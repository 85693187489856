import React, { useState, useCallback, useRef, useEffect } from 'react'
import { Form, Input, Select, DatePicker, DatePickerProps, Checkbox } from 'antd';
import { CheckBox } from 'devextreme-react/check-box';
import refresh from '../../../assets/refresh.png'
import reset from '../../../assets/reset.png'
import { AppraiseButton, Cancel, EditButton, NewButton, SaveButton, UpdateButton } from '../../accessories/buttons';
import { PriorityData, StatusData } from '../data/taskDetailsData';
// import type { SelectProps } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { TaskUpdate } from './taskUpdate';
import ReactDOM from "react-dom/client";
import { Width } from 'devextreme-react/chart';
import useFetch from '../../../hooks/useFetch';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import axios from 'axios';
import { api_url, saveModalData, updateModalData } from '../../accessories/component_infos';
import { log } from 'console';
import { useDispatch } from 'react-redux';
import { task_form_disable, task_tasIdpk } from '../../../features/Task&Todo/Task/TaskDetails';
import MyRoutes from '../../../routers';
import { ModalTemplate } from '../../../templates/modal';
import { TextareaTemplate } from '../../../templates/textarea';



export const TaskDetails = () => {

    const dispatch = useDispatch()
    // Data from redux
    const customtoolbar_active_status = useSelector((state: RootState) => state.checkbox.active_status)
    const redux_tasIdpk_data = useSelector((state: RootState) => state.addTask.tasIdpk);
    const update_state_change = useSelector((state: RootState) => state.addTask.taskFooterUpdate)
    const form_disable = useSelector((state: RootState) => state.addTask.taskFormStatus)
    const selectedRecord = useSelector((state: RootState) => state.addTask.selectedRecord)


    const { Option } = Select;
    const { TextArea } = Input;

    // URL's
    const assignedByURL = "api/TaskDetail/GetAssignedBy";
    const assignedToURL = "api/TaskDetail/GetAssignedTo"
    const titleURL = "api/TaskDetail/GetTitles/1";


    //Endpoint Routes and corresponding data fetched
    const [assignedBy, assignedBy_error, loading] = useFetch(assignedByURL);
    const [assignedTo, assignedTo_error, loading1] = useFetch(assignedToURL);
    const [title, title_error, title_loading] = useFetch(titleURL);


    // Checking on toolbar active status
    // const [useStatus, setUseState] = useState()
    // const [currentUrl, setCurrentUrl] = useState('api/TaskDetail/GetStatus?isActive=true')



    var getallStatusURL = useFetch("api/TaskDetail/GetAllStatus");
    var getStatusURL_true = useFetch("api/TaskDetail/GetStatus?isActive=true")
    var getStatusURL_false = useFetch("api/TaskDetail/GetStatus?isActive=false")
    var getPriorityURL_true = useFetch("api/TaskDetail/GetPriorities?isActive=true")
    var getPriorityURL_false = useFetch("api/TaskDetail/GetPriorities?isActive=false")
    var getallPriorityURL = useFetch("api/TaskDetail/GetAllPriorities")

    // I just like the below logics will definetly use it again 
    // const [status, status_error, status_loading] = (customtoolbar_active_status === true) ?  getStatusURL_true : (customtoolbar_active_status === false ) ? getStatusURL_false  :  getallStatusURL
    // const [priority, priority_error, priority_loading] = (customtoolbar_active_status === true) ?  getPriorityURL_true : (customtoolbar_active_status === false ) ? getPriorityURL_false  :  getallPriorityURL

    const [status, status_error_status_loading] = getStatusURL_true
    const [priority, priority_error, priority_loading] = getPriorityURL_true



    // For Validation & Getting selected Task Details value
    const [onChangeassignedBy, setOnChangeAssignedBy] = useState<number>(0)
    const [onChangeassignedTo, setOnChangeAssignedTo] = useState<string>("")
    const [onChangeassignedToKey, setOnChangeAssignedToKey] = useState<number>(1)
    const [onChangestatus, setOnChangeStatus] = useState<number>(0)
    const [onChangepriority, setOnChangePriority] = useState<number>(0)
    const [onChangetitle, setOnChangeTitle] = useState<string>("")
    const [description, setDescription] = useState<string>("")
    const [scoring, setScoring] = useState<boolean>(false)


    var placeholder = 'StartDate'
    // Start Date & End Date
    const [startDate, setStartDate] = useState<string|Dayjs>("")
    const [endDate, setEndDate] = useState<string|Dayjs>("")



    // Posting assignedBy to the endpoint

    const options = [];
    for (let i = 1; i < 36; i++) {
        options.push({
            label: i.toString(36) + i,
            value: i.toString(36) + i,
        });
    }

    const [textAreaHeight, settextAreaHeight] = useState<string>('75px')

    const handleChange = (value: any) => {
        setOnChangeAssignedToKey(value[0].empIDpk)
        setOnChangeAssignedTo(value[0].empName)
        // alert(onChangeassignedToKey)
        console.log(`selected ${value}`);
        if (value.length >= 3) {
            settextAreaHeight('60px')
        } else {
            settextAreaHeight('71px')
        }
    };
    const dateFormat = " DD MMM YYYY HH: mm:ss";

    console.log(update_state_change)



    const [AppraiseisActive, AppraisesetIsActive] = useState<boolean>(false)

    // converting date back to code date format 

    // const startDatefromSelected = (`${dayjs(selectedRecord[0].tskStartDate)}`);
    // const start = startDatefromSelected.toString();
    // const endDatefromSelected = (`${dayjs(selectedRecord[0].tskDueDate)}`)
    // const end = endDatefromSelected.toString();

    //  Current date
    const editedDate = dayjs().format()
    // console.log(editedDate)

    // Modal dialog data states

    const [refreshValue, setRefreshValue] = useState(false);
    const [showModalDisableButton, setshowModalDisableButton] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [modalData, setModalData] = useState<any>(saveModalData);
    const [icon, setIcon] = useState<'warning' | 'success' | 'question'>();
    const [updatingstatus, setUpdatingstatus] = useState(false);
    const [showCancel, setshowCancel] = useState(false);


    // Validation 

    const validationForms = async () => {
        setIcon('warning')
        if (onChangeassignedBy === 0) {
            setshowModalDisableButton(true);
            setModalData({ message: "Please select data entry for Service Provider", title: " for Service Provider ", okText: "Ok" });
            setOpenModal(true);

        } else if (onChangeassignedTo === "") {
            setshowModalDisableButton(true);
            setModalData({ message: "Please enter data for Vehicle Registration Number", title: "Data entry for Vehicle Registration Number", okText: "Ok" });
            setOpenModal(true);
        } else if (onChangepriority === 0) {
            setshowModalDisableButton(true);
            setModalData({ message: "Please enter data for Assignment Date", title: "Data entry for Assignment Date", okText: "Ok" });
            setOpenModal(true);
        } else if (onChangestatus === 0) {
            setshowModalDisableButton(true);
            setModalData({ message: "Please enter data for End Date", title: "Data entry for End Date", okText: "Ok" });
            setOpenModal(true);
        }
        else if (onChangetitle === "") {
            setshowModalDisableButton(true);
            setModalData({ message: "Please enter data for Card Number", title: "Data entry for Card Number", okText: "Ok" });
            setOpenModal(true);
        }
        else {
            setshowModalDisableButton(false);
            setIcon('question')
            setOpenModal(true);
        }
    }



    // PostDATA
    const postData = async () => {


        //  Passing the current state to redux

        try {
            const res = await axios.post(`${api_url}/api/TaskDetail/CreateTask`, {
                "tskStartDate": dayjs(startDate).format(),
                "tskDueDate": dayjs(endDate).format(),
                "tskCompletionDate": "1967-10-10T11:21:21.935Z",
                "tskAssignedByEmpIDfk": onChangeassignedBy,
                "tskAssignedTo": onChangeassignedTo,
                "tskScoring": scoring,
                "tskStatusIDfk": onChangestatus,
                "tskPriorityIDfk": onChangepriority,
                "tskSubject": onChangetitle,
                "tskTask": description,
                "tskObjectiveCriteria": "",
                "tskObjectiveIDfk": 1,
                "tskObjective": "",
                "tskInitiative": "",
                "tskInitiativeIDfk": 565,
                "tskActive": customtoolbar_active_status,
                "tskCreatedBy": 1,


            }).then((response) => {
                console.log(response);
                const tasIdpk_data = response.data.data[0]
                dispatch(task_tasIdpk(tasIdpk_data))
                const saveAssignement_res = axios.post(`${api_url}/api/TaskDetail/CreateTaskAssignment`, {

                    "tasTaskIdfk": tasIdpk_data,
                    "tasAssignedByEmpIdfk": onChangeassignedBy,
                    "tasAssignedToEmpIdfk": 1,
                    "tasActive": true,
                    "tasCreatedBy": 1,

                }).then((response) => {

                    console.log(response)
                    console.log(JSON.parse(JSON.stringify(saveAssignement_res)))
                })

            })

            setIcon('success')
            setOpenModal(true);
            setshowModalDisableButton(false);
            setModalData({ message: "Data entry saved successfully!", title: "Data Saved!", okText: "Ok" });

            console.log(JSON.parse(JSON.stringify(res)))


        } catch (err: any) {
            console.log(err)
            setIcon('warning')
            setOpenModal(true);
            setshowModalDisableButton(false);
            setModalData({ message: err.message, title: "Unexpected Error!", okText: "Ok" });
        }
    }


    // UpdateData
    const updateData = async () => {



        try {
            const update = await axios.put(`${api_url}/api/TaskDetail/UpdateTask`, {

                // "tasIdpk": 50,
                // "tasTaskIdfk": selectedRecord[0].tskIDpk,
                // "tasAssignedByEmpIdfk": onChangeassignedBy,
                // "tasAssignedToEmpIdfk": 1077,
                // "tasActive": scoring,
                // "tasEditedBy": 1,
                // "tasEditedDate": "2023-01-19T04:19:21.662Z"



                "tskIdpk": selectedRecord[0].tskIDpk,
                "tskStartDate": dayjs(startDate).format(),
                "tskDueDate": dayjs(endDate).format(),
                "tskCompletionDate": "2023-02-24T22:15:30.228Z",
                "tskAssignedByEmpIDfk": onChangeassignedBy,
                "tskAssignedTo": "Duis cupidatat aliquip",
                "tskScoring": scoring,
                "tskStatusIDfk": onChangestatus,
                "tskPriorityIDfk": onChangepriority,
                "tskSubject": onChangetitle,
                "tskTask": description,
                "tskObjectiveCriteria": "cillum",
                "tskObjectiveIDfk": 1,
                "tskObjective": "nulla elit",
                "tskInitiative": "exercitation",
                "tskInitiativeIDfk": 1,
                "tskActive": customtoolbar_active_status,
                "tskEditedBy": 1,
                "tskEditedDate": editedDate,
            }).then((response) => {
                console.log('update results ' + response);
                const tasIdpk_data = response.data.data[0];
                // alert('updated')
                const updateAssignement_res = axios.put(`${api_url}/api/TaskDetail/UpdateTaskAssignment`, {

                    "tasIdpk": 50,
                    "tasTaskIdfk": selectedRecord[0].tskIDpk,
                    "tasAssignedByEmpIdfk": selectedRecord[0]?.tskAssignedByEmpIDfk,
                    "tasAssignedToEmpIdfk": 1174,
                    "tasActive": true,
                    "tasEditedBy": 1,
                    "tasEditedDate": editedDate

                }).then((response) => {
                    // alert('whoa waa ')
                    console.log(response.data)
                    console.log(JSON.parse(JSON.stringify(updateAssignement_res)))
                })
            })
            console.log(update)
            setIcon('success')
            setOpenModal(true);
            setshowModalDisableButton(false);
            setModalData({ message: "Data entry updated successfully!", title: "Data Updated!", okText: "Ok" });


        } catch (err: any) {
            console.log(err);

            setIcon('warning')
            setOpenModal(true);
            setshowModalDisableButton(false);
            setModalData({ message: err.message, title: "Unexpected Error!", okText: "Ok" });
        }

    }




    return (

        <div style={{ width: '600px', minWidth: '600px', height: '100%' }} className="  border-2 rounded-md  dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base  ">
            {/* <h4 className=" w-full  mb-2 bg-slate-100  ">Task Details</h4> */}
            <ModalTemplate icon={icon} cancelText={modalData.cancelText}
                cancelHandler={() => { setOpenModal(false) }} open={openModal} okHandler={() => { modalData === saveModalData ? postData() : modalData === updateModalData ? updateData() : setOpenModal(false) }}
                message={modalData.message} okText={modalData.okText} title={modalData.title} />
            <p id={'form_header'} className='w-full py-1 px-2 bg-slate-100 dark:bg-darkModeSkin-div_headers_backgroundColor dark:text-darkModeSkin-headers'>Task Details</p>
            <div style={{ maxHeight: '49px', minHeight: '49px', height: '49px' }} className=' items-start justify-start'   >

                <Form
                    className='w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 px-2 '
                    name="basic"
                    // labelCol={{ span: 3}}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    size='small'
                    layout='horizontal'

                >
                    {/* Start End Date */}
                    <Form.Item style={{ width: '99%', marginBottom: '2px' }} className='mb-1 dark:bg-slate-900 dark:text-darkModeSkin-base'>
                        <Form.Item wrapperCol={{ span: 15 }} style={{ display: 'inline-block', marginLeft: '6px', width: 'calc(50% - 8px)', }} label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{'Start Date'}</p>} className='mb-1'>
                            <div className="flex flex-row items-center pr-3 dark:bg-slate-900 dark:text-darkModeSkin-base">
                                <DatePicker placeholder='Select Date' disabled={form_disable} onChange={(date, dateString) => { setStartDate(dayjs(date)) }} showTime={{ defaultValue: dayjs('00:00', 'HH:mm') }} className='w-full' format={dateFormat} />


                            </div>

                        </Form.Item>

                        <Form.Item wrapperCol={{ span: 16 }} style={{ display: 'inline-block', marginLeft: '30px', marginRight: '0px', width: 'calc(50% - 30px)' }} label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{'End Date'}</p>} className='mb-1'>
                            <div className="flex flex-row items-center pr-0.5">
                                <DatePicker placeholder='Select Date' disabled={form_disable} onChange={(date, dateString) => { setEndDate(dayjs(date)) }} showTime={{ defaultValue: dayjs('00:00', 'HH:mm') }} className='w-full' format={dateFormat} />
                            </div>
                        </Form.Item>
                    </Form.Item>

                    {/* Assigned By */}
                    <Form.Item style={{ marginLeft: '-5px', marginBottom: '2px' }} label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{'Assigned By'}</p>} className='mb-1 w-full dark:bg-slate-900 dark:text-darkModeSkin-base'>
                        <div className="flex flex-row items-center dark:bg-slate-900 dark:text-darkModeSkin-base">
                            <Select disabled={form_disable} key={selectedRecord[0]?.tskAssignedByEmpIDfk} defaultValue={selectedRecord[0]?.tskAssignedByEmpIDfk} placeholder={''} allowClear onChange={(value: any) => { setOnChangeAssignedBy(value) }} >
                                {

                                    assignedBy?.map((option: any) => {
                                        // console.log(option.empIDpk);

                                        return (
                                            <option key={option.empIDpk} value={option.empIDpk}>{option.empName}</option>
                                        )
                                    })

                                }


                            </Select>

                            <span
                                onClick={() => {
                                    // updateState(select.stateName!, "")
                                    // updateState('town', {id:0, name:''}) 
                                }}
                                style={{ height: 22.5, marginBottom: 2, borderWidth: 1, width: 27.2 }}
                                className='ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded'>
                                <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src={reset} alt="reset" />
                            </span>
                        </div>
                    </Form.Item>

                    {/* Assigned To */}

                    <Form.Item style={{ marginLeft: '-5px', marginBottom: '2px' }} label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{'Assigned To'}</p>} className='mb-1 w-full dark:bg-slate-900 dark:text-darkModeSkin-base'>
                        <div className="flex flex-row items-center dark:bg-slate-900 dark:text-darkModeSkin-base">

                            <Select disabled={form_disable} key={selectedRecord[0]?.tskAssignedTo} defaultValue={selectedRecord[0]?.tskAssignedTo} placeholder='Select an option' allowClear mode='multiple' onChange={(value: any) => { handleChange(value) }} fieldNames={{ label: '50px' }} virtual={true} bordered={false} style={{ backgroundColor: '#fff', border: '1px solid #ccc', overflowY: 'scroll', scrollBehavior: 'smooth', scrollSnapStop: 'normal', width: '550px', maxHeight: '41px', borderRadius: '5px', outline: 'none' }} >

                                {


                                    assignedTo?.map((option: any, index: any) => {
                                        // console.log(option);
                                        const arr: any = [];
                                        arr.push(option);
                                        console.log(option)
                                        return (
                                            <option key={index} value={option.empName}>{option.empName}</option>
                                        )
                                    })

                                }

                            </Select>
                            <span
                                onClick={() => {
                                    // updateState(select.stateName!, "")
                                    // updateState('town', {id:0, name:''}) 
                                }}
                                style={{ height: 22.5, marginBottom: 2, borderWidth: 1, width: 27.2 }}
                                className='ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded'>
                                <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src={reset} alt="reset" />
                            </span>
                        </div>
                    </Form.Item>

                    {/* Status & Priority */}

                    <Form.Item style={{ width: '99%', marginBottom: '2px' }} className=' mb-1 dark:bg-slate-900 dark:text-darkModeSkin-base'>
                        <Form.Item wrapperCol={{ span: 18 }} style={{ display: 'inline-block', marginLeft: '27px', width: 'calc(50% - 27px)' }} label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{'Status'}</p>}>
                            <div className="flex flex-row items-center pr-0.5 dark:bg-slate-900 dark:text-darkModeSkin-base">
                                <Select disabled={form_disable} key={selectedRecord[0]?.stsIDpk} defaultValue={selectedRecord[0]?.stsIDpk} onChange={(value) => { setOnChangeStatus(value) }} placeholder=' ' allowClear dropdownStyle={{ width: '100%' }} >

                                    {


                                        status?.map((option: any, index: any) => {
                                            return (
                                                <option key={option.agsOrder} value={option.agsIDpk}>{option.agsName}</option>
                                            )
                                        })


                                    }

                                </Select>
                                <span
                                    onClick={() => {
                                        // updateState(select.stateName!, "")
                                        // updateState('town', {id:0, name:''}) 
                                    }}
                                    style={{ height: 22.5, marginBottom: 2, borderWidth: 1, width: 32.2 }}
                                    className='ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded'>
                                    <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src={reset} alt="reset" />
                                </span>
                            </div>
                        </Form.Item>

                        <Form.Item className='mb-1 dark:bg-slate-900 dark:text-darkModeSkin-base' style={{ display: 'inline-block', marginLeft: '14px', width: 'calc(50% - 14px)' }} label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs pl-6'>{'Priority'}</p>}>
                            <div className="flex flex-row items-center ml-1 dark:bg-slate-900 dark:text-darkModeSkin-base">
                                <Select disabled={form_disable} key={selectedRecord[0]?.tdpName} defaultValue={selectedRecord[0]?.tdpName} onChange={(value) => { setOnChangePriority(value) }} placeholder='Mr. Dennis' allowClear dropdownStyle={{ width: '100%' }} >

                                    {


                                        priority?.map((option: any, index: any) => {

                                            return (
                                                <option key={option.agsOrder} value={option.agsIDpk}>{option.agsName}</option>
                                            )
                                        })


                                    }

                                </Select>



                                <span
                                    onClick={() => {
                                        // updateState(select.stateName!, "")
                                        // updateState('town', {id:0, name:''}) 
                                    }}
                                    style={{ height: 22.5, marginBottom: 2, borderWidth: 1, width: 27.2 }}
                                    className='ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded'>
                                    <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src={reset} alt="reset" />
                                </span>
                            </div>
                        </Form.Item>
                    </Form.Item>

                    {/* Title */}

                    <Form.Item style={{ display: 'inline-block', marginLeft: '36px', width: 'calc(100% - 36px)', marginBottom: '2px' }} label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{'Title'}</p>} className='mb-1 w-full'>
                        <div className="flex flex-row items-center mr-1 dark:bg-slate-900 dark:text-darkModeSkin-base">
                            <Select disabled={form_disable} key={selectedRecord[0]?.tskSubject} defaultValue={selectedRecord[0]?.tskSubject} onChange={(e: any) => { setOnChangeTitle(e) }} allowClear>
                                {


                                    title?.map((option: any, index: any) => {
                                        return (
                                            <option key={index} value={option.tskSubject}>{option.tskSubject}</option>
                                        )
                                    })




                                }
                            </Select>

                            <span
                                onClick={() => {
                                    // updateState(select.stateName!, "")
                                    // updateState('town', {id:0, name:''}) 
                                }}
                                style={{ height: 22.5, marginBottom: 2, borderWidth: 1, width: 27.2 }}
                                className='ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded'>
                                <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src={reset} alt="reset" />
                            </span>
                        </div>
                    </Form.Item>

                    {/* <Form.Item  style={{ display: 'inline-block',paddingLeft:'32px'}} label={<p className='text-xs'>{'Title'}</p>} className='w-full mb-0.5'>
                                            <div className="flex flex-row items-center ml-1 ">
                                                <Input style={{width:'95%',marginRight:'2px'}} className='' type='text' />
                                                <span  className='w-5 pr-1'>
                                                    <img className='w-full h-full' src= {refresh} alt="" />
                                                </span>
                                            </div>
                                        </Form.Item> */}

                    {/* Description */}

                    <Form.Item style={{ display: 'inline-block', }} label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{'Description'}</p>} className='w-full mb-1'>
                        <TextareaTemplate
                            height={75}
                            readonly={form_disable}
                            defaultValue={selectedRecord[0]?.tskTask}
                            useCallbackFunc
                            setCallbackValue={(e) => { setDescription(e) }}
                        />

                        {/* <TextArea readOnly={form_disable} disabled={form_disable} key={selectedRecord[0]?.tskTask} defaultValue={} onChange={}  style={{resize : 'none',paddingBottom:'0px',height:textAreaHeight}}  /> */}


                    </Form.Item>

                    <div className='w-full flex  flex-row justify-between  '>
                        <li style={{ marginLeft: '1%' }} className='ml-0 flex items-center'><p className='mr-3'>Scoring? : </p> <Checkbox onChange={() => { setScoring(!scoring) }} /></li>
                        <AppraiseButton AppraiseisActive={AppraiseisActive} AppraisesetIsActive={AppraisesetIsActive} />
                        <ul className='flex'>
                            {



                                (form_disable === false && update_state_change === false) ?
                                    <SaveButton handleSave={() => { setModalData(saveModalData); setOpenModal(true); validationForms() }} />
                                    :

                                    (update_state_change === true) ?

                                        <UpdateButton handleUpdate={() => { setModalData(updateModalData); setOpenModal(true); validationForms() }} />

                                        :
                                        <SaveButton />



                                // useCallbackFunc new_button_toggler={()=>{setform_disable(!form_disable)}}
                            }
                            {(form_disable === true) ?
                                <NewButton
                                    new_button_toggler={() => { }}
                                />

                                :
                                (update_state_change === true) ?
                                    <Cancel
                                        cancel_button_toggler={() => { }}
                                    />
                                    :
                                    !(update_state_change && form_disable) ?
                                        <Cancel
                                            cancel_button_toggler={() => { }}
                                        />

                                        :
                                        <NewButton
                                            new_button_toggler={() => { }}
                                        />
                            }


                        </ul>
                    </div>
                </Form>
            </div>



        </div>
    )
}


