/* eslint-disable no-lone-blocks */
import { CloseOutlined } from "@ant-design/icons";
import { TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/accordion";

import { useEffect, useRef, useState } from "react";
import CustomLoader from "../accessories/custom-loader";
import { useBusyLoader } from "../../hooks/useBusyLoader";
import { setGeneralValue } from "../../features/generalSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { Dashboard } from "../../pages/Dashboard/Dashboard";
import { TaskTab } from "../Task&Todos/taskTabs";
import useFetch from "../../hooks/useFetch";
import { useAccountInfo } from "../../hooks/useAccountInfo";
import {
  setElementStyles,
  setElementStylesQuerySelector,
} from "../../functions/DOMElementStyles";
import { userDataCrypt } from "../../functions/userDataEncrypt";
import React from "react";

interface props {
  selected: string;
  UserPage: any;
  tabs: any;
  setTabs: (e: any) => void;
}

const SafetyScreen = ({ selected, UserPage, tabs, setTabs }: props) => {
  const [busyLoader, setBusyLoader] = useBusyLoader();
  const login : boolean = useSelector((state: RootState) => state.general?.loginMode);
  const formCode : boolean = useSelector((state: RootState) => state.user?.formCode);


  // const [tabs, setTabs] = useState<any>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [recentIndexes, setRecentIndexes] = useState<string[]>([]);
  const tabRef = useRef<any>();
  const dispatch = useDispatch();

  function array_move(arr: any, old_index: number, new_index: number) {
    while (old_index < 0) {
      old_index += arr.length;
    }
    while (new_index < 0) {
      new_index += arr.length;
    }
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing purposes
  }

  const rxCloseAllForms = useSelector(
    (state: RootState) => state.general.closeAllForms
  );

  const [employeeID, userID] = useAccountInfo();
  const [userSettingsData, userSettingsError, userSettingsLoading] = useFetch(
    `UserSettings/GetUserSettingById?UserID=${userID}`
  );
  const formHeadersBG = useSelector(
    (state: RootState) => state.general.settingsColors?.fhbgc
  );
  const lockStatus = sessionStorage.getItem("lockStatus");
  // const lockStatus = userDataCrypt("decrypt", status);
  // console.log("isLocked", lockStatus);
  const ids = [
    "form_header",
    "form_header2",
    "form_header3",
    "form_header4",
    "form_header5",
    "form_header6",
    "form_header7",
  ];

  for (const id of ids) {
    setElementStyles(
      id,
      `bg-${
        formHeadersBG?.color ?? "slate-100"
      } flex justify-between pr-2 py-0.5 pl-4 w-full border-b-[1px] rounded-tl font-medium`
    );
  }

  setElementStylesQuerySelector(
    "form_head",
    `bg-${
      formHeadersBG?.color ?? "slate-100"
    } flex justify-between pr-2 py-0.5 pl-4 w-full border-b-[1px] rounded-tl font-medium`
  );

  useEffect(() => {
    dispatch(
      setGeneralValue({ expr: "userSettingFromSafety", userSettingsData })
    );

    if (userSettingsData.ustshowTaskOnStartup === true) {
      setTabs([...tabs, { name: "Tasks", url: "/tasks", page: TaskTab }]);
      setRecentIndexes([...recentIndexes, "Tasks"]);
    }
  }, [userSettingsData.ustshowTaskOnStartup]);

  useEffect(() => {
    // const cachedTabs = JSON.parse(sessionStorage.getItem("tabs"));

    // console.log("cachedTabs", cachedTabs);
    // if (cachedTabs.length !== 0 && lockStatus === "true") {
    //   setTabs([...cachedTabs]);
    // }

    let optional = false;
    let tabIndex = 0;

    if (lockStatus === "false") {
      const newTabs = tabs.filter((tab: any) => tab?.name !== "Lock Screen");
      console.log("newTabs", newTabs);
      setTabs([...newTabs]);
    }

    if (tabs.length < 1) {
      //If the tabs array is empty, add the current one

      setTabs([
        ...tabs,
        { name: selected, url: window.location.pathname, page: UserPage },
      ]);
      setRecentIndexes([...recentIndexes, selected]);

      if (rxCloseAllForms === true) {
        // if all tabs are closed show the Dashboard component
        setTabs([
          {
            name: "Welcome",
            url: `${window.location.pathname}`,
            page: Dashboard,
          },
        ]);
        setRecentIndexes([...recentIndexes, "Dashboard"]);
      }
    } else {
      //loop through array

      for (let index = 0; index < tabs.length; index++) {
        const element = tabs[index];
        //If current location is found in array, maintain that index
        if (window.location.pathname === element.url) {
          optional = true;
          tabIndex = index;
        }
      }

      if (optional) {
        //If such existing value was found,  Set current index to the index that was found

        if (lockStatus === "true") {
          const lockIndex = tabs.findIndex(
            (tab: any) => tab?.name === "Lock Screen"
          );
          setCurrentIndex(lockIndex);
        } else {
          setCurrentIndex(tabIndex);
        }

        //Check for item, remove it and add it.
        let testArray2 = [...recentIndexes];
        let newModifiedArray = array_move(
          testArray2,
          tabIndex,
          testArray2.length - 1
        );
        console.log("Is optional");
        setRecentIndexes([...newModifiedArray]);
      } else {
        //Add that new value to the array and set the tab index to that value's index
        setTabs([
          ...tabs,
          { name: selected, url: window.location.pathname, page: UserPage },
        ]);

        if (lockStatus === "true") {
          const lockIndex = tabs.findIndex(
            (tab: any) => tab?.name === "Lock Screen"
          );
          console.log("lockIndex", lockIndex);
          setCurrentIndex(lockIndex);
        } else {
          setCurrentIndex(tabs.length);
        }
        //dont do anything with the removed value
        setRecentIndexes([...recentIndexes, selected]);
      }
    }

    // console.log("fNewTabs", tabs);
    // sessionStorage.setItem(
    //   "tabs",
    //   JSON.stringify([
    //     ...tabs,
    //     { name: selected, url: window.location.pathname, page: UserPage },
    //   ])
    // );
  }, [window.location.pathname, lockStatus, rxCloseAllForms, selected, UserPage ]);



  return (
    <div className="w-full h-full  flex justify-center items-center">
      {busyLoader !== "" && <CustomLoader text={busyLoader} />}

      <TabPanel
        ref={tabRef}
        scrollingEnabled={true}
        showNavButtons={true}
        swipeEnabled={true}
        // onTitleClick={() => {}}
        // onContentReady={(e) => {}}
        itemTitleRender={(data) => {
          return (
            <React.Fragment key={data.title}>
              <div className="w-full items-center p-1  flex">
                <span
                  className={`w-full  h-full  justify-center items-center`}
                  onClick={() => {
                    setCurrentIndex(data);

                    let clickedTab = tabs.filter(
                      (element: any) => element.name === data.title
                    );

                    window.history.replaceState(
                      null,
                      "",
                      `${clickedTab[0].url}`
                    );
                  }}
                >
                  {data.title}
                </span>
                {data.title === "Lock Screen" ? (
                  <></>
                ) : (
                  <>
                    <CloseOutlined
                      className="ml-4 hover:text-red-400 text-xs pb-0.4"
                      onClick={() => {
                        if (!(tabs.length <= 1)) {
                          let newArray: any = [];
                          let newTabs: any = [];
                          for (
                            let index = 0;
                            index < recentIndexes.length;
                            index++
                          ) {
                            const element = recentIndexes[index];
                            if (data.title !== element) {
                              newArray.push(element);
                            }
                          }

                          tabs.forEach((element: any) => {
                            //If element is not equal to clicked data, retain
                            if (element.name !== data.title) {
                              newTabs.push(element);
                            }
                          });

                          let mostRecentIndex = 0;
                          for (let index = 0; index < newTabs.length; index++) {
                            const element = newTabs[index];
                            if (
                              element.name === newArray[newArray.length - 1]
                            ) {
                              mostRecentIndex = index;
                            }
                          }

                          setCurrentIndex(mostRecentIndex);
                          window.history.replaceState(
                            null,
                            "",
                            `${newTabs[newTabs.length - 1].url}`
                          );

                          setTabs([...newTabs]);
                          // sessionStorage.setItem(
                          //   "tabs",
                          //   JSON.stringify(newTabs)
                          // );
                        }
                      }}
                    />
                  </>
                )}
              </div>
            </React.Fragment>
          );
        }}
        onSelectedIndexChange={(e) => {}}
        selectedIndex={currentIndex}
        className="w-full h-full"
      >
        {tabs.map((tab: any, i: number) => {
          {
            return (
              <Item
                visible={true}
                title={tab.name}
                disabled={
                  lockStatus === "true" && tabs[i]?.name !== "Lock Screen"
                    ? true
                    : false
                }
              >
                <tab.page />
              </Item>
            );
          }
        })}
      </TabPanel>
    </div>
  );
};

export default SafetyScreen;
