import axios from "axios";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import {
  setValue,
  tier1SaveOnclick,
  tier1UpdateOnclick,
} from "../../../../features/forms/tier1/createSlice";
import { setGeneralValue } from "../../../../features/generalSlice";
import useFetch from "../../../../hooks/useFetch";
import { ModalTemplate } from "../../../../templates/modal";
import {
  validationsData,
  saveModalData,
  updateModalData,
  api_url,
} from "../../../accessories/component_infos";
import Setups_Template from "../../../human-resource/setups/.setups_templates/Setups_Template";
import {
  exprsTypes,
  modalTypes,
  searchTextTypes,
} from "../../../human-resource/setups/data/types/selectsTypes"; 
import { performance_sectionInitiative_columns } from "./data/secInitiativeData";
import CustomLoader from "../../../accessories/custom-loader";
import { UpdateDataFunc } from "../../../../functions/update";
import { PostDataFunc  } from "../../../../functions/post";

export const Perf_setups_secInitiatives = forwardRef(({ a }: any, ref) => {
  // on page resize height responsiveness
  const saveSuccessText = {
    message: "Section Initiative added Successfully",
    ok: "Ok",
    title: "Success",
  }; // successful save modal message
  const updateSuccessText = {
    message: "Section Initiative updated Successfully",
    ok: "Ok",
    title: "Success",
  }; // successful update modal message

  // store data expressions[unique] ...dependent on data coming in
  const idExpr: string = "sxiIdpk";
  const nameExpr: string = "sxiName";
  const shortnameExpr: string = "sxiShtName";
  const orderExpr: string = "sxiOrder";
  const activeExpr: string = "sxiActive";
  const remarksExpr: string = "sxiRmks";
  const inputsExprsData: any = {
    idExpr: idExpr,
    nameExpr: nameExpr,
    shortnameExpr: shortnameExpr,
    orderExpr: orderExpr,
    activeExpr:activeExpr,
    remarksExpr: remarksExpr,
  };

  const storeExprs = () => {
    const exprs = [
      { expr: "idExpr", value: idExpr },
      { expr: "nameExpr", value: nameExpr },
      { expr: "shortnameExpr", value: shortnameExpr },
      {expr:'existsUrl',value:`SectionInitiatives`},
    ];

    //to particular redux store based on form type
    for (let i = 0; i < exprs.length; i++) {
      dispatch(setValue({ expr: exprs[i].expr, value: exprs[i].value }));
    }

    // to general slice
    const generalExprs: exprsTypes[] = [
      { expr: "inputExprs", value: inputsExprsData },
    ];
    for (let i = 0; i < generalExprs.length; i++) {
      dispatch(
        setGeneralValue({
          expr: generalExprs[i].expr,
          value: generalExprs[i].value,
        })
      );
    }
  };

  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState<boolean>(false); //refresh data state for data creation or update
  const activeStatus = useSelector((state:RootState)=>state.general.activeStatus);
  const[searchText,setSearchText]=useState<searchTextTypes>({temp:'',text:''});
  const[status,setStatus]=useState<boolean|string>('');
  //modal states
  //--general--
  const triggerSuccessModal = useSelector(
    (state: RootState) => state.tier1Add.successModalState
  ); //trigger success modal
  const SuccessModalText = useSelector(
    (state: RootState) => state.tier1Add.successText
  ); //success modal text

  //--for backend errors--
  const [errState, setErrState] = useState<boolean>(false);
  const [errTitle, seterrTitle] = useState<string>("");
  const [errMessage, seterrMessage] = useState<string>("");

  //--warning modal--
  const triggerwarningModal = useSelector(
    (state: RootState) => state.tier1Add.warningState
  ); //trigger warning
  const warningInfos: any = validationsData; //warning type details
  const warningDataExpr: any = useSelector(
    (state: RootState) => state.tier1Add.warningDataExpr
  ); //warning type selector

  //--save data modal--
  const saveData = saveModalData; //save modal details[data]
  const triggersaveModal = useSelector(
    (state: RootState) => state.tier1Add.saveDataOnclick
  ); //trigger save modal

  //--Update data modal--
  const updateIndInfos = updateModalData; //update modal details[data]
  const triggerupdateModal: boolean = useSelector(
    (state: RootState) => state.tier1Add.updateDataOnclick
  ); //trigger update question
  // on page resize height responsiveness
  const [trigger, setTrigger] = useState<boolean>(false);
  useImperativeHandle(ref, () => ({
    test: () => {
      setTrigger(!trigger);
    },
  }));

  // get current values from  [slice dependent on form type]
  const name = useSelector((state: RootState) => state.tier1Add.name);
  const shortname = useSelector((state: RootState) => state.tier1Add.shortname);
  const order = useSelector((state: RootState) => state.tier1Add.order);
  const active = useSelector((state: RootState) => state.tier1Add.active);
  const remarks = useSelector((state: RootState) => state.tier1Add.remarks);
  const selectedID = useSelector(
    (state: RootState) => state.general.SelectedFieldID
  );

  //post form Data
  //validate data
  const validateDataSave = () => {
    dispatch(tier1SaveOnclick());
    postData();
  };
  // post
  const postData = async () => {
    dispatch(setValue({ expr: "saveDataOnclick", value: false })); //disable modal
    try {
      await PostDataFunc (
        `PfmSectionInitiatives/CreateSectionInitiatives`,
        {
          sxiName: name,
          sxiShtName: shortname,
          sxiActive: active,
          sxiOrder: order,
          sxiRmks: remarks,
          sxiCreatedBy: "1",
        }
      );

      setRefresh(!refresh);

      const exprs: exprsTypes[] = [
        { expr: "saveDataOnclick", value: false },
        { expr: "successModalState", value: true },
        { expr: "successText", value: saveSuccessText },
      ];
      for (let i = 0; i < exprs.length; i++) {
        dispatch(setValue({ expr: exprs[i].expr, value: exprs[i].value }));
      }
    } catch (error: any) {
      //set states for db errors modal
      setErrState(true);
      seterrTitle(
        error.response ? error.response.data.message : "Server Error"
      );
      seterrMessage(
        error.response
          ? error.response.data.errors[0]
          : "Error connecting to server. Please contact system administrator"
      );
    }
  };

  //Update Form data
  //validate data
  const validateDataUpdate = () => {
    dispatch(tier1UpdateOnclick());
    UpdateData();
  };
  // update
  const UpdateData = async () => {
    dispatch(setValue({ expr: "updateDataOnclick", value: false })); //disable modal
    try {
      await UpdateDataFunc(
        `PfmSectionInitiatives/UpdateSectionInitiative/${selectedID}`,
        {
          sxiName: name,
          sxiShtName: shortname,
          sxiActive: true,
          sxiOrder: order,
          sxiRmks: remarks,
          sxiEditedBy: 1,
          sxiEditedDate: "1983-06-01T17:22:05.380Z",
        }
      );

      setRefresh(!refresh);

      const exprs: exprsTypes[] = [
        { expr: "updateDataOnclick", value: false },
        { expr: "successModalState", value: true },
        { expr: "successText", value: updateSuccessText },
      ];
      for (let i = 0; i < exprs.length; i++) {
        dispatch(setValue({ expr: exprs[i].expr, value: exprs[i].value }));
      }
    } catch (error: any) {
      //set states for db errors modal
      setErrState(true);
      seterrTitle(error.response.data.message);
      seterrMessage(error.response.data.errors[0]);
    }
  };

  //modals data
  const modals: modalTypes[] = [
    //warning
    {
      disableCancel: true,
      icon: "warning",
      open: triggerwarningModal,
      okHandler: () =>
        dispatch(setValue({ expr: "warningState", value: false })),
      cancelHandler: () =>
        dispatch(setValue({ expr: "warningState", value: false })),
      message: warningInfos[warningDataExpr!].message,
      cancelText: warningInfos[warningDataExpr!].cancelText,
      okText: warningInfos[warningDataExpr!].okText,
      title: warningInfos[warningDataExpr!].title,
    },
    //backend error
    {
      disableCancel: true,
      icon: "warning",
      open: errState,
      okHandler: () => setErrState(false),
      cancelHandler: () => setErrState(false),
      message: errMessage,
      cancelText: "",
      okText: "Ok",
      title: errTitle,
    },
    //success
    {
      disableCancel: true,
      icon: "success",
      open: triggerSuccessModal,
      okHandler: () =>
        dispatch(setValue({ expr: "successModalState", value: false })),
      cancelHandler: () =>
        dispatch(setValue({ expr: "successModalState", value: false })),
      message: SuccessModalText.message,
      okText: SuccessModalText.okText,
      title: SuccessModalText.title,
    },
    //save
    {
      disableCancel: false,
      icon: "question",
      open: triggersaveModal,
      okHandler: () => postData(),
      cancelHandler: () =>
        dispatch(setValue({ expr: "saveDataOnclick", value: false })),
      message: saveData.message,
      cancelText: saveData.cancelText,
      okText: saveData.okText,
      title: saveData.title,
    },
    //Update
    {
      disableCancel: false,
      icon: "question",
      open: triggerupdateModal,
      okHandler: () => UpdateData(),
      cancelHandler: () =>
        dispatch(setValue({ expr: "updateDataOnclick", value: false })),
      message: updateIndInfos.message,
      cancelText: updateIndInfos.cancelText,
      okText: updateIndInfos.okText,
      title: updateIndInfos.title,
    },
  ];

  // data for datagrid
  const [data, error, loading] = useFetch(
    `PfmSectionInitiatives/SearchSectionInitiative?sectionInitiative=${searchText.text}&activeState=${activeStatus}`,
    refresh,'sxiIdpk'
  );

  if (data) {
    storeExprs(); // run func to store expressions on store
    dispatch(setGeneralValue({ expr: "idExpr", value: idExpr })); // dispatch fetched data to reduxstore
    dispatch(setGeneralValue({ expr: "formData", value: data })); // dispatch fetched data to reduxstore
  }

  // data for datagrid

  // form parameters
  const form_header = "Section Initiatives Details";

  return (
    <>
      {/* modals */}
      {modals.map(
        (
          {
            disableCancel,
            icon,
            okHandler,
            cancelHandler,
            open,
            cancelText,
            okText,
            message,
            title,
          }: modalTypes,
          index
        ) => {
          return (
            <div key={index}>
              <ModalTemplate
                icon={icon && icon}
                disableCancel={disableCancel}
                cancelText={cancelText && cancelText}
                open={open}
                okHandler={okHandler}
                cancelHandler={cancelHandler}
                message={message}
                okText={okText}
                title={title}
              />
            </div>
          );
        }
      )}
      {loading &&(
        <CustomLoader/>
      )}
      
      <Setups_Template
        updateData={validateDataUpdate}
        createData={validateDataSave}
        formTier="1"
        
        trigger={trigger}
        datagridColumns={performance_sectionInitiative_columns}
        datagridData={data}
        formHeader={form_header}
        searchTextChange={(txt)=>{setSearchText({temp:txt,text:searchText.text})}}
        handleSearch={()=>{setSearchText({temp:searchText.temp,text:searchText.temp}); setStatus(activeStatus)}}
        handleRefresh={()=>{
          setRefresh(!refresh)
          setSearchText({temp:'',text:''})
          setStatus('')
        }}
      />
    </>
  );
});
