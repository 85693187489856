import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { RootState } from '../../../../../../app/store';
import { setGeneralValue } from '../../../../../../features/generalSlice';
import { useBusyLoader } from '../../../../../../hooks/useBusyLoader';
import useFetch from '../../../../../../hooks/useFetch';
import Datagrid_template from '../../../../../../templates/Datagrid';
import { navbar_height, toolbar_height } from '../../../../../accessories/component_infos';
import { HrSetupsCustomToolbar } from '../../../../../accessories/custom-toolbars/hrSetupsCustomtoolbar';
import { searchCriteriaTypes, searchTextTypes } from '../../../../../human-resource/setups/data/types/selectsTypes';
import { assets_workShopDetailsColumns } from './data/workshopDetailsData';
import { Workshop_Details_form } from './widgets/workshopDetails_form';



export const Workshop_details_setupform = forwardRef(({a}:any, ref)=>{
    const news_flash_height = useSelector((state:RootState)=>state.general.newsflashHeight);
    const dispatch = useDispatch();
    const[busyLoader,setBusyLoader] = useBusyLoader();
    const [refresh, setRefresh] = useState(false);
   
    const activeStatus = useSelector((state:RootState)=>state.general.activeStatus);
    const [searchText,setSearchText]=useState<searchTextTypes>({temp:'',text:''});
    const [searchCriteriaIndex,setSearchCriteriaIndex]=useState<searchCriteriaTypes>({temp:0,index:0});
    const [status,setstatus]= useState<boolean|string>('');


    const fetchUrl:string = `AsmTmsWorkshopDetails/GetAllVwAsmTmsWorkshopDetails?isActive=${status}&wsdName=${searchText.text}`;
    // api/AsmTmsWorkshopDetails/GetAllVwAsmTmsWorkshopDetails?isActive=true&wsdName=mans

    
    const [selectedFieldData,setSelectedFieldData] = useState({})
    
    const allowUpdate = ()=>{
        dispatch(setGeneralValue({ expr: "updateMode", value: true }));
        dispatch(setGeneralValue({ expr: "formDisabled", value: false}));
    }


    const dataGridRowClick = (selectedFieldData:any)=>{
        dispatch(setGeneralValue({ expr: "updateMode", value: false }));
        dispatch(setGeneralValue({ expr: "formDisabled", value: true}));
        dispatch(setGeneralValue({ expr: "SelectedFieldID", value: selectedFieldData?.wsdIdpk}))
        setSelectedFieldData(selectedFieldData)
        console.log(selectedFieldData)
    }

    // on page resize height responsiveness
    const [trigger,setTrigger] = useState<boolean>(false);
    useImperativeHandle(ref, () => ({
        test: () => {
            setTrigger(!trigger);
        }
    }));

    //navigation
    const setupPageRoute = window.location.pathname; //get current route of page
    const datagridOnlySearchParam = 'table-only'  //search param in datagrid mode
    const formModeSearchParam = 'form-and-table' //search param in form mode mode
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    //miscellaneous heights
    const miscHeight = 7;
    
    // All heights out
    const heights_out = navbar_height + news_flash_height + toolbar_height;

    //varying form height
    const [form_height,set_form_height] = useState<number>(243);
    const pass_form_height=(height:number)=>{
        set_form_height(height)
    }

    const [active, setActive] = useState<{temp:boolean|string, status:boolean|string}>({
        temp: '',
        status: ''
    });


    // Page resize height responsiveness
    useEffect(()=>{
        settableHeight(window.innerHeight - (heights_out + miscHeight -4));
    },[])

    // handle interface switch
    const toggle_staff_form = ():void =>{
        const view = searchParams.get('view');

        (view === null || view === formModeSearchParam) ? 
        navigate({
            pathname : setupPageRoute,
            search : createSearchParams({
                view : datagridOnlySearchParam,
            }).toString(),
        })
        :
        navigate({
            pathname : setupPageRoute,
            search : createSearchParams({
                view : formModeSearchParam,
            }).toString(),
        });
    }

    //Table Height
    const pageHeight = window.innerHeight - (navbar_height + miscHeight) ; //height without navbar
    const [tableHeight,settableHeight] = useState(window.innerHeight); //height without navbar and toolbar

    //get GridView
    const [data, error, loading] = useFetch(fetchUrl, refresh,'wsdIdpk');
    useEffect(()=>{
        if(data){
            console.log(data)
            // storeExprs(); // run func to store expressions on store
            dispatch(setGeneralValue({expr : 'idExpr', value : 'wsdIdpk'})); // dispatch fetched data to reduxstore
            dispatch(setGeneralValue({expr: 'formData' ,value : data})); // dispatch fetched data to reduxstore
        }

        loading ? setBusyLoader('.') : setBusyLoader('');
    },[data,loading])
    console.log(selectedFieldData)
    
    
    return (
        <div style={{height : pageHeight}} className="w-full">
            <div className="px-2">
                <HrSetupsCustomToolbar 
                 searchTextOnchange={(newText) =>{
                    setSearchText({temp:newText, text:searchText.text});
                }}
                 handleFind={() =>{
                    console.log(fetchUrl)
                    setSearchText({temp:searchText.temp, text: searchText.temp});
                    setstatus(activeStatus);
                }}
                handleRefresh={() =>{
                    setRefresh(current=> !current);
                    
                    setstatus('');
                    setSearchText({temp:'', text:''})
                }}
                withDates={false}
                toggler={toggle_staff_form}
             
                checkOnChange={(value) => {
                    // console.log(value)
                    setActive({temp: value, status: active.status}); //store locally
                    dispatch(setGeneralValue({ expr: "activeStatus", value: value })); //push value to redux
                }}
              
             />
            </div>
            
            {/* form section */}
            {(searchParams.get('view') !== datagridOnlySearchParam) &&
            <section style={{height : form_height}}  className="form border-b-4">
                <Workshop_Details_form
                refreshGrid={()=> setRefresh(!refresh)} 
                selectedData={selectedFieldData}/>
            </section>}

            {/* datagrid section */}
            <section className="mt-1 px-2">
                <Datagrid_template gridheight= {searchParams.get('view') === formModeSearchParam || searchParams.get('view') === (null) ?  
                window.innerHeight - (form_height + heights_out + miscHeight) 
                : tableHeight} 
                data = {data} 
                rowDoubleClicked={allowUpdate}
                onRowClick={dataGridRowClick}
                columns = {assets_workShopDetailsColumns}/>
            </section>
        </div>
    );
})