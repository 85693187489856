export const columnsForEmployees = [
    { id: 0, caption: "PICTURE", dataField: "ppxPicture", dataType: "string", alignment: "center", width: 115, fixed: true, },
    { id: 1, caption: "STAFF No", dataField: "empstaffno", dataType: "string", alignment: "left", fixed: true, width: 125 },
    { id: 2, caption: "STAFF NAME", dataField: "empname", dataType: "string", alignment: "left", fixed: true, width: 125 },
    { id: 3, caption: "INTERCOM", dataField: "empIntercom", dataType: "string", alignment: "left", fixed: true, width: 300 },
    { id: 4, caption: "Mob No", dataField: "empMobNox", dataType: "string", alignment: "left", fixed: true, width: 110 },
    { id: 5, caption: "TEL No", dataField: "empTelNo", dataType: "string", alignment: "left", fixed: true, width: 80 },
    { id: 6, caption: "DEPT", dataField: "dptname", dataType: "string", alignment: "left", width: 80, fixed: true },
    { id: 7, caption: "SECTION", dataField: "sxnshtname", dataType: "string", alignment: "left", width: 80, fixed: true },
    { id: 8, caption: "UNIT", dataField: "untName", dataType: "string", alignment: "left", width: 80, fixed: true },
    { id: 9, caption: "JOB TITLE", dataField: "jbtShtName", dataType: "string", alignment: "left", width: 80, fixed: true },
    { id: 10, caption: "LOCATION", dataField: "locshtname", dataType: "string", alignment: "left", width: 80, fixed: true },
    { id: 11, caption: "OFFICE LOC", dataField: "locname", dataType: "string", alignment: "left", width: 80, fixed: true },
    { id: 12, caption: "ROOM No", dataField: "romname", dataType: "string", alignment: "left", width: 80, fixed: true },
    { id: 13, caption: "EMAIL(OFFICIAL)", dataField: "empOfficialEmail", dataType: "string", alignment: "left", width: 80, fixed: true },
    { id: 14, caption: "ACTIVE?", dataField: "empActive", dataType: "boolean", alignment: "center", width: 80, fixed: true },
    { id: 15, caption: "REMARKS", dataField: "tdrRmks", dataType: "string", alignment: "left", width: 80, fixed: true },
];