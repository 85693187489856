/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-pascal-case */
import newIcon from '../../../../../../../../assets/new.png'
import refresh from '../../../../../../../../assets/refresh.png';
import { attachement_cols } from "../../data/datagrid_cols";
import Datagrid_template from "../../../../../../../../templates/Datagrid";
import saveBtn from '../../../../../../../../assets/save2.png';


export const Right_Attachments = ({ fullPage }: { fullPage?: any }) => {
   return (
      <div style={{ height: fullPage }} className="w-full ">
         <ul className="w-full flex justify-evenly items-center py-2">

            <li className="justify-center cursor-pointer items-center"><img src={newIcon} /></li>
            <li className="justify-center cursor-pointer items-center"><img className="w-4" src={refresh} /></li>
            <li className="justify-center cursor-pointer items-center"><img src={saveBtn} className="w-4" /></li>
         </ul>


         <div className="h-[90%]">
            <Datagrid_template
               gridheight={500}
               disablePaging={true}
               disableGroupPanel={true}
               disableSearch={true}
               columns={attachement_cols}
               data={undefined}

            />


         </div>
      </div>
   )
}
