import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetDataFunc } from "../../functions/get";


interface apiStates {
    fromCountryData : any | null;
    toCountryData : any | null;
    fromRegionData : any | null;
    toRegionData : any | null;
    fromLocationData : any | null;
    toLocationData : any | null;
    isLoading: boolean;
    isPending: boolean;
    isError: boolean;
}

var initialState: apiStates = {
    fromCountryData: [],
    toCountryData: [],
    fromRegionData: [],
    toRegionData: [],
    fromLocationData: [],
    toLocationData: [],
    isLoading: false,
    isPending: false,
    isError: false,
};

export const fetchFromCountry = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "fromCountryType/fetch",
    async (search: string) => {
        const res = await GetDataFunc(`AsmTmsVehicleLogBook/GetVehicleDepartureCountry?pagesize=20&pagenumber=1&Search=${search}`);
        return res.data;
    }
);
export const fetchToCountry = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "toCountryType/fetch",
    async (search: string) => {
        const res = await GetDataFunc(`AsmTmsVehicleLogBook/GetVehicleDestinationCountry?pagesize=20&pagenumber=1&Search=${search}`);
        return res.data;
    }
);

export const fetchFromRegion = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "fromRegionType/fetch",
    async (search: string ,states: any) => {
        const res = await GetDataFunc(`AsmTmsVehicleLogBook/GetVehicleDepatureRegion?CtyIDpk=${states.selectedFromCountry.id}&RgnActive=true&pagesize=20&pagenumber=1&Search=${search}`);
        return res.data;
    }
);
export const fetchToRegion = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "toRegionType/fetch",
    async (search: string, states: any) => {
        const res = await GetDataFunc(`AsmTmsVehicleLogBook/GetVehicleDestinationRegion?CtyIDpk=${states}&CtyActive=true&pagesize=20&pagenumber=1&Search=${search}`);
        return res.data;
    }
);
export const fetchFromLocation = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "fromLocationType/fetch",
    async (search: string, states: any) => {
        const res = await GetDataFunc(`AsmTmsVehicleLogBook/GetVehicleDepartureLocation?ToRgnCtyID=${states.selectedFromCountry.id}&FromRgnID=${states.selectedFromRegion.id}&PageSize=20&PageNumber=1&Search=${search}`);
        return res.data;
    }
);
export const fetchToLocation = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "toLocationType/fetch",
    async (search: string, states: any,) => {
        const res = await GetDataFunc(`AsmTmsVehicleLogBook/GetVehicleDestinationLocation?ToRgnCtyID=${states.selectedToCountry.id}&ToRgnID=${states.selectedToRegion.id}&pagesize=20&pagenumber=1&Search=${search}`);
        return res.data;
    }
);


const vehicleLogBook = createSlice({
    name: 'apiLog',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // menType
            .addCase(fetchFromCountry.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchFromCountry.fulfilled, (state, action) => {
                state.isLoading = false;
                state.fromCountryData = action.payload;
            })
            .addCase(fetchFromCountry.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(fetchToCountry.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchToCountry.fulfilled, (state, action) => {
                state.isLoading = false;
                state.toCountryData = action.payload;
            })
            .addCase(fetchToCountry.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(fetchFromRegion.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchFromRegion.fulfilled, (state, action) => {
                state.isLoading = false;
                state.fromRegionData = action.payload;
            })
            .addCase(fetchFromRegion.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(fetchToRegion.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchToRegion.fulfilled, (state, action) => {
                state.isLoading = false;
                state.toRegionData = action.payload;
            })
            .addCase(fetchToRegion.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(fetchFromLocation.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchFromLocation.fulfilled, (state, action) => {
                state.isLoading = false;
                state.fromLocationData = action.payload;
            })
            .addCase(fetchFromLocation.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(fetchToLocation.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchToLocation.fulfilled, (state, action) => {
                state.isLoading = false;
                state.toLocationData = action.payload;
            })
            .addCase(fetchToLocation.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
    },
});

export default vehicleLogBook.reducer;
