/* eslint-disable react/jsx-pascal-case */
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";
import {
  setTier2CheckboxValue,
  tier2CheckboxSaveOnclick,
  tier2CheckboxUpdateOnclick,
} from "../../../../../../features/forms/tier2checkbox/tier2SliceCheckbox";
import { setGeneralValue } from "../../../../../../features/generalSlice";
import useFetch from "../../../../../../hooks/useFetch";
import { ModalTemplate } from "../../../../../../templates/modal";
import {
  validationsData,
  saveModalData,
  updateModalData,
} from "../../../../../accessories/component_infos";
import Setups_Template from "../../../../../human-resource/setups/.setups_templates/Setups_Template";
import {
  exprsTypes,
  modalTypes,
  searchCriteriaTypes,
  searchTextTypes,
  selectExprsTypes,
  selectsTypes,
} from "../../../../../human-resource/setups/data/types/selectsTypes";
import { assets_vehiclesmodelColumns } from "./data/vehicleModelData";
import { useBusyLoader } from "../../../../../../hooks/useBusyLoader";
import { WarningAlert } from "../../../../../accessories/warningAlert";
import dayjs from "dayjs";
import { UpdateDataFunc } from "../../../../../../functions/update";
import { PostDataFunc  } from "../../../../../../functions/post";
import { useAccountInfo } from "../../../../../../hooks/useAccountInfo";
import { useCrudFunc } from "../../../../../../functions/crud";
import { userInfo } from "os";

export const Assets_setups_vehicleModel = forwardRef(({ a }: any, ref) => {
  const postUrl: string = "AsmTmsVehicleModels/CreateAsmTmsVehicleModel";
  const updateUrl: string = "AsmTmsVehicleModels/UpdateAsmTmsVehicleModel";

  // selects
  const [manufacturerData, manufacturerError, manufacturerLoading] = useFetch(
    "AsmTmsManufacturers/GetAllManufacturerModel"
  );
  const [vehicleClassData, vehicleClassError, vehicleClassLoading] = useFetch(
    "AsmTmsVehicleClass/GetAllVehicleClass"
  );
  const [vehicleMakeData, vehicleMakeError, vehicleMakeLoading] = useFetch(
    "AsmTmsVehicleMakes/GetAllAsmTmsVehicleMake"
  );

  const saveSuccessText = {
    message: "Vehicle Model added Successfully",
    ok: "Ok",
    title: "Success",
  }; // successful save modal message
  const updateSuccessText = {
    message: "Vehicle Model updated Successfully",
    ok: "Ok",
    title: "Success",
  }; // successful update modal message

  const [busyLoader, setBusyLoader] = useBusyLoader();
  const [employeeId, userId,userInfo] = useAccountInfo();

  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState<boolean>(false); //refresh data state for data creation or update

  // store data expressions[unique] ...dependent on data coming in
  const idExpr: String = "mdlIdpk";
  const nameExpr: string = "mdlName";
  const shortnameExpr: string = "mdlShtName";
  const orderExpr: string = "mdlOrder";
  const activeExpr: string = "mdlActive";
  const manufacturerExpr: string = "mftIdpk";
  const remarksExpr: string = "mdlRmks";
  const inputsExprsData: any = {
    idExpr: idExpr,
    nameExpr: nameExpr,
    shortnameExpr: shortnameExpr,
    orderExpr: orderExpr,
    activeExpr,
    remarksExpr: remarksExpr,
  };
  const selectsExpr: selectExprsTypes[] = [
    { idExpr: manufacturerExpr, nameExpr: "mftName", label: "manufacturer" },
    { idExpr: "id", nameExpr: "name", label: "region" },
    { idExpr: "id", nameExpr: "name", label: "District" },
    { idExpr: "areIdpk", nameExpr: "areName", label: "area" },
    { idExpr: "areIdpk", nameExpr: "areName", label: "area" },
  ];
  const checkboxExprs: selectExprsTypes[] = [
    { idExpr: "RgnCode", nameExpr: "RgnCode" },
    { idExpr: "ctyCapital", nameExpr: "ctyCapital" },
    { idExpr: "areIdpk", nameExpr: "areName" },
    { idExpr: "areIdpk", nameExpr: "areName" },
    { idExpr: "areIdpk", nameExpr: "areName" },
  ];

  const storeExprs = () => {
    const exprs: exprsTypes[] = [
      { expr: "idExpr", value: idExpr },
      { expr: "nameExpr", value: nameExpr },
      { expr: "shortnameExpr", value: shortnameExpr },
      { expr: "selectExprs", value: selectsExpr[0] },
      { expr: "optionalNameValidation", value: "vehicleModel" },
      { expr: manufacturerExpr, value: "manufacturer" },
      { expr: "existsUrl", value: `AsmTmsVehicleModels` },
    ];
    for (let i = 0; i < exprs.length; i++) {
      dispatch(
        setTier2CheckboxValue({ expr: exprs[i].expr, value: exprs[i].value })
      );
    }

    // to general slice
    const generalExprs: exprsTypes[] = [
      { expr: "selectExprs", value: selectsExpr },
      { expr: "inputExprs", value: inputsExprsData },
      { expr: "checkboxExprs", value: checkboxExprs },
    ];
    for (let i = 0; i < generalExprs.length; i++) {
      dispatch(
        setGeneralValue({
          expr: generalExprs[i].expr,
          value: generalExprs[i].value,
        })
      );
    }
  };

  //modal states
  //--general--
  const triggerSuccessModal = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.successModalState
  ); //trigger success modal
  const SuccessModalText = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.successText
  ); //success modal text

  //--for backend errors--
  const [err, setErr] = useState<{
    state: boolean;
    title: string;
    message: string;
  }>({
    state: false,
    title: "",
    message: "",
  });

  //--warning modal--
  const triggerwarningModal = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.warningState
  ); //trigger warning
  const warningInfos: any = validationsData; //warning type details
  const warningDataExpr = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.warningDataExpr
  ); //warning type selector

  //--save data modal--
  const saveData = saveModalData; //save modal details[data]
  const triggersaveModal = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.saveDataOnclick
  ); //trigger save modal

  //--Update data modal--
  const updateIndInfos = updateModalData; //update modal details[data]
  const triggerupdateModal: boolean = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.updateDataOnclick
  ); //trigger update question

  // on page resize height responsiveness
  const [trigger, setTrigger] = useState<boolean>(false);
  useImperativeHandle(ref, () => ({
    test: () => {
      setTrigger(!trigger);
    },
  }));

  // get current values from  [slice dependent on form type]
  const manufacturer = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.select0
  );
  const vehicleClass = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.select1
  );
  const vehicleMake = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.select2
  );
  const name = useSelector((state: RootState) => state.tier2Ckeckboxforms.name); //model
  const shortname = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.shortname
  );
  const order = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.order
  );
  const active = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.active
  );
  const remarks = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.remarks
  );
  const selectedID = useSelector(
    (state: RootState) => state.general.SelectedFieldID
  );

  // selects validation
  const validateFields = () => {
    if (!manufacturer) {
      setErr({
        state: true,
        title: "Select Manufacturer",
        message: "Please Select Manufacturer",
      });
      return false;
    } else if (!vehicleClass) {
      setErr({
        state: true,
        title: "Select Vehicle Class",
        message: "Please Select Vehicle Class",
      });
      return false;
    } else if (!vehicleMake) {
      setErr({
        state: true,
        title: "Select Vehicle Make",
        message: "Please Select Vehicle Make",
      });
      return false;
    }

    return true;
  };

  //post form Data
  //validate data
  const validateDataSave = () => {
    if (validateFields()) {
      dispatch(tier2CheckboxSaveOnclick(""));
    }
  };

  const [Posting, Updating, Patching] = useCrudFunc()

  // post
  const postData = async () => {
    dispatch(setTier2CheckboxValue({ expr: "saveDataOnclick", value: false })); //disable modal
    try {
      setBusyLoader("Saving record...");

      await Posting (`${postUrl}`, {
        mdlName: name,
        mdlShtName: shortname,
        mdlManufacturerIdfk: manufacturer,
        mdlVehicleMakeIdfk: vehicleMake,
        mdlOrder: order,
        mdlActive: active,
        mdlRmks: remarks,
        mdlCreatedBy: userId,
      },`Created Vehicle Model ${name} with short name ${shortname} , manufacturer -${manufacturer} and Vehicle Make -${vehicleMake} Successfully , created by ${userInfo.empName} on ${dayjs().format()}`);

      setRefresh((current) => !current);
      setBusyLoader(""); //disable busy loader

      const exprs: exprsTypes[] = [
        { expr: "saveDataOnclick", value: false },
        { expr: "successModalState", value: true },
        { expr: "successText", value: saveSuccessText },
      ];
      for (let i = 0; i < exprs.length; i++) {
        dispatch(
          setTier2CheckboxValue({ expr: exprs[i].expr, value: exprs[i].value })
        );
      }

      //disable form
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    } catch (error: any) {
      setBusyLoader("");

      //set states for db errors modal
      setErr({
        state: true,
        title: saveModalData.error.title,
        message: saveModalData.error.message,
      });
    }
  };

  //Update Form data
  //validate data
  const validateDataUpdate = () => {
    if (validateFields()) {
      dispatch(tier2CheckboxUpdateOnclick(""));
    }
  };
  // update
  const UpdateData = async () => {
    dispatch(
      setTier2CheckboxValue({ expr: "updateDataOnclick", value: false })
    ); //disable modal
    try {
      setBusyLoader("Updating record...");

      await Updating(`${updateUrl}`, {
        mdlIdpk: selectedID,
        mdlName: name,
        mdlShtName: shortname,
        mdlManufacturerIdfk: manufacturer,
        mdlVehicleMakeIdfk: vehicleMake,
        mdlOrder: order,
        mdlActive: active,
        mdlRmks: remarks,
        mdlEditedBy: userId,
        mdlEditedDate: dayjs().format(),
      },`Updated Vehicle Model to ${name} with short name ${shortname} , manufacturer -${manufacturer} and Vehicle Make -${vehicleMake} Successfully , Edited by ${userInfo.empName} on ${dayjs().format()}`);

      // refresh datagrid data
      setRefresh((current) => !current);

      setBusyLoader(""); //disable busy loader

      //disable update mode
      dispatch(setGeneralValue({ expr: "updateMode", value: false })); // dispatch fetched data to reduxstore

      const exprs: exprsTypes[] = [
        { expr: "updateDataOnclick", value: false },
        { expr: "successModalState", value: true },
        { expr: "successText", value: updateSuccessText },
      ];
      for (let i = 0; i < exprs.length; i++) {
        dispatch(
          setTier2CheckboxValue({ expr: exprs[i].expr, value: exprs[i].value })
        );
      }
    } catch (error: any) {
      setBusyLoader(""); //disable busy loader

      //set states for db errors modal
      if (error.response.status === 500) {
        setErr({
          state: true,
          title: "Server Error",
          message: "Record update failed. Please contact system administrator",
        });

        return;
      }

      setErr({
        state: true,
        title: updateModalData.error.title,
        message: updateModalData.error.message,
      });
    }
  };

  //modals data
  const modals: modalTypes[] = [
    //warning
    {
      disableCancel: true,
      icon: "warning",
      open: triggerwarningModal,
      okHandler: () =>
        dispatch(setTier2CheckboxValue({ expr: "warningState", value: false })),
      cancelHandler: () =>
        dispatch(setTier2CheckboxValue({ expr: "warningState", value: false })),
      message: warningInfos[warningDataExpr!].message,
      cancelText: warningInfos[warningDataExpr!].cancelText,
      okText: warningInfos[warningDataExpr!].okText,
      title: warningInfos[warningDataExpr!].title,
    },
    //backend error
    {
      disableCancel: true,
      icon: "warning",
      open: err.state,
      okHandler: () => setErr((prev) => ({ ...prev, state: false })),
      cancelHandler: () => setErr((prev) => ({ ...prev, state: false })),
      message: err.message,
      cancelText: "",
      okText: "Ok",
      title: err.title,
    },
    //success
    {
      disableCancel: true,
      icon: "success",
      open: triggerSuccessModal,
      okHandler: () =>
        dispatch(
          setTier2CheckboxValue({ expr: "successModalState", value: false })
        ),
      cancelHandler: () =>
        dispatch(
          setTier2CheckboxValue({ expr: "successModalState", value: false })
        ),
      message: SuccessModalText.message,
      okText: SuccessModalText.okText,
      title: SuccessModalText.title,
    },
    //save
    {
      disableCancel: false,
      icon: "question",
      open: triggersaveModal,
      okHandler: () => postData(),
      cancelHandler: () =>
        dispatch(
          setTier2CheckboxValue({ expr: "saveDataOnclick", value: false })
        ),
      message: saveData.message,
      cancelText: saveData.cancelText,
      okText: saveData.okText,
      title: saveData.title,
    },
    //Update
    {
      disableCancel: false,
      icon: "question",
      open: triggerupdateModal,
      okHandler: () => UpdateData(),
      cancelHandler: () =>
        dispatch(
          setTier2CheckboxValue({ expr: "updateDataOnclick", value: false })
        ),
      message: updateIndInfos.message,
      cancelText: updateIndInfos.cancelText,
      okText: updateIndInfos.okText,
      title: updateIndInfos.title,
    },
  ];

  const [status, setActive] = useState<{
    temp: boolean | string;
    active: boolean | string;
  }>({
    temp: "",
    active: "",
  });
  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });
  const [searchIndex, setSearchIndex] = useState<searchCriteriaTypes>({
    index: 0,
    temp: 0,
  });

  // data for datagrid
  const [data, error, loading] = useFetch(
    `AsmTmsVehicleModels/GetAllVwAsmTmsVehicleModels?criteriaIndex=${searchIndex.index}&isActive=${status.active}&mdlName=${searchText.text}`,
    refresh,
    "mdlIdpk"
  );

  useEffect(() => {
    if (data) {
      storeExprs(); // run func to store expressions on store
      dispatch(setGeneralValue({ expr: "idExpr", value: idExpr })); // dispatch fetched data to reduxstore
    }

    loading ? setBusyLoader(".") : setBusyLoader("");
  }, [loading, data]);

  // form parameters
  const form_header = "Vehicle Model Details";

  const selects: selectsTypes[] = [
    {
      id: 0,
      label: "Manufacturer",
      optionsData: manufacturerData,
      dataExpr: "mftName",
      idExpr: "mftIdpk",
      diffExprs: { idKey: "mftIDpk", valueKey: "mftName" },
    },
    {
      id: 1,
      label: "Vehicle Class",
      optionsData: vehicleClassData,
      dataExpr: "vhcName",
      idExpr: "vhcIdpk",
      diffExprs: { idKey: "vhcIDpk", valueKey: "vhcName" },
    },
    {
      id: 2,
      label: "Vehicle Make",
      optionsData: vehicleMakeData,
      dataExpr: "vmkName",
      idExpr: "vmkIdpk",
      diffExprs: { idKey: "vmkIDpk", valueKey: "vmkName" },
    },
  ];

  const searchCriteria = [
    { id: 0, value: "Model" },
    { id: 1, value: "Vehicle Make" },
    { id: 2, value: "Vehicle Class" },
    { id: 3, value: "Manufacturer" },
  ];
  return (
    <>
      {error && <WarningAlert />}

      {modals.map(
        (
          {
            disableCancel,
            icon,
            okHandler,
            cancelHandler,
            open,
            cancelText,
            okText,
            message,
            title,
          }: modalTypes,
          index
        ) => {
          return (
            <div key={index}>
              <ModalTemplate
                icon={icon && icon}
                disableCancel={disableCancel}
                cancelText={cancelText && cancelText}
                open={open}
                okHandler={okHandler}
                cancelHandler={cancelHandler}
                message={message}
                okText={okText}
                title={title}
              />
            </div>
          );
        }
      )}

      <Setups_Template
        optionalnamelabel={"Model"}
        formTier="tier2checkbox"
        selects={selects}
        searchCriteria={searchCriteria}
        searchCriteriaChange={(index, criteria) => {
          setSearchIndex({ temp: index, index: 0 });
        }}
        handleRefresh={() => {
          setRefresh(!refresh);
          setSearchText({ text: "", temp: "" });
          setSearchIndex({ temp: 0, index: 0 });
          setActive({ temp: "", active: "" });
        }}
        checkOnChange={(value) => {
          setActive({ temp: value, active: status.active }); //store locally
          dispatch(setGeneralValue({ expr: "activeStatus", value: value })); //push value to redux
        }}
        searchTextChange={(newText) => {
          setSearchText({ temp: newText, text: searchText.text });
        }}
        handleSearch={() => {
          setSearchIndex({ temp: searchIndex.temp, index: searchIndex.temp });
          setSearchText({ text: searchText.temp, temp: searchText.temp });
          setActive({ temp: status.temp, active: status.temp });
          setRefresh((current) => !current);
        }}
        trigger={trigger}
        datagridColumns={assets_vehiclesmodelColumns}
        datagridData={data}
        formHeader={form_header}
        datagridClicked={(event: any) => {}}
        createData={validateDataSave}
        updateData={validateDataUpdate}
      />
    </>
  );
});
