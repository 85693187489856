export const assets_workShopDetailsColumns = [
  {
    id: 0,
    caption: "NAME",
    dataField: "wsdName",
    datatype: "string",
    width: 140,
    fixed: true,
    visible: true,
  },
  {
    id: 1,
    caption: "SHORT NAME",
    dataField: "wsdShtName",
    datatype: "string",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "CODE",
    dataField: "wsdCode",
    datatype: "string",
    width: 80,
    fixed: true,
    visible: true,
  },
  {
    id: 4,
    caption: "CONTACT PERSON",
    dataField: "wsdContact",
    datatype: "string",
    width: 140,
    fixed: true,
    visible: true,
  },
  {
    id: 5,
    caption: "EXTENSION/PLCs",
    dataField: "wsdPlc",
    datatype: "string",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 6,
    caption: "TEL. Nos",
    dataField: "wsdTelNo",
    datatype: "string",
    width: 90,
    fixed: true,
    visible: true,
  },
  {
    id: 7,
    caption: "TOWN",
    dataField: "twnName",
    datatype: "string",
    width: 90,
    fixed: true,
    visible: true,
  },
  {
    id: 8,
    caption: "LOCATION",
    dataField: "locName",
    datatype: "string",
    width: 95,
    fixed: true,
    visible: true,
  },
  {
    id: 9,
    caption: "EXTERNAL?",
    dataField: "wsdExternal",
    datatype: "boolean",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 10,
    caption: "ACTIVE?",
    dataField: "wsdActive",
    datatype: "boolean",
    width: 80,
    fixed: true,
    visible: true,
  },
  {
    id: 11,
    caption: "REMARKS",
    dataField: "wsdRmks",
    datatype: "string",
    width: 5000,
    fixed: false,
    visible: true,
  },
 ];
