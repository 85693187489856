/* eslint-disable react/jsx-pascal-case */
import { Form } from "antd";
import { useCallback, useEffect, useImperativeHandle, useState } from "react";
import Datagrid_template from "../../../../../../../templates/Datagrid";
import { InputsTemplate } from "../../../../../../../templates/input";
import { SaveButton } from "../../../../../../accessories/buttons";
import useFetch from "../../../../../../../hooks/useFetch";
import {
  postError,
  serverError,
} from "../../../../../../accessories/component_infos";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { FaBinoculars } from "react-icons/fa";
import {
  modalPropTypes,
  searchTextTypes,
  selectObjectTypes,
} from "../../../../../../human-resource/setups/data/types/selectsTypes";
import dayjs, { Dayjs } from "dayjs";
import {
  staffsColumns,
  workshopsColumns,
} from "../data/workshopAttendantsData";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { useBusyLoader } from "../../../../../../../hooks/useBusyLoader";
import { GetDataFunc } from "../../../../../../../functions/get";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import { useCrudFunc } from "../../../../../../../functions/crud";

interface Prop {
  updateMode: boolean;
  refresh: () => void;
  staffDatagrid: (fetchDone: boolean) => void;
}
interface stateTypes {
  remark: string;
  manager: boolean;
  view: boolean;
  authorize: boolean;
  active: boolean;
  //form state
  disableForm: boolean;
  modalState: modalPropTypes;
  confirmState: modalPropTypes;
  successModal: modalPropTypes;
  fuelDumpData: any;

  staffSearch?: searchTextTypes;
  workshopSearch?: searchTextTypes;
  selectedStaffs: any[];
  stations: any[];
  assignedWorkshops: any[];
  refreshAssignedWorkshops: boolean;
}
export const workshopAttendantsForm = React.forwardRef(
  ({ updateMode, refresh: refreshGrid, staffDatagrid }: Prop, ref) => {
    const [userId] = useAccountInfo();
    const [busyLoader, setBusyLoader] = useBusyLoader();
    const borderTheme = useSelector(
      (state: RootState) => state.general.cssBorderClass
    );
    const [privType, setPrivType] = useState<string>("save");
    const [savePriv] = useFormPriviledge(privType);
    const [Posting, Updating, PatchDataFunc] = useCrudFunc();

    const [states, setState] = useState<stateTypes>({
      fuelDumpData: [],
      remark: "",
      manager: false,
      view: false,
      authorize: false,
      disableForm: true,
      active: true,
      staffSearch: { text: "", temp: "" },
      workshopSearch: { text: "", temp: "" },

      modalState: {
        state: false,
        title: "",
        message: "",
      },
      successModal: {
        state: false,
        title: "",
        message: "",
      },
      confirmState: {
        state: false,
        title: "",
        message: "",
      },
      selectedStaffs: [],
      stations: [],
      assignedWorkshops: [],
      refreshAssignedWorkshops: false,
    });
    const {
      staffSearch,
      selectedStaffs,
      workshopSearch,
      stations,
      assignedWorkshops,
      refreshAssignedWorkshops,
    } = states;
    const updateState = (
      stateName: string,
      value:
        | string
        | boolean
        | number
        | modalPropTypes
        | selectObjectTypes
        | Dayjs
        | Object
        | searchTextTypes
    ) => {
      setState((prevstate) => ({ ...prevstate, [stateName]: value }));
    };

    useImperativeHandle(
      ref,
      () => {
        return {
          toggle: (selected: any) => {
            ToggleStatus("validate", selected);
          },
          toggleAll: (selected: any) => {
            toggleAllStatus("validate", selected);
          },
          setSelectedRecord: (selected: any) => {
            updateState("selectedRecordData", selected);
          },
        };
      },
      []
    );

    const saveAccessPriv = () => {
      setPrivType("Save");

      if (savePriv === true) {
        validate("post");
      } else {
        updateState("modalState", {
          state: true,
          title: "Access Denied!",
          message: "No Privilege Access ",
        });
      }
    };

    const ToggleStatus = async (
      action: "toggle" | "validate",
      selected: any
    ) => {
      if (action === "validate") {
        if (
          !selected ||
          typeof selected !== "object" ||
          Object.keys(selected).length < 1
        ) {
          updateState("modalState", {
            state: true,
            title: "Select Assignment",
            message: "Please select an assignment to toggle active status",
          });
          return;
        }

        updateState("confirmState", {
          state: true,
          title: "Toggle Status?",
          okText: "Yes, Toggle",
          message: `Are you sure you want to toggle the active status of ${selected?.empName ?? "the selected record"
            } to ${selected?.waaActive !== undefined ||
              selected?.waaActive !== null ||
              selected?.waaActive !== ""
              ? !selected?.waaActive
              : false
            }?`,
          func() {
            ToggleStatus("toggle", selected);
          },
          width: 375,
        });
        return;
      }

      try {
        disableModal();

        await Updating("MaintenanceRequestPrivilege/ToggleStatus", {
          waaIDpk: selected?.waaIDpk,
          waaActive: !selected?.waaActive,
          waaEditedDate: dayjs().format(),
        }, `Update the workshipAttendantForm with  waaActive:${!selected?.waaActive} Date Edited:${dayjs().format()}. Edited By:${userId}`);


        setBusyLoader("Toggling Active Status...");

        refreshGrid();
        updateState("successModal", {
          state: true,
          title: "Toggle Successful",
          message: "Active status of selected assignment toggled successfully",
        });
      } catch (error) {
        updateState("modalState", {
          state: true,
          title: "Save Failed",
          message:
            "Failed to toggle active status. Please retry or contact your Systems Administrator",
        });
      } finally {
        setBusyLoader("");
      }
    };

    const toggleAllStatus = async (
      action: "validate" | "toggleAll",
      selected: any
    ) => {
      if (action === "validate") {
        if (
          !selected ||
          typeof selected !== "object" ||
          Object.keys(selected).length < 1
        ) {
          updateState("modalState", {
            state: true,
            title: "Select Assignment",
            message: "Please select an assignment to toggle all active status",
          });
          return;
        }

        updateState("confirmState", {
          state: true,
          title: "Toggle Status?",
          okText: "Yes, Toggle",
          message: `Are you sure you want to toggle the active status of all assignments for ${selected?.empName ?? "the selected record"
            } to ${selected?.waaActive !== undefined ||
              selected?.waaActive !== null ||
              selected?.waaActive !== ""
              ? !selected?.waaActive
              : false
            }?`,
          func() {
            toggleAllStatus("toggleAll", selected);
          },
          width: 375,
        });
        return;
      }

      try {
        disableModal();
        setBusyLoader("Toggling Active Status...");
        console.log(selected);
        await PatchDataFunc("MaintenanceRequestPrivilege/ToogleAllActive", {
          waaEmpIDfk: selected?.waaEmpIDfk,
          waaActive: !selected?.waaActive,
        }, `Update the workshipAttendantForm with empId: ${selected?.waaEmpIDfk} waaActive:${!selected?.waaActive} Date Edited:${dayjs().format()}. Edited By:${userId}`);

        refreshGrid();
        updateState("successModal", {
          state: true,
          title: "Toggle Successful",
          message:
            "Active status of all assignments for the selected record toggled successfully",
        });
      } catch (error) {
        updateState("modalState", {
          state: true,
          title: "Save Failed",
          message:
            "Failed to toggle active status. Please retry or contact your Systems Administrator",
        });
      } finally {
        setBusyLoader("");
      }
    };

    useEffect(() => {
      updateState("disabledMode", !updateMode);
    }, [updateMode]);

    const [addedSelected, setAddSelected] = useState<any>({});

    const disableModal = () => {
      updateState("confirmState", {
        state: false,
        title: "",
        message: "",
        event: () => { },
      });
    };

    const appendColumns = (data: any) => {
      const temp: any = data;
      for (let j = 0; j < temp.length; j++) {
        temp[j] = {
          ...temp[j],
          waaCancelRequests: false,
          waaScheduleAlerts: false,
          waaOpenJobCards: false,
          waaManageJobDetails: false,
          waaManageMaterials: false,
          waaManageWorkOrders: false,
          waaManageInvoices: false,
          waaCMASU: false,
          waaManageUpdates: false,
          waaSeeStatistics: false,
          waaApproveRequests: false,
          waaGetSms: false,
          waaView: false,
          waaRmks: "",
        };
      }
      return temp;
    };

    const [Empdata, EmpError, EmpLoading] = useFetch(
      `MaintenanceRequestPrivilege/GetStaffDetails?search=${staffSearch?.text}`
    );

    function generateRandomId() {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let randomText = "";

      for (let i = 0; i < 4; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomText += characters[randomIndex];
      }

      return randomText;
    }

    useEffect(() => {
      if (Empdata) {
        staffDatagrid(true);
      }
    }, [Empdata]);

    useEffect(() => {
      const getWorkshops = async () => {
        try {
          const empId = selectedStaffs[selectedStaffs.length - 1]?.empIDpk;
          // get assigned Stations
          const assignedResponse = await GetDataFunc(
            `MaintenanceRequestPrivilege/GetAssignedWorkkshops?empId=${empId}&search=${workshopSearch?.text}`
          );
          updateState("assignedWorkshops", assignedResponse.data);

          //get unassigned stations
          const unassignedResponse = await GetDataFunc(
            `MaintenanceRequestPrivilege/GetUnAssignedWorkkshops?empId=${empId}&search=${workshopSearch?.text}`
          );

          updateState("stations", [
            ...assignedResponse.data.map((item: any) => ({
              ...item,
              id: generateRandomId(),
            })),
            ...appendColumns(
              unassignedResponse.data.map((item: any) => ({
                ...item,
                id: generateRandomId(),
              }))
            ),
          ]);
        } catch (error) {
          updateState("successModal", {
            state: true,
            title: serverError.title,
            message: serverError.message,
          });
        }
      };
      selectedStaffs.length > 0 && getWorkshops();
    }, [selectedStaffs, workshopSearch?.text, refreshAssignedWorkshops]);

    const implementRowColouring = useCallback((e: any) => {
      if (e.data?.waaEmpIDfk !== undefined) {
        return (e.rowElement.style.backgroundColor =
          "rgb(134 239 172 / var(--tw-bg-opacity))");
      } else {
        e.rowElement.style.backgroundColor =
          "rgb(240 181 5 / var(--tw-bg-opacity))";
      }
      // }
    }, []);

    const colorCell = (e: any) => {
      if (
        (e.columnIndex === 0 || e.columnIndex === 1) &&
        e.rowIndex >= assignedWorkshops.length
      ) {
        return (e.cellElement.style.backgroundColor =
          "rgb(240 181 5 / var(--tw-bg-opacity))");
      }

      if ((e.columnIndex === 1 || e.columnIndex === 9) && e.rowIndex > -1) {
        return (e.cellElement.style.backgroundColor = "rgb(156,154,154");
      }
    };

    const validate = (method: string) => {
      if (method === "post") {
        updateState("confirmState", {
          state: true,
          title: "Save Record?",
          message: "Are you sure you want to save this record?",
          async func() {
            await PostData();
          },
        });
      } else {
        updateState("confirmState", {
          state: true,
          title: "Confirm",
          message: "Are you sure you want to update record?",
          event: () => {
            // putData();
          },
        });
      }
    };

    //create for the secound option
    const PostData = async () => {
      setBusyLoader("Saving record(s)...");

      disableModal();

      try {
        for (let staff of selectedStaffs) {
          const assignmentsData = stations;
          for (let assignment of assignmentsData) {
            //check if workshop exists
            const exists = assignedWorkshops.find(
              (workshop) => workshop.waaIDpk === assignment?.waaIDpk
            );
            //wsdIDpk
            if (exists !== undefined) {
              await Updating(
                `MaintenanceRequestPrivilege/UpdateV2?waaIDpk=${exists.waaIDpk}`,
                {
                  waaEmpIdfk: staff?.empIDpk,
                  waaWorkshopIdfk: assignment?.wsdIDpk,
                  waaManage: false,
                  waaView: assignment?.waaView,
                  waaCancelRequests: assignment?.waaCancelRequests,
                  waaScheduleAlerts: assignment?.waaScheduleAlerts,
                  waaOpenJobCards: assignment?.waaOpenJobCards,
                  waaManageJobDetails: assignment?.waaManageJobDetails,
                  waaManageMaterials: assignment?.waaManageMaterials,
                  waaManageWorkOrders: assignment?.waaManageWorkOrders,
                  waaManageInvoices: assignment?.waaManageInvoices,
                  waaCMASU: assignment?.waaCMASU,
                  waaManageUpdates: assignment?.waaManageUpdates,
                  waaSeeStatistics: assignment?.waaSeeStatistics,
                  waaApproveRequests: assignment?.waaApproveRequests,
                  waaGetSms: assignment?.waaGetSms,
                  waaRmks: assignment?.waaRmks,
                },
                `Update the workshipAttendantForm with  waaScheduleAlerts:${assignment?.waaScheduleAlerts}, waaSeeStatistics:${assignment?.waaSeeStatistics}, waaView:${assignment?.waaView} waaOpenJobCards:${assignment?.waaManageJobDetails} Date Edited:${dayjs().format()}. Edited By:${userId}`
              );
            } else {
              if (
                assignment?.waaView ||
                assignment?.waaCancelRequests ||
                assignment?.waaScheduleAlerts ||
                assignment?.waaOpenJobCards ||
                assignment?.waaManageJobDetails ||
                assignment?.waaManageMaterials ||
                assignment?.waaManageWorkOrders ||
                assignment?.waaManageInvoices ||
                assignment?.waaCMASU ||
                assignment?.waaManageUpdates ||
                assignment?.waaSeeStatistics ||
                assignment?.waaApproveRequests ||
                assignment?.waaGetSms
              ) {
                await Posting(`MaintenanceRequestPrivilege/Create`, {
                  waaEmpIdfk: staff?.empIDpk,
                  waaWorkshopIdfk: assignment?.wsdIDpk,
                  waaManage: false,
                  waaView: assignment?.waaView,
                  waaCancelRequests: assignment?.waaCancelRequests,
                  waaScheduleAlerts: assignment?.waaScheduleAlerts,
                  waaOpenJobCards: assignment?.waaOpenJobCards,
                  waaManageJobDetails: assignment?.waaManageJobDetails,
                  waaManageMaterials: assignment?.waaManageMaterials,
                  waaManageWorkOrders: assignment?.waaManageWorkOrders,
                  waaManageInvoices: assignment?.waaManageInvoices,
                  waaCMASU: assignment?.waaCMASU,
                  waaManageUpdates: assignment?.waaManageUpdates,
                  waaSeeStatistics: assignment?.waaSeeStatistics,
                  waaApproveRequests: assignment?.waaApproveRequests,
                  waaGetSms: assignment?.waaGetSms,
                  waaActive: true,
                  waaRmks: assignment?.waaRmks,
                  waaCreatedBy: userId,
                },
                  `
                  Created the workshipAttendantForm with  waaScheduleAlerts:${assignment?.waaScheduleAlerts}, waaSeeStatistics:${assignment?.waaSeeStatistics}, waaView:${assignment?.waaView} waaOpenJobCards:${assignment?.waaManageJobDetails} Date Created:${dayjs().format()}. created By:${userId}

                  `
                );
              }
            }
          }
        }

        refreshGrid();

        updateState("successModal", {
          state: true,
          title: "Save Successful",
          message: "Record saved successfully",
        });

        updateState("refreshAssignedWorkshops", !refreshAssignedWorkshops);
      } catch (error) {
        updateState("modalState", {
          state: true,
          title: "Save Failed",
          message: postError.message,
        });
      } finally {
        setBusyLoader("");
      }
    };

    return (
      <div className="px-2 ">
        <ModalTemplate
          disableCancel={true}
          title={states.modalState.title}
          okText={""}
          message={states.modalState.message}
          open={states.modalState.state}
          okHandler={() => {
            updateState("modalState", { state: false });
          }}
          cancelHandler={() => {
            updateState("modalState", { state: false });
          }}
        />
        <ModalTemplate
          icon="success"
          disableCancel={true}
          title={states.successModal.title}
          okText={""}
          message={states.successModal.message}
          open={states.successModal.state}
          okHandler={() => {
            updateState("successModal", { state: false });
          }}
          cancelHandler={() => {
            updateState("successModal", { state: false });
          }}
        />

        <ModalTemplate
          icon="question"
          title={states.confirmState.title}
          okText={"Yes, Save"}
          message={states.confirmState.message}
          open={states.confirmState.state}
          okHandler={states.confirmState.func!}
          cancelHandler={() => {
            updateState("confirmState", { state: false });
          }}
        />
        <p
          id="form_header"
          style={{ borderColor: borderTheme }}
          className="py-1 px-2 w-full border-[1px] border-b-0 rounded bg-slate-100"
        >
          <span className="font-medium">
            {"Workshop Attendants Assignment Details"}
          </span>
        </p>
        <Form
          style={{ borderColor: borderTheme }}
          className="w-full rounded flex justify-between px-2 py-1 border-[1px] border-t-[0px] border-slate-100"
          name="basic"
          wrapperCol={{ span: 25 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          size="small"
        >
          <section style={{ width: "calc(40% - 10px" }} className="w-full bg ">
            <Form.Item
              className=""
              name="Staff No"
              rules={[{ required: false }]}
            >
              <div className="">
                <div className="flex flex-row justify-end items-center">
                  <InputsTemplate
                    useCallbackFunc
                    orderOnchange={(value) =>
                      updateState("staffSearch", {
                        ...staffSearch,
                        temp: value,
                      })
                    }
                    placeholder={"Search"}
                    disabledStatus={false}
                    span
                  />
                  <span
                    onClick={() => {
                      updateState("staffSearch", {
                        temp: staffSearch?.temp,
                        text: staffSearch?.temp,
                      });
                    }}
                    style={{ borderColor: borderTheme }}
                    className=" h-full ml-1 flex items-center  justify-center px-1 py-0.5 hover:cursor-pointer hover:bg-slate-50 rounded border-2"
                  >
                    <FaBinoculars color="#007AFF" />
                  </span>
                </div>

                <Datagrid_template
                  selectionMode="multiple"
                  deselectFirstRow
                  disableGroupPanel
                  disableSearch
                  disablePaging
                  data={Empdata}
                  gridheight={275}
                  columns={staffsColumns}
                  onRowClick={(selected) => {
                    setAddSelected(selected);
                  }}
                  selectedItemsChange={(selectedArr) => {
                    updateState("selectedStaffs", selectedArr);
                  }}
                  showSelectionCheckBox="always"
                />
              </div>
            </Form.Item>
          </section>

          <section className=" " style={{ width: "calc(60%)" }}>
            <Form.Item
              className=""
              name="Staff No"
              rules={[{ required: false }]}
            >
              <div className="">
                <div className="flex flex-row justify-end items-center">
                  <InputsTemplate
                    useCallbackFunc
                    orderOnchange={(value) =>
                      updateState("workshopSearch", {
                        ...workshopSearch,
                        temp: value,
                      })
                    }
                    disabledStatus={false}
                    placeholder={"Search"}
                    span
                  />
                  <span
                    onClick={() => {
                      updateState("workshopSearch", {
                        temp: workshopSearch?.temp,
                        text: workshopSearch?.temp,
                      });
                    }}
                    style={{ borderColor: borderTheme }}
                    className=" h-full ml-1 flex items-center  justify-center px-1 py-0.5 hover:cursor-pointer hover:bg-slate-50 rounded border-2"
                  >
                    <FaBinoculars color="#007AFF" />
                  </span>
                </div>

                <Datagrid_template
                  selectionMode="single"
                  allowColumnEditing
                  disableGroupPanel
                  rowAlternationEnabled={false}
                  deselectFirstRow
                  selectedItemsChange={(selectedArr) => {
                    updateState("fuelDumpData", selectedArr);
                  }}
                  onRowPrepared={implementRowColouring}
                  cellColoringFxn={colorCell}
                  disableSearch
                  disablePaging
                  data={stations}
                  gridheight={275}
                  columns={workshopsColumns}
                />
              </div>

              <div className=" mt-1 flex justify-end">
                <SaveButton
                  disableButton={stations.length > 0 ? false : true}
                  handleSave={() => {
                    saveAccessPriv();
                  }}
                />
              </div>
            </Form.Item>
          </section>
        </Form>
      </div>
    );
  }
);

export default workshopAttendantsForm;
