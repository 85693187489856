import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetDataFunc } from "../../functions/get";


interface apiStates {
    maintenanceType : any | null;
    isLoading: boolean;
    isPending: boolean;
    isError: boolean;
}

var initialState: apiStates = {
    maintenanceType: [],
    isLoading: false,
    isPending: false,
    isError: false,
};

export const fetchMaintenanceType = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "maintenanceType/fetch",
    async (search: string) => {
        const res = await GetDataFunc(`AsmTmsVehicleMaintenanceRequest/GetMaintenanceTypes?PageNumber=1&PageSize=20&Search=${search}&active=true`);
        return res.data;
    }
);


const mainType = createSlice({
    name: 'apiMainType',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // menType
            .addCase(fetchMaintenanceType.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchMaintenanceType.fulfilled, (state, action) => {
                state.isLoading = false;
                state.maintenanceType = action.payload;
            })
            .addCase(fetchMaintenanceType.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
    },
});

export default mainType.reducer;
