import {
  Checkbox,
  DatePicker,
  DatePickerProps,
  Form,
  Select,
  Upload,
} from "antd";
import type { UploadChangeParam } from "antd/es/upload";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import TextArea from "antd/es/input/TextArea";
import { DataGrid, DropDownBox } from "devextreme-react";
import { Column, Selection } from "devextreme-react/data-grid";
import reset from "../../../../assets/reset.png";
import { useState, useEffect, useRef } from "react";
import save from "../../../../assets/floppy-disk.png";
import newIcon from "../../../../assets/new.png";
import dayjs from "dayjs";
import { station_cols } from "../data/transformers-data";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import image_loader from "../../../../assets/image_loader.gif";
import image_upload from "../../../../assets/image_upload.png";
import { RootState } from "../../../../app/store";
import {
  setGeneralValue,
  settask_footer_table_refresh,
  settask_footer_update,
  settask_form_disable,
} from "../../../../features/generalSlice";
import { task_footer_table_selected, task_footer_update, task_form_disable } from "../../../../features/Task&Todo/Task/TaskDetails";
import useFetch from "../../../../hooks/useFetch";
import { ModalTemplate } from "../../../../templates/modal";
import {
  SaveButton,
  UpdateButton,
  NewButton,
  Cancel,
} from "../../../accessories/buttons";
import {
  navbar_height,
  toolbar_height,
  saveModalData,
  api_url,
  updateModalData,
  api_base_url,
  currentDate,
} from "../../../accessories/component_infos";
import { formPropsConst } from "../../../accessories/constants";
import { ImageUpload } from "./imageUpload";
import InputSelect_template from "../../../../templates/InputSelect";
import { GetDataFunc } from "../../../../functions/get";
import { DropdownGrid_template } from "../../../../templates/DropdownGrid";
import { Dropdown_Datagrid_template } from "../../../../templates/DropdownDatagrid";
import { CheckboxTemlate } from "../../../../templates/checkbox";
import { RefreshButtonTemplate } from "../../../../templates/RefreshButtonTemplate";

interface stateTypes {
  transformerId?: { id: number; name: string };
  serialNoState?: { id: number; name: string };
  station?: { id: number; name: string };
  standardState?: { id: number; name: string };
  coolingTypeState?: { id: number; name: string };
  tapChangerState?: { id: number; name: string };
  correctionSymbolState?: { id: number; name: string };
  manufacturerState?: { id: number; name: string };
  supplierState?: { id: number; name: string };
  statusState?: { id: number; name: string };
  statusCheck?: boolean;
  locationState?: { id: number; name: string };
  installation?: any;
  commissioning?: any;
  decommissioning?: any;
  manDate?: any;
  supplyDate?: any;
  verified?: boolean;
  active?: boolean;
  readings?: boolean;
}
interface props {
  otherDeatailsStates?: any;
}

const BaseDetailsTab = ({ otherDeatailsStates }: props) => {
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );

  const sp = "\u00a0";

  const heights_out = navbar_height + news_flash_height + toolbar_height;

  //varying form height
  const form_height = 490;

  //Table Height
  const pageHeight = window.innerHeight - navbar_height; //height without navbar
  const [tableHeight, settableHeight] = useState(
    window.innerHeight - (heights_out + 70)
  ); //height without navbar and toolbar
  const [table_form_Height, settable_form_Height] = useState(
    window.innerHeight - heights_out
  ); //height without navbar and toolbar and form
  //************************************************************************************************* */
  //EndPoints
  const [transformerID] = useFetch(
    `TransformerDetails/PopCmbTransformerIDs`,
    false,
    undefined,
    undefined,
    "powerapp"
  );
  const [serialNo] = useFetch(
    `TransformerDetails/PopCmbSerialNos`,
    false,
    undefined,
    undefined,
    "powerapp"
  );
  const [standard] = useFetch(
    "TransformerDetails/PopCmbStandards",
    false,
    undefined,
    undefined,
    "powerapp"
  );
  const [coolingType] = useFetch(
    "TransformerDetails/PopCmbCoolingType",
    false,
    undefined,
    undefined,
    "powerapp"
  );
  const [tapchangertype] = useFetch(
    "TransformerDetails/PopCmbTapChangerTypes",
    false,
    undefined,
    undefined,
    "powerapp"
  );
  const [correctionSymbol] = useFetch(
    "TransformerDetails/PopCmbConnectionSymbols",
    false,
    undefined,
    undefined,
    "powerapp"
  );
  const [manufacturer] = useFetch(
    "TransformerDetails/PopCmbManufacturers",
    false,
    undefined,
    undefined,
    "powerapp"
  );
  const [supplier] = useFetch(
    "TransformerDetails/PopCmbSuppliers",
    false,
    undefined,
    undefined,
    "powerapp"
  );

  const [status] = useFetch(
    "TransformerDetails/PopCmbStatus",
    false,
    undefined,
    undefined,
    "powerapp"
  );
  const [equipmentLocation] = useFetch(
    "TransformerDetails/PopLueLocations",
    false,
    undefined,
    undefined,
    "powerapp"
  );

  const [showTable, setShowTable] = useState(true);
  const dateFormat = " DD MMM YYYY HH: mm";

  const dispatch = useDispatch();

  const editedDate = dayjs().format();

  const [states, setStates] = useState<stateTypes>({
    transformerId: { id: 0, name: "" },
    serialNoState: { id: 1, name: "" },
    station: { id: 0, name: "" },
    standardState: { id: 0, name: "" },
    coolingTypeState: { id: 0, name: "" },
    tapChangerState: { id: 0, name: "" },
    correctionSymbolState: { id: 0, name: "" },
    manufacturerState: { id: 0, name: "" },
    supplierState: { id: 0, name: "" },
    statusState: { id: 0, name: "" },
    statusCheck: false,
    locationState: { id: 0, name: "" },
    installation: editedDate,
    commissioning: editedDate,
    decommissioning: editedDate,
    manDate: editedDate,
    supplyDate: editedDate,
    verified: false,
    active: false,
    readings: false,
  });

  const updateState = (key: string, value: any) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };

  console.log("states", states);
  // Data from redux
  const update_state_change = useSelector(
    (state: RootState) => state.addTask.taskFooterUpdate
  );
  const form_disable = useSelector(
   (state: RootState) => state.general.formDisabled
 );
  const selectedRecord = useSelector(
    (state: RootState) => state.safetyManagerDetails.selectedRecord
  );
  //   const tw = useSelector((state: RootState) => state.safetyManagerDetails.tw);
  //   const tcr = useSelector((state: RootState) => state.safetyManagerDetails.tcr);
  //   const tcs = useSelector((state: RootState) => state.safetyManagerDetails.tcs);
  //   const imp = useSelector((state: RootState) => state.safetyManagerDetails.imp);
  //   const nt = useSelector((state: RootState) => state.safetyManagerDetails.nt);
  //   const ro = useSelector((state: RootState) => state.safetyManagerDetails.ro);
  //   const rv1 = useSelector((state: RootState) => state.safetyManagerDetails.rv1);
  //   const rv2 = useSelector((state: RootState) => state.safetyManagerDetails.rv2);
  //   const rv3 = useSelector((state: RootState) => state.safetyManagerDetails.rv3);
  //   const rp1 = useSelector((state: RootState) => state.safetyManagerDetails.rp1);
  //   const rp2 = useSelector((state: RootState) => state.safetyManagerDetails.rp2);
  //   const rp3 = useSelector((state: RootState) => state.safetyManagerDetails.rp3);
  //   const zh = useSelector((state: RootState) => state.safetyManagerDetails.zh);
  //   const zx = useSelector((state: RootState) => state.safetyManagerDetails.zx);
  //   const zy = useSelector((state: RootState) => state.safetyManagerDetails.zy);
  //   const resist = useSelector(
  //     (state: RootState) => state.safetyManagerDetails.resist
  //   );
  //   const rmks = useSelector(
  //     (state: RootState) => state.safetyManagerDetails.rmks
  //   );

  const populateFields = (clearFields?: boolean) => {
    // console.log(selectedData);

    const states = [
      {
        key: "transformerState",
        value: clearFields
          ? { id: 0, name: "" }
          : {
              id: selectedRecord[0]?.tfdTransformerId,
              name: selectedRecord[0]?.tfdTransformerId,
            },
      },
      {
        key: "serialNoState",
        value: clearFields
          ? { id: 0, name: "" }
          : {
              id: selectedRecord[0]?.tfdSerialNo,
              name: selectedRecord[0]?.tfdSerialNo,
            },
      },
      {
        key: "station",
        value: clearFields
          ? { id: 0, name: "" }
          : {
              id: selectedRecord[0]?.stnName,
              name: selectedRecord[0]?.stnName,
            },
      },
      {
        key: "standardState",
        value: clearFields
          ? { id: 0, name: "" }
          : { id: 0, name: selectedRecord[0]?.tfdStandard },
      },
      {
        key: "coolingTypeState",
        value: clearFields
          ? { id: 0, name: "" }
          : {
              id: selectedRecord[0]?.tfdCoolingType,
              name: selectedRecord[0]?.tfdCoolingType,
            },
      },
      {
        key: "tapChangerState",
        value: clearFields
          ? { id: 0, name: "" }
          : {
              id: selectedRecord[0]?.tfdTapChangerType,
              name: selectedRecord[0]?.tfdTapChangerType,
            },
      },
      {
        key: "correctionSymbolState",
        value: clearFields
          ? { id: 0, name: "" }
          : {
              id: selectedRecord[0]?.tfdConnectionSymbol,
              name: selectedRecord[0]?.tfdConnectionSymbol,
            },
      },
      {
        key: "manufacturerState",
        value: clearFields
          ? { id: 0, name: "" }
          : {
              id: selectedRecord[0]?.mftName,
              name: selectedRecord[0]?.mftName,
            },
      },
      {
        key: "supplierState",
        value: clearFields
          ? { id: 0, name: "" }
          : {
              id: selectedRecord[0]?.tfdSupplierIdfk,
              name: selectedRecord[0]?.splName,
            },
      },
      {
        key: "statusState",
        value: clearFields
          ? { id: 0, name: "" }
          : {
              id: selectedRecord[0]?.tfdStatusIdfk,
              name: selectedRecord[0]?.estName,
            },
      },
      {
        key: "locationState",
        value: clearFields
          ? { id: 0, name: "" }
          : {
              id: selectedRecord[0]?.tfdStatusIdfk,
              name: selectedRecord[0]?.talName,
            },
      },
      {
        key: "installation",
        value: clearFields ? "" : selectedRecord[0]?.tfdInstallationStartDate,
      },
      {
        key: "commissioning",
        value: clearFields ? "" : selectedRecord[0]?.tfdCommissionDate,
      },
      {
        key: "decommissioning",
        value: clearFields ? "" : selectedRecord[0]?.tfdDecommissionDate,
      },
      {
        key: "manDate",
        value: clearFields ? "" : selectedRecord[0]?.tfdManufactureDate,
      },
      {
        key: "supplyDate",
        value: clearFields ? "" : selectedRecord[0]?.tfdSupplyDate,
      },
      {
        key: "verified",
        value: clearFields ? "" : selectedRecord[0]?.tfdVerified,
      },
      { key: "active", value: clearFields ? "" : selectedRecord[0]?.tfdActive },
      {
        key: "readings",
        value: clearFields ? "" : selectedRecord[0]?.tfdShowInStationReadings,
      },
      {
        key: "statusCheck",
        value: clearFields ? "" : selectedRecord[0]?.tfdAssigned,
      },
    ];

    //set states
    for (let instance of states) updateState(instance.key, instance.value);
  };

  useEffect(() => {
    populateFields(false);
  }, [selectedRecord]);

  // const transformerID = 'api/AssetTamEntTransformerDetail/GetAssetTamLueTransformerId';
  // const serialNo = 'api/AssetTamEntTransformerDetail/GetAssetTamLueSerialNo';
  // const standard = 'api/AssetTamEntTransformerDetail/GetAssetTamLueStandard';
  // const station = 'api/AssetTamEntTransformerDetail/GetAssetTamLueStation'
  // const coolingType = 'api/AssetTamEntTransformerDetail/GetAssetTamLueCoolingType';
  // const tapChangerType = 'api/AssetTamEntTransformerDetail/GetAssetTamLueTapChangerType'
  // const connectionSymbol = 'api/AssetTamEntTransformerDetail/GetAssetTamLueConnectionSymbol'
  // const manufacturer = 'api/AssetTamEntTransformerDetail/GetAssetTamLueManufacturer'
  // const supplier = 'api/AssetTamEntTransformerDetail/GetAssetTamLueSupplier'
  // const status = 'api/AssetTamEntTransformerDetail/GetAssetTamLueStatus'
  // const equipLocation = 'api/AssetTamEntTransformerDetail/GetAssetTamLueEquimentLocation'

  // Endpoints comsuption
  const [transformersID_data, transformerID_error, transformerID_loading] =
    useFetch("api/AssetTamEntTransformerDetail/GetAssetTamLueTransformerId");
  const [serialNo_data, serialNo_error, serialNo_loading] = useFetch(
    "api/AssetTamEntTransformerDetail/GetAssetTamLueSerialNo"
  );
  const [station_data, station_error, station_loading] = useFetch(
    "api/AssetTamEntTransformerDetail/GetAssetTamLueStation"
  );
  const [standard_data, standard_error, standard_loading] = useFetch(
    "api/AssetTamEntTransformerDetail/GetAssetTamLueStandard"
  );
  const [coolingType_data, coolingType_error, coolingType_loading] = useFetch(
    "api/AssetTamEntTransformerDetail/GetAssetTamLueCoolingType"
  );
  const [tapChangerType_data, tapChangerType_error, tapChangerType_loading] =
    useFetch("api/AssetTamEntTransformerDetail/GetAssetTamLueTapChangerType");
  const [
    connectionSymbol_data,
    connectionSymbol_error,
    connectionSymbol_loading,
  ] = useFetch(
    "api/AssetTamEntTransformerDetail/GetAssetTamLueConnectionSymbol"
  );
  const [manufacturer_data, manufacturer_error, manufacturer_loading] =
    useFetch("api/AssetTamEntTransformerDetail/GetAssetTamLueManufacturer");
  const [supplier_data, supplier_error, supplier_loading] = useFetch(
    "api/AssetTamEntTransformerDetail/GetAssetTamLueSupplier"
  );
  const [status_data, status_error, status_loading] = useFetch(
    "api/AssetTamEntTransformerDetail/GetAssetTamLueStatus"
  );
  const [equipLocation_data, equipLocation_error, equipLocation_loading] =
    useFetch("api/AssetTamEntTransformerDetail/GetAssetTamLueEquimentLocation");

  // Modal dialig states
  const [refreshValue, setRefreshValue] = useState(false);
  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [updatingstatus, setUpdatingstatus] = useState(false);
  const [showCancel, setshowCancel] = useState(false);

  const [refresh, setRefresh] = useState(false);

  const clearFeids = () => {
    dispatch(task_footer_table_selected([[]]));
  };

  // Validation
  const validationForms = async () => {
    setIcon("warning");
    if (
      states.transformerId?.name === undefined ||
      states.transformerId.id === undefined ||
      states.transformerId?.name === "" ||
      states.transformerId.id === 0
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select data entry for TransformerID",
        title: "Required Field ",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else if (
      states.serialNoState.name === undefined ||
      states.serialNoState.id === undefined
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select data entry for Serial No",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else if (
      states.station.name === undefined ||
      states.station.id === undefined
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select data entry for Serial No",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else if (
      states.standardState.name === undefined ||
      states.standardState.id === undefined ||
      states.standardState === undefined
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please enter data for Standard",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else if (
      states.coolingTypeState.name === undefined ||
      states.coolingTypeState.id === undefined ||
      states.coolingTypeState === undefined
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please enter data for Cooling Type",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else if (
      states.tapChangerState.name === undefined ||
      states.tapChangerState.id === undefined ||
      states.tapChangerState === undefined
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please enter data for Tap Changer Type",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else if (
      states.correctionSymbolState.name === undefined ||
      states.correctionSymbolState.id === undefined ||
      states.correctionSymbolState === undefined
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please enter data for Correction Symbol",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else if (
      states.manufacturerState.name === undefined ||
      states.manufacturerState.id === undefined ||
      states.manufacturerState === undefined
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please enter data for Manufaturer",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else if (
      states.supplierState.name === undefined ||
      states.supplierState.id === undefined ||
      states.supplierState === undefined
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please enter data for Supplier",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else if (
      states.statusState.name === undefined ||
      states.statusState.id === undefined ||
      states.statusState === undefined
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please enter data for Correction Symbol",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else if (
      states.locationState?.id === undefined ||
      states.locationState?.name === undefined ||
      states.locationState === undefined
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please enter data for Equipment Location",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else if (
      states.commissioning === undefined ||
      states.commissioning === undefined
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please enter data for Commissioning Date",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else if (
      states.decommissioning === "" ||
      states.decommissioning === undefined
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please enter data for Decommissioning Date",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else if (
      states.installation === "" ||
      states.installation === undefined
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please enter data for Installation Date",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else if (states.manDate === "" || states.manDate === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please enter data for Manufacturer Date",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else if (states.supplyDate === "" || states.supplyDate === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please enter data for Supply Date",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else {
      setshowModalDisableButton(false);
      setIcon("question");
      setOpenModal(true);
    }
  };
  const validation = () => {
    const fields = [
      { value: otherDeatailsStates.tappedWinding, name: "Tapped Winding" },
      { value: otherDeatailsStates.tapChangeRange, name: "Tap Change Range" },
      { value: otherDeatailsStates.tapChangeStep, name: "Tap Change Step" },
      { value: otherDeatailsStates.deg, name: "Deg" },
      { value: otherDeatailsStates.normalTap, name: "Normal Tap" },
      { value: otherDeatailsStates.ratedOutput, name: "Rated Output" },
      { value: otherDeatailsStates.ratedVoltageI, name: "Rated Voltage I" },
      { value: otherDeatailsStates.ratedVoltageII, name: "Rated Voltage II" },
      { value: otherDeatailsStates.ratedVoltageIII, name: "Rated Voltage III" },
      { value: otherDeatailsStates.ratedPowerI, name: "Rated Power I" },
      { value: otherDeatailsStates.ratedPowerII, name: "Rated Power II" },
      { value: otherDeatailsStates.ratedPowerIII, name: "Rated Power III" },
      { value: otherDeatailsStates.zhBase, name: "ZH Base" },
      { value: otherDeatailsStates.zxBase, name: "ZX Base" },
      { value: otherDeatailsStates.zyBase, name: "ZY Base" },
      { value: otherDeatailsStates.resistanceBase, name: "Resistance Base" },
    ];

    for (const field of fields) {
      if (field.value === "") {
        setModalData({
          message: `Please input data entry for ${field.name} field!(from Other Details Tab)`,
          title: "Required Field",
          okText: "Ok",
        });
        setOpenModal(true);
        setIcon("warning");
        return;
      }
    }
  };
  const dropdownRef = useRef<any>(null);

  const close = () => {
    dropdownRef.current?.instance.close();
  };

  // const dataGridRender = (data: any, columnHeader: any, callBackFunction: any) => {
  //    return (
  //       <DataGrid
  //          height={'100%'}
  //          width={'100%'}
  //          style={{ maxHeight: '195px' }}
  //          columnWidth={80}
  //          //  keyExpr = {'stnIdpk'}
  //          columnResizingMode={'widget'}
  //          //  focusedRowEnabled={true}
  //          showColumnLines={true}

  //          showRowLines={true}
  //          wordWrapEnabled={false}
  //          allowColumnResizing={true}
  //          dataSource={data}
  //          onRowClick={(e: any) => {
  //             callBackFunction(e.data)
  //             console.log(e.data)
  //             close();
  //          }}
  //       // columns={gridColumns}
  //       // hoverStateEnabled={true}
  //       // selectedRowKeys={this.state.gridBoxValue}
  //       // onSelectionChanged={this.dataGridOnSelectionChanged}
  //       >
  //          {
  //             columnHeader?.map((reqBy: any) => {
  //                return (
  //                   <Column
  //                      key={reqBy.id}
  //                      dataType={reqBy.dataType}
  //                      caption={reqBy.caption}
  //                      dataField={reqBy.dataField}
  //                      alignment="left"
  //                      width={reqBy.width === null ? 45 : reqBy.width}
  //                      fixed={reqBy.fixed}

  //                   />
  //                )
  //             })
  //          }
  //          <Selection mode="single" />
  //          {/* <Scrolling mode="virtual" />
  //        <Paging enabled={true} pageSize={10} /> */}
  //          {/* <FilterRow visible={true} /> */}
  //       </DataGrid>
  //    );
  // }

  // Dates

  //  Booleans

  // Post

  const postData = async () => {
    try {
      const res = await axios.post(
        `${api_base_url}AssetTamEntTransformerDetail/CreateTransformerDetail`
        //   {
        //     tfdSerialNo: states.serialNoState,
        //     tfdTransformerId: states.transformerState,
        //     tfdStationIdfk: states.station?.id, //'stationState[0].stnIdpk'
        //     tfdLocationIdfk: states.locationState?.id,
        //     tfdStandard: states.standardState,
        //     tfdRatedVoltageI: rv1,
        //     tfdRatedVoltageIi: rv2,
        //     tfdRatedVoltageIii: rv3,
        //     tfdRatedPowerI: rp1,
        //     tfdRatedPowerIi: rp2,
        //     tfdRatedPowerIii: rp3,
        //     tfdRatedOutput: ro,
        //     tfdConnectionSymbol: states.correctionSymbolState,
        //     tfdCoolingType: states.coolingTypeState,
        //     tfdTapChangerType: states.tapChangerState,
        //     tfdTappedWinding: tw,
        //     tfdTapChangerRange: tcr,
        //     tfdTapChangerStep: tcs,
        //     tfdNominalTap: nt,
        //     tfdPercentImpedanceAt75Deg: imp,
        //     tfdZhAt100MvabaseAt75Deg: zh,
        //     tfdZxAt100MvabaseAt75Deg: zx,
        //     tfdZyAt100MvabaseAt75Deg: zy,
        //     tfdResistanceAt100MvabaseAt75Deg: resist,
        //     tfdCommissionDate: dayjs(states.commissioning).format(),
        //     tfdDecommissionDate: dayjs(states.decommissioning).format(),
        //     tfdManufactureDate: dayjs(states.manDate).format(),
        //     tfdInstallationStartDate: dayjs(states.installation).format(),
        //     tfdSupplyDate: dayjs(states.supplyDate).format(),
        //     tfdStatusIdfk: states.statusState?.id,
        //     tfdManufacturerIdfk:
        //       states !== undefined ? states.manufacturerState : 0, //states.manufacturerState,
        //     tfdSupplierIdfk: states.supplierState?.id,
        //     tfdBasicImpulseLevelHvLineKv: "",
        //     tfdBasicImpulseLevelHvNeutralKv: "",
        //     tfdBasicImpulseLevelHvMvKv: "",
        //     tfdBasicImpulseLevelHvLvKv: "",
        //     tfdAngles: "",
        //     tfdManufactureStandard: "",
        //     tfdOilTemperatureRiseDeg: "",
        //     tfdWindingTemperatureRiseDeg: "",
        //     tfdNamePlateData: "",
        //     tfdAssigned: states.statusCheck,
        //     tfdVerified: Verified,
        //     tfdShowInStationReadings: Readings,
        //     tfdActive: Active,
        //     tfdRmks: rmks,
        //     tfdCreatedBy: 1,
        //   }
      );

      // console.log(res)
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: "Data entry Updated successfully!",
        title: "Data Updated!",
        okText: "Ok",
      });
    } catch (err: any) {
      console.log(err);

      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: err.response ? err.response.data.message : "Server Error",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };

  const updateData = async () => {
    try {
      const res = await axios.put(
        `${api_url}/AssetTamEntTransformerDetail/UpdateTransformerDetail`
        //   {
        //     tfdIdpk: selectedRecord[0].tfdIdpk,
        //     tfdSerialNo: states.serialNoState,
        //     tfdTransformerId: states.transformerState,
        //     tfdStationIdfk: states.station?.id,
        //     tfdLocationIdfk: states.locationState?.id,
        //     tfdStandard: states.standardState,
        //     tfdRatedVoltageI: rv1,
        //     tfdRatedVoltageIi: rv2,
        //     tfdRatedVoltageIii: rv3,
        //     tfdRatedPowerI: rp1,
        //     tfdRatedPowerIi: rp2,
        //     tfdRatedPowerIii: rp3,
        //     tfdRatedOutput: ro,
        //     tfdConnectionSymbol: states.correctionSymbolState,
        //     tfdCoolingType: states.coolingTypeState,
        //     tfdTapChangerType: states.tapChangerState,
        //     tfdTappedWinding: tw,
        //     tfdTapChangerRange: tcr,
        //     tfdTapChangerStep: tcs,
        //     tfdNominalTap: nt,
        //     tfdPercentImpedanceAt75Deg: imp,
        //     tfdZhAt100MvabaseAt75Deg: zh,
        //     tfdZxAt100MvabaseAt75Deg: zx,
        //     tfdZyAt100MvabaseAt75Deg: zy,
        //     tfdResistanceAt100MvabaseAt75Deg: resist,
        //     tfdCommissionDate: dayjs(states.commissioning).format(),
        //     tfdDecommissionDate: dayjs(states.decommissioning).format(),
        //     tfdManufactureDate: dayjs(states.manDate).format(),
        //     tfdInstallationStartDate: dayjs(states.installation).format(),
        //     tfdSupplyDate: dayjs(states.supplyDate).format(),
        //     tfdStatusIdfk: states.statusState?.id,
        //     tfdManufacturerIdfk: 1, //states.manufacturerState,
        //     tfdSupplierIdfk: states.supplierState?.id,
        //     tfdBasicImpulseLevelHvLineKv: "",
        //     tfdBasicImpulseLevelHvNeutralKv: "",
        //     tfdBasicImpulseLevelHvMvKv: "",
        //     tfdBasicImpulseLevelHvLvKv: "",
        //     tfdAngles: "",
        //     tfdManufactureStandard: "",
        //     tfdOilTemperatureRiseDeg: "",
        //     tfdWindingTemperatureRiseDeg: "",
        //     tfdNamePlateData: "",
        //     tfdAssigned: states.statusCheck,
        //     tfdVerified: Verified,
        //     tfdShowInStationReadings: Readings,
        //     tfdActive: Active,
        //     tfdRmks: rmks,
        //     tfdEditedBy: 1,
        //     tfdEditedDate: editedDate,
        //   }
      );
      console.log(res);
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: "Data entry updated successfully!",
        title: "Data Updaated!",
        okText: "Ok",
      });
      setRefreshValue(!refreshValue);
      dispatch(settask_footer_table_refresh(setRefresh(!refresh)));

      setOpenModal(false);
    } catch (error: any) {
      console.log(error);
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: error.response ? error.response.data.message : "Server Error",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };

  // Image Upload

  const [loading, setLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [imageCon, setImageCon] = useState<any>([]);

  console.log(imageCon);

  // useEffect(() => {
  //    console.log(`inside ${toggled}`)

  //  console.log(station_data)

  // }, [toggled])

  const { Option } = Select;
  return (
    <div>
      <ModalTemplate
        disableCancel={icon === "warning" ? true : false}
        icon={icon}
        cancelText={modalData.cancelText}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        open={openModal}
        okHandler={() => {
          modalData === saveModalData
            ? postData()
            : modalData === updateModalData
            ? updateData()
            : setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
      />
      {
        <div className="  rounded-md">
          <style
            dangerouslySetInnerHTML={{
              __html: `
                    .dx-dropdowneditor-icon {
                        margin-top: 5px;
                    }
                    
                    .dx-dropdowneditor-icon::before {
                        content: "\f001";
                        position: absolute;
                        display: block;
                        width: 18px;
                        top: 50%;
                        margin-top: -5px;
                        left: 50%;
                        margin-left: -9px;
                    }
                    .dx-popup-wrapper {
                        width: 400px;
  

                    }
                    

                    .dx-overlay-content.dx-popup-normal.dx-resizable.dx-popup-flex-height{
                        width: 700px;
                        min-width: 700px;
                        
                    }

                    

                    
                    `,
            }}
          />

          {/* The form */}
          <div className=" flex ">
            <Form
              {...formPropsConst}
              wrapperCol={{ span: 18 }}
              labelCol={{ span: 10 }}
              className="ml-2 my-2 "
              style={{ width: "50%" }}
              disabled={form_disable}
            >
              {/* <Form.Item label={<p className='text-xs'>{'Start Date'}</p>} style={{ marginBottom: 0 }}>
                  <DatePicker showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }} format={dateFormat} style={{ display: 'inline-block', width: 'calc(65%)' }} />


               </Form.Item>

               <Form.Item label={<p className='text-xs'>{'End Date'}</p>} style={{ marginBottom: 0 }}>
                  <DatePicker showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }} style={{ display: 'inline-block', width: 'calc(65%)' }} format={dateFormat} />


               </Form.Item> */}
              <div>
                <InputSelect_template
                  label="Transformer ID"
                  options={transformerID}
                  idexpr="id"
                  dataexp="name"
                  useCallFunc
                  selectedValue={(e: any) => {
                    updateState("transformerId", { id: e.id, name: e.name });
                    console.log("e", e);
                  }}
                  placeHolder={states.transformerId?.name}
                />
              </div>

              <div>
                <InputSelect_template
                  label="Serial No"
                  options={serialNo}
                  idexpr="id"
                  dataexp="name"
                  useCallFunc
                  selectedValue={(e: any) => {
                    updateState("serialNoState", { id: e.id, name: e.name });
                    console.log("e", e);
                  }}
                  placeHolder={states.serialNoState?.name}
                />
              </div>

              <Form.Item
                label={<p className="text-xs">{"Station"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="receivedBy"
                  style={{ display: "inline-block", width: "calc(100%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <DropDownBox
                      hoverStateEnabled={true}
                      ref={dropdownRef}
                      // value={this.state.gridBoxValue}
                      style={{
                        width: "100%",
                        height: "23px",
                        textAlign: "center",
                      }}
                      valueExpr="ID"
                      deferRendering={false}
                      showClearButton={true}
                      openOnFieldClick={true}
                      value={states.station?.name}
                      placeholder={states.station?.name}
                      // contentRender={() =>
                      //    dataGridRender(
                      //       station_data,
                      //       station_cols, (e: any) => {
                      //          // setstationState([e])
                      //          console.log([e])
                      //          updateState('station', { id: e.stnIdpk!, name: e.stnName! })
                      //       }
                      //    )
                      // }
                    />

                    <span
                      onClick={() => {}}
                      style={{
                        height: 22.5,
                        marginBottom: 2,
                        borderWidth: 1,
                        width: 23.9,
                      }}
                      className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                    >
                      <img
                        className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                        src={reset}
                        alt="reset"
                      />
                    </span>
                  </div>
                </Form.Item>
              </Form.Item>

              <div>
                <InputSelect_template
                  label="Standard"
                  options={standard}
                  idexpr="id"
                  dataexp="name"
                  useCallFunc
                  selectedValue={(e: any) => {
                    updateState("standardState", { id: e.id, name: e.name });
                    console.log("e", e);
                  }}
                  placeHolder={states.standardState?.name}
                />
              </div>

              <div>
                <InputSelect_template
                  label="Cooling Type"
                  options={coolingType}
                  idexpr="id"
                  dataexp="name"
                  useCallFunc
                  selectedValue={(e: any) => {
                    updateState("coolingTypeState", { id: e.id, name: e.name });
                    console.log("e", e);
                  }}
                  placeHolder={states.coolingTypeState?.name}
                />
              </div>

              <div>
                <InputSelect_template
                  label="Tap changer Type"
                  options={tapchangertype}
                  idexpr="id"
                  dataexp="name"
                  useCallFunc
                  selectedValue={(e: any) => {
                    updateState("tapChangerSate", { id: e.id, name: e.name });
                    console.log("e", e);
                  }}
                  placeHolder={states.tapChangerState?.name}
                />
              </div>

              <div>
                <InputSelect_template
                  label="Correction Symbol"
                  options={correctionSymbol}
                  idexpr="id"
                  dataexp="name"
                  useCallFunc
                  selectedValue={(e: any) => {
                    updateState("correctionSymbolState", {
                      id: e.id,
                      name: e.name,
                    });
                    console.log("e", e);
                  }}
                  placeHolder={states.correctionSymbolState?.name}
                />
              </div>

              <div>
                <InputSelect_template
                  label="Manufacturer"
                  options={manufacturer}
                  idexpr="id"
                  dataexp="name"
                  useCallFunc
                  selectedValue={(e: any) => {
                    updateState("manufacturerState", {
                      id: e.id,
                      name: e.name,
                    });
                    console.log("e", e);
                  }}
                  placeHolder={states.manufacturerState?.name}
                />
              </div>

              <div>
                <InputSelect_template
                  label="Supplier"
                  options={supplier}
                  idexpr="id"
                  dataexp="name"
                  useCallFunc
                  selectedValue={(e: any) => {
                    updateState("supplierState", { id: e.id, name: e.name });
                    console.log("e", e);
                  }}
                  placeHolder={states.supplierState?.name}
                />
              </div>
              <Form.Item
                label={<p className="text-xs">{"Status"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="receivedBy"
                  style={{ display: "inline-block", width: "calc(100%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <Select
                      className=" mr-0 mb-0.5 py-0"
                      placeholder=""
                      onChange={(value: any) => {
                        updateState("statusState", {
                          id: JSON.parse(value)["id"],
                          name: JSON.parse(value)["name"],
                        });
                      }}
                      value={states.statusState?.name}
                      style={{ width: "85%" }}
                    >
                      {status?.map((s: any, index: number) => {
                        return (
                          <Option key={index} value={JSON.stringify(s)}>
                            {s.name}
                          </Option>
                        );
                      })}
                    </Select>
                    <span className="h-full w-4 ml-1 flex justify-center hover:cursor-pointer hover">
                      <Checkbox
                        className=""
                        checked={states.statusCheck}
                        onChange={(e) => {
                          updateState("statusState", e.target.checked);
                        }}
                      ></Checkbox>
                    </span>

                    <span
                      onClick={() => {
                        updateState("statusState", "");
                      }}
                      style={{
                        height: 22.5,
                        marginBottom: 2,
                        borderWidth: 1,
                        width: 24.2,
                      }}
                      className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                    >
                      <img
                        className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                        src={reset}
                        alt="reset"
                      />
                    </span>
                  </div>
                </Form.Item>
              </Form.Item>
              {/* <div className="flex w-full bg-red-300">
                <InputSelect_template
                  label="Status"
                  options={status}
                labelCol={24}
                  idexpr="id"
                  dataexp="name"
                  useCallFunc
                  selectedValue={(e:any)=>{
                     updateState('statusState', {id: e.id, name: e.name})
                     console.log("e",e)
                  }}
                  placeHolder={states.statusState?.name}
                  span
                  selectStyle={{width:150}}
                />
                <CheckboxTemlate/>
                <RefreshButtonTemplate handleRefresh={function (): void {
                          throw new Error("Function not implemented.");
                       } }/>
              </div> */}

              <div>
                <InputSelect_template
                  label="Equipment Location"
                  options={equipmentLocation}
                  idexpr="id"
                  dataexp="name"
                  useCallFunc
                  selectedValue={(e: any) => {
                    updateState("locationState", { id: e.id, name: e.name });
                    console.log("e", e);
                  }}
                  placeHolder={states.locationState?.name}
                />
              </div>
            </Form>

            <Form
              {...formPropsConst}
              labelCol={{ span: 11 }}
              wrapperCol={{ span: 11 }}
              className="ml-2 my-2 "
              style={{ width: "50%" }}
            >
              <Form.Item
                label={<p className="text-xs">{"Installation Start Date"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(100%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <DatePicker
                      placeholder={"Select Date here"}
                      onChange={(date, dateString) => {
                        updateState("installation", dateString);
                      }}
                      defaultValue={dayjs(currentDate)}
                      className="w-full"
                      format={dateFormat}
                      showTime={false}
                    />
                  </div>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={<p className="text-xs">{"Commissioning Date"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(100%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <DatePicker
                      placeholder={"Select Date here"}
                      onChange={(date, dateString) => {
                        updateState("commissioning", dateString);
                      }}
                      showTime={false}
                      className="w-full"
                      format={dateFormat}
                      defaultValue={dayjs(currentDate)}
                    />
                  </div>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={<p className="text-xs">{"Decommissioning Date"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(100%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <DatePicker
                      placeholder={"Select Date here"}
                      onChange={(date, dateString) => {
                        updateState("decommissioning", dateString);
                      }}
                      showTime={false}
                      className="w-full"
                      format={dateFormat}
                      defaultValue={dayjs(currentDate)}
                    />
                  </div>
                </Form.Item>
              </Form.Item>

              <Form.Item
                label={<p className="text-xs">{"Manufacture Date"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(100%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <DatePicker
                      placeholder={"Select Date here"}
                      onChange={(date, dateString) => {
                        updateState("manDate", dateString);
                      }}
                      showTime={false}
                      className="w-full"
                      format={dateFormat}
                      defaultValue={dayjs(currentDate)}
                    />
                  </div>
                </Form.Item>
              </Form.Item>

              <Form.Item
                label={<p className="text-xs">{"Supply Date"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(100%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <DatePicker
                      placeholder={"Select Date here"}
                      onChange={(date, dateString) => {
                        updateState("supplyDate", dateString);
                      }}
                      showTime={false}
                      className="w-full"
                      format={dateFormat}
                      defaultValue={dayjs(currentDate)}
                    />
                  </div>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={<p className="text-xs">{"Verified?"}</p>}
                style={{ marginBottom: 0 }}
              >
                <div className="flex justify-between ">
                  <Form.Item name="year" style={{ display: "inline-block" }}>
                    <div>
                      <Checkbox
                        checked={states.verified}
                        onChange={(e: any) => {
                          updateState("verified", e.target.checked);
                        }}
                      ></Checkbox>
                    </div>
                  </Form.Item>
                  <Form.Item
                    label={<p className="text-xs">{"Active?"}</p>}
                    name="month"
                    style={{ display: "inline-block" }}
                  >
                    <div>
                      <Checkbox
                        checked={states.active}
                        onChange={(e: any) => {
                          updateState("active", e.target.checked);
                        }}
                      ></Checkbox>
                    </div>
                  </Form.Item>
                </div>
              </Form.Item>
              <Form.Item
                style={{ display: "inline-block", marginBottom: "2px" }}
                label={<p className="text-xs">{"Show In Station Readings?"}</p>}
                className="w-full mb-1"
              >
                <div className=" items-end">
                  <div className="w-full flex  flex-row justify-between  ">
                    <div>
                      <Checkbox
                        checked={states.readings}
                        onChange={(e: any) => {
                          updateState("readings", e.target.checked);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Form.Item>
              <div className="flex  justify-end mt-20 mr-4">
                {update_state_change === false ? (
                  <SaveButton
                    disableButton={form_disable}
                    handleSave={() => {
                      setModalData(saveModalData);
                      setOpenModal(true);
                      validationForms();
                    }}
                  />
                ) : (
                  <UpdateButton
                    handleUpdate={() => {
                      setModalData(updateModalData);
                      setOpenModal(true);
                      validationForms();
                    }}
                  />
                )}
                {/* {
                  form_disable === false && update_state_change === false ? (
                    <SaveButton
                      handleSave={() => {
                        setModalData(saveModalData);
                        setOpenModal(true);
                        validation();
                      }}
                    />
                  ) : update_state_change === true ? (
                    <UpdateButton
                      handleUpdate={() => {
                        setModalData(updateModalData);
                        setOpenModal(true);
                        validationForms();
                      }}
                    /> */}

                {/* // useCallbackFunc new_button_toggler={()=>{setform_disable(!form_disable)}} */}

                {form_disable ? (
                  <NewButton
                    new_button_toggler={() => {
                      dispatch(
                        setGeneralValue({ expr: "formDisabled", value: false })
                      );
                      dispatch(task_form_disable(false));
                    }}
                  />
                ) : (
                  <Cancel
                    cancel_button_toggler={() => {
                      setshowCancel(false);
                      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
                      dispatch(task_form_disable(true));
                      dispatch(settask_form_disable(true));
                      dispatch(task_footer_update(false));
                      dispatch(settask_footer_update(false));
                    
                      dispatch(setGeneralValue({ expr: "updateMode", value: false }));
                    }}
                  />
                )}
                {/* {form_disable === true ? (
                  <NewButton
                    new_button_toggler={() => {
                      clearFeids();
                      populateFields(true);
                      dispatch(
                        setGeneralValue({ expr: "formDisabled", value: false })
                      );
                    }}
                  />
                ) : update_state_change === true ? (
                  <Cancel
                    cancel_button_toggler={() => {
                      setshowCancel(false);
                    }}
                  />)} */}

                {/* //  ) : !(update_state_change && form_disable) ? (
               //    <Cancel cancel_button_toggler={() => {}} />
               //  ) : (
               //    <NewButton */}
                {/* //      new_button_toggler={() => { */}
                {/* //        clearFeids();
               //        populateFields(true);
               //        dispatch(
               //          setGeneralValue({ expr: "formDisabled", value: false })
               //        );
               //      }}
               //    /> */}
              </div>
            </Form>
          </div>
        </div>
      }
    </div>
  );
};

export default BaseDetailsTab;
