import { Drawer } from "devextreme-react/drawer";
import { Toolbar, Item } from "devextreme-react/toolbar";
import React, { useState, useMemo, useRef, useEffect } from "react";
import "devextreme/dist/css/dx.light.css";
import DrawerContent, { DrawerIcons } from "./data/drawerItems";
import ScrollView from 'devextreme-react/scroll-view';
import { useDispatch, useSelector } from "react-redux";
import { setGeneralValue } from "../../features/generalSlice";
import { RootState } from "../../app/store";
import expand from '../../assets/expand.png';
import collapse from '../../assets/collapse.png';
import { current } from "@reduxjs/toolkit";
import { Tooltip } from "antd";
import useFetch from "../../hooks/useFetch";


const NavigationDrawer = ({drawer_component}:any) => {
    const dispatch = useDispatch()

    const rxActiveModule = useSelector((state:RootState) => state.general.focusedCurrentModule)
    const news_flash_height = useSelector((state:RootState)=>state.general.newsflashHeight);
    const mainNavBG = useSelector((state:RootState) => state.general.settingsColors?.mnbgc)
    const mainNavT = useSelector((state:RootState) => state.general.settingsColors?.mntc)
    const drawerOpened = useSelector(
        (state: RootState) => state.general.drawerOpened
    );
    const drawerRef = useRef<any>();
    const mainNav_ref = useRef<any>();

    
    // delay promise
    function timeout(delay: number) {
        return new Promise( res => setTimeout(res, delay) );
    }

    // main state of right navigator
    const [isOpened, setState] =  useState(true);

    //expand expand mode
    const[isExpanded,setIsExpanded]=useState(false);

    // active node in tree view
    let [active_node,set_active_node] = useState<any>('');

    // open full drawer in icon mode
    const openDrawer = async(active_node:any) =>{
        set_active_node(active_node);
        setState(true);
        await timeout(100);
        
        drawerRef.current?.collapse();
    }
    
    // toggle right navigation drawer
    const buttonOptions = useMemo<any>(() => {
        return {
            icon: "menu",
            onClick: () => {
                setState(!isOpened);
                dispatch(setGeneralValue({expr:'drawerOpened',value:!drawerOpened}))
                // drawerRef.current?.collapse();
                set_active_node('')
            }
        };
    }, [isOpened]);

    useEffect(() => {
        dispatch(setGeneralValue({expr: 'loginMode' ,value : true}));
    },[])

    const drawerStyle = useMemo(() => ({
        height: news_flash_height === 0 ? 'calc(94vh)' : 'calc(100vh - 113px)',
    }), [news_flash_height]);

    return (
        <div style={drawerStyle} className="drawerContents  w-full h-full bg-white dark:bg-slate-900 dark:text-gray-100 duration-100">
            {/* drawer  */}
            {<Drawer
                minSize={70}
                maxSize = {310}
                revealMode = 'expand'
                className="border-t border-solid"
                opened={isOpened}
                animationEnabled = {true}
                activeStateEnabled={true}
                render={() =>
                    <div style={{height : 'calc(100vh - 40px)'}} className={`bg-${mainNavBG?.color ?? 'white'}  dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base shadow-2xl border-r-4  px-3.5 pt-2`}>
                        <Toolbar className={` bg-slate-500  sticky flex items-center justify-center caret-gray-800 mb-2`} id="toolbar">
                            <Item
                            widget="dxButton" 
                            options={buttonOptions}
                            location= {isOpened ? 'before' : 'after'}
                            />

                            {isOpened && (
                                <Item>
                                    <p className=" text-sm font-semibold w-full mr-10">Main Navigator</p>
                                </Item>
                            )}

                            {isOpened && (
                                <Item>
                                    <div
                                    onClick={()=>{
                                        setIsExpanded(current=> !current)
                                        isExpanded ? drawerRef.current?.collapse() : drawerRef.current?.expand();
                                    }}
                                    className=" ml-10">
                                        {isExpanded?(
                                            <Tooltip title='Collapse All'>
                                                <div
                                                style={{borderWidth:1}}
                                                className=" border-gray-200 p-1 rounded flex justify-center items-center cursor-pointer">
                                                    <img  src={collapse} alt="" />
                                                </div>
                                            </Tooltip>
                                        ):(
                                            <Tooltip title='Expand All'>
                                                <div
                                                style={{borderWidth:1}}
                                                className=" border-gray-200 p-1 rounded flex justify-center items-center cursor-pointer">
                                                    <img className="w-10/12 h-10/12" src={expand} alt="" />
                                                </div>
                                            </Tooltip>
                                        )}
                                    </div>
                                </Item>
                            )}
                        </Toolbar>

                        <ScrollView 
                        ref={mainNav_ref}
                        showScrollbar={"onScroll"}
                        
                        
                       
                        id="l">
                            <div id="scroll" className={"o mb-32 "}>
                                {isOpened && <DrawerContent active ={active_node} mainRef={mainNav_ref} ref={drawerRef} />}
                                {!isOpened && <DrawerIcons drawerToggler={openDrawer} />}
                            </div>
                        </ScrollView>
                    </div>
                }
            >
                {/* page content */}
                <div className="w-full h-full dark:bg-slate-900 dark:text-gray-100 duration-100">
                    {drawer_component}
                </div>
            </Drawer>}        
        </div>
    );
}
 
export default NavigationDrawer;