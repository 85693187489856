/* eslint-disable react/jsx-pascal-case */
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import useFetch from "../../../../../../hooks/useFetch";
import Setups_Template from "../../../../../human-resource/setups/.setups_templates/Setups_Template";
import {
  checkboxTypes,
  exprsTypes,
  modalTypes,
  searchTextTypes,
  selectExprsTypes,
} from "../../../../../human-resource/setups/data/types/selectsTypes";
import { assetsSetups_vehicleStatus_columns } from "./data/vehicleStatusData";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";
import {
  setTier2CheckboxValue,
  tier2CheckboxSaveOnclick,
  tier2CheckboxUpdateOnclick,
} from "../../../../../../features/forms/tier2checkbox/tier2SliceCheckbox";
import { setGeneralValue } from "../../../../../../features/generalSlice";
import {
  validationsData,
  saveModalData,
  updateModalData,
} from "../../../../../accessories/component_infos";
import { ModalTemplate } from "../../../../../../templates/modal";
import { useBusyLoader } from "../../../../../../hooks/useBusyLoader";
import { WarningAlert } from "../../../../../accessories/warningAlert";
import dayjs from "dayjs";
import { UpdateDataFunc } from "../../../../../../functions/update";
import { PostDataFunc  } from "../../../../../../functions/post";
import { useAccountInfo } from "../../../../../../hooks/useAccountInfo";
import { useCrudFunc } from "../../../../../../functions/crud";

export const Assets_setups_vehicleStatuses = forwardRef(({ a }: any, ref) => {
  const saveSuccessText = {
    message: "Vehicle status added Successfully",
    ok: "Ok",
    title: "Save Succesful",
  }; // successful save modal message

  // store data expressions[unique]
  const idExpr: String = "vstIDpk";
  const nameExpr: string = "vstName";
  const shortnameExpr: string = "vstShtName";
  const orderExpr: string = "vstOrder";
  const activeExpr: string = "vstActive";
  const remarksExpr: string = "vstRmks";
  const inputsExprsData: any = {
    idExpr: idExpr,
    nameExpr: nameExpr,
    shortnameExpr: shortnameExpr,
    orderExpr: orderExpr,
    activeExpr,
    remarksExpr: remarksExpr,
  };
  const selectsExpr: selectExprsTypes[] = [
    {
      idExpr: "etpHardwareTypeIdfk",
      nameExpr: "etpHardwareTypeIdfk",
      label: "hardwareType",
    },
    { idExpr: "areIdpk", nameExpr: "areName", label: "area" },
    { idExpr: "areIdpk", nameExpr: "areName", label: "area" },
    { idExpr: "areIdpk", nameExpr: "areName", label: "area" },
    { idExpr: "areIdpk", nameExpr: "areName", label: "area" },
  ];
  const checkboxesExpr: selectExprsTypes[] = [
    {
      idExpr: "vstFitStatus",
      nameExpr: "vstFitStatus",
    },
    { idExpr: "vstRoadworthy", nameExpr: "vstRoadworthy" },
    { idExpr: "vstInsuranceRenewal", nameExpr: "vstInsuranceRenewal" },
    { idExpr: "areIdpk", nameExpr: "areName" },
    { idExpr: "areIdpk", nameExpr: "areName" },
  ];
  const dispatch = useDispatch();

  const storeExprs = () => {
    const exprs: exprsTypes[] = [
      { expr: "idExpr", value: idExpr },
      { expr: "nameExpr", value: nameExpr },
      { expr: "shortnameExpr", value: shortnameExpr },
      { expr: "selectExprs", value: selectsExpr[0] },
      { expr: "existsUrl", value: `AsmTmsVehicleStatus` },
    ];
    for (let i = 0; i < exprs.length; i++) {
      dispatch(
        setTier2CheckboxValue({ expr: exprs[i].expr, value: exprs[i].value })
      );
    }

    // to general slice
    const generalExprs: exprsTypes[] = [
      { expr: "selectExprs", value: selectsExpr },
      { expr: "inputExprs", value: inputsExprsData },
      { expr: "checkboxExprs", value: checkboxesExpr },
    ];
    for (let i = 0; i < generalExprs.length; i++) {
      dispatch(
        setGeneralValue({
          expr: generalExprs[i].expr,
          value: generalExprs[i].value,
        })
      );
    }
  };

  const [refresh, setRefresh] = useState<boolean>(false); //refresh data state for data creation or update

  const [busyLoader, setBusyLoader] = useBusyLoader();
  const [userId, employeeId] = useAccountInfo();

  //modal states
  //--general--
  const triggerSuccessModal = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.successModalState
  ); //trigger success modal
  const SuccessModalText = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.successText
  ); //success modal text

  //--for backend errors--
  const [errState, setErrState] = useState<boolean>(false);
  const [errTitle, seterrTitle] = useState<string>("");
  const [errMessage, seterrMessage] = useState<string>("");

  //--warning modal--
  const triggerwarningModal = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.warningState
  ); //trigger warning
  const warningInfos: any = validationsData; //warning type details
  const warningDataExpr = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.warningDataExpr
  ); //warning type selector

  //--save data modal--
  const saveData = saveModalData; //save modal details[data]
  const triggersaveModal = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.saveDataOnclick
  ); //trigger save modal

  //--Update data modal--
  const updateIndInfos = updateModalData; //update modal details[data]
  const triggerupdateModal: boolean = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.updateDataOnclick
  ); //trigger update question

  // on page resize height responsiveness
  const [trigger, setTrigger] = useState<boolean>(false);
  useImperativeHandle(ref, () => ({
    test: () => {
      setTrigger(!trigger);
    },
  }));

  const name = useSelector((state: RootState) => state.tier2Ckeckboxforms.name);
  const shortname = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.shortname
  );
  const order = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.order
  );
  const active = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.active
  );
  const remarks = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.remarks
  );
  const checkbox0 = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.checkbox0
  );
  const selectedID = useSelector(
    (state: RootState) => state.general.SelectedFieldID
  );

  //post form Data
  //validate data
  const validateDataSave = () => {
    dispatch(tier2CheckboxSaveOnclick("type1"));
  };

  const [Posting, Updating, Patching] = useCrudFunc(); //crud functions
  // post
  const postData = async () => {
    dispatch(setTier2CheckboxValue({ expr: "saveDataOnclick", value: false }));

    setBusyLoader(saveModalData.progress);
    try {
      await Posting (`AsmTmsVehicleStatus/CreateAsmTmsVehicleStatus`, {
        vstName: name,
        vstShtName: shortname,
        vstFitStatus: checkbox0,
        vstActive: active,
        vstRmks: remarks,
        vstOrder: order,
        vstCreatedBy: userId,
      },`Created the Asset Mgr-Transport Setup- Vehicle Status with Name: ${name}, Short Name: ${shortname}, Fit Status: ${checkbox0}, Active: ${active}, Remarks: ${remarks}, Order: ${order} and created by: ${userId} successfully`);
      setRefresh(!refresh);

      const exprs: exprsTypes[] = [
        { expr: "saveDataOnclick", value: false },
        { expr: "successModalState", value: true },
        { expr: "successText", value: saveSuccessText },
      ];
      for (let i = 0; i < exprs.length; i++) {
        dispatch(
          setTier2CheckboxValue({ expr: exprs[i].expr, value: exprs[i].value })
        );
      }
    } catch (error: any) {
      //set states for db errors modal
      setErrState(true);
      seterrTitle(saveModalData.error.title);
      seterrMessage(saveModalData.error.message);
    } finally {
      setBusyLoader("");
    }
  };

  //Update Form data
  //validate data
  const validateDataUpdate = () => {
    dispatch(tier2CheckboxUpdateOnclick("type1"));
  };
  // update
  const UpdateData = async () => {
    dispatch(
      setTier2CheckboxValue({ expr: "updateDataOnclick", value: false })
    );

    setBusyLoader(updateModalData.progress);

    try {
      await Updating(
        `AsmTmsVehicleStatus/UpdateAsmTmsVehicleStatus/${selectedID}`,
        {
          vstIdpk: selectedID,
          vstName: name,
          vstShtName: shortname,
          vstFitStatus: checkbox0,
          vstActive: active,
          vstRmks: remarks,
          vstOrder: order,
          vstEditedBy: userId,
          vstEditedDate: dayjs().format(),
        },`Edited the Asset Mgr-Transport Setup- Vehicle Status with Name: ${name}, Short Name: ${shortname}, Fit Status: ${checkbox0}, Active: ${active}, Remarks: ${remarks}, Order: ${order} and edited by: ${userId} successfully`
      );
      setRefresh(!refresh);
    } catch (error: any) {
      setErrState(true);
      seterrTitle(updateModalData.error.title);
      seterrMessage(updateModalData.error.message);
    } finally {
      setBusyLoader("");
    }
  };

  //modals data
  const modals: modalTypes[] = [
    //warning
    {
      disableCancel: true,
      icon: "warning",
      open: triggerwarningModal,
      okHandler: () =>
        dispatch(setTier2CheckboxValue({ expr: "warningState", value: false })),
      cancelHandler: () =>
        dispatch(setTier2CheckboxValue({ expr: "warningState", value: false })),
      message: warningInfos[warningDataExpr!].message,
      cancelText: warningInfos[warningDataExpr!].cancelText,
      okText: warningInfos[warningDataExpr!].okText,
      title: warningInfos[warningDataExpr!].title,
    },
    //backend error
    {
      disableCancel: true,
      icon: "warning",
      open: errState,
      okHandler: () => setErrState(false),
      cancelHandler: () => setErrState(false),
      message: errMessage,
      cancelText: "",
      okText: "Ok",
      title: errTitle,
    },
    //success
    {
      disableCancel: true,
      icon: "success",
      open: triggerSuccessModal,
      okHandler: () =>
        dispatch(
          setTier2CheckboxValue({ expr: "successModalState", value: false })
        ),
      cancelHandler: () =>
        dispatch(
          setTier2CheckboxValue({ expr: "successModalState", value: false })
        ),
      message: SuccessModalText.message,
      okText: SuccessModalText.okText,
      title: SuccessModalText.title,
    },
    //save
    {
      disableCancel: false,
      icon: "question",
      open: triggersaveModal,
      okHandler: () => postData(),
      cancelHandler: () =>
        dispatch(
          setTier2CheckboxValue({ expr: "saveDataOnclick", value: false })
        ),
      message: saveData.message,
      cancelText: saveData.cancelText,
      okText: saveData.okText,
      title: saveData.title,
    },
    //Update
    {
      disableCancel: false,
      icon: "question",
      open: triggerupdateModal,
      okHandler: () => UpdateData(),
      cancelHandler: () =>
        dispatch(
          setTier2CheckboxValue({ expr: "updateDataOnclick", value: false })
        ),
      message: updateIndInfos.message,
      cancelText: updateIndInfos.cancelText,
      okText: updateIndInfos.okText,
      title: updateIndInfos.title,
    },
  ];

  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });
  const activeStatus = useSelector(
    (state: RootState) => state.general.activeStatus
  );
  const [status, setStatus] = useState<boolean | string>("");

  // data for datagrid
  const [data, dataError, dataLoading] = useFetch(
    `AsmTmsVehicleStatus/GetAllAsmTmsVehicleStatuses?searchText=${searchText.text}&active=${status}`,
    refresh
  );
  useEffect(() => {
    if (data) {
      storeExprs(); // run func to store expressions on store
      dispatch(setGeneralValue({ expr: "idExpr", value: idExpr })); // dispatch fetched data to reduxstore
      dispatch(setGeneralValue({ expr: "formData", value: data })); // dispatch fetched data to reduxstore
    }

    dataLoading ? setBusyLoader(".") : setBusyLoader("");
  }, [dataLoading, data]);

  // form parameters
  const form_header = "Vehicle Status Details";
  const checkboxes: checkboxTypes[] = [{ id: 0, label: "Fit?" }];

  return (
    <>
      {dataError && <WarningAlert />}

      {modals.map(
        (
          {
            disableCancel,
            icon,
            okHandler,
            cancelHandler,
            open,
            cancelText,
            okText,
            message,
            title,
          }: modalTypes,
          index
        ) => {
          return (
            <div key={index}>
              <ModalTemplate
                icon={icon && icon}
                disableCancel={disableCancel}
                cancelText={cancelText && cancelText}
                open={open}
                okHandler={okHandler}
                cancelHandler={cancelHandler}
                message={message}
                okText={okText}
                title={title}
              />
            </div>
          );
        }
      )}

      <Setups_Template
        searchTextChange={(newText) =>
          setSearchText({ temp: newText, text: searchText.text })
        }
        handleSearch={() => {
          setSearchText({ text: searchText.temp, temp: searchText.temp });
          setStatus(activeStatus);
          setRefresh((current) => !current);
        }}
        handleRefresh={() => {}}
        checkboxlist={checkboxes}
        trigger={trigger}
        datagridColumns={assetsSetups_vehicleStatus_columns}
        datagridData={data}
        formHeader={form_header}
        createData={validateDataSave}
        updateData={validateDataUpdate}
        formTier="tier2checkbox"
      />
    </>
  );
});
