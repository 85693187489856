import { Position } from "devextreme/common";


export const left_access_priv = [
    { id: 0, caption: "EMP ACTV", dataField: "empActive", dataType: "", fixed: true, visible: true, width: 120, },
    { id: 1, caption: "USER ACTV", dataField: "usaActive", dataType: "", fixed: true, visible: true, width: 120, },
    { id: 2, caption: "USERNAME", dataField: "usaUsername", dataType: "", fixed: true, visible: true, width: 120, },
    { id: 3, caption: "STAFF No", dataField: "empStaffNo", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 4, caption: "STAFF NAME", dataField: "empName", dataType: "", fixed: true, visible: true, width: 120, },
    { id: 5, caption: "DEPT", dataField: "dptShtName", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 6, caption: "SECTION", dataField: "sxnShtName", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 7, caption: "END DATE", dataField: "usaEndDate", dataType: "datetime", fixed: false, visible: true, width: 120, },
    { id: 8, caption: "JOB TITLE?", dataField: "jbtName", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 9, caption: "ONLINE?", dataField: "usaLoggedIn", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 10, caption: "INTERCOM", dataField: "empIntercom", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 11, caption: "MOB No", dataField: "empMobNo", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 12, caption: "SUPER USER?", dataField: "usaSuperUser", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 13, caption: "SEE DASHBOARD", dataField: "usaSeeExecDashboard", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 14, caption: "ORG ADMIN?", dataField: "", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 15, caption: "DEPT ADMIN?", dataField: "", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 16, caption: "SXN ADMIN?", dataField: "", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 17, caption: "UNIT ADMIN?", dataField: "", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 18, caption: "SEE SALARY?", dataField: "usaSeeSalaries", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 19, caption: "EDIT MOB Nos", dataField: "usaEditOthersMobNos", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 20, caption: "EDIT INTERCOMs", dataField: "usaEditIntercom", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 21, caption: "EDIT eMAILS?", dataField: "usaEditEmails", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 22, caption: "TRANSP. MGR?", dataField: "", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 23, caption: "RESET PSWD?", dataField: "usaChangePassword", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 24, caption: "CEO", dataField: "", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 25, caption: "ANS. QSNS", dataField: "", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 26, caption: "SEE HIDDEN MOB Nos", dataField: "usaSeeHiddenMobNos", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 27, caption: "APPROVE JOB DESC", dataField: "", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 28, caption: "ALLOW SENDING SMS", dataField: "usaAllowSendingOfSMS", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 29, caption: "SEE EXEC. DASHBOARD", dataField: "usaSeeExecDashboard", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 30, caption: "DEPT PROJ ADMIN?", dataField: "", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 31, caption: "SECTION PROJ ADMIN?", dataField: "", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 32, caption: "UNIT PROJ ADMIN?", dataField: "", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 33, caption: "WKSHOP SUP", dataField: "", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 34, caption: "WKSHOP OFFICER", dataField: "", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 35, caption: "APPROVAL CEO 1", dataField: "", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 36, caption: "APPROVAL CEO 2", dataField: "", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 37, caption: "TMS ORG ADMIN", dataField: "", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 38, caption: "TMS DPT ADMIN", dataField: "", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 39, caption: "TMS SECTN ADMIN", dataField: "", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 40, caption: "TMS UNIT ADMIN", dataField: "", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 41, caption: "TMS ADMIN", dataField: "", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 42, caption: "TMS ALVR?", dataField: "", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 43, caption: "SAVE DEPT RETNS", dataField: "", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 44, caption: "SAVE SECTN RETNS", dataField: "", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 45, caption: "SUBMIT DEPT RETNS", dataField: "", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 46, caption: "SUBMIT SECTN RETNS", dataField: "", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 47, caption: "SEE DRIVERS CODES", dataField: "", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 48, caption: "SFT SYS ADMIN", dataField: "", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 49, caption: "SFT SUP", dataField: "", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 50, caption: "SFT MGR", dataField: "", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 51, caption: "SFT DIR", dataField: "", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 52, caption: "SFT ORG ADMIN", dataField: "", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 53, caption: "SFT REC. TOPICS", dataField: "", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 54, caption: "SFT APPROVE TOPICS", dataField: "", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 55, caption: "V-REG(INSPECT)", dataField: "", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 56, caption: "V-REG (RECOMMEND)", dataField: "", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 57, caption: "V-REG (APPROVE)", dataField: "", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 58, caption: "APPROVE SW REQs", dataField: "", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 59, caption: "CREATED ON", dataField: "usaCreationDate", dataType: "datetime", fixed: false, visible: true, width: 120, },
    { id: 60, caption: "REMARKS", dataField: "usaRmks", dataType: "", fixed: false, visible: true, width: 40000, },

]


export const right_cols1 = [
    { id: 0, caption: "Form", dataField: "afmName", dataType: "string", fixed: true, visible: true, width: 120, allowEditing: false },
    { id: 1, caption: "Save", dataField: "apvInsert", dataType: "boolean", fixed: true, visible: true, width: 80, allowEditing: true },
    { id: 2, caption: "Update", dataField: "apvUpdate", dataType: "boolean", fixed: true, visible: true, width: 80, allowEditing: true },
    { id: 3, caption: "Read", dataField: "apvRead", dataType: "boolean", fixed: true, visible: true, width: 80, allowEditing: true },
    // { id: 4, caption: "Delete", dataField: "", dataType: "boolean", fixed: true, visible: true, width: 80, allowEditing: true },
    { id: 4, caption: "Print", dataField: "apvPrint", dataType: "boolean", fixed: true, visible: true, width: 80, allowEditing: true },
    // { id: 6, caption: "Form Code", dataField: "afmCode", dataType: "string", fixed: true, visible: true, width: 120, allowEditing: false },
    { id: 5, caption: "Description", dataField: "afmDesc", dataType: "string", fixed: true, visible: true, width: 400, allowEditing: false },
];


export const tabsPositions: Position[] = [
    'left',
    'top',
    'right',
    'bottom',
];
