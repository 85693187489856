import 'devextreme/dist/css/dx.light.css';
import { TreeView, SearchEditorOptions, Item } from 'devextreme-react/tree-view';
import Drawerdata, { Drawericons } from './dawerData';
import { useNavigate } from 'react-router-dom';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import search from '../../../assets/search.png';
import { useDispatch } from 'react-redux';
import { setGeneralValue } from '../../../features/generalSlice';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { setElementStyles, setElementStylesQuerySelector } from '../../../functions/DOMElementStyles';
import useFetch from '../../../hooks/useFetch';
import { useAccountInfo } from '../../../hooks/useAccountInfo';
import { userDataCrypt } from '../../../functions/userDataEncrypt';
import { getFormCode } from '../../../features/user';

interface IconsProps {
    drawerToggler: (node: any) => any
}

const ItemRenderer = (({ item, mainNav }: any) => {
    return (
        <div className={`w-full h-full text-${mainNav?.color} hover:text-gray-400`}>
            <ul className='w-full flex space-x-1'>
                {item.icon && <li><img className='w-[18px] h-[18px]' src={item.icon} alt={item.name} /></li>}
                <li>{item.name}</li>
            </ul>
        </div>
    );
});



const DrawerContent = React.forwardRef(({ active, mainRef }: { active?: any, mainRef?: any }, ref,) => {
    const dispatch = useDispatch();
    const treeview = useRef<any>(null);;

    const mainNav = useSelector((state: RootState) => state.general.settingsColors?.mntc)
    const [routeCache, setRouteCache] = useState<string>("");
    const navigate = useNavigate();

    const [refresh, setRefresh] = useState<boolean>(false)


    useEffect(() => {
        navigate(routeCache);

    }, [routeCache, navigate])


    // Assigning each form a formCode 
    const [formCodeData, formCodeError, formCodeLoading] = useFetch(`CheckUserPrivilege/GetFormCode`, refresh)
    // collapse all func


    useEffect(() => {
        if (active) {
            treeview.current.instance.expandItem(active);
        }
    }, []);

    const collapseAll = useCallback(async () => {
        await treeview.current.instance.collapseAll();
    }, []);

    const expandAll = useCallback(async () => {
        await treeview.current.instance.expandAll();
    }, []);

    useImperativeHandle(ref, () => ({
        collapse: collapseAll,
        expand: expandAll
    }));

    // const searchExpr = ["count", "name"];
    const searchExpr = useMemo(() => ["count", "name"], []);

    const handleItemClick = useCallback((e: any) => {
        // alert("hi")
        setRefresh(!refresh) // makes sure the formCodeData is loaded
        for (let formCode of formCodeData) {
            // alert("hello")
            // sessionStorage.setItem("papasPizza", "val")
            if (e.itemData.serverId === formCode.afmIdpk) {
                sessionStorage.setItem("formCode", userDataCrypt("encrypt", formCode.afmCode!)!);
                localStorage.setItem("formCodeX", formCode.afmCode);
                dispatch(setGeneralValue({ expr: "formCode", value: formCode.afmCode }))
                Drawerdata.map((obj) => ({ ...obj, formCode: formCode.afmCode }));
                break;
            }
        }

        try {
            dispatch(getFormCode())
        } catch (error: any) {
            console.error("Main Navigation Error", error);
        }


        if (e.itemData.expanded) {
            e.component.collapseItem(e.node.key);
            e.component.onFocusedItemChanged(active);
        } else {
            e.component.expandItem(e.node.key);
            e.component.onFocusedItemChanged(active);
        }

        e.itemData.route && setRouteCache(e.itemData.route);
        e.component.onFocusedItemChanged(active);

        dispatch(setGeneralValue({ expr: 'closeAllForms', value: false }));
    }, [refresh, formCodeData, userDataCrypt, dispatch, active, setRouteCache]);

    const onInitialized = useCallback((e: any) => {
    }, [])


    const onContentReady = useCallback((e: any) => {
        // Responsible for hot reloading the formData and the callback func
        // setRefresh(!refresh)

        //  getting the default model id 
        //This opens the default module i.e TMS
        const defaultApplicationString = localStorage.getItem("defaultApplication");
        const defaultApplication = defaultApplicationString !== null ? JSON.parse(defaultApplicationString) : null;

        // Expand the item in the component using the default application ID
        if (defaultApplication) {
            e.component.expandItem(defaultApplication?.key); //5_2
        } else {
            console.warn("No default application found in localStorage.");
        }

        // alert(JSON.stringify(defaultApplication))


    }, [])

    return (
        <div className="w-full  ">
            <TreeView
                ref={treeview}
                animationEnabled={true}
                // dataSource={Drawerdata}
                items={Drawerdata}
                itemRender={(item) => <ItemRenderer item={item} mainNav={mainNav} />}
                dataStructure={"plain"}
                virtualModeEnabled={true}
                keyExpr="ID"
                displayExpr="name"
                parentIdExpr="categoryId"
                searchEnabled={true}
                activeStateEnabled={true}
                searchExpr={searchExpr}
                // onItemRendered={(e) => { console.log("onItemRendered: ", e.itemData); }}
                onInitialized={onInitialized}
                onContentReady={onContentReady}
                onItemClick={handleItemClick}
                onSelectionChanged={handleItemClick}
            >
                <SearchEditorOptions
                    placeholder="Type search value here..."
                />



            </TreeView>

        </div >
    );
})

export default DrawerContent;

export const DrawerIcons = ({ drawerToggler }: IconsProps) => {

    const dispatch = useDispatch()
    const handle_item_click = (e: any) => {
        drawerToggler(e);
        // to remain focus on the selected module 
        dispatch(setGeneralValue({ expr: 'focusedCurrentModule', value: e.itemIndex }));
        dispatch(setGeneralValue({ expr: 'closeAllForms', value: false }))
    }

    return (
        <>
            <div className="flex  flex-col items-center justify-center pr-2 ml-1 mb-2 dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base">
                <section onClick={() => handle_item_click('')} className="icon hover:cursor-pointer px-1 mt-1 dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base">
                    <img src={search} alt="icon" />
                </section>

                <section className="w-full mt-1.5">
                    <TreeView
                        items={Drawericons}
                        dataStructure={"plain"}
                        keyExpr="ID"
                        width={90}
                        style={{ marginLeft: '-33px' }}
                        displayExpr="name"
                        parentIdExpr="categoryId"
                        onItemClick={(e: any) => handle_item_click(e.node.key)}


                    >
                        <SearchEditorOptions
                            placeholder="Type search value here..."
                        />
                    </TreeView>
                </section>
            </div>
        </>
    );
}