/* eslint-disable react/jsx-pascal-case */
import React from "react";
import { HSeperator, VSeperator } from "../widgets/utils";
import Datagrid_template from "../../../../templates/Datagrid";
import { CheckboxTemlate } from "../../../../templates/checkbox";
import TabsPanel_template from "../../../../templates/tabsPanel_template";
import { datagridColumnTypes } from "../../../human-resource/setups/data/types/selectsTypes";
import cx from "classix";

const LawCatalogs = React.forwardRef((props, ref) => {
  const [vState, setVstate] = React.useState(false);
  const [hState, setHstate] = React.useState(false);
  const [OpenState, setOpenState] = React.useState<{
    year: boolean;
    search: boolean;
    bigGrid: boolean;
    notes: boolean;
  }>({
    year: true,
    search: true,

    bigGrid: false,
    notes: true,
  });

  const tabs: { id: number; title: string; contents: any }[] = [
    { id: 0, title: "File", contents: <>Empty</> },
    { id: 1, title: "MS Word", contents: <>Empty</> },
    { id: 2, title: "PDF Viewer", contents: <>Empty</> },
    { id: 2, title: "Image Viewer", contents: <>Empty</> },
    // { id: 2, title: "RibbonPage1", contents: <>Empty</> },
  ];
  return (
    <main className="flex w-full h-full">
      <aside className="h-full flex w-2/6">
        {!OpenState.bigGrid && (
          <section className="w-1/2 flex flex-col transition-all">
            <header
              className={cx(
                "flex flex-col pb-20 transition-all p-2",
                !OpenState.search && !OpenState.year
                  ? "flex-1 h-[90%] "
                  : "h-1/5 "
              )}
            >
              <span className="w-full bg-stone-100 p-2">{`Catalog Type`}</span>
              {[0, 0, 0, 0, 0].map((_) => (
                <p className="text-sm text-neutral-700 my-1.5">
                  Lorem ipsum dolor sit amet{" "}
                </p>
              ))}
            </header>
            <HSeperator
              onClick={() =>
                setOpenState((prev) => ({ ...prev, year: !prev.year }))
              }
              status={!OpenState.year}
            />
            {OpenState.year && (
              <header
                className={cx(
                  "flex flex-col pb-20 transition-all",
                  !OpenState.search ? "flex-1 h-[90%] " : "h-1/5 "
                )}
              >
                <span className="w-full bg-stone-100 p-2">{`Year`}</span>
                <section
                  className={cx(
                    // "flex items-center space-x-2 w-full h-auto transition-all"
                    "grid grid-cols-2 gap-x-2 w-full transition-all",
                    !OpenState.search ? "" : "h-auto "
                  )}
                >
                  {[1, 2, 3, 4, 5, 6].map((_) => (
                    <div className="flex space-x-1.5 items-center">
                      <CheckboxTemlate
                        withBorders
                        // defaultValue={true}
                        customDisabled={false}
                      />
                      <span className="text-sm">{`200${_}`}</span>
                    </div>
                  ))}
                </section>
              </header>
            )}
            <HSeperator
              onClick={() =>
                setOpenState((prev) => ({ ...prev, search: !prev.search }))
              }
              status={!OpenState.search}
            />
            {OpenState.search && (
              <header className="flex flex-col flex-1">
                <span className="w-full bg-stone-100 p-2">{`Search`}</span>
                <span className="flex flex-col space-y-2 p-2">
                  <input
                    type="text"
                    name=""
                    id=""
                    className="border rounded-sm w-full outline-none px-2 py-1"
                  />
                  <button className="bg-stone-100 rounded-md px-2 py-1 w-full flex">
                    Search
                  </button>
                </span>
              </header>
            )}
          </section>
        )}
        <VSeperator
          height={100}
          onClick={() =>
            setOpenState((prev) => ({ ...prev, bigGrid: !prev.bigGrid }))
          }
          status={OpenState.bigGrid}
        />
        <section
          className={cx(
            "w-1/2 border flex flex-col space-y-4 transition-all",
            OpenState.bigGrid ? "w-full" : "w-1/2"
          )}
        >
          <span className="grid grid-cols-2 gap-x-2 p-2">
            <input
              type="text"
              name=""
              id=""
              className="border outline-none px-2"
            />
            <button className="bg-stone-100 rounded-lg px-2 py-1">
              Search
            </button>
          </span>
          <Datagrid_template
            disableSearch
            selectedRowIndex={(e) => console.log(e)}
            disableGroupPanel
            onRowClick={(e) => console.log(e)}
            gridheight={100}
            data={[]}
            columns={tempGrids}
          />
        </section>
      </aside>

      <aside className="flex flex-col flex-1">
        <section
          className={cx(
            `transition-all`,
            OpenState.notes ? "w-full h-2/3 " : "w-full h-full "
          )}
        >
          <TabsPanel_template tabs={tabs} />
        </section>
        <HSeperator
          onClick={() =>
            setOpenState((prev) => ({ ...prev, notes: !prev.notes }))
          }
          status={!OpenState.notes}
        />
        {OpenState.notes && (
          <section className="flex flex-col w-full h-1/3 transition-all">
            <span className="w-full bg-stone-100 p-2">{`Notes`}</span>
            <aside className="w-1/3 h-full flex flex-col bg-stone-100">
              <button className="flex items-center p-1.5 text-sm bg-stone-200">
                Add Notes
              </button>
              <Datagrid_template
                disableSearch
                selectedRowIndex={(e) => console.log(e)}
                disableGroupPanel
                onRowClick={(e) => console.log(e)}
                gridheight={100}
                data={[]}
                columns={notes}
              />
            </aside>
            <aside className="flex-1 "></aside>
          </section>
        )}
      </aside>
    </main>
  );
});

export default LawCatalogs;
LawCatalogs.displayName = "LawCatalogs";

const tempGrids: datagridColumnTypes[] = [
  {
    id: 1,
    caption: "Name",
    dataField: "name",
    dataType: "string",
    width: 200,
    visible: true,
    fixed: false,
    allignment: "left",
  },
  {
    id: 2,
    caption: "ID",
    dataField: "id",
    dataType: "string",
    width: 200,
    visible: true,
    fixed: false,
    allignment: "left",
  },
  {
    id: 3,
    caption: "Date",
    dataField: "date",
    dataType: "date",
    width: 200,
    visible: true,
    fixed: false,
    allignment: "left",
  },
  {
    id: 4,
    caption: "Time",
    dataField: "time",
    dataType: "time",
    width: 200,
    visible: true,
    fixed: false,
    allignment: "left",
  },
];
const notes: datagridColumnTypes[] = [
  {
    id: 1,
    caption: "Name",
    dataField: "name",
    dataType: "string",
    width: 200,
    visible: true,
    fixed: false,
    allignment: "left",
  },
  {
    id: 2,
    caption: "Created By",
    dataField: "id",
    dataType: "string",
    width: 200,
    visible: true,
    fixed: false,
    allignment: "left",
  },
];
