import { Select, Form, Checkbox, } from "antd";
import TextArea from "antd/es/input/TextArea";
import { Column, DataGrid, GroupPanel, HeaderFilter, Pager, Paging, Scrolling, SearchPanel } from "devextreme-react/data-grid";
import { useReducer, useState } from "react";

import { staff_name_TH } from "../data/staff-name-data";
import { DateTemplate } from "../../../../../templates/date";
import { SelectsTemplate } from "../../../../../templates/select";
import { InputsTemplate } from "../../../../../templates/input";
import axios from "axios";

import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import { useDispatch } from "react-redux";
import { setDetailsPreview } from "../../../../../features/generalSlice";
import { Cancel, NewButton, SaveButton, UpdateButton } from "../../../../accessories/buttons";
import { api_url, navbar_height, toolbar_height } from "../../../../accessories/component_infos";
import { ModalTemplate } from "../../../../../templates/modal";
import { formPropsConst } from "../../../../accessories/constants";


const { Option } = Select;

interface props {
   toggled?: boolean
   networkTableData?: any[]
   fetchData?: any
   loading?: boolean
   reasonList?: any[],
   staffNoList?: any[],
   titleNoList?: any[],

}

function reducer(state: any, action: any) {
   switch (action.type) {

      case 'add_Data': {
         console.log(state.tableData);
         console.log(action.newData)
         return {

            tableData: [action.newData, ...state.tableData],
            //,  
            detailsPreview: 'disabledMode'
         };
      }
      case 'change_display': {
         console.log(action.newDisplay)
         return {
            tableData: state.tableData,
            detailsPreview: action.newDisplay,

         };
      }

   }
   throw Error('Unknown action: ' + action.type);
}


const StaffNameDetails = ({ toggled, networkTableData, fetchData, reasonList, staffNoList, titleNoList, loading }: props) => {
   const news_flash_height = useSelector((state: RootState) => state.general.newsflashHeight);
   const toggleForm = (value: any) => {
      dispatch({
         type: 'change_display',
         newDisplay: value
      });
   }

   const [state, dispatch] = useReducer(reducer, { tableData: networkTableData, detailsPreview: 'disabledMode' });


   const heights_out = navbar_height + news_flash_height + toolbar_height;

   //varying form height
   const form_height = 490;

   //Table Height
   const pageHeight = window.innerHeight - navbar_height; //height without navbar
   const [tableHeight, settableHeight] = useState(window.innerHeight - (heights_out + 70)); //height without navbar and toolbar
   const [table_form_Height, settable_form_Height] = useState(window.innerHeight - (heights_out)); //height without navbar and toolbar and form

   const [showTable, setShowTable] = useState(true);

   //Checkbox Data

   const [indeterminate, setIndeterminate] = useState(false);
   const [checkBox, setCheckBox] = useState<any>(true);
   const [lastNameData, setLastNameData] = useState('');
   const [firstNameData, setFirstNameData] = useState('');
   const [otherNameData, setOtherNameData] = useState('');
   const [legacyNameData, setLegacyNameData] = useState('');
   const [nickNameData, setNickNameData] = useState('');
   const [initialsData, setInitialsData] = useState('');
   const [remarksData, setRemarksData] = useState('');
   const [staffNoData, setStaffNoData] = useState('');
   const [titleData, setTitleData] = useState<any>('');
   const [reasonData, setReasonData] = useState('');
   const [dateData, setDateData] = useState<any>();
   const [showErrorDialog, setShowErrorDialog] = useState(false);
   const [disableInputStatus, setDisableInputStatus] = useState(true);
   const [errorMessage, setErrorMessage] = useState('');
   const [errorFound, setErrorFound] = useState(false);

   function onFinish(): any {
      if (dateData === undefined || dateData === null || dateData === '') {
         setErrorMessage('Date field cannot be empty')
         setShowErrorDialog(true);
         setErrorFound(true);

      }
      else if (reasonData === undefined || reasonData === null || reasonData === '') {
         setErrorMessage('Reason field cannot be empty')
         setShowErrorDialog(true);
         setErrorFound(true);

      }
      else if (staffNoData === undefined || staffNoData === null || staffNoData === '') {
         setErrorMessage('Staff Number field cannot be empty')
         setShowErrorDialog(true);
         setErrorFound(true);

      } else if (titleData === undefined || titleData === null || titleData === '') {
         setErrorMessage('Title field cannot be empty')
         setShowErrorDialog(true);
         setErrorFound(true);

      }
      else if (lastNameData === '') {
         setErrorMessage('Last Name field cannot be empty')
         setShowErrorDialog(true);
         setErrorFound(true);

      } else if (firstNameData === '') {
         setErrorMessage('First Name field cannot be empty')
         setShowErrorDialog(true);
         setErrorFound(true);

      }
      else if (legacyNameData === '') {
         setErrorMessage('Legacy Name field cannot be empty')
         setShowErrorDialog(true);
         setErrorFound(true);

      } else if (initialsData === '') {
         setErrorMessage('Initials field cannot be empty')
         setShowErrorDialog(true);
         setErrorFound(true);
      } else {
         setErrorFound(false);
      }

   }


   const onChange = () => {

      if (checkBox === true) {
         setCheckBox(false);
         setIndeterminate(false);
         // fetchData('StaffNameDetails/GetAllInActiveEmpNameDetails')
      } else if (checkBox === false) {
         setCheckBox(null);
         setIndeterminate(true);
         // fetchData('StaffNameDetails/GetAllEmpNameDetails')
      } else {
         setCheckBox(true);
         setIndeterminate(false);
         // fetchData('StaffNameDetails/GetAllActiveEmpNameDetails')
      }

   };

   const switchNew = () => {
      setDisableInputStatus(false);
   }

   const clickUser = (data: any) => {
      setLastNameData(data.enmLastName);
      setFirstNameData(data.enmFirstName);
      setOtherNameData(data.enmOtherNames);
      setNickNameData(data.enmNickname);
      setInitialsData(data.enmInitials);
      setLegacyNameData(data.enmLegacyName);
      setRemarksData(data.enmRmks);
      setStaffNoData(data.enmStaffNo);
      setTitleData(data.titName);
      setReasonData(data.enmReasonIDfk);

   }

   const resetForm = () => {
      setLastNameData('');
      setFirstNameData('');
      setOtherNameData('');
      setNickNameData('');
      setInitialsData('');
      setLegacyNameData('');
      setRemarksData('');
      setStaffNoData('');
      setTitleData('');
      setReasonData('');
      setDateData('');

   }



   const dateFormat = "ddd, DD MM YYYY";






   return (
      <>

         <div>
            <ModalTemplate open={showErrorDialog} title='Error' okText="ok" cancelHandler={() => { setShowErrorDialog(false); }} cancelText="cancel" disableCancel={false} okHandler={() => { setShowErrorDialog(false); }} message={errorMessage} />

            <style
               dangerouslySetInnerHTML={{
                  __html: `
          

          textarea:disabled {
            background: #fffbeb;
          }  
          
          .dx-datagrid-header-panel .dx-toolbar {
            margin-bottom: 1.5px;
        }

          .filterTodos .dx-datagrid-headers{
              margin-top: 10px;
          }

          

            `
               }}
            />

            {toggled && <div className="w-full   border rounded-md">
               <div className="text-xs bg-slate-100 rounded-t-md py-1 px-2">Name Details</div>
               {/* The form */}
               <div className="py-2 flex justify-between items-end">
                  <Form {...formPropsConst} labelCol={{ span: 5 }} wrapperCol={{ span: 18 }}
                     size={"small"} className="ml-2 " style={{ width: '30%' }}

                  >
                     <div className="mb-0.5">
                        <DateTemplate changeDate={(date: any) => { if (dayjs(date) > dayjs()) { setErrorMessage('Date cannot be greater than current date'); setShowErrorDialog(true); setDateData(null) } else { setDateData(date) } }} selectedDate={dateData} label="Date" width={'55%'} disabled={state.detailsPreview === 'disabledMode' ? true : false} />
                     </div>
                     <div className="mb-0.5">
                        <SelectsTemplate selectStyle={{ width: '90%' }} label="Reason" placeHolder={reasonData} selectedValue={(e: any) => { setReasonData(e) }} options={reasonList} idexpr={'nrsIdpk'} dataexp={'nrsName'} disabled={state.detailsPreview === 'disabledMode' ? true : false} useCallFunc />
                     </div>
                     <div className="mb-0.5">
                        <SelectsTemplate selectStyle={{ width: '90%' }} label="Staff No" placeHolder={staffNoData} selectedValue={(e: any) => { setStaffNoData(e) }} options={staffNoList} idexpr={'id'} dataexp={'name'} disabled={state.detailsPreview === 'disabledMode' ? true : false} useCallFunc />
                     </div>

                     <SelectsTemplate selectStyle={{ width: '90%' }} placeHolder={titleData} selectedValue={(e: any) => { console.log(e); setTitleData(e) }} label="Title" options={titleNoList} idexpr={'titIdpk'} dataexp={'titName'} disabled={state.detailsPreview === 'disabledMode' ? true : false} useCallFunc />
                     <div className="mb-0.5">
                        <InputsTemplate label="Last Name" insertedValue={(e: any) => { setLastNameData(e.target.value) }} placeholder={lastNameData} disabledStatus={state.detailsPreview === 'disabledMode' ? true : false} />
                     </div>
                     <div className="mb-0.5">
                        <InputsTemplate label="First Name" insertedValue={(e: any) => { setFirstNameData(e.target.value) }} placeholder={firstNameData} disabledStatus={state.detailsPreview === 'disabledMode' ? true : false} />
                     </div>
                     <div className="mb-0.5">
                        <InputsTemplate label="Other Name" insertedValue={(e: any) => { setOtherNameData(e.target.value) }} placeholder={otherNameData} disabledStatus={state.detailsPreview === 'disabledMode' ? true : false} />
                     </div>
                     <div className="mb-0.5">
                        <InputsTemplate label="Legacy Name" insertedValue={(e: any) => { setLegacyNameData(e.target.value) }} placeholder={legacyNameData} disabledStatus={state.detailsPreview === 'disabledMode' ? true : false} />
                     </div>
                     <div className="mb-0.5">
                        <InputsTemplate label="Nick Name" insertedValue={(e: any) => { setNickNameData(e.target.value) }} placeholder={nickNameData} disabledStatus={state.detailsPreview === 'disabledMode' ? true : false} />
                     </div>
                     <div className="mb-0.5">
                        <InputsTemplate label="Initials" insertedValue={(e: any) => { setInitialsData(e.target.value) }} placeholder={initialsData} disabledStatus={state.detailsPreview === 'disabledMode' ? true : false} />
                     </div>









                     <Form.Item style={{ display: 'inline-block', marginBottom: '2px' }} label={<p className='text-xs'>{'Active'}</p>} className='w-full mb-1'>


                        <div className=" items-end">

                           <div className='mr-4 flex  flex-row justify-between  '>
                              <div>
                                 <Checkbox indeterminate={indeterminate} onChange={() => onChange()} checked={checkBox} />
                              </div>
                              <ul className='flex'>
                                 {state.detailsPreview === 'updateMode' ? <UpdateButton handleUpdate={async () => {

                                    try {
                                       console.log({
                                          "enmDate": `${dayjs(dateData).format()}`,
                                          "enmStaffNo": staffNoData,
                                          "enmLastName": lastNameData,
                                          "enmFirstName": firstNameData,
                                          "enmOtherNames": otherNameData,
                                          "enmLegacyName": legacyNameData,
                                          "enmInitials": initialsData,
                                          "enmNickname": nickNameData,
                                          "enmReasonIDfk": reasonData,
                                          "enmTitleIDfk": isNaN(titleData) === false ? titleData : titleNoList!.filter(title => title.titName === titleData)[0]['titIdpk'],
                                          "enmOrgIdfk": 0,
                                          "enmActive": checkBox,
                                          "enmRmks": remarksData,
                                          "enmEditedBy": 0,
                                          "enmEditedDate": `${dayjs("00:00:00", "HH:mm:ss").format()}`

                                       })

                                       await onFinish();
                                       if (errorFound === false) {
                                          const res = await axios.put(`${api_url}/StaffNameDetails/UpdateEmpNameDetails`, (
                                             //change values to int
                                             {
                                                "enmDate": `${dayjs(dateData).format()}`,
                                                "enmStaffNo": staffNoData,
                                                "enmLastName": lastNameData,
                                                "enmFirstName": firstNameData,
                                                "enmOtherNames": otherNameData,
                                                "enmLegacyName": legacyNameData,
                                                "enmInitials": initialsData,
                                                "enmNickname": nickNameData,
                                                "enmReasonIDfk": reasonData,
                                                "enmTitleIDfk": isNaN(titleData) === false ? titleData : titleNoList!.filter(title => title.titName === titleData)[0]['titIdpk'],
                                                "enmOrgIdfk": 0,
                                                "enmActive": checkBox,
                                                "enmRmks": remarksData,
                                                "enmEditedBy": 0,
                                                "enmEditedDate": `${dayjs("00:00:00", "HH:mm:ss").format()}`

                                             }
                                          ))

                                       }
                                    } catch (error: any) {
                                       return;
                                       // set error

                                    } finally {

                                       // setLoading(false); //loading state to false
                                    }
                                 }} /> : <SaveButton handleSave={async () => {


                                    try {

                                       await onFinish();
                                       if (errorFound === false) {
                                          const res = await axios.post(`${api_url}/StaffNameDetails/CreateEmpNameDetail`, (
                                             {
                                                "enmDate": `${dayjs(dateData).format()}`,
                                                "enmStaffNo": staffNoData,
                                                "enmLastName": lastNameData,
                                                "enmFirstName": firstNameData,
                                                "enmOtherNames": otherNameData,
                                                "enmLegacyName": legacyNameData,
                                                "enmInitials": initialsData,
                                                "enmNickname": nickNameData,
                                                "enmReasonIDfk": reasonData,
                                                "enmTitleIDfk": titleData,
                                                "enmOrgIdfk": 0,
                                                "enmActive": checkBox,
                                                "enmRmks": remarksData,
                                                "enmCreatedBy": 0
                                             }
                                          ))
                                       }



                                    } catch (error: any) {
                                       // set error
                                       console.log(error);
                                    } finally {
                                       dispatch({
                                          type: 'add_Data',
                                          newData: {
                                             "enmDate": `${dayjs(dateData).format()}`,
                                             "enmStaffNo": staffNoData,
                                             "enmLastName": lastNameData,
                                             "enmFirstName": firstNameData,
                                             "enmOtherNames": otherNameData,
                                             "enmLegacyName": legacyNameData,
                                             "enmInitials": initialsData,
                                             "enmNickname": nickNameData,
                                             "enmReasonIDfk": reasonData,
                                             "enmTitleIDfk": isNaN(titleData) === false ? titleData : titleNoList!.filter(title => title.titName === titleData)[0]['titIdpk'],
                                             "enmOrgIdfk": 0,
                                             "enmActive": checkBox,
                                             "enmRmks": remarksData,
                                             "enmEditedBy": 0,
                                             "enmEditedDate": `${dayjs("00:00:00", "HH:mm:ss").format()}`

                                          }
                                       });
                                    }
                                 }} />}

                                 {state.detailsPreview === 'disabledMode' ? <NewButton useCallbackFunc={true} new_button_toggler={() => {
                                    resetForm();

                                    toggleForm('newMode');

                                 }} /> : <Cancel handleCallbackCancel={() => {
                                    resetForm();
                                    toggleForm('disabledMode');

                                 }} />}
                              </ul>
                           </div>
                        </div>
                     </Form.Item>

                  </Form>
                  <Form.Item style={{ display: 'inline-block', marginBottom: '1px' }} label={<p className='text-xs'>{'Remarks'}</p>} className='w-2/3 mb-1 mr-2'>

                     <TextArea onChange={(e: any) => { setRemarksData(e.target.value) }} disabled={state.detailsPreview === 'disabledMode' ? true : false} key={'rmks'} defaultValue={remarksData} style={{ resize: 'none', maxHeight: '297px', minHeight: '297px', height: '297px', }} className='o overflow-y-scroll resize-none' rows={3} />


                  </Form.Item>

               </div>


            </div>}

            <div className="pt-0.5">
               <DataGrid
                  dataSource={state.tableData}
                  showBorders={true}
                  columnWidth={150}
                  showRowLines={true}


                  showColumnLines={true}
                  //Remember to change
                  height={toggled === true ? tableHeight - 310 : tableHeight + 40}

                  onRowClick={(e) => {
                     console.log(e);
                     toggleForm('disabledMode');
                     clickUser(e.data)
                  }}

                  onRowDblClick={() => {
                     toggleForm('updateMode');

                  }}

               >
                  <SearchPanel visible={true} width={200} />
                  <GroupPanel visible={true} />
                  <Paging defaultPageSize={0} />
                  <Pager
                     visible={false}

                     showInfo={true}
                     showNavigationButtons={true}
                     showPageSizeSelector={true}
                  />

                  {/* STAFF DETAILS COLUMNS */}
                  {/* FIXED COLUMNS */}

                  {
                     staff_name_TH.map((data) => {

                        let dataType: "string" | "number" | "boolean" | "object" | "date" | "datetime" | undefined = data.dataType as "string" | "number" | "boolean" | "object" | "date" | "datetime" | undefined;

                        return (
                           <Column

                              key={data.id}
                              caption={data.caption}
                              dataField={data.dataField}
                              dataType={dataType}
                              alignment={"left"}

                              fixed={data.fixed}

                              format={data.format}
                              width={data.width === null ? 150 : data.width}
                           />
                        )

                     })
                  }

                  <Scrolling columnRenderingMode="virtual" />



                  <HeaderFilter visible={true} />



                  <SearchPanel visible={true} width={200} />
                  <GroupPanel visible={true} />








               </DataGrid>
            </div>
         </div>
      </>

   );
}

export default StaffNameDetails;

