import { useState, useCallback, useEffect } from "react";

import { DatePicker, Form, Input, Radio, Select } from "antd";
import TextArea from "antd/es/input/TextArea";

import save from "../../../../../../assets/floppy-disk.png";
import reset from "../../../../../../assets/reset.png";

import dayjs, { Dayjs } from "dayjs";
import { Maintenance_activities_tab2_Select } from "../data/Maintenance_selects";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";
import useFetch from "../../../../../../hooks/useFetch";
import { ModalTemplate } from "../../../../../../templates/modal";
import {
  Cancel,
  NewButton,
  SaveButton,
  UpdateButton,
} from "../../../../../accessories/buttons";
import {
  api_url,
  currentDate,
  saveModalData,
  updateModalData,
} from "../../../../../accessories/component_infos";
import {
  formModalStates,
  selectsTypes,
} from "../../../../../human-resource/setups/data/types/selectsTypes";
import { SelectsTemplate } from "../../../../../../templates/select";
import { PostDataFunc } from "../../../../../../functions/post";
import { useAccountInfo } from "../../../../../../hooks/useAccountInfo";
import { RangePickerProps } from "antd/es/date-picker";
import { UpdateDataFunc } from "../../../../../../functions/update";
import { useFormPriviledge } from "../../../../../../hooks/useFormPriviledge";
import {
  setGeneralValue,
  settask_footer_table_selected,
  settask_form_disable,
} from "../../../../../../features/generalSlice";
import {
  task_footer_update,
  task_form_disable,
} from "../../../../../../features/Task&Todo/Task/TaskDetails";
import { GetDataFunc } from "../../../../../../functions/get";
import { useBusyLoader } from "../../../../../../hooks/useBusyLoader";
import { debounce, set, size } from "lodash";
import { InputsTemplate } from "../../../../../../templates/input";
import { DateTemplate } from "../../../../../../templates/date";
import { FormModal } from "../../../../../accessories/formModal";
import { DropdownGrid_template } from "../../../../../../templates/DropdownGrid";
import {
  vehicleReg_cols,
  workshop_cols,
} from "../../Maintenance/Maintenance-Requests/data/maintenance-requests-data";
import { reportingDriver_col } from "../data/datagrid_cols";
import InputSelect_template from "../../../../../../templates/InputSelect";
import { PostRecord } from "../data/Post_Data";
import { useCrudFunc } from "../../../../../../functions/crud";
import { current } from "@reduxjs/toolkit";
import { Width } from "devextreme-react/cjs/chart";
import { fetchMainType } from "../../../../../../features/apiCalls/maintenanceScheduleApi";
import {
  fetchArrivalCon,
  fetchPMmaintType,
} from "../../../../../../features/apiCalls/maintCapApi";
import { Tooltip } from "devextreme-react";

interface props {
  // this means whether a prop is passed on the component or not the component called
  // anywhere should work (i.e. with the ?) but without the ? it takes it's strict rule
  // that without a prop there will be an error
  toggler?: () => void;
  datgridCol?: any;
  customHeightHook?: any;
  openedTask?: any;
  mainType?: any;
  userInfox?: any;
  setrefresh?: (e: any) => void;
  selectedRecord?: any;
  refresh?: any;
  opened?: () => void;
  updateStates?: (key: string, value: any) => void;
  departureTime?: any;
  arrivalTime?: any;
  // regNo?: any;
}

export const ActivitiesCapture_tab2_Details = ({
  toggler,
  datgridCol,
  opened,
  customHeightHook,
  openedTask,
  selectedRecord,
  setrefresh,
  refresh,
  updateStates,
  arrivalTime,
  departureTime,
  mainType,
  userInfox,
}: props) => {
  const [selectedTask_update, setSelectedTask_update] = useState();
  const [fuelLevels, setFuelLevel] = useState<any>([]);

  const [employeeId, userId, userInfo] = useAccountInfo();
  // const [states, setStates] = useState<any>(stateBody);

  type StateType = {
    vehNo: { name: string; id: number };
    maintenanceType: { name: string; id: number };
    reportingDriver: { name: string; id: number };
    workShop: { name: string; id: number };
    arrivalCondition: { name: string; id: number };
    saveOdoModals: boolean;
    arrivalTime: any;
    departureTime: any;
    date: string;
    job: string;
    odometerIn: number;
    odometerOut: number;
    debit3: string;
    fuelIn: number;
    fuelOut: number;
    remarks: string;
    isOpenDropdown1?: boolean;
    isOpenDropdown2?: boolean;
    isLoading: boolean;
  };
  const [states, setStates] = useState<any>({
    vehNo: { name: "", id: 0 },
    maintenanceType: { name: "", id: 0 },
    reportingDriver: { name: "", id: 0 },
    workShop: { name: "", id: 0 },
    arrivalCondition: { name: "", id: 0 },
    saveOdoModals: false,
    arrivalTime: currentDate,
    departureTime: currentDate,
    date: "",
    job: "",
    odometerIn: 0,
    odometerOut: 0,
    debit3: 0,
    fuelIn: 0,
    fuelOut: 0,
    remarks: "",
    isOpenDropdown1: false,
    isOpenDropdown2: false,
    isLoading: false,
  });

  console.log("userInfox", userInfox);
  console.log("#$@^", states);
  console.log("#", mainType);
  const [searchMaintType, setSearchMaintType] = useState<
    string | undefined | null
  >("");
  const [searchAC, setSearchAC] = useState<string | undefined | null>("");
  const selectedMaintainanceChecklist = useSelector(
    (state: RootState) => state.general.maintenanceChecklist
  );
  const saveMaintenanceChecklist = useSelector(
    (state: RootState) => state.general.savedItems
  );
  const saveInventoryChecklist = useSelector(
    (state: RootState) => state.general.saveInventorys
  );
  console.log("saveMaintenanceChecklist", saveMaintenanceChecklist);
  // console.log("maintenanceChecklist", selectedMaintainanceChecklist);
  const inventorySelected = useSelector(
    (state: RootState) => state.general.inventorySelected
  );
  // console.log("inventorySelected", inventorySelected);
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  // states handler
  const setState = (key: string, value: any) => {
    setStates((prev: any) => ({ ...prev, [key]: value }));
  };
  const updateMode = useSelector(
    (state: RootState) => state.addTask.taskFooterUpdate
  );
  const inventoryChecklist = useSelector(
    (state: RootState) => state.general.inventorySelected
  );
  const maintTypeData = useSelector(
    (state: RootState) => state.maintCap.maintTypeData
  );
  const arrivalConData = useSelector(
    (state: RootState) => state.maintCap.arrivalConData
  );
  // console.log("arrivalConData", arrivalConData);
  // console.log("maintTypeData", maintTypeData);
  // Endpoint
  const [vehNo_data, vehNo_error, vehNo_loading] = useFetch(
    "AsmTmsEntMaintenanceActivity/GetEntAsmTmsLueVehicleNos?pagenumber=1&pagesize=10000"
  );
  const [mainT_data, mainT_error, mainT_loading] = useFetch(
    `PmActivitiesCaptures/GetAllAsmTmsMaintenanceTypes?pagenumber=1&pagesize=1&search=${searchMaintType}`
  );

  const preventiveMaintenance = mainT_data?.map((item) => ({
    mtpIdpk: 6,
    mtpName: "Preventive Maintenance",
  }));

  console.log("preventiveMaintenanceIds", preventiveMaintenance);
  const [workshop_data, workshop_error, workshop_loading] = useFetch(
    `PmActivitiesCaptures/GetAllVwAsmTmsWorkshopDetailsFewCols`
  );
  // const [rep_driver, rep_driver_error, rep_driver_loading] = useFetch(
  //   `PmActivitiesCaptures/GetAllVwHrEmpDrivers?empIDx=${employeeId}`
  // );
  // const [req_data, req_error, req_loading] = useFetch(
  //   `AsmRstMaintenanceActivity/GetLueMaintenanceRequestedBy`
  // );
  const [rdriver_data, rdriver_error, rdriver_loading] = useFetch(
    "AsmTmsEntMaintenanceActivity/GetEntAsmTmsLueReportingDrivers"
  );
  const [arrivalConditionData, arrivalConditionError, arrivalConditionLoading] =
    useFetch(
      `PmActivitiesCaptures/GetAllAsmTmsPmActivityArrivalCondition?pagenumber=1&pagesize=20&search=${searchAC}`
    );
  const [vehicleData, vehicleError, vehicleLoading] = useFetch(
    `PmActivitiesCaptures/GetAllVwAsmTmsVehicleDetail3IDs?vdtRegNo=${states.vehNo?.name}`
  );
  const [lookupData, lookupError, lookupLoading] = useFetch(
    `PmActivitiesCaptures/GetEntryLookups?pagenumber=1&pagesize=50`
  );
  // console.log("lookupData", lookupData);
  // console.log("vehicleData", vehicleData);
  const [busyLoader, setBusyLoader] = useBusyLoader();
  const FuelLevels = async () => {
    const res = await GetDataFunc(`AsmTmsVehicleLogBook/GetAllFuelLevels`);

    setFuelLevel(res.data.slice(0, 5));
    // console.log(res.data);
  };
  // IF the data is invalid then uses this as the default
  const defaultFuelLevel = [
    { id: 1, name: "0" },
    { id: 2, name: "1/2" },
    { id: 3, name: "1/4" },
    { id: 4, name: "3/4" },
    { id: 5, name: "full" },
  ];

  // console.log("STATES", states);

  const dateFormat = "DD MMM YYYY HH:mm";

  const updateState = (stateName: string, value: any) => {
    setStates((currentState: any) => ({ ...currentState, [stateName]: value }));
  };

  const resetFields = () => {
    // dispatch(setGeneralValue({ expr: "mainReqClear", value: true }));
    updateState("vehNo", { id: 0, name: "" });
    updateState("maintenanceType", { id: 0, name: "" });
    updateState("reportingDriver", { id: 0, name: "" });
    updateState("workShop", { id: 0, name: "" });
    updateState("arrivalCondition", { id: 0, name: "" });
    updateStates && updateStates("arrivalTime", currentDate);
    updateStates && updateStates("departureTime", currentDate);
    updateState("date", "");
    updateState("job", "");
    updateState("odometerIn", 0);
    updateState("odometerOut", 0);
    updateState("debit3", 0);
    updateState("fuelIn", "");
    updateState("fuelOut", "");
    updateState("remarks", "");
  };

  // Modal dialog data states

  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [Posting, Updating] = useCrudFunc();

  const dispatch = useDispatch();
  useEffect(() => {
    FuelLevels();
    // console.log(datgridCol);
  }, [datgridCol]);
  // console.log("selectedMaintainanceChecklist", selectedMaintainanceChecklist);
  const disablex = useSelector(
    (state: RootState) => state.addTask.taskFormStatus
  );

  // console.log("SWL", selectedRecord);

  const populateFields = (clear?: boolean) => {
    if (clear === true) {
      dispatch(settask_footer_table_selected([]));
    }
    const gridState = [
      {
        key: "arrivalTime",
        value: clear ? currentDate : selectedRecord?.pmaDateIn,
      },
      {
        key: "departureTime",
        value: clear ? currentDate : selectedRecord?.pmaDateOut,
      },
      {
        key: "vehNo",
        value: clear
          ? { id: 0, name: "" }
          : {
              id: selectedRecord?.vdtIDpk ?? openedTask?.vdtIDpk,
              name: selectedRecord?.vdtRegNo ?? openedTask?.vdtRegNo,
            },
      },
      {
        key: "maintenanceType",
        value: clear
          ? { id: 0, name: "" }
          : {
              id: mainType?.mtpIdpk ?? selectedRecord?.pmaMaintenanceTypeIDfk,
              name: mainType?.mtpName ?? selectedRecord?.mtpName,
            },
      },
      {
        key: "workShop",
        value: clear
          ? { id: 0, name: "" }
          : {
              id: selectedRecord?.wsdIDpk ?? openedTask?.wsdIDpk,
              name: selectedRecord?.wsdName ?? openedTask?.pmWorkshopName,
            },
      },
      {
        key: "reportingDriver",
        value: clear
          ? { id: 0, name: "" }
          : {
              id: selectedRecord?.driverEmpID ?? userInfox?.id,
              name: selectedRecord?.driverEmpName ?? userInfox?.mtpName,
            },
      },
      {
        key: "arrivalCondition",
        value: clear
          ? { id: 0, name: "" }
          : {
              id: 0,
              name: selectedRecord?.pmaArrivalCondition,
            },
      },
      { key: "odometerIn", value: clear ? 0 : selectedRecord?.pmaOdometerIn },
      { key: "odometerOut", value: clear ? 0 : selectedRecord?.pmaOdometerOut },
      { key: "debit3", value: clear ? 0 : selectedRecord?.distanceCovered },
      { key: "fuelIn", value: clear ? 0 : selectedRecord?.fuelLevelInID },
      { key: "fuelOut", value: clear ? 0 : selectedRecord?.fuelLevelOutID },
      { key: "remarks", value: clear ? "" : selectedRecord?.pmaRmks },
    ];
    for (let instance of gridState) setState(instance.key, instance.value);
  };

  // console.log("STATES", states);

  const dates: any[] = [
    {
      id: 0,
      label: "Arrival Time ",
      stateName: "arrivalTime",
      defaultValue: states.arrivalTime,
    },
    {
      id: 1,
      label: "Departure Time ",
      stateName: "departureTime",
      defaultValue: states.departureTime,
    },
  ];
  const inputs: any[] = [
    {
      id: 0,
      label: "Odometer In ",
      stateName: "odometerIn",
      defaultValue: states.odometerIn,
      style: "",
      meterholder: "",
    },
    {
      id: 1,
      label: "Odometer Out ",
      stateName: "odometerOut",
      defaultValue: states.odometerOut,
      style: "",
      meterholder: "0km",
    },
  ];
  //Reg No map
  const RegNo = [
    {
      id: 0,
      label: "Reg. No",
      optionsData: vehNo_data,
      nameKey: "vdtRegNo",
      idKey: "vdtIDpk",
      defaultValue: `${states.vehNo?.name ?? ""}`,
      dataExpr: "vehNo",
    },
  ];
  //work,reportingDrv,
  const workRep = [
    {
      id: 0,
      label: "Workshop",
      optionsData: workshop_data,
      nameKey: "wsdName",
      idKey: "wsdIdpk",
      defaultValue: states.workShop?.name ?? "",
      dataExpr: "workShop",
    },
    {
      id: 1,
      label: "Reporting Driver",
      optionsData: rdriver_data,
      nameKey: "empNameStaffNo",
      idKey: "empIDpk",
      defaultValue: states.reportingDriver?.name ?? "",
      dataExpr: "reportingDriver",
    },
  ];
  // const dropDowns: selectsTypes[] = [

  //   {
  //     id: 0,
  //     label: "Maintenance Type",
  //     optionsData: mainT_data,
  //     nameKey: "mtpName",
  //     idKey: "mtpIdpk",
  //     defaultValue: `${states.maintenanceType?.name?? ""}`,
  //     dataExpr: "maintenanceType",
  //   },

  // ];
  if (openedTask !== undefined) {
    console.log(openedTask);
  }

  // console.log("vehicle", states.vehNo?.mdlShtName);
  //useEffect....
  useEffect(() => {
    populateFields(false);
    // updateStates && updateStates("regNo",states.vehNo);
  }, [selectedRecord, openedTask]);

  //validation
  function validate() {
    if (
      arrivalTime === "" ||
      arrivalTime === null ||
      arrivalTime === undefined
    ) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select arrival time",
        title: "Arrival Time",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      departureTime === "" ||
      departureTime === null ||
      departureTime === undefined
    ) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select departure time",
        title: "Departure Time",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.vehNo === undefined || states.vehNo === null) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select registration number",
        title: "Registration Number",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      states.maintenanceType === undefined ||
      states.maintenanceType === null
    ) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select maintenance type",
        title: "Maintenance Type",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.workShop === undefined || states.workShop === null) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select workshop ",
        title: "Workshop",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      states.reportingDriver === undefined ||
      states.reportingDriver === null
    ) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select a reporting driver",
        title: "Reporting Driver",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.fuelIn === "" || states.fuelIn === undefined) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select fuel In",
        title: "Select Fuel In ",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.fuelOut === "" || states.fuelOut === undefined) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select fuel Out",
        title: "Select fuel Out",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      states.odometerIn === 0 ||
      states.odometerOut === 0 ||
      states.odometerIn === null ||
      states.odometerOut === null ||
      states.odometerIn === undefined ||
      states.odometerOut === undefined
    ) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please enter the odometer reading",
        title: "Odometer Reading",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (isNaN(Number(states.odometerIn.toString()))) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please input numbers only",
        title: " Invalid Input for Odometer In!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (isNaN(Number(states.odometerOut.toString()))) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please input numbers only",
        title: " Invalid Input for Odometer Out!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (openedTask?.mrqIDpk === 0 || openedTask?.mrqIDpk === undefined) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Vehicle must go through Maintenance Request!",
        title: "Unexpected!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (inventorySelected === undefined) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select an Inventory Checklist",
        title: "Select Inventory Checklist!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (selectedMaintainanceChecklist === undefined) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select a Maintenance Checklist",
        title: "Select Maintenance Checklist!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else {
      setIcon("question");
      setshowModalDisableButton(false);
      setModalData({
        message: "Are you sure you want to save this record?",
        title: "Save Data? ",
        okText: "Yes, save",
      });
      setOpenModal(true);
      // setState("openSelectedTask", states.selectedTask_update);
    }
  }
  function validateUpdate() {
    if (
      arrivalTime === "" ||
      arrivalTime === null ||
      arrivalTime === undefined
    ) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select arrival time",
        title: "Arrival Time",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      departureTime === "" ||
      departureTime === null ||
      departureTime === undefined
    ) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select departure time",
        title: "Departure Time",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.vehNo.name === undefined || states.vehNo.name === "") {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select registration number",
        title: "Registration Number",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      states.maintenanceType === undefined ||
      states.maintenanceType === null
    ) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select maintenance type",
        title: "Maintenance Type",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.workShop === undefined || states.workShop === null) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select workshop ",
        title: "Workshop",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      states.reportingDriver === undefined ||
      states.reportingDriver === null
    ) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select a reporting driver",
        title: "Reporting Driver",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.fuelIn === "" || states.fuelIn === undefined) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select fuel In",
        title: "Select Fuel In ",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.fuelOut === "" || states.fuelOut === undefined) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select fuel Out",
        title: "Select fuel Out",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      states.odometerIn === 0 ||
      states.odometerOut === 0 ||
      states.odometerIn === null ||
      states.odometerOut === null ||
      states.odometerIn === undefined ||
      states.odometerOut === undefined
    ) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please enter the odometer reading",
        title: "Odometer Reading",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (isNaN(Number(states.odometerIn.toString()))) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please input numbers only",
        title: " Invalid Input for Odometer In!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (isNaN(Number(states.odometerOut.toString()))) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please input numbers only",
        title: " Invalid Input for Odometer Out!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      selectedRecord?.mrqIDpk === 0 ||
      selectedRecord?.mrqIDpk === undefined
    ) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Vehicle must go through Maintenance Request!",
        title: "Unexpected!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (inventorySelected === undefined) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select an Inventory Checklist to update",
        title: "Select Inventory Checklist!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (selectedMaintainanceChecklist === undefined) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select a Maintenance Checklist to update",
        title: "Select Maintenance Checklist!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else {
      setIcon("question");
      setshowModalDisableButton(false);
      setModalData({
        message: "Are you sure you want to update this record?",
        title: "Update Pm details?",
        okText: "Yes, update",
      });
      setOpenModal(true);
      // setState("openSelectedTask", states.selectedTask_update);
    }
  }
  function validateOdometer() {
    if (
      arrivalTime === "" ||
      arrivalTime === null ||
      arrivalTime === undefined
    ) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select arrival time",
        title: "Arrival Time",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      departureTime === "" ||
      departureTime === null ||
      departureTime === undefined
    ) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select departure time",
        title: "Departure Time",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.vehNo === undefined || states.vehNo === null) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select registration number",
        title: "Registration Number",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.workShop === undefined || states.workShop === null) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select workshop ",
        title: "Workshop",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.fuelIn === 0 || states.fuelIn === undefined) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select fuel In",
        title: "Select Fuel In ",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.fuelOut === 0 || states.fuelOut === undefined) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select fuel Out",
        title: "Select fuel Out",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      states.odometerIn === 0 ||
      states.odometerOut === 0 ||
      states.odometerIn === null ||
      states.odometerOut === null ||
      states.odometerIn === undefined ||
      states.odometerOut === undefined
    ) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please enter the odometer reading",
        title: "Odometer Reading",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (isNaN(Number(states.odometerIn?.toString()))) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please input numbers only",
        title: " Invalid Input for Odometer In!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (isNaN(Number(states.odometerOut?.toString()))) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please input numbers only",
        title: " Invalid Input for Odometer Out!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else {
      setIcon("question");
      setshowModalDisableButton(false);
      setModalData({
        message: "Are you sure you want to update odometer reading?",
        title: "Update odometer? ",
        okText: "Yes, update",
      });
      setOpenModal(true);
      // setState("openSelectedTask", states.selectedTask_update);
    }
  }

  //save button
  const saveAccessPriv = () => {
    if (savePriv === true) {
      validate();
    } else {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({ message: "No Access Privilege", title: "Access Denied!" });
    }
  };

  const updateAccessPriv = () => {
    if (updatePriv === true) {
      validateUpdate();
    } else {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({ message: "No Access Privilege", title: "Access Denied!" });
    }
  };

  //async function postData() {
  const postData = async () => {
    try {
      setBusyLoader("Saving Pm Details....");
      const res = await Posting(
        `PmActivitiesCaptures/CreateAsmTmsPmActivity`,
        {
          pmaVehicleDetailIdfk: states.vehNo.id,
          pmaDriverEmpIdfk: states.reportingDriver.id,
          pmaWorkshopIdfk: states.workShop.id,
          pmaDateIn: arrivalTime,
          pmaDateOut: departureTime,
          pmaMaintenanceTypeIdfk: states.maintenanceType.id,
          pmaRequestIdfk: openedTask?.mrqIDpk ?? 0,
          pmaArrivalCondition: states.arrivalCondition.name,
          pmaOdometerIn: states.odometerIn,
          pmaOdometerOut: states.odometerOut,
          pmaFuelLevelInIdfk: states.fuelIn,
          pmaFuelLevelOutIdfk: states.fuelOut,
          pmaRmks: states.remarks,
          pmaCreatedBy: userId,
        },
        `Created the Asset Mgr-Trans-PM Activity with record number`
      );

      const pmaIDpk = res.data.id;
      for (let p = 0; p < inventorySelected.length; p++) {
        setBusyLoader("");
        setBusyLoader("Saving Inventory Checklist Item....");
        await Posting(
          `PmActivitiesCaptures/CreateAsmTmsPmMaintenanceInventoryChecklistDetail`,
          {
            pidPmActivityIdfk: pmaIDpk,
            PidChecklisttemIdfk: inventorySelected[p].mtiIDpk,
            pidBefore: inventorySelected[p].pidBefore,
            pidAfter: inventorySelected[p].pidAfter,
            pidRmks: inventorySelected[p].pidRmks ?? "",
            pidCreatedBy: userId,
          },
          `Created Inventory Checklist for ID ${pmaIDpk}`
        );
      }
      setBusyLoader("");
      setBusyLoader("Saving Maintenance Checklist Item....");
      for (let j = 0; j < selectedMaintainanceChecklist.length; j++) {
        await Posting(
          `PmActivitiesCaptures/CreateAsmTmsPmMaintenanceActivityDetails`,
          {
            padPreventiveMaintenanceActivityIDfk: pmaIDpk,
            padMaintenanceItemIDfk: selectedMaintainanceChecklist[j].mtiIDpk,
            padFaulty: selectedMaintainanceChecklist[j].padFaulty,
            padReplaced: selectedMaintainanceChecklist[j].padReplaced,
            padRmks: selectedMaintainanceChecklist[j].padRmks ?? "",
            padCreatedBy: userId,
          },
          `Created Maintainance Checklist for ID ${pmaIDpk}`
        );
      }

      await Updating(
        `PmActivitiesCaptures/UpdateAsmTmsMaintenanceRequestsSetToOpen?mrqIDx=${
          openedTask?.mrqIDpk ?? 0
        }`,
        {},
        ""
      );
      setrefresh && setrefresh(!refresh);
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: "Pm details saved successfully.",
        title: "Data Saved!",
        okText: "Ok",
      });
      dispatch(task_form_disable(true));
      dispatch(settask_form_disable(true));
      setBusyLoader("");
    } catch (err: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message:
          "Failed to create Pm Activities, please contact system admin for assistance!",
        title: "Error",
        okText: "Ok",
      });
      setBusyLoader("");
    }
  };
  //update pm capture
  const pmUpdate = async () => {
    try {
      setBusyLoader("Updating Pm details....");
      await Updating(
        `PmActivitiesCaptures/UpdateAsmTmsPmActivity`,
        {
          pmaIDpk: selectedRecord?.pmaIDpk,
          pmaVehicleDetailIdfk: states.vehNo.id,
          pmaDriverEmpIdfk: states.reportingDriver.id,
          pmaWorkshopIdfk: states.workShop.id,
          pmaDateIn: arrivalTime,
          pmaDateOut: departureTime,
          pmaMaintenanceTypeIdfk: states.maintenanceType.id,
          pmaRequestIdfk: selectedRecord?.mrqIDpk,
          pmaArrivalCondition: states.arrivalCondition.name,
          pmaOdometerIn: states.odometerIn,
          pmaOdometerOut: states.odometerOut,
          pmaFuelLevelInIdfk: states.fuelIn,
          pmaFuelLevelOutIdfk: states.fuelOut,
          pmaRmks: states.remarks,
          pmaEditedBy: userId,
          pmaEditedDate: currentDate,
        },
        "Closed the Asset Mgr-Trans-PM Activities Capture form successfully"
      );
      await Updating(
        `PmActivitiesCaptures/UpdateAsmTmsMaintenanceRequestsSetToOpen?mrqIDx=${selectedRecord?.mrqIDpk}`,
        {},
        ""
      );

      for (let i = 0; i < saveInventoryChecklist.length; i++) {
        Posting(
          `PmActivitiesCaptures/CreateAsmTmsPmMaintenanceInventoryChecklistDetail`,
          {
            pidPmActivityIdfk: selectedRecord?.pmaIDpk,
            PidChecklisttemIdfk: saveInventoryChecklist[i].mtiIDpk,
            pidBefore: saveInventoryChecklist[i].pidBefore,
            pidAfter: saveInventoryChecklist[i].pidAfter,
            pidRmks: saveInventoryChecklist[i].pidRmks,
            pidCreatedBy: userId,
          },
          `Updated Inventory Checklist Item for ID ${selectedRecord?.mrqIDpk} `
        );
      }
      for (let j = 0; j < saveMaintenanceChecklist.length; j++) {
        Posting(
          `PmActivitiesCaptures/CreateAsmTmsPmMaintenanceActivityDetails`,
          {
            padPreventiveMaintenanceActivityIDfk: selectedRecord?.pmaIDpk,
            padMaintenanceItemIDfk: saveMaintenanceChecklist[j].mtiIDpk,
            padFaulty: saveMaintenanceChecklist[j].padFaulty,
            padReplaced: saveMaintenanceChecklist[j].padReplaced,
            padRmks: saveMaintenanceChecklist[j].padRmks,
            padCreatedBy: userId,
          },
          `Updated Inventory Checklist Item for ID ${selectedRecord?.mrqIDpk} `
        );
      }
      setrefresh && setrefresh(!refresh);
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: "Pm details updated successfully.",
        title: "Data Updated!",
        okText: "Ok",
      });
      dispatch(task_form_disable(true));
      dispatch(settask_form_disable(true));
      setBusyLoader("");
    } catch (e: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message:
          "Failed to Update Pm Activities.Please contact system admin for assistance!",
        title: "Error",
        okText: "Ok",
      });
      setBusyLoader("");
    }
  };

  const updateOdometer = async () => {
    try {
      setBusyLoader("Updating odometer reading");
      await Updating(
        `PmActivitiesCaptures/UpdateAsmTmsPmAcitvitiesOdometerReading?PmaIDpk=${selectedRecord?.pmaIDpk}`,
        {
          pmaDateIn: arrivalTime,
          pmaDateOut: departureTime,
          pmaOdometerIn: states.odometerIn,
          pmaOdometerOut: states.odometerOut,
          pmaFuelLevelInIDfk: states.fuelIn,
          pmaFuelLevelOutIDfk: states.fuelOut,
        }
      );
      setrefresh && setrefresh(!refresh);
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: "Odometer reading updated successfully.",
        title: "Odometer updated!",
        okText: "Ok",
      });
      dispatch(task_form_disable(true));
      dispatch(settask_form_disable(true));
      setBusyLoader("");
    } catch (e: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message:
          "Failed to update odometer reading, please contact system admin for assistance!",
        title: "Error",
        okText: "Ok",
      });
      setBusyLoader("");
    }
  };

  // eslint-disable-next-line arrow-body-style
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf("day");
  };

  const singleEndpoint1: any = fetchPMmaintType;
  const singleEndpoint2: any = fetchArrivalCon;

  const SaveForm = () => (
    <Form
      className="w-full h-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2  "
      name="basic"
      labelCol={{ span: 5 }}
      wrapperCol={{ span: 10 }}
      initialValues={{ remember: true }}
      autoComplete="off"
      size="small"
      layout="horizontal"
    >
      <div className="w-full h-full ">
        <SelectsTemplate
          label="Reg. No"
          selectStyle={{ width: 220 }}
          disabled={false}
          options={vehNo_data}
          useCallFunc
          idexpr={"vdtIDpk"}
          dataexp={"vdtRegNo"}
          placeHolder={states.vehNo?.name}
          selectedValue={(e) => {
            setState("vehNo", {
              id: e["vdtIDpk"],
              name: e["vdtRegNo"],
            });
          }}
        />

        {dates.map(({ id, label, stateName, defaultValue, style }) => {
          return (
            <>
              <DateTemplate
                key={id + "da"}
                label={label}
                selectedDate={dayjs(defaultValue)}
                changeDate={(date: Dayjs) => {
                  updateStates(stateName!, dayjs(date).format("YYYY-MM-DD"));
                }}
                disabled={false}
                width={"100%"}
              />
            </>
          );
        })}

        {inputs.map(
          ({ id, label, stateName, defaultValue, style, meterholder }) => {
            return (
              <>
                <InputsTemplate
                  useCallbackFunc
                  span
                  disabledStatus={false}
                  orderOnchange={(e) => {
                    setState(stateName!, e);
                  }}
                  labelColPan={5}
                  key={id}
                  label={label}
                  defaultValue={defaultValue}
                  inputStyle={{ width: 220 }}
                  additionalWidget={() => (
                    <p className="flex w-full bg-red-500">{meterholder}</p>
                  )}
                />
              </>
            );
          }
        )}
        <Form.Item
          style={{ width: "520px", marginLeft: "" }}
          labelCol={{ span: 5 }}
          label={
            <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
              {"Fuel Guage In"}
            </p>
          }
        >
          <div
            style={{ borderColor: borderTheme }}
            className="w-[400px] flex justify-start items-center border-[1px] mb-0.5 rounded-md"
          >
            <Radio.Group
              value={states.fuelIn}
              defaultValue={states.fuelIn}
              disabled={false}
              className="flex flex-wrap justify-center w-full pl-2 "
              onChange={(e) => {
                setState("fuelIn", e.target.value);
              }}
            >
              {!fuelLevels
                ? defaultFuelLevel?.map((fuelLevel: any, index: number) => {
                    return (
                      <Radio key={index} value={fuelLevel.value}>
                        {fuelLevel.name}
                      </Radio>
                    );
                  })
                : fuelLevels?.map((fuelLevel: any, index: number) => {
                    return (
                      <div className="my-2">
                        <Radio
                          key={fuelLevel.flvIdpk}
                          value={fuelLevel.flvIdpk}
                        >
                          {fuelLevel.flvName}
                        </Radio>
                      </div>
                    );
                  })}
            </Radio.Group>
          </div>
        </Form.Item>
        <Form.Item
          style={{ width: "520px", marginLeft: "" }}
          labelCol={{ span: 5 }}
          label={
            <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
              {"Fuel Guage Out"}
            </p>
          }
        >
          <div
            style={{ borderColor: borderTheme }}
            className="w-[400px] flex justify-start border-[1px] mb-0.5 rounded-md"
          >
            <Radio.Group
              value={states.fuelOut}
              defaultValue={states.fuelOut}
              disabled={false}
              className="flex flex-wrap justify-center w-full pl-2 "
              onChange={(e) => {
                setState("fuelOut", e.target.value);
              }}
            >
              {!fuelLevels
                ? defaultFuelLevel?.map((fuelLevel: any, index: number) => {
                    return (
                      <div className="">
                        <Radio key={index} value={fuelLevel.value}>
                          {fuelLevel.name}
                        </Radio>
                      </div>
                    );
                  })
                : fuelLevels?.map((fuelLevel: any, index: number) => {
                    return (
                      <div className="my-2 ">
                        <Radio
                          key={fuelLevel.flvIdpk}
                          value={fuelLevel.flvIdpk}
                        >
                          {fuelLevel.flvName}
                        </Radio>
                      </div>
                    );
                  })}
            </Radio.Group>
          </div>
        </Form.Item>

        <div className="w-[96%] flex justify-end py-0.5  ">
          <UpdateButton
            handleUpdate={() => {
              validateOdometer();
            }}
          />
        </div>
      </div>
    </Form>
  );

  const formModal: formModalStates[] = [
    {
      id: 0,
      label: "Transport - Update Odometer Readings",
      module: <SaveForm />,
    },
  ];

  //Access Priviledge

  const [privType, setPrivType] = useState<string>("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);
  //new button
  const newAccessPriv = () => {
    if (savePriv === true) {
      setStates({
        vehNo: { name: "", id: 0 },
        maintenanceType: { name: "", id: 0 },
        reportingDriver: { name: "", id: 0 },
        workShop: { name: "", id: 0 },
        arrivalTime: currentDate,
        departureTime: currentDate,
        arrivalCondition: "",
        saveOdoModals: false,
        date: "",
        job: "",
        odometerIn: 0,
        odometerOut: 0,
        debit3: "",
        fuelIn: "",
        fuelOut: "",
        remarks: "",
      });

      // prop state for selectedRecord
      updateStates && updateStates("selectedRecord", "");
      resetFields();
      dispatch(task_form_disable(false));
      dispatch(settask_form_disable(false));
    } else {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({ message: "No Access Privilege", title: "Access Denied!" });
    }
  };

  return (
    <div className="h-[462px] dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base  ">
      <ModalTemplate
        icon_width={modalData.iconSize === "large" ? 100 : 50}
        icon={icon}
        cancelText={modalData.cancelText}
        disableCancel={icon === "question" ? false : true}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        open={openModal}
        okHandler={() => {
          modalData.message === "Are you sure you want to save this record?"
            ? postData()
            : setOpenModal(false);
          modalData.message ===
          "Are you sure you want to update odometer reading?"
            ? updateOdometer()
            : setOpenModal(false);
          modalData.title === "Update Pm details?"
            ? pmUpdate()
            : setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
      />

      <FormModal
        height={1000}
        width={"30%"}
        openState={states.saveOdoModals}
        onCancel={() => {
          setState("saveOdoModals", false);
        }}
        child={formModal[0].module}
        title={formModal[0].label}
      />

      <div className="px-2 h-full ">
        <Form
          className="w-full h-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-0.5  "
          name="basic"
          labelCol={{ span: 8 }}
          // wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          size="small"
          layout="horizontal"
        >
          <div className="w-full h-full">
            {/* Block */}

            <Form.Item
              style={{ width: "340px" }}
              className=" "
              labelCol={{ span: 8 }}
              label={
                <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                  {"Arrival Time"}
                </p>
              }
            >
              <div className="w-full flex ">
                {/* <div className="flex flex-row"> */}
                <DatePicker
                  allowClear={false}
                  disabled={disablex}
                  format={dateFormat}
                  className="w-full"
                  onChange={(date, dateString) => {
                    updateStates(
                      "arrivalTime",
                      dayjs(date).format("YYYY-MM-DD")
                    );
                  }}
                  value={dayjs(arrivalTime)}
                  defaultValue={
                    arrivalTime === undefined
                      ? dayjs()
                      : dayjs(`${arrivalTime}`)
                  }
                />
              </div>
            </Form.Item>

            <Form.Item
              style={{ width: "340px" }}
              className=" "
              labelCol={{ span: 8 }}
              label={
                <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                  {"Departure Time"}
                </p>
              }
            >
              <div className="w-full flex ">
                <DatePicker
                  allowClear={false}
                  disabledDate={disabledDate}
                  disabled={disablex}
                  format={dateFormat}
                  className="w-full"
                  onChange={(date, dateString) => {
                    updateStates(
                      "departureTime",
                      dayjs(date).format("YYYY-MM-DD")
                    );
                  }}
                  value={dayjs(departureTime)}
                  defaultValue={
                    departureTime === undefined
                      ? dayjs()
                      : dayjs(`${departureTime}`)
                  }
                />
              </div>
            </Form.Item>

            {/* Selects */}
            <Form.Item style={{ width: "340px" }}>
              {RegNo.map(
                ({
                  id,
                  label,
                  optionsData,
                  nameKey,
                  defaultValue,
                  idKey,
                  dataExpr,
                }) => {
                  return (
                    <div className="w-full mb-0.5">
                      <DropdownGrid_template
                        KeyExpr={idKey}
                        label={label}
                        labelCol={8}
                        cusBtnStyle={true}
                        dropdownBtnStyles={{ width: 230, overflow: "hidden" }}
                        gridData={optionsData}
                        columns={vehicleReg_cols}
                        defaultValue={defaultValue}
                        disabled={disablex}
                        onRowClick={(e) => {
                          updateStates && updateStates("isGridData", false);
                          updateState(dataExpr!, {
                            id: e[idKey!],
                            name: e[nameKey!],
                          });
                          dispatch(
                            setGeneralValue({
                              expr: "RegNo",
                              value: { name: e[nameKey!], id: e[idKey!] },
                            })
                          );
                        }}
                        handleRefresh={() => {
                          updateState(dataExpr!, { id: 0, name: "" });
                          updateState(dataExpr!, !refresh);
                        }}
                      />
                    </div>
                  );
                }
              )}
              <Form.Item
                label={<p className="text-xs">{"Maintenance Type"}</p>}
                style={{ marginBottom: 0.5 }}
                labelCol={{ span: 8 }}
              >
                <InputSelect_template
                  placeHolder={states.maintenanceType?.name ?? ""}
                  handleRefresh={() => {
                    updateState("maintenanceType", { name: "", id: 0 });
                  }}
                  style={{ width: "259px" }}
                  options={preventiveMaintenance}
                  disabled={disablex}
                  useCallFunc={true}
                  idexpr={"mtpIdpk"}
                  dataexp={"mtpName"}
                  isOpenDropdown={states.isOpenDropdown1}
                  isLoading={states.isLoading}
                  handleFocus={(e: any) => {
                    updateState("isOpenDropdown1", !states.isOpenDropdown1);
                    // dispatch(singleEndpoint1(e));
                  }}
                  // handleSearch={useCallback(
                  //   debounce((e: any) => {
                  //     // updateState("maintenanceType"!, e);
                  //     // dispatch(singleEndpoint1(e));
                  //     // updateState("isLoading", true);
                  //     setSearchMaintType(e);
                  //     updateState("isOpenDropdown1", !states.isOpenDropdown1);
                  //   }, 500), // Adjust the debounce delay as needed
                  //   []
                  // )}
                  selectedValue={(e: any) => {
                    updateState("maintenanceType", {
                      name: e.mtpName!,
                      id: e.mtpIdpk!,
                    });
                    updateState("isOpenDropdown1", !states.isOpenDropdown1);
                    // dispatch(
                    //   setGeneralValue({
                    //     expr: "maintenanceType",
                    //     value: {
                    //       name: e.mtpName!,
                    //       id: e.mtpIdpk!,
                    //     },
                    //   })
                    // );
                  }}
                />
              </Form.Item>
              {/* </Form.Item> */}
              {workRep.map(
                ({
                  id,
                  label,
                  optionsData,
                  nameKey,
                  defaultValue,
                  idKey,
                  dataExpr,
                }) => {
                  return (
                    <div className="w-full mb-0.5 ">
                      <DropdownGrid_template
                        KeyExpr={idKey}
                        label={label}
                        labelCol={8}
                        cusBtnStyle={true}
                        dropdownBtnStyles={{ width: 230, overflow: "hidden" }}
                        gridData={optionsData}
                        columns={
                          label === "Workshop"
                            ? workshop_cols
                            : reportingDriver_col
                        }
                        defaultValue={defaultValue}
                        disabled={disablex}
                        onRowClick={(e) => {
                          updateState(dataExpr!, {
                            id: e[idKey!],
                            name: e[nameKey!],
                          });
                        }}
                        handleRefresh={() => {
                          updateState(dataExpr!, { id: 0, name: "" });
                          updateState(dataExpr!, !refresh);
                        }}
                      />
                    </div>
                  );
                }
              )}
              {/* <Maintenance_activities_tab2_Select openedTask = {openedTask} /> */}
            </Form.Item>

            {/* JOB */}
            <Form className="w-[680px]   flex">
              <Form.Item
                style={{ width: "680px" }}
                wrapperCol={{ span: 20 }}
                labelCol={{ span: 4 }}
                label={
                  <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                    {"Odometer In/Out"}
                  </p>
                }
              >
                <div className="w-[88%] flex space-x-4">
                  <div style={{ width: "calc(36.5%)" }} className="mr-1">
                    <Input
                      value={states.odometerIn}
                      disabled={disablex}
                      className="w-full  "
                      onChange={(e) => {
                        setState("odometerIn", e.target.value);
                        setState("odometerOut", e.target.value);
                      }}
                    />
                  </div>

                  <div style={{ width: "calc(35%)" }} className="mr-1">
                    <Input
                      value={states.odometerOut}
                      disabled={disablex}
                      className="w-full "
                      onChange={(e: any) => {
                        setState("odometerOut", e.target.value);
                        setState("debit3", e.target.value - states.odometerIn);
                      }}
                    />
                  </div>

                  <div style={{ width: "calc(15%)" }} className=" flex mb-0.5 ">
                    <Input
                      value={(states.debit3 || 0) + " km"}
                      disabled={true}
                      className="w-full"
                      onChange={(e) => {
                        setState("debit3", e.target.value);
                      }}
                    />
                  </div>

                  <span
                    id="odometer"
                    className="h-full rounded w-8 ml-1 flex justify-evenly hover:cursor-pointer hover:bg-slate-50
                border-gray-300
                "
                    style={{
                      height: 22.5,
                      marginBottom: 2,
                      borderWidth: 1,
                      width: 24.9,
                    }}
                    onClick={(e: any) => {
                      setState("saveOdoModals", true);
                      e.preventDefault();
                    }}
                  >
                    <img
                      className="w-full h-full flex items-center  p-0.5 justify-center"
                      src={save}
                      alt="save"
                    />
                    <img className="" src={""} alt="" />
                    <Tooltip
                      target="#odometer"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                      hideOnOutsideClick={true}
                    >
                      <p className="">Click to update odometer readings</p>
                    </Tooltip>
                  </span>
                </div>
                {/* </div> */}
              </Form.Item>
            </Form>
            <Form.Item
              style={{ width: "550px", marginLeft: "" }}
              labelCol={{ span: 5 }}
              label={
                <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                  {"Fuel Guage In"}
                </p>
              }
            >
              <div
                style={{ borderColor: borderTheme }}
                className="w-[500px] flex justify-start items-center border-[1px] mb-0.5 rounded-md"
              >
                <Radio.Group
                  value={states.fuelIn}
                  defaultValue={states.fuelIn}
                  disabled={disablex}
                  className="flex flex-wrap justify-center w-full pl-2 "
                  onChange={(e) => {
                    setState("fuelIn", e.target.value);
                  }}
                >
                  {!fuelLevels
                    ? defaultFuelLevel?.map((fuelLevel: any, index: number) => {
                        return (
                          <Radio key={index} value={fuelLevel.value}>
                            {fuelLevel.name}
                          </Radio>
                        );
                      })
                    : fuelLevels?.map((fuelLevel: any, index: number) => {
                        return (
                          <div className="my-2">
                            <Radio
                              key={fuelLevel.flvIdpk}
                              value={fuelLevel.flvIdpk}
                            >
                              {fuelLevel.flvName}
                            </Radio>
                          </div>
                        );
                      })}
                </Radio.Group>
              </div>
            </Form.Item>
            <Form.Item
              style={{ width: "550px", marginLeft: "" }}
              labelCol={{ span: 5 }}
              label={
                <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                  {"Fuel Guage Out"}
                </p>
              }
            >
              <div
                style={{ borderColor: borderTheme }}
                className="w-[500px] flex justify-start border-[1px] mb-0.5 rounded-md"
              >
                <Radio.Group
                  value={states.fuelOut}
                  defaultValue={states.fuelOut}
                  disabled={disablex}
                  className="flex flex-wrap justify-center w-full pl-2 "
                  onChange={(e) => {
                    setState("fuelOut", e.target.value);
                  }}
                >
                  {!fuelLevels
                    ? defaultFuelLevel?.map((fuelLevel: any, index: number) => {
                        return (
                          <div className="">
                            <Radio key={index} value={fuelLevel.value}>
                              {fuelLevel.name}
                            </Radio>
                          </div>
                        );
                      })
                    : fuelLevels?.map((fuelLevel: any, index: number) => {
                        return (
                          <div className="my-2 ">
                            <Radio
                              key={fuelLevel.flvIdpk}
                              value={fuelLevel.flvIdpk}
                            >
                              {fuelLevel.flvName}
                            </Radio>
                          </div>
                        );
                      })}
                </Radio.Group>
              </div>
            </Form.Item>

            {/* <Maintenance_activities_tab2_Select openedTask = {openedTask} /> */}
            <Form.Item
              style={{ width: "550px", marginLeft: "" }}
              labelCol={{ span: 5 }}
              label={
                <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                  {"Arrival Condition"}
                </p>
              }
            >
              <div className="w-[500px] flex">
                <div style={{ width: "calc(100%)" }} className="">
                  <InputSelect_template
                    useCallFunc
                    style={{ width: "100%", marginTop: 2 }}
                    isOpenDropdown={states.isOpenDropdown2}
                    selectedValue={(value: any) => {
                      updateState("arrivalCondition", {
                        name: value.pmaArrivalCondition,
                        id: value.pmaArrivalCondition,
                      });
                      updateState("isOpenDropdown2", !states.isOpenDropdown2);
                    }}
                    handleSearch={useCallback(
                      debounce((e: any) => {
                        // updateState("arrivalCondition"!, e);

                        // dispatch(singleEndpoint2(e));
                        setSearchAC(e);

                        updateState("isOpenDropdown2", !states.isOpenDropdown2);
                      }, 500), // Adjust the debounce delay as needed
                      []
                    )}
                    handleFocus={(e: any) => {
                      updateState("isOpenDropdown2", !states.isOpenDropdown2);
                    }}
                    placeHolder={states.arrivalCondition.name}
                    options={
                      states.arrivalCondition?.length > 0
                        ? arrivalConditionData
                        : lookupData.arrivalConditions
                    }
                    disabled={disablex}
                    idexpr=""
                    dataexp="pmaArrivalCondition"
                    handleRefresh={() => {
                      updateState("arrivalCondition", "");
                    }}
                  />
                  {/* <Select
                    style={{
                      width: "100% ",
                    }}
                    onChange={(value) => {
                      setState("arrivalCondition", value);
                    }}
                    className="w-full mr-0  "
                    placeholder={""}
                    allowClear
                    value={states.arrivalCondition}
                    disabled={disablex}
                  >
                    {arrivalConditionData.map((option: any, index: number) => {
                      return (
                        <option
                          key={index}
                          value={`${option["pmaArrivalCondition"]}`}
                        >
                          {`${option["pmaArrivalCondition"]}`}
                        </option>
                      );
                    })}
                  </Select> */}
                </div>
              </div>
            </Form.Item>

            {/* Remarks */}
            <Form.Item
              style={{ width: "550px", marginLeft: "" }}
              labelCol={{ span: 5 }}
              label={
                <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                  {"Remarks"}
                </p>
              }
            >
              <div className="w-[500px] flex h-[120px] ">
                <div style={{ width: "calc(100%)" }} className="">
                  <TextArea
                    readOnly={disablex}
                    value={states.remarks}
                    style={{ resize: "none", height: "110px" }}
                    onChange={(e) => {
                      setState("remarks", e.target.value);
                    }}
                  />
                </div>
              </div>
            </Form.Item>

            <div className="w-[610px] flex  items-center justify-center pb-1 mb-1  ">
              <ul className="w-full flex items-center justify-end ">
                {!updateMode ? (
                  <div id="saveBtn">
                    <SaveButton
                      disableButton={disablex}
                      handleSave={() => {
                        saveAccessPriv();
                      }}
                    />
                    <Tooltip
                      target="#saveBtn"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                      hideOnOutsideClick={true}
                    >
                      <p className="">Click to save record</p>
                    </Tooltip>
                  </div>
                ) : (
                  <div id="updateBtn">
                    <UpdateButton handleUpdate={updateAccessPriv} />
                    <Tooltip
                      target="#updateBtn"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                      hideOnOutsideClick={true}
                    >
                      <p className="">Click to update record</p>
                    </Tooltip>
                  </div>
                )}

                {!disablex ? (
                  <Cancel />
                ) : (
                  <div>
                    <NewButton
                      useCallbackFunc={true}
                      new_button_toggler={() => {
                        newAccessPriv();
                      }}
                    />
                  </div>
                )}
              </ul>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};
