import { Form } from 'antd'
import React, { useState } from 'react'
import { formPropsConst } from '../../../../../../accessories/constants'
import { SelectsTemplate } from '../../../../../../../templates/select'
import { TextareaTemplate } from '../../../../../../../templates/textarea'
import { BottomBtns } from '../../../../../../../templates/butttons'
import { useDispatch, useSelector } from 'react-redux'
import { useAccountInfo } from '../../../../../../../hooks/useAccountInfo'
import { useFormPriviledge } from '../../../../../../../hooks/useFormPriviledge'
import { saveModalData, updateModalData } from '../../../../../../accessories/component_infos'
import { setGeneralValue } from '../../../../../../../features/generalSlice'
import { ModalTemplate } from '../../../../../../../templates/modal'
import { RootState } from '../../../../../../../app/store'

interface stateTypes {
    fuelTrans: {id : number, name: string};
    resolutionComment: string;
    resolved: boolean;
    active: boolean;
}

interface props{
    refreshGrid:()=>void
    clearSelected:()=>void
    selectedData:any
}

export const Resolution_details = ({refreshGrid, clearSelected, selectedData}:props) => {

    const [states, setStates] = useState<stateTypes>({
        fuelTrans: {id: 0, name: ""},
        resolutionComment: "",
        resolved: true,
        active: true
    })
    const setState = (key: string, value: any) => {
        setStates((prev) => ({ ...prev, [key]: value }));
      };

    const form_disable = useSelector(
        (state: RootState) => state.general.formDisabled
      );

    const [employeeId, userId, userInfo] = useAccountInfo();
    const [privType, setPrivType] = useState<string>("save");
    const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);

    const [openModal, setOpenModal] = useState(false);
    const [modalData, setModalData] = useState<any>(saveModalData);
    const [icon, setIcon] = useState<"warning" | "success" | "question">();
    const [refresh, setRefresh] = useState<boolean>(false);

    const dispatch = useDispatch();

    //wrong gridState
    const populateFields = (clear?: boolean) => {
        const gridState = [
            { key: "fuelTrans", value: clear ? {id: 0 , name:""} : {id :selectedData.fuelTrans.id , name : selectedData.fuelTrans.name} },
            { key: "resolutionComment", value: clear ? "" : selectedData.resolutionComment },
            { key: "resolved", value: clear ? "" : selectedData.resolved },
            { key: "active", value: clear ? "" : selectedData.active },
        ]

        for (let instance of gridState) setState(instance.key, instance.value);
    }
    const validationForms = async () => {
        setIcon("warning");
        if (states.fuelTrans.id === 0) {
          setModalData({ title: "Validation Error!", message: "Fuel Transaction is required" });
          setOpenModal(true);
          return;
        }else{
            setIcon("question");
            setOpenModal(true);
        }
    }

    const newAccessPriv = ()=>{
        setPrivType("save");
        if (savePriv === true) {
          dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
          clearSelected();
          populateFields(true);
        } else {
          dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
          setIcon("warning");
          setOpenModal(true);
          setModalData({ title: "Access Denied!", message: "No Access Privilege" });
        }
    }

    const saveAccessPriv = () => {
        setPrivType("save");
        if (savePriv === true) {
          dispatch(setGeneralValue({ expr: "updateMode", value: false }));
          setIcon("question");
          setModalData(saveModalData);
          setOpenModal(true);
          validationForms();
        } else {
          setIcon("warning");
          setOpenModal(true);
          setModalData({ title: "Access Denied!", message: "No Access Privilege" });
        }
      };

      const updateAcccessPriv = () => {
        setPrivType("update");
        if (updatePriv === true) {
          dispatch(setGeneralValue({ expr: "updateMode", value: true }));
          setIcon("question");
          setModalData(updateModalData);
          setOpenModal(true);
          validationForms();
        } else {
          setIcon("warning");
          setOpenModal(true);
          setModalData({ title: "Access Denied!", message: "No Access Privilege" });
        }
      };

    return (
        <>
            <div className='w-full '>
            <p className='w-full pl-4 py-1 font-medium bg-slate-50 border-[1px] '>{"Resolution Details"}</p>
            <ModalTemplate 
                icon={icon}
                open={openModal}
                disableCancel={icon === "warning" || icon === "success" ? true : false}
                cancelText={modalData.cancelText}
                cancelHandler={() => {
                    setOpenModal(false);
                }}
                okHandler={() => setOpenModal(false)}
                message={modalData.message}
                title={modalData.title}
                okText={modalData.okText}
            />
            <Form
                className='w-full py-1'
                layout='horizontal'
                size="small"
                labelCol={{ span: 4 }}
            >
                <section className='w-full px-1'>
                    <SelectsTemplate
                        label={"Fuel Transaction"}
                        useCallFunc
                        selectStyle={{width: "60%"}}
                        placeHolder={states.fuelTrans.name}
                        // options={}
                        // idexpr={}
                        // dataexp={}
                        // selectedValue={}
                        // handleClear={()=>{}}
                        // handleRefresh={}
                    />

                    <TextareaTemplate
                        label={"Resolution Comment"}
                        labelCol={4}
                        height={100} 
                        useCallbackFunc
                        defaultValue={states.resolutionComment}
                        setCallbackValue={(e)=>{setState("resolutionComment", e)}}
                    />

                    <BottomBtns
                        useCallbackFunc
                        span
                        additionalBottomChckbox={"Resolved?"}
                        additionalCheckboxChange={()=>{}}
                        checkboxChange={()=>{}}
                        addActiveBorders={true}
                        label="Active?"
                        onCancelClick={()=>{}}
                        onNewClick={()=>{newAccessPriv()}}
                        saveHandler={()=>{saveAccessPriv()}}
                        updateHandler={()=>{updateAcccessPriv()}} 
                        outerDisabled={form_disable}

                    />
                </section>
            </Form>



        </div>
        </>
    )
}
