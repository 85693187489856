import { Checkbox, DatePicker, Form, Input, Select } from "antd";
import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import { TextArea } from "devextreme-react";
import { forwardRef, useState, useImperativeHandle } from "react";
import useFetch from "../../../../../../hooks/useFetch";
import { BottomBtns } from "../../../../../../templates/butttons";
import { DateTemplate } from "../../../../../../templates/date";
import { InputsTemplate } from "../../../../../../templates/input";
import { ModalTemplate } from "../../../../../../templates/modal";
import { SelectsTemplate } from "../../../../../../templates/select";
import { TextareaTemplate } from "../../../../../../templates/textarea";
import { api_url, saveModalData } from "../../../../../accessories/component_infos";
import email from '../../../../../../assets/email.png'
import save from '../../../../../../assets/save-small.png'
import { selectsTypes } from "../../../../../human-resource/setups/data/types/selectsTypes";
import { Cancel, NewButton, SaveButton, Schedule_Alert_Button, UpdateButton } from "../../../../../accessories/buttons";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";
import { useFormPriviledge } from "../../../../../../hooks/useFormPriviledge";
import { useAccountInfo } from "../../../../../../hooks/useAccountInfo";
import { UpdateDataFunc } from "../../../../../../functions/update";
import { PostDataFunc  } from "../../../../../../functions/post";

interface stateTypes {
  refresh?: any;

}

const AuthorizeDtailsForm = forwardRef(({ refresh }: stateTypes, ref) => {
const borderTheme=useSelector((state:RootState)=>state.general.cssBorderClass)

  // Current logged in user
  const [empID, setEmpID] = useState<number>(1);

  const[employeeId,userId, userInfo]=useAccountInfo();
  const [privType, setPrivType] = useState<string>("save")
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType,)
  const [selectedData, setSelectedData] = useState<any>({})
  console.log('world',selectedData)

  const [modalInfo,setModalInfo ] = useState<any>({
    icon:'question',
    data: {
      message: "Are you sure you want to authorise this request?",
      title: "Authorise request?",
      okText: "Authorise",
      cancelText: "No, Cancel",
    },
    disableCancel: false,
    openModal : false,
 
 })

 const formCode = useSelector(
  (state: RootState) => state.general.formCode
);

  const [unitsData, unitsErr, unitsLoading] = useFetch("AsmTmsEntAuthorizeFuelRequest/GetLueFuelUnits");

    const [formData, setFormData] = useState({
      selectedId: '',
      dateAuthorised: '',
      quantityRequested: '', 
      quantityApproved: '', 
      fuelUnitSelected: '', 
      reqRmks: '',
      authRmks: '',
      disableForm: true,
      
    })



  useImperativeHandle(ref, () => ({
    singleClick: (e: any) => {
      console.log(e)
      setFormData({
        ...formData,
        selectedId: e?.fmgIDpk,
        dateAuthorised: e?.fmgDateAuthorised,
        quantityRequested: e?.fmgQuantityRequested,
        quantityApproved: e?.fmgQuantityAuthorised,
        reqRmks: e?.fmgRmks,
        authRmks: e?.fmgAuthoriserRmks,
        disableForm: true,
      }) 
      setSelectedData(e);
    },
    doubleClick: () => {
      setFormData({
        ...formData,
       
        disableForm: false,
      })
    },
  }));

  const validate = (method: string) => {
    setModalInfo({...modalInfo, icon: 'question'})
    if(formData.dateAuthorised === ''){
      setModalInfo({...modalInfo, icon: 'warning',  data: {
        message: "Please select a valid authorised date",
        title: "Invalid date",
        okText: "Ok",
        cancelText: "No, Cancel",
      },
      disableCancel: true,
      openModal : true,})
    }else  if(formData.quantityRequested === ''){
      setModalInfo({...modalInfo, icon: 'warning',  data: {
        message: "Please select a valid quantity requested",
        title: "Invalid quantity",
        okText: "Ok",
        cancelText: "No, Cancel",
      },
      disableCancel: true,
      openModal : true,})
    }else  if(formData.quantityRequested === ''){
      setModalInfo({...modalInfo, icon: 'warning',  data: {
        message: "Please input an authorised quantity",
        title: "Invalid quantity",
        okText: "Ok",
        cancelText: "No, Cancel",
      },
      disableCancel: true,
      openModal : true,})
    }else  if(formData.fuelUnitSelected === ''){
      setModalInfo({...modalInfo, icon: 'warning',  data: {
        message: "Please select a valid fuel unit",
        title: "Invalid fuel unit",
        okText: "Ok",
        cancelText: "No, Cancel",
      },
      disableCancel: true,
      openModal : true,})
    }else  if(method === 'authorise'){
      setModalInfo({...modalInfo, icon: 'question',  data: {
        message: "Are you sure you want to authorise this request?",
        title: "Authorise request?",
        okText: "Authorise",
        cancelText: "No, Cancel",
      },
      disableCancel: false,
      openModal : true,})
    }
  }

  

  const postData = async () => {
    try {
      console.log({
        "fmgIdpk": formData.selectedId,
        "fmgQuantityAuthorised": formData.quantityApproved,
        "fmgAuthoriserRmks": formData.authRmks,
        "fmgAuthorised": true,
        "fmgDateAuthorised": `${dayjs(formData.dateAuthorised).format('YYYY-MM-DDTHH:mm:ss[Z]')}`,
        "fmgEditedBy": 1,
        "fmgEditedDate": `${dayjs().format('YYYY-MM-DDTHH:mm:ss[Z]')}`
      })
      const res = await UpdateDataFunc(
        `AsmTmsEntAuthorizeFuelRequest/AuthorizeEntFuelRequest`,
        {
          "fmgIdpk": formData.selectedId,
          "fmgQuantityAuthorised": Number(formData.quantityApproved).toFixed(2),
          "fmgAuthoriserRmks": formData.authRmks,
          "fmgAuthorised": true,
          "fmgDateAuthorised": `${dayjs(formData.dateAuthorised).format('YYYY-MM-DDTHH:mm:ss[Z]')}`,
          "fmgEditedBy": empID,
          "fmgEditedDate": `${dayjs().format('YYYY-MM-DDTHH:mm:ss[Z]')}`
        }
      );
      refresh();
      setModalInfo({
        icon:'success',
        data: {
          message: "Request successfully authorised",
          title: "Success",
          okText: "Ok",
          cancelText: "No, Cancel",
        },
        disableCancel: true,
        openModal : true,
      })
     
    } catch (error: any) {
      console.log(error)
      setModalInfo({
        icon:'warning',
        data: {
          message: error,
          title: "Error",
          okText: "Ok",
          cancelText: "No, Cancel",
        },
        disableCancel: true,
        openModal : true,
      })
    
    }
  }

  const cancelData = async () => {
    // alert(formData.selectedId)
    try {
      const res = await UpdateDataFunc(
        
        `AsmTmsEntAuthorizeFuelRequest/CancelEntFuelRequest`,
        {
          "fmgIdpk": formData?.selectedId,
          // "fmgQuantityAuthorised": formData?.quantityApproved,
          //  "fmgCancelled": true,
          // "fmgEditedDate": `${dayjs().format('YYYY-MM-DDTHH:mm:ss[Z]')}`
        }
      );
      refresh();

      setModalInfo({
        icon:'success',
        data: {
          message: "Request successfully cancelled",
          title: "Success",
          okText: "Ok",
          cancelText: "No, Cancel",
        },
        disableCancel: true,
        openModal : true,
      })
     
    } catch (error: any) {
      setModalInfo({
        icon:'warning',
        data: {
          message: error.message ?? "Error cancelling request",
          title: "Error",
          okText: "Ok",
          cancelText: "No, Cancel",
        },
        disableCancel: true,
        openModal : true,
      })
    
    }
  }
  
  const sendSms = {
    icon: 'question',
    title: 'Send Message?',
    message: 'Are you sure you want to send an alert to the requester?',
    okText: 'Send',
    cancelText: 'No, Cancel',
  }

  const checkConnection = () => {
    if (!navigator.onLine) {
      setModalInfo({
        icon: "warning",
        data: {
          message:
            "Your computer is not connected to the Internet. Please check your Internet connection and try again",
          title: "Connectivity Error!",
          okText: "Ok",
          cancelText: "",
        },
        disableCancel: true,
        openModal: true,
      });
    }
  };

  const message = selectedData?.fmgQuantityAuthorised +  " Litres of fuel authorize with request ID " + selectedData?.fmgIDpk + " has been authorized for " + selectedData?.dispenserEmpName

  let mobNox = ""; // Declare the variable 'mobNox' outside of the if statement

  const smsAlert = async () => {
    try {
      checkConnection();
      if (selectedData.driverEmpID) {
        mobNox = selectedData.dispenserMobNox.trim().replace("", "");
      }
      if (mobNox.trim.length >= 10) {
        mobNox = mobNox.substring(0, 10);
      }
      // setBusyLoader("Sending SMS....");
      await PostDataFunc (`SMSAlert/SaveToLogs`, {
        slgMessage:  message,
        slgMobNo: selectedData?.dispenserMobNox,
        slgSender: userInfo.empName,
        slgSent: true,
        slgRecordIDfk: selectedData.vdtIDpk,
        slgFormCode: formCode,
        slgType: "string",
        slgClass: "string",
        slgSmsServiceProvider: "Hubtel",
        slgEmpIDfk: selectedData.driverEmpID,
        slgInitiatorEmpIDfk: userId,
        slgCreatedBy: userId,
      });

      await PostDataFunc (
        `AsmTsmEntMaintenanceSchedule/SaveSmsAlert`,
        {
          altFormCode: "asmTransMaintenanceSchedules",
          altMessage: message,
          altTakeAction: true,
        }
      );
      
      setModalInfo({
        icon: "success",
        data: {
          message: "SMS Sent Successfully",
          title: "Success",
          okText: "Ok",
          cancelText: "",
        },
        disableCancel: true,
        openModal: true,
      });
      // setBusyLoader("");
    } catch (error: any) {
      console.log(error);
      setModalInfo({
        icon: "warning",
        data: {
          message: "Failed to send Alert!.. Please contact your administrator",
          title: "Unexpected Error!",
          okText: "Ok",
          cancelText: "",
        },
        disableCancel: true,
        openModal: true,
      });
      // setBusyLoader("");
    }
  };
  const smsAlertAuth = async () => {
    try {
      checkConnection();
      if (selectedData.driverEmpID) {
        mobNox = selectedData.dispenserMobNox.trim().replace("", "");
      }
      if (mobNox.trim.length >= 10) {
        mobNox = mobNox.substring(0, 10);
      }
      // setBusyLoader("Sending SMS....");
      await PostDataFunc (`SMSAlert/SaveToLogs`, {
        slgMessage:  message,
        slgMobNo: selectedData?.dispenserMobNox,
        slgSender: userInfo.empName,
        slgSent: true,
        slgRecordIDfk: selectedData.vdtIDpk,
        slgFormCode: formCode,
        slgType: "string",
        slgClass: "string",
        slgSmsServiceProvider: "Hubtel",
        slgEmpIDfk: selectedData.driverEmpID,
        slgInitiatorEmpIDfk: userId,
        slgCreatedBy: userId,
      });

      await PostDataFunc (
        `AsmTsmEntMaintenanceSchedule/SaveSmsAlert`,
        {
          altFormCode: "asmTransMaintenanceSchedules",
          altMessage: message,
          altTakeAction: true,
        }
      );
      
      setModalInfo({
        icon: "success",
        data: {
          message: "SMS Sent Successfully",
          title: "Success",
          okText: "Ok",
          cancelText: "",
        },
        disableCancel: true,
        openModal: true,
      });
      // setBusyLoader("");
    } catch (error: any) {
      console.log(error);
      setModalInfo({
        icon: "warning",
        data: {
          message: "Failed to send Alert!.. Please contact your administrator",
          title: "Unexpected Error!",
          okText: "Ok",
          cancelText: "",
        },
        disableCancel: true,
        openModal: true,
      });
      // setBusyLoader("");
    }
  };
  const smsAlertCan = async () => {
    try {
      checkConnection();
      if (selectedData.driverEmpID) {
        mobNox = selectedData.dispenserMobNox.trim().replace("", "");
      }
      if (mobNox.trim.length >= 10) {
        mobNox = mobNox.substring(0, 10);
      }
      // setBusyLoader("Sending SMS....");
      await PostDataFunc (`SMSAlert/SaveToLogs`, {
        slgMessage:  message,
        slgMobNo: selectedData?.dispenserMobNox,
        slgSender: userInfo.empName,
        slgSent: true,
        slgRecordIDfk: selectedData.vdtIDpk,
        slgFormCode: formCode,
        slgType: "string",
        slgClass: "string",
        slgSmsServiceProvider: "Hubtel",
        slgEmpIDfk: selectedData.driverEmpID,
        slgInitiatorEmpIDfk: userId,
        slgCreatedBy: userId,
      });

      await PostDataFunc (
        `AsmTsmEntMaintenanceSchedule/SaveSmsAlert`,
        {
          altFormCode: "asmTransMaintenanceSchedules",
          altMessage: message,
          altTakeAction: true,
        }
      );
      
      setModalInfo({
        icon: "success",
        data: {
          message: "SMS Sent Successfully",
          title: "Success",
          okText: "Ok",
          cancelText: "",
        },
        disableCancel: true,
        openModal: true,
      });
      // setBusyLoader("");
    } catch (error: any) {
      console.log(error);
      setModalInfo({
        icon: "warning",
        data: {
          message: "Failed to send Alert!.. Please contact your administrator",
          title: "Unexpected Error!",
          okText: "Ok",
          cancelText: "",
        },
        disableCancel: true,
        openModal: true,
      });
      // setBusyLoader("");
    }
  };
  

  const authoriseAccessPriv = () => {
    if (savePriv === true) {
      validate('authorise')
    } else {
      setModalInfo({
        ...modalInfo,
        icon: 'warning',
        data: {
          message: "No Access Privilege",
          title: "Access denied",
          okText: "Ok",
         cancelText: "No, Cancel",
        },
        disableCancel: true,
        openModal: true,
      })
    }
  }

  const cancelAccessPriv = () => {
    setPrivType('save')
    if (savePriv === true) {
      setModalInfo({...modalInfo, icon: 'question',  data: {
        message: "Are you sure you want to cancel this request?",
        title: "Cancel request?",
        okText: "Cancel",
        cancelText: "No, Close",
      },
      disableCancel: false,
      openModal : true,})
    } else {
      setModalInfo({
        ...modalInfo,
        icon: 'warning',
        data: {
          message: "No Access Privilege",
          title: "Access denied",
          okText: "Ok",
          cancelText: "No, Cancel",
        },
        disableCancel: true,
        openModal: true,
      })
    }
  }

  const sendSmsAccessPriv = () => {
    setPrivType('read')
    if (readPriv === true) {
      setModalInfo({
        ...modalInfo,
        icon: sendSms.icon,
        data: {
          ...sendSms,
        },
        disableCancel: false,
        openModal: true,
      })
    } else {
      setModalInfo({
        ...modalInfo,
        icon: 'warning',
        data: {
          message: "No Access Privilege",
          title: "Access denied",
          okText: "Ok",
          cancelText: "No, Cancel",
        },
        disableCancel: true,
        openModal: true,
      })
    }
  }


  return (
    <>
      <ModalTemplate
        disableCancel={modalInfo.disableCancel}
        icon={modalInfo.icon}
        title={modalInfo.data.title}
        okText={modalInfo.data.okText}
        message={modalInfo.data.message}
        cancelText={modalInfo.data.cancelText}
        open={modalInfo.openModal}
        okHandler={() => {
        modalInfo.data.title === 'Authorise request?'?  postData(): modalInfo.data.title === 'Cancel request?'? cancelData(): modalInfo.data.title === sendSms.title? smsAlert() : setModalInfo({...modalInfo, openModal: false})
        }}
        cancelHandler={() => {
          setModalInfo({...modalInfo, openModal: false})
        }}
      />
      
      <div className="px-2">
        {/* FORM HEADER */}
        <p id={"form_header"} style={{borderColor:borderTheme, width:'4000px'}} className='pt-1 px-2 w-full border-[1px] font-medium bg-slate-100'>{'Authorise Fuel Requests'}</p>
        <Form
          style={{borderColor:borderTheme}}
          className='w-full border-[1px] border-r-0 border-slate-100 border-t-0 rounded flex justify-between py-1 pl-2 '
          name="basic"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 19 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          size='small'
        >
          <section style={{ width: '550px' }} className="w-full " >
            {/* date and time */}
            <div className=" mb-0.5">
              <DateTemplate disabled={formData.disableForm}  label="Date Authorised" span={false}
              changeDate={(e:any)=>{setFormData({...formData, dateAuthorised: e})}}
              selectedDate={formData.dateAuthorised === ''? null:dayjs(formData.dateAuthorised)} />
            </div>
            {/* disabledDate={(current:Dayjs)=>{return current && current > dayjs().endOf('day');}} */}

            <Form.Item
              className='mb-0 mt-0.5 '
              label={<p className='dark:bg-fill dark:text-darkModeSkin-base text-xs'>{'Quantity (R/A)'}</p>}
              rules={[{ required: false, }]}

            >

              <span className="flex flex-row items-center">
                <InputsTemplate orderOnchange={(e)=>{setFormData({...formData, quantityRequested: `${e}`})}}   defaultValue={Number(formData.quantityRequested).toFixed(2)} useCallbackFunc={true} disabledStatus={true}  />
                <InputsTemplate orderOnchange={(e)=>{setFormData({...formData, quantityApproved: `${e}`})}} defaultValue={Number(formData.quantityApproved).toFixed(2)} useCallbackFunc={true} disabledStatus={formData.disableForm} />
                <SelectsTemplate selectedValue={(e)=>{setFormData(prev => ({ ...prev, fuelUnitSelected: e['funShtName']}))}} placeHolder={formData.fuelUnitSelected} dataexp="funShtName" idexpr="funIDpk" disabled={formData.disableForm} useCallFunc={true} options={unitsData === undefined? []: unitsData} selectStyle={{ width: '100px' }} />
              </span>

            </Form.Item>


            <TextareaTemplate
            readonly={true}
              label="Requester's Remarks"
              useCallbackFunc={true}
              labelCol={6}
              height={100}
              defaultValue={formData.reqRmks}
              setCallbackValue={(e) => {
               setFormData({...formData, reqRmks: e})
              }
              }
            />
            <div className="w-full flex justify-between">

              <button
                style={{borderColor:borderTheme,
                  marginLeft: '137px'
                }}
                onClick={()=>{ sendSmsAccessPriv()}}

                className=" hover:cursor-pointer dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  mr-1 px-2 py-1 border-2 border-slate-200 rounded hover:border-blue-300"
              >
                <img className="pr-1" src={email} />
                Send Alerts
              </button>
              <div className="flex">
                <button
                  style={{borderColor:borderTheme}}
                  onClick={()=>{authoriseAccessPriv()}}
                  className="hover:cursor-pointer dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  mr-1 px-2 py-1 border-2 border-slate-200 rounded hover:border-blue-300"
                >
                  <img className="pr-1" src={save} />
                  Authorise
                </button>
                <Cancel useCallbackFunc={true} cancel_button_toggler={()=>{cancelAccessPriv()}} />
              </div>

            </div>

          </section>

          {/* remarks */}
          <section className=" pl-4" style={{ width: 'calc(100% - 550px)' }}>
            <TextareaTemplate
            readonly={formData.disableForm}
              label="Authoriser's Remarks"
              useCallbackFunc={true}
              height={200}
              defaultValue={formData.authRmks}
              setCallbackValue={(e) => {
                setFormData({...formData, authRmks: e})
              }
              }
            />
          </section>
        </Form>
      </div>
    </>
  );
})

export default AuthorizeDtailsForm;