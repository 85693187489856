import { datagridColumnTypes } from "../../../setups/data/types/selectsTypes";

export const staff_datagrid_allcolumns: datagridColumnTypes[] = [
  {
    id: 0,
    caption: "ID",
    dataField: "empIdpk",
    dataType: "number",
    width: 85,
    fixed: true,
    visible: false,
  },
  {
    id: 2,
    caption: "STAFF No",
    dataField: "empStaffNo",
    dataType: "string",
    width: 85,
    fixed: true,
    visible: true,
  },
  {
    id: 4,
    caption: "STAFF NAME",
    dataField: "empName",
    dataType: "string",
    width: 200,
    fixed: true,
    visible: true,
  },
  {
    id: 6,
    caption: "DEPT",
    dataField: "dptName",
    dataType: "string",
    width: 120,
    fixed: true,
    visible: true,
  },
  {
    id: 8,
    caption: "SECTION",
    dataField: "sxnName",
    dataType: "string",
    width: 120,
    fixed: true,
    visible: true,
  },
  {
    id: 10,
    caption: "UNIT",
    dataField: "untName",
    dataType: "string",
    width: 120,
    fixed: true,
    visible: true,
  },
  {
    id: 12,
    caption: "JOB TITLE",
    dataField: "jbtName",
    dataType: "string",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 14,
    caption: "STAFF?",
    dataField: "empIsStaff",
    dataType: "boolean",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 16,
    caption: "ACTIVE?",
    dataField: "empActive",
    dataType: "boolean",
    width: 75,
    fixed: false,
    visible: true,
  },
  {
    id: 18,
    caption: "LOCATION",
    dataField: "locName",
    dataType: "string",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 20,
    caption: "AREA",
    dataField: "areName",
    dataType: "string",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 22,
    caption: "DoB",
    dataField: "empDoB",
    dataType: "datetime",
    width: 140,
    fixed: false,
    visible: true,
  },
  {
    id: 24,
    caption: "AGE",
    dataField: "age",
    dataType: "",
    width: 75,
    fixed: false,
    visible: true,
  },
  {
    id: 26,
    caption: "BIRTHDAY",
    dataField: "birthDay",
    dataType: "string",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 28,
    caption: "HIRE DATE",
    dataField: "empHireDate",
    dataType: "datetime",
    width: 140,
    fixed: false,
    visible: true,
  },
  {
    id: 30,
    caption: "RETIRE DATE",
    dataField: "retireDate",
    dataType: "datetime",
    width: 140,
    fixed: false,
    visible: true,
  },
  {
    id: 32,
    caption: "TENURE(YRS)",
    dataField: "serviceLenght",
    dataType: "",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 34,
    caption: "TENURE LEFT",
    dataField: "tenureLeft",
    dataType: "",
    width: 110,
    fixed: false,
    visible: true,
  },
  {
    id: 36,
    caption: "AGE PROFILE",
    dataField: "apfName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 38,
    caption: "AP ST. AGE",
    dataField: "apfStartAge",
    dataType: "",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 40,
    caption: "AP END AGE",
    dataField: "apfEndAge",
    dataType: "",
    width: 100,
    fixed: false,
    visible: true,
  },
  // {id: 9,caption : "PROMO DATE",dataField : 'empLastPromoDate', dataType : "datetime",width : 140 , fixed : false,visible : true,},
  {
    id: 42,
    caption: "GRADE",
    dataField: "grdName",
    dataType: "string",
    width: 80,
    fixed: false,
    visible: true,
  },
  {
    id: 44,
    caption: "STEP",
    dataField: "stpName",
    dataType: "string",
    width: 70,
    fixed: false,
    visible: true,
  },
  {
    id: 46,
    caption: "SUP STF No.",
    dataField: "empSupStaffNo",
    dataType: "string",
    width: 95,
    fixed: false,
    visible: true,
  },
  {
    id: 48,
    caption: "SUPERVISOR",
    dataField: "empSupName",
    dataType: "string",
    width: 150,
    fixed: false,
    visible: true,
  },
  {
    id: 50,
    caption: "UNIT HEAD",
    dataField: "",
    dataType: "string",
    width: 150,
    fixed: false,
    visible: true,
  },
  {
    id: 52,
    caption: "MANAGER",
    dataField: "empMgrName",
    dataType: "string",
    width: 150,
    fixed: false,
    visible: true,
  },
  {
    id: 54,
    caption: "DIRECTOR",
    dataField: "empDirName",
    dataType: "string",
    width: 150,
    fixed: false,
    visible: true,
  },
  {
    id: 56,
    caption: "OFFICE LOCATION",
    dataField: "",
    dataType: "string",
    width: 95,
    fixed: false,
    visible: true,
  },
  {
    id: 58,
    caption: "ROOM No",
    dataField: "romName",
    dataType: "string",
    width: 87,
    fixed: false,
    visible: true,
  },
  {
    id: 70,
    caption: "COST",
    dataField: "",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 72,
    caption: "COST CENTER C",
    dataField: "cctName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 74,
    caption: "SOC. SEC No",
    dataField: "empSocSecNo",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 76,
    caption: "NATIONAL ID",
    dataField: "empNationalID",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 78,
    caption: "ASSIGN. STATUS",
    dataField: "astName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 80,
    caption: "APPRAISAL GROUP",
    dataField: "apgName",
    dataType: "string",
    width: 110,
    fixed: false,
    visible: true,
  },
  {
    id: 82,
    caption: "ASSIGNMENT CATEGORY",
    dataField: "ascName",
    dataType: "string",
    width: 150,
    fixed: false,
    visible: true,
  },
  {
    id: 84,
    caption: "JOB",
    dataField: "jobName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 86,
    caption: "JOB LEVEL",
    dataField: "jlvName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 88,
    caption: "MARITAL STATUS",
    dataField: "mtsName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 90,
    caption: "PERSON TYPE",
    dataField: "pstName",
    dataType: "string",
    width: 150,
    fixed: false,
    visible: true,
  },
  {
    id: 92,
    caption: "EMPLOYEE GROUP",
    dataField: "egpName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 94,
    caption: "GENDER",
    dataField: "gndName",
    dataType: "string",
    width: 78,
    fixed: false,
    visible: true,
  },
  {
    id: 96,
    caption: "PROMO DATE",
    dataField: "empLastPromoDate",
    dataType: "date",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 98,
    caption: "ADDRESS",
    dataField: "empAddress",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 100,
    caption: "INTERCOM",
    dataField: "empIntercom",
    dataType: "string",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 102,
    caption: "MOBILE No",
    dataField: "empMobNo",
    dataType: "number",
    width: 96,
    fixed: false,
    visible: true,
  },
  {
    id: 104,
    caption: "TELEPHONE No",
    dataField: "empTelNo",
    dataType: "number",
    width: 120,
    fixed: false,
    visible: true,
  },
  {
    id: 106,
    caption: "OFFICIAL EMAIL",
    dataField: "empOfficialEmail",
    dataType: "string",
    width: 150,
    fixed: false,
    visible: true,
  },
  {
    id: 108,
    caption: "PERSONAL EMAIL",
    dataField: "empPersonalEmail",
    dataType: "string",
    width: 150,
    fixed: false,
    visible: true,
  },
  {
    id: 110,
    caption: "NATIONALITY",
    dataField: "ctyNationality",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 112,
    caption: "HOME REGION",
    dataField: "rgnName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 114,
    caption: "HOME DISTRICT",
    dataField: "dstName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 116,
    caption: "HOME TOWN",
    dataField: "twnName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 118,
    caption: "LINKEDIN",
    dataField: "empLinkedIn",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 120,
    caption: "SKYPE",
    dataField: "empSkype",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 122,
    caption: "TWITTER",
    dataField: "empTwitterHanlde",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 124,
    caption: "FACEBOOK",
    dataField: "empFacebookAccount",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 126,
    caption: "SUP?",
    dataField: "empIsSupervisor",
    dataType: "boolean",
    width: 62,
    fixed: false,
    visible: true,
  },
  {
    id: 128,
    caption: "MGR?",
    dataField: "empIsManager",
    dataType: "boolean",
    width: 62,
    fixed: false,
    visible: true,
  },
  {
    id: 130,
    caption: "DIR?",
    dataField: "empIsDirector",
    dataType: "boolean",
    width: 62,
    fixed: false,
    visible: true,
  },
  {
    id: 132,
    caption: "SNR?",
    dataField: "empIsSeniorStaff",
    dataType: "boolean",
    width: 62,
    fixed: false,
    visible: true,
  },
  {
    id: 134,
    caption: "JNR?",
    dataField: "empIsJuniorStaff",
    dataType: "boolean",
    width: 62,
    fixed: false,
    visible: true,
  },
  {
    id: 136,
    caption: "CEO?",
    dataField: "empIsCeo",
    dataType: "boolean",
    width: 62,
    fixed: false,
    visible: true,
  },
  {
    id: 138,
    caption: "MGMT?",
    dataField: "empIsManagementStaff",
    dataType: "boolean",
    width: 74,
    fixed: false,
    visible: true,
  },
  {
    id: 140,
    caption: "DEPT ADMIN?",
    dataField: "empIsDeptAdmin",
    dataType: "boolean",
    width: 110,
    fixed: false,
    visible: true,
  },
  {
    id: 142,
    caption: "DEPT SEC?",
    dataField: "",
    dataType: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 144,
    caption: "SEC'N ADMIN?",
    dataField: "empIsSectionAdmin",
    dataType: "boolean",
    width: 110,
    fixed: false,
    visible: true,
  },
  {
    id: 146,
    caption: "SEC'N SEC?",
    dataField: "",
    dataType: "boolean",
    width: 95,
    fixed: false,
    visible: true,
  },
  {
    id: 148,
    caption: "BOARD MEMB?",
    dataField: "empIsBoardMember",
    dataType: "boolean",
    width: 120,
    fixed: false,
    visible: true,
  },
  {
    id: 150,
    caption: "CORE STAFF?",
    dataField: "empIsCoreStaff",
    dataType: "boolean",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 152,
    caption: "TECH STAFF?",
    dataField: "empIsTechnicalStaff",
    dataType: "boolean",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 154,
    caption: "CASUAL?",
    dataField: "empIsCasualStaff",
    dataType: "boolean",
    width: 85,
    fixed: false,
    visible: true,
  },
  {
    id: 156,
    caption: "NSP?",
    dataField: "empIsServicePerson",
    dataType: "boolean",
    width: 60,
    fixed: false,
    visible: true,
  },
  {
    id: 158,
    caption: "VOLUNTARY?",
    dataField: "empIsVoluntaryPerson",
    dataType: "boolean",
    width: 110,
    fixed: false,
    visible: true,
  },
  {
    id: 160,
    caption: "REMARKS",
    dataField: "empRmks",
    dataType: "string",
    width: 4000,
    fixed: false,
    visible: true,
  },
];

export const cost_center_colums: datagridColumnTypes[] = [
  {
    id: 0,
    caption: "ID",
    dataField: "cctIdpk",
    dataType: "number",
    fixed: false,
    visible: true,
  },
  {
    id: 2,
    caption: "COST CENTER",
    dataField: "cctName",
    dataType: "string",
    width: 200,
    fixed: false,
    visible: true,
  },
  {
    id: 3,
    caption: "CODE",
    dataField: "cctCode",
    dataType: "string",
    fixed: false,
    visible: true,
  },
];

export const form_supervisor_columns: datagridColumnTypes[] = [
  {
    id: 0,
    caption: "ID",
    dataField: "empIdpk",
    dataType: "number",
    fixed: false,
    visible: true,
  },
  {
    id: 2,
    caption: "Employee Name",
    dataField: "empName",
    dataType: "string",
    width: 200,
    fixed: false,
    visible: true,
  },
  {
    id: 3,
    caption: "Staff No",
    dataField: "empStaffNo",
    dataType: "string",
    fixed: false,
    visible: true,
  },
  {
    id: 4,
    caption: "Dept. Name",
    dataField: "dptShtName",
    dataType: "string",
    fixed: false,
    visible: true,
  },
  {
    id: 5,
    caption: "Job Title",
    dataField: "jbtShtName",
    dataType: "string",
    fixed: false,
    visible: true,
  },
  {
    id: 6,
    caption: "Loc. Name",
    dataField: "locShtName",
    dataType: "string",
    fixed: false,
    visible: true,
  },
  {
    id: 7,
    caption: "Sec. Name",
    dataField: "sxnShtName",
    dataType: "string",
    fixed: false,
    visible: true,
  },
];

export const staffDetailsCriteria = [
  { id: 0, value: "Name" },
  { id: 1, value: "Staff No" },
  { id: 2, value: "Title" },
  { id: 3, value: "Dept" },
  { id: 4, value: "Section" },
  { id: 5, value: "Unit" },
  { id: 6, value: "Job Title" },
  { id: 7, value: "Grade" },
  { id: 8, value: "Step" },
  { id: 9, value: "Grade & Step" },
  { id: 10, value: "Area" },
  { id: 11, value: "Location" },
  { id: 12, value: "Gender" },
  { id: 13, value: "Appraisal Group" },
  { id: 14, value: "INTERCOM" },
  { id: 15, value: "Tel No" },
  { id: 16, value: "Mob No" },
  { id: 17, value: "e-mail Official" },
  { id: 18, value: "e-mail Personal" },
  { id: 19, value: "DoB" },
  { id: 20, value: "Hire Date" },
  { id: 21, value: "Promo Date" },
  { id: 22, value: "Age" },
  { id: 23, value: "Birthdays" },
];
