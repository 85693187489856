import React, { memo } from 'react';
import Chart from "react-apexcharts"

const mainChartColors = {
    borderColor: '#000000',
    labelColor: '#1951C1',
    opacityFrom: 0.45,
    opacityTo: 0,
}

export const DefaultChartConfig = {
    chart: {
        // type: 'bar',
        fontFamily: 'Inter, sans-serif',
        foreColor: mainChartColors.labelColor,
        toolbar: {
            show: true
        }

    },
    fill: {
        type: 'linear',

    },
    dataLabels: {
        enabled: true
    },
    tooltip: {
        style: {
            fontSize: '16px',
            fontFamily: 'Inter, sans-serif',
        },
    },
    grid: {
        show: true,
        borderColor: mainChartColors.borderColor,
        strokeDashArray: 1,
        padding: {
            left: 35,
            bottom: 15
        }
    },

    markers: {
        size: 5,
        strokeColors: '#DA4C4C',
        hover: {
            size: undefined,
            sizeOffset: 3
        }
    },
    // xaxis: {
    //     categories: [
    //         'Jan',
    //         'Feb',
    //         'Mar',
    //         'Apr',
    //         'May',
    //         'Jun',
    //         'Jul',
    //         'Aug',
    //         'Sep',
    //         'Oct',
    //         'Nov',
    //         'Dec'
    //     ],
    //     labels: {
    //         style: {
    //             colors: [mainChartColors.labelColor],
    //             fontSize: '14px',
    //             fontWeight: 500,
    //         },
    //     },
    //     axisBorder: {
    //         color: mainChartColors.borderColor,
    //     },
    //     axisTicks: {
    //         color: mainChartColors.borderColor,
    //     },
    //     crosshairs: {
    //         show: true,
    //         position: 'back',
    //         stroke: {
    //             color: mainChartColors.borderColor,
    //             width: 1,
    //             dashArray: 10,
    //         },
    //     },
    // },
    yaxis: {
        labels: {
            style: {
                colors: [mainChartColors.labelColor],
                fontSize: '14px',
                fontWeight: 500,
            },
            // formatter: function (value) {
            //     return '' + value;
            // }
        },
    },
    legend: {
        fontSize: '14px',
        fontWeight: 500,
        fontFamily: 'Inter, sans-serif',
        labels: {
            colors: [mainChartColors.labelColor]
        },
        itemMargin: {
            horizontal: 10
        }
    },
    plotOptions: {
        bar: {
            columnWidth: '60%',
        }
    },
    responsive: [
        {
            breakpoint: 1024,
            options: {
                xaxis: {
                    labels: {
                        show: false
                    }
                }
            }
        }
    ]
} satisfies ApexCharts.ApexOptions;

type Props = {

    categories?: Array<string> | any,
    options?: ApexCharts.ApexOptions;
    series?: Array<{ name: string, data: Array<number> }> | any,
    width?: number | string,
    height?: number | string,
    showToolbar?: boolean,
    className?: string,
    type?: "area" | "line" | "bar" | "pie"
    | "donut" | "radialBar" | "scatter"
    | "bubble" | "heatmap" | "candlestick" | "boxPlot" | "radar" | "polarArea" | "rangeBar" | "rangeArea" | "treemap",
}


const BasicChart = ({ series, width, height, type, className = "", options }: Props) => {



    return (
        <main className={`w-full ${className}`}>
            <Chart options={options ?? DefaultChartConfig} series={series} type={type} width={width} height={height ?? 400} />

        </main>
    );
};

export default memo(BasicChart);

