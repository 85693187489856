// export const staffInitiativescolumn = [
//     {id : 0, caption : 'SAFETY TOPIC',datafield : 'ID',datatype : 'string', width : 200, fixed : true, visible : true},
//     {id : 1, caption : 'SHORT NAME', datafield : 'REMOVAL REASON',datatype : 'string', width : 160, fixed : true , visible : true},
//     {id : 3, caption : 'ORDER',datafield : 'r',datatype : 'string', width : 90, fixed : false , visible : true},
//     {id : 4, caption : 'ACTIVE?',datafield : 'Rrr',datatype : 'boolean', width : 100, fixed : false , visible : true},
//     {id : 5, caption : 'PSMA?',datafield : 'Rrr',datatype : 'boolean', width : 100, fixed : false , visible : true},
//     {id : 6, caption : 'ERMM?',datafield : 'Rrr',datatype : 'boolean', width : 100, fixed : false , visible : true},
//     {id : 7, caption : 'PSSA?',datafield : 'Rrr',datatype : 'boolean', width : 100, fixed : false , visible : true},
//     {id : 10, caption : 'REMARKS',datafield : 'Rrg',datatype : 'string', width : 5000, fixed : false , visible : true},
// ]

export const sinitCols = [
    {id : 0, caption : 'ID',dataField : 'sinIdpk',datatype : 'number', width : 200, fixed : true, visible : false},
    {id : 1, caption : 'NAME',dataField : 'sinName',datatype : 'string', width : 200, fixed : true, visible : true},
    {id : 2, caption : 'SHORT NAME', dataField : 'sinShtName',datatype : 'string', width : 160, fixed : true , visible : true},
    {id : 3, caption : 'ORDER', dataField : 'sinOrder',datatype : 'decimal', width : 100, fixed : true , visible : true},
    {id : 4, caption : 'ACTIVE?',dataField : 'sinActive',datatype : 'boolean', width : 90, fixed : false , visible : true},
    {id : 5, caption : 'PSA?',dataField : 'sinPreventStaffSelfAssignmentForAll',datatype : 'boolean', width : 100, fixed : false , visible : true},
    {id : 6, caption : 'ERM..?',dataField : 'sinEnforceResultMonitoringModule',datatype : 'boolean', width : 100, fixed : false , visible : true},
    {id : 7, caption : 'PMU?',dataField : 'sinPreventManualUpdatesForAll',datatype : 'boolean', width : 100, fixed : false , visible : true},
    {id : 8, caption : 'RESULT MONITORING MODULE?',dataField : 'rmmName',datatype : 'string', width : 200, fixed : false , visible : true},
    {id : 9, caption : 'REMARKS',dataField : 'sinRmks',datatype : 'string', width : 5000, fixed : false , visible : true},
]
