// PERFORMANCE


export const theme_members_cols = [
    {id: 1,caption : "STAFF NO", dataField : 'empStaffNo', dataType : "string", fixed : true,width:100},
    {id: 2,caption : "STAFF NAME", dataField : 'empName', dataType : "string", fixed : false,width:100},
    {id: 3,caption : "THEME", dataField : 'empName', dataType : "string", fixed : false,width:100},
    {id: 4,caption : "JOB TITLE", dataField : 'jbtShtName', dataType : "string", fixed : false,width:100},
    {id: 5,caption : "LEADER?", dataField : 'tmbLeader', dataType : "boolean", fixed : false,width:60},
    {id: 6,caption : "ACTIVE?", dataField : 'tmbActive', dataType : "boolean", fixed : false,width:60},
    {id: 7,caption : "START DATE", dataField : 'tmbStartDate', dataType : "string", fixed : false, width:100},
    {id: 8,caption : "END DATE", dataField : 'tmbEndDate', dataType : "string",width:100},
    {id: 9,caption : "DEPT", dataField : 'dptShtName', dataType : "string",width:60},
    {id: 10,caption : "SECTION", dataField : 'sxnShtName', dataType : "string",width:100},
    {id: 11,caption : "INTERCOM", dataField : 'empIntercom', dataType : "string",width:100},
    {id: 12,caption : "MISSION", dataField : 'mvsMission', dataType : "string",width:100},
    {id: 13,caption : "REMARKS", dataField : 'thmRmks', dataType : "string",width:4000},
]

//results


export const theme_results_cols = [
    {id: 1,caption : "THEME", dataField : 'thmName', dataType : "string", fixed : true,width:100},
    {id: 2,caption : "THEME RESULTS", dataField : 'trsResult', dataType : "string", fixed : false,width:230},
    {id: 3,caption : "COMMENTARY", dataField : 'trsCommentary', dataType : "string", fixed : false,width:250},
    {id: 4,caption : "ACTIVE?", dataField : 'trsActive', dataType : "boolean", fixed : false,width:60},
    {id: 5,caption : "ORDER", dataField : 'trsOrder', dataType : "string", fixed : false,width:60},
    {id: 6,caption : "REMARKS", dataField : 'trsRmks', dataType : "string",width:4000},
]

export const inclusion_exclusion_cols = [
    {id: 1,caption : "THEME", dataField : 'thmName', dataType : "string", fixed : true,width:100},
    {id: 2,caption : "CRITERIA", dataField : 'tieCriteria', dataType : "string", fixed : false,width:230},
    {id: 3,caption : "ITEM", dataField : 'tieItem', dataType : "string", fixed : false,width:250},
    {id: 4,caption : "START DATE", dataField : 'tieStartDate', dataType : "boolean", fixed : false,width:100},
    {id: 5,caption : "END DATE", dataField : 'tieEndDate', dataType : "string", fixed : false,width:100},
    {id: 6,caption : "ACTIVE", dataField : 'tieActive', dataType : "string",width:60},
    {id: 7,caption : "MISSION", dataField : 'mvsMission', dataType : "string",width:100},
    {id: 8,caption : "REMARKS", dataField : 'tieRmks', dataType : "string",width:4000},
]


export const tab5 = [
    // {id: 1,caption : "ID", dataField : 'boolean', dataType : "boolean", fixed : true,width:60, visible: true},
    {id: 2,caption : "THEME ", dataField : 'empID', dataType : "number", fixed : true,width:100},
    {id: 3,caption : "HOW WILL IT MOVE US HIGHER", dataField : 'Staff', dataType : "number", fixed : true,width:100},
    {id: 4,caption : "START DATE", dataField : 'Name', dataType : "number", fixed : true,width:100},
    {id: 5,caption : "END DATE", dataField : 'Assignements', dataType : "date", fixed : true,width:100},
    {id: 6,caption : "ACTIVE", dataField : 'Submitted', dataType : "string", fixed : true, width:100},
    {id: 7,caption : "REMARKS", dataField : 'Unsubmitted', dataType : "string",width:4000},
   
]
export const tab4 = [
    // {id: 1,caption : "ID", dataField : 'boolean', dataType : "boolean", fixed : true,width:60, visible: false},
    {id: 2,caption : "CRITETIA ", dataField : 'empID', dataType : "number", fixed : true,width:100},
    {id: 3,caption : "SUCCESS MEASURE", dataField : 'Staff', dataType : "number", fixed : true,width:100},
    {id: 4,caption : "THEME", dataField : 'Name', dataType : "number", fixed : true,width:100},
    {id: 5,caption : "START DATE", dataField : 'Name', dataType : "number", fixed : true,width:100},
    {id: 6,caption : "END DATE", dataField : 'Assignements', dataType : "date", fixed : true,width:100},
    {id: 7,caption : "ACTIVE", dataField : 'Submitted', dataType : "string", fixed : true, width:100},
    {id: 8,caption : "REMARKS", dataField : 'Unsubmitted', dataType : "string",width:4000},
   
]