import { combineReducers } from "@reduxjs/toolkit";
import VehicleDetailsSlicer from "../../TMS-Sicers/Vehicles/VehicleDetailsSlice";
import VehicleAssignmentSlice from "../../TMS-Sicers/Vehicles/VehicleAssignmentSlice";
import VehicleLogBookSlice from "../../TMS-Sicers/Vehicles/VehicleLogBookSlice";
import VehicleRequestSlice from "../../TMS-Sicers/Vehicles/VehicleRequestSlice";
import VehicleSIMcardAssignSlice from "../../TMS-Sicers/Vehicles/VehicleSIMcardAssignSlice";
import VehicleFuelCardAssignSlice from "../../TMS-Sicers/Vehicles/VehicleFuelCardAssignSlice";
import VehicleImageSlice from "../../TMS-Sicers/Vehicles/VehicleImageSlice";
import VehicleClassAssignSlice from "../../TMS-Sicers/Vehicles/VehicleClassAssignSlice";
import VehicleSimCardSlice from "../../TMS-Sicers/Vehicles/VehicleSimCardSlice";


export const VehicleModuleSlicers = combineReducers({
    VehicleDetails : VehicleDetailsSlicer,
    VehicleAssignment : VehicleAssignmentSlice,
    VehicleLogBook : VehicleLogBookSlice,
    VehicleRequest :  VehicleRequestSlice,
    VehicleSIMCardAssignment : VehicleSIMcardAssignSlice,
    VehicleFuelCardAssignment : VehicleFuelCardAssignSlice,
    VehicleImage: VehicleImageSlice,
    vehicleClassAssignement : VehicleClassAssignSlice,
    vehicleSimCard : VehicleSimCardSlice,
})

