
import { Checkbox, Form } from 'antd';
import { TabPanel, Tooltip } from 'devextreme-react';
import { Item } from 'devextreme-react/accordion';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { task_footer_table_selected } from '../../../../features/Task&Todo/Task/TaskDetails';
import useFetch from '../../../../hooks/useFetch';
import  Datagrid_template from '../../../../templates/Datagrid';
import { TextareaTemplate } from '../../../../templates/textarea';
import { LockButton, SignOff } from '../../../accessories/buttons';
// import { SIA_toolbar } from '../../../accessories/custom-toolbars/sia_toolbar copy';
import { unit_initiative_assignement_footer_datagrid_cols } from '../../data/datagrid_cols';
import { SIA_assignment_update } from './widgets/SIA_assignment_update';
import { SIA_Details_Form } from './widgets/SIA_details_form';
import { SIA_Assignments_Details_Form } from './widgets/SIA_assignment_details_form';
import switch_chart from '../../../../assets/switch-chart.png';
import reset from '../../../../assets/reset.png';
import settings from '../../../../assets/setting.png';
import { RootState } from '../../../../app/store';
import { setGeneralValue } from '../../../../features/generalSlice';
import { FormModal } from '../../../accessories/formModal';
import { useAccountInfo } from '../../../../hooks/useAccountInfo';
import { SignOff_Assignments } from '../SignOff Assignements/SignOff';
import { Lock_assignments } from '../LockAssignments/LockSubmission';




interface props {
    state_change: boolean;
}



export const SIA = ({ state_change }: props) => {
    // const width = window.innerWidth/2
    // const fixedWidth = 'calc(50% - 8px)';
    const pageHeight = window.innerHeight - 270;
    const [staffInitiativeTable, staffInitiativeError, staffInitiativeLoading] = useFetch("PfmStaffInitiativesAssignments/GetAllPfmStaffInitiativesAssignmentsForAdmin?prdStartDate=2021-01-01&prdEndDate=2021-03-31%2023%3A59%3A59&dptname=s");
    const childRef = useRef<any>();
    const [pgHeight, setpgHeight] = useState(true)

    const dispatch = useDispatch();
    const borderTheme = useSelector((state:RootState)=>state.general.cssBorderClass)


    const progressBar = [
        { id: 0, value: '', desc: 'Fri 01 Jan 2021 - Thu 30 Sept 2021 : ', text: 'blue-400', headings: 'Tot:24;Staff Rated:15;Sup Rated 7 : ' },
        { id: 1, label: '', value: 0.08, desc: 'Average Staff Ratings', alt: '5.00/0.00', lel: true, bg: 'orange-200' },
        { id: 2, label: '', value: 18.45, desc: 'Average Supervisor Ratings', alt: '5.00/0.00', lel: true },
        { id: 3, label: '', value: 92.54, desc: 'Avg. Target % To Completion', lel: false, bg: 'sky-200' },
        { id: 4, label: '', value: 71.54, desc: 'Avg. Staff % To Complete (All)', lel: false, bg: 'orange-200' },
        { id: 5, label: '', value: 27.08, desc: 'Avg Sup % To Complete (All)', lel: false },
    ]


    const navigate = useNavigate();
    // const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams();


    const current_page_route = '/performance-manager/entries/staff-initiative-assignments';
    const view = searchParams.get('view');
    const home = 'disableMode'
    const multigrid = 'EditMode';


    const form_disable = useSelector((state: RootState) => state.general.formDisabled)

    // new button toggler
    const onEditModeGrid = () => {

        // dispatch(update_route_state(true))

        if (form_disable) {
            // alert('new')
            // setTask_update_col_width(200)
            // console.log(ppe_update_route);
            // dispatch(task_form_disable(false));
            // dispatch(task_form_disable(false));

            navigate({
                pathname: current_page_route,
                search: createSearchParams({
                    view: multigrid,
                }).toString(),
            })

            dispatch(setGeneralValue({ expr: 'formDisabled', value: false }));
            // show display
            //  dispatch(update_route_state(false))
            // will need to seperate the else as a function and call it on the cancel button
        }

    }

    const onDisableModeGrid = () => {
        // dispatch(update_route_state(false))


        // alert("Cancelling")
        if (form_disable === false) {


            // setTask_update_col_width(100)
            // console.log(ppe_update_route);
            navigate({
                pathname: current_page_route,
                search: createSearchParams({
                    view: home,
                }).toString(),
            })

            //   // mini dispay
            dispatch(setGeneralValue({ expr: 'formDisabled', value: true }));

            // dispatch(update_route_state(true))

        }
    }

    const [employeeId, userId, accountInfo] = useAccountInfo()
    const [showForm, setShowForm] = useState(false)
    const [showLock, setShowLockForm] = useState(false)
    const [siaRemarks, setSiaRemarks] = useState('');

    // <div  className='px-5 py-0.5 text-xs bg-orange-200'>Average Staff Ratings</div>
    // <div  className='px-5 py-0.5 text-xs '>Average Supervisor Ratings</div>
    // <div  className='px-5 py-0.5 text-xs bg-sky-200 '>Avg. Target % To Completion</div>
    // <div  className='px-5 py-0.5 text-xs bg-orange-200'>Avg. Staff % To Complete (All)</div>
    // <div  className='px-5 py-0.5 text-xs '>Avg Sup % To Complete (All)</div> 


    return (
        <div className='h-full dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base '>
            {/* toggles between the taskForm and the footer grid table vertically */}
            <FormModal openState={showForm} onCancel={() => setShowForm(!showForm)} child={<SignOff_Assignments state_change={false} />} title={`Pef. Mgmt-Perf. Contract Sign Off - (${accountInfo.empName} - ${accountInfo.empStaffno})`} />
            <FormModal openState={showLock} onCancel={() => setShowLockForm(!showLock)} child={<Lock_assignments state_change={false} />} title={`Lock Submission`} />
            <div className='mx-2'>
                {/* <SIA_toolbar toggler={() => { setpgHeight(!pgHeight) }} /> */}
            </div>

            {!pgHeight ? <div></div> : <div className='mr-3.5 px-2'   >
                <p id={"form_header"} style={{borderColor:borderTheme}} className='pt-1 border-[1px] border-b-0 px-2 w-full bg-slate-100 font-semibold '> Initiative Assignment Details</p>

                {/* <p className='pt-1 px-2 w-full font-semibold bg-slate-100'>{'Core Values'}</p> */}
                <div style={{ height: '506px', borderColor:borderTheme }} className="w-full  border-2 border-slate-100 rounded-b flex justify-between gap-x-4  mb-1 pb-1">

                    {/* Internal toggler : expands the right taskUpdate horizontally */}

                    <div style={{ width: '740px', height: '495px' }} className='mb-1' >
                        <SIA_Details_Form ref={childRef} onEditModeGrid={onEditModeGrid} onDisabledModeGrid={onDisableModeGrid} />
                    </div>


                    {/* Double tap(click) feature to toggle to and fro of the right taskUpdate section */}
                    <div style={{ width: 'calc(100% - 743px)', height: '490px', borderColor:borderTheme }} className=" w-full mt-2 border-r-0 border-[1px]  rounded-md items-center dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base " >
                        <div className='w-[98%]  '>
                            <TabPanel  className="w-full h-full">
                                <Item title={'Assignment Details'} >


                                    <SIA_Assignments_Details_Form />

                                </Item>

                                <Item title={'Updates'} >

                                    <SIA_assignment_update />




                                </Item>
                            </TabPanel>
                        </div>
                        <Form
                            className=' dark:bg-slate-900 dark:text-darkModeSkin-base ml-7  rounded py-2 px-2 '
                            name="basic"
                            style={{ width: 'calc(100% - 43px)' }}
                            initialValues={{ remember: true }}
                            autoComplete="off"
                            size='small'
                            layout='horizontal'

                        >
                            <TextareaTemplate
                                defaultValue={siaRemarks}
                                useCallbackFunc
                                label={"Remarks"}
                                height={50}
                                readonly={form_disable}
                                setCallbackValue={(val) => {
                                    setSiaRemarks(val)
                                    childRef.current.setRemarks(val);
                                }}

                            />


                        </Form>
                        <Form
                            className='w-full dark:bg-slate-900 dark:text-darkModeSkin-base ml-7  rounded  px-2 '
                            name="basic"
                            style={{ width: '97%', }}
                            initialValues={{ remember: true }}
                            autoComplete="off"
                            size='small'
                            layout='horizontal'

                        >
                            <div style={{ marginLeft: '60px', width: '970px' }} className='flex w-full ml-7'>
                                <li className='list-none'><SignOff handleSignOff={() => { setShowForm(!showForm) }} /></li>
                                <li className='ml-5 list-none'><LockButton handleLock={() => { setShowLockForm(!showLock) }} /></li>
                            </div>


                        </Form>





                    </div>


                </div>
            </div>
            }

            {/* Footer gridtable  */}
            <div style={{ height: '' }} className='px-2  dark:bg-slate-900 dark:text-darkModeSkin-base'>
                <Datagrid_template rowDoubleClicked={() => { childRef.current.doubleClick() }} onRowClick={(e) => { childRef.current.singleClick(e); dispatch(setGeneralValue({ expr: 'siaDataGrid', value: e })); }}
                    columns={unit_initiative_assignement_footer_datagrid_cols} data={staffInitiativeTable} gridheight={pgHeight ? 180 : 703.7} />
                {/* <Footer_datagrid_template gridheight={705} columns={unit_initiative_assignement_footer_datagrid_cols} data={''} /> */}

                <div className='w-full flex justify-between'>
                    <p className='text-blue-500 px-1 flex justify-center items-center'>Done</p>
                    <div className='w-1/12 justify-end items-center'>
                        <li className='w-full flex justify-end'><Checkbox style={{borderColor:borderTheme}} className='border rounded px-1' /></li>
                        <li className="mt-0.5 w-full  hover:cursor-pointer  border-slate-200 text-xs px-1 py-1  justify-end  text-gray-600 rounded flex items-center">
                            <span id="switch_chart" className=" flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img className="dark:bg-slate-900  dark:text-darkModeSkin-base duration-100" src={switch_chart} /></span>
                            <Tooltip
                                target="#switch_chart"
                                showEvent="dxhoverstart"
                                hideEvent="dxhoverend"
                                hideOnOutsideClick={true}
                            >
                                <p className="">Switch between charts and table views</p>
                            </Tooltip>
                        </li>

                    </div>

                    {/*  */}

                    <div className='w-11/12 flex '>
                        <div className='w-full flex justify-end'>

                            {/* progress bars */}
                            <Form
                                className='w-full justify-end'
                                labelCol={{ span: 6 }}

                            >
                                {/* bars */}
                                <Form.Item labelCol={{ span: 2 }} label={<p className='hidden'>hi</p>} colon={false}>

                                    <div className='w-full flex justify-end '>

                                        {/* <label className='flex items-center justify-center text-blue-600 px-2'>{'ted 15; Sup Rated: 7'}</label> */}

                                        {progressBar.map(({ id, label, value, alt, lel, desc, bg, text, headings }) => {
                                            return (
                                                <>
                                                    <div style={{ width: '100%' }} className='px-1'>
                                                        <div className="flex flex-col" id="avGAllTasks">
                                                            <div className={`w-full h-[20px]  ${id === 0 ? 'bg-transparent' : 'bg-gray-300'} `}>

                                                                {id === 0 ?
                                                                    <div className={`w-full  flex  text-${text} text-xs bg-${bg}`}>
                                                                        <p style={{ width: '100%' }} className='w-full flex text-xs'>{headings}</p>
                                                                    </div>

                                                                    :
                                                                    <div className={`relative h-full flex w-full  `}>
                                                                        <div className={` absolute h-full w-full  ${id === 0 ? 'bg-transparent' : 'bg-purple-300'}  text-xs font-medium text-blue-100 text-center items-center p-1 leading-none  `} style={{ width: (value.toString() + '%') }} ></div>
                                                                        <div className={`relative text-xs flex ${id === 0 ? 'text-transparent' : 'text-black'}  items-center w-full text-center justify-center`}>{value}%</div>

                                                                    </div>
                                                                }



                                                                <div style={{ height: '100%', maxHeight: '100%' }} className={`w-full h-full ${lel && 'w-[100%]'} ${id == 0 ? ` flex` : ` px-5 mt-1 flex py-0.5 text-${text} text-xs bg-${bg}`}`}>
                                                                    <p className={`w-full h-[100%] flex text-xs ${lel && 'z-30 w-full h-full overflow-visible flex absolute'}`}>{desc}</p>
                                                                </div>



                                                            </div>
                                                        </div>
                                                    </div>
                                                    {lel && <div className='px-5 py-0.5 h-[20px] bg-yellow-200 z-0'>{alt}</div>}
                                                </>
                                            )
                                        })}

                                        <div>
                                            <span
                                                onClick={() => {
                                                    // setData('');
                                                    // handleRefresh && handleRefresh();
                                                }}
                                                style={{ height: 22.5, marginBottom: 2, borderWidth: 1, width: 24 }}
                                                className='ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded'>
                                                <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src={reset} alt="reset" />
                                            </span>

                                            <span
                                                onClick={() => {
                                                    // setData('');
                                                    // handleRefresh && handleRefresh();
                                                }}
                                                style={{ height: 22.5, marginBottom: 2, borderWidth: 1, width: 24 }}
                                                className='ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded'>
                                                <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src={settings} alt="reset" />
                                            </span>
                                        </div>
                                    </div>



                                </Form.Item>


                            </Form>



                        </div>


                    </div>


                </div>

            </div>

        </div>

    )
}