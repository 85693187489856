export const hr_setups_titles_columns = [
    {id : 0, caption : 'ID',dataField : 'titIdpk',dataType : 'number', width : 80, fixed : true, visible : false},
    {id : 1, caption : 'TITLE', dataField : 'titName',dataType : 'string', width : 160, fixed : true , visible : true},
    {id : 2, caption : 'SHORT NAME', dataField : 'titShtName',dataType : 'string', width : 160, fixed : true , visible : true},
    {id : 3, caption : 'ORDER', dataField : 'titOrder',dataType : 'string', width : 0, fixed : true , visible : true},
    {id : 4, caption : 'ACTIVE', dataField : 'titActive',dataType : 'boolean', width : 90, fixed : true , visible : true},
    {id : 5, caption : 'REMARKS',dataField : 'titRmks',dataType : 'string', width : 5000, fixed : false , visible : true}
]

export const Title_data = [
    {
        'ID' : 1,
        'TITLE' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'ORDER' : '4',
        'ACTIVE' : true,
        'REMARKS' : 'SFF'
    },
    {
        'ID' : 2,
        'TITLE' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'ORDER' : '4',
        'ACTIVE' : false,
        'REMARKS' : ''
    },
    {
        'ID' : 3,
        'TITLE' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'ORDER' : '4',
        'ACTIVE' : true,
        'REMARKS' : ''
    },
    {
        'ID' : 4,
        'TITLE' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'ORDER' : '4',
        'ACTIVE' : true,
        'REMARKS' : ''
    },
    {
        'ID' : 5,
        'TITLE' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'ORDER' : '4',
        'ACTIVE' : true,
        'REMARKS' : ''
    },
    {
        'ID' : 6,
        'TITLE' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'ORDER' : '4',
        'ACTIVE' : true,
        'REMARKS' : ''
    },
    {
        'ID' : 7,
        'TITLE' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'ORDER' : '4',
        'ACTIVE' : false,
        'REMARKS' : ''
    },
    {
        'ID' : 8,
        'TITLE' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'ORDER' : '4',
        'ACTIVE' : true,
        'REMARKS' : ''
    },
]