import React, { useState, useCallback,useRef } from 'react'
import {Form, Input, Select,DatePicker, Checkbox } from 'antd';
import { CheckBox } from 'devextreme-react/check-box';

import { NewButton, SaveButton, SignOff } from '../../../../accessories/buttons';
import { Objective_Destination_Date, Objective_Destination_Mini_TextArea, Objective_Destination_TextArea, Objective_Team_Date, Objective_Team_TextArea, Theme_Result_Mini_TextArea, Theme_Result_Order_Input, Theme_Result_TextArea, Theme_tab6_Mini_TextArea, Theme_tab6_TextArea } from '../../../data/Performance_inputs';
import { Objective_Destination_Select, Objective_Team_Select, Theme_Result_Select, Theme_tab6_Select } from '../../../data/Performance_selects';
import { SelectsTemplate } from '../../../../../templates/select';
import { TextareaTemplate } from '../../../../../templates/textarea';
import { inputsTypes } from '../../../../human-resource/setups/data/types/selectsTypes';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../app/store';




export const Theme_tab6_Details = () =>{
    const { Option } = Select;
    const { TextArea } = Input;

    // import { AssignedDept, AssignedLocation, AssignedSelection, AssignedUnit, Batch, Group, PPE_Select, RequestWindow, StaffName } from './PPE_selects';

    const borderTheme = useSelector((state:RootState)=>state.general.cssBorderClass)

    const options = [];
    for (let i = 1; i < 36; i++) {
    options.push({
        label: i.toString(36) + i,
        value: i.toString(36) + i,
    });
    }

    const [textAreaHeight , settextAreaHeight] = useState<string>('75px')

    const handleChange = (value: string[]) => {
    console.log(`selected ${value}`);
        if(value.length > 7 ) {
            settextAreaHeight('60px')
        }else {
            settextAreaHeight('71px')
        }
    };
    const dateFormat = " DD MMM YYYY HH: mm:ss";
   


    
    const [tasks_update_state, setTask_update_state] = useState<Boolean>(true)
    const [tasks_update_state_width, setTask_update_state_width] = useState<string>('100%')
    const [tasks_update_col_width, setTask_update_col_width] = useState<number>(100)
    const [tasks_update_col_height, setTask_update_col_height] = useState<number>()
    const [task_datagrid_width,set_task_datagrid_width] = useState(`calc(100% - ${'550px'})`);
    const [AppraiseisActive, AppraisesetIsActive] = useState<boolean>(false)
    


    const taskDetailsFormHeight = useRef(null);

    // const [heightHook, setheightHook] = useState<any>()

    // const refHook = (e:any) =>{
    //     if(e.length = 1){
    //         console.log("true");
            
    //     }
    // }

    // const refHook = ()=>{
        
        
    //     console.log(taskDetailsFormHeight);
    //     console.log('====================================');
    // }

    const textareas : inputsTypes[]=[
        {id:0, label:'How will it Move Us?', stateName:'',defaultValue:'', style:{height: 90, marginLeft: 23}},
        {id:1, label:'Remarks', stateName:'',defaultValue:'', style:{height: 145, marginLeft: 88}},
    ]

    
    
    return (
        
        <div style={{width:'900px', minWidth:'900px', height:'100%',borderColor:borderTheme}}  className="  border-[1px]   dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base  ">
                            {/* <h4 className=" w-full  mb-2 bg-slate-100  ">Task Details</h4> */}
                            <div style={{maxHeight:'280px', minHeight:'280px', height:'280px'}} className=' items-start justify-start '   >
                                
                                <Form
                                    className='w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 px-2 '
                                    name="basic"
                                    labelCol={{ span: 4}}
                                    // wrapperCol={{ span: 24 }}
                                    initialValues={{ remember: true }}
                                    autoComplete="off"
                                    size='small'
                                    layout='horizontal'

                                >
                                    <SelectsTemplate
                                        label={'Theme'} 
                                        options={[]}
                                        useCallFunc
                                        dataexp={''}
                                        idexpr={''}
                                        wrapperCol={10}
                                        selectedValue={()=>{}}
                                    />


                                    {textareas.map(({id,label,defaultValue,stateName,style})=>{
                                        return(
                                            <>
                                                <TextareaTemplate
                                                    height={style.height}
                                                    label={label}
                                                    // defaultValue={defaultValue}
                                                    useCallbackFunc
                                                    MarginLeft={style.marginLeft}
                                                    setCallbackValue={()=>{}}


                                                />
                                            </>
                                        )
                                    })}
                                    
                                    {/* <Theme_tab6_Select />
                                    <Theme_tab6_Mini_TextArea />
                                    <Theme_tab6_TextArea /> */}

                                    
                                            {/* <Group />
                                            <StaffName />
                                            <RequestWindow />
                                            <Batch />
                                            <AssignedDept />
                                            <AssignedSelection />
                                            <AssignedUnit />
                                            <AssignedLocation /> */}
                                       <Form.Item labelCol={{span:4}} label={<p className='dark:bg-fill dark:text-darkModeSkin-base text-xs'>{'Order'}</p>}>
                                       <div className='w-full  flex flex-col  '>
                                            <div style={{width:'calc(900px - 165px)'}} className=' flex  justify-between  '>
                                                {/* <li className='flex justify-start '><Cancel/></li> */}
                                                <ul className='flex justify-evenly'>
                                                    <li > <Input className='w-24'/></li>
                                                    <li className=' flex h-6 items-center flex-row ml-5'><p className='mr-2 text-xs'>Active? : </p>  <Checkbox style={{borderColor:borderTheme}} className='border-[1px] rounded px-1' /></li>
                                                </ul>
                                                <ul className='flex justify-end  '>
                                                    <SaveButton />
                                                    <NewButton />
                                                </ul>
                                            </div>
                                        </div>
                                       </Form.Item>
                                </Form>
                            </div>

                            

                        </div>
    )
}