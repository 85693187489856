import { useCallback, useState } from "react";
import DataGrid, {
  Column,
  Export,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  Scrolling,
  SearchPanel,
  Selection,
} from "devextreme-react/data-grid";

interface props {
  gridheight: number;
  columns: any;
  data: any;
  loading?: boolean;
}

export const Footer_datagrid_template = ({
  gridheight,
  columns,
  data,
  loading,
}: props) => {
  const [selectedEmployee, setSelectedEmployee] = useState();
  const selectEmployee: any = useCallback<any>((e: any) => {
    e.component.byKey(e.currentSelectedRowKeys[0]).done((employee: any) => {
      setSelectedEmployee(employee);
    });
  }, []);

  const displayModes = [
    { text: "Display Mode 'full'", value: "full" },
    { text: "Display Mode 'compact'", value: "compact" },
  ];
  const allowedPageSizes = ["all", 5, 10, 15];

  return (
    <div className="w w-full mt-1">
      {/* custom style for search section */}
      <style
        dangerouslySetInnerHTML={{
          __html: `
                    .dx-datagrid-header-panel .dx-toolbar {
                        margin-bottom: 4px;
                    }`,
        }}
      />
      <DataGrid
        dataSource={data}
        showBorders={true}
        columnWidth={30}
        showRowLines={true}
        showColumnLines={true}
        height={gridheight}
        allowColumnReordering={true}
        allowColumnResizing={true}
        rowAlternationEnabled={true}
        onSelectionChanged={selectEmployee}
      >
        <Export enabled={true} />
        <HeaderFilter visible={true} allowSearch={true} />
        <SearchPanel width={300} visible={true} />
        <GroupPanel visible={true} />
        <Selection mode="single" />

        {/* STAFF DETAILS COLUMNS */}
        {/* FIXED COLUMNS */}
        {columns.map((column: any) => {

          let dataType = column.dataType as "string" | "number" | "boolean" | "object" | "date" | "datetime" | undefined
          return (
            <Column
              key={column.id}
              dataField={column.dataField}
              caption={column.caption}
              dataType={dataType}
              width={column.width}
              alignment="left"
              fixed={column.fixed}
            />
          );
        })}

        <Scrolling rowRenderingMode={"virtual"} columnRenderingMode="virtual" />
        <Paging defaultPageSize={0} />
        <Pager
          visible={true}
          allowedPageSizes={"auto"}
          displayMode={"compact"}
          showInfo={true}
          showNavigationButtons={true}
          showPageSizeSelector={true}
        />
      </DataGrid>
    </div>
  );
};
