import React, { useEffect, useRef, useState } from "react";
import { Form, Modal } from "antd";
import type { DraggableData, DraggableEvent } from "react-draggable";
import Draggable from "react-draggable";
import { InputsTemplate } from "../../../templates/input";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import help from "../../../assets/helpothers.png";
import { modalPropTypes } from "../../../components/human-resource/setups/data/types/selectsTypes";
import { ModalTemplate } from "../../../templates/modal";
import { PasswordRulesModal } from "./passwordRulesModal";
import { api_url } from "../../../components/accessories/component_infos";
import axios from "axios";

interface props {
  open: boolean;
  handleCancel: () => void;
  StaffNo: string;
}
const RequestPasswordResetModal = ({
  open,
  handleCancel,
  StaffNo: extStaffNo,
}: props) => {
  const userID = useSelector(
    (state: RootState) => state.user.user?.userModel?.usaIDpk
  ); //emp id
  const employeeID = useSelector(
    (state: RootState) => state.user.user?.userModel?.empIDpk
  ); //emp id

  const token = useSelector((state: RootState) => state.user.user?.token); //emp id

  const [disabled, setDisabled] = useState(false);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef<HTMLDivElement>(null);

  const [staffNo, setStaffNo] = useState<string>("");
  useEffect(() => {
    setStaffNo(extStaffNo);
  }, [extStaffNo]);

  const [modalAlert, setModalAlert] = useState<modalPropTypes>({
    state: false,
    title: "",
    message: "",
  });
  const [passwordRules, setPasswordRules] = useState<boolean>(false);

  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  //password validation
  const validateStaffNo = () => {
    if (!staffNo) {
      setModalAlert({
        state: true,
        title: "Enter Staff No",
        message: "Please enter Username or Staff No",
        icon: "warning",
        func() {
          setModalAlert({ ...modalAlert, state: false });
        },
      });

      return false;
    }

    return true;
  };

  const handlePasswordReset = async (action: "confirm" | "reset") => {
    if (action === "confirm") {
      setModalAlert({
        state: true,
        title: "Reset Password?",
        message: "Are you sure you want to reset your password?",
        icon: "question",
        func() {
          handlePasswordReset("reset");
        },
      });

      return;
    }

    try {
      const resetResponse = await axios.put(
        `${api_url}/api/Accounts/ResetPassword`,
        {
          usaEditedBy: userID,
          usaEmpIdfk: employeeID,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      //success
      if (resetResponse.status === 202) {
        setModalAlert({
          //success modal
          state: true,
          title: "Reset Successful",
          message: "Password changed succesfully",
          icon: "success",
          func() {
            disableModal();

            setTimeout(() => {
              //disable reset modal
              handleCancel();
            }, 300);
          },
        });
      }
    } catch (error: any) {
      //incorrect initial password
      if (error.response.status === 400) {
        setModalAlert({
          state: true,
          title: "Password Reset Failed",
          message:
            error.response?.data?.errors[0] ??
            "Password reset failed. Please check your password and try again",
          icon: "warning",
          func() {
            setModalAlert({ ...modalAlert, state: false });
          },
        });

        return;
      }

      setModalAlert({
        state: true,
        title: "Password Reset Failed",
        message: "Password reset failed. Please contact system administrator",
        icon: "warning",
        func() {
          setModalAlert({ ...modalAlert, state: false });
        },
      });

      return;
    }
  };

  const disableModal = () => {
    setModalAlert({ ...modalAlert, state: false }); //disable confirm
  };

  return (
    <>
      <Modal
        maskClosable={false}
        style={{ top: 100 }}
        width={350}
        footer=""
        title={
          <div
            style={{
              width: "100%",
              cursor: "move",
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
            onFocus={() => {}}
            onBlur={() => {}}
            // end
          >
            Reset Password
          </div>
        }
        open={open}
        onOk={() => {}}
        onCancel={handleCancel}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <PasswordRulesModal
          open={passwordRules}
          onCancel={() => setPasswordRules(false)}
          onOk={() => setPasswordRules(false)}
        />
        <>
          {/* modal alert */}
          <ModalTemplate
            icon={modalAlert.icon ?? "question"}
            disableCancel={modalAlert.icon === "question" ? false : true}
            cancelText={"No"}
            open={modalAlert.state}
            okHandler={() => modalAlert.func!()}
            cancelHandler={() => {
              setModalAlert({
                state: false,
                title: "",
                message: "",
              });

              if (modalAlert.icon === "success") {
                setTimeout(() => {
                  //disable reset modal
                  handleCancel();
                }, 200);
              }
            }}
            message={modalAlert.message}
            okText={modalAlert.icon === "warning" ? "Ok" : "Yes"}
            title={modalAlert.title}
          />

          <hr className=" mb-2" />
          <Form labelCol={{ span: 8 }}>
            <InputsTemplate
              placeholder={"Username or Staff No"}
              defaultValue={staffNo}
              orderOnchange={(text) => setStaffNo(`${text}`)}
              disabledStatus={false}
              useCallbackFunc
              span
            />

            <div className="flex flex-row justify-between mt-1">
              <span
                onClick={() => setPasswordRules(true)}
                style={{ height: 32 }}
                className="hover:cursor-pointer border-2 hover:border-blue-300 rounded flex justify-center items-center"
              >
                <img className=" w-8/12 h-7/12" src={help} alt="" />
              </span>

              <button
                onClick={() =>
                  validateStaffNo() && handlePasswordReset("confirm")
                }
                className="hover:cursor-pointer dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  px-2 py-1 border-2 border-slate-200 rounded hover:border-blue-300"
              >
                Reset
              </button>
            </div>
          </Form>
        </>
      </Modal>
    </>
  );
};

export default RequestPasswordResetModal;
