import { Form } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import useFetch from "../../../../../../../hooks/useFetch";
import { BottomBtns } from "../../../../../../../templates/butttons";
import { DateTemplate } from "../../../../../../../templates/date";
import { InputsTemplate } from "../../../../../../../templates/input";
import { SelectsTemplate } from "../../../../../../../templates/select";
import { TextareaTemplate } from "../../../../../../../templates/textarea";
import {
  modalPropTypes,
  modalTypes,
  selectObjectTypes,
  selectsTypes,
} from "../../../../../../human-resource/setups/data/types/selectsTypes";
import dayjs, { Dayjs } from "dayjs";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { updateModalData } from "../../../../../../accessories/component_infos";
import { useDispatch } from "react-redux";
import { setGeneralValue } from "../../../../../../../features/generalSlice";
import { UpdateDataFunc } from "../../../../../../../functions/update";
import { PostDataFunc  } from "../../../../../../../functions/post";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { useCrudFunc } from "../../../../../../../functions/crud";

interface StateTypes {
  date: string;
  serviceProvider: { id: number; value: string };
  serviceStation: { id: number; value: string };
  product: { id: number; value: string };
  unitPrice: number | null;
  currency: { id: number; value: string };
  unitOfMeasure: { id: number; value: string };
  active: boolean;
  order: number;
  remarks: string;
  refresh: boolean;
  selectedRecordId: number;

  confirmModal: modalPropTypes;
  warningModal: modalPropTypes;
  successModal: modalPropTypes;
}

interface props {
  busyloader: (text: string) => void;
  refreshGrid: () => void;
  clearSelected: () => void;
  selectedField: any;
}

export const ProductPrices_form = ({
  busyloader,
  refreshGrid,
  selectedField,
}: props) => {
  const [emploeeId, userId] = useAccountInfo();

  const dispatch = useDispatch();

  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  //populate fields
  useEffect(() => {
    populateStatesFromGrid(false);
  }, [selectedField]);

  //component states
  const [states, setState] = useState<StateTypes>({
    date: dayjs(new Date()).format(),
    serviceProvider: { id: 0, value: "" },
    serviceStation: { id: 0, value: "" },
    product: { id: 0, value: "" },
    unitPrice: null,
    currency: { id: 0, value: "" },
    unitOfMeasure: { id: 0, value: "" },
    order: 0,
    active: false,
    remarks: "",
    refresh: false,
    selectedRecordId: 0,

    confirmModal: { state: false, title: "", message: "" },
    warningModal: { state: false, title: "", message: "" },
    successModal: { state: false, title: "", message: "" },
  });
  const {
    date,
    serviceProvider,
    successModal,
    confirmModal,
    selectedRecordId,
    serviceStation,
    product,
    order,
    unitPrice,
    currency,
    unitOfMeasure,
    active,
    remarks,
    warningModal,
  } = states;

  const updateState = (
    stateName: string,
    value: string | modalPropTypes | boolean | number | selectObjectTypes
  ): void => {
    //update single state

    setState((prev) => ({ ...prev, [stateName]: value }));
  };

  // populate dropdowns
  const [spData, spError, spLoading] = useFetch(
    "AssetMgrProductPriceDetails/PopLueServiceProviders"
  );
  const [serviceStationData, serviceStationError, serviceStationLoading] =
    useFetch(
      `AssetMgrProductPriceDetails/PopLueServiceStations?ServiceProvider=${serviceProvider.id}`
    );
  const [productData, productError, productLoading] = useFetch(
    "AssetMgrProductPriceDetails/PopLueProducts"
  );
  const [currencyData, currencyError, currencyLoading] = useFetch(
    "AssetMgrProductPriceDetails/PopLueCurrency"
  );
  const [uomData, uomError, uomLoading] = useFetch(
    "AssetMgrProductPriceDetails/PopLueUnitOfMeasure"
  );
  const [posting , updating]=useCrudFunc()
  const disableControls = () => {
    //disable Update Mode
    dispatch(setGeneralValue({ expr: "updateMode", value: false }));

    //disable forms
    dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
  };

  //populate states from grid
  const populateStatesFromGrid = (clearFields?: boolean) => {
    const populateInstances = [
      {
        state: "date",
        value: clearFields ? dayjs(new Date()).format() : selectedField.pprDate,
      },
      {
        state: "serviceProvider",
        value: clearFields
          ? { id: 0, value: "" }
          : { id: selectedField.notset, value: selectedField.spdName },
      },
      {
        state: "serviceStation",
        value: clearFields
          ? { id: 0, value: "" }
          : {
              id: selectedField.pprServiceStationIdfk,
              value: selectedField.sstName,
            },
      },
      {
        state: "product",
        value: clearFields
          ? { id: 0, value: "" }
          : { id: selectedField.pprProductIdfk, value: selectedField.prdName },
      },
      {
        state: "unitPrice",
        value: clearFields ? null : selectedField.pprUnitPrice,
      },
      {
        state: "currency",
        value: clearFields
          ? { id: 0, value: "" }
          : {
              id: selectedField.pprCurrencyIdfk,
              value: selectedField.curSymbol,
            },
      },
      {
        state: "unitOfMeasure",
        value: clearFields
          ? { id: 0, value: "" }
          : { id: selectedField.notset, value: selectedField.funName },
      },
      { state: "order", value: clearFields ? 0 : selectedField.pprOrder },
      { state: "active", value: clearFields ? true : selectedField.pprActive },
      { state: "remarks", value: clearFields ? "" : selectedField.pprRmks },
      {
        state: "selectedRecordId",
        value: clearFields ? "" : selectedField.pprIdpk,
      },
    ];

    for (let instance of populateInstances)
      updateState(instance.state, instance.value);
  };

  //post data
  // validation
  const validateInputs = () => {
    if (product.id === 0 || !product.id) {
      updateState("warningModal", {
        state: true,
        title: "Select Product!",
        message: "Please select product.",
      });
      return false;
    } else if (!unitPrice) {
      updateState("warningModal", {
        state: true,
        title: "Enter unit price!",
        message: "Please enter unit price.",
      });
      return false;
    } else if (currency.id === 0 || !currency.id) {
      updateState("warningModal", {
        state: true,
        title: "Select Currency!",
        message: "Please select currency.",
      });
      return false;
    }

    return true;
  };

  const postData = async (action: "validate" | "post") => {
    if (action === "validate") {
      validateInputs() &&
        updateState("confirmModal", {
          state: true,
          title: "Save Record?",
          message: "Are you sure you want to save this record?",
          func() {
            postData("post");
          },
        });

      return;
    }

    updateState("confirmModal", {
      state: false,
      title: "",
      message: "",
    });

    busyloader("Saving record...");
    try {
      await posting (
        `AssetMgrProductPriceDetails/CreateProductPriceDetails`,
        {
          pprDate: date,
          pprServiceProviderIdfk: serviceProvider.id,
          pprServiceStationIdfk: serviceStation.id,
          pprProductIdfk: product.id,
          pprFuelUnitIdfk: unitOfMeasure.id,
          pprCurrencyIdfk: currency.id,
          pprUnitPrice: unitPrice,
          pprActive: active,
          pprRmks: remarks,
          pprOrder: order,
          pprCreatedBy: userId.toString(),
        }, `Created product price ${serviceProvider.id} and ${product.id}`
      );

      refreshGrid();

      populateStatesFromGrid(false);
      disableControls();
      updateState("successModal", {
        state: true,
        title: "Record Saved",
        message: "Record saved successfully",
      });
    } catch (error: any) {
      //set states for db errors modal
      updateState("warningModal", {
        state: true,
        title: "Save Failed",
        message:
          "Save failed. Please try again or contact system administrator",
      });
    } finally {
      busyloader("");
    }
  };

  //update data
  const updateData = async (action: "validate" | "put") => {
    if (action === "validate") {
      validateInputs() &&
        updateState("confirmModal", {
          state: true,
          okText: "Yes, Update",
          action: "update",
          title: "Update Record?",
          message: "Are you sure you want to update this record?",
          func() {
            updateData("put");
          },
        } );

      return;
    }

    updateState("confirmModal", {
      state: false,
      title: "Record Updated",
      message: "Record updated successfully",
    });
    busyloader("Updating record...");
    try {
      await updating(
        `AssetMgrProductPriceDetails/UpdateProductPriceDetails/${selectedRecordId}`,
        {
          pprDate: date,
          pprServiceProviderIdfk: serviceProvider.id,
          pprServiceStationIdfk: serviceStation.id,
          pprProductIdfk: product.id,
          pprFuelUnitIdfk: unitOfMeasure.id,
          pprCurrencyIdfk: currency.id,
          pprUnitPrice: unitPrice,
          pprActive: active,
          pprRmks: remarks,
          pprOrder: order,
          pprEditedBy: userId,
          pprEditedDate: dayjs(new Date()).format(),
        }, `Updated product prices of ${product?.value}`
      );
      // populateStatesFromGrid(true)
      disableControls();
      refreshGrid();

      updateState("successModal", {
        state: true,
        title: "Record Updated",
        message: "Record updated successfully",
      });
    } catch (error: any) {
      //set states for db errors modal
      updateState("warningModal", {
        state: true,
        title: updateModalData.error.title,
        message: updateModalData.error.message,
      });
    } finally {
      busyloader("");
    }
  };

  // modal instances
  const modals: modalTypes[] = [
    //validation
    {
      disableCancel: true,
      icon: "warning",
      open: warningModal.state,
      okHandler: () =>
        updateState("warningModal", { state: false, title: "", message: "" }),
      cancelHandler: () =>
        updateState("warningModal", {
          state: false,
          title: "string",
          message: "string",
        }),
      message: warningModal.message,
      okText: "Ok",
      title: warningModal.title,
      cancelText: "No, cancel",
    },

    //confirmation
    {
      disableCancel: false,
      icon: "question",
      open: confirmModal.state,
      okHandler: confirmModal.func!,
      cancelHandler: () =>
        updateState("confirmModal", {
          state: false,
          title: "string",
          message: "string",
        }),
      message: states.confirmModal.message,
      okText: confirmModal.action === "update" ? "Yes, Update" : "Yes, Save",
      title: states.confirmModal.title,
      cancelText: "No, cancel",
    },

    //successX
    {
      disableCancel: true,
      icon: "success",
      open: successModal.state,
      okHandler: () => {
        updateState("successModal", { state: false, title: "", message: "" });
      },
      cancelHandler: () =>
        updateState("successModal", {
          state: false,
          title: "string",
          message: "string",
        }),
      message: successModal.message,
      okText: "Ok",
      title: successModal.title,
      cancelText: "No, cancel",
    },
  ];

  const selects: selectsTypes[] = [
    {
      id: 0,
      label: "Service Provider",
      idExpr: "spdIdpk",
      dataExpr: "spdName",
      stateName: "serviceProvider",
      optionsData: spData,
      defaultValue: serviceProvider.value,
    },
    {
      id: 1,
      label: "Service Station",
      idExpr: "sstIdpk",
      dataExpr: "sstName",
      stateName: "serviceStation",
      optionsData: serviceStationData,
      defaultValue: serviceStation.value,
    },
    {
      id: 2,
      label: "Product",
      idExpr: "prdIdpk",
      dataExpr: "prdName",
      stateName: "product",
      optionsData: productData,
      defaultValue: product.value,
    },
  ];

  const disabled = useSelector(
    (state: RootState) => state.general.formDisabled
  ); //form disabled state on general redux slice

  return (
    <>
      {
        <div className="px-2">
          {modals.map(
            (
              {
                disableCancel,
                icon,
                okHandler,
                cancelHandler,
                open,
                cancelText,
                okText,
                message,
                title,
              }: modalTypes,
              index
            ) => {
              return (
                <div key={index}>
                  <ModalTemplate
                    icon={icon && icon}
                    disableCancel={disableCancel}
                    cancelText={cancelText && cancelText}
                    open={open}
                    okHandler={okHandler}
                    cancelHandler={cancelHandler}
                    message={message}
                    okText={okText}
                    title={title}
                  />
                </div>
              );
            }
          )}

          {/* FORM HEADER */}
          <p
            id={"form_header"}
            style={{ borderColor: borderTheme }}
            className="pt-1 border-[1px] border-b-0 font-semibold px-2 w-full bg-slate-100"
          >
            {"Product Price Details"}
          </p>
          <Form
            style={{ borderColor: borderTheme }}
            className="w-full border-[1px] border-slate-100 rounded flex justify-between py-1 px-2 "
            name="basic"
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 19 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            size="small"
          >
            <section className="" style={{ width: "550px" }}>
              {/* Date */}
              <div className=" mb-0.5">
                <DateTemplate
                  selectedDate={dayjs(date)}
                  disabled={disabled}
                  width={"50%"}
                  changeDate={(dateTime: Dayjs) => {
                    updateState("date", dayjs(dateTime).format());
                  }}
                  label="Date"
                />
              </div>

              {/* selects */}
              {selects.map(
                ({
                  label,
                  optionsData,
                  idExpr,
                  dataExpr,
                  defaultValue,
                  stateName,
                }: selectsTypes) => {
                  return (
                    <div className={" mb-0.5"}>
                      <SelectsTemplate
                        useCallFunc
                        index={stateName}
                        placeHolder={defaultValue}
                        selectedValue={(object) => {
                          updateState(stateName!, {
                            id: object[idExpr!],
                            value: object[dataExpr!],
                          });
                        }}
                        dataexp={dataExpr}
                        idexpr={idExpr}
                        label={label}
                        options={optionsData}
                      />
                    </div>
                  );
                }
              )}

              <Form.Item
                label={<p className="text-xs">{"Unit price"}</p>}
                style={{ marginBottom: 0 }}
              >
                {/* <Space wrap> */}
                <div className=" w-full flex flex-row justify-between">
                  <InputsTemplate
                    inputType="number"
                    span
                    useCallbackFunc
                    disabledStatus={disabled}
                    defaultValue={unitPrice!}
                    orderOnchange={(e) => {
                      updateState("unitPrice", e);
                    }}
                    style={{
                      display: "inline-block",
                      marginRight: "calc(2%)",
                      width: "39%",
                    }}
                  />

                  <SelectsTemplate
                    idexpr="curIdpk"
                    dataexp="curSymbol"
                    placeHolder={currency}
                    useCallFunc
                    selectedValue={(object) =>
                      updateState("currency", {
                        id: object["curIdpk"],
                        value: object["curSymbol"],
                      })
                    }
                    index={"currency"}
                    style={{
                      display: "inline-block",
                      width: "calc(30%)",
                      marginLeft: "0.5%",
                    }}
                    options={currencyData}
                  />

                  <SelectsTemplate
                    idexpr="funIdpk"
                    dataexp="funName"
                    placeHolder={unitOfMeasure}
                    useCallFunc
                    selectedValue={(object) =>
                      updateState("unitOfMeasure", {
                        id: object["funIdpk"],
                        value: object["funName"],
                      })
                    }
                    index={"unitOfMeasure"}
                    style={{
                      display: "inline-block",
                      width: "calc(28.9%)",
                      marginLeft: "1%",
                    }}
                    options={uomData}
                  />
                </div>
                {/* </Space> */}
              </Form.Item>

              <InputsTemplate
                id="order1"
                inputType={"number"}
                useCallbackFunc
                defaultValue={order}
                orderOnchange={(value) => updateState("order", value)}
                span={false}
                label={"Order"}
              />

              {/* checkbox and butons */}
              <div className="mt-1">
                <BottomBtns
                  // outerDisabled
                  addActiveBorders={true}
                  saveHandler={() => postData("validate")}
                  updateHandler={() => updateData("validate")}
                  onNewClick={() => {
                    populateStatesFromGrid(true);
                  }}
                  useCallbackFunc={true}
                  defaultValue={active}
                  checkboxChange={(value: boolean) =>
                    updateState("active", value)
                  }
                  span={false}
                />
              </div>
            </section>

            {/* remarks */}
            <section className=" " style={{ width: "calc(100% - 550px)" }}>
              <TextareaTemplate
                useCallbackFunc
                defaultValue={remarks}
                setCallbackValue={(value: string) =>
                  updateState("remarks", value)
                }
                
                MarginLeft={40}
                height={200}
                label={"Remarks"}
              />
            </section>
          </Form>
        </div>
      }
    </>
  );
};
