import { Form } from "antd";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/store";
import { setValue } from "../features/forms/tier1/createSlice";
import { setTier2Value } from "../features/forms/tier2/tier2Slice";
import { setTier2CheckboxValue } from "../features/forms/tier2checkbox/tier2SliceCheckbox";
import { setTier3Value } from "../features/forms/tier3/tier3Slice";
import { setTier4Value } from "../features/forms/tier4/tier4Slice";
import React from "react";

interface props {
  readonly?: boolean | undefined;
  label?: string | ReactNode;
  height: number;
  width?: string;
  idExpr?: string;
  dataExpr?: string;
  useCallbackFunc?: boolean;
  defaultValue?: string | number;
  labelCol?: number | string;
  setCallbackValue?: (value: string) => void;
  MarginLeft?: number | string;
  bg?: any;
  disabled?: boolean | undefined;
  placeholder?:string;
  // -------------- send Sms---------
  creditUnit?: number;
  setCreditUnit?: (e: number) => void;
  creditUnitCount?: number;
  setCreditUnitCount?: (e: number) => void;
  totalnumberofchar?: number;
  setTotal_Number_Of_Char?: (e: number) => void;
  remainingChar?: number;
  setRemaining_Char?: (e: number) => void;
  char_Limit?: number;
  setChar_Limit?: (e: number) => void;
  setContent?: (e: string) => void;
  parentClassName?: string;
  //------------------------------------
}

export const TextareaTemplate = ({
  label,
  labelCol,
  height,
  width,
  parentClassName,
  MarginLeft = 0,
  dataExpr,
  useCallbackFunc,
  setCallbackValue,
  defaultValue,
  bg,
  disabled = undefined,
  setCreditUnitCount,
  setContent,
  placeholder,
}: props) => {
  const dispatch = useDispatch();
  const rxCssBorderClass = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  const fixedExpr: string = "remarks";
  // get form tier
  const tier = useSelector((state: RootState) => state.general.tier);
  const formdisabled = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const [data, setData] = useState<string | number>();
  const selectedFieldData = useSelector(
    (state: RootState) => state.general.selectedFieldData
  );

  const textareaOnchange = (value: string) => {
  
    //set Value
    setData(value);
    // pass data
    useCallbackFunc ? setCallbackValue!(value) : setRemarkValue(value);
  };

  useEffect(() => {
    if (useCallbackFunc) {
      setData(defaultValue ?? ""); //set selected field data from local use instance
    } else {
      const remark = selectedFieldData[dataExpr!];
      
      setData(remark ?? "");
      setRemarkValue(remark ?? "");
    }
  }, [selectedFieldData]);

  const setRemarkValue = useCallback(
    (data: string | boolean) => {

      // alert(data)

      tier === "1"
        ? dispatch(setValue({ expr: fixedExpr, value: data }))
        : tier === "tier2checkbox"
        ? dispatch(setTier2CheckboxValue({ expr: fixedExpr, value: data }))
        : tier === "2"
        ? dispatch(setTier2Value({ expr: fixedExpr, value: data }))
        : tier === "3"
        ? dispatch(setTier3Value({ expr: fixedExpr, value: data }))
        : tier === "4"
        ? dispatch(setTier4Value({ expr: fixedExpr, value: data }))
        : console.log("no tier set yet");
    },
    [tier]
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const inputValue: string = e.target.value;
      setData(inputValue);
      setContent && setContent(inputValue);
      setCreditUnitCount && setCreditUnitCount(inputValue.length);
      useCallbackFunc
        ? setCallbackValue!(inputValue)
        : textareaOnchange(inputValue);
    },
    [useCallbackFunc, tier]
  );

  return (
    <Form.Item
      className={parentClassName ?? `mb-0`}
      style={{ marginLeft: MarginLeft }}
      label={
        label && (
          <p className="dark:bg-slate-900 font dark:text-darkModeSkin-base text-xs">
            {label}
          </p>
        )
      }
      labelCol={!labelCol ? { span: "3%" } : { span: labelCol }}
      wrapperCol={{ span: 24 }}
      rules={[{ required: false }]}
    >
      <textarea
        onChange={handleChange}
        placeholder={placeholder}
        disabled={disabled !== undefined ? disabled : formdisabled}
        style={{
          resize: "none",
          height: height - 3,
          width: width ?? "100%",
          borderColor: rxCssBorderClass ?? "#ff6600",
        }}
        className={`bg-${bg} overflow-y-scroll  border-[1px] mb-0 rounded focus:outline-none hover:outline-none px-[4px] `}
        value={defaultValue } //  ?? data
      />
    </Form.Item>
  );
};
