export const assets_vehicleDPHCategoriesColumns = [
  {
    id: 0,
    caption: "ID",
    dataField: "dpcIDpk",
    dataType: "number",
    width: 80,
    fixed: true,
    visible: false,
  },
  {
    id: 1,
    caption: "DRIVER/PERMIT HOLDER CAT.",
    dataField: "dpcName",
    dataType: "string",
    width: 200,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "SHORT NAME",
    dataField: "dpcShtName",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 3,
    caption: "ORDER",
    dataField: "dpcOrder",
    dataType: "string",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 4,
    caption: "DRIVER?",
    dataField: "dpcDriver",
    dataType: "boolean",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 5,
    caption: "PERMIT HOLDER",
    dataField: "dpcPermitHolder",
    dataType: "boolean",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 6,
    caption: "ACTIVE?",
    dataField: "dpcActive",
    dataType: "boolean",
    width: 90,
    fixed: true,
    visible: true,
  },
  {
    id: 7,
    caption: "REMARKS",
    dataField: "dpcRmks",
    dataType: "string",
    width: 5000,
    fixed: false,
    visible: true,
  },
];
