import { useState } from "react";
import VehicleImagesDetails from "./vehicleImages-details";

const VehicleImages = () => {
  const [showTable, setShowTable] = useState(true)
  return (
    <>
      <div className="pl-2 mb-0.5  ">
        <VehicleImagesDetails toggled={showTable} />
      </div>
    </>
  );
};

export default VehicleImages;
