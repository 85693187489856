import { DatePicker, Form } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";
import {
  dateFormat,
  dbDateFormat,
} from "../components/accessories/component_infos";
import { useCallback } from "react";
import dayjs, { Dayjs } from "dayjs";
interface props {
  label?: string;
  style?: any;
  span?: boolean;
  width?: number | string;
  selectedDate?: any | Dayjs;
  datePlaceholder?: string;
  changeDate?: (e: any) => void;
  disabled?: any;
  disabledDate?: any;
  dbFormat?: boolean;
  format?: string;
  showTime?: boolean;
  labelCol?: any;
  className?: string;
  dateType?: "time" | "date" | "month" | "year" | "decade";
}
export const DateTemplate = ({
  label,
  style,
  dbFormat,
  span = true,
  width,
  selectedDate,
  datePlaceholder,
  changeDate,
  showTime = false,
  disabled,
  disabledDate,
  format = "DD MMM YYYY",
  labelCol,
  dateType = "date",
  className,
}: props) => {
  const reduxDisabled: boolean = useSelector(
    (state: RootState) => state.general.formDisabled
  );

  const handleDate = useCallback((date: any) => {
    dbFormat ? changeDate!(date!.format(dbDateFormat)) : changeDate!(date);
  }, []);

  return (
    <Form.Item
      className={className ?? ""}
      labelCol={labelCol && { span: labelCol }}
      label={
        label && (
          <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
            {label}
          </p>
        )
      }
      rules={[{ required: false }]}
      style={style}
    >
      <style
        dangerouslySetInnerHTML={{
          __html: `

            ant-picker-ok{
                background-color: green;
            }
            .ant-btn css-dev-only-do-not-override-1ij74fp ant-btn-primary ant-btn-sm{
                background-color: green;
            }
            :where(.css-dev-only-do-not-override-1ij74fp).ant-btn-primary {
                color: #fff;
                background-color: #1677ff;
                box-shadow: 0 2px 0 rgb(5 145 255 / 10%);
            }


            `,
        }}
      />
      <span className="flex flex-row items-center">
        <DatePicker
          mode={dateType}
          disabledDate={disabledDate}
          allowClear={false}
          disabled={disabled ?? reduxDisabled}
          onChange={handleDate}
          showTime={showTime}
          value={selectedDate}
          style={{ width: width ? width : "100%", ...style }}
          className="border-[1px]"
          format={showTime ? "ddd, DD MMM YYYY HH:mm" : format}
          placeholder={datePlaceholder ?? "Select Date"}
          size="small"
        />
        {!span && (
          <span
            style={{ height: 22.5, marginBottom: 0.6, width: 25.2 }}
            className=" ml-1 flex justify-center hover:cursor-pointer"
          ></span>
        )}
      </span>
    </Form.Item>
  );
};
