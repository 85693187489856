/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-pascal-case */
import { Form, Upload, UploadProps, Select } from "antd";
import { passportMini, passport_footer_cols } from "./data/datagrid_cols";
import data_sharing from "../../../../assets/data_sharing.png";
import save from "../../../../assets/floppy-disk.png";
import webCam from "../../../../assets/webCam.png";
import dayjs from "dayjs";
import useFetch from "../../../../hooks/useFetch";
import { useCallback, useEffect, useRef, useState } from "react";
import { RcFile } from "antd/es/upload";
import Datagrid_template from "../../../../templates/Datagrid";
import { modalTypes } from "../../setups/data/types/selectsTypes";
import { ModalTemplate } from "../../../../templates/modal";
import React from "react";
import { GetDataFunc } from "../../../../functions/get";
import { CustomizableButton, SearchButton } from "../../../accessories/buttons";
import WebCam_template from "../../../../templates/webCam";
import { CheckboxTemlate } from "../../../../templates/checkbox";
import { DateTemplate } from "../../../../templates/date";
import useResizeMode from "../../../../hooks/useResizeMode";
import { useDispatch } from "react-redux";
import { setGeneralValue } from "../../../../features/generalSlice";
import { useAccountInfo } from "../../../../hooks/useAccountInfo";
import { useCrudFunc } from "../../../../functions/crud";
import { update } from "lodash";
interface props {
  state_change: boolean;
}

interface stateTypes {
  date: string; 
  selectedEmployeeID: number;
  selectedPictureID: number;
  existingPictureID: number;
  imageUrl: string;
  dateFormat: string;
  employeePic: any;
  validationModal: { state: boolean; title: string; message: string };
  successModal: { state: boolean; title: string; message: string };
  confirmModal: {
    state: boolean;
    title: string;
    message: string;
    action: string;
  };
  isCam: boolean;
  camImage: any;
  refreshPic: boolean;
  updateMode: boolean;
  refresh: boolean;
}

export const Passport_Picture = ({ state_change }: props) => {
  //component states
  const [states, setStates] = useState<stateTypes>({
    date: dayjs(new Date()).toISOString(), 
    selectedEmployeeID: 0,
    selectedPictureID: 0,
    existingPictureID: 0,
    dateFormat: "DD MMM YYYY",
    imageUrl: "",
    employeePic: [],
    validationModal: { state: false, title: "", message: "" },
    confirmModal: { state: false, title: "", message: "", action: "" },
    successModal: { state: false, title: "", message: "" },
    isCam: false,
    camImage: "",
    refreshPic: false,
    updateMode: false,
    refresh: false,
  });
  const {
    date,
    confirmModal,
    successModal,
    existingPictureID,
    refreshPic,
    validationModal,
    selectedEmployeeID,
    selectedPictureID,
    employeePic,
    imageUrl,
    dateFormat,
    updateMode,
    refresh,
  } = states;

  const activeRef = useRef<any>(true);

  const webCamRef = useRef<any>(null);

  const [initialH, finalH] = useResizeMode(window.innerHeight);

  //update State
  const updateState = (stateName: string, value: any) => {
    setStates((prevStates) => ({ ...prevStates, [stateName]: value }));
  };
  const dispatch = useDispatch();

  //get all employees
  const [data, data_error, data_loading] = useFetch(
    `PassportPictureHistory/GetAllHrEmployeePassportPictures?EmpIsStaff=${activeRef.current}`,
    refresh
  );

  const capture = useCallback(() => {
    const imageSrc = webCamRef.current?.getScreenshot();
    updateState("camImage", imageSrc);
    console.log(imageSrc);
  }, [webCamRef]);

  const [empId, userId] = useAccountInfo();
  const [Posting, Updating] = useCrudFunc();

  //get picture by emp id
  useEffect(() => {
    const get = async () => {
      const res = await GetDataFunc(
        // `PassportPictureHistory/GetPictureDetails/1`
        `PassportPictureHistory/GetPictureDetails/${selectedEmployeeID}`
      );

      const data = res?.data.map((item: any, i: number) => {
        return {
          ...item,
          id: i, // replace 'id' with the actual key property from the object
        };
      });
      updateState("employeePic", data);
    };
    get();
  }, [selectedEmployeeID]);

  //validate fields
  const validate = (): boolean => {
    if (!imageUrl) {
      updateState("validationModal", {
        state: true,
        title: "Upload image",
        message: "please upload image",
      });
      return false;
    } else if (date === "0000-00-04T00:00:00") {
      updateState("validationModal", {
        state: true,
        title: "Select date",
        message: "please select date",
      });
      return false;
    }
    return true;
  };

  //get image
  const [picDetailData, empPicDetailsError, empPicDetailsLoading] = useFetch(
    selectedPictureID
      ? `PassportPictureHistory/GetEmployeePictures/${selectedPictureID}`
      : ""
  );

  useEffect(() => {
    console.log("pictureDetaiksData", picDetailData);

    picDetailData && updateState("imageUrl", picDetailData[0]?.pphPicture);
  }, [picDetailData, empPicDetailsError]);

  // Converting it to base64 format
  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    // reads the type of file
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  // Image Upload change
  const imageUpload: UploadProps["onChange"] = (info: any) => {
    getBase64(info.file.originFileObj as RcFile, (url) => {
      updateState("imageUrl", url.split(",")[1]); //removes the data:image/jpeg;base64, from the url
      console.log(url.slice(0, 10));
    });
  };

  //disable confirm modal
  const disableComfirmModal = () => {
    updateState("confirmModal", {
      state: false,
      title: "",
      message: "",
    });
  };

  //handle save
  const handleSave = () => {
    if (validate()) {
      // enable confirmation modal
      updateState("confirmModal", {
        state: true,
      });
    }
    return;
  };

  const handleUpdateImage = () => {
    if (validate()) {
      console.log("update image");
      // enable confirmation modal
      updateState("confirmModal", {
        state: true,
      });
    }
    return;
  };

  // post to main passport pics fuctionalities

  // save to history functionality
  const postData = async () => {
    // disable confirm modal
    disableComfirmModal();

    // make initial post request
    try {
      await Posting(
        `PassportPictureHistory/UploadNewHrEmployeePassportPicture`,
        {
          pphEmpIdfk: selectedEmployeeID,
          pphPictureStr: imageUrl,
          pphDate: date,
          pphActive: activeRef.current,
          pphRmks: "",
          pphCreatedBy: empId,
        },
        `Created new passport picture for employee ${selectedEmployeeID} on ${date}, Created by ${userId}`
      );

      updateState("successModal", {
        state: true,
        title: "Success",
        message: "Image saved successfully",
      });
    } catch (error) {
      console.log(error);
    }
  };

  // update in history functionality
  const updateData = async () => {
    disableComfirmModal();

    // make update request
    try {
      await Updating(
        `PassportPictureHistory/UpdateHrEmployeePassportPicture`,
        {
          pphIdpk: existingPictureID,
          pphPictureStr: imageUrl,
          pphActive: activeRef.current,
          pphDate: date,
          pphRmks: "",
        },
        `Updated passport picture for employee ${selectedEmployeeID} on ${date}, Created by ${userId}`
      );

      updateState("successModal", {
        state: true,
        title: "Success",
        message: "Image updated successfully",
      });

      // add or update in main passport pictures
      // savePassportMain();
    } catch (error) {
      console.log(error);
    }
  };

  // modal instances
  const modals: modalTypes[] = [
    //validation
    {
      disableCancel: true,
      icon: "warning",
      open: validationModal.state,
      okHandler: () =>
        updateState("validationModal", {
          state: false,
          title: "",
          message: "",
        }),
      cancelHandler: () =>
        updateState("validationModal", {
          state: false,
          title: "string",
          message: "string",
        }),
      message: validationModal.message,
      okText: "Ok",
      title: validationModal.title,
      cancelText: "No, cancel",
    },

    //confirmation
    {
      disableCancel: false,
      icon: "question",
      open: confirmModal.state,
      // okHandler: confirmModal.action === "update" ? updateData : postData,
      okHandler: updateMode ? updateData : postData,
      cancelHandler: () =>
        updateState("confirmModal", {
          state: false,
          title: "string",
          message: "string",
        }),
      message: `Are you sure you want to ${
        update ? "update" : "save"
      } this image?`,
      okText: updateMode ? "Yes, Update" : "Yes, Save",
      title: `Confirm ${updateMode ? "Update" : "Save"}`,
      cancelText: "No, cancel",
    },
    {
      disableCancel: true,
      icon: "success",
      open: successModal.state,
      okHandler: () => {
        updateState("successModal", {
          state: false,
          title: "",
          message: "",
        });
      },
      cancelHandler: () => {
        updateState("successModal", {
          state: false,
          title: "",
          message: "",
        });
      },
      message: successModal.message,
      okText: "Ok",
      title: successModal.title,
    },
  ];

  return (
    <>
      {modals.map(
        (
          {
            disableCancel,
            icon,
            okHandler,
            cancelHandler,
            open,
            cancelText,
            okText,
            message,
            title,
          }: modalTypes,
          index
        ) => {
          return (
            <div key={index}>
              <ModalTemplate
                icon={icon && icon}
                disableCancel={disableCancel}
                cancelText={cancelText && cancelText}
                open={open}
                okHandler={okHandler}
                cancelHandler={cancelHandler}
                message={message}
                okText={okText}
                title={title}
              />
            </div>
          );
        }
      )}

      <div className="h-full dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base mt-1 ">
        {/* toggles between the taskForm and the footer grid table vertically */}
        <div>
          <ul
            style={{ width: "calc(100% - 350px)" }}
            className="flex justify-end items-center px-2 "
          >
            <li className=" flex  mx-1">
              <label className="text-xs flex items-center px-1">
                Criteria :{" "}
              </label>
              <Select size="small" className="w-40" />
            </li>

            <li className=" flex border-2 px-1 mx-1">
              <CheckboxTemlate
                label={"Active Staff?"}
                withBorders
                customDisabled={false}
                setCheckboxOnchange={(checkValue) => {
                  activeRef.current = checkValue; 
                }}
              />
            </li>
            <SearchButton
              label="Search"
              text="Search"
              isText
              handleOnClick={() => updateState("refresh", !refresh)}
            />
          </ul>
        </div>
        <div className=" w-full h-full">
          <div className="px-2 py-2 w-full h-full flex justify-between gap-x-4  mb-1 pb-1">
            {/* Internal toggler : expands the right taskUpdate horizontally */}

            <div style={{ width: "calc(100% - 350px)", height: "100%" }}>
              <Datagrid_template
                onRowClick={(selectedRow) => {
                  // update selected employee state
                  updateState("imageUrl", "");
                  updateState("selectedEmployeeID", selectedRow.empIDpk);
                  console.log(selectedRow.empIDpk);

                  dispatch(
                    setGeneralValue({
                      expr: "pphEmpIdfk",
                      value: selectedRow.empIDpk,
                    })
                  );
                }}
                gridheight={initialH - 200}
                columns={passport_footer_cols}
                data={data}
              />
            </div>

            {/* Double tap(click) feature to toggle to and fro of the right taskUpdate section */}
            <div
              style={{ width: "calc(350px)", height: "100%" }}
              className="h-full w-full  rounded-md items-center dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base "
            >
              <div>
                <div className="w-full h-3/6">
                  <ul className="flex justify-between rounded w-full px-2">
                    <li
                      style={{ width: "46%" }}
                      className="flex justify-center items-center overflow-clip border-2"
                    >
                      {states.isCam ? (
                        states.camImage === "" ? (
                          <div className="bg-black h-36 overflow-clip">
                            <WebCam_template webcamRef={webCamRef} />
                          </div>
                        ) : (
                          <img
                            className="bg-black"
                            src={`${states.camImage}`}
                          />
                        )
                      ) : !imageUrl ? (
                        <div className="h-32 w-full bg-gray-50 rounded flex flex-col justify-center items-center">
                          <li className="flex items-center justify-center">
                            <img src={webCam} alt="passport cam" />
                          </li>
                          <p className="flex items-center justify-center">
                            No image data
                          </p>
                        </div>
                      ) : imageUrl && imageUrl.slice(0, 10) === "data:image" ? (
                        <img className=" w-full h-36" src={`${imageUrl}`} />
                      ) : (
                        <img
                          className="w-full h-36"
                          src={`data:image/jpeg;base64,${imageUrl}`}
                        />
                      )}
                    </li>

                    <li
                      style={{ width: "46%" }}
                      className="flex justify-center items-center rounded-full border-2 overflow-clip border-spacing-2"
                    >
                      {states.isCam ? (
                        states.camImage === "" ? (
                          <div className="bg-black rounded-full h-36 overflow-clip">
                            <WebCam_template webcamRef={webCamRef} />
                          </div>
                        ) : (
                          <img
                            className="bg-black rounded-full"
                            src={`${states.camImage}`}
                          />
                        )
                      ) : !imageUrl ? (
                        <div className="h-32 w-full bg-gray-50 rounded-full border-2 flex flex-col  justify-center items-center">
                          <li className="flex items-center justify-center">
                            <img src={webCam} alt="passport cam" />
                          </li>
                          <p className="flex items-center justify-center">
                            No image data
                          </p>
                        </div>
                      ) : imageUrl && imageUrl.slice(0, 10) === "data:image" ? (
                        <img className="w-full h-36" src={`${imageUrl}`} />
                      ) : (
                        <img
                          className="w-full h-36"
                          src={`data:image/jpeg;base64,${imageUrl}`}
                        />
                      )}
                    </li>
                  </ul>

                  <div className="w-full">
                    <Form
                      className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 px-2 "
                      name="basic"
                      labelCol={{ span: 1 }}
                      // wrapperCol={{ span: 24 }}
                      initialValues={{ remember: true }}
                      autoComplete="off"
                      size="small"
                      layout="horizontal"
                    >
                      {/* option button */}
                      <Form.Item
                        wrapperCol={{ span: 20 }}
                        className="mb-2 w-full dark:bg-slate-900 dark:text-darkModeSkin-base"
                      >
                        <div className="flex w-full flex-row items-center dark:bg-slate-900 dark:text-darkModeSkin-base mb-1">
                          <div>
                            <DateTemplate
                              width={120}
                              selectedDate={dayjs(date)}
                              changeDate={(date) => {
                                updateState("date", dayjs(date));
                              }}
                              showTime={false}
                              span
                              disabled={false}
                            />

                            <li className="flex space-x-1">
                              <CheckboxTemlate
                                withBorders
                                setCheckboxOnchange={(checkValue) => {
                                  activeRef.current = checkValue; 
                                }}
                                customDisabled={false}
                              />
                              <p>active?</p>
                            </li>
                          </div>

                          <li className="flex justify-start space-x-1">
                            <Upload
                              listType="picture"
                              showUploadList={false}
                              onChange={(e: any) => {
                                imageUpload(e);
                              }}
                            >
                              <CustomizableButton
                                isText
                                text="Browse"
                                customizableBtnImage={data_sharing}
                                classname={"p-1 ml-1"}
                              />
                            </Upload>
                            {states.isCam ? (
                              <CustomizableButton
                                isText
                                text="Capture"
                                customizableBtnImage={data_sharing}
                                classname={"p-1"}
                                handleCustomizableClick={() => {
                                  capture();
                                  //   updateState("isCam", false);
                                }}
                              />
                            ) : (
                              <CustomizableButton
                                isText
                                text="useCam"
                                customizableBtnImage={data_sharing}
                                classname={"p-1"}
                                handleCustomizableClick={() =>
                                  updateState("isCam", true)
                                }
                              />
                            )}
                          </li>

                          <li
                            onClick={handleSave}
                            className="flex justify-end  "
                          >
                            <CustomizableButton
                              handleCustomizableClick={
                                updateMode ? handleUpdateImage : handleSave
                              }
                              isText
                              text={updateMode ? "Update" : "Save"}
                              customizableBtnImage={save}
                              classname={"px-5 pt-1 ml-1"}
                            />
                          </li>
                        </div>
                      </Form.Item>
                    </Form>
                  </div>
                </div>

                {/*  Mini grid*/}
                <div className="h-3/6">
                  <Datagrid_template
                    onRowClick={(selected) => {
                      updateState("selectedPictureID", selected?.pphIdpk);
                      updateState("updateMode", false);
                    }}
                    rowDoubleClicked={(selected) => {
                      updateState("existingPictureID", selected?.pphIdpk);
                      updateState("updateMode", true);
                    }}
                    searchWidth={150}
                    disablePaging
                    disableSearch
                    disableGroupPanel
                    data={employeePic}
                    columns={passportMini}
                    gridheight={initialH - 410}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
