

import { DatePicker, Input, Select } from "antd";
import Form from "antd/es/form";
import TextArea from "antd/es/input/TextArea";
import dayjs from 'dayjs';
import { useDispatch } from "react-redux";
import { setciDetailsValue } from "../../../../features/forms/entries/safetyManager/ppeRequest";

import { inputsTypes } from "../../../human-resource/setups/data/types/selectsTypes";


interface SelectProps{
    inputs ?: inputsTypes[]
    textheight ?:string
    labelCol ?:number
    wrapperCol ?:number,
    onChange ?: any,
    disabled ?: boolean,
    value ?:any,
    
}

// FOR PERFORMANCE
    // INPUT FEILDS


export const Performance_input_template = ({inputs,labelCol,wrapperCol}:SelectProps) => {

    // const setID = (id:number)=>{
    //     onchange(id);
    // }
    

    return (
        <>
             {inputs?.map((input:inputsTypes)=>{
                        return(
                            <Form.Item
                            key={input.id}
                            className='mb-0'
                            label={<p className='dark:bg-fill dark:text-darkModeSkin-base text-xs'>{input.label}</p>}
                            name={input.label}
                            rules={[{ required: false,}]}
                            labelCol={{span:labelCol}}
                            wrapperCol={{span:wrapperCol}}
                            >
                                <div className="flex flex-row justify-between">
                                    <Input className=' W-11/12'/>

                                    <span className='h-full w-4 ml-1 flex items-center justify-center hover:cursor-pointer hover'>
                                        {/* <img className='w w-9/12 h-9/12' src= {reset} alt="reset" /> */}
                                    </span>
                
                                </div>
                            </Form.Item>
                        )
                    })}
        </>
    )
}

// Template for Text Area Feild
// FOR Performace

export const Performance_textarea_template = ({inputs, textheight,labelCol}:SelectProps)=>{
    const dispatch = useDispatch()
    return (
        <>
             {inputs?.map((input:inputsTypes)=>{
                        return(
                            <Form.Item
                            key={input.id}
                            className='mb-0'
                            labelCol={{span:labelCol}}
                            
                            label={<p className='dark:bg-fill dark:text-darkModeSkin-base text-xs'>{input.label}</p>}
                            name={input.label}
                            rules={[{ required: false,}]}
                            >
                                <div className="flex flex-row ">
                                    <TextArea onChange={(e: React.ChangeEvent<HTMLTextAreaElement>)=>{dispatch(setciDetailsValue({expr:input.label, value: e.target.value}))}} style={{resize:'none',height:textheight}} className='w-full '/>

                                    <span className='h-full w-4 ml-1 flex items-center justify-center hover:cursor-pointer hover'>
                                        {/* <img className='w w-9/12 h-9/12' src= {reset} alt="reset" /> */}
                                    </span>
                
                                </div>
                            </Form.Item>
                        )
                    })}
        </>
    )

}


// COOPERATIVE INITIATIVES

export const CI_textarea_template = ({inputs, textheight}:SelectProps)=>{
    return (
        <>
             {inputs?.map((input:inputsTypes)=>{
                        return(
                            <Form.Item
                            key={input.id}
                            className='mb-0'
                            // labelCol={{span:7}}

                            label={<p className='dark:bg-fill dark:text-darkModeSkin-base text-xs'>{input.label}</p>}
                            name={input.label}
                            rules={[{ required: false,}]}
                            >
                                <div className="flex flex-row ">
                                    <TextArea style={{resize:'none'}} className='w-full '/>

                                    <span className='h-full w-4 ml-1 flex items-center justify-center hover:cursor-pointer hover'>
                                        {/* <img className='w w-9/12 h-9/12' src= {reset} alt="reset" /> */}
                                    </span>
                
                                </div>
                            </Form.Item>
                        )
                    })}
        </>
    )

}


// FOR STRATEGIC OBJECTIVE Range DATES

export const Performance_date_template = ({inputs,labelCol,wrapperCol,  onChange, disabled, value}:SelectProps)=>{
    const dateFormat = " DD MMM YYYY ";
    const {RangePicker} = DatePicker;
    return (
        <>
             {inputs?.map((input:inputsTypes)=>{
                        return(
                            <Form.Item
                            key={input.id}
                            className='mb-0'
                            label={<p className='dark:bg-fill dark:text-darkModeSkin-base text-xs'>{input.label}</p>}
                            name={input.label}
                            rules={[{ required: false,}]}
                            labelCol={{span:labelCol}}
                            wrapperCol={{span:wrapperCol}}
                            >
                                <div className="flex flex-row justify-between">
                                    <RangePicker onChange={onChange} disabled={disabled} value={value}   className='' format={dateFormat} />
                                    

                                    <span className='h-full w-4 ml-1 flex items-center justify-center hover:cursor-pointer hover'>
                                        {/* <img className='w w-9/12 h-9/12' src= {reset} alt="reset" /> */}
                                    </span>
                
                                </div>
                            </Form.Item>
                        )
                    })}
        </>
    )

}