/* eslint-disable react/jsx-pascal-case */
import React from "react";
import { DateTemplate } from "../../../../../../templates/date";
import { SelectsTemplate } from "../../../../../../templates/select";
import { Form } from "antd";
import { SaveButton } from "../../../../../accessories/buttons";
import { InputsTemplate } from "../../../../../../templates/input";
import { CheckboxTemlate } from "../../../../../../templates/checkbox";
import { Switcher } from "../../_widget/switcher";
import Datagrid_template from "../../../../../../templates/Datagrid";
import MiniToolbar from "../../_widget/minitollbar";
import { energyReadingCol, energyReadingData } from "./data/energyReadingData";
import { useGraphSwitcher } from "../../db/useSwitcher";
import { ReadingsChart } from "../../_widget/readingschart";
import { TextareaTemplate } from "../../../../../../templates/textarea";
import InputSelect_template from "../../../../../../templates/InputSelect";

export const EnergyMeterReading = () => {

  const { status } = useGraphSwitcher();

  const leftSide = [
    { id: 0, label: "Date" },
    { id: 1, label: "Station" },
    { id: 2, label: "Meter" },
    { id: 3, label: "Current Active Energy (kWh)" },
    { id: 4, label: "Previous Active Energy (kWh)" },
    { id: 5, label: "Acive Energy Loss of Reg./Adjust" },
  ];
  const rightSide = [
    { id: 0, label: "Current Reactive Energy (kVArh)" },
    { id: 1, label: "Previous Reactive Energy (kVArh)" },
    { id: 2, label: "Reactive Energy Loss of Reg./Adjust" },
    { id: 3, label: "Maximum Demand (kW)" },
  ];

  return (
    <div className="w-full h-full p-2">
      <Switcher alternate={""}>
        <div className="flex gap-1">
          <aside className="  w-[40%]">
            {/* leftSide */}
            <Form
              className=" h-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded  w-[600px]  "
              name="basic"
              labelCol={{ span: 9 }}
              wrapperCol={{ span: 10 }}
              initialValues={{ remember: true }}
              autoComplete="off"
              size="small"
              layout="horizontal"
            >
              {leftSide.map(({ id, label }) =>
                id === 0 ? (
                  <DateTemplate key={id} label={label} width={225} />
                ) : id === 1 || id === 2 ? (
                  <InputSelect_template label={label} selectStyle={{ width: "60%" }} />
                ) : (
                  <InputsTemplate
                    label={label}
                    span
                    inputStyle={{ width: 225 }}
                  />
                )
              )}
              <Form
                className="w-full h-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded "
                name="basic"
                labelCol={{ span: 15 }}
                initialValues={{ remember: true }}
                autoComplete="off"
                size="small"
                layout="inline"
              >
                <div className="w-[62.5%]">
                  <CheckboxTemlate label={"End of Period Reading?"} withBorders={true} />
                </div>
                <div className=" ">
                  <SaveButton disableButton={false} />
                </div>
              </Form>
            </Form>
          </aside>
          {/* rightSide */}
          <aside className=" w-[60%]  ">
            <Form
              className="flex-1 h-full dark:bg-slate-900 dark:text-darkModeSkin-base rounded "
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              autoComplete="off"
              size="small"
              layout="horizontal"
            >
              <div className="">
                {rightSide.map(({ id, label }) => (
                  <InputsTemplate
                    key={id}
                    label={label}
                    span
                    inputStyle={{ width: 225 }}
                  />
                ))}
              </div>
              <Form.Item className="pt-1 w-full " label={<p className="text-[12px]">Remarks</p>}>
            <div className="flex-1 w-[]">
              <TextareaTemplate height={125}  />
            </div>
          </Form.Item>
            </Form>
          </aside>
        </div>
      </Switcher>
      <section className='flex flex-col'>
        <MiniToolbar />
        <section className='flex-grow'> 
          <section className='w-full h-full'>
            {
              status ? <ReadingsChart /> :
                <Datagrid_template
                  gridheight={515}
                  showColumnHeaders={true}
                  columns={energyReadingCol}
                  data={energyReadingData}
                  disablePaging
                  disableGroupPanel
                />
            }
          </section>
        </section>
      </section>
    </div>
  );
};
