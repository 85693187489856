export const validation = {
  init: [],

  input: (states: any) => {
    const data = validation.init;
    let res = {
      required: {
        title: "",
        message: "",
      },
    };
    data.every((item: any) => {
      const { stateName, label, idExpr }: any = item;

      if (idExpr === "" || idExpr === undefined) {
        if (states[stateName] === undefined || states[stateName] === "") {
          res = {
            required: {
              title: label,
              message: "Input Field can not be Empty",
            },
          };
          return false;
        }
      } else {
        if (
          states[stateName]["value"] === undefined ||
          states[stateName]["value"] === ""
          // states[stateName]["id"] === undefined ||
          // states[stateName]["id"] === ""
        ) {
          res = {
            required: {
              title: label,
              message: `Select ${label}`,
            },
          };
          return false;
        }
      }
      return true;
    });
    return res;
  },
};

export function populateFieldsX(
  data: any,
  // setStates: React.Dispatch<React.SetStateAction<any>>
  updateState: (stateName: string, value: any) => void
) {
  const gridState = [
    {
      key: "empStaffNo",
      value: { id: data?.empIdpk ?? "", value: data?.empStaffNo ?? ""},
    },
    {
      key: "empLastName",
      value: data?.empLastname ?? "",
    },
    {
      key: "empFirstName",
      value: data?.empFirstName ?? "",
    },
    {
      key: "empOtherNames",
      value: data?.empOthernames ?? "",
    },
    {
      key: "empInitials",
      value: data?.empInitials ?? "",
    },
    {
      key: "empNickname",
      value: data?.empNickname ?? "",
    },
    {
      key: "empDoB",
      value: data?.empDoB ?? "",
    },
    {
      key: "empHireDate",
      value: data?.empHireDate ?? "",
    },
    {
      key: "empLastPromoDate",
      value: data?.empLastPromoDate ?? "",
    },
    {
      key: "empTelNo",
      value: data?.empTelNo ?? "",
    },
    {
      key: "empMobNo",
      value: data?.empMobNo ?? "",
    },
    {
      key: "empIntercom",
      value: data?.empIntercom ?? "",
    },
    {
      key: "empOfficialEmail",
      value: data?.empOfficialEmail ?? "",
    },
    {
      key: "empPersonalEmail",
      value: data?.empPersonalEmail ?? "",
    },
    {
      key: "empAddress",
      value: data?.empAddress,
    },
    {
      key: "empSocSecNo",
      value: data?.empSocSecNo ?? "",
    },
    {
      key: "empNationalId",
      value: data?.empNationalID ?? "",
    },
    {
      key: "empTitleIdfk",
      value: { id: data?.titIDpk ?? "", value: data?.titName ?? "" },
    },
    {
      key: "empBranchIdfk",
      value: { id: data?.obrIdpk ?? "", value: data?.obrName ?? "" },
    },
    {
      key: "empDeptIdfk",
      value: { id: data?.dptIDpk ?? "", value: data?.dptName ?? ""},
    },
    {
      key: "empSectionIdfk",
      value: { id: data?.sxnIDpk?? "", value: data?.sxnName ?? ""},
    },
    {
      key: "empUnitIdfk",
      value: { id: data?.untIDpk ?? "", value: data?.untName ?? "" },
    },
    {
      key: "empAreaIdfk",
      value: { id: data?.areName ?? "", value: data?.areShtName ?? "" },
    },
    {
      key: "empLocationIdfk",
      value: { id: data?.locIDpk ?? "", value: data?.locName ?? "" },
    },
    {
      key: "empGradeIdfk",
      value: { id: data?.grdIDpk ?? "", value: data?.grdName ?? ""},
    },
    {
      key: "empStepIdfk",
      value: { id: data?.stpIDpk ?? "", value: data?.stpName ?? ""},
    },
    {
      key: "empJobTitleIdfk",
      value: { id: data?.jbtIDpk ?? "", value: data?.jbtName ?? ""},
    },
    {
      key: "empReligionIdfk",
      value: { id: data?.rgnIDpk ?? "", value: data?.rgnName ?? ""}, // replaced with regions
    }, //checked
    {
      key: "empCostCenterIdfk",
      value: { id: data?.cctIdpk ?? "", value: data?.cctName ?? "" },
    },
    {
      key: "empAppraisalGroupIdfk",
      value: { id: data?.apgIDpk ?? "", value: data?.apgName ?? "" },
    },
    {
      key: "empGenderIdfk",
      value: { id: data?.gndIDpk ?? "", value: data?.gndName ?? ""},
    },
    {
      key: "empAssignmentStatusIdfk",
      value: { id: data?.astIDpk ?? "", value: data?.astName ?? "" },
    },
    {
      key: "empEmployeeGroupIdfk",
      value: { id: data?.egpIDpk ?? "", value: data?.egpName ?? "" },
    },
    {
      key: "empOfficeLocationIdfk",
      value: { id: data?.locIDpk ?? "", value: data?.locName ?? ""},
    },
    {
      key: "empRoomIdfk",
      value: { id: data?.romIDpk ?? "", value: data?.romName ?? ""},
    },
    {
      key: "empSupervisorEmpIdfk",
      value: { id: data?.empSupEmpID ?? "", value: data?.empSupName ?? "" , shtIdFk: data?.sprShtTitle ?? ""}, //check on the supervisors late on
    }, 
    {
      key: "empManagerEmpIdfk",
      value: { id: data?.empMgrEmpID ?? "", value: data?.empMgrName ?? "", shtIdFk: data?.mngShtTitle ?? ""}, //check on this one too later on
    }, 
    {
      key: "empDirectorEmpIdfk",
      value: { id: data?.empDirEmpID ?? "", value: data?.empDirName ?? "", shtIdFk: data?.drtShtTitle ?? ""}, //check on this one too later on
    },
    {
      key: "empMaritalStatusIdfk",
      value: { id: data?.mtsIDpk ?? "", value: data?.mtsName ?? ""},
    },
    {
      key: "empNationalityIdfk",
      value: { id: data?.ctyIDpk ?? "", value: data?.ctyNationality ?? ""},
    },
    {
      key: "empHomeTownIdfk",
      value: { id: data?.twnIDpk ?? "", value: data?.twnName ?? ""},
    },
    {
      key: "empAssignmentCategoryIdfk",
      value: { id: data?.ascIDpk ?? "", value: data?.ascName ?? ""},
    },
    {
      key: "empPersonTypeIdfk",
      value: { id: data?.pstIDpk ?? "", value: data?.pstName ?? ""},
    }, //CHECKed
    {
      key: "empFacebookAccount",
      value: data?.empFacebookAccount ?? "",
    },
    {
      key: "empLinkedIn",
      value: data?.empLinkedIn ?? "",
    },
    {
      key: "empSkype",
      value: data?.empSkype ?? "",
    },
    {
      key: "empTwitterHanlde",
      value: data?.empTwitterHanlde ?? "",
    },
    {
      key: "empVisible",
      value: data?.empVisible ?? false,
    },
    {
      key: "empActive",
      value: data?.empActive ?? false,
    },
    {
      key: "empRmks",
      value: data?.empRmks ,
    },
    {
      key: "empDistrictIdpk",
      value: { id: data?.dstIDpk ?? "", value: data?.dstName ?? ""},
    },
    {
      key: "empIdpk",
      value: data?.empIDpk ?? 0,
    },
  ];

  for (let instance of gridState) updateState(instance.key, instance.value);
}
