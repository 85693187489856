import { Form } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";
import {
  SaveButton,
  NewButton,
  Cancel,
  UpdateButton,
} from "../components/accessories/buttons";
import { CheckboxTemlate } from "./checkbox";
import { setValue } from "../features/forms/tier1/createSlice";
import { useDispatch } from "react-redux";
import { setGeneralValue } from "../features/generalSlice";

interface props {
  label?: string;
  span?: boolean;
  additionalBottomChckbox?: string;
  width?: number | string;
  useCallbackFunc?: boolean;
  defaultValue?: boolean;
  additionalCheckBoxValue?: any;
  outerDisabled?: boolean | undefined;
  outerUpdateMode?: boolean | undefined;
  style?: any;
  checkboxChange?: (value: boolean, index: any) => void;
  additionalCheckboxChange?: (value: boolean, index: any) => void;
  saveHandler?: () => void;
  updateHandler?: () => void;
  //for only additional buttons with one custom and one active
  firstCheckboxChange?: (value: boolean) => void;
  secondCheckboxChange?: (value: boolean) => void;
  onNewClick?: () => void;
  onCancelClick?: () => void;
  addActiveBorders?: boolean;
  midcheckboxPaddingLeft?: number;
  buttonText?: string;
  withoutCheckbox?: boolean;
  newButtonTitle?: string;
}

export const BottomBtns = ({
  label,
  span,
  style,
  additionalBottomChckbox = undefined,
  useCallbackFunc,
  defaultValue,
  additionalCheckBoxValue = [], //array of first checkbox value in index 0 and next in index 1
  outerDisabled = undefined,
  outerUpdateMode = undefined,
  midcheckboxPaddingLeft = 0,
  checkboxChange,
  firstCheckboxChange,
  secondCheckboxChange,
  saveHandler,
  updateHandler,
  onNewClick,
  onCancelClick,
  addActiveBorders = false,
  buttonText,
  withoutCheckbox,
  newButtonTitle,
}: props) => {
  const active = useSelector((state: RootState) => state.tier1Add.active);

  const disabled: boolean = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const updateMode: boolean = useSelector(
    (state: RootState) => state.general.updateMode
  );
  const checkboxExpr: string = useSelector(
    (state: RootState) => state.general.inputExprs.activeExpr
  );
  const additionalCheckboxExpr: string = useSelector(
    (state: RootState) => state.general.inputExprs.additionalCheckboxExpr
  );
  const dispatch = useDispatch();

  const updateTierActiveRedux = (check: boolean) => {
    dispatch(setValue({ expr: "active", value: check }));
  };


  const inOnNewClick = ()=>{
    dispatch(setGeneralValue({expr:"formDisabled", value:false}))
    onNewClick && onNewClick();
  }

  return (
    <>
      {!additionalBottomChckbox && (
        <Form.Item
          className=" mt-0  w-full"
          label={
            <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
              {withoutCheckbox ? "" : label ? label : "Active?"}
            </p>
          }
          colon={withoutCheckbox ? false : true}
          style={style}
        >
          {withoutCheckbox ? (
            <></>
          ) : (
            <CheckboxTemlate
            
              withBorders={addActiveBorders}
              customDisabled={outerDisabled}
              defaultValue={defaultValue} //added a ternary op using the callbackFun for entry forms 
              useCallbackFunc={useCallbackFunc}
              setCheckboxOnchange={(check: boolean, index: any) => {
                  checkboxChange && checkboxChange(check, index)
                 useCallbackFunc === false && updateTierActiveRedux(check)
              }}
              nameExpr={checkboxExpr}
              expr={!label ? "active" : ""}
              style={{
                display: "inline-block",
                width: "calc(38%)",
                marginBottom: 0,
         
              }}
            />
          )}

          <Form.Item
            className=" w-full"
            name="Staff No"
            rules={[{ required: false }]}
            style={
              !span
                ? {
                  display: "inline-block",
                  width: "calc(60% - 27.5px)",
                  marginLeft: "2%",
                  marginBottom: 0,
                }
                : {
                  display: "inline-block",
                  width: `${withoutCheckbox ? "calc(100% )" : "calc(60% )"}`,
                  marginLeft: `${withoutCheckbox ? "0" : "2%"}`,
                  marginBottom: 0,
                }
            }
          >
            <div className="flex justify-end gap-1">
              <div className="">
                {(
                  outerUpdateMode !== undefined ? !outerUpdateMode : !updateMode
                ) ? (
                  <SaveButton title={buttonText} handleSave={saveHandler} />
                ) : (
                  <UpdateButton handleUpdate={updateHandler} />
                )}
              </div>

              {(outerDisabled !== undefined ? outerDisabled : disabled) ? (
                <NewButton
                  title={newButtonTitle}
                  useCallbackFunc={outerDisabled !== undefined ? true : false}
                  // new_button_toggler={() => {
                  //   onNewClick && onNewClick()
                  // }}
                  new_button_toggler={() => {
                    onNewClick
                      && onNewClick()
                    dispatch(setGeneralValue({expr:"formDisabled", value:false}))

                  }}
                />
              ) : (
                <Cancel cancel_button_toggler={onCancelClick} />
              )}
            </div>
          </Form.Item>
        </Form.Item>
      )}

      {additionalBottomChckbox && (
        <Form.Item
          className=""
          label={
            <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
              {additionalBottomChckbox}
            </p>
          }
          style={{ marginBottom: 0 }}
        >
          <Form.Item
            className=""
            name="Name"
            rules={[{ required: false }]}
            style={
              !span
                ? {
                  display: "inline-block",
                  width: "calc(16%)",
                  marginBottom: 0,
                }
                : {
                  display: "inline-block",
                  width: "calc(16%)",
                  marginBottom: 0,
                }
            }
          >
            <div className=" w-full ">
              <CheckboxTemlate
                expr="additionalCheckbox"
                customDisabled={outerDisabled}
                withBorders={addActiveBorders}
                defaultValue={additionalCheckBoxValue[0]}
                useCallbackFunc={useCallbackFunc}
                setCheckboxOnchange={(check: boolean, index: any) =>
                  firstCheckboxChange && firstCheckboxChange(check)
                }
                nameExpr={additionalCheckboxExpr}
              />
            </div>
          </Form.Item>

          <div
            style={{
              display: "inline-block",
              width: !buttonText
                ? "calc(83.9% - 174px)"
                : "calc(83.9% - 220px)",
              marginBottom: 0,
              paddingLeft: midcheckboxPaddingLeft,
            }}
            className={`w-full flex justify-end`}
          >
            <Form.Item
              className=" "
              label={
                <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                  {label ? label : "Active?"}
                </p>
              }
              name="Name"
              rules={[{ required: false }]}
            >
              <div className=" ">
                <CheckboxTemlate
                  expr="active"
                  customDisabled={outerDisabled}
                  defaultValue={additionalCheckBoxValue[1]}
                  withBorders={addActiveBorders}
                  useCallbackFunc={useCallbackFunc}
                  setCheckboxOnchange={(check: boolean, index: any) =>
                    secondCheckboxChange && secondCheckboxChange(check)
                  }
                  nameExpr={checkboxExpr}
                />
              </div>
            </Form.Item>
          </div>

          <Form.Item
            className=" "
            name="Staff No"
            rules={[{ required: false }]}
            style={
              span
                ? {
                  display: "inline-block",
                  width: buttonText ? 220 : 174,
                  marginLeft: "",
                  marginBottom: 0,
                }
                : {
                  display: "inline-block",
                  width: `calc(174px- 27.5px)`,
                  marginLeft: "1.8%",
                  marginBottom: 0,
                }
            }
          >
            <div className="flex justify-end">
              <div className=" ">
                {(
                  outerUpdateMode !== undefined ? !outerUpdateMode : !updateMode
                ) ? (
                  <SaveButton title={buttonText} handleSave={saveHandler} />
                ) : (
                  <UpdateButton handleUpdate={updateHandler} />
                )}
              </div>
              {(outerDisabled !== undefined ? outerDisabled : disabled) ? (
                <NewButton
                  title={newButtonTitle}
                  useCallbackFunc={outerDisabled !== undefined ? true : false}
                  new_button_toggler={inOnNewClick}
                />
              ) : (
                <Cancel cancel_button_toggler={onCancelClick} />
              )}
            </div>
          </Form.Item>
        </Form.Item>
      )}
    </>
  );
};
