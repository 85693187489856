import React from "react";
import { Card, Form, Modal } from "antd";
import { Cancel, SendEmailButton } from "../../../buttons";
import { TextareaTemplate } from "../../../../../templates/textarea";
import { DateTemplate } from "../../../../../templates/date";
import { SelectsTemplate } from "../../../../../templates/select";
import { CheckboxTemlate } from "../../../../../templates/checkbox";
import dayjs from "dayjs";

type props = {
  open: boolean;
  width?: any;
  sendMail?: () => void;
  cancelHandler?: () => void;
  okText: string;
  cancelText?: string;
  cancelButton?: boolean;
  title: string;
  placeHolder?: any;
  selectedValue?: (object: any) => void;
  label?: string;
  datelabel?: string;
  droplabel?: string;
  meslabel?: string;
  options?: any;
  idexpr?: string;
  dataexp?: string;
  useCallFunc?: boolean;
  disabled?: boolean | undefined;
  setCallbackValue?: (value: string) => void;
  textheight?: number | string;
  message?: string;
  workData?: any;
  isMultipleSelects?: boolean;
  multipleSelects?: any[];
  isCheckboxes?: boolean;
  ismultiCheckboxes?: boolean;
  checkboxes?: any[];
  key?: number;
  Modalstyles?: any; // modal styles
  dateStyle?: any; //styles for date
  handleDate: (date: any) => void; // handle date
  handleRefresh?: () => void; // handle selects refresh
  setCheckboxOnchange?: (e: any) => void; // handle selects refresh
  updateStatesx?: (e: string, val: any) => void; // handle selects refresh
  selectedDate?: any;
  disabledDate?: boolean;
  clabel1?: string;
  clabel2?: string;
  Clabel?: string;
};

export const SMSAlerts2 = ({
  title,
  disabled = undefined,
  textheight,
  open,
  cancelHandler,
  sendMail,
  width,
  workData,
  placeHolder,
  selectedValue,
  label,
  datelabel,
  meslabel,
  droplabel,
  message,
  options,
  idexpr,
  dataexp,
  useCallFunc,
  setCallbackValue,
  isMultipleSelects,
  multipleSelects,
  isCheckboxes,
  checkboxes,
  ismultiCheckboxes = false,
  key = 1,
  Modalstyles,
  dateStyle,
  handleDate,
  selectedDate,
  disabledDate,
  handleRefresh,
  updateStatesx,
  setCheckboxOnchange,
  clabel1,
  clabel2,
  Clabel,
}: props) => {
  return (
    <div className="w-full h-full">
      <Modal
        key={key}
        width={width ? width : 600}
        style={{ ...Modalstyles }}
        open={open}
        onCancel={cancelHandler}
        centered
        footer={[
          <div className="flex w-full h-full justify-end items-center">
            <SendEmailButton disableButton={false} handleMail={sendMail} />

            {<Cancel handleCallbackCancel={cancelHandler} />}
          </div>,
        ]}
      >
        <Card size="small" className="w-full h-full  my-5" title={title}>
          <Form
            className=" w-full dark:bg-slate-900 dark:text-darkModeSkin-base rounded"
            name="basic"
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            size="small"
            layout="horizontal"
            disabled={false}
          >
            <section className="w-full">
              <div className="w-full">
                <DateTemplate
                  labelCol={5}
                  label={datelabel ?? "Report Date"}
                  changeDate={handleDate}
                  style={{ ...dateStyle }}
                  width={"45%"}
                  selectedDate={selectedDate ? dayjs(selectedDate) : null}
                  disabled={false}
                />
              </div>
              <div className="w-full ">
                {isMultipleSelects ? (
                  multipleSelects?.map(
                    ({
                      id,
                      idExpr,
                      dataExpr,
                      label,
                      optionsData,
                      defaultValue,
                      stateName,
                      style,
                    }) => {
                      return (
                        <SelectsTemplate
                          label={label}
                          options={optionsData}
                          idexpr={idExpr}
                          dataexp={dataExpr}
                          useCallFunc={useCallFunc}
                          disabled={false}
                          placeHolder={defaultValue}
                          selectStyle={{ width: "300px" }}
                          selectedValue={(e) => {
                            updateStatesx &&
                              updateStatesx(stateName!, {
                                id: e[idExpr!],
                                name: e[dataExpr!],
                              });
                          }}
                          handleRefresh={handleRefresh}
                        />
                      );
                    }
                  )
                ) : (
                  <SelectsTemplate
                    label={droplabel ?? "Workshop"}
                    options={options}
                    idexpr={idexpr}
                    dataexp={dataexp}
                    useCallFunc={useCallFunc}
                    disabled={false}
                    placeHolder={placeHolder}
                    selectStyle={{ width: "300px" }}
                    selectedValue={selectedValue}
                    handleRefresh={handleRefresh}
                  />
                )}
              </div>

              <div className="w-full flex pb-0.5">
                {ismultiCheckboxes ? (
                  <>
                    {checkboxes?.map(
                      ({
                        id,
                        label1,
                        label2,
                        disabled,
                        withBorder,
                        stateName1,
                        stateName2,
                        defaultValue,
                      }) => {
                        return (
                          <>
                            <CheckboxTemlate
                              id={label1}
                              customDisabled={false}
                              useCallbackFunc
                              withBorders={true}
                              style={{ width: "100%" }}
                              label={label1}
                              label2={label2}
                              inlineDefaultValue={defaultValue}
                              setCheckboxOnchange={setCheckboxOnchange}
                              inlineRightLabelCol={15}
                              span
                            />
                          </>
                        );
                      }
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="w-full ">
                <TextareaTemplate
                  labelCol={5}
                  label={meslabel ?? "Alert Message"}
                  height={170}
                  readonly={false}
                  useCallbackFunc
                  setCallbackValue={setCallbackValue}
                  defaultValue={message}
                />
              </div>
            </section>
          </Form>
        </Card>
      </Modal>
    </div>
  );
};
