

import FilterTodos from '../../../../Task&Todos/FooterGrid/FilterTodos';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';

// import type { SelectProps } from 'antd';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';


import refresh from '../../../../../assets/refresh.png';
import edit from '../../../../../assets/edit.png';
import close from '../../../../../assets/close.png';
import {  PotentialMeasure_Form_Details } from './widgets/PMT_Form';
import { Mini_datagrid_template } from './data/template/mini_datagrid';
import { Footer_datagrid_template } from './data/template/footer_datagrid';
import { footer_datagrid_cols, perspective_footer_datagrid_cols } from './data/datagrid_cols';
import { useDispatch, useSelector } from 'react-redux';
import { setGeneralValue } from '../../../../../features/generalSlice';
import { RootState } from '../../../../../app/store';
import { navbar_height, toolbar_height } from '../../../../accessories/component_infos';

interface props {
    state_change: boolean;
    selectedGridRecord ?: ()=>void
    selectAllowUpdate ?: (value:any)=>void
}



export const PotentialMeasure = forwardRef(({state_change, selectAllowUpdate, selectedGridRecord}:props, ref)=>{
    const news_flash_height = useSelector((state:RootState)=>state.general.newsflashHeight);
    // const width = window.innerWidth/2
    // const fixedWidth = 'calc(50% - 8px)';
    const [selectedRecord, setSelectedRecord] = useState<any>()
    const update_state_change = useSelector((state:RootState)=>state.general.MissionUpdateForm)

    // on page resize height responsiveness
    const dispatch = useDispatch();
    const [trigger,setTrigger] = useState<boolean>(false);
    useImperativeHandle(ref, () => ({
        test: () => {
            setTrigger(!trigger);
            toggle_staff_form();

        }
    }));

    

    //navigation
    const setupPageRoute = window.location.pathname; //get current route of page
    const datagridOnlySearchParam = 'table-only'  //search param in datagrid mode
    const formModeSearchParam = 'form-and-table' //search param in form mode mode
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    //miscellaneous heights
    const miscHeight = 7;
    
    // All heights out
    const heights_out = navbar_height + news_flash_height + toolbar_height;

    //varying form height
    const [form_height,set_form_height] = useState<number>(320);

    // Page resize height responsiveness
    useEffect(()=>{
        settableHeight(window.innerHeight - (heights_out + miscHeight -4));
    },[])

    useEffect(()=>{
       toggle_staff_form();
    },[state_change])


    // handle interface switch
    const toggle_staff_form = ():void =>{
        const view = searchParams.get('view');

        (view === null || view === formModeSearchParam) ? 
        navigate({
            pathname : setupPageRoute,
            search : createSearchParams({
                view : datagridOnlySearchParam,
            }).toString(),
        })
        :
        navigate({
            pathname : setupPageRoute,
            search : createSearchParams({
                view : formModeSearchParam,
            }).toString(),
        });
    }

    //Table Height
    const pageHeight = window.innerHeight - (navbar_height + miscHeight) ; //height without navbar
    const [tableHeight,settableHeight] = useState(window.innerHeight); //height without navbar and toolbar


    // Row selection

    const selectRecord:any = useCallback<any>((e:any) => {
        e.component.byKey(e.currentSelectedRowKeys[0]).done((e:any) => {
            setSelectedRecord(e);
        });
    }, []);

        // double click feature
        // const allowUpdate = (e:any) =>{

        //     dispatch(setGeneralValue({ expr: "MissionUpdateForm", value: true }));
           
            
        
        // }
    


    return (
        <div  className='h-full dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base mt-1 '>
            {/* toggles between the taskForm and the footer grid table vertically */}
                    
               
                
                <div className=''>
                    
               { (searchParams.get('view') !== datagridOnlySearchParam) &&  <div  style={{height : form_height}}  className="px-2 flex border-b-4 mb-1 pb-1">
                        
                        {/* Internal toggler : expands the right taskUpdate horizontally */}
                        
                            <div className='w-full' >
                                <PotentialMeasure_Form_Details />
                            </div>
                            
                    
                            
        
                            
                        </div>
                        }
                </div>
        

            {/* Footer gridtable  */}
            <div style={{height:''}} className='px-2 dark:bg-slate-900 dark:text-darkModeSkin-base'>
            <Footer_datagrid_template 
                onSelectionChange={(e:any)=>{console.log(e)}}
                gridheight={ searchParams.get('view') === formModeSearchParam || searchParams.get('view') === (null) ? 
                window.innerHeight - (form_height + heights_out + miscHeight) - 50
                : 
                tableHeight-50} 
                columns={footer_datagrid_cols} 
                data={undefined} />
            </div>

        </div>

    )
})





// const reload = ()=>{
//     return(
//         <img className='w-2/6' src={save} />
//     )
// }