import { Form, Input } from "antd";
import { useState } from "react";
import { datalistTypes } from "../../../../../../human-resource/setups/data/types/selectsTypes";
import dropdown from '../../../../../../../assets/dropdown.png'
import reset from '../../../../../../../assets/reset.png'

interface props{
    datalist_selects ?:datalistTypes[]
    // options ?: any
    // label : string
    // listid ?: string
}
const Datalist_select_template = ({datalist_selects}:props) => {
    const [miniDropdown,setMiniDropdown] = useState<boolean>(true);
    return (
        <>
             {datalist_selects?.map((datalist_select:datalistTypes)=>{
            return(
                <>
                <Form.Item 
        key={datalist_select.id}
        label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{datalist_select.label}</p>}
        className= {'mb-0'}
        name={datalist_select.label}
        >
            <div className="flex flex-row items-center">
                <Input list={datalist_select.optionsData} onMouseLeave ={()=> setMiniDropdown(true)} onMouseEnter = {()=> setMiniDropdown(false)} id="ice-cream-choice" name="ice-cream-choice"/>
                <datalist id= {datalist_select.optionsData}>
                    {
                        datalist_select.optionsData?.map((option:any)=>{
                            return(
                                <>
                                    <option key={option.key} value={option.data}>{option.data}</option>
                                </>
                            )
                        })
                    }
                </datalist>

                <span className='h-full w-4 ml-1 flex justify-center hover:cursor-pointer hover'>
                    
                    {(miniDropdown) && <img className='w-6/12 h-19/12 flex items-center justify-center' src= {dropdown} alt="" />}
                    <img className='w-full h-full flex items-center justify-center' src= {reset} alt="reset" />
                </span>
            </div>
        </Form.Item>
                </>
            )
        })}
        </>
    )
 
}
 
export default Datalist_select_template;




