/* eslint-disable react/jsx-pascal-case */
import React, { useRef } from 'react'
import Datagrid_template from '../../../templates/Datagrid'
import { GetGroupDataInterface } from '../../../interfaces'
import AvatarTemplate from '../../../templates/avatar'
import { columnsForEmployees } from '../data/datagrid-cols'
import dxDataGrid from 'devextreme/ui/data_grid'
import account from "../../../assets/account.png"
import { Collapse } from 'antd'
import useResizeMode from '../../../hooks/useResizeMode'



const TelephoneCardView = ({ dataGrid }) => {
    const [initial, final] = useResizeMode(window.innerHeight);
    const gridheight = initial - 220;

    const datagridRef = useRef<dxDataGrid>();


    return (
        <main className='flex flex-col h-full w-full px-2 justify-center items-center'>
            <header className="flex flex-row">

            </header>

            <section className='w-full bg-slate-50'>
                <Datagrid_template
                    deselectFirstRow
                    disableGroupPanel
                    showColumnHeaders={false}
                    className={`w-full bg-slate-50 flex flex-row justify-center items-center place-items-center place-content-center`}
                    rowAlternationEnabled={false}
                    noColumn={500}
                    dataRowRender={(e) => {

                        let data = e?.data as GetGroupDataInterface
                        return (
                            <>
                                <section className='flex flex-col space-y-3 mt-10 m-10   border-2 rounded-md border-slate-800 w-72 p-2 flex-wrap'>
                                    <span className='flex justify-center'>
                                        <AvatarTemplate shape="circle" size={88} avatarIcon={`data:image/png;base64,${data.ppxPicture ?? account}`} avatarName={data?.empname} />
                                    </span>

                                    <ul className="flex flex-col space-y-1 py-1 ">
                                        {columnsForEmployees.map(({ id, caption, dataField }) => {

                                            return (
                                                (caption !== 'PICTURE') &&
                                                <li className='flex flex-row space-x-4 items-center flex-wrap'>
                                                    <p>{caption}</p>
                                                    <p className='text-ellipsis'>{data[dataField]}</p>
                                                </li>
                                            )
                                        })}


                                    </ul>

                                </section>
                                <Collapse
                                    defaultActiveKey={['1']}
                                // onChange={onChange}
                                // expandIconPosition={expandIconPosition}
                                // items={items}
                                />
                            </>


                        )
                    }}
                    // gridheight={toggleForm ? formPlusDataGridHeight : DataGridHeightOnly}
                    // rowDoubleClicked={() => { setToggleForm((prev) => !prev) }}
                    columns={columnsForEmployees}
                    data={dataGrid}
                    // columnCellRender={setImageOnCellRender}
                    onDatagridInitialized={(e) => {
                        datagridRef.current = e.component
                    }} gridheight={gridheight}

                />
            </section>


        </main>
    )
}

export default TelephoneCardView
