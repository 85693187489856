import React, { useEffect, useRef, useState, } from "react";
import TabPanel, { Item } from 'devextreme-react/tab-panel';

import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CustomToolbar } from "../../../accessories/custom-toolbars/customtoolbar";
import { Strategic_Objective } from "./Strategic_objectives";
import { CIA_toolbar } from "../../../accessories/custom-toolbars/cia_toolbar";
import { Objective_Teams } from "./Objective_teams";
import { Objective_Destinations } from "./Destinations";
import { Footer_datagrid_template } from "./data/footer_datagrid";
import { tableData } from "../../../data";
import axios from "axios";
import { api_url, navbar_height, toolbar_height } from "../../../accessories/component_infos";
import { OBJECTIVES_toolbar } from "../../../accessories/custom-toolbars/objectives_toolbar";
import { useDispatch, useSelector } from "react-redux";
import { task_footer_table_selected, task_footer_update, task_form_disable } from "../../../../features/Task&Todo/Task/TaskDetails";
import { RootState } from "../../../../app/store";
import { searchCriteriaTypes, searchTextTypes, statusTypes } from "../../../human-resource/setups/data/types/selectsTypes";
import  Datagrid_template from "../../../../templates/Datagrid";
import { objectives_columns } from "./data/datagrid_cols";
import { setGeneralValue } from "../../../../features/generalSlice";
import useFetch from "../../../../hooks/useFetch";



export const Objective_Tabs = () => {

    const news_flash_height = useSelector((state: RootState) => state.general.newsflashHeight);

    /**
    **************************************
    ************************************** 
    ***********Api Integrations***********
    **************************************
    **************************************
    */




  

    const [searchText, setSearchText] = useState<searchTextTypes>({ temp: '', text: '' });
    const [activeStatus, setActiveStatus] = useState<statusTypes>({ temp: '', status: '' });
    const [searchCriteria, setsearhCriteria] = useState<searchCriteriaTypes>({ temp: 0, index: 0 });
    const [refreshToolbar, setrefreshToolbar] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState({})
    //Fetch data for table
    const [showFullTable, setShowFullTable] = useState(false)
    const [tableData, setTableData] = useState();
    const [refreshValue, setRefreshValue] = useState(false);

    console.log("tollbarr",{
        activeStatus,
        searchCriteria,
        searchText
    })

    const [DBdata, DBerror, DBloading] = useFetch(`PfmObjectives/GetAllPfmObjectives?criteria=${searchCriteria.index}&search=${searchText.text}&active=${activeStatus.status}`, refreshValue, 'objIDpk');

    const searchCriteriaData = [
        { id: 0, value: 'Objective' },
        { id: 1, value: 'Theme' },
        { id: 2, value: 'Mission' },
        { id: 3, value: 'Vision' },
    
      ]

    // async function fetchTableData() {
    //     try {

    //         // get data
    //         const res = await axios.get(`${api_url}/api/PfmObjectives/GetAllPfmObjectives?criteria=${searchCriteria.index}&search=${searchText.text}&active=${activeStatus.status}`, {
    //             headers: {
    //                 'Content-Type': ['application/json', 'text/plain', 'charset=utf-8'],
    //                 // Accept : ['text/plain','application/json','charset=utf-8'],
    //             }
    //         })

    //         setTableData(res.data); // set data
    //         console.log(res)
    //     } catch (error: any) {
    //         console.log(error);

    //     } finally {

    //     }
    // }
    // init states
    useEffect(() => {

        // fetchTableData();

    }, [refreshValue]);

    const childRef = useRef<any>();

    const taskDetailsHeight = '309px'
    const heights_out = navbar_height + news_flash_height + toolbar_height;

    const [tasks_update_state, setTask_update_state] = useState<boolean>(true)
    const [task_datagrid_height, set_task_datagrid_height] = useState(`calc(100% - ${taskDetailsHeight})`);
    const [searchParams, setSearchParams] = useSearchParams()

    const navigate = useNavigate()

    const current_page_route = '/tasks-todos';
    const view = searchParams.get('view');
    const taskDataGrid = 'task-data-grid-extended';
    const dispatch = useDispatch();


    const height = window.innerHeight - (navbar_height + news_flash_height + 0); //height without navbar
    const [formheight, set_form_height] = useState<number>(0);
    const [switch_change, setSwitch_change] = useState<boolean>(true)

    const formRef = useRef<any>(null);
    const [fullPage, setfullPage] = useState(window.innerHeight - (navbar_height + news_flash_height + 30));
    const pageHeight = window.innerHeight - (navbar_height + news_flash_height + formheight + 20);
    // const fullPage = window.innerHeight - (navbar_height + 45) ; //height without navbar

    // switch toggle
    const switch_toggle = () => {
        if (switch_change) {
            setSwitch_change(!switch_change);

        } else {
            setSwitch_change(!switch_change);

        }
    }

    useEffect(() => {
        set_form_height((formRef.current?.clientHeight) + 30)
        // console.log(formRef)
    }, [])

    const [currentIndex, setCurrentIndex] = useState(0)

    return (
        <div className="w-full h-full " >
            <div className="mx-2">
                <CIA_toolbar
                    toggler={switch_toggle}
                    searchTextOnchange={(searchValue) => {
                        setSearchText({ temp: searchValue, text: searchText.text })
                    }}
                    checkOnChange={(check) => {
                        setActiveStatus({ temp: check, status: activeStatus.status })
                    }}
                    handleRefresh={() => {
                        setrefreshToolbar(current => !current)
                        setSearchText({ temp: '' , text: '' })
                    }}
                    handleFind={() => {
                        setSearchText({ temp: searchText.temp, text: searchText.temp }) //search text
                        setActiveStatus({ temp: activeStatus.temp, status: activeStatus.temp }) //active status
                        setsearhCriteria({ temp: searchCriteria.temp, index: searchCriteria.temp })
                        setRefreshValue(!refreshValue)
                    }}
                    searchCriteriaOnchange={(newIndex, criteria) => {
                        setsearhCriteria({ temp: newIndex, index: searchCriteria.index })
                    }}
                    searchCriteria={true}
                    searchCriteriaData={searchCriteriaData}
                    outerSelected={selectedData}
                    handleNew={() => {
                        setSelectedData({})
                    }}

                />
            </div>
            {switch_change &&  <div ref={formRef} className="w-full flex justify-center items-center">

                <TabPanel

                    onTitleClick={(item: any) => {
                        setCurrentIndex(item['itemIndex'])
                        dispatch(task_form_disable(true));
                        dispatch(task_footer_table_selected({}))
                        //  this destroys the update mode state
                        dispatch(task_footer_update(false));
                    }} className="w-full h-full">
                    <Item title={'Strategic Objectives'} >


                        <Strategic_Objective 
                            refresh={() => setRefreshValue(!refreshValue)} 
                            ref={childRef} 
                            state_change={false} 
                        />

                    </Item>

                    <Item title={'Objective Team Members'}>
                        <Objective_Teams state_change={false} />
                    </Item>

                    <Item title={'Destination(From/To)'}>
                        <Objective_Destinations state_change={false} />

                    </Item>

                </TabPanel>
            </div> }
            {/* Footer gridtable  */}
            <div style={{ height: '' }} className='px-2  dark:bg-slate-900 dark:text-darkModeSkin-base'>

                <Datagrid_template
                    gridheight={switch_change ? pageHeight : fullPage}
                    columns={objectives_columns}
                    data={DBdata}
                    onRowClick={(e: any) => { 
                        // childRef?.current!.singleClick(e)!;
                         dispatch(task_footer_table_selected(e)); setSelectedData(e); dispatch(setGeneralValue({ expr: 'formDisabled', value: true })) }}
                    rowDoubleClicked={(e: any) => {
                        // childRef.current!.doubleClick()!
                        dispatch(task_footer_table_selected(e)) //enable forms
                        dispatch(task_form_disable(true));

                        setSelectedData(e)

                        //enable Update Mode
                        dispatch(task_footer_update(true))
                    }}
                />
            </div>
        </div>

    )
}