/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import TabsPanel_template from '../../../../../../templates/tabsPanel_template';
import SubstationsDirectory from './Tabcontents/SubstationsDirectory';
import OperatorsOnDuty from './Tabcontents/OperatorsOnDuty';
import StaffTelDirectory from './Tabcontents/StaffTelDirectory';

export const Directories = ({ height }: { height: number }) => {
  const tabs: { id: number, title: string, contents: any }[] = [
    { id: 0, title: "Substations Directory", contents: <SubstationsDirectory height={height} /> },
    { id: 1, title: "Operators On Duty", contents: <OperatorsOnDuty height={height} /> },
    { id: 2, title: "Staff Tel Directory", contents: <StaffTelDirectory height={height} /> },
  ]
  return (
    <div className=''>
      <TabsPanel_template tabs={tabs} />
    </div>

  )
}
