/* eslint-disable react/jsx-pascal-case */
import React from "react";
import TabsPanel_template from "../../../../../../templates/tabsPanel_template";
import Attachments from "./Tabcontent/Attachments";
import Comments from "./Tabcontent/Comments";

export function Others() {
  const tabs: { id: number, title: string, contents: any }[] = [
    { id: 0, title: "Comments", contents: <Comments /> },
    { id: 1, title: "Attachments", contents: <Attachments /> },

  ]
  return (
    <div>
      <TabsPanel_template tabs={tabs} />
    </div>
  );
}
