import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios"
import { requestConfig } from "../general/requestConfig";
import { api_base_url, api_url } from "../components/accessories/component_infos";
import { useToken } from "../hooks/useToken";

import { userDataCrypt } from "./userDataEncrypt";
import { satisfies } from "semver";


export const GetDataFunc = async (url: string, params?, baseUrl?: "innox"| "powerapp" ) => {
    // const token = useToken()
    var api = api_url;

    switch (baseUrl) {
        case "powerapp":
            api = api_base_url
            break;
        default:
                api = api_url
    }

    if (!url) {
        // If the URL is not provided, return an empty data object
        return { data: [] };
    }

    // const headers = { 'Authorization': `Bearer ${token}` };

    try {
        // Make the GET request using axios
        const getResponse = await axios.get(`${api}/${url}`, requestConfig(params));

        // Return the response data
        // if (getResponse.status === 200) {
        //     return { status: true, data: getResponse.data }
        // }
        return getResponse;
    } catch (error) {
        // Handle any errors that might occur during the request
        console.error('Error fetching data:', error);

        // Return an empty data object or handle the error as needed
        return { data: [] };
    }
};

export const AsyncGet = async (url: string, params?: any) => {

    if (!url) {
        return { data: [] };
    }
    try {
        const res = await axios.get(`${api_url}/${url}`, requestConfig(params),) satisfies AxiosResponse<any, any>;

        if (res.status === 200) {
            return { status: true, data: res.data }
        }


    }
    catch (error) {
        if (error instanceof AxiosError) {
            return { status: false, data: error.response?.data }
        }
        return { status: false, data: [] };
    }
}

