import { Checkbox, DatePicker, Input, Select } from "antd";
import { Tooltip } from "devextreme-react";
import "devextreme/dist/css/dx.light.css";
import { useEffect, useState } from "react";
import { FaBinoculars } from "react-icons/fa";
import edit from "../../../../../../../assets/edit.png";
import newfile from "../../../../../../../assets/new.png";
import alter from "../../../../../../../assets/alter.png";
import printer from "../../../../../../../assets/printer.png";
import reset from "../../../../../../../assets/refresh-small.png";
import dayjs, { Dayjs } from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import close from "../../../../../../../assets/close.png";

import statusLoader from "../../../../../../../assets/statusloader.gif";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { setGeneralValue } from "../../../../../../../features/generalSlice";
import { currentDate } from "../../../../../../accessories/component_infos";
import {
  setEdit_Cancel_Button,
  setNew_Cancel_Button,
  setSave_Cancel_Button,
  setDisable_form,
} from "../../../../../../../features/VehicleRedux/TMS-Sicers/Vehicles/VehicleSimCardSlice";
import { DateTemplate } from "../../../../../../../templates/date";

interface props {
  toggler?: () => void;
  handleNew?: (value: boolean) => void;
  handleEdit?: (value: boolean) => void;
  checkOnChange?: (value: any) => void; //checkbox change
  handleFind?: (value: any) => void; //find handler
  handleRefresh?: (value: any) => void; //refresh main grid
  handlePrint?: () => void; // handle print functionality
  toggleNew: boolean;
  toggleUpdate: boolean;
  switchState?: (value: boolean) => void;
  outerSelect?: any;
  setOuterSelected?: (e: any) => void;
}

export const VehicleSimCardToolbar = ({
  toggler,
  handleNew,
  handleEdit,
  checkOnChange,
  handleFind,
  handleRefresh,
  handlePrint,
  outerSelect,
  setOuterSelected,
}: props) => {
  const dateFormat = " DD MMM YYYY";

  const [modalState, setModalState] = useState<boolean>(false);
  const [modalStateData, setModalStateData] = useState<{
    title: string;
    message: string;
  }>({ title: "", message: "" });
  const [indeterminate, setIndeterminate] = useState<any>(true);
  const [checkedBox, setCheckedBox] = useState<any>(false);
  const [checkValue, setCheckValue] = useState<boolean | string>(""); //actual value to use
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  const sideAlert = useSelector(
    (state: RootState) => state.general.sideAlertTest
  );

  useEffect(() => {
    checkOnChange && checkOnChange(checkValue);
  }, [checkValue]);

  let NewtoCancelButton = useSelector(
    (state: RootState) => state.TMSModule1.vehicleSimCard.New_Cancel_Button
  );

  let EdittoCancelButton = useSelector(
    (state: RootState) => state.TMSModule1.vehicleSimCard.Edit_Cancel_Button
  );

  const fdispatch = useDispatch();
  const onChange = (value: boolean) => {
    if (indeterminate === true) {
      setCheckedBox(true);
      setIndeterminate(false);
      setCheckValue(true);
    } else if (!indeterminate && checkedBox) {
      setCheckedBox(false);
      setIndeterminate(false);
      setCheckValue(false);
    } else if (!indeterminate && !checkedBox) {
      setCheckedBox(false);
      setIndeterminate(true);
      setCheckValue("");
    }
  };
  const dispatch = useDispatch();
  const [searchParam, setSearchParam] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [startDate, setStartDate] = useState<string | Dayjs>(
    dayjs(dayjs().subtract(3, "year").format("YYYY-MM-DD"))
  );
  const [endDate, setEndDate] = useState<string | Dayjs>(currentDate);
  const [privtype, setPrivType] = useState<string>("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privtype);

  const newAccesPriv = () => {
    setPrivType("save");
    if (savePriv === true) {
      //..........
      fdispatch(setNew_Cancel_Button(false));
      fdispatch(setEdit_Cancel_Button(true));
      fdispatch(setSave_Cancel_Button(true));
      fdispatch(setDisable_form(false));
      setOuterSelected && setOuterSelected({});
      handleNew && handleNew(true);
      handleEdit!(false);
    } else {
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      setModalState(true);
      setModalStateData({
        message: "No Access Privilege",
        title: "Access Denied!",
      });
    }
  };

  const updateAccessPriv = () => {
    setPrivType("update");
    if (updatePriv === true) {
      if (outerSelect !== undefined) {
        if (Object.keys(outerSelect).length === 0) {
          fdispatch(setNew_Cancel_Button(true));
          fdispatch(setEdit_Cancel_Button(true));
          fdispatch(setSave_Cancel_Button(true));
          fdispatch(setDisable_form(true));
          handleEdit && handleEdit(false);
          setModalState(true);
          setModalStateData({
            title: "Required field",
            message: "Please select a record",
          });
        } else {
          fdispatch(setNew_Cancel_Button(true));
          fdispatch(setEdit_Cancel_Button(false));
          fdispatch(setSave_Cancel_Button(false));
          fdispatch(setDisable_form(false));
          handleEdit && handleEdit(true);
          handleNew && handleNew(false);
        }
      }
    } else {
      handleEdit && handleEdit(false);
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      setModalState(true);
      setModalStateData({
        message: "No Access Privilege",
        title: "Access Denied!",
      });
    }
  };

  const handleCancel = () => {
    fdispatch(setNew_Cancel_Button(true));
    fdispatch(setEdit_Cancel_Button(true));
    fdispatch(setSave_Cancel_Button(true));
    fdispatch(setDisable_form(true));
  };


  return (
    <>
      {
        <ModalTemplate
          title={modalStateData.title}
          message={modalStateData.message}
          open={modalState}
          okHandler={() => {
            setModalState(false);
          }}
        />
      }
      <div
        style={{ borderWidth: "1px", height: 32, borderColor: borderTheme }}
        className="px-0.5 mt-0.5 rounded  w-full h-full flex  border-gray-200 mb-1"
      >
        <div className="flex justify-center ">
          <ul className="flex justify-evenly w-full items-center h-full py-0.5">
            {NewtoCancelButton ? (
              <li
                onClick={() => {
                  newAccesPriv();
                }}
                id="newRecord"
                style={{ borderWidth: "1px", borderColor: borderTheme }}
                className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
              >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                  {" "}
                  <img alt="" className="pr-1 " src={newfile} />
                  New{" "}
                </span>
                <Tooltip
                  target="#newRecord"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="h-2">New Record</p>
                </Tooltip>
              </li>
            ) : (
              <li
                id="close-cancel"
                onClick={() => handleCancel()}
                style={{ borderWidth: "1px", borderColor: borderTheme }}
                className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
              >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                  {" "}
                  <img alt="" className="pr-1 " src={close} />
                  Cancel{" "}
                </span>
                <Tooltip
                  target="#close-cancel"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">Cancel</p>
                </Tooltip>
              </li>
            )}

            {EdittoCancelButton ? (
              <li
                onClick={updateAccessPriv}
                id="editRecord"
                style={{ borderWidth: "1px", borderColor: borderTheme }}
                className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
              >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                  {" "}
                  <img
                    alt=""
                    className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                    src={edit}
                  />
                  Edit{" "}
                </span>
                <Tooltip
                  target="#editRecord"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">Edit Record</p>
                </Tooltip>
              </li>
            ) : (
              <li
                id="close-cancel"
                onClick={() => handleCancel()}
                style={{ borderWidth: "1px", borderColor: borderTheme }}
                className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
              >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                  {" "}
                  <img alt="" className="pr-1 " src={close} />
                  Cancel{" "}
                </span>
                <Tooltip
                  target="#close-cancel"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">Cancel</p>
                </Tooltip>
              </li>
            )}
 
            <li
              id="switch"
              onClick={toggler}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img
                  alt=""
                  className="dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"
                  src={alter}
                />
                Switch{" "}
              </span>
              <Tooltip
                target="#switch"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="h-2">Switch interface / table view</p>
              </Tooltip>
            </li>

            <li className="px-1 text-xs">Search Criteria</li>
            <li className="items-right  ">
              <Select
                key={"vsct"}
                showSearch
                onChange={(object) => {
                  setSearchParam(object);
                }}
                value={searchParam}
                size="small"
                allowClear={false}
                style={{ width: ` ${sideAlert === true ? 20 : 120}` }}
                dropdownStyle={{ minWidth: "250px" }}
              >
                {data?.map((option: any, index) => {
                  return (
                    <Select.Option value={index}>{option.label}</Select.Option>
                  );
                })}
              </Select>
            </li>
          </ul>
        </div>

        <div className="flex items-center">
          <ul className="flex items-center">
            <li className="px-1 text-xs">Search Text</li>
            <li className="px-1">
              <Input
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                value={searchValue}
                size="small"
                allowClear
                placeholder=""
                style={{ width: `${sideAlert === true ? 20 : 125}` }}
              />
            </li>
            <li className="px-0.5">
            <DateTemplate 
                changeDate={(val) => {
                  setStartDate(dayjs(val).format("YYYY-MM-DD"));
                }}
                disabled={false}
                selectedDate={dayjs(startDate)}
                dbFormat={true}
                width={125}
              />
              {/* <DatePicker
                onChange={(val) => {
                  setStartDate(dayjs(val).format("YYYY-MM-DD"));
                }}
                size="small"
                value={dayjs(startDate) ?? dayjs().format("YYYY-MM-DD")}
                style={{ width: 125 }}
                clearIcon={<span style={{ display: "none" }} />}
                format={dateFormat}
                disabledDate={(current) => {
                  return current && current.valueOf() > Date.now();
                }}
              /> */}
            </li>
            <li className="px-0.5">
              <DateTemplate 
                changeDate={(val) => {
                  setEndDate(dayjs(val).format("YYYY-MM-DD"));
                }}
                disabled={false}
                selectedDate={dayjs(endDate)}
                dbFormat={true}
                width={125}
              />
              {/* <DatePicker
                onChange={(val) => {
                  setEndDate(dayjs(val).format("YYYY-MM-DD"));
                }}
                size="small"
                value={dayjs(endDate) ?? dayjs().format("YYYY-MM-DD")}
                style={{ width: 125 }}
                format={dateFormat}
                clearIcon={<span style={{ display: "none" }} />}
              /> */}
            </li>
            <li
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              id="status"
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1  text-gray-600 rounded flex items-center"
            >
              <Checkbox
                checked={checkedBox}
                indeterminate={indeterminate}
                onChange={(checkValue: any) => {
                  onChange(checkValue);
                }}
              />
              <Tooltip
                target="#status"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Show Status</p>
              </Tooltip>
            </li>
            <li
              onClick={() => {
                if (handleFind) {
                  handleFind(
                    `AsmTmsVehicleSimCardAssignment/AsmTmsVehicleSimCardAssignmentGetAll?search=${searchValue}&active=${checkValue}&startDate=${startDate}&endDate=${endDate}&searchCriteriaIndex=${searchParam}`
                  );
                }
              }}
              id="findst"
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
                <FaBinoculars size={15.5} color="#007AFF" />
              </span>
              <Tooltip
                target="#findst"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Find</p>
              </Tooltip>
            </li>
            <li
              onClick={() => {
                setSearchValue("");
                setIndeterminate(true);
                setSearchParam(0);
                if (handleRefresh) {
                  handleRefresh(
                    `AsmTmsVehicleSimCardAssignment/AsmTmsVehicleSimCardAssignmentGetAll?search=${""}&active=&startDate=${startDate}&endDate=${endDate}&searchCriteriaIndex=${0}`
                  );
                } 
              }}
              id="refreshst"
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
                {" "}
                <img
                  alt=""
                  className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                  src={reset}
                />
              </span>
              <Tooltip
                target="#refreshst"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Refresh</p>
              </Tooltip>
            </li>

            {/* <li
              onClick={() => handlePrint && handlePrint()}
              id="printst"
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
                {" "}
                <img
                  alt=""
                  className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                  src={printer}
                />
              </span>
              <Tooltip
                target="#printst"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Print/Export</p>
              </Tooltip>
            </li> */}

            <li className="hover:cursor-pointer ml-20  px-2  flex items-center">
              {EdittoCancelButton && NewtoCancelButton ? (
                <p className=" italic font-semibold text-gray-600">Done.</p>
              ) : EdittoCancelButton === true && NewtoCancelButton === false ? (
                <>
                  <img
                    style={{ width: "31px", height: "31px" }}
                    src={statusLoader}
                    alt="gif"
                  />
                  <p className=" italic text-gray-600">New Record</p>
                </>
              ) : EdittoCancelButton === false && NewtoCancelButton === true ? (
                <>
                  <img
                    style={{ width: "31px", height: "31px" }}
                    src={statusLoader}
                    alt="gif"
                  />
                  <p className=" italic text-gray-600">Editing record</p>
                </>
              ) : (
                <p className=" italic font-semibold text-gray-600">Done.</p>
              )}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

const data = [
  {
    label: "Reg No",
  },
  {
    label: "Mob No",
  },
  {
    label: "Telecom Vendor",
  },
];
