import { forwardRef } from "react";
import MiniToolbar from "./_widget/minitollbar";
import { Form } from "antd";
import { InputsTemplate } from "../../../../templates/input";
import { CheckboxTemlate } from "../../../../templates/checkbox";
import { NewButton, SaveButton } from "../../../accessories/buttons";
import InputSelect_template from "../../../../templates/InputSelect";
import Datagrid_template from "../../../../templates/Datagrid";
import { Switcher } from "./_widget/switcher";
import { outagecol } from "./utils/data/colums";
import { HrSetupsCustomToolbar } from "../../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import { TextareaTemplate } from "../../../../templates/textarea";
import { TextArea } from "devextreme-react";
import { backgroundColor } from "@syncfusion/ej2-richtexteditor/src/rich-text-editor/models/items";

export const OutageEquipmentTypes = forwardRef(() => {
  const formDetails = [
    { id: 0, label: "Name" },
    { id: 1, label: "Short Name" },
  ];
  const checkBox = [
    { id: 2, label: "Planned?" },
    { id: 3, label: "Auto?" },
    { id: 4, label: "Active?" },
  ];
  return (
    <div className="w-full h-full  ">
      <HrSetupsCustomToolbar />
      {/* <Switcher alternate={""} > */}
      <div>
        <p className="text-md font-medium border-b-1 bg-slate-200 rounded-t-md py-1 pl-2">
          {"Outage Types Details"}
        </p>
      </div>
      <div className="flex">
        <aside className=" w-[450px] ">
          <Form
            className=" dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2  "
            name="basic"
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 20 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            size="small"
            layout="horizontal"
          >
            {formDetails.map(({ id, label }) => (
              <div className=" w-[630px]">
                <InputSelect_template
                  key={id}
                  label={label}
                  span={id === 0 ? false : true}
                  selectStyle={{ width: id === 1 ? 300 : 272 }}
                  disabled={false}
                />
              </div>
            ))}

            <div className=" flex gap-[63px]  ml-[58px] py-1  w-[500px]">
              {checkBox.map(({ id, label }) => (
                <CheckboxTemlate
                  key={id}
                  label={label}
                  withBorders={true}
                  labelCol={20}
                />
              ))}
            </div>
            <div className="flex py-2 gap-2  w-[437px] justify-end">
              <NewButton />
              <SaveButton disableButton={false} />
            </div>
          </Form>
        </aside>
        <aside className="w-full">
          {/* <Form.Item
              style={{
                display: "inline-block",
                marginBottom: "1px",
                // width: "100%",
                marginRight:"8px",
              }}
              label={<p className="text-xs">{"Remarks"}</p>}
              className="w-[65%]  mb-1 bg-pink-200"
            >
              <TextArea
                style={{
                    marginTop: "8px",
                  maxHeight: "70vh",
                  minHeight: "70px",
                  height: "120px",
                  width: "98%",
                }}
                className="overflow-y-scroll "
               
              />
          </Form.Item> */}
          <Form.Item className="pt-2 pl-[40px]" label={<p className="text-[12px]">Remarks</p>}>
            <div className="w-full pr-2">
              <TextareaTemplate height={125} />
            </div>
          </Form.Item>
        </aside>
      </div>
      {/* </Switcher> */}
      <section className="flex-grow">
        <section className="w-full h-full">
          <Datagrid_template
            gridheight={615}
            showColumnHeaders={true}
            columns={outagecol}
            data={[]}
            disablePaging
            disableGroupPanel
          />
        </section>
      </section>
    </div>
  );
});
