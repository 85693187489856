import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { isArray } from 'lodash';



interface ppeRequest {
    Group: string;
    requestWindow : string;
    RequestBy : string;
    RecommendedBy : string;
    ApprovedBy : string;
    AcknowledgedBy : string;
    Remarks : string;
    DepartmentID: number;
    cia_period : number;
    MissionID: number ;
    ppedistr_department : string;
    ppedistr_unit : string;
    ppedistr_section : string;
    ppedistr_location:string;
    ci_Remarks: string;
    ci_perspective: string;
    ci_initiative : string,
    ci_shortname : string,
    ppeReq_Size_Type : any;
    pers_shrtname : string,
  pers_remarks : string,
  // Maintenance
  main_mainT : string,
  main_rp : string,
  main_fdur: string,
  main_fd : string,
  main_nfd : string,

  maint_c : number,
  maint_t : number,
  maint_con : string,
  maint_req : number,

  // measuresTargets 
  mt_picture : string;
}


var initialState = {
  selectedRecord : [],
  ppeReq_Size_Type : {},
  ppeReq_Date : "",
  ppeReq_Group : "",
  ppeReq_Recomm : "",
  ppeReq_Acknw : "",
  ppeReq_Aprvd : "",
  ppeReq_Remarks : "",
  DepartmentID : 0,
  
  ppedistr_department : "",
  ppedistr_unit : "",
  ppedistr_section : "",
  ppedistr_location:"",


  MissionID : 0,
  cia_period : 0,
  cia_Remarks: "",
  cia_object : "",
  cia_initiative : "",

  ci_Remarks : "",
  ci_perspective : "",
  ci_initiative : "",
  ci_shortname : "",
  pers_name : "",
  pers_shrtname : "",
  pers_remarks : "",
  pers_questionsAnswered: "",
  pers_order : '',


  main_mainT : "",
  main_rp : "",
  main_fdur: "",
  main_fd : "",
  main_nfd : "",
  maint_c : 0,
  maint_t : 0,
  maint_con : "",
  maint_req : 0,

  // measureTargets

  mt_picture : "",

  
}as ppeRequest | any


const safetyManagerSlice = createSlice({
    name: 'safetyManagerDetails',
    initialState,
    reducers: {

      setppeReqSize_Type(state,item) {
        state.ppeReq_Size_Type = item.payload
      },
      // PPE REQ
      
      setppeReqDetailsValue(state, action: PayloadAction<any>) { //set values of variables here
        // this automatically create a state with it's corresponding key value
        const expr:string = action.payload.expr;
        const value:any = action.payload.value;
  
        state[expr] = value;
        //push selected item ID to state
        switch(expr) {
          case 'Request Time':
            state.ppeReq_Date= value;
          break;

          case 'Group':
            state.ppeReq_Group = value;
          break;

          case 'Recommended By':
            state.ppeReq_Recomm = value;
          break;
          case 'Acknowledged By':
            state.ppeReq_Acknw = value;
          break;
          case 'Approved By':
            state.ppeReq_Aprvd= value;
          break;
          case 'Remarks':
            state.ppeReq_Remarks= value;
          break;

          default:
            state[expr] = value;
        }
        
      },

      // CIA 

      setciaDetailsValue(state, action: PayloadAction<any>) { //set values of variables here
        // this automatically create a state with it's corresponding key value
        const expr:string = action.payload.expr;
        const value:any = action.payload.value;
  
        state[expr] = value;
        switch(expr) {
          
          case 'Mission/Vision':
            state.MissionID = value;
          break;
          case 'Strategic Objective':
            state.Strategic = value
            
          break;
          case 'Initiative':
            state.Initiative = value
          break;
          case 'Period':
            state.cia_period = value
          break;
          case 'Remarks':
            state.cia_Remarks = value
            
          break;

         
        }
        


        //push selected item ID to state
        
      },
      setppedistrDetailsValue(state, action: PayloadAction<any>) { //set values of variables here
        // this automatically create a state with it's corresponding key value
        const expr:string = action.payload.expr;
        const value:any = action.payload.value;
  
        state[expr] = value;
        switch(expr) {
          case 'Assigned Dept':
            state.ppedistr_department = value;
          break;

          case 'Assigned Section':
            state.ppedistr_section = value;
          break;
          case 'Assigned Unit':
            state.ppedistr_unit = value;
          break;
          case 'Assigned Location':
            state.ppedistr_location = value;
          break;

          default:
            state[expr] = value;
        }
        


        //push selected item ID to state
        
      },

      // ci

      setciDetailsValue(state, action: PayloadAction<any>) { //set values of variables here
        // this automatically create a state with it's corresponding key value
        const expr:string = action.payload.expr;
        const value:any = action.payload.value;
  
        state[expr] = value;
        switch(expr) {
          case 'Remarks':
            state.ci_Remarks = value;
          break;

          case 'Perspective':
            state.ci_perspective = value;
          break;

          case 'Initiative':
            state.ci_initiative = value;
          break;

          case 'Short Name':
            state.ci_shortname = value;
          break;

          default:
            state[expr] = value;
        }
        
        //push selected item ID to state
        
      },

      // Perspective module
      setperspectiveDetailsValue(state, action: PayloadAction<any>) { //set values of variables here
        // this automatically create a state with it's corresponding key value
        const expr:string = action.payload.expr;
        const value:any = action.payload.value;
  
        state[expr] = value;
        switch(expr) {
          case 'Questions Answered':
            state.pers_questionsAnswered = value;
          break;

          case 'Remarks':
            state.pers_remarks = value;
          break;

          case 'Order':
            state.pers_order = value;
          break;

          case 'Short Name':
            state.pers_shrtname = value;
          break;

          case 'Name':
            state.pers_name = value;
          break;

          

          default:
            state[expr] = value;
        }


        
        //push selected item ID to state
        
      },

      // transformer details
      settransDetailsValue(state, action: PayloadAction<any>) { //set values of variables here
        // this automatically create a state with it's corresponding key value
        const expr:string = action.payload.expr;
        const value:any = action.payload.value;
  
        state[expr] = value;

        //push selected item ID to state
        
      },

      //  maintenance
      setmaintenanceDetailValue(state, action:PayloadAction<any>){
        const expr:string = action.payload.expr;
        const value:any = action.payload.value;

        state[expr] = value

        switch(expr){
          case 'Maintenance Type' :
            state.main_mainT = value
            break;
          
          case 'Reschedule Plan':
            state.main_rp = value
            break;
          
          case 'Freq. Dur - (Days)' :
            state.main_fdur = value;
            break;

          case 'Freq. Distance':
            state.main_fd = value;
            break;
          
            case 'Next Freq. Distance':
              state.main_nfd = value;
              break;
            case 'Maint. Type':
              state.maint_t = value;
              break;
              case 'Arrival Condition':
            state.maint_con = value;
            break;
            case 'Requested By':
            state.maint_req = value;
            break;
            case 'Maint. Category':
            state.maint_c = value;
            break;
        }

      },

      // Measures and Targets Entries

      setmeasuretargetDetailValue(state, action:PayloadAction<any>){
        const expr:string = action.payload.expr;
        const value:any = action.payload.value;

        state[expr] = value

        switch(expr){
          case 'The Picture' :
            state.mt_picture = value
            break;
          
        }

      },

      

      task_footer_table_selected : (state, action: PayloadAction<any>) => {
        state.selectedRecord = (action.payload)
        console.log(action.payload);
        const expr:string = action.payload.expr;
        const value:any = action.payload.value;
        state[expr] = value;

        if(isArray(action.payload.value)){
          // for cia form
            state.MissionID = action.payload[0]?.mvsIdpk;
            state.Strategic = action.payload[0]?.objIdpk;
            state.Initiative = action.payload[0]?.cinIdpk;
            state.cia_period = action.payload[0]?.prdIdpk;
            state.ci_initiative = action.payload[0]?.cinName
            state.ci_perspective = action.payload[0]?.ptvIdpk
            state.ci_shortname = action.payload[0]?.cinShtName
        }else{
          state[expr] = value;
        }

        
    },
    },
  })
  
  export const { task_footer_table_selected,setppeReqDetailsValue,
    setppedistrDetailsValue,setciaDetailsValue,setciDetailsValue,
    setppeReqSize_Type,setperspectiveDetailsValue,settransDetailsValue,
    setmaintenanceDetailValue,setmeasuretargetDetailValue } = safetyManagerSlice.actions


  export default safetyManagerSlice.reducer