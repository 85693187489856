import { DataGrid } from "devextreme-react";
import {
  Column,
  Scrolling,
  SearchPanel,
  GroupPanel,
  HeaderFilter,
  Pager,
} from "devextreme-react/data-grid";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { telephone_data, telephone_headers } from "./data/telephone-data";
import { ToolbarPreparingEvent } from "devextreme/ui/data_grid";
import useResizeMode from "../../../../hooks/useResizeMode";

interface options {
  pageResized: Function;
}

const TelephoneDir = forwardRef(({ pageResized }: options, ref) => {
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const displayModes = [
    { text: "Display Mode 'full'", value: "full" },
    { text: "Display Mode 'compact'", value: "compact" },
  ];
  const allowedPageSizes = ["all", 5, 10, 15];

  // //Table Height
  // const [viewHeight, setViewHeight] = useState(
  //   window.innerHeight - 58.05 - 32 - 47 - 2 - news_flash_height
  // );
  // useImperativeHandle(ref, () => ({
  //   test: () => {
  //     setViewHeight(window.innerHeight - 58.05 - 34 - 47 - news_flash_height);
  //   },
  // }));\
  // let  [resizeOnf11,initialSize] = useResizeMode(window.innerWidth === screen.width && window.innerHeight === screen.height);
  let  [resizeOnf11,initialSize] = useResizeMode(window.innerWidth  && window.innerHeight );

  let  normalPageSize =  initialSize - 210;
  let  onF11PageSize = resizeOnf11 - 210;

  return (
    <div className="mt-1 w-full h-full ">
      <style
        dangerouslySetInnerHTML={{
          __html: `
            #telephoneDataGrid.dx-datagrid-search-panel {
               
            }
            `,
        }}
      />
      <div className="w  w-full  " id="telephoneGrid">
        <DataGrid
          dataSource={telephone_data}
          showBorders={true}
          columnWidth={120}
          showRowLines={true}
          showColumnLines={true}
          height={window.innerWidth && window.innerHeight ?normalPageSize:onF11PageSize}
          id="telephoneDataGrid"
          onToolbarPreparing={(e: ToolbarPreparingEvent<any, any>) => {
            let toolbarItems = e.toolbarOptions.items;

            // Modifies an existing item
            toolbarItems!.forEach(function (item: any) {
              if (item.name === "searchPanel") {
                item.location = "before";
              }
            });
          }}
        >
          <Pager
            visible={true}
            allowedPageSizes={"auto"}
            displayMode={"compact"}
            showInfo={true}
            showNavigationButtons={true}
            showPageSizeSelector={true}
          />
          {/* STAFF DETAILS COLUMNS */}
          {/* FIXED COLUMNS */}

          {telephone_headers.map((data) => {
            return (
              <Column
                key={data.id}
                caption={data.caption}
                dataField={data.dataField}
                alignment={data.alignment as "center" | "left" | "right" | undefined}
                fixed={data.fixed}
                width={data.width === null ? 100 : data.width}
              />
            );
          })}

          {/* SCROLLBAR  */}

          <Scrolling columnRenderingMode="virtual" />

          <HeaderFilter visible={true} />

          <SearchPanel visible={true} />
          <GroupPanel
            visible={true}
            emptyPanelText={"Drag a column header to group"}
          />
        </DataGrid>
      </div>
    </div>
  );
});

export default TelephoneDir;
