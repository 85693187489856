import { Button, Checkbox, DatePicker, Form, Input, Radio, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { DataGrid } from "devextreme-react";
import {
  SearchPanel,
  GroupPanel,
  Pager,
  Column,
  Scrolling,
} from "devextreme-react/data-grid";
import reset from "../../../../../../assets/reset.png";
import { question_table_data, questions_TH } from "../data/questions-data";
// import { EditButton, NewButton, SaveButton } from "../../../../../accessories/buttons";
// import { smallAttendanceData, smallAttendaceTable_TH, mediumlAttendaceTable_TH } from "../../../../../Safety/Entries/SafetyMeetingAttendance/data/meeting-attendance-data";
import { useState, useEffect } from "react";
// import { api_url, navbar_height,  toolbar_height } from "../../../../../accessories/component_infos";
import save from "../../../../assets/floppy-disk.png";
import newIcon from "../../../../assets/new.png";
import axios from "axios";
import { useSelector } from "react-redux";
// import { RootState } from "../../../../../../app/store";
// import { settask_footer_table_refresh } from "../../../../../../features/generalSlice";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import {
  api_url,
  navbar_height,
  toolbar_height,
} from "../../../accessories/component_infos";
import { RootState } from "../../../../app/store";
import { settask_footer_table_refresh } from "../../../../features/generalSlice";
import { InputsTemplate } from "../../../../templates/input";
import { TextareaTemplate } from "../../../../templates/textarea";
interface props {
  toggled?: boolean;
}

const GPSLineDetailsTab = ({ toggled }: props) => {
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  interface props {
    toggled?: boolean;
  }
  const sp = "\u00a0";

  const heights_out = navbar_height + news_flash_height + toolbar_height;

  //varying form height
  const form_height = 490;

  //Table Height
  const pageHeight = window.innerHeight - navbar_height; //height without navbar
  const [tableHeight, settableHeight] = useState(
    window.innerHeight - (heights_out + 70)
  ); //height without navbar and toolbar
  const [table_form_Height, settable_form_Height] = useState(
    window.innerHeight - heights_out
  ); //height without navbar and toolbar and form

  const [showTable, setShowTable] = useState(true);
  const dateFormat = " DD MMM YYYY HH: mm";
  const dispatch = useDispatch();
  const selectedRecord = useSelector(
    (state: RootState) => state.safetyManagerDetails.selectedRecord
  );

  const [states, setStates] = useState({
    startLtt: "",
    endLtt: "",
    startLgt: "",
    endLgt: "",
    rmks: "",
    refresh: false,
  });

  const setState = (key: string, value: any) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };

  const editedDate = dayjs().format();

  const updateData = async () => {
    try {
      const res = await axios
        .put(
          `${api_url}/api/AsmTamEntLineDetails/UpdateGPSAsmTamLineDetail/${selectedRecord[0]?.ldtIdpk}`,
          {
            ldtStartLatitude: states.startLtt,
            ldtEndLatitude: states.endLtt,
            ldtStartLongitude: states.startLgt,
            ldtEndLongitude: states.endLgt,
            ldtRmks: states.rmks,
            ldtEditedBy: 1,
            ldtEditedDate: editedDate,
            ldtIdpk: selectedRecord[0]?.ldtIdpk,
          }
        )
        .then((response) => {
          console.log(response);
          alert("presp updated successfully");
          // dispatch(settask_footer_table_refresh(setRefresh(!refresh)))
          dispatch(settask_footer_table_refresh(!states.refresh));
        });

      // setOpenModal(false)
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    console.log(`inside ${toggled}`);
  }, [toggled]);

  const { Option } = Select;
  return (
    <div>
      {
        <div className="  rounded-md">
          {/* The form */}
          <div className=" flex ">
            <Form
              name="complex-form"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              size={"small"}
              className="ml-2 my-2 "
              style={{ width: "100%" }}
            >
              <Form.Item
                label={<p className="text-xs">{"Start & End Latitude"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(50%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <InputsTemplate
                      useCallbackFunc
                      span
                      disabledStatus={false}
                      style={{ width: "100%" }}
                      defaultValue={states.startLtt}
                      orderOnchange={(e) => {
                        setState("startLtt", e);
                      }}
                      //  onChange={(e: any) => {
                      //    setStartLtt(e.target.value);
                      //  }}
                      //  style={{ marginBottom: 0 }}
                      //  className=""
                      placeholder=""
                    />

                    <InputsTemplate
                      useCallbackFunc
                      span
                      disabledStatus={false}
                      style={{ width: "100%", marginLeft: "5px" }}
                      defaultValue={states.endLtt}
                      orderOnchange={(e) => {
                        setState("endLtt", e);
                      }}
                      placeholder=""
                    />
                  </div>
                </Form.Item>
              </Form.Item>

              <Form.Item
                label={<p className="text-xs">{"Start & End Longitude"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(50%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <InputsTemplate
                      useCallbackFunc
                      span
                      disabledStatus={false}
                      style={{ width: "100%" }}
                      defaultValue={states.startLgt}
                      orderOnchange={(e) => {
                        setState("startLgt", e);
                      }}
                      placeholder=""
                    />

                    <InputsTemplate
                      useCallbackFunc
                      span
                      disabledStatus={false}
                      style={{ width: "100%", marginLeft: "5px" }}
                      defaultValue={states.endLgt}
                      orderOnchange={(e) => {
                        setState("endLgt", e);
                      }}
                      placeholder=""
                    />
                  </div>
                </Form.Item>
              </Form.Item>

              <Form.Item
                label={<p className="text-xs">{"Remarks"}</p>}
                style={{ marginBottom: 0, marginTop: "2px", width: "calc(100%)" }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center w-full">
                    <TextareaTemplate
                      setCallbackValue={(e) => {
                        setState("rmks", e);
                      }}
                      height={200}
                      width={'450%'}
                      defaultValue={states.rmks}
                      readonly={false}
                      disabled={false}
                      //  className="w-full h-full"
                    />
                  </div>
                  <div className="flex  justify-end mt-7 ">
                    <button
                      onClick={async () => {
                        updateData();
                      }}
                      className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center   px-2 py-0.5 border-2 border-slate-200 rounded"
                    >
                      <img className="pr-1" src={save} />
                      Update
                    </button>
                  </div>
                </Form.Item>
              </Form.Item>
            </Form>

            {/* <Form
              name="complex-form"
              labelCol={{ span: 1 }}
              wrapperCol={{ span: 1 }}
              size={"small"}
              className=" border-l-2 ml-2 "
              style={{ width: "45%" }}
            >
              <div className=" border-t-2 pb-2 ">
                <div className="text-xs bg-slate-100 rounded-t-sm py-1 px-2">
                  Images
                </div>

                <div className="px-2 pb-2 flex items-end    h-64 w-full ">
                  <div className=" w-full h-60  border flex justify-center items-center">
                    No Image data
                  </div>
                </div>
                <div className="flex mx-2 mb-0.5">
                  {" "}
                  <TextArea
                    style={{
                      resize: "none",
                      maxHeight: "12%",
                      minHeight: "10%",
                      height: "",
                      width: "88%",
                    }}
                    className="o  py-1  overflow-y-scroll resize-none"
                    rows={1}
                  />
                  <button className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center ml-1  mr-1 px-2  border-2 border-slate-200 rounded">
                    <img className="pr-1" src={save} />
                  </button>
                  <button className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  px-2  border-2 border-slate-200 rounded">
                    <img className="pr-1" src={newIcon} />
                  </button>
                </div>
                <div className="flex">
                  <div
                    style={{
                      resize: "none",
                      maxHeight: "12%",
                      minHeight: "10%",
                      height: "",
                      width: "100%",
                    }}
                    className="o mx-2  border  flex "
                  >
                    <div className=" border-r p-0.5 text-xs px-2">1</div>
                    <div className=" border-r p-0.5 text-xs px-2">2</div>
                    <div className=" border-r p-0.5 text-xs px-2">3</div>
                    <div className=" border-r p-0.5 text-xs px-2">4</div>
                    <div className=" border-r p-0.5 text-xs px-2">5</div>
                    <div className=" border-r p-0.5 text-xs px-2">6</div>
                    <div className=" border-r p-0.5 text-xs px-2">7</div>
                    <div className=" border-r p-0.5 text-xs px-2">8</div>
                  </div>
                </div>
              </div>
            </Form> */}
          </div>
        </div>
      }
    </div>
  );
};

export default GPSLineDetailsTab;
