import { useState } from "react";
import { Form, Input, Select, Image } from "antd";
import reset from "../../../../../../../assets/reset.png";

import Checkbox from "antd/es/checkbox/Checkbox";
import {
  SaveButton,
  NewButton,
  DispenseButton,
  Cancel,
  UpdateButton,
} from "../../../../../../accessories/buttons";
import {
  inputsTypes,
  selectsTypes,
} from "../../../../../../human-resource/setups/data/types/selectsTypes";
import { SelectsTemplate } from "../../../../../../../templates/select";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import { InputsTemplate } from "../../../../../../../templates/input";
import { DateTemplate } from "../../../../../../../templates/date";
import useFetch from "../../../../../../../hooks/useFetch";
import dayjs from "dayjs";

interface stateTypes {
  purMode?: { id: number; name: string };
  reqDate?: string;
  vehNo?: { id: number; name: string };
  sp?: { id: number; name: string };
  ss?: { id: number; name: string };
  prod?: { id: number; name: string };
  quan1?: string;
  quan2?: string;
  unit?: string;
  total?: number;
  uom?: string;
  coupon?: string;
  authBy?: { id: number; name: string };
  rec?: { id: number; name: string };
  dept?: string;
  sxn?: string;
  cost?: string;
  prevOdom?: string;
  curOdom?: string;
  active?: boolean;
  remarks?: string;
}

export const Fuel_Transaction_Details_Form = () => {
  const { TextArea } = Input;

  const form_disable = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const update_state_change = useSelector(
    (state: RootState) => state.general.taskFooterUpdate
  );

  const [states, setStates] = useState<stateTypes>({
    purMode: { id: 0, name: "" },
    reqDate: "",
    vehNo: { id: 0, name: "" },
    sp: { id: 0, name: "" },
    ss: { id: 0, name: "" },
    prod: { id: 0, name: "" },
    quan1: "",
    quan2: "",
    unit: "",
    total: 0,
    uom: "",
    coupon: "",
    authBy: { id: 0, name: "" },
    rec: { id: 0, name: "" },
    dept: "",
    sxn: "",
    cost: "",
    prevOdom: "",
    curOdom: "",
    active: false,
    remarks: "",
  });

  const setState = (key: string, value: any) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };

  const options = [];
  for (let i = 1; i < 36; i++) {
    options.push({
      label: i.toString(36) + i,
      value: i.toString(36) + i,
    });
  }

  // Endpoints
  const [pur_data, pur_error, pur_loading] = useFetch(
    "api/AsmTmsEntFuelManagement/GetEntAsmTmsLuePurchaseModes"
  );
  const [vehNo_data, vehNo_error, vehNo_loading] = useFetch("");
  const [sp_data, sp_error, sp_loading] = useFetch(
    "api/AsmTmsEntFuelManagement/GetEntAsmTmsLueServiceProvides"
  );
  const [ss_data, ss_error, ss_loading] = useFetch("");
  const [prod_data, prod_error, prod_loading] = useFetch(
    `api/AsmTmsEntFuelManagement/GetEntAsmTmsLueDumpProducts?serviceId=1&stationId=1`
  );
  const [uom_data, uom_error, uom_loading] = useFetch(
    "api/AsmTmsEntFuelManagement/GetEntAsmTmsLueFuelUnits"
  );
  const [auth_data, auth_error, auth_loading] = useFetch(
    `api/AsmTmsEntFuelManagement/GetEntAsmTmsLueApprovedBy?serviiceProviderId=1&stationId=1`
  );
  const [rec_data, rec_error, rec_loading] = useFetch(
    "api/AsmTmsEntFuelManagement/GetEntAsmTmsLueReceivedBy"
  );
  const [dept_data, dept_error, dept_loading] = useFetch(
    "api/AsmTmsEntFuelManagement/GetEntAsmTmsLueDumpDebits"
  );
  const [sxn_data, sxn_error, sxn_loading] = useFetch(
    `api/AsmTmsEntFuelManagement/GetEntAsmTmsLueDumpDebitSections?debitDepartmentId=1`
  );
  const [cost_data, cost_error, cost_loading] = useFetch(
    `api/AsmTmsEntFuelManagement/GetEntAsmTmsLueDumpDebitCostCenters?debitDepartmentId=1&debitSectionId=1`
  );

  const pur: selectsTypes[] = [
    {
      id: 0,
      label: "Purchase Mode",
      optionsData: pur_data,
      idExpr: "pcmIdpk",
      dataExpr: "pcmName",
      stateName: "purMode",
      defaultValue: states.purMode?.name,
    },
  ];

  const dates: inputsTypes[] = [
    { id: 0, label: "Request Date", stateName: "reqDate", defaultValue: "" },
  ];

  const selects: selectsTypes[] = [
    {
      id: 1,
      label: "Vehicle Reg. No",
      optionsData: vehNo_data,
      idExpr: "",
      dataExpr: "",
      stateName: "vehNo",
      defaultValue: "",
    },
    {
      id: 2,
      label: "Service Provider",
      optionsData: sp_data,
      idExpr: "spdIdpk",
      dataExpr: "spdName",
      stateName: "sp",
      defaultValue: "",
    },
    {
      id: 3,
      label: "Service Station",
      optionsData: ss_data,
      idExpr: "",
      dataExpr: "",
      stateName: "ss",
      defaultValue: "",
    },
    {
      id: 4,
      label: "Product",
      optionsData: prod_data,
      idExpr: "prdIdpk",
      dataExpr: "prdName",
      stateName: "prod",
      defaultValue: "",
    },
  ];

  const rightSelects: selectsTypes[] = [
    {
      id: 1,
      label: "Authorized",
      optionsData: auth_data,
      idExpr: "",
      dataExpr: "",
      stateName: "auth",
      defaultValue: "",
    },
    {
      id: 2,
      label: "Received By",
      optionsData: rec_data,
      idExpr: "empIdpk",
      dataExpr: "empName",
      stateName: "rec",
      defaultValue: "",
    },
  ];

  const Rightinputs: inputsTypes[] = [
    {
      id: 0,
      label: "Prev. Odom Reading",
      stateName: "prevOdom",
      defaultValue: "",
    },
    {
      id: 1,
      label: "Current Odom Reading",
      stateName: "curOdom",
      defaultValue: "",
    },
  ];

  return (
    <div
      style={{ width: "100%", height: "100%" }}
      className="  border-2   dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base  "
    >
      <div
        style={{ maxHeight: "280px", minHeight: "280px", height: "280px" }}
        className=" items-start justify-start "
      >
        <Form
          className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 px-2 "
          name="basic"
          labelCol={{ span: 7 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          size="small"
          layout="horizontal"
        >
          <div style={{ width: "100%" }} className=" flex ">
            <div style={{ width: "37%" }} className="">
              {pur.map(
                ({
                  id,
                  label,
                  idExpr,
                  dataExpr,
                  optionsData,
                  stateName,
                  defaultValue,
                }) => {
                  return (
                    <>
                      <SelectsTemplate
                        cusWidth={"165px"}
                        disabledCustomWidth={true}
                        label={label}
                        options={optionsData}
                        idexpr={idExpr}
                        dataexp={dataExpr}
                        placeHolder={defaultValue}
                        useCallFunc
                        disabled={form_disable}
                        selectedValue={(e) => {
                          setState(stateName!, {
                            id: e[idExpr!],
                            name: e[dataExpr!],
                          });
                        }}
                      />
                    </>
                  );
                }
              )}

              {dates.map(({ id, label, stateName, defaultValue }) => {
                return (
                  <>
                    <DateTemplate
                      label={label}
                      width={"60%"}
                      changeDate={(e: string) => {
                        setState("reqDate", dayjs(e));
                      }}
                      selectedDate={""}
                    />
                  </>
                );
              })}
              <>
                {selects.map(
                  ({
                    id,
                    label,
                    idExpr,
                    dataExpr,
                    optionsData,
                    stateName,
                    defaultValue,
                  }) => {
                    return (
                      <>
                        <SelectsTemplate
                          cusWidth={"80%"}
                          disabledCustomWidth={true}
                          label={label}
                          options={optionsData}
                          idexpr={idExpr}
                          dataexp={dataExpr}
                          placeHolder={defaultValue}
                          useCallFunc
                          disabled={form_disable}
                          selectedValue={(e) => {
                            setState(stateName!, {
                              id: e[idExpr!],
                              name: e[dataExpr!],
                            });
                          }}
                        />
                      </>
                    );
                  }
                )}
              </>

              <Form.Item
                label={
                  <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                    {"Quantity/UoM"}
                  </p>
                }
                className="mb-0.5"
              >
                <li className="w-full flex items-center flex-row  ">
                  <div className="w-full flex ">
                    <Input
                      style={{ width: "26%" }}
                      value={states.quan1}
                      onChange={(e) => {
                        setState("quan1", e.target.value);
                      }}
                      className=" h-6"
                    />
                    <Input
                      style={{ width: "26%" }}
                      value={states.quan2}
                      onChange={(e) => {
                        setState("quan2", e.target.value);
                      }}
                      className="ml-1 h-6 "
                    />
                    <Select
                      className="ml-0.5"
                      style={{ width: "26.5%" }}
                      placeholder="Select an option"
                      onChange={(value: any) => {
                        setState("uom", value);
                      }}
                      value={states.uom}
                    >
                      {uom_data?.map((uom: any, index: number) => {
                        return (
                          <Select.Option key={index} value={uom.funIdpk}>
                            {uom.funShtName}
                          </Select.Option>
                        );
                      })}
                    </Select>

                    <span
                      onClick={() => {
                        setState("uom", "");
                      }}
                      style={{
                        height: 22.5,
                        marginBottom: 2,
                        borderWidth: 1,
                        width: 23.9,
                      }}
                      className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                    >
                      <img
                        className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                        src={reset}
                        alt="reset"
                      />
                    </span>
                  </div>
                </li>
              </Form.Item>

              <Form.Item
                wrapperCol={{ span: 15 }}
                label={
                  <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                    {"Unit Price/Total Amount"}
                  </p>
                }
                className="mb-0.5"
              >
                <li className=" flex items-center flex-row ">
                  <div className="flex w-full">
                    <Input
                      value={states.unit}
                      onChange={(e) => {
                        setState("unit", e.target.value);
                      }}
                      size="small"
                      style={{ width: "40%" }}
                      className=" mr-2"
                    />
                    <Input
                      value={states.total}
                      onChange={(e) => {
                        setState("total", e.target.value);
                      }}
                      size="small"
                      style={{ width: "49%" }}
                      className=" mr-4"
                    />
                  </div>
                </li>
              </Form.Item>

              <Form.Item
                labelCol={{ span: 2 }}
                style={{ width: `calc(265%)`, marginLeft: "7%" }}
                label={
                  <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                    {"Remarks"}
                  </p>
                }
                className="mb-0.5"
              >
                <div className="flex flex-row ">
                  <TextArea
                    style={{
                      resize: "none",
                      height: "80px",
                      width: "calc(100%)",
                    }}
                    className=""
                    readOnly={form_disable}
                    value={states.remarks}
                    onChange={(e) => {
                      setState("remarks", e.target.value);
                    }}
                  />

                  <span className="h-full w-4 ml-1 flex items-center justify-center hover:cursor-pointer hover"></span>
                </div>
              </Form.Item>
            </div>

            <div style={{ width: "39%" }} className="mt-7">
              <Form.Item
                wrapperCol={{ span: 15 }}
                label={
                  <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                    {"Receipt/Coupon No"}
                  </p>
                }
                className="mb-0.5"
              >
                <div className="flex">
                  <Input
                    value={states.coupon}
                    onChange={(e) => {
                      setState("coupon", e.target.value);
                    }}
                    style={{ width: "91%" }}
                  />
                </div>
              </Form.Item>

              <>
                {rightSelects.map(
                  ({
                    id,
                    label,
                    idExpr,
                    dataExpr,
                    optionsData,
                    stateName,
                    defaultValue,
                  }) => {
                    return (
                      <>
                        <SelectsTemplate
                          cusWidth={"80%"}
                          disabledCustomWidth={true}
                          label={label}
                          options={optionsData}
                          idexpr={idExpr}
                          dataexp={dataExpr}
                          placeHolder={defaultValue}
                          useCallFunc
                          disabled={form_disable}
                          selectedValue={(e) => {
                            setState(stateName!, {
                              id: e[idExpr!],
                              name: e[dataExpr!],
                            });
                          }}
                        />
                      </>
                    );
                  }
                )}
              </>

              <Form.Item
                label={
                  <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                    {"Dept/Section/Cost Center"}
                  </p>
                }
                className="mb-0.5 w-full"
              >
                <li className="w-full flex items-center ">
                  <div className="flex w-full ">
                    <Select
                      style={{ width: "26.35%" }}
                      placeholder="Select an option"
                      className="mr-0.5"
                      onChange={(value: any) => {
                        setState("dept", value);
                      }}
                      value={states.dept}
                    >
                      {dept_data?.map((dpt: any, index: number) => {
                        return (
                          <Select.Option key={index} value={dpt.dptIdpk}>
                            {dpt.dptShtName}
                          </Select.Option>
                        );
                      })}
                    </Select>

                    <Select
                      style={{ width: "26.35%" }}
                      placeholder="Select an option"
                      className="mr-0.5"
                      onChange={(value: any) => {
                        setState("sxn", value);
                      }}
                      value={states.sxn}
                    >
                      {sxn_data?.map((sxn: any, index: number) => {
                        return (
                          <Select.Option key={index} value={sxn.sxnIdpk}>
                            {sxn.sxnShtName}
                          </Select.Option>
                        );
                      })}
                    </Select>
                    <Select
                      style={{ width: "26.35%" }}
                      placeholder="Select an option"
                      className=""
                      onChange={(value: any) => {
                        setState("cost", value);
                      }}
                      value={states.cost}
                    >
                      {cost_data?.map((cst: any, index: number) => {
                        return (
                          <Select.Option key={index} value={""}>
                            {""}
                          </Select.Option>
                        );
                      })}
                    </Select>
                    <span
                      onClick={() => {
                        setState("sxn", "");
                        setState("dept", "");
                        setState("cost", "");
                      }}
                      style={{
                        height: 22.5,
                        marginBottom: 2,
                        borderWidth: 1,
                        width: 23.9,
                      }}
                      className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                    >
                      <img
                        className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                        src={reset}
                        alt="reset"
                      />
                    </span>
                  </div>
                </li>
              </Form.Item>

              <>
                {Rightinputs.map(({ id, label, stateName, defaultValue }) => {
                  return (
                    <>
                      <InputsTemplate
                        inputStyle={{ width: "80%" }}
                        label={label}
                        defaultValue={""}
                        useCallbackFunc
                        orderOnchange={(e) => {
                          setState(stateName!, e);
                        }}
                      />
                    </>
                  );
                })}
              </>

              <Form.Item
                label={
                  <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                    {"Active? "}
                  </p>
                }
              >
                <Checkbox
                  checked={states.active}
                  onChange={(e) => {
                    setState("active", e.target.checked);
                  }}
                />
              </Form.Item>
            </div>

            <div style={{ width: "22%" }} className="mt-7 ">
              <div style={{ borderWidth: 1 }} className="p-1">
                <Image
                  width={100}
                  height={100}
                  src="error"
                  alt="No Image data"
                  className=" text-xs"
                />
                <li className=" flex flex-row mt-0.5 w-full ">
                  <Checkbox />
                  <p className="text-xs ml-1">Load Vehicle Image</p>
                </li>
              </div>
            </div>
          </div>

          <div className="w-full  flex flex-col ">
            <div
              style={{ width: "calc(100% - 0.5%)" }}
              className=" flex justify-between  "
            >
              <ul style={{ width: "calc(48% )" }} className="flex ">
                <li style={{ marginLeft: "22%" }}>
                  {" "}
                  <DispenseButton />{" "}
                </li>
              </ul>

              <ul
                style={{ width: "calc(48% )" }}
                className="flex justify-end px-1.5 "
              >
                {form_disable === false && update_state_change === false ? (
                  <SaveButton handleSave={() => {}} />
                ) : update_state_change === true ? (
                  <UpdateButton handleUpdate={() => {}} />
                ) : (
                  <SaveButton />
                )}
                {form_disable === true ? (
                  <NewButton new_button_toggler={() => {}} />
                ) : update_state_change === true ? (
                  <Cancel cancel_button_toggler={() => {}} />
                ) : !(update_state_change && form_disable) ? (
                  <Cancel cancel_button_toggler={() => {}} />
                ) : (
                  <NewButton new_button_toggler={() => {}} />
                )}
              </ul>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};
