import { Switch } from "antd";
import React from "react";

interface props {
  handleOnchange?: (e: any) => void;
  defaultValue?: any;
  customDisabled?: boolean;
}

export const SwitchTemplate = ({
  handleOnchange,
  defaultValue,
  customDisabled = true,
}: props) => {
  return (
    <>
      {
        <div
          style={{
            // borderWidth: "1px",
            display: "inline-block",
            width: "calc(90% )",
            marginLeft: "0%",
          }}
          className=" border-stone-300 pb-0.5 rounded px-2"
        >
          <Switch
            disabled={customDisabled}
            className="w-12 bg-gray-400"
            defaultChecked={defaultValue}
            onChange={handleOnchange}
          />
        </div>
      }
    </>
  );
};
