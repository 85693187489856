/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import { SelectsTemplate } from "../../../../../../../templates/select";
import { CheckboxTemlate } from "../../../../../../../templates/checkbox";
import {
  checkboxTypes,
  selectsTypes,
} from "../../../../../../human-resource/setups/data/types/selectsTypes";
import Datagrid_template from "../../../../../../../templates/Datagrid";
import { Form } from "antd";
import {
  reportSubmitted_table,
  reportUnSubmitted_table,
  returnReport_table,
} from "../data/vehicle-condition-returns-data";
import useFetch from "../../../../../../../hooks/useFetch";
import dayjs from "dayjs";
import { SearchButton } from "../../../../../../accessories/buttons";
import { ModalTemplate } from "../../../../../../../templates/modal";
import {
  currentDate,
  useModalData,
} from "../../../../../../accessories/component_infos";
interface prop {
  date?: { id: number; value: string };
  loc?: { id: number; value: string };
  staff?: { id: number; value: string };
  active: boolean;
  showSect: boolean;
  refresh: boolean;
  lastDayMonthx?: any;
  criteria: { id: number; value: string };
  section: boolean;
  staffNo: boolean;
  staffName: boolean;
}
export const ReturnReport = () => {
  const [formData, setFormData] = useState<prop>({
    date: { id: 0, value: "" },
    loc: { id: 0, value: "" },
    staff: { id: 0, value: "" },
    active: false,
    showSect: false,
    refresh: false,
    lastDayMonthx: "",
    criteria: { id: 0, value: "Submission" },
    section: false,
    staffNo: false,
    staffName: false,
  });

  const section: { id: number; caption: string; action: boolean }[] = [
    { id: 0, caption: "Section", action: formData.section },
  ];

  const general: { id: number; caption: string; action: boolean }[] = [
    { id: 0, caption: "Section", action: true },
    { id: 0, caption: "StaffNo", action: true },
    { id: 0, caption: "StaffName", action: true },
  ];

  const updateState = (stateName: string, value: any) => {
    setFormData((prev: any) => ({
      ...prev,
      [stateName]: value,
    }));
  };
  const [indeterminate, setIndeterminate] = useState<any>(true);
  const [checkedBox, setCheckedBox] = useState<any>(false);
  const [checkValue, setCheckValue] = useState<boolean | null>(null);
  //-----------------1
  const [indeterminate1, setIndeterminate1] = useState<any>(true);
  const [checkedBox1, setCheckedBox1] = useState<any>(false);

  const [selectedDeptSect, setSeletedDeptSect] = useState<any>();

  const [modalData, setModalData] = useModalData();
  const [activeStatus, setActiveStatus] = useState<{
    temp: string | boolean;
    status: string | boolean;
  }>({
    temp: "",
    status: "",
  });
  const [showSection, setShowSection] = useState<{
    temp: string | boolean;
    status: string | boolean;
  }>({
    temp: "",
    status: "",
  });

  const onChange = (value: boolean) => {
    if (indeterminate === true) {
      setCheckedBox(true);
      setIndeterminate(false);
      setCheckValue(true);
    } else if (!indeterminate && checkedBox) {
      setCheckedBox(false);
      setIndeterminate(false);
      setCheckValue(false);
    } else if (!indeterminate && !checkedBox) {
      setCheckedBox(false);
      setIndeterminate(true);
      setCheckValue(null);
    }
  };

  const validation = () => {
    if (formData.date!.value === "") {
      setModalData((current) => ({
        ...current,
        state: true,
        icon: "warning",
        title: "Empty Field",
        message: "Please select Date",
        disableCancel: true,
        okHandler() {
          setModalData((current) => ({
            ...current,
            state: false,
          }));
        },
      }));
    } else {
      if (indeterminate) {
        setActiveStatus({
          temp: "",
          status: "",
        });
      } else {
        setActiveStatus({
          temp: activeStatus.temp,
          status: activeStatus.temp,
        });
      }

      if (indeterminate1) {
        setShowSection({
          temp: "",
          status: "",
        });
      } else {
        setShowSection({
          temp: showSection.temp,
          status: showSection.temp,
        });
      }
    }
  };

  let startDate = dayjs(`${formData.date!.value}-01`, "MMMM YYYY-DD");
  let [dateData, setDateData] = useState<Object[]>([]);

  useEffect(() => {
    const initialDate = dayjs("2017-01-01");
    const endDate = dayjs().subtract(1, "month");
    let dateOptions: any = [];
    let currentDate = endDate;
    let id = 0;

    while (
      currentDate.isAfter(initialDate, "month") ||
      currentDate.isSame(initialDate, "month")
    ) {
      dateOptions.push({ id: id, value: currentDate.format("MMM YYYY") });
      currentDate = currentDate.subtract(1, "month");
      id++;
    }

    setDateData(dateOptions);
  }, []);

  let firstDayMonth = dayjs(formData.date!.value, "MMM YYYY")
    .date(1)
    .format("YYYY-MM-DD");

  let lastDateOfMonth = dayjs(formData.date!.value)
    .endOf("month")
    .format("YYYY-MM-DD");

  //------------------------------------------------------

  //API's -----------------------------------------
  const [deptSection, deptSection_error, deptSection_loading] = useFetch(
    `AssetTamVehicleConditionReturn/PopGdcDeptsSections?active=${
      activeStatus.status
    }&showSection=${
      formData.section
    }&startDate=${`2023-01-01`}&endDate=${currentDate}`,

    formData.refresh
  );

  const popGdvSumbmiiters = [
    { id: 0, value: "Submission" },
    { id: 1, value: "General" },
  ];

  const [submitted, submitted_error, submitted_loading] = useFetch(
    `AssetTamVehicleConditionReturn/PopGdcDeptsSubmittedReturns?vrcDate=${lastDateOfMonth}&dptIdpk=${selectedDeptSect}`,
    formData.refresh
  );

  const [assigned, assigned_error, assigned_lodaing] = useFetch(
    `AssetTamVehicleConditionReturn/PopGdcAssingedVehicles?date=${lastDateOfMonth}`,
    formData.refresh
  );

  const [unSubmitted, unSubmitted_error, unSubmitted_loading] = useFetch(
    `AssetTamVehicleConditionReturn/PopGdcDeptsUnsubmittedReturns?vrcDate=${lastDateOfMonth}&dptIdpk=${selectedDeptSect}`,
    formData.refresh
  );

  const selects: selectsTypes[] = [
    {
      id: 0,
      label: "",
      idExpr: "id",
      dataExpr: "value",
      optionsData: popGdvSumbmiiters,
      defaultValue: formData.criteria.value,
      stateName: "criteria",
      selectedValue: (e: any) => {
        updateState("criteria", {
          id: e.id,
          value: e.value,
        });
        if (e.id === 1) {
          updateState("section", true);
        }
      },
      style: { width: 200 },
    },
    {
      id: 1,
      label: "Date",
      idExpr: "id",
      dataExpr: "value",
      optionsData: dateData,
      defaultValue: formData.date?.value,
      stateName: "date",
      style: { width: 200 },
      selectedValue: (e) => {
        updateState("date", {
          id: e.id,
          value: e.value,
        });
      },
    },
    {
      id: 2,
      label: "Loc.",
      idExpr: "",
      dataExpr: "",
      optionsData: [],
      defaultValue: "",
      stateName: "",
      style: { width: 200 },
    },
  ];

  const checkboxes: checkboxTypes[] = [
    {
      id: 0,
      label: "Active?",
      defaultValue: checkedBox,
      setCheckboxOnchange: (e: any) => {
        updateState("active", e);
        onChange(e);

        setActiveStatus({
          temp: e,
          status: activeStatus.status,
        });
      },
      indeterminate: indeterminate,
    },
    {
      id: 1,
      label: "Show Sections?",
      defaultValue: checkedBox1,
      setCheckboxOnchange: (e: any) => {
        if (formData.criteria.id === 0) {
          updateState("showSect", e);
          setCheckedBox1(e);
          updateState("section", e);
          setShowSection({
            temp: e,
            status: showSection.status,
          });
        }
      },
    },
  ];

  return (
    <div className="w-full h-full">
      <div className="w-full h-full flex justify-between">
        <div
          className={` ${
            formData.criteria.value === popGdvSumbmiiters[1].value
              ? " w-[45%] h-full px-2"
              : "w-1/2 h-full"
          }`}
        >
          <div>
            <ModalTemplate
              icon={modalData.icon}
              disableCancel={modalData.disableCancel}
              cancelText={modalData.cancelText}
              open={modalData.state}
              okHandler={modalData.okHandler}
              cancelHandler={modalData.cancelHandler}
              message={modalData.message}
              okText={modalData.okText}
              title={modalData.title}
            />
            <Form
              name="gridForm"
              labelCol={{ span: 7 }}
              size={"small"}
              className="w-full flex"
            >
              <section className="w-full flex justify-evenly">
                {selects.map(
                  ({
                    id,
                    label,
                    dataExpr,
                    idExpr,
                    optionsData,
                    defaultValue,
                    style,
                    selectedValue,
                  }) => {
                    return (
                      <div className="">
                        {
                          <div>
                            <SelectsTemplate
                              style={style}
                              label={label}
                              idexpr={idExpr}
                              dataexp={dataExpr}
                              options={optionsData}
                              placeHolder={defaultValue}
                              useCallFunc
                              selectedValue={selectedValue}
                              handleRefresh={() => {}}
                              disabled={false}
                            />
                          </div>
                        }
                      </div>
                    );
                  }
                )}

                <div className="flex-wrap justify-between space-y-0.5">
                  {checkboxes.map(
                    ({
                      id,
                      label,
                      defaultValue,
                      setCheckboxOnchange,
                      indeterminate,
                    }) => {
                      return (
                        <div>
                          {id == 1 ? (
                            <>
                              <CheckboxTemlate
                                indeterminate={false}
                                labelCol={19}
                                useCallbackFunc={true}
                                customDisabled={false}
                                withBorders={true}
                                label={label}
                                defaultValue={defaultValue}
                                setCheckboxOnchange={setCheckboxOnchange}
                              />
                            </>
                          ) : (
                            <div>
                              <CheckboxTemlate
                                indeterminate={indeterminate}
                                labelCol={19}
                                // span={true}
                                useCallbackFunc={true}
                                customDisabled={false}
                                withBorders={true}
                                label={label}
                                defaultValue={defaultValue}
                                setCheckboxOnchange={setCheckboxOnchange}
                              />
                            </div>
                          )}
                        </div>
                      );
                    }
                  )}
                  <div className="w-full flex justify-end">
                    <SearchButton
                      handleOnClick={() => {
                        validation();
                      }}
                    />
                  </div>
                </div>
              </section>
            </Form>
          </div>

          <div className="w-full h-full">
            <Datagrid_template
              loading={deptSection_loading}
              gridheight={600}
              visibleCol={formData.criteria.id === 1 ? general : section}
              columns={returnReport_table}
              disableSearch={true}
              data={deptSection}
              rowDoubleClicked={(e: any) => {}}
              onRowClick={(e) => {
                if (e === undefined) {
                  setSeletedDeptSect("");
                } else {
                  setSeletedDeptSect(e?.dptIDpk);
                }
              }}
            />
          </div>
        </div>

        <div
          className={` ${
            formData.criteria.value === popGdvSumbmiiters[1].value
              ? " w-[55%] h-full flex space-x-2"
              : "w-1/2 h-full"
          }`}
        >
          {formData.criteria.value === popGdvSumbmiiters[1].value && (
            <div className="w-1/2">
              <p className="w-full py-0.5 px-0.5 bg-slate-50">
                {"Assigned Vehicles"}
              </p>
              <Datagrid_template
                loading={submitted_loading}
                gridheight={645}
                columns={reportSubmitted_table}
                data={assigned}
                rowDoubleClicked={() => {}}
                onRowClick={() => {}}
              />
            </div>
          )}

          <div
            className={` ${
              formData.criteria.value === popGdvSumbmiiters[1].value
                ? " w-[50%] h-full  space-x-2"
                : "w-full px-2  h-full"
            }`}
          >
            <div className="w-full  h-1/2 ">
              <p className="w-full py-0.5 bg-slate-50 px-0.5">
                {"Submitted Returns"}
              </p>
              <Datagrid_template
                loading={submitted_loading}
                gridheight={300}
                columns={reportSubmitted_table}
                data={submitted}
                rowDoubleClicked={() => {}}
                onRowClick={() => {}}
              />
            </div>

            <div className="w-full h-1/2 ">
              <p className="w-full ">{"Unsubmitted Returns"}</p>
              <Datagrid_template
                loading={unSubmitted_loading}
                gridheight={300}
                columns={reportUnSubmitted_table}
                data={unSubmitted}
                rowDoubleClicked={() => {}}
                onRowClick={() => {}}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
