import { useEffect, useState } from "react";
import { AiFillPrinter } from "react-icons/ai";
import { HiRefresh } from "react-icons/hi";
import "devextreme/dist/css/dx.light.css";
import { CheckBox } from "devextreme-react/check-box";
import { Tooltip } from "devextreme-react";
import find from "../../../../../../../assets/binoculars.png";
import question from "../../../../../../../assets/question.png";

import reset from "../../../../../../../assets/refresh-small.png";
import printer from "../../../../../../../assets/printer.png";
import email from "../../../../../../../assets/email.png";

import { Checkbox, DatePicker, Input, Select } from "antd";
import {
  DeleteTask,
  TaskSettings,
  AlertSMS,
  AppraiseSettings,
} from "../../../../../../accessories/custom-toolbars/widgets/customToolbarModals";
import { FaBinoculars } from "react-icons/fa";
import { SelectsTemplate } from "../../../../../../../templates/select";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import dayjs from "dayjs";
import { searchTextTypes } from "../../../../../../human-resource/setups/data/types/selectsTypes";
import { saveModalData } from "../../../../../../accessories/component_infos";
import { ModalTemplate } from "../../../../../../../templates/modal";
interface props {
  toggler?: () => void;
  handleDelete?: () => void; //delete handler
  handleNew?: (value: boolean) => void;
  handleSave?: () => void; //save handler
  handleUpdate?: () => void; //update handler
  searchTextOnchange?: (value: string) => void; //search text change
  startDateChanged?: (value: any) => void; //start date change
  endDateChanged?: (value: any) => void; //end date change
  checkOnChange?: (value: any) => void; //checkbox change
  handleFind?: () => void; //find handler
  handleRefresh?: () => void; //refresh main grid
  handlePrint?: () => void; // handle print functionality
  setrefreshx?: () => void;
  searchCriteriaOnchange?: (index: number, criteria: string) => void;
  searchCriteriaData?: { id: number; value: string }[];
  searchCriteria?: boolean;
  useCallBackFunc?: boolean;
  withDates?: boolean; //add dates to the toolbar?
  toggleNew?: boolean;
  toggleUpdate?: boolean;
  handleEdit?: (value: boolean) => void;
  hide?: boolean;
  startDate?: string;
  endDate?: string;
  id?: string;
  tooltip_ids: any[];
  sendSms?: () => void;
}

export const SmsAlertsToolbar = ({
  toggler,
  handleRefresh,
  handlePrint,
  handleFind,
  checkOnChange,
  sendSms,
  hide,
  startDateChanged,
  endDateChanged,
  searchTextOnchange,
  searchCriteriaOnchange,
  setrefreshx,
  searchCriteriaData = [],
  startDate,
  searchCriteria,
  endDate,
  tooltip_ids,
  id,
}: props) => {
  const { Option } = Select;

  const { Search } = Input;

  const dateFormat = " DD MMM YYYY";
  // Three state checkboxes
  const [indeterminate, setIndeterminate] = useState<any>(true);
  const [checkedBox, setCheckedBox] = useState<any>(false);
  const [checkValue, setCheckValue] = useState<boolean | null>(null); //actual value to use
  const [searchTextValue, setSearchTextValue] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });

  const [criteriaDataLocal, setCriterialDataLocal] = useState({
    id: searchCriteriaData![0]?.id ?? 0,
    value: searchCriteriaData![0]?.value ?? "",
  });

  useEffect(() => {
    checkOnChange && checkOnChange(checkValue);
  }, [checkValue]);

  const onChange = (value: boolean) => {
    if (indeterminate === true) {
      setCheckedBox(true);
      setIndeterminate(false);
      setCheckValue(true);
    } else if (!indeterminate && checkedBox) {
      setCheckedBox(false);
      setIndeterminate(false);
      setCheckValue(false);
    } else if (!indeterminate && !checkedBox) {
      setCheckedBox(false);
      setIndeterminate(true);
      setCheckValue(null);
    }
  };

  const [switchiconMsg, setSwitchiconMsg] = useState<string>("Personal");
  const [isActive, setIsActive] = useState<boolean>(false);
  const [SettingsisActive, SettingssetIsActive] = useState<boolean>(false);
  const [AlertSMSisActive, AlertSMSsetIsActive] = useState<boolean>(false);
  const [AppraiseisActive, AppraisesetIsActive] = useState<boolean>(false);
  const size: number = 16;
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const [modalInfo, setModalInfo] = useState<any>({
    icon: "question",
    data: saveModalData,
    disableCancel: false,
    openModal: false,
  });
  const [openModal, setOpenModal] = useState<any>(false);

 
  const [refreshValue, setrefreshValue] = useState<boolean>(false);
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };
  function callFunction() {
    setModalInfo({
      icon: "question",
      data: {
        message: "Are you sure you want send message to this Personnel?",
        title: "Send Massage",
        okText: "Send",
        cancelText: "No,cancel",
      },
      disableCancel: false,
      openModal: true,
    });
  }

  return (
    <>
      <ModalTemplate
        icon={modalInfo.icon}
        disableCancel={modalInfo.disableCancel}
        cancelText={modalInfo.data.cancelText}
        open={modalInfo.openModal}
        okHandler={() => {
          modalInfo.data.title === "Send Massage"
            ? sendSms && sendSms()
            : setModalInfo({ ...modalInfo, openModal: false });
            setModalInfo({ ...modalInfo, openModal: false });
        }}
        cancelHandler={() => {
          setModalInfo({ ...modalInfo, openModal: false });
        }}
        message={modalInfo.data.message}
        okText={modalInfo.data.okText}
        title={modalInfo.data.title}
      />

      <div
        style={{ borderBottomWidth: "1px", borderColor: borderTheme }}
        className="w-full h-full flex border-[1px] px-1 round border-l-0 border-r-0 border-gray-200 mb-1"
      >
        <div className="flex justify-center px-2">
          <ul className="flex justify-evenly w-full items-center h-full py-0.5">
            {!hide && (
              <li
                id="email"
                style={{ borderWidth: "1px", borderColor: borderTheme }}
                className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center"
                onClick={() =>{callFunction()}}
              >
                <span className="flex">
                  {" "}
                  <img
                    className="dark:bg-slate-900  dark:text-gray-100 duration-100"
                    src={email}
                  />{" "}
                </span>
                <Tooltip
                  target="#help"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p>Email</p>
                </Tooltip>
              </li>
            )}
            <li
              id={tooltip_ids[3]}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center"
            >
              <span className="flex">
                {" "}
                <img
                  className="dark:bg-slate-900  dark:text-gray-100 duration-100"
                  src={question}
                />{" "}
              </span>
              <Tooltip
                target={`#${tooltip_ids[3]}`}
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p>Help Center</p>
              </Tooltip>
            </li>

            <li className="px-1 text-xs">Search Criteria</li>
            <li className="items-right  ">
              <Select
                value={criteriaDataLocal}
                dropdownMatchSelectWidth={false}
                className=" mt-0.5"
                onChange={(data: any) => {
                  const criteriaData = JSON.parse(data);
                  setCriterialDataLocal({
                    id: criteriaData?.index,
                    value: criteriaData?.criteria,
                  }); //local
                  searchCriteriaOnchange &&
                    searchCriteriaOnchange(
                      criteriaData?.index,
                      criteriaData?.criteria
                    ); //external
                }}
                id="search"
                size="small"
                showSearch={true}
                style={{ width: 150, height: "25px" }}
              >
                {searchCriteriaData?.map(({ id, value }, index) => (
                  <Select.Option
                    key={index}
                    value={JSON.stringify({ index: index, criteria: value })}
                  >
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </li>
          </ul>
        </div>

        <div className="flex items-center">
          <div className="flex items-center">
            <ul className="flex items-center">
              <li className="items-right mt-0.5 "></li>
              <li className="px-1 text-xs">Search Text</li>

              <li className="px-1">
                <Input
                  onChange={(e: any) => {
                    const searchText: string = e.target.value;
                    searchTextOnchange && searchTextOnchange(searchText);
                    setSearchTextValue(e.target.value);
                  }}
                  value={searchTextValue.text}
                  name="search"
                  id="search"
                  size="small"
                  allowClear={false}
                  style={{ width: 130, height: "24px" }}
                />
              </li>
              <li className="px-0.5">
                <DatePicker
                  value={dayjs(startDate) ?? dayjs()}
                  clearIcon={<span style={{ display: "none" }}></span>}
                  onChange={startDateChanged}
                  size="small"
                  placeholder="Start Date"
                  style={{ width: 125 }}
                  format={"DD MMM YYYY"}
                />
              </li>
              <li className="px-0.5">
                <DatePicker
                  value={dayjs(endDate) ?? dayjs()}
                  clearIcon={<span style={{ display: "none" }}></span>}
                  onChange={endDateChanged}
                  size="small"
                  placeholder="End Date"
                  style={{ width: 125 }}
                  format={"DD MMM YYYY"}
                />
              </li>
              <li
                style={{
                  borderWidth: "1px",
                  paddingTop: 1,
                  paddingBottom: 1,
                  paddingLeft: 5,
                  paddingRight: 5,
                  borderColor: borderTheme,
                }}
                id="loadAll"
                className="  border-slate-200  hover:cursor-pointer mr-1  text-xs px-1 text-gray-600 rounded flex items-center"
              >
                <Checkbox
                  checked={checkedBox}
                  indeterminate={indeterminate}
                  onChange={(checkValue: any) => {
                    onChange(checkValue);
                  }}
                />
                <Tooltip
                  target="#status"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">Show Status</p>
                </Tooltip>
              </li>
              <li
                onClick={() => {
                  handleFind && handleFind();
                }}
                id={tooltip_ids[0]}
                style={{ borderWidth: "1px", borderColor: borderTheme }}
                className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
              >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
                  <FaBinoculars size={15.5} color="#007AFF" />
                </span>
                <Tooltip
                  target={`#${tooltip_ids[0]}`}
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">Find</p>
                </Tooltip>
              </li>

              {/* refresh */}
              <li
                onClick={() => {
                  setrefreshx && setrefreshx();
                  handleRefresh && handleRefresh();
                  searchTextOnchange && searchTextOnchange("");
                  searchCriteriaOnchange &&
                    searchCriteriaOnchange(
                      searchCriteriaData![0]?.id ?? 0,
                      searchCriteriaData![0]?.value ?? ""
                    );
                  checkOnChange && checkOnChange("");

                  //setlocal states
                  setSearchTextValue((prev) => ({
                    ...prev,
                    temp: "",
                    text: "",
                  }));
                  setCheckedBox(false);
                  setIndeterminate(true);
                  // setCheckValue("");
                  setCriterialDataLocal({
                    id: searchCriteriaData![0]?.id ?? 0,
                    value: searchCriteriaData![0]?.value ?? "",
                  });
                }}
                id={tooltip_ids[1]}
                style={{ borderWidth: "1px", borderColor: borderTheme }}
                className="hover:cursor-pointer mr-1.5 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
              >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
                  {" "}
                  <img
                    className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                    src={reset}
                  />
                </span>
                <Tooltip
                  target={`#${tooltip_ids[1]}`}
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">Refresh</p>
                </Tooltip>
              </li>

              {/* <li
                onClick={() => handlePrint && handlePrint()}
                id={tooltip_ids[2]}
                style={{ borderWidth: "1px", borderColor: borderTheme }}
                className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
              >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
                  {" "}
                  <img
                    className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                    src={printer}
                  />
                </span>
                <Tooltip
                  target={`#${tooltip_ids[2]}`}
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">Print/Export</p>
                </Tooltip>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
