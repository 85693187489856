/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from 'react'
import { RefreshButton } from '../../../../StationReadings/_widget/utils'
import Datagrid_template from '../../../../../../../templates/Datagrid'
import axios from 'axios'
import { api_base_url } from '../../../../../../accessories/component_infos'
import useFetch from '../../../../../../../hooks/useFetch'

const StaffTelDirectory_columns = [
  { id: 1, caption: 'STAFF NO', dataField: 'empStaffNo', dataType: 'string', width: 100, fixed: true, visible: true },
  { id: 2, caption: 'NAME', dataField: 'empName', dataType: 'string', width: 100, fixed: false, visible: true },
  { id: 3, caption: 'DEPT.', dataField: 'dptShtName', dataType: 'string', width: 100, fixed: false, visible: true },
  { id: 4, caption: 'SECTION', dataField: 'sxnShtName', dataType: 'string', width: 100, fixed: false, visible: true },
  { id: 5, caption: 'LOCATION', dataField: 'locShtName', dataType: 'string', width: 100, fixed: false, visible: true },
  { id: 6, caption: 'JOB TITLE', dataField: 'jbtShtName', dataType: 'string', width: 140, fixed: false, visible: true },
  { id: 7, caption: 'MOBILE NO.', dataField: 'empMobNox', dataType: 'string', width: 140, fixed: false, visible: true },
  { id: 8, caption: 'INTERCOM', dataField: 'empIntercom', dataType: 'string', width: 100, fixed: false, visible: true },
  { id: 9, caption: 'ISSTAFF?', dataField: 'empIsStaff', dataType: 'boolean', width: 100, fixed: false, visible: true },
]

export default function StaffTelDirectory({ height }: { height: number }) {

  const[refresh, setRefresh] = useState(false);
  const [data, error, loading] = useFetch(`ELogBook/PopGdvTelDir`, refresh, '', false, "powerapp");

  return (
    <main>
      <div className="w-full h-auto border-[1px] pr-1 flex justify-end">
        <RefreshButton onClick={()=>{
          setRefresh(current => !current)
        }}/>
      </div>

      <section>
        <div className="">
          {error && (
             <div className=" flex h-full justify-center items-center mt-10 mb-10">
              <p className=' font-bold text-red-800'>Error Fetching Data. Plase Refresh</p>
            </div>
          )}

          {loading && (
            <div className=" flex h-full justify-center items-center mt-10 mb-4">
              <p className=' font-bold text-gray-800'>Fetching Data...</p>
            </div>
          )}

          {(!loading && !error) && (
            <Datagrid_template
            columns={StaffTelDirectory_columns} 
            data={data}
            disablePaging
            disableGroupPanel
            gridheight={height}
            />
          )}
        </div>
      </section>
    </main>
  )
}
