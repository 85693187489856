import { Select, Form, Input, } from "antd";
import { Column, DataGrid, GroupPanel, Pager, Scrolling, SearchPanel } from "devextreme-react/data-grid";
import { useEffect, useReducer, useState } from "react";
import save from '../../../../../assets/floppy-disk.png';

import { CheckboxTemlate } from "../../../../../templates/checkbox";
import { SelectsTemplate } from "../../../../../templates/select";
import { navbar_height, toolbar_height } from "../../../../accessories/component_infos";
import { block_house_data, block_house_TH } from "../data/staff_classification_data";
import { FaBinoculars } from "react-icons/fa";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import { formPropsConst } from "../../../../accessories/constants";



interface props {
   toggled?: boolean
   departmentList?: any
   locationList?: any
   sectionList?: any
   unitList?: any
   dataTable?: any
}

function reducer(state: any, action: any) {
   switch (action.type) {
      case 'change_display': {
         return {

            detailsPreview: action.newDisplay,
            selectedValue: state.selectedValue

         };
      }
      case 'set_data': {
         console.log(action.newValue.empIsStaff)
         return {

            detailsPreview: state.detailsPreview,
            selectedValue: action.newValue,
            staff: action.newValue.empIsStaff

         };
      }


      default: {
         return {

            detailsPreview: state.detailsPreview
         }
      }
   }
   throw Error('Unknown action: ' + action.type);
}

const StaffClassificationsDetails = ({ toggled, departmentList, locationList, sectionList, unitList, dataTable }: props) => {

   const news_flash_height = useSelector((state: RootState) => state.general.newsflashHeight);
   const initialState = { selectedValue: {}, detailsPreview: 'disabledMode', staff: false };
   const previewMode = {}
   const [state, dispatch] = useReducer(reducer, initialState);


   const heights_out = navbar_height + news_flash_height + toolbar_height;

   //varying form height

   //Table Height
   const [tableHeight, settableHeight] = useState(window.innerHeight - (heights_out + 70)); //height without navbar and toolbar
   const [table_form_Height, settable_form_Height] = useState(window.innerHeight - (heights_out)); //height without navbar and toolbar and form

   const [showTable, setShowTable] = useState(true);

   useEffect(() => {
      console.log(`inside ${toggled}`)
   }, [toggled])

   //Manage state here




   return (<div>
      <style
         dangerouslySetInnerHTML={{
            __html: `
           input[type="text"]:disabled {
            background: #fffbeb;
            height:22px
          }  

          
          
          .dx-datagrid-header-panel .dx-toolbar {
            margin-bottom: 1.5px;
        }

          .filterTodos .dx-datagrid-headers{
              margin-top: 10px;
          }
          :where(.css-dev-only-do-not-override-1ij74fp).ant-form-item .ant-form-item-control-input {
            position: relative;
            display: flex;
            align-items: center;
            min-height: 24px;
        }

            `
         }}
      />

      {toggled && <>
         <div className="bg-slate-50  px-1 border rounded-sm">
            <div>Staff Org Unit Details</div>
            <div className="w-full    rounded-md">
               <div className="pt-0.3 flex">
                  <div className="bg-slate-50  px-1 border rounded-sm" style={{ width: '49.8%' }}>
                     <div className="flex  justify-end">
                        <div className="mx-1">
                           <CheckboxTemlate withBorders={true} customDisabled={state.detailsPreview === 'disabledMode' ? true : false} placeholder="Active?" />
                        </div>
                        <div className="mx-1">
                           <CheckboxTemlate withBorders={true} customDisabled={state.detailsPreview === 'disabledMode' ? true : false} placeholder="Staff?" />
                        </div>
                        <div className="" style={{ marginBottom: 0, paddingTop: '0.45%', width: '10%' }}>
                           <Input style={{ height: '24px', paddingTop: '0px' }} className="bg-yellow-100 text-xs" />

                        </div>
                        <div className="" style={{ marginBottom: 0, paddingTop: '0.45%', }}>

                           <li id="find" style={{ borderWidth: '1px' }} className="hover:cursor-pointer ml-1 border-slate-200 text-xs px-2 py-1 text-gray-600 rounded flex items-center">
                              <span> <FaBinoculars size={15.5} color="#007AFF" /> </span>

                           </li>
                        </div>




                     </div>

                     <DataGrid
                        dataSource={block_house_data}
                        showBorders={true}
                        columnWidth={150}
                        showRowLines={true}
                        style={{}}
                        showColumnLines={true}
                        //Remember to change
                        height={toggled === true ? 310 : tableHeight}
                        width={'100%'}

                     >

                        <Pager visible={false} />


                        {/* STAFF DETAILS COLUMNS */}
                        {/* FIXED COLUMNS */}

                        {
                           block_house_TH.map((data) => {
                              return (
                                 <Column
                                    key={data.id}
                                    caption={data.caption}
                                    dataField={data.dataField}
                                    alignment={"left"}
                                    fixed={data.fixed}

                                    width={data.width === null ? 150 : data.width}
                                 />
                              )
                           })
                        }

                        {/* SCROLLBAR  */}

                        <Scrolling columnRenderingMode="virtual" />
                     </DataGrid>
                  </div>
                  <div style={{ width: '0.5%' }}>

                  </div>

                  <div className=" w-1/2 bg-slate-50 flex  px-1 border rounded-sm">

                     {/* <div className="py-1"><DateTemplate width='30%'  label="Date"/></div> */}
                     <div className="my-0.5" style={{ width: 'calc(22% )' }}>
                        <Form.Item style={{ marginBottom: 0, }}>

                           <Form.Item
                              labelCol={{ span: 10 }}

                              name="month"
                              style={{ display: 'inline-block', width: 'calc(100% )', marginLeft: '3%' }}
                           >
                              <div style={{ borderWidth: '1px' }} className="flex border-stone-300 rounded px-0.5  mb-1" >
                                 <CheckboxTemlate customDisabled={state.detailsPreview === 'disabledMode' ? true : false} placeholder="Active?" tableCheckboxValue={state.selectedValue.empIsActive} />

                              </div>
                           </Form.Item>
                           <Form.Item
                              labelCol={{ span: 10 }}

                              name="month"
                              style={{ display: 'inline-block', width: 'calc(100% )', marginLeft: '3%' }}
                           >
                              <div style={{ borderWidth: '1px' }} className="flex border-stone-300 rounded px-0.5  mb-1" >
                                 <CheckboxTemlate customDisabled={state.detailsPreview === 'disabledMode' ? true : false} placeholder="Staff?" tableCheckboxValue={state.staff} />
                              </div>
                           </Form.Item>
                           <Form.Item
                              labelCol={{ span: 10 }}

                              name="month"
                              style={{ display: 'inline-block', width: 'calc(100% )', marginLeft: '3%' }}
                           >
                              <div style={{ borderWidth: '1px' }} className="flex border-stone-300 rounded px-0.5  mb-1" >
                                 <CheckboxTemlate customDisabled={state.detailsPreview === 'disabledMode' ? true : false} placeholder="Supervisor?" />
                              </div>
                           </Form.Item>
                           <Form.Item
                              labelCol={{ span: 10 }}

                              name="month"
                              style={{ display: 'inline-block', width: 'calc(100% )', marginLeft: '3%' }}
                           >
                              <div style={{ borderWidth: '1px' }} className="flex border-stone-300 rounded px-0.5  mb-1" >
                                 <CheckboxTemlate customDisabled={state.detailsPreview === 'disabledMode' ? true : false} placeholder="Manager?" />
                              </div>
                           </Form.Item>
                           <Form.Item
                              labelCol={{ span: 10 }}

                              name="month"
                              style={{ display: 'inline-block', width: 'calc(100% )', marginLeft: '3%' }}
                           >
                              <div style={{ borderWidth: '1px' }} className="flex border-stone-300 rounded px-0.5  mb-1" >
                                 <CheckboxTemlate customDisabled={state.detailsPreview === 'disabledMode' ? true : false} placeholder="Director?" />
                              </div>
                           </Form.Item>
                           <Form.Item
                              labelCol={{ span: 10 }}

                              name="month"
                              style={{ display: 'inline-block', width: 'calc(100% )', marginLeft: '3%' }}
                           >
                              <div style={{ borderWidth: '1px' }} className="flex border-stone-300 rounded px-0.5  mb-1" >
                                 <CheckboxTemlate customDisabled={state.detailsPreview === 'disabledMode' ? true : false} placeholder="CEO?" />
                              </div>
                           </Form.Item>
                           <Form.Item
                              labelCol={{ span: 10 }}

                              name="month"
                              style={{ display: 'inline-block', width: 'calc(100% )', marginLeft: '3%' }}
                           >
                              <div style={{ borderWidth: '1px' }} className="flex border-stone-300 rounded px-0.5  mb-1" >
                                 <CheckboxTemlate customDisabled={state.detailsPreview === 'disabledMode' ? true : false} placeholder="Senior Staff?" />
                              </div>
                           </Form.Item>
                           <Form.Item
                              labelCol={{ span: 10 }}

                              name="month"
                              style={{ display: 'inline-block', width: 'calc(100% )', marginLeft: '3%' }}
                           >
                              <div style={{ borderWidth: '1px' }} className="flex border-stone-300 rounded px-0.5  mb-1" >
                                 <CheckboxTemlate customDisabled={state.detailsPreview === 'disabledMode' ? true : false} placeholder="Junior Staff?" />
                              </div>
                           </Form.Item>
                           <Form.Item
                              labelCol={{ span: 10 }}

                              name="month"
                              style={{ display: 'inline-block', width: 'calc(100% )', marginLeft: '3%' }}
                           >
                              <div style={{ borderWidth: '1px' }} className="flex border-stone-300 rounded px-0.5  mb-1" >
                                 <CheckboxTemlate customDisabled={state.detailsPreview === 'disabledMode' ? true : false} placeholder="Management Staff?" />
                              </div>
                           </Form.Item>
                        </Form.Item>

                     </div>

                     <div className="my-0.5 ml-2" style={{ width: 'calc(22% )' }}>
                        <Form.Item style={{ marginBottom: 0, }}>

                           <Form.Item
                              labelCol={{ span: 10 }}

                              name="month"
                              style={{ display: 'inline-block', width: 'calc(100% )', marginLeft: '3%' }}
                           >
                              <div style={{ borderWidth: '1px' }} className="flex border-stone-300 rounded px-0.5  mb-1" >
                                 <CheckboxTemlate customDisabled={state.detailsPreview === 'disabledMode' ? true : false} placeholder="Board Member?" />
                              </div>
                           </Form.Item>
                           <Form.Item
                              labelCol={{ span: 10 }}

                              name="month"
                              style={{ display: 'inline-block', width: 'calc(100% )', marginLeft: '3%' }}
                           >
                              <div style={{ borderWidth: '1px' }} className="flex border-stone-300 rounded px-0.5  mb-1" >
                                 <CheckboxTemlate customDisabled={state.detailsPreview === 'disabledMode' ? true : false} placeholder="Org Admin" />
                              </div>
                           </Form.Item>
                           <Form.Item
                              labelCol={{ span: 10 }}

                              name="month"
                              style={{ display: 'inline-block', width: 'calc(100% )', marginLeft: '3%' }}
                           >
                              <div style={{ borderWidth: '1px' }} className="flex border-stone-300 rounded px-0.5  mb-1" >
                                 <CheckboxTemlate customDisabled={state.detailsPreview === 'disabledMode' ? true : false} placeholder="Dept Administrator?" />
                              </div>
                           </Form.Item>
                           <Form.Item
                              labelCol={{ span: 10 }}

                              name="month"
                              style={{ display: 'inline-block', width: 'calc(100% )', marginLeft: '3%' }}
                           >
                              <div style={{ borderWidth: '1px' }} className="flex border-stone-300 rounded px-0.5  mb-1" >
                                 <CheckboxTemlate customDisabled={state.detailsPreview === 'disabledMode' ? true : false} placeholder="Dept Secretary?" />
                              </div>
                           </Form.Item>
                           <Form.Item
                              labelCol={{ span: 10 }}

                              name="month"
                              style={{ display: 'inline-block', width: 'calc(100% )', marginLeft: '3%' }}
                           >
                              <div style={{ borderWidth: '1px' }} className="flex border-stone-300 rounded px-0.5  mb-1" >
                                 <CheckboxTemlate customDisabled={state.detailsPreview === 'disabledMode' ? true : false} placeholder="Sectional Admin" />
                              </div>
                           </Form.Item>
                           <Form.Item
                              labelCol={{ span: 10 }}

                              name="month"
                              style={{ display: 'inline-block', width: 'calc(100% )', marginLeft: '3%' }}
                           >
                              <div style={{ borderWidth: '1px' }} className="flex border-stone-300 rounded px-0.5  mb-1" >
                                 <CheckboxTemlate customDisabled={state.detailsPreview === 'disabledMode' ? true : false} placeholder="Unit Admin" />

                              </div>
                           </Form.Item>
                           <Form.Item
                              labelCol={{ span: 10 }}

                              name="month"
                              style={{ display: 'inline-block', width: 'calc(100% )', marginLeft: '3%' }}
                           >
                              <div style={{ borderWidth: '1px' }} className="flex border-stone-300 rounded px-0.5  mb-1" >
                                 <CheckboxTemlate customDisabled={state.detailsPreview === 'disabledMode' ? true : false} placeholder="Sectional Secretary?" />
                              </div>
                           </Form.Item>
                           <Form.Item
                              labelCol={{ span: 10 }}

                              name="month"
                              style={{ display: 'inline-block', width: 'calc(100% )', marginLeft: '3%' }}
                           >
                              <div style={{ borderWidth: '1px' }} className="flex border-stone-300 rounded px-0.5  mb-1" >
                                 <CheckboxTemlate customDisabled={state.detailsPreview === 'disabledMode' ? true : false} placeholder="Technical Staff?" />
                              </div>
                           </Form.Item>
                           <Form.Item
                              labelCol={{ span: 10 }}

                              name="month"
                              style={{ display: 'inline-block', width: 'calc(100% )', marginLeft: '3%' }}
                           >
                              <div style={{ borderWidth: '1px' }} className="flex border-stone-300 rounded px-0.5  mb-1" >
                                 <CheckboxTemlate customDisabled={state.detailsPreview === 'disabledMode' ? true : false} placeholder="Core Staff?" />
                              </div>
                           </Form.Item>
                        </Form.Item>

                     </div>

                     <div className="my-0.5 ml-2" style={{ width: 'calc(22% )' }}>
                        <Form.Item style={{ marginBottom: 0, }}>

                           <Form.Item
                              labelCol={{ span: 10 }}

                              name="month"
                              style={{ display: 'inline-block', width: 'calc(100% )', marginLeft: '3%' }}
                           >
                              <div style={{ borderWidth: '1px' }} className="flex border-stone-300 rounded px-0.5  mb-1" >
                                 <CheckboxTemlate customDisabled={state.detailsPreview === 'disabledMode' ? true : false} placeholder="Retiree?" />
                              </div>
                           </Form.Item>
                           <Form.Item
                              labelCol={{ span: 10 }}

                              name="month"
                              style={{ display: 'inline-block', width: 'calc(100% )', marginLeft: '3%' }}
                           >
                              <div style={{ borderWidth: '1px' }} className="flex border-stone-300 rounded px-0.5  mb-1" >
                                 <CheckboxTemlate customDisabled={state.detailsPreview === 'disabledMode' ? true : false} placeholder="Voluntary?" />
                              </div>
                           </Form.Item>
                           <Form.Item
                              labelCol={{ span: 10 }}

                              name="month"
                              style={{ display: 'inline-block', width: 'calc(100% )', marginLeft: '3%' }}
                           >
                              <div style={{ borderWidth: '1px' }} className="flex border-stone-300 rounded px-0.5  mb-1" >
                                 <CheckboxTemlate customDisabled={state.detailsPreview === 'disabledMode' ? true : false} placeholder="NSP?" />
                              </div>
                           </Form.Item>
                           <Form.Item
                              labelCol={{ span: 10 }}

                              name="month"
                              style={{ display: 'inline-block', width: 'calc(100% )', marginLeft: '3%' }}
                           >
                              <div style={{ borderWidth: '1px' }} className="flex border-stone-300 rounded px-0.5  mb-1" >
                                 <CheckboxTemlate customDisabled={state.detailsPreview === 'disabledMode' ? true : false} placeholder="Driver?" />
                              </div>
                           </Form.Item>
                           <Form.Item
                              labelCol={{ span: 10 }}

                              name="month"
                              style={{ display: 'inline-block', width: 'calc(100% )', marginLeft: '3%' }}
                           >
                              <div style={{ borderWidth: '1px' }} className="flex border-stone-300 rounded px-0.5  mb-1" >
                                 <CheckboxTemlate customDisabled={state.detailsPreview === 'disabledMode' ? true : false} placeholder="Show Birthday" />
                              </div>
                           </Form.Item>
                           <Form.Item
                              labelCol={{ span: 10 }}

                              name="month"
                              style={{ display: 'inline-block', width: 'calc(100% )', marginLeft: '3%' }}
                           >
                              <div style={{ borderWidth: '1px' }} className="flex border-stone-300 rounded px-0.5  mb-1" >
                                 <CheckboxTemlate customDisabled={state.detailsPreview === 'disabledMode' ? true : false} placeholder="Show Work Anniversary" />
                              </div>
                           </Form.Item>
                           <Form.Item
                              labelCol={{ span: 10 }}

                              name="month"
                              style={{ display: 'inline-block', width: 'calc(100% )', marginLeft: '3%' }}
                           >
                              <div style={{ borderWidth: '1px' }} className="flex border-stone-300 rounded px-0.5  mb-1" >
                                 <CheckboxTemlate customDisabled={state.detailsPreview === 'disabledMode' ? true : false} placeholder="Casual Worker?" />
                              </div>
                           </Form.Item>
                           <Form.Item
                              labelCol={{ span: 10 }}

                              name="month"
                              style={{ display: 'inline-block', width: 'calc(100% )', marginLeft: '3%' }}
                           >
                              <div style={{ borderWidth: '1px' }} className="flex border-stone-300 rounded px-0.5  mb-1" >
                                 <CheckboxTemlate customDisabled={state.detailsPreview === 'disabledMode' ? true : false} placeholder="Show In Tel Direectory?" />
                              </div>
                           </Form.Item>

                        </Form.Item>

                     </div>
                     <div style={{ width: '34%' }}>
                        <Form {...formPropsConst} labelCol={{ span: 7 }} wrapperCol={{ span: 18 }}
                           size={"small"} className="ml-2 " style={{ width: '95%' }}
                        >

                           <div className="mt-0.5">
                              <SelectsTemplate selectStyle={{ width: '100%' }} label="Department" selectedValue={(e: any) => { dispatch({ type: 'change_department', newValue: e }); }} options={departmentList} idexpr={'dptIdpk'} dataexp={'dptName'} disabled={state.detailsPreview === 'disabledMode' ? true : false} useCallFunc />
                           </div>
                           <div className="mt-0.5">
                              <SelectsTemplate selectStyle={{ width: '100%' }} label="Section" selectedValue={(e: any) => { dispatch({ type: 'change_section', newValue: e }); }} options={sectionList} idexpr={'sxnIdpk'} dataexp={'sxnName'} disabled={state.detailsPreview === 'disabledMode' ? true : false} useCallFunc />
                           </div>
                           <div className="mt-0.5">
                              <SelectsTemplate selectStyle={{ width: '100%' }} label="Unit" selectedValue={(e: any) => { dispatch({ type: 'change_unit', newValue: e }); }} options={unitList} idexpr={'id'} dataexp={'name'} disabled={state.detailsPreview === 'disabledMode' ? true : false} useCallFunc />
                           </div>
                           <div className="mt-0.5">
                              <SelectsTemplate selectStyle={{ width: '100%' }} label="Location" selectedValue={(e: any) => { dispatch({ type: 'change_location', newValue: e }); }} options={locationList} idexpr={'locIdpk'} dataexp={'locName'} disabled={state.detailsPreview === 'disabledMode' ? true : false} useCallFunc />
                           </div>



                           <div className="mt-0.5">
                              <SelectsTemplate selectStyle={{ width: '100%' }} label="Supervisor" placeHolder={'reasonData'} selectedValue={() => { }} options={[]} idexpr={'nrsIdpk'} dataexp={'nrsName'} />
                           </div>
                           <div className="mt-0.5">
                              <SelectsTemplate selectStyle={{ width: '100%' }} label="Manager" placeHolder={'reasonData'} selectedValue={() => { }} options={[]} idexpr={'nrsIdpk'} dataexp={'nrsName'} />
                           </div>
                           <div className="mt-0.5">
                              <SelectsTemplate selectStyle={{ width: '100%' }} label="Director" placeHolder={'reasonData'} selectedValue={() => { }} options={[]} idexpr={'nrsIdpk'} dataexp={'nrsName'} />
                           </div>
                           <div
                              className="flex justify-end " style={{ marginTop: '14%' }}
                           >
                              <button onClick={() => { alert(state.staff) }} className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center my-1  px-2 py-0.5 border-2 border-slate-200 rounded">
                                 <img className="pr-1" src={save} />
                                 Update
                              </button>

                           </div>
                        </Form>
                     </div>

                  </div>

               </div>

            </div>
         </div>

      </>
      }


      <div className="pt-0.5">
         <DataGrid
            dataSource={dataTable}
            showBorders={true}
            columnWidth={150}
            showRowLines={true}

            showColumnLines={true}
            //Remember to change
            height={toggled === true ? tableHeight - 370 : tableHeight}
            onRowDblClick={(e: any) => {
               dispatch({
                  type: 'change_display',
                  newDisplay: 'updateMode'
               });
            }}
            onRowClick={(e) => {
               console.log(e.data
               )
               dispatch({
                  type: 'set_data',
                  newValue: e.data
               });
            }}



         >
            <SearchPanel visible={true} width={200} />
            <GroupPanel visible={true} />
            <Pager visible={false} />


            {/* STAFF DETAILS COLUMNS */}
            {/* FIXED COLUMNS */}

            {
               block_house_TH.map((data) => {
                  return (
                     <Column
                        key={data.id}
                        caption={data.caption}
                        dataField={data.dataField}
                        alignment={"left"}
                        fixed={data.fixed}


                        width={data.width === null ? 150 : data.width}
                     />
                  )
               })
            }

            {/* SCROLLBAR  */}

            <Scrolling columnRenderingMode="virtual" />








         </DataGrid>
         <section className='border-2 border-stone-300 flex align-middle my-1  rounded-md bg-slate-100 items-center' style={{ height: '30px' }}>
            <div className='pl-2 text-blue-500   ' >
               Done...
            </div>

         </section>
      </div>
   </div>



   );
}

export default StaffClassificationsDetails;