import { Checkbox, Col, Row } from "antd";
import { useState, useCallback } from "react";
import { CustomToolbar } from "../../../accessories/custom-toolbars/customtoolbar";
import UpdateStaffCheckbox from "./widgets/updateStaffCheckbox";
import UpdateStaffDropdownForm from "./widgets/updateStaffDropdowns";
import UpdateStaffForm from "./widgets/updateStaffForm";
import UpdateStaffMiniTable from "./widgets/updateStaffMiniTable";
import React from "react";

const UpdateStaffOrg = () => {
    // handle reveal and hide of staff form
    const[staff_form_state,set_staff_form_state] = useState<boolean>(false)
    
    const toggle_staff_form = ():void =>{
        staff_form_state ? set_staff_form_state(false) : set_staff_form_state(true);
        tableHeight === window.innerHeight - 107.5 ? settableHeight(window.innerHeight - 606) : settableHeight(window.innerHeight - 107.5);
    }

    const [selectedEmployee, setSelectedEmployee] = useState();

    const selectEmployee:any = useCallback<any>((e:any) => {
        e.component.byKey(e.currentSelectedRowKeys[0]).done((employee:any) => {
            setSelectedEmployee(employee);
        });
    }, []);

    //Table Height
    const pageHeight = window.innerHeight - 58.05; //height without navbar
    const [tableHeight,settableHeight] = useState(window.innerHeight - 107.5); //height without navbar and toolbar
    var tab = tableHeight

    // adjust height when window is resized
    window.onresize = (event) => {console.log(tab);
        !staff_form_state ? settableHeight(window.innerHeight - 107.5) : settableHeight(window.innerHeight - 606);
    };


    return (
        <div style={{height : pageHeight}} className="w-full px-2">
            {/* toolbar */}
            <div className="">
                <CustomToolbar toggler={toggle_staff_form}/>
            </div>

            {/* form */}
            {staff_form_state &&
            <div style={{height : 500}}  className="form">
                <p className='py-1 px-2 w-full bg-slate-100'>Staff Org Unit Details</p>
                <div className="flex">
                <UpdateStaffMiniTable />
                <div className="mt-4 ml-2 w-3/5 ">
               <UpdateStaffCheckbox />
               </div>
               <div>
                <UpdateStaffDropdownForm />
               </div>
               </div>
                {/* <UpdateStaffForm/> */}
            </div>}

            {/* datagrid */}
            <div className="w w-full m-auto pt-1">
                {/* <Staff_Datagrid gridheight={tableHeight}/> */}
            </div>
        </div>
    );
}
 
export default UpdateStaffOrg;