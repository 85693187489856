import { Checkbox, DatePicker, Input } from "antd";
import { Tooltip } from "devextreme-react";
import "devextreme/dist/css/dx.light.css";
import { useEffect, useState } from "react";
import { FaBinoculars } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";
import { ModalTemplate } from "../../../../../../templates/modal";
import alter from "../../../../../../assets/alter.png";
import help from "../../../../../../assets/help.png";
import printer from "../../../../../../assets/printer.png";
import reset from "../../../../../../assets/refresh-small.png";
import statusLoader from "../../../../../../assets/statusloader.gif";
import { useAccountInfo } from "../../../../../../hooks/useAccountInfo";
import { useFormPriviledge } from "../../../../../../hooks/useFormPriviledge";
import dayjs from "dayjs";

interface props {
  useCallBackFunc?: boolean;
  toggler?: () => void; //switch interface
  handleDelete?: () => void; //delete handler
  handleSave?: () => void; //save handler
  handleUpdate?: () => void; //update handler
  searchTextOnchange?: (value: string) => void; //search text change
  startDateChanged?: (value: any) => void; //start date change
  endDateChanged?: (value: any) => void; //end date change
  checkOnChange?: (value: any) => void; //checkbox change
  handleFind?: () => void; //find handler
  handleRefresh?: () => void; //refresh main grid
  handlePrint?: () => void; // handle print functionality
  startDate?: string;
  endDate?: string;

  withDates?: boolean; //add dates to the toolbar?
}

export const AuthoriseToolbar = ({
  toggler,
  handleRefresh,
  handlePrint,
  startDate,
  endDate,
  useCallBackFunc,
  withDates = true,
  handleDelete,
  searchTextOnchange,
  handleSave,
  handleUpdate: handleEdit,
  startDateChanged,
  endDateChanged,
  handleFind,
  checkOnChange,
}: props) => {
  const dispatch = useDispatch();
  const disabled = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const updateMode = useSelector(
    (state: RootState) => state.general.updateMode
  );

  const [warningModal, setWarningModal] = useState<boolean>(false);
  const [warningModalData, setWarningModalData] = useState<any>({
    title: "",
    message: "",
  });
  const [employeeId, userId, userInfo] = useAccountInfo();
  const [privType, setPrivType] = useState<string>("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(
    privType,
   
  );

  const selectedID = useSelector(
    (state: RootState) => state.general.SelectedFieldID
  );
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  // Three state checkboxes
  const [indeterminate, setIndeterminate] = useState<any>(true);
  const [checkedBox, setCheckedBox] = useState<any>(false);
  const [checkValue, setCheckValue] = useState<boolean | null>(null); //actual value to use
  const [searchTextValue, setSearchTextValue] = useState("");

  useEffect(() => {
    checkOnChange && checkOnChange(checkValue);
  }, [checkValue]);

  const onChange = (value: boolean) => {
    if (indeterminate === true) {
      setCheckedBox(true);
      setIndeterminate(false);
      setCheckValue(true);
    } else if (!indeterminate && checkedBox) {
      setCheckedBox(false);
      setIndeterminate(false);
      setCheckValue(false);
    } else if (!indeterminate && !checkedBox) {
      setCheckedBox(false);
      setIndeterminate(true);
      setCheckValue(null);
    }
  };

  //modal funcs
  const setModal = () => {
    setWarningModal(true);
    setWarningModalData({
      title: "Select record",
      message: "Please select a record to edit",
    });
  };
  const OnModalOk = () => {
    setWarningModal(false);
  };
  const OnModalCancel = () => {
    setWarningModal(false);
  };

  const findAccessPriv = () => {
    if (readPriv === true) {
      if (handleFind) {
        handleFind();
      } else {
      }
    } else {
      setWarningModal(true);
      setWarningModalData({
        title: "Access Denied",
        message: "No Access Privilege",
      });
    }
  };

  const printAccessPriv = () => {
    if (readPriv === true) {
      handlePrint && handlePrint();
    } else {
      setWarningModal(true);
      setWarningModalData({
        title: "Access Denied",
        message: "No Access Privilege",
      });
    }
  };

  return (
    <div
      style={{ width: "4000px", borderColor: borderTheme }}
      className="  w-full h-full flex border-[1px] rounded border-gray-200 mb-1 mt-0.5 "
    >
      <ModalTemplate
        icon={"warning"}
        cancelHandler={OnModalCancel}
        disableCancel={true}
        open={warningModal}
        okHandler={OnModalOk}
        message={warningModalData.message}
        okText={"Ok"}
        title={warningModalData.title}
      />
      <ul
        style={{ width: "430px" }}
        className="flex items-center h-full py-0.5 px-0.5"
      >
        <li
          id="switchInterface"
          onClick={toggler}
          style={{ borderWidth: "1px", borderColor: borderTheme }}
          className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
        >
          <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
            {" "}
            <img
              className="dark:bg-slate-900 pr-1 dark:text-darkModeSkin-base duration-100"
              src={alter}
            />
            Switch
          </span>
          <Tooltip
            target="#switchInterface"
            showEvent="dxhoverstart"
            hideEvent="dxhoverend"
            hideOnOutsideClick={true}
          >
            <p className="">Switch interface / table view</p>
          </Tooltip>
        </li>

        <li
          id="helpst"
          style={{ borderWidth: "1px", borderColor: borderTheme }}
          className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
        >
          <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
            {" "}
            <img
              className="dark:bg-slate-900 dark:text-gray-100 duration-100 mr-1"
              src={help}
            />
            Help{" "}
          </span>
          <Tooltip
            target="#helpst"
            showEvent="dxhoverstart"
            hideEvent="dxhoverend"
            hideOnOutsideClick={true}
          >
            <p className="">Help</p>
          </Tooltip>
        </li>
      </ul>

      <ul className="flex  w-full">
        <section className="flex justify-start items-center">
          {/* <li className="px-1 flex items-center flex-row ">
            <label style={{ width: "70px" }} className=" text-xs">
              Search Text
            </label>
            <Input
              onChange={(e: any) => {
                const searchText: string = e.target.value;
                searchTextOnchange && searchTextOnchange(searchText);
                setSearchTextValue(e.target.value);
              }}
              value={searchTextValue}
              name="search"
              id="search"
              size="small"
              allowClear={false}
              style={{ width: 130 }}
            />
          </li> */}
          {withDates && (
            <ul className=" flex flex-row items-center mt-0.5">
              <li className="px-0.5">
                <DatePicker
                  allowClear={false}
                  onChange={startDateChanged}
                  size="small"
                  placeholder="Start Date"
                  style={{ width: 125 }}
                  value={dayjs(startDate) ?? dayjs()}
                  format={"DD MMM YYYY"}
                />
              </li>
              <li className="px-0.5">
                <DatePicker
                  allowClear={false}
                  onChange={endDateChanged}
                  size="small"
                  placeholder="End Date"
                  style={{ width: 125 }}
                  value={dayjs(endDate) ?? dayjs()}
                  format={"DD MMM YYYY"}
                />
              </li>
            </ul>
          )}
           {/* <li
            id="status"
            className="hover:cursor-pointer mr-1  text-xs px-1 py-1   text-gray-600 rounded flex items-center"
          >
            <Checkbox
              style={{ borderColor: borderTheme }}
              className="border-[1px] px-1 rounded "
              checked={checkedBox}
              indeterminate={indeterminate}
              onChange={(checkValue: any) => {
                onChange(checkValue);
              }}
            /> 
             <Tooltip
              target="#status"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Show Status</p>
            </Tooltip>
          </li> */}
          <li
            onClick={() => {
              findAccessPriv();
            }}
            id="findst"
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
          >
            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
              <FaBinoculars size={15.5} color="#007AFF" />
            </span>
            <Tooltip
              target="#findst"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Find</p>
            </Tooltip>
          </li> 

          {/* refresh */}
          {/* <li
            onClick={() => {
              handleRefresh!();
              setSearchTextValue("");
            }}
            id="refreshst"
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
          >
            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
              {" "}
              <img
                className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                src={reset}
              />
            </span>
            <Tooltip
              target="#refreshst"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Refresh</p>
            </Tooltip>
          </li> */}

          <li
            onClick={() => printAccessPriv()}
            id="printst"
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
          >
            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
              {" "}
              <img
                className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                src={printer}
              />
            </span>
            <Tooltip
              target="#printst"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Print/Export</p>
            </Tooltip>
          </li>
        </section>

        {/* Form status label */}
        <section className="flex pl-12 h-full flex-row items-center">
          {/* done state */}
          {disabled && <p className=" text-gray-600">Done.</p>}

          {/* new record or editing state */}
          {!disabled && (
            <div className="gif  flex flex-row items-center">
              <img className="w-11 h-7" src={statusLoader} alt="gif" />
              {!updateMode ? (
                <p className=" text-gray-600">New record...</p>
              ) : (
                <p className=" text-gray-600">Editing...</p>
              )}
            </div>
          )}
        </section>
      </ul>
    </div>
  );
};
