import React, { useState, useCallback, useRef } from 'react'
import { Form, Input, Select, DatePicker } from 'antd';
import { CheckBox } from 'devextreme-react/check-box';

import { Cancel, NewButton, SaveButton, SignOff, UpdateButton } from '../../../../../accessories/buttons';
import { PMT_Input, PMT_Input2, PMT_TextArea } from '../data/Performance_inputs';
import { PMT_Select } from '../data/Performance_selects';
import reset from '../../../../../../assets/reset.png';
import { selectsTypes } from '../../../../../human-resource/setups/data/types/selectsTypes';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../app/store';
import Checkbox from 'antd/es/checkbox/Checkbox';
import { InputsTemplate } from '../../../../../../templates/input';
import { SelectsTemplate } from '../../../../../../templates/select';
import { TextareaTemplate } from '../../../../../../templates/textarea';
import useFetch from '../../../../../../hooks/useFetch';

interface stateTypes {
    date: string,
    mission_vision: {id:number,name:string},
    theme: { id: number; name: string }
    objective: { id: number; name: string }
    perfMeasure:string,
    unit_measure: { id: number; name: string }
    unit: { id: number; name: string }
    max_value: string,
    min_value:string, 
    active: boolean,
    target : string,
    remarks : string,
    order : number,

}


export const PotentialMeasure_Form_Details = () => {
    const { Option } = Select;
    const { TextArea } = Input;

    // import { AssignedDept, AssignedLocation, AssignedSelection, AssignedUnit, Batch, Group, PPE_Select, RequestWindow, StaffName } from './PPE_selects';

    // from redux
    const form_disable = useSelector((state: RootState) => state.general.formDisabled)
    const update_state_change = useSelector((state: RootState) => state.general.taskFooterUpdate)
    const borderTheme = useSelector((state:RootState)=>state.general.cssBorderClass)

    const [states, setStates] = useState<stateTypes>({
        date: '',
        mission_vision: {id:0,name:''},
        theme: { id: 0, name: '' },
        objective: { id: 0, name: '' },
        perfMeasure:'',
        unit_measure: { id: 0, name: '' },
        unit: { id: 0, name: '' },
        max_value: '',
        min_value:'',
        active: false,
        target : '',
        remarks : '',
        order : 0,
    })

    const updateState = (key: string, value: any) => {
        setStates((prev: any) => ({ ...prev, [key]: value }))
    }

    const options = [];
    for (let i = 1; i < 36; i++) {
        options.push({
            label: i.toString(36) + i,
            value: i.toString(36) + i,
        });
    }

    const [textAreaHeight, settextAreaHeight] = useState<string>('75px')

    const handleChange = (value: string[]) => {
        console.log(`selected ${value}`);
        if (value.length > 7) {
            settextAreaHeight('60px')
        } else {
            settextAreaHeight('71px')
        }
    };
    const dateFormat = " DD MMM YYYY HH: mm:ss";




    const [tasks_update_state, setTask_update_state] = useState<Boolean>(true)
    const [tasks_update_state_width, setTask_update_state_width] = useState<string>('100%')
    const [tasks_update_col_width, setTask_update_col_width] = useState<number>(100)
    const [tasks_update_col_height, setTask_update_col_height] = useState<number>()
    const [task_datagrid_width, set_task_datagrid_width] = useState(`calc(100% - ${'550px'})`);
    const [AppraiseisActive, AppraisesetIsActive] = useState<boolean>(false)
    const [mission_vision_data,mission_vision_loading,mission_vision_error] = useFetch(`PfmMissionVision/GetAllPfmMissionVision`)
    const [isActive, setIsActive] = useState<boolean>(true)
    const [theme_data,theme_loading,theme_error] = useFetch(`PfmMeasureAndTarget/GetPfmLueMeasureTargetThemes?isActive=${isActive}`)
    const [objective_data,objective_loading,objective_error] = useFetch(`PfmMeasureAndTarget/GetPfmLueMeasureTargetObjectives?mission=${states.mission_vision.name}&theme=${states.theme.name}`)


    const taskDetailsFormHeight = useRef(null);

    const selects: selectsTypes[] = [
        { id: 0, label: "Mission/Vision", optionsData: mission_vision_data, dataExpr: "mvsName", idExpr: "mvsIdpk", defaultValue: states.mission_vision.name, stateName: "mission_vision"},
        { id: 1, label: "Theme", optionsData: theme_data, dataExpr: "thmName", idExpr: "thmIdpk", defaultValue: states.theme.name ,stateName: "theme"},
        { id: 2, label: "Objective", optionsData: objective_data, dataExpr: "acbName", idExpr: "acbIDpk", defaultValue: states.objective.name,stateName: "objective" },

    ]

    const [updatingStatus, setUpdatingStatus] = useState(false);
    const [showCancel, setShowCancel] = useState(false);


    return (

        <div style={{ width: '100%', minWidth: '100%', height: '100%', borderColor: borderTheme }} className="  border-[1px]   dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base  ">
            {/* <h4 className=" w-full  mb-2 bg-slate-100  ">Task Details</h4> */}
            <div style={{ maxHeight: '295px', minHeight: '295px', height: '295px' }} className=' items-start justify-start '   >

                <Form
                    className='w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 px-2 '
                    name="basic"
                    labelCol={{ span: 2 }}
                    // wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    size='small'
                    layout='horizontal'

                >

                    <Form.Item label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{'Date '}</p>}>
                        <div className='flex'>
                            <div className='flex items-center pr-3'>
                                <DatePicker placeholder='Select Date' />

                            </div>

                            <Form.Item className='pr-3' >
                                <div>
                                    <DatePicker placeholder='Select Date' />
                                </div>
                            </Form.Item>


                        </div>
                    </Form.Item>

                    <div>
                        <>
                            {selects.map(({ id, label, dataExpr, defaultValue, stateName, style, optionsData, idExpr }) => {
                                return (
                                    <>
                                        <SelectsTemplate
                                            label={label}
                                            placeHolder={defaultValue}
                                            options={optionsData}
                                            dataexp={dataExpr}
                                            idexpr={idExpr}
                                            useCallFunc
                                            selectedValue={(e) => { updateState(stateName!,
                                                 { id: e[idExpr!], name: e[dataExpr!] }) 
 }}
                                        />
                                    </>
                                )
                            })}

                            {/* {selects?.map((select: any) => {
                                const total_selects = selects?.length
                                return (
                                    <>
                                        <Form.Item
                                            key={select.id}
                                            label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{select.label}</p>}
                                            className={select.id === total_selects! - 1 ? 'mb-0.5' : ' mb-1'}
                                            name={select.label}
                                        // rules={[{ required: rulesOn,}]}
                                        // labelCol={{span: labelCol }}
                                        // wrapperCol={{span:wrapperCol}}
                                        >
                                            <div className="flex flex-row items-center">
                                                <Select
                                                    onChange={() => { }}
                                                    className='w-full mr-0'
                                                    placeholder=""
                                                    allowClear
                                                >
                                                    {select.optionsData?.map((option: any, index: number) => {
                                                        return (
                                                            <option key={index} value={option[select.nameKey!]}>{option[select.nameKey!]}</option>
                                                        )
                                                    })}
                                                </Select>

                                                <span
                                                    onClick={() => { }}
                                                    className='ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded'
                                                    style={{ height: 22.5, marginBottom: 2, borderWidth: 1, width: 23.9 }}
                                                >
                                                    <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src={reset} alt="reset" />
                                                    
                                                </span>
                                            </div>
                                        </Form.Item>
                                    </>
                                )
                            })} */}
                        </>
                    </div>
                    {/* <PMT_Select /> */}
                    {/* <PMT_Input /> */}


                    <InputsTemplate
                        label='Perf. Measure'
                        placeholder=''
                        id=''
                        expr=''
                        nameExpr=''
                        useCallbackFunc
                        orderOnchange={() => { }}
                    />


                    <Form
                        //className='w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 px-2 '
                        className='ml-[1.2%]'
                        name="basic"
                        labelCol={{ span: 7 }}
                        // wrapperCol={{ span: 24 }}
                        initialValues={{ remember: true }}
                        autoComplete="off"
                        size='small'
                        layout='horizontal'
                    >
                        <div className='w-full flex mt-0.5 '>
                            <div className='w-1/4'>
                                <SelectsTemplate
                                    label={'Unit of Measure'}
                                    placeHolder={''}
                                    options={[]}
                                    dataexp={''}
                                    idexpr={''}
                                    useCallFunc
                                    selectedValue={() => { }}
                                />
                            </div>

                            <div className='w-1/4'>
                                <SelectsTemplate
                                    label={'Unit'}
                                    placeHolder={''}
                                    options={[]}
                                    dataexp={''}
                                    idexpr={''}
                                    useCallFunc
                                    selectedValue={() => { }}
                                />
                            </div>

                            <div className='w-1/4'>
                                <InputsTemplate
                                    label='Max. Value'
                                    placeholder=''
                                    id=''
                                    expr=''
                                    nameExpr=''
                                    useCallbackFunc
                                    orderOnchange={() => { }}
                                />

                            </div>

                            <div className='w-1/4 '>
                                <InputsTemplate
                                    label='Min. Value'
                                    placeholder=''
                                    id=''
                                    expr=''
                                    nameExpr=''
                                    useCallbackFunc
                                    orderOnchange={() => { }}
                                />

                            </div>
                        </div>
                    </Form>


                    {/* <Form.Item className='w-full' label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{'Unit of Measure '}</p>}>
                        <div className='flex w-full justify-between'>
                            <ul className='w-2/4 flex'>
                                <div className='flex items-center pr-3 w-1/2'>

                                    <Select style={{ width: '100%' }} placeholder='Select an option' />
                                    <span
                                        onClick={() => { }}
                                        className='ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded'
                                        style={{ height: 22.5, marginBottom: 2, borderWidth: 1, width: 23.9 }}
                                    >
                                        <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src={reset} alt="reset" />

                                       
                                    </span>
                                </div>

                                <Form.Item className='flex items-center pr-3 w-1/2 ' label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{'Unit '}</p>}  >

                                    <div className='flex items-center w-full' >

                                        <Select style={{ width: '100%' }} placeholder='Select an option' />
                                        <span
                                            onClick={() => { }}
                                            className='ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded'
                                            style={{ height: 22.5, marginBottom: 2, borderWidth: 1, width: 23.9 }}
                                        >
                                            <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src={reset} alt="reset" />
                                            

                                        </span>
                                    </div>

                                </Form.Item>
                            </ul>

                            <ul className='w-2/4 flex'>
                                <Form.Item className='flex items-center pr-3 w-1/2 ' label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{'Min. Value '}</p>}  >
                                    <div className='w-full'>
                                        <Input className='w-full' placeholder='Input an option' />
                                    </div>
                                </Form.Item>
                                <Form.Item className='flex items-center pr-3 w-1/2 ' label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{'Max. Value '}</p>}  >
                                    <div className='w-full'>
                                        <Input style={{ width: '100%' }} placeholder='Input an option' />
                                    </div>
                                </Form.Item>
                            </ul>







                        </div>
                    </Form.Item> */}
                    {/* <PMT_Input2 /> */}
                    <InputsTemplate
                        label='Target'
                        placeholder=''
                        id=''
                        expr=''
                        nameExpr=''
                        useCallbackFunc
                        orderOnchange={() => { }}
                    />
                    {/* <PMT_TextArea /> */}
                    <TextareaTemplate
                        label='Remarks'
                        defaultValue=''
                        idExpr=''
                        dataExpr=''
                        useCallbackFunc
                        height={80}
                        setCallbackValue={() => { }}
                        labelCol={2}
                    />

                    {/* <Group />
                                            <StaffName />
                                            <RequestWindow />
                                            <Batch />
                                            <AssignedDept />
                                            <AssignedSelection />
                                            <AssignedUnit />
                                            <AssignedLocation /> */}
                    <Form.Item
                        labelCol={{ span: 2 }}

                        label={<p className='dark:bg-fill dark:text-darkModeSkin-base text-xs'>{'Order'}</p>}
                        rules={[{ required: false, }]}
                    >
                        <div className="flex flex-row w-full ">
                            <div style={{ width: 'calc(100%)', }} className=' flex  justify-between  '>
                                {/* <li className='flex justify-start '><Cancel/></li> */}
                                <ul className='flex justify-evenly'>
                                    <li><Input type='number' className='w-24 ' disabled={false} value={''} onChange={(e: any) => { }} /></li>
                                    <li className=' flex h-6 items-center flex-row  ml-5'><p className='mr-2 text-xs'>Active : <Checkbox style={{borderColor:borderTheme}} className='border-[1px] rounded px-1' disabled={form_disable} checked={isActive} onChange={(e: any) => { }} ></Checkbox> </p>  </li>
                                </ul>
                                <ul className='flex justify-end '>
                                    {updatingStatus === true ? <UpdateButton handleUpdate={() => { }} /> : <SaveButton handleSave={() => { }} />}
                                    {showCancel === true ? <Cancel useCallbackFunc={true} cancel_button_toggler={() => {


                                        // dispatch(update_route_state(false))
                                        //dispatch(task_footer_update(false));
                                    }} /> : <NewButton useCallbackFunc={true} new_button_toggler={() => {

                                    }} />}
                                </ul>
                            </div>
                        </div>
                    </Form.Item>
                </Form>
            </div>



        </div>
    )
}