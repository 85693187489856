import React from "react";
import { RefreshButton } from "../../../../StationReadings/_widget/utils";
import close from "../../../../../../../assets/close.png";
import { IconsContainer } from "../../../../StationReadings/_widget/stationReadingsToolbar";
import print from "../../../../../../../assets/printer.png";
import addNew from "../../../../../../../assets/addNew.png";
export default function Attachments() {
  return (
    <main className="flex flex-col h-[69vh] w-full ">
      <section className=" flex-1 border-[1px] h-full w-full">
        <div className="w-full h-auto border-[1px] flex justify-around items-center">
          <p className="">File Name</p>
          <aside className="flex">
          <span className="w-8  ">
            <IconsContainer url={addNew} />
          </span>
            <IconsContainer url={print} />
          </aside>
         <aside className="flex space-x-2 items-center">
          <RefreshButton />
          <IconsContainer url={close} />
          </aside>
        </div>
      </section>
     
    </main>
  );
}
