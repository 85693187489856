import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncGet, GetDataFunc } from "../../../functions/get";
import { stat } from "fs";

type State = {
    service_provider: any | null | undefined;
    service_station: any | null | undefined;
    product: any | null | undefined;
    unit_of_measure: any | null | undefined;
    department: any | null | undefined;
    sections: any | null | undefined;
    cost_centers: any | null | undefined;
    isError: boolean,
    isLoading: boolean,
    isPending: boolean,
}
const initialState: State = {
    service_provider: [],
    service_station: [],
    cost_centers: [],
    product: [],
    unit_of_measure: [],
    department: [],
    sections: [],
    isError: false,
    isLoading: false,
    isPending: false,
}

export const Fetch_ServiceProvider = createAsyncThunk<State, string, { rejectValue: string }>(
    'fetch_service_provider',
    async (search: string) => {
        try {
            var response = await AsyncGet(`AsmTmsCaptureOldFuelRecords/GetAllServiceProviders`, {
                pagenumber: 1,
                pagesize: 20,
                search
            });
            if (response?.status === true) {
                return response?.data;
            }
        } catch {
            return response?.data;
        }
    }
)
export const Fetch_ServiceStation = createAsyncThunk<State, {search:string, SpdIDpk: number}, { rejectValue: string }>(
    'fetch_service_station',
    async ({search, SpdIDpk}) => {
        try {
            var response = await AsyncGet(`AsmTmsCaptureOldFuelRecords/GetAllServiceStations`, {
                pagenumber: 1,
                pagesize: 20,
                SpdIDpk,
                search
            });
            if (response.status === true) {
                return response?.data;
            }
        } catch {
            return response?.data;
        }
    }
)
export const Fetch_Product = createAsyncThunk<State, {search:string, SpdIDProd: any}, { rejectValue: string }>(
    'fetch_product',
    async ({search, SpdIDProd}) => {
        try {
            var response = await AsyncGet(`AsmTmsCaptureOldFuelRecords/GetAllProducts`, {
                pagenumber: 1,
                pagesize: 20,
                search,
                SpdIDProd,

            });
            if (response.status === true) {
                return response?.data;
            }
        } catch {
            return response?.data;
        }
    }
)
export const Fetch_UnitOfMeasure = createAsyncThunk<State, string, { rejectValue: string }>(
    'fetch_unit_of_measure',
    async (search) => {
        try {
            var response = await AsyncGet(`AsmTmsCaptureOldFuelRecords/GetAllFuelUnitsOfMeasure`, {
                pagenumber: 1,
                pagesize: 20,
                search,
            });
            if (response.status === true) {
                return response?.data;
            }
        } catch {
            return response?.data;
        }
    }
)
export const Fetch_Department = createAsyncThunk<State, string, { rejectValue: string }>(
    'fetch_department',
    async (search) => {
        try {
            var response = await AsyncGet(`AsmTmsCaptureOldFuelRecords/GetAllDepartments`, {
                pagenumber: 1,
                pagesize: 20,
                search,
            });
            if (response.status === true) {
                return response?.data;
            }
        } catch {
            return response?.data;
        }
    }
)
export const Fetch_Sections = createAsyncThunk<State, {search:string, DptIDSection:string}, { rejectValue: string }>(
    'fetch_sections',
    async ({search, DptIDSection}) => {
        try {
            var response = await AsyncGet(`AsmTmsCaptureOldFuelRecords/GetAllSections`, {
                pagenumber: 1,
                pagesize: 20,
                search,
                DptIDSection,
            });
            if (response.status === true) {
                return response?.data;
            }
        } catch {
            return response?.data;
        }
    }
)
export const Fetch_CostCenter = createAsyncThunk<State, {DptIDCost: string, SxnIDCost: string, search:string }, { rejectValue: any }>(
    'fetch_cost_centers',
    async ({DptIDCost, SxnIDCost, search}) => {
        try {
            var response = await AsyncGet(`AsmTmsCaptureOldFuelRecords/GetAllCostCenters`, {
                pagenumber: 1,
                pagesize: 20,
                search,
                DptIDCost,
                SxnIDCost,
            });
            if (response.status === true) {
                return response?.data;
            }
        } catch {
            return response?.data;
        }
    }
)
const Slice_BacklogFuelEntries = createSlice({
    name: 'BacklogFuelEntries',
    initialState: initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(Fetch_ServiceProvider.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(Fetch_ServiceProvider.fulfilled, (state, action) => {
                state.isLoading = false;
                state.service_provider = action.payload;
            })
            .addCase(Fetch_ServiceProvider.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(Fetch_ServiceStation.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(Fetch_ServiceStation.fulfilled, (state, action) => {
                state.isLoading = false;
                state.service_station = action.payload;
            })
            .addCase(Fetch_ServiceStation.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(Fetch_Product.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(Fetch_Product.fulfilled, (state, action) => {
                state.isLoading = false;
                state.product = action.payload;
            })
            .addCase(Fetch_Product.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(Fetch_UnitOfMeasure.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(Fetch_UnitOfMeasure.fulfilled, (state, action) => {
                state.isLoading = false;
                state.unit_of_measure = action.payload;
            })
            .addCase(Fetch_UnitOfMeasure.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(Fetch_Department.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(Fetch_Department.fulfilled, (state, action) => {
                state.isLoading = false;
                state.department = action.payload;
            })
            .addCase(Fetch_Department.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(Fetch_Sections.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(Fetch_Sections.fulfilled, (state, action) => {
                state.isLoading = false;
                state.sections = action.payload;
            })
            .addCase(Fetch_Sections.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(Fetch_CostCenter.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(Fetch_CostCenter.fulfilled, (state, action) => {
                state.isLoading = false;
                state.cost_centers = action.payload;
            })
            .addCase(Fetch_CostCenter.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            });
    },
})

export default Slice_BacklogFuelEntries.reducer