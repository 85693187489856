export const assets_fuelunitsColumns = [
  {
    id: 0,
    caption: "ID",
    dataField: "funIdpk",
    dataType: "number",
    width: 80,
    fixed: true,
    visible: false,
  },
  {
    id: 1,
    caption: "FUEL UNIT",
    dataField: "funName",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "SHORT NAME",
    dataField: "funShtName",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 3,
    caption: "ORDER",
    dataField: "funOrder",
    dataType: "string",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 4,
    caption: "ACTIVE",
    dataField: "funActive",
    dataType: "boolean",
    width: 90,
    fixed: true,
    visible: true,
  },
  {
    id: 5,
    caption: "REMARKS",
    dataField: "funRmks",
    dataType: "string",
    width: 5000,
    fixed: false,
    visible: true,
  },
];
