/* eslint-disable react/jsx-pascal-case */
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";
import {
  setValue,
  tier1SaveOnclick,
  tier1UpdateOnclick,
} from "../../../../../../features/forms/tier1/createSlice";
import { setGeneralValue } from "../../../../../../features/generalSlice";
import { PostDataFunc  } from "../../../../../../functions/post";
import { UpdateDataFunc } from "../../../../../../functions/update";
import { useAccountInfo } from "../../../../../../hooks/useAccountInfo";
import { useBusyLoader } from "../../../../../../hooks/useBusyLoader";
import useFetch from "../../../../../../hooks/useFetch";
import { ModalTemplate } from "../../../../../../templates/modal";
import {
  validationsData,
  saveModalData,
  updateModalData,
} from "../../../../../accessories/component_infos";
import { WarningAlert } from "../../../../../accessories/warningAlert";
import Setups_Template from "../../../../../human-resource/setups/.setups_templates/Setups_Template";
import {
  exprsTypes,
  modalTypes,
  searchTextTypes,
} from "../../../../../human-resource/setups/data/types/selectsTypes";
import { assets_vehicleDPHCategoriesColumns } from "./data/DPHCategoriesData";
import { useCrudFunc } from "../../../../../../functions/crud";

export const Assets_setups_DPHCategiries = forwardRef(({ a }: any, ref) => {
  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });
  const [posting , updating]=useCrudFunc()
  const activeStatus = useSelector(
    (state: RootState) => state.general.activeStatus
  );
  const [status, setStatus] = useState<boolean | string>("");

  const saveSuccessText = {
    message: "Driver Permit Holders Category added Successfully",
    ok: "Ok",
    title: "Success",
  };
  const updateSuccessText = {
    message: "Driver Permit Holders Category  updated Successfully",
    ok: "Ok",
    title: "Success",
  };

  const [busyLoader, setBusyLoader] = useBusyLoader();
  const [employeeId, userId] = useAccountInfo();

  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState<boolean>(false);

  const idExpr: string = "dpcIDpk";
  const nameExpr: string = "dpcName";
  const shortnameExpr: string = "dpcShtName";
  const orderExpr: string = "dpcOrder";
  const activeExpr: string = "dpcActive";
  const remarksExpr: string = "dpcRmks";
  const inputsExprsData: any = {
    idExpr: idExpr,
    nameExpr: nameExpr,
    shortnameExpr: shortnameExpr,
    orderExpr: orderExpr,
    activeExpr,
    remarksExpr: remarksExpr,
  };

  const storeExprs = () => {
    const exprs: exprsTypes[] = [
      { expr: "existsUrl", value: `` },
      { expr: "idExpr", value: idExpr },
      { expr: "nameExpr", value: nameExpr },
      { expr: "shortnameExpr", value: shortnameExpr },
    ];
    for (let i = 0; i < exprs.length; i++) {
      dispatch(setValue({ expr: exprs[i].expr, value: exprs[i].value }));
    }

    const generalExprs: exprsTypes[] = [
      { expr: "inputExprs", value: inputsExprsData },
    ];
    for (let i = 0; i < generalExprs.length; i++) {
      dispatch(
        setGeneralValue({
          expr: generalExprs[i].expr,
          value: generalExprs[i].value,
        })
      );
    }
  };

  const triggerSuccessModal = useSelector(
    (state: RootState) => state.tier1Add.successModalState
  );
  const SuccessModalText = useSelector(
    (state: RootState) => state.tier1Add.successText
  );

  const [err, setErr] = useState<{
    state: boolean;
    title: string;
    message: string;
  }>({
    state: false,
    title: "",
    message: "",
  });

  const triggerwarningModal = useSelector(
    (state: RootState) => state.tier1Add.warningState
  );
  const warningInfos: any = validationsData;
  const warningDataExpr = useSelector(
    (state: RootState) => state.tier1Add.warningDataExpr
  );

  const saveData = saveModalData;
  const triggersaveModal = useSelector(
    (state: RootState) => state.tier1Add.saveDataOnclick
  );

  const updateIndInfos = updateModalData;
  const triggerupdateModal: boolean = useSelector(
    (state: RootState) => state.tier1Add.updateDataOnclick
  );

  useImperativeHandle(ref, () => ({
    test: () => {
      setTrigger(!trigger);
    },
  }));

  const name = useSelector((state: RootState) => state.tier1Add.name);
  const shortname = useSelector((state: RootState) => state.tier1Add.shortname);
  const drivername = useSelector(
    (state: RootState) => state.tier1Add.checkboxInline0
  );
  const permitholder = useSelector(
    (state: RootState) => state.tier1Add.checkboxInline1
  );
  const order = useSelector((state: RootState) => state.tier1Add.order);
  const active = useSelector((state: RootState) => state.tier1Add.active);
  const remarks = useSelector((state: RootState) => state.tier1Add.remarks);
  const selectedID = useSelector(
    (state: RootState) => state.general.SelectedFieldID
  );

  const modals: modalTypes[] = [
    {
      disableCancel: true,
      icon: "warning",
      open: triggerwarningModal,
      okHandler: () =>
        dispatch(setValue({ expr: "warningState", value: false })),
      cancelHandler: () =>
        dispatch(setValue({ expr: "warningState", value: false })),
      message: warningInfos[warningDataExpr!].message,
      cancelText: warningInfos[warningDataExpr!].cancelText,
      okText: warningInfos[warningDataExpr!].okText,
      title: warningInfos[warningDataExpr!].title,
    },
    {
      disableCancel: true,
      icon: "warning",
      open: err.state,
      okHandler: () => setErr((prev) => ({ ...prev, state: false })),
      cancelHandler: () => setErr((prev) => ({ ...prev, state: false })),
      message: err.message,
      cancelText: "",
      okText: "Ok",
      title: err.title,
    },
    {
      disableCancel: true,
      icon: "success",
      open: triggerSuccessModal,
      okHandler: () =>
        dispatch(setValue({ expr: "successModalState", value: false })),
      cancelHandler: () =>
        dispatch(setValue({ expr: "successModalState", value: false })),
      message: SuccessModalText.message,
      okText: SuccessModalText.okText,
      title: SuccessModalText.title,
    },
    {
      disableCancel: false,
      icon: "question",
      open: triggersaveModal,
      okHandler: () => postData(),
      cancelHandler: () =>
        dispatch(setValue({ expr: "saveDataOnclick", value: false })),
      message: saveData.message,
      cancelText: saveData.cancelText,
      okText: saveData.okText,
      title: saveData.title,
    },
    {
      disableCancel: false,
      icon: "question",
      open: triggerupdateModal,
      okHandler: () => UpdateData(),
      cancelHandler: () =>
        dispatch(setValue({ expr: "updateDataOnclick", value: false })),
      message: updateIndInfos.message,
      cancelText: updateIndInfos.cancelText,
      okText: updateIndInfos.okText,
      title: updateIndInfos.title,
    },
  ];

  const [trigger, setTrigger] = useState<boolean>(false);
  useImperativeHandle(ref, () => ({
    test: () => {
      setTrigger(!trigger);
    },
  }));

  const validateDataSave = () => {
    dispatch(tier1SaveOnclick());
  };
  const postData = async () => {
    dispatch(setValue({ expr: "saveDataOnclick", value: false }));
    try {
      setBusyLoader(saveModalData.progress);

      await posting (
        `DriverPermitHolderCategory/CreateDriversPermitHolderCategory`,
        {
          dpcName: name,
          dpcShtName: shortname,
          dpcDriver: drivername,
          dpcPermitHolder: permitholder,
          dpcOrder: order,
          dpcActive: active,
          dpcRmks: remarks,
          dpcCreatedBy: userId,
        }, `Created Drivers permit holder category ${name}`
      );

      setRefresh(!refresh);
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));

      const exprs: exprsTypes[] = [
        { expr: "saveDataOnclick", value: false },
        { expr: "successModalState", value: true },
        { expr: "successText", value: saveSuccessText },
      ];
      for (let i = 0; i < exprs.length; i++) {
        dispatch(setValue({ expr: exprs[i].expr, value: exprs[i].value }));
      }

      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    } catch (error: any) {
      setErr({
        state: true,
        title: saveModalData.error.title,
        message: saveModalData.error.message,
      });
    } finally {
      setBusyLoader("");
    }
  };

  const validateDataUpdate = () => {
    dispatch(tier1UpdateOnclick());
  };
  const UpdateData = async () => {
    dispatch(setValue({ expr: "updateDataOnclick", value: false }));
    try {
      setBusyLoader(updateModalData.progress);

      await updating(
        `DriverPermitHolderCategory/UpdateDriverPermitHoldersCategory?Id=${selectedID}`,
        {
          dpcName: name,
          dpcShtName: shortname,
          dpcDriver: drivername,
          dpcPermitHolder: permitholder,
          dpcOrder: order,
          dpcActive: active,
          dpcRmks: remarks,
          dpcEditedBy: userId,
        }, `Updated Driver permit holders category to ${name}`
      );

      setRefresh(!refresh);

      dispatch(setGeneralValue({ expr: "updateMode", value: false }));

      const exprs: exprsTypes[] = [
        { expr: "updateDataOnclick", value: false },
        { expr: "successModalState", value: true },
        { expr: "successText", value: updateSuccessText },
      ];
      for (let i = 0; i < exprs.length; i++) {
        dispatch(setValue({ expr: exprs[i].expr, value: exprs[i].value }));
      }
    } catch (error: any) {
      setErr({
        state: true,
        title: updateModalData.error.title,
        message: updateModalData.error.message,
      });
    } finally {
      setBusyLoader("");
    }
  };

  const [data, error, loading] = useFetch(
    `DriverPermitHolderCategory/SearchDriversPermitHoldersCategory?active=${status}&search=${searchText.text}`,
    refresh,
    "dpcIDpk"
  );
  useEffect(() => {
    if (data) {
      storeExprs();
      dispatch(setGeneralValue({ expr: "idExpr", value: idExpr }));
      dispatch(setGeneralValue({ expr: "formData", value: data }));
    }

    loading ? setBusyLoader(".") : setBusyLoader("");
  }, [data, loading]);

  const form_header = "Driver/Permit Holder Category Details";

  return (
    <>
      {error && <WarningAlert />}

      {modals.map(
        (
          {
            disableCancel,
            icon,
            okHandler,
            cancelHandler,
            open,
            cancelText,
            okText,
            message,
            title,
          }: modalTypes,
          index
        ) => {
          return (
            <div key={index}>
              <ModalTemplate
                icon={icon && icon}
                disableCancel={disableCancel}
                cancelText={cancelText && cancelText}
                open={open}
                okHandler={okHandler}
                cancelHandler={cancelHandler}
                message={message}
                okText={okText}
                title={title}
              />
            </div>
          );
        }
      )}

      <Setups_Template
        searchTextChange={(newText) => {
          setSearchText({ temp: newText, text: searchText.text });
        }}
        handleSearch={() => {
          setSearchText({ text: searchText.temp, temp: searchText.temp });
          setStatus(activeStatus);
          setRefresh((current) => !current);
        }}
        handleRefresh={() => {
          setSearchText({ text: "", temp: "" });
          setStatus("");
          setRefresh((current) => !current);
        
        }}
        moreCheckboxes={{ label: "Driver?", label2: "Permit Holder?" }}
        createData={validateDataSave}
        updateData={validateDataUpdate}
        datagridClicked={() => {}}
        trigger={trigger}
        datagridColumns={assets_vehicleDPHCategoriesColumns}
        datagridData={data}
        formHeader={form_header}
      />
    </>
  );
});
