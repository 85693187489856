import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react'
import { Form, Input, Select, DatePicker, Checkbox } from 'antd';
import { CheckBox } from 'devextreme-react/check-box';
import reset from '../../../../../assets/reset.png';
import { Cancel, NewButton, SaveButton, UpdateButton } from '../../../../accessories/buttons';
import { api_url, saveModalData, updateModalData } from '../../../../accessories/component_infos';
import axios from 'axios';
import dayjs from 'dayjs';
import { ModalTemplate } from '../../../../../templates/modal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../app/store';
import { task_footer_update, task_form_disable } from '../../../../../features/Task&Todo/Task/TaskDetails';
import { inputsTypes } from '../../../../human-resource/setups/data/types/selectsTypes';
import { DateTemplate } from '../../../../../templates/date';
import { TextareaTemplate } from '../../../../../templates/textarea';
import { UpdateDataFunc } from '../../../../../functions/update';
interface props {
    objectiveDataList: any,
    nameDataList: any
    toggleRefresh: any
}



export const Objective_Team_Member_Details = forwardRef(({ objectiveDataList, nameDataList, toggleRefresh }: props, ref: any) => {
    const { Option } = Select;
    const { TextArea } = Input;



const borderTheme = useSelector((state:RootState)=>state.general.cssBorderClass)


    const options = [];
    for (let i = 1; i < 36; i++) {
        options.push({
            label: i.toString(36) + i,
            value: i.toString(36) + i,
        });
    }

    // inits
    const [selectedDataId, setSelectedDataId] = useState('');
    const [selectedObjective, setSelectedObjective] = useState({ id: '', name: '' })
    const [selectedName, setSelectedName] = useState({ id: '', name: '' })
    const [startDate, setStartDate] = useState<any>(null);
    const [endDate, setEndDate] = useState<any>(null);
    const [remarks, setRemarks] = useState('');
    const [owner, setOwner] = useState(false);

    const [showModalDisableButton, setShowModalDisableButton] = useState(false);

    const [openModal, setOpenModal] = useState(false);
    const [modalData, setModalData] = useState<any>(saveModalData);
    const [icon, setIcon] = useState<'warning' | 'success' | 'question'>();

    const [disableForms, setDisableForms] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [showCancel, setShowCancel] = useState(false);
    // const [objectivesData, setObjectivesData] = useState([])

    const validateData = async () => {
        console.log(selectedName.name)
        setIcon('warning');
        if (startDate === null) {
            setShowModalDisableButton(true);
            setModalData({ message: 'Please select a start date', title: 'Enter start date', okText: 'Ok' },)
            setOpenModal(true);
        }
        else if (endDate === null) {
            setShowModalDisableButton(true);
            setModalData({ message: 'Please select a end date', title: 'Enter end date', okText: 'Ok' },)
            setOpenModal(true);
        }
        else if (selectedObjective.name === '') {
            setShowModalDisableButton(true);
            setModalData({ message: 'Please select an objective', title: 'Select objective', okText: 'Ok' },)
            setOpenModal(true);
        }
        else if (selectedName.name === '') {
            setShowModalDisableButton(true);
            setModalData({ message: 'Please select a name', title: 'Select name', okText: 'Ok' },)
            setOpenModal(true);

        }
        else {
            setIcon('question');
            setShowModalDisableButton(false);
            setOpenModal(true);
        }

    }

    const clearFields = async () => {
        setSelectedName({ name: '', id: '' });
        setSelectedObjective({ name: '', id: '' });
        setRemarks('');
        setOwner(false);
        setStartDate(null);
        setEndDate(null);
        setUpdating(false);
    }

    useImperativeHandle(ref, () => ({

        doubleClick: () => {

            setShowCancel(true);
            setUpdating(true);
            setDisableForms(false);
        },
        singleClick: (selectedRow: any) => {
            console.log(selectedRow);
            // setDisableForms(true);
            // console.log('from mini row');
            // console.log(selectedRow);
            setSelectedDataId(selectedRow.otmIDpk)
            setSelectedName({ id: selectedRow.memberEmpID, name: selectedRow.memberEmpName });
            // for(var a = 0; a < objectiveDataList.length; a++){
            //     if(objectiveDataList[a].id === selectedRow.objIdpk){
            //         setSelectedObjective({name: objectiveDataList[a].name, id: objectiveDataList[a].id})

            //     }
            // }
            setStartDate(dayjs(selectedRow.mvsStartDate));
            setEndDate(dayjs(selectedRow.mvsEndDate));
            setRemarks(selectedRow.otmRmks);
            setOwner(selectedRow.otmOwner);
            setDisableForms(true);
            setUpdating(false);
            setShowCancel(false);
            setSelectedObjective({ name: selectedRow.objName, id: selectedRow.objIDpk })
            // setMissionVisionData(selectedRow.mvsMission);
            // setPerspectiveData(selectedRow.mvsPtvName);

        }


    }));
    const dateFormat = " DD MMM YYYY HH: mm:ss";

    /**
  **************************************
  ************************************** 
  ***********Api Integrations***********
  **************************************
  **************************************
  */
    const updateData = async () => {
        console.log('here')
        console.log({
            "otmIdpk": selectedDataId,
            "otmObjectiveIdfk": selectedObjective.id,
            "otmEmpIdfk": selectedName.id,
            "otmStartDate": `${dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss[Z]')}`,
            "otmEndDate": `${dayjs(endDate).format('YYYY-MM-DDTHH:mm:ss[Z]')}`,
            "otmOwner": owner,
            "otmActive": true,
            "otmRmks": remarks,
            "otmEditedBy": 1,
            "otmEditedDate": "2023-02-10T14:01:24.609Z"
        })
        await UpdateDataFunc("PfmObjectives/UpdatePfmObjectiveTeamMember", {
            "otmIdpk": selectedDataId,
            "otmObjectiveIdfk": selectedObjective.id,
            "otmEmpIdfk": selectedName.id,
            "otmStartDate": `${dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss[Z]')}`,
            "otmEndDate": `${dayjs(endDate).format('YYYY-MM-DDTHH:mm:ss[Z]')}`,
            "otmOwner": owner,
            "otmActive": true,
            "otmRmks": remarks,
            "otmEditedBy": 1,
            "otmEditedDate": "2023-02-10T14:01:24.609Z"
        });
        
        //close modal
        setOpenModal(false);
        //Refresh table
        setShowModalDisableButton(true);
        setIcon('success');
        setModalData({ message: 'Record successfully updated', title: 'Record updated', okText: 'Ok' },)
        setOpenModal(true);
        toggleRefresh();


    }

    const postData = async () => {
        // dispatch(setValue({expr: 'saveDataOnclick' ,value : false})) //disable modal
        try {
            console.log({
                "otmObjectiveIdfk": selectedObjective.id,
                "otmEmpIdfk": selectedName.id,
                "otmStartDate": `${dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss[Z]')}`,
                "otmEndDate": `${dayjs(endDate).format('YYYY-MM-DDTHH:mm:ss[Z]')}`,
                "otmOwner": owner,
                //confirm boolean value
                "otmActive": true,
                "otmRmks": remarks,
                "otmCreatedBy": 1,
            })

            const res = await axios.post(`${api_url}/api/PfmObjectives/CreatePfmObjectiveTeamMember`,
                // {
                //     "otmObjectiveIdfk": selectedObjective.id,
                //     "otmEmpIdfk": selectedName.id,
                //     "otmStartDate": `${dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss[Z]')}`,
                //     "otmEndDate":  `${dayjs(endDate).format('YYYY-MM-DDTHH:mm:ss[Z]')}`,
                //     "otmOwner": owner,
                //     //confirm boolean value
                //     "otmActive": true,
                //     "otmRmks": remarks,
                //     "otmCreatedBy": 1,
                //   }
                {
                    "otmObjectiveIdfk": selectedObjective.id,
                    "otmEmpIdfk": selectedName.id,
                    "otmStartDate": `${dayjs(startDate).format('YYYY-MM-DD')}`,
                    "otmEndDate": `${dayjs(endDate).format('YYYY-MM-DD')}`,
                    "otmOwner": owner,
                    "otmActive": true,
                    "otmRmks": remarks,
                    "otmCreatedBy": 1
                }
            )


            setOpenModal(false);
            setShowModalDisableButton(true);
            setIcon('success');
            setModalData({ message: 'Record successfully saved', title: 'Record saved', okText: 'Ok' },)
            setOpenModal(true);
            toggleRefresh();

            // const exprs:exprsTypes[] = [{expr: 'saveDataOnclick' ,value : false},{expr: 'successModalState' ,value : true},{expr: 'successText' ,value : saveSuccessText}];
            // for (let i = 0; i < exprs.length; i++) {            
            //     dispatch(setValue({expr: exprs[i].expr ,value : exprs[i].value}));
            // }
        } catch (error: any) {
            console.log(error)
            //set states for db errors modal
            // setErrState(true);
            // seterrTitle(error.response ? error.response.data.message : 'Server Error');
            // seterrMessage(error.response ? error.response.data.errors[0] : 'Error connecting to server. Please contact system administrator');
        }
    }


    const disablex = useSelector((state: RootState) => state.addTask.taskFormStatus);
    const updateMode = useSelector((state: RootState) => state.addTask.taskFooterUpdate);
    const dispatch = useDispatch();


    useEffect(() => {
        if (!disablex) {
            setShowCancel(!disablex);

            setShowCancel(true); setDisableForms(false); clearFields();

            dispatch(task_form_disable(false));

            //  this destroys the update mode state
            dispatch(task_footer_update(false));


        } else {
            setShowCancel(false); setDisableForms(true); setUpdating(false)
        }
        if (updateMode) {

            setShowCancel(true);
            setUpdating(true);
            setDisableForms(false);
        }

    }, [disablex, updateMode])

    const dates: inputsTypes[] = [
        { id: 0, label: '', stateName: 'startDate', defaultValue: startDate, style: '' },
        { id: 1, label: '', stateName: 'endDate', defaultValue: endDate, style: '' },
    ]

    console.log("DATES", {
        startDate,
        endDate
    })

    return (

        <div style={{ width: '900px', minWidth: '900px', height: '100%',borderColor:borderTheme }} className="  border-[1px]   dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base  ">
            <ModalTemplate icon={icon} cancelText={modalData.cancelText} cancelHandler={() => { setOpenModal(false) }} disableCancel={showModalDisableButton} open={openModal} okHandler={() => { showModalDisableButton === true ? setOpenModal(false) : updating === true ? updateData() : postData(); }} message={modalData.message} okText={modalData.okText} title={modalData.title} />

            {/* <h4 className=" w-full  mb-2 bg-slate-100  ">Task Details</h4> */}
            <div style={{ maxHeight: '280px', minHeight: '280px', height: '280px' }} className=' items-start justify-start '   >

                <Form
                    className='w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 px-2 '
                    name="basic"
                    // labelCol={{ span: 6}}
                    // wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    size='small'
                    layout='horizontal'

                >
                    <Form.Item
                        label={<p className='dark:bg-fill dark:text-darkModeSkin-base text-xs'>{'Date'}</p>}
                        labelCol={{ span: 2 }}
                    >
                        <div className="flex flex-row">
                            {dates.map(({ id, label, stateName, defaultValue, style }) => {
                                return (
                                    <>
                                        <DateTemplate
                                            label={label}
                                            style={{ marginLeft: id == 1 && 10 }}
                                            selectedDate={defaultValue}
                                            changeDate={(e: any) => {
                                                id == 0 && setStartDate(e)
                                                id == 1 && setEndDate(e)

                                            }}
                                            format={"DD MMM YYYY"}
                                            disabled={disableForms}
                                        />
                                    </>
                                )
                            })}

                        </div>
                    </Form.Item>
                    <Form.Item
                        label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{'Objective'}</p>}
                        className={' mb-1'}
                        labelCol={{ span: 2 }}
                    >
                        <div className="flex flex-row items-center">
                            <Select
                                disabled={disableForms}
                                onChange={(option) => {
                                    console.log(option)

                                    setSelectedObjective({ id: JSON.parse(option)['objIDpk'], name: JSON.parse(option)['objName'] })
                                }}
                                className='w-full mr-0'
                                placeholder=""
                                allowClear
                                value={selectedObjective.name}
                            >
                                {objectiveDataList.map((option: any, index: number) => {
                                    return (
                                        <option key={index} value={JSON.stringify(option)}> {option['objName']}</option>
                                    )
                                })}
                            </Select>

                            <span onClick={() => {
                                setSelectedObjective({ id: '', name: '' })
                            }}
                                style={{ height: 22.5, marginBottom: 2, borderWidth: 1, width: 23.9,borderColor:borderTheme }}
                                className='ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded'>
                                <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src={reset} alt="reset" />
                                {/* <img className='' src= {''} alt="" /> */}
                            </span>


                        </div>
                    </Form.Item>
                    <Form.Item
                        label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{'Name'}</p>}
                        className={' mb-1'}
                        labelCol={{ span: 2 }}
                    >
                        <div className="flex flex-row items-center">
                            <Select
                                disabled={disableForms}
                                onChange={(option) => {
                                    console.log(option)
                                    setSelectedName({ id: JSON.parse(option)['empIDpk'], name: JSON.parse(option)['empName'] })
                                }}
                                className='w-full mr-0'
                                placeholder=""
                                allowClear
                                value={selectedName.name}
                            >
                                {nameDataList.map((option: any, index: number) => {
                                    return (
                                        <option key={index} value={JSON.stringify(option)}>{String(option['empName'])}</option>
                                    )
                                })}
                            </Select>

                            <span onClick={() => {
                                setSelectedName({ id: '', name: '' })
                            }}
                                style={{ height: 22.5, marginBottom: 2, borderWidth: 1, width: 23.9,borderColor:borderTheme }}
                                className='ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded'>
                                <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src={reset} alt="reset" />
                                {/* <img className='' src= {''} alt="" /> */}
                            </span>


                        </div>
                    </Form.Item>
                    <TextareaTemplate 
                        height={160}
                        label={"Remarks"} 
                        labelCol={2}
                        useCallbackFunc
                        defaultValue={remarks}
                        readonly={disableForms}
                        setCallbackValue={(e:any)=>{ setRemarks(e.target.value)}}
                    />
                    {/* <Form.Item
                        className='mb-0'
                        labelCol={{ span: 2 }}

                        label={<p className='dark:bg-fill dark:text-darkModeSkin-base text-xs'>{"Remarks"}</p>}
                        rules={[{ required: false, }]}
                    >
                        <div className="flex flex-row ">
                            <TextArea readOnly={disableForms} value={remarks} onChange={(e: any) => { setRemarks(e.target.value) }} style={{ resize: 'none', height: '160px' }} className='w-full ' />


                        </div>
                    </Form.Item> */}

                    <div className='w-full  flex flex-col mt-0.5 '>
                        <div style={{ width: 'calc(900px - 32px)', marginLeft: '11px' }} className=' flex  justify-between  '>
                            {/* <li className='flex justify-start '><Cancel/></li> */}
                            <li className=' flex items-center flex-row'><p className='mr-2'>Owner? : </p>  <Checkbox style={{borderColor:borderTheme}} disabled={disableForms} className='border rounded px-1' onChange={(e: any) => { setOwner(e.target.checked) }} checked={owner} /></li>
                            <ul className='flex justify-end  '>
                                {updating === true ? <UpdateButton handleUpdate={() => { setShowModalDisableButton(false); setModalData(updateModalData); validateData() }} /> : <SaveButton
                                    disableButton={disableForms}
                                    handleSave={() => {
                                        setModalData(saveModalData); validateData();
                                    }} />}
                                {showCancel === true ? <Cancel handleCallbackCancel={() => { console.log('fas'); clearFields(); setShowCancel(false); setUpdating(false); clearFields(); setDisableForms(true); }} /> : <NewButton useCallbackFunc={true} new_button_toggler={() => { setShowCancel(true); setShowModalDisableButton(false); setModalData(saveModalData); clearFields(); setDisableForms(false) }} />}

                            </ul>
                        </div>
                    </div>
                </Form>
            </div>



        </div>
    )
});