export const assets_vehicleRCColumns = [
  {
    id: 0,
    caption: "ID",
    dataField: "vctIdpk",
    dataType: "number",
    width: 80,
    fixed: true,
    visible: false,
  },
  {
    id: 1,
    caption: "VEHICLE REQUEST CATEGORY",
    dataField: "vctName",
    dataType: "string",
    width: 200,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "SHORT NAME",
    dataField: "vctShtName",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 3,
    caption: "ORDER",
    dataField: "vctOrder",
    dataType: "string",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 4,
    caption: "PERSONNEL?",
    dataField: "vctPersonal",
    dataType: "boolean",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 5,
    caption: "ACTIVE?",
    dataField: "vctActive",
    dataType: "boolean",
    width: 90,
    fixed: true,
    visible: true,
  },
  {
    id: 6,
    caption: "REMARKS",
    dataField: "vctRmks",
    dataType: "string",
    width: 5000,
    fixed: false,
    visible: true,
  },
];
