import axios from "axios"
import { api_url } from "../components/accessories/component_infos";
import { useToken } from "antd/es/theme/internal";

import { userDataCrypt } from "./userDataEncrypt";
import { requestConfig } from "../general/requestConfig";

const token = useToken;

export const DeleteDataFunc=async(url:string)=>{

    
    const deleteResponse=await axios.delete(
        `${api_url}/${url}`,
        requestConfig()
        
    )
    return deleteResponse;
}

//action message: Deleted image for vehicle with Reg No " & rowx("vdtRegNo"