import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../app/store";
import { useEffect } from "react";
import { userModel } from "../components/human-resource/setups/data/types/selectsTypes";

export const useAccountInfo = (): [number, number, userModel] => {
  const navigate = useNavigate();
  const userID = useSelector(
    (state: RootState) => state.user?.user?.userModel?.usaIDpk
  ); //user ID
  const employeeID = useSelector(
    (state: RootState) => state?.user.user?.userModel?.empIDpk
  ); //emp id
  const userModel = useSelector(
    (state: RootState) => state?.user.user?.userModel
  ); //emp id

  const user: userModel = userModel;

  useEffect(() => {
    if (!userID || !employeeID) {
      navigate("/");
    }
  }, []);

  return [employeeID, userID, user];
};
