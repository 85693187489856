import React, { useCallback, useRef,useState  } from 'react'
import {
    DataGrid,
    Column,
    ColumnFixing,
    Scrolling,
    Selection,
    Editing,
    SearchPanel,
    GroupPanel,
    HeaderFilter
} from 'devextreme-react/data-grid';


interface props{
    // this means whether a prop is passed on the component or not the component called
    // anywhere should work (i.e. with the ?) but without the ? it takes it's strict rule
    // that without a prop there will be an error 
    toggler ?: ()=> void
    customHeightHook ?: any
    columns ?:any
    gridheight ?: number
}

export const Mini_datagrid_template = ({toggler,columns,gridheight}:props)=>{

    const [isActive, setIsActive] = useState<boolean>(false)


   
    const [selectedTask_update, setSelectedTask_update] = useState();

    const selectGrid_update:any = useCallback<any>((e:any) => {
        e.component.byKey(e.currentSelectedRowKeys[0]).done((item:any) => {
            setSelectedTask_update(item);
        });
    }, []);

    
    

    return (
        
        <div className=''>
            
                            <div style={{height:''}} className='flex justify-between w-full h-full bg-slate-50 dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base'>
                            {/* <h4 className=" justify-start  w-32 ml-4 mb-2 bg-slate-200 ">Tasks Update</h4> */}
                                
                            </div>
                            
                            {/* Datagrid table */}
                            <div style={{width:'100%'}} className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base">
                            <DataGrid 
                                 
                                allowColumnReordering={true}
                                // dataSource ={TaskUpdateData}
                                keyExpr={"ID"}
                                height={gridheight}
                                
                                // columnWidth = {100}
                                rowAlternationEnabled={true}
                                showBorders={true}
                                showRowLines={true}
                                allowColumnResizing={true}
                                onSelectionChanged={selectGrid_update}
                                wordWrapEnabled={false}
                                focusedRowEnabled={true}
                                
                                
                            >
                                {/* STAFF DETAILS COLUMNS */}
                {/* FIXED COLUMNS */}
                {columns.map((column:any)=>{
                    return(
                        <Column
                            
                            key={column.id}
                            dataField= {column.dataField}
                            caption = {column.caption}
                            dataType= {column.dataType}
                            width= {column.width}
                            alignment='left'
                            // alignment = {column.dataType === 'boolean' ? 'center' : 'left'}
                            fixed = {column.fixed}
                            // allowFiltering = {column.dataType === 'boolean'? false : true}
                            // headerCellRender={renderTitleHeader}
                            // visible = {column.visible}
                        />
                    )
                })}

                                
                                
                                <HeaderFilter visible={true} allowSearch={true}/>
                                <SearchPanel width={300} visible={true}/>
                                <GroupPanel visible={true} />
                             
                                <Editing mode={'row'}  />
                                <Scrolling columnRenderingMode="virtual" mode= 'virtual' showScrollbar={'always'}/>
                                <Selection mode="single" />
                            </DataGrid>
                            </div>

                            

                    
        </div>
    )
}

