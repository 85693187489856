import { Calendar } from "devextreme-react";
import { useState, forwardRef, useImperativeHandle } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import useResizeMode from "../../../../hooks/useResizeMode";

interface options {
  pageResized: Function;
}

const CalendarComponent = forwardRef(({}: options, ref) => {
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const navbarHeight = 58.05;
  const sidenavTitle = 32;
  const sidenavbottomBar = 45;
  const boxspacingY = 6;

  const [viewHeight, setViewHeight] = useState(
    window.innerHeight -
      navbarHeight -
      sidenavTitle -
      sidenavbottomBar -
      boxspacingY -
      news_flash_height
  );

  useImperativeHandle(ref, () => ({
    test: () => {
      setViewHeight(
        window.innerHeight -
          navbarHeight -
          sidenavTitle -
          sidenavbottomBar -
          boxspacingY -
          news_flash_height
      );
    },
  }));
  // let  [resizeOnf11,initialSize] = useResizeMode(window.innerWidth === screen.width && window.innerHeight === screen.height);
  let  [resizeOnf11,initialSize] = useResizeMode(window.innerWidth  && window.innerHeight);

  let  normalPageSize =  initialSize - 215;
  let  onF11PageSize = resizeOnf11 - 215;
  return (
    <div style={{ height: window.innerWidth  && window.innerHeight  ? normalPageSize:onF11PageSize }} className="widget-container mt-2">
      <Calendar
        id="calendar-container"
        value={Date()}
        onValueChanged={() => {}}
        onOptionChanged={() => {}}
        min={""}
        max={""}
        style={{
          marginLeft: "5%",
          marginRight: "5%",
          marginTop: "1%",
          justify: "center",
        }}
        firstDayOfWeek={0}
        disabled={false}
        zoomLevel={"month"}
      />
    </div>
  );
});

export default CalendarComponent;
