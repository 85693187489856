import { useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import SetupsDatalistTemplat from "../setups_datalist_template";
import InputSelect_template from "../../../../../templates/InputSelect";

interface props {
  nameValue: string;
}
const Hr_Setups_Name_Datalist = ({ nameValue }: props) => {
  const label: string = nameValue;
  const tier = useSelector((state: RootState) => state.general.tier); // get form tier

  const tier1name = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.name
  );
  const tier2name = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.name
  );

  //---nameExpr---
  const CustomDatalistExprs = useSelector(
    (state: RootState) => state.general.CustomDatalistExprs
  ); //custom datasets exprs
  const nameExprObj = useSelector(
    (state: RootState) => state.general.inputExprs
  );
  const nameExpr = nameExprObj.nameExpr;
  const idExpr = nameExprObj.idExpr;

  return (
    <>
      <SetupsDatalistTemplat
        options={[]}
        datalistStyle={{ height: 24 }}
        idExpr={idExpr}
        nameExpr={nameExpr}
        listid="name"
        label={label}
      />

      {/* <InputSelect_template
        useCallFunc
        options={[]}
        selectStyle={{ height: 24 }}
        idexpr={idExpr}
        dataexp={nameExpr}
        key={"name"}
        label={label}
      /> */}
    </>
  );
};

export default Hr_Setups_Name_Datalist;
