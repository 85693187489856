/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { formPropsConst } from "../../accessories/constants";
import { Form } from "antd";
import { CheckboxTemlate } from "../../../templates/checkbox";
import { checkboxTypes } from "../../human-resource/setups/data/types/selectsTypes";
import { InputsTemplate } from "../../../templates/input";
import Datagrid_template from "../../../templates/Datagrid";
import {
  Cancel,
  CustomizableButton,
  SaveButton,
} from "../../accessories/buttons";
import save from "../../../assets/floppy-disk.png";
import numbers from "../../../assets/numbers.png";
import useResizeMode from "../../../hooks/useResizeMode";
import { GetDataFunc } from "../../../functions/get";
import { smsSettings_cols } from "./data/smsSettings_col";
import useFetch from "../../../hooks/useFetch";
import { ModalTemplate } from "../../../templates/modal";
import { RefreshButtonTemplate } from "../../../templates/RefreshButtonTemplate";
import { setGeneralValue } from "../../../features/generalSlice";
import { useDispatch } from "react-redux";
import { useFormPriviledge } from "../../../hooks/useFormPriviledge";
import { useCrudFunc } from "../../../functions/crud"; 

interface stateTypes {
  enableSendSms: boolean;
  allowNssSendSms: boolean;
  allowAttPersonnelSendSms: boolean;
  allowVolunPersonnelSendSms: boolean;
  sendAllSmses: boolean;
  pswdReset: number; 
  smsCapNoExp: number;  
  refresh: boolean;
}
const SmsSettings = () => {
  const [initial, final] = useResizeMode(window.innerHeight);
  const gridheight = initial - 350;
  const dispatch = useDispatch();
  const [PostDataFunc, UpdateDataFunc] = useCrudFunc();
  const [states, setstate] = useState<stateTypes>({
    enableSendSms: false,
    allowNssSendSms: false,
    allowAttPersonnelSendSms: false,
    allowVolunPersonnelSendSms: false,
    sendAllSmses: false,
    pswdReset: 0,  
    smsCapNoExp: 0,     
    refresh: false,
  });
  const [curCheck, setCurCheck] = useState<any>({
    name: "",
    value: false,
  });
  const [modalData, setModalData] = useState<any>({
    okText: "Ok",
    cancelText: "No, Cancel",
    title: "Confirm Update?",
    message: "Are you sure?",
  });
  const [icon, setIcon] = useState<any>("warning");
  const [openModal, setOpenModal] = useState<boolean>(false);

  const checkboxes1: checkboxTypes[] = [
    {
      id: 0,
      label: "Enable Sending of SMS",
      stateName: "enableSendSms",
      defaultValue: states.enableSendSms,
    },
    {
      id: 1,
      label: "Allow National Service Personnel to Send",
      stateName: "allowNssSendSms",
      defaultValue: states.allowNssSendSms,
    },
  ];

  const checkboxes2: checkboxTypes[] = [
    {
      id: 0,
      label: "Allow Attachment Personnel to Send SMS",
      stateName: "allowAttPersonnelSendSms",
      defaultValue: states.allowAttPersonnelSendSms,
    },
    {
      id: 1,
      label: "Allow Voluntary Service Personnel to Send",
      stateName: "allowVolunPersonnelSendSms",
      defaultValue: states.allowVolunPersonnelSendSms,
    },
  ];

  const checkboxes3: checkboxTypes[] = [
    {
      id: 0,
      label: "Send All SMSs from Server",
      stateName: "sendAllSmses",
      defaultValue: states.sendAllSmses,
    }
  ];
  const [smsData, setSmsData] = useState<any>([]);
  const [customSmsData, customSmsDataLoading, customSmsDataErr] = useFetch(
    `GeneralSettings/PopDgvSmsWithCustomSmsCap`,
    states.refresh
  );
  const [noCustomSmsData, noCustomSmsDataLoading, noCustomSmsDataErr] = useFetch(
    `GeneralSettings/PopDgvSmsWithNoCustomSmsCap`,
    states.refresh
  ); 
  useEffect(() => {
    const popDatagrid = () => {
      const addedCols = {
        sscNewCap: "",
        smsAdd: false,
      };
      const addedNoCustomCols = {
        sscRmks: "",
        sscCap: "0"
      };
      customSmsData.forEach((obj) => Object.assign(obj, addedCols));
      noCustomSmsData.forEach((obj) => Object.assign(obj, addedCols, addedNoCustomCols));
      const gridData = [...customSmsData, ...noCustomSmsData];
      setSmsData(gridData);
    }    
    popDatagrid(); 
  },[customSmsData,noCustomSmsData]);

  const [privType, setPrivType] = useState("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);
  const saveAccessPriv = () => {
    setPrivType("save");
    if (savePriv === true) {            
      dispatch(setGeneralValue({ expr: "formDisabledPriv", value: false }));
      setIcon("question");
      setModalData({
        message: "Are you sure you want to update sms cap for staff?",
        title: "Confirm Save?",
        okText: "Yes",
      });
      setOpenModal(true);
    } else {
      dispatch(setGeneralValue({ expr: "formDisabledPriv", value: true }));
      setIcon("warning");
      setModalData({
        message: "No Access Privilege",
        title: "No Access!",
        okText: "Ok",
      });
      setOpenModal(true);
      return;
    }
  };

  const postSave = useCallback(async () => { 
    for (const sms of smsData) { 
      const cap = +sms.sscNewCap.trim();
      if (!isNaN(cap) && cap !== +sms.sscCap && sms.smsAdd) {
        try {
          await UpdateDataFunc(
            `GeneralSettings/DeactivatePreviousCap`,
            {},"", {'empIDpk': sms.empIDpk}
          ); 
            const res = await PostDataFunc(`GeneralSettings/CreateGenSetting`, {
              sscEmpIDfk: sms.empIDpk,
              sscCap: cap,
              sscActive: true,
              sscRmks: sms.sscRmks,
            },
              ""
            ); 
            if(!res.status) {
              throw Error(res.data.message);
            }
        } catch (e) {
          setIcon("warning");
          setModalData({
            message: e.message ?? "Something went wrong. Try again later",
            title: "Unexpected Error!",
            okText: "Ok",
          });
          setOpenModal(true);
        }
      }
    }
    setIcon("success");
    setModalData({
      message: "Sms cap updated successfully for staff",
      title: "Success",
      okText: "Ok",
    });
    setOpenModal(true);
    setstate((prev) => ({ ...prev, refresh: !prev.refresh }));
  },[smsData,UpdateDataFunc, PostDataFunc]);

  const fetchCaps = async () => {
    const smsCapNoExpRes = await GetDataFunc(
      `GeneralSettings/SmsDailyCapForAllStaffWithNoException`
    );
    if (smsCapNoExpRes.data.length > 0) {
      setstate((prev) => ({
        ...prev,
        smsCapNoExp: smsCapNoExpRes.data[0].stlValue,
      }));
    }
    const pswdResetRes = await GetDataFunc(
      `GeneralSettings/DailySmsPswdResetCapForAllStaff`
    );
    if (pswdResetRes.data.length > 0) {
      setstate((prev) => ({
        ...prev,
        pswdReset: pswdResetRes.data[0].stlValue,
      }));
    }
  }

  const initStates = async () => {
    const enableSendSmsRes = await GetDataFunc(`GeneralSettings/EnableSMS`);
    if (enableSendSmsRes.data.length > 0) {
      setstate((prev) => ({
        ...prev,
        enableSendSms: enableSendSmsRes.data[0].stlValue === "0" ? false : true,
      }));
    }

    const allowNssSendSmsRes = await GetDataFunc(
      `GeneralSettings/AllowNSPToSendSMS`
    ); 
    if (allowNssSendSmsRes.data.length > 0) {
      setstate((prev) => ({
        ...prev,
        allowNssSendSms:
          allowNssSendSmsRes.data[0].stlValue === "0" ? false : true,
      }));
    }
    const allowAttPersonnelSendSmsRes = await GetDataFunc(
      `GeneralSettings/AllowAttachmentPersonnelToSendSMS`
    );
    if (allowAttPersonnelSendSmsRes.data.length > 0) {
      setstate((prev) => ({
        ...prev,
        allowAttPersonnelSendSms:
          allowAttPersonnelSendSmsRes.data[0].stlValue === "0" ? false : true,
      }));
    }
    const allowVolunPersonnelSendSmsRes = await GetDataFunc(
      `GeneralSettings/AllowVoluntaryPersonnelToSendSMS`
    );
    if (allowVolunPersonnelSendSmsRes.data.length > 0) {
      setstate((prev) => ({
        ...prev,
        allowVolunPersonnelSendSms:
          allowVolunPersonnelSendSmsRes.data[0].stlValue === "0" ? false : true,
      }));
    }
    const sendAllSmsesRes = await GetDataFunc(
      `GeneralSettings/SendAllSMSsFromServer`
    );
    if (sendAllSmsesRes.data.length > 0) {
      setstate((prev) => ({
        ...prev,
        sendAllSmses: sendAllSmsesRes.data[0].stlValue === "0" ? false : true,
      }));
    }
    fetchCaps();
  };

  const confirmSetting = (stateName: string) => {
    setIcon("question");
    switch (stateName) {
      case "enableSendSms":
        setModalData({
          message: "Are you sure you want to update Sending of SMS Privilege?",
          title: "Confirm Update?",
          okText: "Yes",
        });
        break;
      case "sendAllSmses":
        setModalData({
          message:
            "Are you sure you want to update Send All SMSes from Server Privilege?",
          title: "Confirm Update?",
          okText: "Yes",
        });
        break;
      case "allowVolunPersonnelSendSms":
        setModalData({
          message:
            "Are you sure you want to update Voluntary Personnel SMS Privilege?",
          title: "Confirm Update?",
          okText: "Yes",
        });
        break;
      case "allowNssSendSms":
        setModalData({
          message:
            "Are you sure you want to update National Service Personnel SMS Privilege?",
          title: "Confirm Update?",
          okText: "Yes",
        });
        break;
      case "allowAttPersonnelSendSms":
        setModalData({
          message:
            "Are you sure you want to update Attachment Personnel SMS Privileges?",
          title: "Confirm Update?",
          okText: "Yes",
        });
        break;
      default:
        break;
    }
    setOpenModal(true);
  };

  const deleteSMS = async () => { 
    for (const sms of smsData) {
      if(sms.smsAdd === true) {
        try {
        await UpdateDataFunc(`GeneralSettings/DeleteStaffSmsCap?sscEmpIDfk=${sms.empIDpk}`,{},"");
        }catch(e) {
          setIcon("warning");
          setModalData({
            message: e.message ?? "Something went wrong. Try again later",
            title: "Unexpected Error!",
            okText: "Ok",
          });
          setOpenModal(true);
        }
      }
    }
    setIcon("success");
    setModalData({
      message: "Sms cap deleted successfully for staff",
      title: "Success",
      okText: "Ok",
    });
    setOpenModal(true);
    setstate((prev) => ({ ...prev, refresh: !prev.refresh }));
  }

  const postSetting = async (stateName: string, value: boolean) => {
    let endpointUrl = "GeneralSettings/";
    let settingsID = 0;
    switch (stateName) {
      case "enableSendSms":
        endpointUrl += `EnableSendingOfSms`;
        settingsID = 7;
        break;
      case "sendAllSmses":
        endpointUrl += `ChkAllowSendSmsFromServer`;
        settingsID = NaN;
        break;
      case "allowVolunPersonnelSendSms":
        endpointUrl += `AllowVoluntaryPersonnelToSendSms`;
        settingsID = 12;
        break;
      case "allowNssSendSms":
        endpointUrl += `ChkAllowNspToSendSms`;
        settingsID = 10;
        break;
      case "allowAttPersonnelSendSms":
        endpointUrl += `ChkAllowAttToSendSms`;
        settingsID = 11;
        break;
      default:
        break;
    }
    // TODO: Lukman will continue this for, provide the real IDs from the endpoint
    try {
      const postRes = await PostDataFunc(endpointUrl, {
        stlSettingIDfk: settingsID,
        stlValue: value ? "1" : "0",
      },"");
      if (postRes?.status) {
        //display modal
        setIcon("success");
        setModalData({
          message: "Successfully updated privilege",
          title: "Success",
          okText: "Ok",
        });
        setOpenModal(true);
        setstate((prev) => ({ ...prev, [stateName]: value }));
      } else {
        throw Error("Unable to update privilege");
      }
    } catch (e: any) {
      setIcon("warning");
      setModalData({
        message: e.message ?? "Something went wrong. Try Again Later.",
        title: "Unexpected Error!",
        okText: "Ok",
      });
      setOpenModal(true);
    }
  };

  const validateAllSmsCapSave = () => {
    setIcon("warning");
    if(isNaN(+states.smsCapNoExp)) {
      setModalData({
        message: "Enter a valid number for SMS cap",
        title: "Validation Error!",
        okText: "Ok",
      });
    }else{
    setIcon("question");
    setModalData({
        message: "Are you sure you want to update sms cap for all staff",
        title: "Confirm SMS Save?",
        okText: "Yes",
      }); 
    }
      setOpenModal(true);
  }
  const handleAllSmsCapSave = async () => {
    try {
      const res = await PostDataFunc(`GeneralSettings/UpdateAllSMSCap?SmsCap=${states.smsCapNoExp}`,{},"");
    if(res.data.success) {
      fetchCaps();
      setIcon("success");
      setModalData({
        message: "Sms cap for all staff successfully saved",
        title: "Success",
        okText: "Ok",
      });
      setOpenModal(true);
    }else {
      throw Error(res.data.message);
    }
  }catch(e) {
    setIcon("warning");
      setModalData({
        message: e.message ?? "Something went wrong. Try Again Later.",
        title: "Unexpected Error!",
        okText: "Ok",
      });
      setOpenModal(true);
    }
  }
  const validateResetPasswordCap = () => {
    setIcon("warning"); 
    if(isNaN(+states.pswdReset)) {
      setModalData({
        message: "Enter a valid number for Reset password cap",
        title: "Validation Error!",
        okText: "Ok",
      });
    }else{
    setIcon("question");
    setModalData({
        message: "Are you sure you want to update reset password cap all staff",
        title: "Confirm Password Reset Save?",
        okText: "Yes",
      }); 
    }
      setOpenModal(true);
  }
  const handleResetPasswordCap = async () => {
    try{
      const res = await PostDataFunc(`GeneralSettings/ResetPasswordCap?PasswordResetCap=${states.pswdReset}`,{},"");
      if(res.data.success) {
      fetchCaps();
      setIcon("success");
      setModalData({
        message: "Password reset cap for all staff successfully saved",
        title: "Success",
        okText: "Ok",
      });
      setOpenModal(true);
    }else {
      throw Error(res.data.message);
    }
  }catch(e) {
    setIcon("warning");
      setModalData({
        message: e.message ?? "Something went wrong. Try Again Later.",
        title: "Unexpected Error!",
        okText: "Ok",
      });
      setOpenModal(true);
    }
  }

  useEffect(() => {
    initStates();
  }, []);

  return (
    <div className="w-full">
      <ModalTemplate
        icon={icon}
        open={openModal}
        disableCancel={icon === "warning" || icon === "success" ? true : false}
        cancelText={modalData.cancelText}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        okHandler={() => {
          if (modalData.title === "Confirm Update?") {
            postSetting(curCheck.name, curCheck.value);
          } else if (modalData.title === "Confirm Save?") {       
            postSave();
          }else if(modalData.title === "Confirm Delete?") {
            deleteSMS();
          }else if(modalData.title === "Confirm SMS Save?") {
            handleAllSmsCapSave();
          }else if(modalData.title === "Confirm Password Reset Save?") {
            handleResetPasswordCap();
          }
          setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
      />
      <Form
        labelCol={{ span: 2 }}
        // wrapperCol={{span: 10}}
        className="w-full py-4 px-2 "
        size="small"
        layout="horizontal"
      >
        <section className="w-full border-[1px] rounded-sm  items-center">
          {/* top section */}
          <section className="w-full flex justify-evenly space-y-1 px-1 ">
            <div className="w-1/3">
              {checkboxes1.map(({ id, label, stateName, defaultValue }) => {
                return (
                  <div
                    id={id + ""}
                    className=" flex justify-start items-center space-x-1 py-1"
                  >
                    <CheckboxTemlate
                      useCallbackFunc
                      span
                      customDisabled={false}
                      withBorders
                      defaultValue={defaultValue}
                      setCheckboxOnchange={(e) => { 
                        setCurCheck({ name: stateName, value: e });
                        confirmSetting(stateName);
                      }}
                    />
                    <p className="w-fulll flex items-center">{label}</p>
                  </div>
                );
              })}
            </div>

            <div className="w-1/3">
              {checkboxes2.map(({ id, label, stateName, defaultValue }) => {
                return (
                  <div
                    id={id + ""}
                    className=" flex items-center space-x-1 py-1"
                  >
                    <CheckboxTemlate
                      useCallbackFunc
                      span
                      customDisabled={false}
                      withBorders
                      defaultValue={defaultValue}
                      setCheckboxOnchange={(e) => { 
                        setCurCheck({ name: stateName, value: e });
                        confirmSetting(stateName);
                      }}
                    />
                    <p className="w-fulll flex items-center">{label}</p>
                  </div>
                );
              })}
            </div>

            <div className="w-1/3">
              {checkboxes3.map(({ id, label, stateName, defaultValue }) => {
                return (
                  <div className=" flex items-center space-x-1 py-1 ">
                    <CheckboxTemlate
                      useCallbackFunc
                      span
                      customDisabled={false}
                      withBorders
                      defaultValue={defaultValue}
                      setCheckboxOnchange={(e) => { 
                        setCurCheck({ name: stateName, value: e });
                        confirmSetting(stateName);
                      }}
                    />
                    <p className="w-full flex items-center">{label}</p>
                  </div>
                );
              })}
            </div>
          </section>

          {/* inputs */}

          <Form.Item
            label={<p className="text-xs">{"SMS Cap Per Day"}</p>}
            labelCol={{ span: 2 }}
            className="py-5 items-center"
          >
            <div className="w-[60%] flex justify-between py-1 items-center space-x-1">
              <InputsTemplate
                disabledStatus={false}
                useCallbackFunc
                numberOnly
                span
                orderOnchange={(check: any) => setstate((prev) => ({ ...prev, smsCapNoExp: check }))}
                defaultValue={states.smsCapNoExp}
                additionalWidget={
                  <div className="flex justify-center items-center space-x-1 ml-1">
                    <RefreshButtonTemplate handleRefresh={fetchCaps} />
                    <CustomizableButton
                      classname={""}
                      customizableBtnImage={save}
                      handleCustomizableClick={validateAllSmsCapSave}
                    />
                  </div>
                }
              />

              <InputsTemplate
                defaultValue={states.pswdReset}
                disabledStatus={false}
                useCallbackFunc
                label={"Password SMS Reset Per Cap/Day"}
                numberOnly
                span
                orderOnchange={(check: any) => setstate((prev) => ({ ...prev, pswdReset: check }))}
                additionalWidget={
                  <div className="flex justify-center items-center space-x-1 ml-1">
                    <RefreshButtonTemplate handleRefresh={fetchCaps} />
                    <CustomizableButton
                      classname={""}
                      customizableBtnImage={save}
                      handleCustomizableClick={validateResetPasswordCap}
                    />
                  </div>
                }
              />
            </div>
          </Form.Item>
        </section>

        {/* Datagrid */}

        <section className="">
          <Datagrid_template 
            allowColumnEditing
            gridheight={gridheight}
            columns={smsSettings_cols}
            data={smsData}
            disableGroupPanel
          />

          <section className="w-full flex justify-between">
            <aside className="w-1/2 flex justify-end">
              <InputsTemplate
                useCallbackFunc
                label={"Fill All SMS Cap"}
                numberOnly
                additionalWidget={
                  <div className="flex items-center">
                    <CustomizableButton
                      classname={"ml-1"}
                      customizableBtnImage={numbers}
                    />
                  </div>
                }
              />
            </aside>

            <aside className="w-1/2 flex justify-end">
              <SaveButton disableButton={false} handleSave={saveAccessPriv} />
              <Cancel useCallbackFunc text="Delete" cancel_button_toggler={() => {
                setIcon("question");
                setModalData({
                  message: "Are you sure you want to delete selected sms cap?",
                  title: "Confirm Delete?",
                  okText: "Yes",
                });
                setOpenModal(true);
              }}/>
            </aside>
          </section>
        </section>
      </Form>
    </div>
  );
};

export default SmsSettings;
