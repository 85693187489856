export const driverCodeAsignmentsColumns = [
  {
    id: 0,
    caption: "STAFF No",
    dataField: "empStaffNo",
    dataType: "",
    width: 200,
    fixed: true,
    visible: true,
  },
  {
    id: 1,
    caption: "DRIVER",
    dataField: "empName",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "JOB TITLE",
    dataField: "jbtShtName",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 3,
    caption: "CODE",
    dataField: "dcaCode",
    dataType: "string",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 4,
    caption: "SERVICE PROVIDER",
    dataField: "spdName",
    dataType: "string",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 5,
    caption: "ASSIGNMENT DATE",
    dataField: "dcaAssignDate",
    dataType: "datetime",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 6,
    caption: "END DATE",
    dataField: "dcaEndDate",
    dataType: "datetime",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 7,
    caption: "INTERCOM",
    dataField: "empIntercom",
    dataType: "string",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 8,
    caption: "MOB NO",
    dataField: "empMobNo",
    dataType: "",
    width: 160,
    fixed: false,
    visible: true,
  },
  {
    id: 9,
    caption: "DEPT",
    dataField: "dptShtName",
    dataType: "string",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 10,
    caption: "SECTION",
    dataField: "sxnShtName",
    dataType: "string",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 11,
    caption: "LOCATION",
    dataField: "locShtName",
    dataType: "string",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 12,
    caption: "ACTIVE",
    dataField: "dcaActive",
    dataType: "boolean",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 13,
    caption: "REMARKS",
    dataField: "dcaRmks",
    dataType: "string",
    width: 5000,
    fixed: false,
    visible: true,
  },
  {
    id: 14,
    caption: "ID",
    dataField: "dcaIDpk",
    dataType: "",
    width: 5000,
    fixed: false,
    visible: false,
  },
];

export const vfca_cols = [
  {
    id: 0,
    caption: "Driver Code",
    dataField: "sptDriverCode",
    alignment: "left",
    fixed: true,
    width: 130,
  },
  {
    id: 1,
    caption: "Service Provider",
    dataField: "spdName",
    alignment: "left",
    fixed: true,
    width: 130,
  },
  {
    id: 2,
    caption: "Contact",
    dataField: "spdMainContact",
    alignment: "left",
    fixed: true,
    width: 130,
  },
  {
    id: 3,
    caption: "Mob No",
    dataField: "spdMobNos",
    alignment: "left",
    fixed: true,
    width: 130,
  },
];

export const spd_cols = [
  {
    id: 0,
    caption: "Service Provider",
    dataField: "spdName",
    alignment: "left",
    fixed: true,
    width: 130,
  },
  {
    id: 1,
    caption: "Driver Code",
    dataField: "sptDriverCode",
    alignment: "left",
    fixed: true,
    width: 130,
  },
];

export const Driver_Col = [
  {
    id: 0,
    caption: "Staff No",
    dataField: "empStaffNo",
    alignment: "left",
    fixed: true,
    width: 120,
  },
  {
    id: 1,
    caption: "Driver Name",
    dataField: "empName",
    alignment: "left",
    fixed: true,
    width: 130,
  },
  {
    id: 2,
    caption: "Department",
    dataField: "dptShtName",
    alignment: "left",
    fixed: true,
    width: 130,
  },
  {
    id: 3,
    caption: "Section",
    dataField: "sxnShtName",
    alignment: "left",
    fixed: true,
    width: 120,
  },
  {
    id: 4,
    caption: "Driver Intercom",
    dataField: "empIntercom",
    alignment: "left",
    fixed: true,
    width: 130,
  },
  {
    id: 5,
    caption: "Driver Mobile No",
    dataField: "empMobNox",
    alignment: "left",
    fixed: true,
    width: 130,
  },
  {
    id: 6,
    caption: "Location",
    dataField: "locShtName",
    alignment: "left",
    fixed: true,
    width: 130,
  },
  {
    id: 7,
    caption: "Job Title",
    dataField: "jbtShtName",
    alignment: "left",
    fixed: true,
    width: 130,
  },
];

export const Drivercode_Col = [
  {
    id: 0,
    caption: "Driver ID",
    dataField: "id",
    alignment: "left",
    fixed: true,
    width: 120,
  },
  {
    id: 1,
    caption: "Driver Code",
    dataField: "code",
    alignment: "left",
    fixed: true,
    width: 130,
  },
];

export const Service_Pcol = [
  {
    id: 0,
    caption: "Service Provider ID",
    dataField: "id",
    alignment: "left",
    fixed: true,
    width: 120,
  },
  {
    id: 1,
    caption: "Service Provider Name",
    dataField: "code",
    alignment: "left",
    fixed: true,
    width: 130,
  },
];
