import { TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/accordion";

import { useEffect, useState } from "react";
import VehicleConditions from "./Vehicle-Condition-Returns/widgets/vehicleConditions";
import VehicleDataUpload from "./Vehicle-Data-Upload/widgets/vehicleDataUpload";
import VehicleDetails from "./Vehicle-Details/widgets/vehicleDetails";
import VehicleCard from "./Vehicle-Fuel-Card-Asssignments/widgets/vehicleCard";
import VehicleImages from "./Vehicle-Images/widgets/vehicleImages";
import VehicleLogBook from "./Vehicle-Log-Book/widgets/vehicleLogBook";
import VehicleRequestsSecurity from "./Vehicle-Requests-Security/widgets/vehicle-request-security";

import VehicleRequests from "./Vehicle-Requests/widgets/vehicleRequests";
import VehicleSimCard from "./Vehicle-SIM-Card-Assignments/widgets/vehicleSimCard";

interface props {
  selected: string;
}

const VehiclesEntriesScreen = ({ selected }: props) => {
  const [tabs, setTabs] = useState<string[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (tabs.indexOf(selected) > -1 === true) { //TODO: error prone
      setCurrentIndex(tabs.indexOf(selected));
    } else {
      setTabs([...tabs, selected]);
      setCurrentIndex(tabs.length);
    }
  }, [window.location.pathname]);

  function onSelectionChanged(args: any) {
    if (args) {
      setCurrentIndex(args.value);
    }
  }
  return (
    <div className="w-full h-full flex justify-center items-center">
      <TabPanel
        loop={false}
        animationEnabled={false}
        swipeEnabled={true}
        selectedIndex={currentIndex}
        className="w-full h-full"
        onOptionChanged={(e: any) => {
          onSelectionChanged(e);
        }}
      >
        {tabs.map((tab) => {
          switch (tab) {
            case "TMS Vehicle Assignments":
              return <Item title={tab}>{/* <VehicleAssignments /> */}</Item>;
            case "Transport-Vehicle-SIM Card Assignment":
              return (
                <Item title={tab}>
                  <VehicleSimCard />
                </Item>
              );

            case "TMS-Vehicle Requests":
              return (
                <Item title={tab}>
                  <VehicleRequests />
                </Item>
              );

            case "Asset Mgr-Transport-Vehicle Conditiolln Returns":
              return (
                <Item title={tab}>
                  <VehicleConditions />
                </Item>
              );

            case "Asset Mgr-Transport-Vehicle Details":
              return (
                <Item title={tab}>
                  <VehicleDetails />
                </Item>
              );

            case "Asset Mgr-Transport-Vehicle Images":
              return (
                <Item title={tab}>
                  <VehicleImages />
                </Item>
              );

            case "Transport - Vehicle Log Book":
              return (
                <Item title={tab}>
                  <VehicleLogBook />
                </Item>
              );

            case "Asset Mgr-Transport-Vehicle-Card Assignment":
              return (
                <Item title={tab}>
                  <VehicleCard />
                </Item>
              );

            case "Asset Mgr-Transport-Vehicle Data Upload":
              return (
                <Item title={tab}>
                  <VehicleDataUpload />
                </Item>
              );

            // Transport - Vehicle Requests
            case "Transport - Vehicle Requests":
              return (
                <Item title={tab}>
                  <VehicleRequestsSecurity />
                </Item>
              );

            default:
              return <Item title={tab}></Item>;
          }
        })}
      </TabPanel>
    </div>
  );
};

export default VehiclesEntriesScreen;
