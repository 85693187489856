/* eslint-disable react/jsx-pascal-case */
import { Checkbox, DatePicker, Form, Select } from "antd";
import axios from "axios";
import {
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  api_url,
  currentDate,
  navbar_height,
  putError,
  saveModalData,
  toolbar_height,
  updateModalData,
} from "../../../../../../accessories/component_infos";
import dayjs from "dayjs";
import useFetch from "../../../../../../../hooks/useFetch";
import {
  administrator_TH,
  approvingOfficer_TH,
  director_TH,
  driver_data_TH,
  manager_TH,
  vehicleController_TH,
  workShop_TH,
} from "../data/vehicle-assignments-data";
import { DataGrid, DropDownBox } from "devextreme-react";
import { Column, Pager } from "devextreme-react/data-grid";

import save from "../../../../../../../assets/floppy-disk.png";
import {
  UpdateButton,
  Cancel,
  NewButton,
} from "../../../../../../accessories/buttons";
import TextArea from "antd/es/input/TextArea";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import {
  task_footer_table_selected,
  task_form_disable,
  task_footer_update,
} from "../../../../../../../features/Task&Todo/Task/TaskDetails";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";

// ----------------Eagle Mentality🦅 -------------------------------------------------------------------------------
import {
  setNew_Cancel_Button,
  setEdit_Cancel_Button,
  setSave_Update_Button,
  setDisableForm,
} from "../../../../../../../features/VehicleRedux/TMS-Sicers/Vehicles/VehicleAssignmentSlice";
import { setGeneralValue } from "../../../../../../../features/generalSlice";
import { UpdateDataFunc } from "../../../../../../../functions/update";

import { SelectsTemplate } from "../../../../../../../templates/select";

import { DropdownGrid_template } from "../../../../../../../templates/DropdownGrid";
import { useCrudFunc } from "../../../../../../../functions/crud";
import { AllDocShare } from "../../../Fuel/Document/widgets/AllDocShare";
import { PatchDataFunc } from "../../../../../../../functions/patch";
import {
  dropdownGridTemplateTypes,
  selectsTypes,
} from "../../../../../../human-resource/setups/data/types/selectsTypes";
import InputSelect_template from "../../../../../../../templates/InputSelect";
import { debounce } from "lodash";
import {
  fetchDept,
  fetchLocation,
  fetchSection,
  fetchTransport,
  fetchUnit,
} from "../../../../../../../features/apiCalls/vehAssign";
import { UpdateStates } from "../../../../../../../functions/updateStates";
import { DateTemplate } from "../../../../../../../templates/date";
import { useBusyLoader } from "../../../../../../../hooks/useBusyLoader";
// ---------------------------------------------------------------------------------------------------------------------------

interface props {
  a?: any;
  refresh?: any;
  allStates?: any;
  setAllState?: (e: any) => void;
  addedSelected?: any;
  setAddSelected?: (e: any) => void;
  update?: any;
  setUpdateId?: (e: any) => void;
  setOuterSelected?: (e: any) => void;
  setShowDataOnSecondGrid?: (e: any) => void;
  outerSelected?: any;
}
const VehicleAssignmentDropDowns = forwardRef(
  (
    {
      a,
      refresh,
      allStates,
      setAllState,
      addedSelected,
      setAddSelected,
      update,
      setUpdateId,
      setOuterSelected,
      setShowDataOnSecondGrid,
      outerSelected,
    }: props,
    ref
  ) => {
    const news_flash_height = useSelector(
      (state: RootState) => state.general.newsflashHeight
    );
    const heights_out = navbar_height + news_flash_height + toolbar_height;
    const { Option } = Select;
    const borderTheme = useSelector(
      (state: RootState) => state.general.cssBorderClass
    );
    const form_disable = useSelector(
      (state: RootState) => state.addTask.taskFormStatus
    );
    const updateMode = useSelector(
      (state: RootState) => state.addTask.taskFooterUpdate
    );
    const dispatch = useDispatch();
    const vehA_Clear = useSelector(
      (state: RootState) => state.general.vehA_Clear
    );

    // ---------------------------- Eagle Mentality🦅   -------------------------------------

    let NewAndCancelButton = useSelector(
      (state: RootState) => state.TMSModule1.VehicleAssignment.New_Cancel_Button
    );
    let EditAndCancelButton = useSelector(
      (state: RootState) =>
        state.TMSModule1.VehicleAssignment.Edit_Cancel_Button
    );
    let DisableForm = useSelector(
      (state: RootState) => state.TMSModule1.VehicleAssignment.disableForm
    );
    let SaveAndUpdateButton = useSelector(
      (state: RootState) =>
        state.TMSModule1.VehicleAssignment.save_update_Button
    );
    const Vdispatch = useDispatch();
    // ---------------------------------------------------------------------------------------

    // STATE INITIALIZATIONS

    const updateState = (key: string, value: any) => {
      setAllState!((prev: any) => ({ ...prev, [key]: value }));
    };

    const [lookups, lookupsErr, lookupsLoading] = useFetch(
      `AssetTamVehicleAssignment/GetLookUps?TrUnitDptId=${1}&TrUnitSxnId=${1}&TrUnitUntId=${1}&AssetcolIDpk=${1}&AssetDate=${"2020-01-01"}&SecDptId=${1}&SecdptActive=${true}&UnitDptId=${1}&UnitsxnId=${1}&pagesize=${20}&pagenumber=${1}&search=${""}`
    );
    // const [fetchSection]=useFetch(`AssetTamVehicleAssignment/GetAllSection?DptId=${allStates.department.id}&dptActive=${true}&pageNumber=1&pageSize=50&search=${allStates.searchSection}`)
    // const[fetchLocation]=useFetch(`AssetTamVehicleAssignment/GetLocation?pageNumber=1&pageSize=20&search=${allStates.searchLocation}`)
    // const [fetchUnit]=useFetch(`AssetTamVehicleAssignment/GetUnit?DptId=${allStates?.department.id}&sxnId=${allStates?.section.id}&pageNumber=1&pageSize=20&search=${allStates.searchUnit}`)
    // const [fetchTransportUnit]=useFetch(`AssetTamVehicleAssignment/GetAllTransportUnit?dptId=${allStates?.department.id}&sxnId=${allStates?.section.id}&untId=${allStates?.unit.id}&pageNumber=1&pageSize=20&search=${allStates.searchTransportUnit}`)
    // const [fetchDepartment]=useFetch(`AssetTamVehicleAssignment/GetDepartment?pageNumber=1&pageSize=50&search=${allStates.searchDepartment}`)
    console.log("ALLsTATES", allStates);
    console.log("lookups", lookups);
    // API CALLS;
    // let [D_Refresh, setD_Refresh] = useState(false);
    // const [department_data, department_error, department_loading] = useFetch(
    //   `AssetTamVehicleAssignment/GetDepartment`,
    //   D_Refresh
    // );
    // let [S_Refresh, setS_Refresh] = useState(false);
    // const [section_data, section_error, section_loading] = useFetch(
    //   `AssetTamVehicleAssignment/GetAllSection?DptId=${allStates.department.id}&dptActive=${allStates.active}`
    // );
    // let [U_Refresh, setU_Refresh] = useState(false);
    // const [unit_data, unit_error, unit_loading] = useFetch(
    //   `AssetTamVehicleDetail/GetPopUnitsAssetTamVehicleDetails?dptname=${allStates.department.name}&sxnname=${allStates.section.name}`,
    //   U_Refresh
    // );

    // let [T_Refresh, setT_Refresh] = useState(false);
    // const [transportUnit_data, transportUnit_error, transportUnit_loading] =
    //   useFetch(
    //     // `AssetTamVehicleAssignment/GetAllTransportUnit?dptId=${22}&sxnId=${59}&untId=${1842}`, //this
    //     `AssetTamVehicleAssignment/GetAllTransportUnit?dptId=${allStates.department.id}&sxnId=${allStates.section.id}&untId=${allStates.unit.id}`,
    //     T_Refresh
    //   );
    // let [L_Refresh, setL_Refresh] = useState(false);
    // const [location_data, location_error, location_loading] = useFetch(
    //   `AssetTamVehicleAssignment/GetLocation`,
    //   L_Refresh
    // );
    // let [A_Refresh, setA_Refresh] = useState(false);
    // const [
    //   approvingOfficer_data,
    //   approvingOfficer_error,
    //   approvingOfficer_loading,
    // ] = useFetch(`AssetTamVehicleAssignment/GetApprovingOfficers`, A_Refresh);

    // let [DR_Refresh, setDR_Refresh] = useState(false);
    // const [driver_data, driver_error, driver_loading] = useFetch(
    //   `AssetTamVehicleAssignment/GetDriver`,
    //   DR_Refresh
    // );
    // let [V_Refresh, setV_Refresh] = useState(false);
    // const [
    //   vehicleController_data,
    //   vehicleController_error,
    //   vehicleController_loading,
    // ] = useFetch(`AssetTamVehicleAssignment/GetControllers`, V_Refresh);

    // let [AD_Refresh, setAD_Refresh] = useState(false);
    // const [administrator_data, administrator_error, administrator_loading] =
    //   useFetch(
    //     `AssetTamVehicleAssignment/GetAdministratorVehicleAssignment`,
    //     AD_Refresh
    //   );

    // let [M_Refresh, setM_Refresh] = useState(false);
    // const [manager_data, manager_error, manager_loading] = useFetch(
    //   `AssetTamVehicleAssignment/GetManager`,
    //   M_Refresh
    // );

    // let [Dir_Refresh, setDir_Refresh] = useState(false);
    // const [director_data, director_error, director_loading] = useFetch(
    //   `AssetTamVehicleAssignment/GetDirector`,
    //   Dir_Refresh
    // );

    // let [W_Refresh, setW_Refresh] = useState(false);
    // const [workShop_data, workShop_error, workShop_loading] = useFetch(
    //   `AssetTamVehicleAssignment/GetDefaultWorkShop`,
    //   W_Refresh
    // );

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [Icon, setIcon] = useState<"warning" | "question" | "success">(
      "warning"
    );
    // const [modalData, setModalData] = useState<any>(saveModalData);
    const [modalData, setModalData] = useState<{
      icon: "warning" | "question" | "success";
      message: string;
      title: string;
      okText: string;
      cancelText: string;
      disableCancel: boolean;
      openModal: boolean;
      okHandler: () => void;
      cancelHandler: () => void;
    }>({
      icon: "warning",
      message: "",
      title: "",
      okText: "Yes",
      cancelText: "No",
      disableCancel: false,
      openModal: false,
      okHandler: () => {},
      cancelHandler: () => {},
    });
    const [disableButton, setDisableButton] = useState<boolean>(false);
    // const [showCancel, setShowCancel] = useState<boolean>(false);

    // const [showUpdate, setShowUpdate] = useState<boolean>();

    const dropdownRef = useRef<any>(null);
    const managerdropdownRef = useRef<any>(null);
    const directordropdownRef = useRef<any>(null);
    const workshopdropdownRef = useRef<any>(null);
    const driverdropdownRef = useRef<any>(null);
    const controllerdropdownRef = useRef<any>(null);
    const approveOfficerdropdownRef = useRef<any>({});

    const [PostDataFunc, UpdateDataFunc, PatchDataFunc] = useCrudFunc();

    const close = () => {
      dropdownRef.current!.instance.close();
      managerdropdownRef.current!.instance.close();
      directordropdownRef.current!.instance.close();
      workshopdropdownRef.current!.instance.close();
      driverdropdownRef.current!.instance.close();
      controllerdropdownRef.current!.instance.close();
      approveOfficerdropdownRef.current!.instance.close();
    };

    const department_data = useSelector(
      (state: RootState) => state.vehAssignCalls.departData
    );
    const section_data = useSelector(
      (state: RootState) => state.vehAssignCalls.sectionData
    );
    const unit_data = useSelector(
      (state: RootState) => state.vehAssignCalls.unitData
    );
    const trans_data = useSelector(
      (state: RootState) => state.vehAssignCalls.transData
    );
    const location_data = useSelector(
      (state: RootState) => state.vehAssignCalls.locationData
    );

    console.log("REDUX", {
      department_data,
      section_data,
      unit_data,
      trans_data,
      location_data,
    });

    const selects: selectsTypes[] = [
      {
        id: 0,
        label: "Department",
        idExpr: "dptIdpk",
        dataExpr: "dptName",
        optionsData:
          allStates.department?.length > 0
            ? department_data
            : lookups?.department, // states.regNo?.length > 0 ? regNumber_data : lookups.registrationAsset,//allStates.department?.length > 0 ? department_data : lookups?.department
        stateName: "department",
        registerName: "searchDepartment",
        defaultValue: allStates.department.name,
        isOpenDropdown: allStates.isOpenDropdown1,
        isOpenDropdownKey: "isOpenDropdown1",
        style: "",
        handleFocus: () => {},
        singleEndpoint: fetchDept,
      },
      {
        id: 1,
        label: "Section",
        idExpr: "sxnIdpk",
        dataExpr: "sxnName",
        registerName: "searchSection",
        optionsData:
          allStates.department.name?.length || allStates.section?.length > 0
            ? section_data
            : lookups?.section, // states.regNo?.length > 0 ? regNumber_data : lookups.registrationAsset,//allStates.section.name?.length || allStates.section?.length > 0 ? section_data : lookups?.section
        stateName: "section",
        defaultValue: allStates.section.name,
        isOpenDropdown: allStates.isOpenDropdown2,
        isOpenDropdownKey: "isOpenDropdown2",
        style: "",
        handleFocus: () => {},
        singleEndpoint: fetchSection,
      },
      {
        id: 2,
        label: "Unit",
        idExpr: "untIdpk",
        dataExpr: "untName",
        registerName: "searchUnit",
        optionsData:
          allStates.unit?.length || allStates.section?.name?.length > 0
            ? unit_data
            : lookups?.unit, // states.regNo?.length > 0 ? regNumber_data : lookups.registrationAsset,//allStates.unit?.length > 0 ? unit_data : lookups?.unit
        stateName: "unit",
        defaultValue: allStates.unit.name,
        isOpenDropdown: allStates.isOpenDropdown3,
        isOpenDropdownKey: "isOpenDropdown3",
        style: "",
        handleFocus: () => {},
        singleEndpoint: fetchUnit,
      },
      {
        id: 3,
        label: "Transport Unit",
        idExpr: "truIdpk",
        dataExpr: "truName",
        registerName: "searchTransportUnit",
        optionsData:
          allStates.transportUnit?.length || allStates.unit.name?.length > 0
            ? trans_data
            : lookups?.transport, // states.regNo?.length > 0 ? regNumber_data : lookups.registrationAsset,//allStates.transportUnit?.length > 0 ? trans_data : lookups?.transport
        stateName: "transportUnit",
        defaultValue: allStates.transportUnit.name,
        isOpenDropdown: allStates.isOpenDropdown4,
        isOpenDropdownKey: "isOpenDropdown4",
        style: "",
        handleFocus: () => {},
        singleEndpoint: fetchTransport,
      },
      {
        id: 4,
        label: "Location",
        idExpr: "locIdpk",
        dataExpr: "locName",
        registerName: "searchLocation",
        optionsData:
          allStates.location.name?.length ||
          allStates.transportUnit.name?.length > 0
            ? location_data
            : lookups?.location, // states.regNo?.length > 0 ? regNumber_data : lookups.registrationAsset,
        stateName: "location",
        defaultValue: allStates.location.name,
        isOpenDropdown: allStates.isOpenDropdown5,
        isOpenDropdownKey: "isOpenDropdown5",
        style: "",
        handleFocus: () => {},
        singleEndpoint: fetchLocation,
      },
    ];

    const DataGridSelectBox: dropdownGridTemplateTypes[] = [
      {
        label: "Approving Officer",
        width: 380,
        // labelCol: 5,
        labelCol: 5,
        columns: approvingOfficer_TH,
        data: lookups?.approvingOfficerAssignment,
        defaultValue: allStates?.approvingOfficer.name,
        stateName: "approvingOfficer",
        idExpr: "ownerEmpIDfk",
        dataExpr: "ownerEmpName",

        handleRefresh: () => {
          setAllState!({
            ...allStates,
            ["approvingOfficer"]: {
              name: "",
              id: "",
            },
          });
          // setA_Refresh(!A_Refresh);
        },
      },
      {
        label: "Driver",
        width: 380,
        // labelCol: 5,
        labelCol: 5,
        columns: driver_data_TH,
        data: lookups?.driver, //driver_data,
        defaultValue: allStates?.driver.name,
        stateName: "driver",
        idExpr: "driverEmpIDfk",
        dataExpr: "driverEmpName",
        handleRefresh: () => {
          setAllState!({
            ...allStates,
            ["driver"]: {
              name: "",
              id: "",
            },
          });
          // setDR_Refresh(!DR_Refresh);
        },
      },
      {
        label: "Vehicle Controller",
        width: 380,
        // labelCol: 5,
        labelCol: 5,
        columns: vehicleController_TH,
        data: lookups?.controllers,
        defaultValue: allStates.vehicleController.name,
        stateName: "vehicleController",
        idExpr: "controllerEmpIDfk",
        dataExpr: "controllerEmpName",

        handleRefresh: () => {
          setAllState!({
            ...allStates,
            ["vehicleController"]: {
              name: "",
              id: "",
            },
          });
          // setV_Refresh(!V_Refresh);
        },
      },
      {
        label: "Administrator",
        width: 380,
        // labelCol: 5,
        labelCol: 5,
        columns: administrator_TH,
        data: lookups?.adminAssignment, //administrator_data,
        defaultValue: allStates.administrator.name,
        stateName: "administrator",
        idExpr: "adminEmpIDfk",
        dataExpr: "adminEmpName",

        handleRefresh: () => {
          setAllState!({
            ...allStates,
            ["administrator"]: {
              name: "",
              id: "",
            },
          });
          // setAD_Refresh(!AD_Refresh);
        },
      },
      {
        label: "Manager",
        width: 380,
        // labelCol: 5,
        labelCol: 5,
        columns: manager_TH,
        data: lookups?.managers, //manager_data,
        defaultValue: allStates.manager.name,
        stateName: "manager",
        idExpr: "mgrEmpIDfk",
        dataExpr: "mgrEmpName",

        handleRefresh: () => {
          setAllState!({
            ...allStates,
            ["manager"]: {
              name: "",
              id: "",
            },
          });
          // setM_Refresh(!M_Refresh);
        },
      },
      {
        label: "Director",
        width: 380,
        // labelCol: 5,
        labelCol: 5,
        columns: director_TH,
        data: lookups?.director, //director_data,
        defaultValue: allStates.director.name,
        stateName: "director",
        idExpr: "dirEmpIDfk",
        dataExpr: "dirEmpName",

        handleRefresh: () => {
          setAllState!({
            ...allStates,
            ["director"]: {
              name: "",
              id: "",
            },
          });
          // setDir_Refresh(!Dir_Refresh);
        },
      },

      {
        label: "Default Workshop",
        width: 380,
        // labelCol: 5,
        labelCol: 5,
        columns: workShop_TH,
        data: lookups?.defaultWorkshop, //workShop_data,
        defaultValue: allStates.defaultWorkshop.name,
        stateName: "defaultWorkshop",
        idExpr: "wsdIdpk",
        dataExpr: "wsdName",

        handleRefresh: () => {
          setAllState!({
            ...allStates,
            ["defaultWorkshop"]: {
              name: "",
              id: "",
            },
          });
          // setW_Refresh(!W_Refresh);
        },
      },
    ];

    const resetField = () => {
      setAllState!((prevState: any) => ({
        ...prevState,
        ["startDate"]: currentDate,
        ["endDate"]: currentDate,
        ["department"]: { name: "", id: 0 },
        ["section"]: { name: "", id: 0 },
        ["unit"]: { name: "", id: 0 },
        ["transportUnit"]: { name: "", id: 0 },
        ["location"]: { name: "", id: 0 },
        ["approvingOfficer"]: { name: "", id: 0 },
        ["driver"]: { name: "", id: 0 },
        ["vehicleController"]: { name: "", id: 0 },
        ["administrator"]: { name: "", id: 0 },
        ["manager"]: { name: "", id: 0 },
        ["director"]: { name: "", id: 0 },
        ["defaultWorkshop"]: { name: "", id: 0 },
        ["remarks"]: "",
        ["active"]: true,
        ["footerId"]: { id: 0 },
      }));
      setUpdateId!("");
      setOuterSelected && setOuterSelected({});
      setShowDataOnSecondGrid && setShowDataOnSecondGrid([]);
      setAddSelected!([]);
    };

    function triggerSave() {
      let element;
      for (let index = 0; index < addedSelected.length; index++) {
        element = addedSelected[index].vdtIDpk;
        // setModalData(saveModalData);
        // setIcon("question");
        saveData(element);
      }
    }

    function triggerUpdate() {
      console.log("addedSelected: ", addedSelected);
      // setModalData(updateModalData);
      // setIcon("question");
      let element1;
      let element2;
      for (let index = 0; index < addedSelected.length; index++) {
        element1 = addedSelected[index].vdtIDpk;
        element2 = addedSelected[index].vasIDpk;
        // console.log("element: ", element);
        UpdateData(element1,element2);
      }
    }

    useImperativeHandle(ref, () => ({
      fill: (e: any) => {
        console.log("$#@ useImp", e);
        setAllState!({
          ...allStates,
          ["startDate"]: e?.vasStartDate,
          ["endDate"]: e?.vasEndDate,
          ["department"]: { name: e?.dptName, id: e?.dptIDpk },
          ["section"]: { name: e?.sxnName, id: e?.sxnIDpk },
          ["unit"]: { name: e?.untName, id: e?.untIDpk },
          ["transportUnit"]: { name: e?.truName, id: e?.truIDpk },
          ["location"]: { name: e?.locName, id: e?.locIDpk },
          ["approvingOfficer"]: {
            name: e?.ownerEmpName15,
            id: e?.ownerEmpIDfk,
          },
          ["driver"]: { name: e?.driverEmpName15, id: e?.driverEmpIDfk },
          ["vehicleController"]: {
            name: e?.controllerEmpName15,
            id: e?.controllerEmpIDfk,
          },
          ["administrator"]: { name: e?.adminEmpName15, id: e?.adminEmpIDfk },
          ["manager"]: { name: e?.mgrEmpName15, id: e?.mgrEmpIDfk },
          ["director"]: { name: e?.dirEmpName15, id: e?.dirEmpIDfk },
          ["defaultWorkshop"]: { name: e?.wsdName, id: e?.wsdIDpk },
          ["remarks"]: e?.vasRmks,
          ["active"]: e?.vasActive,
          ["footerId"]: { id: e?.vasIDpk },
        });
      },

      selectedData: (e: any) => {
        setOuterSelected && setOuterSelected(e);
      },

      addedSelected: (e: any) => {
        setAddSelected!(e);
      },

      doubleClick: (e: any) => {
        dispatch(task_footer_update(true));
        setAddSelected!([e]);
      },
    }));

    const validateData = async (action: "save" | "update") => {
      const validationChecks = [
        {
          condition:
            allStates.department.id === "" || allStates.department.id === null,
          message:
            "Please select the department the vehicle is to be assigned to",
        },
        {
          condition:
            allStates.section.id === "" || allStates.section.id === null,
          message: "Please select the section the vehicle is to be assigned to",
        },
        {
          condition: allStates.unit.id === "" || allStates.unit.id === null,
          message: "Please select the unit the vehicle is to be assigned to",
        },
        {
          condition:
            allStates.transportUnit.id === "" ||
            allStates.transportUnit.id === null,
          message:
            "Please select the transport Unit the vehicle is to be assigned to",
        },
        {
          condition:
            allStates.location.id === "" || allStates.location.id === null,
          message:
            "Please select the location the vehicle is to be assigned to",
        },
        {
          condition:
            allStates.approvingOfficer.id === "" ||
            allStates.approvingOfficer.id === null,
          message: "Please select an approving officer",
        },
        {
          condition: allStates.driver.id === "" || allStates.driver.id === null,
          message: "Please select a driver",
        },
        {
          condition:
            allStates.vehicleController.id === "" ||
            allStates.vehicleController.id === null,
          message: "Please select a vehicle controller",
        },
        {
          condition:
            allStates.administrator.id === "" ||
            allStates.administrator.id === null,
          message: "Please select an administrator",
        },
        {
          condition:
            allStates.manager.id === "" || allStates.manager.id === null,
          message: "Please select a manager",
        },
        {
          condition:
            allStates.director.id === "" || allStates.director.id === null,
          message: "Please select a director",
        },
        {
          condition:
            allStates.defaultWorkshop.id === "" ||
            allStates.defaultWorkshop.id === null,
          message: "Please select a default workshop",
        },
        {
          condition: addedSelected.length === 0,
          message: "There are no assigned vehicles",
        },
      ];
      const validationError = validationChecks.find((check) => check.condition);
      if (validationError) {
        setModalData((prev) => ({
          ...prev,
          icon: "warning",
          openModal: true,
          disableCancel: true,
          message: validationError.message,
          title: "Invalid Action",
          okText: "Ok",
          okHandler: () => {
            setModalData((prev) => ({ ...prev, openModal: false }));
          },
        }));
      } else if (action === "save") {
        setModalData((prev) => ({
          ...prev,
          icon: "question",
          openModal: true,
          disableCancel: false,
          message: "Are you sure you want to assign this vehicle?",
          title: "Assign vehicle?",
          okText: "Yes",
          okHandler: () => {
            triggerSave();
          },
          cancelHandler: () => {
            setModalData((prev) => ({ ...prev, openModal: false }));
          },
        }));
      } else {
        setModalData((prev) => ({
          ...prev,
          icon: "question",
          openModal: true,
          disableCancel: false,
          message: "Are you sure you want to update this assignment?",
          title: "Update assignment?",
          okText: "Yes",
          okHandler: () => {
            triggerUpdate();
          },
          cancelHandler: () => {
            setModalData((prev) => ({ ...prev, openModal: false }));
          },
        })); 
      }
    };
const[busyLoader, setBusyLoader]=useBusyLoader()
    const saveData = async (vdtIDpk: any) => {
      try {
        setBusyLoader('Assigning....')
        const res = await PostDataFunc(
          `AssetTamVehicleAssignment/CreateAssetVehicleAssignment`,
          {
            vasVehicleDetailIdfk: vdtIDpk,
            vasStartDate: dayjs(allStates?.startDate).format("YYYY-MM-DD"),
            vasEndDate: dayjs(allStates?.endDate).format("YYYY-MM-DD"),
            vasDeptIdfk: allStates.department.id,
            vasSectionIdfk: allStates.section.id,
            vasUnitIdfk: allStates.unit.id,
            vasOwnerEmpIdfk: allStates.approvingOfficer.id,
            vasDriverEmpIdfk: allStates.driver.id,
            vasVehicleControllerEmpIdfk: allStates.vehicleController.id,
            vasAdminEmpIdfk: allStates.administrator.id,
            vasDefaultWorkshopIdfk: allStates.defaultWorkshop.id,
            vasTransportUnitIdfk: allStates.transportUnit.id,
            vasLocationIdfk: allStates.location.id,
            vasDirectorEmpIdfk: allStates.director.id,
            vasManagerEmpIdfk: allStates.manager.id,
            vasActive: allStates.active,
            vasRmks: allStates.remarks,
            vasCreatedBy: userId,
          },
          `Created the Asset Mgr-Trans-Vehicle Assignment with a 
            start Date:${allStates.startDate}, end Date:${allStates.endDate}, department:${allStates.department.name},
            section:${allStates.section.name}, unit:${allStates.unit.name}, transportUnit:${allStates.transportUnit.name},
            location:${allStates.location.name}, approvingOfficer:${allStates.approvingOfficer.name}, driver:${allStates.driver.name},
            vehicleController:${allStates.vehicleController.name}, administrator:${allStates.administrator.name}, manager:${allStates.manager.name},
            director:${allStates.director.name} successfully`
        );
        // await UpdateDataFunc(
        //   `AssetTamVehicleAssignment/UpdateAssignedStatus?id=${vdtIDpk}`,
        //   {}
        // );
        // await UpdateDataFunc(
        //   `AsmTsmEntMaintenanceSchedule/UpdateMaintenanceSchedule?id=${vdtIDpk}`,
        //   {}
        // );
        setBusyLoader('')
        setModalData((prev) => ({
          ...prev,
          icon: "success",
          openModal: true,
          disableCancel: true,
          message: "Vehicle assigned successfully",
          title: "Vehicle assigned",
          okText: "Ok",
          okHandler: () => {
            setModalData((prev) => ({ ...prev, openModal: false }));
          },
        }));
        refresh();
      } catch (e) {
        setBusyLoader('')
        setModalData((prev) => ({
          ...prev,
          icon: "warning",
          openModal: true,
          disableCancel: false,
          message: "Please contact the system administrator for assistance",
          title: "Record not saved",
          okText: "Ok",
          okHandler: () => {
            setModalData((prev) => ({ ...prev, openModal: false }));
          },
        }));
      }
    };

    const UpdateData = async (vasIDpk: any,vdtIDpk:any ) => {
      try {
        setBusyLoader('Updating assignment...')
        await UpdateDataFunc(
          `AssetTamVehicleAssignment/UpdateVehicleAssignment?id=${vdtIDpk}`,
          {
            vasVehicleDetailIdfk: vasIDpk,
            vasStartDate: dayjs(allStates?.startDate).format("YYYY-MM-DD"),
            vasEndDate: dayjs(allStates?.endDate).format("YYYY-MM-DD"),
            vasDeptIdfk: allStates.department.id,
            vasSectionIdfk: allStates.section.id,
            vasUnitIdfk: allStates.unit.id,
            vasOwnerEmpIdfk: allStates.approvingOfficer.id,
            vasDriverEmpIdfk: allStates.driver.id,
            vasVehicleControllerEmpIdfk: allStates.vehicleController.id,
            vasAdminEmpIdfk: allStates.administrator.id,
            vasDefaultWorkshopIdfk: allStates.defaultWorkshop.id,
            vasTransportUnitIdfk: allStates.transportUnit.id,
            vasLocationIdfk: allStates.location.id,
            vasDirectorEmpIdfk: allStates.director.id,
            vasManagerEmpIdfk: allStates.manager.id,
            vasActive: allStates.active,
            vasRmks: allStates.remarks,
            vasEditedBy: userId,
          },
          `Edited the Asset Mgr-Trans-Vehicle Assignment with ID:${vdtIDpk}, Driver ID:${allStates.driver.id}, Unit ID:${allStates.unit.id}, successfully`
        )
          .then(() => {
            setModalData((prev) => ({
              ...prev,
              icon: "success",
              openModal: true,
              disableCancel: true,
              message: "Record updated successfully",
              title: "Record updated",
              okText: "Ok",
              okHandler: () => {
                setModalData((prev) => ({ ...prev, openModal: false }));
              },
            }));
            setBusyLoader('');
            refresh();
          })
          .catch(() => {
            setModalData((prev) => ({
              ...prev,
              icon: "warning",
              openModal: true,
              disableCancel: false,
              message: "Please contact the system administrator for assistance",
              title: "Record not updated",
              okText: "Ok",
              okHandler: () => {
                setModalData((prev) => ({ ...prev, openModal: false }));
              },
            }));
            setBusyLoader('');
          });
        await PatchDataFunc(
          `AssetTamVehicleAssignment/UpdateAssignedStatus?id=${vdtIDpk}`,
          {}
        );
        // await UpdateDataFunc(
        //   `AsmTsmEntMaintenanceSchedule/UpdateMaintenanceSchedule?id=${vdtIDpk}`,
        //   {}
        // );
      } catch (error) {
        setBusyLoader('');
        setModalData((prev) => ({
          ...prev,
          icon: "warning",
          openModal: true,
          disableCancel: false,
          message: "Please contact the system administrator for assistance",
          title: "Record not updated",
          okText: "Ok",
          okHandler: () => {
            setModalData((prev) => ({ ...prev, openModal: false }));
          },
        }));
      }
    };

    // const dataGridRender = (
    //   data: any,
    //   columnHeader: any,
    //   callBackFunction: any
    // ) => {
    //   return (
    //     <DataGrid
    //       height={"100%"}
    //       style={{ maxHeight: "200px", maxWidth: "1000px" }}
    //       width={"100%"}
    //       columnWidth={80}
    //       columnResizingMode={"widget"}
    //       showColumnLines={true}
    //       showRowLines={true}
    //       onRowClick={(e: any) => {
    //         callBackFunction(e.data);
    //         close();
    //       }}
    //       onRowDblClick={() => { }}
    //       wordWrapEnabled={false}
    //       allowColumnResizing={true}
    //       dataSource={data}
    //     >
    //       <Pager visible={false} />
    //       {columnHeader?.map((reqBy: any) => {
    //         return (
    //           <Column
    //             key={reqBy.id}
    //             dataType={reqBy.dataType}
    //             caption={reqBy.caption}
    //             dataField={reqBy.dataField}
    //             alignment="left"
    //             visible={reqBy.visible}
    //             width={reqBy.width === null ? 150 : reqBy.width}
    //             fixed={reqBy.fixed}
    //           />
    //         );
    //       })}
    //     </DataGrid>
    //   );
    // };

    useEffect(() => {
      if (vehA_Clear === true) {
        resetField();
      }
    }, [vehA_Clear]);

    const [userId, employID, userObj] = useAccountInfo();

    const [privType, setPrivType] = useState<string>("save");

    const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);

    const AccessPriv_on_NewButton = () => {
      setPrivType("save");
      if (savePriv === true) {
        //---------------eagleMentality🦅------------------
        Vdispatch(setNew_Cancel_Button(false));
        Vdispatch(setDisableForm(false));
        //--------------------------------------------------
        resetField();
        dispatch(task_footer_table_selected({}));
        dispatch(setGeneralValue({ expr: "selectedFieldData", value: {} }));
      } else {
        setModalData((prev) => ({
          ...prev,
          icon: "warning",
          openModal: true,
          disableCancel: false,
          message: "No access privilege",
          title: "Access denied",
          okText: "Ok",
          okHandler: () => {
            setModalData((prev) => ({ ...prev, openModal: false }));
            dispatch(task_form_disable(true));
          },
        }));
      }
    };

    const AccessPriv_on_AssignButton = () => {
      if (savePriv === true) {
        dispatch(task_form_disable(false));
        validateData("save");
      } else {
        setModalData((prev) => ({
          ...prev,
          icon: "warning",
          openModal: true,
          disableCancel: false,
          message: "No access privilege",
          title: "Access denied",
          okText: "Ok",
          okHandler: () => {
            setModalData((prev) => ({ ...prev, openModal: false }));
          },
        }));
      }
    };
    const AccessPriv_on_UpdateButton = () => {
      console.log("update", allStates);
      setPrivType("update");
      if (updatePriv === true) {
        dispatch(task_form_disable(false));
        dispatch(task_footer_update(true));
        // validateData2();
        validateData("update");
        // triggerUpdate();
      } else {
        setModalData((prev) => ({
          ...prev,
          icon: "warning",
          openModal: true,
          disableCancel: false,
          message: "No access privilege",
          title: "Access denied",
          okText: "Ok",
          okHandler: () => {
            setModalData((prev) => ({ ...prev, openModal: false }));
          },
        }));
      }
    };
    console.log("heyy", modalData);
    return (
      <>
        <ModalTemplate
          title={modalData.title}
          icon={modalData.icon}
          okHandler={modalData.okHandler}
          okText={modalData.okText}
          cancelHandler={() => {
            setModalData((prev) => ({ ...prev, openModal: false }));
          }}
          cancelText={modalData.cancelText}
          disableCancel={modalData.disableCancel}
          message={modalData.message}
          open={modalData.openModal}
        />
        <Form
          name="complex-form"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 20 }}
          size={"small"}
          className="ml-2 "
        >
          <Form.Item
            label={<p className="text-xs">{"Assign Dates"}</p>}
            style={{ marginBottom: 0, width: "calc(100%)" }}
          >
            <Form.Item
              style={{ display: "inline-block", width: "calc(49.5% )" }}
            >
              <DateTemplate
                selectedDate={dayjs(allStates?.startDate)}
                changeDate={(e) => {
                    console.log('heeey',e)
                  setAllState!({ ...allStates, ["startDate"]: e });
                }}
                format="ddd, DD MMM YYYY"
                disabled={DisableForm}
                style={{ width: "90%" }}
              />
              {/* <DatePicker
                value={
                  allStates?.startDate === ""
                    ? dayjs()
                    : dayjs(allStates?.startDate)
                }
                onChange={(e, dateTime) => {
                  setAllState!({ ...allStates, ["startDate"]: dateTime });
                }}
                style={{ width: "90%" }}
                disabled={DisableForm}
                format={"ddd, DD MMM YYYY"}
              /> */}
            </Form.Item>
            <Form.Item style={{ display: "inline-block", width: "calc(48% )" }}>
              <DatePicker
                value={
                  allStates?.endDate === ""
                    ? dayjs()
                    : dayjs(allStates?.endDate)
                }
                onChange={(e, dateTime) => {
                  setAllState!({ ...allStates, ["endDate"]: dateTime });
                }}
                style={{ width: "90%" }}
                disabled={DisableForm}
                format={"ddd, DD MMM YYYY"}
              />
            </Form.Item>
          </Form.Item>

          {/* <Form.Item
            label={<p className="text-xs">{"Department"}</p>}
            style={{ marginBottom: 0 }}
            className="block w-full"
          >
            <SelectsTemplate
              placeHolder={allStates.department.name}
              handleRefresh={() => {
                setAllState!({
                  ...allStates,
                  department: { name: "", id: "" },
                });
                setD_Refresh(!D_Refresh);
              }}
              options={department_data}
              disabled={DisableForm}
              useCallFunc={true}
              idexpr={"dptIdpk"}
              dataexp={"dptName"}
              selectedValue={(e: any) => {
                setAllState!({
                  ...allStates,
                  ["department"]: {
                    name: e.dptName,
                    id: e.dptIdpk,
                  },
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label={<p className="text-xs">{"Section"}</p>}
            style={{ marginBottom: 0 }}
          >
            <SelectsTemplate
              placeHolder={allStates.section.name}
              handleRefresh={() => {
                setAllState!({
                  ...allStates,
                  ["section"]: {
                    name: "",
                    id: "",
                  },
                });
                setS_Refresh(!S_Refresh);
              }}
              options={section_data}
              disabled={DisableForm}
              useCallFunc={true}
              idexpr={"sxnIdpk"}
              dataexp={"sxnName"}
              selectedValue={(e: any) => {
                setAllState!({
                  ...allStates,
                  ["section"]: {
                    name: e.sxnName,
                    id: e.sxnIdpk,
                  },
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label={<p className="text-xs">{"Unit"}</p>}
            style={{ marginBottom: 0 }}
          >
            <SelectsTemplate
              placeHolder={allStates.unit.name}
              handleRefresh={() => {
                setAllState!({
                  ...allStates,
                  ["unit"]: {
                    name: "",
                    id: "",
                  },
                });
                setU_Refresh(!U_Refresh);
              }}
              options={unit_data}
              disabled={DisableForm}
              useCallFunc={true}
              idexpr={"untIdpk"}
              dataexp={"untName"}
              selectedValue={(e: any) => {
                setAllState!({
                  ...allStates,
                  ["unit"]: {
                    name: e.untName,
                    id: e.untIdpk,
                  },
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label={<p className="text-xs">{"Transport Unit"}</p>}
            style={{ marginBottom: 0 }}
          >
            <SelectsTemplate
              placeHolder={allStates.transportUnit.name}
              handleRefresh={() => {
                setAllState!({
                  ...allStates,
                  ["transportUnit"]: {
                    name: "",
                    id: "",
                  },
                });
                setT_Refresh(!T_Refresh);
              }}
              options={transportUnit_data}
              disabled={DisableForm}
              useCallFunc={true}
              idexpr={"truIdpk"}
              dataexp={"truName"}
              selectedValue={(e: any) => {
                setAllState!({
                  ...allStates,
                  ["transportUnit"]: {
                    name: e.truName,
                    id: e.truIdpk,
                  },
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label={<p className="text-xs">{"Location"}</p>}
            style={{ marginBottom: 0 }}
          >
            <SelectsTemplate
              placeHolder={allStates.location.name}
              handleRefresh={() => {
                setAllState!({
                  ...allStates,
                  ["location"]: {
                    name: "",
                    id: "",
                  },
                });
                setL_Refresh(!L_Refresh);
              }}
              options={location_data}
              disabled={DisableForm}
              useCallFunc={true}
              idexpr={"locIdpk"}
              dataexp={"locName"}
              selectedValue={(e: any) => {
                updateState("location", {
                  name: e.locName,
                  id: e.locIdpk,
                });
              }}
            />
          </Form.Item> */}
          {selects.map(
            ({
              id,
              idExpr,
              dataExpr,
              defaultValue,
              stateName,
              singleEndpoint,
              selectedValue,
              label,
              optionsData,
              isOpenDropdown,
              isOpenDropdownKey,
              registerName,
            }) => {
              return (
                <Fragment>
                  <InputSelect_template
                    label={label}
                    placeHolder={defaultValue}
                    isOpenDropdown={isOpenDropdown}
                    handleFocus={() => {
                      updateState(isOpenDropdownKey, !isOpenDropdown);
                      dispatch(
                        singleEndpoint({ search: "", params: allStates })
                      );
                      UpdateStates(registerName, "", setAllState);
                    }}
                    handleSearch={
                      debounce((e) => {
                        UpdateStates(registerName, e, setAllState);
                        updateState(stateName!, e);
                        // UpdateState("isLoading", true);
                        (id as number) !== 0 || (id as number) !== 4
                          ? dispatch(
                              singleEndpoint({ search: e, params: allStates })
                            )
                          : dispatch(singleEndpoint(e));

                        updateState(isOpenDropdownKey, true);
                      }, 500) // Adjust the debounce delay as needed
                      
                    }
                    handleRefresh={() => {
                      setAllState!({
                        ...allStates,
                        ["location"]: {
                          name: "",
                          id: "",
                        },
                      });
                      // setL_Refresh(!L_Refresh);
                    }}
                    options={optionsData}
                    disabled={DisableForm}
                    useCallFunc={true}
                    idexpr={idExpr}
                    dataexp={dataExpr}
                    selectedValue={(e: any) => {
                      updateState(isOpenDropdownKey, false);
                      updateState(stateName!, {
                        name: e[dataExpr!],
                        id: e[idExpr!],
                      });
                    }}
                  />
                </Fragment>
              );
            }
          )}

          {DataGridSelectBox.map(
            ({
              label,
              labelCol,
              width,
              columns,
              defaultValue,
              data,
              stateName,
              idExpr,
              dataExpr,
              handleRefresh,
            }) => {
              return (
                <>
                  <DropdownGrid_template
                    KeyExpr={idExpr}
                    label={label}
                    ref={dropdownRef}
                    cusBtnStyle={true}
                    className={"mb-1"}
                    dropdownBtnStyles={{ width: width }}
                    labelCol={labelCol}
                    columns={columns}
                    disabled={DisableForm}
                    gridData={data}
                    defaultValue={defaultValue}
                    isHide={false}
                    handleRefresh={handleRefresh}
                    onRowClick={(e) => {
                      updateState(stateName, {
                        id: e[idExpr],
                        name: e[dataExpr],
                      });
                      console.log("@#$", e);
                    }}
                  />
                </>
              );
            }
          )}

          <Form.Item
            style={{ display: "inline-block", marginBottom: "1px" }}
            label={<p className="text-xs">{"Remarks"}</p>}
            className="w-full mb-1 mr-2"
          >
            <TextArea
              style={{
                resize: "none",
                height: "75px",
              }}
              className="o overflow-y-scroll resize-none"
              rows={3}
              onChange={(event) => {
                setAllState!({ ...allStates, ["remarks"]: event.target.value });
              }}
              readOnly={DisableForm}
              value={allStates.remarks}
              disabled={DisableForm}
            />
          </Form.Item>
          <Form.Item
            style={{ display: "inline-block", marginBottom: "2px" }}
            label={<p className="text-xs">{"Active"}</p>}
            className="w-full mb-1"
          >
            <div className=" items-end">
              <div className="w-full flex  flex-row justify-between  ">
                <div
                  style={{
                    borderWidth: "1px",
                    borderColor: borderTheme,
                    paddingLeft: 3,
                    paddingRight: 3,
                  }}
                  className="  border-slate-200  hover:cursor-pointer mr-1  text-xs px-1 text-gray-600 rounded flex items-center h-[24px]"
                >
                  <Checkbox
                    checked={allStates.active}
                    onChange={(e) =>
                      setAllState!({
                        ...allStates,
                        ["active"]: e.target.checked,
                      })
                    }
                    disabled={DisableForm}
                  />
                </div>
                <ul className="flex">
                  {SaveAndUpdateButton === true ? (
                    <button
                      style={
                        DisableForm
                          ? { opacity: 0.5, borderColor: borderTheme }
                          : { opacity: 2, borderColor: borderTheme }
                      }
                      disabled={DisableForm}
                      onClick={() => {
                        AccessPriv_on_AssignButton();
                      }}
                      className="hover:cursor-pointer dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  mr-1 px-2  border-2 border-slate-200 rounded hover:border-blue-300"
                    >
                      <img className="pr-1" src={save} />
                      Assign
                    </button>
                  ) : (
                    <UpdateButton
                      handleUpdate={() => {
                        AccessPriv_on_UpdateButton();
                      }}
                    />
                  )}

                  {NewAndCancelButton === true ? (
                    <>
                      {EditAndCancelButton === true && (
                        <NewButton
                          useCallbackFunc={true}
                          new_button_toggler={() => {
                            AccessPriv_on_NewButton();
                          }}
                        />
                      )}
                      {EditAndCancelButton === false && (
                        <Cancel
                          useCallbackFunc={true}
                          cancel_button_toggler={() => {
                            // ----------eagleMentality🦅--------------
                            Vdispatch(setNew_Cancel_Button(true));
                            Vdispatch(setDisableForm(true));
                            Vdispatch(setEdit_Cancel_Button(true));
                            Vdispatch(setSave_Update_Button(true));
                            // ----------------------------------------
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <Cancel
                      useCallbackFunc={true}
                      cancel_button_toggler={() => {
                        // ----------eagleMentality🦅--------------
                        Vdispatch(setNew_Cancel_Button(true));
                        Vdispatch(setDisableForm(true));
                        Vdispatch(setEdit_Cancel_Button(true));
                        Vdispatch(setSave_Update_Button(true));
                        // ----------------------------------------
                      }}
                    />
                  )}
                </ul>
              </div>
            </div>
          </Form.Item>
        </Form>
      </>
    );
  }
);

export default VehicleAssignmentDropDowns;
