import { useCallback, useState } from "react";
import DataGrid, {
  Column,
  Export,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  Scrolling,
  SearchPanel,
  Selection,
} from "devextreme-react/data-grid";

interface props {
  gridheight: any;
  columns: any;
  data: any;
  loading?: boolean;
  isSearch?: boolean
}

export const Ans_Footer_datagrid_template = ({
  gridheight,
  columns,
  data,
  loading,
  isSearch = true,
}: props) => {
  const [selectedEmployee, setSelectedEmployee] = useState();
  const selectEmployee: any = useCallback<any>((e: any) => {
    e.component.byKey(e.currentSelectedRowKeys[0]).done((employee: any) => {
      setSelectedEmployee(employee);
    });
  }, []);

  const displayModes = [
    { text: "Display Mode 'full'", value: "full" },
    { text: "Display Mode 'compact'", value: "compact" },
  ];
  const allowedPageSizes = ["all", 5, 10, 15];

  return (
    <div className="w w-full mt-1">
      <style
        dangerouslySetInnerHTML={{
          __html: `
                    .dx-datagrid-header-panel .dx-toolbar {
                        margin-bottom: 4px;
                    }`,
        }}
      />
      <DataGrid
        dataSource={data}
        showBorders={true}
        columnWidth={30}
        showRowLines={true}
        showColumnLines={true}
        height={gridheight}
        allowColumnReordering={true}
        allowColumnResizing={true}
        rowAlternationEnabled={false}
        onSelectionChanged={selectEmployee}
      >
        <Export enabled={false} />
        <HeaderFilter visible={true} allowSearch={true} />
        {isSearch && <SearchPanel width={300} visible={true} />}
        <GroupPanel visible={false} />
        <Selection mode={'multiple'} showCheckBoxesMode={'always'} />

        {columns.map((column: any) => {
          return (
            <Column
              key={column.id}
              dataField={column.dataField}
              caption={column.caption}
              dataType={column.dataType}
              width={column.width === null ? 100 : column.width}
              alignment="left"
              fixed={column.fixed}
            />
          );
        })}

        <Scrolling rowRenderingMode={"virtual"} columnRenderingMode="virtual" />
        <Paging defaultPageSize={0} />
        <Pager
          visible={true}
          allowedPageSizes={allowedPageSizes as any}
          displayMode={displayModes as any}
          showInfo={true}
          showNavigationButtons={true}
          showPageSizeSelector={true}
        />
      </DataGrid>
    </div>
  );
};
