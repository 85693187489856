import { DataGrid, TextArea } from "devextreme-react";

import { Pager, Column, Scrolling } from "devextreme-react/data-grid";
import { forwardRef, useImperativeHandle, useState } from "react";

import { Checkbox, Input, Select } from "antd";
import {
  sms_data_1,
  sms_data_2,
  sms_header_1,
  sms_header_2,
} from "../TelephoneDirectory/data/telephone-data";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import useResizeMode from "../../../../hooks/useResizeMode";

interface options {
  pageResized: Function;
}

const SmsAlerts = forwardRef(({ pageResized }: options, ref) => {
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const navbarHeight = 58.05;
  const sidenavTitle = 32;
  const sidenavbottomBar = 45;

  const boxspacingY = 16;
  //from here
  const sendSmsHeight = 32;
  const table1margin = 4;
  const searchSectionHeight = 60;
  const sendSmsMarginTop = 8;

  const [viewHeight, setViewHeight] = useState(
    window.innerHeight -
    navbarHeight -
    sidenavTitle -
    sidenavbottomBar -
    boxspacingY -
    news_flash_height
  );
  const table1Height =
    (viewHeight -
      sendSmsHeight -
      table1margin -
      searchSectionHeight -
      sendSmsMarginTop) /
    3;
  const table2Height =
    (viewHeight -
      sendSmsHeight -
      table1margin -
      searchSectionHeight -
      sendSmsMarginTop) /
    3;
  const textboxHeight =
    (viewHeight -
      sendSmsHeight -
      table1margin -
      searchSectionHeight -
      sendSmsMarginTop) /
    4;

  // useImperativeHandle(ref, () => ({
  //   test: () => {
  //     setViewHeight(window.innerHeight - 58.05 - 48 - 45 - news_flash_height);
  //   },
  // }));
  // let  [resizeOnf11,initialSize] = useResizeMode(window.innerWidth === screen.width && window.innerHeight === screen.height);
  let  [resizeOnf11,initialSize] = useResizeMode(window.innerWidth  && window.innerHeight );

  let  normalPageSize =  initialSize - 222;
  let  onF11PageSize = resizeOnf11 - 222;

  return (
    <div
      style={{ height: window.innerWidth  && window.innerHeight  ? normalPageSize : onF11PageSize }}
      className="mt-2 mb-2 border px-2 pt-2 rounded-md w-full"
    >
      <div
        id="60px"
        style={{ height: searchSectionHeight }}
        className="hidden xl:block"
      >
        <div className="flex justify-between">
          <div className="pt-0.5 text-sm">Search:</div>
          <div>
            <Select
              defaultValue=""
              style={{ width: 420 }}
              size="small"
              className=" rounded-none"
              options={[
                {
                  value: "General",
                  label: "General",
                },
              ]}
            />
          </div>
          <div className="border border-stone-300 rounded-md w-8  text-center ">
            <i className="fa-solid fa-arrows-rotate text-blue-400 text-sm"></i>
          </div>
        </div>
        <div className="flex justify-between mt-2">
          <div className="pt-0.5 text-sm w-10">
            <Checkbox onChange={() => { }}></Checkbox>
          </div>
          <div className="pl-1">
            <Select
              defaultValue=""
              style={{ width: 205 }}
              size="small"
              className=" rounded-none"
              options={[]}
              notFoundContent={""}
            />
          </div>
          <div className="pl-1">
            <Input
              defaultValue="lucy"
              style={{ width: 205 }}
              size="small"
              className="h-6"
            />
          </div>
          <div className="border border-stone-300 rounded-md w-8  text-center ">
            <i className="fa-solid fa-add text-blue-400 text-sm"></i>
          </div>
        </div>
      </div>
      <div className=" mt-2">
        <DataGrid
          dataSource={sms_data_1}
          showBorders={true}
          columnWidth={120}
          showRowLines={true}
          showColumnLines={true}
          height={table1Height}
        >
          <Pager visible={false} />

          {/* STAFF DETAILS COLUMNS */}
          {/* FIXED COLUMNS */}

          {sms_header_1.map((data) => {
            return (
              <Column
                key={data.id}
                caption={data.caption}
                dataField={data.dataField}
                alignment={data.alignment as "center" | "left" | "right" | undefined}
                fixed={data.fixed}
                width={data.width === null ? 100 : data.width}
              />
            );
          })}

          {/* SCROLLBAR  */}

          <Scrolling columnRenderingMode="virtual" />
        </DataGrid>
      </div>
      <div className=" mt-2  ">
        <DataGrid
          dataSource={sms_data_2}
          showBorders={true}
          columnWidth={120}
          showRowLines={true}
          showColumnLines={true}
          height={table2Height}
        >
          {/* STAFF DETAILS COLUMNS */}
          <Pager visible={false} />
          {/* FIXED COLUMNS */}

          {sms_header_2.map((data) => {
            return (
              <Column
                key={data.id}
                caption={data.caption}
                dataField={data.dataField}
                alignment={data.alignment as "center" | "left" | "right" | undefined}
                fixed={data.fixed}
                width={data.width === null ? 100 : data.width}
              />
            );
          })}

          {/* SCROLLBAR  */}

          <Scrolling columnRenderingMode="virtual" />
        </DataGrid>

        <div className="mt-2 lg:mt-4">
          <TextArea
            height={textboxHeight}
            // maxLength={500}

            defaultValue={""}
          ></TextArea>
        </div>
        <div className="hidden xl:block">
          <div className="h-0 2xl:h-2"></div>
          <div
            className="flex "
            style={{ height: sendSmsHeight, marginTop: sendSmsMarginTop }}
          >
            <div
              style={{ width: "500px" }}
              className="border px-2 items-center border-stone-300 rounded-md  h-8 flex justify-between  text-center "
            >
              <i className="hover:cursor-pointer fa-solid fa-envelope-circle-check text-blue-500 p-2"></i>
              <div>Send SMS</div>
              <i className="hover:cursor-pointer fa-solid fa-envelope-circle-check text-blue-500 p-2 invisible"></i>
            </div>

            <div className="border border-stone-300 rounded-md w-8  text-center items-center justify-center flex ml-3 ">
              <i className="fa-solid fa-add text-blue-400 text-sm"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default SmsAlerts;
