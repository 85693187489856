export const footer_datagrid_cols = [
  {
    id: 0,
    caption: "REQ. ID",
    dataField: "mrqIDpk",
    dataType: "",
    fixed: true,
    width: 60,
  },
  {
    id: 1,
    caption: "DATE IN",
    dataField: "mtaDateIn",
    dataType: "datetime",
    fixed: true,
    width: 100,
  },
  {
    id: 2,
    caption: "DATE OUT",
    dataField: "mtaDateOut",
    dataType: "datetime",
    fixed: true,
    width: 100,
  },
  {
    id: 3,
    caption: "OPENED?",
    dataField: "mtaOpened",
    dataType: "boolean",
    fixed: true,
    width: 100,
  },
  {
    id: 4,
    caption: "CLOSED?",
    dataField: "mtaDone",
    dataType: "boolean",
    fixed: true,
    width: 100,
  },
  {
    id: 5,
    caption: "%COMPLETE",
    dataField: "mtaPercentComplete",
    fixed: true,
    width: 100,
    format: "",
  },
  {
    id: 6,
    caption: "REG. No",
    dataField: "vdtRegNo",
    dataType: "string",
    width: 100,
  },
  { id: 7, caption: "JOB No", dataField: "mtaJobNo", dataType: "", width: 100 },
  {
    id: 8,
    caption: "WORKSHOP",
    dataField: "wsdName",
    dataType: "string",
    width: 100,
  },
  {
    id: 9,
    caption: "ARRIVAL CONDITION",
    dataField: "mtaArrivalCondition",
    dataType: "",
    width: 100,
  },
  {
    id: 10,
    caption: "JOB SUMMARY",
    dataField: "mtaJobSummary",
    dataType: "string",
    width: 100,
  },
  {
    id: 11,
    caption: "VEHICLE CLASS",
    dataField: "vhcName",
    dataType: "",
    width: 100,
  },
  {
    id: 12,
    caption: "MAKE",
    dataField: "vmkName",
    dataType: "string",
    width: 100,
  },
  {
    id: 13,
    caption: "MODEL",
    dataField: "mdlName",
    dataType: "string",
    width: 100,
  },
  {
    id: 14,
    caption: "MANUFACTURER",
    dataField: "mftName",
    dataType: "string",
    width: 100,
  },
  {
    id: 15,
    caption: "DEPARTMENT",
    dataField: "dptName",
    dataType: "string",
    width: 100,
  },
  {
    id: 16,
    caption: "SECTION",
    dataField: "sxnName",
    dataType: "",
    width: 100,
  },
  {
    id: 17,
    caption: "SPEEDO IN",
    dataField: "mtaSpeedoIn",
    dataType: "",
    width: 100,
  },
  {
    id: 18,
    caption: "SPEEDO OUT",
    dataField: "mtaSpeedoOut",
    dataType: "",
    width: 100,
  },
  {
    id: 19,
    caption: "SPEEDO DIFF",
    dataField: "mtaSpeedoDiff",
    dataType: "",
    width: 100,
  },
  {
    id: 20,
    caption: "DRIVER INTERCOM",
    dataField: "reqDriverPlc",
    dataType: "string",
    width: 100,
  },
  {
    id: 21,
    caption: "DRIVER",
    dataField: "reqDriverEmpName",
    dataType: "string",
    width: 100,
  },
  {
    id: 22,
    caption: "DRIVER MOB No",
    dataField: "reqDriverMobNo",
    dataType: "string",
    width: 100,
  },
  {
    id: 23,
    caption: "REQUESTED BY",
    dataField: "requestedByEmpName",
    dataType: "string",
    width: 100,
  },
  {
    id: 24,
    caption: "REQUESTED INTERCOM?",
    dataField: "requestedByPlc",
    dataType: "string",
    width: 100,
  },
  {
    id: 25,
    caption: "REQUESTED MOB No",
    dataField: "requestedByMobNox",
    dataType: "string",
    width: 85,
  },
  {
    id: 26,
    caption: "REMARKS",
    dataField: "mtaRmks",
    dataType: "string",
    width: 4000,
  },
];

// Right side pane window

export const attachement_cols = [
  {
    id: 0,
    caption: "Format",
    dataField: "",
    dataType: "",
    width: null,
    fixed: false,
  },
  {
    id: 1,
    caption: "File Name",
    dataField: "",
    dataType: "",
    width: null,
    fixed: false,
  },
];
export const comments_cols = [
  {
    id: 0,
    caption: "DATE",
    dataField: "macCreationDate",
    dataType: "datetime",
    width: null,
    fixed: false,
  },
  {
    id: 1,
    caption: "NAME",
    dataField: "empName",
    dataType: "",
    width: null,
    fixed: false,
  },
  {
    id: 2,
    caption: "COMMENT",
    dataField: "macComment",
    dataType: "",
    width: null,
    fixed: false,
  },
];
export const top_cols = [
  {
    id: 0,
    caption: "",
    dataField: "swtOwn",
    dataType: "boolean",
    width: 50,
    fixed: true,
    allowEditing: true,
  },
  {
    id: 1,
    caption: "",
    dataField: "swtRead",
    dataType: "boolean",
    width: 50,
    fixed: true,
    allowEditing: true,
  },
  {
    id: 2,
    caption: "",
    dataField: "swtPrint",
    dataType: "boolean",
    width: 50,
    fixed: true,
    allowEditing: true,
  },
  {
    id: 3,
    caption: "Name",
    dataField: "empname1",
    dataType: "string",
    width: 100,
    fixed: false,
    allowEditing: false,
  },
  {
    id: 4,
    caption: "Job Title",
    dataField: "jbtShtName",
    dataType: "",
    width: 100,
    fixed: false,
    allowEditing: true,
  },
  {
    id: 5,
    caption: "Dept",
    dataField: "dptShtName",
    dataType: "string",
    width: 100,
    fixed: false,
    allowEditing: true,
  },
  {
    id: 6,
    caption: "Section",
    dataField: "sxnShtName",
    dataType: "",
    width: 100,
    fixed: false,
    allowEditing: true,
  },
  {
    id: 7,
    caption: "Location",
    dataField: "locShtName",
    dataType: "string",
    width: 100,
    allowEditing: true,
  },
];
export const lower_cols = [
  {
    id: 0,
    caption: "Own",
    dataField: "swtOwn",
    dataType: "boolean",
    width: 60,
    fixed: true,
    allowEditing: true,
  },
  {
    id: 1,
    caption: "Upd",
    dataField: "swtRead",
    dataType: "boolean",
    width: 60,
    fixed: true,
    allowEditing: true,
  },
  {
    id: 2,
    caption: "Rdr",
    dataField: "swtPrint",
    dataType: "boolean",
    width: 60,
    fixed: true,
    allowEditing: true,
  },
  {
    id: 3,
    caption: "Name",
    dataField: "empname1",
    dataType: "string",
    width: 100,
    fixed: false,
    allowEditing: false,
  },
  {
    id: 4,
    caption: "Dept",
    dataField: "dptShtName",
    dataType: "string",
    width: 100,
    fixed: false,
    allowEditing: true,
  },
  {
    id: 5,
    caption: "Section",
    dataField: "sxnShtName",
    dataType: "",
    width: 100,
    fixed: false,
    allowEditing: true,
  },
  {
    id: 6,
    caption: "Location",
    dataField: "locShtName",
    dataType: "string",
    width: 100,
    allowEditing: true,
  },
];
export const trails_cols = [];
export const images_cols = [
  {
    id: 0,
    caption: "DATE",
    dataField: "locShtName",
    dataType: "string",
    width: 100,
  },
  {
    id: 1,
    caption: "LONGITUDE",
    dataField: "locShtName",
    dataType: "string",
    width: 100,
    fixed:false,
  },
  {
    id: 2,
    caption: "LATITUDE",
    dataField: "locShtName",
    dataType: "string",
    width: 100,
    fixed:false,
  },
  {
    id: 3,
    caption: "LOCATION",
    dataField: "locShtName",
    dataType: "string",
    width: 100,
    fixed:false,
  },
  {
    id: 4,
    caption: "REMARK",
    dataField: "locShtName",
    dataType: "string",
    width: 100,
    fixed:false,
  },
  
];
