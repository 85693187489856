export const assets_vehicleRAColumns = [
  {
    id: 0,
    caption: "ID",
    dataField: "vraIdpk",
    dataType: "number",
    width: 80,
    fixed: true,
    visible: false,
  },
  {
    id: 1,
    caption: "VEHICLE REQUEST AREA",
    dataField: "vraName",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "SHORT NAME",
    dataField: "vraShtName",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 3,
    caption: "ORDER",
    dataField: "vraOrder",
    dataType: "string",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 4,
    caption: "ACTIVE",
    dataField: "vraActive",
    dataType: "boolean",
    width: 90,
    fixed: true,
    visible: true,
  },
  {
    id: 5,
    caption: "REMARKS",
    dataField: "vraRmks",
    dataType: "string",
    width: 5000,
    fixed: false,
    visible: true,
  },
];
