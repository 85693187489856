import { forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import Setups_Template from '../.setups_templates/Setups_Template';
import { exprsTypes, modalTypes, searchTextTypes, selectExprsTypes, selectsTypes } from '../data/types/selectsTypes';
import useFetch from '../../../../hooks/useFetch';
import { hr_setups_units_columns, units_data } from './data/hrUnitsData';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../app/store';
import { setTier3Value, tier3SaveOnclick, tier3UpdateOnclick } from '../../../../features/forms/tier3/tier3Slice';
import { setGeneralValue } from '../../../../features/generalSlice';
import { validationsData, saveModalData, updateModalData, api_url, putError, postError } from '../../../accessories/component_infos';
import { ModalTemplate } from '../../../../templates/modal';
import CustomLoader from '../../../accessories/custom-loader';
import { WarningAlert } from '../../../accessories/warningAlert';
import { useBusyLoader } from '../../../../hooks/useBusyLoader';
import { useAccountInfo } from '../../../../hooks/useAccountInfo';
import dayjs from 'dayjs';
import { PostDataFunc  } from '../../../../functions/post';
import { UpdateDataFunc } from '../../../../functions/update';
import { useCrudFunc } from '../../../../functions/crud';


export const Hr_Setups_Units = forwardRef(({a}:any, ref)=>{
    const[employeeId, userId] =useAccountInfo();
    const activeStatus = useSelector((state:RootState)=>state.general.activeStatus);

    const [status,setStatus]=useState<boolean|string>('')
    const [searchText,setSearchText] = useState<searchTextTypes>({temp:'',text:''})
    const fetchUrl:string = `HrUnits/GetAllUnits?criteriaIndex=${0}&search=${searchText.text}&active=${status}`;
    const postUrl:string = 'HrUnits/CreateUnit';
    const updateUrl:string = 'HrUnits/UpdateUnit';


    const saveSuccessText = {message : 'Unit added Successfully',ok : 'Ok' , title : 'Save Succesful'} // successful save modal message
    const updateSuccessText = {message : 'Unit updated Successfully',ok : 'Ok' , title : 'Update Successful'} // successful update modal message

    // store data expressions[unique]
    const idExpr:String = 'untIDpk';
    const nameExpr:string = 'untName'
    const shortnameExpr:string = 'untShtName'
    const orderExpr: string = 'untOrder';
    const activeExpr: string = 'untActive';
    const remarksExpr: string = 'untRmks';
    const inputsExprsData: any = {idExpr : idExpr,nameExpr : nameExpr,shortnameExpr : shortnameExpr,orderExpr : orderExpr,activeExpr,remarksExpr : remarksExpr }
    const selectsExpr:selectExprsTypes[] = [{idExpr : 'dptIDpk', nameExpr : 'dptName',label :'department'},{idExpr : 'sxnIDpk', nameExpr : 'sxnName',label :'section'},{idExpr : 'untIDpk', nameExpr : 'untName',label :'unit'},{idExpr : 'untIdpk', nameExpr : 'untShtName',label :'shortname'},{idExpr : 'areIdpk', nameExpr : 'areName',label :'area'},{idExpr : 'areIdpk', nameExpr : 'areName',label :'area'}]

    const storeExprs = () =>{
        const exprs:exprsTypes[] = [{expr:'existsUrl',value:`HrUnits`},{expr: 'idExpr' ,value : idExpr},{expr: 'nameExpr' ,value : nameExpr},{expr: 'shortnameExpr' ,value : shortnameExpr},{expr: 'selectExprs' ,value : selectsExpr}]
        for (let i = 0; i < exprs.length; i++) {            
            dispatch(setTier3Value({expr: exprs[i].expr ,value : exprs[i].value}));
        }

        // to general slice
        const generalExprs:exprsTypes[] = [{expr: 'selectExprs' ,value : selectsExpr},{expr: 'inputExprs' ,value : inputsExprsData}]
        for (let i = 0; i < generalExprs.length; i++) {            
            dispatch(setGeneralValue({expr: generalExprs[i].expr ,value : generalExprs[i].value}));
        }
    }

    const[busyLoader,setBusyLoader] = useBusyLoader();
    const dispatch = useDispatch();
    const [refresh, setRefresh] = useState<boolean>(false); //refresh data state for data creation or update

    //modal states
    //--general--
    const triggerSuccessModal = useSelector((state:RootState)=>state.tier3forms.successModalState); //trigger success modal
    const SuccessModalText = useSelector((state:RootState)=>state.tier3forms.successText); //success modal text

    //--for backend errors--
    const[errState,setErrState] = useState<boolean>(false);
    const[errTitle,seterrTitle] = useState<string>('');
    const[errMessage,seterrMessage] = useState<string>('');

    //--warning modal--
    const triggerwarningModal = useSelector((state:RootState)=>state.tier3forms.warningState); //trigger warning
    const warningInfos:any = validationsData; //warning type details
    const warningDataExpr = useSelector((state:RootState)=>state.tier3forms.warningDataExpr); //warning type selector

    //--save data modal--
    const saveData = saveModalData; //save modal details[data]
    const triggersaveModal = useSelector((state:RootState)=>state.tier3forms.saveDataOnclick); //trigger save modal
   
    //--Update data modal--
    const updateIndInfos = updateModalData; //update modal details[data]
    const triggerupdateModal:boolean = useSelector((state:RootState)=>state.tier3forms.updateDataOnclick); //trigger update question

    // on page resize height responsiveness
    const [trigger,setTrigger] = useState<boolean>(false);
    useImperativeHandle(ref, () => ({
        test: () => {
            setTrigger(!trigger);
        }
    }));

    // get current values from  [slice dependent on form type]
    const select0 = useSelector((state:RootState)=>state.tier3forms.select0Value);
    const select1 = useSelector((state:RootState)=>state.tier3forms.select1Value);
    const name = useSelector((state:RootState)=>state.tier3forms.name);
    const shortname = useSelector((state:RootState)=>state.tier3forms.shortname);
    const order = useSelector((state:RootState)=>state.tier3forms.order);
    const active = useSelector((state:RootState)=>state.tier3forms.active);
    const remarks = useSelector((state:RootState)=>state.tier3forms.remarks);
    const selectedID = useSelector((state:RootState)=>state.general.SelectedFieldID);

    //post form Data
    //validate data
    const validateDataSave = () =>{
        dispatch(tier3SaveOnclick());
    }

    const [Posting, Updating, Patching] = useCrudFunc()
    // post
    const postData = async() =>{
        dispatch(setTier3Value({expr: 'saveDataOnclick' ,value : false}))
        
        try {
            setBusyLoader('Saving record...')
            const res = await Posting (`${postUrl}`,{
                "untName": name,
                "untShtName": shortname,
                "untDeptIdfk": select0,
                "untSectionIdfk": select1,
                "untActive": active,
                "untRmks": remarks,
                // "untCreatedBy": userId,
                "untOrder": order
            },`Created the HR Unit ${name} with Department ID ${select0} and Section ID ${select1}, and remarks ${remarks}, and active status ${active}, and order ${order}`)
            setRefresh(!refresh);
            setBusyLoader('') //disable busy loader

            const exprs:exprsTypes[] = [{expr: 'saveDataOnclick' ,value : false},{expr: 'successModalState' ,value : true},{expr: 'successText' ,value : saveSuccessText}];
            for (let i = 0; i < exprs.length; i++) {            
                dispatch(setTier3Value({expr: exprs[i].expr ,value : exprs[i].value}));
            }
        } catch (error:any) {
            setBusyLoader('')

            //set states for db errors modal
            setErrState(true);
            seterrTitle(postError.title);
            seterrMessage(postError.message);
        }
    }

    //Update Form data
    //validate data
    const validateDataUpdate = () =>{
        dispatch(tier3UpdateOnclick());
    }
    // update
    const UpdateData = async()=>{
        dispatch(setTier3Value({expr: 'updateDataOnclick' ,value : false}));
        
        try {
            setBusyLoader('Updating record...')
            const res = await Updating(`${updateUrl}`,{
                "untIdpk": selectedID,
                "untName": name,
                "untShtName": shortname,
                "untDeptIdfk": select0,
                "untSectionIdfk": select1,
                "untActive": active,
                "untRmks": remarks,
                "untEditedBy": userId,
                "untOrder": order,
                "untEditedDate": dayjs(new Date()).format()
            },`Edited the HR Unit from ID ${selectedID} to ${name} with Department ID ${select0} and Section ID ${select1}, and remarks ${remarks}, and active status ${active}, and order ${order}`)
            setRefresh(!refresh);
            setBusyLoader('') //disable busy loader

            const exprs:exprsTypes[] = [{expr: 'updateDataOnclick' ,value : false},{expr: 'successModalState' ,value : true},{expr: 'successText' ,value : updateSuccessText}];
            for (let i = 0; i < exprs.length; i++) {           
                dispatch(setTier3Value({expr: exprs[i].expr ,value : exprs[i].value}));
            }
        } catch (error:any) {
            console.log(error);
            setBusyLoader('') //disable busy loader

            //set states for db errors modal
            setErrState(true);
            seterrTitle(putError.title);
            seterrMessage(putError.message);
        }
    }

    //modals data
    const modals:modalTypes[] = [
        //warning
        {disableCancel : true , icon : 'warning' ,open : triggerwarningModal, okHandler : ()=> dispatch(setTier3Value({expr: 'warningState' ,value : false})), cancelHandler : ()=> dispatch(setTier3Value({expr: 'warningState' ,value : false})), message : warningInfos[warningDataExpr!]?.message, cancelText : warningInfos[warningDataExpr!]?.cancelText, okText : warningInfos[warningDataExpr!]?.okText, title : warningInfos[warningDataExpr!]?.title},
        //backend error
        {disableCancel : true , icon : 'warning' ,open : errState, okHandler : ()=> setErrState(false), cancelHandler : ()=> setErrState(false), message : errMessage, cancelText : '', okText : 'Ok', title : errTitle},
        //success
        {disableCancel : true , icon : 'success' ,open : triggerSuccessModal, okHandler : ()=> dispatch(setTier3Value({expr: 'successModalState' ,value : false})), cancelHandler : ()=> dispatch(setTier3Value({expr: 'successModalState' ,value : false})), message :SuccessModalText?.message, okText : SuccessModalText?.okText, title : SuccessModalText?.title},
        //save
        {disableCancel : false , icon : 'question' ,open : triggersaveModal, okHandler : ()=> postData(), cancelHandler : ()=> dispatch(setTier3Value({expr: 'saveDataOnclick' ,value : false})), message : saveData?.message, cancelText : saveData?.cancelText, okText : saveData?.okText, title : saveData?.title},
        //Update
        {disableCancel : false , icon : 'question' ,open : triggerupdateModal, okHandler : ()=> UpdateData(), cancelHandler : ()=> dispatch(setTier3Value({expr: 'updateDataOnclick' ,value : false})), message : updateIndInfos?.message, cancelText : updateIndInfos?.cancelText, okText : updateIndInfos?.okText, title : updateIndInfos?.title},
    ]

    // data for datagrid
    const [data,Error,Loading] = useFetch(fetchUrl,refresh,'untIDpk');

    const [departmentData,departmentError,departmentLoading] = useFetch('HrUnits/GetDepartments'); //department

    const [sectionData,sectionError,sectionLoading] = useFetch(`HrUnits/GetSections?dptId=${select0}`); //section

    useEffect(()=>{
        if(data){
            storeExprs(); // run func to store expressions on store
            dispatch(setGeneralValue({expr : 'idExpr', value : idExpr})); // dispatch fetched data to reduxstore
            dispatch(setGeneralValue({expr: 'formData' ,value : data})); // dispatch fetched data to reduxstore
        }

        Loading ? setBusyLoader('.') : setBusyLoader('')
    },[Loading,data])
    
    // form parameters
    const form_header = 'Unit Details';
    const selects:selectsTypes[] = [
        {id: 0, label : 'Department', optionsData :departmentData , idExpr:'dptIDpk',dataExpr:'dptName', diffExprs:{idKey:'dptIDpk', valueKey:'dptName'}},
        {id: 1, label : 'Section', optionsData : sectionData,idExpr:'sxnIDpk',dataExpr:'sxnShtName', diffExprs:{idKey:'sxnIDpk', valueKey:'sxnName'}},
    ]
    const optionalnamelabel = 'Unit';

    return (
        <>
            {Error&&(
                <WarningAlert/>
            )}

            {modals.map(({disableCancel,icon,okHandler,cancelHandler,open,cancelText,okText,message,title}:modalTypes,index)=>{
                return(
                    <div key = {index}>
                        <ModalTemplate  icon= {icon && icon} disableCancel = {disableCancel} cancelText = {cancelText && cancelText} open = {open} okHandler={okHandler} cancelHandler={cancelHandler} message= {message} okText= {okText} title={title}/>
                    </div>
                )
            })}

            <Setups_Template
            searchTextChange={(txt)=>{setSearchText({temp:txt,text:searchText.text})}}
            handleSearch={()=>{
               setSearchText({temp:searchText.temp,text:searchText.temp});
               setStatus(activeStatus)
            }}  
            optionalnamelabel={optionalnamelabel}
            selects={selects}
            createData={validateDataSave}
            updateData={validateDataUpdate}
            trigger = {trigger} 
            handleRefresh={()=> setRefresh(current=>!current)}
            datagridColumns={hr_setups_units_columns} 
            datagridData = {data} 
            formTier='3'
            formHeader={ form_header}
            gridheight_Form={410}
            />
        </>
    );
})