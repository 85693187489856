import { Checkbox, Form } from "antd";
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
  forwardRef,
} from "react";
import {
  checkboxTypes,
  selectsTypes,
} from "../../../human-resource/setups/data/types/selectsTypes";
import { SelectsTemplate } from "../../../../templates/select";
import { BottomBtns } from "../../../../templates/butttons";
import { CheckboxTemlate } from "../../../../templates/checkbox";
import InputSelect_template from "../../../../templates/InputSelect";
import useFetch from "../../../../hooks/useFetch";
import { setGeneralValue } from "../../../../features/generalSlice";
import { useDispatch } from "react-redux";
import { useFormPriviledge } from "../../../../hooks/useFormPriviledge";
import { ModalTemplate } from "../../../../templates/modal";
import { PostDataFunc  } from "../../../../functions/post";
import { UpdateDataFunc } from "../../../../functions/update";
import {
  saveModalData,
  updateModalData,
} from "../../../accessories/component_infos";

interface modalTypes {
  info: any;
  open: boolean;
  icon: "warning" | "question" | "success";
  disableCancel?: boolean;
}

interface stateTypes {
  /// Form States
  module: { id: number, name: string },
  formType: { id: number, name: string },
  formName: { id: number, name: string },
  shtName: { id: number, name: string },
  formCode: { id: number, name: string },
  slfServiceForm: boolean,
  description: string,
  remarks: string,
  visible: boolean,
  active: boolean,
  disableForms: boolean
}

interface props {
  currentStates?: any;
  setCurrentStates?: any;
  refreshState?: () => void;
}
export const ApplicationForm_details = forwardRef(
  (
    { currentStates, setCurrentStates, refreshState, }: props,
    ref
  ) => {

    const dispatch = useDispatch();
    const [states, setStates] = useState<stateTypes>({
      module: { id: 0, name: "" },
      formType: { id: 0, name: "" },
      formName: { id: 0, name: "" },
      shtName: { id: 0, name: "" },
      formCode: { id: 0, name: "" },
      slfServiceForm: false,
      description: "",
      remarks: "",
      visible: false,
      active: true,
      disableForms: false
    });

    const [modalData, setModalData] = useState<modalTypes>({
      info: saveModalData,
      open: false,
      icon: "warning",
      disableCancel: false,
    });

    const [openModal, setOpenModal] = useState(false);
    const [Icon, setIcon] = useState<"warning" | "question" | "success">(
      "warning"
    );

    const {
      module,
      formCode,
      formName,
      formType,
      shtName,
      active,
      slfServiceForm,
      description,
      remarks,
      visible,
    } = states;

    const updateState = (key: string, value: any) => {
      setStates((prev: any) => ({ ...prev, [key]: value }));
    };

    console.log("Applica", states)

    const [moduleData, moduleLoading, moduleErr] = useFetch(
      `ApplicationForm/PopCmbModules`
    );
    const [formCodeData, formCodeLoading, formCodeErr] = useFetch(
      `ApplicationForm/PopAppFormCmbCode`
    );
    const [nameData, nameLoading, nameErr] = useFetch(
      `ApplicationForm/PopAppFormCmbName`
    );
    const formTypes = [
      { id: 0, name: "Setup" },
      { id: 1, name: "Entry" },
      { id: 2, name: "Report" },
      { id: 3, name: "Sys Admin" },
    ];

    const checkBoxes: checkboxTypes[] = [
      { id: 0, stateName: "visible", defaultValue: visible, label: "Visible?" },
      {
        id: 1,
        stateName: "slfServiceForm",
        defaultValue: slfServiceForm,
        label: "Self Service Form?",
      },
    ];

    const selects: selectsTypes[] = [
      {
        id: 0,
        label: "Module",
        idExpr: "apmIDpk",
        dataExpr: "apmName",
        optionsData: moduleData,
        stateName: "module",
        defaultValue: module.name,
        style: "",
      },
      {
        id: 1,
        label: "Form Type",
        idExpr: "id",
        dataExpr: "name",
        optionsData: formTypes,
        stateName: "formType",
        defaultValue: formType.name,
        style: "",
      },
      {
        id: 2,
        label: "Form Name",
        idExpr: "afmIDpk",
        dataExpr: "afmName",
        optionsData: nameData,
        stateName: "formName",
        defaultValue: formName.name,
        style: "",
      },
      {
        id: 3,
        label: "Short Name",
        idExpr: "afmIDpk",
        dataExpr: "afmShtName",
        optionsData: nameData,
        stateName: "shtName",
        defaultValue: shtName.name,
        style: "",
      },
      {
        id: 4,
        label: "Form Code",
        idExpr: "",
        dataExpr: "afmCode",
        optionsData: formCodeData,
        stateName: "formCode",
        defaultValue: formCode.name,
        style: "",
      },
      // {
      //   id: 5,
      //   label: "Description",
      //   idExpr: "",
      //   dataExpr: "",
      //   optionsData: "afmDesc",
      //   stateName: "description",
      //   defaultValue: states.afmDesc,
      //   style: "",
      // },
      // {
      //   id: 6,
      //   label: "Remarks",
      //   idExpr: "",
      //   dataExpr: "afmRmks",
      //   optionsData: "",
      //   stateName: "remarks",
      //   defaultValue: states.afmRmks,
      //   style: "",
      // }
    ];

    const [privType, setPrivType] = useState("save");
    const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);

    const newAccessPriv = () => {
      setPrivType("save");

      if (savePriv === true) {
        dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
        setStates({
          module: { id: 0, name: "" },
          formType: { id: 0, name: "" },
          formName: { id: 0, name: "" },
          shtName: { id: 0, name: "" },
          formCode: { id: 0, name: "" },
          slfServiceForm: false,
          description: "",
          remarks: "",
          visible: false,
          active: false,
          disableForms: false
        });
      } else if (updatePriv === true) {
        dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
        validateForms("update");
      } else {
        dispatch(setGeneralValue({ expr: "formDisabled", value: true }));

        setModalData({
          ...modalData,
          info: {
            message: "No Privilege Access",
            title: "Access Denied!",
          },
          open: true,
          disableCancel: true,
        });
      }
    };

    const saveAccessPriv = () => {
      setPrivType("save");
      if (savePriv === true) {
        validateForms("save");
        dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      } else {
        dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
        setModalData({
          ...modalData,
          info: {
            message: "No Privilege Access",
            title: "Access Denied!",
          },
          open: true,
          disableCancel: true,
        });
      }
    };

    const validateForms = async (type: any) => {
      setModalData({
        ...modalData,
        icon: "warning",
        disableCancel: true,
      });
      let isValid = true;

      if (states.module.id === 0 || states.module.name === "") {
        isValid = false;
        setModalData({
          ...modalData,
          icon: "warning",
          info: {
            message: "Module is required",
            title: "Validation Error",
          },
          open: true,
          disableCancel: true,
        });
      }

      if (states.formType.id === 0 || states.formType.name === "") {
        isValid = false;
        setModalData({
          ...modalData,
          icon: "warning",
          info: {
            message: "Form Type is required",
            title: "Validation Error",
          },
          open: true,
          disableCancel: true,
        });
      }

      if (states.formName.id === 0 || states.formName.name === "") {
        isValid = false;
        setModalData({
          ...modalData,
          icon: "warning",
          info: {
            message: "Form Name is required",
            title: "Validation Error",
          },
          open: true,
          disableCancel: true,
        });
      }

      if (states.shtName.id === 0 || states.shtName.name === "") {
        isValid = false;
        setModalData({
          ...modalData,
          icon: "warning",
          info: {
            message: "Short Name is required",
            title: "Validation Error",
          },
          open: true,
          disableCancel: true,
        });
      }

      if (states.formCode.id === 0 || states.formCode.name === "") {
        isValid = false;
        setModalData({
          ...modalData,
          icon: "warning",
          info: {
            message: "Form Code is required",
            title: "Validation Error",
          },
          open: true,
          disableCancel: true,
        });
      }

      // if (states.description === "") {
      //   isValid = false;
      //   setModalData({
      //     ...modalData,
      //     icon: "warning",
      //     info: {
      //       message: "Description is required",
      //       title: "Validation Error",
      //     },
      //     open: true,
      //     disableCancel: true,
      //   });
      // }

      if (isValid) {
        if (type === "save") {
          setModalData({
            ...modalData,
            info: saveModalData,
            open: true,
            icon: "question",
            disableCancel: false,
          });
        } else if (type === "update") {
          setModalData({
            ...modalData,
            info: updateModalData,
            open: true,
            icon: "question",
            disableCancel: false,
          });
        }
      }
    };

    const postData = async () => {
      try {
        const res = await PostDataFunc (
          `ApplicationForm/CreateApplicationForm`,
          {
            afmName: states.formName.name,
            afmShtName: states.shtName.name,
            afmModuleIDfk: states.module.id,
            afmCode: states.formCode.name,
            afmType: states.formType.name,
            afmDesc: states.description,
            afmSelfService: states.slfServiceForm,
            afmVisible: states.visible,
            afmActive: states.active,
            afmRmks: states.remarks,
          }
        );
        setModalData({ ...modalData, open: false });
        dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
        refreshState && refreshState();
        setIcon("success");
        setModalData({
          ...modalData,
          info: {
            message: "Record Saved Successfully",
            title: "Success",
            okText: "ok",
          },
          icon: "success",
          open: true,
          disableCancel: true,
        });
      } catch (err: any) {
        setIcon("warning");
        setOpenModal(true);
        setModalData({
          ...modalData,
          info: {
            message: err.message,
            title: "Unexpected Error!",
            okText: "Ok",
          },
          disableCancel: true,
        });
      }
    };

    const updateData = async () => {
      const res = await UpdateDataFunc(
        `ApplicationForm/UpdateApplicationForm?afmIDpk=${states.module.id}`,
        {
          afmName: states.formName.name,
          afmShtName: states.shtName.name,
          afmModuleIDfk: states.module.id,
          afmCode: states.formCode.name,
          afmType: states.formType.name,
          afmDesc: states.description,
          afmSelfService: states.slfServiceForm,
          afmVisible: states.visible,
          afmActive: states.active,
          afmRmks: states.remarks,
        }
      );
      setModalData({
        ...modalData,

        open: false,
      });

      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      refreshState && refreshState();
      setIcon("success");
      setModalData({
        ...modalData,
        info: {
          message: "Record updated Successfully",
          title: "Success",
          okText: "ok",
        },
        icon: "success",
        open: true,

        disableCancel: true,
      });
    };



    const populateFields = (e:any) => {
      setStates({
        module: { name: e?.apmName, id: e?.apmIDpk },
        formType: { name: e?.afmType, id: 1 },
        formName: { name: e?.afmName, id: e?.afmIDpk },
        shtName: { name: e?.afmShtName, id: e?.afmIDpk },
        formCode: { name: e?.afmCode, id: e?.afmIDpk },
        slfServiceForm: e?.afmSelfService,
        description: e?.afmDesc,
        remarks: e?.afmRmks,
        visible: e?.afmVisible,
        active: e?.afmActive,
        disableForms: true,
      });
    }


    useImperativeHandle(ref, () => ({
      singleClick: (e: any) => {
        console.log("eee", e)
        if (e !== undefined) {
          populateFields(e);
        }
        dispatch(setGeneralValue({ expr: "updateMode", value: false }));
        dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      },
      doubleClicked: (e: any) => {
        populateFields(e);
        dispatch(setGeneralValue({ expr: "updateMode", value: true }));
        dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      },
    }));

    return (

      <>

        <div
          style={{ width: "450px", minWidth: "450px", height: "100%" }}
          className="    dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base  "
        >
          {/* <h4 className=" w-full  mb-2 bg-slate-100  ">Task Details</h4> */}
          <ModalTemplate
            icon={modalData.icon}
            disableCancel={modalData.disableCancel}
            open={modalData.open}
            okHandler={() => {
              modalData.info === saveModalData
                ? postData()
                : modalData.info === updateModalData
                  ? updateData()
                  : setModalData({ ...modalData, open: false });
            }}
            cancelHandler={() => {
              setModalData({ ...modalData, open: false });
            }}
            message={modalData.info.message}
            okText={modalData.info.okText}
            title={modalData.info.title}
          />


          <div
            style={{ maxHeight: "220px", minHeight: "220px", height: "220px" }}
            className=" items-start justify-start "
          >
            <Form
              className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded-md py-2 px-2 "
              name="basic"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              autoComplete="off"
              size="small"
              layout="horizontal"
              disabled={false}
            >
              {selects.map(
                ({
                  id,
                  label,
                  idExpr,
                  optionsData,
                  dataExpr,
                  stateName,
                  defaultValue,
                  style,
                }) => {
                  return (
                    <div key={id} className="">

                      {id !== 0 ? (
                        <InputSelect_template
                          useCallFunc
                          placeHolder={defaultValue}
                          label={label}
                          dataexp={dataExpr}
                          idexpr={idExpr}

                          // refresh={true}
                          options={optionsData}
                          selectedValue={(value: any) => {
                            updateState(stateName!,
                              {
                                id: value[idExpr!],
                                name: value[dataExpr!],
                              });
                          }}
                        />
                      ) : (
                        <SelectsTemplate
                          placeHolder={defaultValue}
                          label={label}
                          dataexp={dataExpr}
                          idexpr={idExpr}
                          // refresh={true}
                          options={optionsData}
                          // style={label == 'Short Name' ? { width: '91.3%', marginLeft: '2.2%' } : { width: '100%' }}
                          // datalistStyle={label === 'Name' && { width: '82.2%' }}
                          useCallFunc
                          selectedValue={(value: any) => {
                            updateState(stateName!, {
                              id: value[idExpr!],
                              name: value[dataExpr!],
                            });
                          }}
                        />
                      )}
                    </div>
                  );
                }
              )}

              <div className="w-full flex flex-col mt-0.5">
                <CheckboxTemlate
                  withBorders
                  label={"Self Service Form?"}
                  label2={"Visible?"}
                  inlineRightLabelCol={6}
                  inlineDefaultValue={[slfServiceForm, visible]}
                  useCallbackFunc
                  index={["Self Service Form?", "Visible?"]}
                  setCheckboxOnchange={(e, index) => {
                    index === "Self Service Form?" ? updateState("slfServiceForm", e) : updateState("visible", e)

                  }}
                />
                {/* {checkBoxes.map(({ id, defaultValue, stateName, label }) => {
                return (
                  <div >
                    {label}
                    <Checkbox
                      // disabled={form_disable}
                      id="test"
                      checked={defaultValue}
                      onChange={(e: any) => {
                        updateState(stateName!, e.target.checked);
                      }}
                    ></Checkbox>
                  </div>
                );
              }
              
              )} */}

                <div className="w-full flex mt-0.5">
                  <BottomBtns
                    label="Active?"
                    addActiveBorders
                    useCallbackFunc
                    defaultValue={active}
                    onNewClick={() => {
                      newAccessPriv();
                    }}
                    onCancelClick={() => { }}
                    updateHandler={() => {
                      validateForms("update");
                    }}
                    saveHandler={() => saveAccessPriv()}
                    checkboxChange={(e) => {
                      updateState("active", e);
                    }}
                  />
                </div>
              </div>
            </Form>
          </div>
        </div>
      </>
    );
  }
);
