import { Form, Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";
import save from "../../../../assets/floppy-disk.png";
import newIcon from "../../../../assets/new.png";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { settransDetailsValue } from "../../../../features/forms/entries/safetyManager/ppeRequest";
import { settask_footer_table_refresh } from "../../../../features/generalSlice";
import {
  navbar_height,
  toolbar_height,
  api_url,
  saveModalData,
} from "../../../accessories/component_infos";
import { formPropsConst } from "../../../accessories/constants";
import { ImageUpload } from "./imageUpload";
import { ModalTemplate } from "../../../../templates/modal";

interface props {
  toggled?: boolean;
  states?:any;
  updateState ?: (key: string, value: any) => void;
}



const OtherDetailsTab = ({ toggled,states,updateState }: props) => {

  console.log("states",states)
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );

  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const sp = "\u00a0";

  const heights_out = navbar_height + news_flash_height + toolbar_height;

  //varying form height
  const form_height = 490;

  //Table Height
  const pageHeight = window.innerHeight - navbar_height; //height without navbar
  const [tableHeight, settableHeight] = useState(
    window.innerHeight - (heights_out + 70)
  ); //height without navbar and toolbar
  const [table_form_Height, settable_form_Height] = useState(
    window.innerHeight - heights_out
  ); //height without navbar and toolbar and form

  const [showTable, setShowTable] = useState(true);
  const dateFormat = " DD MMM YYYY HH: mm:ss";

  const dispatch = useDispatch();

  const refreshRecord = useSelector(
    (state: RootState) => state.general.refresh
  );
  const [refresh, setRefresh] = useState<boolean>(false);
  // Onchange values

//   const [tw, setTw] = useState<string>("");
//   const [tcr, setTcr] = useState<string>("");
//   const [tcs, setTcs] = useState<string>("");
//   const [imp, setImp] = useState<string>("");
//   const [nt, setNt] = useState<string>("");
//   const [ro, setRo] = useState<string>("");
//   const [rv1, setRv1] = useState<string>("");
//   const [rv2, setRv2] = useState<string>("");
//   const [rv3, setRv3] = useState<string>("");
//   const [rp1, setRp1] = useState<string>("");
//   const [rp2, setRp2] = useState<string>("");
//   const [rp3, setRp3] = useState<string>("");
//   const [zh, setZh] = useState<string>("");
//   const [zx, setZx] = useState<string>("");
//   const [zy, setZy] = useState<string>("");
//   const [resist, setResist] = useState("");

  const validation = () => {
   const fields = [
     { value: states.tappedWinding, name: "Tapped Winding" },
     { value: states.tapChangeRange, name: "Tap Change Range" },
     { value: states.tapChangeStep, name: "Tap Change Step" },
     { value: states.deg, name: "Deg" },
     { value: states.normalTap, name: "Normal Tap" },
     { value: states.ratedOutput, name: "Rated Output" },
     { value: states.ratedVoltageI, name: "Rated Voltage I" },
     { value: states.ratedVoltageII, name: "Rated Voltage II" },
     { value: states.ratedVoltageIII, name: "Rated Voltage III" },
     { value: states.ratedPowerI, name: "Rated Power I" },
     { value: states.ratedPowerII, name: "Rated Power II" },
     { value: states.ratedPowerIII, name: "Rated Power III" },
     { value: states.zhBase, name: "ZH Base" },
     { value: states.zxBase, name: "ZX Base" },
     { value: states.zyBase, name: "ZY Base" },
     { value: states.resistanceBase, name: "Resistance Base" },
   ];
 
   for (const field of fields) {
     if (field.value === "") {
       setModalData({
         message: `Please input data entry for ${field.name} field!`,
         title: "Required Field",
         okText: "Ok",
       });
       setOpenModal(true);
       setIcon("warning");
       return;
     }
   }
 };

  // useEffect(() => {
  //    console.log(`inside ${toggled}`)
  // }, [toggled])

//   const updateData = async () => {
//     alert("updateData");

//     const res = await axios
//       .put(
//         `${api_url}/api/AssetTamEntTransformerDetail/UpdateTransformerOrderDetail`,
//         {
//           tfdIdpk: selectedRecord[0].tfdIdpk,
//           tfdTappedWinding: tw,
//           tfdTapChangerRange: tcr,
//           tfdTapChangerStep: tcs,
//           tfdNominalTap: nt,
//           tfdRatedOutput: ro,
//           tfdRatedVoltageI: rv1,
//           tfdRatedVoltageIi: rv2,
//           tfdRatedVoltageIii: rv3,
//           tfdRatedPowerI: rp1,
//           tfdRatedPowerIi: rp2,
//           tfdRatedPowerIii: rp3,
//           tfdZhAt100MvabaseAt75Deg: zh,
//           tfdZxAt100MvabaseAt75Deg: zx,
//           tfdZyAt100MvabaseAt75Deg: zy,
//           tfdResistanceAt100MvabaseAt75Deg: resist,
//           tfdPercentImpedanceAt75Deg: imp,
//         }
//       )
//       .then((response) => {
//         console.log(response);
//         alert("Updated successfully");
//         dispatch(settask_footer_table_refresh(!refresh));
//       });
//   };

  const { Option } = Select;
  return (
    <div>
      <ModalTemplate
        icon={icon}
        disableCancel={icon ==="warning" ? true :false}
        cancelText={modalData.cancelText}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        open={openModal}
        okHandler={() => {
          //  modalData === saveModalData
          //    ? postData()
          //    : modalData === updateModalData
          //    ? updateData()
          //    : setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
      />
      {
        <div className="  rounded-md">
          {/* The form */}
          <div className=" flex ">
            <Form
              {...formPropsConst}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 18 }}
              className="ml-2 my-2 "
              style={{ width: "50%" }}
            >
              <Form.Item
                label={<p className="text-xs">{"Tapped Winding/kV"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <Input
                      onChange={(e: any) => {
                       updateState && updateState("tappedWinding", e.target.value);
                      }}
                      style={{ marginBottom: 0 }}
                      className=""
                      placeholder="161kV"
                    />
                  </div>
                </Form.Item>
              </Form.Item>

              <Form.Item
                label={<p className="text-xs">{"Tap Changer Range"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <Input
                      onChange={(e: any) => {
                        updateState && updateState("tapChangeRange", e.target.value);
                      }}
                      style={{ marginBottom: 0 }}
                      className=""
                      placeholder=""
                    />
                  </div>
                </Form.Item>
              </Form.Item>

              <Form.Item
                label={<p className="text-xs">{"Tap Changer Steps"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <Input
                      onChange={(e: any) => {
                        updateState && updateState("tapChangeStep", e.target.value);
                      }}
                      style={{ marginBottom: 0 }}
                      className=""
                      placeholder=""
                    />
                  </div>
                </Form.Item>
              </Form.Item>

              <Form.Item
                label={<p className="text-xs">{"% Imp. @ 75 Deg"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <Input
                      onChange={(e: any) => {
                        updateState && updateState("deg", e.target.value);
                      }}
                      style={{ marginBottom: 0 }}
                      className=""
                      placeholder=""
                    />
                  </div>
                </Form.Item>
              </Form.Item>

              <Form.Item
                label={<p className="text-xs">{"Nominal Tap"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <Input
                      onChange={(e: any) => {
                        updateState && updateState("normalTap", e.target.value);
                      }}
                      style={{ marginBottom: 0 }}
                      className=""
                      placeholder=""
                    />
                  </div>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={<p className="text-xs">{"Rated Output (kV)"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <Input
                      onChange={(e: any) => {
                        updateState && updateState("ratedOutput", e.target.value);
                      }}
                      style={{ marginBottom: 0 }}
                      className=""
                      placeholder=""
                    />
                  </div>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={<p className="text-xs">{"Rated Volatage I (kV)"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <Input
                      onChange={(e: any) => {
                        updateState && updateState("ratedVoltageI", e.target.value);
                      }}
                      style={{ marginBottom: 0 }}
                      className=""
                      placeholder=""
                    />
                  </div>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={<p className="text-xs">{"Rated Volatage II (kV)"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <Input
                      onChange={(e: any) => {
                        updateState && updateState("ratedVoltageII", e.target.value);
                      }}
                      style={{ marginBottom: 0 }}
                      className=""
                      placeholder=""
                    />
                  </div>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={<p className="text-xs">{"Rated Volatage III (kV)"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <Input
                      onChange={(e: any) => {
                        updateState && updateState("ratedVoltageIII", e.target.value);
                      }}
                      style={{ marginBottom: 0 }}
                      className=""
                      placeholder=""
                    />
                  </div>
                </Form.Item>
              </Form.Item>
            </Form>

            <Form
              {...formPropsConst}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 20 }}
              className="ml-2 my-2"
              style={{ width: "50%" }}
            >
              <Form.Item
                label={<p className="text-xs">{"Rated Power I (MVA)"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <Input
                      onChange={(e: any) => {
                        updateState && updateState("ratedPowerI", e.target.value);   
                      }}
                      style={{ marginBottom: 0 }}
                      className=""
                      placeholder=""
                    />
                  </div>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={<p className="text-xs">{"Rated Power II (MVA)"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <Input
                      onChange={(e: any) => {
                         updateState("ratedPowerII", e.target.value);
                      }}
                      style={{ marginBottom: 0 }}
                      className=""
                      placeholder=""
                    />
                  </div>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={<p className="text-xs">{"Rated Power III (MVA)"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <Input
                      onChange={(e: any) => {
                        updateState && updateState("ratedPowerIII", e.target.value);
                      }}
                      style={{ marginBottom: 0 }}
                      className=""
                      placeholder=""
                    />
                  </div>
                </Form.Item>
              </Form.Item>

              <Form.Item
                label={<p className="text-xs">{"Zh on 100MVA Base"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <Input
                      onChange={(e: any) => {
                        updateState && updateState("zhBase", e.target.value);
                      }}
                      style={{ marginBottom: 0 }}
                      className=""
                      placeholder=""
                    />
                  </div>
                </Form.Item>
              </Form.Item>

              <Form.Item
                label={<p className="text-xs">{"Zx on 100MVA Base"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <Input
                      onChange={(e: any) => {
                        updateState && updateState("zxBase", e.target.value);
                      }}
                      style={{ marginBottom: 0 }}
                      className=""
                      placeholder=""
                    />
                  </div>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={<p className="text-xs">{"Zy on 100MVA Base"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <Input
                      onChange={(e: any) => {
                           updateState("zyBase", e.target.value);
                      }}
                      style={{ marginBottom: 0 }}
                      className=""
                      placeholder=""
                    />
                  </div>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={<p className="text-xs">{"Resist. on 100MVA Base"}</p>}
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="issuedBy"
                  style={{ display: "inline-block", width: "calc(94%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <Input
                      onChange={(e: any) => {
                        updateState && updateState("resistanceBase", e.target.value);
                      }}
                      style={{ marginBottom: 0 }}
                      className=""
                      placeholder=""
                    />
                  </div>
                </Form.Item>
              </Form.Item>
              <div className="flex  justify-end mt-20 mr-4">
                <button
                  className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  mr-1 px-2 py-0.5 border-2 border-slate-200 rounded"
                  onClick={() => {
                     validation();
                  }}
                >
                  <img className="pr-1" src={save} />
                  Update
                </button>
              </div>
            </Form>
          </div>
        </div>
      }
    </div>
  );
};

export default OtherDetailsTab;
