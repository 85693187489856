import React, { useState, useCallback, useRef, forwardRef, useEffect, useImperativeHandle } from 'react'
import { Form, Input, Select, DatePicker, Checkbox } from 'antd';
import { CheckBox } from 'devextreme-react/check-box';
import refresh from '../../../../../assets/refresh.png'
import dayjs from 'dayjs';
import { SaveButton, NewButton, Cancel, UpdateButton } from '../../../../accessories/buttons';
import { CIA_Details_Select, CI_Details_Select, Objective_Details_Select, Theme_Details_Select } from '../../../data/Performance_selects';
import { CI_TextArea, Objective_Input, Objective_mini_TextArea, Objective_TextArea, Theme_Input, Theme_mini_TextArea, Theme_TextArea } from '../../../data/Performance_inputs';
import axios from 'axios';
import { api_url, saveModalData, updateModalData } from '../../../../accessories/component_infos';
import { ModalTemplate } from '../../../../../templates/modal';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../app/store';
import { task_footer_table_selected, task_footer_update, task_form_disable } from '../../../../../features/Task&Todo/Task/TaskDetails';
import { TextareaTemplate } from '../../../../../templates/textarea';


interface props {

    toggleRefresh: any,

}

export const Theme_Details_Form = forwardRef(({ toggleRefresh }: props, ref: any) => {
    const { Option } = Select;
    const { TextArea } = Input;

    // import { AssignedDept, AssignedLocation, AssignedSelection, AssignedUnit, Batch, Group, PPE_Select, RequestWindow, StaffName } from './PPE_selects';
const borderTheme = useSelector((state:RootState)=>state.general.cssBorderClass)

    const options = [];
    for (let i = 1; i < 36; i++) {
        options.push({
            label: i.toString(36) + i,
            value: i.toString(36) + i,
        });
    }

    const [textAreaHeight, settextAreaHeight] = useState<string>('75px')

    const handleChange = (value: string[]) => {
        console.log(`selected ${value}`);
        if (value.length > 7) {
            settextAreaHeight('60px')
        } else {
            settextAreaHeight('71px')
        }
    };
    const dateFormat = " DD MMM YYYY HH: mm:ss";

    const [missionVisionData, setMissionVisionData] = useState([]);

    //Run functions from table
    useImperativeHandle(ref, () => ({

        doubleClick: () => {
            setDisableForms(false);
            setUpdatingStatus(true);
            setShowCancel(true);
        },
        singleClick: (selectedRow: any) => {
            console.log('eee');
            console.log(selectedRow);
            // const value = missionVisionData.filter((data:any ) => data['mvsName'] === e)
            setAllStates({
                id: selectedRow.thmIdpk,
                missionVision: { name: selectedRow.mvsName, id: selectedRow.mvsIdpk },
                strategicTheme: selectedRow.thmName,
                shortName: selectedRow.thmShtName,
                commentary: selectedRow.thmCommentary,
                remarks: selectedRow.thmRmks,
                order: selectedRow.thmOrder,
                active: selectedRow.thmActive,
            });
            setDisableForms(true);
            setUpdatingStatus(false);
            setShowCancel(false);
        }


    }));

    /**
    **************************************
    ************************************** 
    ***********Api Integrations***********
    **************************************
    **************************************
    */

    async function fetchMissionVision() {
        try {

            // get data
            const res = await axios.get(`${api_url}/api/PfmMissionVision/GetAllPfmMissionVision`, {
                headers: {
                    'Content-Type': ['application/json', 'text/plain', 'charset=utf-8'],
                    // Accept : ['text/plain','application/json','charset=utf-8'],
                }
            })

            setMissionVisionData(res.data.data.reverse()); // set data
        } catch (error: any) {
            console.log(error);

        } finally {

        }
    }

    const validateForms = async () => {
        setIcon('warning');
        if (allStates.missionVision.id === '') {
            setShowModalDisableButton(true);
            setModalData({ message: 'Please select a Mission/Vision', title: 'Select Mission/Vision', okText: 'Ok' },)
            setOpenModal(true);

        } else if (allStates.strategicTheme === '') {
            setShowModalDisableButton(true);
            setModalData({ message: 'Please enter a Strategic Objective', title: 'Enter Strategic Objective', okText: 'Ok' },)
            setOpenModal(true);
        } else if (allStates.shortName === '') {
            setShowModalDisableButton(true);
            setModalData({ message: 'Please enter a Short Name', title: 'Enter Short Name', okText: 'Ok' },)
            setOpenModal(true);
        } else if (allStates.order === '') {
            setShowModalDisableButton(true);
            setModalData({ message: 'Please enter a Order', title: 'Enter Order', okText: 'Ok' },)
            setOpenModal(true);
        } else {
            setIcon('question');
            setShowModalDisableButton(false);
            setOpenModal(true);
        }
    }

    //Function Calls
    const updateData = async () => {
        console.log('here');

        const del = await axios.put(`${api_url}/api/PfmTheme/UpdatePfmTheme`, {

            "thmIdpk": 1,
            "thmMissionIdfk": 1,
            "thmName": allStates.strategicTheme,
            "thmShtName": allStates.shortName,
            "thmCommentary": allStates.commentary,
            "thmOrder": 1,
            "thmActive": allStates.active,
            "thmRmks": allStates.remarks,
            "thmEditedBy": 1,
            "thmEditedDate": `${dayjs().format('YYYY-MM-DDTHH:mm:ss[Z]')}`


            // "objIdpk": immutableIDS.objIDpk,
            // "objMissionIdfk": missionVisionSelected.id,
            // "objThemeIdfk": themesSelected.id,
            // "objPerspectiveIdfk":perspectiveSelected.id, 
            // "objName": strategicObjectives,
            // "objShtName": shortName,
            // "objDescription": description,
            // "objOutcome":outcome,
            // "objOrder": order,
            // "objActive": active,
            // "objRmks": remarks,
            // "objEditedBy": 1,
            // "objEditedDate":  `${dayjs().format('YYYY-MM-DDTHH:mm:ss[Z]')}`
        }
        )
        //close modal
        setOpenModal(false);
        //Refresh table
        setShowModalDisableButton(true);
        setIcon('success');
        setModalData({ message: 'Record successfully updated', title: 'Record updated', okText: 'Ok' },)
        setOpenModal(true);
        toggleRefresh();


    }
    const postData = async () => {
        // dispatch(setValue({expr: 'saveDataOnclick' ,value : false})) //disable modal
        try {
            console.log({
                "thmMissionIdfk": allStates.missionVision.id,
                "thmName": allStates.strategicTheme,
                "thmShtName": allStates.shortName,
                "thmCommentary": allStates.commentary,
                "thmOrder": allStates.order,
                "thmActive": allStates.active,
                "thmRmks": allStates.remarks,
                "thmCreatedBy": 1,
            })

            const res = await axios.post(`${api_url}/api/PfmTheme/CreatePfmTheme`,
                {
                    "thmMissionIdfk": allStates.missionVision.id,
                    "thmName": allStates.strategicTheme,
                    "thmShtName": allStates.shortName,
                    "thmCommentary": allStates.commentary,
                    "thmOrder": allStates.order,
                    "thmActive": allStates.active,
                    "thmRmks": allStates.remarks,
                    "thmCreatedBy": 1,

                    // "objMissionIdfk": missionVisionSelected.id,
                    // "objThemeIdfk": themesSelected.id,
                    // "objPerspectiveIdfk":perspectiveSelected.id,
                    // "objName": strategicObjectives,
                    // "objShtName": shortName,
                    // "objDescription": description,
                    // "objOutcome":outcome,
                    // "objOrder": order,
                    // "objActive": active,
                    // "objRmks": remarks,
                    // "objCreatedBy": 0
                }
            )


            setOpenModal(false);
            setShowModalDisableButton(true);
            setIcon('success');
            setModalData({ message: 'Record successfully saved', title: 'Record saved', okText: 'Ok' },)
            setOpenModal(true);
            toggleRefresh();

            // const exprs:exprsTypes[] = [{expr: 'saveDataOnclick' ,value : false},{expr: 'successModalState' ,value : true},{expr: 'successText' ,value : saveSuccessText}];
            // for (let i = 0; i < exprs.length; i++) {            
            //     dispatch(setValue({expr: exprs[i].expr ,value : exprs[i].value}));
            // }
        } catch (error: any) {
            //set states for db errors modal
            // setErrState(true);
            // seterrTitle(error.response ? error.response.data.message : 'Server Error');
            // seterrMessage(error.response ? error.response.data.errors[0] : 'Error connecting to server. Please contact system administrator');
        }
    }
    const clearFields = async () => {
        setAllStates(
            {
                id: '',
                missionVision: { name: '', id: '' },
                strategicTheme: '',
                shortName: '',
                commentary: '',
                remarks: '',
                order: '',
                active: false
            }
        )

    }




    const [updatingStatus, setUpdatingStatus] = useState(false);

    const [showModalDisableButton, setShowModalDisableButton] = useState(false);
    const [refreshValue, setRefreshValue] = useState(false);
    const [disableForms, setDisableForms] = useState(true);
    const [showCancel, setShowCancel] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [modalData, setModalData] = useState<any>(saveModalData);
    const [icon, setIcon] = useState<'warning' | 'success' | 'question'>();

    const [allStates, setAllStates] = useState({
        id: '',
        missionVision: { name: '', id: '' },
        strategicTheme: '',
        shortName: '',
        commentary: '',
        remarks: '',
        order: '',
        active: false
    })

    useEffect(() => {

        fetchMissionVision();

    }, [refreshValue]);




    const disablex = useSelector((state: RootState) => state.addTask.taskFormStatus);
    const updateMode = useSelector((state: RootState) => state.addTask.taskFooterUpdate);
    const dispatch = useDispatch();


    useEffect(() => {
        if (!disablex) {



            //  this destroys the update mode state
            dispatch(task_footer_update(false));
            setShowCancel(true); setDisableForms(false);
            clearFields();


        } else {
            setShowCancel(false);
            setDisableForms(true);
            setUpdatingStatus(false)

            // dispatch(update_route_state(false))
            dispatch(task_footer_update(false));

        }
        if (updateMode) {
            console.log('updatedsds')

            setUpdatingStatus(true)
            setDisableForms(false);
            setShowCancel(true);
            // setUpdatingStatus(true);
            // setShowCancel(true);
            dispatch(task_footer_update(true));
        }

    }, [disablex, updateMode])






    return (

        <div style={{ width: '100%', height: '100%', borderColor:borderTheme }} className="  border-[1px]   dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base  ">
            <ModalTemplate icon={icon} cancelText={modalData.cancelText}
                cancelHandler={() => { setOpenModal(false) }} disableCancel={showModalDisableButton} open={openModal}
                okHandler={() => { showModalDisableButton === true ? setOpenModal(false) : updatingStatus === true ? updateData() : postData(); }}
                message={modalData.message} okText={modalData.okText} title={modalData.title} />
            {/* <h4 className=" w-full  mb-2 bg-slate-100  ">Task Details</h4> */}
            <div style={{ maxHeight: '240px', minHeight: '240px', height: '240px', width: '100%' }} className=' items-start justify-start '   >

                <Form
                    className='w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 px-2 '
                    name="basic"
                    // labelCol={{ span: 6}}
                    // wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    size='small'
                    layout='horizontal'

                >



                    {/* <Theme_Details_Select /> */}
                    <Form.Item
                        label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{'Mission/Vision'}</p>}
                        className={''}

                        labelCol={{ span: 2 }}
                        wrapperCol={{ span: 6 }}

                    >
                        <div className="flex flex-row items-center mb-0">
                            <Select
                                onChange={(value: any) => { }}
                                className='w-full mr-0'
                                placeholder=""
                                allowClear
                                disabled={disableForms}
                                value={allStates.missionVision.name}
                                onSelect={(e) => {
                                    // const value = missionVisionData.filter((data:any ) => data['mvsName'] === e)
                                    setAllStates({ ...allStates, ['missionVision']: { name: JSON.parse(e).mvsName, id: JSON.parse(e).mvsIdpk } })
                                    // console.log(value);
                                }}
                            // disabled={disableForms}

                            >
                                {missionVisionData!.map((option: any, index: number) => {
                                    return (
                                        <option key={index} value={JSON.stringify(option)}>{option['mvsName']}</option>
                                    )
                                })}
                            </Select>

                            <span onClick={() => {
                                setAllStates({ ...allStates, ['missionVision']: { name: '', id: '' } })
                                // console.log(value);

                            }}
                                style={{ height: 22.5, marginBottom: 2, borderWidth: 1, width: 23.9,borderColor:borderTheme }}
                                className='ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded'>
                                <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src={refresh} alt="reset" />
                                {/* <img className='' src= {''} alt="" /> */}
                            </span>

                        </div>
                    </Form.Item>
                    {/* <Theme_Input /> */}
                    <div className=''>
                        <Form.Item
                            className=''
                            label={<p className='dark:bg-fill dark:text-darkModeSkin-base text-xs'>{'Strategic Themes'}</p>}
                            labelCol={{ span: 2 }}
                        >
                            <div className="flex flex-row justify-between ">
                                <Input disabled={disableForms} value={allStates.strategicTheme} onChange={(e: any) => { console.log(e.target.value); setAllStates({ ...allStates, ['strategicTheme']: e.target.value }) }} className=' W-11/12' />
                            </div>
                        </Form.Item>
                    </div>

                    <div className='mb-0.5'>
                        <Form.Item
                            className=''
                            label={<p className='dark:bg-fill dark:text-darkModeSkin-base text-xs'>{'Short Name'}</p>}
                            labelCol={{ span: 2 }}
                        >
                            <div className="flex flex-row justify-between">
                                <Input disabled={disableForms} value={allStates.shortName} onChange={(e: any) => { console.log(e.target.value); setAllStates({ ...allStates, ['shortName']: e.target.value }) }} className=' W-11/12' />

                            </div>
                        </Form.Item>
                    </div>
                    <div className=''>
                        <TextareaTemplate
                            height={50}
                            useCallbackFunc
                            label={"Commentary"}
                            labelCol={2}
                            defaultValue={allStates.commentary}
                            readonly={disableForms}
                            setCallbackValue={(e: any) => { setAllStates({ ...allStates, ['commentary']: e }) }}
                        />
                    </div>
                    {/* <Form.Item
                        labelCol={{ span: 2 }}

                        label={<p className='dark:bg-fill dark:text-darkModeSkin-base text-xs'>{'Commentary'}</p>}
                        rules={[{ required: false, }]}
                    >
                        <div className="flex flex-row ">
                            <TextArea
                                readOnly={disableForms}
                                value={allStates.commentary} onChange={(e: any) => { setAllStates({ ...allStates, ['commentary']: e.target.value }) }} style={{ resize: 'none', height: '40px' }} className='w-full ' />

                            <span className='h-full w-4 ml-1 flex items-center justify-center hover:cursor-pointer hover'>
                                {/* <img className='w w-9/12 h-9/12' src= {reset} alt="reset" /> */}
                    {/* </span> */}

                    {/* </div> */}
                    {/* </Form.Item> */}
                    <div className='-mt-0.5'>
                        <TextareaTemplate
                            height={65}
                            useCallbackFunc
                            label={"Remarks"}
                            labelCol={2}
                            defaultValue={allStates.remarks}
                            readonly={disableForms}
                            setCallbackValue={(e: any) => { setAllStates({ ...allStates, ['remarks']: e }) }}
                        />
                    </div>
                    {/* <Form.Item
                        labelCol={{ span: 2 }}

                        label={<p className='dark:bg-fill dark:text-darkModeSkin-base text-xs'>{'Remarks'}</p>}
                        rules={[{ required: false, }]}
                    >
                        <div className="flex flex-row ">
                            <TextArea
                                readOnly={disableForms}
                                value={allStates.remarks} onChange={(e: any) => { setAllStates({ ...allStates, ['remarks']: e.target.value }) }} style={{ resize: 'none', height: '75px' }} className='w-full ' />

                            <span className='h-full w-4 ml-1 flex items-center justify-center hover:cursor-pointer hover'>
                                {/* <img className='w w-9/12 h-9/12' src= {reset} alt="reset" /> */}
                    {/* </span> */}

                    {/* </div> */}
                    {/* </Form.Item> */}
                    <Form.Item
                        labelCol={{ span: 2 }}

                        label={<p className='dark:bg-fill dark:text-darkModeSkin-base text-xs'>{'Order'}</p>}
                        rules={[{ required: false, }]}
                    >
                        <div className="flex flex-row w-full ">
                            <div style={{ width: 'calc(100%)', }} className=' flex  justify-between  '>
                                {/* <li className='flex justify-start '><Cancel/></li> */}
                                <ul className='flex justify-evenly'>
                                    <li><Input type='number' className='w-24 ' disabled={disableForms} value={allStates.order} onChange={(e: any) => { setAllStates({ ...allStates, ['order']: e.target.value }) }} /></li>
                                    <li className=' flex h-6 items-center flex-row  ml-5'><p className='mr-2 text-xs'>Active : <Checkbox style={{borderColor:borderTheme}} className='border-[1px] rounded px-1' disabled={disableForms} checked={allStates.active} onChange={(e: any) => { setAllStates({ ...allStates, ['active']: e.target.checked }) }} ></Checkbox> </p>  </li>
                                </ul>
                                <ul className='flex justify-end '>
                                    {updatingStatus === true ? <UpdateButton handleUpdate={() => { setModalData(updateModalData); validateForms(); }} /> : <SaveButton handleSave={() => { setModalData(saveModalData); validateForms(); }} />}
                                    {showCancel === true ? <Cancel useCallbackFunc={true} cancel_button_toggler={() => {
                                        setShowCancel(false); setDisableForms(true); setUpdatingStatus(false)
                                        dispatch(task_form_disable(true));

                                        // dispatch(update_route_state(false))
                                        dispatch(task_footer_update(false));
                                    }} /> : <NewButton useCallbackFunc={true} new_button_toggler={() => {
                                        setShowCancel(true); setDisableForms(false);
                                        clearFields();
                                    }} />}
                                </ul>
                            </div>
                        </div>
                    </Form.Item>


                </Form>
            </div>



        </div>
    )
});
