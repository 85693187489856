// PPE

export const Staff_name_cols = [
    {id: 0,caption : "EmpName", dataField : 'empName', dataType : "string", fixed : true,width:120},
    {id: 1,caption : "Dept.ShortName", dataField : 'dptShtName', dataType : "string", fixed : true,width:120},
    {id: 2,caption : "Section", dataField : 'sxnShtName', dataType : "string", fixed : true,width:120},
    {id: 3,caption : "Loc.ShortName", dataField : 'locShtName', dataType : "string", fixed : true,width:120},
    {id: 4,caption : "Job ShortName", dataField : 'jbtShtName', dataType : "string", fixed : true,width:120},
    {id: 5,caption : "Id",dataField : 'empIDpk',dataType : "number", fixed: false, visible: false, width: 60}
]


export const Batch_no_cols = [
    
    {id: 1,caption : "BatchNo", dataField : 'gpsBatchNo', dataType : "string", fixed : true,width:60},
]

// FOR DISTRIBUTION MINI
export const mini_datagrid_cols = [
    {id: 0,caption : "REQ.", dataField : 'prqRequestNo', dataType : "number", fixed : true,width:60},
    {id: 1,caption : "Req.Window", dataField : 'rqwName', dataType : "string", fixed : true,width:60},
    {id: 2,caption : "PPE", dataField : 'ptpName', dataType : "number", fixed : true,width:60},
    {id: 3,caption : "Size", dataField : 'pszName', dataType : "number", fixed : true,width:60},
    {id: 4,caption : "Req.Date", dataField : 'prqCreationDate', dataType : "date",format:"EEE,dd MMM yyyy", fixed : true,width:100},
    {id: 5,caption : "Qty.Req", dataField : 'prqQuantityRequested', dataType : "number", fixed : true},
    {id: 6,caption : "Qty.Approved", dataField : 'prqQuantityApproved', dataType : "number",width:100},
    {id: 7,caption : "Qty.Distr", dataField : 'prqQuantityDistributed', dataType : "number",width:100},
    {id: 8,caption : "Aprvd Distr?", dataField : 'prqDistributed', dataType : "boolean",width:100},
    {id: 9,caption : "Qty Recom", dataField : 'prqQuantityRecommended', dataType : "string",width:100},
    {id: 10,caption : "Qty.Acknow", dataField : 'prqQuantityAcknowledged', dataType : "number",width:100},
    {id: 11,caption : "Remarks", dataField : 'prqRmks', dataType : "string",width:4000},
]

// FOR PPE DISTRIBUTION FOOTER

export const footer_datagrid_cols = [
    {id: 0,caption : "REQ.ID", dataField : 'requestId', dataType : "number", fixed : true,width:60},
    {id: 1,caption : "REQ. No", dataField : 'prqRequestNo', dataType : "string", fixed : true,width:90},
    {id: 2,caption : "SIGNED?", dataField : 'pddSigned', dataType : "boolean", fixed : true,width:80},
    {id: 3,caption : "STAFF No", dataField : 'requestStaffNo', dataType : "string", fixed : true,width:100},
    {id: 4,caption : "STAFF NAME", dataField : 'requestEmpName', dataType : "string", fixed : true,width:95},
    {id: 5,caption : "DISTR. DATE", dataField : 'pddDate', dataType : "date",format:"EEE,dd MMM yyyy",fixed : true,width:100},
    {id: 6,caption : "PPE TYPE", dataField : 'ptpName', dataType : "number",fixed:true,width:100},
    {id: 7,caption : "PPE SIZE", dataField : 'pszName', dataType : "string",fixed:true,width:100},
    {id: 8,caption : "QTY REQ.", dataField : 'prqQuantityRequested', dataType : "number",fixed:true,width:100},
    {id: 9,caption : "QTY APRVD", dataField : 'prqQuantityApproved', dataType : "number",fixed:true,width:100},
    {id: 10,caption : "QTY DISTR.", dataField : 'pddQuantityDistributed ', dataType : "number",fixed:true,width:100},
    {id: 11,caption : "QTY RECOM.", dataField : 'prqQuantityRecommended', dataType : "number",width:100},
    {id: 12,caption : "QTY ACKNOW.", dataField : 'prqQuantityAcknowledged', dataType : "number",width:100},
    {id: 13,caption : "REQ. WINDOW", dataField : 'rqwName', dataType : "string",width:100},
    {id: 14,caption : "ASSIGNED DEPT", dataField : 'requestDeptName', dataType : "string",width:100},
    {id: 15,caption : "ASSIGNED SECTION", dataField : 'requestSxnName', dataType : "string",width:100},
    {id: 16,caption : "ASSIGNED LOC.", dataField : ' requestLocShtName', dataType : "string",width:100},
    {id: 17,caption : "JOB TITLE", dataField : 'requestJbtShtName', dataType : "string",width:100},
    {id: 18,caption : "EMP DEPT", dataField : 'empDeptShtName', dataType : "string",width:100},
    {id: 19,caption : "EMP SECTION", dataFielLOCd : 'empSxnhtName', dataType : "string",width:100},
    {id: 20,caption : "EMP LOC", dataField : 'empLocShtName', dataType : "string",width:100},
    {id: 21,caption : "ACTIVE", dataField : 'pddActive', dataType : "bool"},
    {id: 22,caption : "REMARKS", dataField : 'pddRmks', dataType : "string",width:4000},
    {id:23,caption:"ID",dataField:"pddIdpk",dataType:"number",width:60,visible:false}

]





{/* <Column  dataField="LOCKED?"  fixed={true} width={60} cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataType='boolean'></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="DATE" fixed={true} dataType='date' width={100} format={"dd MMM yyyy"} ></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="%COMPLETE" fixed={true} cellRender={progressBar}></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="TASK UPDATE"  dataType='string' ></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="UPDATED BY" dataType='string' ></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="JOB TITLE" dataType='string'></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="UPDATER" dataType='string' width={100}></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="CREATED ON " dataType='string'></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="COMPLETION DATE" dataType='string'></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="REMARKS" dataType='string' width={4000} ></Column> */}