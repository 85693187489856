import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetDataFunc } from "../../../functions/get";

interface apiStates {
    regNodata: any | null;
    chassisdata: any | null;
    vehClassdata: any | null;
    engineTypedata: any | null;
    vehMakedata: any | null;
    powerTraindata: any | null;
    fuelTypedata: any | null;
    transmissionTypedata: any | null;
    manufacturerdata: any | null;
    countrydata: any | null;
    engineMakedata: any | null;
    supplierdata: any | null;
    speedUnitdata: any | null;
    currencydata: any | null;
    statusdata: any | null;
    imagesdata: any | null;
    vehicleModeldata: any | null;
    isLoading: boolean;
    isPending: boolean;
    isError: boolean;
}

var initialState: apiStates = {
    regNodata: [],
    chassisdata: [],
    vehClassdata: [],
    engineTypedata: [],
    vehMakedata: [],
    powerTraindata: [],
    fuelTypedata: [],
    transmissionTypedata: [],
    manufacturerdata: [],
    countrydata: [],
    engineMakedata: [],
    supplierdata: [],
    speedUnitdata: [],
    currencydata: [],
    statusdata: [],
    imagesdata: [],
    vehicleModeldata: [],
    isLoading: false,
    isPending: false,
    isError: false,
};

// Thunks for api Calls
export const fetchRegNo = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "regNo/fetch",
    async (search: string) => {
        const res = await GetDataFunc(`AssetTamVehicleDetail/GetRegistrationAssetTamVehicleDetails?pageNumber=1&pageSize=20&search=${search}`);
        return res.data;
    }
);

export const fetchVehicleClass = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "vehicleClass/fetch",
    async (search: string) => {
        const res = await GetDataFunc(`AssetTamVehicleDetail/GetPopVehicleClassAssetTamVehicleDetails?vhcActive=true&pageNumber=1&pageSize=20&search=${search}`);
        return res.data;
    }
);

export const fetchEngineType = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "engineType/fetch",
    async (search: string) => {
        const res = await GetDataFunc(`AssetTamVehicleDetail/GetPopCmbEngineTypesyAssetTamVehicleDetails?egtActive=true&pageNumber=1&pageSize=20&search=${search}`);
        return res.data;
    }
);

export const fetchPowerTrain = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "powerTrain/fetch",
    async (search: string) => {
        const res = await GetDataFunc(`AssetTamVehicleDetail/GetPopPowerTrainAssetTamVehicleDetails?pwtActive=true&pageNumber=1&pageSize=20&search=${search}`);
        return res.data;
    }
);

export const fetchFuelType = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "fuelType/fetch",
    async (search: string) => {
        const res = await GetDataFunc(`AssetTamVehicleDetail/GetCmbFuelTypesAssetTamVehicleDetails?ftpActive=true&pageNumber=1&pageSize=20&search=${search}`);
        return res.data;
    }
);

export const fetchTransmissionType = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "transmissionType/fetch",
    async (search: string) => {
        const res = await GetDataFunc(`AssetTamVehicleDetail/GetTransmissionTypeAssetTamVehicleDetails?trtActive=true&pageNumber=1&pageSize=20&search=${search}`);
        return res.data;
    }
);

export const fetchManufacturer = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "manufacturer/fetch",
    async (search: string) => {
        const res = await GetDataFunc(`AssetTamVehicleDetail/GetPopManufactureAllActiveAssetTamVehicleDetails?pageNumber=1&pageSize=20&search=${search}`);
        return res.data;
    }
);

export const fetchCountry = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "country/fetch",
    async (search: string) => {
        const res = await GetDataFunc(`AssetTamVehicleDetail/GetPopCmbCountryAssetTamVehicleDetails?ctyActive=true&pageNumber=1&pageSize=20&search=${search}`);
        return res.data;
    }
);

export const fetchEngineMake = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "engineMake/fetch",
    async (search: string) => {
        const res = await GetDataFunc(`AssetTamVehicleDetail/GetCmbEngineMakeAssetTamVehicleDetails?egmActive=true&pageNumber=1&pageSize=20&search=${search}`);
        return res.data;
    }
);

export const fetchSupplier = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "supplier/fetch",
    async (search: string) => {
        const res = await GetDataFunc(`AssetTamVehicleDetail/GetPopCmbSuppliersAssetTamVehicleDetails?splActive=true&pageNumber=1&pageSize=20&search=${search}`);
        return res.data;
    }
);

export const fetchSpeedUnit = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "speedUnit/fetch",
    async (search: string) => {
        const res = await GetDataFunc(`AssetTamVehicleDetail/GetPopSpeedoUnitsAssetTamVehicleDetails?spuActive=true&pageNumber=1&pageSize=20&search=${search}`);
        return res.data;
    }
);

export const fetchCurrency = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "currency/fetch",
    async (search: string) => {
        const res = await GetDataFunc(`AssetTamVehicleDetail/GetPopLuePriceCurrencyAssetTamVehicleDetails?curActive=true&pageNumber=1&pageSize=20&search=${search}`);
        return res.data;
    }
);

export const fetchChassisNo = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "chassisNo/fetch",
    async (search: string) => {
        const res = await GetDataFunc(`AssetTamVehicleDetail/GetPopChassisNoAssetTamVehicleDetails?search=${search}`);
        return res.data;
    }
);

export const fetchStatus = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "status/fetch",
    async (search: string) => {
        const res = await GetDataFunc(`AssetTamVehicleDetail/GetPopCmbVehicleStatusAssetTamVehicleDetails?vstActive=true&pageNumber=1&pageSize=20&search=${search}`);
        return res.data;
    }
);

export const fetchImages = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "images/fetch",
    async (vdtIDpk: string) => {
        const res = await GetDataFunc(`AssetTamVehicleDetail/GetImagesAssetTamVehicleDetails?vdtIDpk=${vdtIDpk}&vimActive=true`);
        return res.data;
    }
);

export const fetchVehicleMake = createAsyncThunk<apiStates, { vhcname: string, search: string }, { rejectValue: string }>(
    "vehicleMake/fetch",
    async ({ vhcname, search }) => {
        const res = await GetDataFunc(`AssetTamVehicleDetail/GetPopCmbVehicleMakeAssetTamVehicleDetails?vhcname=${vhcname}&vmkActive=true&pageNumber=1&pageSize=20&search=${search}`);
        return res.data;
    }
);

export const fetchVehicleModel = createAsyncThunk<apiStates, { vhcmake: string, search: string }, { rejectValue: string }>(
    "vehicleModel/fetch",
    async ({ vhcmake, search }) => {
        const res = await GetDataFunc(`AssetTamVehicleDetail/GetPopCmbVehicleModelAssetTamVehicleDetails?vhcmake=${vhcmake}&mdlActive=true&pageNumber=1&pageSize=20&search=${search}`);
        return res.data;
    }
);

const apiCallSlice = createSlice({
    name: 'apiCalls',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // fetchRegNo
            .addCase(fetchRegNo.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchRegNo.fulfilled, (state, action) => {
                state.isLoading = false;
                state.regNodata = action.payload;
            })
            .addCase(fetchRegNo.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            // fetchVehicleClass
            .addCase(fetchVehicleClass.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchVehicleClass.fulfilled, (state, action) => {
                state.isLoading = false;
                state.vehClassdata = action.payload;
            })
            .addCase(fetchVehicleClass.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            // fetchEngineType
            .addCase(fetchEngineType.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchEngineType.fulfilled, (state, action) => {
                state.isLoading = false;
                state.engineTypedata = action.payload;
            })
            .addCase(fetchEngineType.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            // fetchPowerTrain
            .addCase(fetchPowerTrain.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchPowerTrain.fulfilled, (state, action) => {
                state.isLoading = false;
                state.powerTraindata = action.payload;
            })
            .addCase(fetchPowerTrain.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            // fetchFuelType
            .addCase(fetchFuelType.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchFuelType.fulfilled, (state, action) => {
                state.isLoading = false;
                state.fuelTypedata = action.payload;
            })
            .addCase(fetchFuelType.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            // fetchTransmissionType
            .addCase(fetchTransmissionType.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchTransmissionType.fulfilled, (state, action) => {
                state.isLoading = false;
                state.transmissionTypedata = action.payload;
            })
            .addCase(fetchTransmissionType.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            // fetchManufacturer
            .addCase(fetchManufacturer.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchManufacturer.fulfilled, (state, action) => {
                state.isLoading = false;
                state.manufacturerdata = action.payload;
            })
            .addCase(fetchManufacturer.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            // fetchCountry
            .addCase(fetchCountry.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchCountry.fulfilled, (state, action) => {
                state.isLoading = false;
                state.countrydata = action.payload;
            })
            .addCase(fetchCountry.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            // fetchEngineMake
            .addCase(fetchEngineMake.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchEngineMake.fulfilled, (state, action) => {
                state.isLoading = false;
                state.engineMakedata = action.payload;
            })
            .addCase(fetchEngineMake.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            // fetchSupplier
            .addCase(fetchSupplier.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchSupplier.fulfilled, (state, action) => {
                state.isLoading = false;
                state.supplierdata = action.payload;
            })
            .addCase(fetchSupplier.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            // fetchSpeedUnit
            .addCase(fetchSpeedUnit.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchSpeedUnit.fulfilled, (state, action) => {
                state.isLoading = false;
                state.speedUnitdata = action.payload;
            })
            .addCase(fetchSpeedUnit.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            // fetchCurrency
            .addCase(fetchCurrency.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchCurrency.fulfilled, (state, action) => {
                state.isLoading = false;
                state.currencydata = action.payload;
            })
            .addCase(fetchCurrency.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            // fetchChassisNo
            .addCase(fetchChassisNo.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchChassisNo.fulfilled, (state, action) => {
                state.isLoading = false;
                state.chassisdata = action.payload;
            })
            .addCase(fetchChassisNo.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            // fetchStatus
            .addCase(fetchStatus.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchStatus.fulfilled, (state, action) => {
                state.isLoading = false;
                state.statusdata = action.payload;
            })
            .addCase(fetchStatus.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            // fetchImages
            .addCase(fetchImages.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchImages.fulfilled, (state, action) => {
                state.isLoading = false;
                state.imagesdata = action.payload;
            })
            .addCase(fetchImages.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            // fetchVehicleMake
            .addCase(fetchVehicleMake.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchVehicleMake.fulfilled, (state, action) => {
                state.isLoading = false;
                state.vehMakedata = action.payload;
            })
            .addCase(fetchVehicleMake.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            // fetchVehicleModel
            .addCase(fetchVehicleModel.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchVehicleModel.fulfilled, (state, action) => {
                state.isLoading = false;
                state.vehicleModeldata = action.payload;
            })
            .addCase(fetchVehicleModel.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            });
    },
});

export default apiCallSlice.reducer;
