

export const resetPass_cols = [
    {id: 0, caption:"STAFF No", dataField:"empStaffNo", dataType:"", fixed:true, visible:true, width:120,},
    {id: 1, caption:"STAFF NAME", dataField:"empName", dataType:"string", fixed:true, visible:true, width:120,},
    {id: 2, caption:"USERNAME", dataField:"usaUsername", dataType:"string", fixed:true, visible:true, width:120,},
    {id: 3, caption:"DEPT", dataField:"dptShtName", dataType:"string", fixed:true, visible:true, width:120,},
    {id: 4, caption:"SECTION", dataField:"sxnShtName", dataType:"string", fixed:"", visible:true, width:120,},
    {id: 5, caption:"JOB TITLE", dataField:"jbtName", dataType:"string", fixed:true, visible:true, width:120,},
    {id: 6, caption:"MOB No", dataField:"empMobNox", dataType:"string", fixed:false, visible:true, width:120,},
    {id: 7, caption:"ONLINE?", dataField:"usaLoggedIn", dataType:"boolean", fixed:false, visible:true, width:120,},
    {id: 8, caption:"ACTIVE?", dataField:"usaActive", dataType:"boolean", fixed:false, visible:true, width:120,},
    {id: 9, caption:"SUPER USER?", dataField:"usaSuperUser", dataType:"boolean", fixed:false, visible:true, width:120,},
    {id: 10, caption:"ORG. ADMIN?", dataField:"", dataType:"", fixed:false, visible:true, width:120,},
    {id: 11, caption:"RESET PSWD?", dataField:"usaResetOthersPasswords", dataType:"boolean", fixed:false, visible:true, width:120,},
    {id: 12, caption:"REMARKS", dataField:"usaRmks", dataType:"", fixed:false, visible:true, width:40000,},
]