/* eslint-disable react/jsx-pascal-case */
import { Form, Space } from "antd";
import reset from "../../../../../../assets/reset.png";
import { useCallback, useEffect, useRef, useState } from "react";
import Datagrid_template from "../../../../../../templates/Datagrid";
import { DateTemplate } from "../../../../../../templates/date";
import { InputsTemplate } from "../../../../../../templates/input";
import { SaveButton } from "../../../../../accessories/buttons";
import right from "../../../../../../assets/right2.png";
import { membersColumns, staffListColumns } from "../data/permitsHolderData";
import useFetch from "../../../../../../hooks/useFetch";
import {
  modalPropTypes,
  searchTextTypes,
  selectObjectTypes,
} from "../../../../../human-resource/setups/data/types/selectsTypes";
import dayjs, { Dayjs } from "dayjs";
import { ModalTemplate } from "../../../../../../templates/modal";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";
import { useAccountInfo } from "../../../../../../hooks/useAccountInfo";
import { useFormPriviledge } from "../../../../../../hooks/useFormPriviledge";
import { useCrudFunc } from "../../../../../../functions/crud";

interface Prop {
  // updateMode : boolean
  refresh: () => void;
  staffDatagrid: (fetchDone: boolean) => void;
  busyLoader: (text: string) => void;
}
interface stateTypes {
  startDate: string;
  endDate: string;
  members: any[];
  staffList: [];
  staffSearch: searchTextTypes;
  selectedRecord?: any;
  modalState: modalPropTypes;
  confirmState: modalPropTypes;
  successModal: modalPropTypes;
}

export const postError = {
  title: "Save Failed",
  message:
    "Error saving record. Please retry or contact your Systems Administrator",
};

export const PermitsHolderForm = ({
  refresh: refreshGrid,
  staffDatagrid,
  busyLoader,
}: Prop) => {
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const [PostDataFunc, UpdateDataFunc] = useCrudFunc();
  const [states, setState] = useState<stateTypes>({
    startDate: dayjs(new Date()).format(),
    endDate: dayjs(new Date()).format(),
    selectedRecord: {},
    staffList: [],
    staffSearch: { temp: "", text: "" },
    members: [],
    modalState: {
      state: false,
      title: "",
      message: "",
    },
    successModal: {
      state: false,
      title: "",
      message: "",
    },
    confirmState: {
      state: false,
      title: "",
      message: "",
    },
  });

  const { staffList, startDate, endDate, members } = states;
  const updateState = (
    stateName: string,
    value:
      | string
      | boolean
      | number
      | modalPropTypes
      | selectObjectTypes
      | Dayjs
      | Object
      | searchTextTypes
  ) => {
    setState((prevstate) => ({ ...prevstate, [stateName]: value }));
  };
  const [Save, setSave] = useState<string>("");

  const [searchText, setSearchText] = useState<string>("");

  const [userId, userInfo] = useAccountInfo();
  const [privType, setPrivType] = useState<string>("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);
  let selectedStaffs = useRef<any[]>([]);
  const disableModal = () => {
    updateState("confirmState", {
      state: false,
      title: "",
      message: "",
      event: () => {},
    });
  };

  //add assignments data
  const appendAssignments = useCallback((data: any) => {
    const temp: any = data;
    for (let j = 0; j < temp?.length; j++) {
      temp[j] = {
        ...temp[j],
        dpaDriver: false,
        dpaPermitHolder: false,
      };
    }
    return temp;
  }, []);

  //add dates data
  const appendDates = useCallback((data: any) => {
    const temp: any = data;
    for (let j = 0; j < temp.length; j++) {
      temp[j] = {
        ...temp[j],
        dpaStartDate: startDate,
        dpaEndDate: endDate,
        dpaRmks: "",
      };
    }
    return temp;
  }, []);

  const saveAccessPriv = () => {
    setPrivType("Save");

    if (savePriv === true) {
      // not implemented
      updateState("confirmState", {
        state: true,
        title: "Save Record!",
        message: "Are you sure you want to save this record",
      });
      setSave("save");
    } else {
      updateState("modalState", {
        state: true,
        title: "Access Denied!",
        message: "No Privilege Access ",
      });
    }
  };

  // add a staff to members list
  const addToMembers = () => {
    //validations
    if (selectedStaffs.current.length < 1) {
      updateState("modalState", {
        state: true,
        title: "Select Record(s)",
        message: "Please select at least one staff",
      });
      return;
    } else if (!startDate || !endDate) {
      updateState("modalState", {
        state: true,
        title: "Enter Date",
        message: "Please enter start and end dates",
      });
      return;
    } else if (
      dayjs(startDate).format("DD/MM/YYYY") <
      dayjs(new Date()).format("DD/MM/YYYY")
    ) {
      updateState("modalState", {
        state: true,
        title: "Invalid Start Date",
        message: "Start date must not be before today",
      });
      return;
    } else if (dayjs(endDate) <= dayjs(startDate)) {
      updateState("modalState", {
        state: true,
        title: "Invalid Date",
        message: "End date must be greater than start date",
      });
      return;
    }

    let temp: any[] = [];
    //check if already exists in list and add to members
    for (let staff of selectedStaffs.current) {
      const exists = members.find((memb) => memb.empIDpk === staff.empIDpk);

      if (!exists) {
        temp = appendDates([...temp, staff]);
      }
    }
    updateState("members", [...members, ...temp]);
  };

  // filter the second grid using Staff Name

  const selectedChange = useCallback((data: any) => {
    selectedStaffs.current = data;
  }, []);

  const [refresh, setRefresh] = useState<boolean>(false);

  const [empsData, empsErr, empsLoading] = useFetch(
    `DriversPermitHolders/GetAllEmpsForDriversPermitHolders?txtEmpSearch=${searchText}`
  );
  useEffect(() => {
    if (empsData) {
      updateState("staffList", appendAssignments(empsData));
    }
  }, [empsData, empsErr, refresh]);

  const postData = async () => {
    // busyLoader('saving record(s)...');
    disableModal();
    try {
      for (let mem of members) {
        await PostDataFunc (
          "DriversPermitHolders/CreateAsmTmsDriversPermitholdersAssignment",
          {
            dpaEmpIdfk: mem.empIDpk,
            dpaStartDate: mem.dpaStartDate,
            // dpaEndDate: mem.dpaEndDate,
            dpaEndDate: endDate,
            dpaPermitHolder: mem.dpaPermitHolder,
            dpaDriver: mem.dpaDriver,
            dpaRmks: mem.dpaRmks,
            dpaCreatedBy: userId,
          },
          `
          Created the permitHolderForm with  dpaPermitHolder:${mem.dpaPermitHolder}, dpaDriver:${mem.dpaDriver}, dpaStartDate:${mem.dpaStartDate} Date Created:${dayjs().format()}. created By:${userId}
          `
        );
      }

      refreshGrid && refreshGrid();

      updateState("successModal", {
        state: true,
        title: "Record Saved!",
        message: "Data entry saved successfully",
      });
    } catch (err: any) {
      updateState("modalState", {
        state: true,
        title: err.response.data.message ?? "Unexpected Error!",
        message:
          err.response.data.errors[0] ??
          "Error saving members. Please retry or contact system administrator",
      });
    }
  };

  const implementRowColouring = useCallback((row: any) => {
    row.rowElement.style.backgroundColor =
      "rgb(134 239 172 / var(--tw-bg-opacity))";
  }, []);

  return (
    <>
      <ModalTemplate
        disableCancel={true}
        title={states.modalState.title}
        okText={""}
        message={states.modalState.message}
        open={states.modalState.state}
        okHandler={() => {
          updateState("modalState", { state: false });
        }}
        cancelHandler={() => {
          updateState("modalState", { state: false });
        }}
      />

      <ModalTemplate
        icon="success"
        disableCancel={true}
        title={states.successModal.title}
        okText={""}
        message={states.successModal.message}
        open={states.successModal.state}
        okHandler={() => {
          updateState("successModal", { state: false });
        }}
        cancelHandler={() => {
          updateState("successModal", { state: false });
        }}
      />

      <ModalTemplate
        icon="question"
        title={states.confirmState.title}
        okText={"Yes, Save"}
        message={states.confirmState.message}
        open={states.confirmState.state}
        okHandler={() => {
          if (Save === "save") {
            postData();
            updateState("confirmState", { state: false });
          } else {
            return;
          }
        }}
        cancelHandler={() => {
          updateState("confirmState", { state: false });
        }}
      />

      <div className="px-2">
        {/* FORM HEADER */}
        <p
          id={"form_header"}
          style={{ borderColor: borderTheme }}
          className="py-1 px-2 w-full border-[1px]  rounded font-semibold bg-slate-100"
        >
          <span className="font-medium">
            {"Drivers & Permit Holders Assignment Details"}
          </span>
        </p>
        <Form
          className="w-full rounded flex justify-between mt-1 "
          name="basic"
          wrapperCol={{ span: 25 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          size="small"
        >
          <section
            style={{ width: "calc(40% - 40px)", borderColor: borderTheme }}
            className="w-full border-[1px] rounded border-slate-100 rounded-b  bg"
          >
            <p
              id={"form_header2"}
              style={{ borderColor: borderTheme }}
              className="  rounded py-1 px-2 w-full border-[1px] border-t-0 border-l-0 border-r-0 font-medium bg-slate-100"
            >
              {"Staff Lists"}
            </p>
            <div className="px-1 my-0.5">
              {/* SELECTS */}
              <Form.Item
                className=""
                label={
                  <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs ">
                    {"Date"}
                  </p>
                }
                name="Staff No"
                rules={[{ required: false }]}
              >
                <Space className=" w-full flex justify-between">
                  <span className="flex flex-row items-center ">
                    <DateTemplate
                      selectedDate={dayjs(startDate)}
                      changeDate={(date: Dayjs) =>
                        updateState("startDate", dayjs(date).format())
                      }
                      disabled={false}
                      disabledDate={false}
                      style={{ width: "140px", marginRight: "3px" }}
                    />
                    <DateTemplate
                      selectedDate={dayjs(endDate)}
                      changeDate={(date: Dayjs) =>
                        updateState("endDate", dayjs(date).format())
                      }
                      disabled={false}
                      disabledDate={false}
                      style={{ width: "140px" }}
                    />
                  </span>

                  <span className="flex flex-row items-center">
                    <InputsTemplate
                      span
                      useCallbackFunc
                      defaultValue={searchText}
                      orderOnchange={(e: any) => {
                        setSearchText(e);
                      }}
                      placeholder={"Search"}
                      disabledStatus={false}
                    />
                    <span
                      onClick={() => {
                        setSearchText("");
                        setRefresh(!refresh);
                      }}
                      style={{
                        height: 22.5,
                        marginBottom: 2,
                        borderWidth: 1,
                        width: 23.9,
                      }}
                      className="ml-1 mt-0.5 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                    >
                      <img
                        className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                        src={reset}
                        alt="reset"
                      />
                    </span>
                  </span>
                </Space>
              </Form.Item>

              {/* DATAGRID [] */}
              <Form.Item
                className=""
                name="Staff No"
                rules={[{ required: false }]}
              >
                <div className=" mb-1">
                  <Datagrid_template
                    selectionMode="multiple"
                    deselectFirstRow
                    disableGroupPanel
                    allowColumnEditing
                    disableSearch
                    selectedItemsChange={selectedChange}
                    disablePaging
                    data={staffList}
                    gridheight={298}
                    columns={staffListColumns}
                  />
                </div>
              </Form.Item>
            </div>
          </section>

          <section
            onClick={() => addToMembers()}
            className=" border-2 flex hover:cursor-pointer hover:bg-slate-100 rounded items-center justify-center"
            style={{ width: "calc(70px)", borderColor: borderTheme }}
          >
            <img className="w-4/12 h-4/12" src={right} alt="" />
          </section>

          <section
            className=" mt-1 border-[1px] rounded border-slate-100 rounded-b"
            style={{ width: "calc(60% - 40px)", borderColor: borderTheme }}
          >
            <div className=" px-1">
              {/* DATAGRID [] */}
              <Form.Item
                className=""
                name="Staff No"
                rules={[{ required: false }]}
              >
                <div className="">
                  <Datagrid_template
                    selectionMode="single"
                    onRowPrepared={implementRowColouring}
                    rowAlternationEnabled={false}
                    disableGroupPanel
                    allowColumnEditing
                    disablePaging
                    data={members}
                    gridheight={295}
                    columns={membersColumns}
                    onRowClick={(e) => {
                      updateState("selectedRecord", e);
                    }}
                  />
                </div>

                <div className=" mt-1 mb-1 flex justify-end">
                  <SaveButton
                    disableButton={members.length < 1 ? true : false}
                    handleSave={() => {
                      saveAccessPriv();
                    }}
                  />
                </div>
              </Form.Item>
            </div>
          </section>
        </Form>
      </div>
    </>
  );
};

export default PermitsHolderForm;
