

import FilterTodos from '../../../../Task&Todos/FooterGrid/FilterTodos';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';

// import type { SelectProps } from 'antd';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { PPE_distribution_toolbar } from '../../../../accessories/custom-toolbars/ppeDistributionToolbar';


import refresh from '../../../../../assets/refresh.png';
import edit from '../../../../../assets/edit.png';
import close from '../../../../../assets/close.png';
import { BiggerPicture_Form_Details } from './widgets/BiggerPicture_Form';
import { Mini_datagrid_template } from './data/template/mini_datagrid';
import { footer_datagrid_cols, perspective_footer_datagrid_cols } from './data/datagrid_cols';
import { Footer_datagrid_template } from './data/template/footer_datagrid';
import { setGeneralValue, settask_footer_update } from '../../../../../features/generalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { navbar_height,  toolbar_height } from '../../../../accessories/component_infos';
import { RootState } from '../../../../../app/store';

interface props {
    state_change: boolean;
}



export const BiggerPicture = forwardRef(({state_change}:props, ref)=>{
    const news_flash_height = useSelector((state:RootState)=>state.general.newsflashHeight);
    // const width = window.innerWidth/2
    // const fixedWidth = 'calc(50% - 8px)';
   // const width = window.innerWidth/2
    // const fixedWidth = 'calc(50% - 8px)';
    const [selectedRecord, setSelectedRecord] = useState<any>()
    const update_state_change = useSelector((state:RootState)=>state.general.MissionUpdateForm)

    // on page resize height responsiveness
    const dispatch = useDispatch();
    const [trigger,setTrigger] = useState<boolean>(false);
    useImperativeHandle(ref, () => ({
        test: () => {
            setTrigger(!trigger);
            toggle_staff_form();
        }
    }));

    //navigation
    const setupPageRoute = window.location.pathname; //get current route of page
    const datagridOnlySearchParam = 'table-only'  //search param in datagrid mode
    const formModeSearchParam = 'form-and-table' //search param in form mode mode
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    //miscellaneous heights
    const miscHeight = 7;
    
    // All heights out
    const heights_out = navbar_height + news_flash_height + toolbar_height;

    //varying form height
    const [form_height,set_form_height] = useState<number>(362);

    // Page resize height responsiveness
    useEffect(()=>{
        settableHeight(window.innerHeight - (heights_out + miscHeight -4));
        toggle_staff_form();
    },[state_change])

    


    // handle interface switch
    const toggle_staff_form = ():void =>{
        const view = searchParams.get('view');

        (view === null || view === formModeSearchParam) ? 
        navigate({
            pathname : setupPageRoute,
            search : createSearchParams({
                view : datagridOnlySearchParam,
            }).toString(),
        })
        :
        navigate({
            pathname : setupPageRoute,
            search : createSearchParams({
                view : formModeSearchParam,
            }).toString(),
        });
    }

    //Table Height
    const pageHeight = window.innerHeight - (navbar_height + miscHeight) ; //height without navbar
    const [tableHeight,settableHeight] = useState(window.innerHeight); //height without navbar and toolbar



    /******************************************** */
            // Footer Datagrid
    /******************************************* */
          
        const selectRecord:any = useCallback<any>((e:any) => {
        e.component.byKey(e.currentSelectedRowKeys[0]).done((e:any) => {
            setSelectedRecord(e);

             //enable forms
            dispatch(setGeneralValue({expr:'formDisabled', value:true}));
            //enable Update Mode
            dispatch(settask_footer_update(false));
        });
    }, []);

    const allowUpdate = (e:any)=>{
        setSelectedRecord(e); // this contains the selected data
        //enable forms
        dispatch(setGeneralValue({expr:'formDisabled', value:false}));
        //enable Update Mode
        dispatch(settask_footer_update(true));
        console.log(e)
    }
    /***********************************    ******* */


    return (
        <div  className='h-full dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base mt-1 '>
            {/* toggles between the taskForm and the footer grid table vertically */}
                    
               
                
            {(searchParams.get('view') !== datagridOnlySearchParam) &&     <div className=''>
                    
                    <div  className="px-2 flex justify-between gap-x-4 border-b-4 mb-1 pb-1">
                        
                        {/* Internal toggler : expands the right taskUpdate horizontally */}
                        
                            <div style={{width:'100%',height:'320px'}} >
                                <BiggerPicture_Form_Details />
                            </div>
                            
                    
                            {/* Double tap(click) feature to toggle to and fro of the right taskUpdate section */}
                            <div style={{width:'calc(100% - 920px)'}} className="h-full w-full  rounded-md items-center dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base " >
                                <div className='flex justify-end items-center my-1'>
                                    <ul className='flex justify-evenly items-center'>
                                        <li style={{borderWidth : '1px'}}  className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center">
                                            <span className="flex"> <img className="w-4 dark:bg-slate-900 pr-1 dark:text-gray-100 duration-100" src={refresh} /> </span> 
                                        </li>

                                        <li style={{borderWidth : '1px'}}  className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center">
                                            <span className="flex"> <img className="w-4 dark:bg-slate-900 pr-1 dark:text-gray-100 duration-100" src={edit} /> </span> 
                                        </li>

                                        <li style={{borderWidth : '1px'}}  className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center">
                                            <span className="flex"> <img className="w-4 dark:bg-slate-900 pr-1 dark:text-gray-100 duration-100" src={close} /> </span> 
                                        </li>
                                    </ul>
                                </div> 

                                <div className='border-2'>
                                    <Mini_datagrid_template columns={perspective_footer_datagrid_cols} gridheight={285} />
                                </div>
                            </div>
        
                            
                        </div>
                </div>}
        

            {/* Footer gridtable  */}
            <div style={{height:''}} className='px-2 dark:bg-slate-900 dark:text-darkModeSkin-base'>
            <Footer_datagrid_template 
                gridheight={searchParams.get('view') === formModeSearchParam || searchParams.get('view') === (null) ? 
                window.innerHeight - (form_height + heights_out + miscHeight) - 50
                : 
                tableHeight-50} 
                columns={footer_datagrid_cols} 
                data={undefined} 
                selectRecord={selectRecord}
                allowUpdate={allowUpdate}
            
            />
            </div>

        </div>

    )
})





// const reload = ()=>{
//     return(
//         <img className='w-2/6' src={save} />
//     )
// }