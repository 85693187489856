import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetDataFunc } from "../../functions/get";


interface apiStates {
    maintTypeData: any | null | undefined;
    arrivalConData: any | null | undefined;
    isLoading: boolean;
    isPending: boolean;
    isError: boolean;
}
var initialState: apiStates = {
    maintTypeData: [],
    arrivalConData: [],
    isLoading: false,
    isPending: false,
    isError: false,
}

//fetch maint Type
export const fetchPMmaintType= createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "fetchPMmaintType/fetch",
    async (search: string) => {
        const res = await GetDataFunc(`PmActivitiesCaptures/GetAllAsmTmsMaintenanceTypes?pagenumber=1&pagesize=20&search=${search}`);
        return res.data;
    }
);
//fetch fetch ArrivalCon
export const fetchArrivalCon = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "fetchArrivalCon/fetch",
    async (search: string) => {
        const res = await GetDataFunc(`PmActivitiesCaptures/GetAllAsmTmsPmActivityArrivalCondition?pagenumber=1&pagesize=20&search=${search}`);
        return res.data;
    }
);

const maintCap = createSlice({
    name: 'maintCapApi',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // fetchDate
            .addCase(fetchPMmaintType.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchPMmaintType.fulfilled, (state, action) => {
                state.isLoading = false;
                state.maintTypeData = action.payload;
            })
            .addCase(fetchPMmaintType.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            // fetchDept
            .addCase(fetchArrivalCon.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchArrivalCon.fulfilled, (state, action) => {
                state.isLoading = false;
                state.arrivalConData = action.payload;
            })
            .addCase(fetchArrivalCon.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            
    }})
    export default maintCap.reducer;