
import 'devextreme/dist/css/dx.light.css';
import { Tooltip } from "devextreme-react";
import send from '../../../../../assets/send.png';
import folder from '../../../../../assets/open-folder.png';
import close from '../../../../../assets/close.png';

import { EntryCustomToolbar } from "../../../../accessories/custom-toolbars/entryCustomtoolbar";
import { Checkbox } from 'antd';
import { useDispatch } from 'react-redux';
import { setGeneralValue } from '../../../../../features/generalSlice';
import { useState } from 'react';
import { FormModal } from '../../../../accessories/formModal';
import  Datagrid_template from '../../../../../templates/Datagrid';
import { staff_bene_unsub_TH } from '../data/staff-dependant-data';
import { SendSMSAlertDetails } from '../../../../Assets-Manager/Transport/Entries/Vehicles/Vehicle-Requests/widgets/sendSMSAlertDetails';



export const StaffDependantToolbar = ()=>{

    const handleChange = (value: string) => {
        console.log(`selected ${value}`);
      };
      const dispatch = useDispatch();
      const [unsubmitted, setUnsubmitted] = useState(false);
      const [sendSMSAlertModal, setSendSMSAlertModal] = useState<boolean>(false);

    return (
        <div className="">
              { <FormModal
                title={'Staff Beneficiaries - Unsubmitted'}
                child={  <Datagrid_template
                  data={[]}
                  gridheight={500}
                  disablePaging={true}
                  columns={staff_bene_unsub_TH}
                  onRowClick={(selectedData: any) => {
                    // updateState("selectedEquipmentDetailId", selectedData.edtIDpk);
                  }}
               />} openState={unsubmitted} />}
                 { <FormModal
                title={'Send SMS'}
                child={<SendSMSAlertDetails chooseFromDropDown={false} />} openState={sendSMSAlertModal} />}
        <EntryCustomToolbar
        searchCriteria={true}
        searchCriteriaData={
          [
            {id:0,value:'Beneficiary Name'},{id:1,value:'Department'}, {id:2,value:'Staff Name'},
            {id:3,value:'Staff Number'}, {id:4,value:'Invalid Tier 2'},{id:5,value:'Invalid Tier 3'},
            {id:6,value:'Invalid Tier 2 and/or Tier 3'}, {id:7,value:'Tier 2 Report'},{id:8,value:'Tier 3 Report'},
            {id:9,value:'Tier 2 & 3 Reports'},
        ]
        }
        handlePrint={() => {
          //do something
          console.log("Do something");
        }}
        handleRefresh={() => {}}
        checkOnChange={(value:boolean) => {
       
        }}
        searchTextOnchange={(text:string) => {
        
        }}
        handleFind={() => {
        }}
        withDates={true}
      
        handleSave={()=>{}}
        handleUpdate={()=>{}}
        handleDelete={()=>{}}
        toggler={()=>{}}
        additionalComponentsAfter={
          <>
            <li  
                      id="sendSMS" 
                      onClick={()=>{setSendSMSAlertModal(true)}} 
                      style={{borderWidth : '1px'}}  
                      className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center" >
                      <span className="flex justify-center items-center  dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img className="" src={send} /></span> 
                          <Tooltip
                              target="#sendSMS"
                              showEvent="dxhoverstart"
                              hideEvent="dxhoverend"
                              hideOnOutsideClick={true}
                              className="flex flex-col justify-left text-left"
                              >
                              <p className="text-left">Send staff SMS</p>
                         
                          </Tooltip>
                      </li>
            <li  
                      id="beneficiaryList" 
                      onClick={()=>{
                        setUnsubmitted(true);
                      }} 
                      style={{borderWidth : '1px'}}  
                      className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center" >
                      <span className="flex justify-center items-center  dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img className="" src={folder} /></span> 
                          <Tooltip
                              target="#beneficiaryList"
                              showEvent="dxhoverstart"
                              hideEvent="dxhoverend"
                              hideOnOutsideClick={true}
                              className="flex flex-col justify-left text-left"
                              >
                              <p className="text-left">Unsubmitted Beneficiary List</p>
                         
                          </Tooltip>
                      </li>
            <li  
                      id="switchBeneficiary" 
                      onClick={()=>{}} 
                      style={{borderWidth : '1px'}}  
                      className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center" >
                      <span className="flex justify-center items-center  dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img className="" src={close} /></span> 
                          <Tooltip
                              target="#switchBeneficiary"
                              showEvent="dxhoverstart"
                              hideEvent="dxhoverend"
                              hideOnOutsideClick={true}
                              className="flex flex-col justify-left text-left"
                              >
                              <p className="text-left">Switch beneficiary active status</p>
                         
                          </Tooltip>
                      </li>
                      <li  style={{ borderWidth: '1px' }} id="hideStaffNo" className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1  text-gray-600 rounded flex items-center">
                        <Checkbox
                      
                        indeterminate={false}
                        onChange={(checkValue:any)=>{                      
                          dispatch(setGeneralValue({expr:'hideStaffNo',value:checkValue.target.checked}))
                        }}
                        />
                        <Tooltip
                            target="#hideStaffNo"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                            >
                            <p className="">Show Name and Staff No as a single column</p>
                        </Tooltip>
                    </li>
          </>
        }
        
        />
      </div>
    )
};
