

export const sentSMS_cols = [
    {id: 0, caption:"TIME SMS WAS SENT", dataField:"slgCreationDate", dataType:"datetime", fixed:true, visible:true, width:120,},
    {id: 1, caption:"RECEIPENT", dataField:"empName16", dataType:"string", fixed:true, visible:true, width:120,},
    {id: 2, caption:"MOB No", dataField:"empMobNox", dataType:"string", fixed:true, visible:true, width:120,},
    {id: 3, caption:"JOB TITLE", dataField:"jbtShtName", dataType:"string", fixed:"", visible:true, width:120,},
    {id: 4, caption:"DEPT", dataField:"dptShtName", dataType:"string", fixed:true, visible:true, width:120,},
    {id: 5, caption:"SECTION", dataField:"sxnShtName", dataType:"string", fixed:true, visible:true, width:120,},
    {id: 6, caption:"INITIATOR", dataField:"initEmpName16", dataType:"string", fixed:false, visible:true, width:120,},
    {id: 7, caption:"FORM", dataField:"afmShtName", dataType:"string", fixed:false, visible:true, width:120,},
    {id: 8, caption:"MODULE", dataField:"apmShtName", dataType:"string", fixed:false, visible:true, width:120,},
    {id: 9, caption:"MESSAGE", dataField:"slgMessage", dataType:"string", fixed:false, visible:true, width:4000,},
] 

export const pendingSMS_cols = [
    {id: 0, caption:"CREATION DATE", dataField:"imsCreationDate", dataType:"datetime", fixed:true, visible:true, width:120,},
    {id: 1, caption:"RECEIPENT", dataField:"empName16", dataType:"string", fixed:true, visible:true, width:120,},
    {id: 2, caption:"MOB No", dataField:"empMobNox", dataType:"string", fixed:true, visible:true, width:120,},
    {id: 3, caption:"DEL?", dataField:"imsDeleted", dataType:"boolean", fixed:true, visible:true, width:120,},
    {id: 4, caption:"JOB TITLE", dataField:"jbtShtName", dataType:"string", fixed:"", visible:true, width:120,},
    {id: 5, caption:"DEPT", dataField:"dptShtName", dataType:"string", fixed:true, visible:true, width:120,},
    {id: 6, caption:"SECTION", dataField:"sxnShtName", dataType:"string", fixed:true, visible:true, width:120,},
    {id: 7, caption:"INITIATED BY", dataField:"initEmpName16", dataType:"string", fixed:false, visible:true, width:120,},
    {id: 8, caption:"FORM", dataField:"afmShtName", dataType:"string", fixed:false, visible:true, width:120,},
    {id: 9, caption:"MODULE", dataField:"apmShtName", dataType:"string", fixed:false, visible:true, width:120,},
    {id: 10, caption:"MESSAGE", dataField:"imsMessage", dataType:"string", fixed:false, visible:true, width:4000,},
]   