import { Button, Checkbox, DatePicker, Form, Input, Radio, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { DataGrid } from "devextreme-react";
import {
  SearchPanel,
  GroupPanel,
  Pager,
  Column,
  Scrolling,
} from "devextreme-react/data-grid";
import reset from "../../../../assets/reset.png";
import greenAdd from "../../../../assets/green_plus.png";
import { question_table_data, questions_TH } from "../data/questions-data";
// import {
//   EditButton,
//   NewButton,
//   SaveButton,
// } from "../../../../../accessories/buttons";
// import {
//   smallAttendanceData,
//   smallAttendaceTable_TH,
//   mediumlAttendaceTable_TH,
//   meeting_attendance_main_data,
//   meeting_attendance_main_TH,
// } from "../../../../../Safety/Entries/SafetyMeetingAttendance/data/meeting-attendance-data";
// import { useState, useEffect } from "react";
// import {
//   navbar_height,

//   toolbar_height,
// } from "../../../../../accessories/component_infos";
import save from "../../../../assets/floppy-disk.png";
import newIcon from "../../../../assets/new.png";
import { other_details_TH } from "../data/other-details-data";
import { useSelector } from "react-redux";
import {
  navbar_height,
  toolbar_height,
} from "../../../accessories/component_infos";
import { RootState } from "../../../../app/store";
import { useEffect, useState } from "react";
// import { RootState } from "../../../../../../app/store";
import Datagrid_template from "../../../../templates/Datagrid";
import InputSelect_template from "../../../../templates/InputSelect";
import { TextareaTemplate } from "../../../../templates/textarea";
import useFetch from "../../../../hooks/useFetch";

interface props {
  toggled?: boolean;
}

const OtherLineDetailsTab = ({ toggled }: props) => {
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  interface props {
    toggled?: boolean;
  }
  const sp = "\u00a0";

  const heights_out = navbar_height + news_flash_height + toolbar_height;

  //varying form height
  const form_height = 490;

  //Table Height
  const pageHeight = window.innerHeight - navbar_height; //height without navbar
  const [tableHeight, settableHeight] = useState(
    window.innerHeight - (heights_out + 70)
  ); //height without navbar and toolbar
  const [table_form_Height, settable_form_Height] = useState(
    window.innerHeight - heights_out
  ); //height without navbar and toolbar and form

  const [showTable, setShowTable] = useState(true);
  const dateFormat = " DD MMM YYYY HH: mm:ss";

  useEffect(() => {
    console.log(`inside ${toggled}`);
  }, [toggled]);

  const [states, setStates] = useState({
    params : { id: 0, name: "" },
    value : { id: 0, name: "" },
    rmks : ""
  })

  const setState = (key: string, value: any) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };

  const [params_data, params_loading, params_value] = useFetch(`LineDetails/parameter_values`,true, undefined , undefined, 'powerapp')

  const { Option } = Select;
  return (
    <div>
      {
        <div className="  rounded-md">
          {/* The form */}
          <div className=" flex ">
            <Form
              name="complex-form"
              labelCol={{ span: 1 }}
              wrapperCol={{ span: 1 }}
              size={"small"}
              className=" border-l-2 border-r-2 "
              style={{ width: "100%" }}
            >
              <div className="text-xs bg-slate-100 rounded-t-sm py-1 px-2">
                Other Parameters
              </div>
              <Form
                name="complex-form"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 20 }}
                size={"small"}
                className="ml-2 my-2 "
                style={{ width: "90%" }}
              >
                <Form.Item
                  label={<p className="text-xs">{"Parameter"}</p>}
                  style={{ marginBottom: 0 }}
                >
                  <Form.Item
                    name="issuedBy"
                    rules={[{ required: true }]}
                    style={{ display: "inline-block", width: "calc(44%)" }}
                  >
                    {" "}
                    <div className="flex flex-row items-center">
                    <InputSelect_template
                      style={{ width: "100%" }}
                      disabled={false}
                      useCallFunc
                      idexpr={"acvIDpk"}
                      dataexp={"acvName"}
                      options={params_data}
                      placeHolder={states.params.name}
                      selectedValue={(e) => {
                        setState("params", {
                          id: e[""],
                          name: e[""],
                        });
                      }}
                      handleClear={() => {
                        setState("params", { id: 0, name: "" });
                      }}
                      />
                    </div>
                  </Form.Item>
                </Form.Item>

                <Form.Item
                  label={<p className="text-xs">{"Value"}</p>}
                  style={{ marginBottom: 0 }}
                >
                  <Form.Item
                    name="issuedBy"
                    rules={[{ required: true }]}
                    style={{ display: "inline-block", width: "calc(44%)" }}
                  >
                    {" "}
                    <div className="flex flex-row items-center">
                    <InputSelect_template
                      style={{ width: "100%" }}
                      disabled={false}
                      useCallFunc
                      idexpr={""}
                      dataexp={""}
                      options={[]}
                      placeHolder={states.value.name}
                      selectedValue={(e) => {
                        setState("value", {
                          id: e[""],
                          name: e[""],
                        });
                      }}
                      handleClear={() => {
                        setState("value", { id: 0, name: "" });
                      }}
                      />
                    </div>
                  </Form.Item>
                </Form.Item>

                <Form.Item
                  label={<p className="text-xs">{"Remarks"}</p>}
                  style={{ marginBottom: 0, marginTop: "2px" }}
                >
                  <Form.Item
                    name="issuedBy"
                    rules={[{ required: true }]}
                    style={{ display: "inline-block", width: "calc(100%)" }}
                  >
                    {" "}
                    <div className="flex flex-row items-center">
                    <TextareaTemplate
                      setCallbackValue={(e) => {
                        setState("rmks", e);
                      }}
                      height={45}
                      width={'500px'}
                      defaultValue={states.rmks}
                      readonly={false}
                      disabled={false}
                      //  className="w-full h-full"
                    />
                      <div className="flex  justify-end  ">
                        <button
                          title="add"
                          className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center   px-2 ml-2 py-2 border-2 border-slate-200 rounded"
                        >
                          <img alt="add" className="" src={greenAdd} />
                        </button>
                      </div>
                    </div>
                  </Form.Item>
                </Form.Item>
              </Form>

              <div className=" border-t-2  ">
                <div className="text-xs bg-slate-100 rounded-t-sm py-1 px-2">
                  Additional Parameter Details
                </div>
                <Datagrid_template
                  gridheight={178}
                  columns={other_details_TH}
                  data={question_table_data}
                />
                {/* <DataGrid
                  dataSource={meeting_attendance_main_data}
                  showBorders={true}
                  columnWidth={150}
                  showRowLines={true}
                  showColumnLines={true}
                  Remember to change
                  height={178}
                > */}
                {/* <SearchPanel visible={false} width={200} />
                  <GroupPanel visible={false} />
                  <Pager visible={false} /> */}

                {/* STAFF DETAILS COLUMNS */}
                {/* FIXED COLUMNS */}

                {/* {other_details_TH.map((data) => {
                    return (
                      <Column
                        key={data.id}
                        caption={data.caption}
                        dataField={data.dataField}
                        alignment={data.alignment}
                        fixed={data.fixed}
                        width={data.width === null ? 150 : data.width}
                      />
                    );
                  })} */}

                {/* SCROLLBAR  */}

                {/* <Scrolling
                  columnRenderingMode="finite"
                  /> */}
                {/* </DataGrid> */}
              </div>
            </Form>

            {/* <Form
              name="complex-form"
              labelCol={{ span: 1 }}
              wrapperCol={{ span: 1 }}
              size={"small"}
              className=" border-l-2 ml-2 "
              style={{ width: "45%" }}
            >
              <div className=" border-t-2 pb-2 ">
                <div className="text-xs bg-slate-100 rounded-t-sm py-1 px-2">
                  Images
                </div>

                <div className="px-2 pb-2 flex items-end    h-64 w-full ">
                  <div className=" w-full h-60  border flex justify-center items-center">
                    No Image data
                  </div>
                </div>
                <div className="flex mx-2 mb-0.5">
                  {" "}
                  <TextArea
                    style={{
                      resize: "none",
                      maxHeight: "12%",
                      minHeight: "10%",
                      height: "",
                      width: "88%",
                    }}
                    className="o  py-1  overflow-y-scroll resize-none"
                    rows={1}
                  />
                  <button
                    title="save"
                    className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center ml-1  mr-1 px-2  border-2 border-slate-200 rounded"
                  >
                    <img alt="save" className="pr-1" src={save} />
                  </button>
                  <button
                    title="a"
                    className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  px-2  border-2 border-slate-200 rounded"
                  >
                    <img alt="newIcon" className="pr-1" src={newIcon} />
                  </button>
                </div>
                <div className="flex">
                  <div
                    style={{
                      resize: "none",
                      maxHeight: "12%",
                      minHeight: "10%",
                      height: "",
                      width: "100%",
                    }}
                    className="o mx-2  border  flex "
                  >
                    <div className=" border-r p-0.5 text-xs px-2">1</div>
                    <div className=" border-r p-0.5 text-xs px-2">2</div>
                    <div className=" border-r p-0.5 text-xs px-2">3</div>
                    <div className=" border-r p-0.5 text-xs px-2">4</div>
                    <div className=" border-r p-0.5 text-xs px-2">5</div>
                    <div className=" border-r p-0.5 text-xs px-2">6</div>
                    <div className=" border-r p-0.5 text-xs px-2">7</div>
                    <div className=" border-r p-0.5 text-xs px-2">8</div>
                  </div>
                </div>
              </div>
            </Form> */}
          </div>
        </div>
      }
    </div>
  );
};

export default OtherLineDetailsTab;
