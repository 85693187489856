/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useEffect, useRef, useState } from "react";
import Datagrid_template from "../../../templates/Datagrid";
import { HrSetupsCustomToolbar } from "../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import useResizeMode from "../../../hooks/useResizeMode";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { selectsTypes } from "../../human-resource/setups/data/types/selectsTypes";
import { Form } from "antd";
import { TextareaTemplate } from "../../../templates/textarea";
import { appForms_cols } from "./data/datagridCols";
import { navbar_height } from "../../accessories/component_infos";
import { AapplicationMod_details } from "./widgets/applicationMod_details";
import useFetch from "../../../hooks/useFetch";
import { useDispatch } from "react-redux";
import { setGeneralValue } from "../../../features/generalSlice";
import { CustomizableButton } from "../../accessories/buttons";
import cookieLock from "../../../assets/cookieLock.png";
import openLock from "../../../assets/openLock.png";
import { PopGdvApplicationModule } from "../../../interfaces";

interface stateTypes {
  searchText: { temp: string; text: string };
  activeStatus: { temp: any; status: any };
  remarks: string;
}

const ApplicationMod = () => {
  const formRef = useRef<any>(null);
  const dispatch = useDispatch();

  // Redux
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );

  const [switch_change, setSwitch_change] = useState<boolean>(true);
  // switch toggle
  const switch_toggle = () => {
    if (switch_change) {
      setSwitch_change(!switch_change);
    } else {
      setSwitch_change(!switch_change);
    }
  };

  const [selectedRecord, setSelectedRecord] = useState<PopGdvApplicationModule>();
  const [states, setStates] = useState<stateTypes>({
    searchText: { temp: "", text: "" },
    activeStatus: { temp: "", status: "" },
    remarks: selectedRecord?.apmRmks,
  });

  const { searchText, activeStatus } = states;

  const updateStates = (key: string, value: any) => {
    setStates((prev: any) => ({ ...prev, [key]: value }));
  };

  const [formheight, set_form_height] = useState<number>(0);

  const [initHeight, FullHeight] = useResizeMode(switch_change);

  var fullPage = FullHeight - (navbar_height + news_flash_height + 30);

  var pageHeight =
    initHeight - (navbar_height + news_flash_height + formheight + 25);

  const [refresh, setRefresh] = useState<boolean>(false); //refresh data state for data creation or update

  const [application_data, application_error, application_loading]: [PopGdvApplicationModule[], any, boolean] = useFetch(
    `SwdApplicationModule/PopGdvApplicationModule?search=${searchText.temp}&status=${activeStatus.temp}`,
    refresh
  );

  const [searchTextData, searchTextError, searchTextLoading] = useFetch(``);

  // toolbar search's inputSelect field
  const toolbarInputSelectsData: selectsTypes[] = [
    {
      id: 0,
      label: "",
      optionsData: searchTextData,
      idExpr: "",
      dataExpr: "",
      defaultValue: "",
      style: "",
      selectedValue: () => { },
    },
  ];

  const gridonRowClick = useCallback(
    (e: any) => {
      // Add privileges if user has privilege then do stuffs
      dispatch(setGeneralValue({ expr: "updateMode", value: false }));
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));

      setSelectedRecord(e);
    },
    [dispatch]
  );

  const gridDblClick = useCallback(
    (e: any) => {
      // Add privileges if user has privilege then do stuffs
      dispatch(setGeneralValue({ expr: "updateMode", value: true }));
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      setSelectedRecord(e);
    },
    [dispatch]
  );
  useEffect(() => {
    set_form_height(formRef.current?.clientHeight + 25);
  }, [switch_change]);

  return (
    <div className="h-full dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base">
      {/* toggles between the taskForm and the footer grid table vertically */}

      <div className="px-2">
        <HrSetupsCustomToolbar
          withDates={false}
          searchTextOnchange={(searchValue: any) => {
            updateStates("searchText", {
              temp: searchValue,
              text: searchText.text,
            });
          }}
          checkOnChange={(check: any) => {
            updateStates("activeStatus", {
              temp: check,
              status: activeStatus.status,
            });
          }}
          handleFind={() => {
            setRefresh((current) => !current);
            updateStates("searchText", {
              temp: searchText.temp,
              text: searchText.temp,
            });
            updateStates("activeStatus", {
              temp: activeStatus.temp,
              status: activeStatus.temp,
            });
          }}
          handleRefresh={() => {
            setRefresh((current) => !current);
            updateStates("searchText", {
              temp: "",
              text: "",
            });

            updateStates("activeStatus", {
              temp: "",
              status: "",
            });
          }}
          toggler={switch_toggle}
          outerSelected={selectedRecord}
          setOuterSelected={setSelectedRecord}
          extraWidgetsAfterSwitch={
            <div className="flex pr-1">
              <li className="">
                <CustomizableButton
                  buttonID="lockedScenario"
                  isTooltip
                  tooltipMsg={"Lock all scenario"}
                  classname={"p-1 "}
                  customizableBtnImage={cookieLock}
                />
              </li>
              <li className="pl-1">
                {" "}
                <CustomizableButton
                  buttonID="unlockedScenario"
                  isTooltip
                  tooltipMsg={"unLock all scenario"}
                  classname={"p-1 "}
                  customizableBtnImage={openLock}
                />
              </li>
            </div>
          }
          isInputSelect={true}
          toolbarInputSelectsData={toolbarInputSelectsData}
        />
      </div>

      {switch_change && (
        <div ref={formRef} className="px-2">
          <p
            id={"form_header"}
            style={{ borderColor: borderTheme }}
            className="py-0.5 items-center border-[1px] border-b-0 pl-4 w-full bg-slate-100 rounded-tl font-medium"
          >
            Module Details
          </p>
          <div
            style={{ height: "205px", borderColor: borderTheme }}
            className="w-full border-[1px]  px-2 flex justify-between gap-x-4 border-b-[1px] mb-1 pb-1"
          >
            <div style={{ width: "420px", height: "200px" }} className="mb-1">
              <AapplicationMod_details
                selectedRecordx={selectedRecord}
                setRefresh={setRefresh}
                remarks={states?.remarks}
              />
            </div>

            <div
              style={{ width: "100%" }}
              className=" w-full  mt-0.5 rounded-md items-center dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base "
            >
              <Form>
                <TextareaTemplate
                  labelCol={3}
                  useCallbackFunc
                  placeholder={selectedRecord?.apmRmks}
                  setCallbackValue={(value: any) => {
                    updateStates("remarks", value);
                  }}
                  height={195}
                  defaultValue={states.remarks}
                  label={"Remarks"}
                />
              </Form>
            </div>
          </div>
        </div>
      )}

      {/* Footer gridtable  */}
      <div
        style={{ height: "" }}
        className="px-2  dark:bg-slate-900 dark:text-darkModeSkin-base"
      >
        <Datagrid_template
          gridheight={switch_change ? pageHeight : fullPage}
          columns={appForms_cols}
          data={application_data}
          onRowClick={gridonRowClick}
          rowDoubleClicked={gridDblClick}
        />
      </div>
    </div>
  );
};

export default ApplicationMod;
