import { requestConfig } from "../general/requestConfig";
import { api_url } from "../components/accessories/component_infos";
import axios, { AxiosRequestConfig } from "axios";
import { userDataCrypt } from "./userDataEncrypt";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";

export const UpdateDataFunc = async (
  url: string,
  payload?: any,
  action?: string
) => {
  const updateResponse = await axios.put(
    `${api_url}/${url}`,
    payload,
    requestConfig()
  );
  return updateResponse;
};
export const UpdateDataFuncParams = async (url: string) => {
  const updateResponseP = await axios.put(`${api_url}/${url}`, requestConfig());
  return updateResponseP;
};

// Custom hook starts with use
export const useUpdateDataFunc = () => {
  const formCode = useSelector((state: RootState) => state.user?.formCode);

  const updateData = async (url: string, payload: any, action?: string) => {
    const response = await axios.put(
      `${api_url}/${url}`,
      { ...payload, formCode: formCode, action: action },
      requestConfig()
    );
    return response;
  };

  return updateData;
};
