export const mainGridColumns = [
  {
    id: 0,
    caption: "SERVICE PROVIDER",
    dataField: "spdName",
    datatype: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 1,
    caption: "SERVICE STATION",
    dataField: "sstName",
    datatype: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "DATE",
    dataField: "pprDate",
    dataType: "datetime",
    width: 140,
    fixed: true,
    visible: true,
  },
  {
    id: 3,
    caption: "PRODUCT",
    dataField: "prdName",
    datatype: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 4,
    caption: "UNIT PRICE",
    dataField: "pprUnitPrice",
    datatype: "string",
    width: 160,
    fixed: false,
    visible: true,
  },
  {
    id: 5,
    caption: "CURRENCY",
    dataField: "curName",
    datatype: "string",
    width: 160,
    fixed: false,
    visible: true,
  },
  {
    id: 6,
    caption: "UNIT OF MEASURE",
    dataField: "funName",
    datatype: "boolean",
    width: 160,
    fixed: false,
    visible: true,
  },
  {
    id: 15,
    caption: "REMARKS",
    dataField: "pprRmks",
    datatype: "string",
    width: 4000,
    fixed: false,
    visible: true,
  },
];

export const stationsColumns = [
  {
    id: 0,
    caption: "No",
    dataField: "",
    datatype: "string",
    width: 50,
    fixed: true,
    visible: true,
  },
  {
    id: 1,
    caption: "Service Station",
    dataField: "sstName",
    datatype: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "Town",
    dataField: "twnName",
    datatype: "string",
    width: 160,
    fixed: false,
    visible: true,
  },
];
