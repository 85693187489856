
// FOR DISTRIBUTION MINI
export const mission_vision_cols = [
    {id: 0,caption : "ID", dataField : 'msvIdpk', dataType : "number", fixed : true,width:0},
    {id: 1,caption : "NAME", dataField : 'mvsName', dataType : "string", fixed : true,width:160},
    {id: 2,caption : "START DATE", dataField : 'mvsStartDate', dataType : "date",format:"dd MMM yyyy",fixed : true,width:120},
    {id: 3,caption : "END DATE", dataField : 'mvsEndDate', dataType : "date",format:"dd MMM yyyy", fixed : true,width:120},
    {id: 4,caption : "MISSION", dataField : 'mvsMission', dataType : "string", fixed : false,width:200},
    {id: 5,caption : "SHORT MISSION", dataField : 'mvsMissionSht', dataType : "string", fixed : false, width: 200},
    {id: 6,caption : "VISION", dataField : 'mvsVision', dataType : "string", fixed : false, width: 200},
    {id: 7,caption : "SHORT VISION", dataField : 'mvsVisionSht', dataType : "string", fixed : false, width: 200},
    {id: 8,caption : "ACTIVE?", dataField : 'mvsActive', dataType : "boolean"},
    {id: 9,caption : "Remarks", dataField : 'mvsRmks', dataType : "string",width:4000},
]