import { Form, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import SetupsDatalistTemplat from "../../../../../../human-resource/setups/data/setups_datalist_template";
import { InputsTemplate } from "../../../../../../../templates/input";
import { BottomBtns } from "../../../../../../../templates/butttons";
import { TextareaTemplate } from "../../../../../../../templates/textarea";
import { useEffect, useState } from "react";
import useFetch from "../../../../../../../hooks/useFetch";
import {
  saveModalData,
  updateModalData,
} from "../../../../../../accessories/component_infos";
import dayjs from "dayjs";
import { ModalTemplate } from "../../../../../../../templates/modal";
import {
  setGeneralValue,
  settask_footer_table_refresh,
} from "../../../../../../../features/generalSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import { useValidateExist } from "../../../../../../../hooks/useValidateExists";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { useBusyLoader } from "../../../../../../../hooks/useBusyLoader";
import { useCrudFunc } from "../../../../../../../functions/crud";
import InputSelect_template from "../../../../../../../templates/InputSelect";

interface props {
  selectedRecordx?: any;
  resetTable?: () => void;
}

interface stateTypes {
  name?: {id:string, value:string};
  shortName?: {id:string, value:string};
  contactP?: string;
  telNos?: string;
  mobNos?: string;
  email?: string;
  address?: string;
  fuelDump?: boolean;
  active?: boolean;
  remarks?: string;
  order?: number;
}

export const ServiceProvidersForm = ({
  selectedRecordx,
  resetTable,
}: props) => {
  const validateExists = useValidateExist();
  const [employeeId, userId] = useAccountInfo();

  const [busyLoader, setBusyLoader] = useBusyLoader();
  const [posting , updating]=useCrudFunc()
  const dispatch = useDispatch();
  const form_disable = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  const [states, setStates] = useState<stateTypes>({
    name: {id:"", value:""},
    shortName: {id:"", value:""},
    contactP: "",
    telNos: "",
    mobNos: "",
    email: "",
    address: "",
    fuelDump: false,
    active: false,
    remarks: "",
    order: 0,
  });

  const setState = (key: string, value: any) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };

  //populate fields
  const populateFields = (clearFields: boolean) => {
    if (selectedRecordx !== undefined) {
      const statex = [
        { key: "name", value: clearFields ?{id: "", value:""} : {id:selectedRecordx.spdName, value:selectedRecordx.spdName } },
        {
          key: "shortName",
          value: clearFields ? {id: "", value:""} : {id:selectedRecordx.spdShtName, value:selectedRecordx.spdShtName},
        },
        {
          key: "contactP",
          value: clearFields ? "" : selectedRecordx.spdMainContact,
        },
        { key: "telNos", value: clearFields ? "" : selectedRecordx.spdTelNos },
        { key: "mobNos", value: clearFields ? "" : selectedRecordx.spdMobNos },
        { key: "email", value: clearFields ? "" : selectedRecordx.spdEmail },
        {
          key: "address",
          value: clearFields ? "" : selectedRecordx.spdPostalAddress,
        },
        {
          key: "fuelDump",
          value: clearFields ? true : selectedRecordx.spdFuelDump,
        },
        {
          key: "active",
          value: clearFields ? true : selectedRecordx.spdActive,
        },

        { key: "remarks", value: clearFields ? "" : selectedRecordx.spdRmks },
        { key: "order", value: clearFields ? "" : selectedRecordx.order },
      ];

      //set states
      for (let instance of statex) {
        setState(instance.key, instance.value);
      }
    }
  };

  // Endpoints
  const [name_data, name_error, name_loading] = useFetch(
    "AsmTmsServiceProviders/GetAllAsmTmsServiceProviderNames"
  );
 
  //  Current date
  const editedDate = dayjs().format(); 
  const [refresh, setRefresh] = useState<boolean>(false);
  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">(); 

  //  Validation
  const validationForms = async (action: "post" | "put") => {
    setIcon("warning");

    if (states.name.id === "") {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select data entry for Name",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);

      return false;
    } else if (states.shortName.id === "") {
      setIcon("warning");

      setshowModalDisableButton(true);
      setModalData({
        message: "Please select data entry for ShortName",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);

      return false;
    } else if (states.contactP === "") {
      setIcon("warning");

      setshowModalDisableButton(true);
      setModalData({
        message: "Please enter Contacts Persons",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);

      return false;
    } else if (states.email === "") {
      setIcon("warning");

      setshowModalDisableButton(true);
      setModalData({
        message: "Please enter Email",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);

      return false;
    } else {
      if (action === "post") {
        const validated = await validateExists(
          `AsmTmsServiceProviders/GetNameShortName?name=${states.name.value}&shortname=${states.shortName.value}`
        );

        if (validated) {
          return true;
        }

        return false;
      } else if (action === "put") {
        const validated = await validateExists(
          `AsmTmsServiceProviders/GetNameShortName?name=${states.name.value}&shortname=${states.shortName.value}`,
          selectedRecordx.spdIdpk,
          "spdIdpk"
        );

        if (validated) {
          return true;
        }

        return false;
      }
    }
  };

  const disableForm = () => {
    dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    dispatch(setGeneralValue({ expr: "updateMode", value: false }));
  };

  const postData = async () => {
    try {
      setBusyLoader(saveModalData.progress);

        await posting (
        `AsmTmsServiceProviders/CreateAsmTmsServiceProvider`,
        {
          spdName: states.name.value,
          spdShtName: states.shortName.value,
          spdMainContact: states.contactP,
          spdMobNos: states.mobNos,
          spdEmail: states.email,
          spdTelNos: states.telNos,
          spdPostalAddress: states.address,
          spdFuelDump: states.fuelDump,
          spdActive: states.active,
          spdRmks: states.remarks,
          spdOrder: states.order,
          spdCreatedBy: userId,
        },`Created Service Provider ${states.name} successfully!`
      );
      dispatch(settask_footer_table_refresh(!refresh));
      resetTable!();

      disableForm();

      setOpenModal(true);
      setshowModalDisableButton(false);
      setshowModalDisableButton(true);
      setIcon("success");
      setModalData({
        message: saveModalData.success.message,
        title: saveModalData.success.title,
        okText: "Ok",
      });
    } catch (error: any) { 

      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: saveModalData.error.message,
        title: saveModalData.error.title,
        okText: "Ok",
      });
    } finally {
      setBusyLoader("");
    }
  };

  const updateData = async () => {
    setBusyLoader(updateModalData.progress);

    try {
      await updating(
        `AsmTmsServiceProviders/UpdateAsmTmsServiceProvider`,
        {
          spdIdpk: selectedRecordx.spdIdpk,
          spdName: states.name.value,
          spdShtName: states.shortName.value,
          spdMainContact: states.contactP,
          spdMobNos: states.mobNos,
          spdEmail: states.email,
          spdTelNos: states.telNos,
          spdPostalAddress: states.address,
          spdFuelDump: states.fuelDump,
          spdActive: states.active,
          spdRmks: states.remarks,
          spdOrder: states.order,
          spdEditedDate: editedDate,
        },`Updated Service Provider to ${states.name} successfully!`
      );
       resetTable && resetTable();

      disableForm();

      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: "Data entry updated successfully!",
        title: "Data Updated!",
        okText: "Ok",
      });
    } catch (error: any) {

      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: updateModalData.error.message,
        title: updateModalData.error.title,
        okText: "Ok",
      });
    } finally {
      setBusyLoader("");
    }
  };

  useEffect(() => {
    populateFields(false);
  }, [selectedRecordx]);
  return (
    <div className="px-2 ">
      <ModalTemplate
        disableCancel={showModalDisableButton}
        icon={icon}
        cancelText={modalData.cancelText}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        open={openModal}
        okHandler={() => {
          modalData === saveModalData
            ? postData()
            : modalData === updateModalData
            ? updateData()
            : setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
      />
      {/* FORM HEADER */}
      <p
        id={"form_header"}
        style={{ borderColor: borderTheme }}
        className="py-1 border-[1px] px-2 w-full font-semibold bg-slate-100 border-b-[0px] rounded-t-md"
      >
        {"Service Provider Details"}
      </p>
      <Form
        style={{ borderColor: borderTheme }}
        className="w-full border-[1px] border-slate-100 flex justify-between py-1 px-2 "
        name="basic"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 19 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        size="small"
      >
        <section className="" style={{ width: "550px" }}>
          <div className=" mb-1">
          <InputSelect_template
              label="Name"
              idexpr={"spdIdpk"}
              dataexp={"spdName"}
              selectedValue={(e: any) => {
                setState("name", {id:e["spdIdpk"], value:e["spdName"]});
              }}
              placeHolder={states.name.value}
              options={name_data}
              useCallFunc
              // disabled={disabled} 
            />

            {/* <SetupsDatalistTemplat
              datalistStyle={{ height: 24 }}
              label="Name"
              listid="1"
              options={name_data}
              defaultValue={states.name}
              idExpr={"spdIdpk"}
              nameExpr={"spdName"}
              useCallbackFunc
              selectedData={states.name}
              setCallbackValue={(e: any) => {
                setState("name", e);
              }}
            /> */}
          </div>

          <div className="mb-1">
          <InputSelect_template
              label="Short Name"
              idexpr={"spdIdpk"}
              dataexp={"spdShtName"}
              selectedValue={(e: any) => {
                setState("shortName", {id:e["spdIdpk"], value:e["spdShtName"]});
              }}
              placeHolder={states.name.value}
              options={name_data}
              useCallFunc
              // disabled={disabled} 
            />

            {/* <SetupsDatalistTemplat
              datalistStyle={{ height: 24 }}
              label="Short Name"
              listid="2"
              options={name_data}
              defaultValue={states.name}
              idExpr={"spdIdpk"}
              nameExpr={"spdShtName"}
              useCallbackFunc
              selectedData={states.name}
              setCallbackValue={(e: any) => {
                setState("shortName", e);
              }}
            /> */}
          </div>

          <div className="mb-1">
            <InputsTemplate
              inputStyle={{ height: 24 }}
              useCallbackFunc
              span={true}
              label="Contact Persons"
              orderOnchange={(e: any) => {
                setState("contactP", e);
              }}
              defaultValue={states.contactP}
            />
          </div>

          <Form.Item
            className="mb-1"
            label={<p className="text-xs">{"Tel Nos/Mob. Nos"}</p>}
            style={{}}
          >
            <Form.Item
              name="year"
              style={{ display: "inline-block", width: "calc(50% )" }}
            >
              <div className=" mb-1">
                <Input
                  style={{ height: 24 }}
                  disabled={form_disable}
                  placeholder={states.telNos}
                  value={states.telNos}
                  onChange={(e) => {
                    setState("telNos", e.target.value);
                  }}
                  className=""
                />
              </div>
            </Form.Item>

            <Form.Item
              name="month"
              style={{
                display: "inline-block",
                width: "calc(49% )",
                marginLeft: "1%",
              }}
            >
              <div className=" mb-1">
                <Input
                  style={{ height: 24 }}
                  disabled={form_disable}
                  placeholder={states.mobNos}
                  value={states.mobNos}
                  onChange={(e) => {
                    setState("mobNos", e.target.value);
                  }}
                  className=""
                />
              </div>
            </Form.Item>
          </Form.Item>

          <div className="mb-1">
            <InputsTemplate
              inputStyle={{ height: 24 }}
              defaultValue={states.email}
              useCallbackFunc
              label="Email"
              span
              orderOnchange={(e: any) => {
                setState("email", e);
              }}
            />
          </div>

          <Form.Item
            className=""
            label={
              <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                {"Address"}
              </p>
            }
            rules={[{ required: false }]}
          >
            <div className=" mb-0.5">
              <TextArea
                value={states.address}
                onChange={(e) => {
                  setState("address", e.target.value);
                }}
                disabled={form_disable}
                style={{ resize: "none", height: 50 }}
                className="o  overflow-y-scroll resize-none"
                placeholder=""
              />
            </div>
          </Form.Item>
          <div className="mb-1">
            <InputsTemplate
              id="num11"
              inputType="number"
              inputStyle={{ height: 24 }}
              defaultValue={states.order}
              useCallbackFunc
              label="Order"
              span
              orderOnchange={(e: any) => {
                setState("order", e);
              }}
            />
          </div>

          <BottomBtns
            useCallbackFunc
            addActiveBorders={true}
            additionalCheckBoxValue={[states.fuelDump, states.active]}
            additionalBottomChckbox="Fuel Dump?"
            firstCheckboxChange={(e) => {
              setState("fuelDump", e);
            }}
            secondCheckboxChange={(e) => {
              setState("active", e);
            }}
            saveHandler={async () => {
              if (await validationForms("post")) {
                setIcon("question");
                setshowModalDisableButton(false);
                setModalData(saveModalData);
                setOpenModal(true);
              }
            }}
            updateHandler={async () => {
              if (await validationForms("put")) {
                setIcon("question");
                setshowModalDisableButton(false);
                setModalData(updateModalData);
                setOpenModal(true);
              }
            }}
            onNewClick={() => {
              populateFields(true);
              dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
            }}
            span
          />
        </section>

        {/* remarks */}
        <section className=" " style={{ width: "calc(100% - 575px)" }}>
          <TextareaTemplate
            useCallbackFunc
            label="Remarks"
            setCallbackValue={(e) => {
              setState("remarks", e);
            }}
            defaultValue={states.remarks}
            height={235}
            MarginLeft={"40px"}
          />
        </section>
      </Form>
    </div>
  );
};
