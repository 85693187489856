import { inputsTypes } from "../../human-resource/setups/data/types/selectsTypes";
import {  Performance_date_template, Performance_input_template, Performance_textarea_template } from "./template/input_templates";


//  FOR CIA


export const CIA_TextArea = () => {

    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';
    //  will have to work on height calc
    const height = '145px';
    const inputs:inputsTypes[] = [
        {id: 0, label : 'Remark',},
    ]

    // const [data,error,loading] = useFetch(`api/HrEmp/FormAssignmentCategories`);
    // if (error) {
    //     console.log(error);
    // }
    return (
        <>
            <Performance_textarea_template  textheight={height}  inputs={inputs} />
        </>
    );
}


// FOR CI

export const CI_TextArea = () => {

    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';
    //  will have to work on height calc
    const height = '100px';
    const labelCol = 1
    const inputs:inputsTypes[] = [
        {id: 0, label : 'Remarks',},
    ]

 

    // const [data,error,loading] = useFetch(`api/HrEmp/FormAssignmentCategories`);
    // if (error) {
    //     console.log(error);
    // }
    return (
        <>
            <Performance_textarea_template  textheight={height}  inputs={inputs} labelCol={labelCol}  />
        </>
    );
}


// FOR UIA

export const UIA_TextArea = () => {

    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';
    //  will have to work on height calc
    const height = '245px';
    const inputs:inputsTypes[] = [
        {id: 0, label : 'Remark',},
    ]

    // const [data,error,loading] = useFetch(`api/HrEmp/FormAssignmentCategories`);
    // if (error) {
    //     console.log(error);
    // }
    return (
        <>
            <Performance_textarea_template  textheight={height}  inputs={inputs} />
        </>
    );
}


//  FOR MEASURE

export const Measure_TextArea = () => {

    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';
    //  will have to work on height calc
    const height = '95px';
    const inputs:inputsTypes[] = [
        {id: 0, label : 'Remarks',},
    ]

    // const [data,error,loading] = useFetch(`api/HrEmp/FormAssignmentCategories`);
    // if (error) {
    //     console.log(error);
    // }
    return (
        <>
            <Performance_textarea_template  textheight={height}  inputs={inputs} />
        </>
    );
}

// FOR TARGET

export const Target_TextArea = () => {

    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';
    //  will have to work on height calc
    const height = '95px';
    const inputs:inputsTypes[] = [
        {id: 0, label : 'Remarks',},
    ]

    // const [data,error,loading] = useFetch(`api/HrEmp/FormAssignmentCategories`);
    // if (error) {
    //     console.log(error);
    // }
    return (
        <>
            <Performance_textarea_template  textheight={height}  inputs={inputs} />
        </>
    );
}

// FOR PERSPECTIVE ORDER

export const Perspective_Order_Input = () => {

    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';
    const labelCol = 6
    const inputs:inputsTypes[] = [
        {id: 0, label : 'Order',},
        
        
    ]

    // const [data,error,loading] = useFetch(`api/HrEmp/FormAssignmentCategories`);
    // if (error) {
    //     console.log(error);
    // }
    return (
        <>
            <Performance_input_template   inputs={inputs} labelCol={labelCol} />
        </>
    );
}

export const Perspective_mini_TextArea = () => {

    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';
    //  will have to work on height calc
    const height = '65px';
    const labelCol = 2
    const inputs:inputsTypes[] = [
        {id: 0, label : 'Questions Answered',},
    ]


    // const [data,error,loading] = useFetch(`api/HrEmp/FormAssignmentCategories`);
    // if (error) {
    //     console.log(error);
    // }
    return (
        <>
            <Performance_textarea_template  textheight={height}  inputs={inputs} labelCol={labelCol} />
        </>
    );
}

export const Perspective_TextArea = () => {

    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';
    //  will have to work on height calc
    const height = '95px';
    const inputs:inputsTypes[] = [
        {id: 0, label : 'Remarks',},
    ]

    const labelCol = 2
    // const [data,error,loading] = useFetch(`api/HrEmp/FormAssignmentCategories`);
    // if (error) {
    //     console.log(error);
    // }
    return (
        <>
            <Performance_textarea_template  textheight={height}  inputs={inputs} labelCol={labelCol} />
        </>
    );
}


// FOR STRATEGIC OBJECTIVES

export const Objective_Input = () => {

    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';
    const labelCol = 2
   
    const inputs:inputsTypes[] = [
        {id: 0, label : 'Strategic Objective',},
        {id: 1, label : 'Short Name',},
        
        
    ]

    // const [data,error,loading] = useFetch(`api/HrEmp/FormAssignmentCategories`);
    // if (error) {
    //     console.log(error);
    // }
    return (
        <>
            <Performance_input_template   inputs={inputs} labelCol={labelCol} />
        </>
    );
}

export const Objective_mini_TextArea = () => {

    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';
    //  will have to work on height calc
    const height = '40px';
    const labelCol = 2
    const inputs:inputsTypes[] = [
        {id: 0, label : 'Description',},
        {id: 1, label : 'Outcome',},
    ]


    // const [data,error,loading] = useFetch(`api/HrEmp/FormAssignmentCategories`);
    // if (error) {
    //     console.log(error);
    // }
    return (
        <>
            <Performance_textarea_template  textheight={height}  inputs={inputs} labelCol={labelCol} />
        </>
    );
}


export const Objective_TextArea = () => {

    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';
    //  will have to work on height calc
    const height = '75px';
    const labelCol = 2
    const inputs:inputsTypes[] = [
        {id: 0, label : 'Remarks',},
        
    ]


    // const [data,error,loading] = useFetch(`api/HrEmp/FormAssignmentCategories`);
    // if (error) {
    //     console.log(error);
    // }
    return (
        <>
            <Performance_textarea_template  textheight={height}  inputs={inputs} labelCol={labelCol} />
        </>
    );
}


export const Objective_Team_Date = () => {

    const labelCol = 2
    const inputs:inputsTypes[] = [
        {id: 0, label : 'Date',},
       
        
    ]

    return (
        <>
            <Performance_date_template   inputs={inputs} labelCol={labelCol}/>
        </>
    );
}

export const Objective_Team_TextArea = () => {

    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';
    //  will have to work on height calc
    const height = '160px';
    const labelCol = 2
    const inputs:inputsTypes[] = [
        {id: 0, label : 'Remarks',},
        
    ]


    // const [data,error,loading] = useFetch(`api/HrEmp/FormAssignmentCategories`);
    // if (error) {
    //     console.log(error);
    // }
    return (
        <>
            <Performance_textarea_template  textheight={height}  inputs={inputs} labelCol={labelCol} />
        </>
    );
}


export const Objective_Destination_Date = () => {

    const labelCol = 2
    const inputs:inputsTypes[] = [
        {id: 0, label : 'Date',},
       
        
    ]

    return (
        <>
            <Performance_date_template   inputs={inputs} labelCol={labelCol}/>
        </>
    );
}

export const Objective_Destination_Mini_TextArea = () => {

    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';
    //  will have to work on height calc
    const height = '46px';
    const labelCol = 2
    const inputs:inputsTypes[] = [
        {id: 0, label : 'From',},
        {id: 1, label : 'To',},
        
        
    ]


    // const [data,error,loading] = useFetch(`api/HrEmp/FormAssignmentCategories`);
    // if (error) {
    //     console.log(error);
    // }
    return (
        <>
            <Performance_textarea_template  textheight={height}  inputs={inputs} labelCol={labelCol} />
        </>
    );
}

export const Objective_Destination_TextArea = () => {

    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';
    //  will have to work on height calc
    const height = '95px';
    const labelCol = 2
    const inputs:inputsTypes[] = [
        {id: 0, label : 'Remarks',},
    
        
        
    ]


    // const [data,error,loading] = useFetch(`api/HrEmp/FormAssignmentCategories`);
    // if (error) {
    //     console.log(error);
    // }
    return (
        <>
            <Performance_textarea_template  textheight={height}  inputs={inputs} labelCol={labelCol} />
        </>
    );
}

// FOR MISSION & VISION 

export const Mission_Vision_Date = () => {

    const labelCol = 1
    const inputs:inputsTypes[] = [
        {id: 0, label : 'Date',},
       
        
    ]

    return (
        <>
            <Performance_date_template   inputs={inputs} labelCol={labelCol}/>
        </>
    );
}

export const Mission_Vision_Mini_TextArea = () => {

    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';
    //  will have to work on height calc
    const height = '46px';
    const labelCol = 1
    const inputs:inputsTypes[] = [
        {id: 0, label : 'Mission',},
        {id: 1, label : 'Vision',},
        
        
    ]


    // const [data,error,loading] = useFetch(`api/HrEmp/FormAssignmentCategories`);
    // if (error) {
    //     console.log(error);
    // }
    return (
        <>
            <Performance_textarea_template  textheight={height}  inputs={inputs} labelCol={labelCol} />
        </>
    );
}


export const Mission_Vision_Mini_extra_TextArea = () => {

    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';
    //  will have to work on height calc
    const height = '46px';
    const labelCol = 0.5
    const inputs:inputsTypes[] = [
        {id: 0, label : '',},
        {id: 1, label : '',},
        
        
    ]


    // const [data,error,loading] = useFetch(`api/HrEmp/FormAssignmentCategories`);
    // if (error) {
    //     console.log(error);
    // }
    return (
        <>
            <Performance_textarea_template  textheight={height}  inputs={inputs} labelCol={labelCol} />
        </>
    );
}

export const Mission_Vision_TextArea = () => {

    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';
    //  will have to work on height calc
    const height = '106px';
    const labelCol = 0
    const inputs:inputsTypes[] = [
        {id: 0, label : 'Remarks',},
        
        
        
    ]


    // const [data,error,loading] = useFetch(`api/HrEmp/FormAssignmentCategories`);
    // if (error) {
    //     console.log(error);
    // }
    return (
        <>
            <Performance_textarea_template  textheight={height}  inputs={inputs} labelCol={labelCol} />
        </>
    );
}

export const Mission_Vision_Input = () => {

    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';
    const labelCol = 1
   
    const inputs:inputsTypes[] = [
        
        {id: 1, label : 'Name',},
        
        
        
    ]

    // const [data,error,loading] = useFetch(`api/HrEmp/FormAssignmentCategories`);
    // if (error) {
    //     console.log(error);
    // }
    return (
        <>
            <Performance_input_template   inputs={inputs} labelCol={labelCol} />
        </>
    );
}

export const Mission_Vision_extra_Input = () => {

    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';
    const labelCol = 0.5
   
    const inputs:inputsTypes[] = [
        
        {id: 1, label: ''},
        
        
        
    ]

    // const [data,error,loading] = useFetch(`api/HrEmp/FormAssignmentCategories`);
    // if (error) {
    //     console.log(error);
    // }
    return (
        <>
            <Performance_input_template   inputs={inputs} labelCol={labelCol} />
        </>
    );
}


// FOR STRATEGIC THEME

export const Theme_Input = () => {

    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';
    const labelCol = 1
   
    const inputs:inputsTypes[] = [
        {id: 0, label : 'Strategic Theme',},
        {id: 1, label : 'Short Name',},
        
        
    ]

    // const [data,error,loading] = useFetch(`api/HrEmp/FormAssignmentCategories`);
    // if (error) {
    //     console.log(error);
    // }
    return (
        <>
            <Performance_input_template   inputs={inputs} labelCol={labelCol} />
        </>
    );
}

export const Theme_mini_TextArea = () => {

    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';
    //  will have to work on height calc
    const height = '40px';
    const labelCol = 1
    const inputs:inputsTypes[] = [
        {id: 0, label : 'Commentary',},
        
    ]


    // const [data,error,loading] = useFetch(`api/HrEmp/FormAssignmentCategories`);
    // if (error) {
    //     console.log(error);
    // }
    return (
        <>
            <Performance_textarea_template  textheight={height}  inputs={inputs} labelCol={labelCol} />
        </>
    );
}


export const Theme_TextArea = () => {

    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';
    //  will have to work on height calc
    const height = '75px';
    const labelCol = 1
    const inputs:inputsTypes[] = [
        {id: 0, label : 'Remarks',},
        
    ]


    // const [data,error,loading] = useFetch(`api/HrEmp/FormAssignmentCategories`);
    // if (error) {
    //     console.log(error);
    // }
    return (
        <>
            <Performance_textarea_template  textheight={height}  inputs={inputs} labelCol={labelCol} />
        </>
    );
}

export const Theme_Members_Date = () => {

    const labelCol = 2
    const inputs:inputsTypes[] = [
        {id: 0, label : 'Date',},
       
        
    ]

    return (
        <>
            <Performance_date_template   inputs={inputs} labelCol={labelCol}/>
        </>
    );
}

export const Theme_Members_TextArea = () => {

    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';
    //  will have to work on height calc
    const height = '160px';
    const labelCol = 2
    const inputs:inputsTypes[] = [
        {id: 0, label : 'Remarks',},
        
    ]


    // const [data,error,loading] = useFetch(`api/HrEmp/FormAssignmentCategories`);
    // if (error) {
    //     console.log(error);
    // }
    return (
        <>
            <Performance_textarea_template  textheight={height}  inputs={inputs} labelCol={labelCol} />
        </>
    );
}

export const Theme_Result_Mini_TextArea = () => {

    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';
    //  will have to work on height calc
    const height = '46px';
    const labelCol = 2
    const inputs:inputsTypes[] = [
        {id: 0, label : 'Result',},
        {id: 1, label : 'Short Name',},
        {id: 2, label : 'Commentary',},
        
        
        
    ]


    // const [data,error,loading] = useFetch(`api/HrEmp/FormAssignmentCategories`);
    // if (error) {
    //     console.log(error);
    // }
    return (
        <>
            <Performance_textarea_template  textheight={height}  inputs={inputs} labelCol={labelCol} />
        </>
    );
}

export const Theme_Result_TextArea = () => {

    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';
    //  will have to work on height calc
    const height = '65px';
    const labelCol = 2
    const inputs:inputsTypes[] = [
        {id: 0, label : 'Remarks',},
    
        
        
    ]


    // const [data,error,loading] = useFetch(`api/HrEmp/FormAssignmentCategories`);
    // if (error) {
    //     console.log(error);
    // }
    return (
        <>
            <Performance_textarea_template  textheight={height}  inputs={inputs} labelCol={labelCol} />
        </>
    );
}

export const Theme_Result_Order_Input = () => {

    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';
    const labelCol = 1
    const wrapperCol = 2
   
    const inputs:inputsTypes[] = [
        {id: 0, label : 'Order',},
        
        
        
    ]

    // const [data,error,loading] = useFetch(`api/HrEmp/FormAssignmentCategories`);
    // if (error) {
    //     console.log(error);
    // }
    return (
        <>
            <Performance_input_template   inputs={inputs} labelCol={labelCol} wrapperCol={wrapperCol} />
        </>
    );
}

export const Theme_Inclusion_Exclusion_Date = ({onChange, disabled, value}:any) => {

    const labelCol = 2
    const wrapperCol = 10
    const inputs:inputsTypes[] = [
        {id: 0, label : 'Date',},
       
        
    ]

    return (
        <>
            <Performance_date_template onChange={onChange} disabled={disabled} value={value}   inputs={inputs} labelCol={labelCol} wrapperCol={wrapperCol}/>
        </>
    );
}

export const Theme_Inclusion_Exclusion_Mini_TextArea = () => {

    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';
    //  will have to work on height calc
    const height = '65px';
    const labelCol = 2
    const inputs:inputsTypes[] = [
        {id: 0, label : 'Item',},
       
        
        
        
    ]


    // const [data,error,loading] = useFetch(`api/HrEmp/FormAssignmentCategories`);
    // if (error) {
    //     console.log(error);
    // }
    return (
        <>
            <Performance_textarea_template  textheight={height}  inputs={inputs} labelCol={labelCol} />
        </>
    );
}

export const Theme_Inclusion_Exclusion_TextArea = () => {

    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';
    //  will have to work on height calc
    const height = '90px';
    const labelCol = 2
    const inputs:inputsTypes[] = [
        {id: 0, label : 'Remarks',},
    
        
        
    ]


    // const [data,error,loading] = useFetch(`api/HrEmp/FormAssignmentCategories`);
    // if (error) {
    //     console.log(error);
    // }
    return (
        <>
            <Performance_textarea_template  textheight={height}  inputs={inputs} labelCol={labelCol} />
        </>
    );
}



export const Theme_tab5_Mini_TextArea = () => {

    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';
    //  will have to work on height calc
    const height = '65px';
    const labelCol = 4
    const inputs:inputsTypes[] = [
        {id: 0, label : 'Success Measure',},
       
        
        
        
    ]


    // const [data,error,loading] = useFetch(`api/HrEmp/FormAssignmentCategories`);
    // if (error) {
    //     console.log(error);
    // }
    return (
        <>
            <Performance_textarea_template  textheight={height}  inputs={inputs} labelCol={labelCol} />
        </>
    );
}

export const Theme_tab5_TextArea = () => {

    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';
    //  will have to work on height calc
    const height = '170px';
    const labelCol = 4
    const inputs:inputsTypes[] = [
        {id: 0, label : 'Remarks',},
    
        
        
    ]


    // const [data,error,loading] = useFetch(`api/HrEmp/FormAssignmentCategories`);
    // if (error) {
    //     console.log(error);
    // }
    return (
        <>
            <Performance_textarea_template  textheight={height}  inputs={inputs} labelCol={labelCol} />
        </>
    );
}


export const Theme_tab6_Mini_TextArea = () => {

    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';
    //  will have to work on height calc
    const height = '65px';
    const labelCol = 4
    const inputs:inputsTypes[] = [
        {id: 0, label : 'How will it Move Us?',},
       
        
        
        
    ]


    // const [data,error,loading] = useFetch(`api/HrEmp/FormAssignmentCategories`);
    // if (error) {
    //     console.log(error);
    // }
    return (
        <>
            <Performance_textarea_template  textheight={height}  inputs={inputs} labelCol={labelCol} />
        </>
    );
}

export const Theme_tab6_TextArea = () => {

    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';
    //  will have to work on height calc
    const height = '170px';
    const labelCol = 4
    const inputs:inputsTypes[] = [
        {id: 0, label : 'Remarks',},
    
        
        
    ]


    // const [data,error,loading] = useFetch(`api/HrEmp/FormAssignmentCategories`);
    // if (error) {
    //     console.log(error);
    // }
    return (
        <>
            <Performance_textarea_template  textheight={height}  inputs={inputs} labelCol={labelCol} />
        </>
    );
}

export const SIA_Right_Input = () => {

    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';
    const labelCol = 1
   
    const inputs:inputsTypes[] = [
        
        {id: 1, label: 'Stakeholders'},
        
        
        
    ]

    // const [data,error,loading] = useFetch(`api/HrEmp/FormAssignmentCategories`);
    // if (error) {
    //     console.log(error);
    // }
    return (
        <>
            <Performance_input_template   inputs={inputs} labelCol={labelCol} />
        </>
    );
}

export const SIA_TargFreq_Input = () => {

    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';
    const labelCol = 7
    
   
    const inputs:inputsTypes[] = [
        
        {id: 0, label: 'Targ.Freq'},
        
        
        
    ]

    // const [data,error,loading] = useFetch(`api/HrEmp/FormAssignmentCategories`);
    // if (error) {
    //     console.log(error);
    // }
    return (
        <>
            <Performance_input_template   inputs={inputs} labelCol={labelCol}  />
        </>
    );
}


export const SIA_TextArea = () => {

    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';
    //  will have to work on height calc
    const height = '65px';
    const labelCol = 1
    const inputs:inputsTypes[] = [
        {id: 0, label : 'Remarks',},
       
        
        
        
    ]


    // const [data,error,loading] = useFetch(`api/HrEmp/FormAssignmentCategories`);
    // if (error) {
    //     console.log(error);
    // }
    return (
        <>
            <Performance_textarea_template  textheight={height}  inputs={inputs} labelCol={labelCol} />
        </>
    );
}