import axios from "axios";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import {
  setValue,
  tier1SaveOnclick,
  tier1UpdateOnclick,
} from "../../../../features/forms/tier1/createSlice";
import { setGeneralValue } from "../../../../features/generalSlice";
import useFetch from "../../../../hooks/useFetch";
import { ModalTemplate } from "../../../../templates/modal";
import {
  validationsData,
  saveModalData,
  updateModalData,
  api_url,
} from "../../../accessories/component_infos";
import CustomLoader from "../../../accessories/custom-loader";
import Setups_Template from "../../../human-resource/setups/.setups_templates/Setups_Template";
import {
  exprsTypes,
  modalTypes,
  searchTextTypes,
} from "../../../human-resource/setups/data/types/selectsTypes";
import { performance_deptinitiatives_columns } from "./data/deptInitiativesData";
import dayjs from "dayjs";
import { PostDataFunc  } from "../../../../functions/post";

export const Perf_setups_deptInitiatives = forwardRef(({ a }: any, ref) => {
  const saveSuccessText = {
    message: "Department Inititiave added Successfully",
    ok: "Ok",
    title: "Success",
  }; // successful save modal message
  const updateSuccessText = {
    message: "Department Inititiave updated Successfully",
    ok: "Ok",
    title: "Success",
  }; // successful update modal message

  // store data expressions[unique] ...dependent on data coming in
  const idExpr: string = "dinIdpk";
  const nameExpr: string = "dinName";
  const shortnameExpr: string = "dinShtName";
  const orderExpr: string = "dinOrder";
  const activeExpr: string = "dinActive";
  const remarksExpr: string = "dinRmks";
  const inputsExprsData: any = {
    idExpr: idExpr,
    nameExpr: nameExpr,
    shortnameExpr: shortnameExpr,
    orderExpr: orderExpr,
    activeExpr,
    remarksExpr: remarksExpr,
  };

  const storeExprs = () => {
    const exprs = [
      { expr: "existsUrl", value: `DeptInitiatives` },
      { expr: "idExpr", value: idExpr },
      { expr: "nameExpr", value: nameExpr },
      { expr: "shortnameExpr", value: shortnameExpr },
    ];
    //to particular redux store based on form type
    for (let i = 0; i < exprs.length; i++) {
      dispatch(setValue({ expr: exprs[i].expr, value: exprs[i].value }));
    }

    // to general slice
    const generalExprs: exprsTypes[] = [
      { expr: "inputExprs", value: inputsExprsData },
    ];
    for (let i = 0; i < generalExprs.length; i++) {
      dispatch(
        setGeneralValue({
          expr: generalExprs[i].expr,
          value: generalExprs[i].value,
        })
      );
    }
  };

  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState<boolean>(false); //refresh data state for data creation or update
  const activeStatus = useSelector(
    (state: RootState) => state.general.activeStatus
  );
  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });
  const [status, setStatus] = useState<boolean | string>("");

  //modal states
  //--general--
  const triggerSuccessModal = useSelector(
    (state: RootState) => state.tier1Add.successModalState
  ); //trigger success modal
  const SuccessModalText = useSelector(
    (state: RootState) => state.tier1Add.successText
  ); //success modal text

  //--for backend errors--
  const [errState, setErrState] = useState<boolean>(false);
  const [errTitle, seterrTitle] = useState<string>("");
  const [errMessage, seterrMessage] = useState<string>("");

  //--warning modal--
  const triggerwarningModal = useSelector(
    (state: RootState) => state.tier1Add.warningState
  ); //trigger warning
  const warningInfos: any = validationsData; //warning type details
  const warningDataExpr = useSelector(
    (state: RootState) => state.tier1Add.warningDataExpr
  ); //warning type selector

  //--save data modal--
  const saveData = saveModalData; //save modal details[data]
  const triggersaveModal = useSelector(
    (state: RootState) => state.tier1Add.saveDataOnclick
  ); //trigger save modal

  //--Update data modal--
  const updateIndInfos = updateModalData; //update modal details[data]
  const triggerupdateModal: boolean = useSelector(
    (state: RootState) => state.tier1Add.updateDataOnclick
  ); //trigger update question

  // on page resize height responsiveness
  const [trigger, setTrigger] = useState<boolean>(false);
  useImperativeHandle(ref, () => ({
    test: () => {
      setTrigger(!trigger);
    },
  }));
  // sys date
  const editDate = dayjs().format();

  // get current values from  [slice dependent on form type]
  const name = useSelector((state: RootState) => state.tier1Add.name);
  const shortname = useSelector((state: RootState) => state.tier1Add.shortname);
  const order = useSelector((state: RootState) => state.tier1Add.order);
  const active = useSelector((state: RootState) => state.tier1Add.active);
  const remarks = useSelector((state: RootState) => state.tier1Add.remarks);
  const selectedID = useSelector(
    (state: RootState) => state.general.SelectedFieldID
  );

  //post form Data
  //validate data
  const validateDataSave = () => {
    dispatch(tier1SaveOnclick());
    // postData();
  };
  // post
  const postData = async () => {
    dispatch(tier1SaveOnclick());
    // dispatch(setValue({ expr: "saveDataOnclick", value: false })); //disable modal
    try {
      await PostDataFunc (`DeptInitiatives/CreateDeptInitiative`, {
        dinName: name,
        dinShtName: shortname,
        dinActive: active,
        dinRmks: remarks,
        dinOrder: order,
        dinCreatedBy: "1",
      });
      setRefresh(!refresh);
      // dispatch(setValue({ expr:'saveDataOnclick', value: false}));
      dispatch(setValue({expr:'successModalState', value: true}));
      dispatch(setValue({expr:"successText", value:saveSuccessText}))
      // const exprs: exprsTypes[] = [
      //   { expr: "saveDataOnclick", value: false },
      //   { expr: "successModalState", value: true },
      //   { expr: "successText", value: saveSuccessText },
      // ]
      // for (let i = 0; i < exprs.length; i++) {
      //   dispatch(setValue({ expr: exprs[i].expr, value: exprs[i].value }));
      // }
    } catch (error: any) {
      //set states for db errors modal
      console.log(error);
      setErrState(true);
      seterrTitle(
        error.response ? error.response.data.message : "Server Error"
      );
      seterrMessage(
        error.response
          ? error.response.data.errors[0]
          : "Error connecting to server. Please contact system administrator"
      );
    }
  };

  //Update Form data
  //validate data
  const validateDataUpdate = () => {
    dispatch(tier1UpdateOnclick());
    UpdateData();
  };
  // update
  const UpdateData = async () => {
    dispatch(setValue({ expr: "updateDataOnclick", value: false })); //disable modal
    try {
      const res = await axios.put(
        `${api_url}/PfmDepartmentInitiatives/UpdateDeptInitiative`,
        {
          dinIdpk: selectedID,
          dinName: name,
          dinShtName: shortname,
          dinActive: true,
          dinOrder: order,
          dinRmks: remarks,
          dinEditedBy: 1,
          dinLastEditedDate: editDate,
        }
      );

      setRefresh(!refresh);

      const exprs: exprsTypes[] = [
        { expr: "updateDataOnclick", value: false },
        { expr: "successModalState", value: true },
        { expr: "successText", value: updateSuccessText },
      ];
      for (let i = 0; i < exprs.length; i++) {
        dispatch(setValue({ expr: exprs[i].expr, value: exprs[i].value }));
      }
    } catch (error: any) {
      //set states for db errors modal
      setErrState(true);
      seterrTitle(error.response.data.message);
      seterrMessage(error.response.data.errors[0]);
    }
  };

  //modals data
  const modals: modalTypes[] = [
    //warning
    {
      disableCancel: true,
      icon: "warning",
      open: triggerwarningModal,
      okHandler: () =>
        dispatch(setValue({ expr: "warningState", value: false })),
      cancelHandler: () =>
        dispatch(setValue({ expr: "warningState", value: false })),
      message: warningInfos[warningDataExpr!].message,
      cancelText: warningInfos[warningDataExpr!].cancelText,
      okText: warningInfos[warningDataExpr!].okText,
      title: warningInfos[warningDataExpr!].title,
    },
    //backend error
    {
      disableCancel: true,
      icon: "warning",
      open: errState,
      okHandler: () => setErrState(false),
      cancelHandler: () => setErrState(false),
      message: errMessage,
      cancelText: "",
      okText: "Ok",
      title: errTitle,
    },
    //success
    {
      disableCancel: true,
      icon: "success",
      open: triggerSuccessModal,
      okHandler: () =>
        dispatch(setValue({ expr: "successModalState", value: false })),
      cancelHandler: () =>
        dispatch(setValue({ expr: "successModalState", value: false })),
      message: SuccessModalText.message,
      okText: SuccessModalText.okText,
      title: SuccessModalText.title,
    },
    //save
    {
      disableCancel: false,
      icon: "question",
      open: triggersaveModal,
      okHandler: () => postData(),
      cancelHandler: () =>
        dispatch(setValue({ expr: "saveDataOnclick", value: false })),
      message: saveData.message,
      cancelText: saveData.cancelText,
      okText: saveData.okText,
      title: saveData.title,
    },
    //Update
    {
      disableCancel: false,
      icon: "question",
      open: triggerupdateModal,
      okHandler: () => UpdateData(),
      cancelHandler: () =>
        dispatch(setValue({ expr: "updateDataOnclick", value: false })),
      message: updateIndInfos.message,
      cancelText: updateIndInfos.cancelText,
      okText: updateIndInfos.okText,
      title: updateIndInfos.title,
    },
  ];
 
  const [data, error, loading] = useFetch(
   `DeptInitiatives/SearchDepartmentInitiative?departmentName=${searchText.text}&activeState=${status}`,
    refresh
  );

  if (data) {
    storeExprs(); // run func to store expressions on store
    dispatch(setGeneralValue({ expr: "idExpr", value: idExpr })); // dispatch fetched data to reduxstore
    dispatch(setGeneralValue({ expr: "formData", value: data })); // dispatch fetched data to reduxstore
  }

  // form parameters
  const form_header = "Department Initiative Details";
  console.log("hahahahahah:", data);
  return (
    <>
      {/* modals */}
      {modals.map(
        (
          {
            disableCancel,
            icon,
            okHandler,
            cancelHandler,
            open,
            cancelText,
            okText,
            message,
            title,
          }: modalTypes,
          index
        ) => {
          return (
            <div key={index}>
              <ModalTemplate
                icon={icon}
                disableCancel={disableCancel}
                cancelText={cancelText}
                open={open}
                okHandler={okHandler}
                cancelHandler={cancelHandler}
                message={message}
                okText={okText}
                title={title}
              />
            </div>
          );
        }
      )}
      {loading && <CustomLoader />}
      <Setups_Template
        createData={validateDataSave}
        updateData={validateDataUpdate}
        trigger={trigger}
        datagridClicked={() => {}}
        formTier="1"
        datagridColumns={performance_deptinitiatives_columns}
        datagridData={data}
        formHeader={form_header}
        searchTextChange={(txt) => {
          setSearchText({ temp: txt, text: searchText.text });
        }}
        handleSearch={() => {
          setSearchText({ temp: searchText.temp, text: searchText.temp });
          setStatus(activeStatus);
        }}
      />
    </>
  );
});
