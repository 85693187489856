import { maintenance_checklist } from "../data/mini_datagrid_cols";
import { useCallback, useEffect, useState } from "react";
import useFetch from "../../../../../../hooks/useFetch";
import Datagrid_template from "../../../../../../templates/Datagrid";
import { setGeneralValue } from "../../../../../../features/generalSlice";
import { useDispatch, useSelector } from "react-redux";
import { Mini_datagrid_template } from "../data/mini_datagrid";
import { on } from "events";
import { RootState } from "../../../../../../app/store";
import { UpdateStates } from "../../../../../../functions/updateStates";
interface props {
  submit?: any;
  selectedRecord?: any;
  updateStates?: (key: string, val: any) => void;
  statesx?: any;
}

export const Activities_Capture_Details = ({
  selectedRecord,
  submit,
  updateStates,
  statesx,
}: props) => {
  const dispatch = useDispatch();
  const selectedMaintainanceChecklist = useSelector(
    (state: RootState) => state.general.maintenanceChecklist
  );
  console.log("filteredRecords", selectedMaintainanceChecklist);
  // Modal dialog data states

  const [refreshValue, setRefreshValue] = useState(false);
  const [maintCheck_List, setMaintCheck_List] = useState<any>([]);
  const maintenanceType = useSelector(
    (state: RootState) => state.general.maintenanceType
  );
  const RegNo = useSelector((state: RootState) => state.general.RegNo);
  const [
    maintenanceChecklistData,
    maintenanceChecklistErr,
    maintenanceChecklistLoading,
  ] = useFetch(
    `PmActivitiesCaptures/GetAllPmMaintenaceCheckList?PmaIDpk=${selectedRecord?.pmaIDpk}`,
    refreshValue,
    "mtiIDpk"
  );
  const [model_id, model_id_error, model_id_loading] = useFetch(
    `PmActivitiesCaptures/GetAllVwAsmTmsVehicleDetail3IDs?vdtRegNo=${RegNo?.name}`
  );
  console.log("model_id", model_id[0]?.mdlIdpk);
  const [
    maintenance_model,
    maintenance_model_error,
    maintenance_model_loading,
  ] = useFetch(
    `PmActivitiesCaptures/CapturesGetMtiIDpk?MaintenanceType=${"Preventive Maintenance"}&modelIDx=${
      model_id[0]?.mdlIdpk
    }&FormId=${1}`,
    model_id[0]?.mdlIdpk
  );
  console.log("maintenance_model", maintenance_model);

  const [maintenanceModel, setMaintenanceModel] = useState([]);
  const [maintenanceCheck, setMaintenanceCheck] = useState([]);
  console.log("maintenanceCheck",maintenanceCheck)
  console.log("SavedItems",maintenance_model)
  useEffect(() => {
    setRefreshValue(!refreshValue);
    console.log(`selectedRecord`);
  }, [selectedRecord]);

  // --------------------------------------------------------------------
  // const onChangeRecord: any = useCallback<any>((e: any) => {
  //   e.component.byKey(e.currentSelectedRowKeys[0]).done((item: any) => {
  //     // setSelectedTask_update(item);
  //     dispatch(
  //       setGeneralValue({ expr: "maintenanceChecklist", value: [item] })
  //     );
  //   });
  // }, []);
  const addedCols = {
    padFaulty: false,
    padReplaced: false,
  };
  const modMaintList = async () => {
    console.log("&^% maint list", maintenance_model);
    if (maintenance_model.length > 0)
      setMaintCheck_List(
        maintenance_model.forEach((item: any) => Object.assign(item, addedCols))
      );
  };
  useEffect(() => {
    modMaintList();

    setMaintenanceModel(appendColumns(maintenance_model));
    setMaintenanceCheck(maintenanceChecklistData);
  }, [maintenance_model, maintenanceChecklistData]);

  const appendColumns = (data: any[]) => {
    const temp: any = [...data];

    for (let i = 0; i < temp.length; i++) {
      // Add new properties to each object in the array
      temp[i] = {
        ...temp[i],
        padFaulty: false,
        padReplaced: false,
        pidRmks: "",
      };
    }
    return temp;
  };

  return (
    <div>
      <div className="p-2">
        {/* < Mini_datagrid_template
      columns={maintenance_checklist}
      data={maintenance_model}
      gridheight={436}
      onChangeRecord={onChangeRecord}
      // updateMode={allowUpdate}
      /> */}
        <Datagrid_template
          gridheight={436}
          columns={maintenance_checklist}
          // data={appendColumns(maintenance_model)}//
          data={
            statesx?.isGridData === true
              ? maintenanceCheck
              : maintenanceModel
          } //
          disablePaging={true}
          disableGroupPanel
          deselectFirstRow
          allowColumnEditing
          showSelectionCheckBox="always"
          selectionMode="multiple"
          disableSearch
          onRowUpdated={(e) => {
            const filteredRecord = maintenanceModel?.map((r) => {
              return e.data?.mtiIDpk === r.mtiIDpk ? { ...r, ...e.data } : r;
            });
            setMaintenanceModel(filteredRecord);
            const records = selectedMaintainanceChecklist?.map((v) =>
              v.mtiIDpk === e.data?.mtiIDpk ? { ...v, ...e.data } : v
            );
            const maintenanceData = maintenanceCheck?.map((r) => {
              return e.data?.mtiIDpk === r.mtiIDpk ? { ...r, ...e.data } : r;
            });
            const savedItems = maintenanceChecklistData?.map((v) =>
              v.mtiIDpk === e.data?.mtiIDpk ? { ...v, ...e.data } : v
            );
            setMaintenanceCheck(maintenanceData);
            dispatch(
              setGeneralValue({ expr: "maintenanceChecklist", value: records })
            );
            dispatch(
              setGeneralValue({ expr: "savedItems", value: savedItems })
            );
          }}
          selectedItemsChange={(data) => {
            const filteredRecord = maintenanceModel?.map((r) => {
              const matchedItem = data?.find((e) => e.mtiIDpk === r.mtiIDpk);
              return matchedItem ? { ...r, ...matchedItem } : r;
            });
            const maintenanceData = maintenanceCheck?.map((r) => {
              const matchedItem = data?.find((e) => e.mtiIDpk === r.mtiIDpk);
              return matchedItem ? { ...r, ...matchedItem } : r;
            });
            setMaintenanceModel(filteredRecord);
            setMaintenanceCheck(maintenanceData);
            dispatch(
              setGeneralValue({ expr: "maintenanceChecklist", value: data })
            );
            dispatch(
              setGeneralValue({ expr: "savedItems", value: data })
            );
          }}
        />
      </div>
    </div>
  );
};
