/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-pascal-case */
import { useEffect, useRef, useState } from "react";
// import type { SelectProps } from 'antd';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Maintenance_activities_Job_Details } from "./widgets/Maintenance_activity_Job_details";
import { TabPanel, Tooltip } from "devextreme-react";
import { Item } from "devextreme-react/accordion";
import { Maintenance_activities_Materials_Details } from "./widgets/Maintenance_activities_maerials";
import { Maintenance_activities_update } from "./widgets/Maintenance_activities_updates";
import { Maintenance_activitiestoolbar } from "../../../../../accessories/custom-toolbars/maintenance_activitiestoolbar";
import { Maintenance_Activities_tab2_Details } from "./widgets/Maintenance_activities_tab2";
import useFetch from "../../../../../../hooks/useFetch";
import {
  currentDate,
  navbar_height,
  startingDate,
} from "../../../../../accessories/component_infos";
import { footer_datagrid_cols } from "./data/datagrid_cols";
import { Right_Share } from "./widgets/rightPane/share";
import { Right_Attachments } from "./widgets/rightPane/attachments";
import { Right_Comment } from "./widgets/rightPane/comments";
import { ModalTemplate } from "../../../../../../templates/modal";
import { RootState } from "../../../../../../app/store";
import { useDispatch, useSelector } from "react-redux";
import {
  setGeneralValue,
  settask_footer_update,
} from "../../../../../../features/generalSlice";
import { Job_ac, Job_cols, Materials_cols } from "./data/mini_datagrid_cols";
import { Checkbox, Form } from "antd";
import { Maintenance_Activities_Details_Form } from "./widgets/Maintenance_activity_detail_form";
import Datagrid_template from "../../../../../../templates/Datagrid";
import {
  searchCriteriaTypes,
  searchTextTypes,
  statusTypes,
} from "../../../../../human-resource/setups/data/types/selectsTypes";
import dayjs from "dayjs";
import analysis from "../../../../../../assets/3dline.png";
import { useAccountInfo } from "../../../../../../hooks/useAccountInfo";
import { WarningAlert } from "../../../../../accessories/warningAlert";
import CustomLoader from "../../../../../accessories/custom-loader";
import useResizeMode from "../../../../../../hooks/useResizeMode";
import {
  Cancel,
  EditButton,
  NewButton,
  UpdateButton,
} from "../../../../../accessories/buttons";
import { useFormPriviledge } from "../../../../../../hooks/useFormPriviledge";
import { task_footer_update } from "../../../../../../features/Task&Todo/Task/TaskDetails";
import { useBusyLoader } from "../../../../../../hooks/useBusyLoader";
import { Right_Vehicle_Image } from "./widgets/rightPane/vehicle_images";
import { set } from "lodash";
import { UpdateStates } from "../../../../../../functions/updateStates";

interface props {
  state_change: boolean;
}

interface stateTypes {
  selectedTask_update?: any;
  openSelectedTask?: any;
  selectedRecord?: any;
  materialSelectedRecord?: any;
  JobSelectedRecord?: any;
  jobUpdateSelectedRecord?: any;
  open?: boolean;
  refresh?: boolean;
  // DBdata?: any;
  activityUpdate?: string;
  inputModal?: any;
  cancellationReason?: string;
  confirmState?: any;
}

export const Maintenance_Activities = ({ state_change }: props) => {
  const [employeeId, userId, userInfo] = useAccountInfo();
  //Access Priviledge

  const [privType, setPrivType] = useState<string>("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);

  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );

  const height = window.innerHeight - (navbar_height + news_flash_height + 0); //height without navbar
  const [formheight, set_form_height] = useState<number>(0);
  const [switch_change, setSwitch_change] = useState<boolean>(true);
  const form_disabled = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const update_state_change = useSelector(
    (state: RootState) => state.general.taskFooterUpdate
  );
  //Resizin
  const [initialH, fullH] = useResizeMode(switch_change);

  const formRef = useRef<any>(null);
  var fullPage = fullH - (navbar_height + news_flash_height + 105);
  var pageHeight =
    initialH - (navbar_height + news_flash_height + formheight + 35);

  const switch_toggle = () => {
    if (switch_change) {
      setSwitch_change(!switch_change);
    } else {
      setSwitch_change(!switch_change);
    }
  };
  const [paging, setPaging ] = useState<{pageNumber:number, pageSize:number}>({
    pageNumber : 1, // backend having issue with their offset counting, it should start from 0 but they started with 1 instead
    pageSize: 15
  })
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  // Double tap /click gesture

  const [flipSwitcher, setFlipSwitcher] = useState<boolean>(true);

  const switchRoutes = [
    {
      id: 0,
      name: "current_page_route",
      path: "/assets-manager/transport/entries/Maintenance/maintenance-activities",
    },
    { id: 1, name: "job-grid-table", path: "job-grid-table" },
    { id: 2, name: "material-grid-table", path: "material-grid-table" },
    { id: 3, name: "form-display", path: "form-display" },
    { id: 4, name: "form2-display", path: "form2-display" },
  ];

  const view = searchParams.get("view");

  const doubleClick_toggle = () => {
    if (
      flipSwitcher === false ||
      flipSwitcher === null ||
      flipSwitcher === undefined
    ) {
      // update forms view
      setFlipSwitcher(!flipSwitcher);
      navigate({
        pathname: switchRoutes[0].path,
      });
    } else {
      navigate({
        pathname: switchRoutes[0].path,
        search: createSearchParams({
          view: switchRoutes[2].path,
        }).toString(),
      });
      setFlipSwitcher(!flipSwitcher);
    }
  };

  const genFormat = "YYYY-MM-DD";
  const today = dayjs().endOf("month").format(genFormat);

  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });
  const [activeStatus, setActiveStatus] = useState<statusTypes>({
    temp: "",
    status: "",
  });
  const [isProgress, setIsProgrss] = useState<boolean>(true);
  const [isInformation, setInformation] = useState<boolean>(false);
  const [searchCriteria, setsearchCriteria] = useState<searchCriteriaTypes>({
    temp: 0,
    index: 0,
  });

  const [startDate, setStartDate] = useState<searchTextTypes>({
    text: startingDate,
    temp: startingDate,
  });
  const [endDate, setEndDate] = useState<searchTextTypes>({
    text: today,
    temp: today,
  });

  const searchCriteriaData = [
    { id: 0, value: "Reg No" },
    { id: 1, value: "Chassis Number" },
    { id: 2, value: "Maintenance Type" },
    { id: 3, value: "Vehicle Make" },
    { id: 4, value: "Vehicle Model" },
    { id: 5, value: "Manufacturer" },
    { id: 6, value: "Supplier" },
    { id: 7, value: "Manufacture Date" },
    { id: 8, value: "Supply Date" },
  ];

  // Onchange States
  const [states, setStates] = useState<stateTypes>({
    selectedTask_update: [],
    openSelectedTask: [],
    selectedRecord: [],
    JobSelectedRecord: [],
    materialSelectedRecord: {},
    jobUpdateSelectedRecord: [],
    open: true,
    refresh: false,
    inputModal: {
      state: false,
      title: "",
      message: "",
      event: () => {},
    },
    confirmState: {
      state: false,
      title: "",
      message: "",
      event: () => {},
    },
    cancellationReason: "",
  });
  console.log("%#$", states);
  const [refreshstate, setRefreshstate] = useState<boolean>(false);

  const [DBdata] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetEntAsmTsmMaintenanceActivityAll?startdate=${
      startDate.text
    }&enddate=${endDate.text}&active=${activeStatus.status}&search=${
      searchText.text
    }&criteriaIndex=${searchCriteria.index}&pagesize=${paging.pageSize}&pagenumber=${paging.pageNumber}`,
    refreshstate,
    "mtaIDpk"
  );

  // if (DBdata) {
  // } else {
  // }

  // Fxn to set states by passing parameters
  const setState = (key: string, value: any) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };

  const maintenanceRequestModal = {
    message: "Are you sure you want to open job card for this request?",
    title: "Record Selected?",
    okText: "Yes, open",
    cancelText: "No, Cancel",
  };

  const ProgressModal = {
    message:
      "This process might take some minutes to complete. Are you sure you want to proceed and load the data?",
    title: "Proceed?",
    okText: "Yes",
    cancelText: "No",
  };

  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(maintenanceRequestModal);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();

  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const [Job_data, Job_error, Job_loading] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetEntAsmTmsMaintenanceActiviityJobDetails?mtaIDpk=${states.selectedRecord?.mtaIDpk}`,
    refreshstate
  );
  // states.selectedRecord?.mtaIDpk
  const [matData, matError, matLoading] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetEntAsmTmsMaintenanceActiviityMaterials?MtaIDpk=${states.selectedRecord?.mtaIDpk}`,
    refreshstate
  );

  const [Job_updatesData, Job_updatesError, Job_updatesLoading] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetEntAsmTmsMaintenanceActivityJobUpdate?MtaIDpk=${states.selectedRecord?.mtaIDpk}`,
    refreshstate
  );

  const cancelRequest = async () => {
    setState("confirmState", {
      state: false,
      title: "",
      message: "",
      event: () => {},
    });
  };
  const validateCancel = () => {
    if (states.cancellationReason === "") {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message:
          "No Reason was entered for Cancelling the request. Please try again later and enter a reason",
        title: "Required",
        okText: "Ok",
        cancel: "No cancel",
      });
      return;
    }

    setModalData({
      icon: "question",
      state: true,
      title: "Cancel Request?",
      message: "Are you sure you want to continue and Cancel this request?",
      event: () => {
        cancelRequest();
      },
    });
  };

  // When Open button is clicked
  const open = () => {
    try {
      if (states.selectedTask_update?.length !== 0) {
        setIcon("question");
        setshowModalDisableButton(false);
        setModalData({
          message: "Are you sure you want to open this job card? (NB: please click on maintenance activities  tab to continue)",
          title: "Open selected job card? ",
          okText: "Yes, open",
        });
        setOpenModal(true);
        dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      } else {
        // modal here
        setIcon("warning");
        setshowModalDisableButton(true);
        setModalData({
          message: "Please select a record",
          title: "Grid record selection Error",
          okText: "Ok",
        });
        setOpenModal(true);
      }
    } catch (err: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: err.message,
        title: "Record Selection!",
        okText: "Ok",
      });
    }
  };

  const validCancel = () => {
    if (states.selectedTask_update?.length === 0) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setOpenModal(true);
      setModalData({
        message: "Please select a record",
        title: "Grid Record Selection Error",
        okText: "Ok",
      });
    }
  };
  const openCancel = () => {
    try {
      if (states.selectedTask_update?.length === 0) {
        // modal here
        setIcon("warning");
        setshowModalDisableButton(true);
        setModalData({
          message: "Please select a record",
          title: "Grid Record Selection Error",
          okText: "Ok",
        });
        setOpenModal(true);
      } else {
        setState("inputModal", { state: true });
        dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      }
    } catch (err: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: err.message,
        title: "Record selection!",
        okText: "Ok",
      });
    }
  };

  const onChangeRecord = (e: any) => {
    setState("JobSelectedRecord", e?.data);
    if (setState !== undefined) {
      setState("JobSelectedRecord", e?.data);
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    } else {
    }
  };
  const onChangeJobRecord = (e: any) => {
    setState("jobUpdateSelectedRecord", e);
    if (setState !== undefined) {
      setState("jobUpdateSelectedRecord", e);
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    } else {
    }
  };

  // double click feature
  const allowUpdate = (e: any) => {
    setState("JobSelectedRecord", e?.data);
    if (setState !== undefined) {
      setState("JobSelectedRecord", e?.data);
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      dispatch(settask_footer_update(true));
    }
  };
  const allowJobUpdate = (e: any) => {
    setState("JobUpdateSelectedRecord", e);
    if (setState !== undefined) {
      setState("JobUpdateSelectedRecord", e);
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      dispatch(settask_footer_update(true));
    }
  };
  const onChangeMatRecord = (e: any) => {
    setState("materialSelectedRecord", e);
    if (setState !== undefined) {
      setState("materialSelectedRecord", e);
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    } else {
    }
  };

  // double click feature
  const allowMatUpdate = (e: any) => {
    setState("materialSelectedRecord", e);
    if (setState !== undefined) {
      setState("materialSelectedRecord", e);
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      dispatch(settask_footer_update(true));
    }
  };

  const gridRef = useRef<any>(null);

  // ------------------------------------------------------------

  const [getTotAvg, getTotAvgError, getTotLoading] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetTotalAverage`
  );
  const [getTotAvgM, getTotAvgMError, getTotMLoading] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetTotalAverageMonth`
  );
  const [getTotAvgY, getTotAvgYError, getTotYLoading] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetTotalAverageYear`
  );
  const [getTotReqP, getTotReqPError, getTotReqPLoading] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetTotalRequestPending`
  );
  const [getTotReqD, getTotReqDError, getTotReqDLoading] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetTotalRequestDone`
  );
  const [getTotReq, getTotReqError, getTotReqLoading] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetTotalRequest`
  );
  const [getTotJobD, getTotJobDError, getTotJobDLoading] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetTotalCompletedJobs`
  );
  const [getTotJob, getTotJobError, getTotJobLoading] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetTotalNumberOfJobs`
  );

  const [getPdata, setGetPdata] = useState<boolean>(false);
  const [Exit, setExit] = useState<boolean>(false);

  const openProgress = () => {
    const ProgressModal = {
      message:
        "This process might take some minutes to complete. Are you sure you want to proceed and load the data?",
      title: "Proceed?",
      okText: "Yes",
      cancelText: "No",
    };

    setModalData(ProgressModal);
    setIcon("question");
    setOpenModal(true);
  };

  const testBool = () => {
    setGetPdata(true);
    // setExit(true)
  };
  const writtenInfo1 = [
    {
      id: 0,
      label: "Pending",
      value: getPdata ? getTotReqP[0]?.count : "*",
      valcolor: "red-500",
    },
    {
      id: 1,
      label: "Done",
      value: getPdata ? getTotReqD[0]?.count : "*",
      valcolor: "green-500",
    },
    {
      id: 2,
      label: "Total",
      value: getPdata ? getTotReq[0]?.count : "*",
      valcolor: "blue-500",
    },
  ];
  const writtenInfo2 = [
    {
      id: 0,
      label: "Pending",
      value: getPdata ? "*" : "*",
      valcolor: "red-500",
    },
    {
      id: 1,
      label: "Done",
      value: getPdata ? getTotJobD[0]?.count : "*",
      valcolor: "green-500",
    },
    {
      id: 2,
      label: "Total",
      value: getPdata ? getTotJob[0]?.count : "*",
      valcolor: "blue-500",
    },
  ];
  const writtenInfo3 = [
    { id: 0, label: "Pending", value: "*", valcolor: "red-500" },
    { id: 1, label: "Done", value: "*", valcolor: "green-500" },
    { id: 2, label: "Total", value: "*", valcolor: "blue-500" },
  ];

  const totalAvg =
    getTotAvg[0]?.mtaPercentComplete / getTotAvg[0]?.activityCount;
  const totAvgM =
    getTotAvgM[0]?.mtaPercentComplete / getTotAvgM[0]?.activityCount;
  const totAvgY =
    getTotAvgY[0]?.mtaPercentComplete / getTotAvgY[0]?.activityCount;

  const progressBar = [
    {
      id: 1,
      label: "",
      value: getPdata ? totalAvg : 0,
      desc: "Total Average % Complete",
      lel: true,
      bg: "orange-200",
    },
    {
      id: 2,
      label: "",
      value: getPdata ? totAvgM : 0,
      desc: `${"Average % (" + dayjs().format("YYYY") + ")"}`,
      lel: true,
    },
    {
      id: 3,
      label: "",
      value: getPdata ? totAvgY : 0,
      desc: `${"Average % (" + dayjs().format("MMM") + ")"}`,
      lel: true,
    },
  ];

  useEffect(() => {
    // DBdata_Fetch()
    set_form_height(formRef.current?.clientHeight + 25);
    dispatch(setGeneralValue({ expr: "isPrint", value: false }));

    if (isProgress) {
      set_form_height(formRef.current?.clientHeight + 80);
    }
  }, [refreshstate, isProgress]);

  //new button
  const newAccessPriv = () => {
    if (savePriv === true) {
      doubleClick_toggle();
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      setState("jobUpdateSelectedRecord", {});
      dispatch(setGeneralValue({ expr: "updateDate", value: currentDate }));
    } else {
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      setshowModalDisableButton(true);
      setModalData({
        message: "No Access Privilege!",
        title: "Access Denied!",
        okText: "Ok",
      });
      setOpenModal(true);
    }
  };
  //handleUpdate
  const handleUpdate = () => {
    if (states.JobSelectedRecord?.length === 0) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "No record selected",
        title: "Invalid record",
        okText: "Ok",
      });
      setOpenModal(true);
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    } else {
      doubleClick_toggle();
      //enable forms
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      //enable Update Mode
      dispatch(settask_footer_update(true));
    }
  };
  //edit button
  const editAccessPriv = () => {
    if (savePriv === true) {
      handleUpdate();
    } else {
      setshowModalDisableButton(true);
      setModalData({
        message: "No Access Privilege!",
        title: "Access Denied!",
        okText: "Ok",
      });
      setOpenModal(true);
    }
  };

  const rightTabs: { id: number; label: string; content: any }[] = [
    { id: 0, label: "Share", content: <Right_Share fullPage={fullPage} /> },
    {
      id: 1,
      label: "Comments",
      content: (
        <Right_Comment
          fullPage={fullPage}
          selectedRecord={states.selectedRecord}
        />
      ),
    },
    {
      id: 2,
      label: "Attachments",
      content: <Right_Attachments fullPage={fullPage} />,
    },
    
  ];

  const [GetLookUps, setGetLookUps] = useState<any>();
  const [getJobUpdateID, setGetJobUpdateID] = useState<any>();
  console.log('GetLookUps', GetLookUps);
  return (
    <>
      {Job_error && <WarningAlert />}
      {Job_loading && <CustomLoader text="Fetching Data" />}
      <div
        style={{ height: "calc(100)" }}
        className="  flex dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base mt-1 "
      >
        <ModalTemplate
          width={modalData.title == ProgressModal.title ? 500 : 400}
          icon_width={modalData.iconSize === "large" ? 100 : 50}
          icon={icon}
          cancelText={modalData.cancelText}
          disableCancel={showModalDisableButton}
          cancelHandler={() => {
            setOpenModal(false);
            dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
          }}
          open={openModal}
          okHandler={() => {
            modalData === maintenanceRequestModal
              ? open()
              : modalData.title === ProgressModal.title
              ? testBool()
              : setOpenModal(false);
            setOpenModal(false);
          }}
          message={modalData.message}
          okText={modalData.okText}
          title={modalData.title}
        />

        <div
          style={{ width: "calc(100% - 16%)" }}
          className="h-full dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base "
        >
          <div>
            <Maintenance_activitiestoolbar
              toggler={switch_toggle}
              setVisible={setSwitch_change}
              paState={states}
              pasetState={setState}
              gridRefX={gridRef}
              searchTextOnchange={(searchValue) => {
                setSearchText({ temp: searchValue, text: searchText.text });
              }}
              checkOnChange={(check) => {
                setActiveStatus({ temp: check, status: activeStatus.status });
              }}
              handleFind={() => {
                setStartDate({ text: startDate.temp, temp: startDate.temp });
                setEndDate({ text: endDate.temp, temp: endDate.temp });
                setSearchText({ temp: searchText.temp, text: searchText.temp }); //search text
                setActiveStatus({
                  temp: activeStatus.temp,
                  status: activeStatus.temp,
                }); //active status
                setsearchCriteria({
                  temp: searchCriteria.temp,
                  index: searchCriteria.temp,
                });
              }}
              searchCriteriaOnchange={(newIndex, criteria) => {
                setsearchCriteria({
                  temp: newIndex,
                  index: searchCriteria.index,
                });
              }}
              handleRefresh={() => {
                setSearchText({ temp: searchText.temp, text: "" });
                setActiveStatus({
                  temp: "",
                  status: "",
                });
                setRefreshstate(!refreshstate);
              }}
              startDate={startDate.temp}
              endDate={endDate.temp}
              startDateChanged={(date) =>
                setStartDate({
                  temp: dayjs(date).format(genFormat),
                  text: startDate.text,
                })
              }
              endDateChanged={(date) =>
                setEndDate({
                  temp: dayjs(date).format(genFormat),
                  text: endDate.text,
                })
              }
              handleToggle={(e) => {
                setIsProgrss(e);
              }}
              searchCriteriaData={searchCriteriaData}
              selectedRecord={states.selectedRecord}
            />
          </div>
          <div className="">
            {switch_change && (
              <div
                ref={formRef}
                className="px-2 flex justify-between gap-x-2 border-b-4 mb-0.5 pb-1"
              >
                <div
                  style={{ width: "700px", height: "485px" }}
                  className="border-[1px]"
                >
                  <TabPanel className="w-full h-full">
                    <Item title={"Maintenance Request"}>
                      <Maintenance_Activities_Details_Form
                        setState={setState}
                        opened={open}
                        selectedRecord={states.selectedRecord}
                        openSelectedTask={states.openSelectedTask}
                        selectedTask_update={states.selectedTask_update[0]}
                        openCancel={openCancel}
                        validCancel={validCancel}
                      />
                    </Item>

                    <Item title={"Maintenance Activities"}>
                      <Maintenance_Activities_tab2_Details
                        openedTask={states.openSelectedTask}
                        setrefreshx={setRefreshstate}
                        refreshx={refreshstate}
                        selectedRecord={states.selectedRecord}
                        startDate={startDate.text}
                        selectedTask_updatex={states.selectedTask_update[0]}
                        LookUps={setGetLookUps}
                        takeJobUpdateId={getJobUpdateID}
                      />
                    </Item>
                  </TabPanel>
                </div>

                <div
                  style={{ width: "calc(100% - 710px)", height: "485px" }}
                  className="w-full border-[1px] items-center dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base "
                >
                  <TabPanel className="w-full h-full">
                    <Item title={"Job Activities Details"}>
                      <div onDoubleClick={doubleClick_toggle}>
                        {(flipSwitcher && view == null) ||
                        view === switchRoutes[1].path ? (
                          <Datagrid_template
                            columns={Job_ac}
                            data={Job_data}
                            gridheight={446}
                            onRowClick={onChangeRecord}
                            rowDoubleClicked={allowUpdate}
                            disablePaging={true}
                            disableGroupPanel={true}
                            disableSearch
                          />
                        ) : (
                          <Maintenance_activities_Job_Details
                            selectedRecord={states.selectedRecord}
                            openSelectedTask={states.openSelectedTask}
                            setrefreshx={setRefreshstate}
                            jobDetailslookUp={ GetLookUps?.getLueJobs}
                          />
                        )}
                      </div>
                    </Item>

                    <Item title={"Materials"}>
                      <div onDoubleClick={doubleClick_toggle}>
                        {(flipSwitcher && view == null) ||
                        view === switchRoutes[1].path ? (
                          <Datagrid_template
                            columns={Materials_cols}
                            data={matData}
                            disableSearch
                            gridheight={446}
                            onRowClick={onChangeMatRecord}
                            rowDoubleClicked={allowMatUpdate}
                            disablePaging={true}
                            disableGroupPanel={true}
                          />
                        ) : (
                          <Maintenance_activities_Materials_Details
                            selectedRecordx={states.selectedRecord}
                            setrefreshx={()=>{setRefreshstate(!refreshstate)}}
                            materialRecord={states.materialSelectedRecord}
                            materialDetailslookUp={GetLookUps}
                          />
                        )}
                      </div>
                    </Item>

                    <Item title={"Job Updates"}>
                      <div onDoubleClick={doubleClick_toggle}>
                        {(flipSwitcher && view == null) ||
                        view === switchRoutes[1].path ? (
                          // this is the grid-view
                          <div>
                            <Datagrid_template
                              columns={Job_cols}
                              data={Job_updatesData}
                              gridheight={400}
                              onRowClick={onChangeJobRecord}
                              rowDoubleClicked={allowJobUpdate}
                              disableGroupPanel={true}
                              disablePaging={true}
                              disableSearch
                            />

                            <ul className=" flex py-2 px-2 pl-[30%]  w-full ">
                              <EditButton onClick={editAccessPriv} />
                              <ul className=" flex items-center justify-end w-full gap-2">
                                {form_disabled === false ? (
                                  <UpdateButton handleUpdate={() => {}} />
                                ) : (
                                  <UpdateButton disableButton={true} />
                                )}

                                {form_disabled === true ? (
                                  <NewButton
                                    useCallbackFunc={true}
                                    new_button_toggler={() => {
                                      newAccessPriv();
                                    }}
                                  />
                                ) : update_state_change === true ? (
                                  <Cancel />
                                ) : !(update_state_change && form_disabled) ? (
                                  <Cancel />
                                ) : (
                                  <NewButton
                                    useCallbackFunc={true}
                                    new_button_toggler={() => {
                                      newAccessPriv();
                                    }}
                                  />
                                )}
                              </ul>
                            </ul>
                          </div>
                        ) : (
                          <Maintenance_activities_update
                            selectedRecordx={states.selectedRecord}
                            setrefreshx={()=>{setRefreshstate(!refreshstate)}}
                            JobSelectedRecord={states.jobUpdateSelectedRecord}
                            takeJobUpdateId={setGetJobUpdateID}
                            jobUpdateLookUp={
                              GetLookUps?.getEntAsmTmsMaintenanceActivityJobUpdate
                            }
                          />
                        )}
                      </div>
                    </Item>
                  </TabPanel>
                </div>
              </div>
            )}
          </div>

          <div
            style={{ height: "" }}
            className="px-2 dark:bg-slate-900 dark:text-darkModeSkin-base"
          >
            <Datagrid_template
              dataGridRef={gridRef}
              gridheight={switch_change === true ? pageHeight : fullPage}
              columns={footer_datagrid_cols}
              data={DBdata}
              handlePageSize={(e:number)=>{
                UpdateStates("pageSize", e, setPaging)
              }}
              handlePageNumber={(e:number)=>{
                
                UpdateStates("pageNumber", e, setPaging)
              }}
              pageSize={paging.pageSize}
              pageNumber={paging.pageNumber}
              deselectFirstRow={false}
              onRowClick={(data) => {
                dispatch(task_footer_update(false));
                setState("selectedRecord", data);
                setState("openSelectedTask", [data]);

                dispatch(
                  setGeneralValue({ expr: "formDisabled", value: true })
                );
              }}
              rowDoubleClicked={(data) => {
                setState("selectedRecord", data);
                dispatch(
                  setGeneralValue({ expr: "formDisabled", value: false })
                );
                dispatch(task_footer_update(true));
              }}
              selectedItemsChange={(e) => {
                setState("selectedRecord", data);
                alert("hi");
              }}
            />

            {isProgress && (
              <div className="w-full  flex justify-between">
                <p className="text-blue-500 px-1 w-40 flex justify-center items-center">
                  Done...
                </p>
                <div
                  className={
                    !isInformation
                      ? "w-full flex justify-start items-center"
                      : "w-1/2 flex justify-start"
                  }
                >
                  <li className=" flex justify-start items-center">
                    <Checkbox
                      style={{ borderColor: borderTheme }}
                      className="justify-start items-center border-[1px] px-1 rounded"
                      onChange={(e) => {
                        setInformation(e.target.checked);
                        e.target.checked == true && openProgress();
                      }}
                      value={isInformation}
                    />
                  </li>
                  {isInformation && (
                    <li
                      onClick={() => {
                        openProgress();
                      }}
                      className="mx-3 hover:cursor-pointer  text-xs justify-end  text-gray-600 rounded flex items-center"
                    >
                      <span
                        id="switch_chart"
                        style={{ borderColor: borderTheme }}
                        className="w-6 border-[1px] p-1 rounded flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"
                      >
                        {" "}
                        <img
                          className="dark:bg-slate-900    dark:text-darkModeSkin-base duration-100"
                          src={analysis}
                        />
                      </span>
                      <Tooltip
                        target="#switch_chart"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        hideOnOutsideClick={true}
                      >
                        <p className="">Load progress statistics data</p>
                      </Tooltip>
                    </li>
                  )}

                  {isInformation && (
                    <div className="w-full flex justify-start items-center space-x-4 ">
                      <div className="w-full flex items-center space-x-2">
                        <p>*Reqs</p>

                        <ul className=" items-center text-blue-500 ">
                          {writtenInfo1.map(
                            ({ id, label, value, valcolor }) => {
                              return (
                                <div className=" w-full flex justify-between space-x-3  ">
                                  <li
                                    className={` flex justify-start items-center`}
                                  >
                                    {label}
                                  </li>
                                  <li
                                    className={`text-${valcolor} flex justify-end items-center`}
                                  >
                                    {value}
                                  </li>
                                </div>
                              );
                            }
                          )}
                        </ul>
                      </div>

                      <div className="w-full flex items-center space-x-2">
                        <p>*Jobs</p>

                        <ul className=" items-center text-blue-500 ">
                          {writtenInfo2.map(
                            ({ id, label, value, valcolor }) => {
                              return (
                                <div className="w-full flex justify-between space-x-3  ">
                                  <li
                                    className={` flex justify-start items-center`}
                                  >
                                    {label}
                                  </li>
                                  <li
                                    className={`text-${valcolor} flex justify-end items-center`}
                                  >
                                    {value}
                                  </li>
                                </div>
                              );
                            }
                          )}
                        </ul>
                      </div>

                      <div className="w-full flex items-center space-x-2">
                        <p>*Outside Jobs</p>

                        <ul className=" items-center text-blue-500 ">
                          {writtenInfo3.map(
                            ({ id, label, value, valcolor }) => {
                              return (
                                <div className="w-full flex justify-between space-x-3  ">
                                  <li
                                    className={` flex justify-start items-center`}
                                  >
                                    {label}
                                  </li>
                                  <li
                                    className={`text-${valcolor} flex justify-end items-center w-[20px]`}
                                  >
                                    {value}
                                  </li>
                                </div>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    </div>
                  )}
                </div>

                {/*  */}

                {isInformation && (
                  <div className="w-1/2 flex ">
                    <div className="w-full flex justify-end">
                      <Form className="w-full justify-end">
                        {/* bars */}
                        <Form.Item
                          label={<p className="hidden">hi</p>}
                          colon={false}
                        >
                          <div className="w-full flex justify-end ">
                            {progressBar.map(
                              ({ id, label, value, lel, desc, bg }) => {
                                return (
                                  <>
                                    <div
                                      style={{ width: "100%" }}
                                      className="px-1"
                                    >
                                      <div
                                        className="flex flex-col"
                                        id="avGAllTasks"
                                      >
                                        <div
                                          className={`w-full h-6   ${
                                            id === 0
                                              ? "bg-transparent"
                                              : "bg-gray-300"
                                          } `}
                                        >
                                          <div
                                            className={`relative h-full flex w-full  `}
                                          >
                                            <div
                                              className={` absolute h-full w-full  ${
                                                id === 0
                                                  ? "bg-transparent"
                                                  : "bg-purple-300"
                                              }  text-xs font-medium text-blue-100 text-center items-center p-1 leading-none  `}
                                              style={{
                                                width: value?.toString() + "%",
                                              }}
                                            ></div>
                                            <div
                                              className={`relative text-xs flex ${
                                                id === 0
                                                  ? "text-transparent"
                                                  : "text-black"
                                              }  items-center w-full text-center justify-center`}
                                            >
                                              {value}%
                                            </div>
                                          </div>

                                          <div
                                            style={{
                                              height: "150%",
                                              maxHeight: "200%",
                                            }}
                                            className={`w-full h-full ${
                                              lel && "w-[100%]"
                                            } ${
                                              id == 0
                                                ? ` flex`
                                                : ` px-5 mt-1 flex py-0.5  text-xs `
                                            }`}
                                          >
                                            <p
                                              className={`w-full h-[100%] flex text-xs ${
                                                lel &&
                                                "z-30 w-full h-full overflow-visible flex absolute"
                                              }`}
                                            >
                                              {desc}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              }
                            )}
                          </div>
                          <div></div>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div
          className="mt-10"
          style={{
            width: "calc(100% - 84%)",
            height: "100%",
            borderColor: borderTheme,
          }}
        >
          <div style={{ height: fullPage + 5 }} className="w-full ">
            <TabPanel
              showNavButtons={true}
              swipeEnabled
              style={{ height: fullPage + 50 }}
              className="w-full border-[1px] "
            >
              {rightTabs.map(({ id, label, content }) => {
                return (
                  <Item key={id} title={label}>
                    {content}
                  </Item>
                );
              })}

              <div className="text-xs text-indigo-500 px-1">Done</div>
            </TabPanel>
          </div>
        </div>
      </div>
    </>
  );
};

// Dummy data

const data: any = [
  {
    id: 0,
    officer: true,
    workshopOfficer: true,
    supervisor: false,
    SupUpperLimit: 4,
  },
  { id: 1, officer: false, supervisor: true, SupUpperLimit: 8 },
  {
    id: 2,
    officer: false,
    supervisor: false,
    manager: true,
    MgrUpperLimit: 12,
  },
];
