// import { Panel } from "../NavBody/NavBody";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
interface props {
  icons: any[];
  setIsOpened?: (e: boolean) => void;
  isOpened?: boolean;
  userSettingShowRightBar?: boolean;
}

export const Bar = ({ icons, setIsOpened, isOpened }: props) => {
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  const lockStatus = sessionStorage.getItem("lockStatus");

  return (
    <>
      <ul className="w-12 mcd-menu mt-5">
        <li className="pt-2">
          <div
            onClick={() => {
              lockStatus === "false" && setIsOpened!(!isOpened);
            }}
            className="active hover:cursor-pointer h-24 block"
          >
            <div className="flex flex-col items-center space-y-2 ">
              {icons.map((items) =>
                items.id === 4 || items.id === 6 || items.id === 7 ? (
                  <li
                    key={items.id}
                    className={items.class}
                    onClick={items.action}
                    style={{ borderColor: borderTheme }}
                  >
                    <span>
                      {" "}
                      <img alt="" src={items.image} />{" "}
                    </span>
                  </li>
                ) : (
                  <li
                    key={items.id}
                    className={items.class}
                    onClick={() => {
                      if (lockStatus === "false") {
                        console.log(lockStatus);
                        items.action();
                      }
                    }}
                    style={{ borderColor: borderTheme }}
                  ></li>
                )
              )}
            </div>
          </div>
        </li>
      </ul>
    </>
  );
};
