
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
// import type { SelectProps } from 'antd';
import { RootState } from '../../../../app/store';
import { api_url,  navbar_height } from '../../../accessories/component_infos';
import { CI_toolbar } from '../../../accessories/custom-toolbars/ci_toolbar';
import { cooperate_initiatve_footer_datagrid_cols } from './data/datagrid_cols';
import { Footer_datagrid_template } from './data/template/footer_datagrid';
import { CI_Details_Form } from './widgets/Cooperate_initiative_form';
import { searchTextTypes, statusTypes, searchCriteriaTypes } from '../../../human-resource/setups/data/types/selectsTypes';
import { CIA_toolbar } from '../../../accessories/custom-toolbars/cia_toolbar';
import Datagrid_template from '../../../../templates/Datagrid';
import { task_footer_table_selected, task_form_disable, task_footer_update } from '../../../../features/Task&Todo/Task/TaskDetails';
import { setGeneralValue } from '../../../../features/generalSlice';
import { useDispatch } from 'react-redux';
import useFetch from '../../../../hooks/useFetch';
import { WarningAlert } from '../../../accessories/warningAlert';
import { useBusyLoader } from '../../../../hooks/useBusyLoader';
import { HrSetupsCustomToolbar } from '../../../accessories/custom-toolbars/hrSetupsCustomtoolbar';





interface props {
    state_change: boolean;
}



export const CI = ({state_change}:props)=>{
    const[busyLoader, setBusyLoader] = useBusyLoader();

    const news_flash_height = useSelector((state:RootState)=>state.general.newsflashHeight);
    // const width = window.innerWidth/2
    // const fixedWidth = 'calc(50% - 8px)';
    const height = window.innerHeight - (navbar_height + news_flash_height + 43) ; //height without navbar
    const [formheight, set_form_height] = useState<number>(0);
    const formRef = useRef<any>(null);

    const pageHeight = window.innerHeight - (navbar_height + news_flash_height + formheight + 10);
    const [fullPage, setfullPage] = useState(window.innerHeight - (navbar_height + news_flash_height + 30 ) );
    const borderTheme = useSelector((state:RootState)=>state.general.cssBorderClass)
    const [switch_change, setswitch_change] = useState(true)

    const switch_toggle = ()=>{
        if(switch_change === true){
            
            setswitch_change(!switch_change)
        }else{
            setswitch_change(!switch_change)
            
        }
    }

    const dispatch = useDispatch()

    

    // const [DBdata, DBdata_error, DBdata_loading] = useFetch("PfmCorporateInitiative/GetAllCorporateInitiatives")

    //const [DBdata, setDBdata] = useState<any>()
    const [refresh, setRefresh] = useState(false)

    
    const [searchText,setSearchText]=useState<searchTextTypes>({temp:'',text:''});
    const [activeStatus,setActiveStatus]=useState<statusTypes>({temp:'',status:''});
    const [searchCriteria,setsearhCriteria]=useState<searchCriteriaTypes>({temp:0,index:0});
    const[refreshToolbar,setrefreshToolbar]=useState<boolean>(false);


    // fetching data ayschronously
    const [DBdata, DBError, DBLoading] = useFetch(`PfmCorporateInitiative/GetAllCorporateInitiatives?search=${searchText.text}&active=${activeStatus.status}`, refresh);
    useEffect(()=>{
        DBLoading ? setBusyLoader('.') : setBusyLoader('');
    },[DBLoading])
   


    useEffect(()=>{
        set_form_height((formRef.current?.clientHeight)+ 30)
        console.log(formRef)
    },[refresh])


    console.log({
        searchCriteria,
        searchText,
        activeStatus
    })

    const[selectedData,setSelectedData]=useState({});

    // const searchCriteriaData : any[]=[
    //     // {id:0,value:'Initiative'},
    //     // {id:1,value:'Objective'},
    //     // {id:2,value:'Theme'},
    //     // {id:3,value:'Mission/Vision'},
    // ]
    


    return (
        <div  className='w-full h-full dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base '>
            {DBError && (
                <WarningAlert/>
            )}   

            <div>
                <HrSetupsCustomToolbar
                searchCriteria
                
                withDates={false}
                toggler={switch_toggle} 
                searchTextOnchange={(searchValue)=>{
                    setSearchText({temp:searchValue, text:searchText.text})
                }}
                checkOnChange={(check)=>{
                    setActiveStatus({temp:check,status:activeStatus.status })
                }}
                handleFind={()=>{
                    setSearchText({temp:searchText.temp,text:searchText.temp}) //search text
                    setActiveStatus({temp:activeStatus.temp,status:activeStatus.temp}) //active status
                    setsearhCriteria({temp:searchCriteria.temp,index:searchCriteria.temp})
                    setrefreshToolbar(current=> !current);
                }}
                searchCriteriaOnchange={(newIndex,criteria)=>{
                    setsearhCriteria({temp:newIndex,index:searchCriteria.index})
                }}
                // searchCriteriaData={searchCriteriaData}
                outerSelected={selectedData}
                handleNew={()=>{
                    setSelectedData({})
                }}
                />
            </div>
            
            {switch_change && <div ref={formRef}  className='w-full px-2'>
                <p id={"form_header"} style={{borderColor:borderTheme}} className='pt-1  border-[1px] border-b-0 pl-4 w-full bg-slate-100 rounded-tl'>Initiative Details</p>
                <div   className="w-full  flex justify-between gap-x-4 border-b-4 mb-1 pb-1">
                    
                    {/* Internal toggler : expands the right taskUpdate horizontally */}
                    
                        <div style={{width:'100%',height:'278px'}} className='mb-1' >
                            <CI_Details_Form
                            selectedRecordx={selectedData} 
                            setSelectedRecordx = {()=> setSelectedData({})}  
                            setRefreshx={()=> setRefresh(current=> !current)}
                            />
                        </div>
                        
                        
                    </div>
            </div>}
        

            {/* Footer gridtable  */}
            <div style={{height:''}} className='px-2  dark:bg-slate-900 dark:text-darkModeSkin-base'>
                <Datagrid_template 
                gridheight={switch_change ?  pageHeight : fullPage} 
                columns={cooperate_initiatve_footer_datagrid_cols} 
                data={DBdata} 
                rowDoubleClicked={(e) => {
                    dispatch(task_footer_table_selected(e))
                    dispatch(setGeneralValue({ expr: "formDisabled", value: false }))
                    dispatch(setGeneralValue({ expr: "updateMode", value: true }))
                    setSelectedData(e)
                }}
                onRowClick={(e) => {
                    dispatch(task_footer_table_selected(e))
                   
                    dispatch(setGeneralValue({ expr: "formDisabled", value: true }))
                    dispatch(setGeneralValue({ expr: "updateMode", value: false }))
                    setSelectedData(e)
                }}
                />
            </div>
        </div>
    )
}