export const assets_manufacturersColumns = [
  {
    id: 0,
    caption: "ID",
    dataField: "mftIdpk",
    dataType: "number",
    width: 80,
    fixed: true,
    visible: false,
  },
  {
    id: 1,
    caption: "MANUFACTURER",
    dataField: "mftName",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "SHORT NAME",
    dataField: "mftShtName",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 3,
    caption: "ORDER",
    dataField: "mftOrder",
    dataType: "number",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 4,
    caption: "ACTIVE",
    dataField: "mftActive",
    dataType: "boolean",
    width: 90,
    fixed: true,
    visible: true,
  },
  {
    id: 5,
    caption: "REMARKS",
    dataField: "mftRmks",
    dataType: "string",
    width: 5000,
    fixed: false,
    visible: true,
  },
];
