import { memo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useFetch from "../../../../../../../hooks/useFetch";

import VehicleDataUploadDetails from "./vehicleDataUpload-details";
import { WarningAlert } from "../../../../../../accessories/warningAlert";
import CustomLoader from "../../../../../../accessories/custom-loader";
import {
  currentDate,
  startingDate,
} from "../../../../../../accessories/component_infos";
import dayjs from "dayjs";
import VehicleDataUploadToolbar from "./vehicleDataUpload-toolbar";

const VehicleDataUpload = () => {
  const [searchText, setSearchText] = useState<{ temp: string; text: string }>({
    temp: "",
    text: "",
  });
  const [startDate, setStartDate] = useState<{ temp: string; index: string }>({
    temp: dayjs(startingDate).format("YYYY-MM-DD"),
    index: dayjs(startingDate).format("YYYY-MM-DD"),
  });
  const [endDate, setEndDate] = useState<{ temp: string; index: string }>({
    temp: dayjs(currentDate).format("YYYY-MM-DD"),
    index: dayjs(currentDate).format("YYYY-MM-DD"),
  });
  const [searchCriteria, setSearchCriteria] = useState<{
    temp: number;
    index: number;
  }>({ temp: 0, index: 0 });

  const [active, setActive] = useState<{
    temp: string | boolean;
    status: string | boolean;
  }>({ temp: "", status: "" });

  const [refresh, setRefresh] = useState(false);

  const [datagridData, errorDatagridData, loadingDataGridData] = useFetch(
    `AssetMgrLoadVehicleData/PopGdvAllStatus?selected=${searchCriteria.index
    }&searchText=${searchText.text}&activeStatus=${active.status
    }&startDate=${dayjs(startDate.index).format("YYYY-MM-DD")}&endDate=${dayjs(
      endDate.index
    ).format("YYYY-MM-DD")}`,
    refresh
  );

  const [showTable, setShowTable] = useState(true);
  const [fixSheet, setFixSheet] = useState(false);



  // switch button toggler
  const switch_toggle = () => {
    setShowTable(!showTable);
  };

  /**
   * Use to revalidate the 
   * @type {function}
   * @returns void
   */
  const revalidateSheet = () => setFixSheet((prev) => !prev);





  const gridRef = useRef<any>();

  return (
    <>
      {errorDatagridData && <WarningAlert />}
      {loadingDataGridData && (
        <CustomLoader
          text="
      
      Fetching data"
        />
      )}
      <div className="mx-2">
        <VehicleDataUploadToolbar
          toggler={switch_toggle}
          checkFields={revalidateSheet}
          uploadFile={() => {
            gridRef.current.test();
          }}
          fixSheet={() => {
            gridRef.current.fixSheet();
          }}
          handleFind={() => {
            setActive({ temp: active.temp, status: active.temp });
            setEndDate({ temp: endDate.temp, index: endDate.temp });
            setStartDate({ temp: startDate.temp, index: startDate.temp });
            setSearchText({ temp: searchText.temp, text: searchText.temp });
            setSearchCriteria({
              temp: searchCriteria.temp,
              index: searchCriteria.temp,
            });
          }}
          setActvieState={(e) => {
            setActive({ temp: e, status: active.status });
          }}
          setEndDate_N={(e) => {
            setEndDate({ temp: e, index: endDate.index });
          }}
          setStartDate_N={(e) => {
            setStartDate({ temp: e, index: startDate.index });
          }}
          setSearchText={(e) => {
            setSearchText({ temp: e, text: searchText.text });
          }}
          active={active.status}
          startDate={startDate.temp}
          endDate={endDate.temp}
          setSearchCriteria={(e) => {
            setSearchCriteria({ temp: e, index: searchCriteria.index });
          }}
        />
      </div>

      <div className="px-2 mb-0.5 ">
        <VehicleDataUploadDetails
          toggled={showTable}
          setRevalidateSheet={setFixSheet}
          revalidateSheet={fixSheet}
          ref={gridRef}
          mainDataGridData={datagridData}
        />
      </div>
    </>
  );
};

export default memo(VehicleDataUpload);
