import React, { useState, useCallback,useRef } from 'react'
import {Form, Input, Select,DatePicker } from 'antd';
import { CheckBox } from 'devextreme-react/check-box';
import refresh from '../../../../assets/refresh.png'
import dayjs from 'dayjs';
import { NewButton, SaveButton } from '../../../../../accessories/buttons';
import { Target_Details_Select } from '../../../../data/Datalist_select';







export const Target_Details_Form = () =>{
    const { Option } = Select;
    const { TextArea } = Input;

    // import { AssignedDept, AssignedLocation, AssignedSelection, AssignedUnit, Batch, Group, PPE_Select, RequestWindow, StaffName } from './PPE_selects';


    const options = [];
    for (let i = 1; i < 36; i++) {
    options.push({
        label: i.toString(36) + i,
        value: i.toString(36) + i,
    });
    }

    const [textAreaHeight , settextAreaHeight] = useState<string>('75px')

    const handleChange = (value: string[]) => {
    console.log(`selected ${value}`);
        if(value.length > 7 ) {
            settextAreaHeight('60px')
        }else {
            settextAreaHeight('71px')
        }
    };
    const dateFormat = " DD MMM YYYY HH: mm:ss";
   


    
    const [tasks_update_state, setTask_update_state] = useState<Boolean>(true)
    const [tasks_update_state_width, setTask_update_state_width] = useState<string>('100%')
    const [tasks_update_col_width, setTask_update_col_width] = useState<number>(100)
    const [tasks_update_col_height, setTask_update_col_height] = useState<number>()
    const [task_datagrid_width,set_task_datagrid_width] = useState(`calc(100% - ${'550px'})`);
    const [AppraiseisActive, AppraisesetIsActive] = useState<boolean>(false)
    


    const taskDetailsFormHeight = useRef(null);

    // const [heightHook, setheightHook] = useState<any>()

    // const refHook = (e:any) =>{
    //     if(e.length = 1){
    //         console.log("true");
            
    //     }
    // }

    // const refHook = ()=>{
        
        
    //     console.log(taskDetailsFormHeight);
    //     console.log('====================================');
    // }

    
    
    return (
        
        <div style={{width:'450px', minWidth:'450px', height:'100%'}}  className="  border-2  bg-slate-50 dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base  ">
                            {/* <h4 className=" w-full  mb-2 bg-slate-100  ">Task Details</h4> */}
                            <div style={{maxHeight:'95px', minHeight:'95px', height:'95px'}} className=' items-start justify-start bg-slate-50'   >
                                
                                <Form
                                    className='w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 px-2 '
                                    name="basic"
                                    labelCol={{ span: 6}}
                                    wrapperCol={{ span: 24 }}
                                    initialValues={{ remember: true }}
                                    autoComplete="off"
                                    size='small'
                                    layout='horizontal'

                                >
                                   
                                    
                                    <Target_Details_Select />
                                    
                                    
                                            {/* <Group />
                                            <StaffName />
                                            <RequestWindow />
                                            <Batch />
                                            <AssignedDept />
                                            <AssignedSelection />
                                            <AssignedUnit />
                                            <AssignedLocation /> */}
                                        <div className='w-full  flex flex-row justify-center     '>
                                                <div style={{width:'calc(450px - 83px)',marginLeft:'30px'}} className=' flex  justify-between '>
                                                    {/* <li className='flex justify-start '><Cancel/></li> */}
                                                    <li className=' flex justify-start items-center flex-row'><p className='mr-3'>Active? : </p>  <CheckBox /></li>
                                                    <ul className='flex justify-end  '>
                                                        <SaveButton />
                                                        <NewButton />
                                                    </ul>
                                                </div>
                                        </div>
                                </Form>
                            </div>

                            

                        </div>
    )
}