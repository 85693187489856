export const assetsSetups_vehicleStatus_columns = [
  {
    id: 0,
    caption: "VEHICLE STATUS",
    dataField: "vstName",
    dataType: "string",
    width: 200,
    fixed: true,
    visible: true,
  },
  {
    id: 1,
    caption: "SHORT NAME",
    dataField: "vstShtName",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "FIT STATUS",
    dataField: "vstFitStatus",
    dataType: "boolean",
    width: 110,
    fixed: false,
    visible: true,
  },
  {
    id: 3,
    caption: "ORDER",
    dataField: "vstOrder",
    dataType: "string",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 9,
    caption: "ACTIVE?",
    dataField: "vstActive",
    dataType: "boolean",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 10,
    caption: "REMARKS",
    dataField: "vstRmks",
    dataType: "string",
    width: 5000,
    fixed: false,
    visible: true,
  },
];
