import { Input, Form } from "antd";
import { DataGrid } from "devextreme-react";
import {
  Pager,
  Column,
  Scrolling,
  Editing,
  GroupPanel,
  HeaderFilter,
  Selection,
  SearchPanel,
} from "devextreme-react/data-grid";
import newIcon from "../../../../../../../../assets/new.png";
import save from "../../../../../../../../assets/save2.png";
import refresh from "../../../../../../../../assets/refresh.png";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";
import { ModalTemplate } from "../../../../../../../../templates/modal";
import { useFormPriviledge } from "../../../../../../../../hooks/useFormPriviledge";
import { PostDataFunc } from "../../../../../../../../functions/post";
import { comment_cols } from "../../data/datagrid_cols";
import { useCrudFunc } from "../../../../../../../../functions/crud";

interface props {
  lowerSelected: any;
  AllComments: any;
  fetchComments: (e: number) => void;
}

export const Transport_Documents_Comment = ({
  lowerSelected,
  AllComments,
  fetchComments,
}: props) => {
  const [searchText, setSearchText] = useState("");
  const [commentText, setCommentText] = useState<string>("");
  const [disableForm, setDisableForm] = useState(true);
  const [modalData, setModalData] = useState({
    showCancel: false,
    message: "No Access Privilege",
    title: "No Access!",
    okText: "Ok",
  });
  const [openModal, setOpenModal] = useState(false);
  const [PostDataFunc, UpdateDataFunc] = useCrudFunc();
  const [icon, setIcon] = useState<
    "warning" | "success" | "question" | undefined
  >("warning");
  const [cancelText, setCancelText] = useState("No");
  const [privType, setPrivType] = useState("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);

  const validateComment = () => {
    if (commentText.length === 0) {
      setIcon("warning");
      setModalData({
        showCancel: false,
        message: "Comment field must not be empty",
        title: "Validation Error",
        okText: "Ok",
      });
      setOpenModal(true);
    } else {
      setIcon("question");
      setModalData({
        showCancel: true,
        message: "Are you sure you want to save this comment?",
        title: "Confirm Save",
        okText: "Yes",
      });
      setOpenModal(true);
    }
  };

  const saveComment = async () => {
    if (Object.keys(lowerSelected).length !== 0) {
      try {
        const res = await PostDataFunc(
          "AmsTmsEntTransportDocuments/CreateComment",
          {
            acmRecordIdfk: lowerSelected.eddIDpk,
            acmComment: commentText,
            acmActive: true,
            Action: `Add comment to document with ID: ${lowerSelected.eddIDpk}`,
          },
          `
            Add comment to document with ID: ${lowerSelected.eddIDpk}
          `
        );
        if (res.status === 200) {
          fetchComments(lowerSelected.eddIDpk);
          setDisableForm(true);
          setIcon("success");
          setModalData({
            showCancel: false,
            message: "Comment has been successfully saved.",
            title: "Success",
            okText: "Ok",
          });
          setOpenModal(true);
        } else {
          throw Error("Something went wrong. Try again later.");
        }
      } catch (e: any) {
        setIcon("warning");
        setModalData({
          showCancel: true,
          message: e.message,
          title: "Unexpected Error",
          okText: "Ok",
        });
        setOpenModal(true);
      }
    }
  };

  const newAccessPriv = () => {
    setPrivType("save");
    if (savePriv === true) {
      setCommentText("");
      setDisableForm(false);
    } else {
      setIcon("warning");
      setModalData({
        showCancel: false,
        message: "No Access Privilege",
        title: "No Access!",
        okText: "Ok",
      });
      setOpenModal(true);
    }
  };

  const saveAccessPriv = () => {
    setPrivType("save");
    if (savePriv === true) {
      validateComment();
    } else {
      setIcon("warning");
      setModalData({
        showCancel: false,
        message: "No Access Privilege",
        title: "No Access!",
        okText: "Ok",
      });
      setOpenModal(true);
    }
  };

  return (
    <div className="w-full h-full">
      <ModalTemplate
        icon={icon}
        cancelText={cancelText}
        disableCancel={!modalData.showCancel}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        open={openModal}
        okHandler={() => {
          if (modalData.title === "Confirm Save") saveComment();
          setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
      />
      <ul className="w-full flex items-center justify-center py-1.5">
        <li className="justify-center items-center">
          <p>Find</p>
        </li>
        <li className="justify-center items-center mx-2">
          <Input
            onChange={(e: any) => setSearchText(e.target.value)}
            value={searchText}
            className="w-16"
            size="small"
          />
        </li>
        <li
          onClick={newAccessPriv}
          className="justify-center items-center mx-2 cursor-pointer"
        >
          <img alt="" src={newIcon} />
        </li>
        <li
          className="justify-center items-center mx-2 cursor-pointer"
          onClick={() => {
            setSearchText("");
            fetchComments(lowerSelected.eddIDpk);
          }}
        >
          <img alt="" className="w-4" src={refresh} />
        </li>
      </ul>
      <div className="pt-0.5 h-2/5 w-full border-4">
        <Form
          className="w-full h-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-1 px-2 "
          name="basic"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          size="small"
          layout="horizontal"
        >
          <div className="w-full h-full">
            {/* Request */}
            <Form.Item className=" w-full h-full">
              <div className=" w-full h-full ">
                <TextArea
                  onChange={(e: any) => setCommentText(e.target.value)}
                  disabled={disableForm}
                  style={{ resize: "none", height: "210px" }}
                  className="h-full"
                  value={commentText}
                />
                <ul className="w-full flex justify-end h-full">
                  <button
                    onClick={newAccessPriv}
                    style={{ height: "26px", width: "25%" }}
                    className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center   mb-0.5 ml-0.5   px-3 py-1 border-2 border-slate-200 rounded"
                  >
                    <img alt="" src={newIcon} />
                  </button>
                  {/* ------------------------------------- */}
                  <button
                    onClick={() => saveAccessPriv()}
                    style={{ height: "26px", width: "25%" }}
                    className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  ml-0.5 px-3 py-1 border-2 border-slate-200 rounded"
                  >
                    <img alt="" style={{ width: "18px" }} src={save} />
                  </button>
                </ul>
              </div>
            </Form.Item>
          </div>
        </Form>
      </div>

      <div className="pt-0.5 h-3/5">
        <DataGrid
          dataSource={AllComments}
          showBorders={true}
          columnWidth={150}
          showRowLines={true}
          showColumnLines={true}
          className="h-full"
          onRowClick={(e: any) => {
            setCommentText(e.data.acmComment);
          }}
        >
          <Pager visible={false} />
          <SearchPanel visible={false} text={searchText} />
          {comment_cols.map((data: any) => {
            return (
              <Column
                key={data.id}
                caption={data.caption}
                dataField={data.dataField}
                alignment={data.alignment}
                fixed={data.fixed}
                width={150}
                dataType={data.dataType}
              />
            );
          })}

          {/* SCROLLBAR  */}

          <Scrolling columnRenderingMode="virtual" />

          <HeaderFilter visible={true} allowSearch={true} />

          <GroupPanel visible={true} />

          <Editing mode={"row"} />
          <Scrolling
            columnRenderingMode="virtual"
            mode="virtual"
            showScrollbar={"always"}
          />
          <Selection mode="single" />
        </DataGrid>
        <div className="text-xs text-indigo-500 p-1">Done</div>
      </div>
    </div>
  );
};
