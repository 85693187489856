/* eslint-disable react/jsx-pascal-case */
import React, { Suspense, useRef, useState } from 'react'
import { formPropsConst } from '../../../accessories/constants'
import { Form } from 'antd'
import { InputsTemplate } from '../../../../templates/input'
import { SearchButton } from '../../../accessories/buttons'
import { CheckboxTemlate } from '../../../../templates/checkbox'
import Datagrid_template from '../../../../templates/Datagrid'
import useResizeMode from '../../../../hooks/useResizeMode'
import { left_access_priv } from '../data/datagrid_cols'
import useFetch from '../../../../hooks/useFetch'
import { RowClickEvent } from 'devextreme/ui/data_grid'


/**
 * Interface for selected row data on datagrid
 */
export interface GetAllUsersInterface {
    usaIDpk: number;
    empIDpk: number;
    empStaffNo: string;
    usaUsername: string;
    empName: string;
    empName1: string;
    empName2: string;
    dptShtName: string;
    sxnShtName: string;
    untShtName: string;
    usaActive: boolean;
    empActive: boolean;
    usaSuperUser: boolean;
    usaAllowSendingOfSMS: boolean;
    usaEditIntercom: boolean;
    usaEditEmails: boolean;
    usaSeeSalaries: boolean;
    usaSeeClassifiedDocs: boolean;
    usaLoggedIn: boolean;
    usaChangePassword: boolean;
    usaRmks: string;
    jbtShtName: string;
    jbtName: string;
    empMobNo: string;
    empMobNox: string;
    empIntercom: string;
    usaCreationDate: Date | null;
    usaEndDate: Date;
    usaSeeExecDashboard: boolean;
    usaEditOthersMobNos: boolean;
    usaSeeHiddenMobNos: boolean;
    usaDefaultModuleIDfk: number;
    apmShtName: string
    locShtName: string;
}

interface FormProps {
    // for the left datagrid that gets the data for all users
    refresh: boolean;

    setSelectedUser: (e: any) => void;


}

export const Access_Privilees_Assignement_Form = ({ refresh, setSelectedUser }: FormProps) => {

    const [initial, final] = useResizeMode(window.innerHeight)
    const gridheight = initial - 265

    // API Intergration-------------------------------------


    const dgridRef = useRef<RowClickEvent>(null);

    const [SearchText, setSearchText] = useState("");
    const [Temp, setTemp] = useState("");



    const [leftData] = useFetch(`SysAdminFormAccessPrivileges/GetAllUsers?Search=${SearchText}`, refresh);

    const loginTrue = leftData.filter((e: GetAllUsersInterface) => e.usaLoggedIn === true)







    return (
        <div className="w-full h-full">
            {/* toolbar */}
            <div className='w-full '>
                <Form
                    {...formPropsConst}
                >
                    <section className='w-full flex space-x-5'>
                        <div className='w-full flex items-center space-x-1'>
                            <InputsTemplate disabledStatus={false} label={"Search"} useCallbackFunc span orderOnchange={
                                (e: string) => {
                                    setTemp(e)
                                }

                            } />
                            <SearchButton isText handleOnClick={
                                () => {
                                    if (Temp.length >= 0) {
                                        console.log("search: ", Temp)
                                        setSearchText(Temp)
                                    }

                                    console.log("true: ", loginTrue);
                                }
                            } />
                        </div>

                        <div className="w-full flex items-center space-x-1 ">
                            <CheckboxTemlate setCheckboxOnchange={
                                (e: boolean) => {
                                    console.log("state: ", refresh)
                                }
                            } customDisabled={false} withBorders={true} />
                            <p className="text-xs">{"Auto Load Form Privileges?:"}</p>
                        </div>
                    </section>

                </Form>

            </div>

            <Suspense fallback={<div>Loading...</div>}>

                <section className='w-full '>
                    <Datagrid_template
                        dataGridRef={dgridRef}
                        gridheight={gridheight}
                        columns={left_access_priv}
                        data={leftData}
                        deselectFirstRow
                        disablePaging
                        onRowClick={(e: GetAllUsersInterface) => {
                            if (e) {
                                setSelectedUser(e)
                            }
                        }}
                    />

                    <p className='w-full text-blue-500 px-5 py-1'>{"Reset Password with SMS "}</p>

                </section>
            </Suspense>

            {/* {
                leftDBLoading ? <div>Loading...</div> :
                    <Suspense fallback={<div>Loading...</div>}>

                        <section className='w-full '>
                            <Datagrid_template
                                gridheight={gridheight}
                                columns={left_access_priv}
                                data={leftData}
                                disablePaging
                            />

                            <p className='w-full text-blue-500 px-5 py-1'>{"Reset Password with SMS "}</p>

                        </section>
                    </Suspense>
            } */}

        </div>
    )
}
