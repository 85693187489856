/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-pascal-case */
import { Form } from "antd";
import { useState, useRef, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";
import { Cancel, ApproveButton } from "../../../../../accessories/buttons";
import {
  navbar_height,
  saveModalData,
} from "../../../../../accessories/component_infos";
import { Fuel_topuptoolbar } from "../../../../../accessories/custom-toolbars/fuel_topuptoolbar";
import { Topup_Requisition_Details_Form } from "./widgets/Requisition_details";
import {
  selectsTypes,
  inputsTypes,
  searchTextTypes,
  statusTypes,
  searchCriteriaTypes,
  formModalStates,
} from "../../../../../human-resource/setups/data/types/selectsTypes";
import { InputsTemplate } from "../../../../../../templates/input";
import { SelectsTemplate } from "../../../../../../templates/select";
import { TextareaTemplate } from "../../../../../../templates/textarea";
import save from "../../../../../../assets/save2.png";
import Datagrid_template from "../../../../../../templates/Datagrid";
import { useDispatch } from "react-redux";
import { task_footer_table_selected } from "../../../../../../features/forms/entries/safetyManager/ppeRequest";
import {
  task_footer_update,
  task_form_disable,
} from "../../../../../../features/Task&Todo/Task/TaskDetails";
import { setGeneralValue } from "../../../../../../features/generalSlice";
import useFetch from "../../../../../../hooks/useFetch";
import dayjs from "dayjs";
import { footer_datagrid_cols } from "./data/datagrid_cols";
import axios from "axios";
import { ModalTemplate } from "../../../../../../templates/modal";
import { useAccountInfo } from "../../../../../../hooks/useAccountInfo";
import useResizeMode from "../../../../../../hooks/useResizeMode";
import { WarningAlert } from "../../../../../accessories/warningAlert";
import { useFormPriviledge } from "../../../../../../hooks/useFormPriviledge";
import { PatchDataFunc } from "../../../../../../functions/patch";
import { PostDataFunc } from "../../../../../../functions/post";
import { useCrudFunc } from "../../../../../../functions/crud";
import { FormModal } from "../../../../../accessories/formModal";
import { Alert_Drivers } from "../../AlertDrivers/AlertDetails";
import { useBusyLoader } from "../../../../../../hooks/useBusyLoader";
import { debounce, set } from "lodash";
import { Fetch_Costcenter } from "../../../../../../features/apiCalls/FuelTopupRequest";
import InputSelect_template from "../../../../../../templates/InputSelect";
import { UpdateStates } from "../../../../../../functions/updateStates";
import { useAxiosService } from "../../../../../../general/requestConfig";
import { Tooltip } from "devextreme-react";

interface props {
  state_change: boolean;
}

interface stateTypes {
  qtyAprv: number;
  qtyTopup: number;
  debit: { id: number; name: string };
  cost: any
  rightRemarks: string;
  costCenter: boolean;
  cancelRemarks: string;
  openFormModalState: boolean;

  isOpenDropdown1: boolean;
  isOpenDropdown2: boolean;
  isOpenDropdownKey1: string;
  isOpenDropdownKey2: string;

  refresh1: boolean;
  refresh2: boolean;
  isLoading?: boolean;
}

export const Topup_Request = ({ state_change }: props) => {
  const dispatch = useDispatch();
  const fetcher = useAxiosService();
  const [employeeId, userId, userInfo] = useAccountInfo();
  const [privType, setPrivType] = useState<string>("save");
  const [savePriv, updatePriv] = useFormPriviledge(privType);

  const formCode = useSelector((state: RootState) => state.user?.formCode);
  const form_disabled = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const form_disable2 = useSelector(
    (state: RootState) => state.addTask.taskFormStatus
  );
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const Fetch_CostCenter_Data = useSelector(
    (state: RootState) => state.FuelTopupRequestSlice.cost_center
  );
  const [formheight, set_form_height] = useState<number>(0);
  const [switch_change, setSwitch_change] = useState<boolean>(true);
  const [busyLoader, setBusyLoader] = useBusyLoader();

  const [initialH, fullH] = useResizeMode(switch_change);

  const formRef = useRef<any>(null);
  var fullPage = fullH - (navbar_height + news_flash_height + 50);
  var pageHeight =
    initialH - (navbar_height + news_flash_height + formheight + 35);

  const switch_toggle = () => {
    if (switch_change) {
      setSwitch_change(!switch_change);
    } else {
      setSwitch_change(!switch_change);
    }
  };

  const [states, setStates] = useState<stateTypes>({
    qtyAprv: 0,
    qtyTopup: 0,
    debit: { id: 0, name: "" },
    cost: { id: 0, name: "" },
    rightRemarks: "",
    costCenter: false,
    cancelRemarks: "",
    openFormModalState: false,

    isOpenDropdown1: false,
    isOpenDropdown2: false,
    isOpenDropdownKey1: "isOpenDropdown1",
    isOpenDropdownKey2: "isOpenDropdown2",

    refresh1: false,
    refresh2: false,
    isLoading: false,
  });

  const updateStates = (key: string, value: any) => {
    setStates((prev: any) => ({ ...prev, [key]: value }));
  };

  const genFormat = "YYYY-MM-DD HH:mm:ss";
  const today = dayjs().format(genFormat);
  const initialStart = dayjs()
    .subtract(1, "month")
    .startOf("months")
    .format(genFormat);
  const [startDate, setStartDate] = useState<searchTextTypes>({
    text: initialStart,
    temp: initialStart,
  });
  const [endDate, setEndDate] = useState<searchTextTypes>({
    text: today,
    temp: today,
  });

  const [refresh, setRefresh] = useState(false);
  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });
  const [activeStatus, setActiveStatus] = useState<statusTypes>({
    temp: "",
    status: "",
  });
  const [searchCriteria, setsearhCriteria] = useState<searchCriteriaTypes>({
    temp: 0,
    index: 0,
  });

  const [selectedRecord, setSelectedRecord] = useState<any>({});
  console.log(selectedRecord, "selectedRecord");
  const [pagination, setPagination] = useState<{
    pageNumber: number;
    pageSize: number;
  }>({
    pageNumber: 1,
    pageSize: 15,
  });
  const debitCategory: any[] = [
    { id: 1, value: "Section" },
    { id: 2, value: "Staff" },
  ];

  const [paging, setPaging] = useState<{
    pageNumber: number;
    pageSize: number;
  }>({
    pageNumber: 1, // backend having issue with their offset counting, it should start from 0 but they started with 1 instead
    pageSize: 20,
  });
  const [costCenter, setCostCenter] = useState<any[]>([]);

  // const [costCenterData] = useFetch(
  //   `FuelTopUpRequest/GetAllCostCenters`,
  //   states.refresh2
  // );
  useEffect(() => {
    fetcher({
      method: "GET",
      url: `FuelTopUpRequest/GetAllCostCenters`,
    }).then((res) => {
      setCostCenter(res);
    });
  }, [states.refresh2]);
  const [DBdata, DBerror] = useFetch(
    `FuelTopUpRequest/SearchFuelTopRequest?StartDate=${startDate.text}&EndDate=${endDate.text}&criteria=${searchCriteria.index}&search=${searchText.text}&chkActive=${activeStatus.status}&pagenumber=${pagination.pageNumber}&pagesize=${pagination.pageSize}`,
    refresh,
    "ftrIDpk"
  );
  const selects1: selectsTypes[] = [
    {
      id: 0,
      label: "Debit Category",
      optionsData: debitCategory,
      dataExpr: "value",
      idExpr: "id",
      stateName: "debit",
      defaultValue: states.debit.name || "",
      isOpenDropdown: states.isOpenDropdown1,
      isOpenDropdownKey: states.isOpenDropdownKey1,
      style: "290px",
      refreshStateName: "refresh1",
      refresh: states.refresh1,
    },
    {
      id: 1,
      label: "Cost Center",
      optionsData: states.cost.length > 0 ? Fetch_CostCenter_Data :  costCenter,
      dataExpr: "cctShtName",
      idExpr: "cctIDpk",
      stateName: "cost",
      singleEndpoint: Fetch_Costcenter,
      defaultValue: states.cost.name || "",
      style: "350px",
      refreshStateName: "refresh2",
      refresh: states.refresh2,
      isOpenDropdown: states.isOpenDropdown2,
      isOpenDropdownKey: states.isOpenDropdownKey2,
    },
  ];

  const inputs: inputsTypes[] = [
    {
      id: 0,
      label: "Qty Approved",
      stateName: "qtyAprv",
      defaultValue: states.qtyAprv,
      style: "210px",
    },
    {
      id: 1,
      label: "Qty Topped Up",
      stateName: "qtyTopup",
      defaultValue: states.qtyTopup,
      style: "210px",
    },
  ];

  const populateFields = async (clear: boolean) => {
    if (clear === true) {
    }

    const gridState = [
      {
        key: "qtyAprv",
        value: clear ? 0 : selectedRecord?.ftrQuantityApproved,
      },
      {
        key: "qtyTopup",
        value: clear ? 0 : selectedRecord?.ftrQuantityToppedUp,
      },
      {
        key: "debit",
        value: clear
          ? { id: 0, name: "" }
          : {
              id: selectedRecord?.ftrDebitCategory,
              name: selectedRecord?.ftrDebitCategory,
            },
      },
      {
        key: "cost",
        value: clear
          ? { id: 0, name: "" }
          : {
              id: selectedRecord?.cctIDpk,
              name: selectedRecord?.ftrCostCenter,
            },
      },
      {
        key: "rightRemarks",
        value: clear ? "" : selectedRecord?.ftrApprovalRmks,
      },
    ];

    for (let instance of gridState) updateStates(instance.key, instance.value);
  };

  useEffect(() => {
    populateFields(false);
  }, [selectedRecord]);

  const searchCriteriaData = [
    { id: 0, value: "Reg. No" },
    { id: 1, value: "Requested By" },
    { id: 2, value: "Recommended  By" },
    { id: 3, value: "Request Type" },
    { id: 4, value: "Approve By" },
  ];

  const TopUpModalData = {
    message: "Are you sure you want to update this request?",
    title: "Update Approved Request",
    okText: "Yes",
    cancelText: "No",
  };

  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [Posting, Updating, Patching] = useCrudFunc();

  const validationForms = async () => {
    setIcon("warning");
    if (states.qtyAprv === 0 || states.qtyAprv === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select data entry for quantity approved.",
        title: " Field Required ",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.qtyTopup === 0 || states.qtyTopup === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select data entry for quantity topped up.",
        title: " Field Required",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.debit.id === 0 || states.debit?.id === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please enter data for debit category.",
        title: "Field Required",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.cost?.id === 0 || states.cost?.id === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please enter data for cost centers.",
        title: "Field Required",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      states.rightRemarks === "" ||
      states.rightRemarks === undefined
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please enter data for approval remarks.",
        title: "Field Required",
        okText: "Ok",
      });
      setOpenModal(true);
    } else {
      setshowModalDisableButton(false);
      setIcon("question");
      setOpenModal(true);
    }
  };

  const validateUpdate = async () => {
    setIcon("warning");
    if (states.qtyAprv === 0 || states.qtyAprv === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select data entry for quantity approved.",
        title: " Field Required ",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.qtyTopup === 0 || states.qtyTopup === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select data entry for quantity topped up.",
        title: " Field Required",
        okText: "Ok",
      });
      setOpenModal(true);
    } else {
      setshowModalDisableButton(false);
      setIcon("question");
      setOpenModal(true);
    }
  };

  const approveAccessPriv = () => {
    setPrivType("update");
    if (updatePriv === true) {
      setModalData({
        title: "Approve Request",
        message: "Are you sure you want to approve this request?",
        okText: "Yes",
        cancelText: "No, Cancel",
      });
      setOpenModal(true);
      validationForms();
    } else {
      setIcon("warning");
      setOpenModal(true);
      setModalData({ title: "Access Denied!", message: "No Access Privilege" });
    }
  };

  const cancelAccessPriv = () => {
    if (savePriv === true) {
      setModalData(CancellationModalData);
      setOpenModal(true);
      setIcon("warning");
    } else {
      setIcon("warning");
      setOpenModal(true);
      setModalData({ title: "Access Denied!", message: "No Access Privilege" });
    }
  };

  const updateAccessPriv = () => {
    setPrivType("update");
    if (updatePriv === true) {
      setModalData(TopUpModalData);
      validateUpdate();
    } else {
      setIcon("warning");
      setOpenModal(true);
      setModalData({ title: "Access Denied!", message: "No Access Privilege" });
    }
  };

  const doubleClickAcessPriv = (e: any) => {
    setPrivType("update");

    if (!updatePriv) {
      setIcon("warning");
      setOpenModal(true);
      setModalData({ title: "Access Denied!", message: "No Access Privilege" });
      // return;
    }

    const isManagerOrDirector = userInfo.empIsDirector || userInfo.empIsManager;

    if (!isManagerOrDirector) {
      //user
      if (e.ftrRecommended) {
        setshowModalDisableButton(true);
        setIcon("warning");
        setOpenModal(true);
        setModalData({
          title: "Access Denied!",
          message: "Please user can't update recommended requests.",
        });
      } else {
        dispatch(task_footer_update(true));
        dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      }
      // return;
    }
    if (isManagerOrDirector) {
      if (e.ftrRecommended && e.ftrApproved) {
        dispatch(task_form_disable(false));
        // setshowModalDisableButton(true);
        // setIcon("warning");
        // setOpenModal(true);
        // setModalData({
        //   title: "Access Denied!",
        //   message: "Please can't approve already approved records!",
        // });
      } else if (e.ftrRecommended) {
        dispatch(task_form_disable(false));
      } else {
        dispatch(task_footer_update(true));
        dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      }
      // return;
    }
    // if (isManagerOrDirector) {
    //   if (e.ftrRecommended && e.ftrApproved) {
    //     setshowModalDisableButton(true);
    //     setIcon("warning");
    //     setOpenModal(true);
    //     setModalData({
    //       title: "Access Denied!",
    //       message: "User can't update recommended records!",
    //     });
    //   } else {
    //     dispatch(task_footer_update(true));
    //     dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
    //   }
    //   // return;
    // }

    // if (e.ftrRecommended) {
    //   if (e.ftrApproved) {
    //     dispatch(task_form_disable(true));
    //     setSelectedRecord(e);
    //     setshowModalDisableButton(true);
    //     setIcon("warning");
    //     setOpenModal(true);
    //     setModalData({
    //       title: "Access Denied!",
    //       message: "Can't approve already approved records! 1",
    //     });
    //   } else {
    //     dispatch(task_form_disable(true));
    //     setSelectedRecord(e);
    //     setshowModalDisableButton(true);
    //     setIcon("warning");
    //     setOpenModal(true);
    //     setModalData({
    //       title: "Access Denied!",
    //       message: "User can't update recommended records! 2",
    //     });
    //   }
    // } else {
    //   dispatch(task_form_disable(true));
    //   setIcon("warning");
    //   setOpenModal(true);
    //   setModalData({
    //     title: "Approval Denied!",
    //     message: "This Record Is Pending Recommendation",
    //   });
    // }
  };

  let res: any = 0;
  const [recordID, setRecordID] = useState(null);

  const msg = `Hi ${selectedRecord?.requesterFirstName}, Fuel Top-Up Request with ID of ${selectedRecord?.ftrIDpk} has been approved and replenished with ${selectedRecord?.ftrQuantityToppedUp} ltrs
  Thanks.
  Sent from InnoX`;

  const msg1 = `Hi ${selectedRecord?.requesterFirstName}, Fuel Top-Up Request with ID of ${selectedRecord?.ftrIDpk} has been approved. You would be informed when your credit is replenished.
  Thanks.
  Sent from InnoX`;

  const msgAlert = `Fuel Top-Up Request with ID of ${selectedRecord?.ftrIDpk} has been approved.`;

  const msgCan = `Hello ${selectedRecord?.requesterFirstName}, Fuel Top-Up Request with ID of ${selectedRecord?.ftrIDpk} has been cancelled by ${userInfo.empName} (${userInfo.empStaffno}). 
  Reason: ${states.cancelRemarks}.
  Sent from InnoX`;

  const checkConnection = () => {
    if (!navigator.onLine) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message:
          "Please your computer is not connected to the Internet. Please check your Internet connection and try again.",
        title: "Connectivity Error!",
        okText: "Ok",
      });
    }
  };

  let mobNox = "";

  const sendSms = async () => {
    try {
      checkConnection();
      if (selectedRecord?.requesterEmpID) {
        mobNox = selectedRecord?.requesterMobNox.trim().replace("", "");
      }
      if (mobNox.trim.length >= 10) {
        mobNox = mobNox.substring(0, 10);
      }

      await Posting(
        `SMSAlert/SaveSMS`,
        {
          imsMessage: selectedRecord.ftrQuantityToppedUp > 0 ? msg : msg1,
          imsSender: userInfo.empName,
          imsRecordIdfk: selectedRecord.vdtIDpk,
          imsFormCode: formCode,
          imsMessageType: "string",
          imsMessageClass: "string",
          imsRecipientEmpIdfk: selectedRecord.driverEmpID,
          imsInitiatorEmpIdfk: userId,
          imsCreatedBy: userId,
        },
        `Sent Fuel Top-Up Request Approval SMS to ${selectedRecord?.recommendedByEmpName5} ${selectedRecord?.recommendedByStaffNo} for Request record with ID`
      );

      await Posting(`USCXGenAlerts/SaveAlert`, {
        recipientEmpID: selectedRecord.requesterEmpID,
        altFormCode: formCode,
        altMessage: msgAlert,
        altTakeAction: true,
      });
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: "SMS sent successfully",
        title: "Success",
        okText: "Ok",
      });
    } catch (error: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: error.response ? error.response.data.message : "Server Error",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };
  const sendSmsCanceled = async () => {
    try {
      checkConnection();
      if (selectedRecord?.requesterEmpID) {
        mobNox = selectedRecord?.requesterMobNox.trim().replace("", "");
      }
      if (mobNox.trim.length >= 10) {
        mobNox = mobNox.substring(0, 10);
      }

      await Posting(`SMSAlert/SaveSMS`, {
        imsMessage: msgCan,
        imsSender: userInfo.empName,
        imsRecordIdfk: selectedRecord.vdtIDpk,
        imsFormCode: formCode,
        imsMessageType: "string",
        imsMessageClass: "string",
        imsRecipientEmpIdfk: selectedRecord.driverEmpID,
        imsInitiatorEmpIdfk: userId,
        imsCreatedBy: userId,
      });

      await Posting(`USCXGenAlerts/SaveAlert`, {
        recipientEmpID: selectedRecord.requesterEmpID,
        altFormCode: formCode,
        altMessage: msgCan,
        altTakeAction: true,
      });
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: "SMS sent successfully",
        title: "Success",
        okText: "Ok",
      });
    } catch (error: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: error.response ? error.response.data.message : "Server Error",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };

  const approveData = async () => {
    setBusyLoader("Approving Request...");
    try {
      res = await Patching(
        `FuelTopUpRequest/ApprovedFuelTopUpRequest?Id=${selectedRecord?.ftrIDpk}`,
        {
          ftrQuantityApproved: Number(states.qtyAprv).toFixed(2),
          ftrQuantityToppedUp: Number(states.qtyTopup).toFixed(2),
          ftrDebitCategory: states.debit?.name,
          ftrCostCenter: states.cost?.name,
          ftrCostCenterIDfk: states.cost?.id,
          ftrApprovalRmks: states.rightRemarks,
          ftrApproved: true,
          ftrCancelled: false,
          ftrApprovedByEmpIDfk: userId,
          ftrApprovedOn: dayjs().format(),
          ftrEditedBy: employeeId,
        },
        `Approved Transport-Fuel Top-Up Request from ${
          selectedRecord?.recommendedByEmpName5
        } ${selectedRecord?.recommendedByStaffNo} for request with ID - ${
          selectedRecord?.ftrIDpk
        } with Quantity Topped Up of ${
          selectedRecord?.ftrQuantityToppedUp
        } ltrs, Quantity Approved of ${
          states.qtyAprv
        } ltrs, Debit Category of ${states.debit?.name} and Cost Center of ${
          states.cost?.name
        } on ${dayjs().format("DD-MM-YYYY")} by ${userInfo.empName} ${
          userInfo.empStaffno
        }`
      );
      sendSms();
      setRecordID(res);
      setRefresh(!refresh);
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: "Approved Request successfully.",
        title: "Request Approved",
        okText: "Ok",
      });
    } catch (e: any) {
      // console.log(e , "ccccc")
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: e.response ? e.response.data.errors.approve : "Server Error",
        title: e.response ? e.response.data.title : "Unexpected Error!",
        okText: "Ok",
      });
    } finally {
      setBusyLoader("");
    }
  };

  const updateTopUp = async () => {
    setBusyLoader("Updating Request...");
    try {
      await Patching(
        `FuelTopUpRequest/ApproveRemarksFuelTopUpRequest?Id=${selectedRecord?.ftrIDpk}`,
        {
          ftrQuantityToppedUp: Number(states.qtyTopup).toFixed(2),
          ftrQuantityApproved: Number(states.qtyAprv).toFixed(2),
          ftrEditedBy: employeeId,
          ftrEditedDate: dayjs().format(),
        },
        `Updated Transport-Fuel Top-Up Request Quantity Topped Up for request with ID- ${
          selectedRecord?.ftrIDpk
        } and Quantity Topped Up of ${states.qtyTopup} ltrs by ${
          userInfo.empName
        } ${userInfo.empStaffno} on ${dayjs().format("DD-MM-YYYY")}`
      );
      setRefresh(!refresh);
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: "Updated approved request successfully!",
        title: "Updated Approved Request",
        okText: "Ok",
      });
    } catch (e: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: e.response ? e.response.data.errors.approve : "Server Error",
        title: e.response ? e.response.data.title : "Unexpected Error!",
        okText: "Ok",
      });
    } finally {
      setBusyLoader("");
    }
  };

  const CancellationModalData = {
    message: "Please enter the reason for cancelling this request.",
    title: "Cancellation Reason",
    okText: "Ok",
    cancelText: "No, Cancel",
  };

  const rejectData = async () => {
    try {
      await Patching(
        `FuelTopUpRequest/RejectFuelTopUpRequest?CancellationRmks=${states.cancelRemarks}&ftrIdpk=${selectedRecord?.ftrIDpk}`,
        {},
        `Cancelled Transport-Fuel Top-Up Request for request with ${selectedRecord?.ftrIDpk}`
      );
      sendSmsCanceled();
      setRefresh(!refresh);
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: "Request cancelled successfully.",
        title: "Request Cancelled",
        okText: "Ok",
      });
    } catch (e: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: e.response ? e.response.data.message : "Server Error",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };

  useEffect(() => {
    set_form_height(formRef.current?.clientHeight + 25);
  }, [refresh]);

  const formModal: formModalStates[] = [
    {
      id: 0,
      label: "Transport- Alert Driver & Permit Holders",
      module: <Alert_Drivers />,
    },
  ];

  let isDisabled;

  if (form_disable2) {
    isDisabled = true;
  } else {
    if (selectedRecord?.ftrApproved) {
      isDisabled = false;
    } else {
      isDisabled = true;
    }
  }

  return (
    <>
      {DBerror && <WarningAlert />}
      <div className="h-full dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base ">
        <FormModal
          openState={states.openFormModalState}
          width={"80%"}
          title={formModal[0].label}
          child={formModal[0].module}
          onCancel={() => {
            updateStates("openFormModalState", false);
          }}
        />
        <ModalTemplate
          icon_width={modalData.iconSize === "large" ? 100 : 50}
          icon={icon}
          cancelText={modalData.cancelText}
          disableCancel={
            icon === "warning" || icon === "success"
              ? true
              : showModalDisableButton
          }
          cancelHandler={() => {
            setOpenModal(false);
          }}
          open={openModal}
          okHandler={() => {
            modalData.title === TopUpModalData.title
              ? updateTopUp()
              : modalData.title === "Approve Request"
              ? approveData()
              : modalData.title === CancellationModalData.title
              ? rejectData()
              : setOpenModal(false);
          }}
          message={modalData.message}
          okText={modalData.okText}
          title={modalData.title}
          withInput2={
            modalData.title === CancellationModalData.title ? true : false
          }
          inputOnchange={(e) => {
            updateStates("cancelRemarks", e);
          }}
        />

        <div>
          <Fuel_topuptoolbar
            toggler={switch_toggle}
            setVisible={setSwitch_change}
            searchTextOnchange={(searchValue) => {
              setSearchText({ temp: searchValue, text: searchText.text });
            }}
            checkOnChange={(check) => {
              setActiveStatus({ temp: check, status: activeStatus.status });
            }}
            handleFind={() => {
              setSearchText({ temp: searchText.temp, text: searchText.temp });
              setActiveStatus({
                temp: activeStatus.temp,
                status: activeStatus.temp,
              });
              setsearhCriteria({
                temp: searchCriteria.temp,
                index: searchCriteria.temp,
              });
              setStartDate({ text: startDate.temp, temp: startDate.temp });
              setEndDate({ text: endDate.temp, temp: endDate.temp });
            }}
            searchCriteriaOnchange={(newIndex, criteria) => {
              setsearhCriteria({ temp: newIndex, index: searchCriteria.index });
            }}
            handleRefresh={() => {
              setSearchText({ temp: "", text: "" });
              setsearhCriteria({ temp: 0, index: 0 });
              setActiveStatus({ temp: "", status: "" });
            }}
            startDate={startDate.temp}
            endDate={endDate.temp}
            startDateChanged={(date) =>
              setStartDate({
                temp: dayjs(date).format(genFormat),
                text: startDate.text,
              })
            }
            endDateChanged={(date) =>
              setEndDate({
                temp: dayjs(date).format(genFormat),
                text: endDate.text,
              })
            }
            outerSelected={selectedRecord}
            setOuterSelected={setSelectedRecord}
            searchCriteriaData={searchCriteriaData}
            handleSMS={() => {
              updateStates("openFormModalState", true);
            }}
          />
        </div>

        {switch_change && (
          <div ref={formRef} className="">
            <div
              style={{ height: "352px" }}
              className={`w-full  px-2 flex ${
                userInfo.empIsDirector === true ||
                (userInfo.empIsManager === true && "justify-between")
              }  border-b-2 pb-0.5 space-x-1`}
            >
              <div
                className="rounded-t-md border-[1px]  border-t-0"
                style={{
                  borderColor: borderTheme,
                  width: `${
                    userInfo.empIsDirector === true ||
                    userInfo.empIsManager === true
                      ? "660px"
                      : "100%"
                  }`,
                }}
              >
                <p
                  id={"form_header2"}
                  style={{ borderColor: borderTheme }}
                  className=" form_headers pt-1 pl-4 w-full border-b-[1px] border-t-[1px]  bg-slate-100 rounded-t-md font-medium"
                >
                  Fuel Requisition Details
                </p>

                <Topup_Requisition_Details_Form
                  selectedRecordx={selectedRecord}
                  setSelectedRecordx={setSelectedRecord}
                  statesx={states}
                  setRefreshx={setRefresh}
                />
              </div>

              {(userInfo.empIsDirector === true ||
                userInfo.empIsManager === true) && (
                <div
                  style={{
                    width: "calc(100% - 660px)",
                    height: "100%",
                    borderColor: borderTheme,
                  }}
                  className=" w-full border-[1px] border-t-0  rounded-t-md items-center dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base"
                >
                  <p
                    id={"form_header2"}
                    style={{ borderColor: borderTheme }}
                    className=" pt-1 pl-4 py-0 w-full border-[1px]  border-l-0 border-r-0 bg-slate-100 rounded-t-md font-medium"
                  >
                    Requisition Approvals
                  </p>
                  <Form
                    className="w-full  py-1 px-2 "
                    name="basic"
                    labelCol={{ span: 3 }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    size="small"
                  >
                    <div className="mb-0.5">
                      {inputs?.map(
                        ({ id, label, defaultValue, stateName, style }) => {
                          return (
                            <InputsTemplate
                              label={label}
                              defaultValue={defaultValue}
                              useCallbackFunc
                              orderOnchange={(e) => {
                                updateStates(stateName!, e);
                              }}
                              disabledStatus={form_disable2}
                              span
                              inputStyle={{ width: style }}
                              additionalWidget={
                                label === "Qty Topped Up" && (
                                  <div className="pl-2.5 ml-[18px]">
                                    <div id="updateButton">
                                      <button
                                        disabled={isDisabled}
                                        onClick={() => {
                                          updateAccessPriv();
                                        }}
                                        style={{
                                          opacity:
                                            form_disable2 ||
                                            !selectedRecord?.ftrApproved
                                              ? 0.5
                                              : 1,
                                          borderColor: borderTheme,
                                        }}
                                        className=" hover:cursor-pointer h-6 dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center px-2 py- border-2 border-slate-200 rounded hover:border-blue-300"
                                      >
                                        <img className="pr-1 w-5" src={save} />
                                        Update
                                      </button>
                                      <Tooltip
                                        target="#updateButton"
                                        showEvent="dxhoverstart"
                                        hideEvent="dxhoverend"
                                        hideOnOutsideClick={true}
                                      >
                                        <p>Click to update</p>
                                      </Tooltip>
                                    </div>
                                  </div>
                                )
                              }
                            />
                          );
                        }
                      )}
                    </div>

                    <div className="w-full">
                      {selects1?.map(
                        ({
                          id,
                          label,
                          optionsData,
                          idExpr,
                          dataExpr,
                          defaultValue,
                          stateName,
                          style,
                          refreshStateName,
                          isOpenDropdown,
                          isOpenDropdownKey,
                          singleEndpoint,
                          refresh,
                        }) => {
                          return (
                            <InputSelect_template
                              key={id}
                              idexpr={idExpr}
                              dataexp={dataExpr}
                              label={label}
                              isOpenDropdown={isOpenDropdown}
                              wrapperCol={9}
                              useCallFunc
                              options={optionsData}
                              placeHolder={defaultValue}
                              disabled={form_disable2}
                              isLoading={states.isLoading}
                              selectedValue={(e) => {
                                updateStates(isOpenDropdownKey, false);
                                updateStates(stateName!, {
                                  id: e[idExpr!],
                                  name: e[dataExpr!],
                                });
                                updateStates(isOpenDropdownKey, false);
                              }}
                              handleRefresh={() => {
                                updateStates(refreshStateName!, !refresh);
                                updateStates(stateName, { id: 0, name: "" });
                              }}
                              handleFocus={(e: any) => {
                                updateStates(
                                  isOpenDropdownKey,
                                  !isOpenDropdown
                                );
                              }}
                              handleSearch={
                                debounce((e) => {
                                  updateStates(stateName!, e);
                                  // UpdateStates("isLoading", true);
                                  dispatch(
                                    singleEndpoint({
                                      search: e,
                                    })
                                  );
                                  updateStates(isOpenDropdownKey, true);
                                }, 500) // Adjust the debounce delay as needed
                              }
                            />
                          );
                        }
                      )}
                    </div>
                    <>
                      <TextareaTemplate
                        height={175}
                        label={"Approval Remark"}
                        labelCol={3}
                        readonly={false}
                        disabled={form_disable2}
                        useCallbackFunc
                        defaultValue={states.rightRemarks}
                        setCallbackValue={(e) => {
                          updateStates("rightRemarks", e);
                        }}
                      />
                    </>
                    <div className="w-full flex flex-col">
                      <Form.Item
                        labelCol={{ span: 3 }}
                        label={<p className="hidden">hidden</p>}
                        colon={false}
                      >
                        <ul className="w-full flex justify-between">
                          <li
                            className="flex items-center flex-row"
                            id="cancel2"
                          >
                            <Cancel
                              useCallbackFunc
                              disableButton={
                                form_disable2
                                  ? true
                                  : selectedRecord?.ftrCancelled
                              }
                              marginLeft="0"
                              cancel_button_toggler={() => {
                                cancelAccessPriv();
                              }}
                            />
                            <Tooltip
                              target="#cancel2"
                              showEvent="dxhoverstart"
                              hideEvent="dxhoverend"
                              hideOnOutsideClick={true}
                            >
                              <p>Click to cancel</p>
                            </Tooltip>
                          </li>
                          <ul className="flex justify-end">
                            <li id="approve">
                              <ApproveButton
                                disableButton={
                                  form_disable2 || selectedRecord?.ftrApproved
                                }
                                handleApprove={() => {
                                  approveAccessPriv();
                                }}
                              />
                              <Tooltip
                                target="#approve"
                                showEvent="dxhoverstart"
                                hideEvent="dxhoverend"
                                hideOnOutsideClick={true}
                              >
                                <p>Click to approve</p>
                              </Tooltip>
                            </li>
                          </ul>
                        </ul>
                      </Form.Item>
                    </div>
                  </Form>
                </div>
              )}
            </div>
          </div>
        )}

        <div
          style={{ height: "100%" }}
          className="px-2 w-full dark:bg-slate-900 dark:text-darkModeSkin-base"
        >
          <Datagrid_template
            handlePageNumber={(page) => {
              setPagination((prev) => ({ ...prev, pageNumber: page }));
            }}
            handlePageSize={(size) => {
              setPagination((prev) => ({ ...prev, pageSize: size }));
            }}
            gridheight={switch_change ? pageHeight : fullPage}
            columns={footer_datagrid_cols}
            data={DBdata}
            rowDoubleClicked={(e) => {
              doubleClickAcessPriv(e);
            }}
            // handlePageSize={(e: number) => {
            //   UpdateStates("pageSize", e, setPaging);
            // }}
            // handlePageNumber={(e: number) => {
            //   UpdateStates("pageNumber", e, setPaging);
            // }}
            pageSize={paging.pageSize}
            pageNumber={paging.pageNumber}
            onRowClick={(e) => {
              dispatch(task_form_disable(true));
              dispatch(task_footer_update(false));
              dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
              dispatch(setGeneralValue({ expr: "updateMode", value: false }));
              setSelectedRecord(e);
            }}
          />
        </div>
      </div>
    </>
  );
};
