import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncGet, GetDataFunc } from "../../../functions/get";

type M_Job_activtiesDetails = {
    job_D: any|null|undefined;
    isError: boolean,
    isLoading: boolean,
    isPending: boolean,
}
const initialState:M_Job_activtiesDetails={
    job_D: [],
    isError: false,
    isLoading: false,
    isPending: false,
}
export const FetchM_Job_activtiesDetails = createAsyncThunk<M_Job_activtiesDetails,string,{rejectValue:string}>(
    'job/Fetch',
    async (search:string) => {
        try{
            var job_response = await AsyncGet(`AsmTmsEntMaintenanceActivity/GetEntAsmTmsPopLueJobs?search=${search}&pagenumber=1&pagesize=20`);
            if(job_response.status===true){
                return job_response.data;
            }
        }catch{
            return job_response.data;
        }
    }
)
const  Job_activtiesDetailsSlice = createSlice({
    name:'JobUpdate',
    initialState:initialState,
    reducers:{},
    extraReducers(builder) {
        builder
        .addCase(FetchM_Job_activtiesDetails.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(FetchM_Job_activtiesDetails.fulfilled, (state, action) => {
            state.isLoading = false;
            state.job_D = action.payload;
        })
        .addCase(FetchM_Job_activtiesDetails.rejected, (state) => {
            state.isLoading = false;
            state.isError = true;
        })
    },
})

export default Job_activtiesDetailsSlice.reducer