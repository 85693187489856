import { Checkbox, Form, Space, Tooltip } from "antd";
import { useState } from "react";
import { CheckboxTemlate } from "../../../../../../templates/checkbox";
import { DateTemplate } from "../../../../../../templates/date";
import { InputsTemplate } from "../../../../../../templates/input";
import { SelectsTemplate } from "../../../../../../templates/select";
import { TextareaTemplate } from "../../../../../../templates/textarea";
import { ApproveButton, Cancel, NewButton, SaveButton, SubmitButton, UpdateButton } from "../../../../../accessories/buttons";
import { selectsTypes } from "../../../../setups/data/types/selectsTypes";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";

interface stateTypes{
    leaveDays:number
    availableLeaveBalance:number
    holidays:number
    weekends:number
}

export const LeaveRequestsForm = () => {
    const disabled: boolean = useSelector(
        (state: RootState) => state.general.formDisabled
    );
    const updateMode: boolean = useSelector(
    (state: RootState) => state.general.updateMode
    );
    
    // component states
    const [states,setStates]=useState<stateTypes>({
        leaveDays:14,
        holidays:3,
        availableLeaveBalance:8,
        weekends:4
    })
    const{leaveDays,holidays,availableLeaveBalance,weekends}=states;

    // selects
    const selects: selectsTypes[] = [
        {id: 0 , label : 'Staff Name' ,idExpr : '', dataExpr : '',optionsData : []},
        {id: 1 , label : 'Recommended By' ,idExpr : '', dataExpr : '',optionsData : []},
        {id: 2 , label : 'Leave Type' ,idExpr : '', dataExpr : '',optionsData : []},
        {id: 3 , label : 'Department' ,idExpr : '', dataExpr : '',optionsData : []},
        {id: 4 , label : 'Section' ,idExpr : '', dataExpr : '',optionsData : []},
        {id: 4 , label : 'Unit' ,idExpr : '', dataExpr : '',optionsData : []}
    ]

    return (
        <div className="px-2">
        {/* FORM HEADER */}
        <p className='pt-1 px-2 w-full font-semibold bg-slate-100'>{'Leave Request Details'}</p>
        <Form
            className='w-full border-2 border-slate-100 rounded-b py-1 px-2'
            name="basic"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 22 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            size='small'
        >
            <div className=" flex justify-between">
                <section style={{width : '450px'}}  className="w-full  bg" >
                    {/* Selects */}
                    {selects.map(({label,idExpr,dataExpr,optionsData}:selectsTypes,index:number)=>
                        <div key={index} className=" mb-0.5">
                            <SelectsTemplate
                            label={label}
                            options={optionsData}
                            idexpr={idExpr}
                            dataexp={dataExpr}
                            />
                        </div>
                    )}

                    {/* Dates */}
                    <Form.Item
                        className=""
                        label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{'Start & End Dates'}</p>}
                        name="Staff No"
                        rules={[{ required: false }]}
                    >
                       <Space wrap>
                            <div className="flex flex-row justify-between">
                                <DateTemplate width={153}/>
                                <DateTemplate style={{marginLeft:4}} width={153}/>
                            </div>
                        </Space>
                    </Form.Item>
                        
                    {/*leave days... Texts */}
                    <Form.Item
                        style={{marginLeft:114,width:363}}
                        className=""
                        labelCol={{span:6}}
                        name="Staff No"
                        rules={[{ required: false }]}
                    >
                        <div className="flex flex-row justify-between">
                            <p className="w-5/12 flex justify-start text-blue-800">{`*Leave Days: ${leaveDays}`}</p>
                            <Tooltip className=" w-7/12 " title='Number of days left of your leave' >
                                <p style={{paddingLeft:19}} className="flex justify-start text-blue-800">{`*Leave Balance: ${availableLeaveBalance}`}</p>
                            </Tooltip>
                        </div>

                        <div className="flex flex-row justify-between">
                            <p className="w-5/12 flex justify-start text-blue-800">{`*Holidays: ${holidays}`}</p>
                            <p style={{paddingLeft:19}} className="w-7/12 flex justify-start text-blue-800">{`*Weekends: ${weekends}`}</p>
                        </div>

                        <div className=" flex flex-row mr-0.5">
                            <Checkbox/>
                            <p className=" ml-1">Pay allowance with monthly salary</p>
                        </div>
                    </Form.Item>

                    {/* allowance pay... select */}
                    <div className=" mb-0.5">
                        <SelectsTemplate
                        label="Allowance Pay Date"
                        />
                    </div>
                </section>

                {/* right section */}
                <section className=" " style={{width : 'calc(100% - 480px)'}}>
                    <Form
                    name="basic"
                    className=" flex flex-col justify-between h-full"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 22 }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    size='small'
                    >
                        <div className=" mt-1 text-blue-800">
                            <p>*</p>
                            <p className=" text-blue-800 mt-1">*</p>
                        </div>

                        <div className="">
                            {/* travelling... select */}
                            <Form.Item
                            labelCol={{span:'3%'}}
                            wrapperCol={{span:24}}
                            >
                                <div style={{marginLeft: 73}} className=" mb-0.5">
                                    <SelectsTemplate
                                    label="Travelling With"
                                    />
                                </div>
                            </Form.Item>

                            <Form.Item
                            labelCol={{span:'3%'}}
                            wrapperCol={{span:24}}
                            >
                                {/* text areas */}
                                <div
                                style={{marginLeft:82}}
                                className="mb-0.5">
                                    <TextareaTemplate 
                                    label={"Travel Details"} 
                                    height={40}               
                                    />
                                </div>
                            </Form.Item>

                            <Form.Item
                            labelCol={{span:'3%'}}
                            wrapperCol={{span:24}}
                            >
                                <div 
                                style={{marginLeft:22}}
                                className=" mb-0.5">
                                    <TextareaTemplate 
                                    label={"Communication Address"} 
                                    height={50}               
                                    />
                                </div>
                            </Form.Item>

                            <Form.Item
                            labelCol={{span:'3%'}}
                            wrapperCol={{span:24}}
                            >
                            <div className=" mb-1">
                                <InputsTemplate
                                span
                                inputStyle={{height:24}}
                                label="Communication Contact Nos"
                                />
                            </div>

                            </Form.Item>
                        </div>
                    </Form>    
                </section>
            </div>

            {/* remarks */}
            <TextareaTemplate
            MarginLeft={55}
            label="Remarks"
            height={70}
            />

            {/*Buttons */}
            <Form.Item
                style={{marginLeft:68}}
                labelCol={{span:0}}
                wrapperCol={{span:25}}
                className=" "
                label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{'Active'}</p>}
                name="Staff No"
                rules={[{ required: false }]}
            >
                <div className=" w-full mt-0.5 flex flex-row justify-between ">
                    {/* active */}
                    <CheckboxTemlate withBorders/>

                    {/* mid */}
                    <span className=" flex flex-row">
                        <span  className=" mr-1">
                            <ApproveButton/>
                        </span>
                        <Cancel text="Reject"/>
                    </span>

                    <span className=" flex flex-row">
                        <div className=" mr-1">
                            {(!updateMode) ? (
                            <SaveButton handleSave={()=>{}} />
                            ) : (
                            <UpdateButton handleUpdate={()=>{}} />
                            )}
                        </div>

                        {(disabled) ? 
                        <NewButton useCallbackFunc new_button_toggler={()=>{}}/> 
                        : 
                        <Cancel  cancel_button_toggler={()=>{}} />}
                        
                        <div className=" ml-1">
                            <SubmitButton/>
                        </div>
                    </span>
                </div>
            </Form.Item>
        </Form>
    </div>
    );
}