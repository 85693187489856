import React, { useState, useEffect, useCallback } from "react";

import { Form, DatePicker, Slider, InputNumber } from "antd";

import dayjs from "dayjs";
import {
  EditButton,
  SaveButton,
  NewButton,
  UpdateButton,
  Cancel,
} from "../../../../../../accessories/buttons";
import useFetch from "../../../../../../../hooks/useFetch";
import {
  currentDate,
  percentMessg,
  saveModalData,
  updateModalData,
} from "../../../../../../accessories/component_infos";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { RootState } from "../../../../../../../app/store";
import { useDispatch, useSelector } from "react-redux";
import { TextareaTemplate } from "../../../../../../../templates/textarea";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import { setGeneralValue } from "../../../../../../../features/generalSlice";
import { UpdateDataFunc } from "../../../../../../../functions/update";
import { PostDataFunc } from "../../../../../../../functions/post";
import { task_footer_table_selected } from "../../../../../../../features/Task&Todo/Task/TaskDetails";
import { SelectsTemplate } from "../../../../../../../templates/select";
import { useCrudFunc } from "../../../../../../../functions/crud";
import { useBusyLoader } from "../../../../../../../hooks/useBusyLoader";
import InputSelect_template from "../../../../../../../templates/InputSelect";
import { debounce } from "lodash";
import { FetchM_Job_Update } from "../../../../../../../features/apiCalls/maintenanceactApi/jobUpdate";
import { Tooltip } from "devextreme-react";

interface props {
  toggler?: () => void;
  backToggler?: () => void;
  datgridCol?: any;
  selectedRecordx?: any;
  setrefreshx?: () => void;
  JobSelectedRecord?: any;
  takeJobUpdateId?: (e: any) => void;
  jobUpdateLookUp?: any;
}

interface stateTypes {
  date?: any;
  complete?: number;
  job?: { id: number; name: string };
  activityUpdate?: string;
  JobSelectedRecord?: any;
  isLoading: boolean;
  isOpenDropdown: boolean;
}

export const Maintenance_activities_update = ({
  toggler,
  backToggler,
  selectedRecordx,
  JobSelectedRecord,
  setrefreshx,
  takeJobUpdateId,
  jobUpdateLookUp,
}: props) => {
  const dateFormat = " DD MMM YYYY";

  const [userId, employeeId, userInfo] = useAccountInfo();

  // Data from redux

  const form_disable = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const updateDate = useSelector(
    (state: RootState) => state.general.updateDate
  );
  const update_state_change = useSelector(
    (state: RootState) => state.general.taskFooterUpdate
  );

  const [states, setStates] = useState<stateTypes>({
    date: currentDate,
    complete: 0,
    job: { id: 0, name: "" },
    activityUpdate: "",
    // JobSelectedRecord: [],
    isLoading: false,
    isOpenDropdown: false,
  });

  const { job } = states;
  console.log("updateStates", states);
  const setState = (key: string, value: any) => {
    setStates((prev: any) => ({ ...prev, [key]: value }));
  };

  //  Current date
  const editedDate = dayjs().format();
  //

  // Modal dialog data states

  const [refreshValue, setRefreshValue] = useState(false);
  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [posting, updating] = useCrudFunc();
  const [busyLoader, setBusyloader] = useBusyLoader();
  const dispatch = useDispatch();

  const populateFields = async (clear?: boolean) => {
    // dispatch(task_footer_table_selected([{}]));

    const gridState = [
      {
        key: "date",
        value: clear ? currentDate : JobSelectedRecord?.mauDate ?? updateDate,
      },
      {
        key: "complete",
        value: clear ? "" : JobSelectedRecord?.mauPercentComplete,
      },
      {
        key: "job",
        value: clear
          ? ""
          : {
              id: JobSelectedRecord?.mauJobIdfk,
              name: JobSelectedRecord?.mjdJob,
            },
      },
      {
        key: "activityUpdate",
        value: clear ? "" : JobSelectedRecord?.mauActivityUpdate,
      },
    ];

    for (let i of gridState) setState(i.key, i.value);
  };

  useEffect(() => {
    populateFields(false);
  }, [JobSelectedRecord]);

  // Validation

  const validationForms = async () => {
    setIcon("warning");
    if (states.date === "" || states.date === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select data entry for activity date.",
        title: " Invalid Date! ",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      dayjs(states.date).isAfter(currentDate) ||
      dayjs(states.date).toString() === currentDate
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please date cannot be greater than today.",
        title: " Invalid Date! ",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.job?.id === 0 || states.job.id === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please enter data for Job.",
        title: "Invalid Job!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.complete === 0 || states.complete === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please enter data for job complete.",
        title: "Invalid Complete!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else {
      setshowModalDisableButton(false);
      setIcon("question");
      setOpenModal(true);
    }
  };

  //Access Priviledge

  const [privType, setPrivType] = useState<string>("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);

  //new button
  const newAccessPriv = () => {
    if (savePriv === true) {
      populateFields(true);
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
    } else {
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({ message: "No Access Privilege", title: "Access Denied!" });
      setOpenModal(true);
    }
  };
  // const percentCheck = () => {
  //   if (states.complete === 100) {
  //     setshowModalDisableButton(true);
  //     setIcon("question");
  //     setModalData(percentMessg);
  //     setOpenModal(true);
  //   }
  // };
  //update button
  const updateAccessPriv = () => {
    if (updatePriv === true) {
      if (states.complete === 100) {
        setIcon("question");
        setModalData(percentMessg);
        setOpenModal(true);
      } else {
        setIcon("question");
        setModalData(updateModalData);
        setOpenModal(true);
      }
      validationForms();
    } else {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({ message: "No Access Privilege", title: "Access Denied!" });
      setOpenModal(true);
    }
  };

  const saveAccessPriv = () => {
    if (updatePriv === true) {
      setModalData(saveModalData);
      setIcon("question");
      setOpenModal(true);
      validationForms();
    } else {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({ message: "No Access Privilege", title: "Access Denied!" });
      setOpenModal(true);
    }
  };

  //edit button
  const smsMessg = `Hello ${selectedRecordx?.requestedByFName}, please be informed that your vehicle maintenance request with ID ${selectedRecordx?.mtaIDpk} has been completed successfully.Thank You. Sent from InnoX`;

  const updateData = async () => {
    try {
      setBusyloader("Updating request");

      await updating(
        `AsmTmsEntMaintenanceActivity/UpdateEntMaintenanceActivityUpdate`,
        {
          mauIdpk: JobSelectedRecord.mauIDpk ?? 0,
          mauDate: dayjs(states.date),
          mauMaintActivityIdfk:
            selectedRecordx !== undefined ? selectedRecordx?.mtaIDpk : 0,
          mauJobIdfk: JobSelectedRecord.mauJobIdfk ?? 0,
          mauActivityUpdate: states.activityUpdate,
          mauPercentComplete: states.complete,
          mauCompletionDate: editedDate,
          mauEditedBy: userId,
        },
        `Updated Maintenance Activity Update with ID ${JobSelectedRecord.mauIDpk} successfully`
      );
      await updating(
        `PmActivitiesCaptures/UpdateAsmTmsMaintenanceRequestProgress`,
        {
          mrqIDpk: selectedRecordx?.mrqIDpk,
          mrqCompletionDate: dayjs(states.date),
          mrqPercentComplete: states.complete,
        },
        ``
      );
      setBusyloader("");
      setBusyloader(" Sending SMS....");

      if (states.complete === 100) {
        await posting(
          `SMSAlert/SaveSMS`,
          {
            imsMessage: smsMessg,
            imsRecipientEmpIdfk:
              selectedRecordx?.requestedByEmpId ===
              selectedRecordx?.reqDriverEmpID
                ? selectedRecordx?.requestedByEmpId
                : selectedRecordx?.reqDriverEmpID &&
                  selectedRecordx?.requestedByEmpId,
            imsSender: userInfo.empName,
            imsRecordIdfk: selectedRecordx?.mtaIDpk,
            // imsFormCode: ,
            imsMessageType: "string",
            imsMessageClass: "string",
            imsInitiatorEmpIdfk: userId,
            imsCreatedBy: userId,
          },
          `Sent Asset Mgr-Trans-Maintenance Alert to employee (Driver & Approv. Officer) with Emp ID ${
            selectedRecordx?.requestedByEmpId ===
            selectedRecordx?.reqDriverEmpID
              ? selectedRecordx?.requestedByEmpId
              : selectedRecordx?.reqDriverEmpID
          } and staff No ${
            selectedRecordx?.reqDriverStaffNo ===
            selectedRecordx?.requestedByStaffNo
              ? selectedRecordx?.requestedByStaffNo
              : selectedRecordx?.reqDriverStaffNo
          } successfully `
        );
      }
      setIcon("success");
      setRefreshValue(!refreshValue);
      setrefreshx && setrefreshx();
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: "Job update updated successfully!",
        title: "Record Updated successfully",
        okText: "Ok",
      });
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      setBusyloader("");
    } catch (e: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: "Failed to update Record!..Please contact your administrator",
        title: "Unexpected Error!",
        okText: "Ok",
      });
      setBusyloader("");
    }
  };

  // alert( selectedRecordx.mtaIDpk )

  const postData = async () => {
    try {
      setBusyloader("Saving job update...");
      await posting(
        `AsmTmsEntMaintenanceActivity/CreateEntMaintenanceActivityUpdate`,
        {
          mauDate: dayjs(states.date),
          mauMaintActivityIdfk:
            selectedRecordx !== undefined ? selectedRecordx?.mtaIDpk : 0,
          mauJobIdfk: job?.id,
          mauActivityUpdate: states.activityUpdate,
          mauPercentComplete: states.complete,
          mauCompletionDate: editedDate,
          mauCreatedBy: userId,
        },
        `Created Maintenance Activity Update with ID ${selectedRecordx?.mtaIDpk} successfully`
      );
      setRefreshValue(!refreshValue);
      setrefreshx && setrefreshx();
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: "Job-update saved successfully!",
        title: "Record Saved successfully!",
        okText: "Ok",
      });
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      setBusyloader("");
      //
    } catch (e: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: "Failed to save record, please contact your administrator",
        title: "Unexpected Error!",
        okText: "Ok",
      });
      setBusyloader("");
    }
  };

  const [Job_data] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetEntAsmTmsLueJobs?activityId=${selectedRecordx?.mtaIDpk}`
  );
  console.log(Job_data);
  const handleChange = (value: any) => {
    setState("isOpenDropdown", !states.isOpenDropdown);
    setState("job", { id: value.mjdIdpk, name: value.mjdJob });
    takeJobUpdateId && takeJobUpdateId(value.mjdIdpk);
  };
  return (
    <div
      onDoubleClick={backToggler}
      style={{ height: "100%" }}
      className="h-full px-1 py-1 dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base"
    >
      <div className="flex  w-full h-full bg-slate-50 ">
        <ModalTemplate
          icon={icon}
          cancelText={modalData.cancelText}
          cancelHandler={() => {
            setOpenModal(false);
          }}
          open={openModal}
          okHandler={() => {
            modalData === saveModalData
              ? postData()
              : modalData === updateModalData
              ? updateData()
              : modalData === percentMessg
              ? updateData()
              : setOpenModal(false);
          }}
          message={modalData.message}
          okText={modalData.okText}
          title={modalData.title}
          disableCancel={icon === "question" ? false : true}
        />
        <p
          id={"form_header7"}
          className="w-full py-1 px-2 bg-slate-100 dark:bg-darkModeSkin-div_headers_backgroundColor dark:text-darkModeSkin-headers"
        >
          Update
        </p>
      </div>

      {/* Form */}
      <div className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base">
        <Form
          className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-1 px-2 "
          name="basic"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          size="small"
          layout="horizontal"
          disabled={form_disable}
        >
          <Form.Item
            wrapperCol={{ span: 15 }}
            label={
              <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                {"Activity Date"}
              </p>
            }
            className="mb-1 "
          >
            <div className=" dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center ">
              <DatePicker
                value={dayjs(states.date)}
                onChange={(date, dateString) => {
                  setState("date", dateString);
                }}
                format={dateFormat}
                allowClear={false}
              />
            </div>
          </Form.Item>
          <InputSelect_template
            useCallFunc
            label="Job"
            idexpr="mjdIdpk"
            dataexp="mjdJob"
            placeHolder={job?.name}
            options={Job_data}
            isLoading={states.isLoading}
            isOpenDropdown={states.isOpenDropdown}
            selectedValue={handleChange}
            handleFocus={() => {
              setState("isOpenDropdown", !states.isOpenDropdown);
            }}
            handleSearch={
              debounce((e: string) => {
                handleChange(e);
                dispatch(
                  FetchM_Job_Update({
                    search: e,
                    id: selectedRecordx?.mtaIDpk,
                  }) as unknown as any
                );
              }, 500) // Adjust the debounce delay as needed
            }
          />
          {/* <SelectsTemplate
            label={"Job"}
            dataexp={"mjdJob"}
            idexpr={"mjdIdpk"}
            placeHolder={job?.name}
            useCallFunc
            options={Job_data}
            selectedValue={handleChange}
          /> */}

          <Form.Item
            wrapperCol={{ span: 24 }}
            className="mb-1"
            label={
              <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                {"% Complete"}
              </p>
            }
          >
            <div className="w-full flex dark:bg-slate-100 dark:text-darkModeSkin-base">
              <Slider
                style={{ width: "100%" }}
                className="mr-5"
                min={0}
                max={100}
                onChange={(e) => {
                  setState("complete", e);
                }}
                value={states.complete}
              />
              <InputNumber
                className="h-6 w-16 flex items-center justify-center mt-1"
                size="small"
                min={0}
                max={100}
                value={states.complete}
                onChange={(e) => {
                  setState("complete", e);
                }}
              />
            </div>
          </Form.Item>

          <Form.Item
            wrapperCol={{ span: 24 }}
            style={{ display: "inline-block", marginBottom: "2px" }}
            label={
              <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                {"Activity Update "}
              </p>
            }
            className="w-full mb-1"
          >
            <div className=" dark:bg-slate-900 dark:text-darkModeSkin-base">
              <TextareaTemplate
                height={274}
                useCallbackFunc
                defaultValue={states.activityUpdate}
                setCallbackValue={(e) => {
                  setState("activityUpdate", e);
                }}
              />
            </div>
          </Form.Item>
        </Form>
      </div>

      <Form
        labelCol={{ span: 5 }}
        className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded "
      >
        <div className="w-full flex px-3 flex-row items-start justify-between pb-2 ml-1 ">
          <div className="w-[47%] ">
            <Form.Item
              labelCol={{ span: 10 }}
              label={<p className="hidden"></p>}
              colon={false}
            >
              <div id="editBtn" className=" ">
                <EditButton />
                <Tooltip
                  target="#editBtn"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">Edit job update</p>
                </Tooltip>
              </div>
            </Form.Item>
          </div>

          <ul className="w-1/3 flex items-center justify-end gap-4">
            {update_state_change === true ? (
              <div id="updateBtn">
                <UpdateButton
                  disableButton={form_disable}
                  handleUpdate={updateAccessPriv}
                />
                <Tooltip
                  target="#updateBtn"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">Update job </p>
                </Tooltip>
              </div>
            ) : (
              <div id="saveBtn">
                <UpdateButton
                  disableButton={form_disable}
                  handleUpdate={saveAccessPriv}
                />
                <Tooltip
                  target="#saveBtn"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className=""> job update</p>
                </Tooltip>
              </div>
            )}
            {form_disable === true ? (
              <div id="newBtn">
                <NewButton
                  new_button_toggler={() => {
                    newAccessPriv();
                  }}
                  useCallbackFunc={true}
                />
                <Tooltip
                  target="#newBtn"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">Create new job update</p>
                </Tooltip>
              </div>
            ) : update_state_change === true ? (
              <div>
                <Cancel />
              </div>
            ) : !(update_state_change && form_disable) ? (
              <Cancel />
            ) : (
              <div>
                <NewButton useCallbackFunc={true} />
              </div>
            )}
          </ul>
        </div>
      </Form>
    </div>
  );
};
