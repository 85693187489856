/* eslint-disable react/jsx-pascal-case */
import React, { useState } from "react";
import { SearchButton } from "../../../accessories/buttons";
import { DateTemplate } from "../../../../templates/date";
import Datagrid_template from "../../../../templates/Datagrid";
import useResizeMode from "../../../../hooks/useResizeMode";
import {
  inputsTypes,
  searchCriteriaTypes,
  searchTextTypes,
} from "../../../human-resource/setups/data/types/selectsTypes";
import { DatePicker, Form } from "antd";
import { auditDetCol } from "../data/datagridCol";
import useFetch from "../../../../hooks/useFetch";
import dayjs from "dayjs";
import { currentDate, graphStartingDate } from "../../../accessories/component_infos";
import CustomLoader from "../../../accessories/custom-loader";

export const AuditTrailDets = () => {
  const [initial, final] = useResizeMode(window.innerHeight);
  const gridheight = initial - 262;
  const [refreshstate, setRefreshstate] = useState<boolean>(false);

  // Search criteria states
  const genFormat = "YYYY-MM-DD";


  const [criteria, setCriteria] = useState<searchCriteriaTypes>({
    temp: 0,
    index: 0,
  });
  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });
  const [startDate, setStartDate] = useState<searchTextTypes>({
    text: graphStartingDate,
    temp: graphStartingDate,
  });
  const [endDate, setEndDate] = useState<searchTextTypes>({
    text: currentDate,
    temp: currentDate,
  });

  const [auditDetData, auditDetError, auditDetLoading] = useFetch(
    `AuditTrail/PopGdcAuditTrailDetails?Startdate=${startDate.text}&EndDate=${endDate.temp}&searchText=${searchText.temp}&criteria=${criteria.index}`,
    refreshstate
  );

  const dates: inputsTypes[] = [
    { id: 0, label: "", stateName: "", defaultValue: "", style: "" },
    { id: 0, label: "", stateName: "", defaultValue: "", style: "" },
  ];

  return (
    <>
      {auditDetLoading && <CustomLoader text="Fetching Data" />}
      <div className="w-full -full border-[1px] rounded-sm ">
        <Form
          className="w-full rounded py-1 px-2"
          name="basic"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          size="small"
        >
          {/* Top toolbar */}
          <section className="w-full flex space-x-2 justify-end items-center ">
            <ul className=" flex flex-row items-center mt-0.5">
              <li className="px-0.5">
                <DateTemplate
                  selectedDate={dayjs(startDate.temp)}
                  changeDate={(date) =>
                    setStartDate({
                      temp: dayjs(date).format(genFormat),
                      text: startDate.text,
                    })
                  }
                  style={{ width: 125 }}
                  format={"DD MMM YYYY"}
                  disabled={false}
                />
              </li>

              <li className="px-0.5">
                <DateTemplate
                  selectedDate={dayjs(endDate.temp)}
                  changeDate={(date) =>
                    setEndDate({
                      temp: dayjs(date).format(genFormat),
                      text: endDate.text,
                    })
                  }
                  style={{ width: 125 }}
                  format={"DD MMM YYYY"}
                  disabled={false}
                />
              </li>
            </ul>

            <SearchButton />
          </section>

          {/* Grid */}

          <section className="">
            <Datagrid_template
              gridheight={gridheight}
              columns={auditDetCol}
              data={auditDetData}
            />
          </section>
        </Form>
      </div>
    </>
  );
};
