import { Form } from "antd"
import TextArea from "antd/es/input/TextArea"

interface SelectProps {
    textheight:number
    labelCol?: number
    wrapperCol?: number,
    onChange?: any,
    disabled?: boolean,
    value?: any,
    label?: any
    readOnly?: boolean
    borderTheme ?: any

}

export const StrategicThemeTextArea = ({ label, textheight, labelCol, onChange, value, readOnly,borderTheme }: SelectProps) => {

    return (
        <>

            <Form.Item
                labelCol={{ span: labelCol }}
                wrapperCol={{ span: 24 }}
                label={<p className='dark:bg-fill dark:text-darkModeSkin-base text-xs'>{label}</p>}
            >
                <div className="flex flex-row mb-0.5">
                    <textarea readOnly={readOnly} value={value} onChange={onChange} style={{ resize: 'none', height: textheight-3, borderColor: borderTheme }} 
                    className='w-full overflow-y-scroll border-gray-300 border-[1px] mb-0 rounded focus:border-blue-500 hover:border-blue-500 focus:outline-none hover:outline-none px-[4px] ' />

                </div>
            </Form.Item>

        </>
    )

}