

export const other_details_TH = [
  {
    id: 0,
    caption: "PARAMETER",
    dataField: "parameter",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 1,
    caption: "VALUE",
    dataField: "value",
    alignment: "left",
    fixed: true,
    width: null
  },
  {
    id: 2,
    caption: "REMARKS",
    dataField: "remarks",
    alignment: "left",
    fixed: false,
    width: 4000
  },
  

]


export const question_table_data = [
  
]
