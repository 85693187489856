export const hr_setups_district_columns = [
    {id : 0, caption : 'ID',dataField : 'dstIDpk',datatype : 'number', width : 80, fixed : true, visible : false},
    {id : 1, caption : 'DISTRICT', dataField : 'dstName',datatype : 'string', width : 160, fixed : true , visible : true},
    {id : 2, caption : 'SHORT NAME', dataField : 'dstShtName',datatype : 'string', width : 160, fixed : true , visible : true},
    // {id : 3, caption : 'DISTRICT CAPITAL', datafield : 'dstCapial',datatype : 'string', width : 160, fixed : true , visible : true},
    {id : 3, caption : 'COUNTRY', dataField : 'ctyName',datatype : 'string', width : 160, fixed : true , visible : true},
    {id : 4, caption : 'REGION', dataField : 'rgnName',datatype : 'string', width : 160, fixed : true , visible : true},
    {id : 5, caption : 'ORDER', dataField : 'dstOrder',datatype : 'number', width : 70, fixed : true , visible : true},
    {id : 6, caption : 'ACTIVE', dataField : 'dstActive',datatype : 'boolean', width : 90, fixed : true , visible : true},
    {id : 7, caption : 'REMARKS',dataField : 'dstRemarks',datatype : 'string', width : 5000, fixed : false , visible : true}
]
