/* eslint-disable react/jsx-pascal-case */
import {
  forwardRef,
  useEffect,
  useRef,
  useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { RootState } from "../../../../../../app/store";
import { setGeneralValue } from "../../../../../../features/generalSlice";
import { useAccountInfo } from "../../../../../../hooks/useAccountInfo";
import { useBusyLoader } from "../../../../../../hooks/useBusyLoader";
import useFetch from "../../../../../../hooks/useFetch";
import { useFormPriviledge } from "../../../../../../hooks/useFormPriviledge";
import useResizeMode from "../../../../../../hooks/useResizeMode";
import Datagrid_template from "../../../../../../templates/Datagrid";
import { ModalTemplate } from "../../../../../../templates/modal";
import {
  navbar_height,
  toolbar_height,
} from "../../../../../accessories/component_infos";
import { HrSetupsCustomToolbar } from "../../../../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import { WarningAlert } from "../../../../../accessories/warningAlert";
import {
  searchTextTypes,
  statusTypes,
} from "../../../../../human-resource/setups/data/types/selectsTypes";
import { exams_question } from "./data/exams_question";
import ExamsQuestionsForm from "./widgets/examsQuestionsForm";

export const TMSExamsQuestions = forwardRef(({ a }: any, ref) => {
  const dispatch = useDispatch();

  const setupPageRoute = window.location.pathname;
  const datagridOnlySearchParam = "table-only";
  const formModeSearchParam = "form-and-table";
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  let [resizeMode, initialResizeMode] = useResizeMode(
    searchParams.get("view") === formModeSearchParam ||
    searchParams.get("view") === null
  );


  const miscHeight = 7;




  const [form_height, set_form_height] = useState<number>(400);

  const toggle_staff_form = (): void => {
    const view = searchParams.get("view");

    view === null || view === formModeSearchParam
      ? navigate({
        pathname: setupPageRoute,
        search: createSearchParams({
          view: datagridOnlySearchParam,
        }).toString(),
      })
      : navigate({
        pathname: setupPageRoute,
        search: createSearchParams({
          view: formModeSearchParam,
        }).toString(),
      });
  };

  const [refresh, setRefresh] = useState<boolean>(false);
  const [busyLoader, setBusyLoader] = useBusyLoader();

  const [updateMode, setupdateMode] = useState<boolean>(false);
  const [disabledMode, setDisabledMode] = useState<boolean>(true);

  const [seletedData, setSelectedData] = useState({});

  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });
  const [status, setStatus] = useState<statusTypes>({ temp: "", status: "" });


  const [data, error, loading] = useFetch(
    `AsmTmsExamsQuestion/GetAllAsmTmsExamsQuestions?search=${searchText.text}&status=${status.status}`,
    refresh
  );
  useEffect(() => {
    loading ? setBusyLoader(".") : setBusyLoader("");
  }, [loading]);


  const pageHeight = resizeMode - (navbar_height + miscHeight + 490);
  const tableHeight = initialResizeMode - 188;

  const examsRef = useRef<any>();
  // const [empID, userId, userInfo] = useAccountInfo();
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(
    "update",
  );
  const [modalState, setModalState] = useState<{
    open: boolean;
    message: string;
    title: string;
  }>({
    open: false,
    message: "",
    title: "",
  });

  const ondbAccessPriv = () => {
    if (updatePriv === true) {

      dispatch(setGeneralValue({ expr: "updateMode", value: true }));


      dispatch(setGeneralValue({ expr: "formDisabled", value: false! }));
    } else {
      setModalState({
        open: true,
        message: "No Access Privileges",
        title: "Access Denied!",
      });
    }
  };

  return (
    <>
      {/* get all questions error */}
      {error && <WarningAlert />}

      <ModalTemplate
        icon="warning"
        disableCancel={true}
        message={modalState.message}
        title={modalState.title}
        open={modalState.open}
        okHandler={() => {
          setModalState({ ...modalState, open: false });
        }}
      />

      <div className="h-full">
        <div className="px-2">
          <HrSetupsCustomToolbar
            searchTextOnchange={(newText) => {
              setSearchText({ temp: newText, text: searchText.text });
            }}
            checkOnChange={(newStatus) => {
              setStatus({ temp: newStatus, status: status.status });
            }}
            handleFind={() => {
              setRefresh((current) => !current);
              setSearchText({ temp: searchText.temp, text: searchText.temp });
              setStatus({ temp: status.temp, status: status.temp });
            }}

            handleRefresh={() => {
              setRefresh(!refresh)
              setSearchText({ temp: "", text: "" });
              setStatus({ temp: "", status: "" });
            }}
            withDates={false}
            toggler={toggle_staff_form}
            outerSelected={seletedData}
            setOuterSelected={setSelectedData}
          />
        </div>

        {/* form section */}
        {searchParams.get("view") !== datagridOnlySearchParam && (
          <section style={{ height: form_height }} className=" form border-b-4 overflow-hidden">
            <ExamsQuestionsForm
              refresh={() => setRefresh(!refresh)}
              updateMode={updateMode}
              formDisabled={disabledMode}
              setUpdate={(state) => setupdateMode(state)}
              setDisabled={(state) => setDisabledMode(state)}
              selectedData={seletedData}
              setOuterSelected={setSelectedData}
              data={data}
              ref={examsRef}
            />
          </section>
        )}

        {/* datagrid section */}
        <section className=" px-2">
          <Datagrid_template
            gridheight={
              searchParams.get("view") === formModeSearchParam ||
                searchParams.get("view") === null
                ? pageHeight
                : tableHeight
            }
            data={data}
            columns={exams_question}
            rowDoubleClicked={() => {
              ondbAccessPriv();
            }}
            onRowClick={(e: any) => {

              dispatch(setGeneralValue({ expr: "updateMode", value: false }));


              dispatch(setGeneralValue({ expr: "formDisabled", value: true }));

              setSelectedData(e);
            }}
          />
        </section>
      </div>
    </>
  );
});
