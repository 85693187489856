/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useId, useState } from "react";
import "devextreme/dist/css/dx.light.css";
import { CheckBox } from "devextreme-react/check-box";
import { Tooltip } from "devextreme-react";
import {
  AlertSMS,
  AppraiseSettings,
  DeleteTask,
  TaskSettings,
} from "./widgets/customToolbarModals";
import find from "../../../assets/binoculars.png";
import question from "../../../assets/question.png";
import edit from "../../../assets/edit.png";
import close from "../../../assets/close.png";
import pen from "../../../assets/pen.png";
import statusLoader from "../../../assets/statusloader.gif";
import newfile from "../../../assets/new.png";
import refresh from "../../../assets/refresh.png";
import printer from "../../../assets/printer.png";
import settings from "../../../assets/settings.png";
import alter from "../../../assets/alter.png";
import { Checkbox, DatePicker, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import {
  setGeneralValue,
  settask_form_disable,
  settask_footer_update,
} from "../../../features/generalSlice";
import {
  task_form_disable,
  task_footer_update,
} from "../../../features/Task&Todo/Task/TaskDetails";
import { FaBinoculars } from "react-icons/fa";
import { ThreeStateCheckboxTemlate } from "../../../templates/threeStateCheckbox";
import dayjs from "dayjs";
import { ModalTemplate } from "../../../templates/modal";
import { useFormPriviledge } from "../../../hooks/useFormPriviledge";
import { useAccountInfo } from "../../../hooks/useAccountInfo";
import {
  setBulk_Cancel_Button,
  setDisabled_Form,
  setEdit_Cancel_Button,
  setNew_Cancel_Button,
  setSave_Update_Button,
  setEditing_Bulk_Cancel
} from "../../../features/VehicleRedux/TMS-Sicers/Maintenance/MaintenanceScheduleSlice";
import { runScheduleAlert } from "../component_infos";

// //Action Creator

// const setIsBulkAction = (value: boolean) => ({
//   type: "SET_IS_BULK",
//   payload: value,
// });

interface props {
  toggler?: () => void;
  handleDelete?: () => void;
  handleBulkEdit?: () => void;
  onBulkEditCancel?: () => void;
  handleSave?: () => void; //save handler
  handleNewClick?: (value: boolean) => void;
  handleEdit?: (value: boolean) => void;
  handleUpdate?: () => void; //update handler
  searchTextOnchange?: (value: string) => void; //search text change
  startDateChanged?: (value: any) => void; //start date change
  endDateChanged?: (value: any) => void; //end date change
  checkOnChange?: (value: any) => void; //checkbox change
  handleFind?: (startDate, endDate, searchValue) => void; //find handler
  handleRefresh?: () => void; //refresh main grid
  handlePrint?: () => void; // handle prints functionality
  handleToggle?: () => void;
  handleToggleAll?: () => void;
  searchCriteriaOnchange?: (index: number, criteria: string) => void;
  checkBoxAfterHelpOnchange?: (checkValue: boolean) => void; // event handler for checkbox after help
  disableNew_edit?: boolean;
  withDates?: boolean; //add dates to the toolbar?
  outerSelected?: any;
  setOuterSelected?: (state: any) => void;
  checkBoxAfterHelp?: boolean;
  searchCriteria?: boolean;
  assignmentsForm?: boolean;
  searchCriteriaData?: { id: number; value: string }[];
  startDate?: string;
  endDate?: string;
  setVisible?: () => void;
  runAlert?: () => void;
}

export const Maintenance_schedulestoolbar = ({
  toggler,
  handleDelete,
  handleBulkEdit,
  // startDate,
  // endDate,
  handleToggle,
  handleToggleAll,
  setOuterSelected,
  searchCriteriaOnchange,
  checkBoxAfterHelp = false,
  searchCriteriaData = [],
  assignmentsForm,
  checkBoxAfterHelpOnchange,
  searchCriteria,
  handleNewClick,
  handleRefresh,
  handlePrint,
  outerSelected,
  disableNew_edit,
  withDates = true,
  searchTextOnchange,
  handleSave,
  //   handleUpdate: handleEdit,
  // startDateChanged,
  // endDateChanged,
  handleFind,
  checkOnChange,
  setVisible,
  handleEdit,
  onBulkEditCancel,
  runAlert,
}: props) => {
  const { Search } = Input;

  const dateFormat = "DD MMM YYYY";
  const idx = useId();

  const dispatch = useDispatch();

  //..........................
  const fdispatch = useDispatch();

  const NewCancelButton = useSelector(
    (stae: RootState) => stae.TMSMaintenance.New_cancel_Button
  );
  const EditCancelButton = useSelector(
    (state: RootState) => state.TMSMaintenance.Edit_Cancel_Button
  );
  const SaveUpdateButton = useSelector(
    (state: RootState) => state.TMSMaintenance.Save_Update_Button
  );
  const DisabledForm = useSelector(
    (state: RootState) => state.TMSMaintenance.Disabled_form
  );
  const Bulkedit = useSelector(
    (state: RootState) => state.TMSMaintenance.Bulk_Cancel_Button
  );

  const ExtraBulkCheck = useSelector(
    (state: RootState) => state.TMSMaintenance.BulkEditing_Cancel_Button
  );

  const [openModal, setOpenModal] = useState(false);

  const [icon, setIcon] = useState<"warning" | "success" | "question">();

  const runAlertData = async () => { };

  //.........................

  const [searchValue, setSearchValue] = useState<string>("");
  const [criteriaDataLocal, setCriterialDataLocal] = useState({
    id: searchCriteriaData![0]?.id ?? 0,
    value: searchCriteriaData![0]?.value ?? "",
  });
  const [startDate, setStartDate] = useState(
    dayjs().subtract(5, "years").format("YYYY-MM-DD")
  );
  const [endDate, seEndDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [indeterminate, setIndeterminate] = useState<any>(true);
  const [checkedBox, setCheckedBox] = useState<any>(false);
  const [checkValue, setCheckValue] = useState<boolean | string>(""); //actual value to use
  // const [searchTextValue, setSearchTextValue] = useState('')
  const [warningModal, setWarningModal] = useState<boolean>(false);
  const [warningModalData, setWarningModalData] = useState<any>({
    title: "",
    message: "",
  });
  const [employeeId, userId, userInfo] = useAccountInfo();
  const [privtype, setPrivType] = useState<string>("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privtype);

  useEffect(() => {
    dispatch(setGeneralValue({ expr: "activeStatus", value: checkValue }));
    checkOnChange && checkOnChange(checkValue);
  }, [checkValue]);

  const onChange = (value: boolean) => {
    if (indeterminate === true) {
      setCheckedBox(true);
      setIndeterminate(false);
      setCheckValue(true);
    } else if (!indeterminate && checkedBox) {
      setCheckedBox(false);
      setIndeterminate(false);
      setCheckValue(false);
    } else if (!indeterminate && !checkedBox) {
      setCheckedBox(false);
      setIndeterminate(true);
      setCheckValue("");
    }
  };
  // let test = false;

  const bulkEdit = () => {
    if (outerSelected) {
      setVisible && setVisible();
      // console.log("Over Here outerSelected", outerSelected)

      if (Object.keys(outerSelected).length < 1) {
        //...................
        fdispatch(setNew_Cancel_Button(true));
        fdispatch(setEdit_Cancel_Button(false));
        fdispatch(setSave_Update_Button(true));
        fdispatch(setDisabled_Form(true));
        fdispatch(setBulk_Cancel_Button(true));

        //...................

        // kick modal
        setWarningModal(true);
        setWarningModalData({
          message: "Please select a record",
          title: "Required",
        });
        dispatch(task_form_disable(true));
      } else {
        //  setWarningModalData({message:'No Access Privilege', title:'Access Denied!'})
        console.info("Over Here outerSelected");
        fdispatch(setNew_Cancel_Button(true));
        handleBulkEdit && handleBulkEdit();
        // fdispatch(setEdit_Cancel_Button(true));
        fdispatch(setSave_Update_Button(false));
        // fdispatch(setDisabled_Form(false));
        // fdispatch(setBulk_Cancel_Button(false));


        setVisible && setVisible();
        fdispatch(setEdit_Cancel_Button(true));
        fdispatch(setDisabled_Form(false));
        fdispatch(setBulk_Cancel_Button(false));
        fdispatch(setEditing_Bulk_Cancel(false));

      }
    } else {
      //enable forms
      dispatch(task_form_disable(false));
      fdispatch(setEdit_Cancel_Button(false));


      //enable Update Mode
      dispatch(task_footer_update(true));
    }
  };

  const editAccessPriv = () => {
    if (savePriv === true) {
      dispatch(task_footer_update(true));
      if (outerSelected) {
        if (Object.keys(outerSelected).length < 1) {
          //...................
          fdispatch(setNew_Cancel_Button(true));
          fdispatch(setEdit_Cancel_Button(true));
          fdispatch(setSave_Update_Button(true));
          fdispatch(setDisabled_Form(true));
          fdispatch(setBulk_Cancel_Button(true));
          fdispatch(setEditing_Bulk_Cancel(true));


          //...................

          // kick modal
          setWarningModal(true);
          setWarningModalData({
            message: "Please select a record",
            title: "Required",
          });
          dispatch(task_form_disable(true));
        } else {
          setVisible && setVisible();

          //  setWarningModalData({message:'No Access Privilege', title:'Access Denied!'})
          fdispatch(setNew_Cancel_Button(true));
          fdispatch(setEdit_Cancel_Button(false));
          fdispatch(setSave_Update_Button(false));
          fdispatch(setDisabled_Form(false));
          fdispatch(setBulk_Cancel_Button(true));
          fdispatch(setEditing_Bulk_Cancel(true));

        }
      } else {
        //enable forms
        dispatch(task_form_disable(false));

        //enable Update Mode
        dispatch(task_footer_update(true));
      }
    } else {
      dispatch(task_form_disable(true));

      //enable Update Mode
      dispatch(task_footer_update(false));
      dispatch(setGeneralValue({ expr: "formDisabledPriv", value: true }));
      setWarningModal(true);
      setWarningModalData({
        message: "No Access Privilege",
        title: "Access Denied!",
      });
    }
  };

  const findAccessPriv = () => {
    setPrivType("find");
    if (readPriv === true) {
      dispatch(setGeneralValue({ expr: "formDisabledPriv", value: false }));
    } else {
      dispatch(setGeneralValue({ expr: "formDisabledPriv", value: true }));
      setWarningModal(true);
      setWarningModalData({
        message: "No Access Privilege",
        title: "Access Denied",
      });
    }
  };

  const newAccessPriv = () => {
    setPrivType("save");
    if (savePriv === true) {
      //......................
      setVisible && setVisible();
      fdispatch(setNew_Cancel_Button(false));
      fdispatch(setEdit_Cancel_Button(true));
      fdispatch(setSave_Update_Button(true));
      fdispatch(setDisabled_Form(false));
      fdispatch(setBulk_Cancel_Button(true));
      fdispatch(setEditing_Bulk_Cancel(true));
      setOuterSelected && setOuterSelected({});

      dispatch(setGeneralValue({ expr: "isShowDblTable", value: true }));
       
      //.....................

      //   resetFields()
      //   dispatch(setGeneralValue({ expr: 'formDisabledPriv', value: false }));
      //   dispatch(setGeneralValue({ expr: 'formDisabled', value: false }));
      //   dispatch(setGeneralValue({ expr: "formDisabled", value: false }));

      // dispatch(task_form_disable(false));
      // dispatch(settask_form_disable(false));

      // //  this destroys the update mode state
      // dispatch(task_footer_update(false));
      // dispatch(settask_footer_update(false))

      //clear data to redux
        // dispatch(setGeneralValue({ expr: "selectedFieldData", value: [] }));
    } else {
      // dispatch(setGeneralValue({ expr: "formDisabledPriv", value: true }));
      // dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      fdispatch(setNew_Cancel_Button(true));
      fdispatch(setEdit_Cancel_Button(true));
      fdispatch(setSave_Update_Button(true));
      fdispatch(setEditing_Bulk_Cancel(true));
      fdispatch(setDisabled_Form(true));
      fdispatch(setBulk_Cancel_Button(true));
      setWarningModal(true);
      setWarningModalData({
        message: "No Access Privilege",
        title: "Access Denied!",
      });
    }
  };

  // from redux
  const disable = useSelector((state: RootState) => state.general.formDisabled);
  const disablex = useSelector(
    (state: RootState) => state.addTask.taskFormStatus
  );
  const updateMode = useSelector(
    (state: RootState) => state.addTask.taskFooterUpdate
  );
  const selectedRecord = useSelector(
    (state: RootState) => state.safetyManagerDetails.selectedRecord
  );
  const isBulk = useSelector((state: RootState) => state.general.isBulkEdit);
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const sideAlert = useSelector(
    (state: RootState) => state.general.sideAlertTest
  );
  const clear_fields = useSelector(
    (state: RootState) => state.general.clearFields
  );

  //   useEffect(() => {
  //     if (isBulk === undefined) {
  //       console.log("ASASASASASASASASA");
  //       dispatch(setIsBulkAction(false));
  //     }
  //   }, [isBulk, dispatch]);
  // const handleSearchedText = useCallback((e:any)=>{
  //         const searchText: string = e.target.value;
  //         searchTextOnchange && searchTextOnchange(searchText);
  //         setSearchValue(e.target.value);

  // },[])

  // relative to the new button
  const handleNew = () => {
    newAccessPriv();
    handleNewClick && handleNewClick(true);
  };

  const handleCancel = () => {
    //..................
    fdispatch(setNew_Cancel_Button(true));
    fdispatch(setEdit_Cancel_Button(true));
    fdispatch(setSave_Update_Button(true));
    fdispatch(setDisabled_Form(true));
    fdispatch(setBulk_Cancel_Button(true));
    fdispatch(setEditing_Bulk_Cancel(true));
    //...................

    // dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    dispatch(task_form_disable(true));
    dispatch(settask_form_disable(true));
    // dispatch(update_route_state(false))
    dispatch(task_footer_update(false));
    dispatch(settask_footer_update(false));

    // // dispatch(settask_footer_update(false))
    // dispatch(setGeneralValue({ expr: 'updateMode', value: false }));

    // NO ONE SHOULD DELETE THIS !!!!
    // handleCallbackCancel!();
  };

  //   const handleBulkEdit = () => {
  //     fdispatch(setNew_Cancel_Button(true));
  //     fdispatch(setEdit_Cancel_Button(true));
  //     fdispatch(setSave_Update_Button(false));
  //     fdispatch(setDisabled_Form(false));
  //   };

  const setModal = () => {
    setWarningModal(true);
    setWarningModalData({
      title: "Select record",
      message: "Please select a record to edit",
    });
  };

  const cancelModal = () => {
    setWarningModal(false);
  };

  //handle edit
  const handleUpdate = () => {
    editAccessPriv();
  };

  const handleSearchedText = (searchText: string) => {
    searchTextOnchange && searchTextOnchange(searchText);
    setSearchValue(searchText);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText: string = e.target.value;
    handleSearchedText(searchText);
  };

  //   alert(handleBulkEdit)
  return (
    <>
      <ModalTemplate
        icon={"warning"}
        title={warningModalData.title}
        message={warningModalData.message}
        open={warningModal}
        cancelHandler={cancelModal}
        disableCancel={true}
        okText={"Ok"}
        okHandler={cancelModal}
      />
      {/*       
      <ModalTemplate
        icon={icon}
        cancelText={modalData.cancelText}
        // disableCancel={showModalDisableButton}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        open={openModal}
        okHandler={() => {
          modalData.title === runScheduleAlert.title
            ? runAlertData()
            : setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
      /> */}
      <div
        style={{ width: "4000px", borderColor: borderTheme }}
        className=" h-full border-[1px] rounded px-1 flex justify-between border-gray-200 mb-1 "
      >
        <div className="flex justify-center">
          <ul className="flex justify-evenly w-full items-center h-full py-0.5">
            {/* new and cancel section */}
            {NewCancelButton ? (
              <li
                id="newRecord"
                onClick={() => handleNew()}
                style={{
                  borderWidth: "1px",
                  borderColor: borderTheme,
                  width: "61px",
                }}
                className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
              >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                  {" "}
                  <img className="pr-1 " src={newfile} />
                  New{" "}
                </span>
                <Tooltip
                  target="#newRecord"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">New Record</p>
                </Tooltip>
              </li>
            ) : (
              <li
                id="close-cancel"
                onClick={() => {
                  handleCancel(); 
                  dispatch(setGeneralValue({ expr: "isShowDblTable", value: false }));
                }}
                style={{
                  borderWidth: "1px",
                  borderColor: borderTheme,
                  width: "73px",
                }}
                className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
              >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                  {" "}
                  <img className="pr-1 " src={close} />
                  Cancel{" "}
                </span>
                <Tooltip
                  target="#close-cancel"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">Cancel</p>
                </Tooltip>
              </li>
            )}
            {/* {(disablex || updateMode) ?
                            <li id="newRecord" onClick={() => handleNew()} style={{ borderWidth: '1px', borderColor: borderTheme, width: '61px' }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center" >
                                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img className="pr-1 " src={newfile} />New </span>
                                <Tooltip
                                    target="#newRecord"
                                    showEvent="dxhoverstart"
                                    hideEvent="dxhoverend"
                                    hideOnOutsideClick={true}
                                >
                                    <p className="">New Record</p>
                                </Tooltip>
                            </li>
                            :
                            <li id="close-cancel" onClick={() => handleCancel()} style={{ borderWidth: '1px', borderColor: borderTheme, width: '73px' }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center" >
                                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img className="pr-1 " src={close} />Cancel </span>
                                <Tooltip
                                    target="#close-cancel"
                                    showEvent="dxhoverstart"
                                    hideEvent="dxhoverend"
                                    hideOnOutsideClick={true}
                                >
                                    <p className="">Cancel</p>
                                </Tooltip>
                            </li>
                        } */}
            {EditCancelButton ? (
              <li
                id="editRecordst"
                onClick={() => {
                  handleUpdate();
                }}
                style={{ borderWidth: "1px", borderColor: borderTheme }}
                className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
              >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                  {" "}
                  <img
                    className="dark:bg-slate-900 pr-1 dark:text-gray-100 duration-100"
                    src={edit}
                  />
                  Edit{" "}
                </span>
                <Tooltip
                  target="#editRecordst"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">Edit Record</p>
                </Tooltip>
              </li>
            ) : (
              <li
                id="close-cancel"
                onClick={() => handleCancel()}
                style={{ borderWidth: "1px", borderColor: borderTheme }}
                className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
              >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                  {" "}
                  <img className="pr-1 " src={close} />
                  Cancel{" "}
                </span>
                <Tooltip
                  target="#close-cancel"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">Cancel</p>
                </Tooltip>
              </li>
            )}
            {/* {!updateMode ?
                        
                            <li id="editRecordst" onClick={() => { handleUpdate() }} style={{ borderWidth: '1px', borderColor: borderTheme }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center">
                                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img className="dark:bg-slate-900 pr-1 dark:text-gray-100 duration-100" src={edit} />Edit </span>
                                <Tooltip
                                    target="#editRecordst"
                                    showEvent="dxhoverstart"
                                    hideEvent="dxhoverend"
                                    hideOnOutsideClick={true}
                                >
                                    <p className="">Edit Record</p>
                                </Tooltip>
                            </li>
                            :
                            <li id="close-cancel" onClick={() => handleCancel()} style={{ borderWidth: '1px', borderColor: borderTheme }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center" >
                                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img className="pr-1 " src={close} />Cancel </span>
                                <Tooltip
                                    target="#close-cancel"
                                    showEvent="dxhoverstart"
                                    hideEvent="dxhoverend"
                                    hideOnOutsideClick={true}
                                >
                                    <p className="">Cancel</p>
                                </Tooltip>
                            </li>
                        } */}

            {/* <li id="pen" style={{ borderWidth: '1px' }} onClick={handleBulkEdit} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center">
                        <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img className="dark:bg-slate-900  dark:text-darkModeSkin-base duration-100" src={pen} /></span>
                        <Tooltip
                            target="#pen"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p className="">Bulk edit - edit all items saved with the same Batch No</p>
                        </Tooltip>
                    </li> */}

            {(Bulkedit === true && NewCancelButton) ||
              NewCancelButton === false ? (
              <li
                id="pen"
                style={{ borderWidth: "1px", borderColor: borderTheme }}
                onClick={bulkEdit}
                className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
              >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                  {" "}
                  <img
                    className="dark:bg-slate-900  dark:text-darkModeSkin-base duration-100"
                    src={pen}
                  />
                </span>
                <Tooltip
                  target="#pen"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">
                    Bulk edit - edit all items saved with the same Batch No
                  </p>
                </Tooltip>
              </li>
            ) : (
              <li
                id="close-cancel"
                onClick={() => {
                  handleCancel();
                  onBulkEditCancel && onBulkEditCancel();
                }}
                style={{ borderWidth: "1px", borderColor: borderTheme }}
                className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
              >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                  {" "}
                  <img className="pr-1 " src={close} />
                  Cancel{" "}
                </span>
                <Tooltip
                  target="#close-cancel"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">Cancel</p>
                </Tooltip>
              </li>
            )}

            <li
              id="switch"
              onClick={toggler}
              style={{
                borderWidth: "1px",
                borderColor: borderTheme,
                width: "72px",
              }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img
                  className="dark:bg-slate-900 pr-1 dark:text-darkModeSkin-base duration-100"
                  src={alter}
                />
                Switch{" "}
              </span>
              <Tooltip
                target="#switch"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Switch interface / table view</p>
              </Tooltip>
            </li>

            <li
              id="settings"
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              onClick={runAlert}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img
                  className="dark:bg-slate-900  dark:text-darkModeSkin-base duration-100"
                  src={settings}
                />
              </span>
              <Tooltip
                target="#settings"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Run and send maintenance schedule alerts</p>
              </Tooltip>
            </li>

            <li
              id="close"
              onClick={handleDelete}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img
                  className="dark:bg-slate-900  dark:text-darkModeSkin-base duration-100"
                  src={close}
                />
              </span>
              <Tooltip
                target="#close"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p>Delete selected schedule</p>
              </Tooltip>
            </li>

            <li
              id="help"
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center"
            >
              <span className="flex">
                {" "}
                <img
                  className="dark:bg-slate-900  dark:text-gray-100 duration-100"
                  src={question}
                />{" "}
              </span>
              <Tooltip
                target="#help"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p>Help Center</p>
              </Tooltip>
            </li>

            {/* Differents starts here */}

            <li className="items-right ">
              <label htmlFor={"searchCriteria" + idx} className="text-xs px-2">
                Search Criteria
              </label>
              <Select
                value={criteriaDataLocal}
                dropdownMatchSelectWidth={false}
                className=" mt-0.5"
                onChange={(data: any) => {
                  const criteriaData = JSON.parse(data);
                  setCriterialDataLocal({
                    id: criteriaData?.index,
                    value: criteriaData?.criteria,
                  }); //local
                  searchCriteriaOnchange &&
                    searchCriteriaOnchange(
                      criteriaData?.index,
                      criteriaData?.criteria
                    ); //external
                }}
                id={"searchCriteria" + idx}
                size="small"
                showSearch={true}
                style={{ width: 110, height: "25px" }}
              >
                {searchCriteriaData?.map(({ id, value }, index) => (
                  <Select.Option
                    key={id}
                    value={JSON.stringify({ index: index, criteria: value })}
                  >
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </li>

            <li className="px-1 flex items-center ml-14 ">
              <label htmlFor={"search-" + idx} className="flex text-xs px-2">
                Search Text
              </label>
              <Input
                onChange={handleInputChange}
                value={searchValue}
                name="search"
                id={"search-" + idx}
                size="small"
                // allowClear={true}
                allowClear={false}
                style={{ width: 130, height: "24px" }}
              />
            </li>
            <li className="px-0.5">
              <DatePicker
                allowClear={false}
                value={dayjs(startDate) ?? dayjs()}
                clearIcon={<span style={{ display: "none" }}></span>}
                onChange={(val) => {
                  setStartDate(dayjs(val).format("YYYY-MM-DD"));
                }}
                // onChange={startDateChanged}
                size="small"
                placeholder="Start Date"
                style={{ width: 125 }}
                format={dateFormat}
                disabledDate={(current) => {
                  return current && current.valueOf() > Date.now();
                }}
              />
            </li>
            <li className="px-0.5">
              <DatePicker
                allowClear={false}
                value={dayjs(endDate) ?? dayjs()}
                onChange={(val) => {
                  seEndDate(dayjs(val).format("YYYY-MM-DD"));
                }}
                clearIcon={<span style={{ display: "none" }}></span>}
                size="small"
                placeholder="End Date"
                style={{ width: 125 }}
                format={dateFormat}
                disabledDate={(current) => {
                  return current && current.valueOf() > Date.now();
                }}
              />
            </li>

            <li
              id="Maintescheacticestatus"
              className=" h-[26px] px-1 ml-0.5  hover:cursor-pointer mr-1 border-slate-200 text-xs  text-gray-600 rounded flex items-center"
            >
              <Checkbox
                style={{ borderColor: borderTheme }}
                className="border-[1px] rounded px-1"
                checked={checkedBox}
                indeterminate={indeterminate}
                onChange={(checkValue: any) => {
                  onChange(checkValue);
                }}
              />
              <Tooltip
                target="#Maintescheacticestatus"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p>Active Status</p>
              </Tooltip>
            </li>

            <li
              onClick={() => {
                handleRefresh && handleRefresh();
                setSearchValue("");

                setCheckedBox(false);
                setIndeterminate(true);
                setCheckValue("");
                setSearchValue("");
              }}
              style={{ borderColor: borderTheme }}
              className="hover:cursor-pointer h-[26px] border-[1px]  mr-1 border-slate-200 text-xs px-1 text-gray-600 rounded flex items-center"
            >
              <span>
                {" "}
                <img
                  className="w-4  dark:bg-slate-900 dark:text-gray-100 duration-100"
                  src={refresh}
                />{" "}
              </span>
            </li>
            <li
              id="find"
              onClick={() => {
                if (handleFind) {
                  handleFind(startDate, endDate, searchValue);
                } else {
                }
              }}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1 text-gray-600 rounded flex items-center"
            >
              <span>
                {" "}
                <FaBinoculars size={15.5} color="#007AFF" />{" "}
              </span>
              <Tooltip
                target="#find"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p>Find</p>
              </Tooltip>
            </li>
            <li className="hover:cursor-pointer ml-20  px-2  flex items-center">
              {EditCancelButton && NewCancelButton ? (
                <p className=" italic font-semibold text-gray-600">Done.</p>
              ) : EditCancelButton === true && NewCancelButton === false ? (
                <>
                  <img
                    style={{ width: "31px", height: "31px" }}
                    src={statusLoader}
                    alt="gif"
                  />
                  <p className=" italic text-gray-600">New Record</p>
                </>
              ) : EditCancelButton === false && NewCancelButton === true ? (
                <>
                  <img
                    style={{ width: "31px", height: "31px" }}
                    src={statusLoader}
                    alt="gif"
                  />
                  <p className=" italic text-gray-600">Editing record</p>
                </>
              ) : (
                <p className=" italic font-semibold text-gray-600">Done.</p>
              )}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

// const data = [
//     { id: 1, text: "Task",  },
//     { id: 2, text: "Progress Activity",  },
//     { id: 3, text: "Task & Start Date",  },
//     { id: 4, text: "Task & Completion Date",  },
//     { id: 5, text: "Task & Due Date", },
//     { id: 6, text: "Progress Activity & Start Date", },
//     { id: 7, text: "Progress Activity & Completion Date",  },
//     { id: 8, text: "Progress Activity & Due Date", },
//     { id: 9, text: "Status", },
//     { id: 10, text: "Status & Start Date", },
//     { id: 11, text: "Status & Completion Date", },
//     { id: 12, text: "Status & Due Date", },
//     { id: 13, text: "Priority", },
//     { id: 14, text: "Priority & Start Date", },
//     { id: 15, text: "Priority & Completion Date", },
//     { id: 16, text: "Priority & Due Date", },
//     { id: 17, text: "Assigned By", },
//     { id: 18, text: "Assigned By & Start Date", },
//     { id: 19, text: "Assigned By & Completion Date", },
//     { id: 20, text: "Assigned By & Due Date", },
//     { id: 21, text: "Assigned To", },
//     { id: 22, text: "Assignred To & Start Date", },
//     { id: 23, text: "Assigned To & Completion Date", },
//     { id: 24, text: "Assigned To & Due Date", },
// ];
