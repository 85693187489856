import { TabPanel } from 'devextreme-react'
import { Item } from 'devextreme-react/tab-panel'
import React from 'react'
import { SentSMS } from './widgets/sentSMS'
import { PendingSMS } from './widgets/pendingSMS'

const SMSLogs = () => {
    return (
        <div className='w-full h-full pt-0.5'>
            <TabPanel className="w-full h-full px-0.5 ">

                <Item title={"Sent Mesages"}>
                    <div className=' pt-1'>
                       <SentSMS />
                    </div>

                </Item>

                <Item title={"Pending Messages"}>
                    <div className='w-full h-full pt-1'>
                        <PendingSMS />
                    </div>
                </Item>

            </TabPanel>

        </div>
    )
}


export default SMSLogs;
