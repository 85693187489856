/* eslint-disable react/jsx-pascal-case */
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import { RootState } from "../../../../../../app/store";
import { setGeneralValue } from "../../../../../../features/generalSlice";
import useFetch from "../../../../../../hooks/useFetch";
import Datagrid_template from "../../../../../../templates/Datagrid";
import {
  navbar_height,
  toolbar_height,
} from "../../../../../accessories/component_infos";
import { HrSetupsCustomToolbar } from "../../../../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import { assetsSetups_ISP_columns } from "./data/serviceProvidersData";
import { ServiceProvidersForm } from "./widgets/serviceProvidersForm";
import { searchTextTypes } from "../../../../../human-resource/setups/data/types/selectsTypes";
import { statusTypes } from "../../../../../human-resource/setups/data/types/selectsTypes";
import { WarningAlert } from "../../../../../accessories/warningAlert";
import { useBusyLoader } from "../../../../../../hooks/useBusyLoader";

export const Assets_setups_ServiceProviders = forwardRef(({ a }: any, ref) => {
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  // on page resize height responsiveness
  const [trigger, setTrigger] = useState<boolean>(false);
  useImperativeHandle(ref, () => ({
    test: () => {
      setTrigger(!trigger);
    },
  }));

  const [busyLoader, setBusyLoader] = useBusyLoader();

  const dispatch = useDispatch();

  //navigation
  const setupPageRoute = window.location.pathname; //get current route of page
  const datagridOnlySearchParam = "table-only"; //search param in datagrid mode
  const formModeSearchParam = "form-and-table"; //search param in form mode mode
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  //miscellaneous heights
  const miscHeight = 7;

  // All heights out
  const heights_out = navbar_height + news_flash_height + toolbar_height;

  //varying form height
  const [form_height, set_form_height] = useState<number>(291.5);

  // Page resize height responsiveness
  useEffect(() => {
    settableHeight(window.innerHeight - (heights_out + miscHeight - 4));
  }, []);

  // handle interface switch
  const toggle_staff_form = (): void => {
    const view = searchParams.get("view");

    view === null || view === formModeSearchParam
      ? navigate({
          pathname: setupPageRoute,
          search: createSearchParams({
            view: datagridOnlySearchParam,
          }).toString(),
        })
      : navigate({
          pathname: setupPageRoute,
          search: createSearchParams({
            view: formModeSearchParam,
          }).toString(),
        });
  };

  // redux
  const [refresh, setRefresh] = useState<boolean>(false);

  const resetTable = () => {
    if (refresh) {
      setRefresh(!refresh);
    } else {
      setRefresh(!refresh);
    }
  };
  // alert(refresh)

  //Table Height
  const pageHeight = window.innerHeight - (navbar_height + miscHeight); //height without navbar
  const [tableHeight, settableHeight] = useState(window.innerHeight); //height without navbar and toolbar

  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });
  const [status, setstatus] = useState<statusTypes>({ temp: "", status: "" });

  // Endpoints
  const [DBdata, DBerror, DBloading] = useFetch(
    `AsmTmsServiceProviders/GetAllAsmTmsServiceProviders?spdName=${searchText.text}&spdActive=${status.status}`,
    refresh,
    "spdIdpk"
  );
  useEffect(() => {
    DBloading ? setBusyLoader(".") : setBusyLoader("");
  }, [DBloading]);

  const [selectedFieldData, setSelectedFieldData] = useState({});
  const onChangeRecord: any = useCallback<any>((e: any) => {
    // setSelectedTask_update(item);
    setSelectedFieldData(e);
    dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    dispatch(setGeneralValue({ expr: "updateMode", value: false }));
    dispatch(setGeneralValue({ expr: "SelectedFieldID", value: e.spdIdpk }));
  }, []);

  // double click feature
  const allowUpdate = (e: any) => {
    setSelectedFieldData(e);
    dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
    dispatch(setGeneralValue({ expr: "updateMode", value: true }));
  };

  return (
    <>
      {DBerror && <WarningAlert />}

      <div style={{ height: pageHeight }} className="w-full">
        <div className="px-2">
          <HrSetupsCustomToolbar
            checkOnChange={(newCheck) => {
              setstatus({ temp: newCheck, status: status.status });
            }}
            searchTextOnchange={(newText) => {
              setSearchText({ temp: newText, text: searchText.text });
            }}
            handleFind={() => {
              setSearchText({ temp: searchText.temp, text: searchText.temp });
              setstatus({ temp: status.temp, status: status.temp });
              setRefresh((current) => !current);
            }}
            withDates={false}
            toggler={toggle_staff_form}
            outerSelected={selectedFieldData}
            setOuterSelected={setSelectedFieldData}
          />
        </div>

        {/* form section */}
        {searchParams.get("view") !== datagridOnlySearchParam && (
          <section style={{ height: form_height }} className="form border-b-2">
            <ServiceProvidersForm
              selectedRecordx={selectedFieldData}
              resetTable={resetTable}
            />
          </section>
        )}

        {/* datagrid section */}
        <section className="mt-1 px-2">
          <Datagrid_template
            onRowClick={onChangeRecord}
            rowDoubleClicked={allowUpdate}
            gridheight={
              searchParams.get("view") === formModeSearchParam ||
              searchParams.get("view") === null
                ? window.innerHeight - (form_height + heights_out + miscHeight)
                : tableHeight
            }
            data={DBdata}
            columns={assetsSetups_ISP_columns}
          />
        </section>
      </div>
    </>
  );
});
