export const footer_datagrid_SPTL_cols = [
  {
    id: 1,
    caption: "VRFD?",
    dataField: "fmgVerified.",
    dataType: "",
    fixed: false,
    width: 50,
  },
  {
    id: 2,
    caption: "REG. NO",
    dataField: "vdtRegNo",
    dataType: "string",
    fixed: false,
    width: 100,
  },
  {
    id: 3,
    caption: "DATE",
    dataField: "fmgDate",
    dataType: "datetime",
    fixed: false,
    width: 100,
  },
  {
    id: 4,
    caption: "TIME",
    dataField: "fmgTime",
    dataType: "datetime",
    fixed: false,
    width: 100,
  },
  {
    id: 5,
    caption: "MONTH",
    dataField: "monthx",
    dataType: "string",
    fixed: false,
    width: 100,
  },
  {
    id: 6,
    caption: "CARD No.",
    dataField: "cdtCardNo",
    dataType: "string",
    format: "dd MMM yyyy",
    fixed: false,
    width: 100,
  },
  {
    id: 7,
    caption: "PRODUCT",
    dataField: "prdShtName",
    dataType: "string",
    width: 100,
  },
  {
    id: 8,
    caption: "DRIVER CODE",
    dataField: "fddClosingBalance",
    dataType: "string",
    width: 100,
  },
  {
    id: 9,
    caption: "PURCHASED BY",
    dataField: "fddOpeningMeterReading",
    dataType: "string",
    width: 100,
  },
  {
    id: 10,
    caption: "TANK CAP.",
    dataField: "fddClosingMeterReading",
    dataType: "string",
    width: 100,
  },
  {
    id: 11,
    caption: "QUANTITY",
    dataField: "fddOpeningDipBalance",
    dataType: "string",
    width: 100,
  },
  {
    id: 12,
    caption: "UNIT PRICE",
    dataField: "",
    dataType: "string",
    width: 90,
  },
  {
    id: 13,
    caption: "AMOUNT",
    dataField: "fmgAmount",
    dataType: "string",
    width: 100,
  },
  {
    id: 14,
    caption: "BALANCE",
    dataField: "fmgBalance",
    dataType: "string",
    width: 100,
  },
  {
    id: 15,
    caption: "REFILL INTVL(Hrs)",
    dataField: "fddRmks",
    dataType: "string",
    width: 100,
  },
  {
    id: 16,
    caption: "REFILL INTVL(Days)",
    dataField: "fddRmks",
    dataType: "string",
    width: 100,
  },
  {
    id: 17,
    caption: "STATION",
    dataField: "sstName",
    dataType: "string",
    width: 100,
  },
  {
    id: 18,
    caption: "RECIEPT No.",
    dataField: "fmgReceiptNo",
    dataType: "string",
    width: 100,
  },
  {
    id: 19,
    caption: "CURRENT MILEAGE",
    dataField: "fddRmks",
    dataType: "string",
    width: 100,
  },
  {
    id: 20,
    caption: "PAST MILEAGE",
    dataField: "fddRmks",
    dataType: "string",
    width: 100,
  },
  {
    id: 21,
    caption: "MILEAGE",
    dataField: "fddRmks",
    dataType: "string",
    width: 100,
  },
  {
    id: 22,
    caption: "OPERATION TYPE",
    dataField: "fddRmks",
    dataType: "string",
    width: 100,
  },
  {
    id: 23,
    caption: "DRIV. STAFF No.",
    dataField: "fddRmks",
    dataType: "string",
    width: 100,
  },
  {
    id: 24,
    caption: "VECHILE DEPT",
    dataField: "vehAssignDeptName",
    dataType: "string",
    width: 100,
  },
  {
    id: 25,
    caption: "DRIVER DEPT",
    dataField: "fddRmks",
    dataType: "string",
    width: 100,
  },
  {
    id: 26,
    caption: "CARD NAME",
    dataField: "cctName",
    dataType: "string",
    width: 100,
  },
  {
    id: 27,
    caption: "STATION No.",
    dataField: "fddRmks",
    dataType: "string",
    width: 100,
  },
  {
    id: 28,
    caption: "CARD TYPE",
    dataField: "fddRmks",
    dataType: "string",
    width: 100,
  },
  {
    id: 29,
    caption: "PRODUCT CODE",
    dataField: "fddRmks",
    dataType: "string",
    width: 100,
  },
  {
    id: 30,
    caption: "CURRENCY No",
    dataField: "fddRmks",
    dataType: "string",
    width: 100,
  },
  {
    id: 31,
    caption: "CURRENCY",
    dataField: "fddRmks",
    dataType: "string",
    width: 100,
  },
  {
    id: 32,
    caption: "INVOICE No",
    dataField: "fddRmks",
    dataType: "string",
    width: 100,
  },
  {
    id: 33,
    caption: "INVOICE DATE",
    dataField: "fddRmks",
    dataType: "string",
    width: 100,
  },
  {
    id: 34,
    caption: "CUSTOMER",
    dataField: "fddRmks",
    dataType: "string",
    width: 100,
  },
  {
    id: 35,
    caption: "CUST. No",
    dataField: "",
    dataType: "string",
    width: 100,
  },
  {
    id: 36,
    caption: "ACTIVE?",
    dataField: "fmgActive",
    dataType: "string",
    width: 90,
  },
  {
    id: 37,
    caption: "REMARKS",
    dataField: "fddRmks",
    dataType: "string",
    width: 4000,
  },
];

export const footer_datagrid_SPTL_colsB = [
  {
    id: 1,
    caption: "ID?",
    dataField: "fmgIDpk.",
    dataType: "",
    fixed: false,
    width: 60,
  },
  {
    id: 2,
    caption: "VRFD. NO",
    dataField: "vdtRegNo",
    dataType: "string",
    fixed: false,
    width: 100,
  },
  {
    id: 3,
    caption: "LCKD?",
    dataField: "fmgLocked",
    dataType: "boolean",
    fixed: false,
    width: 80,
  },
  {
    id: 4,
    caption: "AUTH?",
    dataField: "fmgAuthorised",
    dataType: "boolean",
    fixed: false,
    width: 80,
  },
  {
    id: 5,
    caption: "ACTIVE?",
    dataField: "fmgActive",
    dataType: "boolean",
    fixed: false,
    width: 100,
  },
  {
    id: 6,
    caption: "REG. No.",
    dataField: "cdtCardNo",
    dataType: "string",
    fixed: false,
    width: 100,
  },
  {
    id: 7,
    caption: " REQUEST DATE",
    dataField: "fmgDate",
    dataType: "datetime",
    format: "dd MMM yyyy",
    width: 150,
  },
  {
    id: 8,
    caption: "DISPENSE DATE ",
    dataField: "fmgDateDispensed",
    dataType: "datetime",
    format: "dd MMM yyyy",
    width: 150,
  },
  {
    id: 9,
    caption: "RECEIVED BY",
    dataField: "driverName",
    dataType: "string",
    width: 150,
  },
  {
    id: 10,
    caption: "TIME.",
    dataField: "fddClosingMeterReading",
    dataType: "string",
    width: 100,
  },
  {
    id: 11,
    caption: "PURCH. MODE",
    dataField: "fddOpeningDipBalance",
    dataType: "string",
    width: 100,
  },
  {
    id: 12,
    caption: "SERV.STATION ",
    dataField: "sstShtName",
    dataType: "string",
    width: 90,
  },
  {
    id: 13,
    caption: "PRODUCT",
    dataField: "prdShtName",
    dataType: "string",
    width: 100,
  },
  {
    id: 14,
    caption: "QTY REQ",
    dataField: "fmgQuantityRequested",
    dataType: "string",
    width: 100,
  },
  {
    id: 15,
    caption: "QTY AUTH",
    dataField: "fmgQuantityAuthorised",
    dataType: "string",
    width: 100,
  },
  {
    id: 16,
    caption: "QTY DISP",
    dataField: "fmgQuantityDispensed",
    dataType: "string",
    width: 100,
  },
  {
    id: 17,
    caption: "UNIT PRICE",
    dataField: "sstName",
    dataType: "string",
    width: 100,
  },
  {
    id: 18,
    caption: "AMOUNT .",
    dataField: "fmgAmount",
    dataType: "string",
    width: 100,
  },
  {
    id: 19,
    caption: "CARD No",
    dataField: "cdtCardNo",
    dataType: "string",
    width: 100,
  },
  {
    id: 20,
    caption: "RECEIPT No",
    dataField: "fmgReceiptNo",
    dataType: "string",
    width: 100,
  },
  {
    id: 21,
    caption: "BALANCE",
    dataField: "fmgBalance",
    dataType: "string",
    width: 100,
  },
  {
    id: 22,
    caption: "SERV. PROV.",
    dataField: "spdShtName",
    dataType: "string",
    width: 100,
  },
  {
    id: 23,
    caption: "MONTH",
    dataField: "monthx",
    dataType: "string",
    width: 100,
  },
  {
    id: 24,
    caption: "CURR. ODOM.RDN",
    dataField: "fmgCurrentOdometerReading",
    dataType: "string",
    width: 100,
  },
  {
    id: 25,
    caption: "PREV ODOM.RDN",
    dataField: "fmgPreviousOdometerReading",
    dataType: "string",
    width: 100,
  },
  {
    id: 26,
    caption: "DISTANCE (km)",
    dataField: "distanceTravelled",
    dataType: "string",
    width: 100,
  },
  {
    id: 27,
    caption: "COST CENTER.",
    dataField: "cctName",
    dataType: "string",
    width: 100,
  },
  {
    id: 28,
    caption: "COST CENT.CODE",
    dataField: "cctCode",
    dataType: "string",
    width: 100,
  },
  {
    id: 29,
    caption: "COST CENT.DEPT",
    dataField: "costCenterDeptShtName",
    dataType: "string",
    width: 150,
  },
  {
    id: 30,
    caption: "COST CENT.SECTION",
    dataField: "costCenterSxnShtName",
    dataType: "string",
    width: 150,
  },
  {
    id: 31,
    caption: "DRIVER DEPT",
    dataField: "dptShtName",
    dataType: "string",
    width: 150,
  },
  {
    id: 32,
    caption: "DRIVER SECTION",
    dataField: "sxnShtName",
    dataType: "string",
    width: 150,
  },
  {
    id: 33,
    caption: "AUTHORISED BY",
    dataField: "authoriserEmpName12",
    dataType: "string",
    width: 150,
  },
  {
    id: 34,
    caption: "DATE AUTHORISED BY",
    dataField: "fmgDateAuthorised",
    dataType: "string",
    width: 150,
  },
  {
    id: 35,
    caption: "VEH. CLASS",
    dataField: "vdtChassisNo",
    dataType: "string",
    width: 100,
  },
  {
    id: 36,
    caption: "VEH.MAKE?",
    dataField: "vmkName",
    dataType: "string",
    width: 100,
  },
  {
    id: 37,
    caption: "VEH.MODEL",
    dataField: "mdlName",
    dataType: "string",
    width: 150,
  },
  {
    id: 38,
    caption: "MANUF.",
    dataField: "mftName",
    dataType: "string",
    width: 150,
  },
  {
    id: 39,
    caption: "ASSIGNED.",
    dataField: "vdtAssigned",
    dataType: "string",
    width: 150,
  },
  {
    id: 40,
    caption: "DATE.SAVED",
    dataField: "fmgCreationDate",
    dataType: "datetime",
    width: 150,
  },
  {
    id: 41,
    caption: "COMMIT.DAYS",
    dataField: "fmgCommitDays",
    dataType: "datetime",
    width: 150,
  },
  {
    id: 42,
    caption: "COMMIT.DAYSX",
    dataField: "fmgCommitDaysx",
    dataType: "datetime",
    width: 150,
  },
  {
    id: 43,
    caption: "COMMIT.HOURS",
    dataField: "fmgCommitHours",
    dataType: "datetime",
    width: 150,
  },
  {
    id: 44,
    caption: "REMARKS.",
    dataField: "fmgRmks",
    dataType: "string",
    width: 4000,
  },
];

export const SPTL_comments = [
  {
    id: 1,
    caption: "DATE",
    dataField: "PERIOD.",
    dataType: "string",
    alignment: "left",
    fixed: false,
    width: 60,
  },
  {
    id: 2,
    caption: "NAME",
    dataField: "PERIOD.",
    dataType: "string",
    alignment: "left",
    fixed: false,
    width: 60,
  },
  {
    id: 3,
    caption: "COMMENT",
    dataField: "PERIOD.",
    dataType: "string",
    alignment: "left",
    fixed: false,
    width: 60,
  },
];
