import { useState, useRef, useEffect, useCallback } from "react";
import { Form, Input, Select, Image } from "antd";

import {
  SaveButton,
  NewButton,
  Cancel,
  UpdateButton,
} from "../../../../../../accessories/buttons";
import {
  inputsTypes,
  selectsTypes,
} from "../../../../../../human-resource/setups/data/types/selectsTypes";
import { SelectsTemplate } from "../../../../../../../templates/select";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import { InputsTemplate } from "../../../../../../../templates/input";
import { DateTemplate } from "../../../../../../../templates/date";
import useFetch from "../../../../../../../hooks/useFetch";
import {
  saveModalData,
  updateModalData,
} from "../../../../../../accessories/component_infos";
import dayjs, { Dayjs } from "dayjs";
import { PostDataFunc } from "../../../../../../../functions/post";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { UpdateDataFunc } from "../../../../../../../functions/update";
import { ss_cols, vehicle_cols } from "../data/datagrid_cols";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import { setGeneralValue } from "../../../../../../../features/generalSlice";
import { DropdownGrid_template } from "../../../../../../../templates/DropdownGrid";
import { CheckboxTemlate } from "../../../../../../../templates/checkbox";
import invalidImage from "../../../../../../../assets/none.jpg";
import { useCrudFunc } from "../../../../../../../functions/crud";
import { useBusyLoader } from "../../../../../../../hooks/useBusyLoader";
import {
  fetchProduct,
  fetchSPType,
} from "../../../../../../../features/apiCalls/FuelReturnRequestApi/ReturnReqCard";
import InputSelect_template from "../../../../../../../templates/InputSelect";
import { debounce } from "lodash";
import { Tooltip } from "devextreme-react";

interface stateTypes {
  purMode?: { id: number; name: string };
  reqDate?: string;
  vehNo?: { id: number; name: string };
  purBy?: { id: number; name: string };
  sp?: any;
  ss?: { id: number; name: string };
  prod?: any;
  cardNo?: { id: number; name: string };
  quan1: number;
  unit: number;
  total?: number;
  coupon?: string;
  bal?: number;
  prevOdom: number;
  curOdom: number;
  active?: boolean;
  remarks?: string;
  purchaceMode: boolean;
  vehicleRegNo: boolean;
  purchasedBy: boolean;
  serviceProvider: boolean;
  serviceStation: boolean;
  product: boolean;
  isOpenDropdown: boolean;
  isOpenDropdown1: boolean;
  isLoading?: boolean;
}

interface props {
  statesx?: any;
  setRefreshx?: (e: boolean) => void;
  refereshx?: boolean;
  purchaseModeChange?: (e: string) => void;
  updateStatesx?: (key: string, value: any) => void;
}

export const Fuel_Transaction_Details_Form = ({
  statesx,
  setRefreshx,
  refereshx,
  purchaseModeChange,
  updateStatesx,
}: props) => {
  const { Option } = Select;
  const { TextArea } = Input;

  const dispatch = useDispatch();

  const form_disable = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const update_state_change = useSelector(
    (state: RootState) => state.general.updateMode
  );
  const editedDate = dayjs().format("YYYY-MMM-DD");
  const refs = useRef<any>(null);
  const refs2 = useRef<any>(null);
  const [employeeId, userId, userInfo] = useAccountInfo();
  const [privType, setPrivType] = useState<string>("save");
  const [savePriv, updatePriv] = useFormPriviledge(privType);
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  const [states, setStates] = useState<stateTypes>({
    purMode: {
      id: statesx !== undefined ? statesx.purModeState?.id : 1,
      name: statesx !== undefined ? statesx.purModeState?.name : "Card",
    },
    reqDate: editedDate,
    vehNo: { id: 0, name: "" },
    purBy: { id: userInfo.empIDpk, name: userInfo.empName },
    sp: { id: 0, name: "" },
    ss: { id: 0, name: "" },
    prod: { id: 0, name: "" },
    cardNo: { id: 0, name: "" },
    quan1: 0,
    unit: 0,
    total: 0,
    coupon: "",
    prevOdom: 0,
    curOdom: 0,
    active: false,
    remarks: "",
    purchaceMode: false,
    vehicleRegNo: false,
    purchasedBy: false,
    serviceProvider: false,
    serviceStation: false,
    product: false,
    isOpenDropdown: false,
    isOpenDropdown1: false,
    isLoading: false,
  });

  const setState = (key: string, value: any) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };
  console.log("initial", states);

  const [pur_data] = useFetch(
    "AsmTmsEntFuelManagement/GetEntAsmTmsLuePurchaseModes",
    states.purchaceMode
  );
  const [vehNo_data] = useFetch(
    `FuelManagement/dumpVehiclesAll`,
    states.vehicleRegNo
  );
  const [sp_data] = useFetch(
    "AsmTmsEntFuelManagement/GetEntAsmTmsLueServiceProvides",
    states.serviceProvider
  );
  const [ss_data] = useFetch(
    `FuelManagement/dumpServiceStations?spdIDx=${states?.sp?.id}`,
    states.serviceStation
  );
  const [prod_data] = useFetch(
    `FuelManagement/dumpAllProducts`,
    states.product
  );
  const [purBy_data] = useFetch(
    `FuelManagement/dumpGetPurchasedByAll`,
    states.purchasedBy
  );
  const [cardNo_data] = useFetch(
    `FuelManagement/dumpGetCardNos?assignDate=${editedDate}&endDate=${states.reqDate}&spdIDx=${states.sp?.id}&vdtIDx=${states.vehNo?.id}`
  );
  const [image_data] = useFetch(
    `AssetTamVehicleDetail/GetImagesAssetTamVehicleDetails?vdtIDpk=${statesx.selectedRecord?.vdtIdpk}&vimActive=true`
  );

  const serviceProviderData = useSelector(
    (state: RootState) => state.returnCard.serviceProviderData
  );
  const productData = useSelector(
    (state: RootState) => state.returnCard.productData
  );

  const [lookups] = useFetch(
    `FuelManagement/GetFuelDumpLookup?PageNumber=1&PageSize=20&spdIDx=${states?.sp?.id}&purchaseMode=${states.purMode.name}&station=${states.ss.id}`
  );
  console.log("lookups", lookups);

  const spData = useSelector(
    (state: RootState) => state.returnCard.serviceProviderData
  );

  const prodData = useSelector(
    (state: RootState) => state.returnCard.productData
  );

  const pur: selectsTypes[] = [
    {
      id: 0,
      label: "Purchase Mode",
      optionsData: pur_data,
      idExpr: "pcmIdpk",
      dataExpr: "pcmName",
      stateName: "purMode",
      defaultValue: states.purMode?.name,
      refreshStateName: "purchaceMode",
      refresh: states.purchaceMode,
    },
  ];

  const dates: inputsTypes[] = [
    {
      id: 0,
      label: "Transaction Date",
      stateName: "reqDate",
      defaultValue: states.reqDate,
    },
  ];

  const selects: selectsTypes[] = [
    {
      id: 1,
      label: "Vehicle Reg. No",
      optionsData: vehNo_data,
      idExpr: "vdtIDpk",
      dataExpr: "vdtRegNo",
      stateName: "vehNo",
      defaultValue: states.vehNo?.name,
      style: "80%",
      refreshStateName: "vehicleRegNo",
      refresh: states.vehicleRegNo,
    },
    {
      id: 2,
      label: "Purchased By",
      optionsData: purBy_data,
      idExpr: "empIdpk",
      dataExpr: "empName",
      stateName: "purBy",
      defaultValue: states.purBy?.name,
      style: "80%",
      refreshStateName: "purchasedBy",
      refresh: states.purchasedBy,
    },
    {
      id: 3,
      label: "Service Provider",
      optionsData: states.sp.length > 0 ? spData : lookups.dumpServiceProviders,
      idExpr: "spdIDpk",
      dataExpr: "spdName",
      stateName: "sp",
      defaultValue: states?.sp?.name,
      style: "30%",
      refreshStateName: "serviceProvider",
      refresh: states.serviceProvider,
      isOpenDropdown: states.isOpenDropdown,
      isOpenDropdownKey: "isOpenDropdown",
      singleEndpoint: fetchSPType,
    },
    {
      id: 4,
      label: "Service Station",
      optionsData: ss_data,
      idExpr: "sstIDpk",
      dataExpr: "sstName",
      stateName: "ss",
      defaultValue: states.ss?.name,
      style: "80%",
      refreshStateName: "serviceStation",
      refresh: states.serviceStation,
    },
    {
      id: 5,
      label: "Product",
      optionsData: states.prod.length > 0 ? prodData : lookups.dumpAllProducts,
      idExpr: "prdIdpk",
      dataExpr: "prdName",
      stateName: "prod",
      defaultValue: states.prod?.name,
      style: "80%",
      refreshStateName: "product",
      refresh: states.product,
      isOpenDropdown: states.isOpenDropdown1,
      isOpenDropdownKey: "isOpenDropdown1",
      singleEndpoint: fetchProduct,
    },
  ];

  const Rightinputs: inputsTypes[] = [
    {
      id: 0,
      label: "Total Amount",
      stateName: "total",
      defaultValue: states.total ?? 0,
      style: "80%",
    },
    {
      id: 1,
      label: "Balance After Purchase",
      stateName: "bal",
      defaultValue: states.bal ?? 0,
      style: "80%",
    },
    {
      id: 2,
      label: "Prev. Odom Reading",
      stateName: "prevOdom",
      defaultValue: states.prevOdom ?? 0,
      style: "80%",
    },
    {
      id: 3,
      label: "Current Odom Reading",
      stateName: "curOdom",
      defaultValue: states.curOdom ?? 0,
      style: "80%",
    },
  ];

  var payload_total = Number(states.quan1 * states.unit)?.toFixed(2);

  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [showImages, setShowImages] = useState(false);
  const [busyLoader, setBusyLoader] = useBusyLoader();

  const populateFields = (clear?: boolean) => {
    if (clear === true) {
    }
    const gridState = [
      {
        key: "purMode",
        value: clear
          ? {
              id: statesx !== undefined ? statesx.purModeState?.id : 1,
              name: statesx !== undefined ? statesx.purModeState?.name : "Card",
            }
          : {
              id: statesx.selectedRecord?.pcmIDpk,
              name: statesx.selectedRecord?.pcmName,
            },
      },
      {
        key: "reqDate",
        value: clear ? editedDate : statesx.selectedRecord?.fmgDate,
      },
      {
        key: "vehNo",
        value: clear
          ? { id: 0, name: "" }
          : {
              id: statesx.selectedRecord?.vdtIDpk,
              name: statesx.selectedRecord?.vdtRegNo,
            },
      },
      {
        key: "purBy",
        value: clear
          ? { id: userInfo.empIDpk, name: userInfo.empName }
          : {
              id: statesx.selectedRecord?.driverEmpID,
              name: statesx.selectedRecord?.driverName,
            },
      },
      {
        key: "sp",
        value: clear
          ? { id: 0, name: "" }
          : {
              id: statesx.selectedRecord?.spdIDpk,
              name: statesx.selectedRecord?.spdName,
            },
      },
      {
        key: "ss",
        value: clear
          ? { id: 0, name: "" }
          : {
              id: statesx.selectedRecord?.sstIDpk,
              name: statesx.selectedRecord?.sstName,
            },
      },
      {
        key: "prod",
        value: clear
          ? { id: 0, name: "" }
          : {
              id: statesx.selectedRecord?.prdIDpk,
              name: statesx.selectedRecord?.prdName,
            },
      },
      {
        key: "cardNo",
        value: clear
          ? { id: 0, name: "" }
          : {
              id: statesx.selectedRecord?.cdtIDpk,
              name: statesx.selectedRecord?.cdtCardNo,
            },
      },
      {
        key: "coupon",
        value: clear ? "" : statesx.selectedRecord?.fmgReceiptNo,
      },
      {
        key: "quan1",
        value: clear ? 0 : statesx.selectedRecord?.fmgQuantityRequested,
      },
      {
        key: "unit",
        value: clear
          ? 0
          : statesx.selectedRecord?.fmgAmount /
            statesx.selectedRecord?.fmgQuantityRequested,
      },
      { key: "total", value: clear ? 0 : statesx.selectedRecord?.fmgAmount },
      { key: "bal", value: clear ? 0 : statesx.selectedRecord?.fmgBalance },
      {
        key: "prevOdom",
        value: clear ? 0 : statesx.selectedRecord?.fmgPreviousOdometerReading,
      },
      {
        key: "curOdom",
        value: clear ? 0 : statesx.selectedRecord?.fmgCurrentOdometerReading,
      },
      { key: "remarks", value: clear ? "" : statesx.selectedRecord?.fmgRmks },
    ];

    for (let instance of gridState) setState(instance.key, instance.value);
  };
  const validationForms = async () => {
    setIcon("warning");
    if (
      statesx.purModeState?.id === 0 ||
      statesx.purModeState?.id === undefined ||
      statesx.purModeState?.id === null
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select purchase mode field.",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.vehNo?.id === 0 || states.vehNo?.id === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select vehicle reg.no field.",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.purBy?.id === 0 || states.purBy?.id === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select purchased by field.",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.sp?.id === 0 || states.sp?.id === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select service provider field.",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.ss?.id === 0 || states.ss?.id === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select service station field.",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.prod?.id === 0 || states.prod?.id === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select Product Field",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      states.reqDate === "" ||
      dayjs(states.reqDate).isAfter(dayjs())
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message:
          "Please select transaction field, date cannot be greater than today.",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.coupon === "" || states.coupon === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please input receipt/coupon field.",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      !states.quan1 ||
      states.quan1 === undefined ||
      states.quan1 < 0 ||
      isNaN(Number(states.quan1))
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message:
          states.quan1 < 0
            ? "Please, quantity cannot be negative."
            : isNaN(Number(states.quan1))
            ? "Please, enter a valid number for quantity."
            : "Please, input quantity field.",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      !states.unit ||
      states.unit === undefined ||
      states.unit < 0 ||
      isNaN(Number(states.unit))
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message:
          states.unit < 0
            ? "Please, Unit Price cannot be negative."
            : isNaN(Number(states.unit))
            ? "Please, enter a valid number for unit price."
            : "Please, input Unit Price Field",
        title: "Required Field!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      !states.total ||
      states.total === undefined ||
      isNaN(Number(states.total))
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message: isNaN(Number(states.total))
          ? "Please, enter a valid number for total amount."
          : "Please, input total amount field.",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      !states.bal ||
      states.bal === undefined ||
      isNaN(Number(states.bal))
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message: isNaN(Number(states.bal))
          ? "Please, enter a valid number for balance after purchase."
          : "Please, input balance after purchase field.",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      !states.prevOdom ||
      states.prevOdom === undefined ||
      isNaN(Number(states.prevOdom))
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message: isNaN(Number(states.prevOdom))
          ? "Please, enter a valid number for previous odometer reading."
          : "Please, input Previous odometer reading field.",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      !states.curOdom ||
      states.curOdom === undefined ||
      isNaN(Number(states.curOdom))
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message: isNaN(Number(states.curOdom))
          ? "Please, enter a valid number for current odometer reading."
          : "Please, input current odometer reading field.",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.coupon === "" || states.coupon === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please input receipt/coupon field.",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      !states.quan1 ||
      states.quan1 === undefined ||
      states.quan1 < 0 ||
      isNaN(Number(states.quan1))
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message:
          states.quan1 < 0
            ? "Please, quantity cannot be negative."
            : isNaN(Number(states.quan1))
            ? "Please, enter a valid number for quantity."
            : "Please, input quantity field.",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      !states.unit ||
      states.unit === undefined ||
      states.unit < 0 ||
      isNaN(Number(states.unit))
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message:
          states.unit < 0
            ? "Please, unit price cannot be negative."
            : isNaN(Number(states.unit))
            ? "Please, enter a valid number for unit price."
            : "Please, input unit price field.",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      !states.total ||
      states.total === undefined ||
      isNaN(Number(states.total))
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message: isNaN(Number(states.total))
          ? "Please, enter a valid number for total amount."
          : "Please, input total amount field.",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      !states.bal ||
      states.bal === undefined ||
      isNaN(Number(states.bal))
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message: isNaN(Number(states.bal))
          ? "Please, enter a valid number for balance after purchase."
          : "Please, input balance after purchase field.",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      !states.prevOdom ||
      states.prevOdom === undefined ||
      isNaN(Number(states.prevOdom))
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message: isNaN(Number(states.prevOdom))
          ? "Please, enter a valid number for previous odometer reading."
          : "Please, input Previous odometer reading field.",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      !states.curOdom ||
      states.curOdom === undefined ||
      isNaN(Number(states.curOdom))
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message: isNaN(Number(states.curOdom))
          ? "Please, enter a valid number for current odometer reading."
          : "Please, input current odometer reading field.",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
    } else {
      setshowModalDisableButton(false);
      setIcon("question");
      setOpenModal(true);
    }
  };

  const newAccesspriv = () => {
    setPrivType("save");
    if (savePriv === true) {
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      updateStatesx!("selectedRecord", {});
    } else {
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      setIcon("warning");
      setOpenModal(true);
      setModalData({ title: "Access Denied!", message: "No Access Privilege" });
    }
  };

  const saveAccesspriv = () => {
    setPrivType("save");
    if (savePriv === true) {
      dispatch(setGeneralValue({ expr: "updateMode", value: false }));
      setIcon("question");
      setModalData({
        message: "Are you sure you want to save this request?",
        title: "Save Request",
        okText: "Ok",
      });
      setOpenModal(true);

      validationForms();
    } else {
      setIcon("warning");
      setOpenModal(true);
      setModalData({ title: "Access Denied!", message: "No Access Privilege" });
    }
  };

  const updateAccesspriv = () => {
    setPrivType("update");
    if (updatePriv === true) {
      dispatch(setGeneralValue({ expr: "updateMode", value: true }));
      setIcon("question");
      setModalData({
        message: "Are you sure you want to update this request?",
        title: "Update Request",
        okText: "Ok",
      });
      setOpenModal(true);
      validationForms();
    } else {
      setIcon("warning");
      setOpenModal(true);
      setModalData({ title: "Access Denied!", message: "No Access Privilege" });
    }
  };

  const [Posting, Updating] = useCrudFunc();

  const postData = async () => {
    setBusyLoader("Saving Request...");
    try {
      const res = await Posting(
        "FuelManagement/CreateFuelManagement",
        {
          fmgDate: dayjs(states.reqDate),
          fmgVehicleDetailIDfk: states.vehNo?.id,
          fmgDriverIDfk: states.purBy?.id,
          fmgDispenserEmpIDfk: employeeId,
          fmgServiceProviderIDfk: states.sp?.id,
          fmgServiceStationIDfk: states.ss?.id,
          fmgProductIDfk: states.prod?.id,
          fmgAuthoriserEmpIDfk: 0,
          fmgPurchaseModeIDfk: states.purMode?.id,
          fmgFuelUnitIDfk: 0,
          fmgCardIDfk: states.cardNo?.id,
          fmgAmount: states.total,
          fmgBalance: Number(states.bal)?.toFixed(2),
          fmgQuantityRequested: Number(states.quan1).toFixed(2),
          fmgQuantityAuthorised: states.quan1,
          fmgQuantityDispensed: states.quan1,
          fmgCurrentOdometerReading: Number(states.curOdom).toFixed(2),
          fmgPreviousOdometerReading: Number(states.prevOdom).toFixed(2),
          fmgReceiptNo: states.coupon,
          fmgVehicleAssignmentIDfk: 1,
          fmgAuthorised: true,
          fmgRmks: states.remarks,
          fmgCreatedBy: employeeId,
        },
        `Created the Asset Mgr-Trans-Fuel Dump Fuel Transaction Log with Purchase Mode Card, Transaction Date ${dayjs(
          states.reqDate
        )}, Vehicle Reg No ${states.vehNo?.name}, Purchased By ${
          states.purBy?.name
        }, Service Provider ${states.sp?.name}, Service Station ${
          states.ss?.name
        }, Product ${states.prod?.name}, Receipt/Coupon ${
          states.coupon
        }, Quantity ${Number(states.quan1).toFixed(2)}, Unit Price ${
          states.total / states.quan1
        }, Total Amount ${states.total}, Prev. Odom Reading ${Number(
          states.prevOdom
        ).toFixed(2)}, Current Odom Reading ${Number(states.curOdom).toFixed(
          2
        )} successfully`
      );
      setRefresh(!refresh);
      setRefreshx && setRefreshx(!refereshx);
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: "Request saved successfully",
        title: "Request Saved",
        okText: "Ok",
      });
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      dispatch(setGeneralValue({ expr: "updateMode", value: false }));
    } catch (err: any) {
      // console.log(err, "ssssss")
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: err.response
          ? err.response.data.errors.InsertFMDto
          : "Server Error",
        title: err.response ? err.response.data.title : "Unexpected Error!",
        okText: "Ok",
      });
    } finally {
      setBusyLoader("");
    }
  };

  const updateData = async () => {
    setBusyLoader("Updating Request...");
    try {
      await Updating(
        `FuelManagement/UpdateFuelManagement?Id=${statesx.selectedRecord?.fmgIDpk}`, //
        {
          fmgDate: dayjs(states.reqDate),
          fmgVehicleDetailIDfk: states.vehNo?.id,
          fmgDriverIDfk: states.purBy?.id,
          fmgDispenserEmpIDfk: employeeId,
          fmgServiceProviderIDfk: states.sp?.id,
          fmgServiceStationIDfk: states.ss?.id,
          fmgProductIDfk: states.prod?.id,
          fmgPurchaseModeIDfk: states.purMode?.id,
          fmgCardIDfk: states.cardNo?.id,
          fmgFuelUnitIDfk: 0,
          fmgAmount: payload_total,
          fmgBalance: Number(states.bal)?.toFixed(2),
          fmgQuantityRequested: Number(states.quan1).toFixed(2),
          fmgQuantityAuthorised: states.quan1,
          fmgQuantityDispensed: states.quan1,
          fmgCurrentOdometerReading: Number(states.curOdom).toFixed(2),
          fmgPreviousOdometerReading: Number(states.prevOdom).toFixed(2),
          fmgReceiptNo: states.coupon,
          fmgVehicleAssignmentIDfk: 0,
          fmgAuthorised: true,
          fmgRmks: states.remarks,
          fmgEditedBy: employeeId,
        },
        `Edited the Asset Mgr-Trans-Manual Fuel Transaction Log with ID- ${
          statesx.selectedRecord?.fmgIDpk
        },Transaction Date ${dayjs(states.reqDate)}, Driver ID- ${
          statesx.selectedRecord?.driverName12
        }, Vehicle Reg No ID ${states.vehNo?.id}, Purchased By ID ${
          states.purBy?.id
        }, Service Provider ${states.sp?.id}, Service Station ID ${
          states.ss?.id
        }, Product ID ${states.prod?.id}, Receipt/Coupon ${
          states.coupon
        }, Quantity ${Number(states.quan1).toFixed(2)}, Unit Price ${
          states.total / states.quan1
        }, Total Amount ${states.total}, Balance After Purchase ${Number(
          states.bal
        )?.toFixed(2)}, Prev. Odom Reading ${Number(states.prevOdom).toFixed(
          2
        )}, Current Odom Reading ${Number(states.curOdom).toFixed(
          2
        )} successfully`
      );
      // setRefresh(!refresh);
      setRefreshx && setRefreshx(!refereshx);
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: "Request updated successfully",
        title: "Request Updated",
        okText: "Ok",
      });
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      dispatch(setGeneralValue({ expr: "updateMode", value: false }));
    } catch (err: any) {
      // console.log(err, "ssssss")
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: err.response
          ? err.response.data.errors.InsertFMDto
          : "Server Error",
        title: err.response ? err.response.data.title : "Unexpected Error!",
        okText: "Ok",
      });
    } finally {
      setBusyLoader("");
    }
  };

  const byServiceP = (
    <div className="w-1/2 flex ml-1 ">
      <SelectsTemplate
        placeHolder={states?.cardNo?.name}
        style={{ width: "100%" }}
        selectStyle={{ width: "68%" }}
        options={cardNo_data}
        idexpr={"cdtIDpk"}
        dataexp={"cctShtName"}
        handleClear={() => {
          setState("cardNo", { id: 0, name: "" });
          setState("sp", { id: 0, name: "" });
        }}
        useCallFunc
        disabled={true}
      />
    </div>
  );

  const close = async () => {
    refs.current!.instance?.close();
    refs2.current!.instance?.close();
  };

  useEffect(() => {
    if (
      statesx.selectedRecord !== undefined &&
      Object.keys(statesx.selectedRecord).length === 0
    ) {
      populateFields(true);
    } else {
      populateFields(false);
    }
  }, [statesx.selectedRecord]);

  return (
    <div
      style={{ width: "100%", height: "100%", borderColor: borderTheme }}
      className="  border-[1px] dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base"
    >
      <>
        <ModalTemplate
          icon={icon}
          cancelText={modalData.cancelText}
          disableCancel={
            icon === "warning" || icon === "success" ? true : false
          }
          cancelHandler={() => {
            setOpenModal(false);
          }}
          open={openModal}
          okHandler={() => {
            modalData.title === "Save Request"
              ? postData()
              : modalData.title === "Update Request"
              ? updateData()
              : setOpenModal(false);
          }}
          message={modalData.message}
          okText={modalData.okText}
          title={modalData.title}
        />
        <div
          style={{ maxHeight: "280px", minHeight: "280px", height: "280px" }}
          className=" items-start justify-start"
        >
          <Form
            className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base ml-1 rounded py-2 px-4 "
            name="basic"
            labelCol={{ span: 7 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            size="small"
            layout="horizontal"
          >
            <div style={{ width: "100%" }} className=" flex ">
              <div style={{ width: "37%" }} className="">
                {pur.map(
                  ({
                    id,
                    label,
                    idExpr,
                    dataExpr,
                    optionsData,
                    stateName,
                    defaultValue,
                    refresh,
                    refreshStateName,
                  }) => {
                    return (
                      <>
                        <SelectsTemplate
                          cusWidth={"80%"}
                          disabledCustomWidth={true}
                          label={label}
                          options={optionsData}
                          idexpr={idExpr}
                          dataexp={dataExpr}
                          placeHolder={defaultValue}
                          useCallFunc
                          disabled={form_disable}
                          selectedValue={(e) => {
                            purchaseModeChange(e);
                          }}
                          handleClear={() => {
                            setState(stateName!, { id: 0, name: "" });
                            updateStatesx &&
                              updateStatesx("purModeState", {
                                id: 0,
                                name: "",
                              });
                          }}
                          handleRefresh={() => {
                            setState(refreshStateName!, !refresh);
                            updateStatesx &&
                              updateStatesx("purModeState", {
                                id: 0,
                                name: "",
                              });
                          }}
                        />
                      </>
                    );
                  }
                )}

                {dates.map(({ id, label, stateName, defaultValue }) => {
                  return (
                    <>
                      <DateTemplate
                        label={label}
                        width={"80%"}
                        changeDate={(e: Dayjs) => {
                          setState(stateName!, dayjs(e).format());
                        }}
                        selectedDate={dayjs(defaultValue)}
                        disabledDate={(current: Dayjs) => {
                          return current && current > dayjs().endOf("day");
                        }}
                      />
                    </>
                  );
                })}
                <div className="w-full">
                  {selects.map(
                    ({
                      id,
                      label,
                      idExpr,
                      dataExpr,
                      optionsData,
                      stateName,
                      defaultValue,
                      style,
                      refresh,
                      refreshStateName,
                      isOpenDropdown,
                      isOpenDropdownKey,
                      singleEndpoint,
                    }) => {
                      const total_selects = selects?.length;
                      return (
                        <div
                          className={`w-full ${
                            id === 1 || id === 4 ? `mb-0.5` : ``
                          }`}
                        >
                          {label === "Service Station" ||
                          label === "Vehicle Reg. No" ? (
                            <div className="w-full flex">
                              <DropdownGrid_template
                                label={label}
                                KeyExpr={idExpr}
                                cusBtnStyle={true}
                                dropdownBtnStyles={{ width: "100% " }}
                                className="w-[400%]"
                                labelCol={7}
                                columns={
                                  label === "Vehicle Reg. No"
                                    ? vehicle_cols
                                    : ss_cols
                                }
                                disabled={form_disable}
                                gridData={optionsData}
                                defaultValue={defaultValue}
                                handleRefresh={() => {
                                  setState(stateName!, { id: 0, name: "" });
                                  setState(refreshStateName!, !refresh);
                                }}
                                onRowClick={(e) => {
                                  if (
                                    e &&
                                    idExpr &&
                                    dataExpr &&
                                    e.hasOwnProperty(idExpr) &&
                                    e.hasOwnProperty(dataExpr)
                                  ) {
                                    setState(stateName!, {
                                      id: e[idExpr],
                                      name: e[dataExpr],
                                    });
                                    close();
                                  } else {
                                  }
                                }}
                              />
                            </div>
                          ) : (
                            <InputSelect_template
                              useCusWidth={true}
                              cusWidth={
                                label === "Service Provider" ? "45%" : "80%"
                              }
                              selectStyle={
                                label == "Service Provider" && {
                                  width: "300px",
                                }
                              }
                              span={label === "Service Provider" ? true : false}
                              disabledCustomWidth={true}
                              label={label}
                              options={optionsData}
                              idexpr={idExpr}
                              dataexp={dataExpr}
                              placeHolder={defaultValue}
                              useCallFunc
                              disabled={
                                label === "Purchased By" ? true : form_disable
                              }
                              selectedValue={(e) => {
                                setState(isOpenDropdownKey, false);
                                setState(stateName!, {
                                  id: e[idExpr!],
                                  name: e[dataExpr!],
                                });
                                setState("cardNo", {
                                  id: cardNo_data[0]?.cdtIdpk,
                                  name: cardNo_data[0]?.cdtCardNo,
                                });
                              }}
                              extraWidget={
                                label === "Service Provider" && byServiceP
                              }
                              handleClear={() => {
                                id !== 3 &&
                                  setState(stateName!, { id: 0, name: "" });
                              }}
                              handleRefresh={() => {
                                setState(refreshStateName!, !refresh);
                                setState(
                                  "serviceProvider",
                                  !states.serviceProvider
                                );
                              }}
                              isOpenDropdown={isOpenDropdown}
                              isLoading={states.isLoading}
                              handleFocus={(e: any) => {
                                setState(isOpenDropdownKey, !isOpenDropdown);
                              }}
                              handleSearch={
                                debounce((e) => {
                                  setState(stateName!, e);
                                  setState("isLoading", true);
                                  dispatch(singleEndpoint(e));

                                  setState(isOpenDropdownKey, true);
                                }, 500) // Adjust the debounce delay as needed
                              }
                              fullWidth={true}
                            />
                          )}
                        </div>
                      );
                    }
                  )}
                </div>

                <Form.Item
                  labelCol={{ span: 2 }}
                  style={{
                    width: `calc(265% - 0.88%)`,
                    marginLeft: "7%",
                    marginTop: 2,
                  }}
                  label={
                    <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs ">
                      {"Remarks"}
                    </p>
                  }
                  className="mb-0.5"
                >
                  <div className="flex flex-row  w-full ">
                    <TextArea
                      style={{
                        resize: "none",
                        height: 75,
                        width: `calc(100%)`,
                      }}
                      className=""
                      readOnly={form_disable}
                      value={states.remarks}
                      onChange={(e) => {
                        setState("remarks", e.target.value);
                      }}
                    />
                  </div>
                </Form.Item>
              </div>

              <div style={{ width: "39%" }} className="mt-7">
                <Form.Item
                  label={
                    <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                      {"Receipt/Coupon No"}
                    </p>
                  }
                  className="mb-0.5"
                >
                  <div className="flex w-[80%]">
                    <InputsTemplate
                      inputStyle={{ width: "100%" }}
                      parentClassName="w-full"
                      span
                      defaultValue={states.coupon}
                      disabledStatus={false}
                      useCallbackFunc
                      readOnly={false}
                      orderOnchange={(e: any) => {
                        setState("coupon", e);
                      }}
                    />
                  </div>
                </Form.Item>

                <>
                  <Form.Item
                    label={
                      <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                        {"Quantity & Unit Price"}
                      </p>
                    }
                    className="mb-0.5"
                  >
                    <li className=" flex items-center w-[80%] justify-between gap-1">
                      {/* <InputsTemplate
                        inputStyle={{ width: "100%" }}
                        parentClassName="w-full"
                        widthChange={true}
                        numberOnly={true}
                        span
                        placeholder={"Quantity"}
                        defaultValue={states.quan1}
                        useCallbackFunc
                        readOnly={form_disable}
                        disabledStatus={false}
                        orderOnchange={(e: any) => {
                          const val = e ?? 0.0;
                          setState("quan1", e?.toFixed(2));
                          setState(
                            "total",
                            Number(states.unit * val)?.toFixed(2)
                          );
                        }}
                      /> */}

                      <Input
                        disabled={form_disable ? true : false}
                        value={states.quan1}
                        onChange={(e) => {
                          const val: any = e.target.value ?? 0.0;
                          setState("quan1", val);
                          setState("total", states.unit * val);
                        }}
                      />

                      {/* <InputsTemplate
                        inputStyle={{ width: "100%" }}
                        parentClassName="w-full"
                        widthChange={true}
                        numberOnly={true}
                        placeholder={"Unit Price"}
                        span
                        defaultValue={states.unit}
                        useCallbackFunc
                        readOnly={form_disable}
                        disabledStatus={false}
                        orderOnchange={(e: any) => {
                          setState("unit", e?.toFixed(2));
                          const val = e ?? 0.0;
                          setState(
                            "total",
                            Number(states.quan1 * val)?.toFixed(2)
                          );
                        }}
                      /> */}

                      <Input
                        disabled={form_disable ? true : false}
                        value={states.unit}
                        onChange={(e) => {
                          const val: any = e.target.value ?? 0.0;
                          setState("unit", val);
                          setState("total", states.quan1 * val);
                        }}
                      />
                    </li>
                  </Form.Item>

                  {Rightinputs.map(
                    ({ id, label, stateName, defaultValue, style }) => {
                      return (
                        <>
                          <InputsTemplate
                            id={label}
                            span
                            inputStyle={{
                              width: style,
                              backgroundColor:
                                label === "Total Amount" && "yellow",
                            }}
                            label={label}
                            defaultValue={defaultValue}
                            useCallbackFunc
                            disabledStatus={
                              label == "Total Amount" ? true : form_disable
                            }
                            orderOnchange={(e: number | string) => {
                              setState(stateName!, e);
                            }}
                            additionalWidget={
                              label === "Current Odom Reading" && (
                                <div className="flex w-full px-1 ">
                                  <p className="text-blue-500 text-xs flex">
                                    {`${states?.curOdom - states?.prevOdom} km`}
                                  </p>
                                </div>
                              )
                            }
                          />
                        </>
                      );
                    }
                  )}
                </>
              </div>

              <div style={{ width: "22%" }} className="mt-7 mx-4 ">
                <div style={{ width: "60%" }} className="p-1">
                  <Image
                    width={150}
                    height={100}
                    src={
                      !showImages
                        ? invalidImage
                        : `data:image/png;base64, ${image_data[0]?.vimImage}`
                    }
                    alt="Vehicle Image"
                    onError={(e) => {
                      e.currentTarget.src = invalidImage;
                    }}
                    className=" text-xs border-[1px] "
                  />
                  <li className=" flex flex-row mt-1 w-full ">
                    <CheckboxTemlate
                      defaultValue={showImages}
                      setCheckboxOnchange={(val) => setShowImages(val)}
                      customDisabled={false}
                      useCallbackFunc={true}
                      withBorders={true}
                    />
                    <p className="text-xs ml-1 mt-0.5 justify-center text-center items-center">
                      Load Vehicle Image
                    </p>
                  </li>
                </div>
              </div>
            </div>

            <div className="w-full  flex flex-col ml-1">
              <div
                style={{ width: "calc(100%)" }}
                className=" flex justify-end  "
              >
                <ul
                  style={{ width: "calc(50% )" }}
                  className="flex justify-end "
                >
                  {form_disable === false && update_state_change === false ? (
                    <div id='save'>
                    <SaveButton
                      handleSave={() => {
                        saveAccesspriv();
                      }}
                    />
                    <Tooltip
                      target="#save"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                      hideOnOutsideClick={true}
                    >
                      <p>Save Request</p>
                    </Tooltip>
                  </div>
                  ) : update_state_change === true ? (
                  <div id='update'>
                    <UpdateButton
                      handleUpdate={() => {
                        updateAccesspriv();
                      }}
                    />
                    <Tooltip
                      target="#update"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                      hideOnOutsideClick={true}
                    >
                      <p>Update Request</p>
                    </Tooltip>
                  </div>
                  ) : (
                    <SaveButton />
                  )}
                  {form_disable === true ? (
                    <div id='new'>
                      <NewButton
                        useCallbackFunc={true}
                        new_button_toggler={() => {
                          newAccesspriv();
                        }}
                      />
                      <Tooltip
                        target="#new"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        hideOnOutsideClick={true}
                      >
                        <p>New Request</p>
                      </Tooltip>
                    </div>
                  ) : update_state_change === true ? (
                    <div id='cancel'>
                      <Cancel cancel_button_toggler={() => {}} />
                      <Tooltip
                        target="#cancel"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        hideOnOutsideClick={true}
                      >
                        <p>Cancel Update</p>
                      </Tooltip>
                    </div>
                  ) : !(update_state_change && form_disable) ? (
                    <div id='cancel'>
                      <Cancel cancel_button_toggler={() => {}} />
                      <Tooltip
                        target="#cancel"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        hideOnOutsideClick={true}
                      >
                        <p>Cancel</p>
                      </Tooltip>
                    </div>
                  ) : (
                    <div id='newx'>
                      <NewButton
                        useCallbackFunc={true}
                        new_button_toggler={() => {
                          newAccesspriv();
                        }}
                      />
                      <Tooltip
                        target="#newx"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        hideOnOutsideClick={true}
                      >
                        <p>New Request</p>
                      </Tooltip>
                    </div>
                  )}
                </ul>
              </div>
            </div>
          </Form>
        </div>
      </>
    </div>
  );
};
