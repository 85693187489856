let gridData = [
  {
    vcrInside: 3,
    vcrOutside: 3,
    vcrTyre: 3,
    vcrSuspension: 3,
    vcrEngine: 3,
    vcrAirConditioner: 3,
  },
];

gridData = gridData.map((item) => ({
  ...item,
  vcrInside: item.vcrInside ?? 3,
  vcrOutside: item.vcrOutside ?? 3,
}));

export const vehicle_condition_TH = [
  {
    id: 0,
    caption: "vcrID",
    dataField: "vcrIdpk",
    alignment: "left",
    fixed: true,
    allowEditing: false,
    visible: false,
    width: 80,
  },

  {
    id: 1,
    caption: "vdtID",
    dataField: "vdtIdpk",
    alignment: "left",
    fixed: true,
    visible: false,
    allowEditing: false,
    width: 80,
  },

  {
    id: 2,
    caption: "vasID",
    dataField: "vasIdpk",
    alignment: "left",
    visible: false,
    allowEditing: false,
    fixed: true,
    width: 80,
  },
  {
    id: 3,
    caption: "No",
    dataField: "rowId",
    alignment: "left",
    allowEditing: false,
    fixed: true,
    width: 50,
  },
  {
    id: 4,
    caption: "Vehicle Class",
    dataField: "vhcName",
    alignment: "left",
    fixed: true,
    allowEditing: false,
    width: 120,
  },
  {
    id: 5,
    caption: "Vehicle Make",
    dataField: "vmkName",
    alignment: "left",
    allowEditing: false,

    fixed: true,
    width: 120,
  },

  {
    id: 6,
    caption: "Model",
    dataField: "mdlName",
    alignment: "left",
    fixed: true,
    allowEditing: false,

    width: 120,
  },

  {
    id: 7,
    caption: "Reg No",
    dataField: "vdtRegNo",
    allowEditing: false,

    alignment: "left",
    fixed: true,
    width: 80,
  },

  {
    fixed: true,
    id: 8,
    caption: "Age (Yrs)",
    dataField: "manufactureAge",
    allowEditing: false,

    alignment: "left",
    width: 90,
  },

  {
    fixed: true,
    id: 9,
    caption: "Dept",
    dataField: "dptShtName",
    alignment: "left",
    allowEditing: false,

    width: 120,
  },
  {
    fixed: true,
    id: 10,
    caption: "Section",
    dataField: "sxnShtName",
    alignment: "left",
    allowEditing: false,

    width: 120,
  },
  {
    fixed: true,
    id: 11,
    caption: "Location",
    dataField: "ownerLocShtName",
    allowEditing: false,

    alignment: "left",
    width: 120,
  },
];

export const vehicle_condition_data = [
  {
    id: 0,
    caption: "Date",
    dataField: "vcrDate",
    alignment: "left",
    fixed: true,
    width: 60,
  },
  {
    id: 1,
    caption: "VEH. REG No",
    dataField: "vdtRegNo",
    alignment: "left",
    fixed: true,
  },

  {
    id: 2,
    caption: "DEPT",
    dataField: "dptShtName",
    alignment: "left",
    fixed: true,
  },
  {
    id: 3,
    caption: "SECTION",
    dataField: "sxnShtName",
    alignment: "left",
    fixed: true,
  },
  {
    fixed: true,
    id: 35,
    caption: "VEH. CLASS",
    dataField: "vhcName",
    alignment: "left",
  },

  {
    fixed: true,
    id: 5,
    caption: "VEH. MAKE",
    dataField: "mftName",
    alignment: "left",
    width: 80,
  },

  {
    fixed: true,
    id: 6,
    caption: "VEH. MODEL",
    dataField: "mdlName",
    alignment: "left",
    width: 80,
  },

  {
    fixed: true,
    id: 7,
    caption: "SUBM?",
    dataType: "boolean",
    dataField: "vcrSubmitted",
    alignment: "left",
  },
  {
    fixed: true,
    id: 8,
    caption: "ACK.?",
    dataType: "boolean",
    dataField: "vcrAcknowledged",
    alignment: "left",
  },
  {
    fixed: false,
    id: 9,
    caption: "START ODOM.",
    dataType: "",
    dataField: "vcrOdometerStart",
    alignment: "left",
    width: 80,
  },
  {
    dataType: "",
    fixed: false,
    id: 10,
    caption: "FINAL ODOM.",
    dataField: "vcrOdometerEnd",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 11,
    caption: "DISTANCE",
    dataField: "vcrDistance",
    alignment: "left",
    width: 80,
    dataType: "",
  },
  {
    fixed: false,
    id: 12,
    caption: "INSIDE",
    dataField: "vcrInside",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 13,
    caption: "OUTSIDE",
    dataField: "vcrOutside",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 14,
    caption: "TYRE",
    dataField: "vcrTyre",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 15,
    caption: "SUSPENSION",
    dataField: "vcrSuspension",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 16,
    caption: "ENGINE",
    dataField: "vcrEngine",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 17,
    caption: "AC (AIR CON)",
    dataField: "vcrAirConditioner",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 18,
    caption: "CONDITION",
    dataField: "insulatorType",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 19,
    caption: "BOARD?",
    dataField: "vcrBoard",
    alignment: "left",
  },
  {
    fixed: false,
    id: 20,
    caption: "CARD?",
    dataField: "vcrCard",
    alignment: "left",
  },
  {
    fixed: false,
    id: 21,
    caption: "NON CARD?",
    dataField: "vcrNonCard",
    alignment: "left",
  },
  {
    fixed: false,
    id: 22,
    caption: "STATUS",
    dataField: "vcrActive",
    alignment: "left",
  },
  {
    fixed: false,
    id: 23,
    caption: "SUBMITTED BY",
    dataField: "submitterEmpName",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 24,
    caption: "SUB. INTERCOM",
    dataField: "driverPlc",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 25,
    caption: "APPROV. OFFICER",
    dataField: "ownerEmpName",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 26,
    caption: "AGE (YRS)",
    dataField: "manufactureAge",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 27,
    caption: "OWNER INTERCOM",
    dataField: "ackPlc",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 28,
    caption: "DRIVER",
    dataField: "ownerEmpName",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 29,
    caption: "DRIV. INTERCOM",
    dataField: "submitterPlc",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 30,
    caption: "ACKNOWLEDGED BY",
    dataField: "ackEmpName1",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 31,
    caption: "ACK. INTERCOM",
    dataField: "ackPlc",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 32,
    caption: "MANUF.",
    dataField: "mftName",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 33,
    caption: "MANUF. DATE",
    dataField: "vdtManufactureDate",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 34,
    caption: "REMARKS",
    dataField: "vcrRmks",
    alignment: "left",

    width: 4000,
  },
];

export const down_data_table = [
  {
    id: 0,
    caption: "Date",
    dataField: "vcrDate",
    alignment: "left",
    fixed: true,
    width: 60,
  },
  {
    id: 1,
    caption: "VEH. REG No",
    dataField: "vdtRegNo",
    alignment: "left",
    fixed: true,
  },

  {
    id: 2,
    caption: "DEPT",
    dataField: "dptShtName",
    alignment: "left",
    fixed: true,
  },
  {
    id: 3,
    caption: "SECTION",
    dataField: "sxnShtName",
    alignment: "left",
    fixed: true,
  },
  {
    fixed: true,
    id: 35,
    caption: "LOCATION",
    dataField: "ownerLocShtName",
    alignment: "left",
  },

  {
    id: 4,
    caption: "VEH. CLASS",
    dataField: "vhcName",
    alignment: "left",
    fixed: true,
    width: 60,
  },

  {
    fixed: true,
    id: 5,
    caption: "VEH. MAKE",
    dataField: "vmkName",
    alignment: "left",
    width: 80,
  },

  {
    fixed: true,
    id: 6,
    caption: "VEH. MODEL",
    dataField: "mdlName",
    alignment: "left",
    width: 80,
  },

  {
    fixed: true,
    id: 7,
    caption: "SUBM?",
    dataField: "vcrSubmitted",
    alignment: "left",
  },
  {
    fixed: true,
    id: 8,
    caption: "ACK.?",
    dataField: "vcrAcknowledged",
    alignment: "left",
  },
  {
    fixed: false,
    id: 9,
    caption: "START ODOM.",
    dataField: "vcrOdometerStart",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 10,
    caption: "FINAL ODOM.",
    dataField: "vcrOdometerEnd",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 11,
    caption: "DISTANCE",
    dataField: "vcrDistance",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 12,
    caption: "INSIDE",
    dataField: "vcrInside",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 13,
    caption: "OUTSIDE",
    dataField: "vcrOutside",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 14,
    caption: "TYRE",
    dataField: "vcrTyre",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 15,
    caption: "SUSPENSION",
    dataField: "vcrSuspension",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 16,
    caption: "ENGINE",
    dataField: "vcrEngine",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 17,
    caption: "AC (AIR CON)",
    dataField: "vcrAirConditioner",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 18,
    caption: "CONDITION",
    dataField: "insulatorType",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 19,
    caption: "BOARD?",
    dataField: "vcrBoard",
    alignment: "left",
  },
  {
    fixed: false,
    id: 20,
    caption: "CARD?",
    dataField: "vcrCard",
    alignment: "left",
  },
  {
    fixed: false,
    id: 21,
    caption: "NON CARD?",
    dataField: "vcrNonCard",
    alignment: "left",
  },
  {
    fixed: false,
    id: 22,
    caption: "STATUS",
    dataField: "vcrActive",
    alignment: "left",
  },
  {
    fixed: false,
    id: 23,
    caption: "SUBMITTED BY",
    dataField: "submitterEmpName",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 24,
    caption: "SUB. INTERCOM",
    dataField: "1",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 25,
    caption: "APPROV. OFFICER",
    dataField: "2",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 26,
    caption: "AGE (YRS)",
    dataField: "manufactureAge",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 27,
    caption: "OWNER INTERCOM",
    dataField: "3",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 28,
    caption: "DRIVER",
    dataField: "ownerEmpName",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 29,
    caption: "DRIV. INTERCOM",
    dataField: "4",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 30,
    caption: "ACKNOWLEDGED BY",
    dataField: " ackEmpName",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 31,
    caption: "ACK. INTERCOM",
    dataField: "5",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 32,
    caption: "MANUF.",
    dataField: "6",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 33,
    caption: "MANUF. DATE",
    dataField: "vdtManufactureDate",
    alignment: "left",
    width: 80,
  },
  {
    fixed: false,
    id: 34,
    caption: "REMARKS",
    dataField: "vcrRmks",
    alignment: "left",

    width: 4000,
  },
];

export const returnReport_table = [
  {
    id: 0,
    width: 10,
    fixed: true,
  },
  {
    id: 1,
    dataField: "dptIDpk",
    fixed: true,
    width: 70,
    caption: "Dept",
    alignment: "left",
  },
  {
    id: 2,
    dataField: "dptShtName",
    fixed: true,
    width: 70,
    caption: "Section",
    alignment: "left",
    visible:false,
  },
  {
    id: 3,
    dataField: "sxnIDpk",
    fixed: true,
    width: 100,
    caption: "StaffNo",
    alignment: "left",
    visible:false,
  },
  {
    id: 4,
    dataField: "sxnShtName",
    fixed: true,
    width: 100,
    caption: "StaffName",
    alignment: "left",
    visible:false,
  },
];

export const reportSubmitted_table = [
  {
    id: 0,
    dataField: "vdtRegNo",
    fixed: false,
    width: 90,
    caption: "REG. No",
    alignment: "left",
  },
  {
    id: 1,
    dataField: "dptShtName",
    fixed: false,
    width: 90,
    caption: "DEPT",
    alignment: "left",
  },
  {
    id: 2,
    dataField: "sxnShtName",
    fixed: false,
    width: 90,
    caption: "Section",
    alignment: "left",
  },
  {
    id: 3,
    dataField: "vhcName",
    fixed: false,
    width: 90,
    caption: "VEH. CLASS",
    alignment: "left",
  },
  {
    id: 4,
    dataField: "vmkName",
    fixed: false,
    width: 90,
    caption: "VEH. MAKE",
    alignment: "left",
  },
  {
    id: 5,
    dataField: "mdlName",
    fixed: false,
    width: 90,
    caption: "VEH. MODEL",
    alignment: "left",
  },
  {
    id: 6,
    dataField: "manufactureAge",
    fixed: false,
    width: 90,
    caption: "AGE (YRS)",
    alignment: "left",
  },
  {
    id: 7,
    dataField: "vcrOdometerStart",
    datatType: "Number",
    fixed: false,
    width: 90,
    caption: "START ODOM",
    alignment: "left",
  },
  {
    id: 8,
    dataField: "vcrOdometerEnd",
    fixed: false,
    width: 90,
    caption: "FINAL ODOM.",
    alignment: "left",
  },
  {
    id: 9,
    dataField: "vcrNonCard",
    fixed: false,
    width: 90,
    caption: "NON CARD",
    alignment: "left",
  },
  {
    id: 10,
    dataField: "vcrCondition",
    fixed: false,
    width: 90,
    caption: "CONDITION",
    alignment: "left",
  },
  {
    id: 11,
    dataField: "vcrDistance",
    fixed: false,
    width: 90,
    caption: "DISTANCE",
    alignment: "left",
  },
  {
    id: 12,
    dataField: "vcrCondition",
    fixed: false,
    width: 90,
    caption: "STATUS",
    alignment: "left",
  },
  {
    id: 13,
    dataField: "vcrBoard",
    fixed: false,
    width: 90,
    caption: "BOARD?",
    alignment: "left",
  },
  {
    id: 14,
    dataField: "vcrCard",
    fixed: false,
    width: 90,
    caption: "CARD?",
    alignment: "left",
  },
  {
    id: 15,
    dataField: "vcrSubmitted",
    fixed: false,
    width: 90,
    caption: "SUBM?",
    alignment: "left",
  },
  {
    id: 16,
    dataField: "vcrAcknowledged",
    fixed: false,
    width: 90,
    caption: "ACK.?",
    alignment: "left",
  },
  {
    id: 17,
    dataField: "submitterPlc",
    fixed: false,
    width: 90,
    caption: "SUB. INTERCOM",
    alignment: "left",
  },
  {
    id: 18,
    dataField: "submitterEmpName",
    fixed: false,
    width: 90,
    caption: "SUBMITTED BY",
    alignment: "left",
  },
  {
    id: 19,
    dataField: "ownerEmpName",
    fixed: false,
    width: 90,
    caption: "APPROV. OFFICER",
    alignment: "left",
  },
  {
    id: 20,
    dataField: "ownerPlc",
    fixed: false,
    width: 90,
    caption: "OWNER INTERCOM",
    alignment: "left",
  },
  {
    id: 21,
    dataField: "driverEmpName",
    fixed: false,
    width: 90,
    caption: "DRIVER",
    alignment: "left",
  },
  {
    id: 22,
    dataField: "driverPlc",
    fixed: false,
    width: 90,
    caption: "DRIV. INTERCOM",
    alignment: "left",
  },
  {
    id: 23,
    dataField: "ackEmpName1",
    fixed: false,
    width: 90,
    caption: "ACKNOWLEDGED BY",
    alignment: "left",
  },
  {
    id: 24,
    dataField: "mftName",
    fixed: false,
    width: 90,
    caption: "MANUF.",
    alignment: "left",
  },
  {
    id: 24,
    dataField: "vdtManufactureDate",
    dataType: "datetime",
    fixed: false,
    width: 90,
    caption: "MANUF. DATE",
    alignment: "left",
  },
  {
    id: 24,
    dataField: "vcrRmks",
    fixed: false,
    width: 390,
    caption: "REMARKS",
    alignment: "left",
  },
];
export const reportUnSubmitted_table = [
  {
    id: 0,
    dataField: "vdtRegNo",
    fixed: false,
    width: 90,
    caption: "REG. No",
    alignment: "left",
  },
  {
    id: 1,
    dataField: "dptShtName",
    fixed: false,
    width: 90,
    caption: "DEPT",
    alignment: "left",
  },
  {
    id: 2,
    dataField: "sxnShtName",
    fixed: false,
    width: 90,
    caption: "Section",
    alignment: "left",
  },
  {
    id: 3,
    dataField: "vhcName",
    fixed: false,
    width: 90,
    caption: "VEH. CLASS",
    alignment: "left",
  },
  {
    id: 4,
    dataField: "vmkName",
    fixed: false,
    width: 90,
    caption: "VEH. MAKE",
    alignment: "left",
  },
  {
    id: 5,
    dataField: "mdlName",
    fixed: false,
    width: 90,
    caption: "VEH. MODEL",
    alignment: "left",
  },
  {
    id: 7,
    dataField: "vcrOdometerStart",
    datatType: "Number",
    fixed: false,
    width: 90,
    caption: "START ODOM",
    alignment: "left",
  },
  {
    id: 8,
    dataField: "vcrOdometerEnd",
    fixed: false,
    width: 90,
    caption: "FINAL ODOM.",
    alignment: "left",
  },
  {
    id: 9,
    dataField: "vcrNonCard",
    fixed: false,
    width: 90,
    caption: "NON CARD",
    alignment: "left",
  },
  {
    id: 10,
    dataField: "vcrCondition",
    fixed: false,
    width: 90,
    caption: "CONDITION",
    alignment: "left",
  },
  {
    id: 11,
    dataField: "vcrDistance",
    fixed: false,
    width: 90,
    caption: "DISTANCE",
    alignment: "left",
  },
  {
    id: 12,
    dataField: "vcrCondition",
    fixed: false,
    width: 90,
    caption: "STATUS",
    alignment: "left",
  },
  {
    id: 13,
    dataField: "vcrBoard",
    fixed: false,
    width: 90,
    caption: "BOARD?",
    alignment: "left",
  },
  {
    id: 14,
    dataField: "vcrCard",
    fixed: false,
    width: 90,
    caption: "CARD?",
    alignment: "left",
  },
  {
    id: 15,
    dataField: "vcrSubmitted",
    fixed: false,
    width: 90,
    caption: "SUBM?",
    alignment: "left",
  },
  {
    id: 16,
    dataField: "vcrAcknowledged",
    fixed: false,
    width: 90,
    caption: "ACK.?",
    alignment: "left",
  },
  {
    id: 17,
    dataField: "submitterPlc",
    fixed: false,
    width: 90,
    caption: "SUB. INTERCOM",
    alignment: "left",
  },
  {
    id: 18,
    dataField: "submitterEmpName",
    fixed: false,
    width: 90,
    caption: "SUBMITTED BY",
    alignment: "left",
  },
  {
    id: 19,
    dataField: "ownerEmpName",
    fixed: false,
    width: 90,
    caption: "APPROV. OFFICER",
    alignment: "left",
  },
  {
    id: 20,
    dataField: "ownerPlc",
    fixed: false,
    width: 90,
    caption: "OWNER INTERCOM",
    alignment: "left",
  },
  {
    id: 21,
    dataField: "driverEmpName",
    fixed: false,
    width: 90,
    caption: "DRIVER",
    alignment: "left",
  },
  {
    id: 22,
    dataField: "driverPlc",
    fixed: false,
    width: 90,
    caption: "DRIV. INTERCOM",
    alignment: "left",
  },
  {
    id: 23,
    dataField: "ackEmpName1",
    fixed: false,
    width: 90,
    caption: "ACKNOWLEDGED BY",
    alignment: "left",
  },
  {
    id: 23,
    dataField: "ackPlc",
    fixed: false,
    width: 90,
    caption: "ACK. INTERCOM",
    alignment: "left",
  },
  {
    id: 24,
    dataField: "mftName",
    fixed: false,
    width: 90,
    caption: "MANUF.",
    alignment: "left",
  },
  {
    id: 24,
    dataField: "vdtManufactureDate",
    dataType: "datetime",
    fixed: false,
    width: 90,
    caption: "MANUF. DATE",
    alignment: "left",
  },
  {
    id: 24,
    dataField: "vcrRmks",
    fixed: false,
    width: 390,
    caption: "REMARKS",
    alignment: "left",
  },
];

export const firstDropdown = [
  {
    id: 0,
    dataField: "empStaffNo",
    fixed: true,
    width: 40,
    caption: "Staff No",
    alignment: "left",
  },
  {
    id: 1,
    dataField: "empName",
    fixed: true,
    width: 40,
    caption: "NAME",
    alignment: "left",
  },
  {
    id: 2,
    dataField: "dptShtName",
    fixed: true,
    width: 40,
    caption: "Dept.",
    alignment: "left",
  },
  {
    id: 3,
    dataField: "sxnShtName",
    fixed: true,
    width: 40,
    caption: "SECT.",
    alignment: "left",
  },
  {
    id: 4,
    dataField: "locShtName",
    fixed: true,
    width: 40,
    caption: "LOC .",
    alignment: "left",
  },
];
