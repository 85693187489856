export const assets_vehiclesmakesColumns = [
  {
    id: 0,
    caption: "ID",
    dataField: "vmkIdpk",
    dataType: "number",
    width: 80,
    fixed: true,
    visible: false,
  },
  {
    id: 1,
    caption: "VEHICLE MAKE",
    dataField: "vmkName",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "SHORT NAME",
    dataField: "vmkShtName",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 3,
    caption: "VEHICLE CLASS NAME",
    dataField: "vhcName",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 4,
    caption: "ORDER",
    dataField: "vmkOrder",
    dataType: "string",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 5,
    caption: "ACTIVE",
    dataField: "vmkActive",
    dataType: "boolean",
    width: 90,
    fixed: true,
    visible: true,
  },
  {
    id: 6,
    caption: "REMARKS",
    dataField: "vmkRmks",
    dataType: "string",
    width: 5000,
    fixed: false,
    visible: true,
  },
];
