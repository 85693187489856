import { useEffect, useImperativeHandle, useState} from 'react';
import Setups_Template from '../.setups_templates/Setups_Template';
import { exprsTypes, modalTypes, searchTextTypes, selectExprsTypes, selectsTypes } from '../data/types/selectsTypes';
import useFetch from '../../../../hooks/useFetch';
import { hr_setups_department_columns } from './data/HrSetupsDepartmentData';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { validationsData, saveModalData, updateModalData, api_url, postError, putError } from '../../../accessories/component_infos';
import { setTier2Value, tier2SaveOnclick, tier2UpdateOnclick } from '../../../../features/forms/tier2/tier2Slice';
import { setGeneralValue } from '../../../../features/generalSlice';
import { ModalTemplate } from '../../../../templates/modal';
import { useBusyLoader } from '../../../../hooks/useBusyLoader';
import { WarningAlert } from '../../../accessories/warningAlert';
import { useAccountInfo } from '../../../../hooks/useAccountInfo';
import dayjs from 'dayjs';
import React from 'react';
import { UpdateDataFunc } from '../../../../functions/update';
import { PostDataFunc } from '../../../../functions/post';
import { useCrudFunc } from '../../../../functions/crud';


export const Hr_Setups_Departments = React.forwardRef(({a}:any, ref)=>{
    const activeStatus = useSelector((state:RootState)=>state.general.activeStatus);
    const [searchText,setSearchText] =useState<searchTextTypes>({temp:'',text:''});
     const [status,setstatus]= useState<boolean|string>('');

    const fetchUrl:string = `HrDepartment/Departments?searchText=${searchText.text}&active=${status}`;
    const postUrl:string = 'HrDepartment/create';
    const updateUrl:string = 'HrDepartment/update';

    const saveSuccessText = {message : 'Department added Successfully',ok : 'Ok' , title : 'Save Succesful'} // successful save modal message
    const updateSuccessText = {message : 'Department updated Successfully',ok : 'Ok' , title : 'Update Successful'} // successful update modal message

    // store data expressions[unique]
    const idExpr:String = 'dptIdpk';
    const nameExpr:string = 'dptName'
    const shortnameExpr:string = 'dptShtName'
    const orderExpr: string = 'dptOrder';
    const activeExpr: string = 'dptActive';
    const remarksExpr: string = 'dptRmks';
    const inputsExprsData: any = {idExpr : idExpr,nameExpr : nameExpr,shortnameExpr : shortnameExpr,orderExpr : orderExpr,activeExpr,remarksExpr : remarksExpr }
    const selectsExpr:selectExprsTypes[] = [{idExpr : 'obrIdpk', nameExpr : 'obrName',label :'branch'},{idExpr : 'areIdpk', nameExpr : 'areName',label :'area'},{idExpr : 'areIdpk', nameExpr : 'areName',label :'area'},{idExpr : 'areIdpk', nameExpr : 'areName',label :'area'},{idExpr : 'areIdpk', nameExpr : 'areName',label :'area'}]

    const storeExprs = () =>{
        const exprs:exprsTypes[] = [{expr:'existsUrl',value:`HrDepartment`},{expr: 'idExpr' ,value : idExpr},{expr: 'nameExpr' ,value : nameExpr},{expr: 'shortnameExpr' ,value : shortnameExpr},{expr: 'selectExprs' ,value : selectsExpr[0]}]
        for (let i = 0; i < exprs.length; i++) {            
            dispatch(setTier2Value({expr: exprs[i].expr ,value : exprs[i].value}));
        }

        // to general slice
        const generalExprs:exprsTypes[] = [{expr: 'selectExprs' ,value : selectsExpr},{expr: 'inputExprs' ,value : inputsExprsData}]
        for (let i = 0; i < generalExprs.length; i++) {            
            dispatch(setGeneralValue({expr: generalExprs[i].expr ,value : generalExprs[i].value}));
        }
    }

    const[busyLoader,setBusyLoader] = useBusyLoader();
    const dispatch = useDispatch();
    const [employeeId,userId] = useAccountInfo();
    const [refresh, setRefresh] = useState<boolean>(false); //refresh data state for data creation or update

    //modal states
    //--general--
    const triggerSuccessModal = useSelector((state:RootState)=>state.tier2forms.successModalState); //trigger success modal
    const SuccessModalText = useSelector((state:RootState)=>state.tier2forms.successText); //success modal text

    //--for backend errors--
    const[errState,setErrState] = useState<boolean>(false);
    const[errTitle,seterrTitle] = useState<string>('');
    const[errMessage,seterrMessage] = useState<string>('');

    //--warning modal--
    const triggerwarningModal = useSelector((state:RootState)=>state.tier2forms.warningState); //trigger warning
    const warningInfos:any = validationsData; //warning type details
    const warningDataExpr = useSelector((state:RootState)=>state.tier2forms.warningDataExpr); //warning type selector

    //--save data modal--
    const saveData = saveModalData; //save modal details[data]
    const triggersaveModal = useSelector((state:RootState)=>state.tier2forms.saveDataOnclick); //trigger save modal
   
    //--Update data modal--
    const updateIndInfos = updateModalData; //update modal details[data]
    const triggerupdateModal:boolean = useSelector((state:RootState)=>state.tier2forms.updateDataOnclick); //trigger update question

    // on page resize height responsiveness
    const [trigger,setTrigger] = useState<boolean>(false);
    useImperativeHandle(ref, () => ({
        test: () => {
            setTrigger(!trigger);
        }
    }));

    // get current values from  [slice dependent on form type]
    const branch = useSelector((state:RootState)=>state.tier2forms.selectValue);
    const name = useSelector((state:RootState)=>state.tier2forms.name);
    const shortname = useSelector((state:RootState)=>state.tier2forms.shortname);
    const order = useSelector((state:RootState)=>state.tier2forms.order);
    const active = useSelector((state:RootState)=>state.tier2forms.active);
    const remarks = useSelector((state:RootState)=>state.tier2forms.remarks);
    const selectedID = useSelector((state:RootState)=>state.general.SelectedFieldID);


    console.log("BRANCHCCC", branch)

    //post form Data
    //validate data
    const validateDataSave = () =>{
        dispatch(tier2SaveOnclick());
    }

    const [Posting, Updating, Patching] = useCrudFunc()
    // post
    const postData = async() =>{
        dispatch(setTier2Value({expr: 'saveDataOnclick' ,value : false}))

        console.log(
            {
                "dptName": name,
                "dptShtName": shortname,
              
                "dptBranchIdfk": branch,
               
               
                "dptActive": active,
                "dptRmks": remarks,
                "dptCreatedBy": userId,
                "dptOrder": order,
                "dptCreationDate": dayjs(new Date()).format(),
               
            }
        )
        
        try {
            setBusyLoader('Saving record...')
            const res = await Posting(`${postUrl}`,{
                "dptName": name,
                "dptShtName": shortname,
              
                "dptBranchIdfk": branch,
               
               
                "dptActive": active,
                "dptRmks": remarks,
                "dptCreatedBy": userId,
                "dptOrder": order,
                "dptCreationDate": dayjs(new Date()).format(),
               
            },`Created the HR-Department ${name}, Department Short Name: ${shortname}, Department Branch ID : ${branch}, Department Active : ${active}, Department Remarks : ${remarks}, Department Order : ${order}, Department Creation Date : ${dayjs(new Date()).format()}`)
            setRefresh(!refresh);
            setBusyLoader('') //disable busy loader

            const exprs:exprsTypes[] = [{expr: 'saveDataOnclick' ,value : false},{expr: 'successModalState' ,value : true},{expr: 'successText' ,value : saveSuccessText}];
            for (let i = 0; i < exprs.length; i++) {            
                dispatch(setTier2Value({expr: exprs[i].expr ,value : exprs[i].value}));
            }
        } catch (error:any) {
            setBusyLoader('');
            console.log(error);

            //set states for db errors modal
            setErrState(true);
            seterrTitle(postError.title);
            seterrMessage(postError.message);
        }
    }

    //Update Form data
    //validate data
    const validateDataUpdate = () =>{
        dispatch(tier2UpdateOnclick());
    }
    // update
    const UpdateData = async()=>{
        dispatch(setTier2Value({expr: 'updateDataOnclick' ,value : false}));
        
        try {
            console.log({
                "dptIdpk": selectedID,
                "dptName": name,
                "dptShtName": shortname,
                
                "dptBranchIdfk": branch,
                
                "dptActive": active,
                "dptRmks": remarks,
                 "dptEditedBy": userId,
               "dptOrder": order,
                "dptEditedDate": dayjs(new Date()).format(),
            })
            setBusyLoader('Updating record...')
            const res = await Updating(`${updateUrl}/${selectedID}`,{
                "dptIdpk": selectedID,
                "dptName": name,
                "dptShtName": shortname,
                
                "dptBranchIdfk": branch,
                
                "dptActive": active,
                "dptRmks": remarks,
                 "dptEditedBy": userId,
               "dptOrder": order,
                "dptEditedDate": dayjs(new Date()).format(),
            },`Edited the HR-Department from ID ${selectedID} to ${name}, Department Short Name: ${shortname}, Department Branch ID : ${branch}, Department Active : ${active}, Department Remarks : ${remarks}, Department Order : ${order}, Department Edited Date : ${dayjs(new Date()).format()}`)
            setRefresh(!refresh);
            setBusyLoader('') //disable busy loader

            const exprs:exprsTypes[] = [{expr: 'updateDataOnclick' ,value : false},{expr: 'successModalState' ,value : true},{expr: 'successText' ,value : updateSuccessText}];
            for (let i = 0; i < exprs.length; i++) {           
                dispatch(setTier2Value({expr: exprs[i].expr ,value : exprs[i].value}));
            }
        } catch (error:any) {
            console.log(error);
            setBusyLoader('') //disable busy loader

            //set states for db errors modal
            setErrState(true);
            seterrTitle(putError.title);
            seterrMessage(putError.message);
        }
    }

    

    //modals data
    const modals:modalTypes[] = [
        //warning
        {disableCancel : true , icon : 'warning' ,open : triggerwarningModal, okHandler : ()=> dispatch(setTier2Value({expr: 'warningState' ,value : false})), cancelHandler : ()=> dispatch(setTier2Value({expr: 'warningState' ,value : false})), message : warningInfos[warningDataExpr!].message, cancelText : warningInfos[warningDataExpr!].cancelText, okText : warningInfos[warningDataExpr!].okText, title : warningInfos[warningDataExpr!].title},
        //backend error
        {disableCancel : true , icon : 'warning' ,open : errState, okHandler : ()=> setErrState(false), cancelHandler : ()=> setErrState(false), message : errMessage, cancelText : '', okText : 'Ok', title : errTitle},
        //success
        {disableCancel : true , icon : 'success' ,open : triggerSuccessModal, okHandler : ()=> dispatch(setTier2Value({expr: 'successModalState' ,value : false})), cancelHandler : ()=> dispatch(setTier2Value({expr: 'successModalState' ,value : false})), message :SuccessModalText.message, okText : SuccessModalText.okText, title : SuccessModalText.title},
        //save
        {disableCancel : false , icon : 'question' ,open : triggersaveModal, okHandler : ()=> postData(), cancelHandler : ()=> dispatch(setTier2Value({expr: 'saveDataOnclick' ,value : false})), message : saveData.message, cancelText : saveData.cancelText, okText : saveData.okText, title : saveData.title},
        //Update
        {disableCancel : false , icon : 'question' ,open : triggerupdateModal, okHandler : ()=> UpdateData(), cancelHandler : ()=> dispatch(setTier2Value({expr: 'updateDataOnclick' ,value : false})), message : updateIndInfos.message, cancelText : updateIndInfos.cancelText, okText : updateIndInfos.okText, title : updateIndInfos.title},
    ]

    // data for datagrid
    const [departments,departmentsError,departmentsLoading] = useFetch(fetchUrl,refresh,'dptIdpk');

    // get branches
    const[branchData,branchError,branchLoading] = useFetch('HrDepartment/getBranchLkup');

    useEffect(()=>{
        if(departments){
            storeExprs(); // run func to store expressions on store
            dispatch(setGeneralValue({expr : 'idExpr', value : idExpr})); // dispatch fetched data to reduxstore
            dispatch(setGeneralValue({expr: 'formData' ,value : departments})); // dispatch fetched data to reduxstore
        }

        departmentsLoading?setBusyLoader('.'):setBusyLoader('');
    },[departments,departmentsLoading])
    
    // form parameters
    const form_header = 'Department Details';
    const selects:selectsTypes[] = [
        {id: 0, label : 'Branch', optionsData : branchData,idExpr:'obrIdpk',dataExpr:'obrName', diffExprs:{idKey: 'obrIDpk', valueKey: 'obrName'}},
    ]
    const optionalnamelabel = 'Department'

    return (
        <>
            {modals.map(({disableCancel,icon,okHandler,cancelHandler,open,cancelText,okText,message,title}:modalTypes,index)=>{
                return(
                    <div key = {index}>
                        <ModalTemplate  icon= {icon && icon} disableCancel = {disableCancel} cancelText = {cancelText && cancelText} open = {open} okHandler={okHandler} cancelHandler={cancelHandler} message= {message} okText= {okText} title={title}/>
                    </div>
                )
            })}

            {departmentsError&&(
                <WarningAlert/>
            )}

            <Setups_Template
             searchTextChange={(newText) =>{
                setSearchText({temp:newText, text:searchText.text});
            }}
            handleSearch={() =>{
                setSearchText({temp:searchText.temp, text:searchText.temp});
                setstatus(activeStatus);
            }}
            createData={validateDataSave}
            updateData = {validateDataUpdate}
            formTier="2"
            handleRefresh={()=>{
                // setstatus('');
                // sets({temp:'', text:''})
                setRefresh(current=>!current)
            }}
            trigger = {trigger}
            datagridColumns={hr_setups_department_columns}
            datagridData = {departments}
            optionalnamelabel= { optionalnamelabel}
            selects={selects}
            formHeader={ form_header}
            />
        </>
    );
})