export const authorise_DetailsColumns = [
    {id : 0, caption : 'ID',dataField : 'fmgIDpk',datatype : 'number', width : 80, fixed : true, visible : false},
    {id : 1, caption : 'VFRD?',dataField : 'fmgVerified',datatype : 'boolean', width : 80, fixed : true , visible : true},
    {id : 2, caption : 'LCKD?',dataField : 'fmgLocked',datatype : 'boolean', width : 80, fixed : true , visible : true},
    {id : 3, caption : 'ACK?',dataField : 'fmgAcknowledged',datatype : 'boolean', width : 80, fixed : true , visible : true},
    {id : 4, caption : 'AUTH?',dataField : 'fmgAuthorised',datatype : 'boolean', width : 80, fixed : true , visible : true},
    {id : 5, caption : 'ACTIVE?',dataField : 'fmgActive',datatype : 'boolean', width : 80, fixed : true , visible : true},
    {id : 6, caption : 'REG. No',dataField : 'vdtRegNo',datatype : 'string', width : 130, fixed : true , visible : true},
    {id : 7, caption : 'REQUEST DATE',dataField : 'fmgDate',dataType : 'datetime', width : 130, fixed : false , visible : true},
    {id : 8, caption : 'DATE ISSUED',dataField : 'fmgDateDispensed',dataType : 'datetime', width : 130, fixed : false , visible : true},
    {id : 9, caption : 'RECEIVED BY',dataField : 'driverName',datatype : 'string', width : 200, fixed : false , visible : true},
    {id : 10, caption : 'TIME',dataField : 'fmgTime',dataType : 'datetime', width : 130, fixed : false , visible : true},
    {id : 11, caption : 'PURCHASE MODE',dataField : 'pcmName',datatype : 'string', width : 130, fixed : false , visible : true},
    {id : 12, caption : 'SERV. STATION',dataField : 'sstName',datatype : 'string', width : 200, fixed : false , visible : true},
    {id : 13, caption : 'QTY DISP.',dataField : 'fmgQuantityDispensed',datatype : 'string', width : 130, fixed : false , visible : true},
    {id : 14, caption : 'QTY AUTH.',dataField : 'fmgQuantityAuthorised',datatype : 'string', width : 130, fixed : false , visible : true},
    {id : 15, caption : 'PRODUCT',dataField : 'prdName',datatype : 'string', width : 200, fixed : false , visible : true},
    {id : 16, caption : 'QTY REQ.',dataField : 'fmgQuantityRequested',datatype : 'string', width : 100, fixed : false , visible : true},
    {id : 17, caption : 'UNIT PRICE',dataField : 'mtdWhy',datatype : 'string', width : 100, fixed : false , visible : true},
    {id : 18, caption : 'AMOUNT',dataField : 'fmgAmount',datatype : 'string', width : 100, fixed : false , visible : true},
    {id : 19, caption : 'CARD No',dataField : 'cdtCardNo',datatype : 'string', width : 100, fixed : false , visible : true},
    {id : 20, caption : 'RECEIPT No',dataField : 'fmgReceiptNo',datatype : 'string', width : 100, fixed : false , visible : true},
    {id : 21, caption : 'BALANCE',dataField : 'fmgBalance',datatype : 'string', width : 100, fixed : false , visible : true},
    {id : 22, caption : 'SERV. PROVIDER',dataField : 'spdName',datatype : 'string', width : 100, fixed : false , visible : true},
    {id : 23, caption : 'MONTH',dataField : 'monthx',datatype : 'string', width : 100, fixed : false , visible : true},
    {id : 24, caption : 'CURR. ODOM. RDN',dataField : 'fmgCurrentOdometerReading',datatype : 'string', width : 100, fixed : false , visible : true},
    {id : 25, caption : 'PREV. ODOM. RDN',dataField : 'fmgPreviousOdometerReading',datatype : 'string', width : 100, fixed : false , visible : true},
    {id : 26, caption : 'DISTANCE(km)',dataField : 'distanceTravelled',datatype : 'string', width : 100, fixed : false , visible : true},
    {id : 27, caption : 'VEH. DEPT',dataField : 'vehAssignDeptName',datatype : 'string', width : 100, fixed : false , visible : true},
    {id : 28, caption : 'VEH. SECTION',dataField : 'vehAssignSxnName',datatype : 'string', width : 100, fixed : false , visible : true},
    {id : 29, caption : 'DRIVER DEPT',dataField : 'dptShtName',datatype : 'string', width : 130, fixed : false , visible : true},
    {id : 30, caption : 'DRIVER SECTION',dataField : 'sxnShtName',datatype : 'string', width : 100, fixed : false , visible : true},
    {id : 31, caption : 'DISPENSED BY',dataField : 'dispenserEmpName',datatype : 'string', width : 100, fixed : false , visible : true},
    {id : 32, caption : 'DATE DISPENSED',dataField : 'fmgDateDispensed',dataType : 'datetime', width : 130, fixed : false , visible : true},
    {id : 33, caption : 'DATE AUTHORIZED',dataField : 'fmgDateAuthorised',dataType : 'datetime', width : 130, fixed : false , visible : true},
    {id : 34, caption : 'DISPENSER SECTION',dataField : 'dispenserSxnShtName',datatype : 'string', width : 100, fixed : false , visible : true},
    {id : 35, caption : 'VEHICLE CLASS',dataField : 'vhcName',datatype : 'string', width : 100, fixed : false , visible : true},
    {id : 36, caption : 'VEH. MAKE',dataField : '"vmkName": ',datatype : 'string', width : 100, fixed : false , visible : true},
    {id : 37, caption : 'VEH. MODEL',dataField : 'mdlName',datatype : 'string', width : 100, fixed : false , visible : true},
    {id : 38, caption : 'MANUFACTURER',dataField : 'mftName',datatype : 'string', width : 100, fixed : false , visible : true},
    {id : 39, caption : 'ASSIGNED',dataField : 'vdtAssigned',datatype : 'string', width : 100, fixed : false , visible : true},
    
    
    {id : 41, caption : 'REMARKS',dataField : 'fmgRmks',datatype : 'string', width : 4000, fixed : false , visible : true}
]