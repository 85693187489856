export const hr_setups_units_columns = [
    {id : 0,dataField:'untIdpk' ,caption : 'ID',datatype : 'number', width : 80, fixed : true, visible : false},
    {id : 1,dataField:'untName' ,caption : 'UNIT',datatype : 'string', width : 160, fixed : true , visible : true},
    {id : 2,dataField:'untShtName' ,caption : 'SHORT NAME',datatype : 'string', width : 160, fixed : true , visible : true},
    {id : 3,dataField:'sxnName' ,caption : 'SECTION',datatype : 'string', width : 130, fixed : true , visible : true},
    {id : 4,dataField:'dptName' ,caption : 'DEPARTMENT',datatype : 'string', width : 130, fixed : true , visible : true},
    {id : 5,dataField:'untOrder' ,caption : 'ORDER',datatype : 'string', width : 130, fixed : true , visible : true},
    {id : 6,dataField:'untActive' ,caption : 'ACTIVE',datatype : 'boolean', width : 90, fixed : true , visible : true},
    // {id : 7,dataField:'' ,caption : 'HEAD STAFF No',datatype : 'string', width : 130, fixed : true , visible : true},
    // {id : 8,dataField:'' ,caption : 'UNIT HEAD',datatype : 'string', width : 130, fixed : true , visible : true},
    {id : 7,dataField:'untRmks' ,caption : 'REMARKS',datatype : 'string', width : 5000, fixed : false , visible : true}

]

export const units_data = [
    {
        'ID' : 1,
        'DEPARTMENT' : 'Accra Central',
        'SECTION' : 'Accra Central',
        'UNIT' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'UNIT HEAD' : 'Accra',
        'ORDER' : '4',
        'HEAD STAFF No' : 'Accra Central',
        'ACTIVE' : true,
        'REMARKS' : 'SFF'
    },
    {
        'ID' : 2,
        'DEPARTMENT' : 'Accra Central',
        'SECTION' : 'Accra Central',
        'UNIT' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'UNIT HEAD' : 'Accra',
        'ORDER' : '4',
        'HEAD STAFF No' : 'Accra Central',
        'ACTIVE' : false,
        'REMARKS' : ''
    },
    {
        'ID' : 3,
        'DEPARTMENT' : 'Accra Central',
        'SECTION' : 'Accra Central',
        'UNIT' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'UNIT HEAD' : 'Accra',
        'ORDER' : '4',
        'HEAD STAFF No' : 'Accra Central',
        'ACTIVE' : true,
        'REMARKS' : ''
    },
    {
        'ID' : 4,
        'DEPARTMENT' : 'Accra Central',
        'SECTION' : 'Accra Central',
        'UNIT' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'UNIT HEAD' : 'Accra',
        'ORDER' : '4',
        'HEAD STAFF No' : 'Accra Central',
        'ACTIVE' : true,
        'REMARKS' : ''
    },
    {
        'ID' : 5,
        'DEPARTMENT' : 'Accra Central',
        'SECTION' : 'Accra Central',
        'UNIT' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'UNIT HEAD' : 'Accra',
        'ORDER' : '4',
        'HEAD STAFF No' : 'Accra Central',
        'ACTIVE' : true,
        'REMARKS' : ''
    },
    {
        'ID' : 6,
        'DEPARTMENT' : 'Accra Central',
        'SECTION' : 'Accra Central',
        'UNIT' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'UNIT HEAD' : 'Accra',
        'ORDER' : '4',
        'HEAD STAFF No' : 'Accra Central',
        'ACTIVE' : true,
        'REMARKS' : ''
    },
    {
        'ID' : 7,
        'DEPARTMENT' : 'Accra Central',
        'SECTION' : 'Accra Central',
        'UNIT' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'UNIT HEAD' : 'Accra',
        'ORDER' : '4',
        'HEAD STAFF No' : 'Accra Central',
        'ACTIVE' : false,
        'REMARKS' : ''
    },
    {
        'ID' : 8,
        'DEPARTMENT' : 'Accra Central',
        'SECTION' : 'Accra Central',
        'UNIT' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'UNIT HEAD' : 'Accra',
        'ORDER' : '4',
        'HEAD STAFF No' : 'Accra Central',
        'ACTIVE' : true,
        'REMARKS' : ''
    },
]