import React, { useState } from "react";
import { DateTemplate } from "../../../../templates/date";
import { checkboxTypes, inputsTypes } from "../../../human-resource/setups/data/types/selectsTypes";
import { Form } from "antd";
import { CheckboxTemlate } from "../../../../templates/checkbox";
import {
  Cancel,
  CustomizableButton,
  SearchButton,
} from "../../../accessories/buttons";
import message from "../../../../assets/email.png";
import settings from "../../../../assets/settings.png";
import print from "../../../../assets/printer.png";
import Datagrid_template from "../../../../templates/Datagrid";
import { SwitchTemplate } from "../../../../templates/switch";
import { InputsTemplate } from "../../../../templates/input";
import useResizeMode from "../../../../hooks/useResizeMode";
import { pendingSMS_cols } from "../data/datagridCols";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import useFetch from "../../../../hooks/useFetch";
import { UpdateDataFunc, UpdateDataFuncParams } from "../../../../functions/update";
import { ModalTemplate } from "../../../../templates/modal";
import { saveModalData } from "../../../accessories/component_infos";
import { PostDataFunc  } from "../../../../functions/post";
import { useAccountInfo } from "../../../../hooks/useAccountInfo";
import { useFormPriviledge } from "../../../../hooks/useFormPriviledge";
import { useCrudFunc } from "../../../../functions/crud";

interface stateTypes {
    checked: boolean;
    sms: boolean;
    tmsJobs: boolean;
    refresh: boolean;
    tasksInterval: string;
    smsInterval: string;
    selectedRecord
}

export const PendingSMS = () => {
    const [states, setStates] = useState<stateTypes>({ 
        checked: false,
        sms: false,
        tmsJobs: false,
        refresh: false,
        tasksInterval: "1800",
        smsInterval: "5000",
        selectedRecord: {}
    });

    const setState = (key: string, value: any) => {
        setStates((prev) => ({ ...prev, [key]: value }));
      };

  const [initial, final] = useResizeMode(window.innerHeight);
  const gridheight = initial - 310;

  // Redux
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const [employeeId, userId, userInfo] = useAccountInfo();
  const [privType, setPrivType] = useState<string>("save");
  const [savePriv, updatePriv] = useFormPriviledge(privType);
  const formCode = useSelector((state: RootState) => state.general.formCode);
  const [pendingSms, pendingSmsError, pendingSmsloading] = useFetch(
    `SMSLogs/GetAllPendingSMS`, states.refresh
  );

  const dates: inputsTypes[] = [
    { id: 0, label: "Time", stateName: "", defaultValue: "", style: "" },
    { id: 0, label: "", stateName: "", defaultValue: "", style: "" },
  ];
//   const checkboxes: inputsTypes[] = [
//     { id: 0, label: "", stateName: "", defaultValue: "", style: "" },
//     { id: 0, label: "", stateName: "", defaultValue: "", style: "" },
//   ];
  const switches: checkboxTypes[] = [
    { id: 0, label: "SMS-", stateName: "sms", defaultValue: states.sms },
    {
      id: 1,
      label: "TMS Jobs",
      stateName: "tmsJobs",
      defaultValue: states.tmsJobs
    },
  ];
  const inputs: inputsTypes[] = [
    {
      id: 0,
      label: "SMS Time Interval (seconds)",
      stateName: "smsInterval",
      defaultValue: states.smsInterval,
      style: "",
      extra: (
        <CustomizableButton
          isText
          text={"Run and Send SMS"}
          classname={"w-[200px] flex items-center mx-2 px-0.5"}
          customizableBtnImage={message}
          customizableBtnImageClassname={"px-1"}
          handleCustomizableClick={() => {sendSms()}}
        />
      ),
    },
    {
      id: 0,
      label: "Tasks Interval (seconds)",
      stateName: "tasksInterval",
      defaultValue: states.tasksInterval,
      style: "",
      extra: (
        <CustomizableButton
          isText
          text={"Run and Send TMS Alerts"}
          classname={"w-[200px] flex items-center mx-2 px-0.5"}
          customizableBtnImage={settings}
          customizableBtnImageClassname={"px-1"}
          handleCustomizableClick={() => {sendAlert()}}
        />
      ),
    },
  ];

  // const [selectedRecord, setSelectedRecord] = useState<any>({});
  console.log("selectedRecord",states.selectedRecord);

  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [wrap, setWrap] = useState<boolean>(false);
  const [Posting, Updating, Patching] = useCrudFunc()

  const deleteData = async () => {
    
    const res = await Updating(`SMSLogs/DeletePendingSMS?MessageID=${states.selectedRecord.imsIDpk}`,{},`Deleted Pending SMS with ID: ${states.selectedRecord.imsIDpk} by ${userInfo.empName} successfully`);
    if(res.status === 200) {
      setModalData({
        message: "Record has been deleted successfully",
        title: "Delete Successful",
        okText: "Ok",
      });
      setIcon("success");
      setOpenModal(true);
      setState('refresh', !states.refresh);
    }
  };

  const deleteModalData = {
    message: `Are you sure you want to DELETE this record?`,
    title: "Delete Record?",
    okText: "Yes",
    cancelText: "No",
  };

  const cancelAccessPriv = () => {
    if (Object.keys(states.selectedRecord).length === 0) {
      setModalData({
        message: "Please select a record to delete",
        title: "No Record Selected",
        okText: "Ok",
      });
      setIcon("warning");
      setOpenModal(true);
    } else {
      setModalData(deleteModalData);
      setIcon("question");
      setOpenModal(true);
    }
  };

  const checkConnection = () => {
    if (!navigator.onLine) {
      setIcon("warning");
      setOpenModal(true);
      setModalData({
        message:
          "Your computer is not connected to the Internet. Please check your Internet connection and try again",
        title: "Connectivity Error!",
        okText: "Ok",
      });
    }
  };

  let mobNox = ""; 
  const sendSms = async () => {
    try {
      checkConnection();
      if (states.selectedRecord?.empIDpk) {
        mobNox = states.selectedRecord?.empMobNox.trim().replace("", "");
      }
      if (mobNox.trim.length >= 10) {
        mobNox = mobNox.substring(0, 10);
      }

      await PostDataFunc (`SMSAlert/SaveToLogs`, {
        slgMessage: states.selectedRecord?.slgMessage,
        slgMobNo: states.selectedRecord?.empMobNox,
        slgSender: userInfo.empName,
        slgSent: true,
        slgRecordIDfk: states.selectedRecord.vdtIDpk,//wrong ID
        slgFormCode: formCode,
        slgType: "string",
        slgClass: "string",
        slgSmsServiceProvider: "Hubtel",
        slgEmpIDfk: states.selectedRecord.empIDpk,
        slgInitiatorEmpIDfk: userId,
        slgCreatedBy: userId,
      });

      // await PostDataFunc (`AsmTsmEntMaintenanceSchedule/SaveSmsAlert`, {
      //   altFormCode: "asmTransMaintenanceSchedules",
      //   altMessage: message,
      //   altTakeAction: true,
      // });
      setIcon("success");
      setOpenModal(true);
      setModalData({
        message: "SMS Sent Successfully",
        title: "Success",
        okText: "Ok",
      });
    } catch {
      setIcon("warning");
      setOpenModal(true);
      setModalData({
        message: "Failed to send SMS!.. Please contact your administrator",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };
  const sendAlert = async () => {
    try {
      checkConnection();
      if (states.selectedRecord?.empIDpk) {
        mobNox = states.selectedRecord?.empMobNox.trim().replace("", "");
      }
      if (mobNox.trim.length >= 10) {
        mobNox = mobNox.substring(0, 10);
      }
      await PostDataFunc (`AsmTsmEntMaintenanceSchedule/SaveSmsAlert`, {
        altFormCode: formCode,
        altMessage: states.selectedRecord?.slgMessage,
        altTakeAction: true,
      });
      setIcon("success");
      setOpenModal(true);
      setModalData({
        message: "SMS Alert Sent Successfully",
        title: "Success",
        okText: "Ok",
      });
    } catch {
      setIcon("warning");
      setOpenModal(true);
      setModalData({
        message: "Failed to send SMS Alert!.. Please contact your administrator",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };

  return (
    <>
      <ModalTemplate
        icon={icon}
        open={openModal}
        disableCancel={icon === "warning" || icon === "success" ? true : false}
        cancelText={modalData.cancelText}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        okHandler={() => modalData.title === "Delete Record?" ? deleteData() : setOpenModal(false)}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
      />

      
      <div className="w-full -full border-[1px] rounded-sm ">
        <p
          id={"form_header3"}
          style={{ borderColor: borderTheme }}
          className="w-full bg-slate-100 pl-2 py-0.5 font-medium"
        >
          {"Pending Messages"}
        </p>

        <Form
          className="w-full rounded py-1 px-2"
          name="basic"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          size="small"
        >
          {/* Top toolbar */}
          <section className="w-full flex  justify-between items-center ">
            <div className="w-[80%] flex space-x-2 items-center pl-4">
              <CheckboxTemlate
                useCallbackFunc
                withBorders
                defaultValue={states.checked}
                setCheckboxOnchange={(e) => {
                    setState("checked", e);
                    setWrap(e);
                }}
                customDisabled={false}
              />

              <SearchButton isText />
              <div className="flex ">
                <CustomizableButton
                  customizableBtnImage={print}
                  classname={"p-1"}
                />
              </div>
            </div>

            <div className="w-[20%] flex justify-end items-center ">
              <Cancel
                useCallbackFunc
                cancel_button_toggler={cancelAccessPriv}
                text={"Delete"}
              />
            </div>
          </section>

          {/* Grid */}

          <section className="">
            <Datagrid_template
              gridheight={gridheight}
              columns={pendingSMS_cols}
              data={pendingSms}
              disablePaging
              wordWrap={wrap}
              onRowClick={(e) => setState("selectedRecord", e)}
            />
          </section>

          {/* Footer */}

          <section className="w-[55%] flex justify-between">
            {/* switches */}
            <div className="w-1/3 ">
              {switches.map(({ id, label, stateName, defaultValue }) => {
                return (
                  <Form.Item
                    label={<p className="text-xs">{label}</p>}
                    labelCol={{ span: 7 }}
                    
                  >
                    <div className="flex border-[1px]">
                    <SwitchTemplate
                      defaultValue={defaultValue}
                      customDisabled={false}
                      handleOnchange={(e) => {
                        setState(stateName!, e);
                      }}
                    />
                    {id === 0 ? (
                      <p className="text-xs flex items-center px-1 justify-start">{`${states.sms === true  ? "Sending" : "Stopped"}`}</p>

                      ):(
                        <p className="text-xs flex items-center px-1 justify-start">{`${ states.tmsJobs === true ? "Sending" : "Stopped"}`}</p>

                      )
                    
                    }
                    </div>
                  </Form.Item>
                );
              })}
            </div>

            {/* inputs */}
            <div className="w-2/3">
              {inputs.map(
                ({ id, label, defaultValue, stateName, style, extra }) => {
                  return (
                    <div className="w-full ">
                      <InputsTemplate
                        numberOnly={true}
                        labelColPan={9}
                        label={label}
                        useCallbackFunc
                        defaultValue={defaultValue}
                        orderOnchange={(e) => {
                          setState(stateName!, e);
                        }}
                        readOnly={false}
                        span
                        inputStyle={{ width: 150 }}
                        disabledStatus={false}
                        additionalWidget={<span>{extra}</span>}
                      />
                    </div>
                  );
                }
              )}
            </div>
          </section>
        </Form>
      </div>
    </>
  );
};
