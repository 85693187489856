import { Chart } from "devextreme-react";
import {
  CommonSeriesSettings,
  Export,
  Grid,
  Legend,
  Series,
  Tooltip,
  ValueAxis,
} from "devextreme-react/chart";

interface props {
  chartheight: any;
  columns: any;
  data: any;
  loading?: boolean;
  selected?: any;
  chartType?: "area" | "bar" | "bubble" | "candlestick" | "fullstackedarea" | "fullstackedbar" | "fullstackedline" | "fullstackedspline" | "fullstackedsplinearea" | "line" | "rangearea" | "rangebar" | "scatter" | "spline" | "splinearea" | "stackedarea" | "stackedbar" | "stackedline" | "stackedspline" | "stackedsplinearea" | "steparea" | "stepline" | "stock";
  chartRef?: any;
  YAxis_label?: string;
  XAxis_cols?: any;
  paletteColor?: any;
  style?: any;
}

export const Chart_template = ({
  chartRef,
  columns,
  data,
  chartheight,
  chartType,
  YAxis_label,
  XAxis_cols,
  paletteColor,
  style,
}: props) => {
  return (
    <div className="h-full w-full mt-1">
      {/* custom style for search section */}
      <style
        dangerouslySetInnerHTML={{
          __html: `
                    .dx-datagrid-header-panel .dx-toolbar {
                        margin-bottom: 1.5px;
                    }`,
        }}
      />

      <Chart
        ref={chartRef}
        style={{ ...style, height: chartheight }}
        dataSource={data}
        palette={paletteColor}
        id="chart"
      >
        <CommonSeriesSettings argumentField={XAxis_cols} type={chartType} />

        {columns.map((column: any, index: number) => (
          <Series
            valueField={column.dataField !== XAxis_cols && column.dataField}
          />
        ))}
        <ValueAxis
          name="total"
          position="left"
          title={YAxis_label}
          valueType={"numeric"}
        >
          <Grid visible={true} />
        </ValueAxis>

        <Legend
          horizontalAlignment={"center"}
          verticalAlignment={"bottom"}
          orientation={"horizontal"}
        />

        <Export enabled={true} />
        <Tooltip enabled={true} />
      </Chart>
    </div>
  );
};
