/* eslint-disable react/jsx-pascal-case */

import React, { useRef } from 'react'
import TabsPanel_template from '../../../../templates/tabsPanel_template';
import Apa_right2_assetMgnt from './apa_right2_assetMgnt';
import Apa_right2_sss from './apa_right2_sss';
import Apa_r2_gen from './apa_r2_gen';
import Apa_right2_hr from './apa_right2_hr';
import { GetAllUsersInterface } from './apa_form';

interface PageProps {
    rowData: GetAllUsersInterface
}


const Apa_right2 = ({rowData}: PageProps) => {

    const tabsRef = useRef<any>(null)

    console.log("tabsRef", tabsRef);

    const tabs: { id: number, title: string, contents: any }[] = [
        { id: 0, title: "Gen. Settings", contents: <Apa_r2_gen rowData={rowData} /> },
        { id: 1, title: "Human Resources", contents: <Apa_right2_hr /> },
        { id: 2, title: "Self Service Settings", contents: <Apa_right2_sss /> },
        { id: 3, title: "Asset Mgnt", contents: <Apa_right2_assetMgnt rowData={rowData}/> },

    ]

    const tabHeight = tabsRef.current

    console.log("tab heigth",tabHeight)


    return (
        <div className='w-full h-full'>
            <aside className='w-full h-full'>

                <TabsPanel_template ref={tabsRef} tabs={tabs} tabsPosition={"left"} height={1000} animationEnabled={true} swipeEnabled={true} />

            </aside>

        </div>
    )
}

export default Apa_right2;