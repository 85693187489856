

export const footer_datagrid_cols = [
    {id: 1,caption : "PERIOD", dataField : 'PERIOD.', dataType : "number", fixed : true,width:150},
    {id: 2,caption : "BUDGET", dataField : 'BUDGET', dataType : "number", fixed : true,width:150},
    {id: 3,caption : "DEPARTMENT", dataField : 'DEPARTMENT', dataType : "number", fixed : true,width:150},
    {id: 4,caption : "SECTION", dataField : 'SECTION', dataType : "number", fixed : true,width:150},
    {id: 5,caption : "INITIATIVE", dataField : 'INITIATIVE', dataType : "number", fixed : true,width:150},
    {id: 6,caption : "ACTIVE?", dataField : 'ACTIVE?', dataType : "date",format:"dd MMM yyyy",fixed : true},
    {id: 7,caption : "PRD START DATE", dataField : 'PRD START DATE', dataType : "string",width:150},
    {id: 8,caption : "PRD END DATE", dataField : 'PRD END DATE', dataType : "string",width:150},
    {id: 9,caption : "OBJECTIVE", dataField : 'OBJECTIVE', dataType : "string",width:150},
    {id: 10,caption : "THEME", dataField : 'THEME', dataType : "string",width:150},
    {id: 11,caption : "MISSION/VISION", dataField : 'MISSION/VISION', dataType : "string",width:150},
    {id: 12,caption : "REMARKS", dataField : 'REMARKS', dataType : "string",width:4000},
    
]

// PERSPECTIVES
// FOR PERSPECTIVE FOOTER DATAGRID
export const perspective_footer_datagrid_cols = [
    {id: 1,caption : "NAME", dataField : 'NAME.', dataType : "string", fixed : true,width:150},
    {id: 2,caption : "SHORT NAME", dataField : 'SHORT NAME.', dataType : "string", fixed : true,width:150},
    {id: 3,caption : "ACTIVITY?", dataField : 'ACTIVITY?.', dataType : "boolean", fixed : true,width:60},
    {id: 4,caption : "FINANCIAL?", dataField : 'FINANCIAL?.', dataType : "boolean", width:100},
    {id: 5,caption : "ORG. CAPACITY", dataField : 'ORG. CAPACITY.', dataType : "boolean", fixed : true,width:80},
    {id: 6,caption : "CUST/STAKEH", dataField : 'CUST/STAKEH.', dataType : "boolean", fixed : true,width:80},
    {id: 7,caption : "BUS/INT.PROC?", dataField : 'BUS/INT.PROC?', dataType : 'boolean', fixed : true,width:80},
    {id: 8,caption : "ORDER", dataField : 'ORDER.', dataType : "number", width:80},
    {id: 9,caption : "QUESTIONS ANSWERED", dataField : 'QUESTIONS ANSWERED.', dataType : "string", fixed : true,width:180},
    
    {id: 10,caption : "Remarks", dataField : 'Remarks.', dataType : "string", width:4000},
]




{/* <Column  dataField="LOCKED?"  fixed={true} width={60} cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataType='boolean'></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="DATE" fixed={true} dataType='date' width={100} format={"dd MMM yyyy"} ></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="%COMPLETE" fixed={true} cellRender={progressBar}></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="TASK UPDATE"  dataType='string' ></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="UPDATED BY" dataType='string' ></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="JOB TITLE" dataType='string'></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="UPDATER" dataType='string' width={100}></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="CREATED ON " dataType='string'></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="COMPLETION DATE" dataType='string'></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="REMARKS" dataType='string' width={4000} ></Column> */}