import { TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/accordion";

import { useEffect, useState } from "react";
import VehicleRequestPriviledges from "./Vehicle-Requests/widgets/vehicleRequestPriviledges";

interface props {
  selected: string;
}

const PriviledgesEntriesScreen = ({ selected }: props) => {
  const [tabs, setTabs] = useState<string[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (tabs.indexOf(selected) > -1 === true) {
      setCurrentIndex(tabs.indexOf(selected));
    } else {
      setTabs([...tabs, selected]);
      setCurrentIndex(tabs.length);
    }
  }, [window.location.pathname]);
  return (
    <div className="w-full h-full flex justify-center items-center">
      <TabPanel selectedIndex={currentIndex} className="w-full h-full">
        {tabs.map((tab) => {
          switch (tab) {
            case "TMS-Vehicle Requests Priviledges":
              return (
                <Item title={tab}>
                  <VehicleRequestPriviledges />
                </Item>
              );

            default:
              return <Item title={tab}></Item>;
          }
        })}
      </TabPanel>
    </div>
  );
};

export default PriviledgesEntriesScreen;
