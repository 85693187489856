import React from "react";
import Webcam from "react-webcam";

interface props {
  webcamRef?: any;
  height?: number;
  width?: number;
  vcheight?: number;
  vcwidth?: number;
}

const WebCam_template = ({
  webcamRef,
  height,
  width,
  vcheight,
  vcwidth,
}: props) => {
  const videoConstraints = {
    width: 300,
    height: 300,
    // height: 220,
    facingMode: "user",
  };

  return (
    <div className="w-full ">
      <Webcam
        audio={false}
        height={height}
        ref={webcamRef}
        screenshotQuality={10}
        imageSmoothing={true}
        screenshotFormat="image/jpeg"
        width={width}
        videoConstraints={videoConstraints}
      />
    </div>
  );
};

export default WebCam_template;
