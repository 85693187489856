
export const ss_cols =[
    { id: 2, caption: "Service Station", dataField: 'sstName', dataType: 'string', fixed: true, width: 80 },
    { id: 3, caption: "Town", dataField: 'twnName', dataType: 'string', fixed: true, width: 80 },
]

export const vehicle_cols = [
    { id: 1, caption: "Reg No", dataField: 'vdtRegNo', dataType: "", fixed: true, width: 80 },
    { id: 2, caption: "Class", dataField: 'vhcShtName', dataType: 'string', fixed: true, width: 80 },
    { id: 3, caption: "Make", dataField: 'vmkShtName', dataType: 'string', fixed: true, width: 80 },
    { id: 4, caption: "Model", dataField: 'mdlShtName', dataType: 'string', fixed: true, width: 80, format: { type: 'fixedPoint', precision: 2 } },
    { id: 5, caption: "Manufacturer", dataField: 'mftShtName', dataType: 'string', fixed: true, width: 80, format: { type: 'fixedPoint', precision: 2 } },
    { id: 6, caption: "Driver", dataField: 'driverEmpName5', dataType: 'string', format: { type: 'fixedPoint', precision: 2 }, fixed: true, width: 120 },
    { id: 7, caption: "Driver INTERCOM.", dataField: 'driverPlc', width: 120, dataType: 'string',  },
    { id: 8, caption: "Approving Officer", dataField: 'ownerEmpName5', dataType: 'string', width: 120,  },
    { id: 9, caption: "Appr. Officer INTERCOM.", dataField: 'ownerPlc', dataType: 'string', width: 120,  },
    { id: 10, caption: "Manuf. Date", dataField: 'vdtManufactureDate', dataType: 'string', width: 120,  },
    { id: 11, caption: "Dept", dataField: 'dptShtName', dataType: 'string', width: 120,  },
    { id: 12, caption: "Section", dataField: 'sxnShtName', dataType: 'string', width: 120,  },
    { id: 13, caption: "Fuel Type", dataField: 'ftpShtName', dataType: 'string', width: 120,  },
    

]
export const vehicleF_cols = [
    { id: 1, caption: "Reg No", dataField: 'vdtRegNo', dataType: "", fixed: true, width: 80 },
    { id: 2, caption: "Class", dataField: 'vhcShtName', dataType: 'string', fixed: true, width: 80 },
    { id: 3, caption: "Make", dataField: 'vmkShtName', dataType: 'string', fixed: true, width: 80 },
    { id: 4, caption: "Model", dataField: 'mdlShtName', dataType: 'string', fixed: true, width: 80, format: { type: 'fixedPoint', precision: 2 } },
    { id: 5, caption: "Manufacturer", dataField: 'mftShtName', dataType: 'string', fixed: true, width: 80, format: { type: 'fixedPoint', precision: 2 } },
    { id: 6, caption: "Driver", dataField: 'driverEmpName5', dataType: 'string', format: { type: 'fixedPoint', precision: 2 }, fixed: true, width: 120 },
    { id: 7, caption: "Driver INTERCOM.", dataField: 'driverPlc', width: 120, dataType: 'string',  },
    { id: 8, caption: "Approving Officer", dataField: 'ownerEmpName5', dataType: 'string', width: 120,  },
    { id: 9, caption: "Appr. Officer INTERCOM.", dataField: 'ownerPlc', dataType: 'string', width: 120,  },
    { id: 10, caption: "Manuf. Date", dataField: 'vdtManufactureDate', dataType: 'string', width: 120,  },
    { id: 11, caption: "Dept", dataField: 'dptShtName', dataType: 'string', width: 120,  },
    { id: 12, caption: "Section", dataField: 'sxnShtName', dataType: 'string', width: 120,  },
    { id: 13, caption: "Fuel Type", dataField: 'ftpShtName', dataType: 'string', width: 120,  },
    

]

export const authorizeBy_cols = [
    {id: 0,caption : "Staff No", dataField : 'empStaffNo', dataType : "", fixed : false,width:80},
    {id: 1,caption : "Name", dataField : 'empName', dataType : "", fixed : false,width:120},
    {id: 2,caption : "Dept", dataField : 'dptShtName', dataType : "", fixed : false,width:80},
    {id: 3,caption : "Section", dataField : 'sxnShtName', dataType : "", fixed : false,width:80},
    {id: 4,caption : "Job Title", dataField : 'jbtShtName', dataType : "", fixed : false,width:90},
    {id: 5,caption : "Location", dataField : 'locShtName', dataType : "", fixed : false,width:80},
]


export const footer_datagrid_service_pro_cols = [
    
    { id: 6, caption: "REG. No", dataField: 'sptRegNo', dataType: 'string', format: { type: 'fixedPoint', precision: 2 }, fixed: true, width: 120 },
    { id: 7, caption: "DATE", dataField: 'sptDate', width: 120, dataType: 'datetime',  },
    { id: 8, caption: "TIME", dataField: 'sptTime', dataType: 'time', width: 120,  },
    { id: 9, caption: "CARD No", dataField: 'sptCardNo', width: 120, dataType: 'string', format: { type: 'fixedPoint', precision: 2 }, fixed: true },
    { id: 10, caption: "PRODUCT", dataField: 'sptProduct', width: 90, dataType: 'string', format: { type: 'fixedPoint', precision: 2 }, },
    { id: 11, caption: "QUANTITY", dataField: 'sptQuantity', width: 90, dataType: 'string', format: { type: 'fixedPoint', precision: 2 }, },
    { id: 12, caption: "AMOUNT", dataField: 'sptAmount', dataType: "number", width: 90 },
    { id: 13, caption: "RECEIPT No", dataField: 'sptReceiptNo', dataType: "", width: 90 },
    { id: 14, caption: "UNIT PRICE", dataField: 'sptUnitPrice', dataType: "", width: 90 },
    { id: 15, caption: "BALANCE", dataField: 'sptBalance', dataType: "", width: 90 },
    { id: 16, caption: "STATION", dataField: 'fmgAmount', dataType: "", width: 90 },
    { id: 17, caption: "PURCHASE BY", dataField: 'cdtCardNo', dataType: "", width: 90 },
    { id: 18, caption: "TANK CAP.", dataField: 'vdtTankCapacity', dataType: "", width: 90 },
    { id: 19, caption: "REFILL INTVL (Days)", dataField: 'sptRefillIntervalDays', dataType: "", width: 90 },
    { id: 20, caption: "CURRENT MILLEAGE", dataField: 'sptCurrentMileage', dataType: "", width: 90 },
    { id: 21, caption: "PAST MILLEAGE", dataField: 'sptPastMileage', dataType: "", width: 90 },
    { id: 22, caption: "MILEAGE", dataField: 'sptMileagex', dataType: "", width: 90 },
    { id: 23, caption: "VEHICLE DEPT", dataField: 'vehAssignDeptShtName', dataType: "string", width: 90 },
    { id: 24, caption: "DRIVER DEPT", dataField: 'driverCodeDeptShtName', dataType: "", width: 90 },
    { id: 25, caption: "DRIV. STAFF No", dataField: 'driverCodeStaffNo', dataType: "string", width: 90 },
    { id: 26, caption: "OPERATION TYPE", dataField: 'sptOperationType', dataType: "", width: 90 },
    { id: 27, caption: "CARD NAME", dataField: 'sptCardName', dataType: "string", width: 90 },
    { id: 28, caption: "INVOICE No", dataField: 'sptInvoiceNo', dataType: "string", width: 90 },
    { id: 29, caption: "STATION No", dataField: 'sptStationNo', dataType: "string", width: 90 },
    { id: 30, caption: "CARD TYPE", dataField: 'sptCardType', dataType: "string", width: 90 },
    { id: 31, caption: "PRODUCT CODE", dataField: 'sptProductCode', dataType: "string", width: 90 },
    { id: 32, caption: "CURRENCY No", dataField: 'sptCurrencyNo', dataType: "string", width: 90 },
    { id: 33, caption: "CURRENCY", dataField: 'sptCurrency', dataType: "string", width: 90 },
    { id: 34, caption: "INVOICE DATE", dataField: 'sptInvoiceDate', dataType: "string", width: 90 },
    { id: 35, caption: "CUSTOMER", dataField: 'sptCustomer', dataType: "datetime", width: 90 },
    { id: 36, caption: "CUST. No", dataField: 'sptCustomerNo', dataType: "string", width: 90 },
    { id: 37, caption: "ACTIVE?", dataField: 'sptActive', dataType: "string", width: 90 },
    { id: 38, caption: "REMARKS", dataField: 'sptRmks', dataType: "string", width: 4000},
   
]

export const FD_cols =[
    {id: 1,caption : "Amount", dataField : 'amtx', dataType : "", fixed : true,width:150},
    {id: 2,caption : "Quantity", dataField : 'qtyx', dataType : "", fixed : true,width:150},
]
export const VF_cols =[
    {id: 1,caption : "Amount", dataField : 'amtx', dataType : "", fixed : true,width:150},
    {id: 2,caption : "Quantity", dataField : 'qtyx', dataType : "", fixed : true,width:150},
]


export const footer_datagrid_auth_cols = [
    { id: 1, caption: "VRFD?", dataField: 'fmgVerified', dataType: 'boolean', fixed: true, width: 80 },
    { id: 2, caption: "LCKD?", dataField: 'fmgLocked', dataType: 'boolean', fixed: true, width: 80 },
    { id: 3, caption: "AUTH?", dataField: 'fmgAuthorised', dataType: 'boolean', fixed: true, width: 80, format: { type: 'fixedPoint', precision: 2 } },
    { id: 4, caption: "ACTIVE?", dataField: 'fmgActive', dataType: 'boolean', fixed: true, width: 80, format: { type: 'fixedPoint', precision: 2 } },
    { id: 5, caption: "REQUEST DATE", dataField: 'fmgDate', width: 120, dataType: 'datetime',  },
    { id: 6, caption: "DATE ISSUED", dataField: 'fmgDateDispensed', dataType: 'datetime', width: 120,  },
    { id: 7, caption: "RECEIVED BY", dataField: 'driverName', width: 120, dataType: 'string', format: { type: 'fixedPoint', precision: 2 }, fixed: true },
    { id: 8, caption: "QTY DISP.", dataField: 'fmgQuantityDispensed', dataType: "", width: 90 },
    { id: 9, caption: "QTY AUTH.", dataField: 'fmgQuantityAuthorised', dataType: "", width: 90 },
    { id: 10, caption: "QTY REQ.", dataField: 'fmgQuantityRequested', dataType: "", width: 90 },
    { id: 11, caption: "PRODUCT", dataField: 'prdName', dataType: "string", width: 90 },
    { id: 12, caption: "AMOUNT", dataField: 'fmgAmount', dataType: "", width: 90 },
    { id: 13, caption: "CARD No", dataField: 'cdtCardNo', dataType: "", width: 90 },
    { id: 14, caption: "RECEIPT No", dataField: 'fmgReceiptNo', dataType: "", width: 90 },
    { id: 15, caption: "BALANCE", dataField: 'fmgBalance', dataType: "", width: 90 },
    { id: 16, caption: "SERV. PROV.", dataField: 'spdName', dataType: "string", width: 90 },
    { id: 17, caption: "MONTH", dataField: 'monthx', dataType: "string", width: 90 },
    { id: 18, caption: "CURR. ODOM. RDN", dataField: 'fmgCurrentOdometerReading', dataType: "string", width: 90 },
    { id: 19, caption: "PREV. ODOM RDN", dataField: 'fmgPreviousOdometerReading', dataType: "string", width: 90 },
    { id: 20, caption: "DISTANCE(km)", dataField: 'distanceTravelled', dataType: "", width: 90 },
    { id: 21, caption: "VEH. DEPT", dataField: 'vehAssignDeptShtName', dataType: "string", width: 90 },
    { id: 22, caption: "VEH. SECTION", dataField: 'vehAssignSxnShtName', dataType: "", width: 90 },
    { id: 23, caption: "DRIVER DEPT", dataField: 'dptShtName', dataType: "string", width: 90 },
    { id: 24, caption: "DRIVER SECTION", dataField: 'sxnShtName', dataType: "string", width: 90 },
    { id: 25, caption: "VEH. CLASS", dataField: 'vhcName', dataType: "string", width: 90 },
    { id: 26, caption: "DISPENSED BY", dataField: 'dispenserEmpName', dataType: "string", width: 90 },
    { id: 27, caption: "DATE DISPENSED", dataField: 'dispenserDptShtName', dataType: "string", width: 90 },
    { id: 28, caption: "DISPENSER DEPT", dataField: 'dispenserSxnShtName', dataType: "string", width: 90 },
    { id: 29, caption: "DATE AUTHORISED", dataField: 'fmgDateAuthorised', dataType: "datetime", width: 90 },
    { id: 30, caption: "DISPENSER SECTION", dataField: 'dispenserSxnShtName', dataType: "string", width: 90 },
    { id: 31, caption: "VEH. MAKE", dataField: 'vmkName', dataType: "string", width: 90 },
    { id: 32, caption: "VEH. MODEL", dataField: 'mdlName', dataType: "string", width: 90 },
    { id: 33, caption: "MANUF.", dataField: 'mftName', dataType: "string", width: 90 },
    { id: 34, caption: "AUTHORISED BY", dataField: 'authoriserEmpName', dataType: "string", width: 90 },
    { id: 35, caption: "ASSIGNED?", dataField: 'vdtAssigned', dataType: "boolean", width: 90 },
    { id: 36, caption: "REMARKS", dataField: 'fmgRmks', dataType: "string", width: 4000 },
]
export const footer_datagrid_authV_cols = [
    { id: 1, caption: "DATE", dataField: 'fddDate', dataType: 'datetime', fixed: true, width: 80 },
    { id: 2, caption: "SERVICE STATION", dataField: 'sstShtName', dataType: 'string', fixed: true, width: 80 },
    { id: 3, caption: "OPENING BAL", dataField: 'fddOpeningBalance', dataType: '', fixed: true, width: 80, format: { type: 'fixedPoint', precision: 2 } },
    { id: 4, caption: "RECEIPT", dataField: 'fddReceipt', dataType: '', fixed: true, width: 80, format: { type: 'fixedPoint', precision: 2 } },
    { id: 5, caption: "ISSUE", dataField: 'fddIssue', width: 120, dataType: '',  },
    { id: 6, caption: "CLOSING BAL", dataField: 'fddClosingBalance', dataType: '', width: 120,  },
    { id: 7, caption: "OPENING MET. READING", dataField: 'fddOpeningMeterReading', width: 120, dataType: '', format: { type: 'fixedPoint', precision: 2 }, fixed: true },
    { id: 8, caption: "OPENING DIP BAL", dataField: 'fddOpeningDipBalance', dataType: "", width: 90 },
    { id: 9, caption: "ACTIVE?", dataField: 'fddActive', dataType: "boolean", width: 90 },
    { id: 10, caption: "REMARKS", dataField: 'fddRmks', dataType: "string", width: 4000 },
    
]


export const footer_datagrid_fuelreturns_cols = [
    { id: 1, caption: "ID", dataField: 'fmgIDpk', dataType: "", fixed: true, width: 60 },
    { id: 2, caption: "VRFD?", dataField: 'fmgVerified', dataType: 'boolean', fixed: true, width: 80 },
    { id: 3, caption: "LCKD?", dataField: 'fmgLocked', dataType: 'boolean', fixed: true, width: 80 },
    { id: 4, caption: "AUTH?", dataField: 'fmgAuthorised', dataType: 'boolean', fixed: true, width: 80, format: { type: 'fixedPoint', precision: 2 } },
    { id: 5, caption: "ACTIVE?", dataField: 'fmgActive', dataType: 'boolean', fixed: true, width: 80, format: { type: 'fixedPoint', precision: 2 } },
    { id: 6, caption: "REG. No", dataField: 'vdtRegNo', dataType: 'string', format: { type: 'fixedPoint', precision: 2 }, fixed: true, width: 120 },
    { id: 7, caption: "REQUEST DATE", dataField: 'fmgDate', width: 120, dataType: 'datetime',  },
    { id: 8, caption: "DISPENSE DATE", dataField: 'fmgDateDispensed', dataType: 'datetime', width: 120,  },
    { id: 9, caption: "RECEIVED BY", dataField: 'driverName', width: 120, dataType: 'string', format: { type: 'fixedPoint', precision: 2 }, fixed: true },
    { id: 10, caption: "PURCH. MODE", dataField: 'pcmName', width: 90, dataType: 'string', format: { type: 'fixedPoint', precision: 2 }, },
    { id: 11, caption: "SERV. STATION", dataField: 'sstName', width: 90, dataType: 'string', format: { type: 'fixedPoint', precision: 2 }, },
    { id: 12, caption: "PRODUCT", dataField: 'prdName', dataType: "string", width: 90 },
    { id: 13, caption: "QTY REQ.", dataField: 'fmgQuantityRequested', dataType: "", width: 90 },
    { id: 14, caption: "QTY AUTH.", dataField: 'fmgQuantityAuthorised', dataType: "", width: 90 },
    { id: 15, caption: "QTY DISP.", dataField: 'fmgQuantityDispensed', dataType: "", width: 90 },
    { id: 16, caption: "AMOUNT", dataField: 'fmgAmount', dataType: "", width: 90 },
    { id: 17, caption: "CARD No", dataField: 'cdtCardNo', dataType: "", width: 90 },
    { id: 18, caption: "RECEIPT No", dataField: 'fmgReceiptNo', dataType: "", width: 90 },
    { id: 19, caption: "BALANCE", dataField: 'fmgBalance', dataType: "", width: 90 },
    { id: 20, caption: "SERV. PROV.", dataField: 'spdName', dataType: "string", width: 90 },
    { id: 21, caption: "MONTH", dataField: 'monthx', dataType: "string", width: 90 },
    { id: 22, caption: "CURR. ODOM. RDN", dataField: 'fmgCurrentOdometerReading', dataType: "", width: 90 },
    { id: 23, caption: "PREV. ODOM RDN", dataField: 'fmgPreviousOdometerReading', dataType: "", width: 90 },
    { id: 24, caption: "DISTANCE(km)", dataField: 'distanceTravelled', dataType: "", width: 90 },
    { id: 25, caption: "COST CENTER", dataField: 'cctName', dataType: "string", width: 90 },
    { id: 26, caption: "COST CENT. CODE", dataField: 'cctCode', dataType: "", width: 90 },
    { id: 27, caption: "COST CENT. DEPT", dataField: 'costCenterDeptName', dataType: "string", width: 90 },
    { id: 28, caption: "COST CENT. SECTION", dataField: 'costCenterSxnName', dataType: "string", width: 90 },
    { id: 29, caption: "DRIVER DEPT", dataField: 'dptShtName', dataType: "string", width: 90 },
    { id: 30, caption: "DRIVER SECTION", dataField: 'sxnShtName', dataType: "string", width: 90 },
    { id: 31, caption: "DISPENSED BY", dataField: 'dispenserEmpName', dataType: "string", width: 90 },
    { id: 32, caption: "DISPENSER DEPT", dataField: 'dispenserDptShtName', dataType: "string", width: 90 },
    { id: 33, caption: "DISPENSER SECTION", dataField: 'dispenserSxnShtName', dataType: "string", width: 90 },
    { id: 34, caption: "AUTHORISED BY", dataField: 'authoriserEmpName', dataType: "string", width: 90 },
    { id: 35, caption: "DATE AUTHORISED", dataField: 'fmgDateAuthorised', dataType: "datetime", width: 90 },
    { id: 36, caption: "VEH. CLASS", dataField: 'vhcName', dataType: "string", width: 90 },
    { id: 37, caption: "VEH. MAKE", dataField: 'vmkName', dataType: "string", width: 90 },
    { id: 38, caption: "VEH. MODEL", dataField: 'mdlName', dataType: "string", width: 90 },
    { id: 39, caption: "MANUF.", dataField: 'mftName', dataType: "string", width: 90 },
    { id: 40, caption: "ASSIGNED?", dataField: 'vdtAssigned', dataType: "boolean", width: 90 },
    { id: 41, caption: "DATE SAVED", dataField: 'fmgDate', dataType: "datetime", width: 90 },
    { id: 42, caption: "REMARKS", dataField: 'fmgRmks', dataType: "string", width: 4000 },
]