import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import menu from "../../assets/list.png";
import rss from "../../assets/rss.png";
import help from "../../assets/help.png";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { Panel } from "./widgets/NavBody/NavBody";
import { Bar } from "./widgets/Bar/Bar";
import { useDispatch } from "react-redux";
import { setGeneralValue } from "../../features/generalSlice";
interface options {
  alertClicked: boolean;
  isOpened: boolean;
  setIsOpened?: (e: boolean) => void;
}

//sa
const Sidenav = forwardRef(({ isOpened, setIsOpened }: options, ref) => {
  const [current, setCurrent] = useState("Alert");
  const [viewHeight, setViewsHeight] = useState(window.innerHeight - 78.05); // window.innerHeight - 78.05
  const dispatch = useDispatch();
  window.onresize = (event) => {
    alertRef.current?.test();
    setViewsHeight(window.innerHeight - 58.05); //58.05
  };
  const alertRef = useRef<any>();
  //conditionally render sidebar, map with icons, add current ontop of navbar
  useImperativeHandle(ref, () => ({
    resizeSide: () => {
      alertRef.current?.test();
      setViewsHeight(window.innerHeight - 100.05);
    },
  }));
  const userSettingShowRightBar: boolean = useSelector(
    (state: RootState) => state.general.rightNavigatorOnStartup
  );
  const userSettingColor = useSelector(
    (state: RootState) => state.general.settingsColors?.rnbgc
  );
  const userSettingTC = useSelector(
    (state: RootState) => state.general.settingsColors?.rntc
  );
  
  useEffect(() => {
    dispatch(setGeneralValue({ expr: "sideAlertTest", value: isOpened })); 
  }, [isOpened]);
  const icons = [
    {
      id: 0,
      class:
        "hover:cursor-pointer fa-solid fa-bell text-amber-400 p-2  border-2 rounded-md",
      action: () => { 
        setCurrent("Alerts");
      },
    },
    {
      id: 2,
      class:
        "hover:cursor-pointer fa-solid fa-phone text-stone-500 p-2 mt-1  border-2 rounded-md",
      action: () => {
        setCurrent("Telephone");
      },
    },
    {
      id: 3,
      class:
        "hover:cursor-pointer fa-solid fa-envelope-circle-check mt-1  text-blue-500 p-2 border-2 rounded-md",
      action: () => {
        setCurrent("SMS Alerts");
      },
    },
    {
      id: 4,
      class:
        "hover:cursor-pointer  text-blue-500 p-2 border-2   mt-1  rounded-md",
      action: () => {
        setCurrent("Menu");
      },
      image: menu,
    },
    {
      id: 5,
      class:
        "hover:cursor-pointer fa-solid fa-calendar-days  mt-1 text-red-500 p-2 border-2 rounded-md",
      action: () => {
        setCurrent("Calendar");
      },
    },
    {
      id: 6,
      class:
        "hover:cursor-pointer  text-blue-500 p-2 border-2   mt-1 rounded-md",
      action: () => {
        setCurrent("RSS");
      },
      image: rss,
    },
    {
      id: 7,
      class:
        "hover:cursor-pointer  text-blue-500 p-2 border-2  mt-1 rounded-md",
      action: () => {
        setCurrent("Help");
      },
      image: help,
    },
  ];
  return (
    <>
      <aside
        style={{ height: viewHeight }}
        className=" float-right"
        aria-label="Sidebar"
      >
        <div
          className=" flex h-full  bg-gray-50  border-l-4 rounded   dark:bg-gray-800" 
        >
          {userSettingShowRightBar === false ? (
            isOpened === true ? (
              <Panel
                icons={icons}
                userSettingColor={userSettingColor}
                userSettingShowRightBar={userSettingShowRightBar}
                userSettingTC={userSettingTC}
                current={current}
                setCurrent={setCurrent}
                setIsOpened={setIsOpened}
                isOpened={isOpened}
              />
            ) : (
              <Bar
                isOpened={isOpened}
                setIsOpened={setIsOpened!}
                icons={icons}
                userSettingShowRightBar={userSettingShowRightBar}
              />
            )
          ) : isOpened === true ? (
            <Bar
              isOpened={isOpened}
              setIsOpened={setIsOpened!}
              icons={icons}
              userSettingShowRightBar={userSettingShowRightBar}
            />
          ) : (
            <Panel
              icons={icons}
              userSettingColor={userSettingColor}
              userSettingShowRightBar={userSettingShowRightBar}
              userSettingTC={userSettingTC}
              current={current}
              setCurrent={setCurrent}
              setIsOpened={setIsOpened}
              isOpened={isOpened}
            />
          )}
        </div>
      </aside>
    </>
  );
});

export default Sidenav;
