/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import TabsPanel_template from '../../../../templates/tabsPanel_template'
import Apa_r2_asM_Transport from './apa_r2_asM_Transport'
import { GetAllUsersInterface } from './apa_form'


interface PageProps {

    rowData: GetAllUsersInterface;
}


const Apa_right2_assetMgnt = ({rowData}: PageProps) => {

    const tabs: { id: number, title: string, contents: any }[] = [
        { id: 0, title: "Transport", contents: <Apa_r2_asM_Transport rowData={rowData}/> },

    ]

    return (
        <div className='w-full'>
            <TabsPanel_template tabs={tabs} />

        </div>
    )
}

export default Apa_right2_assetMgnt