import { Tooltip } from "devextreme-react";
import { useState } from "react";
import {
  useSearchParams,
  useNavigate,
  createSearchParams,
} from "react-router-dom";
import { EntryCustomToolbar } from "../../../../../../accessories/custom-toolbars/entryCustomtoolbar";
import { HrSetupsCustomToolbar } from "../../../../../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import { searchTextTypes } from "../../../../../../human-resource/setups/data/types/selectsTypes";
import VehicleLogBookDetails from "./vehicleLogBook-details";
import { VehicleLogBookToolbar } from "./vehicleLogBook-toolbar";





const VehicleLogBook = () => {
  const taskDetailsHeight = "309px";

  // const [tasks_update_state, setTask_update_state] = useState<boolean>(true);
  // const [task_datagrid_height, set_task_datagrid_height] = useState(
  //   `calc(100% - ${taskDetailsHeight})`
  // );

  // const [searchText, setSearchText] = useState<searchTextTypes>({
  //   temp: "",
  //   text: "",
  // });

  const [showTable, setShowTable] = useState<boolean>(true);

  // switch button toggler
  const switch_toggle = () => {
    setShowTable(!showTable);
  };

  

  return (
    <>
      <div className="px-2 mb-0.5  ">
        <VehicleLogBookDetails toggled={showTable} setToggled={switch_toggle} setShowTable={setShowTable}/>
      </div>
    </>
  );
};

export default VehicleLogBook;
