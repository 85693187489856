export const transaction_upload_th = [
  {
    id: 0,
    caption: "CUSTOMER_NUM",
    dataField: "CUSTOMER_NUM",
    alignment: "left",
    fixed: true,
    width: null,
    dataType: "string"
  },
  {
    id: 1,
    caption: "CUSTOMER",
    dataField: "CUSTOMER",
    alignment: "left",
    fixed: true,
    width: null,
    dataType: "string"
  },
 
  {
    id: 2,
    caption: "DATE",
    dataField: "date",
    alignment: "left",
    fixed: true,
    width: null,
    dataType: "date"


  },
  {
    id: 3,
    caption: "TIME",
    dataField: "TIME",
    alignment: "left",
    fixed: true,
    width: null,
    dataType: "datetime",
    // pickerType: "list",


  },

  {
    id: 4,
    caption: "DRIVER_CODE",
    dataField: "DRIVER_CODE",
    alignment: "left",
    fixed: true,
    width: null,
    dataType: "string"
    
  },
  
  {
    id: 5,
    caption: "REGISTRATION_NUM",
    dataField: "REGISTRATION_NUM",
    alignment: "left",
    fixed: true,
    width: null,
    dataType: "string"
    
  },
  
  
  {
    id: 6,
    caption: "CARD_TYPE",
    dataField: "CARD_TYPE",
    alignment: "left",
    fixed: true,
    width: null,
    dataType: "string"

  },
  
  {
    id: 7,
    caption: "CARD_NUM",
    dataField: "CARD_NUM",
    alignment: "left",
    fixed: true,
    width: null,
    dataType: "string"
    
  },

  {
    id: 8,
    caption: "CARD_NAME",
    dataField: "CARD_NAME",
    alignment: "left",
    fixed: true,
    width: null,
    dataType: "string"

  },
  {
    id: 9,
    caption: "RECEIPT_NUM",
    dataField: "RECEIPT_NUM",
    alignment: "left",
    fixed: true,
    width: null,
    dataType: "string"

  },
  {
    id: 10,
    caption: "PAST_MILEAGE",
    dataField: "PAST_MILEAGE",
    alignment: "left",
    fixed: true,
    width: null,
    dataType: "number"


  },
  {
    id: 11,
    caption: "CURRENT_MILEAGE",
    dataField: "CURRENT_MILEAGE",
    alignment: "left",
    fixed: true,
    width: null,
    dataType: "number"

  },
  {
    id: 12,
    caption: "OPERATION_TYPE",
    dataField: "OPERATION_TYPE",
    alignment: "left",
    fixed: true,
    width: null,
    dataType: "string"

  },
  {
    id: 13,
    caption: "PRODUCT_CODE",
    dataField: "PRODUCT_CODE",
    alignment: "left",
    fixed: true,
    width: null,
    dataType: "string"

  },
  {
    id: 14,
    caption: "PRODUCT",
    dataField: "PRODUCT",
    alignment: "left",
    fixed: true,
    width: null,
    dataType: "string"

  },
  {
    id: 15,
    caption: "UNIT_PRICE",
    dataField: "UNIT_PRICE",
    alignment: "left",
    fixed: true,
    width: 100,
    dataType: "number"

  },
  {
    id: 16,
    caption: "QUANTITY",
    dataField: "QUANTITY",
    alignment: "left",
    fixed: true,
    width: 100,
    dataType: "number"

  },
  {
    id: 17,
    caption: "AMOUNT",
    dataField: "AMOUNT",
    alignment: "left",
    fixed: true,
    width: 100,
    dataType: "number"
  },
  {
    id: 18,
    caption: "CURRENCY_NUM",
    dataField: "CURRENCY_NUM",
    alignment: "left",
    fixed: true,
    width: 100,
    dataType: "string"
  },
  {
    id: 19,
    caption: "CURRENCY",
    dataField: "CURRENCY",
    alignment: "left",
    fixed: true,
    width: 100,
    dataType: "string"
  },
  {
    id: 20,
    caption: "BALANCE",
    dataField: "BALANCE",
    alignment: "left",
    fixed: true,
    width: 100,
    dataType: "number"
  },
  {
    id: 21,
    caption: "STATION_NUM",
    dataField: "STATION_NUM",
    alignment: "left",
    fixed: true,
    width: 100,
    dataType: "string"
  },
  {
    id: 22,
    caption: "PLACE",
    dataField: "PLACE",
    alignment: "left",
    fixed: true,
    width: 100,
    dataType: "string"
  },
  {
    id: 23,
    caption: "INVOICE_DATE",
    dataField: "INVOICE_DATE",
    alignment: "left",
    fixed: true,
    width: 100,
    dataType: "date"
  },
  {
    id: 24,
    caption: "INVOICE_NUM",
    dataField: "INVOICE_NUM",
    alignment: "left",
    fixed: true,
    width: 100,
    dataType: "string"
  },
 

]
