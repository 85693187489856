import { forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import Setups_Template from '../.setups_templates/Setups_Template';
import useFetch from '../../../../hooks/useFetch';
import { hr_setups_branch_columns } from './data/HrSetupsBranchData';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { setValue, tier1DeleteOnclick, tier1SaveOnclick, tier1UpdateOnclick } from '../../../../features/forms/tier1/createSlice';
import { setGeneralValue } from '../../../../features/generalSlice';
import { ModalTemplate } from '../../../../templates/modal';
import { validationsData, saveModalData, updateModalData, api_url, deleteModalData, postError, putError } from '../../../accessories/component_infos';
import CustomLoader from '../../../accessories/custom-loader';
import { exprsTypes, modalTypes, searchTextTypes, statusTypes } from '../data/types/selectsTypes';
import { useBusyLoader } from '../../../../hooks/useBusyLoader';
import { useAccountInfo } from '../../../../hooks/useAccountInfo';
import { PostDataFunc } from '../../../../functions/post';
import dayjs from 'dayjs';
import { WarningAlert } from '../../../accessories/warningAlert';
import { UpdateDataFunc } from '../../../../functions/update';
import { useCrudFunc } from '../../../../functions/crud';


export const Hr_Setups_Branch = forwardRef(({a}:any, ref)=>{
    const [busyLoader,setBusyLoader]=useBusyLoader();
    const [employeeId,userId]=useAccountInfo();

    const [searchText,setSearchText] = useState<searchTextTypes>({temp:'',text:''})
    const[status,setStatus]= useState<statusTypes>({status:'',temp:''});

    const saveSuccessText = {message : 'Branch added Successfully',ok : 'Ok' , title : 'Success'} // successful save modal message
    const updateSuccessText = {message : 'Branch updated Successfully',ok : 'Ok' , title : 'Success'} // successful update modal message
    const deleteSuccessText = {message : 'Record deleted Successfully',ok : 'Ok' , title : 'Success'} // successful update modal message

    // store data expressions[unique] ...dependent on data coming in
    const idExpr:string = 'obrIdpk'
    const nameExpr:string = 'obrName'
    const shortnameExpr:string = 'obrShtName'
    const orderExpr: string = 'obrOrder';
    const activeExpr: string = 'obrActive';
    const remarksExpr: string = 'obrRmks';
    const inputsExprsData: any = {idExpr : idExpr,nameExpr : nameExpr,shortnameExpr : shortnameExpr,orderExpr : orderExpr,activeExpr : activeExpr,remarksExpr : remarksExpr }

    const storeExprs = () =>{
        const exprs = [{expr:'existsUrl',value:`HrBranch`},{expr: 'idExpr' ,value : idExpr},{expr: 'nameExpr' ,value : nameExpr},{expr: 'shortnameExpr' ,value : shortnameExpr}]
        //to particular redux store based on form type
        for (let i = 0; i < exprs.length; i++) {            
            dispatch(setValue({expr: exprs[i].expr ,value : exprs[i].value}));
        }

        // to general slice
        const generalExprs:exprsTypes[] = [{expr: 'inputExprs' ,value : inputsExprsData}]
        for (let i = 0; i < generalExprs.length; i++) {            
            dispatch(setGeneralValue({expr: generalExprs[i].expr ,value : generalExprs[i].value}));
        }
    }

    const dispatch = useDispatch();
    const [refresh, setRefresh] = useState<boolean>(false); //refresh data state for data creation or update

    //modal states
    //--general--
    const triggerSuccessModal = useSelector((state:RootState)=>state.tier1Add.successModalState); //trigger success modal
    const SuccessModalText = useSelector((state:RootState)=>state.tier1Add.successText); //success modal text

    //--for backend errors--
    const[errState,setErrState] = useState<boolean>(false);
    const[errTitle,seterrTitle] = useState<string>('');
    const[errMessage,seterrMessage] = useState<string>('');

    //--warning modal--
    const triggerwarningModal = useSelector((state:RootState)=>state.tier1Add.warningState); //trigger warning
    const warningInfos:any = validationsData; //warning type details
    const warningDataExpr = useSelector((state:RootState)=>state.tier1Add.warningDataExpr); //warning type selector

    //--save data modal--
    const saveData = saveModalData; //save modal details[data]
    const triggersaveModal = useSelector((state:RootState)=>state.tier1Add.saveDataOnclick); //trigger save modal
   
    //--Update data modal--
    const updateIndInfos = updateModalData; //update modal details[data]
    const triggerupdateModal:boolean = useSelector((state:RootState)=>state.tier1Add.updateDataOnclick); //trigger update question

    //--Update data modal--
    const deleteDataInfos = deleteModalData; //update modal details[data]
    const triggerdeleteModal:boolean = useSelector((state:RootState)=>state.tier1Add.deleteDataOnclick); //trigger update question

    // on page resize height responsiveness
    const [trigger,setTrigger] = useState<boolean>(false);
    useImperativeHandle(ref, () => ({
        test: () => {
            setTrigger(!trigger);
        }
    }));

    // get current values from  [slice dependent on form type]
    const name = useSelector((state:RootState)=>state.tier1Add.name);
    const shortname = useSelector((state:RootState)=>state.tier1Add.shortname);
    const order = useSelector((state:RootState)=>state.tier1Add.order);
    const active = useSelector((state:RootState)=>state.tier1Add.active);
    const remarks = useSelector((state:RootState)=>state.tier1Add.remarks);
    const selectedID = useSelector((state:RootState)=>state.general.SelectedFieldID);

    // alert(remarks)
   
    //post form Data
    //validate data
    const validateDataSave = () =>{
        dispatch(tier1SaveOnclick());
    }

    const [Posting, Updating, Patching] = useCrudFunc()

    // post
    const postData = async() =>{
        console.log("testing",{
                obrName : name,
                obrShtName : shortname,
                obrActive : active,
                obrRmks : remarks,
                gndCreatedBy : userId,
                obrOrder : order,
                obrEditedBy: userId,
        })
        setBusyLoader('Saving Record...');
        dispatch(setValue({expr: 'saveDataOnclick' ,value : false})) //disable modal
        try {
            const res = await Posting(`HrBranch/CreateBranch`,{
                obrName : name,
                obrShtName : shortname,
                obrActive : active,
                obrRmks : remarks,
                gndCreatedBy : userId,
                obrOrder : order,
                obrEditedBy: userId,
                obrCreationDate: dayjs(new Date()).format(),
                obrEditedDate: dayjs(new Date()).format()
            },`Created the HR-Org Branch with Name: ${name}, Short Name: ${shortname}, Order: ${order}, Active:${active}, Created By: ${userId}, Created Date: ${dayjs(new Date()).format()}`)
        
            setRefresh(!refresh);
            setBusyLoader('');
    
            const exprs:exprsTypes[] = [{expr: 'saveDataOnclick' ,value : false},{expr: 'successModalState' ,value : true},{expr: 'successText' ,value : saveSuccessText}];
            for (let i = 0; i < exprs.length; i++) {            
                dispatch(setValue({expr: exprs[i].expr ,value : exprs[i].value}));
            }
        } catch (error:any) {
            console.log(error);
            setBusyLoader('')
            
            //set states for db errors modal
            setErrState(true);
            seterrTitle(postError.title);
            seterrMessage(postError.message);
        }
    }

    //Update Form data
    //validate data
    const validateDataUpdate = () =>{
        dispatch(tier1UpdateOnclick());
    }
    // update
    const UpdateData = async()=>{
        setBusyLoader('Updating Record...');
        dispatch(setValue({expr: 'updateDataOnclick' ,value : true})) //disable modal
        try {
           
            const res = await Updating(`HrBranch/UpdateBranch?Id=${selectedID}`,{
                // obrIdpk: selectedID,
                obrName : name,
                obrShtName : shortname,
                obrOrder : order,
                obrRmks : remarks,
                obrActive : active,
                obrEditedBy : userId,
                obrEditedDate : dayjs(new Date()).format()
            },`Edited the HR-Org Branch from ${selectedID} to Name: ${name}, Short Name: ${shortname}, Order: ${order}, Active:${active}, Created By: ${userId}, Created Date: ${dayjs(new Date()).format()}`)
            
            setRefresh(!refresh);
            setBusyLoader('')
    
            const exprs:exprsTypes[] = [{expr: 'updateDataOnclick' ,value : false},{expr: 'successModalState' ,value : true},{expr: 'successText' ,value : updateSuccessText}];
            for (let i = 0; i < exprs.length; i++) {            
                dispatch(setValue({expr: exprs[i].expr ,value : exprs[i].value}));
            }
        } catch (error:any) {
            setBusyLoader('')
            //set states for db errors modal
            console.error(error)
            setErrState(true);
            seterrTitle(putError.title);
            seterrMessage(putError.message);
        }
    }

    //modals data
    const modals:modalTypes[] = [
        //warning
        {disableCancel : true , icon : 'warning' ,open : triggerwarningModal, okHandler : ()=> dispatch(setValue({expr: 'warningState' ,value : false})), cancelHandler : ()=> dispatch(setValue({expr: 'warningState' ,value : false})), message : warningInfos[warningDataExpr!].message, cancelText : warningInfos[warningDataExpr!].cancelText, okText : warningInfos[warningDataExpr!].okText, title : warningInfos[warningDataExpr!].title},
        //backend error
        {disableCancel : true , icon : 'warning' ,open : errState, okHandler : ()=> setErrState(false), cancelHandler : ()=> setErrState(false), message : errMessage, cancelText : '', okText : 'Ok', title : errTitle},
        //success
        {disableCancel : true, icon : 'success' ,open : triggerSuccessModal, okHandler : ()=> dispatch(setValue({expr: 'successModalState' ,value : false})), cancelHandler : ()=> dispatch(setValue({expr: 'successModalState' ,value : false})), message :SuccessModalText.message, okText : SuccessModalText.okText, title : SuccessModalText.title},
        //save
        {disableCancel : false , icon : 'question' ,open : triggersaveModal, okHandler : ()=> postData(), cancelHandler : ()=> dispatch(setValue({expr: 'saveDataOnclick' ,value : false})), message : saveData.message, cancelText : saveData.cancelText, okText : saveData.okText, title : saveData.title},
        //Update
        {disableCancel : false , icon : 'question' ,open : triggerupdateModal, okHandler : ()=> UpdateData(), cancelHandler : ()=> dispatch(setValue({expr: 'updateDataOnclick' ,value : false})), message : updateIndInfos.message, cancelText : updateIndInfos.cancelText, okText : updateIndInfos.okText, title : updateIndInfos.title},
    ]

    // data for datagrid
    const [data,error,loading] = useFetch(`HrBranch/GetAllBranches?searchText=${searchText.text}&isActive=${status.status}`,refresh,'obrIdpk');
    useEffect(()=>{
        if(data){
            storeExprs(); // run func to store expressions on store
            dispatch(setGeneralValue({expr : 'idExpr', value : idExpr})); // dispatch fetched data to reduxstore
            dispatch(setGeneralValue({expr: 'formData' ,value : data})); // dispatch fetched data to reduxstore
        }

        loading?setBusyLoader('.'):setBusyLoader('');
    },[loading,data])

    // form parameters
    const form_header = 'Branch Details';

    return (
        <>
            {error&&(
                <WarningAlert/>
            )}

            {/* modals */}
            {modals.map(({disableCancel,icon,okHandler,cancelHandler,open,cancelText,okText,message,title}:modalTypes,index)=>{
                return(
                    <div key = {index}>
                        <ModalTemplate  icon= {icon && icon} disableCancel = {disableCancel} cancelText = {cancelText && cancelText} open = {open} okHandler={okHandler} cancelHandler={cancelHandler} message= {message} okText= {okText} title={title}/>
                    </div>
                )
            })}

            {/* main form and table */}
            <Setups_Template 
            handleRefresh={()=> setRefresh(current =>!current)}
            searchTextChange={(newText)=> setSearchText({temp:newText , text: searchText.text})}
            checkOnChange={(newStatus)=> setStatus({status:status.status,temp:newStatus})}
            handleSearch={()=>{
                setSearchText({temp:searchText.temp , text: searchText.temp});
                setStatus({status:status.temp,temp:status.temp});
            }}
            updateData={validateDataUpdate} createData={validateDataSave} trigger = {trigger} datagridColumns={hr_setups_branch_columns} datagridData = {data} formHeader={ form_header}/>
        </>
    );
})