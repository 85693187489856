export const assetsSetups_servicestation_columns = [
  {
    id: 13,
    caption: "SERVICE PROVIDER IDPK",
    dataField: "sstIdpk",
    datatype: "number",
    width: 170,
    fixed: true,
    visible: false,
  },
  {
    id: 0,
    caption: "SERVICE PROVIDER",
    dataField: "spdName",
    datatype: "string",
    width: 170,
    fixed: true,
    visible: true,
  },
  {
    id: 1,
    caption: "SERVICE STATION",
    dataField: "sstName",
    datatype: "string",
    width: 150,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "SHORT NAME",
    dataField: "sstShtName",
    datatype: "string",
    width: 120,
    fixed: true,
    visible: true,
  },
  {
    id: 3,
    caption: "STATION NO",
    dataField: "sstStationNo",
    datatype: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 4,
    caption: "REGION",
    dataField: "rgnName",
    datatype: "string",
    width: 110,
    fixed: false,
    visible: true,
  },
  {
    id: 5,
    caption: "TOWN",
    dataField: "twnName",
    datatype: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 6,
    caption: "CONTACT",
    dataField: "sstMainContacts",
    datatype: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 7,
    caption: "TEL NO",
    dataField: "sstTelNos",
    datatype: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 8,
    caption: "EMAIL",
    dataField: "ssteMails",
    datatype: "string",
    width: 110,
    fixed: false,
    visible: true,
  },
  {
    id: 9,
    caption: "FUEL DUMP?",
    dataField: "sstFuelDump",
    datatype: "boolean",
    width: 110,
    fixed: false,
    visible: true,
  },
  {
    id: 10,
    caption: "ACTIVE?",
    dataField: "sstActive",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 11,
    caption: "ORDER?",
    dataField: "sstOrder",
    datatype: "number",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 12,
    caption: "REMARKS",
    dataField: "sstRmks",
    datatype: "string",
    width: 5000,
    fixed: false,
    visible: true,
  },
];
