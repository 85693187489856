import React, { forwardRef, useImperativeHandle, useRef, useState, } from "react";
import TabPanel, { Item } from 'devextreme-react/tab-panel';

import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CustomToolbar } from "../../../accessories/custom-toolbars/customtoolbar";
import { CIA_toolbar } from "../../../accessories/custom-toolbars/cia_toolbar";

import { Theme } from "./Strategic_themes";
import { Theme_Members } from "./Theme_members";
import { Theme_Result } from "./Theme_results";
import { Theme_Tab6 } from "./Theme_tab6";
import { Theme_Tab5 } from "./Theme_tab5";
import { Theme_Inclusion_Exclusion_Date } from "../../data/Performance_inputs";
import { Theme_Inclusion_Exclusion } from "./Inclusion_Exclusion";
import { strategic_themes_footer_datagrid_cols } from "../../data/datagrid_cols";
import { Footer_datagrid_template } from "../Strategic Objectives/data/footer_datagrid";
import useFetch from "../../../../hooks/useFetch";
import { navbar_height, toolbar_height } from "../../../accessories/component_infos";
import { StrategicTheme_Toolbar } from "../../../accessories/custom-toolbars/straThemes_toolbar";
import { useDispatch, useSelector } from "react-redux";
import { task_footer_table_selected, task_footer_update } from "../../../../features/Task&Todo/Task/TaskDetails";
import { RootState } from "../../../../app/store";
import Datagrid_template from "../../../../templates/Datagrid";



export const Theme_Tabs = forwardRef(({ a }: any, ref) => {
    const news_flash_height = useSelector((state: RootState) => state.general.newsflashHeight);
    const dispatch = useDispatch();
    const heights_out = navbar_height + news_flash_height + toolbar_height;

    const taskDetailsHeight = '309px'
    const [table_form_Height, settable_form_Height] = useState(window.innerHeight - (heights_out)); //height without navbar and toolbar and form

    const [tasks_update_state, setTask_update_state] = useState<boolean>(true)
    const [task_datagrid_height, set_task_datagrid_height] = useState(`calc(100% - ${taskDetailsHeight})`);
    const [searchParams, setSearchParams] = useSearchParams()

    const navigate = useNavigate()

    const current_page_route = '/tasks-todos';
    const view = searchParams.get('view');
    const taskDataGrid = 'task-data-grid-extended';

    const childRef = useRef<any>();
    const otherRef = useRef<any>();
    const [refresh, setRefresh] = useState(true);
    const [data, error, loading] = useFetch('api/PfmTheme/GetActiveVwPfmThemes', refresh);
    const [form_height, set_form_height] = useState<number>(336);

    useImperativeHandle(ref, () => ({
        test: () => {
            settableHeight(window.innerHeight - (heights_out + miscHeight - 4));
        }
    }));

    // switch button toggler
    const switch_toggle = () => {
        const view = searchParams.get('view');

        (view === null || view === formModeSearchParam) ?
            navigate({
                pathname: setupPageRoute,
                search: createSearchParams({
                    view: datagridOnlySearchParam,
                }).toString(),
            })
            :
            navigate({
                pathname: setupPageRoute,
                search: createSearchParams({
                    view: formModeSearchParam,
                }).toString(),
            });

    }
    //navigation
    const setupPageRoute = window.location.pathname; //get current route of page
    const datagridOnlySearchParam = 'table-only'  //search param in datagrid mode
    const formModeSearchParam = 'form-and-table' //search param in form mode mode
    //miscellaneous heights
    const miscHeight = 7;
    //Table Height
    const pageHeight = window.innerHeight - (navbar_height + miscHeight); //height without navbar
    const [tableHeight, settableHeight] = useState(window.innerHeight - heights_out + miscHeight - 9); //height without navbar and toolbar





    return (
        <div className="w-full h-full " >
            <div>
                <StrategicTheme_Toolbar toggler={() => { switch_toggle(); }} />
            </div>
            {(searchParams.get('view') !== datagridOnlySearchParam) && <div className="w-full h-50 flex justify-center items-center">

                <TabPanel onSelectedIndexChange={(index) => { index > 0 ? set_form_height(370) : set_form_height(336) }} className="w-full h-full">

                    <Item ref={otherRef} title={'Strategic Themes'} >


                        <Theme state_change={false} ref={childRef} />

                    </Item>

                    <Item ref={otherRef} title={'Theme Members'}>
                        <Theme_Members state_change={false} />
                    </Item>

                    <Item ref={otherRef} title={'Theme Results'}>
                        <Theme_Result state_change={false} />

                    </Item>

                    <Item ref={otherRef} title={'Inclusions/Exclusions'}>
                        <Theme_Inclusion_Exclusion state_change={false} />

                    </Item>

                    <Item ref={otherRef} title={'How Will We Measure Our Success'}>
                        <Theme_Tab5 state_change={false} />

                    </Item>

                    <Item ref={otherRef} title={'How It Will Move Us To Higher Level'}>
                        <Theme_Tab6 state_change={false} />

                    </Item>

                    <Item title={'Strategy Results Map'}>

                    </Item>

                </TabPanel>
            </div>}
            <div style={{ height: '' }} className='px-2  dark:bg-slate-900 dark:text-darkModeSkin-base'>
                <Datagrid_template
                    gridheight={
                        searchParams.get('view') === formModeSearchParam || searchParams.get('view') === (null) ?
                            window.innerHeight - (form_height + heights_out)
                            :
                            tableHeight
                    }
                    columns={strategic_themes_footer_datagrid_cols}
                    data={data}
                    rowDoubleClicked={(e: any) => {
                        childRef.current!.doubleClick()!;
                        dispatch(task_footer_update(true));
                    }}
                    onRowClick={(e: any) => {
                        childRef.current!.singleClick(e)!;
                        dispatch(task_footer_table_selected(e));
                    }}
                />


                {/* <Footer_datagrid_template  callBack={(e: any) => {   dispatch(task_footer_table_selected(e));
                     childRef.current!.singleClick(e)!;  }} doubleClick={(e: any) => { childRef.current!.doubleClick()!; 
                        dispatch(task_footer_update(true)); }} gridheight={searchParams.get('view') === formModeSearchParam || searchParams.get('view') === (null) ?
                        window.innerHeight - (form_height + heights_out  )
                        : 
                        tableHeight} columns={strategic_themes_footer_datagrid_cols} data={data} /> */}
            </div>
        </div>


    )
}

)

