
import { dblClick } from '@testing-library/user-event/dist/click';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import type { SelectProps } from 'antd';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { RootState } from '../../../../app/store';
import useFetch from '../../../../hooks/useFetch';
import { api_url, navbar_height } from '../../../accessories/component_infos';
import { CIA_toolbar } from '../../../accessories/custom-toolbars/cia_toolbar';
import { footer_datagrid_cia_cols } from './data/datagrid_cols';

import { Footer_datagrid_template } from './data/template/footer_datagrid';
import { CIA_Details_Form } from './widgets/CIA_Details_Form';
import { inputsTypes, searchCriteriaTypes, searchTextTypes, statusTypes } from '../../../human-resource/setups/data/types/selectsTypes';
import { TextareaTemplate } from '../../../../templates/textarea';
import { Form } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useBusyLoader } from '../../../../hooks/useBusyLoader';
import { task_footer_table_selected, task_form_disable, task_footer_update } from '../../../../features/Task&Todo/Task/TaskDetails';
import { setGeneralValue } from '../../../../features/generalSlice';
import Datagrid_template from '../../../../templates/Datagrid';
import { department_initiative_assignement_footer_datagrid_cols } from '../DIA/data/datagrid_cols';
import { HrSetupsCustomToolbar } from '../../../accessories/custom-toolbars/hrSetupsCustomtoolbar';
import { WarningAlert } from '../../../accessories/warningAlert';
import useResizeMode from '../../../../hooks/useResizeMode';





interface props {
    state_change: boolean;
}



export const CIA = ({state_change}:props)=>{
    const news_flash_height = useSelector((state:RootState)=>state.general.newsflashHeight);
    // const width = window.innerWidth/2
    // const fixedWidth = 'calc(50% - 8px)';

    const dispatch = useDispatch()
    const form_disable = useSelector((state:RootState) => state.general.formDisabled)


    const height = window.innerHeight - (navbar_height + news_flash_height + 30) ; //height without navbar
    const [formheight, set_form_height] = useState<number>(0);
    const [switch_change, setSwitch_change] = useState<boolean>(true)
    
    const formRef = useRef<any>(null);
    const [fullPage, setfullPage] = useState(window.innerHeight - (navbar_height + news_flash_height +30) );
    const pageHeight = window.innerHeight - (navbar_height + news_flash_height + formheight +35);
    // const fullPage = window.innerHeight - (navbar_height + 45) ; //height without navbar
    const borderTheme = useSelector((state:RootState)=>state.general.cssBorderClass)

    useResizeMode(pageHeight)

    // switch toggle
    const switch_toggle = ()=>{   
        if(switch_change){
            setSwitch_change(false);

        }else{
            setSwitch_change(true);

        }
    }

    // const [DBdata, DBdata_error, DBdata_loading] = useFetch('api/PfmCorporateInnitiativeAssignment/GetAllCorporateInitiativeAssignments')
    const [DBdata, setDBdata] = useState()
    const refresh = useSelector((state:RootState)=> state.general.refresh)

    const[refreshGrid,setRefreshGrid] = useState(false);

    if(DBdata){
        console.log(DBdata)
       
    }else{
      
    }

    const DBdataFunc = async() =>{
        const res = await axios.get(`${api_url}/api/PfmCorporateInnitiativeAssignment/GetAllCorporateInitiativeAssignments`,{
            headers: {
                'Content-Type': ['application/json', 'text/plain', 'charset=utf-8'],
            }
        })

        setDBdata(res.data.data)
        console.log(res.data.data)
    }

    const [remarks, setRemarks] = useState<string>('')

  

    useEffect(()=>{
        DBdataFunc()
        set_form_height((formRef.current?.clientHeight)+ 35)
        console.log(formRef)
    },[refresh])


    const [searchText,setSearchText]=useState<searchTextTypes>({temp:'',text:''});
    const [activeStatus,setActiveStatus]=useState<statusTypes>({temp:'',status:''});
    const [searchCriteria,setsearhCriteria]=useState<searchCriteriaTypes>({temp:0,index:0});
    const[refreshToolbar,setrefreshToolbar]=useState<boolean>(false);

    console.log({
        searchCriteria,
        searchText,
        activeStatus
    })


    const[busyLoader,setBusyLoader]=useBusyLoader();

    const [data, error, loading]= useFetch(`PfmCorporateInnitiativeAssignment/GetAllCorporateInitiativeAssignments?criteria=${searchCriteria.index}&search=${searchText.text}&active=${activeStatus.status}`,refreshGrid,'ciaIdpk')
    useEffect(()=>{
        loading?setBusyLoader('.'):setBusyLoader('');
    },[loading])

    const[selectedData,setSelectedData]=useState({});

    const searchCriteriaData=[
        {id:0,value:'Initiative'},
        {id:1,value:'Objective'},
        {id:2,value:'Theme'},
        {id:3,value:'Mission/Vision'},
    ]
    


    return (
        <div  className='h-full dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base '>
            {/* toggles between the taskForm and the footer grid table vertically */}
                {error && (
                    <WarningAlert/>
                )}
                <div>
                    <HrSetupsCustomToolbar 
                        toggler={setSwitch_change} 
                        searchTextOnchange={(searchValue)=>{
                            setSearchText({temp:searchValue, text:searchText.text})
                        }}
                        checkOnChange={(check)=>{
                            setActiveStatus({temp:check,status:activeStatus.status })
                        }}
                        handleRefresh={()=>{
                            setrefreshToolbar(current=> !current)
                            setRefreshGrid(!refreshGrid)
                            setSearchText({temp:'', text:''}) //search text
                            setActiveStatus({temp:'', status:''}) //active status
                            setsearhCriteria({temp:0, index:0})
                        }}
                        handleFind={()=>{
                            setSearchText({temp:searchText.temp,text:searchText.temp}) //search text
                            setActiveStatus({temp:activeStatus.temp,status:activeStatus.temp}) //active status
                            setsearhCriteria({temp:searchCriteria.temp,index:searchCriteria.temp})
                        }}
                        searchCriteriaOnchange={(newIndex,criteria)=>{
                            setsearhCriteria({temp:newIndex,index:searchCriteria.index})
                        }}
                        withDates={false}    
                        searchCriteria
                        searchCriteriaData={searchCriteriaData}
                        outerSelected={selectedData}
                        handleNew={()=>{
                            setSelectedData({})
                        }}
                        clearRemarks={()=>{
                            setRemarks('')
                        }}
                    />
                </div>
                
                {switch_change &&  <div ref={formRef}  className=''>
                    <p id={"form_header"} style={{borderColor:borderTheme}} className='pt-1 border-[1px] border-b-0 pl-4 w-full bg-slate-100 rounded-tl'>Cooperate Initiative Assignement Details</p>
                    <div style={{height:'155px',borderColor:borderTheme}}  className="w-full border-[1px] px-2 flex justify-between gap-x-4  border-b-4 mb-1 pb-1">
                        
                        {/* Internal toggler : expands the right taskUpdate horizontally */}
                        
                            <div style={{width:'905px',height:'150px',}} className='mb-1  rounded-b-sm' >
                                <CIA_Details_Form 
                                selectedRecordx={selectedData} 
                                setSelectedRecordx = {setSelectedData} 
                                remarks={remarks} 
                                refreshGrid={()=> setRefreshGrid(current=> !current)}
                                setRemarks ={setRemarks} 
                                />
                            </div>
                            
                    
                            {/* Double tap(click) feature to toggle to and fro of the right taskUpdate section */}
                            <div style={{width:'calc(100% - 905px)',height:'100%'}} className="mt-0.5  rounded-md items-center dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base " > 
                                <Form>
                                    <TextareaTemplate
                                        height={148}
                                        label={'Remarks'}
                                        useCallbackFunc
                                        defaultValue={remarks}
                                        readonly={form_disable}
                                        setCallbackValue={(e)=>{setRemarks(e)}}
                                    />
                                </Form>
                                       
                                 
                            </div>
        
                            
                        </div>
                </div> }
        

                {/* Footer gridtable  */}
            <div style={{height:''}} className='px-2  dark:bg-slate-900 dark:text-darkModeSkin-base'>
                <Datagrid_template
                    gridheight={switch_change ? pageHeight : fullPage}
                    columns={footer_datagrid_cia_cols}
                    data={data}
                    rowDoubleClicked={(e) => {
                        dispatch(task_footer_table_selected(e))
                        dispatch(task_form_disable(false));
                        dispatch(task_footer_update(true))
                        dispatch(setGeneralValue({ expr: "formDisabled", value: false }))
                        dispatch(setGeneralValue({ expr: "updateMode", value: true }))
                        setRemarks(e.ciaRmks)
                        setSelectedData(e)
                    }}
                    onRowClick={(e) => {
                        dispatch(task_footer_table_selected(e))
                        dispatch(task_form_disable(true));
                        dispatch(task_footer_update(false))
                        dispatch(setGeneralValue({ expr: "formDisabled", value: true }))
                        dispatch(setGeneralValue({ expr: "updateMode", value: false }))
                        setRemarks(e.ciaRmks)
                        setSelectedData(e)
                    }}
                />
              
                {/* <Footer_datagrid_template 
                    gridheight={switch_change ? pageHeight : fullPage} 
                    columns={department_initiative_assignement_footer_datagrid_cols} 
                    data={data} 
                    callBack={(e:any)=>{setSelectedData(e)}}
                /> */}
            </div>

        </div>

    )
}





// const reload = ()=>{
//     return(
//         <img className='w-2/6' src={save} />
//     )
// }