import React, { useState } from "react";
import { SearchButton } from "../../../accessories/buttons";
import { DateTemplate } from "../../../../templates/date";
import Datagrid_template from "../../../../templates/Datagrid";
import useResizeMode from "../../../../hooks/useResizeMode";
import {
  inputsTypes,
  searchTextTypes,
} from "../../../human-resource/setups/data/types/selectsTypes";
import { DatePicker, Form } from "antd";
import { loginDetailsCol } from "../data/datagridCol";
import dayjs from "dayjs";
import useFetch from "../../../../hooks/useFetch";
import { useFormPriviledge } from "../../../../hooks/useFormPriviledge";
import CustomLoader from "../../../accessories/custom-loader";

interface props {
  handleFind?: (value: any) => void;
}

export const LoginDetails = ({ handleFind }: props) => {
  const [initial, final] = useResizeMode(window.innerHeight);
  const gridheight = initial - 262;
  const [refreshstate, setRefreshstate] = useState<boolean>(false);

  // Search criteria states
  const genFormat = "YYYY-MM-DD";
  const today = dayjs().endOf("month").format(genFormat);
  const initialStart = dayjs("2019-02-01").format(genFormat);

  const [startDate, setStartDate] = useState<searchTextTypes>({
    text: initialStart,
    temp: initialStart,
  });
  const [endDate, setEndDate] = useState<searchTextTypes>({
    text: today,
    temp: today,
  });
  const [privtype, setprivtype] = useState<string>("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privtype);
  const [warningModal, setWarningModal] = useState<boolean>(false);
  const [warningModalData, setWarningModalData] = useState<any>({
    title: "",
    message: "",
  });

  // Fetch data
  const [logdetailsData, logdetailsError, logdetailsLoading] = useFetch(
    `AuditTrail/PopGdcLoginDetails?SrchStartDate=${startDate.temp}&SrchEndDate=${endDate.temp}`,
    refreshstate
  );

  const findAccessPriv = () => {
    setprivtype("read");
    if (readPriv === true) {
      handleFind &&
        handleFind({
          startDate: startDate.temp,
          endDate: endDate.temp,
        });
      setRefreshstate(!refreshstate);
    } else {
      setWarningModal(true);
      setWarningModalData({
        title: "Access Denied",
        message: "No Access Privileges",
      });
    }
  };

  const dates: inputsTypes[] = [
    { id: 0, label: "", stateName: "", defaultValue: "", style: "" },
    { id: 0, label: "", stateName: "", defaultValue: "", style: "" },
  ];

  const [dummy, setDummy] = useState("");

  return (

    <>
      {logdetailsLoading && <CustomLoader text="Fetching Data" />}
      <div className="w-full -full border-[1px] rounded-sm ">
        <Form
          className="w-full rounded py-1 px-2"
          name="basic"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          size="small"
        >
          {/* Top toolbar */}
          <section className="w-full flex space-x-2 justify-end items-center">
            <ul className=" flex flex-row items-center mt-0.5">
              <li className="px-0.5">
                <DatePicker
                  value={dayjs(startDate.temp)}
                  clearIcon={<span style={{ display: "none" }}></span>}
                  onChange={(date) =>
                    setStartDate({
                      temp: dayjs(date).format(genFormat),
                      text: startDate.text,
                    })
                  }
                  size="small"
                  placeholder="Start Date"
                  style={{ width: 125 }}
                  format={"DD MMM YYYY"}
                />
              </li>

              <li className="px-0.5">
                <DatePicker
                  value={dayjs(endDate.temp)}
                  clearIcon={<span style={{ display: "none" }}></span>}
                  onChange={(date) =>
                    setEndDate({
                      temp: dayjs(date).format(genFormat),
                      text: endDate.text,
                    })
                  }
                  size="small"
                  placeholder="End Date"
                  style={{ width: 125 }}
                  format={"DD MMM YYYY"}
                />
              </li>
            </ul>

            <SearchButton handleOnClick={findAccessPriv} buttonID="search" />
          </section>

          {/* Grid */}
          <section className="">
            <Datagrid_template
              gridheight={gridheight}
              columns={loginDetailsCol}
              data={logdetailsData}
            />
          </section>
        </Form>
      </div>
    </>
  );
};
