import { DatePicker, Checkbox, Form, Select, Image } from "antd";
import dayjs from "dayjs";
import { forwardRef, useState, useImperativeHandle, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../../app/store";
import { task_footer_table_selected, task_footer_update, task_form_disable } from "../../../../../features/Task&Todo/Task/TaskDetails";
import { setGeneralValue } from "../../../../../features/generalSlice";
import { useCrudFunc } from "../../../../../functions/crud";
import { GetDataFunc } from "../../../../../functions/get";
import useFetch from "../../../../../hooks/useFetch";
import { useFormPriviledge } from "../../../../../hooks/useFormPriviledge";
import { DropdownGrid_template } from "../../../../../templates/DropdownGrid";
import { CheckboxTemlate } from "../../../../../templates/checkbox";
import { InputsTemplate } from "../../../../../templates/input";
import { ModalTemplate } from "../../../../../templates/modal";
import { SelectsTemplate } from "../../../../../templates/select";
import { TextareaTemplate } from "../../../../../templates/textarea";
import { vehicle_capture_reg_no, dispensedBy_table } from "../../../../Assets-Manager/Transport/Entries/Fuel/BacklogFuelEntries/data/vehicle_reg_data";
import { UpdateButton, SaveButton, NewButton, Cancel } from "../../../../accessories/buttons";
import { saveModalData, updateModalData } from "../../../../accessories/component_infos";
import InputSelect_template from "../../../../../templates/InputSelect";
import SetupsDatalistTemplat from "../../../../human-resource/setups/data/setups_datalist_template";
import reset from "../../../../../assets/refresh.png";
import { bearerToken } from "../../../../data";
import hardFetch from "../../../../../hooks/hardFetch";
interface prop {
  refreshState?: () => void;
  setOuterSelect?: any;
  setSelected_Record?: (val: any) => void;
  a?: any;
  refreshGrid:()=>void
  clearSelected:()=>void
  selectedField:any
}

export const StationDetailsForm = forwardRef(
  ({ refreshState, clearSelected,refreshGrid,selectedField }: prop, ref) => {
   
    const dateFormat = " DD MMM YYYY HH: mm:ss";
    const [ShowImages, setShowImages] = useState<any>(false);

    const [formData, setFormData] = useState<any>({
      selectedId: 0,
      name: "",
      shortName:"",
      requestDate: "",
      code: { name: "", id: "" },
      area: { name: "", id: "" },
      location: { name: "", id: "" },
      region: { name: "", id: "" },
      town: { name: "", id: "" },
      director: { name: "", id: "" },
      manager: { name: "", id: "" },
      supervisor: { name: "", id: "" },
      contactPerson: { name: "", id: "" },
      localSyncServer: { name: "", id: "" },
      localSyncServerName: { name: "", id: "" },
      telephoneNumber: "",
      intercom: "",
      logsEvenRowColor: "",
      flagsLogColor: "",
      latitude: "",
      longitude: "",
      substations: false,
      ncc: false,
      ownedByUs: false,
      masterStation: false,
      operational: false,
      manned: true,
      mannedByUs: false,
      hasComputer: false,
      hasNetwork: false,
      showInElogBook: false,
      elogging: false,
      active: false,
      generationStation: false,
      statusGood: false,
      syncLocalLogs: false,
      remarks: "",
      disabled:true,
      // refresh: false,
    });

  

    const [fromModal, setFromModal] = useState(true);    

    // hardFetch(`StationDetails/regions`, "powerapp")
    const [regions, regions_error,  regions_loading] =
    useFetch(`StationDetails/regions`,true,'id',false,"powerapp", fromModal);

    const [names, names_error,  names_loading] =
    useFetch(`StationDetails/names`,true,'id',false,"powerapp", fromModal);
    const [shortnames, shortnames_error,  shortnames_loading] =
    useFetch(`StationDetails/shortnames`,true,'id',false,"powerapp", fromModal);
    const [code, code_error,  code_loading] =
    useFetch(`StationDetails/code`,true,'id',false,"powerapp", fromModal);

    
    const [area, area_error,  area_loading] =
    useFetch(`StationDetails/areas`,true,'id',false,"powerapp", fromModal);
   
    const [town, town_error,  town_loading] =
    useFetch(`StationDetails/towns/${formData.region.id}`,true,'id',false,"powerapp", fromModal);
    const [location, location_error,  location_loading] =
    useFetch(`StationDetails/locations/${formData.area.id}`,true,'id',false,"powerapp", fromModal);
   
    const [directors, directors_error,  directors_loading] =
    useFetch(`StationDetails/directors`,true,'id',false,"powerapp", fromModal);
    const [supervisors, supervisors_error,  supervisors_loading] =
    useFetch(`StationDetails/supervisors`,true,'id',false,"powerapp", fromModal);
    const [managers, managers_error,  managers_loading] =
    useFetch(`StationDetails/managers`,true,'id',false,"powerapp", fromModal);
    const [localSyncServers, localSyncServers_error,  localSyncServers_loading] =
    useFetch(`StationDetails/server_ips`,true,'id',false,"powerapp", fromModal);
    const [localSyncServerNames, localSyncServerNames_error,  localSyncServerNames_loading] =
    useFetch(`StationDetails/server_names`,true,'id',false,"powerapp", fromModal);
    const [contactPerson, contactPerson_error,  contactPerson_loading] =
    useFetch(`StationDetails/contact_persons`,true,'id',false,"powerapp", fromModal);
    const [evenRowColors, evenRowColors_error,  evenRowColors_loading] =
    useFetch(`StationDetails/even_row_colors`,true,'id',false,"powerapp", fromModal);
    const [flaggedLogColors, flaggedLogColors_error,  flaggedLogColors_loading] =
    useFetch(`StationDetails/flagged_log_colors`,true,'id',false,"powerapp", fromModal);
    
    
    // const [Alldata, Allerror, Allloading] = useFetch(
    //       `PmActivitiesCaptures/GetAllPmSchedules?SeacrchText=hi`
    //     );
    const [posting, updating] = useCrudFunc("powerapp");

    // const [regNoData] = useFetch(
    //   "AsmTmsCaptureOldFuelRecords/GetAllVehicleRegistrationNumbers?VasStartDate=2020-01-01"
    // );
    // const [serviceProviderData, serviceProviderError, serviceProviderLoading] =
    //   useFetch("AsmTmsCaptureOldFuelRecords/GetAllServiceProviders");
    // const [serviceStationsData, setServiceStations] = useState<any>([]);
    // // products data only work with provider data of id 1.
    // const [productsData] = useFetch(
    //   `AsmTmsCaptureOldFuelRecords/GetAllProducts?SpdIDpk=${formData.serviceProvider.id}`
    // );
    // const [loadImg] = useFetch(
    //   `AssetTamVehicleDetail/GetImagesAssetTamVehicleDetails?vdtIDpk=${formData.selectedId}&vimActive=true`
    // );
    // const [recipientsData] = useFetch(
    //   "AsmTmsCaptureOldFuelRecords/GetAllRecipients"
    // );
    // const [authorisersData] = useFetch(
    //   "AsmTmsCaptureOldFuelRecords/GetAllAuthorisers"
    // );
    // const [dispensorsData] = useFetch(
    //   "AsmTmsCaptureOldFuelRecords/GetAllDispensors"
    // );

    // const [unitPrice] = useFetch(
    //   `AsmTmsCaptureOldFuelRecords/GetUnitPrice?SpdIDpk=${formData.serviceProvider.id}&PprIDpK=${formData.product.id}`
    // );
    // let Unit_Price_Value = unitPrice?.map(({ pprUnitPrice, funIDpk }: any) => ({
    //   pprUnitPrice,
    //   funIDpk,
    // }));

    // const [departmentData] = useFetch(
    //   "AsmTmsCaptureOldFuelRecords/GetAllDepartments"
    // );
    // const [sectionData, setSectionData] = useState<any>([]);
    // const [costCenterData, setCostCenterData] = useState<any>([]);
    // const [uomData] = useFetch(
    //   "AsmTmsCaptureOldFuelRecords/GetAllFuelUnitsOfMeasure"
    // );

    useImperativeHandle(ref, () => ({
      singleClick: (e: any) => {
        // console.log(e)
        if (e !== undefined) {
          // dispatch(setGeneralValue({ expr: "selectedFieldData", value: e }));
          // dispatch(task_footer_table_selected(e));

          setFormData({
            ...formData,
            selectedId: e.stnIDpk,
      name: e.stnName,
      shortName:e.stnShtName,
      code: { name: e.stnCode, id: "" },
      area: { name: e.areName, id: e.areIDpk },
      location: { name: e.locName, id: e.locIDpk },
      region: { name: e.rgnName, id: e.rgnIDpk },
      town: { name: e.stnTown, id: "" },
      director: { name: e.stnDirector, id: "" },
      manager: { name: e.stnManager, id: "" },
      supervisor: { name: e.stnSupervisor, id: "" },
      contactPerson: { name: "", id: "" },
      localSyncServer: { name: e. stnLocalSyncServerIP , id: "" },
      localSyncServerName: { name: e.stnLocalSyncServerName, id: "" },
      telephoneNumber: e.stnTelNo,
      intercom: e.stnContact,
      logsEvenRowColor: e.stnEvenRowsColour,
      flagsLogColor: e.stnFlaggedRowsColour,
      latitude: e.stnLatitude,
      longitude: e.stnLongitude,
      substations: e.stnSubstation,
      ncc: e.stnNationalControlCenter,
      ownedByUs: e.stnOwnedByUs,
      masterStation: e.stnMasterStation,
      operational: e.stnOperational,
      manned: e.stnManned,
      mannedByUs: e.stnMannedByUs,
      hasComputer: e.stnHasComputer,
      hasNetwork: e.stnHasNetwork,
      showInElogBook: e.stnShowInElogBook,
      elogging: e.stnElogging,
      active: e.stnActive,
      generationStation: e.stnGenStation,
      statusGood: e.stnStatusGood,
      syncLocalLogs: e.stnSyncLocalLogs,
      remarks: e.stnRmks,
            updateMode: false,
            disabled: true,
          });
        }
      },
      doubleClick: () => {
        // editAccessPriv();
        setFormData({
          ...formData,
          updateMode: true,
          disabled:false
        })
      },
    }));

    // // const fetchData = async (route: string, type: string) => {
    // //   try {
    // //     // get data
    // //     const res = await GetDataFunc(`${route}`);
    // //     type === "serviceStations"
    // //       ? setServiceStations(res.data.reverse().slice(0, 100))
    // //       : type === "sections"
    // //       ? setSectionData(res.data.reverse().slice(0, 100))
    // //       : setCostCenterData(res.data.reverse().slice(0, 100));
    // //   } catch (error: any) {
    // //   } finally {
    // //   }
    // // };

    const [modalInfo, setModalInfo] = useState<any>({
      icon: "question",
      data: saveModalData,
      disableCancel: false,
      openModal: false,
    });

    const clearFields = () => {
      setFormData({
        ...formData,
        selectedId: 0,
        name: "",
        shortName:"",
        requestDate: "",
        code: { name: "", id: "" },
        area: { name: "", id: "" },
        location: { name: "", id: "" },
        region: { name: "", id: "" },
        town: { name: "", id: "" },
        director: { name: "", id: "" },
        manager: { name: "", id: "" },
        supervisor: { name: "", id: "" },
        contactPerson: { name: "", id: "" },
        localSyncServer: { name: "", id: "" },
        localSyncServerName: { name: "", id: "" },
        telephoneNumber: "",
        intercom: "",
        logsEvenRowColor: "",
        flagsLogColor: "",
        latitude: "",
        longitude: "",
        substations: false,
        ncc: false,
        ownedByUs: false,
        masterStation: false,
        operational: false,
        manned: true,
        mannedByUs: false,
        hasComputer: false,
        hasNetwork: false,
        showInElogBook: false,
        elogging: false,
        active: false,
        generationStation: false,
        statusGood: false,
        syncLocalLogs: false,
        remarks: "",
        disabled:false
        // refresh: false,
      });
    };

    const updateState = (stateName: string, value: any) => {
      setFormData((currentState: any) => ({
        ...currentState,
        [stateName]: value,
      }));
    };

   
    // const disablex = useSelector(
    //   (state: RootState) => state.general.formDisabled
    // );
    // const updateMode = useSelector(
    //   (state: RootState) => state.general.updateMode
    // );
    const borderTheme = useSelector(
      (state: RootState) => state.general.cssBorderClass
    );
    const dispatch = useDispatch();

    const validationForms = (method: "post" | "update") => {
      if (formData.name === "") {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please enter name",
          },
          disableCancel: true,
          openModal: true,
        });
      }
  
       else if (formData.shortName === "") {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please enter short name",
          },
          disableCancel: true,
          openModal: true,
        });
      } else if (formData.code.name === "") {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please select Code ",
          },
          disableCancel: true,
          openModal: true,
        });
      } else if (formData.area.name === "") {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please select Area",
          },
          disableCancel: true,
          openModal: true,
        });
      } else if (formData.location.name === "") {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please select Location",
          },
          disableCancel: true,
          openModal: true,
        });
      } else if (formData.region.name === "") {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please select Region",
          },
          disableCancel: true,
          openModal: true,
        });
      } else if (formData.town.name === "") {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please select Town",
          },
          disableCancel: true,
          openModal: true,
        });
      } 
      else if (formData.director.name === "") {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please select Director",
          },
          disableCancel: true,
          openModal: true,
        });
      }
      else if (formData.manager.name === "") {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please select Manager",
          },
          disableCancel: true,
          openModal: true,
        });
      }
      else if (formData.supervisor.name === "") {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please select Supervisor",
          },
          disableCancel: true,
          openModal: true,
        });
      }
      else if (formData.contactPerson.name === "") {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please select Contact Person",
          },
          disableCancel: true,
          openModal: true,
        });
      }
      else if (formData.localSyncServer.name === "") {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please select Local Sync Server",
          },
          disableCancel: true,
          openModal: true,
        });
      }
      else if (formData.telephoneNumber === "") {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please enter Telephone Number",
          },
          disableCancel: true,
          openModal: true,
        });
      }
      else if (formData.intercom === "") {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please enter Intercom",
          },
          disableCancel: true,
          openModal: true,
        });
      }
      else if (formData.logsEvenRowColor === "") {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please select Logs Even Row Color",
          },
          disableCancel: true,
          openModal: true,
        });
      }
      else if (formData.flagsLogColor === "") {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please select Flagged Logs Color",
          },
          disableCancel: true,
          openModal: true,
        });
      }

      else {
        if (method === "post") {
          setModalInfo({
            icon: "question",
            data: saveModalData,
            disableCancel: false,
            openModal: true,
          });
        } else {
          setModalInfo({
            icon: "question",
            data: updateModalData,
            disableCancel: false,
            openModal: true,
          });
        }
      }
    };

    

    const saveData = async () => {
      await posting(
        `StationDetails/create`,
        {
          "stnName": formData.name,
          "stnShtName": formData.shortName,
          "stnCode": formData.code.name,
          "stnContact": formData.intercom,
          "stnTelNo": formData.telephoneNumber,
          "stnPlc": "string",
          "stnEmail": "user@example.com",
          "stnLocationIDfk": formData.location.id,
          "stnLatitude": formData.longitude,
          "stnLongitude":   formData.longitude,
          "stnLocalSyncServerIP": formData.localSyncServer.name,
          "stnLocalSyncServerName": formData.localSyncServerName.name,
          "stnRegionIDfk": formData.region.id,
          "stnTown": formData.town.name,
          "stnDirector": formData.director.name,
          "stnManager": formData.manager.name,
          "stnSupervisor": formData.supervisor.name,
          "stnSubstation": formData.substations,
          "stnGenStation": formData.generationStation,
          "stnMasterStation": formData.masterStation,
          "stnNationalControlCenter": formData.ncc,
          "stnSyncLocalLogs": formData.syncLocalLogs,
          "stnOperational": formData.operational,
          "stnManned": formData.manned,
          "stnMannedByUs": formData.mannedByUs,
          "stnOwnedByUs": formData.ownedByUs,
          "stnHasComputer": formData.hasComputer,
          "stnHasNetwork": formData.hasNetwork,
          "stnShowInElogBook": formData.showInElogBook,
          "stnElogging": formData.elogging,
          "stnStatusGood": formData.statusGood,
          "stnEvenRowsColour": formData.logsEvenRowColor,
          "stnFlaggedRowsColour": formData.flagsLogColor,
          "stnActive": formData.active,
          "stnRmks": formData.remarks,
          "formCode": "string",
          "formAction": "string"
        },
        `Created Stations successfully`
      )
        .then((response) => {
          setModalInfo({
            icon: "success",
            data: {
              title: "Success",
              message: "Record saved successfully",
              okText: "Ok",
              cancelText: "Cancel",
            },
            disableCancel: true,
            openModal: true,
          });
          refreshState && refreshState();
        })
        .catch((err) => {
          setModalInfo({
            icon: "error",
            data: {
              title: "Unexpected error!",
              message:
                "An error occurred whilst executing the action. Please contact your Systems Administrator for assistance",
              okText: "Ok",
              cancelText: "Cancel",
            },
            disableCancel: true,
            openModal: true,
          });
        });
    };
    const updateData = async () => {
      await updating(
        `StationDetails/update/${formData.selectedId}`,
        {
         "stnName": formData.name,
          "stnShtName": formData.shortName,
          "stnCode": formData.code.name,
          "stnContact": formData.intercom,
          "stnTelNo": formData.telephoneNumber,
          "stnPlc": "string",
          "stnEmail": "user@example.com",
          "stnLocationIDfk": formData.location.id,
          "stnLatitude": formData.longitude,
          "stnLongitude":   formData.longitude,
          "stnLocalSyncServerIP": formData.localSyncServer.name,
          "stnLocalSyncServerName": formData.localSyncServerName.name,
          "stnRegionIDfk": formData.region.id,
          "stnTown": formData.town.name,
          "stnDirector": formData.director.name,
          "stnManager": formData.manager.name,
          "stnSupervisor": formData.supervisor.name,
          "stnSubstation": formData.substations,
          "stnGenStation": formData.generationStation,
          "stnMasterStation": formData.masterStation,
          "stnNationalControlCenter": formData.ncc,
          "stnSyncLocalLogs": formData.syncLocalLogs,
          "stnOperational": formData.operational,
          "stnManned": formData.manned,
          "stnMannedByUs": formData.mannedByUs,
          "stnOwnedByUs": formData.ownedByUs,
          "stnHasComputer": formData.hasComputer,
          "stnHasNetwork": formData.hasNetwork,
          "stnShowInElogBook": formData.showInElogBook,
          "stnElogging": formData.elogging,
          "stnStatusGood": formData.statusGood,
          "stnEvenRowsColour": formData.logsEvenRowColor,
          "stnFlaggedRowsColour": formData.flagsLogColor,
          "stnActive": formData.active,
          "stnRmks": formData.remarks,
          "formCode": "string",
          "formAction": "string"
        },
        `Updated Stations successfully`
      )
        .then((response) => {
          setModalInfo({
            icon: "success",
            data: {
              title: "Success",
              message: "Record updated successfully",
              okText: "Ok",
              cancelText: "Cancel",
            },
            disableCancel: true,
            openModal: true,
          });
          refreshState && refreshState();
        })
        .catch((err) => {
          setModalInfo({
            icon: "error",
            data: {
              title: "Unexpected error!",
              message:
                "An error occurred whilst executing the action. Please contact your Systems Administrator for assistance",
              okText: "Ok",
              cancelText: "Cancel",
            },
            disableCancel: true,
            openModal: true,
          });
        });
    };

   

    // const handleInputChange = (field: String, value: any) => {
    //   // Update the common value and propagate it to other fields
    //   setFormData({
    //     ...formData,

    //     quantityAuthorized: value,
    //     quantityRequested: value,
    //     quantityDispensed: value,
    //   });
    // };

    const [privtype, setPrivType] = useState<string>("save");
    // const [savePriv] = useFormPriviledge(privtype);

    const newAccesPriv = () => {
      setPrivType("save");
      
      // if (savePriv === true) {
        // setFormData({ ...formData, disabled: false });
        dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
        dispatch(setGeneralValue({ expr: "updateMode", value: false }));
        clearFields();
      // } else {
      //   setModalInfo({
      //     icon: "warning",
      //     data: {
      //       message: `No Access Privilege`,
      //       title: "Access Denied!",
      //       okText: "Ok",
      //       cancelText: "Cancel",
      //     },
      //     disableCancel: true,
      //     openModal: true,
      //   });
      // }
    };

    const updateAccessPriv = () => {
      setPrivType("post");
      // if (savePriv === true) {
      //   validationForms("update");
      // } else {
      //   setModalInfo({
      //     icon: "warning",
      //     data: {
      //       message: `No Access Privilege`,
      //       title: "Access Denied!",
      //       okText: "Ok",
      //       cancelText: "Cancel",
      //     },
      //     disableCancel: true,
      //     openModal: true,
      //   });
      // }
    };

    const saveAccessPriv = () => {
      setPrivType("save");

      // if (savePriv === true) {
      //   validationForms("post");
      // } else {
      //   setModalInfo({
      //     icon: "warning",
      //     data: {
      //       message: `No Access Privilege`,
      //       title: "Access Denied!",
      //       okText: "Ok",
      //       cancelText: "Cancel",
      //     },
      //     disableCancel: true,
      //     openModal: true,
      //   });
      // }
    };

    // const editAccessPriv = () => {
    //   setPrivType("update");

    //   if (savePriv === true) {
    //     dispatch(task_footer_update(true));
    //     setFormData({
    //       ...formData,
    //       updateMode: true,
    //       disabled: false,
    //     });
    //   } else {
    //     setModalInfo({
    //       icon: "warning",
    //       data: {
    //         message: `No Access Privilege`,
    //         title: "Access Denied!",
    //         okText: "Ok",
    //         cancelText: "Cancel",
    //       },
    //       disableCancel: true,
    //       openModal: true,
    //     });
    //   }
    // };

    // useEffect(() => {
    //   if (!disablex) {
    //     dispatch(task_footer_table_selected({}));

    //     dispatch(task_footer_update(false));
    //     setFormData({ ...formData, disabled: false });
    //     clearFields();
    //     dispatch(setGeneralValue({ expr: "formDisabled", value: false })); //disable form
    //     dispatch(setGeneralValue({ expr: "updateMode", value: false })); //disable update mode
    //   } else {
    //     dispatch(task_form_disable(true));
    //     dispatch(task_footer_update(false));
    //     setFormData({
    //       ...formData,
    //       updateMode: false,
    //       disabled: true,
    //     });
    //   }
    //   if (updateMode) {
    //     dispatch(task_footer_update(true));
    //     setFormData({
    //       ...formData,
    //       updateMode: true,
    //       disabled: false,
    //     });
    //   }
    // }, [disablex, updateMode]);

    return (
      <div
        style={{ width: "100%", height: "100%", borderColor: borderTheme }}
        className="  border-[1px] border-t-0 dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base  pr-2"
      >
        <ModalTemplate
          disableCancel={modalInfo.disableCancel}
          icon={modalInfo.icon}
          title={modalInfo.data.title}
          okText={modalInfo.data.okText}
          message={modalInfo.data.message}
          cancelText={modalInfo.data.cancelText}
          open={modalInfo.openModal}
          okHandler={() => {
            modalInfo.data.title === "Update Record?"
              ? updateData()
              : modalInfo.data.title === "Save Record?"
              ? saveData()
              : setModalInfo({ ...modalInfo, openModal: false });
          }}
          cancelHandler={() => {
            setModalInfo({ ...modalInfo, openModal: false });
          }}
        />
        <div
          style={{ maxHeight: "160px", minHeight: "160px", height: "160px" }}
          className="items-start justify-start "
        >
          <Form
            className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 pl-2 "
            name="basic"
            labelCol={{ span: 7 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            size="small"
            layout="horizontal"
          >
            <div style={{ width: "1300px" }} className=" flex ">
              <div style={{ width: "calc(100% - 800px)" }} className="">
                {" "}
                <SetupsDatalistTemplat
                label="Name"
                selectedData={formData.name}
                setCallbackValue={(e) => {
                  updateState("name", e);
                }}
                useCallbackFunc={true}
                options={names}
                       
                />

                <SetupsDatalistTemplat
                label="Short Name"
                selectedData={formData.shortName}
                setCallbackValue={(e) => {
                  updateState("shortName", e);
                }}
                useCallbackFunc={true}
               
                // useCallbackFunc
                       
                />

                <SetupsDatalistTemplat
                label="Code"
                selectedData={formData.code.name}
                setCallbackValue={(e) => {
                  updateState("code", { name: e, id: e });
                }}
                useCallbackFunc={true}
                       
                />
                
               
                <SelectsTemplate
                  label="Area"
                  placeHolder={formData.area.name}
                  selectedValue={(e) => {
                    console.log(e);
                    updateState("area", { name: e.areName, id: e.areIDpk });
                  }}
                  handleRefresh={
                    ()=>{
                      updateState("area", { name: "", id: "" });
                    }
                  }
                  useCallFunc={true}
                  options={area}
                  dataexp="areName"
                  idexpr="areIDpk"
                />

                <SelectsTemplate
                  label="Location"
                  placeHolder={formData.location.name}
                  selectedValue={(e) => {
                    updateState("location", { name: e.locName, id: e.locIDpk });
                  }}
                  handleRefresh={
                    ()=>{
                      updateState("location", { name: "", id: "" });
                    }
                  }
                  useCallFunc={true}
                  options={location}
                    dataexp="locName"
                  idexpr="locIDpk"
              
                />

                <SelectsTemplate
                  label="Region"
                  placeHolder={formData.region.name}
                  selectedValue={(e) => {
                    updateState("region", { name: e.rgnName, id: e.rgnIDpk });
                  }}
                  handleRefresh={
                    ()=>{
                      updateState("region", { name: "", id: "" });
                    }
                  }
                  useCallFunc={true}
                  options={regions}
                  dataexp="rgnName"
                  idexpr="rgnIDpk"
                />
                <SelectsTemplate
                  label="Town"
                  placeHolder={formData.town.name}
                  selectedValue={(e) => {
                    updateState("town", { name: e.twnName, id: e.twnIDpk });
                  }}
                  handleRefresh={
                    ()=>{
                      updateState("town", { name: "", id: "" });
                    }
                  }
                  useCallFunc={true}
                  options={town}
                  dataexp="twnName"
                  idexpr="twnIDpk"
                />
                  {/* <SetupsDatalistTemplat
                label="Town"
                selectedData={formData.town.name}
                setCallbackValue={(e) => {
                  updateState("town", { name: e, id: e });
                }}
                useCallbackFunc={true}
                style={{marginTop: "1px", marginBottom: "1px"}} 
                /> */}

                
               

                {/* <SelectsTemplate
                  label="Town"
                  placeHolder={formData.town.name}
                  selectedValue={(e) => {
                    updateState("town", { name: e.name, id: e.id });
                  }}
                  useCallFunc={true}
                  options={town}
                  dataexp="name"
                  idexpr="id"
                  handleRefresh={
                    ()=>{
                      updateState("town", { name: "", id: "" });
                    }
                  }
                /> */}

<SelectsTemplate
                  label="Director"
                  placeHolder={formData.director.name}
                  selectedValue={(e) => {
                    updateState("director", { name: e.empName14, id: e.id });
                  }}
                  useCallFunc={true}
                  options={directors}
                  dataexp="empName14"
                  idexpr="id"
              
                />

                <SelectsTemplate
                  label="Manager"
                  placeHolder={formData.manager.name}
                  selectedValue={(e) => {
                    updateState("manager", { name: e.empName14, id: e.id });
                  }}
                  useCallFunc={true}
                  options={managers}
                  dataexp="empName14"
                  idexpr="id"
              
                />

                <SelectsTemplate
                  label="Supervisor"
                  placeHolder={formData.supervisor.name}
                  selectedValue={(e) => {
                    updateState("supervisor", { name: e.empName14, id: e.id });
                  }}
                  useCallFunc={true}
                  options={supervisors}
                  dataexp="empName14"
                  idexpr="id"
                  handleRefresh={
                    ()=>{
                      updateState("supervisor", { name: "", id: "" });
                    }
                  }
              
                />

                <SelectsTemplate
                  label="Contact Person"
                  placeHolder={formData.contactPerson.name}
                  selectedValue={(e) => {
                    updateState("contactPerson", { name: e.empName14, id: e.id });
                  }}
                  useCallFunc={true}
                  options={contactPerson}
                  dataexp="empName14"
                  idexpr="id"
                  handleRefresh={
                    ()=>{
                      updateState("contactPerson", { name: "", id: "" });
                    }
                  }
                />

                <SelectsTemplate
                  label="Local Sync Server"
                  placeHolder={formData.localSyncServer.name}
                  selectedValue={(e) => {
                    updateState("localSyncServer", { name: e.stnLocalSyncServerIP, id: e.id });
                  }}
                  useCallFunc={true}
                  options={localSyncServers}
                  dataexp="stnLocalSyncServerIP"
                  idexpr="id"
                  handleRefresh={
                    ()=>{
                      updateState("localSyncServer", { name: "", id: "" });
                    }
                  }
              
                />

                <SelectsTemplate
                  label="Local Sync Server Name"
                  placeHolder={formData.localSyncServerName.name}
                  selectedValue={(e) => {
                    updateState("localSyncServerName", { name: e.stnLocalSyncServerName, id: e.id });
                  }}
                  useCallFunc={true}
                  options={localSyncServerNames}
                  dataexp="stnLocalSyncServerName"
                  idexpr="id"
                  handleRefresh={
                    ()=>{
                      updateState("localSyncServerName", { name: "", id: "" });
                    }
                  }
                  
                />

                <div className=" flex justify-end mt-0.5 mr-[-5px]">
                    <ul className="flex justify-end pr-1  ">
                    {formData.updateMode ? (
                        <UpdateButton
                        disableButton={formData.disabled}
                        useCallbackFunc={true}
                        handleUpdate={() => {
                          validationForms('update');
                        }}
                        
                        />
                    ) : (
                        <SaveButton
                        disableButton={formData.disabled}
                        useCallbackFunc={true}
                        handleSave={() => {
                            //  saveData()
                            validationForms('post');
                            // saveAccessPriv();
                        }}
                        />
                    )}
                    {formData.disabled ? (
                        <NewButton
                        useCallbackFunc={true}
                        new_button_toggler={() => {
                            newAccesPriv();
                        }}
                        />
                    ) : (
                        <Cancel
                        useCallbackFunc
                        cancel_button_toggler={() => {
                            setFormData({
                            ...formData,
                          
                            disabled: true,
                            });
                            // dispatch(task_form_disable(true));
                            dispatch(
                            setGeneralValue({ expr: "formDisabled", value: true })
                            );
                            // dispatch(task_footer_update(false));
                        }}
                        />
                    )}
                    </ul>
                </div>
              </div>

              <div className="  ml-[1px]">
                {/* Right inputs & image */}
                <div className=" flex ">
                    <div style={{ width: "550px" }} className="">
                        <SetupsDatalistTemplat
                        selectedData={formData.telephoneNumber}
                        setCallbackValue={(e) => {
                          updateState("telephoneNumber", e);
                        }}
                        useCallbackFunc
                        label="Telephone Nos"
                        />

                        <SetupsDatalistTemplat
                       selectedData={formData.intercom}
              
                        label="Intercom"
                        setCallbackValue={(e) => {
                          updateState("intercom", e);
                        }}
                        useCallbackFunc
                        />

                        <Form.Item
                        label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{"Logs Even Row Color"}</p>}
                        >
                        <span
                            className=" flex items-center flex-row "
                            style={{ width: 'calc(100%)' }}
                        >
                            <span
                            style={{ width: 'calc(100% - 47px)' }}
                            >
                            <SelectsTemplate
                            label=""
                            placeHolder={formData.flagsLogColor}
                            selectedValue={(e) => {
                              updateState("flagsLogColor", e);
                            }}
                            useCallFunc={true}
                            options={flaggedLogColors}
                            dataexp="stnLocalSyncServerName"
                            idexpr="id"
                            handleRefresh={
                              ()=>{
                                updateState("flagsLogColor", { name: "", id: "" });
                              }
                            }
                            
                          />
                            </span>

                            <span
                            style={{ height: 22.5, marginBottom: 2, borderWidth: 1, width: 19, borderColor: borderTheme }}
                            className={`ml-1 flex justify-center items-center bg-${""} hover:cursor-pointer border-gray-300 rounded `}>
                            </span>

                            {/* refresh */}
                            <span
                            style={{ height: 22.5, marginBottom: 2, borderWidth: 1, width: 23.9 }}
                            className='ml-1 flex justify-center items-center hover:cursor-pointer border-gray-300 rounded'>
                            <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src={reset} alt="reset" />
                            </span>
                        </span>
                        </Form.Item>

                        <Form.Item
                        label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{"Flagged Log Color"}</p>}
                        >
                        <span
                            className=" flex items-center flex-row "
                            style={{ width: 'calc(100%)' }}
                        >
                            <span
                            style={{ width: 'calc(100% - 47px)' }}
                            >
                            <SelectsTemplate
                            label=""
                            placeHolder={formData.logsEvenRowColor}
                            selectedValue={(e) => {
                              updateState("logsEvenRowColor", e);
                            }}
                            useCallFunc={true}
                            options={evenRowColors}
                            dataexp="evenrowColors"
                            idexpr="id"
                            handleRefresh={
                              ()=>{
                                updateState("localSyncServerName", { name: "", id: "" });
                              }
                            }
                            
                          />

                              
                            {/* <Select
                                style={{ width: '100%' }}
                                onChange={(e)=>{
                                  updateState("logsEvenRowColor", e);
                                }}
                                options={evenRowColors}
                               
                            >
                            </Select> */}
                            </span>

                            <span
                            style={{ height: 22.5, marginBottom: 2, borderWidth: 1, width: 19, borderColor: borderTheme }}
                            className={`ml-1 flex justify-center items-center bg-${""} hover:cursor-pointer border-gray-300 rounded `}>
                            </span>

                            {/* refresh */}
                            <span
                            style={{ height: 22.5, marginBottom: 2, borderWidth: 1, width: 23.9 }}
                            className='ml-1 flex justify-center items-center hover:cursor-pointer border-gray-300 rounded'>
                            <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src={reset} alt="reset" />
                            </span>
                        </span>
                        </Form.Item>
                        
                        <InputsTemplate
                        span
                        label="Latitude"
                        label2="Longitude"
                        useCallbackFunc
                        inlinePlaceholder={[formData.latitude, formData.longitude]}
                        inlineInputsOnchange={(key, value) =>{ 
                          if(key === "latitude"){
                            updateState("latitude", value);
                          }else{
                            updateState("longitude", value);
                          }
                          console.log(key+ value); console.log('seke')
                        }}
                        keyValues={["latitude", "longitude"]}
                        />

                        {/* Checkbox row 1 */}
                        <div className=" flex ml-[25px] mr-[10px] mb-1">
                            <div className=" w-2/6 ml-[10px]">
                                <CheckboxTemlate 
                                withBorders
                                labelCol={18} 
                                label={"Substations"}
                                setCheckboxOnchange={(value) => {
                                  updateState("substations", value);
                                }}
                                />
                            </div>

                            <div className="w-2/6">
                                <CheckboxTemlate 
                                withBorders
                                labelCol={18}
                                label={"NCC?"}
                                setCheckboxOnchange={(value) => {
                                  updateState("ncc", value);
                                }}
                                />
                            </div>

                            <div className="w-2/6 pr-[22px]">
                                <CheckboxTemlate 
                                withBorders
                                labelCol={22}
                                label={"Owned By Us?"}
                                setCheckboxOnchange={(value) => {
                                  updateState("ownedByUs", value);
                                }}
                                />
                            </div>
                        </div>

                        {/* Checkbox row 2 */}
                        <div className=" flex ml-[25px] mr-[10px] mb-1">
                            <div className=" w-2/6 ml-[10px]">
                                <CheckboxTemlate 
                                withBorders
                                labelCol={18} 
                                label={"Master Station"}
                                setCheckboxOnchange={(value) => {
                                  updateState("masterStation", value);
                                }}
                                />
                            </div>

                            <div className="w-2/6">
                                <CheckboxTemlate 
                                withBorders
                                labelCol={18}
                                label={"Operational?"}
                                setCheckboxOnchange={(value) => {
                                  updateState("operational", value);
                                }}
                                />
                            </div>

                            <div className="w-2/6 pr-[22px]">
                                <CheckboxTemlate 
                                withBorders
                                labelCol={22}
                                label={"Manned?"}
                                  setCheckboxOnchange={(value) => {
                                    updateState("manned", value);
                                  }}

                                />
                            </div>
                        </div>

                        {/* Checkbox row 3 */}
                        <div className=" flex ml-[25px] mr-[10px] mb-1">
                            <div className=" w-2/6 ml-[10px]">
                                <CheckboxTemlate 
                                withBorders
                                labelCol={18} 
                                label={"Manned By Us?"}
                                setCheckboxOnchange={(value) => {
                                  updateState("mannedByUs", value);
                                }}
                                />
                            </div>

                            <div className="w-2/6">
                                <CheckboxTemlate 
                                withBorders
                                labelCol={18}
                                label={"Has Computer?"}
                                setCheckboxOnchange={(value) => {
                                  updateState("hasComputer", value);
                                }}
                                />
                            </div>

                            <div className="w-2/6 pr-[22px]">
                                <CheckboxTemlate 
                                withBorders
                                labelCol={22}
                                label={"Has Network?"}
                                setCheckboxOnchange={(value) => {
                                  updateState("hasNetwork", value);
                                }}
                                />
                            </div>
                        </div>

                        {/* Checkbox row 4 */}
                        <div className=" flex ml-[25px] mr-[10px] mb-1">
                            <div className=" w-2/6 ml-[10px]">
                                <CheckboxTemlate 
                                withBorders
                                labelCol={18} 
                                label={"Show In eLog Book?"}
                                setCheckboxOnchange={(value) => {
                                  updateState("showInElogBook", value);
                                }}
                                />
                            </div>

                            <div className="w-2/6">
                                <CheckboxTemlate 
                                withBorders
                                labelCol={18}
                                label={"elogging?"}
                                setCheckboxOnchange={(value) => {
                                  updateState("elogging", value);
                                }}
                                />
                            </div>

                            <div className="w-2/6 pr-[22px]">
                                <CheckboxTemlate 
                                withBorders
                                labelCol={22}
                                label={"Active?"}
                                setCheckboxOnchange={(value) => {
                                  updateState("active", value);
                                }}
                                />
                            </div>
                        </div>

                        {/* Checkbox row 5 */}
                        <div className=" flex ml-[25px] mr-[10px] mb-1">
                            <div className=" w-2/6 ml-[10px]">
                                <CheckboxTemlate 
                                withBorders
                                labelCol={18} 
                                label={"Generation Station?"}
                                setCheckboxOnchange={(value) => {
                                  updateState("generationStation", value);
                                }}
                                />
                            </div>

                            <div className="w-2/6">
                                <CheckboxTemlate 
                                withBorders
                                labelCol={18}
                                label={"Status Good?"}
                                setCheckboxOnchange={(value) => {
                                  updateState("statusGood", value);
                                }}
                                />
                            </div>

                            <div className="w-2/6 pr-[22px]">
                                <CheckboxTemlate 
                                withBorders
                                labelCol={22}
                                label={"Sync Local Logs?"}
                                setCheckboxOnchange={(value) => {
                                  updateState("syncLocalLogs", value);
                                }}
                                
                                />
                            </div>
                        </div>
                    </div>

                    <div
                    style={{
                        width: "250px",
                    }}  className=" w-full flex pb-1 pl-4">
                        <div
                        style={{
                            border: "1px solid #ccc",
                            borderColor: borderTheme,
                        }}
                        className="p-1 flex items-center justify-center w-[200px] mr-1"
                        >
                            <Image
                            alt="No Image data"
                            className="w-full text-xs "/>
                        </div>

                        <div
                        className=" flex items-end">
                            <Checkbox
                                style={{ borderColor: borderTheme }}
                                className=" py-0.5 px-1 rounded border-[1px]"
                                checked={formData.loadImageActive}
                                value={formData.loadImageActive}
                                onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    loadImageActive: e.target.checked,
                                    //
                                });
                                setShowImages(e);
                                }}
                            />
                        </div>
                    </div>
                </div>

                {/* Remarks */}
                <div className=" ml-[33px]">
                    <TextareaTemplate
                    labelCol={4}
                    height={102.5}
                    label={'Remarks'}
                    useCallbackFunc ={true} 
                    defaultValue= {formData.remarks}
                    setCallbackValue= {(value)=>{
                        updateState('remarks', value)}}
                    />
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
   );
  }
);  
