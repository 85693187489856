export const equipCols=[
    {  id: 1,
        caption: "STATION",
        dataField: "stnName",
        fixed: false,
        width: 100,
    },
    {  id: 2,
        caption: "EQUIPMENT",
        dataField: "eotEquipment",
        fixed: false,
        width: 100,
    },
    {  id: 3,
        caption: "OUTAGE TYPE",
        dataField: "otpName",
        fixed: false,
        width: 100,
    },
    {  id: 4,
        caption: "EQUIPMENT TYPE",
        dataField: "oetName",
        fixed: false,
        width: 100,
    },
    {  id: 5,
        caption: "START TIME",
        dataField: "eotStartTime",
        fixed: false,
        width: 100,
    },
    {  id: 6,
        caption: "ACTIVE",
        dataField: "eotActive",
        dataType: "boolean",
        fixed: false,
        width: 100,
    },
    {  id: 7,
        caption: "END TIME",
        dataField: "eotEndTime",
        fixed: false,
        width: 100,
    },
    {  id: 8,
        caption: "DURATION (MINS)",
        dataField: "timeDifference",
        fixed: false,
        width: 100,
    },
    {  id: 9,
        caption: "DETAILS",
        dataField: "eotDetails",
        fixed: false,
        width: 100,
    },
    {  id: 10,
        caption: "REMARKS",
        dataField: "eotRmks",
        fixed: false,
        width: 300,
    },
]

export const equipData=[
    {
        id: 1,
        station: "Station A",
        equipment: "Transformer 1",
        outageType: "Planned",
        equipmentType: "Transformer",
        startTime: "2023-06-25T08:00:00",
        active: true,
        endTime: "2023-06-25T12:00:00",
        duration: 240,
        details: "Routine maintenance",
        remarks: "Maintenance completed successfully",
      },
      {
        id: 2,
        station: "Station B",
        equipment: "Generator 3",
        outageType: "Unplanned",
        equipmentType: "Generator",
        startTime: "2023-06-24T10:30:00",
        active: false,
        endTime: "2023-06-24T14:45:00",
        duration: 255,
        details: "Unexpected shutdown",
        remarks: "Fault in cooling system",
      },
      {
        id: 3,
        station: "Station C",
        equipment: "Switchgear 2",
        outageType: "Planned",
        equipmentType: "Switchgear",
        startTime: "2023-06-23T09:00:00",
        active: true,
        endTime: "2023-06-23T15:00:00",
        duration: 360,
        details: "Upgrade to higher capacity",
        remarks: "Upgrade in progress",
      },
      {
        id: 4,
        station: "Station D",
        equipment: "Circuit Breaker 5",
        outageType: "Planned",
        equipmentType: "Circuit Breaker",
        startTime: "2023-06-22T07:00:00",
        active: false,
        endTime: "2023-06-22T11:30:00",
        duration: 270,
        details: "Inspection and testing",
        remarks: "Inspection completed, no issues found",
      },
      {
        id: 5,
        station: "Station E",
        equipment: "Transformer 2",
        outageType: "Unplanned",
        equipmentType: "Transformer",
        startTime: "2023-06-21T14:00:00",
        active: true,
        endTime: "2023-06-21T16:45:00",
        duration: 165,
        details: "Overheating detected",
        remarks: "Ongoing investigation",
      },
      {
        id: 6,
        station: "Station F",
        equipment: "Generator 1",
        outageType: "Planned",
        equipmentType: "Generator",
        startTime: "2023-06-20T06:00:00",
        active: false,
        endTime: "2023-06-20T10:00:00",
        duration: 240,
        details: "Fuel system upgrade",
        remarks: "Upgrade completed successfully",
      },
      {
        id: 7,
        station: "Station G",
        equipment: "Switchgear 1",
        outageType: "Unplanned",
        equipmentType: "Switchgear",
        startTime: "2023-06-19T12:00:00",
        active: false,
        endTime: "2023-06-19T14:30:00",
        duration: 150,
        details: "Control failure",
        remarks: "Control system repaired",
      },
      {
        id: 8,
        station: "Station H",
        equipment: "Circuit Breaker 3",
        outageType: "Planned",
        equipmentType: "Circuit Breaker",
        startTime: "2023-06-18T08:00:00",
        active: true,
        endTime: "2023-06-18T12:00:00",
        duration: 240,
        details: "Routine inspection",
        remarks: "No issues detected",
      },
      {
        id: 9,
        station: "Station I",
        equipment: "Transformer 4",
        outageType: "Unplanned",
        equipmentType: "Transformer",
        startTime: "2023-06-17T09:00:00",
        active: false,
        endTime: "2023-06-17T13:15:00",
        duration: 255,
        details: "Oil leakage",
        remarks: "Leakage repaired, monitoring ongoing",
      },
      {
        id: 10,
        station: "Station J",
        equipment: "Generator 2",
        outageType: "Planned",
        equipmentType: "Generator",
        startTime: "2023-06-16T11:00:00",
        active: true,
        endTime: "2023-06-16T15:30:00",
        duration: 270,
        details: "Exhaust system maintenance",
        remarks: "Maintenance in progress",
      },
      {
        id: 11,
        station: "Station A",
        equipment: "Transformer 1",
        outageType: "Planned",
        equipmentType: "Transformer",
        startTime: "2023-06-25T08:00:00",
        active: true,
        endTime: "2023-06-25T12:00:00",
        duration: 240,
        details: "Routine maintenance",
        remarks: "Maintenance completed successfully",
      },
      {
        id: 12,
        station: "Station B",
        equipment: "Generator 3",
        outageType: "Unplanned",
        equipmentType: "Generator",
        startTime: "2023-06-24T10:30:00",
        active: false,
        endTime: "2023-06-24T14:45:00",
        duration: 255,
        details: "Unexpected shutdown",
        remarks: "Fault in cooling system",
      },
      {
        id: 13,
        station: "Station C",
        equipment: "Switchgear 2",
        outageType: "Planned",
        equipmentType: "Switchgear",
        startTime: "2023-06-23T09:00:00",
        active: true,
        endTime: "2023-06-23T15:00:00",
        duration: 360,
        details: "Upgrade to higher capacity",
        remarks: "Upgrade in progress",
      },
      {
        id: 14,
        station: "Station D",
        equipment: "Circuit Breaker 5",
        outageType: "Planned",
        equipmentType: "Circuit Breaker",
        startTime: "2023-06-22T07:00:00",
        active: false,
        endTime: "2023-06-22T11:30:00",
        duration: 270,
        details: "Inspection and testing",
        remarks: "Inspection completed, no issues found",
      },
      {
        id: 15,
        station: "Station E",
        equipment: "Transformer 2",
        outageType: "Unplanned",
        equipmentType: "Transformer",
        startTime: "2023-06-21T14:00:00",
        active: true,
        endTime: "2023-06-21T16:45:00",
        duration: 165,
        details: "Overheating detected",
        remarks: "Ongoing investigation",
      },
      {
        id: 16,
        station: "Station F",
        equipment: "Generator 1",
        outageType: "Planned",
        equipmentType: "Generator",
        startTime: "2023-06-20T06:00:00",
        active: false,
        endTime: "2023-06-20T10:00:00",
        duration: 240,
        details: "Fuel system upgrade",
        remarks: "Upgrade completed successfully",
      },
      {
        id: 17,
        station: "Station G",
        equipment: "Switchgear 1",
        outageType: "Unplanned",
        equipmentType: "Switchgear",
        startTime: "2023-06-19T12:00:00",
        active: false,
        endTime: "2023-06-19T14:30:00",
        duration: 150,
        details: "Control failure",
        remarks: "Control system repaired",
      },
      {
        id: 18,
        station: "Station H",
        equipment: "Circuit Breaker 3",
        outageType: "Planned",
        equipmentType: "Circuit Breaker",
        startTime: "2023-06-18T08:00:00",
        active: true,
        endTime: "2023-06-18T12:00:00",
        duration: 240,
        details: "Routine inspection",
        remarks: "No issues detected",
      },
      {
        id: 19,
        station: "Station I",
        equipment: "Transformer 4",
        outageType: "Unplanned",
        equipmentType: "Transformer",
        startTime: "2023-06-17T09:00:00",
        active: false,
        endTime: "2023-06-17T13:15:00",
        duration: 255,
        details: "Oil leakage",
        remarks: "Leakage repaired, monitoring ongoing",
      },
      {
        id: 20,
        station: "Station J",
        equipment: "Generator 2",
        outageType: "Planned",
        equipmentType: "Generator",
        startTime: "2023-06-16T11:00:00",
        active: true,
        endTime: "2023-06-16T15:30:00",
        duration: 270,
        details: "Exhaust system maintenance",
        remarks: "Maintenance in progress",
      },
]