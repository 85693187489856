import { Form, Select } from "antd";
import { useEffect, useRef, useState, useContext } from "react";
// import type { SelectProps } from 'antd';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ForwardAssignmentButton, LockSubmissionButton, RecallSubmissionButton, SearchButton } from '../../../accessories/buttons';
import { CIA_toolbar } from '../../../accessories/custom-toolbars/cia_toolbar';
import { lock_submission_datagrid_cols, unit_initiative_assignement_footer_datagrid_cols } from '../../data/datagrid_cols';
import { UIA_TextArea } from '../../data/Performance_inputs';
import { Footer_datagrid_template } from '../../data/template/footer_datagrid';
import { Lock_left_details_select, Lock_right_details_select } from './data/Performance_selects';
import reset from '../../../../assets/reset.png';
import useFetch from '../../../../hooks/useFetch';
import axios from 'axios';
import { api_url, navbar_height, saveModalData, updateModalData } from '../../../accessories/component_infos';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { useAccountInfo } from '../../../../hooks/useAccountInfo';
import  Datagrid_template from '../../../../templates/Datagrid';
import { useIsFetching } from 'react-query';
import { UpdateDataFunc } from '../../../../functions/update';
import { ModalTemplate } from '../../../../templates/modal';
import { modalTypes } from '../../../human-resource/setups/data/types/selectsTypes';
import { selectsTypes } from '../../../human-resource/setups/data/types/selectsTypes';
import { SelectsTemplate } from '../../../../templates/select';
import { ThemeProvider } from '../../../../App';




interface stateTypes {
    hat?: { id: number, name: string },
    periods?: { id: number, name: string },
    branch?: { id: number, name: string },
    department?: { id: number, name: string },
    section?: { id: number, name: string },
    units?: { id: number, name: string },
    selectedRecord?: any
}


// interface stateTypes {
//   hat?: { id: number; name: string };
//   periods?: { id: number; name: string };
//   branch?: { id: number; name: string };
//   department?: { id: number; name: string };
//   section?: { id: number; name: string };
//   units?: { id: number; name: string };
//   selectedRecord?: any;
// }

interface props {
  state_change: boolean;
}

interface stateTypes {
  hatState?: { id: number; name: string };
  periodState?: { id: number; name: string };
  branState?: { id: number; name: string };
  deptState?: { id: number; name: string };
  secState?: { id: number; name: string };
  untState?: { id: number; name: string };
  hat?: { id: number; name: string };
  periods?: { id: number; name: string };
  branch?: { id: number; name: string };
  department?: { id: number; name: string };
  section?: { id: number; name: string };
  units?: { id: number; name: string };
  selectedRecord?: any;
}

export const Lock_assignments = ({ state_change }: props) => {
  // use context api hook
 // const { themeColor } = useContext(ThemeProvider);

  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  // const width = window.innerWidth/2
  // const fixedWidth = 'calc(50% - 8px)';

  const height = window.innerHeight - (navbar_height + news_flash_height + 0); //height without navbar
  const [formheight, set_form_height] = useState<number>(0);
  const [switch_change, setSwitch_change] = useState<boolean>(true);

  const formRef = useRef<any>(null);
  const [fullPage, setfullPage] = useState(
    window.innerHeight - (navbar_height + news_flash_height + 25)
  );
  const pageHeight =
    window.innerHeight - (navbar_height + news_flash_height + formheight + 35);
  // const fullPage = window.innerHeight - (navbar_height + 45) ; //height without navbar

  // switch toggle
  const switch_toggle = () => {
    if (switch_change) {
      setSwitch_change(!switch_change);
    } else {
      setSwitch_change(!switch_change);
    }
  };

  const [states, setStates] = useState<stateTypes>({
    hatState: { id: 0, name: "" },
    periodState: { id: 0, name: "" },
    branState: { id: 0, name: "" },
    deptState: { id: 0, name: "" },
    secState: { id: 0, name: "" },
    untState: { id: 0, name: "" },
    selectedRecord: {},
  });

  const updateStates = (key: string, value: any) => {
    setStates((prev: any) => ({ ...prev, [key]: value }));
  };

  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [updatingstatus, setUpdatingstatus] = useState(false);
  const [showCancel, setshowCancel] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [isChart, setIsChart] = useState<boolean>(false);

  const [AllData, AllError, AllLoading] = useFetch(
    `PfmLockSubmissions/GetAllPfmLockSubmissions?Hat=${states.hatState?.id}&EmpIDpk=${1}}&SiaPeriodIDfk=${states.periodState?.id}&DptIDpk=${states.deptState?.id}&SxnIDpk=${states.secState?.id}&UntIDpk=${states.untState?.id}`,
    refresh
  );
  const [periodsData, periodsError, periodsLoading] = useFetch(
    "PfmLockSubmissions/GetAllPeriods"
  );
  const [branchesData, branchesError, branchesLoading] = useFetch(
    "PfmLockSubmissions/GetAllBranches"
  );
  const [departmentData, departmentError, departmentLoading] = useFetch(
    `PfmLockSubmissions/GetAllDepartments/1`
  );
  const [sectionData, sectionError, sectionLoading] = useFetch(
    `PfmLockSubmissions/GetAllSections/1`
  );
  const [unitData, unitError, unitLoading] = useFetch(
    `PfmLockSubmissions/GetAllUnits/1`
  );

  const [sections, setSections] = useState([]);
  const hatData = [
    { id: 0, value: "Supervisor" },
    { id: 1, value: "Manager" },
    { id: 2, value: "Director" },
    { id: 3, value: "Perf. Mgmt Team" },
  ];

  const leftSelects: selectsTypes[] = [
    {
      id: 0,
      label: "Hat",
      idExpr: "id",
      dataExpr: "value",
      optionsData: hatData,
      stateName: "",
      defaultValue: "",
      style: "",
    },
    {
      id: 1,
      label: "Periods",
      idExpr: "prdIdpk",
      dataExpr: "prdName",
      optionsData: periodsData,
    //   se
      stateName: "",
      defaultValue: "",
      style: "",
    },
  ];
  const rightSelects: selectsTypes[] = [
    {
      id: 0,
      label: "Branch",
      idExpr: "obrIdpk",
      dataExpr: "obrShtName",
      optionsData: branchesData,
      stateName: "",
      defaultValue: "",
      style: "",
    },
    {
      id: 1,
      label: "Department",
      idExpr: "dptIdpk",
      dataExpr: "dptShtName",
      optionsData: departmentData,
      stateName: "",
      defaultValue: "",
      style: "",
    },
    {
      id: 2,
      label: "Section",
      idExpr: "sxnIdpk",
      dataExpr: "sxnShtName",
      optionsData: sectionData,
      stateName: "",
      defaultValue: "",
      style: "",
    },
    {
      id: 3,
      label: "Unit",
      idExpr: "untIdpk",
      dataExpr: "untShtName",
      optionsData: unitData,
      stateName: "",
      defaultValue: "",
      style: "",
    },
  ];

  const lockModalData = {
    message: "Are you sure you want to Lock the selected transaction?",
    title: "Lock Transaction?",
    okText: "Yes",
    cancelText: "No",
  };

  const [modalInfo, setModalInfo] = useState<any>({
    icon: "question",
    data: saveModalData,
    disableCancel: false,
    openModal: false,
  });

  const validation = (method: "post" | "update") => {
    if (states.hatState?.name === "" || states.hatState?.name === undefined) {
      setModalInfo({
        icon: "warning",
        data: {
          title: "Required field",
          message: "Please put on hat",
        },
        disableCancel: true,
        openModal: true,
      });
    } else if (
      states.periods?.name === "" ||
      states.hatState?.name === undefined
    ) {
      setModalInfo({
        icon: "warning",
        data: {
          title: "Required field",
          message: "Please select period",
        },
        disableCancel: true,
        openModal: true,
      });
    } else {
      if (method === "post") {
        setModalInfo({
          icon: "question",
          data: saveModalData,
          disableCancel: false,
          openModal: true,
        });
      } else {
        setModalInfo({
          icon: "question",
          data: updateModalData,
          disableCancel: false,
          openModal: true,
        });
      }
    }
  };

  const lockData = async () => {
    try {
      const res = UpdateDataFunc(
        `PfmLockSubmissions/UpdatePfmLockSubmissions/${states.selectedRecord.empIDpk}`
      );
      setRefresh(!refresh);
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: "Data Locked successfully!",
        title: "All Data Lock Updated!",
        okText: "Ok",
      });
    } catch (err: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: err.message,
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };

  useEffect(() => {
    set_form_height(formRef.current?.clientHeight + 45);
    console.log(formRef);
  }, []);

  return (
    <div
      className={`h-full bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base   `}
    >
      {/* toggles between the taskForm and the footer grid table vertically */}

      {/* toggles between the taskForm and the footer grid table vertically */}
      <ModalTemplate
        icon={icon}
        cancelText={modalData.cancelText}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        open={openModal}
        okHandler={() => {
          modalData.title === lockModalData.title
            ? lockData()
            : setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
      />
      {/* <div>
                <LockSub_toolbar toggler={switch_toggle} />
            </div> */}


            {switch_change && <div ref={formRef} style={{ height: '200px' }} className='gap-x-4 border-b-4 mb-1 pb-1'>
                <p id={"form_header"} className='pt-1 mx-2 pl-4 w-full bg-slate-100 rounded-tl'>Lock Submission</p>
                <div style={{ width: '720px' }} className=" px-2 flex justify-between ">

                    {/* Internal toggler : expands the right taskUpdate horizontally */}
                    {/* <Lock_left_details_select /> */}

                    <Form
                        className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 px-2 "
                        name="basic"
                        labelCol={{ span: 7 }}
                        // wrapperCol={{ span: 24 }}
                        initialValues={{ remember: true }}
                        autoComplete="off"
                        size="small"
                        layout="horizontal"
                    >
                        <div style={{ width: '100%', height: '100%', marginTop: '72px' }} className='mb-1  ' >
                            {/* <Lock_left_details_select /> */}
                            <>
                                {leftSelects.map(({ id, label, idExpr, dataExpr, optionsData, stateName, defaultValue, style }) => {
                                    return (
                                        <>
                                            <SelectsTemplate
                                                label={label}
                                                options={optionsData}
                                                idexpr={idExpr}
                                                dataexp={dataExpr}
                                                placeHolder={defaultValue}
                                                useCallFunc
                                                disabled={false}
                                                selectedValue={(e) => { updateStates(stateName!, { id: e[idExpr!], name: e[dataExpr!] }) }}

                                            />
                                        </>
                                    )
                                })}

                            </>
                            <ul className='flex justify-end mr-[9%] '>
                                <SearchButton />
                            </ul>
                        </div>
                    </Form>


                    < Form
                        className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base y rounded py-2 px-2 "
                        name="basic"
                        labelCol={{ span: 9 }
                        }
                        wrapperCol={{ span: 24 }}
                        initialValues={{ remember: true }}
                        autoComplete="off"
                        size="small"
                        layout="horizontal"
                    >
                        <div style={{ width: '100%', height: '100%' }} className=" w-full mt-5 rounded-md items-center dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base " >
                            {/* <Lock_right_details_select /> */}
                            <>
                                {rightSelects.map(({ id, label, idExpr, dataExpr, optionsData, stateName, defaultValue, style }) => {
                                    return (
                                        <>
                                            <SelectsTemplate
                                                label={label}
                                                options={optionsData}
                                                idexpr={idExpr}
                                                dataexp={dataExpr}
                                                placeHolder={defaultValue}
                                                useCallFunc
                                                disabled={false}
                                                selectedValue={(e) => { updateStates(stateName!, { id: e[idExpr!], name: e[dataExpr!] }) }}
                                            />
                                        </>

                                    )
                                })}


                            </>


                            <ul className='flex justify-end mr-[9%]'>
                                <SearchButton />
                            </ul>
                        </div >
                    </Form >


                </div >
            </div >}


            {/* Footer gridtable  */}
            <div style={{ height: '' }} className='px-2 w-full  dark:bg-slate-900 dark:text-darkModeSkin-base'>
                <Form>
                    <Form.Item label={<p>Submissions</p>} labelCol={{span: 2}} >
                        <div className="border-t-[1px] border-l-[1px] border-r-none border-b-none ">
                            <Datagrid_template
                            
                                selectionMode='multiple'
                                gridheight={switch_change ? pageHeight : fullPage}
                                columns={lock_submission_datagrid_cols}
                                data={AllData}
                                onRowClick={(e) => { updateStates('selectedRecord', e) }}
                            />
                        </div>
                    </Form.Item>
                </Form>
                {/* <Footer_datagrid_template

                    gridheight={switch_change ? pageHeight : fullPage}
                    columns={lock_submission_datagrid_cols}
                    data={[]}
                /> */}
        <Form>
          <Form.Item
            label={<p className="hidden">Submissions</p>}
            labelCol={{ span: 2 }}
            colon={false}
          >
            <ul className="flex w-full ">
              <li className="flex w-2/6 justify-start">
                <RecallSubmissionButton />
              </li>
              <li className="flex w-2/6 justify-center">
                <ForwardAssignmentButton />
              </li>
              <li className="flex w-2/6  justify-end">
                <LockSubmissionButton
                  handleLock={() => {
                    setOpenModal(true);
                    setModalData(lockModalData);
                    setIcon("question");
                  }}
                />
              </li>
            </ul>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

// const reload = ()=>{
//     return(
//         <img className='w-2/6' src={save} />
//     )
// }