import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { CustomToolbar } from "../../../accessories/custom-toolbars/customtoolbar";
import { Staff_Datagrid } from "./widgets/staff-datagrid";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Staff_Form } from "./widgets/staffForm";
import { Staff_customtoolbar } from "../../../accessories/custom-toolbars/staff_details_customToolbar";
import {
  navbar_height,
  toolbar_height,
} from "../../../accessories/component_infos";
import Datagrid_template from "../../../../templates/Datagrid";
import {
  staff_datagrid_allcolumns,
  staffDetailsCriteria,
} from "./data/staff-details-data";
import useFetch from "../../../../hooks/useFetch";
import CustomLoader from "../../../accessories/custom-loader";
import { WarningAlert } from "../../../accessories/warningAlert";
import { useBusyLoader } from "../../../../hooks/useBusyLoader";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { HrSetupsCustomToolbar } from "../../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import dayjs from "dayjs";
import {
  formModalStates,
  searchTextTypes,
  statusTypes,
} from "../../setups/data/types/selectsTypes";
import { useAccountInfo } from "../../../../hooks/useAccountInfo";
import { useDispatch } from "react-redux";
import { setGeneralValue } from "../../../../features/generalSlice";
import { CustomizableButton } from "../../../accessories/buttons";
import users from "../../../../assets/users.png";
import { FormModal } from "../../../accessories/formModal";
import { Passport_Picture } from "../passportPictures/passportPictures";

const StaffDetails = forwardRef(({ a }: any, ref) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const miscHeight = 4;
  // All heights out
  const heights_out =
    navbar_height + news_flash_height + toolbar_height + miscHeight;

  //varying form height
  const form_height = 490;

  // search param value
  const current_page_route =
    "/human-resource/entries/staff-management/staff-details";
  const datagrid_only_search_param = "table-only";
  const form_mode_search_param = "form-and-table";

  // on page resize height responsiveness
  useImperativeHandle(ref, () => ({
    test: () => {
      settableHeight(window.innerHeight - heights_out);
      settable_form_Height(
        window.innerHeight - (form_height + heights_out + 3)
      );
    },
  }));

  const toggle_staff_form = (): void => {
    const view = searchParams.get("view");

    view === null || view === form_mode_search_param
      ? navigate({
          pathname: current_page_route,
          search: createSearchParams({
            view: form_mode_search_param,
          }).toString(),
        })
      : navigate({
          pathname: current_page_route,
          search: createSearchParams({
            view: datagrid_only_search_param,
          }).toString(),
        });
  };

  //Table Height
  const pageHeight = window.innerHeight - navbar_height; //height without navbar
  const [tableHeight, settableHeight] = useState(
    window.innerHeight - heights_out
  ); //height without navbar and toolbar
  const [table_form_Height, settable_form_Height] = useState(
    window.innerHeight - (form_height + heights_out + 3)
  ); //height without navbar and toolbar and form

  const [busyLoader, setBusyLoader] = useBusyLoader();
  const [refresh, setRefresh] = useState(false);

  //new states
  const genFormat = "YYYY-MM-DD";
  const today = dayjs().format(genFormat);
  const initialStart = dayjs()
    .subtract(14, "year")
    .startOf("year")
    .format(genFormat);

  const [startDate, setStartDate] = useState<searchTextTypes>({
    text: initialStart,
    temp: initialStart,
  });
  const [endDate, setEndDate] = useState<searchTextTypes>({
    text: today,
    temp: today,
  });
  const [searchText, setSearchText] = useState<searchTextTypes>({
    text: "",
    temp: "",
  });
  const [criteria, setCriteria] = useState<searchTextTypes>({
    text: "0",
    temp: "0",
  });
  const [status, setStatus] = useState<statusTypes>({ temp: "", status: "" });
  const [selectedData, setSeletedData] = useState<any>({});
  const [openFormModal, setOpenFormModal] = useState<boolean>(false);

  const [employeeId, userId, UserModel] = useAccountInfo();

  const [staffData, staffError, staffLoading] = useFetch(
    `HrEmployee/AllEmployees?startdate=${startDate.text}&enddate=${endDate.text}&criteriaIndex=${criteria.text}&search=${searchText.text}&active=${status.status}`,
    refresh
  );

  useEffect(() => {
    staffLoading ? setBusyLoader(".") : setBusyLoader("");
  }, [staffLoading]);

  const formModal: formModalStates[] = [
    {
      id: 0,
      label: "Passport Picture",
      module: <Passport_Picture state_change={false} />,
    },
  ];

  return (
    <>
      {/* error handler */}
      {staffError && <WarningAlert />}

      <FormModal
        height={400}
        width={"85%"}
        openState={openFormModal}
        onCancel={() => {
          setOpenFormModal(false);
        }}
        child={formModal[0].module}
        title={formModal[0].label}
      />

      <div style={{ height: pageHeight }} className="w-full px-2">
        {/*staff toolbar */}
        <div className="">
          <HrSetupsCustomToolbar
            startDate={startDate.temp}
            endDate={endDate.temp}
            startDateChanged={(date) =>
              setStartDate({
                temp: dayjs(date).format(genFormat),
                text: startDate.text,
              })
            }
            endDateChanged={(date) =>
              setEndDate({
                temp: dayjs(date).format(genFormat),
                text: endDate.text,
              })
            }
            searchTextOnchange={(text) =>
              setSearchText({ temp: text, text: searchText.text })
            }
            checkOnChange={(check) =>
              setStatus({ temp: check, status: status.status })
            }
            handleFind={() => {
              setStartDate({ text: startDate.temp, temp: startDate.temp });
              setEndDate({ text: endDate.temp, temp: endDate.temp });
              setSearchText({ temp: searchText.temp, text: searchText.temp });
              setStatus({ temp: status.temp, status: status.temp });
              setCriteria({ temp: criteria.temp, text: criteria.temp });
              setRefresh(!refresh);
            }}
            handleNew={() => setSeletedData({})}
            outerSelected={selectedData}
            toggler={toggle_staff_form}
            // setVisible={setSwitch_change}
            searchCriteria
            searchCriteriaData={staffDetailsCriteria}
            searchCriteriaOnchange={(text) =>
              setCriteria({ temp: text.toString(), text: criteria.temp })
            }
            handleRefresh={() => {
              setSearchText({ temp: "", text: "" });
              setRefresh(!refresh);
            }}
            extraWidgetsAfterSwitch={
              <div>
                <CustomizableButton
                  isTooltip
                  buttonID="update_pass"
                  tooltipMsg="Update Passport Picture"
                  classname={"mr-0.5 my-0.5"}
                  customizableBtnImage={users}
                  handleCustomizableClick={() => {
                    setOpenFormModal(true);
                  }}
                />
              </div>
            }
          />
        </div>

        {/* form */}
        {searchParams.get("view") === form_mode_search_param && (
          <div
            style={{ height: form_height }}
            className="form dark:text-darkModeSkin-base"
          >
            <Staff_Form
              refreshGrid={() => setRefresh(!refresh)}
              selectedRecord={selectedData}
            />
          </div>
        )}

        {/* datagrid */}
        <div className="dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base">
          <Datagrid_template
            columns={staff_datagrid_allcolumns}
            data={staffData}
            onRowClick={(rowData) => {
              setSeletedData(rowData);
              dispatch(setGeneralValue({ expr: "updateMode", value: false }));
              dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
            }}
            gridheight={
              searchParams.get("view") === datagrid_only_search_param ||
              searchParams.get("view") === null
                ? tableHeight
                : table_form_Height
            }
            rowDoubleClicked={(rowData) => {
              console.log(rowData);
              setSeletedData(rowData);
              dispatch(setGeneralValue({ expr: "updateMode", value: true }));
              dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
            }}
          />
        </div>
      </div>
    </>
  );
});

export default StaffDetails;
