import { Form, Select } from "antd";
import select from "antd/es/select";
import { useDispatch } from "react-redux";
import reset from '../../../../../../assets/reset.png';


interface SelectProps{
    
    labelCol ?: number
    wrapperCol ?: number
    disabled ?:boolean,
    onChange ?: any
    value ?:any
    dataSet ?:any
    label ?: string
    //display key is for the key for value you want to display
    displayKey ?: string
    refresh ?: any
    span ?: boolean
    width ?: any
    borderTheme?:any
}

// FOR PPE REQUEST ONLY

const StategicTheme_Select_Template = ({label,labelCol,wrapperCol,disabled, onChange, value, dataSet, displayKey, refresh,span,width,borderTheme}:SelectProps) => {

    
     
                
            return(
                <>
                <Form.Item 
                key={displayKey}
                label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{label}</p>}
                className= { ' mb-1'}
                labelCol={{span: labelCol }}
                wrapperCol={{span:wrapperCol}}
                >
                    <div className="flex flex-row items-center">
                    <Select
                            disabled={disabled}
                                onChange={onChange}
                                className='w-full mr-0'
                                placeholder=""
                                style={{width: width}}
                                value={value}
                            >
                                {dataSet.map((option: any, index: number) => {
                                    return (
                                        <option key={index} value={JSON.stringify(option)}> {option[displayKey!]}</option>
                                    )
                                })}
                            </Select>
                        
                       {!span && (
                         <span 
                         onClick={refresh} 
                         style={{height:22.5, marginBottom:2,borderWidth:1,width:23.9, borderColor:borderTheme}}
                         className='ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded'>
                         <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src= {reset} alt="reset" />
                         {/* <img className='' src= {''} alt="" /> */}
                     </span>
                       )}

                        
                    </div>
                </Form.Item>
                </>
            
        
    )
}
 
export default StategicTheme_Select_Template;