// whatever the number it affects the progress bar %complete

export const task_update_mini_cols = [
    {
        id:0,
        caption : "LOCKED?",
        dataField: "true",
        dataType: "boolean",
        alignment: 'left',
        width: 60,
        fixed: true,
        cssClass : 'dark:bg-slate-900 dark:text-darkModeSkin-base',
        cellRender: renderGridCell
    },
    {
        id:1,
        caption : "DATE",
        dataField: "tupDate",
        dataType: "string",
        alignment: 'left',
        fixed: true,
        cssClass : 'dark:bg-slate-900 dark:text-darkModeSkin-base',
        

    },
    {
        id:2,
        caption:"%COMPLETE",
        dataField: "tskPercentComplete",
        dataType: "string",
        alignment: 'left',
        fixed: true,
        cssClass : 'dark:bg-slate-900 dark:text-darkModeSkin-base',
        cellRender: renderGridCell


    },
    {
        id:3,
        caption:"TASK UPDATE",
        dataField: "tskUpdate",
        dataType: "string",
        alignment: 'left',
        fixed: true,
        cssClass : 'dark:bg-slate-900 dark:text-darkModeSkin-base'


    },
    {
        id:4,
        caption:"UPDATED BY",
        dataField: "tupUpdatedBy",
        dataType: "string",
        alignement: 'left',
        cssClass : 'dark:bg-slate-900 dark:text-darkModeSkin-base'

    },
    {
        id:5,
        caption:"JOB TITLE",
        dataField: "JOB TITLE",
        dataType: "string",
        alignement: 'left',
        cssClass : 'dark:bg-slate-900 dark:text-darkModeSkin-base'

    },
    {
        id:6,
        caption:"UPDATER",
        dataField: "tskCreatedBy",
        dataType: "string",
        alignement: 'left',
        cssClass : 'dark:bg-slate-900 dark:text-darkModeSkin-base'
    },
    {
        id:7,
        caption:"CREATED ON",
        dataField: "CREATED ON",
        dataType: "string",
        alignement: 'left',
        cssClass : 'dark:bg-slate-900 dark:text-darkModeSkin-base'

    },
    {
        id:8,
        caption:"COMPLETION DATE",
        dataField: "tskCompletionDate",
        dataType: "string",
        alignement: 'left',
        cssClass : 'dark:bg-slate-900 dark:text-darkModeSkin-base'
    },

    {
        id:9,
        caption:"REMARKS",
        dataField: "REMARKS",
        dataType: "string",
        alignement: 'left',
        width: 4000,
        cssClass : 'dark:bg-slate-900 dark:text-darkModeSkin-base'
    }
    


]


// Right side pane window


export const attachement_cols = []
export const comments_cols = []
export const share_cols =[]
export const trails_cols = []
export const images_cols = []

function renderGridCell(value: any) {
    throw new Error("Function not implemented.");
}


// <Column  dataField="LOCKED?"  fixed={true} width={60} cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataType='boolean'></Column>
//                                 <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="DATE" fixed={true} dataType='date' width={100} format={"dd MMM yyyy"} ></Column>
//                                 <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="%COMPLETE" fixed={true} cellRender={progressBar}></Column>
//                                 <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="TASK UPDATE"  dataType='string' ></Column>
//                                 <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="UPDATED BY" dataType='string' ></Column>
//                                 <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="JOB TITLE" dataType='string'></Column>
//                                 <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="UPDATER" dataType='string' width={100}></Column>
//                                 <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="CREATED ON " dataType='string'></Column>
//                                 <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="COMPLETION DATE" dataType='string'></Column>
//                                 <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="REMARKS" dataType='string' width={4000} ></Column>
