import { type ClassValue, clsx } from "clsx";
import * as React from "react";
import {
  FaChevronDown,
  FaChevronLeft,
  FaChevronRight,
  FaChevronUp,
} from "react-icons/fa";

export const HSeperator = ({
  status = false,
  onClick,
}: {
  status?: boolean;
  onClick?: () => void;
}) => {
  return (
    <section
      className={`w-full h-auto bg-sky-100 flex justify-center items-center text-neutral-500 cursor-pointer`}
      onClick={onClick}
    >
      {status ? (
        <FaChevronUp className="size-[10px]" />
      ) : (
        <FaChevronDown className="size-[10px]" />
      )}
    </section>
  );
};

export const VSeperator = ({
  status = false,
  height,
  onClick,
}: {
  status?: boolean;
  height?: number;
  onClick?: () => void;
}) => {
  return (
    <section
      className={`w-auto h-${
        height ? height : "full"
      } bg-sky-100 flex justify-center items-center text-neutral-500 cursor-pointer`}
      onClick={onClick}
    >
      {status ? (
        <FaChevronRight className="size-[10px]" />
      ) : (
        <FaChevronLeft className="size-[10px]" />
      )}
    </section>
  );
};

export function useDebounce<T>(value: T, delay?: number) {
  const [debounceValue, setDebounceValue] = React.useState<T>();
  React.useEffect(() => {
    const d = setTimeout(() => setDebounceValue(value), delay ?? 500);
    return () => {
      clearTimeout(d);
    };
  }, [value, delay]);
  return debounceValue;
}
