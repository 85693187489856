/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-pascal-case */
import { Checkbox, Form, Input, Space } from "antd";
import reset from '../../../../assets/reset.png'
import save from '../../../../assets/floppy-disk.png'
import search from '../../../../assets/search.png'

import dayjs, { Dayjs } from "dayjs";
import { useEffect, useImperativeHandle, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import useFetch from "../../../../hooks/useFetch";
import { CheckboxTemlate } from "../../../../templates/checkbox";
import { DateTemplate } from "../../../../templates/date";
import { InputsTemplate } from "../../../../templates/input";
import { ModalTemplate } from "../../../../templates/modal";
import { SelectsTemplate } from "../../../../templates/select";
import { currentDate, navbar_height, useModalData } from "../../../accessories/component_infos";
import { modalPropTypes, selectObjectTypes, modalTypes, checkboxTypes, searchTextTypes } from "../../../human-resource/setups/data/types/selectsTypes";
import { BottomBtns } from "../../../../templates/butttons";
import { TextareaTemplate } from "../../../../templates/textarea";
import Datagrid_template from "../../../../templates/Datagrid";
import { userPrivelegesColumns } from "../data/userAdminData";
import { passwordResetRequestFunc } from "../../../../pages/Login/functions/passwordResetRequest";
import React from "react";
import InputSelect_template from "../../../../templates/InputSelect";
import { trimAll } from "../../../Assets-Manager/Transport/Entries/Vehicles/Vehicle-Data-Upload/widgets/vehicleDataUpload-details";
import { SaveButton } from "../../../accessories/buttons";
import { useAccountInfo } from "../../../../hooks/useAccountInfo";
import { useDispatch } from "react-redux";
import { setGeneralValue } from "../../../../features/generalSlice";
import { GetDataFunc } from "../../../../functions/get";
import { useCrudFunc } from "../../../../functions/crud";

interface StateTypes {
    staffName: { id: number, value: string, staffno: string };
    date: string
    endDate: string
    username: { id: number, value: string }
    usernameBackup: string
    password: string
    confirmPassword: string
    expirePassword: boolean
    defaultApplication: selectObjectTypes
    headerColour: selectObjectTypes
    footerColour: selectObjectTypes

    active: boolean
    superUser: boolean
    seeSalaries: boolean
    editIntercoms: boolean
    editEmails: boolean
    seeDashboard: boolean
    seeAllForms: boolean
    resetOthersPassword: boolean
    seeAuditTrail: boolean
    allowSendingSMS: boolean
    editMobNos: boolean
    seeClassifiedDocs: boolean
    seeHiddenMobNos: boolean
    showRightNavigatorOnStartup: boolean

    remarks: string
    selectedRecordId: number
    confirmModal: modalPropTypes,
    warningModal: modalPropTypes,
    successModal: modalPropTypes,
    userPrivileges: any[]

    userAccountDetailsFormHeight: number
    usernameExistsStatus: 'neutral' | 'sameUser' | 'diffUser' | 'notExists'
    saveWithPassword: boolean

    savingPasswordStatus: boolean
    showAllForms: boolean

    selectedFormModule: selectObjectTypes
    selectedFormType: selectObjectTypes
    formSearchText: searchTextTypes

    selectedFormIndex: number | null
}

interface props {
    busyloader: (text: string) => void,
    clearSelected: () => void
    refreshGrid: () => void
    selectedField: any
    currentAccounts: any[]
    loadAllForms: boolean
    selectedRowIndex: number | null
}

export const UserAdministrationForm = React.forwardRef(({ busyloader, selectedRowIndex, clearSelected, refreshGrid, selectedField, loadAllForms, currentAccounts }: props, ref: any) => {
    const userAccountDetailsRef = useRef<any>();
    const [modal, setModal] = useModalData();
    const [PostDataFunc, UpdateDataFunc] = useCrudFunc();
    const updateMode: boolean = useSelector((state: RootState) => state.general.updateMode);
    const news_flash_height = useSelector((state: RootState) => state.general.newsflashHeight);
    const dispatch = useDispatch()
    //capture 
    useImperativeHandle(ref, () => ({
        handleClick() {
            resetPasswordWithSms('validate');
        }
    }));

    //populate fields
    useEffect(() => {
        selectedField && populateStatesFromGrid(false);
        const populatePrivelegesData = async () => {
            updateState('userPrivileges', await getPrivelegesData())
        }
        if (selectedField) (Object.keys(selectedField).length > 0 && loadAllForms) ? populatePrivelegesData() : updateState('userPrivileges', [])
    }, [selectedField]);

    //set form load status
    useEffect(() => {
        updateState('showAllForms', loadAllForms);
    }, [loadAllForms])

    //component states
    const [states, setState] = useState<StateTypes>({
        staffName: { id: 0, value: '', staffno: '' },
        date: "",
        endDate: "",
        username: { id: 0, value: '' },
        usernameBackup: '',
        password: '',
        confirmPassword: '',
        expirePassword: true,
        defaultApplication: { id: 0, value: '' },
        headerColour: { id: 0, value: '' },
        footerColour: { id: 0, value: '' },

        active: false,
        superUser: false,
        seeSalaries: false,
        editIntercoms: false,
        editEmails: false,
        seeDashboard: false,
        seeAllForms: false,
        resetOthersPassword: false,
        seeAuditTrail: false,
        allowSendingSMS: false,
        editMobNos: false,
        seeClassifiedDocs: false,
        seeHiddenMobNos: false,
        showRightNavigatorOnStartup: false,
        userPrivileges: [],

        remarks: '',
        selectedRecordId: 0,
        confirmModal: { state: false, title: '', message: '' },
        warningModal: { state: false, title: '', message: '' },
        successModal: { state: false, title: '', message: '' },

        userAccountDetailsFormHeight: 449,
        usernameExistsStatus: 'neutral',
        saveWithPassword: false,

        savingPasswordStatus: false,
        showAllForms: false,

        selectedFormModule: { id: 0, value: '' },
        selectedFormType: { id: 0, value: '' },
        formSearchText: { temp: '', text: '' },

        selectedFormIndex: null
    })
    const { staffName, usernameBackup, selectedFormModule, selectedFormType, selectedFormIndex, formSearchText, date, username, savingPasswordStatus, userPrivileges, showAllForms, saveWithPassword, password, usernameExistsStatus, userAccountDetailsFormHeight, endDate, selectedRecordId, confirmModal, expirePassword, defaultApplication, headerColour, footerColour, active, superUser, seeSalaries, editEmails, editIntercoms, seeDashboard, seeAllForms, resetOthersPassword, seeAuditTrail, allowSendingSMS, editMobNos, seeClassifiedDocs, seeHiddenMobNos, showRightNavigatorOnStartup, remarks, successModal, confirmPassword, warningModal } = states;

    //handle state update
    const updateState = (stateName: string, value: null | string | modalPropTypes | any[] | boolean | number | selectObjectTypes | searchTextTypes): void => { //update single state
        setState((prev) => ({ ...prev, [stateName]: value }));
    }

    const [currentUser] = useAccountInfo();

    // populate dropdowns
    //user account details
    const [staffData] = useFetch('AccountManagement/GetStaffNames')
    const [usernameData] = useFetch('AccountManagement/GetUserNames')
    const [defaultApplicationData] = useFetch('AccountManagement/GetModules')
    const [coloursData] = useFetch('AccountManagement/GetColours')

    //access priveleges
    const [formModulesData] = useFetch('AccountManagement/GetModules')

    //clear Password Field
    const clearPasswordFields = () => {
        updateState('password', '');
        updateState('confirmPassword', '');
        updateState('expirePassword', true)
    }

    //username text change
    useEffect(() => {
        const func = () => {
            const exists = usernameData?.find((item: any) => item?.name === username);
            if (exists) {
                // exists for same user?
                const user = currentAccounts?.find((staff: any) => staff?.usaUsername === exists?.name);
                if (user) {
                    if (user.empIDpk === staffName?.id) { //exists for same user
                        updateState('usernameExistsStatus', 'sameUser');
                        return;
                    }
                }

                //exists for different user
                updateState('usernameExistsStatus', 'diffUser');
                return;
            }

            updateState('usernameExistsStatus', 'notExists')
            return;
        }

        if (username) {
            func();
        }
        else {
            updateState('usernameExistsStatus', 'neutral');
        }
    }, [username, staffName.id]);

    //update user password func
    const updateUserPassword = async (userID: number) => {
        if(!userID) throw new Error("User ID not found")
        const updatePasswordResponse = await UpdateDataFunc(`AccountManagement/UpdatePassword`, {
            "usaIdpk": userID,
            "usaPassword": password,
            "usaChangePassword": expirePassword,
        },
            `Update the UpdatePassword with  usaIdpk:${userID}, usaChangePassword:${expirePassword} Date Created:${currentDate}. created By:${currentUser}`

        )
    }

    //populate states from grid
    const populateStatesFromGrid = (clearFields?: boolean) => {
        const populateInstances = [
            { state: 'date', value: clearFields ? dayjs(new Date()).format() : selectedField?.usaStartDate },
            { state: 'endDate', value: clearFields ? dayjs(new Date()).format() : selectedField?.usaEndDate },
            { state: 'staffName', value: clearFields ? { id: 0, value: '', staffno: '' } : { id: selectedField.empIDpk, value: selectedField?.empName, staffno: selectedField?.empStaffNo } },
            { state: 'username', value: clearFields ? { id: 0, value: '' } : { id: 0, value: selectedField?.usaUsername } },
            { state: 'usernameBackup', value: clearFields ? '' : selectedField?.usaUsername },
            { state: 'defaultApplication', value: clearFields ? { id: 0, value: '' } : { id: selectedField?.usaDefaultModuleIDfk, value: selectedField?.notset } },
            { state: 'headerColour', value: clearFields ? { id: 0, value: '' } : { id: selectedField.notset, value: selectedField?.notset } },
            { state: 'footerColour', value: clearFields ? { id: 0, value: '' } : { id: selectedField.notset, value: selectedField?.notset } },

            { state: 'active', value: clearFields ? false : selectedField?.empActive },
            { state: 'resetOthersPassword', value: clearFields ? false : selectedField?.usaResetOthersPasswords },
            { state: 'superUser', value: clearFields ? false : selectedField?.usaSuperUser },
            { state: 'seeAuditTrail', value: clearFields ? false : selectedField?.usaSeeAuditTrail },
            { state: 'seeSalaries', value: clearFields ? false : selectedField?.usaSeeSalaries },
            { state: 'allowSendingSMS', value: clearFields ? false : selectedField?.usaAllowSendingOfSMS },
            { state: 'editIntercoms', value: clearFields ? false : selectedField?.usaEditIntercom },
            { state: 'editMobNos', value: clearFields ? false : selectedField?.usaEditOthersMobNos },
            { state: 'editEmails', value: clearFields ? false : selectedField?.usaEditEmails },
            { state: 'seeClassifiedDocs', value: clearFields ? false : selectedField?.usaSeeClassifiedDocs },
            { state: 'seeDashboard', value: clearFields ? false : selectedField?.usaSeeExecDashboard },
            { state: 'seeHiddenMobNos', value: clearFields ? false : selectedField?.usaSeeHiddenMobNos },
            { state: 'seeAllForms', value: clearFields ? false : selectedField?.notset },
            { state: 'showRightNavigatorOnStartup', value: clearFields ? false : selectedField?.notset },
            { state: 'remarks', value: clearFields ? '' : selectedField?.usaRmks },
            { state: 'selectedRecordId', value: clearFields ? '' : selectedField?.usaIDpk },
        ]

        for (let instance of populateInstances) updateState(instance.state, instance.value);
    }

    //pre password reset with sms checks
    const resetPswdBySmsChecks = () => {
        return true;
    }

    //reset password with sms
    const resetPasswordWithSms = async (action: 'reset' | 'validate') => {
        // validate and confirm
        if (action === 'validate') {
            if (!selectedRecordId) {
                updateState('warningModal', {
                    state: true,
                    title: 'Select an account',
                    message: 'Please select an account to reset password with sms'
                })
                return;
            }

            resetPswdBySmsChecks() && updateState('confirmModal', {
                state: true,
                title: 'Reset Password?',
                message: 'Are you sure you want to reset the password of the selected account with sms?',
                okText: 'Yes, Reset',
                func() {
                    resetPasswordWithSms('reset');
                },
            })
            return;
        }

        // reset password
        disableModal();
        busyloader('Resetting Password...');

        const reset = await passwordResetRequestFunc(staffName.staffno);
        if (reset.status === 200) {
            updateState('successModal', {
                state: true,
                message: 'Pasword successfully reset with SMS',
                title: 'Reset Successful',
            })
            busyloader('')
            alert(reset.data.data[0]);
            return;
        }
        else if (reset.status === 400) {
            busyloader('')
            updateState('warningModal', {
                state: true,
                message: reset?.data?.data ?? 'Something went wrong. Please retry.',
                title: 'Reset Failed!',
            })

            return;
        }

        busyloader('')
        updateState('warningModal', {
            state: true,
            message: 'Something went wrong. Please retry.',
            title: 'Reset Failed!',
        })
    }

    const disableModal = () => {
        updateState('warningModal', {
            state: false,
            title: '',
            message: ''
        })

        updateState('confirmModal', {
            state: false,
            title: '',
            message: ''
        })
    }

    // validation
    const validateInputs = (validateFor: 'post' | 'put') => {
        if (!staffName.id) {
            updateState('warningModal', { state: true, title: 'Select Staff', message: 'Please select a staff' });
            return false;
        }
        else if (!date) {
            updateState('warningModal', { state: true, title: 'Enter Start date', message: 'Please enter start date' });
            return false;
        }
        else if (!endDate) {
            updateState('warningModal', { state: true, title: 'Enter End date', message: 'Please enter end date' });
            return false;
        }
        else if (validateFor === 'post' && (dayjs(date).format('DD/MM/YYYY') < dayjs(new Date).format('DD/MM/YYYY'))) {
            updateState('warningModal', { state: true, title: 'Invalid Start Date', message: 'Start date must not be before today' });
            return false;
        }
        else if (dayjs(endDate) < dayjs(date)) {
            updateState('warningModal', { state: true, title: 'Invalid End Date', message: 'End Date must be greater than start date' });
            return false;
        }
        else if (dayjs(endDate).format('DD/MM/YYYY hh:mm A') === dayjs(date).format('DD/MM/YYYY hh:mm A')) {
            updateState('warningModal', { state: true, title: 'Invalid End Date', message: 'Start Date and end date must not be same' });
            return false;
        }
        else if (!username || trimAll(username.value) === '') {
            updateState('warningModal', { state: true, title: 'Enter username', message: 'Please enter username' });
            return false;
        }
        else if (!defaultApplication.id) {
            updateState('warningModal', { state: true, title: 'Select Default Application', message: 'Please select default application' });
            return false;
        }
        else if (!headerColour.id) {
            updateState('warningModal', { state: true, title: 'Select Header Colour', message: 'Please select header colour' });
            return false;
        }
        else if (!footerColour.id) {
            updateState('warningModal', { state: true, title: 'Select Default Application', message: 'Please select footer colour' });
            return false;
        }
        return true;
    }

    // other validations
    const makeChecks = (checkOn: 'post' | 'put', user?: any): boolean => {
        //check whether to post password and validate
        if (password) {
            if (password !== confirmPassword) {
                setModal({
                    state: true,
                    title: "Passwords Do Not Match",
                    message: 'Password and it\'s confirmation do not match',
                    icon: "warning",
                    okText: "Ok",
                    cancelText: "No",
                    disableCancel: true,
                    okHandler: () => {
                        setModal(prev => ({
                            ...prev,
                            state: false,

                        }))
                    },
                    cancelHandler: () => {
                        setModal(prev => ({
                            ...prev,
                            state: false,

                        }))
                    }
                })
                return false;
            }
            updateState('saveWithPassword', true);
        }

        return true;
    }

    //update data
    const updateData = async (action: 'validate' | 'put') => {
        try {
            let user: any;
            if (action === 'validate') {
                if (validateInputs('put')) {
                    // check if user exists [if username has changed]
                    if (username.value !== usernameBackup) {
                        user = await GetDataFunc(`Accounts/CheckAccount?empId=${staffName.staffno}&username=${username}`);
                    }
                }
                makeChecks('put') && setModal({
                    state: true,
                    title: 'Update Record?',
                    message: 'Are you sure you want to update this record?',
                    icon: "question",
                    okText: 'Yes, Update',
                    cancelText: "No",
                    disableCancel: false,
                    okHandler: async () => {
                        setModal(prev => ({
                            ...prev,
                            state: false,

                        }))
                        await updateData('put');
                    },
                    cancelHandler: () => {
                        setModal(prev => ({
                            ...prev,
                            state: false,
                        }))
                    }
                })
                return;
            }

            disableModal();

            busyloader('Updating record...');

            const res = await UpdateDataFunc(
                `AccountManagement/Update`,
                {
                    "usaIdpk": selectedRecordId,
                    "usaEmpIdfk": staffName.id,
                    "usaUsername": username.value,
                    "usaChangePassword": expirePassword,
                    "usaResetOthersPasswords": resetOthersPassword,
                    "usaActive": active,
                    "usaDefaultModuleIdfk": defaultApplication.id,
                    "usaSuperUser": superUser,
                    "usaAllowSendingOfSms": allowSendingSMS,
                    "usaSeeSalaries": seeSalaries,
                    "usaEditOthersMobNos": editMobNos,
                    "usaSeeHiddenMobNos": seeHiddenMobNos,
                    "usaEditIntercom": editIntercoms,
                    "usaEditEmails": editEmails,
                    "usaSeeExecDashboard": seeDashboard,
                    "usaSeeClassifiedDocs": seeClassifiedDocs,
                    "usaSeeAllForms": seeAllForms,
                    "usaShowRightNavigatorOnStartup": showRightNavigatorOnStartup,
                    "usaFormHeaderBackColourIdfk": headerColour.id,
                    "usaFormFooterBackColourIdfk": footerColour.id,
                    "usaRmks": remarks,
                    "usaCreatedBy": currentUser,
                    "usaSeeAuditTrail": seeAuditTrail,
                    "usaStartDate": date,
                    "usaEndDate": endDate,
                    "usaEditedBy": 1,
                    "usaEditedDate": dayjs(new Date()).format()
                },
                `Update the 'User Administration' with  usaSuperUser:${superUser}, usaUsername:${username.value}, usaResetOthersPasswords:${resetOthersPassword} Date Created:${currentDate}. created By:${currentUser}`

            );

            // check status and make password update
            if (password && (password === confirmPassword)) {
                updateUserPassword(selectedRecordId);
            }
            if (res?.status) {
                setModal({
                    state: true,
                    title: 'Record Updated',
                    message: 'Record updated successfully',
                    icon: "success",
                    okText: "Ok",
                    cancelText: "No",
                    disableCancel: true,
                    okHandler: async () => {
                        setModal(prev => ({
                            ...prev,
                            state: false,

                        }))

                    },
                    cancelHandler: () => {
                        setModal(prev => ({
                            ...prev,
                            state: false,

                        }))
                    }
                })
            }
            busyloader('');
            refreshGrid();
        }
        catch (error: any) {

            busyloader('');

            //user name exists instance
            if (error?.response?.status === 400) {
                const errorMessage = error.response?.data;
                updateState('warningModal', {
                    state: true,
                    title: 'Update Failed',
                    message: errorMessage ?? 'Update failed. Please try again.'
                })

                return;
            }

            const errordata: any[] = Object.values(typeof error?.response?.data?.errors === 'object' ? error?.response?.data?.errors : {});

            //set states for db errors modal
            updateState('warningModal', {
                state: true,
                title: 'Update Failed',
                message: errordata[0][0] ?? 'Update failed. Please try again or contact system administrator'
            })
        }
    };
    const PostFunction = async (action: 'validate' | 'post') => {
        try {
            let user: any;
            if (action === 'validate') {
                if (validateInputs('post')) {
                    // check if user exists [if username has changed]
                    if (username.value !== usernameBackup) {
                        user = await GetDataFunc(`Accounts/CheckAccount?empId=${staffName.staffno}&username=${username}`);
                    }
                }
                makeChecks('post') && setModal({
                    state: true,
                    title: 'Save Record?',
                    message: 'Are you sure you want to sav this record?',
                    icon: "question",
                    okText: 'Yes, Save',
                    cancelText: "No",
                    disableCancel: false,
                    okHandler: async () => {
                        setModal(prev => ({
                            ...prev,
                            state: false,

                        }))
                        await PostFunction('post');
                    },
                    cancelHandler: () => {
                        setModal(prev => ({
                            ...prev,
                            state: false,
                        }))
                    }
                })
                return;
            }

            disableModal();

            busyloader('Saving record...');

            const res = await PostDataFunc(`AccountManagement/Create`, {
                "usaEmpIdfk": staffName.id,
                "usaUsername": username.value,
                "usaChangePassword": expirePassword,
                "usaResetOthersPasswords": resetOthersPassword,
                "usaActive": active,
                "usaDefaultModuleIdfk": defaultApplication.id,
                "usaSuperUser": superUser,
                "usaAllowSendingOfSms": allowSendingSMS,
                "usaSeeSalaries": seeSalaries,
                "usaEditOthersMobNos": editMobNos,
                "usaSeeHiddenMobNos": seeHiddenMobNos,
                "usaEditIntercom": editIntercoms,
                "usaEditEmails": editEmails,
                "usaSeeExecDashboard": seeDashboard,
                "usaSeeClassifiedDocs": seeClassifiedDocs,
                "usaSeeAllForms": seeAllForms,
                "usaShowRightNavigatorOnStartup": showRightNavigatorOnStartup,
                "usaFormHeaderBackColourIdfk": headerColour.id,
                "usaFormFooterBackColourIdfk": footerColour.id,
                "usaRmks": remarks,
                "usaCreatedBy": currentUser,
                "usaSeeAuditTrail": seeAuditTrail,
                "usaStartDate": date,
                "usaEndDate": endDate
            },
                `Created the 'User Administration ' with  usaSuperUser:${superUser}, usaUsername:${username.value}, usaResetOthersPasswords:${resetOthersPassword} Date Created:${currentDate}. created By:${currentUser}`
            );

            // check status and make password update
            if (password && (password === confirmPassword)) {
                updateUserPassword(selectedRecordId);
            }
            if (res?.data?.isSuccess) {
                setModal({
                    state: true,
                    title: 'Record Saved',
                    message: 'Record Saved successfully',
                    icon: "success",
                    okText: "Ok",
                    cancelText: "No",
                    disableCancel: true,
                    okHandler: async () => {
                        setModal(prev => ({
                            ...prev,
                            state: false,
                        }))
                    },
                    cancelHandler: () => {
                        setModal(prev => ({
                            ...prev,
                            state: false,
                        }))
                    }
                })
            } else {
                setModal({
                    state: true,
                    title: 'Record Not Saved',
                    message: 'Record not saved, an error occured',
                    icon: "warning",
                    okText: "Ok",
                    cancelText: "No",
                    disableCancel: true,
                    okHandler: async () => {
                        setModal(prev => ({
                            ...prev,
                            state: false,
                        }))
                    },
                    cancelHandler: () => {
                        setModal(prev => ({
                            ...prev,
                            state: false,
                        }))
                    }
                })
            }
            busyloader('');
            refreshGrid();
        }
        catch (error: any) {
            busyloader('');

            //user name exists instance
            if (error?.response?.status === 400) {
                const errorMessage = error.response?.data?.errors?.usaUsername[0] ?? 'Update failed. Please try again.';
                setModal({
                    state: true,
                    title: 'Record not saved, an error occured',
                    message: `${errorMessage}`,
                    icon: "warning",
                    okText: "Ok",
                    cancelText: "No",
                    disableCancel: true,
                    okHandler: async () => {
                        setModal(prev => ({
                            ...prev,
                            state: false,
                        }))
                    },
                    cancelHandler: () => {
                        setModal(prev => ({
                            ...prev,
                            state: false,
                        }))
                    }
                })

                return;
            }

            const errordata: any[] = Object.values(typeof error?.response?.data?.errors === 'object' ? error?.response?.data?.errors : {});

            //set states for db errors modal
            updateState('warningModal', {
                state: true,
                title: 'Update Failed',
                message: errordata[0][0] ?? 'Update failed. Please try again or contact system administrator'
            })
        }
    };

    //password save click
    const passwordSaveClick = (action: 'validate' | 'updatePassword') => {
        // Validate Password Instance
        if (action === 'validate') {
            if (!selectedRecordId) {
                updateState('warningModal', {
                    state: true,
                    title: 'Select Account',
                    message: 'Please select an account to update password'
                })
                return;
            }
            else if (!password || password.trim() === '') {
                updateState('warningModal', {
                    state: true,
                    title: 'Enter Password',
                    message: 'Please enter password'
                })
                return;
            }
            else if (!confirmPassword || confirmPassword.trim() === '') {
                updateState('warningModal', {
                    state: true,
                    title: 'Confirm Password',
                    message: 'Please enter password\'s confirmation'
                })
                return;
            }
            else if (password !== confirmPassword) {
                updateState('warningModal', {
                    state: true,
                    title: 'Password Mismatch',
                    message: 'Password and it\'s confirmation do not match'
                })
                return;
            }

            // confirm password update
            updateState('confirmModal', {
                state: true,
                title: 'Save Password?',
                message: 'Are you sure you want to save this password for the selected account?',
                okText: 'Yes, Save',
                cancelFunc() {
                    clearPasswordFields();
                    disableModal();
                },
                func() {
                    passwordSaveClick('updatePassword');
                },
            })

            return;
        }

        // update Password Instance
        disableModal();
        busyloader('Saving Password...');
        try {
            updateUserPassword(selectedRecordId);

            //success
            clearPasswordFields();
            busyloader('');
            updateState('successModal', {
                state: true,
                title: 'Save Successful',
                message: 'Password saved successfully'
            })

        } catch (error: any) {


            updateState('warningModal', {
                state: true,
                title: 'Password Save Failed',
                message: 'Failed to save password. Please try again or contact system adminstrator'
            })
            return;
        }
    }




    //---PRIVELEGES SECTION---
    //get all forms
    const getPrivelegesData = async (url?: string) => {
        //initially get all forms
        let mergedData: any[] = [];
        busyloader('Fetching forms & Privileges...')
        const allFormsResponse = await GetDataFunc(url ?? `AccountManagement/GetAllForms`);

        for (let form of allFormsResponse.data) {
            //get priveleges of that form
            const formPrivelegesResponse = await GetDataFunc(`AccountManagement/GetFormAccess?userId=${selectedRecordId}&formId=${form?.afmIDpk}`);
            // 


            const privelege = formPrivelegesResponse.data[0] ?? { "apvInsert": false, "apvRead": false, "apvUpdate": false, "apvPrint": false, "apvDelete": false }

            form = { ...form, ...privelege }

            mergedData.push(form);
        }

        busyloader('');
        return mergedData
    }

    //get priveleges by search
    const getPrivelegesBySearch = async () => {
        //reset index
        updateState('selectedFormIndex', null);
        const filterForms = async () => {
            if (selectedFormModule.value && selectedFormType.value) {
                updateState('userPrivileges', await getPrivelegesData(`AccountManagement/GetFormsForSelectedModuleAndType?type=${selectedFormType.value}&module=${selectedFormModule.value}&search=${formSearchText.text}`));
                return;
            }
            else if (selectedFormType.value) {
                updateState('userPrivileges', await getPrivelegesData(`AccountManagement/GetFormsForSelectedType?type=${selectedFormType.value}&search=${formSearchText.text}`));
                return;
            }

            updateState('userPrivileges', await getPrivelegesData(`AccountManagement/GetFormsForSelectedModule?module=${selectedFormModule.value}&search=${formSearchText.text}`));
        }

        //pre filter checks
        if (selectedRecordId) {
            if (!selectedFormModule.value && !selectedFormType.value) {
                //get all forms
                updateState('userPrivileges', await getPrivelegesData(`AccountManagement/GetAllForms?search=${formSearchText.text}`));
            }
            else {
                filterForms();
            }
        } else {
            updateState('warningModal', {
                state: true,
                title: 'Select An Account',
                message: 'Please select an account to read privileges'
            })
        }
    }

    // create access privileges
    const createPrivilege = (action: 'validate' | 'create') => {
        // validate instance
        if (action === 'validate') {
            if (!selectedRecordId) {
                updateState('warningModal', {
                    state: true,
                    title: 'Select An Account',
                    message: 'Please select an account to assign privileges'
                })
                return;
            }
            else if (userPrivileges.length < 1) {
                updateState('warningModal', {
                    state: true,
                    title: 'Load Forms',
                    message: 'Please load forms to assign privileges'
                })
                return;
            }

            updateState('confirmModal', {
                state: true,
                title: 'Assign Privileges',
                message: 'Are you sure you want to assign these privileges to the selected account?',
                func() {
                    createPrivilege('create');
                },
            })
            return;
        }


        const func = async () => {
            disableModal();
            busyloader('Saving Privilege(s)')
            try {
                for (let form of userPrivileges) {


                    // check if there is an existing privilege
                    const existingPrivilege = await GetDataFunc(`AccountManagement/GetFormAccess?userId=${selectedRecordId}&formId=${form.afmIDpk}`);

                    if (existingPrivilege) {
                        // delete and push to privileges history
                    }

                    // at least one privilege assigned and make request
                    if (form.apvUpdate || form.apvRead || form.apvPrint || form.apvInsert || form.apvRead || form.apvDelete) {


                        await PostDataFunc(`AccountManagement/AssignPrivlege`, {
                            "apvUsaIdfk": selectedRecordId,
                            "apvFormIdfk": form.afmIDpk,
                            "apvInsert": form.apvInsert,
                            "apvRead": form.apvRead,
                            "apvUpdate": form.apvUpdate,
                            "apvDelete": form.apvDelete,
                            "apvPrint": form.apvPrint,
                            "apvCreatedBy": currentUser
                        },
                            `Created the 'Assign Privilege ' with  apvFormIdfk:${form.afmIDpk}, apvInsert:${form.apvInsert}, apvRead:${form.apvRead} Date Created:${currentDate}. created By:${currentUser}`

                        )
                    }
                }
                busyloader('')
                //success
                updateState('successModal', {
                    state: true,
                    title: 'Save Successful',
                    message: 'Access privilege(s) saved successfully'
                })
            } catch (error) {
                busyloader('');


                updateState('warningModal', {
                    state: true,
                    title: 'Save Failed',
                    message: 'Failed to save privileges. Please try again or contact system administrator'
                })
            }
        }
        func();
    }

    // modal instances
    const modals: modalTypes[] = [
        //validation
        { disableCancel: true, icon: 'warning', open: warningModal.state, okHandler: () => updateState('warningModal', { state: false, title: '', message: '' }), cancelHandler: () => updateState('warningModal', { state: false, title: 'string', message: 'string' }), message: warningModal.message, okText: 'Ok', title: warningModal.title, cancelText: 'No, cancel' },

        //confirmation
        { disableCancel: false, icon: 'question', open: confirmModal.state, okHandler: confirmModal.func!, cancelHandler: () => confirmModal?.cancelFunc ? confirmModal.cancelFunc() : updateState('confirmModal', { state: false, title: 'string', message: 'string' }), message: states.confirmModal.message, okText: confirmModal.action === 'update' ? 'Yes, Update' : (confirmModal.okText ?? 'Yes, Save'), title: states.confirmModal.title, cancelText: 'No, cancel' },

        //success
        {
            disableCancel: true, icon: 'success', open: successModal.state, okHandler: () => {
                updateState('successModal', { state: false, title: '', message: '' })
            }, cancelHandler: () => updateState('successModal', { state: false, title: 'string', message: 'string' }), message: successModal.message, okText: 'Ok', title: successModal.title, cancelText: 'No, cancel'
        },
    ]

    const formTypes: any[] = [
        { typeIDpk: 0, typeShtName: 'Entry' },
        { typeIDpk: 1, typeShtName: 'Setup' },
        { typeIDpk: 2, typeShtName: 'Report' },
        { typeIDpk: 3, typeShtName: 'Sys Admin' }
    ]

    const checkboxes: checkboxTypes[] = [
        { id: 0, label: 'Active?', label2: 'Reset Others Password?', inlineDefaultValue: [active, resetOthersPassword], inlineStateName: ['active', 'resetOthersPassword'] },
        { id: 1, label: 'Super User?', label2: 'See Audit Trail?', inlineDefaultValue: [superUser, seeAuditTrail], inlineStateName: ['superUser', 'seeAuditTrail'] },
        { id: 2, label: 'See Salaries?', label2: 'Allow Sending of SMS?', inlineDefaultValue: [seeSalaries, allowSendingSMS], inlineStateName: ['seeSalaries', 'allowSendingSMS'] },
        { id: 3, label: 'Edit Intercoms?', label2: 'Edit Others Mob Nos?', inlineDefaultValue: [editIntercoms, editMobNos], inlineStateName: ['editIntercoms', 'editMobNos'] },
        { id: 4, label: 'Edit Emails?', label2: 'See Classified Docs?', inlineDefaultValue: [editEmails, seeClassifiedDocs], inlineStateName: ['editEmails', 'seeClassifiedDocs'] },
        { id: 5, label: 'See Dashboards?', label2: 'See Hidden Mob Nos?', inlineDefaultValue: [seeDashboard, seeHiddenMobNos], inlineStateName: ['seeDashboard', 'seeHiddenMobNos'] },
        { id: 6, label: 'See All Forms?', label2: 'Show Right Navigator On Startup?', inlineDefaultValue: [seeAllForms, showRightNavigatorOnStartup], inlineStateName: ['seeAllForms', 'showRightNavigatorOnStartup'] },
    ]



    const disabled = useSelector((state: RootState) => state.general.formDisabled); //form disabled state on general redux slice


    return (
        <>
            <ModalTemplate
                width={400}
                icon={modal.icon}
                disableCancel={modal.disableCancel}
                cancelText={modal.cancelText}
                open={modal.state}
                okHandler={modal.okHandler}
                cancelHandler={modal.cancelHandler}
                message={modal.message}
                okText={modal.okText}
                title={modal.title}
            />
            {<div className=" w-full px-2">
                {modals.map(({ disableCancel, icon, okHandler, cancelHandler, open, cancelText, okText, message, title }: modalTypes, index) => {
                    return (
                        <div key={index}>
                            {/* <ModalTemplate icon={icon && icon} disableCancel={disableCancel} cancelText={cancelText && cancelText} open={open} okHandler={okHandler} cancelHandler={cancelHandler} message={message} okText={okText} title={title} /> */}
                        </div>

                    )
                })}
                <div className="w-full">
                    <p className='py-1 font-semibold px-2 w-full bg-slate-100'>{'User Account Details'}</p>

                    <div
                        ref={userAccountDetailsRef}
                        className=" flex flex-row border-2 rounded-b border-slate-100">
                        <Form
                            style={{ width: '55%' }}
                            className='rounded py-1 px-2 '
                            name="basic"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 19 }}
                            initialValues={{ remember: true }}
                            autoComplete="off"
                            size='small'
                        >
                            <div className=" mb-0.4">
                                <SelectsTemplate

                                    useCallFunc
                                    options={staffData}
                                    selectedValue={(obj) => {
                                        console.log("staffName: ", obj.empName)
                                        updateState('staffName', {
                                            id: obj.empIDpk,
                                            value: obj.empName
                                        })
                                    }}
                                    disabled={updateMode ? true : undefined}
                                    placeHolder={staffName.value}
                                    idexpr="empIDpk"
                                    dataexp="empName"

                                    label={'Staff Name'}
                                />
                            </div>

                            <div className=" mb-0.5">
                                <DateTemplate
                                    span={false}
                                    selectedDate={dayjs(date)}
                                    datePlaceholder={dayjs(new Date()).format()}
                                    changeDate={(dateTime: Dayjs) => {
                                        console.log("startdate: ", dayjs(dateTime).format())
                                        updateState('date', dayjs(dateTime).format())
                                    }}
                                    label="Start Date" />
                            </div>

                            <div className=" mb-0.5">
                                <DateTemplate
                                    span={false}
                                    selectedDate={dayjs(endDate)}
                                    datePlaceholder={dayjs(new Date()).format()}
                                    disabled={disabled}
                                    changeDate={(dateTime: Dayjs) => {
                                        console.log("endDate: ", dayjs(dateTime).format())

                                        updateState('endDate', dayjs(dateTime).format())
                                    }}
                                    label="End Date" />
                            </div>

                            <div className=" mb- mt-[-1px] ">
                                {/* TODO: error was here */}
                                <InputSelect_template
                                    placeHolder={states.username.value}
                                    selectStyle={
                                        { "borderRadius": "0.375rem", backgroundColor: `${usernameExistsStatus === 'diffUser' ? "red" : usernameExistsStatus === 'sameUser' ? "orange" : usernameExistsStatus === 'notExists' ? "#65DF65" : "white"}` }
                                    }
                                    useCallFunc={true}
                                    label={'Username'}
                                    options={usernameData}
                                    selectedValue={(obj) => {
                                        console.log("username: ", obj.usaUsername)
                                        updateState('username', {
                                            id: obj.usaIDpk,
                                            value: obj.usaUsername
                                        })
                                    }}
                                    disabled={updateMode ? true : undefined}
                                    idexpr="usaIDpk"
                                    dataexp="usaUsername"
                                />
                            </div>

                            <div className=" bg-yellow-50 rounded-md pb-0.5">
                                <div className=" mb-0">
                                    <InputsTemplate
                                        disabledStatus={false}
                                        label='Password'
                                        defaultValue={password}
                                        inputType='password'
                                        useCallbackFunc
                                        orderOnchange={(text) => {
                                            console.log("password: ", text)
                                            updateState('password', text)
                                        }}
                                    />
                                </div>

                                <div className=" mb-0.5 ">
                                    <span className=" bg-blue-800s">
                                        <InputsTemplate
                                            inputType='password'
                                            defaultValue={confirmPassword}
                                            disabledStatus={false}
                                            useCallbackFunc
                                            orderOnchange={(text) => {
                                                console.log("confirmPassword: ", text)
                                                updateState('confirmPassword', text)
                                            }}
                                            label='Confirm Password'
                                        />
                                    </span>
                                </div>

                                <Form.Item
                                    style={{ marginLeft: 3 }}
                                    labelCol={{ span: 9 }}
                                    label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>Expire Password?</p>}
                                    className=" flex flex-row w-full">

                                    <Space>
                                        <div
                                            style={{ marginRight: 195 }}
                                            className=" flex flex-row justify-between w-full">
                                            <div className=" border-2 rounded px-0.5 mt-0.5 mb-1 ">
                                                <Checkbox
                                                    checked={expirePassword}
                                                    onChange={(check) => {
                                                        console.log("togglePasswordExpireTo: ", check.target.checked)
                                                        updateState('expirePassword', check.target.checked);
                                                    }}
                                                />
                                            </div>

                                            <span className="">
                                                <SaveButton
                                                    handleOnClick={() => passwordSaveClick('updatePassword')}
                                                    text={'Save'}
                                                // className="flex h-6 hover:border-blue-200 flex-row items-center justify-center  px-2 py-1 border-2 border-slate-200 rounded dark:bg-slate-900 dark:text-darkModeSkin-base"

                                                />
                                                {/* <button
                                                    onClick={() => passwordSaveClick('validate')}
                                                    className="flex h-6 hover:border-blue-200 flex-row items-center justify-center  px-2 py-1 border-2 border-slate-200 rounded dark:bg-slate-900 dark:text-darkModeSkin-base"
                                                >
                                                    <img className="pr-1" src={save} />
                                                    Save
                                                </button> */}
                                            </span>
                                        </div>
                                    </Space>
                                </Form.Item>
                            </div>

                            <div className=" mb-0.5">
                                <SelectsTemplate
                                    label={'Default Application'}
                                    useCallFunc
                                    options={defaultApplicationData}
                                    selectedValue={(obj) => {
                                        console.log("defaultApplication: ", obj.apmShtName)
                                        updateState('defaultApplication', {
                                            id: obj.apmIDpk,
                                            value: obj.apmShtName
                                        })
                                    }}
                                    placeHolder={defaultApplication.value}
                                    idexpr="apmIDpk" //apmIDpk
                                    dataexp="apmShtName"
                                />
                            </div>

                            <Form.Item
                                label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{'Header & Footer Colours'}</p>}
                            >
                                <div className=" w-full flex justify-between flex-row items-center">
                                    <span
                                        className=" flex items-center flex-row"
                                        style={{ width: 'calc(40%)' }}
                                    >
                                        <span
                                            style={{ width: 'calc(100%-28px)' }}
                                            className="  w-full"
                                        >
                                            <SelectsTemplate
                                                useCallFunc
                                                options={coloursData}
                                                span={true}
                                                fullWidth={true}
                                                selectedValue={(obj) => {
                                                    console.log("headerColour: ", obj.sclName)
                                                    updateState('headerColour', {
                                                        id: obj.sclIDpk,
                                                        value: obj.sclName
                                                    })
                                                }}

                                                disabled={updateMode ? true : undefined}
                                                placeHolder={headerColour.value}
                                                idexpr="sclIDpk"
                                                dataexp="sclName"
                                                extraWidget={
                                                    <div className={`border ml-1 mb-0.5 w-[24px] h-[23.5px]  rounded-md`} style={{ backgroundColor: `${headerColour.value}` }}>
                                                        {/* <Input
                                                            disabled={true}
                                                            className={`bg-${headerColour.value}`}
                                                            style={{
                                                                width: 24,
                                                                // backgroundColor: `${states.colour}`,
                                                            }}
                                                        /> */}
                                                    </div>
                                                }
                                            />


                                            {/* <Select
                                                value={headerColour.value}
                                                onChange={(option: any) => {
                                                    const optionData = JSON.parse(option);
                                                    updateState('headerColour', {
                                                        id: optionData.sclIDpk,
                                                        value: optionData.sclName
                                                    })
                                                }}
                                            >
                                                {coloursData?.map((option: any) =>
                                                    <Select.Option key={option.sclIDpk} value={JSON.stringify(option)}>{option.sclName}</Select.Option>
                                                )}
                                            </Select> */}
                                        </span>

                                        {/* <span
                                            style={{ height: 22.5, marginBottom: 2, borderWidth: 1, width: 23.9 }}
                                            className={`ml-1 flex justify-center items-center bg-${headerColour.value} hover:cursor-pointer border-gray-300 rounded`}>
                                        </span> */}
                                    </span>

                                    <span
                                        className=" flex flex-row justify-between items-center"
                                        style={{ width: 'calc(58.5%)' }}>
                                        <span
                                            style={{ width: 'calc(100% - 25px)' }}
                                            className=" w-full"
                                        >
                                            <SelectsTemplate
                                                useCallFunc={true}
                                                options={coloursData}
                                                selectedValue={(obj) => {
                                                    console.log("footerColour: ", obj.schName)
                                                    updateState('footerColour', {
                                                        id: obj.sclIDpk,
                                                        value: obj.sclName
                                                    })
                                                }}

                                                disabled={updateMode ? true : undefined}
                                                placeHolder={footerColour.value}
                                                idexpr="sclIDpk"
                                                dataexp="sclName"
                                                span={true}
                                                fullWidth={true}
                                                extraWidget={
                                                    <div className={`border ml-1 mb-0.5 w-[24px] h-[23.5px]  rounded-md`} style={{ backgroundColor: `${footerColour.value}` }}>
                                                    </div>
                                                }


                                            // label={'Staff Name'}
                                            />
                                            {/* <Select
                                                value={footerColour.value}
                                                onChange={(option: any) => {
                                                    const optionData = JSON.parse(option);
                                                    updateState('footerColour', {
                                                        id: optionData.sclIDpk,
                                                        value: optionData.sclName
                                                    })
                                                }}
                                            >
                                                {coloursData?.map((option: any) =>
                                                    <Select.Option key={option.sclIDpk} value={JSON.stringify(option)}>{option.sclName}</Select.Option>
                                                )}
                                            </Select> */}
                                        </span>
                                        <span
                                            style={{ height: 22.5, marginBottom: 2, borderWidth: 1, width: 25 }}
                                            className='ml-1 flex justify-center items-center hover:cursor-pointer border-gray-300 rounded'>
                                            <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src={reset} alt="reset" />
                                        </span>
                                    </span>
                                </div>
                            </Form.Item>

                            <div className=" mt-1">
                                <BottomBtns
                                    useCallbackFunc
                                    onNewClick={() => {
                                        populateStatesFromGrid(true);
                                        dispatch(setGeneralValue({ expr: "formDisabled", value: false }))
                                    }}
                                    saveHandler={async () => {
                                        await PostFunction('validate')
                                    }}
                                    updateHandler={async () => await updateData('validate')}
                                    span />
                            </div>
                        </Form>
                        <Form
                            style={{ width: '45%' }}
                            className='rounded py-1 px-2 '
                            name="basic"
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 19 }}
                            initialValues={{ remember: true }}
                            autoComplete="off"
                            size='small'
                        >
                            {checkboxes.map(({ id, label, label2, inlineDefaultValue, inlineStateName }) =>
                                <div key={id} className=" mr-2 mb-1">
                                    <CheckboxTemlate
                                        index={[0, 1]}
                                        useCallbackFunc
                                        inlineDefaultValue={[inlineDefaultValue![0], inlineDefaultValue![1]]}
                                        setCheckboxOnchange={(checkValue, index) => {
                                            updateState(inlineStateName![index], checkValue);
                                        }}
                                        withBorders
                                        label={label}
                                        label2={label2} />
                                </div>
                            )}
                            <TextareaTemplate
                                useCallbackFunc
                                setCallbackValue={(value) => updateState('remarks', value)}
                                defaultValue={remarks}
                                label="Remarks"
                                labelCol={7}
                                height={87} />
                        </Form>
                    </div>
                    <div
                        style={{ width: 859 }}
                        className="grid mt-2 ">
                        <p className='font-semibold py-1 px-2 w-full bg-slate-100'>{'Access Privileges'}</p>
                        <div
                            style={{ width: 859 }}
                            className=" px-1 pb-1 border-2 border-slate-100  rounded-b">
                            <Datagrid_template
                                selectedRowIndex={(index) => updateState('selectedFormIndex', index)}
                                disableGroupPanel
                                style={{ width: 851 }}
                                allowColumnEditing
                                disablePaging
                                disableSearch
                                gridheight={window.innerHeight - (userAccountDetailsFormHeight + navbar_height + news_flash_height)}
                                columns={userPrivelegesColumns}
                                data={userPrivileges}
                            />
                        </div>

                        <div className="base flex px-1 rounded border-2 flex-row items-center mt-1">
                            <p className=" w-20">{`Rec ${(userPrivileges.length > 0 && selectedFormIndex !== null) ? selectedFormIndex + 1 : 0} of ${userPrivileges.length ?? 0}`}</p>

                            <span
                                style={{ marginRight: 39 }}
                                className="flex items-center">
                                <label className=" mr-1" htmlFor="">Module: </label>
                                <SelectsTemplate
                                    disabled={false}
                                    useCallFunc
                                    useCusWidth
                                    fullWidth
                                    span={true}
                                    style={{ width: 130 }}
                                    disabledCustomWidth={false}
                                    options={formModulesData}
                                    selectedValue={(obj) => {
                                        console.log("selectedFormModule: ", obj.apmShtName)
                                        updateState('selectedFormModule', {
                                            id: obj.apmIDpk,
                                            value: obj.apmShtName
                                        })
                                    }}
                                    placeHolder={selectedFormModule.value}
                                    idexpr="apmIDpk"
                                    dataexp="apmShtName"
                                />
                            </span>

                            <span className=" mr-7 flex items-center">
                                <label className=" mr-1" htmlFor="">Type: </label>
                                <SelectsTemplate
                                    useCallFunc
                                    useCusWidth
                                    disabled={false}
                                    fullWidth
                                    style={{ width: 130 }}
                                    span={true}
                                    disabledCustomWidth={false}
                                    options={formTypes}
                                    selectedValue={(obj) => {
                                        updateState('selectedFormType', {
                                            id: obj.typeIDpk,
                                            value: obj.typeShtName
                                        })
                                    }}
                                    placeHolder={selectedFormType.value}
                                    idexpr="typeIDpk"
                                    dataexp="typeShtName"
                                />
                            </span>

                            <span className=" mr-1">
                                <label className=" mr-1" htmlFor="">Form: </label>
                                <Input
                                    allowClear
                                    onChange={(text) => {
                                        updateState('formSearchText', {
                                            text: text.target.value,
                                            temp: text.target.value
                                        })
                                    }}
                                    className=" bg-yellow-50"
                                    size='small'
                                    style={{ width: 110, height: 22 }}
                                />
                            </span>

                            <span
                                style={{ height: 22.5, marginBottom: 2, borderWidth: 1, width: 23.9 }}
                                className='ml-1 mr-1 mt-0.5  flex justify-center items-center hover:cursor-pointer border-gray-300 rounded'>
                                <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src={reset} alt="reset" />
                            </span>

                            <span
                                onClick={getPrivelegesBySearch}
                                style={{ height: 22.5, marginBottom: 2, borderWidth: 1, width: 23.9 }}
                                className='ml-1 mr-2 mt-0.5 flex justify-center items-center hover:cursor-pointer border-gray-300 rounded'>
                                <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src={search} alt="reset" />
                            </span>

                            <button
                                onClick={() => createPrivilege('validate')}
                                style={{ height: 26 }}
                                className="flex font-semibold bg-slate-100  hover:border-blue-200 flex-row items-center justify-center  px-1 py-1 border-2 border-slate-200 rounded dark:bg-slate-900 dark:text-darkModeSkin-base"
                            >
                                <img className="pr-1" src={save} height={`10px`} width={`20px`} />
                                Save Privs
                            </button>
                        </div>
                    </div>
                </div>

            </div>


            }
        </>
    );
})
