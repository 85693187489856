import { useEffect, useState } from "react";
import "devextreme/dist/css/dx.light.css";
import { Tooltip } from "devextreme-react";
import swap from "../../../../assets/swap.png";
import edit from "../../../../assets/edit.png";
import newfile from "../../../../assets/new.png";
import close from "../../../../assets/close.png";
import refresh from "../../../../assets/refresh.png";
import printer from "../../../../assets/printer.png";
import { FaBinoculars } from "react-icons/fa";

import setting from "../../../../assets/setting.png";

import { RootState } from "../../../../app/store";
import { ModalTemplate } from "../../../../templates/modal";
import statusLoader from "../../../../assets/statusloader.gif";
import {
  task_form_disable,
  task_footer_update,
} from "../../../../features/Task&Todo/Task/TaskDetails";
import {
  setGeneralValue,
  settask_form_disable,
  settask_footer_update,
} from "../../../../features/generalSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteTask,
  TaskSettings,
  AlertSMS,
  AppraiseSettings,
} from "../../../accessories/custom-toolbars/widgets/customToolbarModals";
import { Input, Select, DatePicker, Checkbox } from "antd";
import { saveModalData } from "../../../accessories/component_infos";
import dayjs from "dayjs";
import { useFormPriviledge } from "../../../../hooks/useFormPriviledge";

interface props {
  toggler?: () => void;
  startDate?: string;
  endDate?: string;
  searchTextOnchange?: (value: string) => void;
  startDateChanged?: (value: any) => void;
  endDateChanged?: (value: any) => void;
  checkOnChange?: (value: any) => void;
  handleFind?: () => void;
  handleRefresh?: () => void;
  searchCriteriaOnchange?: (index: number, criteria: string) => void;
  checkBoxAfterHelpOnchange?: (checkValue: boolean) => void;
  checkBoxAfterHelp?: boolean;
  searchCriteria?: boolean;
  assignmentsForm?: boolean;
  searchCriteriaData?: { id: number; label: string }[];
}

export const TransformersToolbar = ({
  toggler,
  startDate,
  endDate,
  searchCriteria,
  searchTextOnchange,
  startDateChanged,
  endDateChanged,
  checkOnChange,
  handleFind,
  handleRefresh,
  searchCriteriaOnchange,
  searchCriteriaData,
}: props) => {
  const { Search } = Input;

  const dateFormat = " DD MMM YYYY";
  const [privType, setPrivType] = useState<string>("save");
  const dispatch = useDispatch();
  const [criteriaDataLocal, setCriterialDataLocal] = useState({ id: searchCriteriaData![0]?.id ?? 0, value: searchCriteriaData![0]?.label ?? '' });
  const [switchiconMsg, setSwitchiconMsg] = useState<string>("Personal");
  const [isActive, setIsActive] = useState<boolean>(false);
  const [SettingsisActive, SettingssetIsActive] = useState<boolean>(false);
  const [AlertSMSisActive, AlertSMSsetIsActive] = useState<boolean>(false);
  const [AppraiseisActive, AppraisesetIsActive] = useState<boolean>(false);
  const size: number = 16;
  const [checkedBox, setCheckedBox] = useState<any>(true);
  const [checkValue, setCheckValue] = useState<boolean | string>("");
  const [searchTextValue, setSearchTextValue] = useState("");
  const [indeterminate, setIndeterminate] = useState<any>(false);
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);
  useEffect(() => {
    dispatch(setGeneralValue({ expr: "activeStatus", value: checkValue }));
    // checkOnChange && checkOnChange(checkValue);
  }, [checkValue]);

  const onChange = (value: boolean) => {
    if (indeterminate === true) {
      setCheckedBox(true);
      setIndeterminate(false);
      setCheckValue(true);
    } else if (!indeterminate && checkedBox) {
      setCheckedBox(false);
      setIndeterminate(false);
      setCheckValue(false);
    } else if (!indeterminate && !checkedBox) {
      setCheckedBox(false);
      setIndeterminate(true);
      setCheckValue("");
    }
  };

  // const Customswitch = (val:Boolean)=>{
  //     if(val === true){
  //         setSwitchiconMsg('All tasks')
  //     }else {
  //         setSwitchiconMsg('Personal')
  //         setIsActive(!isActive)

  //     }
  // }

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  // from redux
  const disable = useSelector((state: RootState) => state.general.formDisabled);
  const disablex = useSelector(
    (state: RootState) => state.addTask.taskFormStatus
  );
  const updateMode = useSelector(
    (state: RootState) => state.addTask.taskFooterUpdate
  );
  const selectedRecord = useSelector(
    (state: RootState) => state.safetyManagerDetails.selectedRecord
  );

  // relative to the new button
  const handleNew = () => {
    dispatch(setGeneralValue({ expr: "formDisabled", value: false }));

    dispatch(task_form_disable(false));
    dispatch(settask_form_disable(false));

    //  this destroys the update mode state
    dispatch(task_footer_update(false));
    dispatch(settask_footer_update(false));

    //clear data to redux
    dispatch(setGeneralValue({ expr: "selectedFieldData", value: [] }));
  };

  const handleCancel = () => {
    dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    dispatch(task_form_disable(true));
    dispatch(settask_form_disable(true));
    // dispatch(update_route_state(false))
    dispatch(task_footer_update(false));
    dispatch(settask_footer_update(false));

    // dispatch(settask_footer_update(false))
    dispatch(setGeneralValue({ expr: "updateMode", value: false }));

    // NO ONE SHOULD DELETE THIS !!!!
    // handleCallbackCancel!();
  };
  const findAccessPriv = () => {
    if (readPriv === true) {
      handleFind && handleFind();
    } else {
      setIcon("warning");
      setModalData({ message: "No Access privilege", title: "Access Denied!" });
      setOpenModal(true);
    }
  };
  const [refreshValue, setRefreshValue] = useState(false);
  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [updatingstatus, setUpdatingstatus] = useState(false);
  const [showCancel, setshowCancel] = useState(false);
  const [Active, setActive] = useState(false);
  const [refreshx, setRefreshx] = useState(false);

  //handle edit
  const handleUpdate = () => {
    if (selectedRecord.length === 0) {
      // dispatch(task_form_disable(true));
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "No record selected",
        title: "Invalid record",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning")
    } else {
      //enable forms
      dispatch(task_form_disable(false));

      //enable Update Mode
      dispatch(task_footer_update(true));
    }
  };

  return (
    <div
      style={{ borderBottomWidth: "1px", width: "4000px" }}
      className="w-full h-full flex  border-gray-200 mb-1"
    >
      <div className="flex justify-center px-2">
        <ModalTemplate
          icon_width={modalData.iconSize === "large" ? 100 : 40}
          icon={icon}
          cancelText={""}
          open={openModal}
          okHandler={() => {
            setOpenModal(false);
          }}
          message={modalData.message}
          okText={modalData.okText}
          title={modalData.title}
        />
        <ul className="flex justify-evenly w-full items-center h-full py-0.5">
          {/* new and cancel section */}
          {disable || updateMode ? (
            <li
              id="newRecord"
              onClick={() => handleNew()}
              style={{ borderWidth: "1px" }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img className="pr-1 " src={newfile} />
                New{" "}
              </span>
              <Tooltip
                target="#newRecord"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">New Record</p>
              </Tooltip>
            </li>
          ) : (
            <li
              id="close-cancel"
              onClick={() => handleCancel()}
              style={{ borderWidth: "1px" }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img className="pr-1 " src={close} />
                Cancel{" "}
              </span>
              <Tooltip
                target="#close-cancel"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Cancel</p>
              </Tooltip>
            </li>
          )}
          {!updateMode ? (
            <li
              id="editRecordst"
              onClick={() => {
                handleUpdate();
              }}
              style={{ borderWidth: "1px" }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img
                  className="dark:bg-slate-900 pr-1 dark:text-gray-100 duration-100"
                  src={edit}
                />
                Edit{" "}
              </span>
              <Tooltip
                target="#editRecordst"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Edit Record</p>
              </Tooltip>
            </li>
          ) : (
            <li
              id="close-cancel"
              onClick={() => handleCancel()}
              style={{ borderWidth: "1px" }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img className="pr-1 " src={close} />
                Cancel{" "}
              </span>
              <Tooltip
                target="#close-cancel"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Cancel</p>
              </Tooltip>
            </li>
          )}
          <li
            id="switch"
            onClick={toggler}
            style={{ borderWidth: "1px" }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
          >
            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
              {" "}
              <img
                className="dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"
                src={swap}
              />
              Switch{" "}
            </span>
            <Tooltip
              target="#switch"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="h-2">Switch interface / table view</p>
            </Tooltip>
          </li>

          {/* <li id="towns" onClick={()=>{}} style={{borderWidth : '1px'}}  className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center">
                   <img className="dark:bg-slate-900 dark:text-darkModeSkin-base duration-100" src={setting} />  
                        <Tooltip
                            target="#towns"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                            >
                            <p className="h-2">Towns</p>
                        </Tooltip>
                    </li> */}
          <li className="px-1 text-xs">Search Criteria</li>
          <li className="items-right  ">
          <Select
                value={criteriaDataLocal}
                dropdownMatchSelectWidth={false}
                className=" mt-0.5"
                onChange={(data: any) => {
                  const criteriaData = JSON.parse(data);
                  // console.log('@#',criteriaData);
                  setCriterialDataLocal({
                    id: criteriaData?.index,
                    value: criteriaData?.criteria,
                  }); //local
                  searchCriteriaOnchange &&
                    searchCriteriaOnchange(
                      criteriaData?.index,
                      criteriaData?.criteria
                    ); //external
                }}
                id="search"
                size="small"
                showSearch={true}
                style={{ width: 110, height: "25px" }}
              >
                {searchCriteriaData?.map(({ id, label }, index) => (
                  <Select.Option
                    key={id}
                    value={JSON.stringify({ index: index, criteria: label })}
                  >
                    {label}
                  </Select.Option>
                ))}
              </Select>
          </li>
        </ul>
      </div>

      <div className="flex items-center">
        <ul className="flex items-center">
          <li className="px-1 text-xs">Search Text</li>
          <li className="px-1">
          <Input
                onChange={(e: any) => {
                  const searchText: string = e.target.value;
                  searchTextOnchange && searchTextOnchange(searchText);
                  setSearchTextValue(e.target.value);
                }}
                value={searchTextValue}
                name="search"
                id="search"
                size="small"
                allowClear={true}
                style={{ width: 330, height: "24px" }}
              />
          </li>
          <li className="px-0.5">
          <DatePicker
                value={dayjs(startDate) ?? dayjs()}
                clearIcon={<span style={{ display: "none" }}></span>}
                onChange={startDateChanged}
                size="small"
                placeholder="Start Date"
                style={{ width: 125 }}
                format={"DD MMM YYYY"}
              />
          </li>
          <li className="px-0.5">
          <DatePicker
                value={dayjs(endDate) ?? dayjs()}
                clearIcon={<span style={{ display: "none" }}></span>}
                onChange={(date)=>{ 
                  endDateChanged && endDateChanged(date)}}
                size="small"
                placeholder="End Date"
                style={{ width: 125 }}
                format={"DD MMM YYYY"}
              />
          </li>
          <li
              style={{ borderWidth: "1px",  }}
              id="status"
              className="hover:cursor-pointer mx-1 border-slate-200 text-xs px-1  text-gray-600 rounded flex items-center"
            >
              <Checkbox
                checked={checkedBox}
                indeterminate={indeterminate}
                onChange={(checkValue: any) => {
                  // console.log("checkValue", checkValue.target.checked);
                  onChange(checkValue);
                  checkOnChange && checkOnChange(checkValue.target.checked);
                }}
              />
            <Tooltip
              target="#status"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p>show status</p>
            </Tooltip>
          </li>

          <li
            id="refresh"
            style={{ borderWidth: "1px" }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1 text-gray-600 rounded flex items-center"
          >
            <span>
              {" "}
              <img
                src={refresh}
                className="w-4  dark:bg-slate-900 dark:text-gray-100 duration-100"
              />{" "}
            </span>
            <Tooltip
              target="#refresh"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p>Refresh</p>
            </Tooltip>
          </li>
          <li
            id="find"
            style={{ borderWidth: "1px" }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1 text-gray-600 rounded flex items-center"
          >
            <span>
              {" "}
              <FaBinoculars size={15.5} color="#007AFF" />{" "}
            </span>
            <Tooltip
              target="#find"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p>Find</p>
            </Tooltip>
          </li>
          <li
            id="print"
            style={{ borderWidth: "1px" }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1 text-gray-600 rounded flex items-center"
          >
            <span>
              {" "}
              <img src={printer} />{" "}
            </span>
            <Tooltip
              target="#print"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p>Print / Export</p>
            </Tooltip>
          </li>

          <li className="hover:cursor-pointer ml-20  px-2  flex items-center">
            {disablex && (
              <p className=" italic font-semibold text-gray-600">Done.</p>
            )}

            {!disablex && (
              <div className="  flex flex-row items-center">
                <img
                  style={{ width: "31px", height: "31px" }}
                  src={statusLoader}
                  alt="gif"
                />
                {!updateMode ? (
                  <p className=" italic text-gray-600">New Record</p>
                ) : (
                  <p className=" italic text-gray-600">Editing</p>
                )}
              </div>
            )}
          </li>
        </ul>

        <DeleteTask isActive={isActive} setIsActive={setIsActive} />
        <TaskSettings
          SettingsisActive={SettingsisActive}
          SettingssetIsActive={SettingssetIsActive}
        />
        <AlertSMS
          AlertSMSisActive={AlertSMSisActive}
          AlertSMSsetIsActive={AlertSMSsetIsActive}
        />
        <AppraiseSettings
          AppraiseisActive={AppraiseisActive}
          AppraisesetIsActive={AppraisesetIsActive}
        />
      </div>
    </div>
  );
};

const data = [
  {
    label: "ID",
  },
  {
    label: "Serial Number",
  },
  {
    label: "Station",
  },
  {
    label: "Manufacturer",
  },
  {
    label: "Standard",
  },
  {
    label: "Cooling Type",
  },
  {
    label: "Tap changing Gear Type",
  },
  {
    label: "Connection Symbol",
  },
  {
    label: "status",
  },
  {
    label: "Tapped Wing (KV)",
  },
  {
    label: "Steps",
  },
  {
    label: "% Impedance @ 75deg cel",
  },
  {
    label: "Rated Output",
  },
  {
    label: "Norminal Tap",
  },
  {
    label: "Rated Voltage I",
  },
  {
    label: "Rated Voltage II",
  },
  {
    label: "Rated Voltage III",
  },
  {
    label: "Rated Voltage II",
  },
  {
    label: "Rated Power I",
  },
  {
    label: "Rated Power II",
  },
  {
    label: "Rated Power III",
  },
  {
    label: "Construction Start Date",
  },
  {
    label: "Commissioning Date",
  },
  {
    label: "Decommissioning Date",
  },
  {
    label: "Manufacture Date",
  },
  {
    label: "Supply Date",
  },
];
