import React, { useState } from "react";
import { checkboxTypes } from "../../human-resource/setups/data/types/selectsTypes";
import { CheckboxTemlate } from "../../../templates/checkbox";
import { Form } from "antd";
import { formPropsConst } from "../../accessories/constants";
import { SaveButton } from "../../accessories/buttons";

interface stateTypes {
  alertRequester: boolean;
  authoriseFuelDump: boolean;
  recommendVehicleRequest: boolean;
  includeWorkshopName: boolean;
  allowUsersRequestMaintenance: boolean;
  showMaintenanceWorkshopUpdate: boolean;
  smsAlertApprovingOfficer: boolean;
  smsAlertOnlyApprovingOfficer: boolean;
  smsAlertApprovingOfficerAndDriver: boolean;
  smsAlertDriver: boolean;
  allowOnlyVehicleRequests: boolean;
}

const TmsSettings = () => {
  const [states, setStates] = useState<stateTypes>({
    alertRequester: false,
    authoriseFuelDump: false,
    recommendVehicleRequest: false,
    includeWorkshopName: false,
    allowUsersRequestMaintenance: false,
    showMaintenanceWorkshopUpdate: false,
    smsAlertApprovingOfficer: false,
    smsAlertOnlyApprovingOfficer: false,
    smsAlertApprovingOfficerAndDriver: false,
    smsAlertDriver: false,
    allowOnlyVehicleRequests: false,
  });

  const updateStates = (key: string, value: any) => {
    setStates((prev: any) => ({ ...states, [key]: value }));
  };
console.log("states",states)
  const checkboxes: checkboxTypes[] = [
    {
      id: 0,
      label: "Alert Requester when Job is complete through SMS",
      stateName: "alertRequester",
      defaultValue: states.alertRequester,
    },
    {
      id: 1,
      label: "Authorise Fuel Dump before dispensing",
      stateName: "authoriseFuelDump",
      defaultValue: states.authoriseFuelDump,
    },
    {
      id: 2,
      label: "Recommend Vehicle Request Before Approval ",
      stateName: "recommendVehicleRequest",
      defaultValue: states.recommendVehicleRequest,
    },
    {
      id: 3,
      label: "Include Workshop Name In Maintenance Schedule Alerts",
      stateName: "includeWorkshopName",
      defaultValue: states.includeWorkshopName,
    },
    {
      id: 4,
      label:
        "Allow users to Request Maintenance Service for only their Assigned Vehicles",
      stateName: "allowUsersRequestMaintenance",
      defaultValue: states.allowUsersRequestMaintenance,
    },
    {
      id: 5,
      label: "Show Maintenance Workshop Update to Users",
      stateName: "showMaintenanceWorkshopUpdate",
      defaultValue: states.showMaintenanceWorkshopUpdate,
    },
    {
      id: 6,
      label:
        "SMS Alert Approving Officer and Driver of Pending Renewals and Maintenance Schedules",
      stateName: "smsAlertApprovingOfficerAndDriver",
      defaultValue: states.smsAlertApprovingOfficerAndDriver,
    },
    {
      id: 7,
      label:
        "SMS Alert only Approving Officer of Pending Renewals and Maintenance Schedules",
      stateName: "smsAlertApprovingOfficer",
      defaultValue: states.smsAlertApprovingOfficer,
    },
    {
      id: 8,
      label:
        "SMS Alert only Driver of Pending Renewals and Maintenance Schedules",
      stateName: "smsAlertDriver",
      defaultValue: states.smsAlertDriver,
    },
    {
      id: 9,
      label:
        "SMS Alert only Approving Officer of Pending Renewals and Maintenance Schedules",
      stateName: "smsAlertOnlyApprovingOfficer",
      defaultValue: states.smsAlertOnlyApprovingOfficer,
    },
    {
      id: 10,
      label: "Allow only Vehicle Requests",
      stateName: "allowOnlyVehicleRequests",
      defaultValue: states.allowOnlyVehicleRequests,
    },
  ];

return (
    <div className="w-full  ">
        <Form
            labelCol={{ span: 8 }}
            className="w-full-500 px-5 py-4 items-center"
        >
            {checkboxes.map(({ id, label, stateName, defaultValue }) => {
                return (
                    <div
                        style={{ marginTop: (id == 4 || id === 8) && 20 }}
                        className="flex items-center space-x-1"
                    >
                        <CheckboxTemlate
                            span
                            customDisabled={false}
                            withBorders
                            useCallbackFunc
                            setCheckboxOnchange={(e) => {
                                updateStates(stateName!, e);
                            }}
                        />
                        <p className="w-fulll flex items-center">{label}</p>
                    </div>
                );
            })}

            <section className="w-full mt-7">
                <SaveButton disableButton={!Object.values(states).some((value) => value)} />
            </section>
        </Form>
    </div>
);
};

export default TmsSettings;
