
export const transformers_TH = [
  {
    id: 0,
    caption: "STATION",
    dataField: "tfdStationIdfk",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 1,
    caption: "TRANSF. ID",
    dataField: "tfdTransformerId",
    alignment: "left",
    fixed: true,
    width: null
  },
 
  {
    id: 2,
    caption: "SERIAL NO",
    dataField: "tfdSerialNo",
    alignment: "left",
    fixed: true,
    width: null
  },
  {
    id: 3,
    caption: "MANUFACTURER",
    dataField: "tfdManufacturerIdfk",
    alignment: "left",
    fixed: true,
    width: null


  },
  {
    id: 4,
    caption: "STANDARD",
    dataField: "tfdStandard",
    alignment: "left",
    fixed: true,
    width: null,

  },
  {
    id: 5,
    caption: "MANUF. DATE",
    dataField: "tfdManufactureDate",
    alignment: "left",
    fixed: true,
    width: null

  },
  
  {
    id: 6,
    caption: "INSTALL DATE",
    dataField: "tfdInstallationStartDate",
    alignment: "left",
    fixed: true,
    width: null

  },
  
  
  {
    id: 7,
    caption: "STATUS",
    dataField: "tfdStatusIdfk",
    alignment: "left",
    fixed: false,
    width: null
  },
  {
    id: 8,
    caption: "FIT?",
    dataField: "fit",
    alignment: "left",
    fixed: false,
    width: 60

  },
  {
    id: 9,
    caption: "VERIFIED?",
    dataField: "tfdVerified",
    alignment: "left",
    fixed: false,
    width: 60

  },
  {
    id: 10,
    caption: "TEL Nos",
    dataField: "telNos",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 11,
    caption: "INTERCOM",
    dataField: "interCom",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 12,
    caption: "SISR",
    dataField: "sisr",
    alignment: "left",
    fixed: false,
    width: 60

  },
  {
    id: 13,
    caption: "ACTIVE?",
    dataField: "tfdActive",
    alignment: "left",
    fixed: false,
    width: 60

  },
  {
    id: 14,
    caption: "RATED VOLTAGE I (kV)",
    dataField: "tfdRatedVoltageI",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 15,
    caption: "RATED VOLTAGE II (kV)",
    dataField: "tfdRatedVoltageIi",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 16,
    caption:"RATED VOLTAGE III (kV)",
    dataField: "tfdRatedVoltageIii",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 17,
    caption: "RATED POWER I (MVA)",
    dataField: "tfdRatedPowerI",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 18,
    caption:"RATED POWER II (MVA)",
    dataField: "tfdRatedPowerIi",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 19,
    caption: "RATED POWER III (MVA)",
    dataField: "tfdRatedPowerIii",
    alignment: "left",
    fixed: false,
    width: null

  },
 
  {
    id: 20,
    caption: "RATED OUTPUT",
    dataField: "tfdRatedOutput",
    alignment: "left",
    fixed: false,
    width: null

  },

  {
    id: 21,
    caption: "CONN. SYMBOL",
    dataField: "tfdConnectionSymbol",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 22,
    caption: "COOLING TYPE",
    dataField: "tfdCoolingType",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 23,
    caption: "TAP CHANGER TYPE",
    dataField: "tfdTapChangerType",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 24,
    caption: "TAPPED WINDING",
    dataField: "tfdTappedWinding",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 25,
    caption: "TAP CHANGER RANGE",
    dataField: "tfdTapChangerRange",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 26,
    caption: "NOMINAL TAP",
    dataField: "tfdNominalTap",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 27,
    caption: "% IMPED. @ 75 DEG",
    dataField: "tfdPercentImpedanceAt75Deg",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 28,
    caption: "Zh on 100 MVA BASE",
    dataField: "tfdZhAt100MvabaseAt75Deg",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 29,
    caption:  "Zx on 100 MVA BASE",
    dataField: "tfdZxAt100MvabaseAt75Deg",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 30,
    caption:  "Zy on 100 MVA BASE",
    dataField: "tfdZyAt100MvabaseAt75Deg",
    alignment: "left",
    fixed: false,
    width: null

    
  },

  {
    id: 31,
    caption: "RESIST. on 100 MVA",
    dataField: "tfdResistanceAt100MvabaseAt75Deg",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 32,
    caption: "COMMISSION DATE",
    dataField: "tfdCommissionDate",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 33,
    caption: "LOG BOOK EVEN ROW COLOUR?",
    dataField: "lbEvenColour",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 34,
    caption: "DECOMMISSION DATE",
    dataField: "tfdDecommissionDate",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 35,
    caption: "SUPPLY DATE?",
    dataField: "tfdSupplyDate",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 36,
    caption: "SUPPLIER",
    dataField: "tfdSupplierIdfk",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 37,
    caption: "LOCATION",
    dataField: "tfdLocationIdfk",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 38,
    caption: "REMARKS",
    dataField: "tfdRmks",
    alignment: "left",
    fixed: false,
    width: 4000

  },
  


]

export const station_cols = [
  {
    id: 0,
    caption: "Station",
    dataField: "stnName",
    dataType: "string",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 1,
    caption: "Code",
    dataField: "stnCode",
    dataType: "string",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 2,
    caption: "PLCs",
    dataField: "stnPlc",
    dataType: "number",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 3,
    caption: "Contact",
    dataField: "stnContact",
    dataType: "string",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 4,
    caption: "Location",
    dataField: "locShtName",
    dataType: "string",
    alignment: "left",
    fixed: true,
    width: 100,
  },
]
