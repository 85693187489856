import { Modal } from "antd";
import { optionsTypes } from "../../../components/human-resource/setups/data/types/selectsTypes";

interface props {
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
}
export const HelpModal = ({ onCancel, onOk, open }: props) => {
  const howToLogin: optionsTypes[] = [
    { id: 1, data: "Enter Username or Staff Number" },
    { id: 2, data: "Enter your Password" },
    { id: 3, data: "Click on the Login button and" },
    { id: 4, data: "Wait for the main form to launch" },
  ];

  const forgottenPassword: optionsTypes[] = [
    { id: 1, data: "Enter Username or Staff Number" },
    {
      id: 2,
      data: 'Click on the "Forgot your password" text on the login form',
    },
    { id: 3, data: 'Click on the "Yes" button on the pop up window' },
    { id: 4, data: "Wait for a text message on your mobile phone" },
    {
      id: 5,
      data: "Enter the five(5) digits number in the SMS in the password entry box",
    },
    { id: 6, data: "Click on the login button" },
    {
      id: 7,
      data: "Enter your new password and confirm it in the pop up window",
    },
    {
      id: 8,
      data: "Click on the update button after the colours of the boxes have changed to green.",
    },
    { id: 9, data: "Wait for the main form to launch" },
  ];

  const contacts = [
    { name: "Nicholas Morkry", contact: "720 395 or 024 730 0488" },
    { name: "Albert Longdon", contact: "720 352 or 026 423 1331" },
    { name: "Dennis Asamoah", contact: "720 263 or 024 240 5049" },
  ];
  return (
    <>
      <Modal
        transitionName=""
        style={{ left: 400 }}
        width={450}
        centered
        open={open}
        title="Login Help..."
        onOk={onOk}
        onCancel={onCancel}
        okText="Ok"
        cancelText="取消"
        footer=""
      >
        <main className=" text-xs text-gray-700">
          {/* how to login */}
          <p className=" mt-4 text-blue-800">How to Login?</p>
          {howToLogin.map(({ id, data }) => (
            <p key={id}>{`${id}. ${data}`}</p>
          ))}

          {/* forgotten password */}
          <p className=" mt-6 text-blue-800">
            Have you forgotten your password? Reset it!
          </p>
          <p>If you have forgotten your Password, then:</p>
          {forgottenPassword.map(({ id, data }) => (
            <p key={id}>{`${id}. ${data}`}</p>
          ))}

          {/* further assistance */}
          <p className=" mt-6 text-blue-800">
            In case you are facing other challenges, please call any of the
            numbers below for assistance
          </p>
          {contacts.map(({ name, contact }) => (
            <p key={name}>{`${name} -${contact}`}</p>
          ))}
        </main>
      </Modal>
    </>
  );
};
