import { Select, Input } from "antd"
import { DataGrid } from "devextreme-react"
import { Pager, Column, Scrolling } from "devextreme-react/data-grid"
import newIcon from '../../../../assets/new.png'
import refresh from '../../../../assets/refresh.png';
import { attachement_cols } from "../../data/datagrid_cols";


export const Right_Attachments = () => {
   return (
      <div className="w-full h-full">
         <ul className="w-full flex justify-evenly items-center">
            <li className="justify-center items-center"><p>Find</p></li>
            <li className="justify-center items-center"><Select className="w-16 mt-0.5" size="small" /></li>
            <li className="justify-center items-center"><Input className="w-16" size="small" /></li>
            <li className="justify-center items-center"><img src={newIcon} /></li>
            <li className="justify-center items-center"><img className="w-4" src={refresh} /></li>
         </ul>


         <div className=" h-full">
            <DataGrid
               dataSource={[]}
               showBorders={true}
               columnWidth={150}
               showRowLines={true}

               showColumnLines={true}
               //Remember to change
               // height={tableHeight - 310}
               // 
               className='h-full'





            >

               <Pager visible={false} />

               {/* STAFF DETAILS COLUMNS */}
               {/* FIXED COLUMNS */}

               {
                  attachement_cols?.map((data: any) => {
                     return (
                        <Column
                           key={data.id}
                           caption={data.caption}
                           dataField={data.dataField}
                           alignment={"left"}
                           fixed={data.fixed}

                           width={150}
                        />
                     )
                  })
               }

               {/* SCROLLBAR  */}

               <Scrolling columnRenderingMode="virtual" />

            </DataGrid>
            <div className="text-xs text-indigo-500 p-1">
               Done
            </div>
         </div>
      </div>
   )
}