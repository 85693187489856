import { Checkbox, DatePicker, Form, Input, Select, } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { Cancel, NewButton, SaveButton, UpdateButton } from "../../../../../components/accessories/buttons";
import { SelectsTemplate } from "../../../../../templates/select";
import { api_url, navbar_height, saveModalData, toolbar_height, updateModalData } from "../../../../accessories/component_infos";

import axios from "axios";
import dayjs from 'dayjs';
import { TabPanel, Tooltip } from "devextreme-react";
import { Item } from "devextreme-react/accordion";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import close from '../../../../../assets/close.png';
import correct from '../../../../../assets/correct.png';
import submit from '../../../../../assets/forward.png';
import reset from '../../../../../assets/reset.png';
import settingIcon from '../../../../../assets/setting.png';
import { setGeneralValue } from "../../../../../features/generalSlice";
import useFetch from "../../../../../hooks/useFetch";
import Datagrid_template from "../../../../../templates/Datagrid";
import { ModalTemplate } from "../../../../../templates/modal";

import { formPropsConst } from "../../../../accessories/constants";
import { modalTypes } from "../../../setups/data/types/selectsTypes";
import { staff_dependant_TH, staff_dependant_TH_Without_No } from "../data/staff-dependant-data";
const { Option } = Select;

interface props {
   toggled?: boolean

}

interface stateTypes {
   selectedID?: any;
   selectedEmpIdfk?: any;
   selectedTitleIdfk?: any;

   selectedStaffName?: any;
   selectedDependantClass?: any;
   selectedDependantNo?: any;
   selectedRelationship?: any;
   selectedDependantLastName?: string;
   selectedDependantFirstName?: string;
   selectedDependantOtherName?: string;
   selectedBeneficiaryDoB?: any;
   selectedDependantAge?: any;
   selectedWitnessedBy?: any;
   selectedApprovedBy?: any;
   selectedIDType?: any;
   selectedRemarks?: any;
   selectedActive?: boolean;
   selectedIDNumber?: any;
   selectedDependantAddress?: string;
   selectedStaffAddress?: string;

   selectedApproved?: boolean;
   selectedDisapproved?: boolean;
   selectedApprovedOn?: any;
   selectedEditedBy?: any;
   selectedEditedDate?: any;

   showUpdateButton?: any;
   showCancelButton?: any;
   rowData?: any;
   validationModalState: { state: boolean, title: string, message: string };
   confirmationModalState: { state: boolean, title: string, message: string };

   approveConfirmationModalState: { state: boolean, title: string, message: string };
   rejectConfirmationModalState: { state: boolean, title: string, message: string };

   disabled?: boolean;
}

const StaffDependantDetails = ({ toggled }: props) => {
   const news_flash_height = useSelector((state: RootState) => state.general.newsflashHeight);
   const heights_out = navbar_height + news_flash_height + toolbar_height;

   //varying form height
   const form_height = 490;

   //Table Height
   const pageHeight = window.innerHeight - navbar_height; //height without navbar
   const [tableHeight, settableHeight] = useState(window.innerHeight - (heights_out + 70)); //height without navbar and toolbar
   const [table_form_Height, settable_form_Height] = useState(window.innerHeight - (heights_out)); //height without navbar and toolbar and form

   const [showTable, setShowTable] = useState(true);
   const [refresh, setRefresh] = useState(false)

   const dateFormat = " DD MMM YYYY HH: mm:ss";

   // Get all the form and table data (ie lookups)
   const [staff_name_data, staff_name_error, staff_name_loads] = useFetch(`api/HrDependantDetails/getStaffNameLkup`);
   const [dependant_class_data, dependant_class_error, dependant_class_loads] = useFetch(`api/HrDependantDetails/getDependantClassLkup`);
   const [dependant_no_data, dependant_no_error, dependant_no_loads] = useFetch(`api/HrDependantDetails/getDependantClassLkup`);
   const [relationship_data, relationship_error, relationship_loads] = useFetch(`api/HrDependantDetails/getRelationshipLkup`);
   const [dependantLastName_data, dependantLastName_error, dependantLastName_loads] = useFetch(`api/HrDependantDetails/getDependantLastNameLkup`);
   // if(dependantLastName_data){
   //    console.log(dependantLastName_data)
   // }
   //const [witnessedBy_data, witnessedBy_error, witnessedBy_loads] = useFetch(`api/HrDependantDetails/getApprovedByLkup`);
   const [approvedBy_data, approvedBy_error, approvedBy_loads] = useFetch(`api/HrDependantDetails/getApprovedByLkup`);
   const [IDType_data, IDType_error, IDType_loads] = useFetch(`api/HrDependantDetails/getIDTypesLkup`);
   const [dependantDetail_data, dependantDetail_error, dependantDetail_loads] = useFetch(`api/HrDependantDetails/getDependantDetailsGv`, refresh);
   const initialState = {
      selectedID: { name: '', id: '' },
      selectedEmpIdfk: { name: '', id: '1' },
      selectedTitleIdfk: { name: '', id: '1' },

      selectedStaffName: { name: '', id: '' },
      selectedDependantClass: { name: '', id: '1' },
      selectedDependantNo: '',
      selectedRelationship: { name: '', id: '1' },
      selectedDependantLastName: '',
      selectedDependantFirstName: '',
      selectedDependantOtherName: '',
      selectedBeneficiaryDoB: '',
      selectedDependantAge: 0.00,
      selectedWitnessedBy: { name: '', id: '0' },
      selectedApprovedBy: { name: '', id: '1' },
      selectedIDType: { name: '', id: '' },
      selectedRemarks: '',
      selectedActive: false,
      selectedIDNumber: '',
      selectedDependantAddress: '',
      selectedStaffAddress: '',

      selectedApproved: true,
      selectedDisapproved: false,
      selectedApprovedOn: '0000-00-00T00:00:00',
      selectedEditedBy: { name: '', id: '1' },
      selectedEditedDate: '0000-00-00T00:00:00',

      showUpdateButton: false,
      showCancelButton: false,
      rowData: {},
      validationModalState: { state: false, title: '', message: '' },
      confirmationModalState: { state: false, title: '', message: '' },

      approveConfirmationModalState: { state: false, title: '', message: '' },
      rejectConfirmationModalState: { state: false, title: '', message: '' },

      disabled: true
   }
   //Initialize values
   const [states, setCurrentState] = useState<stateTypes>(initialState);



   //datagrid methods
   const datagridRowClick = (data: any) => {
      console.log(data)
      //set states
      setCurrentState({
         ...states,
         selectedID: data.dpdIdpk,
         selectedStaffName: { name: data.empName, id: data.empIdpk },
         selectedDependantClass: { name: data.dclName, id: data.dclIdpk },
         selectedDependantNo: data.dpdDependantNumber,
         selectedDependantLastName: data.dpdLastName,
         // there should be one for the title
         selectedDependantFirstName: data.dpdFirstName,
         selectedDependantOtherName: data.dpdOtherNames,
         selectedBeneficiaryDoB: data.dpdDoB,
         selectedDependantAge: data.dpdAge,
         selectedRelationship: { name: data.rlsName, id: data.rlsIdpk },
         selectedRemarks: data.dpdRmks,
         selectedActive: data.dpdActive,
         selectedWitnessedBy: { name: data.witnessedByEmpName15, id: data.witnessedByEmpId },
         selectedApprovedBy: { name: data.approvedByEmpName, id: data.approvedByEmpId },
         selectedIDType: { name: data.idtName, id: data.idtIdpk },
         selectedIDNumber: data.dpdIdnumber,
         selectedDependantAddress: data.dpdAddress,
         selectedStaffAddress: data.empAddress,


      });

      // //deactivate form fields
      // runGenerator("deactivateFields", true);

      // //deactivate update & cancel button
      // runGenerator("showUpdateButton", false);
      // runGenerator("showCancelButton", false);
      // runGenerator("rowData", data);
   }

   const datagridRowDoubleClick = () => {
      runGenerator("deactivateFields", false);
      runGenerator("showUpdateButton", true);
      runGenerator("showCancelButton", true);
      runGenerator("disabled", false)
   }

   // The runGenerator() sets the currentState
   function runGenerator(data: any, value: any) {
      console.log(value.empName);
      console.log(`${data}--- ${value}`);
      setCurrentState((currentState: any) => ({ ...currentState, [data]: value }));
      console.log(states.rowData.empName);
   }

   const modals: modalTypes[] = [
      //warning
      { disableCancel: true, icon: 'warning', open: states.validationModalState.state, okHandler: () => runGenerator('validationModalState', { ...states.validationModalState, state: false }), cancelHandler: () => runGenerator('validationModalState', { ...states.validationModalState, state: false }), message: states.validationModalState.message, okText: 'Ok', title: states.validationModalState.title, cancelText: 'No, cancel' },

      //confirmation
      { disableCancel: false, icon: 'question', open: states.confirmationModalState.state, okHandler: () => postData(), cancelHandler: () => runGenerator('confirmationModalState', { ...states.confirmationModalState, state: false }), message: states.confirmationModalState.message, okText: 'Yes Save', title: states.confirmationModalState.title, cancelText: 'No, cancel' },

      // Approve Confirmation
      { disableCancel: false, icon: 'question', open: states.approveConfirmationModalState.state, okHandler: () => ApproveDependant(), cancelHandler: () => runGenerator('approveConfirmationModalState', { ...states.approveConfirmationModalState, state: false }), message: states.approveConfirmationModalState.message, okText: 'Yes Save', title: states.approveConfirmationModalState.title, cancelText: 'No, cancel' },

      // Reject Confirmation
      { disableCancel: false, icon: 'warning', open: states.rejectConfirmationModalState.state, okHandler: () => RejectDependant(), cancelHandler: () => runGenerator('rejectConfirmationModalState', { ...states.rejectConfirmationModalState, state: false }), message: states.rejectConfirmationModalState.message, okText: 'Yes Save', title: states.rejectConfirmationModalState.title, cancelText: 'No, cancel' },
   ]


   async function validateForms(data: any) {
      if (states.selectedStaffName == '') {
         setModalData({
            ...states,
            info: { message: 'Please select a staff', title: 'Invalid Staff', okText: 'Ok', cancelText: '' },
            icon: 'warning',
            open: true,
            disableCancel: true
         })
      }

      else if (states.selectedDependantClass == '') {
         setModalData({
            ...states,
            info: { message: 'Please select a dependant class', title: 'Invalid Dependant Class', okText: 'Ok', cancelText: '' },
            icon: 'warning',
            open: true,
            disableCancel: true
         })
      }

      else if (states.selectedDependantNo == '') {
         setModalData({
            ...states,
            info: { message: 'Please enter dependant number', title: 'Invalid Dependant Number', okText: 'Ok', cancelText: '' },
            icon: 'warning',
            open: true,
            disableCancel: true
         })
      }

      else if (states.selectedDependantLastName == '') {
         setModalData({
            ...states,
            info: { message: 'Please enter last name', title: 'Invalid Last Name', okText: 'Ok', cancelText: '' },
            icon: 'warning',
            open: true,
            disableCancel: true
         })
      }

      else if (states.selectedDependantFirstName == '') {
         setModalData({
            ...states,
            info: { message: 'Please enter first name', title: 'Invalid First Name', okText: 'Ok', cancelText: '' },
            icon: 'warning',
            open: true,
            disableCancel: true
         })
      }

      else if (states.selectedBeneficiaryDoB == '') {
         setModalData({
            ...states,
            info: { message: 'Please enter Date of Birth', title: 'Invalid Date of Birth', okText: 'Ok', cancelText: '' },
            icon: 'warning',
            open: true,
            disableCancel: true
         })
      }

      else if (states.selectedRelationship == '') {
         setModalData({
            ...states,
            info: { message: 'Please select relationship with dependant', title: 'Invalid Relationship Entry', okText: 'Ok', cancelText: '' },
            icon: 'warning',
            open: true,
            disableCancel: true
         })
      }

      else if (states.selectedApprovedBy == '') {
         setModalData({
            ...states,
            info: { message: 'Please select approver', title: 'Invalid Approver', okText: 'Ok', cancelText: '' },
            icon: 'warning',
            open: true,
            disableCancel: true
         })
      }

      else if (states.selectedIDType == '') {
         setModalData({
            ...states,
            info: { message: 'Please select ID Type', title: 'Invalid ID Type', okText: 'Ok', cancelText: '' },
            icon: 'warning',
            open: true,
            disableCancel: true
         })
      }

      else if (states.selectedIDNumber == '') {
         setModalData({
            ...states,
            info: { message: 'Please enter ID Number', title: 'Invalid ID Number', okText: 'Ok', cancelText: '' },
            icon: 'warning',
            open: true,
            disableCancel: false
         })
      }

      else if (states.selectedDependantAddress == '') {
         setModalData({
            ...states,
            info: { message: 'Please enter Dependant\'s Address', title: 'Invalid  Dependant Address', okText: 'Ok', cancelText: '' },
            icon: 'warning',
            open: true,
            disableCancel: false
         })
      }

      // else if(states.selectedStaffAddress== ''){
      //    setModalData({...states,  
      //       info: {message: 'Please enter Staff Address', title: 'Invalid  Staff Address', okText: 'Ok',cancelText: ''},
      //       icon : 'warning',
      //       open: true,
      //       disableCancel: true
      //    } )
      // }

      else {
         data === 'update' ? setModalData({
            ...states,
            info: updateModalData,
            icon: 'question',
            open: true,
            disableCancel: false
         }) :

            setModalData({
               ...states,
               info: saveModalData,
               icon: 'question',
               open: true,
               disableCancel: false
            })
      }
   }

   useEffect(() => {
      console.log(`inside ${toggled}`)


      //   document.querySelector(".output").textContent
      //           = elemHeight + "px";
      function handleResize() {
         var divElement = document.getElementById("details");

         var elemHeight = divElement!.offsetHeight;
         // alert(elemHeight)

      }

      window.addEventListener('resize', handleResize)
   }, [])

   const [modalData, setModalData] = useState<any>({
      info: saveModalData,
      icon: 'warning',
      open: false,
      disableCancel: false
   })



   const postData = async () => {
      console.log({
         "dpdEmpIdfk": states.selectedEmpIdfk.id,
         "dpdTitleIdfk": states.selectedTitleIdfk.id,

         "dpdDoB": "2023-03-03T09:27:54.350Z",
         "dpdIdtypeIdfk": states.selectedIDType.id,
         "dpdDependantClassIdfk": states.selectedDependantClass.id,
         "dpdIdnumber": states.selectedIDNumber,
         "dpdDependantNumber": states.selectedDependantNo,
         "dpdAddress": states.selectedDependantAddress,
         "dpdFirstName": states.selectedDependantFirstName,
         "dpdLastName": states.selectedDependantLastName,
         "dpdOtherNames": states.selectedDependantOtherName,
         "dpdRelationshipIdfk": states.selectedRelationship.id,
         "dpdWitnessedByEmpIdfk": states.selectedWitnessedBy.id,
         "dpdActive": states.selectedActive,
         "dpdRmks": states.selectedRemarks,
         "dpdCreatedBy": 1
      });

      //post data
      try {
         runGenerator('confirmationModalState', { state: false, title: '', message: '' })
         const res = await axios.post(`${api_url}/api/HrDependantDetails/create`, {

            "dpdEmpIdfk": states.selectedEmpIdfk.id,
            "dpdTitleIdfk": states.selectedTitleIdfk.id,

            "dpdDoB": "2023-03-03T09:27:54.350Z",
            "dpdIdtypeIdfk": states.selectedIDType.id,
            "dpdDependantClassIdfk": states.selectedDependantClass.id,
            "dpdIdnumber": states.selectedIDNumber,
            "dpdDependantNumber": states.selectedDependantNo,
            "dpdAddress": states.selectedDependantAddress,
            "dpdFirstName": states.selectedDependantFirstName,
            "dpdLastName": states.selectedDependantLastName,
            "dpdOtherNames": states.selectedDependantOtherName,
            "dpdRelationshipIdfk": states.selectedRelationship.id,
            "dpdWitnessedByEmpIdfk": states.selectedWitnessedBy.id,
            "dpdActive": states.selectedActive,
            "dpdRmks": states.selectedRemarks,
            "dpdCreatedBy": 1

            // "dpdEmpIdfk": states.selectedEmpIdfk.id,
            // "dpdTitleIdfk": states.selectedTitleIdfk.id,

            // "dpdDoB": "2023-03-03T09:27:54.350Z",
            // "dpdIdtypeIdfk": states.selectedIDType.id,
            // "dpdDependantClassIdfk": states.selectedDependantClass.id,
            // "dpdIdnumber": states.selectedIDNumber,
            // "dpdDependantNumber": states.selectedDependantNo,
            // "dpdAddress": states.selectedDependantAddress,
            // "dpdFirstName": states.selectedDependantFirstName,
            // "dpdLastName": states.selectedDependantLastName,
            // "dpdOtherNames": states.selectedDependantOtherName,
            // "dpdRelationshipIdfk": states.selectedRelationship.id,
            // "dpdWitnessedByEmpIdfk": states.selectedWitnessedBy.id,
            // "dpdActive": states.selectedActive,
            // "dpdRmks": states.selectedRemarks,
            // "dpdCreatedBy": 1
         });
         console.log('done')

         setRefresh(!refresh);
         setModalData({
            ...states,

            open: false,

         })
         setModalData({
            ...states,
            info: {
               message: "Record saved successfully",
               title: 'Success',
               okText: 'Ok',
               cancelText: ''
            },
            icon: 'success',
            open: true,
            disableCancel: true
         })

      } catch (error: any) {
         console.log(error);

         //set states for db errors modal
         // setErrState(true);
         //seterrTitle(error.response ? error.response.data.message : 'Server Error');
         //seterrMessage(error.response ? error.response.data.errors[0] : 'Error connecting to server. Please contact system administrator');
      }
   }


   const updateData = async () => {
      console.log(states);

      //post data
      try {
         runGenerator('confirmationModalState', { state: false, title: '', message: '' })
         const res = await axios.put(`${api_url}/api/HrDependantDetails/save/update/${states.selectedID}`, {

            "dpdIdpk": states.selectedID.id,
            "dpdEmpIdfk": states.selectedEmpIdfk.id,
            "dpdTitleIdfk": states.selectedTitleIdfk.id,
            "dpdDoB": dayjs(states.selectedBeneficiaryDoB),
            "dpdIdtypeIdfk": states.selectedIDType.id,
            "dpdDependantClassIdfk": states.selectedDependantClass.id,
            "dpdIdnumber": states.selectedIDNumber,
            "dpdDependantNumber": states.selectedDependantNo,
            "dpdAddress": states.selectedDependantAddress,
            "dpdFirstName": states.selectedDependantFirstName,
            "dpdLastName": states.selectedDependantLastName,
            "dpdOtherNames": states.selectedDependantOtherName,
            "dpdRelationshipIdfk": states.selectedRelationship.id,
            "dpdWitnessedByEmpIdfk": states.selectedWitnessedBy.id,
            "dpdActive": states.selectedActive,
            "dpdRmks": states.selectedRemarks,
            "dpdEditedBy": 1,
            "dpdEditedDate": "2023-02-24T12:03:33.352Z"
         })


         setRefresh(!refresh);
         setModalData({
            ...states,

            open: false,

         })
         setModalData({
            ...states,
            info: {
               message: "Record updated successfully",
               title: 'Success',
               okText: 'Ok',
               cancelText: ''
            },
            icon: 'success',
            open: true,
            disableCancel: true
         })

      } catch (error: any) {
         console.log(error);

         //set states for db errors modal
         // setErrState(true);
         //seterrTitle(error.response ? error.response.data.message : 'Server Error');
         //seterrMessage(error.response ? error.response.data.errors[0] : 'Error connecting to server. Please contact system administrator');
      }
   }


   const ConfirmApproveDependant = () => {
      console.log("hey");
      runGenerator('approveConfirmationModalState', { state: true, title: 'Approve', message: 'Are you sure you wanna approve the dependant?' })

   }

   const ApproveDependant = async () => {
      try {
         const res = await axios.put(`${api_url}/api/HrDependantDetails/ApproveBtnUpdateApp`, {
            "dpdIdpk": states.selectedID.id,
            "dpdApproved": states.selectedApproved,
            "dpdDisapproved": states.selectedDisapproved,
            "dpdApprovedByEmpIdfk": states.selectedApprovedBy.id,
            "dpdApprovedOn": "2023-03-07T12:28:20.059Z",
            "dpdEditedBy": 1,
            "dpdEditedDate": "2023-02-24T12:03:33.352Z"
         })
         setRefresh(!refresh);
         setModalData({
            ...states,
            info: {
               message: "Record approved successfully",
               title: 'Success',
               okText: 'Ok',
               cancelText: ''
            },
            icon: 'success',
            open: true,
            disableCancel: true
         })
      } catch (error: any) {
         console.log(error);
      }
   }

   const RejectDependant = async () => {
      try {
         runGenerator('confirmationModalState', {})
         const res = await axios.put(`${api_url}/api/HrDependantDetails/RejectBtnUpdate`, {
            "dpdIdpk": states.selectedID.id,
            "dpdApproved": false,
            "dpdDisapproved": true,
            "dpdApprovedByEmpIdfk": states.selectedApprovedBy.id,
            "dpdApprovedOn": "2023-03-07T12:28:20.059Z",
            "dpdEditedBy": 1,
            "dpdEditedDate": "2023-02-24T12:03:33.352Z"
         })
         setRefresh(!refresh);
         setModalData({
            ...states,
            info: {
               message: "Record rejected",
               title: 'Rejected',
               okText: 'Ok',
               cancelText: ''
            },
            icon: 'success',
            open: true,
            disableCancel: true
         })
      } catch (error: any) {
         console.log(error);
      }
   }

   const disabled = useSelector((state: RootState) => state.general.formDisabled);
   const hideStaffNo = useSelector((state: RootState) => state.general.hideStaffNo);
   const updateMode = useSelector((state: RootState) => state.general.updateMode);
   const dispatch = useDispatch();


   useEffect(() => {
      //New Mode
      if (!disabled && !updateMode) {
         runGenerator("disabled", false);
         runGenerator("showCancelButton", true);
         runGenerator("rowData", {});

      }
      //Cancelled Mode
      if (disabled) {
         runGenerator("showUpdateButton", false);
         runGenerator("showCancelButton", false);
         runGenerator("disabled", true);
         runGenerator("rowData", {});
      }
      //Update Mode
      if (updateMode) {
         runGenerator("deactivateFields", false);
         runGenerator("showUpdateButton", true);
         runGenerator("showCancelButton", true);
         runGenerator("disabled", false)
      }

   }, [disabled, updateMode])


   return (
      <>
         <style
            dangerouslySetInnerHTML={{
               __html: `
           input[type="text"]:disabled {
            background: #fffbeb;
            height:22px
          }  

          
          
          .dx-datagrid-header-panel .dx-toolbar {
            margin-bottom: 1.5px;
        }

          .filterTodos .dx-datagrid-headers{
              margin-top: 10px;
          }

            `
            }}
         />

         {modals.map(({ open, icon, disableCancel, cancelHandler, cancelText, message, okHandler, okText, title }) =>
            <ModalTemplate
               disableCancel={disableCancel}
               icon={icon} cancelText={cancelText}
               cancelHandler={cancelHandler}
               open={open}
               okHandler={okHandler}
               message={message} okText={okText} title={title} />
         )}

         <div className="flex w-full"> <div className="" style={{ width: '79.5%' }}> {toggled &&

            <div id="details" className="w-full   border rounded-sm">
               <div className="text-xs bg-slate-100 rounded-t-sm py-1 px-2">Dependant Details</div>
               {/* The form */}
               <div className="pt-2 flex ">
                  <Form {...formPropsConst} labelCol={{ span: 10 }} wrapperCol={undefined}
                     className="ml-2 " style={{ width: '450px' }}>
                     <SelectsTemplate
                        selectedValue={(e) => {
                           console.log(e);

                           setCurrentState({ ...states, selectedStaffName: { name: e.empName, id: e.empIdpk } })
                        }}
                        cusWidth={'700px'}
                        useCusWidth={true}
                        disabledCustomWidth={true}
                        idexpr="empIdpk"
                        dataexp="empName"
                        disabled={states.disabled}
                        label="Staff Name"
                        useCallFunc={true}
                        placeHolder={states.selectedStaffName.name}
                        options={staff_name_data}

                     />

                     <SelectsTemplate
                        selectedValue={(e) => {
                           setCurrentState({ ...states, selectedDependantClass: { name: e.dclName, id: e.dclIdpk } })
                        }}
                        disabled={states.disabled}
                        label="Dependant Class"
                        options={dependant_class_data}
                        useCallFunc={true}
                        placeHolder={states.selectedDependantClass.name}
                        cusWidth={'600px'}
                        useCusWidth={true}
                        disabledCustomWidth={true}
                     />
                     <Form.Item label={<p className='text-xs'>{'Dependant Number'}</p>} style={{ marginBottom: 0, width: 'calc(100%)' }}>
                        <Form.Item
                           name="Dependant Number"

                           style={{ display: 'inline-block', width: '236px', marginBottom: '0.5%' }}
                        >
                           <div className="flex flex-row items-center">

                              <Input disabled={states.disabled} value={states.selectedDependantNo} size="small" placeholder="" onChange={(value) => setCurrentState({ ...states, ['selectedDependantNo']: value.target.value })} />

                           </div>
                        </Form.Item>
                        <Form.Item
                           name="month"
                           labelCol={{ span: 10 }}

                           style={{ display: 'inline-block', width: '20px', marginLeft: '3px' }}
                        >
                           <span style={{ height: 22.5, marginBottom: 2, borderWidth: 1, width: 24 }} className='h-full w-4  flex justify-center hover:cursor-pointer hover:bg-slate-50 border-gray-300 rounded' >
                              <img className='w-full h-full flex items-center justify-center p-0.5' src={settingIcon} alt="reset" />
                           </span>

                        </Form.Item>
                     </Form.Item>

                     <Form.Item label={<p className='text-xs'>{'Dependant Last Name'}</p>} style={{ marginBottom: 0, width: 'calc(100%)' }}>
                        <Form.Item
                           style={{ display: 'inline-block', width: '150px' }}
                        >
                           <Input onChange={(e) => {
                              setCurrentState({
                                 ...states, selectedDependantLastName: e.target.value
                              })
                           }} disabled={states.disabled} size="small" value={states.selectedDependantLastName} placeholder="" />
                        </Form.Item>

                        <Form.Item
                           name="month"
                           labelCol={{ span: 10 }}

                           style={{ display: 'inline-block', width: '80px', marginLeft: '2.5%' }}
                        >
                           <Select
                              className={'mb-0'}
                              value={states.selectedTitleIdfk.name}
                              onChange={(e) => {
                                 setCurrentState({ ...states, ['selectedTitleIdfk']: { name: JSON.parse(e)['titName'], id: JSON.parse(e)['titIdpk'] } })
                              }}
                           >
                              {dependantLastName_data === null ? [].map((option: any, index: number) => {
                                 return (
                                    <option key={index} value={JSON.stringify(option)} >{option['titName']}</option>
                                 )
                              }) : dependantLastName_data.map((option: any, index: number) => {
                                 return (
                                    <option key={index} value={JSON.stringify(option)} >{option['titName']}</option>
                                 )
                              })}
                           </Select>

                        </Form.Item>

                        <Form.Item
                           name="month"
                           labelCol={{ span: 10 }}

                           style={{ display: 'inline-block', width: 'calc(2% )', marginLeft: '1.5%' }}
                        >
                           <span

                              style={{ height: 22.5, marginBottom: 2, borderWidth: 1, width: 24 }}
                              className='flex justify-center hover:cursor-pointer border-gray-300 rounded'>
                              <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src={reset} alt="reset" />
                           </span>

                        </Form.Item>

                     </Form.Item>






                     <Form.Item label={<p className='text-xs'>{'Dependant First Name'}</p>} style={{ marginBottom: 0, width: 'calc(100%)' }}>
                        <Form.Item
                           name="dependantFirstName"

                           style={{ display: 'inline-block', width: '237px', marginBottom: '0.5%' }}
                        > <div className="flex flex-row items-center">

                              <Input onChange={(e) => {
                                 setCurrentState({
                                    ...states, selectedDependantFirstName: e.target.value
                                 })
                              }} value={states.selectedDependantFirstName} size="small" placeholder="" disabled={states.disabled} />


                           </div>
                        </Form.Item>
                        <Form.Item
                           name="month"
                           labelCol={{ span: 10 }}

                           style={{ display: 'inline-block', width: 'calc(2% )', marginLeft: '1.5%' }}
                        ></Form.Item>
                     </Form.Item>
                     <Form.Item label={<p className='text-xs'>{'Dependant Other Names'}</p>} style={{ marginBottom: 0, width: 'calc(100%)' }}>
                        <Form.Item
                           name="dependantOtherName"

                           style={{ display: 'inline-block', width: '237px', marginBottom: '0.5%' }}
                        > <div className="flex flex-row items-center">

                              <Input size="small" onChange={(e) => {
                                 setCurrentState({
                                    ...states, selectedDependantOtherName: e.target.value
                                 })
                              }} value={states.selectedDependantOtherName} placeholder="" disabled={states.disabled} />


                           </div>
                        </Form.Item>

                     </Form.Item>
                     <Form.Item label={<p className='text-xs'>{'Dependant DoB'}</p>} style={{ marginBottom: 0 }}>
                        <Form.Item
                           name="receivedBy"

                           style={{ display: 'inline-block', width: '187px', marginTop: '' }}
                        > <div className="flex flex-row items-center">
                              <DatePicker onChange={(value) => { setCurrentState({ ...states, ['selectedBeneficiaryDoB']: dayjs(value) }) }} value={states.selectedBeneficiaryDoB === '' ? null : dayjs(states.selectedBeneficiaryDoB)} size="small" placeholder="Start Date" style={{ width: '93.5%' }} format={dateFormat} disabled={states.disabled} />
                           </div>
                        </Form.Item>
                        <Form.Item
                           name="month"
                           labelCol={{ span: 10 }}

                           style={{ display: 'inline-block', width: 'calc(2% )', }}
                        >
                           <span
                              className="text-indigo-500"
                           >
                              {states.selectedDependantAge.toFixed(2)}yrs
                           </span>

                        </Form.Item>
                     </Form.Item>

                     <SelectsTemplate label="Relationship"
                        selectedValue={(e) => {
                           setCurrentState({ ...states, selectedRelationship: { name: e.rlsName, id: e.rlsIdpk } })
                        }}
                        idexpr="rlsIdpk"
                        dataexp="rlsName"
                        options={relationship_data}
                        disabled={states.disabled}
                        useCallFunc={true}
                        placeHolder={states.selectedRelationship.name}
                        cusWidth={'600px'}
                        useCusWidth={true}
                        disabledCustomWidth={true}
                     />




                  </Form>


                  {/* Second */}
                  <Form  {...formPropsConst} labelCol={{ span: 8 }} wrapperCol={{ span: 20 }}
                     style={{ width: 'calc(100% - 470px)', marginLeft: '20px' }}
                  >

                     <SelectsTemplate
                        // selectedValue={(e)=>{
                        //    setCurrentState({...states, selectedWitnessedBy:{name:e.rlsName, id: e.rlsIdpk}})}} 
                        label="Witnessed By"
                        dataexp="witnessedByEmpName15"
                        idexpr="witnessedByEmpId"
                        options={[]}
                        disabled={states.disabled}
                        useCallFunc={true}
                        placeHolder={states.selectedWitnessedBy.name}
                        cusWidth={'250px'}
                        useCusWidth={true}
                        disabledCustomWidth={true}
                     />
                     <SelectsTemplate
                        selectedValue={(e) => {
                           setCurrentState({ ...states, selectedApprovedBy: { name: e.empName, id: e.empIdpk } })
                        }}
                        label="Approved By"
                        idexpr="empIdpk"
                        dataexp="empName"
                        options={approvedBy_data}
                        disabled={states.disabled}
                        useCallFunc={true}
                        placeHolder={states.selectedApprovedBy.name}
                        cusWidth={'250px'}
                        useCusWidth={true}
                        disabledCustomWidth={true}
                     />

                     <SelectsTemplate
                        selectedValue={(e) => {
                           setCurrentState({ ...states, selectedIDType: { name: e.idtName, id: e.idtIdpk } })
                        }}
                        idexpr="idtIdpk"
                        dataexp="idtName"
                        label="ID Type"
                        options={IDType_data}
                        disabled={states.disabled}
                        useCallFunc={true}
                        placeHolder={states.selectedIDType.name}
                        cusWidth={'250px'}
                        useCusWidth={true}
                        disabledCustomWidth={true}
                     />
                     <Form.Item label={<p className='text-xs'>{'ID Number'}</p>} style={{ marginBottom: 0, width: 'calc(100%)' }}>
                        <Form.Item
                           name="issuedBy"

                           style={{ display: 'inline-block', width: '250px', }}
                        >
                           <div className="flex flex-row items-center">

                              <Input size="small"
                                 value={states.selectedIDNumber}
                                 onChange={(value) => setCurrentState({ ...states, ['selectedIDNumber']: value.target.value })}
                                 placeholder={states.selectedIDNumber.name}
                                 disabled={states.disabled}
                              />
                           </div>
                        </Form.Item>
                     </Form.Item>
                     <Form.Item label={<p className='text-xs'>{'Dependant Address'}</p>} style={{ marginBottom: 0, marginTop: '' }}>

                        <div className="flex">
                           <TextArea onChange={(e) => {
                              setCurrentState({
                                 ...states, selectedDependantAddress: e.target.value
                              })
                           }} value={states.selectedDependantAddress} style={{ resize: 'none', height: '50px', }} className='o mr-2 overflow-y-scroll resize-none' rows={3} disabled={states.disabled} />

                        </div>


                     </Form.Item>
                     <Form.Item label={<p className='text-xs'>{'Staff Address'}</p>} style={{ marginBottom: 0, marginTop: '' }}>

                        <div className="flex">
                           <TextArea onChange={(e) => {
                              setCurrentState({
                                 ...states, selectedStaffAddress: e.target.value
                              })
                           }} value={states.selectedStaffAddress} style={{ resize: 'none', height: '50px', }} className='o mr-2 overflow-y-scroll resize-none' rows={3} disabled={states.disabled} />

                        </div>


                     </Form.Item>
                  </Form>

                  {/* Third
                  <Form name="complex-form" labelCol={{ span: 8 }} wrapperCol={{ span: 20 }}
                     size={"small"} className="ml-10 " style={{ width: '25%' }}
                  >

                     <div className="h-24 border w-24 text-xs flex justify-center items-center ">
                        <p> No Image Data</p>
                     </div>

                  </Form> */}





               </div>
               <Form {...formPropsConst} wrapperCol={undefined} labelCol={undefined} style={{ width: 'calc(100%)', }} >
                  <Form.Item label={<p className='text-xs'>{'Remarks'}</p>} style={{ marginBottom: 0, paddingLeft: '137px' }} >

                     <div className="flex">
                        <TextArea value={states.selectedRemarks} onChange={(e) => {
                           console.log(e.target.value); setCurrentState({
                              ...states, selectedRemarks: e.target.value
                           })
                        }} style={{ resize: 'none', maxHeight: '40px', minHeight: '40px', height: '40px', width: '' }} className='o mr-2 overflow-y-scroll resize-none' rows={3} disabled={states.disabled} />

                     </div>


                  </Form.Item>
                  <Form.Item label={<p className='text-xs'>{'Active'}</p>} style={{ marginBottom: '5px', paddingLeft: '150px' }} >

                     <div className="">
                        <div className=' flex pr-2  justify-between' style={{ marginBottom: '1px' }} >


                           <div style={{ borderWidth: '1px', height: '25px' }} id="status" className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 text-gray-600 rounded flex items-center">
                              <Checkbox checked={states.selectedActive} onChange={(e) => {
                                 setCurrentState({
                                    ...states, selectedActive: e.target.checked
                                 })
                              }} />
                           </div>
                           <div className="flex">
                              <li onClick={ConfirmApproveDependant} id="approve" style={{ borderWidth: '1px' }} className="hover:cursor-pointer ml-40 mr-1 border-slate-200 text-xs px-2    text-gray-600 rounded flex items-center" >
                                 <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img className="mr-1" src={correct} /> Approve</span>
                                 <Tooltip
                                    target="#approve"
                                    showEvent="dxhoverstart"
                                    hideEvent="dxhoverend"
                                    hideOnOutsideClick={true}
                                    className="flex flex-col justify-left text-left"
                                 >
                                    <p className="text-left">Witness/Approve selected record</p>

                                 </Tooltip>
                              </li>
                              <li onClick={RejectDependant} id="reject" style={{ borderWidth: '1px' }} className="hover:cursor-pointer ml-2 mr-1 border-slate-200 text-xs px-2    text-gray-600 rounded flex items-center" >
                                 <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img className="mr-1" src={close} /> Reject</span>

                                 <Tooltip
                                    target="#reject"
                                    showEvent="dxhoverstart"
                                    hideEvent="dxhoverend"
                                    hideOnOutsideClick={true}
                                    className="flex flex-col justify-left text-left"
                                 >
                                    <p className="text-left">Disapprove selected record</p>

                                 </Tooltip>
                              </li>

                           </div>
                           <div className="flex">

                              {/* <li id="newRecord" style={{ borderWidth: '1px' }} className="hover:cursor-pointer  mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center" >
      <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img className="pr-1 " src={saveSmall} />Save </span>

   </li>
   <li id="newRecord" style={{ borderWidth: '1px' }} className="hover:cursor-pointer  mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center" >
      <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img className="pr-1 " src={newfile} />New </span>

   </li>
   <li id="newRecord" style={{ borderWidth: '1px' }} className="hover:cursor-pointer   border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center" >
      <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img className="pr-1 " src={submit} />Submit </span>

   </li> */}

                              {states.showUpdateButton === true ? (
                                 <UpdateButton
                                    handleUpdate={() => {
                                       // validate("put");
                                       validateForms('update')
                                    }}
                                 />
                              ) : (
                                 <SaveButton
                                    useCallbackFunc
                                    handleSave={() => {
                                       validateForms('post')
                                    }
                                       // validatePost

                                    }
                                 />
                              )}

                              {states.showCancelButton === true ? (
                                 <Cancel
                                    useCallbackFunc={true}
                                    cancel_button_toggler={() => {
                                       runGenerator("showUpdateButton", false);
                                       runGenerator("showCancelButton", false);
                                       runGenerator("disabled", true);
                                       dispatch(setGeneralValue({ expr: 'formDisabled', value: true }));
                                       dispatch(setGeneralValue({ expr: 'updateMode', value: false }));
                                    }}
                                 />
                              ) : (
                                 <NewButton
                                    useCallbackFunc={true}
                                    new_button_toggler={() => {
                                       runGenerator("disabled", false);
                                       runGenerator("showCancelButton", true);
                                       setCurrentState(initialState)
                                    }}
                                 />
                              )}

                              <li id="newRecord" style={{ borderWidth: '1px' }} className="hover:cursor-pointer   border-slate-200 text-xs px-2 py-1 ml-1  text-gray-600 rounded flex items-center" >
                                 <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img className="pr-1 " src={submit} />Submit </span>
                              </li>

                           </div>

                        </div>
                     </div>


                  </Form.Item>
               </Form>

            </div>

         }

            <div className="pt-0.5">
               <Datagrid_template
                  gridheight={toggled === true ? tableHeight - 320 : tableHeight}
                  columns={hideStaffNo ? staff_dependant_TH_Without_No : staff_dependant_TH}
                  data={dependantDetail_data}
                  onRowClick={(e: any) => datagridRowClick(e)}
                  rowDoubleClicked={datagridRowDoubleClick}
               />
               <section className='border-2 border-stone-300 flex align-middle my-1  rounded-md bg-slate-100 items-center' style={{ height: '30px' }}>
                  <div className='pl-2 text-blue-500   ' >
                     Done...
                  </div>

                  <div className='pl-40 text-blue-500  font-bold ' >
                     <p id="maxDependant">*Max. No of Dependants:</p>
                  </div>
                  <div className='pl-40 text-blue-500  font-bold ' >
                     <p id="maxWards"> *Max. No of Wards:</p>
                  </div>
                  <Tooltip
                     target="#maxDependant"
                     showEvent="dxhoverstart"
                     hideEvent="dxhoverend"
                     hideOnOutsideClick={true}
                     className="flex flex-col justify-left text-left"
                  >
                     <p className="text-left">Maximum number of dependants allowed</p>

                  </Tooltip>
                  <Tooltip
                     target="#maxWards"
                     showEvent="dxhoverstart"
                     hideEvent="dxhoverend"
                     hideOnOutsideClick={true}
                     className="flex flex-col justify-left text-left"
                  >
                     <p className="text-left">Maximum number of wards allowed</p>

                  </Tooltip>
               </section>
            </div>
         </div>
            <div style={{ width: 'calc(.5%)', height: '100%' }}>

            </div>
            <div style={{ width: 'calc(20%)', height: '100%' }} className="">
               <div className="w-full h-full ">
                  <TabPanel
                     className="w-full h-full border-[1px] "


                  >

                     {/* <Item title={"Comments"}>

                        <Estate_Maintenance_Comment />

                     </Item>

                     <Item title={"Trails (N)"}>

                        <Estate_Maintenance_Attachments />

                     </Item> */}


                  </TabPanel>
               </div>
            </div>
         </div>

      </>


   );
}

export default StaffDependantDetails;