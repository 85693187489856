import React, { useEffect, useRef, useState } from "react";
import { Form, Modal } from "antd";
import type { DraggableData, DraggableEvent } from "react-draggable";
import Draggable from "react-draggable";
import { InputsTemplate } from "../../../templates/input";
import save from "../../../assets/floppy-disk.png";
import help from "../../../assets/helpothers.png";
import { modalPropTypes } from "../../../components/human-resource/setups/data/types/selectsTypes";
import { ModalTemplate } from "../../../templates/modal";
import { PasswordRulesModal } from "../../../pages/Login/widgets/passwordRulesModal";
import { UpdateDataFunc } from "../../../functions/update";
import { useBusyLoader } from "../../../hooks/useBusyLoader";
import { useAccountInfo } from "../../../hooks/useAccountInfo";

interface props {
  open: boolean;
  handleCancel: () => void;
}

const NavResetPasswordModal = ({ open, handleCancel }: props) => {
  const [employeeID, userID] = useAccountInfo();

  const [busyLoader, setBusyLoader] = useBusyLoader();

  const [disabled, setDisabled] = useState(false);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef<HTMLDivElement>(null);

  const [newPassword, setNewPassword] = useState<string>("");
  const [checkPassword, setCheckPassword] = useState<string>("");
  const [curentPassword, setCurrentPassword] = useState<string>("");
  const resetInputs = () => {
    setNewPassword("");
    setCheckPassword("");
    setCurrentPassword("");
  };
  const [modalAlert, setModalAlert] = useState<modalPropTypes>({
    state: false,
    title: "",
    message: "",
  });
  const [passwordRules, setPasswordRules] = useState<boolean>(false);

  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  //set input border colours
  const handleBorderColours = (): string | undefined => {
    if (newPassword === checkPassword) {
      return "green";
    }
    return "rgb(248 113 113)";
  };
  useEffect(() => {
    handleBorderColours();
  }, [checkPassword, newPassword]);

  //password validation
  const validatePassword = () => {
    if (!curentPassword || curentPassword.trim() === "") {
      setModalAlert({
        state: true,
        title: "Enter Current Password",
        message: "Please enter current password",
        icon: "warning",
        func() {
          setModalAlert({ ...modalAlert, state: false });
        },
      });

      return false;
    } else if (!newPassword || newPassword.trim() === "") {
      setModalAlert({
        state: true,
        title: "Enter New Password",
        message: "Please enter new password",
        icon: "warning",
        func() {
          setModalAlert({ ...modalAlert, state: false });
        },
      });

      return false;
    } else if (!checkPassword || checkPassword.trim() === "") {
      setModalAlert({
        state: true,
        title: "Re-Enter Password",
        message: "Please enter re-enter new password",
        icon: "warning",
        func() {
          setModalAlert({ ...modalAlert, state: false });
        },
      });

      return false;
    } else if (newPassword !== checkPassword) {
      setModalAlert({
        state: true,
        title: "Password Mismatch",
        message: "Passwords do not match",
        icon: "warning",
        okText: "Yes, Reset",
        func() {
          setModalAlert({ ...modalAlert, state: false });
        },
      });
      return false;
    }
    //all other validations
    else if (newPassword?.length < 8) {
      // this is the reason the help shows up when clicked on the rest button
      setModalAlert({
        state: true,
        title: "Invalid Password",
        message: "Password Length is less than 8 characters",
        icon: "warning",
        okText: "Yes, Reset",
        func() {
          setModalAlert({ ...modalAlert, state: false });
        },
      });
      return false;
    }

    return true;
  };

  //handle password reset
  const handlePasswordReset = async (action: "reset" | "confirm") => {
    if (action === "confirm") {
      validatePassword() &&
        setModalAlert({
          //success modal
          state: true,
          title: "Reset Password?",
          message: "Are you sure you want to reset your password?",
          icon: "question",
          func() {
            handlePasswordReset("reset");
          },
        });

      return;
    }

    disableModal();
    setBusyLoader("Resetting Password...");
    try {
      const resetResponse = await UpdateDataFunc(`api/Accounts/ResetPassword`, {
        usaEmpIdfk: employeeID,
        oldPasswoed: curentPassword,
        newPassword: newPassword,
        usaEditedBy: userID,
      });

      //success
      if (resetResponse.status === 202) {
        resetInputs(); //clear fields

        setModalAlert({
          //success modal
          state: true,
          title: "Reset Successful",
          message: "Password changed succesfully",
          icon: "success",
          func() {
            disableModal();

            setTimeout(() => {
              //disable reset modal
              handleCancel();
            }, 300);
          },
        });
      }
    } catch (error: any) {
      resetInputs();

      //incorrect initial password
      if (error.response.status === 400) {
        setModalAlert({
          state: true,
          title: "Password Reset Failed",
          message: "Password reset failed. Please check password and try again",
          icon: "warning",
          func() {
            setModalAlert({ ...modalAlert, state: false });
          },
        });

        return;
      }

      setModalAlert({
        state: true,
        title: "Password Reset Failed",
        message: "Password reset failed. Please contact system administrator",
        icon: "warning",
        func() {
          setModalAlert({ ...modalAlert, state: false });
        },
      });

      return;
    } finally {
      setBusyLoader("");
    }
  };

  const disableModal = () => {
    setModalAlert({ ...modalAlert, state: false }); //disable confirm
  };

  return (
    <>
      <Modal
        maskClosable={false}
        style={{ top: 100, zIndex: 1000 }}
        width={375}
        footer=""
        title={
          <div
            style={{
              width: "100%",
              cursor: "move",
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
            onFocus={() => {}}
            onBlur={() => {}}
            // end
          >
            Reset Password
          </div>
        }
        open={open}
        onOk={() => {}}
        transitionName=""
        onCancel={() => {
          handleCancel();
        }}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <PasswordRulesModal
          isIcon={true}
          passRules={true}
          open={passwordRules}
          onCancel={() => setPasswordRules(false)}
          onOk={() => setPasswordRules(false)}
        />
        <>
          {/* modal alert */}
          <ModalTemplate
            icon={modalAlert.icon ?? "question"}
            disableCancel={modalAlert.icon === "question" ? false : true}
            cancelText={modalAlert.icon === "question" ? "No,Cancel" : "No"}
            open={modalAlert.state}
            okHandler={() => modalAlert.func!()}
            cancelHandler={() => {
              setModalAlert({
                state: false,
                title: "",
                message: "",
              });

              if (modalAlert.icon === "success") {
                setTimeout(() => {
                  //disable reset modal
                  handleCancel();
                }, 200);
              }
            }}
            message={modalAlert.message}
            okText={
              modalAlert.icon !== "question"
                ? "Ok"
                : modalAlert.okText ?? "Yes, Save"
            }
            title={modalAlert.title}
          />

          <hr className=" mb-2" />
          <Form labelCol={{ span: 8 }}>
            <InputsTemplate
              inputStyle={{ height: 26, borderRadius: 22.5 }}
              defaultValue={curentPassword}
              inputType="password"
              orderOnchange={(text) => setCurrentPassword(`${text}`)}
              label="Current Passsword"
              disabledStatus={false}
              useCallbackFunc
              span
            />

            <InputsTemplate
              inputStyle={{
                borderColor: newPassword
                  ? handleBorderColours()
                  : "rgb(209 213 219)",
                focus: {
                  borderColor: newPassword
                    ? handleBorderColours()
                    : "rgb(209 213 219)",
                },
                height: 26,
                borderRadius: 22.5,
              }}
              defaultValue={newPassword}
              orderOnchange={(text) => setNewPassword(`${text}`)}
              label="New Passsword"
              inputType="password"
              disabledStatus={false}
              useCallbackFunc
              span
            />

            <InputsTemplate
              inputStyle={{
                borderColor: newPassword
                  ? handleBorderColours()
                  : "rgb(209 213 219)",
                focus: {
                  borderColor: newPassword
                    ? handleBorderColours()
                    : "rgb(209 213 219)",
                },
                height: 26,
                borderRadius: 22.5,
              }}
              defaultValue={checkPassword}
              orderOnchange={(text) => setCheckPassword(`${text}`)}
              label="Confirm Passsword"
              inputType="password"
              useCallbackFunc
              disabledStatus={false}
              span
            />

            <div style={{}} className="flex flex-row justify-between mt-1">
              <span
                onClick={() => setPasswordRules(true)}
                style={{ height: 32 }}
                className=" hover:cursor-pointer border-2 hover:border-blue-300 rounded-[50%] flex justify-center items-center h-8 w-8"
              >
                <img className=" w-8/12 h-8/12" src={help} alt="" />
              </span>

              <button
                onClick={() => handlePasswordReset("confirm")}
                className="hover:cursor-pointer rounded-[25px] dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  px-4 shadow py-1.5 border-2 border-slate-200 hover:border-blue-300"
              >
                <img alt="" className="pr-1" src={save} />
                Reset
              </button>
            </div>
          </Form>
        </>
      </Modal>
    </>
  );
};

export default NavResetPasswordModal;
