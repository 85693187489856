export const hr_setups_Areas_columns = [
  {
    id: 0,
    caption: "ID",
    dataField: "areIdpk",
    datatype: "number",
    width: 80,
    fixed: true,
    visible: false,
  },
  {
    id: 1,
    caption: "AREA",
    dataField: "areName",
    datatype: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "SHORT NAME",
    dataField: "areShtName",
    datatype: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 3,
    caption: "ORDER",
    dataField: "areOrder",
    datatype: "string",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 4,
    caption: "ACTIVE",
    dataField: "areActive",
    datatype: "boolean",
    width: 90,
    fixed: true,
    visible: true,
  },
  {
    id: 5,
    caption: "REMARKS",
    dataField: "areRmks",
    datatype: "string",
    width: 5000,
    fixed: false,
    visible: true,
  },
];

export const Areas_data = [
  {
    ID: 1,
    AREA: "Accra Central",
    "SHORT NAME": "Accra Central",
    ORDER: "4",
    ACTIVE: true,
    REMARKS: "SFF",
  },
  {
    ID: 2,
    AREA: "Accra Central",
    "SHORT NAME": "Accra Central",
    ORDER: "4",
    ACTIVE: false,
    REMARKS: "",
  },
  {
    ID: 3,
    AREA: "Accra Central",
    "SHORT NAME": "Accra Central",
    ORDER: "4",
    ACTIVE: true,
    REMARKS: "",
  },
  {
    ID: 4,
    AREA: "Accra Central",
    "SHORT NAME": "Accra Central",
    ORDER: "4",
    ACTIVE: true,
    REMARKS: "",
  },
  {
    ID: 5,
    AREA: "Accra Central",
    "SHORT NAME": "Accra Central",
    ORDER: "4",
    ACTIVE: true,
    REMARKS: "",
  },
  {
    ID: 6,
    AREA: "Accra Central",
    "SHORT NAME": "Accra Central",
    ORDER: "4",
    ACTIVE: true,
    REMARKS: "",
  },
  {
    ID: 7,
    AREA: "Accra Central",
    "SHORT NAME": "Accra Central",
    ORDER: "4",
    ACTIVE: false,
    REMARKS: "",
  },
  {
    ID: 8,
    AREA: "Accra Central",
    "SHORT NAME": "Accra Central",
    ORDER: "4",
    ACTIVE: true,
    REMARKS: "",
  },
];
