/* eslint-disable react/jsx-pascal-case */
import { Switcher } from "../../_widget/switcher";
import { RefreshButton } from "../../_widget/utils";
import Datagrid_template from "../../../../../../templates/Datagrid";
import { SaveButton } from "../../../../../accessories/buttons";
import { DateTemplate } from "../../../../../../templates/date";
import { feeder, feederColumns, stationEntries, stationEntriesColumns } from "../BusVoltage/_data/stationentriescolumns";
import MiniToolbar from "../../_widget/minitollbar";
import { InputsTemplate } from "../../../../../../templates/input";
import { TextareaTemplate } from "../../../../../../templates/textarea";
import { useGraphSwitcher } from "../../db/useSwitcher";
import { ReadingsChart } from "../../_widget/readingschart";
import { modalPropTypes, modalTypes, validateInstanceTypes } from "../../../../../human-resource/setups/data/types/selectsTypes";
import React, { useEffect, useState } from "react";
import { useBusyLoader } from "../../../../../../hooks/useBusyLoader";
import { useCrudFunc } from "../../../../../../functions/crud";
import { saveModalData } from "../../../../../accessories/component_infos";
import { ModalTemplate } from "../../../../../../templates/modal";
import { useDispatch } from "react-redux";
import useFetch from "../../../../../../hooks/useFetch";
import dayjs from "dayjs";


interface stateTypes{
    feederTime:string,
    voltage:string,
    current:string,
    activePower:string,
    reactivePower:string,
    station: any,
    feeder:any,
    
    active:boolean,
    remarks:string,
    
  
    selectedStationId:0,
        selectedFeederId:0,
    confirmModal:modalPropTypes
    warningModal:modalPropTypes
    successModal:modalPropTypes
  }

export default function FeederReadings() {
    const { status } = useGraphSwitcher();
    const [refresh, setrefresh] = useState<boolean>(false);

    const updateState = (key: string, value: any) => {
        setStates((prev: any) => ({ ...prev, [key]: value }));
      };

      const disableConfirmModal=()=>{
        updateState('confirmModal',{
            state:false,
            title:'',
            message:'',
            action:'post'
        })
    }


    
   //post data
   const [posting , updating]=useCrudFunc("powerapp");

   const [allowUpdate,setAllowUpdate]=useState(false);

   const [busyLoader, setBusyLoader] = useBusyLoader();

    const[states,setStates]=useState<stateTypes>({
        feederTime:'',
        voltage:'',
        activePower:'',
        current:'',
        reactivePower:'',
        station: {name:'', id:""},
        feeder:{name:'', id:''},
       
        active:false,
        remarks:'',
    
        selectedStationId:0,
        selectedFeederId:0,
    
        confirmModal:{state:false,title:'',message:'',action:'post'},
        warningModal:{state:false,title:'',message:''},
        successModal:{state:false,title:'',message:''}
    })

    const postData = async (action:'validate'|'post') => {
        if (action==='validate') {
            // validate fieldds
            validateFields() &&
            updateState('confirmModal',{
                state:true,
                title:'Save Record',
                message:'Are you sure you want to save this record?',
                action:'post'
            })
            return;
        }
 
        //post
        disableConfirmModal();
        setBusyLoader(saveModalData.progress)
      //post request here
    //   setModal("state", false);
      // refreshGrid();
      // setBusyLoader(saveModalData.progress);
      try {
        const postResponse =   await posting (`EntFeederReadings
            
            `, {
            "frdStationIDfk": states.station.id,
            "frdFeederIDfk": states.feeder.id,
            "frdTime": states.feederTime,
            "frdVoltage": states.voltage,
            "frdCurrent": states.current,
            "frdActivePower": states.activePower,
            "frdReactivePower": states.reactivePower,
            "frdActive": states.active,
            "frdRmks": states.remarks,
            "formCode": "string",
            "formAction": "string"
        },`Created Feeder Reading`);
        setrefresh(!refresh);
        // setRefresh(!refresh);
        // setsuccesModalState({
        //   state: true,
        //   message: "Record saved successfully",
        //   title: "Save Successful",
        // });
        // setBusyLoader("");
        // populateFromGrid(false);
        setrefresh(!refresh);
       
        setStates({
            feederTime:'',
            voltage:'',
            activePower:'',
            current:'',
            reactivePower:'',
            station: {name:'', id:""},
            feeder:{name:'', id:''},
           
            active:false,
            remarks:'',
        
            selectedStationId:0,
            selectedFeederId:0,
        
            confirmModal:{state:false,title:'',message:'',action:'post'},
            warningModal:{state:false,title:'',message:''},
            successModal:{state:false,title:'',message:''}
        })
     updateState('successModal',{
       state:true,
       title:`Save Successful`,
       message:`Record saved successfully`,
   })
      
       //  populateStatesFromGrid(false);
    } catch (error) {
        console.log(error);
        
        updateState('warningModal',{
            state: true,
            title: saveModalData.error.title,
            message: saveModalData.error.message
        })
    } finally{
        setBusyLoader('');
    }
   
    return;
    };

     //validate fields
   const validateFields=():boolean=>{
    const validationInstances:validateInstanceTypes[]=[
      {state:states.station.id,baseValue:'',modalProps:{state:true,title:'Select Station',message:'Please select a station'}},
      {state:states.feeder.id ,baseValue:'',modalProps:{state:true,title:'Select Feeder',message:'Please select a feeder'}},
        
      
      {state:states.feederTime,baseValue:'',modalProps:{state:true,title:'Enter Time',message:'Please select a time'}},
        {state:states.voltage,baseValue:'',modalProps:{state:true,title:'Enter Voltage',message:'Please enter voltage'}},
        {state:states.current,baseValue:'',modalProps:{state:true,title:'Enter Current',message:'Please enter a current'}},
        {state:states.activePower,baseValue:'',modalProps:{state:true,title:'Enter Active Power',message:'Please enter active power'}},
        {state:states.reactivePower,baseValue:'',modalProps:{state:true,title:'Enter Reactive Power',message:'Please enter reactive power'}},
        // {state:weight,baseValue:0,modalProps:{state:true,title:'Enter % weight',message:'Please enter % weight'}},
    ]

    for(let instance of validationInstances) if (instance.state===instance.baseValue) {
        const{modalProps}=instance;
        updateState('warningModal',{
            state:modalProps.state,
            title:modalProps.title,
            message:modalProps.message
        })
        return false;
    }
    return true;
}

const updateData = async (action:'validate'|'update') => {
    if (action==='validate') {
        // validate fieldds
        validateFields() &&
        updateState('confirmModal',{
            state:true,
            title:'Update Record',
            message:'Are you sure you want to update this record?',
            action:'update'
        })
        return;
    }

    //post
    disableConfirmModal();
    setBusyLoader(saveModalData.progress)
  //post request here
//   setModal("state", false);
  // refreshGrid();
  // setBusyLoader(saveModalData.progress);
  try {
    const postResponse =   await updating (`EquipmentOutage/UpdateEquipmentOutage`, {
        "frdStationIDfk": 0,
        "frdFeederIDfk": 0,
        "frdTime": "2024-08-13T10:43:59.779Z",
        "frdVoltage": 0,
        "frdCurrent": 0,
        "frdActivePower": 0,
        "frdReactivePower": 0,
        "frdActive": true,
        "frdRmks": "string",
     "eotCreatedBy": 0,
        "formCode": "string",
        "formAction": "string"
    },`Created Bus Detail`);
    // setrefresh(!refresh);
    // setRefresh(!refresh);
    // setsuccesModalState({
    //   state: true,
    //   message: "Record saved successfully",
    //   title: "Save Successful",
    // });
    // setBusyLoader("");
    // populateFromGrid(false);
     
    setrefresh(!refresh);
    setAllowUpdate(false);
    setStates({
        feederTime:'',
        voltage:'',
        activePower:'',
        current:'',
        reactivePower:'',
        station: {name:'', id:""},
        feeder:{name:'', id:''},
       
        active:false,
        remarks:'',
    
        selectedStationId:0,
        selectedFeederId:0,
    
        confirmModal:{state:false,title:'',message:'',action:'post'},
        warningModal:{state:false,title:'',message:''},
        successModal:{state:false,title:'',message:''}
    })
 updateState('successModal',{
  state:true,
  title:`Update Successful`,
  message:`Record updated successfully`,
})
   //  populateStatesFromGrid(false);
} catch (error) {
    console.log(error);
    
    updateState('warningModal',{
        state: true,
        title: saveModalData.error.title,
        message: saveModalData.error.message
    })
} finally{
    setBusyLoader('');
}
return}

  // modal instances
  const modals:modalTypes[] = [
    //validation
    {disableCancel : true , icon : 'warning' ,open : states.warningModal.state, okHandler : ()=>updateState('warningModal',{state:false,title:'',message:''}), cancelHandler : ()=> updateState('warningModal',{state:false,title:'string',message:'string'}), message : states.warningModal.message, okText : 'Ok', title : states.warningModal.title,cancelText : 'No, cancel'},
    
    //confirmation
    {disableCancel : false , icon : 'question' ,open : states.confirmModal.state, okHandler : states.confirmModal.action==='update'?()=>updateData('update'):()=>postData('post'), cancelHandler : ()=> updateState('confirmModal',{state:false,title:'string',message:'string'}), message : states.confirmModal.message, okText : states.confirmModal.action==='update' ? 'Yes, Update':'Yes, Save', title : states.confirmModal.title,cancelText : 'No, cancel'},

    //success
    {disableCancel : true , icon : 'success' ,open : states.successModal.state, okHandler : ()=>updateState('successModal',{state:false,title:'',message:''}), cancelHandler : ()=> updateState('successModal',{state:false,title:'string',message:'string'}), message : states.successModal.message, okText : 'Ok', title : states.successModal.title,cancelText : 'No, cancel'},
]

const dispatch = useDispatch();


const [stationData,stationDataError, stationDataLoading] = useFetch(`EntFeederReadings/stations`, refresh, 'stnIDpk', false, "powerapp");
const [feederData,feederDataError, feederDataLoading] = useFetch(`EntFeederReadings/feeder-details?stnIDfk=${states.station.id}`, refresh, 'fdrIDpk', false, "powerapp");

  
    

    return (
        <>
          {modals.map(({disableCancel,icon,okHandler,cancelHandler,open,cancelText,okText,message,title}:modalTypes,index)=>{
            return(
                <div key = {index}>
                    <ModalTemplate  icon= {icon && icon} disableCancel = {disableCancel} cancelText = {cancelText && cancelText} open = {open} okHandler={okHandler} cancelHandler={cancelHandler} message= {message} okText= {okText} title={title}/>
                </div>
            )
        })}

        
        <section className="w-full h-full" >

            <Switcher alternate={''} >
                {/* top section */}
                <section className='flex space-x-3 w-full border-2 p-2'>
                    {/* left section */}
                    <aside className='w-[22%]'>
                        <aside className='flex items-center justify-between bg-neutral-50 py-1 rounded-sm px-2 border-[1px] border-neutral-400'>
                            <p className='text-sm font-medium'>STATION</p>
                            <RefreshButton />
                        </aside>
                        <aside>
                            <Datagrid_template
                                gridheight={240}
                                showColumnHeaders={false}
                                columns={[{
                                    id: 1,
                                    caption: "STATION",
                                    dataField: "stnName",
                                },]}
                                data={stationData}
                                onRowClick={(e) => {
                                  console.log(e);
                                  updateState('station', {
                                    id: e['stnIDpk'],
                                    name: e['stnName'!],
                                  });
                            }}
                                disablePaging
                                disableSearch
                                disableGroupPanel
                            />
                        </aside>
                    </aside>
                    {/* right section */}
                    <aside className='w-[78%] items-right overflow-hidden'>
                        <section className='flex items-start ml-[160px]'>
                            <p className='text-[12px] font-medium mr-2'>Feeder:</p>
                            <Datagrid_template
                                gridheight={100}
                                showColumnHeaders={true}
                                columns={feederColumns}
                                data={feederData}
                                disablePaging
                                disableSearch
                                disableGroupPanel
                                onRowClick={(e) => {
                                    console.log(e);
                                    updateState('feeder', {
                                      id: e['fdrIDpk'],
                                      name: e['fdrName'!],
                                    });
                              }}
                            />
                        </section>
                        <section className='w-full h-full mt-1'>
                            <section className="flex items-start justify-between">
                                <aside className="flex items-start w-full ml-[168px] mb-0.5">
                                    <p className="mr-[6px]">Time:</p>
                                    <DateTemplate disabled={false} style={{ width: 230, height: 32 }} className="w-full"
                                     selectedDate={states.feederTime===''?'':dayjs( states.feederTime)}
                                     changeDate={(e) => {
                                       updateState('feederTime', e);
                                       
                                     }}
                                    />
                                </aside>
                                <aside>
                                    <RefreshButton />
                                </aside>
                            </section>
                            <section className="flex space-x-2 items-center">
                                <aside className="space-y-0.5 ">
                                    <section className="flex space-x-1">
                                        <aside className="ml-14">
                                            <InputsTemplate disabledStatus={false} span inputStyle={{ width: 114 }} label="Voltage (kv) & Current (A)" labelColPan={3}
                                             useCallbackFunc
                                             orderOnchange={(e)=>{
                                               updateState('voltage'!, e)
                                             }}
                                             defaultValue={states.voltage}
                                            />
                                        </aside>
                                        <InputsTemplate disabledStatus={false} span inputStyle={{ width: 114 }}
                                        
                                        useCallbackFunc
                                        orderOnchange={(e)=>{
                                          updateState('current'!, e)
                                        }}
                                        defaultValue={states.current} />
                                    </section>
                                    <section className="flex space-x-1 ml-0.5">
                                        <InputsTemplate disabledStatus={false} span inputStyle={{ width: 114 }} label="Active (kW) & Reactive Power (kVAr)" labelColPan={12} 
                                         useCallbackFunc
                                         orderOnchange={(e)=>{
                                           updateState('activePower', e)
                                         }}
                                         defaultValue={states.activePower}
                                        />
                                        <InputsTemplate disabledStatus={false} span inputStyle={{ width: 114 }}
                                         useCallbackFunc
                                         orderOnchange={(e)=>{
                                           updateState('reactivePower', e)
                                         }}
                                         defaultValue={states.reactivePower}
                                        />
                                        <SaveButton  
                                         disableButton={false}
                                         useCallbackFunc={true}
                                         handleSave={()=>{
                                             postData("validate") 
                                            //  updateData("validate");
                                             ;
                                         }}
                                        />
                                    </section>
                                </aside>
                            </section>
                            <section className="h-full mt-0.5  ml-[205px]">
                                <TextareaTemplate height={75}
                                 defaultValue={states.remarks}
                                 useCallbackFunc
                                setCallbackValue={(e) => {
                                  updateState('remarks', e);
                                 }}
                                />
                            </section>
                        </section>
                    </aside>
                </section>
                <div className='h-1 w-full bg-sky-200' />
            </Switcher>
            {/* botom section */}
            <section className='flex flex-col'>
                <MiniToolbar showEditIcon={true} />
                <section className='flex-grow'>
                    <section className='w-full h-full'>
                        {
                            status ? <ReadingsChart /> :
                                <Datagrid_template
                                    gridheight={415}
                                    showColumnHeaders={true}
                                    columns={feederColumns}
                                    data={feederData}
                                    disablePaging
                                    disableGroupPanel
                                />}
                    </section>
                </section>
            </section>
        </section>
        </>
    );
}