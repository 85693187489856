import React, { useEffect, useState } from 'react'
import { CheckboxTemlate } from '../../../../templates/checkbox';
import { Form } from 'antd';
import useResizeMode from '../../../../hooks/useResizeMode';
import { checkboxTypes } from '../../../human-resource/setups/data/types/selectsTypes';
import { TextareaTemplate } from '../../../../templates/textarea';
import { UpdateButton } from '../../../accessories/buttons';
import { GetAllUsersInterface } from './apa_form';
import useFetch from '../../../../hooks/useFetch';
import { GetAsmTmsPrivilegesInterface } from '../../../../interfaces';
import { UpdateStates } from '../../../../functions/updateStates';
import { useModalData } from '../../../accessories/component_infos';
import { ModalTemplate } from '../../../../templates/modal';
import { PostDataFunc } from '../../../../functions/post';

interface PageProps {

    rowData: GetAllUsersInterface;
}


const Apa_r2_asM_Transport = ({ rowData }: PageProps) => {

    const [modal, setModal] = useModalData();


    const [initial, final] = useResizeMode(window.innerHeight);
    const gridheight = initial - 350;
    const [Refresh, setRefresh] = useState(false);

    const [transportData, errorT, loadingT]: [GetAsmTmsPrivilegesInterface[], any, any] = useFetch(`SysAdminFormAccessPrivileges/GetAsmTmsPrivileges?EmpID=${rowData?.empIDpk}`, Refresh)
    const tData = transportData[0];



    useEffect(() => {
        setRefresh((prev) => (!prev));
    }, [rowData]);

    const [States, setStates] = useState<GetAsmTmsPrivilegesInterface>({
        tupManager: false,
        tupDirector: false,
        tupCeo: false,
        tupFuelAnalyst: false,
        tupRecommendAllVehicleRequests: false,
        tupOrgAdmin: false,
        tupDeptAdmin: false,
        tupSectionAdmin: false,
        tupUnitAdmin: false,
        tupApproveFuelRequests: false,
        tupSysAdmin: false,
        tupSaveConditionReturns: false,
        tupSubmitConditionReturns: false,
        tupSeeDriversCodes: false,
        tupWorkShopServiceOfficer: false,
        tupRmks: "",
    });

    useEffect(() => {
        if (tData) {
            setStates({
                tupManager: tData?.tupManager ?? false,
                tupDirector: tData?.tupDirector ?? false,
                tupCeo: tData?.tupCeo ?? false,
                tupFuelAnalyst: tData?.tupFuelAnalyst ?? false,
                tupRecommendAllVehicleRequests: tData?.tupRecommendAllVehicleRequests ?? false,
                tupOrgAdmin: tData?.tupOrgAdmin ?? false,
                tupDeptAdmin: tData?.tupDeptAdmin ?? false,
                tupSectionAdmin: tData?.tupSectionAdmin ?? false,
                tupUnitAdmin: tData?.tupUnitAdmin ?? false,
                tupApproveFuelRequests: tData?.tupApproveFuelRequests ?? false,
                tupSysAdmin: tData?.tupSysAdmin ?? false,
                tupSaveConditionReturns: tData?.tupSaveConditionReturns ?? false,
                tupSubmitConditionReturns: tData?.tupSubmitConditionReturns ?? false,
                tupSeeDriversCodes: tData?.tupSeeDriversCodes ?? false,
                tupWorkShopServiceOfficer: tData?.tupWorkShopServiceOfficer ?? false,
                tupRmks: "",
            });
        } else {
            setStates({
                tupManager: false,
                tupDirector: false,
                tupCeo: false,
                tupFuelAnalyst: false,
                tupRecommendAllVehicleRequests: false,
                tupOrgAdmin: false,
                tupDeptAdmin: false,
                tupSectionAdmin: false,
                tupUnitAdmin: false,
                tupApproveFuelRequests: false,
                tupSysAdmin: false,
                tupSaveConditionReturns: false,
                tupSubmitConditionReturns: false,
                tupSeeDriversCodes: false,
                tupWorkShopServiceOfficer: false,
                tupRmks: "",
            });
        }
    }, [tData, rowData]);

    const checkboxes1: checkboxTypes[] = [
        { id: 0, label: "Transport Manager", stateName: "tupManager", defaultValue: States?.tupManager, },
        { id: 1, label: "Transport Director", stateName: "tupDirector", defaultValue: States?.tupDirector, },
        { id: 2, label: "Transport CEO 1", stateName: "tupCeo", defaultValue: States?.tupCeo, },
        { id: 3, label: "Fuel Analyst", stateName: "tupFuelAnalyst", defaultValue: States?.tupFuelAnalyst, },
        { id: 4, label: "Recom. All Veh. Reqs?", stateName: "tupRecommendAllVehicleRequests", defaultValue: States?.tupRecommendAllVehicleRequests, },
    ]

    const checkboxes2: checkboxTypes[] = [
        { id: 0, label: "Trans. Org. Admin.?", stateName: "tupOrgAdmin", defaultValue: States?.tupOrgAdmin, },
        { id: 1, label: "Trans. Dept Admin?", stateName: "tupDeptAdmin", defaultValue: States?.tupDeptAdmin, },
        { id: 2, label: "Trans. Sectioin Admin?", stateName: "tupSectionAdmin", defaultValue: States?.tupSectionAdmin, },
        { id: 3, label: "Trans. Unit Admin?", stateName: "tupUnitAdmin", defaultValue: States?.tupUnitAdmin, },
        { id: 4, label: "Approve Fuel Reqs?", stateName: "tupApproveFuelRequests", defaultValue: States?.tupApproveFuelRequests, },
    ]

    const checkboxes3: checkboxTypes[] = [
        { id: 0, label: "System Admin?", stateName: "tupSysAdmin", defaultValue: States?.tupSysAdmin },
        { id: 1, label: "Same Veh. Cond.", stateName: "tupSaveConditionReturns", defaultValue: States?.tupSaveConditionReturns },
        { id: 2, label: "Submit Veh. Cond.", stateName: "tupSubmitConditionReturns", defaultValue: States?.tupSubmitConditionReturns },
        { id: 3, label: "See Driver's Codes", stateName: "tupSeeDriversCodes", defaultValue: States?.tupSeeDriversCodes },
        { id: 4, label: "Workshop Officer", stateName: "tupWorkShopServiceOfficer", defaultValue: States?.tupWorkShopServiceOfficer },
    ]

    const triggerUpdateAccessPrivileges = async () => {
        try {
            const res = await PostDataFunc(`SysAdminFormAccessPrivileges/CreateAsmTmsAccessPrivileges?EmpID=${rowData?.empIDpk}`, States)

            if(res.status) {
                setModal({
                    state: true,
                    title: "Update Privileges",
                    message: `Privileges have been updated for ${rowData.empName}`,
                    icon: "success",
                    okText: "Ok",
                    cancelText: "No",
                    disableCancel: true,
                    okHandler: async () => {
                        setModal(prev => ({
                            ...prev,
                            state: false,

                        }))
                    },
                    cancelHandler: () => {
                        setModal(prev => ({
                            ...prev,
                            state: false,

                        }))
                    }
                })
            }
        } catch (e) {

        }
    }

    return (
        <div className='w-full h-full'>
            <ModalTemplate
                width={500}
                icon={modal.icon}
                disableCancel={modal.disableCancel}
                cancelText={modal.cancelText}
                open={modal.state}
                okHandler={modal.okHandler}
                cancelHandler={modal.cancelHandler}
                message={modal.message}
                okText={modal.okText}
                title={modal.title}
            />
            <Form
                labelCol={{ span: 20 }}
                className='w-full py-2 px-2 '
                size='small'
                layout="horizontal"

            >
                <section className='w-full border-[1px] rounded-sm  items-center px-2'>
                    {/* top section */}
                    <section className='w-full flex justify-evenly space-y-1  '>
                        <div className='w-1/3'>
                            {checkboxes1.map(({ id, label, stateName, defaultValue }) => {
                                return (
                                    <React.Fragment key={id}>
                                        <CheckboxTemlate
                                            useCallbackFunc

                                            defaultValue={defaultValue}
                                            setCheckboxOnchange={(e) => {
                                                UpdateStates(stateName, e, setStates)
                                            }}
                                            span
                                            customDisabled={false}
                                            withBorders
                                            label={label}
                                            style={{ padding: "5px 0" }}
                                        />

                                    </React.Fragment>
                                )
                            })}
                        </div>

                        <div className='w-1/3'>
                            {checkboxes2.map(({ id, label, stateName, defaultValue }) => {
                                return (
                                    <React.Fragment key={id}>
                                        <CheckboxTemlate
                                            useCallbackFunc
                                            span
                                            customDisabled={false}
                                            defaultValue={defaultValue}
                                            setCheckboxOnchange={(e) => {
                                                UpdateStates(stateName, e, setStates)
                                            }}
                                            withBorders
                                            label={label}
                                            style={{ padding: "5px  0" }}
                                        />

                                    </React.Fragment>
                                )
                            })}
                        </div>

                        <div className='w-1/3'>
                            {checkboxes3.map(({ id, label, stateName, defaultValue }) => {
                                return (
                                    <React.Fragment key={id}>
                                        <CheckboxTemlate
                                            useCallbackFunc
                                            span
                                            customDisabled={false}
                                            defaultValue={defaultValue}
                                            setCheckboxOnchange={(e) => {
                                                UpdateStates(stateName, e, setStates)
                                            }}
                                            withBorders
                                            label={label}
                                            style={{ padding: " 5px  0" }}
                                        />

                                    </React.Fragment>
                                )
                            })}
                        </div>

                    </section>


                    <TextareaTemplate
                        height={175}
                        useCallbackFunc
                        placeholder='Remarks'
                        disabled={false}
                        setCallbackValue={(e) => {
                            UpdateStates("tupRmks", e, setStates)
                        }}

                    />

                    <section className='flex justify-end mb-2'>
                        <UpdateButton useCallbackFunc={true} disableButton={false} disabled_status={false} handleUpdate={
                            () => {
                                console.table(States)
                                setModal({
                                    state: true,
                                    title: "Update Privileges",
                                    message: `Are you sure you want to update the Privileges of ${rowData.empName}?`,
                                    icon: "question",
                                    okText: "Yes",
                                    cancelText: "No",
                                    disableCancel: false,
                                    okHandler: async () => {
                                        setModal(prev => ({
                                            ...prev,
                                            state: false,
                
                                        }))
                                        await triggerUpdateAccessPrivileges();
                                    },
                                    cancelHandler: () => {
                                        setModal(prev => ({
                                            ...prev,
                                            state: false,
                
                                        }))
                                    }
                                })
                            }

                        } />
                    </section>

                </section>
            </Form>
        </div>
    )
}

export default Apa_r2_asM_Transport