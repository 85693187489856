import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncGet, GetDataFunc } from "../../../functions/get";
import { stat } from "fs";

type State = {
  vehicle_regno: any | null | undefined;
  cost_center: any | null | undefined;
  isError: boolean;
  isLoading: boolean;
  isPending: boolean;
};
const initialState: State = {
  vehicle_regno: [],
  cost_center: [],
  isError: false,
  isLoading: false,
  isPending: false,
};

export const Fetch_VehicleRegNo = createAsyncThunk<
  State,
  { search: string; EmpID: number },
  { rejectValue: string }
>("fetch_vehicle_regno", async ({ search, EmpID }) => {
  try {
    var response = await AsyncGet(`FuelTopUpRequest/GetVehicles`, {
      pagenumber: 1,
      pagesize: 20,
      search,
      EmpID,
    });
    if (response?.status === true) {
      return response?.data;
    }
  } catch {
    return response.data;
  }
});
export const Fetch_Costcenter = createAsyncThunk<
  State,
  { search: string; EmpID: number },
  { rejectValue: string }
>("fetch_cost_center", async ({ search }) => {
  try {
    var response = await AsyncGet(`FuelTopUpRequest/CostCenters`, {
      pagenumber: 1,
      pagesize: 20,
      cctShtName: search,
    });
    if (response?.status === true) {
      return response?.data;
    }
  } catch {
    return response.data;
  }
});

const Slice_FuelTopupRequest = createSlice({
  name: "FuelTopupRequest",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(Fetch_VehicleRegNo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(Fetch_VehicleRegNo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.vehicle_regno = action.payload;
      })
      .addCase(Fetch_VehicleRegNo.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })

      .addCase(Fetch_Costcenter.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(Fetch_Costcenter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.cost_center = action.payload;
      })
      .addCase(Fetch_Costcenter.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default Slice_FuelTopupRequest.reducer;
