import React, { useState } from "react";
import { formPropsConst } from "../../../accessories/constants";
import { Form, Upload, UploadFile, UploadProps } from "antd";
import { RcFile, UploadChangeParam } from "antd/es/upload";
import image_loader from "../../../../assets/image_loader.gif";
import image_upload from "../../../../assets/image_upload.png";
import save from "../../../../assets/floppy-disk.png";
import newIcon from "../../../../assets/new.png";
import TextArea from "antd/es/input/TextArea";

export const ImageUpload = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [imageCon, setImageCon] = useState<any>([]);

  // Image Upload

  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const uploadChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    console.log(info);
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }

    if (info.file.status === "done") {
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
    // Uploads the image
    getBase64(info.file.originFileObj as RcFile, (url) => {
      setLoading(false);
      setImageUrl(url);
      setImageCon({ ...imageCon, url });
    });

    console.log(info.file.name);
  };
  const uploadButton = (
    <div className="flex items-center justify-center  w-full h-full">
      <div className="w-full">
        {loading ? (
          <img
            className="justify-center items-center"
            src={image_loader}
            alt="gif"
            style={{ width: "100%", height: "100%" }}
          />
        ) : (
          <img
            className="justify-center items-center"
            style={{ width: "100%", height: "100px" }}
            src={image_upload}
            alt="gif"
          />
        )}
        <div className="h-full" style={{ marginTop: 8, width: "calc(100%)" }}>
          Upload
        </div>
      </div>
    </div>
  );
  return (
    <div>
      {/* <Form  {...formPropsConst} labelCol={{ span: 1} } wrapperCol={{ span: 1 }}
                  className=" border-l-2 ml-2 " style={{ width: '45%' }}
               > */}
      <div className=" border-t-2 pb-2 w-full ">
        <div className="text-xs bg-slate-100 rounded-t-sm py-1 px-2">
          Images
        </div>
        {/* inner div for images */}

        <div className="px-2 pb-2 flex items-end    h-full w-full ">
          <div
            style={{
              backgroundImage: imageUrl
                ? `url(${imageUrl})`
                : `url(${imageUrl})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
            className=" w-full h-60 border flex justify-center items-center "
          >
            {/* No Image data */}
            <Upload
              name="File uploaded "
              listType="picture"
              className="avatar-uploader justify-center items-center "
              showUploadList={false}
              // beforeUpload={beforeUpload}
              onChange={(e) => {
                uploadChange(e);
                console.log(e);
              }}
            >
              {/* {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%'}} /> : uploadButton} */}
              {/* {!imageUrl && uploadButton} */}
            </Upload>
          </div>
        </div>
        <div className="flex mx-2 mb-0.5 ">
          {" "}
          <TextArea
            style={{
              resize: "none",
              maxHeight: "12%",
              minHeight: "10%",
              height: "",
              width: "88%",
            }}
            className="o  py-1  overflow-y-scroll resize-none "
            rows={1}
          />
          <li className="h-full">
            <button className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center ml-1 h-full mr-1 px-2 border-2 border-slate-200 rounded">
              <img className="pr-1" src={save} />
            </button>
          </li>
          <li>
            <button className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  px-2  border-2 border-slate-200 rounded">
              <Upload
                showUploadList={false}
                onChange={(e) => {
                  uploadChange(e);
                }}
              >
                <img className="pr-1" src={newIcon} />
              </Upload>
            </button>
          </li>
        </div>
        <div className="flex">
          <div
            style={{
              resize: "none",
              maxHeight: "12%",
              minHeight: "10%",
              height: "",
              width: "100%",
            }}
            className="o mx-2  border  flex "
          >
            <div className=" border-r p-0.5 text-xs px-2">1</div>
            <div className=" border-r p-0.5 text-xs px-2">2</div>
            <div className=" border-r p-0.5 text-xs px-2">3</div>
            <div className=" border-r p-0.5 text-xs px-2">4</div>
            <div className=" border-r p-0.5 text-xs px-2">5</div>
            <div className=" border-r p-0.5 text-xs px-2">6</div>
            <div className=" border-r p-0.5 text-xs px-2">7</div>
            <div className=" border-r p-0.5 text-xs px-2">8</div>
          </div>
        </div>
      </div>
      {/* </Form> */}
    </div>
  );
};
