/* eslint-disable react/jsx-pascal-case */
import React, { useRef, useState } from 'react'
import { CheckboxTemlate } from '../../../templates/checkbox'
import { inputsTypes, selectsTypes } from '../../human-resource/setups/data/types/selectsTypes'
import { InputsTemplate } from '../../../templates/input'
import { Form } from 'antd'
import AvatarTemplate from '../../../templates/avatar'
import account from "../../../assets/account.jpg"
import Datagrid_template from "../../../templates/Datagrid"
import { SaveButton } from '../../accessories/buttons'
import { GetGroupDataInterface } from '../../../interfaces'
import useFetch from '../../../hooks/useFetch'
import InputSelect_template from '../../../templates/InputSelect'
import dxDataGrid, { } from 'devextreme/ui/data_grid'
import { UpdateDataFunc } from '../../../functions/update'
import { useAccountInfo } from '../../../hooks/useAccountInfo'
import { useModalData } from '../../accessories/component_infos'
import { ModalTemplate } from '../../../templates/modal'
import useResizeMode from '../../../hooks/useResizeMode'

interface props {
    columns: any[]
    gridData: GetGroupDataInterface[]
    revalidate: (e: any) => void
}


const setImageOnCellRender = (rowData: GetGroupDataInterface, value: string): React.ReactNode => {

    return (
        <section className="w-24 h-24 py-1.5 px-1.5">

            {
                rowData.ppxPicture ?
                    <AvatarTemplate size={88} avatarIcon={`data:image/png;base64,${rowData.ppxPicture ?? account}`} avatarName={rowData.empname} />
                    :
                    <AvatarTemplate size={88} avatarIcon={account} avatarName={rowData.empname} />

            }

        </section>

    )
}


interface StateTypes {
    name: string;
    staffNo: string;
    dept: string;
    section: string;
    unit: string;
    location: string;
    intercom: string;
    officeLocation: string;
    roomNumber: string;

    "empIdpk": number,
    "empMobNo": string,
    "empMobNox": string,
    "empHideMobNo": boolean,
    "empIntercom": string,
    "empOfficialEmail": string,
    "empPersonalEmail": string,
    "empOfficeLocationIdfk": number,
    "empRoomIdfk": number,
    "empEditedBy": number

}


const EDM = ({ columns, gridData, revalidate }: props) => {

    const [toggleForm, setToggleForm] = useState(false)
    const datagridRef = useRef<dxDataGrid>(null);
    let [DataGridOnly, FormPlusDataGrid] = useResizeMode(toggleForm)

    let DataGridHeightOnly = DataGridOnly - 220;
    let formPlusDataGridHeight = FormPlusDataGrid - 398;
    const [officeLocationData, error, officeLoadingState] = useFetch(`OfficeLocation/GetActive?search=`);
    const [roomnumbersData, error1, roomLoading] = useFetch(`HrEmployee/FormRoomNumbers`);


    const [hideMob_No, setHideMob_No] = useState<boolean>(false)
    const [Pixis, setPixis] = useState(undefined);

    const [userId] = useAccountInfo();
    const [modalData, setModalData] = useModalData();



    let [state, setState] = useState<StateTypes>({
        name: "",
        staffNo: "",
        dept: "",
        section: "",
        unit: "",
        location: "",
        intercom: "",
        officeLocation: "",
        roomNumber: "",


        "empIdpk": NaN,
        "empMobNo": "",
        "empMobNox": "",
        "empHideMobNo": false,
        "empIntercom": "",
        "empOfficialEmail": "",
        "empPersonalEmail": "",
        "empOfficeLocationIdfk": NaN,
        "empRoomIdfk": NaN,
        "empEditedBy": userId


    });

    const [PictureShow, setPictureShow] = useState<boolean>(true);

    const directoryDetail: inputsTypes[] = [
        { id: 0, label: "Name & Staff No", stateName: "name", defaultValue: state.name, style: "", additionalwidgetValue: state.staffNo, additonallabel: "staffNo" },
        { id: 1, label: "Dept. & Section", stateName: "dept", defaultValue: state.dept, style: "", additionalwidgetValue: state.section, additonallabel: "section" },
        { id: 2, label: "Unit & Location", stateName: "unit", defaultValue: state.unit, style: "", additionalwidgetValue: state.location, additonallabel: "location" },
        { id: 3, label: "Intercom & Tel. No", stateName: "intercom", defaultValue: state.empMobNox, style: "", additionalwidgetValue: state.empMobNox, additonallabel: "empMobNox" },
        { id: 4, label: "Mobile Number", stateName: "empMobNo", defaultValue: state.empMobNo, style: "" },
    ]

    const rightInputs: inputsTypes[] = [
        { id: 0, label: "Official eMail", stateName: "empOfficialEmail", defaultValue: state.empOfficialEmail, style: "" },
        { id: 1, label: "Personal eMail", stateName: "empPersonalEmail", defaultValue: state.empPersonalEmail, style: "" },
    ]
    const rightSelects: selectsTypes[] = [
        { id: 0, label: "Office Location", idExpr: "ofcIdpk", dataExpr: "ofcName", optionsData: officeLocationData, stateName: "officeLocation", defaultValue: state.officeLocation, style: "" },
        { id: 1, label: "Room Number", idExpr: "romIdpk", dataExpr: "romName", optionsData: roomnumbersData, stateName: "roomNumber", defaultValue: state.roomNumber, style: "" },
    ]
    const UpdateState = (key: string, value: string) => {
        setState((prev) => ({ ...prev, [key]: value }));
    }


    const UpdateTelephoneDirectory = async () => {


        try {
            const res = await UpdateDataFunc(`TelephoneDir/UpdateTelephoneDirectory/${state.empIdpk}`,
                {
                    "empIdpk": state?.empIdpk,
                    "empMobNo": state?.empMobNo,
                    "empMobNox": state?.empMobNox,
                    "empHideMobNo": state?.empHideMobNo,
                    "empIntercom": state?.empIntercom,
                    "empOfficialEmail": state?.empOfficialEmail,
                    "empPersonalEmail": state?.empPersonalEmail,
                    "empOfficeLocationIdfk": state?.empOfficeLocationIdfk,
                    "empRoomIdfk": state?.empRoomIdfk,
                    "empEditedBy": userId
                }
            )

            if (res.status) {

                setModalData({
                    state: true,
                    title: "Update?",
                    message: "Record Updated Successfully!",
                    icon: "question",
                    okText: "Yes",
                    cancelText: "No",
                    disableCancel: true,
                    okHandler: async () => {
                        setModalData(prev => ({
                            ...prev,
                            state: false,

                        }))
                    },
                    cancelHandler: () => {
                        setModalData(prev => ({
                            ...prev,
                            state: false,

                        }))
                    }
                })

                revalidate((prev: any) => ({
                    employee: !prev.employee,
                    director: !prev.director,
                    manager: !prev.manager

                }))


            }


        }
        catch (e) {

            const errorMessage = e.response?.data?.message
            console.log("error:", e)

            setModalData({
                state: true,
                title: "Warning",
                message: `${e.response?.data?.isSuccess ? errorMessage : errorMessage ?? "An error occured while submitting data, try again!"}`,
                icon: "warning",
                okText: "Ok",
                cancelText: "No",
                disableCancel: true,
                okHandler: async () => {
                    setModalData(prev => ({
                        ...prev,
                        state: false,

                    }))
                },
                cancelHandler: () => {
                    setModalData(prev => ({
                        ...prev,
                        state: false,

                    }))
                }
            })

        }

    }

    return (
        <div className='w-full h-full '>
            {/* Top div */}
            <ModalTemplate
                width={600}
                icon={modalData.icon}
                disableCancel={modalData.disableCancel}
                cancelText={modalData.cancelText}
                open={modalData.state}
                okHandler={modalData.okHandler}
                cancelHandler={modalData.cancelHandler}
                message={modalData.message}
                okText={modalData.okText}
                title={modalData.title}
            />
            {toggleForm && (<div className='w-full h-full border-[1px] rounded'>

                <p id={"form_header"} className='pl-4 py-0.5 font-medium bg-slate-50'>{"Directory Details"}</p>
                <section className='w-full flex justify-between '>
                    {/* leftSide */}
                    <aside className='w-1/2  flex p-2 '>
                        {/* Avatar */}
                        <aside className='flex '>
                            <div className=' justify-end items-end'>
                                <AvatarTemplate size={130} avatarIcon={Pixis ? `data:image/png;base64,${Pixis}` : account} />

                            </div>

                            <div className=' flex items-end '>
                                <CheckboxTemlate
                                    withBorders
                                    customDisabled={false}
                                    defaultValue={PictureShow}
                                    useCallbackFunc={true}
                                    setCheckboxOnchange={(e, _) => {
                                        setPictureShow(e)
                                    }}
                                />
                            </div>

                        </aside>
                        {/* details */}
                        <aside className='w-full'>
                            <Form
                                className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded-md py-2 px-2 "
                                name="basic"
                                labelCol={{ span: 6 }}
                                wrapperCol={{ span: 24 }}
                                initialValues={{ remember: true }}
                                autoComplete="off"
                                size="small"
                                layout="horizontal"
                                disabled={false}
                            >
                                {directoryDetail.map(({ id, label, stateName, defaultValue, style, additionalwidgetValue, additonallabel }) => {
                                    return (
                                        <React.Fragment key={id}>
                                            {id === 4 ?
                                                <InputsTemplate disabledStatus={false}
                                                    key={id}
                                                    defaultValue={defaultValue}
                                                    inputType={hideMob_No ? "password" : "text"}
                                                    useCallbackFunc
                                                    label={label}
                                                    span
                                                    inputStyle={{ width: id === 4 ? "72%" : id !== 0 ? "50%" : "45%" }}
                                                    orderOnchange={(e: string) => UpdateState(stateName, e)}
                                                    additionalWidget={
                                                        <>
                                                            <CheckboxTemlate useCallbackFunc label={"Hide Mob.No?"} defaultValue={hideMob_No} withBorders style={{ marginLeft: 3 }} customDisabled={false} setCheckboxOnchange={(e) => { setHideMob_No(e) }} />
                                                        </>
                                                    }
                                                />

                                                :
                                                <InputsTemplate
                                                    key={id}
                                                    disabledStatus={false}
                                                    defaultValue={defaultValue}
                                                    useCallbackFunc
                                                    label={label}
                                                    span
                                                    inputStyle={{ width: id === 4 ? "72%" : id !== 0 ? "50%" : "45%" }}
                                                    orderOnchange={(e: string) => UpdateState(stateName, e)}
                                                    additionalWidget={<>
                                                        <InputsTemplate useCallbackFunc={true} defaultValue={additionalwidgetValue} span inputStyle={{ marginLeft: 3, width: "100%" }} disabledStatus={false} orderOnchange={(e: string) => { UpdateState(additonallabel, e) }} />
                                                    </>

                                                    }
                                                />
                                            }
                                        </React.Fragment>
                                    )
                                })}

                            </Form>
                        </aside>
                    </aside>

                    {/* rightSide */}
                    <aside className="w-1/2 ">
                        <Form
                            className="w-full  dark:bg-slate-900 dark:text-darkModeSkin-base  rounded-md py-2 px-2 "
                            name="basic"
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 24 }}
                            initialValues={{ remember: true }}
                            autoComplete="off"
                            size="small"
                            layout="horizontal"
                        >
                            {rightInputs.map(({ id, label, stateName, defaultValue, style }) => {
                                return (
                                    <InputsTemplate
                                        key={id}
                                        useCallbackFunc
                                        span
                                        label={label}
                                        disabledStatus={false}
                                        defaultValue={defaultValue}
                                        orderOnchange={(e: string) => UpdateState(stateName, e)}
                                    />
                                )
                            })}

                            {rightSelects.map(({ id, label, idExpr, dataExpr, optionsData, stateName, defaultValue, style }) => {
                                return (

                                    <InputSelect_template
                                        key={id}
                                        placeHolder={defaultValue}
                                        handleRefresh={() => { }}
                                        label={label}
                                        options={optionsData}
                                        disabled={false}

                                        useCallFunc
                                        idexpr={idExpr}
                                        dataexp={dataExpr}
                                        selectedValue={(e: any) => {
                                            UpdateState(stateName!, e[dataExpr]);
                                        }}
                                    />
                                )
                            })}
                        </Form>
                        <div className="w-full flex justify-end px-2">
                            <SaveButton disableButton={false} disabled_status={false} useCallbackFunc={true}
                                handleSave={() => {

                                    setModalData({
                                        state: true,
                                        title: "Update?",
                                        message: "Are you sure you want to update this record?",
                                        icon: "question",
                                        okText: "Yes",
                                        cancelText: "No",
                                        disableCancel: false,
                                        okHandler: async () => {
                                            setModalData(prev => ({
                                                ...prev,
                                                state: false,

                                            }))
                                            await UpdateTelephoneDirectory()
                                        },
                                        cancelHandler: () => {
                                            setModalData(prev => ({
                                                ...prev,
                                                state: false,

                                            }))
                                        }
                                    })
                                }}
                            />

                        </div>
                    </aside>

                </section>
            </div>)
            }
            {/* Datagrid */}
            <div className='w-full h-full px-2'>
                <Datagrid_template
                          showColumnHeaders={false}
                          className={`flex flex-row justify-center items-center place-items-center place-content-center`}

                    // dataRowRender={
                    //     (e) => {
                    //         let data = e?.data as GetGroupDataInterface
                    //         return (
                    //             <section className='flex flex-col space-y-3 mt-10 ml-[40rem] border-2 border-slate-800 w-72 p-2 flex-wrap'>
                    //                 <span className='flex justify-center'>
                    //                     <AvatarTemplate size={88} avatarIcon={`data:image/png;base64,${data.ppxPicture ?? account}`} avatarName={data?.empname} />
                    //                 </span>

                    //                 <ul className="flex flex-col space-y-1 py-1 ">
                    //                     {

                    //                         columns.map(({id, caption, dataField }) => {

                    //                             return (
                    //                                ( caption !== 'PICTURE' ) && 
                    //                                 <li className='flex flex-row space-x-4 items-center flex-wrap'>
                    //                                     <p>{caption}</p>
                    //                                     <p>{data[dataField]}</p>
                    //                                 </li>
                    //                             )
                    //                         })
                    //                     }


                    //                 </ul>

                    //             </section>

                    //         );
                    //     }
                    // }
                    gridheight={toggleForm ? formPlusDataGridHeight : DataGridHeightOnly}
                    rowDoubleClicked={() => { setToggleForm((prev) => !prev) }}
                    columns={columns}
                    data={gridData}
                    columnCellRender={setImageOnCellRender}
                    onContentReady={(e) => {
                        const getDataSource = e.component.getDataSource().items();
                        if (getDataSource) {

                            let entries = getDataSource[1] as GetGroupDataInterface;

                            setState({
                                name: entries?.empname,
                                staffNo: entries?.empstaffno,
                                dept: entries?.dptname,
                                section: entries?.sxnshtname,
                                unit: entries?.untName,
                                location: entries?.locshtname,
                                intercom: entries?.empIntercom,
                                officeLocation: entries?.locname,
                                roomNumber: entries?.romname,

                                "empIdpk": entries?.empIdpk,
                                "empMobNo": entries?.empmobno,
                                "empMobNox": entries?.empMobNox,
                                "empHideMobNo": entries?.empHideMobNo,
                                "empIntercom": entries?.empIntercom,
                                "empOfficialEmail": entries?.empOfficialEmail,
                                "empPersonalEmail": entries?.empPersonalEmail,
                                "empOfficeLocationIdfk": entries?.ofcIdpk,
                                "empRoomIdfk": entries?.romIdpk,
                                "empEditedBy": userId
                            })

                            setPixis(entries?.ppxPicture ?? undefined)
                        }
                    }}
                    onRowClick={(e: GetGroupDataInterface) => {
                        setState({
                            name: e?.empname,
                            staffNo: e?.empstaffno,
                            dept: e?.dptname,
                            section: e?.sxnshtname,
                            unit: e?.untName,
                            location: e?.locshtname,
                            intercom: e?.empIntercom,
                            officeLocation: e?.locname,
                            roomNumber: e?.romname,

                            "empIdpk": e?.empIdpk,
                            "empMobNo": e?.empmobno,
                            "empMobNox": e?.empMobNox,
                            "empHideMobNo": e?.empHideMobNo,
                            "empIntercom": e?.empIntercom,
                            "empOfficialEmail": e?.empOfficialEmail,
                            "empPersonalEmail": e?.empPersonalEmail,
                            "empOfficeLocationIdfk": e?.ofcIdpk,
                            "empRoomIdfk": e?.romIdpk,
                            "empEditedBy": userId

                        })

                        setPixis(e?.ppxPicture ?? undefined)

                    }}
                    onDatagridInitialized={
                        (e) => {
                            datagridRef.current = e.component;
                        }
                    }

                    visibleCol={[
                        { id: 0, caption: "PICTURE", action: PictureShow }
                    ]}

                />
            </div>
        </div>
    )
}

export default EDM