import { forwardRef } from "react";
import { Tooltip } from "devextreme-react";
import lineitem from '../../../../../assets/lineitem.png';
import gaugestylelinearhorizontal from '../../../../../assets/gaugestylelinearhorizontal.png';
import drilldown from '../../../../../assets/drilldown.png';
import satellite from '../../../../../assets/satellite.png';
import switchBlue from '../../../../../assets/switchBlue.png';
import capacitorWhite from '../../../../../assets/capacitorWhite.png';
import customer from '../../../../../assets/customer.png';
import gaugestylerightquartercircular from '../../../../../assets/gaugestylerightquartercircular.png';
import { CheckboxTemlate } from "../../../../../templates/checkbox";
import { DateTemplate } from "../../../../../templates/date";
import { InputsTemplate } from "../../../../../templates/input";
import { SelectsTemplate } from "../../../../../templates/select";
import { RefreshButton } from "./utils";
import { FaBinoculars } from "react-icons/fa";

export const StationReadingsToolbar = forwardRef(() => {
    return (
        <div className="border-2 border-muted w-full h-auto rounded-md">
            < section className="flex items-center w-full h-full space-x-1 px-1" >
                <aside className="flex space-x-0.5">
                    {
                        icons.map(({ src, onClick }, index) => (
                            <IconsContainer
                                url={src}
                                name="Midnight Conditions"
                                tooltip="Click to initiate the capturing of midnight conditions for the station" />
                        ))
                    }
                </aside>
                <aside className="flex flex-1 space-x-1 items-center">
                    < SelectsTemplate label="Search Criteria" extraWidget={false} selectStyle={{ height: "25px" }} disabled={false} options={["Station"]} span={true} useCusWidth cusWidth={"90px"} disabledCustomWidth={true} />
                    < section className="flex-1" >
                        <InputsTemplate label="Search Text" inputStyle={{ height: "25px" }} span={true} disabledStatus={false} />
                    </section >
                </aside>
                <aside className="flex space-x-1 items-center mr-2">
                    <DateTemplate disabled={false} />
                    <DateTemplate disabled={false} />
                    <li
                        id="multiline-rows"
                        style={{
                        }}
                        className="hover:cursor-pointer mr-1.5 text-xs text-gray-600 rounded flex items-center"
                    >
                        <CheckboxTemlate
                            withBorders
                            defaultValue={true}
                            customDisabled={false}
                        />

                        <Tooltip
                            target="#multiline-rows"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p className=" text-left font-semibold">Multiline Rows</p>
                            <p>Click to expand or collapse multiple rows in the table.</p>
                        </Tooltip>
                    </li>
                    <div className="p-1 border-[1px] rounded-sm">
                        <FaBinoculars size={15.5} color="#007AFF" />
                    </div>
                    <RefreshButton />
                </aside>
            </section >
        </div >
    )
});


export const IconsContainer = ({ url, name, tooltip, onClick }: { url: string, name?: string, tooltip?: string, onClick?: () => void }) => {
    return (
        <div>
            <li
                onClick={onClick && onClick}
                style={{ borderWidth: "1px" }}
                id={name}
                className="hover:cursor-pointer mr-1.5 border-slate-200 text-xs px-1 py-1 text-gray-600 rounded flex items-center"
            >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
                    {" "}
                    <img
                        className="dark:bg-slate-900 dark:text-gray-100 duration-100 w-full"
                        src={url} alt={name}
                    />
                </span>
                <Tooltip
                    target={`#${name}`}
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    hideOnOutsideClick={true}
                >
                    <p className=" text-left font-semibold">{name}</p>
                    <p>{tooltip}</p>
                </Tooltip>
            </li>
        </div>
    );
}

type Icon = {
    src: string;
    name: string;
    toolTip: string;
    onClick: () => void
}

const icons: Icon[] = [
    {
        src: gaugestylelinearhorizontal,
        name: "Midnight Conditions",
        toolTip: "Click to initiate the capturing of midnight conditions for the station",
        onClick: () => { },
    },
    {
        src: drilldown,
        name: "Midnight Conditions",
        toolTip: "Click to initiate the capturing of midnight conditions for the station",
        onClick: () => { }
    },
    {
        src: lineitem,
        name: "Midnight Conditions",
        toolTip: "Click to initiate the capturing of midnight conditions for the station",
        onClick: () => { }
    },
    {
        src: satellite,
        name: "Midnight Conditions",
        toolTip: "Click to initiate the capturing of midnight conditions for the station",
        onClick: () => { }
    },
    {
        src: gaugestylerightquartercircular,
        name: "Midnight Conditions",
        toolTip: "Click to initiate the capturing of midnight conditions for the station",
        onClick: () => { }
    },
    {
        src: capacitorWhite,
        name: "Midnight Conditions",
        toolTip: "Click to initiate the capturing of midnight conditions for the station",
        onClick: () => { }
    },
    {
        src: switchBlue,
        name: "Midnight Conditions",
        toolTip: "Click to initiate the capturing of midnight conditions for the station",
        onClick: () => { }
    },
    {
        src: customer,
        name: "Midnight Conditions",
        toolTip: "Click to initiate the capturing of midnight conditions for the station",
        onClick: () => { }
    },
]