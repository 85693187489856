import { useEffect, useRef, useState } from 'react';
import { Checkbox, Form, Modal, Select } from 'antd';
import type { DraggableData, DraggableEvent } from 'react-draggable';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { checkboxTypes, colorsType, inputsTypes, modalPropTypes, searchTextTypes, selectExprsTypes, selectsTypes } from '../../../components/human-resource/setups/data/types/selectsTypes';
import { ModalTemplate } from '../../../templates/modal';
import { selectObjectTypes } from '../../../components/human-resource/setups/data/types/selectsTypes';
import { CheckboxTemlate } from '../../../templates/checkbox';
import reset from '../../../assets/refresh.png'
import { SaveButton } from '../../accessories/buttons';
import { PatchDataFunc } from '../../../functions/patch';
import { useAccountInfo } from '../../../hooks/useAccountInfo';
import { updateModalData } from '../../accessories/component_infos';
import useFetch from '../../../hooks/useFetch';
import { setGeneralValue } from '../../../features/generalSlice';


interface props {
  open: boolean
  handleCancel: () => void
}

interface stateTypes {
  taskManagerOnStartup: boolean
  newsflashOnStartup: boolean
  newsflashBarOnStartup: boolean
  rightNavigatorOnStartup: boolean
  birthdayInNewsFlash: boolean
  workAnniversaryInNewsFlash: boolean
  tipOfTheDayOnStartup: boolean
  alertsWindowOnStartup: boolean
  ApplysettingsToAllUsers: boolean
  userChangeAppColours: boolean

  defaultApplication: { id: number, value: string , key: string}
  formHeaderBgColor: { id: string, key: number, value: string }
  footerBgColor: { id: string, key: number, value: string }
  mainNavigatorBgColor: { id: string, key: number, value: string }
  mainNavigatorTextColor: { id: string, key: number, value: string }
  rightNavigatorTextColor: { id: string, key: number, value: string }
  rightNavigatorBgColor: { id: string, key: number, value: string }
  topNavigatorBgColor: { id: string, key: number, value: string }
  topNavigatorTextColor: { id: string, key: number, value: string }
  newsflashBgColor: { id: string, key: number, value: string }
  newsFlashTextColor: { id: string, key: number, value: string }
  applyColorsToAllUsers: boolean
}

const NavUserSettings = ({ open, handleCancel }: props) => {
  
  const token = useSelector((state: RootState) => state.user.user?.token); //token
  const borderTheme = useSelector((state: RootState) => state.general.cssBorderClass)
  const [employeeID, userID, user] = useAccountInfo();


  const [disabled, setDisabled] = useState(false); 
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
  const draggleRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const [modalAlert, setModalAlert] = useState<modalPropTypes>({
    state: false,
    title: '',
    message: ''
  })
  const [passwordRules, setPasswordRules] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(updateModalData);
  const [icon, setIcon] = useState<'warning' | 'success' | 'question'>();

  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };



  //FORM FUNCTIONALITIES
  const [states, setState] = useState<stateTypes>({
    taskManagerOnStartup: false,
    newsflashOnStartup: false,
    newsflashBarOnStartup: false,
    rightNavigatorOnStartup: false,
    birthdayInNewsFlash: false,
    workAnniversaryInNewsFlash: false,
    tipOfTheDayOnStartup: false,
    alertsWindowOnStartup: false,
    ApplysettingsToAllUsers: false,
    userChangeAppColours: false,

    defaultApplication: { id: 0, value: '', key: ''},
    formHeaderBgColor: { id: '', key: 0, value: '' },
    footerBgColor: { id: '', key: 0, value: '' },
    mainNavigatorBgColor: { id: '', key: 0, value: '' },
    mainNavigatorTextColor: { id: '', key: 0, value: '' },
    rightNavigatorBgColor: { id: '', key: 0, value: '' },
    rightNavigatorTextColor: { id: '', key: 0, value: '' },
    topNavigatorBgColor: { id: '', key: 0, value: '' },
    topNavigatorTextColor: { id: '', key: 0, value: '' },
    newsFlashTextColor: { id: '', key: 0, value: '' },
    newsflashBgColor: { id: '', key: 0, value: '' },
    applyColorsToAllUsers: false
  })

  const { taskManagerOnStartup, newsFlashTextColor, newsflashBgColor, newsflashBarOnStartup, newsflashOnStartup, rightNavigatorBgColor, rightNavigatorOnStartup, rightNavigatorTextColor, birthdayInNewsFlash, workAnniversaryInNewsFlash, tipOfTheDayOnStartup, alertsWindowOnStartup, applyColorsToAllUsers, userChangeAppColours, defaultApplication, formHeaderBgColor, footerBgColor, mainNavigatorBgColor, mainNavigatorTextColor, topNavigatorBgColor, topNavigatorTextColor, ApplysettingsToAllUsers } = states;

  //handle state update
  const updateState = (stateName: string, value: null | string | modalPropTypes | any[] | any | boolean | number | selectObjectTypes): void => { //update single state
    setState((prev) => ({ ...prev, [stateName]: value }));
  }


  const defaultApp: selectObjectTypes[] = [
    { id:0, key: "0", value: 'AD Mgr' },
    { id:1, key: "1", value: 'Appl Conn Mgr' },
    { id:2, key: "5_5", value: 'Asset Mgr-Comm' },
    { id:3, key: "5_3", value: 'Asset Mgr-Comps' },
    { id:4, key: "5_4", value: 'Asset Mgr-RealEst' },
    { id:5, key: "5_2", value: 'Asset Mgr-Trans' },
    { id:6, key: "6", value: 'Asset Mgr-Transm' },
    { id:7, key: "3_2_5", value: 'Assoc Mgr' },
    { id:8, key: "8", value: 'Budget' },
    { id:9, key: "9", value: 'Display System' },
    { id:10, key: "4", value: 'Doc Manager' },
    { id:11, key: "1_17", value: 'eLog Book' },
    { id:12, key: "12", value: 'eVoting' },
    { id:13, key: "7", value: 'Finanace-Budget' },
    { id:14, key: "11_1_2", value: 'Finance-Ops' },
    { id:15, key: "2", value: 'General' },
  ]

  const stone = `bg-stone-700`
  const cyan = `bg-cyan-300`
  const neutral = `bg-neutral-800`
  const navyx = `bg-blue-700`

  const colors: colorsType[] = [
    { id: 0, label: 'White', color: 'white' },
    { id: 1, label: 'Gray-100', color: 'gray-50' },
    { id: 2, label: 'Gray-150', color: 'gray-100' },
    { id: 3, label: 'LightDark', color: 'stone-700' },
    { id: 4, label: 'Gray-200', color: 'gray-200' },
    { id: 5, label: 'Gray-300', color: 'gray-300' },
    { id: 6, label: 'LightYellow', color: 'yellow-100' },
    { id: 7, label: 'Cyan', color: 'cyan-300' },
    { id: 8, label: 'NavyBlue', color: 'blue-700' },
    { id: 9, label: 'Black', color: 'black' },
  ]

  // checkboxes
  const checkboxes: checkboxTypes[] = [
    { id: 0, label: 'Launch Task Manager On Startup?', defaultValue: taskManagerOnStartup, stateName: 'taskManagerOnStartup' },
    { id: 1, label: 'Start Newsflash On Startup?', defaultValue: newsflashOnStartup, stateName: 'newsflashOnStartup' },
    { id: 2, label: 'Show Newsflash Bar On Startup?', defaultValue: newsflashBarOnStartup, stateName: 'newsflashBarOnStartup' },
    { id: 3, label: 'Show Right Navigator On Startup?', defaultValue: rightNavigatorOnStartup, stateName: 'rightNavigatorOnStartup' },
    { id: 4, label: 'Show My Birthday in Newsflash?', defaultValue: birthdayInNewsFlash, stateName: 'birthdayInNewsFlash' },
    { id: 5, label: 'Show My Work Anniversary in Newsflash?', defaultValue: workAnniversaryInNewsFlash, stateName: 'workAnniversaryInNewsFlash' },
    { id: 6, label: 'Show Tip of Day Window on Startup?', defaultValue: tipOfTheDayOnStartup, stateName: 'tipOfTheDayOnStartup' },
    { id: 7, label: 'Show Alert Window on Startup?', defaultValue: alertsWindowOnStartup, stateName: 'alertsWindowOnStartup' },
  ]

  const selects: selectsTypes[] = [
    { id: 0, label: 'Default Application', idExpr: 'id', dataExpr: 'value', idKey:"key", optionsData: defaultApp, stateName: 'defaultApplication', defaultValue: defaultApplication.value },
    { id: 1, label: 'Form Header Background Colour', idKey: 'id', idExpr: 'color', dataExpr: 'label', optionsData: colors, stateName: 'formHeaderBgColor', defaultValue: formHeaderBgColor?.id },
    { id: 2, label: 'Form Footer Background Colour', idKey: 'id', idExpr: 'color', dataExpr: 'label', optionsData: colors, stateName: 'footerBgColor', defaultValue: footerBgColor?.id },
    { id: 3, label: 'Main Navigator Background Colour', idKey: 'id', idExpr: 'color', dataExpr: 'label', optionsData: colors, stateName: 'mainNavigatorBgColor', defaultValue: mainNavigatorBgColor?.id },
    { id: 4, label: 'Main Navigator Text Colour', idKey: 'id', idExpr: 'color', dataExpr: 'label', optionsData: colors, stateName: 'mainNavigatorTextColor', defaultValue: mainNavigatorTextColor?.id },
    { id: 5, label: 'Right Navigator Background Colour', idKey: 'id', idExpr: 'color', dataExpr: 'label', optionsData: colors, stateName: 'rightNavigatorBgColor', defaultValue: rightNavigatorBgColor?.id },
    { id: 6, label: 'Right Navigator Text Colour', idKey: 'id', idExpr: 'color', dataExpr: 'label', optionsData: colors, stateName: 'rightNavigatorTextColor', defaultValue: rightNavigatorTextColor?.id },
    { id: 7, label: 'Top Navigator Background Colour', idKey: 'id', idExpr: 'color', dataExpr: 'label', optionsData: colors, stateName: 'topNavigatorBgColor', defaultValue: topNavigatorBgColor?.id },
    { id: 8, label: 'Top Navigator Text Colour', idKey: 'id', idExpr: 'color', dataExpr: 'label', optionsData: colors, stateName: 'topNavigatorTextColor', defaultValue: topNavigatorTextColor?.id },
    { id: 9, label: 'Newsflash Background Colour', idKey: 'id', idExpr: 'color', dataExpr: 'label', optionsData: colors, stateName: 'newsflashBgColor', defaultValue: newsflashBgColor?.id },
    { id: 10, label: 'Newsflash Text Colour', idKey: 'id', idExpr: 'color', dataExpr: 'label', optionsData: colors, stateName: 'newsFlashTextColor', defaultValue: newsFlashTextColor?.id },
  ]
  const disableModal = () => {
    setModalAlert({ ...modalAlert, state: false }) //disable confirm 
  }

  const substringsToRemove = ["-100", "-200", "-300", "-400", "-500", "-600", "-700", "-800", "-900"];
  const [refresh, setrefresh] = useState<boolean>(false);
  const [refreshDB, setrefreshDB] = useState<boolean>(false);
  

  const getTheme = localStorage.getItem("theme");


  // save and update setting 
  const postData = () => {

    setIcon('question')
    try {

      const res = PatchDataFunc(`UserSettings/CreateUserSetting?UserID=${userID}`, {
        "ustEmpIDfk": employeeID,
        "ustDefaultModuleIDfk": defaultApplication.id,
        "ustAllowUsersToChangeColours": userChangeAppColours,
        "ustshowTaskOnStartup": taskManagerOnStartup,
        "ustShowToDoOnStartup": true,
        "ustShowAdvertOnStartup": true,
        "ustShowTipOfDayOnStartup": tipOfTheDayOnStartup,
        "ustShowRightNavigatorOnStartup": rightNavigatorOnStartup,
        "ustStartNewsflashOnStartup": newsflashOnStartup,
        "ustShowNewsflashBarOnStartup": newsflashBarOnStartup,
        "ustShowWorkAnniversaryInNewsflash": workAnniversaryInNewsFlash,
        "ustShowBirthdayInNewsflash": birthdayInNewsFlash,
        "ustShowAlertsWindowOnStartup": alertsWindowOnStartup,
        "ustAppTheme": getTheme,
        "ustFormHeaderBackColourIDfk": formHeaderBgColor.key,
        "ustFormFooterBackColourIDfk": footerBgColor.key,
        "ustMainNavBackColourIDfk": mainNavigatorBgColor.key,
        "ustMainNavForeColourIDfk": mainNavigatorTextColor.key,
        "ustRightNavBackColourIDfk": rightNavigatorBgColor.key,
        "ustRightNavForeColourIDfk": rightNavigatorTextColor.key,
        "ustTopNavBackColourIDfk": topNavigatorBgColor.key,
        "ustTopNavForeColourIDfk": topNavigatorTextColor.key,
        "ustNewsflashBackColourIDfk": newsflashBgColor.key,
        "ustNewsflashForeColourIDfk": newsFlashTextColor.key
      })

      setIcon('success')
      setrefresh(!refresh);
      setrefreshDB(!refreshDB)
      setOpenModal(true);
      setModalData({ message: "Record updated successfully!", title: "Update Successful!", okText: "Ok", });
      localStorage.setItem("defaultApplication", JSON.stringify(defaultApplication));


    } catch (err: any) {
      setIcon('warning')
      setOpenModal(true);
      setModalData({ message: updateModalData.error.message, title: updateModalData.error.title, okText: "Ok" });
    }
  }

  // UserSettings
  const [userSettingsData, userSettingsError, userSettingsLoading] = useFetch(`UserSettings/GetUserSettingById?UserID=${userID}`,);

  const populateSettings = async (data:any) =>{
    const settings: any = [
      { stateName: 'taskManagerOnStartup', value: data.ustshowTaskOnStartup },
      { stateName: 'newsflashOnStartup', value: data.ustStartNewsflashOnStartup },
      { stateName: 'newsflashBarOnStartup', value: data.ustShowNewsflashBarOnStartup },
      { stateName: 'rightNavigatorOnStartup', value: data.ustShowRightNavigatorOnStartup },
      { stateName: 'birthdayInNewsFlash', value: data.ustShowBirthdayInNewsflash },
      { stateName: 'workAnniversaryInNewsFlash', value: data.ustShowWorkAnniversaryInNewsflash },
      { stateName: 'tipOfTheDayOnStartup', value: data.ustShowTipOfDayOnStartup },
      { stateName: 'alertsWindowOnStartup', value: data.ustShowAlertsWindowOnStartup },

    ]

    for (let i of settings) {
      updateState(i.stateName, i.value);

      dispatch(setGeneralValue({ expr: i.stateName, value: i.value }));
    }
  }

  const populateSettings2 = async (data: any) => {
    const defApp = defaultApp.find((item) => {
      return data.ustDefaultModuleIDfk === item.id;
    });

    const fhbgc = colors.find((item)=>{
        return  data.ustFormHeaderBackColourIDfk === item.id;
    })
    const fbgc = colors.find((item)=>{
        return  data.ustFormFooterBackColourIDfk === item.id;
    })
    const mnbgc = colors.find((item)=>{
        return  data.ustMainNavBackColourIDfk === item.id;
    })
    const mntc = colors.find((item)=>{
        return  data.ustMainNavForeColourIDfk === item.id;
    })
    const rnbgc = colors.find((item)=>{
        return  data.ustRightNavBackColourIDfk === item.id;
    })
    const rntc = colors.find((item)=>{
        return  data.ustRightNavForeColourIDfk === item.id;
    })
    const tnbgc = colors.find((item)=>{
        return  data.ustTopNavBackColourIDfk === item.id;
    })
    const tntc = colors.find((item)=>{
        return  data.ustTopNavForeColourIDfk === item.id;
    })
    const nfbgc = colors.find((item)=>{
        return  data.ustNewsflashBackColourIDfk === item.id;
    })
    const nftc = colors.find((item)=>{
        return  data.ustNewsflashForeColourIDfk === item.id;
    })

    // Dispatching all the color objects to redux
    dispatch(setGeneralValue({expr:'settingsColors', value:{defApp,fhbgc,fbgc,mnbgc,mntc,rnbgc,rntc,tnbgc,tntc,nfbgc,nftc}}))

    const rightSetitngs: any = [
      { stateName: 'defaultApplication', value: { id: defApp?.id ?? 0, value: defApp?.value ?? '', key:defApp?.key ?? '' } },
      { stateName: 'formHeaderBgColor', value: { key: fhbgc?.id ?? 0, id: fhbgc?.color ?? '' , value: fhbgc?.label ?? '' } },
      { stateName: 'footerBgColor', value: { key: fbgc?.id ?? 0, id: fbgc?.color ?? '' , value: fbgc?.label ?? '' } },
      { stateName: 'mainNavigatorBgColor', value: { key: mnbgc?.id ?? 0, id: mnbgc?.color ?? '' , value: mnbgc?.label ?? '' } },
      { stateName: 'mainNavigatorTextColor', value: { key: mntc?.id ?? 0, id: mntc?.color ?? '' , value: mntc?.label ?? '' } },
      { stateName: 'rightNavigatorBgColor', value: { key: rnbgc?.id ?? 0, id: rnbgc?.color ?? '' , value: rnbgc?.label ?? '' } },
      { stateName: 'rightNavigatorTextColor', value: { key: rntc?.id ?? 0, id: rntc?.color ?? '' , value: rntc?.label ?? '' } },
      { stateName: 'topNavigatorBgColor', value: { key: tnbgc?.id ?? 0, id: tnbgc?.color ?? '' , value: tnbgc?.label ?? '' } },
      { stateName: 'topNavigatorTextColor', value: { key: tntc?.id ?? 0, id: tntc?.color ?? '' , value: tntc?.label ?? '' } },
      { stateName: 'newsflashBgColor', value: { key: nfbgc?.id ?? 0, id: nfbgc?.color ?? '' , value: nfbgc?.label ?? '' } },
      { stateName: 'newsFlashTextColor', value: { key: nftc?.id ?? 0, id: nftc?.color ?? '' , value: nftc?.label ?? '' } },
    ]


    for (let c of rightSetitngs) {
      updateState(c.stateName, c.value);
      if (c.stateName === "defaultApplication"){
        localStorage.setItem("defaultApplication", JSON.stringify(c.value));

      }
      dispatch(setGeneralValue({ expr: c.stateName, value: c.value }));
    }
  }



  useEffect(() => {
    populateSettings(userSettingsData)
    populateSettings2(userSettingsData);  

  }, [ userSettingsData ])

  return (
    <>
      <Modal
        maskClosable={false}
        width={790}
        footer=''
        centered
        style={{ border: '1px solid rgb(59 130 246)', borderRadius: 9, paddingBottom: 0 }}
        title={
          <div
            style={{
              width: '100%',
              cursor: 'move',
            }}
            
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
            onFocus={() => { }}
            onBlur={() => { }}
          // end
          >
            My Settings
          </div>
        }
        open={open}
        onOk={() => { }}
        transitionName=''
        onCancel={() => {
          handleCancel();
        }}
        // Comment out Settings Draggable 
        // modalRender={(modal) => (
        //   <Draggable
        //     disabled={disabled}
        //     bounds={bounds}
        //     onStart={(event, uiData) => onStart(event, uiData)}
        //   >
        //     <div ref={draggleRef}>{modal}</div>
        //   </Draggable>
        // )}
      >
        <>
          {/* modal alert */}
          <ModalTemplate
            icon={icon}
            // disableCancel={icon === 'question' ? false : true}
            // cancelText={'No'}
            open={openModal}
            disableCancel={true}
            okHandler={() => { modalData == updateModalData ? postData() : setOpenModal(false) }}
            cancelHandler={() => {
              
              setOpenModal(false);

              if (icon === 'success') {
                setTimeout(() => { //disable reset modal
                  handleCancel();
                }, 200);
              }
            }}

            message={modalData.message}
            okText={modalData.okText}
            title={modalData.title} />

          <hr className=' mb-2' />
          <main className=" flex flex-row justify-between">
            {/* left form */}
            <Form
              className=''
              style={{ width: 250 }}
              labelCol={{ span: 23 }}
            >
              {/* form items */}
              {checkboxes.map(({ id, label, stateName, defaultValue }) =>
                <div key={id} className="">
                  <CheckboxTemlate
                    style={{ marginBottom: -3 }}
                    customDisabled={false}
                    label={label}
                    useCallbackFunc
                    withBorders
                    setCheckboxOnchange={(CheckValue) => {
                      updateState(stateName!, CheckValue);
                    }}
                    defaultValue={defaultValue}
                  />
                </div>
              )}

              <div className=" mt-6">
                <CheckboxTemlate
                  customDisabled={false}
                  labelStyle=' text-red-600'
                  style={{ marginBottom: -3 }}
                  useCallbackFunc
                  withBorders
                  setCheckboxOnchange={(check) => updateState('ApplysettingsToAllUsers', check)}
                  label='Apply Settings to All Users' />

                <CheckboxTemlate
                  customDisabled={false}
                  labelStyle=' text-red-600'
                  useCallbackFunc
                  style={{ marginBottom: -3 }}
                  withBorders
                  setCheckboxOnchange={(check) => updateState('userChangeAppColours', check)}
                  label='Allow Users To Change App Colours?' />
              </div>
            </Form>

            {/* right form */}
            <Form
              className=' '
              size='small'
              style={{ width: 'calc(100% - 265px)' }}
            >
              {selects.map(({ id, idKey, label, defaultValue, stateName, optionsData, idExpr, dataExpr }) =>
                <Form.Item
                  key={id}
                  wrapperCol={{ span: 22 }}
                  labelCol={{ span: 12 }}
                  label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{label}</p>}
                >
                  <span
                    className=" flex items-center flex-row "
                    style={{ width: 'calc(100%)' }}
                  >
                    <span
                      style={{ width: 'calc(100% - 47px)' }}
                    >
                      <Select
                        style={{ width: '100%' }}
                        value={defaultValue}
                        onChange={(option: any) => {
                          const optionData = JSON.parse(option);
                          updateState(stateName!, {
                            key: optionData[idKey!],
                            id: optionData[idExpr!],
                            value: optionData[dataExpr!]
                          })
                        }}
                      >
                        {optionsData?.map((option: any) =>
                          <Select.Option key={option[idExpr!]} value={JSON.stringify(option)}>{option[dataExpr!]}</Select.Option>
                        )}
                      </Select>
                    </span>

                    <span
                      style={{ height: 22.5, marginBottom: 2, borderWidth: 1, width: 19, borderColor: borderTheme }}
                      className={`ml-1 flex justify-center items-center bg-${defaultValue} hover:cursor-pointer border-gray-300 rounded `}>
                    </span>

                    {/* refresh */}
                    <span
                      onClick={() => {
                        updateState(stateName!, {
                          id: optionsData[0],
                          value: optionsData[0]
                        })
                      }}
                      style={{ height: 22.5, marginBottom: 2, borderWidth: 1, width: 23.9 }}
                      className='ml-1 flex justify-center items-center hover:cursor-pointer border-gray-300 rounded'>
                      <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src={reset} alt="reset" />
                    </span>
                  </span>
                </Form.Item>
              )}

              <div style={{ marginLeft: 237.5, marginRight: 50, borderWidth: 1 }} className=" border-gray-300 rounded  px-2 flex mt-0.5 flex-row bg-gray-100 py-0.5 items-center justify-between">
                <p className=' text-red-600 text-xs'>Apply Colours to All Users</p>
                <Checkbox checked={states.applyColorsToAllUsers} onChange={(e: any) => { updateState('applyColorsToAllUsers', e.target.checked) }} />
              </div>

              <div style={{ marginRight: 50 }} className=" mt-1 flex flex-row justify-end">
                <SaveButton
                  disableButton={false}
                  disabled_status={false}
                  handleSave={() => { setOpenModal(true); setModalData(updateModalData); setIcon('question'); }}
                />
              </div>
            </Form>
          </main>
        </>
      </Modal>
    </>
  );
};

export default NavUserSettings;