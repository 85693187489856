import {
  HtmlEditor,
  Image,
  ImageSettingsModel,
  Inject,
  Link,
  RichTextEditorComponent,
} from "@syncfusion/ej2-react-richtexteditor";
import * as React from "react";

interface props {
  height?: number | string;
  content: string;
}

export const RichTextViewerTemplate: React.FC<props> = ({
  height,
  content,
}) => {
  const imageSettings: ImageSettingsModel = {
    allowedTypes: [".jpeg", ".jpg", ".png"],
    display: "inline",
    width: "250px",
    height: "250px",
    saveFormat: "Base64",
  };
  return (
    <>
      <main className="border-[1px] w-full rounded-b px-3 pt-3 overflow-y-scroll">
        <RichTextEditorComponent
          readOnly={true}
          readonly={true}
          value={content ?? "<p> </p>"}
          insertImageSettings={imageSettings}
          onChange={() => {}}
          height={height ?? 150}
        >
          <Inject services={[Image, Link, HtmlEditor]} />
        </RichTextEditorComponent>
      </main>
    </>
  );
};
