import { createSlice } from "@reduxjs/toolkit";


interface mainState {
    gridtable: any;
    tasIdpk : any;
    taskFooterUpdate: boolean;
    taskFormStatus : boolean;
    selectedRecord : any | null;
    ppe_request_update_route : boolean | null;
    ppe_req_selects : any
}
 
var initialState = {
    gridtable : [],
    tasIdpk : 0,
    taskFooterUpdate : false,
    taskFormStatus: true,
    selectedRecord : [],
    ppe_request_update_route : true,
    ppe_req_selects : []
} as mainState;


export const taskstateSlice = createSlice({
    name: 'addTask',
    initialState,

    reducers : {
        // this is an action function with a func name of clickedViewedItem
        task_form_disable : (state, item) => {
            state.taskFormStatus = item.payload
            
        },

        task_footer_table : (state, item) => {
            state.gridtable = item.payload
            console.log(item.payload);
        },
        task_footer_table_selected : (state, item) => {
            state.selectedRecord = (item.payload)
            console.log(item.payload);
        },

        task_tasIdpk : (state, item) => {
            state.tasIdpk = item.payload
            console.log("taskIUdpk " + item.payload)
            alert(item.payload)
        }
        ,
        task_footer_update : (state, item) => {
            state.taskFooterUpdate = item.payload
        },

        //  PPE REQUEST
        update_route_state : (state,item) => {
            state.ppe_request_update_route = item.payload
        },
            
    }
})

export const {task_footer_table,task_footer_table_selected, task_tasIdpk,task_footer_update,task_form_disable,update_route_state} = taskstateSlice.actions;
export default taskstateSlice.reducer;


























