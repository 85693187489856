import React, { useState, useCallback, useRef, useEffect } from 'react'
import { Form, Input, Select, DatePicker, Checkbox } from 'antd';
import { CheckBox } from 'devextreme-react/check-box';
import refresh from '../../../../assets/refresh.png'
import dayjs from 'dayjs';
import { SaveButton, NewButton, UpdateButton, Cancel } from '../../../../accessories/buttons';
import { RootState } from '../../../../../app/store';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { api_url, saveModalData, updateModalData } from '../../../../accessories/component_infos';
import { setGeneralValue, settask_footer_table_refresh } from '../../../../../features/generalSlice';
import { setciaDetailsValue, task_footer_table_selected } from '../../../../../features/forms/entries/safetyManager/ppeRequest';
import { ModalTemplate } from '../../../../../templates/modal';
import { selectsTypes } from '../../../../human-resource/setups/data/types/selectsTypes';
import useFetch from '../../../../../hooks/useFetch';
import { SelectsTemplate } from '../../../../../templates/select';
import { DateTemplate } from '../../../../../templates/date';
import { UpdateDataFunc } from '../../../../../functions/update';
import { PostDataFunc } from '../../../../../functions/post';
import { useBusyLoader } from '../../../../../hooks/useBusyLoader';
import { useAccountInfo } from '../../../../../hooks/useAccountInfo';
import { FormModal } from '../../../../accessories/formModal';
import { CI } from '../../CI/Cooperate_initiative';


interface stateTypes {
    period: { id: number; name: string }
    periodEnd: { id: number; name: string }
    mission: { id: number; name: string }
    objective: { id: number; name: string }
    initiative: { id: number; name: string }
    active: boolean
    // remarks:string;
}

interface props {
    remarks?: string;
    setRemarks?: (e: string) => void;
    selectedRecordx?: any;
    setSelectedRecordx?: (e: any) => void
    refreshGrid :()=> void
}


export const CIA_Details_Form = ({ remarks, setRemarks, selectedRecordx, setSelectedRecordx ,refreshGrid}: props) => {
    const { Option } = Select;
    const { TextArea } = Input;

    const [busyLoader, setBusyLoader] = useBusyLoader();
    const [userId, employeeId] = useAccountInfo();
    const borderTheme = useSelector((state:RootState)=>state.general.cssBorderClass)

    // import { AssignedDept, AssignedLocation, AssignedSelection, AssignedUnit, Batch, Group, PPE_Select, RequestWindow, StaffName } from './PPE_selects';


    const [states, setStates] = useState<stateTypes>({
        mission: { id: 0, name: '' },
        period: { id: 0, name: '' },
        periodEnd: { id: 0, name: '' },
        objective: { id: 0, name: '' },
        initiative: { id: 0, name: '' },
        active: false,
        // remarks:'',
    })

    const updateState = (key: string, value: any) => {
        setStates((prev: any) => ({ ...prev, [key]: value }))
    }

    console.log(states)


    const dispatch = useDispatch()

    const form_disable = useSelector((state: RootState) => state.general.formDisabled)
    const update_state_change = useSelector((state: RootState) => state.addTask.taskFooterUpdate)


    const [refreshValue, setRefreshValue] = useState(false);
    const [showModalDisableButton, setshowModalDisableButton] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [modalData, setModalData] = useState<any>(saveModalData);
    const [icon, setIcon] = useState<'warning' | 'success' | 'question'>();
    const [updatingstatus, setUpdatingstatus] = useState(false);
    const [showCancel, setshowCancel] = useState(false);
    const [Active, setActive] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const editedDate = dayjs().format()

    // Taking data from redux


    const [periods_data, periods_error, periods_loading] = useFetch('PfmCorporateInnitiativeAssignment/GetPfmLueCorporatePeriods?isActive=true')
    const [mission_data, mission_error, mission_loading] = useFetch('PfmCorporateInnitiativeAssignment/GetPfmLueMissions?isActive=true')
    const [initiative_data, initiative_error, initiative_loading] = useFetch('PfmCorporateInnitiativeAssignment/GetPfmLueInitiatives?isActive=true')
    //    const [Objective_data, objective_error,objective_loading] = useFetch(`api/PfmCorporateInnitiativeAssignment/GetPfmLueObjectivesWithMissionId?isActive=true&missionId=${MissionID}`)
    const [Objective_data, objective_error, objective_loading] = useFetch(`PfmCorporateInnitiativeAssignment/GetPfmLueObjectivesWithMissionId?isActive=true&missionId=1`)


    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';

    const periods: selectsTypes[] = [
        { id: 0, label: 'Period', optionsData: periods_data, dataExpr: "prdStartDate", idExpr: "prdIdpk", stateName: 'period', defaultValue: states.period.name },
    ]
    const periodsEnd: selectsTypes[] = [
        { id: 0, label: 'Period', optionsData: periods_data, dataExpr: "prdEndDate", idExpr: "prdIdpk", stateName: 'periodEnd', defaultValue: states.periodEnd.name },
    ]
    const selects: selectsTypes[] = [
        { id: 1, label: 'Mission/Vision', optionsData: mission_data, dataExpr: "mvsMission", idExpr: "mvsIdpk", stateName: 'mission', defaultValue: states.mission.name, style: "89%" },
        { id: 2, label: 'Strategic Objective', optionsData: Objective_data, dataExpr: "thmName", idExpr: "objIdpk", stateName: 'objective', defaultValue: states.objective.name, style: "89%" },
        { id: 3, label: 'Corporate Initiative', optionsData: initiative_data, dataExpr: "cinName", idExpr: "cinIdpk", stateName: 'initiative', defaultValue: states.initiative.name, style: "89%" },
    ]

    const reduxDispatchs = () => {
        dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
        dispatch(setGeneralValue({ expr: "updateMode", value: false }));
    
        dispatch(setGeneralValue({ expr: "selectedFieldData", value: [] }));
      };

    const populateFields = (clear?: boolean) => {
        if (clear === true) {
            dispatch(task_footer_table_selected([]))
            reduxDispatchs();
            setSelectedRecordx!({})
            setRemarks&&setRemarks(selectedRecordx?.ciaRmks)
        }

        const gridState = [
            { key: 'period', value: clear ? { id: 0, name: '' } : { id: selectedRecordx?.prdIdpk, name: selectedRecordx?.prdStartDate } },
            { key: 'periodEnd', value: clear ? { id: 0, name: '' } : { id: selectedRecordx?.prdIdpk, name: selectedRecordx?.prdEndDate } },
            { key: 'mission', value: clear ? { id: 0, name: '' } : { id: selectedRecordx?.mvsIdpk, name: selectedRecordx?.mvsMission } },
            { key: 'objective', value: clear ? { id: 0, name: '' } : { id: selectedRecordx?.thmIdpk, name: selectedRecordx?.thmName } },
            { key: 'initiative', value: clear ? { id: 0, name: '' } : { id: selectedRecordx?.cinIdpk, name: selectedRecordx?.cinName } },
            { key: 'active', value: clear ? true : selectedRecordx?.ciaActive },
            // {key:'remarks', value: clear ? "": selectedRecordx?.ciaRmks }
        ]

        // if (setRemarks !== undefined) {
        //     setRemarks(selectedRecordx?.ciaRmks);
        // }

        for (let instance of gridState) updateState(instance.key, instance.value);
    }



    // Validation 
    const validationForms = async () => {
        setIcon('warning')
        if (states.period?.id === 0 || states.period?.id === undefined) {
            setshowModalDisableButton(true);
            setModalData({ message: "Please select data entry for Period", title: " Field Required! ", okText: "Ok" });
            setOpenModal(true);
        } else if (states.mission?.id === 0 || states.mission?.id === undefined) {
            setshowModalDisableButton(true);
            setModalData({ message: "Please select data entry for Mission / Vision", title: " Field Required!", okText: "Ok" });
            setOpenModal(true);
        } else if (states.objective?.id === 0 || states.objective?.id === undefined) {
            setshowModalDisableButton(true);
            setModalData({ message: "Please enter data for Strategic Objective", title: "Field Required!", okText: "Ok" });
            setOpenModal(true);
        } else if (states.initiative?.id === 0 || states.initiative?.id === undefined) {
            setshowModalDisableButton(true);
            setModalData({ message: "Please enter data for Initiative", title: "Field Required!", okText: "Ok" });
            setOpenModal(true);
        } else {
            setshowModalDisableButton(false);
            setIcon('question')
            setOpenModal(true);
        }
    }



    const postData = async () => {

        try {
            setBusyLoader(saveModalData.progress);

            const res = PostDataFunc(`PfmCorporateInnitiativeAssignment/CreatePfmCorpInitiativeAssignemt`,{
                "ciaIdpk": selectedRecordx?.ciaIdpk,
                "ciaPeriodIdfk": states.period?.id,
                "ciaObjectiveIdfk": states.objective?.id,
                "ciaInitiativeIdfk": states.initiative?.id,
                "ciaActive": states.active,
                "ciaRmks": remarks ?? '',
                "ciaCreatedBy": userId
            })

            //  Refreshes the table when data is manupulated
            setIcon('success')
            refreshGrid();
            setOpenModal(true);
            setRefreshValue(!refreshValue)
            dispatch(settask_footer_table_refresh(!refreshValue))
            setshowModalDisableButton(true);
            setModalData({ message: "Data entry saved successfully!", title: "Data Saved!", okText: "Ok" });

        } catch (e: any) {
            setIcon('warning')
            setOpenModal(true);
            setshowModalDisableButton(true);
            setModalData({ message: saveModalData.error.message, title: saveModalData.error.title, okText: "Ok" });
        } finally{
            setBusyLoader("");
        }

    }

    const updateData = async () => {
        try {
            setBusyLoader(updateModalData.progress);

            const res = await UpdateDataFunc(`PfmCorporateInnitiativeAssignment/UpdatePfmCorpInitiativeAssignemt`, {
                "ciaIdpk": selectedRecordx?.ciaIdpk,
                "ciaPeriodIdfk": states.period?.id,
                "ciaObjectiveIdfk": states.objective?.id,
                "ciaInitiativeIdfk": states.initiative?.id,
                "ciaActive": states.active,
                "ciaRmks": remarks ?? '',
                "ciaEditedBy": userId,
                "ciaEditedDate": editedDate
            })

            setIcon('success')
            refreshGrid();
            setOpenModal(true);
            setshowModalDisableButton(false);
            setModalData({ message: "Data entry updated successfully!", title: "Data Updated!", okText: "Ok" });
            console.log(res.data)
            setRefreshValue(!refreshValue)
            dispatch(settask_footer_table_refresh(!refreshValue))


        } catch (e: any) {
            console.log(e);
            setIcon('warning')
            setOpenModal(true);
            setshowModalDisableButton(true);
            setModalData({ message: updateModalData.error.message, title: updateModalData.error.title, okText: "Ok" });
        } finally{
            setBusyLoader('');
        }
    }
    const [OpenCorperateInitiative, setOpenCorperateInitiative] = useState(false);

    useEffect(() => {
        populateFields(false);
    }, [selectedRecordx])

    return (

        <div style={{ width: '900px', minWidth: '900px', height: '100%' }} className="  dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base  ">
            {/* <h4 className=" w-full  mb-2 bg-slate-100  ">Task Details</h4> */}
            <ModalTemplate disableCancel={showModalDisableButton} icon_width={modalData.iconSize === 'large' ? 100 : 50} icon={icon} cancelText={modalData.cancelText}
                cancelHandler={() => { setOpenModal(false) }} open={openModal} okHandler={() => { modalData === saveModalData ? postData() : modalData === updateModalData ? updateData() : setOpenModal(false) }}
                message={modalData.message} okText={modalData.okText} title={modalData.title} />
            <FormModal title='Corporate Initiative' openState={OpenCorperateInitiative} onCancel={()=>{setOpenCorperateInitiative(false)}} child={<CI state_change={true}/>}/>    
            <div style={{ maxHeight: '140px', minHeight: '140px', height: '140px' }} className=' items-start justify-start '   >

                <Form
                    className='w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 px-2 '
                    name="basic"
                    labelCol={{ span: 4 }}
                    // wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    size='small'
                    layout='horizontal'


                >


                    <Form.Item className='w-full' label={<p className='text-xs'>Start / End Periods</p>}>
                        <div className='flex w-1/2 '>
                            <div className='w-full '>
                                {periods?.map(({ optionsData, idExpr, dataExpr, defaultValue, stateName }) => {
                                    return (
                                        <SelectsTemplate
                                            idexpr={idExpr}
                                            dataexp={dataExpr}
                                            style={{ width: '112%' }}
                                            span
                                            useCallFunc={true}
                                            options={optionsData}
                                            placeHolder={defaultValue}
                                            selectedValue={(e) => { updateState(stateName!, { id: e[idExpr!], name: dayjs(e[dataExpr!]).format("ddd, DD, MMM, YYYY") }) }}
                                        />
                                    )
                                })}
                            </div>

                            <div className='w-full '>
                                {periodsEnd?.map(({ optionsData, idExpr, dataExpr, defaultValue, stateName }) => {
                                    return (
                                        <SelectsTemplate
                                            style={{ width: '100%', }}
                                            idexpr={idExpr}
                                            dataexp={dataExpr}
                                            span
                                            useCallFunc
                                            options={optionsData}
                                            placeHolder={defaultValue}
                                            selectedValue={(e) => { updateState(stateName!, { id: e[idExpr!], name: dayjs(e[dataExpr!]).format("dd, DD, MMM, YYYY") }) }}
                                        />
                                    )
                                })}
                            </div>

                        </div>
                    </Form.Item>



                    {selects.map(({ label, idExpr, dataExpr, optionsData, stateName, defaultValue, style }) => {
                        return (
                            <SelectsTemplate
                                idexpr={idExpr}
                                dataexp={dataExpr}
                                label={label}
                                options={optionsData}
                                placeHolder={defaultValue}
                                useCallFunc
                                selectedValue={(e) => { updateState(stateName!, { id: e[idExpr!], name: e[dataExpr!] }) }}
                                disabled={form_disable}
                                settingsIcon={label === "Corporate Initiative"}
                                handleSettings={()=>{
                                    setOpenCorperateInitiative(true)
                                }}
                                disabledCustomWidth={true}
                                cusWidth={style}
                            />
                        )
                    })}

                    <div className='w-full  flex flex-row justify-center     '>
                    <Form.Item
                            // labelCol={{span:20}}
                            label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{"Active?"}</p>}
                            className='w-full'
                        >
                            <div  className='w-full  flex  justify-between '>
                                <div>
                                    <Checkbox style={{borderColor:borderTheme}}  checked={selectedRecordx?.ciaActive || states?.active }  disabled={form_disable} className='border-[1px] px-1 rounded' onChange={(e) => { updateState('active', e.target.checked) }} />
                                </div>

                                <div className='mr-[78px]'>
                                <ul className='flex justify-end   '>
                                {

                                    (form_disable === false && update_state_change === false) ?
                                        <SaveButton handleSave={() => { setModalData(saveModalData); validationForms(); setOpenModal(true); }} />
                                        :
                                        (update_state_change === true) ?
                                            <UpdateButton handleUpdate={() => { setModalData(updateModalData); validationForms(); setOpenModal(true); }} />
                                            :
                                            <SaveButton />

                                    // useCallbackFunc new_button_toggler={()=>{setform_disable(!form_disable)}}
                                }

                                {(form_disable === true) ?
                                    <NewButton new_button_toggler={() => { populateFields(true) }} />

                                    :
                                    (update_state_change === true) ?
                                        <Cancel cancel_button_toggler={() => { }} />
                                        :
                                        !(update_state_change && form_disable) ?
                                            <Cancel cancel_button_toggler={() => { }} />

                                            :
                                            <NewButton new_button_toggler={() => { populateFields(true) }} />
                                }
                            </ul>
                                </div>

                            </div>

                            

                        </Form.Item>
                    </div>
                </Form>
            </div>



        </div>
    )
}