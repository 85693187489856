import { Checkbox, Form } from "antd";
import { FormModal } from "../../../../../../accessories/formModal";
import Datagrid_template from "../../../../../../../templates/Datagrid";
import { vehicleSimCard_TH } from "../../Vehicle-Requests/data/vehicle-requests-data";
import { CheckboxTemlate } from "../../../../../../../templates/checkbox";
import { UpdateButton } from "../../../../../../accessories/buttons";
import { useCallback, useEffect, useState } from "react";
import useFetch from "../../../../../../../hooks/useFetch";
import search1 from "../../../../../../../assets/search1.png";
import { V_FieldTypes } from "./vehDetails_form";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import { UpdateDataFunc } from "../../../../../../../functions/update";
import { currentDate } from "../../../../../../accessories/component_infos";
import { Page } from "@syncfusion/ej2-react-grids";
import { useCrudFunc } from "../../../../../../../functions/crud";

interface DataGridParamsFromParentFile {
  searchCriteria?: { temp: number; index: number };
  date?: { startDate: string; endDate: string };
  searchTextValue?: { temp: string; index: string };
}
interface ModalDisplay {
  icon: "warning" | "question" | "success";
  title: string;
  message: string;
  okText: string;
  cancelText?: string;
  openState: boolean;
  disableCancel: boolean;
  okHandler: () => void;
  cancelHandler: () => void;
}
export const VehicleCategory = ({
  searchCriteria,
  date,
  searchTextValue,
}: DataGridParamsFromParentFile) => {
  // states for  checkbox tag
  const [checkedBox, setCheckBox] = useState(false);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkValue, setCheckValue] = useState<{
    temp: boolean | string;
    status: boolean | string;
  }>({
    temp: "",
    status: "",
  });

  // Refresh state for datagrid
  const [refresh, setRefresh] = useState(false);

  // states
  const [states, setStates] = useState<V_FieldTypes>({
    vdtIdpk: 0,
    regNo: " ",
    vehClass: { id: 0, value: "" },
    manufacturer: { id: 0, value: "" },
    engineType: { id: 0, value: "" },
    powerTrain: { id: 0, value: "" },
    len: 0,
    width: 0,
    height: 0,
    noCylinder: 0,
    horsePower: "",
    netWeight: 0,
    grossWeight: 0,
    manuDate: currentDate,
    chassisNo: "",
    vehMake: { id: 0, value: "" },
    country: { id: 0, value: "" },
    fuelType: { id: 0, value: "" },
    transType: { id: 0, value: "" },
    status: { id: 0, value: "" },
    noOfAxles: 0,
    noOfWheels: 0,
    tankCap: 0,
    price: "",
    currency: { id: 0, value: "" },
    supplyDate: currentDate,
    maxSpeed: 0,
    speedUnit: { id: 0, value: "" },
    vehModel: { id: 0, value: "" },
    supplier: { id: 0, value: "" },
    engineMake: { id: 0, value: "" },
    cubicCapacity: 0,
    seatingCap: 0,
    engineNo: "",
    tyreSizeF: "",
    tyreSizeM: "",
    tyreSizeR: "",
    other: "",
    regDate: currentDate,
    colour: "",
    general: false,
    bus: false,
    specialised: false,
    motorBike: false,
    equipment: false,
    lightDuty: false,
    heavyDuty: false,
    vehicle: false,
    active: false,
    remarks: "",
  });
  //Function to update the state(Prototype)
  const UpdateState = (key: any, value: any) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };
  // Function to Find  active , inActive and  intermediate records
  const Vehicle_Category_Model_Find_Button = () => {
    setCheckValue({ temp: checkValue.temp, status: checkValue.temp });
    // setRefresh(!refresh);
  };
  // function to Update the state with a selected record
  const UpdateState_onSingle_and_Double_Click = (e: any) => {
    const state = [
      { key: "vdtIdpk", name: e?.vdtIDpk },
      { key: "regNo", name: e?.vdtRegNo },
      { key: "chassisNo", name: e?.vdtChassisNo },
      {
        key: "vehClass",
        name: { id: e?.vdtVehicleClassIDfk, value: e?.vhcName },
      },
      { key: "manufacturer", name: { id: e?.mftIDpk, value: e?.mftName } },
      { key: "engineType", name: { id: e?.egtIDpk, value: e?.egtName } },
      { key: "powerTrain", name: { id: e?.pwtIDpk, value: e?.pwtName } },
      { key: "len", name: Number(e?.vdtLength).toFixed(2) },
      { key: "width", name: Number(e?.vdtWidth).toFixed(2) },
      { key: "height", name: Number(e?.vdtHeight).toFixed(2) },
      { key: "noCylinder", name: e?.vdtNoOfCylinders },
      { key: "horsePower", name: e?.vdtHorsePower },
      { key: "netWeight", name: Number(e?.vdtNetWeight).toFixed(2) },
      { key: "grossWeight", name: Number(e?.vdtGrossWeight).toFixed(2) },
      { key: "manuDate", name: e?.vdtManufactureDate },
      { key: "vehMake", name: { id: e?.vmkIdpk, value: e?.vmkName } },
      { key: "country", name: { id: e?.ctyIDpk, value: e?.ctyName } },
      { key: "fuelType", name: { id: e?.vdtFuelTypeIDfk, value: e?.ftpName } },
      { key: "transType", name: { id: e?.trtIDpk, value: e?.trtName } },
      { key: "status", name: { id: e?.vdtStatusIDfk, value: e?.vstName } },
      { key: "noOfAxles", name: e?.vdtNoOfAxles },
      { key: "noOfWheels", name: e?.vdtNoOfWheels },
      { key: "tankCap", name: e?.vdtTankCapacity },
      { key: "price", name: Number(e?.vdtPrice).toFixed(2) },
      {
        key: "currency",
        name: { id: e?.vdtPriceCurrencyIDfk, value: e?.curSymbol },
      },
      { key: "supplyDate", name: e?.vdtSupplyDate },
      { key: "vehModel", name: { id: e?.vdtModelIDfk, value: e?.mdlName } },
      { key: "supplier", name: { id: e?.vdtSupplierIDfk, value: e?.splName } },
      { key: "engineMake", name: { id: e?.egmIDpk, value: e?.egmName } },
      { key: "cubicCapacity", name: e?.vdtCubicCapacity },
      { key: "seatingCap", name: e?.vdtSeatingCapacity },
      { key: "engineNo", name: e?.vdtEngineNo },
      { key: "tyreSizeF", name: e?.vdtTyreSizeFront },
      { key: "tyreSizeM", name: e?.vdtTyreSizeMiddle },
      { key: "tyreSizeR", name: e?.vdtTyreSizeRear },
      { key: "maxSpeed", name: Number(e?.vdtSpeedometerLimit).toFixed(2) },
      { key: "colour", name: e?.vdtColour },
      { key: "general", name: e?.vdtGeneral },
      { key: "bus", name: e?.vdtBus },
      { key: "specialised", name: e?.vdtSpecialised },
      { key: "motorBike", name: e?.vdtMotorBike },
      { key: "equipment", name: e?.vdtEquipment },
      { key: "lightDuty", name: e?.vdtLightDuty },
      { key: "heavyDuty", name: e?.vdtHeavyDuty },
      { key: "vehicle", name: e?.vdtVehicle },
      { key: "active", name: e?.vdtActive },
      { key: "remarks", name: e?.vdtRmks },
      //   {key:'other',name:e?. },
      { key: "regDate", name: e?.vdtDvlaRegDate },
    ];

    for (let instance of state) {
      UpdateState(instance.key, instance.name);
    }
  };

  // State (prototype)
  const [selectedRecord, setSelectedRecord] = useState({});

  // User Priviledges
  const [privType, setPrivType] = useState<string>("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);

  //  state for  modal display
  let [modalData, setModalData] = useState<ModalDisplay>({
    icon: "warning",
    title: "Access Denied",
    message: "No Access Priviledge",
    okText: "Ok",
    cancelText: "cancel",
    openState: false,
    disableCancel: true,
    okHandler: () => {
      setModalData({ ...modalData, ["openState"]: false });
    },
    cancelHandler: () => {
      setModalData({ ...modalData, ["openState"]: false });
    },
  });

  // Funcion to change the state of the check box from indeterminate , active to inActive
  const Indeterminate_active_and_inActiveSwitch = () => {
    if (indeterminate === true) {
      setCheckBox(true);
      setIndeterminate(false);
      setCheckValue({ temp: true, status: checkValue.status });
    } else if (indeterminate === false && checkedBox === true) {
      setCheckBox(false);
      setIndeterminate(false);
      setCheckValue({ temp: false, status: checkValue.status });
    } else if (indeterminate === false && checkedBox === false) {
      setCheckBox(false);
      setIndeterminate(true);
      setCheckValue({ temp: "", status: checkValue.status });
    }
  };
const [postData, updateData, patchData] = useCrudFunc('innox')
  // Update Function
  const UpdateRecordFromDataBase = async () => {
    await updateData(
      `AssetTamVehicleDetail/UpdateAssetTamVehicleDetails/${states?.vdtIdpk}`,
      {
        vdtRegNo: states.regNo,
        vdtChassisNo: states.chassisNo,
        vdtVehicleClassIdfk: states.vehClass?.id,
        vdtEngineNo: states.engineNo,
        vdtModelIdfk: states.vehModel?.id,
        vdtManufacturerIdfk: states.manufacturer?.id,
        vdtCountryOfOriginIdfk: states.country?.id,
        vdtSupplierIdfk: states.supplier?.id,
        vdtManufactureDate: states.manuDate,
        vdtSupplyDate: states.supplyDate,
        vdtDvlaRegDate: states.regDate,
        vdtEngineTypeIdfk: states.engineType?.id,
        vdtFuelTypeIdfk: states.fuelType?.id,
        vdtCubicCapacity: states.cubicCapacity,
        vdtTankCapacity: states.tankCap,
        vdtPowerTrainIdfk: states.powerTrain?.id,
        vdtTransmissionTypeIdfk: states.transType?.id,
        vdtEngineMakeIdfk: states.engineMake?.id,
        vdtNoOfCylinders: states.noCylinder,
        vdtSpeedometerLimit: states.maxSpeed,
        vdtSpeedometerUnitIdfk: states.speedUnit?.id,
        vdtColour: states.colour,
        vdtStatusIdfk: states.status?.id,
        vdtSeatingCapacity: states.seatingCap,
        vdtLength: states.len,
        vdtWidth: states.width,
        vdtHeight: states.height,
        vdtNoOfAxles: states.noOfAxles,
        vdtNoOfWheels: states.noOfWheels,
        vdtTyreSizeFront: states.tyreSizeF,
        vdtTyreSizeMiddle: states.tyreSizeM,
        vdtTyreSizeRear: states.tyreSizeR,
        vdtNetWeight: states.netWeight,
        vdtGrossWeight: states.grossWeight,
        vdtHorsePower: states.horsePower,
        vdtPrice: states.price,
        vdtPriceCurrencyIdfk: states.currency?.id,
        vdtGeneral: states.general,
        vdtBus: states.bus,
        vdtSpecialised: states.specialised,
        vdtMotorBike: states.motorBike,
        vdtEquipment: states.equipment,
        vdtLightDuty: states.lightDuty,
        vdtHeavyDuty: states.heavyDuty,
        vdtVehicle: states.vehicle,
        vdtActive: states.active,
        vdtRmks: states.remarks,
      },
      `Edited the Asset Mgr-Trans-Vehicle Detail with ID:${states.vdtIdpk}, Registration No.:${states.regNo}, chassis No:${states.chassisNo}, engine No:${states.engineNo},
      manufacturer:${states.manufacturer.value}, engine Type: ${states.engineType.value}, power Train:${states.powerTrain.value},
      length:${states.len}, width:${states.width}, height:${states.height}, No. of cylinder:${states.noCylinder},
      horse Power:${states.horsePower}, net weight:${states.netWeight}, gross weight:${states.grossWeight},
      manufacturing Date:${states.manuDate}, vehicle Make:${states.vehMake.value}, country:${states.country.value}, fuel Type:${states.fuelType.value},
      Trans type:${states.transType.value}, status:${states.status.value}, No. of Axles:${states.noOfAxles}, No. of Wheels:${states.noOfWheels},
      Tank Capacity:${states.tankCap}, Price:${states.price}, currency:${states.currency.value}, supply Date:${states.supplyDate},
      maxSpeed:${states.maxSpeed}, Speed unit:${states.speedUnit.value}, vehicle model:${states.vehModel.value}, supplier:${states.supplier},
      engine Make:${states.engineMake.value}, cubic Capacity:${states.cubicCapacity} seating capacity:${states.seatingCap},
      tyreSizeF:${states.tyreSizeF}, tyreSizeM:${states.tyreSizeM}, tyreSizeR:${states.tyreSizeR}, other:${states.other}, registration Date:${states.regDate},   
      colour:${states.colour}  successfully`
    );
  };
  // Function to update the vehicle category of a selected record
  const Confirm_To_Update_Data = () => {
    setPrivType("update");
    if (savePriv === true) {
      setModalData({
        ...modalData,
        okText:'Yes',
        cancelText:'No',
        icon: "question",
        title: "Update Veh.Category of this record ?",
        message: "Are you sure you want to update this record ?",
        disableCancel: false,
        openState: true,
        okHandler: async () => {
          setModalData({ ...modalData, openState: false });
          UpdateRecordFromDataBase();
        },
      });
    } else {
      setModalData({
        ...modalData,
        icon: "warning",
        title: "Access Denied! ",
        message: "No Access Priviledge",
        disableCancel: true,
        openState: true,
        okHandler: () => {
          setModalData({ ...modalData, openState: false });
        },
      });
    }
  };
  const [pagination, setPagination] = useState<{
    pageNumber: number;
    pageSize: number;
  }>({
    pageNumber: 1,
    pageSize: 20,
  });
  // Data
  const [DBdata, DBerr, DBLoading] = useFetch(
    `AssetTamVehicleDetail/GetAllAssetTamVehicleDetails?criteria=${searchCriteria?.index}&dtpStartDate=${date?.startDate}&dtpEndDate=${date?.endDate}&chkActive=${checkValue.status}&search=${searchTextValue?.index}&pageSize=${pagination.pageSize}&pageNumber=${pagination.pageNumber}`,
    refresh
  );

  // hook to to update the state with a the selectedRecord dependencies
  useEffect(() => {
    UpdateState_onSingle_and_Double_Click(selectedRecord);
  }, [selectedRecord]);

  console.log("searchCriteria: ", searchCriteria);
  console.log("searchTextValue: ", searchTextValue);
  console.log("date: ", date);
  console.log("selectedRecord: ", selectedRecord);
  console.log("states:", states);
  console.log("checkValue: ", checkValue);
  return (
    <>
      <ModalTemplate
        icon_width={50}
        icon={modalData.icon}
        cancelText={modalData.cancelText}
        disableCancel={modalData.disableCancel}
        cancelHandler={modalData.cancelHandler}
        open={modalData.openState}
        okHandler={modalData.okHandler}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
      />
      <div className="flex justify-end ">
        <CheckboxTemlate
          useCallbackFunc
          // customDisabled={DisableForm}
          customDisabled={false}
          indeterminate={indeterminate}
          setCheckboxOnchange={() => {
            Indeterminate_active_and_inActiveSwitch();
          }}
          withBorders={true}
          defaultValue={checkValue.temp}
          expr={""}
          style={{
            display: "inline-block",
            width: "",
            marginBottom: 0,
          }}
        />
        <li
          onClick={() => {
            Vehicle_Category_Model_Find_Button();
          }}
          style={{ borderWidth: "1px" }}
          className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2  ml-2   text-gray-600 rounded flex items-center "
        >
          <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
            {" "}
            <img className="pr-1 " src={search1} />
            Search{" "}
          </span>
        </li>
      </div>
      <Datagrid_template
        gridheight={400}
        pageNumber={pagination.pageNumber}
        pageSize={pagination.pageSize}
        handlePageNumber={(e) => {setPagination((prev)=>({...prev,pageNumber:e}))}}
        handlePageSize={(e) => {setPagination((prev)=>({...prev,pageSize:e}))}}
        columns={vehicleSimCard_TH}
        data={DBdata}
        onRowClick={(val) => {
          setSelectedRecord(val);
        }}
      />
      <Form.Item
        label={<p className="text-xs ">{"Veh. Category"}</p>}
        style={{ marginBottom: 0, marginTop: "1%" }}
      >
        <Form.Item
          style={{
            display: "inline-block",
            marginBottom: "1px",
            width: "calc(99%)",
          }}
          className=" mb-1 mr-2 "
        >
          <div className="flex justify-between mt-0.5 h-[50px] ">
            {/* <Checkbox className="border rounded border-solid" onChange={(e:any)=>{setState('vehicle',e.target.checked)}} disabled={form_disable} checked={states.vehicle}> </Checkbox>Vehicle? */}
            <div className="flex justify-center">
              {" "}
              <CheckboxTemlate
                useCallbackFunc
                // customDisabled={DisableForm}
                customDisabled={false}
                setCheckboxOnchange={(value) => {
                  UpdateState("vehicle", value);
                }}
                withBorders={true}
                defaultValue={states.vehicle}
                expr={""}
                style={{
                  display: "inline-block",
                  width: "",
                  marginBottom: 0,
                }}
              />{" "}
              <span className="ml-1 mt-1 "> Vehicle?</span>
            </div>

            <div className="flex justify-center">
              {" "}
              <CheckboxTemlate
                useCallbackFunc
                // customDisabled={DisableForm}
                customDisabled={false}
                setCheckboxOnchange={(value) => {
                  UpdateState("general", value);
                }}
                withBorders={true}
                defaultValue={states.general}
                expr={""}
                style={{
                  display: "inline-block",
                  width: "",
                  marginBottom: 0,
                }}
              />{" "}
              <span className="ml-1 mt-1"> Gen Vehicle?</span>
            </div>
            {/* <Checkbox onChange={(e:any)=>{setState('general',e.target.checked)}} disabled={form_disable}  checked={states.general}>Gen Vehicle?</Checkbox> */}
            <div className="flex justify-center">
              {" "}
              <CheckboxTemlate
                // customDisabled={DisableForm}
                customDisabled={false}
                useCallbackFunc
                setCheckboxOnchange={(value) => {
                  UpdateState("bus", value);
                }}
                withBorders={true}
                defaultValue={states.bus}
                expr={""}
                style={{
                  display: "inline-block",
                  width: "",
                  marginBottom: 0,
                }}
              />{" "}
              <span className="ml-1 mt-1"> Bus?</span>
            </div>
            {/* <Checkbox onChange={(e:any)=>{setState('bus',e.target.checked)}} disabled={form_disable} checked={states.bus}>Bus?</Checkbox> */}
            <div className="flex justify-center ">
              <CheckboxTemlate
                useCallbackFunc
                // customDisabled={DisableForm}
                customDisabled={false}
                setCheckboxOnchange={(value) => {
                  UpdateState("lightDuty", value);
                }}
                withBorders={true}
                defaultValue={states.lightDuty}
                expr={""}
                style={{
                  display: "inline-block",
                  width: "",
                  marginBottom: 0,
                }}
              />{" "}
              <span className="ml-1 mt-1"> Light Duty?</span>
            </div>
            {/* <Checkbox onChange={(e:any)=>{setState('lightDuty',e.target.checked)}} disabled={form_disable} checked={states.lightDuty}>Light Duty?</Checkbox> */}
            <div className="flex justify-center">
              {" "}
              <CheckboxTemlate
                useCallbackFunc
                // customDisabled={DisableForm}
                customDisabled={false}
                setCheckboxOnchange={(value) => {
                  UpdateState("heavyDuty", value);
                }}
                withBorders={true}
                defaultValue={states.heavyDuty}
                expr={""}
                style={{
                  display: "inline-block",
                  width: "",
                  marginBottom: 0,
                }}
              />{" "}
              <span className="ml-1 mt-1"> Heavy Duty?</span>
            </div>
            {/* <Checkbox  onChange={(e:any)=>{setState('heavyDuty',e.target.checked)}} disabled={form_disable} checked={states.heavyDuty}>Heavy Duty?</Checkbox> */}
            <div className="flex justify-center">
              {" "}
              <CheckboxTemlate
                useCallbackFunc
                // customDisabled={DisableForm}
                customDisabled={false}
                setCheckboxOnchange={(value) => {
                  UpdateState("specialised", value);
                }}
                withBorders={true}
                defaultValue={states.specialised}
                expr={""}
                style={{
                  display: "inline-block",
                  width: "",
                  marginBottom: 0,
                }}
              />{" "}
              <span className="ml-1 mt-1"> Specialised</span>
            </div>
            {/* <Checkbox onChange={(e:any)=>{setState('specialised',e.target.checked)}} disabled={form_disable} checked={states.specialised}>Specialised?</Checkbox> */}
            <div className="flex justify-center">
              {" "}
              <CheckboxTemlate
                useCallbackFunc
                // customDisabled={DisableForm}
                customDisabled={false}
                setCheckboxOnchange={(value) => {
                  UpdateState("motorBike", value);
                }}
                withBorders={true}
                defaultValue={states.motorBike}
                expr={""}
                style={{
                  display: "inline-block",
                  width: "",
                  marginBottom: 0,
                }}
              />{" "}
              <span className="ml-1 mt-1"> Motor Bike?</span>
            </div>
            {/* <Checkbox onChange={(e:any)=>{setState('motorBike',e.target.checked)}} disabled={form_disable} checked={states.motorBike}>Motor Bike?</Checkbox> */}
            <div className="flex justify-center">
              {" "}
              <CheckboxTemlate
                useCallbackFunc
                // customDisabled={DisableForm}
                customDisabled={false}
                setCheckboxOnchange={(value) => {
                  UpdateState("equipment", value);
                }}
                withBorders={true}
                defaultValue={states.equipment}
                expr={""}
                style={{
                  display: "inline-block",
                  width: "",
                  marginBottom: 0,
                }}
              />{" "}
              <span className="ml-1 mt-1"> Equipment?</span>
            </div>
            {/* <Checkbox onChange={(e:any)=>{setState('equipment',e.target.checked)}} disabled={form_disable} checked={states.equipment}>Equipment?</Checkbox> */}
          </div>
          <div className="flex justify-end">
            <UpdateButton
              handleUpdate={() => {
                Confirm_To_Update_Data();
              }}
            />
          </div>
        </Form.Item>
      </Form.Item>
    </>
  );
};
