import dayjs from "dayjs";

export const validateDates=(startDate:string,endDate:string|undefined,action:'post'|'put'):{isValid:boolean,error:{title:string,message:string}}=>{
    const genTitle='Invalid Date'

    const IsBeforeCheckDate = dayjs(startDate).format('DD/MM/YYYY')
    const currentDate = dayjs().format('DD/MM/YYYY'); // Get the current date

    // alert(startDate);

    //validation rules
    if (!dayjs(startDate)) {
        return {isValid:false, error:{title:`Enter ${endDate!==undefined && 'Start '}Date`,message:`Please enter ${endDate!==undefined && `start `}date`}}
    }
    if (endDate !== undefined && !endDate) {
        return {isValid:false, error:{title:'Enter End Date',message:'Please enter end date'}}
    }
    if (endDate !== undefined && (dayjs(endDate).isBefore(startDate))) {
        return {isValid:false, error:{title:genTitle,message:'End date must not be before start date'}}
    }
    //post specifics
    if (action === 'post' && dayjs(IsBeforeCheckDate).isBefore(dayjs(currentDate))) {
        return {isValid:false, error:{title:genTitle,message:`${endDate!==undefined ? 'Start date' : 'Date'} must not be before today`}}
    }
    
    return {isValid:true, error:{title:'',message:''}}
}