/* eslint-disable no-useless-computed-key */
import { memo, useEffect, useRef, useState } from "react";

import "devextreme/dist/css/dx.light.css";

import { Tooltip } from "devextreme-react";

import alter from "../../../../../../../assets/alter.png";
import printer from "../../../../../../../assets/printer.png";

import reset from "../../../../../../../assets/refresh-small.png";
import correct from "../../../../../../../assets/correct.png";
import spreadSheetIcon from "../../../../../../../assets/excel.png";

import question from "../../../../../../../assets/question.png";

import { Checkbox, DatePicker, Input, Select } from "antd";

import { FaBinoculars } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { setGeneralValue } from "../../../../../../../features/generalSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import statusLoader from "../../../../../../../assets/statusloader.gif";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import { saveModalData, useModalData } from "../../../../../../accessories/component_infos";
import { ModalTemplate } from "../../../../../../../templates/modal";
import dayjs from "dayjs";

interface props {
  toggler?: () => void;
  uploadFile?: any;
  fixSheet?: () => void;
  handleFind?: () => any;
  handleRefresh?: () => void;
  setSearchText?: (value: string) => void;
  setStartDate_N?: (val: any) => void;
  setEndDate_N?: (val: any) => void;
  setActvieState?: (val: any) => void;
  active?: string | boolean;
  startDate?: any;
  endDate?: any;
  setSearchCriteria?: (index: number) => void;

  checkFields?: () => void;
}

const VehicleDataUploadToolbar = ({
  toggler,
  uploadFile,
  fixSheet,
  handleFind,
  setSearchText,
  setStartDate_N,
  setEndDate_N,
  startDate,
  endDate,
  setActvieState,
  active,
  setSearchCriteria,
  handleRefresh,

  checkFields,
}: props) => {
  const [searchParam, setSearchParam] = useState(0);

  const sideAlert = useSelector(
    (state: RootState) => state.general.sideAlertTest
  );

  const message = [

    { id: 0, value: 'This action will take some time depending on the number of records' },
    { id: 1, value: 'Are you sure you want to continue and check the column headers and data' }
  ] satisfies any;

  const messageAjourn = message.map((rule) => rule.value).join("\n");
  const [modalData, setModalData] = useModalData();



  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState<string>("");
  // Three state checkboxes
  const [indeterminate, setIndeterminate] = useState<any>(true);
  const [checkedBox, setCheckedBox] = useState<any>(false);
  const [checkValue, setCheckValue] = useState<any>(null); //actual value to use

  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const form_disable = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const update_state_change = useSelector(
    (state: RootState) => state.general.taskFooterUpdate
  );

  useEffect(() => {
    dispatch(setGeneralValue({ expr: "activeStatus", value: checkValue }));
  }, [checkValue]);

  const onChange = (value: boolean) => {
    if (indeterminate === true) {
      setCheckedBox(true);
      setIndeterminate(false);
      setCheckValue(true);
    } else if (!indeterminate && checkedBox) {
      setCheckedBox(false);
      setIndeterminate(false);
      setCheckValue(false);
    } else if (!indeterminate && !checkedBox) {
      setCheckedBox(false);
      setIndeterminate(true);
      setCheckValue(null);
    }
  };

  // const [modalInfo, setModalInfo] = useState<any>({
  //   icon: "question",
  //   data: saveModalData,
  //   disableCancel: false,
  //   openModal: false,
  // });

  const [userId, employID, userObj] = useAccountInfo();

  const [privType, setPrivType] = useState<string>("save");

  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);

  const AccessPriv_on_FindButton = () => {
    setPrivType("read");
    if (savePriv === true) {
      handleFind!();
    } else {
      // setModalInfo({
      //   ...modalInfo,
      //   ["icon"]: "warning",
      //   ["openModal"]: true,
      //   ["disableCancel"]: true,
      //   ["data"]: {
      //     message: "No Access Privilege",
      //     title: "Access Denied",
      //     okText: "Ok",
      //   },
      // });
    }
  };
  const AccessPriv_on_PrintButton = () => {
    if (savePriv === true) {
      alert("No Function Implemented For Print/ Export");
    } else {

      // setModalInfo({
      //   ...modalInfo,
      //   ["icon"]: "warning",
      //   ["openModal"]: true,
      //   ["disableCancel"]: true,
      //   ["data"]: {
      //     message: "No Access Privilege",
      //     title: "Access Denied",
      //     okText: "Ok",
      //   },
      // });
    }
  };

  return (
    <>
      {/* <ModalTemplate
        icon={modalInfo.icon}
        disableCancel={modalInfo.disableCancel}
        cancelText={modalInfo.data.cancelText}
        open={modalInfo.openModal}
        okHandler={() => {
          checkFields && checkFields();
          setModalInfo({ ...modalInfo, ["openModal"]: false });

        }}
        cancelHandler={() => {
          setModalInfo({ ...modalInfo, ["openModal"]: false });
        }}
        message={modalInfo.data.message}
        okText={modalInfo.data.okText}
        title={modalInfo.data.title}
      /> */}
      <ModalTemplate
        width={"600px"}
        icon={modalData.icon}
        disableCancel={modalData.disableCancel}
        cancelText={modalData.cancelText}
        open={modalData.state}
        okHandler={modalData.okHandler}
        cancelHandler={modalData.cancelHandler}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
      />
      <div
        style={{
          borderWidth: "1px",
          height: 32,
          borderColor: borderTheme,
          width: "4000px",
        }}
        className="px-0.5 mt-0.5 rounded   w-full h-full flex  border-gray-200 mb-1"
      >
        <style
          dangerouslySetInnerHTML={{
            __html: `
         
          .ant-upload-wrapper{
            line-height: 0
          }
            `,
          }}
        />
        <div className="flex justify-center ">
          <ul className="flex justify-evenly w-full items-center h-full py-0.5">
            <li
              onClick={uploadFile}
              id="spreadsheet"
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2  py-1  text-gray-600 rounded flex items-center"
            >
              <span>
                {" "}
                <img src={spreadSheetIcon} />{" "}
              </span>

              <Tooltip
                target="#spreadsheet"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p>Import spreadsheet</p>
              </Tooltip>
            </li>
            <li
              id="correct"
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2  py-1  text-gray-600 rounded flex items-center"
              //  onClick={() => {
              //       setModalInfo({
              //         ...modalInfo,
              //         ["icon"]: "warning",
              //         ["openModal"]: true,
              //         ["disableCancel"]: false,

              //         ["data"]: {
              //           message: messageAjourn,


              //           title: "Check Data?",
              //           okText: "Ok",
              //         },
              //       });
              //     }}
              onClick={() => {
                setModalData(prev => ({
                  ...prev,
                  state: true,
                  icon: "question",
                  title: "Check Data?",
                  message: messageAjourn,
                  okText: "Yes",
                  cancelText: "No",
                  cancelHandler: () => setModalData(prev => ({ ...prev, state: false })),
                  okHandler: () => {
                    checkFields && checkFields();
                    setModalData(prev => ({
                      ...prev, state: false
                    }))
                  },
                }
                ))
              }
              }
            >
              <span>
                {" "}
                <img src={correct} />{" "}
              </span>
              <Tooltip
                target="#correct"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p>Check loaded data and column headers</p>
              </Tooltip>
            </li>
            <li
              id="switch"
              onClick={toggler}
              style={{
                borderWidth: "1px",
                borderColor: borderTheme,
                width: "65px",
              }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img
                  className="dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"
                  src={alter}
                />
                Switch{" "}
              </span>
              <Tooltip
                target="#switch"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Switch interface / table view</p>
              </Tooltip>
            </li>

            <li
              id="help"
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center"
            // onClick={() => {
            //   setModalInfo({
            //     ...modalInfo,
            //     ["icon"]: "warning",
            //     ["openModal"]: true,
            //     ["disableCancel"]: true,
            //     ["data"]: {
            //       message: "No Function implemented",
            //       title: "Shhhh! 🤫",
            //       okText: "Ok",
            //     },
            //   });
            // }}
            >
              <span className="flex">
                {" "}
                <img
                  className="dark:bg-slate-900 pr-1 dark:text-gray-100 duration-100"
                  src={question}
                />{" "}
              </span>
              <Tooltip
                target="#help"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p>Help Center</p>
              </Tooltip>
            </li>

            <li className="px-1 text-xs">Search Criteria</li>
            <li className="items-right mt-0.5 ">
              <Select
                showSearch
                onChange={(object: any) => {
                  const criteriaData = JSON.parse(object);
                  setSearchParam(object);
                  setSearchCriteria && setSearchCriteria(criteriaData);
                }}
                value={searchParam}
                size="small"
                placeholder={data["Reg. No"]}
                allowClear={false}
                style={{ width: `${sideAlert === true ? 60 : 120}` }}
                dropdownStyle={{ minWidth: "250px" }}
              >
                {data?.map((option: any, index: number) => {
                  return (
                    <Select.Option value={index}>{option.label}</Select.Option>
                  );
                })}
              </Select>
            </li>
          </ul>
        </div>

        <div className="flex items-center">
          <ul className="flex items-center">
            <label className="px-1 text-xs">Search Text</label>
            <li className="px-1">
              <Input
                name="search"
                id="search"
                size="small"
                className="h-6"
                placeholder=""
                style={{ width: `${sideAlert === true ? 50 : 150}` }}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                  setSearchText!(e.target.value);
                }}
                value={searchValue}
              />
            </li>
            <li className="px-0.5">
              <DatePicker
                clearIcon={<span style={{ display: "none" }}></span>}
                value={dayjs(startDate)}
                onChange={(valstring) => {
                  const hold = dayjs(valstring).format("DD MMM YYYY");
                  setStartDate_N && setStartDate_N(hold);
                }}
                size="small"
                placeholder="Start Date"
                style={{ width: 125 }}
                format={"DD MMM YYYY"}
              />
            </li>
            <li className="px-0.5">
              <DatePicker
                clearIcon={<span style={{ display: "none" }}></span>}
                value={dayjs(endDate)}
                onChange={(valstring) => {
                  const hold = dayjs(valstring).format("DD MMM YYYY");
                  setEndDate_N && setEndDate_N(hold);
                }}
                size="small"
                placeholder="End Date"
                style={{ width: 125 }}
                format={"DD MMM YYYY"}
              />
            </li>
            <li
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              id="status"
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1  text-gray-600 rounded flex items-center"
            >
              <Checkbox
                checked={checkedBox}
                indeterminate={indeterminate}
                onChange={(checkValue: any) => {
                  onChange(checkValue);
                  setActvieState!(checkValue.target.checked);
                }}
              />
              <Tooltip
                target="#status"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Show Status</p>
              </Tooltip>
            </li>
            <li
              id="find"
              onClick={() => {
                AccessPriv_on_FindButton();
              }}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1 text-gray-600 rounded flex items-center"
            >
              <span>
                {" "}
                <FaBinoculars size={15.5} color="#007AFF" />{" "}
              </span>
              <Tooltip
                target="#find"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p>Find</p>
              </Tooltip>
            </li>
            <li
              onClick={() => {
                setSearchValue("");
                setCheckedBox(false);
                setIndeterminate(true);
                setCheckValue("");
              }}
              id="refreshst"
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
                {" "}
                <img
                  className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                  src={reset}
                />
              </span>
              <Tooltip
                target="#refreshst"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Refresh</p>
              </Tooltip>
            </li>
            {/* <li
              id="print"
              onClick={() => {
                AccessPriv_on_PrintButton();
              }}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1 text-gray-600 rounded flex items-center"
            >
              <span>
                {" "}
                <img
                  className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                  src={printer}
                />{" "}
              </span>
              <Tooltip
                target="#print"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p>Print / Export</p>
              </Tooltip>
            </li> */}

            <li className="hover:cursor-pointer ml-20  px-2  flex items-center">
              {form_disable && (
                <p className=" italic font-semibold text-gray-600">Done.</p>
              )}

              {!form_disable && (
                <div className="  flex flex-row items-center">
                  <img
                    style={{ width: "31px", height: "31px" }}
                    src={statusLoader}
                    alt="gif"
                  />
                  {!update_state_change ? (
                    <p className=" italic text-gray-600">New Record</p>
                  ) : (
                    <p className=" italic text-gray-600">Editing</p>
                  )}
                </div>
              )}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

const data: any = [
  {
    id: 0,
    label: "Reg. No",
  },
  {
    id: 1,
    label: "Chassis No",
  },
  {
    id: 2,
    label: "Engine No",
  },
];

export default memo(VehicleDataUploadToolbar);
