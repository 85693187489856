import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncGet, GetDataFunc } from "../../../functions/get";

type M_Job_Update = {
    job: any|null|undefined;
    isError: boolean,
    isLoading: boolean,
    isPending: boolean,
}
const initialState:M_Job_Update={
    job: [],
    isError: false,
    isLoading: false,
    isPending: false,
}
export const FetchM_Job_Update = createAsyncThunk<M_Job_Update,{id:any, search:string},{rejectValue:string}>(
    'job/Fetch',
    async (id, search) => {
        try{
            var job_response = await AsyncGet(`AsmTmsEntMaintenanceActivity/GetEntAsmTmsLueJobs?activityId=${id}&search=${search}&pagenumber=1&pagesize=20`);
            if(job_response.status===true){
                return job_response.data;
            }
        }catch{
            return job_response.data;
        }
    }
)

const  JobUpdateSlice = createSlice({
    name:'JobUpdate',
    initialState:initialState,
    reducers:{},
    extraReducers(builder) {
        builder
        .addCase(FetchM_Job_Update.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(FetchM_Job_Update.fulfilled, (state, action) => {
            state.isLoading = false;
            state.job = action.payload;
        })
        .addCase(FetchM_Job_Update.rejected, (state) => {
            state.isLoading = false;
            state.isError = true;
        })
    },
})

export default JobUpdateSlice.reducer