import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setGeneralValue } from "../features/generalSlice";
import { GetDataFunc } from "../functions/get";

const useFetch = (
  route: string,
  refresh?: string | number | boolean,
  sortKey?: string,
  sortKeyIsDate?: boolean,
  baseUrl?: "innox" | "powerapp",fromModal?: boolean
): [any, any, boolean] => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>("");
  const dispatch = useDispatch();

  useEffect(() => {
   
    !route ? setData([]) : fetchData();
  }, [route, refresh,fromModal]);
 
 

  // Function to generate a random ID
  function generateRandomId() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomText = "";

    for (let i = 0; i < 4; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomText += characters[randomIndex];
    }

    return randomText;
  }

  const fetchData = async () => {
    console.log(`fetching ${route}`)
    dispatch(setGeneralValue({ expr: "formData", value: [] })); //clear form data from redux general store
    try {
      setLoading(true); // initially set loading state
      // get data
      const res = await GetDataFunc(`${route}`, ``, baseUrl);
      console.log("is res done array");
      console.log(res.data);

      if(route === `StationDetails/station_details`){
        console.log('is true new array')
      console.log(res.data.data.data)
      if(res.data.data.data){
        let newArray = res.data.data.data.map((obj: any) => ({
          ...obj,
          id: generateRandomId(),
        }));
        console.log('is true new array')
      console.log(newArray)
        setData(newArray);
        setData(newArray);
      }
        }else{


     
      // this creates a key value pair to all the objects in it just so i get a unique key across all datasets

        if (!Array.isArray(res.data)) {
          // if its an object of an array
          if (res.data.value) {
            let newArray = res.data.value.map((obj: any) => ({
              ...obj,
              id: generateRandomId(),
            }));
            console.log("is new array");
            console.log(newArray);
            setData(newArray);
          } else {
            console.log("is res array");
            console.log(res.data);
            if (res.data.data) {
              if (res.data.data.colors) {
                let newArray = res.data.data.colors;
                console.log("is new array");
                console.log(newArray);
                setData(newArray);
                setData(newArray);
              } else {
                let newArray = res.data.data.map((obj: any) => ({
                  ...obj,
                  id: generateRandomId(),
                }));

                setData(newArray);
                setData(newArray);
              }
            } else {
              // let newArray = res.data.map((obj: any) => ({
              //   ...obj,
              //   id: generateRandomId(),
              // }));
              // console.log("is new array");
              // console.log(newArray);
              // setData(newArray);
              setData(res.data);
            }
          }
        } else {
          const newArray = res.data.map((obj: any) => ({
            ...obj,
            id: generateRandomId(),
          }));
          // set the new array
          console.log("is array");
          console.log(newArray);
          setData(newArray);
        }
      }
    } catch (error: any) {
      // set error
      setError(error);
    } finally {
      setLoading(false); //loading state to false
    }
  };

  return [data, error, loading];
};
export default useFetch;
