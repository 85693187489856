import React, { useEffect, useRef, useState } from 'react'
import { HrSetupsCustomToolbar } from '../../../../../accessories/custom-toolbars/hrSetupsCustomtoolbar'
import Datagrid_template from "../../../../../../templates/Datagrid"
import useResizeMode from '../../../../../../hooks/useResizeMode';
import { navbar_height, saveModalData } from '../../../../../accessories/component_infos';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../app/store';
import { Comments_details } from './widgets/comments_details';
import { Resolution_details } from './widgets/resolution_details';
import { tag_cols } from './data/datagridCols';
import { useAccountInfo } from '../../../../../../hooks/useAccountInfo';
import { useFormPriviledge } from '../../../../../../hooks/useFormPriviledge';
import { useBusyLoader } from '../../../../../../hooks/useBusyLoader';
import dayjs from 'dayjs';
import { searchTextTypes, statusTypes } from '../../../../../human-resource/setups/data/types/selectsTypes';
import { ModalTemplate } from '../../../../../../templates/modal';
import { setGeneralValue } from '../../../../../../features/generalSlice';
import useFetch from '../../../../../../hooks/useFetch';

const TagComments = () => {
    const formRef = useRef<any>()


    // Redux
    const news_flash_height = useSelector(
        (state: RootState) => state.general.newsflashHeight
      );

    const borderTheme = useSelector((state: RootState) => state.general.cssBorderClass);

    const [switch_change, setSwitch_change] = useState<boolean>(true);
    // switch toggle
    const switch_toggle = () => {
        if (switch_change) {
            setSwitch_change(!switch_change);
        } else {
            setSwitch_change(!switch_change);
        }
    };

    const [formheight, set_form_height] = useState<number>(0);

    const [initHeight, FullHeight] = useResizeMode(switch_change);

    var fullPage = FullHeight - (navbar_height + news_flash_height + 30);

    var pageHeight =
        initHeight - (navbar_height + news_flash_height + formheight + 25);

    const [employeeId, userId, UserModel] = useAccountInfo();
    const [privType, setPrivType] = useState<string>("save")
    const [updatePriv] = useFormPriviledge(privType,)
    const dispatch = useDispatch();
    
    const [selectedData, setSeletedData] = useState<any>({});
    const [busyLoader, setBusyLoader] = useBusyLoader();
    
    const [refresh, setRefresh] = useState(false);
    
    const genFormat = 'YYYY-MM-DD';
    const today = dayjs().format(genFormat);
    const initialStart = dayjs().subtract(14, 'year').startOf('year').format(genFormat)
    
    const [startDate, setStartDate] = useState<searchTextTypes>({ text: initialStart, temp: initialStart });
    const [endDate, setEndDate] = useState<searchTextTypes>({ text: today, temp: today })
    const [searchText, setSearchText] = useState<searchTextTypes>({ text: '', temp: '' })
    const [status, setStatus] = useState<statusTypes>({ temp: '', status: '' });

    const [gridData, gridError, gridLoading] = useFetch(``);

    const [openModal, setOpenModal] = useState(false);
    const [modalData, setModalData] = useState<any>(saveModalData);
    const [icon, setIcon] = useState<'warning' | 'success' | 'question'>();
    const [showCancel] = useState(false);

    const doubleClickAccesspriv = () => {
        setPrivType('update')
        if (updatePriv === true) {
          dispatch(setGeneralValue({ expr: 'updateMode', value: true }));
    
          dispatch(setGeneralValue({ expr: 'formDisabled', value: false }));
        } else {
          setIcon('warning')
          setOpenModal(true)
          setModalData({ title: 'Access Denied!', message: 'No Access Privilege' })
        }
      }
    


    useEffect(() => {
        set_form_height(formRef.current?.clientHeight + 25);
    }, [switch_change]);
    return (
        <>
            <div className='w-full px-2 '>
            {/* toolbar */}
            <div className='w-full'>
            <ModalTemplate 
                icon={icon}
                open={openModal}
                disableCancel={icon === "warning" || icon === "success" ? true : false}
                cancelText={modalData.cancelText}
                cancelHandler={() => {
                    setOpenModal(false);
                }}
                okHandler={() => setOpenModal(false)}
                message={modalData.message}
                title={modalData.title}
                okText={modalData.okText}
            />
                <HrSetupsCustomToolbar
                    startDate={startDate.temp}
                    endDate={endDate.temp}
                    startDateChanged={(date) => setStartDate({ temp: dayjs(date).format(genFormat), text: startDate.text })}
                    endDateChanged={(date) => setEndDate({ temp: dayjs(date).format(genFormat), text: endDate.text })}
                    searchTextOnchange={(text) => setSearchText({ temp: text, text: searchText.text })}
                    checkOnChange={(check) => setStatus({ temp: check, status: status.status })}
                    handleFind={() => {
                      setStartDate({ text: startDate.temp, temp: startDate.temp });
                      setEndDate({ text: endDate.temp, temp: endDate.temp });
                      setSearchText({ temp: searchText.temp, text: searchText.temp });
                      setStatus({ temp: status.temp, status: status.temp });
                    }}
                    handleNew={() => setSeletedData({})}
                    toggler={switch_toggle}
                    setVisible={setSwitch_change}
                    withDates={false}
                    // isSearch={true}
                    // isInputSelect={true}
                    toolbarInputSelectsData={[]} // pass the search Text endpoint to load the dropdown list
                    handleRefresh={() => {setSearchText({ temp: '', text: '' }); }}
                    outerSelected={[]} // use the selected value
                    setOuterSelected={() => { }} // set the selected setFunction
                />

            </div>

            {switch_change && (
                <section ref={formRef} className='w-full flex justify-between space-x-1'>
                    {/* left side */}
                    <div className='w-1/2 border-[1px] rounded'>
                        <Comments_details 
                            refreshGrid={() => setRefresh(current => !current)}
                            clearSelected={() => setSeletedData({})}
                            selectedData={selectedData} />
                    </div>

                    {/* right side */}

                    <div className='w-1/2 border-[1px] rounded'>
                        <Resolution_details 
                            refreshGrid={() => setRefresh(current => !current)}
                            clearSelected={() => setSeletedData({})}
                            selectedData={selectedData}/> 
                    </div>

                </section>
            )}

            {/* Datagrid */}
            <section className='w-full h-full'>
                <Datagrid_template
                    gridheight={switch_change ? pageHeight : fullPage}
                    columns={tag_cols}
                    data={[gridData]}
                    onRowClick={(data) => {
                        setSeletedData(data ?? {});
                        console.log(data);
          
                        dispatch(setGeneralValue({ expr: 'updateMode', value: false }));
          
                        dispatch(setGeneralValue({ expr: 'formDisabled', value: true }));
                      }}
                      rowDoubleClicked={() => {
                        doubleClickAccesspriv()
          
                      }}
                />

            </section>

        </div>
        </>
    )
}

export default TagComments