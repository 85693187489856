import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetDataFunc } from "../../../functions/get";


interface apiStates {
    serviceProviderData : any | null;
    productData : any | null;
    isLoading: boolean;
    isPending: boolean;
    isError: boolean;
}

var initialState: apiStates = {
    serviceProviderData: [],
    productData: [],
    isLoading: false,
    isPending: false,
    isError: false,
};

export const fetchSPTypeDump = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "serviceProviderType/fetch",
    async (search: string) => {
        const res = await GetDataFunc(`AsmTmsEntFuelManagement/GetEntAsmTmsLueServiceProvides?PageNumber=1&PageSize=20&Search=${search}`);
        return res.data;
    }
);

export const fetchProductDump = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "product/fetch",
    async (search: string) => {
        const res = await GetDataFunc(`FuelManagement/dumpAllProducts?PageNumber=1&PageSize=20&Search=${search}`);
        return res.data;
    }
);


const returnDump = createSlice({
    name: 'apiFuelDump',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // vehicle fuel card assignment
            .addCase(fetchSPTypeDump.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchSPTypeDump.fulfilled, (state, action) => {
                state.isLoading = false;
                state.serviceProviderData = action.payload;
            })
            .addCase(fetchSPTypeDump.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(fetchProductDump.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchProductDump.fulfilled, (state, action) => {
                state.isLoading = false;
                state.productData = action.payload;
            })
            .addCase(fetchProductDump.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
    },
});

export default returnDump.reducer;
