import React, { useCallback, useRef, useState } from 'react'
import {
    DataGrid,
    Column,
    ColumnFixing,
    Scrolling,
    Selection,
    Editing
} from 'devextreme-react/data-grid';
import { Form, Input, Select, DatePicker, InputNumber, Slider } from 'antd';
import { CheckBox } from 'devextreme-react/check-box';
import { VscNewFile } from 'react-icons/vsc';
import save from '../../assets/save2.png'
import add from '../../assets/add.png'
import locked from '../../../../../assets/locked.png'
import newIcon from '../../../../../assets/new.png'
import refresh from '../../../../../assets/refresh.png'
import { AiOutlineSwitcher } from 'react-icons/ai'
import { EditButton, SaveButton, NewButton } from '../../../../accessories/buttons';
import { TaskUpdateData } from '../../../../Task&Todos/data/taskUpdateData';
import { sia_updates_cols } from '../data/datagrid_cols';
import { CheckboxTemlate } from '../../../../../templates/checkbox';
import {  Tooltip } from 'devextreme-react';
import Datagrid_template from '../../../../../templates/Datagrid';
import useFetch from '../../../../../hooks/useFetch';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../app/store';
import dayjs from 'dayjs';
import { TextareaTemplate } from '../../../../../templates/textarea';
import { dateFormat } from '../../../../accessories/component_infos';
import { SelectsTemplate } from '../../../../../templates/select';
import { InputsTemplate } from '../../../../../templates/input';


interface props {
    // this means whether a prop is passed on the component or not the component called
    // anywhere should work (i.e. with the ?) but without the ? it takes it's strict rule
    // that without a prop there will be an error 
    toggler?: () => void
    siaId?: any
    customHeightHook?: any
}

interface stateTypes {
    date?: string,
    complete?: number,
    job?: number,
    description?: string,
  
  }

export const SIA_assignment_update = ({ toggler, siaId, customHeightHook }: props) => {

  const siaDataGrid = useSelector((state:RootState)=>state.general.siaDataGrid)
  const borderTheme = useSelector((state:RootState)=>state.general.cssBorderClass)
  

  const [toggled, setToggled] = useState(false);
   

     const [taskUpdates, taskUpdatesError, taskUpdatesLoading] = useFetch(`api/PfmStaffInitiativesAssignments/GetTaskUpdates?SiaIDpk=10`);
     const handleref = useRef<any>(null);
     const [states, setStates] = useState<stateTypes>({
        date: "",
        complete: 0,
        job: 0,
        description: "",
    
      })

      const setState = (key: string, value: any) => {
        setStates({ ...states, [key]: value })
      }
      const [textAreaHeight , settextAreaHeight] = useState<string>('127px')
      const [onChangedoneby, setOnChangeDoneby] = useState<any>("")
   
      const handleChange = (value: string[]) => {
        setOnChangeDoneby(value)
    
     console.log('====================================');
        console.log(handleref.current.clientHeight);
        console.log(handleref.current.clientWidth);

        console.log('====================================');
        console.log(`selected ${value}` );

        var handlerefHeight = handleref.current.clientHeight
        var handlerefWidth = handleref.current.clientWidth/4

        if(handlerefHeight > 29){
            settextAreaHeight('111px')
        }else {
            settextAreaHeight('127px')
        }
        
        // if(isScroll == true) {
        //     settextAreaHeight('111px')
        //     console.log(isScroll + " is  scrollable");
        //     setIsScroll(!isScroll)
            
        // }
        // else
        // { 
        //     settextAreaHeight('127px')
        //     console.log(isScroll + " is not scrollable");
           
            
        // }
        
    };


    return (

        <div style={{borderColor:borderTheme}} className='m-1 border rounded'>

            <div style={{ height: '' }} className='flex  justify-between w-full h-full bg-slate-50 dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base'>
                {/* <h4 className=" justify-start  w-32 ml-4 mb-2 bg-slate-200 ">Tasks Update</h4> */}
                <p className='w-full py-1 px-2 bg-slate-100 dark:bg-darkModeSkin-div_headers_backgroundColor dark:text-darkModeSkin-headers'>Task Update</p>
                <ul className='flex items-center justify-end w-full bg-slate-100  self-end dark:bg-darkModeSkin-div_headers_backgroundColor dark:text-darkModeSkin-headers'>
                    <CheckboxTemlate withBorders={true} />
                    <span
                                            onClick={()=>{
                                                // setData('');
                                                // handleRefresh && handleRefresh();
                                            }}
                                            style={{height:23.5, borderWidth:1,width:25}}
                                            className='mx-1 flex justify-center hover:cursor-pointer border-gray-300 rounded'>
                                                <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src= {refresh} alt="reset" />
                                        </span>

                    <li onClick={()=>{setToggled(!toggled)}} className="hover:cursor-pointer border  border-gray-300 rounded text-xs  py-1 text-gray-600 px-2 mr-1 flex items-center"><AiOutlineSwitcher size={16} /></li>

                </ul>
            </div>

            {/* Datagrid table */}
           {toggled? <div style={{ width: '100%', }} className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base">
                
                <Datagrid_template gridheight={235} 
                columns={sia_updates_cols} data={      taskUpdates}

                disableSearch={true} disableGroupPanel={true} disablePaging={true}
            /> 
                
             
            </div> : <div>
            <div
        style={{ width: "100%" }}
        className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base"
      >
        <Form
          className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-1 px-2 "
          name="basic"
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          size="small"
          layout="horizontal"
          disabled={false}
        >

          <Form.Item
            wrapperCol={{ span: 15 }}
            label={
              <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                {"Date"}
              </p>
            }
            className="mb-1 "
          >
            <div className=" dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center ">
              <DatePicker
                onChange={(date, dateString) => { setState('date', dateString) }}
                showTime={{ defaultValue: dayjs("00:00:00", "HH:mm") }}
                // className="3/6"
                format={dateFormat}
              />
              <SelectsTemplate label='Status'useCusWidth={true} 
              cusWidth={'200px'}
                disabledCustomWidth={false}
                selectStyle={{'width': '200px'}}
                style={{'marginLeft': '20px'}}
                disabled={false}
              />
            </div>
          </Form.Item>



          

          <Form.Item
            className="mb-1"
            label={
              <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                {"% Complete"}
              </p>
            }
          >
            <div className="w-full flex dark:bg-slate-100 dark:text-darkModeSkin-base">
              <Slider style={{ width: '100%' }} className="mr-5" min={0} max={100} onChange={(e) => { setState('complete', e) }} value={states.complete} />
              <InputNumber

                className="h-6 w-16 flex items-center justify-center"
                size="small"
                min={0}
                max={100}
                // style={{ margin: '0 16px' }}
                value={states.complete}
                onChange={(e) => { setState('complete', e) }}
              />
            </div>
          </Form.Item>

          <Form.Item
            style={{ display: "inline-block", marginBottom: "2px" }}
            label={
              <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                {"Update "}
              </p>
            }
            className="w-full mb-1"
          >
            <div className=" dark:bg-slate-900 dark:text-darkModeSkin-base">
              <TextareaTemplate
                height={134}
                useCallbackFunc
                defaultValue=""
                setCallbackValue={(e) => { setState('description', e) }}


              />

            </div>
          </Form.Item>
          <Form.Item  style={{paddingLeft:'', display:'inline-block', }}  label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{'Done By'}</p>} className='mb-0.5 w-full'>
                                            <div ref={handleref} className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center "  >
                                                <Select  fieldNames={{options:'100px'}} bordered={false} virtual={false} style={{border:'1px solid #ccc',maxHeight:'44px',overflowY:'scroll', borderRadius:'5px',outline:'none',borderColor:borderTheme}}  placeholder='Please Select' allowClear mode='multiple'  onChange={handleChange}> 
                                                
                                                {
                                                 []?.map((option:any,index:any)=>{
                                                    return(
                                                        <option  key={option.id} value ={option.name}>{option.name}</option>
                                                    )
                                                }) 
                                               }
                                                
                                                </Select>
                                                <span
                                            onClick={()=>{
                                                // setData('');
                                                // handleRefresh && handleRefresh();
                                            }}
                                            style={{height:23.5, borderWidth:1,width:25,borderColor:borderTheme}}
                                            className='mx-1 flex justify-center hover:cursor-pointer border-gray-300 rounded'>
                                                <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src= {refresh} alt="reset" />
                                        </span>
                                            </div>                                                
                                        </Form.Item>
                                        <Form.Item  style={{paddingLeft:'', display:'inline-block'}}  label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{'Save for All?'}</p>} className='mb-0.5 w-full'>
                                            <CheckboxTemlate withBorders={true} />                                               
                                        </Form.Item>
        </Form>
      </div>
            </div>
}

            <div className='w-full flex px-3 flex-row items-start justify-between py-1  bg-slate-100'>
                <li className=' flex justify-start items-center'><EditButton /></li>

                <ul className='flex items-start justify-start'>
                    <SaveButton useCallbackFunc new_button_toggler={() => { }} />
                    <button
                        onClick={() => {

                        }}
                        style={{borderColor:borderTheme}}
                        className=" h-8 flex hover:border-blue-200 flex-row items-center justify-center ml-1 px-2 py-1 border-2 border-slate-200 rounded dark:bg-slate-900 dark:text-darkModeSkin-base"
                    >
                        <img className="pr-1" src={newIcon} />
                        New
                    </button>
                </ul>



            </div>


        </div>
    )
}

const progressBar = () => {
    return (
        <div className="flex flex-col" id="avGAllTasks">
            <div className="w-full h-4  bg-gray-200 rounded-sm dark:bg-gray-700">
                <div className="relative h-full flex ">
                    <div className=" absolute h-full w-6 bg-purple-300 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-sm " ></div>
                    <div className="relative text-xs text-black w-full text-center">42%</div>

                </div>

            </div>
        </div>

    )
}