import { Checkbox, DatePicker, Input, Select } from 'antd';
import { Tooltip } from "devextreme-react";
import 'devextreme/dist/css/dx.light.css';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import alter from '../../../assets/alter.png';
import close from '../../../assets/close.png';
import edit from '../../../assets/edit.png';
import newfile from '../../../assets/new.png';
import printer from '../../../assets/printer.png';
import question from '../../../assets/question.png';
import refresh from '../../../assets/refresh.png';
import statusLoader from '../../../assets/statusloader.gif';
import { task_footer_update, task_form_disable } from "../../../features/Task&Todo/Task/TaskDetails";
import { setGeneralValue, settask_footer_update, settask_form_disable } from "../../../features/generalSlice";
import { ModalTemplate } from "../../../templates/modal";
import { saveModalData } from "../component_infos";
import { AlertSMS, AppraiseSettings, DeleteTask, TaskSettings } from "./widgets/customToolbarModals";

import { FaBinoculars } from "react-icons/fa";
interface props{
    toggler ?: ()=> void

    
}



export const Fuel_backlogtoolbar = ({toggler}:props)=>{

    const { Search } = Input;

    const dateFormat = " DD MMM YYYY";
    const dispatch = useDispatch()
   

    const [isActive, setIsActive] = useState<boolean>(false)
    const [SettingsisActive, SettingssetIsActive] = useState<boolean>(false)
    const [AlertSMSisActive, AlertSMSsetIsActive] = useState<boolean>(false)
    const [AppraiseisActive, AppraisesetIsActive] = useState<boolean>(false)
    const size:number = 16;

    const handleChange = (value: string) => {
      };

      const [searchValue, setSearchValue] = useState<string>();
    
        
    const disable = useSelector((state:RootState)=>state.general.formDisabled);
    const disablex = useSelector((state:RootState)=>state.addTask.taskFormStatus);
    const updateMode = useSelector((state:RootState)=>state.addTask.taskFooterUpdate);
    const selectedRecord = useSelector((state: RootState) => state.addTask.selectedRecord)
    const sideAlert = useSelector((state:RootState)=> state.general.sideAlertTest)
    
    const handleNew = () => {
        toggler&&toggler();
        dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
        
        dispatch(task_form_disable(false));
        dispatch(settask_form_disable(false));
    
        
        dispatch(task_footer_update(false));
        dispatch(settask_footer_update(false))
        
        
        dispatch(setGeneralValue({ expr: "selectedFieldData", value: [] }));
      };

      const cancel_button_toggler = () => {
        dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
        dispatch(task_form_disable(true));
        dispatch(settask_form_disable(true))
        
        dispatch(task_footer_update(false));
        dispatch(settask_footer_update(false))
    
    
        
        dispatch(setGeneralValue({expr: 'updateMode' ,value : false}));
    
      
        
      };

    const [showModalDisableButton, setshowModalDisableButton] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [modalData, setModalData] = useState<any>(saveModalData);
    const [icon, setIcon] = useState<'warning' | 'success' | 'question'>();
    const [showCancel, setshowCancel] = useState(false);


      
   const handleUpdate = ()=>{
    if (Object.keys(selectedRecord).length === 0) {
        
        setIcon('warning')
        setshowModalDisableButton(true);
        setshowCancel(false)
        setModalData({ message: "No record selected", title: "Invalid record", okText: "Ok" });
        setOpenModal(true);
    }

    else {
        
        dispatch(task_form_disable(false));

        
        dispatch(task_footer_update(true))
    }
   }

   

    return (
        <div style={{borderBottomWidth : '1px', width:'4000px'}} className=" h-full flex justify-between border-gray-200 mb-1 mx-1">
            <div className="" >
            <ModalTemplate icon_width={modalData.iconSize === 'large' ? 100 : 50} icon={icon} cancelText={modalData.cancelText}
                    disableCancel={!showCancel}
                    cancelHandler={() => { setOpenModal(false) }} open={openModal} okHandler={() => { setOpenModal(false) }}
                    message={modalData.message} okText={modalData.okText} title={modalData.title} />
            <ul  className="flex justify-evenly w-full items-center h-full py-0.5">
                             {/* new and cancel section */}
                {(disablex || updateMode)?
                <li  id="newRecord" onClick={()=> handleNew()} style={{borderWidth : '1px'}}  className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center" >
                    <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img alt="" className="pr-1 " src={newfile} />New </span> 
                    <Tooltip
                        target="#newRecord"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        hideOnOutsideClick={true}
                        >
                        <p className="">New Record</p>
                    </Tooltip>
                </li>
                :
                <li  id="close-cancel" onClick={()=> cancel_button_toggler()} style={{borderWidth : '1px'}}  className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center" >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img alt="" className="pr-1 " src={close} />Cancel </span> 
                <Tooltip
                    target="#close-cancel"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    hideOnOutsideClick={true}
                    >
                    <p className="">Cancel</p>
                </Tooltip>
                </li>
                }
                     {!updateMode ?
                <li id="editRecordst" onClick={()=> {handleUpdate()}} style={{borderWidth : '1px'}}  className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center">
                    <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img alt="" className="dark:bg-slate-900 pr-1 dark:text-gray-100 duration-100" src={edit} />Edit </span>  
                    <Tooltip
                        target="#editRecordst"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        hideOnOutsideClick={true}
                        >
                        <p className="">Edit Record</p>
                    </Tooltip>
                </li>
                :
                <li  id="close-cancel" onClick={()=> cancel_button_toggler()} style={{borderWidth : '1px'}}  className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center" >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img alt="" className="pr-1 " src={close} />Cancel </span> 
                <Tooltip
                    target="#close-cancel"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    hideOnOutsideClick={true}
                    >
                    <p className="">Cancel</p>
                </Tooltip>
                </li>
                }

                    <li id="switch" onClick={toggler} style={{borderWidth : '1px'}}  className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center">
                    <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img alt="" className="dark:bg-slate-900 pr-1 dark:text-darkModeSkin-base duration-100" src={alter} />Switch </span> 
                        <Tooltip
                            target="#switch"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                            >
                            <p className="h-2">Switch interface / table view</p>
                        </Tooltip>
                    </li>
                    
                    
                    

                    <li id="help" style={{borderWidth : '1px'}}  className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center">
                        <span className="flex"> <img alt="" className="dark:bg-slate-900  dark:text-gray-100 duration-100" src={question} /> </span> 
                        <Tooltip
                            target="#help"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                            >
                            <p>Help Center</p>
                        </Tooltip>
                    </li>


                    {/* Differents starts here */}

                    <li className=" flex items-center ml-2 ">
                        <label  className = 'flex text-xs px-2'>Search Criteria </label>
                            <Select
                                
                                style={{ width: `${sideAlert===true?30:150}` }}
                                onChange={handleChange}
                                options={data}
                                size="small"
                                
                                dropdownStyle={{minWidth:'250px' }}
                            />
                        
                        </li>
                    

                    <li  className="px-1 flex items-center  ">
                            <label  className = 'flex text-xs px-2'>Search Text</label>
                            <Input name="search" id="search" size="small"  className="h-6" style={{ width: '150px'}} onChange={(e:any)=>{setSearchValue(e.target.value)}} value={searchValue} />
                        </li>
                        <li className="px-0.5">
                            <DatePicker size="small" placeholder="Start Date" style={{ width: `${sideAlert===true?20:125}`}}  format={dateFormat}/>
                        </li>
                        <li className="px-0.5">
                            <DatePicker size="small" placeholder="End Date" style={{ width: 125 }} format={dateFormat}/>
                        </li>
                        <li className="px-0.5 mr-1">
                            <Checkbox className='border-[1px] rounded px-1'  />
                            <Tooltip
                                target="#active_status"
                                showEvent="dxhoverstart"
                                hideEvent="dxhoverend"
                                hideOnOutsideClick={true}
                            >
                                <p>Active status</p>
                            </Tooltip>
                    </li>

                    <li  className="hover:cursor-pointer mr-1 border-[1px] py-1 text-xs px-1 text-gray-600 rounded flex items-center">
                        <span> <img alt="" className="w-4  dark:bg-slate-900 dark:text-gray-100 duration-100" src={refresh} /> </span>
                    </li>
                        <li id="find" style={{borderWidth : '1px'}}  className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1 text-gray-600 rounded flex items-center">
                             <span> <FaBinoculars size={15.5} color="#007AFF"/> </span> 
                             <Tooltip
                            target="#find"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                            >
                            <p>Find</p>
                        </Tooltip>
                        </li>
                        {/* <li id="print" style={{borderWidth : '1px'}}  className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1 text-gray-600 rounded flex items-center">
                            <span> <img alt="" className="dark:bg-slate-900 dark:text-gray-100 duration-100" src={printer} /> </span> 
                            <Tooltip
                            target="#print"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                            >
                            <p>Print / Export</p>
                            </Tooltip>
                        </li> */}


                        <li className="hover:cursor-pointer ml-20  px-2  flex items-center">
                            {disablex && <p className=" italic font-semibold text-gray-600">Done.</p>}  

                            {!disablex && <div className="  flex flex-row items-center">
                                <img style={{width:'31px', height:'31px'}} src= {statusLoader} alt="gif" />
                                {!updateMode ?
                                <p className=" italic text-gray-600">New Record</p>
                                :
                                <p className=" italic text-gray-600">Editing</p>}
                            </div>}
                        </li>
                    
                        
                </ul>      

            </div>

            <div className="">
                    <ul className=" ">
                        

                        

                    </ul>

                    <DeleteTask isActive={isActive} setIsActive={setIsActive} />
                    <TaskSettings SettingsisActive={SettingsisActive} SettingssetIsActive={SettingssetIsActive} />
                    <AlertSMS AlertSMSisActive={AlertSMSisActive} AlertSMSsetIsActive={AlertSMSsetIsActive}/>
                    <AppraiseSettings AppraiseisActive={AppraiseisActive} AppraisesetIsActive={AppraisesetIsActive} />
                </div>
        </div>
    )
};

const data = [
    
       
          { id: 1, label: 'Registration No', value: 'Registration No' },
          { id: 2, label: 'Chasis No', value: 'Chasis No' },
          { id: 3, label: 'Vehicle Type', value: 'Vehicle Type' },
          { id: 4, label: 'Vehicle Make', value: 'Vehicle Make' },
          { id: 5, label: 'Vehicle Model', value: 'Vehicle Model' },
          { id: 6, label: 'Manufacturer', value: 'Manufacturer' },
          { id: 7, label: 'Supplier', value: 'Supplier' },
          { id: 8, label: 'Manufacturer Date', value: 'Manufacturer Date' },
          { id: 9, label: 'Supply Date', value: 'Supply Date' },
]

























