import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Form, Input, Select, DatePicker } from "antd";
import { CheckBox } from "devextreme-react/check-box";
import reset from "../../../../../../assets/reset.png";
import email from "../../../../../../assets/email.png";
import axios from "axios";
import dayjs from "dayjs";
import { ModalTemplate } from "../../../../../../templates/modal";
import {
  UpdateButton,
  Cancel,
  NewButton,
} from "../../../../../accessories/buttons";
import {
  saveModalData,
  api_url,
  updateModalData,
} from "../../../../../accessories/component_infos";
interface props {
  objectiveDataList: any;
  nameDataList: any;
  toggleRefresh: any;
}

export const SmsAlertVehicleUsers = forwardRef(
  ({ objectiveDataList, nameDataList, toggleRefresh }: props, ref: any) => {
    const { TextArea } = Input;

    const options = [];
    for (let i = 1; i < 36; i++) {
      options.push({
        label: i.toString(36) + i,
        value: i.toString(36) + i,
      });
    }

    // inits
    const [selectedDataId, setSelectedDataId] = useState("");
    const [selectedObjective, setSelectedObjective] = useState({
      id: "",
      name: "",
    });
    const [startDate, setStartDate] = useState<any>(null);
    const [endDate, setEndDate] = useState<any>(null);
    const [remarks, setRemarks] = useState("");
    const [owner, setOwner] = useState(false);
    const [toData, setToData] = useState("");
    const [fromData, setFromData] = useState("");
    const [showModalDisableButton, setShowModalDisableButton] = useState(false);

    const [openModal, setOpenModal] = useState(false);
    const [modalData, setModalData] = useState<any>(saveModalData);
    const [icon, setIcon] = useState<"warning" | "success" | "question">();

    const [disableForms, setDisableForms] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [showCancel, setShowCancel] = useState(false);

    const validateData = async () => {
      setIcon("warning");
      if (startDate === null) {
        setShowModalDisableButton(true);
        setModalData({
          message: "Please select a start date",
          title: "Enter start date",
          okText: "Ok",
        });
        setOpenModal(true);
      } else if (endDate === null) {
        setShowModalDisableButton(true);
        setModalData({
          message: "Please select a end date",
          title: "Enter end date",
          okText: "Ok",
        });
        setOpenModal(true);
      } else if (selectedObjective.name === "") {
        setShowModalDisableButton(true);
        setModalData({
          message: "Please select an objective",
          title: "Select objective",
          okText: "Ok",
        });
        setOpenModal(true);
      } else {
        setIcon("question");
        setShowModalDisableButton(false);
        setOpenModal(true);
      }
    };

    const clearFields = async () => {
      setSelectedObjective({ name: "", id: "" });
      setRemarks("");

      setOwner(false);
      setStartDate(null);
      setEndDate(null);
      setUpdating(false);
      setToData("");
      setFromData("");
    };

    useImperativeHandle(ref, () => ({
      doubleClick: () => {
        setShowCancel(true);
        setUpdating(true);
        setDisableForms(false);
      },
      singleClick: (selectedRow: any) => {
        setSelectedDataId(selectedRow.odsIdpk);
        setDisableForms(true);
        for (var a = 0; a < objectiveDataList.length; a++) {
          if (objectiveDataList[a].id === selectedRow.objIdpk) {
            setSelectedObjective({
              name: objectiveDataList[a].name,
              id: objectiveDataList[a].id,
            });
          }
        }
        setStartDate(dayjs(selectedRow.odsStartDate));
        setEndDate(dayjs(selectedRow.odsEndDate));
        setRemarks(selectedRow.odsRmks);
        setOwner(selectedRow.odsOwner);
        setDisableForms(true);
        setUpdating(false);
        setShowCancel(false);
        setToData(selectedRow.odsTo);
        setFromData(selectedRow.odsFrom);
      },
    }));
    const dateFormat = " DD MMM YYYY HH: mm:ss";

    /**
     **************************************
     **************************************
     ***********Api Integrations***********
     **************************************
     **************************************
     */
    const updateData = async () => {
      await axios.put(
        `${api_url}/api/PfmObjectives/UpdatePfmObjectiveDestination`,
        {
          odsIdpk: selectedDataId,
          odsObjectiveIdfk: selectedObjective.id,
          odsStartDate: startDate,
          odsEndDate: endDate,
          odsFrom: fromData,
          odsTo: toData,
          odsActive: true,
          odsRmks: remarks,
          odsEditedBy: 1,
          odsEditedDate: "2023-02-13T16:40:25.171Z",
        }
      );
      //close modal
      setOpenModal(false);
      setShowModalDisableButton(true);
      setIcon("success");
      setModalData({
        message: "Record successfully updated",
        title: "Record updated",
        okText: "Ok",
      });
      setOpenModal(true);
      toggleRefresh();
    };

    const postData = async () => {
      try {
        await axios.post(
          `${api_url}/api/PfmObjectives/CreatePfmObjectiveDestination`,

          {
            odsObjectiveIdfk: selectedObjective.id,
            odsStartDate: `${dayjs(startDate).format("YYYY-MM-DD")}`,
            odsEndDate: `${dayjs(endDate).format("YYYY-MM-DD")}`,
            odsFrom: fromData,
            odsTo: toData,
            odsActive: true,
            odsRmks: remarks,
            odsCreatedBy: 1,
          }
        );

        setOpenModal(false);
        setShowModalDisableButton(true);
        setIcon("success");
        setModalData({
          message: "Record successfully saved",
          title: "Record saved",
          okText: "Ok",
        });
        setOpenModal(true);
        toggleRefresh();
      } catch (error: any) {}
    };

    return (
      <div
        style={{ width: "100%", minWidth: "100%", height: "100%" }}
        className="  border-2  bg-slate-50 dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base "
      >
        <ModalTemplate
          icon={icon}
          cancelText={modalData.cancelText}
          cancelHandler={() => {
            setOpenModal(false);
          }}
          disableCancel={showModalDisableButton}
          open={openModal}
          okHandler={() => {
            showModalDisableButton === true
              ? setOpenModal(false)
              : updating === true
              ? updateData()
              : postData();
          }}
          message={modalData.message}
          okText={modalData.okText}
          title={modalData.title}
        />

        <div
          style={{ maxHeight: "280px", minHeight: "280px", height: "280px" }}
          className=" items-start justify-start bg-slate-50"
        >
          <div className="border px-2 text-md text-stone-500">
            Gen - Schedule Request
          </div>
          <Form
            className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 px-2 "
            name="basic"
            initialValues={{ remember: true }}
            autoComplete="off"
            size="small"
            layout="horizontal"
          >
            <Form.Item
              label={
                <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                  {"Report Date"}
                </p>
              }
              labelCol={{ span: 2 }}
            >
              <div className="flex flex-row justify-between">
                <DatePicker
                  size="small"
                  placeholder="Start Date"
                  style={{ width: "25%" }}
                  format={dateFormat}
                />

                <span className="h-full w-4 ml-1 flex items-center justify-center hover:cursor-pointer hover"></span>
              </div>
            </Form.Item>
            <Form.Item
              label={
                <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                  {"Workshop"}
                </p>
              }
              className={" mb-1"}
              labelCol={{ span: 2 }}
            >
              <div className="flex flex-row items-center">
                <Select
                  onChange={(option) => {}}
                  className="w-full mr-0"
                  placeholder=""
                  allowClear
                  style={{ width: "25%" }}
                >
                  {[].map((option: any, index: number) => {
                    return (
                      <option key={index} value={JSON.stringify(option)}>
                        {option["name"]}
                      </option>
                    );
                  })}
                </Select>

                <span
                  onClick={() => {}}
                  style={{ height: 22.5, borderWidth: 1, width: 25.2 }}
                  className=" ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                >
                  <img
                    className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                    src={reset}
                    alt="reset"
                  />
                </span>
              </div>
            </Form.Item>
            <Form.Item
              labelCol={{ span: 2 }}
              label={
                <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                  {"Alert Message"}
                </p>
              }
            >
              <div className="flex flex-row ">
                <TextArea
                  value={fromData}
                  onChange={(e: any) => {
                    setFromData(e.target.value);
                  }}
                  style={{ resize: "none", height: "225px", width: "45%" }}
                />

                <span className="h-full w-4 ml-1 flex items-center justify-center hover:cursor-pointer hover"></span>
              </div>
            </Form.Item>

            <div className="w-full  flex flex-col mt-0.5 ">
              <div
                style={{ width: "calc(50%)", marginLeft: "" }}
                className=" flex  justify-between  "
              >
                <li className=" invisible flex items-center flex-row">
                  <p className="mr-3">Owner? : </p>{" "}
                  <CheckBox
                    onValueChange={(e: any) => {
                      setOwner(e);
                    }}
                    value={owner}
                  />
                </li>
                <ul className="flex justify-end  ">
                  {updating === true ? (
                    <UpdateButton
                      handleUpdate={() => {
                        setShowModalDisableButton(false);
                        setModalData(updateModalData);
                        validateData();
                      }}
                    />
                  ) : (
                    <button
                      style={{}}
                      className="hover:cursor-pointer dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  mr-1 px-2 py-1 border-2 border-slate-200 rounded hover:border-blue-300"
                    >
                      <img alt="" className="pr-1" src={email} />
                      Send
                    </button>
                  )}
                  {showCancel === true ? (
                    <Cancel
                      useCallbackFunc
                      cancel_button_toggler={() => {
                        console.log("fas");
                        clearFields();
                        setShowCancel(false);
                        setUpdating(false);
                        clearFields();
                        setDisableForms(true);
                      }}
                    />
                  ) : (
                    <NewButton
                      useCallbackFunc={true}
                      new_button_toggler={() => {
                        setShowCancel(true);
                        setShowModalDisableButton(false);
                        setModalData(saveModalData);
                        clearFields();
                        setDisableForms(false);
                      }}
                    />
                  )}
                </ul>
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  }
);
