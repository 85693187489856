import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncGet, GetDataFunc } from "../../../functions/get";
import { stat } from "fs";

type State = {
  station_service: any | null | undefined;
  product: any | null | undefined;
  isError: boolean;
  isLoading: boolean;
  isPending: boolean;
};
const initialState: State = {
  station_service: [],
  product: [],
  isError: false,
  isLoading: false,
  isPending: false,
};
export interface StationType {
  sstIdpk: number;
  sstName: string;
  twnName: string;
}
export interface ProductType {
  prdIdpk: number;
  prdName: string;
}

export const Fetch_StationService = createAsyncThunk<
  State,
  string,
  { rejectValue: string }
>("fetch_station_service", async (search: string) => {
  try {
    var response = await AsyncGet(
      `AsmTmsEntFuelDumpDip/GetEntAsmTmsLueServiceStationsDefault`,
      {
        pagenumber: 1,
        pagesize: 20,
        search,
      }
    );
    if (response?.status === true) {
      return response?.data;
    }
  } catch {
    return response.data;
  }
});
export const Fetch_Product = createAsyncThunk<
  State,
  string,
  { rejectValue: string }
>("fetch_product", async (search, stationId) => {
  try {
    var response = await AsyncGet(
      `AsmTmsEntFuelDumpDip/GetEntAsmTmsLueProducts`,
      {
        pagenumber: 1,
        pagesize: 20,
        search,
        stationId,
      }
    );
    if (response.status === true) {
      return response.data;
    }
  } catch {
    return response.data;
  }
});
const Slice_FuelDumpDips = createSlice({
  name: "FuelDumpDips",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(Fetch_StationService.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(Fetch_StationService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.station_service = action.payload;
      })
      .addCase(Fetch_StationService.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })

      .addCase(Fetch_Product.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(Fetch_Product.fulfilled, (state, action) => {
        state.isLoading = false;
        state.product = action.payload;
      })
      .addCase(Fetch_Product.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default Slice_FuelDumpDips.reducer;
