/* eslint-disable react/jsx-pascal-case */
import edit from "../../../../../../../assets/edit.png";
import reset from "../../../../../../../assets/reset.png";
import newIcon from "../../../../../../../assets/new.png";
import close from "../../../../../../../assets/close.png";
import save from "../../../../../../../assets/save.png";
import {
  ApproveButton,
  DisapproveButton,
  SubmitButton,
} from "../../../../../../accessories/buttons";
import { Mini_datagrid_template } from "../data/mini_datagrid";
import { Materials_cols } from "../data/mini_datagrid_cols";
import useFetch from "../../../../../../../hooks/useFetch";
import { DatePicker, Form, Input, InputNumber, Select } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import {
  currentDate,
  deleteModalData2,
  saveModalData,
  updateModalData,
} from "../../../../../../accessories/component_infos";
import {
  setGeneralValue,
  settask_footer_update,
} from "../../../../../../../features/generalSlice";
import { ModalTemplate } from "../../../../../../../templates/modal";
import {
  setmaintenanceDetailValue,
  task_footer_table_selected,
} from "../../../../../../../features/forms/entries/safetyManager/ppeRequest";
import { TextareaTemplate } from "../../../../../../../templates/textarea";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import { PostDataFunc } from "../../../../../../../functions/post";
import { UpdateDataFunc } from "../../../../../../../functions/update";
import { useBusyLoader } from "../../../../../../../hooks/useBusyLoader";
import { debounce, set } from "lodash";
import { useCrudFunc } from "../../../../../../../functions/crud";
import { Console } from "console";
import InputSelect_template from "../../../../../../../templates/InputSelect";
import {
  Fetch_CurrentPrice,
  Fetch_Job,
  Fetch_Materials,
  Fetch_unit,
} from "../../../../../../../features/apiCalls/maintenanceactApi/materials";
import { Tooltip } from "devextreme-react";

interface props {
  // submit?: any;
  selectedRecordx?: any;
  setrefreshx?: () => void;
  materialRecord?: any;
  materialDetailslookUp?: any;
}

interface stateTypes {
  jobState?: { id: number; name: string };
  materialState?: { id: number; name: string };
  dateState?: any;
  quantityState?: number;
  unitState?: { id: number; name: string };
  reqNoState?: string;
  codeState?: string;
  unitPriceState1?: number;
  unitPriceState2?: number;
  currencyState?: { id: number; name: string };
  TotState?: string;
  remarks?: string;
  materialSelectedRecord?: any;
  isOpenDropdown1: boolean;
  isOpenDropdown2: boolean;
  isOpenDropdown3: boolean;
  isOpenDropdown4: boolean;
  isLoading: boolean;
  searchMaterial: string;
  searchUnit: string;
  searchCurrency: string;
}

export const Maintenance_activities_Materials_Details = ({
  materialRecord,
  selectedRecordx,
  // submit,
  setrefreshx,
  materialDetailslookUp,
}: props) => {
  const dispatch = useDispatch();
  const [employeeId, userId, userInfo] = useAccountInfo();

  //  states initialization
  const [states, setStates] = useState<stateTypes>({
    jobState: { id: 0, name: "" },
    materialState: { id: 0, name: "" },
    dateState: currentDate,
    quantityState: 0,
    unitState: { id: 0, name: "" },
    reqNoState: "",
    codeState: "",
    unitPriceState1: 0,
    unitPriceState2: 0,
    currencyState: { id: 0, name: "" },
    TotState: "",
    remarks: "",
    materialSelectedRecord: [],
    isOpenDropdown1: false,
    isOpenDropdown2: false,
    isOpenDropdown3: false,
    isOpenDropdown4: false,
    isLoading: false,
    searchMaterial: "",
    searchUnit: "",
    searchCurrency: "",
  });
  console.log("MaterialState", states);
  // Onchange state Fxn
  const setState = (key: string, value: any) => {
    setStates((prev: any) => ({ ...prev, [key]: value }));
  };

  const [material] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetEntAsmTmsLueMaterials?pagenumber=1&pagesize=20&search=${states.searchMaterial}`
  );
  const [unit] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetEntAsmTmsLueUnits?pagenumber=1&pagesize=20&search=${states.searchUnit}`
  );
  const [currency] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetEntAsmTmsLueCurrency?pagenumber=1&pagesize=20&search=${states.searchCurrency}`
  );

  console.log("material", material);
  const [refresh, setRefresh] = useState<boolean>(false);
  const formCode = useSelector((state: RootState) => state.general.formCode);
  const [posting, updating] = useCrudFunc();

  const populateFields = async (clear?: boolean) => {
    dispatch(task_footer_table_selected([{}]));

    const refreshGrid = () => {
      setRefreshstate((prevState) => !prevState);
    };
    const gridState = [
      {
        key: "dateState",
        value: clear ? currentDate : states.materialSelectedRecord.mamDate,
      },
      {
        key: "jobState",
        value: clear
          ? ""
          : {
              id: states.materialSelectedRecord.mjdIdpk,
              name: states.materialSelectedRecord.mjdJob,
            },
      },
      {
        key: "materialState",
        value: clear
          ? ""
          : {
              id: states.materialSelectedRecord.mamMaterialIdfk,
              name: states.materialSelectedRecord.mtrName,
            },
      },
      {
        key: "quantityState",
        value: clear ? 0 : states.materialSelectedRecord.mamQuantity,
      },
      {
        key: "unitState",
        value: clear
          ? ""
          : {
              id: states.materialSelectedRecord.mamIdpk,
              name: states.materialSelectedRecord.mamQuantityUnit,
            },
      },
      {
        key: "reqNoState",
        value: clear ? "" : states.materialSelectedRecord.mamRequisitionNo,
      },
      {
        key: "codeState",
        value: clear ? "" : states.materialSelectedRecord.mjdRmks,
      },
      {
        key: "unitPriceState1",
        value: clear ? 0 : states.materialSelectedRecord.mamUnitPrice,
      },
      {
        key: "unitPriceState2",
        value: clear ? 0 : states.materialSelectedRecord.mamVatCharge,
      },
      {
        key: "currencyState",
        value: clear
          ? ""
          : {
              id: states.materialSelectedRecord.curIdpk,
              name: states.materialSelectedRecord.curSymbol,
            },
      },
      {
        key: "TotState",
        value: clear ? "" : states.materialSelectedRecord.mamTotal,
      },
      {
        key: "remarks",
        value: clear ? "" : states.materialSelectedRecord.mamRmks,
      },
    ];

    for (let i of gridState) setState(i.key, i.value);
  };

  // Endpoints

  const Format = " YYYY MMM DD";
  const today = dayjs().format(Format);
  const [currency_data] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetEntAsmTmsLueCurrency`,
    refresh
  );
  const [job_data] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetEntAsmTmsLueJobs?activityId=${selectedRecordx?.mtaIDpk}`,
    refresh
  );
  const [materials_data] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetEntAsmTmsLueMaterials`,
    refresh
  );
  const [unit_data] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetEntAsmTmsLueUnits`,
    refresh
  );
  const [supervisorLimit] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetSupervidorApprovalCat?date=${today}`,
    refresh
  );
  const [managerLimit] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetSupervidorApprovalCat?date=${today}`,
    refresh
  );
  const [directorLimit] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetSupervidorApprovalCat?date=${today}`,
    refresh
  );
  const superviser_details = useFetch(
    `AsmTmsEntMaintenanceActivity/GetAuthorisingSupervisor`
  )[0];
  const manager_details = useFetch(
    `AsmTmsEntMaintenanceActivity/GetAuthorisingManager`
  )[0];
  const director_details = useFetch(
    `AsmTmsEntMaintenanceActivity/GetAuthorisingDirector`
  )[0];
  const privilages = useFetch(
    `SysAdminFormAccessPrivileges/GetAsmTmsPrivileges?EmpID=${userInfo.empIDpk}`
  );

  const allDetails = [
    ...superviser_details,
    ...manager_details,
    ...director_details,
  ];
  //
  //
  // Data from redux
  const form_disable = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const update_state_change = useSelector(
    (state: RootState) => state.general.TMaterialUpdateMode
  );
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  //  Current date
  const editedDate = dayjs().format();
  //
  //loader
  const [busyLoader, setBusyLoader] = useBusyLoader();
  // Modal dialog data states

  const [refreshValue, setRefreshValue] = useState(false);
  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();

  const [refreshstate, setRefreshstate] = useState(false);

  const [miniData] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetEntAsmTmsMaintenanceActiviityMaterials?MtaIDpk=${selectedRecordx?.mtaIDpk}`,
    refreshValue
  );
  const MaterailModal = {
    message: "Are you sure you want to submit this material for approval?",
    title: "Material Submission",
    okText: "Yes, Submit",
    cancelText: "No, Cancel",
  };

  const checkConnection = () => {
    if (!navigator.onLine) {
      setshowModalDisableButton(true);
      setModalData({
        message:
          "Your computer is not connected to the Internet. Please check your internet connection and try again",
        title: "No Internet Connection!",
        okText: "Ok",
      });
      setOpenModal(true);
    }
  };

  useEffect(() => {
    const quanState = states.quantityState ?? 0;
    const unitPrice1 = states.unitPriceState1 ?? 0;
    const unitPrice2 = states.unitPriceState2 ?? 0;
    const total = (
      Number(quanState) * Number(unitPrice1) +
      Number(unitPrice2)
    ).toFixed(2);
    setState("TotState", total);
    const vatRate = 0.15; // VAT rate of 20%
    const vatAmount = Number(unitPrice1) * Number(quanState) * vatRate;
    setState("unitPriceState2", vatAmount);
  }, [states.quantityState, states.unitPriceState1, states.unitPriceState2]);

  const dateFormat = "ddd, DD MMM YYYY HH:mm";

  // handleNew
  const handleNew = () => {
    dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
    dispatch(setGeneralValue({ expr: "TMaterialUpdateMode", value: false }));
    // dispatch(setGeneralValue({ expr: "selectedFieldData", value: [] }));
    populateFields(true);
  };

  //  handleCancel
  const handleCancel = () => {
    dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    dispatch(setGeneralValue({ expr: "TMaterialUpdateMode", value: false }));
  };
  // Validation
  const validationForms = async () => {
    setIcon("warning");
    if (states.jobState?.name === "" || states.jobState.name === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select data entry for job",
        title: "Select Job",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      states.materialState?.name === "" ||
      states.materialState.name === undefined
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select data entry for Material",
        title: " Select Material",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.dateState === "" || states.dateState === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select data entry for Date",
        title: " Select Date",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      states.dateState &&
      dayjs(states.dateState, "ddd, DD MMM YYYY").isAfter(dayjs(), "day")
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please Date cannot be greater than today",
        title: " Invalid Date",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      states.quantityState === 0 ||
      states.quantityState === undefined
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please input data entry for Quantity",
        title: "Input Quantity",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (isNaN(Number(states.quantityState.toString()))) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please input numbers only",
        title: " Invalid Input for Quantity",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.quantityState < 0) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please the price value should be above zero ",
        title: " Input Quantity",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.unitState.name === " ") {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select data entry for unit",
        title: " Select Unit",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.reqNoState === "" || states.reqNoState === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please input data entry for Requisition Number",
        title: " Input Requisition Number",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (isNaN(Number(states.reqNoState))) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please input numbers only",
        title: " Invalid Input for Requisition Number!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.codeState === "" || states.codeState === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select data entry for code",
        title: "Code field required!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (isNaN(Number(states.codeState))) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please input numbers only",
        title: " Invalid Input for Code!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.unitPriceState1 === 0) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select data entry for unit price",
        title: " For Unit Price",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.unitPriceState1 < 0) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please the price value should be above zero ",
        title: " Unit Price field required!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (isNaN(Number(states.unitPriceState1.toString()))) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please input numbers only",
        title: " Invalid Input for Unit price!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (isNaN(Number(states.unitPriceState2.toString()))) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please VAT must be a number",
        title: " Invalid Input for VAT!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.unitPriceState2 === 0) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please input data entry for VAT",
        title: " Input Unit VAT",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.currencyState?.name === "") {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select data entry for currency",
        title: " Select Currency",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.TotState === "" || states.TotState === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please input data entry for total.",
        title: " Input Total.",
        okText: "Ok",
      });
      setOpenModal(true);
    } else {
      setshowModalDisableButton(false);
      setIcon("question");
      setOpenModal(true);
    }
  };
  // postData
  const postData = async () => {
    try {
      setBusyLoader("Saving material...");
      const res = await posting(
        `AsmTmsEntMaintenanceActivity/CreateEntMaintenanceActivityMaterial`,
        {
          mamDate: dayjs(states.dateState),
          mamMaintActivityIdfk:
            selectedRecordx !== undefined ? selectedRecordx?.mtaIDpk : 0,
          mamRelatedJobIdfk: states.jobState?.id,
          mamMaterialIdfk: states.materialState?.id,
          mamUnitPrice: states.unitPriceState1,
          mamQuantity: states.quantityState,
          mamVatCharge: states.unitPriceState2,
          mamQuantityUnit: states.unitState?.name,
          mamCurrencyIdfk: states.currencyState?.id,
          mamRequisitionNo: states.reqNoState,
          mamRmks: states.remarks,
          mamCreatedBy: userId,
        },
        `Added  to the Maintenance Activity with ID ${selectedRecordx?.mtaIDpk}  for vehicle with ID  ${selectedRecordx?.vdtIDpk}`
      );

      //
      setRefreshValue(!refreshValue);
      setrefreshx && setrefreshx()
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: "Material details saved successfully!",
        title: "Material details saved!",
        okText: "Ok",
      });
      setBusyLoader("");
    } catch (e: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: "Failed to save material!..please contact your administrator",
        title: "Unexpected Error!",
        okText: "Ok",
      });
      setBusyLoader("");
    }
  };

  // updateData

  const updateData = async () => {
    try {
      setBusyLoader("Updating material....");
      await updating(
        `AsmTmsEntMaintenanceActivity/UpdateEntMaintenanceActivityMaterial`,
        {
          mamIdpk: states.materialSelectedRecord.mamIdpk,
          mamDate: dayjs(states.dateState),
          mamMaintActivityIdfk:
            selectedRecordx !== undefined ? selectedRecordx?.mtaIDpk : 0,
          mamRelatedJobIdfk: states.jobState?.id,
          mamMaterialIdfk: states.materialState?.id,
          mamUnitPrice: states.unitPriceState1,
          mamQuantity: states.quantityState,
          mamVatCharge: states.unitPriceState2,
          mamQuantityUnit: states.unitState?.name,
          mamCurrencyIdfk: states.currencyState?.id,
          mamRequisitionNo: states.reqNoState,
          mamRmks: states.remarks,
          mtrEditedBy: employeeId,
        },
        `Updated Maintenance Material Details with ID ${states.materialSelectedRecord.mamIdpk} `
      );

      setRefreshValue(!refreshValue);
      setrefreshx && setrefreshx()
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      // setOpenModal(false)
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: "Data entry updated successfully!",
        title: "Data Updated!",
        okText: "Ok",
      });
      setBusyLoader("");
    } catch (e: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: "Failed to update record!..please contact your administrator",
        title: "Unexpected Error!",
        okText: "Ok",
      });
      setBusyLoader("");
    }
  };
  const deleteRecord = async () => {
    try {
      setBusyLoader("Deleting material...");
      await updating(
        `AsmTmsEntMaintenanceActivity/DeleteEntMaintenanceActivityMaterial`,
        {
          mamIdpk: states.materialSelectedRecord.mamIdpk,
        },
        `Material with ID ${states.materialSelectedRecord.mamIdpk} deleted successfully by ${userInfo.empName} on ${editedDate}`
      );
      setRefreshValue(!refreshValue);
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: "Material details deleted successfully!",
        title: "Material details Deleted!",
        okText: "Ok",
      });
      setBusyLoader("");
    } catch (e: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: "Failed to delete record!..please contact your administrator",
        title: "Unexpected Error!",
        okText: "Ok",
      });
      setBusyLoader("");
    }
  };
  const Submit = async () => {
    setBusyLoader("Submitting Material Data....");
    try {
      await updating(
        `AsmTmsEntMaintenanceActivity/SubmitEntMaintenanceActivityMaterial`,
        {
          mamIdpk: states.materialSelectedRecord?.mamIdpk,
          mamSubmittedByEmpIdfk: employeeId,
        },
        `Material with ID ${states.materialSelectedRecord?.mamIdpk} submitted successfully by ${userInfo.empName} on ${editedDate}`
      );
      setRefreshValue(!refreshValue);
      setIcon("success");
      setshowModalDisableButton(true);
      setOpenModal(true);
      setModalData({
        message: "Materials submitted successfully.",
        title: "Success!",
        okText: "Ok",
      });
      setBusyLoader("");
    } catch (err: any) {
      setIcon("warning");
      setModalData({
        message:
          "Failed to submit material please try again or contact admin for assistance!",
        title: "Unexpected Error! ",
        okText: "Ok",
        iconSize: "large",
      });
      setshowModalDisableButton(true);
      setOpenModal(true);
      setBusyLoader("");
    }
  };
  //retrieve Material
  const retrieve = async () => {
    try {
      setBusyLoader("Retrieving Material Data....");
      await updating(
        `AsmTmsEntMaintenanceActivity/RetrieveMaterial`,
        {
          mamIdpk: states.materialSelectedRecord?.mamIdpk,
        },
        `Retrieved Material with ID ${states.materialSelectedRecord?.mamIdpk} successfully`
      );
      setRefreshValue(!refreshValue);
      setIcon("success");
      setshowModalDisableButton(true);
      setOpenModal(true);
      setModalData({
        message: "Material retrieved successfully.",
        title: "Success",
        okText: "Ok",
      });
      setBusyLoader("");
    } catch (err: any) {
      setIcon("warning");
      setModalData({
        message:
          "Failed to retrieve material please try again or contact admin for assistance!",
        title: "Unexpected Error! ",
        okText: "Ok",
        iconSize: "large",
      });
      setshowModalDisableButton(true);
      setOpenModal(true);
      setBusyLoader("");
    }
  };

  //recommend Material
  const recommendMaterial = async () => {
    setBusyLoader("Recommending Material Data....");
    try {
      for (let detail of director_details) {
        let msg = `Hello ${detail.empFirstName}, you have been sent a Works Order Authorisation approval request for vehicle with Reg. No ${selectedRecordx?.vdtRegNo}. Thank you.\nSent from InnoX`;
        checkConnection();
        await updating(
          `AsmTmsEntMaintenanceActivity/RecommendEntMaintenanceActivityMaterial`,
          {
            mamIdpk: states.materialSelectedRecord?.mamIdpk,
            mamRecommendedByEmpIdfk: employeeId,
          },
          `Sent Asset Mgr-Trans-Maintenance Approval Alert to employee with Emp ID ${detail.empIDpk} and Staff No ${detail.empStaffNo} successfully sent by ${userInfo?.empName}`
        );
        await posting(
          `SMSAlert/SaveSMS`,
          {
            imsMessage: msg,
            imsRecipientEmpIdfk: detail.empIDpk,
            imsSender: userInfo.empName,
            imsRecordIdfk: states.materialSelectedRecord?.mamIdpk,
            imsMessageType: "string",
            imsMessageClass: "string",
            imsInitiatorEmpIdfk: userId,
            imsCreatedBy: userId,
          },
          `Sent SMS to ID ${detail.empIDpk} successfully sent by ${userInfo?.empName}`
        );
      }
      setRefreshValue(!refreshValue);
      setIcon("success");
      setshowModalDisableButton(true);
      setOpenModal(true);
      setModalData({
        message: "Material recommended successfully",
        title: "Success",
        okText: "Ok",
      });
      setBusyLoader("");
    } catch (err: any) {
      setIcon("warning");
      setModalData({
        message:
          "The amount for the material selected is either above or below your approval limits. Please check and try again.!",
        title: "Unexpected Error! ",
        okText: "Ok",
        iconSize: "large",
      });
      setshowModalDisableButton(true);
      setOpenModal(true);
      setBusyLoader("");
    }
  };

  const supDetails = [...superviser_details];
  //prepare and recommend endpoint
  const prepRec = async () => {
    try {
      setBusyLoader("Preparing and recommending material...");
      for (let detail of supDetails) {
        let msg = `Hello ${detail.empFirstName}, you have been sent a Works Order Authorisation approval request for vehicle with Reg. No ${selectedRecordx?.vdtRegNo}. Thank you.\nSent from InnoX`;
        setBusyLoader("Preparing / Recommending Material...");
        await updating(
          `AsmTmsEntMaintenanceActivity/PrepareAndRecommendEntMaintenanceActivityMaterial`,
          {
            mamIdpk: states.materialSelectedRecord.mamIdpk,
            mamPreparedByEmpIdfk: employeeId,
            mamRecommendedByEmpIdfk: employeeId,
          },
          `Sent Asset Mgr-Trans-Maintenance Approval Alert to employee with Emp ID ${detail.empIDpk} and Staff No ${detail.empStaffNo} successfully`
        );
        checkConnection();

        await posting(
          `SMSAlert/SaveSMS`,
          {
            imsMessage: msg,
            imsRecipientEmpIdfk: detail.empIDpk,
            imsSender: userInfo.empName,
            imsRecordIdfk: states.materialSelectedRecord?.mamIdpk,
            imsMessageType: "string",
            imsMessageClass: "string",
            imsInitiatorEmpIdfk: userId,
            imsCreatedBy: userId,
          },
          `Sent SMS to ID ${detail.empIDpk} successfully sent by ${userInfo?.empName}`
        );
      }
      setRefreshValue(!refreshValue);
      setIcon("success");
      setshowModalDisableButton(true);
      setOpenModal(true);
      setModalData({
        message:
          "The material has been prepared and recommended successfully, pending authorisation",
        title: "Prepared/Recommended successfully",
        okText: "Ok",
      });
      setBusyLoader("");
    } catch (err: any) {
      setIcon("warning");
      setModalData({
        message:
          "Failed to Prepare / recommend material please try again or contact admin for assistance!",
        title: "Unexpected Error! ",
        okText: "Ok",
        iconSize: "large",
      });
      setshowModalDisableButton(true);
      setOpenModal(true);
      setBusyLoader("");
    }
  };

  //Authorise Material
  const authoriseMaterial = async () => {
    try {
      setBusyLoader("Authorising material");
      await updating(
        `AsmTmsEntMaintenanceActivity/AuthorizeEntMaintenanceActivityMaterial`,
        {
          mamIdpk: states.materialSelectedRecord?.mamIdpk,
          mamAuthorisedByEmpIdfk: employeeId,
        },
        `Material with ID ${states.materialSelectedRecord?.mamIdpk} authorised successfully by ${userInfo.empName} on ${editedDate}`
      );
      setRefreshValue(!refreshValue);
      setIcon("success");
      setshowModalDisableButton(true);
      setOpenModal(true);
      setModalData({
        message: "Material authorised successfully",
        title: "Success",
        okText: "Ok",
      });
      setBusyLoader("");
    } catch (err: any) {
      setIcon("warning");
      setModalData({
        message:
          "The amount for the material selected is either above or below your approval limits. Please check and try again.!",
        title: "Unexpected Error! ",
        okText: "Ok",
        iconSize: "large",
      });
      setshowModalDisableButton(true);
      setOpenModal(true);
      setBusyLoader("");
    }
  };
  //Disapprove Material
  const disapprove = async () => {
    try {
    } catch {}
  };

  // prepare Material
  const prepare = async () => {
    setBusyLoader("Preparing Material....");
    try {
      for (let detail of manager_details) {
        let msg = `Hello ${detail.empFirstName}, you have been sent a Works Order Authorisation approval request for vehicle with Reg. No ${selectedRecordx?.vdtRegNo}. Thank you.\nSent from InnoX`;
        await updating(
          `AsmTmsEntMaintenanceActivity/PrepareEntMaintenanceActivityMaterial`,
          {
            mamIdpk: states.materialSelectedRecord?.mamIdpk,
            mamPreparedByEmpIdfk: employeeId,
          },
          `Sent Asset Mgr-Trans-Maintenance Approval Alert to employee with Emp ID ${detail.empIDpk} and Staff No ${detail.empStaffNo} successfully, sent by ${userInfo?.empName}`
        );
        checkConnection();
        await posting(
          `SMSAlert/SaveSMS`,
          {
            imsMessage: msg,
            imsRecipientEmpIdfk: detail.empIDpk,
            imsSender: userInfo.empName,
            imsRecordIDfk: states.materialSelectedRecord?.mamIdpk,
            imsMessageType: "string",
            imsMessageClass: "string",
            imsEmpIDfk: employeeId,
            imsInitiatorEmpIDfk: userId,
            imsCreatedBy: userId,
          },
          `Sent SMS to ID ${detail.empIDpk} successfully, sent by ${userInfo?.empName}`
        );
      }
      setRefreshValue(!refreshValue);
      setIcon("success");
      setshowModalDisableButton(true);
      setOpenModal(true);
      setModalData({
        message: "Material prepared successfully",
        title: "Success",
        okText: "Ok",
      });
      setBusyLoader("");
    } catch (err: any) {
      setIcon("warning");
      setModalData({
        message: "Preparing Failed, please check and try again.!",
        title: "Unexpected Error! ",
        okText: "Ok",
        iconSize: "large",
      });
      setshowModalDisableButton(true);
      setOpenModal(true);
      setBusyLoader("");
    }
  };
  //validate submit
  const submitMat = () => {
    if (states.materialSelectedRecord.length === 0) {
      //if there are no record selected
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select a Record",
        title: "Grid Record Selection Error",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      (states.materialSelectedRecord.mamSubmitted &&
        states.materialSelectedRecord.mamPrepared) ||
      states.materialSelectedRecord.mamRecommended ||
      states.materialSelectedRecord.mamAuthorised === true
    ) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message:
          "This Material has gone through an approval process and so cannot be retrieved",
        title: "Invalid Action",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      states.materialSelectedRecord.mamSubmitted === true && //if the record is submitted but not prepared
      (states.materialSelectedRecord.mamPrepared === false ||
        states.materialSelectedRecord.mamPrepared === null)
    ) {
      setIcon("question");
      setshowModalDisableButton(true);
      setOpenModal(true);
      setModalData({
        message: "Are you sure you want to retrieve this material?",
        title: "Retrieve Material?",
        okText: "Yes",
        cancelText: "No",
      });
    } else {
      setIcon("question");
      setshowModalDisableButton(false);
      setModalData(MaterailModal);
      setOpenModal(true);
    }
  };

  const officer_prepares_and_recommends_supervisor_authorises = () => {
    if (states.materialSelectedRecord.length !== 0) {
      if (states.materialSelectedRecord?.mamSubmitted === false) {
        setIcon("warning");
        setshowModalDisableButton(true);
        setModalData({
          message: "This Material has not been submitted yet",
          title: "Access Denied!",
          okText: "Ok",
        });
        setOpenModal(true);
      } else if (states.materialSelectedRecord?.mamAuthorised === true) {
        setIcon("warning");
        setshowModalDisableButton(true);
        setModalData({
          message: "This material has already been authorised",
          title: "Access Denied!",
          okText: "Ok",
        });
        setOpenModal(true);
      } else if (
        (states.materialSelectedRecord?.mamTotal <=
          supervisorLimit[0]?.salUpperLimit,
        privilages[0][0]?.tupWorkShopServiceOfficer === true) //true
      ) {
        if (states.materialSelectedRecord?.mamPrepared === true) {
          setIcon("warning");
          setshowModalDisableButton(true);
          setModalData({
            message: "This material has already been prepared and recommended",
            title: "Access Denied!",
            okText: "Ok",
          });
          setOpenModal(true);
        } else {
          setIcon("question");
          setshowModalDisableButton(true);
          setModalData({
            message:
              "Are you sure you want to prepare and recommend the selected material?",
            title: "Prepare & Recommend for Authorisation?",
            okText: "Ok",
          });
          setOpenModal(true);
        }
      }
    } else {
      //if there are no record selected
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select a Record",
        title: "Grid Record Selection Error",
        okText: "Ok",
      });
      setOpenModal(true);
    }
  };

  const officer_prepares_supervisor_recommends_manager_authorises = () => {
    if (
      (states.materialSelectedRecord?.mamTotal <=
        managerLimit[0]?.salUpperLimit &&
        states.materialSelectedRecord?.mamTotal >=
          supervisorLimit[0]?.salUpperLimit,
      privilages[0][0]?.tupWorkShopServiceOfficer === true) //true
      //its not recommended
    ) {
      if (states.materialSelectedRecord.mamPrepared === true) {
        setIcon("warning");
        setshowModalDisableButton(true);
        setModalData({
          message: "This material has already been prepared",
          title: "Access Denied!",
          okText: "Ok",
        });
        setOpenModal(true);
      } else {
        setIcon("question");
        setshowModalDisableButton(true);
        setModalData({
          message:
            "Are you sure you want to prepare the selected material for recommendation??",
          title: "Prepare for recommendation?",
          okText: "Ok",
        });
        setOpenModal(true);
      }
    }
    if (privilages[0][0]?.tupDeptAdmin === true) {
      //true
      if (states.materialSelectedRecord?.mamPrepared === false) {
        setIcon("warning");
        setshowModalDisableButton(true);
        setModalData({
          message:
            "This material has not been Prepared yet. You can recommend only prepared materials",
          title: "Access Denied!",
          okText: "Ok",
        });
        setOpenModal(true);
      } else if (
        states.materialSelectedRecord?.mamRecommended === true &&
        states.materialSelectedRecord?.mamAuthorised === false
      ) {
        setIcon("warning");
        setshowModalDisableButton(true);
        setModalData({
          message:
            "This material has already been Recommeded. Please remind your manager to authorise it",
          title: "Access Denied!",
          okText: "Ok",
        });
        setOpenModal(true);
      } else if (
        states.materialSelectedRecord?.mamRecommended === true &&
        states.materialSelectedRecord?.mamAuthorised === true
      ) {
        setIcon("warning");
        setshowModalDisableButton(true);
        setModalData({
          message:
            "This material has already been recommeded, and authorised by your manager",
          title: "Access Denied!",
          okText: "Ok",
        });
        setOpenModal(true);
      } else {
        setIcon("question");
        setshowModalDisableButton(true);
        setModalData({
          message: "Are you sure you want to recommend the selected material?",
          title: "Recommend for authorisation?",
          okText: "Ok",
        });
        setOpenModal(true);
      }
    }
    if (privilages[0][0]?.tupManager === true) {
      //true
      if (states.materialSelectedRecord?.mamRecommended === false) {
        setIcon("warning");
        setshowModalDisableButton(true);
        setModalData({
          message:
            "This material has not been recommended by the supervisor yet. You can authorise only recommended materials",
          title: "Invalid Action!",
          okText: "Ok",
        });
        setOpenModal(true);
      } else {
        setIcon("question");
        setshowModalDisableButton(true);
        setModalData({
          message: "Are you sure you want to authorise the selected material?",
          title: "Authorise?",
          okText: "Ok",
        });
        setOpenModal(true);
      }
    }
  };

  const supervisor_prepares_manager_recommends_director_authorises = () => {
    if (
      states.materialSelectedRecord?.mamTotal <=
        directorLimit[0]?.salUpperLimit &&
      states.materialSelectedRecord?.mamTotal >=
        managerLimit[0]?.salUpperLimit &&
      privilages[0][0]?.tupDeptAdmin === true //supervisor
    ) {
      if (states.materialSelectedRecord.mamPrepared === true) {
        setIcon("warning");
        setshowModalDisableButton(true);
        setModalData({
          message: "This material has already been prepared",
          title: "Access Denied!",
          okText: "Ok",
        });
        setOpenModal(true);
      } else {
        setIcon("question");
        setshowModalDisableButton(true);
        setModalData({
          message:
            "Are you sure you want to prepare the selected material for recommendation??",
          title: "Prepare for recommendation?",
          okText: "Ok",
        });
        setOpenModal(true);
      }
    }
    if (privilages[0][0]?.tupManager === true) {
      if (states.materialSelectedRecord?.mamPrepared === false) {
        setIcon("warning");
        setshowModalDisableButton(true);
        setModalData({
          message:
            "This material has not been prepared by the supervisor yet. You can recommend only prepared materials.",
          title: "Access Denied!",
          okText: "Ok",
        });
        setOpenModal(true);
      }
    } else {
      setIcon("question");
      setshowModalDisableButton(true);
      setModalData({
        message: "Are you sure you want to recommend the selected material?",
        title: "Recommend for authorisation?",
        okText: "Ok",
      });
      setOpenModal(true);
    }
    if (privilages[0][0]?.tupDirector === false) {
      //true
      if (states.materialSelectedRecord?.mamRecommended === false) {
        setIcon("warning");
        setshowModalDisableButton(true);
        setModalData({
          message:
            "This material has not been recommended by the Manager yet. You can authorise only recommended materials.",
          title: "Access Denied!",
          okText: "Ok",
        });
        setOpenModal(true);
      } else {
        setIcon("question");
        setshowModalDisableButton(true);
        setModalData({
          message: "Are you sure you want to authorise the selected material?",
          title: "Authorise?",
          okText: "Ok",
        });
        setOpenModal(true);
      }
    }
  };

  const manager_prepares_director_recommends_ceo_authorises = () => {
    if (privilages[0][0]?.tupManager === true) {
      if (
        states.materialSelectedRecord?.mamTotal >=
        directorLimit[0]?.salUpperLimit
      ) {
        setIcon("question");
        setshowModalDisableButton(true);
        setModalData({
          message:
            "Are you sure you want to Prepare this material for recommendation?",
          title: "Prepare for Recommendation?",
          okText: "Ok",
        });
        setOpenModal(true);
      }
    }
    if (privilages[0][0]?.tupDirector === true) {
      if (states.materialSelectedRecord?.mamPrepared === false) {
        setIcon("warning");
        setshowModalDisableButton(true);
        setModalData({
          message:
            "This material has not been prepared yet by the manager yet. You can recommend only prepared materials.",
          title: "Access Denied!",
          okText: "Ok",
        });
        setOpenModal(true);
      } else {
        setIcon("question");
        setshowModalDisableButton(true);
        setModalData({
          message: "Are you sure you want to recommend the selected material?",
          title: "Recommend for Authorisation?",
          okText: "Ok",
        });
        setOpenModal(true);
      }
    }
    if (privilages[0][0]?.tupCeo === true) {
      if (states.materialSelectedRecord?.mamRecommended === false) {
        setIcon("warning");
        setshowModalDisableButton(true);
        setModalData({
          message:
            "This material has not been recommended by the manager yet. You can authorise only recommended materials.",
          title: "Access Denied!",
          okText: "Ok",
        });
        setOpenModal(true);
      } else {
        setIcon("question");
        setshowModalDisableButton(true);
        setModalData({
          message: "Are you sure you want to authorise the selected material?",
          title: "Authorise?",
          okText: "Ok",
        });
        setOpenModal(true);
      }
    }
  };
  //approve functions
  const approveBtn = () => {
    officer_prepares_supervisor_recommends_manager_authorises();
    supervisor_prepares_manager_recommends_director_authorises();
    manager_prepares_director_recommends_ceo_authorises();
    officer_prepares_and_recommends_supervisor_authorises();
  };

  // Mini datagrid function
  //----------------------------------------------------------------
  const onChangeRecord = (e: any) => {
    if (setState !== undefined) {
      setState("materialSelectedRecord", e.data);
      dispatch(
        setmaintenanceDetailValue({
          expr: "materialSelectedRecord",
          value: [e.data],
        })
      );
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      dispatch(setGeneralValue({ expr: "TMaterialFormDisabled", value: true }));
      dispatch(setGeneralValue({ expr: "TMaterialUpdateMode", value: false }));
    } else {
    }
    dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    dispatch(setGeneralValue({ expr: "TMaterialFormDisabled", value: true }));
    dispatch(setGeneralValue({ expr: "TMaterialUpdateMode", value: false }));
  };

  // double click feature
  const allowUpdate = (e: any) => {
    if (setState !== undefined) {
      setState("materialSelectedRecord", e.data);
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      dispatch(settask_footer_update(true));

      dispatch(setGeneralValue({ expr: "TMaterialUpdateMode", value: true }));
    }
    dispatch(setGeneralValue({ expr: "formDisabled", value: true }));

    dispatch(setGeneralValue({ expr: "TMaterialUpdateMode", value: true }));
  };

  // --------------------------------------------------------------------

  useEffect(() => {
    // Materials_Fetch()

    populateFields(false);
  }, [refreshstate, states.materialSelectedRecord]);

  //Access Priviledge

  const [privType, setPrivType] = useState<string>("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);
  // new button
  const newAccessPriv = () => {
    if (savePriv === true) {
      handleNew();
    } else {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({ message: "No Access Privilege", title: "Access Denied!" });
      setOpenModal(true);
    }
  };

  //save button
  const saveAccessPriv = () => {
    if (savePriv === true) {
      setModalData(saveModalData);
      validationForms();
      setOpenModal(true);
    } else {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({ message: "No Access Privilege", title: "Access Denied!" });
      setOpenModal(true);
    }
  };

  //validate edit
  const valEdit = () => {
    if (states.materialSelectedRecord.length === 0) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({ message: "No Record Selected", title: "No Record!" });
      setOpenModal(true);
    } else if (states.materialSelectedRecord.mamAuthorised === true) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message:
          "This record has been authorised and so cannot be edited, please disapprove it first before trying to edit.",
        title: "Invalid Action!",
      });
      setOpenModal(true);
    } else if (states.materialSelectedRecord.mamRecommended === true) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message:
          "This record has been recommended and so cannot be edited, please disapprove it first before trying to edit.",
        title: "Invalid Action!",
      });
      setOpenModal(true);
    } else if (states.materialSelectedRecord.mamPrepared === true) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message:
          "This record has been prepared and so cannot be edited, please disapprove it first before trying to edit.",
        title: "Invalid Action!",
      });
      setOpenModal(true);
    } else {
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      //  this destroys the update mode state
      dispatch(setGeneralValue({ expr: "TMaterialUpdateMode", value: true }));
    }
  };
  //edit button
  const editAccessPriv = () => {
    if (updatePriv === true) {
      valEdit();
    } else {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({ message: "No access privilege", title: "Access Denied!" });
      setOpenModal(true);
    }
  };

  const handleDelete = () => {
    if (states.materialSelectedRecord.length === 0) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({ message: "No record selected", title: "No record!" });
      setOpenModal(true);
    } else if (states.materialSelectedRecord.mamAuthorised === true) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "You cannot delete an authorised material",
        title: "Invalid Action!",
      });
      setOpenModal(true);
    } else {
      setIcon("question");
      setModalData(deleteModalData2);
      setOpenModal(true);
    }
  };
  // delete button
  const cancelAccessPriv = () => {
    if (updatePriv === true) {
      handleDelete();
    } else {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({ message: "No access privilege", title: "Access denied!" });
      setOpenModal(true);
    }
  };

  // approve button
  const approveAccessPriv = () => {
    if (updatePriv === true) {
      approveBtn();
    } else {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({ message: "No access privilege", title: "Access denied!" });
      setOpenModal(true);
    }
  };

  //disapprove button
  const disapproveAccessPriv = () => {
    if (updatePriv === true) {
    } else {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({ message: "No access privilege", title: "Access denied!" });
      setOpenModal(true);
    }
  };

  //submit button
  const submitAccessPriv = () => {
    if (savePriv === true) {
      // submit();
      submitMat();
    } else {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({ message: "No access privilege", title: "Access denied!" });
      setOpenModal(true);
    }
  };
  return (
    <div>
      <ModalTemplate
        icon={icon}
        cancelText={modalData.cancelText}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        open={openModal}
        okHandler={() => {
          modalData === saveModalData
            ? postData()
            : modalData === updateModalData
            ? updateData()
            : setOpenModal(false);
          modalData === deleteModalData2 ? deleteRecord() : setOpenModal(false);
          modalData.title === "Material Submission"
            ? Submit()
            : setOpenModal(false);
          modalData.title === "Retrieve Material?"
            ? retrieve()
            : setOpenModal(false);
          modalData.title === "Prepare & Recommend for Authorisation?"
            ? prepRec()
            : setOpenModal(false);
          modalData.title === "Recommend for Authorisation?"
            ? recommendMaterial()
            : setOpenModal(false);
          modalData.title === "Authorise?"
            ? authoriseMaterial()
            : setOpenModal(false);
          modalData.title === "Disapprove?"
            ? disapprove()
            : setOpenModal(false);
          modalData.title === "Prepare for Recommendation?"
            ? prepare()
            : setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
        disableCancel={icon === "question" ? false : true}
      />

      <div className="px-2 border-b-4 mb-1 ">
        <Form
          className="w-full  dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 px-2 "
          name="basic"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          size="small"
          layout="horizontal"
        >
          <div className="w-full ">
            {/* JOB */}
            <div className="">
              <Form.Item
                labelCol={{ span: 3 }}
                label={
                  <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                    {"Job"}
                  </p>
                }
                className="w-full"
              >
                <div className="flex mb-0.5 w-full">
                  <InputSelect_template
                    style={{ width: "100%" }}
                    placeHolder={states.jobState?.name}
                    handleRefresh={() => {
                      setState("jobState", {
                        id: 0,
                        name: "",
                      });
                    }}
                    selectStyle={{}}
                    options={job_data}
                    disabled={form_disable}
                    useCallFunc={true}
                    idexpr={"mjdIdpk"}
                    dataexp={"mjdJob"}
                    isOpenDropdown={states.isOpenDropdown1}
                    isLoading={states.isLoading}
                    handleFocus={(e: any) => {
                      setState("isOpenDropdown1", !states.isOpenDropdown1);
                    }}
                    handleSearch={
                      debounce((e: string) => {
                        setState("jobState", e);
                        dispatch(Fetch_Job(e) as unknown as any);
                        setState("isOpenDropdown1", true);
                      }, 500) // Adjust the debounce delay as needed
                    }
                    selectedValue={(value: any) => {
                      setState("isOpenDropdown1", false);
                      setState("jobState", {
                        id: value["mjdIdpk"],
                        name: value["mjdJob"],
                      });
                    }}
                  />
                  {/* <Select
                    disabled={form_disable}
                    value={states.jobState?.name}
                    onChange={(value: any) => {
                      setState("jobState", {
                        id: JSON.parse(value)["mjdIdpk"],
                        name: JSON.parse(value)["mjdJob"],
                      });
                    }}
                  >
                    {job_data?.map((jb: any, index: number) => {
                      return (
                        <option key={index} value={JSON.stringify(jb)}>
                          {jb["mjdJob"]}
                        </option> //mjdIdpk mjdJob
                      );
                    })}
                  </Select> */}
                  {/* <span
                    onClick={() => {
                      setRefresh(!refresh);
                      setState("jobState", { id: 0, name: "" });
                    }}
                    style={{
                      height: 22.5,
                      marginBottom: 2,
                      borderWidth: 1,
                      width: 24.9,
                      borderColor: borderTheme,
                    }}
                    className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                  >
                    <img
                      className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                      src={reset}
                      alt="reset"
                    />
                  </span> */}
                </div>
              </Form.Item>
            </div>
            {/* Material */}
            <Form className="w-full  flex" labelCol={{ span: 3 }}>
              <Form.Item
                className="w-full"
                label={
                  <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                    {"Material"}
                  </p>
                }
              >
                <div className="w-full flex justify-between space-x-2">
                  <div className="flex w-[60%]">
                    <InputSelect_template
                      useCallFunc
                      style={{ width: "100%", marginTop: 2, marginBottom: 2 }}
                      placeHolder={states.materialState?.name}
                      options={material}
                      idexpr="mtrIdpk"
                      dataexp="mtrName"
                      isLoading={states.isLoading}
                      isOpenDropdown={states.isOpenDropdown2}
                      disabled={form_disable}
                      selectedValue={(value: any) => {
                        setState("isOpenDropdown2", false);
                        setState("materialState", {
                          id: value.mtrIdpk,
                          name: value.mtrName,
                        });
                      }}
                      handleRefresh={() => {
                        setRefresh(!refresh);
                        setState("materialState", { id: 0, name: "" });
                      }}
                      handleSearch={
                        debounce((e: string) => {
                          setState("materialState", e);
                          setState("searchMaterial", e);
                          dispatch(Fetch_Materials(e) as unknown as any);
                          setState("isOpenDropdown2", true);
                        }, 500) // Adjust the debounce delay as needed
                      }
                      handleFocus={() => {
                        setState("isOpenDropdown2", !states.isOpenDropdown2);
                      }}
                    />
                    {/* <Select
                      disabled={form_disable}
                      value={states.materialState?.name}
                      placeholder={states.materialState?.name}
                      onChange={(value) => {
                        setState("materialState", {
                          id: JSON.parse(value)["mtrIdpk"],
                          name: JSON.parse(value)["mtrName"],
                        });
                      }}
                    >
                      {materials_data?.map((ma: any, index: number) => {
                        return (
                          <option key={index} value={JSON.stringify(ma)}>
                            {ma["mtrName"]}
                          </option> //mtrIdpk  //mtrName
                        );
                      })}
                    </Select> */}
                    {/* <span
                      onClick={() => {
                        setRefresh(!refresh);
                        setState("materialState", { id: 0, name: "" });
                      }}
                      style={{
                        height: 22.5,
                        marginBottom: 2,
                        borderWidth: 1,
                        width: 24.9,
                        borderColor: borderTheme,
                      }}
                      className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                    >
                      <img
                        className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                        src={reset}
                        alt="reset"
                      />
                    </span> */}
                  </div>

                  <div className="w-[40%] ">
                    <DatePicker
                      allowClear={false}
                      value={dayjs(states.dateState)}
                      disabled={form_disable}
                      onChange={(date, dateString) => {
                        setState("dateState", dateString);
                      }}
                      className="w-full"
                      format={dateFormat}
                    />
                  </div>
                </div>
              </Form.Item>
            </Form>

            {/* Quantity */}

            <Form className="w-full flex " labelCol={{ span: 12 }}>
              <Form.Item
                className="w-[25%] "
                label={
                  <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                    {"Quantity"}
                  </p>
                }
              >
                <div className="w-full flex">
                  {/* <Tooltip title="Enter the quantity here"> */}
                  <Input
                    value={states.quantityState}
                    disabled={form_disable}
                    className="w-24"
                    onChange={(e) => {
                      setState("quantityState", e.target.value);
                    }}
                  />
                  {/* </Tooltip> */}
                </div>
              </Form.Item>

              <div className="w-[30%] justify-start items-center">
                <Form.Item
                  labelCol={{ span: 7 }}
                  wrapperCol={{ span: 15 }}
                  className=""
                  label={
                    <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                      {"Unit"}
                    </p>
                  }
                >
                  <div className="w-full flex  ">
                    <InputSelect_template
                      style={{ width: "100%" }}
                      placeHolder={states.unitState?.name}
                      options={unit}
                      disabled={form_disable}
                      useCallFunc={true}
                      idexpr={"mamIdpk"}
                      dataexp={"mamQuantityUnit"}
                      isOpenDropdown={states.isOpenDropdown3}
                      isLoading={states.isLoading}
                      handleFocus={(e: any) => {
                        setState("isOpenDropdown3", !states.isOpenDropdown3);
                      }}
                      handleSearch={
                        debounce((e: string) => {
                          setState("unitState", e);
                          setState("searchUnit",e)
                          dispatch(Fetch_unit(e) as unknown as any);

                          setState("isOpenDropdown3", true);
                        }, 500) // Adjust the debounce delay as needed
                      }
                      selectedValue={(e: any) => {
                        setState("isOpenDropdown3", false);
                        setState("unitState", {
                          id: e?.["mamIdpk"],
                          name: e?.["mamQuantityUnit"],
                        });
                      }}
                    />
                    {/* <Select
                      value={states.unitState?.name}
                      disabled={form_disable}
                      onChange={(value: any) => {
                        setState("unitState", {
                          id: JSON.parse(value).mamIdpk,
                          name: JSON.parse(value).mamQuantityUnit,
                        });
                      }}
                      className="w-full overflow-hidden"
                    >
                      {unit_data?.map((ma: any, index: number) => {
                        return (
                          <option key={index} value={JSON.stringify(ma)}>
                            {ma["mamQuantityUnit"]}
                          </option>
                        );
                      })}
                    </Select> */}
                    {/* <span
                      onClick={() => {
                        setRefresh(!refresh);
                        setState("unitState", { id: 0, name: "" });
                      }}
                      style={{
                        height: 22.5,
                        marginBottom: 2,
                        borderWidth: 1,
                        width: 35,
                        borderColor: borderTheme,
                      }}
                      className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                    >
                      <img
                        className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                        src={reset}
                        alt="reset"
                      />
                    </span> */}
                  </div>
                </Form.Item>
              </div>

              <Form.Item
                className="w-[45%]"
                label={
                  <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                    {"Req. No/Code"}
                  </p>
                }
              >
                <div className="w-full flex ">
                  <Input
                    placeholder="Req. No"
                    value={states.reqNoState}
                    disabled={form_disable}
                    onChange={(e) => {
                      setState("reqNoState", e.target.value);
                    }}
                    className=""
                  />
                  <Input
                    placeholder="Code"
                    value={states.codeState}
                    disabled={form_disable}
                    onChange={(e) => {
                      setState("codeState", e.target.value);
                    }}
                    className="ml-0.5"
                  />
                </div>
              </Form.Item>
            </Form>

            {/* Unit Price */}
            <Form className="w-full flex " labelCol={{ span: 8 }}>
              <Form.Item
                className="w-[38%]"
                label={
                  <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                    {"Unit Price"}
                  </p>
                }
              >
                <div className="w-full flex">
                  <Input
                    value={states.unitPriceState1}
                    disabled={form_disable}
                    onChange={(e) => {
                      setState("unitPriceState1", e.target.value);
                    }}
                    className="mb-0.5"
                  />
                  <Input
                    readOnly={true}
                    value={states.unitPriceState2}
                    disabled={form_disable}
                    onChange={(e) => {
                      setState("unitPriceState2", e.target.value);
                    }}
                    className="ml-0.5 mb-0.5"
                  />
                </div>
              </Form.Item>
              <Form.Item
                style={{ width: "calc(27%)" }}
                labelCol={{ span: 7 }}
                className=""
                label={
                  <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                    {"Cur"}
                  </p>
                }
              >
                <div className="w-full flex">
                  <InputSelect_template
                    style={{ width: "100%" }}
                    placeHolder={states.currencyState?.name}
                    options={currency}
                    disabled={form_disable}
                    useCallFunc={true}
                    idexpr={"curIdpk"}
                    dataexp={"curSymbol"}
                    isOpenDropdown={states.isOpenDropdown4}
                    isLoading={states.isLoading}
                    handleRefresh={() => {
                      setState("currencyState", { id: 0, name: "" });
                    }}
                    handleFocus={(e: any) => {
                      setState("isOpenDropdown4", !states.isOpenDropdown4);
                    }}
                    handleSearch={
                      debounce((e: string) => {
                        setState("currencyState", e);
                        setState("searchCurrency",e)
                        dispatch(Fetch_CurrentPrice(e) as unknown as any);
                        setState("isOpenDropdown4", true);
                      }, 500) // Adjust the debounce delay as needed
                    }
                    selectedValue={(e: any) => {
                      setState("isOpenDropdown4", false);
                      setState("currencyState", {
                        id: e?.["curIdpk"],
                        value: e?.["curSymbol"],
                      });
                    }}
                  />
                  {/* <Select
                    value={states.currencyState?.name}
                    disabled={form_disable}
                    onChange={(value: any) => {
                      setState("currencyState", {
                        id: JSON.parse(value)["curIdpk"],
                        name: JSON.parse(value)["curSymbol"],
                      });
                    }}
                  >
                    {currency_data?.map((cur: any, index: number) => {
                      return (
                        <option key={index} value={JSON.stringify(cur)}>
                          {cur["curSymbol"]}
                        </option> //curIdpk curSymbol
                      );
                    })}
                  </Select> */}
                  {/* <span
                    onClick={() => {
                      setRefresh(!refresh);
                      setState("currencyState", { id: 0, name: "" });
                    }}
                    style={{
                      height: 22.5,
                      marginBottom: 2,
                      borderWidth: 1,
                      width: 34.9,
                      borderColor: borderTheme,
                    }}
                    className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                  >
                    <img
                      className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                      src={reset}
                      alt="reset"
                    />
                  </span> */}
                </div>
              </Form.Item>

              <Form.Item
                style={{ width: "calc(38%)" }}
                className=""
                labelCol={{ span: 6 }}
                label={
                  <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                    {"Tot."}
                  </p>
                }
              >
                <div className="w-full flex">
                  <Input
                    value={
                      form_disable
                        ? states.TotState ?? `${Number(0).toFixed(2)}`
                        : `${states.currencyState?.name || ""} ${
                            states.TotState ?? `${Number(0).toFixed(2)}`
                          }`
                    }
                    disabled={true}
                    onChange={(e) => {
                      setState("TotState", e.target.value);
                    }}
                  />
                </div>
              </Form.Item>
            </Form>

            {/* Remarks */}
            <Form.Item
              labelCol={{ span: 3 }}
              label={
                <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                  {"Remarks"}
                </p>
              }
            >
              <div className="w-full flex">
                <div style={{ width: "calc(95%)" }} className="">
                  <TextareaTemplate
                    defaultValue={states.remarks}
                    readonly={form_disable}
                    height={74}
                    useCallbackFunc
                    // defaultValue=""
                    setCallbackValue={(e) => {
                      setState("remarks", e);
                    }}
                  />
                </div>
                <ul
                  style={{ width: "7%" }}
                  className=" flex flex-col justify-center  mb-1 "
                >
                  {form_disable === true ? (
                    <button
                      id={"new"}
                      onClick={newAccessPriv}
                      style={{ height: "32px", width: "100%" }}
                      className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  w-full mb-0.5 ml-0.5   border-2 border-slate-200 rounded"
                    >
                      <img className="px-0.5" src={newIcon} />
                      <Tooltip
                        target="#new"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        hideOnOutsideClick={true}
                      >
                        <p className="">Create new material</p>
                      </Tooltip>
                    </button>
                  ) : states.materialSelectedRecord !== undefined ? (
                    <button
                      onClick={handleCancel}
                      style={{
                        height: "32px",
                        width: "100%",
                        borderColor: borderTheme,
                      }}
                      className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  w-full mb-0.5 ml-0.5   border-2 border-slate-200 rounded"
                    >
                      <img className="px-0.5" src={close} />
                    </button>
                  ) : form_disable === false ? (
                    <button
                      onClick={handleCancel}
                      style={{
                        height: "32",
                        width: "100%",
                        borderColor: borderTheme,
                      }}
                      className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  w-full mb-0.5 ml-0.5    border-2 border-slate-200 rounded"
                    >
                      <img className="px-0.5" src={close} />
                    </button>
                  ) : (
                    <button
                      onClick={newAccessPriv}
                      style={{
                        height: "32px",
                        width: "100%",
                        borderColor: borderTheme,
                      }}
                      className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  w-full mb-0.5 ml-0.5    border-2 border-slate-200 rounded"
                    >
                      <img className="px-0.5" src={newIcon} />
                    </button>
                  )}

                  {form_disable === false && update_state_change === false ? (
                    <button
                      id={"save"}
                      onClick={() => {
                        saveAccessPriv();
                      }}
                      style={{
                        height: "36px",
                        width: "100%",
                        borderColor: borderTheme,
                      }}
                      className=" dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  w-full ml-0.5 p-2  border-2 border-slate-200 rounded "
                    >
                      <img className="" src={save} />
                      <Tooltip
                        target="#save"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        hideOnOutsideClick={true}
                      >
                        <p className="">Save</p>
                      </Tooltip>
                    </button>
                  ) : update_state_change == true && form_disable === false ? (
                    <button
                      id="update"
                      onClick={() => {
                        setModalData(updateModalData);
                        validationForms();
                        setOpenModal(true);
                      }}
                      style={{
                        height: "36px",
                        width: "100%",
                        borderColor: borderTheme,
                      }}
                      className=" dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  w-full mb-0.5 ml-0.5  p-2 border-2 border-slate-200 rounded"
                    >
                      <img className="" src={save} />
                      <Tooltip
                        target="#update"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        hideOnOutsideClick={true}
                      >
                        <p className="">Update</p>
                      </Tooltip>
                    </button>
                  ) : (
                    <button
                      id="save"
                      // onClick={''}
                      style={{
                        height: "36px",
                        width: "100%",
                        borderColor: borderTheme,
                      }}
                      className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  w-full mb-0.5 ml-0.5   p-2 border-2 border-slate-200 rounded"
                    >
                      <img className="" src={save} />
                      <Tooltip
                        target="#save"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        hideOnOutsideClick={true}
                      >
                        <p className="">Save</p>
                      </Tooltip>
                    </button>
                  )}
                </ul>
              </div>
            </Form.Item>
          </div>
        </Form>
      </div>

      {/* Footer gridtable  */}
      <div className=""></div>

      {/* Datagrid table  */}
      <div className="flex justify-end items-center ">
        <p
          id={"form_header6"}
          className="pt-1 pl-4 w-full bg-slate-100 rounded-tl"
        >
          Material Details
        </p>
        <ul className="flex justify-evenly items-center">
          <li
            id={"refresh"}
            className="hover:cursor-pointer  border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center"
          >
            <span
              onClick={() => {
                setRefreshValue(!refreshValue);
              }}
              style={{
                height: 22.5,
                borderWidth: 1,
                width: 25.2,
                borderColor: borderTheme,
              }}
              className="  flex justify-center hover:cursor-pointer border-gray-300 rounded"
            >
              <img
                className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                src={reset}
                alt="reset"
              />
            </span>
            <Tooltip
              target="#refresh"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Refresh material</p>
            </Tooltip>
          </li>

          <li
            id="edit"
            onClick={() => {
              editAccessPriv();
            }}
            className="hover:cursor-pointer border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center"
          >
            <span
              style={{
                height: 22.5,
                borderWidth: 1,
                width: 25.2,
                borderColor: borderTheme,
              }}
              className="  flex justify-center hover:cursor-pointer border-gray-300 rounded"
            >
              <img
                className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                src={edit}
                alt="reset"
              />
            </span>
            <Tooltip
              target="#edit"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Edit material</p>
            </Tooltip>
          </li>

          <li
            id="cancel"
            className="hover:cursor-pointer  border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center"
          >
            <span
              onClick={() => {
                cancelAccessPriv();
              }}
              style={{
                height: 22.5,
                borderWidth: 1,
                width: 25.2,
                borderColor: borderTheme,
              }}
              className="  flex justify-center hover:cursor-pointer border-gray-300 rounded"
            >
              <img
                className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                src={close}
                alt="reset"
              />
            </span>
            <Tooltip
              target="#cancel"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Delete material entry</p>
            </Tooltip>
          </li>
        </ul>
      </div>

      <div className="">
        <Mini_datagrid_template
          columns={Materials_cols}
          data={miniData}
          gridheight={174}
          setState={setState}
          onChangeRecord={onChangeRecord}
          updateMode={allowUpdate}
        />
        <div className="w-full flex flex-row items-center justify-between py-0.5 bg-slate-100">
          <li id="submit" className=" flex justify-start items-center">
            <SubmitButton handleSubmit={submitAccessPriv} />
            <Tooltip
              target="#submit"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Submit material for apporval</p>
            </Tooltip>
          </li>

          <ul className="flex items-start justify-end space-x-1 pr-1 ">
            <div id="approve">
              <ApproveButton handleApprove={approveAccessPriv} />
              <Tooltip
                target="#approve"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Approve material</p>
              </Tooltip>
            </div>
            <div id="disapprove">
              <DisapproveButton handleDisapprove={disapproveAccessPriv} />
              <Tooltip
                target="#disapprove"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Disapprove material</p>
              </Tooltip>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};
// Dummy data

const data: any = [
  {
    id: 0,
    officer: true,
    workshopOfficer: true,
    supervisor: false,
    SupUpperLimit: 400000,
  },
  {
    id: 1,
    workshopOfficer: false,
    supervisor: true,
    officer: false,
    SupUpperLimit: 8,
  },
  {
    id: 2,
    workshopOfficer: false,
    supervisor: false,
    manager: true,
    MgrUpperLimit: 12,
    officer: false,
  },
];
