export const left_mini_cols = [
  {
    id: 0,
    caption: "Staff?",
    dataField: "empIsStaff",
    dataType: "",
    alignment: "left",
    width: 45,
    fixed: true,
  },
  {
    id: 1,
    caption: "Tel No",
    dataField: "empTelNo",
    dataType: "string",
    alignment: "left",
    fixed: true,
    width: 100,
  },
  {
    id: 2,
    caption: "Name",
    dataField: "empName1",
    dataType: "string",
    alignment: "left",
    fixed: true,
    width: 80,
  },
  {
    id: 3,
    caption: "SP",
    dataField: "tskTask",
    dataType: "string",
    alignment: "left",
    fixed: false,
    width: 80,
  },
  {
    id: 4,
    caption: "RETURNS",
    dataField: "tskPercentComplete",
    dataType: "number",

    alignment: "left",
    fixed: false,
    width: 80,
  },
  {
    id: 5,
    caption: "Mob No",
    dataField: "empMobNo",
    dataType: "string",
    alignment: "center",
    fixed: false,
    width: 80,
  },
  {
    id: 6,
    caption: "INTERCOM",
    dataField: "empIntercom",
    dataType: "boolean",
    alignment: "center",
    width: 80,
    fixed: false,
  },
  {
    id: 7,
    caption: "Job Title",
    dataField: "jbtShtName",
    dataType: "boolean",
    alignment: "center",
    width: 80,
    fixed: false,
  },
  {
    id: 8,
    caption: "Dept",
    dataField: "dptShtName",
    alignment: "left",
    width: 80,
    fixed: false,
  },
  {
    id: 9,
    caption: "Section",
    dataField: "sxnShtName",
    dataType: "string",
    alignment: "left",
    width: 80,
    fixed: false,
  },
  {
    id: 10,
    caption: "Loc",
    dataField: "locName",
    dataType: "string",
    alignment: "left",
    width: 80,
    fixed: false,
  },
];
