/* eslint-disable react/jsx-pascal-case */
import { forwardRef, useEffect, useRef, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Datagrid_template from "../../../../../../templates/Datagrid";
import {
  navbar_height,
  toolbar_height,
} from "../../../../../accessories/component_infos";
import { HrSetupsCustomToolbar } from "../../../../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import { attendantsMainGridColumns } from "./data/workshopAttendantsData";
import WorkshopAttendantsForm from "./widgets/workshopAttendantsForm";
import useFetch from "../../../../../../hooks/useFetch";
import { useBusyLoader } from "../../../../../../hooks/useBusyLoader";
import { WarningAlert } from "../../../../../accessories/warningAlert";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";
import {
  searchTextTypes,
  searchCriteriaTypes,
  statusTypes,
} from "../../../../../human-resource/setups/data/types/selectsTypes";
import useResizeMode from "../../../../../../hooks/useResizeMode";

export const TmsWorkshopAttendantAssignments = forwardRef(({ a }: any, ref) => {
  const formRef = useRef<any>();

  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const setupPageRoute = window.location.pathname;
  const datagridOnlySearchParam = "table-only";
  const formModeSearchParam = "form-and-table";
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const miscHeight = 7;

  const heights_out = navbar_height + news_flash_height + toolbar_height;

  const [form_height] = useState<number>(386);

  const toggle_staff_form = (): void => {
    const view = searchParams.get("view");

    view === null || view === formModeSearchParam
      ? navigate({
          pathname: setupPageRoute,
          search: createSearchParams({
            view: datagridOnlySearchParam,
          }).toString(),
        })
      : navigate({
          pathname: setupPageRoute,
          search: createSearchParams({
            view: formModeSearchParam,
          }).toString(),
        });
  };

  const [resizeMode, initialResizeMode] = useResizeMode(
    searchParams.get("view") === formModeSearchParam ||
      searchParams.get("view") === null
  );

  //Table Height
  var pageHeight = resizeMode - (navbar_height + miscHeight + 480);
  var tableHeight = initialResizeMode - (heights_out + miscHeight);

  const [refresh, setRefresh] = useState<boolean>(false);
  const [updateMode, setupdateMode] = useState<boolean>(false);
  const [staffDataReady, setStaffDataReady] = useState<boolean>(false);
  const [busyLoader, setBusyLoader] = useBusyLoader();

  // search parameter states
  const [searchText, setSearchText] = useState<searchTextTypes>({
    text: "",
    temp: "",
  });
  const [searchCriteria, setSearchCriteria] = useState<searchCriteriaTypes>({
    temp: 0,
    index: 0,
  });
  const [status, setStatus] = useState<statusTypes>({ temp: "", status: "" });

  const [selectedRecord, setSelectedRecord] = useState<any>({});
console.log("selectedRecord", selectedRecord);
  //api call for Main grid data.
  const [Maindata, dataError, dataLoading] = useFetch(
    `MaintenanceRequestPrivilege/GetGridViewForMaintenanceRequest?criteria=${searchCriteria.index}&search=${searchText.text}&status=${status.status}`,
    refresh,
    "waaIDpk"
  );
  useEffect(() => {
    dataLoading ? setBusyLoader(".") : setBusyLoader("");
  }, [dataLoading]);

  const criteria = [
    { id: 0, value: "Attendant" },
    { id: 1, value: "Block" },
  ];

  return (
    <>
      {/* main grid error */}
      {dataError && <WarningAlert />}

      <div className="h-full">
        <div className="px-2">
          <HrSetupsCustomToolbar
            searchCriteriaData={criteria}
            handleRefresh={() => {
              setRefresh(!refresh);
              setSearchText({ temp: "", text: "" });
              setSearchCriteria({ temp: 0, index: 0 });
              setStatus({ temp: "", status: "" });
            }}
            searchTextOnchange={(text) =>
              setSearchText({ temp: text, text: searchText.text })
            }
            searchCriteriaOnchange={(crit) =>
              setSearchCriteria({ temp: crit, index: searchCriteria.index })
            }
            checkOnChange={(check) =>
              setStatus({ temp: check, status: status.status })
            }
            handleFind={() => {
              setRefresh((current) => !current);
              setSearchText({ temp: searchText.temp, text: searchText.temp });
              setSearchCriteria({
                temp: searchCriteria.temp,
                index: searchCriteria.temp,
              });
              setStatus({ temp: status.temp, status: status.temp });
            }}
            handleToggle={() => formRef.current?.toggle(selectedRecord)}
            handleToggleAll={() => formRef.current?.toggleAll(selectedRecord)}
            assignmentsForm
            disableNew_edit
            withDates={false}
            toggler={toggle_staff_form}
          />
        </div>

        {searchParams.get("view") !== datagridOnlySearchParam && (
          <section style={{ height: form_height }} className=" form border-b-4">
            <WorkshopAttendantsForm
              ref={formRef}
              staffDatagrid={(status: any) => setStaffDataReady(status)}
              refresh={() => {
                setRefresh((current) => !current);
              }}
              updateMode={updateMode}
            />
          </section>
        )}

        <section className=" px-2">
          <Datagrid_template
            onRowClick={(selected) => {
              setSelectedRecord(selected);
              formRef.current?.setSelectedRecord(selected);
            }}
            gridheight={
              searchParams.get("view") === formModeSearchParam ||
              searchParams.get("view") === null
                ? pageHeight
                : tableHeight
            }
            data={Maindata}
            selectionMode={"single"}
            columns={attendantsMainGridColumns}
          />
        </section>
      </div>
    </>
  );
});
