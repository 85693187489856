import Countdown from "antd/es/statistic/Countdown";
import React from "react";

interface props {
  duration: number;
  onchange: (time: number) => void;
  onFinish: () => void;
}
export const ExamsCountDown = React.memo(
  ({ duration, onchange, onFinish }: props) => {
    return (
      <>
        <Countdown
          title="Time Left"
          valueStyle={
            duration > 1000 * 60 * 2 ? { color: "green" } : { color: "red" }
          }
          onChange={(e: any) => onchange(e)}
          value={Date.now() + duration}
          onFinish={onFinish}
        />
      </>
    );
  }
);
