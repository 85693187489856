
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
// import type { SelectProps } from 'antd';
import { api_url, navbar_height } from '../../../accessories/component_infos';
import { CIA_toolbar } from '../../../accessories/custom-toolbars/cia_toolbar';
import { Footer_datagrid_template } from '../../data/template/footer_datagrid';
import { mission_vision_cols } from './data/mission_cols_data';
import { Mission_Vision_Details_Form } from './widgets/Mission_vision_form_details';
import { MissionVisionToolbar } from './widgets/mission_vision_toolbar';
import { searchTextTypes, statusTypes } from '../../../human-resource/setups/data/types/selectsTypes';
import { HrSetupsCustomToolbar } from '../../../accessories/custom-toolbars/hrSetupsCustomtoolbar';
import { useDispatch } from 'react-redux';
import { setGeneralValue } from '../../../../features/generalSlice';
import Datagrid_template from '../../../../templates/Datagrid';
import useFetch from '../../../../hooks/useFetch';





interface props {
    state_change: boolean;
}



export const Mission_Vision = ({ state_change }: props) => {
    const dispatch = useDispatch();

    const news_flash_height = useSelector((state: RootState) => state.general.newsflashHeight);
    // const width = window.innerWidth/2
    // const fixedWidth = 'calc(50% - 8px)';

    const [formheight, set_form_height] = useState<number>(0);
    const formRef = useRef<any>(null);
    const [fullPage, setfullPage] = useState(window.innerHeight - (navbar_height + news_flash_height + 30));
    const pageHeight = window.innerHeight - (navbar_height + news_flash_height + formheight + 20);
    const borderTheme = useSelector((state: RootState) => state.general.cssBorderClass)

    const childRef = useRef<any>();
    useEffect(() => {

        set_form_height((formRef.current?.clientHeight) + 20)
        console.log(formRef)
    }, [])

    /**
  **************************************
  ************************************** 
  ***********Api Integrations***********
  **************************************
  **************************************
  */
    const activeStatus = useSelector((state: RootState) => state.general.activeStatus);

    //Fetch data for table
    // const [tableData, setTableData] = useState<any>([]);
    const [refreshValue, setRefreshValue] = useState(false);
    const [status, setStatus] = useState<boolean | string>('');
    const [search, setSearch] = useState<searchTextTypes>({ temp: '', text: '' })
    const [tableData, dataErr, dataLoading] = useFetch(`PfmMissionVision/GetAllPfmMissionVision?search=${search.text}&active=${status}`)


    // async function fetchTableData() {
    //     try {
    //         // setLoading(true); // initially set loading state
    //         // get data
    //         const res = await axios.get(`${api_url}/api/PfmMissionVision/GetAllPfmMissionVision?search=${search.text}&active=${status}`, {
    //             headers: {
    //                 'Content-Type': ['application/json', 'text/plain', 'charset=utf-8'],
    //                 // Accept : ['text/plain','application/json','charset=utf-8'],
    //             }
    //         })

    //         //console.log(res.data)
    //         setTableData(res.data.reverse()); // set data
    //     } catch (error: any) {
    //         console.log(error);
    //         // set error
    //         // setError(error);
    //     } finally {
    //         // setLoading(false); //loading state to false
    //     }
    // }
    // const [tableData,error,loading] = useFetch('api/PfmMissionVision/GetAllPfmMissionVision');

    const [toggleTable, setToggleTable] = useState(false);

    useEffect(() => {
        // console.log(route);
        // fetchTableData();
    }, [!refreshValue]);

    const [selectedRow, setSelectedRow] = useState({});

    const rowDbClick = (e: any) => {

    }
    return (
        <div className='w-full h-full dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base mt-1'>
            {/* toggles between the taskForm and the footer grid table vertically */}
            <div className=' px-2'>
                <HrSetupsCustomToolbar

                    withDates={false}
                    toggler={() => {
                        setToggleTable(!toggleTable);
                        console.log(toggleTable)
                    }
                    }
                    handleUpdate={() => { childRef.current.checkUpdate() }}
                    searchTextOnchange={(e) => { setSearch({ text: search.text, temp: e }) }}
                    handleFind={() => {
                        setSearch({ temp: search.temp, text: search.temp });
                        setStatus(activeStatus);
                        setRefreshValue((current) => !current)
                    }}
                    handleRefresh={() => {
                        setSearch({ temp: '', text: '' });
                        setStatus('');

                    }}
                    handleNew={() => {

                    }}

                />
            </div>

            {!toggleTable &&
                <div ref={formRef} className='w-full px-2'>
                    <p id={"form_header"} style={{ borderColor: borderTheme }} className='pt-1 border-[1px] border-b-0 px-2 pl-4 w-full bg-slate-100 rounded-tl'>Mission/Vision Details</p>
                    <div className="w-full flex justify-between gap-x-4 border-b-4 mb-1 pb-1">

                        {/* Internal toggler : expands the right taskUpdate horizontally */}

                        <div className='w-full mb-1  ' >
                            <Mission_Vision_Details_Form ref={childRef} toggleRefresh={(e: any) => { setRefreshValue(!refreshValue) }} />
                        </div>


                    </div>
                </div>}


            {/* Footer gridtable  */}
            <div style={{ height: '' }} className='px-2  dark:bg-slate-900 dark:text-darkModeSkin-base'>

                <Datagrid_template
                    gridheight={!toggleTable ? pageHeight : fullPage}
                    columns={mission_vision_cols}
                    data={tableData}
                    rowDoubleClicked={(e: any) => {
                        dispatch(setGeneralValue({ expr: 'updateMode', value: true }));

                        //disable forms
                        dispatch(setGeneralValue({ expr: 'formDisabled', value: false }));
                    }}

                    onRowClick={(e: any) => {
                        if (childRef.current) {
                          childRef.current.singleClick(e);
                        }
                        setSelectedRow(e);
                      }}

                    // onRowClick={(e: any) => {
                    //     dispatch(setGeneralValue({ expr: 'updateMode', value: false }));

                    //     //disable forms
                    //     dispatch(setGeneralValue({ expr: 'formDisabled', value: true }));
                    // }}
                />

                {/* <Footer_datagrid_template gridheight={toggleTable === true ?  820 : 450} columns={mission_vision_cols} data={tableData} 
                doubleClick={(e:any)=>{
                    //disable Update Mode
                    dispatch(setGeneralValue({expr: 'updateMode' ,value : true}));

                    //disable forms
                    dispatch(setGeneralValue({expr: 'formDisabled' ,value : false}));
                    
                    // childRef.current!.doubleClick()!
                }}
                callBack={(e:any)=>{
                    //disable Update Mode
                    dispatch(setGeneralValue({expr: 'updateMode' ,value : false}));

                    //disable forms
                    dispatch(setGeneralValue({expr: 'formDisabled' ,value : true}));

                // childRef.current!.singleClick(e)!
                }} /> */}
            </div>

        </div>

    )
}





// const reload = ()=>{
//     return(
//         <img className='w-2/6' src={save} />
//     )
// }