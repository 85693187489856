export const transport_staff_datagrid_allcolumns = [
  {
    id: 0,
    caption: "ID",
    dataField: "empIdpk",
    dataType: "number",
    width: 85,
    fixed: true,
    visible: false,
  },
  {
    id: 2,
    caption: "STAFF No.",
    dataField: "empStaffNo",
    dataType: "string",
    width: 85,
    fixed: true,
    visible: true,
  },
  {
    id: 4,
    caption: "STAFF NAME",
    dataField: "empName",
    dataType: "string",
    width: 200,
    fixed: true,
    visible: true,
  },
  {
    id: 6,
    caption: "DEPT",
    dataField: "dptName",
    dataType: "string",
    width: 120,
    fixed: true,
    visible: true,
  },
  {
    id: 8,
    caption: "SECTION",
    dataField: "sxnName",
    dataType: "string",
    width: 120,
    fixed: true,
    visible: true,
  },
  {
    id: 16,
    caption: "ACTIVE?",
    dataField: "empActive",
    // dataField: "empIsStaff",
    dataType: "boolean",
    width: 75,
    fixed: true,
    visible: true,
  },
  {
    id: 10,
    caption: "UNIT",
    dataField: "untName",
    dataType: "string",
    width: 120,
    fixed: false,
    visible: true,
  },
  {
    id: 12,
    caption: "JOB TITLE",
    dataField: "jbtName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 22,
    caption: "DoB",
    dataField: "empDoB",
    dataType: "datetime",
    width: 140,
    fixed: false,
    visible: true,
  },
  {
    id: 24,
    caption: "AGE",
    dataField: "age",
    dataType: "",
    width: 75,
    fixed: false,
    visible: true,
  },
  {
    id: 30,
    caption: "RETIRE DATE",
    dataField: "retireDate",
    dataType: "datetime",
    width: 140,
    fixed: false,
    visible: true,
  },
  {
    id: 32,
    caption: "TENURE(YRS)",
    dataField: "serviceLenght",
    dataType: "",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 18,
    caption: "LOCATION",
    dataField: "locName",
    dataType: "string",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 56,
    caption: "OFFICE LOCATION",
    dataField: "",
    dataType: "string",
    width: 95,
    fixed: false,
    visible: true,
  },
  {
    id: 58,
    caption: "ROOM No",
    dataField: "romName",
    dataType: "string",
    width: 87,
    fixed: false,
    visible: true,
  },
  {
    id: 78,
    caption: "ASSIGNMENT STATUS",
    dataField: "astName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 82,
    caption: "ASSIGNMENT CATEGORY",
    dataField: "ascName",
    dataType: "string",
    width: 150,
    fixed: false,
    visible: true,
  },
  {
    id: 90,
    caption: "PERSON TYPE",
    dataField: "pstName",
    dataType: "string",
    width: 150,
    fixed: false,
    visible: true,
  },
  {
    id: 94,
    caption: "GENDER",
    dataField: "gndName",
    dataType: "string",
    width: 78,
    fixed: false,
    visible: true,
  },
  {
    id: 98,
    caption: "ADDRESS",
    dataField: "empAddress",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 100,
    caption: "INTERCOM",
    dataField: "empIntercom",
    dataType: "string",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 102,
    caption: "MOBILE No",
    dataField: "empMobNo",
    dataType: "number",
    width: 96,
    fixed: false,
    visible: true,
  },
  {
    id: 104,
    caption: "DIRECT TELEPHONE",
    dataField: "empTelNo",
    dataType: "number",
    width: 120,
    fixed: false,
    visible: true,
  },
  {
    id: 106,
    caption: "EMAIL(OFFICIAL)",
    dataField: "empOfficialEmail",
    dataType: "string",
    width: 150,
    fixed: false,
    visible: true,
  },
  {
    id: 48,
    caption: "SUPERVISOR",
    dataField: "empSupName",
    dataType: "string",
    width: 150,
    fixed: false,
    visible: true,
  },
  {
    id: 130,
    caption: "DIR?",
    dataField: "empIsDirector",
    dataType: "boolean",
    width: 62,
    fixed: false,
    visible: true,
  },
  {
    id: 128,
    caption: "MGR?",
    dataField: "empIsManager",
    dataType: "boolean",
    width: 62,
    fixed: false,
    visible: true,
  },
  {
    id: 126,
    caption: "SUP?",
    dataField: "empIsSupervisor",
    dataType: "boolean",
    width: 62,
    fixed: false,
    visible: true,
  },
  {
    id: 132,
    caption: "SNR STAFF?",
    dataField: "empIsSeniorStaff",
    dataType: "boolean",
    width: 62,
    fixed: false,
    visible: true,
  },
  {
    id: 134,
    caption: "JNR STAFF?",
    dataField: "empIsJuniorStaff",
    dataType: "boolean",
    width: 62,
    fixed: false,
    visible: true,
  },
  {
    id: 138,
    caption: "MGMT? STAFF",
    dataField: "empIsManagementStaff",
    dataType: "boolean",
    width: 74,
    fixed: false,
    visible: true,
  },
  {
    id: 160,
    caption: "REMARKS",
    dataField: "empRmks",
    dataType: "string",
    width: 4000,
    fixed: false,
    visible: true,
  },
];
