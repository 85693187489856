export const assetsSetups_serviceItems_columns = [
  {
    id: 0,
    caption: "SERVICE ITEM ID",
    dataField: "msiIdpk",
    dataType: "number",
    width: 200,
    fixed: true,
    visible: false,
  },
  {
    id: 1,
    caption: "SERVICE ITEM ",
    dataField: "msiName",
    dataType: "string",
    width: 200,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "SHORT NAME",
    dataField: "msiShtName",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 6,
    caption: "MAINT. CLASSIFICATION",
    dataField: "mtcName",
    dataType: "string",
    width: 200,
    fixed: false,
    visible: true,
  },
  {
    id: 7,
    caption: "ORDER",
    dataField: "msiOrder",
    dataType: "string",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 9,
    caption: "ACTIVE?",
    dataField: "msiActive",
    dataType: "boolean",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 10,
    caption: "REMARKS",
    dataField: "msiRmks",
    dataType: "string",
    width: 5000,
    fixed: false,
    visible: true,
  },
];
