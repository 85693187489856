/* eslint-disable react/jsx-pascal-case */
import {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";
import { Form, Image, DatePicker } from "antd";
import reset from "../../../../../../../assets/reset.png";

import Checkbox from "antd/es/checkbox/Checkbox";
import {
  SaveButton,
  NewButton,
  UpdateButton,
  Cancel,
} from "../../../../../../accessories/buttons";
// import { SelectsTemplate } from "../../../../../../../templates/select";
import useFetch from "../../../../../../../hooks/useFetch";
import {
  saveModalData,
  updateModalData,
} from "../../../../../../accessories/component_infos";
import { InputsTemplate } from "../../../../../../../templates/input";
import dayjs, { Dayjs } from "dayjs";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import {
  task_footer_table_selected,
  task_footer_update,
  task_form_disable,
} from "../../../../../../../features/Task&Todo/Task/TaskDetails";
import { TextareaTemplate } from "../../../../../../../templates/textarea";
import { setGeneralValue } from "../../../../../../../features/generalSlice";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import { GetDataFunc } from "../../../../../../../functions/get";
import {
  dispensedBy_table,
  vehicle_capture_reg_no,
} from "../data/vehicle_reg_data";
import invalidImage from "../../../../../../../assets/none.jpg";
import { DropdownGrid_template } from "../../../../../../../templates/DropdownGrid";
import { CheckboxTemlate } from "../../../../../../../templates/checkbox";
import { useCrudFunc } from "../../../../../../../functions/crud";
import { DateTemplate } from "../../../../../../../templates/date";
import InputSelect_template from "../../../../../../../templates/InputSelect";
import { debounce } from "lodash";
import {
  Fetch_CostCenter,
  Fetch_Department,
  Fetch_Product,
  Fetch_Sections,
  Fetch_ServiceProvider,
  Fetch_ServiceStation,
  Fetch_UnitOfMeasure,
} from "../../../../../../../features/apiCalls/BacklogFuelEntries";
import React from "react";
import { useAxiosService } from "../../../../../../../general/requestConfig";
import { useDebounce } from "../../../../../../Safety/elogger/widgets/utils";

interface prop {
  refreshState?: () => void;
  rowData?: any;
  setOuterSelect?: any;
  setSelected_Record?: (val: any) => void;
  a?: any;
}
export interface LookupResponseType {
  serviceProviders: ServiceProvider[];
  serviceStations: ServiceStation[];
  products: any[];
  unitsOfMeasure: any[];
  dispensedBy: EdBy[];
  recievedBy: EdBy[];
  authorisedBy: EdBy[];
  department: Department[];
  sections: Section[];
  costCenters: CostCenter[];
}

export interface EdBy {
  empIdpk: number;
  empStaffNo: string;
  empName: string;
  dptShtName: string;
  sxnShtName: string;
  locShtName: string;
  jbtShtName: string;
}
export interface CostCenter {
  fcctIdpk: number;
  fcctName: string;
  fcctCode: string;
}

export interface Department {
  dptIdpk: number;
  dptShtName: string;
}

export interface Section {
  sxnIdpk: number;
  sxnShtName: string;
}

export interface ServiceProvider {
  spdIDpk: number;
  spdName: string;
}

export interface ServiceStation {
  sstIdpk: number;
  sstName: string;
  twnName: string;
}

export const Fuel_Backlog_Details_Form = forwardRef(
  ({ refreshState, rowData }: prop, ref) => {
    const fetcher = useAxiosService();

    const options = [];
    for (let i = 1; i < 36; i++) {
      options.push({
        label: i.toString(36) + i,
        value: i.toString(36) + i,
      });
    }

    const dateFormat = " DD MMM YYYY HH: mm:ss";
    const [ShowImages, setShowImages] = useState<any>(false);

    const [formData, setFormData] = useState<any>({
      selectedId: 0,
      requestDate: dayjs().format("YYYY-MM-DD"),
      vehicleRegNo: { name: "", id: "" },
      serviceProvider: { name: "", id: "" },
      serviceStation: { name: "", id: "" },
      loadImage: { name: "", id: "" },
      product: { name: "", id: "" },
      quantityRequested: 0,
      quantityAuthorized: 0,
      quantityDispensed: 0,
      uom: { name: "", id: "" },
      unitPrice: 0,
      dumpAmount: 0,
      receiptNumber: "",
      dispensedBy: { name: "", id: "" },
      authorisedBy: { name: "", id: "" },
      receivedBy: { name: "", id: "" },
      department: { name: "", id: "" },
      section: { name: "", id: "" },
      costCenter: { name: "", id: "" },
      previousOdomReading: "",
      currentOdomReading: "",
      active: false,
      updateMode: false,
      disabled: true,
      loadImageActive: false,
      remarks: "",
      refresh: false,

      /**
       * By Icanhazip
       */
      isOpenDropdown1: false,
      isOpenDropdown2: false,
      isOpenDropdown3: false,
      isOpenDropdown4: false,
      isOpenDropdown5: false,
      isOpenDropdown6: false,
      isOpenDropdown7: false,

      serviceProviderRefresh: false,
      serviceStationRefresh: false,
      productRefresh: false,
      uomRefresh: false,
      departmentRefresh: false,
      sectionRefresh: false,
      costCenterRefresh: false,

      isOpenDropdownKey1: "isOpenDropdown1",
      isOpenDropdownKey2: "isOpenDropdown2",
      isOpenDropdownKey3: "isOpenDropdown3",
      isOpenDropdownKey4: "isOpenDropdown4",
      isOpenDropdownKey5: "isOpenDropdown5",
      isOpenDropdownKey6: "isOpenDropdown6",
      isOpenDropdownKey7: "isOpenDropdown7",
    });
    const fmgAccAmount = formData.unitPrice * formData.quantityRequested;

    useEffect(() => {
      if (!rowData) return;
      setFormData((state) => ({
        ...state,
        requestDate: rowData?.fmgDate ?? dayjs().format(),
        vehicleRegNo: { name: rowData.vdtRegNo, id: rowData.vdtIDpk },
        serviceProvider: { name: rowData.spdName, id: rowData.spdIDpk },
        serviceStation: { name: rowData.sstName, id: rowData.sstIDpk },
        loadImage: { name: "", id: "" },
        product: { name: rowData.prdName, id: rowData.prdIDpk },
        quantityRequested: rowData.fmgQuantityRequested,
        quantityAuthorized: rowData.fmgQuantityAuthorised,
        quantityDispensed: rowData.fmgQuantityDispensed,
        uom: { name: rowData.untOfMeasure, id: rowData.untOfMeasureID },
        unitPrice:
          Number(rowData.fmgAmount) / Number(rowData.fmgQuantityRequested),
        // unitPrice:
        //   Number(rowData.fmgAmount) +
        //   (Number(rowData.fmgAmount) / Number(rowData.fmgQuantityRequested)) *
        //     rowData.fmgQuantityRequested,
        // unit price
        /**
 *     Number(formData.dumpAmount) /
                            Number(formData.quantityRequested)
 */
        // dump amount
        /**
         * Number(formData.dumpAmount) +
            Number(formData.unitPrice) * Number(formData.quantityRequested)
         */
        dumpAmount: rowData.fmgAmount,

        receiptNumber: rowData.fmgReceiptNo,
        dispensedBy: {
          name: rowData.dispenserEmpName,
          id: rowData.dispenserEmpID,
        },
        authorisedBy: {
          name: rowData.authoriserEmpName,
          id: rowData.authoriserEmpID,
        },
        receivedBy: { name: rowData.driverName, id: rowData.driverEmpID },
        department: {
          name: rowData.vehAssignDeptName,
          id: rowData.vehAssignDeptID,
        },
        section: { name: rowData.vehAssignSxnName, id: rowData.vehAssignSxnID },
        costCenter: {
          name: rowData.costCenterDeptName,
          id: rowData.costCenterDeptID,
        },
        previousOdomReading: rowData.fmgPreviousOdometerReading,
        currentOdomReading: rowData.fmgCurrentOdometerReading,
        active: rowData.fmgActive,
      }));
    }, [rowData]);
    const ServiceProviderData = useSelector(
      (state: RootState) => state.BacklogFuelEntries.service_provider
    );
    const ServiceStationData = useSelector(
      (state: RootState) => state.BacklogFuelEntries.service_station
    );
    const ProductData = useSelector(
      (state: RootState) => state.BacklogFuelEntries.product
    );
    const UnitOfMeasureData = useSelector(
      (state: RootState) => state.BacklogFuelEntries.unit_of_measure
    );
    const DepartmentData = useSelector(
      (state: RootState) => state.BacklogFuelEntries.department
    );
    const SectionData = useSelector(
      (state: RootState) => state.BacklogFuelEntries.sections
    );
    const CostCenterData = useSelector(
      (state: RootState) => state.BacklogFuelEntries.cost_centers
    );

    const setState = (key: string, value: any) => {
      setFormData((prev) => ({ ...prev, [key]: value }));
    };
    const [posting, updating] = useCrudFunc();
    const [lookup_data, setLookup] = React.useState<LookupResponseType>();
    const [serviceStation, setServiceStation] = React.useState<any>();
    const [products, setProducts] = React.useState<any>();
    const [section, setSection] = React.useState<any>();
    const [costCenters, setCostCenters] = React.useState<any>();
    const [loading, setLoading] = React.useState<boolean>();

    //debounced values
    const debouncedStationValue = useDebounce(
      formData.serviceStation.name,
      2000
    );
    const debouncedProductValue = useDebounce(formData.product.name, 2000);
    const debouncedSectionValue = useDebounce(formData.section.name, 2000);

    const [Submittable, setSubmittable] = React.useState<{
      serviceProvider: number;
      serviceStation: number;
      product: number;
    }>({
      serviceProvider: 1,
      serviceStation: 1,
      product: 1,
    });

    //Fetch Service Station
    React.useEffect(() => {
      if (!formData.serviceProvider.name) return;
      fetcher({
        url: `AsmTmsCaptureOldFuelRecords/GetAllServiceStations`,
        method: "GET",
        params: {
          pagenumber: 1,
          pagesize: 20,
          SpdIDpk: formData.serviceProvider?.id,
          search: debouncedStationValue,
        },
      }).then((res) => setServiceStation(res));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.serviceProvider.id, debouncedStationValue]);

    // Fetch all main lookups
    React.useEffect(() => {
      (() => {
        setLoading(true);
        fetcher({
          url: `AsmTmsCaptureOldFuelRecords/GetLookUps`,
          params: {
            VasStartDate: "2001-02-02",
            SpdIDpk: 1,
            SpdIDProd: 1,
            DptIDSection: 1,
            DptIDCost: 1,
            SxnIDCost: 1,
            pagesize: 20,
            pagenumber: 1,
          },
          method: "GET",
        }).then((res) => {
          setLookup(res);
        });
        setLoading(false);
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Fetch products
    React.useEffect(() => {
      if (!formData.serviceProvider.id) return;
      fetcher({
        url: `AsmTmsCaptureOldFuelRecords/GetAllProducts`,
        method: `GET`,
        params: {
          SpdIDpk: formData.serviceProvider.id,
          search: debouncedProductValue,
          pagesize: 20,
          pagenumber: 1,
        },
      }).then((res) => {
        setProducts(res);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedProductValue, formData.serviceProvider.id]);

    // Fetch sections
    React.useEffect(() => {
      if (!formData.department.id) return;
      fetcher({
        url: `AsmTmsCaptureOldFuelRecords/GetAllSections`,
        method: `GET`,
        params: {
          DptIDSection: formData.department.id,
          search: debouncedSectionValue,
          pagesize: 20,
          pagenumber: 1,
        },
      }).then((res) => {
        setSection(res);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.department.id, debouncedSectionValue]);

    // Fetch cost centers
    React.useEffect(() => {
      if (!formData.department.id || !formData.section.id) return;
      fetcher({
        url: `AsmTmsCaptureOldFuelRecords/GetAllCostCenters`,
        method: `GET`,
        params: {
          DptIDCost: formData.department.id,
          SxnIDCost: formData.section.id,
          search: formData.costCenter.name,
          pagesize: 20,
          pagenumber: 1,
        },
      }).then((res) => {
        setCostCenters(res);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.costCenter.name, formData.department.id, formData.section.id]);
    const [regNoData] = useFetch(
      "AsmTmsVehicleSimCardAssignment/AsmTmsVehicleSimCardAssignmentGetAllVehicleRegNos"
    );
    // const [serviceProviderData, serviceProviderError, serviceProviderLoading] =
    //   useFetch("AsmTmsCaptureOldFuelRecords/GetAllServiceProviders");
    // const [serviceStationsData, setServiceStations] = useState<any>([]);
    // // products data only work with provider data of id 1.
    // const [productsData] = useFetch(
    //   // `AsmTmsCaptureOldFuelRecords/GetAllProducts?SpdIDpk=${formData.serviceProvider.id}`
    //   `FuelManagement/dumpAllProducts`
    // );
    const [loadImg] = useFetch(
      `AssetTamVehicleDetail/GetImagesAssetTamVehicleDetails?vdtIDpk=${formData.selectedId}&vimActive=true`
    );
    const [recipientsData] = useFetch(
      "AsmTmsCaptureOldFuelRecords/GetAllRecipients"
    );
    const [authorisersData] = useFetch(
      "AsmTmsCaptureOldFuelRecords/GetAllAuthorisers"
    );
    const [dispensorsData] = useFetch(
      "AsmTmsCaptureOldFuelRecords/GetAllDispensors"
    );

    // const [unitPrice] = useFetch(
    //   `AsmTmsCaptureOldFuelRecords/GetUnitPrice?SpdIDpk=${formData.serviceProvider.id}&PprIDpK=${formData.product.id}`
    // );
    // let Unit_Price_Value = unitPrice?.map(({ pprUnitPrice, funIDpk }: any) => ({
    //   pprUnitPrice,
    //   funIDpk,
    // }));

    // const [departmentData] = useFetch(
    //   "AsmTmsCaptureOldFuelRecords/GetAllDepartments"
    // );
    // const [sectionData, setSectionData] = useState<any>([]);
    // const [costCenterData, setCostCenterData] = useState<any>([]);
    // const [uomData] = useFetch(
    //   "AsmTmsCaptureOldFuelRecords/GetAllFuelUnitsOfMeasure"
    // );

    useImperativeHandle(ref, () => ({
      singleClick: (e: any) => {
        if (e !== undefined) {
          dispatch(setGeneralValue({ expr: "selectedFieldData", value: e }));
          dispatch(setGeneralValue({ expr: "updateMode", value: false }));
          dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
          dispatch(task_footer_table_selected(e));

          setFormData({
            ...formData,
            selectedId: e.fmgIDpk,
            requestDate: e.fmgDate,
            vehicleRegNo: { name: e.vdtRegNo, id: e.vdtIDpk },
            serviceProvider: { name: e.spdName, id: e.spdIDpk },
            serviceStation: { name: e.sstName, id: e.sstIDpk },
            product: { name: e.prdName, id: e.prdIDpk },
            quantityRequested: e.fmgQuantityRequested,
            quantityAuthorized: e.fmgQuantityAuthorised,
            quantityDispensed: e.fmgQuantityDispensed,
            uom: { name: e.untOfMeasure, id: e.untOfMeasureID },
            unitPrice: e.unitPrice,
            dumpAmount: Number(e.fmgAmount),
            receiptNumber: e.fmgReceiptNo,
            dispensedBy: { name: e.dispenserEmpName, id: e.dispenserEmpID },
            authorisedBy: { name: e.authoriserEmpName, id: e.authoriserEmpID },
            receivedBy: { name: e.driverName, id: e.driverEmpID },
            department: { name: e.vehAssignDeptName, id: e.vehAssignDeptID },
            section: { name: e.vehAssignSxnName, id: e.vehAssignSxnID },
            costCenter: { name: e.cctName, id: e.fmgCostCenterIDfk },
            previousOdomReading: e.fmgPreviousOdometerReading,
            currentOdomReading: e.fmgCurrentOdometerReading,
            active: e.fmgActive,
            remarks: e.fmgRmks,
            updateMode: false,
            disabled: true,
          });
        }
      },
      doubleClick: () => {
        editAccessPriv();
      },
    }));

    const fetchData = async (route: string, type: string) => {
      try {
        // get data
        const res = await GetDataFunc(`${route}`);
        // type === "serviceStations"
        //   ? setServiceStations(res.data.reverse().slice(0, 100))
        //   : type === "sections"
        //   ? setSectionData(res.data.reverse().slice(0, 100))
        //   : setCostCenterData(res.data.reverse().slice(0, 100));
      } catch (error: any) {
      } finally {
      }
    };

    const [modalInfo, setModalInfo] = useState<any>({
      icon: "question",
      data: saveModalData,
      disableCancel: false,
      openModal: false,
    });

    const clearFields = () => {
      setFormData({
        ...formData,
        selectedId: "",
        requestDate: dayjs().format("YYYY-MM-DD"),
        vehicleRegNo: { name: "", id: "" },
        serviceProvider: { name: "", id: "" },
        serviceStation: { name: "", id: "" },
        product: { name: "", id: "" },
        quantityRequested: "",
        quantityAuthorized: "",
        quantityDispensed: "",
        uom: { name: "", id: "" },
        unitPrice: "",
        dumpAmount: "",
        receiptNumber: "",
        dispensedBy: { name: "", id: "" },
        authorisedBy: { name: "", id: "" },
        receivedBy: { name: "", id: "" },
        department: { name: "", id: "" },
        section: { name: "", id: "" },
        costCenter: { name: "", id: "" },
        previousOdomReading: "",
        currentOdomReading: "",
        active: true,
        updateMode: false,
        disabled: false,
        loadImage: false,
        remarks: "",
      });
    };

    const updateState = (stateName: string, value: any) => {
      setFormData((currentState: any) => ({
        ...currentState,
        [stateName]: value,
      }));
    };

    const disablex = useSelector(
      (state: RootState) => state.general.formDisabled
    );
    const updateMode = useSelector(
      (state: RootState) => state.general.updateMode
    );
    const borderTheme = useSelector(
      (state: RootState) => state.general.cssBorderClass
    );
    const dispatch = useDispatch();

    const validationForms = (method: "post" | "update") => {
      if (formData.vehicleRegNo.name === "") {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please select vehicle registration number",
          },
          disableCancel: true,
          openModal: true,
        });
      } else if (
        // Unit_Price_Value[0]?.pprUnitPrice === "" ||
        // Unit_Price_Value[0]?.pprUnitPrice === undefined
        (formData.unitPrice === "" || formData.unitPrice === undefined) &&
        !rowData
      ) {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please make sure Unit Price is given",
          },
          disableCancel: true,
          openModal: true,
        });
      } else if (formData.serviceProvider.name === "") {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please select service Provider Name",
          },
          disableCancel: true,
          openModal: true,
        });
      } else if (formData.serviceStation.name === "") {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please select vehicle service Station ",
          },
          disableCancel: true,
          openModal: true,
        });
      } else if (formData.product.name === "") {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please select product name",
          },
          disableCancel: true,
          openModal: true,
        });
      } else if ((formData.uom.id || formData.uom.name) === "" && !rowData) {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please provide Quantity UoM",
          },
          disableCancel: true,
          openModal: true,
        });
      } else if (formData.receiptNumber === "" && !rowData) {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please provide Receipt number",
          },
          disableCancel: true,
          openModal: true,
        });
      } else if (formData.dispensedBy.name === "") {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please select Dispensed By",
          },
          disableCancel: true,
          openModal: true,
        });
      } else if (formData.authorisedBy.name === "") {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please select Authorised By name",
          },
          disableCancel: true,
          openModal: true,
        });
      } else if (formData.receivedBy.name === "") {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please select received by name",
          },
          disableCancel: true,
          openModal: true,
        });
      } else if (
        (formData.department.id === "" || formData.department.id === 0) &&
        !rowData
      ) {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please select a department",
          },
          disableCancel: true,
          openModal: true,
        });
      } else if (formData.previousOdomReading === "" && !rowData) {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please provide Pre. Odom Reading ",
          },
          disableCancel: true,
          openModal: true,
        });
      } else if (formData.currentOdomReading === "" && !rowData) {
        setModalInfo({
          icon: "warning",
          data: {
            title: "Required field",
            message: "Please provide current Odom Reading",
          },
          disableCancel: true,
          openModal: true,
        });
      } else {
        if (method === "post") {
          setModalInfo({
            icon: "question",
            data: saveModalData,
            disableCancel: false,
            openModal: true,
          });
        } else {
          setModalInfo({
            icon: "question",
            data: updateModalData,
            disableCancel: false,
            openModal: true,
          });
        }
      }
    };

    const saveData = async () => {
      await posting(
        `AsmTmsCaptureOldFuelRecords/CreateAsmTmsCaptureOldFuelRecord`,
        {
          fmgDate: formData.requestDate,
          fmgDateAuthorised: dayjs().format("YYYY-MM-DDTHH:mm:ss[Z]"),
          fmgDateDispensed: dayjs().format("YYYY-MM-DDTHH:mm:ss[Z]"),
          fmgVehicleDetailIDfk: formData.vehicleRegNo.id,
          fmgDriverIDfk: formData.receivedBy.id,
          fmgServiceProviderIDfk: formData.serviceProvider.id,
          fmgServiceStationIDfk: formData.serviceStation.id,
          fmgProductIDfk: formData.product.id,
          fmgAuthoriserEmpIDfk: formData.authorisedBy.id,
          fmgDispenserEmpIDfk: formData.dispensedBy.id,
          // fmgFuelUnitIDfk: Unit_Price_Value[0]?.funIDpk,
          fmgFuelUnitIDfk: formData?.uom?.id,
          fmgAmount:
            Number(formData.dumpAmount) +
            Number(formData.unitPrice) * Number(formData.quantityRequested),
          fmgQuantityRequested: formData.quantityRequested,
          fmgQuantityAuthorised: formData.quantityAuthorized,
          fmgQuantityDispensed: formData.quantityDispensed,
          fmgCurrentOdometerReading: formData.currentOdomReading,
          fmgPreviousOdometerReading: formData.previousOdomReading,
          fmgReceiptNo: formData.receiptNumber,
          fmgVehicleAssignmentIDfk: 1,
          // fmgVehicleAssignmentIDfk: formData.department.id,
          fmgAuthorised: true,
          fmgRmks: formData.remarks,
          formCode: "string",
          fmgActive: formData.active,
        },
        `Created the backlog details successfully`
      )
        .then((response) => {
          setModalInfo({
            icon: "success",
            data: {
              title: "Success",
              message: "Record saved successfully",
              okText: "Ok",
              cancelText: "Cancel",
            },
            disableCancel: true,
            openModal: true,
          });

          refreshState && refreshState();
        })
        .catch((err) => {
          setModalInfo({
            icon: "error",
            data: {
              title: "Unexpected error!",
              message:
                "An error occurred whilst executing the action. Please contact your Systems Administrator for assistance",
              okText: "Ok",
              cancelText: "Cancel",
            },
            disableCancel: true,
            openModal: true,
          });
        });
    };

    const updateData = async () => {
      updateState("confirmState", {
        state: false,
        title: "",
        message: "",
        event: () => {},
      });

      await updating(
        `AsmTmsCaptureOldFuelRecords/UpdateAsmTmsCaptureOldFuelRecords/${formData.selectedId}`,
        {
          fmgDate: formData.requestDate,
          fmgDateAuthorised: dayjs().format("YYYY-MM-DDTHH:mm:ss[Z]"),
          fmgDateDispensed: dayjs().format("YYYY-MM-DDTHH:mm:ss[Z]"),
          fmgVehicleDetailIDfk: formData.vehicleRegNo.id,
          fmgDriverIDfk: formData.receivedBy.id,
          fmgServiceProviderIDfk: formData.serviceProvider.id,
          fmgServiceStationIDfk: formData.serviceStation.id,
          fmgProductIDfk: formData.product.id,
          fmgAuthoriserEmpIDfk: formData.authorisedBy.id,
          fmgDispenserEmpIDfk: formData.dispensedBy.id,
          fmgPurchaseModeIDfk: 2,
          fmgCardIDfk: 1,
          fmgFuelUnitIDfk: formData?.uom?.id,
          fmgAmount:
            Number(formData.dumpAmount) +
            Number(formData.unitPrice) * Number(formData.quantityRequested),
          fmgBalance: 0,
          fmgQuantityRequested: formData.quantityRequested,
          fmgQuantityAuthorised: formData.quantityAuthorized,
          fmgQuantityDispensed: formData.quantityDispensed,
          fmgCurrentOdometerReading: formData.currentOdomReading,
          fmgPreviousOdometerReading: formData.previousOdomReading,
          fmgReceiptNo: formData.receiptNumber,
          // fmgVehicleAssignmentIDfk: formData.department.id,
          fmgVehicleAssignmentIDfk: 1,
          fmgAuthorised: true,
          fmgRmks: formData.remarks,
          formCode: "string",
          fmgActive: formData.active,
        },
        `Updated backlog details successfully`
      )
        .then((response) => {
          setModalInfo({
            icon: "success",
            data: {
              title: "Success",
              message: "Record updated successfully",
              okText: "Ok",
              cancelText: "Cancel",
            },
            disableCancel: true,
            openModal: true,
          });
          dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
          dispatch(setGeneralValue({ expr: "updateMode", value: false }));
          setFormData((state) => ({
            ...state,
            updateMode: false,
            disabled: true,
          }));
          refreshState && refreshState();
        })
        .catch((err) => {
          setModalInfo({
            icon: "error",
            data: {
              title: "Unexpected error!",
              message:
                "An error occurred whilst executing the action. Please contact your Systems Administrator for assistance",
              okText: "Ok",
              cancelText: "Cancel",
            },
            disableCancel: true,
            openModal: true,
          });
        });
    };

    const handleInputChange = (field: String, value: any) => {
      // Update the common value and propagate it to other fields
      setFormData({
        ...formData,

        quantityAuthorized: value,
        quantityRequested: value,
        quantityDispensed: value,
      });
    };

    const unitInputChange = (field: string, value: any) => {
      setFormData({
        ...formData,
        [field]: value,
      });
    };

    const [privtype, setPrivType] = useState<string>("save");
    const [savePriv] = useFormPriviledge(privtype);

    const newAccesPriv = () => {
      setPrivType("save");
      if (savePriv === true) {
        setFormData({ ...formData, disabled: false });
        dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
        dispatch(setGeneralValue({ expr: "updateMode", value: false }));
        clearFields();
      } else {
        setModalInfo({
          icon: "warning",
          data: {
            message: `No Access Privilege`,
            title: "Access Denied!",
            okText: "Ok",
            cancelText: "Cancel",
          },
          disableCancel: true,
          openModal: true,
        });
      }
    };

    const updateAccessPriv = () => {
      setPrivType("post");
      if (savePriv === true) {
        validationForms("update");
      } else {
        setModalInfo({
          icon: "warning",
          data: {
            message: `No Access Privilege`,
            title: "Access Denied!",
            okText: "Ok",
            cancelText: "Cancel",
          },
          disableCancel: true,
          openModal: true,
        });
      }
    };

    const saveAccessPriv = () => {
      setPrivType("save");

      if (savePriv === true) {
        validationForms("post");
      } else {
        setModalInfo({
          icon: "warning",
          data: {
            message: `No Access Privilege`,
            title: "Access Denied!",
            okText: "Ok",
            cancelText: "Cancel",
          },
          disableCancel: true,
          openModal: true,
        });
      }
    };

    const editAccessPriv = () => {
      setPrivType("update");

      if (savePriv === true) {
        dispatch(task_footer_update(true));
        dispatch(setGeneralValue({ expr: "updateMode", value: true }));
        setFormData({
          ...formData,
          updateMode: true,
          disabled: false,
        });
      } else {
        setModalInfo({
          icon: "warning",
          data: {
            message: `No Access Privilege`,
            title: "Access Denied!",
            okText: "Ok",
            cancelText: "Cancel",
          },
          disableCancel: true,
          openModal: true,
        });
      }
    };

    useEffect(() => {
      if (!disablex) {
        dispatch(task_footer_table_selected({}));

        dispatch(task_footer_update(false));
        // setFormData({ ...formData, disabled: false });
        clearFields();
        dispatch(setGeneralValue({ expr: "formDisabled", value: false })); //disable form
        // dispatch(setGeneralValue({ expr: "updateMode", value: false })); //disable update mode
      } else {
        dispatch(task_form_disable(true));
        dispatch(task_footer_update(false));
        setFormData({
          ...formData,
          updateMode: false,
          disabled: true,
        });
      }
      if (updateMode) {
        dispatch(task_footer_update(true));
        setFormData({
          ...formData,
          updateMode: true,
          disabled: false,
        });
      }
    }, [disablex, updateMode]);
    useEffect(() => {}, [updateMode]);

    return (
      <div
        style={{ width: "100%", height: "100%", borderColor: borderTheme }}
        className="  border-[1px] border-t-0 dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base  pr-2"
      >
        <ModalTemplate
          disableCancel={modalInfo.disableCancel}
          icon={modalInfo.icon}
          title={modalInfo.data.title}
          okText={modalInfo.data.okText}
          message={modalInfo.data.message}
          cancelText={modalInfo.data.cancelText}
          open={modalInfo.openModal}
          okHandler={() => {
            modalInfo.data.title === "Update Record"
              ? updateData()
              : modalInfo.data.title === "Save Record"
              ? saveData()
              : setModalInfo({ ...modalInfo, openModal: false });
          }}
          cancelHandler={() => {
            setModalInfo({ ...modalInfo, openModal: false });
          }}
        />{" "}
        <div
          style={{ maxHeight: "160px", minHeight: "160px", height: "160px" }}
          className="items-start justify-start "
        >
          <Form
            className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 pl-2 "
            name="basic"
            labelCol={{ span: 6 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            size="small"
            layout="horizontal"
          >
            <div style={{ width: "1400px" }} className=" flex ">
              <div style={{ width: "600px" }} className="">
                {" "}
                <Form.Item
                  label={
                    <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                      {"Request Date"}
                    </p>
                  }
                  labelCol={{ span: 7 }}
                >
                  <div className="w-full flex flex-row justify-between  ">
                    <DateTemplate
                      format={dateFormat}
                      // defaultDateValue={}
                      disabled={formData.disabled}
                      datePlaceholder="Request Date"
                      selectedDate={
                        formData.requestDate
                          ? dayjs(formData.requestDate).isValid()
                            ? dayjs(formData.requestDate)
                            : dayjs()
                          : dayjs()
                      }
                      changeDate={(e: Dayjs) => {
                        // setFormData({ ...formData, requestDate: e });
                        setState(
                          "requestDate",
                          e.format("YYYY-MM-DDTHH:mm:ss[Z]")
                        );
                      }}
                    />

                    <span className="h-full w-4 ml-1 flex items-center justify-center hover:cursor-pointer hover"></span>
                  </div>
                </Form.Item>
                <Form.Item style={{ width: "100%", marginBottom: 2.5 }}>
                  <Form.Item
                    name="issueBy"
                    style={{
                      display: "inline-block",
                      marginLeft: "82px",
                      width: "100%",
                    }}
                  >
                    <div className="flex flex-row items-center">
                      <div className="">
                        <DropdownGrid_template
                          labelCol={25}
                          KeyExpr="vdtIdpk"
                          label={"Vehicle Reg No"}
                          cusBtnStyle={true}
                          dropdownBtnStyles={{ width: 348 }}
                          isHide={true}
                          defaultValue={formData.vehicleRegNo?.name}
                          disabled={formData.disabled}
                          columns={vehicle_capture_reg_no}
                          gridData={regNoData}
                          onRowClick={(e: any) => {
                            updateState("vehicleRegNo", {
                              id: e.vdtIdpk,
                              name: e.vdtRegNo,
                            });
                          }}
                        />
                      </div>

                      <span
                        onClick={() => {
                          setFormData({
                            ...formData,
                            vehicleRegNo: { name: "", id: 0 },
                          });
                          updateState("refresh", !formData.refresh);
                        }}
                        style={{
                          borderColor: borderTheme,
                          height: 22.5,
                          marginBottom: 0.6,
                          borderWidth: 1,
                          width: 25.2,
                        }}
                        className=" ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                      >
                        <img
                          className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                          src={reset}
                          alt="reset"
                        />
                      </span>
                    </div>
                  </Form.Item>
                </Form.Item>
                <InputSelect_template
                  useCallFunc
                  label="Service Provider"
                  isOpenDropdown={formData.isOpenDropdown1}
                  handleFocus={(e: any) => {
                    updateState(`isOpenDropdown1`, !formData.isOpenDropdown1);
                  }}
                  style={{ width: "83.5%", marginLeft: "8.3%" }}
                  options={
                    formData.serviceProvider?.name?.length > 0
                      ? ServiceProviderData
                      : lookup_data?.serviceProviders
                  }
                  handleRefresh={() => {
                    updateState("serviceProvider"!, "");
                    updateState(
                      "serviceProviderRefresh",
                      !formData.serviceProviderRefresh
                    );
                  }}
                  handleSearch={debounce((e) => {
                    setState(`serviceProvider`!, { id: 0, name: e });
                    setState("isLoading", true);
                    dispatch(Fetch_ServiceProvider(e) as unknown as any);
                    setState(`isOpenDropdown1`, true);
                  }, 500)}
                  dataexp="spdName"
                  idexpr="spdIDpk"
                  selectedValue={(e: any) => {
                    console.log("INFORMATION: ", e);
                    setSubmittable((state) => ({
                      ...state,
                      serviceProvider: e.spdIDpk,
                    }));
                    setState("serviceProvider", {
                      name: e.spdName,
                      id: e.spdIDpk,
                    });
                  }}
                  placeHolder={formData.serviceProvider.name}
                  disabled={formData.disabled}
                />
                <InputSelect_template
                  label="Service Station"
                  style={{ width: "83.5%", marginLeft: "8.3%" }}
                  isOpenDropdown={formData.isOpenDropdown2}
                  handleFocus={(e: any) => {
                    setState(`isOpenDropdown2`, !formData.isOpenDropdown2);
                  }}
                  options={serviceStation}
                  // options={
                  //   formData.serviceStation?.name?.length > 0
                  //     ? ServiceStationData
                  //     : serviceStation
                  // }
                  dataexp="sstName"
                  idexpr="sstIdpk"
                  selectedValue={(e: any) => {
                    setSubmittable((state) => ({
                      ...state,
                      serviceStation: e.sstIdpk,
                    }));
                    setState("serviceStation", {
                      name: e.sstName,
                      id: e.sstIdpk,
                    });
                  }}
                  handleRefresh={() => {
                    setState("serviceStation"!, "");
                    setState(
                      "serviceStationRefresh",
                      !formData.serviceStationRefresh
                    );
                  }}
                  handleSearch={debounce((e: string) => {
                    setState(`serviceStation`!, e);
                    setState("isLoading", true);
                    // dispatch(
                    //   Fetch_ServiceStation({
                    //     search: e,
                    //     SpdIDpk: Submittable.serviceProvider,
                    //   }) as unknown as any
                    // );

                    updateState(`isOpenDropdown2`, !formData.isOpenDropdown2);
                  }, 500)}
                  placeHolder={formData.serviceStation.name}
                  useCallFunc={true}
                  disabled={formData.disabled}
                />
                <InputSelect_template
                  label="Product"
                  isOpenDropdown={formData.isOpenDropdown3}
                  handleFocus={(e: any) => {
                    updateState(`isOpenDropdown3`, !formData.isOpenDropdown3);
                  }}
                  style={{ width: "83.5%", marginLeft: "8.3%" }}
                  options={products}
                  // options={
                  //   formData.product?.name === "" ? products : ProductData
                  // }
                  // options={ProductData}
                  handleSearch={debounce((e: string) => {
                    updateState(`product`!, e);
                    // dispatch(
                    //   Fetch_Product({
                    //     search: e,
                    //     SpdIDProd: Submittable.serviceProvider,
                    //   }) as unknown as any
                    // );

                    updateState(`isOpenDropdown3`, !formData.isOpenDropdown3);
                  }, 500)}
                  dataexp="prdName"
                  idexpr="prdIdpk"
                  selectedValue={(e) => {
                    updateState("product", { name: e.prdName, id: e.prdIdpk });
                  }}
                  placeHolder={formData.product.name}
                  useCallFunc={true}
                  disabled={formData.disabled}
                />
                <ul className=" flex flex-col">
                  <div className="  w-full  justify-between ">
                    <li
                      style={{
                        width: "calc(600px - 20px)",
                        marginLeft: "84px",
                      }}
                      className=" flex items-center flex-row "
                    >
                      <p className=" mr-2 text-xs">Quantity/UoM : </p>
                      <div className="flex justify-evenly h-5">
                        <InputsTemplate
                          inputStyle={{ width: 72 }}
                          parentClassName="mr-1"
                          numberOnly={true}
                          span
                          defaultValue={formData.quantityRequested}
                          useCallbackFunc
                          disabledStatus={formData.disabled}
                          orderOnchange={(e: any) => {
                            setFormData({
                              ...formData,
                              quantityAuthorized: e,
                              quantityRequested: e,
                              quantityDispensed: e,
                            });
                          }}
                        />

                        <InputsTemplate
                          inputStyle={{ width: 72 }}
                          parentClassName="mr-1"
                          numberOnly={true}
                          span
                          defaultValue={formData.quantityAuthorized}
                          useCallbackFunc
                          disabledStatus={formData.disabled}
                          orderOnchange={(e: any) => {
                            setFormData({
                              ...formData,
                              quantityAuthorized: e,
                              quantityDispensed: e,
                            });
                          }}
                        />

                        <InputsTemplate
                          inputStyle={{ width: 72 }}
                          parentClassName="mr-1"
                          numberOnly={true}
                          span
                          defaultValue={formData.quantityDispensed}
                          useCallbackFunc
                          disabledStatus={formData.disabled}
                          orderOnchange={(e: any) => {
                            setFormData({
                              ...formData,
                              quantityDispensed: e,
                            });
                          }}
                        />

                        <InputSelect_template
                          style={{ width: "149px" }}
                          options={
                            formData.uom?.name === ""
                              ? lookup_data?.unitsOfMeasure
                              : UnitOfMeasureData
                          }
                          handleFocus={(e: any) => {
                            updateState(
                              `isOpenDropdown4`,
                              !formData.isOpenDropdown4
                            );
                          }}
                          handleRefresh={() => {
                            updateState("uom"!, { name: "", id: "" });
                            updateState("uomRefresh", !formData.uomRefresh);
                          }}
                          isOpenDropdown={formData.isOpenDropdown4}
                          handleSearch={debounce((e) => {
                            updateState(`uom`!, e);
                            updateState("isLoading", true);
                            dispatch(Fetch_UnitOfMeasure(e) as unknown as any);
                            updateState(
                              `isOpenDropdown4`,

                              // !formData.isOpenDropdown4
                              true
                            );
                          }, 500)}
                          dataexp="funShtName"
                          idexpr="funIdpk"
                          selectedValue={(e: any) => {
                            updateState("uom", {
                              name: e.funShtName,
                              id: e.funIdpk,
                            });
                            updateState("isOpenDropdown4", false);
                          }}
                          placeHolder={formData.uom.name}
                          // placeHolder={`placeholder`}
                          useCallFunc={true}
                          disabled={formData.disabled}
                        />
                      </div>
                    </li>

                    <li
                      style={{
                        width: "calc(600px - 20px)",
                        marginLeft: "34px",
                        marginTop: "5px",
                      }}
                      className="flex items-center flex-row "
                    >
                      <p className="mr-2 text-xs ">
                        Unit Price/Total Amount :{" "}
                      </p>
                      <div className="flex justify-evenly mt-0.5 ">
                        <InputsTemplate
                          disabledStatus={formData.disabled}
                          inputStyle={{ width: 72 }}
                          parentClassName="mr-1"
                          numberOnly={true}
                          // readOnly={true}
                          span
                          defaultValue={
                            Number(formData.dumpAmount) /
                            Number(formData.quantityRequested)
                          }
                          useCallbackFunc
                          orderOnchange={(e: any) => {
                            unitInputChange("unitPrice", e);
                          }}
                        />
                        <InputsTemplate
                          inputStyle={{ width: 72 }}
                          parentClassName="mr-1"
                          numberOnly={true}
                          readOnly={true}
                          span
                          defaultValue={
                            Number(formData.dumpAmount) +
                              Number(formData.unitPrice) *
                                formData.quantityRequested ||
                            Number(formData.dumpAmount)
                          }
                          useCallbackFunc
                          disabledStatus={formData.disabled}
                          orderOnchange={(e: any) => {
                            setFormData({
                              ...formData,
                              dumpAmount: e,
                            });
                          }}
                        />

                        <InputsTemplate
                          inputStyle={{ width: 197, height: 24, marginTop: 1 }}
                          parentClassName="mr-1"
                          span
                          defaultValue={formData.receiptNumber}
                          useCallbackFunc
                          disabledStatus={formData.disabled}
                          orderOnchange={(e: any) => {
                            setFormData({
                              ...formData,
                              receiptNumber: e,
                            });
                          }}
                        />
                      </div>
                    </li>
                  </div>
                </ul>
                {/* Remarks */}
              </div>

              <div style={{ width: "600px" }} className="">
                <Form.Item
                  style={{
                    width: "100%",
                    marginLeft: "85px",
                    marginBottom: 3,
                  }}
                >
                  <Form.Item
                    name="DispensedBy"
                    style={{ display: "inline-block", width: "100%" }}
                  >
                    <div className="flex flex-row items-center  ">
                      <div className="">
                        <DropdownGrid_template
                          labelCol={25}
                          KeyExpr="empStaffNo"
                          label={"Dispensed By"}
                          cusBtnStyle={true}
                          dropdownBtnStyles={{ width: 273 }}
                          isHide={true}
                          defaultValue={formData.dispensedBy.name}
                          disabled={formData.disabled}
                          columns={dispensedBy_table}
                          gridData={dispensorsData}
                          // gridData={lookup_data?.dispensedBy}
                          onRowClick={(e: any) => {
                            updateState("dispensedBy", {
                              id: e.empIdpk,
                              name: e.empName,
                            });
                          }}
                        />
                      </div>

                      <span
                        onClick={() => {
                          setFormData({
                            ...formData,
                            dispensedBy: { name: "", id: 0 },
                          });
                          updateState("refresh", !formData.refresh);
                        }}
                        style={{
                          borderColor: borderTheme,
                          height: 22.5,
                          marginBottom: 1,
                          borderWidth: 1,
                          width: 25.2,
                        }}
                        className=" ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                      >
                        <img
                          className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                          src={reset}
                          alt="reset"
                        />
                      </span>
                    </div>
                  </Form.Item>
                </Form.Item>

                <Form.Item
                  style={{
                    width: "100%",
                    marginLeft: "82px",
                    marginBottom: 2,
                  }}
                >
                  <Form.Item
                    name="DispensedBy"
                    style={{ display: "inline-block", width: "100%" }}
                  >
                    <div className="flex flex-row items-center">
                      <div className="">
                        <DropdownGrid_template
                          labelCol={25}
                          label={"Authorised By"}
                          KeyExpr="empStaffNo"
                          cusBtnStyle={true}
                          dropdownBtnStyles={{ width: 273 }}
                          isHide={true}
                          defaultValue={formData.authorisedBy.name}
                          disabled={formData.disabled}
                          columns={dispensedBy_table}
                          gridData={authorisersData}
                          // gridData={lookup_data?.authorisedBy}
                          onRowClick={(e: any) => {
                            updateState("authorisedBy", {
                              id: e.empIdpk,
                              name: e.empName,
                            });
                          }}
                        />
                      </div>

                      <span
                        onClick={() => {
                          setFormData({
                            ...formData,
                            authorisedBy: { name: "", id: 0 },
                          });
                          updateState("refresh", !formData.refresh);
                        }}
                        style={{
                          borderColor: borderTheme,
                          height: 22.5,
                          marginBottom: 0.6,
                          borderWidth: 1,
                          width: 25.2,
                        }}
                        className=" ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                      >
                        <img
                          className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                          src={reset}
                          alt="reset"
                        />
                      </span>
                    </div>
                  </Form.Item>
                </Form.Item>

                <Form.Item
                  style={{
                    width: "100%",
                    marginLeft: "85px",
                    marginBottom: 2.5,
                  }}
                >
                  <Form.Item
                    name="DispensedBy"
                    style={{ display: "inline-block", width: "100%" }}
                  >
                    <div className="flex flex-row items-center">
                      <div className="ml-[8px]">
                        <DropdownGrid_template
                          labelCol={25}
                          KeyExpr="empStaffNo"
                          label={"Received By"}
                          cusBtnStyle={true}
                          dropdownBtnStyles={{ width: 273 }}
                          isHide={true}
                          defaultValue={formData.receivedBy.name}
                          disabled={formData.disabled}
                          columns={dispensedBy_table}
                          gridData={recipientsData}
                          // gridData={lookup_data?.recievedBy}
                          onRowClick={(e: any) => {
                            updateState("receivedBy", {
                              id: e.empIdpk,
                              name: e.empName,
                            });
                          }}
                        />
                      </div>

                      <span
                        onClick={() => {
                          setFormData({
                            ...formData,
                            receivedBy: { name: "", id: 0 },
                          });
                          updateState("refresh", !formData.refresh);
                        }}
                        style={{
                          borderColor: borderTheme,
                          height: 22.5,
                          marginBottom: 0.6,
                          borderWidth: 1,
                          width: 25.2,
                        }}
                        className=" ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                      >
                        <img
                          className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                          src={reset}
                          alt="reset"
                        />
                      </span>
                    </div>
                  </Form.Item>
                </Form.Item>

                <ul className=" flex flex-col ">
                  <div className="  w-full  justify-between mb-0.5">
                    <li className=" flex items-center flex-row w-[472px] ">
                      <Form.Item
                        className="w-full"
                        labelCol={{ span: 8 }}
                        label={
                          <p className=" text-xs ">{`Dept/Section/Cost Center`}</p>
                        }
                      >
                        <div
                          style={{ width: "100%" }}
                          className="flex space-x-1"
                        >
                          <div className="w-full flex pl-3">
                            <InputSelect_template
                              isOpenDropdown={formData.isOpenDropdown5}
                              style={{ width: "100%" }}
                              options={
                                formData.department?.name?.length > 0
                                  ? DepartmentData
                                  : lookup_data?.department
                              }
                              handleFocus={(e: any) => {
                                updateState(
                                  `isOpenDropdown5`,
                                  !formData.isOpenDropdown5
                                );
                              }}
                              handleSearch={debounce((e) => {
                                updateState(`department`!, e);
                                updateState("isLoading", true);
                                dispatch(Fetch_Department(e) as unknown as any);

                                updateState(
                                  `isOpenDropdown5`,
                                  !formData.isOpenDropdown5
                                );
                              }, 500)}
                              dataexp="dptShtName"
                              idexpr="dptIdpk"
                              disabledCustomWidth={true}
                              cusWidth={`100%`}
                              selectedValue={(e) => {
                                updateState("department", {
                                  name:
                                    e.dptShtName.length > 6
                                      ? `${e.dptShtName.slice(0, 6)}...`
                                      : e.dptShtName,
                                  id: e.dptIdpk,
                                });
                              }}
                              useCallFunc={true}
                              disabled={formData.disabled}
                              placeHolder={
                                formData.department.name?.length > 6
                                  ? `${formData.department.name.slice(0, 6)}...`
                                  : formData.department.name
                              }
                              span={true}
                            />
                          </div>
                          <InputSelect_template
                            isOpenDropdown={formData.isOpenDropdown6}
                            style={{ width: "100%", marginRight: "2px" }}
                            options={
                              formData.section?.name?.length > 0
                                ? SectionData
                                : section
                            }
                            handleFocus={(e: any) => {
                              updateState(
                                `isOpenDropdown6`,
                                !formData.isOpenDropdown6
                              );
                            }}
                            handleSearch={debounce((e) => {
                              updateState(`section`!, e);
                              updateState("isLoading", true);
                              dispatch(
                                Fetch_Sections({
                                  search: e,
                                  DptIDSection: formData.department.id,
                                }) as unknown as any
                              );

                              updateState(
                                `isOpenDropdown6`,
                                !formData.isOpenDropdown6
                              );
                            }, 500)}
                            dataexp="sxnShtName"
                            fullWidth={true}
                            span={true}
                            disabledCustomWidth={true}
                            cusWidth={`100%`}
                            idexpr="sxnIdpk"
                            selectedValue={(e) => {
                              updateState("section", {
                                name:
                                  e.sxnShtName.length > 5
                                    ? `${e.sxnShtName.slice(0, 5)}...`
                                    : e.sxnShtName,
                                id: e.sxnIdpk,
                              });
                            }}
                            useCallFunc={true}
                            disabled={formData.disabled}
                            placeHolder={
                              formData.section.name?.length > 5
                                ? `${formData.section.name.slice(0, 5)}...`
                                : formData.section.name
                            }
                          />
                          <InputSelect_template
                            style={{ width: "100%", marginRight: "2px" }}
                            isOpenDropdown={formData.isOpenDropdown7}
                            options={
                              formData.costCenter?.name?.length > 0
                                ? CostCenterData
                                : costCenters
                            }
                            dataexp="fcctName"
                            idexpr="fcctIdpk"
                            selectedValue={(e) => {
                              updateState("costCenter", {
                                name:
                                  e.fcctName.length > 5
                                    ? `${e.fcctName.slice(0, 5)}...`
                                    : e.fcctName,
                                id: e.fcctIdpk,
                              });
                            }}
                            placeHolder={
                              formData.costCenter.name?.length > 5
                                ? `${formData.costCenter.name.slice(0, 5)}...`
                                : formData.costCenter.name
                            }
                            handleFocus={(e: any) => {
                              updateState(
                                `isOpenDropdown7`,
                                !formData.isOpenDropdown7
                              );
                            }}
                            handleSearch={debounce((e) => {
                              updateState(`costCenter`!, e);
                              updateState("isLoading", true);
                              dispatch(
                                Fetch_CostCenter({
                                  DptIDCost: formData.department.id,
                                  search: e,
                                  SxnIDCost: formData.costCenter.id,
                                }) as unknown as any
                              );

                              updateState(
                                `isOpenDropdown7`,
                                !formData.isOpenDropdown7
                              );
                            }, 500)}
                            useCallFunc={true}
                            disabled={formData.disabled}
                            handleRefresh={() => {
                              updateState("costCenter"!, { name: "", id: "" });
                              updateState(
                                "costCenterRefresh",
                                !formData.costCenterRefresh
                              );

                              updateState("department", { name: "", id: "" });
                              updateState("section", { name: "", id: "" });

                              updateState(
                                "sectionRefresh",
                                !formData.sectionRefresh
                              );
                              updateState(
                                "departmentRefresh",
                                !formData.departmentRefresh
                              );
                            }}
                          />
                        </div>
                      </Form.Item>
                    </li>
                    <Form.Item
                      label={
                        <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                          {"Prev. Odom Reading"}
                        </p>
                      }
                      labelCol={{ span: 7 }}
                      // wrapperCol={{ span: 15 }}
                    >
                      <div className="flex flex-row justify-between">
                        <InputsTemplate
                          inputStyle={{ width: "73.5%", marginBottom: 0.5 }}
                          parentClassName="w-full"
                          span
                          defaultValue={formData.previousOdomReading}
                          useCallbackFunc
                          disabledStatus={formData.disabled}
                          orderOnchange={(e: any) => {
                            setFormData({
                              ...formData,
                              previousOdomReading: e,
                            });
                          }}
                        />
                      </div>
                    </Form.Item>
                    <Form.Item
                      label={
                        <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                          {"Current Odom Reading"}
                        </p>
                      }
                      labelCol={{ span: 7 }}
                      // wrapperCol={{ span: 15 }}
                    >
                      <div className="flex flex-row justify-between ">
                        <InputsTemplate
                          inputStyle={{ width: "73.5%" }}
                          parentClassName="w-full"
                          span
                          defaultValue={formData.currentOdomReading}
                          useCallbackFunc
                          disabledStatus={formData.disabled}
                          orderOnchange={(e: any) => {
                            setFormData({
                              ...formData,
                              currentOdomReading: e,
                            });
                          }}
                        />
                      </div>
                    </Form.Item>
                    {/* <Fuel_Backlog_Right_Input /> */}
                    <li
                      style={{ width: "calc(600px - 20px)" }}
                      className=" flex items-center flex-row mt-0.5"
                    >
                      <div className="w-full flex items-center">
                        <CheckboxTemlate
                          style={{ borderColor: borderTheme, width: "100%" }}
                          label="Active? "
                          withBorders={true}
                          customDisabled={formData.disabled}
                          defaultValue={formData.active}
                          labelCol={7}
                          useCallbackFunc
                          setCheckboxOnchange={(e) => {
                            setFormData({
                              ...formData,
                              active: e,
                            });
                          }}
                          extraWidget={
                            <span className="flex w-full px-2 items-center -mt-5 ml-8">
                              <p className="ml-2 text-xs text-blue-700 font-semibold">{`${
                                Number(formData.currentOdomReading) -
                                Number(formData.previousOdomReading)
                              } km`}</p>{" "}
                            </span>
                          }
                        />
                      </div>
                    </li>
                  </div>
                </ul>
              </div>

              <div className=" w-full ">
                <div
                  style={{
                    border: "1px solid #ccc",
                    width: "160px",
                    borderColor: borderTheme,
                  }}
                  className="p-1 flex items-center justify-center"
                >
                  <Image
                    onError={(e) => {
                      e.currentTarget.src = invalidImage;
                    }}
                    src={
                      !ShowImages
                        ? invalidImage
                        : `data:image/png;base64, ${loadImg[0]?.vimImage}`
                    }
                    alt="No Image data"
                    className="w-full text-xs "
                  />
                </div>
                <li className="w-full flex flex-row items-center mt-1">
                  <Checkbox
                    style={{ borderColor: borderTheme }}
                    className=" py-0.5 px-1 rounded border-[1px]"
                    checked={formData.loadImageActive}
                    value={formData.loadImageActive}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        loadImageActive: e.target.checked,
                        //
                      });
                      setShowImages(e);
                    }}
                  />
                  <p
                    style={{ maxWidth: "500px" }}
                    className="w-full text-xs ml-1"
                  >
                    Load Vehicle Image{" "}
                  </p>
                </li>
              </div>
            </div>

            <div className="w-full mt-0.5 flex flex-col ">
              <Form.Item
                // className="ml-16"
                style={{ marginLeft: "4.5rem" }}
              >
                <>
                  <TextareaTemplate
                    // readonly={formData.disabled}
                    disabled={formData.disabled}
                    height={70}
                    label={"Remarks"}
                    MarginLeft={45}
                    useCallbackFunc={true}
                    defaultValue={formData.remarks}
                    setCallbackValue={(val) => {
                      updateState("remarks", val);
                    }}
                  />
                </>
              </Form.Item>

              <div className=" flex justify-end w-full pb-4">
                <ul className="flex justify-end">
                  {formData.updateMode ? (
                    <UpdateButton
                      disableButton={formData.disabled}
                      useCallbackFunc={true}
                      handleUpdate={() => {
                        console.log("ROW DATA: \n", rowData);
                        console.log("ALL STATES: \n", formData);
                        updateAccessPriv();
                      }}
                    />
                  ) : (
                    <SaveButton
                      disableButton={formData.disabled}
                      useCallbackFunc={true}
                      handleSave={() => {
                        saveAccessPriv();
                      }}
                    />
                  )}
                  {formData.disabled ? (
                    <NewButton
                      useCallbackFunc={true}
                      new_button_toggler={() => {
                        newAccesPriv();
                      }}
                    />
                  ) : (
                    <Cancel
                      useCallbackFunc
                      cancel_button_toggler={() => {
                        setFormData({
                          ...formData,
                          updateMode: false,
                          disabled: true,
                        });
                        dispatch(task_form_disable(true));
                        dispatch(
                          setGeneralValue({ expr: "formDisabled", value: true })
                        );
                        dispatch(
                          setGeneralValue({ expr: "updateMode", value: false })
                        );
                        dispatch(task_footer_update(false));
                      }}
                    />
                  )}
                </ul>
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  }
);
