/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useEffect, useRef, useState } from "react";
import Datagrid_template from "../../../../../../../templates/Datagrid";
import { selectsTypes } from "../../../../../../human-resource/setups/data/types/selectsTypes";
import { SelectsTemplate } from "../../../../../../../templates/select";
import {
  footer_Model_columns,
  left_Model_columns,
  right_Model_columns,
} from "../data/maintenanceI&CData";
import { SaveButton } from "../../../../../../accessories/buttons";
import { Form } from "antd";
import { navbar_height } from "../../../../../../accessories/component_infos";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import useFetch from "../../../../../../../hooks/useFetch";
import { PostDataFunc  } from "../../../../../../../functions/post";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { useCrudFunc } from "../../../../../../../functions/crud";

interface props {
  searchCriteriax?: any;
}

type statesType = {
  mainT_state: { id: number; name: string };
  itemClass_state: { id: number; name: string };
  leftSelectedRecord: any;
  gridRefresh: boolean;
  rightSelectedRecord: any;
  // modal states
  setModalState: {
    icon: "warning" | "success" | "question";
    open: boolean;
    title: string;
    message: string;
  };
};

export const Model_IA = ({ searchCriteriax }: props) => {
  const [empId, userId, userInfo] = useAccountInfo();
  const upperForm = useRef<any>(null);
  const [posting , updating ]= useCrudFunc();
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const [formheight, set_form_height] = useState<number>(0);
  let full_height =
    window.innerHeight - (formheight + news_flash_height + navbar_height + 40);

  useEffect(() => {
    set_form_height(upperForm.current?.clientHeight + 30);
  }, []);

  // states for the model
  const [states, setStates] = useState<statesType>({
    mainT_state: { id: 0, name: "" },
    itemClass_state: { id: 0, name: "" },
    leftSelectedRecord: {},
    gridRefresh: false,
    rightSelectedRecord: {},
    // modal states
    setModalState: {
      icon: "warning",
      open: false,
      title: "",
      message: "",
    },
  });

  const {
    mainT_state,
    itemClass_state,
    leftSelectedRecord,
    rightSelectedRecord,
    gridRefresh,
  } = states;
  const { open, title, message, icon } = states.setModalState;

  // function to updateStates

  const updateStates = useCallback((key: string, value: any) => {
    setStates((prev: any) => ({ ...prev, [key]: value }));
  }, []);

  // ---------------------------APIs -- GET Endpoints ----------------------------------------------------

  const [mainT_data, mainT_error, mainT_loading] = useFetch(
    `AsmTmsMaintenanceItems/GetAllMaintenanceTypesNames`
  );
  const itemClass: { itemId: number; value: string }[] = [
    { itemId: 1, value: "Inventory Checklist" },
    { itemId: 2, value: "Maintenance Checklist" },
  ];
  const [leftDataApi, leftApiError, leftApiLoading] = useFetch(
    `AsmTmsMaintenanceItems/GetAllVehicleModelsForGdvModels`
  );
  const [rightDataApi, rightApiError, rightApiLoading] = useFetch(
    `AsmTmsMaintenanceItems/GetAllModelChecklistAssignmentsForGdvMaintenanceItems?mcaItemClass=${itemClass_state.name}&mtpName=${mainT_state.name}&mcaModelIDfk=${leftSelectedRecord.mdlIDpk}`
  );
  const [mainDataApi, mainApiError, mainApiLoading] = useFetch(
    `AsmTmsMaintenanceItems/GetAllModelChecklistAssignmentsForGdvAssignment?mdlName=${""}&mtiName=${""}&vhcName=a&vmkName=${""}`,
    gridRefresh
  );

  const leftGrid: selectsTypes[] = [
    {
      id: 0,
      label: "Maint. Type",
      idExpr: "mtpIdpk",
      dataExpr: "mtpName",
      optionsData: mainT_data,
      stateName: "mainT_state",
      defaultValue: mainT_state.name,
      style: "",
    },
    {
      id: 1,
      label: "Item Class",
      idExpr: "itemId",
      dataExpr: "value",
      optionsData: itemClass,
      stateName: "itemClass_state",
      defaultValue: itemClass_state.name,
      style: "",
    },
  ];

  const colorCell = (e: any) => {
    if ((e.columnIndex === 0 || e.columnIndex === 5) && e.rowIndex > -1) {
      return (e.cellElement.style.backgroundColor =
        "rgb(250 204 21 / var(--tw-bg-opacity))");
    }
  };

  const validateAll = () => {
    if (states.itemClass_state.id == 0) {
      updateStates("setModalState", {
        open: true,
        title: "Invalid Item Class",
        message: "Please select an Item Class",
        icon: "warning",
      });
    } else {
      updateStates("setModalState", {
        ...states.setModalState,
        open: true,
        icon: "question",
        title: "Save Assignments",
        message: "Are you sure you want to save this assignment?",
      });
    }
  };
  const validate = () => {
    if (states.itemClass_state.id == 0) {
      updateStates("setModalState", {
        open: true,
        title: "Invalid Item Class",
        message: "Please select an Item Class",
        icon: "warning",
      });
    } else if (states.mainT_state.id === 0) {
      updateStates("setModalState", {
        open: true,
        title: "Invalid Maintaince Type",
        message: "Please select Maintenance Type",
        icon: "warning",
      });
    } else {
      updateStates("setModalState", {
        ...states.setModalState,
        open: true,
        icon: "question",
        title: "Save Assignment",
        message: "Are you sure you want to save this assignment?",
      });
    }
  };

  const postData = async () => {
    try {
      // validation

      if (rightSelectedRecord.length < 0) {
        updateStates("setModalState", {
          open: true,
          title: "Invalid Record",
          message: "Please select a record to save",
          icon: "warning",
        });
      }

      for (let model of rightSelectedRecord) {
        await posting (
          `AsmTmsMaintenanceItems/CreateAsmTmsModelChecklistAssignment`,
          {
            mcaModelIDfk: model?.mcaIDpk,
            mcaMakeIDfk: leftSelectedRecord?.vmkIDpk,
            mcaClassIDfk: leftSelectedRecord?.vhcIDpk,
            mcaMaintenanceItemIDfk: model?.mtiIDpk,
            mcaMaintenanceTypeIDfk: mainT_state.id,
            mcaItemClass: itemClass_state.name,
            mcaActive: true,
            mcaRmks: model?.mcaRmks,
            mcaCreatedBy: userId,
          },``
        );
      }

      // used to refresh the grid_table
      updateStates("gridRefresh", !gridRefresh);
      updateStates("setModalState", {
        open: true,
        title: "Record Saved!",
        message: "Data entry saved successfully",
        icon: "success",
      });
    } catch (err: any) {
      console.log(err)
      updateStates("setModalState", {
        open: true,
        title: "Unexpected Error!",
        message: err.response.statusText ?? "Error creating record, Please Contact your System Administrator",
        icon: "warning",
      });
    }
  };
  const postDataAll = async () => {
    try {
      // validation

      if (rightSelectedRecord.length < 0) {
        updateStates("setModalState", {
          open: true,
          title: "Invalid Record",
          message: "Please select a record to save",
          icon: "warning",
        });
      }

      for (let model of rightSelectedRecord) {
        await posting (
          `AsmTmsMaintenanceItems/CreateAllAsmTmsModelChecklistAssignment`,
          {
            mcaModelIDfk: model?.mcaIDpk,
            mcaMakeIDfk: leftSelectedRecord?.vmkIDpk,
            mcaClassIDfk: leftSelectedRecord?.vhcIDpk,
            mcaMaintenanceItemIDfk: model?.mtiIDpk,
            mcaMaintenanceTypeIDfk: mainT_state.id,
            mcaItemClass: itemClass_state.name,
            mcaRmks: model?.mcaRmks,
            mcaCreatedBy: userId,
          }
        );
      }

      // used to refresh the grid_table
      updateStates("gridRefresh", !gridRefresh);
      updateStates("setModalState", {
        open: true,
        title: "Record Saved!",
        message: "Data entry saved successfully",
        icon: "success",
      });
    } catch (err: any) {
      console.log(err)
      updateStates("setModalState", {
        open: true,
        title: "Unexpected Error!",
        message: err.response.statusText ?? "Error creating record, Please Contact your System Administrator",
        icon: "warning",
      });
    }
  };

  return (
    <>
      <ModalTemplate
        disableCancel={(icon === "warning" || icon === "success") && true}
        icon={icon}
        open={states.setModalState.open}
        message={message}
        title={title}
        cancelHandler={() => {
          updateStates("setModalState", {
            ...states.setModalState,
            open: false,
          });
        }}
        okHandler={() => {
          states.setModalState.title === "Save Assignment"
            ? postData()
            : states.setModalState.title === "Save Assignments"
            ? postDataAll()
            : updateStates("setModalState", {
                ...states.setModalState,
                open: false,
              });
        }}
      />
      <div className="w-full h-full ">
        <div
          ref={upperForm}
          className="w-full h-full flex justify-between space-x-1 p-2 border-b-4"
        >
          <div className="w-1/2 flex">
            <Datagrid_template
              gridheight={370}
              columns={left_Model_columns}
              data={leftDataApi}
              disablePaging
              onRowClick={(e) => {
                updateStates("leftSelectedRecord", e);
              }}
            />
          </div>

          <div className="w-[48%] h-full">
            <Form
              // style={{ borderColor: borderTheme }}
              className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row"
              name="basic"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              autoComplete="off"
              size="small"
              layout="horizontal"
            >
              <div className="w-full flex">
                {leftGrid.map(
                  ({
                    id,
                    label,
                    idExpr,
                    dataExpr,
                    optionsData,
                    stateName,
                    defaultValue,
                    style,
                  }) => {
                    return (
                      <div key={id} className="w-full space-x-2">
                        <SelectsTemplate
                          index={id}
                          useCallFunc
                          idexpr={idExpr}
                          dataexp={dataExpr}
                          label={label}
                          options={optionsData}
                          placeHolder={defaultValue}
                          selectedValue={(e) => {
                            updateStates(stateName!, {
                              id: e[idExpr!],
                              name: e[dataExpr!],
                            });
                          }}
                          handleRefresh={() => {}}
                        />
                      </div>
                    );
                  }
                )}
              </div>
            </Form>

            <Datagrid_template
              gridheight={305}
              columns={right_Model_columns}
              cellColoringFxn={colorCell}
              data={rightDataApi}
              disablePaging
              disableSearch
              disableGroupPanel
              deselectFirstRow
              selectionMode={"multiple"}
              selectedItemsChange={(e) => {
                updateStates("rightSelectedRecord", e);
              }}
              allowColumnEditing
              showSelectionCheckBox="always"
            />

            <ul className="pt-1 flex justify-between">
              <li>
                <SaveButton
                  title={"Save For All Models"}
                  tooltipMsg={"SaveForAll"}
                  handleSave={() => {
                    validateAll();
                  }}
                />
              </li>
              <li>
                <SaveButton
                  useCallbackFunc
                  handleSave={() => {
                    validate();
                  }}
                />
              </li>
            </ul>
          </div>
        </div>

        {/* Datagrid */}
        <div className="w-full h-full px-2">
          <Datagrid_template
            gridheight={full_height}
            columns={footer_Model_columns}
            data={mainDataApi}
            onRowClick={() => {}}
            rowDoubleClicked={() => {}}
          />
        </div>
      </div>
    </>
  );
};
