/* eslint-disable react/jsx-pascal-case */
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";
import { setGeneralValue } from "../../../../../../features/generalSlice";
import useFetch from "../../../../../../hooks/useFetch";
import { ModalTemplate } from "../../../../../../templates/modal";
import {
  validationsData,
  saveModalData,
  updateModalData,
} from "../../../../../accessories/component_infos";
import Setups_Template from "../../../../../human-resource/setups/.setups_templates/Setups_Template";
import {
  exprsTypes,
  modalTypes,
  searchCriteriaTypes,
  searchTextTypes,
  selectsTypes,
} from "../../../../../human-resource/setups/data/types/selectsTypes";
import { assets_vehiclesmakesColumns } from "./data/vehicleMakeData";
import {
  setTier2CheckboxValue,
  tier2CheckboxSaveOnclick,
  tier2CheckboxUpdateOnclick,
} from "../../../../../../features/forms/tier2checkbox/tier2SliceCheckbox";
import { useBusyLoader } from "../../../../../../hooks/useBusyLoader";
import { WarningAlert } from "../../../../../accessories/warningAlert";
import { useAccountInfo } from "../../../../../../hooks/useAccountInfo";
import dayjs from "dayjs";
import { UpdateDataFunc } from "../../../../../../functions/update";
import { PostDataFunc  } from "../../../../../../functions/post";
import { useCrudFunc } from "../../../../../../functions/crud";

export const Assets_setups_vehicleMake = forwardRef(({ a }: any, ref) => {
  const [employeeId, userId] = useAccountInfo();

  // on page resize height responsiveness
  const saveSuccessText = {
    message: "Vehicle Make added Successfully",
    ok: "Ok",
    title: "Success",
  }; // successful save modal message
  const updateSuccessText = {
    message: "Vehicle Make updated Successfully",
    ok: "Ok",
    title: "Success",
  }; // successful update modal message

  // store data expressions[unique] ...dependent on data coming in
  const idExpr: string = "vmkIdpk";
  const nameExpr: string = "vmkName";
  const shortnameExpr: string = "vmkShtName";
  const orderExpr: string = "vmkOrder";
  const activeExpr: string = "vmkActive";
  const remarksExpr: string = "vmkRmks";
  const inputsExprsData: any = {
    idExpr: idExpr,
    nameExpr: nameExpr,
    shortnameExpr: shortnameExpr,
    orderExpr: orderExpr,
    activeExpr,
    remarksExpr: remarksExpr,
  };

  const storeExprs = () => {
    const exprs = [
      { expr: "idExpr", value: idExpr },
      { expr: "nameExpr", value: nameExpr },
      { expr: "shortnameExpr", value: shortnameExpr },
    ];
    //to particular redux store based on form type
    for (let i = 0; i < exprs.length; i++) {
      dispatch(
        setTier2CheckboxValue({ expr: exprs[i].expr, value: exprs[i].value })
      );
    }

    // to general slice
    const generalExprs: exprsTypes[] = [
      { expr: "inputExprs", value: inputsExprsData },
    ];
    for (let i = 0; i < generalExprs.length; i++) {
      dispatch(
        setGeneralValue({
          expr: generalExprs[i].expr,
          value: generalExprs[i].value,
        })
      );
    }
  };

  const [busyLoader, setBusyLoader] = useBusyLoader();
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState<boolean>(false); //refresh data state for data creation or update

  //modal states
  //--general--
  const triggerSuccessModal = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.successModalState
  ); //trigger success modal
  const SuccessModalText = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.successText
  ); //success modal text

  //--for backend errors--
  const [errState, setErrState] = useState<boolean>(false);
  const [errTitle, seterrTitle] = useState<string>("");
  const [errMessage, seterrMessage] = useState<string>("");

  //--warning modal--
  const triggerwarningModal = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.warningState
  ); //trigger warning
  const warningInfos: any = validationsData; //warning type details
  const warningDataExpr: any = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.warningDataExpr
  ); //warning type selector

  //--save data modal--
  const saveData = saveModalData; //save modal details[data]
  const triggersaveModal = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.saveDataOnclick
  ); //trigger save modal

  //--Update data modal--
  const updateIndInfos = updateModalData; //update modal details[data]
  const triggerupdateModal: boolean = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.updateDataOnclick
  ); //trigger update question
  // on page resize height responsiveness
  const [trigger, setTrigger] = useState<boolean>(false);
  useImperativeHandle(ref, () => ({
    test: () => {
      setTrigger(!trigger);
    },
  }));

  // get current values from  [slice dependent on form type]
  const vehicleClass = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.select0
  );
  const name = useSelector((state: RootState) => state.tier2Ckeckboxforms.name);
  const shortname = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.shortname
  );
  const order = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.order
  );
  const active = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.active
  );
  const remarks = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.remarks
  );
  const selectedID = useSelector(
    (state: RootState) => state.general.selectedFieldData
  );

  //post form Data
  //validate data
  const validateDataSave = () => {
    if(!vehicleClass){
      setErrState(true);
      seterrTitle("Error");
      seterrMessage("Please Vehicle Class data is empty,");
      return;
    }
    dispatch(tier2CheckboxSaveOnclick(""));
  };

  const [Posting, Updating, Patching] = useCrudFunc(); //crud functions
  // post
  const postData = async () => {
    setBusyLoader(saveModalData.progress);

    dispatch(setTier2CheckboxValue({ expr: "saveDataOnclick", value: false })); //disable modal
    try {
      await Posting (`AsmTmsVehicleMakes/CreateAsmTmsVehicleMake`, {
        vmkName: name,
        vmkShtName: shortname,
        vmkOrder: order,
        vmkVehicleClassIdfk: vehicleClass,
        vmkActive: active,
        vmkRmks: remarks,
        vmkCreatedBy: userId,
      },`Created Vehicle Make - ${name} with short name ${shortname} Successfully`);

      setRefresh(!refresh);
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));

      const exprs: exprsTypes[] = [
        { expr: "saveDataOnclick", value: false },
        { expr: "successModalState", value: true },
        { expr: "successText", value: saveSuccessText },
      ];
      for (let i = 0; i < exprs.length; i++) {
        dispatch(
          setTier2CheckboxValue({ expr: exprs[i].expr, value: exprs[i].value })
        );
      }
      setBusyLoader("");
    } catch (error: any) {
      //set states for db errors modal
      setErrState(true);
      seterrTitle(saveModalData.error.title);
      seterrMessage(saveModalData.error.message);
    } finally {
      setBusyLoader("");
    }
  };

  //Update Form data
  //validate data
  const validateDataUpdate = () => {
    if(!vehicleClass){
      setErrState(true);
      seterrTitle("Error");
      seterrMessage("Please Vehicle Class data is empty,");
      return;
    }
    dispatch(tier2CheckboxUpdateOnclick(""));
  };
  // update
  const UpdateData = async () => {
    dispatch(
      setTier2CheckboxValue({ expr: "updateDataOnclick", value: false })
    ); //disable modal
    try {
      setBusyLoader(updateModalData.progress);

      await Updating(`AsmTmsVehicleMakes/UpdateAsmTmsVehicleMake`, {
        vmkIdpk: selectedID.vmkIDpk,
        vmkName: name,
        vmkShtName: shortname,
        vmkActive: active,
        vmkRmks: remarks,
        vmkOrder: order,
        vmkVehicleClassIdfk: vehicleClass,
        vmkEditedBy: userId,
        vmkEditedDate: dayjs().format(),
      },`Updated Vehicle Make - to ${name} with short name ${shortname} Successfully`);

      setRefresh(!refresh);

      const exprs: exprsTypes[] = [
        { expr: "updateDataOnclick", value: false },
        { expr: "successModalState", value: true },
        { expr: "successText", value: updateSuccessText },
      ];
      for (let i = 0; i < exprs.length; i++) {
        dispatch(
          setTier2CheckboxValue({ expr: exprs[i].expr, value: exprs[i].value })
        );
      }
      setBusyLoader("");
    } catch (error: any) {
      //set states for db errors modal

      setErrState(true);
      seterrTitle(updateModalData.error.title);
      seterrMessage(updateModalData.error.message);
      setBusyLoader("");
    }
  };

  //modals data
  const modals: modalTypes[] = [
    //warning
    {
      disableCancel: true,
      icon: "warning",
      open: triggerwarningModal,
      okHandler: () =>
        dispatch(setTier2CheckboxValue({ expr: "warningState", value: false })),
      cancelHandler: () =>
        dispatch(setTier2CheckboxValue({ expr: "warningState", value: false })),
      message: warningInfos[warningDataExpr!].message,
      cancelText: warningInfos[warningDataExpr!].cancelText,
      okText: warningInfos[warningDataExpr!].okText,
      title: warningInfos[warningDataExpr!].title,
    },
    //backend error
    {
      disableCancel: true,
      icon: "warning",
      open: errState,
      okHandler: () => setErrState(false),
      cancelHandler: () => setErrState(false),
      message: errMessage,
      cancelText: "",
      okText: "Ok",
      title: errTitle,
    },
    //success
    {
      disableCancel: true,
      icon: "success",
      open: triggerSuccessModal,
      okHandler: () =>
        dispatch(
          setTier2CheckboxValue({ expr: "successModalState", value: false })
        ),
      cancelHandler: () =>
        dispatch(
          setTier2CheckboxValue({ expr: "successModalState", value: false })
        ),
      message: SuccessModalText.message,
      okText: SuccessModalText.okText,
      title: SuccessModalText.title,
    },
    //save
    {
      disableCancel: false,
      icon: "question",
      open: triggersaveModal,
      okHandler: () => postData(),
      cancelHandler: () =>
        dispatch(
          setTier2CheckboxValue({ expr: "saveDataOnclick", value: false })
        ),
      message: saveData.message,
      cancelText: saveData.cancelText,
      okText: saveData.okText,
      title: saveData.title,
    },
    //Update
    {
      disableCancel: false,
      icon: "question",
      open: triggerupdateModal,
      okHandler: () => UpdateData(),
      cancelHandler: () =>
        dispatch(
          setTier2CheckboxValue({ expr: "updateDataOnclick", value: false })
        ),
      message: updateIndInfos.message,
      cancelText: updateIndInfos.cancelText,
      okText: updateIndInfos.okText,
      title: updateIndInfos.title,
    },
  ];

  const [status, setActive] = useState<{
    temp: boolean | string;
    active: boolean | string;
  }>({
    temp: "",
    active: "",
  });
  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });
  const [searchIndex, setSearchIndex] = useState<searchCriteriaTypes>({
    index: 0,
    temp: 0,
  });

  const [data, error, loading] = useFetch(
    `AsmTmsVehicleMakes/GetAllAsmTmsVehicleMakeGrid?criteriaIndex=${searchIndex.index}&search=${searchText.text}&active=${status.active}`,
    refresh
  );

  useEffect(() => {
    if (data) {
      storeExprs(); // run func to store expressions on store
      dispatch(setGeneralValue({ expr: "idExpr", value: idExpr })); // dispatch fetched data to reduxstore
      dispatch(setGeneralValue({ expr: "formData", value: data })); // dispatch fetched data to reduxstore
    }

    loading ? setBusyLoader(".") : setBusyLoader("");
  }, [loading, data]);

  // form parameters
  const form_header = "Vehicle Make Details";

  //selects data
  const [vehicleClassData, vehicleClassError, vehicleClassLoading] = useFetch(
    "AsmTmsVehicleClass/GetAllVehicleClassNames",
    refresh,
    "vmkIDpk"
  );

  const select: selectsTypes[] = [
    {
      id: 0,
      label: "Veh.Class",
      optionsData: vehicleClassData,
      dataExpr: "vhcName",
      idExpr: "vhcIdpk",
      idKey: "vhcIdpk",
      nameKey: "vhcName",
    },
  ];

  const searchCriteria = [
    { id: 0, value: "Make" },
    { id: 1, value: "Class" },
  ];

  return (
    <>
      {vehicleClassError && <WarningAlert />}

      {/* modals */}
      {modals.map(
        (
          {
            disableCancel,
            icon,
            okHandler,
            cancelHandler,
            open,
            cancelText,
            okText,
            message,
            title,
          }: modalTypes,
          index
        ) => {
          return (
            <div key={index}>
              <ModalTemplate
                icon={icon && icon}
                disableCancel={disableCancel}
                cancelText={cancelText && cancelText}
                open={open}
                okHandler={okHandler}
                cancelHandler={cancelHandler}
                message={message}
                okText={okText}
                title={title}
              />
            </div>
          );
        }
      )}

      <Setups_Template
        searchCriteria={searchCriteria}
        searchCriteriaChange={(index, criteria) => {
          setSearchIndex({ temp: index, index: index });
        }}
        handleRefresh={() => {
          setRefresh(!refresh);
          setSearchText({ temp: "", text: "" });
          setActive({ temp: "", active: "" });
        }}
        checkOnChange={(value) => {
          setActive({ temp: value, active: status.active }); //store locally
          dispatch(setGeneralValue({ expr: "activeStatus", value: value })); //push value to redux
        }}
        searchTextChange={(newText) => {
          setSearchText({ temp: newText, text: searchText.text });
        }}
        handleSearch={() => {
          setRefresh((current) => !current);
          setSearchIndex({ temp: searchIndex.temp, index: searchIndex.temp });
          setSearchText({ text: searchText.temp, temp: searchText.temp });
          setActive({ temp: status.temp, active: status.temp });
        }}
        labelCol={5}
        updateData={validateDataUpdate}
        createData={validateDataSave}
        formTier="tier2checkbox"
        trigger={trigger}
        datagridColumns={assets_vehiclesmakesColumns}
        datagridData={data}
        formHeader={form_header}
        selects={select}
      />
    </>
  );
});
