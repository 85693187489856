import { DatagridExcelProps, trimAll } from "../widgets/vehicleDataUpload-details";

export type VehicleData = {
    vdtRegNo: string;
    vdtChassisNo: string;
    vdtEngineNo: string;
    vdtModelIdfk: number;
    vdtManufacturerIdfk: number;
    vdtCountryOfOriginIdfk: number;
    vdtSupplierIdfk: number;
    vdtPowerTrainIdfk: number;
    vdtTransmissionTypeIdfk: number;
    vdtEngineMakeIdfk: number;
    vdtEngineTypeIdfk: number;
    vdtFuelTypeIdfk: number;
    vdtStatusIdfk: number;
    vdtPriceCurrencyIdfk: number;
    vdtManufactureDate: string;
    vdtSupplyDate: string;
    vdtDvlaRegDate: string;
    vdtCubicCapacity: number;
    vdtTankCapacity: number;
    vdtNoOfCylinders: number;
    vdtColour: string;
    vdtSeatingCapacity: number;
    vdtLength: number;
    vdtWidth: number;
    vdtHeight: number;
    vdtNoOfAxles: number;
    vdtNoOfWheels: number;
    vdtTyreSizeFront: string;
    vdtTyreSizeMiddle: string;
    vdtTyreSizeRear: string;
    vdtNetWeight: number;
    vdtGrossWeight: number;
    vdtHorsePower: string;
    vdtPrice: number;
    vdtActive: boolean;
    vdtRmks: string;
    vdtCreatedBy: string;
};

export type VehicleDataArray = VehicleData[];
export default class Mapper {

    toSubmittable(dataSource: DatagridExcelProps): VehicleData {
        return {
            vdtRegNo: trimAll(dataSource["REG No"]),
            vdtChassisNo: trimAll(dataSource["CHASSIS No"]),
            vdtEngineNo: trimAll(dataSource["Engine No"]),
            vdtModelIdfk: dataSource.vdtModelIdfk,
            vdtManufacturerIdfk: dataSource.vdtManufacturerIdfk,
            vdtCountryOfOriginIdfk: dataSource.vdtCountryOfOriginIdfk,
            vdtSupplierIdfk: dataSource.vdtSupplierIdfk,
            vdtManufactureDate: "2002-01-24", //TODO: check if date is valid
            vdtSupplyDate: dataSource["SUPPLY DATE"] ?? "1900-01-01",
            vdtDvlaRegDate: dataSource["DVLA REG DATE"] ?? "1900-01-01",
            vdtEngineTypeIdfk: dataSource.vdtEngineTypeIdfk,
            vdtFuelTypeIdfk: dataSource.vdtFuelTypeIdfk,
            vdtCubicCapacity: trimAll(dataSource["CUBIC CAPACITY"]),
            vdtTankCapacity: trimAll(dataSource["TANK CAPACITY"]),
            vdtPowerTrainIdfk: dataSource.vdtPowerTrainIdfk,
            vdtTransmissionTypeIdfk: dataSource.vdtTransmissionTypeIdfk,
            vdtEngineMakeIdfk: dataSource.vdtEngineMakeIdfk,
            vdtNoOfCylinders: trimAll(dataSource["No OF CYLINDERS"]),
            vdtColour: trimAll(dataSource.COLOUR),
            vdtStatusIdfk: dataSource.vdtStatusIdfk,
            vdtSeatingCapacity: trimAll(dataSource["SEATING CAPACITY"]),
            vdtLength: trimAll(dataSource.LENGTH),
            vdtWidth: trimAll(dataSource.WIDTH),
            vdtHeight: trimAll(dataSource.HEIGHT),
            vdtNoOfAxles: trimAll(dataSource["No OF AXLES"]),
            vdtNoOfWheels: trimAll(dataSource["No OF WHEELS"]),
            vdtTyreSizeFront: trimAll(dataSource["TYRE SIZE (F)"]),
            vdtTyreSizeMiddle: trimAll(dataSource["TYRE SIZE (M)"]),
            vdtTyreSizeRear: trimAll(dataSource["TYRE SIZE (R)"]),
            vdtNetWeight: trimAll(dataSource["NET WEIGHT"]),
            vdtGrossWeight: trimAll(dataSource["GROSS WEIGHT"]),
            vdtHorsePower: trimAll(dataSource["HORSE POWER"]),
            vdtPrice: trimAll(dataSource.PRICE),
            vdtPriceCurrencyIdfk: dataSource.vdtPriceCurrencyIdfk,
            vdtActive: true,
            vdtRmks: trimAll(dataSource.REMARKS),
            vdtCreatedBy: dataSource.vdtCreatedBy,
        }
    }

}

