import { datagridColumnTypes } from "../../../human-resource/setups/data/types/selectsTypes"

export const userAdminMainGridColumns:datagridColumnTypes[] = [ 
    {id : 0, caption : 'EMP ACTV?',dataField : 'empActive',dataType : 'boolean', width : 90, fixed : true , visible : true},
    {id : 1, caption : 'USER ACTV?',dataField : 'usaActive',dataType : 'boolean', width : 90, fixed : true , visible : true},
    {id : 2, caption : 'USERNAME',dataField : 'usaUsername',dataType : 'datetime', width : 160, fixed : true , visible : true},
    {id : 3, caption : 'STAFF NO',dataField : 'empStaffNo',dataType : 'string', width : 110, fixed : false , visible : true},
    {id : 4, caption : 'STAFF NAME',dataField : 'empName',dataType : 'string', width : 190, fixed : false , visible : true},
    {id : 5, caption : 'DEPT',dataField : 'dptShtName',dataType : 'string', width : 160, fixed : false , visible : true},
    {id : 6, caption : 'SECTIION',dataField : 'sxnShtName',dataType : 'string', width : 160, fixed : false , visible : true},
    {id : 7, caption : 'JOB TITLE',dataField : 'jbtname',dataType : 'string', width : 170, fixed : false , visible : true},
    {id : 8, caption : 'CREATED ON',dataField : 'usaCreationDate',dataType : 'datetime', width : 100, fixed : false , visible : true},
    {id : 9, caption : 'START DATE',dataField : 'usaStartDate',dataType : 'datetime', width : 100, fixed : false , visible : true},
    {id : 10, caption : 'END DATE',dataField : 'usaEndDate',dataType : 'datetime', width : 100, fixed : false , visible : true},
    {id : 11, caption : 'ONLINE?',dataField : 'usaLoggedIn',dataType : 'boolean', width : 90, fixed : false , visible : true},
    {id : 12, caption : 'MOB No',dataField : 'empMobNo',dataType : 'string', width : 160, fixed : false , visible : true},
    {id : 13, caption : 'INTERCOM/PLC',dataField : 'empIntercom',dataType : 'string', width : 160, fixed : false , visible : true},
    {id : 14, caption : 'SEE DASHBOARD?',dataField : 'usaSeeExecDashboard',dataType : 'boolean', width : 110, fixed : false , visible : true},
    {id : 15, caption : 'ORG ADMIN?',dataField : 'notset',dataType : 'boolean', width : 120, fixed : false , visible : true},
    {id : 16, caption : 'DEPT ADMIN',dataField : 'notset2',dataType : 'boolean', width : 120, fixed : false , visible : true},

    {id : 17, caption : 'SXN ADMIN',dataField : 'notset3',dataType : 'boolean', width : 120, fixed : false , visible : true},
    {id : 18, caption : 'EDIT MOB No',dataField : 'usaEditOthersMobNos',dataType : 'boolean', width : 120, fixed : false , visible : true},
    {id : 19, caption : 'EDIT INTERCOM',dataField : 'usaEditIntercom',dataType : 'boolean', width : 120, fixed : false , visible : true},
    {id : 20, caption : 'EDIT EMAILS',dataField : 'usaEditEmails',dataType : 'boolean', width : 120, fixed : false , visible : true},

    {id : 21, caption : 'CHANGE PSWD',dataField : 'notset4',dataType : 'boolean', width : 120, fixed : false , visible : true},
    {id : 22, caption : 'CEO',dataField : 'notset5',dataType : 'boolean', width : 120, fixed : false , visible : true},
    {id : 23, caption : 'DEP.CEO',dataField : 'notset6',dataType : 'boolean', width : 120, fixed : false , visible : true},
    {id : 24, caption : 'SEE HIDDEN MOB Nos?',dataField : 'usaSeeHiddenMobNos',dataType : 'boolean', width : 120, fixed : false , visible : true},

    {id : 25, caption : 'SEND SMS',dataField : 'usaAllowSendingOfSMS',dataType : 'boolean', width : 120, fixed : false , visible : true},
    {id : 26, caption : 'REMARKS',dataField : 'usaRmks',dataType : 'string', width : 4000, fixed : false , visible : true},
]

export const userPrivelegesColumns:datagridColumnTypes[]  = [ 
    {id : 0, caption : 'FORM',dataField : 'afmName',allowEditing:false,dataType : 'string', width : 200, fixed : true , visible : true},
    {id : 1, caption : 'SAVE',dataField : 'apvInsert',allowEditing:true,dataType : 'boolean', width : 110, fixed : false , visible : true},
    {id : 2, caption : 'UPDATE',dataField : 'apvUpdate',allowEditing:true,dataType : 'boolean', width : 110, fixed : false , visible : true},
    {id : 3, caption : 'READ',dataField : 'apvRead',allowEditing:true   ,dataType : 'boolean', width : 110, fixed : false , visible : true},
    {id : 4, caption : 'PRINT',dataField : 'apvPrint',allowEditing:true ,dataType : 'boolean', width : 110, fixed : false , visible : true},
    {id : 5, caption : 'DELETE',dataField : 'dptShtName',allowEditing:true ,dataType : 'boolean', width : 110, fixed : false , visible : true},
    {id : 6, caption : 'FORM CODE',dataField : 'afmCode',allowEditing:false,dataType : 'string', width : 160, fixed : false , visible : true},
    {id : 7, caption : 'DESCRIPTION',dataField : 'afmDesc',allowEditing:false,dataType : 'string', width : 2000, fixed : false , visible : true},
]