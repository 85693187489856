import React from 'react'
import { useEffect, useRef, useState } from "react";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import useFetch from "../../../../../../hooks/useFetch";
import  Datagrid_template from "../../../../../../templates/Datagrid";
import { navbar_height,  toolbar_height } from "../../../../../accessories/component_infos";
import { HrSetupsCustomToolbar } from "../../../../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import { assets_fuelDAAssignmentsColumns } from "./data/FDAAssignments";
import FdaAssignmentsForm from "./widgets/fdaAssignmentsForm";
import { useBusyLoader } from '../../../../../../hooks/useBusyLoader';
import { WarningAlert } from '../../../../../accessories/warningAlert';
import { searchTextTypes, searchCriteriaTypes, statusTypes } from '../../../../../human-resource/setups/data/types/selectsTypes';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../app/store';
import useResizeMode from '../../../../../../hooks/useResizeMode';

export const Assets_setups_fuelDAAssignments = React.forwardRef(({a}:any, ref)=>{
    const news_flash_height = useSelector((state:RootState)=>state.general.newsflashHeight);
    const[loader,setLoader]=useBusyLoader()
    const formRef = useRef<any>();
    //navigation
    const setupPageRoute = window.location.pathname; //get current route of page
    const datagridOnlySearchParam = 'table-only'  //search param in datagrid mode
    const formModeSearchParam = 'form-and-table' //search param in form mode mode
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    //miscellaneous heights
    const miscHeight = 7;
    
    const heights_out = navbar_height + news_flash_height + toolbar_height;

    const [form_height,set_form_height] = useState<number>(386);
  
    const toggle_staff_form = ():void =>{
        const view = searchParams.get('view');

        (view === null || view === formModeSearchParam) ? 
        navigate({
            pathname : setupPageRoute,
            search : createSearchParams({
                view : datagridOnlySearchParam,
            }).toString(),
        })
        :
        navigate({
            pathname : setupPageRoute,
            search : createSearchParams({
                view : formModeSearchParam,
            }).toString(),
        });
    }

    const [resizeMode, initialResizeMode] = useResizeMode(searchParams.get('view') === formModeSearchParam || searchParams.get('view') === (null))

    //Table Height
    const pageHeight = resizeMode - (navbar_height + miscHeight + 480); 
    const tableHeight = initialResizeMode - (heights_out + miscHeight);

    const [refresh, setRefresh] = useState<boolean>(false); //refresh data state for data creation or update
    const [updateMode, setupdateMode] = useState<boolean>(false); //update mode
    const [selectedRecord,setSelectedRecord] = useState<any>({});
    const criteria=[
        {id:0,value:'Attendant'},
        {id:1,value:'Fuel Dump'},
    ]

    const[searchText,setSearchText]=useState<searchTextTypes>({text:'',temp:''})
    const[searchCriteria,setSearchCriteria]=useState<searchCriteriaTypes>({temp:0,index:0})
    const[status, setStatus] = useState<statusTypes>({temp:'',status:''}); 
    
    //api call for Main grid data.
    const [Maindata, dataError, dataLoading] = useFetch(`AsmTmsFuelDumpAttendentAssignment/GetAll?criteria=${searchCriteria.index}&search=${searchText.text}&active=${status.status}`, refresh,'faaIDpk');
    useEffect(()=>{
        dataLoading ? setLoader('.') : setLoader('');
    },[dataLoading])
    
    return(
        <>
            {dataError&&(
                <WarningAlert/>
            )}
            
            <div className="h-full">
                <div className="px-2">
                    <HrSetupsCustomToolbar
                    searchTextOnchange={(text)=> setSearchText({temp:text,text:searchText.text})}
                    searchCriteriaOnchange={(crit)=> setSearchCriteria({temp:crit,index:searchCriteria.index})}
                    checkOnChange={(check)=> setStatus({temp:check,status:status.status})}
                    handleFind={()=>{
                        setRefresh(current => !current)
                        setSearchText({temp:searchText.temp,text:searchText.temp});
                        setSearchCriteria({temp:searchCriteria.temp,index:searchCriteria.temp});
                        setStatus({temp:status.temp,status:status.temp});
                    }}
                    searchCriteriaData={criteria}
                    handleRefresh={()=>{
                        setRefresh(current => !current)
                        setSearchText({temp:"",text:""});
                        setSearchCriteria({temp:0,index:0});
                        setStatus({temp:"",status:""});

                    }}
                    handleToggle={()=> formRef.current?.toggle(selectedRecord)}
                    handleToggleAll={()=> formRef.current?.toggleAll(selectedRecord)}
                    assignmentsForm
                    disableNew_edit
                    withDates={false}
                    toggler={toggle_staff_form}/>
                </div>

                {(searchParams.get('view') !== datagridOnlySearchParam) &&
                <section style={{height : form_height}}  className=" form border-b-4">
                    <FdaAssignmentsForm
                    selectedRecord={selectedRecord}
                    ref={formRef}
                    refresh={()=>{
                        setSelectedRecord({})
                        setRefresh(current=>!current)
                    }} 
                    updateMode={updateMode} 
                    />
                </section>}

                <section className=" px-2">
                    <Datagrid_template 
                    onRowClick={(selected)=>{
                        setSelectedRecord(selected);
                        formRef.current?.setSelectedRecord(selected)
                    }}
                    gridheight= {searchParams.get('view') === formModeSearchParam || searchParams.get('view') === (null) ?
                    pageHeight
                    : 
                    tableHeight
                    } 
                    data = {Maindata} 
                    selectionMode = {"single"}
                    columns = {assets_fuelDAAssignmentsColumns}/>
                </section>
            </div>
        </>
    )
})