import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import right from '../../../../../../../assets/right.png';
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";

interface props{
    onCheck: ()=> void
}
export const ExamsInitialScreen:React.FC<props>=({onCheck})=>{
    const[employeeId,userId,userModel] = useAccountInfo();
    const borderTheme = useSelector((state:RootState)=>state.general.cssBorderClass)

    return(
        <>
            <main style={{borderColor:borderTheme}} className=" border-[1px] rounded px-5 py-10 my-2">
                <p className=" text-3xl text-blue-500 ">Hi, {userModel.empFirstName ?? 'User'}</p>

                <p className=" text-3xl  mt-8 font-semibold">{'Driving Tests'}</p>

                <p className="my-2 text-gray-500">Click on <span className=" text-gray-800 font-semibold">Check</span> to find out if you have an exam to take.</p>

                <button
                style={{borderColor:borderTheme}}
                className="border-[1.5px] rounded shadow-lg font-semibold text-gray-600 text-base py-2.5 mt-8 px-4 flex items-center justify-between"
                onClick={()=>{
                    onCheck();
                }}
                >
                    Check
                    <img className=" ml-12" src={right} alt="icon" />
                </button>
            </main>
        </>
    )
}