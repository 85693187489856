/* eslint-disable react/jsx-pascal-case */
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";
import {
  setTier2CheckboxValue,
  tier2CheckboxSaveOnclick,
  tier2CheckboxUpdateOnclick,
} from "../../../../../../features/forms/tier2checkbox/tier2SliceCheckbox";
import {
  setGeneralValue,
  setFormData,
} from "../../../../../../features/generalSlice";
import useFetch from "../../../../../../hooks/useFetch";
import { ModalTemplate } from "../../../../../../templates/modal";
import {
  validationsData,
  saveModalData,
  updateModalData,
} from "../../../../../accessories/component_infos";
import Setups_Template from "../../../../../human-resource/setups/.setups_templates/Setups_Template";
import {
  checkboxTypes,
  exprsTypes,
  modalTypes,
  searchTextTypes,
  selectExprsTypes,
} from "../../../../../human-resource/setups/data/types/selectsTypes";
import { assetsSetups_maintenancetypes_columns } from "./data/maintenanceTypesData";
import { WarningAlert } from "../../../../../accessories/warningAlert";
import dayjs from "dayjs";
import { UpdateDataFunc } from "../../../../../../functions/update";
import { PostDataFunc  } from "../../../../../../functions/post";
import { useBusyLoader } from "../../../../../../hooks/useBusyLoader";
import { useAccountInfo } from "../../../../../../hooks/useAccountInfo";
import { useCrudFunc } from "../../../../../../functions/crud";

export const Assets_setups_maintenanceTypes = forwardRef(({ a }: any, ref) => {
  const saveSuccessText = {
    message: "Maintenance Type added Successfully",
    ok: "Ok",
    title: "Save Succesful",
  }; // successful save modal message
  const updateSuccessText = {
    message: "Maintenance Type updated Successfully",
    ok: "Ok",
    title: "Update Successful",
  }; // successful update modal message

  // store data expressions[unique]
  const idExpr: String = "mtpIdpk";
  const nameExpr: string = "mtpName";
  const shortnameExpr: string = "mtpShtName";
  const orderExpr: string = "mtpOrder";
  const activeExpr: string = "mtpActive";
  const remarksExpr: string = "mtpRmks";
  const inputsExprsData: any = {
    idExpr: idExpr,
    nameExpr: nameExpr,
    shortnameExpr: shortnameExpr,
    orderExpr: orderExpr,
    activeExpr,
    remarksExpr: remarksExpr,
  };
  const selectsExpr: selectExprsTypes[] = [
    {
      idExpr: "etpHardwareTypeIdfk",
      nameExpr: "etpHardwareTypeIdfk",
      label: "hardwareType",
    },
    { idExpr: "areIdpk", nameExpr: "areName", label: "area" },
    { idExpr: "areIdpk", nameExpr: "areName", label: "area" },
    { idExpr: "areIdpk", nameExpr: "areName", label: "area" },
    { idExpr: "areIdpk", nameExpr: "areName", label: "area" },
  ];
  const checkboxesExpr: selectExprsTypes[] = [
    {
      idExpr: "mtpPreventiveMaintenance",
      nameExpr: "mtpPreventiveMaintenance",
    },
    { idExpr: "mtpRoadworthy", nameExpr: "mtpRoadworthy" },
    { idExpr: "mtpInsuranceRenewal", nameExpr: "mtpInsuranceRenewal" },
    { idExpr: "areIdpk", nameExpr: "areName" },
    { idExpr: "areIdpk", nameExpr: "areName" },
  ];
  const dispatch = useDispatch();

  const storeExprs = () => {
    const exprs: exprsTypes[] = [
      { expr: "idExpr", value: idExpr },
      { expr: "nameExpr", value: nameExpr },
      { expr: "shortnameExpr", value: shortnameExpr },
      { expr: "selectExprs", value: selectsExpr[0] },
      { expr: "existsUrl", value: `AsmTmsMaintenanceType` },
    ];
    for (let i = 0; i < exprs.length; i++) {
      dispatch(
        setTier2CheckboxValue({ expr: exprs[i].expr, value: exprs[i].value })
      );
    }

    // to general slice
    const generalExprs: exprsTypes[] = [
      { expr: "selectExprs", value: selectsExpr },
      { expr: "inputExprs", value: inputsExprsData },
      { expr: "checkboxExprs", value: checkboxesExpr },
    ];
    for (let i = 0; i < generalExprs.length; i++) {
      dispatch(
        setGeneralValue({
          expr: generalExprs[i].expr,
          value: generalExprs[i].value,
        })
      );
    }
  };

  const [refresh, setRefresh] = useState<boolean>(false); //refresh data state for data creation or update
  const [posting , updating] = useCrudFunc()
  const [busyLoader, setBusyLoader] = useBusyLoader();
  const [userId, employeeId] = useAccountInfo();

  //modal states
  //--general--
  const triggerSuccessModal = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.successModalState
  ); //trigger success modal
  const SuccessModalText = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.successText
  ); //success modal text

  //--for backend errors--
  const [errState, setErrState] = useState<boolean>(false);
  const [errTitle, seterrTitle] = useState<string>("");
  const [errMessage, seterrMessage] = useState<string>("");

  //--warning modal--
  const triggerwarningModal = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.warningState
  ); //trigger warning
  const warningInfos: any = validationsData; //warning type details
  const warningDataExpr = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.warningDataExpr
  ); //warning type selector

  //--save data modal--
  const saveData = saveModalData; //save modal details[data]
  const triggersaveModal = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.saveDataOnclick
  ); //trigger save modal

  //--Update data modal--
  const updateIndInfos = updateModalData; //update modal details[data]
  const triggerupdateModal: boolean = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.updateDataOnclick
  ); //trigger update question

  // on page resize height responsiveness
  const [trigger, setTrigger] = useState<boolean>(false);
  useImperativeHandle(ref, () => ({
    test: () => {
      setTrigger(!trigger);
    },
  }));

  const name = useSelector((state: RootState) => state.tier2Ckeckboxforms.name);
  const shortname = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.shortname
  );
  const order = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.order
  );
  const active = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.active
  );
  const remarks = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.remarks
  );
  const checkbox0 = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.checkbox0
  );
  const checkbox1 = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.checkbox1
  );
  const checkbox2 = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.checkbox2
  );
  const selectedID = useSelector(
    (state: RootState) => state.general.SelectedFieldID
  );

  //post form Data
  //validate data
  const validateDataSave = () => {
    dispatch(tier2CheckboxSaveOnclick("type1"));
  };

  // post
  const postData = async () => {
    dispatch(setTier2CheckboxValue({ expr: "saveDataOnclick", value: false }));

    try {
      setBusyLoader(saveModalData.progress);

      await posting (`AsmTmsMaintenanceType/CreateAsmTmsMaintenanceType`, {
        mtpName: name,
        mtpShtName: shortname,
        mtpPreventiveMaintenance: checkbox0,
        mtpRoadworthy: checkbox1,
        mtpInsuranceRenewal: checkbox2,
        mtpActive: active,
        mtpRmks: remarks,
        mtpOrder: order,
        mtpCreatedBy: userId.toString(),
      },`Created Maintenance Type -${name} successfully`);
      setRefresh(!refresh);
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));

      const exprs: exprsTypes[] = [
        { expr: "saveDataOnclick", value: false },
        { expr: "successModalState", value: true },
        { expr: "successText", value: saveSuccessText },
      ];
      for (let i = 0; i < exprs.length; i++) {
        dispatch(
          setTier2CheckboxValue({ expr: exprs[i].expr, value: exprs[i].value })
        );
      }
    } catch (error: any) {
      //set states for db errors modal
      setErrState(true);
      seterrTitle(saveModalData.error.title);
      seterrMessage(saveModalData.error.message);
    } finally {
      setBusyLoader("");
    }
  };

  //Update Form data
  //validate data
  const validateDataUpdate = () => {
    dispatch(tier2CheckboxUpdateOnclick("type1"));
  };
  // update
  const UpdateData = async () => {
    dispatch(
      setTier2CheckboxValue({ expr: "updateDataOnclick", value: false })
    );

    try {
      setBusyLoader(updateModalData.progress);

      await updating(
        `AsmTmsMaintenanceType/UpdateAsmTmsMaintenanceTypes/${selectedID}`,
        {
          mtpIdpk: selectedID,
          mtpName: name,
          mtpShtName: shortname,
          mtpPreventiveMaintenance: checkbox0,
          mtpRoadworthy: checkbox1,
          mtpInsuranceRenewal: checkbox2,
          mtpActive: active,
          mtpRmks: remarks,
          mtpOrder: order,
          mtpEditedBy: userId,
          mtpEditedDate: dayjs().format(),
        },`Updated Maintenance Type to ${name} Successfully`
      );
      setRefresh(!refresh);

      const exprs: exprsTypes[] = [
        { expr: "updateDataOnclick", value: false },
        { expr: "successModalState", value: true },
        { expr: "successText", value: updateSuccessText },
      ];
      for (let i = 0; i < exprs.length; i++) {
        dispatch(
          setTier2CheckboxValue({ expr: exprs[i].expr, value: exprs[i].value })
        );
      }
    } catch (error: any) {
      setErrState(true);
      seterrTitle(updateModalData.error.title);
      seterrMessage(updateModalData.error.message);
    } finally {
      setBusyLoader("");
    }
  };

  //modals data
  const modals: modalTypes[] = [
    //warning
    {
      disableCancel: true,
      icon: "warning",
      open: triggerwarningModal,
      okHandler: () =>
        dispatch(setTier2CheckboxValue({ expr: "warningState", value: false })),
      cancelHandler: () =>
        dispatch(setTier2CheckboxValue({ expr: "warningState", value: false })),
      message: warningInfos[warningDataExpr!].message,
      cancelText: warningInfos[warningDataExpr!].cancelText,
      okText: warningInfos[warningDataExpr!].okText,
      title: warningInfos[warningDataExpr!].title,
    },
    //backend error
    {
      disableCancel: true,
      icon: "warning",
      open: errState,
      okHandler: () => setErrState(false),
      cancelHandler: () => setErrState(false),
      message: errMessage,
      cancelText: "",
      okText: "Ok",
      title: errTitle,
    },
    //success
    {
      disableCancel: true,
      icon: "success",
      open: triggerSuccessModal,
      okHandler: () =>
        dispatch(
          setTier2CheckboxValue({ expr: "successModalState", value: false })
        ),
      cancelHandler: () =>
        dispatch(
          setTier2CheckboxValue({ expr: "successModalState", value: false })
        ),
      message: SuccessModalText.message,
      okText: SuccessModalText.okText,
      title: SuccessModalText.title,
    },
    //save
    {
      disableCancel: false,
      icon: "question",
      open: triggersaveModal,
      okHandler: () => postData(),
      cancelHandler: () =>
        dispatch(
          setTier2CheckboxValue({ expr: "saveDataOnclick", value: false })
        ),
      message: saveData.message,
      cancelText: saveData.cancelText,
      okText: saveData.okText,
      title: saveData.title,
    },
    //Update
    {
      disableCancel: false,
      icon: "question",
      open: triggerupdateModal,
      okHandler: () => UpdateData(),
      cancelHandler: () =>
        dispatch(
          setTier2CheckboxValue({ expr: "updateDataOnclick", value: false })
        ),
      message: updateIndInfos.message,
      cancelText: updateIndInfos.cancelText,
      okText: updateIndInfos.okText,
      title: updateIndInfos.title,
    },
  ];

  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });

  const activeStatus = useSelector(
    (state: RootState) => state.general.activeStatus
  );
  const [status, setstatus] = useState<boolean | string>("");
  // data for datagrid
  const [data, dataError, dataLoading] = useFetch(
    `AsmTmsMaintenanceType/GetAllAsmTmsMaintenanceType?search=${searchText.text}&status=${status}`,
    refresh
  );
  useEffect(() => {
    if (data) {
      storeExprs(); // run func to store expressions on store
      dispatch(setGeneralValue({ expr: "idExpr", value: idExpr })); // dispatch fetched data to reduxstore
      dispatch(setFormData(data)); // dispatch fetched data to reduxstore
    }

    dataLoading ? setBusyLoader(".") : setBusyLoader("");
  }, [dataLoading, data]);

  // form parameters
  const form_header = "Maintenance Type Details";

  const checboxelist: checkboxTypes[] = [
    { id: 0, label: "PM" },
    { id: 1, label: "RoadWorthy?" },
    { id: 2, label: "Ins. Renewal" },
  ];

  return (
    <>
      {dataError && <WarningAlert />}

      {modals.map(
        (
          {
            disableCancel,
            icon,
            okHandler,
            cancelHandler,
            open,
            cancelText,
            okText,
            message,
            title,
          }: modalTypes,
          index
        ) => {
          return (
            <div key={index}>
              <ModalTemplate
                icon={icon && icon}
                disableCancel={disableCancel}
                cancelText={cancelText && cancelText}
                open={open}
                okHandler={okHandler}
                cancelHandler={cancelHandler}
                message={message}
                okText={okText}
                title={title}
              />
            </div>
          );
        }
      )}

      <Setups_Template
        formTier="tier2checkbox"
        checkboxlist={checboxelist}
        searchTextChange={(newText) => {
          setSearchText({ temp: newText, text: searchText.text });
        }}
        handleSearch={() => {
          setSearchText({ temp: searchText.temp, text: searchText.temp });
          setstatus(activeStatus);
          setRefresh((current) => !current);
        }}
        handleRefresh={() => {
          setSearchText({ temp: "", text: "" });
          setstatus("");
          setRefresh((current) => !current);
        
        }}
        labelCol={5}
        trigger={trigger}
        datagridColumns={assetsSetups_maintenancetypes_columns}
        datagridData={data}
        formHeader={form_header}
        updateData={validateDataUpdate}
        createData={validateDataSave}
      />
    </>
  );
});
