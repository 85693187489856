/* eslint-disable no-useless-computed-key */
/* eslint-disable react/jsx-pascal-case */
import { Select, Form, Input, Upload, Image } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useCallback, useEffect, useState } from "react";
import general from "../../../../../../../assets/docs.png";
import reset from "../../../../../../../assets/reset.png";
import reset2 from "../../../../../../../assets/reset2.png";
import closeGreyed from "../../../../../../../assets/closeGreyed.png";

import invalidImage from "../../../../../../../assets/none.jpg";
import closeRed from "../../../../../../../assets/close.png";
import {
  SaveButton,
  NewButton,
  Cancel,
  UpdateButton,
} from "../../../../../../accessories/buttons";
import {
  navbar_height,
  saveModalData,
  toolbar_height,
} from "../../../../../../accessories/component_infos";
import {
  vehicle_images_data,
  vehicle_images_TH,
} from "../data/vehicle-images-data";
import useFetch from "../../../../../../../hooks/useFetch";
import { Tooltip } from "devextreme-react";
import { RcFile } from "antd/es/upload";
import { ModalTemplate } from "../../../../../../../templates/modal";
import Datagrid_template from "../../../../../../../templates/Datagrid";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import { WarningAlert } from "../../../../../../accessories/warningAlert";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import { GetDataFunc } from "../../../../../../../functions/get";
import { DeleteDataFunc } from "../../../../../../../functions/delete";

import useResizeMode from "../../../../../../../hooks/useResizeMode";
import { fgridheight, gridWidth } from "../data/gridData";
import { useCrudFunc } from "../../../../../../../functions/crud";
import { useBusyLoader } from "../../../../../../../hooks/useBusyLoader";
import { UpdateStates } from "../../../../../../../functions/updateStates";

interface props {
  toggled?: boolean;
}
const VehicleImagesDetails = ({ toggled }: props) => {
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const heights_out = navbar_height + news_flash_height + toolbar_height;
  let [resizeMode, initialResizeMode] = useResizeMode(true);
  //varying form height
  //Table Height
  let tableHeight = resizeMode - (heights_out + 70); //height without navbar and toolbar
  //height without navbar and toolbar and form

  //Initial States
  const [allState, setAllState] = useState<any>({
    Id: { id: "" },
    vidIdpk: { id: "" },
    Name: { data: "" },
    Description: { data: "" },
    Order: { data: "" },
    Remarks: { data: "" },
    currentImageId: 0,
  });

  const [gridStates, setGridStates] = useState<any>({
    topGridSelected: {},
    downGridSelected: {},
  });
  const [busyLoader, setBusyLoader] = useBusyLoader();
  const [paging, setPaging] = useState<{
    pageNumber: number;
    pageSize: number;
  }>({
    pageNumber: 1, // backend having issue with their offset counting, it should start from 0 but they started with 1 instead
    pageSize: 15,
  });
  const [refresh, setRefresh] = useState<boolean>(false);
  const [refreshTopGrid, setRefreshTopGrid] = useState<boolean>(false);
  const resetField = () => {
    setAllState({
      ...allState,
      ["Name"]: { data: gridStates.topGridSelected.e?.vdtRegNo },
      ["Order"]: { data: "" },
      ["Description"]: { data: "" },
      ["Remarks"]: { data: "" },
    });
  };

  // Endpoints for records with images
  const [vehicle_Details_with_images, vehicle_error, vehicle_loading] =
    useFetch(
      `AssetTamVehicleImages/PopGdcAllImageVehiclesDeptAdmin?PageNumber=1&PageSize=${paging.pageSize}`,
      refreshTopGrid,
      "vdtIdpk"
    );

  const [PostDataFunc, UpdateDataFunc] = useCrudFunc();

  const [
    load_Vehicle_Details,
    load_Vehicle_Details_Error,
    load_Vehicle_Details_loading,
  ] = useFetch(
    `AssetTamVehicleImages/PopGdcVehiclesOrgAdmin?PageNumber=1&PageSize=${paging.pageSize}`,
    refreshTopGrid,
    "vdtIdpk"
  );

  const [image_data, setImageData] = useState<any>([]);

  const fetchImageData = async (id: any, order: any) => {
    try {
      const res = await GetDataFunc(
        `AssetTamVehicleImages/GetImages?vidVehicleDetailIDfk=${id}&vidIndex=${order}`
      );

      setImageData(res.data[0]);
    } catch (err: any) {
      setOpenModal(true);
      setModalData({
        message: err.message ? err.message : "Server Error",
        title: "Unexpected Error!",
      });
    }
  };

  useEffect(() => {
    if (image_data) {
      setAllState({ ...allState, ["currentImageId"]: image_data.vimIdpk });
      setImageUrl("");
    } else {
      setAllState({ ...allState, ["currentImageId"]: 0 });
    }
  }, [image_data]);

  // ModalData

  const finaldeleteModalData = {
    message: "Do you really want to proceed and Delete this record entirely",
    title: "Delete Image",
    okText: "Yes",
    cancelText: "No",
  };

  const [getId, setId] = useState<any>();

  const [imageDetails_data, imageDetails_error, dataloading] = useFetch(
    `AssetTamVehicleImages/PopGdvImageDetails/${getId}?PageNumber=1&PageSize=20`,
    refresh,
    "vidIdpk"
  );

  // For BrowseImage

  const [loading, setLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [vimImage, setVimImage] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [image, setImage] = useState<any>();
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [openModal, setOpenModal] = useState(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [icon, setIcon] = useState<"warning" | "success" | "question">(
    "warning"
  );
  const [disableForm, setDisableForm] = useState<boolean>(true);
  const [showUpdate, setShowUpdate] = useState<boolean>(false);
  const [showCancel, setShowCancel] = useState<boolean>(false);
  const [showSecondModal, setShowSecondModal] = useState<boolean>(false);

  const [Load_All_Vehicles_with_images, setLoad_All_Vehicles_with_images] =
    useState<boolean>(true);

  // converting to base64
  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const browseImage = (info: any) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }

    if (info.file.status === "done") {
      getBase64(info.file.originFileObj as RcFile, (url: string) => {
        setLoading(false);
        setImageUrl(url);
      });
    }

    //  work even without the done status
    getBase64(info.file.originFileObj as RcFile, (url: string) => {
      setLoading(false);
      setImageUrl(url);
      setFileName(info.file.name);

      setVimImage(url);
    });
  };

  const ValidateSave = () => {
    setIcon("warning");
    if (allState.Name.data === "" || allState.Name.data === undefined) {
      setDisabled(true);
      setOpenModal(true);
      setModalData({
        message: "Please name field cannot be empty",
        title: "Enter Name",
        okText: "Ok",
        cancelText: "No,Cancel",
      });
    } else if (
      allState.Order.data === "" ||
      allState.Order.data === undefined
    ) {
      setDisabled(true);
      setOpenModal(true);
      setModalData({
        message: "Please order field cannot be empty",
        title: "Enter Order",
        okText: "Ok",
        cancelText: "No,Cancel",
      });
    } else if (isNaN(Number(allState.Order.data))) {
      setDisabled(true);
      setOpenModal(true);
      setModalData({
        message: "Plase order field must be a number",
        title: "Invalid input",
        okText: "Ok",
        cancelText: "No,Cancel",
      });
    } else if (
      allState.Description.data === "" ||
      allState.Description.data === undefined
    ) {
      setDisabled(true);
      setOpenModal(true);
      setModalData({
        message: "Please description field cannot be empty",
        title: "Enter Description",
        okText: "Ok",
        cancelText: "No,Cancel",
      });
    } else if (imageUrl === "") {
      setOpenModal(true);
      setModalData({
        message: "Please upload an image for the vehicle",
        title: "Image required!",
        okText: "ok",
        cancelText: "No,Cancel",
      });
    } else {
      setDisabled(true);
      setIcon("question");
      setModalData({
        message: "Are you sure you want to save this record?",
        title: "Save record?",
        okText: "Yes, save",
        cancelText: "No, Cancel",
      });
      setOpenModal(true);
    }
  };

  const ValidateUpdate = () => {
    setIcon("warning");
    if (allState.Name.data === "") {
      setDisabled(true);
      setOpenModal(true);
      setModalData({
        message: "Name field cannot be empty",
        title: "Enter Name",
        okText: "ok",
        cancelText: "No,Cancel",
      });
    } else if (allState.Order.data === "") {
      setDisabled(true);
      setOpenModal(true);
      setModalData({
        message: "Order field cannot be empty",
        title: "Enter Order",
        okText: "ok",
        cancelText: "No,Cancel",
      });
    } else {
      setDisabled(true);
      setIcon("question");
      setModalData({
        message: "Are you sure you want to update this record?",
        title: "Update record?",
        okText: "Yes, update",
        cancelText: "No, Cancel",
      });
      setOpenModal(true);
    }
  };

  //Saving Im

  const saveData = async () => {
    setBusyLoader("Saving record....");
    await saveImage(allState.Id.id);

    await PostDataFunc(
      `AssetTamVehicleImages/CreateAssetTamVehicleImages`,
      {
        vidVehicleDetailIdfk: allState.Id.id,
        vidName: allState.Name.data,
        vidDescription: allState.Description.data,
        vidIndex: allState.Order.data,
        vidRmks: allState.Remarks.data,
        vidCreatedBy: userId,
      },
      `Created the TS Bills-Feeder-`
    )
      .then(() => {
        setDisableForm(true);
        setShowCancel(false);
        setDisabled(true);
        setBusyLoader("");
        setIcon("success");
        setModalData({
          message: "Record saved successfully",
          title: "Record saved!",
          okText: "ok",
          cancelText: "",
        });
        setOpenModal(true);

        setRefresh(!refresh);
      })
      .catch(() => {
        setDisabled(true);
        setIcon("warning");
        setBusyLoader("");
        setModalData({
          message: " Please Contact Your Administrator ",
          title: "Record not saved ",
          okText: "ok",
          cancelText: "",
        });
        setOpenModal(true);
      });
  };

  const UpdateData = async () => {
    try {
      setBusyLoader("Updating record...");

      await UpdateDataFunc(
        `AssetTamVehicleImages/UpdateAssetTamVehicleImages/${allState.Id.id}`,
        {
          vidIdpk: allState.vidIdpk?.id,
          vidVehicleDetailIdfk: allState.Id.id,
          vidName: allState.Name.data,
          vidDescription: allState.Description.data,
          vidIndex: allState.Order.data,
          vidRmks: allState.Remarks.data,
        }
      );
      await saveImage(allState.Id.id);
      setDisabled(true);
      setBusyLoader("");
      setIcon("success");
      setModalData({
        message: "Record updated successfully",
        title: "Record updated!",
        okText: "ok",
        cancelText: "",
      });
      setOpenModal(true);

      setRefresh(!refresh);

      // await DeleteRecord();
      // await saveData();

      //TODO: call ```setRefresh(!refresh)``` if table still doesn't refresh

      setRefresh(!refresh);
      setBusyLoader("");
    } catch (err: any) {
      setBusyLoader("");
      setModalData({
        message: err.message ? err.message : "Server Error",
        title: "Unexpected Error!",
        okText: "ok",
        cancelText: "",
      });
      setIcon("warning");
      setRefresh(!refresh);
    }
  };

  // Update starts here

  const deleteImage = async () => {
    try {
      setBusyLoader("Deleting vehicle image...");
      const res = await DeleteDataFunc(
        `AssetTamVehicleImages/DeleteCurrentImage?id=${allState.Id.id}&txtOrder=${allState.Order.data}`
      );

      if (res.status === 200) {
        setDisabled(true);
        setOpenModal(true);
        setBusyLoader("");
        setIcon("success");
        setModalData({
          message: "Image deleted duccessfully",
          title: "Image deleted",
          okText: "Ok",
          cancelText: "Cancel",
        });

        setRefresh(!refresh);
      }
    } catch (e: any) {
      setIcon("warning");
      setBusyLoader("");
      setOpenModal(true);
      setModalData({
        message: e.response ? e.response.data.message : "Server Error",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };

  const saveImage = async (a: number) => {
    try {
      setBusyLoader("Saving vehicle image...");
      const res = await PostDataFunc(
        `AssetTamVehicleImages/CreateSavedImages`,
        {
          vimVehicleDetailIDfk: a,
          vimImage: vimImage.split(",")[1],
          vimIndex: allState.Order.data,
          vimImageHeader: vimImage.split(",")[0],
          vimCreatedBy: userId,
        },
        `Updated image for vehicle with Reg No ${gridStates.topGridSelected.e?.vdtRegNo} for record with Image Details ID ${allState.vidIdpk?.id}`
      );

      if (res.status === 200) {
        setBusyLoader("");
        setIcon("success");
        setModalData({
          message: "Image saved succesfully",
          title: "Image saved",
          okText: "ok",
          cancelText: "",
        });
        setRefresh(!refresh);
      }
    } catch (e: any) {
      setBusyLoader("");
      setIcon("warning");
      setOpenModal(true);

      setModalData({
        message: e.response ? e.response.data.message : "Server Error",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };

  // Update ends here

  const deleteAll = {
    message:
      "Are you sure you want to Delete this vehicle and image entirely? Note that this action is not reversible.",
    title: "Delete entire vehicle and images?",
    okText: "Yes,Delete",
    cancelText: "No,Cancel",
  };

  const DeleteRecord = async () => {
    try {
      setBusyLoader("Deleting entire vehicle and images...");
      await DeleteDataFunc(
        `AssetTamVehicleImages/DeleteRecord/${allState.currentImageId}/${allState.vidIdpk.id}`
      );

      setShowSecondModal(true);
      setOpenModal(true);
      setDisabled(true);
      setBusyLoader("");
      setIcon("success");
      setModalData({
        message: "Vehicle and image deleted successfully",
        title: "vehicle and image deleted",
        okText: "Ok",
        cancelText: "Cancel",
      });
      setRefresh(!refresh);
      setRefreshTopGrid(!refreshTopGrid);
    } catch (e: any) {
      setBusyLoader("");
      setIcon("warning");
      setOpenModal(true);
      setModalData({
        message: e.response ? e.response.data.message : "Server Error",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };

  useEffect(() => {}, [toggled]);

  const [empID, userId, userObj] = useAccountInfo();

  const [privType, setPrivType] = useState<string>("save");

  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);

  const AccessPriv_on_NewButton = () => {
    setPrivType("save");
    if (savePriv === true) {
      setDisableForm(false);
      setShowCancel(true);
      resetField();
      setVimImage(invalidImage.split(",")[1]);
      setImageUrl("");
      setImageData([]);
    } else {
      setModalData({
        message: "No Access Privilege",
        title: "Access Denied!",
        okText: "Ok",
        cancelText: "No",
      });
      setIcon("warning");
      setDisabled(true);
      setOpenModal(true);
    }
  };

  const AccessPriv_on_saveButton = () => {
    setPrivType("save");

    if (savePriv === true) {
      ValidateSave();
    } else {
      setModalData({
        message: "No Access Privilege",
        title: "Access Denied!",
        okText: "Ok",
        cancelText: "No",
      });
      setIcon("warning");
      setDisabled(true);
      setOpenModal(true);
    }
  };

  const AccessPriv_on_UpdateButton = () => {
    setPrivType("update");
    if (updatePriv === true) {
      ValidateUpdate();
    } else {
      setModalData({
        message: "No Accokess Privilege",
        title: "Access Denied!",
        okText: "Ok",
        cancelText: "No",
      });

      setIcon("warning");
      setDisabled(true);
      setOpenModal(true);
    }
  };
  const AccessPriv_on_DeleteImageButton = () => {
    if (savePriv === true) {
      setModalData({
        message: "Are you sure you want to delete image for this vehicle?",
        title: "Delete vehicle image?",
        okText: "Delete",
        cancelText: "No",
      });
      setOpenModal(true);
      setIcon("question");
    } else {
      setModalData({
        message: "No Access Privilege",
        title: "Access Denied!",
        okText: "Ok",
        cancelText: "No",
      });

      setIcon("warning");
      setDisabled(true);
      setOpenModal(true);
    }
  };

  const AccessPriv_on_DeleteRecordButton = () => {
    if (savePriv === true) {
      setIcon("question");
      setModalData(deleteAll);
      setOpenModal(true);
      setDisabled(true);

      if (modalData.title === deleteAll.title) {
        setIcon("question");
        setOpenModal(true);
      }
    } else {
      setModalData({
        message: "No Access Privilege",
        title: "Access Denied!",
        okText: "Ok",
        cancelText: "No",
      });
      setIcon("warning");
      setDisabled(true);
      setOpenModal(true);
    }
  };

  const AccessPriv_onRowGridClick = useCallback(async (e: any) => {
    setDisableForm(true);
    setShowUpdate(false);
    setShowCancel(false);
    setId(e.vdtIdpk);
    setAllState({ ...allState, ["Id"]: { id: e.vdtIdpk } });
    setGridStates({ ...gridStates, ["topGridSelected"]: { e } });
  }, []);

  const AccessPriv_on_BrowseButton = (e: any) => {
    if (savePriv === true) {
      browseImage(e);
    } else {
      setModalData({
        message: "No Access Privilege",
        title: "Access Denied!",
        okText: "Ok",
        cancelText: "No",
      });
      setIcon("warning");
      setDisabled(true);
      setOpenModal(true);
    }
  };
  const AccessPriv_on_DataGridDoubleClick = () => {
    setPrivType("read");
    if (savePriv === true) {
      setShowUpdate(true);
      setDisableForm(false);
      setShowCancel(true);
    } else {
      setModalData({
        message: "No Access Privilege",
        title: "Access Denied!",
        okText: "Ok",
        cancelText: "No",
      });
      setIcon("warning");
      setDisabled(true);
      setOpenModal(true);
    }
  };

  const Second_AccessPriv_onRowDataGridClick = useCallback(async (e: any) => {
    setDisableForm(true);
    setShowUpdate(false);
    setShowCancel(false);
    setAllState({
      ...allState,
      ["Id"]: { id: e.vidVehicleDetailIdfk },
      ["Name"]: { data: e.vidName },
      ["Description"]: { data: e.vidDescription },
      ["Remarks"]: { data: e.vidRmks },
      ["Order"]: { data: e.vidIndex },
      ["vidIdpk"]: { id: e.vidIdpk },
    });
    await fetchImageData(e.vidVehicleDetailIdfk, e.vidIndex);
    setGridStates({ ...gridStates, ["downGridSelected"]: { e } });
  }, []);

  return (
    <>
      {vehicle_error && imageDetails_error && <WarningAlert />}
      <div>
        {/* all modals */}
        {/* modals */}

        <ModalTemplate
          title={modalData.title}
          icon={icon}
          cancelHandler={() => {
            setOpenModal(false);
          }}
          open={openModal}
          cancelText={modalData.cancelText}
          okText={modalData.okText}
          message={modalData.message}
          okHandler={() => {
            modalData.title === "Save record?"
              ? saveData()
              : modalData.title === "Update record?"
              ? UpdateData() //TODO: use create instead
              : modalData.title === "Delete entire vehicle and images?"
              ? DeleteRecord()
              : modalData.title === "Delete vehicle image?"
              ? deleteImage()
              : setOpenModal(false);
          }}
          disableCancel={icon === "question" ? false : true}
        />

        <style
          dangerouslySetInnerHTML={{
            __html: `
           input[type="text"]:disabled {
            background: #fffbeb;
            height:22px
          }  

          
          
          .dx-datagrid-header-panel .dx-toolbar {
            margin-bottom: 1.5px;
        }

          .filterTodos .dx-datagrid-headers{
              margin-top: 10px;
          }

            `,
          }}
        />
        <div className="w-full h-full flex">
          <div className="mx-1">
            <ul className="w-full flex justify-end p-1 px-2">
              <span
                id="vi"
                onClick={() => {
                  setLoad_All_Vehicles_with_images(false);
                }}
                style={{
                  height: 22.5,
                  marginBottom: 2,
                  borderWidth: 1,
                  width: 24,
                  borderColor: borderTheme,
                }}
                className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
              >
                <img
                  className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                  src={reset}
                  alt="reset"
                />
                <Tooltip
                  target="#vi"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p>Load all vehicles</p>
                </Tooltip>
              </span>
              <span
                id="allvi"
                onClick={() => {
                  setLoad_All_Vehicles_with_images(true);
                }}
                style={{
                  height: 22.5,
                  marginBottom: 2,
                  borderWidth: 1,
                  width: 24,
                  borderColor: borderTheme,
                }}
                className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
              >
                <img
                  className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                  src={reset2}
                  alt="reset"
                />
                <Tooltip
                  target="#allvi"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p>Load all vehicles with images</p>
                </Tooltip>
              </span>
            </ul>
            <div className="pt-0.5 mx-1">
              <Datagrid_template
                handlePageSize={(e: number) => {
                  UpdateStates("pageSize", e, setPaging);
                }}
                handlePageNumber={(e: number) => {
                  UpdateStates("pageNumber", e, setPaging);
                }}
                pageSize={paging.pageSize}
                pageNumber={paging.pageNumber}
                gridheight={fgridheight}
                columns={vehicle_images_TH}
                data={
                  Load_All_Vehicles_with_images
                    ? vehicle_Details_with_images
                    : load_Vehicle_Details
                }
                gridwidth={gridWidth}
                disablePaging={false}
                searchWidth={150}
                onRowClick={AccessPriv_onRowGridClick}
              />
            </div>

            <div className="pt-0.5 mx-1">
              <Datagrid_template
                gridheight={tableHeight - 280}
                columns={vehicle_images_data}
                data={imageDetails_data}
                gridwidth={gridWidth}
                disableGroupPanel={true}
                disablePaging={false}
                disableSearch={true}
                onRowClick={Second_AccessPriv_onRowDataGridClick}
                rowDoubleClicked={() => {
                  AccessPriv_on_DataGridDoubleClick();
                }}
              />
            </div>
          </div>

          {/* Here */}
          <div className=" w-full p-1 px-5">
            <Form
              name="complex-form"
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 20 }}
              size={"small"}
              className="border rounded mb-2"
              style={{ width: "100%", borderColor: borderTheme }}
            >
              <Form.Item
                label={<p className="text-xs">{"Name"}</p>}
                style={{ marginBottom: "3px", marginTop: "1%" }}
              >
                <Form.Item
                  rules={[{ required: true }]}
                  style={{
                    display: "inline-block",
                    width: "calc(60%)",
                    marginRight: "3%",
                  }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <Input
                      className="h-[27px]"
                      size="small"
                      placeholder={"Input Text"}
                      value={allState.Name.data}
                      onChange={(e: any) => {
                        setAllState({
                          ...allState,
                          ["Name"]: { data: e.target.value },
                        });
                      }}
                      disabled={disableForm}
                    />
                  </div>
                </Form.Item>

                <Form.Item
                  label={<p className="text-xs">{"Order"}</p>}
                  style={{ display: "inline-block", width: "calc(37%)" }}
                >
                  {" "}
                  <div className="flex flex-row items-center">
                    <Input
                      className="h-[27px]"
                      // style={{ height: "50%" }}
                      id="order"
                      // size="middle"
                      placeholder=""
                      value={allState.Order.data}
                      onChange={(e: any) => {
                        setAllState({
                          ...allState,
                          ["Order"]: { data: e.target.value },
                        });
                      }}
                      disabled={disableForm}
                    />
                  </div>
                </Form.Item>
              </Form.Item>

              <Form.Item
                label={<p className="text-xs">{"Description"}</p>}
                style={{ marginBottom: 0, marginTop: "0.2%" }}
              >
                <Form.Item
                  style={{
                    display: "inline-block",
                    marginBottom: "1px",
                    width: "calc(100%)",
                  }}
                  className=" mb-1 mr-2 "
                >
                  <TextArea
                    style={{
                      resize: "none",
                      maxHeight: "60px",
                      minHeight: "60px",
                      height: "60px",
                      width: "",
                    }}
                    className="o  overflow-y-scroll resize-none"
                    rows={3}
                    value={allState.Description.data}
                    onChange={(e: any) => {
                      setAllState({
                        ...allState,
                        ["Description"]: { data: e.target.value },
                      });
                    }}
                    disabled={disableForm}
                  />
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={<p className="text-xs">{"Remarks"}</p>}
                style={{ marginBottom: 0, marginTop: "" }}
              >
                <Form.Item
                  style={{
                    display: "inline-block",
                    marginBottom: "1px",
                    width: "calc(100%)",
                  }}
                  className=" mb-1 mr-2 "
                >
                  <TextArea
                    style={{
                      resize: "none",
                      maxHeight: "60px",
                      minHeight: "60px",
                      height: "60px",
                      width: "",
                    }}
                    className="o  overflow-y-scroll resize-none"
                    rows={3}
                    value={allState.Remarks.data}
                    onChange={(e: any) => {
                      setAllState({
                        ...allState,
                        ["Remarks"]: { data: e.target.value },
                      });
                    }}
                    disabled={disableForm}
                  />
                </Form.Item>
              </Form.Item>

              <Form.Item
                colon={false}
                label={<p className="hidden text-xs">{""}</p>}
              >
                <div
                  className=" flex w-full flex-row justify-between "
                  style={{ marginBottom: "1px", width: "calc(100%)" }}
                >
                  <ul className="flex w-1/2 mb-1">
                    <div
                      id="browse"
                      style={{ borderColor: borderTheme }}
                      className="dark:bg-slate-900 cursor-pointer dark:text-darkModeSkin-base flex flex-row text-xs items-center justify-center  mr-1 px-2  border-2 border-slate-200 rounded"
                    >
                      <img alt="" className="pr-1 h-4" src={general} />
                      <Upload
                        showUploadList={false}
                        name="browse"
                        onChange={(e) => {
                          AccessPriv_on_BrowseButton(e);
                        }}
                      >
                        Browse
                      </Upload>

                      <Tooltip
                        target="#browse"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        hideOnOutsideClick={true}
                      >
                        <p>Browse for image</p>
                      </Tooltip>
                    </div>

                    <div
                      id="deleteOneImage"
                      style={{ borderColor: borderTheme }}
                      className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row text-xs items-center justify-center  mr-1 px-2  border-2 border-slate-200 rounded hover:cursor-pointer"
                      onClick={() => {
                        AccessPriv_on_DeleteImageButton();
                      }}
                    >
                      <img alt="" className=" h-4" src={closeGreyed} />
                      <Tooltip
                        target="#deleteOneImage"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        hideOnOutsideClick={true}
                      >
                        <p>Delete current image only</p>
                      </Tooltip>
                    </div>
                    <div
                      id="deleteAll"
                      style={{ borderColor: borderTheme }}
                      className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row text-xs items-center justify-center  mr-1 px-2  border-2 border-slate-200 rounded hover:cursor-pointer"
                      onClick={() => {
                        AccessPriv_on_DeleteRecordButton();
                      }}
                    >
                      <img alt="" className=" h-4" src={closeRed} />
                      <Tooltip
                        target="#deleteAll"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        hideOnOutsideClick={true}
                      >
                        <p>Delete entire record and image</p>
                      </Tooltip>
                    </div>
                  </ul>
                  <ul className="flex justify-end  mb-1">
                    {showUpdate === true ? (
                      <UpdateButton
                        useCallbackFunc={true}
                        handleUpdate={() => {
                          AccessPriv_on_UpdateButton();
                        }}
                      />
                    ) : (
                      <SaveButton
                        disableButton={disableForm}
                        useCallbackFunc={true}
                        handleSave={() => {
                          AccessPriv_on_saveButton();
                        }}
                      />
                    )}

                    {showCancel === false ? (
                      <NewButton
                        useCallbackFunc={true}
                        new_button_toggler={() => {
                          AccessPriv_on_NewButton();
                        }}
                      />
                    ) : (
                      <Cancel
                        useCallbackFunc={true}
                        handleCallbackCancel={() => {
                          setDisableForm(true);
                        }}
                        cancel_button_toggler={() => {
                          setShowCancel(false);
                          setDisableForm(true);
                          setShowUpdate(false);
                        }}
                      />
                    )}
                  </ul>
                </div>
              </Form.Item>
            </Form>

            <div
              className="w-full border rounded flex justify-center  items-center h-[67%] "
              style={{ width: "100%", borderColor: borderTheme }}
            >
              {
                <Image
                  style={{ width: "800px" }}
                  height={tableHeight - 335}
                  className=" w-full h-full bg-slate-300"
                  src={
                    imageUrl !== ""
                      ? imageUrl
                      : image_data?.vimImage?.slice(0, 10) === ""
                      ? `${image_data?.vimImage}`
                      : `${image_data?.vimImageHeader},${image_data?.vimImage}`
                  }
                />
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VehicleImagesDetails;
