/* eslint-disable react/jsx-pascal-case */
import { forwardRef, useRef, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  currentDate,
  navbar_height,
  startingDate,
} from "../../../../../accessories/component_infos";
import CustomLoader from "../../../../../accessories/custom-loader";
import { HrSetupsCustomToolbar } from "../../../../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import { driverCodeAsignmentsColumns } from "./data/driverCodeAssignmentsColumns";
import DriverCodeAssignmentsForm from "./widgets/driverCodeAssgnForm";
import dayjs from "dayjs";
import Datagrid_template from "../../../../../../templates/Datagrid";
import { useDispatch } from "react-redux";
import useFetch from "../../../../../../hooks/useFetch";
import { WarningAlert } from "../../../../../accessories/warningAlert";
import useResizeMode from "../../../../../../hooks/useResizeMode";
import {
  searchCriteriaTypes,
  searchTextTypes,
  statusTypes,
} from "../../../../../human-resource/setups/data/types/selectsTypes";
import { setGeneralValue } from "../../../../../../features/generalSlice";

type state_Types = {
  selectedId: number;
  serviceProvider: { name: string; id: number };
  driverCode: string;
  driver: { name: string; id: number };
  assignmentDate: any;
  endDate: any;
  remarks: string;
  unassignedCodes: { name: string; id: number };
  activeState: boolean;
  disableForms: boolean;
};

export const DriverCodeAssignments = forwardRef(({ a }: any, ref) => {
  const setupPageRoute = window.location.pathname;
  const datagridOnlySearchParam = "table-only";
  const formModeSearchParam = "form-and-table";
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const miscHeight = 7;

  const [form_height, set_form_height] = useState<number>(258);

  const toggle_staff_form = (): void => {
    const view = searchParams.get("view");

    view === null || view === formModeSearchParam
      ? navigate({
          pathname: setupPageRoute,
          search: createSearchParams({
            view: datagridOnlySearchParam,
          }).toString(),
        })
      : navigate({
          pathname: setupPageRoute,
          search: createSearchParams({
            view: formModeSearchParam,
          }).toString(),
        });
  };

  const [resizeMode, initialResizeMode] = useResizeMode(
    searchParams.get("view") === formModeSearchParam ||
      searchParams.get("view") === null
  );
  let pageHeight = resizeMode - (navbar_height + miscHeight + 360);
  let tableHeight = initialResizeMode - 198;

  const dispatch = useDispatch();

  const [refreshstate, setRefreshstate] = useState<boolean>(false);
  const [warningModal, setWarningModal] = useState<boolean>(false);
  const [warningModalData, setWarningModalData] = useState<any>({
    title: "",
    message: "",
  });

  const [currentStates, setCurrentStates] = useState<state_Types>({
    selectedId: 0,
    serviceProvider: { name: "", id: 0 },
    driverCode: "",
    driver: { name: "", id: 0 },
    assignmentDate: startingDate,
    endDate: currentDate,
    remarks: "",
    unassignedCodes: { name: "", id: 0 },
    activeState: false,
    disableForms: true,
  });

  const genFormat = "YYYY-MM-DD";
  const today = dayjs().endOf("month").format(genFormat);
  const initialStart = dayjs("2019-02-01").format(genFormat);

  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });
  const [active, setActive] = useState<statusTypes>({
    temp: "",
    status: "",
  });
  const [searchCriteria, setsearchCriteria] = useState<searchCriteriaTypes>({
    temp: 0,
    index: 0,
  });

  const [startDate, setStartDate] = useState<searchTextTypes>({
    text: initialStart,
    temp: initialStart,
  });
  const [endDate, setEndDate] = useState<searchTextTypes>({
    text: today,
    temp: today,
  });

  // End

  const [datagridData, datagridError, datagridLoading] = useFetch(
    `AsmTmsEntDriverCodeAssigment/GetAllDriverCodeAssignmentByCode?startdate=${startDate.text}&enddate=${endDate.text}&SearchText=${searchText.text}&IsActive=${active.status}`,
    refreshstate
  );

  // Duplicate Drivers Code
  const [GetDuplicateCodes, GetDuplicateCodesError, GetDuplicateCodesLoading] =
    useFetch(`AsmTmsEntDriverCodeAssigment/GetDuplicateDriversCodes`);

  const [selectedRecord, setSelectedRecord] = useState({});

  const childRef = useRef<any>();

  const handleEdit = () => {
    if (Object.keys(selectedRecord).length === 0) {
      setWarningModal(true);
      setWarningModalData({
        title: "Select record",
        message: "Please select a record to edit",
      });
      return;
    }
    dispatch(setGeneralValue({ expr: "updateMode", value: true }));
    dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
  };

  return (
    <>
      {datagridLoading && <CustomLoader text={"Fetching Data"} />}
      {datagridError && <WarningAlert />}
      <div className="h-full">
        <div className="px-2">
          <HrSetupsCustomToolbar
            setCurrentStates={() => {
              setCurrentStates({
                selectedId: 0,
                serviceProvider: { name: "", id: 0 },
                driverCode: "",
                driver: { name: "", id: 0 },
                assignmentDate: startingDate,
                endDate: currentDate,
                remarks: "",
                unassignedCodes: { name: "", id: 0 },
                activeState: false,
                disableForms: false,
              });
            }}
            checkOnChange={(check) => {
              setActive({ temp: check, status: active.status });
            }}
            startDateChanged={(date) =>
              setStartDate({
                temp: dayjs(date).format(genFormat),
                text: startDate.text,
              })
            }
            endDateChanged={(date) =>
              setEndDate({
                temp: dayjs(date).format(genFormat),
                text: endDate.text,
              })
            }
            searchTextOnchange={(searchValue) => {
              setSearchText({ temp: searchValue, text: searchText.text });
            }}
            searchCriteriaOnchange={(newIndex, criteria) => {
              setsearchCriteria({
                temp: newIndex,
                index: searchCriteria.index,
              });
            }}
            handleUpdate={() => handleEdit}
            handleFind={() => {
              setStartDate({ text: startDate.temp, temp: startDate.temp });
              setEndDate({ text: endDate.temp, temp: endDate.temp });
              setSearchText({ temp: searchText.temp, text: searchText.temp });
              setActive({
                temp: active.temp,
                status: active.temp,
              });
              setsearchCriteria({
                temp: searchCriteria.temp,
                index: searchCriteria.temp,
              });
            }}
            handleNew={() => {
              if (childRef.current && childRef.current.singleClick) {
                childRef.current.singleClick({});
              }
              setSelectedRecord({});
              dispatch(setGeneralValue({ expr: "updateMode", value: false }));
              dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
            }}
            toggler={toggle_staff_form}
            useCallBackFunc={true}
            startDate={startDate.temp}
            endDate={endDate.temp}
            selected_Record={selectedRecord}
            setSelected_Record={setSelectedRecord}
            handleRefresh={() => {
              setRefreshstate(!refreshstate);
              setSearchText({ temp: "", text: "" });
              setActive({ temp: "", status: "" });
              setsearchCriteria({ temp: 0, index: 0 });
              setStartDate({ text: initialStart, temp: initialStart });
              setEndDate({ text: today, temp: today });
            }}
          />
        </div>

        {searchParams.get("view") !== datagridOnlySearchParam && (
          <section style={{ height: form_height }} className=" form border-b-4">
            <DriverCodeAssignmentsForm
              ref={childRef}
              refreshState={() => setRefreshstate(!refreshstate)}
              currentStates={currentStates}
              setCurrentStates={setCurrentStates}
            />
          </section>
        )}

        <section className=" px-2">
          <Datagrid_template
            rowDoubleClicked={(e) => {
              if (childRef.current) {
                childRef.current.doubleClicked(e);
              }
              setSelectedRecord(e);
            }}
            onRowClick={(e: any) => {
              if (childRef.current) {
                childRef.current.singleClick(e);
              }
              setSelectedRecord(e);
            }}
            gridheight={
              searchParams.get("view") === formModeSearchParam ||
              searchParams.get("view") === null
                ? pageHeight - 40
                : tableHeight - 40
            }
            data={datagridData}
            columns={driverCodeAsignmentsColumns}
          />
        </section>

        <div className="w-full justify-center h-24">
          <div className="w-full flex h-9 border-b-4 mb-60">
            <div className="border w-[33.3%]"></div>
            <div className="border w-[33.3%] text-indigo-600 text-center font-medium items-center justify-center">{`Duplicate Driver's Code Count: ${GetDuplicateCodes.length}`}</div>
            <div className="border w-[33.3%]"></div>
          </div>
        </div>
        <div className="w-full  mb-60 justify-center"></div>
      </div>
      <div className="px-2 mb-0.5 bg-black "></div>
    </>
  );
});
