export const passportMini = [
  {
    id: 0,
    caption: "Date",
    dataField: "pphDate",
    dataType: "datetime",
    alignment: "left",
    fixed: true,
    width: 120,
  },
  {
    id: 1,
    caption: "Active?",
    dataField: "pphActive",
    dataType: "boolean",
    alignment: "left",
    fixed: true,
    width: 40,
  },
];

export const passport_footer_cols = [
  {
    id: 0,
    caption: "STAFF No",
    dataField: "empIDpk",
    alignment: "left",
    dataType: "",
    fixed: true,
    width: 100,
  },
  {
    id: 1,
    caption: "STAFF NAME",
    dataField: "empName",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 2,
    caption: "DEPT",
    dataField: "dptShtName",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 3,
    caption: "SECTION",
    dataField: "sxnShtName",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 4,
    caption: "UNIT",
    dataField: "untShtName",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 5,
    caption: "JOB TITLE",
    dataField: "jbtShtName",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 6,
    caption: "LOCATION",
    dataField: "locShtName",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 7,
    caption: "STAFF?",
    dataField: "empIsStaff",
    alignment: "left",
    dataType: "boolean",
    fixed: true,
    width: 200,
  },
  {
    id: 8,
    caption: "ACTIVE?",
    dataField: "empActive",
    alignment: "left",
    dataType: "boolean",
    fixed: true,
    width: 200,
  },
  {
    id: 9,
    caption: "DoB",
    dataField: "empDoB",
    alignment: "left",
    dataType: "datetime",
    fixed: true,
    width: 200,
  },
  {
    id: 10,
    caption: "AGE",
    dataField: "age",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 11,
    caption: "HIRE DATE",
    dataField: "empHireDate",
    alignment: "left",
    dataType: "datetime",
    fixed: true,
    width: 200,
  },
  {
    id: 12,
    caption: "RETIRE DATE",
    dataField: "retireDate",
    alignment: "left",
    dataType: "datetime",
    fixed: true,
    width: 200,
  },
  {
    id: 13,
    caption: "TENURE (YRS)",
    dataField: "serviceLenght",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 14,
    caption: "TENURE LEFT",
    dataField: "tenureLeft",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 15,
    caption: "PROMO DATE",
    dataField: "empPromoDate",
    alignment: "left",
    dataType: "datetime",
    fixed: true,
    width: 200,
  },
  {
    id: 16,
    caption: "GRADE",
    dataField: "fgrdShtName",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 17,
    caption: "STEP",
    dataField: "fstpShtName",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 18,
    caption: "SUP STF. No",
    dataField: "supStaffNo",
    alignment: "left",
    dataType: "",
    fixed: true,
    width: 200,
  },
  {
    id: 19,
    caption: "SUPERVISOR",
    dataField: "supName",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 20,
    caption: "UNIT HEAD",
    dataField: "headEmpName",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 21,
    caption: "MANAGER",
    dataField: "mgrEmpName",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 22,
    caption: "DIRECTOR",
    dataField: "dirEmpName",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 23,
    caption: "OFFICE LOC",
    dataField: "fofcShtName",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 24,
    caption: "ROOM No",
    dataField: "fromName",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 25,
    caption: "COST CENTER",
    dataField: "empCostCenter",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 26,
    caption: "SOC. SEC No",
    dataField: "empSocSecNo",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 27,
    caption: "NATIONAL ID No",
    dataField: "empNationalID",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 28,
    caption: "ASSIGN. STATUS",
    dataField: "astShtName",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 29,
    caption: "APPRAISE GRP",
    dataField: "apgShtName",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 30,
    caption: "ASSIGN CAT",
    dataField: "ascShtName",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 31,
    caption: "JOB",
    dataField: "fjobName",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 32,
    caption: "JOB LEVEL",
    dataField: "jlvShtName",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 33,
    caption: "MARITAL ST.",
    dataField: "mtsName",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 34,
    caption: "PERSON TYPE",
    dataField: "pstName",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 35,
    caption: "EMP. GROUP",
    dataField: "egpName",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 36,
    caption: "GENDER",
    dataField: "gndName",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  //{id: 37, caption : "PROMO DATE", dataField : 'empPromoDate', alignment:'left', dataType : "datetime", fixed : true,width:200},
  {
    id: 38,
    caption: "ADDRESS",
    dataField: "empAddress",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 39,
    caption: "INTERCOM/INTERCOM",
    dataField: "empIntercom",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 40,
    caption: "MOBILE No",
    dataField: "empMobNo",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 41,
    caption: "TELEPHONE No",
    dataField: "empDirTelNo",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 42,
    caption: "OFFICE EMAIL",
    dataField: "empOfficialEmail",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 43,
    caption: "PERSONAL EMAIL",
    dataField: "empPersonalEmail",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 44,
    caption: "NATIONALITY",
    dataField: "ctyNationality",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 45,
    caption: "HOME REGION",
    dataField: "",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 46,
    caption: "HOME DISTRICT",
    dataField: "rgnName",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 47,
    caption: "HOME TOWN",
    dataField: "dstName",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 48,
    caption: "LINKEDIN",
    dataField: "twnName",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 49,
    caption: "SKYPE",
    dataField: "",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 50,
    caption: "TWITTER",
    dataField: "",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 51,
    caption: "FACEBOOK",
    dataField: "",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 200,
  },
  {
    id: 52,
    caption: "SUP?",
    dataField: "empIsSupervisor",
    alignment: "left",
    dataType: "boolean",
    fixed: true,
    width: 60,
  },
  {
    id: 53,
    caption: "MGR?",
    dataField: "empIsManager",
    alignment: "left",
    dataType: "boolean",
    fixed: true,
    width: 60,
  },
  {
    id: 54,
    caption: "DIR?",
    dataField: "empIsDirector",
    alignment: "left",
    dataType: "boolean",
    fixed: true,
    width: 60,
  },
  {
    id: 55,
    caption: "SNR?",
    dataField: "empIsSeniorStaff",
    alignment: "left",
    dataType: "boolean",
    fixed: true,
    width: 60,
  },
  {
    id: 56,
    caption: "JNR?",
    dataField: "empIsJuniorStaff",
    alignment: "left",
    dataType: "boolean",
    fixed: true,
    width: 60,
  },
  {
    id: 57,
    caption: "CEO?",
    dataField: "empIsCeo",
    alignment: "left",
    dataType: "boolean",
    fixed: true,
    width: 60,
  },
  {
    id: 58,
    caption: "MGMT?",
    dataField: "empIsManagementStaff",
    alignment: "left",
    dataType: "boolean",
    fixed: true,
    width: 60,
  },
  {
    id: 59,
    caption: "DEPT ADMIN?",
    dataField: "empIsDeptAdministrator",
    alignment: "left",
    dataType: "boolean",
    fixed: true,
    width: 60,
  },
  {
    id: 60,
    caption: "DEPT SEC?",
    dataField: "empIsDeptSecretary",
    alignment: "left",
    dataType: "boolean",
    fixed: true,
    width: 60,
  },
  {
    id: 61,
    caption: "SEC'N ADMIN?",
    dataField: "empIsSectionalAdministrator",
    alignment: "left",
    dataType: "boolean",
    fixed: true,
    width: 60,
  },
  {
    id: 62,
    caption: "SEC'N SEC?",
    dataField: "empIsSectionSecretary",
    alignment: "left",
    dataType: "boolean",
    fixed: true,
    width: 60,
  },
  {
    id: 63,
    caption: "BOARD MEMB?",
    dataField: "empIsBoardMember",
    alignment: "left",
    dataType: "boolean",
    fixed: true,
    width: 60,
  },
  {
    id: 64,
    caption: "CASUAL?",
    dataField: "empIsCasualStaff",
    alignment: "left",
    dataType: "boolean",
    fixed: true,
    width: 60,
  },
  {
    id: 65,
    caption: "CORE STAFF?",
    dataField: "empIsCoreStaff",
    alignment: "left",
    dataType: "boolean",
    fixed: true,
    width: 60,
  },
  {
    id: 66,
    caption: "TECH. STAFF?",
    dataField: "empIsTechnicalStaff",
    alignment: "left",
    dataType: "boolean",
    fixed: true,
    width: 60,
  },
  {
    id: 67,
    caption: "NSP?",
    dataField: "empIsServicePerson",
    alignment: "left",
    dataType: "boolean",
    fixed: true,
    width: 60,
  },
  {
    id: 68,
    caption: "VOLUNTARY?",
    dataField: "empIsVoluntaryPerson",
    alignment: "left",
    dataType: "boolean",
    fixed: true,
    width: 60,
  },
  {
    id: 69,
    caption: "REMARKS",
    dataField: "empRmks",
    alignment: "left",
    dataType: "datetime",
    fixed: true,
    width: 40000,
  },
];
