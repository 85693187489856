import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncGet, GetDataFunc } from "../../functions/get";


interface apiStates {
    reSchPlanData: any | null;
    mainTypeData: any | null;
    isLoading: boolean;
    isPending: boolean;
    isError: boolean;
}
var initialState: apiStates = {
    reSchPlanData: [],
    mainTypeData: [],
    isLoading: false,
    isPending: false,
    isError: false,
}
//fetch Reschedule Plan
export const fetchReSchPlan = createAsyncThunk<apiStates, {search: string, pagenumber: number, pagesize: number}, { rejectValue: string }>(
    "reSchPlan/fetch",
    async ({search, pagenumber, pagesize}) => {
        const res = await AsyncGet(`AsmTsmEntMaintenanceSchedule/PopCmbMaintTypes`, {
            pagenumber,
            pagesize,
            search
        });
        return res?.data;
    }
);

export const fetchMainType = createAsyncThunk<apiStates, {search: string, pagenumber: number, pagesize: number}, { rejectValue: string }>(
    "mainType/fetch",
    async ({search, pagenumber, pagesize}) => {
        const res = await AsyncGet(`AsmTsmEntMaintenanceSchedule/PopCmbMaintTypes`, {
            pagenumber,
            pagesize,
            search
        });
        return res?.data;
    }
);
const maintSchApi = createSlice({
    name: 'maintenanceScheduleApi',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // fetchReSchPlan
            .addCase(fetchReSchPlan.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchReSchPlan.fulfilled, (state, action) => {
                state.isLoading = false;
                state.reSchPlanData = action.payload;
            })
            .addCase(fetchReSchPlan.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            // fetchMainType
            .addCase(fetchMainType.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchMainType.fulfilled, (state, action) => {
                state.isLoading = false;
                state.mainTypeData = action.payload;
            })
            .addCase(fetchMainType.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
    }})
    export default maintSchApi.reducer;