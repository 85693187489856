/* eslint-disable react/jsx-pascal-case */
import { Form, Tooltip } from "antd";
import { useState, memo } from "react";
import refresh from "../../../../../../../assets/reset.png";
import Datagrid_template from "../../../../../../../templates/Datagrid";
import { DateTemplate } from "../../../../../../../templates/date";
import useFetch from "../../../../../../../hooks/useFetch";
import { stationsColumns } from "../data/data";
import {
  dbDateFormat,
  saveModalData,
} from "../../../../../../accessories/component_infos";
import { ModalTemplate } from "../../../../../../../templates/modal";
import {
  modalPropTypes,
  modalTypes,
  selectObjectTypes,
  validateInstanceTypes,
} from "../../../../../../human-resource/setups/data/types/selectsTypes";
import { InputsTemplate } from "../../../../../../../templates/input";
import { FaBinoculars } from "react-icons/fa";

import dayjs, { Dayjs } from "dayjs";
import { SelectsTemplate } from "../../../../../../../templates/select";
import { SaveButton } from "../../../../../../accessories/buttons";
import { TextareaTemplate } from "../../../../../../../templates/textarea";
import { useDispatch } from "react-redux";
import { setGeneralValue } from "../../../../../../../features/generalSlice";
import { GetDataFunc } from "../../../../../../../functions/get";
import { PostDataFunc  } from "../../../../../../../functions/post";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import { UpdateDataFunc } from "../../../../../../../functions/update";
import { useCrudFunc } from "../../../../../../../functions/crud";

interface props {
  busyloader: (text?: string) => void;
  refreshGrid: () => void;
  clearSelected: () => void;
  selectedField: any;
}
interface stateTypes {
  serviceProvider: selectObjectTypes;
  serviceStations: any;
  product: selectObjectTypes;
  date: string;
  dbDate: Dayjs | string;

  Uom: selectObjectTypes;
  currency: selectObjectTypes;
  unitPrice?: number;
  selectedStations: any;
  remark: string;
  serviceStationSearchText: string;
  serviceStationSearchTemp: string;

  validationModal: modalPropTypes;
  successModal: modalPropTypes;
  confirmModal: modalPropTypes;

  //refresh states
  serviceProvideRefresh: boolean;
  productRefresh: boolean;
  uomRefresh: boolean;
  currencyRefresh: boolean;
}

export const BulkProductPricesForm = memo(
  ({ busyloader, refreshGrid, clearSelected, selectedField }: props) => {
    const [employeeId, userId] = useAccountInfo();
    const dispatch = useDispatch();
    const [posting , updating]=useCrudFunc()
    const borderTheme = useSelector(
      (state: RootState) => state.general.cssBorderClass
    );

    const [states, setState] = useState<stateTypes>({
      serviceProvider: { id: 0, value: "" },
      serviceStations: [],
      product: { id: 0, value: "" },
      date: dayjs(new Date()).format(),
      dbDate: dayjs(new Date()),
      Uom: { id: 0, value: "" },
      currency: { id: 0, value: "" },
      unitPrice: undefined,
      selectedStations: [],

      remark: "",
      serviceStationSearchText: "",
      serviceStationSearchTemp: "",

      validationModal: { state: false, title: "", message: "" },
      successModal: { state: false, title: "", message: "" },
      confirmModal: { state: false, title: "", message: "" },

      serviceProvideRefresh: false,
      productRefresh: false,
      uomRefresh: false,
      currencyRefresh: false,
    });
    const {
      serviceProvider,
      serviceProvideRefresh,
      productRefresh,
      uomRefresh,
      currencyRefresh,
      selectedStations,
      product,
      date,
      Uom,
      currency,
      unitPrice,
      remark,
      serviceStationSearchTemp,
      serviceStationSearchText,
      validationModal,
      confirmModal,
      successModal,
    } = states;
    const updateState = (
      stateName: string,
      value:
        | string
        | boolean
        | number
        | modalPropTypes
        | selectObjectTypes
        | Dayjs
        | Object
    ) => {
      setState((currentState: any) => ({
        ...currentState,
        [stateName]: value,
      }));
    };

    const disableConfirmModal = () => {
      updateState("confirmModal", {
        state: false,
        title: "",
        message: "",
        action: "post",
      });
    };

    const disableFields = () => {
      //disable Update Mode
      dispatch(setGeneralValue({ expr: "updateMode", value: false }));

      //disable forms
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    };

    //validate fields before post or update
    const validateFields = (): boolean => {
      const validationInstances: validateInstanceTypes[] = [
        {
          state: serviceProvider.id,
          baseValue: 0,
          modalProps: {
            state: true,
            title: "Select Service Provider",
            message: "Please select a service provider",
          },
        },
        {
          state: product.id,
          baseValue: 0,
          modalProps: {
            state: true,
            title: "Select Product",
            message: "Please select a product",
          },
        },
        {
          state: date,
          baseValue: "",
          modalProps: {
            state: true,
            title: "Enter date",
            message: "Please enter a date",
          },
        },
        {
          state: Uom.id,
          baseValue: 0,
          modalProps: {
            state: true,
            title: "Select Unit of Measure",
            message: "Please select a unit of measure",
          },
        },
        {
          state: currency.id,
          baseValue: 0,
          modalProps: {
            state: true,
            title: "Select Currency",
            message: "Please select a currency",
          },
        },
        {
          state: unitPrice,
          baseValue: undefined,
          modalProps: {
            state: true,
            title: "Enter Unit Price",
            message: "Please enter unit price",
          },
        },
      ];

      for (let instance of validationInstances)
        if (instance.state === instance.baseValue) {
          const { modalProps } = instance;
          updateState("validationModal", {
            state: modalProps.state,
            title: modalProps.title,
            message: modalProps.message,
          });
          return false;
        }

      if (selectedStations.length > 0) {
        return true;
      } else {
        updateState("validationModal", {
          state: true,
          title: "Select Service Station",
          message: "Please select at least one service station",
        });
        return false;
      }
    };

    //post data
    const postData = async (action: "validate" | "post") => {
      if (action === "validate") {
        // validate fieldds
        validateFields() &&
          updateState("confirmModal", {
            state: true,
            title: "Save Record",
            message: "Are you sure you want to save this record?",
            action: "post",
          });
        return;
      }

      //post
      disableConfirmModal();

      busyloader("Saving Record...");
      try {
        //loop and save all selcted PPEs
        for (let selectedStation of selectedStations) {
          const serviceStationIdKey: string = "sstIdpk";

          // return id if record exists
          const getResponse = await GetDataFunc(
            `AssetMgrTmsBulkProductPrices/selectID?pprDate=${dayjs(date).format(
              dbDateFormat
            )}&pprServiceStationIDfk=${
              selectedStation[serviceStationIdKey]
            }&pprProductIDfk=${product.id}&pprUnitPrice=${unitPrice}`
          );

          if (getResponse?.data.length < 1) {
            await posting (`AssetMgrTmsBulkProductPrices/create`, {
              pprDate: date,
              pprServiceStationIdfk: selectedStation[serviceStationIdKey],
              pprProductIdfk: product.id,
              pprFuelUnitIdfk: Uom.id,
              pprCurrencyIdfk: currency.id,
              pprUnitPrice: unitPrice,
              pprActive: true,
              pprRmks: remark,
              pprCreatedBy: `${userId}`,
            }, `
              Create AssetMgrTmsBulkProductPrices/create with Unit Price: ${unitPrice} and Product ID: ${product.id} and Service Station ID: ${selectedStation[serviceStationIdKey]},
              UoM ID: ${Uom.id}, Currency ID: ${currency.id} and Date: ${date} and Remark: ${remark} and Created By: ${userId} 
            `
            );
          } else {
            let recordId: number = getResponse?.data[0]?.pprIdpk;

            await updating(
              `AssetMgrTmsBulkProductPrices/update/${recordId}`,
              {
                pprIdpk: recordId,
                pprDate: dayjs(date).format(dbDateFormat),
                pprServiceStationIdfk: selectedStation[serviceStationIdKey],
                pprProductIdfk: product.id,
                pprFuelUnitIdfk: Uom.id,
                pprCurrencyIdfk: currency.id,
                pprUnitPrice: unitPrice,
                pprActive: true,
                pprRmks: remark,
                pprEditedDate: dayjs(date).format(),
              },
              `
                Update AssetMgrTmsBulkProductPrices/update/${recordId} with Unit Price: ${unitPrice} and Product ID: ${product.id} and Service Station ID: ${selectedStation[serviceStationIdKey]},
                UoM ID: ${Uom.id}, Currency ID: ${currency.id} and Date: ${date} and Remark: ${remark} and Edited Date: ${dayjs(date).format()} 
              `
            );
          }
        }

        updateState("successModal", {
          state: true,
          title: `${"Save"} Successful`,
          message: `Record ${"saved"} successfully`,
        });
        disableFields();
        refreshGrid();
        clearSelected();
      } catch (error) {
        updateState("validationModal", {
          state: true,
          title: saveModalData.error.title,
          message: saveModalData.error.message,
        });
      }
      return;
    };

    //GET REQUESTS
    //grids
    const [serviceProviderData, serviceProviderError, serviceProviderLoading] =
      useFetch(
        "AssetMgrTmsBulkProductPrices/getPopLueServiceProviders",
        serviceProvideRefresh
      );
    const [serviceStationsData, serviceStationsError, serviceStationsLoading] =
      useFetch(
        serviceProvider.id
          ? `AssetMgrTmsBulkProductPrices/getPopLueServiceStations?txtSearchStation=${serviceStationSearchText}&spdIDpk=${serviceProvider.id}`
          : ``
      );
    const [productData, productError, productLoading] = useFetch(
      `AssetMgrTmsBulkProductPrices/getPopLueProducts`,
      productRefresh
    );

    //selects
    const [UomData, UomError, UomLoading] = useFetch(
      "AssetMgrTmsBulkProductPrices/getPopLueUnitOfMeasure",
      uomRefresh
    ); //exams grid
    const [currencyData, currencyError, currencyLoading] = useFetch(
      `AssetMgrTmsBulkProductPrices/getPopLueCurrency`,
      currencyRefresh
    ); //exams grid

    //modals data
    // modal instances
    const modals: modalTypes[] = [
      //validation
      {
        disableCancel: true,
        icon: "warning",
        open: validationModal.state,
        okHandler: () =>
          updateState("validationModal", {
            state: false,
            title: "",
            message: "",
          }),
        cancelHandler: () =>
          updateState("validationModal", {
            state: false,
            title: "string",
            message: "string",
          }),
        message: validationModal.message,
        okText: "Ok",
        title: validationModal.title,
        cancelText: "No, cancel",
      },

      //confirmation
      {
        disableCancel: false,
        icon: "question",
        open: confirmModal.state,
        okHandler:
          confirmModal.action === "update" ? () => {} : () => postData("post"),
        cancelHandler: () =>
          updateState("confirmModal", {
            state: false,
            title: "string",
            message: "string",
          }),
        message: states.confirmModal.message,
        okText: confirmModal.action === "update" ? "Yes, Update" : "Yes, Save",
        title: states.confirmModal.title,
        cancelText: "No, cancel",
      },

      //success
      {
        disableCancel: true,
        icon: "success",
        open: successModal.state,
        okHandler: () =>
          updateState("successModal", { state: false, title: "", message: "" }),
        cancelHandler: () =>
          updateState("successModal", {
            state: false,
            title: "string",
            message: "string",
          }),
        message: successModal.message,
        okText: "Ok",
        title: successModal.title,
        cancelText: "No, cancel",
      },
    ];

    return (
      <div className="px-2">
        {/* modals */}
        {modals.map(
          (
            {
              disableCancel,
              icon,
              okHandler,
              cancelHandler,
              open,
              cancelText,
              okText,
              message,
              title,
            }: modalTypes,
            index
          ) => {
            return (
              <div key={index}>
                <ModalTemplate
                  icon={icon && icon}
                  disableCancel={disableCancel}
                  cancelText={cancelText && cancelText}
                  open={open}
                  okHandler={okHandler}
                  cancelHandler={cancelHandler}
                  message={message}
                  okText={okText}
                  title={title}
                />
              </div>
            );
          }
        )}

        {/* FORM HEADER */}
        <p
          id={"form_header"}
          style={{ borderColor: borderTheme }}
          className="pt-1 border[1px] border-b-0 px-2 w-full font-semibold bg-slate-100"
        >
          {"Bulk Product Price Details"}
        </p>
        <Form
          style={{ borderColor: borderTheme }}
          className="w-full border-[1px] border-slate-100 rounded flex justify-between py-1 px-2 "
          name="basic"
          initialValues={{ remember: true }}
          autoComplete="off"
          size="small"
        >
          <section style={{ width: "250px" }} className="w-full  bg">
            {/* DATAGRID [Names] */}
            <Form.Item className="" name="name" rules={[{ required: false }]}>
              <div className="flex flex-row justify-between">
                <span style={{ width: "calc(100% - 27.5px)" }} className="">
                  <Datagrid_template
                    onRowClick={(selected) => {
                      updateState("serviceProvider", {
                        id: selected.spdIdpk,
                        value: selected.spdName,
                      });
                    }}
                    selectionMode="single"
                    disableGroupPanel
                    disableSearch
                    disablePaging
                    data={serviceProviderData}
                    gridheight={382}
                    columns={[
                      {
                        id: 0,
                        caption: "SERVICE PROVIDER",
                        dataField: "spdName",
                        datatype: "string",
                        width: 300,
                        fixed: false,
                        visible: true,
                      },
                    ]}
                  />
                </span>

                <span
                  onClick={() => {
                    updateState(
                      "serviceProvideRefresh",
                      !serviceProvideRefresh
                    );
                  }}
                  style={{
                    height: 22.5,
                    marginBottom: 2,
                    borderWidth: 1,
                    width: 23.9,
                    borderColor: borderTheme,
                  }}
                  className="ml-1 mt-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                >
                  <img
                    className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                    src={refresh}
                    alt="reset"
                  />
                </span>
              </div>
            </Form.Item>
          </section>

          {/* middle section */}
          <section className=" " style={{ width: "460px" }}>
            <Form className="w-full" name="basic">
              <div
                style={{ borderColor: borderTheme }}
                className=" border-[1px] rounded pb-1 mt-0.5  border-slate-100"
              >
                <div className=" px-1 ">
                  <div className="flex flex-row justify-end items-center mt-0.5 w-full">
                    <InputsTemplate
                      span
                      useCallbackFunc
                      orderOnchange={(value) => {
                        updateState("serviceStationSearchTemp", value);
                      }}
                      disabledStatus={false}
                      label="Find"
                    />

                    <span
                      style={{ borderColor: borderTheme }}
                      className=" h-full  flex items-center justify-center px-1 py-0.5 hover:cursor-pointer hover:bg-blue-200 ml-1 rounded border-2"
                      onClick={() => {
                        updateState(
                          "serviceStationSearchText",
                          serviceStationSearchTemp
                            ? serviceStationSearchTemp
                            : ""
                        );
                      }}
                    >
                      <FaBinoculars color="#007AFF" />
                    </span>
                  </div>

                  {/* SERVICE STATIONS  */}
                  <Form.Item
                    className=""
                    name="Staff No"
                    rules={[{ required: false }]}
                  >
                    <div className="">
                      <Datagrid_template
                        selectedItemsChange={(selectedItems) => {
                          updateState("selectedStations", selectedItems);
                        }}
                        selectionMode="multiple"
                        onRowClick={() => {}}
                        rowDoubleClicked={() => {}}
                        disableSearch
                        disableGroupPanel
                        disablePaging
                        data={serviceStationsData}
                        gridheight={346.5}
                        columns={stationsColumns}
                      />
                    </div>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </section>

          <section style={{ width: "335px" }} className="w-full  bg">
            {/* DATAGRID [safety groups] */}
            <Form className="" labelCol={{ span: 5 }} name="name">
              <div className="flex flex-row justify-between mb-0.5">
                <span
                  style={{ width: "calc(100% - 24px)" }}
                  className=" mb-0.5"
                >
                  <Datagrid_template
                    onRowClick={(selected) => {
                      updateState("product", {
                        id: selected.prdIdpk,
                        value: selected.prdName,
                      });
                    }}
                    selectionMode="single"
                    disableGroupPanel
                    disableSearch
                    disablePaging
                    data={productData}
                    gridheight={272.5}
                    columns={[
                      {
                        id: 0,
                        caption: "PRODUCT",
                        dataField: "prdName",
                        datatype: "string",
                        width: 300,
                        fixed: false,
                        visible: true,
                      },
                    ]}
                  />
                </span>

                <span
                  onClick={() => {
                    // updateState('product',{id:0,value:''})
                    updateState("productRefresh", !productRefresh);
                  }}
                  style={{
                    height: 22.5,
                    marginBottom: 2,
                    borderWidth: 1,
                    width: 23.9,
                    borderColor: borderTheme,
                  }}
                  className="ml-1 mt-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                >
                  <img
                    className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                    src={refresh}
                    alt="reset"
                  />
                </span>
              </div>

              {/* dates */}
              <DateTemplate
                changeDate={(date: Dayjs) => {
                  updateState("date", dayjs(date.format()));
                  updateState("dbDate", dayjs(date.format(dbDateFormat)));
                }}
                style={{ marginBottom: 1 }}
                selectedDate={dayjs(date)}
                disabled={false}
                width={237.5}
                label="Date"
              />

              {/* selects */}
              <SelectsTemplate
                useCallFunc
                style={{ marginBottom: 1 }}
                placeHolder={Uom.value}
                selectedValue={(object) => {
                  updateState("Uom", {
                    id: object.funIdpk,
                    value: object.funName,
                  });
                }}
                handleRefresh={() => {
                  updateState("uomRefresh", !uomRefresh);
                }}
                idexpr="funIdpk"
                dataexp="funName"
                options={UomData}
                disabled={false}
                label="UoM"
              />

              <SelectsTemplate
                useCallFunc
                placeHolder={currency.value}
                selectedValue={(object) => {
                  updateState("currency", {
                    id: object.curIdpk,
                    value: object.curSymbol,
                  });
                }}
                idexpr="curIdpk"
                handleRefresh={() => {
                  updateState("currencyRefresh", !currencyRefresh);
                }}
                dataexp="curSymbol"
                options={currencyData}
                disabled={false}
                label="Currency"
              />

              {/* input */}
              <div className=" mt-0">
                <InputsTemplate
                  useCallbackFunc
                  inputType="number"
                  defaultValue={unitPrice}
                  orderOnchange={(value) => updateState("unitPrice", value)}
                  disabledStatus={false}
                  label="Unit Price"
                />
              </div>
            </Form>
          </section>

          <section className=" " style={{ width: "calc(100% - 1065px)" }}>
            {/*Remarks*/}
            <Tooltip id="remark" title="Remarks">
              <Form.Item
                id="remark"
                className=" mb-1"
                style={{ marginTop: "3px" }}
                name="Staff No"
                rules={[{ required: false }]}
              >
                <TextareaTemplate
                  useCallbackFunc
                  readonly={false}
                  defaultValue={remark}
                  setCallbackValue={(text) => {
                    updateState("remark", text);
                  }}
                  height={343}
                />
              </Form.Item>
            </Tooltip>

            <div className=" mt-1">
              <SaveButton
                handleSave={() => postData("validate")}
                disableButton={false}
              />
            </div>
          </section>
        </Form>
      </div>
    );
  }
);
