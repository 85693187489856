export const assets_fuelDAAssignmentsColumns = [
  {
    id: 0,
    caption: "STAFF NO",
    dataField: "empStaffNo",
    dataType: "string",
    fixed: true,
    visible: true,
  },
  {
    id: 1,
    caption: "ATTENDANT",
    dataField: "empName",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "SERVICE STATION",
    dataField: "sstName",
    dataType: "string",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 3,
    caption: "ATTENDANT LOC.",
    dataField: "locShtName",
    dataType: "string",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 4,
    caption: "SERVICE PROVIDER",
    dataField: "spdName",
    dataType: "string",
    width: 150,
    fixed: true,
    visible: true,
  },
  {
    id: 10,
    caption: "ACTIVE",
    dataField: "faaActive",
    dataType: "boolean",
    fixed: false,
    visible: true,
  },
  {
    id: 15,
    caption: "MANAGE?",
    dataField: "faaManage",
    dataType: "boolean",
    fixed: false,
    visible: true,
  },
  {
    id: 16,
    caption: "VIEW?",
    dataField: "faaView",
    dataType: "boolean",
    fixed: false,
    visible: true,
  },
  {
    id: 17,
    caption: "AUTHORIZE?",
    dataField: "faaAuthorise",
    dataType: "boolean",
    fixed: false,
    visible: true,
  },
  {
    id: 18,
    caption: "DATE ASSIGNED",
    dataField: "faaCreationDate",
    dataType: "datetime",
    width: null,
    fixed: false,
    visible: true,
  },
  {
    id: 19,
    caption: "DATE UNASSIGNED",
    dataField: "faaEditedDate",
    dataType: "datetime",
    width: null,
    fixed: false,
    visible: true,
  },
   {
    id: 23,
    caption: "DEPT",
    dataField: "dptShtName",
    dataType: "string",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 24,
    caption: "SECTION",
    dataField: "sxnShtName",
    dataType: "string",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 25,
    caption: "REMARKS",
    dataField: "faaRmks",
    dataType: "string",
    width: 5000,
    fixed: false,
    visible: true,
  },
];

export const fdaStaffColumns = [
  {
    id: 0,
    caption: "Staff No",
    dataField: "empStaffNo",
    dataType: "string",
    width: 90,
    fixed: true,
    visible: true,
  },
  {
    id: 1,
    caption: "Staff Name",
    dataField: "empName",
    dataType: "string",
    width: 150,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "Dept",
    dataField: "dptShtName",
    dataType: "string",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 3,
    caption: "Section",
    dataField: "sxnShtName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 4,
    caption: "Location",
    dataField: "locShtName",
    dataType: "string",
    width: 2000,
    fixed: false,
    visible: true,
  },
];

export const fdaselectedColumns = [
  {
    id: 0,
    caption: " No",
    dataField: "sstIDpk",
    dataType: "",
    width: 50,
    fixed: true,
    visible: true,
  },
  {
    id: 1,
    caption: " Fuel Dump",
    dataField: "sstName",
    dataType: "string",
    allowEditing: false,
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "Manage",
    dataField: "faaManage",
    dataType: "boolean",
    width: 85,
    fixed: false,
    visible: true,
    allowEditing: true,
  },
  {
    id: 3,
    caption: "View",
    dataField: "faaView",
    dataType: "boolean",
    width: 65,
    fixed: false,
    visible: true,
    allowEditing: true,
  },
  {
    id: 4,
    caption: "Authorize",
    dataField: "faaAuthorise",
    dataType: "boolean",
    width: 90,
    fixed: false,
    visible: true,
    allowEditing: true,
  },
   {
    id: 5,
    caption: "Service",
    dataField: "spdName",
    dataType: "string",
    width: 150,
    fixed: false,
    visible: true,
  },
  {
    id: 6,
    caption: "Remark",
    dataField: "faaRmks",
    dataType: "string",
    width: 500,
    fixed: false,
    visible: true,
    allowEditing: true,
  },
];
