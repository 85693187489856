import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncGet } from "../../functions/get";

interface Fuel_TransactionsUploadState {
  service_Provider: any | null | undefined;
  isLoading: boolean;
  isError: boolean;
  isPending: boolean;
}
let initialState: Fuel_TransactionsUploadState = {
  service_Provider: [],
  isLoading: false,
  isError: false,
  isPending: false,
};

export const FetchServiceProvider = createAsyncThunk<
  Fuel_TransactionsUploadState,
  string,
  { rejectValue: string }
>("serviceProvider/fetch", async (search: string) => {
  try {
    var serviceProvider_response = await AsyncGet(
      `AsmTmsVehicleSimCardAssignment/AsmTmsVehicleSimCardAssignmentGetAllVehicleRegNos?pagesize=20&pagenumber=1&Search=${search}`
    );
    if (serviceProvider_response?.status === true) {
      return serviceProvider_response?.data;
    }
  } catch {
    return serviceProvider_response?.data;
  }
});

const FuelTransactionUploadSlice = createSlice({
  name: "FuelTransactionSlice",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      //-------------Fetch Service Provider---------------
      .addCase(FetchServiceProvider.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(FetchServiceProvider.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.service_Provider = action.payload;
      })
      .addCase(FetchServiceProvider.rejected, (state) => {
        state.isError = true;
        state.isLoading = false;
      });
  },
});
export default FuelTransactionUploadSlice.reducer;