import { Form } from "antd";

import { useEffect, useState } from "react";
import {
  inputsTypes,
  modalPropTypes,
  modalTypes,
  selectsTypes,
} from "../../../../../../human-resource/setups/data/types/selectsTypes";
import { BottomBtns } from "../../../../../../../templates/butttons";
import { InputsTemplate } from "../../../../../../../templates/input";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { TextareaTemplate } from "../../../../../../../templates/textarea";
import {
  saveModalData,
  updateModalData,
} from "../../../../../../accessories/component_infos";
import SetupsDatalistTemplat from "../../../../../../human-resource/setups/data/setups_datalist_template";
import useFetch from "../../../../../../../hooks/useFetch";
import dayjs from "dayjs";
import { SelectsTemplate } from "../../../../../../../templates/select";
import { useDispatch, useSelector } from "react-redux";
import { useValidateExist } from "../../../../../../../hooks/useValidateExists";
import { UpdateDataFunc } from "../../../../../../../functions/update";
import { PostDataFunc  } from "../../../../../../../functions/post";
import { useBusyLoader } from "../../../../../../../hooks/useBusyLoader";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { setGeneralValue } from "../../../../../../../features/generalSlice";
import { RootState } from "../../../../../../../app/store";
import { useCrudFunc } from "../../../../../../../functions/crud";

interface props {
  selectedData: any;
  refreshGrid?: () => void;
}

interface stateTypes {
  workshop?: string;
  shortName?: string;
  location?: { id: number; name: string };
  town?: { id: number; name: string };
  telNos?: string;
  contactP?: string;
  extension?: string;
  code?: string;
  external?: boolean;
  active?: boolean;
  remarks?: string;
  selectedId?: any;
  validationModal?: { state: boolean; title: string; message: string };
  confirmModal?: { state: boolean; title: string; message: string };
  successModal: modalPropTypes;
}

export const Workshop_Details_form = ({ refreshGrid, selectedData }: props) => {
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  const [states, setStates] = useState<stateTypes>({
    workshop: "",
    shortName: "",
    location: { id: 0, name: "" },
    town: { id: 0, name: "" },
    telNos: "",
    contactP: "",
    extension: "",
    code: "",
    external: false,
    active: false,
    remarks: "",

    successModal: { state: false, title: "", message: "" },
  });

  const validateExists = useValidateExist();
  const updateState = (stateName: string | number, value: any) => {
    //update a state
    setStates((prevStates) => ({ ...prevStates, [stateName]: value }));
  };
  const [posting , updating]=useCrudFunc()
  // Endpoints
  const [workshopData, workshopError, workshopLoading] = useFetch(
    "AsmTmsWorkshopDetails/GetAllAsmTmsWorkshopDetails"
  );
  const [locationData, locationError, locationLoading] = useFetch(
    "HrLocations/GetHrLocations"
  );
  const [townsData, townsError, townsLoading] = useFetch(
    "Towns/GetGenAllTowns"
  );
  //populate fields
  const populateFields = (clearFields?: boolean) => {
    const states = [
      {
        stateName: "workshop",
        value: clearFields ? "" : selectedData?.wsdName,
      },
      {
        stateName: "shortName",
        value: clearFields ? "" : selectedData?.wsdShtName,
      },
      {
        stateName: "location",
        value: clearFields
          ? ""
          : { id: selectedData?.locIdpk, name: selectedData?.locName },
      },
      {
        stateName: "town",
        value: clearFields
          ? ""
          : { id: selectedData?.twnIdpk, name: selectedData?.twnName },
      },
      {
        stateName: "contactP",
        value: clearFields ? "" : selectedData?.wsdContact,
      },
      { stateName: "telNos", value: clearFields ? "" : selectedData?.wsdTelNo },
      { stateName: "code", value: clearFields ? "" : selectedData?.wsdCode },
      {
        stateName: "extension",
        value: clearFields ? "" : selectedData?.wsdPlc,
      },
      {
        stateName: "active",
        value: clearFields ? true : selectedData?.wsdActive,
      },
      {
        stateName: "external",
        value: clearFields ? true : selectedData?.wsdExternal,
      },
      { stateName: "remarks", value: clearFields ? "" : selectedData?.wsdRmks },
    ];

    //set states
    for (let instance of states)
      updateState(instance.stateName, instance.value);
  };

  const [busyLoader, setBusyLoader] = useBusyLoader();
  const [employeeId, userId] = useAccountInfo();

  const disableForm = () => {
    dispatch(setGeneralValue({ expr: "updateMode", value: false }));
    dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
  };

  const datalists: selectsTypes[] = [
    {
      id: 0,
      label: "Workshop",
      optionsData: workshopData,
      idExpr: "wsdIdpk",
      dataExpr: "wsdName",
      stateName: "workshop",
      defaultValue: states.workshop,
    },
    {
      id: 1,
      label: "Short Name",
      optionsData: workshopData,
      idExpr: "",
      dataExpr: "wsdShtName",
      stateName: "shortName",
      defaultValue: states.workshop,
    }, //states.shortName
  ];

  const datalists2a: selectsTypes[] = [
    {
      id: 1,
      label: "Location/Town",
      optionsData: locationData,
      idExpr: "locIDpk",
      dataExpr: "locShtName",
      stateName: "location",
      defaultValue: states.location?.name,
    },
  ];
  const datalists2b: selectsTypes[] = [
    {
      id: 0,
      label: "",
      optionsData: townsData,
      idExpr: "twnIDpk",
      dataExpr: "twnName",
      stateName: "town",
      defaultValue: states.town?.name,
    },
  ];
  const datalists3: selectsTypes[] = [
    {
      id: 1,
      label: "Code",
      optionsData: workshopData,
      idExpr: "wsdIdpk",
      dataExpr: "wsdCode",
      stateName: "code",
      defaultValue: states.code,
    },
  ];

  const inputs: inputsTypes[] = [
    {
      id: 0,
      label: "Contact Persons",
      stateName: "contactP",
      defaultValue: states.contactP,
    },
  ];

  const inputs2a: inputsTypes[] = [
    {
      id: 1,
      label: "Telephone/Ext.",
      stateName: "telNos",
      defaultValue: states.telNos,
    },
  ];

  const inputs2b: inputsTypes[] = [
    {
      id: 2,
      label: "",
      stateName: "extension",
      defaultValue: states.extension,
    },
  ];

  //  Current date
  const editedDate = dayjs().format();

  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();

  //run populate func
  useEffect(() => {
    populateFields(false);
  }, [selectedData]);

  const validateFields = (method: () => void) => {
    if (!states.workshop || states.workshop.replace(/\s/g, "") === "") {
      updateState("validationModal", {
        state: true,
        title: "Enter Workshop!",
        message: "Please enter workshop",
      });
    } else if (
      !states.shortName ||
      states.shortName.replace(/\s/g, "") === ""
    ) {
      updateState("validationModal", {
        state: true,
        title: "Enter Short name!",
        message: "Please enter short name",
      });
    } else {
      method();
    }
  };

  const setSaveConfirmModal = async () => {
    (await validateExists(
      `AsmTmsWorkshopDetails/GetNameShortName?name=${states.workshop}&shortname=${states.shortName}`
    )) &&
      updateState("confirmModal", {
        state: true,
        title: "Save Record?",
        message: "Are you sure you want to save this record?",
      });
  };
  const setUpdateConfirmModal = async () => {
    (await validateExists(
      `AsmTmsWorkshopDetails/GetNameShortName?name=${states.workshop}&shortname=${states.shortName}`,
      selectedData.wsdIdpk,
      "wsdIdpk"
    )) &&
      updateState("confirmModal", {
        state: true,
        title: "Update Record?",
        message: "Are you sure you want to update this record?",
      });
  };

  const dispatch = useDispatch();

  // PostData
  const postData = async () => {
    //post request
    updateState("confirmModal", {
      state: false,
      title: "Save Record?",
      message: "Are you sure you want to save this record?",
    });

    try {
      setBusyLoader(saveModalData.progress);

      await posting (`AsmTmsWorkshopDetails/CreateAsmTmsWorkshopDetail`, {
        wsdName: states.workshop,
        wsdShtName: states.shortName,
        wsdCode: states.code,
        wsdContact: states.contactP,
        wsdTelNo: states.telNos,
        wsdPlc: states.extension,
        wsdLocIdfk: states.location?.id,
        wsdTownIdfk: states.town?.id,
        wsdActive: states.active,
        wsdExternal: states.external,
        wsdRmks: states.remarks,
        wsdCreatedBy: userId,
      }, `Created Drivers workshop area ${states.workshop}`);
      refreshGrid!();
      disableForm();

      updateState("successModal", {
        state: true,
        message: "Record saved successfully",
        title: "Save successful",
      });
    } catch (error: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      updateState("validationModal", {
        state: true,
        title: saveModalData.error.title,
        message: saveModalData.error.message,
      });
    } finally {
      setBusyLoader("");
    }
  };

  // update
  const UpdateData = async () => {
    updateState("confirmModal", {
      state: false,
      title: "Update Record?",
      message: "Are you sure you want to update this record?",
    });
    try {
      setBusyLoader(updateModalData.progress);

      await updating(`AsmTmsWorkshopDetails/UpdateAsmTmsWorkshopDetail`, {
        wsdIdpk: selectedData.wsdIdpk,
        wsdName: states.workshop,
        wsdShtName: states.shortName,
        wsdCode: states.code,
        wsdContact: states.contactP,
        wsdTelNo: states.telNos,
        wsdPlc: states.extension,
        wsdLocIdfk: states.location?.id,
        wsdTownIdfk: states.town?.id,
        wsdActive: states.active,
        wsdExternal: states.external,
        wsdRmks: states.remarks,
        wsdEditedBy: userId,
        wsdEditedDate: editedDate,
      }, `Updated Drivers workshop area ${states.workshop}`);

      refreshGrid!();
      disableForm();

      updateState("successModal", {
        state: true,
        message: "Record updated successfully",
        title: "Update successful",
      });
    } catch (error: any) {
      //set states for db errors modal

      setIcon("warning");
      updateState("validationModal", {
        state: true,
        title: updateModalData.error.title,
        message: updateModalData.error.message,
      });
    } finally {
      setBusyLoader("");
    }
  };

  //modal instances
  const modals: modalTypes[] = [
    //warning
    {
      disableCancel: true,
      icon: "warning",
      open: states.validationModal!?.state,
      okHandler: () =>
        updateState("validationModal", {
          ...states.validationModal,
          state: false,
        }),
      cancelHandler: () =>
        updateState("validationModal", {
          ...states.validationModal,
          state: false,
        }),
      message: states.validationModal!?.message,
      cancelText: "",
      okText: "Ok",
      title: states.validationModal!?.title,
    },
    // confirmModal
    {
      disableCancel: false,
      icon: "question",
      open: states.confirmModal!?.state,
      okHandler: () => {
        states.confirmModal?.title === "Save Record?"
          ? postData()
          : UpdateData();
      },
      cancelHandler: () =>
        updateState("confirmModal", { ...states.confirmModal, state: false }),
      message: states.confirmModal!?.message,
      cancelText: "No, Cancel",
      okText:
        states.confirmModal?.title === "Save Record?"
          ? "Yes, Save"
          : "Yes, Update",
      title: states.confirmModal!?.title,
    },
    // Success Modal
    {
      disableCancel: true,
      icon: "success",
      open: states.successModal.state,
      okHandler: () =>
        updateState("successModal", { state: false, title: "", message: "" }),
      cancelHandler: () =>
        updateState("successModal", {
          state: false,
          title: "string",
          message: "string",
        }),
      message: states.successModal.message,
      okText: "Ok",
      title: states.successModal.title,
      cancelText: "No, cancel",
    },
  ];

  return (
    <div className="px-2">
      {modals.map(
        (
          {
            disableCancel,
            icon,
            okHandler,
            cancelHandler,
            open,
            cancelText,
            okText,
            message,
            title,
          }: modalTypes,
          index
        ) => {
          return (
            <div key={index}>
              <ModalTemplate
                icon={icon && icon}
                disableCancel={disableCancel}
                cancelText={cancelText && cancelText}
                open={open}
                okHandler={okHandler}
                cancelHandler={cancelHandler}
                message={message}
                okText={okText}
                title={title}
              />
            </div>
          );
        }
      )}

      {/* FORM HEADER */}
      <p
        id={"form_header"}
        style={{ borderColor: borderTheme }}
        className="pt-1 border-[1px] border-b-0 px-2 w-ful font-semibold bg-slate-100"
      >
        {"Workshop Details"}
      </p>
      <Form
        style={{ borderColor: borderTheme }}
        className="w-full border-[1px] border-slate-100 rounded flex justify-between py-1 px-2 "
        name="basic"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 19 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        size="small"
      >
        <section className="" style={{ width: "550px" }}>
          {/* datalists */}
          {datalists.map(
            ({ id, label, optionsData, dataExpr, stateName, defaultValue }) => {
              return (
                <div key={id} className={"mb-1"}>
                  <SetupsDatalistTemplat
                    selectedData={defaultValue}
                    listid={label}
                    nameExpr={dataExpr}
                    label={label}
                    refresh={true}
                    datalistStyle={{ height: 24 }}
                    span={label === "Short Name" ? false : true}
                    options={optionsData}
                    useCallbackFunc
                    setCallbackValue={(value) => {
                      updateState(stateName!, value);
                    }}
                  />
                </div>
              );
            }
          )}

          {/* Location & Town */}

          <Form
            labelCol={{ span: 7 }}
            className=""
            style={{ width: "550px", marginLeft: 13 }}
          >
            <div className=" mb-0.5 flex">
              <div className="mr-1" style={{ width: "350px" }}>
                {datalists2a.map(
                  ({
                    id,
                    label,
                    idExpr,
                    optionsData,
                    dataExpr,
                    stateName,
                    defaultValue,
                  }) => {
                    return (
                      <div key={id} className="">
                        <SelectsTemplate
                          idexpr={idExpr}
                          label={label}
                          placeHolder={defaultValue}
                          dataexp={dataExpr}
                          options={optionsData}
                          useCallFunc
                          selectedValue={(object) => {
                            updateState(stateName!, {
                              id: object[idExpr!],
                              name: object[dataExpr!],
                            });
                          }}
                        />
                      </div>
                    );
                  }
                )}
              </div>

              <div style={{ width: "183px" }} className=" ">
                {datalists2b.map(
                  ({
                    id,
                    label,
                    idExpr,
                    optionsData,
                    dataExpr,
                    stateName,
                    defaultValue,
                  }) => {
                    return (
                      <div key={id} className="">
                        <SelectsTemplate
                          idexpr={idExpr}
                          label={label}
                          dataexp={dataExpr}
                          placeHolder={defaultValue}
                          options={optionsData}
                          useCallFunc
                          selectedValue={(object) => {
                            updateState(stateName!, {
                              id: object[idExpr!],
                              name: object[dataExpr!],
                            });
                          }}
                        />
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </Form>

          {/* contact person */}

          {inputs.map(({ id, label, stateName, defaultValue }) => {
            return (
              <div key={id} className="ml-1 mb-1">
                <InputsTemplate
                  defaultValue={defaultValue}
                  span
                  style={{ marginLeft: "1%", width: "93.8%" }}
                  inputStyle={{ height: 24 }}
                  useCallbackFunc
                  orderOnchange={(value) => {
                    updateState(stateName!, value);
                  }}
                  label={label}
                />
              </div>
            );
          })}

          {/* Telephone & Ext. */}
          <Form
            labelCol={{ span: 7 }}
            className=""
            style={{ width: "530px", marginLeft: 9 }}
          >
            <div className="  flex justify-between mb-1">
              <div className=" mr-1" style={{ width: "340px" }}>
                {inputs2a.map(({ id, label, stateName, defaultValue }) => {
                  return (
                    <div style={{ marginLeft: 9.2 }} key={id} className="">
                      <InputsTemplate
                        inputStyle={{ height: 24 }}
                        defaultValue={defaultValue}
                        span
                        useCallbackFunc
                        orderOnchange={(value) => {
                          updateState(stateName!, value);
                        }}
                        label={label}
                      />
                    </div>
                  );
                })}
              </div>

              <div style={{ width: "185px" }} className=" ">
                {inputs2b.map(({ id, label, stateName, defaultValue }) => {
                  return (
                    <div key={id} className="">
                      <InputsTemplate
                        defaultValue={defaultValue}
                        span
                        inputStyle={{ height: 24 }}
                        useCallbackFunc
                        style={{ marginLeft: "1%", width: "90%" }}
                        orderOnchange={(value) => {
                          updateState(stateName!, value);
                        }}
                        label={label}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </Form>

          {/* Code */}
          {datalists3.map(
            ({ id, label, optionsData, dataExpr, stateName, defaultValue }) => {
              return (
                <div key={id} className=" mb-1">
                  <SetupsDatalistTemplat
                    selectedData={defaultValue}
                    listid={label}
                    datalistStyle={{ height: 24 }}
                    nameExpr={dataExpr}
                    label={label}
                    options={optionsData}
                    useCallbackFunc
                    setCallbackValue={(value) => {
                      updateState(stateName!, value);
                    }}
                  />
                </div>
              );
            }
          )}

          {/* checkbox and butons */}
          <div className="pl-[7px] mr-[27.5px]">
            <BottomBtns
              span={true}
              addActiveBorders={true}
              label="Active?"
              additionalBottomChckbox="External"
              onCancelClick={() => {}}
              updateHandler={() => validateFields(setUpdateConfirmModal)}
              useCallbackFunc
              onNewClick={() => {
                populateFields(true); dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
              }}
              additionalCheckBoxValue={[states.external, states.active]}
              secondCheckboxChange={(e: any) => {
                updateState("active", e);
              }}
              firstCheckboxChange={(e: any) => {
                updateState("external", e);
              }}
              saveHandler={() => validateFields(setSaveConfirmModal)}
            />
          </div>
        </section>

        {/* remarks */}
        <section className=" " style={{ width: "calc(100% - 550px)" }}>
          <TextareaTemplate
            defaultValue={states.remarks}
            MarginLeft={40}
            height={195}
            label={"Remarks"}
            useCallbackFunc
            setCallbackValue={(value) => {
              updateState("remarks", value);
            }}
          />
        </section>
      </Form>
    </div>
  );
};
