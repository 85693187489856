import { Modal } from "antd";
import { useEffect, useRef, useState } from "react";
interface props {
  openState: boolean;
  child: any;
  title: string;
  width?: number | string;
  height?: number | string;
  onCancel?: () => void;
  childStyles ?: any

  // -----Dont touch this--------
  seeMap?: boolean;
  setSeeMap?: (e: boolean) => void;
  //------------------------------
}

interface stateTypes {
  modalOpen: boolean;
}
export const FormModal = ({
  openState,
  child,
  width,
  height,
  title,
  onCancel,
  childStyles
}: props) => {
  //states
  const [states, setStates] = useState<stateTypes>({
    modalOpen: false,
  });
  //update state
  const updateState = (stateName: string, value: any) => {
    setStates((prevStates) => ({ ...prevStates, [stateName]: value }));
  };

  const ismounted = useRef<boolean>();

  useEffect(() => {
    ismounted.current
      ? updateState("modalOpen", true)
      : (ismounted.current = true);
  }, [openState]);
  return (
    <div className="modal">
      <Modal
        width={width ?? "95%"}
        title={`${title}`}
        open={openState}
        onCancel={() => {
          onCancel && onCancel();
        }}
        centered
        footer={null}
        transitionName=""
      >
        <div style={childStyles}>
        {child}
        </div>
      </Modal>
    </div>
  );
};
