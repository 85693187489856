/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useState } from 'react'
import { InputsTemplate } from '../../../templates/input'
import { CustomizableButton, SearchButton } from '../../accessories/buttons'
import save from "../../../assets/floppy-disk.png"
import help from "../../../assets/help.png"
import { Form } from 'antd'
import { formPropsConst } from '../../accessories/constants'
import Datagrid_template from '../../../templates/Datagrid'
import { resetPass_cols } from './data/datagridCols'
import { inputsTypes } from '../../human-resource/setups/data/types/selectsTypes'
import { CheckboxTemlate } from '../../../templates/checkbox'
import useResizeMode from '../../../hooks/useResizeMode'
import useFetch from '../../../hooks/useFetch'
import { ModalTemplate } from '../../../templates/modal'
import { currentDate, useModalData } from '../../accessories/component_infos'
import { PopDgvUsers } from '../../../interfaces'
import { useCrudFunc } from '../../../functions/crud'
import { useAccountInfo } from '../../../hooks/useAccountInfo'


interface formType {
    refresh?: boolean;
    text?: string;
    newPass?: string;
    conPass?: string;
    check?: boolean;
    rowId?: { id: number, value: string, staffName:string, staffNo:string };


}
const ResetPassword = () => {
  const [initial, final] = useResizeMode(window.innerHeight);
    const gridheight = initial - 310;
    const [PostDataFunc, UpdateDataFunc] = useCrudFunc();
    const [userId] = useAccountInfo();

    const [formData, setFormData] = useState<formType>({
        refresh: false,
        check: false,
        text: "",
        newPass: "",
        conPass: "",
        rowId: { id: 0, value: "" , staffName:"", staffNo:""}
    })

    const [searchData, setSearchData] = useState(`GenResetPassword/PopDgvUsers?searchText=${formData.text}`)

    const [allData, allData_errror, allData_loading]: [PopDgvUsers[], any, boolean] = useFetch(
        searchData,
        formData.refresh

    )
    const [modalData, setModalData] = useModalData();


    const updateStates = useCallback((key: string, value: any) => {
        setFormData((prev: any) => ({ ...prev, [key]: value }))
    }, [])

    const handleOrderOnchage = useCallback((e: any) => {
        updateStates("text", e)
    }, [])
   

    const inputs: inputsTypes[] = [
        { id: 0, label: "New Password", stateName: "newPass", defaultValue: formData.newPass, style: "", },
        { id: 1, label: "Confirm Password", stateName: "conPass", defaultValue: formData.conPass, style: "" },
    ]


    const passValidate = (text: any, staffArray: string[]): boolean => {
        if(!staffArray) return false
        return staffArray.some(substring => text.includes(substring.toLowerCase()));
    }

    const runUpdate = async () => {
        try {
            await UpdateDataFunc(
                `GenResetPassword/PasswordResetUpdate`,
                {
                    "usaPassword": formData.newPass,
                    "usaChangePassword": formData.check,
                    "usaIDpk": formData.rowId?.id
                },
                `Update the ResetPassword with usaIDpk:${formData.rowId?.id} Date Updated:${currentDate}. Updated By:${userId}`

            )
            setModalData(current => ({
                ...current,
                state: true,
                icon: "success",
                title: "Success",
                message: "Password Updated Successfuly",
                okText: "Ok",
                cancelText: "Cancel",
                disableCancel: true,
                okHandler: () => {
                    setModalData(current => ({
                        ...current,
                        state: false,
                    })
                    )
                },
                cancelHandler: () => {
                    setModalData(current => ({
                        ...current,
                        state: false,
                    })
                    )
                },
            }))

        } catch (e: any) {
            setModalData(current => ({
                ...current,
                state: true,
                icon: "warning",
                title: "Failed",
                message: "Password failed to update. Contact your Administrator",
                okText: "Ok",
                cancelText: "Cancel",
                disableCancel: true,
                okHandler: () => {
                    setModalData(current => ({
                        ...current,
                        state: false,
                    })
                    )
                },
                cancelHandler: () => {
                    setModalData(current => ({
                        ...current,
                        state: false,
                    })
                    )
                },
            }))
        }

    }

    const showSave = () => {
        setModalData(current => ({
            ...current,
            state: true,
            icon: "question",
            title: "Question",
            message: "Are you sure you want to update slected User's Password",
            okText: "Ok",
            cancelText: "Cancel",
            disableCancel: false,
            okHandler: () => {
                setModalData(current => ({
                    ...current,
                    state: false,
                })
                )
                runUpdate()
            },
            cancelHandler: () => {
                setModalData(current => ({
                    ...current,
                    state: false,
                })
                )
            },
        }))
    }

    const validation = () => {
        if (formData.newPass === "") {
            setModalData(current => ({
                ...current,
                state: true,
                icon:"warning",
                title: "Empty field",
                message: "Enter New Password",
                okText: "Ok",
                cancelText: "Cancel",
                disableCancel: true,
                okHandler: () => {
                    setModalData(current => ({
                        ...current,
                        state: false,
                    })
                    )
                },
                cancelHandler: () => {
                    setModalData(current => ({
                        ...current,
                        state: false,
                    })
                    )
                },
            }))
        }
        else if (formData.conPass !== formData.newPass) {
            setModalData(current => ({
                ...current,
                icon:"warning",
                state: true,
                title: "No Match",
                message: "New and Confirm Password don't match",
                okText: "Ok",
                cancelText: "Cancel",
                disableCancel: true,
                okHandler: () => {
                    setModalData(current => ({
                        ...current,
                        state: false,
                    })
                    )
                },
                cancelHandler: () => {
                    setModalData(current => ({
                        ...current,
                        state: false,
                    })
                    )
                },
            }))
        }
        else {
            let staffArray;
            if (!formData.rowId!.staffName.split(" ") === undefined) {
                staffArray = formData.rowId!.staffName.split(" ")
            }

            (!(formData.newPass !== undefined && formData.newPass.length < 9)  || formData.newPass?.toLowerCase().includes(formData.rowId!.staffNo),  passValidate(formData.newPass?.toLowerCase(), staffArray))?
                setModalData(current => ({
                    ...current,
                    state: true,
                    icon:"warning",
                    title: "Password Rules",
                    okText: "Ok",
                    disableCancel: true,
                    message: dataValue,
                    okHandler: () => {
                        setModalData(current => ({
                            ...current,
                            state: false,
                        }))
                    }
                })): showSave()
        }
    }

    console.log("%^& formData.newPass", (formData.newPass !== undefined && formData.newPass.length < 9))


    // Help  Modal
    const passwordRules: { id: number, data: string }[] = [
        { id: 1, data: 'Your password cannot contain any of the following' },
        { id: 2, data: '- Any form of the word "PASSWORD" or the word "PASS"' },
        { id: 3, data: '- Your USERNAME \n Your STAFF NUMBER ' },
        { id: 4, data: '- Any part of your Name' },
        { id: 5, data: '- Any form of your Date of Birth' },
        { id: 6, data: '- And must be EIGHT (8) CHARACTERS or MORE' },
    ]

    const dataValue = passwordRules.map((rule) => rule.data).join("\n")

    return (
        <>

            <div className='w-full h-full'>
                {/* Top section */}
                <ModalTemplate
                    icon={modalData.icon}
                    width={modalData.title === "Password Rules" ? 500 : 400}
                    disableCancel={modalData.disableCancel}
                    cancelText={modalData.cancelText}
                    open={modalData.state}
                    okHandler={
                        modalData.okHandler
                        //   ()=>{
                        //   modalData.title === "Save Records?"? saveData(): setModalData(current =>({
                        //     ...current,
                        //     state:false,
                        //   }))
                        // }
                    }
                    cancelHandler={modalData.cancelHandler}
                    message={modalData.message}
                    okText={modalData.okText}
                    title={modalData.title}
                />
                <section className='w-full'>

                    <Form
                        {...formPropsConst}
                    >
                        <section className='w-full flex space-x-1 items-center'>
                            <InputsTemplate
                                label={"Search"}
                                useCallbackFunc
                                defaultValue={formData.text}

                                orderOnchange={(handleOrderOnchage)}
                                // readOnly={false}
                                disabledStatus={false}
                                span
                            // inputStyle={{ height: "25px" }}

                            />

                            <SearchButton
                                handleOnClick={() => {
                                    // updateStates("text", formData.text)
                                    setSearchData(`GenResetPassword/PopDgvUsers?searchText=${formData.text}`)
                                }}
                            />
                        </section>
                    </Form>

                </section>

                {/* Grid section */}

                <section className='w-full px-2'>
                    <Datagrid_template
                        gridheight={gridheight}
                        columns={resetPass_cols}
                        data={allData ?? []}
                        disablePaging
                        onRowClick={(e: PopDgvUsers) => {
                            updateStates("rowId", {
                                id: e?.usaIDpk,
                                value: e?.empName,
                                staffName: e?.empName1,
                                staffNo:e?.empStaffNo
                            })
                        }}

                    />
                </section>

                {/* Password Section */}

                <section className='w-full' >
                    <Form
                        className='w-full rounded py-1 px-2 '
                        name="basic"
                        labelCol={{ span: 10 }}
                        wrapperCol={{ span: 24 }}
                        initialValues={{ remember: true }}
                        autoComplete="off"
                        size='small'
                        disabled={false}
                    >
                        <section className='w-1/2 flex justify-between space-x-2'>
                            {/* inputs */}
                            <div className='w-3/5'>
                                {inputs.map(({ id, label, stateName, defaultValue, style, handleOrderOnchage }) => {
                                    return (
                                        <>
                                            <InputsTemplate
                                                inputType="password"
                                                label={label}
                                                disabledStatus={false}
                                                useCallbackFunc
                                                defaultValue={defaultValue}
                                                orderOnchange={(e: any) => {
                                                    updateStates(stateName!, e)
                                                    console.log("%^& stateName", stateName)
                                                    console.log("%^& stateName", e)
                                                }}
                                                readOnly={false}
                                                span
                                                inputStyle={{ width: 400 }}
                                            />
                                        </>
                                    )
                                })}

                                <CheckboxTemlate
                                    label={"Expire Password"}
                                    withBorders
                                    labelCol={10}
                                    defaultValue={formData.check}
                                    useCallbackFunc
                                    customDisabled={false}
                                    setCheckboxOnchange={(e) => {
                                        updateStates("check", e)
                                    }}
                                />

                            </div>

                            {/* button */}
                            <div className='w-2/5 h-full '>
                                <CustomizableButton isText
                                    text={"Update"}
                                    classname='h-full justify-center items-center py-3 px-2'
                                    customizableBtnImageClassname={"px-2"}
                                    customizableBtnImage={save}
                                    handleCustomizableClick={() => {
                                        validation()
                                    }} />
                            </div>

                        </section>

                    </Form>

                </section>

                {/* help */}
                <div className='w-full flex px-2 space-x-10'>
                    <Form
                        className='w-[35%] rounded py-1 px-2 flex justify-between items-center'
                        name="basic"
                        initialValues={{ remember: true }}
                        autoComplete="off"
                        size='small'
                    >
                        <CustomizableButton
                            classname='flex justify-center items-center p-1'
                            customizableBtnImage={help}
                            handleCustomizableClick={() => {
                                setModalData(current => ({
                                    ...current,
                                    state: true,
                                    title: "Password Rules",
                                    okText: "Ok",
                                    disableCancel: true,
                                    message: dataValue,
                                    okHandler: () => {
                                        setModalData(current => ({
                                            ...current,
                                            state: false,
                                        }))
                                    }
                                }))
                            }} />
                        <p className='text-blue-500' >Reset Password with Text Message</p>

                    </Form>

                </div>

            </div>
        </>
    )
}
export default ResetPassword
