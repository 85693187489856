import { Form } from "antd";
import {
  inputsTypes,
  modalPropTypes,
  modalTypes,
  selectObjectTypes,
  selectsTypes,
  validateInstanceTypes,
} from "../../../../../../human-resource/setups/data/types/selectsTypes";
import { BottomBtns } from "../../../../../../../templates/butttons";
import { InputsTemplate } from "../../../../../../../templates/input";
import { TextareaTemplate } from "../../../../../../../templates/textarea";
import SetupsDatalistTemplat from "../../../../../../human-resource/setups/data/setups_datalist_template";
import useFetch from "../../../../../../../hooks/useFetch";
import { useEffect, useState } from "react";
import { SelectsTemplate } from "../../../../../../../templates/select";
import {
  saveModalData,
  updateModalData,
} from "../../../../../../accessories/component_infos";
import { useDispatch, useSelector } from "react-redux";
import { setGeneralValue } from "../../../../../../../features/generalSlice";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { useValidateExist } from "../../../../../../../hooks/useValidateExists";
import { UpdateDataFunc } from "../../../../../../../functions/update";
import { PostDataFunc  } from "../../../../../../../functions/post";
import { RootState } from "../../../../../../../app/store";
import { useCrudFunc } from "../../../../../../../functions/crud";
import InputSelect_template from "../../../../../../../templates/InputSelect";

interface props {
  busyloader: (text?: string) => void;
  refreshGrid: () => void;
  clearSelected: () => void;
  selectedField: any;
  data: any;
}
interface StateTypes {
  serviceProvider: selectObjectTypes;
  station: selectObjectTypes;
  shortName: selectObjectTypes;

  stationNo: selectObjectTypes;
  region: selectObjectTypes;
  town: selectObjectTypes;
  contactPersons: string;
  mobTelNo: string;
  email: string;
  fuelDump: boolean;
  active: boolean;
  order: number;
  remarks: string;
  refresh: boolean;
  busyLoader: string;
  selectedRecordId: number;
  confirmModal: modalPropTypes;
  warningModal: modalPropTypes;
  successModal: modalPropTypes;
}

export const ServiceStations_form = ({
  busyloader,
  clearSelected,
  refreshGrid,
  data: formData,
  selectedField,
}: props) => {
  const dispatch = useDispatch();
  const [employeeId, userId] = useAccountInfo();
  const validate = useValidateExist();

  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  useEffect(() => {
    populateStatesFromGrid(false);
  }, [selectedField]);

  //component states
  const [states, setState] = useState<StateTypes>({
    serviceProvider: { id: 0, value: "" },
    station: { id: 0, value: "" },
    shortName: { id: 0, value: "" },
    stationNo: { id: 0, value: "" },
    region: { id: 0, value: "" },
    town: { id: 0, value: "" },
    contactPersons: "",
    mobTelNo: "",
    email: "",
    fuelDump: false,
    order: 0,
    active: false,
    remarks: "",
    refresh: false,
    busyLoader: "",
    selectedRecordId: 0,
    confirmModal: { state: false, title: "", message: "", action: "post" },
    warningModal: { state: false, title: "", message: "" },
    successModal: { state: false, title: "", message: "" },
  });
  //handle state update
  const updateState = (
    stateName: string,
    value: boolean | number | string | selectObjectTypes | modalPropTypes
  ) => {
    setState((prevState) => ({ ...prevState, [stateName]: value }));
  };

  const {
    confirmModal,
    warningModal,
    successModal,
    serviceProvider,
    station,
    shortName,
    stationNo,
    order,
    region,
    town,
    contactPersons,
    selectedRecordId,
    mobTelNo,
    email,
    fuelDump,
    active,
    remarks,
  } = states;

  //get dropdowns data
  const [serviceproviderdata, serviceprovidererror, serviceproviderloading] =
    useFetch(
      "AssetTmsServiceStations/PopCmbServiceProvidersAssetTmsServiceStations"
    );
  const [stationsdata, stationserror, stationsloading] = useFetch(
    "AssetTmsServiceStations/PopCmbNameAssetTmsServiceStations"
  );
  const [stationNumberdata, stationNumbererror, stationNumberloading] =
    useFetch("AssetTmsServiceStations/StationNo");
  const [regiondata, regionerror, regionloading] = useFetch(
    "AssetTmsServiceStations/PopCmbRegionAssetTmsServiceStations"
  );
  const [towndata, townerror, townloading] = useFetch(
    region.value
      ? `AssetTmsServiceStations/PopCmbTownAssetTmsServiceStationsCommand?regionName=${region.value}`
      : ""
  );
  const [posting , updating]=useCrudFunc()

  const inputs: inputsTypes[] = [
    {
      id: 0,
      label: "Contact Persons",
      stateName: "contactPersons",
      defaultValue: contactPersons,
    },
    {
      id: 1,
      label: "Mob/Tel Nos",
      stateName: "mobTelNo",
      defaultValue: mobTelNo,
    },
    { id: 2, label: "Email", stateName: "email", defaultValue: email },
  ];

  const disableConfirmModal = () => {
    updateState("confirmModal", {
      state: false,
      title: "",
      message: "",
      action: "post",
    });
  };

  const enableSuccessModal = (
    titleActionText: string,
    messageActionText: string
  ) => {
    updateState("successModal", {
      state: true,
      title: `${titleActionText} Successful`,
      message: `Record ${messageActionText} successfully`,
    });
  };

  //validate fields
  const validateFields = (): boolean => {
    const validationInstances: validateInstanceTypes[] = [
      {
        state: serviceProvider.id,
        baseValue: 0,
        modalProps: {
          state: true,
          title: "Select service provider",
          message: "Please select a service provider",
        },
      },
      {
        state: station.id,
        baseValue: "",
        type: "string",
        modalProps: {
          state: true,
          title: "Enter station",
          message: "Please enter a station",
        },
      },
      {
        state: shortName.id,
        baseValue: "",
        type: "string",
        modalProps: {
          state: true,
          title: "Enter shortname",
          message: "Please enter a short name",
        },
      },

      {
        state: region.id,
        baseValue: 0,
        modalProps: {
          state: true,
          title: "Select region",
          message: "Please select a region",
        },
      },
      {
        state: town.id,
        baseValue: 0,
        modalProps: {
          state: true,
          title: "Select town",
          message: "Please select a town",
        },
      },
      {
        state: stationNo.id,
        baseValue: 0,
        modalProps: {
          state: true,
          title: "Enter station No",
          message: "Please select a station No",
        },
      },
      {
        state: contactPersons,
        baseValue: "",
        type: "string",
        modalProps: {
          state: true,
          title: "Enter contact person",
          message: "Please enter a contact person",
        },
      },
      {
        state: mobTelNo,
        baseValue: "",
        type: "string",
        modalProps: {
          state: true,
          title: "Enter Tel No",
          message: "Please enter a Tel No",
        },
      },
      {
        state: email,
        baseValue: "",
        type: "string",
        modalProps: {
          state: true,
          title: "Enter email",
          message: "Please enter an email address",
        },
      },
    ];

    for (let instance of validationInstances)
      if (
        !instance.state || instance.state === "string"
          ? instance.state === instance.baseValue
          : `${instance.state}`.replace(/\s/g, "") === ""
      ) {
        const { modalProps } = instance;
        updateState("warningModal", {
          state: modalProps.state,
          title: modalProps.title,
          message: modalProps.message,
        });
        return false;
      }
    return true;
  };

  const disableFields = () => {
    //disable Update Mode
    dispatch(setGeneralValue({ expr: "updateMode", value: false }));

    //disable forms
    dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
  };

  const populateStatesFromGrid = (clearFields?: boolean) => {
    const run = (selectedField: any = {}) => {
      const populateInstances = [
        {
          state: "serviceProvider",
          value: clearFields
            ? { id: 0, value: "" }
            : { id: selectedField?.spdIdpk, value: selectedField?.spdName },
        },
        { state: "station", value: clearFields ? "" : selectedField?.sstName },
        {
          state: "shortName",
          value: clearFields ? "" : selectedField?.sstShtName,
        },
        {
          state: "region",
          value: clearFields
            ? { id: 0, value: "" }
            : { id: selectedField?.rgnIdpk, value: selectedField?.rgnName },
        },
        {
          state: "town",
          value: clearFields
            ? { id: 0, value: "" }
            : { id: selectedField?.twnIdpk, value: selectedField?.twnName },
        },
        {
          state: "stationNo",
          value: clearFields ? "" : selectedField?.sstStationNo,
        },
        {
          state: "order",
          value: clearFields ? 0 : selectedField?.sstOrder ?? 0,
        },
        {
          state: "contactPersons",
          value: clearFields ? "" : selectedField?.sstMainContacts,
        },
        {
          state: "mobTelNo",
          value: clearFields ? "" : selectedField?.sstTelNos,
        },
        { state: "email", value: clearFields ? "" : selectedField?.ssteMails },
        {
          state: "active",
          value: clearFields ? true : selectedField?.sstActive,
        },
        {
          state: "fuelDump",
          value: clearFields ? true : selectedField?.sstFuelDump,
        },
        { state: "remarks", value: clearFields ? "" : selectedField?.sstRmks },
        {
          state: "selectedRecordId",
          value: clearFields ? 0 : selectedField?.sstIdpk,
        },
      ];

      for (let instance of populateInstances)
        updateState(instance.state, instance.value);
    };

    run(selectedField);
  };

  //post data
  const postData = async (action: "validate" | "post") => {
    if (action === "validate") {
      // validate fieldds
      validateFields() &&
        (await validate(
          `AssetTmsServiceStations/GetNameShortName?name=${station}&shortname=${shortName}`
        )) &&
        updateState("confirmModal", {
          state: true,
          title: "Save Record",
          message: "Are you sure you want to save this record?",
          action: "post",
        });

      return;
    }

    //post
    disableConfirmModal();
    busyloader(saveModalData.progress);

    try {
      await posting(
        `AssetTmsServiceStations/CreateAssetTmsServiceStations`,
        {
          sstName: station.value,
          sstShtName: shortName.value,
          sstStationNo: stationNo.value,
          sstServiceProviderIdfk: serviceProvider.id,
          sstTownIdfk: town.id,
          sstMainContacts: contactPersons,
          sstTelNos: mobTelNo,
          ssteMails: email,
          sstFuelDump: fuelDump,
          sstActive: active,
          sstRmks: remarks,
          sstOrder: order,
          sstCreatedBy: userId.toString(),
        },
        `Created the Service Station Details with service provider - ${serviceProvider.value}, station - ${station.value}, short name - ${shortName.value}, station no - ${stationNo.value}, region - ${region.value}, town - ${town.value}, contact persons - ${contactPersons}, mob tel no - ${mobTelNo}, email - ${email}, order - ${order}`
      );

      enableSuccessModal("Save", "saved");
      disableFields();
      refreshGrid();
      clearSelected();
    } catch (error) {
      updateState("warningModal", {
        state: true,
        title: saveModalData.error.title,
        message: saveModalData.error.message,
      });
    } finally {
      busyloader("");
    }
  };

  const update = async (action: "validate" | "update") => {
    if (action === "validate") {
      // validate fieldds
      validateFields() &&
        (await validate(
          `AssetTmsServiceStations/GetNameShortName?name=${station}&shortname=${shortName}`,
          selectedRecordId,
          "sstIdpk"
        )) &&
        updateState("confirmModal", {
          state: true,
          title: "Update Record",
          message: "Are you sure you want to update this record?",
          action: "update",
        });
      return;
    }

    //update
    disableConfirmModal();
    busyloader(updateModalData.progress);

    try {
      await updating(
        `AssetTmsServiceStations/UpdateAssetTmsServiceStations/${selectedRecordId}`,
        {
          sstIdpk: selectedRecordId,
          sstName: station.value,
          sstShtName: shortName.value,
          sstStationNo: stationNo.value,
          sstServiceProviderIdfk: serviceProvider.id,
          sstTownIdfk: town.id,
          sstMainContacts: contactPersons,
          sstTelNos: mobTelNo,
          ssteMails: email,
          sstFuelDump: fuelDump,
          sstActive: active,
          sstRmks: remarks,
          sstOrder: order,
          sstEditedBy: userId,
        },
        `Updated the Service Station Details with service provider - ${serviceProvider.value}, station - ${station.value}, short name - ${shortName.value}, station no - ${stationNo.value}, region - ${region.value}, town - ${town.value}, contact persons - ${contactPersons}, mob tel no - ${mobTelNo}, email - ${email}, order - ${order}`
      );

      enableSuccessModal("Update", "updated");
      disableFields();
      refreshGrid();
      clearSelected();
    } catch (error) {
      updateState("warningModal", {
        state: true,
        title: updateModalData.error.title,
        message: updateModalData.error.message,
      });
    } finally {
      busyloader("");
    }
    return;
  };

  // modal instances
  const modals: modalTypes[] = [
    //validation
    {
      disableCancel: true,
      icon: "warning",
      open: warningModal.state,
      okHandler: () =>
        updateState("warningModal", { state: false, title: "", message: "" }),
      cancelHandler: () =>
        updateState("warningModal", {
          state: false,
          title: "string",
          message: "string",
        }),
      message: warningModal.message,
      okText: "Ok",
      title: warningModal.title,
      cancelText: "No, cancel",
    },

    //confirmation
    {
      disableCancel: false,
      icon: "question",
      open: confirmModal.state,
      okHandler:
        confirmModal.action === "update"
          ? () => update("update")
          : () => postData("post"),
      cancelHandler: () =>
        updateState("confirmModal", {
          state: false,
          title: "string",
          message: "string",
        }),
      message: states.confirmModal.message,
      okText: confirmModal.action === "update" ? "Yes, Update" : "Yes, Save",
      title: states.confirmModal.title,
      cancelText: "No, cancel",
    },

    //success
    {
      disableCancel: true,
      icon: "success",
      open: successModal.state,
      okHandler: () =>
        updateState("successModal", { state: false, title: "", message: "" }),
      cancelHandler: () =>
        updateState("successModal", {
          state: false,
          title: "string",
          message: "string",
        }),
      message: successModal.message,
      okText: "Ok",
      title: successModal.title,
      cancelText: "No, cancel",
    },
  ];

  return (
    <>
      {modals.map(
        (
          {
            disableCancel,
            icon,
            okHandler,
            cancelHandler,
            open,
            cancelText,
            okText,
            message,
            title,
          }: modalTypes,
          index
        ) => {
          return (
            <div key={index}>
              <ModalTemplate
                icon={icon && icon}
                disableCancel={disableCancel}
                cancelText={cancelText && cancelText}
                open={open}
                okHandler={okHandler}
                cancelHandler={cancelHandler}
                message={message}
                okText={okText}
                title={title}
              />
            </div>
          );
        }
      )}

      <div className="px-2">
        {/* FORM HEADER */}
        <p
          id={"form_header"}
          style={{ borderColor: borderTheme }}
          className="py-1 border-[1px] px-2 w-full font-semibold bg-slate-100 border-b-[0px] rounded-t-md"
        >
          {"Service Station Details"}
        </p>
        <Form
          style={{ borderColor: borderTheme }}
          className="w-full border-[1px] border-t-0 border-slate-100 flex justify-between py-1 px-2 "
          name="basic"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          size="small"
        >
          <section className="" style={{ width: "550px" }}>
            <div className=" mb-0.5">
              {/* Service Provider */}
              <SelectsTemplate
                label="Service Provider"
                useCallFunc
                placeHolder={serviceProvider.value}
                options={serviceproviderdata}
                selectedValue={(selected) => {
                  updateState("serviceProvider", {
                    id: selected.spdIdpk,
                    value: selected.spdName,
                  });
                }}
                idexpr="spdIdpk"
                dataexp="spdName"
              />
            </div>

            <div className=" mb-0.5">
              <InputSelect_template
                style={{ height: 24 }}
                idexpr="sstIdpk"
                dataexp="sstName"
                placeHolder={station}
                span
                useCallFunc
                selectedValue={(e) => {
                  updateState("station", {
                    id: e.sstIdpk,
                    value: e.sstName,
                  });
                }}
                label="Station"
                options={stationsdata}
              />
              </div>

              <div className=" mb-0.5">
              <InputSelect_template
                style={{ height: 24 }}
                idexpr="sstIdpk"
                dataexp="sstShtName"
                placeHolder={shortName}
                span
                useCallFunc
                selectedValue={(e) => {
                  updateState("shortName", {
                    id: e.sstIdpk,
                    value: e.sstShtName,
                  });
                }}
                label="Short Name"
                options={stationsdata}
              />
            </div>

            <div className=" mb-0.5">
              <InputSelect_template
                style={{ height: 24 }}
                idexpr="sstIdpk"
                dataexp="sstStationNo"
                placeHolder={stationNo}
                span
                useCallFunc
                selectedValue={(e) => {
                  updateState("stationNo", {
                    id: e.sstIdpk,
                    value: e.sstStationNo,
                  });
                }}
                label="Station No"
                options={stationNumberdata}
              />
            </div>

            {/* flex selects */}
            <Form.Item
              label={<p className="text-xs">{"Region"}</p>}
              style={{ marginBottom: 0 }}
            >
              <div className=" flex flex-row justify-between mb-0.5">
                <SelectsTemplate
                  span
                  style={{ display: "inline-block", width: "calc(47% )" }}
                  useCallFunc
                  selectedValue={(selected) => {
                    updateState("region", {
                      id: selected.rgnIdpk,
                      value: selected.rgnName,
                    });
                  }}
                  options={regiondata}
                  placeHolder={region}
                  idexpr="rgnIdpk"
                  dataexp="rgnName"
                />

                <SelectsTemplate
                  style={{ display: "inline-block", width: "calc(49.5% )" }}
                  useCallFunc
                  selectedValue={(selected) => {
                    updateState("town", {
                      id: selected.twnIdpk,
                      value: selected.twnName,
                    });
                  }}
                  placeHolder={town.value}
                  options={towndata}
                  label="Town"
                  idexpr="twnIdpk"
                  dataexp="twnName"
                />
              </div>
            </Form.Item>

            {/* inputs */}
            {inputs.map(({ id, label, stateName, defaultValue }) => {
              return (
                <div key={id} className=" mb-1">
                  <InputsTemplate
                    span
                    inputStyle={{ height: 24 }}
                    useCallbackFunc
                    orderOnchange={(value) => {
                      updateState(stateName!, value);
                    }}
                    defaultValue={defaultValue}
                    label={label}
                  />
                </div>
              );
            })}

            {/* order */}
            <div className=" mb-1">
              <InputsTemplate
                span
                inputType="number"
                defaultValue={order}
                orderOnchange={(value) => {
                  updateState("order", value);
                }}
                inputStyle={{ height: 24 }}
                useCallbackFunc
                label="Order"
              />
            </div>

            {/* checkbox and buttons */}
            <div className="">
              <BottomBtns
                addActiveBorders={true}
                useCallbackFunc
                defaultValue={active}
                onNewClick={() => {
                  populateStatesFromGrid(true); dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
                }}
                saveHandler={() => postData("validate")}
                updateHandler={() => {
                  update("validate");
                }}
                additionalCheckBoxValue={[fuelDump, active]}
                firstCheckboxChange={(check) => {
                  updateState("fuelDump", check);
                }}
                secondCheckboxChange={(check) => {
                  updateState("active", check);
                }}
                additionalBottomChckbox="fuel Dump?"
                span
              />
            </div>
          </section>

          {/* remarks */}
          <section className=" " style={{ width: "calc(100% - 550px)" }}>
            <TextareaTemplate
              useCallbackFunc
              defaultValue={remarks}
              setCallbackValue={(value) => {
                updateState("remarks", value);
              }}
              MarginLeft={40}
              height={282.5}
              label={"Remarks"}
            />
          </section>
        </Form>
      </div>
    </>
  );
};
