export const sms_header_2 = [
  {
    id: 0,
    caption: "No",
    dataField: "No",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 1,
    caption: "Name",
    dataField: "Name",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 2,
    caption: "Mob No",
    dataField: "Mob No",
    alignment: "center",
    fixed: false,
    width: null,
  },
  {
    id: 3,
    caption: "Intercom",
    dataField: "Intercom",
    alignment: "center",
    fixed: false,
    width: null,
  },
  {
    id: 4,
    caption: "Job Title",
    dataField: "Job Title",
    alignment: "center",
    fixed: false,
  },
  {
    id: 5,
    caption: "Dept",
    dataField: "Dept",
    alignment: "left",
    fixed: false,
    width: null,
  },
  {
    id: 5,
    caption: "Section",
    dataField: "Section",
    alignment: "left",
    fixed: false,
    width: null,
  },

  {
    id: 6,
    caption: "Location",
    dataField: "LOCATION",
    alignment: "center",
    fixed: false,
    width: null,
  },
];

export const sms_header_1 = [
  {
    id: 0,
    caption: "Add",
    dataField: "Add",
    alignment: "center",
    fixed: true,
    width: 50,
  },
  {
    id: 1,
    caption: "No",
    dataField: "No",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 2,
    caption: "Name",
    dataField: "Name",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 3,
    caption: "Mob No",
    dataField: "Mob No",
    alignment: "center",
    fixed: false,
    width: null,
  },
  {
    id: 4,
    caption: "Intercom",
    dataField: "Intercom",
    alignment: "center",
    fixed: false,
    width: null,
  },
  {
    id: 7,
    caption: "Job Title",
    dataField: "Job Title",
    alignment: "center",
    fixed: false,
  },
  {
    id: 5,
    caption: "Dept",
    dataField: "Dept",
    alignment: "left",
    fixed: false,
    width: null,
  },
  {
    id: 6,
    caption: "Section",
    dataField: "Section",
    alignment: "left",
    fixed: false,
    width: null,
  },

  {
    id: 8,
    caption: "Location",
    dataField: "LOCATION",
    alignment: "center",
    fixed: false,
    width: null,
  },
];

export const telephone_headers = [
  {
    id: 0,
    caption: "ACTIVE STAFF?",
    dataField: "ACTIVE STAFF",
    alignment: "center",
    fixed: true,
    width: 60,
  },
  {
    id: 1,
    caption: "STAFF NAME",
    dataField: "STAFF NAME",
    alignment: "left",
    fixed: true,
    width: 100,
  },
  {
    id: 2,
    caption: "INTERCOM",
    dataField: "INTERCOM",
    alignment: "left",
    fixed: false,
    width: 70,
  },
  {
    id: 3,
    caption: "MOB NO",
    dataField: "MOB NO",
    alignment: "left",
    fixed: false,
    width: null,
  },
  {
    id: 4,
    caption: "TEL NO",
    dataField: "TEL NO",
    alignment: "center",
    fixed: false,
    width: null,
  },
  {
    id: 5,
    caption: "DEPT",
    dataField: "DEPT",
    alignment: "left",
    fixed: false,
    width: null,
  },
  {
    id: 6,
    caption: "SECTION",
    dataField: "SECTION",
    alignment: "left",
    fixed: false,
    width: null,
  },

  {
    id: 7,
    caption: "JOB TITLE",
    dataField: "JOB TITLE",
    alignment: "center",
    fixed: false,
  },
  {
    id: 8,
    caption: "LOCATION",
    dataField: "LOCATION",
    alignment: "center",
    fixed: false,
    width: null,
  },
  {
    id: 9,
    caption: "OFFICE LOCATION",
    dataField: "OFFICE LOCATION",
    alignment: "center",
    fixed: false,
    width: null,
  },
  {
    id: 10,
    caption: "ROOM NO",
    dataField: "ROOM NO",
    alignment: "center",
    fixed: false,
    width: null,
  },
];

export const telephone_data = Array.from({ length: 50 }, () => {
  return {
    "ACTIVE STAFF": true,
    "STAFF NAME": "Abrego, Mr Kelsi",
    INTERCOM: 720551,
    "MOB NO": "236-931-8173",
    "TEL NO": "",
    DEPT: "South Grid",
    SECTION: "Telmin Area",
    "JOB TITLE": "Electrical Engineer",
    LOCATION: "Accra",
    "OFFICE LOCATION": "Mallam Substation",
    "ROOM NO": "General",
  };
});

export const sms_data_1 = Array.from({ length: 50 }, () => {
  return {
    Add: true,
    No: "1234567890",
    Name: "Test name",
    "Mob No": "236-931-8173",
    Intercom: "",
    "Job Title": "Electrical Engineer",
    Dept: "Telmin Area",
    Section: "South Grid",
    Location: "Accra",
  };
});

export const sms_data_2 = Array.from({ length: 50 }, () => {
  return {
    No: "1234567890",
    Name: "Test name",
    "Mob No": "236-931-8173",
    Intercom: "",
    "Job Title": "Electrical Engineer",
    Dept: "Telmin Area",
    Section: "South Grid",
    Location: "Accra",
  };
});
