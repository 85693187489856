import { useEffect, useState } from "react";
import { AiFillPrinter } from "react-icons/ai";
import { HiRefresh } from "react-icons/hi";
import "devextreme/dist/css/dx.light.css";
import { CheckBox } from "devextreme-react/check-box";
import { Tooltip } from "devextreme-react";
import find from "../../../../../../../assets/binoculars.png";
import alter from "../../../../../../../assets/alter.png";
import edit from "../../../../../../../assets/edit.png";
import newfile from "../../../../../../../assets/new.png";
import setting from "../../../../../../../assets/setting.png";

import close from "../../../../../../../assets/close.png";
import printer from "../../../../../../../assets/printer.png";
import reset from "../../../../../../../assets/refresh-small.png";
import { Checkbox, DatePicker, Input, Select } from "antd";
import {
  DeleteTask,
  TaskSettings,
  AlertSMS,
  AppraiseSettings,
} from "../../../../../../accessories/custom-toolbars/widgets/customToolbarModals";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import {
  setGeneralValue,
  settask_form_disable,
  settask_footer_update,
} from "../../../../../../../features/generalSlice";
import {
  task_form_disable,
  task_footer_update,
} from "../../../../../../../features/Task&Todo/Task/TaskDetails";
import { currentDate, saveModalData, startingDate } from "../../../../../../accessories/component_infos";
import { FaBinoculars } from "react-icons/fa";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { CheckboxTemlate } from "../../../../../../../templates/checkbox";
import dayjs from "dayjs";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import statusLoader from "../../../../../../../assets/statusloader.gif";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";

// ----------------Eagle Mentality🦅 -------------------------------------------------------------------------------
import {
  setNew_Cancel_Button,
  setEdit_Cancel_Button,
  setSave_Update_Button,
  setDisableForm,
} from "../../../../../../../features/VehicleRedux/TMS-Sicers/Vehicles/VehicleDetailsSlice";
// ---------------------------------------------------------------------------------------------------------------------------

interface props {
  toggler?: () => void;
  handleDelete?: () => void; //delete handler
  handleNew?: (value: boolean) => void;
  handleSave?: () => void; //save handler
  handleUpdate?: () => void; //update handler
  searchTextOnchange?: (value: string) => void; //search text change
  searchCriteriaOnchange?: (index: number, criteria: string) => void;
  startDateChanged?: (value: any) => void; //start date change
  endDateChanged?: (value: any) => void; //end date change
  checkOnChange?: (value: any) => void; //checkbox change
  handleFind?: () => void; //find handler
  handleRefresh?: () => void; //refresh main grid
  handlePrint?: () => void; // handle print functionality
  useCallBackFunc?: boolean;
  withDates?: boolean; //add dates to the toolbar?
  toggleNew?: boolean;
  toggleUpdate?: boolean;
  handleEdit?: (value: boolean) => void;
  switchState?: (value: boolean) => void;
  setState?: (value: any) => void;
  outerSelected?: any;
  setOuterSelected?: (e: any) => void;
  searchCriteriaData?: { id: number; value: string }[];
  //-----------------------------------------
  startDate?: string;
  endDate?: string;
  setVisible?: () => void;
  setShowImages?: () => void;
  //-----------------------------------------
}

export const VehicleDetailsToolbar = ({
  toggler,
  handleRefresh,
  handlePrint,
  handleFind,
  checkOnChange,
  switchState,
  setState,
  outerSelected,
  setOuterSelected,
  searchTextOnchange,
  startDateChanged,
  endDateChanged,
  searchCriteriaOnchange,
  searchCriteriaData = [],

  // ------------------
  startDate,
  endDate,
  setVisible,
  setShowImages,
}: // ------------------
props) => {
  const dateFormat = "DD MMM YYYY";

  // const Customswitch = (val:Boolean)=>{
  //     if(val === true){
  //         setSwitchiconMsg('All tasks')
  //     }else {
  //         setSwitchiconMsg('Personal')
  //         setIsActive(!isActive)

  //     }
  // }

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  const disablex = useSelector(
    (state: RootState) => state.addTask.taskFormStatus
  );

  const updateMode = useSelector(
    (state: RootState) => state.addTask.taskFooterUpdate
  );

  const selectedRecord = useSelector(
    (state: RootState) => state.addTask.selectedRecord
  );
  const borderTheme = useSelector((state: RootState) => state.general.AppTheme);
  const sideAlert = useSelector(
    (state: RootState) => state.general.SideAlertTest
  );

  const NewSelectedRecord = useSelector(
    (state: RootState) => state.general.VDgridData
  );

  // ---------------------------- Eagle Mentality🦅   -------------------------------------

  let NewAndCancelButton = useSelector(
    (state: RootState) => state.TMSModule1.VehicleDetails.New_Cancel_Button
  );
  let EditAndCancelButton = useSelector(
    (state: RootState) => state.TMSModule1.VehicleDetails.Edit_Cancel_Button
  );
  const Vdispatch = useDispatch();
  // -----------------------------------------------------------------------------

  const [userId, employID, userObj] = useAccountInfo();

  const [privType, setPrivType] = useState<string>("save");

  const [savePriv, readPriv, updatePriv] = useFormPriviledge(
    privType,
  
  );

  const ResetFields = () => {
    setState!({
      vdtIdpk: 0,
      regNo: " ",
      vehClass: { id: 0, value: "" },
      manufacturer: { id: 0, value: "" },
      engineType: { id: 0, value: "" },
      powerTrain: { id: 0, value: "" },
      len: "",
      width: "",
      height: "",
      noCylinder: "",
      horsePower: "",
      netWeight: "",
      grossWeight: "",
      manuDate: "",
      chassisNo: "",
      vehMake: { id: 0, value: "" },
      country: { id: 0, value: "" },
      fuelType: { id: 0, value: "" },
      transType: { id: 0, value: "" },
      status: { id: 0, value: "" },
      noOfAxles: "",
      noOfWheels: "",
      tankCap: "",
      price: "",
      currency: { id: 0, value: "" },
      supplyDate: "",
      maxSpeed: 0,
      speedUnit: { id: 0, value: "" },
      vehModel: { id: 0, value: "" },
      supplier: { id: 0, value: "" },
      engineMake: { id: 0, value: "" },
      cubicCapacity: "",
      seatingCap: "",
      engineNo: "",
      tyreSizeF: "",
      tyreSizeM: "",
      tyreSizeR: "",
      other: "",
      regDate: "",
      colour: "",
      specialised: false,
      motorBike: false,
      equipment: false,
      lightDuty: false,
      heavyDuty: false,
      vehicle: false,
      active: false,
      remarks: "",
    });
  };
  const AccessPriv_on_FindButton = () => {
    setPrivType("read");
    if (savePriv === true) {
      handleFind && handleFind();
    } else {
      setIcon("warning");
      setshowModalDisableButton(true);
      setshowCancel(false);
      setModalData({
        message: "No Access Privilege",
        title: "Access Denied!",
        okText: "Ok",
      });
      setOpenModal(true);
    }
  };
  const AccessPriv_on_PrintButton = () => {
    if (savePriv === true) {
      handlePrint && handlePrint();
    } else {
      setIcon("warning");
      setshowModalDisableButton(true);
      setshowCancel(false);
      setModalData({
        message: "No Access Privilege",
        title: "Access Denied!",
        okText: "Ok",
      });
      setOpenModal(true);
    }
  };
  console.log("reeeeeeeeeeeduuux😜", NewAndCancelButton);

  const AccessPriv_on_NewButton = () => {
    setPrivType("save");
    if (savePriv === true) {
      switchState && switchState(false);
      // dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      // dispatch(setGeneralValue({ expr: "vehDClear", value: true }));

      // dispatch(task_form_disable(false));
      // dispatch(settask_form_disable(false));

      // -------------------eagleMentality🦅------------------------------
      Vdispatch(setNew_Cancel_Button(false));
      Vdispatch(setEdit_Cancel_Button(true));
      Vdispatch(setSave_Update_Button(true));
      Vdispatch(setDisableForm(false));
      ResetFields();
      setOuterSelected && setOuterSelected({});
      //---- -------------------------------------------------------------
      setShowImages && setShowImages();
      //this destroys the update mode state
      // dispatch(task_footer_update(false));
      // dispatch(settask_footer_update(false));

      //clear data to redux
      dispatch(setGeneralValue({ expr: "selectedFieldData", value: [] }));
    } else {
      setIcon("warning");
      setshowModalDisableButton(true);
      setshowCancel(false);
      setModalData({
        message: "No Access Privilege",
        title: "Access Denied!",
        okText: "Ok",
      });
      setOpenModal(true);
    }
  };

  console.log("selectedRecord:", NewSelectedRecord);

  const AccessPriv_on_EditButton = () => {
    setPrivType("update");
    setVisible && setVisible() ;
    if (updatePriv === true) {
      if (outerSelected !== undefined) {
        if (Object.keys(outerSelected).length == 0) {
          // dispatch(task_form_disable(true));
          setIcon("warning");
          setshowModalDisableButton(true);
          setshowCancel(false);
          setModalData({
            message: "No record selected",
            title: "Invalid record",
            okText: "Ok",
          });
          setOpenModal(true);
          Vdispatch(setDisableForm(true));
          Vdispatch(setNew_Cancel_Button(true));
          Vdispatch(setSave_Update_Button(true));
          Vdispatch(setEdit_Cancel_Button(true));
          switchState && switchState(false);
        } else {
          //enable forms
          // dispatch(task_form_disable(true));

          // //enable Update Mode
          // dispatch(task_footer_update(true));
          //----------eagleMentality🦅------------
          Vdispatch(setDisableForm(false));
          Vdispatch(setEdit_Cancel_Button(false));
          Vdispatch(setSave_Update_Button(false));
          Vdispatch(setNew_Cancel_Button(true));
          //---------------------------------------
        }
      }
    } else {
      setIcon("warning");
      setshowModalDisableButton(true);
      setshowCancel(false);
      setModalData({
        message: "No Access Privilegde",
        title: "Access Denied",
        okText: "Ok",
      });
      setOpenModal(true);
    }
  };

  // relative to the new button
  const handleNew = () => {
    AccessPriv_on_NewButton();
  };

  const handleCancel = () => {
    // dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    // dispatch(task_form_disable(true));
    // dispatch(settask_form_disable(true));
    // // dispatch(update_route_state(false))
    // dispatch(task_footer_update(false));
    // dispatch(settask_footer_update(false));

    // dispatch(settask_footer_update(false))
    // dispatch(setGeneralValue({ expr: "updateMode", value: false }));

    //----------------eagleMentality🦅----------------------------------------
    Vdispatch(setNew_Cancel_Button(true));
    Vdispatch(setDisableForm(true));
    Vdispatch(setEdit_Cancel_Button(true));
    Vdispatch(setSave_Update_Button(true));
    //---------------------------------------------------------

    // NO ONE SHOULD DELETE THIS !!!!
    // handleCallbackCancel!();
  };

  const [refreshValue, setRefreshValue] = useState(false);
  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [updatingstatus, setUpdatingstatus] = useState(false);
  const [showCancel, setshowCancel] = useState(false);
  const [Active, setActive] = useState(false);
  const [refreshx, setRefreshx] = useState(false);
  const dispatch = useDispatch();

  const form_disable = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  console.log("form_disable: ", form_disable);

  const update_state_change = useSelector(
    (state: RootState) => state.general.taskFooterUpdate
  );

  //handle edit
  const handleUpdate = () => {
    console.log(Object.keys(selectedRecord).length);
    AccessPriv_on_EditButton();
  };

  // Three state checkboxes
  const [indeterminate, setIndeterminate] = useState<any>(true);
  const [checkedBox, setCheckedBox] = useState<any>(false);
  const [checkValue, setCheckValue] = useState<boolean | string>(""); //actual value to use
  const [searchTextValue, setSearchTextValue] = useState<string>("");

  const [criteriaDataLocal, setCriterialDataLocal] = useState({
    id: searchCriteriaData![0]?.id ?? 0,
    value: searchCriteriaData![0]?.value ?? "",
  });

  useEffect(() => {
    dispatch(setGeneralValue({ expr: "activeStatus", value: checkValue }));
    checkOnChange && checkOnChange(checkValue);
  }, [checkValue]);

  const onChange = (value: boolean) => {
    if (indeterminate === true) {
      setCheckedBox(true);
      setIndeterminate(false);
      setCheckValue(true);
    } else if (!indeterminate && checkedBox) {
      setCheckedBox(false);
      setIndeterminate(false);
      setCheckValue(false);
    } else if (!indeterminate && !checkedBox) {
      setCheckedBox(false);
      setIndeterminate(true);
      setCheckValue("");
    }
  };

  // const [searchParam, setSearchParam] = useState<any>(0);
  // const [searchTextValue, setSearchTextValue] = useState("");
  // const [startDate, setStartDate] = useState<any>(
  //   dayjs().format("DD MMM YYYY")
  // );
  // const [endDate, setEndDate] = useState<any>(dayjs().format("DD MMM YYYY"));
  //   const [employeeId,userId, userObject ] = useAccountInfo();

  return (
    <div
      style={{
        borderColor: borderTheme,
        borderWidth: "1px",
        width: "4000px",
        height: 32,
      }}
      className="px-0.5 mt-0.5 rounded  w-full h-full flex  border-gray-200 mb-1"
    >
      <div className="">
        <ModalTemplate
          icon_width={modalData.iconSize === "large" ? 100 : 50}
          icon={icon}
          cancelText={modalData.cancelText}
          disableCancel={!showCancel}
          cancelHandler={() => {
            setOpenModal(false);
          }}
          open={openModal}
          okHandler={() => {
            setOpenModal(false);
          }}
          message={modalData.message}
          okText={modalData.okText}
          title={modalData.title}
        />
        <ul className="flex justify-evenly w-full items-center h-full py-0.5">
          {/* {disablex || updateMode ? (
            <li
              id="newRecord"
              onClick={() => handleNew()}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img className="pr-1 " src={newfile} />
                New{" "}
              </span>
              <Tooltip
                target="#newRecord"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">New Record</p>
              </Tooltip>
            </li>
          ) : (
            <li
              id="close-cancel"
              onClick={() => handleCancel()}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img className="pr-1 " src={close} />
                Cancel{" "}
              </span>
              <Tooltip
                target="#close-cancel"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Cancel</p>
              </Tooltip>
            </li>
          )} */}

          {NewAndCancelButton === true ? (
            <li
              id="newRecord"
              onClick={() => handleNew()}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img className="pr-1 " src={newfile} />
                New{" "}
              </span>
              <Tooltip
                target="#newRecord"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">New Record</p>
              </Tooltip>
            </li>
          ) : (
            <li
              id="close-cancel"
              onClick={() => handleCancel()}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img className="pr-1 " src={close} />
                Cancel{" "}
              </span>
              <Tooltip
                target="#close-cancel"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Cancel</p>
              </Tooltip>
            </li>
          )}

          {/* {!updateMode ? (
            <li
              id="editRecordst"
              onClick={() => {
                handleUpdate();
              }}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img
                  className="dark:bg-slate-900 pr-1 dark:text-gray-100 duration-100"
                  src={edit}
                />
                Edit{" "}
              </span>
              <Tooltip
                target="#editRecordst"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Edit Record</p>
              </Tooltip>
            </li>
          ) : (
            <li
              id="close-cancel"
              onClick={() => handleCancel()}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img className="pr-1 " src={close} />
                Cancel{" "}
              </span>
              <Tooltip
                target="#close-cancel"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Cancel</p>
              </Tooltip>
            </li>
          )} */}
          {EditAndCancelButton === true ? (
            <li
              id="editRecordst"
              onClick={() => {
                handleUpdate();
              }}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img
                  className="dark:bg-slate-900 pr-1 dark:text-gray-100 duration-100"
                  src={edit}
                />
                Edit{" "}
              </span>
              <Tooltip
                target="#editRecordst"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Edit Record</p>
              </Tooltip>
            </li>
          ) : (
            <li
              id="close-cancel"
              onClick={() => handleCancel()}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img className="pr-1 " src={close} />
                Cancel{" "}
              </span>
              <Tooltip
                target="#close-cancel"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Cancel</p>
              </Tooltip>
            </li>
          )}
          <li
            id="switch"
            onClick={toggler}
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
          >
            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
              {" "}
              <img
                className="dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"
                src={alter}
              />
              Switch{" "}
            </span>
            <Tooltip
              target="#switch"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Switch interface / table view</p>
            </Tooltip>
          </li>

          <li className="px-1 flex items-center flex-row ">
            <label style={{ width: "87px" }} className=" text-xs">
              Search Criteria
            </label>
            <Select
              value={criteriaDataLocal}
              dropdownMatchSelectWidth={false}
              className=" mt-0.5"
              onChange={(data: any) => {
                const criteriaData = JSON.parse(data);
                setCriterialDataLocal({
                  id: criteriaData?.index,
                  value: criteriaData?.criteria,
                }); //local
                searchCriteriaOnchange &&
                  searchCriteriaOnchange(
                    criteriaData?.index,
                    criteriaData?.criteria
                  ); //external
              }}
              id="search"
              size="small"
              showSearch={true}
              style={{ width: 90, height: "25px" }}
            >
              {searchCriteriaData?.map(({ id, value }, index) => (
                <Select.Option
                  key={id}
                  value={JSON.stringify({ index: index, criteria: value })}
                >
                  {value}
                </Select.Option>
              ))}
            </Select>
          </li>
        </ul>
      </div>

      <div className="flex ml-2">
        <ul className=" flex items-center">
          <div className="text-xs">Search Text</div>
          <li className="px-1">
            <Input
              onChange={(e) => {
                let changeText = e.target.value;
                searchTextOnchange && searchTextOnchange(changeText);
                setSearchTextValue(changeText);
              }}
              value={searchTextValue}
              size="small"
              allowClear
              placeholder=""
              style={{ width: `${sideAlert === true ? 20 : 180}` }}
            />
          </li>

          <li className="px-0.5">
            <DatePicker
              value={dayjs(startDate)}
              clearIcon={<span style={{ display: "none" }}></span>}
              onChange={(e)=>{
                const hold = dayjs(e).format('DD MMM YYYY');
                startDateChanged!(hold)
                }
                }
              size="small"
              placeholder="Start Date"
              style={{ width: 125 }}
              format={"DD MMM YYYY"}
            />
          </li>
          <li className="px-0.5">
            <DatePicker
              value={dayjs(endDate)}
              clearIcon={<span style={{ display: "none" }}></span>}
              onChange={(e) => {
                const hold = dayjs(e).format('DD MMM YYYY');
                endDateChanged!(hold);
              }}
              size="small"
              placeholder="End Date"
              style={{ width: 125 }}
              format={"DD MMM YYYY"}
            />
          </li>

          <li
            id="status"
            style={{ borderWidth: 1 }}
            className="hover:cursor-pointer mr-1  text-xs px-1  text-gray-600  flex items-center  rounded border-gray-300"
          >
            <Checkbox
              checked={checkedBox}
              indeterminate={indeterminate}
              onChange={(checkValue: any) => {
                onChange(checkValue);
                checkOnChange!(checkValue.target.checked);
              }}
            />
            <Tooltip
              target="#status"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Show Status</p>
            </Tooltip>
          </li>
          <li
            onClick={() => {
              if (handleFind) {
                AccessPriv_on_FindButton();
              }
            }}
            id="findst"
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
          >
            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
              <FaBinoculars size={15.5} color="#007AFF" />
            </span>
            <Tooltip
              target="#findst"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Find</p>
            </Tooltip>
          </li>

          {/* refresh */}
          <li
            onClick={() => {
              handleRefresh && handleRefresh();
              // searchTextOnchange && searchTextOnchange("");
              // searchCriteriaOnchange &&
              //   searchCriteriaOnchange(
              //     searchCriteriaData![0]?.id ?? 0,
              //     searchCriteriaData![0]?.value ?? ""
              //   );
              // startDateChanged && startDateChanged(dayjs(startingDate).format('DD MMM YYYY'))
              // endDateChanged && endDateChanged(dayjs(currentDate).format('YYYY MMM DD'))  
              // checkOnChange && checkOnChange("");
              //setlocal states
              setSearchTextValue("");
              setCheckedBox(false);
              setIndeterminate(true);
              setCheckValue("");
              setCriterialDataLocal({
                id: searchCriteriaData![0]?.id ?? 0,
                value: searchCriteriaData![0]?.value ?? "",
              });
            }}
            id="refreshst"
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
          >
            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
              {" "}
              <img
                className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                src={reset}
              />
            </span>
            <Tooltip
              target="#refreshst"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Refresh</p>
            </Tooltip>
          </li>

          {/* <li
            onClick={() => AccessPriv_on_PrintButton()}
            id="printst"
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
          >
            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
              {" "}
              <img
                className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                src={printer}
              />
            </span>
            <Tooltip
              target="#printst"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Print/Export</p>
            </Tooltip>
          </li> */}

          <li className="hover:cursor-pointer ml-20  px-2  flex items-center">
            {/* {form_disable && (
              <p className=" italic font-semibold text-gray-600">Done.</p>
            )} */}

            {/* {!form_disable && (
              <div className="  flex flex-row items-center">
                <img
                  style={{ width: "31px", height: "31px" }}
                  src={statusLoader}
                  alt="gif"
                />
                {!update_state_change ? (
                  <p className=" italic text-gray-600">New Record</p>
                ) : (
                  <p className=" italic text-gray-600">Editing</p>
                )}
              </div>
            )} */}
            {EditAndCancelButton === true && NewAndCancelButton === true ? (
              <p className=" italic font-semibold text-gray-600">Done.</p>
            ) : EditAndCancelButton === true && NewAndCancelButton === false ? (
              <>
                {" "}
                <img
                  style={{ width: "31px", height: "31px" }}
                  src={statusLoader}
                  alt="gif"
                />
                <p className=" italic text-gray-600">New Record</p>
              </>
            ) : EditAndCancelButton === false && NewAndCancelButton === true ? (
              <>
                {" "}
                <img
                  style={{ width: "31px", height: "31px" }}
                  src={statusLoader}
                  alt="gif"
                />
                <p className=" italic text-gray-600">Editing</p>
              </>
            ) : (
              <p className=" italic font-semibold text-gray-600">Done.</p>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

const data = [
  {
    label: "Registration No",
  },
  {
    label: "Chassis No",
  },
  {
    label: "Vehicle Class",
  },
  { label: "Vehicle Make" },
  { label: "Vehicle Model" },
  { label: "Manufacturer" },
  { label: "Supplier" },
  { label: "Manufacture Date" },
  { label: "Supply Date" },
];

// const data = [
//     { id: 1, text: "Task",  },
//     { id: 2, text: "Progress Activity",  },
//     { id: 3, text: "Task & Start Date",  },
//     { id: 4, text: "Task & Completion Date",  },
//     { id: 5, text: "Task & Due Date", },
//     { id: 6, text: "Progress Activity & Start Date", },
//     { id: 7, text: "Progress Activity & Completion Date",  },
//     { id: 8, text: "Progress Activity & Due Date", },
//     { id: 9, text: "Status", },
//     { id: 10, text: "Status & Start Date", },
//     { id: 11, text: "Status & Completion Date", },
//     { id: 12, text: "Status & Due Date", },
//     { id: 13, text: "Priority", },
//     { id: 14, text: "Priority & Start Date", },
//     { id: 15, text: "Priority & Completion Date", },
//     { id: 16, text: "Priority & Due Date", },
//     { id: 17, text: "Assigned By", },
//     { id: 18, text: "Assigned By & Start Date", },
//     { id: 19, text: "Assigned By & Completion Date", },
//     { id: 20, text: "Assigned By & Due Date", },
//     { id: 21, text: "Assigned To", },
//     { id: 22, text: "Assignred To & Start Date", },
//     { id: 23, text: "Assigned To & Completion Date", },
//     { id: 24, text: "Assigned To & Due Date", },
// ];