import { Avatar, Space } from 'antd';
import React from 'react'


interface props {
    size?: number;
    styles?: any
    avatarIcon?: any
    avatarName?: string
    shape ?: "circle" | "square" 

}

const AvatarTemplate = ({ size, styles, avatarIcon, avatarName, shape }: props) => {
    return (
        <div className='w-full'>
            <Space wrap>
                <Avatar shape={shape} size={size ?? 50} src={<img src={avatarIcon} alt="avatar img." />}   >{avatarName}</Avatar>
            </Space>

        </div>
    )
}

export default AvatarTemplate