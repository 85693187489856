import VehicleCardDetails from "./vehicleCard-details";

const VehicleCard = () => {
  return (
    <>
      {/* <div className="mx-2">
      </div> */}
      <div className="px-2 mb-0.5  ">
        <VehicleCardDetails />
      </div>
    </>
  );
};

export default VehicleCard;
