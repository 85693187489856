export const elogbook_columns = [
    {id : 1, caption : 'LOG EVENT', dataField : 'exnName',dataType : 'string', width : 100, fixed : true , visible : true},
    {id : 2, caption : 'EVENT DATE', dataField : 'exnName',dataType : 'string', width : 100, fixed : true , visible : true},
    {id : 3, caption : 'TIME', dataField : 'exnName',dataType : 'string', width : 100, fixed : true , visible : true},
    {id : 4, caption : 'TYPE', dataField : 'exnName',dataType : 'string', width : 100, fixed : true , visible : true},
    {id : 5, caption : 'LOG', dataField : 'exnName',dataType : 'string', width : 250, fixed : true , visible : true},
    {id : 6, caption : 'RPTN.STA', dataField : 'exnName',dataType : 'string', width : 130, fixed : true , visible : true},
    {id : 7, caption : 'CORRESPONDING', dataField : 'exnName',dataType : 'string', width : 100, fixed : true , visible : true},
   
]


export const elogbook_columns2 = [
    {id : 1, caption : 'Station', dataField : '',dataType : 'string', width : 100, fixed : false , visible : true},
    {id : 2, caption : 'Active', dataField : '',dataType : 'string', width : 100, fixed : false , visible : true},
    {id : 3, caption : 'OPS?', dataField : '',dataType : 'string', width : 100, fixed : false , visible : true},
    {id : 4, caption : 'Remarks', dataField : '',dataType : 'string', width : 400, fixed : false , visible : true},
]


export const elogbook_columns3 = [
    {id : 1, caption : 'STAFF Name', dataField : '',dataType : 'string', width : 100, fixed : false , visible : true},
    {id : 2, caption : 'Job Title', dataField : '',dataType : 'string', width : 100, fixed : false , visible : true},
    {id : 3, caption : 'Dept', dataField : '',dataType : 'string', width : 100, fixed : false , visible : true},
    {id : 4, caption : 'Section', dataField : '',dataType : 'string', width : 100, fixed : false , visible : true},
    {id : 5, caption : 'Location', dataField : '',dataType : 'string', width : 100, fixed : false , visible : true},
    {id : 6, caption : 'Remarks', dataField : '',dataType : 'string', width : 400, fixed : false , visible : true},
]

export const elogbook_columns4 = [
    {id : 1, caption : 'STAFF No', dataField : '',dataType : 'string', width : 100, fixed : false , visible : true},
    {id : 2, caption : 'STAFF Name', dataField : '',dataType : 'string', width : 100, fixed : false , visible : true},
    {id : 3, caption : 'Job Title', dataField : '',dataType : 'string', width : 100, fixed : false , visible : true},
    {id : 4, caption : 'Dept', dataField : '',dataType : 'string', width : 100, fixed : false , visible : true},
    {id : 5, caption : 'Section', dataField : '',dataType : 'string', width : 100, fixed : false , visible : true},
    {id : 6, caption : 'Location', dataField : '',dataType : 'string', width : 100, fixed : false , visible : true},
    {id : 7, caption : 'Remarks', dataField : '',dataType : 'string', width : 400, fixed : false , visible : true},
]
export const elogbook_columns5 = [
    {id : 1, caption : 'STAFF No', dataField : '',dataType : 'string', width : 100, fixed : true , visible : true},
    {id : 2, caption : 'STAFF Name', dataField : '',dataType : 'string', width : 100, fixed : true , visible : true},
    {id : 3, caption : 'Substation', dataField : '',dataType : 'string', width : 100, fixed : true , visible : true},
    {id : 4, caption : 'Shift LDR?', dataField : '',dataType : 'string', width : 100, fixed : true , visible : true},
    {id : 5, caption : 'LOG?', dataField : '',dataType : 'string', width : 100, fixed : true , visible : true},
    {id : 6, caption : 'View?', dataField : '',dataType : 'string', width : 100, fixed : true , visible : true},
    {id : 7, caption : 'Home?', dataField : '',dataType : 'string', width : 100, fixed : true , visible : true},
    {id : 8, caption : 'DEF.Home?', dataField : '',dataType : 'string', width : 100, fixed : true , visible : true},
    {id : 9, caption : 'IRSC', dataField : '',dataType : 'string', width : 100, fixed : true , visible : true},
    {id : 10, caption : 'TOS', dataField : '',dataType : 'string', width : 100, fixed : true , visible : true},
    {id : 11, caption : 'Flag?', dataField : '',dataType : 'string', width : 100, fixed : true , visible : true},
    {id : 12, caption : 'Active', dataField : '',dataType : 'string', width : 100, fixed : true , visible : true},
    {id : 13, caption : 'SHIFT MEM.', dataField : '',dataType : 'string', width : 100, fixed : false , visible : true},
    {id : 14, caption : 'MS?', dataField : '',dataType : 'string', width : 100, fixed : false , visible : true},
    {id : 15, caption : 'NCC?', dataField : '',dataType : 'string', width : 100, fixed : false , visible : true},
    {id : 16, caption : 'Operational?', dataField : '',dataType : 'string', width : 100, fixed : false , visible : true},
    {id : 17, caption : 'DATE ASSIGNED', dataField : '',dataType : 'string', width : 100, fixed : false , visible : true},
    {id : 18, caption : 'DATE UNASSIGNED', dataField : '',dataType : 'string', width : 100, fixed : false , visible : true},
    {id : 19, caption : 'OPERATOR LOC', dataField : '',dataType : 'string', width : 100, fixed : false , visible : true},
    {id : 20, caption : 'JOB TITLE', dataField : '',dataType : 'string', width : 100, fixed : false , visible : true},
    {id : 21, caption : 'DEPT', dataField : '',dataType : 'string', width : 100, fixed : false , visible : true},
    {id : 22, caption : 'SECTION', dataField : '',dataType : 'string', width : 100, fixed : false , visible : true},
    {id : 23, caption : 'REMARKS', dataField : '',dataType : 'string', width : 400, fixed : false , visible : true},
]