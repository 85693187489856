import axios from "axios";
import { trigger } from "devextreme/events";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { setTier2CheckboxValue, tier2CheckboxSaveOnclick, tier2CheckboxUpdateOnclick } from "../../../../features/forms/tier2checkbox/tier2SliceCheckbox";
import { setGeneralValue, setFormData } from "../../../../features/generalSlice";
import useFetch from "../../../../hooks/useFetch";
import { ModalTemplate } from "../../../../templates/modal";
import { validationsData, saveModalData, updateModalData, api_url, currentDate } from "../../../accessories/component_infos";
import CustomLoader from "../../../accessories/custom-loader";
import Setups_Template from "../../../human-resource/setups/.setups_templates/Setups_Template";
import { checkboxTypes, exprsTypes, searchTextTypes, selectExprsTypes, selectsTypes, statusTypes } from "../../../human-resource/setups/data/types/selectsTypes";
import { sinitCols } from "./data/staffIniatives";
import { error } from "console";
import { WarningAlert } from "../../../accessories/warningAlert";
import { useBusyLoader } from "../../../../hooks/useBusyLoader";
import { UpdateDataFunc } from "../../../../functions/update";
import { PostDataFunc  } from "../../../../functions/post";
import { useAccountInfo } from "../../../../hooks/useAccountInfo";
import dayjs from "dayjs";
import { useFormPriviledge } from "../../../../hooks/useFormPriviledge";
import { BottomBtns } from "../../../../templates/butttons";

interface stateTypes {
  searchText: { temp: string; text: string };
  active: { temp: string; status: string };
  refresh?: boolean;
}

interface modalTypes {
  info: any, 
      open: boolean, 
      icon: 'warning'| 'question'| 'success',
      disableButton: boolean,
}

interface prop {
  currentStates?:any;
  setCurrentStates?:any;
  refreshState?:()=>void;
}


export const PerformanceStaffInitiatives = forwardRef(({currentStates,setCurrentStates,refreshState}:prop, ref) => {
  const saveSuccessText = {
    message: "Staff Initiative added Successfully",
    ok: "Ok",
    title: "Save Succesful",
  }; // successful save modal message
  const updateSuccessText = {
    message: "Staff Initiative updated Successfully",
    ok: "Ok",
    title: "Update Successful",
  }; // successful update modal message

  
  const [modalData, setModalData] = useState<modalTypes>({
    info: saveModalData, 
    open: false, 
    icon: 'warning',
    disableButton: false,
})
const[Icon,setIcon]=useState<'warning'|'question'|'success'>('warning')
const[disableButton,setDisableButton]=useState<boolean>(true)

  // store data expressions[unique]
  const idExpr: String = "sinIdpk";
  const nameExpr: string = "sinName";
  const shortnameExpr: string = "sinShtName";
  const orderExpr: string = "sinOrder";
  const activeExpr: string = "sinActive";
  const remarksExpr: string = "sinRmks";
  const inputsExprsData: any = {
    idExpr: idExpr,
    nameExpr: nameExpr,
    shortnameExpr: shortnameExpr,
    orderExpr: orderExpr,
    activeExpr:activeExpr,
    remarksExpr: remarksExpr,
  };
  const selectAfterExpr: selectExprsTypes[] = [
    {
      idExpr: "rmmName",
      nameExpr: "rmmName",
      label: "staffInitiaves",
    },
    { idExpr: "areIdpk", nameExpr: "areName", label: "area" },
    { idExpr: "areIdpk", nameExpr: "areName", label: "area" },
    { idExpr: "areIdpk", nameExpr: "areName", label: "area" },
    { idExpr: "areIdpk", nameExpr: "areName", label: "area" },
  ];
  const checkboxesExpr: selectExprsTypes[] = [
    {
      idExpr: "sinPreventManualUpdatesForAll",
      nameExpr: "sinPreventManualUpdatesForAll",
    },
    { idExpr: "sinEnforceResultMonitoringModule", nameExpr: "sinEnforceResultMonitoringModule" },
    { idExpr: "mtpInsuranceRenewal", nameExpr: "mtpInsuranceRenewal" },
    { idExpr: "areIdpk", nameExpr: "areName" },
    { idExpr: "areIdpk", nameExpr: "areName" },
  ];
  const dispatch = useDispatch();

  const storeExprs = () => {
    const exprs: exprsTypes[] = [
      { expr: "idExpr", value: idExpr },
      { expr: "nameExpr", value: nameExpr },
      { expr: "shortnameExpr", value: shortnameExpr },
      { expr: "selectAfterExprs", value: selectAfterExpr[0] },
      {expr:'existsUrl',value:`StaffInitiatives`},
    ];
    for (let i = 0; i < exprs.length; i++) {
      dispatch(
        setTier2CheckboxValue({ expr: exprs[i].expr, value: exprs[i].value })
      );
    }

    // to general slice
    const generalExprs: exprsTypes[] = [
      { expr: "selectAfterExprs", value: selectAfterExpr },
      { expr: "inputExprs", value: inputsExprsData },
      { expr: "checkboxExprs", value: checkboxesExpr },
    ];
    for (let i = 0; i < generalExprs.length; i++) {
      dispatch(
        setGeneralValue({
          expr: generalExprs[i].expr,
          value: generalExprs[i].value,
        })
      );
    }
  };

  const [states, setStates] = useState<stateTypes>({
    searchText: { temp: "", text: "" },
    active: { temp: "", status: "" },
    refresh: false,
  });

// Search criteria states
const genFormat = "YYYY-MM-DD";
const today = dayjs().endOf("month").format(genFormat);
const initialStart = dayjs("2019-02-01").format(genFormat);

const [searchText, setSearchText] = useState<searchTextTypes>({
temp: "",
text: "",
});
const [active, setActive] = useState<statusTypes>({
temp: "",
status: "",
});
console.log({
searchText,
active,
});

const [refreshstate, setRefreshstate] = useState<boolean>(false);  
const [refresh, setRefresh] = useState<boolean>(false); //refresh data state for data creation or update
  const[status,setStatus]=useState<boolean|string>('');
  const[MonitorResult_data,MonitorResult_error,MonitorResult_loading]=useFetch(`PfmStaffInitiatives/PopLueResultsModule`);
  const [privType, setPrivType] = useState<string>("save");
    const [savePriv, readPriv, updatePriv] = useFormPriviledge(
      privType,
    );

  //modal states
  //--general--
  const triggerSuccessModal = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.successModalState
  ); //trigger success modal
  const SuccessModalText = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.successText
  ); //success modal text

  //--for backend errors--
  const [errState, setErrState] = useState<boolean>(false);
  const [errTitle, seterrTitle] = useState<string>("");
  const [errMessage, seterrMessage] = useState<string>("");

  //--warning modal--
  const triggerwarningModal = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.warningState
  ); //trigger warning
  const warningInfos: any = validationsData; //warning type details
  const warningDataExpr = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.warningDataExpr
  ); //warning type selector

  //--save data modal--
  const saveData = saveModalData; //save modal details[data]
  const triggersaveModal = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.saveDataOnclick
  ); //trigger save modal

  //--Update data modal--
  const updateIndInfos = updateModalData; //update modal details[data]
  const triggerupdateModal: boolean = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.updateDataOnclick
  ); //trigger update question

  // on page resize height responsiveness
  const [trigger, setTrigger] = useState<boolean>(false);
  useImperativeHandle(ref, () => ({
    test: () => {
      setTrigger(!trigger);
    },
  }));

  // get current values from  [slice dependent on form type]

  const name = useSelector((state: RootState) => state.tier2Ckeckboxforms.name);
  const shortname = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.shortname
  );
  const order = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.order
  );

 const monitorResult = useSelector((state:RootState)=>state.tier2Ckeckboxforms.selectAfter0);


  // const active = useSelector(
  //   (state: RootState) => state.tier2Ckeckboxforms.active
  // );
  const remarks = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.remarks
  );

  console.log(remarks)
  const preventStaff = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.checkboxInline0
  );
  const preventSelfAssign = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.checkboxInline1
  );
  const enforceResults = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.checkbox1
  );
  const selectedID = useSelector(
    (state: RootState) => state.general.SelectedFieldID
  );

    // New Button Privilege 

    const newAccessPriv = () => {
      setPrivType("save");
     
      if (savePriv === true) {
          dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
        setCurrentStates({
          sinName: "",
          sinShtName: "",
          sinPreventManualUpdatesForAll: "",
          sinPreventStaffSelfAssignmentForAll: "",
          sinEnforceResultMonitoringModule: "",
          sinResultMonitoringModuleIdfk: "",
          sinOrder: 0,
          sinActive: false,
          sinRmks: "",
          disableForms: false, 
      })       

      } else {
          dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
          
          setModalData({
              ...modalData, 'info':{
              message:"No Privilege Access",
              title:'Access Denied!',
              okText:'Ok',
              cancelText:'No,Cancel',              
              },
              open: true,
              disableButton: true
          })         
      }
    };

    const SaveAccessPriv =() =>{
      setPrivType("save")
      if(savePriv === true){
          dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
          validateSave('save')

      }
      else{
          dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
          setModalData({
              ...modalData, 'info':{
              message:"No Privilege Access",
              title:'Access Denied!',
              okText:'ok',
              cancelText:'No,Cancel'
              },
              open: true
          })
      }
    }


  //post form Data
      //STATE INITIALIATION

      const validateSave = (type:any) => {
        setModalData({
            ...modalData, 
            icon: 'question',
            disableButton: true
        })
        console.log(currentStates);
        if(currentStates?.sinName === ""){
            setDisableButton(true);
            setModalData({
                ...modalData,
                info: {
                    message: "",
                    title: "Input a Name",
                    okText: "Ok",
                    cancelText: "Cancel",
                },
                open: true
            });
           

        } else if (currentStates?.sinShtName === "") {
            setDisableButton(true);
            setModalData({
                ...modalData,
                info: {
                    message: "",
                    title: 'Invalid short name',
                    okText: 'ok',
                    cancelText: 'Cancel'
                },
                open: true
            });
            
        } else if (currentStates?.sinPreventManualUpdatesForAll === "" && currentStates?.sinPreventStaffSelfAssignmentForAll === "") {
            // setDisableButton(true)
            setModalData({
                ...modalData,'info': {
                message:"",
                title:'',
                okText:'ok',
                cancelText:'Cancel'
                },
                open: true
            })
          
        }else if(currentStates?.sinEnforceResultMonitoringModule === ""){
            // setDisableButton(true)
            setModalData({
                ...modalData, 'info':{
                message:"Select an Assigned Date",
                title:'Invalid',
                okText:'ok',
                cancelText:'No,Cancel'
                },
                open: true
            })
            
        }else if(currentStates?.sinResultMonitoringModuleIdfk.id === 0){
           
            setModalData({
                ...modalData, 'info':{
                message:"Monitor results?",
                title:'Invalid',
                okText:'ok',
                cancelText:'No,Cancel'
                },
                open: true
            })
            
        }
        else if(currentStates?.sinOrder === 0){
           
          setModalData({
              ...modalData, 'info':{
              message:"Set an order",
              title:'Invalid',
              okText:'ok',
              cancelText:'No,Cancel'
              },
              open: true
          })
          
      }
      else if(currentStates?.sinActive === ""){
        setDisableButton(true)
        setModalData({
            ...modalData, 'info':{
            message:"Select active status",
            title:'Invalid',
            okText:'ok',
            cancelText:'No,Cancel'
            },
            open: true
        })
      }
        else if(currentStates?.sinRmks === ""){
            setDisableButton(true)
            setModalData({
                ...modalData, 'info':{
                message:"Input Remarks",
                title:'Invalid',
                okText:'ok',
                cancelText:'No,Cancel'
                },
                open: true
            })
          }

            if(type === 'save'){
                setModalData({
                    ...modalData, 'info': saveModalData,
                    open: true, 
                    icon: 'question',
                    disableButton: false
                })
            }else if(type === 'update'){
                setModalData({
                    ...modalData, 'info': updateModalData,
                    open: true,
                    icon: 'question',
                    disableButton: false
                })
        }
    }

  // post
  const postData = async () => {
    dispatch(tier2CheckboxSaveOnclick('type1'));
    try {
      const res = await PostDataFunc (
        `PfmStaffInitiatives/CreateStaffInitiative`,
        {
          sinName: name,
          sinShtName: shortname,
          sinPreventManualUpdatesForAll: preventStaff,
          sinPreventStaffSelfAssignmentForAll: preventSelfAssign,
          sinEnforceResultMonitoringModule: enforceResults,
          sinResultMonitoringModuleIdfk:  monitorResult.id,
          sinOrder: order,
          sinActive: active,
          sinRmks: remarks,
          sinCreatedBy: userId.toString()
        });
        setModalData({...modalData,
          open: false,
      });
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      console.log(res.data);
      refreshState && refreshState()
      setIcon("success")
      setModalData({...modalData,
       info:
        {
        message:"Staff Initiative Saved Successfully",
        title:'Success',
        okText:'ok',
        cancelText:''
    }, 
    icon: 'success', 
    open: true,
    disableButton: true,
})

    } catch (error: any) {
      console.log(error);

      //set states for db errors modal
      setErrState(true);
      seterrTitle(saveModalData.error.title);
     seterrMessage(saveModalData.error.message);
    } finally{
      setBusyLoader('');
    }
  };

  //Update Form data
  //validate data
  const validateDataUpdate = () => {
    dispatch(tier2CheckboxUpdateOnclick('type1'));
  };
  // update
  const UpdateData = async () => {
    dispatch(
      setTier2CheckboxValue({ expr: "updateDataOnclick", value: false })
    );

    try {
      setBusyLoader(updateModalData.progress);

      const res = await UpdateDataFunc(
        `PfmStaffInitiatives/UpdateStaffInitiatives/${selectedID}`,
        {
          sinName: name,
          sinShtName: shortname,
          sinPreventManualUpdatesForAll: preventStaff,
          sinPreventStaffSelfAssignmentForAll: preventSelfAssign,
          sinEnforceResultMonitoringModule: enforceResults,
          sinResultMonitoringModuleIdfk:  monitorResult.id,
          sinOrder: order,
          sinActive: active,
          sinRmks: remarks,
          sinCreatedBy: userId.toString()
        }
      );
      setRefresh(!refresh);

      const exprs: exprsTypes[] = [
        { expr: "updateDataOnclick", value: false },
        { expr: "successModalState", value: true },
        { expr: "successText", value: updateSuccessText },
      ];
      for (let i = 0; i < exprs.length; i++) {
        dispatch(
          setTier2CheckboxValue({ expr: exprs[i].expr, value: exprs[i].value })
        );
      }
    } catch (error: any) {
      //set states for db errors modal
      console.log(error)

      setErrState(true);
      seterrTitle(updateModalData.error.title);
      seterrMessage(updateModalData.error.message);
    } finally{
      setBusyLoader('');
    }
  };

  //modals data
  <ModalTemplate
        icon={modalData.icon}
        disableCancel={modalData.disableButton}
        open={modalData.open}
        okHandler={()=>{
            modalData.info === saveModalData ? postData(): modalData.info === updateModalData ? UpdateData() : 
            setModalData({...modalData, open: false})
        }}
        cancelHandler={() =>{ setModalData({...modalData, open: false})}}
        message={modalData.info.message}
        okText={modalData.info.okText}
        title={modalData.info.title}
        />
  const[busyLoader,setBusyLoader]=useBusyLoader();
  const[employeeId, userId] = useAccountInfo();

  // data for datagrid
  const [data, dataError, dataLoading] = useFetch(
    `PfmStaffInitiatives/SearchStaffInitiative?searchText=${searchText.text}&activeState=${true}`,
    refreshstate,
  ); 
  // useEffect(()=>{
  //   if (data) {
  //     storeExprs(); // run func to store expressions on store
  //     dispatch(setGeneralValue({ expr: "idExpr", value: idExpr })); // dispatch fetched data to reduxstore
  //     dispatch(setFormData(data)); // dispatch fetched data to reduxstore
  //   }  
    
  //   dataLoading?setBusyLoader('.'):setBusyLoader('')
  // },[data,dataLoading])



  // form parameters
  const form_header:string = "Staff Initiative Details";

  const checboxList: checkboxTypes[] = [
    { id: 0, label: "Prevent Staff Manual Updates?" ,label2 : "Prevent Staff Self Assign.?",nameExpr:'sinPreventManualUpdatesForAll',nameExpr2:'sinPreventStaffSelfAssignmentForAll'},
    { id: 1, label: "Enforce Results monitoring from module?"},
  ];

  const selectsAfter: selectsTypes[] = [
    { id: 0, label: "Monitor Results From", dataExpr:'rmmName',idExpr:'rmmIdpk', optionsData : MonitorResult_data},
  ];

  
  return (
    <>
      {dataError&&(
        <WarningAlert/>
      )}

      {/* {modals.map(({disableCancel,icon,okHandler,cancelHandler,open,cancelText,okText,message,title}:modalTypes,index)=>{
        return(
            <div key = {index}>
                <ModalTemplate  icon= {icon && icon} disableCancel = {disableCancel} cancelText = {cancelText && cancelText} open = {open} okHandler={okHandler} cancelHandler={cancelHandler} message= {message} okText= {okText} title={title}/>
            </div>
        )
      })} */}

      <Setups_Template
      createData={() => validateSave('save')}
      updateData={()=>{validateSave('update')}}
      datagridData={data}
      formWidth={600}
      formTier="tier2checkbox"
      checkboxlist={checboxList}
      labelCol={10}
      datagridColumns={sinitCols}
      trigger={false}
      selects_after_constants={selectsAfter}
      formHeader={form_header}     
      searchTextChange={(txt)=>{setSearchText({temp:txt,text:searchText.text})}}
      handleSearch={()=>{setSearchText({temp:searchText.temp,text:searchText.temp}); setStatus(active.temp)}}
      
    />
    </>
  );
});
  