export const mainGridColumns = [ 
  {id : 0, caption : 'EXAM',dataField : 'exnName',dataType : 'string', width : 120, fixed : true , visible : true},
  {id : 1, caption : 'START DATE',dataField : 'ergStartDate',dataType : 'datetime', width : 160, fixed : true , visible : true},
  {id : 2, caption : 'END DATE',dataField : 'ergEndDate',dataType : 'datetime', width : 160, fixed : true , visible : true},
  {id : 3, caption : 'STAFF NAME',dataField : 'empName',dataType : 'string', width : 160, fixed : true , visible : true},
  {id : 4, caption : 'STAFF No',dataField : 'empStaffNo',dataType : 'string', width : 75, fixed : false , visible : true},
  {id : 5, caption : 'WRITTEN ON',dataField : 'ergWrittenOn',dataType : 'datetime', width : 160, fixed : false , visible : true},
  {id : 6, caption : 'SUBMITTED ON',dataField : 'ergSubmittedOn',dataType : 'datetime', width : null, fixed : false , visible : true},
  {id : 7, caption : 'DURATION',dataField : 'ergDuration',dataType : '', width : 50, fixed : false , visible : true},
  {id : 8, caption : 'PASS MARK',dataField : 'ergPassMark',dataType : '', width : 50, fixed : false , visible : true},
  {id : 9, caption : 'NUMBER OF QUESTION',dataField : 'exnNoOfQuestions',dataType : '', width : 50, fixed : false , visible : true},
  {id : 10, caption : 'PASSED',dataField : 'ergPassed',dataType : 'boolean', width : 70, fixed : false , visible : true},
  {id : 11, caption : 'ACTIVE?',dataField : 'ergActive',dataType : 'boolean', width : 90, fixed : false , visible : true},
  {id : 12, caption : 'SMS ALERT',dataField : 'ergSmsSent',dataType : 'boolean', width : 50, fixed : false , visible : true},
  {id : 13, caption : 'SMS COUNT',dataField : 'ergSmsCount',dataType : 'boolean', width : 50, fixed : false , visible : true},
  {id : 14, caption : 'JOB TITLE',dataField : 'jbtName',dataType : 'string', width : 80, fixed : false , visible : true},
  {id : 15, caption : 'DEPT',dataField : 'dptShtName',dataType : 'string', width : 80, fixed : false , visible : true},
  {id : 16, caption : 'SECTION',dataField : 'sxnShtName',dataType : 'string', width : 80, fixed : false , visible : true},
  {id : 17, caption : 'UNIT',dataField : 'empIntercom',dataType : 'string', width : 80, fixed : false , visible : true},
  {id : 18, caption : 'LOCATION',dataField : 'locName',dataType : 'string', width : 80, fixed : false , visible : true},
  {id : 19, caption : 'REMARKS',dataField : 'ergRmks',dataType : 'string', width : 4000, fixed : false , visible : true},
]

export const staffList = [ 
  {id : 0, caption : 'No',dataField : 'empStaffNo',dataType : 'string', width : 50, fixed : true , visible : true},
  {id : 1, caption : 'Name',dataField : 'empName',dataType : 'string', width : 160, fixed : true , visible : true},
  {id : 2, caption : 'Dept',dataField : 'dptShtName',dataType : 'string', width : 80, fixed : true , visible : true},
  {id : 3, caption : 'Job Title',dataField : 'jbtShtName',dataType : 'string', width : 80, fixed : true , visible : true},
  {id : 4, caption : 'Section',dataField : 'sxnShtName',dataType : 'string', width : 80, fixed : true , visible : true},
  {id : 5, caption : 'Mob. no',dataField : 'empMobNo',dataType : 'string', width : 100, fixed : true , visible : true},
  {id : 6, caption : 'Unit',dataField : 'untShtName',dataType : 'string', width : 80, fixed : true , visible : true},
  {id : 7, caption : 'Location',dataField : 'locShtName',dataType : 'string', width : 80, fixed : true , visible : true},
  {id : 8, caption : 'Appraisal Group',dataField : 'apgShtName',dataType : 'string', width : 80, fixed : true , visible : true},
  {id : 9, caption : 'Person Type',dataField : 'pstShtName',dataType : 'string', width : 80, fixed : true , visible : true},
  {id : 10, caption : 'Gender',dataField : 'gndshtName',dataType : 'string', width : 80, fixed : true , visible : true},
  {id : 11, caption : 'Assignment Status',dataField : 'astShtName',dataType : 'string', width : 80, fixed : true , visible : true},
  {id : 12, caption : 'Intercom',dataField : 'empIntercom',dataType : 'string', width : 80, fixed : true , visible : true},

]

export const CurrentMembers = [ 
  {id : 0, caption : 'No',dataField : 'empStaffNo',dataType : 'string', width : 50, fixed : true , visible : true},
  {id : 1, caption : 'Staff Name',dataField : 'empName',dataType : 'string', width : 160, fixed : true , visible : true},
  {id : 2, caption : 'Dept',dataField : 'dptShtName',dataType : 'string', width : 80, fixed : true , visible : true},
  {id : 3, caption : 'Job Title',dataField : 'jbtShtName',dataType : 'string', width : 80, fixed : true , visible : true},
  {id : 4, caption : 'Section',dataField : 'sxnShtName',dataType : 'string', width : 160, fixed : true , visible : true},
  {id : 6, caption : 'Unit',dataField : 'untShtName',dataType : 'string', width : 160, fixed : true , visible : true},
  {id : 7, caption : 'Location',dataField : 'locName',dataType : 'string', width : 160, fixed : true , visible : true},
  {id : 8, caption : 'Start Date',dataField : 'ergStartDate',dataType : 'datetime', width : 160, fixed : true , visible : true},
  {id : 9, caption : 'End Date',dataField : 'ergEndDate',dataType : 'datetime', width : 160, fixed : true , visible : true},
  {id : 11, caption : 'Mob. no',dataField : 'empMobNox',dataType : 'string', width : 160, fixed : true , visible : true},
  {id : 12, caption : 'Intercom',dataField : 'empIntercom',dataType : 'string', width : 160, fixed : true , visible : true},
]