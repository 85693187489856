
import FilterTodos from '../../../Task&Todos/FooterGrid/FilterTodos';
import { useEffect, useRef, useState } from 'react';
// import type { SelectProps } from 'antd';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { PPE_distribution_toolbar } from '../../../accessories/custom-toolbars/ppeDistributionToolbar';

import { Objective_Team_Member_Details } from './widgets/Objective_teams_form';
import refresh from '../../../../assets/refresh.png';
import edit from '../../../../assets/edit.png';
import close from '../../../../assets/close.png';
import { Mini_datagrid_template } from './data/mini_datagrid';
import { Footer_datagrid_template } from './data/footer_datagrid';
import { objectives_teams_mini_datagrid } from './data/datagrid_cols';
import axios from 'axios';
import { api_url } from '../../../accessories/component_infos';
import { task_footer_table_selected, task_footer_update, task_form_disable } from '../../../../features/Task&Todo/Task/TaskDetails';
import { useDispatch } from 'react-redux';
import { GetDataFunc } from '../../../../functions/get';


interface props {
    state_change: boolean;
}



export const Objective_Teams = ({ state_change }: props) => {
    // const width = window.innerWidth/2
    // const fixedWidth = 'calc(50% - 8px)';
    const pageHeight = window.innerHeight - 233;

    /**
   **************************************
   ************************************** 
   ***********Api Integrations***********
   **************************************
   **************************************
   */

    //Fetch data for table
    const [tableData, setTableData] = useState();
    const [refreshValue, setRefreshValue] = useState(false);
    const [teamObjectives, setTeamObjectives] = useState([]);
    const [teamNames, setTeamNames] = useState([]);
    async function fetchTableData() {

        try {

            // get data
            const res = await GetDataFunc(`PfmObjectives/GetAllTeamMembers`, {
                headers: {
                    'Content-Type': ['application/json', 'text/plain', 'charset=utf-8'],

                    // Accept : ['text/plain','application/json','charset=utf-8'],
                }
            })

            setTableData(res.data.reverse()); // set data
        } catch (error: any) {
            console.log(error);

        } finally {

        }
    }

    async function fetchObjective() {

        try {

            // get data
            const res = await GetDataFunc(`PfmObjectives/GetLueObjectives`, {
                headers: {
                    'Content-Type': ['application/json', 'text/plain', 'charset=utf-8'],

                    // Accept : ['text/plain','application/json','charset=utf-8'],
                }
            })

            setTeamObjectives(res.data.reverse()); // set data
        } catch (error: any) {
            console.log(error);

        } finally {

        }
    }

    async function fetchNames() {

        try {

            // get data
            const res = await GetDataFunc(`PfmObjectives/GetLueStaffNames`, {
                headers: {
                    'Content-Type': ['application/json', 'text/plain', 'charset=utf-8'],

                    // Accept : ['text/plain','application/json','charset=utf-8'],
                }
            })

            setTeamNames(res.data.reverse()); // set data
        } catch (error: any) {
            console.log(error);

        } finally {

        }
    }

    // init states
    useEffect(() => {

        fetchTableData();
        fetchObjective();
        fetchNames();

    }, [refreshValue]);

    const childRef = useRef<any>();

    const dispatch = useDispatch();


    return (
        <div className='h-full dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base mt-1 '>
            {/* toggles between the taskForm and the footer grid table vertically */}



            <div className=''>

                <div className="px-2 flex justify-between gap-x-4 border-b-4 mb-1 pb-1">

                    {/* Internal toggler : expands the right taskUpdate horizontally */}

                    <div style={{ width: '100%', height: '295px' }} >
                        <Objective_Team_Member_Details toggleRefresh={() => { setRefreshValue(!refreshValue) }} nameDataList={teamNames} objectiveDataList={teamObjectives} ref={childRef} />
                    </div>


                    {/* Double tap(click) feature to toggle to and fro of the right taskUpdate section */}
                    <div style={{ width: 'calc(100% - 920px)' }} className="h-full w-full  rounded-md items-center dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base " >
                        <div className='flex justify-end items-center my-1'>
                            <ul className='flex justify-evenly items-center'>
                                <li style={{ borderWidth: '1px' }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center">
                                    <span className="flex"> <img className="w-4 dark:bg-slate-900 pr-1 dark:text-gray-100 duration-100" src={refresh} /> </span>
                                </li>

                                <li style={{ borderWidth: '1px' }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center">
                                    <span className="flex"> <img className="w-4 dark:bg-slate-900 pr-1 dark:text-gray-100 duration-100" src={edit} /> </span>
                                </li>

                                <li style={{ borderWidth: '1px' }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center">
                                    <span className="flex"> <img className="w-4 dark:bg-slate-900 pr-1 dark:text-gray-100 duration-100" src={close} /> </span>
                                </li>
                            </ul>
                        </div>

                        <div className='border-2'>
                            <Mini_datagrid_template singleClick={(e: any) => { console.log(e); childRef.current.singleClick(e); dispatch(task_footer_table_selected(e)) }} doubleClick={() => {
                                console.log('ds'); childRef.current.doubleClick();  //enable forms
                                dispatch(task_form_disable(true));

                                //enable Update Mode
                                dispatch(task_footer_update(true))
                            }} tableData={tableData} columns={objectives_teams_mini_datagrid} gridheight={260} />
                        </div>
                    </div>


                </div>
            </div>


            {/* Footer gridtable  */}
            {/* <div style={{height:''}} className='px-2 dark:bg-slate-900 dark:text-darkModeSkin-base'>
            <Footer_datagrid_template gridheight={475} columns={objectives_columns} data={undefined} />
            </div> */}

        </div>

    )
}





// const reload = ()=>{
//     return(
//         <img className='w-2/6' src={save} />
//     )
// }