import { useDispatch, useSelector } from "react-redux";
import { setGeneralValue } from "../features/generalSlice";
import { RootState } from "../app/store";
import { useEffect } from "react";
import { getTheme } from "../features/user";

interface ThemeClasses {
  borderClass: string;
  fontClass: string;
}

export const useThemes = () => {

  const dispatch = useDispatch();
  const theme = useSelector((state: RootState) => state.user.theme)
  const themeRefresh = useSelector((state: RootState) => state.general.themeRefresh)


  useEffect(() => {
    let fontClass = "";
    let cssBorderClass = "";
    let cssBorderHover = "";
    let cssBorderBg = "";

    switch(theme) {
      case "Bootstrap":
        fontClass = "font-bold";
        cssBorderClass = "#bfbfbf";
        cssBorderHover = "#3385ff";
        cssBorderBg = "#ffffff";
        break;
      case "Bootstrap Blue":
        fontClass = "font-light";
        cssBorderClass = "#3385ff";
        cssBorderHover = "#ff00d0";
        cssBorderBg = "#ffffff";
        break;
      case "Bootstrap Black":
        fontClass = "font-light";
        cssBorderClass = "#404040";
        cssBorderHover = "#3385ff";
        cssBorderBg = "#ffffff";
        break;
      case "Vista":
        fontClass = "font-bold";
        cssBorderClass = "#bfbfbf";
        cssBorderHover = "#ff9900";
        cssBorderBg = "#3385ff";
        break;
      default:
        fontClass = "font-light";
        cssBorderClass = "#bfbfbf";
        cssBorderHover = "#3385ff";
        cssBorderBg = "#ffffff";
        break;
    }

    // Dispatch actions to update CSS classes in Redux store
    dispatch(getTheme());
    dispatch(setGeneralValue({ expr: "cssBorderClass", value: cssBorderClass }));
    dispatch(setGeneralValue({ expr: "cssBorderHover", value: cssBorderHover }));
    dispatch(setGeneralValue({ expr: "cssBorderBg", value: cssBorderBg }));
    

  }, [themeRefresh, theme, dispatch, getTheme])


};



