/* eslint-disable react/jsx-pascal-case */
import { SaveButton } from "../../../accessories/buttons";
import { DateTemplate } from "../../../../templates/date";
import { TextareaTemplate } from "../../../../templates/textarea";
import { navbar_height, toolbar_height, tab_bar_height } from "../../../accessories/component_infos";
import useFetch from "../../../../hooks/useFetch";
import { InputsTemplate } from "../../../../templates/input";
import close from "../../../../assets/close.png";
import switchUser from "../../../../assets/switUser241.png";
import { IconsContainer } from "../StationReadings/_widget/stationReadingsToolbar";
import { RefreshButton } from "../StationReadings/_widget/utils";
import InputSelect_template from "../../../../templates/InputSelect";
import { Form } from "antd";

interface props {
  open: boolean;
  showMiniForm: boolean;
}


export const ElogBookMainFormV1 = ({ open, showMiniForm }: props) => {

  const [eventTypes] = useFetch(
    `ELogBook/PopCmbEventTypes`,
    false,
    undefined, undefined, "powerapp"
  );
  const [reportingStations] = useFetch(
    `ELogBook/PopLueReportingStations`,
    false,
    undefined, undefined, "powerapp"
  );
  const [correspondents] = useFetch(
    `ELogBook/PopCmbCorrespondents`,
    false,
    undefined, undefined, "powerapp"
  );
  const [homeStations] = useFetch(
    `ELogBook/PopCmbHomeStation`,
    false,
    undefined, undefined, "powerapp"
  );

  const inputTemps = [
    { id: 0, option: eventTypes, label: ["Event", "Type"], idexpr: "evtidpk", dataexp: "evtName" },
    { id: 1, option: reportingStations, label: ["Reporting", "Station"], idexpr: "stnIDpk", dataexp: "stnName" },
    { id: 2, option: correspondents, label: ["Correspondent", ""], idexpr: "crdIDpk", dataexp: "empName1" },
    { id: 3, option: homeStations, label: ["Home", "Station"], idexpr: "stnIDpk", dataexp: "stnName" },
  ];

  const heights_out = navbar_height + toolbar_height + tab_bar_height + 380;

  return (
    <section>
      <header className="bg-neutral-200 p-1 w-full h-auto font-medium">
        eLog Book
      </header>
      <section className="w-full h-auto border-2 px-2 ">
        <section className="flex items-center space-x-3 p-1 w-full overflow-x-clip">
          <aside className="flex items-center space-x-2">
            <label className="text-[12px] w-full truncate">Event Time:</label>
            <DateTemplate disabled={false} width={90} />
          </aside>
          {/* <aside className="flex items-center space-x-2">
            <label className="text-[12px] text-right">Event<br />Type</label>
            <SelectsTemplate disabled={false} selectStyle={{ height: 25, width: 92 }} idexpr="evtidpk" dataexp="evtName" options={eventTypes} />
          </aside>
          <aside className="flex items-center space-x-2">
            <label className="text-[12px] text-right">Reporting<br />Station</label>
            <SelectsTemplate disabled={false} selectStyle={{ height: 25, width: 92 }} idexpr="stnIDpk" dataexp="stnName" options={reportingStations} />
          </aside>
          <aside className="flex items-center space-x-2">
            <label className="text-[12px] text-right">Correspondent</label>
            <SelectsTemplate disabled={false} selectStyle={{ height: 25, width: 92 }} idexpr="crdIDpk" dataexp="empName1" options={correspondents} />
          </aside>
          <aside className="flex items-center space-x-2">
            <label className="text-[12px] text-right">Home<br />Station</label>
            <SelectsTemplate disabled={false} selectStyle={{ height: 25, width: 92 }} idexpr="stnIDpk" dataexp="stnName" options={homeStations} />
            <DropdownGrid_template
              label={"Home Station"}
              labelCol={8}
              gridData={[]}
              dropdownBtnStyles={{ width: 205, overflow: "hidden" }}
              cusBtnStyle={true}
              columns={workshop_cols}
              onRowClick={(e) => {
                updateState(stateName!, {
                  id: e[idExpr!],
                  name: e[dataExpr!],
                });
                // ref.current!.instance.close();
              } }
              handleRefresh={() => {
                updateState("selectedWorkshop", {
                  name: "",
                  id: 0,
                });
                updateState(
                  "workShopRefresh",
                  !states.workShopRefresh
                );
              } } defaultValue={undefined} disabled={false}            />
          </aside> */}
          {
            inputTemps.map(({ label, option, idexpr, dataexp }, index) => (
              <aside key={index} className="flex items-center space-x-2">
                <label className="text-[12px] text-right">{label[0]}<br />{label[1]}</label>
                <InputSelect_template disabled={false} selectStyle={{ height: 25, width: 92 }} idexpr={idexpr} dataexp={dataexp} options={option} useCallFunc />
              </aside>
            ))
          }

        </section>
        <section className="flex items-start space-x-2 w-full px-2">
          <label className="text-[12px] text-right">Event Log</label>
          <aside className="flex-1">
            <TextareaTemplate disabled={false} height={open ? 100 : (showMiniForm ? 370 : heights_out)} MarginLeft={4} />
          </aside>
        </section>
        <section className="flex justify-between items-center px-1 ml-[14px] mb-2">
          <em className="font-bold text-neutral-500">New log...
            <span className="text-red-400">No Shift Change detected for the selected Home Station. Contact Sys Admin for assistance</span>
          </em>
          <SaveButton disableButton={false} disabled_status={false} title="Save Log" />
        </section>
        {
          showMiniForm && <MiniForm />
        }
      </section>
    </section>
  )
};

export const ElogBookMainFormV2 = ({ open, showMiniForm }: props) => {

  const [eventTypes] = useFetch(
    `ELogBook/PopCmbEventTypes`,
    false,
    undefined, undefined, "powerapp"
  );
  const [reportingStations] = useFetch(
    `ELogBook/PopLueReportingStations`,
    false,
    undefined, undefined, "powerapp"
  );
  const [correspondents] = useFetch(
    `ELogBook/PopCmbCorrespondents`,
    false,
    undefined, undefined, "powerapp"
  );
  const [homeStations] = useFetch(
    `ELogBook/PopCmbHomeStation`,
    false,
    undefined, undefined, "powerapp"
  );

  const inputTemps = [
    { id: 0, option: eventTypes, label: ["Event", "Type"], idexpr: "evtidpk", dataexp: "evtName" },
    { id: 1, option: reportingStations, label: ["Reporting", "Station"], idexpr: "stnIDpk", dataexp: "stnName" },
    { id: 2, option: correspondents, label: ["Correspondent", ""], idexpr: "crdIDpk", dataexp: "empName1" },
    { id: 3, option: homeStations, label: ["Home", "Station"], idexpr: "stnIDpk", dataexp: "stnName" },
  ];

  const heights_out = navbar_height + toolbar_height + tab_bar_height + 380;

  return (
    <section>
      <header className="bg-neutral-200 p-1 w-full h-auto font-medium">
        eLog Book
      </header>
      <section className="w-full h-auto border-2 px-2 ">
        <section className="flex items-center space-x-3 p-1 w-full overflow-x-clip">
          <aside className="flex items-center space-x-2">
            <label className="text-[12px] w-full truncate">Event Time:</label>
            <DateTemplate disabled={false} width={120} />
          </aside> 
          {
            inputTemps.map(({ label, option, idexpr, dataexp }, index) => (
              // <aside key={index} className="flex items-center space-x-2 w-full ">
              <Form.Item
                label={<p className="text-xs">{label[0] + " " + label[1]}</p>}
                style={{ marginBottom: 0 }}
                labelCol={{ span: 12 }}
                className="w-full"
              >
                <InputSelect_template disabled={false} selectStyle={{ height: 25 }} idexpr={idexpr} dataexp={dataexp} options={option} useCallFunc />
              </Form.Item>
              // </aside> 
            ))
          }
        </section>
        <section className="flex items-start space-x-2 w-full px-2">
          <label className="text-[12px] text-right">Event Log</label>
          <aside className="flex-1">
            <TextareaTemplate disabled={false} height={open ? 100 : (showMiniForm ? 370 : heights_out)} MarginLeft={4} />
          </aside>
        </section>

        <section className="flex justify-between items-center px-1 ml-[14px] mb-2">
          <em className="font-bold text-neutral-500">New log...
            <span className="text-red-400">No Shift Change detected for the selected Home Station. Contact Sys Admin for assistance</span>
          </em>
          <SaveButton disableButton={false} disabled_status={false} title="Save Log" />
        </section>
        {
          showMiniForm && <MiniForm />
        }
      </section>
    </section>
  )
};


const Container = ({ title, children, textColor }: any) => {
  return (
    <aside className="w-full h-auto border-[1px] rounded-md relative">
      <section className={`text-[12px] absolute -top-2 bg-white left-2 px-1 ${textColor} font-bold`}>{title}</section>
      <section className="w-full h-full p-2">
        {children}
      </section>
    </aside>
  )
}

const MiniForm = ({ title, children }: any) => {
  return (
    <section className="flex space-x-2 m-2">
      <section className="w-[70%]">
        <Container title="Incoming Shift Crew Login" textColor={"text-green-500"}>
          <section className="flex space-x-1 items-center h-full">
            <aside className="h-36 p-3 w-[40%]">
              <aside>
                <p className="text-sm">Username or Staff No:</p>
                <section className="flex space-x-1 items-center w-full">
                  <div className="flex-1">
                    <InputsTemplate span={true} disabledStatus={false} inputStyle={{ height: "24px" }} />
                  </div>
                  <IconsContainer url={close} />
                </section>
              </aside>
              <aside>
                <p className="text-sm">Password:</p>
                <div className="mr-9">
                  <InputsTemplate span={true} disabledStatus={false} inputStyle={{ height: "24px" }} /></div>
              </aside>
              <p className="text-red-500">Invalid</p>
            </aside>
            <aside className="flex space-x-1 w-[60%] h-36 border-2 rounded-md flex-row-reverse">
              <aside className="flex justify-end p-0.5">
                <RefreshButton />
              </aside>
              <aside className="flex-1">

              </aside>
            </aside>
          </section>
        </Container>
      </section>
      <section className="w-[30%]">
        <Container title="Outgoining Shift Crew Out" textColor={"text-red-500"}>
          <aside className="h-36 p-3 w-full">
            <section className="flex space-x-1 items-center w-full">
              <div className="flex-1">
                <InputsTemplate span={true} disabledStatus={false} inputStyle={{ height: "24px" }} />
              </div>
              <IconsContainer url={close} />
            </section>
            <div className="mr-9">
              <InputsTemplate span={true} disabledStatus={false} inputStyle={{ height: "24px" }} /></div>
            <p className="text-red-500">Invalid</p>
            <section className="flex border-2 border-neutral-300 p-2 rounded-md items-center justify-center mt-2 cursor-pointer space-x-2">
              <img src={switchUser} alt="switch" />
              <p>shift change</p>
            </section>
          </aside>
        </Container>
      </section>
    </section>
  )
}