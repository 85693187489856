import { Item } from "devextreme-react/accordion";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams, useNavigate, createSearchParams } from "react-router-dom";
import { setDetailsPreview, setFormData } from "../../../../../features/generalSlice";
import useFetch from "../../../../../hooks/useFetch";
import CustomLoader from "../../../../accessories/custom-loader";

import GroupPPEStockDetails from "./staff-name-details";
import StaffNameDetails from "./staff-name-details";
import CentralPPEStockDetails from "./staff-name-details";
import { StaffNameToolbar } from "./staff-name-toolbar";
import React from "react";



const StaffNames = () => {

  const taskDetailsHeight = '309px'

  const [tasks_update_state, setTask_update_state] = useState<boolean>(true)
  const [task_datagrid_height, set_task_datagrid_height] = useState(`calc(100% - ${taskDetailsHeight})`);
  const [searchParams, setSearchParams] = useSearchParams()

  const navigate = useNavigate()

  const current_page_route = '/tasks-todos';
  const view = searchParams.get('view');
  const taskDataGrid = 'task-data-grid-extended';
  const [showTable, setShowTable] = useState(true)

  // switch button toggler
  const switch_toggle = () => {

    setShowTable(!showTable);

  }

  const [currentUrl, setCurrentUrl] = useState('StaffNameDetails/GetAllActiveEmpNameDetails');



  var [data, error, loading] = useFetch(currentUrl);
  var [titleList, titleError, titleLoading] = useFetch('StaffNameDetails/GetAllEmpNameTitles');
  var [reasonsList, reasonsError, reasonLoading] = useFetch('StaffNameDetails/GetAllEmpNameReasons');

  const fetchData = (url: string) => {
    setCurrentUrl(url);

  }
  const dispatch = useDispatch();
  if (data) {
    dispatch(setFormData(data));
  }


  return (
    <>{

      !(data && titleList && reasonsList) && <div className="w-full h-full flex justify-center items-center"><CustomLoader /></div>}
      {(data && titleList && reasonsList) && <div>
        <div className="mx-2">
          <StaffNameToolbar toggler={switch_toggle} />
          {/* <Staff_customtoolbar /> */}
        </div>

        <div className="px-2 mb-0.5  ">
          <StaffNameDetails toggled={showTable} networkTableData={data} reasonList={reasonsList} titleNoList={titleList} fetchData={(url: any) => fetchData(url)} />

        </div>
      </div>
      }
    </>

  );
}

export default StaffNames;