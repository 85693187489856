import { CheckBox, DataGrid, NumberBox, ProgressBar } from "devextreme-react";
import { Column, GroupPanel, HeaderFilter, Pager, Paging, Scrolling, SearchPanel, Selection } from "devextreme-react/data-grid";
import { completed, inProgress, tableData, yetToStart } from "../../data.js";
import 'devextreme/data/odata/store';
import './styles/styles.css'
import { ReactNode, useCallback, useState } from "react";
import { task_todos_footer_datagrid_cols } from "./datagrid_cols";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import React, { useEffect } from 'react'

import { start } from "repl";


import { useDispatch } from "react-redux";
import { task_footer_table, task_footer_table_selected, task_footer_update, task_form_disable } from "../../../features/Task&Todo/Task/TaskDetails";
import dayjs from 'dayjs'
import { setGeneralValue } from "../../../features/generalSlice";
import { api_url } from "../../accessories/component_infos";
import useFetch from "../../../hooks/useFetch";
// import { navbar_height, news_flash_height } from "../accessories/component_infos.js";


interface props {
  gridheight: number;
  data?: any;
}


const FilterTodos = ({ gridheight, data }: props) => {

  const dispatch = useDispatch()


  const resized = useSelector((state: RootState) => state.general.resized);


  const heights_out = 550;
  // Auto resize after refreshing


  const displayModes = [{ text: 'Display Mode \'full\'', value: 'full' }, { text: 'Display Mode \'compact\'', value: 'compact' }];
  const allowedPageSizes = [5, 10, 15, 'all'];




  const allowUpdate = (e: any) => {
    //enable forms
    dispatch(task_form_disable(false));

    //enable Update Mode
    dispatch(task_footer_update(true));
    console.log(e)
  }
  // dispatch(task_footer_table(DBdata))
  // const indeterminateCheckbox = () =>{
  //   if (reduxIndeterminateCheckboxData === true){
  //         setBackendData(data)

  //   }else {
  //     console.log('====================================');
  //     console.log('Fuck off');
  //     console.log('====================================');
  //   }
  // }

  const [selectedRecord, setSelectedRecord] = useState<any>([]);
  const selectRecord = useCallback((e: any) => {
    e.component.byKey(e.currentSelectedRowKeys[0]).done((e: any) => {
      setSelectedRecord(e);
      //disable forms
      dispatch(task_form_disable(true));





    });


  }, []);

  // post selected record to redux
  dispatch(task_footer_table_selected([selectedRecord]))
  console.log(selectedRecord)

  const renderGridCell = (value: number) => {
    return (<>
      <div className="flex flex-col" id="avGAllTasks">
        <div className="w-full h-4  bg-gray-300 rounded-sm ">
          <div className="relative h-full flex ">
            <div className=" absolute h-full  bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-sm " style={{ width: (value.toString() + '%') }} ></div>
            <div className="relative text-xs text-white  w-full text-center">{value}%</div>

          </div>

        </div>
      </div>
    </>);
  }

  const statusButton = (value: string) => {
    console.log(value)
    const style = value === 'Completed' ? completed : value === 'In-Progress' ? inProgress : yetToStart
    return (<>
      <div>
        <button className=" bg-green-100  px-2   h-full text-xs" style={style}>{value}</button>
      </div>
    </>);
  }

  useEffect(() => {


  }, [])


  return (

    <>


      <div className="w-full px-2 bg-slate-100" >
        <style
          dangerouslySetInnerHTML={{
            __html: `
            .dx-datagrid-header-panel .dx-toolbar {
                margin-bottom: 0;
            }

            .filterTodos .dx-datagrid-headers{
                margin-top: 5px;
            }

           

            
            `
          }}
        />
        <DataGrid

          dataSource={data}
          height={gridheight}
          showColumnLines={true}
          showRowLines={true}
          wordWrapEnabled={false}
          columnResizingMode={'widget'}

          onRowDblClick={allowUpdate}
          // onSelectionChanged={selectItem}
          onSelectionChanged={selectRecord}
          allowColumnResizing={true}
          showBorders={true}>
          <SearchPanel visible={true}
            highlightSearchText={true}
            width={300}


          />


          <HeaderFilter visible={true} />
          <GroupPanel visible={true} />
          <Selection mode="single" />
          <Paging defaultPageSize={0} />
          <Pager
            visible={true}
            allowedPageSizes={"auto"}
            displayMode={"compact"}
            showInfo={true}
            showNavigationButtons={true}
            showPageSizeSelector={true}
          />


          {
            task_todos_footer_datagrid_cols?.map((data_cols) => {
              // status
              //   cellRender={(data:any)=>statusButton(data.value)}
              // progress
              return (
                <Column

                  key={data_cols.id}
                  dataType={data_cols.dataType as "string" | "number" | "boolean" | "object" | "date" | "datetime" | undefined}
                  caption={data_cols.caption}
                  dataField={data_cols.dataField}
                  cellRender={(data: any) => {
                    return data_cols.caption === 'PROGRESS' ? renderGridCell(data.value)
                      :
                      data_cols.caption === 'STATUS' ? statusButton(data.value)
                        : data.value
                  }}
                  // cellRender={(data:any)=>renderGridCell(data.value)}
                  alignment={data_cols.alignment as "left" | "center" | "right" | undefined}
                  fixed={data_cols.fixed}
                  // visible={data_cols.visible??true}
                  width={data_cols.width === null ? 150 : data_cols.width}
                />
              )
            })
          }


          <Scrolling mode="virtual" />


        </DataGrid>




      </div>

    </>

  );

}

export default FilterTodos;


