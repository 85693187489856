import { useState } from "react";
import { Form } from "antd";
import { selectsTypes } from "../../../../../../human-resource/setups/data/types/selectsTypes";
import useFetch from "../../../../../../../hooks/useFetch";
import { SelectsTemplate } from "../../../../../../../templates/select";
import {
  Cancel,
  NewButton,
  SaveButton,
  UpdateButton,
} from "../../../../../../accessories/buttons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import { WarningAlert } from "../../../../../../accessories/warningAlert";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import { saveModalData } from "../../../../../../accessories/component_infos";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { setGeneralValue } from "../../../../../../../features/generalSlice";
import { Tooltip } from "devextreme-react";

interface props {
  updateStatesx?: (key: string, value: any) => void;
  statesx?: any;
}

export const Return_Request_Details_Form = ({
  updateStatesx,
  statesx,
}: props) => {
  const form_disable = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const update_state_change = useSelector(
    (state: RootState) => state.general.taskFooterUpdate
  );
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  const [privType, setPrivType] = useState<string>("save");
  const [savePriv, updatePriv] = useFormPriviledge(privType);

  const dispatch = useDispatch();

  const newAccessPriv = () => {
    if (savePriv === true) {
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
    } else {
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      setIcon("warning");
      setOpenModal(true);
      setModalData({ title: "Access Denied!", message: "No Access Privilege" });
    }
  };

  const saveAccessPriv = () => {
    setPrivType("save");
    if (savePriv === true) {
    } else {
      setIcon("warning");
      setOpenModal(true);
      setModalData({ title: "Access Denied!", message: "No Access Privilege" });
    }
  };

  const updateAccessPriv = () => {
    setPrivType("update");
    if (updatePriv === true) {
    } else {
      setIcon("warning");
      setOpenModal(true);
      setModalData({ title: "Access Denied!", message: "No Access Privilege" });
    }
  };

  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [showCancel] = useState(false);

  const [purData, purError] = useFetch(`FuelManagement/dumpGetPurchaseMode`);

  const selects: selectsTypes[] = [
    {
      id: 0,
      label: "Purchase Mode",
      idExpr: "pcmIdpk",
      dataExpr: "pcmName",
      optionsData: purData,
      stateName: "purModeState",
      defaultValue: statesx.purModeState?.name,
      style: "",
    },
  ];

  return (
    <>
      {purError && <WarningAlert />}
      <ModalTemplate
        icon_width={modalData.iconSize === "large" ? 100 : 50}
        icon={icon}
        cancelText={modalData.cancelText}
        disableCancel={!showCancel}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        open={openModal}
        okHandler={() => {
          setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
      />
      <div
        style={{ width: "100%", height: "100%", borderColor: borderTheme }}
        className="border-[1px] border-r-1 border-t-1 border-b-1  mb-1 bg-slate-50 dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base  "
      >
        <div
          style={{ height: "275px" }}
          className=" border-r-1 items-start justify-start"
        >
          <Form
            className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 pl-2 "
            name="basic"
            initialValues={{ remember: true }}
            autoComplete="off"
            size="small"
            layout="horizontal"
          >
            {selects.map(
              ({
                id,
                label,
                optionsData,
                idExpr,
                dataExpr,
                defaultValue,
                stateName,
                style,
              }) => {
                return (
                  <div>
                    <SelectsTemplate
                      label={label}
                      idexpr={idExpr}
                      dataexp={dataExpr}
                      placeHolder={defaultValue}
                      options={optionsData}
                      useCallFunc
                      selectedValue={(e) => {
                        updateStatesx &&
                          updateStatesx(stateName!, {
                            id: e[idExpr!],
                            name: e[dataExpr!],
                          });
                      }}
                      wrapperCol={5}
                    />
                  </div>
                );
              }
            )}
          </Form>
        </div>

        <div className="w-full flex justify-end px-2 py-1">
          {form_disable === false && update_state_change === false ? (
            <div id="save">
              <SaveButton
                disableButton={true}
                handleSave={() => {
                  saveAccessPriv();
                }}
              />
              <Tooltip
                target="#save"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p>Save Request</p>
              </Tooltip>
            </div>
          ) : update_state_change === true ? (
            <div id="update">
              <UpdateButton
                handleUpdate={() => {
                  updateAccessPriv();
                }}
              />
              <Tooltip
                target="#update"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p>Update Request</p>
              </Tooltip>
            </div>
          ) : (
            <SaveButton />
          )}
          {form_disable === true ? (
            <div id="new">
              <NewButton
                useCallbackFunc={true}
                new_button_toggler={() => {
                  newAccessPriv();
                }}
              />
              <Tooltip
                target="#new"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p>New Request</p>
              </Tooltip>
            </div>
          ) : update_state_change === true ? (
            <div id="cancel">
              <Cancel cancel_button_toggler={() => {}} />
              <Tooltip
                target="#cancel"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p>Cancel Update</p>
              </Tooltip>
            </div>
          ) : !(update_state_change && form_disable) ? (
            <div id="cancel">
              <Cancel cancel_button_toggler={() => {}} />
              <Tooltip
                target="#cancel"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p>Cancel</p>
              </Tooltip>
            </div>
          ) : (
            <div id="new">
              <NewButton
                useCallbackFunc={true}
                new_button_toggler={() => {
                  newAccessPriv();
                }}
              />
              <Tooltip
                target="#new"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p>New Request</p>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
