/* eslint-disable react/jsx-pascal-case */
import { forwardRef } from "react";
import { StationReadingsToolbar } from "./_widget/stationReadingsToolbar";
import TabsPanel_template from "../../../../templates/tabsPanel_template";
import { EquipmentOutage } from "./tabContents/EquipmentOutage/equipmentOutage";
import { EnergyMeterReading } from "./tabContents/EnergyMeterReadings/energyMeterReading";
import FeederReadings from "./tabContents/FeederReadings/feederReadings";
import TransformerReadings from "./tabContents/TransformerReadings/transformerReadings";
import LineReadings from "./tabContents/LineReadings/lineReading";
import BusVoltage from "./tabContents/BusVoltage/busVoltage";


export const SationReadings = forwardRef(() => {
    const tabs: { id: number, title: string, contents: any }[] = [
        { id: 0, title: "Bus Voltage", contents: <BusVoltage /> },
        { id: 1, title: "Feeder Readings", contents: <FeederReadings /> },
        { id: 2, title: "Line Readings", contents: <LineReadings />},
        { id: 3, title: "Transformer Readings", contents: <TransformerReadings /> },
        { id: 4, title: "Equipment Outages", contents: <EquipmentOutage /> },
        { id: 5, title: "Energy Meter Readings", contents: <EnergyMeterReading /> },
    ]
    return (
        <main className="px-1">
            <StationReadingsToolbar />
            <section>
                <TabsPanel_template tabs={tabs} />
            </section>
        </main>
    );
});

