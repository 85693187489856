
// FOR DISTRIBUTION MINI
export const saa_cols = [
    {id: 0,caption : "ID", dataField : 'siaIDpk', dataType : "number", fixed : true,width:30},
    {id: 1,caption : "LOCKED?", dataField : 'siaLocked', dataType : "boolean", fixed : true,width:80},
    {id: 2,caption : "ACTIVE?", dataField : 'siaActive', dataType : "boolean",fixed : true,width:80},
    {id: 3,caption : "INITIATIVE", dataField : 'sinName', dataType : "string", fixed : true,width:100},
    {id: 4,caption : "TARGET", dataField : 'tgtName', dataType : "string", fixed : true,width:100},
    {id: 5,caption : "STAFF ACTUAL", dataField : 'staffActualName', dataType : "string", fixed : true,width:100},
    {id: 6,caption : "SUP. ACTUAL", dataField : 'supActualName', dataType : "string", fixed : false,width:100},
    {id: 7,caption : "WEIGHT", dataField : 'siaWeight', dataType : "string", fixed : false,width:100},
    {id: 8,caption : "STAFF RATING", dataField : 'staffRatingRate', dataType : "string", fixed : false,width:100},
    {id: 9,caption : "STAFF GRADE", dataField : 'staffRatingGrade', dataType : "string", fixed : false,width:100},
    {id: 10,caption : "STAFF RATE INTERPR.", dataField : 'staffRatingInterpretation', dataType : "string", fixed : false,width:100},
    {id: 11,caption : "STAFF % COMPLETE", dataField : 'siaStaffPercentageComplete', dataType : "string", fixed : false,width:100},
    {id: 12,caption : "SUP RATING", dataField : 'supRatingRate', dataType : "string", fixed : false,width:100},
    {id: 13,caption : "SUP GRADE", dataField : 'supRatingGrade', dataType : "string", fixed : false,width:100},
    {id: 14,caption : "SUP RATE INTERPR.", dataField : 'supRatingInterpretation', dataType : "string", fixed : false,width:100},
    {id: 15,caption : "SUP % COMPLETE", dataField : 'siaSupPercentageComplete', dataType : "string", fixed : false,width:100},
    
    {id: 16,caption : "STAFF REMARKS", dataField : 'siaStaffAppraisalRmks', dataType : "string", fixed : false,width:400},
    {id: 17,caption : "SUP REMARKS", dataField : 'siaSupAppraisalRmks', dataType : "string", fixed : false,width:4000},
    
]

// FOR DISTRIBUTION MINI
export const saa_emp_cols = [
    {id: 0,caption : "ID", dataField : 'empIDpk', dataType : "number", fixed : true,width:0},
    {id: 1,caption : "STAFF?", dataField : 'empIsStaff', dataType : "boolean", fixed : true,width:80},
    {id: 2,caption : "STAFF NO", dataField : 'empStaffNo', dataType : "string",fixed : false,width:160},
    {id: 3,caption : "STAFF NAME", dataField : 'empName', dataType : "string", fixed : false,width:160},
    {id: 4,caption : "ACTIVE?", dataField : 'empActive', dataType : "boolean", fixed : false,width:80},
    {id: 5,caption : "JOB TITLE", dataField : 'jbtShtName', dataType : "string", fixed : false,width:200},
    {id: 6,caption : "LOCATION", dataField : 'locShtName', dataType : "string", fixed : false,width:200},
    {id: 7,caption : "DEPT", dataField : 'dptShtName', dataType : "string", fixed : false,width:200},
    {id: 8,caption : "SECTION", dataField : 'sxnShtName', dataType : "string", fixed : false,width:200},
    {id: 9,caption : "UNIT", dataField : '', dataType : "string", fixed : false,width:200},
    {id: 10,caption : "AREA", dataField : '', dataType : "string", fixed : false,width:200},
    {id: 11,caption : "SUP STF. No", dataField : '', dataType : "string", fixed : false,width:200},
    {id: 12,caption : "SUPERVISOR", dataField : '', dataType : "string", fixed : false,width:200},
    {id: 13,caption : "UNIT HEAD", dataField : '', dataType : "string", fixed : false,width:200},
    {id: 14,caption : "MANAGER", dataField : '', dataType : "string", fixed : false,width:200},
    {id: 15,caption : "DIRECTOR", dataField : '', dataType : "string", fixed : false,width:200},
    {id: 16,caption : "OFFICE LOC", dataField : '', dataType : "string", fixed : false,width:200},
    {id: 17,caption : "ROOM No", dataField : '', dataType : "string", fixed : false,width:200},
    {id: 18,caption : "GENDER", dataField : '', dataType : "string", fixed : false,width:200},
    {id: 19,caption : "INTERCOM/INTERCOM", dataField : '', dataType : "string", fixed : false,width:200},
    {id: 20,caption : "MOBILE No.", dataField : '', dataType : "string", fixed : false,width:200},
    {id: 21,caption : "TELEPHONE No.", dataField : '', dataType : "string", fixed : false,width:200},
    {id: 22,caption : "OFFICIAL EMAIL", dataField : '', dataType : "string", fixed : false,width:200},
    {id: 23,caption : "PERSON EMAIL", dataField : '', dataType : "string", fixed : false,width:200},
    {id: 24,caption : "LINKEDIN", dataField : '', dataType : "string", fixed : false,width:200},
    {id: 25,caption : "SKYPE", dataField : '', dataType : "string", fixed : false,width:200},
    {id: 26,caption : "SUP?", dataField : '', dataType : "string", fixed : false,width:200},
    {id: 27,caption : "TWITTER", dataField : '', dataType : "string", fixed : false,width:200},
    {id: 28,caption : "MGR?", dataField : '', dataType : "string", fixed : false,width:200},
    {id: 29,caption : "FACEBOOK", dataField : '', dataType : "string", fixed : false,width:200},
    {id: 30,caption : "DIR?", dataField : '', dataType : "string", fixed : false,width:200},
    {id: 31,caption : "SNR?", dataField : '', dataType : "string", fixed : false,width:200},
    {id: 32,caption : "JNR?", dataField : '', dataType : "string", fixed : false,width:200},
    {id: 33,caption : "CEO?", dataField : '', dataType : "string", fixed : false,width:200},
    {id: 34,caption : "CEO?", dataField : '', dataType : "string", fixed : false,width:200},
    {id: 35,caption : "MGMT?", dataField : '', dataType : "string", fixed : false,width:200},
    {id: 36,caption : "DEPT ADMIN?", dataField : '', dataType : "string", fixed : false,width:200},
    {id: 37,caption : "DEPT SEC?", dataField : '', dataType : "string", fixed : false,width:200},
    {id: 38,caption : "SEC'N ADMIN?", dataField : '', dataType : "string", fixed : false,width:200},
    {id: 39,caption : "SEC'N SEC?", dataField : '', dataType : "string", fixed : false,width:200},
    {id: 40,caption : "BOARD MEMB?", dataField : '', dataType : "string", fixed : false,width:200},
    {id: 41,caption : "CORE STAFF?", dataField : '', dataType : "string", fixed : false,width:200},
    {id: 42,caption : "TECH. STAFF?", dataField : '', dataType : "string", fixed : false,width:200},
    {id: 43,caption : "CASUAL?", dataField : '', dataType : "string", fixed : false,width:200},
    {id: 44,caption : "NSP?", dataField : '', dataType : "string", fixed : false,width:200},
    {id: 45,caption : "VOLUNTARY?", dataField : '', dataType : "string", fixed : false,width:200},
    {id: 46,caption : "REMARKS?", dataField : '', dataType : "string", fixed : false,width:4000},
    
   
]

export const saa_periods_cols = [
    {id: 0,caption : "ID", dataField : 'prdIdpk', dataType : "string", fixed : true,width:60,visible: false},
    {id: 1,caption : "Period", dataField : 'prdName', dataType : "string", fixed : true,width:100},
    {id: 2,caption : "Start Date", dataField : 'prdStartDate', dataType : "string", fixed : true,width:100},
    {id: 3,caption : "End Date", dataField : 'prdEndDate', dataType : "string", fixed : true,width:100},
    {id: 4,caption : "Opened?", dataField : 'prdOpened', dataType : "string", fixed : true,width:100},
  
]