import React, { useState, useCallback, useRef } from 'react'
import { Form, Input, Select, DatePicker } from 'antd';
import { CheckBox } from 'devextreme-react/check-box';

import { SaveButton, NewButton, UpdateButton, Cancel } from '../../../../../accessories/buttons';
import { Tab_xxxx_Mini_TextArea, Tab_xxxx_TextArea, } from '../data/Performance_inputs';
import { Tab_xxxx_Objective_Select } from '../data/Performance_selects';
import reset from '../../../../../../assets/reset.png';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../app/store';
import { TextareaTemplate } from '../../../../../../templates/textarea';
import { SelectsTemplate } from '../../../../../../templates/select';



export const Tab_xxxx_Details = () => {
    const { Option } = Select;
    const { TextArea } = Input;

    // import { AssignedDept, AssignedLocation, AssignedSelection, AssignedUnit, Batch, Group, PPE_Select, RequestWindow, StaffName } from './PPE_selects';
    // from redux
    const form_disable = useSelector((state: RootState) => state.general.formDisabled)
    const update_state_change = useSelector((state: RootState) => state.general.taskFooterUpdate)
    const borderTheme = useSelector((state: RootState) => state.general.cssBorderClass)

    const options = [];
    for (let i = 1; i < 36; i++) {
        options.push({
            label: i.toString(36) + i,
            value: i.toString(36) + i,
        });
    }

    const [textAreaHeight, settextAreaHeight] = useState<string>('75px')

    const handleChange = (value: string[]) => {
        console.log(`selected ${value}`);
        if (value.length > 7) {
            settextAreaHeight('60px')
        } else {
            settextAreaHeight('71px')
        }
    };
    const dateFormat = " DD MMM YYYY HH: mm:ss";




    const [tasks_update_state, setTask_update_state] = useState<Boolean>(true)
    const [tasks_update_state_width, setTask_update_state_width] = useState<string>('100%')
    const [tasks_update_col_width, setTask_update_col_width] = useState<number>(100)
    const [tasks_update_col_height, setTask_update_col_height] = useState<number>()
    const [task_datagrid_width, set_task_datagrid_width] = useState(`calc(100% - ${'550px'})`);
    const [AppraiseisActive, AppraisesetIsActive] = useState<boolean>(false)



    const taskDetailsFormHeight = useRef(null);

    // const [heightHook, setheightHook] = useState<any>()

    // const refHook = (e:any) =>{
    //     if(e.length = 1){
    //         console.log("true");

    //     }
    // }

    // const refHook = ()=>{


    //     console.log(taskDetailsFormHeight);
    //     console.log('====================================');
    // }



    return (

        <div style={{ width: '900px', minWidth: '900px', height: '100%',borderColor:borderTheme }} className="  border-[1px]   dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base  ">
            {/* <h4 className=" w-full  mb-2 bg-slate-100  ">Task Details</h4> */}
            <div style={{ maxHeight: '280px', minHeight: '280px', height: '280px' }} className=' items-start justify-start '   >

                <Form
                    className='w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 px-2 '
                    name="basic"
                    labelCol={{ span: 2 }}
                    // wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    size='small'
                    layout='horizontal'

                >
                    <Form.Item label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{'Date '}</p>}>
                        <div className='flex'>
                            <div className='flex items-center pr-3'>
                                <DatePicker placeholder='Select Date' />

                            </div>

                            <Form.Item className='pr-3' >
                                <div>
                                    <DatePicker placeholder='Select Date' />
                                </div>
                            </Form.Item>


                        </div>
                    </Form.Item>
                    {/* { <Tab_xxxx_Objective_Select /> } */}
                    <SelectsTemplate
                                    label={'Objective'}
                                    placeHolder={''}
                                    options={[]}
                                    dataexp={''}
                                    idexpr={''}
                                    useCallFunc
                                    selectedValue={() => { }}
                                />


                    <div>
                    <TextareaTemplate 
                        height={40}
                        label={"From"} 
                        labelCol={2}
                        useCallbackFunc
                        defaultValue={''}
                        setCallbackValue={() => { }}
                    />
                    </div>
                    {/* <Tab_xxxx_Mini_TextArea /> */}
                   <div>
                   <TextareaTemplate 
                        height={40}
                        label={"To"} 
                        labelCol={2}
                        useCallbackFunc
                        defaultValue={''}
                        setCallbackValue={() => { }}
                    />
                   </div>
                    {/* <Tab_xxxx_TextArea /> */}

                    <div>
                    <TextareaTemplate
                        height={120}
                        label={"Remarks"}
                        labelCol={2}
                        useCallbackFunc
                        defaultValue={''}
                        setCallbackValue={() => { }}
                    />
                    </div>

                    {/* <Group />
                                            <StaffName />
                                            <RequestWindow />
                                            <Batch />
                                            <AssignedDept />
                                            <AssignedSelection />
                                            <AssignedUnit />
                                            <AssignedLocation /> */}
                    <div className='w-full  flex flex-col mt-0.5 '>
                        {/* <div style={{width:'calc(900px - 55px)',marginLeft:'19px'}} className=' flex  justify-between  '>
                                                {/* <li className='flex justify-start '><Cancel/></li> */}
                        {/* <ul className='flex justify-evenly'>
                                                    <li className=' flex items-center flex-row pr-2'><p className='mr-3'>Order :</p> <Input className='w-24'/></li>
                                                    <li className=' flex items-center flex-row'><p className='mr-3'>Activity? : </p>  <CheckBox /></li> 
                                                </ul> */}
                        <ul style={{ width: 'calc(900px - 43px)', marginLeft: '19px' }} className='flex justify-end  '>
                            {
                                (form_disable === false && update_state_change === false) ?
                                    <SaveButton handleSave={() => { }} />
                                    :
                                    (update_state_change === true) ?

                                        <UpdateButton handleUpdate={() => { }} />

                                        :
                                        <SaveButton />

                                // useCallbackFunc new_button_toggler={()=>{setform_disable(!form_disable)}}
                            }
                            {(form_disable === true) ?
                                <NewButton new_button_toggler={() => { }} />

                                :
                                (update_state_change === true) ?
                                    <Cancel handleCallbackCancel={() => { }} />
                                    :
                                    !(update_state_change && form_disable) ?
                                        <Cancel handleCallbackCancel={() => { }} />

                                        :
                                        <NewButton new_button_toggler={() => { }} />
                            }
                        </ul>
                        {/* </div> */}
                    </div>
                </Form>
            </div>



        </div>
    )
}