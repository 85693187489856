/* eslint-disable react/jsx-pascal-case */
import React, { useState } from "react";

import { Input, Select } from "antd";
import {
  mini_datagrid_batch_cols,
  mini_datagrid_cols,
} from "../data/datagrid_cols";
import search from "../../../../../../../assets/search.png";
import plus_add from "../../../../../../../assets/plus_add.png";
import Datagrid_template from "../../../../../../../templates/Datagrid";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";

interface props {
  // this means whether a prop is passed on the component or not the component called
  // anywhere should work (i.e. with the ?) but without the ? it takes it's strict rule
  // that without a prop there will be an error
  toggler?: () => void;
  datgridCol?: any;
  customHeightHook?: any;
  data?: any;
  dataVA?: any;
  setRefreshCallback?: (e: any) => void;
  statesx?: any;
  updateStatesx?: (key: string, value: any) => void;
  SingleValue?: string;
  singleOnchange?: (e: any) => void;
  handleSearch?: () => void;
  vehicleCriteria?: any;
}

export const Maintenance_Schedule_Single_Table_Form = ({
  toggler,
  datgridCol,
  customHeightHook,
  data,
  dataVA,
  setRefreshCallback,
  statesx,
  updateStatesx,
  vehicleCriteria,
  singleOnchange,
  SingleValue,
  handleSearch,
}: props) => {
  const isNotBulk = useSelector((state: RootState) => state.TMSMaintenance.Bulk_Cancel_Button);
  console.log("isNotBulk", isNotBulk);
  const [refresh, setRefresh] = useState<boolean>(false);
  const isdropdown = useSelector(
    (state: RootState) => state.general.isdropdown
  );
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  return (
    <div>
      <div
        style={{ height: "" }}
        className="flex w-full h-[25px]  dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base"
      >
        <li className=" list-none justify-center items-center mt-0.5">
          <Select
            disabled={isdropdown}
            value={statesx?.rightVeh}
            placeholder={statesx?.rightVeh}
            size="small"
            className="w-28"
            onChange={(e) => {
              updateStatesx!("rightVeh", e);
              setRefreshCallback !== undefined && setRefreshCallback(!refresh);
            }}
          >
            {vehicleCriteria?.map(
              ({ id, label }: { id: number; label: string }) => {
                return (
                  <Select.Option id={id} value={label}>
                    {label}
                  </Select.Option>
                );
              }
            )}
          </Select>
        </li>
        <li className="px-2 list-none justify-center items-center mt-0.5">
          <Input
            style={{ height: "24px" }}
            readOnly={isdropdown}
            value={statesx && statesx.miniVDSearch.temp}
            onChange={(e: any) => {
              updateStatesx &&
                updateStatesx("miniVDSearch", {
                  temp: e.target.value,
                  text: statesx.miniVDSearch?.text,
                });
            }}
          />
        </li>
        <ul className="h-full flex items-center justify-start w-full dark:bg-darkModeSkin-div_headers_backgroundColor dark:text-darkModeSkin-headers">
          <li className="w-[37px] mt-0.5 mr-2 justify-center items-center">
            <button
              onClick={() => {
                handleSearch && handleSearch();
              }}
              disabled={isdropdown}
              style={{ borderColor: borderTheme }}
              className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center px-2 py-0.5 border-2 border-slate-200 rounded hover:border-blue-300"
            >
              <img alt="" src={search} />
            </button>
          </li>{" "}
          <li className="w-[37px] mt-0.5 mr-2 justify-center items-center">
            {" "}
            <button
              disabled={isdropdown}
              style={{ borderColor: borderTheme }}
              className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  px-2 py-0.5 border-2 border-slate-200 rounded hover:border-blue-300"
            >
              <img alt="" src={plus_add} />
            </button>
          </li>
        </ul>
      </div>

      {/* 2nd Datagrid table */}
      <div
        style={{ width: "100%", marginBottom: "5px" }}
        className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base"
      >
        <Datagrid_template
        dataId="vdtIDpk"
          gridheight={315}
          columns={isNotBulk ? mini_datagrid_cols :mini_datagrid_batch_cols  }
          // columns={mini_datagrid_cols}
          deselectFirstRow
          data={
            statesx !== undefined && statesx.rightVeh === "Vehicle Details"
              ? data
              : dataVA
          }
          disableGroupPanel
          disablePaging
          disableSearch
        />
      </div>
    </div>
  );
};
