import axios from "axios"
import { api_url } from "../components/accessories/component_infos";
import { useToken } from "antd/es/theme/internal";

import { userDataCrypt } from "./userDataEncrypt";

import { requestConfig } from "../general/requestConfig";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";


export const PostDataFunc = async (url: string, payload: any, action?: string,) => {

    const postResponse = await axios.post(`${api_url}/${url}`,
        payload,
        requestConfig()
    )
    return postResponse;
}

export const PostDataFuncNoAuth = async (url: string, payload: any, action?: string,) => {

    const postResponse = await axios.post(`${api_url}/${url}`,
        payload,

    )
    return postResponse;
}

