export const stationEntriesColumns = [
  {
    id: 1,
    caption: "TIME",
    dataField: "time",
    fixed: false,
    width: 100,
  },
  {
    id: 2,
    caption: "BD0001",
    dataField: "b",
    fixed: false,
    width: 100,
    allowEditing: true,
  },
  {
    id: 3,
    caption: "BD0002",
    dataField: "d",
    fixed: false,
    width: 100,
  },
  {
    id: 4,
    caption: "REMARKS",
    dataField: "remarks",
  },
];

export const stationEntries = [
  { id: 0, time: "00:00", b: "0.00", d: "0.00", remarks: "No Remarks" },
  { id: 1, time: "01:00", b: "0.00", d: "0.00", remarks: "No Remarks" },
  { id: 2, time: "02:00", b: "0.00", d: "0.00", remarks: "No Remarks" },
  { id: 3, time: "03:00", b: "0.00", d: "0.00", remarks: "No Remarks" },
  { id: 4, time: "04:00", b: "0.00", d: "0.00", remarks: "No Remarks" },
  { id: 5, time: "05:00", b: "0.00", d: "0.00", remarks: "No Remarks" },
  { id: 6, time: "06:00", b: "0.00", d: "0.00", remarks: "No Remarks" },
  { id: 7, time: "07:00", b: "0.00", d: "0.00", remarks: "No Remarks" },
  { id: 8, time: "08:00", b: "0.00", d: "0.00", remarks: "No Remarks" },
  { id: 9, time: "09:00", b: "0.00", d: "0.00", remarks: "No Remarks" },
  { id: 10, time: "10:00", b: "0.00", d: "0.00", remarks: "No Remarks" },
  { id: 11, time: "11:00", b: "0.00", d: "0.00", remarks: "No Remarks" },
  { id: 12, time: "12:00", b: "0.00", d: "0.00", remarks: "No Remarks" },
  { id: 13, time: "13:00", b: "0.00", d: "0.00", remarks: "No Remarks" },
  { id: 14, time: "14:00", b: "0.00", d: "0.00", remarks: "No Remarks" },
];

// Station content for the BusStation tab
export const stationColumns = [
  {
    id: 1,
    caption: "STATION",
    dataField: "station",
    fixed: false,
    width: 100,
  },
  {
    id: 2,
    caption: "BUS",
    dataField: "bus",
    fixed: false,
    width: 100,
  },
  {
    id: 3,
    caption: "DATE",
    dataField: "date",
    fixed: false,
    width: 100,
  },
  {
    id: 4,
    caption: "TIME",
    dataField: "time",
    fixed: false,
    width: 100,
  },
  {
    id: 5,
    caption: "VOLTAGE",
    dataField: "voltage",
    fixed: false,
    width: 100,
  },
  {
    id: 5,
    caption: "ACTIVITY",
    dataField: "activity",
    fixed: false,
    width: 100,
  },
  {
    id: 4,
    caption: "REMARKS",
    dataField: "remarks",
  },
];

export const stations = [
  {
    id: 0,
    station: "Voldivost",
    bus: "Transit",
    date: "2021-09-01",
    time: "12:00",
    voltage: "0.00",
    activity: "0.00",
    remarks: "No Remarks",
  },
  {
    id: 1,
    station: "Station 1",
    bus: "Bus 1",
    date: "2021-09-02",
    time: "13:00",
    voltage: "1.00",
    activity: "1.00",
    remarks: "Remarks 1",
  },
  {
    id: 2,
    station: "Station 2",
    bus: "Bus 2",
    date: "2021-09-03",
    time: "14:00",
    voltage: "2.00",
    activity: "2.00",
    remarks: "Remarks 2",
  },
  {
    id: 3,
    station: "Station 3",
    bus: "Bus 3",
    date: "2021-09-04",
    time: "15:00",
    voltage: "3.00",
    activity: "3.00",
    remarks: "Remarks 3",
  },
  {
    id: 4,
    station: "Station 4",
    bus: "Bus 4",
    date: "2021-09-05",
    time: "16:00",
    voltage: "4.00",
    activity: "4.00",
    remarks: "Remarks 4",
  },
  {
    id: 5,
    station: "Station 5",
    bus: "Bus 5",
    date: "2021-09-06",
    time: "17:00",
    voltage: "5.00",
    activity: "5.00",
    remarks: "Remarks 5",
  },
  {
    id: 6,
    station: "Station 6",
    bus: "Bus 6",
    date: "2021-09-07",
    time: "18:00",
    voltage: "6.00",
    activity: "6.00",
    remarks: "Remarks 6",
  },
  {
    id: 7,
    station: "Station 7",
    bus: "Bus 7",
    date: "2021-09-08",
    time: "19:00",
    voltage: "7.00",
    activity: "7.00",
    remarks: "Remarks 7",
  },
  {
    id: 8,
    station: "Station 8",
    bus: "Bus 8",
    date: "2021-09-09",
    time: "20:00",
    voltage: "8.00",
    activity: "8.00",
    remarks: "Remarks 8",
  },
  {
    id: 9,
    station: "Station 9",
    bus: "Bus 9",
    date: "2021-09-10",
    time: "21:00",
    voltage: "9.00",
    activity: "9.00",
    remarks: "Remarks 9",
  },
  {
    id: 10,
    station: "Station 10",
    bus: "Bus 10",
    date: "2021-09-11",
    time: "22:00",
    voltage: "10.00",
    activity: "10.00",
    remarks: "Remarks 10",
  },
  {
    id: 11,
    station: "Station 11",
    bus: "Bus 11",
    date: "2021-09-12",
    time: "23:00",
    voltage: "11.00",
    activity: "11.00",
    remarks: "Remarks 11",
  },
];

// Station content for the BusStation tab
export const feederColumns = [
  {
    id: 1,
    caption: "STATION",
    dataField: "stnName",
    fixed: false,
    width: 100,
  },
  {
    id: 2,
    caption: "FEEDER",
    dataField: "fdrName",
    fixed: false,
    width: 100,
  },
  {
    id: 2,
    caption: "LOCATION",
    dataField: "locName",
    fixed: false,
    width: 100,
  },
  {
    id: 2,
    caption: "AREA",
    dataField: "areName",
    fixed: false,
    width: 100,
  },
  {
    id: 2,
    caption: "ACTIVE",
    dataField: "fdrActive",
    fixed: false,
    width: 100,
  },
  {
    id: 10,
    caption: "REMARKS",
    dataField: "fdrRmks",
  },
];

export const feeder = [
  {
    id: 0,
    station: "Voldivost",
    feeder: "Transit",
    date: "2021-09-01",
    time: "12:00",
    voltage: "0.00",
    current: "0.00",
    active: "0.00",
    activePower: "0.00",
    reactivePower: "0.00",
    remarks: "No Remarks",
  },
  {
    id: 1,
    station: "Station 1",
    feeder: "Bus 1",
    date: "2021-09-02",
    time: "13:00",
    voltage: "1.00",
    current: "1.00",
    active: "1.00",
    activePower: "1.00",
    reactivePower: "1.00",
    remarks: "Remarks 1",
  },
  {
    id: 2,
    station: "Station 2",
    feeder: "Bus 2",
    date: "2021-09-03",
    time: "14:00",
    voltage: "2.00",
    current: "2.00",
    active: "2.00",
    activePower: "2.00",
    reactivePower: "2.00",
    remarks: "Remarks 2",
  },
  {
    id: 3,
    station: "Station 3",
    feeder: "Bus 3",
    date: "2021-09-04",
    time: "15:00",
    voltage: "3.00",
    current: "3.00",
    active: "3.00",
    activePower: "3.00",
    reactivePower: "3.00",
    remarks: "Remarks 3",
  },
  {
    id: 4,
    station: "Station 4",
    feeder: "Bus 4",
    date: "2021-09-05",
    time: "16:00",
    voltage: "4.00",
    current: "4.00",
    active: "4.00",
    activePower: "4.00",
    reactivePower: "4.00",
    remarks: "Remarks 4",
  },
  {
    id: 5,
    station: "Station 5",
    feeder: "Bus 5",
    date: "2021-09-06",
    time: "17:00",
    voltage: "5.00",
    current: "5.00",
    active: "5.00",
    activePower: "5.00",
    reactivePower: "5.00",
    remarks: "Remarks 5",
  },
  {
    id: 6,
    station: "Station 6",
    feeder: "Bus 6",
    date: "2021-09-07",
    time: "18:00",
    voltage: "6.00",
    current: "6.00",
    active: "6.00",
    activePower: "6.00",
    reactivePower: "6.00",
    remarks: "Remarks 6",
  },
];

export const lineColumns = [
  {
    id: 1,
    caption: "STATION",
    dataField: "station",
    fixed: false,
    width: 100,
  },
  {
    id: 2,
    caption: "NOM.",
    dataField: "nom",
    fixed: false,
    width: 100,
  },
  {
    id: 3,
    caption: "DATE",
    dataField: "date",
    fixed: false,
    width: 100,
  },
  {
    id: 4,
    caption: "TIME",
    dataField: "time",
    fixed: false,
    width: 100,
  },
  {
    id: 5,
    caption: "VOLTAGE",
    dataField: "voltage",
    fixed: false,
    width: 100,
  },
  {
    id: 6,
    caption: "CURRENT",
    dataField: "voltage",
    fixed: false,
    width: 100,
  },
  {
    id: 7,
    caption: "ACTIVE?",
    dataField: "active",
    fixed: false,
    width: 100,
  },
  {
    id: 8,
    caption: "ACTIVE POW.",
    dataField: "activePower",
    fixed: false,
    width: 100,
  },
  {
    id: 9,
    caption: "REACTIVE POW.",
    dataField: "reactivePower",
    fixed: false,
    width: 100,
  },
  {
    id: 10,
    caption: "SOURCESTATION",
    dataField: "sourceStation",
    fixed: false,
    width: 100,
  },
  {
    id: 11,
    caption: "DEST. STATION",
    dataField: "destStation",
    fixed: false,
    width: 100,
  },
  {
    id: 12,
    caption: "REMARKS",
    dataField: "remarks",
  },
];

export const lineData = [
  {
    id: 0,
    station: "Voldivost",
    nom: "Transit",
    date: "2021-09-01",
    time: "12:00",
    voltage: "0.00",
    current: "0.00",
    active: "0.00",
    activePower: "0.00",
    reactivePower: "0.00",
    sourceStation: "Station 1",
    destStation: "Station 2",
    remarks: "No Remarks",
  },
];

export const transformerColumns = [
  {
    id: 1,
    caption: "STATION",
    dataField: "station",
    fixed: false,
    width: 100,
  },
  {
    id: 2,
    caption: "TRANSF. NOM.",
    dataField: "nom",
    fixed: false,
    width: 100,
  },
  {
    id: 3,
    caption: "DATE",
    dataField: "date",
    fixed: false,
    width: 100,
  },
  {
    id: 4,
    caption: "TIME",
    dataField: "time",
    fixed: false,
    width: 100,
  },
  {
    id: 5,
    caption: "VOLTAGE",
    dataField: "voltage",
    fixed: false,
    width: 100,
  },
  {
    id: 6,
    caption: "CURRENT",
    dataField: "voltage",
    fixed: false,
    width: 100,
  },
  {
    id: 7,
    caption: "ACTIVE?",
    dataField: "active",
    fixed: false,
    width: 100,
  },
  {
    id: 8,
    caption: "ACTIVE POW.",
    dataField: "activePower",
    fixed: false,
    width: 100,
  },
  {
    id: 9,
    caption: "REACTIVE POW.",
    dataField: "reactivePower",
    fixed: false,
    width: 100,
  },
  {
    id: 10,
    caption: "TAP CHANGER",
    dataField: "tapChanger",
    fixed: false,
    width: 100,
  },
  {
    id: 11,
    caption: "MIN. OIL TEMP",
    dataField: "minOilTemp",
    fixed: false,
    width: 100,
  },
  {
    id: 12,
    caption: "MAX. OIL TEMP",
    dataField: "maxOilTemp",
    fixed: false,
    width: 100,
  },
  {
    id: 13,
    caption: "MIN. WIND. TEMP 1",
    dataField: "minWindTemp1",
    fixed: false,
    width: 100,
  },
  {
    id: 13,
    caption: "MAX. WIND. TEMP 1",
    dataField: "maxWindTemp1",
    fixed: false,
    width: 100,
  },
  {
    id: 14,
    caption: "MIN. WIND. TEMP 2",
    dataField: "minWindTemp2",
    fixed: false,
    width: 100,
  },
  {
    id: 15,
    caption: "MAX. WIND. TEMP 2",
    dataField: "maxWindTemp2",
    fixed: false,
    width: 100,
  },
  {
    id: 16,
    caption: "REMARKS",
    dataField: "remarks",
  },
];

export const transformerData = [
  {
    id: 0,
    station: "Voldivost",
    nom: "Transit",
    date: "2021-09-01",
    time: "12:00",
    voltage: "0.00",
    current: "0.00",
    active: "0.00",
    activePower: "0.00",
    reactivePower: "0.00",
    tapChanger: "0.00",
    minOilTemp: "0.00",
    maxOilTemp: "0.00",
    minWindTemp1: "0.00",
    maxWindTemp1: "0.00",
    minWindTemp2: "0.00",
    maxWindTemp2: "0.00",
    remarks: "No Remarks",
  },
  {
    id: 1,
    station: "Voldivost",
    nom: "Transit",
    date: "2021-09-01",
    time: "12:00",
    voltage: "0.00",
    current: "0.00",
    active: "0.00",
    activePower: "0.00",
    reactivePower: "0.00",
    tapChanger: "0.00",
    minOilTemp: "0.00",
    maxOilTemp: "0.00",
    minWindTemp1: "0.00",
    maxWindTemp1: "0.00",
    minWindTemp2: "0.00",
    maxWindTemp2: "0.00",
    remarks: "No Remarks",
  },
  {
    id: 2,
    station: "Voldivost",
    nom: "Transit",
    date: "2021-09-01",
    time: "12:00",
    voltage: "0.00",
    current: "0.00",
    active: "0.00",
    activePower: "0.00",
    reactivePower: "0.00",
    tapChanger: "0.00",
    minOilTemp: "0.00",
    maxOilTemp: "0.00",
    minWindTemp1: "0.00",
    maxWindTemp1: "0.00",
    minWindTemp2: "0.00",
    maxWindTemp2: "0.00",
    remarks: "No Remarks",
  },
  {
    id: 3,
    station: "Voldivost",
    nom: "Transit",
    date: "2021-09-01",
    time: "12:00",
    voltage: "0.00",
    current: "0.00",
    active: "0.00",
    activePower: "0.00",
    reactivePower: "0.00",
    tapChanger: "0.00",
    minOilTemp: "0.00",
    maxOilTemp: "0.00",
    minWindTemp1: "0.00",
    maxWindTemp1: "0.00",
    minWindTemp2: "0.00",
    maxWindTemp2: "0.00",
    remarks: "No Remarks",
  },
  {
    id: 4,
    station: "Voldivost",
    nom: "Transit",
    date: "2021-09-01",
    time: "12:00",
    voltage: "0.00",
    current: "0.00",
    active: "0.00",
    activePower: "0.00",
    reactivePower: "0.00",
    tapChanger: "0.00",
    minOilTemp: "0.00",
    maxOilTemp: "0.00",
    minWindTemp1: "0.00",
    maxWindTemp1: "0.00",
    minWindTemp2: "0.00",
    maxWindTemp2: "0.00",
    remarks: "No Remarks",
  },
];
