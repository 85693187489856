
import { DataGrid } from "devextreme-react";
import { Column, Pager, Scrolling } from "devextreme-react/data-grid";
import { staff_table_1_data, staff_table_1_header } from "../data/updateStaffData";

const UpdateStaffMiniTable = () => {
    return (<div className=" mt-4">
        <DataGrid
            dataSource={staff_table_1_data}
            showBorders={true}
            columnWidth={120}
            showRowLines={true}
            showColumnLines={true}
            height={280}
            width={550}



        >
            <Pager visible={false} />

            {/* STAFF DETAILS COLUMNS */}
            {/* FIXED COLUMNS */}

            {
                staff_table_1_header.map((data) => {
                    return (
                        <Column
                            key={data.id}
                            caption={data.caption}
                            dataField={data.dataField}
                            alignment={"left"}
                            fixed={data.fixed}
                            width={data.width === null ? 100 : data.width}
                        />
                    )
                })
            }

            {/* SCROLLBAR  */}

            <Scrolling columnRenderingMode="virtual" />








        </DataGrid>
    </div>);
}

export default UpdateStaffMiniTable;