import React, { useState, useEffect } from 'react'
import { Form, Input, Select, Checkbox } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { SaveButton, NewButton, UpdateButton, Cancel } from '../../../../accessories/buttons';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../app/store';
import axios from 'axios';
import { api_url, saveModalData, updateModalData } from '../../../../accessories/component_infos';
import { useDispatch } from 'react-redux';
import { ModalTemplate } from '../../../../../templates/modal';
import { DateTemplate } from '../../../../../templates/date';
import { inputsTypes, selectsTypes } from '../../../../human-resource/setups/data/types/selectsTypes';
import { SelectsTemplate } from '../../../../../templates/select';
import useFetch from '../../../../../hooks/useFetch';
import { TextareaTemplate } from '../../../../../templates/textarea';
import { task_footer_table_selected } from '../../../../../features/forms/entries/safetyManager/ppeRequest';
import { useAccountInfo } from '../../../../../hooks/useAccountInfo';
import { useBusyLoader } from '../../../../../hooks/useBusyLoader';
import { PostDataFunc } from '../../../../../functions/post';
import { UpdateDataFunc } from '../../../../../functions/update';
import { setGeneralValue } from '../../../../../features/generalSlice';

interface statesType {
    startDate?: string;
    endDate?: string;
    perspective: { id: number; name: string }
    initiative: { id: number; name: string }
    shortname: { id: number; name: string }
    remarks: string
    active: boolean
}


interface Props {
    selectedRecordx?: any;
    setSelectedRecordx?: (e: any) => void
    setRefreshx?: () => void
}



export const CI_Details_Form = ({ selectedRecordx, setSelectedRecordx, setRefreshx }: Props) => {
    const { Option } = Select;
    const { TextArea } = Input;

    const dispatch = useDispatch()
    //  Current date
    const editedDate = dayjs().format()

    const borderTheme = useSelector((state:RootState)=>state.general.cssBorderClass)

    const [states, setStates] = useState<statesType>({
        startDate: editedDate,
        endDate: editedDate,
        perspective: { id: 0, name: "" },
        initiative: { id: 0, name: "" },
        shortname: { id: 0, name: "" },
        remarks: "",
        active: false,
    })

    const setState = (key: string, value: any) => {
        setStates((prev) => ({ ...prev, [key]: value }))
    }

    console.log(states)
    const [refresh, setRefresh] = useState<boolean>(false)


    const update_state_change = useSelector((state: RootState) => state.general.updateMode)
    const form_disable = useSelector((state: RootState) => state.general.formDisabled)

    const [perspective_data, perspective_error, perspective_loading] = useFetch('PfmCorporateInitiative/GetPfmLuePerspectives?isActive=true', refresh, 'cinIdpk')
    const [initiative_data, initiative_error, initiative_loading] = useFetch('PfmCorporateInitiative/GetPfmInitiatives')
    const [shortname_data, shortname_error, shortname_loading] = useFetch('PfmCorporateInitiative/GetPfmInitiatives')
    //    const [Objective_data, objective_error,objective_loading] = useFetch(`api/PfmCorporateInnitiativeAssignment/GetPfmLueObjectivesWithMissionId?isActive=true&missionId=${MissionID}`)

    // import { AssignedDept, AssignedLocation, AssignedSelection, AssignedUnit, Batch, Group, PPE_Select, RequestWindow, StaffName } from './PPE_selects';


    const options = [];
    for (let i = 1; i < 36; i++) {
        options.push({
            label: i.toString(36) + i,
            value: i.toString(36) + i,
        });
    }

    const populateFields = (clear?: boolean) => {
        if (clear === true) {
            dispatch(task_footer_table_selected([]))
            setSelectedRecordx!({})
        }

        const gridState = [
            { key: 'startDate', value: clear ? editedDate : selectedRecordx?.prdStartDate ?? dayjs().format()},
            { key: 'endDate', value: clear ? editedDate : selectedRecordx?.prdEndDate ?? dayjs().format()},
            { key: 'perspective', value: clear ? { id: 0, name: '' } : { id: selectedRecordx?.ptvIdpk, name: selectedRecordx?.ptvName} ?? { id: 0, name: '' } },
            { key: 'initiative', value: clear ? { id: 0, name: '' } : { id: selectedRecordx?.cinIdpk, name: selectedRecordx?.cinName } ?? { id: 0, name: '' } },
            { key: 'shortname', value: clear ? { id: 0, name: '' } : { id: selectedRecordx?.cinIdpk, name: selectedRecordx?.cinShtName } ?? { id: 0, name: '' } },
            { key: 'remarks', value: clear ? '' : selectedRecordx?.cinRmks ?? '' },
            { key: 'active', value: clear ? true : selectedRecordx?.cinActive ?? false},
        ]

        
        for (let instance of gridState) setState(instance.key, instance.value);
    }


    const selects: selectsTypes[] = [

        { id: 1, label: 'Perspectives', optionsData: perspective_data, dataExpr: "ptvName", idExpr: "ptvIdpk", stateName: 'perspective', defaultValue: states.perspective?.name, style: "30%" },
        { id: 2, label: 'Initiative', optionsData: initiative_data, dataExpr: "name", idExpr: "id", stateName: 'initiative', defaultValue: states.initiative?.name, style: "" },
        { id: 3, label: 'Shortname', optionsData: shortname_data, dataExpr: "shortName", idExpr: "id", stateName: 'shortname', defaultValue: states.shortname?.name, style: "" },

    ]



    
    
    // console.log(editedDate)

    // Modal dialog data states

    const [refreshValue, setRefreshValue] = useState(false);
    const [showModalDisableButton, setshowModalDisableButton] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [modalData, setModalData] = useState<any>(saveModalData);
    const [icon, setIcon] = useState<'warning' | 'success' | 'question'>();
    const [updatingstatus, setUpdatingstatus] = useState(false);
    const [showCancel, setshowCancel] = useState(false);




    const Access_new = ()=>{
        populateFields(true)
        dispatch(setGeneralValue({expr:"formDisabled", value :false}))

    }

    

    // Validation 

    const validation = (action: 'post' | 'put') => {
        setIcon('warning')
        if (!states.perspective) {
            setshowModalDisableButton(true);
            setModalData({ message: "Please select data entry for Perspective", title: "Select Service Perspective ", okText: "Ok" });
            setOpenModal(true);

        } else if (!states.initiative) {
            setshowModalDisableButton(true);
            setModalData({ message: "Please enter data for Initiative", title: "Data entry for Initiative", okText: "Ok" });
            setOpenModal(true);
        } else if (!states.shortname) {
            setshowModalDisableButton(true);
            setModalData({ message: "Please enter data for ShortName", title: "Data entry for ShortName", okText: "Ok" });
            setOpenModal(true);
        }
        else {
            setshowModalDisableButton(false);
            setIcon('question')
            setOpenModal(true);
            setModalData(action === 'post' ? saveModalData : updateModalData)
        }
    }


    const[employeeId, userId] = useAccountInfo()  
    const[busyLoader, setBusyLoader] = useBusyLoader()

    const postData = async () => {
        try {
            setBusyLoader(saveModalData.progress);

            await PostDataFunc(`PfmCorporateInitiative/CreatePfmCorpInitiative`, {
                "cinPerspectiveIdfk": states.perspective.id,
                "cinName": states.initiative.name,
                "cinShtName": states.shortname.name,
                "cinStartDate": dayjs(states.startDate).format(),
                "cinEndDate": dayjs(states.endDate).format(),
                "cinActive": states.active,
                "cinRmks": states.remarks ?? "",
                "cinCreatedBy": userId
            }).then((response) => {
                setRefreshx && setRefreshx()
                setIcon('success')
                setOpenModal(true);
                setshowModalDisableButton(true);
                setModalData({ message: "Data entry saved successfully!", title: "Data Saved!", okText: "Ok" });
            })
        } catch (e: any) {
            console.log(e);
            setIcon('warning')
            setOpenModal(true);
            setshowModalDisableButton(true);

            setModalData({ message: saveModalData.error.message, title: saveModalData.error.title, okText: "Ok" });
        }
    }

    // Update

    const updateData = async () => {
        try {
            setBusyLoader(updateModalData.progress);
            
            const res = await UpdateDataFunc(`PfmCorporateInitiative/UpdatePfmCorpInitiative`, {
                "cinIdpk": selectedRecordx?.cinIdpk,
                "cinPerspectiveIdfk": states.perspective?.id,
                "cinName": states.initiative?.name,
                "cinShtName": states.shortname?.name,
                "cinStartDate": dayjs(states.startDate).format(),
                "cinEndDate": dayjs(states.endDate).format(),
                "cinActive": states.active,
                "cinRmks": states.remarks ?? '',
                "cinEditedBy": userId,
                "cinEditedDate": editedDate
            })


            setIcon('success')
            setOpenModal(true);
            setshowModalDisableButton(true);
            setModalData({ message: "Data entry saved successfully!", title: "Data Saved!", okText: "Ok" });

            setRefreshx && setRefreshx()

        } catch (e: any) {
            console.log(e);

            setIcon('warning')
            setOpenModal(true);
            setshowModalDisableButton(true);
            setModalData({ message: updateModalData.error.message, title: updateModalData.error.title, okText: "Ok" });
        } finally{
            setBusyLoader('');
        }
    }

    const datex: inputsTypes[] = [
        { id: 0, label: 'Start Date', stateName: 'startDate', defaultValue: states.startDate },
        { id: 1, label: 'End Date', stateName: 'endDate', defaultValue: states.endDate },
    ]

    useEffect(() => {
        populateFields(false);
    }, [selectedRecordx])


    return (

        <div style={{ width: '100%', height: '100%', borderColor: borderTheme }} className="  border-[1px]   dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base  ">
            {/* <h4 className=" w-full  mb-2 bg-slate-100  ">Task Details</h4> */}
            <ModalTemplate 
            icon={icon} 
            cancelText={modalData.cancelText}
            cancelHandler={() => { setOpenModal(false) }} open={openModal} okHandler={() => { modalData === saveModalData ? postData() : modalData === updateModalData ? updateData() : setOpenModal(false) }}
            message={modalData.message} 
            okText={modalData.okText} 
            disableCancel={showModalDisableButton}
            title={modalData.title} />

            <div style={{ maxHeight: '275px', minHeight: '275px', height: '275px' }} className=' items-start justify-start '   >

                <Form
                    className='w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 px-2 '
                    name="basic"
                    labelCol={{ span: 2 }}
                    // wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    size='small'
                    layout='horizontal'


                >

                    {datex.map(({ id, label, stateName, defaultValue }) => {
                        return (
                            <div key={id} className="">
                                <DateTemplate
                                    label={label}
                                    labelCol = {2}
                                    disabled={form_disable}
                                    changeDate={(e: Dayjs) => { setState(stateName!, dayjs(e).format('YYYY-MM-DD')) }}
                                    width={'18%'}
                                    selectedDate={dayjs(defaultValue)}
                                />
                            </div>
                        )

                    })}

                    {selects.map(({ label, idExpr, dataExpr, optionsData, stateName, defaultValue, style }) => {
                        return (
                            <SelectsTemplate
                                idexpr={idExpr}
                                dataexp={dataExpr}
                                label={label}
                                options={optionsData}
                                placeHolder={defaultValue}
                                useCallFunc
                                selectedValue={(e) => {
                                    if(stateName === 'initiative' || stateName === 'shortname'){
                                        setState('shortname', {id: e['id'], name: e['shortName']})
                                        setState('initiative', {id: e['id'], name: e['name']})
                                    }else{
                                    setState(stateName!, { id: e[idExpr!], name: e[dataExpr!] }) }}
                                }
                                disabled={form_disable }
                                span={label === "Shortname"}
                                disabledCustomWidth={true}
                                cusWidth={style}


                            />
                        )
                    })}


                    <div>
                        <TextareaTemplate
                            height={93}
                            label={'Remarks'}
                            useCallbackFunc
                            labelCol={2}
                            defaultValue={states.remarks}
                            readonly={form_disable}
                            setCallbackValue={(e) => {setState('remarks', e)  }}
                        />
                    </div>

                    <Form.Item label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{'Active?'}</p>} className='mb-1 w-full dark:bg-slate-900 dark:text-darkModeSkin-base '>
                        <div className='flex'>
                            <li className=' flex items-center justify-start  '>  
                            <Checkbox 
                            style={{borderColor:borderTheme}}
                            checked={states.active}
                            disabled={form_disable}
                            className='border-[1px] px-1 rounded' 
                            onChange={(e)=>{
                                setState('active', e.target.checked)
                            }} 
                            /></li>

                            {/* <Group />
                                            <StaffName />
                                            <RequestWindow />
                                            <Batch />
                                            <AssignedDept />
                                            <AssignedSelection />
                                            <AssignedUnit />
                                            <AssignedLocation /> */}

                            <div className=' flex  justify-end w-full  '>
                                {/* <li className='flex justify-start '><Cancel/></li> */}

                                <ul style={{ marginRight: '' }} className='flex justify-end  '>
                                    {



                                        (form_disable === false && update_state_change === false) ?
                                            <SaveButton handleSave={() => {
                                                validation('post')
                                            }} />


                                            :

                                            (update_state_change === true) ?

                                                <UpdateButton handleUpdate={() => { validation('put') }} />  //validationForms()

                                                :
                                                <SaveButton />


                                        // useCallbackFunc new_button_toggler={()=>{setform_disable(!form_disable)}}
                                    }
                                
                                    {(form_disable === true) ?
                                        <NewButton useCallbackFunc={false} new_button_toggler={() => { Access_new() }} 
                                    />

                                        :
                                        (update_state_change === true) ?
                                            <Cancel handleCallbackCancel={() => { }} cancel_button_toggler={() => { dispatch(setGeneralValue({expr:"formDisabled", value:true}))}} />
                                            :
                                            !(update_state_change && form_disable) ?
                                                <Cancel handleCallbackCancel={() => { }} cancel_button_toggler={() => { }} />

                                                :
                                                <NewButton useCallbackFunc={false} new_button_toggler={() => { Access_new() }} />
                                    }
                                    
                                </ul>
                            </div>

                        </div>
                        
                    </Form.Item>
                </Form>
            </div>



        </div>
    )
}