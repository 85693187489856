/* eslint-disable react/jsx-pascal-case */
import { forwardRef, useEffect, useRef, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { navbar_height } from "../../../../accessories/component_infos";
import { HrSetupsCustomToolbar } from "../../../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import PermitsHolderForm from "./widgets/permitsHolderForm";
import { permitsHolderColumns } from "./data/permitsHolderData";
import Datagrid_template from "../../../../../templates/Datagrid";
import useFetch from "../../../../../hooks/useFetch";
import CustomLoader from "../../../../accessories/custom-loader";
import { ModalTemplate } from "../../../../../templates/modal";
import {
  modalPropTypes,
  searchCriteriaTypes,
  searchTextTypes,
  statusTypes,
} from "../../../../human-resource/setups/data/types/selectsTypes";
import { setGeneralValue } from "../../../../../features/generalSlice";
import { useDispatch } from "react-redux";
import { useAccountInfo } from "../../../../../hooks/useAccountInfo";
import dayjs from "dayjs";
import useResizeMode from "../../../../../hooks/useResizeMode";
import { useCrudFunc } from "../../../../../functions/crud";

export const DriversPermitHoldersAssignments = forwardRef(({ a }: any, ref) => {
  const renderCount = useRef(null);
  const dispatch = useDispatch();
  const [_, UpdateDataFunc] = useCrudFunc();
  //navigation
  const setupPageRoute = window.location.pathname; //get current route of page
  const datagridOnlySearchParam = "table-only"; //search param in datagrid mode
  const formModeSearchParam = "form-and-table"; //search param in form mode mode
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [empID, userID, userInfo] = useAccountInfo();

  const editedDate = dayjs().format();
  const endDate = dayjs().format();

  //miscellaneous heights
  const miscHeight = 7;
  //varying form height
  const [form_height, set_form_height] = useState<number>(415);
  // Page resize height responsiveness
  // on page resize height responsiveness

  // handle interface switch
  const toggle_staff_form = (): void => {
    const view = searchParams.get("view");

    view === null || view === formModeSearchParam
      ? navigate({
          pathname: setupPageRoute,
          search: createSearchParams({
            view: datagridOnlySearchParam,
          }).toString(),
        })
      : navigate({
          pathname: setupPageRoute,
          search: createSearchParams({
            view: formModeSearchParam,
          }).toString(),
        });
  };

  const [selectedRecord, setSelectedRecord] = useState<any>({});

  // Data Manupulation Methods

  const CancelData = {
    message: "Are you sure you want to set this record as Cancelled",
    title: "Toggle Cancel Status? ",
    okText: "Yes",
    cancelText: "No",
  };
  const ActivateData = {
    message: "Are you sure you want to Activate  this Record",
    title: "Toggle Active Status? ",
    okText: "Yes",
    cancelText: "No",
  };
  const CancelDataAll = {
    message: `Are you sure you want to toggle the active status of all records for ${selectedRecord?.empName} to false?`,
    title: "Toggle All Cancelled Status? ",
    okText: "Yes",
    cancelText: "No",
  };
  const ActivateDataAll = {
    message: `Are you sure you want to toggle the active status of all records for ${selectedRecord?.empName} to true?`,
    title: "Toggle All Active Status? ",
    okText: "Yes",
    cancelText: "No",
  };

  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [modalData, setModalData] = useState<any>(ActivateData);
  const [openModal, setOpenModal] = useState(false);
  const [refreshValue, setRefreshValue] = useState(false);

  // toggle selected record

  const Cancel = async () => {
    if (Object.keys(selectedRecord).length < 1) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select a Record",
        title: "Select Vehicle request",
        okText: "Ok",
      });
      setOpenModal(true);
    }

    if (selectedRecord.dpaActive === true) {
      try {
        await UpdateDataFunc(
          `DriversPermitHolders/ToggleActiveStatus?dpaIDpk=${selectedRecord.dpaIDpk}`,
          {
            dpaActive: false,
            dpaEndDate: endDate,
            dpaaEditedBy: userID,
            dpaEditedDate: editedDate,
          }, 
          `Updated the  DriversPermitHolders with record dpaEditedDate:${editedDate} Date Edited:${editedDate}. Edited By:${userID}`
        );

        setRefreshValue(!refreshValue);
        setRefresh(!refreshValue);
        setIcon("success");
        setOpenModal(true);
        setshowModalDisableButton(false);
        setModalData({
          message: "Request has been Changed successfully!",
          title: "Active Changed Successfully",
          okText: "Ok",
        });
      } catch (error: any) {
        setIcon("warning");
        setOpenModal(true);
        setshowModalDisableButton(false);
        setModalData({
          message: error.response ? error.response.data : "Server Error",
          title: "Unexpected Error!",
          okText: "Ok",
        });
      }
    } else {
      Activate();
    }
  };

  const Activate = async () => {
    if (Object.keys(selectedRecord).length < 1) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select a Record",
        title: "Select Vehicle request",
        okText: "Ok",
      });
      setOpenModal(true);
    }

    if (selectedRecord.dpaActive === false) {
      try {
        // Find the endpoint that updates a single records active
        await UpdateDataFunc(
          `DriversPermitHolders/ToggleActiveStatus?dpaIDpk=${selectedRecord.dpaIDpk}`,
          {
            dpaActive: true,
            dpaEditedBy: userID,
            dpaEditedDate: editedDate,
          },
          `Updated the  DriversPermitHolders with record dpaEditedDate:${editedDate} Date Edited:${editedDate}. Edited By:${userID}`
        );

        setRefreshValue(!refreshValue);
        setRefresh(!refreshValue);
        setIcon("success");
        setOpenModal(true);
        setshowModalDisableButton(false);
        setModalData({
          message: "Request has been Activated successfully!",
          title: "Activated Successfully",
          okText: "Ok",
        });
      } catch (error: any) {
        setIcon("warning");
        setOpenModal(true);
        setshowModalDisableButton(false);
        setModalData({
          message: error.response ? error.response.data : "Server Error",
          title: "Unexpected Error!",
          okText: "Ok",
        });
      }
    } else {
      Activate();
    }
  };

  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  // toggle All record

  const CancelAll = async () => {
    if (Object.keys(selectedRecord).length < 1) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select a Record",
        title: "Select Vehicle request",
        okText: "Ok",
      });
      setOpenModal(true);
    }

    if (selectedRecord.dpaActive === true) {
      try {
        // this loops through all records which is done backend
        // this needs an endpoint that is used to update a bulk record
        await UpdateDataFunc(
          `DriversPermitHolders/ToggleAllActiveStatus?empIDpk=${selectedRecord.empIDpk}`,
          {
            dpaEmpIdfk: selectedRecord.empIDpk,
            dpaActive:
              selectedRecord?.dpaActive !== undefined ||
              selectedRecord?.dpaActive !== null ||
              selectedRecord?.dpaActive !== ""
                ? !selectedRecord?.dpaActive
                : false,
            dpaEditedBy: userID,
            dpaEditedDate: editedDate,
          },
          `Updated the  DriversPermitHolders with record dpaEditedDate:${editedDate} Date Edited:${editedDate}. Edited By:${userID}`

        );

        setRefreshValue(!refreshValue);
        setRefresh(!refreshValue);
        setIcon("success");
        setOpenModal(true);
        setshowModalDisableButton(false);
        setModalData({
          message: "Request has been Changed successfully!",
          title: "Active Changed Successfully",
          okText: "Ok",
        });
      } catch (error: any) {
        setIcon("warning");
        setOpenModal(true);
        setshowModalDisableButton(false);
        setModalData({
          message: error.response ? error.response.data : "Server Error",
          title: "Unexpected Error!",
          okText: "Ok",
        });
      }
    } else {
      ActivateAll();
    }
  };

  const ActivateAll = async () => {
    if (Object.keys(selectedRecord).length < 1) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select a Record",
        title: "Select Vehicle request",
        okText: "Ok",
      });
      setOpenModal(true);
    }

    if (selectedRecord.dpaActive === false) {
      try {
        // this loops through all the records which is done backend
        await UpdateDataFunc(
          `DriversPermitHolders/ToggleAllActiveStatus?dpaEmpIDfk=${selectedRecord.empIDpk}`,
          {
            dpaEmpIdfk: selectedRecord.empIDpk,
            dpaActive:
              selectedRecord?.dpaActive !== undefined ||
              selectedRecord?.dpaActive !== null ||
              selectedRecord?.dpaActive !== ""
                ? !selectedRecord?.dpaActive
                : true,
            dpaEditedDate: editedDate,
            dpaEditedBy: userID,
          },
          `Updated the  DriversPermitHolders with record dpaEmpIdfk:${selectedRecord.empIDpk} Date Edited:${editedDate}. Edited By:${userID}`
        );

        setRefreshValue(!refreshValue);
        setRefresh(!refreshValue);
        setIcon("success");
        setOpenModal(true);
        setshowModalDisableButton(false);
        setModalData({
          message: "Request has been Activated successfully!",
          title: "Activated Successfully",
          okText: "Ok",
        });
      } catch (error: any) {
        setIcon("warning");
        setOpenModal(true);
        setshowModalDisableButton(false);
        setModalData({
          message: error.response ? error.response.data : "Server Error",
          title: "Unexpected Error!",
          okText: "Ok",
        });
      }
    } else {
      CancelAll();
    }
  };

  let [resizeMode, initialResizeMode] = useResizeMode(
    searchParams.get("view") === formModeSearchParam ||
      searchParams.get("view") === null
  );

  //Table Height
  var pageHeight = resizeMode - (navbar_height + miscHeight + 520);
  var tableHeight = initialResizeMode - 200;

  const [refresh, setRefresh] = useState<boolean>(false); //refresh data state for data creation or update
  const [staffDataReady, setStaffDataReady] = useState<boolean>(false); //staff datagrid in child ready status
  const [busyLoader, setBusyLoader] = useState<string>("");
  const [modal, setModal] = useState<modalPropTypes>({
    state: false,
    title: "",
    message: "",
  });

  const [searchCriteria, setSearchCriteria] = useState<searchCriteriaTypes>({
    temp: 0,
    index: 0,
  });
  const criteria = [
    { id: 0, value: "Section" },
    { id: 1, value: "Staff Name" },
    { id: 2, value: "Department" },
  ];

  const [searchText, setSearchText] = useState<searchTextTypes>({
    text: "",
    temp: "",
  });
  const [status, setStatus] = useState<statusTypes>({ temp: "", status: "" });

  const [driversPermitData, driversPermitErr, driversPermitLoading] = useFetch(
    `DriversPermitHolders/GetAllDriversPermitholdersAssignmentsForGdv?empName=${searchText.text}&dpaActive=${status.status}`,
    refresh
  );
  useEffect(() => {
    if (driversPermitErr) {
      setModal({
        state: true,
        title: "Server Error",
        message:
          "Error getting data from server. Please retry or contact system administrator",
      });
    }
  }, [driversPermitErr]);

  return (
    <>
      <ModalTemplate
        icon_width={modalData.iconSize === "large" ? 100 : 50}
        icon={icon}
        cancelText={modalData.cancelText}
        disableCancel={showModalDisableButton}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        open={openModal}
        okHandler={() => {
          modalData.title === ActivateData.title
            ? Activate()
            : modalData.title === CancelData.title
            ? Cancel()
            : modalData.title === ActivateDataAll.title
            ? ActivateAll()
            : modalData.title === CancelDataAll.title
            ? CancelAll()
            : setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
      />

      {busyLoader && <CustomLoader text={busyLoader} />}

      {driversPermitLoading && renderCount.current && <CustomLoader />}
      <div className="h-full">
        <div className="px-2">
          <HrSetupsCustomToolbar
            searchCriteria={true}
            searchTextOnchange={(text) =>
              setSearchText({ temp: text, text: searchText.text })
            }
            searchCriteriaOnchange={(crit) =>
              setSearchCriteria({ temp: crit, index: searchCriteria.index })
            }
            checkOnChange={(check) =>
              setStatus({ temp: check, status: status.status })
            }
            handleFind={() => {
              setRefresh((current) => !current);
              setSearchText({ temp: searchText.temp, text: searchText.temp });
              setSearchCriteria({
                temp: searchCriteria.temp,
                index: searchCriteria.temp,
              });
              setStatus({ temp: status.temp, status: status.temp });
            }}
            searchCriteriaData={criteria}
            handleRefresh={() => {
              setRefresh((current) => !current);
              setSearchText({ temp: "", text: "" });
              setSearchCriteria({
                temp: 0,
                index: 0,
              });
              setStatus({ temp: "", status: "" });
            }}
            withDates={false}
            disableNew_edit
            handleToggle={() => {
              selectedRecord.dpaActive === false
                ? setModalData(ActivateData)
                : setModalData(CancelData);
              setOpenModal(true);
              setIcon("question");
            }}
            handleToggleAll={() => {
              selectedRecord.dpaActive === false
                ? setModalData(ActivateDataAll)
                : setModalData(CancelDataAll);
              setOpenModal(true);
              setIcon("question");
            }}
            toggler={toggle_staff_form}
          />
        </div>

        {/* form section */}
        {searchParams.get("view") !== datagridOnlySearchParam && (
          <section style={{ height: form_height }} className=" form border-b-4">
            <PermitsHolderForm
              busyLoader={(text) => setBusyLoader(text)}
              staffDatagrid={(status) => setStaffDataReady(true)}
              refresh={() => setRefresh(!refresh)}
            />
          </section>
        )}

        {/* datagrid section */}
        <section className=" px-2">
          <Datagrid_template
            gridheight={
              searchParams.get("view") === formModeSearchParam ||
              searchParams.get("view") === null
                ? pageHeight
                : tableHeight
            }
            data={driversPermitData}
            columns={permitsHolderColumns}
            onRowClick={(e) => {
              setSelectedRecord(e);
            }}
            rowDoubleClicked={(e) => {
              dispatch(setGeneralValue({ expr: "selectedRecord", value: e }));
              setSelectedRecord(e);
            }}
          />
        </section>
      </div>
    </>
  );
});
