export const assetsSetups_maintenancelocation_columns = [
  {
    id: 0,
    caption: "MAINT. LOCATION",
    dataField: "mlcName",
    datatype: "number",
    width: 200,
    fixed: true,
    visible: true,
  },
  {
    id: 1,
    caption: "SHORT NAME",
    dataField: "mlcShtName",
    datatype: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 3,
    caption: "AREA",
    dataField: "areName",
    datatype: "string",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 4,
    caption: "LOCATION",
    dataField: "locName",
    datatype: "string",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 5,
    caption: "TOWN",
    dataField: "twnName",
    datatype: "string",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 6,
    caption: "CONTACTS",
    dataField: "mlcMainContacts",
    datatype: "string",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 7,
    caption: "TEL. Nos",
    dataField: "mlcTelNos",
    datatype: "string",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 8,
    caption: "EMAIL",
    dataField: "mlcEmails",
    datatype: "string",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 9,
    caption: "ORDER",
    dataField: "mlcOrder",
    datatype: "string",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 10,
    caption: "ACTIVE?",
    dataField: "mlcActive",
    datatype: "boolean",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 11,
    caption: "REMARKS",
    dataField: "mlcRmks",
    datatype: "string",
    width: 5000,
    fixed: false,
    visible: true,
  },
];
