

export const staff_table_1_header = [
    {
        id: 0,
        caption:"No",
        dataField:"No",
        alignment:"center",
       fixed: true, 
        width:50,
    },
    {
        id: 1,
        caption:"Staff No",
        dataField:"Staff No",
        alignment:"left",
        fixed: true,
        width : null
    },
    {
        id: 2,
        caption:"Staff Name",
        dataField:"Staff Name",
        alignment:"left",
        fixed: true,
        width : null
    },
    {
        id: 3,
        caption:"Dept",
        dataField:"Dept",
        alignment:"center",
        fixed: true,
        width : null

    },
    {
        id: 4,
        caption:"Section",
        dataField:"Section",
        alignment:"center",
        fixed: false,
        width : null

    },
    {
        id: 5,
        caption:"Unit",
        dataField:"Unit",
        alignment:"center",
        fixed: false,
        
    },
    {
        id: 6,
        caption:"Location",
        dataField:"Location",
        alignment:"left",
        fixed: false,
        width : null

    },
    {
        id: 7,
        caption:"Job Title",
        dataField:"Job Title",
        alignment:"left",
        fixed: false,
        width : null

    },  
]




export const staff_table_1_data =   Array.from({length: 50}, () =>{return {
    'No' : true,
    'Staff No' : 'Abrego, Mr Kelsi',
    'Staff Name': 720551,
    'Dept' : '236-931-8173',
    'Section' :'', 
    'Unit': 'South Grid',
   'Location': 'Telmin Area',
    'Job Title': 'Electrical Engineer',
    


}}, );

