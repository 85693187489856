import reset from "../../../../../../assets/reset.png";
import avatar from "../../../../../../assets/profile.jpg";
import signature from "../../../../../../assets/signature.jpg";
import upload from "../../../../../../assets/upload.png";

import { Button, Input, Upload } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";
import useFetch from "../../../../../../hooks/useFetch";

export const HrInputRightSecFiles = () => {
  const [profilePicture, setprofilepicture] = useState(upload);
  // const[data,setData] = useState();
  const dispatch = useDispatch();
  const formdisabled = useSelector(
    (state: RootState) => state.general.formDisabled
  ); //form disabled mode from general redux slice
  const pphEmpIdfk = useSelector(
    (state: RootState) => state.general.pphEmpIdfk
  ); //form disabled mode from general redux slicev

  const [data] = useFetch(`HrEmployee/Pictures?EmpId=${pphEmpIdfk}`, true);
  const [sigData] = useFetch(`HrEmployee/Signatures?EmpId=${pphEmpIdfk}`, true);
  console.log(sigData);
  return (
    <>
      <div className="image-signature w-1/2 flex flex-row dark:bg-fill dark:text-darkModeSkin-base">
        {/* image */}
        <div className="dark:bg-fill dark:text-darkModeSkin-base">
          <div className="flex flex-row h-28 mr-6 mb-1 dark:bg-fill dark:text-darkModeSkin-base">
            <span className="b border-2  flex justify-center mr-1  items-center bg-slate-100 border-slate-300 rounded h-full w-24">
              {data && data[0]?.ppxPicture ? (
                <img
                  className=""
                  src={`data:image/jpeg;base64,${data[0].ppxPicture}`}
                  alt=""
                />
              ) : (
                <p className="flex font-bold text-center  justify-center w-full">
                  {" "}
                  No image Found
                </p>
              )}
            </span>

            {/* reset icon */}
            <span
              onClick={() => {
                // setData('');
                // handleRefresh && handleRefresh();
              }}
              style={{
                height: 22.5,
                marginBottom: 2,
                borderWidth: 1,
                width: 23.9,
              }}
              className=" flex justify-center hover:cursor-pointer border-gray-300 rounded"
            >
              {sigData && sigData[0]?.sgnSignature ? (
                <img
                  className=""
                  src={`data:image/jpeg;base64,${sigData[0].sgnSignature}`}
                  alt=""
                />
              ) : (
                <img
                  className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                  src={reset}
                  alt="reset"
                />
              )}
            </span>
          </div>
          <Upload>
            <Button
              style={{ backgroundColor: "rgb(243 244 246)" }}
              className="flex flex-row border-2 items-center dark:bg-fill dark:text-darkModeSkin-base text-xs"
            >
              <img className="mr-0.5" src={profilePicture} />
              Upload
            </Button>
          </Upload>
        </div>

        {/* signature */}
        <div className="">
          <div className="flex flex-row h-28 mb-1">
            <span className="b border-2 flex justify-center mr-1 items-center bg-slate-100 border-slate-300 rounded h-full w-48 dark:bg-fill dark:text-darkModeSkin-base">
              <img className="" src={signature} alt="reset" />
            </span>

            {/* reset icon */}
            <span
              onClick={() => {
                // setData('');
                // handleRefresh && handleRefresh();
              }}
              style={{
                height: 22.5,
                marginBottom: 2,
                borderWidth: 1,
                width: 23.9,
              }}
              className=" flex justify-center hover:cursor-pointer border-gray-300 rounded"
            >
              <img
                className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                src={reset}
                alt="reset"
              />
            </span>
          </div>
          <Upload disabled={true}>
            <Button
              style={{ backgroundColor: "rgb(243 244 246)" }}
              className="flex bg-gray-100 border-2 flex-row items-center dark:bg-fill dark:text-darkModeSkin-base"
            >
              <img className="mr-0.5" src={upload} />
              Upload
            </Button>
          </Upload>
        </div>
      </div>
    </>
  );
};
