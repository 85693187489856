import { useState } from "react"
import {AiFillPrinter} from 'react-icons/ai';
import  { HiRefresh } from 'react-icons/hi'
import 'devextreme/dist/css/dx.light.css';
import { CheckBox } from 'devextreme-react/check-box';
import { Tooltip } from "devextreme-react";
import find from '../../../../../assets/binoculars.png';
import swap from '../../../../../assets/swap.png';
import edit from '../../../../../assets/edit.png';
import newfile from '../../../../../assets/new.png';

import {DatePicker, Input} from 'antd'
import { DeleteTask, TaskSettings, AlertSMS, AppraiseSettings } from "../../../../accessories/custom-toolbars/widgets/customToolbarModals";
import { EntryCustomToolbar } from "../../../../accessories/custom-toolbars/entryCustomtoolbar";
interface props{
    toggler ?: ()=> void
    
}



export const StaffNameToolbar = ({toggler}:props)=>{

    const { Search } = Input;

    const dateFormat = " DD MMM YYYY";
   

    const [switchiconMsg, setSwitchiconMsg] = useState<string>('Personal')
    const [isActive, setIsActive] = useState<boolean>(false)
    const [SettingsisActive, SettingssetIsActive] = useState<boolean>(false)
    const [AlertSMSisActive, AlertSMSsetIsActive] = useState<boolean>(false)
    const [AppraiseisActive, AppraisesetIsActive] = useState<boolean>(false)
    const size:number = 16;

    // const Customswitch = (val:Boolean)=>{
    //     if(val === true){
    //         setSwitchiconMsg('All tasks')
    //     }else {
    //         setSwitchiconMsg('Personal')
    //         setIsActive(!isActive)
            
    //     }
    // }

    const handleChange = (value: string) => {
        console.log(`selected ${value}`);
      };


    return (
        <EntryCustomToolbar toggler={toggler} withDates={false} />
    )
};

const data = [
    {
        label: 'Group',
       
      },
      {
        label: 'Staff Name',
       
      },
      {
        label: 'Job Title',
       
      },
      {
        label: 'Department',
       
      },
      {
        label: 'Section',
       
      },
      {
        label: 'Unit',
       
      },
      {
        label: 'Location',
       
      },
]

// const data = [
//     { id: 1, text: "Task",  },
//     { id: 2, text: "Progress Activity",  },
//     { id: 3, text: "Task & Start Date",  },
//     { id: 4, text: "Task & Completion Date",  },
//     { id: 5, text: "Task & Due Date", },
//     { id: 6, text: "Progress Activity & Start Date", },
//     { id: 7, text: "Progress Activity & Completion Date",  },
//     { id: 8, text: "Progress Activity & Due Date", },
//     { id: 9, text: "Status", },
//     { id: 10, text: "Status & Start Date", },
//     { id: 11, text: "Status & Completion Date", },
//     { id: 12, text: "Status & Due Date", },
//     { id: 13, text: "Priority", },
//     { id: 14, text: "Priority & Start Date", },
//     { id: 15, text: "Priority & Completion Date", },
//     { id: 16, text: "Priority & Due Date", },
//     { id: 17, text: "Assigned By", },
//     { id: 18, text: "Assigned By & Start Date", },
//     { id: 19, text: "Assigned By & Completion Date", },
//     { id: 20, text: "Assigned By & Due Date", },
//     { id: 21, text: "Assigned To", },
//     { id: 22, text: "Assignred To & Start Date", },
//     { id: 23, text: "Assigned To & Completion Date", },
//     { id: 24, text: "Assigned To & Due Date", },
// ];