/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { CheckboxTemlate } from '../../../../templates/checkbox';
import { Form } from 'antd';
import useResizeMode from '../../../../hooks/useResizeMode';
import { checkboxTypes, selectsTypes } from '../../../human-resource/setups/data/types/selectsTypes';
import { TextareaTemplate } from '../../../../templates/textarea';
import { UpdateButton } from '../../../accessories/buttons';
import { SelectsTemplate } from '../../../../templates/select';
import { GetGenPrivilegesInterface } from '../../../../interfaces';
import useFetch from '../../../../hooks/useFetch';
import { UpdateStates } from '../../../../functions/updateStates';
import { GetAllUsersInterface } from './apa_form';
import { ModalTemplate } from '../../../../templates/modal';
import { useModalData } from '../../../accessories/component_infos';
import { DateTemplate } from '../../../../templates/date';
import dayjs from 'dayjs';
import { PostDataFunc } from '../../../../functions/post';

interface PageProps {

    rowData: GetAllUsersInterface;
}

const Apa_r2_gen = ({ rowData }: PageProps) => {

    const [initial, final] = useResizeMode(window.innerHeight);
    const gridheight = initial - 350;

    const [employeesPriv]: [GetGenPrivilegesInterface[], any, boolean] = useFetch(`SysAdminFormAccessPrivileges/GetGenPrivileges?UserID=${rowData?.empIDpk}`, true);
    const [defaultModules]: [{ apmIDpk: number;apmShtName: string; }[], any, boolean] = useFetch(`SysAdminFormAccessPrivileges/GetGenDefaultModules`, true);
    const [defaultColours]: [{ sclIDpk: number;apmSsclNamehtName: string; }[], any, boolean] = useFetch(`SysAdminFormAccessPrivileges/GetColours`, true);

    const initialState = {
        "usaResetOthersPasswords": false,
        "usaSuperUser": false,
        "usaAllowSendingOfSMS": false,
        "usaSeeExecDashboard": false,
        "usaSeeClassifiedDocs": false,
        "usaSeeAuditTrail": false,
        "usaSeeSalaries": false,
        "usaEditOthersMobNos": false,
        "usaSeeHiddenMobNos": false,
        "usaEditIntercom": false,
        "usaEditEmails": false,
        "usaDefaultModuleIDfk": 0,
        "usaEndDate": "",
        "usaActive": false,
        "usaChangePassword": false,
        "usaSeeAllForms": false,
        "usaShowTaskOnStartup": false,
        "usaShowToDoOnStartup": false,
        "usaShowAdvertOnStartup": false,
        "usaShowTipOfDayOnStartup": false,
        "usaShowRightNavigatorOnStartup": false,
        "usaStartNewsflashOnStartup": false,
        "usaShowNewsflashBarOnStartup": false,
        "usaShowWorkAnniversaryInNewsflash": false,
        "usaShowBirthdayInNewsflash": false,
        "usaShowAlertsWindowOnStartup": false,
        "usaFormHeaderBackColourIDfk": 0,
        "usaFormFooterBackColourIDfk": 0,
        "usaRmks": ""
    }

    const [State, setState] = useState<GetGenPrivilegesInterface>(initialState);

    const [SelectStates, setSelectStates] = useState({
        "usaDefaultModuleIDfk": {id: 0, value: ""},
        "usaFormHeaderBackColourIDfk": {id: 0, value: ""},
        "usaFormFooterBackColourIDfk": {id: 0, value: ""}
    });

    useEffect(() => {

        if (employeesPriv) {
            setState(employeesPriv[0]);
        } else {
            setState(initialState);
        }
    }, [employeesPriv]);



    const [modal, setModal] = useModalData();

    const checkboxes1: checkboxTypes[] = [
        { id: 0, label: "Active Acount?", stateName: "usaActive", defaultValue: State?.usaActive },
        { id: 1, label: "Expire Password?", stateName: "usaChangePassword", defaultValue: State?.usaChangePassword },
        { id: 2, label: "Super User?", stateName: "usaSuperUser", defaultValue: State?.usaSuperUser },
        { id: 3, label: "See Salaries", stateName: "usaSeeSalaries", defaultValue: State?.usaSeeSalaries },
        { id: 4, label: "Edit Others MobNos", stateName: "usaEditOthersMobNos", defaultValue: State?.usaEditOthersMobNos },
        { id: 5, label: "Edit Intercom?", stateName: "usaEditIntercom", defaultValue: State?.usaEditIntercom },
        { id: 6, label: "Edit eMails?", stateName: "usaEditEmails", defaultValue: State?.usaEditEmails },
        { id: 7, label: "See All Forms?", stateName: "usaSeeAllForms", defaultValue: State?.usaSeeAllForms },
    ]

    const selects1: selectsTypes[] = [
        { id: 0, label: "Default Module", idExpr: "apmIDpk", dataExpr: "apmShtName", optionsData: defaultModules, stateName: "usaDefaultModuleIDfk", defaultValue: `${SelectStates?.usaDefaultModuleIDfk?.value}`, style: "" },
        { id: 1, label: "Header Backcolour", idExpr: "sclIDpk", dataExpr: "sclName", optionsData: defaultColours, stateName: "usaFormHeaderBackColourIDfk", defaultValue: `${SelectStates?.usaFormHeaderBackColourIDfk?.value}`, style: "" },
        { id: 2, label: "Footer Backcolour", idExpr: "sclIDpk", dataExpr: "sclName", optionsData: defaultColours, stateName: "usaFormFooterBackColourIDfk", defaultValue: `${SelectStates?.usaFormFooterBackColourIDfk?.value}`, style: "" },
    ]

    const checkboxes2: checkboxTypes[] = [
        { id: 0, label: "Reset Others Pwds?", stateName: "usaResetOthersPasswords", defaultValue: State?.usaResetOthersPasswords },
        { id: 1, label: "See Audit Trail?", stateName: "usaSeeAuditTrail", defaultValue: State?.usaSeeAuditTrail },
        { id: 2, label: "See Hidden MobNos", stateName: "usaSeeHiddenMobNos", defaultValue: State?.usaSeeHiddenMobNos },
        { id: 3, label: "Allow Sending SMS?", stateName: "usaAllowSendingOfSMS", defaultValue: State?.usaAllowSendingOfSMS },
        { id: 4, label: "See Exec.Dashboard", stateName: "usaSeeExecDashboard", defaultValue: State?.usaSeeExecDashboard },
        { id: 5, label: "See Classified Docs?", stateName: "usaSeeClassifiedDocs", defaultValue: State?.usaSeeClassifiedDocs },
    ]

    const checkboxes3: checkboxTypes[] = [
        { id: 0, label: "Show Right Nav On Startup?", stateName: "usaShowRightNavigatorOnStartup", defaultValue: State?.usaShowRightNavigatorOnStartup },
        { id: 1, label: "Show Task Manager On Startup?", stateName: "usaShowTaskOnStartup", defaultValue: State?.usaShowTaskOnStartup },
        { id: 2, label: "Show Todo List On Startup?", stateName: "usaShowToDoOnStartup", defaultValue: State?.usaShowToDoOnStartup },
        { id: 3, label: "Show Advert Form On Startup?", stateName: "usaShowAdvertOnStartup", defaultValue: State?.usaShowAdvertOnStartup },
        { id: 4, label: "Show Alerts Window Startup?", stateName: "usaShowAlertsWindowOnStartup", defaultValue: State?.usaShowAlertsWindowOnStartup },
        { id: 5, label: "Show Tip Of Day On Startup?", stateName: "usaShowTipOfDayOnStartup", defaultValue: State?.usaShowTipOfDayOnStartup },
        { id: 6, label: "Show News Flash On Startup?", stateName: "usaStartNewsflashOnStartup", defaultValue: State?.usaStartNewsflashOnStartup },
        { id: 7, label: "Start News Flash On Startup?", stateName: "usaShowNewsflashBarOnStartup", defaultValue: State?.usaShowNewsflashBarOnStartup },
        { id: 8, label: "Show Work Aniv. On Startup?", stateName: "usaShowWorkAnniversaryInNewsflash", defaultValue: State?.usaShowWorkAnniversaryInNewsflash },
        { id: 9, label: "Show Birthday in News Flash?", stateName: "usaShowBirthdayInNewsflash", defaultValue: State?.usaShowBirthdayInNewsflash },
    ]

    const CreateGenAccessPrivileges = async () => {
        try {
            const res = await PostDataFunc(`SysAdminFormAccessPrivileges/CreateGenPrivileges?EmpID=${rowData?.empIDpk}`, {
                ...State,
                usaDefaultModuleIDfk: SelectStates.usaDefaultModuleIDfk.id,
                usaFormHeaderBackColourIDfk: SelectStates.usaFormHeaderBackColourIDfk.id,
                usaFormFooterBackColourIDfk: SelectStates.usaFormFooterBackColourIDfk.id,
            });

            if (res.status) {
                setModal({
                    state: true,
                    title: "Update Privileges",
                    message: `Privileges updated for ${rowData?.empName}`,
                    icon: "success",
                    okText: "OK",
                    cancelText: "No",
                    disableCancel: true,
                    okHandler: async () => {
                        setModal(prev => ({
                            ...prev,
                            state: false,

                        }))
                    },
                    cancelHandler: () => {
                        setModal(prev => ({
                            ...prev,
                            state: false,

                        }))
                    }
                })
            }
        } catch (e) {
            console.log(e);

        }
    }

    return (
        <div className='w-full h-full'>
            <ModalTemplate
                width={500}
                icon={modal.icon}
                disableCancel={modal.disableCancel}
                cancelText={modal.cancelText}
                open={modal.state}
                okHandler={modal.okHandler}
                cancelHandler={modal.cancelHandler}
                message={modal.message}
                okText={modal.okText}
                title={modal.title}
            />
            <Form
                labelCol={{ span: 12 }}
                // wrapperCol={{span: 10}}
                className='w-full py-2 px-1.5 '
                size='small'
                layout="horizontal"

            >
                <section className='w-full border-[1px] rounded-sm  items-center px-1.5 py-1'>
                    {/* top section */}
                    <section className='w-full flex justify-evenly space-y-1  '>
                        <div className='w-[40%]'>
                            {checkboxes1.map(({ id, label, stateName, defaultValue }) => {
                                return (
                                    <React.Fragment key={id}>
                                        <CheckboxTemlate
                                            useCallbackFunc
                                            span
                                            customDisabled={false}
                                            defaultValue={defaultValue}
                                            withBorders
                                            label={label}
                                            style={{ padding: "5px 0" }}
                                            setCheckboxOnchange={(e) => {
                                                UpdateStates(stateName, e, setState)
                                            }}
                                        />

                                    </React.Fragment>
                                )
                            })}

                            {selects1.map(({ id, label, idExpr, dataExpr, optionsData, stateName, defaultValue, style }) => {
                                return (
                                    <React.Fragment key={id}>
                                        <SelectsTemplate
                                            useCallFunc
                                            // cusWidth={true}
                                            // useCusWidth
                                            // fullWidth
                                            
                                            span={id !== 0 ? true : false}
                                            label={label}
                                            idexpr={idExpr}
                                            dataexp={dataExpr}
                                            options={optionsData}
                                            placeHolder={defaultValue}
                                            selectedValue={(value: string) => {
                                                UpdateStates(stateName, {id: value[idExpr], name: value[dataExpr]}, setSelectStates)
                                            }}
                                            disabled={false}
                                            
                                        />
                                    </React.Fragment>
                                )
                            })}

                            <DateTemplate
                                dbFormat
                                disabledDate={false}
                                disabled={false}
                                label={"End Date"}
                                width={'8rem'}
                                span={true}
                                datePlaceholder='End Date'
                                selectedDate={dayjs(State?.usaEndDate)}
                                changeDate={(e) => {
                                    UpdateStates("usaEndDate", e, setState);
                                }}
                            />

                        </div>

                        <div className='w-[25%]'>
                            {checkboxes2.map(({ id, label, stateName, defaultValue }) => {
                                return (
                                    <React.Fragment key={id}>
                                        <CheckboxTemlate
                                            useCallbackFunc
                                            labelCol={19}
                                            span
                                            customDisabled={false}
                                            defaultValue={defaultValue}
                                            withBorders
                                            label={label}
                                            style={{ padding: "5px  0" }}
                                            setCheckboxOnchange={(e) => {
                                                UpdateStates(stateName, e, setState)
                                            }}
                                        />

                                    </React.Fragment>
                                )
                            })}
                        </div>

                        <div className='w-[35%]'>
                            {checkboxes3.map(({ id, label, stateName, defaultValue }) => {
                                return (
                                    <React.Fragment key={id}>
                                        <CheckboxTemlate
                                            useCallbackFunc
                                            labelCol={21}
                                            span
                                            customDisabled={false}
                                            defaultValue={defaultValue}
                                            withBorders
                                            label={label}
                                            style={{ padding: " 5px  0" }}
                                            setCheckboxOnchange={(e) => {
                                                UpdateStates(stateName, e, setState)
                                            }}
                                        />

                                    </React.Fragment>
                                )
                            })}
                        </div>

                    </section>


                    <TextareaTemplate
                        height={175}
                        useCallbackFunc
                        labelCol={4}
                        MarginLeft={25}
                        label={"Remarks"}
                        placeholder={"Remarks"}
                        disabled={false}
                        setCallbackValue={(e) => {
                            UpdateStates("usaRmks", e, setState)
                        }}

                    />

                    <section className='flex justify-end'>
                        <UpdateButton disableButton={false} disabled_status={false} useCallbackFunc handleUpdate={
                            () => {
                                console.table(State);
                                setModal({
                                    state: true,
                                    title: "Update Privileges",
                                    message: `Are you sure you want to update the Privileges for ${rowData?.empName}`,
                                    icon: "question",
                                    okText: "OK",
                                    cancelText: "No",
                                    disableCancel: false,
                                    okHandler: async () => {
                                        console.log(State)
                                        setModal(prev => ({
                                            ...prev,
                                            state: false,

                                        }))

                                        await CreateGenAccessPrivileges();


                                    },
                                    cancelHandler: () => {
                                        setModal(prev => ({
                                            ...prev,
                                            state: false,

                                        }))
                                    }
                                })
                            }
                        } />
                    </section>

                </section>

                {/* Datagrid */}





            </Form>
        </div>
    )
}

export default Apa_r2_gen