// PERFORMANCE

export const objectives_columns = [
    {id: 1,caption : "STRATEGIC OBJECTIVE", dataField : 'objName', dataType : "string", fixed : true,width:120},
    {id: 2,caption : "MISSION", dataField : 'mvsMission', dataType : "string", fixed : true,width:240},
    {id: 3,caption : "VISION", dataField : 'mvsVision', dataType : "string", fixed : true,width:240},
    {id: 4,caption : "STRATEGIC THEME", dataField : 'thmName', dataType : "string", fixed : true,width:240},
    {id: 5,caption : "PERSPECTIVE", dataField : 'ptvName', dataType : "string", fixed : true,width:140},
    {id: 6,caption : "OWNER", dataField : 'ownerEmpName', dataType : "", fixed : true,width:140},
    {id: 7,caption : "DESCRIPTION", dataField : 'objDescription', dataType : "string", fixed : true,width:180},
    {id: 8,caption : "OUTCOME", dataField : 'objOutcome', dataType : "string", fixed : true,width:120},
    {id: 9,caption : "ORDER", dataField : 'objOrder', dataType : "", fixed : true,width:80},
    {id: 10,caption : "ACTIVE", dataField : 'objActive', dataType : "", fixed : true,width:80},
    {id: 11,caption : "REMARKS", dataField : 'objRmks', dataType : "string", fixed : true,width:4000},
    {id: 12,caption : "ID", dataField : 'objIDpk', dataType : "", fixed : true,width:50,visible:false}
]

export const lock_submission_datagrid_cols = [
    {id: 1,caption : "boolean", dataField : 'boolean', dataType : "boolean", fixed : true,width:60},
    {id: 2,caption : "empID", dataField : 'empID', dataType : "number", fixed : true,width:100},
    {id: 3,caption : "Staff", dataField : 'Staff', dataType : "number", fixed : true,width:100},
    {id: 4,caption : "Name", dataField : 'Name', dataType : "number", fixed : true,width:100},
    {id: 5,caption : "Assignements", dataField : 'Assignements', dataType : "date", fixed : true,width:100},
    {id: 6,caption : "Submitted", dataField : 'Submitted', dataType : "string", fixed : true, width:100},
    {id: 7,caption : "Unsubmitted", dataField : 'Unsubmitted', dataType : "string",width:100},
    {id: 8,caption : "Locked", dataField : 'Locked', dataType : "string",width:100},
    {id: 9,caption : "Job Title", dataField : 'Job Title', dataType : "string",width:100},
    {id: 10,caption : "Dept", dataField : 'Dept', dataType : "string",width:100},
    {id: 11,caption : "Section", dataField : 'Section', dataType : "string",width:100},
    {id: 12,caption : "Location", dataField : 'Location', dataType : "string",width:100},
    {id: 13,caption : "Remarks", dataField : 'Remarks', dataType : "string",width:4000},
]

// FOR PERFORMANCE FOOTER
// FOR FOR UNIT INITIATIVE ASSIGNMENT 

export const unit_initiative_assignement_footer_datagrid_cols = [
    {id: 1,caption : "PERIOD", dataField : 'PERIOD.', dataType : "number", fixed : true,width:150},
    {id: 2,caption : "DEPARTMENT", dataField : 'DEPARTMENT', dataType : "number", fixed : true,width:150},
    {id: 3,caption : "SECTION", dataField : 'SECTION', dataType : "number", fixed : true,width:150},
    {id: 4,caption : "UNIT INITIAVE", dataField : 'UNIT INITIAVE', dataType : "number", fixed : true,width:150},
    {id: 5,caption : "ACTIVE?", dataField : 'ACTIVE?', dataType : "date",format:"dd MMM yyyy",fixed : true,width:150},
    {id: 6,caption : "START DATE", dataField : 'START DATE', dataType : "string",width:150},
    {id: 7,caption : "END DATE", dataField : 'END DATE', dataType : "string",width:150},
    {id: 8,caption : "COOPERATIVE INITIATIVE", dataField : 'COOPERATIVE INITIATIVE', dataType : "string",width:150},
    {id: 9,caption : "OBJECTIVE", dataField : 'OBJECTIVE', dataType : "string",width:150},
    {id: 10,caption : "THEME", dataField : 'THEME', dataType : "string",width:150},
    {id: 11,caption : "MISSION/VISION", dataField : 'MISSION/VISION', dataType : "string",width:150},
    {id: 12,caption : "REMARKS", dataField : 'REMARKS', dataType : "string",width:4000},
    
]



// FOR COOPERATIVE INITIATIVE ASSIGNMENTS

export const footer_datagrid_cia_cols = [
    {id: 1,caption : "PERIOD", dataField : 'PERIOD.', dataType : "number", fixed : true,width:150},
    {id: 2,caption : "BUDGET", dataField : 'BUDGET', dataType : "number", fixed : true,width:150},
    {id: 3,caption : "DEPARTMENT", dataField : 'DEPARTMENT', dataType : "number", fixed : true,width:150},
    {id: 4,caption : "SECTION", dataField : 'SECTION', dataType : "number", fixed : true,width:150},
    {id: 5,caption : "INITIAVE", dataField : 'INITIAVE', dataType : "number", fixed : true,width:150},
    {id: 6,caption : "ACTIVE?", dataField : 'ACTIVE?', dataType : "date",format:"dd MMM yyyy",fixed : true,width:150},
    {id: 7,caption : "PRD START DATE", dataField : 'PRD START DATE', dataType : "string",width:150},
    {id: 8,caption : "PRD END DATE", dataField : 'PRD END DATE', dataType : "string",width:150},
    {id: 9,caption : "OBJECTIVE", dataField : 'OBJECTIVE', dataType : "string",width:150},
    {id: 10,caption : "THEME", dataField : 'THEME', dataType : "string",width:150},
    {id: 11,caption : "MISSION/VISION", dataField : 'MISSION/VISION', dataType : "string",width:150},
    {id: 12,caption : "REMARKS", dataField : 'REMARKS', dataType : "string",width:4000},
    
]

// PERSPECTIVES
// FOR PERSPECTIVE FOOTER DATAGRID
export const perspective_footer_datagrid_cols = [
    {id: 1,caption : "NAME", dataField : 'NAME.', dataType : "string", fixed : true,width:150},
    {id: 1,caption : "SHORT NAME", dataField : 'SHORT NAME.', dataType : "string", fixed : true,width:150},
    {id: 1,caption : "ACTIVITY?", dataField : 'ACTIVITY?.', dataType : "boolean", fixed : true,width:60},
    {id: 1,caption : "FINANCIAL?", dataField : 'FINANCIAL?.', dataType : "boolean", width:100},
    {id: 1,caption : "ORG. CAPACITY", dataField : 'ORG. CAPACITY.', dataType : "boolean", fixed : true,width:80},
    {id: 1,caption : "CUST/STAKEH", dataField : 'CUST/STAKEH.', dataType : "boolean", fixed : true,width:80},
    {id: 1,caption : "BUS/INT.PROC?", dataField : 'BUS/INT.PROC?', dataType : 'boolean', fixed : true,width:80},
    {id: 1,caption : "ORDER", dataField : 'ORDER.', dataType : "number", width:80},
    {id: 1,caption : "QUESTIONS ANSWERED", dataField : 'QUESTIONS ANSWERED.', dataType : "string", fixed : true,width:180},
    
    {id: 1,caption : "Remarks", dataField : 'Remarks.', dataType : "string", width:4000},
]

// FOR MEASURES AND TARGETS

export const measure_target_footer_datagrid_cols = [
    {id: 1,caption : "NAME", dataField : 'NAME.', dataType : "string", fixed : true,width:120},
    {id: 2,caption : "SHORT NAME", dataField : 'SHORT NAME.', dataType : "string", fixed : true,width:120},
    {id: 3,caption : "ACTIVITY?", dataField : 'ACTIVITY?', dataType : "boolean", fixed : true,width:60},
    {id: 4,caption : "REMARKS", dataField : 'REMARKS.', dataType : "string", fixed : true,width:4000},
    
]

// FOR COOPERATE INITIATIVE

export const cooperate_initiatve_footer_datagrid_cols = [
    {id: 1,caption : "INITIATIVE", dataField : 'cinName', dataType : "string", fixed : true,width:120},
    {id: 2,caption : "SHORT NAME", dataField : 'cinShtName', dataType : "string", fixed : true,width:120},
    {id: 3,caption : "ACTIVITY?", dataField : 'cinActive', dataType : "boolean", fixed : true,width:60},
    {id: 4,caption : "PERSPECTIVE", dataField : 'PERSPECTIVE.', dataType : "string", fixed : true,width:120},
    {id: 5,caption : "REMARKS", dataField : 'cinRmks', dataType : "string", fixed : true,width:4000},
    
]



// FOR MEETING SCHEDULES will be changed to something in performance

export const footer_meeting_schedules_datagrid_cols = [
    {id: 1,caption : "ID?", dataField : 'ID?', dataType : "number", fixed : true,width:50},
    {id: 2,caption : "SUB?", dataField : 'SUB?', dataType : "boolean", fixed : true,width:100},
    {id: 3,caption : "ACKN?", dataField : 'ACKN?', dataType : "boolean", fixed : true,width:100},
    {id: 4,caption : "CALENDER", dataField : 'CALENDER', dataType : "number", fixed : true,width:100},
    {id: 5,caption : "DATE", dataField : 'DATE', dataType : "date",format:"dd MMM yyyy", fixed : true,width:100,},
    {id: 6,caption : "GROUP", dataField : 'GROUP', dataType : "string", fixed : true,width:100},
    {id: 7,caption : "RESOURCE PERSON", dataField : 'RESOURCE PERSON', dataType : "string",fixed : true,width:160},
    {id: 8,caption : "ACTIVE?", dataField : 'ACTIVE?', dataType : "boolean",fixed : true,width:60},
    {id: 9,caption : "TOPIC", dataField : 'TOPIC ', dataType : "string",width:100},
    {id: 10,caption : "WHY TOPIC IS CHOSEN", dataField : 'WHY TOPIC IS CHOSEN', dataType : "string",width:200},
    {id: 11,caption : "SUBMITTED BY", dataField : 'SUBMITTED BY', dataType : "string",width:100},
    {id: 12,caption : "REMARKS", dataField : 'REMARKS', dataType : "string", width:4000},
];

// FOR PERSPECTIVE FOOTER DATAGRID
export const objectives_teams_mini_datagrid = [
    {id: 0,caption : "STAFF No", dataField : 'memberStaffNo', dataType : "string", fixed : false,width:100},
    {id: 1,caption : "STAFF NAME", dataField : 'memberEmpName', dataType : "string", fixed : false,width:150},
    {id: 2,caption : "ACTIVE?", dataField : 'otmActive', dataType : "boolean", fixed : false,width:60},
    {id: 3,caption : "START DATE", dataField : 'mvsStartDate', dataType : "string", width:100, fixed:false},
    {id: 4,caption : "END DATE", dataField : 'mvsEndDate', dataType : "string", fixed : false,width:100},
    {id: 5,caption : "OWNER?", dataField : 'otmOwner', dataType : "boolean", fixed : false,width:60},
    {id: 6,caption : "DEPT", dataField : 'memberDptName', dataType : 'string', fixed : false,width:120},
    {id: 7,caption : "SECTION", dataField : 'memberSxnShtName', dataType : "number", width:100,fixed : false,},
    {id: 8,caption : "INTERCOM", dataField : 'memberPlc', dataType : "string", fixed : false,width:100},
    {id: 9,caption : "MISSION", dataField : 'mvsMission', dataType : "string", fixed : false, width:200},
    {id: 10,caption : "THEME", dataField : 'thmName', dataType : "string", fixed : false, width:200},
    {id: 11,caption : "REMARKS", dataField : 'otmRmks', dataType : "string", fixed : false, width:4000},
]

// FOR PERSPECTIVE FOOTER DATAGRID
export const destinations_teams_mini_datagrid = [
    {id: 0,caption : "OBJECTIVE", dataField : 'odsObjectiveIdfk', dataType : "string", fixed : false,width:60,  visible: false},
    {id: 1,caption : "TO", dataField : 'odsTo', dataType : "string", fixed : false,width:150},
    {id: 2,caption : "FROM", dataField : 'odsFrom', dataType : "string", fixed : false,width:150},
    {id: 3,caption : "START DATE", dataField : 'odsStartDate', dataType : "string", width:100, fixed: false},
    {id: 4,caption : "END DATE", dataField : 'odsEndDate', dataType : "string", fixed : false,width:100},
    {id: 5,caption : "MISSION", dataField : 'mvsMission', dataType : "string", fixed : false,width:150},
    {id: 6,caption : "THEME", dataField : 'thmName', dataType : 'string', fixed : false,width:120},
    {id: 7,caption : "ACTIVE", dataField : 'odsActive', dataType : "boolean", width:60,fixed : false,},
    {id: 8,caption : "REMARKS", dataField : 'odsRmks', dataType : "string", fixed : false, width:4000},
]



{/* <Column  dataField="LOCKED?"  fixed={true} width={60} cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataType='boolean'></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="DATE" fixed={true} dataType='date' width={100} format={"dd MMM yyyy"} ></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="%COMPLETE" fixed={true} cellRender={progressBar}></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="TASK UPDATE"  dataType='string' ></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="UPDATED BY" dataType='string' ></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="JOB TITLE" dataType='string'></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="UPDATER" dataType='string' width={100}></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="CREATED ON " dataType='string'></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="COMPLETION DATE" dataType='string'></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="REMARKS" dataType='string' width={4000} ></Column> */}