import React, { useState } from "react";
import { DateTemplate } from "../../../../templates/date";
import {
  checkboxTypes,
  inputsTypes,
  searchTextTypes,
} from "../../../human-resource/setups/data/types/selectsTypes";
import { Form } from "antd";
import { CheckboxTemlate } from "../../../../templates/checkbox";
import { CustomizableButton, SearchButton } from "../../../accessories/buttons";
import message from "../../../../assets/email.png";
import settings from "../../../../assets/settings.png";
import print from "../../../../assets/printer.png";
import Datagrid_template from "../../../../templates/Datagrid";
import { SwitchTemplate } from "../../../../templates/switch";
import { InputsTemplate } from "../../../../templates/input";
import useResizeMode from "../../../../hooks/useResizeMode";
import { sentSMS_cols } from "../data/datagridCols";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import useFetch from "../../../../hooks/useFetch";
import { ModalTemplate } from "../../../../templates/modal";
import { saveModalData, startingDate } from "../../../accessories/component_infos";
import { set } from "lodash";
import dayjs, { Dayjs } from "dayjs";
import { PostDataFunc  } from "../../../../functions/post";
import { useAccountInfo } from "../../../../hooks/useAccountInfo";
import { useFormPriviledge } from "../../../../hooks/useFormPriviledge";

interface stateTypes {
  selectedRecord: any;
  startDate: searchTextTypes;
  endDate: searchTextTypes;
  checkBox: boolean;
  checkBox2: boolean;
  checkBox3: boolean;
  sms: boolean;
  tmsJobs: boolean; 
  tasksInterval: string;
  smsInterval: string;
}

interface props{

}

export const SentSMS = () => {
  const format = 'YYYY-MM-DD';
  const today = dayjs(new Date()).format(format);
  const initialDate = dayjs()
    .subtract(0, "year")
    .startOf("year")
    .format(format);
  const [states, setStates] = useState<stateTypes>({
    selectedRecord: {},
    startDate: {temp: initialDate, text: initialDate},
    endDate: {temp: today, text: today},
    checkBox: false,
    checkBox2: false,
    checkBox3: false,
    sms: false,
    tmsJobs: false, 
    smsInterval: "5000",
    tasksInterval: "1800"
  });


  const setState = (key: string, value: any) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };

  const [initial, final] = useResizeMode(window.innerHeight);
  const gridheight = initial - 310; 

  // Redux
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  const [employeeId, userId, userInfo] = useAccountInfo();
  const [privType, setPrivType] = useState<string>("save");
  const [savePriv, updatePriv] = useFormPriviledge(privType);
  const formCode = useSelector((state: RootState) => state.general.formCode);

  const [sentSms, smsError, smsloading] = useFetch(`SMSLogs/GetAllSendSMS?SlgCreationDate=${states.startDate.text}&SlgEndDate=${states.endDate.text}`); 

  const dates: inputsTypes[] = [
    {
      id: 0,
      label: "Time",
      stateName: "startDate",
      defaultValue: states.startDate.temp,
      style: "",
    },
    {
      id: 1,
      label: "",
      stateName: "endDate",
      defaultValue: states.endDate.temp,
      style: "",
    },
  ];
  const checkboxes: checkboxTypes[] = [
    { id: 0, label: "", stateName: "checkBox", defaultValue: states.checkBox, tooltipMsg: "Show Deleted"},
    {
      id: 1,
      label: "",
      stateName: "checkBox2",
      defaultValue: states.checkBox2,
      tooltipMsg: "Show Sent",
    },
  ];
  const switches: checkboxTypes[] = [
    {
      id: 0,
      label: "SMS-",
      stateName: "sms",
      defaultValue: states.sms,
    },
    {
      id: 1,
      label: "TMS Jobs",
      stateName: "tmsJobs",
      defaultValue: states.tmsJobs,
    },
  ];
  const inputs: inputsTypes[] = [
    {
      id: 0,
      label: "SMS Time Interval (seconds)",
      stateName: "smsInterval",
      defaultValue: states.smsInterval,
      style: "",
      extra: (
        <CustomizableButton
          isText
          text={"Run and Send SMS"}
          classname={"w-[200px] flex items-center mx-2 px-0.5"}
          customizableBtnImage={message}
          customizableBtnImageClassname={"px-1"}
          handleCustomizableClick={() => sendSms()}
        />
      ),
    },
    {
      id: 1,
      label: "Tasks Time Interval (seconds)",
      stateName: "tasksInterval",
      defaultValue: states.tasksInterval,
      style: "",
      extra: (
        <CustomizableButton
          isText
          text={"Run and Send TMS Alerts"}
          classname={"w-[200px] flex items-center mx-2 px-0.5"}
          customizableBtnImage={settings}
          customizableBtnImageClassname={"px-1"}
          handleCustomizableClick={() => sendAlert()}
        />
      ),
    },
  ];

  // const [selectedRecord, setSelectedRecord] = useState({});

  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();

  const [wrap, setWrap] = useState<boolean>(false);

  console.log('Initial', states)

  const checkConnection = () => {
    if (!navigator.onLine) {
      setIcon("warning");
      setOpenModal(true);
      setModalData({
        message:
          "Your computer is not connected to the Internet. Please check your Internet connection and try again",
        title: "Connectivity Error!",
        okText: "Ok",
      });
    }
  };

  let mobNox = ""; 
  const sendSms = async () => {
    try {
      checkConnection();
      if (states.selectedRecord?.empIDpk) {
        mobNox = states.selectedRecord?.empMobNox.trim().replace("", "");
      }
      if (mobNox.trim.length >= 10) {
        mobNox = mobNox.substring(0, 10);
      }

      await PostDataFunc (`SMSAlert/SaveToLogs`, {
        slgMessage: states.selectedRecord?.slgMessage,
        slgMobNo: states.selectedRecord?.empMobNox,
        slgSender: userInfo.empName,
        slgSent: true,
        slgRecordIDfk: states.selectedRecord.vdtIDpk,//wrong ID
        slgFormCode: formCode,
        slgType: "string",
        slgClass: "string",
        slgSmsServiceProvider: "Hubtel",
        slgEmpIDfk: states.selectedRecord.empIDpk,
        slgInitiatorEmpIDfk: userId,
        slgCreatedBy: userId,
      });

      // await PostDataFunc (`AsmTsmEntMaintenanceSchedule/SaveSmsAlert`, {
      //   altFormCode: "asmTransMaintenanceSchedules",
      //   altMessage: message,
      //   altTakeAction: true,
      // });
      setIcon("success");
      setOpenModal(true);
      setModalData({
        message: "SMS Sent Successfully",
        title: "Success",
        okText: "Ok",
      });
    } catch {
      setIcon("warning");
      setOpenModal(true);
      setModalData({
        message: "Failed to send SMS!.. Please contact your administrator",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };
  const sendAlert = async () => {
    try {
      checkConnection();
      if (states.selectedRecord?.empIDpk) {
        mobNox = states.selectedRecord?.empMobNox.trim().replace("", "");
      }
      if (mobNox.trim.length >= 10) {
        mobNox = mobNox.substring(0, 10);
      }
      await PostDataFunc (`AsmTsmEntMaintenanceSchedule/SaveSmsAlert`, {
        altFormCode: formCode,
        altMessage: states.selectedRecord?.slgMessage,
        altTakeAction: true,
      });
      setIcon("success");
      setOpenModal(true);
      setModalData({
        message: "SMS Alert Sent Successfully",
        title: "Success",
        okText: "Ok",
      });
    } catch {
      setIcon("warning");
      setOpenModal(true);
      setModalData({
        message: "Failed to send SMS Alert!.. Please contact your administrator",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };

  return (
    <>
      <ModalTemplate
        icon={icon}
        open={openModal}
        disableCancel={icon === "warning" || icon === "success" ? true : false}
        cancelText={modalData.cancelText}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        okHandler={() => setOpenModal(false)}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
      />
      <div className="w-full -full border-[1px] rounded-sm ">
        <p
          id={"form_header"}
          style={{ borderColor: borderTheme }}
          className="w-full bg-slate-100 pl-2 py-0.5 font-medium"
        >
          {"Sent Messages"}
        </p>

        <Form
          className="w-full rounded py-1 px-2"
          name="basic"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          size="small"
        >
          {/* Top toolbar */}
          <section className="w-full flex  justify-between items-center ">
            <div className="w-[80%] flex space-x-2 items-center pl-3">
              {dates.map(({ id, label, stateName, defaultValue, style }) => {
                return (
                  <>
                    <DateTemplate
                      label={label}
                      selectedDate={dayjs(defaultValue)}
                      changeDate={(e: Dayjs) => {
                        setState(stateName!, {temp: dayjs(e).format(format), text: states[stateName].text});
                      }}
                      dbFormat
                      disabled={false}
                    />
                  </>
                );
              })}

              {checkboxes.map(({ id, label, stateName, defaultValue,  tooltipMsg }) => {
                return (
                  <>
                    <CheckboxTemlate
                      useCallbackFunc
                      withBorders
                      defaultValue={defaultValue}
                      setCheckboxOnchange={(e) => {
                        setState(stateName!, e);
                        if (stateName === "checkBox2") {
                          setWrap(e);
                        }
                      }}
                      customDisabled={false}
                      istooltip={true}
                      tooltipId={label}
                      tooltipMsg={tooltipMsg}
                    />
                  </>
                );
              })}

              <SearchButton handleOnClick={() => {
                setState('startDate', {temp: states.startDate.temp, text: states.startDate.temp});
                setState('endDate', {temp: states.endDate.temp, text: states.endDate.temp});
              }} />
            </div>

            <div className="w-[20%] flex justify-end items-center ">
              <Form
                labelCol={{ span: 10 }}
                className="w-full space-x-2"
                size="small"
                layout="horizontal"
              >
                <CheckboxTemlate
                  useCallbackFunc
                  label={"Clear Deleted"}
                  withBorders
                  defaultValue={states.checkBox3}
                  setCheckboxOnchange={(e) => {
                    setState("checkBox3", e);
                  }}
                  customDisabled={false}
                />
              </Form>

              <CustomizableButton
                customizableBtnImage={print}
                classname={"p-1"}
              />
            </div>
          </section>

          {/* Grid */}

          <section className="">
            <Datagrid_template
              gridheight={gridheight}
              columns={sentSMS_cols}
              data={sentSms}
              disablePaging
              wordWrap={wrap}
              onRowClick={(e) => setState("selectedRecord", e)}
            />
          </section>

          {/* Footer */}

          <section className="w-[55%] flex justify-between">
            {/* switches */}
            <div className="w-1/3 ">
              {switches.map(({ id, label, stateName, defaultValue, }) => {
                return (
                  <Form.Item
                    label={<p className="text-xs">{label}</p>}
                    labelCol={{ span: 7 }}
                    // className="py-0.5 flex"
                  >
                    <div className="flex border-[1px]">
                      <SwitchTemplate
                        defaultValue={defaultValue}
                        customDisabled={false}
                        handleOnchange={(e) => {
                          setState(stateName!, e);
                        }}
                      />

                      {id === 0 ? (
                      <p className="text-xs flex items-center px-1 justify-start">{`${states.sms === true  ? "Sending" : "Stopped"}`}</p>

                      ):(
                        <p className="text-xs flex items-center px-1 justify-start">{`${ states.tmsJobs === true ? "Sending" : "Stopped"}`}</p>

                      )
                    
                    }
                     
                      {/* <p className="text-xs flex items-center px-1 justify-start">{`${states.sms === true || states.tmsJobs === true ? "Sending" : "Stopped"}`}</p> */}
                    </div>
                  </Form.Item>
                );
              })}
            </div>

            {/* inputs */}
            <div className="w-2/3">
              {inputs.map(
                ({ id, label, defaultValue, stateName, style, extra }) => {
                  return (
                    <div className="w-full ">
                      <InputsTemplate
                        numberOnly={true}
                        labelColPan={9}
                        label={label}
                        useCallbackFunc
                        defaultValue={defaultValue}
                        orderOnchange={() => {}}
                        readOnly={false}
                        span
                        inputStyle={{ width: 150 }}
                        additionalWidget={<span>{extra}</span>}
                        disabledStatus={false}
                      />
                    </div>
                  );
                }
              )}
            </div>
          </section>
        </Form>
      </div>
    </>
  );
};
