import { datagridColumnTypes } from "../../../human-resource/setups/data/types/selectsTypes";

export const smsSettings_cols:datagridColumnTypes[]  = [ 
    {id : 0, caption : 'EMP ID',dataField : 'empIDpk',allowEditing:false,dataType : '', width : 200, fixed : true , visible : true},
    {id : 1, caption : 'STAFF NO',dataField : 'empStaffNo',allowEditing:false   ,dataType : 'string', width : 110, fixed : false , visible : true},
    {id : 2, caption : 'NAME',dataField : 'empname1',allowEditing:false,dataType : 'string', width : 110, fixed : false , visible : true},
    {id : 3, caption : '',dataField : 'smsAdd',allowEditing:true,dataType : 'boolean', width : 110, fixed : false , visible : true},
    {id : 4, caption : 'NEW CAP',dataField : 'sscNewCap',allowEditing:true,dataType : 'string', width : 110, fixed : false , visible : true},
    {id : 5, caption : 'REMARKS',dataField : 'sscRmks',allowEditing:true,dataType : 'string', width : 310, fixed : false , visible : true},
    {id : 6, caption : 'OLD CAP',dataField : 'sscCap',allowEditing:false,dataType : '', width : 110, fixed : false , visible : true},
    {id : 7, caption : 'DEPARTMENT',dataField : 'dptShtName',allowEditing:false ,dataType : 'string', width : 110, fixed : false , visible : true},
    {id : 8, caption : 'SECTION',dataField : 'sxnShtName',allowEditing:false ,dataType : 'string', width : 110, fixed : false , visible : true},
    {id : 9, caption : 'JOB TITLE',dataField : 'jbtShtName',allowEditing:false ,dataType : 'string', width : 110, fixed : false , visible : true},
    {id : 10, caption : 'LOCATION',dataField : 'locShtName',allowEditing:false ,dataType : 'string', width : 110, fixed : false , visible : true}, 
] 