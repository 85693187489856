import React from 'react'
import { CheckboxTemlate } from '../../../../templates/checkbox';
import { Form } from 'antd';
import useResizeMode from '../../../../hooks/useResizeMode';
import { checkboxTypes } from '../../../human-resource/setups/data/types/selectsTypes';
import { TextareaTemplate } from '../../../../templates/textarea';
import { UpdateButton } from '../../../accessories/buttons';

const Apa_r2_hr = () => {
    const [initial, final] = useResizeMode(window.innerHeight);
    const gridheight = initial - 350;

    const checkboxes1: checkboxTypes[] = [
        { id: 0, label: "Org. Admin?", stateName: "", defaultValue: false, },
        { id: 1, label: "Dept Admin?", stateName: "", defaultValue: false, },
        { id: 2, label: "Section Admin?", stateName: "", defaultValue: false, },
        { id: 3, label: "Unit Admin?", stateName: "", defaultValue: false, },
        { id: 4, label: "CEO?", stateName: "", defaultValue: false, },
        { id: 4, label: "Approve Org Job Desc?", stateName: "", defaultValue: false, },
        { id: 4, label: "Approve Dept Job Desc?", stateName: "", defaultValue: false, },
        { id: 4, label: "Approve Section Job Desc?", stateName: "", defaultValue: false, },
    ]

    const checkboxes2: checkboxTypes[] = [
        { id: 0, label: "Approve Org. Passport Pics?", stateName: "", defaultValue: false, },
        { id: 1, label: "Approve Dept Passport Pics?", stateName: "", defaultValue: false, },
        { id: 2, label: "Approve Sectioin Passport Pics?", stateName: "", defaultValue: false, },
        
    ]

    


    return (
        <div className='w-full h-full'>
            <Form
                labelCol={{ span: 15}}
                // wrapperCol={{span: 10}}
                className='w-full py-2 px-2 '
                size='small'
                layout="horizontal"

            >
                <section className='w-full border-[1px] rounded-sm  items-center p-2'>
                    {/* top section */}
                    <section className='w-full flex justify-evenly space-y-1  '>
                        <div className='w-1/2'>
                            {checkboxes1.map(({ id, label, stateName, defaultValue }) => {
                                return (
                                    <React.Fragment key={id}>
                                        <CheckboxTemlate
                                            useCallbackFunc
                                            span
                                            customDisabled={false}
                                            withBorders
                                            label={label}
                                            style={{padding: "5px 0"}}
                                        />

                                    </React.Fragment>
                                )
                            })}
                        </div>

                        <div className='w-1/2'>
                            {checkboxes2.map(({ id, label, stateName, defaultValue }) => {
                                return (
                                    <React.Fragment key={id}>
                                        <CheckboxTemlate
                                            useCallbackFunc
                                            span
                                            customDisabled={false}
                                            withBorders
                                            label={label}
                                            style={{padding: "5px  0"}}
                                        />

                                    </React.Fragment>
                                )
                            })}
                        </div>

                        

                    </section>


                    <TextareaTemplate
                        height={175}
                        useCallbackFunc
                        label={""}
                        labelCol={9}

                    />

                    <section className='flex justify-end'>
                        <UpdateButton />
                    </section>

                </section>

                {/* Datagrid */}





            </Form>
        </div>
    )
}

export default Apa_r2_hr