import { Card, DatePicker, Form, Modal, Select } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import reset from "../../../../../assets/reset.png";
import { setGeneralValue } from "../../../../../features/generalSlice";
import { TextareaTemplate } from "../../../../../templates/textarea";
import { SaveButton } from "../../../buttons";

const { RangePicker } = DatePicker;
const dateFormat = " DD MMM YYYY HH: mm";

interface props {
  open: boolean;
  width?: any;
  sendMail?: () => void;
  cancelHandler?: () => void;
  okText: string;
  cancelText?: string;
  cancelButton?: boolean;
  title: string;
  placeHolder?: any;
  selectedValue?: (object: any) => void;
  label?: string;
  datelabel?: string;
  droplabel?: string;
  meslabel?: string;
  options?: any;
  idexpr?: string;
  dataexp?: string;
  useCallFunc?: boolean;
  disabled?: boolean | undefined;
  setCallbackValue?: (value: string) => void;
  textheight?: number | string;
  message?: string;
  workData?: any;
  isWorkShop ?: boolean
  defaultDate ?: any
  disabledTextArea?: boolean
  setDateState?: (date: any) => void;
}

export const SMSAlerts = ({
  title,
  disabled = undefined,
  textheight,
  open,
  cancelHandler,
  sendMail,
  width,
  workData,
  placeHolder,
  selectedValue,
  label,
  datelabel,
  meslabel,
  droplabel,
  message,
  options,
  idexpr,
  dataexp,
  useCallFunc,
  setCallbackValue,
  isWorkShop = true,
  defaultDate,
  disabledTextArea = false,
  setDateState,
}: props) => {
  const dispatch = useDispatch();
  const [data, setData] = useState<string>();
  const [text, setText] = useState<string>();
  const selectedRecord = useSelector(
    (state: RootState) => state.general.selectedFieldData
  );
  const form_disable = useSelector(
    (state: RootState) => state.general.formDisabled
  );

  /*********************************************** */
  
  /*********************************************** */
  const handleSelectOnchange = (selected: any = {}) => {
    
    dispatch(
      setGeneralValue({ expr: "Alertworkshop", value: selected.wsdIDpk })
    );
  };
  const setRemarkValue = (value: any) => {
    
    dispatch(setGeneralValue({ expr: "AlertMessage", value: value }));
  };
  /*********************************************** */
  
  const textareaOnchange = (value: string) => {
    
    setText(value);
    
    useCallFunc ? setCallbackValue!(value) : setRemarkValue(value); 
  };

  useEffect(() => {
    if (useCallFunc) {
      setData(placeHolder);
    } else {
      const expr = dataexp!;
      const idExpr = idexpr!;

      setData(selectedRecord[expr]); 
      handleSelectOnchange(selectedRecord[idExpr]);
    }
  }, [selectedRecord, placeHolder]);
  return (
    <div className="w-full h-full">
      <Modal
        key={1}
        width={width ? width : 600}
        style={{}}
        
        open={open}
        onCancel={cancelHandler}
        centered
        footer={[
          <div className="flex w-full h-full justify-end items-center">
            <SaveButton disableButton={false} handleMail={sendMail} />

          </div>,
        ]}
      >
        <Card size="small" className="w-full h-full  my-5" title={title}>
          <Form
            className=" w-full dark:bg-slate-900 dark:text-darkModeSkin-base rounded"
            name="basic"
            
            
            initialValues={{ remember: true }}
            autoComplete="off"
            size="small"
            layout="horizontal"
            disabled={false}
          >
            <Form.Item
              label={
                <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">{`${
                  datelabel ? datelabel : "Date"
                }`}</p>
              }
              labelCol={{ span: 4 }}
            >
              <div className="flex flex-row justify-between w-[132.5%]">
                <DatePicker
                  onChange={(date, dateString) => {
                    
                    
                    
                    setDateState && setDateState(dateString)
                  }}
                  size="small"
                  value={dayjs(defaultDate) ?? dayjs()}
                  style={{ width: "30%" }}
                  format={dateFormat}
                  allowClear={false}
                />

                <span className="h-full w-4 ml-1 flex items-center justify-center hover:cursor-pointer hover">
                </span>
              </div>
            </Form.Item>

            {isWorkShop && (
              <Form.Item
                label={
                  <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">{`${
                    droplabel ? droplabel : "Workshop"
                  }`}</p>
                }
                className={" mb-1"}
                labelCol={{ span: 4 }}
                
              >
                <div className="flex flex-row items-center">
                  <Select
                    onChange={(object) => {
                      const selected = JSON.parse(object);
                      setData(selected[dataexp!]); 
                      useCallFunc === true
                        ? selectedValue && selectedValue(selected) 
                        : handleSelectOnchange(selected); 
                    }}
                    value={workData ? workData : data}
                    key={useCallFunc ? (placeHolder ? placeHolder : "") : data}
                    
                    className="w-full mr-0"
                    placeholder="Select an option"
                    allowClear
                    style={{ width: "40%" }}
                  >
                    {options?.map((option: any, index: number) => {
                      return (
                        <Select.Option
                          key={option[idexpr!]}
                          value={JSON.stringify(option)}
                        >
                          {option[dataexp!]}
                        </Select.Option>
                      );
                    })}
                  </Select>

                  <span className="h-full   p-0.5 border-[1px] rounded flex ml-1 hover:cursor-pointer hover:bg-slate-50">
                    <img
                      className="w-4 h-full flex items-center justify-center"
                      src={reset}
                      alt="reset"
                    />
                    {/* <img className='' src= {''} alt="" /> */}
                  </span>
                </div>
              </Form.Item>
            )}

            <Form.Item
              labelCol={{ span: 4 }}
              label={
                <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">{`${
                  meslabel ? meslabel : "Message"
                }`}</p>
              }
            >
              <div className="w-full ">
                
                <TextareaTemplate
                  height={170}
                  readonly={false}
                  useCallbackFunc
                  setCallbackValue={setCallbackValue}
                  defaultValue={message}
                  disabled={disabledTextArea}
                />
              </div>
            </Form.Item>
          </Form>
        </Card>
      </Modal>
    </div>
  );
};
