/* eslint-disable react/jsx-pascal-case */
import { TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/tab-panel";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Assets_setups_maintenanceIC } from "./widgets/maintenanceI&C";
import { Model_IA } from "./widgets/model_IA";
import { HrSetupsCustomToolbar } from "../../../../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import { searchTextTypes } from "../../../../../human-resource/setups/data/types/selectsTypes";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";

export const Assets_setups_Maintenance_I_A_M = forwardRef(({}, ref) => {
  // on page resize height responsiveness
  const [trigger, setTrigger] = useState<boolean>(false);
  useImperativeHandle(ref, () => ({
    test: () => {
      setTrigger(!trigger);
    },
  }));

  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });
  const activeStatus = useSelector(
    (state: RootState) => state.general.activeStatus
  );
  const [status, setstatus] = useState<boolean | string>("");
  const [search, setSearch] = useState<{ temp: number; index: number }>({
    temp: 0,
    index: 0,
  });

  const searchCriteria: { id: number; value: string }[] = [
    { id: 1, value: "Checklist items" },
    { id: 2, value: "Checklist Assignment" },
  ];

  return (
    <div className="w-full">
      <div className="px-2">
        <HrSetupsCustomToolbar
          setSearchParamspathname={""}
          setSearchParams={""}
          searchTextOnchange={(newText) => {
            setSearchText({ temp: newText, text: searchText.text });
          }}
          withDates={false}
          searchCriteria={true}
          searchCriteriaData={searchCriteria}
          searchCriteriaOnchange={(c, i) => {
            setSearch({ temp: c, index: search.index });
          }}
          handleFind={() => {
            setSearchText({ temp: searchText.temp, text: searchText.temp });
            setSearch({ temp: search.temp, index: search.temp });
            setstatus(activeStatus);
            // setRefresh(current => !current)
          }}
        />
      </div>
      <TabPanel className="w-full h-full">
        <Item title={"Maintenance Item Details"}>
          <div className="pt-1">
            <Assets_setups_maintenanceIC
              searchTextx={searchText}
              statusx={status}
            />
          </div>
        </Item>

        <Item title={"Model-Item Assignment"}>
          <div className="w-full h-full">
            <Model_IA searchCriteriax={search} />
          </div>
        </Item>
      </TabPanel>
    </div>
  );
});
