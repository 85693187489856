import React, { useState, useCallback, useRef, forwardRef, useImperativeHandle, useEffect } from 'react'
import { Form, Input, Select, DatePicker, Checkbox } from 'antd';
import { CheckBox } from 'devextreme-react/check-box';

import { Cancel, NewButton, SaveButton, SignOff, UpdateButton } from '../../../../accessories/buttons';
import reset from '../../../../../assets/refresh.png'
import dayjs from 'dayjs';
import axios from 'axios';
import { api_url, saveModalData, updateModalData } from '../../../../accessories/component_infos';
import { ModalTemplate } from '../../../../../templates/modal';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../app/store';
import { task_footer_table_selected, task_footer_update, task_form_disable } from '../../../../../features/Task&Todo/Task/TaskDetails';
import { TextareaTemplate } from '../../../../../templates/textarea';
import { DateTemplate } from '../../../../../templates/date';
import { inputsTypes } from '../../../../human-resource/setups/data/types/selectsTypes';
interface props {

    toggleRefresh: any,

}







export const Theme_Member_Details = forwardRef(({ toggleRefresh }: props, ref: any) => {
    const { Option } = Select;
    const { TextArea } = Input;

    // import { AssignedDept, AssignedLocation, AssignedSelection, AssignedUnit, Batch, Group, PPE_Select, RequestWindow, StaffName } from './PPE_selects';

    const borderTheme = useSelector((state:RootState)=>state.general.cssBorderClass)

    const [mission, setMission] = useState([]);
    const [themeData, setThemesData] = useState([]);
    const [nameData, setNameData] = useState([]);
    const [refreshValue, setRefreshValue] = useState(false);
    const [themeMembersData, setThemeMembersData] = useState<any>({
        date: { startDate: '', endDate: '' },
        mission: { name: '', id: '' },
        theme: { name: '', id: '' },
        name: { name: '', id: '' },
        remarks: '',
        leader: false,
        active: false,
        disabled: true,
        id: '',
    });

    async function fetchMissionVision() {
        try {

            // get data
            const res = await axios.get(`${api_url}/api/PfmMissionVision/GetAllPfmMissionVision`, {
                headers: {
                    'Content-Type': ['application/json', 'text/plain', 'charset=utf-8'],
                    // Accept : ['text/plain','application/json','charset=utf-8'],
                }
            })


            setMission(res.data.data.reverse()); // set data
        } catch (error: any) {
            console.log(error);

        } finally {

        }
    }

    async function fetchThemes() {
        try {

            // get data
            const res = await axios.get(`${api_url}/api/PfmObjectives/GetLuePfmThemes`, {
                headers: {
                    'Content-Type': ['application/json', 'text/plain', 'charset=utf-8'],
                    // Accept : ['text/plain','application/json','charset=utf-8'],
                }
            })

            setThemesData(res.data.reverse()); // set data
        } catch (error: any) {
            console.log(error);

        } finally {

        }
    }

    async function fetchNames() {

        try {

            // get data
            const res = await axios.get(`${api_url}/api/AsmCamEntEquipmentAssignment/GetLueStaffNames`, {
                headers: {
                    'Content-Type': ['application/json', 'text/plain', 'charset=utf-8'],
                    // Accept : ['text/plain','application/json','charset=utf-8'],
                }
            })
            console.log(res.data.reverse());

            setNameData(res.data.reverse()); // set data
        } catch (error: any) {
            console.log(error);

        } finally {

        }
    }


    const options = [];
    for (let i = 1; i < 36; i++) {
        options.push({
            label: i.toString(36) + i,
            value: i.toString(36) + i,
        });
    }

    //Run functions from table
    useImperativeHandle(ref, () => ({

        doubleClick: () => {
            setThemeMembersData({ ...themeMembersData, ['disabled']: false })
            //    setDisableForms(false);
            setUpdatingStatus(true);
            setShowCancel(true);
        },
        singleClick: (selectedRow: any) => {
            console.log('eee');
            console.log(selectedRow.tmbActive);
            console.log(themeMembersData);
            setThemeMembersData(
                {
                    date: { startDate: dayjs(selectedRow.tmbStartDate), endDate: dayjs(selectedRow.tmbEndDate) },
                    mission: { name: selectedRow.mvsMission, id: selectedRow.mvsIdpk },
                    theme: { name: selectedRow.thmName, id: selectedRow.thmIdpk },
                    name: { name: selectedRow.empName, id: selectedRow.empIdpk },
                    remarks: selectedRow.tmbRmks,
                    leader: selectedRow.tmbLeader,
                    active: true,
                    disabled: true,
                    id: '',
                }
            )



            // setDisableForms(true);
            //  setUpdatingStatus(false);
            //  setShowCancel(false);
        }


    }));

    const validateForms = async () => {
        setIcon('warning');
        if (themeMembersData.date.startDate === '') {
            setShowModalDisableButton(true);
            setModalData({ message: 'Please select a start date', title: 'Select Start Date', okText: 'Ok' },)
            setOpenModal(true);

        } else if (themeMembersData.date.endDate === '') {
            setShowModalDisableButton(true);
            setModalData({ message: 'Please select an end date', title: 'Select End Date', okText: 'Ok' },)
            setOpenModal(true);
        } else if (themeMembersData.mission.id === '') {
            setShowModalDisableButton(true);
            setModalData({ message: 'Please select a mission', title: 'Select Mission', okText: 'Ok' },)
            setOpenModal(true);
        } else if (themeMembersData.theme.id === '') {
            setShowModalDisableButton(true);
            setModalData({ message: 'Please select a theme', title: 'Select Theme', okText: 'Ok' },)
            setOpenModal(true);
        }
        else if (themeMembersData.name.id === '') {
            setShowModalDisableButton(true);
            setModalData({ message: 'Please select a name', title: 'Select Name', okText: 'Ok' },)
            setOpenModal(true);
        }
        else {
            setIcon('question');
            setShowModalDisableButton(false);
            setOpenModal(true);
        }
    }

    //Function Calls
    const updateData = async () => {
        console.log('here');

        const del = await axios.put(`${api_url}/api/PfmTheme/UpdatePfmTheme`, {
            // "otmIdpk": 0,
            // "otmObjectiveIdfk": 0,
            // "otmEmpIdfk": themeMembersData.empIdpk,
            // "otmStartDate": themeMembersData.date.startDate,
            // "otmEndDate": themeMembersData.date.endDate,
            // "otmOwner": 1,
            // "otmActive": themeMembersData.active,
            // "otmRmks": themeMembersData.remarks,
            // "otmEditedBy": 1,
            // "otmEditedDate":  `${dayjs().format('YYYY-MM-DDTHH:mm:ss[Z]')}`

            "tmbIdpk": themeMembersData.tmbIdpk,
            "tmbThemeIdfk": themeMembersData.theme.id,
            "tmbEmpIdfk": themeMembersData.empIdpk,
            "tmbStartDate": themeMembersData.date.startDate,
            "tmbEndDate": themeMembersData.date.endDate,
            "tmbLeader": themeMembersData.leader,
            "tmbActive": themeMembersData.active,
            "tmbRmks": themeMembersData.remarks,
            "tmbEditedBy": 1,
            "tmbEditedDate": `${dayjs().format('YYYY-MM-DDTHH:mm:ss[Z]')}`




        }
        )
        //close modal
        setOpenModal(false);
        //Refresh table
        setShowModalDisableButton(true);
        setIcon('success');
        setModalData({ message: 'Record successfully updated', title: 'Record updated', okText: 'Ok' },)
        setOpenModal(true);
        toggleRefresh();


    }



    const dateFormat = " DD MMM YYYY HH: mm:ss";


    useEffect(() => {

        fetchMissionVision();
        fetchThemes();
        fetchNames();

    }, [refreshValue]);

    const [updatingStatus, setUpdatingStatus] = useState(false);

    const [showModalDisableButton, setShowModalDisableButton] = useState(false);

    const [disableForms, setDisableForms] = useState(true);
    const [showCancel, setShowCancel] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [modalData, setModalData] = useState<any>(saveModalData);
    const [icon, setIcon] = useState<'warning' | 'success' | 'question'>();



    const clearFields = async () => {
        setThemeMembersData(
            {
                date: { startDate: '', endDate: '' },
                mission: { name: '', id: '' },
                theme: { name: '', id: '' },
                name: { name: '', id: '' },
                remarks: '',
                leader: false,
                active: true,
                disabled: false,
            }
        )

    }

    function postData() { }


    const { RangePicker } = DatePicker;

    const disablex = useSelector((state: RootState) => state.addTask.taskFormStatus);
    const updateMode = useSelector((state: RootState) => state.addTask.taskFooterUpdate);
    const dispatch = useDispatch();


    useEffect(() => {
        if (!disablex) {

            dispatch(task_footer_table_selected({}))

            //  this destroys the update mode state
            dispatch(task_footer_update(false));
            setShowCancel(true); setDisableForms(false);
            clearFields();


        } else {
            setShowCancel(false); setDisableForms(true);
            dispatch(task_form_disable(true));
            setThemeMembersData({ ...themeMembersData, ['disabled']: true })
            // dispatch(update_route_state(false))
            dispatch(task_footer_update(false));

        }
        if (updateMode) {
            setDisableForms(false);
            setUpdatingStatus(true);
            setShowCancel(true);
        }

    }, [disablex, updateMode])


    const dates: inputsTypes[] = [
        { id: 0, label: '', stateName: 'startDate', defaultValue: themeMembersData.date.startDate, style: '' },
        { id: 1, label: '', stateName: 'endDate', defaultValue: themeMembersData.date.endDate, style: '' },
    ]


    return (

        <div style={{ width: '900px', minWidth: '900px', height: '100%',borderColor:borderTheme }} className="  border-[1px]   dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base  ">
            {/* <h4 className=" w-full  mb-2 bg-slate-100  ">Task Details</h4> */}
            <div style={{ maxHeight: '295px', minHeight: '295px', height: '295px' }} className=' items-start justify-start '   >
                <ModalTemplate icon={icon} cancelText={modalData.cancelText}
                    cancelHandler={() => { setOpenModal(false) }} disableCancel={showModalDisableButton} open={openModal}
                    okHandler={() => { showModalDisableButton === true ? setOpenModal(false) : updatingStatus === true ? updateData() : postData() }}
                    message={modalData.message} okText={modalData.okText} title={modalData.title} />

                <Form
                    className='w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 px-2 '
                    name="basic"
                    // labelCol={{ span: 6}}
                    // wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    size='small'
                    layout='horizontal'

                >
                    {/* <Theme_Members_Date /> */}
                    <Form.Item
                        label={<p className='dark:bg-fill dark:text-darkModeSkin-base text-xs'>{'Date'}</p>}
                        labelCol={{ span: 2 }}
                    >
                        <div className="flex flex-row">
                            {dates.map(({ id, label, stateName, defaultValue, style }) => {
                                return (
                                    <>
                                        <DateTemplate
                                            label={label}
                                            style={{ marginLeft: id == 1 && 10 }}
                                            selectedDate={defaultValue}
                                            changeDate={(e: any) => {
                                                id == 0 && setThemeMembersData( {...themeMembersData, ['date'] : {startDate : dayjs(e)}})
                                                id == 1 && setThemeMembersData( {...themeMembersData, ['date'] : {endDate: dayjs(e)}})

                                            }}
                                            format={"DD MMM YYYY"}
                                        />
                                    </>
                                )
                            })}

                        </div>
                    </Form.Item>
                    {/* <Form.Item
                        label={<p className='dark:bg-fill dark:text-darkModeSkin-base text-xs'>{'Date'}</p>}
                        labelCol={{ span: 2 }}
                    >
                        <div className="flex flex-row justify-between">
                            <RangePicker onChange={(e: any, dateString: any) => {
                                setThemeMembersData({
                                    ...themeMembersData, ['date']: {
                                        startDate: dayjs(dateString[0]),
                                        endDate: dayjs(dateString[1])
                                    }
                                })
                            }}
                                disabled={themeMembersData.disabled} value={[themeMembersData.date.startDate, themeMembersData.date.endDate]} className='' format={dateFormat} />


                            <span className='h-full w-4 ml-1 flex items-center justify-center hover:cursor-pointer hover'>
                                
                            </span>

                        </div>
                    </Form.Item> */}
                    <Form.Item
                        label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{'Mission'}</p>}
                        className={' mb-1'}
                        labelCol={{ span: 2 }}
                    >
                        <div className="flex flex-row items-center">
                            <Select
                                disabled={themeMembersData.disabled}
                                onChange={(option) => {
                                    setThemeMembersData({ ...themeMembersData, ['mission']: { id: JSON.parse(option)['mvsIdpk'], name: JSON.parse(option)['mvsName'] } })
                                    // setSelectedObjective({id: JSON.parse(option)['id'], name: JSON.parse(option)['name']})
                                }}
                                className='w-full mr-0'
                                placeholder=""
                                allowClear
                                value={themeMembersData.mission.name}
                            >
                                {mission.map((option: any, index: number) => {
                                    return (
                                        <option key={index} value={JSON.stringify(option)}> {option['mvsName']}</option>
                                    )
                                })}
                            </Select>

                            <span
                                onClick={() => {
                                    setThemeMembersData({ ...themeMembersData, ['mission']: { id: '', name: '' } })
                                }}
                                style={{ height: 22.5, marginBottom: 2, borderWidth: 1, width: 23.9 }}
                                className='ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded'>
                                <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src={reset} alt="reset" />
                                {/* <img className='' src= {''} alt="" /> */}
                            </span>


                        </div>
                    </Form.Item>

                    <Form.Item
                        label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{'Theme'}</p>}
                        className={' mb-1'}
                        labelCol={{ span: 2 }}
                    >
                        <div className="flex flex-row items-center">
                            <Select
                                disabled={themeMembersData.disabled}
                                onChange={(option) => {
                                    setThemeMembersData({ ...themeMembersData, ['theme']: { id: JSON.parse(option)['id'], name: JSON.parse(option)['name'] } })
                                    // setSelectedObjective({id: JSON.parse(option)['id'], name: JSON.parse(option)['name']})
                                }}
                                className='w-full mr-0'
                                placeholder=""
                                allowClear
                                value={themeMembersData.theme.name}
                            >
                                {themeData.map((option: any, index: number) => {
                                    return (
                                        <option key={index} value={JSON.stringify(option)}> {option['name']}</option>
                                    )
                                })}
                            </Select>

                            <span
                                onClick={() => { setThemeMembersData({ ...themeMembersData, ['theme']: { id: '', name: '' } }) }}
                                style={{ height: 22.5, marginBottom: 2, borderWidth: 1, width: 23.9 }}
                                className='ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded'>
                                <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src={reset} alt="reset" />
                                {/* <img className='' src= {''} alt="" /> */}
                            </span>


                        </div>
                    </Form.Item>
                    <Form.Item
                        label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{'Name'}</p>}
                        className={' mb-1'}
                        labelCol={{ span: 2 }}
                    >
                        <div className="flex flex-row items-center">
                            <Select
                                disabled={themeMembersData.disabled}
                                onChange={(option) => {
                                    console.log('err')
                                    console.log(JSON.parse(option))
                                    setThemeMembersData({ ...themeMembersData, ['name']: { id: JSON.parse(option)['id'], name: JSON.parse(option)['empName'] } })
                                    // setSelectedObjective({id: JSON.parse(option)['id'], name: JSON.parse(option)['name']})
                                }}
                                className='w-full mr-0'
                                placeholder=""
                                allowClear
                                value={themeMembersData.name.name}
                            >
                                {nameData.map((option: any, index: number) => {
                                    return (
                                        <option key={index} value={JSON.stringify(option)}> {option['empName']}</option>
                                    )
                                })}
                            </Select>

                            <span onClick={() => {
                                setThemeMembersData({ ...themeMembersData, ['name']: { id: '', name: '' } })
                            }}
                                style={{ height: 22.5, marginBottom: 2, borderWidth: 1, width: 23.9 }}
                                className='ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded'>
                                <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src={reset} alt="reset" />
                                {/* <img className='' src= {''} alt="" /> */}
                            </span>


                        </div>
                    </Form.Item>
                    {/* <Theme_Members_Select /> */}

                    <TextareaTemplate 
                        height={165} 
                        label={"Remarks"}
                        labelCol={2}
                        defaultValue={themeMembersData.remarks}
                        readonly={disableForms}
                        setCallbackValue={(e:any)=>{setThemeMembersData({ ...themeMembersData, ['remarks']: e })}}
                    />

                    {/* <Form.Item
                        labelCol={{ span: 2 }}

                        label={<p className='dark:bg-fill dark:text-darkModeSkin-base text-xs'>{'Remarks'}</p>}
                        rules={[{ required: false, }]}
                    >
                        <div className="flex flex-row ">
                            <TextArea readOnly={themeMembersData.disabled} value={themeMembersData.remarks} onChange={(e: any) => {
                                setThemeMembersData({ ...themeMembersData, ['remarks']: e })
                            }} style={{ resize: 'none', height: '160px' }} className='w-full ' />

                            <span className='h-full w-4 ml-1 flex items-center justify-center hover:cursor-pointer hover'>
                                {/* <img className='w w-9/12 h-9/12' src= {reset} alt="reset" /> */}
                            {/* </span> */}

                        {/* </div> */}
                    {/* </Form.Item> */} 
                    {/* <Theme_Members_TextArea /> */}


                    {/* <Group />
                                            <StaffName />
                                            <RequestWindow />
                                            <Batch />
                                            <AssignedDept />
                                            <AssignedSelection />
                                            <AssignedUnit />
                                            <AssignedLocation /> */}
                    <Form.Item labelCol={{ span: 2 }} label={<p className='dark:bg-fill dark:text-darkModeSkin-base text-xs'>{'Leader?'}</p>}>
                        <div className='w-full  flex flex-col  '>
                            <div style={{ width: 'calc(900px - 90px)' }} className=' flex  justify-between '>
                                {/* <li className='flex justify-start '><Cancel/></li> */}
                                <li> <Checkbox style={{borderColor:borderTheme}} className='border-[1px] rounded px-1' disabled={themeMembersData.disabled} checked={themeMembersData.leader} onChange={(e) => {
                                    setThemeMembersData({ ...themeMembersData, ['leader']: e.target.checked })
                                }} /></li>
                                <li className=' flex items-center flex-row h-6 '><p className='mr-2 text-xs justify-center '>Active? : </p>  <Checkbox style={{borderColor:borderTheme}} className=' border-[1px] rounded px-1' disabled={themeMembersData.disabled} checked={themeMembersData.active} onChange={(e) => {
                                    setThemeMembersData({ ...themeMembersData, ['active']: e.target.checked })
                                }} /></li>

                                <ul className='flex justify-end  '>
                                    {updatingStatus === true ? <UpdateButton handleUpdate={() => { setModalData(updateModalData); validateForms(); }} /> : <SaveButton handleSave={() => { setModalData(saveModalData); validateForms(); }} />}
                                    {showCancel === true ? <Cancel useCallbackFunc={true} cancel_button_toggler={() => {
                                        setThemeMembersData({ ...themeMembersData, ['disabled']: true })
                                        setShowCancel(false); setDisableForms(true); setUpdatingStatus(false)
                                        dispatch(task_form_disable(true));

                                        // dispatch(update_route_state(false))
                                        dispatch(task_footer_update(false));
                                    }} /> : <NewButton useCallbackFunc={true} new_button_toggler={() => {
                                        setShowCancel(true); setDisableForms(false);
                                        clearFields();
                                    }} />}
                                </ul>
                            </div>
                        </div>
                    </Form.Item>
                </Form>
            </div>



        </div>
    )
})