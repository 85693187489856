/* eslint-disable react/jsx-pascal-case */
import { useEffect, useRef, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import Datagrid_template from "../../../templates/Datagrid";
import {
  navbar_height,
  toolbar_height,
} from "../../accessories/component_infos";
import CustomLoader from "../../accessories/custom-loader";
import { HrSetupsCustomToolbar } from "../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import { UserAdministrationForm } from "./widgets/userAdministrationForm";
import { userAdminMainGridColumns } from "./data/userAdminData";
import { setGeneralValue } from "../../../features/generalSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  modalPropTypes,
  searchTextTypes,
} from "../../human-resource/setups/data/types/selectsTypes";
import { ModalTemplate } from "../../../templates/modal";
import { RootState } from "../../../app/store";
import React from "react";
import { isEmpty } from "lodash";

export const UserAdministration = () => {
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const childRef = useRef<any>();
  const dispatch = useDispatch(); //dispatch
  useEffect(() => {
    //disable Update Mode
    dispatch(setGeneralValue({ expr: "updateMode", value: false }));

    //disable forms
    dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
  }, []);

  //monitor initial render
  const renderCount = useRef<number>(0);

  //navigation
  const setupPageRoute = window.location.pathname; //get current route of page
  const datagridOnlySearchParam = "table-only"; //search param in datagrid mode
  const formModeSearchParam = "form-and-table"; //search param in form mode mode
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [getAllForms, setGetAllForms] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });

  //miscellaneous heights
  const miscHeight = 7;

  // All heights out
  const heights_out = navbar_height + news_flash_height + toolbar_height;

  //varying form height

  // handle interface switch
  const toggle_staff_form = (): void => {
    const view = searchParams.get("view");

    view === null || view === formModeSearchParam
      ? navigate({
          pathname: setupPageRoute,
          search: createSearchParams({
            view: datagridOnlySearchParam,
          }).toString(),
        })
      : navigate({
          pathname: setupPageRoute,
          search: createSearchParams({
            view: formModeSearchParam,
          }).toString(),
        });
  };

  //Table Height
  const [tableHeight] = useState(
    window.innerHeight - (heights_out + miscHeight - 4)
  ); //height without navbar and toolbar

  const [refresh, setrefresh] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState({});
  const [busyLoader, setBusyLoader] = useState<string>("");

  const [alert, setAlert] = useState<modalPropTypes>({
    state: false,
    title: "",
    message: "",
  });

  //Main grid data
  const [data, error, loading] = useFetch(
    `AccountManagement/GetUsers?search=${searchText.text}`,
    refresh,
    "usaIDpk"
  );
  useEffect(() => {
    if (data) {
      renderCount.current = 10;
    } else if (error) {
      setAlert({
        state: true,
        title: "Server Error",
        message:
          "Error fetching data from server. Please retry or contact system administrator",
      });
    }
  }, [data, error]);

  return (
    <>
      {/* busy loader */}
      {busyLoader !== "" && <CustomLoader text={busyLoader} />}

      {/* modal */}
      <ModalTemplate
        title={alert.title}
        message={alert.message}
        disableCancel={true}
        open={alert.state}
        cancelHandler={() => setAlert({ state: false, title: "", message: "" })}
        okHandler={() => setAlert({ state: false, title: "", message: "" })}
      />

      {/* form loading state */}
      {loading && !renderCount.current ? (
        <CustomLoader />
      ) : (
        <div className="h-full">
          <div className="px-2">
            <HrSetupsCustomToolbar
              checkBoxAfterHelp
              checkBoxAfterHelpOnchange={(value) => setGetAllForms(value)}
              outerSelected={selectedData}
              searchTextOnchange={(searchValue) =>
                setSearchText((prev) => ({
                  temp: searchValue,
                  text: prev.text,
                }))
              }
              handleFind={() => {
                setSearchText((prev) => ({
                  temp: prev.temp,
                  text: prev.temp,
                }));
              }}
              handleNew={() => {
                setSelectedData({});

                //disable forms
                dispatch(
                  setGeneralValue({ expr: "formDisabled", value: false })
                );
              }}
              withDates={false}
              toggler={toggle_staff_form}
            />
          </div>

          {/* form section */}
          <main className=" flex flex-row-reverse">
            {searchParams.get("view") !== datagridOnlySearchParam && (
              <section
                style={{ height: tableHeight, width: 875 }}
                className=" form border-b-4"
              >
                <UserAdministrationForm
                  ref={childRef}
                  selectedRowIndex={selectedRowIndex}
                  loadAllForms={getAllForms}
                  currentAccounts={!isEmpty(data) ? data : []}
                  selectedField={selectedData}
                  clearSelected={() => setSelectedData({})}
                  busyloader={(text) => setBusyLoader(text ?? "")}
                  refreshGrid={() => setrefresh((prev) => !prev)}
                />
              </section>
            )}

            {/* datagrid section */}
            <section
              style={
                searchParams.get("view") === formModeSearchParam ||
                searchParams.get("view") === null
                  ? { width: "calc(100% - 875px)" }
                  : { width: "100%" }
              }
              className=" px-2 "
            >
              <div className="border-2 rounded-b border-slate-100">
                {/* FORM HEADER */}
                <p className="px-2 w-full py-1  font-semibold bg-slate-100">
                  {"Current Accounts"}
                </p>

                <div className=" px-1">
                  <Datagrid_template
                    disableSearch
                    selectedRowIndex={(index) => setSelectedRowIndex(index)}
                    disableGroupPanel
                    onRowClick={(selected) => {
                      setSelectedData(selected);

                      //disable Update Mode
                      dispatch(
                        setGeneralValue({ expr: "updateMode", value: false })
                      );

                      //disable forms
                      dispatch(
                        setGeneralValue({ expr: "formDisabled", value: true })
                      );
                    }}
                    rowDoubleClicked={() => {
                      //disable Update Mode
                      dispatch(
                        setGeneralValue({ expr: "updateMode", value: true })
                      );

                      //disable forms
                      dispatch(
                        setGeneralValue({ expr: "formDisabled", value: false })
                      );
                    }}
                    gridheight={tableHeight - 81.5}
                    data={!isEmpty(data) ? data : []}
                    columns={userAdminMainGridColumns}
                  />
                </div>
              </div>

              <p
                onClick={() => childRef.current.handleClick()}
                className=" hover:bg-slate-100 cursor-pointer border-2 mt-1 text-center text-violet-900 py-1 font-semibold border-slate-200 rounded"
              >
                Reset Password with SMS
              </p>
            </section>
          </main>
        </div>
      )}
    </>
  );
};

export default UserAdministration;
