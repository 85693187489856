

export const vehicle_assignment_TH = [
  {
    id: 0,
    caption: "REG. No",
    dataField: "nomenclature",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 1,
    caption: "START DATE",
    dataField: "shortName",
    alignment: "left",
    fixed: true,
    width: null
  },
  {
    id: 2,
    caption: "END DATE",
    dataField: "station",
    alignment: "left",
    fixed: true,
    width: null
  },
  {
    id: 3,
    caption: "DEPT",
    dataField: "active",
    alignment: "left",
    fixed: true,
    width: null
  },
  {
    id: 4,
    caption: "SECTION",
    dataField: "startDate",
    alignment: "left",
    fixed: true,
    width: null


  },
  {
    id: 5,
    caption: "CLASS",
    dataField: "endDate",
    alignment: "left",
    fixed: true,
    width: null

  },
  {
    id: 27,
    caption: "MAKE",
    dataField: "remarks",
    alignment: "left",
    fixed: false,
    width: null

  },
  
  {
    id: 6,
    caption: "MODEL",
    dataField: "qtyLeft",
    alignment: "left",
    fixed: false,
    width: null

  },
  
  
  {
    id: 7,
    caption: "ACTIVE?",
    dataField: "active",
    alignment: "left",
    fixed: false,
    width: 60

  },
  {
    id: 8,
    caption: "FIT?",
    dataField: "signed",
    alignment: "left",
    fixed: false,
    width: 60

  },
  {
    id: 9,
    caption: "DEL?",
    dataField: "requestWindow",
    alignment: "left",
    fixed: false,
    width: 60

  },



  {
    id: 10,
    caption: "VEH. STATUS",
    dataField: "batchNo",
    alignment: "left",
    fixed: false,
    width: null

  },

  {
    id: 11,
    caption: "WORKSHOP",
    dataField: "isStaffNo",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 12,
    caption: "DRIVER",
    dataField: "issuedBy",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 13,
    caption: "APPROVING OFFICER",
    dataField: "recStaffNo",
    alignment: "left",
    fixed: false,
    width: null


  },

  {
    id: 14,
    caption: "ADMINISTRATOR",
    dataField: "receivedBy",
    alignment: "left",
    fixed: false,
    width: null


  },
  {
    id: 15,
    caption: "VEHICLE CONTROLLER",
    dataField: "assignedLoc",
    alignment: "left",
    fixed: false,
    width: null


  },
  {
    id: 16,
    caption: "MANAGER",
    dataField: "manager",
    alignment: "left",
    fixed: false,
    width: null


  },
  {
    id: 17,
    caption: "DIRECTOR",
    dataField: "director",
    alignment: "left",
    fixed: false,
    width: null


  },
  {
    id: 18,
    caption: "TRANS. UNIT",
    dataField: "tr",
    alignment: "left",
    fixed: false,
    width: null


  },
  {
    id: 19,
    caption: "LOC.",
    dataField: "remarks",
    alignment: "left",
    fixed: false,
    width: null


  },
  {
    id: 20,
    caption: "UNIT",
    dataField: "remarks",
    alignment: "left",
    fixed: false,
    width: null


  },
  {
    id: 21,
    caption: "CHASSIS No",
    dataField: "remarks",
    alignment: "left",
    fixed: false,
    width: null


  },
  {
    id: 22,
    caption: "MANUFACTURER",
    dataField: "remarks",
    alignment: "left",
    fixed: false,
    width: null


  },
  {
    id: 23,
    caption: "MANUF. DATE",
    dataField: "remarks",
    alignment: "left",
    fixed: false,
    width: null


  },
  {
    id: 24,
    caption: "SUPPPLY DATE",
    dataField: "remarks",
    alignment: "left",
    fixed: false,
    width: null


  },
  {
    id: 25,
    caption: "ENGINE TYPE",
    dataField: "remarks",
    alignment: "left",
    fixed: false,
    width: null


  },
  {
    id: 26,
    caption: "REMARKS",
    dataField: "remarks",
    alignment: "left",
    fixed: false,
    width: 4000


  }
  


]


export const administrator_TH = [
  {
     id: 0,
    caption: "Staff No",
    dataField: "empStaffNo",
    alignment: "left",
    fixed: false,
    width: 60
  },
  {
       id: 1,
    caption: "Staff Name",
    dataField: "adminEmpName",
    alignment: "left",
    fixed: false,
    width: 140
  },
  {
        id: 2,
    caption: "DEPT",
    dataField: "dptShtName",
    alignment: "left",
    fixed: false,
    width: 100

  }, 
  {
  id: 3,
    caption: "SECTION",
    dataField: "sxnShtName",
    alignment: "left",
    fixed: false,
    width: 100
  },{id: 4,
    caption: "LOCATION",
    dataField: "locShtName",
    alignment: "left",
    fixed: false,
    width: 100
  },{id: 5,
    caption: "JOB TITLE",
    dataField: "jbtShtName",
    alignment: "left",
    fixed: false,
    width: 100
  },{id: 6,
    caption: "ID",
    dataField: "adminEmpIDpk",
    alignment: "left",
    fixed: false,
    width: 100,
    visible: false
  }
]

export const manager_TH = [
  {
     id: 0,
    caption: "Staff No",
    dataField: "empStaffNo",
    alignment: "left",
    fixed: false,
    width: 60
  },
  {
       id: 1,
    caption: "Staff Name",
    dataField: "mgrEmpName",
    alignment: "left",
    fixed: false,
    width: 140
  },
  {
        id: 2,
    caption: "DEPT",
    dataField: "dptShtName",
    alignment: "left",
    fixed: false,
    width: 100

  }, 
  {
  id: 3,
    caption: "SECTION",
    dataField: "sxnShtName",
    alignment: "left",
    fixed: false,
    width: 100
  },{id: 4,
    caption: "LOCATION",
    dataField: "locShtName",
    alignment: "left",
    fixed: false,
    width: 100
  },{id: 5,
    caption: "JOB TITLE",
    dataField: "jbtShtName",
    alignment: "left",
    fixed: false,
    width: 100
  },{id: 6,
    caption: "ID",
    dataField: "mgrEmpIDfk",
    alignment: "left",
    fixed: false,
    width: 100,
    visible:false,
  }
]

export const director_TH = [
  {
     id: 0,
    caption: "Staff No",
    dataField: "empStaffNo",
    alignment: "left",
    fixed: false,
    width: 60
  },
  {
       id: 1,
    caption: "Staff Name",
    dataField: "dirEmpName",
    alignment: "left",
    fixed: false,
    width: 140
  },
  {
        id: 2,
    caption: "DEPT",
    dataField: "dptShtName",
    alignment: "left",
    fixed: false,
    width: 100

  }, 
  {
  id: 3,
    caption: "SECTION",
    dataField: "sxnShtName",
    alignment: "left",
    fixed: false,
    width: 100
  },{id: 4,
    caption: "LOCATION",
    dataField: "locShtName",
    alignment: "left",
    fixed: false,
    width: 100
  },{id: 5,
    caption: "JOB TITLE",
    dataField: "jbtShtName",
    alignment: "left",
    fixed: false,
    width: 100
  },
  {id: 6,
    caption: "ID",
    dataField: "dirEmpIDfk",
    alignment: "left",
    fixed: false,
    width: 100,
    visible:false,
  }
]

export const approvingOfficer_TH = [
  {
     id: 0,
    caption: "Staff No",
    dataField: "empStaffNo",
    alignment: "left",
    fixed: false,
    width: 60
  },
  {
       id: 1,
    caption: "Staff Name",
    dataField: "ownerEmpName",
    alignment: "left",
    fixed: false,
    width: 140
  },
  {
        id: 2,
    caption: "DEPT",
    dataField: "dptShtName",
    alignment: "left",
    fixed: false,
    width: 100

  }, 
  {
  id: 3,
    caption: "SECTION",
    dataField: "sxnShtName",
    alignment: "left",
    fixed: false,
    width: 100
  },{id: 4,
    caption: "LOCATION",
    dataField: "locShtName",
    alignment: "left",
    fixed: false,
    width: 100
  },{id: 5,
    caption: "JOB TITLE",
    dataField: "jbtShtName",
    alignment: "left",
    fixed: false,
    width: 100
  },
  {id: 6,
    caption: "ID",
    dataField: "ownerEmpIDfk",
    alignment: "left",
    fixed: false,
    width: 100,
    visible:false,
  }
]

export const workShop_TH = [
  {
     id: 0,
    caption: "Work Shop",
    dataField: "wsdName",
    alignment: "left",
    fixed: false,
    width: 140
  },
  {
       id: 1,
    caption: "Town",
    dataField: "twnName",
    alignment: "left",
    fixed: false,
    width: 120
  },
  {
        id: 2,
    caption: "Area",
    dataField: "areName",
    alignment: "left",
    fixed: false,
    width: 100

  }, 
  {
  id: 3,
    caption: "Location",
    dataField: "locName",
    alignment: "left",
    fixed: false,
    width: 100
  },{id: 4,
    caption: "Contacts",
    dataField: "wsdContact",
    alignment: "left",
    fixed: false,
    width: 100
  },{id: 5,
    caption: "INTERCOM/Tel Nos",
    dataField: "wsdTelNo",
    alignment: "left",
    fixed: false,
    width: 100
  },
  {id: 6,
    caption: "Ext?",
    dataField: "wsdExternal",
    alignment: "left",
    fixed: false,
    width: 100
  },


  {id: 7,
    caption: "ID",
    dataField: "wsdIdpk",
    alignment: "left",
    fixed: false,
    width: 100,
    visible:false,
  }
]


export const driver_data_TH = [

  {
     id: 0,
    caption: "Staff No",
    dataField: "empStaffNo",
    alignment: "left",
    fixed: false,
    width: 140
  },
  {
       id: 1,
    caption: "Name",
    dataField: "driverEmpName",
    alignment: "left",
    fixed: false,
    width: 120
  },
  {
        id: 2,
    caption: "Dept",
    dataField: "dptShtName",
    alignment: "left",
    fixed: false,
    width: 100

  }, 
  {
  id: 3,
    caption: "Section",
    dataField: "sxnShtName",
    alignment: "left",
    fixed: false,
    width: 100
  },{id: 4,
    caption: "Job Title",
    dataField: "jbtShtName",
    alignment: "left",
    fixed: false,
    width: 100
  },{id: 5,
    caption: "Location",
    dataField: "locShtName",
    alignment: "left",
    fixed: false,
    width: 100
  },
  {id: 6,
    caption: "ID",
    dataField: "driverEmpIDfk",
    alignment: "left",
    fixed: false,
    width: 100,
    visible:false,
  }
]


export const vehicleController_TH=[
   {
     id: 0,
    caption: "Staff No",
    dataField: "empStaffNo",
    alignment: "left",
    fixed: false,
    width: 140
  },
  {
       id: 1,
    caption: "Name",
    dataField: "controllerEmpName",
    alignment: "left",
    fixed: false,
    width: 120
  },
  {
        id: 2,
    caption: "Dept",
    dataField: "dptShtName",
    alignment: "left",
    fixed: false,
    width: 100

  }, 
  {
  id: 3,
    caption: "Section",
    dataField: "sxnShtName",
    alignment: "left",
    fixed: false,
    width: 100
  },{id: 4,
    caption: "Job Title",
    dataField: "jbtShtName",
    alignment: "left",
    fixed: false,
    width: 100
  },{id: 5,
    caption: "Location",
    dataField: "locShtName",
    alignment: "left",
    fixed: false,
    width: 100
  },
  {id: 6,
    caption: "ID",
    dataField: "controllerEmpIDfk",
    alignment: "left",
    fixed: false,
    width: 100,
    visible:false,
  }
]

 export const vehicleAssignmentFooter_TH = [


  {
     id: 0,
    caption: "REG. No",
    dataField: "vdtRegNo",
    alignment: "left",
    fixed: true,
   
  },
  {
       id: 1,
    caption: "START DATE",
    dataField: "vasStartDate",
    alignment: "left",
    dataType : 'datetime',
    fixed: true,
    width: 120, 
    format: 'dd MMM yyyy',
  },
  {
        id: 2,
    caption: "END DATE",
    dataField: "vasEndDate",
    alignment: "left",
    fixed: true,
    dataType : 'datetime',
    width: 100,
    format: 'dd MMM yyyy',

  }, 
  {
  id: 3,
    caption: "DEPT",
    dataField: "dptShtName",
    alignment: "left",
    fixed: true,
   
  },{id: 4,
    caption: "SECTION",
    dataField: "sxnShtName",
    alignment: "left",
    fixed: false,
    
  },{id: 5,
    caption: "CLASS",
    dataField: "vhcName",
    alignment: "left",
    fixed: true,
    width: 100
  },
  {id: 6,
    caption: "MAKE",
    dataField: "vmkName",
    alignment: "left",
    fixed: true,
    width: 100,
    visible:false,
  },
  {
    id:7,
    caption: "MODEL",
    dataField: "mdlName",
    alignment: "left",
    fixed: true,
    width: 100,
  }
  ,
  {
    id:8,
    caption: "ACTIVE?",
    dataField: "vasActive",
    alignment: "left",
    fixed: true,
  
  }
  ,
  {
    id:9,
    caption: "FIT?",
    dataField: "vstFitStatus",
    alignment: "left",
    fixed: true,
 
  }
  ,
  {
    id:10,
    caption: "DEL?",
    dataField: "vasDeleted",
    alignment: "left",
    fixed: true,
    
  }
  ,
  {
    id:11,
    caption: "VEH.STATUS",
    dataField: "vstName",
    alignment: "left",
    fixed: true,
    width: 100,
  }
  ,
  {
    id:12,
    caption: "WORKSHOP",
    dataField: "wsdName",
    alignment: "left",
    fixed: false,
    width: 160,
  }
  ,
  {
    id:13,
    caption: "DRIVER",
    dataField: "driverEmpName15",
    alignment: "left",
    fixed: false,
    width: 140,
  }
  ,
  {
    id:14,
    caption: "APPROVING OFFICER",
    dataField: "ownerEmpName",
    alignment: "left",
    fixed: false,
    width: 140,
  }
  ,
  {
    id:15,
    caption: "ADMINISTRATOR",
    dataField: "adminEmpName15",
    alignment: "left",
    fixed: false,
    width: 140,
  }
  ,
  {
    id:16,
    caption: "VEHICLE CONTROLLER",
    dataField: "controllerEmpName15",
    alignment: "left",
    fixed: false,
    width: 140,
  }
  ,
  {
    id:17,
    caption: "MANAGER",
    dataField: "mgrEmpName15",
    alignment: "left",
    fixed: false,
    width: 140,
  }
  ,
  {
    id:18,
    caption: "DIRECTOR",
    dataField: "dirEmpName15",
    alignment: "left",
    fixed: false,
    width: 140,
  }
  ,
  {
    id:19,
    caption: "TRANS. UNIT",
    dataField: "truName",
    alignment: "left",
    fixed: false,
    width: 140,
  }
  ,
  {
    id:20,
    caption: "LOC.",
    dataField: "locShtName",
    alignment: "left",
    fixed: false,
    
  }
  ,
  {
    id:21,
    caption: "UNIT",
    dataField: "untShtName",
    alignment: "left",
    fixed: false,
    
  }
  ,
  {
    id:22,
    caption: "CHASSIS No",
    dataField: "vdtChassisNo",
    alignment: "left",
    fixed: false,
    width: 140,
  }
  ,
  {
    id:23,
    caption: "MANUF. DATE",
    dataField: "vdtManufactureDate",
    alignment: "left",
    dataType : 'datetime',
    fixed: false,
    width: 140,
  }
  ,
  {
    id:24,
    caption: "SUPPLY DATE",
    dataField: "vdtSupplyDate",
    alignment: "left",
    dataType : 'datetime',
    fixed: false,
    width: 140,
  }
  ,
  {
    id:25,
    caption:'MANUFACTURER',
    dataField: 'mftName',
    alignment:'left',
    fixed: false,
    width:140,
  }
  ,
  {
    id:25,
    caption: "ENGINE TYPE",
    dataField: "egtName",
    alignment: "left",
    fixed: false,
    width: 140,
  }
  ,
  {
    id:26,
    caption: "REMARKS",
    dataField: "vasRmks",
    alignment: "left",
    fixed: false,
    width: 4000,
  },
  {
    id:27,
    caption: "ID",
    dataField: "vasIdpk ",
    alignment: "left",
    fixed: false,
    width: 60,
    visible:false
  }
]


export const DataGridOne =[

   {
     id: 0,
    caption: "REG. No",
    dataField: "vdtRegNo",
    alignment: "left",
    fixed: true,
    width: 100
  },
  {
       id: 1,
    caption: "VEHICLE CLASS",
    dataField: "vhcName",
    alignment: "left",
    fixed: true,
    width: 120
  },
  {
        id: 2,
    caption: "MAKE",
    dataField: "vmkName",
    alignment: "left",
    fixed: true,
    width: 100

  }, 
  {
    id:3,
    caption:"MODEL",
    dataField:"mdlName",
    alignment:"left",
    fixed:true,
    width:160

  },
  {
  id: 4,
    caption: "CHASSIS No",
    dataField: "vdtChassisNo",
    alignment: "left",
    fixed: false,
    width: 100
  },
  {id: 5,
    caption: "ACTIVE?",
    dataField: "vdtActive",
    alignment: "left",
    fixed: false,
    width: 100
  },
  {id: 6,
    caption: "STATUS",
    dataField: "vstName",
    alignment: "left",
    fixed: false,
    width: 60
  },
  {id: 7,
    caption: "FIT?",
    dataField: "vstFitStatus",
    alignment: "left",
    fixed: false,
    width: 60,
    visible:false,
  },
  {
    id:8,
    caption: "ASSIGNED?",
    dataField: " vdtAssigned",
    alignment: "left",
    fixed: false,
    width: 60,
  }
  ,
  {
    id:9,
    caption: "MANUFACTURER",
    dataField: "mftName",
    alignment: "left",
    fixed: false,
    width: 100,
  }
  ,
  {
    id:10,
    caption: "SUPPLIER",
    dataField: "splName",
    alignment: "left",
    fixed: false,
    width: 100,
  }
  ,
  {
    id:11,
    caption: "MANUF. DATE",
    dataField: "vdtManufactureDate",
    alignment: "left",
    fixed: false,
    width: 100,
  }
  ,
  {
    id:12,
    caption: "SUPPLY DATE",
    dataField: "vdtSupplyDate",
    alignment: "left",
    fixed: false,
    width: 100,
  }
  ,
  {
    id:13,
    caption: "ENGINE TYPE",
    dataField: "egtName",
    alignment: "left",
    fixed: false,
    width: 160,
  }
  ,
  {
    id:14,
    caption: "REMARKS",
    dataField: "vdtRmks",
    alignment: "left",
    fixed: false,
    width: 4000,
  },
  {
    id:15,
    caption:"ID",
    dataField:"vdtIdpk",
    alignment:"left",
    fixed:false,
    visible:false
  }
  
]
