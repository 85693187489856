import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";
import submit from "../../../../../../assets/submit.png";
import { GetDataFunc } from "../../../../../../functions/get";
import { PatchDataFunc } from "../../../../../../functions/patch";
import { PostDataFunc } from "../../../../../../functions/post";
import { UpdateDataFunc } from "../../../../../../functions/update";
import { useAccountInfo } from "../../../../../../hooks/useAccountInfo";
import { useBusyLoader } from "../../../../../../hooks/useBusyLoader";
import useFetch from "../../../../../../hooks/useFetch";
import useLocalStorage from "../../../../../../hooks/useLocalStorage";
import useModalStore from "../../../../../../hooks/zustand/modalStore";
import { WarningAlert } from "../../../../../accessories/warningAlert";
import { drivingTestExamRules } from "./data/drivingTestData";
import { ExamsInitialScreen } from "./widgets/initialScreen";
import { Questions } from "./widgets/questions";
import { ExamsResult } from "./widgets/result";

interface examsDetails {
  exam: {
    id: number;
    name: string;
    startDate: string;
    endDate: string;
    registrationId: number;
  };
  totalQustions: number;
  duration: number;
  passMark: number;
  employee: string;
}
export interface examsType {
  exqIdpk: number;
  exqQuestion: string;
  exqMultipleCorrectAnswers: boolean;
  answers: {
    exaIdpk: number;
    exaAnswer: string;
    exaNoOfLines: number;
    exaCorrectAnswer: boolean;
  }[];
  examDetails: examsDetails;
  qustionNumber: number;
}

export const AssetsEntries_drivingTests = () => {
  const modal = useModalStore();

  const defaultAnswerValues = [false, false, false, false, false, false];

  const [busyLoader, setBusyLoader] = useBusyLoader();

  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  const [examsTime, setExamsTime] = useLocalStorage<number>(
    0,
    "HJVHBA74GDGVG184797"
  );

  const [employeeId, userId, userModel] = useAccountInfo();

  const [examsInfo, setExamsInfo] = useState<examsDetails>({
    exam: { id: 0, name: "", startDate: "", endDate: "", registrationId: 0 },
    totalQustions: 0,
    duration: 0,
    passMark: 0,
    employee: "",
  });

  const [studentAnswers, setStudentAnswers] = useState<boolean[][]>([]);

  const [gotCorrectAnswer, setGotCorrectAnswer] = useState<boolean[]>([]);

  const [activeAnswer, setActiveAnswer] = useState<boolean[]>([]);

  const [number, SetNumber] = useState(0);

  const [questionHistory, setQuestionHistory] = useState<number[]>([]);

  const [examsStarted, setExamsStarted] = useState<
    boolean | "waiting" | "neutral" | "done"
  >("neutral");

  const [refresh, setRefresh] = useState(false);

  const appendQuestionsIdsToQuestionRoute = useCallback(() => {
    let route = "?examId=0";

    for (let history of questionHistory) {
      route = `${route}&examId=${history}`;
    }

    return route;
  }, [questionHistory]);

  const markQuestion = (userAnswers: boolean[]) => {
    for (let i = 0; i < examBody.answers.length; i++) {
      if (examBody.answers[i]?.exaCorrectAnswer !== userAnswers[i]) {
        if (gotCorrectAnswer[0] !== undefined) {
          const correctTemp = gotCorrectAnswer;
          correctTemp[number] = false;

          setGotCorrectAnswer([...correctTemp]);
          return;
        }

        setGotCorrectAnswer((current) => [...current, false]);
        return;
      }
    }

    if (gotCorrectAnswer[number] !== undefined) {
      const correctTemp = gotCorrectAnswer;
      correctTemp[number] = true;

      setGotCorrectAnswer([...correctTemp]);
      return;
    }

    setGotCorrectAnswer((current) => [...current, true]);
  };

  const [examCheckData, examCheckError, examCheckIsLoading] = useFetch(
    `DrivingTest/GetsStaffExaminations?employeeId=${employeeId}`,
    refresh
  );

  useEffect(() => {
    const initiateExamsProcess = () => {
      if (
        !examCheckError &&
        (!examCheckData ||
          examCheckData?.length < 1 ||
          !examCheckData[0]?.ergIDpk)
      ) {
        setExamsStarted("neutral");
        modal.warn(
          "No Active Exams Assigned",
          "You do not have an active Exams Assignment currently"
        );
        return;
      }

      const examInfoData: examsDetails = {
        exam: {
          id: examCheckData[0]?.exnIDpk,
          name: examCheckData[0]?.exnName,
          startDate: examCheckData[0]?.ergStartDate,
          endDate: examCheckData[0]?.ergEndDate,
          registrationId: examCheckData[0]?.ergIDpk,
        },
        totalQustions: examCheckData[0]?.exnNoOfQuestions,
        duration: examCheckData[0]?.ergDuration ?? 10,
        passMark: examCheckData[0]?.ergPassMark,
        employee: examCheckData[0]?.empname1,
      };
      setExamsInfo(examInfoData);

      setExamBody({
        exqIdpk: 0,
        exqQuestion: "",
        exqMultipleCorrectAnswers: false,
        answers: [],
        qustionNumber: 0,
        examDetails: examInfoData,
      });

      modal.confirm(
        "Start Exam?",
        `You have ${
          examCheckData[0]?.exnName ?? `an exam`
        } to take. Do you want to start now?`,
        () => {
          setExamsStarted("waiting");

          modal.disableModal();
        },
        "Yes, Start"
      );
    };

    !examCheckIsLoading && initiateExamsProcess();

    examCheckIsLoading ? setBusyLoader("Hold On...") : setBusyLoader("");
  }, [examCheckData, examCheckIsLoading]);

  const [examBody, setExamBody] = useState<examsType>({
    exqIdpk: 0,
    exqQuestion: "",
    exqMultipleCorrectAnswers: false,
    answers: [],
    qustionNumber: 0,
    examDetails: examsInfo,
  });

  const getAnswers = async (questionId: number) => {
    try {
      const answersResponse = await GetDataFunc(
        `DrivingTest/GetQuestionAnswers?questionId=${questionId}`
      );

      return answersResponse;
    } catch (error) {}
  };

  const getQuestionAndAnswers = async () => {
    setBusyLoader("...");

    try {
      const questionResponse = await GetDataFunc(
        `DrivingTest/GetExamQuestion/${appendQuestionsIdsToQuestionRoute()}`
      );

      const submitResponse = await PatchDataFunc(
        `DrivingTest/UpdateWrittenOn/${examsInfo.exam.registrationId}`,
        {}
      );

      if (questionResponse.data && questionResponse.data[0]?.exqIdpk) {
        const answers = await getAnswers(questionResponse.data[0]?.exqIdpk);

        setQuestionHistory((current) => [
          ...current,
          questionResponse.data[0]?.exqIdpk,
        ]);

        answers &&
          setExamBody((current) => ({
            ...current,
            ...questionResponse.data[0],
            answers: [...answers?.data],
            qustionNumber: number,
          }));
        return;
      }

      modal.warn(
        "No Question",
        "No Question is currently available for the exam"
      );
    } catch (error) {
    } finally {
      setBusyLoader("");
    }
  };

  const getExamQuestionWithQuestionId = async (questionId: number) => {
    try {
      setBusyLoader("Getting Question...");

      const questionResponse = await GetDataFunc(
        `DrivingTest/GetQuestionWithQuestionId/${questionId}`
      );
      return questionResponse;
    } catch (error) {
    } finally {
      setBusyLoader("");
    }
  };

  const nextQuestionHandler = async (index: number) => {
    try {
      if (!studentAnswers[index]) {
        setStudentAnswers((current) => [...current, [...defaultAnswerValues]]);
        setGotCorrectAnswer((current) => [...current, false]);
      }

      studentAnswers[index] && markQuestion(studentAnswers[index]);

      if (index >= examsInfo.totalQustions - 1) {
        return;
      }

      if (questionHistory[index + 1]) {
        const questions = await getExamQuestionWithQuestionId(
          questionHistory[index + 1]
        );

        const answers = await getAnswers(questions?.data?.exqIdpk);

        questions &&
          setExamBody((current) => ({
            ...current,
            ...questions?.data,
            answers: [...answers?.data],
            qustionNumber: index,
          }));

        setActiveAnswer(studentAnswers[index + 1]);

        SetNumber((current) => current + 1);

        return;
      }

      getQuestionAndAnswers();

      setActiveAnswer(defaultAnswerValues);

      SetNumber((current) => current + 1);
    } catch (error) {}
  };

  const previousQuestionHandler = async (index: number) => {
    try {
      if (number <= 0) {
        return;
      }

      SetNumber(index);

      const questions = await getExamQuestionWithQuestionId(
        questionHistory[index]
      );

      const answers = await getAnswers(questions?.data?.exqIdpk);

      setActiveAnswer(studentAnswers[index]);

      questions &&
        setExamBody((current) => ({
          ...current,
          ...questions?.data,
          answers: [...answers?.data],
          qustionNumber: number,
        }));
    } catch (error) {}
  };

  const submitExam = async (
    actionFrom: "userConfirm" | "userSubmit" | "automated"
  ) => {
    try {
      if (actionFrom === "userConfirm") {
        modal.confirm(
          "Submit Exam?",
          "You can't make changes after submitting. Are you sure you want to submit exam?",
          () => {
            submitExam("userSubmit");
            modal.disableModal();
          },
          "Yes, Submit"
        );

        return;
      }
      setBusyLoader("Submitting Exam...");

      for (let i = 0; i < questionHistory.length; i++) {
        const postDataResponse = await PostDataFunc(
          `DrivingTest/SaveNewStudentAnswer`,
          {
            esaEmpIdfk: employeeId,
            esaQuestionIdfk: questionHistory[i],
            esaGotCorrectAnswer: gotCorrectAnswer[i] ?? false,
            esaAnswerA: studentAnswers[i][0] ?? false,
            esaAnswerB: studentAnswers[i][1] ?? false,
            esaAnswerC: studentAnswers[i][2] ?? false,
            esaAnswerD: studentAnswers[i][3] ?? false,
            esaAnswerE: studentAnswers[i][4] ?? false,
            esaQuestionOrder: i + 1,
            esaActive: true,
            formCode: "string",
            formAction: "string",
          }
        );

        const updateAnswerResponse = await UpdateDataFunc(
          `DrivingTest/Update`,
          {
            esaIdpk: postDataResponse.data?.id,
            esaEmpIdfk: employeeId,
            esaQuestionIdfk: questionHistory[i],
            esaExamDetailIdfk: examsInfo.exam.registrationId,
            esaGotCorrectAnswer: gotCorrectAnswer[i],
            esaAnswerA: studentAnswers[i][0] ?? false,
            esaAnswerB: studentAnswers[i][1] ?? false,
            esaAnswerC: studentAnswers[i][2] ?? false,
            esaAnswerD: studentAnswers[i][3] ?? false,
            esaAnswerE: studentAnswers[i][4] ?? false,
            formCode: "string",
            formAction: "string",
          }
        );
      }

      const submittedResponse = await PatchDataFunc(
        `DrivingTest/UpdateSubmittedOn/${examsInfo.exam.registrationId}`,
        {}
      );

      modal.success("Submission Successful", "Exam Submitted Successfully");
      setExamsStarted("done");

      setQuestionHistory([]);
      setActiveAnswer([]);
      setExamsTime(0);
      setGotCorrectAnswer([]);
      setStudentAnswers([]);
      setStudentAnswers([]);
      SetNumber(0);
    } catch (error) {
      modal.warn(
        "Submission Failed",
        "Failed to submit exam. Please retry or contact your Systems Administrator"
      );
    } finally {
      setBusyLoader("");
    }
  };

  return (
    <>
      {/* error getting user exams */}
      {examCheckError && (
        <WarningAlert
          title="Something Went Wrong"
          message="Something went wrong. Please Retry or contact your Systems Administrator"
        />
      )}

      <div className="h-full  px-2">
        {/* user has an active exam and has started */}
        {examsStarted === true ? (
          <>
            <Questions
              answers={activeAnswer ?? defaultAnswerValues}
              questionIndex={number}
              body={examBody}
              submit={submit}
              setExamsTime={setExamsTime}
              submitExam={submitExam}
              examsTime={examsTime}
              examsInfo={examsInfo}
              onNextClick={() => {
                nextQuestionHandler(number);
              }}
              onPreviousClick={() => {
                previousQuestionHandler(number - 1);
              }}
              setUserAnswers={(answers: any) => {
                setActiveAnswer(answers);

                if (studentAnswers[number]) {
                  studentAnswers[number] = answers;
                  setStudentAnswers([...studentAnswers]);

                  return;
                }

                setStudentAnswers((current) => [...current, answers]);
              }}
            />

            <div className=" bg-slate-100 h-full"></div>
          </>
        ) : examsStarted === "waiting" ? (
          <div className=" px-7 py-12 flex justify-center flex-col border-[1px] rounded mt-3">
            <p className="text-3xl font-semibold mb-5">
              {examBody.examDetails.exam.name}
            </p>

            {drivingTestExamRules.map(({ id, rule }) => (
              <p key={id} className=" text-sm text-gray-400 py-[4px]">
                {rule}
              </p>
            ))}

            <p className=" text-base text-gray-400 py-[4px]">
              You have{" "}
              <span className="text-gray-600 font-semibold">
                {examBody.examDetails.duration} minutes
              </span>{" "}
              to complete this assessment.{" "}
              <span className="text-gray-600 font-semibold">
                {examBody.examDetails.totalQustions} Questions
              </span>{" "}
              in total.
            </p>
            <p className=" text-base text-green-600 py-[4px] mb-10">
              Click on{" "}
              <span className=" text-green-700 font-semibold">Start Exam</span>{" "}
              to begin the assessment
            </p>

            {/* Start exams button */}
            <button
              style={{ borderColor: borderTheme }}
              onClick={() => {
                setExamsStarted(true);
                getQuestionAndAnswers();

                setExamsTime(1000 * 60 * examsInfo?.duration ?? 10);
              }}
              className="dark:bg-slate-900 border-[1px] border-green-300 dark:text-darkModeSkin-base w-60 flex flex-row shadow-lg shadow-gray-100 bg-green-50 items-center justify-between text-base h-12 font-semibold  mr-1 px-4  py-2   rounded"
            >
              <p>{`Start Exam`}</p>
              <img alt="" className="pl-2" src={submit} />
            </button>
          </div>
        ) : examsStarted === false ? (
          <div className=" flex h-screen items-center justify-center pb-48">
            <h1>You do not have any Driving Test to write for now...</h1>
          </div>
        ) : examsStarted === "done" ? (
          <>
            <ExamsResult
              onContinue={() => {
                setExamsStarted("neutral");
              }}
              passMark={examsInfo.passMark}
              examTitle={examsInfo.exam.name}
              examsRegistrationId={examsInfo.exam.registrationId}
              totalQuestions={examsInfo.totalQustions}
            />
          </>
        ) : (
          <>
            <ExamsInitialScreen
              onCheck={() => {
                setRefresh((current) => !current);
              }}
            />
          </>
        )}
      </div>
    </>
  );
};
