import { Button, Modal } from "antd";
import question from "../assets/helpothers.png";
import success from "../assets/success.png";
import warning from "../assets/warning.png";
import { TextareaTemplate } from "./textarea";

interface props {
  icon?: "" | "warning" | "success" | "question";
  width?: any;
  icon_width?: any;
  title: string;
  okText?: string;
  cancelText?: string;
  message: string | JSX.Element;
  open: boolean;
  disableCancel?: boolean;
  okHandler: () => void;
  cancelHandler?: () => void;
  withInput?: boolean;
  withInput2?: boolean;
  withTextarea?: boolean;
  withInputType?: string;
  inputOnchange?: (value: string | number) => void;
  textareaOnchange?: (value: string | number) => void;
  textareaHeight?: number;
  textareaWidth?: string;
  textareaMarginLeft?: number | string;
  textareaDefaultValue?: string;

  customComponent?: any;
  isIcon?: boolean;
}
export const ModalTemplate = ({
  title,
  width,
  icon_width,
  icon = "warning",
  withInput2,
  withInputType,
  okText,
  cancelHandler,
  cancelText = "No, Cancel",
  disableCancel,
  okHandler,
  message,
  open = false,
  withInput,
  inputOnchange,
  customComponent,
  isIcon = true,
  withTextarea = false,
  textareaOnchange,
  textareaHeight = 50,
  textareaWidth,
  textareaMarginLeft,
  textareaDefaultValue,
}: props) => {
  return (
    <div className="modal">
      <Modal
        title={title}
        open={open}
        onOk={okHandler}
        okButtonProps={{ style: { backgroundColor: "#0747a1" } }}
        cancelButtonProps={disableCancel ? { style: { display: "none" } } : {}}
        maskClosable={true}
        centered
        width={width ?? 380}
        onCancel={cancelHandler}
        okText={okText ? okText : icon === "question" ? "Yes, Save" : "Ok"}
        style={{
          border: "1px solid rgb(96 165 250)",
          borderRadius: 9,
          zIndex: 100000,
        }}
        transitionName=""
        footer={[
          !customComponent && (
            <Button
              className="bg-blue-800"
              onClick={okHandler}
              key="save"
              type="primary"
            >
              {okText ? okText : icon === "question" ? "Yes, Save" : "Ok"}
            </Button>
          ),
          !customComponent && !disableCancel && (
            <Button onClick={cancelHandler} key="cancel">
              {cancelText}
            </Button>
          ),
          customComponent && "",
        ]}
        cancelText={cancelText}
      >
        {!customComponent ? (
          <>
            <div className="flex flex-row items-center">
              {isIcon ? (
                <span style={{ width: icon_width ?? 50 }} className="pr-3">
                  <img
                    src={
                      icon === "question"
                        ? question
                        : icon === "warning"
                          ? warning
                          : success
                    }
                    alt=""
                  />
                </span>
              ) : (
                <></>
              )}

              {/* <p style={{ width: "calc(100% - 50px)" }}>{message}</p> */}
              <div style={{ whiteSpace: 'pre-wrap', width: "calc(100% - 50px)" }}>{message}</div>

              {withInput && (
                <input
                  className="text-black border-2 border-blue-500 w-full p-0.5 px-1"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    inputOnchange!(e.target.value)
                  }
                  type={withInputType ? withInputType : "text"}
                />
              )}
            </div>
            <div>
              {withInput2 && (
                <input
                  className="text-black  border-2 border-blue-500 w-full p-0.5 px-1"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    inputOnchange!(e.target.value)
                  }
                  type={withInputType ? withInputType : "text"}
                />
              )}
              {withTextarea && (
                <TextareaTemplate
                  disabled={false}
                  readonly={false}
                  useCallbackFunc
                  height={textareaHeight}
                  defaultValue={textareaDefaultValue}
                  width={textareaWidth}
                  MarginLeft={textareaMarginLeft}
                  setCallbackValue={(e) => {
                    textareaOnchange && textareaOnchange(e);
                  }}
                />
              )}
            </div>
          </>
        ) : (
          <>{customComponent}</>
        )}
      </Modal>
    </div>
  );
};
