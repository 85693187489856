

export const transactionUpoad_cols = [
  {id: 1,caption : "REG. NO", dataField : 'sptRegNo', dataType : "string", fixed : true,width:100, visible: true},
  {id: 2,caption : "DATE", dataField : 'sptDate', dataType : "datetime", fixed : true,width:120,visible: true},
  {id: 3,caption : "TIME", dataField : 'sptTime', dataType : "time", fixed : true,width:78,visible: true},
  {id: 4,caption : "MONTH", dataField : 'sptMonth', dataType : "string", fixed : true,width:80,visible: false},
  {id: 5,caption : "CARD No", dataField : 'sptCardNo', dataType : "string", fixed : true,width:80,visible: true},
  {id: 6,caption : "PRODUCT", dataField : 'sptProduct', dataType : "string", fixed : true, width:90,visible: true},
  {id: 7,caption : "DRIVER CODE", dataField : 'sptDriverCode', dataType : "string",width:85,visible: false},
  {id: 8,caption : "PURCHASED BY", dataField : 'driverCodeEmpName', dataType : "string",width:100,visible: true},
  {id: 9,caption : "TANK CAP.", dataField : 'vdtTankCapacity', dataType : "",width:100,visible: true},
  {id: 10,caption : "QTY", dataField : 'sptQuantity', dataType : "",width:70,visible: true},
  {id: 11,caption : "UNIT PRICE", dataField : 'sptUnitPrice', dataType : "",width:80,visible: true},
  {id: 12,caption : "AMOUNT", dataField : 'sptAmount', dataType : "",width:85,visible: true},
  {id: 13,caption : "BALANCE", dataField : 'sptBalance', dataType : "",width:85,visible: true},
  {id: 14,caption : "REFILL INTVL(Hrs)", dataField : 'sptRefillIntervalHours', dataType : "",width:100 ,visible: true},
  {id: 15,caption : "REFILL INTVL(Days)", dataField : 'sptRefillIntervalDays', dataType : "",width:100,visible: true},
  {id: 16,caption : "PLACE", dataField : 'sptPlace', dataType : "string",width:150,visible: true},
  {id: 17,caption : "RECEIPT No", dataField : 'sptReceiptNo', dataType : "",width:85,visible: true},
  {id: 18,caption : "CURRENT MILEAGE", dataField : 'sptCurrentMileage', dataType : "",width:15},
  {id: 19,caption : "PAST MILEAGE", dataField : 'sptPastMileage', dataType : "",width:150,visible: true},
  {id: 20,caption : "MILEAGE", dataField : 'sptMileagex', dataType : "",width:85,visible: true},
  {id: 21,caption : "OPERATION TYPE", dataField : 'sptOperationType', dataType : "string",width:100,visible: true},
  {id: 22,caption : "DRIV. STAFF No", dataField : 'driverCodeStaffNo', dataType : "string",width:100,visible: true},
  {id: 23,caption : "VEHICLE DEPT", dataField : 'vehAssignDeptShtName', dataType : "string",width:120,visible: true},
  {id: 24,caption : "DRIVER DEPT", dataField : 'driverCodeDeptShtName', dataType : "string",width:120,visible: true},
  {id: 25,caption : "CARD NAME", dataField : 'sptCardName', dataType : "string",width:120,visible: true},
  {id: 26,caption : "STATION No", dataField : 'sptStationNo', dataType : "string",width:100,visible: true},
  {id: 27,caption : "CARD TYPE", dataField : 'sptCardType', dataType : "string",width:100,visible: true},
  {id: 28,caption : "PRODUCT CODE", dataField : 'sptProductCode', dataType : "string",width:100,visible: true},
  {id: 29,caption : "CURRENCY No", dataField : 'sptCurrencyNo', dataType : "string",width:85,visible: true},
  {id: 30,caption : "CURRENCY", dataField : 'sptCurrency', dataType : "string",width:85,visible: true},
  {id: 31,caption : "INVOICE No", dataField : 'sptInvoiceNo', dataType : "string",width:150,visible: true},
  {id: 32,caption : "INVOICE DATE", dataField : 'sptInvoiceDate', dataType : "string",width:125,visible: true},
  {id: 33,caption : "CUSTOMER", dataField : 'sptCustomer', dataType : "string",width:150,visible: true},
  {id: 34,caption : "CUST. No", dataField : 'sptCustomerNo', dataType : "string",width:110,visible: true},
  {id: 35,caption : "ACTIVE", dataField : 'sptActive', dataType : "boolean",width:null,visible: true},
  {id: 36,caption : "INVESTIGATIVE COM", dataField : "", dataType : "",width:150,visible: true},
  {id: 37,caption : "REMARKS", dataField : 'sptRmks', dataType : "string",width:4000,visible: true},
]



export const DeptAmountURL_cols =[
  {id: 1,caption : "Dept", dataField : 'deptShtNamex', dataType : "", fixed : true,width:150},
  {id: 2,caption : "Amount", dataField : 'amtx', dataType : "", fixed : true,width:150},
]

export const DeptAmount_CountURL_cols =[
  {id: 1,caption : "Dept", dataField : 'deptShtNamex', dataType : "", fixed : true,width:150},
  {id: 2,caption : "Amount", dataField : 'amtx', dataType : "", fixed : true,width:150},
  {id: 2,caption : "Count", dataField : 'countx', dataType : "", fixed : true,width:150},
]
export const DeptQuantityURL_cols =[
  {id: 1,caption : "Dept", dataField : 'deptShtNamex', dataType : "", fixed : true,width:150},
  {id: 2,caption : "Quantity", dataField : 'qtyx', dataType : "", fixed : true,width:150},
]
export const DeptQuantity_CountURL_cols =[
  {id: 1,caption : "Dept", dataField : 'deptShtNamex', dataType : "", fixed : true,width:150},
  {id: 2,caption : "Quantity", dataField : 'qtyx', dataType : "", fixed : true,width:150},
  {id: 2,caption : "Count", dataField : 'countx', dataType : "", fixed : true,width:150},
]
export const DeptAmountQuantityURL_cols =[
  {id: 1,caption : "DEPT", dataField : 'deptShtNamex', dataType : "", fixed : true,width:150},
  {id: 2,caption : "AMOUNT", dataField : 'amtx', dataType : "", fixed : true,width:150},
  {id: 2,caption : "QUANTITY", dataField : 'qtyx', dataType : "", fixed : true,width:150},
  {id: 2,caption : "COUNT", dataField : 'countx', dataType : "", fixed : true,width:150},
]
export const secAmountURL_cols =[
  {id: 1,caption : "DEPT", dataField : 'deptShtNamex', dataType : "", fixed : true,width:150},
  {id: 2,caption : "SECTION", dataField : 'sxnShtName', dataType : "", fixed : true,width:150},
  {id: 2,caption : "AMOUNT", dataField : 'amount', dataType : "", fixed : true,width:150},
]
export const secAmount_CountURL_cols =[
  {id: 1,caption : "DEPT", dataField : 'deptShtNamex', dataType : "", fixed : true,width:150},
  {id: 2,caption : "SECTION", dataField : 'sxnShtName', dataType : "", fixed : true,width:150},
  {id: 2,caption : "AMOUNT", dataField : 'amount', dataType : "", fixed : true,width:150},
  {id: 2,caption : "COUNT", dataField : 'countx', dataType : "", fixed : true,width:150},
]
export const secQuantityURL_cols =[
  {id: 1,caption : "DEPT", dataField : 'deptShtNamex', dataType : "", fixed : true,width:150},
  {id: 2,caption : "SECTION", dataField : 'sxnShtName', dataType : "", fixed : true,width:150},
  {id: 2,caption : "QUANTITY", dataField : 'quantity', dataType : "", fixed : true,width:150},
]
export const secQuantity_CountURL_cols =[
  {id: 1,caption : "DEPT", dataField : 'deptShtNamex', dataType : "", fixed : true,width:150},
  {id: 2,caption : "SECTION", dataField : 'sxnShtName', dataType : "", fixed : true,width:150},
  {id: 2,caption : "QUANTITY", dataField : 'quantity', dataType : "", fixed : true,width:150},
  {id: 2,caption : "COUNT", dataField : 'countx', dataType : "", fixed : true,width:150},
]
export const secAmountQuantity_CountURL_cols =[
  {id: 1,caption : "DEPT", dataField : 'deptShtNamex', dataType : "", fixed : true,width:150},
  {id: 2,caption : "SECTION", dataField : 'sxnShtName', dataType : "", fixed : true,width:150},
  {id: 2,caption : "AMOUNT", dataField : 'amount', dataType : "", fixed : true,width:150},
  {id: 2,caption : "QUANTITY", dataField : 'quantity', dataType : "", fixed : true,width:150},
  {id: 2,caption : "COUNT", dataField : 'countx', dataType : "", fixed : true,width:150},
]
export const vehFuelAmountURL_cols =[
  {id: 1,caption : "REG No.", dataField : 'regNox', dataType : "", fixed : true,width:150},
  {id: 2,caption : "CLASS", dataField : 'classx', dataType : "", fixed : true,width:150},
  {id: 3,caption : "MAKE", dataField : 'makex', dataType : "", fixed : true,width:150},
  {id: 4,caption : "MODEL", dataField : 'modelx', dataType : "", fixed : true,width:150},
  {id: 5,caption : "AMOUNT", dataField : 'amtx', dataType : "", fixed : true,width:150},
  {id: 6,caption : "QUANTITY", dataField : 'qtyx', dataType : "", fixed : true,width:150},
]
export const vehClassAmountURL_cols =[
  {id: 1,caption : "CLASS", dataField : 'classx', dataType : "", fixed : true,width:150},
  {id: 2,caption : "AMOUNT", dataField : 'amtx', dataType : "", fixed : true,width:150},
]
export const vehClassAmount_CountURL_cols =[
  {id: 1,caption : "CLASS", dataField : 'classx', dataType : "", fixed : true,width:150},
  {id: 2,caption : "AMOUNT", dataField : 'amtx', dataType : "", fixed : true,width:150},
  {id: 2,caption : "COUNT", dataField : 'countx', dataType : "", fixed : true,width:150},
]
export const vehClassQuantity_CountURL_cols =[
  {id: 1,caption : "CLASS", dataField : 'classx', dataType : "", fixed : true,width:150},
  {id: 2,caption : "QUANTITY", dataField : 'qtyx', dataType : "", fixed : true,width:150},
  {id: 2,caption : "COUNT", dataField : 'countx', dataType : "", fixed : true,width:150},
]
export const vehClassAmountQuantityURL_cols =[
  {id: 1,caption : "CLASS", dataField : 'classx', dataType : "", fixed : true,width:150},
  {id: 2,caption : "AMOUNT", dataField : 'amtx', dataType : "", fixed : true,width:150},
  {id: 2,caption : "QUANTITY", dataField : 'qtyx', dataType : "", fixed : true,width:150},
  {id: 2,caption : "COUNT", dataField : 'countx', dataType : "", fixed : true,width:150},
]


export const Line_cols = [
  {id: 0, value: 'amtx', name: 'Amount' },
  {id: 1, value: 'qtyx', name: 'Quantity' },
  
]


