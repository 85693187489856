import settings from  '../assets/setting.png';

interface Props{
    handleClick?:()=>void;
    style?:any;
}

export const SettingsTemplate = ({handleClick,style}:Props) => {
  return (
    <>
      <span
        onClick={() => {
        handleClick && handleClick()
        }}
        style={style}
        className="ml-3 flex justify-center hover:cursor-pointer border-gray-300 rounded "
      >
        <img
          className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
          src={settings}
          alt="reset"
        />
      </span>
    </>
  );
};


