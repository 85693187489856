import React,{ useCallback, useRef, useState, } from "react";
import TabPanel, {Item} from 'devextreme-react/tab-panel';

import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CIA_toolbar } from "../../../accessories/custom-toolbars/cia_toolbar";
import { BiggerPicture_Form_Details } from "./BiggerPictureTab/widgets/BiggerPicture_Form";
import { BiggerPicture } from "./BiggerPictureTab/BiggerPicture";
import { Tab_xxxx } from "./xxxxTab/Tab_xxxx";
import { NameTheMeasure } from "./NameTheMeasures/NameTheMeasure";
import { PotentialMeasure } from "./PMT/PMT";
import { useDispatch, useSelector } from "react-redux";
import { setGeneralValue } from "../../../../features/generalSlice";
import { RootState } from "../../../../app/store";



export const Measures_Targets_Tabs = ()=>{

    
    const borderTheme = useSelector((state:RootState)=>state.general.cssBorderClass)

    // const navigate = useNavigate();
    // const [searchParams,setSearchParams] = useSearchParams();

    // const current_page_route = '/tasks-todos';
    // const taskOtherUpdate = 'updateAlt'
    // const defaultPage = 'tasks-todos';

    // const toggle_taskUpdate = () =>{
    //     const view = searchParams.get('View');

    //     (view === null || view ===taskOtherUpdate) ?
    //     navigate({
    //         pathname: current_page_route,
    //         search: createSearchParams({
    //             view : defaultPage,
    //         }).toString(),
    //     })

    //     : 
    //     navigate({
    //         pathname : current_page_route,
    //         search: createSearchParams({
    //             view : taskOtherUpdate,
    //         }).toString(),
    //     })
    // }

    const taskDetailsHeight = '309px'

    const [tasks_update_state, setTask_update_state] = useState<boolean>(true)
    const [task_datagrid_height,set_task_datagrid_height] = useState(`calc(100% - ${taskDetailsHeight})`);
    const [searchParams, setSearchParams] = useSearchParams()
    const [selectedRecord, setSelectedRecord] = useState<any>()
    const [switch_change, setSwitch_change] = useState<boolean>(false)
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const current_page_route = '/tasks-todos';
    const view = searchParams.get('view');
    const taskDataGrid = 'task-data-grid-extended';
    const pmtRef = useRef<any>();
    const tbpRef = useRef<any>();
    const ntmRef = useRef<any>();
    const xxxxRef = useRef<any>();

    // switch button toggler
    const switch_toggle = ()=>{
        pmtRef.current.test();
        tbpRef.current.test();
        ntmRef.current.test();
        xxxxRef.current.test();
            
      
    }
   

     // Row selection

    const selectRecord:any = useCallback<any>((e:any) => {
        e.component.byKey(e.currentSelectedRowKeys[0]).done((e:any) => {
            setSelectedRecord(e);
        });
    }, []);

        // double click feature
        const allowUpdate = (e:any) =>{

            dispatch(setGeneralValue({ expr: "MissionUpdateForm", value: true }));
           
            
        
        }

    return (
        <div className="w-full h-full " >
                <div>
                    <CIA_toolbar toggler={switch_toggle} />
                </div>
            <div className="w-full h-full flex justify-center items-center">
                
            <TabPanel style={{borderColor:borderTheme}} className="w-full h-full">
                <Item title={'Potential Measures & Targets'} >
                    
                    <PotentialMeasure ref={pmtRef} state_change={switch_change} selectedGridRecord={selectRecord} selectAllowUpdate={(e:any)=>allowUpdate(e)} />
                     
                </Item>

                <Item title={'The Bigger Picture'}>
                    <BiggerPicture ref={tbpRef} state_change={switch_change} />
                </Item>

                <Item title={'Name The Measures'}>
                   <NameTheMeasure ref={ntmRef} state_change={switch_change} />
                   
                </Item> 

                <Item title={'xxxx'}>
                    <Tab_xxxx ref={xxxxRef} state_change={switch_change} />
                   
                </Item> 

                
                
            </TabPanel>
        </div>
        </div>

    )
}



