export const vehicle_card_TH = [
  {
    id: 0,
    caption: "VEH. REG No",
    dataField: "name",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 1,
    caption: "MOB. No",
    dataField: "shortName",
    alignment: "left",
    fixed: true,
    width: null
  },
 
  {
    id: 2,
    caption: "SIM VENDOR",
    dataField: "code",
    alignment: "left",
    fixed: true,
    width: null
  },
  {
    id: 3,
    caption: "TRACKER VENDOR",
    dataField: "towerType",
    alignment: "left",
    fixed: true,
    width: null


  },
  {
    id: 4,
    caption: "TRACKER URL",
    dataField: "active",
    alignment: "left",
    fixed: true,
    width: null

  },
  {
    id: 5,
    caption: "START DATE",
    dataField: "height",
    alignment: "left",
    fixed: true,
    width: null

  },
  
  {
    id: 6,
    caption: "END DATE",
    dataField: "footing",
    alignment: "left",
    fixed: true,
    width: null

  },
  
  
  {
    id: 7,
    caption: "ACTIVE",
    dataField: "insulatorType",
    alignment: "left",
    fixed: true,
    width: 60

  },
  
  {
    id: 8,
    caption: "REMARKS",
    dataField: "remarks",
    alignment: "left",
    fixed: true,
    width: 4000

  },
  


]


export const  vehicle_card_TH_col = [
  {
    id: 0,
    caption: "VEH. REG No",
    dataField: "vdtRegNo",
    alignment: "left",
    fixed: true,
    
  },
  {
    id: 1,
    caption: "CARD No",
    dataField: "cdtCardNo",
    alignment: "left",
    fixed: true,
    width: null
  },
 
  {
    id: 2,
    caption: "SERVICE PROVIDER",
    dataField: "spdName",
    alignment: "left",
    fixed: true,
    width: null
  },
  {
    id: 3,
    caption: "ASSIGNMENT DATE",
    dataField: "vcaAssignDate",
    dataType: 'datetime',
    alignment: "left",
    fixed: true,
    width: null


  },
  {
    id: 4,
    caption: "END DATE",
    dataField: "vcaEndDate",
    dataType: "datetime",
    alignment: "left",
    fixed: true,
    width: null

  },
  {
    id: 5,
    caption: "ISSUED AT",
    dataField: "twnName",
    alignment: "left",
    fixed: true,
    width: null

  },
  
  {
    id: 6,
    caption: "ACTIVE",
    dataField: "vcaActive",
    dataType: "boolean",
    alignment: "left",
    fixed: true,

  },
  
  
  {
    id: 7,
    caption: "REMARKS",
    dataField: "vcaRmks",
    alignment: "left",
    fixed: true,
    width: 4000

  },
  
  
  
]



export const vfca_cols = [
  {
    id: 0,
    caption: "CardNo",
    dataField: "cdtCardNo",
    alignment: "left",
    fixed: true,
    width: null

  },
  {
    id: 1,
    caption: "Serv. Provider",
    dataField: "spdName",
    alignment: "left",
    fixed: true,
    width: null

  },
  {
    id: 2,
    caption: "Contact",
    dataField: "spdMainContact",
    alignment: "left",
    fixed: true,
    width: null

  },
  {
    id: 3,
    caption: "Mob No",
    dataField: "spdMobNos",
    alignment: "left",
    fixed: true,
    width: null

  },
]

export const vehicle_Reg_No = [
  {
    id: 0,
    caption: "Reg No",
    dataField: "vdtRegNo",
    alignment: "left",
    fixed: true,
    width: 120,

  },
  {
    id: 1,
    caption: "Class",
    dataField: "",
    alignment: "left",
    fixed: true,
    width: null

  },
  {
    id: 2,
    caption: "Make",
    dataField: "",
    alignment: "left",
    fixed: true,
    width: null

  },
  {
    id: 3,
    caption: "Manufacturer",
    dataField: "vmkShtName",
    alignment: "left",
    fixed: true,
    width: 120

  },
  {
    id: 4,
    caption: "Driver",
    dataField: "",
    alignment: "left",
    fixed: true,
    width: null

  },
  {
    id: 5,
    caption: "Driver Intercom",
    dataField: "",
    alignment: "left",
    fixed: true,
    width: null

  },
  {
    id: 6,
    caption: "Approving Officer",
    dataField: "",
    alignment: "left",
    fixed: true,
    width: null

  },
  {
    id: 7,
    caption: "Appr. Officer INTER",
    dataField: "",
    alignment: "left",
    fixed: true,
    width: null

  },
  {
    id: 8,
    caption: "Manuf. Date",
    dataField: "vdtManufactureDate",
    alignment: "left",
    fixed: true,
    width: 120

  },
  {
    id: 9,
    caption: "Dept",
    dataField: "",
    alignment: "left",
    fixed: true,
    width: null

  },
  {
    id: 10,
    caption: "Section",
    dataField: "",
    alignment: "left",
    fixed: true,
    width: null

  },
  {
    id: 11,
    caption: "Unit",
    dataField: "",
    alignment: "left",
    fixed: true,
    width: null

  },
  {
    id: 12,
    caption: "Fuel Type",
    dataField: "ftpShtName",
    alignment: "left",
    fixed: true,
    width: 120

  },
]

  export const card_no_data = [
  {
    id: 0,
    caption: "CardNo",
    dataField: "cdtCardNo",
    alignment: "left",
    fixed: true,
    width: 120

  },
  {
    id: 1,
    caption: "Serv. Provider",
    dataField: "spdName",
    alignment: "left",
    fixed: true,
    width: 120

  },
  {
    id: 2,
    caption: "Issue Date",
    dataField: "cdtIssueDate",
    alignment: "left",
    fixed: true,
    width: 120

  },
  {
    id: 3,
    caption: "Expiry Date",
    dataField: "cdtExpiryDate",
    alignment: "left",
    fixed: true,
    width: 120

  },
]
