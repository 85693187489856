import { useState } from "react";
import { useSearchParams, useNavigate, createSearchParams } from "react-router-dom";
import useFetch from "../../../../../hooks/useFetch";
import CustomLoader from "../../../../accessories/custom-loader";
import StaffClassificationsDetails from "./staff-classifications-details";
import AttendantBlockAssignmentDetails from "./staff-classifications-details";
import AttendantBlocksAssignmentDetails from "./staff-classifications-details";
import { StaffClassificationsToolbar } from "./staff-classifications-toolbar";
import React from "react";





const StaffClassifications = () => {

  const taskDetailsHeight = '309px'

  const [tasks_update_state, setTask_update_state] = useState<boolean>(true)
  const [task_datagrid_height, set_task_datagrid_height] = useState(`calc(100% - ${taskDetailsHeight})`);
  const [searchParams, setSearchParams] = useSearchParams()

  const navigate = useNavigate()

  const current_page_route = '/tasks-todos';
  const view = searchParams.get('view');
  const taskDataGrid = 'task-data-grid-extended';
  const [showTable, setShowTable] = useState(true)
  const [refresh, setRefreshTable] = useState(true)
  var [departmentData, error, loading] = useFetch('api/HrEmployee/FormDepartments/1');

  var [locationData, error, loading] = useFetch('', refresh, 'https://192.168.100.40:5000/api/HrLocations/GetHrLocations',);
  var [sectionData, error, loading] = useFetch('', refresh, 'https://192.168.100.40:5000/api/HrEmployee/FormSections/1',);
  var [unitData, error, loading] = useFetch('', refresh, 'https://192.168.100.40:5000/api/HrEmployee/FormUnits/1',);
  var [allStaffData, error, loading] = useFetch('', refresh, 'https://192.168.100.36:7174/api/HrEmployeeClassifications/GetAllStaff',);


  // switch button toggler
  const switch_toggle = () => {

    setShowTable(!showTable);

  }


  return (
    <>
      {!(departmentData && locationData && sectionData && unitData) && <div className="w-full h-full flex justify-center items-center"><CustomLoader /></div>}

      {(departmentData && locationData && sectionData && unitData) && <div>   <div className="">
        <StaffClassificationsToolbar toggler={switch_toggle} />
        {/* <Staff_customtoolbar /> */}
      </div>

        <div className="px-2 mb-0.5  ">

          <StaffClassificationsDetails toggled={showTable} departmentList={departmentData} locationList={locationData} sectionList={sectionData}
            unitList={unitData} dataTable={allStaffData}
          />

        </div>
      </div>
      }
    </>

  );
}

export default StaffClassifications;