import { PayloadAction, createSlice } from "@reduxjs/toolkit";


interface SimCardSlice{
    disable_Form: boolean,
    New_Cancel_Button : boolean,
    Edit_Cancel_Button: boolean,
    Save_Update_Button: boolean,
}

const VehicleSiCard_Initial_State : SimCardSlice ={
    disable_Form: true,
    New_Cancel_Button : true,
    Edit_Cancel_Button: true,
    Save_Update_Button: true,
};


const VehicleSimCardSlice = createSlice({
    name : "Vehicle Sim Card",
    initialState : VehicleSiCard_Initial_State,
    reducers : {
        setDisable_form:(state, action:PayloadAction<boolean>)=>{
            state.disable_Form = action.payload
        },
        setNew_Cancel_Button:(state, action:PayloadAction<boolean>) =>{
            state.New_Cancel_Button = action.payload
        },
        setEdit_Cancel_Button:(state, action:PayloadAction<boolean>) =>{
            state.Edit_Cancel_Button = action.payload
        },
        setSave_Cancel_Button:(state, action:PayloadAction<boolean>) =>{
            state.Save_Update_Button = action.payload
        },
    }
})

export const{
    setDisable_form,
    setNew_Cancel_Button,
    setEdit_Cancel_Button,
    setSave_Cancel_Button,
} = VehicleSimCardSlice.actions;

export default VehicleSimCardSlice.reducer;