import { useEffect, useState } from "react";
import "devextreme/dist/css/dx.light.css";
import { Tooltip } from "devextreme-react";
import newfile from "../../../assets/new.png";
import edit from "../../../assets/edit.png";
import play from "../../../assets/play.png";
import forward from "../../../assets/forward.png";
import alter from "../../../assets/alter.png";
import help from "../../../assets/help.png";
import close from "../../../assets/close.png";
import ack from "../../../assets/checked.png";
import reset from "../../../assets/refresh-small.png";
import printer from "../../../assets/printer.png";
import statusLoader from "../../../assets/statusloader.gif";
import { Checkbox, Input, Select } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { useDispatch } from "react-redux";
import { setGeneralValue } from "../../../features/generalSlice";
import { ModalTemplate } from "../../../templates/modal";
import { FaBinoculars } from "react-icons/fa";
import "devextreme/dist/css/dx.light.css";

interface props {
  useCallBackFunc?: boolean;
  toggler?: () => void; //switch interface
  handleDelete?: () => void; //delete handler
  handleSave?: () => void; //save handler
  handleNew?: () => void; //new handler
  handleUpdate?: () => void; //update handler
  searchTextOnchange?: (value: string) => void; //search text change
  startDateChanged?: (value: any) => void; //start date change
  endDateChanged?: (value: any) => void; //end date change
  checkOnChange?: (value: any) => void; //checkbox change
  handleFind?: () => void; //find handler
  handleRefresh?: () => void; //refresh main grid
  handlePrint?: () => void; // handle prints functionality
  handleToggle?: () => void;
  handleToggleAll?: () => void;
  searchCriteriaOnchange?: (index: number, criteria: string) => void;
  checkBoxAfterHelpOnchange?: (checkValue: boolean) => void; // event handler for checkbox after help
  disableNew_edit?: boolean;
  withDates?: boolean; //add dates to the toolbar?
  outerSelected?: any;
  setOuterSelected?: (state: any) => void;
  checkBoxAfterHelp?: boolean;
  searchCriteria?: boolean;
  assignmentsForm?: boolean;
  searchCriteriaData?: { id: number; value: string }[];
}

export const RequestsToolbar = ({
  toggler,
  handleToggle,
  handleToggleAll,
  setOuterSelected,
  searchCriteriaOnchange,
  checkBoxAfterHelp = false,
  searchCriteriaData = [],
  assignmentsForm,
  checkBoxAfterHelpOnchange,
  searchCriteria,
  handleNew: newButtonClick,
  handleRefresh,
  handlePrint,
  outerSelected,
  disableNew_edit,
  useCallBackFunc,
  withDates = true,
  handleDelete,
  searchTextOnchange,
  handleSave,
  handleUpdate: handleEdit,
  startDateChanged,
  endDateChanged,
  handleFind,
  checkOnChange,
}: props) => {
  const dispatch = useDispatch();
  const disabled = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const updateMode = useSelector(
    (state: RootState) => state.general.updateMode
  );

  const [warningModal, setWarningModal] = useState<boolean>(false);
  const [warningModalData, setWarningModalData] = useState<any>({
    title: "",
    message: "",
  });
  const selected = useSelector(
    (state: RootState) => state.general.selectedFieldData
  );

  // Three state checkboxes
  const [indeterminate, setIndeterminate] = useState<any>(true);
  const [checkedBox, setCheckedBox] = useState<any>(false);
  const [checkValue, setCheckValue] = useState<boolean | string>(""); //actual value to use
  const [searchTextValue, setSearchTextValue] = useState("");

  const [criteriaDataLocal, setCriterialDataLocal] = useState({
    id: searchCriteriaData![0]?.id ?? 0,
    value: searchCriteriaData![0]?.value ?? "",
  });

  useEffect(() => {
    dispatch(setGeneralValue({ expr: "activeStatus", value: checkValue }));
    checkOnChange && checkOnChange(checkValue);
  }, [checkValue]);

  const onChange = (value: boolean) => {
    if (indeterminate === true) {
      setCheckedBox(true);
      setIndeterminate(false);
      setCheckValue(true);
    } else if (!indeterminate && checkedBox) {
      setCheckedBox(false);
      setIndeterminate(false);
      setCheckValue(false);
    } else if (!indeterminate && !checkedBox) {
      setCheckedBox(false);
      setIndeterminate(true);
      setCheckValue("");
    }
  };

  //handleNew
  const handleNew = () => {
    const reduxDispatchs = () => {
      dispatch(setGeneralValue({ expr: "formDisabled", value: false })); //disable form
      dispatch(setGeneralValue({ expr: "updateMode", value: false })); //disable update mode

      //clear data to redux
      dispatch(setGeneralValue({ expr: "selectedFieldData", value: [] }));
    };
    reduxDispatchs(); //to redux

    if (setOuterSelected !== undefined) {
      setOuterSelected([]);
    }

    //callback function if passed
    newButtonClick && newButtonClick();
  };

  //modal funcs
  const setModal = () => {
    setWarningModal(true);
    setWarningModalData({
      title: "Select record",
      message: "Please select a record to edit",
    });
  };
  const OnModalOk = () => {
    setWarningModal(false);
  };
  const OnModalCancel = () => {
    setWarningModal(false);
  };

  //handle edit
  const handleUpdate = () => {
    if (outerSelected && !useCallBackFunc) {
      if (Object.keys(outerSelected).length < 1) {
        setModal();
        dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      } else {
        //enable forms
        dispatch(setGeneralValue({ expr: "formDisabled", value: false }));

        //enable Update Mode
        dispatch(setGeneralValue({ expr: "updateMode", value: true }));
      }

      return;
    } else if (outerSelected && useCallBackFunc) {
      //set callback update handler here
      return;
    }

    if (Object.keys(selected).length < 1) {
      setModal();
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    } else {
      //enable forms
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));

      //enable Update Mode
      dispatch(setGeneralValue({ expr: "updateMode", value: true }));
    }
  };

  //Handle cancel
  const handleCancel = () => {
    dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    dispatch(setGeneralValue({ expr: "updateMode", value: false }));
  };

  return (
    <div
      style={{ borderWidth: "1px", height: 32 }}
      className="px-0.5 mt-0.5 rounded  w-full h-full flex  border-gray-200 mb-1"
    >
      <ModalTemplate
        icon={"warning"}
        cancelHandler={OnModalCancel}
        disableCancel={true}
        open={warningModal}
        okHandler={OnModalOk}
        message={warningModalData.message}
        okText={"Ok"}
        title={warningModalData.title}
      />
      <ul style={{ width: 410 }} className="flex items-center h-full py-0.5">
        {/* new and cancel section */}
        {!disableNew_edit && (
          <span className="flex items-center h-full ">
            {disabled || updateMode ? (
              <li
                id="newRecord"
                onClick={() => handleNew()}
                style={{ borderWidth: "1px" }}
                className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
              >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                  {" "}
                  <img alt="" className="pr-1 " src={newfile} />
                  New{" "}
                </span>
                <Tooltip
                  target="#newRecord"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">New Record</p>
                </Tooltip>
              </li>
            ) : (
              <li
                id="close-cancel"
                onClick={() => handleCancel()}
                style={{ borderWidth: "1px" }}
                className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
              >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                  {" "}
                  <img alt="" className="pr-1 " src={close} />
                  Cancel{" "}
                </span>
                <Tooltip
                  target="#close-cancel"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">Cancel</p>
                </Tooltip>
              </li>
            )}

            {!updateMode ? (
              <li
                id="editRecordst"
                onClick={() => handleUpdate()}
                style={{ borderWidth: "1px" }}
                className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
              >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                  {" "}
                  <img
                    alt=""
                    className="dark:bg-slate-900 pr-1 dark:text-gray-100 duration-100"
                    src={edit}
                  />
                  Edit{" "}
                </span>
                <Tooltip
                  target="#editRecordst"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">Edit Record</p>
                </Tooltip>
              </li>
            ) : (
              <li
                id="close-cancel"
                onClick={() => handleCancel()}
                style={{ borderWidth: "1px" }}
                className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
              >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                  {" "}
                  <img alt="" className="pr-1 " src={close} />
                  Cancel{" "}
                </span>
                <Tooltip
                  target="#close-cancel"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">Cancel</p>
                </Tooltip>
              </li>
            )}
          </span>
        )}

        <li
          id="switchInterface"
          onClick={toggler}
          style={{ borderWidth: "1px" }}
          className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
        >
          <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
            {" "}
            <img
              alt=""
              className="dark:bg-slate-900 pr-1 dark:text-darkModeSkin-base duration-100"
              src={alter}
            />
            Switch
          </span>
          <Tooltip
            target="#switchInterface"
            showEvent="dxhoverstart"
            hideEvent="dxhoverend"
            hideOnOutsideClick={true}
          >
            <p className="">Switch interface / table view</p>
          </Tooltip>
        </li>

        <li
          id="close-cancel-cl"
          onClick={() => handleCancel()}
          style={{ borderWidth: "1px" }}
          className=" h-[26px] hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
        >
          <span className="flex justify-center items-center dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
            {" "}
            <img alt="" className=" w-10/12 h-10/12" src={close} />{" "}
          </span>
          <Tooltip
            target="#close-cancel-cl"
            showEvent="dxhoverstart"
            hideEvent="dxhoverend"
            hideOnOutsideClick={true}
          >
            <p className="">Cancel</p>
          </Tooltip>
        </li>

        <li
          id="submit"
          onClick={() => handleCancel()}
          style={{ borderWidth: "1px" }}
          className="hover:cursor-pointer h-[26px] mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
        >
          <span className="flex justify-center items-center dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
            {" "}
            <img alt="" className="w-10/12" src={play} />{" "}
          </span>
          <Tooltip
            target="#submit"
            showEvent="dxhoverstart"
            hideEvent="dxhoverend"
            hideOnOutsideClick={true}
          >
            <p className="">Submit</p>
          </Tooltip>
        </li>

        <li
          id="submit-monthly"
          onClick={() => handleCancel()}
          style={{ borderWidth: "1px" }}
          className="hover:cursor-pointer h-[26px] mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
        >
          <span className="flex justify-center items-center dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
            {" "}
            <img alt="" className=" h-10/12 w-12/12" src={forward} />{" "}
          </span>
          <Tooltip
            target="#submit-monthly"
            showEvent="dxhoverstart"
            hideEvent="dxhoverend"
            hideOnOutsideClick={true}
          >
            <p className="">Submit Monthly</p>
          </Tooltip>
        </li>

        <li
          id="acknowledge"
          onClick={() => handleCancel()}
          style={{ borderWidth: "1px", padding: 2.3 }}
          className="hover:cursor-pointer h-[26px] mr-1 border-slate-200 text-xs  text-gray-600 rounded flex items-center"
        >
          <span className="flex justify-center items-center dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
            {" "}
            <img alt="" className=" h-10/12 w-10/12" src={ack} />{" "}
          </span>
          <Tooltip
            target="#acknowledge"
            showEvent="dxhoverstart"
            hideEvent="dxhoverend"
            hideOnOutsideClick={true}
          >
            <p className="">Acknowledge</p>
          </Tooltip>
        </li>

        <li
          id="helpst"
          style={{ borderWidth: "1px" }}
          className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
        >
          <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
            {" "}
            <img
              alt=""
              className="dark:bg-slate-900 dark:text-gray-100 duration-100 mr-1"
              src={help}
            />
            Help{" "}
          </span>
          <Tooltip
            target="#helpst"
            showEvent="dxhoverstart"
            hideEvent="dxhoverend"
            hideOnOutsideClick={true}
          >
            <p className="">Help</p>
          </Tooltip>
        </li>

        {checkBoxAfterHelp && (
          <li
            style={{
              borderWidth: "1px",
              paddingTop: 1,
              paddingBottom: 1,
              paddingLeft: 5,
              paddingRight: 5,
            }}
            id="loadAll"
            className="  border-slate-200  hover:cursor-pointer mr-1  text-xs px-1 text-gray-600 rounded flex items-center"
          >
            <Checkbox
              onChange={(checkValue) => {
                checkBoxAfterHelpOnchange &&
                  checkBoxAfterHelpOnchange(checkValue.target.checked);
              }}
            />
            <Tooltip
              target="#loadAll"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Load all forms</p>
            </Tooltip>
          </li>
        )}
      </ul>

      <ul style={{ width: "calc(100% - 410px)" }} className="flex pl-7  w-full">
        <section className="flex justify-start items-center">
          {(searchCriteria || assignmentsForm) && (
            <li className="px-1 flex items-center flex-row ">
              <label style={{ width: "87px" }} className=" text-xs">
                Search Criteria
              </label>
              <Select
                value={criteriaDataLocal}
                className=" mt-0.5 "
                onChange={(data: any) => {
                  const criteriaData = JSON.parse(data);
                  setCriterialDataLocal({
                    id: criteriaData?.index,
                    value: criteriaData?.criteria,
                  }); //local
                  searchCriteriaOnchange &&
                    searchCriteriaOnchange(
                      criteriaData?.index,
                      criteriaData?.criteria
                    ); //external
                }}
                id="search"
                size="small"
                showSearch={true}
                style={{ width: 110, height: "25px" }}
              >
                {searchCriteriaData?.map(({ id, value }, index) => (
                  <Select.Option
                    key={id}
                    value={JSON.stringify({ index: index, criteria: value })}
                  >
                    <p className=" text-xs">{value}</p>
                  </Select.Option>
                ))}
              </Select>
            </li>
          )}

          <li className="px-1 flex items-center pl-4 flex-row ">
            <label style={{ width: "70px" }} className=" text-xs">
              Search Text
            </label>
            <Input
              onChange={(e: any) => {
                const searchText: string = e.target.value;
                searchTextOnchange && searchTextOnchange(searchText);
                setSearchTextValue(e.target.value);
              }}
              value={searchTextValue}
              name="search"
              id="search"
              size="small"
              allowClear={true}
              style={{ width: 130, height: "25px" }}
            />
          </li>

          <li
            id="status"
            style={{
              borderWidth: "1px",
              paddingTop: 0.8,
              paddingBottom: 0.8,
              paddingLeft: 3.25,
              paddingRight: 3,
            }}
            className="hover:cursor-pointer mr-1  text-xs   text-gray-600 rounded flex items-center"
          >
            <Checkbox
              checked={checkedBox}
              indeterminate={indeterminate}
              onChange={(checkValue: any) => {
                onChange(checkValue);
              }}
            />
            <Tooltip
              target="#status"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Show Status</p>
            </Tooltip>
          </li>
          <li
            onClick={() => {
              if (handleFind) {
                handleFind();
              } else {
              }
            }}
            id="findst"
            style={{ borderWidth: "1px" }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
          >
            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
              <FaBinoculars size={15.5} color="#007AFF" />
            </span>
            <Tooltip
              target="#findst"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Find</p>
            </Tooltip>
          </li>

          {/* refresh */}
          <li
            onClick={() => {
              handleRefresh && handleRefresh();
              setSearchTextValue("");

              setCheckedBox(false);
              setIndeterminate(true);
              setCheckValue("");
            }}
            id="refreshst"
            style={{ borderWidth: "1px" }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
          >
            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
              {" "}
              <img
                alt=""
                className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                src={reset}
              />
            </span>
            <Tooltip
              target="#refreshst"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Refresh</p>
            </Tooltip>
          </li>

          <li
            onClick={() => handlePrint && handlePrint()}
            id="printst"
            style={{ borderWidth: "1px" }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
          >
            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
              {" "}
              <img
                alt=""
                className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                src={printer}
              />
            </span>
            <Tooltip
              target="#printst"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Print/Export</p>
            </Tooltip>
          </li>
          {/* Exports */}
        </section>

        {/* Form status label */}
        {!disableNew_edit && (
          <section className="flex pl-10 h-full flex-row items-center">
            {/* done state */}
            {disabled && <p className=" text-gray-600">Done.</p>}

            {/* new record or editing state */}
            {!disabled && (
              <div className="gif  flex flex-row items-center">
                <img className="w-11 h-7" src={statusLoader} alt="gif" />
                {!updateMode ? (
                  <p className=" text-gray-600">New record...</p>
                ) : (
                  <p className=" text-gray-600">Editing...</p>
                )}
              </div>
            )}
          </section>
        )}
      </ul>
    </div>
  );
};
