export const Bus_details_column = [
    {id : 0, caption : 'STATION',dataField : 'stnName',datatype : 'number', width : 160, fixed : true, visible : true},
    {id : 1, caption : 'NAME',dataField : 'bdtName',datatype : 'number', width : 160, fixed : true, visible : true},
    {id : 2, caption : 'SHORT NAME', dataField : 'bdtShtName',datatype : 'string', width : 160, fixed : true , visible : true},
    {id : 3, caption : 'ACTIVE?', dataField : 'bdtActive',datatype : 'boolean', width : 90, fixed : false, visible : true},
    {id : 4, caption : 'MIN VOLTAGE', dataField : 'bdtMinimumVoltage',datatype : 'string', width : 160, fixed : false , visible : true},
    {id : 5, caption : 'MAX VOLTAGE', dataField : 'bdtMaximumVoltage',datatype : 'string', width : 160, fixed : false , visible : true},
   
    {id : 6, caption : 'REMARKS',dataField : 'bdtRmks',datatype : 'string', width : 5000, fixed : false , visible : true}
]
