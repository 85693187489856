export const station_details_Columns = [
    //  {id : 0, caption : 'ID',dataField : 'evtIDpk',dataType : 'number', width : 80, fixed : true, visible : false},
    {id : 0, caption : 'STATION NAME', dataField : 'stnName',dataType : 'string', width : 130, fixed : true , visible : true},
    {id : 1, caption : 'SHORT NAME', dataField : 'stnShtName',dataType : 'string', width : 110, fixed : true , visible : true},
    {id : 2, caption : 'CODE', dataField : 'stnCode',dataType : 'string', width : 80, fixed : true , visible : true},
    {id : 3, caption : 'CONTACT', dataField : 'stnContact',dataType : 'string', width : 80, fixed : true , visible : true},
    {id : 4, caption : 'TEL NO', dataField : 'stnTelNo',dataType : 'string', width : 80, fixed : true , visible : true},
    {id : 5, caption : 'PLC', dataField : 'stnPlc',dataType : 'string', width : 80, fixed : true , visible : true},
    {id : 6, caption : 'ACTIVE?', dataField : 'stnActive',dataType : 'boolean', width : 80, fixed : true , visible : true},
    {id : 7, caption : 'LOCATION', dataField : 'locName',dataType : 'string', width : 100, fixed : false , visible : true},
    {id : 8, caption : 'AREA', dataField : 'areName',dataType : 'string', width : 100, fixed : false , visible : true},
    {id : 9, caption : 'REGION', dataField : 'rgnName',dataType : 'string', width : 100, fixed : false , visible : true},
    {id : 10, caption : 'TOWN', dataField : 'stnTown',dataType : 'string', width : 100, fixed : false , visible : true},
    {id : 11, caption : 'CITY', dataField : 'ctyName',dataType : 'string', width : 100, fixed : false , visible : true},
    {id : 12, caption : 'SUPERVISOR', dataField : 'stnSupervisor',dataType : 'string', width : 100, fixed : false , visible : true},
    {id : 13, caption : 'LATITUDE', dataField : 'stnLatitude',dataType : 'string', width : 100, fixed : false , visible : true},
    {id : 14, caption : 'LONGITUDE', dataField : 'stnLongitude',dataType : 'string', width : 100, fixed : false , visible : true},
    {id : 15, caption : 'EVEN ROWS COLOUR', dataField : 'stnEvenRowsColour',dataType : 'string', width : 100, fixed : false , visible : true},
    {id : 16, caption : 'FLAGGED ROWS COLOUR', dataField : 'stnFlaggedRowsColour',dataType : 'string', width : 100, fixed : false , visible : true},
    {id : 17, caption : 'LOCAL SYNC SERVER IP', dataField : 'stnLocalSyncServerIP',dataType : 'string', width : 100, fixed : false , visible : true},
    {id : 18, caption : 'LOCAL SYNC SERVER NAME', dataField : 'stnLocalSyncServerName',dataType : 'string', width : 100, fixed : false , visible : true},
  
    {id : 19, caption : 'AREA ACTIVE?', dataField : 'areActive',dataType : 'boolean', width : 80, fixed : false , visible : true},
    {id : 20, caption : 'MASTER STATION?', dataField : 'stnMasterStation',dataType : 'boolean', width : 80, fixed : false , visible : true},
    {id : 21, caption : 'NCC?', dataField : 'stnNationalControlCenter',dataType : 'boolean', width : 80, fixed : false , visible : true},
    {id : 22, caption : 'OPERATIONAL?', dataField : 'stnOperational',dataType : 'boolean', width : 80, fixed : false , visible : true},
    {id : 23, caption : 'SUBSTATION?', dataField : 'stnSubstation',dataType : 'boolean', width : 80, fixed : false , visible : true},
    {id : 24, caption : 'MANNED?', dataField : 'stnManned',dataType : 'boolean', width : 80, fixed : false , visible : true},
    {id : 25, caption : 'MANNED BY US?', dataField : 'stnMannedByUs',dataType : 'boolean', width : 80, fixed : false , visible : true},
    {id : 26, caption : 'OWNED BY US?', dataField : 'stnOwnedByUs',dataType : 'boolean', width : 80, fixed : false , visible : true},
    {id : 27, caption : 'HAS COMPUTER?', dataField : 'stnHasComputer',dataType : 'boolean', width : 80, fixed : false , visible : true},
    {id : 28, caption : 'HAS NETWORK?', dataField : 'stnHasNetwork',dataType : 'boolean', width : 80, fixed : false , visible : true},
    {id : 29, caption : 'ELOGGING?', dataField : 'stnElogging',dataType : 'boolean', width : 80, fixed : false , visible : true},
    {id : 30, caption : 'STATUS GOOD?', dataField : 'stnStatusGood',dataType : 'boolean', width : 80, fixed : false , visible : true},
    {id : 31, caption : 'GENERATION STATION?', dataField : 'stnGenStation',dataType : 'boolean', width : 80, fixed : false , visible : true},
    {id : 32, caption : 'SHOW IN ELOG BOOK?', dataField : 'stnShowInElogBook',dataType : 'boolean', width : 80, fixed : false , visible : true},
    {id : 33, caption : 'SYNC LOCAL LOGS?', dataField : 'stnSyncLocalLogs',dataType : 'boolean', width : 80, fixed : false , visible : true},
    
   
    {id : 34, caption : 'REMARKS',dataField : 'stnRmks',dataType : 'string', width : 4000, fixed : false , visible : true}
]