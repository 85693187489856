import { create } from "zustand";
import { devtools } from "zustand/middleware";

interface SwitcherStore {
  status: boolean;
  setWitch: () => void;
}

export const useSwitcher = create<SwitcherStore>()(
  devtools((set: any) => ({
    status: false,

    setWitch: () => {
      set({
        status: !useSwitcher.getState().status,
      });
    },
  }))
);

interface GraphSwitcherStore {
  status: boolean;
  setSwitchGraph: () => void;
}

export const useGraphSwitcher = create<GraphSwitcherStore>()(
  devtools((set: any) => ({
    status: false,

    setSwitchGraph: () => {
      set({
        status: !useGraphSwitcher.getState().status,
      });
    },
  }))
);
 
