export const CardDetailsColumns = [
  {
    id: 0,
    caption: "cardID",
    dataField: "cdtIdpk",
    dataType: "",
    width: 80,
    fixed: true,
    visible: false,
  },
  {
    id: 12,
    caption: "townID",
    dataField: "twnIdpk",
    dataType: "",
    width: 80,
    fixed: true,
    visible: false,
  },
  {
    id: 13,
    caption: "regionID",
    dataField: "rgnIdpk",
    dataType: "",
    width: 80,
    fixed: true,
    visible: false,
  },
  {
    id: 1,
    caption: "CARD NO",
    dataField: "cdtCardNo",
    dataType: "string",
    width: 80,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "CARD PIN",
    dataField: "cdtCardPin",
    dataType: "string",
    width: 85,
    fixed: true,
    visible: true,
  },
  {
    id: 3,
    caption: "SERVICE PROVIDER",
    dataField: "spdName",
    dataType: "string",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 4,
    caption: "TOWN ISSUED",
    dataField: "twnName",
    dataType: "string",
    width: 80,
    fixed: true,
    visible: true,
  },
  {
    id: 6,
    caption: "ISSUE DATE",
    dataField: "cdtIssueDate",
    dataType: "datetime",
    width: 80,
    fixed: false,
    visible: true,
  },
  {
    id: 7,
    caption: "EXPIRY DATE",
    dataField: "cdtExpiryDate",
    dataType: "datetime",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 8,
    caption: "REGION ISSUED",
    dataField: "rgnName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 9,
    caption: "ORDER",
    dataField: "cdtOrder",
    dataType: "",
    width: 80,
    fixed: false,
    visible: true,
  },
  {
    id: 10,
    caption: "ACTIVE?",
    dataField: "cdtActive",
    dataType: "boolean",
    width: 50,
    fixed: false,
    visible: true,
  },
  {
    id: 11,
    caption: "REMARKS",
    dataField: "cdtRmks",
    dataType: "string",
    width: 4000,
    fixed: false,
    visible: true,
  },
];
