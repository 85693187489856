import dayjs from "dayjs";
import { Dispatch, SetStateAction, useState } from "react";

interface ModalData {
  state: boolean;
  icon: "warning" | "success" | "question";
  title: string;
  okText: string;
  cancelText: string;
  message: any | JSX.Element;
  okHandler: () => void;
  cancelHandler: () => void;
  disableCancel: boolean;
}

export const tab_bar_height = 32;

// navbar height value
export const navbar_height = 60 + tab_bar_height;

//toolbar height
export const toolbar_height = 28;

//db date format
export const dbDateFormat = "YYYY-MM-DD";

export const startingDate = dayjs()
  .subtract(1, "years")
  .startOf("month")
  .format(dbDateFormat);
export const currentDate = dayjs().format(dbDateFormat);

export const graphStartingDate = dayjs().startOf("month").format(dbDateFormat);

export const regex = /^[&@%*~?<>_.\-&^$#!^+]/; //character validation

export const dateFormat = "EEE, dd MMM yyyy HH:mm";
export const dateFormat2 = "EEE, dd MMM yyyy";
export const dateFormat3 = "HH:mm:ss";

export const serverError = {
  title: "Server Error",
  message: "Server Error. Please retry or contact your Systems Administrator",
};

export const putError = {
  title: "Update Failed",
  message:
    "Error updating record. Please retry or contact your Systems Administrator",
};

export const postError = {
  title: "Save Failed",
  message:
    "Error saving record. Please retry or contact your Systems Administrator",
};

export const recordExistsError = {
  title: "Record Exists",
  message: "The name or shortname you have entered already exists",
};

//modal infos

export function useModalData(): [
  ModalData,
  Dispatch<SetStateAction<ModalData>>
] {
  const [modalData, setModalData] = useState<ModalData>({
    state: false,
    icon: "warning",
    title: "",
    message: "",
    okText: "",
    cancelText: "",
    okHandler: () => {},
    cancelHandler: () => {},
    disableCancel: false,
  });

  return [modalData, setModalData];
}
export const deleteModalData = {
  message: "Are you sure you want to delete this record?",
  title: "Delete Record?",
  okText: "Yes",
  cancelText: "No",
};
export const deleteModalData2 = {
  message:
    "Are you sure you want to delete this job detail? remember this action is irreversible.",
  title: "Delete Record?",
  okText: "Yes",
  cancelText: "No",
};

export const confirmSaveModalData = {
  message: "Are you sure you want to save this record?",
  title: "Save Record",
  okText: "Yes",
  cancelText: "No",
};

export const conditionSave = {
  message: "Are you sure save these entries",
  title: "Save Entries",
  okText: "Ok",
  cancelText: "No",
};
export const percentMessg = {
  message: "Do you want to update and send an SMS alert to the requester?",
  title: "Update & Send SMS Alert",
  okText: "Yes",
  cancelText: "No",
};

export const confirmSendAlert = {
  message: "Are you sure you want to run schedule alert?",
  title: "Run Schedule Alerts??",
  okText: "OK",
};

export const confirmConditionSubmit = {
  message:
    "Are you sure you want to proceed and submit the Vehicle Conditions Returns for the month? ",
  title: "Note that only saved returns will be submitted.",
  okText: "OK",
  cancelText: "No",
};

export const confirmUpdateModalData = {
  message: "Are you sure you want to update this record?",
  title: "Update Record",
  okText: "Yes, update",
  cancelText: "No, cancel",
  event: () => {},
};

export const saveModalData = {
  ...confirmSaveModalData,
  progress: "Saving record...",
  success: { title: "Save succesful", message: "Record saved successfully" },
  error: {
    title: "Save failed",
    message:
      "Failed to save record. Please retry or contact your systems administrator",
  },
};
export const updateModalData = {
  ...confirmUpdateModalData,
  progress: "Updating record...",
  success: {
    title: "Update successful",
    message: "Record updated successfully",
  },
  error: {
    title: "Update Failed",
    message:
      "Failed to update record. Please retry or contact your Systems Administrator",
  },
};

export const runScheduleAlert = {
  ...confirmSendAlert,
  success: {
    title: "Schedule Run Successful",
    message: "Schedule alerts did run successfully",
  },
  error: {
    title: "Schedule Run Failed",
    message:
      "Schedule alerts didn't run successfully. Please retry or contact your Systems Administrator",
  },
};
export const submitCondition = {
  ...confirmConditionSubmit,
  success: {
    title: "Schedule Run Successful",
    message: "Schedule alerts did run successfully",
  },
  error: {
    title: "Schedule Run Failed",
    message:
      "Schedule alerts didn't run successfully. Please retry or contact your Systems Administrator",
  },
};

export const submitModalData = {
  message: "Are you sure you want to submit this record?",
  title: "Submit Record?",
  okText: "Yes, submit",
  cancelText: "No, Cancel",
  progress: "Updating Record...",
  success: {
    title: "Update Succesful",
    message: "Record Updated Successfully",
  },
  error: {
    title: "Update Failed",
    message:
      "Failed to update record. Please retry or contact your Systems Administrator",
  },
};

export const validationsData: any = {
  serverError: { ...serverError, okText: "Ok" },
  exists: { ...recordExistsError, okText: "Ok" },
  name: { message: "Please enter a name", title: "Enter name", okText: "Ok" },
  validName: {
    message: "Please enter a valid name",
    title: "Enter Valid Name",
    okText: "Ok",
  },
  label: {
    message: "Please enter a valid name value",
    title: "Enter name",
    okText: "Ok",
  },
  shortname: {
    message: "Please enter a short name",
    title: " Enter short name",
    okText: "Ok",
  },
  validShortname: {
    message: "Please enter a valid short name value",
    title: " Enter Valid Short Name",
    okText: "Ok",
  },
  area: {
    message: "Please select an area",
    title: "Select area",
    okText: "Ok",
  },
  branch: {
    message: "Please select a branch",
    title: "Select branch",
    okText: "Ok",
  },
  department: {
    message: "Please select a department",
    title: "Select department",
    okText: "Ok",
  },
  section: {
    message: "Please select a section",
    title: "Select section",
    okText: "Ok",
  },
  unit: { message: "Please select a unit", title: "Select unit", okText: "Ok" },
  transUnit: {
    message: "Please select a transport unit",
    title: "Select transport unit",
    okText: "Ok",
  },
  station: {
    message: "Please select a station",
    title: "Select station",
    okText: "Ok",
  },
  group: {
    message: "Please select a group",
    title: "Select group",
    okText: "Ok",
  },
  delete: {
    message: "Please select a field to delete",
    title: "Select field",
    okText: "Ok",
  },
  hardwareType: {
    message: "Please select a hardware type",
    title: "Select hardware type",
    okText: "Ok",
  },
  osFlavours: {
    message: "Please select an OS Flavour",
    title: "Select os flavour",
    okText: "Ok",
  },
  country: {
    message: "Please select a country",
    title: "Select country",
    okText: "Ok",
  },
  Class: {
    message: "Please select a class",
    title: "Select class",
    okText: "Ok",
  },
  grade: {
    message: "Please select a grade",
    title: "Select Grade",
    okText: "Ok",
  },
  vehicleModel: {
    message: "Please enter a valid vehicle model value",
    title: "Enter Vehicle Model",
    okText: "Ok",
  },
  order: {
    message: "Please enter a value for the order field",
    title: "Enter Order",
    okText: "Ok",
  },
  inputAfter0: {
    message: "Please enter a value for duration field",
    title: "Enter Duration",
    okText: "Ok",
  },
  inputAfter1: {
    message: "Please enter a value for NoOfQuestion field",
    title: "Enter NoOfQuestion",
    okText: "Ok",
  },
  validOrder: {
    message: "Please the order field should be a number",
    title: "Input Type Error",
    okText: "Ok",
  },
  validInputAfter0: {
    message: "Please the duration field should be a number",
    title: "Input Type Error",
    okText: "Ok",
  },
  validInputAfter1: {
    message: "Please the NoOfQuestion field should be a number",
    title: "Input Type Error",
    okText: "Ok",
  },
};

export const customvalidationsData: any = {
  name: { message: "Please enter a", title: "Invalid entry!", okText: "Ok" },
  shortname: {
    message: "Please enter a short name",
    title: "Invalid entry!",
    okText: "Ok",
    cancelText: "No, Cancel",
  },
  select: { message: "Please enter a ", title: "Invalid entry!", okText: "Ok" },
};

// local route url

// export const api_url = "https://scarcely-smashing-tahr.ngrok-free.app/api";
// export const api_base_url = "http://localhost:6026/api";
export const api_base_url = "http://guiding-composed-ape.ngrok-free.app/api";
// export const api_url = "https://clean-viper-morally.ngrok-free.app/api";
// export const api_url = "http://localhost:5000/api";

// export const api_base_url = "http://localhost:6026/api";
// export const api_base_url = "https://steady-expert-hippo.ngrok-free.app/api";
// export const api_base_url = "https://friendly-guiding-mouse.ngrok-free.app/api";
// "http://localhost:5000";
// cloud route url
export const api_url = "https://innoxapi.azurewebsites.net/api"

// *********************************** NAVBAR ********************************
export const ActionTypes = {
  LOG_OUT: "Log Out",
  RESET_PASSWORD: "Reset Password",
  MY_SETTINGS: "My Settings",
  ALERTS: "Alerts",
  STOP_NEWS_FLASH: "Stop News Flash",
  START_NEWS_FLASH: "Start News Flash",
  HIDE_NEWS_FLASH: "Hide News Flash",
  SHOW_NEWS_FLASH: "Show News Flash",
  THEME: "Theme",
  CLOSE_ALL_FORMS: "Close All Forms",
  LIGHT_MODE: "Light Mode",
  DARK_MODE: "Dark Mode",
  BOOTSTRAP: "Bootstrap",
  BOOTSTRAP_BLUE: "Bootstrap Blue",
  BOOTSTRAP_BLACK: "Bootstrap Black",
  VISTA: "Vista",
};

export const ProfileThemes = {
  LIGHT_MODE: "Light Mode",
  DARK_MODE: "Dark Mode",
  BOOTSTRAP: "Bootstrap",
  BOOTSTRAP_BLUE: "Bootstrap Blue",
  BOOTSTRAP_BLACK: "Bootstrap Black",
  VISTA: "Vista",
};

export const ProfileOptions = {};

// *********************************** NAVBAR ********************************

export const ticker_data = `
<div  class='ticker-wrap  py-[10px]'  id='ticker-wrap'>
  <div class="ticker " >
    <div class="ticker__item text-3xl items-center px-2" id="ticker__item"> 
      Our Mission is to provide innovative, world class and enterprise software products and services globally, thereby enabling them to leverage technology for business growth and sustainability.
      Our vision is to help both local and international businesses overcome the ever trending technological challenges of digital transformation by delivering eminent software solutions and services.
      Our Values form the building blocks of our culture and defines how we work together to serve our customers and to change the world.
    </div>

  </div>
</div>
`;
