import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState, } from "react";
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import { Tasks } from "./TaskTab";
import { TodoList } from "./todoList";
import { CustomToolbar } from "../accessories/custom-toolbars/customtoolbar";
import { Staff_customtoolbar } from '../accessories/custom-toolbars/staff_details_customToolbar'
import { CustomSwitcher } from "../accessories/custom-toolbars/widgets/customToolbarModals";
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { HrSetupsCustomToolbar } from "../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import { useDispatch, useSelector } from "react-redux";
import { setGeneralValue } from "../../features/generalSlice";
import { RootState } from "../../app/store";



export const TaskTab = forwardRef(({ a }: any, ref) => {
    const resized = useSelector((state: RootState) => state.general.resized);
    const dispatch = useDispatch()

    // on page resize height responsiveness
    const [trigger, setTrigger] = useState<boolean>(false);
    useImperativeHandle(ref, () => ({
        test: () => {
            setTrigger(!trigger);
            dispatch(setGeneralValue({ expr: "resized", value: !resized }))
        }
    }));





    const taskDetailsHeight = '309px'

    const [tasks_update_state, setTask_update_state] = useState<boolean>(true)
    const [task_datagrid_height, set_task_datagrid_height] = useState(`calc(100% - ${taskDetailsHeight})`);
    const [searchParams, setSearchParams] = useSearchParams()

    const navigate = useNavigate()

    const current_page_route = '/tasks-todos';
    const view = searchParams.get('view');
    const taskDataGrid = 'task-data-grid-extended';



    // switch button toggler
    const switch_toggle = () => {
        if (tasks_update_state) {
            // extended display
            setTask_update_state(!tasks_update_state);
            // setTask_update_col_width(200)
            set_task_datagrid_height('100%')

            navigate({
                pathname: current_page_route,
                search: createSearchParams({
                    view: taskDataGrid,
                }).toString(),
            })

        } else {
            // mini dispay
            setTask_update_state(!tasks_update_state);
            // setTask_update_col_width(100)
            navigate({
                pathname: current_page_route,
                search: createSearchParams({
                    view: current_page_route,
                }).toString(),
            })

        }

    }


    const miscHeight = 43
    //Table Height



    useEffect(() => {



    }, [])

    return (
        <div style={{ height: '' }} className="w-full flex justify-center items-center">

            <div className="w-full">
                <div className="">
                    <CustomToolbar toggler={switch_toggle} />
                    {/* <Staff_customtoolbar /> */}
                </div>

                <div>
                    <Tasks state_change={tasks_update_state} trigger={trigger} />
                </div>

            </div>
            {/* <TabPanel style={{height : ''}} className="w-full">
                <Item title={'Task'}>
                    <div>
                        <div className="">
                            <CustomToolbar toggler={switch_toggle}  />
                            {/* <Staff_customtoolbar /> */}
            {/* </div>
        
                       <div>
                            <Tasks state_change ={tasks_update_state} trigger = {trigger}   />
                       </div>
                    
                    </div> */}
            {/* </Item> */}

            {/* <Item title={'Todo List'}> */}
            {/* <div className=""> */}
            {/* <CustomToolbar /> */}
            {/* </div> */}
            {/* <TodoList /> */}
            {/* </Item> */}

            {/* <Item title={'Done Today'}> */}

            {/* <CustomSwitcher /> */}
            {/* <HrSetupsCustomToolbar /> */}
            {/* <Staff_customtoolbar /> */}
            {/* </Item>  */}

            {/* </TabPanel>  */}
        </div>
    )
})



