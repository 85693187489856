import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';
import { setGeneralValue } from '../../../../features/generalSlice';
import useFetch from '../../../../hooks/useFetch';
import  Datagrid_template from '../../../../templates/Datagrid';
import { navbar_height,  toolbar_height } from '../../../accessories/component_infos';
import { HrSetupsCustomToolbar } from '../../../accessories/custom-toolbars/hrSetupsCustomtoolbar';
import { PerformanceSetups_CorporateMeasures_columns } from './data/corporatemeasuresData';
import { Corporate_measures_form } from './widgets/corporateMeasuresForm';
import { RootState } from '../../../../app/store';
import { searchTextTypes } from '../../../human-resource/setups/data/types/selectsTypes';
import { useBusyLoader } from '../../../../hooks/useBusyLoader';
import { WarningAlert } from '../../../accessories/warningAlert';
import CustomLoader from '../../../accessories/custom-loader';


export const PerformanceSetupsCorporateMeasures = forwardRef(({a}:any, ref)=>{
    const news_flash_height = useSelector((state:RootState)=>state.general.newsflashHeight);
    const dispatch = useDispatch();

    
    // on page resize height responsiveness
    const [trigger,setTrigger] = useState<boolean>(false);
    useImperativeHandle(ref, () => ({
        test: () => {
            setTrigger(!trigger);
        }
    }));

    //navigation
    const setupPageRoute = window.location.pathname; //get current route of page
    const datagridOnlySearchParam = 'table-only'  //search param in datagrid mode
    const formModeSearchParam = 'form-and-table' //search param in form mode mode
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const activeStatus = useSelector((state:RootState)=>state.general.activeStatus);
    const[searchText,setSearchText]=useState<searchTextTypes>({temp:'',text:''});
    const[status,setStatus]=useState<boolean|string>('');

    //miscellaneous heights
    const miscHeight = 7;
    
    // All heights out
    const heights_out = navbar_height + news_flash_height + toolbar_height;

    //varying form height
    const [form_height,set_form_height] = useState<number>(215);
    const pass_form_height=(height:number)=>{
        set_form_height(height)
    }

    // Page resize height responsiveness
    useEffect(()=>{
        settableHeight(window.innerHeight - (heights_out + miscHeight -4));
    },[])

    // handle interface switch
    const toggle_staff_form = ():void =>{
        const view = searchParams.get('view');

        (view === null || view === formModeSearchParam) ? 
        navigate({
            pathname : setupPageRoute,
            search : createSearchParams({
                view : datagridOnlySearchParam,
            }).toString(),
        })
        :
        navigate({
            pathname : setupPageRoute,
            search : createSearchParams({
                view : formModeSearchParam,
            }).toString(),
        });
    }
    
    
    const[refresh,setrefresh]=useState<boolean>(false);
    const[selectedData,setSelectedData]=useState({});
    const[busyLoader,setBusyLoader]=useBusyLoader()

    //Table Height
    const pageHeight = window.innerHeight - (navbar_height + miscHeight) ; //height without navbar
    const [tableHeight,settableHeight] = useState(window.innerHeight); //height without navbar and toolbar

    const [getGridViewData,getGridViewData_Error,getGridViewData_Loading] = useFetch(`CorporateMeasures/SearchCorporateMeasure?corporateMeasure=${searchText.text}&activeStatus=${status}`,refresh,'cmsIDpk');
    console.log("getGridViewData",getGridViewData);
    useEffect(()=>{
        getGridViewData_Loading?setBusyLoader('.'):setBusyLoader('')
    },[getGridViewData_Loading])
   
    return (
        <>
            {getGridViewData_Error&&(
                <WarningAlert/>
            )}
            
            <div style={{height : pageHeight}} className="w-full">
                <div className="px-2">
                    <HrSetupsCustomToolbar
                    withDates={false}
                    outerSelected={selectedData}
                    handleNew={()=> setSelectedData({})}
                    toggler={toggle_staff_form}
                    searchTextOnchange={(txt)=>{setSearchText({temp:txt,text:searchText.text})}}
                    handleFind={()=>{
                        setSearchText({temp:searchText.temp,text:searchText.temp});
                        setStatus(activeStatus)
                        setrefresh(current=> !current)
                    }} 
                    />
            
                </div>
                
                {/* form section */}
                {(searchParams.get('view') !== datagridOnlySearchParam) &&
                <section style={{height : form_height}}  className="form border-b-4">
                    <Corporate_measures_form
                    selectedField={selectedData}
                    clearSelected={()=>setSelectedData({})} 
                    busyloader={(text)=>setBusyLoader(text??'')}
                    refreshGrid={()=>setrefresh((prev)=>(!prev))}                />
                </section>}

                {/* datagrid section */}
                <section className="mt-1 px-2">
                    <Datagrid_template 
                    gridheight= {searchParams.get('view') === formModeSearchParam || searchParams.get('view') === (null) ? 
                    window.innerHeight - (form_height + heights_out + miscHeight) : tableHeight} 
                    data = {getGridViewData} 
                    columns = {PerformanceSetups_CorporateMeasures_columns}
                    onRowClick={(selected)=>{
                    setSelectedData(selected);
                    
                    //disable Update Mode
                    dispatch(setGeneralValue({expr: 'updateMode' ,value : false}));

                    //disable forms
                    dispatch(setGeneralValue({expr: 'formDisabled' ,value : true}));
                    }}
                    rowDoubleClicked={()=>{
                        //disable Update Mode
                        dispatch(setGeneralValue({expr: 'updateMode' ,value : true}));

                        //disable forms
                        dispatch(setGeneralValue({expr: 'formDisabled' ,value : false}));
                    }}                 
                    />
                </section>
            </div>
        </>
    );
})
