import React, { useRef } from "react";
import {
  DataGrid,
  Column,
  Scrolling,
  Selection,
  Editing,
  SearchPanel,
  GroupPanel,
  HeaderFilter,
} from "devextreme-react/data-grid";
import { useDispatch } from "react-redux";

interface props {

  toggler?: () => void;
  customHeightHook?: any;
  columns?: any;
  gridheight?: number;
  data?: any;
  setState?: (key: string, value: any) => void;
  onChangeRecord?: (e: any) => void;
  updateMode?: (e: any) => void;
  onContentReady?: () => void

  deselectFirstRow?: boolean
}

export const Mini_datagrid_template = ({
  toggler,
  columns,
  gridheight,
  data,
  setState,
  onChangeRecord,
  updateMode,
  onContentReady,
  deselectFirstRow,
}: props) => {
  const dispatch = useDispatch()
  const dataGridRef = useRef<any>();

  const dateFormat = 'EEE, dd MMM yyyy HH:mm'

  return (
    <div className="">

      <div
        style={{ height: "" }}
        className="flex justify-between w-full h-full bg-slate-50 dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base"
      >
      </div>

      <div
        style={{ width: "100%" }}
        className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base"
      >
        <DataGrid
          allowColumnReordering={true}
          dataSource={data}
          height={gridheight}
          rowAlternationEnabled={true}
          columnResizingMode="widget"
          showBorders={true}
          showRowLines={true}
          allowColumnResizing={true}
          onRowClick={onChangeRecord}
          onRowDblClick={updateMode}
          onContentReady={(e) => {
            const dataGridInstance = dataGridRef.current?.instance;
            !deselectFirstRow && dataGridInstance?.selectRowsByIndexes([0]);
            onContentReady && onContentReady()
          }}
          wordWrapEnabled={false}
          focusedRowEnabled={false}
        >

          {columns.map((column: any) => {
            return (
              <Column
                key={column.id}
                dataField={column.dataField}
                caption={column.caption}
                dataType={column.dataType}
                width={column.width === null ? 100 : column.width}
                alignment="left"
                fixed={column.fixed}
                format={column.format ?? (column.dataType === 'datetime' ? dateFormat : (column.dataType === 'time' ? dateFormat : column.dataType))}

              />
            );
          })}

          <HeaderFilter visible={true} allowSearch={true} />
          <SearchPanel width={150} visible={true} />
          <GroupPanel visible={true} />

          <Editing mode={"row"} />
          <Scrolling
            useNative={false}
            scrollByContent={true}
            scrollByThumb={true}
            showScrollbar="always"
          />
          <Selection mode="single" />
        </DataGrid>
      </div>
    </div>
  );
};
