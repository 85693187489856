/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import { RefreshButton } from "../../../../StationReadings/_widget/utils";
import Datagrid_template from "../../../../../../../templates/Datagrid";
import useFetch from "../../../../../../../hooks/useFetch";

const Substations_columns = [
  { id: 1, caption: 'STAFF NO', dataField: 'empStaffNo', dataType: 'string', width: 100, fixed: true, visible: true },
  { id: 2, caption: 'STAFF NAME', dataField: 'empName', dataType: 'string', width: 200, fixed: false, visible: true },
  { id: 3, caption: 'PVAACTIVE?', dataField: 'pvaActive', dataType: 'boolean', width: 100, fixed: false, visible: true },
  { id: 4, caption: 'STATION PLC', dataField: 'stnPlc', dataType: 'string', width: 100, fixed: false, visible: true },
  { id: 5, caption: 'PVASHIFTLEADER?', dataField: 'pvaActive', dataType: 'boolean', width: 150, fixed: false, visible: true },
  { id: 6, caption: 'DEPARTMENT', dataField: 'dptShtName', dataType: 'string', width: 120, fixed: false, visible: true },
  { id: 7, caption: 'SECTION', dataField: 'sxnShtName', dataType: 'string', width: 120, fixed: false, visible: true },
  { id: 8, caption: 'STATION CONTACT', dataField: 'stnContact', dataType: 'string', width: 180, fixed: false, visible: true },
  { id: 9, caption: 'REMARKS', dataField: 'rmks', dataType: 'string', width: 1000, fixed: false, visible: true },
];

export default function SubstationsDirectory({ height }: { height: number }) {
  const [refresh, setRefresh] = useState(false);
  const [data, error, loading] = useFetch(`ELogBook/PopDgvSstDirectory`, refresh, '', false, "powerapp");

  return (
    <main>
      <div className="w-full h-auto border-[1px] pr-1 flex justify-end">
        <RefreshButton onClick={()=>{
          setRefresh(current => !current)
        }}/>
      </div>

      {error && (
        <div className=" flex h-full justify-center items-center mt-10 mb-10">
          <p className=' font-bold text-red-800'>Error Fetching Data. Plase Refresh</p>
        </div>
      )}

      {loading && (
        <div className=" flex h-full justify-center items-center mt-10 mb-4">
          <p className=' font-bold text-gray-800'>Fetching Data...</p>
        </div>
      )}

      {(!loading && !error) && (
      <Datagrid_template
        columns={Substations_columns}
        data={data}
        disablePaging
        disableGroupPanel
        gridheight={height}
      />)}
    </main>
  );
}
