import React, { createContext, useId } from "react";
import MyRoutes from "./routers";
import "devextreme/dist/css/dx.light.css";
import ConfigProvider from "antd/es/config-provider";
import { useSelector } from "react-redux";
import { RootState } from "./app/store";
import { useThemes } from "./functions/themes";

export const ThemeProvider = createContext<any | undefined | null>(null);

const App = () => {
  const idx = useId();
  useThemes()
  
  const borderTheme = useSelector((state:RootState)=>state.general?.cssBorderClass)
  const borderHoverTheme = useSelector((state:RootState)=>state.general?.cssBorderHover)
  const borderBgTheme = useSelector((state:RootState)=>state.general?.cssBorderBg)

  return (
    <>
      <ConfigProvider
        theme={{token: { borderRadius: 5, colorPrimary: borderHoverTheme ?? "#3385ff", colorBorder: borderTheme ?? "#cccc" }}}
      >
        <div className=" text-base text-neutral-9000 " id={idx}>
          <MyRoutes />
        </div>
      </ConfigProvider>
    </>
  );
};

export default App;
