/* eslint-disable react/jsx-pascal-case */
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import useFetch from "../../../../../../hooks/useFetch";
import Setups_Template from "../../../../../human-resource/setups/.setups_templates/Setups_Template";
import {
  datagridTypes,
  exprsTypes,
  modalTypes,
  searchTextTypes,
} from "../../../../../human-resource/setups/data/types/selectsTypes";
import {
  assets_assignedColumns,
  assets_requestAreasColumns,
  assets_requestClassColumns,
  assets_unassignedColumns,
} from "./data/vehicleClassAssignmentsData";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../../../app/store";
import {
  setValue,
  tier1SaveOnclick,
  tier1UpdateOnclick,
} from "../../../../../../features/forms/tier1/createSlice";
import { setGeneralValue } from "../../../../../../features/generalSlice";
import {
  validationsData,
  saveModalData,
  updateModalData,
  deleteModalData,
  currentDate,
} from "../../../../../accessories/component_infos";
import { ModalTemplate } from "../../../../../../templates/modal";
import CustomLoader from "../../../../../accessories/custom-loader";
import { WarningAlert } from "../../../../../accessories/warningAlert";
import { useFormPriviledge } from "../../../../../../hooks/useFormPriviledge"; 
import { GetDataFunc } from "../../../../../../functions/get";
import { useCrudFunc } from "../../../../../../functions/crud";
import { useAccountInfo } from "../../../../../../hooks/useAccountInfo";

export const Assets_setups_vehicleClassAssignments = forwardRef(
  ({ a }: any, ref) => {
    // on page resize height responsiveness
    useImperativeHandle(ref, () => ({
      test: () => {
        setTrigger(!trigger);
      },
    }));
    const [userid] = useAccountInfo()
    const postUrl: string =
      "VehicleAndVehicleClassesAssignments/CreateAsmTmsVehicleRequestClass";
    const updateUrl: string =
      "AsmTmsVehicleRequestClasses/UpdateAsmTmsVehicleRequestClass";

    const saveSuccessText = {
      message: "Record Saved Successfully",
      ok: "Ok",
      title: "Success",
    }; // successful save modal message
    const updateSuccessText = {
      message: "Record Updated Successfully",
      ok: "Ok",
      title: "Success",
    }; // successful update modal message

    // store data expressions[unique] ...dependent on data coming in
    const idExpr: string = "vrcIdpk";
    const nameExpr: string = "vrcName";
    const shortnameExpr: string = "vrcShtName";
    const orderExpr: string = "vrcOrder";
    const activeExpr: string = "vrcActive";
    const remarksExpr: string = "vrcRmks";
    const inputsExprsData: any = {
      idExpr: idExpr,
      nameExpr: nameExpr,
      shortnameExpr: shortnameExpr,
      orderExpr: orderExpr,
      activeExpr,
      remarksExpr: remarksExpr,
    };
    const [PostDataFunc, UpdateDataFunc] = useCrudFunc();
    const storeExprs = () => {
      const exprs = [
        { expr: "idExpr", value: idExpr },
        { expr: "nameExpr", value: nameExpr },
        { expr: "shortnameExpr", value: shortnameExpr },
      ];
      //to particular redux store based on form type
      for (let i = 0; i < exprs.length; i++) {
        dispatch(setValue({ expr: exprs[i].expr, value: exprs[i].value }));
      }

      // to general slice
      const generalExprs: exprsTypes[] = [
        { expr: "inputExprs", value: inputsExprsData },
      ];
      for (let i = 0; i < generalExprs.length; i++) {
        dispatch(
          setGeneralValue({
            expr: generalExprs[i].expr,
            value: generalExprs[i].value,
          })
        );
      }
    };

    const dispatch = useDispatch();
    const [refresh, setRefresh] = useState<boolean>(false); //refresh data state for data creation or update
    const [openAccessModal, setOpenAccessModal] = useState(false);
    const [selectedData, setSelectedData] = useState<any>({});
    const [title, setTitle] = useState("No Access");
    const [message, setMessage] = useState("");
    const [icon, setIcon] = useState<
      "success" | "warning" | "question" | undefined
    >("warning");
    const [vra, setVra] = useState<any>({id: 0, name: ""});
    const [vrc, setVrc] = useState<any>({id: 0, name: ""});
    const [rClassRefresh, setRClassRefresh] = useState<boolean>();
    const [rAreasRefresh, setRAreasRefresh] = useState<boolean>();
    const [AssignedRefresh, setAssignedRefresh] = useState<boolean>();
    const [UnassignedRefresh, setUnassignedRefresh] = useState<boolean>();
    //modal states
    //--general--
    const triggerSuccessModal = useSelector(
      (state: RootState) => state.tier1Add.successModalState
    ); //trigger success modal
    const SuccessModalText = useSelector(
      (state: RootState) => state.tier1Add.successText
    ); //success modal text

    //busy loader
    const [busyLoader, setBusyLoader] = useState<string>("");

    //--for backend errors--
    const [err, setErr] = useState<{
      state: boolean;
      title: string;
      message: string;
    }>({
      state: false,
      title: "",
      message: "",
    });

    //--warning modal--
    const triggerwarningModal = useSelector(
      (state: RootState) => state.tier1Add.warningState
    ); //trigger warning
    const warningInfos: any = validationsData; //warning type details
    const warningDataExpr = useSelector(
      (state: RootState) => state.tier1Add.warningDataExpr
    ); //warning type selector

    //--save data modal--
    const saveData = saveModalData; //save modal details[data]
    const triggersaveModal = useSelector(
      (state: RootState) => state.tier1Add.saveDataOnclick
    ); //trigger save modal

    //--Update data modal--
    const updateIndInfos = updateModalData; //update modal details[data]
    const triggerupdateModal: boolean = useSelector(
      (state: RootState) => state.tier1Add.updateDataOnclick
    ); //trigger update question

    // on page resize height responsiveness
    const [trigger, setTrigger] = useState<boolean>(false);
    useImperativeHandle(ref, () => ({
      test: () => {
        setTrigger(!trigger);
      },
    }));

    // get current values from  [slice dependent on form type]
    const name = useSelector((state: RootState) => state.tier1Add.name);
    const shortname = useSelector(
      (state: RootState) => state.tier1Add.shortname
    );
    const order = useSelector((state: RootState) => state.tier1Add.order);
    const active = useSelector((state: RootState) => state.tier1Add.active);
    const remarks = useSelector((state: RootState) => state.tier1Add.remarks);
    const selectedID = useSelector(
      (state: RootState) => state.general.SelectedFieldID
    );

    const refreshGrid = (type: string) => {
      if (type === "rClass") {
        setRClassRefresh((current) => !current);
      } else if (type === "rAreas") {
        setRAreasRefresh((current) => !current);
      } else if (type === "AssignedV") {
        setAssignedRefresh((current) => !current);
      } else if (type === "UnassignedV") {
        setUnassignedRefresh((current) => !current);
      }
    };

    //post form Data
    //validate data
    const validateDataSave = () => {
      dispatch(tier1SaveOnclick());
    };
    // post
    const postData = async () => {
      dispatch(setValue({ expr: "saveDataOnclick", value: false })); //disable modal

      try {
        setBusyLoader("Submitting request");
        await PostDataFunc (`${postUrl}`, {
          vrcName: name,
          vrcShtName: shortname,
          vrcActive: active,
          vrcRmks: remarks,
          vrcOrder: order,
          vrcCreatedBy: 1,
        });

        setRClassRefresh((prev) => !prev);
        setBusyLoader(""); //disable busy loader

        const exprs: exprsTypes[] = [
          { expr: "saveDataOnclick", value: false },
          { expr: "successModalState", value: true },
          { expr: "successText", value: saveSuccessText },
        ];
        for (let i = 0; i < exprs.length; i++) {
          dispatch(setValue({ expr: exprs[i].expr, value: exprs[i].value }));
        }

        //disable form
        dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      } catch (error: any) {
        setBusyLoader("");

        //set states for db errors modal
        setErr({
          state: true,
          title: error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Server Error"
            : "Server Error",
          message: error.response
            ? error.response.data.errors
              ? error.response.data?.errors[0]
              : "Something went wrong.Please contact system administrator"
            : "Error connecting to server. Please contact system administrator",
        });
      }
    };

    //Update Form data
    //validate data
    const validateDataUpdate = () => {
      dispatch(tier1UpdateOnclick());
    };
    // update
    const UpdateData = async () => {
      dispatch(setValue({ expr: "updateDataOnclick", value: false })); //disable modal
      try {
        setBusyLoader("Updating record");
        await UpdateDataFunc(`${updateUrl}`, {
          vrcIdpk: selectedID,
          vrcName: name,
          vrcShtName: shortname,
          vrcActive: active,
          vrcRmks: remarks,
          vrcOrder: order,
          vrcEditedBy: 1,
          vrcEditedDate: "2023-04-04T16:02:43.891Z",
        },
          `Update Vehicle & Vehicle Class Assignment with ID ${selectedID} to Name ${name} and Short Name ${shortname}. Date: ${currentDate} by ${userid}`
        );

        // refresh datagrid data
        setRClassRefresh((prev) => !prev);

        setBusyLoader(""); //disable busy loader

        //disable update mode
        dispatch(setGeneralValue({ expr: "updateMode", value: false }));

        const exprs: exprsTypes[] = [
          { expr: "updateDataOnclick", value: false },
          { expr: "successModalState", value: true },
          { expr: "successText", value: updateSuccessText },
        ];
        for (let i = 0; i < exprs.length; i++) {
          dispatch(setValue({ expr: exprs[i].expr, value: exprs[i].value }));
        }
      } catch (error: any) {
        setBusyLoader(""); //disable busy loader

        //set states for db errors modal
        setErr({
          state: true,
          title: error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Server Error"
            : "Server Error",
          message: error.response
            ? error.response.data.errors
              ? error.response.data?.errors[0]
              : "Something went wrong.Please contact system administrator"
            : "Error connecting to server. Please contact system administrator",
        });
      }
    };

    //modals data
    const modals: modalTypes[] = [
      //custom modal
      {
        disableCancel: true,
        icon: icon,
        open: openAccessModal,
        okHandler: () => {
          setOpenAccessModal(false);
        },
        cancelHandler: () => {
          setOpenAccessModal(false);
        },
        message: message,
        cancelText: "No",
        okText: "Ok",
        title: title,
      },
      //warning
      {
        disableCancel: true,
        icon: "warning",
        open: triggerwarningModal,
        okHandler: () =>
          dispatch(setValue({ expr: "warningState", value: false })),
        cancelHandler: () =>
          dispatch(setValue({ expr: "warningState", value: false })),
        message: warningInfos[warningDataExpr!].message,
        cancelText: warningInfos[warningDataExpr!].cancelText,
        okText: warningInfos[warningDataExpr!].okText,
        title: warningInfos[warningDataExpr!].title,
      },
      //backend error
      {
        disableCancel: true,
        icon: "warning",
        open: err.state,
        okHandler: () => setErr((prev) => ({ ...prev, state: false })),
        cancelHandler: () => setErr((prev) => ({ ...prev, state: false })),
        message: err.message,
        cancelText: "",
        okText: "Ok",
        title: err.title,
      },
      //success
      {
        disableCancel: true,
        icon: "success",
        open: triggerSuccessModal,
        okHandler: () =>
          dispatch(setValue({ expr: "successModalState", value: false })),
        cancelHandler: () =>
          dispatch(setValue({ expr: "successModalState", value: false })),
        message: SuccessModalText.message,
        okText: SuccessModalText.okText,
        title: SuccessModalText.title,
      },
      //save
      {
        disableCancel: false,
        icon: "question",
        open: triggersaveModal,
        okHandler: () => postData(),
        cancelHandler: () =>
          dispatch(setValue({ expr: "saveDataOnclick", value: false })),
        message: "Are you sure you want to save this record?",
        cancelText: saveData.cancelText,
        okText: saveData.okText,
        title: saveData.title,
      },
      //Update
      {
        disableCancel: false,
        icon: "question",
        open: triggerupdateModal,
        okHandler: () => UpdateData(),
        cancelHandler: () =>
          dispatch(setValue({ expr: "updateDataOnclick", value: false })),
        message: updateIndInfos.message,
        cancelText: updateIndInfos.cancelText,
        okText: updateIndInfos.okText,
        title: updateIndInfos.title,
      },
    ];

    const [searchText, setSearchText] = useState<searchTextTypes>({
      temp: "",
      text: "",
    });
    const activeStatus = useSelector(
      (state: RootState) => state.general.activeStatus
    );
    const [status, setstatus] = useState<boolean | string>("");

    // data for datagrid
    // const [requestClassesData, requestClassesErr, requestClassesLoading] =
    //   useFetch(
    //     `VehicleAndVehicleClassesAssignments/GetAllAsmTmsVehicleRequestClasses?vrcName=${searchText.text}&status=${status}`,
    //     rClassRefresh,
    //     "vrcIdpk"
    //   );
    const [requestClassesData, requestClassesErr, requestClassesLoading] =
      useFetch(
        `VehicleAndVehicleClassesAssignments/GetAllAsmTmsVehicleRequestClasses?pagenumber=1&pagesize=20&search=${searchText.text}&status=${status}`,
        rClassRefresh,
        "vrcIdpk"
      );
    useEffect(() => {
      if (requestClassesData) {
        storeExprs(); // run func to store expressions on store
        dispatch(setGeneralValue({ expr: "idExpr", value: idExpr })); // dispatch fetched data to reduxstore
        dispatch(
          setGeneralValue({ expr: "formData", value: requestClassesData })
        ); // dispatch fetched data to reduxstore
      }
    }, [requestClassesErr, requestClassesData]);

    // data for datagrid
    // const [requestAreaData, error, loading] = useFetch(
    //   "VehicleAndVehicleClassesAssignments/GetAllAsmTmsVehicleRequestAreasxx",
    //   rAreasRefresh
    // );
    const [requestAreaData, error, loading] = useFetch(
      `VehicleAndVehicleClassesAssignments/GetAllAsmTmsVehicleRequestAreasxx?pagesize=20&pagenumber=1&search=${searchText.text}`,
      rAreasRefresh
    );

    // const [assignedData, assignedErr, assignedLoading] = useFetch(
    //   `VehicleAndVehicleClassesAssignments/GetAllAsmTmsVehicleRequestClassesVehicleAssignments?vraIDpk=${vra.id}&vrcIDpk=${vrc.name}`,
    //   AssignedRefresh
    // );
    const [assignedData, assignedErr, assignedLoading] = useFetch(
      `VehicleAndVehicleClassesAssignments/GetAllAsmTmsVehicleRequestClassesVehicleAssignments?vraIDpk=${vra.id}&vrcIDpk=${vrc.id}&pagesize=20&pagenumber=1&search=${searchText.text}`,
      AssignedRefresh
    );
    console.log(`&^% ee`, assignedData)
    // const [unassignedData, unassignedErr, unassignedLoading] = useFetch(
    //   `VehicleAndVehicleClassesAssignments/GetAllAsmTmsVehicleAssignmentsxx?vraIDpk=${vra.id}&vrcIDpk=${vrc.id}`,
    //   UnassignedRefresh
    // );
    const [unassignedData, unassignedErr, unassignedLoading] = useFetch(
      `VehicleAndVehicleClassesAssignments/GetAllAsmTmsVehicleAssignmentsxx?vraIDpk=${vra.id}&vrcIDpk=${vrc.id}&search=${searchText.text}&pagenumber=1&pagesize=20`,
      UnassignedRefresh
    );
    const [privType, setPrivType] = useState("save");
    const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);
    const datagridAccessPriv = (e: any) => {
      setPrivType("update");
      if (updatePriv === true) {
        dispatch(setGeneralValue({ expr: "updateMode", value: true }));
        dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      } else {
        setIcon("warning");
        setTitle("No Access!");
        setMessage("No Access Privilege");
        setOpenAccessModal(true);
      }
    };

    const handleVehicleUnassignedDblClick = async (e: any) => {
      if (vra.id > 0 && vrc.id > 0) {
        try {
          const res = await GetDataFunc(
            `VehicleAndVehicleClassesAssignments/GetAsmTmsVehicleRequestClassesVehicleAssignmentsExists?vcaRequestAreaIDfk=${vra.id}&vcaRequestClassIDfk=${vrc.id}&vcaVehicleDetailIDfk=${e.vdtIDpk}`
          );
          if (res.data > 0) {
            const updateRes = await UpdateDataFunc(
              `VehicleAndVehicleClassesAssignments/UpdateAsmTmsVehicleRequestClassesVehicleAssignmentSetActive`,
              {
                vcaRequestClassIdfk: vrc.id,
                vcaRequestAreaIdfk: vra.id,
                vcaVehicleDetailIdfk: e.data.vdtIDpk,
              },
              `Updated the Vehicle with Reg No ${e.data.vdtRegNo} with Vehicle Detail ID ${e.data.vdtIDpk} to the Request Class ${vrc.name} for the Request Area ${vra.name}`
            );
            if (!updateRes.data.success) {
              throw Error(
                "Unable to assign vehicle to class. Try again later."
              );
            } else {
              setAssignedRefresh((prev) => !prev);
              setUnassignedRefresh((prev) => !prev);
            }
          } else {
            const insertRes = await PostDataFunc (
              `VehicleAndVehicleClassesAssignments/CreateAsmTmsVehicleRequestClassesVehicleAssignment`,
              {
                vcaRequestClassIdfk: vrc.id,
                vcaRequestAreaIdfk: vra.id ,
                vcaVehicleDetailIdfk: e.data.vdtIDpk,
                vdtRegNo: e.data.vdtRegNo,
                vraName: vra.name,
                vrcName: vrc.name
              },
              `Added the Vehicle with Reg No ${e.data.vdtRegNo} with Vehicle Detail ID ${e.data.vdtIDpk} to the Request Class ${vrc.name} for the Request Area ${vra.name}`
            );
            if (!insertRes.data.success) {
              throw Error(
                "Unable to assign vehicle to class. Try again later."
              );
            } else {
              setAssignedRefresh((prev) => !prev);
              setUnassignedRefresh((prev) => !prev);
            }
          }
        } catch (error: any) {
          setIcon("warning");
          setTitle("Unexpected Error");
          setMessage(error.message ?? "Something went wrong. Try again later.");
          setOpenAccessModal(true);
        }
      } else {
        setIcon("warning");
        setTitle("Validation Error");
        setMessage(
          error.message ?? "Please select a vehicle class and a vehicle area"
        );
        setOpenAccessModal(true);
      }
    };
    const handleVehicleAssignedDblClick = async (e: any) => {
      if (vra.id > 0 && vrc.id > 0) {
        try {
          const res = await UpdateDataFunc(
            `VehicleAndVehicleClassesAssignments/UpdateAsmTmsVehicleRequestClassesVehicleAssignmentSetInActive`,
            {
              vcaRequestClassIdfk: vrc.id,
              vcaRequestAreaIdfk: vra.id,
              vcaVehicleDetailIdfk: e.data.vdtIDpk,
            },`Removed the Vehicle the Vehicle with Reg No ${e.data.vdtRegNo} with Vehicle Detail ID ${e.data.vdtIDpk} to the Request Class ${vrc.name} for the Request Area ${vra.name}`
          );
          if (!res.data.success) {
            throw Error(
              "Unable to remove vehicle from class. Try again later."
            );
          } else {
            setAssignedRefresh((prev) => !prev);
            setUnassignedRefresh((prev) => !prev);
          }
        } catch (error: any) {
          setIcon("warning");
          setTitle("Unexpected Error");
          setMessage(error.message ?? "Something went wrong. Try again later.");
          setOpenAccessModal(true);
        }
      } else {
        setIcon("warning");
        setTitle("Validation Error");
        setMessage(
          error.message ?? "Please select a vehicle class and a vehicle area"
        );
        setOpenAccessModal(true);
      }
    };

    const toolbarEditHandler = () => {
      if (Object.keys(selectedData).length === 0) {
        setTitle("Error");
        setMessage("Please select a record to edit");
        setOpenAccessModal(true);
        return;
      }
      dispatch(setGeneralValue({ expr: "updateMode", value: true }));
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
    };
    // form parameters
    const form_header = "Vehicle Classification Details";
    const datagrids: datagridTypes[] = [
      {
        id: 0,
        header: "Request Class",
        columns: assets_requestClassColumns,
        data: requestClassesData,
        styleId: "form_header2",
        dblClick: datagridAccessPriv,
        singleClick: (e) => {
          setSelectedData(e); 
          setVrc({id: e.vrcIdpk, name:e.vrcName});
        },
        toggleRefresh: refreshGrid,
        type: "rClass",
      },
      {
        id: 1,
        header: "Request Areas",
        columns: assets_requestAreasColumns,
        data: requestAreaData,
        styleId: "form_header3",
        singleClick: (e) => { 
          setVra({id: e.vraIdpk, name: e.vraName});
        },
        toggleRefresh: refreshGrid,
        type: "rAreas",
      },
      {
        id: 2,
        header: "Unassigned Vehicles",
        columns: assets_unassignedColumns,
        data: unassignedData,
        styleId: "form_header4",
        toggleRefresh: refreshGrid,
        type: "UnassignedV",
        dblClick: handleVehicleUnassignedDblClick,
      },
      {
        id: 3,
        header: "Assigned Vehicles",
        columns: assets_assignedColumns,
        data: assignedData,
        styleId: "form_header5",
        toggleRefresh: refreshGrid,
        type: "AssignedV",
        dblClick: handleVehicleAssignedDblClick,
      },
    ];

    return (
      <>
        {error && assignedErr && unassignedErr && <WarningAlert />}
        {modals.map(
          (
            {
              disableCancel,
              icon,
              okHandler,
              cancelHandler,
              open,
              cancelText,
              okText,
              message,
              title,
            }: modalTypes,
            index
          ) => {
            return (
              <div key={index}>
                <ModalTemplate
                  icon={icon && icon}
                  disableCancel={disableCancel}
                  cancelText={cancelText && cancelText}
                  open={open}
                  okHandler={okHandler}
                  cancelHandler={cancelHandler}
                  message={message}
                  okText={okText}
                  title={title}
                />
              </div>
            );
          }
        )}
        {requestClassesLoading && <CustomLoader />}
        <Setups_Template
          searchTextChange={(newText) => {
            setSearchText((prev) => ({ ...prev, temp: newText }));
          }}
          handleSearch={() => {
            setSearchText((prev) => ({ ...prev, text: prev.temp }));
            setstatus(activeStatus);
          }}
          handleRefresh={() => {
            setSearchText({ temp: "", text: "" });
            setRefresh((current) => !current);
          }}
          handleEdit={() => toolbarEditHandler}
          multipleDatagridsWidth={"24.8%"}
          additionalHeights={30}
          multipleDatagrids={datagrids}
          trigger={trigger}
          datagridData={[]}
          formHeader={form_header}
          updateData={validateDataUpdate}
          createData={validateDataSave}
          handleNew={() => {
            setSelectedData({});
          }}
          handleFormNew={() => {
            setSelectedData({});
          }}
          selectedRecordX={selectedData}
        />
      </>
    );
  }
);
