import { TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/tab-panel";
import React, { useState } from "react";
import { LoginDetails } from "./widgets/loginDetails";
import { HrSetupsCustomToolbar } from "../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import { AuditTrailSum } from "./widgets/auditTrailSum";
import { AuditTrailDets } from "./widgets/auditTrailDets";
import { AuditTrailDetsSum } from "./widgets/auditTrailDetsSum";
import dayjs from "dayjs";
import {
  searchCriteriaTypes,
  searchTextTypes,
  statusTypes,
} from "../../human-resource/setups/data/types/selectsTypes";
import AuditTrailDailyCount from "./widgets/auditTrailDailyCount";
import { GetDataFunc } from "../../../functions/get";
import useFetch from "../../../hooks/useFetch";
import AuditTrailUserAccounts from "./widgets/auditTrailUserAccounts";
import AuditUniqueLogins from "./widgets/auditNonUniqueLogins";
import AuditNonUniqueLogins from "./widgets/auditNonUniqueLogins";
import AuditUniqueNonUniqueCounts from "./widgets/auditUnique_Non-Unique";

export const AuditTrail = () => {


  const tabs: { id: number; title: string; contents: any }[] = [
    { id: 0, title: "Login Details", contents: <LoginDetails /> },
    { id: 1, title: "Audit Trail Summary", contents: <AuditTrailSum /> },
    { id: 2, title: "Audit Trail Details", contents: <AuditTrailDets /> },
    { id: 3, title: "Login Details Summary", contents: <AuditTrailDetsSum /> },
    { id: 4, title: "Daily Audit Trail Count", contents: <AuditTrailDailyCount /> },
    { id: 5, title: "Audit Trail for User Account", contents: <AuditTrailUserAccounts /> },
    { id: 6, title: "Unique Login Counts", contents: <AuditUniqueLogins /> },
    { id: 7, title: "Non-unique Login Counts", contents: <AuditNonUniqueLogins /> },
    { id: 8, title: "Unique-Non-unique Logins", contents: <AuditUniqueNonUniqueCounts /> },
  ];


  return (
    <div className="w-full h-full px-0.5">
      {/* toolbar */}
      <></>

      <TabPanel showNavButtons={true} className="w-full h-full pt-1 ">
        {tabs.map(({ id, title, contents }) => {
          return (
            <Item key={id} title={title}>
              <div className="w-full h-full  pt-1">{contents}</div>
            </Item>
          );
        })}
      </TabPanel>
    </div>
  );
};
