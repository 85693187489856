import { Form, Radio, RadioChangeEvent } from "antd";
import { useState } from "react";
import correct from "../../../../../../../../assets/correct.png";
import { DateTemplate } from "../../../../../../../../templates/date";
import { InputsTemplate } from "../../../../../../../../templates/input";
import { SelectsTemplate } from "../../../../../../../../templates/select";
import { TextareaTemplate } from "../../../../../../../../templates/textarea";
import { UpdateButton } from "../../../../../../../accessories/buttons";
import useFetch from "../../../../../../../../hooks/useFetch";
import dayjs, { Dayjs } from "dayjs";
import {
  currentDate,
  saveModalData,
} from "../../../../../../../accessories/component_infos";
import { ModalTemplate } from "../../../../../../../../templates/modal";
import { useCrudFunc } from "../../../../../../../../functions/crud";

interface prop {
  refreshState?: () => void;
}

interface modalTypes {
  info: any;
  open: boolean;
  icon: "warning" | "question" | "success";
  disableCancel?: boolean;
}

export const VehicleKeyForm = ({ refreshState }: prop) => {
  const initialStates = {
    returnDate: currentDate,
    selectedId: 0,
    allPickedUpBy: { name: "", id: 0 },
    allReturnedBy: { name: "", id: 0 },
    remarks: "",
    active: true,
    refresh: false,
    startOdometer: "",
    currentOdometer: "",
  };

  const [inspectionType, setInspectionType] = useState<"pickup" | "return">("pickup");

  const handleInspectionTypeChange = (e: RadioChangeEvent) => {
    setInspectionType(e.target.value === 1 ? "pickup" : "return");
  };

  const [modalData, setModalData] = useState<modalTypes>({
    info: saveModalData,
    open: false,
    icon: "warning",
    disableCancel: false,
  });
  const [Icon, setIcon] = useState<"warning" | "question" | "success">(
    "warning"
  );

  const [states, setStates] = useState<any>(initialStates);

  const setState = (key: string, value: any) => {
    setStates((prev: any) => ({ ...prev, [key]: value }));
  };
  const [getAllPickedBy] = useFetch(
    `AsmTmsVehicleRequestKeyPicks/GetAllKeysPickedBy`
  );
  const [getAllReturnedBy] = useFetch(
    `AsmTmsVehicleRequestKeyPicks/GetAllKeysReturnedBy`
  );

  const [posting, updating] = useCrudFunc();

  const [value, setValue] = useState(1);

  const onChange = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const validateKeyForm = () => {
    if (states.allPickedUpBy.id === 0) {
      setIcon("warning");
      setModalData({
        ...modalData,
        info: {
          message: "Please select a user to pickup the key",
          title: "Required Field",
          okText: "ok",
        },
        icon: "warning",
        open: true,

        disableCancel: true,
      });
      return false;
    }
    if (states.allReturnedBy.id === 0) {
      setIcon("warning");
      setModalData({
        ...modalData,
        info: {
          message: "Please select a user to return the key",
          title: "Required Field",
          okText: "ok",
        },
        icon: "warning",
        open: true,

        disableCancel: true,
      });
      return false;
    }
    if (states.returnDate === "") {
      setIcon("warning");
      setModalData({
        ...modalData,
        info: {
          message: "Please select a return date",
          title: "Required Field",
          okText: "ok",
        },
        icon: "warning",
        open: true,

        disableCancel: true,
      });
      return false;
    }
}

const handleUpdateConfirmation = () => {
  setModalData({
    ...modalData,
    info: {
      message: "Are you sure you want to update the record?",
      title: "Confirmation",
      okText: "Yes",
    },
    icon: "question",
    open: true,
    disableCancel: false,
  });
};

const handleUpdate = async () => {
  if (modalData.open && modalData.info.okText === "Yes") {
    await updatePickupData();
  } else {
    setModalData({ ...modalData, open: false });
  }
};

  const updatePickupData = async () => {
    try {
      await updating(
        `AsmTmsVehicleRequestKeyPicks/UpdateAsmTmsVehiclRequestKeyPickupsKeyAction?vrqIDpk=${states.selectedId}`,
        {
          vrqKeyOutRmks: states.remarks,
          vrqStartOdometerReading: 0,
          vrqFinalOdometerReading: 2,
          vrqVehicleKeyOut: true,
          vrqKeyPickedByEmpIdfk: states.selectedId,
          vrqKeyPickedOn: states.returnDate,
          vrqEditedBy: 0,
          vrqEditedDate: states.returnDate,
        },
        ""
      );
      setModalData({
        ...modalData,
        open: false,
      });
  
      refreshState && refreshState();
      setIcon("success");
      setModalData({
        ...modalData,
        info: {
          message: "Record updated Successfully",
          title: "Success",
          okText: "ok",
        },
        icon: "success",
        open: true,
        disableCancel: true,
      });
    } catch (err) {
      setIcon("warning");
      setModalData({
        ...modalData,
        info: {
          message: "An unexpected error occurred",
          title: "Unexpected Error!",
          okText: "ok",
        },
        icon: "success",
        open: true,

        disableCancel: true,
      });
    }
  };

  const updateReturnData = async () => {
    await updating(
      `AsmTmsVehicleRequestKeyPicks/UpdateAsmTmsVehiclRequestKeyReturnKeyAction?vrqIDpk=${states.selectedId}`,
      {
        vrqKeyReturnRmks: states.remarks,
        vrqStartOdometerReading: 0,
        vrqFinalOdometerReading: 2,
        vrqVehicleKeyReturned: true,
        vrqKeyReturnedByEmpIdfk: states.selectedId,
        vrqKeyReturnedOn: states.returnDate,
        vrqEditedBy: 0,
        vrqActualReturnTime: states.returnDate,
        vrqEditedDate: states.returnDate,
      },
      ""
    );
    setModalData({
      ...modalData,

      open: false,
    });

    refreshState && refreshState();
    setIcon("success");
    setModalData({
      ...modalData,
      info: {
        message: "Record updated Successfully",
        title: "Success",
        okText: "ok",
      },
      icon: "success",
      open: true,

      disableCancel: true,
    });
  };

  return (
    <div className=" ">
      <ModalTemplate
        icon={modalData.icon}
        disableCancel={modalData.disableCancel}
        open={modalData.open}
        okHandler={() => {
          handleUpdate();
          setModalData({ ...modalData, open: false });
      }}
        cancelHandler={() => {
          setModalData({ ...modalData, open: false });
        }}
        message={modalData.info.message}
        okText={modalData.info.okText}
        title={modalData.info.title}
      />
      <section className="px-2">
        <div className="font-semibold bg-slate-100 px-1">Key Management</div>
        {/* Action */}
        <section>
          <Form name="basic" size="small" autoComplete="off">
            <Form.Item
              label="Action"
              className="bg-yellow-200 px-2 py-1"
              style={{ paddingLeft: "135px" }}
            >
              <div className="">
                <div className="flex ">
                  <div
                    className="border rounded px-2  bg-white"
                    style={{ maxWidth: "350px" }}
                  >
                    <Radio.Group
                    onChange={handleInspectionTypeChange}
                    value={inspectionType === "pickup" ? 1 : 2}
                    className="flex justify-between"
                  >
                    <Radio value={1}>Key Pickup</Radio>
                    <Radio value={2}>Key Return</Radio>
                  </Radio.Group>
                  </div>
                </div>
              </div>
            </Form.Item>
          </Form>
        </section>
      </section>

      <div className="mx-2 py-2 flex bg-yellow-100 ">
        <section>
          <Form
            className="w-full rounded flex flex-col "
            name="basic"
            labelCol={{ span: 10 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            size="small"
          >
            <SelectsTemplate
              idexpr={"empIdpk"}
              dataexp={"empName"}
              label="Pickup By"
              placeHolder={states.allPickedUpBy.name}
              selectedValue={(e: any) => {
                setState("allPickedUpBy", {
                  id: e.empIdpk,
                  name: e.empName,
                });
              }}
              options={getAllPickedBy}
              disabled={inspectionType === "return"}
              useCallFunc={true}
              style={{ width: 450 }}
              selectStyle={{ width: 450 }}
              handleRefresh={() => {
                setState("allPickedUpBy", { id: 0, name: "" });
                setState("refresh", !states.refresh);
              }}
            />
            <SelectsTemplate
              idexpr={"empIdpk"}
              dataexp={"empName"}
              label="Returned By"
              placeHolder={states.allReturnedBy.name}
              selectedValue={(e: any) => {
                setState("allReturnedBy", {
                  id: e.empIdpk,
                  name: e.empName,
                });
              }}
              options={getAllReturnedBy}
              disabled={inspectionType === "pickup"}
              useCallFunc={true}
              style={{ width: 450 }}
              selectStyle={{ width: 450 }}
              handleRefresh={() => {
                setState("allReturnedBy", { id: 0, name: "" });
                setState("refresh", !states.refresh);
              }}
            />
            <DateTemplate
              label="Actual Return Time"
              selectedDate={dayjs(states.returnDate)}
              changeDate={(date: Dayjs) => {
                setState("returnDate", date.format());
              }}
              showTime={true}
              width={250}
              span={false}
              style={{ paddingBottom: 0, paddingTop: 0 }}
              disabled={inspectionType === "pickup"}
            />
            <InputsTemplate
              nameExpr="vrqStartOdometerReading"
              label="Start Odometer Reading"
              defaultValue={states.startOdometer}
              useCallbackFunc={true}
              disabledStatus={false}
            />
            <InputsTemplate
              nameExpr="vrqFinalOdometerReading"
              label="Final Odometer Reading"
              defaultValue={states.currentOdometer}
              useCallbackFunc={true}
              disabledStatus={false}
            />

            <Form.Item label={<p></p>} style={{ width: "450px" }} colon={false}>
              <div
                className=" text-indigo-500  rounded text-xs font-bold "
                style={{ maxWidth: "240px" }}
              >
                *Distance: {states.currentOdometer - states.startOdometer}
              </div>
            </Form.Item>
          </Form>
        </section>

        <section className="ml-4">
          <Form
            className="w-full rounded flex flex-col"
            name="basic"
            labelCol={{ span: 8 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            size="small"
          >
            <TextareaTemplate
              label="Remarks"
              defaultValue={states.remarks}
              setCallbackValue={(e: any) => {
                setState("remarks", e);
              }}
              useCallbackFunc={true}
              height={80}
              MarginLeft={"75px"}
              width={"240px"}
              disabled={false}
            />
            <InputsTemplate
              label="User's Password"
              disabledStatus={false}
              useCallbackFunc={true}
              style={{ width: 400 }}
              span
              additionalWidget={
                <>
                  <span
                    onClick={() => {}}
                    style={{
                      height: 22.5,
                      marginBottom: 2,
                      borderWidth: 1,
                      width: 24,
                    }}
                    className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                  >
                    <img
                      className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                      src={correct}
                      alt="correct"
                    />
                  </span>
                </>
              }
              inputStyle={{ width: 450 }}
            />
            <Form.Item
              label=""
              colon={false}
              style={{ width: "450px", marginLeft: "75px" }}
            >
              <div
                className="flex justify-end  rounded "
                style={{ maxWidth: "297px" }}
              >
                <UpdateButton handleUpdate={handleUpdateConfirmation} />
              </div>
            </Form.Item>
          </Form>
        </section>
      </div>
    </div>
  );
};
