import React, { useState, useCallback,useRef } from 'react'
import {Form, Input, Select,DatePicker } from 'antd';
import { CheckBox } from 'devextreme-react/check-box';
import reset from '../../../../../../assets/reset.png'
import { Cancel, NewButton, SaveButton, SignOff, UpdateButton } from '../../../../../accessories/buttons';
import { BiggerPicture_Select, } from '../data/Performance_selects';
import { inputsTypes, selectsTypes } from '../../../../../human-resource/setups/data/types/selectsTypes';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../../app/store';
import { saveModalData, updateModalData } from '../../../../../accessories/component_infos';
import dayjs from 'dayjs'
import { ModalTemplate } from '../../../../../../templates/modal';
import { TextareaTemplate } from '../../../../../../templates/textarea';



interface stateTypes {
    mission ?: string;
    theme ?: string;
    objective ?: string;
    the_picture ?: string;
    remarks ?: string;
    
}


export const BiggerPicture_Form_Details = () =>{
    const { Option } = Select;
    const { TextArea } = Input;

    // import { AssignedDept, AssignedLocation, AssignedSelection, AssignedUnit, Batch, Group, PPE_Select, RequestWindow, StaffName } from './PPE_selects';

    // from redux
    const form_disable = useSelector((state:RootState)=>state.general.formDisabled)
    const update_state_change = useSelector((state:RootState) => state.general.taskFooterUpdate)
    const borderTheme = useSelector((state:RootState) => state.general.cssBorderClass)


    // state initialization
    const [states, setStates] = useState<stateTypes>({
        mission : "",
        theme : "",
        objective : "",
        the_picture : "",
        remarks : "",
    })

    // state function
    const setState = (key:string, value:string) =>{
        setStates({...states, [key]:value})
    }

    const options = [];
    for (let i = 1; i < 36; i++) {
    options.push({
        label: i.toString(36) + i,
        value: i.toString(36) + i,
    });
    }

    const [textAreaHeight , settextAreaHeight] = useState<string>('75px')

    const handleChange = (value: string[]) => {
    console.log(`selected ${value}`);
        if(value.length > 7 ) {
            settextAreaHeight('60px')
        }else {
            settextAreaHeight('71px')
        }
    };
    const dateFormat = " DD MMM YYYY HH: mm:ss";



    const taskDetailsFormHeight = useRef(null);

    const selects:selectsTypes[] = [
        {id: 0, label : 'Mission\Vision', optionsData : [{id : 0, data : 'option1'}], nameKey:"", idKey:"", stateName:'', defaultValue:''},
        {id: 1, label : 'Theme', optionsData : [{id : 0, data : 'option1'}], nameKey:"", idKey:"", stateName:'', defaultValue:''},
        {id: 2, label : 'Objective', optionsData : [{id : 0, data : 'option1'}], nameKey:"", idKey:"", stateName:'', defaultValue:''},

        
    ]

    const textareas : inputsTypes[] = [
        {id: 0, label : 'The Picture', style:{height:80, marginLeft:39}, stateName:'', defaultValue:''},
        {id: 1, label : 'Remarks',style:{height:105, marginLeft:50}, stateName:'', defaultValue:''},
    ]


    //  Current date
    const editedDate = dayjs().format()
    // console.log(editedDate)
 
    // Modal dialog data states
 
    const [refreshValue, setRefreshValue] = useState (false);
    const [showModalDisableButton, setshowModalDisableButton] = useState(false) ;
    const [openModal, setOpenModal] = useState(false);
    const [modalData, setModalData] = useState<any> (saveModalData) ; 
    const [icon, setIcon] = useState< 'warning' | 'success' | 'question'>();
    const [updatingstatus, setUpdatingstatus] = useState (false);
    const [showCancel, setshowCancel] = useState(false);
    

    // Validation 

const validationForms = async () => {
    setIcon('warning')
    if('' === ''){
        setshowModalDisableButton(true);
        setModalData({message: "Please select data entry for Profession", title:" for Profession ",okText: "Ok"});
        setOpenModal (true);
        
    }else if ('' === ""){
        setshowModalDisableButton(true);
        setModalData({message: "Please enter data for Contact Person", title:"Data entry for Contact Person",okText: "Ok"});
        setOpenModal (true);
    }else if('' === ""){
        setshowModalDisableButton(true);
        setModalData({message: "Please enter data for Location", title:"Data entry for Location",okText: "Ok"});
        setOpenModal (true);
    }else if('' === ""){
        setshowModalDisableButton(true);
        setModalData({message: "Please enter data for Name", title:"Data entry for Name",okText: "Ok"});
        setOpenModal (true);
    }
    else if('' === ""){
      setshowModalDisableButton(true);
      setModalData({message: "Please enter data for Short Name", title:"Data entry for Short Name",okText: "Ok"});
      setOpenModal (true);
  }
    else {
        setshowModalDisableButton(false);
        setIcon('question')
        setOpenModal (true);
    }
}


const postData =async()=>{
    //post request
    // updateState('confirmModal',{state:false,title:'Save Record?',message:'Are you sure you want to save this record?'});

    try {
        
        //refreshGrid!();
        // console.log(res)
        setIcon('success')
        setOpenModal (true);
        setshowModalDisableButton(false);
        setModalData({message: "Data entry saved successfully!", title:"Data Saved!",okText: "Ok"});
       
  
      } catch (error: any) {
        console.log(error);
        setIcon('warning')
        setOpenModal (true);
        setshowModalDisableButton(false);
        setModalData({message: error.message, title:"Unexpected Error!",okText: "Ok"});
  
        //set states for db errors modal
        // setErrState(true);
        // seterrTitle(error.response.data.message);
        // seterrMessage(error.response.data.errors[0]);
      }
    }

    // update
    const UpdateData = async()=>{
        try {
           
            //refreshGrid!();
            // console.log(res)
            setIcon('success')
            setOpenModal (true);
            setshowModalDisableButton(false);
            setModalData({message: "Data entry updated successfully!", title:"Data Updaated!",okText: "Ok"});

         
        } catch (error:any) {
            //set states for db errors modal
            console.log(error);
            setIcon('warning')
            setOpenModal (true);
            setshowModalDisableButton(false);
            setModalData({message: error.message, title:"Unexpected Error!",okText: "Ok"});
        }
    }
    
    
    return (
        
        <div style={{width:'900px', minWidth:'900px', height:'100%',borderColor:borderTheme}}  className="  border-[1px]   dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base  ">
                            {/* <h4 className=" w-full  mb-2 bg-slate-100  ">Task Details</h4> */}
            <ModalTemplate icon={icon} cancelText= {modalData.cancelText}
                cancelHandler = {()=>{setOpenModal(false)}}  open = {openModal} okHandler={()=>{ modalData===  saveModalData ?postData(): modalData===updateModalData ? UpdateData(): setOpenModal (false)}}
                message= {modalData.message} okText= {modalData.okText} title={modalData.title} />
                            <div style={{maxHeight:'295px', minHeight:'295px', height:'295px', }} className=' items-start justify-start '   >
                                
                                <Form
                                    className='w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 px-2 '
                                    name="basic"
                                    labelCol={{ span: 3}}
                                    // wrapperCol={{ span: 24 }}
                                    initialValues={{ remember: true }}
                                    autoComplete="off"
                                    size='small'
                                    layout='horizontal'
                                    disabled={form_disable}

                                >
                                    <div>
                                        {selects.map((select:any)=>{
                                            const total_selects = selects?.length;
                                            return(
                                                <Form.Item 
                                                    key={select.id}
                                                    label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{select.label}</p>}
                                                    className= {select.id === total_selects! - 1 ? 'mb-0.5' : ' mb-1'}
                                                    name={select.label}
                                                    // rules={[{ required: rulesOn,}]}
                                                    // labelCol={{span: labelCol }}
                                                    // wrapperCol={{span:wrapperCol}}
                                                >
                                                    <div className="flex flex-row items-center">
                                                        <Select
                                                            onChange = {()=>{}}
                                                            className='w-full mr-0'
                                                            placeholder=""
                                                            allowClear
                                                        >
                                                            {select.optionsData?.map((option:any, index:number)=>{
                                                                return(
                                                                    <option  key={index} value ={option[select.nameKey!]}>{option[select.nameKey!]}</option>
                                                                )
                                                            })}
                                                        </Select>
                                                        
                                                        <span
                                                            onClick={()=>{}} 
                                                            className='ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded'
                                                            style={{height:22.5, marginBottom:2,borderWidth:1,width:23.9,borderColor: borderTheme}}
                                                        >
                                                            <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src= {reset} alt="reset" />
                                                            {/* <img className='' src= {''} alt="" /> */}
                                                        </span>
                                                    </div>
                                                </Form.Item>
                                            )
                                        }
                                    )}
                                        
                                    </div>
                                    

                                    <div>
                                        { textareas.map((textarea:any)=>{
                                            return(
                                                <>
                                                    <TextareaTemplate
                                                        height={textarea.style.height}
                                                        label={textarea.label}
                                                        useCallbackFunc
                                                        defaultValue=''
                                                        MarginLeft={textarea.style.marginLeft}
                                                        setCallbackValue={()=>{}}
                                                    />
                                                </>
                                                // <Form.Item
                                                // key={textarea.id}
                                                // className='mb-0'
                                                // label={<p className='dark:bg-fill dark:text-darkModeSkin-base text-xs'>{textarea.label}</p>}
                                                // name={textarea.label}
                                                // rules={[{ required: false,}]}
                                                // // labelCol={{span:labelCol}}
                                                // // wrapperCol={{span:wrapperCol}}
                                                // >
                                                //     <div className="flex flex-row ">
                                                //         <TextArea style={{resize:'none', height:textarea.style}} className='w-full '/>

                                                //         <span className='h-full w-4 ml-1 flex items-center justify-center hover:cursor-pointer hover'>
                                                //             {/* <img className='w w-9/12 h-9/12' src= {reset} alt="reset" /> */}
                                                //         </span>
                
                                                //     </div>

                                                // </Form.Item>
                                            )
                                        })

                                        }
                                    </div>

                                    
                                            {/* <Group />
                                            <StaffName />
                                            <RequestWindow />
                                            <Batch />
                                            <AssignedDept />
                                            <AssignedSelection />
                                            <AssignedUnit />
                                            <AssignedLocation /> */}
                                        <div className='w-full  flex flex-col mt-0.5 '>
                                            {/* <div style={{width:'calc(900px - 47px)',marginLeft:'9px'}} className=' flex  justify-between  '> */}
                                                {/* <li className='flex justify-start '><Cancel/></li> */}
                                                {/* <li className=' flex items-center flex-row'><p className='mr-3'>Leader? : </p>  <CheckBox /></li>
                                                <li className=' flex items-center flex-row'><p className='mr-3'>Active? : </p>  <CheckBox /></li> */}

                                                <ul style={{width:'calc(900px - 20px)',}} className='flex justify-end  '>
                                                    {
                                            (form_disable === false && update_state_change === false ) ?
                                                    <SaveButton handleSave={ ()=>{setModalData(saveModalData);  setOpenModal(true);validationForms()}}/>       
                                                    :
                                            (update_state_change === true ) ?
                                            
                                                    <UpdateButton  handleUpdate={()=>{setModalData(updateModalData);  setOpenModal(true);validationForms()}}   />
                                            
                                                    :
                                                    <SaveButton />
                                                    
                                                    // useCallbackFunc new_button_toggler={()=>{setform_disable(!form_disable)}}
                                        }
                                        {(form_disable === true )  ? 
                                                <NewButton  new_button_toggler={()=>{}} />
                                                
                                                :
                                                (update_state_change === true) ?
                                                <Cancel  handleCallbackCancel={()=>{}} />
                                                :
                                                !(update_state_change && form_disable) ? 
                                                <Cancel  handleCallbackCancel={()=>{}}  />
                                                
                                                :
                                                <NewButton  new_button_toggler={()=>{}} />
                                           }
                                                </ul>
                                            {/* </div> */}
                                        </div>
                                </Form>
                            </div>

                            

                        </div>
    )
}


