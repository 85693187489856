export const vehicleSimCard_TH = [
  {
    id: 0,
    caption: "VEH. REG No",
    dataField: "vdtRegNo",
    alignment: "left",
    fixed: true,
    
  },
  {
    id: 1,
    caption: "MOB. No",
    dataField: "vsaMobNo",
    alignment: "left",
    fixed: true,
    
  },
 
  {
    id: 2,
    caption: "SIM VENDOR",
    dataField: "tvdName",
    alignment: "left",
    fixed: true,
    width: null
  },
  {
    id: 3,
    caption: "TRACKER VENDOR",
    dataField: "tedVendor",
    alignment: "left",
    fixed: true,
    width: null


  },
  {
    id: 4,
    caption: "TRACKER URL",
    dataField: "tedTrackerUrl",
    alignment: "left",
    fixed: true,
    width: null

  },
  {
    id: 5,
    caption: "START DATE",
    dataField: "vsaStartDate",
    dataType: "datetime",
    alignment: "left",
    fixed: true,
    width: null

  },
  
  {
    id: 6,
    caption: "END DATE",
    dataField: "vsaEndDate",
    dataType: "datetime",
    alignment: "left",
    fixed: true,
    width: null

  },

  {
    id: 7,
    caption: "ACTIVE",
    dataField: "vsaActive",
    alignment: "left",
    fixed: true,

  },
  {
    id: 8,
    caption: "REMARKS",
    dataField: "vsaRmks",
    alignment: "left",
    fixed: true,
    width: 4000

  },
]


export const vehicleSimCard_data = [
  
]

export const vehicle_sim_reg_no = [
  { id: 1, caption: "Reg No", dataField: 'vdtRegNo', dataType: "", fixed: false, width: 120 },
  { id: 2, caption: "Class", dataField: 'vhcShtName', dataType: 'string', fixed: false, width: 80 },
  { id: 3, caption: "Make", dataField: 'vmkShtName', dataType: 'string', fixed: false, width: 80 },
  { id: 4, caption: "Model", dataField: 'mdlShtName', dataType: 'string', fixed: false, width: 80, format: { type: 'fixedPoint', precision: 2 } },
  { id: 5, caption: "Manufacturer", dataField: 'mftShtName', dataType: 'string', fixed: false, width: 80, format: { type: 'fixedPoint', precision: 2 } },
  { id: 6, caption: "Driver", dataField: 'driverEmpName5', dataType: 'string', format: { type: 'fixedPoint', precision: 2 }, fixed: false, width: 120 },
  { id: 7, caption: "Driver INTERCOM.", dataField: 'driverPlc', width: 120, dataType: 'string',  },
  { id: 8, caption: "Approving Officer", dataField: 'ownerEmpName5', dataType: 'string', width: 120,  },
  { id: 9, caption: "Appr. Officer INTERCOM.", dataField: 'ownerPlc', dataType: 'string', width: 120,  },
  { id: 10, caption: "Manuf. Date", dataField: 'vdtManufactureDate', dataType: 'string', width: 120,  },
  { id: 11, caption: "Dept", dataField: 'dptShtName', dataType: 'string', width: 120,  },
  { id: 12, caption: "Section", dataField: 'sxnShtName', dataType: 'string', width: 120,  },
  { id: 13, caption: "Unit", dataField: 'untShtName', dataType: 'string', width: 120,  },
  { id: 14, caption: "Fuel Type", dataField: 'ftpShtName', dataType: 'string', width: 120,  },
]