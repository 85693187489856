import { useState } from "react";
import VehicleRequestPriviledgesDetails from "./vehicleRequestPriviledges-details";
import { HrSetupsCustomToolbar } from "../../../../../../accessories/custom-toolbars/hrSetupsCustomtoolbar";

const VehicleRequestPriviledges = () => {

  const [showTable, setShowTable] = useState(true);

  const switch_toggle = () => {
    setShowTable(!showTable);
  };

  const criteria = [
    { id: 0, value: "Attendant" },
    { id: 1, value: "Block" },
  ];

  return (
    <>
      <div className="">
        <HrSetupsCustomToolbar
          searchCriteriaData={criteria}
          assignmentsForm
          disableNew_edit
          withDates={false}
          toggler={switch_toggle}
        />
      </div>

      <div className="px-2 mb-0.5 ">
        <VehicleRequestPriviledgesDetails toggled={showTable} /> 
      </div>
    </>
  );
};

export default VehicleRequestPriviledges;
