import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import './styles/styles.css'

const Newsticker = () => {
  const newsTickerHeight = useSelector((state: RootState) => state.general.newsflashHeight);
  const newsFlashBG = useSelector((state: RootState) => state.general.settingsColors.nfbgc)
  const newsFlashTC = useSelector((state: RootState) => state.general.settingsColors.nftc)
  const rxStopNews = useSelector((state: RootState) => state.general.stopNewsFlash)


  return (
    <div id='newsticker' className={`${newsTickerHeight === 0 ? 'hidden' : 'overflow-hidden'}  `}>
      {rxStopNews == true ?
        <div className={`w-full py-2 flex justify-center items-center   bg-${newsFlashBG?.color ?? 'black'} `}>
          <div className={` text-[30px] flex justify-center items-center  `} >

            <div className={`flex text-${newsFlashTC?.color ?? 'black'} px-2 py-1 justify-center items-center`}  >
                *Newsticker*
            </div>
          </div>
        </div>
        :
        <div className={` flex items-center ticker-wrap py-[10px] bg-${newsFlashBG?.color ?? 'white'} `} id='ticker-paused' >
          <div className={`ticker text-[30px] inline-flex  `} id='ticker-wrap '>

            <div className={`ticker__item  text-${newsFlashTC?.color ?? 'black'} px-2 items-center`}  >

              Our Mission is to provide innovative, world class and enterprise software products and services globally, thereby enabling them to leverage technology for business growth and sustainability.
              Our vision is to help both local and international businesses overcome the ever trending technological challenges of digital transformation by delivering eminent software solutions and services.
              Our Values form the building blocks of our culture and defines how we work together to serve our customers and to change the world.
            </div>


            {/* <div className="ticker__item"> thereby enabling them to leverage technology for business growth and sustainability.</div>
            <div className="ticker__item">Our vision is to help both local and international businesses overcome the ever trending technological challenges of digital transformation by delivering eminent software solutions and services.</div> */}
            {/* <div className="ticker__item">Maecenas et facilisis mi.  </div> */}
            {/* <div className="ticker__item"> Our Values form the building blocks of our culture and defines how we work together to serve our customers and to change the world.</div> */}
            {/* <div className="ticker__item"> In hac habitasse platea dictumst.</div> */}
            {/* <div className="ticker__item">Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</div>
            <div className="ticker__item">Nullam ut consequat neque.</div>
            <div className="ticker__item">Ut nec malesuada elit. </div>
            <div className="ticker__item">Proin auctor mauris ut rutrum eleifend. </div> */}
          </div>
        </div>
      }

    </div>);
}

export default Newsticker;