export const Assets_setups_insuranceCompaniesColumns = [
  {
    id: 0,
    caption: "ID",
    dataField: "icmIDpk",
    dataType: "number",
    width: 200,
    fixed: true,
    visible: false,
  },
  {
    id: 1,
    caption: "COMPANY NAME",
    dataField: "icmName",
    dataType: "string",
    width: 200,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "SHORT NAME",
    dataField: "icmShtName",
    dataType: "string",
    width: 140,
    fixed: true,
    visible: true,
  },
  {
    id: 3,
    caption: "CONTACT PERSON",
    dataField: "icmContacts",
    dataType: "string",
    width: 140,
    fixed: true,
    visible: true,
  },
  {
    id: 4,
    caption: " Email",
    dataField: "icmEmails",
    dataType: "string",
    width: 140,
    fixed: false,
    visible: true,
  },
  {
    id: 5,
    caption: "TEL NO.",
    dataField: "icmTelNos",
    dataType: "string",
    width: 140,
    fixed: false,
    visible: true,
  },
  {
    id: 6,
    caption: "EXTENSION",
    dataField: "icmExtensions",
    dataType: "string",
    width: 110,
    fixed: false,
    visible: true,
  },
  {
    id: 7,
    caption: "MobNos",
    dataField: "icmMobNos",
    dataType: "string",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 8,
    caption: "GEOGRAPHIC CORDINATES",
    dataField: "icmGeogCordinates",
    dataType: "string",
    width: 200,
    fixed: false,
    visible: true,
  },
  {
    id: 9,
    caption: "ADDRESS",
    dataField: "icmAddress",
    dataType: "string",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 10,
    caption: "ORDER",
    dataField: "icmOrder",
    dataType: "string",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 12,
    caption: "ACTIVE",
    dataField: "icmActive",
    dataType: "string",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 13,
    caption: "REMARKS",
    dataField: "icmRmks",
    dataType: "string",
    width: 5000,
    fixed: false,
    visible: true,
  },
];
