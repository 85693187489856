import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncGet } from "../../../functions/get";

interface apiStates {

    ServiceProviderData: any | null;
    CardNoData: any | null;
    CardPinData: any | null;
    RegionIssuedData: any | null;
    TownIssuedData: any | null;

    isLoading: boolean;
    isPending: boolean;
    isError: boolean;
}

var initialState: apiStates = {

    ServiceProviderData: [],
    CardNoData: [],
    CardPinData: [],
    RegionIssuedData: [],
    TownIssuedData: [],
    isLoading: false,
    isPending: false,
    isError: false,
};

// Thunks for api Calls
export const Fetch_ServiceProvider = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "serviceProvider/fetch",
    async (search: string) => {

        const res = await AsyncGet(`AssetMgrTmsCardDetail/GetCardDetailServiceProviderLookup`, {
            search, pagenumber: 1, pagesize: 20
        });
        return res?.data;
    }
);

export const Fetch_CardNo = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "cardNo/fetch",
    async (search: string) => {
        const res = await AsyncGet(`AssetMgrTmsCardDetail/GetCardDetailCardNoLookup`, {
            search, pagenumber: 1, pagesize: 20
        });
        return res?.data;
    }
);

export const Fetch_CardPin = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "cardPin/fetch",
    async (search: string) => {
        const res = await AsyncGet(`AssetMgrTmsCardDetail/GetCardDetailCardPinLookup`, {
            search, pagenumber: 1, pagesize: 20
        });
        return res?.data;
    }
);

export const Fetch_RegionIssued = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "regionIssued/fetch",
    async (search: string) => {
        const res = await AsyncGet(`AssetMgrTmsCardDetail/GetCardDetailRegionIssuedLookup`, {
            search, pagenumber: 1, pagesize: 20
        });
        return res?.data;
    }
);

export const Fetch_TownIssued = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "townIssued/fetch",
    async (search: string) => {
        const res = await AsyncGet(`AssetMgrTmsCardDetail/GetCardDetailTownIssuedLookup`, {
            search, pagenumber: 1, pagesize: 20, region: "central"
        });
        return res?.data;
    }
);

const Slice_CardDetails = createSlice({
    name: 'slice_cardDetails',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Fetch Service Provider
            .addCase(Fetch_ServiceProvider.pending, (state) => {
                state.isLoading = true;

            })
            .addCase(Fetch_ServiceProvider.fulfilled, (state, action) => {
                state.isLoading = false;
                state.ServiceProviderData = action.payload;
            })
            .addCase(Fetch_ServiceProvider.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })

            // Fetch Card No
            .addCase(Fetch_CardNo.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(Fetch_CardNo.fulfilled, (state, action) => {
                state.isLoading = false;
                state.CardNoData = action.payload;
            })
            .addCase(Fetch_CardNo.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })

            // Fetch Card Pin
            .addCase(Fetch_CardPin.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(Fetch_CardPin.fulfilled, (state, action) => {
                state.isLoading = false;
                state.CardPinData = action.payload;
            })
            .addCase(Fetch_CardPin.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })

            // Fetch Region Issued
            .addCase(Fetch_RegionIssued.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(Fetch_RegionIssued.fulfilled, (state, action) => {
                state.isLoading = false;
                state.RegionIssuedData = action.payload;
            })
            .addCase(Fetch_RegionIssued.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })

            // Fetch Town Issued
            .addCase(Fetch_TownIssued.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(Fetch_TownIssued.fulfilled, (state, action) => {
                state.isLoading = false;
                state.TownIssuedData = action.payload;
            })
            .addCase(Fetch_TownIssued.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
    },
});
export default Slice_CardDetails.reducer;