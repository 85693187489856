import { Tooltip } from "devextreme-react";
import "devextreme/dist/css/dx.light.css";
import { useEffect, useState } from "react";

import { Checkbox, DatePicker, Input, Select } from "antd";
import { FaBinoculars } from "react-icons/fa";
import printer from "../../../../../../../assets/printer.png";
import reset from "../../../../../../../assets/refresh-small.png";
import log from "../../../../../../../assets/log.png";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import statusLoader from "../../../../../../../assets/statusloader.gif";
import { saveModalData } from "../../../../../../accessories/component_infos";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { searchTextTypes } from "../../../../../../human-resource/setups/data/types/selectsTypes";
import { useSearchParams } from "react-router-dom";
interface props {
  toggler?: () => void;
  handleDelete?: () => void; //delete handler
  handleSave?: () => void; //save handler
  handleUpdate?: () => void; //update handler
  searchTextOnchange?: (value: string) => void; //search text change
  startDateChanged?: (value: any) => void; //start date change
  endDateChanged?: (value: any) => void; //end date change
  checkOnChange?: (value: any) => void; //checkbox change
  handleFind?: (value: any) => void; //find handler
  handleRefresh?: (value: any) => void; //refresh main grid
  handlePrint?: () => void; // handle print functionality
  useCallBackFunc?: boolean;
  withDates?: boolean; //add dates to the toolbar?
  launchVehicleLogBookModal?: () => void;
  searchCriteriaOnchange?: (index: number, criteria: string) => void;
  searchCriteriaData?: { id: number; value: string }[];

  startDate?: string;
  endDate?: string;
}

export const VehicleRequestsSecurityToolbar = ({
  handleRefresh,
  handlePrint,
  handleFind,
  checkOnChange,
  launchVehicleLogBookModal,
  searchTextOnchange,
  startDateChanged,
  endDateChanged,
  startDate,
  endDate,
  searchCriteriaOnchange,
  searchCriteriaData = [],
}: props) => {
  const [openModal, setOpenModal] = useState(false);
  const [criteriaDataLocal, setCriterialDataLocal] = useState({
    id: searchCriteriaData![0]?.id ?? 0,
    value: searchCriteriaData![0]?.value ?? "",
  });

  const [disableCancelButton, setDisableCancelButton] = useState(false);
  const [modalData, setModalData] = useState(saveModalData);
  const [modalIcon, setModalIcon] = useState<
    "warning" | "success" | "question"
  >("question");
  const [indeterminate, setIndeterminate] = useState<any>(true);
  const [checkedBox, setCheckedBox] = useState<any>(false);
  const [checkValue, setCheckValue] = useState<boolean | null>(null); //actual value to use
  const [searchText, setSearchText] = useState("");
  const [searchTextValue, setSearchTextValue] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });

  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const form_disable = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const update_state_change = useSelector(
    (state: RootState) => state.general.taskFooterUpdate
  );
  useEffect(() => {
    checkOnChange && checkOnChange(checkValue);
  }, [checkValue]);

  const onChange = (value: boolean) => {
    if (indeterminate === true) {
      setCheckedBox(true);
      setIndeterminate(false);
      setCheckValue(true);
    } else if (!indeterminate && checkedBox) {
      setCheckedBox(false);
      setIndeterminate(false);
      setCheckValue(false);
    } else if (!indeterminate && !checkedBox) {
      setCheckedBox(false);
      setIndeterminate(true);
      setCheckValue(null);
    }
  };
  const [privType, setPrivType] = useState<string>("save");

  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);

  const findAccessPriv = () => {
    setPrivType("read");
    if (readPriv === true) {
      handleFind &&
        handleFind(
          `&searchCriteria=${criteriaDataLocal.id}` +
            (checkValue !== null ? `&active=${checkValue}` : "") +
            (searchText !== "" ? `&searchText=${searchText}` : "") +
            `&startDate=${startDate}&endDate=${endDate}`
        );
    } else {
      setModalIcon("warning");
      setDisableCancelButton(true);
      setModalData({
        message: "No Access Privilege",
        title: "Access Denied!",
        okText: "Ok",
        cancelText: "No, Cancel",
        progress: "",
        error: { title: "", message: "" },
        success: { title: "", message: "" },
      });
      setOpenModal(true);
    }
  };
  const AccessPriv_on_PrintButton = () => {
    if (savePriv === true) {
      handlePrint && handlePrint();
    } else {
      setModalIcon("warning");
      setDisableCancelButton(true);
      setModalData({
        message: "No Access Privilege",
        title: "Access Denied!",
        okText: "Ok",
        cancelText: "No, Cancel",
        progress: "",
        error: { title: "", message: "" },
        success: { title: "", message: "" },
      });
      setOpenModal(true);
    }
  };

  return (
    <>
      <ModalTemplate
        icon={modalIcon}
        disableCancel={disableCancelButton}
        cancelText={modalData.cancelText}
        open={openModal}
        okHandler={() => {
          setOpenModal(false);
        }}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
      />
      <div
        style={{ borderWidth: "1px", height: 32, borderColor: borderTheme }}
        className="px-0.5 mt-0.5 rounded   w-full h-full flex  border-gray-200 mb-1"
      >
        <div className="flex justify-center ">
          <ul className="flex justify-evenly w-full items-center h-full py-0.5">
            <li
              id="log"
              onClick={launchVehicleLogBookModal}
              style={{
                borderWidth: "1px",
                borderColor: borderTheme,
                width: "35px",
              }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <img
                className="dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"
                src={log}
                alt="log"
              />
              <Tooltip
                target="#log"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="h-2">Vehicle log book</p>
              </Tooltip>
            </li>

            <li className="px-1 text-xs">Search Criteria</li>
            <li className="items-right  ">
              <Select
                value={criteriaDataLocal}
                popupMatchSelectWidth={false}
                className=" mt-0.5"
                onChange={(data: any) => {
                  const criteriaData = JSON.parse(data);
                  setCriterialDataLocal({
                    id: criteriaData?.index,
                    value: criteriaData?.criteria,
                  }); //local
                  searchCriteriaOnchange &&
                    searchCriteriaOnchange(
                      criteriaData?.index,
                      criteriaData?.criteria
                    ); //external
                }}
                id="search"
                size="small"
                showSearch={true}
                style={{ width: 110, height: "25px" }}
              >
                {searchCriteriaData?.map(({ id, value }, index) => (
                  <Select.Option
                    key={id}
                    value={JSON.stringify({ index: index, criteria: value })}
                  >
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </li>
          </ul>
        </div>

        <div className="flex items-center">
          <ul className="flex items-center ">
            <label className="flex text-xs px-2  items-center">
              Search Text
            </label>

            <li className="px-1">
              <Input
                onChange={(e: any) => {
                  const searchText: string = e.target.value;
                  searchTextOnchange && searchTextOnchange(searchText);
                  setSearchTextValue(e.target.value);
                }}
                value={searchTextValue.text}
                name="search"
                id="search"
                size="small"
                allowClear={true}
                style={{ width: 130, height: "24px" }}
              />
            </li>
            <li className="px-0.5">
              <DatePicker
                value={dayjs(startDate) ?? dayjs()}
                clearIcon={<span style={{ display: "none" }}></span>}
                onChange={startDateChanged}
                size="small"
                placeholder="Start Date"
                style={{ width: 125 }}
                format={"DD MMM YYYY"}
              />
            </li>
            <li className="px-0.5">
              <DatePicker
                value={dayjs(endDate) ?? dayjs()}
                clearIcon={<span style={{ display: "none" }}></span>}
                onChange={endDateChanged}
                size="small"
                placeholder="End Date"
                style={{ width: 125 }}
                format={"DD MMM YYYY"}
              />
            </li>
            <li
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              id="status"
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1  text-gray-600 rounded flex items-center"
            >
              <Checkbox
                checked={checkedBox}
                indeterminate={indeterminate}
                onChange={(checkValue: any) => {
                  onChange(checkValue);
                }}
              />
              <Tooltip
                target="#status"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Show Status</p>
              </Tooltip>
            </li>
            <li
              onClick={() => {
                findAccessPriv();
              }}
              id="findst"
              style={{
                borderWidth: "1px",
                borderColor: borderTheme,
                width: "25px",
              }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
                <FaBinoculars size={15.5} color="#007AFF" />
              </span>
              <Tooltip
                target="#findst"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Find</p>
              </Tooltip>
            </li>

            {/* refresh */}
            <li
              onClick={() => {
                handleRefresh &&
                  handleRefresh(
                    `&searchCriteria=${criteriaDataLocal.id}` +
                      (checkValue !== null ? `&active=${checkValue}` : "") +
                      (searchText !== "" ? `&searchText=${searchText}` : "") +
                      `&startDate=${startDate}&endDate=${endDate}`
                  );
                setSearchTextValue({ temp: "", text: "" });
                setCheckedBox(false);
                setIndeterminate(true);
                setCheckValue(null);
              }}
              id="refreshst"
              style={{
                borderWidth: "1px",
                borderColor: borderTheme,
                width: "25px",
              }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
                {" "}
                <img
                  alt=""
                  className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                  src={reset}
                />
              </span>
              <Tooltip
                target="#refreshst"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Refresh</p>
              </Tooltip>
            </li>

            {/* <li
              onClick={() => {
                AccessPriv_on_PrintButton();
              }}
              id="printst"
              style={{
                borderWidth: "1px",
                borderColor: borderTheme,
                width: "25px",
              }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
                {" "}
                <img
                  alt=""
                  className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                  src={printer}
                />
              </span>
              <Tooltip
                target="#printst"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Print/Export</p>
              </Tooltip>
            </li> */}

            <li className="hover:cursor-pointer ml-20  px-2  flex items-center">
              {form_disable && (
                <p className=" italic font-semibold text-gray-600">Done.</p>
              )}

              {!form_disable && (
                <div className="  flex flex-row items-center">
                  <img
                    style={{ width: "31px", height: "31px" }}
                    src={statusLoader}
                    alt="gif"
                  />
                  {!update_state_change ? (
                    <p className=" italic text-gray-600">New Record</p>
                  ) : (
                    <p className=" italic text-gray-600">Editing</p>
                  )}
                </div>
              )}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
