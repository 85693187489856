/* eslint-disable react/jsx-pascal-case */
import { Form, Space } from "antd";
import useFetch from "../../../../../../../hooks/useFetch";
import manArrow from "../../../../../../../assets/manArrow.png";
import reset from "../../../../../../../assets/reset.png";
import { BottomBtns } from "../../../../../../../templates/butttons";
import { DateTemplate } from "../../../../../../../templates/date";
import { SelectsTemplate } from "../../../../../../../templates/select";
import { forwardRef, useImperativeHandle, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import {
  currentDate,
  saveModalData,
  startingDate,
  updateModalData,
} from "../../../../../../accessories/component_infos";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import { setGeneralValue } from "../../../../../../../features/generalSlice";
import { GetDataFunc } from "../../../../../../../functions/get";
import { PostDataFunc } from "../../../../../../../functions/post";
import { UpdateDataFunc } from "../../../../../../../functions/update";
import { statusTypes } from "../../../../../../human-resource/setups/data/types/selectsTypes";
import { createSearchParams, useNavigate } from "react-router-dom";
import { Tooltip } from "devextreme-react";
import { Driver_Col, vfca_cols } from "../data/driverCodeAssignmentsColumns";
import TextArea from "antd/es/input/TextArea";
import { DropdownGrid_template } from "../../../../../../../templates/DropdownGrid";
import { useCrudFunc } from "../../../../../../../functions/crud";

interface modalTypes {
  info: any;
  open: boolean;
  icon: "warning" | "question" | "success";
  disableCancel?: boolean;
}

interface prop {
  currentStates?: any;
  setCurrentStates?: any;
  refreshState?: () => void;
  setSearchParamspathname?: string | any | undefined;
  selected_Record?: any;
  setSelected_Record?: (val: any) => void;
}

const DriverCodeAssignmentsForm = forwardRef(
  (
    {
      setCurrentStates,
      refreshState,
      setSearchParamspathname,
      selected_Record,
    }: prop,
    ref
  ) => {
    const initialStates = {
      startDate: { temp: startingDate, index: startingDate },
      endDate: startingDate,
      searchText: { temp: "", text: "" },
      selectedId: 0,
      serviceProvider: { name: "", id: 0 },
      driverCode: { name: "", id: 0 },
      driver: { name: "", id: 0 },
      assignmentDate: currentDate,
      remarks: "",
      unassignedCodes: { name: "", id: 0 },
      disableForms: false,
      driversCodeAss_grid: [],
      active: true,
      refresh: false,
    };

    const [states, setStates] = useState<any>(initialStates);

    const setState = (key: string, value: any) => {
      setStates((prev: any) => ({ ...prev, [key]: value }));
    };

    const [
      serviceprovider_data,
      serviceprovider_error,
      serviceprovider_loading,
    ] = useFetch(
      `AssetMgrTmsDriverCodeAssignment/GetServiceProviderLookup/getServiceProviderLookup`
    );
    const [driver_data, driver_error, driver_loading] = useFetch(
      `AsmCamEntEquipmentAssignment/GetLueStaffNames`
    );
    const [DriverCodeData, Card_error, Card_loading] = useFetch(
      `AsmTmsEntDriverCodeAssigment/GetLueDriversCode`,
      states.refresh
    );
    const [unassigned_codes_data, unassigned_error, unassigned_loading] =
      useFetch(`AsmTmsEntDriverCodeAssigment/GetLueUnassignedCodes`);

    const borderTheme = useSelector(
      (state: RootState) => state.general.cssBorderClass
    );
    const form_disable = useSelector(
      (state: RootState) => state.general.formDisabled
    );

    const navigate = useNavigate();

    const [setSearchParams, setSetSearchParams] = useState<string>("");
    const [selectedRecord, setSelectedRecord] = useState<any>({});

    const [warningModal, setWarningModal] = useState<boolean>(false);
    const [warningModalData, setWarningModalData] = useState<any>({
      title: "",
      message: "",
    });

    const [modalData, setModalData] = useState<modalTypes>({
      info: saveModalData,
      open: false,
      icon: "warning",
      disableCancel: false,
    });

    const clearFields = async () => {
      setSelectedRecord([]);
      setStates(initialStates);
    };

    const [openModal, setOpenModal] = useState(false);
    const [Icon, setIcon] = useState<"warning" | "question" | "success">(
      "warning"
    );

    const [employeeId, userId, userInfo] = useAccountInfo();
    const [privType, setPrivType] = useState<string>("save");
    const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);

    const dispatch = useDispatch();

    const [posting, updating] = useCrudFunc();

    const handleUpate = () => {
      navigate({
        pathname: setSearchParamspathname,
        search: createSearchParams({
          view: setSearchParams ?? "form-and-table",
        }).toString(),
      });

      if (selectedRecord.length < 1) {
        setWarningModal(true);
        setWarningModalData({
          title: "Select record",
          message: "Please select a record to edit",
        });
        dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      } else {
        dispatch(setGeneralValue({ expr: "formDisabled", value: false }));

        dispatch(setGeneralValue({ expr: "updateMode", value: true }));
      }
    };

    const [active, setActive] = useState<statusTypes>({
      temp: "",
      status: "",
    });

    const [Uncard, setUncard] = useState<any>([]);

    const newAccessPriv = () => {
      setPrivType("save");

      if (savePriv === true) {
        dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
        setStates({
          selectedId: 0,
          serviceProvider: { name: "", id: 0 },
          driverCode: { name: "", id: 0 },
          driver: { name: "", id: 0 },
          assignmentDate: startingDate,
          endDate: currentDate,
          remarks: "",
          unassignedCodes: { name: "", id: 0 },
          active: true,
        });
      } else if (updatePriv === true) {
        dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
        handleUpate();
      } else {
        dispatch(setGeneralValue({ expr: "formDisabled", value: true }));

        setModalData({
          ...modalData,
          info: {
            message: "No Privilege Access",
            title: "Access Denied!",
          },
          open: true,
          disableCancel: true,
        });
      }
    };

    const SaveAccessPriv = () => {
      setPrivType("save");
      if (savePriv === true) {
        validateSave("save");
        dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      } else {
        dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
        setModalData({
          ...modalData,
          info: {
            message: "No Privilege Access",
            title: "Access Denied!",
          },
          open: true,
          disableCancel: true,
        });
      }
    };

    const validateSave = async (type: any) => {
      setModalData({
        ...modalData,
        icon: "warning",
        disableCancel: true,
      });
      let isValid = true;

      if (states.serviceProvider.id === 0) {
        isValid = false;
        setModalData({
          ...modalData,
          icon: "warning",
          info: {
            message: "Please select a service provider",
            title: "Invalid Service Provider",
          },
          open: true,
          disableCancel: true,
        });
      }

      if (states.driverCode.name === "" || states.driverCode.id === 0) {
        isValid = false;
        setModalData({
          ...modalData,
          icon: "warning",
          info: {
            message: "Please select the code to be assigned to driver",
            title: "Invalid Code",
          },
          open: true,
          disableCancel: true,
        });
      }

      if (states.driver.id === 0) {
        isValid = false;
        setModalData({
          ...modalData,
          icon: "warning",
          info: {
            message: "Please select a driver who is to be assigned a code",
            title: "Invalid Driver",
          },
          open: true,
          disableCancel: true,
        });
      }

      if (states.assignmentDate === "") {
        isValid = false;
        setModalData({
          ...modalData,
          icon: "warning",
          info: {
            message: "Select an Assignment Date",
            title: "Invalid Date",
          },
          open: true,
          disableCancel: true,
        });
      }

      if (states.endDate === "") {
        isValid = false;
        setModalData({
          ...modalData,
          icon: "warning",
          info: {
            message: "Select an End Date",
            title: "Invalid Date",
          },
          open: true,
          disableCancel: true,
        });
      }

      if (states.endDate < states.assignmentDate) {
        isValid = false;
        setModalData({
          ...modalData,
          icon: "warning",
          info: {
            message: "Assignment Date cannot be greater than End Date",
            title: "Invalid Date",
          },
          open: true,
          disableCancel: true,
        });
      }

      const resData = await GetDataFunc(
        `AsmTmsEntDriverCodeAssigment/CheckStaffAssignmentExists?EmpID=${states.driver.id}&SpdID=${states.serviceProvider.id}&AssignDate=${states.assignmentDate}&ExpiryDate=${states.endDate}`
      );
      if (resData.data.length > 0) {
        isValid = false;
        setModalData({
          ...modalData,
          icon: "warning",
          info: {
            message:
              "The selected staff already has an active code assignment, please check the entries and try again.",
            title: "Invalid Entry",
          },
          open: true,
          disableCancel: true,
        });
      }

      const res = await GetDataFunc(
        `AsmTmsEntDriverCodeAssigment/CheckDriverCodeExists?DriverCode=${states.driverCode.name}&SpdID=${states.serviceProvider.id}`
      );
      if (res.data.length > 0) {
        isValid = false;
        setModalData({
          ...modalData,
          icon: "warning",
          info: {
            message:
              "The Code you entered is has already been assigned to another staff, please check the code and try again.",
            title: "Invalid Entry",
          },
          open: true,
          disableCancel: true,
        });
      }

      const response = await GetDataFunc(
        `AsmTmsEntDriverCodeAssigment/CheckPreviousAssignmentExists?EmpID=${states.driver.id}&SpdID=${states.serviceProvider.id}&AssignDate=${states.assignmentDate}&EndDate=${states.endDate}`
      );
      if (response.data.length > 0) {
        isValid = false;
        setModalData({
          ...modalData,
          icon: "warning",
          info: {
            message:
              "The selected staff has an assignment that falls between a previous assignment, please check the End Date of the previous assignment and try again.",
            title: "Invalid Entry",
          },
          open: true,
          disableCancel: true,
        });
      }

      if (isValid) {
        if (type === "save") {
          setModalData({
            ...modalData,
            info: saveModalData,
            open: true,
            icon: "question",
            disableCancel: false,
          });
        } else if (type === "update") {
          setModalData({
            ...modalData,
            info: updateModalData,
            open: true,
            icon: "question",
            disableCancel: false,
          });
        }
      }
    };

    const SendSMS = async (message) => {
      try {
        await posting(
          `SMSAlert/SaveSMS`,
          {
            imsMessage: message,
            imsRecipientEmpIdfk: 0,
            imsSender: userInfo.empName,
            imsRecordIdfk: 0,
            imsFormCode: "driverCodeAssignment",
            imsMessageType: "string",
            imsMessageClass: "string",
            imsInitiatorEmpIdfk: employeeId,
            imsCreatedBy: userId,
          },
          `Successfully sent SMS to the driver ${states.driver.name}`
        );
      } catch (err: any) {
        setIcon("warning");
        setOpenModal(true);
        setModalData({
          ...modalData,
          info: {
            message: err.message,
            title: "Unexpected Error!",
            okText: "Ok",
          },
          disableCancel: true,
        });
      }
    };

    const saveData = async () => {
      try {
        await posting(
          `AsmTmsEntDriverCodeAssigment/CreateDriveCodeAssignment`,
          {
            dcaServiceProviderIdfk: states.serviceProvider.id,
            dcaCode: states.driverCode.name,
            dcaDriverEmpIdfk: states.driver.id,
            dcaAssignDate: dayjs(states.assignmentDate).format(),
            dcaEndDate: dayjs(states.endDate).format(),
            dcaActive: states.active,
            dcaRmks: states.remarks,
            dcaCreatedBy: employeeId,
          },
          `Successfully created the Drivers Code Assignment with service provider - ${states.serviceProvider.name}, drivers code - ${states.driverCode.name}, driver - ${states.driver.name} and assignment date - ${states.assignmentDate} to end date - ${states.endDate}`
        );
        SendSMS(
          `The code ${states.driverCode.name} has been asigned to driver ${states.driver.name}`
        );
        setModalData({ ...modalData, open: false });
        dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
        refreshState && refreshState();
        setIcon("success");
        setModalData({
          ...modalData,
          info: {
            message: "Record Saved Successfully",
            title: "Success",
            okText: "ok",
          },
          icon: "success",
          open: true,
          disableCancel: true,
        });
      } catch (err: any) {
        setIcon("warning");
        setOpenModal(true);
        setModalData({
          ...modalData,
          info: {
            message: err.message,
            title: "Unexpected Error!",
            okText: "Ok",
          },
          disableCancel: true,
        });
      }
    };

    const updateData = async () => {
      await updating(
        `AsmTmsEntDriverCodeAssigment/UpdateDriveCodeAssignment`,
        {
          dcaIdpk: states.selectedId,
          dcaServiceProviderIDfk: states.serviceProvider.id,
          dcaCode: states.driverCode.name,
          dcaDriverEmpIDfk: states.driver.id,
          dcaAssignDate: dayjs(states.assignmentDate).format(),
          dcaEndDate: dayjs(states.endDate).format(),
          dcaActive: states.active,
          dcaRmks: states.remarks,
          dcaCreatedBy: employeeId,
          dcaEditedBy: employeeId,
          dcaEditedDate: dayjs().format(),
        },
        `Successfully updated the Drivers Code Assignment with service provider - ${states.serviceProvider.name}, drivers code - ${states.driverCode.name}, driver - ${states.driver.name} and assignment date - ${states.assignmentDate} to end date - ${states.endDate}`
      );
      SendSMS(
        `The code ${states.driverCode.name} has been asigned to driver ${states.driver.name}`
      );
      setModalData({
        ...modalData,

        open: false,
      });
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      refreshState && refreshState();
      setIcon("success");
      setModalData({
        ...modalData,
        info: {
          message: "Record updated Successfully",
          title: "Success",
          okText: "ok",
        },
        icon: "success",
        open: true,

        disableCancel: true,
      });
    };

    useImperativeHandle(ref, () => ({
      singleClick: (e: any) => {
        if (e !== undefined) {
          setStates({
            selectedId: e?.dcaIDpk,
            serviceProvider: { name: e?.spdName, id: e?.spdIDpk },
            driverCode: { name: e.dcaCode, id: e.dcaIDpk },
            driver: { name: e?.empName, id: e?.empIDpk },
            assignmentDate: e?.dcaAssignDate,
            endDate: e?.dcaEndDate,
            remarks: e?.dcaRmks,
            unassignedCodes: { name: e?.sptDriverCode, id: e?.id },
            active: e?.dcaActive,
            disableForms: true,
          });
        }
        dispatch(setGeneralValue({ expr: "updateMode", value: false }));
        dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      },
      doubleClicked: (e: any) => {
        setStates({
          selectedId: e?.dcaIDpk,
          serviceProvider: { name: e?.spdName, id: e?.spdIDpk },
          driverCode: { name: e.dcaCode, id: e.dcaIDpk },
          driver: { name: e?.empName, id: e?.empIDpk },
          assignmentDate: e?.dcaAssignDate,
          endDate: e?.dcaEndDate,
          remarks: e?.dcaRmks,
          unassignedCodes: { name: e?.sptDriverCode, id: e?.id },
          active: e?.dcaActive,
          disableForms: false,
        });
        dispatch(setGeneralValue({ expr: "updateMode", value: true }));
        dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      },
    }));

    return (
      <>
        <ModalTemplate
          icon={modalData.icon}
          disableCancel={modalData.disableCancel}
          open={modalData.open}
          okHandler={() => {
            modalData.info === saveModalData
              ? saveData()
              : modalData.info === updateModalData
              ? updateData()
              : setModalData({ ...modalData, open: false });
          }}
          cancelHandler={() => {
            setModalData({ ...modalData, open: false });
          }}
          message={modalData.info.message}
          okText={modalData.info.okText}
          title={modalData.info.title}
        />

        <div className="w-[full] pl-2 border-r-0">
          <p
            id={"form_header"}
            style={{
              borderColor: borderTheme,
              borderRight: 1,
              borderLeft: 1,
              borderTop: 1,
              borderBottom: 0,
              borderWidth: 1,
              borderStyle: "solid",
              borderBottomWidth: 0,
            }}
            className="pt-1  w-[4000px] border-l-[1px] border-b-0 border-r-[1px]  px-1 rounded  font-semibold bg-slate-100"
          >
            {"Driver Code Assignment Details"}
          </p>
          <Form
            style={{ borderColor: borderTheme }}
            className="w-full border-[1px] border-slate-100 rounded py-1 px-2 "
            name="basic"
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 22 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            size="small"
          >
            <section style={{ width: "100%" }}>
              <Form.Item
                className=""
                label={<p className="text-xs">{"Service Provider"}</p>}
                style={{
                  marginBottom: 0,
                  width: "100%",
                  display: "inline-block",
                }}
              >
                <Space className="w-full" wrap>
                  <SelectsTemplate
                    idexpr={"spdIdpk"}
                    dataexp={"spdName"}
                    disabled={form_disable}
                    placeHolder={states.serviceProvider.name}
                    selectedValue={(value: any) => {
                      setState("serviceProvider", {
                        id: value.spdIdpk,
                        name: value.spdName,
                      });
                    }}
                    options={serviceprovider_data}
                    useCallFunc={true}
                    selectStyle={{ width: 250, marginBottom: 0 }}
                    handleRefresh={() => {
                      setState("serviceProvider", { id: 0, name: "" });
                      setState("refresh", !states.refresh);
                    }}
                  />

                  {Uncard[0] ? (
                    <span className="w-full text-indigo-600 ml-[28px] text-xs">
                      {`* ${Uncard[0]?.spdName}`}
                    </span>
                  ) : (
                    <span className="w-full text-indigo-600 ml-[28px] text-xs">
                      {"* "}
                    </span>
                  )}
                </Space>
              </Form.Item>

              <Form.Item
                className=""
                label={<p className="text-xs">{"Driver's code"}</p>}
                style={{
                  marginBottom: 0,
                  width: "100%",
                  display: "inline-block",
                }}
              >
                <Space className="w-full" wrap>
                  <SelectsTemplate
                    idexpr={"dcaIDpk"}
                    dataexp={"code"}
                    disabled={form_disable}
                    placeHolder={states.driverCode.name}
                    selectedValue={(value: any) => {
                      setState("driverCode", {
                        id: value.dcaIDpk,
                        name: value.code,
                      });
                    }}
                    options={DriverCodeData}
                    useCallFunc={true}
                    selectStyle={{ width: 250, marginBottom: 0.5 }}
                  />
                  <div className="w-full ml-[37px] flex flex-row justify-between  ">
                    <div className="">
                      <DropdownGrid_template
                        labelCol={25}
                        label={"Unassigned Codes"}
                        cusBtnStyle={true}
                        dropdownBtnStyles={{ width: 200 }}
                        isHide={true}
                        defaultValue={Uncard[0]?.sptDriverCode}
                        disabled={form_disable}
                        columns={vfca_cols}
                        gridData={unassigned_codes_data}
                        onRowClick={(e: any) => {
                          setUncard([e]);
                        }}
                      />
                    </div>

                    <div className="ml-[-30px]">
                      <span className="w-full flex">
                        <span
                          onClick={() => {
                            clearFields();
                            dispatch(
                              setGeneralValue({
                                expr: "formDisabled",
                                value: false,
                              })
                            );

                            setStates({
                              selectedId: 0,
                              serviceProvider: {
                                name: "",
                                id: 0,
                              },
                              driverCode: {
                                name: Uncard[0]?.sptDriverCode,
                                id: Uncard[0]?.id,
                              },
                              driver: { name: "", id: 0 },
                              vehicle: { name: "", id: 0 },
                              assignmentDate: dayjs().format(),
                              endDate: dayjs().format(),
                              remarks: "",
                              active: true,
                            });
                          }}
                          style={{ borderColor: borderTheme }}
                          className="border-[1px] h-[24px] w-[24px] p-0.5 rounded mr-1 "
                        >
                          <img
                            className="w-4 h-full flex items-center justify-center "
                            id="manArrow"
                            src={manArrow}
                            alt="reset"
                          />
                          <Tooltip
                            target="#manArrow"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                          >
                            <p className="h-2">
                              Click to assign this code to a driver
                            </p>
                          </Tooltip>
                        </span>

                        <span className="w-full ml-1 flex ">
                          <span
                            onClick={() => {
                              setUncard([]);
                            }}
                            style={{
                              height: 22.5,
                              borderWidth: 1,
                              width: 24,
                              borderColor: borderTheme,
                            }}
                            className=" flex justify-center hover:cursor-pointer border-gray-300 rounded "
                          >
                            <img
                              className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center "
                              src={reset}
                              alt="reset"
                            />
                          </span>
                          <span className="text-indigo-600 ml-3 flex flex-row text-xs ">
                            {`* Unassigned Codes: ${unassigned_codes_data.length}`}
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                </Space>
              </Form.Item>

              <DropdownGrid_template
                labelCol={2}
                label={"Driver"}
                isHide={false}
                cusBtnStyle={true}
                dropdownBtnStyles={{ width: 250 }}
                className="mt-[2px] mb-[3px]"
                defaultValue={states.driver.name}
                disabled={form_disable}
                columns={Driver_Col}
                gridData={driver_data}
                onRowClick={(e: any) => {
                  setState("driver", {
                    id: e.empIDpk,
                    name: e.empName,
                  });
                }}
              />

              <div className="" style={{ marginBottom: "3px" }}>
                <DateTemplate
                  selectedDate={dayjs(states.assignmentDate)}
                  changeDate={(date: Dayjs) => {
                    setState("assignmentDate", date.format());
                  }}
                  width={250}
                  label="Assignment Date"
                  span={false}
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                />

                <DateTemplate
                  selectedDate={dayjs(states.endDate)}
                  changeDate={(date: Dayjs) => {
                    setState("endDate", date.format());
                  }}
                  width={250}
                  label="End Date"
                  span={false}
                />
              </div>

              <Form.Item
                label={<p className="text-xs ">{"Remarks"}</p>}
                style={{ marginBottom: 0, marginTop: "" }}
              >
                <Form.Item
                  style={{
                    display: "inline-block",
                    marginBottom: "1px",
                    width: "calc(100%)",
                  }}
                  className=" mb-1 mr-2 "
                >
                  <div className="flex mt-[-2px]">
                    <TextArea
                      value={states.remarks}
                      onChange={(e: any) => {
                        setState("remarks", e.target.value);
                      }}
                      disabled={form_disable}
                      style={{
                        resize: "none",
                        maxHeight: 50,
                        minHeight: 50,
                      }}
                      className=" overflow-y-scroll resize-none"
                      rows={3}
                    />
                  </div>
                </Form.Item>
              </Form.Item>

              <BottomBtns
                useCallbackFunc
                additionalCheckBoxValue={[active]}
                onNewClick={() => {
                  newAccessPriv();
                }}
                outerDisabled={form_disable}
                addActiveBorders={true}
                checkboxChange={(value) => {
                  setState("active", value);
                }}
                saveHandler={() => SaveAccessPriv()}
                updateHandler={() => {
                  validateSave("update");
                }}
                span
              />
            </section>
          </Form>
        </div>
      </>
    );
  }
);

export default DriverCodeAssignmentsForm;
