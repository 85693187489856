import { forwardRef, useState } from "react";
import  Datagrid_template from "../../../../../../../templates/Datagrid";
import { modal_sms_TH } from "../data/vehicle-requests-data";
import { SendSMSAlertForm } from "./widgets/sendSMSAlertForm";
import useFetch from "../../../../../../../hooks/useFetch";

interface SMSALerts{
    chooseFromDropDown?:boolean
    selectedRecordFromDatagrid?:any
}

export const SendSMSAlertDetails = forwardRef(({chooseFromDropDown, selectedRecordFromDatagrid}:SMSALerts, ref)=>{

  const [form_height,set_form_height] = useState<number>(270);

    const [tableMode,setTableMode]=useState<boolean>(false)

    const [RecipientCriteria, setRecipientCriteria] = useState<{id:number, value:string}>({id:0, value:'Selected Staff'});
    const [Refresh, setRefresh] = useState(true)
    const [SMS_Alert_Data, SMS_AlertError, SMS_AlertLoading] = useFetch(`AsmTmsVehicleRequest/GetAllSmsRecipient?criteriaIndex=${RecipientCriteria.id}`,Refresh)    

    return(
        <div className="h-full">
            {!tableMode &&
            <section style={{height : form_height}}  className=" form border-b-4">
                <SendSMSAlertForm refresh={Refresh} setRefresh={setRefresh} criteria={RecipientCriteria} setCriteria={setRecipientCriteria} chooseFromDropDown={chooseFromDropDown} selectedRecordFromDatagrid={selectedRecordFromDatagrid}/>
            </section>}

            <section className=" px-2">
                <Datagrid_template 
                gridheight= {tableMode?573:250} 
                data = {SMS_Alert_Data} 
                columns = {modal_sms_TH}/>
            </section>
        </div>
    )
})