export const assets_requestClassColumns = [
  {
    id: 1,
    caption: "REQUEST CLASSIFICATION",
    dataField: "vrcName",
    datatype: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 4,
    caption: "ACTIVE",
    dataField: "vrcActive",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 5,
    caption: "REMARKS",
    dataField: "vrcRmks",
    datatype: "string",
    width: 5000,
    fixed: false,
    visible: true,
  },
];
export const assets_requestAreasColumns = [
  {
    id: 1,
    caption: "REQUEST AREA",
    dataField: "vraName",
    datatype: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 4,
    caption: "ACTIVE",
    dataField: "vraActive",
    datatype: "boolean",
    width: 90,
    fixed: true,
    visible: true,
  },
];
export const assets_unassignedColumns = [
  {
    id: 1,
    caption: "REG. No",
    dataField: "vdtRegNo",
    datatype: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "ACTIVE",
    dataField: "vdtActive",
    datatype: "boolean",
    width: 90,
    fixed: true,
    visible: true,
  },
  {
    id: 4,
    caption: "ASSIGNED?",
    dataField: "vdtAssigned",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 5,
    caption: "VEHICLE CLASS",
    dataField: "vhcShtName",
    datatype: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 6,
    caption: "VEHICLE MAKE",
    dataField: "DD",
    datatype: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 7,
    caption: "MODEL",
    dataField: "D",
    datatype: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 8,
    caption: "MANUFACTURER",
    dataField: "A",
    datatype: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 9,
    caption: "SEAT. CAP.",
    dataField: "A",
    datatype: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 10,
    caption: "REMARKS",
    dataField: "AFVFV",
    datatype: "string",
    width: 1000,
    fixed: false,
    visible: true,
  },
];
export const assets_assignedColumns = [
  {
    id: 1,
    caption: "REG. No",
    dataField: "vdtRegNo",
    datatype: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 3,
    caption: "ACTIVE",
    dataField: "vdtActive",
    datatype: "boolean",
    width: 90,
    fixed: true,
    visible: true,
  },
  {
    id: 4,
    caption: "ASSIGNED?",
    dataField: "vdtAssigned",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 5,
    caption: "VEHICLE CLASS",
    dataField: "vhcShtName",
    datatype: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 6,
    caption: "VEHICLE MAKE",
    dataField: "DD",
    datatype: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 7,
    caption: "MODEL",
    dataField: "D",
    datatype: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 8,
    caption: "MANUFACTURER",
    dataField: "A",
    datatype: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 9,
    caption: "SEAT. CAP.",
    dataField: "A",
    datatype: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 10,
    caption: "REMARKS",
    dataField: "AFVFV",
    datatype: "string",
    width: 1000,
    fixed: false,
    visible: true,
  },
];
