type chartTypes = "bar" | "line" | "area"

const mainChartColors = {
    borderColor: '#D2D6BF',
    labelColor: '#131416',
    opacityFrom: 0.45,
    opacityTo: 0,

    getColor: ['#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
        '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
        '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
        '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
        '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
        '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
        '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
        '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
        '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
        '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF']
}

type globalXAxisKeys = {
    xAxis: string

}


export const setApexchartOptions = <T extends globalXAxisKeys>(type: chartTypes, dataSource: T[]) => {

    const barChartOptions = {
        chart: {
            fontFamily: 'Inter, sans-serif',
            foreColor: mainChartColors.labelColor,
            toolbar: {
                show: true
            }

        },

        fill: {
            type: 'linear',

        },
        dataLabels: {

            enabled: true,
            offsetY: -28,
            offsetX: 0,
            style: {
                fontSize: '16px',
                colors: ['#B2B9E2'],
                fontWeight: 700,
            },
            background: {
                enabled: true,
                foreColor: '#2E0FF8',
                padding: 5,
                borderRadius: 5,
                // borderWidth: 1,
                // dropShadow: {
                //     enabled: true,
                //     top: 1,
                //     left: 1,
                //     blur: 1,
                //     color: '#000',
                //     opacity: 0.45
                // },

                // borderColor: mainChartColors.getColor[4],
                opacity: 0.3,

            },
        },
        tooltip: {
            // custom: function ({ series, seriesIndex, dataPointIndex, w }: { series: any, seriesIndex: any, dataPointIndex: any, w: any }) {
            //     return `<div class="p-4 bg-white border border-gray-200 rounded-lg shadow-lg">
            //     <div class="flex items-center justify-between">
            //         <h4 class="text-lg font-semibold">${w.config.series[seriesIndex].name}</h4>
            //         <span class="text-sm font-semibold">${w.config.xaxis.categories[dataPointIndex]}</span>
            //     </div>
            //     <div class="flex items
            //     -center justify-between mt-2">
            //         <span class="text-sm font-semibold">Value</span>
            //         <span class="text-sm font-semibold">${w.config.series[seriesIndex].data[dataPointIndex]}</span>
            //     </div>
            // </div>`;
            // },

            style: {
                fontSize: '16px',
                fontFamily: 'Inter, sans-serif',

            },
        },
        grid: {
            show: true,
            borderColor: mainChartColors.borderColor,
            strokeDashArray: 1,
            padding: {
                left: 35,
                bottom: 15
            }
        },

        markers: {

            size: 5,
            strokeColors: '#DA4C4C',
            hover: {
                size: undefined,
                sizeOffset: 3
            }
        },
        xaxis: {
            categories: dataSource.map((data: T) => data.xAxis),
            labels: {
                style: {
                    colors: [mainChartColors.labelColor],
                    fontSize: '14px',
                    fontWeight: 500,
                },
            },

            axisBorder: {
                color: mainChartColors.borderColor,
            },
            axisTicks: {
                color: mainChartColors.borderColor,
            },
            crosshairs: {
                show: true,
                position: 'back',
                stroke: {
                    color: mainChartColors.borderColor,
                    width: 1,
                    dashArray: 10,
                },
            },
        },
        yaxis: {
            labels: {
                show: true,
                style: {
                    colors: [mainChartColors.labelColor],
                    fontSize: '14px',
                    fontWeight: 500,
                },
                // formatter: function (value) {
                //     return '' + value;
                // }
            },
            // title: {
            //     text: 'Count',
            //     // rotate: 0,
            //     // offsetX: 0,
            //     offsetY: -200,

            //     style: {
            //         color: mainChartColors.labelColor,
            //         fontSize: '16px',
            //         fontWeight: 500,
            //         fontFamily: 'Inter, sans-serif',

            //     }
            // },

        },
        legend: {
            fontSize: '14px',
            fontWeight: 500,
            fontFamily: 'Inter, sans-serif',
            labels: {
                colors: [mainChartColors.labelColor]

            },
            itemMargin: {
                horizontal: 10
            },
            position: 'top',
            // formatter: function (val, opts) {
            //     return val + " - " + opts.w.globals.series[opts.seriesIndex]
            // }
            // floating: true,
        },
        plotOptions: {

            bar: {
                columnWidth: '60%',


                dataLabels: {
                    position: 'top', // top, center, bottom




                    // orientation: 'horizontal', // horizontal, vertical
                },

                horizontal: false,

            }
        },
        responsive: [
            {
                breakpoint: 1024,
                options: {
                    xaxis: {
                        labels: {
                            show: false
                        }
                    }
                }
            }
        ]
    } satisfies ApexCharts.ApexOptions;

    const lineChartOptions = {
        chart: {
            fontFamily: 'Inter, sans-serif',
            foreColor: mainChartColors.labelColor,
            toolbar: {
                show: true
            }
        },
        stroke: {
            curve: 'smooth'
        },
        dataLabels: {
            enabled: false
        },
        tooltip: {
            style: {
                fontSize: '16px',
                fontFamily: 'Inter, sans-serif',
            },
        },
        grid: {
            show: true,
            borderColor: mainChartColors.borderColor,
            strokeDashArray: 1,
            padding: {
                left: 35,
                bottom: 15
            }
        },
        markers: {
            size: 5,
            strokeColors: '#DA4C4C',
            hover: {
                size: undefined,
                sizeOffset: 3
            }
        },
        xaxis: {
            categories: dataSource.map((data: any) => data.xAxis),
            labels: {
                style: {
                    colors: [mainChartColors.labelColor],
                    fontSize: '14px',
                    fontWeight: 500,
                },
            },
            axisBorder: {
                color: mainChartColors.borderColor,
            },
            axisTicks: {
                color: mainChartColors.borderColor,
            },
            crosshairs: {
                show: true,
                position: 'back',
                stroke: {
                    color: mainChartColors.borderColor,
                    width: 1,
                    dashArray: 10,
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: [mainChartColors.labelColor],
                    fontSize: '14px',
                    fontWeight: 500,
                },
            },
        },
        legend: {
            fontSize: '14px',
            fontWeight: 500,
            fontFamily: 'Inter, sans-serif',
            labels: {
                colors: [mainChartColors.labelColor]
            },
            itemMargin: {
                horizontal: 10
            }
        },
        responsive: [
            {
                breakpoint: 1024,
                options: {
                    xaxis: {
                        labels: {
                            show: false
                        }
                    }
                }
            }
        ]
    } as ApexCharts.ApexOptions;


    switch (type) {
        case "bar":
            return barChartOptions;

        case "line":
            return lineChartOptions;

        default:
            return barChartOptions;

    }




}

