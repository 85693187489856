/* eslint-disable react/jsx-pascal-case */
import { forwardRef, useState } from "react";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import useFetch from "../../../../../hooks/useFetch";
import Datagrid_template from "../../../../../templates/Datagrid";
import { transport_staff_datagrid_allcolumns } from "./data/staff-details-data";
import { Transport_Staff_customtoolbar } from "./widgets/transport_staff_details_customToolbar";
import { useAccountInfo } from "../../../../../hooks/useAccountInfo";

export const TransportStaffDetails = forwardRef(({ a }: any, ref) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  //varying form height
  const datagrid_only_search_param = "table-only";
  const form_mode_search_param = "form-and-table";
  const [activeStatus, setActiveStatus] = useState({ temp: "", status: "" });
  const [refresh, setRefresh] = useState(false);
  const [userId, employID, userObj] = useAccountInfo();
  let IsStaff =
    userObj.empIsCeo ||
    userObj.empIsDeptadmin ||
    userObj.empIsDirector ||
    userObj.empIsManager ||
    userObj.empIsOrgAdmin ||
    userObj.empIsSectionAdmin ||
    userObj.empIsSupervisor ||
    userObj.empIsUnitAdmin
      ? false
      : true;
  // search param value
  const current_page_route =
    "/human-resource/entries/staff-management/staff-details";
  const [staffDetails, staffDetails_error, staffDetails_loading] = useFetch(
    `AssetTamVehicleAssignment/GetStaffDetails?Active=${activeStatus.status}`,
    // `AssetTamVehicleAssignment/GetStaffDetails?Active=${activeStatus.status}`,
    refresh
  );
  //------------------------Not used any where ---------------------------------------------------

  const toggle_staff_form = (): void => {
    const view = searchParams.get("view");

    view === null || view === datagrid_only_search_param
      ? navigate({
          pathname: current_page_route,
          search: createSearchParams({
            view: form_mode_search_param,
          }).toString(),
        })
      : navigate({
          pathname: current_page_route,
          search: createSearchParams({
            view: datagrid_only_search_param,
          }).toString(),
        });
  };
  return (
    <>
      <div style={{ height: 550 }} className="w-full px-2">
        {/*staff toolbar */}
        <div className="">
          <Transport_Staff_customtoolbar
            toggler={toggle_staff_form}
            checkOnChange={(val) => {
              setActiveStatus({ temp: val, status: activeStatus.status });
            }}
            handleFind={() => {
              setRefresh(!refresh);
              setActiveStatus({
                temp: activeStatus.temp,
                status: activeStatus.temp,
              });
            }}
          />
        </div>

        {/* datagrid */}
        <div className="dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base">
          <Datagrid_template
            disablePaging={true}
            columns={transport_staff_datagrid_allcolumns}
            data={staffDetails}
            gridheight={500}
          />
        </div>
      </div>
    </>
  );
});
