import { useEffect, useRef, useState } from "react";
import { FaBinoculars } from "react-icons/fa";
import "devextreme/dist/css/dx.light.css";
import { Tooltip } from "devextreme-react";
import { Checkbox } from "antd";

interface props {
  toggler?: () => void;
  handleDelete?: () => void; //delete handler
  handleSave?: () => void; //save handler
  handleNew?: () => void; //new handler
  handleUpdate?: () => void; //update handler
  searchTextOnchange?: (value: string) => void; //search text change
  searchCriteraOnchange?: (criteria: number) => void; //search criteria change
  startDateChanged?: (value: any) => void; //start date change
  endDateChanged?: (value: any) => void; //end date change
  checkOnChange?: (value: any) => void; //checkbox change
  handleFind?: () => void; //find handler
  handleRefresh?: () => void; //refresh main grid
  handlePrint?: () => void; // handle print functionality
  checkBoxAfterHelpOnchange?: (checkValue: boolean) => void; // event handler for checkbox after help
  changeStatus?: (e: any) => void;
  activeStatus?: boolean | undefined;
}

export const Transport_Staff_customtoolbar = ({
  handleFind,
  checkOnChange,
}: props) => {
  const RightSection = useRef<any>();

  // Three state checkboxes
  const [indeterminate, setIndeterminate] = useState<any>(true);
  const [checkedBox, setCheckedBox] = useState<any>(false);
  const [checkValue, setCheckValue] = useState<boolean | string>(''); //actual value to use

  const onChange = (value: boolean) => {
    if (indeterminate === true) {
      setCheckedBox(true);
      setIndeterminate(false);
      setCheckValue(true);
    } else if (!indeterminate && checkedBox) {
      setCheckedBox(false);
      setIndeterminate(false);
      setCheckValue(false);
    } else if (!indeterminate && !checkedBox) {
      setCheckedBox(false);
      setIndeterminate(true);
      setCheckValue('');
    }
  };

console.log('@#$',checkValue)

  useEffect(() => {
    checkOnChange && checkOnChange(checkValue);
  }, [checkValue]);

  return (
    <div
      style={{ borderWidth: "1px", height: 32 }}
      className=" px-0.5 rounded mt-0.5 w-full h-full flex justify-between border-gray-200 mb-1"
    >
      <div
        ref={RightSection}
        style={{ width: "calc(100% - 500px)" }}
        className="flex items-center"
      >
        <ul
          style={{ width: "calc(100% - 340px)" }}
          className="flex  w-full mr-4"
        >
          <section className="flex justify-start items-center">
            <li className="ml-4 px-1 flex items-center flex-row ">
              <label style={{ width: "80px" }} className=" text-xs">
                Active Status :
              </label>
            </li>

            <li
              style={{ borderWidth: "1px" }}
              id="state"
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1  text-gray-600 rounded flex items-center"
            >
              <Checkbox
                checked={checkedBox}
                indeterminate={indeterminate}
                onChange={(checkValue: any) => {
                  onChange(checkValue);
                }}
              />
              <Tooltip
                target="#state"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Show Status</p>
              </Tooltip>
            </li>
            <li
              onClick={() => {
                handleFind && handleFind();
              }}
              id="find"
              style={{ borderWidth: "1px" }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
                <FaBinoculars size={15.5} color="#007AFF" />
              </span>
              <Tooltip
                target="#find"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Find</p>
              </Tooltip>
            </li>
          </section>
        </ul>
      </div>
    </div>
  );
};
