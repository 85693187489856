export const appForms_cols = [
  {
    id: 0,
    caption: "APPLICATION MODULE ID",
    dataField: "apmIDpk",
    dataType: "",
    fixed: true,
    visible: true,
    width: 120,
  },
  {
    id: 1,
    caption: "PROJECT",
    dataField: "prjShtName",
    dataType: "string",
    fixed: true,
    visible: true,
    width: 120,
  },
  {
    id: 2,
    caption: "MODULE",
    dataField: "apmName",
    dataType: "string",
    fixed: true,
    visible: true,
    width: 120,
  },
  {
    id: 3,
    caption: "SHORT NAME",
    dataField: "apmShtName",
    dataType: "string",
    fixed: true,
    visible: true,
    width: 120,
  },
  {
    id: 4,
    caption: "CODE",
    dataField: "apmCode",
    dataType: "string",
    fixed: true,
    visible: true,
    width: 120,
  },
  {
    id: 5,
    caption: "ORDER",
    dataField: "apmOrder",
    dataType: "",
    fixed: "",
    visible: true,
    width: 120,
  },
  {
    id: 6,
    caption: "HMO",
    dataField: "apmHelpModuleOrder",
    dataType: "",
    fixed: true,
    visible: true,
    width: 120,
  },
  {
    id: 7,
    caption: "ACTIVE?",
    dataField: "apmActive",
    dataType: "boolean",
    fixed: false,
    visible: true,
    width: 120,
  },
  {
    id: 8,
    caption: "HELP MODULE",
    dataField: "apmIsHelpModule",
    dataType: "boolean",
    fixed: false,
    visible: true,
    width: 120,
  },
  {
    id: 9,
    caption: "REMARKS",
    dataField: "apmRmks",
    dataType: "string",
    fixed: false,
    visible: true,
    width: 120,
  },
];

export const generalSettings_cols = [
  {
    id: 0,
    caption: "SAL ID",
    dataField: "salIDpk",
    dataType: "number",
    fixed: true,
    visible: true,
    width: 120,
  },
  {
    id: 1,
    caption: "DATE",
    dataField: "salDate",
    dataType: "date",
    fixed: true,
    visible: true,
    width: 120,
  },
  {
    id: 2,
    caption: "FORM",
    dataField: "salForm",
    dataType: "string",
    fixed: true,
    visible: true,
    width: 120,
  },
  {
    id: 3,
    caption: "APPROVAL CATEGORY",
    dataField: "salApprovalCat",
    dataType: "string",
    fixed: true,
    visible: true,
    width: 120,
  },
  {
    id: 4,
    caption: "LOWER LIMIT",
    dataField: "salLowerLimit",
    dataType: "number",
    fixed: true,
    visible: true,
    width: 120,
  },
  {
    id: 5,
    caption: "UPPER LIMIT",
    dataField: "salUpperLimit",
    dataType: "number",
    fixed: true,
    visible: true,
    width: 120,
  },
  {
    id: 6,
    caption: "ACTIVE?",
    dataField: "salActive",
    dataType: "boolean",
    fixed: false,
    visible: true,
    width: 120,
  },
  {
    id: 7,
    caption: "REMARKS",
    dataField: "salRmks",
    dataType: "string",
    fixed: false,
    visible: true,
    width: 120,
  },
];