import { useState } from "react";
import { ModalTemplate } from "../templates/modal";
import React from "react";

const ErrorHandler = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [errMsg, setErrorMsg] = useState<string>(
    "Something went wrong. Try again later."
  );
  window.onerror = function (msg: any) {
    setErrorMsg(msg);
    setOpenModal(true);
    return true;
  };
  return (
    <ModalTemplate
      title="Unexpected Error"
      message={errMsg}
      open={openModal}
      okHandler={() => {
        setOpenModal(false);
      }}
      disableCancel={true}
    />
  );
};

export default ErrorHandler;
