import React from 'react';
import { ModalTemplate } from './modal';

interface ConfirmationModalProps {
  title: string;
  message: string;
  okText?: string;
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  title,
  message,
  okText = 'OK',
  open,
  onConfirm,
  onCancel,
}) => {
  return (
    <ModalTemplate
      icon="question"
      title={title}
      okText={okText}
      message={message}
      open={open}
      okHandler={onConfirm}
      cancelHandler={onCancel}
    />
  );
};

export default ConfirmationModal;
