import { requestConfig } from "../general/requestConfig";
import { api_url } from "../components/accessories/component_infos";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { userDataCrypt } from "./userDataEncrypt";

export const PatchDataFunc = async (url: string, payload: any) => {
  const updateResponse = await axios.patch(
    `${api_url}/${url}`,
    payload,
    requestConfig()
  );
  return updateResponse;
};

export const PatchDataPrivFunc = async (url: string, payload: any) => {
  // Send a PATCH request using axios.patch
  const privResponse = await axios.patch(
    `${api_url}/${url}`,
    payload,
    requestConfig()
  );

  console.log("ResssPPP", privResponse);
  // Return the response
  return privResponse;
};
