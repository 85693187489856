export const hr_setups_Gender_columns = [
    {id : 0, caption : 'ID',dataField : 'gndIdpk',datatype : 'number', width : 80, fixed : true, visible : false},
    {id : 1, caption : 'GENDER', dataField : 'gndName',datatype : 'string', width : 160, fixed : true , visible : true},
    {id : 2, caption : 'SHORT NAME', dataField : 'gndshtName',datatype : 'string', width : 160, fixed : true , visible : true},
    {id : 3, caption : 'ORDER', dataField : 'gndOrder',datatype : 'string', width : 70, fixed : true , visible : true},
    {id : 4, caption : 'ACTIVE', dataField : 'gndActive',datatype : 'boolean', width : 90, fixed : true , visible : true},
    {id : 5, caption : 'REMARKS',dataField : 'gndRmks',datatype : 'string', width : 5000, fixed : false , visible : true}
]

export const Gender_data = [
    {
        'ID' : 1,
        'GENDER' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'ORDER' : '4',
        'ACTIVE' : true,
        'REMARKS' : 'SFF'
    },
    {
        'ID' : 2,
        'GENDER' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'ORDER' : '4',
        'ACTIVE' : false,
        'REMARKS' : ''
    },
    {
        'ID' : 3,
        'GENDER' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'ORDER' : '4',
        'ACTIVE' : true,
        'REMARKS' : ''
    },
    {
        'ID' : 4,
        'GENDER' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'ORDER' : '4',
        'ACTIVE' : true,
        'REMARKS' : ''
    },
    {
        'ID' : 5,
        'GENDER' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'ORDER' : '4',
        'ACTIVE' : true,
        'REMARKS' : ''
    },
    {
        'ID' : 6,
        'GENDER' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'ORDER' : '4',
        'ACTIVE' : true,
        'REMARKS' : ''
    },
    {
        'ID' : 7,
        'GENDER' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'ORDER' : '4',
        'ACTIVE' : false,
        'REMARKS' : ''
    },
    {
        'ID' : 8,
        'GENDER' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'ORDER' : '4',
        'ACTIVE' : true,
        'REMARKS' : ''
    },
]