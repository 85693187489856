import { Checkbox, Form, Input, Select } from "antd";
import {
  checkboxTypes,
  datalistTypes,
  inputsTypes,
  selectsTypes,
} from "../types/selectsTypes";
import reset from "../../../../../assets/reset.png";
import { NewButton, SaveButton } from "../../../../accessories/buttons";
import { useEffect, useRef, useState } from "react";
import Hr_Setups_Name_Datalist from "./hr_setups_name_datalist";
import Hr_Setups_ShortName_Datalist from "./hr_setups_shortname_datalist";
import { CheckboxTemlate } from "../../../../../templates/checkbox";
import { InputsTemplate } from "../../../../../templates/input";
import { BottomBtns } from "../../../../../templates/butttons";
import { TextareaTemplate } from "../../../../../templates/textarea";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import { SelectsTemplate } from "../../../../../templates/select";
import { Hr_Setups_Datagrid_Template } from "../hr_setup_datagid_template";
import SetupsDatalistTemplat from "../setups_datalist_template";
import { setGeneralValue } from "../../../../../features/generalSlice";
import { useDispatch } from "react-redux";
import { useFormPriviledge } from "../../../../../hooks/useFormPriviledge";
import { useAccountInfo } from "../../../../../hooks/useAccountInfo";
import { ModalTemplate } from "../../../../../templates/modal";
import InputSelect_template from "../../../../../templates/InputSelect";

interface props {
  form_header: string;
  selects?: selectsTypes[];
  inputs?: inputsTypes[];
  selects_after_constants?: selectsTypes[];
  optionalnamelabel?: string;
  optionalShortnamelabel?: string;
  additionalBottomChckbox?: string;
  moreCheckboxes?: any;
  formWidth?: number;
  checkboxlist?: checkboxTypes[];
  labelCol?: number;
  setheight: (height: number) => void;
  postData?: () => void;
  updateData?: () => void;
  handleNew?: () => void;
  disableName?: boolean;
  disableShortName?: boolean;
  extraWidgets?: any;
  formTier?: string;
  postRoute?: string;
  deleteRoute?: string;
  updateRoute?: string;
  additionalCheckBoxVal?: any;
  inputsAfterConstants?: inputsTypes[];
  datalistsAfterConstants?: datalistTypes[];
  customDataSet?: { data: any[]; idExpr: string; nameExpr: string }; //custom data set for name value instances
}
const Hr_Setups_Form_Template = ({
  postData,
  datalistsAfterConstants,
  optionalShortnamelabel,
  updateData,
  inputsAfterConstants,
  postRoute,
  formTier = "1",
  form_header,
  checkboxlist,
  moreCheckboxes,
  handleNew,
  inputs,
  selects,
  labelCol,
  selects_after_constants,
  optionalnamelabel,
  formWidth,
  additionalBottomChckbox,
  setheight,
  disableName = false,
  disableShortName = false,
  extraWidgets,
  additionalCheckBoxVal,
}: props) => {

  const tier: string = useSelector((state: RootState) => state.general.tier); // get form tier

  const selectedRecordData: string = useSelector(
    (state: RootState) => state.general.selectedFieldData
  ); // get form tier
  const colorTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  ); // border theme
  const form_disable = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const [privtype, setprivtype] = useState<string>("save");
  const [empId, userId, userInfo] = useAccountInfo();
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privtype);
  const [modalState, setModalState] = useState<{
    open: boolean;
    title: string;
    message: string;
  }>({
    open: false,
    title: "",
    message: "",
  });

  const [leftSectionHeight, setLeftSectionHeight] = useState<number>();

  const formRef = useRef<any>(null);
  const leftForm = useRef<any>(null);

  useEffect(() => {
    setheight(formRef.current?.clientHeight + 10);
    setLeftSectionHeight(leftForm.current.clientHeight);
  }, []);

  const width = formWidth ?? 450;

  const newAccessPriv = () => {
    setprivtype("save");
    if (savePriv === true) {
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      // if (Object.keys(selectedRecordData).length < 1) {
      //   dispatch(
      //     setGeneralValue({ expr: "selectedFieldData", value: [] })
      //   );
      //   return;
      // }
      handleNew && handleNew();
      dispatch(setGeneralValue({ expr: "selectedFieldData", value: [] }));
    } else {
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      setModalState({
        open: true,
        title: "Access Denied!",
        message: "No Access Privileges",
      });
    }
  };

  const handleSave = () => {
    postData!();
  };

  const dispatch = useDispatch();

  const selectExprs = useSelector(
    (state: RootState) => state.general.selectExprs
  ); //select exprs
  const selectAfterExprs = useSelector(
    (state: RootState) => state.general.selectAfterExprs
  ); //select after exprs

  const textareaExpr: string = useSelector(
    (state: RootState) => state.general.inputExprs.remarksExpr
  ); // text area expr
  const orderExpr = useSelector(
    (state: RootState) => state.general.inputExprs.orderExpr
  ); //order expr

  const orderListExprs = useSelector(
    (state: RootState) => state.general.checkboxExprs
  ); // orderlist exprs

  const inputAfterExprs = useSelector(
    (state: RootState) => state.general.inputAfterExprs
  ); //inputs after exprs



  return (
    <div ref={formRef} className="px-2">
      <ModalTemplate
        icon="warning"
        disableCancel={true}
        message={modalState.message}
        title={modalState.title}
        open={modalState.open}
        okHandler={() => {
          setModalState({ ...modalState, open: false });
        }}
      />
      {/* FORM HEADER */}
      <p
        id={"form_header"}
        style={{ borderColor: colorTheme }}
        className="pt-1 border-[1px] border-b-0 pl-2 w-full bg-slate-100 font-semibold"
      >
        {form_header}
      </p>
      <Form
        className="w-full border-[1px] border-slate-100 rounded-b flex justify-between py-1 px-2"
        style={{ borderColor: colorTheme }}
        name="basic"
        labelCol={labelCol ? { span: labelCol } : { span: 5 }}
        wrapperCol={{ span: 19 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        size="small"
      >
        {/* inputs section */}
        {/* selects before constans controls */}
        <section className="" style={{ width: width }} ref={leftForm}>
          {extraWidgets?.map((data: any) => {
            return (
              <Form.Item
                key={data.id}
                className="mb-0"
                label={
                  <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                    {data.label}
                  </p>
                }
                name={data.label}
                rules={[{ required: false }]}
              >
                <div className="flex flex-row justify-between">
                  {data.widget}
                </div>
              </Form.Item>
            );
          })}

          {/* dynamic input forms */}
          {inputs?.map((input: inputsTypes) => {
            return (
              <Form.Item
                key={input.id}
                className="mb-0"
                label={
                  <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                    {input.label}
                  </p>
                }
                name={input.label}
                rules={[{ required: false }]}
              >
                <div className="flex flex-row justify-between">
                  <Input className=" W-11/12" />

                  <span className="h-full w-4 ml-1 flex items-center justify-center hover:cursor-pointer hover">
                    {/* <img className='w w-9/12 h-9/12' src= {reset} alt="reset" /> */}
                  </span>
                </div>
              </Form.Item>
            );
          })}

          {/* selects [defined controls] */}
          {selects?.map(
            (
              {
                label,
                id,
                optionsData,
                diffExprs,
                idExpr,
                dataExpr,
                style,
              }: selectsTypes,
              index: number
            ) => {
              const idKey = selectExprs[index]["idExpr"]; //idExpr
              const nameKey = selectExprs[index]["nameExpr"]; //dataexpr
              return (
                <div className=" mb-0.5 ">
                  <SelectsTemplate
                    useCallFunc={false}
                    diffExprs={
                      diffExprs && {
                        idKey: diffExprs?.idKey,
                        valueKey: diffExprs?.valueKey,
                      }
                    }
                    options={optionsData}
                    index={index}
                    style={style}
                    idexpr={idExpr ?? idKey} // ?? idKey
                    dataexp={dataExpr ?? nameKey} //?? nameKey
                    key={index}
                    label={label}
                  />
                </div>
              );
            }
          )}

          {/* constant controls */}
          {!disableName && (
            <div className=" mb-1">
              <Hr_Setups_Name_Datalist
                nameValue={optionalnamelabel ? optionalnamelabel : "Name"}
              />
            </div>
          )}
          {!disableShortName && (
            <div className=" mb-1">
              <Hr_Setups_ShortName_Datalist
                span={selects_after_constants ? false : true}
                nameValue={
                  optionalShortnamelabel ? optionalShortnamelabel : "Short Name"
                }
              />
            </div>
          )}
          {/* Let order accept numbers only */}
          <InputsTemplate
            inputStyle={{ height: 24, width: "100%" }}
            numberOnly={false}
            nameExpr={orderExpr}
            expr="order"
            span={selects_after_constants ? false : true}
            label={"Order"}
            labelColPan={labelCol ?? 5}
          />
          {/* <Hr_Setups_order_input/> */}

          {/* datalists after constants */}
          {datalistsAfterConstants?.map(
            ({ label, id, optionsData, nameExpr, idExpr }: datalistTypes) => {
              console.log(optionsData);
              console.log(nameExpr);
              return (
                <div key={id} className=" mt-1">
                  {/* <SetupsDatalistTemplat
                    datalistStyle={{ height: 24 }}
                    nameExpr={nameExpr!}
                    options={optionsData}
                    listid={label}
                    refresh={false}
                    label={label}
                  /> */}
                  <InputSelect_template
                    useCallFunc
                    options={optionsData}
                    selectStyle={{ height: 24 }}
                    idexpr={idExpr}
                    dataexp={nameExpr!}
                    key={label}
                    label={label}
                    span={false}
                  />
                </div>
              );
            }
          )}

          {/* selects after constant controls */}
          <div className=" mt-1">
            {selects_after_constants?.map(
              (
                {
                  label,
                  id,
                  optionsData,
                  idExpr,
                  dataExpr,
                  diffExprs,
                }: selectsTypes,
                index: number
              ) => {
                return (
                  <>
                    <SelectsTemplate
                      diffExprs={diffExprs}
                      options={optionsData}
                      index={`After${index}`}
                      idexpr={idExpr}
                      dataexp={dataExpr}
                      key={index}
                      label={label}
                    />
                  </>
                );
              }
            )}
          </div>

          {/* dynamic input forms */}
          {inputsAfterConstants?.map(
            (
              {
                label,
                id,
                style,
                disabled,
                defaultValue,
                nameKey,
              }: inputsTypes,
              index: number
            ) => {
              return (
                <div key={id} className=" mt-1 mb-0.5">
                  <InputsTemplate
                    defaultValue={defaultValue}
                    disabledStatus={disabled && disabled}
                    expr={`inputAfter${index}`}
                    nameExpr={nameKey}
                    span
                    inputStyle={{ ...style, height: 24 }}
                    label={label}
                  />
                </div>
              );
            }
          )}

          {moreCheckboxes && (
            <CheckboxTemlate
              nameExpr={moreCheckboxes.label}
              inlineNameExpr={[
                moreCheckboxes ?? moreCheckboxes.label,
                moreCheckboxes.label2 ?? "",
              ]}
              span={false}
              label={moreCheckboxes.label}
              label2={moreCheckboxes.label2}
              withBorders={true}
            />
          )}

          {checkboxlist?.map(
            (
              { label, label2, nameExpr, nameExpr2 }: checkboxTypes,
              index: number
            ) => {
              const idKey = orderListExprs[index]["idExpr"]; //idExpr
              const nameKey = orderListExprs[index]["nameExpr"]; //dataexpr
              return (
                // template can handle single label2 instance
                <div
                  style={{ borderColor: colorTheme }}
                  key={index}
                  className=" mb-1"
                >
                  <CheckboxTemlate
                    span={false}
                    useCallbackFunc={false}
                    setCheckboxOnchange={() => { }}
                    id={index}
                    label2={label2}
                    nameExpr={nameExpr ?? nameKey}
                    inlineNameExpr={[nameExpr ?? nameKey, nameExpr2 ?? ""]}
                    key={index}
                    label={label}
                    withBorders={true}
                  />
                </div>
              );
            }
          )}

          <p className=" h-0.5"></p>
          {/* Checkboxes and buttons */}
          {!additionalBottomChckbox && (
            <BottomBtns
              outerDisabled={form_disable}
              useCallbackFunc={false}

              onNewClick={() => {
                newAccessPriv();

              }}
              onCancelClick={() => { }}
              updateHandler={updateData}
              saveHandler={handleSave}
              span={!selects_after_constants ? true : false}
              addActiveBorders={true}
              additionalCheckBoxValue={additionalCheckBoxVal}
            />
          )}
          {additionalBottomChckbox && (
            <BottomBtns
              outerDisabled={form_disable}
              useCallbackFunc={false}
              onNewClick={() => {
                newAccessPriv();
              }}
              onCancelClick={() => { }}
              additionalBottomChckbox={additionalBottomChckbox}
              updateHandler={updateData}
              saveHandler={handleSave}
              span
              addActiveBorders={true}
              additionalCheckBoxValue={additionalCheckBoxVal}
            />
          )}
        </section>

        {/* remarks */}
        <section style={{ width: `calc(100% - ${width}px)` }}>
          <TextareaTemplate
            MarginLeft={40}
            dataExpr={textareaExpr}
            label="Remarks"
            height={leftSectionHeight!}
          />
        </section>
      </Form>
    </div>
  );
};

export default Hr_Setups_Form_Template;
