
import reset from "../../../../../assets/reset.png";

export const RefreshButton = ({ onClick }: { onClick?: () => void }) => {
    return (
        <span
            onClick={onClick}
            style={{
                height: 22.5,
                marginBottom: 2,
                borderWidth: 1,
                width: 24,
            }}
            className="flex justify-center hover:cursor-pointer border-gray-300 rounded "
        >
            <img
                className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                src={reset}
                alt="reset"
            />
        </span>
    );
}