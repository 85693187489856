import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncGet, GetDataFunc } from "../../../functions/get";

interface maintainanceActivityStates {
  registration_No: any | null | undefined;
  maintainance_Type: any | null | undefined;
  request: any | null | undefined;
  arrivalCondition: any | null | undefined;
  reportingDriver: any | null | undefined;
  debitDept: any | null | undefined;
  debitSection: any | null | undefined;
  isLoading: boolean;
  isPending: boolean;
  isError: boolean;
}

let initialState: maintainanceActivityStates = {
  registration_No: [],
  maintainance_Type: [],
  request: [],
  arrivalCondition: [],
  reportingDriver: [],
  debitDept: [],
  debitSection: [],
  isLoading: false,
  isError: false,
  isPending: false,
};

export const FetchRegistration_No = createAsyncThunk<
  maintainanceActivityStates,
  string,
  { rejectValue: string }
>("registration/fetch", async (search: string) => {
  try {
    var Reg_response = await AsyncGet(
      `AsmTmsVehicleSimCardAssignment/AsmTmsVehicleSimCardAssignmentGetAllVehicleRegNos?pagesize=20&pagenumber=1&Search=${search}`
    );
    if (Reg_response?.status === true) {
      return Reg_response?.data;
    }
  } catch {
    return Reg_response?.data;
  }
});
export const FetchMaintainance_Type = createAsyncThunk<
  maintainanceActivityStates,
  string,
  { rejectValue: string }
>("maintainanceType/fetch", async (search: string) => {
  try {
    var MT_response = await AsyncGet(
      `AsmTmsEntMaintenanceActivity/GetEntAsmTmsLueMaintenanceTypes?pagesize=20&pagenumber=1&Search=${search}`
    );
    if (MT_response?.status === true) {
      return MT_response?.data;
    }
  } catch {
    return MT_response?.data;
  }
});
export const FetchRequest = createAsyncThunk<
  maintainanceActivityStates,
  string,
  { rejectValue: string }
>("request/fetch", async () => {
  try {
    var request_response = await AsyncGet(``);
    if (request_response?.status === true) {
      return request_response?.data;
    }
  } catch {
    return request_response?.data;
  }
});
export const FetchArrivalCondition = createAsyncThunk<
  maintainanceActivityStates,
  string,
  { rejectValue: string }
>("arrivalCondition/fetch", async (search: string) => {
  try {
    var ArrConResponse = await AsyncGet(
      `AsmTmsEntMaintenanceActivity/GetEntAsmTmsLueArrivalConditions?pagesize=20&pagenumber=1&Search=${search}`
    );
    if (ArrConResponse.status === true) {
      return ArrConResponse?.data;
    }
  } catch {
    return ArrConResponse?.data;
  }
});
export const FetchReportingDriver = createAsyncThunk<
  maintainanceActivityStates,
  string,
  { rejectValue: string }
>("reportingDriver/fetch", async (search: string) => {
  try {
    var R_DriverResponse = await AsyncGet(
      `AsmTmsEntMaintenanceActivity/GetEntAsmTmsLueReportingDrivers?pagesize=20&pagenumber=1&Search=${search}`
    );
    if (R_DriverResponse.status === true) {
      return R_DriverResponse?.data;
    }
  } catch {
    return R_DriverResponse?.data;
  }
});
export const FetchDebitDepartment = createAsyncThunk<
  maintainanceActivityStates,
  string,
  { rejectValue: string }
>("DebitDepartment/fetch", async () => {
  try {
    var Debit_Department = await AsyncGet(``);
    if (Debit_Department.status === true) {
      return Debit_Department?.data;
    }
  } catch {
    return Debit_Department?.data;
  }
});
export const FetchDebitSection = createAsyncThunk<
  maintainanceActivityStates,
  string,
  { rejectValue: string }
>("debitSection/fetch", async () => {
  try {
    var Debit_SectionResponse = await AsyncGet(``);
    if (Debit_SectionResponse.status === true) {
      return Debit_SectionResponse?.data;
    }
  } catch {
    return Debit_SectionResponse?.data;
  }
});

const maintenanceActivitiesSlice = createSlice({
  name: "maintenanceActiveSlice",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      //-------------FetchRegistration_No---------------
      .addCase(FetchRegistration_No.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(FetchRegistration_No.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.registration_No = action.payload;
      })
      .addCase(FetchRegistration_No.rejected, (state) => {
        state.isError = true;
        state.isLoading = false;
      })
      //------------FetchMaintainance_Type----------------
      .addCase(FetchMaintainance_Type.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(FetchMaintainance_Type.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.maintainance_Type = action.payload;
      })
      .addCase(FetchMaintainance_Type.rejected, (state) => {
        state.isError = true;
        state.isLoading = false;
      })
      //-------------FetchRequest-------------------------
      .addCase(FetchRequest.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(FetchRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.request = action.payload;
      })
      .addCase(FetchRequest.rejected, (state) => {
        state.isError = true;
        state.isLoading = false;
      })
      //---------------FetchArrivalCondition-------------
      .addCase(FetchArrivalCondition.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(FetchArrivalCondition.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.arrivalCondition = action.payload;
      })
      .addCase(FetchArrivalCondition.rejected, (state) => {
        state.isError = true;
        state.isLoading = false;
      })
      //----------------FetchReportingDriver---------------
      .addCase(FetchReportingDriver.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(FetchReportingDriver.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.reportingDriver = action.payload;
      })
      .addCase(FetchReportingDriver.rejected, (state) => {
        state.isError = true;
        state.isLoading = false;
      })
      //------------------FetchDebitDepartment-------------
      .addCase(FetchDebitDepartment.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(FetchDebitDepartment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.debitDept = action.payload;
      })
      .addCase(FetchDebitDepartment.rejected, (state) => {
        state.isError = true;
        state.isLoading = false;
      })
      //------------------FetchDebitSection------------------
      .addCase(FetchDebitSection.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(FetchDebitSection.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.debitSection = action.payload;
      })
      .addCase(FetchDebitSection.rejected, (state) => {
        state.isError = true;
        state.isLoading = false;
      });
  },
});
export default maintenanceActivitiesSlice.reducer;
