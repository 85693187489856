import { Form, Space } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import useFetch from "../../../../../hooks/useFetch";
import { BottomBtns } from "../../../../../templates/butttons";
import { DateTemplate } from "../../../../../templates/date";
import { InputsTemplate } from "../../../../../templates/input";
import { ModalTemplate } from "../../../../../templates/modal";
import { SelectsTemplate } from "../../../../../templates/select";
import { TextareaTemplate } from "../../../../../templates/textarea";
import { api_url, saveModalData, updateModalData } from "../../../../accessories/component_infos";
import { UpdateDataFunc } from "../../../../../functions/update";
import { PostDataFunc  } from "../../../../../functions/post";
import { useAccountInfo } from "../../../../../hooks/useAccountInfo";
import { useBusyLoader } from "../../../../../hooks/useBusyLoader";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import { setGeneralValue } from "../../../../../features/generalSlice";
import { useDispatch } from "react-redux";



interface stateTypes {
    cvlIdpk:{id:number}
    Mission:{id:number,value:string}
    StartDate:any
    EndDate:any
    Category:{id:number,value:string}
    CoreValue:string
    ShtName:string
    Order:number
    Active:boolean
    Remarks:string
    Unit:{id:number,value:string}
    Section:{id:number,value:string}
    Department:{id:number,value:string}
}

interface props {
    selectedRowData ?: any
    refreshGrid:()=>void
}



export const CoreValuesForm = ({selectedRowData,refreshGrid}:props) => {

    useEffect(()=>{
        setAllState({...allState,['cvlIdpk']:{id:selectedRowData?.cvlIdpk}, ['Mission']: {id: selectedRowData?.cvlMissionIdfk, value: selectedRowData?.mvsMission},['StartDate']:dayjs(selectedRowData?.cvlStartDate),['EndDate']:dayjs(selectedRowData?.cvlEndDate),['Category']:{id:0,value:selectedRowData?.cvlCategory},['CoreValue']:selectedRowData?.cvlCoreValue,['ShtName']:selectedRowData?.cvlShtCoreValue,['Order']:selectedRowData?.cvlOrder,['Active']:selectedRowData?.cvlActive,['Remarks']:selectedRowData?.cvlRmks,['Unit']:{id:0,value:selectedRowData?.untName},['Section']:{id:0,value:selectedRowData?.sxnName},['Department']:{id:0,value:selectedRowData?.dptName}})
    },[selectedRowData])

    const editedDate = dayjs().format()
    const form_disable = useSelector((state: RootState) => state.general.formDisabled);
    const borderTheme = useSelector((state:RootState)=>state.general.cssBorderClass)
    const dispatch = useDispatch();
    
    const[cmbMission_data,cmbMission_error,cmbMission_loading] = useFetch(`PfmCoreValues/PopCmbMission`)
    const[category_data,category_error,category_loading]=useFetch(`PfmCoreValues/GetAllCategory`)
    const[dept_data,dept_error,dept_loading]=useFetch(`PfmCoreValues/GetAllDepartments`)
    const[sxn_data,sxn_error,sxn_loading]=useFetch(`PfmCoreValues/GetAllSection`)
    const[unit_data,unit_error,unit_loading]=useFetch(`PfmCoreValues/GetAllUnits`)
    const[icon,setIcon]=useState<'warning'|'success'|'question'>('warning')
    const[openModel,setOpenModal]=useState<boolean>(false)
    const[modalData,setModalData]=useState<any>(saveModalData)
    const[cancelButton,setCancelButton]=useState<boolean>(false)

    const[employeeId, userId] = useAccountInfo();
    const[busyLoader, setBusyLoader] = useBusyLoader();

    const[allState,setAllState]=useState<stateTypes>({
        cvlIdpk:{id:0},
        Mission:{id:0,value:''},
        StartDate:editedDate,
        EndDate:editedDate,
        Category:{id:0,value:''},
        CoreValue:'',
        ShtName:'',
        Order:0,
        Active:true,
        Remarks:'',
        Unit:{id:0,value:''},
        Section:{id:0,value:''},
        Department:{id:0,value:''}
    })


    const resetFields =()=>{
        setAllState({...allState,
            ['cvlIdpk']:{id:0},
            ['CoreValue']:'',
            ['ShtName']:'',
            ['Remarks']:'',
             ['Order']:0,
             ['Mission']:{id:0,value:''},
             ['Category']:{id:0,value:''},
             ['Unit']:{id:0,value:''},
             ['Section']:{id:0,value:''},
            ['Department']:{id:0,value:''},
            ['Active']: true,
            ['StartDate'] : editedDate,
            ['EndDate'] : editedDate,
        })
    }

    const ValidateSave = ()=>{
        setIcon('warning');
        setCancelButton(true);
        if(!allState.Mission.value){
            setOpenModal(true);
            setModalData({
                message:'Please select a Mission/Vision',
                title:'Error',
                okText:'ok',
                cancelText:''
            })

        }else if(!allState.StartDate){
            setOpenModal(true);
            setModalData({
                message:'Please select a Start date',
                title:'Error',
                okText:'ok',
                cancelText:''
            })
        }else if(!allState.EndDate){
            setOpenModal(true);
            setModalData({
                message:'Please select a End date',
                title:'Error',
                okText:'ok',
                cancelText:''
            })
            setCancelButton(true)
        }else if(!allState.Category.value){
            setOpenModal(true);
            setModalData({
                message:'Please select a Category',
                title:'Error',
                okText:'ok',
                cancelText:''
            })
            setCancelButton(true)
        }else if(!allState.Department.value){
            setOpenModal(true);
            setModalData({
                message:'Please select a Department ',
                title:'Error',
                okText:'ok',
                cancelText:''
            })
            setCancelButton(true)
        }else if(!allState.Section.value){
            setOpenModal(true);
            setModalData({
                message:'Please select a Section ',
                title:'Error',
                okText:'ok',
                cancelText:''
            })
            setCancelButton(true)
        }else if(!allState.Unit.value){
            setOpenModal(true);
            setModalData({
                message:'Please select a Unit ',
                title:'Error',
                okText:'ok',
                cancelText:''
            })
            setCancelButton(true)
        }else if(!allState.Order){
            setOpenModal(true);
            setModalData({
                message:'Please Enter an Order ',
                title:'Error',
                okText:'ok',
                cancelText:''
            })
            setCancelButton(true)
        }else{
            setIcon('question')
            setModalData(saveModalData)
            setOpenModal(true)
            setCancelButton(false)

        }
    }


      const ValidatePut = ()=>{
        setCancelButton(true);
        setIcon('warning')
        if(!allState.Mission.value){
            setOpenModal(true);
            setModalData({
                message:'Please select a Mission/Vision',
                title:'Error',
                okText:'ok',
                cancelText:''
            })
            setCancelButton(true)
        }else if(!allState.StartDate){
            setOpenModal(true);
            setModalData({
                message:'Please select a Start date',
                title:'Error',
                okText:'ok',
                cancelText:''
            })
            setCancelButton(true)
        }else if(!allState.EndDate){
            setOpenModal(true);
            setModalData({
                message:'Please select a End date',
                title:'Error',
                okText:'ok',
                cancelText:''
            })
            setCancelButton(true)
        }else if(!allState.Category.value){
            setOpenModal(true);
            setModalData({
                message:'Please select a Category ',
                title:'Error',
                okText:'ok',
                cancelText:''
            })
            setCancelButton(true)
        }else if(!allState.Department.value){
            setOpenModal(true);
            setModalData({
                message:'Please select a Department ',
                title:'Error',
                okText:'ok',
                cancelText:''
            })
            setCancelButton(true);
        }else if(!allState.Section.value){
            setOpenModal(true);
            setModalData({
                message:'Please select a Section ',
                title:'Error',
                okText:'ok',
                cancelText:''
            })
            setCancelButton(true)
        }else if(!allState.Unit.value){
            setOpenModal(true);
            setModalData({
                message:'Please select a Unit ',
                title:'Error',
                okText:'ok',
                cancelText:''
            })
            setCancelButton(true)
        }else if(!allState.Order){
            setOpenModal(true);
            setModalData({
                message:'Please Enter an Order ',
                title:'Error',
                okText:'ok',
                cancelText:''
            })
            setCancelButton(true)
        }else{
           setIcon('question')
            setModalData(updateModalData)
            setOpenModal(true)
            setCancelButton(false)
        }
    }


    const saveData = async ()=>{
        try {
            setBusyLoader(updateModalData.progress);

            const res = await PostDataFunc (`PfmCoreValues/CreatePfmCoreValue`,{
                "cvlMissionIdfk": `${allState.Mission.id}`,
                "cvlCategory": allState.Category.value,
                "cvlCoreValue": allState.CoreValue,
                "cvlShtCoreValue": allState.ShtName,
                "cvlDeptIdfk": allState.Department.id,
                "cvlSectionIdfk": allState.Section.id,
                "cvlUnitIdfk": allState.Unit.id,
                "cvlStartDate": dayjs(allState.StartDate).format(),
                "cvlEndDate": dayjs(allState.EndDate).format(),
                "cvlOrder": allState.Order,
                "cvlActive": allState.Active,
                "cvlRmks": allState.Remarks,
                "cvlCreatedBy": userId
            })
    
            setIcon('success')
            setOpenModal(true)
            setCancelButton(true)
            setModalData({
                message:'Record saved successfully',
                title:'Success',
                okText:'ok',
                cancelText:''
            })
            refreshGrid()
            setCancelButton(true)
        } 
        catch (error) {
            console.log(error);
            
            setIcon('warning')
            setOpenModal(true);
            setModalData({
                message: saveModalData.error.message,
                title: saveModalData.error.title,
                okText:'ok',
            })
            setCancelButton(true)
        }
        finally{
            setBusyLoader('');
        }
    }


    const PutData = async ()=>{
        try {
            setBusyLoader(updateModalData.progress);

            const res = await UpdateDataFunc(`PfmCoreValues/UpdatePfmCoreValue/${allState.cvlIdpk.id}`,{
                "cvlMissionIdfk": `${allState.Mission.id}`,
                "cvlCategory": allState.Category.value,
                "cvlCoreValue": allState.CoreValue,
                "cvlShtCoreValue": allState.ShtName,
                "cvlDeptIdfk": allState.Department.id,
                "cvlSectionIdfk": allState.Section.id,
                "cvlUnitIdfk": allState.Unit.id,
                "cvlStartDate": dayjs(allState.StartDate).format(),
                "cvlEndDate": dayjs(allState.EndDate).format(),
                "cvlOrder": allState.Order,
                "cvlActive": allState.Active,
                "cvlRmks": allState.Remarks,
                "cvlEditedBy": userId
            })

            setIcon('success')
            setOpenModal(true)
            setModalData({
                message:'Record updated successfully',
                title:'Success',
                okText:'ok',
                cancelText:'Cancel'
            })
            setCancelButton(true)
            refreshGrid()
        } 
        catch (error) {
            console.log(error);
            
            setIcon('warning')
            setOpenModal(true);
            setModalData({
                message: updateModalData.error.message,
                title: updateModalData.error.title,
                okText:'ok',
            })
            setCancelButton(true)
        }
        finally{
            setBusyLoader('');
        }
    }
    return (<>

    <ModalTemplate title={modalData.title} okText={modalData.okText} message={modalData.message} icon={icon} open={openModel} okHandler={()=>{modalData === saveModalData? saveData(): modalData === updateModalData? PutData(): setOpenModal(false) }} disableCancel={cancelButton} cancelHandler={()=>{setOpenModal(false)}} cancelText={modalData.cancelText}/>
        <div className="px-2">
            {/* FORM HEADER */}
            <p id={"form_header"} style={{borderColor: borderTheme}} className='pt-1 border-[1px] border-b-0 px-2 w-full font-semibold bg-slate-100'>{'Core Values'}</p>
            <Form
                style={{borderColor: borderTheme}}
                className='w-full  border-[1px] border-slate-100 rounded flex justify-between py-1 px-2 '
                name="basic"
                labelCol={{ span: 3 }}
                wrapperCol={{ span: 25 }}
                initialValues={{ remember: true }}
                autoComplete="off"
                size='small'
            >
                <section  className="" style={{width: '1000px'}} >
                    {/* mission/vison select */}
                    <Form.Item className="" label={<p className='text-xs'>{'Mission/Vision'}</p>} style={{ marginBottom: 0 }}>
                        <div className = 'w-1/2 mb-0.5'>
                            <SelectsTemplate options={cmbMission_data} idexpr={'mvsIdpk'} dataexp={'mvsMission'} selectedValue={(e:any)=>{ setAllState({...allState,['Mission']:{id:Number(e.mvsIdpk),value:e.mvsMission}});console.log(e)}} useCallFunc={true} placeHolder={allState.Mission.value}/>
                        </div>
                    </Form.Item>

                    {/* start and end dates */}
                    <Form.Item className="" label={<p className='text-xs'>{'Start Date'}</p>} style={{ marginBottom: 0 }}>
                        <div 
                        style={{
                            marginRight:20
                        }}
                        className="w-1/2 flex justify-between">
                            <div
                            style={{
                                width:'calc(38.5%)'
                            }}
                            className=" mb-0.5">
                                <DateTemplate showTime  changeDate={(e:any)=>{setAllState({...allState,['StartDate']:e})}} selectedDate={dayjs(allState.StartDate)} />
                            </div>

                            <div 
                            style={{
                                width:'calc(48%)',marginRight:27.5
                            }}
                            className=" ml-0.5">
                                <DateTemplate showTime label="End Date"  changeDate={(e:any)=>{setAllState({...allState,['EndDate']:e})}} selectedDate={dayjs(allState.EndDate)}/>
                            </div>
                        </div>
                    </Form.Item>

                    {/*category, org units..... selects */}
                    <Form.Item className="" label={<p className='text-xs'>{'Category'}</p>} style={{ marginBottom: 0 }}>
                       <main className=" flex justify-between mb-0.5">
                            <div className="  w-1/2 flex justify-between">
                                <span style={{width:'45%'}} className=" w-1/2">
                                    <SelectsTemplate  span options={category_data} dataexp={'cvlCategory'} idexpr={'cvlIDpk'} useCallFunc={true} selectedValue={(e:any)=>{
                                    console.log(e);setAllState({...allState,['Category']:{id:e.cvlIDpk,value:e.cvlCategory}}) }} placeHolder={allState.Category.value === null ? '':allState.Category.value }/>
                                </span>
                                
                                <span style={{width:'55%'}} className=" w-1/2">
                                    <SelectsTemplate label="Org Units"  options={dept_data} dataexp={'dptName'} useCallFunc={true} idexpr={'dptIdpk'} selectedValue={(e:any)=>{setAllState({...allState,['Department']:{id:e.dptIdpk,value:e.dptName}})}} placeHolder={allState.Department.value}/>
                                </span>
                            </div>
                            
                            
                            <div style={{width:'calc(50% - 4px)'}} className=" w-1/2 flex justify-between">
                                <span className=" w-1/2 ml-1">
                                    <SelectsTemplate span  useCallFunc={true}  options={sxn_data} dataexp={'sxnName'} idexpr={'sxnIdpk'} selectedValue={(e:any)=>{setAllState({...allState,['Section']:{id:e.sxnIdpk,value:e.sxnName}})}} placeHolder={allState.Section.value}/>
                                </span>
                                
                                <span className=" w-1/2">
                                    <SelectsTemplate useCallFunc={true}  options={unit_data} dataexp={'untName'} idexpr={'untIdpk'} selectedValue={(e:any)=>{setAllState({...allState,['Unit']:{id:e.untIdpk,value:e.untName}})}}  placeHolder={allState.Unit.value}/>
                                </span>
                            </div>
                       </main>
                    </Form.Item>

                    {/* core value */}
                    <div className=" mb-0.5">
                        <TextareaTemplate 
                        labelCol={3} 
                        height = {45} 
                        label = {'Core Value'} 
                        useCallbackFunc={true} 
                        setCallbackValue={(e:any)=>{
                            setAllState({...allState,['CoreValue']:e,['ShtName']:e})
                        }} 
                        defaultValue={allState.CoreValue} />
                    </div>

                    {/* short name */}
                    <div className=" mb-1">
                        <InputsTemplate inputStyle={{height:24}} span label = {'Short Name'} useCallbackFunc={true} orderOnchange={(e:any)=>{setAllState({...allState,['ShtName']:e})}}  defaultValue={allState.ShtName} />
                    </div>

                    {/* order ...buttons */}
                    <Form.Item className="" label={<p className='text-xs'>{'Order'}</p>} style={{ marginBottom: 0 }}>
                        <Space
                        className=" w-full flex items-start"
                        wrap>
                            {/* short name */}
                            <InputsTemplate inputType='number' inputStyle={{height:24}} useCallbackFunc={true}  orderOnchange={(e:any)=>{setAllState({...allState,['Order']:e})}} defaultValue={allState.Order ?? 0} span/>

                            <BottomBtns
                            addActiveBorders={true}
                            saveHandler={()=>{ValidateSave()}}
                            updateHandler={()=>{ValidatePut()}}
                            defaultValue={allState.Active}
                            onCancelClick={()=>{
                                dispatch(setGeneralValue({expr: 'formDisabled', value: true}));
                                dispatch(setGeneralValue({expr: 'updateMode', value: false}));
                            }}
                            onNewClick={()=>{
                                dispatch(setGeneralValue({expr: 'formDisabled', value: false}));
                                dispatch(setGeneralValue({expr: 'updateMode', value: false}));
                                resetFields()
                            }}
                            span 
                            style={{width:692}}  
                            useCallbackFunc={true} 
                            checkboxChange={(value:boolean,index:number)=>{
                                setAllState({...allState,['Active']:value})
                            }}
                            additionalCheckBoxValue={[allState.Active]}
                            />                           
                        </Space>
                    </Form.Item>
                    
                </section>

                {/* remarks */}
                <section className=" " style={{width : 'calc(100% - 1000px)'}}>
                    <TextareaTemplate readonly={form_disable} MarginLeft={30} height = {192} label = {'Remarks'} useCallbackFunc={true} defaultValue={allState.Remarks} setCallbackValue={(e:any)=>{setAllState({...allState,['Remarks']:e } )
                }}/>
                </section>
            </Form>
           
        </div>
        </>
    );
}


 