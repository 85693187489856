import { useEffect, useState } from "react";
import { ModalTemplate } from "../../templates/modal";

interface props {
  title?: string;
  message?: string;
}
export const WarningAlert = ({
  title = "Server Down",
  message = "Error fetching data. Please retry or contact your Systems Administrator",
}: props) => {
  useEffect(() => {
    setShow(true);
  }, []);
  const [show, setShow] = useState(true);

  return (
    <ModalTemplate
      disableCancel={true}
      title={title}
      okText={"Ok"}
      message={message}
      open={show}
      okHandler={() => {
        setShow(false);
      }}
      cancelHandler={() => {
        setShow(false);
      }}
    />
  );
};
