import { Checkbox } from "antd";
import { useEffect, useState } from "react";
import right from "../../../../../../../assets/right.png";
import left from "../../../../../../../assets/left.png";
import { examsType } from "../drivingTests";
import { RichTextViewerTemplate } from "../../../../../../../templates/textViever";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import { ExamsCountDown } from "./countDown";
interface props {
  questionIndex: number;
  body: examsType;
  answers: boolean[];
  onNextClick: () => void;
  onPreviousClick: () => void;
  setUserAnswers: (answers: boolean[]) => void;
  number?: number;
  submitExam: (e: "userConfirm" | "userSubmit" | "automated") => void;
  setExamsTime: (e: any) => void;
  examsTime: any;
  submit: any;
  examsInfo: any;
}

export const Questions: React.FC<props> = ({
  body,
  questionIndex,
  answers,
  onNextClick,
  onPreviousClick,
  setUserAnswers,
  number,
  submitExam,
  setExamsTime,
  examsTime,
  submit,
}) => {
  const answerAlphabets = ["A", "B", "C", "D"];
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  const defaultAnswerValues = [false, false, false, false, false, false];

  
  const [selectedAnswer, setSelectedAnswer] = useState<{
    index: number | null;
    values: boolean[];
  }>({ index: 0, values: defaultAnswerValues });

  
  const [selectedAnswers, setSelectedAnswers] = useState<{
    index: number[];
    values: boolean[];
  }>({ index: [], values: defaultAnswerValues });

  const resetAnswerFields = () => {
    setSelectedAnswers({ index: [], values: defaultAnswerValues });

    setSelectedAnswer({ index: 0, values: defaultAnswerValues });

    return true;
  };

  
  useEffect(() => {
    if (answers.length > 0) {
      if (body.exqMultipleCorrectAnswers) {
        setSelectedAnswers((current) => ({
          index: current.index,
          values: [...answers],
        }));
        return;
      }

      setSelectedAnswer((current) => ({
        index: current.index,
        values: [...answers],
      }));
    }
  }, [answers]);

  
  const updateAnswer = (selectedIndex: number, value: boolean) => {
    
    if (body.exqMultipleCorrectAnswers) {
      let updatedSelected = selectedAnswers.values;
      updatedSelected[selectedIndex] = value;

      setSelectedAnswers((current) => ({
        index: [...current.index, selectedIndex],
        values: [...updatedSelected],
      }));

      setUserAnswers([...updatedSelected]);
      return;
    }

    let updatedSelected = selectedAnswer.values;

    updatedSelected.forEach((check, index) => {
      if (index === selectedIndex) {
        updatedSelected[index] = value;
      } else {
        updatedSelected[index] = false;
      }
    });

    setSelectedAnswer({ index: selectedIndex, values: [...updatedSelected] });

    setUserAnswers([...updatedSelected]);
  };

  return (
    <div className="my-1 h-full flex flex-col">
      {/* header */}
      <header
        className="flex justify-between flex-row items-center"
        id="form_header"
      >
        <p className=" py-1 font-bold text-sm w-32 ">
          Question {questionIndex + 1} of {body.examDetails.totalQustions}
        </p>
        <p
          className="font-semibold rounded py-2 text-center"
          style={{ width: "calc(100% - 135px)", borderColor: borderTheme }}
        >
          {`Read the question carefully and ${
            body.exqMultipleCorrectAnswers
              ? `select the answer(s) that is/are most appropriate`
              : "select the correct answer"
          }`}
        </p>
      </header>

      {/* main */}
      <div className=" w-full flex flex-col overflow-hidden relative">
        {/* image */}
        <section className=" w-full h-36  mt-2">
          <RichTextViewerTemplate content={body.exqQuestion} height={155} />
        </section>

        {/* possible answers */}
        <section className=" mt-10 h-full  overflow-y-auto flex flex-col">
          <section className="w-full ">
            <p className=" py-3 pl-24 font-semibold">
              {body.exqMultipleCorrectAnswers
                ? `Tick all answers that apply`
                : "Choose the correct answer"}
            </p>
            {body.answers?.map(
              ({ exaIdpk, exaAnswer, exaNoOfLines }, index: number) => {
                return (
                  <>
                    <div key={exaIdpk} className=" mb-2 pl-3.5">
                      <span className="flex flex-row items-center">
                        <p className="  py-1 font-semibold w-12">
                          {answerAlphabets[index]}
                        </p>

                        <Checkbox
                          checked={
                            body.exqMultipleCorrectAnswers
                              ? selectedAnswers.values[index]
                              : selectedAnswer.values[index]
                          }
                          onChange={(e) => {
                            updateAnswer(index, e.target.checked);
                          }}
                          className="py-1 w-10"
                        />

                        <div
                          style={{
                            width: "(calc(100% - 90px))",
                            borderColor: borderTheme,
                          }}
                          className=" w-full"
                        >
                          <RichTextViewerTemplate
                            content={exaAnswer}
                            height={25 * (exaNoOfLines ?? 1)}
                          />
                        </div>
                      </span>
                    </div>
                  </>
                );
              }
            )}
          </section>

          <section className=" ml-24 mt-2 flex flex-row">
            {/* Previous button */}
            <button
              disabled={questionIndex <= 0 ? true : false}
              onClick={() => {
                onPreviousClick();
              }}
              className={`${
                questionIndex <= 0 && `hover:cursor-not-allowed opacity-50`
              } dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  mr-1 px-2 py-2 border-2 border-slate-200 rounded`}
            >
              <img alt="left-icon" className="pr-2" src={left} />
              Previous
            </button>

            {/* Next Button */}
            <button
              disabled={
                questionIndex >= body.examDetails.totalQustions - 1
                  ? true
                  : false
              }
              onClick={() => {
                resetAnswerFields() && onNextClick();
              }}
              className={`${
                questionIndex >= body.examDetails.totalQustions - 1 &&
                `hover:cursor-not-allowed opacity-50`
              } dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  mr-1 px-2 py-2 border-2 border-slate-200 rounded`}
            >
              Next
              <img alt="right-icon" className="pl-2" src={right} />
            </button>
          </section>

          {/* number */}
          <div className="mt-5 bg-gray-100">
            <p className="py-1 font-semibold text-center rounded">{number}</p>
          </div>

          <div className=" flex flex-row justify-end text-left w-full ">
            <div className="mt-3 p-5 w-1/2  flex flex-row justify-between items-center">
              <ExamsCountDown
                onFinish={() => {
                  submitExam("automated");
                }}
                onchange={(time) => {
                  setExamsTime(time);
                }}
                duration={examsTime}
              />

              <button
                onClick={() => {
                  submitExam("userConfirm");
                }}
                disabled={false}
                style={{ borderColor: borderTheme }}
                className={`bg-green-50 shadow-lg dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center font-semibold  mr-1 px-2.5 border-green-400 py-3 border-2 rounded`}
              >
                {`Submit`}
                <img alt="submit-icon" className="pl-2" src={submit} />
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
