export const backlogColumns = [
  {
    id: 0,
    caption: "ID",
    dataField: "fmgIDpk",
    dataType: "",
    width: 50,
    fixed: true,
    visible: true,
  },
  {
    id: 1,
    caption: "VRFD?",
    dataField: "fmgVerified",
    dataType: "boolean",
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "LCKD?",
    dataField: "fmgLocked",
    dataType: "boolean",
    fixed: true,
    visible: true,
  },
  {
    id: 3,
    caption: "AUTH?",
    dataField: "fmgAuthorised",
    dataType: "boolean",
    fixed: true,
    visible: true,
  },
  {
    id: 4,
    caption: "ACTIVE?",
    dataField: "fmgActive",
    dataType: "boolean",
    fixed: true,
    visible: true,
  },
  {
    id: 5,
    caption: "REG. NO",
    dataField: "vdtRegNo",
    dataType: "string",
    fixed: true,
    visible: true,
  },
  {
    id: 6,
    caption: "REQUEST DATE",
    dataField: "fmgDate",
    dataType: "datetime",
    width: 120,
    fixed: false,
    visible: true,
  },
  {
    id: 7,
    caption: "DISPENSE DATE",
    dataField: "fmgDateDispensed",
    dataType: "datetime",
    width: 120,
    fixed: false,
    visible: true,
  },
  {
    id: 8,
    caption: "RECEIVED BY",
    dataField: "driverName",
    dataType: "string",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 9,
    caption: "TIME",
    dataField: "fmgTime",
    dataType: "datetime",
    width: 120,
    fixed: false,
    visible: true,
  },
  {
    id: 10,
    caption: "PURCH. MODE",
    dataField: "pcmShtName",
    dataType: "string",
    width: 85,
    fixed: false,
    visible: true,
  },
  {
    id: 11,
    caption: "SERV. STATION",
    dataField: "sstName",
    dataType: "string",
    width: 120,
    fixed: false,
    visible: true,
  },
  {
    id: 12,
    caption: "PRODUCT",
    dataField: "prdName",
    dataType: "string",
    width: 85,
    fixed: false,
    visible: true,
  },
  {
    id: 13,
    caption: "QTY REQ.",
    dataField: "fmgQuantityRequested",
    dataType: "",
    width: 150,
    fixed: false,
    visible: true,
  },
  {
    id: 14,
    caption: "QTY AUTH.",
    dataField: "fmgQuantityAuthorised",
    dataType: "",
    width: 150,
    fixed: false,
    visible: true,
  },
  {
    id: 15,
    caption: "UNIT PRICE",
    dataField: "",
    dataType: "",
    width: 100,
    fixed: false,
    visible: false,
  },
  {
    id: 16,
    caption: "AMOUNT",
    dataField: "fmgAmount",
    dataType: "",
    width: 150,
    fixed: false,
    visible: true,
  },
  {
    id: 17,
    caption: "CARD No",
    dataField: "cdtCardNo",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 18,
    caption: "RECEIPT No",
    dataField: "fmgReceiptNo",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 19,
    caption: "BALANCE",
    dataField: "fmgBalance",
    dataType: "",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 20,
    caption: "SERV. PROV.",
    dataField: "spdName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 21,
    caption: "MONTH",
    dataField: "monthx",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 22,
    caption: "CURR. ODOM. RDN",
    dataField: "fmgCurrentOdometerReading",
    dataType: "",
    width: 120,
    fixed: false,
    visible: true,
  },
  {
    id: 23,
    caption: "PREV. ODOM. RDN",
    dataField: "fmgPreviousOdometerReading",
    dataType: "",
    width: 120,
    fixed: false,
    visible: true,
  },
  {
    id: 24,
    caption: "DISTANCE(km)",
    dataField: "distanceTravelled",
    dataType: "",
    width: 120,
    fixed: false,
    visible: true,
  },
  {
    id: 25,
    caption: "VEH. DEPT",
    dataField: "vehAssignDeptName",
    dataType: "string",
    width: 120,
    fixed: false,
    visible: true,
  },
  {
    id: 26,
    caption: "VEH. SECTION",
    dataField: "vehAssignSxnName",
    dataType: "string",
    width: 120,
    fixed: false,
    visible: true,
  },
  {
    id: 27,
    caption: "DRIVER DEPT",
    dataField: "dptShtName",
    dataType: "string",
    width: 120,
    fixed: false,
    visible: true,
  },
  {
    id: 28,
    caption: "DRIVER SECTION",
    dataField: "sxnShtName",
    dataType: "string",
    width: 120,
    fixed: false,
    visible: true,
  },
  {
    id: 29,
    caption: "DISPENSED BY",
    dataField: "dispenserEmpName",
    dataType: "string",
    width: 120,
    fixed: false,
    visible: true,
  },
  {
    id: 30,
    caption: "DISPENSER DEPT",
    dataField: "dispenserDptShtName",
    dataType: "string",
    width: 120,
    fixed: false,
    visible: true,
  },
  {
    id: 31,
    caption: "DISPENSER SECTION",
    dataField: "dispenserSxnShtName",
    dataType: "string",
    width: 120,
    fixed: false,
    visible: true,
  },
  {
    id: 32,
    caption: "AUTHORIZED BY",
    dataField: "authoriserEmpName",
    dataType: "string",
    width: 120,
    fixed: false,
    visible: true,
  },
  {
    id: 33,
    caption: "DATE AUTHORIZED",
    dataField: "fmgDateAuthorised",
    dataType: "datetime",
    width: 150,
    fixed: false,
    visible: true,
  },
  {
    id: 34,
    caption: "VEH. CLASS",
    dataField: "vhcName",
    dataType: "string",
    width: 120,
    fixed: false,
    visible: true,
  },
  {
    id: 35,
    caption: "VEH. MAKE",
    dataField: "vmkName",
    dataType: "string",
    width: 120,
    fixed: false,
    visible: true,
  },
  {
    id: 36,
    caption: "VEH. MODEL",
    dataField: "mdlName",
    dataType: "string",
    width: 120,
    fixed: false,
    visible: true,
  },
  {
    id: 37,
    caption: "MANUF.",
    dataField: "mftName",
    dataType: "string",
    width: 120,
    fixed: false,
    visible: true,
  },
  {
    id: 38,
    caption: "ASSIGNED?",
    dataField: "vdtAssigned",
    dataType: "boolean",
    width: 80,
    fixed: false,
    visible: true,
  },
  {
    id: 38,
    caption: "UOM?",
    dataField: "untOfMeasure",
    dataType: "string",
    width: 80,
    fixed: false,
    visible: true,
  },
  {
    id: 40,
    caption: "REMARKS",
    dataField: "fmgRmks",
    dataType: "string",
    width: 4000,
    fixed: false,
    visible: true,
  },
];
