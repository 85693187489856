import { Form, message, Radio, RadioChangeEvent, Tooltip } from "antd";
import { DropdownGrid_template } from "../../../../../../../templates/DropdownGrid";
import { DateTemplate } from "../../../../../../../templates/date";
import { InputsTemplate } from "../../../../../../../templates/input";
import {
  currentDate,
  dateFormat,
} from "../../../../../../accessories/component_infos";
import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import useFetch from "../../../../../../../hooks/useFetch";
import save from "../../../../../../../assets/floppy-disk.png";
import { useCrudFunc } from "../../../../../../../functions/crud";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { useBusyLoader } from "../../../../../../../hooks/useBusyLoader";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";

interface prop {
  openModal?: boolean;
  action?: (e: any) => void;
  selectedRecord?: any;
  refreshAction?: () => void;
}
interface stateTypes {
  selectedDepartureDate?: any;
  selectedArrivalDate?: any;
  selectedStartFuelLevel?: any;
  selectedEndFuelLevel?: any;
  refresh?: boolean;
  startOdometer?: any;
  endOdometer?: any;
}
type modalStateTypes = {
  disableCancel: boolean;
  icon: "warning" | "success" | "question";
  title: string;
  okText: string;
  cancelText: string;
  message: string;
  open: boolean;
  okHandler: () => void;
  cancelHandler: () => void;
};
export const OdometerModal = ({
  openModal,
  action,
  selectedRecord,
  refreshAction,
}: prop) => {
  const [PostDataFunc, UpdateDataFunc] = useCrudFunc();
  const [busyLoader, setBusyLoader] = useBusyLoader();
  const [employeeId, userId, userInfo] = useAccountInfo();
  const [fuelLevels, fuelLevelsErr, fuelLevelLoading] = useFetch(
    "AsmTmsVehicleLogBook/GetAllFuelLevels"
  );
  const [privType, setPrivType] = useState<string>("save");

  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);

  const [modalState, setModalState] = useState<modalStateTypes>({
    disableCancel: false,
    icon: "warning",
    title: "",
    okText: "",
    cancelText: "",
    message: "",
    open: false,
    okHandler: () => {},
    cancelHandler: () => {},
  });
  const [states, setState] = useState<stateTypes>({
    selectedDepartureDate: currentDate,
    selectedArrivalDate: currentDate,
    selectedStartFuelLevel: { id: 0, name: "" },
    selectedEndFuelLevel: { id: 0, name: "" },
    startOdometer: "",
    endOdometer: "",
    refresh: false,
  });
  const startAndFinalOdometerDiff = states.endOdometer - states.startOdometer;
  const updateState = (stateName: string, value: any) => {
    setState((currentState: any) => ({ ...currentState, [stateName]: value }));
  };

  function OdometerFieldValidation() {
    const conditions = [
      {
        condition: states.endOdometer === "" || states.startOdometer === "",
        message:
          "Please final odometer reading can not be empty and should be a number",
        title: "Required field.",
      },
      {
        condition:
          isNaN(Number(states.startOdometer)) === true ||
          isNaN(Number(states.endOdometer)) === true,
        message: "Please enter a valid number",
        title: "Required field.",
      },
      {
        condition: Number(states.startOdometer) > Number(states.endOdometer),
        message:
          "The arrival odometer can not be greater than the outgoing readings.",
        title: "Invalid odometer reading.",
      },
    ];
    let Error = conditions.find((conditions) => conditions.condition);
    if (Error) {
      setModalState((currentState) => ({
        ...currentState,
        open: false,
      }));
      setModalState((currentState) => ({
        ...currentState,
        icon: "warning",
        disableCancel: true,
        title: Error.title,
        message: Error.message,
        open: true,
        okHandler: () => {
          setModalState((currentState) => ({
            ...currentState,
            open: false,
          }));
        },
      }));
    } else {
      confirmFromUserBeforeUpdating();
    }
  }
  function showSuccessMessage(responseMessage: any) {
    setBusyLoader("");
    setModalState((currentState) => ({
      ...currentState,
      open: false,
    }));
    setModalState((currentState) => ({
      ...currentState,
      icon: "success",
      disableCancel: true,
      title: "Success",
      message: responseMessage.data?.message,
      okText: "Ok",
      open: true,
      okHandler: () => {
        setModalState((currentState) => ({
          ...currentState,
          open: false,
        }));
        setModalState((currentState) => ({
          ...currentState,
          icon: "success",
          disableCancel: true,
          title: "Update successful",
          message: "Arrival details updated successfully",
          okText: "Ok",
          open: true,
          okHandler: () => {
            setModalState((currentState) => ({
              ...currentState,
              open: false,
            }));
          },
        }));
      },
    }));
    action && action(false);
  }
  function showErrorMessage(errorMessage: any) {
    setBusyLoader("");
    setModalState((currentState) => ({
      ...currentState,
      open: false,
    }));
    setModalState((currentState) => ({
      ...currentState,
      icon: "warning",
      disableCancel: true,
      title: "Update failed",
      message: "Please try again later or contact the systems administrator.",
      okText: "Ok",
      open: true,
      okHandler: () => {
        setModalState((currentState) => ({
          ...currentState,
          open: false,
        }));
      },
    }));
    action && action(false);
  }
  async function saveOdometerReading() {
    setModalState((currentState) => ({ ...currentState, open: false }));
    setBusyLoader("Updating odometer reading...");
    await UpdateDataFunc(
      `AsmTmsVehicleLogBook/UpdateAsmTmsVehicleLogBook/${selectedRecord?.vlbIdpk}`,
      {
        vdtRegNo: selectedRecord?.vdtRegNo,
        vlbTimeIn: states.selectedArrivalDate,
        vlbTimeOut: states.selectedDepartureDate,
        vlbOdometerStart: Number(states.startOdometer),
        vlbOdometerEnd: Number(states.endOdometer),
        vlbStartFuelLevelIdfk: states.selectedStartFuelLevel.id,
        vlbFinalFuelLevelIdfk: states.selectedEndFuelLevel.id,
        vlbToRegionIdfk: selectedRecord?.toRegionId,
        vlbFromRegionIdfk: selectedRecord?.fromRegionId,
        vlbFromTown: selectedRecord?.vlbFromTown,
        vlbToTown: selectedRecord?.vlbToTown,
        vlbAuthorisedByEmpIdfk: selectedRecord?.authorisedByEmpIdfk,
        vlbDescription: selectedRecord?.vlbDescription,
        vlbRmks: selectedRecord?.vlbRmks,
      },
      `Edited the Asset Mgr-Transport-Vehicle Log Book with 
           time out:${states.selectedDepartureDate},
            time in:${states.selectedArrivalDate},
            start fuel level:${states.selectedStartFuelLevel.id},
            end fuel level:${states.selectedEndFuelLevel.id}, start Odometer:${states.startOdometer} and end odometer:${states.endOdometer}
          `
    )
      .then((res) => {
        refreshAction && refreshAction();
        showSuccessMessage(res);
      })
      .catch((err) => {
        showErrorMessage(err);
      });
    await UpdateDataFunc(
      `AsmTmsVehicleLogBook/UpdateFinalOdometerReading?vlbIDpk=${selectedRecord?.vlbIdpk}&userID=${userId}&regNo=${selectedRecord?.vdtRegNo}&loggedByEmpIDfk=${selectedRecord?.loggedByEmpIdfk}`,
      {},
      `Updated Final OdoMeter with a start odometer : ${selectedRecord?.vlbOdometerStart} and Final Odometer : ${selectedRecord?.vlbOdometerEnd}
                   with  userId: ${userId} and registration number : ${selectedRecord?.vdtRegNo} `
    );
  }
  function confirmFromUserBeforeUpdating() {
    setModalState((currentState) => ({
      ...currentState,
      icon: "question",
      disableCancel: false,
      title: "Update odometer reading",
      message: "Are you sure you want to update this odometer reading ?",
      okText: "Yes",
      cancelText: "No",
      open: true,
      okHandler: async () => {
        saveOdometerReading();
      },
      cancelHandler: () => {
        setModalState((currentState) => ({ ...currentState, open: false }));
      },
    }));
  }

  const AccessPriv_on_FinalOdo_UpdateButton = async () => {
    setPrivType("update");
    if (updatePriv === true) {
      OdometerFieldValidation();
    } else {
      updateState("modalState", {
        state: true,
        title: "Access Denied!",
        message: "No Access Privilege.",
      });
    }
  };
  const Populatefields = () => {
    setState((prev) => ({
      ...prev,
      selectedDepartureDate: selectedRecord?.vlbTimeOut,
      selectedArrivalDate: selectedRecord?.vlbTimeIn,
      selectedStartFuelLevel: {
        id: selectedRecord?.startFuelLevelId,
        name: "",
      },
      selectedEndFuelLevel: { id: selectedRecord?.finalFuelLevelId, name: "" },
      startOdometer: selectedRecord?.vlbOdometerStart,
      endOdometer: selectedRecord?.vlbOdometerEnd,
    }));
  };
  useEffect(() => {
    Populatefields();
  }, [selectedRecord]);
  return (
    <>
      <ModalTemplate
        disableCancel={modalState.disableCancel}
        icon={modalState.icon}
        title={modalState.title}
        cancelText={modalState.cancelText}
        okText={modalState.okText}
        message={modalState.message}
        open={modalState.open}
        okHandler={modalState.okHandler}
        cancelHandler={modalState.cancelHandler}
      />
      <div className="py-2 flex ">
        <Form
          name="complex-form"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 24 }}
          size={"small"}
          className="ml-2 flex flex-col gap-0.5"
          style={{ width: "500px" }}
        >
          <InputsTemplate
            id="reg number"
            numberOnly={false}
            cusWidth={true}
            labelColPan={7}
            inputStyle={{ height: 24, maxWidth: "90px" }}
            defaultValue={selectedRecord?.vdtRegNo}
            span={true}
            useCallbackFunc
            label={"Reg. No"}
            disabledStatus={true}
          />
          <DateTemplate
            width={"220px"}
            label="Departure Time"
            changeDate={(e: any) => {
              updateState("selectedDepartureDate", e);
            }}
            // selectedDate={dayjs(selectedRecord?.vlbTimeOut)}
            selectedDate={dayjs(states.selectedDepartureDate)}
            disabled={false}
            format={"ddd DD MMM YYYY HH: mm:ss"}
            span={false}
          />

          <DateTemplate
            width={"220px"}
            label="Arrival Time"
            changeDate={(e: any) => {
              updateState("selectedArrivalDate", e);
            }}
            // selectedDate={dayjs(selectedRecord?.vlbTimeIn)}
            selectedDate={dayjs(states.selectedArrivalDate)}
            disabled={false}
            format={"ddd DD MMM YYYY HH: mm:ss"}
            span={false}
          />

          <InputsTemplate
            id="departureOdometer"
            disabledStatus={false}
            numberOnly={false}
            cusWidth={true}
            labelColPan={7}
            inputStyle={{ height: 24, maxWidth: "90px" }}
            defaultValue={
              //   states.startOdometer === ""
              //     ? selectedRecord?.vlbOdometerStart
              //     :
              states.startOdometer
            }
            span={true}
            useCallbackFunc
            orderOnchange={(value) => {
              updateState("startOdometer", value);
              //   updateState("endOdometer", value);
            }}
            label={"Departure Odometer"}
            additionalWidget={
              <span
                onClick={() => {}}
                style={{
                  height: 22.5,
                  width: "100px",
                  position: "absolute",
                  marginTop: "-5px",
                  marginLeft: "12px",
                }}
                className="ml-2 w-full text-center hover:cursor-pointer text-blue-500 text-xs font-medium "
              >
                {startAndFinalOdometerDiff}km
              </span>
            }
          />
          <InputsTemplate
            disabledStatus={false}
            id="arrivalOdometer"
            labelColPan={7}
            inputStyle={{ height: 24, maxWidth: "90px" }}
            // defaultValue={selectedRecord?.vlbOdometerEnd}
            defaultValue={states.endOdometer}
            span={true}
            useCallbackFunc
            orderOnchange={(value) => {
              updateState("endOdometer", value);
            }}
            label={"Arrival Odometer"}
          />
          <Form.Item
            label={
              <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                {"Start Fuel Level"}
              </p>
            }
          >
            <div
              className="flex border-[1px] rounded-md "
              style={{
                borderRadius: "4px",
                width: "259px",
                maxWidth: "259px",
                height: "65px",
              }}
            >
              <Radio.Group
                size="small"
                className="w-full grid grid-cols-2 p-2"
                disabled={true}
                value={states.selectedStartFuelLevel.id}
                // value={selectedRecord?.startFuelLevelId}
                onChange={(e: RadioChangeEvent) => {
                  updateState("selectedStartFuelLevel", {
                    id: e.target.value,
                    name: "clicked",
                  });
                  updateState("selectedEndFuelLevel", {
                    id: e.target.value,
                    name: "clicked",
                  });
                }}
              >
                {fuelLevels.slice(0, 4)?.map((fuelLevel: any) => {
                  return (
                    <Radio key={fuelLevel.flvIdpk} value={fuelLevel.flvIdpk}>
                      {fuelLevel.flvName}
                    </Radio>
                  );
                })}
              </Radio.Group>
            </div>
          </Form.Item>

          <Form.Item
            style={{ marginBottom: "1px" }}
            label={
              <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                {"End Fuel Level"}
              </p>
            }
          >
            <div
              className="flex border-[1px] rounded-md   "
              style={{
                borderRadius: "4px",
                width: "259px",
                maxWidth: "259px",
                height: "65px",
              }}
            >
              <Radio.Group
                className="w-full grid grid-cols-2 p-2 "
                disabled={false}
                value={
                  //   states.selectedEndFuelLevel.id === 0
                  //     ? selectedRecord?.startFuelLevelId
                  //     :
                  states.selectedEndFuelLevel.id
                }
                onChange={(e) => {
                  updateState("selectedEndFuelLevel", {
                    id: e.target.value,
                    name: "clicked",
                  });
                }}
              >
                {fuelLevels.slice(0, 4)?.map((fuelLevel: any) => {
                  return (
                    <Radio key={fuelLevel.flvIdpk} value={fuelLevel.flvIdpk}>
                      {fuelLevel.flvName}
                    </Radio>
                  );
                })}
              </Radio.Group>
            </div>
          </Form.Item>
          <div className="mt-5 w-full">
            <button
              onClick={() => {
                AccessPriv_on_FinalOdo_UpdateButton();
              }}
              disabled={false}
              style={{
                borderWidth: 1,
                width: "110px",
              }}
              className="flex space-x-2 ml-[63%] p-1 rounded-md border border-zinc-400"
            >
              <img
                style={{ width: "22%" }}
                className=""
                src={save}
                alt="reset"
              />
              <span>Update</span>
            </button>
          </div>
        </Form>
      </div>
    </>
  );
};
