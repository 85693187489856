import React, { memo, useState } from 'react'
import BasicChart from '../../../../templates/BasicChart'
import useFetch from '../../../../hooks/useFetch'
import { DatePicker, Form } from 'antd'
import dayjs from 'dayjs'
import { searchTextTypes } from '../../../human-resource/setups/data/types/selectsTypes'
import { SearchButton } from '../../../accessories/buttons'
import { DateTemplate } from '../../../../templates/date'
import { setApexchartOptions } from '../../../../genChartOptions'
import { formPropsConst } from '../../../accessories/constants'
import { currentDate, graphStartingDate, startingDate } from '../../../accessories/component_infos'
import CustomLoader from '../../../accessories/custom-loader'
import { name } from 'mustache'


export interface AuditCountType {
    xAxis: string;
    countx: number;
}



const AuditUniqueNonUniqueCounts = () => {

    const genFormat = "YYYY-MM-DD";

    const [startDate, setStartDate] = useState<searchTextTypes>({
        text: graphStartingDate,
        temp: graphStartingDate,
    });
    const [endDate, setEndDate] = useState<searchTextTypes>({
        text: currentDate,
        temp: currentDate,
    });

    const [auditUniqueNonUniqueLogins, auditError, isAuditLoading]: [AuditCountType[], any, any] = useFetch(`AuditTrail/DrawChartNonUniqueLogins?Startdate=${startDate.temp}&EndDate=${endDate.temp}`, false);

    const uniqLoginsOptions = setApexchartOptions<AuditCountType>("bar", auditUniqueNonUniqueLogins);


    return (
        <>
            {isAuditLoading && <CustomLoader text="Fetching Data" />}
            <main className='flex flex-col w-full h-full pb-16 px-6 space-y-5'>

                <Form {...formPropsConst}>
                    <aside className="flex justify-end items-center w-full space-x-2">
                        <DateTemplate
                            selectedDate={dayjs(startDate.temp)}
                            changeDate={(date: any) =>
                                setStartDate({
                                    temp: dayjs(date).format(genFormat),
                                    text: startDate.text,
                                })
                            }

                            style={{ width: 125 }}
                            format={"DD MMM YYYY"}
                            disabled={false}
                        />



                        <DateTemplate
                            selectedDate={dayjs(endDate.temp)}
                            changeDate={(date: any) =>
                                setEndDate({
                                    temp: dayjs(date).format(genFormat),
                                    text: endDate.text,
                                })
                            }

                            style={{ width: 125 }}
                            format={"DD MMM YYYY"}
                            disabled={false}
                        />

                        <SearchButton
                            handleOnClick={() => {

                                setStartDate({
                                    temp: dayjs(startDate.temp).format(genFormat),
                                    text: startDate.temp,

                                })
                                setEndDate({
                                    temp: dayjs(endDate.temp).format(genFormat),
                                    text: endDate.temp,

                                })
                            }



                            }
                        />

                    </aside>
                </Form>


                <section className='w-full h-full '>
                    <BasicChart
                        type='bar'
                        height={`100%`}
                        // width={800}
                        className='w-full h-full '
                        options={uniqLoginsOptions}
                        series={[
                            {

                                data: auditUniqueNonUniqueLogins.map((item) => item.countx),
                                name: "Unique Logins",




                            },
                            {
                                data: auditUniqueNonUniqueLogins.map((item) => item.countx),
                                name: "Non-Unique Logins"
                            }


                        ]}

                    />
                </section>
            </main>
        </>
    )
}

export default memo(AuditUniqueNonUniqueCounts)

