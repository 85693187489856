import { Form, Select } from "antd";

const UpdateStaffDropdownForm = () => {
    const { Option } = Select;
    return (  <Form.Item
        label={<p className='text-xs'>{'Staff No'}</p>}
        
        className='mb-1' name="Branch">
        <Select
        placeholder=""
        allowClear
        >
            <Option value="male">{'option.text'}</Option>
        </Select>
    </Form.Item> );
}
 
export default UpdateStaffDropdownForm;