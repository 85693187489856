import { forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { setValue, tier1SaveOnclick, tier1UpdateOnclick } from '../../../../features/forms/tier1/createSlice';
import { setGeneralValue } from '../../../../features/generalSlice';
import useFetch from '../../../../hooks/useFetch';
import { ModalTemplate } from '../../../../templates/modal';
import { validationsData, saveModalData, updateModalData, api_url } from '../../../accessories/component_infos';
import Setups_Template from '../../../human-resource/setups/.setups_templates/Setups_Template';
import { exprsTypes, modalTypes, searchTextTypes } from '../../../human-resource/setups/data/types/selectsTypes';
import { performance_unitOfMeasure_columns } from './data/unitOfMeasureData';
import { WarningAlert } from '../../../accessories/warningAlert';
import { useAccountInfo } from '../../../../hooks/useAccountInfo';
import { useBusyLoader } from '../../../../hooks/useBusyLoader';
import dayjs from 'dayjs';
import { UpdateDataFunc } from '../../../../functions/update';
import { PostDataFunc  } from '../../../../functions/post';

export const Perf_setups_unitOfMeasure = forwardRef(({a}:any, ref)=>{
   // on page resize height responsiveness
   const saveSuccessText = {message : 'Unit of Measure added Successfully',ok : 'Ok' , title : 'Success'} // successful save modal message
   const updateSuccessText = {message : 'Unit of Measure updated Successfully',ok : 'Ok' , title : 'Success'} // successful update modal message

   // store data expressions[unique] ...dependent on data coming in
   const idExpr:string = 'uomIdpk'
   const nameExpr:string = 'uomName'
   const shortnameExpr:string = 'uomShtName'
   const orderExpr: string = 'uomOrder';
   const activeExpr: string = 'uomActive';
   const remarksExpr: string = 'uomRmks';
   const inputsExprsData: any = {idExpr : idExpr,nameExpr : nameExpr,shortnameExpr : shortnameExpr,orderExpr : orderExpr,activeExpr,remarksExpr : remarksExpr }

   const storeExprs = () =>{
       const exprs = [{expr: 'idExpr' ,value : idExpr},
       {expr: 'nameExpr' ,value : nameExpr},
       {expr: 'shortnameExpr' ,value : shortnameExpr},
       {expr:'existsUrl',value:`api/UnitOfMeasure`}

    ]
       //to particular redux store based on form type
       for (let i = 0; i < exprs.length; i++) {            
           dispatch(setValue({expr: exprs[i].expr ,value : exprs[i].value}));
       }

       // to general slice
       const generalExprs:exprsTypes[] = [{expr: 'inputExprs' ,value : inputsExprsData}]
       for (let i = 0; i < generalExprs.length; i++) {            
           dispatch(setGeneralValue({expr: generalExprs[i].expr ,value : generalExprs[i].value}));
       }
   }

   const dispatch = useDispatch();
   const [refresh, setRefresh] = useState<boolean>(false); //refresh data state for data creation or update

   //modal states
   //--general--
   const triggerSuccessModal = useSelector((state:RootState)=>state.tier1Add.successModalState); //trigger success modal
   const SuccessModalText = useSelector((state:RootState)=>state.tier1Add.successText); //success modal text

   //--for backend errors--
   const[errState,setErrState] = useState<boolean>(false);
   const[errTitle,seterrTitle] = useState<string>('');
   const[errMessage,seterrMessage] = useState<string>('');

   //--warning modal--
   const triggerwarningModal = useSelector((state:RootState)=>state.tier1Add.warningState); //trigger warning
   const warningInfos:any = validationsData; //warning type details
   const warningDataExpr:any = useSelector((state:RootState)=>state.tier1Add.warningDataExpr); //warning type selector

   //--save data modal--
   const saveData = saveModalData; //save modal details[data]
   const triggersaveModal = useSelector((state:RootState)=>state.tier1Add.saveDataOnclick); //trigger save modal
  
   //--Update data modal--
   const updateIndInfos = updateModalData; //update modal details[data]
   const triggerupdateModal:boolean = useSelector((state:RootState)=>state.tier1Add.updateDataOnclick); //trigger update question
   // on page resize height responsiveness
   const [trigger,setTrigger] = useState<boolean>(false);
   useImperativeHandle(ref, () => ({
       test: () => {
           setTrigger(!trigger);
       }
   }));

   const [employeeId,userId]=useAccountInfo();
   const [busyLoader, setBusyLoader] = useBusyLoader()

    // get current values from  [slice dependent on form type]
    const name = useSelector((state:RootState)=>state.tier1Add.name);
    const shortname = useSelector((state:RootState)=>state.tier1Add.shortname);
    const order = useSelector((state:RootState)=>state.tier1Add.order);
    const active = useSelector((state:RootState)=>state.tier1Add.active);
    const remarks = useSelector((state:RootState)=>state.tier1Add.remarks);
    const selectedID = useSelector((state:RootState)=>state.general.SelectedFieldID);

    //post form Data
    //validate data
    const validateDataSave = () =>{
        dispatch(tier1SaveOnclick());
    }
    // post
    const postData = async() =>{

        dispatch(setValue({expr: 'saveDataOnclick' ,value : false})) //disable modal
        try {
            setBusyLoader(saveModalData.progress);

            const res = await PostDataFunc (`api/UnitOfMeasure/CreateUnitOfMeasure`,{
                uomName : name,
                uomShtName : shortname, 
                uomActive : active,
                uomRmks: remarks,
                uomOrder: order,
                uomCreatedBy : userId
            })
        
            setRefresh(!refresh);
    
            const exprs:exprsTypes[] = [{expr: 'saveDataOnclick' ,value : false},{expr: 'successModalState' ,value : true},{expr: 'successText' ,value : saveSuccessText}];
            for (let i = 0; i < exprs.length; i++) {            
                dispatch(setValue({expr: exprs[i].expr ,value : exprs[i].value}));
            }
        } catch (error:any) {
            console.log(error);
            
            //set states for db errors modal
            setErrState(true);
            seterrTitle(saveModalData.error.title);
            seterrMessage(saveModalData.error.message);
        } finally{
            setBusyLoader('');
        }
    }

    //Update Form data
    //validate data
    const validateDataUpdate = () =>{
        dispatch(tier1UpdateOnclick());
    }
    // update
    const UpdateData = async()=>{
        dispatch(setValue({expr: 'updateDataOnclick' ,value : false})) //disable modal
        try {
            setBusyLoader(updateModalData.progress);

            const res = await UpdateDataFunc(`api/UnitOfMeasure/UpdateUnitOfMeasure/${selectedID}`,{
                uomIdpk: selectedID,
                uomName : name,
                uomShtName : shortname, 
                uomActive : active,
                uomRmks: remarks,
                uomOrder: order,
                uomEditedBy : userId,
                UomEditedDate: dayjs().format()
            })
            
            setRefresh(!refresh);
    
            const exprs:exprsTypes[] = [{expr: 'updateDataOnclick' ,value : false},{expr: 'successModalState' ,value : true},{expr: 'successText' ,value : updateSuccessText}];
            for (let i = 0; i < exprs.length; i++) {            
                dispatch(setValue({expr: exprs[i].expr ,value : exprs[i].value}));
            }
        } catch (error:any) {
            console.log(error);
            
            //set states for db errors modal
            setErrState(true);
            seterrTitle(updateModalData.error.title);
            seterrMessage(updateModalData.error.message);
        } finally{
            setBusyLoader('');
        }
    }

    //modals data
    const modals:modalTypes[] = [
        //warning
        {disableCancel : true , icon : 'warning' ,open : triggerwarningModal, okHandler : ()=> dispatch(setValue({expr: 'warningState' ,value : false})), cancelHandler : ()=> dispatch(setValue({expr: 'warningState' ,value : false})), message : warningInfos[warningDataExpr!].message, cancelText : warningInfos[warningDataExpr!].cancelText, okText : warningInfos[warningDataExpr!].okText, title : warningInfos[warningDataExpr!].title},
        //backend error
        {disableCancel : true , icon : 'warning' ,open : errState, okHandler : ()=> setErrState(false), cancelHandler : ()=> setErrState(false), message : errMessage, cancelText : '', okText : 'Ok', title : errTitle},
        //success
        {disableCancel : true, icon : 'success' ,open : triggerSuccessModal, okHandler : ()=> dispatch(setValue({expr: 'successModalState' ,value : false})), cancelHandler : ()=> dispatch(setValue({expr: 'successModalState' ,value : false})), message :SuccessModalText.message, okText : SuccessModalText.okText, title : SuccessModalText.title},
        //save
        {disableCancel : false , icon : 'question' ,open : triggersaveModal, okHandler : ()=> postData(), cancelHandler : ()=> dispatch(setValue({expr: 'saveDataOnclick' ,value : false})), message : saveData.message, cancelText : saveData.cancelText, okText : saveData.okText, title : saveData.title},
        //Update
        {disableCancel : false , icon : 'question' ,open : triggerupdateModal, okHandler : ()=> UpdateData(), cancelHandler : ()=> dispatch(setValue({expr: 'updateDataOnclick' ,value : false})), message : updateIndInfos.message, cancelText : updateIndInfos.cancelText, okText : updateIndInfos.okText, title : updateIndInfos.title},
    ]

    const [searchText, setSearchText]=useState<searchTextTypes>({temp:'',text:''});

    const activeStatus= useSelector((state:RootState)=>state.general.activeStatus);
    const [activex, setActivex]=useState<boolean|string>('');
    // data for datagrid
    const [data,error,loading] = useFetch(`api/UnitOfMeasure/SearchUnitOfMeasure?active=${activex}&search=${searchText.text}`,refresh,'uomIdpk');
    useEffect(()=>{
        if(data){
            storeExprs(); // run func to store expressions on store
            dispatch(setGeneralValue({expr : 'idExpr', value : idExpr})); // dispatch fetched data to reduxstore
            dispatch(setGeneralValue({expr: 'formData' ,value : data})); // dispatch fetched data to reduxstore
        }

        loading ? setBusyLoader('.') : setBusyLoader('');
    }, [data, loading])
    

  
   // form parameters
   const form_header = 'Unit of Measure Details';

   return (
       <>
            {error && (
                <WarningAlert/>
            )}

            {/* modals */}
            {modals.map(({disableCancel,icon,okHandler,cancelHandler,open,cancelText,okText,message,title}:modalTypes,index)=>{
               return(
                   <div key = {index}>
                       <ModalTemplate icon= {icon && icon} disableCancel = {disableCancel} cancelText = {cancelText && cancelText} open = {open} okHandler={okHandler} cancelHandler={cancelHandler} message= {message} okText= {okText} title={title}/>
                   </div>
               )
           })}

           <Setups_Template 
           updateData={validateDataUpdate} 
           createData={validateDataSave}  
           formTier='1' 
           trigger = {trigger} 
           searchTextChange={(newText)=>{
                setSearchText({temp:newText,text:searchText.text})
           }}
           handleSearch={()=>{
                setSearchText({text:searchText.temp, temp:searchText.temp})
                setActivex(activeStatus)
                setRefresh(current=> !current)
           }}
           datagridColumns={performance_unitOfMeasure_columns} 
           datagridData = {data} 
           formHeader={ form_header}/>
       </>
   );
})