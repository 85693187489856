import React from "react";
import { VehicleInspectionsForms } from "./vehicleInspectionsForm";
import { VehicleKeyForm } from "./vehicleKeyForm";



export const VehicleKeyAndInspectionForm = () => {

    return (
        <div className="">
            
           <VehicleInspectionsForms />
           <div className=" border-b-4"></div>
           <VehicleKeyForm />
        </div>
    );
}