import { useState, useEffect } from "react";
import { userDataCrypt } from "../functions/userDataEncrypt";

const useLocalStorage = <T>(
  initialValue: T,
  key: string
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const storageKey = `G&&%bvs5dfd756^%^n${key}`;
  const getEncryptedValue = () => {
    const storedValue = localStorage.getItem(storageKey);

    return storedValue
      ? (userDataCrypt("decrypt", storedValue) as T)
      : initialValue;
  };

  const [value, setValue] = useState<T>(getEncryptedValue);

  useEffect(() => {
    localStorage.setItem(
      storageKey,
      userDataCrypt("encrypt", JSON.stringify(value))
    );
  }, [key, value]);

  return [value, setValue];
};

export default useLocalStorage;
