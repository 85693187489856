/* eslint-disable react/jsx-pascal-case */
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';
import { setGeneralValue } from '../../../../features/generalSlice';
import useFetch from '../../../../hooks/useFetch';
import Datagrid_template from '../../../../templates/Datagrid';
import { navbar_height, toolbar_height } from '../../../accessories/component_infos';
import { HrSetupsCustomToolbar } from '../../../accessories/custom-toolbars/hrSetupsCustomtoolbar';
import { PerformanceSetups_periods_columns } from './data/periodsData';
import { Periods_form } from './widgets/periodsForm';
import { searchTextTypes, statusTypes } from '../../../human-resource/setups/data/types/selectsTypes';
import { RootState } from '../../../../app/store';
import { WarningAlert } from '../../../accessories/warningAlert';
import { useBusyLoader } from '../../../../hooks/useBusyLoader';



export const PerformanceSetupsPeriods = forwardRef(({ a }: any, ref) => {
    const news_flash_height = useSelector((state: RootState) => state.general.newsflashHeight);

    // on page resize height responsiveness
    const dispatch = useDispatch();

    const [trigger, setTrigger] = useState<boolean>(false);
    useImperativeHandle(ref, () => ({
        test: () => {
            setTrigger(!trigger);
        }
    }));

    //navigation
    const setupPageRoute = window.location.pathname; //get current route of page
    const datagridOnlySearchParam = 'table-only'  //search param in datagrid mode
    const formModeSearchParam = 'form-and-table' //search param in form mode mode
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    //miscellaneous heights
    const miscHeight = 7;

    // All heights out
    const heights_out = navbar_height + news_flash_height + toolbar_height;

    //varying form height
    const [form_height, set_form_height] = useState<number>(216);
    const pass_form_height = (height: number) => {
        set_form_height(height)
    }

    // Page resize height responsiveness
    useEffect(() => {
        settableHeight(window.innerHeight - (heights_out + miscHeight - 4));
    }, [])

    // handle interface switch
    const toggle_staff_form = (): void => {
        const view = searchParams.get('view');

        (view === null || view === formModeSearchParam) ?
            navigate({
                pathname: setupPageRoute,
                search: createSearchParams({
                    view: datagridOnlySearchParam,
                }).toString(),
            })
            :
            navigate({
                pathname: setupPageRoute,
                search: createSearchParams({
                    view: formModeSearchParam,
                }).toString(),
            });
    }

    //Table Height
    const pageHeight = window.innerHeight - (navbar_height + miscHeight); //height without navbar
    const [tableHeight, settableHeight] = useState(window.innerHeight); //height without navbar and toolbar

    const [refresh, setrefresh] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState({});
    const [busyLoader, setBusyLoader] = useBusyLoader();

    const [searchText, setSearchText] = useState<searchTextTypes>({ temp: '', text: '' })
    const [status, setStatus] = useState<statusTypes>({ temp: '', status: '' })

    //gridview data
    const [data, error, loading] = useFetch(`Periods/SearchPeriods?period=${searchText.text}&activeState=${status.status}`, refresh, 'prdIdpk');
    useEffect(() => {
        loading ? setBusyLoader('') : setBusyLoader('');
    }, [loading]);

    return (
        <>
            {error && (
                <WarningAlert />
            )}

            <div style={{ height: pageHeight }} className="w-full">
                <div className="px-2">
                    <HrSetupsCustomToolbar
                        searchTextOnchange={(newText) => {
                            setSearchText({ temp: newText, text: searchText.text })
                        }}
                        checkOnChange={(newStatus) => {
                            setStatus({ temp: newStatus, status: status.status })
                        }}
                        handleFind={() => {
                            setSearchText({ temp: searchText.temp, text: searchText.temp });
                            setStatus({ temp: status.temp, status: status.temp })
                            setrefresh(current => !current)
                        }}
                        toggler={toggle_staff_form}
                        outerSelected={selectedData} handleNew={() => setSelectedData({})} withDates={false} />
                </div>

                {/* form section */}
                {(searchParams.get('view') !== datagridOnlySearchParam) &&
                    <section style={{ height: form_height }} className="form border-b-4">
                        <Periods_form
                            clearSelected={() => setSelectedData({})}
                            data={data}
                            selectedField={selectedData}
                            busyloader={(text) => setBusyLoader(text ?? '')}
                            refreshGrid={() => setrefresh((prev) => (!prev))}
                        />
                    </section>}

                {/* datagrid section */}
                <section className="mt-1 px-2">

                    <Datagrid_template
                        gridheight={searchParams.get('view') === formModeSearchParam || searchParams.get('view') === (null) ?
                            window.innerHeight - (form_height + heights_out + miscHeight)
                            :
                            tableHeight}
                        data={data}
                        onRowClick={(selected) => {
                            setSelectedData(selected);
                            //disable Update Mode
                            dispatch(setGeneralValue({ expr: 'updateMode', value: false }));

                            //disable forms
                            dispatch(setGeneralValue({ expr: 'formDisabled', value: true }));
                        }}
                        rowDoubleClicked={() => {
                            //disable Update Mode
                            dispatch(setGeneralValue({ expr: 'updateMode', value: true }));

                            //disable forms
                            dispatch(setGeneralValue({ expr: 'formDisabled', value: false }));
                        }}
                        columns={PerformanceSetups_periods_columns}
                    />
                </section>
            </div>
        </>
    );
})