/* eslint-disable react/jsx-pascal-case */
import { useState, useEffect, useCallback } from "react";
import { Form } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { BottomBtns } from "../../../../../../../templates/butttons";
import useFetch from "../../../../../../../hooks/useFetch";
import {
  inputsTypes,
  modalPropTypes,
  modalTypes,
  selectsTypes,
} from "../../../../../../human-resource/setups/data/types/selectsTypes";
import { SelectsTemplate } from "../../../../../../../templates/select";
import { DateTemplate } from "../../../../../../../templates/date";
import { InputsTemplate } from "../../../../../../../templates/input";
import { TextareaTemplate } from "../../../../../../../templates/textarea";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { postError } from "../../../../../../accessories/component_infos";
import { setGeneralValue } from "../../../../../../../features/generalSlice";
import { useDispatch } from "react-redux";
import { validateDates } from "../../../../../../../functions/validateDate";
import { useBusyLoader } from "../../../../../../../hooks/useBusyLoader";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { PostDataFunc } from "../../../../../../../functions/post";
import { UpdateDataFunc } from "../../../../../../../functions/update";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import { useCrudFunc } from "../../../../../../../functions/crud";
import InputSelect_template from "../../../../../../../templates/InputSelect";
import { debounce } from "lodash";
import {
  Fetch_Product,
  Fetch_StationService,
} from "../../../../../../../features/apiCalls/FuelDumpDips";
import React from "react";
import { useAxiosService } from "../../../../../../../general/requestConfig";

/**
 * Interface for this file
 * @typedef {object} stateTypes
 * @property {string} dateValue
 * @property {{ id: number, value: string }} serviceStation
 */
interface stateTypes {
  dateValue: string;
  serviceStation?: { id: number; value: string } | any;
  product?: { id: number; value: string } | any;
  tankCapacity?: number;
  openingBalance?: number;
  receipt?: number;
  issue?: number;
  closingBalance?: number;
  openingMeter?: number;
  closingMeter?: number;
  openeingDip?: number;
  remarks?: string;
  active?: boolean;
  // servicStation1: boolean
  product1: boolean;
  isOpenDropdown1?: boolean;
  isOpenDropdown2?: boolean;
  productRefresh?: boolean;
  serviceStationRefresh?: boolean;
  isOpenDropdownKey1?: any;
  isOpenDropdownKey2?: any;
  isLoading?: boolean;
  selectedRecordId: number;
  confirmModal: modalPropTypes;
  warningModal: modalPropTypes;
  successModal: modalPropTypes;
}
interface props {
  refreshGrid: () => void;
  clearSelected: () => void;
  selectedData: any;
}
export interface LookupResponseType {
  getServiceStations: GetServiceStation[];
  getProducts: GetProduct[];
}

export interface GetProduct {
  prdIdpk: number;
  prdName: string;
}

export interface GetServiceStation {
  sstIdpk: number;
  sstName: string;
  twnName: string;
}

export const Dump_Dips_Details_Form = ({
  selectedData,
  refreshGrid,
  clearSelected,
}: props) => {
  const axiosGo = useAxiosService();
  //CRUD FUNCTIONALITIES
  const [busyLoader, setBusyLoader] = useBusyLoader();
  const [userId] = useAccountInfo();
  const [privType, setPrivType] = useState<string>("save");
  const [savePriv, updatePriv] = useFormPriviledge(privType);
  const initialDate = dayjs().format();

  const [states, setState] = useState<stateTypes>({
    dateValue: initialDate,
    serviceStation: { id: 0, value: "" },
    product: { id: 0, value: "" },
    tankCapacity: 0,
    openingBalance: 0,
    receipt: 0,
    issue: 0,
    closingBalance: 0,
    openingMeter: 0,
    closingMeter: 0,
    openeingDip: 0,
    remarks: "",
    active: true,
    // servicStation1: false,
    product1: false,
    isLoading: false,
    isOpenDropdown1: false,
    isOpenDropdown2: false,
    serviceStationRefresh: false,
    productRefresh: false,
    isOpenDropdownKey1: "isOpenDropdown1",
    isOpenDropdownKey2: "isOpenDropdown2",
    selectedRecordId: 0,
    confirmModal: { state: false, title: "", message: "", action: "post" },
    warningModal: { state: false, title: "", message: "" },
    successModal: { state: false, title: "", message: "" },
  });

  const {
    dateValue,
    confirmModal,
    selectedRecordId,
    warningModal,
    successModal,
    serviceStation,
    product,
    tankCapacity,
    openeingDip,
    openingBalance,
    openingMeter,
    receipt,
    remarks,
    issue,
    closingBalance,
    closingMeter,
    active,
  } = states;

  const updateState = (stateName: string | number, value: any) => {
    setState((prevStates: stateTypes) => ({
      ...prevStates,
      [stateName]: value,
    }));
  };
  const dispatch = useDispatch();

  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const form_disable = useSelector(
    (state: RootState) => state.general.formDisabled
  );

  const [lookup_data, setLookup] = React.useState<LookupResponseType>();
  const [loading, setLoading] = React.useState<boolean>();

  React.useEffect(() => {
    (() => {
      setLoading(true);
      axiosGo({
        url: `AsmTmsEntFuelDumpDip/GetFuelDumpDipsLookups`,
        params: { pagenumber: 1, pagesize: 10 },
        method: "GET",
      }).then((res) => {
        setLookup(res);
      });
      setLoading(false);
    })();
  }, []);
  const ServiceStation_Data = useSelector(
    (state: RootState) => state.FuelDumpDips.station_service
  );
  const Product_Data = useSelector(
    (state: RootState) => state.FuelDumpDips.product
  );

  const populateFields = (clearFieldsMain?: boolean) => {
    const populate = (clearFields: boolean) => {
      const stateValues = [
        {
          stateName: "dateValue",
          value: clearFields
            ? dayjs(new Date()).format()
            : selectedData?.fddDate,
        },
        {
          stateName: "serviceStation",
          value: clearFields
            ? { id: 0, value: "" }
            : { id: selectedData?.sstIdpk, value: selectedData?.sstName },
        },
        {
          stateName: "product",
          value: clearFields
            ? { id: 0, value: "" }
            : { id: selectedData?.prdIdpk, value: selectedData?.prdName },
        },
        {
          stateName: "tankCapacity",
          value: clearFields ? "" : selectedData?.fddTankCapacity,
        },
        {
          stateName: "openingBalance",
          value: clearFields ? "" : selectedData?.fddOpeningBalance,
        },
        {
          stateName: "receipt",
          value: clearFields ? "" : selectedData?.fddReceipt,
        },
        {
          stateName: "issue",
          value: clearFields ? "" : selectedData?.fddIssue,
        },
        {
          stateName: "closingBalance",
          value: clearFields ? "" : selectedData?.fddClosingBalance,
        },
        {
          stateName: "openingMeter",
          value: clearFields ? "" : selectedData?.fddOpeningMeterReading,
        },
        {
          stateName: "closingMeter",
          value: clearFields ? "" : selectedData?.fddClosingMeterReading,
        },
        {
          stateName: "openeingDip",
          value: clearFields ? "" : selectedData?.fddOpeningDipBalance,
        },
        {
          stateName: "remarks",
          value: clearFields ? "" : selectedData?.fddRmks,
        },
        {
          stateName: "active",
          value: clearFields ? true : selectedData?.fddActive,
        },

        {
          stateName: "selectedRecordId",
          value: clearFields ? 0 : selectedData?.fddIdpk,
        },
      ];
      for (let instance of stateValues)
        updateState(instance.stateName, instance.value);
    };

    typeof selectedData === "object" &&
      (Object.keys(selectedData).length > 1
        ? populate(clearFieldsMain ?? false)
        : populate(true));
  };

  useEffect(() => {
    populateFields(false);
  }, [selectedData]);

  const setWarning = (title: string, message: string) => {
    updateState("warningModal", {
      state: true,
      title: title,
      message: message,
    });
  };
  const validateFields = (action: "post" | "put") => {
    const validDate = validateDates(dateValue, undefined, action);
    if (!validDate.isValid) {
      setWarning(validDate.error.title, validDate.error.message);
      return;
    } else if (dayjs(dateValue).isAfter(dayjs())) {
      setWarning("Invalid Date", "The date cannot be in the future.");
      return;
    }
    if (!serviceStation?.id) {
      setWarning("Select Service Station", "Please select a service station");
      return false;
    } else if (!product?.id) {
      setWarning("Select Product", "Please select a product");
      return false;
    } else if (
      !tankCapacity ||
      tankCapacity < 0 ||
      isNaN(Number(tankCapacity))
    ) {
      setWarning(
        "Enter Tank Capacity",
        `Please ${
          tankCapacity < 0 || isNaN(Number(tankCapacity))
            ? "ensure tank capacity is not negative and is a number."
            : "enter a valid tank capacity."
        }`
      );
      return false;
    } else if (
      !openingBalance ||
      openingBalance < 0 ||
      isNaN(Number(openingBalance))
    ) {
      setWarning(
        "Enter Tank Opening Balance",
        `Please ${
          openingBalance < 0 || isNaN(Number(openingBalance))
            ? "ensure opening balance is not negative and is a number"
            : "enter a valid opening balance"
        }`
      );
      return false;
    } else if (!receipt || receipt < 0 || isNaN(Number(receipt))) {
      setWarning(
        "Enter Receipt",
        `Please ${
          receipt < 0 || isNaN(Number(receipt))
            ? "ensure receipt is not negative and is a number"
            : "enter a valid receipt"
        }`
      );
      return false;
    } else if (!issue || issue < 0 || isNaN(Number(issue))) {
      setWarning(
        "Enter Issue no.",
        `Please ${
          issue < 0 || isNaN(Number(issue))
            ? "ensure issue no. is not negative and is a number"
            : "enter a valid issue no."
        }`
      );
      return false;
    } else if (
      !closingBalance ||
      closingBalance < 0 ||
      isNaN(Number(closingBalance))
    ) {
      setWarning(
        "Enter Closing Balance",
        `Please ${
          closingBalance < 0 || isNaN(Number(closingBalance))
            ? "ensure closing balance is not negative and is a number"
            : "enter a valid closing balance"
        }`
      );
      return false;
    } else if (
      !openingMeter ||
      openingMeter < 0 ||
      isNaN(Number(openingMeter))
    ) {
      setWarning(
        "Enter Opening Meter Reading",
        `Please ${
          openingMeter < 0 || isNaN(Number(openingMeter))
            ? "ensure opening meter reading is not negative and is a number"
            : "enter a valid opening meter reading"
        }`
      );
      return false;
    } else if (
      !closingMeter ||
      closingMeter < 0 ||
      isNaN(Number(closingMeter))
    ) {
      setWarning(
        "Enter Closing Meter Reading",
        `Please ${
          closingMeter < 0 || isNaN(Number(closingMeter))
            ? "ensure closing meter reading is not negative and is a number"
            : "enter a valid closing meter reading"
        }`
      );
      return false;
    } else if (!openeingDip || openeingDip < 0 || isNaN(Number(openeingDip))) {
      setWarning(
        "Enter Opening Dip Balance",
        `Please ${
          openeingDip < 0 || isNaN(Number(openeingDip))
            ? "ensure opening dip balance is not negative and is a number"
            : "enter a valid opening dip balance"
        }`
      );
      return false;
    }

    return true;
  };

  const disableConfirmModal = () => {
    updateState("confirmModal", {
      state: false,
      title: "",
      message: "",
      action: "post",
    });
  };

  const disableControls = () => {
    dispatch(setGeneralValue({ expr: "updateMode", value: false }));

    dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
  };

  const enableSuccessModal = (
    titleActionText: string,
    messageActionText: string
  ) => {
    updateState("successModal", {
      state: true,
      title: `${titleActionText} Record`,
      message: `Record ${messageActionText} successfully`,
    });
  };

  const [posting, updating] = useCrudFunc();

  const postData = async (action: "validate" | "post") => {
    if (action === "validate") {
      validateFields("post") &&
        updateState("confirmModal", {
          state: true,
          title: "Save Record",
          message: "Please are you sure you want to save this record?",
          action: "post",
        });
      return;
    }

    //post
    disableConfirmModal();
    setBusyLoader("Saving Record...");

    try {
      const res = await posting(
        `AsmTmsEntFuelDumpDip/CreateEntFuelDumpDip`,
        {
          fddDate: dateValue ?? initialDate,
          fddServiceStationIdfk: serviceStation?.id,
          fddServiceStation: serviceStation?.value,
          fddProductIdfk: product?.id,
          fddTankCapacity: Number(tankCapacity).toFixed(2),
          fddOpeningBalance: Number(openingBalance).toFixed(2),
          fddReceipt: Number(receipt).toFixed(2),
          fddIssue: Number(issue).toFixed(2),
          fddClosingBalance: Number(closingBalance).toFixed(2),
          fddOpeningMeterReading: Number(openingMeter).toFixed(2),
          fddClosingMeterReading: Number(closingMeter).toFixed(2),
          fddOpeningDipBalance: Number(openeingDip).toFixed(2),
          fddActive: active,
          fddRmks: remarks,
          fddCreatedBy: userId,
        },
        `Created the Asset Mgr-Transport-Fuel Dump Dip- for service station- ${
          serviceStation?.value
        } , Date-'${dateValue}', Closing Balance-${Number(
          closingBalance
        ).toFixed(2)}, Closing Meter Reading- ${Number(closingMeter).toFixed(
          2
        )}, Issue- ${Number(issue).toFixed(2)}, Opening Balance- ${Number(
          openingBalance
        ).toFixed(2)}, Opening Dip Balance- ${Number(openeingDip).toFixed(
          2
        )}", Opening Meter Reading- ${Number(openingMeter).toFixed(
          2
        )}, Receipt-${Number(receipt).toFixed(2)} for record with ID`
      );
      refreshGrid();
      populateFields(true);
      disableControls();

      enableSuccessModal("Saved", "saved");
    } catch (error: any) {
      updateState("warningModal", {
        state: true,
        title: postError.title,
        message: error.response
          ? error.response.data.errors.command
          : "Server Error",
      });
    } finally {
      setBusyLoader("");
    }
    return;
  };

  const updateData = async (action: "validate" | "update") => {
    if (action === "validate") {
      validateFields("put") &&
        updateState("confirmModal", {
          state: true,
          title: "Update Record",
          message: "Please are you sure you want to update this record?",
          action: "update",
        });
      return;
    }
    disableConfirmModal();
    setBusyLoader("Updating Record...");

    try {
      await updating(
        `AsmTmsEntFuelDumpDip/UpdateEntFuelDumpDip`,
        {
          fddIdpk: selectedRecordId,
          fddDate: dateValue,
          fddServiceStationIdfk: serviceStation?.id,
          fddServiceStation: serviceStation?.value,
          fddProductIdfk: product?.id, //product?.id,
          fddTankCapacity: tankCapacity,
          fddOpeningBalance: openingBalance,
          fddReceipt: receipt,
          fddIssue: issue,
          fddClosingBalance: closingBalance,
          fddOpeningMeterReading: openingMeter,
          fddClosingMeterReading: closingMeter,
          fddOpeningDipBalance: openeingDip,
          fddActive: active,
          fddRmks: remarks,
          fddEditedBy: userId,
          fddEditedDate: dayjs(new Date()).format(),
        },
        `Updated the Asset Mgr-Transport-Fuel Dump Dip- for service station- ${
          serviceStation?.value
        } , Date-'${dateValue}', Closing Balance-${Number(
          closingBalance
        ).toFixed(2)}, Closing Meter Reading- ${Number(closingMeter).toFixed(
          2
        )}, Issue- ${Number(issue).toFixed(2)}, Opening Balance- ${Number(
          openingBalance
        ).toFixed(2)}, Opening Dip Balance- ${Number(openeingDip).toFixed(
          2
        )}", Opening Meter Reading- ${Number(openingMeter).toFixed(
          2
        )}, Receipt-${Number(receipt).toFixed(2)} for record with ID`
      );

      refreshGrid();
      disableControls();

      enableSuccessModal("Updated", "updated");
    } catch (error: any) {
      updateState("warningModal", {
        state: true,
        message: error.response
          ? error.response.data.errors.command
          : "Server Error",
        title: error.response ? error.response.data.title : "Unexpected Error!",
        okText: "Ok",
      });
    } finally {
      setBusyLoader("");
    }
  };
  const selects: selectsTypes[] = [
    {
      id: 0,
      label: "Service Station",
      optionsData:
        states.serviceStation?.length > 0
          ? ServiceStation_Data
          : lookup_data?.getServiceStations,
      dataExpr: "sstName",
      idExpr: "sstIdpk",
      defaultValue: serviceStation?.value,
      stateName: "serviceStation",
      refreshStateName: "servicStation1",
      refresh: states.serviceStationRefresh,
      isOpenDropdown: states.isOpenDropdown1,
      isOpenDropdownKey: states.isOpenDropdownKey1,
      singleEndpoint: Fetch_StationService,
      isLoading: states.isLoading,
    },
    {
      id: 1,
      label: "Product",
      optionsData:
        states.product?.length > 0 ? Product_Data : lookup_data?.getProducts,
      dataExpr: "prdName",
      idExpr: "prdIdpk",
      defaultValue: product?.value,
      stateName: "product",
      refreshStateName: "product1",
      refresh: states.productRefresh,
      isOpenDropdown: states.isOpenDropdown2,
      isOpenDropdownKey: states.isOpenDropdownKey2,
      singleEndpoint: Fetch_Product,
      isLoading: states.isLoading,
    },
  ];

  const newAcccessPriv = () => {
    setPrivType("save");
    if (savePriv === true) {
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      clearSelected();
      populateFields(true);
    } else {
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      updateState("warningModal", {
        state: true,
        title: "Access Denied!",
        message: "No Access Privilege",
      });
    }
  };

  const saveAccessPriv = () => {
    setPrivType("save");
    if (savePriv === true) {
      dispatch(setGeneralValue({ expr: "updateMode", value: false }));
      postData("validate");
    } else {
      updateState("warningModal", {
        state: true,
        title: "Access Denied!",
        message: "No Access Privilege",
      });
    }
  };

  const updateAccessPriv = () => {
    setPrivType("update");
    if (updatePriv === true) {
      dispatch(setGeneralValue({ expr: "updateMode", value: true }));
      updateData("validate");
    } else {
      updateState("warningModal", {
        state: true,
        title: "Access Denied!",
        message: "No Access Privilege",
      });
    }
  };

  const inputs: inputsTypes[] = [
    {
      id: 0,
      label: "Tank Capacity",
      defaultValue: tankCapacity,
      stateName: "tankCapacity",
    },
    {
      id: 1,
      label: "Opening Balance",
      defaultValue: openingBalance,
      stateName: "openingBalance",
    },
    { id: 2, label: "Receipt", defaultValue: receipt, stateName: "receipt" },
    { id: 3, label: "Issue no.", defaultValue: issue, stateName: "issue" },
    {
      id: 4,
      label: "Closing Balance",
      defaultValue: closingBalance,
      stateName: "closingBalance",
    },
  ];

  const rightInputs: inputsTypes[] = [
    {
      id: 0,
      label: "Opening Meter Reading",
      defaultValue: openingMeter,
      stateName: "openingMeter",
    },
    {
      id: 1,
      label: "Closing Meter Reading",
      defaultValue: closingMeter,
      stateName: "closingMeter",
    },
    {
      id: 2,
      label: "Opening Dip Balance",
      defaultValue: openeingDip,
      stateName: "openeingDip",
    },
  ];

  const modals: modalTypes[] = [
    {
      disableCancel: true,
      icon: "warning",
      open: warningModal.state,
      okHandler: () =>
        updateState("warningModal", { state: false, title: "", message: "" }),
      cancelHandler: () =>
        updateState("warningModal", {
          state: false,
          title: "string",
          message: "string",
        }),
      message: warningModal.message,
      okText: "Ok",
      title: warningModal.title,
      cancelText: "No, cancel",
    },

    {
      disableCancel: false,
      icon: "question",
      open: confirmModal.state,
      okHandler:
        confirmModal.action === "update"
          ? () => updateData("update")
          : () => postData("post"),
      cancelHandler: () =>
        updateState("confirmModal", {
          state: false,
          title: "string",
          message: "string",
        }),
      message: states.confirmModal.message,
      okText: confirmModal.action === "update" ? "Yes, Update" : "Yes, Save",
      title: states.confirmModal.title,
      cancelText: "No, cancel",
    },

    {
      disableCancel: true,
      icon: "success",
      open: successModal.state,
      okHandler: () => {
        updateState("successModal", { state: false, title: "", message: "" });
      },
      cancelHandler: () =>
        updateState("successModal", {
          state: false,
          title: "string",
          message: "string",
        }),
      message: successModal.message,
      okText: "Ok",
      title: successModal.title,
      cancelText: "No, cancel",
    },
  ];
  return (
    <div
      style={{ width: "100%", height: "100%" }}
      className="    dark:bg-darkModeSkin-div_backgroundColor dark:text-darkModeSkin-base  "
    >
      {modals.map(
        (
          {
            disableCancel,
            icon,
            okHandler,
            cancelHandler,
            open,
            cancelText,
            okText,
            message,
            title,
          }: modalTypes,
          index
        ) => {
          return (
            <div key={index}>
              <ModalTemplate
                icon={icon && icon}
                disableCancel={disableCancel}
                cancelText={cancelText && cancelText}
                open={open}
                okHandler={okHandler}
                cancelHandler={cancelHandler}
                message={message}
                okText={okText}
                title={title}
              />
            </div>
          );
        }
      )}

      <div
        // style={{ maxHeight: "150px", minHeight: "150px", height: "150px", }}
        className=" items-start justify-start "
      >
        <Form
          style={{ borderColor: borderTheme }}
          className="w-full border-[1px] border-t-0 dark:bg-slate-900 dark:text-darkModeSkin-base flex rounded-b-md p-2"
          name="basic"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          size="small"
          layout="horizontal"
        >
          <section style={{ width: "500px" }}>
            <DateTemplate
              showTime
              selectedDate={dayjs(dateValue)}
              changeDate={(date: Dayjs) => {
                updateState("dateValue", dayjs(date).format());
              }}
              disabledDate={(current: Dayjs) => {
                return current && current > dayjs().endOf("day");
              }}
              width={200}
              label="Date-time"
            />

            {selects.map(
              (
                {
                  id,
                  label,
                  isOpenDropdown,
                  singleEndpoint,
                  isOpenDropdownKey,
                  optionsData,
                  dataExpr,
                  idExpr,
                  defaultValue,
                  stateName,
                  refresh,
                  refreshStateName,
                },
                _w
              ) => (
                <>
                  <InputSelect_template
                    useCallFunc
                    placeHolder={defaultValue}
                    isOpenDropdown={isOpenDropdown}
                    handleRefresh={() => {
                      updateState(stateName!, "");
                      updateState(refreshStateName!, !refreshStateName);
                    }}
                    handleFocus={(e: any) => {
                      updateState(isOpenDropdownKey, !isOpenDropdown);
                    }}
                    handleSearch={
                      debounce((e) => {
                        updateState(stateName!, e);
                        // updateState("isLoading", true);
                        dispatch(singleEndpoint(e));

                        updateState(isOpenDropdownKey, true);
                      }, 500) // Adjust the debounce delay as needed
                    }
                    options={optionsData}
                    selectStyle={{ height: 24 }}
                    idexpr={idExpr}
                    dataexp={dataExpr!}
                    key={label}
                    label={label}
                    span={false}
                    selectedValue={(e: any) => {
                      updateState(stateName!, {
                        id: e[idExpr],
                        value: e[dataExpr],
                      });
                      updateState(isOpenDropdownKey, false);
                    }}
                  />
                </>
              )
            )}

            {inputs.map(({ label, stateName, defaultValue }, id) => (
              <>
                <InputsTemplate
                  id={id.toString()}
                  span
                  labelColPan={5}
                  inputStyle={{ width: 500 }}
                  useCallbackFunc
                  // numberOnly
                  orderOnchange={(value) => {
                    updateState(stateName!, value);
                  }}
                  defaultValue={defaultValue}
                  label={label}
                />
              </>
            ))}

            <div className=" mt-0.5">
              <BottomBtns
                outerDisabled={form_disable}
                useCallbackFunc={true}
                addActiveBorders={true}
                defaultValue={active}
                checkboxChange={(checkValue: boolean) => {
                  updateState("active", checkValue);
                }}
                onNewClick={() => {
                  newAcccessPriv();
                }}
                updateHandler={() => updateAccessPriv()}
                saveHandler={() => saveAccessPriv()}
                span
              />
            </div>
          </section>

          <section style={{ width: "calc(100% - 450px)" }}>
            {rightInputs.map(({ label, stateName, defaultValue }, id) => (
              <>
                <InputsTemplate
                  id={`input${id}`}
                  span
                  labelColPan={5}
                  useCallbackFunc
                  // numberOnly
                  inputStyle={{ width: 300 }}
                  orderOnchange={(value) => {
                    updateState(stateName!, value);
                  }}
                  defaultValue={defaultValue}
                  label={label}
                />
              </>
            ))}
            <div className=" mt-0.5">
              <TextareaTemplate
                useCallbackFunc
                defaultValue={remarks}
                setCallbackValue={(value: any) => {
                  updateState("remarks", value);
                }}
                labelCol={5}
                label="Remarks"
                height={159}
              />
            </div>
          </section>
        </Form>
      </div>
    </div>
  );
};
