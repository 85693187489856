import { Checkbox, Form } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/store";
import { setValue } from "../features/forms/tier1/createSlice";
import { setTier2Value } from "../features/forms/tier2/tier2Slice";
import { setTier2CheckboxValue } from "../features/forms/tier2checkbox/tier2SliceCheckbox";
import { setTier3Value } from "../features/forms/tier3/tier3Slice";
import { setTier4Value } from "../features/forms/tier4/tier4Slice";
import { Tooltip } from "devextreme-react";

interface props {
  id?: number;
  index?: any;
  nameExpr?: string;
  inlineNameExpr?: [string, string];
  label?: any;
  label2?: string;
  borders?: boolean;
  key?: number;
  span?: boolean;
  style?: any;
  expr?: string;
  placeholder?: string;
  customDisabled?: boolean | undefined;
  tableCheckboxValue?: any;
  useCallbackFunc?: boolean;
  labelStyle?: string;
  defaultValue?: any;
  inlineDefaultValue?: any;
  withBorders?: boolean;
  setCheckboxOnchange?: (value: boolean, index: any) => void;
  inlineRightLabelCol?: any;
  handleCheck?: (e?: any) => void;
  extraWidget?: any;
  parentClassName?: string;
  labelCol?: any;
  indeterminate?: boolean;
  istooltip?: boolean;
  tooltipId?: any;
  tooltipMsg?: string;
}

export const CheckboxTemlate = ({
  label,
  handleCheck,
  labelStyle,
  inlineNameExpr,
  defaultValue,
  withBorders = false,
  inlineDefaultValue,
  label2,
  index,
  borders,
  key,
  extraWidget,
  span,
  style,
  expr,
  nameExpr,
  id,
  useCallbackFunc,
  setCheckboxOnchange,
  placeholder,
  customDisabled = undefined,
  tableCheckboxValue,
  inlineRightLabelCol,
  parentClassName,
  labelCol,
  indeterminate,
  istooltip = false,
  tooltipId,
  tooltipMsg,
}: props) => {
  const dispatch = useDispatch();

  //Populating field on update
  const selectedFieldData = useSelector(
    (state: RootState) => state.general.selectedFieldData
  );
  const [data, setData] = useState<boolean>(false);
  const [inlineCheckBox, setInlineCheckBox] = useState<any>([false, false]);

  const colorTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  useEffect(() => {
    if (useCallbackFunc) {
      label2 && inlineDefaultValue
        ? setInlineCheckBox([inlineDefaultValue[0], inlineDefaultValue[1]])
        : setData(defaultValue);
    } else {
      if (
        typeof selectedFieldData === "object" &&
        Object.keys(selectedFieldData).length > 0
      ) {
        if (label2) {
          if (inlineNameExpr) {
            const firstCheckbox = selectedFieldData[inlineNameExpr[0]] ?? false;
            const secondCheckbox =
              selectedFieldData[inlineNameExpr[1]] ?? false;

            setInlineCheckBox([firstCheckbox, secondCheckbox]);
            passValue(selectedFieldData[inlineNameExpr![0]] ?? false, 0);
            passValue(selectedFieldData[inlineNameExpr![1]] ?? false, 1);

            return;
          }
        }

        if (nameExpr) {
          setData(selectedFieldData[nameExpr!] ?? false);
          passValue(selectedFieldData[nameExpr!] ?? false);
        }
      } else {
        if (label2) {
          setInlineCheckBox([true, true]);
          return;
        }

        setData(true);
        passValue(true);
      }
    }
  }, [defaultValue, selectedFieldData, inlineDefaultValue]);

  // Post onchange Data to redux
  const tier: string = useSelector((state: RootState) => state.general.tier);
  const disabled: boolean = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const passValue = (data: boolean, index?: number) => {
    let cbexpr: any = id || id === 0 ? `checkbox${id}` : expr!;
    let inlinecbexpr = `checkboxInline${index}`;

    tier === "tier2checkbox"
      ? dispatch(
          setTier2CheckboxValue({
            expr: index || index === 0 ? inlinecbexpr : cbexpr,
            value: data,
          })
        )
      : tier === "2"
      ? dispatch(setTier2Value({ expr: expr, value: data })) // dispactch to tier2 slice
      : tier === "3"
      ? dispatch(setTier3Value({ expr: expr, value: data })) // dispactch to tier3 slice
      : tier === "4"
      ? dispatch(setTier4Value({ expr: expr, value: data })) // dispactch to tier4 slice
      : dispatch(
          setValue({
            expr: index || index === 0 ? inlinecbexpr : cbexpr,
            value: data,
          })
        ); //to tier 1
  };

  return (
    <>
      {!label2 && (
        <Form.Item
          className={parentClassName}
          style={style}
          labelCol={labelCol && { span: labelCol }}
          label={
            label && (
              <p
                className={`dark:bg-slate-900 dark:text-darkModeSkin-base text-xs ${labelStyle}`}
              >
                {label}
              </p>
            )
          }
          rules={[{ required: false }]}
        >
          <Checkbox
            id={tooltipId}
            indeterminate={indeterminate}
            style={
              withBorders
                ? { borderWidth: 1, borderColor: colorTheme }
                : { borderColor: colorTheme }
            }
            className={withBorders ? `px-1 py-0 rounded border-gray-300` : ""}
            checked={defaultValue !== undefined ? defaultValue : data}
            disabled={customDisabled !== undefined ? customDisabled : disabled}
            onChange={(e: CheckboxChangeEvent) => {
              const checkValue: boolean = e.target.checked;
              setData(checkValue);
              handleCheck && handleCheck(e);
              setCheckboxOnchange && setCheckboxOnchange(checkValue, index);
              !useCallbackFunc && passValue(checkValue);
              // useCallbackFunc
              //   ? setCheckboxOnchange!(checkValue, index)
              //   : passValue(checkValue);
            }}
          >
            {placeholder}
          </Checkbox>
          {extraWidget && extraWidget}

          {istooltip && (
            <Tooltip
              target={tooltipId} // Remove `#`
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">{tooltipMsg}</p>
            </Tooltip>
          )}
        </Form.Item>
      )}

      {label2 && (
        <Form.Item
          className=" w-full"
          label={<p className="text-xs">{label}</p>}
          style={{ marginBottom: 0 }}
        >
          <div className="flex justify-between">
            <Form.Item
              className=" "
              name="year"
              style={{
                display: "inline-block",
                width: `${!span ? "calc(15%)" : "calc(19%)"}`,
              }}
            >
              <div
                style={borders ? { borderWidth: "1px" } : {}}
                className={borders ? " border-stone-300 rounded px-0.5" : ""}
              >
                <Checkbox
                  id={tooltipId}
                  indeterminate={indeterminate}
                  style={
                    withBorders
                      ? { borderWidth: 1, borderColor: colorTheme }
                      : { borderColor: colorTheme }
                  }
                  className={
                    withBorders ? "px-1 py-0 rounded border-gray-300" : ""
                  }
                  disabled={
                    customDisabled !== undefined ? customDisabled : disabled
                  }
                  checked={inlineCheckBox[0]}
                  onChange={(e: CheckboxChangeEvent) => {
                    const checkValue: boolean = e.target.checked;
                    inlineCheckBox[0] = checkValue;
                    setInlineCheckBox([...inlineCheckBox]); //set locally

                    useCallbackFunc
                      ? setCheckboxOnchange!(checkValue, index[0])
                      : passValue(checkValue, 0);
                  }}
                >
                  {placeholder}
                </Checkbox>
              </div>
            </Form.Item>

            <Form.Item
              className=""
              labelCol={
                !inlineRightLabelCol
                  ? { span: 22 }
                  : { span: inlineRightLabelCol }
              }
              label={<p className="text-xs">{label2}</p>}
              name="month"
              style={
                !span
                  ? { display: "inline-block", width: "calc(100% - 15px)" }
                  : { display: "inline-block", width: "calc(100% - 19px)" }
              }
            >
              <div>
                <Checkbox
                  id={tooltipId}
                  indeterminate={indeterminate}
                  checked={inlineCheckBox[1]}
                  style={
                    withBorders
                      ? { borderWidth: 1, borderColor: colorTheme }
                      : { borderColor: colorTheme }
                  }
                  className={
                    withBorders ? "px-1 py-0 rounded border-gray-300" : ""
                  }
                  disabled={
                    customDisabled !== undefined ? customDisabled : disabled
                  }
                  onChange={(e: CheckboxChangeEvent) => {
                    const checkValue: boolean = e.target.checked;
                    inlineCheckBox[1] = checkValue;
                    setInlineCheckBox([...inlineCheckBox]); //set locally

                    useCallbackFunc
                      ? setCheckboxOnchange!(checkValue, index[1])
                      : passValue(checkValue, 1);
                  }}
                >
                  {placeholder}
                </Checkbox>
              </div>
            </Form.Item>
          </div>
        </Form.Item>
      )}
    </>
  );
};
