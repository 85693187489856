export const hr_setups_Sections_columns = [
    {id : 0, caption : 'ID',dataField : 'sxnIdpk',datatype : 'number', width : 80, fixed : true, visible : false},
    {id : 1, caption : 'SECTION', dataField : 'sxnName',datatype : 'string', width : 160, fixed : true , visible : true},
    {id : 2, caption : 'SHORT NAME', dataField : 'sxnShtName',datatype : 'string', width : 160, fixed : true , visible : true},
     {id : 3, caption : 'DEPARTMENT', dataField : 'dptName',datatype : 'string', width : 160, fixed : true , visible : true},
     {id: 4 , caption : 'ACTIVE', dataField : 'sxnActive',datatype : 'boolean', width : 90, fixed : true , visible : true},
     {id : 5, caption : 'ORDER', dataField : 'sxnOrder',datatype : 'string', width : 70, fixed : true , visible : true},
     {id: 6 , caption : 'MGR STAFF NUMBER', dataField : 'sxnMgrStaffNo',datatype : 'string', width : 90, fixed : false , visible : false},
    {id : 7, caption : 'MANAGER', dataField : 'sxnMgrEmpName',datatype : 'string', width : 160, fixed : false , visible : true},
    {id : 8, caption : 'DIR STAFF NUMBER', dataField : 'sxnDirEmpName',datatype : 'string', width : 160, fixed : false , visible : false},
    {id : 9, caption : 'DIRECTOR', dataField : 'sxnDirStaffNo',datatype : 'string', width : 160, fixed : false , visible : false},
   
  
    {id : 10, caption : 'REMARKS',dataField : 'sxnRmks',datatype : 'string', width : 5000, fixed : false , visible : true}
]