import { datagridColumnTypes } from "../../../../../human-resource/setups/data/types/selectsTypes";

export const permitsHolderColumns = [
  {
    id: 0,
    caption: "STAFF NO",
    dataField: "empStaffNo",
    dataType: "string",
    fixed: true,
    visible: true,
  },
  {
    id: 1,
    caption: "STAFF NAME",
    dataField: "empName",
    dataType: "string",
    width: null,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "DRIVER?",
    dataField: "dpaDriver",
    dataType: "boolean",
    width: 50,
    fixed: true,
    visible: true,
  },
  {
    id: 3,
    caption: "PERM.HOLD?",
    dataField: "dpaPermitHolder",
    dataType: "boolean",
    fixed: false,
    visible: true,
  },
  {
    id: 4,
    caption: "ACTIVE?",
    dataField: "dpaActive",
    dataType: "boolean",
    fixed: false,
    visible: true,
  },
  {
    id: 5,
    caption: "START DATE",
    dataField: "dpaStartDate",
    dataType: "datetime",
    width: null,
    fixed: false,
    visible: true,
  },
  {
    id: 6,
    caption: "END DATE",
    dataField: "dpaEndDate",
    dataType: "datetime",
    width: null,
    fixed: true,
    visible: true,
  },
  {
    id: 8,
    caption: "STAFF?",
    dataField: "empIsStaff",
    dataType: "boolean",
    fixed: false,
    visible: true,
  },
  {
    id: 10,
    caption: "RETIRE DATE",
    dataField: "retireDate",
    dataType: "datetime",
    width: null,
    fixed: false,
    visible: true,
  },
  {
    id: 11,
    caption: "LICENCE EXP. DATE",
    dataField: "dpaLicenseExpiryDate",
    dataType: "datetime",
    width: null,
    fixed: false,
    visible: true,
  },
  {
    id: 12,
    caption: "DEPT",
    dataField: "dptShtName",
    dataType: "",
    fixed: false,
    visible: true,
  },
  {
    id: 13,
    caption: "SECTION",
    dataField: "sxnShtName",
    dataType: "",
    fixed: false,
    visible: true,
  },
  {
    id: 14,
    caption: "JOB TITLE",
    dataField: "jbtShtName",
    dataType: "",
    fixed: false,
    visible: true,
  },
  {
    id: 15,
    caption: "LOCATION",
    dataField: "locShtName",
    dataType: "",
    fixed: false,
    visible: true,
  },
  {
    id: 16,
    caption: "REMARKS",
    dataField: "dpaRmks",
    dataType: "",
    width: 5000,
    fixed: false,
    visible: true,
  },
];

// datagrid1
export const staffListColumns: datagridColumnTypes[] = [
  {
    id: 0,
    allowEditing: false,
    caption: "NO",
    dataField: "empIDpk",
    dataType: "",
    width: 50,
    fixed: true,
    visible: true,
  },
  {
    id: 1,
    allowEditing: true,
    caption: "DRIVER?",
    dataField: "dpaDriver",
    dataType: "boolean",
    width: 50,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    allowEditing: true,
    caption: "PH",
    dataField: "dpaPermitHolder",
    dataType: "boolean",
    width: 50,
    fixed: true,
    visible: true,
  },
  {
    id: 3,
    allowEditing: true,
    caption: "STAFF NO",
    dataField: "empStaffNo",
    dataType: "string",
    width: 80,
    fixed: true,
    visible: true,
  },
  {
    id: 4,
    allowEditing: false,
    caption: "NAME",
    dataField: "empname1",
    dataType: "string",
    width: 150,
    fixed: true,
    visible: true,
  },
  {
    id: 5,
    allowEditing: false,
    caption: "Mob. No",
    dataField: "empMobNo",
    dataType: "string",
    width: 150,
    fixed: true,
    visible: true,
  },
  {
    id: 6,
    allowEditing: false,
    caption: "INTERCOM",
    dataField: "empIntercom",
    dataType: "string",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 7,
    allowEditing: false,
    caption: "JOB TITLE",
    dataField: "jbtShtName",
    dataType: "string",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 8,
    allowEditing: false,
    caption: "DEPT",
    dataField: "dptShtName",
    dataType: "string",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 9,
    allowEditing: false,
    caption: "LOCATION",
    dataField: "locName",
    dataType: "string",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 10,
    allowEditing: false,
    caption: "SECTION",
    dataField: "sxnShtName",
    dataType: "string",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 11,
    allowEditing: false,
    caption: "FNAME",
    dataField: "empFirstName",
    dataType: "string",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 12,
    allowEditing: false,
    caption: "LNAME",
    dataField: "empLastName",
    dataType: "string",
    width: 130,
    fixed: true,
    visible: true,
  },
];

// datagrid2
export const membersColumns: datagridColumnTypes[] = [
  {
    id: 0,
    caption: "NO",
    allowEditing: false,
    dataField: "empIDpk",
    dataType: "",
    fixed: true,
    visible: true,
  },
  {
    id: 1,
    caption: "STAFF NO",
    allowEditing: false,
    dataField: "empStaffNo",
    dataType: "string",
    width: 80,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "NAME",
    allowEditing: false,
    dataField: "empname1",
    dataType: "string",
    width: 140,
    fixed: false,
    visible: true,
  },
  {
    id: 3,
    caption: "DRIVER?",
    allowEditing: true,
    dataField: "dpaDriver",
    dataType: "boolean",
    width: 50,
    fixed: false,
    visible: true,
  },
  {
    id: 4,
    caption: "PH",
    allowEditing: true,
    dataField: "dpaPermitHolder",
    dataType: "boolean",
    width: 50,
    fixed: false,
    visible: true,
  },
  {
    id: 5,
    caption: "START DATE",
    allowEditing: false,
    dataField: "dpaStartDate",
    dataType: "datetime",
    width: 150,
    fixed: false,
    visible: true,
  },
  {
    id: 6,
    caption: "END DATE",
    allowEditing: false,
    dataField: "dpaEndDate",
    dataType: "datetime",
    width: 150,
    fixed: false,
    visible: true,
  },
  {
    id: 7,
    caption: "REMARKS",
    allowEditing: true,
    dataField: "dpaRmks",
    dataType: "string",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 8,
    caption: "DEPT",
    allowEditing: false,
    dataField: "dptShtName",
    dataType: "string",
    width: 120,
    fixed: false,
    visible: true,
  },
  {
    id: 10,
    caption: "SECTION",
    allowEditing: false,
    dataField: "sxnShtName",
    dataType: "string",
    width: 120,
    fixed: false,
    visible: true,
  },
  {
    id: 11,
    caption: "JOB TITLE",
    allowEditing: false,
    dataField: "jbtShtName",
    dataType: "string",
    width: 120,
    fixed: false,
    visible: true,
  },
  {
    id: 12,
    caption: "MOB No",
    allowEditing: false,
    dataField: "empMobNo",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 13,
    caption: "INTERCOM",
    allowEditing: false,
    dataField: "empIntercom",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 14,
    caption: "LOCATION",
    allowEditing: false,
    dataField: "locName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 15,
    caption: "FNAME",
    allowEditing: false,
    dataField: "empFirstName",
    dataType: "STRING",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 16,
    caption: "LNAME",
    allowEditing: false,
    dataField: "empLastName",
    dataType: "STRING",
    width: 5000,
    fixed: false,
    visible: true,
  },
  {
    id: 17,
    caption: "DpaIdpk",
    allowEditing: false,
    dataField: "dpaIDpk",
    dataType: "number",
    width: 15,
    fixed: false,
    visible: false,
  },
];
