/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-useless-computed-key */
import {
  Column,
  DataGrid,
  Editing,
  Export,
  Pager,
  Scrolling,
} from "devextreme-react/data-grid";
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import _ from "lodash";

import {
  navbar_height,
  saveModalData,
} from "../../../../../../accessories/component_infos";
import XLSX from "xlsx";
import { v4 as uuidv4 } from 'uuid';
import {
  first_data_TH,
  vehicle_data_upload_TH,
} from "../data/vehicle-data-upload-data";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import Datagrid_template from "../../../../../../../templates/Datagrid";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import useResizeMode from "../../../../../../../hooks/useResizeMode";
import { SaveButton } from "../../../../../../accessories/buttons";
import dxDataGrid, { CellPreparedEvent } from "devextreme/ui/data_grid";
import { AsyncGet } from "../../../../../../../functions/get";
import { EventInfo } from "devextreme/events";
import Mapper, { VehicleData } from "../data/entity";
import { PostDataFunc  } from "../../../../../../../functions/post";
import { UpdateDataFunc } from "../../../../../../../functions/update";
export interface VehicleProps {
  vdtRegNo: string;
  vdtChassisNo: string;
  vdtEngineNo: string;
  vhcName: string;
  vmkName: string;
  mftName: string;
  mdlName: string;
  splName: string;
  vdtColour: string;
  egtName: string;
  ftpName: string;
  egmName: string;
  pwtName: string;
  ctyName: string;
  trtName: string;
  vdtSeatingCapacity: number;
  curSymbol: string;
  vstName: string;
  vstFitStatus: boolean;
  vdtManufactureDate: string;
  vdtSupplyDate: string;
  vdtDvlaRegDate: string;
  vdtCubicCapacity: number;
  vdtTankCapacity: number;
  vdtNoOfCylinders: number;
  vdtLength: number;
  vdtWidth: number;
  vdtHeight: number;
  vdtNoOfAxles: number;
  vdtNoOfWheels: number;
  vdtTyreSizeFront: string;
  vdtTyreSizeMiddle: string;
  vdtTyreSizeRear: string;
  vdtNetWeight: number;
  vdtGrossWeight: number;
  vdtHorsePower: string;
  vdtPrice: number;
  vdtPriceCurrencyIDfk: number;
  vdtAssigned: boolean;
  vdtActive: boolean;
  vdtRmks: string;
}

export interface DatagridExcelProps extends ExtraFieldProps {
  "REG No": string;
  "CHASSIS No": string;
  "Engine No": string;
  "VEHICLE CLASS": string;
  "VEHICLE MAKE": string;
  "VEHICLE MODEL": string;
  MANUFACTURER: string;
  COUNTRY: string;
  SUPPLIER: string;
  "SUPPLY DATE": string;
  "DVLA REG DATE": string;
  "ENGINE TYPE": string;
  "FUEL TYPE": string;
  "CUBIC CAPACITY": string;
  "TANK CAPACITY": number;
  "POWER TRAIN": string;
  "TRANSMISSION TYPE": string;
  "ENGINE MAKE": boolean;
  "No OF CYLINDERS": string;
  COLOUR: string;
  STATUS: string;
  "SEATING CAPACITY": number;
  LENGTH: number;
  WIDTH: number;
  HEIGHT: number;
  "No OF AXLES": number;
  "No OF WHEELS": number;
  "TYRE SIZE (F)": number;
  "TYRE SIZE (M)": number;
  "TYRE SIZE (R)": string;
  "NET WEIGHT": string;
  "GROSS WEIGHT": string;
  "HORSE POWER": number;
  PRICE: number;
  "PRICE CURRENCY": string;
  REMARKS: number;
  PASSED: number;
}

export type ExtraFieldProps = {
  vdtModelIdfk: number;
  vdtManufacturerIdfk: number;
  vdtCountryOfOriginIdfk: number;
  vdtSupplierIdfk: number;
  vdtPowerTrainIdfk: number;
  vdtTransmissionTypeIdfk: number;
  vdtEngineMakeIdfk: number;
  vdtEngineTypeIdfk: number;
  vdtFuelTypeIdfk: number;
  vdtStatusIdfk: number;
  vdtPriceCurrencyIdfk: number;

  vdtCreatedBy: string;
};

interface props {
  toggled?: boolean;
  mainDataGridData?: VehicleProps[];
  revalidateSheet?: boolean;
  setRevalidateSheet?: (e: boolean) => void;
}

export const trimAll = (value: any): any => {
  if (typeof value === "string") {
    return _.trim(value!);
  }
  return value;
};

const VehicleDataUploadDetails = forwardRef(
  ({ toggled, mainDataGridData, revalidateSheet, setRevalidateSheet }: props, importedRef) => {
    const news_flash_height = useSelector(
      (state: RootState) => state.general.newsflashHeight
    );
    const borderTheme = useSelector(
      (state: RootState) => state.general.cssBorderClass
    );

    var [resizeMode, initialResizeMode]: any = useResizeMode(!toggled);
    const pickerRef = useRef<any>();

    const [formheight] = useState<number>(0);

    const formRef = useRef<any>(null);
    var fullPage = initialResizeMode - (navbar_height + news_flash_height + 35);

    var pageHeight =
      resizeMode - (navbar_height + news_flash_height + formheight + 35);

    const [firstTable, setFirstTable] = useState<any>([]);

    const [isfixSheet, setIsFixSheet] = useState(false);
    // on page resize height responsiveness

    useImperativeHandle(importedRef, () => ({
      test: () => {
        pickerRef!.current!.click();
      },

      fixSheet: () => {
        setIsFixSheet(true);
      },
    }));

    const [modalInfo, setModalInfo] = useState<any>({
      icon: "question",
      data: saveModalData,
      disableCancel: false,
      openModal: false,
      event: () => { },
    });

    // Posts data
    const postData = async () => {

      let postNew: VehicleData[] = _.uniqBy(readyToSave, (item: VehicleData) => `${item.vdtRegNo}${item.vdtChassisNo}${item.vdtEngineNo}${item.vdtModelIdfk}${item.vdtManufacturerIdfk}${item.vdtCountryOfOriginIdfk}${item.vdtSupplierIdfk}${item.vdtPowerTrainIdfk}${item.vdtTransmissionTypeIdfk}${item.vdtEngineMakeIdfk}${item.vdtEngineTypeIdfk}${item.vdtFuelTypeIdfk}${item.vdtStatusIdfk}${item.vdtPriceCurrencyIdfk}`);
      let postUpdate: VehicleData[] = _.uniqBy(readyToUpdate, (item: VehicleData) => `${item.vdtRegNo}${item.vdtChassisNo}${item.vdtEngineNo}${item.vdtModelIdfk}${item.vdtManufacturerIdfk}${item.vdtCountryOfOriginIdfk}${item.vdtSupplierIdfk}${item.vdtPowerTrainIdfk}${item.vdtTransmissionTypeIdfk}${item.vdtEngineMakeIdfk}${item.vdtEngineTypeIdfk}${item.vdtFuelTypeIdfk}${item.vdtStatusIdfk}${item.vdtPriceCurrencyIdfk}`);

      console.log("postNew: ", postNew);
      console.log("postUpdate: ", postUpdate);

      await Promise.all([
        PostDataFunc ("AssetMgrLoadVehicleData/UploadVehicleDetails", postNew),
        UpdateDataFunc("AssetMgrLoadVehicleData/UpdateVehicleDetails", postUpdate),
      ])

      // Modal calling 
      setModalInfo({
        ...modalInfo,
        ["icon"]: "success",
        ["openModal"]: true,
        ["disableCancel"]: true,
        ["data"]: {
          message: "Successfully saved!",
          title: "Record Saved!",
          okText: "Ok",
        },
        ["event"]: () => {
          setModalInfo({ ...modalInfo, ["openModal"]: false });
        },
      });


    }


    const Validation_on_save = async () => {
      if (firstTable.length < 1) {
        setModalInfo({
          ...modalInfo,
          ["icon"]: "warning",
          ["openModal"]: true,
          ["disableCancel"]: true,
          ["data"]: {
            message: "Please import an excel to populate record",
            title: "No Record Found !",
            okText: "Ok",
          },
          ["event"]: () => {
            setModalInfo({ ...modalInfo, ["openModal"]: false });
          },
        });
      } else {


        setModalInfo({
          ...modalInfo,
          ["icon"]: "question",
          ["openModal"]: true,

          ["data"]: saveModalData,
          ["disableCancel"]: false,
          ["event"]: async () => { postData() },
        });
      }
    };




    const onFileChangeHandler = (excelFile: any) => {
      if (excelFile) {
        if (!excelFile.name.match(/\.(xlsx|xls|csv|xlsm)$/)) {
          alert("Please Upload Excel File");
        } else {
          let fileReader = new FileReader();
          fileReader.readAsBinaryString(excelFile);

          fileReader.onload = (event: any) => {
            let data = event!.target.result;
            let workbook = XLSX.read(data, { type: "binary" });
            workbook.SheetNames.forEach((sheet) => {
              var rowObject: any = XLSX.utils.sheet_to_json(
                workbook.Sheets[sheet]
              );

              setFirstTable(rowObject);
            });
          };
        }
      }
    };


    let readyToSave: any[] = [];
    let readyToUpdate: any[] = [];
    const new_map = new Mapper();


    const FireAfterDGridLoaded = useCallback(async function (event: EventInfo<dxDataGrid<any, any>>) {


      if (revalidateSheet) {

        const dataSource = event.component.getDataSource();
        if (dataSource && dataSource.items().length > 0) {
          await IterateAndColourSheet(event);
        }


      }



    }, [revalidateSheet])



    const IterateAndColourSheet = async function (
      e: EventInfo<dxDataGrid<any, any>>
    ) {
      let visibleRows = e.component.getVisibleRows();
      if (visibleRows.length === 0) return;

      for (
        let rowIndex = 0;
        rowIndex < firstTable.length ?? visibleRows.length;
        rowIndex++
      ) {
        // Class Make Model not setup
        let getClassCell = e.component.getCellElement(
          rowIndex,
          "VEHICLE CLASS"
        );
        let getMakeCell = e.component.getCellElement(rowIndex, "VEHICLE MAKE");
        let getModelCell = e.component.getCellElement(
          rowIndex,
          "VEHICLE MODEL"
        );
        let getManufCell = e.component.getCellElement(rowIndex, "MANUFACTURER");

        // Record saved/Record ready to save
        let regNoCell = e.component.getCellElement(rowIndex, "REG No");

        // Check for duplicates
        let chassisNoCell = e.component.getCellElement(rowIndex, "CHASSIS No");
        let engineNoCell = e.component.getCellElement(rowIndex, "Engine No");

        // Foriegn keys columns
        let manufacturerCell = e.component.getCellElement(
          rowIndex,
          "MANUFACTURER"
        );
        let countryCell = e.component.getCellElement(rowIndex, "COUNTRY");
        let supplierCell = e.component.getCellElement(rowIndex, "SUPPLIER");
        let engineTypeCell = e.component.getCellElement(
          rowIndex,
          "ENGINE TYPE"
        );
        let fuelTypeCell = e.component.getCellElement(rowIndex, "FUEL TYPE");
        let powerTrainCell = e.component.getCellElement(
          rowIndex,
          "POWER TRAIN"
        );
        let transmissionTypeCell = e.component.getCellElement(
          rowIndex,
          "TRANSMISSION TYPE"
        );
        let engineMakeCell = e.component.getCellElement(
          rowIndex,
          "ENGINE MAKE"
        );
        let statusCell = e.component.getCellElement(rowIndex, "STATUS");
        let currencyCell = e.component.getCellElement(
          rowIndex,
          "PRICE CURRENCY"
        );

        let [
          checkRegNo,
          checkChassisNo,
          checkEngineNo,
          checkManufacturer,
          checkCountry,
          checkSupplier,
          checkEngineType,
          checkFuelType,
          checkPowerTrain,
          checkTransmissionType,
          checkEngineMake,
          checkStatus,
          checkCurrency,
          checkClassMakeModel,
        ] = await Promise.all([
          AsyncGet(`AssetMgrLoadVehicleData/CheckSavedRegNo`, {
            VdtRegNo: regNoCell?.textContent,
          }),
          AsyncGet(`AssetMgrLoadVehicleData/CheckDuplicateChassisNos`, {
            ChassisNo: chassisNoCell?.textContent,
            vdtRegNo: regNoCell?.textContent,
          }),
          AsyncGet(`AssetMgrLoadVehicleData/CheckDuplicateEngineNos`, {
            EngineNo: engineNoCell?.textContent,
            vdtRegNo: regNoCell?.textContent,
          }),
          AsyncGet(`AssetMgrLoadVehicleData/GetRecordIDWithName`, {
            name: manufacturerCell?.textContent,
            criteria: 0,
            shtName: manufacturerCell?.textContent,
          }),
          AsyncGet(`AssetMgrLoadVehicleData/GetRecordIDWithName`, {
            name: countryCell?.textContent,
            criteria: 1,
            shtName: countryCell?.textContent,
          }),
          AsyncGet(`AssetMgrLoadVehicleData/GetRecordIDWithName`, {
            name: supplierCell?.textContent,
            criteria: 2,
            shtName: supplierCell?.textContent,
          }),
          AsyncGet(`AssetMgrLoadVehicleData/GetRecordIDWithName`, {
            name: engineTypeCell?.textContent,
            criteria: 3,
            shtName: engineTypeCell?.textContent,
          }),
          AsyncGet(`AssetMgrLoadVehicleData/GetRecordIDWithName`, {
            name: fuelTypeCell?.textContent,
            criteria: 4,
            shtName: fuelTypeCell?.textContent,
          }),
          AsyncGet(`AssetMgrLoadVehicleData/GetRecordIDWithName`, {
            name: powerTrainCell?.textContent,
            criteria: 5,
            shtName: powerTrainCell?.textContent,
          }),
          AsyncGet(`AssetMgrLoadVehicleData/GetRecordIDWithName`, {
            name: transmissionTypeCell?.textContent,
            criteria: 6,
            shtName: transmissionTypeCell?.textContent,
          }),
          AsyncGet(`AssetMgrLoadVehicleData/GetRecordIDWithName`, {
            name: engineMakeCell?.textContent,
            criteria: 7,
            shtName: engineMakeCell?.textContent,
          }),
          AsyncGet(`AssetMgrLoadVehicleData/GetRecordIDWithName`, {
            name: statusCell?.textContent,
            criteria: 8,
            shtName: statusCell?.textContent,
          }),
          AsyncGet(`AssetMgrLoadVehicleData/GetRecordIDWithName`, {
            name: currencyCell?.textContent,
            criteria: 9,
            shtName: currencyCell?.textContent,
          }),
          AsyncGet(`AssetMgrLoadVehicleData/GetModelNameById`, {
            mftName: getManufCell?.textContent,
            mdlName: getModelCell?.textContent,
            vmkName: getMakeCell?.textContent,
            vhcName: getClassCell?.textContent,
          }),
        ]);


        if (
          checkRegNo!.status &&
          checkChassisNo!.data.length < 1 &&
          checkEngineNo!.data.length < 1 &&
          checkManufacturer!.status &&
          checkManufacturer!.data.length > 0 &&
          checkCountry!.status &&
          checkCountry!.data.length > 0 &&
          checkSupplier!.status &&
          checkSupplier!.data.length > 0 &&
          checkEngineType!.status &&
          checkEngineType!.data.length > 0 &&
          checkFuelType!.status &&
          checkFuelType!.data.length > 0 &&
          checkPowerTrain!.status &&
          checkPowerTrain!.data.length > 0 &&
          checkTransmissionType!.status &&
          checkTransmissionType!.data.length > 0 &&
          checkEngineMake!.status &&
          checkEngineMake!.data.length > 0 &&
          checkStatus!.status &&
          checkStatus!.data.length > 0 &&
          checkCurrency!.status &&
          checkCurrency!.data.length > 0 &&
          checkClassMakeModel!.status
        ) {
          // regNoCell!.style.backgroundColor = "#86EFAC";
          regNoCell!.classList.add("bg-green-400");

          let getRowData: DatagridExcelProps = e.component
            .getDataSource()
            .items()[rowIndex];

          let extraFields = {
            vdtModelIdfk: checkClassMakeModel?.data.mdlIDpk,
            vdtManufacturerIdfk: checkManufacturer?.data[0].recordIDpk,
            vdtCountryOfOriginIdfk: checkCountry?.data[0].recordIDpk,
            vdtSupplierIdfk: checkSupplier?.data[0].recordIDpk,
            vdtPowerTrainIdfk: checkPowerTrain?.data[0].recordIDpk,
            vdtTransmissionTypeIdfk: checkTransmissionType?.data[0].recordIDpk,
            vdtEngineMakeIdfk: checkEngineMake?.data[0].recordIDpk,
            vdtEngineTypeIdfk: checkEngineType?.data[0].recordIDpk,
            vdtFuelTypeIdfk: checkFuelType?.data[0].recordIDpk,
            vdtStatusIdfk: checkStatus?.data[0].recordIDpk,
            vdtPriceCurrencyIdfk: checkCurrency?.data[0].recordIDpk,
            vdtCreatedBy: userId.toString(),
          } satisfies ExtraFieldProps;

          let oneNess = {
            ...getRowData,

            ...extraFields,
          };

          let converted = new_map.toSubmittable(oneNess);
          readyToUpdate.push(converted);



        } else if (
          checkRegNo!.status! === false &&
          checkChassisNo!.data.length < 1 &&
          checkEngineNo!.data.length < 1 &&
          checkManufacturer!.status &&
          checkManufacturer!.data.length > 0 &&
          checkCountry!.status &&
          checkCountry!.data.length > 0 &&
          checkSupplier!.status &&
          checkSupplier!.data.length > 0 &&
          checkEngineType!.status &&
          checkEngineType!.data.length > 0 &&
          checkFuelType!.status &&
          checkFuelType!.data.length > 0 &&
          checkPowerTrain!.status &&
          checkPowerTrain!.data.length > 0 &&
          checkTransmissionType!.status &&
          checkTransmissionType!.data.length > 0 &&
          checkEngineMake!.status &&
          checkEngineMake!.data.length > 0 &&
          checkStatus!.status &&
          checkStatus!.data.length > 0 &&
          checkCurrency!.status &&
          checkCurrency!.data.length > 0 &&
          checkClassMakeModel!.status
        ) {
          regNoCell?.classList.add("bg-amber-200");

          let extraFields = {
            vdtModelIdfk: checkClassMakeModel?.data.mdlIDpk,
            vdtManufacturerIdfk: checkManufacturer?.data[0].recordIDpk,
            vdtCountryOfOriginIdfk: checkCountry?.data[0].recordIDpk,
            vdtSupplierIdfk: checkSupplier?.data[0].recordIDpk,
            vdtPowerTrainIdfk: checkPowerTrain?.data[0].recordIDpk,
            vdtTransmissionTypeIdfk: checkTransmissionType?.data[0].recordIDpk,
            vdtEngineMakeIdfk: checkEngineMake?.data[0].recordIDpk,
            vdtEngineTypeIdfk: checkEngineType?.data[0].recordIDpk,
            vdtFuelTypeIdfk: checkFuelType?.data[0].recordIDpk,
            vdtStatusIdfk: checkStatus?.data[0].recordIDpk,
            vdtPriceCurrencyIdfk: checkCurrency?.data[0].recordIDpk,
            vdtCreatedBy: userId.toString(),
          } satisfies ExtraFieldProps;

          let getRowData: DatagridExcelProps = e.component
            .getDataSource()
            .items()[rowIndex];

          let oneNess = {
            ...getRowData,

            ...extraFields,
          };

          let converted = new_map.toSubmittable(oneNess);
          readyToSave.push(converted);



        } else {

          if (!checkClassMakeModel?.status) {

            getClassCell?.classList.add("bg-orange-400");
            getMakeCell?.classList.add("bg-orange-400");
            getModelCell?.classList.add("bg-orange-400");
          }

          if (checkChassisNo?.status && checkChassisNo?.data.length > 0)
            chassisNoCell?.classList.add("bg-rose-600");
          if (checkEngineNo?.status && checkChassisNo?.data.length > 0)
            engineNoCell?.classList.add("bg-rose-600");

          if (checkManufacturer?.status && checkManufacturer?.data.length === 0)
            manufacturerCell?.classList.add("bg-yellow-300");
          if (checkCountry?.status && checkCountry?.data.length === 0)
            countryCell?.classList.add("bg-yellow-300");
          if (checkSupplier?.status && checkSupplier?.data.length === 0)
            supplierCell?.classList.add("bg-yellow-300");
          if (checkEngineType?.status && checkEngineType?.data.length === 0)
            engineTypeCell?.classList.add("bg-yellow-300");
          if (checkFuelType?.status && checkFuelType?.data.length === 0)
            fuelTypeCell?.classList.add("bg-yellow-300");
          if (checkPowerTrain?.status && checkPowerTrain?.data.length === 0)
            powerTrainCell?.classList.add("bg-yellow-300");
          if (
            checkTransmissionType?.status &&
            checkTransmissionType?.data.length === 0
          )
            transmissionTypeCell?.classList.add("bg-yellow-300");
          if (checkEngineMake?.status && checkEngineMake?.data.length === 0)
            engineMakeCell?.classList.add("bg-yellow-300");
          if (checkStatus?.status && checkStatus?.data.length === 0)
            statusCell?.classList.add("bg-yellow-300");
          if (checkCurrency?.status && checkCurrency?.data.length === 0)
            currencyCell?.classList.add("bg-yellow-300");
        }
      }
    };


    const [userId, employID, userObj] = useAccountInfo();

    const [privType, setPrivType] = useState<string>("save");

    const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);
    const [disable, setDisable] = useState(false);

    const AssessPriv_on_SaveButton = async () => {
      setPrivType("save");
      if (savePriv === true) {
        // setDisable(false);
        await Validation_on_save();
      } else {
        // setDisable(true);
        setModalInfo({
          ...modalInfo,
          ["icon"]: "warning",
          ["openModal"]: true,
          ["disableCancel"]: true,
          ["data"]: {
            message: "No Access Privilege",
            title: "Access Denied",
            okText: "Ok",
          },
        });
      }
    };

    return (
      <div>
        <ModalTemplate
          icon={modalInfo.icon}
          disableCancel={modalInfo.disableCancel}
          cancelText={modalInfo.data.cancelText}
          open={modalInfo.openModal}
          okHandler={modalInfo.event}
          cancelHandler={() => {
            setModalInfo({ ...modalInfo, ["openModal"]: false });
          }}
          message={modalInfo.data.message}
          okText={modalInfo.data.okText}
          title={modalInfo.data.title}
        />

        <style
          dangerouslySetInnerHTML={{
            __html: `
           input[type="text"]:disabled {
            background: #fffbeb;
            height:22px
          }  

          
          
          .dx-datagrid-header-panel .dx-toolbar {
            margin-bottom: 1.5px;
        }

          .filterTodos .dx-datagrid-headers{
              margin-top: 10px;
          }

            `,
          }}
        />

        <div
          className="w-full  border-[1px]  rounded-md"
          style={{
            display: toggled === true ? "none" : "block",
            borderColor: borderTheme,
          }}
        >
          <div
            id={"form_header"}
            style={{ borderColor: borderTheme }}
            className="border-b-[1px] pr-3 text-xs bg-slate-100 rounded-t-md py-1 px-1 font-medium"
          >
            Vehicle Details
          </div>
          <div className="pt-0.5">
            <div
              ref={formRef}
              className="mb-[1px] mx-[1px] "
              style={{
                display: toggled === true ? "none" : "block",
                height: "40%",
              }}
            >
              <input
                className="hidden"
                ref={pickerRef}
                type="file"
                onInput={(e: any) => {
                  onFileChangeHandler(e.target.files![0]);
                }}
              />

              <DataGrid
                dataSource={firstTable}
                key={revalidateSheet ? uuidv4() : ''}
                showBorders={true}
                columnWidth={150}
                showRowLines={true}
                allowColumnReordering={true}
                onCellPrepared={async (e: CellPreparedEvent<any, any>) => {
                  if (e.rowType === "data") {
                    let value = trimAll(e.value);
                    if (
                      e.columnIndex === 9 &&
                      (value === null || value === undefined || value === "")
                    ) {
                      e.cellElement.innerText = "1900-01-01";
                    } else if (
                      value === null ||
                      value === undefined ||
                      value === ""
                    ) {
                      e.cellElement.innerText = "N/A";
                    }
                  }


                }}
                // onContentReady={async (e) => { await IterateAndColourSheet(e) }}
                onContentReady={async (e: EventInfo<dxDataGrid<any, any>>) => { await FireAfterDGridLoaded(e) }}
                onRowUpdated={async (e: EventInfo<dxDataGrid<any, any>>) => {
                  if (readyToSave.length > 0 && readyToUpdate.length > 0) {
                    readyToSave = [];
                    readyToUpdate = [];
                  }
                  await IterateAndColourSheet(e);
                }}
                onRowRemoved={async (e: EventInfo<dxDataGrid<any, any>>) => {
                  if (readyToSave.length > 0 && readyToUpdate.length > 0) {
                    readyToSave = [];
                    readyToUpdate = [];
                  }
                  await IterateAndColourSheet(e);
                }}
                onRowInserted={async (e: EventInfo<dxDataGrid<any, any>>) => {
                  if (readyToSave.length > 0 && readyToUpdate.length > 0) {
                    readyToSave = [];
                    readyToUpdate = [];
                  }
                  await IterateAndColourSheet(e);
                }}
                showColumnLines={true}
                //Remember to change
                height={toggled === true ? 0 : 340}
              >
                <Pager visible={false} />
                <Export enabled={true} />
                <Editing
                  mode="cell"
                  allowUpdating={true}
                  allowAdding={true}
                  allowDeleting={true}
                />

                {/* STAFF DETAILS COLUMNS */}
                {/* FIXED COLUMNS */}

                {first_data_TH.map((data) => {
                  return (
                    <Column
                      allowEditing={true}
                      key={data.id}
                      caption={data.caption}
                      dataField={data.dataField}
                      alignment={"left"}
                      fixed={data.fixed}
                      width={data.width === null ? 150 : data.width}
                    />
                  );
                })}

                <Scrolling columnRenderingMode="virtual" />
              </DataGrid>
              <div className=" mt-[1px] flex justify-between ">
                <div className="flex">
                  <div>
                    <button className="bg-green-300 dark:text-darkModeSkin-base flex flex-row items-center justify-center   px-2 py-0.5 border border-stone-200 rounded">
                      Record Saved
                    </button>
                  </div>
                  <div>
                    <button className="bg-amber-200 dark:text-darkModeSkin-base flex flex-row items-center justify-center ml-1  px-2 py-0.5 border border-stone-200 rounded">
                      Ready to Save
                    </button>
                  </div>
                  <div>
                    <button className="bg-orange-300 dark:text-darkModeSkin-base flex flex-row items-center justify-center ml-1  px-2 py-0.5 border border-stone-200 rounded">
                      Class-Make-Model not setup
                    </button>
                  </div>
                  <div>
                    <button className="bg-yellow-300 dark:text-darkModeSkin-base flex flex-row items-center justify-center ml-1   px-2 py-0.5 border border-stone-200 rounded">
                      No Setup
                    </button>
                  </div>
                  <div>
                    <button className="bg-red-500 dark:text-darkModeSkin-base flex flex-row items-center justify-center ml-1   px-2 py-0.5 border border-stone-200 rounded">
                      Duplicate Record
                    </button>
                  </div>
                </div>
                <div className="flex pr-0.5">
                  <SaveButton
                    useCallbackFunc={true}
                    disableButton={disable}
                    handleSave={AssessPriv_on_SaveButton}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pr-0">
          <Datagrid_template
            gridheight={toggled === true ? fullPage : pageHeight}
            data={mainDataGridData}
            columns={vehicle_data_upload_TH}
          />
        </div>
      </div>
    );
  }
);

export default VehicleDataUploadDetails;
