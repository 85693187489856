export const vehicle_requests_security_TH = [
  {
    id: 0,
    caption: "ID",
    dataField: "vrqIDpk",
    alignment: "left",
    fixed: true,
    width: 60,
  },
  {
    id: 1,
    caption: "CAN.?",
    dataField: "vrqCancelled",
    alignment: "left",
    fixed: true,
    width: 60,
  },
  {
    id: 2,
    caption: "RECOM.",
    dataField: "vrqRecommended",
    alignment: "left",
    fixed: true,
    width: 60,
  },
  {
    id: 3,
    caption: "APRVD?",
    dataField: "vrqApproved",
    alignment: "left",
    fixed: true,
    width: 60,
  },
  {
    id: 4,
    caption: "ACTIVE?",
    dataField: "vrqActive",
    alignment: "left",
    fixed: true,
    width: 60,
  },
  {
    id: 5,
    caption: "VEH?",
    dataField: "vrqRequestVehicle",
    alignment: "left",
    fixed: true,
    width: 60,
  },
  {
    id: 6,
    caption: "DRIV?",
    dataField: "vrqRequestDriver",
    alignment: "left",
    fixed: true,
    width: 60,
  },

  {
    id: 7,
    caption: "SMS SENT?",
    dataField: "vrqSmsSent",
    alignment: "left",
    fixed: true,
    width: 60,
  },

  {
    id: 8,
    caption: "KEY OUT?",
    dataField: "vrqVehicleKeyOut",
    alignment: "left",
    fixed: true,
    width: 60,
  },
  {
    id: 9,
    caption: "KEY IN?",
    dataField: "vrqVehicleKeyReturned",
    alignment: "left",
    fixed: true,
    width: 60,
  },
  {
    id: 10,
    caption: "PIS",
    dataField: "vrqPickupInspectionDone",
    alignment: "left",
    fixed: true,
    width: 60,
  },

  {
    id: 11,
    caption: "RIS",
    dataField: "vrqReturnInpsectionDone",
    alignment: "left",
    fixed: true,
    width: 60,
  },

  {
    id: 12,
    caption: "OFFICIAL",
    dataField: "vrqOfficialUse",
    alignment: "left",
    fixed: true,
    width: 60,
  },
  {
    id: 13,
    caption: "REQUESTED BY",
    dataField: "requesterEmpName",
    alignment: "left",
    fixed: false,
    width: 160,
  },
  {
    id: 14,
    caption: "REQUEST AREA",
    dataField: "vraName",
    alignment: "left",
    fixed: false,
    width: 160,
  },

  {
    id: 15,
    caption: "DEPARTURE TIME",
    dataField: "vrqDepartureTime",
    dataType: "datetime",
    alignment: "left",
    fixed: false,
    width: 160,
  },
  {
    id: 16,
    caption: "RETURN TIME",
    dataField: "vrqActualReturnTime",
    dataType: "datetime",
    alignment: "left",
    fixed: false,
    width: 160,
  },
  {
    id: 17,
    caption: "REG. No",
    dataField: "vdtRegNo",
    alignment: "left",
    fixed: false,
    width: 160,
  },
  {
    id: 18,
    caption: "TOWN (FROM)",
    dataField: "vrqFromTown",
    alignment: "left",
    fixed: false,
    width: 160,
  },
  {
    id: 19,
    caption: "TOWN (TO)",
    dataField: "vrqToTown",
    alignment: "left",
    fixed: false,
    width: 160,
  },
  {
    id: 20,
    caption: "PURPOSE",
    dataField: "vrqPurpose",
    alignment: "left",
    fixed: false,
    width: 160,
  },
  {
    id: 21,
    caption: "ROUTE",
    dataField: "vrqRoute",
    alignment: "left",
    fixed: false,
    width: 160,
  },
  {
    id: 22,
    caption: "START. ODOM.",
    dataField: "vrqStartOdometerReading",
    alignment: "left",
    fixed: false,
    width: 160,
  },
  {
    id: 23,
    caption: "FINAL ODOM.",
    dataField: "vrqFinalOdometerReading",
    alignment: "left",
    fixed: false,
    width: 160,
  },
  {
    id: 24,
    caption: "DISTANCE",
    dataField: "tripDistance",
    alignment: "left",
    fixed: false,
    width: 160,
  },
  {
    id: 25,
    caption: "CLASS",
    dataField: "vrcName",
    alignment: "left",
    fixed: false,
    width: 160,
  },
  {
    id: 26,
    caption: "CATEGORY",
    dataField: "vctName",
    alignment: "left",
    fixed: false,
    width: 160,
  },
  {
    id: 27,
    caption: "RECOMMENDED BY",
    dataField: "recommendedByEmpName",
    alignment: "left",
    fixed: false,
    width: 160,
  },
  {
    id: 28,
    caption: "APPROVED BY",
    dataField: "approverEmpName",
    alignment: "left",
    fixed: false,
    width: 160,
  },
  {
    id: 29,
    caption: "DRIVER",
    dataField: "driverEmpName",
    alignment: "left",
    fixed: false,
    width: 160,
  },
  {
    id: 30,
    caption: "RATING",
    dataField: "vrqOverallRating",
    alignment: "left",
    fixed: false,
    width: 160,
  },
  {
    id: 31,
    caption: "REGION (FROM)",
    dataField: "fromRgnName",
    alignment: "left",
    fixed: false,
    width: 160,
  },
  {
    id: 32,
    caption: "REGION (TO)",
    dataField: "toRgnName",
    alignment: "left",
    fixed: false,
    width: 160,
  },
  {
    id: 33,
    caption: "SEAT. CAP.",
    dataField: "vrqApprovedSeatingCapacity",
    alignment: "left",
    fixed: false,
    width: 160,
  },
  {
    id: 34,
    caption: "DEBIT",
    dataField: "vrqDebit",
    alignment: "left",
    fixed: false,
    width: 160,
  },
  {
    id: 35,
    caption: "APPROVAL REMARKS",
    dataField: "vrqApprovalRmks",
    alignment: "left",
    fixed: false,
    width: 160,
  },
  {
    id: 36,
    caption: "REQ. DEPT",
    dataField: "requesterDeptShtName",
    alignment: "left",
    fixed: false,
    width: 160,
  },
  {
    id: 37,
    caption: "REQ. SECTION",
    dataField: "requesterSxnShtName",
    alignment: "left",
    fixed: false,
    width: 160,
  },
  {
    id: 38,
    caption: "REQUESTED ON",
    dataField: "vrqCreationDate",
    dataType: "datetime",
    alignment: "left",
    fixed: false,
    width: 160,
  },
  {
    id: 39,
    caption: "RECOMMENDED ON",
    dataField: "vrqRecommendedOn",
    dataType: "datetime",
    alignment: "left",
    fixed: false,
    width: 160,
  },
  {
    id: 40,
    caption: "APPROVED ON",
    dataField: "vrqApprovedOn",
    alignment: "left",
    fixed: false,
    width: 160,
  },
  {
    id: 41,
    caption: "KEY PICKED ON",
    dataField: "vrqKeyPickedOn",
    alignment: "left",
    fixed: false,
    width: 160,
  },
  {
    id: 42,
    caption: "KEY RETURNED ON",
    dataField: "vrqKeyReturnedOn",
    alignment: "left",
    fixed: false,
    width: 160,
  },
  {
    id: 43,
    caption: "CANCELLATION REASON",
    dataField: "vrqCancellationRmks",
    alignment: "left",
    fixed: false,
    width: 160,
  },
  {
    id: 44,
    caption: "REMARKS",
    dataField: "vrqRmks",
    alignment: "left",
    fixed: false,
    width: 4000,
  },
];

export const vehicle_assignment_data = [];
