import refresh from "../../../../../../assets/refresh.png";
import edit from "../../../../../../assets/edit.png";
import close from "../../../../../../assets/close.png";
import reset from "../../../../../../assets/reset.png";
import save from "../../../../../../assets/save.png";
import save2 from "../../../../../../assets/save2.png";
import newIcon from "../../../../../../assets/new.png";
import { Mini_datagrid_template } from "../data/mini_datagrid";
import Datagrid_template from "../../../../../../templates/Datagrid";
import { DatePicker, Form, Select, Tooltip } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";
import {
  setGeneralValue,
  settask_footer_update,
} from "../../../../../../features/generalSlice";
import useFetch from "../../../../../../hooks/useFetch";
import { ModalTemplate } from "../../../../../../templates/modal";
import {
  saveModalData,
  api_url,
  updateModalData,
} from "../../../../../accessories/component_infos";
import { inventory_checklist } from "../data/mini_datagrid_cols";
import { GetDataFunc } from "../../../../../../functions/get";
import { Data } from "@syncfusion/ej2-react-grids";
import { truncate } from "lodash";
import { UpdateStates } from "../../../../../../functions/updateStates";
interface props {
  selectedRecord?: any;
  openSelectedTask?: any;
  pmaId?: string;
  updateStates?: (key: string, val: any) => void;
  statesx?: any;
  regNo?: string;
}

interface stateTypes {
  job?: string;
  date?: string;
  description?: string;
  remarks?: string;
  inventorySelected?: any;
}

export const ActivitiesCapture_Job_Details = ({
  selectedRecord,
  openSelectedTask,
  pmaId,
  updateStates,
  statesx,
  regNo,
}: props) => {
  const dispatch = useDispatch();
  // states initialization
  const [states, setStates] = useState<stateTypes>({
    job: "",
    date: "",
    description: "",
    remarks: "",
    inventorySelected: [],
  });
  console.log("REGNO", regNo);
  //  Onchange fxn
  const setState = (key: string, value: any) => {
    setStates({ ...states, [key]: value });
  };
  const [inventory_List, setInventory_List] = useState<any>([]);
  const isInventory = useSelector(
    (state: RootState) => state.general.IsinventorySelected
  );
  const inventorySelected = useSelector(
    (state: RootState) => state.general.inventorySelected
  );
  const maintenanceType = useSelector(
    (state: RootState) => state.general.maintenanceType
  );
  const RegNo = useSelector((state: RootState) => state.general.RegNo);
  // console.log("maintenanceType", maintenanceType?.name);
  // console.log("RegNo", RegNo?.name);

  console.log("inventory Selected", inventorySelected);
  const [inventory_data, inventory_error, inventory_loading] = useFetch(
    `PmActivitiesCaptures/GetAllAsmTmsPmInventoryChecklistDetail?pidPmActivityIDx=${pmaId}`
  );
  const [model_id, model_id_error, model_id_loading] = useFetch(
    `PmActivitiesCaptures/GetAllVwAsmTmsVehicleDetail3IDs?vdtRegNo=${RegNo?.name}`
  );
  console.log("model_id", model_id[0]?.mdlIdpk);

  const [inventory_ListData] = useFetch(
    `PmActivitiesCaptures/CapturesGetMtiIDpk?MaintenanceType=${"Preventive Maintenance"}&modelIDx=${
      model_id[0]?.mdlIdpk
    }&FormId=${0}`
  );
  const [inventoryModel, setInventoryModel] = useState([]);
  const [inventoryCheck, setInventoryCheck] = useState([]);

  const appendColumns = (data: any[]) => {
    const temp: any = [...data];

    for (let i = 0; i < temp.length; i++) {
      // Add new properties to each object in the array
      temp[i] = {
        ...temp[i],
        pidBefore: false,
        pidAfter: false,
        pidRmks: "",
      };
    }

    return temp;
  };

  const addedCols = { pidAfter: false, pidBefore: false, pidRemark: "" };
  const modInventoryList = async () => {
    // const inventory_ListData = await GetDataFunc(`PmActivitiesCaptures/CapturesGetMtiIDpk?MaintenanceType=${
    //         maintenanceType?.name
    //       }&modelIDx=${model_id[0]?.mdlIdpk}&FormId=${0}`);
    console.log("&^% inv list", inventory_ListData);
    if (inventory_ListData.length > 0)
      setInventory_List(
        inventory_ListData.forEach((item: any) =>
          Object.assign(item, addedCols)
        )
      );
  };

  useEffect(() => {
    modInventoryList();
    setInventoryModel(appendColumns(inventory_ListData));
    setInventoryCheck(inventory_data);
  }, [inventory_ListData, inventory_data]);
console.log("inventoryCheck",inventoryCheck)
  // const rowColoring =  useCallback((e: any) => {
  //   if (e.rowType === "data") {
  //     console.log("row",e)
  //     if(e.data?.pidAfter || e.data?.pidBefore || e.data?.pidRmks){
  //   return (e.rowElement.style.backgroundColor = "rgb(134 239 172 / var(--tw-bg-opacity))"); }else{
  //     return(e.rowElement.style.backgroundColor = "rgb(240 181 5 / var(--tw-bg-opacity))")
  //   }
  // }
  // }, []);
  //rgb(134 239 172 / var(--tw-bg-opacity))
  //if (e.rowType === "data") {
  // return (e.rowElement.style.backgroundColor = "#FFE4C4");
  // }
  // const inplementRowColouring = useCallback((row: any) => {
  //   if (row.data?.faaAuthorise || row.data?.faaManage || row.data?.faaView) {
  //     row.rowElement.style.backgroundColor =
  //       "rgb(134 239 172 / var(--tw-bg-opacity))";
  //   } else {
  //     row.rowElement.style.backgroundColor = "rgb(240 181 5 / var(--tw-bg-opacity))";
  //   }
  // }, []);

  return (
    <div>
      {/* Datagrid */}

      <div className="p-2">
        <Datagrid_template
          columns={inventory_checklist}
          data={statesx?.isGridData === true ? inventoryCheck : inventoryModel} // appendColumns(inventory_ListData)
          gridheight={440}
          deselectFirstRow
          onRowUpdated={(e) => {
            const filteredRecord = inventoryModel.map((r) => {
              return e.data?.mtiIDpk === r.mtiIDpk ? { ...r, ...e.data } : r;
            });
            setInventoryModel(filteredRecord);
            const records = inventorySelected.map((v) =>
              v.mtiIDpk === e.data?.mtiIDpk ? { ...v, ...e.data } : v
            );
            const inventoryData = inventoryCheck?.map((r) => {
              return e.data?.mtiIDpk === r.mtiIDpk ? { ...r, ...e.data } : r;
            });
            const savedItems = inventorySelected?.map((v) =>
              v.mtiIDpk === e.data?.mtiIDpk ? { ...v, ...e.data } : v
            );
            setInventoryCheck(inventoryData);
            dispatch(
              setGeneralValue({ expr: "inventorySelected", value: records })
            );
            dispatch(
              setGeneralValue({ expr: "saveInventorys", value: savedItems })
            );
          }}
          // onRowPrepared={rowColoring}
          selectedItemsChange={(item: any) => {
            setState("inventorySelected", item);
            const filteredRecord = inventoryModel.map((r) => {
              const matchedItem = item?.find((e) => e.mtiIDpk === r.mtiIDpk);
              return matchedItem ? { ...r, ...matchedItem } : r;
            });
            const inventoryData = inventoryCheck?.map((r) => {
              const matchedItem = item?.find((e) => e.mtiIDpk === r.mtiIDpk);
              return matchedItem ? { ...r, ...matchedItem } : r;
            });
            setInventoryModel(filteredRecord);
            setInventoryCheck(inventoryData)
            dispatch(
              setGeneralValue({ expr: "inventorySelected", value: item })
            );
            console.log("selectedItemsChange", item);
            dispatch(
              setGeneralValue({ expr: "saveInventorys", value: item })
            );
          }}
          allowColumnEditing={true}
          selectionMode={"multiple"}
          showSelectionCheckBox="always"
          rowAlternationEnabled={false}
          disableGroupPanel
          disablePaging
          disableSearch
        />
        {/* <Mini_datagrid_template
          columns={inventory_checklist}
          data={statesx?.isGridData === true ? inventory_data:inventory_model??inventory_model}
          gridheight={440}
          setState={setState}
          onChangeRecord={onChangeRecord}
          updateMode={allowUpdate}
        /> */}
      </div>
    </div>
  );
};
