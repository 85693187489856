import React, { useState } from "react";
import {
  Radio,
  Button,
  Modal,
  Card,
  Form,
  Select,
  Input,
  Slider,
  RadioChangeEvent,
} from "antd";
import { IoHelpCircle } from "react-icons/io5";
import { CheckBox } from "devextreme-react";
import refresh from "../../../../assets/refresh.png";
import save from "../../../../assets/save2.png";
import { useDispatch, useSelector } from "react-redux";
import { customtoolbar_toggle_task } from "../../../../features/Task&Todo/Task/Checkstate";
import { RootState } from "../../../../app/store";
import axios from "axios";
import { api_url } from "../../component_infos";
import { selectsTypes } from "../../../human-resource/setups/data/types/selectsTypes";
import { formPropsConst } from "../../constants";

interface props {
  isActive?: boolean;
  setIsActive?: any;
  SettingsisActive?: boolean;
  SettingssetIsActive?: any;
  AlertSMSisActive?: boolean;
  AlertSMSsetIsActive?: any;
  AppraiseisActive?: boolean;
  AppraisesetIsActive?: any;
  Message?: any;
  title?: string;
  iscardTitle?: boolean;
  cardName?: string;
  cancelHandler?: () => void;
  open?: boolean;
  footerButton?: boolean;
  footerButtonName?: string;
  footerButtonImg?: string;
  footerButtonClick?: () => void;
}

// switch componenet
export const CustomSwitcher = () => {
  const options: any[] = [
    { id: 0, label: " By me", value: " By me" },
    { id: 1, label: "All tasks", value: "All tasks" },
    { id: 2, label: " To me", value: " to me" },
  ];

  const disptach = useDispatch();
  const [value, setValue] = useState<any>(options[1].label);

  // alert(value)

  const onChange = ({ target: { value } }: RadioChangeEvent) => {
    setValue(value);
    disptach(customtoolbar_toggle_task(value));
  };

  return (
    <div>
      <Radio.Group
        size="small"
        defaultValue={value}
        onChange={onChange}
        options={options}
        optionType="button"
        buttonStyle="solid"
      />
    </div>
  );
};

// delete Modal
export const DeleteTask = ({ isActive, setIsActive }: props) => {
  // retrieving data from redux
  const selectedRecord = useSelector(
    (state: RootState) => state.addTask.selectedRecord
  );

  return (
    <div className="bg-slate-50">
      <Modal
        title="Delete Task"
        open={isActive}
        width="37%"
        centered
        onCancel={() => {
          setIsActive(!isActive);
        }}
        className="bg-slate-50"
        footer={[
          <Button
            className="border-red-400"
            onClick={() => {
              axios
                .put(`${api_url}api/TaskDetail/DeleteTask`, {
                  tskIdpk: selectedRecord[0].tskIDpk,
                  tskActive: selectedRecord[0].tskActive,
                  tskEditedBy: 1,
                  tskEditedDate: selectedRecord[0].tskDueDate,
                })
                .then((response) => {});
              setIsActive(!isActive);
              alert("deleted");
            }}
          >
            Yes
          </Button>,
          <Button
            onClick={() => {
              setIsActive(!isActive);
            }}
          >
            No
          </Button>,
        ]}
      >
        <ul className="flex flex-row justify-around">
          <IoHelpCircle size={60} color={"blue"} />
          <li className=" flex-col justify-center">
            <p className="mb-2">Are you sure you want to Delete this task</p>
            <p>
              Note that the personel who are assigned to this task will be
              notified accordingly
            </p>
          </li>
        </ul>
      </Modal>
    </div>
  );
};

// Task Settings

export const TaskSettings = ({
  SettingsisActive,
  SettingssetIsActive,
}: props) => {
  const { Option } = Select;
  const { TextArea } = Input;

  return (
    <div>
      <Modal
        title="Task Settings"
        open={SettingsisActive}
        onCancel={() => {
          SettingssetIsActive(!SettingsisActive);
        }}
        centered
        footer={[
          <Button
            onClick={() => {
              SettingssetIsActive(!SettingsisActive);
            }}
          >
            <span className="w-6 h-4 justify-center">
              {" "}
              <img alt="" className="w-full h-full mt-1 pr-1" src={save} />{" "}
            </span>
            Update
          </Button>,
        ]}
      >
        <div>
          <li className="ml-12 flex items-center">
            <p className="mr-2">Scoring ? : </p> <CheckBox />
          </li>
          <Form {...formPropsConst} labelCol={{ span: 6 }}>
            <Form.Item
              style={{ marginBottom: "3px" }}
              label={<p className="text-xs">{"Strategic Objective"}</p>}
              className="mb-1 w-full"
            >
              <div className="flex flex-row items-center">
                <Select placeholder="Mr. Dennis" allowClear>
                  <Option value="male">Option here</Option>
                </Select>

                <span className="w-4 ml-1">
                  <img className="w-full h-full" src={refresh} alt="" />
                </span>
              </div>
            </Form.Item>

            <Form.Item
              style={{ marginBottom: "2.5px" }}
              label={<p className="text-xs">{"Initiative"}</p>}
              className="mb-1 w-full"
            >
              <div className="flex flex-row items-center">
                <Select placeholder="Mr. Dennis" allowClear>
                  <Option value="male">Option here</Option>
                </Select>

                <span className="w-4 ml-1">
                  <img className="w-full h-full" src={refresh} alt="" />
                </span>
              </div>
            </Form.Item>

            <Form.Item
              style={{ display: "inline-block", marginBottom: "2px" }}
              label={<p className="text-xs">{"Remarks"}</p>}
              className="w-full mb-1"
            >
              <TextArea
                style={{
                  resize: "none",
                  maxHeight: "79px",
                  minHeight: "79px",
                  height: "79px",
                }}
                rows={3}
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

// Alert By SMS

export const AlertSMS = ({
  Message,
  title,
  iscardTitle = false,
  cardName,
  cancelHandler,
  open,
  footerButton,
  footerButtonName,
  footerButtonImg,
  footerButtonClick,
}: props) => {
  return (
    <div>
      <Modal
        title={title}
        open={open}
        onCancel={cancelHandler}
        centered
        footer={[
          footerButton && (
            <Button onClick={footerButtonClick}>
              <span className="w-6 h-4 justify-center">
                {" "}
                <img
                  alt=""
                  className="w-full h-full mt-1 pr-1"
                  src={footerButtonImg}
                />{" "}
              </span>{" "}
              {footerButtonName}
            </Button>
          ),
        ]}
      >
        <Card title={iscardTitle ? cardName : ""}>{Message}</Card>
      </Modal>
    </div>
  );
};

// Appraise

export const AppraiseSettings = ({
  AppraiseisActive,
  AppraisesetIsActive,
}: props) => {
  const { Option } = Select;
  const { TextArea } = Input;

  const sliders: selectsTypes[] = [
    { id: 1, label: "Effective Communication", optionsData: [] },
    { id: 2, label: "Timely Updates", optionsData: [] },
    { id: 3, label: "Timely Completion of Task", optionsData: [] },
    { id: 4, label: "Quality of Delivery", optionsData: [] },
    { id: 5, label: "Overall Rating", optionsData: [] },
  ];

  return (
    <div>
      <Modal
        title="Task Rating"
        open={AppraiseisActive}
        width={1000}
        centered
        onCancel={() => {
          AppraisesetIsActive(!AppraiseisActive);
        }}
        confirmLoading={true}
        footer={[
          <Button
            style={{ height: "40px" }}
            onClick={() => {
              AppraisesetIsActive(!AppraiseisActive);
            }}
          >
            <span className="w-6 h-4 justify-center">
              {" "}
              <img alt="" className="w-full h-full mt-1 pr-1" src={save} />{" "}
            </span>
            Rate
          </Button>,
        ]}
      >
        <div>
          <Form {...formPropsConst} className="w-full   rounded py-2 ">
            <div>
              {sliders.map((slider) => {
                return (
                  <Form.Item
                    label={
                      slider.label && (
                        <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                          {slider.label}
                        </p>
                      )
                    }
                  >
                    <div className="flex flex-row items-center">
                      <Slider style={{ width: "100%" }} />

                      <span className="w-4 ml-1">
                        <img className="w-full h-full" src={refresh} alt="" />
                      </span>
                    </div>
                  </Form.Item>
                );
              })}
            </div>

            <Form.Item
              style={{ width: "100%", marginBottom: "1px" }}
              className="items-center ml-4 mb-1"
            >
              <div className="flex w-full ">
                <Form.Item
                  style={{ display: "inline-block", paddingRight: "215px" }}
                  label={
                    <p className="text-xs">
                      {"Satisfied with Overall Handling of Task ? "}
                    </p>
                  }
                  className=" mb-1"
                >
                  <div className="flex flex-row items-center ">
                    <li className=" mb-1 flex items-center">
                      {" "}
                      <CheckBox />
                    </li>
                  </div>
                </Form.Item>

                <Form.Item
                  labelCol={{ span: 5 }}
                  style={{ display: "inline-block", width: "calc(50% - 0px)" }}
                  label={<p className="text-xs">{"Status"}</p>}
                  className="mb-1"
                >
                  <div className="flex flex-row items-center w-full  ">
                    <Select className="w-full"></Select>
                    <span className="w-4 ml-1">
                      <img className="w-full h-full" src={refresh} alt="" />
                    </span>
                  </div>
                </Form.Item>
              </div>
            </Form.Item>

            <li
              style={{ marginLeft: "177px", marginBottom: "3px" }}
              className=" mb-1 flex items-center"
            >
              <p className="mr-2 text-xs">Scoring ? : </p> <CheckBox />
            </li>

            <Form.Item
              style={{ marginBottom: "3px" }}
              label={<p className="text-xs">{"Strategic Objective"}</p>}
              className="mb-1 w-full"
            >
              <div className="flex flex-row items-center">
                <Select placeholder="Mr. Dennis" allowClear>
                  <Option value="male">Option here</Option>
                </Select>

                <span className="w-4 ml-1">
                  <img className="w-full h-full" src={refresh} alt="" />
                </span>
              </div>
            </Form.Item>

            <Form.Item
              style={{ marginBottom: "3px" }}
              label={<p className="text-xs">{"Initiative"}</p>}
              className="mb-1 w-full"
            >
              <div className="flex flex-row items-center">
                <Select placeholder="Mr. Dennis" allowClear>
                  <Option value="male">Option here</Option>
                </Select>

                <span className="w-4 ml-1">
                  <img className="w-full h-full" src={refresh} alt="" />
                </span>
              </div>
            </Form.Item>

            <Form.Item
              style={{ display: "inline-block", marginBottom: "2px" }}
              label={<p className="text-xs">{"Remarks"}</p>}
              className="w-full mb-1"
            >
              <TextArea
                style={{
                  resize: "none",
                  maxHeight: "79px",
                  minHeight: "79px",
                  height: "79px",
                }}
                rows={3}
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};
