import { useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import SetupsDatalistTemplat from "../setups_datalist_template";
interface props{
    nameValue ?: string
    span ?: boolean
}
const Hr_Setups_ShortName_Datalist = ({nameValue,span}:props) => {
    const tier = useSelector((state:RootState)=>state.general.tier); // get form tier

    // return some values from specific slices based on ---
    //---nameExpr---
    const CustomDatalistExprs = useSelector((state:RootState)=>state.general.CustomDatalistExprs); //custom datasets exprs
    const nameExprObj = useSelector((state:RootState)=>state.general.inputExprs);
    const nameExpr = nameExprObj.shortnameExpr;
    const idExpr = nameExprObj.idExpr; 

    return (
        <>
            <SetupsDatalistTemplat
            
            span={span===undefined?true:span}
            refresh={false} 
            datalistStyle={{height:24}}
            idExpr={idExpr} 
            nameExpr= {nameExpr}  
            listid="shortname" label = {nameValue}/>
        </>
    );
}
 
export default Hr_Setups_ShortName_Datalist;