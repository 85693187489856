import { Form } from "antd";
import { Dayjs } from "dayjs";
import React, { useCallback, useEffect, useImperativeHandle, useState } from "react";
import { FaBinoculars } from "react-icons/fa";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import { GetDataFunc } from "../../../../../../../functions/get";
import { PatchDataFunc } from "../../../../../../../functions/patch";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { useBusyLoader } from "../../../../../../../hooks/useBusyLoader";
import useFetch from "../../../../../../../hooks/useFetch";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import Datagrid_template from "../../../../../../../templates/Datagrid";
import { InputsTemplate } from "../../../../../../../templates/input";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { SaveButton } from "../../../../../../accessories/buttons";
import {
  postError,
  serverError,
} from "../../../../../../accessories/component_infos";
import {
  modalPropTypes,
  searchTextTypes,
  selectObjectTypes,
} from "../../../../../../human-resource/setups/data/types/selectsTypes";
import { fdaStaffColumns, fdaselectedColumns } from "../data/FDAAssignments";
import { useCrudFunc } from "../../../../../../../functions/crud";
interface Prop {
  updateMode: boolean;
  refresh: () => void;
  selectedRecord: any;
}
interface stateTypes {
  remark: string;
  manager: boolean;
  view: boolean;
  authorize: boolean;
  active: boolean;
  //form state
  disableForm: boolean;
  modalState: modalPropTypes;
  confirmState: modalPropTypes;
  successModal: modalPropTypes;
  fuelDumpData: any;
  selectedRecordData: any;
  staffSearch?: searchTextTypes;
  stationsSearch?: searchTextTypes;
  selectedStaffs: any[];
  stations: any[];
  assignedStations: any[];
}
export const FdaAssignmentsForm = React.forwardRef(
  ({ updateMode, refresh: refreshGrid }: Prop, ref) => {
    const [loaderValue, busyLoader] = useBusyLoader();
    const [employeeID, userID] = useAccountInfo();
    const borderTheme = useSelector(
      (state: RootState) => state.general.cssBorderClass
    );
    const [Posting, Updating] = useCrudFunc();
    const [privType, setPrivType] = useState<string>("save");
    const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);

    const saveAccessPriv = () => {
      setPrivType("Save");

      if (savePriv === true) {
        validate("post");
      } else {
        updateState("modalState", {
          state: true,
          title: "Access Denied!",
          message: "No Privilege Access ",
        });
      }
    };

    const [states, setState] = useState<stateTypes>({
      fuelDumpData: [],
      remark: "",
      manager: false,
      view: false,
      authorize: false,
      disableForm: true,
      active: true,
      staffSearch: { text: "", temp: "" },
      stationsSearch: { text: "", temp: "" },
      selectedRecordData: {},

      modalState: {
        state: false,
        title: "",
        message: "",
      },
      successModal: {
        state: false,
        title: "",
        message: "",
      },
      confirmState: {
        state: false,
        title: "",
        message: "",
      },
      selectedStaffs: [],
      stations: [],
      assignedStations: [],
    });
    const {
      staffSearch,
      selectedStaffs,
      stationsSearch,
      stations,
    } = states;

    // update states
    const updateState = (
      stateName: string,
      value:
        | string
        | boolean
        | number
        | modalPropTypes
        | selectObjectTypes
        | Dayjs
        | Object
        | searchTextTypes
    ) => {
      setState((prevstate) => ({ ...prevstate, [stateName]: value }));
    };

    const disableModal = () => {
      updateState("confirmState", {
        state: false,
        title: "",
        message: "",
        event: () => { },
      });
    };

    useImperativeHandle(
      ref,
      () => {
        return {
          toggle: (selected: any) => {
            ToggleStatus("validate", selected);
          },
          toggleAll: (selected: any) => {
            toggleAllStatus("validate", selected);
          },
          setSelectedRecord: (selected: any) => {
            updateState("selectedRecordData", selected);
          },
        };
      },
      []
    );

    const ToggleStatus = async (
      action: "toggle" | "validate",
      selected: any
    ) => {
      if (action === "validate") {
        if (
          !selected ||
          typeof selected !== "object" ||
          Object.keys(selected).length < 1
        ) {
          updateState("modalState", {
            state: true,
            title: "Select Assignment",
            message: "Please select an assignment to toggle active status",
          });
          return;
        }

        updateState("confirmState", {
          state: true,
          title: "Toggle Status?",
          okText: "Yes, Toggle",
          message: `Are you sure you want to toggle the active status of the selected assignment to ${selected?.faaActive !== undefined ||
            selected?.faaActive !== null ||
            selected?.faaActive !== ""
            ? !selected?.faaActive
            : true
            }?`,
          func() {
            ToggleStatus("toggle", selected);
          },
          width: 375,
        });
        return;
      }

      try {
        disableModal();

        busyLoader("Toggling Active Status...");

        await PatchDataFunc(
          `AsmTmsFuelDumpAttendentAssignment/ActivateSingle`,
          {
            faaIdpk: selected?.faaIDpk,
            faaActive:
              selected?.faaActive !== undefined ||
                selected?.faaActive !== null ||
                selected?.faaActive !== ""
                ? !selected?.faaActive
                : true,
            faaEditedBy: userID,
          }
        );

        busyLoader("");
        refreshGrid();
        updateState("successModal", {
          state: true,
          title: "Toggle Successful",
          message: "Active status of selected assignment toggled successfully",
        });
      } catch (error) {
        busyLoader("");
        updateState("modalState", {
          state: true,
          title: "Save Failed",
          message:
            "Failed to toggle active status. Please retry or contact your Systems Administrator",
        });
      }
    };

    const toggleAllStatus = async (
      action: "validate" | "toggleAll",
      selected: any
    ) => {
      if (action === "validate") {
        if (
          !selected ||
          typeof selected !== "object" ||
          Object.keys(selected).length < 1
        ) {
          updateState("modalState", {
            state: true,
            title: "Select Assignment",
            message: "Please select an assignment to toggle all active status",
          });
          return;
        }

        updateState("confirmState", {
          state: true,
          title: "Toggle Status?",
          okText: "Yes, Toggle",
          message: `Are you sure you want to toggle the active status of all assignments for ${selected?.empName ?? "the selected record"
            } to ${selected?.faaActive !== undefined ||
              selected?.faaActive !== null ||
              selected?.faaActive !== ""
              ? !selected?.faaActive
              : true
            }?`,
          func() {
            toggleAllStatus("toggleAll", selected);
          },
          width: 375,
        });
        return;
      }

      //toggle all
      try {
        disableModal();
        busyLoader("Toggling Active Status...");

        // loop through and update statuses
        await Updating(
          `AsmTmsFuelDumpAttendentAssignment/ActivateAll`,
          {
            faaEmpIDfk: selected.empIDpk,
            faaActive:
              selected?.faaActive !== undefined ||
                selected?.faaActive !== null ||
                selected?.faaActive !== ""
                ? !selected?.faaActive
                : true,
            faaEditedBy: userID,
          },
          `Updated the Fuel Dump Attendant Log with EmpID: ${selected.empIDpk} Active: ${selected?.faaActive !== undefined ||
            selected?.faaActive !== null ||
            selected?.faaActive !== ""
            ? !selected?.faaActive
            : true
          }`
        );

        //success
        refreshGrid();
        updateState("successModal", {
          state: true,
          title: "Toggle Successful",
          message:
            "Active status of all assignments for the selected record toggled successfully",
        });
      } catch (error) {
        updateState("modalState", {
          state: true,
          title: "Save Failed",
          message:
            "Failed to toggle active status. Please retry or contact your Systems Administrator",
        });
      } finally {
        busyLoader("");
      }
    };

    useEffect(() => {
      updateState("disabledMode", !updateMode);
    }, [updateMode]);

    const [addedSelected, setAddSelected] = useState<any>({});

    const appendColumns = (data: any) => {
      const temp: any = data;
      for (let j = 0; j < temp.length; j++) {
        temp[j] = {
          ...temp[j],
          faaManage: false,
          faaView: false,
          faaAuthorise: false,
          faaActive: false,
          faaRmks: "",
        };
      }
      return temp;
    };

    const [Empdata, EmpError, EmpLoading] = useFetch(
      `AsmTmsFuelDumpAttendentAssignment/GetStaffDetails?search=${staffSearch?.text}`
    );

    useEffect(() => {
      const getStations = async () => {
        try {
          if (selectedStaffs.length < 1) {
            updateState("stations", []);
            return;
          }

          const empId = selectedStaffs[selectedStaffs.length - 1]?.empIDpk;
          const assignedResponse = await GetDataFunc(
            `AsmTmsFuelDumpAttendentAssignment/GetAssinedFuelDetails?empId=${empId}&search=${stationsSearch?.text}`
          );
          updateState("assignedStations", assignedResponse.data);

          const unassignedResponse = await GetDataFunc(
            `AsmTmsFuelDumpAttendentAssignment/GetUnassinedFuelDetails?empId=${empId}&search=${stationsSearch?.text}`
          );

          updateState("stations", [
            ...assignedResponse.data.map((obj: any) => ({
              ...obj,
              id: generateRandomId(),
            })),
            ...appendColumns(
              unassignedResponse.data.map((obj: any) => ({
                ...obj,
                id: generateRandomId(),
              }))
            ),
          ]);
        } catch (error) {
          updateState("modalState", {
            state: true,
            title: serverError.title,
            message: serverError.message,
          });
        }
      };
      selectedStaffs.length > 0 && getStations();
    }, [selectedStaffs, stationsSearch?.text]);

    const inplementRowColouring = useCallback((row: any) => {
      if (row.data?.faaAuthorise || row.data?.faaManage || row.data?.faaView) {
        row.rowElement.style.backgroundColor =
          "rgb(134 239 172 / var(--tw-bg-opacity))";
      } else {
        row.rowElement.style.backgroundColor = "rgb(240 181 5 / var(--tw-bg-opacity))";
      }
    }, []);

    const colorCell = (e: any) => {
      var res = states.assignedStations.find((reqArea: any) => reqArea?.sstIDpk === e.data?.sstIDpk);

      if (e.columnIndex === 1 && res === undefined && e.rowIndex > -1) {
        return (e.cellElement.style.backgroundColor =
          "rgb(156,154,154");
      }
    };

    const validate = (method: string) => {
      if (Object.keys(selectedStaffs).length < 1) {
        updateState("modalState", {
          state: true,
          title: "Select Attendant",
          message: "Please select at least one(1) attendant",
        });
        return;
      }

      updateState("confirmState", {
        state: true,
        title: "Save Record?",
        message: "Are you sure you want to save this record?",
        func() {
          PostData();
        },
      });
    };

    function generateRandomId() {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let randomText = "";

      for (let i = 0; i < 4; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomText += characters[randomIndex];
      }

      return randomText;
    }

    const PostData = async () => {
      busyLoader("Saving record(s)...");
      disableModal();

      try {
        for (let staff of selectedStaffs) {
          const assignmentsData = stations;
          const assignedResponse = await GetDataFunc(
            `AsmTmsFuelDumpAttendentAssignment/GetAssinedFuelDetails?empId=${staff?.empIDpk}`
          );

          for (let index = 0; index < assignmentsData.length; index++) {
            const exists = assignedResponse?.data?.find(
              (station: any) =>
                station?.sstIDpk === assignmentsData[index]?.sstIDpk
            );

            if (exists) {
              if (
                assignmentsData[index]?.faaManage ||
                assignmentsData[index]?.faaView ||
                assignmentsData[index]?.faaAuthorise
              ) {
                await Updating(
                  `AsmTmsFuelDumpAttendentAssignment/Update`,
                  {
                    faaIdpk: exists.faaIDpk,
                    faaEmpIdfk: staff?.empIDpk,
                    faaServiceStationIdfk: assignmentsData[index]?.sstIDpk,
                    faaManage: assignmentsData[index]?.faaManage,
                    faaView: assignmentsData[index]?.faaView,
                    faaAuthorise: assignmentsData[index]?.faaAuthorise,
                    faaActive: true,
                    faaRmks: assignmentsData[index]?.faaRmks,
                    faaEditedBy: userID,
                  },
                  `Updated the Fuel Dump Attendant Log with ID: ${exists.faaIDpk}, EmpID: ${staff?.empIDpk}, Service Station ID: ${assignmentsData[index]?.sstIDpk} Manager: ${assignmentsData[index]?.faaManage}, View: ${assignmentsData[index]?.faaView}, Authorizer: ${assignmentsData[index]?.faaAuthorise}, remarks: ${assignmentsData[index]?.faaRmks}}`
                );
              } else {
                await Updating(
                  `AsmTmsFuelDumpAttendentAssignment/Update`,
                  {
                    faaIdpk: exists.faaIDpk,
                    faaEmpIdfk: staff?.empIDpk,
                    faaServiceStationIdfk: assignmentsData[index]?.sstIDpk,
                    faaManage: assignmentsData[index]?.faaManage,
                    faaView: assignmentsData[index]?.faaView,
                    faaAuthorise: assignmentsData[index]?.faaAuthorise,
                    faaActive: false,
                    faaRmks: assignmentsData[index]?.faaRmks,
                    faaEditedBy: userID,
                  },
                  `Updated the Fuel Dump Attendant Log with ID: ${exists.faaIDpk}, EmpID: ${staff?.empIDpk}, Service Station ID: ${assignmentsData[index]?.sstIDpk} Manager: ${assignmentsData[index]?.faaManage}, View: ${assignmentsData[index]?.faaView}, Authorizer: ${assignmentsData[index]?.faaAuthorise}, remarks: ${assignmentsData[index]?.faaRmks}}`
                );
              }
            } else {
              if (
                assignmentsData[index]?.faaManage ||
                assignmentsData[index]?.faaView ||
                assignmentsData[index]?.faaAuthorise
              ) {
                await Posting(`AsmTmsFuelDumpAttendentAssignment/Create`, {
                  faaEmpIdfk: staff?.empIDpk,
                  faaServiceStationIdfk: assignmentsData[index]?.sstIDpk,
                  faaManage: assignmentsData[index]?.faaManage,
                  faaView: assignmentsData[index]?.faaView,
                  faaAuthorise: assignmentsData[index]?.faaAuthorise,
                  faaActive: true,
                  faaRmks: assignmentsData[index]?.faaRmks,
                  faaCreatedBy: userID,
                },
                  `Created the Fuel Dump Attendant Log with EmpID: ${staff?.empIDpk}, Service Station ID: ${assignmentsData[index]?.sstIDpk} Manager: ${assignmentsData[index]?.faaManage}, View: ${assignmentsData[index]?.faaView}, Authorizer: ${assignmentsData[index]?.faaAuthorise}, remarks: ${assignmentsData[index]?.faaRmks}}`
                );
              }
            }
          }
        }

        refreshGrid();

        updateState("successModal", {
          state: true,
          title: "Save Successful",
          message: "Record saved successfully",
        });
      } catch (error) {
        updateState("modalState", {
          state: true,
          title: "Save Failed",
          message: postError.message,
        });
      } finally {
        busyLoader("");
      }
    };
    return (
      <>
        <div className="px-2">
          <ModalTemplate
            disableCancel={true}
            title={states.modalState.title}
            okText={""}
            message={states.modalState.message}
            open={states.modalState.state}
            okHandler={() => {
              updateState("modalState", { state: false });
            }}
            cancelHandler={() => {
              updateState("modalState", { state: false });
            }}
          />

          <ModalTemplate
            icon="success"
            disableCancel={true}
            title={states.successModal.title}
            okText={""}
            message={states.successModal.message}
            open={states.successModal.state}
            okHandler={() => {
              updateState("successModal", { state: false });
            }}
            cancelHandler={() => {
              updateState("successModal", { state: false });
            }}
          />
          <ModalTemplate
            icon="question"
            width={states.successModal?.width}
            title={states.confirmState.title}
            okText={states.confirmState.okText ?? "Yes, Save"}
            message={states.confirmState.message}
            open={states.confirmState.state}
            okHandler={states.confirmState.func!}
            cancelHandler={() => {
              updateState("confirmState", { state: false });
            }}
          />
          <p
            id={"form_header"}
            style={{ borderColor: borderTheme }}
            className="py-1  w-full border-[1px] px-1 rounded bg-slate-100"
          >
            <span className="font-medium">{"Fuel Dump Attendants Assignment Details"}</span>
          </p>

          <main>
            <div
              style={{ borderColor: borderTheme }}
              className="flex justify-between border-[1px] px-1 rounded border-t-0 border-slate-100"
            >
              <section className=" w-2/5 px-2 py-1 bg">
                <Form.Item
                  className=""
                  name="Staff No"
                  rules={[{ required: false }]}
                >
                  <div className="">
                    <div
                      id="MYINPUT"
                      className="flex flex-row justify-end  py-0 items-center"
                    >
                      <InputsTemplate
                        useCallbackFunc
                        orderOnchange={(value) =>
                          updateState("staffSearch", {
                            ...staffSearch,
                            temp: value,
                          })
                        }
                        inputStyle={{ height: 23 }}
                        placeholder={"Search"}
                        disabledStatus={false}
                        span
                      />

                      <span
                        onClick={() => {
                          updateState("staffSearch", {
                            temp: staffSearch?.temp,
                            text: staffSearch?.temp,
                          });
                        }}
                        style={{ borderColor: borderTheme }}
                        className=" h-full  flex items-center  justify-center px-1 py-0.5 hover:cursor-pointer hover:bg-slate-50 rounded border-2"
                      >
                        <FaBinoculars color="#007AFF" />
                      </span>
                    </div>

                    <Datagrid_template
                      selectionMode="multiple"
                      deselectFirstRow
                      disableGroupPanel
                      disableSearch
                      disablePaging
                      data={Empdata}
                      gridheight={275}
                      columns={fdaStaffColumns}
                      onRowClick={(selected) => {
                        setAddSelected(selected);
                      }}
                      selectedItemsChange={(selectedArr) => {
                        setAddSelected(selectedArr);
                        updateState("selectedStaffs", selectedArr);
                      }}
                      showSelectionCheckBox="always"
                    />
                  </div>
                </Form.Item>
              </section>

              <section className=" px-2 w-3/5 py-1">
                <Form.Item
                  className=""
                  name="Staff No"
                  rules={[{ required: false }]}
                >
                  <>
                    <div className="">
                      <div
                        id="MYINPUT"
                        className="flex flex-row justify-end  py-0 items-center"
                      >
                        <InputsTemplate
                          useCallbackFunc
                          orderOnchange={(value) =>
                            updateState("stationsSearch", {
                              text: stationsSearch?.text,
                              temp: value,
                            })
                          }
                          inputStyle={{ height: 23 }}
                          placeholder={"Search"}
                          disabledStatus={false}
                          span
                        />
                        <span
                          onClick={() => {
                            updateState("stationsSearch", {
                              temp: stationsSearch?.temp,
                              text: stationsSearch?.temp,
                            });
                          }}
                          style={{ borderColor: borderTheme }}
                          className=" h-full flex items-center  justify-center px-1 py-0.5 hover:cursor-pointer hover:bg-slate-50 rounded border-2"
                        >
                          <FaBinoculars color="#007AFF" />
                        </span>
                      </div>

                      <Datagrid_template
                        onRowPrepared={inplementRowColouring}
                        cellColoringFxn={colorCell}
                        rowAlternationEnabled={false}
                        selectionMode="single"
                        deselectFirstRow
                        allowColumnEditing
                        disableGroupPanel
                        selectedItemsChange={(selectedArr) => {
                          updateState("fuelDumpData", selectedArr);
                        }}
                        disableSearch
                        disablePaging
                        data={stations}
                        gridheight={275}
                        columns={fdaselectedColumns}
                      />
                    </div>

                    <div className=" mt-1 flex justify-end">
                      <SaveButton
                        disableButton={false}
                        handleSave={() => {
                          saveAccessPriv();
                        }}
                      />
                    </div>
                  </>
                </Form.Item>
              </section>
            </div>
          </main>
        </div>
      </>
    );
  }
);

export default FdaAssignmentsForm;
