export const vehicle_images_TH = [
  {
    id: 0,
    caption: "VEH. REG No",
    dataField: "vdtRegNo",
    alignment: "left",
    fixed: true,
    visible:true,
    width: 120,
  },
  {
    id: 1,
    caption: "CLASS",
    dataField: "vhcName",
    alignment: "left",
    fixed: true,
    visible:true,
    width: 120
  },
 
  {
    id: 2,
    caption: "MAKE",
    dataField: "vmkName",
    alignment: "left",
    fixed: false,
    visible:true,
    width: 120
  },
  {
    id: 3,
    caption: "MODEL",
    dataField: "mdlName",
    alignment: "left",
    fixed: true,
    visible:true,
    width: 120


  },
  {
    id: 4,
    caption: "CHASSIS No",
    dataField: "vdtChassisNo",
    alignment: "left",
    fixed: false,
    visible:true,
    width: 160

  },
  {
    id: 5,
    caption: "MANUFACTURER",
    dataField: "mftName",
    alignment: "left",
    fixed: false,
    visible:true,
    width: 120

  },
  {
    id: 6,
    caption: "COLOR",
    dataType:'string',
    dataField: "vdtColour",
    alignment: "left",
    fixed: false,
    width: 120,
  },
  {
    id: 7,
    caption: "MANUF.DATE",
    dataField: "vdtManufactureDate",
    dataType:'date',
    format: 'EEE,dd MMM yyyy',
    alignment: "left",
    fixed: false,
    visible:true,
    width: 120

  },
  
  
  {
    id: 8,
    caption: "SUPPLY DATE",
    dataField: "vdtSupplyDate",
    alignment: "left",
    dataType:'date',
    format: 'EEE,dd MMM yyyy',
    fixed: false,
    visible:true,
    width: 120

  },
  
  {
    id: 9,
    caption: "SUPPLIER",
    dataField: "splName",
    alignment: "left",
    fixed: false,
    visible:true,
    width: 160

  },
  {
    id: 10,
    caption: "PRICE",
    dataField: "vdtPrice",
    alignment: "left",
    fixed: false,
    visible:true,
    width: 120
  },
  {
    id: 11,
    caption: "OTHER EXPENSES",
    dataField: "OTHER Expenses",
    alignment: "left",
    fixed: false,
    visible:true,
    width: 160
  },
  {
    id: 12,
    caption: "STATUS",
    dataField: "vstName",
    alignment: "left",
    fixed: false,
    visible:true,
    width: 60
  },
  {
    id: 13,
    caption: "ENGINE TYPE",
    dataField: "egtName",
    alignment: "left",
    fixed: false,
    visible:true,
    width: 120
  },
  {
    id: 14,
    caption: "ASSIGNED?",
    dataField: "assigned",
    alignment: "left",
    fixed: false,
    visible:true,
    width: 120
  },
  {
    id: 15,
    caption: "ACTIVE?",
    dataField: "active",
    alignment: "left",
    fixed: false,
    visible:true,
    width: 120
  },
  {id: 16,
    caption: "REMARKS",
    dataField: "vdtRmks",
    alignment: "left",
    fixed: false,
    visible:true,
    width: 4000
  },
  {id: 17,
    caption: "ID",
    dataField: "vdtIdpk",
    alignment: "left",
    fixed: false,
    width: null,
    visible:false
  }
]
export const vehicle_images_data = [

  {
    id: 0,
    caption: "ID",
    dataField: "vidIdpk",
    alignment: "left",
    fixed: true,
    visible:false,
    width: 120

  },
  {
    id: 1,
    caption: "NAME",
    dataField: "vidName",
    alignment: "left",
    fixed: false,
    visible:true,
    width: 120

  },
  {
    id: 2,
    caption: "DESCRIPTION",
    dataField: "vidDescription",
    alignment: "left",
    fixed: false,
    visible:true,
    width: 120

  },
  {
    id: 3,
    caption: "ORDER",
    dataField: "vidIndex",
    alignment: "left",
    fixed: false,
    visible:true,
    

  }, {
    id: 4,
    caption: "REMARKS",
    dataField: "vidRmks",
    alignment: "left",
    fixed: false,
    visible:true,
    width: 4000

  },
  {
    id: 5,
    caption: "VehIdfk",
    dataField: "vidVehicleDetailIdfk",
    alignment: "left",
    fixed: false,
    visible:false,
    width: 40
  }
  
]