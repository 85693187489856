import { datagridColumnTypes } from "../../../../../../human-resource/setups/data/types/selectsTypes";

export const approversRequestColumns: datagridColumnTypes[] = [
  {
    id: 0,
    caption: "STAFF No",
    dataField: "empStaffNo",
    dataType: "string",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 1,
    caption: "APPROVER",
    dataField: "empName",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "REQUEST AREAS",
    dataField: "vraName",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 3,
    caption: "APPROVER LOC",
    dataField: "locShtName",
    dataType: "string",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 4,
    caption: "DEPT",
    dataField: "dptShtName",
    dataType: "string",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 5,
    caption: "SECTION",
    dataField: "sxnShtName",
    dataType: "string",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 6,
    caption: " ACTIVE? ",
    dataField: "vaaActive",
    dataType: "boolean",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 7,
    caption: "RECOMMEND? ",
    dataField: "vaaRecommend",
    dataType: "boolean",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 8,
    caption: "APPROVE? ",
    dataField: "vaaApprove",
    dataType: "boolean",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 10,
    caption: "RECOMM. PRs ",
    dataField: "vaaRecommendPersonalRequests",
    dataType: "boolean",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 11,
    caption: "APPROVE PRs ",
    dataField: "vaaApprovePersonalRequests",
    dataType: "boolean",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 12,
    caption: "ASSIGN VEH ",
    dataField: "vaaAssign",
    dataType: "boolean",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 13,
    caption: "ALERT? ",
    dataField: "vaaAlert",
    dataType: "boolean",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 14,
    caption: "DRIVER? ",
    dataField: "vaaDriver",
    dataType: "boolean",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 15,
    caption: "MANAGE KEYS? ",
    dataField: "vaaManageKeys",
    dataType: "boolean",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 16,
    caption: "SEE RATING? ",
    dataField: "vaaSeeRating",
    dataType: "boolean",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 17,
    caption: "SADCC? ",
    dataField: "vaaSeeAllDeptsCostCenters",
    dataType: "boolean",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 18,
    caption: "SOSCC? ",
    dataField: "vaaSeeOnlySectionsCostCenters",
    dataType: "boolean",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 19,
    caption: "SACC? DATE ",
    dataField: "vaaSeeAllCostCenters",
    dataType: "string",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 20,
    caption: "ASSIGNED? LAST ",
    dataField: "vaaCreationDate",
    dataType: "datetime",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 21,
    caption: "EDITED ON?",
    dataField: "vaaEditedDate",
    dataType: "datetime",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 22,
    caption: "REMARKS?",
    dataField: "vaaRmks",
    dataType: "string",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 23,
    caption: "SHIFT LDR?",
    dataField: "",
    dataType: "string",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 24,
    caption: "DEPT?",
    dataField: "",
    dataType: "string",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 25,
    caption: "SECTION?",
    dataField: "",
    dataType: "string",
    width: 5000,
    fixed: false,
    visible: true,
  },
];

// datagrid1
export const approvers1Columns: datagridColumnTypes[] = [
  {
    id: 0,
    caption: "Staff No",
    dataField: "empStaffNo",
    dataType: "",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 1,
    caption: "Staff Name",
    dataField: "empName",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "Dept",
    dataField: "dptShtName",
    dataType: "string",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 3,
    caption: "Section",
    dataField: "sxnShtName",
    dataType: "string",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 4,
    caption: "Location",
    dataField: "locShtName",
    dataType: "string",
    width: 130,
    fixed: true,
    visible: true,
  },
];

// datagrid2
export const approvers2Columns: datagridColumnTypes[] = [
  {
    id: 0,
    caption: "No",
    dataField: "vraIdpk",
    dataType: "",
    width: 60,
    fixed: true,
    visible: true,
  },
  {
    id: 1,
    caption: "Request Area",
    dataField: "vraName",
    dataType: "string",
    width: 160,
    fixed: true,
    allowEditing: false,
    visible: true,
  },
  {
    id: 2,
    caption: "Recom. Req?",
    dataField: "vaaRecommendPersonalRequests",
    dataType: "boolean",
    width: 160,
    fixed: false,
    visible: true,
  },
  {
    id: 3,
    caption: "Approve Req?",
    dataField: "vaaApprovePersonalRequests",
    dataType: "boolean",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 4,
    caption: "Recomm. PR?",
    dataField: "vaaRecommend",
    dataType: "boolean",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 5,
    caption: "Approve PR?",
    dataField: "vaaApprove",
    dataType: "boolean",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 6,
    caption: "Assign Veh.?",
    dataField: "vaaAssign",
    dataType: "boolean",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 7,
    caption: "Driver?",
    dataField: "vaaDriver",
    dataType: "boolean",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 8,
    caption: "Alert?",
    dataField: "vaaAlert",
    dataType: "boolean",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 10,
    caption: "Mng Key?",
    dataField: "vaaManageKeys",
    dataType: "boolean",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 11,
    caption: "See Rating?",
    dataField: "vaaSeeRating",
    dataType: "boolean",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 12,
    caption: "SADCC?",
    dataField: "vaaSeeAllDeptsCostCenters",
    dataType: "boolean",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 13,
    caption: "SOSCC?",
    dataField: "vaaSeeOnlySectionsCostCenters",
    dataType: "boolean",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 14,
    caption: "SACC?",
    dataField: "vaaSeeAllCostCenters",
    dataType: "boolean",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 15,
    caption: "Remarks",
    dataField: "vaaRmks",
    dataType: "string",
    width: 5000,
    fixed: false,
    visible: true,
  },
];
