export const left_alert_drivers_cols = [
  {
    id: 0,
    caption: "ID",
    dataField: "tskIDpk",
    dataType: "string",
    alignment: "left",
    width: 45,
    fixed: true,
  },
  {
    id: 1,
    caption: "Staff?",
    dataField: "tskStartDate",
    dataType: "boolean",
    alignment: "left",
    fixed: true,
    width: 125,
  },
  {
    id: 2,
    caption: "No",
    dataField: "tskDueDate",
    dataType: "string",
    alignment: "left",
    fixed: true,
    width: 125,
  },
  {
    id: 3,
    caption: "SP",
    dataField: "tskTask",
    dataType: "string",
    alignment: "left",
    fixed: true,
    width: 300,
  },
  {
    id: 4,
    caption: "Returns",
    dataField: "tskPercentComplete",
    dataType: "number",

    alignment: "left",
    fixed: true,
    width: 110,
  },
  {
    id: 5,
    caption: "Mob No",
    dataField: "ratingPercent",
    dataType: "string",
    alignment: "center",
    fixed: true,
    width: 80,
  },
  {
    id: 6,
    caption: "INTERCOMP",
    dataField: "",
    dataType: "boolean",
    alignment: "center",
    width: 80,
    fixed: true,
  },
  {
    id: 7,
    caption: "Job Title",
    dataField: "",
    dataType: "boolean",
    alignment: "center",
    width: 80,
    fixed: true,
  },
  {
    id: 8,
    caption: "Dept",
    dataField: "",

    alignment: "left",
    width: 125,
  },
  {
    id: 9,
    caption: "Section",
    dataField: "",
    dataType: "string",
    alignment: "left",
    width: 80,
  },
  {
    id: 10,
    caption: "PROGRESS ACTIVITY",
    dataField: "",
    dataType: "string",
    alignment: "left",
    width: 300,
  },
  {
    id: 11,
    caption: "ASSIGNED BY",
    dataField: "",
    dataType: "string",
    alignment: "left",
    width: 200,
  },
  {
    id: 12,
    caption: "ASSIGNED TO",
    dataField: "",
    dataType: "string",
    alignment: "left",
    width: 4000,
  },
];

export const right_alert_drivers_col = [
  {
    id: 0,
    caption: "No",
    dataField: "",
    dataType: "string",
    alignment: "left",
    width: 45,
    fixed: true,
  },
  {
    id: 1,
    caption: "Name",
    dataField: "",
    dataType: "boolean",
    alignment: "left",
    fixed: true,
    width: 125,
  },
  {
    id: 2,
    caption: "Mob No",
    dataField: "",
    dataType: "string",
    alignment: "left",
    fixed: true,
    width: 125,
  },
  {
    id: 3,
    caption: "INTERCOM",
    dataField: "",
    dataType: "string",
    alignment: "left",
    fixed: true,
    width: 300,
  },
  {
    id: 4,
    caption: "Job Title",
    dataField: "",
    dataType: "number",

    alignment: "left",
    fixed: true,
    width: 110,
  },
  {
    id: 5,
    caption: "Dept",
    dataField: "",
    dataType: "string",
    alignment: "center",
    fixed: true,
    width: 80,
  },
  {
    id: 6,
    caption: "Section",
    dataField: "",
    dataType: "boolean",
    alignment: "center",
    width: 80,
    fixed: true,
  },
  {
    id: 7,
    caption: "Location",
    dataField: "",
    dataType: "boolean",
    alignment: "center",
    width: 80,
    fixed: true,
  },
];
