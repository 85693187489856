import { Modal } from "antd";
import { Dayjs } from "dayjs";
import close from "../../../assets/closeGreyed.png";
import fileX from "../../../assets/fileX.png";
import { useRef, useState } from "react";
import { InputsTemplate } from "../../../templates/input";
import {
  modalPropTypes,
  selectObjectTypes,
} from "../../human-resource/setups/data/types/selectsTypes";
import {
  BrowseButton,
  Cancel,
  FindButton,
  SearchButton,
} from "../../accessories/buttons";
import { Tooltip } from "devextreme-react";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import useFetch from "../../../hooks/useFetch";
import { ModalTemplate } from "../../../templates/modal";
import DataGrid, {
  Column,
  Pager,
  Paging,
  Scrolling,
  Selection,
} from "devextreme-react/data-grid";
import { setGeneralValue } from "../../../features/generalSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { UpdateDataFunc, UpdateDataFuncParams } from "../../../functions/update";
import { Alerts_cols } from "../../Sidenav/widgets/Alerts/data/data";
import Datagrid_template from "../../../templates/Datagrid"

interface stateTypes {
  modalState: boolean;
}
interface props {
  open: boolean;
  cancelHandler: () => void;
}
export const NewsAlert = ({ open, cancelHandler }: props) => {
  const dispatch = useDispatch();
  const refreshRx = useSelector((state: RootState) => state.general.refresh);
  //component states
  const [states, setState] = useState<stateTypes>({
    modalState: true,
  });
  const { modalState } = states;

  //handle state update
  const updateState = (
    stateName: string,
    value:
      | string
      | boolean
      | number
      | modalPropTypes
      | selectObjectTypes
      | Dayjs
      | Object
  ) => {
    setState((currentState: any) => ({ ...currentState, [stateName]: value }));
  };
  const draggleRef = useRef<HTMLDivElement>(null);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [okText, setOkText] = useState<string>("Ok");
  const [message, setMessage] = useState<string>(
    "Are you sure want to dismiss alerts"
  );
  const [title, setTitle] = useState<string>("Confirm Action");
  const [icon, setIcon] = useState<any>("question");
  const [disableCancel, setDisableCancel] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [refresh, setRefresh] = useState(false);
  const [searchText, setSearchText] = useState<string | number>("");
  const [mainUrl, setMainUrl] = useState(
    `USCXGenAlerts/GetAllUSCXGenAlert?searchText=${searchText}`
  );
  const [gridData, gridErr, gridLoading] = useFetch(mainUrl, refreshRx, "");

  const dismissSelected = async () => {
    try {
      if (Object.keys(selectedItem)?.length > 0) {
        const res = await UpdateDataFuncParams(
          `USCXGenAlerts/ClearUSCXGenAlert?alertIDpk=${selectedItem?.altIDpk}`
        );
        if (res.status === 200) {
          setIcon("success");
          setTitle("Success");
          setMessage("Alert has been successfully dismissed");
          setOkText("Ok");
          setDisableCancel(true);
          setOpenModal(true);
          setRefresh(!refresh);
          dispatch(setGeneralValue({ expr: "refresh", value: !refresh }));
        } else {
          throw Error("Something went wrong. Please try again later.");
        }
      } else {
        throw new Error("Please select a record to dismiss");
      }
    } catch (e: any) {
      setIcon("warning");
      setTitle("Unexpected Error!");
      setOkText("Ok");
      setMessage(e.message);
      setDisableCancel(true);
      setOpenModal(true);
    }
  };

  const dismissAll = async () => {
    try {
      const res = await UpdateDataFuncParams(`USCXGenAlerts/ClearAllUSCXGenAlert`);
      if (res.status === 200) {
        setIcon("success");
        setTitle("Success");
        setMessage("All alerts has been successfully dismissed");
        setOkText("Ok");
        setDisableCancel(true);
        setOpenModal(true);
      }
    } catch (e: any) {
      setIcon("warning");
      setTitle("Unexpected Error!");
      setOkText("Ok");
      setMessage(e.message);
      setDisableCancel(true);
      setOpenModal(true);
    }
  };

  const refreshData = () => {
    setMainUrl(
      `USCXGenAlerts/GetAllUSCXGenAlert?` +
        (searchText !== "" ? `searchText=${searchText}` : "")
    );
  };

  const onRowSelected = (selected:any)=>{
    setSelectedItem(selected.data);
  }


  return (
    <>
      <div className="modal" style={{ height: "100%" }}>
        <ModalTemplate
          icon={icon}
          disableCancel={disableCancel}
          cancelText={"No"}
          open={openModal}
          okHandler={() => {
            if (title === "Dismiss All") {
              dismissAll();
            } else if (title === "Dismiss Selected") {
              dismissSelected();
            } else {
              setOpenModal(false);
            }
          }}
          cancelHandler={() => setOpenModal(false)}
          message={message}
          okText={okText}
          title={title}
        />
        <Modal
          style={{ height: "90%" }}
          width={550}
          title="Alerts"
          open={open}
          onCancel={cancelHandler}
          centered
          footer={null}
          transitionName=""
          modalRender={(modal:any) => (
            <Draggable
              //   disabled={disabled}
              bounds={bounds}
              onStart={(event:any, uiData:any) => onStart(event, uiData)}
            >
              <div ref={draggleRef}>{modal}</div>
            </Draggable>
          )}
        >
          {/* datagrid section */}
          <section className="w-full ">
          <Datagrid_template 
          gridheight={500} 
          
          columns={Alerts_cols} 
          data={gridData}
          wordWrap={true}
          onRowClick={onRowSelected}
          disableGroupPanel
          disablePaging
          disableSearch
          showColumnHeaders={false}

        />

            {/* <DataGrid
              rowAlternationEnabled={false}
              className=" alertsComponent"
              id="gridContainer"
              dataSource={gridData}
              height={500}
              onRowClick={(selected: any) => {
                setSelectedItem(selected.data);
              }}
              hoverStateEnabled={true}
              activeStateEnabled={true}
              showColumnHeaders={false}
              wordWrapEnabled={true}
              allowColumnResizing={true}
              showBorders={true}
            >
              <Selection mode="multiple" showCheckBoxesMode={false} />
              <Scrolling mode="infinite" />
              <Pager visible={false} />
              <Paging defaultPageSize={0} />
              <Column
                dataField="algAlert"
                caption={"Alert"}
                alignment="left"
                width={335}
              />
              <Column
                dataField="altIdpk"
                caption={"Alert"}
                alignment="left"
                visible={false}
              />
              <Scrolling mode="infinite" />
              <Pager visible={false} />
              <Paging defaultPageSize={0} />
            </DataGrid> */}
          </section>

          {/* others */}
          <section className=" mt-3 flex justify-evenly ">
            <div className="w-1/3 flex justify-start space-x-1">
              <li>
                <InputsTemplate
                  span
                  useCallbackFunc
                  disabledStatus={false}
                  style={{ width: 80 }}
                  orderOnchange={(e: any) => {
                    setSearchText(e);
                  }}
                />
              </li>

              <li className="mt-0.5">
                <SearchButton
                  isText={false}
                  isTooltip={true}
                  tooltipMsg={"Search for undismissed alerts"}
                />
              </li>
              <li>
                <FindButton
                  isText={false}
                  isTooltip={true}
                  tooltipMsg={
                    "Search all alerts both dismissed and undismissed"
                  }
                  handleOnClick={() => {
                    refreshData();
                  }}
                />
              </li>
            </div>

            <div className="w-1/3 flex  justify-center">
              <li>
                <BrowseButton
                  isText={false}
                  isTooltip={true}
                  tooltipMsg={"Open Form"}
                />
              </li>
            </div>

            <div className=" w-1/3 flex space-x-1 justify-end ">
              <li
                onClick={() => {
                  setIcon("question");
                  setTitle("Dismiss Selected");
                  setMessage(
                    "Are you sure you want to dismiss the selected alert?"
                  );
                  setOkText("Yes");
                  setDisableCancel(false);
                  setOpenModal(true);
                }}
                id="newRecord11"
                style={{ borderWidth: "2px" }}
                className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2    text-gray-600 rounded flex items-center"
              >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                  <img className="pr-1 " src={close} />
                </span>
                <Tooltip
                  target="#newRecord11"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">Dismiss selected</p>
                </Tooltip>
              </li>
              <li
                onClick={() => {
                  setIcon("question");
                  setTitle("Dismiss All");
                  setMessage("Are you sure you want to dismiss all alerts?");
                  setOkText("Yes");
                  setDisableCancel(false);
                  setOpenModal(true);
                }}
                id="newRecord112"
                style={{ borderWidth: "2px" }}
                className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2    text-gray-600 rounded flex items-center"
              >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                  <img className="pr-1 " src={fileX} />
                </span>
                <Tooltip
                  target="#newRecord112"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">Dismiss all Alerts</p>
                </Tooltip>
              </li>
            </div>
          </section>
        </Modal>
      </div>
    </>
  );
};
