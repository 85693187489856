
export const task_todos_footer_datagrid_cols = [
    {
        id:0,
        caption:"ID",
        dataField:"tskIDpk",
        dataType: "string",
        alignment:"left",
        width:45,
        fixed: true,
        cellRender: renderGridCell
    
    },
    {
        id:1,
        caption:"START DATE",
        dataField:"tskStartDate",
        dataType: "string",
        alignment:"left",
        fixed: true,
        width: 125
    },
    {
        id:2,
        caption:"DUE DATE",
        dataField:"tskDueDate",
        dataType: "string",
        alignment:"left",
        fixed: true,
        width: 125
    },
    {
        id:3,
        caption:"TASK",
        dataField:"tskTask",
        dataType: "string",
        alignment:"left",
        fixed: true,
        width: 300
    },
    {
        id:4,
        caption:"PROGRESS",
        dataField:"tskPercentComplete",
        dataType: "number",
        
        alignment:"left",
        fixed: true,
        width:110
    },
    {
        id:5,
        caption:"APPRAISE",
        dataField:"ratingPercent",
        dataType: "string",
        alignment:"center",
        fixed: true,
        width:80
    }, 
    {
        id:6,
        caption:"SCORING",
        dataField:"tskScoring",
        dataType:'boolean',
        alignment:"center",
        width:80,
        fixed: true,
    },
    {
        id:7,
        caption:"ACTV",
        dataField:"tskActive",
        dataType: "boolean",
        alignment:"center",
        width:80,
        fixed: true,
    },
    {
        id:8,
        caption:"STATUS",
        dataField:"stsName",
        
        alignment:"left",
        width: 125
        
    },
    {
        id:9,
        caption:"PRIORITY",
        dataField:"tdpName",
        dataType: "string",
        alignment:"left",
        width:80
    },
    {
        id:10,
        caption:"PROGRESS ACTIVITY",
        dataField:"tskProgressActivities",
        dataType: "string",
        alignment:"left",
        width:300
    },
    {
        id:11,
        caption:"ASSIGNED BY",
        dataField:"assignedByEmpNameStaffNo",
        dataType: "string",
        alignment:"left",
        width:200
    },
    {
        id:12,
        caption:"ASSIGNED TO",
        dataField:"tskAssignedTo",
        dataType: "string",
        alignment:"left",
        width: 4000
    }
]

function renderGridCell(value: any) {
    throw new Error("Function not implemented.");
}
