
export const fuel_dump_dips_cols = [
    {id: 1,caption : "DATE", dataField : 'fddDate', dataType : "datetime", fixed : true,width:150},
    {id: 2,caption : "SERVICE STATION", dataField : 'sstName', fixed : true,width:110},
    {id: 3,caption : "PRODUCT", dataField : 'prdName', fixed : true,width:100},
    {id: 4,caption : "OPENING BALANCE", dataField : 'fddOpeningBalance',  fixed : true,width:85,format: { type: 'fixedPoint', precision: 2 }},
    {id: 5,caption : "RECEIPT", dataField : 'fddReceipt', fixed : true,width:85,format: { type: 'fixedPoint', precision: 2 }},
    {id: 6,caption : "TANK CAP.", dataField : 'fddTankCapacity',format: { type: 'fixedPoint', precision: 2 },fixed : true,width:85},
    {id: 7,caption : "ISSUE", dataField : 'fddIssue',width:85,format: { type: 'fixedPoint', precision: 2 }},
    {id: 8,caption : "CLOSING BALANCE", dataField : 'fddClosingBalance',width:85,format: { type: 'fixedPoint', precision: 2 },},
    {id: 9,caption : "OPENING MET.READING", dataField : 'fddOpeningMeterReading',width:85,format: { type: 'fixedPoint', precision: 2 },},
    {id: 10,caption : "CLOSING MET.READING", dataField : 'fddClosingMeterReading',width:85,format: { type: 'fixedPoint', precision: 2 },},
    {id: 11,caption : "OPENING DIP BAL", dataField : 'fddOpeningDipBalance',width:90,format: { type: 'fixedPoint', precision: 2 },},
    {id: 12,caption : "ACTIVE", dataField : 'fddActive', dataType : "boolean",},
    {id: 13,caption : "REMARKS", dataField : 'fddRmks', dataType : "string",width:4000},
]
