import { Checkbox, Col, Row } from "antd";

let checkData: string[] = ['Active?', 'Board Member?', 'Retiree?', 'Staff?','Org Admin','Voluntary?','Supervisor?','Dept Administrator?',
'NSP?', 'Manager?', 'Dept Secretary', 'Driver?', 'Director?', 'Sectional Admin', 'Show Birthday', 'CEO','Unit Admin','Show Work Anniversary',
'Senior Staff?', 'Sectional Secretary?', 'Casual Worker?', 'Junior Staff?', 'Technical Staff?', 'Show In Tel Directory', 'Management Staff?',
'Core Staff?'
];

const UpdateStaffCheckbox = () => {
    return ( <Checkbox.Group className="" style={{ width: '80%' }} onChange={()=>{}}>
     <Row>
     { checkData.map((data)=>{
        return  <Col span={8} className="mb-2 ">
      <div className="mx-1 px-1 w-56 border whitespace-nowrap">  <Checkbox value="A">{data}</Checkbox> </div>
      </Col>
      }  )}
    </Row>
  </Checkbox.Group>);
}
 
export default UpdateStaffCheckbox;