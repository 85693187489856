export const vehicleDetails_cols = [
    //  will change this after the presentation
    {
      id: 0,
      // caption: "VEH. REG No",
      caption: "REG. No",
      dataField: "vdtRegNo",
      dataType:'string',
      alignment: "left",
      fixed: true,
     
    },
    {
      id: 1,
      caption: "CHASSIS No.",
      dataField: "vdtChassisNo",
      dataType:'string',
      alignment: "left",
      fixed: true,
      width: 150,
    },
  
    {
      id: 2,
      caption: "ENGINE No",
      dataType:'string',
      dataField: "vdtEngineNo",
      alignment: "left",
      fixed: true,
      width: 100,
    },
    {
      id: 3,
      caption: "VEHICLE CLASS",
      dataType:'string',
      dataField: "vhcName",
      alignment: "left",
      fixed: true,
      width: 70,
    },
    {
      id: 4,
      caption: "MAKE",
      dataType:'string',
      dataField: "vmkName",
      alignment: "left",
      fixed: true,
      width: 70,
    },
    {
      id: 5,
      caption: "MODEL",
      dataType:'string',
      dataField: "mdlName",
      alignment: "left",
      fixed: true,
      width: 70,
    },
  
    {
      id: 6,
      caption: "MANUF.",
      dataType:'string',
      dataField: "mftName",
      alignment: "left",
      fixed: true,
      width: 70,
    },
  
    {
      id: 7,
      caption: "STATUS",
      dataType:'string',
      dataField: "vstName",
      alignment: "left",
      fixed: false,
      width: 70,
     },
     {
      id: 8,
      caption: "FIT?",
      dataField: "vdtFit",
      alignment: "left",
      dataType: "boolean",
      fixed: false,
      
    },
     {
      id: 9,
      caption: "ASSIGNED?",
      dataType:'boolean',
      dataField: "vdtAssigned",
      alignment: "left",
      fixed: false,
      
    },
     {
      id: 10,
      caption: "ACTIVE?",
      dataType:'boolean',
      dataField: "vdtActive",
      alignment: "left",
      fixed: false,
      
    },
     {
      id: 11,
      caption: "DEPT",
      dataType:'string',
      dataField: "dptShtName",
      alignment: "left",
      fixed: false,
   
    },
     {
      id: 12,
      caption: "SECTION",
      dataType:'string',
      dataField: "sxnShtName",
      alignment: "left",
      fixed: false,
      
    },
     {
      id: 13,
      caption: "COUNTRY",
      dataType:'string',
      dataField: "ctyName",
      alignment: "left",
      fixed: false,
      width: 120,
    },
     {
      id: 14,
      caption: "SUPPLIER",
      dataType:'string',
      dataField: "splName",
      alignment: "left",
      fixed: false,
      width: 120,
    },
     {
      id: 15,
      caption: "MANUF. DATE",
      dataType: 'datetime',
      dataField: "vdtManufactureDate",
      alignment: "left",
      fixed: false,
      width: 120,
    },
     {
      id: 16,
      caption: "SUPPLY DATE",
      dataType:'datetime',
      dataField: "vdtSupplyDate",
      alignment: "left",
      fixed: false,
      width: 120,
    },
     {
      id: 17,
      caption: "REG. DATE",
      dataType:'datetime',
      dataField: "vdtRegistrationDate",
      alignment: "left",
      fixed: false,
      width: 120,
    },
     {
      id: 18,
      caption: "ENGINE TYPE",
      dataType:'string',
      dataField: "egtName",
      alignment: "left",
      fixed: false,
      width: 120,
    },
     {
      id: 19,
      caption: "ENGINE MAKE",
      dataType:'string',
      dataField: "egmName",
      alignment: "left",
      fixed: false,
      width: 120,
    },
     {
      id: 20,
      caption: "TRANS. TYPE",
      dataType:'string',
      dataField: "trtName",
      alignment: "left",
      fixed: false,
      width: 120,
    },
     {
      id: 21,
      caption: "FUEL TYPE",
      dataType:'string',
      dataField: "ftpName",
      alignment: "left",
      fixed: false,
      width: 120,
    },
    {
      id: 22,
      caption: "POW TRAIN",
      dataType:'string',
      dataField: "pwtName",
      alignment: "left",
      fixed: false,
      width: 120,
    },
  
    {
      id: 23,
      caption: "HORSE POW",
      dataType:' ',
      dataField: "vdtHorsePower",
      alignment: "left",
      fixed: false,
      width: 120,
    },
  
    {
      id: 24,
      caption: "CUBIC CAP.",
      dataType:'',
      dataField: "vdtCubicCapacity",
      alignment: "left",
      fixed: false,
      width: 120,
    },
  
    {
      id: 25,
      caption: "LENGTH",
      dataType:'',
      dataField: "vdtLength",
      alignment: "left",
      fixed: false,
      width: 120,
    },
  
    {
      id: 26,
      caption: "WIDTH",
      dataType:'',
      dataField: "vdtWidth",
      alignment: "left",
      fixed: false,
      width: 120,
    },
  
    {
      id: 27,
      caption: "HEIGHT",
      dataType:'',
      dataField: "vdtHeight",
      alignment: "left",
      fixed: false,
      width: 120,
    },
  {
      id: 28,
      caption: "No OF AXLES",
      dataType:'',
      dataField: "vdtNoOfAxles",
      alignment: "left",
      fixed: false,
      width: 120,
    },
  
    {
      id: 29,
      caption: "No OF CYLINDERS",
      dataType:'',
      dataField: "vdtNoOfCylinders",
      alignment: "left",
      fixed: false,
      width: 120,
    },
  
    {
      id: 30,
      caption: "No OF WHEELS",
      dataType:'',
      dataField: "vdtNoOfWheels",
      alignment: "left",
      fixed: false,
      width: 120,
    },
  
    {
      id: 31,
      caption: "TANK CAPACITY",
      dataType:'',
      dataField: "vdtTankCapacity",
      alignment: "left",
      fixed: false,
      width: 120,
    },
   {
      id: 32,
      caption: "SEATING CAP.",
      dataType:'',
      dataField: "vdtSeatingCapacity",
      alignment: "left",
      fixed: false,
      width: 120,
    },
     {
      id: 33,
      caption: "MAX SPEEDO.",
      dataType:'',
      dataField: "maxSpeed",
      alignment: "left",
      fixed: false,
      width: 120,
    },
     {
      id: 34,
      caption: "SPEEDO UNIT",
      dataType:'',
      dataField: "speedUnit",
      alignment: "left",
      fixed: false,
      width: 120,
    },
     
     {
      id: 35,
      caption: "TYPE SIZE(F)",
      dataType:'string',
      dataField: "vdtTyreSizeFront",
      alignment: "left",
      fixed: false,
      width: 120,
    },
    {
      id: 36,
      caption: "TYPE SIZE(M)",
      dataType:'string',
      dataField: "vdtTyreSizeMiddle",
      alignment: "left",
      fixed: false,
      width: 120,
    },
    {
      id: 37,
      caption: "PRICE",
      dataType:' ',
      dataField: "vdtPrice",
      alignment: "left",
      fixed: false,
      width: 120,
    },
    {
      id: 38,
      caption: "TYPE SIZE(R)",
      dataType:'string',
      dataField: "vdtTyreSizeRear",
      alignment: "left",
      fixed: false,
      width: 120,
    },
    {
      id: 39,
      caption: "PRICE CURR.",
      dataType:'string',
      dataField: "curSymbol",
      alignment: "left",
      fixed: false,
      width: 120,
    },
  {
      id: 40,
      caption: "OTHER EXPENSES",
      dataType:'string',
      dataField: "other",
      alignment: "left",
      fixed: false,
      width: 120,
    },
  
    {
      id: 41,
      caption: "COLOR",
      dataType:'string',
      dataField: "vdtColour",
      alignment: "left",
      fixed: false,
      width: 120,
    },
  
    {
      id: 42,
      caption: "APPROV. OFFICER NAME",
      dataType:'string',
      dataField: "AO Officer",
      alignment: "left",
      fixed: false,
      width: 120,
    },
  
    {
      id: 43,
      caption: "AO DEPT",
      dataType:'string',
      dataField: "AO Dept",
      alignment: "left",
      fixed: false,
      width: 120,
    },
  
    {
      id: 44,
      caption: "AO SECTION",
      dataType:'string',
      dataField: "AO Section",
      alignment: "left",
      fixed: false,
      width: 120,
    },
  
    {
      id: 45,
      caption: "DRIVER NAME",
      dataType:'string',
      dataField: "driverName",
      alignment: "left",
      fixed: false,
      width: 120,
    },
  
    {
      id: 46,
      caption: "DRIVER DEPT.",
      dataType:'string',
      dataField: "driverDept",
      alignment: "left",
      fixed: false,
      width: 120,
    },
  
    {
      id: 47,
      caption: "DRIVER SECTION",
      dataType:'string',
      dataField: "driverSection",
      alignment: "left",
      fixed: false,
      width: 120,
    },
   {
      id: 48,
      caption: "ADMIN NAME",
      dataType:'string',
      dataField: "adminEmpName15",
      alignment: "left",
      fixed: false,
      width: 120,
    },
     {
      id: 49,
      caption: "ADMIN DEPT",
      dataType:'string',
      dataField: "adminDptShtName",
      alignment: "left",
      fixed: false,
      width: 120,
    },
     {
      id: 50,
      caption: "ADMIN SECTION",
      dataType:'string',
      dataField: "adminSxnShtName",
      alignment: "left",
      fixed: false,
      width: 120,
    },
     {
      id: 51,
      caption: "CONTROLLER NAME",
      dataType:'string',
      dataField: "controllerEmpName15",
      alignment: "left",
      fixed: false,
      width: 120,
    },
     {
      id: 52,
      caption: "CONTR. DEPT",
      dataType:'string',
      dataField: "controllerDptShtName",
      alignment: "left",
      fixed: false,
      width: 120,
    },
     {
      id: 53,
      caption: "CONTR. SECTION",
      dataType:'string',
      dataField: "controllerSxnShtName",
      alignment: "left",
      fixed: false,
      width: 120,
    },
     {
      id: 54,
      caption: "MANAGER NAME",
      dataType:'string',
      dataField: "mgrEmpName15",
      alignment: "left",
      fixed: false,
      width: 120,
    },
     {
      id: 55,
      caption: "MGR DEPT",
      dataType:'string',
      dataField: "mgrDptShtName",
      alignment: "left",
      fixed: false,
      width: 120,
    },
     {
      id: 56,
      caption: "MGR SECTION",
      dataType:'string',
      dataField: "mgrSxnShtName",
      alignment: "left",
      fixed: false,
      width: 120,
    },
     {
      id: 57,
      caption: "DIRECTOR NAME",
      dataType:'string',
      dataField: "dirEmpName15",
      alignment: "left",
      fixed: false,
      width: 120,
    }, {
      id: 58,
      caption: "DIR DEPT",
      dataType:'string',
      dataField: "dirDptShtName",
      alignment: "left",
      fixed: false,
      width: 120,
    },
     {
      id: 59,
      caption: "DIR SECTION",
      dataType:'string',
      dataField: "dirSxnShtName",
      alignment: "left",
      fixed: false,
      width: 120,
    },
     {
      id: 60,
      caption: "VEH?",
      dataType:'boolean',
      dataField: "vdtVehicle",
      alignment: "left",
      fixed: false,
     
    },
   {
      id: 61,
      caption: "EQUIP?",
      dataType:'boolean',
      dataField: "vdtEquipment",
      alignment: "left",
      fixed: false,
     
    },
     {
      id: 62,
      caption: "GEN. VEH?",
      dataType:'boolean',
      dataField: "vdtGeneral",
      alignment: "left",
      fixed: false,
  
    },
     {
      id: 63,
      caption: "LIGHT DUTY?",
      dataType:'boolean',
      dataField: "vdtLightDuty",
      alignment: "left",
      fixed: false,
      width: 80,
    },
  
   {
      id: 64,
      caption: "HEAVY DUTY?",
      dataType:'boolean',
      dataField: "vdtHeavyDuty",
      alignment: "left",
      fixed: false,
      width: 80,
    },
     {
      id: 65,
      caption: "BUS?",
      dataType:'boolean',
      dataField: "vdtBus",
      alignment: "left",
      fixed: false,
    
    },
     {
      id: 66,
      caption: "SPEC?",
      dataType:'boolean',
      dataField: "vdtSpecialised",
      alignment: "left",
      fixed: false,
  
    },
     {
      id: 67,
      caption: "BIKE?",
      dataType:'boolean',
      dataField: "vdtBike",
      alignment: "left",
      fixed: false,
     
    },
    {
      id: 68,
      caption: "REMARKS",
      dataType:'string',
      dataField: "vdtRmks",
      alignment: "left",
      fixed: false,
      width: 4000,
    },
    {
      id: 69,
      caption: "Vehicle Details ID",
      dataType:'number',
      dataField: "vdtIdpk",
      alignment: "left",
      fixed: false,
      width: 15,
      visible:false
    },
  ];