import { useSelector } from "react-redux";
import { RootState } from "../app/store";
import { useDispatch } from "react-redux";
import { setGeneralValue } from "../features/generalSlice";

export const useBusyLoader = (): [string, (text: string) => void] => {
  const dispatch = useDispatch();

  //value
  const busyLoader = useSelector(
    (state: RootState) => state.general?.busyLoader
  ); // get form tier

  //setValue
  const setBusyLoader = (loaderText: string) => {
    dispatch(setGeneralValue({ expr: "busyLoader", value: loaderText }));
  };

  return [busyLoader, setBusyLoader];
};
