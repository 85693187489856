import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { userDataCrypt } from '../functions/userDataEncrypt'
import Cookie from 'universal-cookie'
interface userStateTypes {
  user: any
  formCode: any
}

const cookie = new Cookie();

var initialState = {
  user: sessionStorage.getItem('client') ? JSON.parse(userDataCrypt('decrypt', sessionStorage.getItem('client')!)) : {},
  formCode: sessionStorage.getItem('formCode') ? userDataCrypt('decrypt', sessionStorage.getItem('formCode')!) : "",
  theme : localStorage.getItem('theme') ? localStorage.getItem('theme') : "",
} as userStateTypes | any


const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserValue(state, action: PayloadAction<any>) { //set values of variables here
      const expr: string = action.payload.expr;
      const value: any = action.payload.value;

      state[expr] = value;
    },
    initiateUserData(state) {
      let item = sessionStorage.getItem('client')
      let encItem = userDataCrypt('decrypt', item)
      let parsedItem = JSON.parse(encItem)

      state.user = parsedItem ?? { "userData": {} };
    },

    getFormCode(state) {
      let item = sessionStorage.getItem('formCode')
      let dcrptItem = JSON.stringify(userDataCrypt('decrypt', item)!)
      

      state.formCode = dcrptItem ?? { "formCode": "NoCode" };
    },

    getTheme(state){
      let theme =  localStorage.getItem('theme');
      state.theme = theme ?? "lightMode"
    }
  },
})

export const { setUserValue, initiateUserData, getFormCode, getTheme } = userSlice.actions;
export default userSlice.reducer;