/* eslint-disable react/jsx-pascal-case */
import { Form } from 'antd'
import React, { useState } from 'react'
import { selectsTypes } from '../../human-resource/setups/data/types/selectsTypes'
import { SelectsTemplate } from '../../../templates/select'
import { Cancel, NewButton, SaveButton, UpdateButton } from '../../accessories/buttons'
import { InputsTemplate } from '../../../templates/input'
import Datagrid_template from "../../../templates/Datagrid"
import { TextareaTemplate } from '../../../templates/textarea'
import useFetch from '../../../hooks/useFetch'
import { PopGenSettingsTableInterface } from '../../../interfaces'
import { DateTemplate } from '../../../templates/date'
import dayjs from 'dayjs'
import { useAccountInfo } from '../../../hooks/useAccountInfo'
import { currentDate, useModalData } from '../../accessories/component_infos'
import { ModalTemplate } from '../../../templates/modal'
import { AxiosResponse } from 'axios'
import useResizeMode from '../../../hooks/useResizeMode'
import { useCrudFunc } from '../../../functions/crud'

const generalSettings_cols = [
    {
        id: 0,
        caption: "SAL ID",
        dataField: "salIDpk",
        dataType: "",
        fixed: true,
        visible: true,
        width: 120,
    },
    {
        id: 1,
        caption: "DATE",
        dataField: "salDate",
        dataType: "datetime",
        fixed: true,
        visible: true,
        width: 120,
    },
    {
        id: 2,
        caption: "FORM",
        dataField: "salForm",
        dataType: "string",
        fixed: true,
        visible: true,
        width: 120,
    },
    {
        id: 3,
        caption: "APPROVAL CATEGORY",
        dataField: "salApprovalCat",
        dataType: "string",
        fixed: true,
        visible: true,
        width: 120,
    },
    {
        id: 4,
        caption: "LOWER LIMIT",
        dataField: "salLowerLimit",
        dataType: "",
        fixed: true,
        visible: true,
        width: 120,
    },
    {
        id: 5,
        caption: "UPPER LIMIT",
        dataField: "salUpperLimit",
        dataType: "",
        fixed: true,
        visible: true,
        width: 120,
    },
    {
        id: 6,
        caption: "ACTIVE?",
        dataField: "salActive",
        dataType: "boolean",
        fixed: false,
        visible: true,
        width: 120,
    },
    {
        id: 7,
        caption: "REMARKS",
        dataField: "salRmks",
        dataType: "string",
        fixed: false,
        visible: true,
        width: 120,
    },
];

const level = [

    { xId: 0, xValue: "Supervisor" },
    { xId: 1, xValue: "Manager" },
    { xId: 2, xValue: "Director" },
    { xId: 3, xValue: "Chief Executive" },
]

const forms = [
    { yId: 0, yValue: "Car Loan" },
    { yId: 1, yValue: "Funding Approval" },
    { yId: 2, yValue: "Housing Loan" },
    { yId: 3, yValue: "Petty Cash" },
    { yId: 4, yValue: "Real Estate" },
    { yId: 5, yValue: "Rent Advance" },
    { yId: 6, yValue: "Vehicle Workshop" },
];


const GenSettings = () => {

    const [initial, final] = useResizeMode(window.innerHeight);
    const gridheight = initial - 380;
    const [modal, setModal] = useModalData();

    const [userId] = useAccountInfo();

    const [Refresh, setRefresh] = useState<boolean>(false);
    const genFormat = "YYYY-MM-DD";
    const [Disable, setDisable] = useState(true);
    const [generalData, error, isLoading]: [PopGenSettingsTableInterface[], any, any] = useFetch(`GeneralSettings/PopGenSettingsTable`, Refresh, 'salIDpk')

    const [Postdata, setPostdata] = useState({
        "salDate": "",
        "salForm": "",
        "salApprovalCat": "",
        "salLowerLimit": NaN,
        "salUpperLimit": NaN,
        "salActive": true,
        "salRmks": "",
        "salCreatedBy": userId.toString(),
        "salEditedBy": userId.toString(),
        "salCreationDate": dayjs().format(genFormat),
        "salEditedDate": dayjs().format(genFormat),
    })


    const selects: selectsTypes[] = [
        { id: 0, label: "Form", idExpr: "yId", dataExpr: "yValue", optionsData: forms, stateName: "salForm", defaultValue: Postdata.salForm, style: "" },
        { id: 1, label: "Approval Level", idExpr: "xId", dataExpr: "xValue", optionsData: level, stateName: 'salApprovalCat', defaultValue: Postdata.salApprovalCat, style: "" },
    ]

    const [updateMode, setUpdateMode] = useState(false);
    const [showCancelButton, setShowCancelButton] = useState(false);
    const [salIDpk, setSalIDpk] = useState<number>(undefined);
    const [PostDataFunc, _, PatchDataFunc] = useCrudFunc();
    

    const clearFields = () => {
        setPostdata({
            "salDate": "2023-01-01",
            "salForm": "",
            "salApprovalCat": "",
            "salLowerLimit": NaN,
            "salUpperLimit": NaN,
            "salActive": true,
            "salRmks": "",
            "salCreatedBy": userId.toString(),
            "salEditedBy": userId.toString(),
            "salCreationDate": currentDate,
            "salEditedDate": currentDate,
        })
    }
    async function createApprovalLimit() {


        
        try {

            const postres = await PostDataFunc(`GeneralSettings/CreateApprovalLimits`, Postdata, 
                `
                Created the genSettings with  salDate:${Postdata?.salDate}, salForm:${Postdata?.salForm}, salApprovalCat:${Postdata?.salApprovalCat} Date Created:${Postdata?.salCreationDate}. created By:${userId}
                `
            ) as AxiosResponse<{
                id: number;
                isSuccess: boolean;
                message: string;
                errors: null;
            }, any>
            
            if (postres.data.isSuccess) {
                setRefresh(!Refresh)
                setDisable(true)
                setShowCancelButton(false)

                setModal({
                    state: true,
                    title: "Saved Success",
                    message: "Record saved successfully",
                    icon: "success",
                    okText: "OK",
                    cancelText: "No",
                    disableCancel: true,
                    okHandler: () => {
                        setModal(prev => ({
                            ...prev,
                            state: false,

                        }))
                        // createApprovalLimit()
                    },
                    cancelHandler: () => {
                        setModal(prev => ({
                            ...prev,
                            state: false,

                        }))
                    }
                })
                
                
            }
        } catch (e) {
            
        }
    }
    async function updateApprovalLimit() {
        try {

            const postres = await PatchDataFunc(`GeneralSettings/UpdateApprovalLimit`, {
                "salLowerLimit": Postdata.salLowerLimit,
                "salUpperLimit": Postdata.salUpperLimit,
                "salRmks": Postdata.salRmks,
                "salEditedBy": Postdata.salEditedBy,
                "salEditedDate": dayjs().format(genFormat),
                "salIDpk": salIDpk
                
            },
            `
            Update the genSettings with  salDate:${Postdata?.salDate}, salForm:${Postdata?.salForm}, salApprovalCat:${Postdata?.salApprovalCat} Date Edited:${Postdata?.salEditedDate}. Edited By:${Postdata?.salEditedBy}
            `            
            ) as AxiosResponse<any, any>
            
            if (postres.status) {
                setRefresh(!Refresh)
                setDisable(true)
                setShowCancelButton(false)

                setModal({
                    state: true,
                    title: "Update Success",
                    message: "Record Updated successfully",
                    icon: "success",
                    okText: "OK",
                    cancelText: "No",
                    disableCancel: true,
                    okHandler: () => {
                        setModal(prev => ({
                            ...prev,
                            state: false,

                        }))
                    },
                    cancelHandler: () => {
                        setModal(prev => ({
                            ...prev,
                            state: false,

                        }))
                    }
                })
                
                
            }
        } catch (e) {
            
        }
    }

    return (
        <div className='w-full h-full p-2'>
            <ModalTemplate
                width={600}
                icon={modal.icon}
                disableCancel={modal.disableCancel}
                cancelText={modal.cancelText}
                open={modal.state}
                okHandler={modal.okHandler}
                cancelHandler={modal.cancelHandler}
                message={modal.message}
                okText={modal.okText}
                title={modal.title}
            />
            {/* Top Sec */}
            <section className='w-full border-[1px] rounded'>
                <Form
                    className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded-md py-2 px-2 "
                    name="sfafss"
                    labelCol={{ span: 2 }}
                    wrapperCol={{ span: 10 }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    size="small"
                    layout="horizontal"
                >
                    <DateTemplate
                        selectedDate={dayjs(Postdata.salDate) ?? dayjs().format("YYYY-MM-DD ")}
                        datePlaceholder={dayjs("2024-04-11T22:36:17.930Z").format(genFormat)}
                        changeDate={(date: any) => setPostdata((prev) => ({ ...prev, "salDate": dayjs(date).format(genFormat) }))
                        }
                        label='Date'
                        width={135}
                        // style={{ width: 125 }}
                        format={"DD MMM YYYY"}
                        disabled={Disable}
                    />

                    {selects.map(({ id, label, idExpr, dataExpr, optionsData, stateName, defaultValue }) => {
                        return (
                            <React.Fragment key={id} >
                                <SelectsTemplate
                                    useCallFunc
                                    label={label}
                                    selectStyle={{width: 300}}
                                    idexpr={idExpr}
                                    dataexp={dataExpr}
                                    options={optionsData}
                                    placeHolder={defaultValue}
                                    disabled={Disable}

                                    selectedValue={(value: any) => { 
                                        setPostdata((prev) => ({ ...prev, [stateName]: value[dataExpr] }))
                                    }}

                                />
                            </React.Fragment>
                        )
                    })}

                    <InputsTemplate
                        useCallbackFunc
                        label={"Lower Limit"}
                        defaultValue={Postdata.salLowerLimit}
                        span
                        labelColPan={2}
                        disabledStatus={Disable}
                        orderOnchange={(e: any) => { 
                            setPostdata((prev) => ({ ...prev, "salLowerLimit": e }))
                        }}
                        numberOnly
                        // inputStyle={{marginRight: 5}}
                        additionalWidget={<div className="ml-4">
                            <InputsTemplate
                                orderOnchange={(e: any) => { 
                                    setPostdata((prev) => ({ ...prev, "salUpperLimit": e }))
                                }}
                                useCallbackFunc numberOnly label="Upper Limit" disabledStatus={Disable} defaultValue={Postdata.salUpperLimit} span inputStyle={{width:150}} />
                        </div>}
                    />

                    <TextareaTemplate
                        placeholder={"Enter Remarks"}
                        setCallbackValue={(e: any) => { 
                            setPostdata((prev) => ({ ...prev, "salRmks": e }))
                        }}
                        labelCol={2}  useCallbackFunc height={70} label={"Remarks"} disabled={Disable} defaultValue={Postdata.salRmks} />


                    {/*<div className='w-1/2 flex'>
                        {dates.map(({ id, label }) => {
                            return (
                                <div className='w-full '>
                                    <DateTemplate width={200} labelCol={12} label={label} />
                                </div>
                            )
                        })}
                    </div>

                    <TextareaTemplate labelCol={3} MarginLeft={3} useCallbackFunc height={70} label={"Remarks"} /> */}

                    <div className='w-full flex justify-end'>
                        {updateMode === true ? (
                            <UpdateButton
                                handleUpdate={() => {
                                    
                                    setModal({
                                        state: true,
                                        title: "Update Approval Limit",
                                        message: "Are you sure you want to update this record?",
                                        icon: "question",
                                        okText: "Yes",
                                        cancelText: "No",
                                        disableCancel: false,
                                        okHandler: async () => {
                                            setModal(prev => ({
                                                ...prev,
                                                state: false,

                                            }))
                                            await updateApprovalLimit()
                                        },
                                        cancelHandler: () => {
                                            setModal(prev => ({
                                                ...prev,
                                                state: false,

                                            }))
                                        }
                                    })
                                    // setUpdateMode(false)
                                }}
                            />
                        ) : (
                            <SaveButton
                                handleSave={() => {
                                        // setShowCancelButton(false)
                                        setModal({
                                            state: true,
                                            title: "Save Approval Limit",
                                            message: "Are you sure you want to save this record?",
                                            icon: "question",
                                            okText: "Yes",
                                            cancelText: "No",
                                            disableCancel: false,
                                            okHandler: async () => {
                                                setModal(prev => ({
                                                    ...prev,
                                                    state: false,

                                                }))
                                                await createApprovalLimit()
                                            },
                                            cancelHandler: () => {
                                                setModal(prev => ({
                                                    ...prev,
                                                    state: false,

                                                }))
                                            }
                                        })
                                }}
                                disableButton={Disable}
                            />
                        )}
                        {showCancelButton === true ? (
                            <Cancel
                                useCallbackFunc={true}
                                cancel_button_toggler={() => {
                                    setShowCancelButton(false)
                                    setDisable(true)
                                    setUpdateMode(false)
                                }}
                            />
                        ) : (
                                <NewButton
                                useCallbackFunc={true}
                                    new_button_toggler={() => {
                                        clearFields()
                                    setDisable(false)
                                    setShowCancelButton(true)
                                }}
                            />
                        )}
                    </div>

                </Form>
            </section>

            <section className="w-full h-full ">
                <Datagrid_template
                    gridheight={gridheight}
                    columns={generalSettings_cols}
                    data={generalData}
                    rowDoubleClicked={() => {
                        setShowCancelButton(true)
                        setUpdateMode(true)
                        setDisable(false)
                    }}
                    onRowClick={(e: PopGenSettingsTableInterface) => {

                        setShowCancelButton(false)
                        setUpdateMode(false)
                        setDisable(true)
                        
                        setSalIDpk(e?.salIDpk)
                        setPostdata({
                            ...Postdata,
                            "salDate": e?.salDate,
                            "salForm": e?.salForm,
                            "salApprovalCat": e?.salApprovalCat,
                            "salLowerLimit": e?.salLowerLimit,
                            "salUpperLimit": e?.salUpperLimit,
                            "salActive": e?.salActive,
                            "salRmks": e?.salRmks,


                        })



                    }}

                />

            </section>


        </div>
    )
}

export default GenSettings



// function async saveData (){
//  await blahblah (
        
    // )
// .then((res) => {
// setDisable(true)
// setShowCancelButton(false)
    // })
// .catch((err) => { 
        
    // })
// }