import { forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import Setups_Template from '../.setups_templates/Setups_Template';
import { exprsTypes, modalTypes, searchTextTypes, selectExprsTypes, selectsTypes } from '../data/types/selectsTypes';
import useFetch from '../../../../hooks/useFetch';
import { hr_setups_Sections_columns } from './data/HrSetupsSectionsData';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { validationsData, saveModalData, updateModalData, api_url } from '../../../accessories/component_infos';
import { setGeneralValue } from '../../../../features/generalSlice';
import { ModalTemplate } from '../../../../templates/modal';
import CustomLoader from '../../../accessories/custom-loader';
import { WarningAlert } from '../../../accessories/warningAlert';
import { setTier2CheckboxValue, tier2CheckboxSaveOnclick, tier2CheckboxUpdateOnclick } from '../../../../features/forms/tier2checkbox/tier2SliceCheckbox';
import { useBusyLoader } from '../../../../hooks/useBusyLoader';
import { PostDataFunc  } from '../../../../functions/post';
import { UpdateDataFunc } from '../../../../functions/update';
import { userInfo } from 'os';
import { useAccountInfo } from '../../../../hooks/useAccountInfo';
import { useCrudFunc } from '../../../../functions/crud';


export const Hr_Setups_Sections = forwardRef(({a}:any, ref)=>{
    const updateUrl:string = 'HrSections/UpdateHrSection';

    const saveSuccessText = {message : 'Section added Successfully',ok : 'Ok' , title : 'Save Succesful'} // successful save modal message
    const updateSuccessText = {message : 'Section updated Successfully',ok : 'Ok' , title : 'Update Successful'} // successful update modal message

    // store data expressions[unique]
    const idExpr:String = 'sxnIdpk';
    const nameExpr:string = 'sxnName'
    const shortnameExpr:string = 'sxnShtName'
    const orderExpr: string = 'sxnOrder';
    const activeExpr: string = 'sxnActive';
    const remarksExpr: string = 'sxnRmks';
    const inputsExprsData: any = {idExpr : idExpr,nameExpr : nameExpr,shortnameExpr : shortnameExpr,orderExpr : orderExpr,activeExpr,remarksExpr : remarksExpr }
    const selectsExpr:selectExprsTypes[] = [{idExpr : 'obrIdpk', nameExpr : 'obrName',label :'branch'},{idExpr : 'dptIdpk', nameExpr : 'dptName',label :'department'},{idExpr : 'areIdpk', nameExpr : 'areName',label :'area'},{idExpr : 'areIdpk', nameExpr : 'areName',label :'area'},{idExpr : 'areIdpk', nameExpr : 'areName',label :'area'},{idExpr : 'areIdpk', nameExpr : 'areName',label :'area'}]

    const storeExprs = () =>{
        const exprs:exprsTypes[] = [{expr:'existsUrl',value:`HrSections`},{expr: 'idExpr' ,value : idExpr},{expr: 'nameExpr' ,value : nameExpr},{expr: 'shortnameExpr' ,value : shortnameExpr},{expr: 'selectExprs' ,value : selectsExpr[0]}]
        for (let i = 0; i < exprs.length; i++) {            
            dispatch(setTier2CheckboxValue({expr: exprs[i].expr ,value : exprs[i].value}));
        }

        // to general slice
        const generalExprs:exprsTypes[] = [{expr: 'selectExprs' ,value : selectsExpr},{expr: 'inputExprs' ,value : inputsExprsData}]
        for (let i = 0; i < generalExprs.length; i++) {            
            dispatch(setGeneralValue({expr: generalExprs[i].expr ,value : generalExprs[i].value}));
        }
    }

    const [empId, userId, userInfo ] = useAccountInfo()
    const[busyLoader,setBusyLoader] = useBusyLoader();
    const dispatch = useDispatch();
    const [refresh, setRefresh] = useState<boolean>(false); //refresh data state for data creation or update

    //modal states
    //--general--
    const triggerSuccessModal = useSelector((state:RootState)=>state.tier2Ckeckboxforms.successModalState); //trigger success modal
    const SuccessModalText = useSelector((state:RootState)=>state.tier2Ckeckboxforms.successText); //success modal text

    //--for backend errors--
    const[errState,setErrState] = useState<boolean>(false);
    const[errTitle,seterrTitle] = useState<string>('');
    const[errMessage,seterrMessage] = useState<string>('');

    //--warning modal--
    const triggerwarningModal = useSelector((state:RootState)=>state.tier2Ckeckboxforms.warningState); //trigger warning
    const warningInfos:any = validationsData; //warning type details
    const warningDataExpr = useSelector((state:RootState)=>state.tier2Ckeckboxforms.warningDataExpr); //warning type selector

    //--save data modal--
    const saveData = saveModalData; //save modal details[data]
    const triggersaveModal = useSelector((state:RootState)=>state.tier2Ckeckboxforms.saveDataOnclick); //trigger save modal
   
    //--Update data modal--
    const updateIndInfos = updateModalData; //update modal details[data]
    const triggerupdateModal:boolean = useSelector((state:RootState)=>state.tier2Ckeckboxforms.updateDataOnclick); //trigger update question

    // on page resize height responsiveness
    const [trigger,setTrigger] = useState<boolean>(false);
    useImperativeHandle(ref, () => ({
        test: () => {
            setTrigger(!trigger);
        }
    }));

    // get current values from  [slice dependent on form type]
    const department = useSelector((state:RootState)=>state.tier2Ckeckboxforms.select0);
    const select1 = useSelector((state:RootState)=>state.tier2Ckeckboxforms.select1);
    const manager = useSelector((state:RootState)=>state.tier2Ckeckboxforms.selectAfter0);
    const name = useSelector((state:RootState)=>state.tier2Ckeckboxforms.name);
    const shortname = useSelector((state:RootState)=>state.tier2Ckeckboxforms.shortname);
    const order = useSelector((state:RootState)=>state.tier2Ckeckboxforms.order);
    const active = useSelector((state:RootState)=>state.tier2Ckeckboxforms.active);
    const remarks = useSelector((state:RootState)=>state.tier2Ckeckboxforms.remarks);
    const selectedID = useSelector((state:RootState)=>state.general.selectedFieldData?.sxnIDpk);
    // Sorry
    const managerStaffNumber = useSelector((state:RootState)=>state.general.selectedFieldData?.sxnMgrStaffNo);
    const managerStaffNumberSelect = useSelector((state:RootState)=> state.tier2Ckeckboxforms?.selectedDropDown && JSON.parse(state.tier2Ckeckboxforms.selectedDropDown)?.empStaffNo);
    const[staffNumberSelectValid, setStaffNumberSelect] = useState(true);

useEffect(()=>{
    setStaffNumberSelect(false)
}, [
    managerStaffNumber
])

useEffect(()=>{
    setStaffNumberSelect(true)
    console.log(managerStaffNumberSelect)
}, [
    managerStaffNumberSelect
])
   
   

    console.log({"secx1": department, "secx2": manager})

    //post form Data
    //validate data
    const validateDataSave = () =>{
        console.log(managerStaffNumber)
        dispatch(tier2CheckboxSaveOnclick('type1'));
    }

    const [Posting, Updating, Patching] = useCrudFunc()
    // post
    const postData = async() =>{
        dispatch(setTier2CheckboxValue({expr: 'saveDataOnclick' ,value : false}))
        // console.log(manager)
        // console.log({
        //     "sxnName": name,
        //     "sxnShtName": shortname,
           
        //     "sxnDeptIdfk": department,
           
        //     "sxnMgrEmpIdfk": manager.id,
        //     "sxnMgrStaffNo":staffNumberSelectValid? managerStaffNumberSelect:managerStaffNumber,
        //     "sxnMgrEmpName":manager.data,
        //     "sxnActive": active,
        //     "sxnRmks": remarks,
        //     "sxnOrder" : order,
        //     "sxnCreatedBy": 1, 
        // })
        
        try {
            setBusyLoader('Saving record...')
            const res = await Posting (`HrSections/CreateHrSection`,{
                "sxnName": name,
                "sxnShtName": shortname,
                "sxnDeptIdfk": department,
                "sxnMgrEmpIdfk": manager?.id,
                "SxnMgrEmpName": manager?.data,
                "sxnActive": active,
                "sxnRmks": remarks,
                "sxnOrder" : order, 
            },`Created the HR-Org Unit-Section with Section name: ${name}, Short Name: ${shortname}, Dept ID: ${department}, Manager ID: ${manager?.id}, Active: ${active}, Remarks: ${remarks}, Order: ${order}`)
            if(res.data.success ===  true ){
                setRefresh(!refresh);
                setBusyLoader('') //disable busy loader

                const exprs:exprsTypes[] = [{expr: 'saveDataOnclick' ,value : false},{expr: 'successModalState' ,value : true},{expr: 'successText' ,value : saveSuccessText}];
                for (let i = 0; i < exprs.length; i++) {            
                dispatch(setTier2CheckboxValue({expr: exprs[i].expr ,value : exprs[i].value}));
                }
            }else{throw Error(res.data.message)}
        } catch (error:any) {
            setBusyLoader('');
            console.log(error);

            //set states for db errors modal
            setErrState(true);
            seterrTitle(error.response ? error.response.data.message : 'Server Error');
            seterrMessage(error.response ? error.response.data.errors[0] : 'Error connecting to server. Please contact system administrator');
        }
    }

    //Update Form data
    //validate data
    const validateDataUpdate = () =>{
        dispatch(tier2CheckboxUpdateOnclick(''));
    }
    // update
    const UpdateData = async()=>{
        dispatch(setTier2CheckboxValue({expr: 'updateDataOnclick' ,value : false}));
        
        try {
            setBusyLoader('Updating record...')
            const res = await Updating(`HrSections/UpdateHrSection`,{
                "sxnIDpk": selectedID,
                "sxnName": name,
                "sxnShtName": shortname,
                "sxnDeptIdfk": department,
                "sxnMgrEmpIdfk": manager?.id,
                "sxnMgrStaffNo":staffNumberSelectValid? managerStaffNumberSelect:managerStaffNumber,
                "sxnMgrEmpName": manager?.data,
                "sxnActive": active,
                "sxnRmks": remarks,
                "sxnOrder": order,
                // "sxnEditedBy": 1,
                // "sxnEditedDate": "2023-05-24T09:40:13.516Z"

            },`Edited HR-Org Unit-Sections from ${selectedID} to Section name: ${name}, Short Name: ${shortname}, Dept ID: ${department}, Manager ID: ${manager?.id}, Manager Staff No: ${staffNumberSelectValid? managerStaffNumberSelect:managerStaffNumber}, Manager Name: ${manager?.data}, Active: ${active}, Remarks: ${remarks}, Order: ${order}`)
            setRefresh(!refresh);
            setBusyLoader('') //disable busy loader

            const exprs:exprsTypes[] = [{expr: 'updateDataOnclick' ,value : false},{expr: 'successModalState' ,value : true},{expr: 'successText' ,value : updateSuccessText}];
            for (let i = 0; i < exprs.length; i++) {           
                dispatch(setTier2CheckboxValue({expr: exprs[i].expr ,value : exprs[i].value}));
            }
        } catch (error:any) {
            console.log(error);
            setBusyLoader('') //disable busy loader

            //set states for db errors modal
            setErrState(true);
            seterrTitle(error.response.data.message);
            seterrMessage(error.response.data.errors[0]);
        }
    }

    //modals data
    const modals:modalTypes[] = [
        //warning
        {disableCancel : true , icon : 'warning' ,open : triggerwarningModal, okHandler : ()=> dispatch(setTier2CheckboxValue({expr: 'warningState' ,value : false})), cancelHandler : ()=> dispatch(setTier2CheckboxValue({expr: 'warningState' ,value : false})), message : warningInfos[warningDataExpr!].message, cancelText : warningInfos[warningDataExpr!].cancelText, okText : warningInfos[warningDataExpr!].okText, title : warningInfos[warningDataExpr!].title},
        //backend error
        {disableCancel : true , icon : 'warning' ,open : errState, okHandler : ()=> setErrState(false), cancelHandler : ()=> setErrState(false), message : errMessage, cancelText : '', okText : 'Ok', title : errTitle},
        //success
        {disableCancel : true , icon : 'success' ,open : triggerSuccessModal, okHandler : ()=> dispatch(setTier2CheckboxValue({expr: 'successModalState' ,value : false})), cancelHandler : ()=> dispatch(setTier2CheckboxValue({expr: 'successModalState' ,value : false})), message :SuccessModalText.message, okText : SuccessModalText.okText, title : SuccessModalText.title},
        //save
        {disableCancel : false , icon : 'question' ,open : triggersaveModal, okHandler : ()=> postData(), cancelHandler : ()=> dispatch(setTier2CheckboxValue({expr: 'saveDataOnclick' ,value : false})), message : saveData.message, cancelText : saveData.cancelText, okText : saveData.okText, title : saveData.title},
        //Update
        {disableCancel : false , icon : 'question' ,open : triggerupdateModal, okHandler : ()=> UpdateData(), cancelHandler : ()=> dispatch(setTier2CheckboxValue({expr: 'updateDataOnclick' ,value : false})), message : updateIndInfos.message, cancelText : updateIndInfos.cancelText, okText : updateIndInfos.okText, title : updateIndInfos.title},
    ]

    const [searchText,setSearchText] =useState<searchTextTypes>({temp:'',text:''});
    const activeStatus = useSelector((state:RootState)=>state.general.activeStatus);
    const [status,setstatus]= useState<boolean|string>('');
  
    // data for datagrid
    const [data,Error,Loading] = useFetch(`HrSections/GetAllHrSections?search=${searchText.text}&status=${status}`,refresh);

    const [departmentData,departmentDataError,departmentDataLoading] = useFetch( `HrSections/GetAllHrSectionsDepartments`,refresh); //department
    const [branchData,branchDataError,branchDataLoading] = useFetch('HrSections/GetHrBranch',refresh); //department

    const [managerData,managerDataError,managerDataLoading] = useFetch('HrSections/GetAllHrSectionsManagers',refresh); //manager

    useEffect(()=>{
        if(data){
            storeExprs(); // run func to store expressions on store
            dispatch(setGeneralValue({expr : 'idExpr', value : idExpr})); // dispatch fetched data to reduxstore
            dispatch(setGeneralValue({expr: 'formData' ,value : data})); // dispatch fetched data to reduxstore
        }

        Loading ? setBusyLoader('.') : setBusyLoader('');
    },[data,Loading]);

    // form parameters
    const form_header = 'Section Details';
    const selects:selectsTypes[] = [
        {id: 0, label : 'Department', optionsData :departmentData, idExpr:'dptIDpk',dataExpr:'dptName'},
    ]

    const selects_after_constants:selectsTypes[] = [
        {id: 0, label : 'Manager', optionsData : managerData,idExpr:'empIDpk',dataExpr:'empName',diffExprs:{idKey: 'sxnMgrEmpIDfk', valueKey: 'sxnMgrEmpName', extraKey: 'sxnMgrStaffNo'}},
    ]
    const optionalnamelabel = 'Section'

    return (
        <>
            {/* modals */}
            {modals.map(({disableCancel,icon,okHandler,cancelHandler,open,cancelText,okText,message,title}:modalTypes,index)=>{
                return(
                    <div key = {index}>
                        <ModalTemplate icon= {icon && icon} disableCancel = {disableCancel} cancelText = {cancelText && cancelText} open = {open} okHandler={okHandler} cancelHandler={cancelHandler} message= {message} okText= {okText} title={title}/>
                    </div>
                )
            })}

            {Error&&(
                <WarningAlert/>
            )}

            <Setups_Template
               searchTextChange={(newText) =>{
                setSearchText({temp:newText, text:searchText.text});
            }}
            handleSearch={() =>{
                setSearchText({temp:searchText.temp, text:searchText.temp});
                setstatus(activeStatus);
            }}
            
            selects_after_constants={selects_after_constants}
            updateData={validateDataUpdate}
            createData={validateDataSave}
            formTier='tier2checkbox'
            trigger = {trigger}
            datagridColumns={hr_setups_Sections_columns}
            datagridData = {data}
            optionalnamelabel= {optionalnamelabel}
            selects={selects}
            formHeader={ form_header}/>
        </>
    );
})