import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import right from '../../../../../../../assets/right.png';
import { GetDataFunc } from "../../../../../../../functions/get";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { useBusyLoader } from "../../../../../../../hooks/useBusyLoader";
import useModalStore from "../../../../../../../hooks/zustand/modalStore";

interface props{
    passMark:number
    examsRegistrationId: number,
    totalQuestions: number,
    examTitle: string,
    onContinue: ()=> void
}
export const ExamsResult:React.FC<props>=({examsRegistrationId,totalQuestions,examTitle,passMark,onContinue})=>{
    const modal = useModalStore();
    const [busyLoader,setBusyLoader] = useBusyLoader();
    const [employeeId, userId] = useAccountInfo();

    const borderTheme = useSelector((state:RootState)=>state.general.cssBorderClass)

    const [totalCorrects, setTotalCorrects] = useState<number|undefined>(undefined);
    const [percentagePass, setPercentagePass] = useState(0);
    const [hasPassed, setHasPassed] = useState(false);

    
    useEffect(()=>{
        const getData=async()=>{
            try {
                setBusyLoader('Getting Results...');

                const resultsData = await GetDataFunc(`DrivingTest/GetExamResults?RegistrationId=${examsRegistrationId}&EmployeeId=${employeeId}&TotalQuestions=${totalQuestions}&PassMark=${passMark}`);
                
                setHasPassed(resultsData.data?.hasPassed);
                setPercentagePass(resultsData.data?.percentagePass);
                setTotalCorrects(resultsData.data?.totalCorrects);
            } catch (error) {

                modal.error('Something went wrong', 'Error getting results. Please contact your Systems Administrator for assistance')
            } finally{
                setBusyLoader('');
            }
        }     
        
        getData();
    },[])

    return(
        <>
           {totalCorrects !== undefined &&(
                <main style={{borderColor:borderTheme}} className=" border-[1px] rounded px-5 py-10 my-2">
                {hasPassed?(
                    <p className=" text-4xl text-green-600 font-semibold">Congrats, Exam Passed</p>
                ):(
                    <p className=" text-4xl text-red-600 font-semibold">Exam Failed</p>
                )}

                <p className=" text-2xl  mt-7">{examTitle}</p>

                <p className="my-2 text-gray-500">Total Questions Answered : <span>{totalQuestions}</span></p>
                <p className="my-2 text-gray-500">Total Corrects : <span className=" text-green-800 font-semibold">{totalCorrects ?? 0}</span></p>
                <p className="my-2 text-gray-500">Percentage Pass : <span>{percentagePass ?? 0}%</span></p>

                <button
                style={{borderColor:borderTheme}}
                className="border-[1.5px] rounded shadow-lg font-semibold text-gray-600 text-base py-2.5 mt-6 px-4 flex items-center justify-between"
                onClick={()=>{
                    onContinue();
                }}
                >
                    Continue
                    <img className=" ml-4" src={right} alt="icon" />
                </button>
             </main>
           )}
        </>
    )
}