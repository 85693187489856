import axios from "axios";
import { api_url } from "../../../components/accessories/component_infos";

interface smsDataType {
  usaIDpk: number;
  empIDpk: number;
  empMobNox: string;
}

export const passwordResetRequestFunc = async (StaffNo: string | number) => {
  try {
    //send request for reset
    const res = await axios.patch(`${api_url}/Accounts/RequestPasswordReset`, {
      username: StaffNo,
    });

    //return success
    return { status: 200, data: res.data };
  } catch (error: any) {
    if (error.response.status === 400) {
      return { status: 400, data: error.response };
    }

    return { status: 500, error: error.response };
  }
};

export const passwordResetRequestNewFunc = async ({
  usaIDpk,
  empIDpk,
  empMobNox,
}: smsDataType) => {
  try {
    //send request for reset
    const res = await axios.put(`${api_url}/Accounts/ResetUserPassword`, {
      empIDpk: empIDpk,
      usaID: usaIDpk,
      mobileNo: empMobNox.replace(/-/g, ""), 
    });

    //return success
    return { status: 200, data: res.data };
  } catch (error: any) {
    if (error.response.status === 400) {
      return { status: 400, data: error.response };
    }

    return { status: 500, error: error.response };
  }
};

export const resetModalResponses = [
  {
    title: "SMS Disabled",
    message: `Sending of SMS has been disabled. Please contact your Systems Administrator for assistance`,
    icon: "warning",
    state: true,
  },
  {
    title: "Enter Username",
    message: `Please enter your USERNAME or Staff Number`,
    icon: "warning",
    state: true,
  },
  {
    title: "Invalid Username",
    message: `The Username/Staff Number is invalid. Please try again or contact your Systems Administrator for assistance`,
    icon: "warning",
    state: true,
  },
  {
    title: "SMS Alert Disabled",
    message: `SMS Alerts have been disabled for now.\n Please contact your Systems Administrator for assistance`,
    icon: "warning",
    state: true,
  },
  {
    title: "Password Reset Cap Exhausted",
    message: `You have exhausted your chances of Password Reset through SMS for today. You have a maximum of three (3) chances for a day.
    \n\nPlease contact your Systems Administrator for assistance`,
    icon: "warning",
    state: true,
  },
  {
    title: "SMS Password?",
    message: `Do you want to receive your temporary\npassword through a text message?`,
    icon: "question",
    state: true,
  },
];
