/* eslint-disable react/jsx-pascal-case */
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";
import {
  setValue,
  tier1SaveOnclick,
  tier1UpdateOnclick,
} from "../../../../../../features/forms/tier1/createSlice";
import { setGeneralValue } from "../../../../../../features/generalSlice";
import useFetch from "../../../../../../hooks/useFetch";
import { ModalTemplate } from "../../../../../../templates/modal";
import {
  currentDate,
  postError,
  putError,
  saveModalData,
  updateModalData,
  validationsData,
} from "../../../../../accessories/component_infos";
import Setups_Template from "../../../../../human-resource/setups/.setups_templates/Setups_Template";
import {
  exprsTypes,
  inputsTypes,
  modalTypes,
  searchTextTypes,
} from "../../../../../human-resource/setups/data/types/selectsTypes";

import { PostDataFunc } from "../../../../../../functions/post";
import { UpdateDataFunc } from "../../../../../../functions/update";
import { useAccountInfo } from "../../../../../../hooks/useAccountInfo";
import { useBusyLoader } from "../../../../../../hooks/useBusyLoader";
import { WarningAlert } from "../../../../../accessories/warningAlert";
import { safety_setups_examsNamesColumns } from "./data/examNamesData";

export const TMSExamNames = forwardRef(({ a }: any, ref) => {
  const saveSuccessText = {
    message: "Exam Name added Successfully",
    ok: "Ok",
    title: "Success",
  };
  const updateSuccessText = {
    message: "Exam Name updated Successfully",
    ok: "Ok",
    title: "Success",
  };

  const [employeeID, userID] = useAccountInfo();

  const idExpr: string = "exnIdpk";
  const nameExpr: string = "exnName";
  const shortnameExpr: string = "exnShtName";
  const orderExpr: string = "exnOrder";
  const durationExpr: string = "exnDuration";
  const noOfQuestionExpr: string = "exnNoOfQuestions";
  const activeExpr: string = "exnActive";
  const remarksExpr: string = "exnRmks";
  const inputsExprsData: any = {
    idExpr: idExpr,
    nameExpr: nameExpr,
    shortnameExpr: shortnameExpr,
    durationExpr: durationExpr,
    noOfQuestionExpr: noOfQuestionExpr,
    orderExpr: orderExpr,
    activeExpr: activeExpr,
    remarksExpr: remarksExpr,
  };

  const storeExprs = () => {
    const exprs = [
      { expr: "idExpr", value: idExpr },
      { expr: "nameExpr", value: nameExpr },
      { expr: "shortnameExpr", value: shortnameExpr },
      { expr: "existsUrl", value: `AsmTmsExamNames` },
    ];

    for (let i = 0; i < exprs.length; i++) {
      dispatch(setValue({ expr: exprs[i].expr, value: exprs[i].value }));
    }

    const generalExprs: exprsTypes[] = [
      { expr: "inputExprs", value: inputsExprsData },
    ];
    for (let i = 0; i < generalExprs.length; i++) {
      dispatch(
        setGeneralValue({
          expr: generalExprs[i].expr,
          value: generalExprs[i].value,
        })
      );
    }
  };

  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState<boolean>(false);

  const triggerSuccessModal = useSelector(
    (state: RootState) => state.tier1Add.successModalState
  );
  const SuccessModalText = useSelector(
    (state: RootState) => state.tier1Add.successText
  );

  const [errState, setErrState] = useState<boolean>(false);
  const [errTitle, seterrTitle] = useState<string>("");
  const [errMessage, seterrMessage] = useState<string>("");

  const triggerwarningModal = useSelector(
    (state: RootState) => state.tier1Add.warningState
  );
  const warningInfos: any = validationsData;
  const warningDataExpr: any = useSelector(
    (state: RootState) => state.tier1Add.warningDataExpr
  );

  const saveData = saveModalData;
  const triggersaveModal = useSelector(
    (state: RootState) => state.tier1Add.saveDataOnclick
  );

  const updateIndInfos = updateModalData;
  const triggerupdateModal: boolean = useSelector(
    (state: RootState) => state.tier1Add.updateDataOnclick
  );

  const [trigger, setTrigger] = useState<boolean>(false);
  useImperativeHandle(ref, () => ({
    test: () => {
      setTrigger(!trigger);
    },
  }));

  const name = useSelector((state: RootState) => state.tier1Add.name);

  const shortname = useSelector((state: RootState) => state.tier1Add.shortname);

  const order = useSelector((state: RootState) => state.tier1Add.order);

  const active = useSelector((state: RootState) => state.tier1Add.active);

  const remarks = useSelector((state: RootState) => state.tier1Add.remarks);
  const selectedID = useSelector(
    (state: RootState) => state.general.SelectedFieldID
  );

  const selectedRecord = useSelector(
    (state: RootState) => state.general.selectedFieldData
  );

  const inputAfter0 = useSelector(
    (state: RootState) => state.tier1Add.inputAfter0
  );

  const inputAfter1 = useSelector(
    (state: RootState) => state.tier1Add.inputAfter1
  );

  const validateDataSave = () => {
    dispatch(tier1SaveOnclick(true));
  };

  const postData = async () => {
    dispatch(setValue({ expr: "saveDataOnclick", value: false }));
    setBusyLoader("Saving Record...");

    try {
      const res = await PostDataFunc(`AsmTmsExamNames/CreateAsmTmsExamName`, {
        exnName: name,
        exnShtName: shortname,
        exnDuration: inputAfter0,
        exnNoOfQuestions: inputAfter1,
        exnActive: active,
        exnRmks: remarks,
        exnOrder: order,
        formCode: "string",
        formAction: "string",
      });

      setBusyLoader("");
      setRefresh(!refresh);

      const exprs: exprsTypes[] = [
        { expr: "saveDataOnclick", value: false },
        { expr: "successModalState", value: true },
        { expr: "successText", value: saveSuccessText },
      ];
      for (let i = 0; i < exprs.length; i++) {
        dispatch(setValue({ expr: exprs[i].expr, value: exprs[i].value }));
      }
    } catch (error: any) {
      setBusyLoader("");

      setErrState(true);
      seterrTitle(postError.title);
      seterrMessage(postError.message);
    }
  };

  const validateDataUpdate = () => {
    dispatch(tier1UpdateOnclick(true));
  };

  const UpdateData = async () => {
    dispatch(setValue({ expr: "updateDataOnclick", value: false }));
    try {
      setBusyLoader("Updating Record...");
      const res = await UpdateDataFunc(
        `AsmTmsExamNames/UpdateAsmTmsExamName`,
        {
          exnIdpk: selectedID,
          exnName: name,
          exnShtName: shortname,
          exnActive: active,
          exnRmks: remarks,
          exnOrder: order,
          exnDuration: inputAfter0,
          exnNoOfQuestions: inputAfter1,
          // exnEditedBy: `${userID}`,
          exnEditedDate: currentDate,
          formCode: "string",
          formAction: "string",
        }
      );

      setRefresh(!refresh);
      setBusyLoader("");
      const exprs: exprsTypes[] = [
        { expr: "updateDataOnclick", value: false },
        { expr: "successModalState", value: true },
        { expr: "successText", value: updateSuccessText },
      ];
      for (let i = 0; i < exprs.length; i++) {
        dispatch(setValue({ expr: exprs[i].expr, value: exprs[i].value }));
      }
    } catch (error: any) {
      setErrState(true);
      seterrTitle(putError.title);
      seterrMessage(putError.message);
    }
  };

  const modals: modalTypes[] = [
    {
      disableCancel: true,
      icon: "warning",
      open: triggerwarningModal,
      okHandler: () =>
        dispatch(setValue({ expr: "warningState", value: false })),
      cancelHandler: () =>
        dispatch(setValue({ expr: "warningState", value: false })),
      message: warningInfos[warningDataExpr!].message,
      cancelText: warningInfos[warningDataExpr!].cancelText,
      okText: warningInfos[warningDataExpr!].okText,
      title: warningInfos[warningDataExpr!].title,
    },

    {
      disableCancel: true,
      icon: "warning",
      open: errState,
      okHandler: () => setErrState(false),
      cancelHandler: () => setErrState(false),
      message: errMessage,
      cancelText: "",
      okText: "Ok",
      title: errTitle,
    },

    {
      disableCancel: true,
      icon: "success",
      open: triggerSuccessModal,
      okHandler: () =>
        dispatch(setValue({ expr: "successModalState", value: false })),
      cancelHandler: () =>
        dispatch(setValue({ expr: "successModalState", value: false })),
      message: SuccessModalText.message,
      okText: SuccessModalText.okText,
      title: SuccessModalText.title,
    },

    {
      disableCancel: false,
      icon: "question",
      open: triggersaveModal,
      okHandler: () => postData(),
      cancelHandler: () =>
        dispatch(setValue({ expr: "saveDataOnclick", value: false })),
      message: saveData.message,
      cancelText: saveData.cancelText,
      okText: saveData.okText,
      title: saveData.title,
    },

    {
      disableCancel: false,
      icon: "question",
      open: triggerupdateModal,
      okHandler: () => UpdateData(),
      cancelHandler: () =>
        dispatch(setValue({ expr: "updateDataOnclick", value: false })),
      message: updateIndInfos.message,
      cancelText: updateIndInfos.cancelText,
      okText: updateIndInfos.okText,
      title: updateIndInfos.title,
    },
  ];

  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });
  const [status, setStatus] = useState<boolean | string>("");
  const activeStatus = useSelector(
    (state: RootState) => state.general.activeStatus
  );
  const [busyLoader, setBusyLoader] = useBusyLoader();

  const [data, error, loading] = useFetch(
    `AsmTmsExamNames/GetAllAsmTmsExamName?search=${searchText.text}&status=${status}`,
    refresh,
    "exnIdpk"
  );
  useEffect(() => {
    if (data) {
      storeExprs();
      dispatch(setGeneralValue({ expr: "idExpr", value: idExpr }));
      dispatch(setGeneralValue({ expr: "formData", value: data }));
    }

    loading ? setBusyLoader(".") : setBusyLoader("");
  }, [data, loading]);

  const form_header = "Exam Name Details";

  const inputs: inputsTypes[] = [
    {
      id: 0,
      label: "Duration (mins)",
      defaultValue: selectedRecord?.exnDuration,
      nameKey: "exnDuration",
    },
    {
      id: 1,
      label: "No of questions",
      defaultValue: selectedRecord?.exnNoOfQuestions,
      nameKey: "exnNoOfQuestions",
    },
  ];

  return (
    <>
      {error && <WarningAlert />}

      {/* modals */}
      {modals.map(
        (
          {
            disableCancel,
            icon,
            okHandler,
            cancelHandler,
            open,
            cancelText,
            okText,
            message,
            title,
          }: modalTypes,
          index
        ) => {
          return (
            <div key={index}>
              <ModalTemplate
                icon={icon && icon}
                disableCancel={disableCancel}
                cancelText={cancelText && cancelText}
                open={open}
                okHandler={okHandler}
                cancelHandler={cancelHandler}
                message={message}
                okText={okText}
                title={title}
              />
            </div>
          );
        }
      )}

      <Setups_Template
        searchTextChange={(newText) => {
          setSearchText({ temp: newText, text: searchText.text });
        }}
        handleSearch={() => {
          setSearchText({ temp: searchText.temp, text: searchText.temp });
          setStatus(activeStatus);
        }}
        handleRefresh={() => {
          setRefresh(!refresh);
          setSearchText({ temp: "", text: "" });
        }}
        labelCol={5}
        createData={validateDataSave}
        updateData={validateDataUpdate}
        formTier="1"
        trigger={trigger}
        datagridColumns={safety_setups_examsNamesColumns}
        datagridData={data}
        formHeader={form_header}
        inputsAfterConstants={inputs}
        datagridClicked={(e) => { }}
      />
    </>
  );
});
