export const vehicle_request_priviledges_TH = [
  {
    id: 0,
    caption: "VEH. REG No",
    dataField: "name",
    alignment: "left",
    fixed: true,
    width: 100,
  },
  {
    id: 1,
    caption: "MOB. No",
    dataField: "shortName",
    alignment: "left",
    fixed: true,
    width: 100,
  },

  {
    id: 2,
    caption: "SIM VENDOR",
    dataField: "code",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 3,
    caption: "TRACKER VENDOR",
    dataField: "towerType",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 4,
    caption: "TRACKER URL",
    dataField: "active",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 5,
    caption: "START DATE",
    dataField: "height",
    alignment: "left",
    fixed: true,
    width: null,
  },

  {
    id: 6,
    caption: "END DATE",
    dataField: "footing",
    alignment: "left",
    fixed: true,
    width: null,
  },

  {
    id: 7,
    caption: "ACTIVE",
    dataField: "insulatorType",
    alignment: "left",
    fixed: true,
    width: 60,
  },

  {
    id: 8,
    caption: "REMARKS",
    dataField: "remarks",
    alignment: "left",
    fixed: true,
    width: 4000,
  },
];

export const vehicle_request_priviledges_data = [];
