

export const loginDetailsCol = [
    { id: 0, caption: "STAFF No", dataField: "empStaffNo", dataType: "", fixed: true, visible: true, width: 120, },
    { id: 1, caption: "STAFF NAME", dataField: "empName2", dataType: "", fixed: true, visible: true, width: 120, },
    { id: 2, caption: "USERNAME", dataField: "usaUsername", dataType: "", fixed: true, visible: true, width: 120, },
    { id: 3, caption: "JOB TITLE", dataField: "jbtShtName", dataType: "", fixed: true, visible: true, width: 120, },
    { id: 4, caption: "LOGIN DATE", dataField: "ldtLoginDate", dataType: "datetime", fixed: false, visible: true, width: 120, },
    { id: 5, caption: "LOGOUT DATE", dataField: "ldtLogoutDate", dataType: "datetime", fixed: false, visible: true, width: 120, },
    { id: 6, caption: "HOST NAME", dataField: "ldtHostname", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 7, caption: "STAFF?", dataField: "empIsStaff", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 8, caption: "EMP ACTV?", dataField: "empActive", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 9, caption: "ACTV ACNT?", dataField: "usaActive", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 10, caption: "IP ADDRESS", dataField: "ldtIP", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 11, caption: "APP VERSION", dataField: "ldtAppVersion", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 12, caption: "LOGGEDIN", dataField: "ldtLoggedIn", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 13, caption: "DEPT", dataField: "dptShtName", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 14, caption: "SECTION", dataField: "sxnShtName", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 15, caption: "LAST APP BUILD DATE", dataField: "ldtLastAppBuildDate", dataType: "datetime", fixed: false, visible: true, width: 120, },
    { id: 16, caption: "OS NAME", dataField: "ldtOsName", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 17, caption: "OS PLATFORM", dataField: "ldtOsPlatform", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 18, caption: "OS VERSION", dataField: "ldtOsVersion", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 19, caption: "TOT PHYSICAL MEM", dataField: "ldtTotalPhysicalMemory", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 20, caption: "TOT VIRTUAL MEM", dataField: "ldtTotalVirtualMemory", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 21, caption: "AVAIL PHYSICAL MEM", dataField: "ldtAvailablePhysicalMemory", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 22, caption: "AVAIL VIRTUAL MEM", dataField: "ldtAvailableVirtualMemory", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 23, caption: "REMARKS", dataField: "", dataType: "", fixed: false, visible: true, width: 40000, },
]

export const auditSumCol = [
    { id: 0, caption: "STAFF No", dataField: "empStaffNo", dataType: "", fixed: true, visible: true, width: 120, },
    { id: 1, caption: "STAFF NAME", dataField: "empName2", dataType: "", fixed: true, visible: true, width: 120, },
    { id: 2, caption: "USERNAME", dataField: "usaUsername", dataType: "", fixed: true, visible: true, width: 120, },
    { id: 3, caption: "COUNT", dataField: "", dataType: "", fixed: true, visible: true, width: 120, },
    { id: 4, caption: "JOB TITLE", dataField: "jbtShtName", dataType: "", fixed: true, visible: true, width: 120, },
    { id: 5, caption: "STAFF?", dataField: "empIsStaff", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 6, caption: "EMP ACTIVE?", dataField: "empActive", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 7, caption: "ACNT ACTIVE?", dataField: "usaActive", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 8, caption: "FIRST LOGIN", dataField: "ldtLoginDate", dataType: "datetime", fixed: false, visible: true, width: 120, },
    { id: 9, caption: "LAST LOGIN", dataField: "ldtLogoutDate", dataType: "datetime", fixed: false, visible: true, width: 120, },
    { id: 10, caption: "DEPT", dataField: "dptShtName", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 11, caption: "SECTION", dataField: "sxnShtName", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 12, caption: "LOCATION", dataField: "locName", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 13, caption: "REMARKS", dataField: "", dataType: "", fixed: false, visible: true, width: 40000, },
]

export const auditDetCol = [
    { id: 0, caption: "STAFF No", dataField: "empStaffNo", dataType: "", fixed: true, visible: true, width: 120, },
    { id: 1, caption: "STAFF NAME", dataField: "empName2", dataType: "", fixed: true, visible: true, width: 120, },
    { id: 2, caption: "USERNAME", dataField: "usaUsername", dataType: "", fixed: true, visible: true, width: 120, },
    { id: 3, caption: "JOB TITLE", dataField: "jbtShtName", dataType: "", fixed: true, visible: true, width: 120, },
    { id: 4, caption: "FORM", dataField: "afmName", dataType: "", fixed: true, visible: true, width: 120, },
    { id: 5, caption: "DATE", dataField: "algCreationDate", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 6, caption: "IS STAFF?", dataField: "empIsStaff", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 7, caption: "EMP ACTIVE?", dataField: "empActive", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 8, caption: "ACTV ACNT?", dataField: "usaActive", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 9, caption: "FORM CODE", dataField: "", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 10, caption: "DEPT", dataField: "dptShtName", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 11, caption: "SECTION", dataField: "sxnShtName", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 12, caption: "ACTION", dataField: "", dataType: "", fixed: false, visible: true, width: 120, },
]

export const loginDetailSumCol = [
    { id: 0, caption: "STAFF No", dataField: "empStaffNo", dataType: "", fixed: true, visible: true, width: 120, },
    { id: 1, caption: "STAFF NAME", dataField: "empName2", dataType: "", fixed: true, visible: true, width: 120, },
    { id: 2, caption: "USERNAME", dataField: "usaUsername", dataType: "", fixed: true, visible: true, width: 120, },
    { id: 3, caption: "JOB TITLE", dataField: "jbtShtName", dataType: "", fixed: true, visible: true, width: 120, },
    { id: 4, caption: "IS STAFF?", dataField: "empIsStaff", dataType: "boolean", fixed: true, visible: true, width: 120, },
    { id: 5, caption: "EMP ACTIVE?", dataField: "empActive", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 6, caption: "ACNT ACTV?", dataField: "usaActive", dataType: "boolean", fixed: false, visible: true, width: 120, },
    { id: 7, caption: "FIRST LOGIN", dataField: "ldtLoginDate", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 8, caption: "LAST LOGIN", dataField: "ldtLogoutDate", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 9, caption: "IP ADDRESS", dataField: "ldtIP", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 10, caption: "APP VERSION", dataField: "ldtAppVersion", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 11, caption: "DEPT", dataField: "dptShtName", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 12, caption: "SECTION", dataField: "sxnShtName", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 13, caption: "LOCATION", dataField: "locName", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 14, caption: "HOST NAME", dataField: "ldtHostname", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 15, caption: "APP BUILD DATE", dataField: "ldtLastAppBuildDate", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 16, caption: "OS NAME", dataField: "ldtOsName", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 17, caption: "OS PLATFORM", dataField: "ldtOsPlatform", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 18, caption: "OS VERSION", dataField: "ldtOsVersion", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 19, caption: "TOT PHYSICAL MEM", dataField: "ldtTotalPhysicalMemory", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 20, caption: "TOT VIRTUAL MEM", dataField: "ldtTotalVirtualMemory", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 21, caption: "AVAIL PHYSICAL MEM", dataField: "ldtAvailablePhysicalMemory", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 22, caption: "AVAIL VIRTUAL MEM", dataField: "ldtAvailableVirtualMemory", dataType: "", fixed: false, visible: true, width: 120, },
    { id: 23, caption: "REMARKS", dataField: "", dataType: "", fixed: false, visible: true, width: 40000, },
]