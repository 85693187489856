import { Form, Select } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import useFetch from "../../../../hooks/useFetch";
import {
  navbar_height,
  toolbar_height,
} from "../../../accessories/component_infos";
import { formPropsConst } from "../../../accessories/constants";
import Datagrid_template from "../../../../templates/Datagrid";
import { other_details_TH } from "../data/other-details-data";
interface props {
  toggled?: boolean;
}

const OtherParameters = ({ toggled }: props) => {
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  interface props {
    toggled?: boolean;
  }
  const sp = "\u00a0";

  const heights_out = navbar_height + news_flash_height + toolbar_height;

  //varying form height
  const form_height = 490;

  //Table Height
  const pageHeight = window.innerHeight - navbar_height; //height without navbar
  const [tableHeight, settableHeight] = useState(
    window.innerHeight - (heights_out + 70)
  ); //height without navbar and toolbar
  const [table_form_Height, settable_form_Height] = useState(
    window.innerHeight - heights_out
  ); //height without navbar and toolbar and form

  const [showTable, setShowTable] = useState(true);
  const dateFormat = " DD MMM YYYY HH: mm:ss";

  // Fetching endpoints
  // const [otherParams_data, otherParams_error, otherParams_loading] = useFetch(
  //   "api/AssetTamEntTransformerDetail/GetAssetTamLueOtherParams"
  // );
  const [otherParams_data] = useFetch(
    `TransformerDetails/PopGdcOtherTransformerParameters?olpLineDetailsIDfk=1`,
    false,
    undefined,
    undefined,
    "powerapp"
  );

  // useEffect(() => {
  //     console.log(`inside ${toggled}`)
  // }, [toggled])

  const { Option } = Select;
  return (
    <div>
      {
        <div className="  rounded-md">
          {/* The form */}
          <div className=" flex ">
            <Form
              {...formPropsConst}
              labelCol={{ span: 1 }}
              wrapperCol={{ span: 1 }}
              className=" border-l-2 border-r-2 "
              style={{ width: "100%" }}
            >
              <div className=" border-t-2  ">
                <div className="text-xs bg-slate-100 rounded-t-sm py-1 px-2">
                  Additional Parameter Details
                </div>
                <Datagrid_template
                  gridheight={250}
                  showColumnHeaders={true}
                  columns={other_details_TH}
                  data={[]}
                  disablePaging
                  disableSearch
                  disableGroupPanel
                />
              </div>
            </Form>

                          
          </div>
        </div>
      }
    </div>
  );
};

export default OtherParameters;
