import { useEffect, useState } from "react";
import "devextreme/dist/css/dx.light.css";
import { Tooltip } from "devextreme-react";
import alter from "../../../../../../../assets/alter.png";
import edit from "../../../../../../../assets/edit.png";
import newfile from "../../../../../../../assets/new.png";
import check from "../../../../../../../assets/correct.png";
import bulkAssignment from "../../../../../../../assets/delete-batch.png";
import staffDetails from "../../../../../../../assets/man.png";
import statusLoader from "../../../../../../../assets/statusloader.gif";

import close from "../../../../../../../assets/close.png";
import printer from "../../../../../../../assets/printer.png";
import reset from "../../../../../../../assets/refresh-small.png";
import { Checkbox, DatePicker, Input, Select } from "antd";
import {
  DeleteTask,
  TaskSettings,
  AlertSMS,
  AppraiseSettings,
} from "../../../../../../accessories/custom-toolbars/widgets/customToolbarModals";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import { setGeneralValue } from "../../../../../../../features/generalSlice";
import { currentDate, saveModalData } from "../../../../../../accessories/component_infos";
import { FaBinoculars } from "react-icons/fa";
import { ModalTemplate } from "../../../../../../../templates/modal";
import dayjs from "dayjs";
import { FormModal } from "../../../../../../accessories/formModal";
import BulkAssignmentTermination from "../../../Priviledges-Assignments/BulkAssignmentTermination/widgets/bulk-assignment-termination";
import { TransportStaffDetails } from "../../../staffDetails/transportStaffDetails";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";

// ----------------Eagle Mentality🦅 -------------------------------------------------------------------------------
import {
  setNew_Cancel_Button,
  setEdit_Cancel_Button,
  setSave_Update_Button,
  setDisableForm,
} from "../../../../../../../features/VehicleRedux/TMS-Sicers/Vehicles/VehicleAssignmentSlice";
import { DeleteDataFunc } from "../../../../../../../functions/delete";
import { useCrudFunc } from "../../../../../../../functions/crud";
// ---------------------------------------------------------------------------------------------------------------------------
interface props {
  toggler?: () => void;
  handleDelete?: () => void; //delete handler
  handleNew?: (value: boolean) => void;
  searchCriteriaOnchange?: (index: number, criteria: string) => void;
  handleSave?: () => void; //save handler
  handleUpdate?: () => void; //update handler
  searchTextOnchange?: (value: string) => void; //search text change
  startDateChanged?: (value: any) => void; //start date change
  endDateChanged?: (value: any) => void; //end date change
  checkOnChange?: (value: any) => void; //checkbox change
  deleteCheckOnchange?: (value: any) => void;
  handleFind?: () => void; //find handler
  handleRefresh?: () => void; //refresh main grid
  handlePrint?: () => void; // handle print functionality
  useCallBackFunc?: boolean;
  withDates?: boolean; //add dates to the toolbar?
  toggleNew?: boolean;
  toggleUpdate?: boolean;
  handleEdit?: (value: boolean) => void;
  switchState?: (value: boolean) => void;
  searchCriteriaData?: { id: number; value: string }[];
  searchCriteria?: boolean;
  startDate?: string;
  endDate?: string;
  addedSelected?: any;
  allStates?: any;
  setAllState?: (e: any) => void;
  setAddSelected?: (e: any) => void;
  setUpdateId?: (e: any) => void;
  outerSelected?: any;
  setOuterSelected?: (val: any) => void;
  setShowDataOnSecondGrid?:(val:any)=>void;
  setVisible?: () => void;
}

export const VehicleAssignmentsToolbar = ({
  toggler,
  handleRefresh,
  handlePrint,
  handleFind,
  checkOnChange,
  switchState,
  startDateChanged,
  endDateChanged,
  searchTextOnchange,
  searchCriteriaOnchange,
  deleteCheckOnchange,
  searchCriteria,
  searchCriteriaData = [],
  startDate,
  endDate,
  addedSelected,
  allStates,
  setAllState,
  setAddSelected,
  setUpdateId,
  outerSelected,
  setOuterSelected,
  setShowDataOnSecondGrid,
  setVisible,
}: props) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [SettingsisActive, SettingssetIsActive] = useState<boolean>(false);
  const [AlertSMSisActive, AlertSMSsetIsActive] = useState<boolean>(false);
  const [AppraiseisActive, AppraisesetIsActive] = useState<boolean>(false);
  const [openBulkAssignment, setOpenBulkAssignment] = useState<boolean>(false);
  const [openTransportStaff, setOpenTransportStaff] = useState<boolean>(false);
  const [searchTextValue, setSearchTextValue] = useState("");
  console.log('searchTextValue from  toolbar file :', searchTextValue);
  const size: number = 16;
  const colorTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  const selectedRecord = useSelector(
    (state: RootState) => state.general.selectedAssignment
  );

  // ---------------------------- Eagle Mentality🦅   -------------------------------------

  let NewAndCancelButton = useSelector(
    (state: RootState) => state.TMSModule1.VehicleAssignment.New_Cancel_Button
  );
  let EditAndCancelButton = useSelector(
    (state: RootState) => state.TMSModule1.VehicleAssignment.Edit_Cancel_Button
  );
  const Vdispatch = useDispatch();
  // ---------------------------------------------------------------------------------------

  const [userId, employID, userObj] = useAccountInfo();

  const [privType, setPrivType] = useState<string>("save");

  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);

  const resetField = () => {
    setAllState!((prevState: any) => ({
      ...prevState,
      ["startDate"]: currentDate,
      ["endDate"]: currentDate,
      ["department"]: { name: "", id: "" },
      ["section"]: { name: "", id: "" },
      ["unit"]: { name: "", id: "" },
      ["transportUnit"]: { name: "", id: "" },
      ["location"]: { name: "", id: "" },
      ["approvingOfficer"]: { name: "", id: "" },
      ["driver"]: { name: "", id: "" },
      ["vehicleController"]: { name: "", id: "" },
      ["administrator"]: { name: "", id: "" },
      ["manager"]: { name: "", id: "" },
      ["director"]: { name: "", id: "" },
      ["defaultWorkshop"]: { name: "", id: "" },
      ["remarks"]: "",
      ["active"]: true,
      ["footerId"]: { id: "" },
    }));
    setUpdateId!("");
    setAddSelected!([]);
    setOuterSelected && setOuterSelected({});
    setShowDataOnSecondGrid&&setShowDataOnSecondGrid([])
    // setUpdateId!("");
    // setAddSelected!([]);
  };

  const AccessPriv_on_NewButton = () => {
    if (savePriv === true) {
      switchState && switchState(false);
      setVisible && setVisible();
      // -------------------eagleMentality🦅------------------------------
      Vdispatch(setNew_Cancel_Button(false));
      Vdispatch(setEdit_Cancel_Button(true));
      Vdispatch(setDisableForm(false));
      resetField();
      //---- -------------------------------------------------------------
      //clear data to redux
      dispatch(
        setGeneralValue({ expr: "selectedFieldData", value: undefined })
      );
    } else {
      setIcon("warning");
      setshowModalDisableButton(true);
      setshowCancel(false);
      setModalData({
        message: "No Access Privilege",
        title: "Access Denied!",
        okText: "Ok",
      });
      setOpenModal(true);
    }
  };
  const AccessPriv_on_EditButton = (selectedRecord: any) => {
    console.log('selectedRecord:',selectedRecord)
    setShowDataOnSecondGrid && setShowDataOnSecondGrid([selectedRecord])
    setPrivType("read");
    if (updatePriv === true) {
      setVisible && setVisible();
      var SelectedData: any = selectedRecord;
      try {
        if (Object.keys(SelectedData).length < 1) {
          setIcon("warning");
          setshowModalDisableButton(true);
          setshowCancel(false);
          setModalData({
            message: "Please select record to edit",
            title: "No record selected",
            okText: "Ok",
          });
          setOpenModal(true);
          //----------eagleMentality🦅------------
          Vdispatch(setDisableForm(true));
          Vdispatch(setEdit_Cancel_Button(true));
          Vdispatch(setSave_Update_Button(true));
          Vdispatch(setNew_Cancel_Button(true));
          //---------------------------------------
        } else {
          //----------eagleMentality🦅------------
          Vdispatch(setDisableForm(false));
          Vdispatch(setEdit_Cancel_Button(false));
          Vdispatch(setSave_Update_Button(false));
          Vdispatch(setNew_Cancel_Button(true));
          //---------------------------------------
        }
      } catch (e) {
        setIcon("warning");
        setshowModalDisableButton(true);
        setshowCancel(false);
        setModalData({ message: e, title: "Invalid record", okText: "Ok" });
        setOpenModal(true);
      }
    } else {
      setIcon("warning");
      setshowModalDisableButton(true);
      setshowCancel(false);
      setModalData({
        message: "No Access Privilege",
        title: "Access Denied!",
        okText: "Ok",
      });
      setOpenModal(true);
    }
  };

  const AccessPriv_on_FindButton = () => {
    setPrivType("read");
    if (readPriv === true) {
      handleFind && handleFind();
    } else {
      setIcon("warning");
      setshowModalDisableButton(true);
      setshowCancel(false);
      setModalData({
        message: "No Access Privilege",
        title: "Access Denied!",
        okText: "Ok",
      });
      setOpenModal(true);
    }
  };
  const AccessPriv_on_PrintButton = () => {
    if (savePriv === true) {
      handlePrint && handlePrint();
    } else {
      setIcon("warning");
      setshowModalDisableButton(true);
      setshowCancel(false);
      setModalData({
        message: "No Access Privilege",
        title: "Access Denied!",
        okText: "Ok",
      });
      setOpenModal(true);
    }
  };

  const AccessPriv_on_BulkAssignment = () => {
    setPrivType("read");
    if (readPriv === true) {
      setOpenBulkAssignment(true);
    } else {
      setIcon("warning");
      setshowModalDisableButton(true);
      setshowCancel(false);
      setModalData({
        message: "No Access Privilege",
        title: "Access Denied!",
        okText: "Ok",
      });
      setOpenModal(true);
      setOpenBulkAssignment(false);
    }
  };
  const AccessPriv_on_StaffDetails = () => {
    if (readPriv === true) {
      setOpenTransportStaff(true);
    } else {
      setIcon("warning");
      setshowModalDisableButton(true);
      setshowCancel(false);
      setModalData({
        message: "No Access Privilege",
        title: "Access Denied!",
        okText: "Ok",
      });
      setOpenModal(true);
      setOpenTransportStaff(false);
    }
  };

  const activeAssignment = useSelector(
    (state: RootState) => state.general.activeAssignment
  );
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  

  // relative to the new button
  const handleNew = () => {
    AccessPriv_on_NewButton();
  };

  const handleCancel = () => {
    dispatch(setGeneralValue({ expr: "updateMode", value: false }));

    //----------------eagleMentality🦅----------------------------------------
    Vdispatch(setNew_Cancel_Button(true));
    Vdispatch(setDisableForm(true));
    Vdispatch(setEdit_Cancel_Button(true));
    Vdispatch(setSave_Update_Button(true));
    //---------------------------------------------------------------

    // NO ONE SHOULD DELETE THIS !!!!
    // handleCallbackCancel!();
  };

  const [criteriaDataLocal, setCriterialDataLocal] = useState({
    id: searchCriteriaData![0]?.id ?? 0,
    value: searchCriteriaData![0]?.value ?? "",
  });

  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [updatingstatus, setUpdatingstatus] = useState(false);
  const [showCancel, setshowCancel] = useState(false);
  const [Active, setActive] = useState(false);
  const [refreshx, setRefreshx] = useState(false);
  const dispatch = useDispatch();
  const form_disable = useSelector(
    (state: RootState) => state.general.formDisabled
  );

  //handle edit
  // const handleUpdate = (selected) => {
  //   AccessPriv_on_EditButton(outerSelected);
  // };

  // Three state checkboxes
  const [indeterminate, setIndeterminate] = useState<any>(true);
  const [checkedBox, setCheckedBox] = useState<any>(false);
  const [checkValue, setCheckValue] = useState<boolean | string>(""); //actual value to use
  const [searchText, setSearchText] = useState("");

  const [D_indeterminate, setD_indeterminate] = useState<any>(true);
  const [D_checkedBox, setD_checkedBox] = useState<any>(false);
  const [D_checkValue, setD_checkValue] = useState<boolean | string>("");

  const onChange = () => {
    if (indeterminate === true) {
      setCheckedBox(true);
      setIndeterminate(false);
      setCheckValue(true);
    } else if (!indeterminate && checkedBox) {
      setCheckedBox(false);
      setIndeterminate(false);
      setCheckValue(false);
    } else if (!indeterminate && !checkedBox) {
      setCheckedBox(false);
      setIndeterminate(true);
      setCheckValue("");
    }
  };
  const D_onChange = () => {
    if (D_indeterminate === true) {
      setD_checkedBox(true);
      setD_indeterminate(false);
      setD_checkValue(true);
    } else if (!D_indeterminate && D_checkedBox) {
      setD_checkedBox(false);
      setD_indeterminate(false);
      setD_checkValue(false);
    } else if (!D_indeterminate && !D_checkedBox) {
      setD_checkedBox(false);
      setD_indeterminate(true);
      setD_checkValue("");
    }
  };

  const DeleteFunc = async (selected: any) => {
    if (Object.keys(selected).length === 0) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setshowCancel(false);
      setModalData({
        message: "Please Selected a Record",
        title: "No Record Selected",
        okText: "Ok",
      });
    } else {
      try {
        await DeleteDataFunc(
          `AssetTamVehicleAssignment/DeleteAndAssign?VasID=${selected?.vasIDpk}&vasDeleted=${selected?.vasDeleted}`,// vasActive
        )
          .then(() => {
            // try {
              setIcon("success");
              setshowModalDisableButton(true);
              setshowCancel(false);
              setModalData({
                message: `Record ${
                  activeAssignment === true ? "deleted" : "activited"
                } successfully`,
                title: "Deleted",
                okText: "Ok",
              });
              setOpenModal(true);
              handleRefresh && handleRefresh();
            // } catch {}
          })
          .catch(() => {
            setIcon("warning");
            setshowModalDisableButton(true);
            setshowCancel(false);
            setModalData({
              message: `Record not ${
                activeAssignment === true ? "deleted" : "activited"
              }`,
              title: "Please contact systems administrator",
              okText: "Ok",
            });
            setOpenModal(true);
          });
      } catch (err) {
        setIcon("warning");
        setshowModalDisableButton(true);
        setshowCancel(false);
        setModalData({
          message: `Record not ${
            activeAssignment === true ? "deleted" : "activited"
          }`,
          title: "Please contact systems administrator",
          okText: "Ok",
        });
        setOpenModal(true);
      }
    }
  };
  useEffect(() => {
    checkOnChange && checkOnChange(checkValue);
    deleteCheckOnchange && deleteCheckOnchange(D_checkValue);
  }, [checkValue, D_checkValue]);

  // useEffect(() => {
  //   checkOnChange && checkOnChange(checkValue);
  // }, [checkValue]);

  return (
    <div
      style={{
        borderWidth: "1px",
        height: 32,
        borderColor: borderTheme,
        width: "4000px",
      }}
      className="px-0.5 mt-0.5 rounded  w-full h-full flex  border-gray-200 mb-1"
    >
      <FormModal
        openState={openBulkAssignment}
        child={<BulkAssignmentTermination />}
        title={"TMS-Bulk Assignment Termination"}
        onCancel={() => {
          setOpenBulkAssignment(false);
        }}
      />
      <FormModal
        width={1200}
        openState={openTransportStaff}
        child={<TransportStaffDetails />}
        title={"Asset Mgr-Transport-Staff Details"}
        onCancel={() => {
          setOpenTransportStaff(false);
        }}
      />
      <div className="">
        <ModalTemplate
          icon_width={modalData.iconSize === "large" ? 100 : 50}
          icon={icon}
          cancelText={modalData.cancelText}
          disableCancel={!showCancel}
          cancelHandler={() => {
            setOpenModal(false);
          }}
          open={openModal}
          okHandler={() => {
            icon === "question"
              ? DeleteFunc(selectedRecord)
              : setOpenModal(false);
          }}
          message={modalData.message}
          okText={modalData.okText}
          title={modalData.title}
        />
        <ul className="flex justify-evenly w-full items-center h-full py-0.5">
          {NewAndCancelButton === true ? (
            <li
              id="newRecord"
              onClick={() => handleNew()}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img className="pr-1 " src={newfile} />
                New{" "}
              </span>
              <Tooltip
                target="#newRecord"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">New Record</p>
              </Tooltip>
            </li>
          ) : (
            <li
              id="close-cancel"
              onClick={() => handleCancel()}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img className="pr-1 " src={close} />
                Cancel{" "}
              </span>
              <Tooltip
                target="#close-cancel"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Cancel</p>
              </Tooltip>
            </li>
          )}

          {EditAndCancelButton === true ? (
            <li
              id="editRecordst"
              onClick={() => {
                AccessPriv_on_EditButton(outerSelected);
              }}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img
                  className="dark:bg-slate-900 pr-1 dark:text-gray-100 duration-100"
                  src={edit}
                />
                Edit{" "}
              </span>
              <Tooltip
                target="#editRecordst"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Edit Record</p>
              </Tooltip>
            </li>
          ) : (
            <li
              id="close-cancel"
              onClick={() => handleCancel()}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img className="pr-1 " src={close} />
                Cancel{" "}
              </span>
              <Tooltip
                target="#close-cancel"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Cancel</p>
              </Tooltip>
            </li>
          )}
          <li
            id="switch"
            onClick={toggler}
            style={{
              borderWidth: "1px",
              borderColor: borderTheme,
              width: "70px",
            }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
          >
            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
              {" "}
              <img
                className="dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"
                src={alter}
              />
              Switch{" "}
            </span>
            <Tooltip
              target="#switch"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Switch interface / table view</p>
            </Tooltip>
          </li>
          {/* {activeAssignment === true ? ( */}
          {outerSelected?.vasDeleted === false ? (
            <li
              id="deleteGrey"
              style={{
                borderWidth: "1px",
                borderColor: borderTheme,
                width: "35px",
              }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <img
                onClick={() => {
                  setIcon("question");
                  setshowModalDisableButton(true);
                  setshowCancel(true);
                  setModalData({
                    message: "Are you sure you want to delete this record ?",
                    title: "Delete record",
                    okText: "Yes, Delete",
                  });
                  setOpenModal(true);
                }}
                className="dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"
                src={close}
              />
              <Tooltip
                target="#deleteGrey"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Delete Assignment</p>
              </Tooltip>
            </li>
          ) : (
            <li
              id="check"
              style={{
                borderWidth: "1px",
                borderColor: borderTheme,
                width: "35px",
              }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <img
                onClick={() => {
                  setIcon("question");
                  setshowModalDisableButton(true);
                  setshowCancel(false);
                  setModalData({
                    message:
                      "Are you sure you want to set this record to active ?",
                    title: "Activate record?",
                    okText: "Yes",
                  });
                  setOpenModal(true);
                }}
                className="dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"
                src={check}
              />
              <Tooltip
                target="#check"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">check</p>
              </Tooltip>
            </li>
          )}

          <li
            id="bulkAssign"
            style={{
              borderWidth: "1px",
              borderColor: borderTheme,
              width: "35px",
            }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
          >
            <img
              className="dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"
              src={bulkAssignment}
              onClick={() => {
                AccessPriv_on_BulkAssignment();
              }}
            />
            <Tooltip
              target="#bulkAssign"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p>Bulk assignment</p>
            </Tooltip>
          </li>

          <li
            id="staffDetails"
            style={{
              borderWidth: "1px",
              borderColor: borderTheme,
              width: "35px",
            }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1 text-gray-600 rounded flex items-center"
          >
            <img
              className="dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"
              src={staffDetails}
              onClick={() => { 
                AccessPriv_on_StaffDetails();
              }}
            />
            <Tooltip
              target="#staffDetails"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p>Staff details</p>
            </Tooltip>
          </li>
          <li className="px-1 flex items-center flex-row">
            <label style={{ width: "87px" }} className=" text-xs">
              Search Criteria
            </label>
            <Select
              value={criteriaDataLocal}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ cursor: "default" }}
              className=" mt-0.5 cursor-pointer"
              onChange={(data: any) => {
                const criteriaData = JSON.parse(data);
                setCriterialDataLocal({
                  id: criteriaData?.index,
                  value: criteriaData?.criteria,
                }); //local
                searchCriteriaOnchange &&
                  searchCriteriaOnchange(
                    criteriaData?.index,
                    criteriaData?.criteria
                  ); //external
              }}
              id="search"
              size="small"
              showSearch={true}
              style={{ width: 110, height: "25px", cursor: "pointer" }}
            >
              {searchCriteriaData?.map(({ id, value }, index) => (
                <Select.Option
                  key={id}
                  value={JSON.stringify({ index: index, criteria: value })}
                >
                  {value}
                </Select.Option>
              ))}
            </Select>
          </li>
        </ul>
      </div>

      <div className="flex">
        <ul className="ml-2 flex items-center">
          <li className="px-1 flex items-center flex-row ml-1 ">
            <label style={{ width: "70px" }} className=" text-xs">
              Search Text
            </label>
            <Input
              onChange={(e: any) => {
                // const searchText: string = e.target.value;
                searchTextOnchange && searchTextOnchange(e.target.value);
                setSearchTextValue(e.target.value);
              }}
              value={searchTextValue}
              name="search"
              id="search"
              size="small"
              allowClear={true}
              style={{ width: 130, height: "24px" }}
            />
          </li>

          <li className="px-0.5">
            <DatePicker
              value={dayjs(startDate) ?? dayjs()}
              clearIcon={<span style={{ display: "none" }}></span>}
              onChange={(e) => {
                const hold = dayjs(e).format("YYYY-MM-DD");
                startDateChanged!(hold);
              }}
              size="small"
              placeholder="Start Date"
              style={{ width: 125 }}
              format={"DD MMM YYYY"}
            />
          </li>
          <li className="px-0.5">
            <DatePicker
              value={dayjs(endDate) ?? dayjs()}
              clearIcon={<span style={{ display: "none" }}></span>}
              onChange={(e) => {
                const hold = dayjs(e).format("YYYY-MM-DD");
                endDateChanged!(hold);
              }}
              size="small"
              placeholder="End Date"
              style={{ width: 125 }}
              format={"DD MMM YYYY"}
            />
          </li>

          <li
            id="status"
            style={{
              borderWidth: "1px",
              paddingTop: 0.8,
              paddingBottom: 0.8,
              paddingLeft: 3.25,
              paddingRight: 3,
              borderColor: colorTheme,
            }}
            className="hover:cursor-pointer mr-1.5 ml-1  text-xs   text-gray-600 rounded flex items-center"
          >
            <Checkbox
              checked={checkedBox}
              value={checkValue}
              indeterminate={indeterminate}
              onChange={(checkValue: any) => {
                onChange();
              }}
            />
            <Tooltip
              target="#status"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Active Status</p>
            </Tooltip>
          </li>

          <li
            id="Dstatus"
            style={{
              borderWidth: "1px",
              paddingTop: 0.8,
              paddingBottom: 0.8,
              paddingLeft: 3.25,
              paddingRight: 3,
              borderColor: colorTheme,
            }}
            className="hover:cursor-pointer mr-1.5 ml-1  text-xs   text-gray-600 rounded flex items-center"
          >
            <Checkbox
              checked={D_checkedBox}
              value={D_checkValue}
              indeterminate={D_indeterminate}
              onChange={(D_checkValue: any) => {
                D_onChange();
              }}
            />
            <Tooltip
              target="#Dstatus"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Delete Status</p>
            </Tooltip>
          </li>
          <li
            onClick={() => {
              AccessPriv_on_FindButton();
            }}
            id="findst"
            style={{ borderWidth: "1px", borderColor: colorTheme }}
            className="hover:cursor-pointer mr-1.5 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
          >
            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
              <FaBinoculars size={15.5} color="#007AFF" />
            </span>
            <Tooltip
              target="#findst"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Find</p>
            </Tooltip>
          </li>

          {/* refresh */}
          <li
            onClick={() => {
              handleRefresh && handleRefresh();
              searchTextOnchange && searchTextOnchange("");
              searchCriteriaOnchange &&
                searchCriteriaOnchange(
                  searchCriteriaData![0]?.id ?? 0,
                  searchCriteriaData![0]?.value ?? ""
                );
              checkOnChange && checkOnChange("");
              deleteCheckOnchange && deleteCheckOnchange("");
              setIndeterminate(true);
              setD_indeterminate(true);
              setCheckValue("");
              setD_checkValue("");
              //setlocal states
              setSearchTextValue("");
              setCriterialDataLocal({
                id: searchCriteriaData![0]?.id ?? 0,
                value: searchCriteriaData![0]?.value ?? "",
              });
            }}
            id="refreshst"
            style={{ borderWidth: "1px", borderColor: colorTheme }}
            className="hover:cursor-pointer mr-1.5 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
          >
            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
              {" "}
              <img
                className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                src={reset}
              />
            </span>
            <Tooltip
              target="#refreshst"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Refresh</p>
            </Tooltip>
          </li>

          <li
            onClick={() => AccessPriv_on_PrintButton()}
            id="printst"
            style={{ borderWidth: "1px", borderColor: colorTheme }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
          >
            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
              {" "}
              <img
                className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                src={printer}
              />
            </span>
            <Tooltip
              target="#printst"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Print/Export</p>
            </Tooltip>
          </li>

          <li className="hover:cursor-pointer ml-20  px-2  flex items-center">
            {EditAndCancelButton === true && NewAndCancelButton === true ? (
              <p className=" italic font-semibold text-gray-600">Done.</p>
            ) : EditAndCancelButton === true && NewAndCancelButton === false ? (
              <>
                {" "}
                <img
                  style={{ width: "31px", height: "31px" }}
                  src={statusLoader}
                  alt="gif"
                />
                <p className=" italic text-gray-600">New Record</p>
              </>
            ) : EditAndCancelButton === false && NewAndCancelButton === true ? (
              <>
                {" "}
                <img
                  style={{ width: "31px", height: "31px" }}
                  src={statusLoader}
                  alt="gif"
                />
                <p className=" italic text-gray-600">Editing</p>
              </>
            ) : (
              <p className=" italic font-semibold text-gray-600">Done.</p>
            )}
          </li>
        </ul>

        <DeleteTask isActive={isActive} setIsActive={setIsActive} />
        <TaskSettings
          SettingsisActive={SettingsisActive}
          SettingssetIsActive={SettingssetIsActive}
        />
        <AlertSMS
          AlertSMSisActive={AlertSMSisActive}
          AlertSMSsetIsActive={AlertSMSsetIsActive}
        />
        <AppraiseSettings
          AppraiseisActive={AppraiseisActive}
          AppraisesetIsActive={AppraisesetIsActive}
        />
      </div>
    </div>
  );
};

const data = [
  {
    label: "Registration No",
  },
  {
    label: "Chassis No",
  },
  {
    label: "Vehicle Type",
  },
  {
    label: "Vehicle Make",
  },
  {
    label: "Vehicle Model",
  },
  {
    label: "Manufacturer",
  },
  {
    label: "Supplier",
  },
  {
    label: "Manufacture Date",
  },
  {
    label: "Supply Date",
  },
];
