import React from 'react'
import TabsPanel_template from '../../../../templates/tabsPanel_template'
import Apa_r2_hr_sss from './apa_r2_hr_sss'

const Apa_right2_sss = () => {

    const tabs: { id: number, title: string, contents: any }[] = [
        { id: 0, title: "HR Training", contents: <Apa_r2_hr_sss /> },

    ]

    return (
        <div className='w-full'>
            <TabsPanel_template tabs={tabs} />

        </div>
    )
}

export default Apa_right2_sss