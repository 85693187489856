import { Checkbox, DatePicker, Form, Input, message, Select } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import axios from "axios";
import { title } from "process";
import { async } from "q";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import { setValue } from "../../../../../features/forms/tier1/createSlice";
import { setGeneralValue } from "../../../../../features/generalSlice";
import { CheckboxTemlate } from "../../../../../templates/checkbox";
import { InputsTemplate } from "../../../../../templates/input";
import { ModalTemplate } from "../../../../../templates/modal";
import { TextareaTemplate } from "../../../../../templates/textarea";
import {
  SaveButton,
  NewButton,
  Cancel,
  UpdateButton,
} from "../../../../accessories/buttons";
import { api_url, saveModalData, updateModalData } from "../../../../accessories/component_infos";
import SetupsDatalistTemplat from "../../../../human-resource/setups/data/setups_datalist_template";
import {
  datalistTypes,
  exprsTypes,
  modalPropTypes,
  selectsTypes,
} from "../../../../human-resource/setups/data/types/selectsTypes";
import dayjs from "dayjs";
import { UpdateDataFunc } from "../../../../../functions/update";
import { PostDataFunc  } from "../../../../../functions/post";
import { useBusyLoader } from "../../../../../hooks/useBusyLoader";
import { useAccountInfo } from "../../../../../hooks/useAccountInfo";

interface props {
  selectedItemData: any;
  refreshGrid: () => void
}
export const MonitoringModules_form = ({ selectedItemData, refreshGrid }: props) => {
  const dispatch = useDispatch()


  const borderTheme = useSelector((state:RootState)=>state.general.cssBorderClass)

  const handleNew = () => {
    populateFromGrid(true)
    dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
  }

  const [busyLoader, setBusyLoader] = useBusyLoader();
  const [employeeId, userId] = useAccountInfo()

  const disabled = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const updateMode = useSelector(
    (state: RootState) => state.general.updateMode
  );


  //--validation modal --
  const [validationModalState, setvalidationModalState] = useState<any>({
    state: false,
    title: '',
    message: '',
  });
  const setValidationModal = (state: string, value: string | boolean) => {
    setvalidationModalState((warningState: any) => ({ ...warningState, [state]: value }));
  }

  //--save modal--
  const [saveModalState, setsaveModalState] = useState<any>({
    state: false,
    title: '',
    message: '',
    okText: ''
  });

  //--success modal--
  const [succesModalState, setsuccesModalState] = useState<modalPropTypes>({
    state: false,
    title: '',
    message: '',
    okText: ''
  });
  const setModal = (state: string, value: string | boolean) => {
    setsaveModalState((modalState: any) => ({ ...modalState, [state]: value }))
  }

  // retrieving input fields data
  //datalist user inputs
  let [datalistData, setDatalistData]: any = useState({
    datalist0: "",
    datalist1: "",
  });

  //checkbox user inputs
  const [checkboxData, setCheckboxData]: any = useState({
    checkbox0: false,
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
    checkbox4: false,
    checkbox5: false,
    checkbox6: false,
    checkbox7: false,
  });

  //Order user inputs
  const [orderData, setOrderData] = useState<string | number>(0);

  //textarea user inputs
  const [textareaValue, setTextareaValue] = useState<string>("");

  //set checkbox value
  const setCheckboxes = (value: boolean, index: number) => {
    //set checkboxes data
    setCheckboxData((prevState: any) => ({
      ...prevState,
      [`checkbox${index}`]: value, datalist1: value
    }));
  };

  const datalists: selectsTypes[] = [
    { id: 0, label: "Name", optionsData: [{}], defaultValue: datalistData.datalist0 },
    { id: 1, label: "Short Name", optionsData: [{}], defaultValue: datalistData.datalist1 },
  ];

  useEffect(() => {
    populateFromGrid(false);
    setTextareaValue(selectedItemData?.rmmRmks)
  }, [selectedItemData])

  const populateFromGrid = (clearFields: boolean) => {
    // populate datalists
    const datalistSelected = [
      { datalistIndex: 0, value: selectedItemData?.rmmName ?? '' },
      { datalistIndex: 1, value: selectedItemData?.rmmShtName ?? '' },
    ]
    for (let index = 0; index < datalistSelected.length; index++) {
      setDatalists(clearFields ? '' : datalistSelected[index].value, datalistSelected[index].datalistIndex)
    }

    //populate checkboxes
    const checkBoxListSelected = [
      { checkbox: 0, value: selectedItemData?.rmmActive ?? false },
      { checkbox: 1, value: selectedItemData?.rmmPickFrequencyFromModule ?? false },
      { checkbox: 2, value: selectedItemData?.rmmHelpDesk },
      { checkbox: 3, value: selectedItemData?.rmmSingleAssignmentPerPeriod ?? false },
      { checkbox: 4, value: selectedItemData?.rmmTaskManager ?? false },
      { checkbox: 5, value: selectedItemData?.rmmSafetyManagerMeetingAttendance ?? false },
      { checkbox: 6, value: selectedItemData?.rmmHrLeaveManagement ?? false },
      { checkbox: 7, value: selectedItemData?.rmmHospManagerMedicalExams ?? false },
    ]

    for (let index = 0; index < checkBoxListSelected.length; index++) {
      setCheckboxes(clearFields === true && index == 0 ? true : clearFields ? false : checkBoxListSelected[index].value, checkBoxListSelected[index].checkbox)
    }

    clearFields ? setOrderData(0) : setOrderData(selectedItemData?.rmmOrder ?? 0)
  }

  const disableForm = () => {
    dispatch(setGeneralValue({ expr: 'formDisabled', value: true }));
    dispatch(setGeneralValue({ expr: 'updateMode', value: false }));
  }

  //set datalist value
  const setDatalists = (value: string | number, index: number) => {
    //set datalists value
    setDatalistData((prevState: any) => ({
      ...prevState,
      [`datalist${index}`]: value,
    }));

    index === 0 && setDatalistData((prevState: any) => ({
      ...prevState,
      [`datalist1`]: value,
    }));
  };

  const setOrder = (value: string | number) => {
    //set order value
    setOrderData(value);
  };

  const setTextarea = (value: string) => {
    //set order value
    setTextareaValue(value);
  };
  const [refresh, setRefresh] = useState<boolean>(false);

  //validate Data
  const ValidatePostData = () => {
    if (datalistData["datalist0"] === '') {
      setvalidationModalState({
        state: true,
        title: 'Empty Field',
        message: 'Please enter field data'
      })
    }
    else if (datalistData["datalist0"]?.length < 2) {
      setvalidationModalState({
        state: true,
        title: 'Module Detail Name can not be less than 2 characters.',
        message: 'Please enter field data'
      })
    }
    else if (datalistData["datalist1"] === '') {
      setvalidationModalState(
        {
          state: true,
          title: 'Short Name can not be Empty',
          message: 'Please enter field data'
        }
      )
    }
    else if (datalistData["datalist1"].length < 2) {
      setvalidationModalState({
        state: true,
        title: 'Module Detail Short Name can not be less than 2 characters.',
        message: 'Please enter field data'
      })
    }
    else if (checkboxData["checkbox0"] === false) {
      setvalidationModalState({
        state: true,
        title: 'Details will be invisible',
        message: 'Active checkbox Is not checked'
      })
    }
    else {
      setsaveModalState({
        state: true,
        title: 'Save Data?',
        message: 'Are you sure you want to save this record?',
        okText: 'Yes, Save'
      })
    }
  }
  const postData = async () => {
    //post request here
    setModal('state', false)
    try {
      setBusyLoader(saveModalData.progress);

      const res = PostDataFunc (
        `ResultMonitoring/CreateResultMonitoring`,
        {
          rmmName: datalistData["datalist0"],
          rmmShtName: datalistData["datalist1"],
          rmmActive: checkboxData["checkbox0"],
          rmmPickFrequencyFromModule: checkboxData["checkbox1"],
          rmmHelpDesk: checkboxData["checkbox2"],
          rmmSingleAssignmentPerPeriod: checkboxData["checkbox3"],
          rmmTaskManager: checkboxData["checkbox4"],
          rmmSafetyManagerMeetingAttendance: checkboxData["checkbox5"],
          rmmHrLeaveManagement: checkboxData["checkbox6"],
          rmmHospManagerMedicalExams: checkboxData["checkbox7"],
          rmmRmks: textareaValue,
          rmmOrder: orderData,
          rmmCreatedBy: userId.toString(),
        }
      );

      refreshGrid();

      setRefresh(!refresh);
      setsuccesModalState({
        state: true,
        message: 'Record saved successfully',
        title: 'Save Successful'
      })

      disableForm();
    } catch (error: any) {
      console.log(error);

      setvalidationModalState({
        state: true,
        title: saveModalData.error.title,
        message: saveModalData.error.message
      })
    } finally {
      setBusyLoader('');
    }
  };

  ///validating the updating field 
  const ValidatePutData = () => {
    if (datalistData["datalist0"] === '') {
      setvalidationModalState({
        state: true,
        title: 'Empty Field',
        message: 'Please enter field data'
      })
    }
    else if (datalistData["datalist0"].length < 2) {
      setvalidationModalState({
        state: true,
        title: 'Module Detail Name can not be less than 2 characters.',
        message: 'Please enter field data'
      })
    }
    else if (datalistData["datalist1"] === '') {
      setvalidationModalState(
        {
          state: true,
          title: 'Short Name can not be Empty',
          message: 'Please enter field data'
        }
      )
    }
    else if (datalistData["datalist1"].length < 2) {
      setvalidationModalState({
        state: true,
        title: 'Module Detail Short Name can not be less than 2 characters.',
        message: 'Please enter field data'
      })
    }
    else {
      setsaveModalState({
        state: true,
        title: 'Update Data?',
        message: 'Are you sure you want to update this record?',
        okText: 'Yes, Update'
      })
    }
  }

  const updateData = async () => {
    //update request here
    setModal('state', false)
    try {
      setBusyLoader(updateModalData.progress);

      const res = await UpdateDataFunc(
        `ResultMonitoring/UpdateResultMonitoring/${selectedItemData?.rmmIdpk}`,
        {
          rmmName: datalistData["datalist0"],
          rmmShtName: datalistData["datalist1"],
          rmmActive: checkboxData["checkbox0"],
          rmmPickFrequencyFromModule: checkboxData["checkbox1"],
          rmmHelpDesk: checkboxData["checkbox2"],
          rmmSingleAssignmentPerPeriod: checkboxData["checkbox3"],
          rmmTaskManager: checkboxData["checkbox4"],
          rmmSafetyManagerMeetingAttendance: checkboxData["checkbox5"],
          rmmHrLeaveManagement: checkboxData["checkbox6"],
          rmmHospManagerMedicalExams: checkboxData["checkbox7"],
          rmmRmks: textareaValue,
          rmmOrder: orderData,
          rmmEditedBy: userId,
          rmmEditedDate: dayjs().format()
        }
      );

      setRefresh(!refresh);

      refreshGrid();
      setsuccesModalState({
        state: true,
        message: 'Record Updated successfully',
        title: 'Update Successful'
      })

      disableForm();
    } catch (error: any) {
      console.log(error);

      setvalidationModalState({
        state: true,
        title: updateModalData.error.title,
        message: updateModalData.error.message
      })

    } finally {
      setBusyLoader('');
    }
  };

  return (
    <div className="px-2">
      {/* save and update modal */}
      <ModalTemplate
        icon={'question'}
        disableCancel={false}
        cancelText={'Cancel'}
        open={saveModalState.state}
        okHandler={saveModalState.title === 'Save Data?' ? postData : updateData}
        cancelHandler={() => setModal('state', false)}
        message={saveModalState.message}
        okText={saveModalState.okText}
        title={saveModalState.title}
      />

      {/* success modal */}
      <ModalTemplate
        icon={'success'}
        disableCancel={true}
        cancelText={'Cancel'}
        open={succesModalState.state}
        okHandler={() => {
          setsuccesModalState((prev: any) => ({ ...prev, state: false }))
        }}
        cancelHandler={() => setsuccesModalState((prev: any) => ({ ...prev, state: false }))}
        message={succesModalState.message}
        okText={succesModalState.okText}
        title={succesModalState.title}
      />



      {/* validation */}
      <ModalTemplate
        disableCancel={true}
        open={validationModalState.state}
        okHandler={() => {
          setValidationModal('state', false)
        }}
        cancelHandler={() => setValidationModal('state', false)}
        message={validationModalState.message}
        okText={'Ok'}
        title={validationModalState.title}
      />

      {/* FORM HEADER */}
      <p id={"form_header"} className="pt-1 px-2 font-semibold w-full bg-slate-100">{"Monitoring Module Details"}</p>
      <Form
        className="w-full border-2 border-slate-100 rounded flex justify-between py-1 px-2 "
        name="basic"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 19 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        size="small"
      >
        <section className="" style={{ width: "500px" }}>
          {/* datalists */}
          {datalists.map(
            ({ id, optionsData, label, defaultValue }, index: number) => {
              return (
                <div key={index} className=" mb-1">
                  <SetupsDatalistTemplat
                    selectedData={defaultValue}
                    index={index}
                    datalistStyle={{ height: 24 }}
                    setCallbackValue={setDatalists}
                    useCallbackFunc
                    label={label}
                    options={optionsData}
                  />
                </div>
              );
            }
          )}

          <div className=" mb-1">
            <InputsTemplate
              span
              inputType='number'
              inputStyle={{ height: 24 }}
              useCallbackFunc
              defaultValue={orderData}
              orderOnchange={(value) => { setOrder(value) }}
              label={"Order"}
            />
          </div>

          {/* checkboxes */}
          <div className=" mb-1">
            <CheckboxTemlate
              withBorders
              inlineDefaultValue={[checkboxData.checkbox0, checkboxData.checkbox1]}
              useCallbackFunc
              index={[0, 1]}
              setCheckboxOnchange={setCheckboxes}
              label={"Active?"}
              label2={"Pick frequent from Module?"}
            />
          </div>

          <div className=" mb-1">
            <CheckboxTemlate
              withBorders
              inlineDefaultValue={[checkboxData.checkbox2, checkboxData.checkbox3]}
              useCallbackFunc
              index={[2, 3]}
              setCheckboxOnchange={setCheckboxes}
              label={"Help Desk?"}
              label2={"Single Assignment Per Period?"}
            />
          </div>

          <div className=" mb-1">
            <CheckboxTemlate
              withBorders
              inlineDefaultValue={[checkboxData.checkbox4, checkboxData.checkbox5]}
              useCallbackFunc
              index={[4, 5]}
              setCheckboxOnchange={setCheckboxes}
              label={"Task Mgr?"}
              label2={"Safety Mgr-Meeting Attendance?"}
            />
          </div>

          <Form.Item
            className=" mb-1"
            style={{ width: "98.2%" }}
            labelCol={{ span: 23 }}
            label={
              <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                {"HR-Leave Management?"}
              </p>
            }
            name="Name"
            rules={[{ required: false }]}
          >
            <Checkbox
              style={{ borderColor: borderTheme }}
              className="px-1 border-[1px] py-0 rounded border-gray-300 mb-1"
              disabled={disabled}
              key={selectedItemData?.rmmHrLeaveManagement ?? ''}
              checked={checkboxData.checkbox6}
              onChange={(e: CheckboxChangeEvent) =>
                setCheckboxes!(e.target.checked, 6)!
              }

            ></Checkbox>
          </Form.Item>

          <Form.Item
            className=" "
            style={{ width: "98.2%" }}
            labelCol={{ span: 23 }}
            label={
              <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                {"Hosp. Mgr-Medical Exams"}
              </p>
            }
            name="Name"
            rules={[{ required: false }]}
          >
            <div className=" mb-1">
              <Checkbox
                style={{ borderColor: borderTheme }}
                className="px-1 border-[1px] py-0 rounded border-gray-300 "
                disabled={disabled}
                key={selectedItemData?.rmmHospManagerMedicalExams ?? ''}
                checked={checkboxData.checkbox7}
                onChange={(e: CheckboxChangeEvent) =>
                  setCheckboxes!(e.target.checked, 7)!
                }
              ></Checkbox>
            </div>
          </Form.Item>

          {/* butons */}

          <Form.Item
            wrapperCol={{ span: 25 }}
            className="mt-0.5 "
            rules={[{ required: false }]}
            style={{
              // width: "calc(115% )",
              marginLeft: "5.4%",
              marginBottom: 0,
            }}
          >
            <div className="flex flex-row justify-end pl-5">
              {!updateMode ? (
                <SaveButton handleSave={ValidatePostData} />
              ) : (
                <UpdateButton handleUpdate={ValidatePutData} />
              )}

              {disabled ? <NewButton new_button_toggler={() => {
                setTextarea(''); populateFromGrid(true)
                handleNew()
              }} useCallbackFunc /> : <Cancel />}
            </div>
          </Form.Item>
        </section>

        {/* remarks */}
        <section className=" " style={{ width: "calc(100% - 500px)" }}>
          <TextareaTemplate
            defaultValue={textareaValue}
            useCallbackFunc
            setCallbackValue={setTextarea}
            height={250}
            MarginLeft={40}
            label={"Remarks"}
          />
        </section>
      </Form>
    </div>
  );
};

