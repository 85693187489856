/* eslint-disable react/jsx-pascal-case */
import { Tooltip } from "devextreme-react";
import Datagrid_template from "../../../../../../../templates/Datagrid";
import { DateTemplate } from "../../../../../../../templates/date";
import { NewButton, SaveButton } from "../../../../../../accessories/buttons";
import { vehicleList_data } from "../data/bulk-assignment-data";
import reset from "../../../../../../../assets/refresh-small.png";
import search from "../../../../../../../assets/search1.png";
import useFetch from "../../../../../../../hooks/useFetch";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import { PatchDataFunc } from "../../../../../../../functions/patch";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { VehicleData } from "../../../Vehicles/Vehicle-Data-Upload/data/entity";
import { add_Selected_Record_To_Terminate, Remove_Duplicates_From_TerminationList } from "../functions/BulkAssignmentFunc";
const BulkAssignmentTermination = () => {
  const selectedAssignment = useSelector(
    (state: RootState) => state.general?.selectedAssignment
  );
  const [refresh, setRefresh] = useState(false);
  const [vehicle_list_data] = useFetch(
    `AssetTamVehicleAssignment/GetVehicleList?VdtIDsx=${
      selectedAssignment?.vdtIDpk
    }&EndDate=${dayjs().format("YYYY-MM-DD")}`,
    refresh
  );
  const [recordsToTerminate, setRecordsToTerminate] = useState([]);
  const [terminationList, setTerminationList] = useState([]);
  const [currentRecord, setCurrentRecord] = useState<any>([]);
  const [currentDate, setCurrentDate] = useState<any>(dayjs());
  const [userId] = useAccountInfo();
  const [modalData, setModalData] = useState<any>({
    title: "Terminate Assignment",
    icon: "question",
    openModal: false,
    message:
      "Are you sure you want to end the assignments for the selected vehicles?",
    RemoveCancelButton: false,
    cancelText: "No",
    okHandler: () => {},
    cancelHandler: () => {},
  });

  const [doubleClickedItem, setDoubleClickedItem] = useState({});
  const [isDoubleClicked, setIsDoubleClicked] = useState(false);

  const terminateAssignment = () => {
    for (let records of recordsToTerminate) {
      PatchDataFunc(
        `AssetTamVehicleAssignment/SaveEndAssignment?VasID=${records["vasIDpk"]}`,
        {
          vasEndDate: currentDate,
          vasActive: !records["vasActive"],
          vasEditedBy: userId,
        }
      )
      .then(()=>{
        return;
      })
      .catch(() => {
        setModalData({
          ...modalData,
          title: `Record with VasID=${records["vasIDpk"]} not terminated`,
          icon: "warning",
          message: "Please contact the systems administrator for assistance",
          RemoveCancelButton: true,
          cancelText: "No",
          okText: "Ok",
          openModal: true,
          okHandler: () => {
            setModalData({ ...modalData, openModal: false });
          },
        });
      });
    }
    setModalData({
      title: "Terminate Assignment",
      icon: "success",
      message: "Assignments terminated successfully",
      RemoveCancelButton: true,
      cancelText: "No",
      okText: "Ok",
      openModal: true,
      okHandler: () => {
        setModalData({
          ...modalData,
          openModal: false,
        });
      },
    });
    setRefresh(!refresh);
  };
  const validateForm = () => {
    if (recordsToTerminate.length === 0) {
      setModalData({ ...modalData, openModal: false });
      setModalData({
        ...modalData,
        title: "Empty!",
        icon: "warning",
        message: "Please select vehicle to terminate.",
        RemoveCancelButton: true,
        cancelText: "No",
        okText: "Ok",
        openModal: true,
        okHandler: () => {
          setModalData({ ...modalData, openModal: false });
        },
      });
    } else {
      setModalData({
        ...modalData,
        title: "Terminate Assignment ? ",
        icon: "question",
        message:
          "Are you sure you want to end the assignments for the selected vehicles?",
        RemoveCancelButton: false,
        cancelText: "No",
        okText: "Yes",
        openModal: true,
        okHandler: () => {
          setModalData({ ...modalData, openModal: false });
          terminateAssignment();
        },
        cancelHandler: () => {
          setModalData({ ...modalData, openModal: false });
        },
      });
    }
  };

  useEffect(() => {
    const Remove_Item_onDoubleClick_In_TerminationList=(doubleClickedItem:any)=>{
        const target = doubleClickedItem
        const remainingRecords = terminationList.filter((item) => item !== target);
        setTerminationList(remainingRecords);
    }
    Remove_Item_onDoubleClick_In_TerminationList(doubleClickedItem)
    return () => {
      setDoubleClickedItem({})
      setIsDoubleClicked(false);
    };
  }, [isDoubleClicked]);

  console.log("terminationList: ", terminationList);

  return (
    <>
      <div className="flex">
        <ModalTemplate
          title={modalData.title}
          icon={modalData.icon}
          okHandler={modalData.okHandler}
          okText={modalData.okText}
          cancelHandler={modalData.cancelHandler}
          cancelText={modalData.cancelText}
          disableCancel={modalData.RemoveCancelButton}
          message={modalData.message}
          open={modalData.openModal}
        />
        <div className="w-[49%] mx-[0.5%] border rounded-sm">
          <p
            id={"form_header"}
            className="py-1 px-1 w-full font-medium flex justify-between"
          >
            {"Vehicle List"}
            <div
              onClick={() => {
                setRefresh(!refresh);
              }}
              id="searchbulkassignment"
              style={{ borderWidth: "1px" }}
              className="hover:cursor-pointer  border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
                {" "}
                <img
                  className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                  src={search}
                  alt=""
                />
              </span>
              <Tooltip
                target="#searchbulkassignment"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Search</p>
              </Tooltip>
            </div>
          </p>
          <div className="m-[2px]">
            <Datagrid_template
              gridheight={400}
              selectionMode={"multiple"}
              columns={vehicleList_data}
              data={vehicle_list_data}
              disablePaging={true}
              deselectFirstRow={true}
              selectedItemsChange={(record) => {
                setCurrentRecord(record);
              }}
            />
          </div>
          <div className="flex justify-end my-[4px] mx-[2px]">
            <NewButton
              useCallbackFunc
              new_button_toggler={() => {
                add_Selected_Record_To_Terminate(
                  setTerminationList,
                  currentRecord
                );
              }}
              title="Add"
            />
          </div>
        </div>

        <div className="w-[49%]  border rounded-sm">
          <p className="py-1 px-1 w-full bg-slate-100 font-semibold flex justify-between">
            {"Termination List"}
            <div
              onClick={() => {}}
              id="refreshbulkassignment"
              style={{ borderWidth: "1px" }}
              className="hover:cursor-pointer  border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
                {" "}
                <img
                  className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                  src={reset}
                  alt=""
                />
              </span>
              <Tooltip
                target="#refreshbulkassignment"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Refresh</p>
              </Tooltip>
            </div>
          </p>

          <div className="m-[2px]">
            <Datagrid_template
              gridheight={400}
              columns={vehicleList_data}
              rowDoubleClicked={(doubleClickedItem: any) => {
                doubleClickedItem && setIsDoubleClicked(true);
                console.log('doubleClickedItem:', doubleClickedItem)
                setDoubleClickedItem(doubleClickedItem);
              }}
              // data={Remove_Duplicates_From_TerminationList(terminationList)}
              data={terminationList}
              selectedItemsChange={(records) => {
                setRecordsToTerminate([...recordsToTerminate, ...records]);
              }}
              selectionMode={"multiple"}
              disablePaging={true}
              deselectFirstRow={true}
            />
          </div>
          <div className="flex justify-end my-[4px] mx-[2px]">
            <DateTemplate
              label="End Date"
              disabled={false}
              changeDate={(val: any) => {
                setCurrentDate(val);
              }}
              format={"DD MMM, YYYY"}
              selectedDate={currentDate}
            />
            <span className="mx-[4px]"></span>
            <SaveButton
              title="End Assignment"
              disableButton={false}
              useCallbackFunc
              handleSave={() => {
                validateForm();
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BulkAssignmentTermination;
