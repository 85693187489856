/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import { Form, Space } from "antd";
import { useCallback, useEffect, useState } from "react";
import Datagrid_template from "../../../../../../../templates/Datagrid";
import { InputsTemplate } from "../../../../../../../templates/input";
import {
  approvers1Columns,
  approvers2Columns,
} from "../data/approversRequestColumn";
import reset from "../../../../../../../assets/reset.png";
import useFetch from "../../../../../../../hooks/useFetch";
import { SaveButton } from "../../../../../../accessories/buttons";
import React from "react";
import { saveModalData } from "../../../../../../accessories/component_infos";
import { ModalTemplate } from "../../../../../../../templates/modal";
import dayjs from "dayjs";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { searchTextTypes } from "../../../../../../human-resource/setups/data/types/selectsTypes";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import { GetDataFunc } from "../../../../../../../functions/get";
import { useCrudFunc } from "../../../../../../../functions/crud";
import { PopDgvRequestAreasAssignedPrivileges, VehicleRequestPrivilegesPopDgvEmps } from "../../../../../../../interfaces";
import { AxiosResponse } from "axios";


interface stateTypes {
  vehicleEmp?: any;
  vehicleAssPriveledges?: any;
  vehicleUnaPriveledges?: any;
  leftSelectedRecord?: VehicleRequestPrivilegesPopDgvEmps[];
  empKey?: number;
  getAll: any[];
  rightGrid?: any;
  stationsSearch?: searchTextTypes;
  staffSearch?: any;
}

interface props {
  setRefreshx?: (e: boolean) => void;
}

export const VehicleRequestPriveledgesForm = ({ setRefreshx }: props) => {

  const [PostDataFunc, UpdateDataFunc] = useCrudFunc();


  const [employeeID, userID] = useAccountInfo();
  const colorTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  const [states, setStates] = useState<stateTypes>({
    vehicleAssPriveledges: [],
    vehicleUnaPriveledges: [],
    vehicleEmp: [],
    leftSelectedRecord: [],
    empKey: 0,
    getAll: [],
    rightGrid: [],
    staffSearch: { empName: "", dept: "", sxn: "", loc: "", job: "" },

    stationsSearch: { text: "", temp: "" },
  });

  const setState = (key: string, value: any) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };
  var editedDate = dayjs().format();
  const [DBA, setDBA] = useState<any>([]);
  const [empIdpk, setempIdpk] = useState<number>(0);

  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [refreshstate, setRefreshstate] = useState<boolean>(false);

  const [privType, setPrivType] = useState<string>("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);
  // const dispatch = useDispatch()
  // Mini datagrid function
  //----------------------------------------------------------------
  const onChangeRecord = (e: VehicleRequestPrivilegesPopDgvEmps[]) => {
    if (setState !== undefined) {
      setState("leftSelectedRecord", e);

      if (e.length !== 0) {
        setempIdpk(e.slice(-1)[0].empIdpk);
      }
    }
  }; //

  const appendColumns = (data: any) => {
    const temp: any = data;
    for (let j = 0; j < temp.length; j++) {
      temp[j] = {
        ...temp[j],
        vaaActive: false,
        vaaApprove: false,
        vaaApprovePersonalRequests: false,
        vaaRecommendPersonalRequests: false,
        vaaRecommend: false,
        vaaAlert: false,
        vaaDriver: false,
        vaaManageKeys: false,
        vaaSeeRating: false,
        vaaSeeAllDeptsCostCenters: false,
        vaaSeeOnlySectionsCostCenters: false,
        vaaSeeAllCostCenters: false,
        vaaAssign: false,

        vaaRmks: "",
      };
    }
    return temp;
  };

  const [DBEdata, DBEerror, DBEloading]: [VehicleRequestPrivilegesPopDgvEmps[], any, boolean] = useFetch(
    `AsmTmsVehicleRequestPrivileges/VehicleRequestPrivilegesPopDgvEmps?EmpName=${states.staffSearch?.empName}&SxnShtName=${states.staffSearch?.sxn}&DptShtName=${states.staffSearch?.dept}&JbtName=${states.staffSearch?.job}&LocName=${states.staffSearch?.loc}`,
    states.staffSearch
  );
  function generateRandomId() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomText = "";

    for (let i = 0; i < 4; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomText += characters[randomIndex];
    }

    return randomText;
  }

  const DB_Fetch = async () => {

    const resA = await GetDataFunc(
      `AsmTmsVehicleRequestPrivileges/PopDgvRequestAreasAssignedPrivileges?EmpIdpk=${empIdpk}&VraName=${states.stationsSearch?.text}`
    ) as AxiosResponse<PopDgvRequestAreasAssignedPrivileges[]>;

    setDBA(resA.data.slice(0, 50));

    const resU = await GetDataFunc(
      `AsmTmsVehicleRequestPrivileges/PopDgvRequestAreasUnassignedRequestAreas?EmpIdpk=${empIdpk}&VraName=${states.stationsSearch?.text}`
    );
    // setDBU(resU.data);

    setState("getAll", [
      ...resA.data
        .slice(0, 50)
        .map((obj: any) => ({ ...obj, id: generateRandomId() })),
      ...appendColumns(
        resU.data
          .slice(0, 50)
          .map((obj: any) => ({ ...obj, id: generateRandomId() }))
      ),
    ]);

    // spreading the assigned and remaining unassigned
  };

  const implementRowColouring = useCallback((row: any) => {
    if (
      row.data?.vaaActive === true ||
      row.data?.vaaAlert === true ||
      row.data?.vaaApprove === true ||
      row.data?.vaaApprovePersonalRequests === true ||
      row.data?.vaaAssign === true ||
      row.data?.vaaDriver === true ||
      row.data?.vaaManageKeys === true ||
      row.data?.vaaRecommend === true ||
      row.data?.vaaRecommendPersonalRequests === true ||
      row.data?.vaaSeeAllCostCenters === true ||
      row.data?.vaaSeeAllDeptsCostCenters === true ||
      row.data?.vaaSeeOnlySectionsCostCenters === true ||
      row.data?.vaaSeeRating
    ) {
      return (row.rowElement.style.backgroundColor =
        "rgb(134 239 172 / var(--tw-bg-opacity))");
    } else {
      return (row.rowElement.style.backgroundColor =
        "rgb(250 204 21 / var(--tw-bg-opacity))");
    }
  }, []);

  const colorCell = (e: any) => {
    var res = DBA.find((reqArea: any) => reqArea?.vraIdpk === e.data?.vraIdpk);
    if (e.columnIndex === 1 && res === undefined && e.rowIndex > -1) {
      return (e.cellElement.style.backgroundColor = "rgb(255,255,255");
    }
  };

  // Adding Privilege
  const saveAccessPriv = () => {
    setPrivType("save");

    if (savePriv === true) {
      setModalData(saveModalData);
      setOpenModal(true);
      validationForms();
    } else {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "No Access Privileges",
        title: "Access Denied!",
        okText: "Ok",
      });
      setOpenModal(true);
    }
  };

  //   validations
  const validationForms = () => {
    if (states.getAll.length === 0) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select a Record",
        title: "Grid Record Selection Error",
        okText: "Ok",
      });
      setOpenModal(true);
    } else {
      setshowModalDisableButton(false);
      setIcon("question");
      setOpenModal(true);
    }
  };

  //checking privilages selection status
  const checkActiveSelectionStatus = (j: number): boolean => {
    return (
      states.getAll[j]?.vaaApprove ||
      states.getAll[j]?.vaaActive ||
      states.getAll[j]?.vaaApprovePersonalRequests ||
      states.getAll[j]?.vaaRecommendPersonalRequests ||
      states.getAll[j]?.vaaRecommend ||
      states.getAll[j]?.vaaAlert ||
      states.getAll[j]?.vaaDriver ||
      states.getAll[j]?.vaaManageKeys ||
      states.getAll[j]?.vaaSeeRating ||
      states.getAll[j]?.vaaSeeAllDeptsCostCenters ||
      states.getAll[j]?.vaaSeeOnlySectionsCostCenters ||
      states.getAll[j]?.vaaSeeAllCostCenters ||
      states.getAll[j]?.vaaAssign
    );
  };

  //   postDATA
  const postData = async () => {
    try {
      for (let staff of states.leftSelectedRecord) {
        const resA = await GetDataFunc(
          `AsmTmsVehicleRequestPrivileges/PopDgvRequestAreasAssignedPrivileges?EmpIdpk=${staff?.empIdpk
          }&VraName=${""}`
        );

        for (let j = 0; j < states.getAll.length; j++) {
          const exists = resA?.data.find(
            (reqArea: any) => reqArea?.vraIdpk === states.getAll[j]?.vraIdpk
          );

          if (exists !== undefined) {
            if (checkActiveSelectionStatus(j) === true) {
              await UpdateDataFunc(
                `AsmTmsVehicleRequestPrivileges/UpdateVehicleRequestPrivileges?Id=${exists.vaaIdpk}`,
                {
                  // vaaIdpk: exists?.vaaIdpk,
                  vaaAssign: states.getAll[j]?.vaaAssign,
                  vaaApprove: states.getAll[j]?.vaaApprove,
                  vaaRecommendPersonalRequests:
                    states.getAll[j]?.vaaRecommendPersonalRequests,
                  vaaApprovePersonalRequests:
                    states.getAll[j]?.vaaApprovePersonalRequests,
                  vaaRecommend: states.getAll[j]?.vaaRecommend,
                  vaaDriver: states.getAll[j]?.vaaDriver,
                  vaaAlert: states.getAll[j]?.vaaAlert,
                  vaaManageKeys: states.getAll[j]?.vaaManageKeys,
                  vaaSeeRating: states.getAll[j]?.vaaSeeRating,
                  vaaSeeAllDeptsCostCenters:
                    states.getAll[j]?.vaaSeeAllDeptsCost,
                  vaaSeeOnlySectionsCostCenters:
                    states.getAll[j]?.vaaSeeOnlySectionsCost,
                  vaaActive: true,
                  vaaSeeAllCostCenters: states.getAll[j]?.vaaSeeAllCost,
                  vaaRmks: states.getAll[j]?.vaaRmks,
                  vaaEditedDate: editedDate,
                 
                },
                `Update the Asset Mgr-Transport-Vehicle Request Approvers Assignment for ${states.leftSelectedRecord[0]?.empName}
                "${states.leftSelectedRecord[0].empStaffNo}" with Emp ID  ${states.leftSelectedRecord[0].empIdpk} with Approve Status ${states.getAll[j]?.vaaApprove}`
              );
            } else {
              await UpdateDataFunc(
                `AsmTmsVehicleRequestPrivileges/UpdateVehicleRequestPrivileges?Id=${exists.vaaIdpk}`,
                {
                  vaaIdpk: exists?.vaaIdpk,
                  vaaAssign: states.getAll[j]?.vaaAssign,
                  vaaApprove: states.getAll[j]?.vaaApprove,
                  vaaRecommendPersonalRequests:
                    states.getAll[j]?.vaaRecommendPersonalRequests,
                  vaaApprovePersonalRequests:
                    states.getAll[j]?.vaaApprovePersonalRequests,
                  vaaRecommend: states.getAll[j]?.vaaRecommend,
                  vaaDriver: states.getAll[j]?.vaaDriver,
                  vaaAlert: states.getAll[j]?.vaaAlert,
                  vaaManageKeys: states.getAll[j]?.vaaManageKeys,
                  vaaSeeRating: states.getAll[j]?.vaaSeeRating,
                  vaaSeeAllDeptsCostCenters:
                    states.getAll[j]?.vaaSeeAllDeptsCost,
                  vaaSeeOnlySectionsCostCenters:
                    states.getAll[j]?.vaaSeeOnlySectionsCost,
                  vaaActive: false,
                  vaaSeeAllCostCenters: states.getAll[j]?.vaaSeeAllCost,
                  vaaRmks: states.getAll[j]?.vaaRmks,
                  vaaEditedBy: userID,
                  vaaEditedDate: editedDate,
                }, 
                `Update the Asset Mgr-Transport-Vehicle Request Approvers Assignment for ${states.leftSelectedRecord[0]?.empName}
                  "${states.leftSelectedRecord[0].empStaffNo}" with Emp ID  ${states.leftSelectedRecord[0].empIdpk} with Approve Status ${states.getAll[j]?.vaaApprove}`
              );
            }
          } else {
            if (checkActiveSelectionStatus(j) === true) {
              await PostDataFunc(
                `AsmTmsVehicleRequestPrivileges/CreateVehicleRequestPrivileges`,
                {
                  vaaEmpIdfk: staff.empIdpk,
                  vaaRequestAreaIdfk: states.getAll[j]?.vraIdpk,
                  vaaAssign: states.getAll[j]?.vaaAssign,
                  vaaApprove: states.getAll[j]?.vaaApprove,
                  vaaRecommendPersonalRequests:
                    states.getAll[j]?.vaaRecommendPersonalRequests,
                  vaaApprovePersonalRequests:
                    states.getAll[j]?.vaaApprovePersonalRequests,
                  vaaRecommend: states.getAll[j]?.vaaRecommend,
                  vaaDriver: states.getAll[j]?.vaaDriver,
                  vaaAlert: states.getAll[j]?.vaaAlert,
                  vaaManageKeys: states.getAll[j]?.vaaManageKeys,
                  vaaSeeRating: states.getAll[j]?.vaaSeeRating,
                  vaaSeeAllDeptsCostCenters:
                    states.getAll[j]?.vaaSeeAllDeptsCostCenters,
                  vaaSeeOnlySectionsCostCenters:
                    states.getAll[j]?.vaaSeeOnlySectionsCostCenters,
                  vaaSeeAllCostCenters: states.getAll[j]?.vaaSeeAllCostCenters,
                  vaaActive: true,
                  vaaRmks: states.getAll[j]?.vaaRmks,
                  vaaCreatedBy: userID,
                  formAction: `Created the Asset Mgr-Transport-Vehicle Request Approvers Assignment for ${states.leftSelectedRecord[0].empName}
                  "${states.leftSelectedRecord[0].empStaffNo}" with Emp ID  ${states.leftSelectedRecord[0].empIdpk} with Approve Status 
                  ${states.getAll[j]?.vaaApprove}`
                },
                `Created the Asset Mgr-Transport-Vehicle Request Approvers Assignment for ${states.leftSelectedRecord[0].empName}
                  "${states.leftSelectedRecord[0].empStaffNo}" with Emp ID  ${states.leftSelectedRecord[0].empIdpk} with Approve Status 
                  ${states.getAll[j]?.vaaApprove}`
              );
            }
          }
        }
      }

      setRefreshx && setRefreshx(!refreshstate);
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(true);

      // setRefreshstate(!refreshstate);
      setModalData({
        message: "Data entry saved successfully!",
        title: "Data Saved!",
        okText: "Ok",
      });
    } catch (err: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: err.message,
        title: "Record Selection!",
        okText: "Ok",
      });
    }
  };

  useEffect(() => {
    DB_Fetch();
  }, [empIdpk, refreshstate, states.stationsSearch?.text]);

  return (
    <div className="px-2 ">
      {/* FORM HEADER */}
      <ModalTemplate
        icon_width={modalData.iconSize === "large" ? 100 : 50}
        icon={icon}
        cancelText={modalData.cancelText}
        disableCancel={showModalDisableButton}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        open={openModal}
        okHandler={() => {
          modalData === saveModalData ? postData() : setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
      />
      <p
        id={"form_header"}
        style={{ borderColor: colorTheme }}
        className="py-1 px-2 w-full border-[1px] rounded bg-slate-100"
      >
        <span className="font-medium">{"Vehicle Request Privileges"}</span>
      </p>
      <Form
        style={{ borderColor: colorTheme }}
        className="w-full border-[1px] px-0.5 rounded border-t-0 flex justify-between  "
        name="basic"
        wrapperCol={{ span: 25 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        size="small"
      >
        <section style={{ width: "calc(50% - 5px)" }} className="w-full  bg">
          {/* SELECTS */}
          <Form.Item className="" name="Staff No" rules={[{ required: false }]}>
            <Space className=" w-full flex justify-end">
              <div className="flex flex-row items-center">
                <InputsTemplate
                  span
                  useCallbackFunc
                  orderOnchange={(value) =>
                    setState("staffSearch", {
                      empName: value,
                      dept: value,
                      loc: value,
                      sxn: value,
                      job: value,
                    })
                  }
                  disabledStatus={false}
                  placeholder={"Search"}
                  style={{ display: "inline-block", width: "calc(200px)" }}
                />

                <div
                  onClick={() => {
                    setState("staffSearch", {
                      empName: states.staffSearch?.empName,
                      dept: states.staffSearch?.dept,
                      loc: states.staffSearch?.loc,
                      sxn: states.staffSearch?.sxn,
                      job: states.staffSearch?.job,
                    });
                  }}
                  style={{ borderColor: colorTheme }}
                  className="border-[1px] rounded p-0.5 mx-1"
                >
                  <span className="h-full w-4 flex justify-center hover:cursor-pointer hover:bg-slate-50">
                    <img
                      className="w-full h-full flex items-center justify-center"
                      src={reset}
                      alt="reset"
                    />
                  </span>
                </div>
              </div>
            </Space>
          </Form.Item>

          {/* DATAGRID []  Left*/}
          <Form.Item className="" name="Staff No" rules={[{ required: false }]}>
            <div className="">
              <Datagrid_template
                selectedItemsChange={onChangeRecord}
                selectionMode="multiple"
                disableGroupPanel
                disableSearch
                disablePaging
                data={DBEdata}
                gridheight={270}
                columns={approvers1Columns}
                onRowClick={(e) => { }}
              />
            </div>
          </Form.Item>
        </section>

        <section className="" style={{ width: "calc(50% - 5px)" }}>
          {/* SELECTS */}
          <Form.Item className="" name="Staff No" rules={[{ required: false }]}>
            <Space className=" w-full  flex justify-end ml-2">
              <div className="flex flex-row space-x-1 items-center">
                <InputsTemplate
                  span
                  useCallbackFunc
                  orderOnchange={(value) =>
                    setState("stationsSearch", { text: value, temp: value })
                  }
                  disabledStatus={false}
                  placeholder={"Search"}
                  style={{ display: "inline-block", width: "calc(200px)" }}
                />

                <div
                  onClick={() => {
                    setState("stationsSearch", {
                      temp: states.stationsSearch?.temp,
                      text: states.stationsSearch?.temp,
                    });
                  }}
                  style={{ borderColor: colorTheme }}
                  className="border-[1px] rounded p-0.5 mr-0.5"
                >
                  <span className="h-full w-4 flex justify-center hover:cursor-pointer hover:bg-slate-50  ">
                    <img
                      className="w-full h-full flex items-center justify-center"
                      src={reset}
                      alt="reset"
                    />
                  </span>
                </div>
              </div>{" "}
            </Space>
          </Form.Item>

          {/* DATAGRID []  Right*/}

          <Form.Item className="" name="Staff No" rules={[{ required: false }]}>
            <div className="">
              <Datagrid_template
                onRowPrepared={implementRowColouring}
                cellColoringFxn={colorCell}
                rowAlternationEnabled={false}
                allowColumnEditing
                deselectFirstRow
                selectionMode="single"
                disableGroupPanel
                disableSearch
                disablePaging
                data={states.getAll}
                gridheight={270}
                columns={approvers2Columns}
                onRowClick={(e) => { }}
                selectedItemsChange={(selectedArr) => {
                  setState("rightGrid", selectedArr);
                }}
                showSelectionCheckBox="always"
              />
              <li className="w-full mt-0.5 flex justify-end">
                <SaveButton disableButton={false} handleSave={saveAccessPriv} />
              </li>
            </div>
          </Form.Item>
        </section>
      </Form>
    </div>
  );
};

export default VehicleRequestPriveledgesForm;
