import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";
import { api_base_url, api_url } from "../components/accessories/component_infos";
import { requestConfig } from "../general/requestConfig";


// Custom hook for crud Func
export const useCrudFunc = (baseUrl?: "innox"| "powerapp",) => {
    let FormCode = useSelector((state: RootState) => state.user?.formCode);

    var api = api_url;

    switch (baseUrl) {
        case "powerapp":
            api = api_base_url
            break;
        default:
            api = api_url
    }


    const postData = async (url: string, payload: any, FormAction?: string, cusHeader?: any) => {
        if (!FormCode) FormCode = "0000-debug"
        const response = await axios.post(
            `${api}/${url}`,

            { ...payload, FormCode: FormCode, FormAction: FormAction },
            requestConfig(null,cusHeader) 
        );
        return response;
    };
    
    const updateData = async (url: string, payload: any, FormAction?: string, cusHeader?:any) => {
        const response = await axios.put(
            `${api}/${url}`,
            { ...payload, FormCode: FormCode, FormAction: FormAction },
            requestConfig(null, cusHeader) 
        );
        return response;
    };
    
    // const DeleteData = async (url: string, payload:any,  FormAction?: string, cusHeader?:any) => {
    //     const response = await axios.delete(
    //         `${api}/${url}`,
    //         { ...payload, FormCode: FormCode, FormAction: FormAction },
    //         requestConfig()
           
    //     );
    //     return response;
    // };


    const patchData = async (url: string, payload: any, FormAction?: string) => {
        const response = await axios.patch(
            `${api}/${url}`,
            { ...payload, FormCode, FormAction},
            requestConfig() 
        );
        return response;
    };

    return [postData, updateData, patchData];
};

