import {
  HtmlEditor,
  Image,
  ImageSettingsModel,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import * as React from "react";

interface props {
  height?: number | string;
  content: string;
  readonly?: boolean;
  disableToolbar?: boolean;
  setElementContent: (element: string) => void;
  handleFocus?: (element: any) => void;
  RichEditorStyles?: any;
}

export const RichTextEditorTemplate: React.FC<props> = ({
  height,
  readonly = false,
  disableToolbar,
  content,
  setElementContent,
  RichEditorStyles,
  handleFocus,
}) => {
  const toolbarSettings = {
    items: disableToolbar
      ? ["|"]
      : [
          "Bold",
          "Italic",
          "Underline",
          "FontName",
          "FontSize",
          "|",
          "Image",
          "|",
          "|",
          "Undo",
          "Redo",
        ],
  };

  const imageSettings: ImageSettingsModel = {
    allowedTypes: [".jpeg", ".jpg", ".png"],
    display: "inline",
    width: "250px",
    height: "25+0px",
    saveFormat: "Base64",
  };
  return (
    <>
      <main
        style={{ ...RichEditorStyles }}
        className="border-[1px] rounded-b px-1 pt-1 overflow-y-scroll"
      >
        <RichTextEditorComponent
          onFocus={handleFocus}
          readOnly={readonly}
          readonly={readonly}
          value={content ?? "<p> </p>"}
          change={(e: any) => {
            setElementContent(e.value);
          }}
          insertImageSettings={imageSettings}
          onChange={() => {}}
          height={height ?? 150}
          toolbarSettings={toolbarSettings}
        >
          <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]} />
        </RichTextEditorComponent>
      </main>
    </>
  );
};
