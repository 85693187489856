import { Form, Radio, RadioChangeEvent } from "antd";
import { useState } from "react";
import correct from "../../../../../../../../assets/correct.png";
import { CheckboxTemlate } from "../../../../../../../../templates/checkbox";
import { DateTemplate } from "../../../../../../../../templates/date";
import { SelectsTemplate } from "../../../../../../../../templates/select";
import { TextareaTemplate } from "../../../../../../../../templates/textarea";
import { UpdateButton } from "../../../../../../../accessories/buttons";
import useFetch from "../../../../../../../../hooks/useFetch";
import dayjs, { Dayjs } from "dayjs";
import {
  currentDate,
  saveModalData,
} from "../../../../../../../accessories/component_infos";
import { InputsTemplate } from "../../../../../../../../templates/input";
import CheckBoxForm from "./checkboxForm";
import { useAccountInfo } from "../../../../../../../../hooks/useAccountInfo";
import { ModalTemplate } from "../../../../../../../../templates/modal";
import { useCrudFunc } from "../../../../../../../../functions/crud";

interface prop {
  refreshState?: () => void;
}

interface modalTypes {
  info: any;
  open: boolean;
  icon: "warning" | "question" | "success";
  disableCancel?: boolean;
}

export const VehicleInspectionsForms = ({ refreshState }: prop) => {
  const initialStates = {
    returnDate: currentDate,
    selectedId: 0,
    pickupInspection: { name: "", id: 0 },
    returnInspection: { name: "", id: 0 },
    inspectionDate: currentDate,
    remarks: "",
    active: true,
    refresh: false,
    password: "",
    // checkbox states
    vriPickupBrakesWorkingCorrectly: true,
    vriPickupBumpersInGoodCondition: true,
    vriPickupHornWorkingProperly: true,
    vriPickupSeatbeltsInGoodCondition: true,
    vriPickupWariningTriangleAvailable: true,
    vriPickupWindowsControlsWorking: true,
    vriPickupInteriorIsClean: true,
    vriPickupCracksOnWindscreen: true,
    vriPickupSideMirrorsInGoodCondition: true,
    vriPickupBrakeLightsWorkingCorrectly: true,
    vriPickupHeadlightsWorkingCorrectly: true,
    vriPickupIndicatorLightsWorkingCorrectly: true,
    vriPickupReverseLightsWorkingCorrectly: true,
    vriPickupAllInsideLightsWorkingCorrectly: true,
    vriPickupTyresInGoodCondition: true,
    vriPickupSpareTyreAvailable: true,
    vriPickupToolsForReplacingTyresAvailable: true,
    vriPickupBrakeFluidLevelOk: true,
    vriPickupEngineOilLevelOk: true,
    vriPickupPowerSteeringFluidLevelOk: true,
    vriPickupCoolantLevelOk: true,
    vriPickupFireExtinguisherAvailable: true,
    vriPickupFullyStockedFirstAidKidAvailable: true,
    vriPickupBatteryOk: true,
    vriPickupAnyLeakages: true,
    vriPickupWipersInGoodCondition: true,
    vriPickupAnyDents: true,
    vriPickupAnyScratches: true,
    vriPickupInsuranceValid: true,
    vriPickupRoadworthyValid: true,
    vriPickupDriversLicensePresentAndValid: true,
    vriPickupAllFloorMatsIntact: true,
    vriPickupAcInGoodCondition: true,
    vriVehiclePickupConditionOk: true,
    checkAll : false

  };

  const [indeterminate, setIndeterminate] = useState<any>(true);

  const [inspectionType, setInspectionType] = useState<"pickup" | "return">("pickup");

  const [employeeId, userId, userInfo] = useAccountInfo();

  const handleInspectionTypeChange = (e: RadioChangeEvent) => {
    setInspectionType(e.target.value === 1 ? "pickup" : "return");
  };

  const [modalData, setModalData] = useState<modalTypes>({
    info: saveModalData,
    open: false,
    icon: "warning",
    disableCancel: false,
  });
  const [Icon, setIcon] = useState<"warning" | "question" | "success">(
    "warning"
  );

  const [states, setStates] = useState<any>(initialStates);

  const setState = (key: string, value: any) => {
    setStates((prev: any) => ({ ...prev, [key]: value }));
  };

  const [posting, updating] = useCrudFunc();

  //   all checkboxes staets

const populateField = (e:boolean)=>{
   
    const checkStates = [
        {
            key: "vriPickupBrakesWorkingCorrectly",
            value: e,
        },
        {
            key: "vriPickupBumbersInGoodCondition",
            value: e,
        },
        {
            key: "vriPickupHornWorkingProperly",
            value: e,
        },
        {
            key: "vriPickupSeatbeltsInGoodCondition",
            value: e,
        },
        {
            key: "vriPickupWarningTriangles",
            value: e,
        },
        {
            key: "vriPickupWindowControlsWorking",
            value: e,
        },
        {
            key: "vriPickupInteriorClean",
            value: e,
        },
        {
            key: "vriPickupCracksOnWindshield",
            value: e,
        },
        {
            key: "vriPickupSideMirrorsInGoodCondition",
            value: e,
        },
        {
            key: "vriPickupBrakeLightsWorkingCorrectly",
            value: e,
        },
        {
            key: "vriPickupHeadlightsWorkingCorrectly",
            value: e,
        },
        {
            key: "vriPickupIndicatorLightsWorkingCorrectly",
            value: e,
        },
        {
            key: "vriPickupReverseLightsWorkingCorrectly",
            value: e,
        },
        {
            key: "vriPickupInsideLightsWorking",
            value: e,
        },
        {
            key: "vriPickupTyresInGoodCondition",
            value: e,
        },
        {
            key: "vriPickupSpareTyre",
            value: e,
        },
        {
            key: "vriPickupToolsForReplacingTyres",
            value: e,
        },
        {
            key: "vriPickupBrakeFluidLevelOk",
            value: e,
        },
        {
            key: "vriPickupEngineOilLevelOk",
            value: e,
        },
        {
            key: "vriPickupPowerSteeringFluidLevelOk",
            value: e,
        },
        {
            key: "vriPickupCoolantLevelOk",
            value: e,
        },
        {
            key: "vriPickupFireExtinguisher",
            value: e,
        },
        {
            key: "vriPickupFirstAidKit",
            value: e,
        },
        {
            key: "vriPickupBatteryOk",
            value: e,
        },
        {
            key: "vriPickupAnyLeakages",
            value: e,
        },
        {
            key: "vriPickupWipersInGoodCondition",
            value: e,
        },
        {
            key: "vriPickupAnyDents",
            value: e,
        },
        {
            key: "vriPickupAnyScratches",
            value: e,
        },
        {
            key: "vriPickupInsuranceValid",
            value: e,
        },
        {
            key: "vriPickupRoadworthyValid",
            value: e,
        },
        {
            key: "vriPickupDriversLicensePresentAndValid",
            value: e,
        },
        {
            key: "vriPickupAllFloorMatsIntact",
            value: e,
        },
        {
            key: "vriPickupAcInGoodCondition",
            value: e,
        },
    ];

    for(let i of checkStates) setState(i.key, i.value)
}


  const [pickupInspectionData] = useFetch(
    `AsmTmsVehicleRequestKeyPicks/GetAllKeysInsInspectedBy`
  );
  const [returnInspectionData] = useFetch(
    `AsmTmsVehicleRequestKeyPicks/GetAllKeysReturnInspectedBy`
  );

  const [value, setValue] = useState(1);
  const [okValue, setOkValue] = useState(1);

  const onChange = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  const onOkChange = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setOkValue(e.target.value);
  };

  const handleUpdateConfirmation = () => {
    setModalData({
      ...modalData,
      info: {
        message: "Are you sure you want to update the record?",
        title: "Confirmation",
        okText: "Yes",
      },
      icon: "question",
      open: true,
      disableCancel: false,
    });
  };
  
  const handleUpdate = async () => {
    if (modalData.open && modalData.info.okText === "Yes") {
      await updatePickupInsData();
    } else {
      setModalData({ ...modalData, open: false });
    }
  };

  const updatePickupInsData = async () => {
    await updating(
      `AsmTmsVehicleRequestKeyPicks/UpdateAsmTmsVehiclRequestKeyPickupsInspection?vrqIDpk=${states.selectedId}`,
      {
        vriRequestIDfk: 1,
        vriPickupBrakesWorkingCorrectly: true,
        vriPickupBumpersInGoodCondition: true,
        vriPickupHornWorkingProperly: true,
        vriPickupSeatbeltsInGoodCondition: true,
        vriPickupWariningTriangleAvailable: true,
        vriPickupWindowsControlsWorking: true,
        vriPickupInteriorIsClean: true,
        vriPickupCracksOnWindscreen: true,
        vriPickupSideMirrorsInGoodCondition: true,
        vriPickupBrakeLightsWorkingCorrectly: true,
        vriPickupHeadlightsWorkingCorrectly: true,
        vriPickupIndicatorLightsWorkingCorrectly: true,
        vriPickupReverseLightsWorkingCorrectly: true,
        vriPickupAllInsideLightsWorkingCorrectly: true,
        vriPickupTyresInGoodCondition: true,
        vriPickupSpareTyreAvailable: true,
        vriPickupToolsForReplacingTyresAvailable: true,
        vriPickupBrakeFluidLevelOk: true,
        vriPickupEngineOilLevelOk: true,
        vriPickupPowerSteeringFluidLevelOk: true,
        vriPickupCoolantLevelOk: true,
        vriPickupFireExtinguisherAvailable: true,
        vriPickupFullyStockedFirstAidKidAvailable: true,
        vriPickupBatteryOk: true,
        vriPickupAnyLeakages: true,
        vriPickupWipersInGoodCondition: true,
        vriPickupAnyDents: true,
        vriPickupAnyScratches: true,
        vriPickupInsuranceValid: true,
        vriPickupRoadworthyValid: true,
        vriPickupDriversLicensePresentAndValid: true,
        vriPickupAllFloorMatsIntact: true,
        vriPickupAcInGoodCondition: true,
        vriPickupInspectorEmpIDfk: 1,
        vriPickupInspectionTime: states.inspectionDate,
        vriVehiclePickupConditionOk: true,
        vriPickupInspectionRmks: states.remarks,
        vriEditedBy: 0,
        vriEditedDate: states.inspectionDate,
      },
      ""
    );
    setModalData({
      ...modalData,

      open: false,
    });

    refreshState && refreshState();
    setIcon("success");
    setModalData({
      ...modalData,
      info: {
        message: "Record updated Successfully",
        title: "Success",
        okText: "ok",
      },
      icon: "success",
      open: true,

      disableCancel: true,
    });
  };

  return (
    <div className="">
       <ModalTemplate
        icon={modalData.icon}
        disableCancel={modalData.disableCancel}
        open={modalData.open}
        okHandler={() => {
          handleUpdate();
          setModalData({ ...modalData, open: false });
        }}
        cancelHandler={() => {
          setModalData({ ...modalData, open: false });
        }}
        message={modalData.info.message}
        okText={modalData.info.okText}
        title={modalData.info.title}
      />
      <section className="px-2">
        <div className="font-semibold bg-slate-100 px-1">
          Vehicle Inspections
        </div>
        <Form
          name="basic"
          labelCol={{ span: 2 }}
          size="small"
          autoComplete="off"
        >
          <Form.Item label="Action" className="bg-stone-100 px-2 py-1">
            <div className="flex justify-between">
              <div className="flex ">
                <div
                  className="border rounded px-2 bg-white"
                  style={{ maxWidth: "350px" }}
                >
                  <Radio.Group
                    onChange={handleInspectionTypeChange}
                    value={inspectionType === "pickup" ? 1 : 2}
                    className="flex justify-between"
                  >
                    <Radio value={1}>Pickup Inspection</Radio>
                    <Radio value={2}>Return Inspection</Radio>
                  </Radio.Group>
                </div>
                <span className="ml-8 flex">
                  <CheckboxTemlate withBorders={true} customDisabled={false}  useCallbackFunc setCheckboxOnchange={(e)=>{setState("checkAll", e); populateField(e)}} />
                  <p className="ml-1">Check All</p>
                </span>
              </div>
              <span className="ml-8 flex">
                <p className="mr-4">Legend :</p>
                <CheckboxTemlate withBorders={true} defaultValue={true} customDisabled={false} />
                <p className="ml-1">Yes</p>

                <span className="ml-8 flex">
                  <CheckboxTemlate withBorders={true} defaultValue={false} customDisabled={false} />
                  <p className="ml-1">No</p>
                </span>

                <span className="ml-8 flex">
                  <CheckboxTemlate withBorders={true} customDisabled={false} indeterminate={indeterminate} />
                  <p className="ml-1">Unanswered</p>
                </span>
              </span>
            </div>
          </Form.Item>
        </Form>
      </section>

     <CheckBoxForm statesx = {states} updateStatesx ={setState} />
      <div className=" border-b-2 px-12"></div>
      <div className="px-2 py-2 flex">
        <section>
          <Form
            className="w-full rounded flex flex-col"
            name="basic"
            labelCol={{ span: 10 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            size="small"
          >
             <SelectsTemplate
              idexpr={"empIdpk"}
              dataexp={"empName"}
              label="Pickup Inspection By"
              placeHolder={states.pickupInspection.name}
              selectedValue={(e: any) => {
                setState("pickupInspection", {
                  id: e.empIdpk,
                  name: e.empName,
                });
              }}
              options={pickupInspectionData}
              disabled={inspectionType === "return"}
              useCallFunc={true}
              style={{ width: 450 }}
              selectStyle={{ width: 450 }}
              handleRefresh={() => {
                setState("pickupInspection", { id: 0, name: "" });
                setState("refresh", !states.refresh);
              }}
            />
            <SelectsTemplate
              label="Return Inspection By"
              idexpr={"empIdpk"}
              dataexp={"empName"}
              placeHolder={states.returnInspection.name}
              selectedValue={(e: any) => {
                setState("returnInspection", {
                  id: e.empIdpk,
                  name: e.empName,
                });
              }}
              options={returnInspectionData}
              disabled={inspectionType === "pickup"}
              useCallFunc={true}
              style={{ width: 450 }}
              selectStyle={{ width: 450 }}
              handleRefresh={() => {
                setState("returnInspection", { id: 0, name: "" });
                setState("refresh", !states.refresh);
              }}
            />
            <DateTemplate
              label="Pickup Inspection Time"
              selectedDate={dayjs(states.inspectionDate)}
              changeDate={(date: Dayjs) => {
                setState("inspectionDate", date.format());
              }}
              showTime={true}
              width={250}
              span={false}
              style={{ paddingBottom: 0, paddingTop: 0 }}
              disabled={inspectionType === "return"}
            />
            <DateTemplate
              label="Return Inspection Time"
              selectedDate={dayjs(states.returnDate)}
              changeDate={(date: Dayjs) => {
                setState("returnDate", date.format());
              }}
              showTime={true}
              width={250}
              span={false}
              style={{ paddingBottom: 0, paddingTop: 0 }}
              disabled={inspectionType === "pickup"}
            />

            <Form.Item label="Vehicle Condition" style={{ width: "450px" }}>
              <div
                className="border  rounded px-2 "
                style={{ maxWidth: "235px" }}
              >
                <Radio.Group
                  onChange={onOkChange}
                  value={okValue}
                  className="flex justify-between"
                >
                  <Radio value={1}>Ok</Radio>
                  <Radio value={2}>Not Ok</Radio>
                </Radio.Group>
              </div>
            </Form.Item>
          </Form>
        </section>

        <section className="ml-4">
          <Form
            className="w-full rounded flex flex-col"
            name="basic"
            labelCol={{ span: 8 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            size="small"
          >
            <TextareaTemplate
              label="Remarks"
              defaultValue={states.remarks}
              setCallbackValue={(e: any) => {
                setState("remarks", e);
              }}
              useCallbackFunc={true}
              height={80}
              MarginLeft={"75px"}
              width={"240px"}
              disabled={false}
            />
            <InputsTemplate
              label="User's Password"
              defaultValue={states.password}
              disabledStatus={false}
              useCallbackFunc={true}
              style={{ width: 400 }}
              span
              additionalWidget={
                <>
                  <span
                    onClick={() => {}}
                    style={{
                      height: 22.5,
                      marginBottom: 2,
                      borderWidth: 1,
                      width: 24,
                    }}
                    className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                  >
                    <img
                      className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                      src={correct}
                      alt="correct"
                    />
                  </span>
                </>
              }
              inputStyle={{ width: 450 }}
            />
            <Form.Item
              label=""
              colon={false}
              style={{ width: "450px", marginLeft: "75px" }}
            >
              <div
                className="flex justify-end  rounded "
                style={{ maxWidth: "297px" }}
              >
                <UpdateButton handleUpdate={handleUpdateConfirmation} />
              </div>
            </Form.Item>
          </Form>
        </section>
      </div>
    </div>
  );
};
