import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { RootState } from "../../../../../app/store";
import { setGeneralValue } from "../../../../../features/generalSlice";
import useFetch from "../../../../../hooks/useFetch";
import  Datagrid_template from "../../../../../templates/Datagrid";
import { navbar_height,  saveModalData,  toolbar_height } from "../../../../accessories/component_infos";
import CustomLoader from "../../../../accessories/custom-loader";
import { HrSetupsCustomToolbar } from "../../../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import { authorise_DetailsColumns } from "./data/authoriseDetailsColumns";
import AuthorizeDtailsForm from "./widgets/authoriseDetailsForm";
import { AuthoriseToolbar } from "./widgets/authoriseToolbar";
import useResizeMode from "../../../../../hooks/useResizeMode";
import { WarningAlert } from "../../../../accessories/warningAlert";
import { useAccountInfo } from "../../../../../hooks/useAccountInfo";
import { useFormPriviledge } from "../../../../../hooks/useFormPriviledge";
import { ModalTemplate } from "../../../../../templates/modal";
import dayjs from "dayjs";
import {  searchTextTypes } from "../../../../human-resource/setups/data/types/selectsTypes";


export const AuthorizeFuelRequestDetails = forwardRef(({a}:any, ref)=>{
    const news_flash_height = useSelector((state:RootState)=>state.general.newsflashHeight);
    const dispatch = useDispatch();  //dispatch on row click
    const[employeeId,userId, userInfo]=useAccountInfo();
  const [privType, setPrivType] = useState<string>("save")
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType,)

  //date
  const genFormat = 'YYYY-MM-DD';
  const today = dayjs().format(genFormat);
  const initialStart = dayjs().subtract(5, 'year').startOf('year').format(genFormat)
  const [startDate, setStartDate] = useState<searchTextTypes>({ text: initialStart, temp: initialStart });
  const [endDate, setEndDate] = useState<searchTextTypes>({ text: today, temp: today })


 const [searchText, setSearchText] = useState<searchTextTypes>({ text: '', temp: '' })

    //disable save and upload on page load
    useEffect(()=>{
        dispatch(setGeneralValue({expr:'formDisabled',value:true})) //enable fields and update on redux
        dispatch(setGeneralValue({expr:'updateMode',value:false})) //enable fields and update on redux  
    },[])

    //navigation
    const setupPageRoute = window.location.pathname; //get current route of page
    const datagridOnlySearchParam = 'table-only'  //search param in datagrid mode
    const formModeSearchParam = 'form-and-table' //search param in form mode mode
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    //Resizing
    const[initialH,fullH] = useResizeMode(searchParams.get('view') === formModeSearchParam || searchParams.get('view') === (null) )

    //miscellaneous heights
    const miscHeight = 7;
    
    // All heights out
    const heights_out = navbar_height + news_flash_height + toolbar_height;

    //varying form height
    const [form_height,set_form_height] = useState<number>(245);
    const pass_form_height=(height:number)=>{
        set_form_height(height)
    }
    const [refresh, setRefresh] = useState(true);
    // Datagrid data
    const [data, err, loading] = useFetch(`AsmTmsEntAuthorizeFuelRequest/GetFuelUsages?vdtIdpk=${1}&startdate=${startDate.text}&enddate=${endDate.text}`, refresh);
    console.log('ttt',data)
    
    // Page resize height responsiveness
    // on page resize height responsiveness
    const [trigger,setTrigger] = useState<boolean>(false);
    // useImperativeHandle(ref, () => ({
    //     test: () => {
    //         settableHeight(fullH - (heights_out + miscHeight -104));
    //     }
    // }));

    // handle interface switch
    const toggle_staff_form = ():void =>{
        const view = searchParams.get('view');

        (view === null || view === formModeSearchParam) ? 
        navigate({
            pathname : setupPageRoute,
            search : createSearchParams({
                view : datagridOnlySearchParam,
            }).toString(),
        })
        :
        navigate({
            pathname : setupPageRoute,
            search : createSearchParams({
                view : formModeSearchParam,
            }).toString(),
        });
    }

    //priviledge
    const doubleClickAcessPriv = (e:any) => {
        setPrivType('update')
        if (readPriv) {
            childRef.current.doubleClick();
        } else {
            setIcon('warning')
            setOpenModal(true)
            setModalData({ title: 'Access Denied!', message: 'No Access Privilege' })
        }
    }

    //Table Height
    var pageHeight = initialH - (navbar_height + miscHeight) ; //height without navbar
    var tableHeight= fullH //height without navbar and toolbar


    const childRef = useRef<any>()
    var formHeight = initialH - (form_height + heights_out + miscHeight)
    // useResizeMode(formHeight)

    const [openModal, setOpenModal] = useState(false);
    const [modalData, setModalData] = useState<any>(saveModalData);
    const [icon, setIcon] = useState<'warning' | 'success' | 'question'>();
    const [updatingstatus, setUpdatingstatus] = useState(false);
    const [showCancel, setshowCancel] = useState(false);

    return(
        <>
        {err && (<WarningAlert/>)}
        <ModalTemplate icon_width={modalData.iconSize === 'large' ? 100 : 50} icon={icon} cancelText={modalData.cancelText}
      disableCancel={!showCancel}
      cancelHandler={() => { setOpenModal(false) }} open={openModal} okHandler={() => { setOpenModal(false) }}
      message={modalData.message} okText={modalData.okText} title={modalData.title} />
        {loading ?
        <CustomLoader/>
        :
       <div className="flex flex-row h-full">
        
           <section style={{width : 'calc(100%)' ,height : pageHeight}} className="w-full">
                <div className="px-2">
                    <AuthoriseToolbar toggler={toggle_staff_form}
                     startDate={startDate.temp}
                     endDate={endDate.temp}
                     startDateChanged={(date) => setStartDate({ temp: dayjs(date).format(genFormat), text: startDate.text })}
                     endDateChanged={(date) => setEndDate({ temp: dayjs(date).format(genFormat), text: endDate.text })}
                     handleFind={() => {
                        //  setSearchText({ temp: searchText.temp, text: searchText.temp });
                        setStartDate({ text: startDate.temp, temp: startDate.temp });
                        setEndDate({ text: endDate.temp, temp: endDate.temp });
                     }}
                    />
                </div>

                {/* form section */}
                {(searchParams.get('view') !== datagridOnlySearchParam) &&
                <section style={{height : form_height}}  className=" form border-b-4">
                    <AuthorizeDtailsForm ref={childRef} refresh={()=>{setRefresh(!refresh)}} />
                </section>}

                {/* datagrid section */}
                <section className=" px-2">
                    <Datagrid_template 
                    gridheight= {searchParams.get('view') === formModeSearchParam || searchParams.get('view') === (null) ?  formHeight : tableHeight - 190} 
                    data = {data} 
                    disablePaging={false} 
                    rowDoubleClicked={(e:any) => {
                        doubleClickAcessPriv(e)
                        //console.log(e);
                        
                                     
                    }}
                    onRowClick={(e) => {
                      
                        childRef?.current?.singleClick(e);
                    }}
                    columns = {authorise_DetailsColumns}/>
                </section>
           </section>

        
       </div>
}
       </>
    )
})


