import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncGet} from "../../../functions/get";
interface M_Materials{
    job:any|null|undefined;
    material:any|null|undefined;
    unit:any|null|undefined;
    currentPrice:any|null|undefined;
    isError: boolean,
    isLoading: boolean,
    isPending: boolean,
}
const initialState: M_Materials = {
    job:[],
    material:[],
    unit:[],
    currentPrice:[],
    isError: false,
    isLoading: false,
    isPending: false,
}

export const Fetch_Job = createAsyncThunk<M_Materials,string,{rejectValue:string}>(
    'job/fetch',
    async (search:string)=>{
        try{
            var jobResponse = await AsyncGet(`AsmTmsEntMaintenanceActivity/GetEntAsmTmsPopLueJobs?search=${search}&pagenumber=1&pagesize=20`);
            if(jobResponse.status === true){
                return jobResponse.data;
            }
        }catch{
            return jobResponse.data;
        }
    }
)
export const Fetch_Materials = createAsyncThunk <M_Materials,string,{rejectValue:string}>(
    'material/fetch',
    async (search:string)=>{
        try{
            var MaterialResponse = await AsyncGet(`AsmTmsEntMaintenanceActivity/GetEntAsmTmsLueMaterials?pagenumber=1&pagesize=20&search=${search}`);
            if(MaterialResponse.status === true){
                return MaterialResponse.data;
            }
        }catch{
            return MaterialResponse.data;
        }
    }
)
export const Fetch_unit = createAsyncThunk <M_Materials,string,{rejectValue:string}>(
    'unit/fetch',
    async (search:string)=>{
        try{
            var unitResponse = await AsyncGet(`AsmTmsEntMaintenanceActivity/GetEntAsmTmsLueUnits?pagenumber=1&pagesize=20&search=${search}`);
            if(unitResponse.status === true){
                return unitResponse.data;
            }
        }catch{
            return unitResponse.data;
        }
    }
)
export const Fetch_CurrentPrice = createAsyncThunk <M_Materials,string,{rejectValue:string}>(
    'price/fetch',
    async (search:string)=>{
        try{
            var currentPriceResponse = await AsyncGet(`AsmTmsEntMaintenanceActivity/GetEntAsmTmsLueCurrency?pagenumber=1&pagesize=20&search=${search}`);
            if(currentPriceResponse.status === true){
                return currentPriceResponse.data;
            }
        }catch{
            return currentPriceResponse.data;
        }
    }
)
const  M_MaterialSlice = createSlice({
    name:'M_Materials',
    initialState: initialState,
    reducers:{},
    extraReducers(builder) {
        builder
        //----------------Fetch_Job--------------------
        .addCase(Fetch_Job.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(Fetch_Job.fulfilled, (state, action) => {
            state.isLoading = false;
            state.job = action.payload;
        })
        .addCase(Fetch_Job.rejected, (state) => {
            state.isLoading = false;
            state.isError = true;
        })
        //--------------Fetch_Materials----------------
        .addCase(Fetch_Materials.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(Fetch_Materials.fulfilled, (state, action) => {
            state.isLoading = false;
            state.material = action.payload;
        })
        .addCase(Fetch_Materials.rejected, (state) => {
            state.isLoading = false;
            state.isError = true;
        })
        //--------------Fetch_unit---------------------
        .addCase(Fetch_unit.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(Fetch_unit.fulfilled, (state, action) => {
            state.isLoading = false;
            state.unit = action.payload;
        })
        .addCase(Fetch_unit.rejected, (state) => {
            state.isLoading = false;
            state.isError = true;
        })
        //---------------Fetch_CurrentPrice-----------------
        .addCase(Fetch_CurrentPrice.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(Fetch_CurrentPrice.fulfilled, (state, action) => {
            state.isLoading = false;
            state.currentPrice = action.payload;
        })
        .addCase(Fetch_CurrentPrice.rejected, (state) => {
            state.isLoading = false;
            state.isError = true;
        })
    },
})
export default M_MaterialSlice.reducer