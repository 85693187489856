/* eslint-disable react/jsx-pascal-case */
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import Setups_Template from '../.setups_templates/Setups_Template';
import useFetch from '../../../../hooks/useFetch';
import { hr_setups_EmployeeCategories_columns } from './data/HrSetupsEmployeeCategoriesData';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../app/store';
import { setValue, tier1SaveOnclick, tier1UpdateOnclick } from '../../../../features/forms/tier1/createSlice';
import { setGeneralValue } from '../../../../features/generalSlice';
import { validationsData, saveModalData, updateModalData, deleteModalData, api_url, putError, postError } from '../../../accessories/component_infos';
import { exprsTypes, modalTypes, searchTextTypes } from '../data/types/selectsTypes';
import CustomLoader from '../../../accessories/custom-loader';
import { ModalTemplate } from '../../../../templates/modal';
import { WarningAlert } from '../../../accessories/warningAlert';
import { useBusyLoader } from '../../../../hooks/useBusyLoader';
import React from 'react';
import { useAccountInfo } from '../../../../hooks/useAccountInfo';
import { useCrudFunc } from '../../../../functions/crud';
import { EctInterface } from '../../../../interfaces';


export const Hr_Setups_EmployeeCategories = React.forwardRef(({ a }: any, ref) => {
    const [PostDataFunc, UpdateDataFunc, PathDataFunc] = useCrudFunc(); //crud functions [post,update,patch
    const activeStatus = useSelector((state: RootState) => state.general.activeStatus);
    const postUrl: string = 'HrEmployeeCategory/CreateHrEmployeeCategory';
    const updateUrl: string = 'HrEmployeeCategory/UpdateHrEmployeeCategory'
    const saveSuccessText = { message: 'Employee category added Successfully', ok: 'Ok', title: 'Success' } // successful save modal message
    const updateSuccessText = { message: 'Employee category updated Successfully', ok: 'Ok', title: 'Success' } // successful update modal message

    const [busyLoader, setBusyLoader] = useBusyLoader();
    const [employeeId, userId] = useAccountInfo();
    // store data expressions[unique] ...dependent on data coming in
    const idExpr: string = 'ectIdpk'
    const nameExpr: string = 'ectName'
    const shortnameExpr: string = 'ectShtName'
    const orderExpr: string = 'ectOrder';
    const activeExpr: string = 'ectActive';
    const remarksExpr: string = 'ectRmks';
    const inputsExprsData: any = { idExpr: idExpr, nameExpr: nameExpr, shortnameExpr: shortnameExpr, orderExpr: orderExpr, activeExpr, remarksExpr: remarksExpr }

    const storeExprs = () => {
        const exprs = [{ expr: 'existsUrl', value: `HrEmployeeCategory` }, { expr: 'idExpr', value: idExpr }, { expr: 'nameExpr', value: nameExpr }, { expr: 'shortnameExpr', value: shortnameExpr }]
        //to particular redux store based on form type
        for (let i = 0; i < exprs.length; i++) {
            dispatch(setValue({ expr: exprs[i].expr, value: exprs[i].value }));
        }

        // to general slice
        const generalExprs: exprsTypes[] = [{ expr: 'inputExprs', value: inputsExprsData }]
        for (let i = 0; i < generalExprs.length; i++) {
            dispatch(setGeneralValue({ expr: generalExprs[i].expr, value: generalExprs[i].value }));
        }
    }

    const dispatch = useDispatch();
    const [refresh, setRefresh] = useState<boolean>(false); //refresh data state for data creation or update
    const [status, setStatus] = useState<{ temp: boolean | string, active: boolean | string }>({
        temp: '',
        active: ''
    });
    const [searchText, setSearchText] = useState<searchTextTypes>({ temp: '', text: '' });

    const getUrl = status.active === '' ? `HrEmployeeCategory/GetAllHrEmployeeCategory?fewColn=false&ectName=${searchText.text}` : status.active === true ? `HrEmployeeCategory/GetActiveHrEmployeeCategory?fewColn=false&ectName=${searchText.text}` : `HrEmployeeCategory/GetInActiveHrEmployeeCategory?fewColn=false&ectName=${searchText.text}`;



    //modal states
    //--general--
    const triggerSuccessModal = useSelector((state: RootState) => state.tier1Add.successModalState); //trigger success modal
    const SuccessModalText = useSelector((state: RootState) => state.tier1Add.successText); //success modal text

    //--for backend errors--
    const [errState, setErrState] = useState<boolean>(false);
    const [errTitle, seterrTitle] = useState<string>('');
    const [errMessage, seterrMessage] = useState<string>('');

    //--warning modal--
    const triggerwarningModal = useSelector((state: RootState) => state.tier1Add.warningState); //trigger warning
    const warningInfos: any = validationsData; //warning type details
    const warningDataExpr = useSelector((state: RootState) => state.tier1Add.warningDataExpr); //warning type selector

    //--save data modal--
    const saveData = saveModalData; //save modal details[data]
    const triggersaveModal = useSelector((state: RootState) => state.tier1Add.saveDataOnclick); //trigger save modal

    //--Update data modal--
    const updateIndInfos = updateModalData; //update modal details[data]
    const triggerupdateModal: boolean = useSelector((state: RootState) => state.tier1Add.updateDataOnclick); //trigger update question

    //--Update data modal--
    const deleteDataInfos = deleteModalData; //update modal details[data]
    const triggerdeleteModal: boolean = useSelector((state: RootState) => state.tier1Add.deleteDataOnclick); //trigger update question

    // on page resize height responsiveness
    const [trigger, setTrigger] = useState<boolean>(false);
    useImperativeHandle(ref, () => ({
        test: () => {
            setTrigger(!trigger);
        }
    }));

    // get current values from  [slice dependent on form type]
    const name = useSelector((state: RootState) => state.tier1Add.name);
    const shortname = useSelector((state: RootState) => state.tier1Add.shortname);
    const order = useSelector((state: RootState) => state.tier1Add.order);
    const active = useSelector((state: RootState) => state.tier1Add.active);
    const remarks = useSelector((state: RootState) => state.tier1Add.remarks);
    const selectedID = useSelector((state: RootState) => state.general.SelectedFieldID);

    const [Rowdata, setRowdata] = useState<EctInterface>();

    //post form Data
    //validate data
    const validateDataSave = () => {
        dispatch(tier1SaveOnclick());
    }
    // post
    const postData = async () => {
        dispatch(setValue({ expr: 'saveDataOnclick', value: false })) //disable modal
        try {
            setBusyLoader('Saving record...');

            const res = await PostDataFunc(`${postUrl}`, {
                ectIdpk: 0,
                ectName: name,
                ectShtName: shortname,
                ectActive: active,
                ectRmks: remarks,
                ectorder: order,
            })

            setRefresh(!refresh);
            setBusyLoader('') //disable busy loader

            const exprs: exprsTypes[] = [{ expr: 'saveDataOnclick', value: false }, { expr: 'successModalState', value: true }, { expr: 'successText', value: saveSuccessText }];
            for (let i = 0; i < exprs.length; i++) {
                dispatch(setValue({ expr: exprs[i].expr, value: exprs[i].value }));
            }
        } catch (error: any) {
            console.log(error);
            setBusyLoader('')

            //set states for db errors modal
            setErrState(true);
            seterrTitle(postError.title);
            seterrMessage(postError.message);
        }
    }

    //Update Form data
    //validate data
    const validateDataUpdate = () => {
        dispatch(tier1UpdateOnclick());
    }
    // update
    const UpdateData = async () => {
        console.log("Rowdata: ", Rowdata);

        dispatch(setValue({ expr: 'updateDataOnclick', value: false })) //disable modal
        try {
            setBusyLoader('Updating record...');
            const res = await UpdateDataFunc(`${updateUrl}`, {
                ectIdpk: Rowdata?.ectIdpk,
                ectName: Rowdata?.ectName,
                ectShtName: Rowdata?.ectShtName,
                ectActive: Rowdata?.ectActive,
                ectRmks: Rowdata?.ectRmks,
                ectorder: Rowdata?.ectOrder,

            })

            // refresh datagrid data
            setRefresh(!refresh);

            setBusyLoader('') //disable busy loader

            //disable update mode
            dispatch(setGeneralValue({ expr: 'updateMode', value: false })); // dispatch fetched data to reduxstore

            const exprs: exprsTypes[] = [{ expr: 'updateDataOnclick', value: false }, { expr: 'successModalState', value: true }, { expr: 'successText', value: updateSuccessText }];
            for (let i = 0; i < exprs.length; i++) {
                dispatch(setValue({ expr: exprs[i].expr, value: exprs[i].value }));
            }
        } catch (error: any) {
            console.log(error);
            setBusyLoader('') //disable busy loader

            //set states for db errors modal
            setErrState(true);
            seterrTitle(putError.title);
            seterrMessage(putError.message);
        }
    }

    //modals data
    const modals: modalTypes[] = [
        //warning
        { disableCancel: true, icon: 'warning', open: triggerwarningModal, okHandler: () => dispatch(setValue({ expr: 'warningState', value: false })), cancelHandler: () => dispatch(setValue({ expr: 'warningState', value: false })), message: warningInfos[warningDataExpr!].message, cancelText: warningInfos[warningDataExpr!].cancelText, okText: warningInfos[warningDataExpr!].okText, title: warningInfos[warningDataExpr!].title },
        //backend error
        { disableCancel: true, icon: 'warning', open: errState, okHandler: () => setErrState(false), cancelHandler: () => setErrState(false), message: errMessage, cancelText: '', okText: 'Ok', title: errTitle },
        //success
        { disableCancel: true, icon: 'success', open: triggerSuccessModal, okHandler: () => dispatch(setValue({ expr: 'successModalState', value: false })), cancelHandler: () => dispatch(setValue({ expr: 'successModalState', value: false })), message: SuccessModalText.message, okText: SuccessModalText.okText, title: SuccessModalText.title },
        //save
        { disableCancel: false, icon: 'question', open: triggersaveModal, okHandler: () => postData(), cancelHandler: () => dispatch(setValue({ expr: 'saveDataOnclick', value: false })), message: saveData.message, cancelText: saveData.cancelText, okText: saveData.okText, title: saveData.title },
        //Update
        { disableCancel: false, icon: 'question', open: triggerupdateModal, okHandler: () => UpdateData(), cancelHandler: () => dispatch(setValue({ expr: 'updateDataOnclick', value: false })), message: updateIndInfos.message, cancelText: updateIndInfos.cancelText, okText: updateIndInfos.okText, title: updateIndInfos.title },
    ]

    // data for datagrid
    const [data, error, loading]: [EctInterface[], any, boolean] = useFetch(getUrl, refresh, 'ectIdpk');
    useEffect(() => {
        if (data) {
            storeExprs(); // run func to store expressions on store
            dispatch(setGeneralValue({ expr: 'idExpr', value: idExpr })); // dispatch fetched data to reduxstore
            dispatch(setGeneralValue({ expr: 'formData', value: data })); // dispatch fetched data to reduxstore
        }

        loading ? setBusyLoader('.') : setBusyLoader('')
    }, [data, loading])

    // form parameters
    const form_header = 'Employee Categories Details';

    return (
        <>
            {/* warning */}
            {error && (
                <WarningAlert />
            )}

            {modals.map(({ disableCancel, icon, okHandler, cancelHandler, open, cancelText, okText, message, title }: modalTypes, index) => {
                return (
                    <div key={index}>
                        <ModalTemplate icon={icon && icon} disableCancel={disableCancel} cancelText={cancelText && cancelText} open={open} okHandler={okHandler} cancelHandler={cancelHandler} message={message} okText={okText} title={title} />
                    </div>
                )
            })}

            <Setups_Template
                searchTextChange={(newText) => {
                    setSearchText({ temp: newText, text: searchText.text })
                }}
                handleSearch={() => {
                    setSearchText({ text: searchText.temp, temp: searchText.temp })
                    setStatus({ temp: status.temp, active: status.temp })
                }}
                checkOnChange={(value) => {
                    setStatus({ temp: value, active: status.active });
                }}
                handleRefresh={() => {
                    setRefresh(!refresh);

                    setSearchText({ temp: '', text: '' });
                    setStatus({ temp: '', active: '' });
                }}
                createData={validateDataSave}
                updateData={validateDataUpdate}
                trigger={trigger}
                datagridColumns={hr_setups_EmployeeCategories_columns}
                datagridData={data}
                formHeader={form_header}
                datagridClicked={(e) => {
                    setRowdata(e?.data as EctInterface);
                }}

            />
        </>
    );
})