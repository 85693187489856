import { Select, Input } from "antd";
import { DataGrid } from "devextreme-react";
import { Pager, Column, Scrolling } from "devextreme-react/data-grid";
import newIcon from "../../../../../../../../assets/new.png";
import refresh from "../../../../../../../../assets/refresh.png";
import { vehicleSimCard_TH } from "../../../../Vehicles/Vehicle-Requests/data/vehicle-requests-data";

export const Fuel_Management_Attachments = () => {
  return (
    <div className="w-full h-full">
      <ul className="w-full flex justify-evenly items-center">
        <li className="justify-center items-center">
          <p>Find</p>
        </li>
        <li className="justify-center items-center">
          <Select className="w-28 mt-0.5" size="small" />
        </li>
        <li className="justify-center items-center">
          <Input className="w-24" size="small" />
        </li>
        <li className="justify-center items-center">
          <img alt="new-icon" src={newIcon} />
        </li>
        <li className="justify-center items-center">
          <img alt="refresh-icon" className="w-4" src={refresh} />
        </li>
      </ul>

      <div className=" h-full">
        <DataGrid
          dataSource={[]}
          showBorders={true}
          columnWidth={150}
          showRowLines={true}
          showColumnLines={true}
          className="h-full"
        >
          <Pager visible={false} />

          {/* STAFF DETAILS COLUMNS */}
          {/* FIXED COLUMNS */}

          {vehicleSimCard_TH.map((data) => {
            return (
              <Column
                key={data.id}
                caption={data.caption}
                dataField={data.dataField}
                alignment={data.alignment as any}
                fixed={data.fixed}
                width={150}
              />
            );
          })}

          {/* SCROLLBAR  */}

          <Scrolling columnRenderingMode="virtual" />
        </DataGrid>
        <div className="text-xs text-indigo-500 p-1">Done</div>
      </div>
    </div>
  );
};
