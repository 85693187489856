import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Setups_Template from "../.setups_templates/Setups_Template";
import useFetch from "../../../../hooks/useFetch";
import { hr_setups_Gender_columns } from "./data/HrSetupsGendersData";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import axios from "axios";
import {
  api_url,
  postError,
  putError,
  saveModalData,
  updateModalData,
  validationsData,
} from "../../../accessories/component_infos";
import { useDispatch } from "react-redux";
import {
  tier1SaveOnclick,
  setValue,
  tier1UpdateOnclick,
  tier1DeleteOnclick,
} from "../../../../features/forms/tier1/createSlice";
import { ModalTemplate } from "../../../../templates/modal";
import {
  exprsTypes,
  modalTypes,
  searchCriteriaTypes,
  searchTextTypes,
} from "../data/types/selectsTypes";
import { setGeneralValue } from "../../../../features/generalSlice";
import CustomLoader from "../../../accessories/custom-loader";
import React from "react";
import { WarningAlert } from "../../../accessories/warningAlert";
import { useBusyLoader } from "../../../../hooks/useBusyLoader";
import { useAccountInfo } from "../../../../hooks/useAccountInfo";
import { useCrudFunc } from "../../../../functions/crud";
import dayjs from "dayjs";

export const Hr_Setups_Genders = React.forwardRef(({ a }: any, ref) => {
  const [busyLoader, setBusyLoader] = useBusyLoader();

  const [PostFunc, UpdateFunc] = useCrudFunc();

  const [employeeId, userId] = useAccountInfo();

  const activeStatus = useSelector(
    (state: RootState) => state.general.activeStatus
  );
  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });
  const [searchCriteriaIndex, setSearchCriteriaIndex] =
    useState<searchCriteriaTypes>({ temp: 0, index: 0 });
  const [status, setstatus] = useState<boolean | string>("");

  const saveSuccessText = {
    message: "Gender added Successfully",
    ok: "Ok",
    title: "Success",
  }; // successful save modal message
  const updateSuccessText = {
    message: "Gender updated Successfully",
    ok: "Ok",
    title: "Success",
  }; // successful update modal message

  // store data expressions[unique] ...dependent on data coming in
  const idExpr: string = "gndIdpk";
  const nameExpr: string = "gndName";
  const shortnameExpr: string = "gndshtName";
  const orderExpr: string = "gndOrder";
  const activeExpr: string = "gndActive";
  const remarksExpr: string = "gndRmks";
  const inputsExprsData: any = {
    idExpr: idExpr,
    nameExpr: nameExpr,
    shortnameExpr: shortnameExpr,
    orderExpr: orderExpr,
    activeExpr,
    remarksExpr: remarksExpr,
  };

  const storeExprs = () => {
    const exprs = [
      { expr: "existsUrl", value: `HrGender` },
      { expr: "idExpr", value: idExpr },
      { expr: "nameExpr", value: nameExpr },
      { expr: "shortnameExpr", value: shortnameExpr },
    ];
    //to particular redux store based on form type
    for (let i = 0; i < exprs.length; i++) {
      dispatch(setValue({ expr: exprs[i].expr, value: exprs[i].value }));
    }

    // to general slice
    const generalExprs: exprsTypes[] = [
      { expr: "inputExprs", value: inputsExprsData },
    ];
    for (let i = 0; i < generalExprs.length; i++) {
      dispatch(
        setGeneralValue({
          expr: generalExprs[i].expr,
          value: generalExprs[i].value,
        })
      );
    }
  };

  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState<boolean>(false); //refresh data state for data creation or update

  //modal states
  //--general--
  const triggerSuccessModal = useSelector(
    (state: RootState) => state.tier1Add.successModalState
  ); //trigger success modal
  const SuccessModalText = useSelector(
    (state: RootState) => state.tier1Add.successText
  ); //success modal text

  //--for backend errors--
  const [errState, setErrState] = useState<boolean>(false);
  const [errTitle, seterrTitle] = useState<string>("");
  const [errMessage, seterrMessage] = useState<string>("");

  //--warning modal--
  const triggerwarningModal = useSelector(
    (state: RootState) => state.tier1Add.warningState
  ); //trigger warning
  const warningInfos: any = validationsData; //warning type details
  const warningDataExpr = useSelector(
    (state: RootState) => state.tier1Add.warningDataExpr
  ); //warning type selector

  //--save data modal--
  const saveData = saveModalData; //save modal details[data]
  const triggersaveModal = useSelector(
    (state: RootState) => state.tier1Add.saveDataOnclick
  ); //trigger save modal

  //--Update data modal--
  const updateIndInfos = updateModalData; //update modal details[data]
  const triggerupdateModal: boolean = useSelector(
    (state: RootState) => state.tier1Add.updateDataOnclick
  ); //trigger update question

  // on page resize height responsiveness
  const [trigger, setTrigger] = useState<boolean>(false);
  useImperativeHandle(ref, () => ({
    test: () => {
      setTrigger(!trigger);
    },
  }));

  // get current values from  [slice dependent on form type]
  const name = useSelector((state: RootState) => state.tier1Add.name);
  const shortname = useSelector((state: RootState) => state.tier1Add.shortname);
  const order = useSelector((state: RootState) => state.tier1Add.order);
  const active =
    useSelector((state: RootState) => state.tier1Add.active) ?? false;
  const remarks = useSelector((state: RootState) => state.tier1Add.remarks);
  const selectedID = useSelector(
    (state: RootState) => state.general.SelectedFieldID
  );

  //post form Data
  //validate data
  const validateDataSave = () => {
    dispatch(tier1SaveOnclick());
  };
  // post
  const postData = async () => {
    setBusyLoader("Saving record...");
    dispatch(setValue({ expr: "saveDataOnclick", value: false })); //disable modal
    try {
      const res = await PostFunc(
        `HrGender/CreateHrGender`,
        {
          gndName: name,
          gndshtName: shortname,
          gndOrder: order,
          gndActive: active,
          gndRmks: remarks,
        },
        `Created the HR-Setup- gender form details with Name:${name}, shortName:${shortname}, order:${order}, activeStatus:${active} and remark:${remarks}. created By:${userId} Date created:${dayjs()}`
      );
      setRefresh(!refresh);
      setBusyLoader("");

      const exprs: exprsTypes[] = [
        { expr: "saveDataOnclick", value: false },
        { expr: "successModalState", value: true },
        { expr: "successText", value: saveSuccessText },
      ];
      for (let i = 0; i < exprs.length; i++) {
        dispatch(setValue({ expr: exprs[i].expr, value: exprs[i].value }));
      }
    } catch (error: any) {
      console.log(error);
      setErrState(true);
      setBusyLoader("");
      if (error.response.status === 500) {
        seterrTitle("Server Error");
        seterrMessage(
          "Error connecting to server. Please contact system administrator"
        );
      } else {
        //set states for db errors modal
        setErrState(true);
        seterrTitle(error.response.data.message);
        seterrMessage(error.response.data.errors[0]);
      }
    }
  };

  //Update Form data
  //validate data
  const validateDataUpdate = () => {
    dispatch(tier1UpdateOnclick());
  };
  // update
  const UpdateData = async () => {
    setBusyLoader("Updating record...");
    dispatch(setValue({ expr: "updateDataOnclick", value: false })); //disable modal
    try {
      console.log({
        gndIdpk: selectedID,
        gndName: name,
        gndshtName: shortname,
        gndOrder: order,
        gndActive: active,
        gndRmks: remarks,
        gndLastEditedBy: 1,
        gndLastEditedDate: "1983-06-01T17:22:05.380Z",
      });
      const res = await UpdateFunc(
        `HrGender/UpdateHrGender/${selectedID}`,
        {
          gndName: name,
          gndshtName: shortname,
          gndOrder: order,
          gndActive: active,
          gndRmks: remarks,
        },
        `Updated the HR-Setup- gender form details with Id:${selectedID}, Name:${name}, shortName:${shortname}, order:${order}, activeStatus:${active}, remark:${remarks} editedDate:${dayjs()}. Edited By:${userId}`
      );

      setRefresh(!refresh);
      setBusyLoader("");

      const exprs: exprsTypes[] = [
        { expr: "updateDataOnclick", value: false },
        { expr: "successModalState", value: true },
        { expr: "successText", value: updateSuccessText },
      ];
      for (let i = 0; i < exprs.length; i++) {
        dispatch(setValue({ expr: exprs[i].expr, value: exprs[i].value }));
      }
    } catch (error: any) {
      setErrState(true);
      setBusyLoader("");
      console.log(error);
      if (error.response.status === 500) {
        seterrTitle("Server Error");
        seterrMessage(
          "Error connecting to server. Please contact system administrator"
        );
      } else {
        //set states for db errors modal
        setErrState(true);
        seterrTitle(error.response.data.message);
        seterrMessage(error.response.data.errors[0]);
      }
    }
  };

  const validateDataDelete = () => {
    // dispatch(tier1DeleteOnclick());
  };
  // delete
  const deleteData = async () => {
    dispatch(setValue({ expr: "deleteDataOnclick", value: false })); //disable modal
    try {
      const res = await axios.put(`${api_url}/api/HrGender/UpdateHrGender`, {
        gndIdpk: selectedID,
        gndName: name,
        gndshtName: shortname,
        gndActive: active,
        gndLastEditedBy: 1,
        ndLastEditedDate: "1983-06-01T17:22:05.380Z",
      });

      setRefresh(!refresh);

      const exprs: exprsTypes[] = [
        { expr: "updateDataOnclick", value: false },
        { expr: "successModalState", value: true },
        { expr: "successText", value: updateSuccessText },
      ];
      for (let i = 0; i < exprs.length; i++) {
        dispatch(setValue({ expr: exprs[i].expr, value: exprs[i].value }));
      }
    } catch (error: any) {
      console.log(error);
      setBusyLoader("");

      //set states for db errors modal
      setErrState(true);
      seterrTitle(putError.title);
      seterrMessage(putError.message);
    }
  };

  //modals data
  const modals: modalTypes[] = [
    //warning
    {
      disableCancel: true,
      icon: "warning",
      open: triggerwarningModal,
      okHandler: () =>
        dispatch(setValue({ expr: "warningState", value: false })),
      cancelHandler: () =>
        dispatch(setValue({ expr: "warningState", value: false })),
      message: warningInfos[warningDataExpr!].message,
      cancelText: warningInfos[warningDataExpr!].cancelText,
      okText: warningInfos[warningDataExpr!].okText,
      title: warningInfos[warningDataExpr!].title,
    },
    //backend error
    {
      disableCancel: true,
      icon: "warning",
      open: errState,
      okHandler: () => setErrState(false),
      cancelHandler: () => setErrState(false),
      message: errMessage,
      cancelText: "",
      okText: "Ok",
      title: errTitle,
    },
    //success
    {
      disableCancel: true,
      icon: "success",
      open: triggerSuccessModal,
      okHandler: () =>
        dispatch(setValue({ expr: "successModalState", value: false })),
      cancelHandler: () =>
        dispatch(setValue({ expr: "successModalState", value: false })),
      message: SuccessModalText.message,
      okText: SuccessModalText.okText,
      title: SuccessModalText.title,
    },
    //save
    {
      disableCancel: false,
      icon: "question",
      open: triggersaveModal,
      okHandler: () => postData(),
      cancelHandler: () =>
        dispatch(setValue({ expr: "saveDataOnclick", value: false })),
      message: saveData.message,
      cancelText: saveData.cancelText,
      okText: saveData.okText,
      title: saveData.title,
    },
    //Update
    {
      disableCancel: false,
      icon: "question",
      open: triggerupdateModal,
      okHandler: () => UpdateData(),
      cancelHandler: () =>
        dispatch(setValue({ expr: "updateDataOnclick", value: false })),
      message: updateIndInfos.message,
      cancelText: updateIndInfos.cancelText,
      okText: updateIndInfos.okText,
      title: updateIndInfos.title,
    },
  ];

  // data for datagrid
  const [data, error, loading] = useFetch(
    `HrGender/GetAllHrGender?search=${searchText.text}&status=${status}`,
    refresh
  );

  useEffect(() => {
    if (data) {
      storeExprs(); // run func to store expressions on store
      dispatch(setGeneralValue({ expr: "idExpr", value: idExpr })); // dispatch fetched data to reduxstore
      dispatch(setGeneralValue({ expr: "formData", value: data })); // dispatch fetched data to reduxstore
    }

    loading ? setBusyLoader(".") : setBusyLoader("");
  }, [data, loading]);

  // form parameters
  const form_header = "Gender Details";

  return (
    <>
      {/* error */}
      {error && <WarningAlert />}

      {/* modals */}
      {modals.map(
        (
          {
            disableCancel,
            icon,
            okHandler,
            cancelHandler,
            open,
            cancelText,
            okText,
            message,
            title,
          }: modalTypes,
          index
        ) => {
          return (
            <div key={index}>
              <ModalTemplate
                icon={icon && icon}
                disableCancel={disableCancel}
                cancelText={cancelText && cancelText}
                open={open}
                okHandler={okHandler}
                cancelHandler={cancelHandler}
                message={message}
                okText={okText}
                title={title}
              />
            </div>
          );
        }
      )}

      {/* main form and table */}
      <Setups_Template
        searchTextChange={(newText) => {
          setSearchText({ temp: newText, text: searchText.text });
        }}
        handleSearch={() => {
          setSearchText({ temp: searchText.temp, text: searchText.temp });
          setstatus(activeStatus);
        }}
        handleRefresh={() => {
          setRefresh((current) => !current);

          setstatus("");
          setSearchText({ temp: "", text: "" });
        }}
        updateData={validateDataUpdate}
        createData={validateDataSave}
        formTier="1"
        trigger={trigger}
        datagridColumns={hr_setups_Gender_columns}
        datagridData={data}
        formHeader={form_header}
      />
    </>
  );
});
