import { useState, useEffect } from "react";

const useResizeMode = (IsToggled: boolean | number) => {
  const [resizeMode, setResizedMode] = useState<number>(0);
  const [initialResizeMode, setInitialResizedMode] = useState<number>(0);
  useEffect(() => {
    // let height = window.innerHeight;
    const handleResizing = () => {
      setResizedMode(window.innerHeight); //height
      setInitialResizedMode(window.innerHeight); // height
    };
    handleResizing(); // Get Default sizes
    window.addEventListener("resize", handleResizing);
    return () => {
      window.removeEventListener("resize", handleResizing);
      handleResizing(); // Get default sizes
    };
  }, [IsToggled]);
  return [resizeMode, initialResizeMode];
};
export default useResizeMode;
