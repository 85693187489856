import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetDataFunc } from "../../functions/get";


interface apiStates {
    dateData: any | null;
    deptData: any | null;
    sectiondata: any | null;
    isLoading: boolean;
    isPending: boolean;
    isError: boolean;
}
var initialState: apiStates = {
    dateData: [],
    deptData: [],
    sectiondata: [],
    isLoading: false,
    isPending: false,
    isError: false,
}

//fetch Date
export const fetchDate= createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "fetchDate/fetch",
    async (search: string) => {
        const res = await GetDataFunc(`AssetTamVehicleConditionReturn/GetDateVehicleConditionReturn?PageNumber=1&PageSize=10&search=${search}`);
        return res.data;
    }
);
//fetch fetchDept
export const fetchDept = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "fetchDept/fetch",
    async (search: string) => {
        const res = await GetDataFunc(`AssetTamVehicleConditionReturn/GetOrgAdminDeptAssetTamVehicleConditionReturn?PageNumber=1&PageSize=10&search=${search}`);
        return res.data;
    }
);
//fetch  fetchSection
export const fetchSection = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "fetchSection/fetch",
    async (search: string , formState:any) => {
        // console.log("statesAPI",formStates)
        const res = await GetDataFunc(`AssetTamVehicleConditionReturn/GetOrgAdminSxnAssetTamVehicleConditionReturn?Id=${formState?.dept?.id}&PageNumber=1&PageSize=10&search=${search}`);
        return res.data;
    }
);

const vehCondition = createSlice({
    name: 'vehConditionsApi',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // fetchDate
            .addCase(fetchDate.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchDate.fulfilled, (state, action) => {
                state.isLoading = false;
                state.dateData = action.payload;
            })
            .addCase(fetchDate.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            // fetchDept
            .addCase(fetchDept.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchDept.fulfilled, (state, action) => {
                state.isLoading = false;
                state.deptData = action.payload;
            })
            .addCase(fetchDept.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            // fetchSection
            .addCase(fetchSection.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchSection.fulfilled, (state, action) => {
                state.isLoading = false;
                state.sectiondata = action.payload;
            })
            .addCase(fetchSection.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
    }})
    export default vehCondition.reducer;