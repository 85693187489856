import { Form, Radio } from "antd";
import dayjs from "dayjs";
import { Tooltip } from "devextreme-react";
import { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import close from "../../../../../../../assets/close.png";
import edit from "../../../../../../../assets/edit.png";
import { setGeneralValue } from "../../../../../../../features/generalSlice";
import { DeleteDataFunc } from "../../../../../../../functions/delete";
import { GetDataFunc } from "../../../../../../../functions/get";
import { PostDataFunc } from "../../../../../../../functions/post";
import { UpdateDataFunc } from "../../../../../../../functions/update";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { useBusyLoader } from "../../../../../../../hooks/useBusyLoader";
import useFetch from "../../../../../../../hooks/useFetch";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import { BottomBtns } from "../../../../../../../templates/butttons";
import { CheckboxTemlate } from "../../../../../../../templates/checkbox";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { SelectsTemplate } from "../../../../../../../templates/select";
import { RichTextEditorTemplate } from "../../../../../../../templates/textEditor";
import { TextareaTemplate } from "../../../../../../../templates/textarea";
import {
  Cancel,
  NewButton,
  SaveButton,
  UpdateButton,
} from "../../../../../../accessories/buttons";
import {
  saveModalData,
  serverError,
  updateModalData,
} from "../../../../../../accessories/component_infos";
import SetupsDatalistTemplat from "../../../../../../human-resource/setups/data/setups_datalist_template";

interface props {
  data?: any;
  selectedData: any;
  updateMode: boolean;
  formDisabled: boolean;
  setUpdate: (state: boolean) => void;
  setDisabled: (state: boolean) => void;
  refresh: () => void;
  setOuterSelected?: (e: any) => void;
}

interface stateTypes {
  id: number;
  title: string;
  category: { id: number; value: string };
  question: string;
  remarks: string;
  practiceQns: boolean;
  multipleQ: boolean;
  active?: boolean;
  modalState?: any;
  confirmState?: any;
  successModal?: any;

  showAnswers: boolean;

  Answer: string;
  no_OfLine: number;
  correct_Answer?: boolean;
  second_Active?: boolean;
  all_Answer?: number;
  secondRemark?: string;

  disableForm: boolean;
  updateForm: boolean;
  selectedAnswer?: any;

  answersCount: number;
  answerDetailsForQuestion: any[];
  getAnswerError: boolean;

  refreshStates: { title: boolean; category: boolean; answerCount: boolean };
}

const ExamsQuestionsForm = forwardRef(
  (
    {
      data,
      selectedData,
      setOuterSelected,
      updateMode,
      formDisabled,
      refresh: refreshGrid,
      setDisabled,
      setUpdate,
    }: props,
    ref
  ) => {
    const [busyLoader, setBusyLoader] = useBusyLoader();
    const [employeeId, userID, userModel] = useAccountInfo();
    const borderTheme = useSelector(
      (state: RootState) => state.general.cssBorderClass
    );
    const [privType, setPrivType] = useState<string>("save");
    const [savePriv, updatePriv] = useFormPriviledge(privType);

    const defaultRichText = "<p></p>";

    const [states, setState] = useState<stateTypes>({
      id: 0,
      title: "",
      category: { id: 0, value: "" },
      question: defaultRichText,
      remarks: "",
      practiceQns: false,
      multipleQ: false,
      active: false,

      Answer: "",
      no_OfLine: 0,
      correct_Answer: false,
      second_Active: false,
      all_Answer: 0,
      secondRemark: "",
      showAnswers: false,
      disableForm: true,
      updateForm: false,
      selectedAnswer: {},
      modalState: {
        state: false,
        title: "",
        message: "",
      },
      successModal: {
        state: false,
        title: "",
        message: "",
      },
      confirmState: {
        state: false,
        title: "",
        message: "",
        okText: "",
        event: () => {},
      },

      answersCount: 0,
      answerDetailsForQuestion: [],
      getAnswerError: false,
      refreshStates: { title: false, category: false, answerCount: false },
    });

    useEffect(() => {
      updateState("disabledMode", !updateMode);
    }, [updateMode]);

    const {
      title,
      category,
      question,
      remarks,
      practiceQns,
      multipleQ,
      active,
      answerDetailsForQuestion,
      refreshStates,
      answersCount,
    } = states;

    const updateState = (stateName: string, value: any) => {
      setState((prevstate) => ({ ...prevstate, [stateName]: value }));
    };

    useEffect(() => {
      const get = async () => {
        if (states.id) {
          try {
            const getRes = await GetDataFunc(
              `AsmTmsExamsQuestion/GetAnswersForQuestionCount/${states.id}`
            );
            resetAnswers();
            updateState("disableForm", true);
            updateState("updateForm", false);
            updateState("answersCount", getRes.data);
            getAnswer(states.all_Answer ?? 1);
          } catch (error) {
            updateState("answersCount", 4);
          }
        }
      };
      get();
    }, [states.id, refreshStates.answerCount]);

    const newAccessPriv = () => {
      setPrivType("save");
      if (savePriv === true) {
        setOuterSelected && setOuterSelected({});

        populateFields(true);
        setDisabled(false);
        dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      } else {
        updateState("modalState", {
          state: true,
          message: "No Access Privileges",
          title: "Access Denied!",
        });
      }
    };

    const newAccessPriv2 = () => {
      setPrivType("save");
      if (savePriv === true) {
        resetAnswers();
        updateState("disableForm", false);
      } else {
        updateState("modalState", {
          state: true,
          message: "No Access Privileges",
          title: "Access Denied!",
        });
      }
    };

    const saveAccessPriv2 = () => {
      setPrivType("save");
      if (savePriv === true) {
        validateAnswer("secondPost");
      } else {
        updateState("modalState", {
          state: true,
          message: "No Access Privileges",
          title: "Access Denied!",
        });
      }
    };

    const updateAccessPriv2 = () => {
      setPrivType("update");
      if (updatePriv === true) {
        validateAnswer("secondUpdate");
      } else {
        updateState("modalState", {
          state: true,
          message: "No Access Privileges",
          title: "Access Denied!",
        });
      }
    };

    const populateFields = (mainClearFields: boolean = false) => {
      const populate = (clearFields: boolean) => {
        const stateInstances = [
          { stateName: "id", value: selectedData!["exqIdpk"], clear: 0 },
          { stateName: "title", value: selectedData!["exqTitle"], clear: "" },
          {
            stateName: "category",
            value: {
              id: selectedData!["excIdpk"],
              value: selectedData!["excName"],
            },
            clear: { id: 0, value: "" },
          },
          {
            stateName: "question",
            value: selectedData!["exqQuestion"],
            clear: defaultRichText,
          },
          { stateName: "remarks", value: selectedData!["exqRmks"], clear: "" },
          {
            stateName: "practiceQns",
            value: selectedData!["exqPracticeQuestion"],
            clear: false,
          },
          {
            stateName: "multipleQ",
            value: selectedData!["exqMultipleCorrectAnswers"],
            clear: false,
          },
          {
            stateName: "active",
            value: selectedData!["exqActive"],
            clear: true,
          },
        ];

        for (let instance of stateInstances)
          updateState(
            instance.stateName,
            clearFields ? instance.clear : instance.value
          );
      };

      mainClearFields
        ? populate(true)
        : selectedData &&
          typeof selectedData === "object" &&
          Object.keys(selectedData).length > 0
        ? populate(false)
        : populate(true);
    };

    useEffect(() => {
      if (selectedData) {
        populateFields(false);
      }
    }, [selectedData]);

    const radioButtons = [
      { id: 1, value: 1, name: "A" },
      { id: 2, value: 2, name: "B" },
      { id: 3, value: 3, name: "C" },
      { id: 4, value: 4, name: "D" },
      { id: 5, value: 5, name: "E" },
      { id: 6, value: 6, name: "F" },
      { id: 7, value: 7, name: "G" },
      { id: 8, value: 8, name: "H" },
      { id: 9, value: 9, name: "I" },
      { id: 10, value: 10, name: "J" },
      { id: 11, value: 11, name: "K" },
    ];

    const form_disable = useSelector(
      (state: RootState) => state.general.formDisabled
    );
    const update_state_change = useSelector(
      (state: RootState) => state.general.updateMode
    );

    const dispatch = useDispatch();
    const [refresh, setRefresh] = useState<boolean>(false);

    const [CategoryData, CategoryError, CategoryLoading] = useFetch(
      "AsmTmsExamsQuestion/GetAsmTmsExamQuestionExamCategory",
      refreshStates.category
    );

    const [AnswersData, AnswerError, AnswerLoading] = useFetch(
      "AsmTmsExamsQuestion/GetInActiveAsmTmsExamAnswer",
      refresh
    );

    if (AnswersData) {
      const encoding = new TextDecoder("utf-8");

      for (let i = 0; i < AnswersData.length; i++) {
        const byteArray = new Uint8Array(AnswersData[i].answer);
      }
    }

    const validate = async (method: "post" | "put") => {
      const {
        title: modalTitle,
        message: modalMessage,
        error,
      } = method === "post" ? saveModalData : updateModalData;

      try {
        setBusyLoader("Making Checks...");

        if (states.category.id === 0) {
          updateState("modalState", {
            state: true,
            message: "Please select Exams Category",
            title: "Required field",
          });
          return;
        }

        if (!title || title.trim() === "") {
          updateState("modalState", {
            state: true,
            message: "Please select exams Title",
            title: "Required field",
          });
          return;
        }

        if (!question || question === defaultRichText) {
          updateState("modalState", {
            state: true,
            message: "Please enter a question",
            title: "Enter Question",
          });
          return;
        }

        if (states.question?.length < 5) {
          updateState("modalState", {
            state: true,
            message: "Question must be at least three characters!",
            title: "Invalid Question Length",
          });
          return;
        }

        if (states.question.length < 400) {
          const questionRes = await GetDataFunc(
            `AsmTmsExamsQuestion/GetAsmTmsExamsQuestionWithParamQuestandTitle?exqQuestion=${states.question}&exqTitle=${states.title}`
          );

          if (questionRes.data?.length > 0) {
            if (method !== "put") {
              updateState("modalState", {
                state: true,
                message: "The answer you have entered already exists",
                title: "Answer Exists",
              });

              return;
            } else if (
              method === "put" &&
              states.id !== questionRes.data[0]?.exqIdpk
            ) {
              if (method !== "put" && states.id === questionRes.data[0]?.id) {
                updateState("modalState", {
                  state: true,
                  message: "The answer you have entered already exists",
                  title: "Answer Exists",
                });

                return;
              }
            }
          }
        }

        if (method === "post") {
          updateState("confirmState", {
            state: true,
            title: modalTitle,
            message: modalMessage,
            event: () => {
              postData();
            },
          });
        } else {
          updateState("confirmState", {
            state: true,
            title: modalTitle,
            message: modalMessage,
            okText: "Yes, Update",
            event: () => {
              updateQuestion();
            },
          });
        }
      } catch (ResponseError) {
        updateState("modalState", {
          state: true,
          message: error.message,
          title: error.title,
        });
      } finally {
        setBusyLoader("");
      }
    };

    const validateAnswer = async (method: "secondPost" | "secondUpdate") => {
      const {
        title: modalTitle,
        message: modalMessage,
        error,
      } = method === "secondPost" ? saveModalData : updateModalData;

      try {
        setBusyLoader("Making Checks...");

        if (!states.id) {
          updateState("modalState", {
            state: true,
            message: `Please select a record to ${
              method === "secondPost" ? "add" : "update"
            } an answer`,
            title: "Select Record",
          });
          return;
        }

        if (
          !states.Answer ||
          states.Answer.trim() === "" ||
          states.Answer === defaultRichText
        ) {
          updateState("modalState", {
            state: true,
            message: "Please enter an answer",
            title: "Enter Answer",
          });
          return;
        }

        if (states !== undefined && states.Answer.length < 3) {
          updateState("modalState", {
            state: true,
            message: "Answers should contain more than 3 characters",
            title: "Required field",
          });
          return;
        }

        if (states.Answer.length < 400) {
          const answerRes = await GetDataFunc(
            `AsmTmsExamsQuestion/GetAnswerWithParamAnswerIfExists?questionId=${states.id}&answer=${states.Answer}`
          );

          if (answerRes.data?.length > 0) {
            if (method !== "secondUpdate") {
              updateState("modalState", {
                state: true,
                message: "The answer you have entered already exists",
                title: "Answer Exists",
              });

              return;
            } else if (
              method === "secondUpdate" &&
              answerDetailsForQuestion[0]?.exaIdpk !==
                answerRes.data[0]?.exaIdpk
            ) {
              updateState("modalState", {
                state: true,
                message: "The answer you have entered already exists",
                title: "Answer Exists",
              });

              return;
            }
          }
        }

        if (!selectedData?.exqMultipleCorrectAnswers && states.correct_Answer) {
          const correctAnswer = await GetDataFunc(
            `AsmTmsExamsQuestion/GetCorrectAnswer/${states.id}`
          );

          if (correctAnswer.data.length > 0) {
            if (method !== "secondUpdate") {
              updateState("modalState", {
                state: true,
                message:
                  "A correct answer already exists for the selected question",
                title: "Correct Answer Exists",
              });

              return;
            } else if (
              method === "secondUpdate" &&
              answerDetailsForQuestion[0]?.exaIdpk !==
                correctAnswer.data[0]?.exaIdpk
            ) {
              updateState("modalState", {
                state: true,
                message:
                  "A correct answer already exists for the selected question",
                title: "Correct Answer Exists",
              });

              return;
            }
          }
        }

        if (method === "secondPost") {
          updateState("confirmState", {
            state: true,
            title: modalTitle,
            message: modalMessage,
            event: () => {
              createNewAnswer();
            },
          });
        } else {
          updateState("confirmState", {
            state: true,
            title: modalTitle,
            message: modalMessage,
            okText: "Yes, Update",
            event: () => {
              updateAnswer();
            },
          });
        }
      } catch (ResponseError) {
        updateState("modalState", {
          state: true,
          message: error.message,
          title: error.title,
        });
      } finally {
        setBusyLoader("");
      }
    };

    const postData = async () => {
      const { error, success, progress } = saveModalData;

      updateState("confirmState", {
        state: false,
        title: "",
        message: "",
        event: () => {},
      });

      try {
        setBusyLoader(progress);

        const createQuestion = await PostDataFunc(
          `AsmTmsExamsQuestion/CreateAsmTmsExamsQuestion`,
          {
            exqTitle: states.title,
            exqExamCategoryIdfk: states.category.id,
            question: states.question,
            exqActive: states.active,
            exqPracticeQuestion: states.practiceQns,
            exqMultipleCorrectAnswers: states.multipleQ,
            exqRmks: states.remarks,
            formCode: "string",
            formAction: "string",
          }
        );

        refreshGrid();
        setRefresh(!refresh);
        populateFields(true);

        updateState("successModal", {
          state: true,
          title: success.title,
          message: "Exams Question created successfully",
        });
      } catch (ResponseError) {
        updateState("modalState", {
          state: true,
          title: error.title,
          message: error.message,
        });
      } finally {
        setBusyLoader("");
      }
    };

    const createNewAnswer = async () => {
      const { error, success, progress } = saveModalData;

      updateState("confirmState", {
        state: false,
        title: "",
        message: "",
        event: () => {},
      });

      try {
        setBusyLoader(progress);

        const getCountResponse = await GetDataFunc(
          `AsmTmsExamsQuestion/GetAnswersForQuestionCount/${states.id}`
        );

        const createAnswerResponse = await PostDataFunc(
          `AsmTmsExamsQuestion/CreateAsmTmsExamAnswer`,
          {
            exaAnswerNo: getCountResponse.data + 1,
            exaQuestionIdfk: states.id,
            answer: states.Answer,
            exaCorrectAnswer: states.correct_Answer,
            exaNoOfLines: states.no_OfLine ?? 1,
            exaActive: states.second_Active,
            exaRmks: states.secondRemark,
            formCode: "string",
            formAction: "string",
          }
        );

        const newAnswerCount = answersCount + 1;
        updateState("all_Answer", newAnswerCount);
        getAnswer(newAnswerCount);
        updateState("refreshStates", {
          ...refreshStates,
          ["answerCount"]: !refreshStates.answerCount,
        });

        updateState("successModal", {
          state: true,
          title: success.title,
          message: success.message,
        });
      } catch (responseError) {
        updateState("modalState", {
          state: true,
          title: error.title,
          message: error.message,
        });
      } finally {
        setBusyLoader("");
      }
    };

    const updateQuestion = async () => {
      const { success, progress, error } = updateModalData;
      updateState("confirmState", {
        state: false,
        title: "",
        message: "",
        event: () => {},
      });

      try {
        setBusyLoader(progress);

        const updateQuestionResponse = await UpdateDataFunc(
          `AsmTmsExamsQuestion/UpdateAsmTmsExamsQuestion/${states.id}`,
          {
            exqIdpk: states.id,
            exqTitle: states.title,
            exqExamCategoryIdfk: states.category.id,
            question: states.question,
            exqActive: states.active,
            exqPracticeQuestion: states.practiceQns,
            exqMultipleCorrectAnswers: states.multipleQ,
            exqRmks: states.remarks,
            // exqEditedBy: userID,
            exqEditedDate: dayjs(new Date()).format(),
            formCode: "string",
            formAction: "string",
          }
        );

        refreshGrid();
        setRefresh(!refresh);
        populateFields(true);

        updateState("successModal", {
          state: true,
          title: success.title,
          message: "Exams Question created successfully",
        });
      } catch (responseError) {
        updateState("modalState", {
          state: true,
          title: error.title,
          message: error.message,
        });
      } finally {
        setBusyLoader("");
      }
    };

    const updateAnswer = async () => {
      const { success, progress, error } = updateModalData;
      updateState("confirmState", {
        state: false,
        title: "",
        message: "",
        event: () => {},
      });

      try {
        setBusyLoader(progress);
        const examAnswer = answerDetailsForQuestion[0] ?? {};

        const updateAnswerResponse = await UpdateDataFunc(
          `AsmTmsExamsQuestion/UpdateAsmTmsExamAnswer/${states.id}`,
          {
            exaIdpk: examAnswer?.exaIdpk,
            exaAnswerNo: examAnswer?.exaAnswerNo,
            exaQuestionIdfk: examAnswer?.exaQuestionIdfk,
            answer: states.Answer,
            exaCorrectAnswer: states.correct_Answer,
            exaNoOfLines: states.no_OfLine ?? 1,
            exaActive: states.active,
            exaRmks: states.secondRemark,
            // exaEditedBy: userID,
            exaEditedDate: dayjs(new Date()).format(),
            formCode: "string",
            formAction: "string",
          }
        );

        getAnswer(examAnswer?.exaAnswerNo ?? 1);
        updateState("disableForm", true);
        updateState("updateForm", false);

        updateState("successModal", {
          state: true,
          title: success.title,
          message: "Exams Answers updated successfully",
        });
      } catch (responseError) {
        updateState("modalState", {
          state: true,
          title: error.title,
          message: error.message,
        });
      } finally {
        setBusyLoader("");
      }
    };

    const resetAnswers = () => {
      updateState("Answer", defaultRichText);
      updateState("secondRemark", "");
      updateState("second_Active", false);
      updateState("correct_Answer", false);
    };

    const getAnswer = async (questionNo: number) => {
      try {
        setBusyLoader("Getting Answer...");

        const answerResponse = await GetDataFunc(
          `AsmTmsExamsQuestion/GetAnswerByIdAndQuestionNo/${states.id}?QuestionNo=${questionNo}`
        );

        const answer = answerResponse.data[0] ?? [{}];
        updateState("Answer", answer?.answer);
        updateState("secondRemark", answer?.exaRmks);
        updateState("second_Active", answer?.exaActive);
        updateState("correct_Answer", answer?.exaCorrectAnswer);
        updateState("no_OfLine", answer?.exaNoOfLines);

        updateState("answerDetailsForQuestion", answerResponse.data ?? []);
      } catch (error) {
        updateState("getAnswerError", true);

        updateState("modalState", {
          state: true,
          title: serverError.title,
          message: serverError.message,
        });
      } finally {
        setBusyLoader("");
      }
    };

    const validateDelete = () => {
      if (!answerDetailsForQuestion[0]?.exaIdpk) {
        updateState("modalState", {
          state: true,
          title: "Select Answer",
          message: "Please select an answer to delete",
        });

        return false;
      }

      return true;
    };

    const deleteAnswer = async (action: "validate" | "delete") => {
      try {
        if (action === "validate") {
          validateDelete() &&
            updateState("confirmState", {
              state: true,
              title: "Delete Answer?",
              message: "Are you sure you want to delete this answer?",
              okText: "Yes, Delete",
              event: () => deleteAnswer("delete"),
            });

          return;
        }

        updateState("confirmState", {
          state: false,
          title: "",
          message: "",
          event: () => {},
        });

        setBusyLoader("Deleting Record...");

        const deleteResponse = await DeleteDataFunc(
          `AsmTmsExamsQuestion/DeleteAnswer?QuestionId=${answerDetailsForQuestion[0]?.exaQuestionIdfk}&AnswerNo=${answerDetailsForQuestion[0]?.exaAnswerNo}`
        );

        updateState("answersCount", answersCount - 1);
        updateState("Answer", "");

        updateState("successModal", {
          state: true,
          title: "Delete Successful",
          message: "Answer deleted successfully",
        });
      } catch (error) {
        updateState("modalState", {
          state: true,
          title: "Delete Failed",
          message:
            "Failed to delete record. Please try again or contact your systems administrator",
        });
      } finally {
        setBusyLoader("");
      }
    };

    return (
      <>
        <ModalTemplate
          disableCancel={true}
          title={states.modalState.title}
          okText={""}
          message={states.modalState.message}
          open={states.modalState.state}
          okHandler={() => {
            updateState("modalState", { state: false });
          }}
          cancelHandler={() => {
            updateState("modalState", { state: false });
          }}
        />
        <ModalTemplate
          icon="success"
          disableCancel={true}
          title={states.successModal.title}
          okText={""}
          message={states.successModal.message}
          open={states.successModal.state}
          okHandler={() => {
            updateState("successModal", { state: false });
          }}
          cancelHandler={() => {
            updateState("successModal", { state: false });
          }}
        />

        <ModalTemplate
          icon="question"
          title={states.confirmState.title}
          okText={states.confirmState.okText ?? "Yes, Save"}
          message={states.confirmState.message}
          open={states.confirmState.state}
          okHandler={states.confirmState.event}
          cancelHandler={() => {
            updateState("confirmState", { state: false });
          }}
        />
        <div className="px-2 flex flex-row justify-between">
          {/* FORM 1 */}
          <section style={{ width: "49.5%" }} className="">
            {/* FORM 1 HEADER */}
            <p
              id={"form_header"}
              style={{ borderColor: borderTheme }}
              className="pt-1 border-[1px] border-b-0 px-2 w-full bg-slate-100"
            >
              <span className="font-medium">{"Question Details"}</span>
            </p>
            <Form
              style={{ borderColor: borderTheme }}
              className="w-full border-[1px] border-b-[1px] border-l- border-r-1 border-t-0 border-slate-100 rounded py-1 px-2"
              name="basic"
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 25 }}
              initialValues={{ remember: true }}
              autoComplete="off"
              size="small"
            >
              <Form.Item
                className=" "
                label={<p className="text-xs">{"Title"}</p>}
                style={{
                  marginBottom: 0,
                  width: "100%",
                  display: "inline-block",
                }}
              >
                <div className="flex flex-row mb-0.5  w-full">
                  {/* title */}
                  <span style={{ width: "calc(100% - 200px)" }}>
                    <SetupsDatalistTemplat
                      datalistStyle={{ height: 24 }}
                      useCallbackFunc
                      outerDisable={form_disable}
                      selectedData={title}
                      setCallbackValue={(e) => {
                        updateState("title", e);
                      }}
                      refresh={false}
                      listid={"title"}
                      span
                      nameExpr="exqTitle"
                      options={data}
                      style={{ width: "100%", paddingRight: "20px" }}
                    />
                  </span>

                  {/* category */}
                  <span className=" w-[200px]">
                    <SelectsTemplate
                      handleRefresh={() => {
                        updateState("refreshStates", {
                          ...refreshStates,
                          ["category"]: !refreshStates.category,
                        });
                      }}
                      handleClear={() => {
                        updateState("category", { id: 0, value: "" });
                      }}
                      useCallFunc
                      disabled={form_disable}
                      selectedValue={(object: any) => {
                        updateState("category", {
                          id: object["excIdpk"],
                          value: object["excName"],
                        });
                      }}
                      placeHolder={category.value}
                      dataexp="excName"
                      idexpr="excIdpk"
                      label="Cat."
                      options={CategoryData}
                      style={{ width: 200, paddingRight: "0px" }}
                    />
                  </span>
                </div>
              </Form.Item>

              <Form.Item
                className=" "
                label={<p className="text-xs">{"Question"}</p>}
              >
                <div className=" mb-2">
                  <RichTextEditorTemplate
                    RichEditorStyles={{ borderColor: borderTheme }}
                    readonly={form_disable}
                    disableToolbar={form_disable}
                    content={question}
                    setElementContent={(content) => {
                      updateState("question", content);
                    }}
                    height={205}
                  />
                </div>
              </Form.Item>

              {/* Remark */}
              <TextareaTemplate
                readonly={form_disable}
                useCallbackFunc
                defaultValue={remarks}
                labelCol={3}
                setCallbackValue={(e: any) => {
                  updateState("remarks", e);
                }}
                label="Remarks"
                dataExpr="exqRmks"
                idExpr="remark"
                height={50}
              />

              {/* bottom buttons and chckbxs */}
              <BottomBtns
                newButtonTitle="New Question"
                buttonText="Save"
                addActiveBorders
                saveHandler={() => {
                  setPrivType("save");
                  if (savePriv === true) {
                    validate("post");
                  } else {
                    updateState("modalState", {
                      state: true,
                      message: "No Access Privileges",
                      title: "Access Denied!",
                    });
                  }
                }}
                updateHandler={() => {
                  setPrivType("update");
                  if (updatePriv === true) {
                    validate("put");
                  } else {
                    updateState("modalState", {
                      state: true,
                      message: "No Access Privileges",
                      title: "Access Denied!",
                    });
                  }
                }}
                onNewClick={() => {
                  newAccessPriv();
                }}
                onCancelClick={() => {
                  setDisabled(true);
                  setUpdate(false);
                  dispatch(
                    setGeneralValue({ expr: "formDisabled", value: true })
                  );
                  dispatch(
                    setGeneralValue({ expr: "updateMode", value: false })
                  );
                }}
                useCallbackFunc
                outerDisabled={form_disable}
                outerUpdateMode={update_state_change}
                firstCheckboxChange={(e) => {
                  updateState("practiceQns", e);
                }}
                secondCheckboxChange={(e) => {
                  updateState("multipleQ", e);
                }}
                additionalCheckBoxValue={[practiceQns, multipleQ]}
                label="Multiple correct answers"
                span
                additionalBottomChckbox="Practice Qns"
              />

              <CheckboxTemlate
                withBorders
                defaultValue={active}
                useCallbackFunc
                setCheckboxOnchange={(e) => {
                  updateState("active", e);
                }}
                customDisabled={form_disable}
                nameExpr="exqActive"
                label="Active?"
              />
            </Form>
          </section>

          {/* FORM 2 */}
          <section style={{ width: "49.5%" }} className="">
            {/* FORM 2 HEADER */}
            <div
              id={"form_header2"}
              style={{ borderColor: borderTheme }}
              className="pt-1 border-[1px] border-b-[0px] px-2 w-full bg-slate-100 flex flex-row justify-between"
            >
              <p className=" w-9/12  font-medium">{"Answer Details"}</p>

              <span className=" w-3/12 flex flex-row justify-end py-0.5">
                {/* Edit an answer */}
                <span
                  className=" mr-2 hover:cursor-pointer"
                  id="examsQuestionUpdateAnswer"
                  onClick={() => {
                    setPrivType("update");
                    if (updatePriv === true) {
                      if (!answerDetailsForQuestion[0]?.exaIdpk) {
                        updateState("modalState", {
                          state: true,
                          message: "Please select an answer to update",
                          title: "Select Answer",
                        });
                        return;
                      }

                      updateState("disableForm", false);
                      updateState("updateForm", true);
                    } else {
                      updateState("modalState", {
                        state: true,
                        message: "No Access Privileges",
                        title: "Access Denied!",
                      });
                    }
                  }}
                >
                  <img className=" cursor-pointer" src={edit} alt="icon" />

                  <Tooltip
                    target="#examsQuestionUpdateAnswer"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    hideOnOutsideClick={true}
                  >
                    <p className="">Update Answer</p>
                  </Tooltip>
                </span>

                {/* delete answer */}
                <span
                  className=" hover:cursor-pointer"
                  id="examsQuestionDeleteAnswer"
                  onClick={() => {
                    setPrivType("save");
                    if (savePriv === true) {
                      deleteAnswer("validate");
                    } else {
                      updateState("modalState", {
                        state: true,
                        message: "No Access Privileges",
                        title: "Access Denied!",
                      });
                    }
                  }}
                >
                  <img className=" mr-1.5 border-3px" src={close} alt="icon" />

                  <Tooltip
                    target="#examsQuestionDeleteAnswer"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    hideOnOutsideClick={true}
                  >
                    <p className="">Delete Answer</p>
                  </Tooltip>
                </span>
              </span>
            </div>

            <div
              style={{ borderColor: borderTheme }}
              className="border-[1px] border-slate-100"
            >
              <Form
                className="w-full  rounded py-1 px-2 "
                name="basic"
                labelCol={{ span: 3 }}
                wrapperCol={{ span: 25 }}
                initialValues={{ remember: true }}
                autoComplete="off"
                size="small"
              >
                <Form.Item
                  className=" "
                  label={<p className="text-xs">{"Answer"}</p>}
                >
                  <div className=" mb-2">
                    <RichTextEditorTemplate
                      RichEditorStyles={{ borderColor: borderTheme }}
                      readonly={states.disableForm}
                      disableToolbar={states.disableForm}
                      content={states?.Answer}
                      setElementContent={(content) => {
                        updateState("Answer", content);
                      }}
                      height={227}
                    />
                  </div>
                </Form.Item>

                {/* Remark */}
                <TextareaTemplate
                  readonly={states.disableForm}
                  useCallbackFunc
                  labelCol={3}
                  defaultValue={states.secondRemark}
                  setCallbackValue={(e: any) => {
                    updateState("secondRemark", e);
                  }}
                  label="Remarks"
                  height={52}
                />

                <Form.Item
                  className=""
                  label={
                    <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                      {"Active?"}
                    </p>
                  }
                  style={{ marginBottom: 0 }}
                >
                  <Form.Item
                    className=" "
                    name="Name"
                    rules={[{ required: false }]}
                    style={{
                      display: "inline-block",
                      width: "35px",
                      marginBottom: 0,
                    }}
                  >
                    <CheckboxTemlate
                      defaultValue={states.second_Active}
                      useCallbackFunc
                      customDisabled={states.disableForm}
                      withBorders
                      setCheckboxOnchange={(e) => {
                        updateState("second_Active", e);
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    className=" "
                    label={
                      <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                        {"Correct Answer?"}
                      </p>
                    }
                    name="Name"
                    rules={[{ required: false }]}
                    style={{
                      display: "inline-block",
                      width: "130px",
                      marginBottom: 0,
                    }}
                  >
                    <CheckboxTemlate
                      defaultValue={states.correct_Answer}
                      useCallbackFunc
                      customDisabled={states.disableForm}
                      withBorders
                      setCheckboxOnchange={(e) => {
                        updateState("correct_Answer", e);
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    className=" ml-2"
                    label={
                      <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                        {"No of lines"}
                      </p>
                    }
                    name="Staff No"
                    rules={[{ required: false }]}
                    style={{
                      display: "inline-block",
                      width: "calc(100% - 200px)",
                      marginLeft: "",
                      marginBottom: 0,
                    }}
                  >
                    <Radio.Group
                      value={states.no_OfLine}
                      onChange={(e) => {
                        updateState("no_OfLine", e.target.value);
                      }}
                      className=" border-[1px] px-1 rounded"
                      style={{ borderColor: borderTheme }}
                    >
                      {radioButtons
                        .slice(0, 4)
                        .map(({ id, value, name }: any, index: number) => {
                          return (
                            <Radio
                              onChange={(e) =>
                                updateState("no_OfLine", e.target.value)
                              }
                              disabled={states.disableForm}
                              key={index}
                              value={value}
                            >
                              {id}
                            </Radio>
                          );
                        })}
                    </Radio.Group>
                  </Form.Item>
                </Form.Item>

                <Form.Item
                  className=""
                  label={
                    <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs border-r-1">
                      {"All Answers"}
                    </p>
                  }
                  name="Staff No"
                  rules={[{ required: false }]}
                >
                  <div className=" flex w-full justify-between ">
                    <Radio.Group
                      value={states.all_Answer}
                      onChange={(e) => {
                        const value = e.target.value;
                        updateState("all_Answer", value);

                        updateState("disableForm", true);
                        updateState("updateForm", false);
                        getAnswer(value);
                      }}
                      className="h-fullflex items-center mt-1 border-[1px]  px-1 rounded pt-1"
                      style={{
                        display: "inline-block",
                        width: "calc(100% - 220px)",
                        borderColor: borderTheme,
                      }}
                    >
                      {radioButtons
                        .slice(0, answersCount ?? 4)
                        .map(({ id, value, name }: any) => {
                          return (
                            <Radio key={id} value={value}>
                              {name}
                            </Radio>
                          );
                        })}
                    </Radio.Group>

                    <Form.Item
                      className=" w-full"
                      name="Staff No"
                      rules={[{ required: false }]}
                      style={{
                        display: "inline-block",
                        width: "calc(200px)",
                        marginLeft: 20,
                        marginBottom: 0,
                      }}
                    >
                      <div className="flex justify-end pt-0.5">
                        {!states.updateForm ? (
                          <SaveButton
                            disableButton={states.disableForm}
                            disabled_status={states.disableForm}
                            handleSave={() => {
                              saveAccessPriv2();
                            }}
                          />
                        ) : (
                          <UpdateButton
                            handleUpdate={() => {
                              updateAccessPriv2();
                            }}
                          />
                        )}

                        {states.disableForm ? (
                          <NewButton
                            useCallbackFunc
                            title="New Answer"
                            new_button_toggler={() => {
                              newAccessPriv2();
                            }}
                          />
                        ) : (
                          <Cancel
                            useCallbackFunc
                            cancel_button_toggler={() => {
                              updateState("disableForm", true);
                              updateState("updateForm", false);
                            }}
                          />
                        )}
                      </div>
                    </Form.Item>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </section>
        </div>
      </>
    );
  }
);
export default ExamsQuestionsForm;
