
// import type { SelectProps } from 'antd';
import axios from 'axios';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { api_url } from '../../../accessories/component_infos';
import { Footer_datagrid_template } from './data/footer_datagrid';
import { Objective_Details_Form } from './widgets/Objective_details';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';






interface props {
    state_change: boolean;
    refresh: ()=>void;
}



export const Strategic_Objective = forwardRef(({ state_change, refresh }: props, ref: any) => {
    // const width = window.innerWidth/2
    // const fixedWidth = 'calc(50% - 8px)';
    const pageHeight = window.innerHeight - 233;

    /**
     **************************************
     ************************************** 
     ***********Api Integrations***********
     **************************************
     **************************************
     */

    //Fetch data for table
    const [tableData, setTableData] = useState();
    const [refreshValue, setRefreshValue] = useState(false);
    
    const borderTheme = useSelector((state:RootState)=>state.general.cssBorderClass)


   
  


    return (
        <div className='w-full h-full dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base mt-1'>
            {/* toggles between the taskForm and the footer grid table vertically */}



            <div className='w-full px-2'>
                <p id={"form_header"} style={{borderColor:borderTheme}} className='pt-1 border-[1px] border-b-0  pl-4 w-full bg-slate-100 rounded-tl'>Objective Details</p>
                <div className="w-full flex justify-between gap-x-4 border-b-4 mb-1 pb-1">

                    {/* Internal toggler : expands the right taskUpdate horizontally */}


                    <div className='w-full mb-1  ' >
                        <Objective_Details_Form ref={ref} toggleRefresh={()=>{setRefreshValue(!refreshValue); refresh()}} />

                    </div>


                </div>
            </div>


            {/* Footer gridtable 
            <div style={{ height: '' }} className='px-2  dark:bg-slate-900 dark:text-darkModeSkin-base'>
                <Footer_datagrid_template callBack={(e:any)=>{childRef.current!.singleClick(e)!}} doubleClick={(e:any)=>{childRef.current!.doubleClick()!}} gridheight={395} columns={objectives_columns} data={tableData} />
            </div> */}

        </div>

    )
})