import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Form, Input, Select, DatePicker, Checkbox } from "antd";
import { CheckBox } from "devextreme-react/check-box";
import refresh from "../../../../assets/refresh.png";
import dayjs, { Dayjs } from "dayjs";
import {
  SaveButton,
  NewButton,
  UpdateButton,
  Cancel,
} from "../../../../accessories/buttons";
import {
  CIA_Details_Select,
  CI_Details_Select,
  Objective_Details_Select,
} from "../../../data/Performance_selects";
import {
  CI_TextArea,
  Mission_Vision_Date,
  Mission_Vision_extra_Input,
  Mission_Vision_Input,
  Mission_Vision_Mini_extra_TextArea,
  Mission_Vision_Mini_TextArea,
  Mission_Vision_TextArea,
  Objective_Input,
  Objective_mini_TextArea,
  Objective_TextArea,
} from "../../../data/Performance_inputs";
import { current } from "@reduxjs/toolkit";
import {
  api_url,
  currentDate,
  saveModalData,
  startingDate,
  updateModalData,
  validationsData,
} from "../../../../accessories/component_infos";
import axios from "axios";
import { ModalTemplate } from "../../../../../templates/modal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import { setGeneralValue } from "../../../../../features/generalSlice";
import {
  task_footer_table_selected,
  task_form_disable,
  task_footer_update,
} from "../../../../../features/Task&Todo/Task/TaskDetails";
import { inputsTypes } from "../../../../human-resource/setups/data/types/selectsTypes";
import { DateTemplate } from "../../../../../templates/date";

interface props {
  toggleRefresh: any;
}

// interface stateTypes {
//     selectedRow?: any;
//   }

export const Mission_Vision_Details_Form = forwardRef(
  ({ toggleRefresh }: props, ref: any) => {
    const initialStates = {
      selectedId: 0,
      name: "",
      shortName: "",
      vision: "",
      shortVision: "",
      mission: "",
      shortMission: "",
      remarks: "",
      activity: true,
      startDate: startingDate,
      endDate: startingDate,
      showCancel: true,
      setUpdating: true,
      refresh: false,
    };

    const [states, setStates] = useState<any>(initialStates);

    const setState = (key: string, value: any) => {
      setStates((prev: any) => ({ ...prev, [key]: value }));
    };

    const { Option } = Select;
    const { TextArea } = Input;
    const dispatch = useDispatch();

    const borderTheme = useSelector(
      (state: RootState) => state.general.cssBorderClass
    );

    // Initialize fields
    const [name, setName] = useState("");
    const [shortName, setShortName] = useState("");
    const [mission, setMission] = useState("");
    const [shortMission, setShortMission] = useState("");
    const [vision, setVision] = useState("");
    const [shortVision, setShortVision] = useState("");
    const [remarks, setRemarks] = useState("");
    const [activity, setActivity] = useState(false);
    const [startDate, setStartDate] = useState<any>(null);
    const [endDate, setEndDate] = useState<any>(null);
    const [id, setId] = useState("");

    const options = [];
    for (let i = 1; i < 36; i++) {
      options.push({
        label: i.toString(36) + i,
        value: i.toString(36) + i,
      });
    }

    const [textAreaHeight, settextAreaHeight] = useState<string>("75px");

    const [refreshState, setRefreshState] = useState(false);

    const validateData = async () => {
      setIcon("warning");
      if (startDate === null) {
        setShowModalDisableButton(true);
        setModalData({
          message: "Please select a start date",
          title: "Enter start date",
          okText: "Ok",
          cancelText: "No, Cancel",
        });
        setOpenModal(true);
      } else if (endDate === null) {
        setShowModalDisableButton(true);
        setModalData({
          message: "Please select a end date",
          title: "Enter end date",
          okText: "Ok",
          cancelText: "No, Cancel",
        });
        setOpenModal(true);
      } else if (name === "") {
        setShowModalDisableButton(true);
        setModalData({
          message: "Please enter a d",
          title: "Enter name",
          okText: "Ok",
          cancelText: "No, Cancel",
        });
        setOpenModal(true);
      } else if (shortName === "") {
        setShowModalDisableButton(true);
        setModalData({
          message: "Please enter a short name",
          title: " Enter short name",
          okText: "Ok",
          cancelText: "No, Cancel",
        });
        setOpenModal(true);
      } else if (mission === "") {
        setShowModalDisableButton(true);
        setModalData({
          message: "Please enter a mission",
          title: "Enter mission",
          okText: "Ok",
          cancelText: "No, Cancel",
        });
        setOpenModal(true);
      } else if (shortMission === "") {
        setShowModalDisableButton(true);
        setModalData({
          message: "Please enter a short mission",
          title: "Enter short mission",
          okText: "Ok",
          cancelText: "No, Cancel",
        });
        setOpenModal(true);
      } else if (vision === "") {
        setShowModalDisableButton(true);
        setModalData({
          message: "Please enter a vision",
          title: "Enter vision",
          okText: "Ok",
          cancelText: "No, Cancel",
        });
        setOpenModal(true);
      } else if (shortVision === "") {
        setShowModalDisableButton(true);
        setModalData({
          message: "Please enter a short vision",
          title: "Enter short vision",
          okText: "Ok",
          cancelText: "No, Cancel",
        });
        setOpenModal(true);
      } else {
        setIcon("question");
        setOpenModal(true);
      }
    };

    const postData = async () => {

        console.log({
            "mvsName": name,
            "mvsShtName": shortName,
            "mvsStartDate": startDate,
            "mvsEndDate": endDate,
            "mvsMission": mission,
            "mvsMissionSht": shortMission,
            "mvsVision": vision,
            "mvsVisionSht": shortVision,
            "mvsActive": activity,
            "mvsRmks": remarks,
            "mvsCreatedBy": 1
        })
        try {

            const res = await axios.post(`${api_url}PfmMissionVision/CreatePfmMissionVision`, {
                "mvsName": name,
                "mvsShtName": shortName,
                "mvsStartDate": startDate,
                "mvsEndDate": endDate,
                "mvsMission": mission,
                "mvsMissionSht": shortMission,
                "mvsVision": vision,
                "mvsVisionSht": shortVision,
                "mvsActive": activity,
                "mvsRmks": remarks,
                "mvsCreatedBy": 1
            })


        // setOpenModal(false);
        toggleRefresh(!refreshState);
        setIcon("success");
        setShowModalDisableButton(true);
        setModalData({
          message: "Success",
          title: "Record successfully added",
          okText: "Ok",
          cancelText: "No, Cancel",
        });

        // const exprs:exprsTypes[] = [{expr: 'saveDataOnclick' ,value : false},{expr: 'successModalState' ,value : true},{expr: 'successText' ,value : saveSuccessText}];
        // for (let i = 0; i < exprs.length; i++) {
        //     dispatch(setValue({expr: exprs[i].expr ,value : exprs[i].value}));
        // }
      } catch (error: any) {
        //set states for db errors modal
        // setErrState(true);
        // seterrTitle(error.response ? error.response.data.message : 'Server Error');
        // seterrMessage(error.response ? error.response.data.errors[0] : 'Error connecting to server. Please contact system administrator');
      }
    };

    const handleChange = (value: string[]) => {
      console.log(`selected ${value}`);
      if (value.length > 7) {
        settextAreaHeight("60px");
      } else {
        settextAreaHeight("71px");
      }
    };

    const clearFields = async () => {
      setName("");
      setShortName("");
      setMission("");
      setShortMission("");
      setVision("");
      setShortVision("");
      setRemarks("");
      setActivity(false);
      setStartDate(null);
      setEndDate(null);
      setUpdating(false);
    };
    const dateFormat = " DD MMM YYYY HH: mm:ss";

    const updateData = async () => {
        console.log('here')
        const del = await axios.put(`${api_url}PfmMissionVision/UpdatePfmMissionVision`, {

            "mvsIdpk": id,
            "mvsName": name,
            "mvsShtName": shortName,
            "mvsStartDate": startDate,
            "mvsEndDate": endDate,
            "mvsMission": mission,
            "mvsMissionSht": shortMission,
            "mvsVision": vision,
            "mvsVisionSht": shortVision,
            "mvsActive": activity,
            "mvsRmks": remarks,
            "mvsEditedBy": 0,
            "mvsEditedDate": "2023-02-07T14:26:10.407Z"
        }
      );
      toggleRefresh(!refreshState);
      setIcon("success");
      setShowModalDisableButton(true);
      setModalData({
        message: "Success",
        title: "Record successfully updated",
        okText: "Ok",
        cancelText: "No, Cancel",
      });
    };

    const [showModalDisableButton, setShowModalDisableButton] = useState(false);

    const [openModal, setOpenModal] = useState(false);
    const [modalData, setModalData] = useState<any>(saveModalData);
    const [icon, setIcon] = useState<"warning" | "success" | "question">();

    const [disableForms, setDisableForms] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [showCancel, setShowCancel] = useState(false);

    function handleClick(value: string) {
      setIcon("question");
      setModalData(saveModalData);
      setOpenModal(true);
    }

    useImperativeHandle(ref, () => ({
      doubleClick: () => {
        setDisableForms(false);
        setShowCancel(true);
        setUpdating(true);
      },
      singleClick: (e: any) => {
        console.log("eee");
        if (e !== undefined) {
          setStates({
            selectedId: e?.mvsIdpk,
            name: e?.mvsName,
            shortName: e?.mvsShtName,
            vision: e?.mvsVision,
            shortVision: e?.mvsMissionSht,
            mission: e?.mvsMission,
            shortMission: e?.mvsMissionSht,
            remarks: e?.mvsRmks,
            activity: e?.mvsActive,
            startDate: dayjs(e.mvsStartDate),
            endDate: dayjs(e.mvsEndDate),
            showCancel: false,
            setUpdating: false,
          });
          // setId(e.mvsIdpk);
          // setName(e.mvsName);
          // setShortName(e.mvsShtName);
          // setVision(e.mvsVision);
          // setShortVision(e.mvsVisionSht);
          // setMission(e.mvsMission);
          // setShortMission(e.mvsMissionSht);
          // setRemarks(e.mvsRmks);
          // setActivity(e.mvsActive);
          // setStartDate(dayjs(e.mvsStartDate));
          // setEndDate(dayjs(e.mvsEndDate));
          // setShowCancel(false);
          // setUpdating(false);
        }
        setDisableForms(true);
      },
      checkUpdate: () => {
        console.log("er");
        if (id === "") {
          setShowModalDisableButton(true);
          setModalData({
            message: "No record selected",
            title: "Invalid record",
            okText: "Ok",
            cancelText: "No, Cancel",
          });
          setOpenModal(true);
        } else {
          setDisableForms(false);
          setShowCancel(true);
          setUpdating(true);
          dispatch(task_footer_update(true));
        }
      },
    }));

    const disablex = useSelector(
      (state: RootState) => state.addTask.taskFormStatus
    );
    const updateMode = useSelector(
      (state: RootState) => state.addTask.taskFooterUpdate
    );

    useEffect(() => {
      if (!disablex) {
        console.log("ds");
        setShowCancel(true);
        setShowModalDisableButton(false);
        setModalData(saveModalData);
        clearFields();
        setDisableForms(false);
        setId("");

        dispatch(task_footer_table_selected({}));

        dispatch(task_form_disable(false));
        //  this destroys the update mode state
        dispatch(task_footer_update(false));
      } else {
        setDisableForms(true);
        setShowCancel(false);
        setUpdating(false);
        // setShowCancel(false); setDisableForms(true); setUpdating(false)
      }
      if (updateMode) {
        //enable forms
        dispatch(task_form_disable(true));

        //enable Update Mode
        dispatch(task_footer_update(true));

        setDisableForms(false);
        setShowCancel(true);
        setUpdating(true);

        //     setShowCancel(true);
        //     setUpdating(true);
        //  setDisableForms(false);
      }
    }, [disablex, updateMode]);

    const { RangePicker } = DatePicker;
    const dates: inputsTypes[] = [
      {
        id: 0,
        label: "Start Date",
        stateName: "",
        defaultValue: "",
        style: "",
      },
      { id: 1, label: "", stateName: "", defaultValue: "", style: "" },
    ];
    return (
      <div
        style={{ width: "100%", height: "100%", borderColor: borderTheme }}
        className="  border-[1px]   dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base  "
      >
        {/* <h4 className=" w-full  mb-2 bg-slate-100  ">Task Details</h4> */}
        <ModalTemplate
          icon={icon}
          cancelText={modalData.cancelText}
          cancelHandler={() => {
            setOpenModal(false);
          }}
          disableCancel={showModalDisableButton}
          open={openModal}
          okHandler={() => {
            showModalDisableButton === true
              ? setOpenModal(false)
              : updating === true
              ? updateData()
              : postData();
          }}
          message={modalData.message}
          okText={modalData.okText}
          title={modalData.title}
        />

        <div
          style={{
            maxHeight: "310px",
            minHeight: "310px",
            height: "310px",
            width: "100%",
          }}
          className=" items-start justify-start "
        >
          <Form
            className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 px-2 "
            name="basic"
            // labelCol={{ span: 6}}
            // wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            size="small"
            layout="horizontal"
          >
            <div className="w-full h-full">
              <div className="flex">
                <div className="w-4/5">
                  <Form labelCol={{ span: 7 }}>
                    <div className="flex flex-row   ">
                      {/* <RangePicker disabled={disableForms} onChange={(e: any, dateString: any) => { setStartDate(dayjs(dateString[0])); setEndDate(dayjs(dateString[1])); }} value={[startDate, endDate]} className='' format={dateFormat} />  */}

                      {dates.map(
                        ({ id, label, stateName, defaultValue, style }) => {
                          return (
                            <DateTemplate
                              // style={{ marginLeft: 5 }}
                              label={label}
                              selectedDate={defaultValue}
                              changeDate={(date: Dayjs) => {
                                if (id == 0) {
                                  setStartDate(date);
                                } else {
                                  setEndDate(date);
                                }
                              }}
                            />
                          );
                        }
                      )}
                    </div>
                  </Form>
                </div>
              </div>
              {/* for inputs */}
              <div className="flex w-full">
                <div className="w-4/5">
                  <Form.Item
                    className="mb-0"
                    label={
                      <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                        {"Name"}
                      </p>
                    }
                    labelCol={{ span: 2 }}
                  >
                    <div className="flex flex-row justify-between mb-0.5">
                      <Input
                        disabled={disableForms}
                        value={name}
                        onChange={(e: any) => {
                          console.log(e.target.value);
                          setName(e.target.value);
                        }}
                        className=" W-11/12"
                      />

                      <span className="h-full w-4 ml-1 flex items-center justify-center hover:cursor-pointer hover">
                        {/* <img className='w w-9/12 h-9/12' src= {reset} alt="reset" /> */}
                      </span>
                    </div>
                  </Form.Item>
                </div>
                <div className="w-1/5">
                  <Form.Item
                    className=""
                    // label={<p className='dark:bg-fill dark:text-darkModeSkin-base text-xs'>{ }</p>}
                    labelCol={{ span: 1 }}
                    colon={false}
                  >
                    <div className="flex flex-row justify-between mb-0.5">
                      <Input
                        disabled={disableForms}
                        value={shortName}
                        onChange={(e: any) => {
                          console.log(e.target.value);
                          setShortName(e.target.value);
                        }}
                        className=" W-11/12"
                      />
                    </div>
                  </Form.Item>
                </div>
              </div>
              {/* For textarea1 */}
              <div className="flex w-full ">
                <div className="w-4/5">
                  <Form.Item
                    labelCol={{ span: 2 }}
                    label={
                      <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                        {"Mission"}
                      </p>
                    }
                  >
                    <div className="flex flex-row ">
                      <textarea
                        readOnly={disableForms}
                        value={mission}
                        onChange={(e: any) => {
                          setMission(e.target.value);
                        }}
                        style={{
                          resize: "none",
                          height: 70,
                          borderColor: borderTheme,
                        }}
                        className={`w-full overflow-y-scroll border-gray-300 border-[1px] mb-0.5 rounded focus:border-blue-500 hover:border-blue-500 focus:outline-none hover:outline-none px-[4px] `}
                      />

                      <span className="h-full w-4 ml-1 flex items-center justify-center hover:cursor-pointer hover">
                        {/* <img className='w w-9/12 h-9/12' src= {reset} alt="reset" /> */}
                      </span>
                    </div>
                  </Form.Item>
                </div>
                <div className="w-1/5">
                  <Form.Item
                    className="mb-0"
                    labelCol={{ span: 1 }}
                    colon={false}
                    // label={<p className='dark:bg-fill dark:text-darkModeSkin-base text-xs'>{ }</p>}
                    rules={[{ required: false }]}
                  >
                    <div className="flex flex-row ">
                      <textarea
                        readOnly={disableForms}
                        value={shortMission}
                        onChange={(e: any) => {
                          setShortMission(e.target.value);
                        }}
                        style={{
                          resize: "none",
                          height: 70,
                          borderColor: borderTheme,
                        }}
                        className={`w-full overflow-y-scroll border-gray-300 border-[1px] mb-0.5 rounded focus:border-blue-500 hover:border-blue-500 focus:outline-none hover:outline-none px-[4px] `}
                      />
                    </div>
                  </Form.Item>
                </div>
              </div>
              {/* Vision */}
              <div className="flex w-full ">
                <div className="w-4/5">
                  <Form.Item
                    className="mb-0"
                    labelCol={{ span: 2 }}
                    label={
                      <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                        {"Vision"}
                      </p>
                    }
                  >
                    <div className="flex flex-row ">
                      <textarea
                        readOnly={disableForms}
                        value={vision}
                        onChange={(e: any) => {
                          setVision(e.target.value);
                        }}
                        style={{
                          resize: "none",
                          height: 70,
                          borderColor: borderTheme,
                        }}
                        className={`w-full overflow-y-scroll border-gray-300 border-[1px] mb-0.5 rounded focus:border-blue-500 hover:border-blue-500 focus:outline-none hover:outline-none px-[4px] `}
                      />
                      <span className="h-full w-4 ml-1 flex items-center justify-center hover:cursor-pointer hover">
                        {/* <img className='w w-9/12 h-9/12' src= {reset} alt="reset" /> */}
                      </span>
                    </div>
                  </Form.Item>
                </div>
                <div className="w-1/5">
                  <Form.Item
                    className="mb-0"
                    labelCol={{ span: 1 }}
                    colon={false}
                    // label={<p className='dark:bg-fill dark:text-darkModeSkin-base text-xs'>{ }</p>}
                    rules={[{ required: false }]}
                  >
                    <div className="flex flex-row ">
                      <textarea
                        readOnly={disableForms}
                        value={shortVision}
                        onChange={(e: any) => {
                          setShortVision(e.target.value);
                        }}
                        style={{
                          resize: "none",
                          height: 70,
                          borderColor: borderTheme,
                        }}
                        className={`w-full overflow-y-scroll border-gray-300 border-[1px] mb-0.5 rounded focus:border-blue-500 hover:border-blue-500 focus:outline-none hover:outline-none px-[4px] `}
                      />
                    </div>
                  </Form.Item>
                </div>
              </div>
              {/* Remarks */}
              <div
                className="flex"
                style={{ width: "calc(100% - 2.5%)", marginLeft: "2.5%" }}
              >
                <div className="w-full">
                  <Form.Item
                    className="mb-0"
                    labelCol={{ span: 1 }}
                    label={
                      <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs ">
                        {"Remarks"}
                      </p>
                    }
                    rules={[{ required: false }]}
                  >
                    <div className="flex flex-row ">
                      <textarea
                        readOnly={disableForms}
                        value={remarks}
                        onChange={(e: any) => {
                          setRemarks(e.target.value);
                        }}
                        style={{
                          resize: "none",
                          height: 70,
                          borderColor: borderTheme,
                        }}
                        className={`w-full overflow-y-scroll border-gray-300 border-[1px] mb-0.5 rounded focus:border-blue-500 hover:border-blue-500 focus:outline-none hover:outline-none px-[4px] `}
                      />
                    </div>
                  </Form.Item>
                </div>
              </div>
            </div>

            <div className="w-full  flex flex-col mt-0.5 ">
              <div
                style={{ width: "calc(100% )" }}
                className=" flex  justify-between  "
              >
                {/* <li className='flex justify-strt '><Cancel/></li> */}
                <div className="ml-[7px]" style={{ width: "50%" }}>
                  <Form.Item
                    className="mb-0"
                    labelCol={{ span: 3 }}
                    label={
                      <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs ">
                        {"Activity"}
                      </p>
                    }
                    rules={[{ required: false }]}
                  >
                    <div className="flex flex-row  ">
                      <Checkbox
                        style={{ borderColor: borderTheme }}
                        className="border-[1px] rounded px-1"
                        disabled={disableForms}
                        value={activity}
                        onChange={(e: any) => {
                          setActivity(e.target.checked);
                        }}
                      />
                    </div>
                  </Form.Item>
                </div>
                {/* <li className=' flex items-center flex-row' style={{marginLeft: '2.1%'}}><p className='mr-3'>Activity? : </p>  </li> */}
                <ul className="flex justify-end  " style={{ width: "50%" }}>
                  {updating === true ? (
                    <UpdateButton
                      handleUpdate={() => {
                        setShowModalDisableButton(false);
                        setModalData(updateModalData);
                        validateData();
                      }}
                    />
                  ) : (
                    <SaveButton
                      handleSave={() => {
                        // postData();
                        // handleClick('create')
                        setShowModalDisableButton(false);
                        setModalData(saveModalData);
                        validateData();
                      }}
                    />
                  )}
                  {showCancel || !disablex ? (
                    <Cancel
                      handleCallbackCancel={() => {
                        console.log("fas");
                        clearFields();
                        dispatch(
                          setGeneralValue({
                            expr: "missionUpdate",
                            value: false,
                          })
                        );
                        setShowCancel(false);
                        setUpdating(false);
                        clearFields();
                        setDisableForms(true);
                        dispatch(
                          setGeneralValue({ expr: "formDisabled", value: true })
                        );
                        dispatch(task_form_disable(true));
                        // dispatch(update_route_state(false))
                        dispatch(task_footer_update(false));

                        // dispatch(settask_footer_update(false))
                        dispatch(
                          setGeneralValue({ expr: "updateMode", value: false })
                        );
                      }}
                    />
                  ) : (
                    <NewButton
                      useCallbackFunc={true}
                      new_button_toggler={() => {
                        setShowCancel(true);
                        setShowModalDisableButton(false);
                        setModalData(saveModalData);
                        clearFields();
                        setDisableForms(!disableForms);
                        dispatch(task_form_disable(false));
                        //  this destroys the update mode state
                        dispatch(task_footer_update(false));
                      }}
                    />
                  )}
                </ul>
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  }
);
