/* eslint-disable react/jsx-pascal-case */
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import useFetch from "../../../../../../hooks/useFetch";
import Datagrid_template from "../../../../../../templates/Datagrid";
import {
  navbar_height,
  toolbar_height,
} from "../../../../../accessories/component_infos";
import { HrSetupsCustomToolbar } from "../../../../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import { mainGridColumns } from "./data/data";
import { BulkProductPricesForm } from "./widgets/bulkProductsForm";
import { WarningAlert } from "../../../../../accessories/warningAlert";
import { useBusyLoader } from "../../../../../../hooks/useBusyLoader";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";
import { searchTextTypes } from "../../../../../human-resource/setups/data/types/selectsTypes";
import { useDispatch } from "react-redux";
import { setGeneralValue } from "../../../../../../features/generalSlice";

export const BulkProductPrices = forwardRef(({ a }: any, ref) => {
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const dispatch = useDispatch();
  //navigation
  const setupPageRoute = window.location.pathname; //get current route of page
  const datagridOnlySearchParam = "table-only"; //search param in datagrid mode
  const formModeSearchParam = "form-and-table"; //search param in form mode mode
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [status, setstatus] = useState<boolean | string>("");
  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });
  const activeStatus = useSelector(
    (state: RootState) => state.general.activeStatus
  );
  const [active, setActive] = useState<{
    temp: boolean | string;
    status: boolean | string;
  }>({
    temp: "",
    status: "",
  });

  //loader
  const [busyLoader, setBusyLoader] = useBusyLoader();

  //miscellaneous heights
  const miscHeight = 7;

  // All heights out
  const heights_out = navbar_height + news_flash_height + toolbar_height;

  //varying form height
  const [form_height, set_form_height] = useState<number>(430);

  // Page resize height responsiveness
  useImperativeHandle(ref, () => ({
    test: () => {
      settableHeight(window.innerHeight - (heights_out + miscHeight - 4));
    },
  }));

  // handle interface switch
  const toggle_staff_form = (): void => {
    const view = searchParams.get("view");

    view === null || view === formModeSearchParam
      ? navigate({
          pathname: setupPageRoute,
          search: createSearchParams({
            view: datagridOnlySearchParam,
          }).toString(),
        })
      : navigate({
          pathname: setupPageRoute,
          search: createSearchParams({
            view: formModeSearchParam,
          }).toString(),
        });
  };

  //Table Height
  const [tableHeight, settableHeight] = useState(
    window.innerHeight - (heights_out + miscHeight - 4)
  ); //height without navbar and toolbar

  const [refresh, setrefresh] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState({});

  //Form functionalities
  const [data, error, loading] = useFetch(
    `AssetMgrTmsBulkProductPrices/getBulkProductPricesPopGdv?searchText=${searchText.text}&isActive=${status}`,
    refresh,
    "pprDate"
  );
  useEffect(() => {
    loading ? setBusyLoader(".") : setBusyLoader("");
  }, [loading]);
  return (
    <>
      {/* error alert */}
      {error && <WarningAlert />}

      <div className="h-full">
        <div className="px-2">
          <HrSetupsCustomToolbar
            searchTextOnchange={(newText) => {
              setSearchText({ temp: newText, text: searchText.text });
            }}
            handleFind={() => {
              setSearchText({ temp: searchText.temp, text: searchText.temp });
              setstatus(activeStatus);
            }}
            handleRefresh={() => {
              setrefresh((current) => !current); // might change the spelling

              setstatus("");
              setSearchText({ temp: "", text: "" });
            }}
            checkOnChange={(value) => {
              setActive({ temp: value, status: active.status }); //store locally
              dispatch(setGeneralValue({ expr: "activeStatus", value: value })); //push value to redux
            }}
            disableNew_edit
            withDates={false}
            toggler={toggle_staff_form}
          />
        </div>

        {/* form section */}
        {searchParams.get("view") !== datagridOnlySearchParam && (
          <section style={{ height: form_height }} className=" form border-b-4">
            <BulkProductPricesForm
              clearSelected={() => setSelectedData({})}
              selectedField={selectedData}
              busyloader={(text) => setBusyLoader(text ?? "")}
              refreshGrid={() => setrefresh((prev) => !prev)}
            />
          </section>
        )}

        {/* datagrid section */}
        <section className=" px-2">
          <Datagrid_template
            gridheight={
              searchParams.get("view") === formModeSearchParam ||
              searchParams.get("view") === null
                ? window.innerHeight - (form_height + heights_out + miscHeight)
                : tableHeight
            }
            data={data}
            columns={mainGridColumns}
          />
        </section>
      </div>
    </>
  );
});
