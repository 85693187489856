export const first_data_TH = [
  {
    id: 0,
    caption: "REG No",
    dataField: "REG No",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 1,
    caption: "CHASSIS No",
    dataField: "CHASSIS No",
    alignment: "left",
    fixed: true,
    width: null
  },

  {
    id: 2,
    caption: "Engine No",
    dataField: "Engine No",
    alignment: "left",
    fixed: true,
    width: null


  },
  {
    id: 3,
    caption: "VEHICLE CLASS",
    dataField: "VEHICLE CLASS",
    alignment: "left",
    fixed: true,
    width: null

  },
  {
    id: 4,
    caption: "VEHICLE MAKE",
    dataField: "VEHICLE MAKE",
    alignment: "left",
    fixed: true,
    width: null

  },

  {
    id: 5,
    caption: "VEHICLE MODEL",
    dataField: "VEHICLE MODEL",
    alignment: "left",
    fixed: true,
    width: null

  },


  {
    id: 6,
    caption: "MANUFACTURER",
    dataField: "MANUFACTURER",
    alignment: "left",
    fixed: true,
    width: null

  },

  {
    id: 7,
    caption: "COUNTRY",
    dataField: "COUNTRY",
    alignment: "left",
    fixed: true,
    width: null

  },
  {
    id: 8,
    caption: "SUPPLIER",
    dataField: "SUPPLIER",
    alignment: "left",
    fixed: true,
    width: null

  },
  {
    id: 9,
    caption: "MANUF. DATE",
    dataField: "MANUF. DATE",
    alignment: "left",
    fixed: true,
    width: null

  },
  {
    id: 10,
    caption: "SUPPLY DATE",
    dataField: "SUPPLY DATE",
    alignment: "left",
    fixed: true,
    width: null

  },
  {
    id: 11,
    caption: "DVLA REG DATE",
    dataField: "DVLA REG DATE",
    alignment: "left",
    fixed: true,
    width: null

  },
  {
    id: 12,
    caption: "ENGINE TYPE",
    dataField: "ENGINE TYPE",
    alignment: "left",
    fixed: true,
    width: null

  },
  {
    id: 13,
    caption: "FUEL TYPE",
    dataField: "FUEL TYPE",
    alignment: "left",
    fixed: true,
    width: null

  },
  {
    id: 14,
    caption: "CUBIC CAPACITY",
    dataField: "CUBIC CAPACITY",
    alignment: "left",
    fixed: true,
    width: 100

  },
  {
    id: 15,
    caption: "TANK CAPACITY",
    dataField: "TANK CAPACITY",
    alignment: "left",
    fixed: true,
    width: 100

  },
  {
    id: 16,
    caption: "POWER TRAIN",
    dataField: "POWER TRAIN",
    alignment: "left",
    fixed: true,
    width: 100
  },
  {
    id: 17,
    caption: "TRANSMISSION TYPE",
    dataField: "TRANSMISSION TYPE",
    alignment: "left",
    fixed: true,
    width: 100
  },
  {
    id: 18,
    caption: "ENGINE MAKE",
    dataField: "ENGINE MAKE",
    alignment: "left",
    fixed: true,
    width: 100
  },
  {
    id: 19,
    caption: "No OF CYLINDERS",
    dataField: "No OF CYLINDERS",
    alignment: "left",
    fixed: true,
    width: 100
  },
  {
    id: 20,
    caption: "COLOUR",
    dataField: "COLOUR",
    alignment: "left",
    fixed: true,
    width: 100
  },
  {
    id: 21,
    caption: "STATUS",
    dataField: "STATUS",
    alignment: "left",
    fixed: true,
    width: 100,
  },
  {
    id: 22,
    caption: "SEATING CAPACITY",
    dataField: "SEATING CAPACITY",
    alignment: "left",
    fixed: true,
    width: 100
  },
  {
    id: 23,
    caption: "LENGTH",
    dataField: "LENGTH",
    alignment: "left",
    fixed: true,
    width: 100
  },
  {
    id: 24,
    caption: "WIDTH",
    dataField: "WIDTH",
    alignment: "left",
    fixed: true,
    width: 100
  },
  {
    id: 25,
    caption: "HEIGHT",
    dataField: "HEIGHT",
    alignment: "left",
    fixed: true,
    width: 100
  },
  {
    id: 26,
    caption: "No OF AXLES",
    dataField: "No OF AXLES",
    alignment: "left",
    fixed: true,
    width: 100
  },
  {
    id: 27,
    caption: "No OF WHEELS",
    dataField: "No OF WHEELS",
    alignment: "left",
    fixed: true,
    width: 100
  },
  {
    id: 28,
    caption: "TYRE SIZE (F)",
    dataField: "TYRE SIZE (F)",
    alignment: "left",
    fixed: true,
    width: 100
  },
  {
    id: 29,
    caption: "TYRE SIZE (M)",
    dataField: "TYRE SIZE (M)",
    alignment: "left",
    fixed: true,
    width: 100
  },
  {
    id: 30,
    caption: "TYRE SIZE (R)",
    dataField: "TYRE SIZE (R)",
    alignment: "left",
    fixed: true,
    width: 100
  },
  {
    id: 31,
    caption: "NET WEIGHT",
    dataField: "NET WEIGHT",
    alignment: "left",
    fixed: true,
    width: 120
  },
  {
    id: 32,
    caption: "GROSS WEIGHT",
    dataField: "GROSS WEIGHT",
    alignment: "left",
    fixed: true,
    width: 100
  },
  {
    id: 33,
    caption: "HORSE POWER",
    dataField: "HORSE POWER",
    alignment: "left",
    fixed: true,
    width: 100
  },
  {
    id: 34,
    caption: "PRICE",
    dataField: "PRICE",
    alignment: "left",
    fixed: true,
    width: 100
  },
  {
    id: 35,
    caption: "PRICE CURRENCY",
    dataField: "PRICE CURRENCY",
    alignment: "left",
    fixed: true,
    width: 100
  },
  {
    id: 36,
    caption: "REMARKS",
    dataField: "REMARKS",
    alignment: "left",
    fixed: true,
    width: 4000
  },
  {
    id: 37,
    caption: "PASSED",
    dataField: "PASSED",
    alignment: "left",
    fixed: true,
    width: 100
  },



]


export const vehicle_data_upload_TH = [
  {
    id: 0,
    caption: "REG No",
    dataField: "vdtRegNo",
    alignment: "left",
    fixed: true,
    width: null

  },
  {
    id: 1,
    caption: "CHASSIS No",
    dataField: "vdtChassisNo",
    alignment: "left",
    fixed: true,
    width: null
  },

  {
    id: 2,
    caption: "ENGINE No",
    dataField: "vdtEngineNo",
    alignment: "left",
    fixed: true,
    width: null
  },
  {
    id: 3,
    caption: "VEHICLE CLASS",
    dataField: "vhcName",
    alignment: "left",
    fixed: true,
    width: null


  },
  {
    id: 4,
    caption: "MODEL",
    dataField: "mdlName",
    alignment: "left",
    fixed: true,
    width: null

  },
  {
    id: 5,
    caption: "MANUF",
    dataField: "mftName",
    alignment: "left",
    fixed: true,
    width: null

  },

  {
    id: 6,
    caption: "COUNTRY",
    dataField: "ctyName",
    alignment: "left",
    fixed: true,
    width: null

  },


  {
    id: 7,
    caption: "SUPPLIER",
    dataField: "splName",
    alignment: "left",
    fixed: true,
    width: null

  },

  {
    id: 8,
    caption: "MANUF. DATE",
    dataField: "vdtManufactureDate",
    dataType: "datetime",
    alignment: "left",
    fixed: true,
    width: 120

  },
  {
    id: 9,
    caption: "SUPPLY DATE",
    dataField: "vdtSupplyDate",
    dataType: "datetime",
    alignment: "left",
    fixed: true,
    width: 120

  },
  {
    id: 10,
    caption: "REG. DATE",
    dataField: "vdtDvlaRegDate",
    dataType: "datetime",
    alignment: "left",
    fixed: true,
    width: 120

  },
  {
    id: 11,
    caption: "ENGINE TYPE",
    dataField: "egtName",
    alignment: "left",
    fixed: true,
    width: null

  },
  {
    id: 12,
    caption: "ENGINE MAKE",
    dataField: "egmName",
    alignment: "left",
    fixed: true,
    width: null

  },
  {
    id: 13,
    caption: "TRANS. TYPE",
    dataField: "trtName",
    alignment: "left",
    fixed: true,
    width: null

  },
  {
    id: 14,
    caption: "FUEL TYPE",
    dataField: "ftpName",
    alignment: "left",
    fixed: true,
    width: null

  },
  {
    id: 15,
    caption: "POW. TRAIN",
    dataField: "pwtName",
    alignment: "left",
    fixed: true,
    width: 100

  },
  {
    id: 16,
    caption: "HORSE POW",
    dataField: "vdtHorsePower",
    alignment: "left",
    fixed: true,
    width: 100

  },
  {
    id: 17,
    caption: "CUBIC CAP.",
    dataField: "vdtCubicCapacity",
    alignment: "left",
    fixed: true,
    width: 100
  },
  {
    id: 18,
    caption: "LENGTH",
    dataField: "vdtLength",
    alignment: "left",
    fixed: true,
    width: 100
  },
  {
    id: 19,
    caption: "WIDTH",
    dataField: "vdtWidth",
    alignment: "left",
    fixed: true,
    width: 100
  },
  {
    id: 20,
    caption: "HEIGHT",
    dataField: "vdtHeight",
    alignment: "left",
    fixed: true,
    width: 100
  },
  {
    id: 21,
    caption: "No OF AXLES",
    dataField: "vdtNoOfAxles",
    alignment: "left",
    fixed: true,
    width: 100
  },
  {
    id: 22,
    caption: "No OF CYLINDERS",
    dataField: "vdtNoOfCylinders",
    alignment: "left",
    fixed: true,
    width: 100,
  },
  {
    id: 23,
    caption: "No OF WHEELS",
    dataField: "vdtNoOfWheels",
    alignment: "left",
    fixed: true,
    width: 100
  },
  {
    id: 24,
    caption: "TANK CAPACITY",
    dataField: "vdtTankCapacity",
    alignment: "left",
    fixed: true,
    width: 100
  },
  {
    id: 25,
    caption: "SEATING CAP.",
    dataField: "vdtSeatingCapacity",
    alignment: "left",
    fixed: true,
    width: 100
  },
  {
    id: 26,
    caption: "TYRE SIZE (F)",
    dataField: "vdtTyreSizeFront",
    alignment: "left",
    fixed: true,
    width: 100
  },
  {
    id: 27,
    caption: "TYRE SIZE (M)",
    dataField: "vdtTyreSizeMiddle",
    alignment: "left",
    fixed: true,
    width: 100
  },
  {
    id: 28,
    caption: "TYRE SIZE (R)",
    dataField: "vdtTyreSizeRear",
    alignment: "left",
    fixed: true,
    width: 100
  },
  {
    id: 29,
    caption: "STATUS",
    dataField: "vstName",
    alignment: "left",
    fixed: true,
    width: 100
  },
  {
    id: 30,
    caption: "PRICE",
    dataField: "vdtPrice",
    alignment: "left",
    fixed: true,
    width: 100
  },
  {
    id: 31,
    caption: "PRICE CURR.",
    dataField: "curSymbol",
    alignment: "left",
    fixed: true,
    width: 100
  },
  {
    id: 32,
    caption: "OTHER EXPENSES",
    dataField: "vdtCubicCapacity",
    alignment: "left",
    fixed: true,
    width: 120
  },
  {
    id: 33,
    caption: "ASSIGNED?",
    dataField: "vdtAssigned",
    alignment: "left",
    fixed: true,
    width: 80

  },
  {
    id: 34,
    caption: "ACTIVE?",
    dataField: "vstFitStatus",
    alignment: "left",
    fixed: true,
    width: 80

  },
  {
    id: 35,
    caption: "REMARKS",
    dataField: "edtRmks",
    alignment: "left",
    fixed: true,
    width: 4000
  },



]



export const vehicle_data_upload_data = [

]