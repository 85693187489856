/* eslint-disable react/jsx-pascal-case */
import { forwardRef, useImperativeHandle, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import useFetch from "../../../../../../hooks/useFetch";
import Datagrid_template from "../../../../../../templates/Datagrid";
import { navbar_height } from "../../../../../accessories/component_infos";
import { HrSetupsCustomToolbar } from "../../../../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import { approversRequestColumns } from "./data/approversRequestColumn";
import VehicleRequestPriveledgesForm from "./widgets/vehicleRequestPriveledgesForm";
import {
  searchCriteriaTypes,
  searchTextTypes,
  statusTypes,
} from "../../../../../human-resource/setups/data/types/selectsTypes";
import { ModalTemplate } from "../../../../../../templates/modal";
import { useAccountInfo } from "../../../../../../hooks/useAccountInfo";
import dayjs from "dayjs";
import useResizeMode from "../../../../../../hooks/useResizeMode";
import { useCrudFunc } from "../../../../../../functions/crud";

export const TMSApproversVehicleRequestPriveledges = forwardRef(
  ({ a }: any, ref) => {

    const [refresh, setRefresh] = useState(false);

    const [employeeID, userID] = useAccountInfo();

    const [PostDataFunc, UpdateDataFunc] = useCrudFunc();

    //navigation
    const setupPageRoute = window.location.pathname; //get current route of page
    const datagridOnlySearchParam = "table-only"; //search param in datagrid mode
    const formModeSearchParam = "form-and-table"; //search param in form mode mode
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    //miscellaneous heights
    const miscHeight = 7;

    //varying form height
    const [form_height, set_form_height] = useState<number>(365);

    // on page resize height responsiveness
    const [trigger, setTrigger] = useState<boolean>(false);
    useImperativeHandle(ref, () => ({
      test: () => {
        setTrigger(!trigger);
      },
    }));

    // handle interface switch
    const toggle_staff_form = (): void => {
      const view = searchParams.get("view");

      view === null || view === formModeSearchParam
        ? navigate({
          pathname: setupPageRoute,
          search: createSearchParams({
            view: datagridOnlySearchParam,
          }).toString(),
        })
        : navigate({
          pathname: setupPageRoute,
          search: createSearchParams({
            view: formModeSearchParam,
          }).toString(),
        });
    };

    var editedDate = dayjs().format();
    const [resizeMode, initialResizeMode] = useResizeMode(
      searchParams.get("view") === formModeSearchParam ||
      searchParams.get("view") === null
    );
    //Table Height
    const pageHeight = resizeMode - (navbar_height + miscHeight + 460);
    const tableHeight = initialResizeMode - 190;

    const criteria = [
      { id: 0, value: "Approver" },
      { id: 1, value: "Request Area" },
    ];

    const [searchText, setSearchText] = useState<searchTextTypes>({
      text: "",
      temp: "",
    });
    const [searchCriteria, setSearchCriteria] = useState<searchCriteriaTypes>({
      temp: 0,
      index: 0,
    });
    const [status, setStatus] = useState<statusTypes>({ temp: "", status: "" });

    const mainGdvUrl = `AsmTmsVehicleRequestPrivileges/VehicleRequestPrivilegesPopDgv?criteriaIndex=${searchCriteria.index}&active=${status.status}&search=${searchText.text}`;

    const [selectedRecord, setSelectedRecord] = useState<any>({});

    // Endpoints
    const [DBdata, DBerror, DBloading] = useFetch(
      mainGdvUrl,
      refresh,
      "vaaIdpk"
    );

    const CancelData = {
      message: "Are you sure you want to set this record as Cancelled",
      title: "Toggle Cancel Status? ",
      okText: "Yes",
      cancelText: "No",
    };
    const ActivateData = {
      message: "Are you sure you want to Activate  this Record",
      title: "Toggle Active Status?",
      okText: "Yes",
      cancelText: "No",
    };
    const CancelDataAll = {
      message: `Are you sure you want to toggle the active status of all records for ${selectedRecord?.empName} to false?`,
      title: "Toggle All Cancelled Status? ",
      okText: "Yes",
      cancelText: "No",
    };
    const ActivateDataAll = {
      message: `Are you sure you want to toggle the active status of all records for ${selectedRecord?.empName} to true?`,
      title: "Toggle All Active Status? ",
      okText: "Yes",
      cancelText: "No",
    };

    const [refreshValue, setRefreshValue] = useState(false);
    const [showModalDisableButton, setshowModalDisableButton] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [modalData, setModalData] = useState<any>(ActivateData);
    const [icon, setIcon] = useState<"warning" | "success" | "question">();
    // toggle selected record

    const Cancel = async () => {
      if (Object.keys(selectedRecord).length < 1) {
        setIcon("warning");
        setshowModalDisableButton(true);
        setModalData({
          message: "Please select a Record",
          title: "Select Vehicle request",
          okText: "Ok",
        });
        setOpenModal(true);
      }

      if (selectedRecord.vaaActive === true) {
        try {
          await UpdateDataFunc(
            `AsmTmsVehicleRequestPrivileges/UpdateActivateSingleBtn`,
            {
              vaaIdpk: selectedRecord?.vaaIdpk,
              vaaActive: false,
              vaaEditedBy: userID,
              vaaEditedDate: editedDate,
            },
            `Updating vehicle request privileges with ID:  ${selectedRecord?.vaaIdpk}`
          );
          setRefreshValue(!refreshValue);
          setRefresh(!refreshValue);
          setIcon("success");
          setOpenModal(true);
          setshowModalDisableButton(false);
          setModalData({
            message: "Request has been Changed successfully!",
            title: "Active Changed Successfully",
            okText: "Ok",
          });
        } catch (error: any) {
          setIcon("warning");
          setOpenModal(true);
          setshowModalDisableButton(false);
          setModalData({
            message: error.response ? error.response.data : "Server Error",
            title: "Unexpected Error!",
            okText: "Ok",
          });
        }
      } else {
        Activate();
      }
    };

    const Activate = async () => {
      if (Object.keys(selectedRecord).length < 1) {
        setIcon("warning");
        setshowModalDisableButton(true);
        setModalData({
          message: "Please select a Record",
          title: "Select Vehicle request",
          okText: "Ok",
        });
        setOpenModal(true);
      }

      if (selectedRecord?.vaaActive === false) {
        try {
          await UpdateDataFunc(
            `AsmTmsVehicleRequestPrivileges/UpdateActivateSingleBtn`,
            {
              vaaIdpk: selectedRecord?.vaaIdpk,
              vaaActive: true,
              vaaEditedBy: userID,
              vaaEditedDate: editedDate,
            },
            `Updating vehicle request privileges with ID:  ${selectedRecord?.vaaIDpk}`
          );
          setRefreshValue(!refreshValue);
          setRefresh(!refreshValue);
          setIcon("success");
          setOpenModal(true);
          setshowModalDisableButton(false);
          setModalData({
            message: "Request has been Activated successfully!",
            title: "Activated Successfully",
            okText: "Ok",
          });
        } catch (error: any) {
          setIcon("warning");
          setOpenModal(true);
          setshowModalDisableButton(false);
          setModalData({
            message: error.response ? error.response.data : "Server Error",
            title: "Unexpected Error!",
            okText: "Ok",
          });
        }
      } else {
        Activate();
      }
    };

    // ------------------------------------------------------------------------------------------------------------------------------------------------------

    // toggle All record

    const CancelAll = async () => {
      if (Object.keys(selectedRecord).length < 1) {
        setIcon("warning");
        setshowModalDisableButton(true);
        setModalData({
          message: "Please select a Record",
          title: "Select Vehicle request",
          okText: "Ok",
        });
        setOpenModal(true);
      }

      if (selectedRecord?.vaaActive === true) {
        try {
          // this loops through all records which is done backend
          await UpdateDataFunc(
            `AsmTmsVehicleRequestPrivileges/UpdateActivateAllBtn`,
            {
              vaaEmpIdfk: selectedRecord?.empIdpk,
              vaaActive:
                selectedRecord?.vaaActive !== undefined ||
                  selectedRecord?.vaaActive !== null ||
                  selectedRecord?.vaaActive !== ""
                  ? !selectedRecord?.vaaActive
                  : false,
              vaaEditedBy: userID,
              vaaEditedDate: editedDate,
            },
            `Updating vehicle request privileges with ID:  ${selectedRecord?.empIdpk}`
          );
          setRefreshValue(!refreshValue);
          setRefresh(!refreshValue);
          setIcon("success");
          setOpenModal(true);
          setshowModalDisableButton(false);
          setModalData({
            message: "Request has been Changed successfully!",
            title: "Active Changed Successfully",
            okText: "Ok",
          });
        } catch (error: any) {
          setIcon("warning");
          setOpenModal(true);
          setshowModalDisableButton(false);
          setModalData({
            message: error.response ? error.response.data : "Server Error",
            title: "Unexpected Error!",
            okText: "Ok",
          });
        }
      } else {
        ActivateAll();
      }
    };

    const ActivateAll = async () => {
      if (Object.keys(selectedRecord).length < 1) {
        setIcon("warning");
        setshowModalDisableButton(true);
        setModalData({
          message: "Please select a Record",
          title: "Select Vehicle request",
          okText: "Ok",
        });
        setOpenModal(true);
      }

      if (selectedRecord.vaaActive === false) {
        try {
          // this loops through all the records which is done backend
          await UpdateDataFunc(
            `AsmTmsVehicleRequestPrivileges/UpdateActivateAllBtn`,
            {
              vaaEmpIdfk: selectedRecord?.empIdpk,
              vaaActive:
                selectedRecord?.vaaActive !== undefined ||
                  selectedRecord?.vaaActive !== null ||
                  selectedRecord?.vaaActive !== ""
                  ? !selectedRecord?.vaaActive
                  : true,
              vaaEditedBy: userID,
              vaaEditedDate: editedDate,
            },
            `Updating vehicle request privileges with ID:  ${selectedRecord?.empIdpk}`
          );
          setRefreshValue(!refreshValue);
          setRefresh(!refreshValue);
          setIcon("success");
          setOpenModal(true);
          setshowModalDisableButton(false);
          setModalData({
            message: "Request has been Activated successfully!",
            title: "Activated Successfully",
            okText: "Ok",
          });
        } catch (error: any) {
          setIcon("warning");
          setOpenModal(true);
          setshowModalDisableButton(false);
          setModalData({
            message: error.response ? error.response.data : "Server Error",
            title: "Unexpected Error!",
            okText: "Ok",
          });
        }
      } else {
        CancelAll();
      }
    };

    return (
      <div className="h-full">
        <ModalTemplate
          icon_width={modalData.iconSize === "large" ? 100 : 50}
          icon={icon}
          cancelText={modalData.cancelText}
          disableCancel={showModalDisableButton || icon === "success"}
          cancelHandler={() => {
            setOpenModal(false);
          }}
          open={openModal}
          okHandler={() => {
            modalData.title === ActivateData.title
              ? Activate()
              : modalData.title === CancelData.title
                ? Cancel()
                : modalData.title === ActivateDataAll.title
                  ? ActivateAll()
                  : modalData.title === CancelDataAll.title
                    ? CancelAll()
                    : setOpenModal(false);
          }}
          message={modalData.message}
          okText={modalData.okText}
          title={modalData.title}
        />

        <div className="px-3">
          <HrSetupsCustomToolbar
            searchTextOnchange={(text) =>
              setSearchText({ temp: text, text: searchText.text })
            }
            searchCriteriaOnchange={(crit) =>
              setSearchCriteria({ temp: crit, index: searchCriteria.index })
            }
            checkOnChange={(check) =>
              setStatus({ temp: check, status: status.status })
            }
            handleFind={() => {
              setRefresh((current) => !current);
              setSearchText({ temp: searchText.temp, text: searchText.temp });
              setSearchCriteria({
                temp: searchCriteria.temp,
                index: searchCriteria.temp,
              });
              setStatus({ temp: status.temp, status: status.temp });
            }}
            searchCriteriaData={criteria}
            handleRefresh={() => {
              setRefresh((current) => !current);
              setSearchText({ temp: "", text: "" });
              setSearchCriteria({
                temp: 0,
                index: 0,
              });
              setStatus({ temp: "", status: "" });
            }}
            handleToggle={() => {
              selectedRecord.vaaActive === false
                ? setModalData(ActivateData)
                : setModalData(CancelData);
              setOpenModal(true);
              setIcon("question");
            }}
            handleToggleAll={() => {
              selectedRecord.vaaActive === false
                ? setModalData(ActivateDataAll)
                : setModalData(CancelDataAll);
              setOpenModal(true);
              setIcon("question");
            }}
            assignmentsForm
            disableNew_edit
            withDates={false}
            toggler={toggle_staff_form}
          />
        </div>

        {/* form section */}
        {searchParams.get("view") !== datagridOnlySearchParam && (
          <section
            style={{ height: form_height }}
            className=" form border-b-4 p-1"
          >
            <VehicleRequestPriveledgesForm setRefreshx={setRefresh} />
          </section>
        )}

        {/* datagrid section */}
        <section className=" px-2">
          <Datagrid_template
            gridheight={
              searchParams.get("view") === formModeSearchParam ||
                searchParams.get("view") === null
                ? pageHeight
                : tableHeight
            }
            data={DBdata}
            onRowClick={(selected) => {
              setSelectedRecord(selected);
            }}
            columns={approversRequestColumns}
            selectionMode={"single"}
          />
        </section>
      </div>
    );
  }
);
