import axios from "axios"
import { api_url, recordExistsError, serverError } from "../components/accessories/component_infos";
import { requestConfig } from "../general/requestConfig";

export const validateRecordExists = async(url:string) =>{
    try {
        console.log('hehe');
        
        const checkResponse = await axios.get(`${api_url}/${url}`, requestConfig());
        console.log(checkResponse);
        
        // record not exists
        if (checkResponse.status === 204) {
            return {status:204,message:'Record does not exist',title:'New',data:{}}
        }

        //record exists
        return {status:200,...recordExistsError,data:checkResponse.data}
        
    } catch (error) {
        console.log(error);
        
        return {status:500,...serverError,data:{}};
    }
}