import axios from 'axios';
import { forwardRef, useImperativeHandle, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../app/store';
import { setValue, tier1SaveOnclick, tier1UpdateOnclick } from '../../../../features/forms/tier1/createSlice';
import { setGeneralValue } from '../../../../features/generalSlice';
import useFetch from '../../../../hooks/useFetch';
import { ModalTemplate } from '../../../../templates/modal';
import { validationsData, saveModalData, updateModalData, deleteModalData, api_url } from '../../../accessories/component_infos';
import CustomLoader from '../../../accessories/custom-loader';
import Setups_Template from '../../../human-resource/setups/.setups_templates/Setups_Template';
import { exprsTypes, modalTypes, searchTextTypes } from '../../../human-resource/setups/data/types/selectsTypes';
import { performance_target_columns } from './data/targetsData';



export const Perf_setups_Target = forwardRef(({a}:any, ref)=>{
    const activeStatus = useSelector((state:RootState)=>state.general.activeStatus);
    const[searchText,setSearchText]=useState<searchTextTypes>({temp:'',text:''});
    const[status,setStatus]=useState<boolean|string>('');
    const fetchUrl = `Targets/SearchTarget?searchText=${searchText.text}&activeState=${status}`;
    const saveSuccessText = {message : 'Target added Successfully',ok : 'Ok' , title : 'Success'} // successful save modal message
    const updateSuccessText = {message : 'Targetupdated Successfully',ok : 'Ok' , title : 'Success'} // successful update modal message

    const[busyLoader,setBusyLoader] = useState<string>('')
    // store data expressions[unique] ...dependent on data coming in
    const idExpr:string = 'tgtIdpk'
    const nameExpr:string = 'tgtName'
    const shortnameExpr:string = 'tgtShtName'
    const orderExpr: string = 'tgtOrder';
    const activeExpr: string = 'tgtActive';
    const remarksExpr: string = 'tgtRmks';
    const inputsExprsData: any = {idExpr : idExpr,nameExpr : nameExpr,shortnameExpr : shortnameExpr,orderExpr : orderExpr,activeExpr,remarksExpr : remarksExpr }

    const storeExprs = () =>{
        const exprs = [{expr:'existsUrl',value:`Targets`},{expr: 'idExpr' ,value : idExpr},{expr: 'nameExpr' ,value : nameExpr},{expr: 'shortnameExpr' ,value : shortnameExpr}]
        //to particular redux store based on form type
        for (let i = 0; i < exprs.length; i++) {            
            dispatch(setValue({expr: exprs[i].expr ,value : exprs[i].value}));
        }

        // to general slice
        const generalExprs:exprsTypes[] = [{expr: 'inputExprs' ,value : inputsExprsData}]
        for (let i = 0; i < generalExprs.length; i++) {            
            dispatch(setGeneralValue({expr: generalExprs[i].expr ,value : generalExprs[i].value}));
        }
    }

    const dispatch = useDispatch();
    const [refresh, setRefresh] = useState<boolean>(false); //refresh data state for data creation or update

    //modal states
    //--general--
    const triggerSuccessModal = useSelector((state:RootState)=>state.tier1Add.successModalState); //trigger success modal
    const SuccessModalText = useSelector((state:RootState)=>state.tier1Add.successText); //success modal text

    //--for backend errors--
    const[errState,setErrState] = useState<boolean>(false);
    const[errTitle,seterrTitle] = useState<string>('');
    const[errMessage,seterrMessage] = useState<string>('');

    //--warning modal--
    const triggerwarningModal = useSelector((state:RootState)=>state.tier1Add.warningState); //trigger warning
    const warningInfos:any = validationsData; //warning type details
    const warningDataExpr = useSelector((state:RootState)=>state.tier1Add.warningDataExpr); //warning type selector

    //--save data modal--
    const saveData = saveModalData; //save modal details[data]
    const triggersaveModal = useSelector((state:RootState)=>state.tier1Add.saveDataOnclick); //trigger save modal
   
    //--Update data modal--
    const updateIndInfos = updateModalData; //update modal details[data]
    const triggerupdateModal:boolean = useSelector((state:RootState)=>state.tier1Add.updateDataOnclick); //trigger update question

    //--Update data modal--
    const deleteDataInfos = deleteModalData; //update modal details[data]
    const triggerdeleteModal:boolean = useSelector((state:RootState)=>state.tier1Add.deleteDataOnclick); //trigger update question

    // on page resize height responsiveness
    const [trigger,setTrigger] = useState<boolean>(false);
    useImperativeHandle(ref, () => ({
        test: () => {
            setTrigger(!trigger);
        }
    }));

    // get current values from  [slice dependent on form type]
    const name = useSelector((state:RootState)=>state.tier1Add.name);
    const shortname = useSelector((state:RootState)=>state.tier1Add.shortname);
    const order = useSelector((state:RootState)=>state.tier1Add.order);
    const active = useSelector((state:RootState)=>state.tier1Add.active);
    const remarks = useSelector((state:RootState)=>state.tier1Add.remarks);
    const selectedID = useSelector((state:RootState)=>state.general.SelectedFieldID);

    //post form Data
    //validate data
    const validateDataSave = () =>{
        dispatch(tier1SaveOnclick());
    }
    // post
    const postData = async() =>{
        dispatch(setValue({expr: 'saveDataOnclick' ,value : false})) //disable modal
        try {
            setBusyLoader('Updating record')
            const res = await axios.post(`${api_url}/Targets/CreateTarget`,{
                tgtName : name,
                tgtShtName : shortname,
                tgtActive : active,
                tgtOrder:order,
                tgtRmks : remarks,
                tgtorder : order,
                tgtCreatedBy : '1',
            })
        
            setRefresh(!refresh);
            setBusyLoader('') //disable busy loader

            const exprs:exprsTypes[] = [{expr: 'saveDataOnclick' ,value : false},{expr: 'successModalState' ,value : true},{expr: 'successText' ,value : saveSuccessText}];
            for (let i = 0; i < exprs.length; i++) {            
                dispatch(setValue({expr: exprs[i].expr ,value : exprs[i].value}));
            }
        } catch (error:any) {
            console.log(error);
            setBusyLoader('')

            //set states for db errors modal
            setErrState(true);
            seterrTitle(error.response ? error.response.data.message : 'Server Error');
            seterrMessage(error.response ? error.response.data.errors[0] : 'Error connecting to server. Please contact system administrator');
        }
    }

    //Update Form data
    //validate data
    const validateDataUpdate = () =>{
        dispatch(tier1UpdateOnclick());
    }
    // update
    const UpdateData = async()=>{
        dispatch(setValue({expr: 'updateDataOnclick' ,value : false})) //disable modal
        try {
            setBusyLoader('Updating record')
            const res = await axios.put(`${api_url}/Targets/UpdateTarget/${selectedID}`,{
                tgtIDpk : selectedID,
                tgtName : name,
                tgtShtName : shortname,
                tgtActive : active,
                tgtOrder:order,
                tgtRmks : remarks,
                tgtporder : order,
                tgtEditedBy : '1',
            })
            
            // refresh datagrid data
            setRefresh(!refresh);

            setBusyLoader('') //disable busy loader

            //disable update mode
            dispatch(setGeneralValue({expr : 'updateMode', value : false})); // dispatch fetched data to reduxstore
    
            const exprs:exprsTypes[] = [{expr: 'updateDataOnclick' ,value : false},{expr: 'successModalState' ,value : true},{expr: 'successText' ,value : updateSuccessText}];
            for (let i = 0; i < exprs.length; i++) {            
                dispatch(setValue({expr: exprs[i].expr ,value : exprs[i].value}));
            }
        } catch (error:any) {
            console.log(error);
            setBusyLoader('') //disable busy loader

            //set states for db errors modal
            setErrState(true);
            seterrTitle(error.response.data.message);
            seterrMessage(error.response.data.errors[0]);
        }
    }

    //modals data
    const modals:modalTypes[] = [
        //warning
        {disableCancel : true , icon : 'warning' ,open : triggerwarningModal, okHandler : ()=> dispatch(setValue({expr: 'warningState' ,value : false})), cancelHandler : ()=> dispatch(setValue({expr: 'warningState' ,value : false})), message : warningInfos[warningDataExpr!].message, cancelText : warningInfos[warningDataExpr!].cancelText, okText : warningInfos[warningDataExpr!].okText, title : warningInfos[warningDataExpr!].title},
        //backend error
        {disableCancel : true , icon : 'warning' ,open : errState, okHandler : ()=> setErrState(false), cancelHandler : ()=> setErrState(false), message : errMessage, cancelText : '', okText : 'Ok', title : errTitle},
        //success
        {disableCancel : true, icon : 'success' ,open : triggerSuccessModal, okHandler : ()=> dispatch(setValue({expr: 'successModalState' ,value : false})), cancelHandler : ()=> dispatch(setValue({expr: 'successModalState' ,value : false})), message :SuccessModalText.message, okText : SuccessModalText.okText, title : SuccessModalText.title},
        //save
        {disableCancel : false , icon : 'question' ,open : triggersaveModal, okHandler : ()=> postData(), cancelHandler : ()=> dispatch(setValue({expr: 'saveDataOnclick' ,value : false})), message : saveData.message, cancelText : saveData.cancelText, okText : saveData.okText, title : saveData.title},
        //Update
        {disableCancel : false , icon : 'question' ,open : triggerupdateModal, okHandler : ()=> UpdateData(), cancelHandler : ()=> dispatch(setValue({expr: 'updateDataOnclick' ,value : false})), message : updateIndInfos.message, cancelText : updateIndInfos.cancelText, okText : updateIndInfos.okText, title : updateIndInfos.title},
    ]

    // data for datagrid
    const [data,error,loading] = useFetch(fetchUrl,refresh,'tgtIdpk');
    if(data){
        storeExprs(); // run func to store expressions on store
        dispatch(setGeneralValue({expr : 'idExpr', value : idExpr})); // dispatch fetched data to reduxstore
        dispatch(setGeneralValue({expr: 'formData' ,value : data})); // dispatch fetched data to reduxstore
    }
    if (error){
        console.log(error);
              
        const exprs = [{expr: 'warningState' ,value : true},{expr: 'warningDataExpr' ,value : 'serverError'}]
        //to particular redux store based on form type
        for (let i = 0; i < exprs.length; i++) {            
            dispatch(setValue({expr: exprs[i].expr ,value : exprs[i].value}));
        }
    }

    // form parameters
    const form_header = 'Target Details';

    return (
        <>
            {busyLoader !== '' && <CustomLoader text= {busyLoader}/>}
            {modals.map(({disableCancel,icon,okHandler,cancelHandler,open,cancelText,okText,message,title}:modalTypes,index)=>{
                return(
                    <div key = {index}>
                        <ModalTemplate  icon= {icon && icon} disableCancel = {disableCancel} cancelText = {cancelText && cancelText} open = {open} okHandler={okHandler} cancelHandler={cancelHandler} message= {message} okText= {okText} title={title}/>
                    </div>
                )
            })}
            
            {loading &&
            <CustomLoader/>
            }
            <Setups_Template updateData={validateDataUpdate} createData={validateDataSave} trigger = {trigger} datagridColumns={performance_target_columns} datagridData = {data} formHeader={ form_header}
            searchTextChange={(txt)=>{setSearchText({temp:txt,text:searchText.text})}}
            handleSearch={()=>{setSearchText({temp:searchText.temp,text:searchText.temp}); setStatus(activeStatus)}}
            />
        </>
    );
})