/* eslint-disable react/jsx-pascal-case */
import Alerts from "../Alerts/Alerts";
import CalendarComponent from "../Calendar/calendar";
import SmsAlerts from "../SmsAlerts/SmsAlerts";
import StickyNotes from "../Stickynotes/stickynotes";
import TelephoneDir from "../TelephoneDirectory/TelephoneDir";
import { useRef } from "react";
import menu from "../../../../assets/list.png";
import rss from "../../../../assets/rss.png";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { setGeneralValue } from "../../../../features/generalSlice";
import { useDispatch } from "react-redux";
import useResizeMode from "../../../../hooks/useResizeMode";

interface props {
  userSettingColor?: any;
  userSettingTC?: any;
  isOpened?: boolean;
  current?: string;
  setIsOpened?: (e: boolean) => void;
  components?: any[];
  setCurrent?: (e: string) => void;
  icons: any[];
  userSettingShowRightBar?: boolean;
}
export const Panel = ({
  userSettingColor,
  userSettingTC,
  current,
  setCurrent,
  setIsOpened,
}: props) => {
  const alertRef = useRef<any>();
  const dispatch = useDispatch();


  const Just_A_Content_By_The_way = () => {
    // let  [resizeOnf11,initialSize] = useResizeMode(window.innerWidth === screen.width && window.innerHeight === screen.height);
    let  [resizeOnf11,initialSize] = useResizeMode(window.innerWidth  && window.innerHeight );

    let  normalPageSize =  initialSize - 208;
    let  onF11PageSize = resizeOnf11 - 208;
    return (
      <main
        className="flex p-5 justify-center items-center  flex-1"
        style={{ height: window.innerWidth  && window.innerHeight  ? normalPageSize : onF11PageSize}}
      >
        <p className="text-center border border-gray-400 text-lg font-bold text-slate-400">
          Please I Need A Content To Display
        </p>
      </main>
    );
  };
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const icon = [
    {
      id: 0,
      class: `hover:cursor-pointer fa-solid fa-bell text-amber-400 p-2   border-2 rounded-md`,
      functionLabel: "Alerts",
    },
    {
      id: 1,
      class:
        "hover:cursor-pointer fa-solid fa-file text-amber-400 p-2 border-2 rounded-md",
      functionLabel: "Stickynotes",
    },
    {
      id: 2,
      class:
        "hover:cursor-pointer fa-solid fa-phone text-stone-500 p-2 border-2 rounded-md",
      functionLabel: "Telephone",
    },
    {
      id: 3,
      class:
        "hover:cursor-pointer fa-solid fa-envelope-circle-check text-blue-500 p-2 border-2 rounded-md",
      functionLabel: "SMS Alerts",
    },
    {
      id: 4,
      class: "hover:cursor-pointer  text-blue-500 p-2 border-2 rounded-md",
      functionLabel: "Menu",
      icon: (
        <span>
          <img alt="" src={menu} />
        </span>
      ),
    },
    {
      id: 5,
      class:
        "hover:cursor-pointer fa-solid fa-calendar-days text-red-500 p-2 border-2 rounded-md",
      functionLabel: "Calendar",
    },
    {
      id: 6,
      class: "hover:cursor-pointer  text-blue-500 p-2  border-2 rounded-md",
      functionLabel: "RSS",
      icon: (
        <span>
          <img alt="" src={rss} />
        </span>
      ),
    },
  ];
  return (
    <>
      <div
        className={` bg-${userSettingColor?.color ?? "white"} text-${
          userSettingTC?.color ?? "black"
        } dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base `}
        // style={{backgroundColor:'red'}}
      >
        <div className="h-full">
          <div
            className="shadow-xl  h-full"
            style={{
              width:
                current === "Telephone"
                  ? "550px"
                  : current === "SMS Alerts"
                  ? "550px"
                  : "350px",
            }}
          >
            <div className="px-2 dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base ">
              <div className="flex justify-between pt-1 h-full">
                <div className="font-bold text-base text-slate-800">
                  {current === "Telephone" ? "Telephone Directory" : current}
                </div>
                <div
                  className="p-0.5"
                  onClick={() => {
                    setIsOpened && setIsOpened(false);

                    dispatch(
                      setGeneralValue({
                        expr: "rightNavigatorOnStartup",
                        value: false,
                      })
                    );
                  }}
                >
                  <i className="rotate-90  hover:cursor-pointer fa-sharp fa-solid fa-thumbtack"></i>
                </div>
              </div>
              {current === "Alerts" ? (
                <Alerts ref={alertRef} pageResized={() => {}} />
              ) : current === "Stickynotes" ? (
                <StickyNotes ref={alertRef} pageResized={() => {}} />
              ) : current === "Calendar" ? (
                <CalendarComponent ref={alertRef} pageResized={() => {}} />
              ) : current === "SMS Alerts" ? (
                <SmsAlerts ref={alertRef} pageResized={() => {}} />
              ) : current === "Telephone" ? (
                <TelephoneDir ref={alertRef} pageResized={() => {}} />
              ) : current === "Menu" ||
                current === "RSS" ||
                current === "Help" ? (
                <Just_A_Content_By_The_way/>
              ) : (
                <Alerts ref={alertRef} pageResized={() => {}} />
              )}
            </div>
            <div className="border-b mx-2 border-stone-300 "></div>

            <div   className="items-center py-2 flex justify-around">
              {icon.map((item) => {
                return item.id === 4 || item.id === 6 ? (
                  <li
                    className={item.class}
                    onClick={() => {
                      setCurrent!(item.functionLabel);
                    }}
                    style={{ borderColor: borderTheme, listStyleType: "none" }}
                    key={item.id}
                  >
                    {item.icon}
                  </li>
                ) : (
                  <li
                    className={item.class}
                    onClick={() => {
                      setCurrent!(item.functionLabel);
                    }}
                    
                    style={{ borderColor: borderTheme, listStyleType: "none" }}
                    key={item.id}
                  ></li>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
