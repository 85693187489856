import { Divider, Modal } from "antd";
import { optionsTypes } from "../../../components/human-resource/setups/data/types/selectsTypes";
import question from "../../../assets/helpothers.png";
import success from "../../../assets/success.png";
import warning from "../../../assets/warning.png";

interface props {
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
  passRules?: boolean;
  isIcon?: boolean;
  icon?: string;
  icon_width?: string | number;
}
export const PasswordRulesModal = ({
  onCancel,
  onOk,
  open,
  passRules = false,
  isIcon,
  icon_width,
  icon = "warning",
}: props) => {
  const passwordRules: optionsTypes[] = [
    { id: 1, data: 'It must not contain any form of the word "PASS" ' },
    { id: 2, data: "It must not contain your USERNAME " },
    { id: 3, data: "It must not contain your STAFF NUMBER " },
    { id: 4, data: "It must not contain any part of your NAME " },
    { id: 5, data: "It must not contain any form of your Date of Birth " },
    {
      id: 6,
      data: "It must not contain your Mobile or Telephone Numbers or PLC ",
    },
    {
      id: 7,
      data: 'It must not contain any form of the word "aabbcc" or "123456" ',
    },
    {
      id: 8,
      data: 'It must not contain any form of the word "abc123" or "abcabc" ',
    },
    {
      id: 9,
      data: 'It must not contain any form of the word "abcdef" or "cccc" ',
    },
    {
      id: 10,
      data: 'It must not contain the word "aaaa" or "bbbb" or "cccc" ',
    },
    {
      id: 11,
      data: 'It must not contain the word "xxxx" or "yyyy" or "zzzz" and',
    },

    { id: 12, data: "It must contain at least one CAPITAL letter " },
    { id: 13, data: "It must contain at least three(3) different characters " },
    { id: 14, data: "It must contain " },
    { id: 15, data: "It must contain at least ONE number and " },

    { id: 16, data: "It must contain EIGHT(8) or MORE CHARACTERS in all " },
  ];

  const howToLogin: optionsTypes[] = [
    { id: 1, data: "Enter Username or Staff Number" },
    { id: 2, data: "Enter your Password" },
    { id: 3, data: "Click on the Login button and" },
    { id: 4, data: "Wait for the main form to launch" },
  ];

  const forgottenPassword: optionsTypes[] = [
    { id: 1, data: "Enter Username or Staff Number" },
    {
      id: 2,
      data: 'Click on the "Forgot your password" text on the login form',
    },
    { id: 3, data: 'Click on the "Yes" button on the pop up window' },
    { id: 4, data: "Wait for a text message on your mobile phone" },
    {
      id: 5,
      data: "Enter the five(5) digits number in the SMS in the password entry box",
    },
    { id: 6, data: "Click on the login button" },
    {
      id: 7,
      data: "Enter your new password and confirm it in the pop up window",
    },
    {
      id: 8,
      data: "Click on the update button after the colours of the boxes have changed to green.",
    },
    { id: 9, data: "Wait for the main form to launch" },
  ];

  const contacts = [
    { name: "Nicholas Morkry", contact: "720 395 or 024 730 0488" },
    { name: "Albert Longdon", contact: "720 352 or 026 423 1331" },
    { name: "Dennis Asamoah", contact: "720 263 or 024 240 5049" },
  ];
  return (
    <>
      <Modal
        style={{ left: 400 }}
        width={470}
        centered
        open={open}
        title="Password Rules"
        onOk={onOk}
        onCancel={onCancel}
        okText="Ok"
        cancelText=""
        footer=""
      >
        <main className=" text-xs text-gray-700">
          {passRules ? (
            <>
              <Divider orientationMargin={5} />
              <div className={`w-full flex mb-5 justify-between`}>
                {/* icon */}

                <div className="w-1/6 ">
                  <span 
                    className="w-full"
                  >
                    <img
                      className={" w-20 pr-3"}
                      src={
                        icon === "question"
                          ? question
                          : icon === "warning"
                          ? warning
                          : success
                      }
                      alt=""
                    />
                  </span>
                </div>

                {/* header and info */}
                <div className="w-5/6">
                  <span>Your password must obey the following rules :</span>
                  {/* Reset password rules  */}
                  <div className="pt-7">
                    {passwordRules.map(({ id, data }) => {
                      return <p key={id}>{`${id}. ${data}`}</p>;
                    })}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {/* how to login */}
              <p className=" mt-4 text-blue-800">How to Login?</p>
              {howToLogin.map(({ id, data }) => (
                <p key={id}>{`${id}. ${data}`}</p>
              ))}

              {/* forgotten password */}
              <p className=" mt-6 text-blue-800">
                Have you forgotten your password? Reset it!
              </p>
              <p>If you have forgotten your Password, then:</p>
              {forgottenPassword.map(({ id, data }) => (
                <p key={id}>{`${id}. ${data}`}</p>
              ))}

              {/* further assistance */}
              <p className=" mt-6 text-blue-800">
                In case you are facing other challenges, please call any of the
                numbers below for assistance
              </p>
              {contacts.map(({ name, contact }) => (
                <p key={name}>{`${name} -${contact}`}</p>
              ))}
            </>
          )}
        </main>
      </Modal>
    </>
  );
};
