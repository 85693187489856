import React, { useEffect, useState } from "react"
import { VscNewFile } from 'react-icons/vsc';
import { ImShare } from 'react-icons/im'
import { FaEdit, FaUserTie, FaUsers, FaUserCog, FaRegEdit } from 'react-icons/fa';
import { AiFillSwitcher, AiOutlineProfile, AiFillPrinter, AiOutlineSwitcher } from 'react-icons/ai';
import { HiRefresh } from 'react-icons/hi'
import { IoIosSwitch, IoMdSwitch, } from 'react-icons/io';
import { IoSettingsOutline, IoTrashOutline, IoMailOutline, IoHelpCircle } from 'react-icons/io5';
import 'devextreme/dist/css/dx.light.css';
import { Pager, Paging } from 'devextreme-react/data-grid';
import DropDownButton from 'devextreme-react/drop-down-button';
import Switch from 'devextreme-react/switch';
import DateBox from 'devextreme-react/date-box';
import { CheckBox } from 'devextreme-react/check-box';
import { Tooltip } from "devextreme-react";
import { AlertSMS, AppraiseSettings, CustomSwitcher, DeleteTask, TaskSettings } from "./widgets/customToolbarModals";
import find from '../../../assets/binoculars.png';
import question from '../../../assets/question.png';
import edit from '../../../assets/edit.png';
import swap from '../../../assets/swap.png';
import close from '../../../assets/close.png';
import statusLoader from '../../../assets/statusloader.gif';
import { FaBinoculars } from "react-icons/fa";
import newfile from '../../../assets/new.png';
import refresh from '../../../assets/refresh.png'
import printer from '../../../assets/printer.png'
import update from '../../../assets/update.png'
import alter from '../../../assets/alter.png'
import { Checkbox, DatePicker, Input, Select } from 'antd'
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { setGeneralValue, settask_form_disable, settask_footer_update } from "../../../features/generalSlice";
import { task_form_disable, task_footer_update } from "../../../features/Task&Todo/Task/TaskDetails";
import { useDispatch } from "react-redux";
import { saveModalData } from "../component_infos";
import { ModalTemplate } from "../../../templates/modal";
import { task_footer_table_selected } from "../../../features/forms/entries/safetyManager/ppeRequest";
import dayjs from "dayjs";


interface props {
    startDate?: string
    endDate?: string
    useCallBackFunc?: boolean
    toggler?: () => void //switch interface
    handleDelete?: () => void //delete handler
    handleSave?: () => void //save handler
    handleNew?: () => void //new handler
    handleUpdate?: () => void //update handler
    searchTextOnchange?: (value: string) => void //search text change
    startDateChanged?: (value: any) => void //start date change
    endDateChanged?: (value: any) => void //end date change
    checkOnChange?: (value: any) => void //checkbox change
    handleFind?: () => void //find handler
    handleRefresh?: () => void //refresh main grid
    handlePrint?: () => void // handle prints functionality
    handleToggle?: () => void
    handleToggleAll?: () => void
    searchCriteriaOnchange?: (index: number, criteria: string) => void
    checkBoxAfterHelpOnchange?: (checkValue: boolean) => void // event handler for checkbox after help
    disableNew_edit?: boolean
    withDates?: boolean //add dates to the toolbar?
    outerSelected?: any
    setOuterSelected?: (state: any) => void
    checkBoxAfterHelp?: boolean
    searchCriteria?: boolean
    assignmentsForm?: boolean
    searchCriteriaData?: { id: number, value: string }[]


}



export const CIA_toolbar = ({
    toggler,
    startDate,
    endDate,
    handleToggle,
    handleToggleAll,
    setOuterSelected,
    searchCriteriaOnchange,
    checkBoxAfterHelp = false,
    searchCriteriaData = [],
    assignmentsForm,
    checkBoxAfterHelpOnchange,
    searchCriteria,
    handleNew: newButtonClick,
    handleRefresh,
    handlePrint,
    outerSelected,
    disableNew_edit,
    useCallBackFunc,
    withDates = true,
    handleDelete,
    searchTextOnchange,
    handleSave,
    handleUpdate: handleEdit,
    startDateChanged,
    endDateChanged,
    handleFind,
    checkOnChange
}: props) => {


    const { Search } = Input;

    const dateFormat = " DD MMM YYYY";
    const dispatch = useDispatch()
    const borderTheme = useSelector((state:RootState)=>state.general.cssBorderClass)



    const handleChange = (value: string) => {
        console.log(`selected ${value}`);
    };

    const [searchValue, setSearchValue] = useState<string>();

    // Three state checkboxes
    const [indeterminate, setIndeterminate] = useState<any>(true);
    const [checkedBox, setCheckedBox] = useState<any>(false);
    const [checkValue, setCheckValue] = useState<boolean | string>(''); //actual value to use
    const [searchTextValue, setSearchTextValue] = useState('')

    const [criteriaDataLocal, setCriterialDataLocal] = useState({ id: searchCriteriaData![0]?.id ?? 0, value: searchCriteriaData![0]?.value ?? '' });

    useEffect(() => {
        dispatch(setGeneralValue({ expr: 'activeStatus', value: checkValue }))
        checkOnChange && checkOnChange(checkValue);
    }, [checkValue])

    const onChange = (value: boolean) => {
        if (indeterminate === true) {
            setCheckedBox(true);
            setIndeterminate(false);
            setCheckValue(true);
        }
        else if (!indeterminate && checkedBox) {
            setCheckedBox(false);
            setIndeterminate(false);
            setCheckValue(false)
        }
        else if (!indeterminate && !checkedBox) {
            setCheckedBox(false);
            setIndeterminate(true);
            setCheckValue('');
        }
    };


    // from redux
    const disablex = useSelector((state: RootState) => state.general.formDisabled);
    const disable = useSelector((state: RootState) => state.addTask.taskFormStatus);
    const updateMode = useSelector((state: RootState) => state.addTask.taskFooterUpdate);
    const selectedRecord = useSelector((state: RootState) => state.safetyManagerDetails.selectedRecord)

    // relative to the new button
    const handleNew = () => {
        dispatch(setGeneralValue({ expr: "formDisabled", value: false }));

        dispatch(task_form_disable(false));
        dispatch(settask_form_disable(false));

        //  this destroys the update mode state
        dispatch(task_footer_update(false));
        dispatch(settask_footer_update(false))
        

        //clear data to redux
        dispatch(setGeneralValue({ expr: "selectedFieldData", value: [] }));
        dispatch(task_footer_table_selected([]))
        dispatch(task_footer_table_selected([[]]))

        // clear the selected Data 
        setOuterSelected && setOuterSelected({})
    };

    const handleCancel = () => {
        dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
        dispatch(task_form_disable(true));
        dispatch(settask_form_disable(true))
        // dispatch(update_route_state(false))
        dispatch(task_footer_update(false));
        dispatch(settask_footer_update(false))


        // dispatch(settask_footer_update(false))
        dispatch(setGeneralValue({ expr: 'updateMode', value: false }));

        // NO ONE SHOULD DELETE THIS !!!!
        // handleCallbackCancel!();
    };
    

    const [refreshValue, setRefreshValue] = useState(false);
    const [showModalDisableButton, setshowModalDisableButton] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [modalData, setModalData] = useState<any>(saveModalData);
    const [icon, setIcon] = useState<'warning' | 'success' | 'question'>();
    const [updatingstatus, setUpdatingstatus] = useState(false);
    const [showCancel, setshowCancel] = useState(false);
    const [Active, setActive] = useState(false);
    const [refreshx, setRefreshx] = useState(false);

    const setModal=() => {
        setIcon('warning')
        setOpenModal(true)
        setModalData({title:'Select record',message:'Please select a record to edit'})
    }

    //handle edit
    const handleUpdate = () => {

        if (outerSelected == undefined) {
            // dispatch(task_form_disable(true));
            setIcon('warning')
            setshowModalDisableButton(true);
            setModalData({ message: "No record selected", title: "Invalid record", okText: "Ok" });
            setOpenModal(true);
        }

        else {
            //enable forms
            dispatch(task_form_disable(false));

            //enable Update Mode
            dispatch(task_footer_update(true))
            dispatch(setGeneralValue({ expr: 'formDisabled', value: false }))
            dispatch(setGeneralValue({ expr: 'updateMode', value: true }))
        }
    }



    return (
        <div style={{ borderWidth: '1px', height: 32, borderColor:borderTheme }} className="mt-0.5 rounded px-0.5 w-full h-full flex  border-gray-200 mb-1">
            <div className="flex justify-center" >
                <ModalTemplate icon_width={modalData.iconSize === 'large' ? 100 : 40} icon={icon} cancelText={modalData.cancelText}
                    cancelHandler={() => { setOpenModal(false) }} open={openModal} okHandler={() => { setOpenModal(false) }}
                    message={modalData.message} okText={modalData.okText} title={modalData.title} disableCancel={icon === "warning" || icon === "success" ? true : false} />
                <ul className="flex justify-evenly w-full items-center h-full py-0.5">
                    {/* new and cancel section */}
                    {(disablex || updateMode) ?
                        <li id="newRecord" onClick={() => handleNew()} style={{ borderWidth: '1px',borderColor:borderTheme }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center" >
                            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img className="pr-1 " src={newfile} />New </span>
                            <Tooltip
                                target="#newRecord"
                                showEvent="dxhoverstart"
                                hideEvent="dxhoverend"
                                hideOnOutsideClick={true}
                            >
                                <p className="">New Record</p>
                            </Tooltip>
                        </li>
                        :
                        <li id="close-cancel" onClick={() => handleCancel()} style={{ borderWidth: '1px',borderColor:borderTheme }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center" >
                            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img className="pr-1 " src={close} />Cancel </span>
                            <Tooltip
                                target="#close-cancel"
                                showEvent="dxhoverstart"
                                hideEvent="dxhoverend"
                                hideOnOutsideClick={true}
                            >
                                <p className="">Cancel</p>
                            </Tooltip>
                        </li>
                    }
                    {!updateMode ?
                        <li id="editRecordst" onClick={() => { handleUpdate() }} style={{ borderWidth: '1px',borderColor:borderTheme }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center">
                            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img className="dark:bg-slate-900 pr-1 dark:text-gray-100 duration-100" src={edit} />Edit </span>
                            <Tooltip
                                target="#editRecordst"
                                showEvent="dxhoverstart"
                                hideEvent="dxhoverend"
                                hideOnOutsideClick={true}
                            >
                                <p className="">Edit Record</p>
                            </Tooltip>
                        </li>
                        :
                        <li id="close-cancel" onClick={() => handleCancel()} style={{ borderWidth: '1px',borderColor:borderTheme }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center" >
                            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img className="pr-1 " src={close} />Cancel </span>
                            <Tooltip
                                target="#close-cancel"
                                showEvent="dxhoverstart"
                                hideEvent="dxhoverend"
                                hideOnOutsideClick={true}
                            >
                                <p className="">Cancel</p>
                            </Tooltip>
                        </li>
                    }

                    <li id="switch" onClick={toggler} style={{ borderWidth: '1px',borderColor:borderTheme }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center">
                        <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img className="dark:bg-slate-900 pr-1 dark:text-darkModeSkin-base duration-100" src={alter} />Switch </span>
                        <Tooltip
                            target="#switch"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p className="">Switch interface / table view</p>
                        </Tooltip>
                    </li>




                    {/* <li><FaUserCog size={size}/></li> */}




                    <li id="help" style={{ borderWidth: '1px',borderColor:borderTheme }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center">
                        <span className="flex"> <img className="dark:bg-slate-900  dark:text-gray-100 duration-100" src={question} /> </span>
                        <Tooltip
                            target="#help"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p>Help Center</p>
                        </Tooltip>
                    </li>


                    {/* Differents starts here */}

                    {searchCriteria && (
                        <li className="px-1 flex items-center flex-row ">
                        <label style={{ width: '87px' }} className=' text-xs'>Search Criteria</label>
                        <Select
                            value={criteriaDataLocal}
                            dropdownMatchSelectWidth={false}
                            className=" mt-0.5"
                            onChange={(data: any) => {
                                const criteriaData = JSON.parse(data);
                                setCriterialDataLocal({ id: criteriaData?.index, value: criteriaData?.criteria }) //local
                                searchCriteriaOnchange && searchCriteriaOnchange(criteriaData?.index, criteriaData?.criteria); //external
                            }}
                            id="search"
                            size="small"
                            showSearch={true}
                            style={{ width: 110, height: '25px' }}>
                            {searchCriteriaData?.map(({ id, value }, index) =>
                                <Select.Option key={id} value={JSON.stringify({ index: index, criteria: value })}>{value}</Select.Option>
                            )}
                        </Select>
                    </li>
                    )}


                    <li className="px-1 flex items-center flex-row ml-1 ">
                        <label style={{ width: '70px' }} className=' text-xs'>Search Text</label>
                        <Input
                            onChange={(e: any) => {
                                const searchText: string = e.target.value;
                                searchTextOnchange && searchTextOnchange(searchText);
                                setSearchTextValue(e.target.value)
                            }}
                            value={searchTextValue}
                            name="search"
                            id="search"
                            size="small"
                            allowClear={true}
                            style={{ width: 130, height: '24px' }} />
                    </li>
                    {/* <li className="px-0.5">
                        <DatePicker value={dayjs(startDate)??dayjs()} onChange={startDateChanged} size="small" placeholder="Start Date" style={{ width: 125 }}  format={"DD MMM YYYY"}/>
                        </li>
                        <li className="px-0.5">
                        <DatePicker value={dayjs(endDate)??dayjs()} onChange={endDateChanged} size="small" placeholder="End Date" style={{ width: 125 }} format={"DD MMM YYYY"}/>
                        </li> */}
                    <li id='status' className="mr-1" >
                        <Checkbox
                            style={{borderColor:borderTheme}}
                            className="border-[1px] rounded px-1"
                            checked={checkedBox}
                            indeterminate={indeterminate}
                            onChange={(checkValue: any) => {
                                onChange(checkValue);
                            }}
                        />
                        <Tooltip
                            target="#status"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p className="">Show Status</p>
                        </Tooltip>
                    </li>

                    <li onClick={() => {
                        handleRefresh && handleRefresh();
                        searchTextOnchange && searchTextOnchange('');
                        searchCriteriaOnchange &&  searchCriteriaOnchange(searchCriteriaData![0]?.id ?? 0,searchCriteriaData![0]?.value ?? '');
                        checkOnChange && checkOnChange('');
                        setSearchTextValue('');

                        setCheckedBox(false);
                        setIndeterminate(true);
                        setCheckValue('');
                        setCriterialDataLocal({id:searchCriteriaData![0]?.id ?? 0,value:searchCriteriaData![0]?.value ?? ''});

                    }}
                        id='refresh'
                        style={{borderColor:borderTheme}}
                        className="hover:cursor-pointer mr-1 border-slate-200 text-xs border-[1px] p-1 text-gray-600 rounded flex items-center">
                        <span> <img className="w-4  dark:bg-slate-900 dark:text-gray-100 duration-100" src={refresh} /> </span>
                        <Tooltip
                            target="#refresh"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p className="">Refresh</p>
                        </Tooltip>
                    </li>
                    <li id="find" onClick={() => { if (handleFind) { handleFind() } else { } }} style={{ borderWidth: '1px',borderColor:borderTheme }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1 text-gray-600 rounded flex items-center">
                        <span> <FaBinoculars size={15.5} color="#007AFF" /> </span>
                        <Tooltip
                            target="#find"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p>Find</p>
                        </Tooltip>
                    </li>
                    <li id="print" onClick={() => handlePrint && handlePrint()} style={{ borderWidth: '1px',borderColor:borderTheme }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1 text-gray-600 rounded flex items-center">
                        <span> <img className="dark:bg-slate-900 dark:text-gray-100 duration-100" src={printer} /> </span>
                        <Tooltip
                            target="#print"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p>Print / Export</p>
                        </Tooltip>
                    </li>

                    <li className="hover:cursor-pointer ml-20  px-2  flex items-center">
                        {disablex && <p className=" italic font-semibold text-gray-600">Done.</p>}

                        {!disablex && <div className="  flex flex-row items-center">
                            <img style={{ width: '31px', height: '31px' }} src={statusLoader} alt="gif" />
                            {!updateMode ?
                                <p className=" italic text-gray-600">New Record</p>
                                :
                                <p className=" italic text-gray-600">Editing</p>}
                        </div>}
                    </li>


                </ul>

            </div>


        </div>
    )
};

const data = [


    { id: 1, label: 'Initiative', value: 'Initiative' },
    { id: 2, label: 'Objective', value: 'Objective' },
    { id: 3, label: 'Theme', value: 'Theme' },
    { id: 4, label: "Mission\\Vision", value: "Mission\\Vision" },



]

// const data = [
//     { id: 1, text: "Task",  },
//     { id: 2, text: "Progress Activity",  },
//     { id: 3, text: "Task & Start Date",  },
//     { id: 4, text: "Task & Completion Date",  },
//     { id: 5, text: "Task & Due Date", },
//     { id: 6, text: "Progress Activity & Start Date", },
//     { id: 7, text: "Progress Activity & Completion Date",  },
//     { id: 8, text: "Progress Activity & Due Date", },
//     { id: 9, text: "Status", },
//     { id: 10, text: "Status & Start Date", },
//     { id: 11, text: "Status & Completion Date", },
//     { id: 12, text: "Status & Due Date", },
//     { id: 13, text: "Priority", },
//     { id: 14, text: "Priority & Start Date", },
//     { id: 15, text: "Priority & Completion Date", },
//     { id: 16, text: "Priority & Due Date", },
//     { id: 17, text: "Assigned By", },
//     { id: 18, text: "Assigned By & Start Date", },
//     { id: 19, text: "Assigned By & Completion Date", },
//     { id: 20, text: "Assigned By & Due Date", },
//     { id: 21, text: "Assigned To", },
//     { id: 22, text: "Assignred To & Start Date", },
//     { id: 23, text: "Assigned To & Completion Date", },
//     { id: 24, text: "Assigned To & Due Date", },
// ];