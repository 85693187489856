/* eslint-disable react/jsx-pascal-case */

import { TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/accordion";
import { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import useFetch from "../../../../../../../hooks/useFetch";
import { navbar_height } from "../../../../../../accessories/component_infos";
import { SelectsTemplate } from "../../../../../../../templates/select";
import { DateTemplate } from "../../../../../../../templates/date";
import { Form, Input } from "antd";

import { DesignTab } from "./tabs/Design";
import {
  DeptAmountURL_cols,
  DeptAmount_CountURL_cols,
  DeptQuantityURL_cols,
  DeptQuantity_CountURL_cols,
  DeptAmountQuantityURL_cols,
  secAmountURL_cols,
  secAmount_CountURL_cols,
  secQuantityURL_cols,
  secQuantity_CountURL_cols,
  secAmountQuantity_CountURL_cols,
  vehFuelAmountURL_cols,
  vehClassAmountURL_cols,
  vehClassAmount_CountURL_cols,
  vehClassQuantity_CountURL_cols,
  vehClassAmountQuantityURL_cols,
} from "../data/datagrid_cols";
import { Ans_Footer_datagrid_template } from "../data/footer_datagrid_ana";
import { Chart_template } from "../../../../../../../templates/Chart";
import { RootState } from "../../../../../../../app/store";
import { WarningAlert } from "../../../../../../accessories/warningAlert";
import dayjs from "dayjs";

interface props {
  state_change: boolean;
  windowHeight: any;
}

export type ChartType =
  | "area"
  | "bar"
  | "bubble"
  | "candlestick"
  | "fullstackedarea"
  | "fullstackedbar"
  | "fullstackedline"
  | "fullstackedspline"
  | "fullstackedsplinearea"
  | "line"
  | "rangearea"
  | "rangebar"
  | "scatter"
  | "spline"
  | "splinearea"
  | "stackedarea"
  | "stackedbar"
  | "stackedline"
  | "stackedspline"
  | "stackedsplinearea"
  | "steparea"
  | "stepline"
  | "stock";

interface statesTypes {
  startDate?: string;
  endDate?: string;
  lowerSelected?: any;
  upperSelected?: any;
  staffDC?: boolean;
  DPHC?: boolean;
  indeterminate?: any;
  indeterminate2?: any;
  criteria?: { id: number; name: string };
  chartType?: ChartType;
}

export const Transaction_Word = ({ state_change, windowHeight }: props) => {
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const formRef = useRef<any>(null);
  const [fullPage] = useState(
    window.innerHeight - (navbar_height + news_flash_height + 290)
  );
  const editedDate = dayjs().format("DD MMM YYYY");
  const startingDate = dayjs().startOf("month").format("DD MMM YYYY");

  const [states, setStates] = useState<statesTypes>({
    startDate: startingDate,
    endDate: editedDate,
    upperSelected: [],
    lowerSelected: {},
    staffDC: false,
    DPHC: false,
    indeterminate: true,
    indeterminate2: true,
    chartType: undefined,

    criteria: { id: 0, name: "" },
  });

  const updateState = (key: string, value: any) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };

  const [startDate, setStartDate] = useState<string>(startingDate);
  const [endDate, setEndDate] = useState<string>(editedDate);

  const [refresh] = useState<boolean>(false);

  const DeptAmountURL = useFetch(
    `FuelTransactionsCardDataUpload/GetDepartmentAmount?startdate=${startDate}&enddate=${endDate}`,
    refresh
  );
  const DeptAmount_CountURL = useFetch(
    `FuelTransactionsCardDataUpload/GetDepartmentAmountWithCount?startdate=${startDate}&enddate=${endDate}`,
    refresh
  );
  const DeptQuantityURL = useFetch(
    `FuelTransactionsCardDataUpload/GetDepartmentQuantity?startdate=${startDate}&enddate=${endDate}`,
    refresh
  );
  const DeptQuantity_CountURL = useFetch(
    `FuelTransactionsCardDataUpload/GetDepartmentQuantityWithCount?startdate=${startDate}&enddate=${endDate}`,
    refresh
  );
  const DeptAmountQuantityURL = useFetch(
    `FuelTransactionsCardDataUpload/GetDepartmentAmountQuantityWithCount?startdate=${startDate}&enddate=${endDate}`,
    refresh
  );
  const secAmountURL = useFetch(
    `FuelTransactionsCardDataUpload/GetSectionAmount?startdate=${startDate}&enddate=${endDate}`,
    refresh
  );
  const secAmount_CountURL = useFetch(
    `FuelTransactionsCardDataUpload/GetSectionAmountWithCount?startdate=${startDate}&enddate=${endDate}`,
    refresh
  );
  const secQuantityURL = useFetch(
    `FuelTransactionsCardDataUpload/GetSectionQuantity?startdate=${startDate}&enddate=${endDate}`
  );
  const secQuantity_CountURL = useFetch(
    `FuelTransactionsCardDataUpload/GetSectionQuantityWithCount?startdate=${startDate}&enddate=${endDate}`,
    refresh
  );
  const secAmountQuantity_CountURL = useFetch(
    `FuelTransactionsCardDataUpload/GetSectionAmountQuantityWithCount?startdate=${startDate}&enddate=${endDate}`,
    refresh
  );
  const vehFuelAmountURL = useFetch(
    `FuelTransactionsCardDataUpload/GetVehicleFuelAmount?startdate=${startDate}&enddate=${endDate}`,
    refresh
  );
  const vehClassAmountURL = useFetch(
    `FuelTransactionsCardDataUpload/GetVehicleClassAmount?startdate=${startDate}&enddate=${endDate}`,
    refresh
  );
  const vehClassAmount_CountURL = useFetch(
    `FuelTransactionsCardDataUpload/GetVehicleClassAmountWithCount?startdate=${startDate}&enddate=${endDate}`,
    refresh
  );
  const vehClassQuantity_CountURL = useFetch(
    `FuelTransactionsCardDataUpload/GetVehicleClassQuantityWithCount?startdate=${startDate}&enddate=${endDate}`,
    refresh
  );
  const vehClassAmountQuantityURL = useFetch(
    `FuelTransactionsCardDataUpload/GetVehicleClassAmountQuantity?startdate=${startDate}&enddate=${endDate}`,
    refresh
  );

  const [DBdata, DBerror] =
    states.criteria?.id === 0
      ? DeptAmountURL
      : states.criteria?.id === 1
      ? DeptAmount_CountURL
      : states.criteria?.id === 2
      ? DeptQuantityURL
      : states.criteria?.id === 3
      ? DeptQuantity_CountURL
      : states.criteria?.id === 4
      ? DeptAmountQuantityURL
      : states.criteria?.id === 5
      ? secAmountURL
      : states.criteria?.id === 6
      ? secAmount_CountURL
      : states.criteria?.id === 7
      ? secQuantityURL
      : states.criteria?.id === 8
      ? secQuantity_CountURL
      : states.criteria?.id === 9
      ? secAmountQuantity_CountURL
      : states.criteria?.id === 10
      ? vehFuelAmountURL
      : states.criteria?.id === 13
      ? vehClassAmountURL
      : states.criteria?.id === 14
      ? vehClassAmount_CountURL
      : states.criteria?.id === 16
      ? vehClassQuantity_CountURL
      : states.criteria?.id === 17
      ? vehClassAmountQuantityURL
      : DeptAmountURL;

  const chartXAxis =
    DeptAmountURL_cols[0].caption === "DEPT"
      ? "deptShtNamex"
      : DeptAmount_CountURL_cols[0].caption === "DEPT"
      ? "deptShtNamex"
      : DeptQuantityURL_cols[0].caption === "DEPT"
      ? "deptShtNamex"
      : DeptQuantity_CountURL_cols[0].caption === "DEPT"
      ? "deptShtNamex"
      : DeptAmountQuantityURL_cols[0].caption === "DEPT"
      ? "deptShtNamex"
      : secAmountURL_cols[0].caption === "DEPT"
      ? "deptShtNamex"
      : secAmount_CountURL_cols[0].caption === "DEPT"
      ? "deptShtNamex"
      : secQuantityURL_cols[0].caption === "SECTION"
      ? "sxnShtName"
      : secQuantity_CountURL_cols[0].caption === "DEPT"
      ? "deptShtNamex"
      : secAmountQuantity_CountURL_cols[0].caption === "DEPT"
      ? "deptShtNamex"
      : vehFuelAmountURL_cols[0].caption === "CLASS"
      ? "classx"
      : vehClassAmountURL_cols[0].caption === "CLASS"
      ? "classx"
      : vehClassAmount_CountURL_cols[0].caption === "CLASS"
      ? "classx"
      : vehClassQuantity_CountURL_cols[0].caption === "CLASS"
      ? "classx"
      : vehClassAmountQuantityURL_cols[0].caption === "CLASS"
      ? "classx"
      : DeptAmountURL_cols[0].caption === "DEPT"
      ? "deptShtNamex"
      : "deptShtNamex";

  const DBColumns =
    states.criteria?.id === 0
      ? DeptAmountURL_cols
      : states.criteria?.id === 1
      ? DeptAmount_CountURL_cols
      : states.criteria?.id === 2
      ? DeptQuantityURL_cols
      : states.criteria?.id === 3
      ? DeptQuantity_CountURL_cols
      : states.criteria?.id === 4
      ? DeptAmountQuantityURL_cols
      : states.criteria?.id === 5
      ? secAmountURL_cols
      : states.criteria?.id === 6
      ? secAmount_CountURL_cols
      : states.criteria?.id === 7
      ? secQuantityURL_cols
      : states.criteria?.id === 8
      ? secQuantity_CountURL_cols
      : states.criteria?.id === 9
      ? secAmountQuantity_CountURL_cols
      : states.criteria?.id === 10
      ? vehFuelAmountURL_cols
      : states.criteria?.id === 13
      ? vehClassAmountURL_cols
      : states.criteria?.id === 14
      ? vehClassAmount_CountURL_cols
      : states.criteria?.id === 16
      ? vehClassQuantity_CountURL_cols
      : states.criteria?.id === 17
      ? vehClassAmountQuantityURL_cols
      : DeptAmountURL_cols;

  const [inputValue, setInputValue] = useState<string>("");
  const [inputValues, setInputValues] = useState<string>("");
  const [inputStyle, setInputStyle] = useState<number>(19);
  const [inputStyles, setInputStyles] = useState<number>(12);

  useEffect(() => {}, []);
  return (
    <>
      {DBerror && <WarningAlert />}
      <div
        style={{ height: "100%" }}
        className=" dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base "
      >
        <div style={{ height: "" }} className="flex h-full ">
          <div
            style={{ width: "calc(100% - 80%)" }}
            className="h-full px-2 mb-1"
          >
            <Form
              className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 px-2 "
              name="basic"
              labelCol={{ span: 4 }}
              initialValues={{ remember: true }}
              autoComplete="off"
              size="small"
              layout="horizontal"
            >
              <SelectsTemplate
                disabled={false}
                selectedValue={(e) => {
                  setInputValue(e.label);
                  updateState("criteria", { id: e.id, name: e.label });
                }}
                useCallFunc
                placeHolder={criteriaData[0].label}
                label="Criteria"
                options={criteriaData}
                idexpr="id"
                dataexp="label"
              />

              <Form.Item
                label={
                  <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                    {"Dates "}
                  </p>
                }
              >
                <div className="flex ">
                  <DateTemplate
                    span
                    disabled={false}
                    changeDate={(e: any) => {
                      setStartDate(dayjs(e).format("DD MMM YYYY"));
                    }}
                  />
                  <span className="mr-1"></span>
                  <DateTemplate
                    span
                    disabled={false}
                    changeDate={(e: any) => {
                      setEndDate(dayjs(e).format("DD MMM YYYY"));
                    }}
                  />
                </div>
              </Form.Item>

              <Form.Item
                label={
                  <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                    {"Header "}
                  </p>
                }
              >
                <div className="flex w-full">
                  <Input
                    className="mr-1 w-4/5"
                    value={inputValue}
                    onChange={(e) => {
                      setInputValue(e.target.value);
                    }}
                  />
                  <Input
                    className="w-1/5"
                    value={inputStyle}
                    onChange={(e) => {
                      setInputStyle(Number(e.target.value));
                    }}
                  />
                </div>
              </Form.Item>

              <Form.Item
                label={
                  <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                    {"Footer "}
                  </p>
                }
              >
                <div className="flex">
                  <Input
                    className="mr-1 w-4/5"
                    value={inputValues}
                    onChange={(e) => {
                      setInputValues(e.target.value);
                    }}
                  />
                  <Input
                    className="w-1/5"
                    value={inputStyles}
                    onChange={(e) => {
                      setInputStyles(Number(e.target.value));
                    }}
                  />
                </div>
              </Form.Item>
            </Form>

            <Ans_Footer_datagrid_template
              gridheight={fullPage + 130}
              columns={
                states.criteria?.id === 0
                  ? DeptAmountURL_cols
                  : states.criteria?.id === 1
                  ? DeptAmount_CountURL_cols
                  : states.criteria?.id === 2
                  ? DeptQuantityURL_cols
                  : states.criteria?.id === 3
                  ? DeptQuantity_CountURL_cols
                  : states.criteria?.id === 4
                  ? DeptAmountQuantityURL_cols
                  : states.criteria?.id === 5
                  ? secAmountURL_cols
                  : states.criteria?.id === 6
                  ? secAmount_CountURL_cols
                  : states.criteria?.id === 7
                  ? secQuantityURL_cols
                  : states.criteria?.id === 8
                  ? secQuantity_CountURL_cols
                  : states.criteria?.id === 9
                  ? secAmountQuantity_CountURL_cols
                  : states.criteria?.id === 10
                  ? vehFuelAmountURL_cols
                  : states.criteria?.id === 13
                  ? vehClassAmountURL_cols
                  : states.criteria?.id === 14
                  ? vehClassAmount_CountURL_cols
                  : states.criteria?.id === 16
                  ? vehClassQuantity_CountURL_cols
                  : states.criteria?.id === 17
                  ? vehClassAmountQuantityURL_cols
                  : DeptAmountURL_cols
              }
              data={DBdata}
              isSearch={false}
            />
          </div>

          <div style={{ width: "calc(100% - 20%)" }}>
            <div ref={formRef} className="">
              <p className="py-4 mx-2 pl-4 w-full bg-slate-100 rounded-tl">
                Chart Tools
              </p>
              <div
                style={{ height: "140px" }}
                className="w-full px-2 flex justify-between gap-x-4  mb-1 pb-1"
              >
                <div
                  style={{ width: "100%", height: "100px" }}
                  className="mb-1"
                >
                  <div style={{ height: "" }} className="w-full">
                    <TabPanel style={{ height: "" }} className="w-full ">
                      <Item title={"dropdown"}></Item>

                      <Item title={"Design"}>
                        <DesignTab updateStates={updateState} />
                      </Item>

                      <Item title={"Other"}></Item>
                    </TabPanel>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                height: windowHeight ? windowHeight - 56 : fullPage,
                width: "100%",
              }}
              className="px-2  dark:bg-slate-900 dark:text-darkModeSkin-base mb-10"
            >
              <div
                style={{
                  height: windowHeight ? windowHeight - 60 : fullPage,
                  width: "100%",
                }}
                className="h-full "
              >
                <h4
                  className="w-full flex mb-5 justify-center itenms-center"
                  style={{ fontSize: inputStyle }}
                >
                  {" "}
                  {inputValue} - ( {startDate} - {endDate})
                </h4>
                <div style={{ height: "", width: "95%" }} className=" ">
                  <Chart_template
                    chartheight={fullPage}
                    columns={DBColumns}
                    data={DBdata}
                    XAxis_cols={chartXAxis}
                    chartType={states?.chartType as ChartType}
                    YAxis_label={inputValue}
                    paletteColor={"Soft Blue"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const criteriaData = [
  { id: 0, label: "Dept Fuel Amounts (GHC)" },
  { id: 1, label: "Dept Fuel Amounts (GHC), with Count " },
  { id: 2, label: "Dept Fuel Quantity (Ltrs)" },
  { id: 3, label: "Dept Fuel Quantity (Ltrs), with Count " },
  { id: 4, label: "Dept Fuel Amount & Quantity " },
  { id: 5, label: "Section Fuel Amounts (GHC)" },
  { id: 6, label: "Section Fuel Amounts (GHC), with Count " },
  { id: 7, label: "Section Fuel Quantity (Ltrs)" },
  { id: 8, label: "Section Fuel Quantity (Ltrs), with Count " },
  { id: 9, label: "Section Fuel Amount & Quantity " },
  { id: 10, label: "Vehicle Fuel Amounts (GHC)" },
  { id: 11, label: "Vehicle Fuel Quantity (Ltrs) " },
  { id: 12, label: "Vehicle Fuel Amount & Quantity " },
  { id: 13, label: "Vehicle Class Amounts (GHC)" },
  { id: 14, label: "Vehicle Class Amount (GHC), with Count" },
  { id: 15, label: "Vehicle Class Quantity (Ltrs) " },
  { id: 16, label: "Vehicle Class Quantity (Ltrs), with Count" },
  { id: 17, label: "Vehicle Class Amount & Quantity" },
  { id: 18, label: "Vehicle Make Amount (GHC)" },
  { id: 19, label: "Vehicle Make Quantity (Ltrs) " },
  { id: 20, label: "Vehicle Make Amount & Quantity" },
  { id: 21, label: "Vehicle Model Amount (GHC)" },
  { id: 22, label: "Vehicle Model Quantity (Ltrs)" },
  { id: 23, label: "Vehicle Model Amount & Quantity" },
  { id: 24, label: "All Vehicle Fuel Amount (GHC)" },
  { id: 25, label: "All Vehicle Fuel Quantity (Ltrs)" },
  { id: 26, label: "All Vehicle Fuel Amount & Quantity" },
  { id: 27, label: "Number of Transactions (Depts)" },
  { id: 28, label: "Number of Transactions (Sections)" },
  { id: 29, label: "Number of Transactions (Vehicles)" },
  { id: 30, label: "Number of Transactions (Drivers)" },
  { id: 31, label: "Department By Month" },
  { id: 32, label: "Section By Month" },
  { id: 33, label: "Vehicle By Month" },
  { id: 34, label: "Department By Quarter" },
  { id: 35, label: "Section By Quarter" },
  { id: 36, label: "Vehicle By Quarter" },
  { id: 37, label: "Top N Percent " },
  { id: 38, label: "Bottom N Percent " },
];
