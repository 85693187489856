import React, { useState } from 'react'
import { HrSetupsCustomToolbar } from '../accessories/custom-toolbars/hrSetupsCustomtoolbar'
import { TabPanel } from 'devextreme-react'
import EDM from './widgets/EDM'
import { CustomizableButton } from '../accessories/buttons'
import user from "../../assets/user.png"
import switchChart from "../../assets/switch-chart.png"
import { RefreshButtonTemplate } from '../../templates/RefreshButtonTemplate'
import { columnsForEmployees } from './data/datagrid-cols'
import useFetch from '../../hooks/useFetch'
import { GetGroupDataInterface } from '../../interfaces'
import { Item } from 'devextreme-react/tab-panel'
import { UpdateStates } from '../../functions/updateStates'
import TelephoneCardView from './widgets/CardView'
import Datagrid_template from "../../templates/Datagrid"
import useResizeMode from '../../hooks/useResizeMode'

interface stateTypes {
    emplpyeeStates: boolean,
    directorStates: boolean,
    managerStates: boolean,
    changeView: boolean,
}


const TelephoneDir = () => {

    const [initial, final] = useResizeMode(window.innerHeight);
    const gridheight = initial - 220;

    const [Mutate, setMutate] = useState<stateTypes>({
        emplpyeeStates: false,
        directorStates: false,
        managerStates: false,
        changeView: false,
    });

    const { emplpyeeStates, directorStates, managerStates, changeView } = Mutate

    console.table(Mutate)


    const [employees, getError, getLoadingState]: [GetGroupDataInterface[], any, boolean] = useFetch(`TelephoneDir/DirectoryGroups/GetGroupData?groupName=employees`, emplpyeeStates, "")
    const [directors, getError1, getLoadingState1]: [GetGroupDataInterface[], any, boolean] = useFetch(`TelephoneDir/DirectoryGroups/GetGroupData?groupName=directors`, directorStates, "")
    const [managers, getError2, getLoadingState2]: [GetGroupDataInterface[], any, boolean] = useFetch(`TelephoneDir/DirectoryGroups/GetGroupData?groupName=managers`, managerStates, "")


    const E_Form = () => {

        return (
            <div className="w-full h-full ">
                {changeView ?
                    <section className="w-full flex justity-between">
                        <aside className="w-1/2 ">
                            <TelephoneCardView dataGrid={employees} />
                        </aside>

                        <aside className="w-1/2 ">
                            <Datagrid_template
                                columns={columnsForEmployees}
                                data={employees} 
                                gridheight={gridheight}                            />
                        </aside>
                    </section>
                    :
                    <section className="w-full">
                        <EDM revalidate={setMutate} columns={columnsForEmployees} gridData={employees} />
                    </section>


                }

            </div>
        )
    }
    const D_Form = () => {

        return (
            <div className="w-full h-full ">
                {changeView ?
                    <section className="w-full flex justity-between">
                        <aside className="w-1/2 ">
                            <TelephoneCardView dataGrid={directors} />
                        </aside>

                        <aside className="w-1/2 ">
                            <Datagrid_template
                                columns={columnsForEmployees}
                                data={directors} 
                                gridheight={gridheight}                            />
                        </aside>
                    </section>
                    :
                    <section className="w-full">
                        <EDM revalidate={setMutate} columns={columnsForEmployees} gridData={directors} />
                    </section>


                }

            </div>
        )
    }
    const M_Form = () => {

        return (
            <div className="w-full h-full ">
                {changeView ?
                    <section className="w-full flex justity-between">
                        <aside className="w-1/2 bg-slate-50 ">
                            <TelephoneCardView dataGrid={managers} />
                        </aside>

                        <aside className="w-1/2 ">
                            <Datagrid_template
                                columns={columnsForEmployees}
                                data={managers} 
                                gridheight={gridheight}                            />
                        </aside>
                    </section>
                    :
                    <section className="w-full">
                        <EDM revalidate={setMutate} columns={columnsForEmployees} gridData={managers} />
                    </section>


                }

            </div>
        )
    }


    const tabs: { id: number, title: string, contents: any }[] = [
        { id: 0, title: "Employees", contents: <E_Form /> },
        { id: 1, title: "Directors", contents: <D_Form/> },
        { id: 2, title: "Managers", contents: <M_Form/> },

    ]



    return (
        <div className='w-full h-full'>
            {/* Toolbar */}
            <div className='w-full px-2'>
                <HrSetupsCustomToolbar
                    disableNew_edit
                    withDates={false}
                    searchCriteria={false}
                    checkBoxAfterHelp={false}
                    useCallBackFunc
                    isSwitch={false}
                    isSearch={false}
                    isAFRP={false}
                    extraWidgetsAfterSwitch={
                        <div className="flex pr-1 items-center">
                            <li className=""><CustomizableButton handleOnClick={undefined} buttonID="diplay-my-record" isTooltip tooltipMsg={"Display my record"} classname={"p-0.5"} customizableBtnImage={user} /></li>

                            <li className="pl-1"> <CustomizableButton buttonID="ChangeView" isTooltip tooltipMsg={"Change View"} classname={"p-0.5"} customizableBtnImage={switchChart} handleCustomizableClick={() => { UpdateStates("changeView", !changeView, setMutate) }} /></li>
                            <li className="pl-1"> <RefreshButtonTemplate handleRefresh={() => { }} /></li>

                        </div>
                    }
                />
            </div>
            {/* Tbas */}
            <div className="w-full px-0.5">
                <TabPanel showNavButtons={true} className="w-full h-full  ">
                    {tabs.map(({ id, title, contents }) => {
                        return (
                            <Item key={id} title={title}>
                                <div className='w-full h-full  pt-1'>
                                    {contents}
                                </div>

                            </Item>
                        )
                    })}


                </TabPanel>
            </div>

        </div>
    )
}

export default TelephoneDir