/* eslint-disable react/jsx-pascal-case */
import React, {
  useState,
  useEffect,
  useCallback,
  MouseEventHandler,
} from "react";
import { Form, Input, DatePicker, Checkbox, Upload, UploadFile } from "antd";
import {
  SaveButton,
  NewButton,
  Cancel,
  UpdateButton,
} from "../../../../../../accessories/buttons";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import {
  saveModalData,
  updateModalData,
} from "../../../../../../accessories/component_infos";
import dayjs from "dayjs";
import { ModalTemplate } from "../../../../../../../templates/modal";
import useFetch from "../../../../../../../hooks/useFetch";
import {
  setGeneralValue,
  settask_footer_table_selected,
  settask_form_disable,
} from "../../../../../../../features/generalSlice";
import { useDispatch } from "react-redux";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import TextArea from "antd/es/input/TextArea";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import { task_form_disable } from "../../../../../../../features/Task&Todo/Task/TaskDetails";
import { useBusyLoader } from "../../../../../../../hooks/useBusyLoader";
import { SelectsTemplate } from "../../../../../../../templates/select";
import { useCrudFunc } from "../../../../../../../functions/crud";
import InputSelect_template from "../../../../../../../templates/InputSelect";
import { debounce } from "lodash";
import { fetchDocType } from "../../../../../../../features/apiCalls/transDoc";
import newIcon from "../../../../../../../assets/new.png";

interface props {
  parentstates?: any;
  parentSetstate: (key: string, value: any) => void;
  setDocs: (e: any) => void;
  setActiveDoc: (e: any) => void;
  docs: any;
  sharedEmps: any;
  setSharedEmps: (e: any) => void;
  defaultDoc: any;
  fetchLowerGridData: (e: number) => void;
}

interface statesTypes {
  docType?: { id: number; name: string };
  date?: string;
  order?: string;
  title?: string;
  description?: string;
  remarks?: string;
  classified?: boolean;
  verified?: boolean;
  docTypeRefresh: boolean;
  isDropdownKey?: any;
  isOpenDropdown?: boolean;
  isLoading?: boolean;
}

export const Document_Details = ({
  parentstates,
  parentSetstate,
  setDocs,
  setActiveDoc,
  docs,
  sharedEmps,
  setSharedEmps,
  defaultDoc,
  fetchLowerGridData,
}: props) => {
  const [employeeID, userID, user] = useAccountInfo();
  // import { AssignedDept, AssignedLocation, AssignedSelection, AssignedUnit, Batch, Group, PPE_Select, RequestWindow, StaffName } from './PPE_selects';
  // const form_disable = useSelector(
  //   (state: RootState) => state.general.TransformDisabled
  // );
  const form_access_disable = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  // const updateMode = useSelector(
  //   (state: RootState) => state.general.TransupdateMode
  // );
  const updateMode = useSelector(
    (state: RootState) => state.general.updateMode
  );
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  //  Current date
  const editedDate = dayjs().format();
  const [busyLoader, setBusyLoader] = useBusyLoader();

  const [states, setStates] = useState<statesTypes>({
    date: editedDate,
    order: "1",
    description: parentstates?.lowerSelected?.eddDescription || "",
    remarks: parentstates?.lowerSelected?.eddRmks || "",
    classified: false,
    verified: false,
    docTypeRefresh: false,
    isDropdownKey: "",
    isOpenDropdown: false,
    isLoading: false,
  });

  const [PostDataFunc, UpdateDataFunc] = useCrudFunc();
  const fileRef = React.useRef<HTMLInputElement>();

  const updateState = (key: string, value: any) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };

  const dateFormat = "DD MMM YYYY HH: mm";
  const dispatch = useDispatch();

  // Endpoints
  const [DocType_data, DocType_error, DocType_loading] = useFetch(
    "AmsTmsEntTransportDocuments/GetDocumentTypes",
    states.docTypeRefresh
  );

  // Modal dialog data states

  const [refreshValue, setRefreshValue] = useState(false);
  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [showCancel, setshowCancel] = useState(false);
  const [document, setDocument] = useState<any>();
  const [documentList, setDocumentList] = useState<UploadFile[]>([]);
  const [privType, setPrivType] = useState("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);
  const saveAccessPriv = () => {
    setPrivType("save");
    if (savePriv === true) {
      setModalData({
        message: "Are you sure save this document?",
        title: "Save Document?",
        okText: "Yes, save",
      });
      validationForms();
    } else {
      setIcon("warning");
      setModalData({ message: "No Access Privilege", title: "Access Denied!" });
      setshowCancel(false);
      setOpenModal(true);
    }
  };

  const newAccessPriv = () => {
    setPrivType("save");
    if (savePriv === true) {
      setSharedEmps([]);
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      clearFields(true);
    } else {
      setDocs([defaultDoc]);
      setDocument("");
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      setIcon("warning");
      setOpenModal(true);
      setshowCancel(false);
      setModalData({ message: "No Access Privilege", title: "Access Denied!" });
    }
  };

  const updateAccessPriv = () => {
    setPrivType("update");
    if (updatePriv === true) {
      setOpenModal(true);
      validationForms();
      setModalData({
        message: "Are you sure save this document?",
        title: "Update Document?",
        okText: "Yes, save",
      });
    } else {
      setIcon("warning");
      setOpenModal(true);
      setshowCancel(false);
      setModalData({ message: "No Access Privilege", title: "Access Denied!" });
    }
  };

  //  Clear Feilds

  const clearFields = (clear?: boolean) => {
    if (clear === true) {
      dispatch(settask_footer_table_selected([]));
    }

    const gridState = [
      {
        key: "docType",
        value: clear
          ? ""
          : parentstates.lowerSelected
          ? {
              id: parentstates.lowerSelected.dtpIDpk,
              name: parentstates.lowerSelected.dtpName,
            }
          : "",
      },
      {
        key: "date",
        value: clear
          ? editedDate
          : parentstates.lowerSelected
          ? parentstates.lowerSelected.eddDocDate
          : "",
      },
      {
        key: "title",
        value: clear
          ? ""
          : parentstates.lowerSelected
          ? parentstates.lowerSelected.eddTitle
          : "",
      },
      {
        key: "description",
        value: clear
          ? ""
          : parentstates.lowerSelected
          ? parentstates.lowerSelected.eddDescription
          : "",
      },
      {
        key: "remarks",
        value: clear
          ? ""
          : parentstates.lowerSelected
          ? parentstates.lowerSelected.eddRmks
          : "",
      },
      {
        key: "classified",
        value: clear
          ? false
          : parentstates.lowerSelected
          ? parentstates.lowerSelected.eddClassified
          : false,
      },
      {
        key: "verified",
        value: clear
          ? false
          : parentstates.lowerSelected
          ? parentstates.lowerSelected.eddVerified
          : false,
      },
    ];
    for (let instance of gridState) {
      parentSetstate(instance.key, instance.value);
      updateState(instance.key, instance.value);
    }
  };
  // Validation

  const validationForms = async () => {
    setIcon("warning");
    if (
      parentstates.upperSelected === undefined ||
      Object.keys(parentstates.upperSelected).length === 0
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select an employee in the upper grid",
        title: "Invalid Entry! ",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      parentstates.docType?.name === "" ||
      parentstates.docType?.name === undefined
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select data entry for DocType",
        title: "Invalid Entry! ",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      states.date === "" ||
      states.date === undefined ||
      states.date === editedDate
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select data entry for date.",
        title: "Date Expired! ",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (parentstates.title === "" || parentstates.title === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select data entry for title.",
        title: "Invalid Entry! ",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.description === "" || states.description === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select data entry for Description.",
        title: "Invalid Entry! ",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (docs.length < 1) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select a document to submit.",
        title: "Invalid Entry! ",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (sharedEmps.length === 0) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select personels from the share panel to share with.",
        title: "Required!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else {
      setOpenModal(true);
      setIcon("question");
      setOpenModal(true);
    }
  };

  const saveShareWith = async (emp: any, docDetailId: number) => {
    if (emp.swtOwn || emp.swtPrint || emp.swtRead) {
      try {
        const res = await PostDataFunc(
          `AmsTmsEntTransportDocuments/CreateShareAsmTmsEntTransportDocument`,
          {
            swtDocDetailIdfk: docDetailId,
            swtEmpIdfk: emp.empIDpk,
            swtOwn: emp.swtOwn,
            swtRead: emp.swtRead,
            swtPrint: emp.swtPrint,
            swtActive: true,
            swtType: "TMS-transport docs",
            swtCreatedBy: userID,
          },
          `Shared the Asset Mgr-TMS-Transport Doc. Details record with Staff ${emp.empName} whose Emp ID is ${emp.empIdpk} with privileges Own-${emp.swtOwn}, Read-${emp.swtRead} and Print-${emp.swtPrint}`
        );
        if (res.status !== 202) {
          throw Error(res.statusText);
        }
      } catch (e: any) {
        setshowModalDisableButton(true);
        setModalData({
          message: "Something went wrong. Please try again.",
          title: "Invalid Entry! ",
          okText: "Ok",
        });
        setOpenModal(true);
        return false;
      }
    }
  };

  const createShareWith = async (docDetailId: number) => {
    if (sharedEmps.length > 0) {
      for (const emp of sharedEmps) {
        await saveShareWith(emp, docDetailId);
      }
      return true;
    }
  };
  const updateShareWith = async (emp: any) => {
    try {
      const res = await UpdateDataFunc(
        `AmsTmsEntTransportDocuments/UpdateShareAsmTmsEntTransportDocument`,
        {
          swtIdpk: emp.swtIdpk,
          swtOwn: emp.swtOwn,
          swtRead: emp.swtRead,
          swtPrint: emp.swtPrint,
          swtActive: true,
          swtEditedBy: userID,
          swtEditedDate: editedDate,
        },
        `Updated the Asset Mgr-TMS-Transport Doc. Details record shared with Staff ${emp.empName} whose Emp ID is ${emp.empIdpk} with privileges Own-${emp.swtOwn}, Read-${emp.swtRead} and Print-${emp.swtPrint}`
      );
      if (res.status !== 204) {
        throw Error(res.statusText);
      }
    } catch (e: any) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Something went wrong. Please try again.",
        title: "Invalid Entry! ",
        okText: "Ok",
      });
      setOpenModal(true);
      return false;
    }
  };
  const editShareWith = async () => {
    if (sharedEmps.length > 0) {
      for (const emp of sharedEmps) {
        if (Object.hasOwn(emp, "swtIdpk")) {
          await updateShareWith(emp);
        } else {
          await saveShareWith(emp, parentstates.lowerSelected.eddIDpk);
        }
      }
      return true;
    }
  };
  const saveDocumentHandler = async (mailId: number) => {
    const doc = documentList[0];
    const fileString = doc.name.split(".");
    const type = doc.type;
    const filename = fileString[0];
    const format = fileString[1];
    try {
      const res = await PostDataFunc(
        "AmsTmsEntTransportDocuments/SaveDocument",
        {
          atdMailIdfk: mailId,
          atdDoc: document,
          atdFileName: filename,
          atdFileNo: "",
          atdAttachmentTypeIdfk: 200,
          atdSubject: parentstates.title,
          atdFormat: format,
          atdAttachedFrom: "tms-transport docs",
          atdMainAttachment: true,
          atdCreatedBy: userID,
          atdCreationDate: dayjs().format(),
          atdType: type,
        },
        ""
      );
      if (res.status !== 202) {
        throw Error(res.statusText);
      }
      return true;
    } catch (e: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setshowCancel(false);
      setModalData({ title: "Server Error", message: e.message, okText: "Ok" });
    }
  };
  const postData = async () => {
    setBusyLoader("Creating Document...");
    try {
      const res = await PostDataFunc(
        "AmsTmsEntTransportDocuments/CreateAsmTmEntTransportDocument",
        {
          eddEmpIdfk: parentstates.upperSelected.empIDpk,
          eddTitle: parentstates.title,
          eddDescription: states.description,
          eddDocDate: states.date,
          eddDocTypeIdfk: parentstates.docType?.id,
          eddActive: true,
          eddClassified: states.classified,
          eddVerified: states.verified,
          eddRmks: states.remarks,
          eddCreaeddBy: userID,
        },
        `Created the Asset Mgr-TMS-Transport Doc. Details record`
      );
      if (res.data.isSuccess === true) {
        const saveRes = await saveDocumentHandler(res.data.id);
        const shareRes = await createShareWith(res.data.id);
        if (saveRes || shareRes) {
          fetchLowerGridData(parentstates.upperSelected.empIDpk);
          setIcon("success");
          dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
          setshowModalDisableButton(false);
          setshowCancel(false);
          setModalData({
            message: "Document saved successfully!",
            title: "Document saved!",
            okText: "Ok",
          });
          setOpenModal(true);
        } else {
          throw Error("Something went wrong. Try again later.");
        }
      } else {
        throw Error(res.statusText);
      }
    } catch (error: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setshowCancel(false);
      setModalData({
        message: error ? error.message : "Server Error",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
    setBusyLoader("");
  };
  const updateData = async () => {
    setBusyLoader("Updating Document...");
    try {
      const res = await UpdateDataFunc(
        "AmsTmsEntTransportDocuments/UpdateAsmTmEntTransportDocument",
        {
          eddIdpk: parentstates.lowerSelected.eddIDpk,
          eddEmpIdfk: parentstates.upperSelected.empIDpk,
          eddTitle: parentstates.title,
          eddDescription: states.description,
          eddDocDate: states.date,
          eddDocTypeIdfk: parentstates.docType?.id,
          eddClassified: states.classified,
          eddVerified: states.verified,
          eddRmks: states.remarks,
          eddEditedBy: userID,
          eddEditeddate: editedDate,
        },
        `Edited the Asset Mgr-TMS-Transport Doc. Details record`
      );

      if (res?.data?.isSuccess) {
        const editRes = await editShareWith();
        if (editRes) {
          fetchLowerGridData(parentstates.upperSelected.empIDpk);
          setRefreshValue(!refreshValue);
          parentSetstate !== undefined &&
            parentSetstate("refresh", refreshValue);
          setIcon("success");
          setOpenModal(true);
          dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
          setshowModalDisableButton(false);
          setModalData({
            message: "Document updated successfully!",
            title: "Document pdated!",
            okText: "Ok",
          });
        }
      }
      // else {
      //   throw Error(res.statusText);
      // }
    } catch (error: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setshowCancel(false);
      setModalData({
        message: error["message"],
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
    setBusyLoader("");
  };

  const fetchDoc: any = fetchDocType;

  useEffect(() => {
    clearFields(false);
  }, [parentstates.lowerSelected]);

  return (
    <div
      style={{
        width: "100%",
        minWidth: "100%",
        maxWidth: "100%",
        height: "100%",
        borderColor: borderTheme,
      }}
      className=" border-[1px] border-t-[0px] px-2  dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base"
    >
      <ModalTemplate
        icon={icon}
        cancelText={modalData.cancelText}
        disableCancel={icon === "question" ? false : true}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        open={openModal}
        okHandler={() => {
          modalData.title === "Save Document?"
            ? postData()
            : modalData.title === "Update Document?"
            ? updateData()
            : setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
      />
      <div
        style={{ maxHeight: "180px", minHeight: "180px", height: "180px" }}
        className=" items-start justify-start "
      >
        <Form
          className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 px-2 "
          name="basic"
          labelCol={{ span: 3 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          size="small"
          layout="horizontal"
        >
          {/* Doc Type */}

          <Form.Item
            label={
              <p className=" dark:bg-slate-900 dark:text-darkModeSkin-base text-xs ">
                {"Doc. Type "}
              </p>
            }
          >
            <div className="w-full flex flex-row justify-between">
              <InputSelect_template
                placeHolder={parentstates.docType?.name}
                handleRefresh={() => {
                  updateState("docType", "");
                  updateState("docTypeRefresh", !states.docTypeRefresh);
                }}
                options={DocType_data}
                disabled={form_access_disable}
                useCallFunc={true}
                style={{ width: "160px" }}
                idexpr={"docId"}
                dataexp={"name"}
                isLoading={states.isLoading}
                isOpenDropdown={states.isOpenDropdown}
                handleSearch={useCallback(
                  debounce((e) => {
                    updateState("docType", e);
                    // UpdateState("isLoading", true);
                    // dispatch(fetchDoc(e));

                    updateState("isOpenDropdown", true);
                  }, 500), // Adjust the debounce delay as needed
                  []
                )}
                handleFocus={(e: any) => {
                  updateState("isOpenDropdown", !states.isOpenDropdown);
                }}
                selectedValue={(e: any) => {
                  updateState("isOpenDropdown", false);
                  parentSetstate("docType", { id: e.docId, name: e.name });
                }}
              />

              <Form.Item
                className="pr-3"
                label={
                  <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs pl-4">
                    {"Expiry Date "}
                  </p>
                }
              >
                <div>
                  <DatePicker
                    placeholder="Select Date"
                    onChange={(date, dateString) => {
                      updateState("date", date);
                    }}
                    value={
                      parentstates.lowerSelected !== undefined
                        ? dayjs(states.date)
                        : dayjs()
                    }
                    format={dateFormat}
                    disabled={form_access_disable}
                    clearIcon={false}
                  />
                </div>
              </Form.Item>

              <li className=" flex items-center flex-row pr-2">
                <p className="mr-3">Order:</p>{" "}
                <Input
                  disabled={form_access_disable}
                  className="w-[50px] -mr-[7px]"
                  onChange={(e) => {
                    updateState("order", e.target.value);
                  }}
                  value={states.order}
                />
              </li>
            </div>
          </Form.Item>

          {/* Title */}
          <Form.Item
            label={
              <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                {"Title "}
              </p>
            }
          >
            <div>
              <Input
                style={{ resize: "none", marginTop: 0, marginBottom: 2 }}
                className="h-6"
                onChange={(e) => {
                  parentSetstate("title", e.target.value);
                }}
                value={parentstates.title}
                readOnly={form_access_disable}
              />
            </div>
          </Form.Item>

          {/* Description */}
          <Form.Item
            label={
              <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                {"Description "}
              </p>
            }
          >
            <div>
              <TextArea
                style={{
                  resize: "none",
                  marginTop: 1,
                  marginBottom: 3,
                  height: "40px",
                }}
                className=""
                onChange={(e) => {
                  updateState("description", e.target.value);
                }}
                value={states.description}
                readOnly={form_access_disable}
              />
            </div>
          </Form.Item>

          {/* Remarks */}
          <Form.Item
            label={
              <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                {"Remarks "}
              </p>
            }
          >
            <div>
              <TextArea
                style={{ resize: "none", height: "80px" }}
                className=""
                rows={2}
                onChange={(e) => {
                  updateState("remarks", e.target.value);
                }}
                value={states.remarks}
                readOnly={form_access_disable}
              />
            </div>
          </Form.Item>

          <Form.Item
            label={
              <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs mt-1">
                {"Classified? "}
              </p>
            }
          >
            <div className=" flex  justify-between  ">
              <ul className="flex justify-evenly">
                <li className=" flex items-center flex-row pr-2">
                  {" "}
                  <Checkbox
                    disabled={form_access_disable}
                    style={{ borderColor: borderTheme }}
                    className="border-[1px] px-1 rounded"
                    onChange={(e) => {
                      updateState("classified", e.target.checked);
                    }}
                    checked={states.classified}
                  />
                </li>
                <li className=" flex items-center flex-row">
                  <p className="mr-3">Verified? : </p>{" "}
                  <Checkbox
                    disabled={form_access_disable}
                    style={{ borderColor: borderTheme }}
                    className="border-[1px] px-1 rounded"
                    onChange={(e) => {
                      updateState("verified", e.target.checked);
                    }}
                    checked={states.verified}
                  />
                </li>
              </ul>
              <ul className="flex justify-end  ">
                {form_access_disable === false && updateMode === false ? (
                  <SaveButton
                    handleSave={() => {
                      saveAccessPriv();
                    }}
                  />
                ) : updateMode === true ? (
                  <UpdateButton
                    handleUpdate={() => {
                      updateAccessPriv();
                    }}
                  />
                ) : (
                  <SaveButton
                    handleSave={() => {
                      saveAccessPriv();
                    }}
                  />
                )}
                {form_access_disable === true ? (
                  <Upload
                    key={Math.random() * 1000}
                    maxCount={1}
                    className="h-8 flex hover:border-blue-200 flex-row items-center justify-center ml-1 px-2 py-1 border-2 border-slate-200 rounded dark:bg-slate-900 dark:text-darkModeSkin-base"
                    fileList={[]}
                    onChange={(e: any) => {
                      const reader = new FileReader();
                      setDocumentList([e.file.originFileObj]);
                      const file = e.file.originFileObj;
                      const fileUri = {
                        uri: window.URL.createObjectURL(file),
                        fileName: file.name,
                      };
                      setDocs([fileUri]);
                      setActiveDoc(fileUri);
                      reader.onload = (e: any) => {
                        const encodedFile = btoa(e.target.result);
                        setDocument(encodedFile);
                        newAccessPriv();
                      };
                      reader.readAsBinaryString(e.file.originFileObj);
                    }}
                  >
                    {/* <NewButton
                      useCallbackFunc
                      // newButtonId={"upload"}
                      handleOnClick={() => {
                        openExplorer();
                        alert("NEW TOGGLE, CLEAR STATE");
                        setDocs([defaultDoc]);
                        setDocument("");
                      }}
                    /> */}
                    <button
                      id={`upload`}
                      className="flex space-x-2 items-center"
                    >
                      <img src={newIcon} alt="" />
                      <p>New</p>
                    </button>
                  </Upload>
                ) : updateMode === true ? (
                  <Cancel
                    handleCallbackCancel={() => {
                      setDocs([defaultDoc]);
                      setDocument("");
                    }}
                  />
                ) : !(updateMode && form_access_disable) ? (
                  <Cancel
                    handleCallbackCancel={() => {
                      setDocs([defaultDoc]);
                      setDocument("");
                    }}
                  />
                ) : (
                  <Upload
                    maxCount={1}
                    className="h-8 flex hover:border-blue-200 flex-row items-center justify-center ml-1 px-2 py-1 border-2 border-slate-200 rounded dark:bg-slate-900 dark:text-darkModeSkin-base"
                    fileList={[]}
                    onChange={(e: any) => {
                      const reader = new FileReader();
                      setDocumentList([e.file.originFileObj]);
                      const file = e.file.originFileObj;
                      const fileUri = {
                        uri: window.URL.createObjectURL(file),
                        fileName: file.name,
                      };
                      setDocs([fileUri]);
                      setActiveDoc(fileUri);
                      reader.onload = (e: any) => {
                        const encodedFile = btoa(e.target.result);
                        setDocument(encodedFile);
                        newAccessPriv();
                      };
                      reader.readAsBinaryString(e.file.originFileObj);
                    }}
                  >
                    {/* <NewButton
                      newButtonId={"upload"}
                      useCallbackFunc
                      handleOnClick={() => {
                        openExplorer();
                        alert("second onclick");
                      }}
                    /> */}
                    <button
                      id={`upload`}
                      className="flex space-x-2 items-center"
                    >
                      <img src={newIcon} alt="" />
                      <p>New</p>
                    </button>
                  </Upload>
                )}
              </ul>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
