import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncGet, GetDataFunc } from "../../../functions/get";

interface RequisitionDetails {
    requestArea:any|null|undefined;
    requestBy:any|null|undefined;
    requestType:any|null|undefined;
    recommendedBy:any|null|undefined;
    from_country:any|null|undefined;
    to_country:any|null|undefined;
    from_region:any|null|undefined;
    to_region:any|null|undefined;
    from_location:any|null|undefined;
    to_location:any|null|undefined;
    isLoading: boolean;
    isPending: boolean;
    isError: boolean;
}

let initialState: RequisitionDetails = {
    requestArea:[],
    requestBy:[],
    requestType:[],
    recommendedBy:[],
    from_country:[],
    to_country:[],
    from_region:[],
    to_region:[],
    from_location:[],
    to_location:[],
    isLoading: false,
    isPending: false,
    isError: false
}
export const FetchRequestArea = createAsyncThunk<RequisitionDetails,string,{ rejectValue: string }>(
    "requestArea/fetch", 
        async (search:string) => {
            try {
                var requestArea_response = await AsyncGet(`AsmTmsVehicleRequest/GetAllAsmTsmVehicleRequestAreas?pagesize=20&pagenumber=1&Search=${search}`);
                if (requestArea_response?.status === true) {
                return requestArea_response?.data;
                }
            } catch {
                return requestArea_response?.data;
            }
    })
export const FetchRequestBy = createAsyncThunk<RequisitionDetails,string,{ rejectValue: string }>(
    "requestBy/fetch", 
        async (search:string) => {
            try {
                var requestBy_response = await AsyncGet(`AsmTmsVehicleRequest/GetAllAsmTmsVehicleRequestedByAll?PageNumber=20&PageSize=1&Search=${search}`);
                if (requestBy_response?.status === true) {
                return requestBy_response?.data;
                }
            } catch {
                return requestBy_response?.data;
            }
    });
export const FetchRequestType = createAsyncThunk<RequisitionDetails,string,{ rejectValue: string }>(
    "requestType/fetch", 
        async (search:string) => {
            try {
                var requestType_response = await AsyncGet(`AsmTmsVehicleRequest/GetAllAsmTsmVehicleRequestTypes?PageNumber=20&PageSize=1&Search=${search}`);
                if (requestType_response?.status === true) {
                return requestType_response?.data;
                }
            } catch {
                return requestType_response?.data;
            }
    });
export const FetchRecommendedBy = createAsyncThunk<RequisitionDetails,{search:string, id:number},{ rejectValue: string }>(
    "recommendedBy/fetch",
        async ({search, id}) => {
            try {
                var recommendedBy_response = await AsyncGet(`AsmTmsVehicleRequest/GetAllAsmTsmVehicleRequestRecommendByGetAll?VaaRequestAreaIDfk=${id}&PageNumber=20&PageSize=1&Search=${search}`);
                if (recommendedBy_response?.status === true) {
                return recommendedBy_response?.data;
                }
            } catch {
                return recommendedBy_response?.data;
            }
    });
export const FetchFrom_country = createAsyncThunk<RequisitionDetails,string,{ rejectValue: string }>(
    "from_country/fetch", 
        async (search:string) => {
            try {
                var from_country_response = await AsyncGet(`AsmTmsVehicleRequest/GetAllAsmTsmVehicleRequestFromCountryGetAll?PageNumber=20&PageSize=1&Search=${search}`);
                if (from_country_response?.status === true) {
                return from_country_response?.data;
                }
            } catch {
                return from_country_response?.data;
            }
    });
export const FetchTo_country = createAsyncThunk<RequisitionDetails,string,{ rejectValue: string }>(
    "to_country/fetch", 
        async (search:string) => {
            try {
                var to_country_response = await AsyncGet(`AsmTmsVehicleRequest/GetAllAsmTsmVehicleRequestToCountryGetAll?PageNumber=20&PageSize=1&Search=${search}`);
                if (to_country_response?.status === true) {
                return to_country_response?.data;
                }
            } catch {
                return to_country_response?.data;
            }
    });
export const FetchTo_region = createAsyncThunk<RequisitionDetails,{search:string,id:number},{ rejectValue: string }>(
    "to_region/fetch", 
        async ({search,id}) => {
            try {
                var to_region_response = await AsyncGet(`AsmTmsVehicleRequest/GetAllAsmTsmVehicleRequestToRegionGetAll?CtyIDpk=${id}&PageNumber=20&PageSize=1&Search=${search}`);
                if (to_region_response?.status === true) {
                return to_region_response?.data;
                }
            } catch {
                return to_region_response?.data;
            }
    });
export const FetchFrom_region = createAsyncThunk<RequisitionDetails,{search:string,id:number},{ rejectValue: string }>(
    "From_region/fetch", 
        async ({search,id}) => {
            try {
                var from_region_response = await AsyncGet(`AsmTmsVehicleRequest/GetAllAsmTsmVehicleRequestFromRegionGetAll?ctyIDpk=${id}&PageNumber=20&PageSize=1&Search=${search}`);
                if (from_region_response?.status === true) {
                return from_region_response?.data;
                }
            } catch {
                return from_region_response?.data;
            }
    });
export const FetchFrom_location = createAsyncThunk<RequisitionDetails,{search:string, countryID:number,regionID:number},{ rejectValue: string }>(
    "From_locaion/fetch", 
        async ({search,countryID,regionID}) => {
            try {
                var from_location_response = await AsyncGet(`AsmTmsVehicleRequest/GetAllAsmTsmVehicleRequestFromTownGetAll?ctyRgnIDpk=${countryID}&FromRgnID=${regionID}&PageNumber=20&PageSize=1&Search=${search}`);
                if (from_location_response?.status === true) {
                return from_location_response?.data;
                }
            } catch {
                return from_location_response?.data;
            }
    });
export const FetchTo_location = createAsyncThunk<RequisitionDetails,{search:string, countryID:number,regionID:number},{ rejectValue: string }>(
        "To_location/fetch", 
            async ({search,countryID,regionID}) => {
                try {
                    var from_region_response = await AsyncGet(`AsmTmsVehicleRequest/GetAllAsmTsmVehicleRequestToTownGetAll?rgnCtyIDpk=${countryID}&toRgnID=${regionID}&PageNumber=20&PageSize=1&Search=${search}`);
                    if (from_region_response?.status === true) {
                    return from_region_response?.data;
                    }
                } catch {
                    return from_region_response?.data;
                }
        });



const RequisitionDetailsSlice =  createSlice({
    name:'Vehicle Request',
    initialState:initialState,
    reducers:{},
    extraReducers(builder) {
        builder
        //-------------FetchRequestArea-------------
        .addCase(FetchRequestArea.pending,(state)=>{
            state.isLoading = true;
            state.isError = false;
        })
        .addCase(FetchRequestArea.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.isError = false;
            state.requestArea = action.payload;
        })
        .addCase(FetchRequestArea.rejected,(state)=>{
            state.isError = true;
            state.isLoading=false;
        })
        //-------------FetchRequestBy-----------------
        .addCase(FetchRequestBy.pending,(state)=>{
            state.isLoading = true;
            state.isError = false;
        })
        .addCase(FetchRequestBy.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.isError = false;
            state.requestBy = action.payload;
        })
        .addCase(FetchRequestBy.rejected,(state)=>{
            state.isError = true;
            state.isLoading=false;
        })
        //--------------FetchRequestType---------------
        .addCase(FetchRequestType.pending,(state)=>{
            state.isLoading = true;
            state.isError = false;
        })
        .addCase(FetchRequestType.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.isError = false;
            state.requestType = action.payload;
        })
        .addCase(FetchRequestType.rejected,(state)=>{
            state.isError = true;
            state.isLoading=false;
        })
        //--------------FetchRecommendedBy--------------
        .addCase(FetchRecommendedBy.pending,(state)=>{
            state.isLoading = true;
            state.isError = false;
        })
        .addCase(FetchRecommendedBy.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.isError = false;
            state.recommendedBy = action.payload;
        })
        .addCase(FetchRecommendedBy.rejected,(state)=>{
            state.isError = true;
            state.isLoading=false;
        })
        //--------------FetchFrom_country----------------
        .addCase(FetchFrom_country.pending,(state)=>{
            state.isLoading = true;
            state.isError = false;
        })
        .addCase(FetchFrom_country.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.isError = false;
            state.from_country = action.payload;
        })
        .addCase(FetchFrom_country.rejected,(state)=>{
            state.isError = true;
            state.isLoading=false;
        })
        //--------------FetchTo_country------------------
        .addCase(FetchTo_country.pending,(state)=>{
            state.isLoading = true;
            state.isError = false;
        })
        .addCase(FetchTo_country.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.isError = false;
            state.to_country = action.payload;
        })
        .addCase(FetchTo_country.rejected,(state)=>{
            state.isError = true;
            state.isLoading=false;
        })
        //---------------FetchTo_region--------------------
        .addCase(FetchTo_region.pending,(state)=>{
            state.isLoading = true;
            state.isError = false;
        })
        .addCase(FetchTo_region.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.isError = false;
            state.to_region = action.payload;
        })
        .addCase(FetchTo_region.rejected,(state)=>{
            state.isError = true;
            state.isLoading=false;
        })
        //----------------FetchFrom_region-------------------
        .addCase(FetchFrom_region.pending,(state)=>{
            state.isLoading = true;
            state.isError = false;
        })
        .addCase(FetchFrom_region.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.isError = false;
            state.from_region = action.payload;
        })
        .addCase(FetchFrom_region.rejected,(state)=>{
            state.isError = true;
            state.isLoading=false;
        })
        //---------------FetchFrom_location------------------
        .addCase(FetchFrom_location.pending,(state)=>{
            state.isLoading = true;
            state.isError = false;
        })
        .addCase(FetchFrom_location.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.isError = false;
            state.from_location = action.payload;
        })
        .addCase(FetchFrom_location.rejected,(state)=>{
            state.isError = true;
            state.isLoading=false;
        })
        //---------------FetchTo_location-------------------
        .addCase(FetchTo_location.pending,(state)=>{
            state.isLoading = true;
            state.isError = false;
        })
        .addCase(FetchTo_location.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.isError = false;
            state.to_location = action.payload;
        })
        .addCase(FetchTo_location.rejected,(state)=>{
            state.isError = true;
            state.isLoading=false;
        })
    },
})
export default RequisitionDetailsSlice.reducer;