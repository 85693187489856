export const vehicleSimCard_TH = [
  {
    id: 0,
    caption: "VEH. REG No",
    dataField: "name",
    alignment: "left",
    fixed: true,
  },
  {
    id: 1,
    caption: "MOB. No",
    dataField: "shortName",
    alignment: "left",
    fixed: true,
    width: null,
  },

  {
    id: 2,
    caption: "SIM VENDOR",
    dataField: "code",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 3,
    caption: "TRACKER VENDOR",
    dataField: "towerType",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 4,
    caption: "TRACKER URL",
    dataField: "active",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 5,
    caption: "START DATE",
    dataField: "height",
    alignment: "left",
    fixed: true,
    width: null,
  },

  {
    id: 6,
    caption: "END DATE",
    dataField: "footing",
    alignment: "left",
    fixed: true,
    width: null,
  },

  {
    id: 7,
    caption: "ACTIVE",
    dataField: "insulatorType",
    alignment: "left",
    fixed: true,
  },

  {
    id: 8,
    caption: "REMARKS",
    dataField: "remarks",
    alignment: "left",
    fixed: true,
    width: 4000,
  },
];

export const vehicleSimCard_data = [
  {
    id: 0,
    caption: "RQST NO",
    dataField: "mrqIDpk",
    dataType: "",
    alignment: "left",
    fixed: true,
  },
  {
    id: 1,
    caption: "REG. NO",
    dataField: "vdtRegNo",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 90,
  },

  {
    id: 2,
    caption: "RECOM.?",
    dataField: "mrqRecommended",
    dataType: "boolean",
    alignment: "left",
    fixed: true,
    width: 80,
  },
  {
    id: 3,
    caption: "ACKN?",
    dataField: "mrqAcknowledged",
    alignment: "left",
    dataType: "boolean",
    fixed: true,
    width: 80,
  },
  {
    id: 4,
    caption: "SMS SENT?",
    dataField: "mrqSmsSent",
    alignment: "left",
    dataType: "boolean",
    fixed: true,
    width: 80,
  },
  {
    id: 5,
    caption: "OPENED?",
    dataField: "mtaOpened",
    alignment: "left",
    dataType: "boolean",
    fixed: true,
    width: 80,
  },

  {
    id: 6,
    caption: "CLOSED",
    dataField: "mtaDone",
    dataType: "boolean",
    alignment: "left",
    fixed: true,
    width: 80,
  },

  {
    id: 7,
    caption: "PM?",
    dataField: "mrqPm",
    dataType: "boolean",
    alignment: "left",
    fixed: true,
    width: 60,
  },

  {
    id: 8,
    caption: "CANC?",
    dataField: "mrqCancelled",
    dataType: "boolean",
    alignment: "left",
    fixed: true,
    width: 80,
  },
  {
    id: 9,
    caption: "APRVD?",
    dataField: "mrqApproved",
    alignment: "left",
    dataType: "boolean",
    fixed: true,
    width: 80,
  },

  {
    id: 10,
    caption: "%COMPLETE?",
    dataField: "mrqPercentComplete",
    alignment: "left",
    dataType: "",
    fixed: true,
    width: 80,
  },

  {
    id: 11,
    caption: "RATING",
    dataField: "mrqRating",
    alignment: "left",
    dataType: "",
    fixed: true,
    width: 85,
  },
  {
    id: 12,
    caption: "JOB No",
    dataField: "mtaJobNo",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 80,
  },
  {
    id: 13,
    caption: "MAINT. TYPE",
    dataField: "mtpShtName",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 85,
  },
  {
    id: 14,
    caption: "REQUEST TIME",
    dataField: "mrqDate",
    alignment: "left",
    dataType: "datetime",
    fixed: true,
    width: 85,
  },
  {
    id: 15,
    caption: "REPORT TIME",
    dataField: "mrqReportedOn",
    alignment: "left",
    dataType: "datetime",
    fixed: true,
    width: 90,
  },
  {
    id: 16,
    caption: "REQ. WORKSHOP",
    dataField: "wsdName",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 100,
  },
  {
    id: 17,
    caption: "DEFECT REQUEST",
    dataField: "mrqDefectRequest",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 100,
  },
  {
    id: 18,
    caption: "ACCEPTANCE REPORT",
    dataField: "mrqReviewReport",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 100,
  },
  {
    id: 19,
    caption: "REQUESTED BY",
    dataField: "requestedByEmpName",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 120,
  },
  {
    id: 20,
    caption: "REPORTING DRIVER",
    dataField: "driverEmpName",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 120,
  },
  {
    id: 21,
    caption: "RECOMMENDED BY",
    dataField: "recommendedByEmpName",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 120,
  },
  {
    id: 22,
    caption: "RECOM. BY STAFF No",
    dataField: "recommendedByStaffNo",
    alignment: "left",
    dataType: "",
    fixed: true,
    width: 85,
  },
  {
    id: 23,
    caption: "RECOM. BY JOB TITLE",
    dataField: "recommendedByJbtShtName",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 100,
  },
  {
    id: 24,
    caption: "APPROVED BY",
    dataField: "approvedByEmpName",
    dataType: "string",
    alignment: "left",
    fixed: true,
    width: 100,
  },
  {
    id: 25,
    caption: "APPROVER JOB TITLE",
    dataField: "approvedByJbtShtName",
    dataType: "string",
    alignment: "left",
    fixed: true,
    width: 100,
  },
  {
    id: 26,
    caption: "ACKNOWLEDGED BY",
    dataField: "string",
    alignment: "left",
    fixed: true,
    width: 100,
  },
  {
    id: 27,
    caption: "ACKNOWLEDGED ON",
    dataField: "datetime",
    alignment: "left",
    fixed: true,
    width: 100,
  },
  {
    id: 28,
    caption: "OPENED BY",
    dataField: "openedByEmpName",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 120,
  },
  {
    id: 29,
    caption: "REQ. DEPT",
    dataField: "requestedByDptShtName",
    alignment: "left",
    dataType: "string",
    fixed: true,
    width: 110,
  },
  {
    id: 30,
    caption: "SCHED. WORKSHOP",
    dataField: "schedWkshpName",
    dataType: "string",
    alignment: "left",
    fixed: true,
    width: 120,
  },
  {
    id: 31,
    caption: "REQ. SECTION",
    dataField: "requestedBySxnShtName",
    dataType: "string",
    alignment: "left",
    fixed: true,
    width: 100,
  },
  {
    id: 32,
    caption: "CANCELLED",
    dataField: "mrqCancelled",
    dataType: "boolean",
    alignment: "left",
    fixed: true,
    width: 85,
  },
  {
    id: 33,
    caption: "OPENED ON",
    dataField: "mtaCreationDate",
    alignment: "left",
    dataType: "datetime",
    fixed: true,
    width: 100,
  },
  {
    id: 34,
    caption: "CREATED ON",
    dataField: "mrqCreationDate",
    alignment: "left",
    dataType: "datetime",
    fixed: true,
    width: 100,
  },
  {
    id: 35,
    caption: "CANCELLED BY",
    dataField: "cancelledByEmpName",
    dataType: "string",
    alignment: "left",
    fixed: true,
    width: 100,
  },
  {
    id: 36,
    caption: "CANCELLED ON",
    dataField: "mrqCancelledOn",
    alignment: "left",
    dataType: "datetime",
    fixed: true,
    width: 100,
  },
  {
    id: 37,
    caption: "REMARKS",
    dataField: "mrqRmks",
    dataType: "string",
    alignment: "left",
    fixed: true,
    width: 4000,
  },
];

// droopdown data

export const vehicleReg_cols = [
  {
    id: 0,
    caption: "Reg No",
    dataField: "vdtRegNo",
    dataType: "",
    fixed: false,
    width: 100,
  },
  {
    id: 1,
    caption: "Class",
    dataField: "vhcShtName",
    dataType: "string",
    fixed: false,
    width: 80,
  },
  {
    id: 2,
    caption: "Make",
    dataField: "vmkShtName",
    dataType: "string",
    fixed: false,
    width: 80,
  },
  {
    id: 3,
    caption: "Model",
    dataField: "mdlShtName",
    dataType: "string",
    fixed: false,
    width: 80,
  },
  {
    id: 4,
    caption: "Manufacturer",
    dataField: "mftShtName",
    dataType: "string",
    fixed: false,
    width: 80,
  },
  {
    id: 5,
    caption: "Driver",
    dataField: "driverEmpName5",
    dataType: "string",
    fixed: false,
    width: 120,
  },
  {
    id: 6,
    caption: "Driver INTERCOM.",
    dataField: "driverPlc",
    width: 120,
    dataType: "string",
  },
  {
    id: 7,
    caption: "Approving Officer",
    dataField: "ownerEmpName5",
    dataType: "string",
    width: 120,
  },
  {
    id: 8,
    caption: "Appr. Officer INTERCOM.",
    dataField: "ownerPlc",
    dataType: "string",
    width: 120,
  },
  {
    id: 9,
    caption: "Manuf. Date",
    dataField: "vdtManufactureDate",
    dataType: "string",
    width: 120,
  },
  {
    id: 10,
    caption: "Dept",
    dataField: "dptShtName",
    dataType: "string",
    width: 120,
  },
  {
    id: 11,
    caption: "Section",
    dataField: "sxnShtName",
    dataType: "string",
    width: 120,
  },
  {
    id: 12,
    caption: "Fuel Type",
    dataField: "ftpShtName",
    dataType: "string",
    width: 120,
  },
];




export const reqBy_cols = [
  {
    id: 0,
    caption: "Staff No",
    dataField: "empStaffNo",
    dataType: "",
    width: 80,
  },
  {
    id:   1,
    caption: "Name",
    dataField: "empName",
    dataType: "string",
    width: 120,
  },
  {
    id: 2,
    caption: "Dept",
    dataField: "dptShtName",
    dataType: "string",
    width: 80,
  },
  {
    id: 3,
    caption: "Section",
    dataField: "sxnShtName",
    dataType: "string",
    width: 80,
  },
  {
    id: 4,
    caption: " Job Title",
    dataField: "jbtShtName",
    dataType: "string",
    width: 120,
  }
  ,
  {
    id: 5,
    caption: "Location",
    dataField: "locShtName",
    dataType: "string",
    width: 80,
  }
];

// export const repD_cols = [];

// export const recomBy_cols = [];

export const workshop_cols = [
  {
    id: 0,
    caption: "Workshop",
    dataField: "wsdName",
    dataType: "string",
    width: 200,
  },
  {
    id: 1,
    caption: "Code",
    dataField: "wsdCode",
    dataType: "string",
    width: 80,
  },
  {
    id: 2,
    caption: "Contact",
    dataField: "wsdContact",
    dataType: "string",
    width: 80,
  },
  {
    id: 3,
    caption: "Tel No",
    dataField: "wsdPlc",
    dataType: "",
    width: 80,
  },
  {
    id: 4,
    caption: "Location",
    dataField: "locName",
    dataType: "string",
    width: 80,
  },
]
