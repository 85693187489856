// DOM Manuplation functions
// used to manuplate DOM Node id attribute styles

export const setElementStyles = (id: string, className: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.className = className;
    }
  };


  export const setElementStylesQuerySelector = (id: string, className : string) => {
    const element = document.querySelector(id);
    if (element) {
      element.className = className;
    }
  }
  