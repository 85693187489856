export const vehicleNo1Columns = [
  {
    id: 0,
    caption: "Reg No",
    dataField: "vdtRegNo",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 1,
    caption: "Class",
    dataField: "vhcShtName",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 2,
    caption: "Make",
    dataField: "vmkShtName",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 3,
    caption: "Manufacturer",
    dataField: "mftShtName",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 4,
    caption: "Driver",
    dataField: "ownerEmpName5",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 5,
    caption: "Driver Intercom",
    dataField: "driverPlc",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 5,
    caption: "Approving Officer",
    dataField: "ownerEmpName1",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 6,
    caption: "Appr. Officer INTERCOM",
    dataField: "ownerPlc",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 7,
    caption: "Manuf Date",
    dataField: "vdtManufactureDate",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 8,
    caption: "Dept",
    dataField: "dptShtName",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 9,
    caption: "Section",
    dataField: "sxnShtName",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 10,
    caption: "Fuel Type",
    dataField: "ftpShtName",
    alignment: "left",
    fixed: true,
    width: null,
  },
];


export const driverColumns = [
    {
        id: 0,
        caption: "Staff No",
        dataField: "empStaffNo",
        alignment: "left",
        fixed: true,
        width: null,
    },
    {
        id: 1,
        caption: "Name",
        dataField: "empName",
        alignment: "left",
        fixed: true,
        width: null,
    },
    {
        id: 2,
        caption: "Dept",
        dataField: "dptShtName",
        alignment: "left",
        fixed: true,
        width: null,
    },
    {
        id: 4,
        caption: "Section",
        dataField: "sxnShtName",
        alignment: "left",
        fixed: true,
        width: null,
    },
    {
        id: 5,
        caption: "Job Title",
        dataField: "jbtShtName",
        alignment: "left",
        fixed: true,
        width: null,
    },
    {
        id: 6,
        caption: "Location",
        dataField: "locShtName",
        alignment: "left",
        fixed: true,
        width: null,
    },
]

export const requestByColumns = [
    {
        id: 0,
        caption: "Staff No",
        dataField: "empStaffNo",
        alignment: "left",
        fixed: true,
        width: null,
    },
    {
        id: 1,
        caption: "Name",
        dataField: "empName",
        alignment: "left",
        fixed: true,
        width: null,
    },
    {
        id: 2,
        caption: "Dept",
        dataField: "dptShtName",
        alignment: "left",
        fixed: true,
        width: null,
    },
    {
        id: 4,
        caption: "Section",
        dataField: "sxnShtName",
        alignment: "left",
        fixed: true,
        width: null,
    },
    {
        id: 5,
        caption: "Job Title",
        dataField: "jbtShtName",
        alignment: "left",
        fixed: true,
        width: null,
    },
    {
        id: 6,
        caption: "Location",
        dataField: "locShtName",
        alignment: "left",
        fixed: true,
        width: null,
    },
]