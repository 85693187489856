/* eslint-disable react/jsx-pascal-case */
import { DateTemplate } from "../../../../../../templates/date";
import { InputsTemplate } from "../../../../../../templates/input";
import { TextareaTemplate } from "../../../../../../templates/textarea";
import { SaveButton } from "../../../../../accessories/buttons";
import MiniToolbar from "../../_widget/minitollbar";
import { Switcher } from "../../_widget/switcher";
import { RefreshButton } from "../../_widget/utils";
import Datagrid_template from "../../../../../../templates/Datagrid";
import { ReadingsChart } from "../../_widget/readingschart";
import { useGraphSwitcher } from "../../db/useSwitcher";
import { transformerColumns, transformerData } from "../BusVoltage/_data/stationentriescolumns";

export default function TransformerReadings() {
    const { status } = useGraphSwitcher();

    return (
        <section className="w-full h-full" >

            <Switcher alternate={''} >
                {/* top section */}
                <section className='flex space-x-3 w-full border-2 p-2'>
                    {/* left section */}
                    <aside className='w-[250px]'>
                        <aside className='flex items-center justify-between bg-neutral-50 py-1 rounded-sm px-2 border-[1px] border-neutral-400'>
                            <p className='text-sm font-medium'>STATION</p>
                            <RefreshButton />
                        </aside>
                        <aside>
                            <Datagrid_template
                                gridheight={270}
                                showColumnHeaders={false}
                                columns={[{
                                    id: 1,
                                    caption: "STATION",
                                    dataField: "station",
                                },]}
                                data={[{ id: 0, station: "Station", value: "Bus Station" }, { id: 1, station: "Vladivost", value: "Bus Station" }]}
                                disablePaging
                                disableSearch
                                disableGroupPanel
                            />
                        </aside>
                    </aside>
                    {/* right section */}
                    <aside className='w-[88%] items-right overflow-hidden pl-0.5'>
                        <section className='flex items-start ml-[143px]'>
                            <p className='text-[12px] font-medium mr-2'>Transformer:</p>
                            <Datagrid_template
                                gridheight={100}
                                showColumnHeaders={true}
                                columns={transformerColumns}
                                data={transformerData}
                                disablePaging
                                disableSearch
                                disableGroupPanel
                            />
                        </section>
                        <section className='w-full h-full mt-1'>
                            <section className="flex items-start justify-between">
                                <aside className="flex items-center w-full ml-[180px] mb-0.5 ">
                                    <p className="mr-[6px]">Time:</p>
                                    <DateTemplate disabled={false} style={{ width: 230, height: 32 }} className="w-full" />
                                </aside>
                                <aside>
                                    <RefreshButton />
                                </aside>
                            </section>
                            <section className="flex space-x-2 items-center">
                                <aside className="flex space-x-2 items-center">
                                    <section className="space-y-0.5 ">
                                        <section className="flex space-x-1">
                                            <aside className="ml-[70px]">
                                                <InputsTemplate disabledStatus={false} span inputStyle={{ width: 114 }} label="Voltage (kv) & Current (A)" labelColPan={3} />
                                            </aside>
                                            <InputsTemplate disabledStatus={false} span inputStyle={{ width: 114 }} />
                                        </section>
                                        <section className="flex space-x-1 ml-4">
                                            <InputsTemplate disabledStatus={false} span inputStyle={{ width: 114 }} label="Active (kW) & Reactive Power (kVAr)" labelColPan={12} />
                                            <InputsTemplate disabledStatus={false} span inputStyle={{ width: 114 }} />
                                        </section>
                                        <section className="flex space-x-1 -ml-0.5">
                                            <InputsTemplate disabledStatus={false} span inputStyle={{ width: 114 }} label="Min & Max Winding Temperature 1 (°C)" labelColPan={12} />
                                            <InputsTemplate disabledStatus={false} span inputStyle={{ width: 114 }} />
                                        </section>
                                    </section>
                                </aside>
                                <aside className="flex space-x-2 items-center">
                                    <section className="space-y-0.5 ">
                                        <section className="flex space-x-1">
                                            <aside className="ml-[70px]">
                                                <InputsTemplate disabledStatus={false} span inputStyle={{ width: 114 }} label="Top Counter & Position" labelColPan={3} />
                                            </aside>
                                            <InputsTemplate disabledStatus={false} span inputStyle={{ width: 114 }} />
                                        </section>
                                        <section className="flex space-x-1 ml-5">
                                            <InputsTemplate disabledStatus={false} span inputStyle={{ width: 114 }} label="Min & Max Oil Temperatures (°C)" labelColPan={12} />
                                            <InputsTemplate disabledStatus={false} span inputStyle={{ width: 114 }} />
                                        </section>
                                        <section className="flex space-x-1 ml-4">
                                            <InputsTemplate disabledStatus={false} span inputStyle={{ width: 114 }} label="Min & Max Winding Temps. 2 (°C)" labelColPan={12} />
                                            <InputsTemplate disabledStatus={false} span inputStyle={{ width: 114 }} />
                                            <SaveButton />
                                        </section>
                                    </section>
                                </aside>
                            </section>
                            <section className="flex h-full mt-0.5 ml-[158px]">
                                <p className="text-sm mr-2">Remarks: </p>
                                <aside className="w-full">
                                    <TextareaTemplate height={75} />
                                </aside>
                            </section>
                        </section>
                    </aside>
                </section>
                <div className='h-1 w-full bg-sky-200' />
            </Switcher>
            {/* botom section */}
            <section className='flex flex-col'>
                <MiniToolbar />
                <section className='flex-grow'>
                    <section className='w-full h-full'>
                        {
                            status ? <ReadingsChart /> :
                                <Datagrid_template
                                    gridheight={415}
                                    showColumnHeaders={true}
                                    columns={transformerColumns}
                                    data={transformerData}
                                    disablePaging
                                    disableGroupPanel
                                />
                        }
                    </section>
                </section>
            </section>
        </section>
    );
}