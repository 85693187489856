import React, { useCallback, useEffect, useRef, useState } from "react";
import DataGrid, {
  Column,
  Export,
  GroupPanel,
  HeaderFilter,
  KeyboardNavigation,
  LoadPanel,
  Pager,
  Paging,
  RemoteOperations,
  Scrolling,
  SearchPanel,
  Selection,
} from "devextreme-react/data-grid";
import { useDispatch } from "react-redux";
import { setValue } from "../../../../features/forms/tier1/createSlice";
import { setGeneralValue, settask_gridRef } from "../../../../features/generalSlice";

interface props {
  columns: any;
  gridheight: number;
  dataGridData: any;
  searchWidth?: number;
  setSelectedRecord?: (val: any) => void;
  clicked?: (e: any) => void;
  doubleClicked?: (e: any) => void;
}

export const Hr_Setups_Datagrid_Template = ({
  gridheight,
  columns,
  dataGridData,
  searchWidth,
  clicked,
  doubleClicked,
  setSelectedRecord
}: props) => {
  const dataGridRef = useRef<any>(null);
  const dispatch = useDispatch();

  const selectItem= (e: any) => {
    //disable Update Mode
    dispatch(setGeneralValue({ expr: "updateMode", value: false }));

    //disable forms
    dispatch(setGeneralValue({ expr: "formDisabled", value: true }));

    clicked && clicked(e);
    setSelectedRecord && setSelectedRecord(e.data);
    //clear selected field data on redux
    dispatch(setGeneralValue({ expr: "selectedFieldData", value: e.data }));
    //post selected field data to redux
    setTimeout(function () {

      // Something you want delayed.
      dispatch(setGeneralValue({ expr: "selectedFieldData", value: e.data }));
    }, 1 / (10 ** 10));



  };

  const allowUpdate = (e: any) => {
    doubleClicked && doubleClicked!(e);
    setSelectedRecord && setSelectedRecord(e)
    //enable forms
    // dispatch(setGeneralValue({ expr: "formDisabled", value: false }));

    //enable Update Mode
    // dispatch(setGeneralValue({ expr: "updateMode", value: true }));
  };


  const onSelectionChanged = (selected: any) => {
    //clear selected field data on redux
    dispatch(setGeneralValue({ expr: "selectedFieldData", value: {} }));
    //post selected field data to redux
    dispatch(setGeneralValue({ expr: "selectedFieldData", value: selected?.selectedRowsData[0] ?? {} }));

    //disable Update Mode
    dispatch(setGeneralValue({ expr: "updateMode", value: false }));

    //disable forms
    dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    setSelectedRecord && setSelectedRecord(selected);
  }


  const onFocusedCellChanging = (e :any) => {
    // console.log(dataGridData[e.newRowIndex])
    // console.log(e.newRowIndex)
    console.log(e)
    //post selected field data to redux
    dispatch(setGeneralValue({ expr: "selectedFieldData", value: dataGridData[e.newRowIndex] ?? {} }));
    const dataGridInstance = dataGridRef.current?.instance;
    dataGridInstance.selectRowsByIndexes([e.newRowIndex]);

  }


  const onContentReady = (e:any) => {
    const dataGridInstance = dataGridRef.current?.instance;
    dataGridInstance.selectRowsByIndexes([0]);
    // dispatch(setGeneralValue({expr:'gridRef', value: dataGridRef}))
    // const gridInstance: any = dataGridRef.current
    // dispatch(settask_gridRef(gridInstance))

  }

  const displayModes = [
    { text: "Display Mode 'full'", value: "full" },
    { text: "Display Mode 'compact'", value: "compact" },
  ];
  
  const allowedPageSizes: any[] = ["all", 15, 50, 125, 200, 500, 1000];


  const renderTitleHeader = (data: any) => {
    return <p>{data.column.caption}</p>;
  };
  return (
    <div className="w-full">
      {/* custom style for search section */}
      <style
        dangerouslySetInnerHTML={{
          __html: `
                    .dx-datagrid-header-panel .dx-toolbar {
                        margin-bottom: 1.5px;
                    }`,
        }}
      />
      <DataGrid
        ref={dataGridRef}
        keyExpr={'id'}
        dataSource={dataGridData}
        showBorders={true}
        rowAlternationEnabled={true}
        focusedRowEnabled={true}
        showRowLines={true}
        showColumnLines={true}
        width={"100%"}
        onRowDblClick={allowUpdate}
        columnResizingMode="widget"
        onContentReady={onContentReady}
        onSelectionChanged={onSelectionChanged}
        height={gridheight}
        allowColumnResizing={true}
        onRowClick={selectItem}
        onFocusedCellChanging={onFocusedCellChanging}
      >
        {/* <Export enabled={true} formats={['xlsx', 'pdf']}/> */}

        <HeaderFilter visible={true} allowSearch={true} />
        <SearchPanel 
          width={searchWidth ? searchWidth : 300} 
          visible={true} 
          highlightSearchText={true}
          searchVisibleColumnsOnly={true}
        />

        <GroupPanel visible={true} />
        <Selection mode="single" />

        {/* STAFF DETAILS COLUMNS */}
        {/* FIXED COLUMNS */}
        {columns.map((column: any) => {
          return (
            <Column
              key={column.id}
              dataField={column.dataField}
              caption={column.caption}
              dataType={column.dataType}
              width={(column.caption === 'ACTIVE' || column.caption === 'ACTIVE?' || column.caption === 'ORDER') ? 70 : column.width}
              alignment={"left"}
              fixed={column.fixed}
              allowFiltering={true}
              headerCellRender={renderTitleHeader}
              visible={column.visible}
              renderAsync={true} // loads simple columns first before complex columns

            />
          );
        })}
        {/* <Scrolling mode="virtual" /> */}
        {/* <LoadPanel enabled={true}/> */}

        <Scrolling
            useNative={false}
            scrollByContent={true}
            scrollByThumb={true}
            showScrollbar="always"
            mode={"infinite"}
            rowRenderingMode={"virtual"}
            columnRenderingMode={"virtual"}
          />

        <Paging defaultPageSize={0} />
        <Pager
          visible={true}
          allowedPageSizes={allowedPageSizes} //TODO: breakpoint 
          displayMode={"compact"}
          showInfo={true}
          showNavigationButtons={true}
          showPageSizeSelector={true}
        />

        <RemoteOperations
          filtering={true}
          paging={true}
          sorting={true}
          summary={true}
          grouping={true}
          groupPaging={true}
        />

      </DataGrid>
    </div>
  );
};
