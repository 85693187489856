import React, { useState , useCallback, useRef, useEffect } from 'react'

import {Form, Input, Select,DatePicker,Slider, DatePickerProps } from 'antd';
import { CheckBox } from 'devextreme-react/check-box';
import save from '../../assets/save2.png'
import add from '../../assets/add.png'
import locked from '../../../assets/locked.png'
import refresh from '../../../assets/refresh.png'
import {AiOutlineSwitcher} from 'react-icons/ai'
import { AppraiseButton, Cancel, EditButton, NewButton, SaveButton } from '../../accessories/buttons';
import dayjs from 'dayjs';
import useFetch from '../../../hooks/useFetch';
import axios from 'axios';
import { api_url } from '../../accessories/component_infos';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';



interface props{
    // this means whether a prop is passed on the component or not the component called
    // anywhere should work (i.e. with the ?) but without the ? it takes it's strict rule
    // that without a prop there will be an error 
    toggler ?: ()=> void
    backToggler ?: ()=> void
    datgridCol ?: any
}

export const TaskOtherUpdate = ({toggler,backToggler}:props)=>{
    // const formref =useRef<any>(null)
    const { Option } = Select;
    const { TextArea } = Input;
    const dateFormat = " DD MMM YYYY HH: mm:ss"
    const handleref =useRef<any>(null)

    useEffect(()=>{
    //   alert( selectedRecord[0].tskIDpk )
    },[handleref])
    const options = [];
    for (let i = 1; i < 36; i++) {
    options.push({
        label: i.toString(36) + i,
        value: i.toString(36) + i,
    });
    }

    const [onChangestatus, setOnChangeStatus] = useState<number>(0)
    const [onChangedoneby, setOnChangeDoneby] = useState<any>("")
    const [description, setDescription] = useState<string>("")

    const [textAreaHeight , settextAreaHeight] = useState<string>('127px')
    const [isScroll, setIsScroll] = useState<boolean>(false)
    const [maxHeight, setMaxHeight] = useState('44px')

    const handleChange = (value: string[]) => {
        setOnChangeDoneby(value)
    
     console.log('====================================');
        console.log(handleref.current.clientHeight);
        console.log(handleref.current.clientWidth);

        console.log('====================================');
        console.log(`selected ${value}` );

        var handlerefHeight = handleref.current.clientHeight
        var handlerefWidth = handleref.current.clientWidth/4

        if(handlerefHeight > 29){
            settextAreaHeight('111px')
        }else {
            settextAreaHeight('127px')
        }
        
        // if(isScroll == true) {
        //     settextAreaHeight('111px')
        //     console.log(isScroll + " is  scrollable");
        //     setIsScroll(!isScroll)
            
        // }
        // else
        // { 
        //     settextAreaHeight('127px')
        //     console.log(isScroll + " is not scrollable");
           
            
        // }
        
    };

    var getStatusURL_true =   useFetch("api/TaskDetail/GetStatus?isActive=true")
    var getDoneBy = useFetch("api/TaskUpdate/GetDoneBy")


    const [status,status_error, status_loading] = getStatusURL_true
    const [doneby,doneby_error, doneby_loading] = getDoneBy

    // Activity Date
    const [activityDate, setActivityDate] = useState<any>('')
   

    const activityDateOnChange: DatePickerProps['onChange'] = ( date ,dateString) => {
        console.log("task start date "+dateString, );
        setActivityDate(dateString)
      };

    //   converted date
    const activityDate_con = `${dayjs(activityDate).format()}`

      // State Disable Status

    const [disable_status, setDisable_status] = useState<boolean>(true)

    //  slider percentage display

    const [inputValue, setInputValue] = useState(1);

    const onChange = (newValue: number) => {
        setInputValue(newValue);
    };
    

    

    // Data from redux
    const tskIDpk = useSelector((state:RootState)=> state.addTask.tasIdpk)
    const selectedRecord = useSelector((state:RootState) => state.addTask.selectedRecord)

    
    return (

        
        
        <div onDoubleClick={backToggler} style={{height:'100%'}} className='h-full px-1 py-1 dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base' >
                <div  className='flex justify-between w-full h-full bg-slate-50 '>
                            {/* <h4 className=" justify-start  w-32 ml-4 mb-2 bg-slate-200 ">Tasks Update</h4> */}
                                <p id='form_header3' className='w-full py-1 px-2 bg-slate-100 dark:bg-darkModeSkin-div_headers_backgroundColor dark:text-darkModeSkin-headers'>Tasks Update</p>
                                <ul className='flex items-center justify-end w-full bg-slate-100  self-end dark:bg-darkModeSkin-div_headers_backgroundColor dark:text-darkModeSkin-headers'>
                                    
                                    <li className="px-4">
                                        <CheckBox
                                        hint="toggle Update rows auto height"
                                        iconSize="16"
                                        
                                        />
                                    </li>
                                    <li className="px-4">
                                        <img className='w-4' src={locked} />
                                    </li>
                                    <li className="px-1">
                                        <img className='w-4' src={refresh} />
                                    </li>
                                    <li onClick={toggler} className="hover:cursor-pointer border-2 border-slate-200 text-xs px-6 py-1 text-gray-600 rounded-lg flex items-center"><AiOutlineSwitcher size={16}/>Switch</li>

                                </ul>
                            </div>
                            
                            {/* Form */}
                            <div style={{width:'100%'}}  className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base">
                            <Form
                            
                                    className='w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-0.5 px-2 '
                                    name="basic"
                                    // labelCol={{ span: 3}}
                                    wrapperCol={{ span: 24 }}
                                    initialValues={{ remember: true }}
                                    autoComplete="off"
                                    size='small'
                                    layout='horizontal'
                                >
                                    <Form.Item style={{width : '99%',marginBottom:'1px'}} className='mb-0.5 '>
                                        <Form.Item style={{ display: 'inline-block',marginRight : '0px', width: 'calc(30% - 0px)', }} label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{'Activity Date'}</p>} className='mb-1'>
                                            <div className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center ">
                                                <DatePicker onChange={activityDateOnChange} showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}  className='w-3/4' format={dateFormat}/>
                                            </div>

                                        </Form.Item>

                                        <Form.Item  className='mb-1 dark:bg-slate-900 dark:text-darkModeSkin-base' style={{ display: 'inline-block',marginLeft : '0', width: 'calc(70% - 0px)'}} label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{'Status'}</p>}>
                                            <div className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-start ">
                                                <Select style={{width:'200px'}} placeholder='' allowClear onChange={(value)=>{setOnChangeStatus(value)}}>
                                                    
                                               {
                                                 status?.map((option:any,index:any)=>{
                                                    return(
                                                        <option  key={option.agsOrder} value ={option.agsIDpk}>{option.agsName}</option>
                                                    )
                                                }) 
                                               }
                                                </Select>
                                                <span className='w-5  pl-1'>
                                                    <img className='w-full ' src= {refresh} alt="" />
                                                </span>
                                            </div>
                                        </Form.Item>
                                    </Form.Item>

                                        <Form.Item style={{ display:'inline-block'}} className='w-full mb-0.5' label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{'% Complete'}</p>}>
                                            <div style={{border: "1px solid #ccc", borderRadius:'6px'}} className='ml-1 mb-0.5 dark:bg-slate-100 dark:text-darkModeSkin-base flex flex-row items-center h-1/6 px-4'>
                                                <Slider  className='w-full h-3/6' dots={true}  min={0} max={100} onChange={onChange} value={typeof inputValue === 'number' ? inputValue: 0} />
                                                <span className='w-5  pr-1'>
                                                    {inputValue}%
                                                </span>
                                            </div>
                                        </Form.Item>

                                        <Form.Item  style={{ display: 'inline-block',marginBottom:'2px'}} label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{'Description '}</p>} className='w-full mb-0.5'>
                                            <div className='ml-2 dark:bg-slate-900 dark:text-darkModeSkin-base'>
                                            <TextArea onChange={(e)=>{setDescription(e.target.value)}}  style={{resize : 'none',paddingBottom:'0px',height:textAreaHeight}}  />
                                            </div>
                                        </Form.Item>

                                        <Form.Item  style={{paddingLeft:'15px', display:'inline-block'}}  label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{'Done By'}</p>} className='mb-0.5 w-full'>
                                            <div ref={handleref} className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center ml-2"  >
                                                <Select  fieldNames={{options:'100px'}} bordered={false} virtual={isScroll} style={{border:'1px solid #ccc',maxHeight:'44px',overflowY:'scroll', borderRadius:'5px',outline:'none'}}  placeholder='Please Select' allowClear mode='multiple'  onChange={handleChange}> 
                                                
                                                {
                                                 doneby?.map((option:any,index:any)=>{
                                                    return(
                                                        <option  key={option.id} value ={option.name}>{option.name}</option>
                                                    )
                                                }) 
                                               }
                                                
                                                </Select>
                                                <span className='w-5 pl-1'>
                                                    <img className='w-full h-full' src= {refresh} alt="" />
                                                </span>
                                            </div>                                                
                                        </Form.Item>

                                </Form>

                                

                            </div>

                            <div className='w-full flex px-3 flex-row items-start justify-between pb-1 '>
                                <li  className=' flex justify-start items-center '><EditButton /></li>
                                
                                <ul  className='flex items-start justify-start '>
                                    
                                        <SaveButton handleSave={async ()=>{
                                            //  alert('petbn')
                                            
                                            //  Passing the current state to redux
                                            

                                            try {
                                                
                                                    const updateSave = await axios.post(`${api_url}/api/TaskUpdate/CreateTaskUpdate`, {
                                                        "tupDate": activityDate_con,
                                                        "tupTaskIdfk": 1,
                                                        "tupUpdate": description,
                                                        "tupPercentComplete": inputValue,
                                                        "tupCreatedBy": "dolor",
                                                        "tupCompletionDate": "2023-01-30T01:37:12.838Z",
                                                        "tupUpdatedBy": "sed",
                                                        "tupStatusIdfk": onChangestatus
                                                    }).then((response)=>{
                                                        // alert('sadasda')
                                                        console.log(response);
                                                        const tupIdpk_data = response.data.data[0]
                                                        const res =  axios.put(`${api_url}/api/TaskUpdate/UpdateTaskUpdate`,{

                                                            "tupIdpk": tupIdpk_data,
                                                            "tupDate": activityDate_con,
                                                            "tupTaskIdfk": selectedRecord[0].tskIDpk,
                                                            "tupUpdate": description,
                                                            "tupPercentComplete": inputValue,
                                                            "tupEditedBy": 1,
                                                            "tupCompletionDate": "2023-01-24T13:37:34.957Z",
                                                            "tupUpdatedBy": "sed",
                                                            "tupStatusIdfk": onChangestatus,
                                                            "tupEditedDate": "2023-01-24T13:37:34.957Z"
                                                        }).then((response)=>{
                                                            console.log('taskOther ' + response);
                                                            // const tasIdpk = response.data.data[0]
                                                            // alert('added')
                                                            
                                                        })
                                                    })
                                                    console.log(JSON.parse(JSON.stringify(updateSave)))
            
                                                
                                                    


                                                    // "tupDate": activityDate_con,
                                                    // "tupTaskIdfk": selectedRecord[0].tskIDpk ,
                                                    // "tupUpdate": description,
                                                    // "tupPercentComplete": inputValue,
                                                    // "tupCreatedBy": "string",
                                                    // "tupCompletionDate": "2023-01-19T17:05:43.419Z",
                                                    // "tupUpdatedBy": "string",
                                                    // "tupStatusIdfk": onChangestatus

                                        
                                                

                                                
                                                
                                                // console.log(JSON.parse(JSON.stringify(res)))
                                                
                                                
                                            }catch (err:any) {
                                                console.log(err)
                                            }
                                        }} 
                                        /> 
                                        
                                
                                                                
                                    {disable_status? 
                                    <NewButton useCallbackFunc new_button_toggler={()=>{setDisable_status(!disable_status)}} />

                                    :
                                    <Cancel handleCallbackCancel = {()=> setDisable_status(true)} />
                                    }
    
                                </ul>
                                                
                                            

                             </div>

                    
        </div>
    )
}

