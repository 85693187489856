// PERFORMANCE


export const lock_submission_datagrid_cols = [
    {id: 1,caption : "boolean", dataField : '', dataType : "boolean", fixed : true,width:50, visible: false},
    {id: 2,caption : "EMPID", dataField : 'empIDpk', dataType : "", fixed : true,width:40},
    {id: 3,caption : "STAFF NO", dataField : 'empStaffNo', dataType : "", fixed : true,width:100},
    {id: 4,caption : "NAME", dataField : 'empName', dataType : "string", fixed : true,width:150},
    {id: 5,caption : "ASSIGNMENTS", dataField : 'astName', dataType : "string", fixed : false,width:100},
    {id: 6,caption : "SUBMITTED", dataField : 'countSubmitted', dataType : "string", fixed : false, width:100},
    {id: 7,caption : "UNSUBMITTED", dataField : 'countUnsubmitted', dataType : "string",width:100},
    {id: 8,caption : "LOCKED", dataField : 'countLocked', dataType : "boolean",width:80},
    {id: 9,caption : "JOB TITLE", dataField : 'jbtShtName', dataType : "string",width:100},
    {id: 10,caption : "DEPT", dataField : 'dptShtName', dataType : "string",width:100},
    {id: 11,caption : "SECTION", dataField : 'sxnShtName', dataType : "string",width:100},
    {id: 12,caption : "LOCATION", dataField : 'locShtName', dataType : "string",width:100},
    {id: 13,caption : "REMARKS", dataField : 'empRmks', dataType : "string",width:4000},
]

// FOR PERFORMANCE FOOTER
// FOR FOR UNIT INITIATIVE ASSIGNMENT 

export const unit_initiative_assignement_footer_datagrid_cols = [
    {id: 1,caption : "PERIOD", dataField : 'prdName', dataType : "string", fixed : true,width:110},
    {id: 2,caption : "DEPARTMENT", dataField : 'dptName', dataType : "string", fixed : true,width:110},
    {id: 3,caption : "SECTION", dataField : 'sxnName', dataType : "string", fixed : false},
    {id: 4,caption : "UNIT INITIAVE", dataField : 'untName', dataType : "string", fixed : false,width:150},
    {id: 5,caption : "ACTIVE?", dataField : 'siaActive', dataType : "boolean",fixed : false,},
    {id: 6,caption : "START DATE", dataField : 'prdStartDate', dataType : "datetime",width:150, fixed: false},
    {id: 7,caption : "END DATE", dataField : 'prdEndDate', dataType : "datetime",width:150, fixed: false},
    {id: 8,caption : "COOPERATIVE INITIATIVE", dataField : 'cinName', dataType : "string",width:150, fixed: false},
    {id: 9,caption : "OBJECTIVE", dataField : 'objShtName', dataType : "string",width:100, fixed: false},
    // {id: 10,caption : "THEME", dataField : 'THEME', dataType : "string",width:150},
    // {id: 11,caption : "MISSION/VISION", dataField : 'MISSION/VISION', dataType : "string",width:150},
    {id: 10,caption : "REMARKS", dataField : 'siaRmks', dataType : "string",width:4000},
    
]



// FOR COOPERATIVE INITIATIVE ASSIGNMENTS
export const footer_datagrid_cia_cols = [
    {id: 1,caption : "DATE", dataField : 'fddDate', dataType : "datetime", fixed : true,width:150},
    {id: 2,caption : "SERVICE STATION", dataField : 'sstName', fixed : true,width:110},
    {id: 3,caption : "PRODUCT", dataField : 'prdName', fixed : true,width:100},
    {id: 4,caption : "OPENING BALANCE", dataField : 'fddOpeningBalance',  fixed : true,width:85,format: { type: 'fixedPoint', precision: 2 }},
    {id: 5,caption : "RECEIPT", dataField : 'fddReceipt', fixed : true,width:85,format: { type: 'fixedPoint', precision: 2 }},
    {id: 6,caption : "TANK CAP.", dataField : 'fddTankCapacity',format: { type: 'fixedPoint', precision: 2 },fixed : true,width:85},
    {id: 7,caption : "ISSUE", dataField : 'fddIssue',width:85,format: { type: 'fixedPoint', precision: 2 }},
    {id: 8,caption : "CLOSING BALANCE", dataField : 'fddClosingBalance',width:85,format: { type: 'fixedPoint', precision: 2 },},
    {id: 9,caption : "OPENING MET.READING", dataField : 'fddOpeningMeterReading',width:85,format: { type: 'fixedPoint', precision: 2 },},
    {id: 10,caption : "CLOSING MET.READING", dataField : 'fddClosingMeterReading',width:85,format: { type: 'fixedPoint', precision: 2 },},
    {id: 11,caption : "OPENING DIP BAL", dataField : 'fddOpeningDipBalance',width:90,format: { type: 'fixedPoint', precision: 2 },},
    {id: 12,caption : "ACTIVE", dataField : 'fddActive', dataType : "boolean",},
    {id: 13,caption : "REMARKS", dataField : 'fddRmks', dataType : "string",width:4000},
]




// PERSPECTIVES
// FOR PERSPECTIVE FOOTER DATAGRID


// FOR MEASURES AND TARGETS
export const measure_target_footer_datagrid_cols = [
    {id: 1,caption : "NAME", dataField : 'NAME.', dataType : "string", fixed : true,width:120},
    {id: 2,caption : "SHORT NAME", dataField : 'SHORT NAME.', dataType : "string", fixed : true,width:120},
    {id: 3,caption : "ACTIVITY?", dataField : 'ACTIVITY?', dataType : "boolean", fixed : true,width:60},
    {id: 4,caption : "REMARKS", dataField : 'REMARKS.', dataType : "string", fixed : true,width:4000},
    
]

// FOR COOPERATE INITIATIVE



// FOR COOPERATE INITIATIVE
export const cooperate_initiatve_footer_datagrid_cols = [
    {id: 1,caption : "INITIATIVE", dataField : 'cinName', dataType : "string", fixed : true,width:120},
    {id: 2,caption : "SHORT NAME", dataField : 'cinShtName', dataType : "string", fixed : true,width:120},
    {id: 3,caption : "ACTIVITY?", dataField : 'cinActive', dataType : "boolean", fixed : true,width:60},
    {id: 4,caption : "PERSPECTIVE", dataField : 'PERSPECTIVE.', dataType : "string", fixed : true,width:120},
    {id: 5,caption : "REMARKS", dataField : 'cinRmks', dataType : "string", fixed : true,width:4000},
    
]

// FOR STRATEGIC THEMES
export const strategic_themes_footer_datagrid_cols = [
    {id: 1,caption : "THEME", dataField : 'thmName', dataType : "string", fixed : true,width:null},
    {id: 2,caption : "COMMENTARY", dataField : 'thmCommentary', dataType : "string", fixed : true,width:null},
    {id: 3,caption : "MISSION", dataField : 'mvsMission', dataType : "string", fixed : true,width:120},
    {id: 4,caption : "VISION", dataField : 'mvsVision', dataType : "string", fixed : true,width:120},
    {id: 5,caption : "ORDER", dataField : 'thmOrder', dataType : "string", fixed : true,},
    {id: 6,caption : "ACTIVE?", dataField : 'thmActive', dataType : "boolean", fixed : true,},
    {id: 7,caption : "REMARKS", dataField : 'thmRmks', dataType : "string", fixed : true,width:4000},
    
    
]



// FOR MEETING SCHEDULES will be changed to something in performance
export const footer_meeting_schedules_datagrid_cols = [
    {id: 1,caption : "ID?", dataField : 'ID?', dataType : "number", fixed : true,width:50},
    {id: 2,caption : "SUB?", dataField : 'SUB?', dataType : "boolean", fixed : true,width:100},
    {id: 3,caption : "ACKN?", dataField : 'ACKN?', dataType : "boolean", fixed : true,width:100},
    {id: 4,caption : "CALENDER", dataField : 'CALENDER', dataType : "number", fixed : true,width:100},
    {id: 5,caption : "DATE", dataField : 'DATE', dataType : "date",format:"dd MMM yyyy", fixed : true,width:100,},
    {id: 6,caption : "GROUP", dataField : 'GROUP', dataType : "string", fixed : true,width:100},
    {id: 7,caption : "RESOURCE PERSON", dataField : 'RESOURCE PERSON', dataType : "string",fixed : true,width:160},
    {id: 8,caption : "ACTIVE?", dataField : 'ACTIVE?', dataType : "boolean",fixed : true,width:60},
    {id: 9,caption : "TOPIC", dataField : 'TOPIC ', dataType : "string",width:100},
    {id: 10,caption : "WHY TOPIC IS CHOSEN", dataField : 'WHY TOPIC IS CHOSEN', dataType : "string",width:200},
    {id: 11,caption : "SUBMITTED BY", dataField : 'SUBMITTED BY', dataType : "string",width:100},
    {id: 12,caption : "REMARKS", dataField : 'REMARKS', dataType : "string", width:4000},
]

{/* <Column  dataField="LOCKED?"  fixed={true} width={60} cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataType='boolean'></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="DATE" fixed={true} dataType='date' width={100} format={"dd MMM yyyy"} ></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="%COMPLETE" fixed={true} cellRender={progressBar}></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="TASK UPDATE"  dataType='string' ></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="UPDATED BY" dataType='string' ></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="JOB TITLE" dataType='string'></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="UPDATER" dataType='string' width={100}></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="CREATED ON " dataType='string'></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="COMPLETION DATE" dataType='string'></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="REMARKS" dataType='string' width={4000} ></Column> */}


        