import { Form, Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useState, useEffect } from "react";
import save from '../../../../assets/floppy-disk.png';
import newIcon from '../../../../assets/new.png'; 
import { useDispatch, useSelector } from "react-redux";
import axios from "axios"; 
import { RootState } from "../../../../app/store";
import { settransDetailsValue } from "../../../../features/forms/entries/safetyManager/ppeRequest";
import { navbar_height, toolbar_height, api_url, saveModalData } from "../../../accessories/component_infos";
import { formPropsConst } from "../../../accessories/constants";
import { ImageUpload } from "./imageUpload";
import { ModalTemplate } from "../../../../templates/modal";

interface props {
   toggled?: boolean

}
interface stateTypes{
   latitude: string,
   longitude: string,
   remrk: string
}

const GPSDetailsTab = ({ toggled }: props) => {

   const [states, setStates] = useState<stateTypes>({
      latitude: '',
      longitude: '',
      remrk: ''
   })
   const updateState = (key: string, value: any) => {
      setStates((prev) => ({ ...prev, [key]: value }));
    };
   const news_flash_height = useSelector((state: RootState) => state.general.newsflashHeight);
   interface props {
      toggled?: boolean

   }
   const sp = '\u00a0';

   const heights_out = navbar_height + news_flash_height + toolbar_height;
   const [openModal, setOpenModal] = useState(false);
   const [modalData, setModalData] = useState<any>(saveModalData);
   const [icon, setIcon] = useState<"warning" | "success" | "question">();
   //varying form height
   const form_height = 490;

   //Table Height
   const pageHeight = window.innerHeight - navbar_height; //height without navbar
   const [tableHeight, settableHeight] = useState(window.innerHeight - (heights_out + 70)); //height without navbar and toolbar
   const [table_form_Height, settable_form_Height] = useState(window.innerHeight - (heights_out)); //height without navbar and toolbar and form

   const [showTable, setShowTable] = useState(true);
   const dateFormat = " DD MMM YYYY HH: mm:ss";

   const dispatch = useDispatch()

   // Onchange state change
  
   // Update Form
const validate = () => {
   if(states.latitude === '' ){
      setModalData({
         title: 'Error',
         message: 'Please input data entry for latitude',
         okText: 'Ok',
         cancelText: 'Cancel'
       })
       setIcon('warning')
       setOpenModal(true)
}
else if( states.longitude === ''){
   setModalData({
      title: 'Error',
      message: 'Please input data entry for longitude',
      okText: 'Ok',
      cancelText: 'Cancel'
    })
    setIcon('warning')
    setOpenModal(true)
}
}


   useEffect(() => {
      console.log(`inside ${toggled}`)
   }, [toggled])

   const { Option } = Select;
   return (
      <div>
          <ModalTemplate
        icon={icon}
        disableCancel={icon === "warning"?true : false}
        cancelText={modalData.cancelText}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        open={openModal}
        okHandler={() => {
         
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
      />
         {<div className="  rounded-md">

            {/* The form */}
            <div className=" flex ">
               <Form {...formPropsConst} labelCol={{ span: 2 }} wrapperCol={{ span: 22 }}
                  className="ml-2 my-2 " style={{ width: '100%' }}
               >


                  <Form.Item label={<p className='text-xs'>{'Latitude'}</p>} style={{ marginBottom: 0 }}>
                     <Form.Item
                        name="issuedBy"

                        style={{ display: 'inline-block', width: 'calc(30%)' }}
                     > <div className="flex flex-row items-center">
                           <Input style={{ marginBottom: 0 }} onChange={(e: any) => { updateState("latitude",e.target.value)}} className="" placeholder="161kV" />

                        </div>
                     </Form.Item>
                  </Form.Item>



                  <Form.Item label={<p className='text-xs'>{'Longitude'}</p>} style={{ marginBottom: 0 }}>
                     <Form.Item
                        name="issuedBy"

                        style={{ display: 'inline-block', width: 'calc(30%)' }}
                     > <div className="flex flex-row items-center">
                           <Input style={{ marginBottom: 0 }} onChange={(e: any) => { updateState("longitude",e.target.value) }} className="" placeholder="" />

                        </div>
                     </Form.Item>
                  </Form.Item>



                  <Form.Item label={<p className='text-xs'>{'Remarks'}</p>} style={{ marginBottom: 0, marginTop: '2px' }}>
                     <Form.Item
                        name="issuedBy"

                        style={{ display: 'inline-block', width: 'calc(100%)' }}
                     > <div className="flex flex-row items-center">
                           <TextArea style={{ resize: 'none', height: '220px' }} onChange={(e: any) => {updateState("remrk",e.target.value) }} className='w-full h-full' />


                        </div>
                        <div className='flex  justify-end mt-7 '>

                           <button className='dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center   px-2 py-0.5 border-2 border-slate-200 rounded'
                              onClick={() => {
                                 validate()
                              }}
                           >
                              <img className='pr-1' src={save} />Update
                           </button>

                        </div>
                     </Form.Item>
                  </Form.Item>






               </Form>





            </div>


         </div>}
      </div>
   );
}

export default GPSDetailsTab;