import { Select } from "antd";
import { useState } from "react";

import {
  navbar_height,
  toolbar_height,
} from "../../../../../../accessories/component_infos";
import {
  vehicle_requests_security_TH,
} from "../data/vehicle-requests-security-data";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import  Datagrid_template from "../../../../../../../templates/Datagrid";

const { Option } = Select;

interface props {
  toggled?: boolean;
  data: any;
}

const VehicleRequestsSecurityDetails = ({ toggled, data }: props) => {
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const heights_out = navbar_height + news_flash_height + toolbar_height;
  //Table Height
  const [tableHeight, settableHeight] = useState(
    window.innerHeight - (heights_out + 10)
  ); //height without navbar and toolbar
  const [table_form_Height, settable_form_Height] = useState(
    window.innerHeight - heights_out
  ); 

  return (
    <div>
      <style
        dangerouslySetInnerHTML={{
          __html: `
           input[type="text"]:disabled {
            background: #fffbeb;
          }  
          
          .dx-datagrid-header-panel .dx-toolbar {
            margin-bottom: 1.5px;
        }

          .filterTodos .dx-datagrid-headers{
              margin-top: 10px;
          }

            `,
        }}
      />
   <div className="w-full ">
      <div className="pt-0.5">
        <Datagrid_template
          gridheight={tableHeight - 30}
          columns={vehicle_requests_security_TH}
          data={data}

        />
      </div>
    </div>
    </div>
  );
};

export default VehicleRequestsSecurityDetails;
