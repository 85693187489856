import { Checkbox, DatePicker, Form, Input, Select } from "antd";
import reset from '../../../../../assets/reset.png';
import TextArea from "antd/es/input/TextArea";
import { BottomBtns } from "../../../../../templates/butttons";
import { InputsTemplate } from "../../../../../templates/input";
import { TextareaTemplate } from "../../../../../templates/textarea";
import SetupsDatalistTemplat from "../../../../human-resource/setups/data/setups_datalist_template";
import { modalPropTypes, modalTypes, selectsTypes, validateInstanceTypes } from "../../../../human-resource/setups/data/types/selectsTypes";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setGeneralValue } from "../../../../../features/generalSlice";
import axios from "axios";
import { api_url, saveModalData, updateModalData } from "../../../../accessories/component_infos";
import { ModalTemplate } from "../../../../../templates/modal";
import { useValidateExist } from "../../../../../hooks/useValidateExists";
import { UpdateDataFunc } from "../../../../../functions/update";
import { PostDataFunc  } from "../../../../../functions/post";
import { useAccountInfo } from "../../../../../hooks/useAccountInfo";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";

interface props{
    busyloader:(text?:string)=>void,
    refreshGrid:()=>void
    clearSelected:()=>void
    selectedField:any
    data:any
}
interface stateTypes{
    name:string,
    interpretation:string,
    shtInterpretation:string,
    grade:any,
    order:number,
    active:boolean,
    remarks:string

    selectedRecordId:number,
    confirmModal:modalPropTypes
    warningModal:modalPropTypes
    successModal:modalPropTypes
}

export const Ratings_form = ({busyloader,clearSelected,refreshGrid,data,selectedField}:props) => {
    const validateExists = useValidateExist();
    const [employeeId, userId] = useAccountInfo();
    const borderTheme = useSelector((state:RootState)=>state.general.cssBorderClass)

    const postUrl:string = 'Ratings/CreateRating'
    const updateUrl:string = 'Ratings/UpdateRating'

    useEffect(()=>{
        populateStatesFromGrid(false);
    },[selectedField])

    const dispatch = useDispatch();

    const[states,setStates]=useState<stateTypes>({
        name:'',
        interpretation:'',
        shtInterpretation:'',
        grade:'',
        order:0,
        active:false,
        remarks:'',

        selectedRecordId:0,

        confirmModal:{state:false,title:'',message:'',action:'post'},
        warningModal:{state:false,title:'',message:''},
        successModal:{state:false,title:'',message:''}
    })


    const{name,interpretation,shtInterpretation,selectedRecordId,order,grade,active,remarks,confirmModal,warningModal,successModal}=states;

    const updateState=(stateName:string,value:string|modalPropTypes|boolean|number):void=>{ //update single state
        setStates((prev)=>({...prev,[stateName]:value}));
    }

    const disableConfirmModal=()=>{
        updateState('confirmModal',{
            state:false,
            title:'',
            message:'',
            action:'post'
        })
    }

    const enableSuccessModal=(titleActionText:string,messageActionText:string)=>{
        updateState('successModal',{
            state:true,
            title:`${titleActionText} Successful`,
            message:`Record ${messageActionText} successfully`,
        })
    }

    //validate fields
    const validateFields=():boolean=>{
        const validationInstances:validateInstanceTypes[]=[
            {state:name,baseValue:'',modalProps:{state:true,title:'Enter name',message:'Please enter a name'}},
            {state:name,baseValue:'',modalProps:{state:true,title:'Enter name',message:'Please enter a name'}},
            {state:interpretation,baseValue:'',modalProps:{state:true,title:'Enter interpretation',message:'Please enter interpretation'}},
            // {state:weight,baseValue:0,modalProps:{state:true,title:'Enter % weight',message:'Please enter % weight'}},
        ]

        for(let instance of validationInstances) if (instance.state===instance.baseValue) {
            const{modalProps}=instance;
            updateState('warningModal',{
                state:modalProps.state,
                title:modalProps.title,
                message:modalProps.message
            })
            return false;
        }

        if (typeof(name) !== 'number') {
            updateState('warningModal',{
                state:true,
                title: 'Invalid Rating Entry',
                message: 'Rating must be a number!'
            })
            return false;
        }

        return true;
    }

    const disableFields=()=>{
        //disable Update Mode
        dispatch(setGeneralValue({expr: 'updateMode' ,value : false}));

        //disable forms
        dispatch(setGeneralValue({expr: 'formDisabled' ,value : true}));
    }

    const populateStatesFromGrid=(clearFields?:boolean)=>{
        console.log(selectedField);
        const run=()=>{
            const populateInstances=[
                {state:'name',value:clearFields?'':selectedField?.rtnRate},
                {state:'interpretation',value:clearFields?'':selectedField?.rtnInterpretation},
                {state:'shtInterpretation',value:clearFields?'':selectedField?.rtnShtInterpretation},
                {state:'order',value:clearFields?0:selectedField?.rtnOrder},
                {state:'grade',value:clearFields?'':selectedField?.rtnGrade},
                {state:'active',value:clearFields?true:selectedField?.rtnActive},
                {state:'remarks',value:clearFields?'':selectedField?.rtnRmks},
                {state:'selectedRecordId',value:clearFields?0:selectedField?.rtnIdpk},
            ]
    
            for(let instance of populateInstances) updateState(instance.state,instance.value);
        }
        Object.keys(selectedField).length !== 0 && run();
    }

    //post data
    const postData=async(action:'validate'|'post')=>{
        if (action==='validate') {
            // validate fieldds
            validateFields() &&
            await validateExists(`Ratings/GetNameShortName?name=${interpretation}&shortname=${shtInterpretation}`) &&
            updateState('confirmModal',{
                state:true,
                title:'Save Record',
                message:'Are you sure you want to save this record?',
                action:'post'
            })
            return;
        }

        //post
        disableConfirmModal();
        busyloader(saveModalData.progress);

        try {
            const postResponse = await PostDataFunc (`${postUrl}`,{
                "rtnRate": name,
                "rtnInterpretation": interpretation,
                "rtnShtInterpretation": shtInterpretation,
                "rtnGrade": grade,
                "rtnOrder": order,
                "rtnActive": active,
                "rtnRmks": remarks,
                "rtnCreatedBy": userId.toString(),
            })

            postResponse.status===202 && enableSuccessModal('Save','saved')
            disableFields();
            refreshGrid();
            clearSelected()
            populateStatesFromGrid(false);
        } catch (error) {
            console.log(error);
            
            updateState('warningModal',{
                state: true,
                title: saveModalData.error.title,
                message: saveModalData.error.message
            })
        } finally{
            busyloader('');
        }
    }

    //update data
    const update=async(action:'validate'|'update')=>{
        console.log(states);
        
        if (action==='validate') {
            // validate fieldds
            validateFields()&&
            await validateExists(`Ratings/GetNameShortName?name=${interpretation}&shortname=${shtInterpretation}`,selectedRecordId,'rtnIdpk') &&
            updateState('confirmModal',{
                state:true,
                title:'Update Record',
                message:'Are you sure you want to update this record?',
                action:'update'
            })
            return;
        }

        //update
        disableConfirmModal();
        busyloader(updateModalData.progress);

        try {
            const updateResponse = await UpdateDataFunc(`${updateUrl}/${selectedRecordId}`,{
                "rtnRate": name,
                "rtnInterpretation": interpretation,
                "rtnShtInterpretation": shtInterpretation,
                "rtnGrade": grade,
                "rtnActive": active,
                "rtnOrder": order,
                "rtnRmks": remarks,
                "rtnEditedBy": userId,
            })

            enableSuccessModal('Update','updated')
            disableFields();
            refreshGrid();
            clearSelected();
            populateStatesFromGrid(false);

        } catch (error) {
            console.log(error);

            updateState('warningModal',{
                state:true,
                title:updateModalData.error.title,
                message:updateModalData.error.message
            })
        } finally{
            busyloader('')
        }
        return;
    }

    // modal instances
    const modals:modalTypes[] = [
        //validation
        {disableCancel : true , icon : 'warning' ,open : warningModal.state, okHandler : ()=>updateState('warningModal',{state:false,title:'',message:''}), cancelHandler : ()=> updateState('warningModal',{state:false,title:'string',message:'string'}), message : warningModal.message, okText : 'Ok', title : warningModal.title,cancelText : 'No, cancel'},
        
        //confirmation
        {disableCancel : false , icon : 'question' ,open : confirmModal.state, okHandler : confirmModal.action==='update'?()=>update('update'):()=>postData('post'), cancelHandler : ()=> updateState('confirmModal',{state:false,title:'string',message:'string'}), message : states.confirmModal.message, okText : confirmModal.action==='update' ? 'Yes, Update':'Yes, Save', title : states.confirmModal.title,cancelText : 'No, cancel'},

        //success
        {disableCancel : true , icon : 'success' ,open : successModal.state, okHandler : ()=>updateState('successModal',{state:false,title:'',message:''}), cancelHandler : ()=> updateState('successModal',{state:false,title:'string',message:'string'}), message : successModal.message, okText : 'Ok', title : successModal.title,cancelText : 'No, cancel'},
    ]

    const datalists: selectsTypes[] = [
        {id: 0 , label : 'Rating' ,optionsData : data,refresh : true, stateName: 'name', idExpr: 'rtnIdpk', dataExpr: 'rtnRate', defaultValue:name},
        {id: 1 , label : 'Interpretation' ,optionsData : data,refresh : false, stateName: 'interpretation', idExpr: 'rtnIdpk', dataExpr: 'rtnInterpretation', defaultValue:interpretation},
        {id: 2 , label : 'Short Interpretation' ,optionsData : data,refresh : false, stateName: 'shtInterpretation', idExpr: 'rtnIdpk', dataExpr: 'rtnShtInterpretation', defaultValue:shtInterpretation},
    ]

    return (
        <>
            {modals.map(({disableCancel,icon,okHandler,cancelHandler,open,cancelText,okText,message,title}:modalTypes,index)=>{
                return(
                    <div key = {index}>
                        <ModalTemplate  icon= {icon && icon} disableCancel = {disableCancel} cancelText = {cancelText && cancelText} open = {open} okHandler={okHandler} cancelHandler={cancelHandler} message= {message} okText= {okText} title={title}/>
                    </div>
                )
            })}

            <div className="px-2">
                {/* FORM HEADER */}
                <p  style={{borderColor: borderTheme}}id={'form_header'} className='pt-1 border-[1px] border-b-0 px-2 w-full  font-semibold'>{'Rating Details'}</p>
                <Form
                    style={{borderColor: borderTheme}}
                    className='w-full border-[1px] border-slate-100 rounded flex justify-between py-1 px-2 '
                    name="basic"
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 19 }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    size='small'
                >
                    <section  className="" style={{width: '550px'}} >
                        {/* datalists */}
                        {datalists.map((datalist, index)=>{
                            return(
                                <div key={index} className=" mb-1">
                                    <SetupsDatalistTemplat 
                                    useCallbackFunc
                                    inputType={index === 0 ? 'number' : 'text'}
                                    datalistStyle={{height: 24}}
                                    selectedData={datalist.defaultValue}
                                    setCallbackValue={(value)=>{
                                        updateState(datalist.stateName!,index === 0 ? value : value.toString());

                                        datalist.stateName === 'interpretation' && updateState('shtInterpretation', value.toString());
                                    }}
                                    listid={datalist.label}
                                    idExpr={datalist.idExpr}
                                    nameExpr={datalist.dataExpr}
                                    refresh={datalist.refresh} 
                                    label = {datalist.label} 
                                    options = {datalist.optionsData}
                                />
                                </div>
                            )
                        })}


                        <div className=" mb-1">
                            <InputsTemplate 
                                span 
                                label = {'Grade'} 
                                useCallbackFunc
                                defaultValue={grade}
                                inputStyle={{height: 24}}
                                orderOnchange={(value)=>{
                                    updateState('grade',value)
                                }}
                            />
                        </div>

                       <div className=" mb-1">
                            <InputsTemplate 
                            inputType='number'
                            span 
                            label = {'Order'}
                            useCallbackFunc
                            defaultValue={order}
                            inputStyle={{height: 24}}
                            orderOnchange={(value)=>{
                                updateState('order',value)
                            }}
                            />
                       </div>

                        {/* checkbox and butons */}
                        <div className = 'mt-0.5'>
                            <BottomBtns 
                            span
                            defaultValue={active}
                            onNewClick={()=>{
                                populateStatesFromGrid(true)
                            }}
                            addActiveBorders
                            saveHandler={()=>postData('validate')}
                            updateHandler={()=>update('validate')}
                            useCallbackFunc
                            checkboxChange={(value)=>updateState('active',value)}
                                
                            />
                        </div>
                    </section>

                    {/* remarks */}
                    <section className=" " style={{width : 'calc(100% - 550px)'}}>
                        <TextareaTemplate 
                            MarginLeft={40}
                            useCallbackFunc
                            setCallbackValue={(value)=>{
                                updateState('remarks',value);
                            }}
                            defaultValue={remarks}
                            height = {170} 
                            label = {'Remarks'}
                        />
                    </section>
                </Form>
            </div>
        </>
    );
}
 