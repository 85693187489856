import React, { useState, useCallback, useRef } from 'react'
import { Form, Input, Select, DatePicker, Checkbox } from 'antd';
import { CheckBox } from 'devextreme-react/check-box';

import { Cancel, NewButton, SaveButton, SignOff, UpdateButton } from '../../../../../accessories/buttons';
import { NameTheMeasure_Input, NameTheMeasure_Input2, NameTheMeasure_TextArea, NameTheMeasure_mini_input } from '../data/Performance_inputs';
import { NameTheMeasure_Select, NameTheMeasure_mini_select } from '../data/Performance_selects';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../app/store';
import { inputsTypes, selectsTypes } from '../../../../../human-resource/setups/data/types/selectsTypes';
import { SelectsTemplate } from '../../../../../../templates/select';
import { InputsTemplate } from '../../../../../../templates/input';
import { TextareaTemplate } from '../../../../../../templates/textarea';




export const NameTheMeasure_Form_Details = () => {
    const { Option } = Select;
    const { TextArea } = Input;

    // import { AssignedDept, AssignedLocation, AssignedSelection, AssignedUnit, Batch, Group, PPE_Select, RequestWindow, StaffName } from './PPE_selects';

    // from redux
    const form_disable = useSelector((state: RootState) => state.general.formDisabled)
    const update_state_change = useSelector((state: RootState) => state.general.taskFooterUpdate)
    const borderTheme = useSelector((state: RootState) => state.general.cssBorderClass)

    const options = [];
    for (let i = 1; i < 36; i++) {
        options.push({
            label: i.toString(36) + i,
            value: i.toString(36) + i,
        });
    }

    const [textAreaHeight, settextAreaHeight] = useState<string>('75px')

    const handleChange = (value: string[]) => {
        console.log(`selected ${value}`);
        if (value.length > 7) {
            settextAreaHeight('60px')
        } else {
            settextAreaHeight('71px')
        }
    };
    const dateFormat = " DD MMM YYYY HH: mm:ss";




    const [tasks_update_state, setTask_update_state] = useState<Boolean>(true)
    const [tasks_update_state_width, setTask_update_state_width] = useState<string>('100%')
    const [tasks_update_col_width, setTask_update_col_width] = useState<number>(100)
    const [tasks_update_col_height, setTask_update_col_height] = useState<number>()
    const [task_datagrid_width, set_task_datagrid_width] = useState(`calc(100% - ${'550px'})`);
    const [AppraiseisActive, AppraisesetIsActive] = useState<boolean>(false)



    const taskDetailsFormHeight = useRef(null);

    // const [heightHook, setheightHook] = useState<any>()

    // const refHook = (e:any) =>{
    //     if(e.length = 1){
    //         console.log("true");

    //     }
    // }

    // const refHook = ()=>{


    //     console.log(taskDetailsFormHeight);
    //     console.log('====================================');
    // }

    const selects: selectsTypes[] = [
        { id: 0, label: "Mission/Vision", dataExpr: "", idExpr: "", optionsData: [], defaultValue: "", stateName: "", style: "" },
        { id: 1, label: "Theme", dataExpr: "", idExpr: "", optionsData: [], defaultValue: "", stateName: "", style: "" },
        { id: 2, label: "Objective", dataExpr: "", idExpr: "", optionsData: [], defaultValue: "", stateName: "", style: "" },
    ]

    const selects1: selectsTypes[] = [
        { id: 0, label: "Unit of Measure", dataExpr: "", idExpr: "", optionsData: [], defaultValue: "", stateName: "", style: "" },
        { id: 1, label: "Unit", dataExpr: "", idExpr: "", optionsData: [], defaultValue: "", stateName: "", style: "" },
    ]

    const inputs1: inputsTypes[] = [
        { id: 0, label: "Min. Value", stateName: "", defaultValue: "", style: "" },
        { id: 1, label: "Max. Value", stateName: "", defaultValue: "", style: "" },
    ]

    return (

        <div style={{ width: '900px', minWidth: '900px', height: '100%', borderColor: borderTheme }} className="  border-[1px]  dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base  ">
            {/* <h4 className=" w-full  mb-2 bg-slate-100  ">Task Details</h4> */}
            <div style={{ maxHeight: '295px', minHeight: '295px', height: '295px' }} className=' items-start justify-start '   >

                <Form
                    className='w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 px-2 '
                    name="basic"
                    labelCol={{ span: 3 }}
                    // wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    size='small'
                    layout='horizontal'

                >

                    {selects.map(({ id, label, dataExpr, defaultValue, stateName, style, optionsData, idExpr }) => {
                        return (
                            <>
                                <SelectsTemplate
                                    label={label}
                                    placeHolder={defaultValue}
                                    options={optionsData}
                                    dataexp={dataExpr}
                                    idexpr={idExpr}
                                    useCallFunc
                                    selectedValue={() => { }}
                                />
                            </>
                        )
                    })}

                    <InputsTemplate
                        label='Perf. Measure'
                        placeholder=''
                        id=''
                        expr=''
                        nameExpr=''
                        useCallbackFunc
                        orderOnchange={() => { }}
                    />

                    <Form
                        // className='w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 px-2 '
                        name="basic"
                        labelCol={{ span: 6 }}
                        // wrapperCol={{ span: 24 }}
                        initialValues={{ remember: true }}
                        autoComplete="off"
                        size='small'
                        layout='horizontal'
                    >
                        <div className='w-full flex justify-between'>

                            <div className='w-1/2 justify-start'>
                                {selects1.map(({ id, label, dataExpr, defaultValue, stateName, style, optionsData, idExpr }) => {
                                    return (
                                        <>
                                            <SelectsTemplate
                                                label={label}
                                                placeHolder={defaultValue}
                                                options={optionsData}
                                                dataexp={dataExpr}
                                                idexpr={idExpr}
                                                useCallFunc
                                                selectedValue={() => { }}
                                            />
                                        </>
                                    )
                                })}
                            </div>
                            <div className='w-1/2 justify-start'>
                                {inputs1.map(({ id, label, defaultValue, stateName, style }) => {
                                    return (
                                        <>
                                            <InputsTemplate
                                                label={label}
                                                placeholder=''
                                                id=''
                                                expr=''
                                                nameExpr=''
                                                useCallbackFunc
                                                orderOnchange={() => { }}
                                            />
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                    </Form>

                    <InputsTemplate
                        label='Target'
                        placeholder=''
                        id=''
                        expr=''
                        nameExpr=''
                        useCallbackFunc
                        orderOnchange={() => { }}
                    />

                    <TextareaTemplate
                        label='Remarks'
                        defaultValue=''
                        idExpr=''
                        dataExpr=''
                        useCallbackFunc
                        height={90}
                        setCallbackValue={()=>{}}
                        MarginLeft={51}
                    />

                    {/* <NameTheMeasure_Select /> */}
                    {/* <NameTheMeasure_Input /> */}
                    {/* <Form.Item>
                                        <div className='flex w-full '>
                                            <div  className='w-6/12 justify-start'>
                                                <NameTheMeasure_mini_select />
                                            </div>
                                            <div  className='w-3/6 justify-start'>
                                                <NameTheMeasure_mini_input />   
                                            </div>

                                            
                                        </div>
                                    </Form.Item> */}
                    {/* <NameTheMeasure_Input2 />
                    <NameTheMeasure_TextArea /> */}


                    {/* <Group />
                                            <StaffName />
                                            <RequestWindow />
                                            <Batch />
                                            <AssignedDept />
                                            <AssignedSelection />
                                            <AssignedUnit />
                                            <AssignedLocation /> */}
                    <div className='w-full  flex flex-col mt-0.5 '>
                        <div style={{ width: 'calc(900px - 40px)', marginLeft: '19px' }} className=' flex  justify-between  '>
                            {/* <li className='flex justify-start '><Cancel/></li> */}
                            <ul className='flex justify-evenly ml-10'>
                                <li className=' flex items-center flex-row pr-2'><p className='mr-3'>Order :</p> <Input className='w-24' /></li>
                                <li className=' flex items-center flex-row'><p className='mr-3'>Activity? : </p>  <Checkbox style={{borderColor: borderTheme}} className='border-[1px] px-1 rounded' /></li>
                            </ul>
                            <ul className='flex justify-end  '>
                                {
                                    (form_disable === false && update_state_change === false) ?
                                        <SaveButton handleSave={() => { }} />
                                        :
                                        (update_state_change === true) ?

                                            <UpdateButton handleUpdate={() => { }} />

                                            :
                                            <SaveButton />

                                    // useCallbackFunc new_button_toggler={()=>{setform_disable(!form_disable)}}
                                }
                                {(form_disable === true) ?
                                    <NewButton new_button_toggler={() => { }} />

                                    :
                                    (update_state_change === true) ?
                                        <Cancel handleCallbackCancel={() => { }} />
                                        :
                                        !(update_state_change && form_disable) ?
                                            <Cancel handleCallbackCancel={() => { }} />

                                            :
                                            <NewButton new_button_toggler={() => { }} />
                                }
                            </ul>
                        </div>
                    </div>
                </Form>
            </div>



        </div>
    )
}