/* eslint-disable react/jsx-pascal-case */
import React, { useState } from "react";
import { Form } from "antd";
import { checkboxTypes } from "../../human-resource/setups/data/types/selectsTypes";
import { CheckboxTemlate } from "../../../templates/checkbox";
import { NewButton, SaveButton } from "../../accessories/buttons";
import { useDispatch } from "react-redux";
import { setGeneralValue } from "../../../features/generalSlice";
import { useModalData } from "../../accessories/component_infos";
import { ModalTemplate } from "../../../templates/modal";
import { PostDataFunc } from "../../../functions/post";
import {
  advertFormPrompt,
  advertFormRes,
  loggingPrompt,
  pwdPolicyPrompt,
  pwdPolicyRes,
  saveEditAuditLogPrompt,
  saveNewRecordAuditLogPrompt,
  saveSearchAuditLogPrompt,
} from "./data/sysSettingsData";
import useFetch from "../../../hooks/useFetch";

const SysSettings = () => {
  const dispatch = useDispatch();
  const [modal, setModal] = useModalData();

  const [Mutate, setMutate] = useState(false);

  const [auditLogBoolean] = useFetch("SystemAdminSettings/GetSysAdminSettings?SettingCode=Enable Audit Logs", Mutate);
  const [searchAuditLogBoolean] = useFetch("SystemAdminSettings/GetSysAdminSettings?SettingCode=Save Search Audit Log", Mutate);
  const [pwdPolicyBoolean] = useFetch("SystemAdminSettings/GetSysAdminSettings?SettingCode=Enforce Password Policy", Mutate);
  const [advertFormBoolean] = useFetch("SystemAdminSettings/GetSysAdminSettings?SettingCode=Show Advert Form", Mutate);
  const [saveNewRecordBoolean] = useFetch("SystemAdminSettings/GetSysAdminSettings?SettingCode=Save New Record Audit Log", Mutate);
  const [saveEditBoolean] = useFetch("SystemAdminSettings/GetSysAdminSettings?SettingCode=Save Edit Audit Log", Mutate);

  

  const checkboxes: checkboxTypes[] = [
    {
      id: 0,
      label: "Enable Audit Logs",
      label2: "Save Search Audit Log",
      inlineDefaultValue: [auditLogBoolean, searchAuditLogBoolean],
      inlineStateName: ["Enable Audit Logs", "Save Search Audit Log"],
      setCheckboxOnchange: (v: boolean, index: any) => {
        index === "Enable Audit Logs"
          ? handleLogginPrompt(v)
          : handleSearchAuditLogPrompt(v);
      },
    },
    {
      id: 1,
      label: "Enforce Password Policy",
      label2: "Save Edit Audit Log",
      inlineDefaultValue: [pwdPolicyBoolean, saveEditBoolean],
      inlineStateName: ["Enforce Password Policy", "Save Edit Audit Log"],
      setCheckboxOnchange: (v: boolean, index: any) => {
        index === "Enforce Password Policy"
          ? handlePwdPolicyPrompt(v)
          : handleSaveEditAuditLogPrompt(v);
      },
    },
    {
      id: 2,
      label: "Show Advert Form",
      label2: "Save New Record Audit Log",
      inlineDefaultValue: [advertFormBoolean, saveNewRecordBoolean],
      inlineStateName: ["Show Advert Form", "Save New Record Audit Log"],
      setCheckboxOnchange: (v: boolean, index: any) => {
        index === "Show Advert Form"
          ? handleAdvertFormPrompt(v)
          : handleNewRecordAuditLogPrompt(v)
      },
    },

  ];


  const closeModal = () => {
    setModal((prev) => ({
      ...prev,
      state: false,
    }));
  };

  const handleAuditLog = async (statusValue: boolean) => {
    try {
      const url = `SystemAdminSettings/SetSysAdminSettings?SettingCode=${`Enable Audit Logs`}&StlValue=${statusValue}`;
      const res = await PostDataFunc(url, {});
      if (res.status === 200) {
        setMutate((prev) => !prev)
        
        handleLogginRes(statusValue);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleLogginPrompt = (status: boolean) => {
    setModal({
      ...loggingPrompt(status ? "Enabled" : "Disabled"),
      okHandler: () => {
        closeModal();
        handleAuditLog(status);
      },
      cancelHandler: () => {
        closeModal();
      },
    });
  };

  const handleLogginRes = (status: boolean) => {
    setModal({
      ...loggingPrompt(status ? "Enabled" : "Disabled", true),
      okHandler: () => {
        closeModal();
      },
      cancelHandler: () => {
        closeModal();
      },
    });
  };

  const handlePwdPolicy = async (statusValue: boolean) => {
    try {
      const url = `SystemAdminSettings/SetSysAdminSettings?SettingCode=${`Enforce Password Policy`}&StlValue=${statusValue}`;
      const res = await PostDataFunc(url, {});
      if (res.status === 200) {
        setMutate((prev) => !prev)
        handlePwdPolicyRes(statusValue);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handlePwdPolicyPrompt = (status: boolean) => {
    setModal({
      ...pwdPolicyPrompt(status ? "Enabled" : "Disabled"),
      okHandler: () => {
        closeModal();
        handlePwdPolicy(status);
      },
      cancelHandler: () => {
        closeModal();
      },
    });
  };

  const handlePwdPolicyRes = (status: boolean) => {
    setModal({
      ...pwdPolicyRes(status),
      okHandler: () => {
        closeModal();
      },
      cancelHandler: () => {
        closeModal();
      },
    });
  };

  const handleSearchAuditLog = async (statusValue: boolean) => {
    try {
      const url = `SystemAdminSettings/SetSysAdminSettings?SettingCode=${`Save Search Audit Log`}&StlValue=${statusValue}`;
      const res = await PostDataFunc(url, {});
      if (res.status === 200) {
        setMutate((prev) => !prev)
        handleSearchAuditLogRes(statusValue);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSearchAuditLogPrompt = (status: boolean) => {
    setModal({
      ...saveSearchAuditLogPrompt(status ? "Enabled" : "Disabled"),
      okHandler: () => {
        closeModal();
        handleSearchAuditLog(status);
      },
      cancelHandler: () => {
        closeModal();
      },
    });
  };

  const handleSearchAuditLogRes = (status: boolean) => {
    setModal({
      ...saveSearchAuditLogPrompt(status ? "Enabled" : "Disabled", true),
      okHandler: () => {
        closeModal();
      },
      cancelHandler: () => {
        closeModal();
      },
    });
  };
  const handleSaveEditAuditLog = async (statusValue: boolean) => {
    try {
      const url = `SystemAdminSettings/SetSysAdminSettings?SettingCode=${`Save Edit Audit Log`}&StlValue=${statusValue}`;
      const res = await PostDataFunc(url, {});
      if (res.status === 200) {
        setMutate((prev) => !prev)
        handleSaveEditAuditLogRes(statusValue);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSaveEditAuditLogPrompt = (status: boolean) => {
    setModal({
      ...saveEditAuditLogPrompt(status ? "Enabled" : "Disabled"),
      okHandler: () => {
        closeModal();
        handleSaveEditAuditLog(status);
      },
      cancelHandler: () => {
        closeModal();
      },
    });
  };

  const handleSaveEditAuditLogRes = (status: boolean) => {
    setModal({
      ...saveEditAuditLogPrompt(status ? "Enabled" : "Disabled", true),
      okHandler: () => {
        closeModal();
      },
      cancelHandler: () => {
        closeModal();
      },
    });
  };
  const handleNewRecordAuditLog = async (statusValue: boolean) => {
    try {
      const url = `SystemAdminSettings/SetSysAdminSettings?SettingCode=${`Save New Record Audit Log`}&StlValue=${statusValue}`;
      const res = await PostDataFunc(url, {});
      if (res.status === 200) {
        setMutate((prev) => !prev)
        handleNewRecordAuditLogRes(statusValue);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleNewRecordAuditLogPrompt = (status: boolean) => {
    setModal({
      ...saveNewRecordAuditLogPrompt(status ? "Enabled" : "Disabled"),
      okHandler: () => {
        closeModal();
        handleNewRecordAuditLog(status);
      },
      cancelHandler: () => {
        closeModal();
      },
    });
  };

  const handleNewRecordAuditLogRes = (status: boolean) => {
    setModal({
      ...saveNewRecordAuditLogPrompt(status ? "Enabled" : "Disabled", true),
      okHandler: () => {
        closeModal();
      },
      cancelHandler: () => {
        closeModal();
      },
    });
  };




  const handleAdvertForm = async (statusValue: boolean) => {
    try {
      const url = `SystemAdminSettings/SetSysAdminSettings?SettingCode=${`Show Advert Form`}&StlValue=${statusValue}`;
      const res = await PostDataFunc(url, {});
      if (res.status === 200) {
        setMutate((prev) => !prev)
        handleAdvertFormRes(statusValue);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleAdvertFormPrompt = (status: boolean) => {
    setModal({
      ...advertFormPrompt(status ? "Show" : "Hide"),
      okHandler: () => {
        closeModal();
        handleAdvertForm(status);
      },
      cancelHandler: () => {
        closeModal();
      },
    });
  };

  const handleAdvertFormRes = (status: boolean) => {
    setModal({
      ...advertFormRes(status),
      okHandler: () => {
        closeModal();
      },
      cancelHandler: () => {
        closeModal();
      },
    });
  };

  return (
    <div className="w-full h-full p-2">
      <ModalTemplate
        width={400}
        icon={modal.icon}
        disableCancel={modal.disableCancel}
        cancelText={modal.cancelText}
        open={modal.state}
        okHandler={modal.okHandler}
        cancelHandler={modal.cancelHandler}
        message={modal.message}
        okText={modal.okText}
        title={modal.title}
      />
      {/* Top Sec */}
      <section className="w-full border-[1px] rounded">
        <Form
          className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded-md py-2 px-2 "
          name="basic"
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          size="small"
          layout="horizontal"
        >
          {checkboxes.map(
            ({
              id,
              label,
              label2,
              inlineDefaultValue,
              inlineStateName,
              setCheckboxOnchange,
            }) => {
              return (
                <div className="p-1 mb-10" key={id}>
                  <CheckboxTemlate
                    customDisabled={false}
                    withBorders
                    label={label}
                    label2={label2}
                    inlineRightLabelCol={6}
                    inlineDefaultValue={inlineDefaultValue}
                    useCallbackFunc
                    index={inlineStateName}
                    setCheckboxOnchange={setCheckboxOnchange}
                  />
                </div>
              );
            }
          )}
        </Form>
      </section>
    </div>
  );
};

export default SysSettings;
