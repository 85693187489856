import React from "react";

import reset from "../assets/refresh-small.png";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";

export const RefreshButtonTemplate = ({
  handleRefresh,
}: {
  handleRefresh: () => void;
}) => {

  const borderTheme = useSelector((state:RootState)=>state.general.cssBorderClass)

  return (
    <>
      {
        <span
          style={{ height: 22.5, marginBottom: 2, borderWidth: 1, borderColor: borderTheme, width: 24.9 }}
          className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
          onClick={() => {
            handleRefresh && handleRefresh();
          }}
        >
          <img
            className="w-full h-full p-0.5 flex items-center justify-center"
            src={reset}
            alt="reset"
          />
        </span>
      }
    </>
  );
};
