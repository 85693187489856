import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetDataFunc } from "../../functions/get";

interface apiStates {
    docTypeData: any | null;
    
    
    isLoading: boolean;
    isPending: boolean;
    isError: boolean;
}

var initialState: apiStates = {
    docTypeData: [],
    
    
    isLoading: false,
    isPending: false,
    isError: false,
};

// Thunks for api Calls
export const fetchDocType = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "docType/fetch",
    async (search: string) => {
        const res = await GetDataFunc(`AmsTmsEntTransportDocuments/GetDocumentTypes?pageNumber=1&pageSize=20&search=${search}`);
        return res.data;
    }
);







const transDocSlice = createSlice({
    name: 'transDoc',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // fetchDept
            .addCase(fetchDocType.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchDocType.fulfilled, (state, action) => {
                state.isLoading = false;
                state.docTypeData = action.payload;
            })
            .addCase(fetchDocType.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            
            
            
    },
});

export default transDocSlice.reducer;
