import React, { useState, useCallback, useRef, useEffect } from 'react'
import { Form, Input, Select, DatePicker } from 'antd';
import { CheckBox } from 'devextreme-react/check-box';
import reset from '../../../../../assets/reset.png';
import settings from '../../../../../assets/setting.png'
import dayjs from 'dayjs';
import { SaveButton, NewButton } from '../../../../accessories/buttons';
import { CIA_Details_Select, CI_Details_Select, Objective_Details_Select, SIA_Right_Details_extra_Select, SIA_Right_Details_Select } from '../../../data/Performance_selects';
import { CI_TextArea, Mission_Vision_Date, Mission_Vision_extra_Input, Mission_Vision_Input, Mission_Vision_Mini_extra_TextArea, Mission_Vision_Mini_TextArea, Mission_Vision_TextArea, Objective_Input, Objective_mini_TextArea, Objective_TextArea, SIA_Right_Input } from '../../../data/Performance_inputs';
import { DataGrid } from 'devextreme-react';
import { Column, Editing, Scrolling, Selection } from 'devextreme-react/data-grid';
import green_plus from '../../../../../assets/green_plus.png'
import { requisition_update_edit_mini_datagrid, requisition_update_mini_datagrid } from '../../../../Safety/Entries/PPE/Request/data/datagrid_cols';
import { selectsTypes } from '../../../../human-resource/setups/data/types/selectsTypes';
import { SelectsTemplate } from '../../../../../templates/select';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../app/store';
import useFetch from '../../../../../hooks/useFetch';
import  Datagrid_template from '../../../../../templates/Datagrid';
import { useAccountInfo } from '../../../../../hooks/useAccountInfo';
import { InputsTemplate } from '../../../../../templates/input';





export const SIA_Assignments_Details_Form = () => {
    const { Option } = Select;
    const { TextArea } = Input;

    // import { AssignedDept, AssignedLocation, AssignedSelection, AssignedUnit, Batch, Group, PPE_Select, RequestWindow, StaffName } from './PPE_selects';

    const form_disable = useSelector((state: RootState) => state.general.formDisabled)

    const options = [];
    for (let i = 1; i < 36; i++) {
        options.push({
            label: i.toString(36) + i,
            value: i.toString(36) + i,
        });
    }

    
    const [selectsData, setSelectsData] = useState<any>({
        assignedTo: { id: '', name: '' },
        supervisor: { id: '', name: '' },
        manager: { id: '', name: '' },
        director: { id: '', name: '' },
    })

    const [textAreaHeight, settextAreaHeight] = useState<string>('75px')


    const [selectedTask_update, setSelectedTask_update] = useState();



    const sectionId = useSelector((state: RootState) => state.general.siaSectionId)
    const departmentId = useSelector((state: RootState) => state.general.siaDepartmentId)
    const siaActive = useSelector((state: RootState) => state.general.siaActive)

    const siaDataGrid = useSelector((state: RootState) => state.general.siaDataGrid)
    const borderTheme = useSelector((state: RootState) => state.general.cssBorderClass)


    const [employeeId, userId, userInfo] = useAccountInfo()
    const [tasks_update_state, setTask_update_state] = useState<Boolean>(true)
    const [tasks_update_state_width, setTask_update_state_width] = useState<string>('100%')
    const [tasks_update_col_width, setTask_update_col_width] = useState<number>(100)
    const [tasks_update_col_height, setTask_update_col_height] = useState<number>()
    const [task_datagrid_width, set_task_datagrid_width] = useState(`calc(100% - ${'550px'})`);
    const [AppraiseisActive, AppraisesetIsActive] = useState<boolean>(false)
    const [ratingsData, ratingsError, ratingsLoading] = useFetch("PfmStaffInitiativesAssignments/PfmRatingGetAllActiveRatings");
    const [assignedToData, assignedToError, assignedToLoading] = useFetch(`PfmStaffInitiativesAssignments/GetStaffNamesForAssignedTo?sectionId=${sectionId}&departmentId=${departmentId}&active=${siaActive}`);
    const [supervisorData, supervisorError, supervisorLoading] = useFetch(`PfmStaffInitiativesAssignments/GetStaffNamesForSupervisor?sectionId=${sectionId}&active=${siaActive}`);
    const [directorsData, directorsError, directorsLoading] = useFetch(`PfmStaffInitiativesAssignments/GetStaffNamesForDirectors?active=${siaActive}`);
    const [managersData, managersError, managersLoading] = useFetch(`PfmStaffInitiativesAssignments/GetStaffNamesForManagers?active=${siaActive}`);
    const [addedRatingData, setAddedRatingData] = useState<any>([]);





    function newT() {

    }

    useEffect(() => {
        console.log('1riszzzz')
        console.log(siaDataGrid)
        setSelectsData({
            ...selectsData,
            assignedTo: { id: '', name: '' },
            supervisor: { id: siaDataGrid?.supEmpID, name: siaDataGrid?.supEmpName },
            manager: { id: siaDataGrid?.mgrEmpID, name: siaDataGrid?.mgrEmpName },
            director: { id: siaDataGrid?.dirEmpID, name: siaDataGrid?.dirEmpName },
        })
    }, [siaDataGrid])

    const selects: selectsTypes[] = [
        { id: 0, label: 'Assigned To', idExpr: 'id', dataExpr: 'empName', optionsData: assignedToData, defaultValue: selectsData.assignedTo.name, style: '70%' },
        { id: 1, label: 'Supervisor ', idExpr: 'id', dataExpr: 'empName', optionsData: supervisorData, defaultValue: selectsData.supervisor.name, style: '70%' },


    ]

    const selects2: selectsTypes[] = [
        { id: 0, label: 'Manager', idExpr: 'id', dataExpr: 'empName', optionsData: managersData, defaultValue: selectsData.manager.name, style: '100% ' },
        { id: 1, label: 'Director', idExpr: 'id', dataExpr: 'empName', optionsData: directorsData, defaultValue: selectsData.director.name, style: '100%' },


    ]


    const [searchParams, setSearchParams] = useSearchParams();


    return (

        <div style={{ width: '100%', height: '100%', borderColor:borderTheme }} className="  border-[1px] border-l-0  dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base  ">
            {/* <h4 className=" w-full  mb-2 bg-slate-100  ">Task Details</h4> */}
            <div style={{ maxHeight: '100%', minHeight: '100%', height: '100%', width: '100%' }} className=' items-start justify-start '   >

                <Form
                    className='w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 px-2 '
                    name="basic"
                    labelCol={{ span: 6 }}
                    // wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    size='small'
                    layout='horizontal'

                >

                    <div className='w-full h-full'>

                        {/* for selects */}
                        <div className='flex  '>
                            <div className='w-3/6'>
                                {selects.map(({ id, label, optionsData, idExpr, dataExpr, defaultValue, style }) => {
                                    return (
                                        <>
                                            <SelectsTemplate
                                                key={id}
                                                label={label}
                                                disabled={form_disable}
                                                selectStyle={{ width: style }}
                                                options={optionsData}
                                                idexpr={idExpr}
                                                dataexp={dataExpr}
                                                useCallFunc
                                                selectedValue={() => {
                                                    // updateState(stateName!,{
                                                    //     id:selected[idExpr!],
                                                    //     value:selected[dataExpr!]
                                                    // })
                                                }}
                                                placeHolder={defaultValue}



                                            // useCusWidth={true}
                                            // disabledCustomWidth={true}
                                            // cusWidth={style}
                                            // selectStyle={label == 'Supervisor' && {marginLeft: '9px'}}


                                            />
                                        </>
                                    )
                                })}
                                {/* <SIA_Right_Details_Select /> */}
                            </div>
                            <div className='w-[40%] '>
                                {selects2.map(({ id, label, optionsData, idExpr, dataExpr, defaultValue, style }) => {
                                    return (
                                        <>
                                            <SelectsTemplate
                                                key={id}
                                                label={label}

                                                selectStyle={{ width: style }}
                                                disabled={form_disable}
                                                options={optionsData}
                                                idexpr={idExpr}
                                                dataexp={dataExpr}
                                                useCallFunc
                                                selectedValue={() => {
                                                    // updateState(stateName!,{
                                                    //     id:selected[idExpr!],
                                                    //     value:selected[dataExpr!]
                                                    // })
                                                }}
                                                placeHolder={defaultValue}
                                            // useCusWidth={true}
                                            // disabledCustomWidth={true}
                                            // cusWidth={style}


                                            />
                                        </>
                                    )
                                })}
                                {/* <SIA_Right_Details_extra_Select />    */}
                            </div>


                        </div>
                        <Form.Item labelCol={{ span: 3 }} label={<p className='text-xs'>{"Stakeholders "}</p>}>
                            <div className='w-full flex mb-0.5' >
                                <li className='w-full '>
                                    {/* <Input style={{ width: '96%' }} className='' /> */}
                                    <InputsTemplate 
                                        useCallbackFunc
                                        insertedValue={''}
                                        
                                    />
                                </li>
                            </div>
                        </Form.Item>

                        {/* Table */}
                        <Form.Item labelCol={{ span: 3 }}
                        className={'w-full'}
                            label={

                                <div className='w-full ml-0 px-0'>
                                    <p className=' text-xs my-0.5 '>Measurement <br /> <p className='text-right text-xs '>Scale</p> </p>
                                    {!form_disable &&
                                        <button
                                            onClick={() => {
                                                setAddedRatingData(ratingsData);
                                            }}
                                            className='dark:bg-slate-900 dark:text-darkModeSkin-base h-20 text-xs  items-center justify-center  py-0.5  rounded' ><img className='mx-auto flex justify-center items-center ' src={green_plus} />Add All Ratings</button>
                                    }
                                </div>
                            }
                        >
                            <div className='flex  w-full '>


                                {/*  */}
                                {/* Mini Datagrid table */}
                                <div className=' flex'>
                                    {!form_disable && (
                                        <div className='mr-1.5 w-2/5'>
                                            <Datagrid_template
                                                columns={requisition_update_edit_mini_datagrid}
                                                data={ratingsData}
                                                gridheight={245}
                                                
                                                disableGroupPanel={true}
                                                disablePaging={true}
                                                disableSearch={true}
                                                rowDoubleClicked={(record: any) => {
                                                    setAddedRatingData([...addedRatingData, record])
                                                }}
                                            />

                                        </div>
                                    )}
                                    <div className={`dark:bg-slate-900 dark:text-darkModeSkin-base ${!form_disable && 'w-3/5'} w-full`}>

                                        <Datagrid_template
                                            columns={requisition_update_edit_mini_datagrid}
                                            data={addedRatingData}
                                            gridheight={245}
                                           
                                            disableGroupPanel={true}
                                            disablePaging={true}
                                            disableSearch={true}
                                            allowColumnEditing={true}
                                        />

                                    </div>

                                    <div className=' mt-1 '>

                                        <span
                                            onClick={() => {
                                                // setData('');
                                                // handleRefresh && handleRefresh();
                                            }}
                                            style={{ height: 22.5, marginBottom: 2, borderWidth: 1, width: 24,borderColor:borderTheme }}
                                            className='ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded'>
                                            <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src={reset} alt="reset" />
                                        </span>



                                        <span
                                            onClick={() => {
                                                // setData('');
                                                // handleRefresh && handleRefresh();
                                            }}
                                            style={{ height: 22.5, marginBottom: 2, borderWidth: 1, width: 24,borderColor:borderTheme }}
                                            className='ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded'>
                                            <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src={settings} alt="reset" />
                                        </span>

                                    </div>

                                </div>

                            </div>
                        </Form.Item>
                        {/* </Form.Item> */}






                    </div>


                    <div>




                    </div>




                </Form>
            </div>



        </div>
    )
}