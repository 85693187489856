/* eslint-disable react/jsx-pascal-case */
import axios from "axios";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../../../app/store";
import {
  setTier2CheckboxValue,
  tier2CheckboxSaveOnclick,
  tier2CheckboxUpdateOnclick,
} from "../../../../../../features/forms/tier2checkbox/tier2SliceCheckbox";
import { setGeneralValue } from "../../../../../../features/generalSlice";
import useFetch from "../../../../../../hooks/useFetch";
import { ModalTemplate } from "../../../../../../templates/modal";
import {
  validationsData,
  saveModalData,
  updateModalData,
  api_url,
} from "../../../../../accessories/component_infos";
import Setups_Template from "../../../../../human-resource/setups/.setups_templates/Setups_Template";
import {
  exprsTypes,
  modalTypes,
  searchTextTypes,
  selectExprsTypes,
  selectsTypes,
} from "../../../../../human-resource/setups/data/types/selectsTypes";
import { assetsSetups_serviceItems_columns } from "./data/serviceItemsData";
import { WarningAlert } from "../../../../../accessories/warningAlert";
import { UpdateDataFunc } from "../../../../../../functions/update";
import { useBusyLoader } from "../../../../../../hooks/useBusyLoader";
import { useAccountInfo } from "../../../../../../hooks/useAccountInfo";
import { useCrudFunc } from "../../../../../../functions/crud";

export const Assets_setups_serviceItems = forwardRef(({ a }: any, ref) => {
  // on page resize height responsiveness
  const postUrl: string =
    "AssetTmsMaintenanceServiceItems/CreateAssetTmsMaintenanceServiceItems";
  const updateUrl: string =
    "AssetTmsMaintenanceServiceItems/UpdateAssetTmsMaintenanceServiceItems";

  //get MaintenanceServiceItem data
  const saveSuccessText = {
    message: "MaintenanceServiceItem added Successfully",
    ok: "Ok",
    title: "Success",
  }; // successful save modal message
  const updateSuccessText = {
    message: "MaintenanceServiceItem updated Successfully",
    ok: "Ok",
    title: "Success",
  }; // successful update modal message

  const [busyLoader, setBusyLoader] = useBusyLoader();
  const [employeeId, userId] = useAccountInfo();
  const [posting , updating]=useCrudFunc()
  // store data expressions[unique] ...dependent on data coming in
  const idExpr: String = "msiIdpk";
  const nameExpr: string = "msiName";
  const shortnameExpr: string = "msiShtName";
  const orderExpr: string = "msiOrder";
  const activeExpr: string = "msiActive";
  const remarksExpr: string = "msiRmks";
  const inputsExprsData: any = {
    idExpr: idExpr,
    nameExpr: nameExpr,
    shortnameExpr: shortnameExpr,
    orderExpr: orderExpr,
    activeExpr,
    remarksExpr: remarksExpr,
  };

  const selectsExpr: selectExprsTypes[] = [
    { idExpr: "msiIdpk", nameExpr: "msiName", label: "Service Item" },
    { idExpr: "mtcIdpk", nameExpr: "mtcName", label: "Maint. Classification" },
    { idExpr: "areIdpk", nameExpr: "areName", label: "area" },
    { idExpr: "areIdpk", nameExpr: "areName", label: "area" },
    { idExpr: "areIdpk", nameExpr: "areName", label: "area" },
  ];

  const inputsAfterExprsData: selectExprsTypes[] = [
    { idExpr: "RgnCode", nameExpr: "RgnCode" },
    { idExpr: "ctyCapital", nameExpr: "ctyCapital" },
    { idExpr: "areIdpk", nameExpr: "areName" },
    { idExpr: "areIdpk", nameExpr: "areName" },
    { idExpr: "areIdpk", nameExpr: "areName" },
  ];

  const storeExprs = () => {
    const exprs: exprsTypes[] = [
      { expr: "idExpr", value: idExpr },
      { expr: "nameExpr", value: nameExpr },
      { expr: "shortnameExpr", value: shortnameExpr },
      { expr: "selectExprs", value: selectsExpr[0] },
      { expr: "existsUrl", value: `AssetTmsMaintenanceServiceItems` },
    ];
    for (let i = 0; i < exprs.length; i++) {
      dispatch(
        setTier2CheckboxValue({ expr: exprs[i].expr, value: exprs[i].value })
      );
    }

    // to general slice
    const generalExprs: exprsTypes[] = [
      { expr: "selectExprs", value: selectsExpr },
      { expr: "inputExprs", value: inputsExprsData },
      { expr: "inputAfterExprs", value: inputsAfterExprsData },
    ];
    for (let i = 0; i < generalExprs.length; i++) {
      dispatch(
        setGeneralValue({
          expr: generalExprs[i].expr,
          value: generalExprs[i].value,
        })
      );
    }
  };

  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState<boolean>(false); //refresh data state for data creation or update

  //modal states
  //--general--
  const triggerSuccessModal = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.successModalState
  ); //trigger success modal
  const SuccessModalText = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.successText
  ); //success modal text

  //--for backend errors--
  const [err, setErr] = useState<{
    state: boolean;
    title: string;
    message: string;
  }>({
    state: false,
    title: "",
    message: "",
  });

  //--warning modal--
  const triggerwarningModal = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.warningState
  ); //trigger warning
  const warningInfos: any = validationsData; //warning type details
  const warningDataExpr = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.warningDataExpr
  ); //warning type selector

  //--save data modal--
  const saveData = saveModalData; //save modal details[data]
  const triggersaveModal = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.saveDataOnclick
  ); //trigger save modal

  //--Update data modal--
  const updateIndInfos = updateModalData; //update modal details[data]
  const triggerupdateModal: boolean = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.updateDataOnclick
  ); //trigger update question

  // on page resize height responsiveness
  const [trigger, setTrigger] = useState<boolean>(false);
  useImperativeHandle(ref, () => ({
    test: () => {
      setTrigger(!trigger);
    },
  }));

  // get current values from  [slice dependent on form type]
  const Country = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.select0
  );
  const MaintenanceClassification = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.selectAfter0
  );
  const name = useSelector((state: RootState) => state.tier2Ckeckboxforms.name);
  const shortname = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.shortname
  );
  const order = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.order
  );
  const active = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.active
  );
  const remarks = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.remarks
  );
  const selectedID = useSelector(
    (state: RootState) => state.general.SelectedFieldID
  );

  // selects validation
  const validateFields = () => {
    if (!MaintenanceClassification) {
      setErr({
        state: true,
        title: "Select MaintenanceClassification",
        message: "Please Select MaintenanceClassification",
      });
      return false;
    }

    return true;
  };

  //post form Data
  //validate data
  const validateDataSave = () => {
    if (validateFields()) {
      dispatch(tier2CheckboxSaveOnclick(""));
    }
  };
  // post
  const postData = async () => {
    dispatch(setTier2CheckboxValue({ expr: "saveDataOnclick", value: false })); //disable modal
    try {
      setBusyLoader(saveModalData.progress);

      await posting(`${postUrl}`, {
        msiName: name,
        msiShtName: shortname,
        msiMaintenanceClassificationIDfk: MaintenanceClassification.id,
        msiActive: active,
        msiRmks: remarks,
        msiOrder: order,
      },`Created Service Item ${name} successfully`);

      setRefresh(!refresh);

      const exprs: exprsTypes[] = [
        { expr: "saveDataOnclick", value: false },
        { expr: "successModalState", value: true },
        { expr: "successText", value: saveSuccessText },
      ];
      for (let i = 0; i < exprs.length; i++) {
        dispatch(
          setTier2CheckboxValue({ expr: exprs[i].expr, value: exprs[i].value })
        );
      }

      //disable form
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    } catch (error: any) {
      //set states for db errors modal
      setErr({
        state: true,
        title: saveModalData.error.title,
        message: saveModalData.error.message,
      });
    } finally {
      setBusyLoader("");
    }
  };

  //Update Form data
  //validate data
  const validateDataUpdate = () => {
    if (validateFields()) {
      dispatch(tier2CheckboxUpdateOnclick(""));
    }
  };
  // update
  const UpdateData = async () => {
    dispatch(
      setTier2CheckboxValue({ expr: "updateDataOnclick", value: false })
    ); //disable modal
    try {
      setBusyLoader(updateModalData.progress);

      await updating(`${updateUrl}/${selectedID}`, {
        msiIdpk: selectedID,
        msiName: name,
        msiShtName: shortname,
        msiMaintenanceClassificationIDfk: MaintenanceClassification?.id,
        msiActive: active,
        msiRmks: remarks,
        msiOrder: order,
        msiEditedBy: userId.toString(),
      },`Updated Service Item to ${name} successfully`);

      // refresh datagrid data
      setRefresh(!refresh);

      //disable update mode
      dispatch(setGeneralValue({ expr: "updateMode", value: false })); // dispatch fetched data to reduxstore

      const exprs: exprsTypes[] = [
        { expr: "updateDataOnclick", value: false },
        { expr: "successModalState", value: true },
        { expr: "successText", value: updateSuccessText },
      ];
      for (let i = 0; i < exprs.length; i++) {
        dispatch(
          setTier2CheckboxValue({ expr: exprs[i].expr, value: exprs[i].value })
        );
      }
    } catch (error: any) {
      setErr({
        state: true,
        title: updateModalData.error.title,
        message: updateModalData.error.message,
      });
    } finally {
      setBusyLoader(""); //disable busy loader
    }
  };

  //modals data
  const modals: modalTypes[] = [
    //warning
    {
      disableCancel: true,
      icon: "warning",
      open: triggerwarningModal,
      okHandler: () =>
        dispatch(setTier2CheckboxValue({ expr: "warningState", value: false })),
      cancelHandler: () =>
        dispatch(setTier2CheckboxValue({ expr: "warningState", value: false })),
      message: warningInfos[warningDataExpr!].message,
      cancelText: warningInfos[warningDataExpr!].cancelText,
      okText: warningInfos[warningDataExpr!].okText,
      title: warningInfos[warningDataExpr!].title,
    },
    //backend error
    {
      disableCancel: true,
      icon: "warning",
      open: err.state,
      okHandler: () => setErr((prev) => ({ ...prev, state: false })),
      cancelHandler: () => setErr((prev) => ({ ...prev, state: false })),
      message: err.message,
      cancelText: "",
      okText: "Ok",
      title: err.title,
    },
    //success
    {
      disableCancel: true,
      icon: "success",
      open: triggerSuccessModal,
      okHandler: () =>
        dispatch(
          setTier2CheckboxValue({ expr: "successModalState", value: false })
        ),
      cancelHandler: () =>
        dispatch(
          setTier2CheckboxValue({ expr: "successModalState", value: false })
        ),
      message: SuccessModalText.message,
      okText: SuccessModalText.okText,
      title: SuccessModalText.title,
    },
    //save
    {
      disableCancel: false,
      icon: "question",
      open: triggersaveModal,
      okHandler: () => postData(),
      cancelHandler: () =>
        dispatch(
          setTier2CheckboxValue({ expr: "saveDataOnclick", value: false })
        ),
      message: saveData.message,
      cancelText: saveData.cancelText,
      okText: saveData.okText,
      title: saveData.title,
    },
    //Update
    {
      disableCancel: false,
      icon: "question",
      open: triggerupdateModal,
      okHandler: () => UpdateData(),
      cancelHandler: () =>
        dispatch(
          setTier2CheckboxValue({ expr: "updateDataOnclick", value: false })
        ),
      message: updateIndInfos.message,
      cancelText: updateIndInfos.cancelText,
      okText: updateIndInfos.okText,
      title: updateIndInfos.title,
    },
  ];

  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });

  const activeStatus = useSelector(
    (state: RootState) => state.general.activeStatus
  );
  const [activex, setActivex] = useState<boolean | string>("");

  // data for datagrid
  const [data, error, loading] = useFetch(
    `AssetTmsMaintenanceServiceItems/PopGdvSearch?search=${searchText.text}&active=${activex}`,
    refresh,
    "msiIdpk"
  );
  useEffect(() => {
    if (data) {
      storeExprs(); // run func to store expressions on store
      dispatch(setGeneralValue({ expr: "idExpr", value: idExpr })); // dispatch fetched data to reduxstore
      dispatch(setGeneralValue({ expr: "formData", value: data })); // dispatch fetched data to reduxstore
    }

    loading ? setBusyLoader(".") : setBusyLoader("");
  }, [loading, data]);

  // form parameters
  const form_header = "Service Item Details";
  const [McData, mMcError, McLoading] = useFetch(
    "AssetTmsMaintenanceServiceItems/PopCmbMaintenanceClassifications"
  );
  const selectsaftercontrols: selectsTypes[] = [
    {
      id: 0,
      label: "Maint. Classification",
      idExpr: "mtcIdpk",
      dataExpr: "mtcName",
      optionsData: McData,
      defaultValue: MaintenanceClassification?.id ?? 0,
      diffExprs: { idKey: "mtcIDpk", valueKey: "mtcName" },
    },
  ];

  return (
    <>
      {error && <WarningAlert />}

      {/* modals */}
      {modals.map(
        (
          {
            disableCancel,
            icon,
            okHandler,
            cancelHandler,
            open,
            cancelText,
            okText,
            message,
            title,
          }: modalTypes,
          index
        ) => {
          return (
            <div key={index}>
              <ModalTemplate
                icon={icon && icon}
                disableCancel={disableCancel}
                cancelText={cancelText && cancelText}
                open={open}
                okHandler={okHandler}
                cancelHandler={cancelHandler}
                message={message}
                okText={okText}
                title={title}
              />
            </div>
          );
        }
      )}

      <Setups_Template
        formTier="tier2checkbox"
        labelCol={7}
        selects_after_constants={selectsaftercontrols}
        createData={validateDataSave}
        updateData={validateDataUpdate}
        searchTextChange={(newText) => {
          setSearchText({ temp: newText, text: searchText.text });
        }}
        handleSearch={() => {
          setSearchText({ text: searchText.temp, temp: searchText.temp });
          setActivex(activeStatus);
          setRefresh((current) => !current);
        }}
        trigger={trigger}
        datagridClicked={() => {}}
        datagridColumns={assetsSetups_serviceItems_columns}
        datagridData={data}
        formHeader={form_header}
      />
    </>
  );
});
