
import { useState } from 'react';
// import type { SelectProps } from 'antd';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CIA_toolbar } from '../../../../accessories/custom-toolbars/cia_toolbar';
import {  measure_target_footer_datagrid_cols } from '../../../data/datagrid_cols';
import {   Target_TextArea, UIA_TextArea } from '../../../data/Performance_inputs';
import { Footer_datagrid_template } from '../../../data/template/footer_datagrid';
import { Target_Details_Form } from './widgets/Target_details_form';





interface props {
    state_change: boolean;
}



export const Target = ({state_change}:props)=>{
    // const width = window.innerWidth/2
    // const fixedWidth = 'calc(50% - 8px)';
    const pageHeight = window.innerHeight - 233;


    return (
        <div  className='h-full dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base '>
            {/* toggles between the taskForm and the footer grid table vertically */}
                    
                <div>
                    <CIA_toolbar />
                </div>
                
                <div   className=''>
                    <p id={"form_header"} className='pt-1 mx-2 pl-4 w-full  rounded-tl'>Target Details</p>
                    <div style={{height:'125px'}}  className="w-full  px-2 flex justify-between gap-x-4 border-b-4 mb-1 pb-1">
                        
                        {/* Internal toggler : expands the right taskUpdate horizontally */}
                        
                            <div style={{width:'450px',height:'100px'}} className='mb-1' >
                                <Target_Details_Form />
                            </div>
                            
                    
                            {/* Double tap(click) feature to toggle to and fro of the right taskUpdate section */}
                            <div style={{width:'100%',height:'100%'}} className=" w-full mt-2  rounded-md items-center dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base " > 
                                <Target_TextArea />
                            </div>
        
                            
                        </div>
                </div>
        

            {/* Footer gridtable  */}
            <div style={{height:''}} className='px-2  dark:bg-slate-900 dark:text-darkModeSkin-base'>
                <Footer_datagrid_template gridheight={705} columns={measure_target_footer_datagrid_cols} data={''} />
            </div>

        </div>

    )
}





// const reload = ()=>{
//     return(
//         <img className='w-2/6' src={save} />
//     )
// }