import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { setGeneralValue } from "../../../../features/generalSlice";
import useFetch from "../../../../hooks/useFetch";
import  Datagrid_template from "../../../../templates/Datagrid";
import { navbar_height, toolbar_height } from "../../../accessories/component_infos";
import { HrSetupsCustomToolbar } from "../../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import { coreValuesColumns } from "./data/coreValuesData";
import { CoreValuesForm } from "./widgets/coreValuesForm";
import React from "react";
import { searchCriteriaTypes, searchTextTypes } from "../../../human-resource/setups/data/types/selectsTypes";
import { RootState } from "../../../../app/store";
import CustomLoader from "../../../accessories/custom-loader";
import { WarningAlert } from "../../../accessories/warningAlert";
import { useBusyLoader } from "../../../../hooks/useBusyLoader";


export const CoreValues = React.forwardRef(({a}:any, ref)=>{
    const news_flash_height = useSelector((state:RootState)=>state.general.newsflashHeight);
    const dispatch = useDispatch()
    //navigation
    const setupPageRoute = window.location.pathname; //get current route of page
    const datagridOnlySearchParam = 'table-only'  //search param in datagrid mode
    const formModeSearchParam = 'form-and-table' //search param in form mode mode
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    //miscellaneous heights
    const miscHeight = 7;
    
    // All heights out
    const heights_out = navbar_height + news_flash_height + toolbar_height;

    //varying form height
    const [form_height,set_form_height] = useState<number>(237);
    const pass_form_height=(height:number)=>{
        set_form_height(height)
    }

    const[refresh,setRefresh]=useState(true)
    const activeStatus = useSelector((state:RootState)=>state.general.activeStatus);
    const[searchText,setSearchText]=useState<searchTextTypes>({temp:'',text:''});
    const[searchCriteria,setSearchCriteria]=useState<searchCriteriaTypes>({temp:0,index:0});
    const[status,setStatus]=useState<boolean|string>('');
    const[busyLoader, setBusyLoader] = useBusyLoader();
     
    const[gridData,gridData_error,gridData_loading]=useFetch(`PfmCoreValues/GetAllCoreValues?searchText=${searchText.text}&active=${status}&criteria=${searchCriteria.index}`,refresh,'cvlIdpk');
    useEffect(()=>{
        gridData_loading ? setBusyLoader('.') : setBusyLoader('');
    }, [gridData_loading])
    const [selectedRow, setSelectedRow] = useState<any>({})
    
    // Page resize height responsiveness
    useImperativeHandle(ref, () => ({
        test: () => {
            settableHeight(window.innerHeight - (heights_out + miscHeight -4));
        }
    }));

    // handle interface switch
    const toggle_staff_form = ():void =>{
        const view = searchParams.get('view');

        (view === null || view === formModeSearchParam) ? 
        navigate({
            pathname : setupPageRoute,
            search : createSearchParams({
                view : datagridOnlySearchParam,
            }).toString(),
        })
        :
        navigate({
            pathname : setupPageRoute,
            search : createSearchParams({
                view : formModeSearchParam,
            }).toString(),
        });
    }

    //Table Height
    const pageHeight = window.innerHeight - (navbar_height + miscHeight) ; //height without navbar
    const [tableHeight,settableHeight] = useState(window.innerHeight - heights_out + miscHeight -9); //height without navbar and toolbar

    const criteriaData=[
        {id:0,value:'Corporate'},
        {id:1,value:'Department'},
        {id:2,value:'Section'},
        {id:3,value:'Unit'}
    ]
    return(
       <div className="h-full">
            {gridData_error &&(
                <WarningAlert/>
            )}

            <div className="px-2">
                <HrSetupsCustomToolbar 
                searchCriteriaData={criteriaData}
                searchCriteria
                searchCriteriaOnchange={(criteria)=> setSearchCriteria({temp:criteria,index:searchCriteria.index})}
                toggler={toggle_staff_form} 
                outerSelected={selectedRow} 
                handleNew={()=>{setSelectedRow({})}} 
                withDates={false}
                searchTextOnchange={(txt)=>{setSearchText({temp:txt,text:searchText.text})}} 
                handleFind={()=>{
                    setSearchText({temp:searchText.temp,text:searchText.temp}); 
                    setSearchCriteria({index:searchCriteria.temp,temp:searchCriteria.temp});
                    setStatus(activeStatus);
                    setRefresh((current)=> !current)
                }}
                selected_Record={selectedRow}
                />
            </div>

            {/* form section */}
            {(searchParams.get('view') !== datagridOnlySearchParam) &&
            <section style={{height : form_height}}  className=" form border-b-4">
                <CoreValuesForm  refreshGrid={()=>setRefresh(!refresh)} selectedRowData={selectedRow} />
            </section>}

            {/* datagrid section */}
            <section className=" px-2">
                <Datagrid_template 
                gridheight= {
                    searchParams.get('view') === formModeSearchParam || searchParams.get('view') === (null) ?  
                    window.innerHeight - (form_height + heights_out + miscHeight) 
                    : 
                    tableHeight
                }
                data = {gridData} 
                columns = {coreValuesColumns} 
                rowDoubleClicked={(e: any)=>{
                    setSelectedRow(e);
                    //disable Update Mode
                    dispatch(setGeneralValue({expr: 'updateMode' ,value : true}));

                    //disable forms
                    dispatch(setGeneralValue({expr: 'formDisabled' ,value : false}));
                }}
                onRowClick={(e:any)=>{
                    setSelectedRow(e);
                    //disable Update Mode
                    dispatch(setGeneralValue({expr: 'updateMode' ,value : false}));

                    //disable forms
                    dispatch(setGeneralValue({expr: 'formDisabled' ,value : true}));
                    setSelectedRow(e);console.log(e)
                }} />
            </section>
       </div>
    )
})