/* eslint-disable react/jsx-pascal-case */
import { Transaction_Upload_Details_Form } from "./widgets/Transaction_upload_details_form"


export const Transaction_Upload = ()=>{
    return (
        <div>
            <Transaction_Upload_Details_Form state_change={false} />
            
            
        </div>
    )
}