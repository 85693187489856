/* eslint-disable react/jsx-pascal-case */
import { Mini_datagrid_template } from "../data/mini_datagrid";
import { right_alert_drivers_col } from "../data/datagrid_cols";

export const Right_Grid_Form = () => {
  return (
    <div>
      <div className="border-2">
        <Mini_datagrid_template
          columns={right_alert_drivers_col}
          gridheight={330}
        />
      </div>
    </div>
  );
};
