import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Setups_Template from "../.setups_templates/Setups_Template";
import {
  exprsTypes,
  inputsTypes,
  modalTypes,
  searchTextTypes,
  selectExprsTypes,
  selectsTypes,
} from "../data/types/selectsTypes";
import useFetch from "../../../../hooks/useFetch";
import { hr_setups_region_columns } from "../regions/data/HrSetupsRegionData";
import { hr_setups_district_columns } from "./data/HrSetupsDistrictsData";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../app/store";
import {
  setTier2CheckboxValue,
  tier2CheckboxSaveOnclick,
  tier2CheckboxUpdateOnclick,
} from "../../../../features/forms/tier2checkbox/tier2SliceCheckbox";
import { setGeneralValue } from "../../../../features/generalSlice";
import { ModalTemplate } from "../../../../templates/modal";
import {
  validationsData,
  saveModalData,
  updateModalData,
  deleteModalData,
  api_url,
  putError,
  postError,
} from "../../../accessories/component_infos";
import CustomLoader from "../../../accessories/custom-loader";
import { WarningAlert } from "../../../accessories/warningAlert";
import { useBusyLoader } from "../../../../hooks/useBusyLoader";
import React from "react";
import { useCrudFunc } from "../../../../functions/crud";
import { useAccountInfo } from "../../../../hooks/useAccountInfo";

export const Hr_Setups_Districts = React.forwardRef(({ a }: any, ref) => {
  const activeStatus = useSelector(
    (state: RootState) => state.general.activeStatus
  );
  const [status, setStatus] = useState<boolean | string>("");
  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });
  const fetchUrl: string = `SetupsDistrict/getAllActiveAndInActiveDistrict?searchText=${searchText.text}&active=${status}`;
  const postUrl: string = "SetupsDistrict/createDistrict";
  const updateUrl: string = "SetupsDistrict/updateDistrict";
  const [posting, updating] = useCrudFunc();
  //get country data
  const saveSuccessText = {
    message: "District added Successfully",
    ok: "Ok",
    title: "Success",
  }; // successful save modal message
  const updateSuccessText = {
    message: "District updated Successfully",
    ok: "Ok",
    title: "Success",
  }; // successful update modal message

  const [busyLoader, setBusyLoader] = useBusyLoader();
  // store data expressions[unique] ...dependent on data coming in
  const idExpr: String = "dstIDpk";
  const nameExpr: string = "dstName";
  const shortnameExpr: string = "dstShtName";
  const orderExpr: string = "dstOrder";
  const activeExpr: string = "dstActive";
  const remarksExpr: string = "dstRemarks";
  const inputsExprsData: any = {
    idExpr: idExpr,
    nameExpr: nameExpr,
    shortnameExpr: shortnameExpr,
    orderExpr: orderExpr,
    activeExpr,
    remarksExpr: remarksExpr,
  };
  const selectsExpr: selectExprsTypes[] = [
    { idExpr: "rgnCountryIDfk", nameExpr: "ctyName", label: "country" },
    { idExpr: "dstRegionIDfk", nameExpr: "rgnName", label: "region" },
    { idExpr: "areIdpk", nameExpr: "areName", label: "area" },
    { idExpr: "areIdpk", nameExpr: "areName", label: "area" },
    { idExpr: "areIdpk", nameExpr: "areName", label: "area" },
  ];
  const inputsAfterExprsData: selectExprsTypes[] = [
    { idExpr: "RgnCode", nameExpr: "RgnCode" },
    { idExpr: "ctyCapital", nameExpr: "ctyCapital" },
    { idExpr: "areIdpk", nameExpr: "areName" },
    { idExpr: "areIdpk", nameExpr: "areName" },
    { idExpr: "areIdpk", nameExpr: "areName" },
  ];

  const storeExprs = () => {
    const exprs: exprsTypes[] = [
      { expr: "existsUrl", value: `SetupsDistrict` },
      { expr: "idExpr", value: idExpr },
      { expr: "nameExpr", value: nameExpr },
      { expr: "shortnameExpr", value: shortnameExpr },
      { expr: "selectExprs", value: selectsExpr[0] },
    ];
    for (let i = 0; i < exprs.length; i++) {
      dispatch(
        setTier2CheckboxValue({ expr: exprs[i].expr, value: exprs[i].value })
      );
    }

    // to general slice
    const generalExprs: exprsTypes[] = [
      { expr: "selectExprs", value: selectsExpr },
      { expr: "inputExprs", value: inputsExprsData },
      { expr: "inputAfterExprs", value: inputsAfterExprsData },
    ];
    for (let i = 0; i < generalExprs.length; i++) {
      dispatch(
        setGeneralValue({
          expr: generalExprs[i].expr,
          value: generalExprs[i].value,
        })
      );
    }
  };

  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState<boolean>(false); //refresh data state for data creation or update

  //modal states
  //--general--
  const triggerSuccessModal = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.successModalState
  ); //trigger success modal
  const SuccessModalText = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.successText
  ); //success modal text

  //--for backend errors--
  const [err, setErr] = useState<{
    state: boolean;
    title: string;
    message: string;
  }>({
    state: false,
    title: "",
    message: "",
  });

  //--warning modal--
  const triggerwarningModal = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.warningState
  ); //trigger warning
  const warningInfos: any = validationsData; //warning type details
  const warningDataExpr = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.warningDataExpr
  ); //warning type selector

  //--save data modal--
  const saveData = saveModalData; //save modal details[data]
  const triggersaveModal = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.saveDataOnclick
  ); //trigger save modal

  //--Update data modal--
  const updateIndInfos = updateModalData; //update modal details[data]
  const triggerupdateModal: boolean = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.updateDataOnclick
  ); //trigger update question

  //--Update data modal--
  const deleteDataInfos = deleteModalData; //update modal details[data]
  const triggerdeleteModal: boolean = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.deleteDataOnclick
  ); //trigger update question

  // on page resize height responsiveness
  const [trigger, setTrigger] = useState<boolean>(false);
  useImperativeHandle(ref, () => ({
    test: () => {
      setTrigger(!trigger);
    },
  }));

  // get current values from  [slice dependent on form type]
  const Country = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.select0
  );
  const region = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.select1
  );
  const name = useSelector((state: RootState) => state.tier2Ckeckboxforms.name);
  const shortname = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.shortname
  );
  const order = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.order
  );
  const active = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.active
  );
  const remarks = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.remarks
  );
  const code = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.inputAfter0
  );
  const capital = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.inputAfter1
  );
  const selectedID = useSelector(
    (state: RootState) => state.general.SelectedFieldID
  );
  const [employeeId, userId, userInfo] = useAccountInfo();
  console.log(order);


  // selects validation
  const validateFields = () => {
    if (!Country) {
      setErr({
        state: true,
        title: "Select Country",
        message: "Please Select Country",
      });
      return false;
    }
    if (!region) {
      setErr({
        state: true,
        title: "Select Region",
        message: "Please Select Region",
      });
      return false;
    }

    return true;
  };

  //post form Data
  //validate data
  const validateDataSave = () => {
    if (validateFields()) {
      dispatch(tier2CheckboxSaveOnclick(""));
    }
  };
  // post
  const postData = async () => {
    dispatch(setTier2CheckboxValue({ expr: "saveDataOnclick", value: false })); //disable modal
    try {
      setBusyLoader("Saving record...");
      const res = await posting(`${postUrl}`, {
        dstName: name,
        dstShtName: shortname,
        dstRegionIdfk: region,
        dstCapial: capital,
        dstActive: active,
        dstOrder: order,
        dstRemarks: remarks,
        dstCreatedBy: 1,
      });

      setRefresh(!refresh);
      setBusyLoader(""); //disable busy loader

      const exprs: exprsTypes[] = [
        { expr: "saveDataOnclick", value: false },
        { expr: "successModalState", value: true },
        { expr: "successText", value: saveSuccessText },
      ];
      for (let i = 0; i < exprs.length; i++) {
        dispatch(
          setTier2CheckboxValue({ expr: exprs[i].expr, value: exprs[i].value })
        );
      }

      //disable form
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    } catch (error: any) {
      console.log(error);
      setBusyLoader("");

      //set states for db errors modal
      setErr({
        state: true,
        title: postError.title,
        message: postError.message,
      });
    }
  };

  //Update Form data
  //validate data
  const validateDataUpdate = () => {
    if (validateFields()) {
      dispatch(tier2CheckboxUpdateOnclick(""));
    }
  };
  // update
  const UpdateData = async () => {
    dispatch(
      setTier2CheckboxValue({ expr: "updateDataOnclick", value: false })
    ); //disable modal
    try {
      setBusyLoader("Updating record...");
      const res = await updating(`${updateUrl}/${selectedID}`, {
        dstName: name,
        dstShtName: shortname,
        dstRegionIdfk: region,
        dstCapial: capital,
        dstActive: active,
        dstOrder: order,
        dstRemarks: remarks,
        dstLastEditedBy: userId,
      });

      // refresh datagrid data
      setRefresh(!refresh);

      setBusyLoader(""); //disable busy loader

      //disable update mode
      dispatch(setGeneralValue({ expr: "updateMode", value: false })); // dispatch fetched data to reduxstore

      const exprs: exprsTypes[] = [
        { expr: "updateDataOnclick", value: false },
        { expr: "successModalState", value: true },
        { expr: "successText", value: updateSuccessText },
      ];
      for (let i = 0; i < exprs.length; i++) {
        dispatch(
          setTier2CheckboxValue({ expr: exprs[i].expr, value: exprs[i].value })
        );
      }
    } catch (error: any) {
      console.log(error);
      setBusyLoader(""); //disable busy loader

      //set states for db errors modal
      //set states for db errors modal
      setErr({
        state: true,
        title: putError.title,
        message: putError.message,
      });
    }
  };

  //modals data
  const modals: modalTypes[] = [
    //warning
    {
      disableCancel: true,
      icon: "warning",
      open: triggerwarningModal,
      okHandler: () =>
        dispatch(setTier2CheckboxValue({ expr: "warningState", value: false })),
      cancelHandler: () =>
        dispatch(setTier2CheckboxValue({ expr: "warningState", value: false })),
      message: warningInfos[warningDataExpr!].message,
      cancelText: warningInfos[warningDataExpr!].cancelText,
      okText: warningInfos[warningDataExpr!].okText,
      title: warningInfos[warningDataExpr!].title,
    },
    //backend error
    {
      disableCancel: true,
      icon: "warning",
      open: err.state,
      okHandler: () => setErr((prev) => ({ ...prev, state: false })),
      cancelHandler: () => setErr((prev) => ({ ...prev, state: false })),
      message: err.message,
      cancelText: "",
      okText: "Ok",
      title: err.title,
    },
    //success
    {
      disableCancel: true,
      icon: "success",
      open: triggerSuccessModal,
      okHandler: () =>
        dispatch(
          setTier2CheckboxValue({ expr: "successModalState", value: false })
        ),
      cancelHandler: () =>
        dispatch(
          setTier2CheckboxValue({ expr: "successModalState", value: false })
        ),
      message: SuccessModalText.message,
      okText: SuccessModalText.okText,
      title: SuccessModalText.title,
    },
    //save
    {
      disableCancel: false,
      icon: "question",
      open: triggersaveModal,
      okHandler: () => postData(),
      cancelHandler: () =>
        dispatch(
          setTier2CheckboxValue({ expr: "saveDataOnclick", value: false })
        ),
      message: saveData.message,
      cancelText: saveData.cancelText,
      okText: saveData.okText,
      title: saveData.title,
    },
    //Update
    {
      disableCancel: false,
      icon: "question",
      open: triggerupdateModal,
      okHandler: () => UpdateData(),
      cancelHandler: () =>
        dispatch(
          setTier2CheckboxValue({ expr: "updateDataOnclick", value: false })
        ),
      message: updateIndInfos.message,
      cancelText: updateIndInfos.cancelText,
      okText: updateIndInfos.okText,
      title: updateIndInfos.title,
    },
  ];

  // data for datagrid
  const [data, error, loading] = useFetch(fetchUrl, refresh, "dstIDpk");
  useEffect(() => {
    if (data) {
      storeExprs(); // run func to store expressions on store
      dispatch(setGeneralValue({ expr: "idExpr", value: idExpr })); // dispatch fetched data to reduxstore
      dispatch(setGeneralValue({ expr: "formData", value: data })); // dispatch fetched data to reduxstore
    }

    loading ? setBusyLoader(".") : setBusyLoader("");
  }, [loading, data]);

  // form parameters
  const form_header = "District Details";

  //selects data
  const [countryData, countryError, countryLoading] = useFetch(
    "SetupsDistrict/PopLueCountries"
  );
  const [regionData, regionError, regionLoading] = useFetch(
    `SetupsDistrict/PopLueRegions/${Country}`
  );
  console.log("country",Country);
  const selects: selectsTypes[] = [
    //selects
    {
      id: 0,
      label: "Country",
      optionsData: countryData,
      idExpr: "ctyIDpk",
      dataExpr: "ctyName",
      diffExprs: { idKey: "rgnCountryIDfk", valueKey: "ctyName" },
    },
    {
      id: 1,
      label: "Region",
      optionsData: regionData,
      idExpr: "rgnIDpk",
      dataExpr: "rgnName",
      diffExprs: { idKey: "dstRegionIDfk", valueKey: "rgnName" },
    },
  ];

  const inputs: inputsTypes[] = [
    //inputs
    {
      id: 0,
      label: "District Capital",
      disabled: true,
      style: { background: "#fffbeb" },
    },
  ];

  const optionalnamelabel = "District";

  return (
    <>
      {error && <WarningAlert />}

      {modals.map(
        (
          {
            disableCancel,
            icon,
            okHandler,
            cancelHandler,
            open,
            cancelText,
            okText,
            message,
            title,
          }: modalTypes,
          index
        ) => {
          return (
            <div key={index}>
              <ModalTemplate
                icon={icon && icon}
                disableCancel={disableCancel}
                cancelText={cancelText && cancelText}
                open={open}
                okHandler={okHandler}
                cancelHandler={cancelHandler}
                message={message}
                okText={okText}
                title={title}
              />
            </div>
          );
        }
      )}

      <Setups_Template
        searchTextChange={(txt) => {
          setSearchText({ temp: txt, text: searchText.text });
        }}
        handleSearch={() => {
          setSearchText({ temp: searchText.temp, text: searchText.temp });
          setStatus(activeStatus);
        }}
        labelCol={5}
        inputsAfterConstants={inputs}
        formTier="tier2checkbox"
        optionalnamelabel={optionalnamelabel}
        datagridClicked={() => {}}
        createData={validateDataSave}
        updateData={validateDataUpdate}
        trigger={trigger}
        datagridColumns={hr_setups_district_columns}
        datagridData={data}
        selects={selects}
        formHeader={form_header}
      />
    </>
  );
});
