
import FilterTodos from '../../../Task&Todos/FooterGrid/FilterTodos';
import { useState } from 'react';
// import type { SelectProps } from 'antd';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { PPE_distribution_toolbar } from '../../../accessories/custom-toolbars/ppeDistributionToolbar';

import { footer_datagrid_cols } from '../../../Safety/Entries/PPE/Distribution/data/datagrid_cols';
import { Footer_datagrid_template } from '../../data/template/footer_datagrid';
import { Mini_datagrid_template } from '../../data/template/mini_datagrid';
import refresh from '../../../../assets/refresh.png';
import edit from '../../../../assets/edit.png';
import close from '../../../../assets/close.png';
import { Theme_Result_Details } from './widgets/Theme_result_form';
import { Theme_tab6_Details } from './widgets/Theme_tab6_details';
import { Theme_tab5_Details } from './widgets/Theme_tab5_form';
import { tab4 } from './data/strategic_theme_cols';


interface props {
    state_change: boolean;
}



export const Theme_Tab5 = ({state_change}:props)=>{
    // const width = window.innerWidth/2
    // const fixedWidth = 'calc(50% - 8px)';
    const pageHeight = window.innerHeight - 233;


    return (
        <div  className='h-full dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base mt-1 '>
            {/* toggles between the taskForm and the footer grid table vertically */}
                    
               
                
                <div className=''>
                    
                    <div  className=" px-2 flex justify-between gap-x-4 border-b-4 mb-1 pb-1">
                        
                        {/* Internal toggler : expands the right taskUpdate horizontally */}
                        
                            <div style={{width:'100%',height:'315px'}} >
                                <Theme_tab5_Details />
                            </div>
                            
                    
                            {/* Double tap(click) feature to toggle to and fro of the right taskUpdate section */}
                            <div style={{width:'calc(100% - 920px)'}} className="h-full w-full  rounded-md items-center dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base " >
                                <div className='flex justify-end items-center my-1'>
                                    <ul className='flex justify-evenly items-center'>
                                        <li style={{borderWidth : '1px'}}  className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center">
                                            <span className="flex"> <img className="w-4 dark:bg-slate-900 pr-1 dark:text-gray-100 duration-100" src={refresh} /> </span> 
                                        </li>

                                        <li style={{borderWidth : '1px'}}  className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center">
                                            <span className="flex"> <img className="w-4 dark:bg-slate-900 pr-1 dark:text-gray-100 duration-100" src={edit} /> </span> 
                                        </li>

                                        <li style={{borderWidth : '1px'}}  className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center">
                                            <span className="flex"> <img className="w-4 dark:bg-slate-900 pr-1 dark:text-gray-100 duration-100" src={close} /> </span> 
                                        </li>
                                    </ul>
                                </div> 

                                <div className='border-2'>
                                    <Mini_datagrid_template columns={tab4} gridheight={280} />
                                </div>
                            </div>
        
                            
                        </div>
                </div>
        

            {/* Footer gridtable  */}
            <div style={{height:''}} className='px-2 dark:bg-slate-900 dark:text-darkModeSkin-base'>
            {/* <Footer_datagrid_template gridheight={225} columns={footer_datagrid_cols} data={undefined} /> */}
            </div>

        </div>

    )
}





// const reload = ()=>{
//     return(
//         <img className='w-2/6' src={save} />
//     )
// }