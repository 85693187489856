import {useSwitcher} from "../db/useSwitcher";

interface SwitcherProps {
    children: React.ReactNode;
    alternate: React.ReactNode;
}

export const Switcher = ({ alternate, children }: SwitcherProps) => {
    const { status } = useSwitcher();

    return (
        <section className="w-full h-full">
            {status ? alternate : children}
        </section>
    );
}
