/* eslint-disable react/jsx-pascal-case */
import { Switcher } from "../../_widget/switcher";
import { RefreshButton } from "../../_widget/utils";
import Datagrid_template from "../../../../../../templates/Datagrid";
import { SaveButton } from "../../../../../accessories/buttons";
import { DateTemplate } from "../../../../../../templates/date";
import MiniToolbar from "../../_widget/minitollbar";
import { InputsTemplate } from "../../../../../../templates/input";
import { TextareaTemplate } from "../../../../../../templates/textarea";
import { ReadingsChart } from "../../_widget/readingschart";
import { useGraphSwitcher } from "../../db/useSwitcher";
import {
  lineColumns,
  lineData,
  stationEntries,
  stationEntriesColumns,
} from "../BusVoltage/_data/stationentriescolumns";
import useFetch from "../../../../../../hooks/useFetch";
import { useState } from "react";
import { line_cols } from "./data/datagridCols";
import dayjs, { Dayjs } from "dayjs";
import { searchCriteriaTypes, searchTextTypes, statusTypes } from "../../../../../human-resource/setups/data/types/selectsTypes";

interface LineReadingsTypes {
  voltage: any;
  current: any;
  activeInput: any;
  reactiveInput: any;
  refresh: boolean;
  selectedDate?: Dayjs;
  active: boolean;
  remarks: string;
}

export default function LineReadings() {
  const [states, setStates] = useState<LineReadingsTypes>({
    refresh: false,
    selectedDate: dayjs(),
    voltage: "",
    current: "",
    activeInput: "",
    active: false,
    reactiveInput: "",
    remarks: "",
  });

  const setState = (key: string, value: any) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };

  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });
  const [activeStatus, setActiveStatus] = useState<statusTypes>({
    temp: "",
    status: "",
  });
  const [searchCriteria, setsearhCriteria] = useState<searchCriteriaTypes>({
    temp: 0,
    index: 0,
  });

  const { status } = useGraphSwitcher();

  const [station_data] = useFetch(
    `LineDetails/stations`,
    states.refresh,
    undefined,
    undefined,
    "powerapp"
  );
  console.log(station_data, "station_data");

  return (
    <section className="w-full h-full">
      <Switcher alternate={""}>
        {/* top section */}
        <section className="flex space-x-3 w-full border-2 p-2">
          {/* left section */}
          <aside className="w-[22%]">
            <aside className="flex items-center justify-between bg-neutral-50 py-1 rounded-sm px-2 border-[1px] border-neutral-400">
              <p className="text-sm font-medium">STATION</p>
              <RefreshButton />
            </aside>
            <aside>
              <Datagrid_template
                gridheight={240}
                showColumnHeaders={false}
                columns={[
                  {
                    id: 1,
                    caption: "STATION",
                    dataField: "stnName",
                  },
                ]}
                data={station_data}
                disablePaging
                disableSearch
                disableGroupPanel
              />
            </aside>
          </aside>
          {/* right section */}
          <aside className="w-[78%] items-right overflow-hidden">
            <section className="flex items-start ml-[174px]">
              <p className="text-[12px] font-medium mr-2">Line:</p>
              <Datagrid_template
                gridheight={100}
                showColumnHeaders={true}
                columns={line_cols}
                data={stationEntries}
                disablePaging
                disableSearch
                disableGroupPanel
              />
            </section>
            <section className="w-full h-full mt-1">
              <section className="flex items-start justify-between">
                <aside className="flex items-start w-full ml-[168px] mb-0.5">
                  <p className="mr-[6px]">Time:</p>
                  <DateTemplate
                    disabled={false}
                    style={{ width: 230, height: 32 }}
                    selectedDate={dayjs(states.selectedDate)}
                    changeDate={(date: Dayjs) => {
                      setState("selectedDate", date);
                    }}
                    className="w-full"
                  />
                </aside>
                <aside>
                  <RefreshButton />
                </aside>
              </section>
              <section className="flex space-x-2 items-center">
                <aside className="space-y-0.5 ">
                  <section className="flex space-x-1">
                    <aside className="ml-14">
                      <InputsTemplate
                        disabledStatus={false}
                        span
                        inputStyle={{ width: 114 }}
                        label="Voltage (kv) & Current (A)"
                        labelColPan={3}
                        defaultValue={states.voltage}
                        orderOnchange={(e) => {
                          setState("voltage", e);
                        }}
                      />
                    </aside>
                    <InputsTemplate
                      disabledStatus={false}
                      span
                      inputStyle={{ width: 114 }}
                      defaultValue={states.current}
                      orderOnchange={(e) => {
                        setState("current", e);
                      }}
                    />
                  </section>
                  <section className="flex space-x-1 ml-0.5">
                    <InputsTemplate
                      disabledStatus={false}
                      span
                      inputStyle={{ width: 114 }}
                      label="Active (kW) & Reactive Power (kVAr)"
                      labelColPan={12}
                      defaultValue={states.activeInput}
                      orderOnchange={(e) => {
                        setState("activeInput", e);
                      }}
                    />
                    <InputsTemplate
                      disabledStatus={false}
                      span
                      inputStyle={{ width: 114 }}
                      defaultValue={states.reactiveInput}
                      orderOnchange={(e) => {
                        setState("reactiveInput", e);
                      }}
                    />
                    <SaveButton 
                        disableButton={false}
                        handleSave={() => {}}
                    />
                  </section>
                </aside>
              </section>
              <section className="h-full mt-0.5  ml-[205px]">
                <TextareaTemplate
                  height={75}
                //   labelCol={7}
                //   label={"Remarks"}
                  useCallbackFunc
                  defaultValue={states.remarks}
                  disabled={false}
                  setCallbackValue={(e) => {
                    setState("remarks", e);
                  }}
                />
              </section>
            </section>
          </aside>
        </section>
        <div className="h-1 w-full bg-sky-200" />
      </Switcher>
      {/* botom section */}
      <section className="flex flex-col">
        <MiniToolbar />
        <section className="flex-grow">
          <section className="w-full h-full">
            {status ? (
              <ReadingsChart />
            ) : (
              <Datagrid_template
                gridheight={415}
                showColumnHeaders={true}
                columns={lineColumns}
                data={lineData}
                disablePaging
                disableGroupPanel
              />
            )}
          </section>
        </section>
      </section>
    </section>
  );
}
