export const PerformanceSetups_monitoringModules_columns = [
    {id : 0, caption : 'ID',dataField : 'rmmIdpk',datatype : 'number', width : 80, fixed : true, visible : false},
    {id : 1, caption : 'MODULE',dataField : 'rmmName',datatype : 'number', width : 80, fixed : true, visible : true},
    {id : 2, caption : 'SHORT NAME', dataField : 'rmmShtName',datatype : 'string', width : 160, fixed : true , visible : true},
    {id : 3, caption : 'ORDER', dataField : 'rmmOrder',datatype : 'number', width : 160, fixed : true , visible : true},
    {id : 4, caption : 'ACTIVE?', dataField : 'rmmActive',datatype : 'boolean', width : 130, fixed : true , visible : true},
    {id : 5, caption : 'PICK FREQ?', dataField : 'rmmPickFrequencyFromModule',datatype : 'boolean', width : 90, fixed : true , visible : true},
    {id : 6, caption : 'SINGLE ASSN.?', dataField : 'rmmSingleAssignmentPerPeriod',datatype : 'boolean', width : 90, fixed : true , visible : true},
    {id : 7, caption : 'SAF.MGR.M-ATT.?', dataField : 'rmmSafetyManagerMeetingAttendance',datatype : 'boolean', width : 90, fixed : true , visible : true},
    {id : 8, caption : 'HR-LEAVE MNGT?', dataField : 'rmmHrLeaveManagement',datatype : 'boolean', width : 90, fixed : false , visible : true},
    {id : 9, caption : 'HOSP. M-EXAMS?', dataField : 'rmmHospManagerMedicalExams',datatype : 'boolean', width : 90, fixed : false , visible : true},
    {id : 10, caption : 'HELP DESK?', dataField : 'rmmHelpDesk',datatype : 'boolean', width : 90, fixed : false , visible : true},
    {id : 11, caption : 'TASK MGR?', dataField : 'rmmTaskManager',datatype : 'boolean', width : 90, fixed : false, visible : true},
    {id : 12, caption : 'REMARKS',dataField : 'rmmRmks',datatype : 'string', width : 5000, fixed : false , visible : true}
]
