import { stat } from "fs";
import useFetch from "../../../../../../../../hooks/useFetch";
import { CheckboxTemlate } from "../../../../../../../../templates/checkbox";
import React, { PropsWithoutRef, useState } from "react";

interface props {
    statesx ?: any
    updateStatesx ?: (key:string, val:any)=>void
}

const CheckBoxForm = ({statesx, updateStatesx}:props) => {
  const [checkboxStates, setCheckboxStates] = useState({
    vriPickupBrakesWorkingCorrectly: statesx?.vriPickupBrakesWorkingCorrectly,
    vriPickupBumbersInGoodCondition: statesx?.vriPickupBumbersInGoodCondition,
    vriPickupHornWorkingProperly: statesx?.vriPickupHornWorkingProperly,
    vriPickupSeatbeltsInGoodCondition: statesx?.vriPickupSeatbeltsInGoodCondition,
    vriPickupWarningTriangles: statesx?.vriPickupWarningTriangles,
    vriPickupWindowControlsWorking: statesx?.vriPickupWindowControlsWorking,
    vriPickupInteriorClean: statesx?.vriPickupInteriorClean,
    vriPickupCracksOnWindshield: statesx?.vriPickupCracksOnWindshield,
    vriPickupSideMirrorsInGoodCondition: statesx?.vriPickupSideMirrorsInGoodCondition,
    vriPickupBrakeLightsWorkingCorrectly: statesx?.vriPickupBrakeLightsWorkingCorrectly,
    vriPickupHeadlightsWorkingCorrectly: statesx?.vriPickupHeadlightsWorkingCorrectly,
    vriPickupIndicatorLightsWorkingCorrectly: statesx?.vriPickupIndicatorLightsWorkingCorrectly,
    vriPickupReverseLightsWorkingCorrectly: statesx?.vriPickupReverseLightsWorkingCorrectly,
    vriPickupInsideLightsWorking: statesx?.vriPickupInsideLightsWorking,
    vriPickupTyresInGoodCondition: statesx?.vriPickupTyresInGoodCondition,
    vriPickupSpareTyre: statesx?.vriPickupSpareTyre,
    vriPickupToolsForReplacingTyres: statesx?.vriPickupToolsForReplacingTyres,
    vriPickupBrakeFluidLevelOk: statesx?.vriPickupBrakeFluidLevelOk,
    vriPickupEngineOilLevelOk: statesx?.vriPickupEngineOilLevelOk,
    vriPickupPowerSteeringFluidLevelOk: statesx?.vriPickupPowerSteeringFluidLevelOk,
    vriPickupCoolantLevelOk: statesx?.vriPickupCoolantLevelOk,
    vriPickupFireExtinguisher: statesx?.vriPickupFireExtinguisher,
    vriPickupFirstAidKit: statesx?.vriPickupFirstAidKit,
    vriPickupBatteryOk: statesx?.vriPickupBatteryOk,
    vriPickupAnyLeakages: statesx?.vriPickupAnyLeakages,
    vriPickupWipersInGoodCondition: statesx?.vriPickupWipersInGoodCondition,
    vriPickupAnyDents: statesx?.vriPickupAnyDents,
    vriPickupAnyScratches: statesx?.vriPickupAnyScratches,
    vriPickupInsuranceValid: statesx?.vriPickupInsuranceValid,
    vriPickupRoadworthyValid: statesx?.vriPickupRoadworthyValid,
    vriPickupDriversLicensePresentAndValid: statesx?.vriPickupDriversLicensePresentAndValid,
    vriPickupAllFloorMatsIntact: statesx?.vriPickupAllFloorMatsIntact,
    vriPickupAcInGoodCondition: statesx?.vriPickupAcInGoodCondition,
});

const handleCheckboxChange = (name: string, value: boolean) => {
    setCheckboxStates((prevStates) => ({
      ...prevStates,
      [name]: value,
    }));
  };

  const [GetAllPickupInspectionDetails] = useFetch(
    `AsmTmsVehicleRequestKeyPicks/GetAllPickupsInspectionDetails?vriRequestIDfk=${"1"}`
  );
  const [GetAllReturnInspectionDetails] = useFetch(
    `AsmTmsVehicleRequestKeyPicks/GetAllReturnInspectionDetails?vriRequestIDfk=${"1"}`
  );
  const [indeterminate, setIndeterminate] = useState<any>(true);

  var options = [
    {
      text: "Are the brakes working correctly?",
      value: statesx?.vriPickupBrakesWorkingCorrectly,
      nameExpr: "vriPickupBrakesWorkingCorrectly",
    },
    {
      text: "Are the bumbers in good condition?",
      value: statesx?.vriPickupBumbersInGoodCondition,
      nameExpr: "vriPickupBumbersInGoodCondition",
    },
    {
      text: "Is the horn working properly?",
      value: statesx?.vriPickupHornWorkingProperly,
      nameExpr: "vriPickupHornWorkingProperly",
    },
    {
      text: "Are all seatbelts in good condition?",
      value: statesx?.vriPickupSeatbeltsInGoodCondition,
      nameExpr: "vriPickupSeatbeltsInGoodCondition",
    },
    {
      text: "Are there warning triangles?",
      value: statesx?.vriPickupWarningTriangles,
      nameExpr: "vriPickupWarningTriangles",
    },
    {
      text: "Are the window controls working?",
      value: statesx?.vriPickupWindowControlsWorking,
      nameExpr: "vriPickupWindowControlsWorking",
    },
    {
      text: "Is the interior clean?",
      value: statesx?.vriPickupInteriorClean,
      nameExpr: "vriPickupInteriorClean",
    },
    {
      text: "Are there cracks on the windshield?",
      value: statesx?.vriPickupCracksOnWindshield,
      nameExpr: "vriPickupCracksOnWindshield",
    },
    {
      text: "Are the Side Mirrors in good condition?",
      value: statesx?.vriPickupSideMirrorsInGoodCondition,
      nameExpr: "vriPickupSideMirrorsInGoodCondition",
    },
    {
      text: "Are the brake lights working correctly?",
      value: statesx?.vriPickupBrakeLightsWorkingCorrectly,
      nameExpr: "vriPickupBrakeLightsWorkingCorrectly",
    },
    {
      text: "Are the headlights working correctly?",
      value: statesx?.vriPickupHeadlightsWorkingCorrectly,
      nameExpr: "vriPickupHeadlightsWorkingCorrectly",
    },
    {
      text: "Are the indicator lights working correctly?",
      value: statesx?.vriPickupIndicatorLightsWorkingCorrectly,
      nameExpr: "vriPickupIndicatorLightsWorkingCorrectly",
    },
    {
      text: "Are the reverse lights working correctly?",
      value: statesx?.vriPickupReverseLightsWorkingCorrectly,
      nameExpr: "vriPickupReverseLightsWorkingCorrectly",
    },
    {
      text: "Are all inside lights working?",
      value: statesx?.vriPickupInsideLightsWorking,
      nameExpr: "vriPickupInsideLightsWorking",
    },
    {
      text: "Are the tyres in good condition?",
      value: statesx?.vriPickupTyresInGoodCondition,
      nameExpr: "vriPickupTyresInGoodCondition",
    },
    {
      text: "Is there a spare tyre?",
      value: statesx?.vriPickupSpareTyre,
      nameExpr: "vriPickupSpareTyre",
    },
    {
      text: "Are there tools for replacing tyres?",
      value: statesx?.vriPickupToolsForReplacingTyres,
      nameExpr: "vriPickupToolsForReplacingTyres",
    },
    {
      text: "Is the brake fluid level ok?",
      value: statesx?.vriPickupBrakeFluidLevelOk,
      nameExpr: "vriPickupBrakeFluidLevelOk",
    },
    {
      text: "Is the engine oil level ok?",
      value: statesx?.vriPickupEngineOilLevelOk,
      nameExpr: "vriPickupEngineOilLevelOk",
    },
    {
      text: "Is the power steering fluid level ok?",
      value: statesx?.vriPickupPowerSteeringFluidLevelOk,
      nameExpr: "vriPickupPowerSteeringFluidLevelOk",
    },
    {
      text: "Is the coolant level ok?",
      value: statesx?.vriPickupCoolantLevelOk,
      nameExpr: "vriPickupCoolantLevelOk",
    },
    {
      text: "Is there a Fire Extinguisher?",
      value: statesx?.vriPickupFireExtinguisher,
      nameExpr: "vriPickupFireExtinguisher",
    },
    {
      text: "Is there a fully stocked first aid kit?",
      value: statesx?.vriPickupFirstAidKit,
      nameExpr: "vriPickupFirstAidKit",
    },
    {
      text: "Is the battery ok?",
      value: statesx?.vriPickupBatteryOk,
      nameExpr: "vriPickupBatteryOk",
    },
    {
      text: "Are there any leakages?",
      value: statesx?.vriPickupAnyLeakages,
      nameExpr: "vriPickupAnyLeakages",
    },
    {
      text: "Are the wipers in good condition?",
      value: statesx?.vriPickupWipersInGoodCondition,
      nameExpr: "vriPickupWipersInGoodCondition",
    },
    {
      text: "Are there any dents?",
      value: statesx?.vriPickupAnyDents,
      nameExpr: "vriPickupAnyDents",
    },
    {
      text: "Are there any scratches?",
      value: statesx?.vriPickupAnyScratches,
      nameExpr: "vriPickupAnyScratches",
    },
    {
      text: "Is Insurance valid?",
      value: statesx?.vriPickupInsuranceValid,
      nameExpr: "vriPickupInsuranceValid",
    },
    {
      text: "Is Roadworthy valid?",
      value: statesx?.vriPickupRoadworthyValid,
      nameExpr: "vriPickupRoadworthyValid",
    },
    {
      text: "Is the Driver's license present and valid?",
      value: statesx?.vriPickupDriversLicensePresentAndValid,
      nameExpr: "vriPickupDriversLicensePresentAndValid",
    },
    {
      text: "Are all floor mats intact?",
      value: statesx?.vriPickupAllFloorMatsIntact,
      nameExpr: "vriPickupAllFloorMatsIntact",
    },
    {
      text: "Is the AC in good condition?",
      value: statesx?.vriPickupAcInGoodCondition,
      nameExpr: "vriPickupAcInGoodCondition",
    },
  ];

  
  return (
    <div className="grid grid-rows-6 grid-flow-col gap-2">
      {options.map((option) => {
        return (
          <span className="ml-8 flex items-center align-middle">
            <CheckboxTemlate
            //   indeterminate={indeterminate}
              nameExpr={option.nameExpr}
              withBorders={true}
              customDisabled={false}
              setCheckboxOnchange={(val) => {
                option.value = val;
              }}
              useCallbackFunc={true}
              defaultValue={option.value}
            />
            <p className="ml-1">{option.text}</p>
          </span>
        );
      })}
    </div>
  );
};

export default CheckBoxForm;
