export const vehicle_capture_reg_no = [
    { id: 1, caption: "Reg No", dataField: 'vdtRegNo', dataType: "", fixed: false, width: 120 },
    { id: 2, caption: "Class", dataField: 'vhcShtName', dataType: 'string', fixed: false, width: 80 },
    { id: 3, caption: "Make", dataField: 'vmkShtName', dataType: 'string', fixed: false, width: 80 },
    { id: 4, caption: "Model", dataField: 'mdlShtName', dataType: 'string', fixed: false, width: 80, format: { type: 'fixedPoint', precision: 2 } },
    { id: 5, caption: "Manufacturer", dataField: 'mftShtName', dataType: 'string', fixed: false, width: 80, format: { type: 'fixedPoint', precision: 2 } },
    { id: 6, caption: "Driver", dataField: 'driverEmpName5', dataType: 'string', format: { type: 'fixedPoint', precision: 2 }, fixed: false, width: 120 },
    { id: 7, caption: "Driver INTERCOM.", dataField: 'driverPlc', width: 120, dataType: 'string',  },
    { id: 8, caption: "Approving Officer", dataField: 'ownerEmpName5', dataType: 'string', width: 120,  },
    { id: 9, caption: "Appr. Officer INTERCOM.", dataField: 'ownerPlc', dataType: 'string', width: 120,  },
    { id: 10, caption: "Manuf. Date", dataField: 'vdtManufactureDate', dataType: 'string', width: 120,  },
    { id: 11, caption: "Dept", dataField: 'dptShtName', dataType: 'string', width: 120,  },
    { id: 12, caption: "Section", dataField: 'sxnShtName', dataType: 'string', width: 120,  },
    { id: 13, caption: "Unit", dataField: 'untShtName', dataType: 'string', width: 120,  },
    { id: 14, caption: "Fuel Type", dataField: 'ftpShtName', dataType: 'string', width: 120,  },
    
]

export const dispensedBy_table=[

    {id:1, caption:"Staff No", dataField: "empStaffNo", dataType: "String", width:120},
    {id:2, caption:"Name", dataField: "empName", dataType: "String", width:120},
    {id:3, caption:"Dept.", dataField: "dptShtName", dataType: "String", width:120},
    {id:4, caption:"Section", dataField: "sxnShtName", dataType: "String", width:120},
    {id:5, caption:"job Title", dataField: "jbtShtName", dataType: "String", width:120},
    {id:6, caption:"Location", dataField: "locShtName", dataType: "String", width:120},
]