interface PromptType {
  state: boolean;
  title: string;
  message: string;
  icon: "warning" | "success" | "question";
  okText: string;
  cancelText: string;
  disableCancel: boolean;
}

export const loggingPrompt = (
  status: "Enabled" | "Disabled",
  res: boolean = false
): PromptType => {
  let msg = "";
  let title = "";
  if (!res) {
    title = `${status} Logging`;
    msg = `Are you sure you want to ${status} Audit Logging?`;
  } else {
    title = `Logging ${status}`;
    msg = `Logging ${status} Successfully`;
  }
  return {
    state: true,
    title: title,
    message: msg,
    icon: !res ? "question" : "success",
    okText: !res ? "Yes" : "Ok",
    cancelText: "No",
    disableCancel: res,
  };
};

export const pwdPolicyPrompt = (status: "Enabled" | "Disabled"): PromptType => {
  let title = "";
  let content = "";

  if (status === "Enabled") {
    title = "Enforce Password Policy";
    content = "Enforce the";
  } else {
    title = "Don't Enforce Password Policy";
    content = "Disable Enforcement of";
  }

  return {
    state: true,
    title: title,
    message: `Are you sure you want to ${content} Password Policy?`,
    icon: "question",
    okText: "Yes",
    cancelText: "No",
    disableCancel: false,
  };
};

export const pwdPolicyRes = (res: boolean = false): PromptType => {
  let msg = "";

  if (res) {
    msg = `Password Policy Enforcement activated successfully`;
  } else {
    msg = `Password Enforcement deactivated successfully`;
  }

  return {
    state: true,
    title: `Passwword Enforcement ${res ? "Activated" : "Deactivated"}`,
    message: msg,
    icon: "success",
    okText: "Ok",
    cancelText: "No",
    disableCancel: true,
  };
};

export const advertFormPrompt = (status: "Show" | "Hide"): PromptType => {
  return {
    state: true,
    title: `${status} Advert Form`,
    message: `Are you sure you want to ${status} Advert Form on Startup?`,
    icon: "question",
    okText: "Yes",
    cancelText: "No",
    disableCancel: false,
  };
};

export const advertFormRes = (res: boolean = false): PromptType => {
  return {
    state: true,
    title: `Advert Form ${res ? "Will Show" : "Hidden"}`,
    message: `Advert Form will ${res ? "show" : "be hidden"} on Startup`,
    icon: "success",
    okText: "Ok",
    cancelText: "No",
    disableCancel: true,
  };
};
export const saveSearchAuditLogPrompt = (
  status: "Enabled" | "Disabled",
  res: boolean = false
): PromptType => {
  let msg = "";
  let title = "";
  if (!res) {
    title = `${status} Logging`;
    msg = `Are you sure you want to ${status} Save search audit Log?`;
  } else {
    title = `Save search audit Log${status}`;
    msg = `Save search audit Logging ${status} Successfully`;
  }
  return {
    state: true,
    title: title,
    message: msg,
    icon: !res ? "question" : "success",
    okText: !res ? "Yes" : "Ok",
    cancelText: "No",
    disableCancel: res,
  };
};
export const saveEditAuditLogPrompt = (
  status: "Enabled" | "Disabled",
  res: boolean = false
): PromptType => {
  let msg = "";
  let title = "";
  if (!res) {
    title = `${status} Logging`;
    msg = `Are you sure you want to ${status} save Edit Audit Log?`;
  } else {
    title = `Save Edit Audit Log ${status}`;
    msg = `Save Edit Audit Logging ${status} Successfully`;
  }
  return {
    state: true,
    title: title,
    message: msg,
    icon: !res ? "question" : "success",
    okText: !res ? "Yes" : "Ok",
    cancelText: "No",
    disableCancel: res,
  };
};
export const saveNewRecordAuditLogPrompt = (
  status: "Enabled" | "Disabled",
  res: boolean = false
): PromptType => {
  let msg = "";
  let title = "";
  if (!res) {
    title = `${status} Logging`;
    msg = `Are you sure you want to ${status} Save New Record Audit Log?`;
  } else {
    title = `Save New Record Audit Log ${status}`;
    msg = `Save New Record Audit Logging ${status} Successfully`;
  }
  return {
    state: true,
    title: title,
    message: msg,
    icon: !res ? "question" : "success",
    okText: !res ? "Yes" : "Ok",
    cancelText: "No",
    disableCancel: res,
  };
};