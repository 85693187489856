import { GetFormAccessPrivileges, GreateGenAccessPrivileges, MaintenaceChecklistInterface } from "./interfaces";

/**
 * A class that maps entities to interfaces
 */
export default class entityMapper {

    toCreateGenAccessPrivPOST(dataSource: GetFormAccessPrivileges[], userID: number): GreateGenAccessPrivileges[] {

        return dataSource.map((item) => {
            return {
                apvUsaIdfk: userID,
                apvFormIdfk: item.afmIDpk,
                apvInsert: item.apvInsert,
                apvUpdate: item.apvUpdate,
                apvRead: item.apvRead,
                apvPrint: item.apvPrint,
                apvDelete: false,
            }
        });

    }

    toMaintenanceCheckType(data: MaintenaceChecklistInterface[]): any[] {
        return data?.map((item) => {
            return {
                id: item.mtiIDpk,
                mtiIDpk: item.mtiIDpk,
                mtiName: item.mtiName,

                pidBefore: false,
                pidAfter: false, 
            }
        });
    }    


}
