/* eslint-disable react/jsx-pascal-case */
import { useState, useRef, useEffect, useCallback } from "react";
import { Form, Input, Select, Image, InputNumber } from "antd";
import reset from "../../../../../../../assets/reset.png";

import {
  SaveButton,
  NewButton,
  DispenseButton,
  Cancel,
  UpdateButton,
} from "../../../../../../accessories/buttons";
import {
  formModalStates,
  inputsTypes,
  selectsTypes,
} from "../../../../../../human-resource/setups/data/types/selectsTypes";
import { SelectsTemplate } from "../../../../../../../templates/select";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import { TextareaTemplate } from "../../../../../../../templates/textarea";
import { InputsTemplate } from "../../../../../../../templates/input";
import { DateTemplate } from "../../../../../../../templates/date";
import useFetch from "../../../../../../../hooks/useFetch";
import {
  saveModalData,
  updateModalData,
} from "../../../../../../accessories/component_infos";
import dayjs, { Dayjs } from "dayjs";
import { PostDataFunc } from "../../../../../../../functions/post";
import { UpdateDataFunc } from "../../../../../../../functions/update";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import {
  authorizeBy_cols,
  ss_cols,
  vehicleF_cols,
} from "../data/datagrid_cols";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import { useDispatch } from "react-redux";
import { setGeneralValue } from "../../../../../../../features/generalSlice";

import { DropdownGrid_template } from "../../../../../../../templates/DropdownGrid";
import { CheckboxTemlate } from "../../../../../../../templates/checkbox";
import invalidImage from "../../../../../../../assets/none.jpg";
import { FormModal } from "../../../../../../accessories/formModal";
import { useCrudFunc } from "../../../../../../../functions/crud";
import { useBusyLoader } from "../../../../../../../hooks/useBusyLoader";
import {
  fetchProductDump,
  fetchSPTypeDump,
} from "../../../../../../../features/apiCalls/FuelReturnRequestApi/ReturnReqDumb";
import InputSelect_template from "../../../../../../../templates/InputSelect";
import { debounce } from "lodash";
import { Tooltip } from "devextreme-react";
import DispenseForm from "./dispens";

interface props {
  statesx?: any;
  statesxx?: any;
  updateStatesx?: (key: string, value: any) => void;
  setRefreshx?: (e: boolean) => void;
  refereshx?: boolean;
  purchaseModeChange: (e: string) => void;
}

interface stateTypes {
  purMode?: any;
  reqDate?: string;
  vehNo?: { id: number; name: string };
  sp?: any;
  ss?: { id: number; name: string };
  prod?: any;
  untP: number;
  quan1: number;
  quan2: number;
  bal?: number;
  unit: number;
  total: number;
  uom?: { id: number; name: string };
  coupon?: string;
  authBy?: { id: number; name: string };
  rec?: { id: number; name: string };
  dept?: { id: number; name: string };
  sxn?: { id: number; name: string };
  cost?: { id: number; name: string };
  prevOdom: number;
  curOdom: number;
  active?: boolean;
  remarks?: string;
  remarks2?: string;
  disp?: number;
  selectedDate1?: Dayjs;
  password?: string;

  purchaseMode: boolean;
  vehicleRegNo: boolean;
  serviceProvider: boolean;
  serviceStation: boolean;
  product: boolean;
  quantity: boolean;
  authorizedBy: boolean;
  receivedBy: boolean;
  department: boolean;
  section: boolean;
  costCenter: boolean;

  dispenseFormState: boolean;

  isOpenDropdown: boolean;
  isOpenDropdown1: boolean;
  isLoading?: boolean;
}

export const FuelDump_Transaction_Details_Form = ({
  statesx,
  updateStatesx,
  statesxx,
  setRefreshx,
  refereshx,
  purchaseModeChange,
}: props) => {
  const { TextArea } = Input;

  const dispatch = useDispatch();

  const form_disable = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const FormCode = useSelector((state: RootState) => state.user?.formCode);
  const update_state_change = useSelector(
    (state: RootState) => state.general.updateMode
  );
  const [employeeId, userId, userInfo] = useAccountInfo();
  const [privType, setPrivType] = useState<string>("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);
  const [showImages, setShowImages] = useState(false);

  const refs = useRef<any>(null);

  const editedDate = dayjs().format();

  const [states, setStates] = useState<stateTypes>({
    purMode: {
      id: statesxx !== undefined ? statesxx.purModeState?.id : 2,
      name: statesxx !== undefined ? statesxx.purModeState?.name : "Fuel Dump",
    },

    reqDate: "",
    vehNo: { id: 0, name: "" },
    sp: { id: 0, name: "" },
    ss: { id: 0, name: "" },
    prod: { id: 0, name: "" },
    untP: 0,
    quan1: 0,
    quan2: 0,
    bal: 0,
    unit: 0,
    total: 0,
    uom: { id: 0, name: "" },
    coupon: "",
    authBy: { id: 0, name: "" },
    rec: { id: userInfo.empIDpk, name: userInfo.empName },
    dept: { id: 0, name: "" },
    sxn: { id: 0, name: "" },
    cost: { id: 0, name: "" },
    prevOdom: 0,
    curOdom: 0,
    active: true,
    remarks: "",
    remarks2: "",
    disp: 0,
    selectedDate1: dayjs(),
    password: "",

    purchaseMode: false,
    vehicleRegNo: false,
    serviceProvider: false,
    serviceStation: false,
    product: false,
    quantity: false,
    authorizedBy: false,
    receivedBy: false,
    department: false,
    section: false,
    costCenter: false,

    dispenseFormState: false,

    isOpenDropdown: false,
    isOpenDropdown1: false,
    isLoading: false,
  });
  console.log("ppp", states);

  const setState = (key: string, value: any) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };

  const close = async () => {
    refs.current!.instance.close();
  };

  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  const [pur_data] = useFetch(
    "AsmTmsEntFuelManagement/GetEntAsmTmsLuePurchaseModes",
    states.purchaseMode
  );
  const [vehNo_data] = useFetch(
    `FuelManagement/dumpVehiclesAll`,
    states.vehicleRegNo
  );
  const [sp_data] = useFetch(
    "AsmTmsEntFuelManagement/GetEntAsmTmsLueServiceProvides",
    states.serviceProvider
  );
  const [ss_data] = useFetch(
    `FuelManagement/dumpServiceStations?spdIDx=${states.sp?.id}`,
    states.serviceStation
  );
  const [prod_data] = useFetch(
    `FuelManagement/dumpAllProducts`,
    states.product
  );
  const [purBy_data, purBy_error, purBy_loading] = useFetch(
    `FuelManagement/dumpGetPurchasedByAll`,
    states.purchaseMode
  );
  const [uom_data] = useFetch(
    "AsmTmsEntFuelManagement/GetEntAsmTmsLueFuelUnits",
    states.quantity
  );
  console.log("uom", uom_data);
  const [auth_data] = useFetch(
    `FuelManagement/authorizeBy?PageNumber=1&PageSize=20&Mode=${
      form_disable ? 1 : update_state_change ? 1 : 1
    }&provider=${states?.sp?.id}&station=${states?.ss?.id}`,
    states.authorizedBy
  );
  const [rec_data] = useFetch(
    "AsmTmsEntFuelManagement/GetEntAsmTmsLueReceivedBy",
    states.receivedBy
  );
  const [dept_data] = useFetch(
    "AsmTmsEntFuelManagement/GetEntAsmTmsLueDumpDebits",
    states.department
  );
  const [sxn_data] = useFetch(
    `AsmTmsEntFuelManagement/GetEntAsmTmsLueDumpDebitSections?debitDepartmentId=${states.dept?.id}`,
    states.section
  );
  const [cost_data] = useFetch(
    `AsmTmsEntFuelManagement/GetEntAsmTmsLueDumpDebitCostCenters?debitDepartmentId=${states.dept?.id}&debitSectionId=${states.sxn?.id}`,
    states.costCenter
  );
  const [untP_data] = useFetch(
    `FuelManagement/dumpUnitPrice?spdIDx=${
      states !== undefined ? states.sp?.id : 0
    }&prdIDx=${states !== undefined ? states.prod?.id : 0}&sstIDx=${
      states !== undefined ? states.ss?.id : 0
    }`
  );
  const [image_data] = useFetch(
    `AssetTamVehicleDetail/GetImagesAssetTamVehicleDetails?vdtIDpk=${statesxx.selectedRecord?.vdtIdpk}&vimActive=true`
  );

  const [lookups] = useFetch(
    `FuelManagement/GetFuelDumpLookup?PageNumber=1&PageSize=20&spdIDx=${
      states.sp.id
    }&Mode=${form_disable ? 1 : update_state_change ? 1 : 1}&purchaseMode=${
      states.purMode?.name
    }&provider=${states?.sp?.id}&station=${states?.ss?.id}`
  );

  const spData = useSelector(
    (state: RootState) => state.returnDump.serviceProviderData
  );
  const prodData = useSelector(
    (state: RootState) => state.returnDump.productData
  );

  const pur: selectsTypes[] = [
    {
      id: 0,
      label: "Purchase Mode",
      optionsData:
        states.purMode.length > 0 ? pur_data : lookups.dumpGetPurchaseModes,
      idExpr: "pcmIdpk",
      dataExpr: "pcmName",
      stateName: "purMode",
      defaultValue: states.purMode?.name,
      refreshStateName: "purchaseMode",
      refresh: states.purchaseMode,
    },
  ];

  const dates: inputsTypes[] = [
    {
      id: 0,
      label: "Request Date",
      stateName: "reqDate",
      defaultValue: states.reqDate,
    },
  ];

  const selects: selectsTypes[] = [
    {
      id: 1,
      label: "Vehicle Reg. No",
      optionsData: vehNo_data,
      idExpr: "vdtIDpk",
      dataExpr: "vdtRegNo",
      stateName: "vehNo",
      defaultValue: states.vehNo?.name,
      style: "80%",
      refreshStateName: "vehicleRegNo",
      refresh: states.vehicleRegNo,
    },
    {
      id: 2,
      label: "Service Provider",
      optionsData: states.sp.length > 0 ? spData : lookups.dumpServiceProviders,
      idExpr: "spdIDpk",
      dataExpr: "spdName",
      stateName: "sp",
      defaultValue: states.sp?.name,
      style: "80%",
      refreshStateName: "serviceProvider",
      refresh: states.serviceProvider,
      isOpenDropdown: states.isOpenDropdown,
      isOpenDropdownKey: "isOpenDropdown",
      singleEndpoint: fetchSPTypeDump,
    },
    {
      id: 3,
      label: "Service Station",
      optionsData: ss_data,
      idExpr: "sstIDpk",
      dataExpr: "sstName",
      stateName: "ss",
      defaultValue: states.ss?.name,
      style: "80%",
      refreshStateName: "serviceStation",
      refresh: states.serviceStation,
    },
    {
      id: 4,
      label: "Product",
      optionsData: states.prod.length > 0 ? prodData : lookups.dumpAllProducts,
      idExpr: "prdIdpk",
      dataExpr: "prdName",
      stateName: "prod",
      defaultValue: states.prod?.name,
      style: "80%",
      refreshStateName: "product",
      refresh: states.product,
      isOpenDropdown: states.isOpenDropdown1,
      isOpenDropdownKey: "isOpenDropdown1",
      singleEndpoint: fetchProductDump,
    },
  ];

  const rightSelects: selectsTypes[] = [
    {
      id: 1,
      label: "Authorized By",
      optionsData: auth_data,
      idExpr: "empIDpk",
      dataExpr: "empName",
      stateName: "authBy",
      defaultValue: states.authBy?.name,
      refreshStateName: "authorizedBy",
      refresh: states.authorizedBy,
    },
    {
      id: 2,
      label: "Received By",
      optionsData: rec_data,
      idExpr: "empIdpk",
      dataExpr: "empName",
      stateName: "rec",
      defaultValue: states.rec?.name,
      refreshStateName: "receivedBy",
      refresh: states.receivedBy,
    },
  ];

  const Rightinputs: inputsTypes[] = [
    {
      id: 0,
      label: "Prev. Odom Reading",
      stateName: "prevOdom",
      defaultValue: Number(states.prevOdom).toFixed(2),
    },
    {
      id: 1,
      label: "Current Odom Reading",
      stateName: "curOdom",
      defaultValue: Number(states.curOdom).toFixed(2),
    },
  ];

  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [Posting, Updating] = useCrudFunc();
  const [busyLoader, setBusyLoader] = useBusyLoader();

  const populateFields = (clear?: boolean) => {
    const gridState = [
      {
        key: "purMode",
        value: clear
          ? {
              id: statesxx !== undefined ? statesxx.purModeState?.id : 2,
              name:
                statesxx !== undefined
                  ? statesxx.purModeState?.name
                  : "Fuel Dump",
            }
          : {
              id: statesxx?.selectedRecord?.pcmIDpk,
              name: statesxx?.selectedRecord?.pcmName,
            },
      },
      {
        key: "reqDate",
        value: clear ? editedDate : statesxx.selectedRecord?.fmgDate,
      },
      {
        key: "vehNo",
        value: clear
          ? { id: 0, name: "" }
          : {
              id: statesxx?.selectedRecord?.vdtIDpk,
              name: statesxx?.selectedRecord?.vdtRegNo,
            },
      },
      {
        key: "purBy",
        value: clear
          ? { id: 0, name: "" }
          : {
              id: statesxx?.selectedRecord?.driverEmpID,
              name: statesxx?.selectedRecord?.driverName,
            },
      },
      {
        key: "sp",
        value: clear
          ? { id: 0, name: "" }
          : {
              id: statesxx?.selectedRecord?.spdIDpk,
              name: statesxx?.selectedRecord?.spdName,
            },
      },
      {
        key: "ss",
        value: clear
          ? { id: 0, name: "" }
          : {
              id: statesxx?.selectedRecord?.sstIDpk,
              name: statesxx?.selectedRecord?.sstName,
            },
      },
      {
        key: "prod",
        value: clear
          ? { id: 0, name: "" }
          : {
              id: statesxx?.selectedRecord?.prdIDpk,
              name: statesxx?.selectedRecord?.prdName,
            },
      },
      {
        key: "coupon",
        value: clear ? "" : statesxx?.selectedRecord?.fmgReceiptNo,
      },
      {
        key: "quan1",
        value: clear ? 0 : statesxx?.selectedRecord?.fmgQuantityRequested,
      },
      {
        key: "quan2",
        value: clear ? 0 : statesxx?.selectedRecord?.fmgQuantityAuthorised,
      },
      {
        key: "unit",
        value: clear
          ? 0
          : statesxx?.selectedRecord?.fmgAmount /
            statesxx?.selectedRecord?.fmgQuantityRequested,
      },
      {
        key: "uom",
        value: clear
          ? ""
          : {
              id: statesxx?.selectedRecord?.funIDpk,
              name: statesxx?.selectedRecord?.funShtName,
            },
      },
      {
        key: "total",
        value: clear ? 0 : statesxx?.selectedRecord?.fmgAmount,
      },
      { key: "bal", value: clear ? 0 : statesxx?.selectedRecord?.fmgBalance },

      {
        key: "authBy",
        value: clear
          ? ""
          : {
              id: statesxx?.selectedRecord?.authoriserEmpID,
              name: statesxx?.selectedRecord?.authoriserEmpName,
            },
      },
      {
        key: "dept",
        value: clear
          ? ""
          : {
              id: statesxx?.selectedRecord?.costCenterDeptID,
              name: statesxx?.selectedRecord?.costCenterDeptName,
            },
      },
      {
        key: "sxn",
        value: clear
          ? ""
          : {
              id: statesxx?.selectedRecord?.sxnIdpk,
              name: statesxx?.selectedRecord?.costCenterSxnName,
            },
      },
      {
        key: "cost",
        value: clear
          ? ""
          : {
              id: statesxx?.selectedRecord?.cctIdpk,
              name: statesxx?.selectedRecord?.cctName,
            },
      },
      {
        key: "rec",
        value: clear
          ? { id: userInfo.empIDpk, name: userInfo.empName }
          : {
              id: statesxx?.selectedRecord?.driverEmpID,
              name: statesxx?.selectedRecord?.driverName,
            },
      },
      {
        key: "prevOdom",
        value: clear ? 0 : statesxx?.selectedRecord?.fmgPreviousOdometerReading,
      },
      {
        key: "curOdom",
        value: clear ? 0 : statesxx?.selectedRecord?.fmgCurrentOdometerReading,
      },
      {
        key: "remarks",
        value: clear ? "" : statesxx?.selectedRecord?.fmgRmks,
      },
    ];

    for (let instance of gridState) setState(instance.key, instance.value);
  };

  const validationForms = () => {
    setIcon("warning");
    if (
      statesxx.purModeState?.id === 0 ||
      statesxx.purModeState?.id === undefined ||
      statesxx.purModeState?.id === null
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select purchase mode field.",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.vehNo?.id === 0 || states.vehNo?.id === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select vehicle reg.No field.",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.purMode?.id === 0 || states.purMode?.id === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select purchased by field.",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.sp?.id === 0 || states.sp?.id === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select service provider field.",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.ss?.id === 0 || states.ss?.id === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select service station field.",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.prod?.id === 0 || states.prod?.id === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select product field.",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      states.reqDate === "" ||
      dayjs(states.reqDate).isAfter(dayjs().endOf("day"))
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message:
          "Please select Transaction Field, Date cannot be greater than today.",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.authBy?.id === 0) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select authorise by field.",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.rec?.id === 0) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select received by field.",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      !states.quan1 ||
      states.quan1 === undefined ||
      states.quan1 < 0 ||
      isNaN(Number(states.quan1))
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message:
          states.quan1 < 0
            ? "Please, quantity requested cannot be negative."
            : isNaN(Number(states.quan1))
            ? "Please, enter a valid number for quantity requested."
            : "Please, enter quantity requested.",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.uom?.id === 0 || states.uom?.id === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select fuel unit of measure field.",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (
      !states.unit ||
      states.unit === undefined ||
      states.unit < 0 ||
      isNaN(Number(states.unit))
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message:
          states.unit < 0
            ? "Please, unit price cannot be negative."
            : isNaN(Number(states.unit))
            ? "Please, enter a valid number for unit price."
            : "Please, enter unit price.",
        title: "Required Field",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.authBy?.id === 0 || states.authBy?.id === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select authorise by field.",
        title: "Required Field!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else {
      setshowModalDisableButton(false);
      setIcon("question");
      setOpenModal(true);
    }
  };

  const newAccessPriv = () => {
    setPrivType("save");
    if (savePriv === true) {
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      updateStatesx!("selectedRecord", {});
    } else {
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      setIcon("warning");
      setOpenModal(true);
      setModalData({ title: "Access Denied!", message: "No Access Privilege" });
    }
  };

  const saveAccessPriv = () => {
    setPrivType("save");
    if (savePriv === true) {
      dispatch(setGeneralValue({ expr: "updateMode", value: false }));
      setIcon("question");
      setModalData({
        message: "Are you sure you want to save this request?",
        title: "Save Request",
        okText: "Ok",
      });
      setOpenModal(true);
      validationForms();
    } else {
      setIcon("warning");
      setOpenModal(true);
      setModalData({ title: "Access Denied!", message: "No Access Privilege" });
    }
  };

  const updateAcccessPriv = () => {
    setPrivType("update");
    if (updatePriv === true) {
      dispatch(setGeneralValue({ expr: "updateMode", value: true }));
      setIcon("question");
      setModalData({
        message: "Are you sure you want to update this request?",
        title: "Update Request",
        okText: "Ok",
      });
      setOpenModal(true);
      validationForms();
    } else {
      setIcon("warning");
      setOpenModal(true);
      setModalData({ title: "Access Denied!", message: "No Access Privilege" });
    }
  };

  const checkConnection = () => {
    if (!navigator.onLine) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message:
          "Your computer is not connected to the internet. Please check your internet connection and try again.",
        title: "Connectivity Error!",
        okText: "Ok",
      });
    }
  };

  const message = `Fuel request from ${userInfo.empName} is pending your authorisation`;

  let mobNox = "";

  const sendSms = async () => {
    try {
      checkConnection();
      if (statesxx.selectedRecord?.driverEmpID) {
        mobNox = statesxx.selectedRecord?.dispenserMobNox
          .trim()
          .replace("", "");
      }
      if (mobNox.trim.length >= 10) {
        mobNox = mobNox.substring(0, 10);
      }

      await Posting(`SMSAlert/SaveSMS`, {
        imsMessage: message,
        imsSender: userInfo.empName,
        imsRecordIdfk: statesxx.selectedRecord.fmgIDpk,
        imsFormCode: FormCode,
        imsMessageType: "string",
        imsMessageClass: "string",
        imsRecipientEmpIdfk: statesxx.selectedRecord.driverEmpID,
        imsInitiatorEmpIdfk: userId,
        imsCreatedBy: userId,
      });

      await Posting(`USCXGenAlerts/SaveAlert`, {
        recipientEmpID: statesxx.selectedRecord.driverEmpID,
        altFormCode: FormCode,
        altMessage: message,
        altTakeAction: true,
      });

      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: "SMS sent successfully",
        title: "Success",
        okText: "Ok",
      });
    } catch {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: "Failed to send alert!.. Please contact your administrator.",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };

  const postData = async () => {
    setBusyLoader("Saving Request...");
    try {
      const res = await Posting(
        "FuelManagement/CreateDumpFuelManagement",
        {
          fmgDate: editedDate,
          fmgVehicleDetailIDfk: states.vehNo?.id,
          fmgDriverIDfk: states.rec?.id,
          fmgDispenserEmpIDfk: 0,
          fmgServiceProviderIDfk: states.sp?.id,
          fmgServiceStationIDfk: states.ss?.id,
          fmgProductIDfk: states.prod?.id,
          fmgFuelUnitIDfk: states.uom?.id,
          fmgAuthoriserEmpIDfk: states.authBy?.id,
          fmgPurchaseModeIDfk: states.purMode?.id,
          fmgCardIDfk: 0,
          fmgAmount: Number(states.total).toFixed(2),
          fmgBalance: 0,
          fmgQuantityRequested: Number(states.quan1).toFixed(2),
          fmgReceiptNo: states.coupon,
          fmgVehicleAssignmentIDfk: 0,
          fmgAuthorised: false,
          fmgRmks: states.remarks,
          fmgCreatedBy: userId,
        },
        `Created the Asset Mgr-Transport-Fuel Transaction Log (Dump) with Purchase Mode ${
          states.purMode?.name
        }, Request Date ${editedDate}, Vehicle Reg No ${
          states.vehNo?.name
        }, Service Provider ${states.sp?.name}, Service Station ${
          states.ss?.name
        }, Product ${states.prod?.name}, Fuel Unit ${
          states.uom?.name
        }, Authorised By ${states.authBy?.name}, Unit Price ${
          states.total / states.quan1
        }, Total Amount ${Number(states.total).toFixed(
          2
        )}, Quantity Requested ${Number(states.quan1).toFixed(2)}, Remarks ${
          states.remarks
        }, Received by ${states.rec.name} successfully`
      );
      sendSms();
      setRefresh(!refresh);
      setRefreshx && setRefreshx(!refereshx);
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: "Request saved successfully.",
        title: "Request Saved",
        okText: "Ok",
      });
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      dispatch(setGeneralValue({ expr: "updateMode", value: false }));
    } catch (err: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: err.response
          ? err.response.data.errors.InsertFMDto
          : "Server Error",
        title: err.response ? err.response.data.title : "Unexpected Error!",
        okText: "Ok",
      });
    } finally {
      setBusyLoader("");
    }
  };

  const updateData = async () => {
    setBusyLoader("Updating Request...");
    try {
      await Updating(
        `FuelManagement/UpdateDumpFuelManagement?Id=${statesxx.selectedRecord?.fmgIDpk}`,
        {
          fmgDate: editedDate,
          fmgVehicleDetailIDfk: states.vehNo?.id,
          fmgDriverIDfk: states.rec?.id,
          fmgDispenserEmpIDfk: 0,
          fmgServiceProviderIDfk: states.sp?.id,
          fmgServiceStationIDfk: states.ss?.id,
          fmgProductIDfk: states.prod?.id,
          fmgAuthoriserEmpIDfk: states.authBy?.id,
          fmgPurchaseModeIDfk: states.purMode?.id,
          fmgCardIDfk: 0,
          fmgFuelUnitIDfk: states.uom?.id,
          fmgAmount: Number(states.total).toFixed(2),
          fmgBalance: 0,
          fmgQuantityRequested: Number(states.quan1).toFixed(2),
          fmgCurrentOdometerReading: Number(states.curOdom).toFixed(2),
          fmgPreviousOdometerReading: Number(states.prevOdom).toFixed(2),
          fmgReceiptNo: states.coupon,
          fmgVehicleAssignmentIDfk: 0,
          fmgAuthorised: statesxx.fmgAuthorised ? true : false,
          fmgRmks: states.remarks,
          fmgCreatedBy: userId,
        },
        `Edited the Asset Mgr-Transport-Fuel Dump Log with Purchase Mode ${
          states.purMode?.name
        }, Request Date ${editedDate}, Vehicle Reg No ${
          states.vehNo?.name
        }, Service Provider ${states.sp?.name}, Service Station ${
          states.ss?.name
        }, Product ${states.prod?.name}, Fuel Unit ${
          states.uom?.name
        }, Authorised By ${states.authBy?.name}, Unit Price ${
          states.total / states.quan1
        }, Total Amount ${Number(states.total).toFixed(
          2
        )}, Quantity Requested ${Number(states.quan1).toFixed(2)}, Remarks ${
          states.remarks
        }, Received by ${states.rec.name} successfully`
      );
      setRefresh(!refresh);
      setRefreshx && setRefreshx(!refereshx);
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: "Request updated successfully!",
        title: "Request Updated",
        okText: "Ok",
      });
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      dispatch(setGeneralValue({ expr: "updateMode", value: false }));
    } catch (err: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: err.response
          ? err.response.data.errors.InsertFMDto
          : "Server Error",
        title: err.response ? err.response.data.title : "Unexpected Error!",
        okText: "Ok",
      });
    } finally {
      setBusyLoader("");
    }
  };

  useEffect(() => {
    if (
      statesxx.selectedRecord !== undefined &&
      Object.keys(statesxx.selectedRecord).length === 0
    ) {
      populateFields(true);
    } else {
      populateFields(false);
    }
  }, [statesxx?.selectedRecord]);

  

  const formModal: formModalStates[] = [
    { id: 0, label: "Dispense Fuel", module: <DispenseForm setRefreshx={setRefreshx} statesxx={statesxx} refereshx={refereshx} /> },
  ];

  return (
    <div
      style={{ borderColor: borderTheme, width: "100%", height: "100%" }}
      className=" border-[1px]  dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base  "
    >
      <>
        <ModalTemplate
          icon={icon}
          cancelText={modalData.cancelText}
          disableCancel={
            icon === "warning" || icon === "success" ? true : false
          }
          cancelHandler={() => {
            setOpenModal(false);
          }}
          open={openModal}
          okHandler={() => {
            modalData.title === 'Save Request'
              ? postData()
              : modalData.title === 'Update Request'
              ? updateData()
              : setOpenModal(false);
          }}
          message={modalData.message}
          okText={modalData.okText}
          title={modalData.title}
        />

        <FormModal
          height={1000}
          width={"30%"}
          openState={states.dispenseFormState}
          onCancel={() => {
            setState("dispenseFormState", false);
          }}
          child={formModal[0].module}
          title={formModal[0].label}
        />

        <div
          style={{ maxHeight: "280px", minHeight: "280px", height: "280px" }}
          className=" items-start justify-start "
        >
          <Form
            className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base ml-1 rounded py-2 px-4"
            name="basic"
            labelCol={{ span: 7 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            size="small"
            layout="horizontal"
          >
            <div style={{ width: "100%" }} className=" flex ">
              <div style={{ width: "37%" }} className="">
                {pur.map(
                  ({
                    id,
                    label,
                    idExpr,
                    dataExpr,
                    optionsData,
                    stateName,
                    defaultValue,
                    refresh,
                    refreshStateName,
                  }) => {
                    return (
                      <>
                        <SelectsTemplate
                          cusWidth={"80%"}
                          disabledCustomWidth={true}
                          label={label}
                          options={optionsData}
                          idexpr={idExpr}
                          dataexp={dataExpr}
                          placeHolder={defaultValue}
                          useCallFunc
                          disabled={form_disable}
                          selectedValue={(e) => {
                            purchaseModeChange(e);
                          }}
                          handleClear={() => {
                            setState(stateName!, { id: 0, name: "" });
                            updateStatesx &&
                              updateStatesx("purModeState", {
                                id: 0,
                                name: "",
                              });
                          }}
                          handleRefresh={() => {
                            setState(refreshStateName!, !refresh);
                            updateStatesx &&
                              updateStatesx("purModeState", {
                                id: 0,
                                name: "",
                              });
                          }}
                        />
                      </>
                    );
                  }
                )}

                {dates.map(({ id, label, stateName, defaultValue }) => {
                  return (
                    <>
                      <DateTemplate
                        label={label}
                        width={"80%"}
                        changeDate={(e: Dayjs) => {
                          setState(stateName!, dayjs(e).format());
                        }}
                        selectedDate={dayjs(defaultValue)}
                        disabled={true}
                        format={"DD MMMM YYYY HH:mm"}
                      />
                    </>
                  );
                })}
                <>
                  {selects.map(
                    ({
                      id,
                      label,
                      idExpr,
                      dataExpr,
                      optionsData,
                      stateName,
                      defaultValue,
                      style,
                      refresh,
                      refreshStateName,
                      isOpenDropdown,
                      isOpenDropdownKey,
                      singleEndpoint,
                    }) => {
                      const total_selects = selects?.length;

                      return (
                        <div className={`w-full ${id === 1 ? `mb-0.5` : ``} `}>
                          {id == 1 || id == 3 ? (
                            <div className="w-full">
                              <DropdownGrid_template
                                KeyExpr={idExpr}
                                label={label}
                                cusBtnStyle={true}
                                dropdownBtnStyles={{ width: "100%" }}
                                className={`w-[400%] ${
                                  id === 3 ? "mb-0.5" : ""
                                }`}
                                labelCol={7}
                                columns={id == 1 ? vehicleF_cols : ss_cols}
                                disabled={form_disable ? true : false}
                                gridData={optionsData}
                                defaultValue={defaultValue}
                                handleRefresh={() => {
                                  setState(stateName!, { id: 0, name: "" });
                                  setState(refreshStateName!, !refresh);
                                }}
                                onRowClick={(e) => {
                                  setState(stateName!, {
                                    id: e[idExpr!],
                                    name: e[dataExpr!],
                                  });
                                  close();
                                }}
                              />
                            </div>
                          ) : (
                            <>
                              <InputSelect_template
                                cusWidth={"80%"}
                                disabledCustomWidth={true}
                                label={label}
                                options={optionsData}
                                idexpr={idExpr}
                                dataexp={dataExpr}
                                placeHolder={defaultValue}
                                useCallFunc
                                disabled={form_disable}
                                selectedValue={(e) => {
                                  setState(isOpenDropdownKey, false);
                                  setState(stateName!, {
                                    id: e[idExpr!],
                                    name: e[dataExpr!],
                                  });
                                  setState("untP", {
                                    id:
                                      untP_data.length != 0
                                        ? untP_data[0]?.pprIdpk
                                        : 0,
                                    name:
                                      untP_data.length != 0
                                        ? untP_data[0]?.pprUnitPrice
                                        : 0,
                                  });
                                }}
                                handleClear={() => {
                                  setState(stateName!, { id: 0, name: "" });
                                  setState("untP", { id: 0, name: 0 });
                                }}
                                handleRefresh={() => {
                                  setState(refreshStateName!, !refresh);
                                  setState("untP", { id: 0, name: 0 });
                                }}
                                isOpenDropdown={isOpenDropdown}
                                isLoading={states.isLoading}
                                handleFocus={(e: any) => {
                                  setState(isOpenDropdownKey, !isOpenDropdown);
                                }}
                                handleSearch={
                                  debounce((e) => {
                                    setState(stateName!, e);

                                    dispatch(singleEndpoint(e));

                                    setState(isOpenDropdownKey, true);
                                  }, 500) // Adjust the debounce delay as needed
                                }
                              />
                            </>
                          )}
                        </div>
                      );
                    }
                  )}
                </>

                <Form.Item
                  label={
                    <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                      {"Quantity/UoM"}
                    </p>
                  }
                  className="mb-0.5"
                >
                  <li className="w-full flex items-center flex-row  ">
                    <div className="w-full flex ">
                      <Input
                        // type="number"
                        disabled={form_disable ? true : false}
                        style={{ width: "26%" }}
                        value={states.quan1}
                        onChange={(e: any) => {
                          setState("quan1", e?.target.value);
                          setState("total", e?.target.value * states.unit);
                        }}
                        className=" h-6"
                      />
                      <Input
                        // type="number"
                        disabled={true}
                        style={{ width: "26%" }}
                        value={states.quan2}
                        onChange={(e) => {
                          setState("quan2", e.target.value);
                        }}
                        className="ml-1 h-6 "
                      />
                      <SelectsTemplate
                        style={{ width: "42.5%" }}
                        selectStyle={{ width: "60%", marginLeft: 5 }}
                        disabledCustomWidth={true}
                        label=""
                        options={uom_data}
                        idexpr={"funIdpk"}
                        dataexp={"funShtName"}
                        placeHolder={states.uom?.name}
                        useCallFunc
                        disabled={form_disable}
                        selectedValue={(e) => {
                          setState("uom", {
                            id: e["funIdpk"],
                            name: e["funShtName"],
                          });
                        }}
                        handleClear={() => {
                          setState("uom", { id: 0, name: "" });
                          setState("quan1", 0);
                          setState("quan2", 0);
                        }}
                      />
                    </div>
                  </li>
                </Form.Item>

                <Form.Item
                  wrapperCol={{ span: 15 }}
                  label={
                    <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                      {"Unit Price/Total Amount"}
                    </p>
                  }
                  className="mb-0.5"
                >
                  <li className=" flex items-center justify-between w-[90%] gap-1">
                    <InputNumber
                      disabled={form_disable ? true : false}
                      type="number"
                      value={states.unit}
                      onChange={(e: any) => {
                        setState("unit", e?.toFixed(2));
                        setState("total", states.quan1 * e?.toFixed(2));
                      }}
                      style={{ width: "50%" }}
                      className="bg-yellow-200"
                    />

                    <InputNumber
                      disabled={true}
                      value={states.total}
                      onChange={(e) => {
                        setState("total", e?.toFixed(2));
                      }}
                      size="small"
                      style={{ width: "50%" }}
                      className="bg-yellow-200"
                    />
                  </li>
                </Form.Item>

                <Form.Item
                  labelCol={{ span: 2 }}
                  style={{
                    width: `calc(265% - 0.88%)`,
                    marginLeft: "7%",
                    marginTop: 2,
                  }}
                  label={
                    <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                      {"Remarks"}
                    </p>
                  }
                  className="mb-0.5"
                >
                  <div className="flex flex-row  w-full ">
                    <TextArea
                      style={{
                        resize: "none",
                        height: 40,
                        width: `calc(100%)`,
                      }}
                      className=""
                      readOnly={form_disable}
                      value={states.remarks}
                      onChange={(e) => {
                        setState("remarks", e.target.value);
                      }}
                    />
                  </div>
                </Form.Item>
              </div>

              <div style={{ width: "39%" }} className="mt-7">
                <Form.Item
                  wrapperCol={{ span: 15 }}
                  label={
                    <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                      {"Receipt/Coupon No"}
                    </p>
                  }
                  className="mb-0.5"
                >
                  <div className="flex">
                    <Input
                      readOnly={true}
                      disabled={true}
                      value={states.coupon}
                      onChange={(e) => {
                        setState("coupon", e.target.value);
                      }}
                      style={{ width: "91%" }}
                    />
                  </div>
                </Form.Item>

                <>
                  {rightSelects.map(
                    ({
                      id,
                      label,
                      idExpr,
                      dataExpr,
                      optionsData,
                      stateName,
                      defaultValue,
                      refreshStateName,
                      refresh,
                    }) => {
                      const total_selects = selects?.length;
                      return (
                        <>
                          {label === "Authorized By" ? (
                            <div className="w-full flex mb-0.5">
                              <DropdownGrid_template
                                KeyExpr={idExpr}
                                label={label}
                                cusBtnStyle={true}
                                dropdownBtnStyles={{ width: "100% " }}
                                className="w-[400%]"
                                labelCol={7}
                                columns={authorizeBy_cols}
                                disabled={form_disable}
                                gridData={optionsData}
                                defaultValue={defaultValue}
                                handleRefresh={() => {
                                  setState(stateName!, { id: 0, name: "" });
                                  setState(refreshStateName!, !refresh);
                                }}
                                onRowClick={(e) => {
                                  if (
                                    e &&
                                    idExpr &&
                                    dataExpr &&
                                    e.hasOwnProperty(idExpr) &&
                                    e.hasOwnProperty(dataExpr)
                                  ) {
                                    setState(stateName!, {
                                      id: e[idExpr],
                                      name: e[dataExpr],
                                    });
                                    close();
                                  } else {
                                  }
                                }}
                              />
                            </div>
                          ) : (
                            <SelectsTemplate
                              cusWidth={"80%"}
                              disabledCustomWidth={true}
                              label={label}
                              options={optionsData}
                              idexpr={idExpr}
                              dataexp={dataExpr}
                              placeHolder={defaultValue}
                              useCallFunc
                              disabled={
                                label == "Received By" ? true : form_disable
                              }
                              selectedValue={(e) => {
                                setState(stateName!, {
                                  id: e[idExpr!],
                                  name: e[dataExpr!],
                                });
                              }}
                              handleClear={() => {
                                setState(stateName!, { id: 0, name: "" });
                              }}
                              handleRefresh={() => {
                                setState(refreshStateName!, !refresh);
                              }}
                            />
                          )}
                        </>
                      );
                    }
                  )}
                </>

                <Form.Item
                  label={
                    <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                      {"Dept/Section/Cost Center"}
                    </p>
                  }
                  className="mb-0.5 w-full"
                >
                  <li className="w-full flex items-center ">
                    <div className="flex w-full ">
                      <Select
                        disabled={true}
                        style={{ width: "26.35%" }}
                        placeholder="Dept"
                        className="mr-0.5"
                        onChange={(e: any) => {
                          setState("dept", {
                            id: JSON.parse(e)["dptIdpk"],
                            name: JSON.parse(e)["dptShtName"],
                          });
                        }}
                        value={states.dept?.name}
                      >
                        {dept_data?.map((dpt: any, index: number) => {
                          return (
                            <Select.Option
                              key={index}
                              value={JSON.stringify(dpt)}
                            >
                              {dpt["dptShtName"]}
                            </Select.Option>
                          );
                        })}
                      </Select>

                      <Select
                        disabled={true}
                        style={{ width: "26.35%" }}
                        placeholder="Section"
                        className="mr-0.5"
                        onChange={(e: any) => {
                          setState("sxn", {
                            id: JSON.parse(e)["sxnIdpk"],
                            name: JSON.parse(e)["sxnShtName"],
                          });
                        }}
                        value={states.sxn?.name}
                      >
                        {sxn_data?.map((sxn: any, index: number) => {
                          return (
                            <Select.Option
                              key={index}
                              value={JSON.stringify(sxn)}
                            >
                              {sxn["sxnShtName"]}
                            </Select.Option>
                          );
                        })}
                      </Select>
                      <Select
                        disabled={true}
                        style={{ width: "26.35%" }}
                        placeholder="Cost"
                        className=""
                        onChange={(e: any) => {
                          setState("cost", {
                            id: JSON.parse(e)["cctIdpk"],
                            name: JSON.parse(e)["cctName"],
                          });
                        }}
                        value={states.cost?.name}
                      >
                        {cost_data?.map((cst: any, index: number) => {
                          return (
                            <Select.Option key={index} value={JSON.parse(cst)}>
                              {cst["cctName"]}
                            </Select.Option>
                          );
                        })}
                      </Select>
                      <span
                        onClick={() => {
                          setState("sxn", { id: 0, name: "" });
                          setState("dept", { id: 0, name: "" });
                          setState("cost", { id: 0, name: "" });
                          setState("section", !states.section);
                          setState("department", !states.department);
                          setState("costCenter", !states.costCenter);
                        }}
                        style={{
                          height: 22.5,
                          marginBottom: 2,
                          borderWidth: 1,
                          width: 23.9,
                        }}
                        className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                      >
                        <img
                          className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                          src={reset}
                          alt="reset"
                        />
                      </span>
                    </div>
                  </li>
                </Form.Item>

                <>
                  {Rightinputs.map(({ id, label, stateName, defaultValue }) => {
                    return (
                      <>
                        <InputsTemplate
                          inputStyle={{ width: "80%" }}
                          widthChange={true}
                          span
                          label={label}
                          defaultValue={defaultValue}
                          useCallbackFunc
                          disabledStatus={true}
                          orderOnchange={(e) => {
                            setState(stateName!, e);
                          }}
                        />
                      </>
                    );
                  })}
                </>

                <Form.Item
                  label={
                    <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                      {"Active? "}
                    </p>
                  }
                >
                  <div className="w-full flex justify-between items-center">
                    <CheckboxTemlate
                      defaultValue={states.active}
                      setCheckboxOnchange={(val) => {
                        setState("active", val);
                      }}
                      useCallbackFunc={true}
                      customDisabled={true}
                      withBorders={true}
                    />
                    <span className="flex w-full px-5 ">
                      <p className="text-blue-500 text-xs flex">
                        {`${states?.curOdom - states?.prevOdom} km`}
                      </p>
                    </span>
                  </div>
                </Form.Item>
              </div>

              <div style={{ width: "22%" }} className="mt-7 mx-4 ">
                <div style={{ width: "50%" }} className="p-1">
                  <Image
                    width={150}
                    height={100}
                    src={
                      !showImages
                        ? invalidImage
                        : `data:image/png;base64, ${image_data[0]?.vimImage}`
                    }
                    alt="Vehicle Image"
                    onError={(e) => {
                      e.currentTarget.src = invalidImage;
                    }}
                    className=" text-xs border-[1px] "
                  />
                  <li className=" flex flex-row mt-1 w-full ">
                    <CheckboxTemlate
                      defaultValue={showImages}
                      setCheckboxOnchange={(val) => setShowImages(val)}
                      customDisabled={false}
                      useCallbackFunc={true}
                      withBorders={true}
                    />
                    <p className="text-xs mt-0.5 ml-1 justify-center text-center items-center">
                      Load Vehicle Image
                    </p>
                  </li>
                </div>
              </div>
            </div>

            <Form.Item
              className="w-full "
              colon={false}
              labelCol={{ span: 2 }}
              label={<p className="hidden">{"Active? "}</p>}
            >
              <div
                style={{ width: `calc(100% - 2%)`, marginLeft: "2.4%" }}
                className=" flex justify-between items-center "
              >
                <ul className="flex justify-start mt-0.5">
                  <li>
                    {" "}
                    {(userInfo.empName ===
                      statesxx?.selectedRecord?.authorizedBy?.name ||
                      userInfo.empName ===
                        statesxx?.selectedRecord?.driverName) && (
                      <DispenseButton
                        handleSubmit={() => {
                          statesxx.selectedRecord?.fmgAuthorised
                            ? (() => {
                                setState("dispenseFormState", true);
                              })()
                            : (() => {
                                setIcon("warning");
                                setOpenModal(true);
                                setshowModalDisableButton(true);
                                setModalData({
                                  message:
                                    "Request is yet to be authorised to dispense fuel",
                                  title: "Access Denied!",
                                  okText: "Ok",
                                });
                              })();
                        }}
                      />
                    )}{" "}
                  </li>
                </ul>

                <ul
                  style={{ width: "calc(100%)" }}
                  className="flex justify-end px-1 "
                >
                  {form_disable === false && update_state_change === false ? (
                    <div id="save">
                      <SaveButton
                        handleSave={() => {
                          saveAccessPriv();
                        }}
                        tooltipMsg="Save Button"
                      />
                      <Tooltip
                        target="#save"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        hideOnOutsideClick={true}
                      >
                        <p>Save Request</p>
                      </Tooltip>
                    </div>
                  ) : update_state_change === true ? (
                    <div id="updatexxxx">
                      <UpdateButton
                        handleUpdate={() => {
                          updateAcccessPriv();
                        }}
                        tooltipMsg="Update Button"
                      />
                      <Tooltip
                        target="#updatexxxx"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        hideOnOutsideClick={true}
                      >
                        <p>Update Request</p>
                      </Tooltip>
                    </div>
                  ) : (
                    <div id="save">
                      <SaveButton tooltipMsg="Save Button" />
                      <Tooltip
                        target="#save"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        hideOnOutsideClick={true}
                      >
                        <p>Save Request</p>
                      </Tooltip>
                    </div>
                  )}
                  {form_disable === true ? (
                    <div id="new">
                      <NewButton
                        useCallbackFunc={true}
                        new_button_toggler={() => {
                          newAccessPriv();
                        }}
                        tooltipMsg="New Button"
                      />
                      <Tooltip
                        target="#new"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        hideOnOutsideClick={true}
                      >
                        <p>New Request</p>
                      </Tooltip>
                    </div>
                  ) : update_state_change === true ? (
                    <div id="cancel">
                      <Cancel cancel_button_toggler={() => {}} />
                      <Tooltip
                        target="#cancel"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        hideOnOutsideClick={true}
                      >
                        <p>Cancel Update</p>
                      </Tooltip>
                    </div>
                  ) : !(update_state_change && form_disable) ? (
                    <div id="cancel">
                      <Cancel cancel_button_toggler={() => {}} />
                      <Tooltip
                        target="#cancel"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        hideOnOutsideClick={true}
                      >
                        <p>Cancel</p>
                      </Tooltip>
                    </div>
                  ) : (
                    <div id="new">
                      <NewButton
                        useCallbackFunc={true}
                        tooltipMsg="New Button"
                        new_button_toggler={() => {
                          newAccessPriv();
                        }}
                      />
                      <Tooltip
                        target="#new"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        hideOnOutsideClick={true}
                      >
                        <p>New Request</p>
                      </Tooltip>
                    </div>
                  )}
                </ul>
              </div>
            </Form.Item>
          </Form>
        </div>
      </>
    </div>
  );
};
