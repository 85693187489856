/* eslint-disable react/jsx-pascal-case */
import { Card, Divider, Form, Input, Modal, Select } from "antd";
import React, { useState } from "react";
import { AuthButton, Cancel, SendEmailButton } from "../../../buttons";
import { inputsTypes } from "../../../../human-resource/setups/data/types/selectsTypes";
import { DateTemplate } from "../../../../../templates/date";
import { SelectsTemplate } from "../../../../../templates/select";
import chart from "../../../../../assets/3dline.png";
import switchbtn from "../../../../../assets/meeting-details.png";
import { TextareaTemplate } from "../../../../../templates/textarea";
import { Tooltip } from "devextreme-react";
import Datagrid_template from "../../../../../templates/Datagrid";
import { FaBinoculars } from "react-icons/fa";
import dayjs, { Dayjs } from "dayjs";
import { Chart_template } from "../../../../../templates/Chart";
import { ModalTemplate } from "../../../../../templates/modal";
import { formPropsConst } from "../../../constants";

interface props {
  openx: boolean;
  widthx?: any;
  sendMailx?: () => void;
  cancelHandlerx?: () => void;
  okTextx: string;
  cancelTextx?: string;
  cancelButtonx?: boolean;
  titlex: string;
  Gridtitlex: string;
  Gridtitlex2: string;
  placeHolderx?: any;
  selectedValuex?: (object: any) => void;
  onRowClickx?: (object: any) => void;
  rowDblClickx?: (object: any) => void;
  gridheightx: number;
  optionsx: any;
  optionsx2: any;
  idexprx?: string;
  dataexpx?: string;
  useCallFuncx?: boolean;
  disabledx?: boolean | undefined;
  setCallbackValuex?: (value: string) => void;
  columnsx: any;
  columnsx2: any;
  selectedRecordx?: any;

  unitsDatax?: any[];
  updateStatesx?: (key: string, value: any) => void;
  statesx?: any;
  criteriax?: any;
  criteriax2?: any;
  chartLsx?: any;
  chartDatax?: any;
  chartColumnx?: any;
  chartTypex?: string;
  XAxis_val?: any;
  chartDatax2?: any;
  chartColumnx2?: any;
  XAxis_val2?: any;
  authDatax?: () => void;
  cancelAuthDatax?: () => void;
  smsAlertx?: () => void;
}

export const AuthModal = ({
  openx,
  widthx,
  sendMailx,
  cancelHandlerx,
  okTextx,
  cancelTextx,
  cancelButtonx,
  titlex,
  Gridtitlex,
  Gridtitlex2,
  placeHolderx,
  selectedValuex,
  onRowClickx,
  rowDblClickx,
  gridheightx,
  optionsx,
  idexprx,
  dataexpx,
  useCallFuncx,
  disabledx,
  setCallbackValuex,
  columnsx,
  columnsx2,
  selectedRecordx,
  unitsDatax,
  updateStatesx,
  statesx,
  criteriax,
  criteriax2,
  chartLsx,
  chartDatax,
  chartColumnx,
  chartTypex,
  XAxis_val,
  chartDatax2,
  chartColumnx2,
  XAxis_val2,
  optionsx2,
  authDatax,
  cancelAuthDatax,
  smsAlertx,
}: props) => {
  // information
  const requests = [
    {
      id: 0,
      label: "Requested By",
      val: `${selectedRecordx !== undefined ? selectedRecordx.driverName : ""}`,
      textcolor: "blue-500",
    },
    {
      id: 1,
      label: "Requested On",
      val: `${
        selectedRecordx !== undefined
          ? dayjs(selectedRecordx.fmgCreationDate).format(
              "ddd DD MMM YYYY HH:MM"
            )
          : ""
      }`,
      textcolor: "blue-500",
    },
  ];
  const requests2 = [
    { id: 0, label: "", val: ``, textcolor: "" },
    {
      id: 1,
      label: "Reg. No",
      val: `${selectedRecordx !== undefined ? selectedRecordx.vdtRegNo : ""}`,
      textcolor: "blue-500",
    },
  ];

  const date: inputsTypes[] = [
    {
      id: 0,
      label: "Date Authorised",
      defaultValue: `${
        selectedRecordx !== undefined
          ? dayjs()
          : ""
      }`,
      stateName: "",
      style: "",
    },
  ];
  const textarea = [
    {
      id: 0,
      label: "Requester Remarks",
      defaultValue: selectedRecordx?.fmgRmks,
      stateName: "",
      style: "",
    },
    {
      id: 1,
      label: "Authorisor Remarks",
      defaultValue: statesx?.remarksA,
      stateName: "remarksA",
      style: "",
    },
  ];

  const authModalData = {
    message: `Are you sure you want to authorise this request?`,
    title: "Authorize",
    okText: "Yes",
    cancelText: "No",
  };
  const cancelauthModalData = {
    message: `Are you sure you want to reject this request?`,
    title: "Reject Request",
    okText: "Yes",
    cancelText: "No",
  };
  const sendSms = {
    message: "Are you sure you want to send an alert to the requester?",
    title: "Send Message",
    okText: "Send",
    cancelText: "No, Cancel",
  };

  const [useCriteria, setuseCriteria] = useState<any>(criteriax);
  const [isGrid, setisGrid] = useState<boolean>(false);
  const [isChart, setisChart] = useState<boolean>(true);
  const [isToggle, setisToggle] = useState<boolean>(false);
  const [isChartF, setisChartF] = useState<boolean>(true);
  const [isChart_tools, setisChart_tools] = useState<boolean>(false);

  return (
    <div className="w-full h-full">
      <>
        <ModalTemplate
          open={statesx?.authModalOpen}
          icon={statesx?.setIcon}
          cancelText={statesx?.setModalData.cancelText}
          message={statesx?.setModalData.message}
          okText={statesx?.setModalData.okText}
          title={statesx?.setModalData.title}
          cancelHandler={() => {
            updateStatesx && updateStatesx("authModalOpen", false);
          }}
          disableCancel={
            statesx?.setIcon === "warning" || statesx?.setIcon === "success"
              ? true
              : false
          }
          okHandler={() => {
            authModalData.title === statesx?.setModalData.title
              ? authDatax && authDatax()
              : cancelauthModalData.title === statesx?.setModalData.title
              ? cancelAuthDatax && cancelAuthDatax()
              : sendSms.title === statesx?.setModalData.title
              ? smsAlertx && smsAlertx()
              : updateStatesx && updateStatesx("authModalOpen", false);
          }}
        />
      </>
      <Modal
        width={widthx ? widthx : isGrid ? 1250 : 600}
        title={titlex}
        open={openx}
        onCancel={cancelHandlerx}
        centered
        footer={null}
      >
        <div
          className={
            isGrid === true
              ? `w-full h-full flex justify-between`
              : "w-full h-full"
          }
        >
          <div
            className={
              isGrid === true ? ` w-2/5 border-r-[1px] ` : "w-full h-full"
            }
          >
            <Form
              {...formPropsConst}
              className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded  px-2 "
            >
              {/* Requested */}

              <>
                <div className="w-full flex justify-between items-center">
                  <div className="w-2/3 ">
                    {requests.map(({ id, label, val, textcolor }) => {
                      return (
                        <Form.Item
                          labelCol={{ span: 9 }}
                          label={<p>{label}</p>}
                        >
                          <p className={`text-${textcolor}`}>{val}</p>
                        </Form.Item>
                      );
                    })}
                  </div>

                  <div className="w-1/3 ">
                    {requests2.map(({ id, label, val, textcolor }) => {
                      return (
                        <Form.Item
                          colon={id === 1 && true}
                          labelCol={{ span: 9 }}
                          label={<p>{label}</p>}
                        >
                          <p className={`text-${textcolor}`}>{val}</p>
                        </Form.Item>
                      );
                    })}
                  </div>
                </div>
              </>

              <Divider className="my-1" />

              {/* Date */}

              {date.map(({ id, label, stateName, defaultValue, style }) => {
                return (
                  <>
                    <DateTemplate
                      disabled={true}
                      label={label}
                      selectedDate={dayjs(defaultValue)}
                      changeDate={() => {}}
                      span
                      width={200}
                      format={"ddd DD MMM YYYY HH:MM"}
                    />
                  </>
                );
              })}

              {/* Quantity */}

              <Form.Item
                label={
                  <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                    {"Quantity (R/A)"}
                  </p>
                }
                className="mb-0.5"
              >
                <li className="w-full flex items-center flex-row  ">
                  <div className="w-full flex ">
                    <Input
                      disabled={true}
                      style={{ width: "25%" }}
                      value={selectedRecordx?.fmgQuantityRequested}
                      onChange={(e) => {}}
                      className=" h-6"
                    />
                    <Input
                      type="number"
                      disabled={false}
                      style={{ width: "25%" }}
                      value={statesx?.quanA}
                      onChange={(e) => {
                        updateStatesx && updateStatesx("quanA", e.target.value);
                      }}
                      className="ml-1 h-6 "
                    />
                    <SelectsTemplate
                      disabled={false}
                      style={{ width: "40.5%" }}
                      selectStyle={{ width: "100%", marginLeft: 5 }}
                      idexpr="funIDpk"
                      dataexp="funShtName"
                      options={unitsDatax ?? []}
                      useCallFunc
                      placeHolder={statesx.uom?.name}
                      selectedValue={(e) => {
                        updateStatesx &&
                          updateStatesx("uom", {
                            id: e["funIDpk"],
                            name: e["funShtName"],
                          });
                      }}
                    />
                  </div>
                </li>
              </Form.Item>

              {/* Remarks */}

              {textarea.map(({ id, label, stateName, defaultValue, style }) => {
                return (
                  <div>
                    <TextareaTemplate
                      height={id === 0 ? 70 : 120}
                      label={label}
                      defaultValue={defaultValue}
                      useCallbackFunc
                      readonly={id === 0 ? true : false}
                      disabled={id === 0 ? true : false}
                      setCallbackValue={(e) => {
                        updateStatesx && updateStatesx("remarksA", e);
                      }}
                      labelCol={6}
                      bg={id === 0 && "yellow-200"}
                    />
                  </div>
                );
              })}

              <div className="flex w-full h-full justify-evenly items-center">
                <ul className="w-1/4 flex items-center">
                  {!isGrid && (
                    <>
                      <span
                        id="mswitch"
                        onClick={() => {
                          setisGrid(!isGrid);
                        }}
                        style={{
                          height: 27.5,
                          marginBottom: 2,
                          borderWidth: 1,
                          width: 28.9,
                        }}
                        className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                      >
                        <img
                          className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                          src={switchbtn}
                          alt="switch-toggle"
                        />
                      </span>

                      <Tooltip
                        target="#mswitch"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        hideOnOutsideClick={true}
                      >
                        <p className="">Switch interface</p>
                      </Tooltip>
                    </>
                  )}
                </ul>

                <ul className="w-1/3 flex items-center justify-center mr-[15px]">
                  <SendEmailButton
                    useCallbackFunc
                    marginLeft="0"
                    disableButton={false}
                    handleMail={async () => {
                      updateStatesx && updateStatesx("setIcon", "question");
                      updateStatesx && updateStatesx("authModalOpen", true);
                      updateStatesx && updateStatesx("setModalData", sendSms);
                    }}
                  />
                </ul>

                <ul className="w-2/3 space-x-2 flex justify-end items-center">
                  <AuthButton
                    handleAuth={async () => {
                      updateStatesx && updateStatesx("setIcon", "question");
                      updateStatesx && updateStatesx("authModalOpen", true);
                      updateStatesx &&
                        updateStatesx("setModalData", authModalData);
                    }}
                  />
                  <Cancel
                    useCallbackFunc
                    cancel_button_toggler={async () => {
                      updateStatesx && updateStatesx("setIcon", "question");
                      updateStatesx && updateStatesx("authModalOpen", true);
                      updateStatesx &&
                        updateStatesx("setModalData", cancelauthModalData);
                    }}
                  />
                </ul>
              </div>
            </Form>
          </div>

          {/* right grid */}
          {isGrid && (
            <div className="w-3/5">
              <div className="w-full ">
                <Form {...formPropsConst}>
                  <div className="w-full flex space-x-2 px-3.5 ">
                    <DateTemplate
                      disabled={false}
                      selectedDate={dayjs(statesx?.startDate)}
                      changeDate={(e: Dayjs) => {
                        updateStatesx &&
                          updateStatesx(
                            "startDate",
                            dayjs(e).format("YYYY-MM-DD")
                          );
                      }}
                      span
                      width={130}
                    />
                    <DateTemplate
                      disabled={false}
                      selectedDate={dayjs(statesx?.endDate)}
                      changeDate={(e: Dayjs) => {
                        updateStatesx &&
                          updateStatesx(
                            "endDate",
                            dayjs(e).format("YYYY-MM-DD")
                          );
                      }}
                      span
                      width={130}
                    />

                    <span
                      onClick={() => {
                        // setState('uom', '')
                      }}
                      style={{
                        height: 22.5,
                        marginBottom: 2,
                        borderWidth: 1,
                        width: 23.9,
                      }}
                      className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                    >
                      <FaBinoculars size={15.5} color="#007AFF" />
                    </span>

                    <li>
                      <span
                        id="meswitch"
                        onClick={() => {
                          setisGrid(!isGrid);
                        }}
                        style={{
                          height: 22.5,
                          marginBottom: 2,
                          borderWidth: 1,
                          width: 23.9,
                        }}
                        className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                      >
                        <img
                          className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                          src={switchbtn}
                          alt="reset"
                        />
                      </span>
                      <Tooltip
                        target="#meswitch"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        hideOnOutsideClick={true}
                      >
                        <p className="">Switch interface</p>
                      </Tooltip>
                    </li>
                    {isChart_tools && (
                      <li className="flex ">
                        <Select
                          className="ml-0.5"
                          style={{ width: "150px" }}
                          placeholder={statesx.criteriaState?.name}
                          onChange={(e: any) => {
                            updateStatesx &&
                              updateStatesx("criteriaState", {
                                id: JSON.parse(e)["id"],
                                name: JSON.parse(e)["name"],
                              });
                          }}
                          value={statesx.criteriaState?.name}
                        >
                          {useCriteria?.map((crt: any, index: number) => {
                            return (
                              <Select.Option
                                key={index}
                                value={JSON.stringify(crt)}
                              >
                                {crt["name"]}
                              </Select.Option>
                            );
                          })}
                        </Select>

                        <Select
                          className="ml-0.5"
                          style={{ width: "100px" }}
                          placeholder={statesx.chartLState?.name}
                          onChange={(e: any) => {
                            updateStatesx &&
                              updateStatesx("chartLState", {
                                id: JSON.parse(e)["id"],
                                name: JSON.parse(e)["name"],
                              });
                          }}
                          value={statesx.chartLState?.name}
                        >
                          {chartLsx?.map((cl: any, index: number) => {
                            return (
                              <Select.Option
                                key={index}
                                value={JSON.stringify(cl)}
                              >
                                {cl["name"]}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </li>
                    )}

                    <li>
                      <span
                        id="mcswitch"
                        onClick={() => {
                          setisChart_tools(!isChart_tools);
                          if (isToggle) {
                            setisChart(!isChart);
                            setisToggle(!isToggle);
                          } else {
                            setisChartF(!isChartF);
                            setisToggle(!isToggle);
                          }

                          if (!isChart) {
                            setuseCriteria(criteriax);
                          } else {
                            setuseCriteria(criteriax2);
                          }
                        }}
                        style={{
                          height: 22.5,
                          marginBottom: 2,
                          borderWidth: 1,
                          width: 23.9,
                        }}
                        className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                      >
                        <img
                          className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                          src={chart}
                          alt="reset"
                        />
                      </span>
                      <Tooltip
                        target="#mcswitch"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        hideOnOutsideClick={true}
                      >
                        <p className="">
                          Switch between charts and data tables
                        </p>
                      </Tooltip>
                    </li>
                  </div>
                </Form>
              </div>

              <>
                <Card
                  size="small"
                  className="w-full  mx-3"
                  title={
                    isChart
                      ? Gridtitlex
                      : isChart === false
                      ? Gridtitlex2
                      : isChartF
                      ? Gridtitlex
                      : isChartF === false && Gridtitlex2
                  }
                >
                  {isToggle && (
                    <>
                      {isChart ? (
                        <>
                          <Chart_template
                            chartheight={"270px"}
                            columns={chartColumnx}
                            data={chartDatax}
                            XAxis_cols={XAxis_val}
                            chartType={chartTypex as any}
                            YAxis_label={"Fuel Dump Dips"}
                            paletteColor={"Soft Blue"}
                          />
                        </>
                      ) : (
                        <>
                          <Chart_template
                            chartheight={"270px"}
                            columns={chartColumnx2}
                            data={chartDatax2}
                            XAxis_cols={XAxis_val2}
                            chartType={chartTypex as any}
                            YAxis_label={"Vehicle Fuel"}
                            paletteColor={"Soft Blue"}
                          />
                        </>
                      )}
                    </>
                  )}

                  {!isToggle && (
                    <>
                      {isChartF ? (
                        <>
                          <Datagrid_template
                            gridheight={gridheightx}
                            columns={columnsx2}
                            data={optionsx2}
                            onRowClick={onRowClickx}
                            rowDoubleClicked={rowDblClickx}
                            disablePaging
                          />
                        </>
                      ) : (
                        <>
                          <Datagrid_template
                            gridheight={gridheightx}
                            columns={columnsx}
                            data={optionsx}
                            onRowClick={onRowClickx}
                            rowDoubleClicked={rowDblClickx}
                            disablePaging
                          />
                        </>
                      )}
                    </>
                  )}
                </Card>
              </>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};
