import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncGet, GetDataFunc } from "../../../functions/get";


interface RequisitionApprval{
requestClass:any|null|undefined;
vehicleNo1:any|null|undefined;
vehicleNo2:any|null|undefined;
driver1:any|null|undefined;
driver2:any|null|undefined;
requestType:any|null|undefined;
isLoading: boolean;
isPending: boolean;
isError: boolean;
}

const initialState: RequisitionApprval = {
requestClass:[],
vehicleNo1:[],
vehicleNo2:[],
driver1:[],
driver2:[],
requestType:[],
isLoading: false,
isPending: false,
isError: false
};

export const FetchRequestClass = createAsyncThunk<RequisitionApprval,string,{ rejectValue: string }>(
    "RequestClass/fetch", 
        async (search:string) => {
            try {
                var requestClass_response = await AsyncGet(`AsmTmsVehicleRequest/GetAllAsmTsmVehicleRequestClasses?pagesize=20&pagenumber=1&Search=${search}`);
                if (requestClass_response?.status === true) {
                return requestClass_response?.data;
                }
            } catch {
                return requestClass_response?.data;
            }
    });
export const FetchVehicle_No1 = createAsyncThunk<RequisitionApprval,string,{ rejectValue: string }>(
    "Vehicle_No1/fetch", 
        async (search:string) => {
            try {
                var vehicleNo1_response = await AsyncGet(`AsmTmsVehicleRequest/GetAllAsmTmsVehicleRequestVehiclesRegNo?pagesize=20&pagenumber=1&Search=${search}`);
                if (vehicleNo1_response?.status === true) {
                return vehicleNo1_response?.data;
                }
            } catch {
                return vehicleNo1_response?.data;
            }
    });
export const FetchVehicle_No2 = createAsyncThunk<RequisitionApprval,string,{ rejectValue: string }>(
    "Vehicle_No2/fetch", 
        async (search:string) => {
            try {
                var vehicleNo2_response = await AsyncGet(`AsmTmsVehicleRequest/GetAllAsmTmsVehicleRequestVehiclesRegNo?pagesize=20&pagenumber=1&Search=${search}`);
                if (vehicleNo2_response?.status === true) {
                return vehicleNo2_response?.data;
                }
            } catch {
                return vehicleNo2_response?.data;
            }
    });
export const FetchDriver_No1 = createAsyncThunk<RequisitionApprval,{search:string,rqAreaID:number,AreaId:number},{ rejectValue: string }>(
    "Drvier_No1/fetch", 
        async ({search,rqAreaID,AreaId}) => {
            try {
                var driverNo1_response = await AsyncGet(`AsmTmsVehicleRequest/GetAllAsmTmsVehicleRequestDrivers?vaaRequestAreaIDfk=${rqAreaID}&vrqAreadIDfk=${AreaId}&pagesize=20&pagenumber=1&Search=${search}`);
                if (driverNo1_response?.status === true) {
                return driverNo1_response?.data;
                }
            } catch {
                return driverNo1_response?.data;
            }
    });
export const FetchDriver_No2 = createAsyncThunk<RequisitionApprval,{search:string,rqAreaID:number,AreaId:number},{ rejectValue: string }>(
    "Drvier_No2/fetch", 
        async ({search,rqAreaID,AreaId}) => {
            try {
                var driverNo2_response = await AsyncGet(`AsmTmsVehicleRequest/GetAllAsmTmsVehicleRequestDrivers?vaaRequestAreaIDfk=${rqAreaID}&vrqAreadIDfk=${AreaId}&pagesize=20&pagenumber=1&Search=${search}`);
                if (driverNo2_response?.status === true) {
                return driverNo2_response?.data;
                }
            } catch {
                return driverNo2_response?.data;
            }
    });
export const FetchRequestType = createAsyncThunk<RequisitionApprval,string,{ rejectValue: string }>(
    "RequestType/fetch", 
        async (search:string) => {
            try {
                var requestType_response = await AsyncGet(`AsmTmsVehicleRequest/GetAllAsmTsmVehicleRequestTypes?pagesize=20&pagenumber=1&Search=${search}`);
                if (requestType_response?.status === true) {
                return requestType_response?.data;
                }
            } catch {
                return requestType_response?.data;
            }
    });


const RequisitionApprvalSlice = createSlice({
    name: 'requisitionApproval',
    initialState:initialState,
    reducers:{},
    extraReducers(builder) {
        builder
    
      //---------------FetchRequestClass-------------------
      .addCase(FetchRequestClass.pending,(state)=>{
        state.isLoading = true;
        state.isError = false;
    })
    .addCase(FetchRequestClass.fulfilled,(state,action)=>{
        state.isLoading = false;
        state.isError = false;
        state.requestClass = action.payload;
    })
    .addCase(FetchRequestClass.rejected,(state)=>{
        state.isError = true;
        state.isLoading=false;
    })
    //-------------FetchVehicle_No1-----------------------
    .addCase(FetchVehicle_No1.pending,(state)=>{
        state.isLoading = true;
        state.isError = false;
    })
    .addCase(FetchVehicle_No1.fulfilled,(state,action)=>{
        state.isLoading = false;
        state.isError = false;
        state.vehicleNo1 = action.payload;
    })
    .addCase(FetchVehicle_No1.rejected,(state)=>{
        state.isError = true;
        state.isLoading=false;
    })
    //-------------FetchVehicle_No2-----------------------
    .addCase(FetchVehicle_No2.pending,(state)=>{
        state.isLoading = true;
        state.isError = false;
    })
    .addCase(FetchVehicle_No2.fulfilled,(state,action)=>{
        state.isLoading = false;
        state.isError = false;
        state.vehicleNo2 = action.payload;
    })
    .addCase(FetchVehicle_No2.rejected,(state)=>{
        state.isError = true;
        state.isLoading=false;
    })
    //-------------FetchDriver_No1--------------------------
    .addCase(FetchDriver_No1.pending,(state)=>{
        state.isLoading = true;
        state.isError = false;
    })
    .addCase(FetchDriver_No1.fulfilled,(state,action)=>{
        state.isLoading = false;
        state.isError = false;
        state.driver1 = action.payload;
    })
    .addCase(FetchDriver_No1.rejected,(state)=>{
        state.isError = true;
        state.isLoading=false;
    })
    //-------------FetchDriver_No2---------------------------
    .addCase(FetchDriver_No2.pending,(state)=>{
        state.isLoading = true;
        state.isError = false;
    })
    .addCase(FetchDriver_No2.fulfilled,(state,action)=>{
        state.isLoading = false;
        state.isError = false;
        state.driver2 = action.payload;
    })
    .addCase(FetchDriver_No2.rejected,(state)=>{
        state.isError = true;
        state.isLoading=false;
    })
    //-------------FetchRequestType---------------------------
    .addCase(FetchRequestType.pending,(state)=>{
        state.isLoading = true;
        state.isError = false;
    })
    .addCase(FetchRequestType.fulfilled,(state,action)=>{
        state.isLoading = false;
        state.isError = false;
        state.requestType = action.payload;
    })
    .addCase(FetchRequestType.rejected,(state)=>{
        state.isError = true;
        state.isLoading=false;
    })
    }})
    export default RequisitionApprvalSlice.reducer;