import axios, { AxiosRequestConfig } from "axios";
import { userDataCrypt } from "../functions/userDataEncrypt";
import {
  api_base_url,
  api_url,
} from "../components/accessories/component_infos";
import React from "react";

export const requestConfig = (params?: any, cusHeader?: any) => {
  let item = sessionStorage.getItem("client");
  let encItem = userDataCrypt("decrypt", item);
  let parsedItem = JSON.parse(encItem);
  const requestConfig: AxiosRequestConfig = {
    headers: {
      "Content-Type": ["application/json", "text/plain", "charset=utf-8"],
      "ngrok-skip-browser-warning": "true",
      Authorization: `Bearer ${parsedItem?.token}`,

      ...cusHeader,
    },

    params,
  };

  return requestConfig;
};
const axiosInstance = axios.create({
  baseURL: `${process.env.INNOX_API_URL}/`,
  headers: {
    "Content-Type": ["application/json", "text/plain", "charset=utf-8"],
    "ngrok-skip-browser-warning": "true",
  },
});
type AxiosConfigType = {
  method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
  url: string;
  data?: any;
  params?: any;
};
export const useAxiosService = (app?: "innox" | "powerapp") => {
  let item = sessionStorage.getItem("client");
  let encItem = userDataCrypt("decrypt", item);
  let parsedItem = JSON.parse(encItem);

  axiosInstance.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${parsedItem?.token}`;

  React.useEffect(() => {
    axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${parsedItem?.token}`;
    if (app === "innox") {
      axiosInstance.defaults.baseURL = `${
        process.env.INNOX_API_URL ?? api_url
      }/`;
    } else if (app === "powerapp") {
      axiosInstance.defaults.baseURL = `${
        process.env.POWERAPP_API_URL ?? api_base_url
      }/`;
    } else {
      axiosInstance.defaults.baseURL = `${
        process.env.INNOX_API_URL ?? api_url
      }/`;
    }
  }, [app, parsedItem?.token]);
  return (config: AxiosConfigType) =>
    axiosInstance({
      method: config.method,
      url: config.url,
      data: config.data,
      params: config.params,
    }).then((res) => res?.data);
};
