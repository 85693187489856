export const assets_productPriceColumns = [
  {
    id: 0,
    caption: "SERVICE PROVIDER",
    dataField: "spdName",
    dataType: "number",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 1,
    caption: "SERVICE STATION",
    dataField: "sstName",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 3,
    caption: "DATE",
    dataField: "pprDate",
    dataType: "datetime",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 4,
    caption: "PRODUCT",
    dataField: "prdName",
    dataType: "string",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 5,
    caption: "UNIT PRICE",
    dataField: "pprUnitPrice",
    dataType: "",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 6,
    caption: "CURRENCY",
    dataField: "curSymbol",
    dataType: "string",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 7,
    caption: "UNIT OF MEASURE",
    dataField: "funName",
    dataType: "string",
    width: 150,
    fixed: true,
    visible: true,
  },
  {
    id: 8,
    caption: "ORDER",
    dataField: "pprOrder",
    dataType: "",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 9,
    caption: "ACTIVE",
    dataField: "pprActive",
    dataType: "",
    width: 90,
    fixed: true,
    visible: true,
  },
  {
    id: 10,
    caption: "REMARKS",
    dataField: "pprRmks",
    dataType: "string",
    width: 5000,
    fixed: false,
    visible: true,
  },
];
