import { Checkbox, DatePicker, Form, Input, Select } from 'antd';
import { CheckBox } from 'devextreme-react/check-box';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import reset from '../../../../../assets/refresh.png'
import { Cancel, NewButton, SaveButton, UpdateButton } from '../../../../accessories/buttons';
import { Theme_Result_Mini_TextArea, Theme_Result_TextArea } from '../../../data/Performance_inputs';
import { Theme_Result_Select } from '../../../data/Performance_selects';
import dayjs from 'dayjs';
import axios from 'axios';
import { api_url, saveModalData, updateModalData } from '../../../../accessories/component_infos';
import { ModalTemplate } from '../../../../../templates/modal';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../app/store';
import { task_footer_table_selected, task_footer_update, task_form_disable } from '../../../../../features/Task&Todo/Task/TaskDetails';
import { TextareaTemplate } from '../../../../../templates/textarea';

interface props {

    toggleRefresh: any,

}


export const Theme_Result_Details = forwardRef(({ toggleRefresh }: props, ref: any) => {
    const { Option } = Select;
    const { TextArea } = Input;

    const [mission, setMission] = useState([]);
    const [themeData, setThemesData] = useState([]);
    const [refreshValue, setRefreshValue] = useState(false);

    const borderTheme = useSelector((state:RootState)=>state.general.cssBorderClass)

    const options = [];
    for (let i = 1; i < 36; i++) {
        options.push({
            label: i.toString(36) + i,
            value: i.toString(36) + i,
        });
    }

    //Run functions from table
    useImperativeHandle(ref, () => ({

        doubleClick: () => {
            setThemeResultsData({ ...themeResultsData, ['disabled']: false })
            //    setDisableForms(false);
            setUpdatingStatus(true);
            setShowCancel(true);
        },
        singleClick: (selectedRow: any) => {
            console.log('eee');
            console.log(selectedRow)
            setThemeResultsData({
                ...themeResultsData,
                ['disabled']: true,
                ['mission']: { name: selectedRow.mvsMission, id: selectedRow.mvsIdpk },
                ['theme']: { name: selectedRow.thmName, id: selectedRow.thmIdpk },
                ['results']: selectedRow.trsResult,
                ['shortName']: selectedRow.thmShtName,
                ['commentary']: selectedRow.trsCommentary,
                ['remarks']: selectedRow.trsRmks,
                ['order']: selectedRow.trsOrder,
                ['activity']: selectedRow.trsActive,
                ['id']: selectedRow.trsIdpk,
            })
            // setDisableForms(true);
            //  setUpdatingStatus(false);
            //  setShowCancel(false);
        }


    }));

    const validateForms = async () => {
        setIcon('warning');
        if (themeResultsData.mission.id === '') {
            setShowModalDisableButton(true);
            setModalData({ message: 'Please select a mission', title: 'Select Mission', okText: 'Ok' },)
            setOpenModal(true);
        } else if (themeResultsData.theme.id === '') {
            setShowModalDisableButton(true);
            setModalData({ message: 'Please select a theme', title: 'Select Theme', okText: 'Ok' },)
            setOpenModal(true);
        }
        else if (themeResultsData.order === '') {
            setShowModalDisableButton(true);
            setModalData({ message: 'Please enter an order', title: 'Enter Order', okText: 'Ok' },)
            setOpenModal(true);
        }
        else {
            setIcon('question');
            setShowModalDisableButton(false);
            setOpenModal(true);
        }
    }

    //Function Calls
    const updateData = async () => {
        console.log('here');

        const del = await axios.put(`${api_url}/api/PfmThemeResults/UpdatePfmThemeResult`, {
            "trsIdpk": themeResultsData.id,
            "trsThemeIdfk": themeResultsData.theme.id,
            "trsResult": themeResultsData.results,
            "trsShtResult": themeResultsData.shortName,
            "trsCommentary": themeResultsData.commentary,
            "trsOrder": themeResultsData.order,
            "trsActive": themeResultsData.activity,
            "trsRmks": themeResultsData.remarks,
            "trsEditedBy": 1,
            "trsEditedDate": `${dayjs().format('YYYY-MM-DDTHH:mm:ss[Z]')}`
        }
        )
        //close modal
        setOpenModal(false);
        //Refresh table
        setShowModalDisableButton(true);
        setIcon('success');
        setModalData({ message: 'Record successfully updated', title: 'Record updated', okText: 'Ok' },)
        setOpenModal(true);
        toggleRefresh();


    }

    async function fetchMissionVision() {
        try {

            // get data
            const res = await axios.get(`${api_url}/api/PfmMissionVision/GetAllPfmMissionVision`, {
                headers: {
                    'Content-Type': ['application/json', 'text/plain', 'charset=utf-8'],
                    // Accept : ['text/plain','application/json','charset=utf-8'],
                }
            })


            setMission(res.data.data.reverse()); // set data
        } catch (error: any) {
            console.log(error);

        } finally {

        }
    }

    async function fetchThemes() {
        try {

            // get data
            const res = await axios.get(`${api_url}/api/PfmObjectives/GetLuePfmThemes`, {
                headers: {
                    'Content-Type': ['application/json', 'text/plain', 'charset=utf-8'],
                    // Accept : ['text/plain','application/json','charset=utf-8'],
                }
            })
            console.log('done with themes')
            if (res) {
                console.log(res)
            }

            setThemesData(res.data.reverse()); // set data
        } catch (error: any) {
            console.log(error);

        } finally {

        }
    }


    const dateFormat = " DD MMM YYYY HH: mm:ss";
    const [themeResultsData, setThemeResultsData] = useState<any>({
        mission: { name: '', id: '' },
        theme: { name: '', id: '' },
        results: '',
        shortName: '',
        commentary: '',
        remarks: '',
        order: '',
        activity: false,
        disabled: true,
        id: '',
    });

    useEffect(() => {

        fetchMissionVision();
        fetchThemes();
        // fetchNames();

    }, [refreshValue]);

    const [updatingStatus, setUpdatingStatus] = useState(false);

    const [showModalDisableButton, setShowModalDisableButton] = useState(false);

    const [disableForms, setDisableForms] = useState(true);
    const [showCancel, setShowCancel] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [modalData, setModalData] = useState<any>(saveModalData);
    const [icon, setIcon] = useState<'warning' | 'success' | 'question'>();



    const clearFields = async () => {
        setThemeResultsData(
            {
                mission: { name: '', id: '' },
                theme: { name: '', id: '' },
                results: '',
                shortName: '',
                commentary: '',
                remarks: '',
                order: '',
                activity: false,
                disabled: false,
                id: '',
            }
        )

    }

    async function postData() {
        const del = await axios.post(`${api_url}/api/PfmThemeResults/CreatePfmThemeResult`, {

            "trsThemeIdfk": themeResultsData.theme.id,
            "trsResult": themeResultsData.results,
            "trsShtResult": themeResultsData.shortName,
            "trsCommentary": themeResultsData.commentary,
            "trsOrder": themeResultsData.order,
            "trsActive": themeResultsData.activity,
            "trsRmks": themeResultsData.remarks,
            "trsEditedBy": 1,

        }
        )
    }

    const disablex = useSelector((state: RootState) => state.addTask.taskFormStatus);
    const updateMode = useSelector((state: RootState) => state.addTask.taskFooterUpdate);
    const dispatch = useDispatch();


    useEffect(() => {
        if (!disablex) {

            dispatch(task_footer_table_selected({}))

            //  this destroys the update mode state
            dispatch(task_footer_update(false));
            setShowCancel(true); setDisableForms(false);
            clearFields();


        } else {
            setShowCancel(false);
            setThemeResultsData({
                ...themeResultsData,
                ['disabled']: true,

            })
            setUpdatingStatus(false)
            dispatch(task_form_disable(true));
            // dispatch(update_route_state(false))
            dispatch(task_footer_update(false));

        }
        if (updateMode) {
            setDisableForms(false);
            setUpdatingStatus(true);
            setShowCancel(true);
        }

    }, [disablex, updateMode])


    const { RangePicker } = DatePicker;

    return (

        <div style={{ width: '900px', minWidth: '900px', height: '100%', borderColor:borderTheme }} className="  border-[1px]   dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base  ">
            {/* <h4 className=" w-full  mb-2 bg-slate-100  ">Task Details</h4> */}
            <ModalTemplate icon={icon} cancelText={modalData.cancelText}
                cancelHandler={() => { setOpenModal(false) }} disableCancel={showModalDisableButton} open={openModal}
                okHandler={() => { showModalDisableButton === true ? setOpenModal(false) : updatingStatus === true ? updateData() : postData() }}
                message={modalData.message} okText={modalData.okText} title={modalData.title} />

            <div style={{ maxHeight: '280px', minHeight: '280px', height: '280px' }} className=' items-start justify-start '   >

                <Form
                    className='w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 px-2 '
                    name="basic"
                    // labelCol={{ span: 6}}
                    // wrapperCol={{ span: 24 }}
                    initialValues={{ reResult: true }}
                    autoComplete="off"
                    size='small'
                    layout='horizontal'

                >
                    <Form.Item
                        label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{'Mission'}</p>}
                        className={' mb-1'}
                        labelCol={{ span: 2 }}
                        wrapperCol={{ span: 10 }}
                    >
                        <div className="flex flex-row items-center">
                            <Select
                                disabled={themeResultsData.disabled}
                                onChange={(option) => {
                                    console.log(option)
                                    setThemeResultsData({ ...themeResultsData, ['mission']: { id: JSON.parse(option)['mvsIdpk'], name: JSON.parse(option)['mvsName'] } })
                                    // setSelectedObjective({id: JSON.parse(option)['id'], name: JSON.parse(option)['name']})
                                }}
                                className='w-full mr-0'
                                placeholder=""

                                value={themeResultsData.mission.name}
                            >
                                {mission.map((option: any, index: number) => {
                                    return (
                                        <option key={index} value={JSON.stringify(option)}> {option['mvsName']}</option>
                                    )
                                })}
                            </Select>

                            <span onClick={() => {
                                setThemeResultsData({ ...themeResultsData, ['mission']: { id: '', name: '' } })
                            }}

                                style={{ height: 22.5, marginBottom: 2, borderWidth: 1, width: 23.9 }}
                                className='ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded'>
                                <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src={reset} alt="reset" />
                                {/* <img className='' src= {''} alt="" /> */}
                            </span>


                        </div>
                    </Form.Item>
                    <Form.Item
                        label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{'Theme'}</p>}
                        className={' mb-1'}
                        labelCol={{ span: 2 }}
                        wrapperCol={{ span: 10 }}
                    >
                        <div className="flex flex-row items-center">
                            <Select
                                disabled={themeResultsData.disabled}
                                onChange={(option) => {
                                    console.log(option)
                                    setThemeResultsData({ ...themeResultsData, ['results']: { id: JSON.parse(option)['mvsIdpk'], name: JSON.parse(option)['mvsName'] } })
                                    // setSelectedObjective({id: JSON.parse(option)['id'], name: JSON.parse(option)['name']})
                                }}
                                className='w-full mr-0'
                                placeholder=""

                                value={themeResultsData.theme.name}
                            >
                                {themeData.map((option: any, index: number) => {
                                    return (
                                        <option key={index} value={JSON.stringify(option)}> {option['name']}</option>
                                    )
                                })}
                            </Select>

                            <span
                                onClick={() => {
                                    setThemeResultsData({ ...themeResultsData, ['results']: { id: '', name: '' } })
                                }}
                                style={{ height: 22.5, marginBottom: 2, borderWidth: 1, width: 23.9 }}
                                className='ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded'>
                                <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src={reset} alt="reset" />
                                {/* <img className='' src= {''} alt="" /> */}
                            </span>


                        </div>
                    </Form.Item>
                    
                    <TextareaTemplate 
                        height={47} 
                        label={"Result"}
                        labelCol={2}
                        defaultValue={themeResultsData.results}
                        readonly={disableForms}
                        setCallbackValue={(e:any)=>{setThemeResultsData({ ...themeResultsData, ['results']: e.target.value })}}
                    />

                    {/* <Theme_Result_Select /> */}
                    {/* <Form.Item
                        labelCol={{ span: 2 }}

                        label={<p className='dark:bg-fill dark:text-darkModeSkin-base text-xs'>{'Result'}</p>}
                        rules={[{ required: false, }]}
                    > */}
                        {/* <div className="flex flex-row ">
                            <TextArea value={themeResultsData.results} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                setThemeResultsData({ ...themeResultsData, ['results']: e.target.value })
                            }} style={{ resize: 'none', height: '46px' }} className='w-full ' />

                            <span className='h-full w-4 ml-1 flex items-center justify-center hover:cursor-pointer hover'> */}
                                {/* <img className='w w-9/12 h-9/12' src= {reset} alt="reset" /> */}
                            {/* </span> */}

                        {/* </div> */}
                    {/* </Form.Item> */}

                    <TextareaTemplate 
                        height={47} 
                        label={"Short Name"}
                        labelCol={2}
                        defaultValue={themeResultsData.shortName}
                        readonly={disableForms}
                        setCallbackValue={(e:any)=>{setThemeResultsData({ ...themeResultsData, ['shortName']: e.target.value })}}
                    />


                    {/* <Form.Item
                        labelCol={{ span: 2 }}

                        label={<p className='dark:bg-fill dark:text-darkModeSkin-base text-xs'>{'Short Name'}</p>}
                        rules={[{ required: false, }]}
                    > */}
                        {/* <div className="flex flex-row ">
                            <TextArea
                                value={themeResultsData.shortName}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                    setThemeResultsData({ ...themeResultsData, ['shortName']: e.target.value })
                                }} style={{ resize: 'none', height: '46px' }} className='w-full ' />

                            <span className='h-full w-4 ml-1 flex items-center justify-center hover:cursor-pointer hover'> */}
                                {/* <img className='w w-9/12 h-9/12' src= {reset} alt="reset" /> */}
                            {/* </span> */}

                        {/* </div> */}
                    {/* </Form.Item> */}

                    <TextareaTemplate 
                        height={47} 
                        label={"Commentary"}
                        labelCol={2}
                        defaultValue={themeResultsData.commentary}
                        readonly={disableForms}
                        setCallbackValue={(e:any)=>{setThemeResultsData({ ...themeResultsData, ['commentary']: e.target.value })}}
                    />

                    {/* <Form.Item
                        labelCol={{ span: 2 }}

                        label={<p className='dark:bg-fill dark:text-darkModeSkin-base text-xs'>{'Commentry'}</p>}
                        rules={[{ required: false, }]}
                    > */}
                        {/* <div className="flex flex-row ">
                            <TextArea
                                value={themeResultsData.commentary}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                    setThemeResultsData({ ...themeResultsData, ['commentary']: e.target.value })
                                }} style={{ resize: 'none', height: '46px' }} className='w-full ' />

                            <span className='h-full w-4 ml-1 flex items-center justify-center hover:cursor-pointer hover'>
                                {/* <img className='w w-9/12 h-9/12' src= {reset} alt="reset" /> */}
                            {/* </span> */}

                        {/* </div>  */}
                    {/* </Form.Item> */}

                    <TextareaTemplate 
                        height={65} 
                        label={"Remarks"}
                        labelCol={2}
                        defaultValue={themeResultsData.remarks}
                        readonly={disableForms}
                        setCallbackValue={(e:any)=>{setThemeResultsData({ ...themeResultsData, ['remarks']: e.target.value })}}
                    />

                    {/* <Form.Item
                        labelCol={{ span: 2 }}

                        label={<p className='dark:bg-fill dark:text-darkModeSkin-base text-xs'>{'Remarks'}</p>}
                        rules={[{ required: false, }]}
                    > */}
                        {/* <div className="flex flex-row ">
                            <TextArea
                                value={themeResultsData.remarks}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                    setThemeResultsData({ ...themeResultsData, ['remarks']: e.target.value })
                                }} style={{ resize: 'none', height: '65px' }} className='w-full ' />

                            <span className='h-full w-4 ml-1 flex items-center justify-center hover:cursor-pointer hover'>
                                {/* <img className='w w-9/12 h-9/12' src= {reset} alt="reset" /> */}
                            {/* </span> */}

                        {/* </div> */} 
                    {/* </Form.Item> */}
                    {/* <Theme_Result_Mini_TextArea />
                                    <Theme_Result_TextArea /> */}


                    {/* <Group />
                                            <StaffName />
                                            <RequestWindow />
                                            <Batch />
                                            <AssignedDept />
                                            <AssignedSelection />
                                            <AssignedUnit />
                                            <AssignedLocation /> */}
                    <Form.Item labelCol={{ span: 2 }} label={<p className='dark:bg-fill dark:text-darkModeSkin-base text-xs'>{'Order?'}</p>}>
                        <div className='w-full  flex flex-col  '>
                            <div style={{ width: 'calc(900px - 93px)', }} className=' flex  justify-between  '>
                                {/* <li className='flex justify-start '><Cancel/></li> */}
                                <ul className='flex justify-evenly'>
                                    <li><Input type='number' disabled={themeResultsData.disabled} value={themeResultsData.order} onChange={(e: any) => {
                                        setThemeResultsData({ ...themeResultsData, ['order']: e.target.value })
                                    }} className='w-24' /></li>
                                    <li className=' flex items-center flex-row h-6 ml-5'><p className='mr-2 text-xs'>Active? : </p>  <Checkbox style={{borderColor:borderTheme}} className='border-[1px] rounded px-1' disabled={themeResultsData.disabled} checked={themeResultsData.activity} onChange={(e) => {
                                        setThemeResultsData({ ...themeResultsData, ['activity']: e.target.checked })
                                    }} /></li>
                                </ul>
                                <ul className='flex justify-end '>
                                    {updatingStatus === true ? <UpdateButton handleUpdate={() => { setModalData(updateModalData); validateForms(); }} /> : <SaveButton handleSave={() => { setModalData(saveModalData); validateForms(); }} />}
                                    {showCancel === true ? <Cancel useCallbackFunc={true} cancel_button_toggler={() => {
                                        setShowCancel(false);
                                        dispatch(task_form_disable(true));
                                        // dispatch(update_route_state(false))
                                        dispatch(task_footer_update(false));
                                        setThemeResultsData({
                                            ...themeResultsData,
                                            ['disabled']: true,

                                        })
                                        setUpdatingStatus(false)
                                    }} /> : <NewButton useCallbackFunc={true} new_button_toggler={() => {
                                        setShowCancel(true); setDisableForms(false);
                                        clearFields();
                                    }} />}
                                </ul>
                            </div>
                        </div>
                    </Form.Item>
                </Form>
            </div>



        </div>
    )
})