export const cooperate_initiatve_footer_datagrid_cols = [
    {id: 1,caption : "INITIATIVE", dataField : 'cinName', dataType : "string", fixed : true,width:120},
    {id: 2,caption : "SHORT NAME", dataField : 'cinShtName', dataType : "string", fixed : true,width:120},
    {id: 3,caption : "ACTIVE?", dataField : 'cinActive', dataType : "boolean", fixed : true,width:85},
    {id: 4,caption : "PERSPECTIVE", dataField : 'ptvName', dataType : "string", fixed : true,width:120},

    {id: 5,caption : "START DATE", dataField : 'cinStartDate', dataType : "datetime", fixed : true,width:120},
    {id: 6,caption : "END DATE", dataField : 'cinEndDate', dataType : "datetime", fixed : true,width:120},

    {id: 7,caption : "REMARKS", dataField : 'cinRmks', dataType : "string", fixed : true,width:4000},
    
]