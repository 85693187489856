import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { selectExprsTypes } from '../../../components/human-resource/setups/data/types/selectsTypes'

interface tier2CheckboxState {
  selectValue : number | null
  selectAfter0 : number | null
  selectAfter1 : number | null
  selectAfter2 : number | null
  selectAfter3 : number | null

  inputsAfter0 : string
  inputsAfter1 : string
  inputsAfter2 : string
  inputsAfter3 : string
  inputsAfter4 : string

  select0 : number | null
  select0Text :string
  select1 : number | null
  select1Text :string
  select2 : number | null
  select2Text :string
  select3 : number | null
  select3Text :string

  datalistAfter0 : string
  selectExprs : selectExprsTypes[]

  checkbox0 : boolean
  checkbox1 : boolean
  checkbox2 : boolean
  checkbox3 : boolean
  checkbox4 : boolean
  checkbox5 : boolean
  checkbox6 : boolean
  checkbox7 : boolean
  checkbox8 : boolean

  checkboxInline0 : boolean
  checkboxInline1 : boolean

  name : string | null
  shortname : string | null
  order : number
  remarks : string | null
  value: number | null
  active : boolean
  data : any
  idExpr : string | null
  nameExpr : string | null
  shortnameExpr : string | null
  SelectedFieldData : any
  SelectedFieldID : number
  saveDataOnclick : boolean,
  updateDataOnclick : boolean,
  warningState : boolean,
  warningDataExpr : any
  successModalState : boolean,
  successText : any,
  optionalNameValidation : string

  existsUrl : string,
  checkExistsTrigger : boolean,
  checkExistsOnclick : boolean,
}

var initialState = {
  selectValue : null,
  selectExprs : {idExpr : 'idkey', nameExpr : 'datakey', label : 'label'},

  selectAfter0 : null,
  selectAfter1 : null,
  selectAfter2 : null,
  selectAfter3 : null,

  select0 : null,
  select0Text :'',
  select1 : null,
  select1Text :'',
  select2 : null,
  select2Text :'',
  select3 : null,
  select3Text :'',


  name : '',
  shortname : '',
  order : 0,
  remarks : '',
  active : true,
  value: 0,
  data : [],
  idExpr : null,
  nameExpr : null,
  shortnameExpr : null,
  selectedFieldData : {},
  SelectedFieldID : 0,

  inputAfter0 : '',
  inputAfter1 : '',
  inputAfter2 : '',
  inputAfter3 : '',
  inputAfter4 : '',


  checkbox0 : false,
  checkbox1 : false,
  checkbox2 : false,
  checkbox3 : false,
  checkbox4 : false,
  checkbox5 : false,
  checkbox6 : false,
  checkbox7 : false,
  checkbox8 : false,

  checkboxInline0 : false,
  checkboxInline1 : false,

  saveDataOnclick : false,
  updateDataOnclick : false,
  warningState : false,
  warningDataExpr : 'name',
  successModalState : false,
  successText : '',
  optionalNameValidation : '',

  existsUrl : '',
  checkExistsTrigger : false,
  checkExistsOnclick : false,
}as tier2CheckboxState | any

const tier2CheckboxSlice = createSlice({
  name: 'tier2Checkbox',
  initialState,
  reducers: {
    //set state reducers [name ,shortname ...]
    setTier2CheckboxValue(state, action: PayloadAction<any>) {
      // set value of given expr
      // console.log(action)
      if (action.payload.expr === 'select0'||action.payload.expr === 'select1'||action.payload.expr === 'select2'||action.payload.expr === 'select3') {
        state[action.payload.expr] = action.payload.value.id;
        state[`${action.payload.expr}Text`] = action.payload.value.data;
      }
      else{
        state[action.payload.expr] = action.payload.value;
      }

      if (action.payload.expr === 'selectedFieldData') {
        const selected = action.payload.value;
        
        // set ID of selected Item
        state.SelectedFieldID = selected[state.idExpr];
      }
    },
    tier2CheckboxSaveOnclick(state,action ?: PayloadAction<any>){ //handle form data save
      const regex = /^[&@%*~?<>\+]/;

      const selectValue = state.selectValue;
      const name = state.name;
      const shortname = state.shortname;

      if (!selectValue && action?.payload === 'type2') {
        state.warningDataExpr = state.selectExprs.label;
        state.warningState = true;
      }
      else if(!state.select0 && action?.payload === 'transUnit'){
        state.warningDataExpr = 'department';
        state.warningState = true;
      }
      else if(!state.select1 && action?.payload === 'transUnit'){
        state.warningDataExpr = 'section';
        state.warningState = true;
      }
      else if(!state.select2 && action?.payload === 'transUnit'){
        state.warningDataExpr = 'unit';
        state.warningState = true;
      }
     // name validation
     if (!name || name.replace(/\s/g,"") == "") {
      state.warningDataExpr = 'name';
      state.warningState = true;
      return;
      }
      if (regex.test(name[0])) {
        state.warningDataExpr = 'validName';
        state.warningState = true;
        return;
      }
      // short name validation
      else if (!shortname || shortname.replace(/\s/g,"") == "") {
        state.warningDataExpr = 'shortname';
        state.warningState = true;
        return;
      }
      else if (regex.test(shortname[0])) {
        state.warningDataExpr = 'validShortname';
        state.warningState = true;
        return;
      }
      //exists check
      else if (state.existsUrl){
        state.checkExistsOnclick = true;
        state.checkExistsTrigger = !state.checkExistsTrigger;
      }
      else {
        state.saveDataOnclick = true;
      }
    },
    tier2CheckboxUpdateOnclick(state,action ?: PayloadAction<any>){ //handle form data update
      const regex = /^[&@%*~?<>\+]/;

      const selectValue = state.selectValue;
      const name = state.name;
      const shortname = state.shortname;

      if (!selectValue && action?.payload === 'type2' ) {
        state.warningDataExpr = 'name';
        state.warningState = true;
      }
      else if(!state.select0 && action?.payload === 'transUnit'){
        state.warningDataExpr = 'department';
        state.warningState = true;
      }
      else if(!state.select1 && action?.payload === 'transUnit'){
        state.warningDataExpr = 'section';
        state.warningState = true;
      }
      else if(!state.select2 && action?.payload === 'transUnit'){
        state.warningDataExpr = 'unit';
        state.warningState = true;
      }
     
      // name validation
      if (!name || name.replace(/\s/g,"") == "") {
        state.warningDataExpr = 'name';
        state.warningState = true;
        return;
      }
      if (regex.test(name[0])) {
        state.warningDataExpr = 'validName';
        state.warningState = true;
        return;
      }
      // short name validation
      else if (!shortname || shortname.replace(/\s/g,"") == "") {
        state.warningDataExpr = 'shortname';
        state.warningState = true;
        return;
      }
      else if (regex.test(shortname[0])) {
        state.warningDataExpr = 'validShortname';
        state.warningState = true;
        return;
      }
      //exists check
      else if (state.existsUrl){
        state.checkExistsOnclick = true;
        state.checkExistsTrigger = !state.checkExistsTrigger;
      }
      else {
        state.updateDataOnclick = true;
      }
    },
  },
})

export const { setTier2CheckboxValue,tier2CheckboxSaveOnclick,tier2CheckboxUpdateOnclick} = tier2CheckboxSlice.actions;
export default tier2CheckboxSlice.reducer;
