/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-pascal-case */
import { Form } from "antd";
import { Pager } from "devextreme-react/data-grid";
import newIcon from "../../../../../../../../assets/new.png";
import save from "../../../../../../../../assets/save2.png";
import TextArea from "antd/es/input/TextArea";
import { comments_cols } from "../../data/datagrid_cols";
import useFetch from "../../../../../../../../hooks/useFetch";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../../app/store";
import { setGeneralValue } from "../../../../../../../../features/generalSlice";
import { PostDataFunc } from "../../../../../../../../functions/post";
import { ModalTemplate } from "../../../../../../../../templates/modal";
import Datagrid_template from "../../../../../../../../templates/Datagrid";
import { useCrudFunc } from "../../../../../../../../functions/crud";
import { useAccountInfo } from "../../../../../../../../hooks/useAccountInfo";

interface props {
  selectedRecord?: any;
  fullPage: any;
}

interface stateTypes {
  comment: string;
  grid: any;
}

export const Right_Comment = ({ fullPage, selectedRecord }: props) => {
  const [comments_data] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetCommentsMaintenanceActivities?mtaIDpk=${selectedRecord?.mtaIDpk}`
  );

  const dispatch = useDispatch();
  const [states, setStates] = useState<stateTypes>({
    comment: "",
    grid: [],
  });
  const [posting, updating] = useCrudFunc();
  const updateState = (key: string, value: any) => {
    setStates((prev: any) => ({ ...prev, [key]: value }));
  };

  const confirm = {
    title: "Send Comment?",
    message: "Are you sure you want to save this comment?",
    okText: "Yes",
  };

  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const form_disable = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>(confirm);
  const [userId,employeeId,userInfo]=useAccountInfo()

  const sendComment = async () => {
    try {
      await posting(`AsmTmsEntMaintenanceActivity/SaveComment`, {
        mtaIDpk: selectedRecord?.mtaIDpk,
        macComment: states.comment,
       
      },`saved comment sent by ${userInfo.empName}` );
      setIcon("success")
      setModalData({
        message: " Comment saved successfully",
        title: "Comment Saved!",
        okText: "Ok",
      });
      setOpenModal(true);
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    } catch (e: any) {
      setIcon("warning")
      setModalData({
        message: "Failed to save comment",
        title: "Failed!",
        okText: "Ok",
      });
      setOpenModal(true);
    }
  };

  //save comment
  const saveComment = () => {
    setOpenModal(true);
    setModalData(confirm);
    setIcon("question");
  };
  return (
    <>
      <ModalTemplate
        icon={icon}
        title={modalData.title}
        message={modalData.message}
        okText={modalData.okText}
        disableCancel={icon === "question" ? false : true}
        open={openModal}
        okHandler={() => {
          modalData.title === "Send Comment?"
            ? sendComment()
            : setOpenModal(false);
        }}
        cancelHandler={() => {
          setOpenModal(false);
        }}
      />

      <div style={{ height: fullPage }} className="w-full ">
        <div className="pt-0.5 h-[30%] w-full border-4">
          <Form
            className="w-full h-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-1 px-2 "
            name="basic"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            size="small"
            layout="horizontal"
          >
            <div className="w-full h-full">
              {/* Request */}
              <Form.Item className=" w-full h-full">
                <div className=" w-full h-full ">
                  <TextArea
                    value={states.grid?.macComment}
                    style={{ resize: "none", height: "160px" }}
                    className="h-full"
                    onChange={(e) => {
                      updateState("comment", e.target.value);
                    }}
                    disabled={form_disable}
                  />
                  <ul className="w-full flex  h-full ">
                    <button
                      onClick={() => {
                        dispatch(
                          setGeneralValue({
                            expr: "formDisabled",
                            value: false,
                          })
                        );
                        updateState("grid", "");
                      }}
                      style={{ height: "20%" }}
                      className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  w-full mb-0.5 ml-0.5   px-3 py-1 border-2 border-slate-200 rounded"
                    >
                      <img
                        style={{ width: "18%" }}
                        className="pr-1 "
                        src={newIcon}
                      />
                    </button>
                    <button
                      onClick={() => {
                        saveComment();
                      }}
                      style={{ height: "" }}
                      className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  w-full ml-0.5 px-3 py-1 border-2 border-slate-200 rounded"
                    >
                      <img
                        style={{ width: "18%" }}
                        className="pr-1 "
                        src={save}
                      />
                    </button>
                  </ul>
                </div>
              </Form.Item>
            </div>
          </Form>
        </div>

        <div className="pt-0.5 h-[57%]">
          <Datagrid_template
            gridheight={400}
            columns={comments_cols}
            data={comments_data}
            onRowClick={(data: any) => {
              updateState("grid", data);
            }}
            disableGroupPanel
            disablePaging
            disableSearch
          />

          <Pager visible={false} />
        </div>
      </div>
    </>
  );
};
