import { Form, Modal } from 'antd'
import React, { useState } from 'react'
import { formPropsConst } from '../../../../../../accessories/constants'
import { SelectsTemplate } from '../../../../../../../templates/select'
import { TextareaTemplate } from '../../../../../../../templates/textarea'
import { BottomBtns } from '../../../../../../../templates/butttons'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../app/store'
import { useAccountInfo } from '../../../../../../../hooks/useAccountInfo'
import { useFormPriviledge } from '../../../../../../../hooks/useFormPriviledge'
import { setGeneralValue } from '../../../../../../../features/generalSlice'
import { ModalTemplate } from '../../../../../../../templates/modal'
import { saveModalData, updateModalData } from '../../../../../../accessories/component_infos'


interface stateTypes {
    fuelTrans: {id: number, name: string};
    tagComments: string;
    active: boolean;
}

interface props{
    refreshGrid:()=>void
    clearSelected:()=>void
    selectedData:any
  }

export const Comments_details = ({refreshGrid, clearSelected,selectedData}:props) => {
    const [states, setStates] = useState<stateTypes>({
        fuelTrans: {id: 0, name: ""},
        tagComments: "",
        active: true
    })
    const setState = (key: string, value: any) => {
        setStates((prev) => ({ ...prev, [key]: value }));
      };

    const form_disable = useSelector(
        (state: RootState) => state.general.formDisabled
      );
    const borderTheme = useSelector((state:RootState)=>state.general.cssBorderClass);
    const [employeeId, userId, userInfo] = useAccountInfo();
    const [privType, setPrivType] = useState<string>("save");
    const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);

    const [openModal, setOpenModal] = useState(false);
    const [modalData, setModalData] = useState<any>(saveModalData);
    const [icon, setIcon] = useState<"warning" | "success" | "question">();
    const [refresh, setRefresh] = useState<boolean>(false);

    const dispatch = useDispatch();

    // wrong grid state

    const populateFields = (clear?: boolean) => {
        const gridState = [
            { key: "fuelTrans", value: clear ? {id : 0, name: ""} : {id: selectedData.fuelTrans.id , name : selectedData.fuelTrans.name} }, //wrong grid state
            { key: "tagComments", value: clear ? "" : selectedData.tagComments },
            { key: "active", value: clear ? "" : selectedData.active },
        ]

        for (let instance of gridState) setState(instance.key, instance.value);
    }

    const validationForms = async () => {
        setIcon("warning");
        if (states.fuelTrans.id === 0) {
          setModalData({ title: "Validation Error!", message: "Fuel Transaction is required" });
          setOpenModal(true);
          return;
        }else{
            setIcon("question");
            setOpenModal(true);
        }
    }

    const newAccessPriv = ()=>{
        setPrivType("save");
        if (savePriv === true) {
          dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
          clearSelected();
          populateFields(true);
        } else {
          dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
          setIcon("warning");
          setOpenModal(true);
          setModalData({ title: "Access Denied!", message: "No Access Privilege" });
        }
    }

    const saveAccessPriv = () => {
        setPrivType("save");
        if (savePriv === true) {
          dispatch(setGeneralValue({ expr: "updateMode", value: false }));
          setIcon("question");
          setModalData(saveModalData);
          setOpenModal(true);
          validationForms();
        } else {
          setIcon("warning");
          setOpenModal(true);
          setModalData({ title: "Access Denied!", message: "No Access Privilege" });
        }
      };

      const updateAcccessPriv = () => {
        setPrivType("update");
        if (updatePriv === true) {
          dispatch(setGeneralValue({ expr: "updateMode", value: true }));
          setIcon("question");
          setModalData(updateModalData);
          setOpenModal(true);
          validationForms();
        } else {
          setIcon("warning");
          setOpenModal(true);
          setModalData({ title: "Access Denied!", message: "No Access Privilege" });
        }
      };

    return (
        <>
            <div className='w-full'>
            <p className='w-full pl-4 font-medium items-center py-1 border-[1px] bg-slate-50'>{"Comment Details"}</p>
            <ModalTemplate 
                icon={icon}
                open={openModal}
                disableCancel={icon === "warning" || icon === "success" ? true : false}
                cancelText={modalData.cancelText}
                cancelHandler={() => {
                    setOpenModal(false);
                }}
                okHandler={() => setOpenModal(false)}
                message={modalData.message}
                title={modalData.title}
                okText={modalData.okText}
            />
            <Form
                className='w-full py-1'
                layout='horizontal'
                size="small"
                labelCol={{ span: 4 }}
            >
                <section className='w-full px-1'>
                    <SelectsTemplate
                        label={"Fuel Transaction"}
                        useCallFunc
                        selectStyle={{width: "60%"}}
                        placeHolder={states.fuelTrans.name}
                        // options={}
                        // idexpr={}
                        // dataexp={}
                        // selectedValue={}
                        // handleClear={()=>{}}
                        // handleRefresh={}
                    />

                    <TextareaTemplate
                        label={"Tag Comments"}
                        labelCol={4}
                        height={100}
                        useCallbackFunc
                        defaultValue={states.tagComments}
                        setCallbackValue={(e) => setState("tagComments", e)}

                    />

                    <div className='w-full '>
                        <BottomBtns
                            useCallbackFunc
                            span
                            addActiveBorders={true}
                            label="Active?"
                            onCancelClick={() => { }}
                            onNewClick={() => {newAccessPriv() }}
                            saveHandler={() => { saveAccessPriv()}}
                            updateHandler={() => { updateAcccessPriv()}}
                            outerDisabled={form_disable}

                        />
                    </div>
                </section>
            </Form>



        </div>
        </>
    )
}
