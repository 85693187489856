import React, { useState, useCallback, useRef, useEffect } from 'react'
import { Form, Input, Select, DatePicker, Checkbox } from 'antd';
import { CheckBox } from 'devextreme-react/check-box';
import refresh from '../../../../assets/refresh.png'
import dayjs from 'dayjs';
import { Cancel, NewButton, SaveButton, UpdateButton } from '../../../../accessories/buttons';
import { RootState } from '../../../../../app/store';
import { useDispatch, useSelector } from 'react-redux';  
import { saveModalData, updateModalData } from '../../../../accessories/component_infos';
import { setGeneralValue, settask_footer_table_refresh, settask_footer_table_selected } from '../../../../../features/generalSlice';
import { ModalTemplate } from '../../../../../templates/modal';
import { setperspectiveDetailsValue, task_footer_table_selected } from '../../../../../features/forms/entries/safetyManager/ppeRequest';
import { inputsTypes, selectsTypes } from '../../../../human-resource/setups/data/types/selectsTypes';
import SetupsDatalistTemplat from '../../../../human-resource/setups/data/setups_datalist_template';
import { InputsTemplate } from '../../../../../templates/input';
import useFetch from '../../../../../hooks/useFetch';
import { PostDataFunc } from '../../../../../functions/post';
import { UpdateDataFunc } from '../../../../../functions/update';


interface props {
    resetTable?: () => void;
    statesx?: any
    refreshx ?: boolean;
    setRefreshx?: (state : any) => void;
}

interface stateTypes {
    name?: string;
    shortName?: string;
    order?: string;
    question?: string;
    fin ?: boolean;
    org ?: boolean;
    cus ?: boolean;
    bus ?: boolean;
    active ?: boolean;
}





export const Perspective_Details_Form = ({ resetTable, statesx, refreshx, setRefreshx }: props) => {


    // import { AssignedDept, AssignedLocation, AssignedSelection, AssignedUnit, Batch, Group, PPE_Select, RequestWindow, StaffName } from './PPE_selects';

    const [states, setStates] = useState<stateTypes>({
        name: '',
        shortName: "",
        order: '',
        question: '',
        fin: false,
        org: false,
        cus: false,
        bus: false,
        active: true,
    })

    const updateState = (key: string, value: any) => {
        setStates((prev) => ({ ...prev, [key]: value }))
    }




    const [refresh, setRefresh] = useState<boolean>(false)

    const dispatch = useDispatch()





    //populate fields
    const populateFields = (clearFields?: boolean) => {

        if(clearFields) {
            dispatch(settask_footer_table_selected([[]]))
        }

        if (statesx.selectedRecord !== undefined && Object.keys(statesx.selectedRecord).length) {
            const states = [
                { stateName: 'name', value: clearFields ? '' : statesx.selectedRecord?.ptvName },
                { stateName: 'shortName', value: clearFields ? '' : statesx.selectedRecord?.ptvShtName },
                //{stateName : 'order',value: clearFields ? '':selectedRecord.hnoSecurityPost},
                { stateName: 'order', value: clearFields ? 0 : statesx.selectedRecord?.ptvOrder },
                { stateName: 'fin', value: clearFields ? false : statesx.selectedRecord?.ptvFinancials },
                { stateName: 'cus', value: clearFields ? false : statesx.selectedRecord?.ptvCustomersStakeholders },
                { stateName: 'org', value: clearFields ? false : statesx.selectedRecord?.ptvOrganisationalCapacity },
                { stateName: 'bus', value: clearFields ? false : statesx.selectedRecord?.ptvBusinessProcesses },
                { stateName: 'active', value: clearFields ? true : statesx.selectedRecord?.ptvActive },
            ]

            //set states
            for (let instance of states) updateState(instance.stateName, instance.value)
        }
    }
    // Data from redux

    const form_disable = useSelector((state: RootState) => state.general.formDisabled)
    const update_state_change = useSelector((state: RootState) => state.general.updateMode)
    const borderTheme = useSelector((state: RootState) => state.general.cssBorderClass)


    //  Current date
    const editedDate = dayjs().format()
    // console.log(editedDate)

    // Modal dialog data states

    const [refreshValue, setRefreshValue] = useState(false);
    const [showModalDisableButton, setshowModalDisableButton] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [modalData, setModalData] = useState<any>(saveModalData);
    const [icon, setIcon] = useState<'warning' | 'success' | 'question'>();
    const [updatingstatus, setUpdatingstatus] = useState(false);
    const [showCancel, setshowCancel] = useState(false);
    const [refreshstate, setRefreshstate] = useState(false);



  

    // Validation 
    const validationForms = async () => {
        setIcon('warning')
        if (states.name === "") {
            setshowModalDisableButton(true);
            setModalData({ message: "Please select data entry for Name", title: " for Name ", okText: "Ok" });
            setOpenModal(true);

        } else if (states.shortName === "") {
            setshowModalDisableButton(true);
            setModalData({ message: "Please select data entry for Short Name", title: " for Short Name", okText: "Ok" });
            setOpenModal(true);
        } else if (states.order === "") {
            setshowModalDisableButton(true);
            setModalData({ message: "Please enter data for Order", title: "Data entry for Order", okText: "Ok" });
            setOpenModal(true);
            // }else if(statesx?.question === ""){
            //     setshowModalDisableButton(true);
            //     setModalData({message: "Please enter data for Questions Answered", title:"Data entry for Questions Answered",okText: "Ok"});
            //     setOpenModal (true);
        }
        else {
            setshowModalDisableButton(false);
            setIcon('question')
            setOpenModal(true);
        }
    }


    const postData = async () => {


        try {

            const res = await PostDataFunc(`PfmPerspective/CreatePfmPerspective`, {
                "ptvName": states.name,
                "ptvShtName": states.shortName,
                "ptvQuestionsAnswered": statesx?.question,
                "ptvOrder": states.order,
                "ptvFinancials": states.fin,
                "ptvOrganisationalCapacity": states.org,
                "ptvCustomersStakeholders": states.cus,
                "ptvBusinessProcesses": states.bus,
                "ptvActive": states.active,
                "ptvRmks": statesx?.remarks,
                "ptvCreatedBy": 1
            });
                // console.log(response);
                // alert('Saved Success')
                //  Refreshes the table when data is manupulated 
            if(res.data.success) {
                dispatch(settask_footer_table_refresh(setRefresh(!refresh)))
                setRefreshx && setRefreshx(!refresh)
                resetTable!()
                setIcon('success')
                setOpenModal(true);
                setshowModalDisableButton(true);
                setModalData({ message: "Data entry saved successfully!", title: "Data Saved!", okText: "Ok" });
            }else {
                throw Error("Something went wrong. Try again later.");
            }

            // setOpenModal(false)

        } catch (e: any) {
            console.log(e);
            setIcon('warning')
            setOpenModal(true);
            setshowModalDisableButton(true);
            setModalData({ message: e.message, title: "Unexpected Error!", okText: "Ok" });
        }

    }

    const updateData = async () => {

        try {
            UpdateDataFunc(`PfmPerspective/UpdatePfmPerspective`, {
                "ptvIdpk": statesx.selectedRecord?.ptvIdpk,
                "ptvName": states.name,
                "ptvShtName": states.shortName,
                "ptvQuestionsAnswered": statesx?.question,
                "ptvOrder": states.order,
                "ptvFinancials": states.fin,
                "ptvOrganisationalCapacity": states.org,
                "ptvCustomersStakeholders": states.cus,
                "ptvBusinessProcesses": states.bus,
                "ptvActive": states.active,
                "ptvRmks": statesx?.remarks,
                "ptvEditedBy": 1,
                "ptvEditedDate": editedDate
            }).then((response) => {
                if(response.data.success) {
                    dispatch(settask_footer_table_refresh(setRefresh(!refresh)))
                    resetTable!()
                    setIcon('success')
                    setshowModalDisableButton(true);
                    setModalData({ message: 'Record has been updated successfully.', title: "Unexpected Error!", okText: "Ok" });
                    setOpenModal(true);
                }else {
                    throw Error('Something went wrong. Try again later.');
                }
            })
            setOpenModal(false)
        } catch (e: any) {
            console.log(e);
            setIcon('warning')
            setOpenModal(true);
            setshowModalDisableButton(true);
            setModalData({ message: e.response ? e.response.data.message : 'Server Error', title: "Unexpected Error!", okText: "Ok" });
        }
    }


    const enableForm = () => {
        dispatch(setGeneralValue({expr: 'formDisabled', value: false}));
    }


    console.log(update_state_change)
    const [name_data, perspective_error, perspective_loading] = useFetch(`PfmPerspective/GetAllPfmPerspectiveNames`)

    const datalists: selectsTypes[] = [
        { id: 0, label: 'Name', optionsData: name_data, idExpr: 'ptvIdpk', dataExpr: "ptvName", stateName: 'name', defaultValue: states.name, style: '90%' },
        { id: 1, label: 'Short Name', optionsData: name_data, idExpr: 'ptvIdpk', dataExpr: "ptvShtName", stateName: 'shortName', defaultValue: states.shortName },
    ]

    const inputs: inputsTypes[] = [
        { id: 0, label: 'Order', stateName: 'order', defaultValue: states.order },
    ]


    useEffect(() => {
        populateFields(false)
        console.log("SEELECCTTEDD", statesx)
    }, [statesx.selectedRecord])

    return (

        <div style={{ width: '450px', minWidth: '450px', height: '100%' }} className="    dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base  ">
            {/* <h4 className=" w-full  mb-2 bg-slate-100  ">Task Details</h4> */}
            <ModalTemplate icon={icon} cancelText={modalData.cancelText}
                disableCancel={showModalDisableButton}
                cancelHandler={() => { setOpenModal(false) }} open={openModal} okHandler={() => { modalData === saveModalData ? postData() : modalData === updateModalData ? updateData() : setOpenModal(false) }}
                message={modalData.message} okText={modalData.okText} title={modalData.title} />
            <div style={{ maxHeight: '160px', minHeight: '160px', height: '160px' }} className=' items-start justify-start '   >

                <Form
                    className='w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 px-2 '
                    name="basic"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    size='small'
                    layout='horizontal'
                    disabled={form_disable}

                >

                    {datalists.map(({ id, label, optionsData, dataExpr, stateName, defaultValue, style }) => {
                        return (
                            <div key={id} className="px-2">
                                <SetupsDatalistTemplat
                                    selectedData={defaultValue}
                                    defaultValue={defaultValue}
                                    listid={label}
                                    nameExpr={dataExpr}
                                    label={label}
                                    refresh={true}
                                    options={optionsData}
                                    style={label == 'Short Name' ? { width: '91.3%', marginLeft: '2.2%' } : { width: '100%' }}
                                    datalistStyle={label === 'Name' && { width: '82.2%' }}
                                    useCallbackFunc
                                    setCallbackValue={(value) => {
                                        updateState(stateName!, value);
                                        // if(label === 'Name'){
                                        //     updateState('name', value);
                                        // }else {
                                        //     updateState('shortName', value);
                                        // }
                                    }}
                                />
                            </div>
                        )
                    })}

                    {inputs.map(({ id, label, stateName, defaultValue }) => {
                        return (
                            <div key={id} className="ml-2.5">
                                <InputsTemplate
                                    defaultValue={defaultValue}
                                    span
                                    style={{ width: '93.4%' }}
                                    useCallbackFunc
                                    orderOnchange={(value) => {
                                        updateState(stateName!, value);
                                    }}
                                    label={label} />
                            </div>
                        )
                    })}


                    <div className='w-full  flex flex-col mt-0.5    '>
                        <div style={{ width: 'calc(94% - 36px)', marginLeft: '33px' }} className=' flex  justify-between mb-0.5'>
                            {/* <li className='flex justify-start '><Cancel/></li> */}
                            <li className=' flex items-center flex-row'><p className='mr-2'>Financial? : </p>  <Checkbox style={{borderColor:borderTheme}} className='border-[1px] rounded px-1' onChange={(e) => { updateState('fin',e.target.checked) }} checked={states.fin} /></li>
                            <li className=' flex items-center flex-row'><p className='mr-2'>Customers/Stakeholders? : </p>  <Checkbox style={{borderColor:borderTheme}} className='border-[1px] rounded px-1' onChange={(e) => { updateState('cus',e.target.checked) }} checked={states.cus} /></li>

                        </div>
                        <div style={{ width: 'calc(94% - 10px)', marginLeft: '7px' }} className=' flex  justify-between mb-0.5'>
                            {/* <li className='flex justify-start '><Cancel/></li> */}
                            <li className=' flex items-center flex-row'><p className='mr-2'>Org.Capacity? : </p>  <Checkbox style={{borderColor:borderTheme}} className='border-[1px] rounded px-1' onChange={(e) => { updateState('org',e.target.checked) }} checked={states.org} /></li>
                            <li className=' flex items-center flex-row'><p className='mr-2'>Business/Internal Processes? : </p>  <Checkbox style={{borderColor:borderTheme}} className='border-[1px] rounded px-1' onChange={(e) => { updateState('bus',e.target.checked) }} checked={states.bus} /></li>

                        </div>

                        <div style={{ width: 'calc(94% - 50px)', marginLeft: '48px' }} className=' flex  justify-between  '>
                            {/* <li className='flex justify-start '><Cancel/></li> */}
                            <li className=' flex items-center flex-row'><p className='mr-2'>Active? : </p>  <Checkbox style={{borderColor:borderTheme}} className='border-[1px] rounded px-1' onChange={(e) => { updateState('active',e.target.checked) }} checked={states.active} /></li>
                            <ul className='flex justify-end  '>
                                {



                                    (form_disable === false && update_state_change === false) ?
                                        <SaveButton handleSave={() => { setModalData(saveModalData); validationForms(); setOpenModal(true); }} />
                                        :
                                        (update_state_change === true) ?

                                            <UpdateButton handleUpdate={() => { setModalData(updateModalData); validationForms(); setOpenModal(true); }} />

                                            :
                                            <SaveButton />

                                    // useCallbackFunc new_button_toggler={()=>{setform_disable(!form_disable)}}
                                }

                                {(form_disable === true) ?
                                    <NewButton new_button_toggler={() => { enableForm(); populateFields(true) }} />

                                    :
                                    (update_state_change === true) ?
                                        <Cancel cancel_button_toggler={() => { }} handleCallbackCancel={() => { setshowCancel(false); }} />
                                        :
                                        !(form_disable) ?
                                            <Cancel cancel_button_toggler={() => { }} handleCallbackCancel={() => { }} />

                                            :
                                            <NewButton new_button_toggler={() => { enableForm(); populateFields(true) }} />
                                }
                            </ul>
                        </div>
                    </div>
                </Form>
            </div>



        </div>
    )
}