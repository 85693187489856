/* eslint-disable react/jsx-pascal-case */
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import {
  setValue,
  tier1SaveOnclick,
  tier1UpdateOnclick,
} from "../../../../../../../features/forms/tier1/createSlice";
import { setGeneralValue } from "../../../../../../../features/generalSlice";
import useFetch from "../../../../../../../hooks/useFetch";
import { ModalTemplate } from "../../../../../../../templates/modal";
import {
  validationsData,
  saveModalData,
  updateModalData,
  deleteModalData,
} from "../../../../../../accessories/component_infos";
import Setups_Template from "../../../../../../human-resource/setups/.setups_templates/Setups_Template";
import {
  exprsTypes,
  modalTypes,
  selectExprsTypes,
} from "../../../../../../human-resource/setups/data/types/selectsTypes";
import { maintenanceIC_columns } from "../data/maintenanceI&CData";
import { WarningAlert } from "../../../../../../accessories/warningAlert";
import dayjs from "dayjs";
import { UpdateDataFunc } from "../../../../../../../functions/update";
import { PostDataFunc  } from "../../../../../../../functions/post";
import { useBusyLoader } from "../../../../../../../hooks/useBusyLoader";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { useCrudFunc } from "../../../../../../../functions/crud";

interface props {
  searchTextx: any;
  statusx: any;
}

export const Assets_setups_maintenanceIC = forwardRef(
  ({ searchTextx, statusx }: props, ref) => {
    const postUrl: string = "MaintenanceItems/CreateMaintenanceItem";
    const updateUrl: string = "MaintenanceItems/UpdateMaintenanceItem";

    const saveSuccessText = {
      message: "Maintenance Item added Successfully",
      ok: "Ok",
      title: "Save Succesful",
    }; // successful save modal message
    const updateSuccessText = {
      message: "Maintenance Item updated Successfully",
      ok: "Ok",
      title: "Update Successful",
    }; // successful update modal message

    // store data expressions[unique]
    const idExpr: String = "mtiIdpk";
    const nameExpr: string = "mtiName";
    const shortnameExpr: string = "mtiShtName";
    const orderExpr: string = "mtiOrder";
    const activeExpr: string = "mtiActive";
    const remarksExpr: string = "mtiRmks";
    const inputsExprsData: any = {
      idExpr: idExpr,
      nameExpr: nameExpr,
      shortnameExpr: shortnameExpr,
      orderExpr: orderExpr,
      activeExpr,
      remarksExpr: remarksExpr,
    };
    const selectsExpr: selectExprsTypes[] = [
      { idExpr: "mtiIdpk", nameExpr: "mtiName", label: "Name" },
      { idExpr: "areIdpk", nameExpr: "areName", label: "area" },
      { idExpr: "areIdpk", nameExpr: "areName", label: "area" },
      { idExpr: "areIdpk", nameExpr: "areName", label: "area" },
      { idExpr: "areIdpk", nameExpr: "areName", label: "area" },
    ];

    const storeExprs = () => {
      const exprs: exprsTypes[] = [
        { expr: "idExpr", value: idExpr },
        { expr: "nameExpr", value: nameExpr },
        { expr: "shortnameExpr", value: shortnameExpr },
        { expr: "selectExprs", value: selectsExpr[0] },
      ];
      for (let i = 0; i < exprs.length; i++) {
        dispatch(setValue({ expr: exprs[i].expr, value: exprs[i].value }));
      }

      // to general slice
      const generalExprs: exprsTypes[] = [
        { expr: "selectExprs", value: selectsExpr },
        { expr: "inputExprs", value: inputsExprsData },
      ];
      for (let i = 0; i < generalExprs.length; i++) {
        dispatch(
          setGeneralValue({
            expr: generalExprs[i].expr,
            value: generalExprs[i].value,
          })
        );
      }
    };

    const [busyLoader, setBusyLoader] = useBusyLoader();
    const [employeeId, userId] = useAccountInfo();

    const dispatch = useDispatch();
    const [refresh, setRefresh] = useState<boolean>(false); //refresh data state for data creation or update

    //modal states
    //--general--
    const triggerSuccessModal = useSelector(
      (state: RootState) => state.tier1Add.successModalState
    ); //trigger success modal
    const SuccessModalText = useSelector(
      (state: RootState) => state.tier1Add.successText
    ); //success modal text

    //--for backend errors--
    const [err, setErr] = useState<{
      state: boolean;
      title: string;
      message: string;
    }>({
      state: false,
      title: "",
      message: "",
    });

    //--warning modal--
    const triggerwarningModal = useSelector(
      (state: RootState) => state.tier1Add.warningState
    ); //trigger warning
    const warningInfos: any = validationsData; //warning type details
    const warningDataExpr = useSelector(
      (state: RootState) => state.tier1Add.warningDataExpr
    ); //warning type selector

    //--save data modal--
    const saveData = saveModalData; //save modal details[data]
    const triggersaveModal = useSelector(
      (state: RootState) => state.tier1Add.saveDataOnclick
    ); //trigger save modal

    //--Update data modal--
    const updateIndInfos = updateModalData; //update modal details[data]
    const triggerupdateModal: boolean = useSelector(
      (state: RootState) => state.tier1Add.updateDataOnclick
    ); //trigger update question

    // on page resize height responsiveness
    const [trigger, setTrigger] = useState<boolean>(false);
    useImperativeHandle(ref, () => ({
      test: () => {
        setTrigger(!trigger);
      },
    }));

    // get current values from  [slice dependent on form type]
    const name = useSelector((state: RootState) => state.tier1Add.name);
    const shortname = useSelector(
      (state: RootState) => state.tier1Add.shortname
    );
    const order = useSelector((state: RootState) => state.tier1Add.order);
    const active = useSelector((state: RootState) => state.tier1Add.active);
    const remarks = useSelector((state: RootState) => state.tier1Add.remarks);
    const selectedID = useSelector(
      (state: RootState) => state.general.selectedFieldData?.mtiIDpk
    );
const [posting , updating] = useCrudFunc();
    //post form Data
    //validate data
    const validateDataSave = () => {
      dispatch(tier1SaveOnclick());
    };
    // post
    const postData = async () => {
      dispatch(setValue({ expr: "saveDataOnclick", value: false })); //disable modal

      try {
        setBusyLoader(saveModalData.progress);

        await posting (`${postUrl}`, {
          mtiName: name,
          mtiShtName: shortname,
          mtiActive: active,
          mtiOrder: order,
          mtiRmks: remarks,
          mtiCreatedBy: userId,
        },`Created Maintenance Item ${name} successfully`);
        setRefresh(!refresh);

        const exprs: exprsTypes[] = [
          { expr: "saveDataOnclick", value: false },
          { expr: "successModalState", value: true },
          { expr: "successText", value: saveSuccessText },
        ];
        for (let i = 0; i < exprs.length; i++) {
          dispatch(setValue({ expr: exprs[i].expr, value: exprs[i].value }));
        }

        //disable form
        dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      } catch (error: any) {
        //set states for db errors modal
        setErr({
          state: true,
          title: saveModalData.error.title,
          message: saveModalData.error.message,
        });
      } finally {
        setBusyLoader("");
      }
    };

    //Update Form data
    //validate data
    const validateDataUpdate = () => {
      dispatch(tier1UpdateOnclick());
    };
    // update
    const UpdateData = async () => {
      dispatch(setValue({ expr: "updateDataOnclick", value: false })); //disable modal

      try {
        setBusyLoader(updateModalData.progress);

        await updating(`${updateUrl}?Id=${selectedID}`, {
          mtiIDpk: selectedID,
          mtiName: name,
          mtiShtName: shortname,
          mtiActive: active,
          mtiOrder: order,
          mtiRmks: remarks,
          mtiEditedBy: userId,
          mtiEditedDate: dayjs().format(),
        },`Updated Maintenance Item to ${name}`);
        setRefresh(!refresh);

        //disable update mode
        dispatch(setGeneralValue({ expr: "updateMode", value: false })); // dispatch fetched data to reduxstore

        const exprs: exprsTypes[] = [
          { expr: "updateDataOnclick", value: false },
          { expr: "successModalState", value: true },
          { expr: "successText", value: updateSuccessText },
        ];
        for (let i = 0; i < exprs.length; i++) {
          dispatch(setValue({ expr: exprs[i].expr, value: exprs[i].value }));
        }
      } catch (error: any) {
        //set states for db errors modal
        setErr({
          state: true,
          title: updateModalData.error.title,
          message: updateModalData.error.message,
        });
      } finally {
        setBusyLoader(""); //disable busy loader
      }
    };

    //modals data
    const modals: modalTypes[] = [
      //warning
      {
        disableCancel: true,
        icon: "warning",
        open: triggerwarningModal,
        okHandler: () =>
          dispatch(setValue({ expr: "warningState", value: false })),
        cancelHandler: () =>
          dispatch(setValue({ expr: "warningState", value: false })),
        message: warningInfos[warningDataExpr!].message,
        cancelText: warningInfos[warningDataExpr!].cancelText,
        okText: warningInfos[warningDataExpr!].okText,
        title: warningInfos[warningDataExpr!].title,
      },
      //backend error
      {
        disableCancel: true,
        icon: "warning",
        open: err.state,
        okHandler: () => setErr((prev) => ({ ...prev, state: false })),
        cancelHandler: () => setErr((prev) => ({ ...prev, state: false })),
        message: err.message,
        cancelText: "",
        okText: "Ok",
        title: err.title,
      },
      //success
      {
        disableCancel: true,
        icon: "success",
        open: triggerSuccessModal,
        okHandler: () =>
          dispatch(setValue({ expr: "successModalState", value: false })),
        cancelHandler: () =>
          dispatch(setValue({ expr: "successModalState", value: false })),
        message: SuccessModalText.message,
        okText: SuccessModalText.okText,
        title: SuccessModalText.title,
      },
      //save
      {
        disableCancel: false,
        icon: "question",
        open: triggersaveModal,
        okHandler: () => postData(),
        cancelHandler: () =>
          dispatch(setValue({ expr: "saveDataOnclick", value: false })),
        message: saveData.message,
        cancelText: saveData.cancelText,
        okText: saveData.okText,
        title: saveData.title,
      },
      //Update
      {
        disableCancel: false,
        icon: "question",
        open: triggerupdateModal,
        okHandler: () => UpdateData(),
        cancelHandler: () =>
          dispatch(setValue({ expr: "updateDataOnclick", value: false })),
        message: updateIndInfos.message,
        cancelText: updateIndInfos.cancelText,
        okText: updateIndInfos.okText,
        title: updateIndInfos.title,
      },
    ];

    // data for datagrid
    const fetchUrl: string = `MaintenanceItems/GetAllMaintenanceItems?search=${searchTextx.text}&activeStatus=${statusx}`;
    const [data, error, loading] = useFetch(fetchUrl, refresh, "mtiIDpk");
    useEffect(() => {
      if (data) {
        storeExprs(); // run func to store expressions on store
        dispatch(setGeneralValue({ expr: "idExpr", value: idExpr })); // dispatch fetched data to reduxstore
        dispatch(setGeneralValue({ expr: "formData", value: data })); // dispatch fetched data to reduxstore
      }

      loading ? setBusyLoader("") : setBusyLoader("");
    }, [data, loading]);

    // form parameters
    const form_header = "Maintenance Item";

    return (
      <>
        {error && <WarningAlert />}

        {modals.map(
          (
            {
              disableCancel,
              icon,
              okHandler,
              cancelHandler,
              open,
              cancelText,
              okText,
              message,
              title,
            }: modalTypes,
            index
          ) => {
            return (
              <div key={index}>
                <ModalTemplate
                  icon={icon && icon}
                  disableCancel={disableCancel}
                  cancelText={cancelText && cancelText}
                  open={open}
                  okHandler={okHandler}
                  cancelHandler={cancelHandler}
                  message={message}
                  okText={okText}
                  title={title}
                />
              </div>
            );
          }
        )}

        <Setups_Template
          isToolbar={false}
          createData={validateDataSave}
          updateData={validateDataUpdate}
          datagridClicked={() => {}}
          labelCol={6}
          trigger={trigger}
          datagridColumns={maintenanceIC_columns}
          datagridData={data}
          formHeader={form_header}
        />
      </>
    );
  }
);
