export const PerformanceSetups_periods_columns = [
    {id : 0, caption : 'PERIOD',dataField : 'prdName',datatype : 'number', width : 160, fixed : true, visible : true},
    {id : 1, caption : 'SHORT NAME', dataField : 'prdShtName',datatype : 'string', width : 160, fixed : true , visible : true},
    {id : 2, caption : 'ACTIVE?', dataField : 'prdActive',datatype : 'boolean', width : 90, fixed : true , visible : true},
    {id : 3, caption : 'OPENED?', dataField : 'prdOpened',datatype : 'boolean', width : 90, fixed : true , visible : true},
    {id : 4, caption : 'START DATE', dataField : 'prdStartDate',dataType : 'datetime', width : 150, fixed : true , visible : true,},
    {id : 5, caption : 'END DATE', dataField : 'prdEndDate',dataType : 'datetime', width : 150, fixed : true , visible : true},
    {id : 6, caption : 'ORDER', dataField : 'prdOrder',datatype : 'number', width : 90, fixed : false , visible : true},
    {id : 7, caption : 'REMARKS',dataField : 'prdRmks',datatype : 'string', width : 5000, fixed : false , visible : true}
]
