import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { registerLicense } from "@syncfusion/ej2-base";
import ErrorHandler from "./hooks/useHandleWindowError";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// Registering Syncfusion license key
registerLicense(
  "Mgo+DSMBaFt+QHJqVEZrXVNbdV5dVGpAd0N3RGlcdlR1fUUmHVdTRHRcQlViSH5Vd0JgUHdXcXI=;Mgo+DSMBPh8sVXJ1S0R+XVFPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXZSd0VlWHhdeX1VQmY=;ORg4AjUWIQA/Gnt2VFhiQlBEfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5bd0RjX35Zc31cR2la;MjYzNzMzM0AzMjMxMmUzMDJlMzBNNlpGTUxYSUdGQ0tvdmszbXpDWmZJWmpvVGFtVmdFekJQTzJGYU1SSTFVPQ==;MjYzNzMzNEAzMjMxMmUzMDJlMzBGdzE5OUJNZmlYWURMcDU3UDB6TWhlRnVUVzhLa1F1L0ovcnczS3d1TWNRPQ==;NRAiBiAaIQQuGjN/V0d+Xk9FdlRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31TfkRiWXlfd3BVRmFYVw==;MjYzNzMzNkAzMjMxMmUzMDJlMzBqTGdVZEx1Z3J2Q09wenVDM3Btd1ZOanNwY29zUFBEbjUzRFh0M09ob1ZBPQ==;MjYzNzMzN0AzMjMxMmUzMDJlMzBYcTFIemRLOTAxZVBQMUpQMS8xenVSaDJwUWpCLytmUlIyd2JiK1dNVmZrPQ==;Mgo+DSMBMAY9C3t2VFhiQlBEfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5bd0RjX35ZdHRVTmRa;MjYzNzMzOUAzMjMxMmUzMDJlMzBtYS9OTm0yaGxkS0UxdWNHT1M5MXQveklJdjh6d0VSdGE1THZtMEZ3cndBPQ==;MjYzNzM0MEAzMjMxMmUzMDJlMzBrYUlBNTZlam5qeGZxSk8xMmNrN0FOck81SDJ4R3Q3L05nMGx3ZTVWeVBNPQ==;MjYzNzM0MUAzMjMxMmUzMDJlMzBqTGdVZEx1Z3J2Q09wenVDM3Btd1ZOanNwY29zUFBEbjUzRFh0M09ob1ZBPQ=="
);

root.render(
  <Provider store={store}>
    <ErrorHandler />
    <App />
  </Provider>
);

reportWebVitals();
