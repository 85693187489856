import { Input, Form } from "antd";
import { DataGrid } from "devextreme-react";
import {
  Pager,
  Column,
  Scrolling,
  Editing,
  GroupPanel,
  HeaderFilter,
  Selection,
} from "devextreme-react/data-grid";
import newIcon from "../../../../../../../assets/new.png";
import save from "../../../../../../../assets/save2.png";
import TextArea from "antd/es/input/TextArea";
import { SPTL_comments } from "../data/datagrid_cols";

export const Footer_Management_Comment = () => {
  return (
    <div className="w-full h-full">
      <ul className="w-full ">
        <li className=" px-2 my-1">
          <Input className="w-full " size="small" />
        </li>
      </ul>
      <div className="pt-0.5 h-2/5 w-full border-4">
        <Form
          className="w-full h-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-1 px-2 "
          name="basic"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          size="small"
          layout="horizontal"
        >
          <div className="w-full h-full">
            {/* Request */}
            <Form.Item className=" w-full h-full">
              <div className=" w-full h-full flex">
                <div style={{ width: "70%" }}>
                  <TextArea
                    style={{ resize: "none", height: "160px" }}
                    className="h-full"
                  />
                </div>
                <ul
                  style={{ width: "30%", height: "100%" }}
                  className="w-full  h-full "
                >
                  {/* ------------------------------------- */}
                  <button
                    style={{ height: "30%" }}
                    className="mb-1 dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  w-full ml-0.5 px-3 py-1 border-2 border-slate-200 rounded"
                  >
                    <img alt="save-icon" className="pr-1 w-1/2 " src={save} />
                  </button>

                  {/* --------------------------------------- */}

                  <button
                    style={{ height: "30%" }}
                    className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  w-full mb-0.5 ml-0.5   px-3 py-1 border-2 border-slate-200 rounded"
                  >
                    <img alt="new_icon" className="pr-1 w-1/2" src={newIcon} />
                  </button>
                </ul>
              </div>
            </Form.Item>
          </div>
        </Form>
      </div>

      <div className="pt-0.5 h-3/5">
        <DataGrid
          dataSource={[]}
          showBorders={true}
          columnWidth={150}
          showRowLines={true}
          showColumnLines={true}
          className="h-full"
        >
          <Pager visible={false} />

          {/* STAFF DETAILS COLUMNS */}
          {/* FIXED COLUMNS */}

          {SPTL_comments.map((data) => {
            return (
              <Column
                key={data.id}
                caption={data.caption}
                dataField={data.dataField}
                alignment={data.alignment as any}
                fixed={data.fixed}
                width={150}
              />
            );
          })}

          {/* SCROLLBAR  */}

          <Scrolling columnRenderingMode="virtual" />

          <HeaderFilter visible={true} allowSearch={true} />

          <GroupPanel visible={true} />

          <Editing mode={"row"} />
          <Scrolling
            columnRenderingMode="virtual"
            mode="virtual"
            showScrollbar={"always"}
          />
          <Selection mode="single" />
        </DataGrid>
        <div className="text-xs text-indigo-500 p-1">Done</div>
      </div>
    </div>
  );
};
