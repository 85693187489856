export const upperGrid = [
  {
    id: 0,
    caption: "STAFF No",
    dataField: "empStaffNo",
    dataType: "string",
    alignment: "left",
    width: 70,
    fixed: false,
  },
  {
    id: 1,
    caption: "NAME",
    dataField: "empName",
    dataType: "string",
    alignment: "left",
    fixed: false,
    width: 100,
  },
  {
    id: 2,
    caption: "JOB TITLE",
    dataField: "jbtShtName",
    dataType: "string",
    alignment: "left",
    fixed: false,
    width: 80,
  },
  {
    id: 3,
    caption: "DEPT",
    dataField: "dptShtName",
    dataType: "string",
    alignment: "left",
    fixed: false,
    width: 80,
  },
  {
    id: 4,
    caption: "SECTION",
    dataField: "sxnShtName",
    dataType: "number",

    alignment: "left",
    fixed: false,
    width: 80,
  },
  {
    id: 5,
    caption: "UNIT",
    dataField: "ratingPercent",
    dataType: "string",
    alignment: "center",
    fixed: false,
    width: 80,
  },
  {
    id: 6,
    caption: "LOCATION",
    dataField: "locShtName",
    dataType: "string",
    alignment: "center",
    width: 80,
    fixed: false,
  },
];

export const lowerGrid = [
  {
    id: 0,
    caption: "CLASS?",
    dataField: "eddClassified",
    dataType: "boolean",
    alignment: "left",
    width: 45,
    fixed: true,
  },
  {
    id: 1,
    caption: "TITLE",
    dataField: "eddTitle",
    dataType: "string",
    alignment: "left",
    fixed: true,
    width: 125,
  },
  {
    id: 2,
    caption: "DOC TYPE",
    dataField: "dtpName",
    dataType: "string",
    alignment: "left",
    fixed: true,
    width: 125,
  },
  {
    id: 3,
    caption: "ACTIVE?",
    dataField: "eddActive",
    dataType: "",
    alignment: "left",
    fixed: false,
    width: 45,
  },
  {
    id: 4,
    caption: "VER?",
    dataField: "eddVerified",
    dataType: "",

    alignment: "left",
    fixed: false,
    width: 45,
  },
  {
    id: 5,
    caption: "DESCRIPTION",
    dataField: "eddDescription",
    dataType: "string",
    alignment: "center",
    fixed: false,
    width: 125,
  },
  {
    id: 6,
    caption: "DOC DATE",
    dataField: "eddDocDate",
    dataType: "datetime",
    alignment: "center",
    width: 125,
    fixed: false,
  },
  {
    id: 7,
    caption: "DATE SAVED",
    dataField: "eddCreationDate",
    dataType: "datetime",
    alignment: "left",
    width: 125,
    fixed: false,
  },
  {
    id: 8,
    caption: "REMARKS",
    dataField: "eddRmks",
    dataType: "string",
    alignment: "left",
    width: 4000,
    fixed: false,
  },
];

export const upperShare_cols = [
  {
    id: 0,
    caption: "OWN",
    dataField: "swtOwn",
    dataType: "boolean",
    width: 65,
    fixed: false,
    alignment: "left",
    allowEditing: true,
  },
  {
    id: 1,
    caption: "RDR",
    dataField: "swtRead",
    dataType: "boolean",
    width: 65,
    fixed: false,
    alignment: "left",
    allowEditing: true,
  },
  {
    id: 2,
    caption: "PRINT",
    dataField: "swtPrint",
    dataType: "boolean",
    width: 65,
    fixed: false,
    alignment: "left",
    allowEditing: true,
  },

  {
    id: 3,
    caption: "NAME",
    dataField: "empName",
    dataType: "string",
    width: 68,
    fixed: false,
    alignment: "left",
    allowEditing: false,
  },
  {
    id: 4,
    caption: "DEPT",
    dataField: "dptShtName",
    dataType: "string",
    width: 65,
    fixed: false,
    alignment: "left",
    allowEditing: false,
  },
];

export const lowerShare_cols = [
  {
    id: 0,
    caption: "OWN",
    dataField: "swtOwn",
    dataType: "boolean",
    width: 65,
    fixed: false,
    alignment: "left",
  },
  {
    id: 1,
    caption: "RDR",
    dataField: "swtRead",
    dataType: "boolean",
    width: 65,
    fixed: false,
    alignment: "left",
  },
  {
    id: 2,
    caption: "PRINT",
    dataField: "swtPrint",
    dataType: "boolean",
    width: 65,
    fixed: false,
    alignment: "left",
  },

  {
    id: 3,
    caption: "NAME",
    dataField: "empName",
    dataType: "string",
    width: 68,
    fixed: false,
    alignment: "left",
  },
  {
    id: 4,
    caption: "DEPT",
    dataField: "dptShtName",
    dataType: "string",
    width: 65,
    fixed: false,
    alignment: "left",
  },
];

export const comment_cols = [
  {
    id: 0,
    caption: "DATE",
    dataField: "acmCreationDate",
    dataType: "datetime",
    width: 45,
    fixed: false,
    alignment: "left",
  },
  {
    id: 1,
    caption: "EMP NAME",
    dataField: "empName",
    dataType: "string",
    width: 45,
    fixed: false,
    alignment: "left",
  },
  {
    id: 2,
    caption: "COMMENT",
    dataField: "acmComment",
    dataType: "string",
    width: 45,
    fixed: false,
    alignment: "left",
  },
];

export const AutoShare_cols1 = [
  {
    id: 0,
    caption: "OWN",
    dataField: "dasOwner",
    dataType: "boolean",
    width: 45,
    fixed: false,
    alignment: "left",
    allowEditing: true,
  },
  {
    id: 1,
    caption: "READ",
    dataField: "dasReader",
    dataType: "boolean",
    width: 60,
    fixed: false,
    alignment: "left",
    allowEditing: true,
  },
  {
    id: 2,
    caption: "PRINT",
    dataField: "dasPrint",
    dataType: "boolean",
    width: 60,
    fixed: false,
    alignment: "left",
    allowEditing: true,
  },
  {
    id: 3,
    caption: "SOURCE",
    dataField: "dasSource",
    dataType: "string",
    width: 60,
    fixed: false,
    alignment: "left",
    allowEditing: false,
  },
  {
    id: 4,
    caption: "STAFF No",
    dataField: "empStaffNo",
    dataType: "string",
    width: 60,
    fixed: false,
    alignment: "left",
    allowEditing: false,
  },
  {
    id: 5,
    caption: "STAFF NAME",
    dataField: "empName",
    dataType: "string",
    width: 60,
    fixed: false,
    alignment: "left",
    allowEditing: false,
  },
  {
    id: 6,
    caption: "JOB TITLE",
    dataField: "jbtShtName",
    dataType: "string",
    width: 60,
    fixed: false,
    alignment: "left",
    allowEditing: false,
  },
  {
    id: 7,
    caption: "STAFF?",
    dataField: "empIsStaff",
    dataType: "boolean",
    width: 60,
    fixed: false,
    alignment: "left",
    allowEditing: false,
  },
  {
    id: 8,
    caption: "DEPT",
    dataField: "dptShtName",
    dataType: "string",
    width: 45,
    fixed: false,
    alignment: "left",
    allowEditing: false,
  },
  {
    id: 9,
    caption: "SECTION",
    dataField: "sxnShtName",
    dataType: "string",
    width: 45,
    fixed: false,
    alignment: "left",
    allowEditing: false,
  },
  {
    id: 10,
    caption: "LOCATION",
    dataField: "locShtName",
    dataType: "string",
    width: 45,
    fixed: false,
    alignment: "left",
    allowEditing: false,
  },
  {
    id: 11,
    caption: "PERSON TYPE",
    dataField: "pstShtName",
    dataType: "string",
    width: 45,
    fixed: false,
    alignment: "left",
    allowEditing: false,
  },
  {
    id: 12,
    caption: "ASSIGN CATEGORY",
    dataField: "ascShtName",
    dataType: "string",
    width: 45,
    fixed: false,
    alignment: "left",
    allowEditing: false,
  },
];
export const AutoShare_cols2 = [
  {
    id: 0,
    caption: "OWN",
    dataField: "dasOwner",
    dataType: "boolean",
    width: 45,
    fixed: false,
    alignment: "left",
  },
  {
    id: 1,
    caption: "READ",
    dataField: "dasReader",
    dataType: "boolean",
    width: 45,
    fixed: false,
    alignment: "left",
  },
  {
    id: 2,
    caption: "PRINT",
    dataField: "dasPrint",
    dataType: "boolean",
    width: 45,
    fixed: false,
    alignment: "left",
  },
  {
    id: 4,
    caption: "STAFF No",
    dataField: "empStaffNo",
    dataType: "string",
    width: 45,
    fixed: false,
    alignment: "left",
  },
  {
    id: 5,
    caption: "STAFF NAME",
    dataField: "empName",
    dataType: "string",
    width: 45,
    fixed: false,
    alignment: "left",
  },
  {
    id: 6,
    caption: "JOB TITLE",
    dataField: "jbtShtName",
    dataType: "string",
    width: 45,
    fixed: false,
    alignment: "left",
  },
  {
    id: 7,
    caption: "STAFF?",
    dataField: "empIsStaff",
    dataType: "boolean",
    width: 45,
    fixed: false,
    alignment: "left",
  },
  {
    id: 8,
    caption: "DEPT",
    dataField: "dptShtName",
    dataType: "string",
    width: 45,
    fixed: false,
    alignment: "left",
  },
  {
    id: 9,
    caption: "SECTION",
    dataField: "sxnShtName",
    dataType: "string",
    width: 45,
    fixed: false,
    alignment: "left",
  },
  {
    id: 10,
    caption: "LOCATION",
    dataField: "locShtName",
    dataType: "string",
    width: 45,
    fixed: false,
    alignment: "left",
  },
  {
    id: 11,
    caption: "PERSON TYPE",
    dataField: "pstShtName",
    dataType: "string",
    width: 45,
    fixed: false,
    alignment: "left",
  },
  {
    id: 12,
    caption: "ASSIGN CATEGORY",
    dataField: "ascShtName",
    dataType: "string",
    width: 45,
    fixed: false,
    alignment: "left",
  },
];

export const shareWith_cols1 = [
  {
    id: 0,
    caption: "DATE",
    dataField: "acmCreationDate",
    dataType: "datetime",
    width: 45,
    fixed: false,
    alignment: "left",
  },
  {
    id: 1,
    caption: "EMP NAME",
    dataField: "empName",
    dataType: "string",
    width: 45,
    fixed: false,
    alignment: "left",
  },
  {
    id: 2,
    caption: "COMMENT",
    dataField: "acmComment",
    dataType: "string",
    width: 45,
    fixed: false,
    alignment: "left",
  },
];
export const shareWith_cols2 = [
  {
    id: 0,
    caption: "DATE",
    dataField: "acmCreationDate",
    dataType: "datetime",
    width: 45,
    fixed: false,
    alignment: "left",
  },
  {
    id: 1,
    caption: "EMP NAME",
    dataField: "empName",
    dataType: "string",
    width: 45,
    fixed: false,
    alignment: "left",
  },
  {
    id: 2,
    caption: "COMMENT",
    dataField: "acmComment",
    dataType: "string",
    width: 45,
    fixed: false,
    alignment: "left",
  },
];
