import { TabPanel } from 'devextreme-react'
import { Item } from 'devextreme-react/cjs/tab-panel'
import React from 'react'
import { tabsPanelTypes } from '../components/human-resource/setups/data/types/selectsTypes'


interface props {
    tabs : tabsPanelTypes[]
    animationEnabled ?: boolean
    swipeEnabled ?: boolean
    ref ?: any
    tabsPosition ?: "right" | "left"| "top"| "bottom"
    stylingMode ?: "primary" | "secondary"
    height ?: number | string
    width ?: number | string
    styles ?: any
    

}

const TabsPanel_template = ({tabs, animationEnabled, swipeEnabled, ref, tabsPosition = "top", stylingMode, styles, height, width}:props) => {
  return (
    <div className='w-full'>

            <TabPanel 
                ref={ref} 
                animationEnabled={animationEnabled} 
                swipeEnabled={swipeEnabled} 
                tabsPosition={tabsPosition} 
                showNavButtons={true} 
                className="w-full h-full  "
                style={styles}
                stylingMode={stylingMode} 
                height={height}
                width={width}
            >
                {tabs.map(({ id, title, contents }) => {
                    return (
                        <Item key={id} title={title}>
                            <div className='w-full h-full  pt-1'>
                                {contents}
                            </div>

                        </Item>
                    )
                })}


            </TabPanel>

        </div>
  )
}

export default TabsPanel_template