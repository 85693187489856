export const assets_productsColumns = [
  {
    id: 0,
    caption: "ID",
    dataField: "prdIdpk",
    dataType: "number",
    width: 80,
    fixed: true,
    visible: false,
  },
  {
    id: 1,
    caption: "PRODUCT",
    dataField: "prdName",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "SHORT NAME",
    dataField: "prdShtName",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 3,
    caption: "ORDER",
    dataField: "prdOrder",
    dataType: "int",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 4,
    caption: "ACTIVE",
    dataField: "prdActive",
    dataType: "boolean",
    width: 90,
    fixed: true,
    visible: true,
  },
  {
    id: 5,
    caption: "REMARKS",
    dataField: "prdRmks",
    dataType: "string",
    width: 5000,
    fixed: false,
    visible: true,
  },
];
