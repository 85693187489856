import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetDataFunc } from "../../functions/get";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";


interface apiStates {
    simVendorData: any | null;
    trackerVendorData: any | null;
    trackerURLdata: any | null;
    mobileNo: any | null;
    isLoading: boolean;
    isPending: boolean;
    isError: boolean;
}
var initialState: apiStates = {
    simVendorData: [],
    trackerVendorData: [],
    trackerURLdata: [],
    mobileNo: [],
    isLoading: false,
    isPending: false,
    isError: false,
}

//fetch 
export const fetchSimVendor= createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "simVendor/fetch",
    async (search: string) => {
        const res = await GetDataFunc(`AsmTmsVehicleSimCardAssignment/AsmTmsVehicleSimCardAssignmentGetAllTelecomVendors?pagenumber=1&pagesize=20&search=${search}`);
        return res.data;
    }
);
export const fetchMobileNo= createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "mobileNo/fetch",
    async (search: string) => {
        const res = await GetDataFunc(`AsmTmsVehicleSimCardAssignment/AsmTmsVehicleSimCardAssignmentGetAllMobileNos?pagenumber=1&pagesize=20&search=${search}`);
        return res.data;
    }
);
//fetch fetchTrackerVendor
export const fetchTrackerVendor = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "trackerVendor/fetch",
    async (search: string) => {
        const res = await GetDataFunc(`AsmTmsVehicleSimCardAssignment/AsmTmsVehicleSimCardAssignmentGetAllTrackerVendors?pagesize=20&pagenumber=1&search=${search}`);
        return res.data;
    }
);
//fetch  trackerURL
export const fetchTrackerURL = createAsyncThunk<apiStates, string, { rejectValue: string }>(
    "trackerURL/fetch",
    async (search: string , states:any) => {
        console.log("statesSSSS",states);
        const res = await GetDataFunc(`AsmTmsVehicleSimCardAssignmentGetAllTrackerUrl?tedIDpk=${states}&pagenumber=1&pagesize=20&search=${search}`);
        return res.data;
    }
);

const vehSIM = createSlice({
    name: 'vehSIMcardApi',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // fetchSimVendor
            .addCase(fetchSimVendor.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchSimVendor.fulfilled, (state, action) => {
                state.isLoading = false;
                state.simVendorData = action.payload;
            })
            .addCase(fetchSimVendor.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            // fetchMobileNo
            .addCase(fetchMobileNo.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchMobileNo.fulfilled, (state, action) => {
                state.isLoading = false;
                state.mobileNo = action.payload;
            })
            .addCase(fetchMobileNo.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            // fetchTrackerVendor
            .addCase(fetchTrackerVendor.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchTrackerVendor.fulfilled, (state, action) => {
                state.isLoading = false;
                state.trackerVendorData = action.payload;
            })
            .addCase(fetchTrackerVendor.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            // fetchtrackerURL
            .addCase(fetchTrackerURL.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchTrackerURL.fulfilled, (state, action) => {
                state.isLoading = false;
                state.trackerURLdata = action.payload;
            })
            .addCase(fetchTrackerURL.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
    }})
    export default vehSIM.reducer;