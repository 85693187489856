import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface props{
    New_Cancel_Button: boolean,
    Edit_Cancel_Button: boolean,
    save_update_Button: boolean,
    disableForm: boolean,
  }
  
  const Vehicle_LogBook_toggle_state: props = {
    New_Cancel_Button: true,
    Edit_Cancel_Button: true,
    save_update_Button: true,
    disableForm: true,
  };
  

const VehicleLogBookSlice =  createSlice ({
    name:'vehicle LogBook',
    initialState: Vehicle_LogBook_toggle_state,
    reducers:{
        setNew_Cancel_Button: (state, action: PayloadAction<boolean>) => {
            state.New_Cancel_Button = action.payload;
          },
          setEdit_Cancel_Button: (state, action: PayloadAction<boolean>) => {
            state.Edit_Cancel_Button = action.payload;
          },
          setSave_Update_Button: (state, action: PayloadAction<boolean>) => {
            state.save_update_Button = action.payload;
          },
          setDisableForm: (state, action: PayloadAction<boolean>) => {
            state.disableForm = action.payload;
          },
    }
});


export const {
    setNew_Cancel_Button,
    setEdit_Cancel_Button,
    setSave_Update_Button,
    setDisableForm,
  } = VehicleLogBookSlice.actions;
  export default VehicleLogBookSlice.reducer;