import { Form } from "antd";

export const Popup_Tips = () =>{
    return (<>
     <Form.Item
     className="h-full w-full"
    >
      <textarea
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {} }
        style={{
            height:"700px"
        }}
        className={`h-full w-full overflow-y-scroll  border-[1px] mb-0 rounded   focus:outline-none hover:outline-none px-[4px] `}
      />
    </Form.Item>
    </>)
}