

export const tag_cols = [
    {id: 0,caption : "TRANSACTION DATE", dataField : '', dataType : "datetime", fixed : true,width:120},
    {id: 1,caption : "VEH REG No", dataField : '', dataType : "string", fixed : true,width:120},
    {id: 2,caption : "TAG COMMENT", dataField : '', dataType : "string", fixed : true,width:120},
    {id: 3,caption : "RESOLVED?", dataField : '', dataType : "boolean", fixed : true,width:80},
    {id: 4,caption : "ACTIVE?", dataField : '', dataType : "boolean", fixed : true,width:90},
    {id: 5,caption : "AMOUNT", dataField : '', dataType : "string", fixed : false,width:80},
    {id: 5,caption : "QUANTITY", dataField : '', dataType : "", fixed : false,width:80},
    {id: 5,caption : "RESOLUTION COMMENT", dataField : '', dataType : "string", fixed : false,width:4000},
]