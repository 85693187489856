/* eslint-disable react/jsx-pascal-case */
import React from "react";
import { checkboxTypes } from "../../../../../../human-resource/setups/data/types/selectsTypes";
import { CheckboxTemlate } from "../../../../../../../templates/checkbox";
import { InputsTemplate } from "../../../../../../../templates/input";
import { FindButton } from "../../../../../../accessories/buttons";
import Datagrid_template from "../../../../../../../templates/Datagrid";
import { RefreshButtonTemplate } from "../../../../../../../templates/RefreshButtonTemplate";
import { formPropsConst } from "../../../../../../accessories/constants";
import { Form } from "antd";
import { AutoShare_cols1, AutoShare_cols2 } from "../data/datagrid_cols";

export const AllDocShare = () => {
  const checkboxes: checkboxTypes[] = [
    {
      id: 0,
      label: "",
      nameExpr: "",
      idExpr: "",
      stateName: "",
      defaultValue: false,
    },
    {
      id: 1,
      label: "",
      nameExpr: "",
      idExpr: "",
      stateName: "",
      defaultValue: false,
    },
    {
      id: 2,
      label: "",
      nameExpr: "",
      idExpr: "",
      stateName: "",
      defaultValue: false,
    },
  ];

  return (
    <div className="w-full h-full p-1">
      <Form {...formPropsConst}>
        <div className="w-full flex bg-yellow-100 justify-evenly items-center">
          {checkboxes.map(
            ({ id, label, nameExpr, idExpr, stateName, defaultValue }) => {
              return (
                <div className="px-1">
                  <CheckboxTemlate
                    customDisabled={false}
                    defaultValue={defaultValue}
                    withBorders={true}
                    setCheckboxOnchange={() => {}}
                  />
                </div>
              );
            }
          )}

          <div className="w-full flex space-x-1 items-center">
            <InputsTemplate
              disabledStatus={false}
              span={true}
              useCallbackFunc
              defaultValue={""}
              label="Search"
              orderOnchange={() => {}}
            />
            <FindButton findSize={16} findHeight={4} />
          </div>
        </div>
      </Form>

      {/* 1st Datagrid */}
      <div className="">
        <Datagrid_template
          gridheight={250}
          columns={AutoShare_cols1}
          data={[]}
          disableGroupPanel
          disablePaging
          disableSearch
        />
      </div>
      {/* 2nd Datagrid */}
      <div className="">
        <li className="w-full bg-yellow-100 py-1 flex justify-between">
          <p className="text-xs text-blue-500 pl-0.5">
            Assign at least one privilege and double click to share. The
            assigned privileges will apply to all existing docs for the user{" "}
          </p>
          <RefreshButtonTemplate handleRefresh={() => {}} />
        </li>
        <Datagrid_template
          gridheight={350}
          columns={AutoShare_cols2}
          data={[]}
          disableGroupPanel
        />
      </div>
    </div>
  );
};
