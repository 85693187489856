import dayjs from "dayjs";
import { forwardRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { RootState } from "../../../../../../app/store";
import { useBusyLoader } from "../../../../../../hooks/useBusyLoader";
import useFetch from "../../../../../../hooks/useFetch";
import useResizeMode from "../../../../../../hooks/useResizeMode";
import Datagrid_template from "../../../../../../templates/Datagrid";
import {
  navbar_height,
  toolbar_height,
} from "../../../../../accessories/component_infos";
import { HrSetupsCustomToolbar } from "../../../../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import { WarningAlert } from "../../../../../accessories/warningAlert";
import {
  searchCriteriaTypes,
  searchTextTypes,
  statusTypes,
} from "../../../../../human-resource/setups/data/types/selectsTypes";
import { mainGridColumns } from "./data/data";
import { DrivingTestsRegistrationForm } from "./widgets/examsRegistrationForm";

export const DrivingTestRegistration = forwardRef(({ a }: any, ref) => {
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  
  const [busyLoader, setBusyLoader] = useBusyLoader();

  
  const genFormat = "YYYY-MM-DD";
  const today = dayjs().add(1, "day").format(genFormat);
  const initialStart = dayjs()
    .subtract(1, "year")
    .startOf("year")
    .format(genFormat);

  const [startDate, setStartDate] = useState<searchTextTypes>({
    text: initialStart,
    temp: initialStart,
  });
  const [endDate, setEndDate] = useState<searchTextTypes>({
    text: today,
    temp: today,
  });
  const [searchText, setSearchText] = useState<searchTextTypes>({
    text: "",
    temp: "",
  });
  const [searchCriteria, setSearchCriteria] = useState<searchCriteriaTypes>({
    temp: 0,
    index: 0,
  });
  const [status, setStatus] = useState<statusTypes>({ temp: "", status: "" });

  
  const setupPageRoute = window.location.pathname; 
  const datagridOnlySearchParam = "table-only"; 
  const formModeSearchParam = "form-and-table"; 
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [resizeMode, initialResizeMode] = useResizeMode(
    searchParams.get("view") === formModeSearchParam ||
      searchParams.get("view") === null
  );

  
  const miscHeight = 7;

  
  const heights_out = navbar_height + news_flash_height + toolbar_height;

  
  const [form_height, set_form_height] = useState<number>(430);
 

  
  
  const [refresh, setRefresh] = useState<boolean>(false);






  
  const toggle_staff_form = (): void => {
    const view = searchParams.get("view");

    view === null || view === formModeSearchParam
      ? navigate({
          pathname: setupPageRoute,
          search: createSearchParams({
            view: datagridOnlySearchParam,
          }).toString(),
        })
      : navigate({
          pathname: setupPageRoute,
          search: createSearchParams({
            view: formModeSearchParam,
          }).toString(),
        });
  };

  
  const pageHeight = resizeMode- (navbar_height + miscHeight + 530); 
  const tableHeight= initialResizeMode - (heights_out + miscHeight + 10)
  

  
  const [data, error, loading] = useFetch(
    `AsmTsmEntDrivingTestRegistration/GetAll?startdate=${startDate.text}&enddate=${endDate.text}&criteria=${searchCriteria.index}&search=${searchText.text}&active=${status.status}`,
    refresh,
    "ergIdpk"
  );

  useEffect(() => {
    loading ? setBusyLoader(".") : setBusyLoader("");
  }, [loading]);

  const searchCriteriaData = [
    { id: 0, value: "Exams" },
    { id: 1, value: "Staff Name" },
    { id: 2, value: "Job Title" },
    { id: 3, value: "Department" },
    { id: 4, value: "Section" },
    { id: 5, value: "Unit" },
    { id: 6, value: "Location" },
  ];

  return (
    <>
      {/* error */}
      {error && <WarningAlert />}

      <div className="h-full">
        <div className="px-2">
          <HrSetupsCustomToolbar
            startDateChanged={(date) =>
               setStartDate({
                temp: dayjs(date).format(genFormat),
                text: startDate.text,
              })
            }
            endDateChanged={(date) =>
              setEndDate({
                temp: dayjs(date).format(genFormat),
                text: endDate.text,
              })
            }
            searchTextOnchange={(text) =>
              setSearchText({ temp: text, text: searchText.text })
            }
            searchCriteriaOnchange={(crit) =>
              setSearchCriteria({ temp: crit, index: searchCriteria.index })
            }
            checkOnChange={(check) =>
              setStatus({ temp: check, status: status.status })
            }
            handleFind={() => {
              setStartDate({ text: startDate.temp, temp: startDate.temp });
              setEndDate({ text: endDate.temp, temp: endDate.temp });

              setSearchText({ temp: searchText.temp, text: searchText.temp });
              setSearchCriteria({
                temp: searchCriteria.temp,
                index: searchCriteria.temp,
              });
              setStatus({ temp: status.temp, status: status.temp });
            }}
            handleRefresh={() => {
              setRefresh((current) => !current)
              setSearchText({ temp:"", text:"" });
              setSearchCriteria({
                temp:0,
                index: 0,
              });

            }}

            
            startDate={startDate.temp}
            endDate={endDate.temp}
            withDates
            searchCriteria
            searchCriteriaData={searchCriteriaData}
            disableNew_edit
            toggler={toggle_staff_form}
          />
        </div>

        {/* form section */}
        {searchParams.get("view") !== datagridOnlySearchParam && (
          <section style={{ height: form_height }} className=" form border-b-4">
            <DrivingTestsRegistrationForm
              refreshGrid={() => setRefresh((current) => !current)}
            />
          </section>
        )}

        {/* datagrid section */}
        <section className=" px-2">
          <Datagrid_template
            gridheight={
              searchParams.get("view") === formModeSearchParam ||
              searchParams.get("view") === null
                ? pageHeight
                : tableHeight
            }
            data={data}
            columns={mainGridColumns}
          />
        </section>
      </div>
    </>
  );
});
