/* eslint-disable react/jsx-pascal-case */
import { Tooltip } from "devextreme-react";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import useFetch from "../../../../hooks/useFetch";
import { InputsTemplate } from "../../../../templates/input";
import { ModalTemplate } from "../../../../templates/modal";
import { useDispatch } from "react-redux";
import { setGeneralValue } from "../../../../features/generalSlice";
import { UpdateDataFunc, UpdateDataFuncParams } from "../../../../functions/update";
import Datagrid_template from "../../../../templates/Datagrid";
import { Alerts_cols } from "./data/data";
import useResizeMode from "../../../../hooks/useResizeMode";

interface options {
  pageResized: Function;
  // height?:number;
}

const Alerts = forwardRef(({}: options, ref) => {
  const dispatch = useDispatch();
  const refreshRx = useSelector((state: RootState) => state.general.refresh);
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  // const [viewHeight, setViewHeight] = useState(
  //   window.innerHeight - 58.05 - 32 - 40 - 38 - 19 - news_flash_height
  // );
  // let  [resizeOnf11,initialSize] = useResizeMode(window.innerWidth === screen.width && window.innerHeight === screen.height);
  let  [resizeOnf11,initialSize] = useResizeMode(window.innerWidth  && window.innerHeight );

  let  normalPageSize =  initialSize - 250;
  let  onF11PageSize = resizeOnf11 - 250;
  useEffect(() => {
    dispatch(setGeneralValue({ expr: "alertCount", value: gridData.length }));
  }, [refreshRx]);

  useImperativeHandle(ref, () => ({
    test: () => {},
  }));

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [okText, setOkText] = useState<string>("Ok");
  const [message, setMessage] = useState<string>(
    "Are you sure want to dismiss alerts"
  );
  const [title, setTitle] = useState<string>("Confirm Action");
  const [icon, setIcon] = useState<any>("question");
  const [disableCancel, setDisableCancel] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [refresh, setRefresh] = useState(false);
  const [searchText, setSearchText] = useState<string | number>("");
  const [mainUrl, setMainUrl] = useState(
    `USCXGenAlerts/GetAllUSCXGenAlert?searchText=${searchText}`
  );
  const [gridData, gridErr, gridLoading] = useFetch(mainUrl, refreshRx, "");

  const dismissSelected = async () => {
    try {
      if (Object.keys(selectedItem).length > 0) {
        const res = await UpdateDataFuncParams(
          `USCXGenAlerts/ClearUSCXGenAlert?alertIDpk=${selectedItem.altIDpk}`
        );
        if (res.status === 200) {
          setRefresh(true);
          setIcon("success");
          setTitle("Success");
          setMessage("Alert has been successfully dismissed");
          setOkText("Ok");
          setDisableCancel(true);
          setOpenModal(true);
          setRefresh(!refresh);
          dispatch(setGeneralValue({ expr: "refresh", value: !refresh }));
        } else {
          throw Error("Something went wrong. Please try again later.");
        }
      } else {
        throw Error("Please select a record to dismiss");
      }
    } catch (e: any) {
      setIcon("warning");
      setTitle("Unexpected Error!");
      setOkText("Ok");
      setMessage(e.message);
      setDisableCancel(true);
      setOpenModal(true);
    }
  };

  const dismissAll = async () => {
    try {
      const res = await UpdateDataFuncParams(`USCXGenAlerts/ClearAllUSCXGenAlert`);
      if (res.status === 200) {
        setIcon("success");
        setTitle("Success");
        setMessage("All alerts has been successfully dismissed");
        setOkText("Ok");
        setDisableCancel(true);
        setOpenModal(true);
      } else {
        throw Error("Something went wrong. Please try again later.");
      }
    } catch (e: any) {
      setIcon("warning");
      setTitle("Unexpected Error!");
      setOkText("Ok");
      setMessage(e.message);
      setDisableCancel(true);
      setOpenModal(true);
    }
  };

  const refreshData = () => {
    setMainUrl(
      `USCXGenAlerts/GetAllUSCXGenAlert?` +
        (searchText !== "" ? `searchText=${searchText}` : "")
    );
  };

  const onRowSelected = (selected: any) => {
    setSelectedItem(selected.data);
  };

  return (
    <div className=" bg-gray-200">
      <div className=" w-68 ">
        <ModalTemplate
          icon={icon}
          disableCancel={disableCancel}
          cancelText={"No"}
          open={openModal}
          okHandler={() => {
            if (title === "Dismiss All") {
              dismissAll();
            } else if (title === "Dismiss Selected") {
              dismissSelected();
            } else {
              setOpenModal(false);
            }
          }}
          cancelHandler={() => setOpenModal(false)}
          message={message}
          okText={okText}
          title={title}
        />

        <Datagrid_template
          // gridheight={window.innerWidth === screen.width && window.innerHeight === screen.height ? normalPageSize : onF11PageSize}
          gridheight={window.innerWidth && window.innerHeight ? normalPageSize : onF11PageSize}
          columns={Alerts_cols}
          data={gridData}
          wordWrap={true}
          onRowClick={onRowSelected}
          disableGroupPanel
          disablePaging
          showColumnHeaders={false}
          searchWidth={333}
        />
        <div className="flex justify-around items-center py-[4px]">
          <InputsTemplate
            orderOnchange={(val) => {
              setSearchText(val);
            }}
            useCallbackFunc={true}
            disabledStatus={false}
            inputStyle={{ height: 26, marginLeft: 4 }}
            style={{ width: 140 }}
            span={true}
          />
          <span
            className="w-[26px] h-[26px]  border-2 rounded-md border-stone-300 flex items-center justify-center"
            style={{ borderColor: borderTheme }}
          >
            <i className="fa-solid fa-magnifying-glass text-blue-400 p-[2.5px]  "></i>
          </span>
          <span
            style={{ borderColor: borderTheme }}
            onClick={() => {
              refreshData();
            }}
            className="w-[26px] h-[26px]  border-2 rounded-md border-stone-300 flex items-center justify-center"
          >
            <i className="fa-solid fa-binoculars text-blue-400 p-[2.5px]  "></i>
          </span>
          <span
            style={{ borderColor: borderTheme }}
            className="w-[26px] h-[26px]  border-2 rounded-md border-stone-300 flex items-center justify-center"
          >
            <i className="fa-solid fa-folder-open text-amber-400 p-[2.5px] "></i>
          </span>
          <span
            onClick={() => {
              setIcon("question");
              setTitle("Dismiss Selected");
              setMessage(
                "Are you sure you want to dismiss the selected alert?"
              );
              setOkText("Yes");
              setDisableCancel(false);
              setOpenModal(true);
            }}
            style={{ borderColor: borderTheme }}
            className="w-[26px] h-[26px]  border-2 rounded-md border-stone-300 flex items-center justify-center"
          >
            <i className="fa-solid fa-xmark text-red-500 p-[2.5px] "></i>
          </span>
          <span
            onClick={() => {
              setIcon("question");
              setTitle("Dismiss All");
              setMessage("Are you sure you want to dismiss all alerts?");
              setOkText("Yes");
              setDisableCancel(false);
              setOpenModal(true);
            }}
            style={{ borderColor: borderTheme }}
            className="w-[26px] h-[26px]  border-2 rounded-md border-stone-300 flex items-center justify-center"
          >
            <i className="fa-solid fa-file-excel  text-white  p-[2.5px]  "></i>
          </span>
          {/* </div> */}
        </div>
        <Tooltip
          target=".fa-magnifying-glass"
          showEvent="dxhoverstart"
          hideEvent="dxhoverend"
          hideOnOutsideClick={true}
        >
          <div>Search active alerts</div>
        </Tooltip>
        <Tooltip
          target=".fa-binoculars"
          showEvent="dxhoverstart"
          hideEvent="dxhoverend"
          hideOnOutsideClick={true}
        >
          <div>Search all alerts</div>
        </Tooltip>
        <Tooltip
          target=".fa-folder-open"
          showEvent="dxhoverstart"
          hideEvent="dxhoverend"
          hideOnOutsideClick={true}
        >
          <div>Open form for the selected alerts</div>
        </Tooltip>
        <Tooltip
          target=".fa-xmark"
          showEvent="dxhoverstart"
          hideEvent="dxhoverend"
          hideOnOutsideClick={true}
        >
          <div>Delete or dismiss selected alerts</div>
        </Tooltip>
        <Tooltip
          target=".fa-file-excel"
          showEvent="dxhoverstart"
          hideEvent="dxhoverend"
          hideOnOutsideClick={true}
        >
          <div>Dismiss all active alerts</div>
        </Tooltip>
      </div>
    </div>
  );
});

export default Alerts;
