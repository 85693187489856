import { useEffect, useState } from "react";
import useFetch from "../../../../../../../hooks/useFetch";
import { VehicleDetailsToolbar } from "./vehicleDetails-toolbar";
import VehicleDetailsDetails from "./vehicleDets-details";
import CustomLoader from "../../../../../../accessories/custom-loader";
import { WarningAlert } from "../../../../../../accessories/warningAlert";
import dayjs from "dayjs";
import {
  currentDate,
  startingDate,
} from "../../../../../../accessories/component_infos";

interface SelectTypes {
  vdtIdpk?: number;
  regNo?: string;
  vehClass?: { id: number; value: string };
  manufacturer?: { id: number; value: string };
  engineType?: { id: number; value: string };
  powerTrain?: { id: number; value: string };
  len?: number;
  width?: number;
  height?: number;
  noCylinder?: any;
  horsePower?: any;
  netWeight?: number;
  grossWeight?: any;
  manuDate?: string;
  chassisNo?: string;
  vehMake?: { id: number; value: string };
  country?: { id: number; value: string };
  fuelType?: { id: number; value: string };
  transType?: { id: number; value: string };
  status?: { id: number; value: string };
  noOfAxles?: any;
  noOfWheels?: any;
  tankCap?: any;
  price?: any;
  currency?: { id: number; value: string };
  supplyDate?: string;
  maxSpeed?: number;
  speedUnit?: { id: number; value: string };
  vehModel?: { id: number; value: string };
  supplier?: { id: number; value: string };
  engineMake?: { id: number; value: string };
  cubicCapacity?: any;
  seatingCap?: any;
  engineNo?: string;
  tyreSizeF?: any;
  tyreSizeM?: any;
  tyreSizeR?: any;
  other?: string;
  regDate?: string;
  colour?: string;
  general?: boolean;
  bus?: boolean;
  specialised?: boolean;
  motorBike?: boolean;
  equipment?: boolean;
  lightDuty?: boolean;
  heavyDuty?: boolean;
  vehicle?: boolean;
  active?: boolean;
  remarks?: string;
}

const VehicleDetails = () => {
  const [showTable, setShowTable] = useState(false);

  // switch button toggler
  const switch_toggle = () => {
    if (showTable === true) {
      setShowTable(false);
    } else {
      setShowTable(true);
    }
  };

  const [states, setStates] = useState<SelectTypes>({
    vdtIdpk: 0,
    regNo: " ",
    vehClass: { id: 0, value: "" },
    manufacturer: { id: 0, value: "" },
    engineType: { id: 0, value: "" },
    powerTrain: { id: 0, value: "" },
    len: 0,
    width: 0,
    height: 0,
    noCylinder: "",
    horsePower: "",
    netWeight: 0,
    grossWeight: "",
    manuDate: "",
    chassisNo: "",
    vehMake: { id: 0, value: "" },
    country: { id: 0, value: "" },
    fuelType: { id: 0, value: "" },
    transType: { id: 0, value: "" },
    status: { id: 0, value: "" },
    noOfAxles: "",
    noOfWheels: "",
    tankCap: "",
    price: "",
    currency: { id: 0, value: "" },
    supplyDate: "",
    maxSpeed: 0,
    speedUnit: { id: 0, value: "" },
    vehModel: { id: 0, value: "" },
    supplier: { id: 0, value: "" },
    engineMake: { id: 0, value: "" },
    cubicCapacity: "",
    seatingCap: "",
    engineNo: "",
    tyreSizeF: "",
    tyreSizeM: "",
    tyreSizeR: "",
    other: "",
    regDate: "",
    colour: "",
    general: false,
    bus: false,
    specialised: false,
    motorBike: false,
    equipment: false,
    lightDuty: false,
    heavyDuty: false,
    vehicle: false,
    active: false,
    remarks: "",
  });

  const updateState = (key: string, value: any) => {
    setStates((prev: any) => ({ ...prev, [key]: value }));
  };

  const selectVehDetails = (e: any) => {
    const state = [
      { key: "vdtIdpk", name: e?.vdtIDpk },
      { key: "regNo", name: e?.vdtRegNo },
      { key: "chassisNo", name: e?.vdtChassisNo },
      {
        key: "vehClass",
        name: { id: e?.vdtVehicleClassIDfk, value: e?.vhcName },
      },
      { key: "manufacturer", name: { id: e?.mftIDpk, value: e?.mftName } },
      { key: "engineType", name: { id: e?.egtIDpk, value: e?.egtName } },
      { key: "powerTrain", name: { id: e?.pwtIDpk, value: e?.pwtName } },
      { key: "len", name: Number(e?.vdtLength).toFixed(2) },
      { key: "width", name: Number(e?.vdtWidth).toFixed(2) },
      { key: "height", name: Number(e?.vdtHeight).toFixed(2) },
      { key: "noCylinder", name: e?.vdtNoOfCylinders },
      { key: "horsePower", name: e?.vdtHorsePower },
      { key: "netWeight", name: Number(e?.vdtNetWeight).toFixed(2) },
      { key: "grossWeight", name: Number(e?.vdtGrossWeight).toFixed(2) },
      { key: "manuDate", name: e?.vdtManufactureDate },
      { key: "vehMake", name: { id: e?.vmkIdpk, value: e?.vmkName } },
      { key: "country", name: { id: e?.ctyIDpk, value: e?.ctyName } },
      { key: "fuelType", name: { id: e?.vdtFuelTypeIDfk, value: e?.ftpName } },
      { key: "transType", name: { id: e?.trtIDpk, value: e?.trtName } },
      { key: "status", name: { id: e?.vdtStatusIDfk, value: e?.vstName } },
      { key: "noOfAxles", name: e?.vdtNoOfAxles },
      { key: "noOfWheels", name: e?.vdtNoOfWheels },
      { key: "tankCap", name: e?.vdtTankCapacity },
      { key: "price", name: Number(e?.vdtPrice).toFixed(2) },
      {
        key: "currency",
        name: { id: e?.vdtPriceCurrencyIDfk, value: e?.curSymbol },
      },
      { key: "supplyDate", name: e?.vdtSupplyDate },
      { key: "vehModel", name: { id: e?.vdtModelIDfk, value: e?.mdlName } },
      { key: "supplier", name: { id: e?.vdtSupplierIDfk, value: e?.splName } },
      { key: "engineMake", name: { id: e?.egmIDpk, value: e?.egmName } },
      { key: "cubicCapacity", name: e?.vdtCubicCapacity },
      { key: "seatingCap", name: e?.vdtSeatingCapacity },
      { key: "engineNo", name: e?.vdtEngineNo },
      { key: "tyreSizeF", name: e?.vdtTyreSizeFront },
      { key: "tyreSizeM", name: e?.vdtTyreSizeMiddle },
      { key: "tyreSizeR", name: e?.vdtTyreSizeRear },
      { key: "maxSpeed", name: Number(e?.vdtSpeedometerLimit).toFixed(2) },
      { key: "colour", name: e?.vdtColour },
      { key: "general", name: e?.vdtGeneral },
      { key: "bus", name: e?.vdtBus },
      { key: "specialised", name: e?.vdtSpecialised },
      { key: "motorBike", name: e?.vdtMotorBike },
      { key: "equipment", name: e?.vdtEquipment },
      { key: "lightDuty", name: e?.vdtLightDuty },
      { key: "heavyDuty", name: e?.vdtHeavyDuty },
      { key: "vehicle", name: e?.vdtVehicle },
      { key: "active", name: e?.vdtActive },
      { key: "remarks", name: e?.vdtRmks },
      { key: "regDate", name: e?.vdtDvlaRegDate },
    ];

    for (let instance of state) updateState(instance.key, instance.name);
  };

  const CriteriaData: any[] = [
    {
      id: 0,
      value: "Registration No",
    },
    {
      id: 1,
      value: "Chasis No",
    },
    {
      id: 2,
      value: "Vehicle Class",
    },
    {
      id: 3,
      value: "Vehicle Make",
    },
    {
      id: 4,
      value: "Vehicle Model",
    },
    {
      id: 5,
      value: "Manufacturer",
    },
    {
      id: 6,
      value: "Supplier",
    },
    {
      id: 7,
      value: "Manufacture Date",
    },
    {
      id: 8,
      value: "Supply Date",
    },
  ];

  const [checkValue, setCheckValue] = useState<{
    temp: boolean | string;
    index: boolean | string;
  }>({ temp: "", index: "" });

  const [searchParam, setSearchParam] = useState<{
    temp: number;
    index: number;
  }>({ temp: 0, index: 0 });
  const [searchTextValue, setSearchTextValue] = useState<{
    temp: string;
    index: string;
  }>({ temp: "", index: "" });
  const [startDate, setStartDate] = useState<{ temp: string; S_date: string }>({
    temp: dayjs(startingDate).format("YYYY-MM-DD"),
    S_date: dayjs(startingDate).format("YYYY-MM-DD"),
  });
  const [endDate, setEndDate] = useState<{ temp: string; E_date: string }>({
    temp: dayjs(currentDate).format("YYYY-MM-DD"),
    E_date: dayjs(currentDate).format("YYYY-MM-DD"),
  });

  const [refresh, setRefresh] = useState(false);

  const [mainDataGridData, mainDataGridError, mainDataGridLoading] = useFetch(
    `AssetTamVehicleDetail/GetAllAssetTamVehicleDetails?criteria=${
      searchParam.index
    }&startDate=${dayjs(startDate.S_date).format("YYYY-MM-DD")}&endDate=${dayjs(
      endDate.E_date
    ).format("YYYY-MM-DD")}&active=${checkValue.index}&search=${
      searchTextValue.index
    }`,
    refresh,
  );

  const [selectedRecord, setSelectedRecord] = useState<any>({});

  const [showImages, setShowImages] = useState<boolean>(false);

  useEffect(() => {
    selectVehDetails(selectedRecord);
  }, [selectedRecord]);

  return (
    <>
      {mainDataGridError && <WarningAlert />}
      {mainDataGridLoading && <CustomLoader text="Fetching Data" />}
      <div className="ml-2">
        <VehicleDetailsToolbar
          toggler={switch_toggle}
          switchState={setShowTable}
          handleRefresh={() => {
            setSearchTextValue({
              temp: "",
              index: "",
            });
            setSearchParam({ temp: 0, index: 0 });
            setCheckValue({ temp: "", index: "" });
            setStartDate({
              temp: dayjs(startingDate).format("YYYY-MM-DD"),
              S_date: dayjs(startingDate).format("YYYY-MM-DD"),
            });
            setEndDate({
              temp: dayjs(currentDate).format("YYYY-MM-DD"),
              E_date: dayjs(currentDate).format("YYYY-MM-DD"),
            });
          }}
          handleFind={() => {
            setSearchTextValue({
              temp: searchTextValue.temp,
              index: searchTextValue.temp,
            });
            setSearchParam({ temp: searchParam.temp, index: searchParam.temp });
            setCheckValue({ temp: checkValue.temp, index: checkValue.temp });
            setStartDate({ temp: startDate.temp, S_date: startDate.temp });
            setEndDate({ temp: endDate.temp, E_date: endDate.temp });
          }}
          checkOnChange={(e) => {
            setCheckValue({ temp: e, index: checkValue.index });
          }}
          setState={setStates}
          outerSelected={selectedRecord}
          setOuterSelected={setSelectedRecord}
          // ---------------------------------------
          startDateChanged={(Sdate) => {
            setStartDate({ temp: Sdate, S_date: startDate.S_date });
          }}
          endDateChanged={(Edate) => {
            setEndDate({ temp: Edate, E_date: endDate.E_date });
          }}
          searchCriteriaData={CriteriaData}
          searchCriteriaOnchange={(e) => {
            setSearchParam({ temp: e, index: searchParam.index });
          }}
          searchTextOnchange={(e) => {
            setSearchTextValue({ temp: e, index: searchTextValue.index });
          }}
          startDate={startDate.temp}
          endDate={endDate.temp}
          setVisible={() => {
            setShowTable(false);
          }}
          setShowImages={() => {
            setShowImages(false);
          }}

          // ----------------------------------------------
        />

        {/* <Staff_customtoolbar /> */}
      </div>

      <div className="pl-2 mb-0.5">
        <VehicleDetailsDetails
          toggled={showTable} //for now only
          mainDataGrid={mainDataGridData}
          setState={updateState}
          states={states}
          setSelectedRecordx={setSelectedRecord}
          selectedRecordx={selectedRecord}
          setVisible={() => {
            setShowTable(false);
          }}
          setShowImages={(val: any) => {
            setShowImages(val);
          }}
          showImage={showImages}
        />
      </div>
    </>
  );
};

export default VehicleDetails;
