export const maintenanceIC_columns = [
  {
    id: 0,
    caption: "MAINTENANCE ITEM",
    dataField: "mtiName",
    dataType: "string",
    width: 200,
    fixed: true,
    visible: true,
  },
  {
    id: 1,
    caption: "SHORT NAME",
    dataField: "mtiShtName",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "ORDER",
    dataField: "mtiOrder",
    dataType: "",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 3,
    caption: "ACTIVE?",
    dataField: "mtiActive",
    dataType: "boolean",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 4,
    caption: "REMARKS",
    dataField: "mtiRmks",
    dataType: "string",
    width: 5000,
    fixed: false,
    visible: true,
  },
];
export const left_Model_columns = [
  {
    id: 0,
    caption: "VEH. CLASS",
    dataField: "vhcName",
    dataType: "string",
    width: 200,
    fixed: true,
    visible: true,
  },
  {
    id: 1,
    caption: "VEH. MAKE",
    dataField: "vmkName",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "MODEL",
    dataField: "mdlName",
    dataType: "string",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 3,
    caption: "MANUFACTURER",
    dataField: "mftName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 4,
    caption: "ACTIVE",
    dataField: "mdlActive",
    dataType: "boolean",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 5,
    caption: "REMARKS",
    dataField: "mdlRmks",
    dataType: "string",
    width: 5000,
    fixed: false,
    visible: true,
  },
];
export const right_Model_columns = [
  {
    id: 0,
    caption: "No",
    dataField: "rowIndex",
    dataType: "number",
    width: 70,
    fixed: true,
    visible: true,
  },
  {
    id: 1,
    caption: "mcaid",
    dataField: "mcaIDpk",
    dataType: "",
    width: 80,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "mdid",
    dataField: "mtiIDpk",
    dataType: "",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 3,
    caption: "Maintenace Item",
    dataField: "mtiName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 4,
    caption: "REMARKS",
    dataField: "mcaRmks",
    dataType: "string",
    width: 5000,
    fixed: false,
    visible: true,
  },
];

export const footer_Model_columns = [
  {
    id: 0,
    caption: "VEH. CLASS",
    dataField: "vhcName",
    dataType: "",
    width: 200,
    fixed: true,
    visible: true,
  },
  {
    id: 1,
    caption: "VEH. MAKE",
    dataField: "vmkName",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "MODEL",
    dataField: "mdlName",
    dataType: "",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 3,
    caption: "MAiNTENANCE ITEM",
    dataField: "mtiName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 4,
    caption: "MAiNTENANCE TYPE",
    dataField: "mtpName",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 5,
    caption: "ITEM CLASS",
    dataField: "mcaItemClass",
    dataType: "string",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 6,
    caption: "ACTIVE?",
    dataField: "mcaActive",
    dataType: "boolean",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 7,
    caption: "REMARKS",
    dataField: "mcaRmks",
    dataType: "string",
    width: 5000,
    fixed: false,
    visible: true,
  },
];
