

export const questions_TH = [
  {
    id: 0,
    caption: "ASKED BY",
    dataField: "askedBy",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 1,
    caption: "QUESTION TIME?",
    dataField: "quesTime",
    alignment: "left",
    fixed: true,
    width: null
  },
  {
    id: 2,
    caption: "QUESTION",
    dataField: "ques",
    alignment: "left",
    fixed: false,
    width: null
  },
  {
    id: 3,
    caption: "ANSWERS",
    dataField: "stDate",
    alignment: "left",
    fixed: false,
    width: null
  },
  {
    id: 4,
    caption: "STAFF No",
    dataField: "staffNo",
    alignment: "left",
    fixed: false,
    width: 100


  },
  {
    id: 5,
    caption: "JOB TITLE",
    dataField: "jobTitle",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 6,
    caption: "DEPT",
    dataField: "dept",
    alignment: "left",
    fixed: false,
    width: null

  },
  
  {
    id: 7,
    caption: "SECTION",
    dataField: "section",
    alignment: "left",
    fixed: false,
    width: null

  },
  
  
  {
    id: 8,
    caption: "LOCATION",
    dataField: "location",
    alignment: "left",
    fixed: false,
    width: null

  },
  
  {
    id: 9,
    caption: "REMARKS",
    dataField: "remarks",
    alignment: "left",
    fixed: false,
    width: 4000


  },

]


export const question_table_data = [
  
]
