import { useState } from "react";
import { useSearchParams, useNavigate, createSearchParams } from "react-router-dom";
import SmsAlertsDetails from "./smsAlerts-details";





const SmsAlertsTransport = () => {

  const [showTable, setShowTable] = useState(true)

  return (
    <>


      <div className="p-2 mb-0.5">
        <SmsAlertsDetails toggled={showTable} />

      </div>
    </>

  );
}

export default SmsAlertsTransport;