
import { Checkbox, Input, Form, Select } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
// import type { SelectProps } from 'antd';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ForwardAssignmentButton, LockSubmissionButton, RecallSubmissionButton, SearchButton, SignOff } from '../../../accessories/buttons';
import { api_url, navbar_height } from '../../../accessories/component_infos';
import { CIA_toolbar } from '../../../accessories/custom-toolbars/cia_toolbar';
import { selectsTypes } from '../../../human-resource/setups/data/types/selectsTypes';
import { lock_submission_datagrid_cols, unit_initiative_assignement_footer_datagrid_cols } from '../../data/datagrid_cols';
import { UIA_TextArea } from '../../data/Performance_inputs';
import { Lock_left_details_select, Lock_right_details_select, SignOFF_Select } from '../../data/Performance_selects';
import { signOff_cols } from './data/datagrid_cols';
import reset from '../../../../assets/reset.png';
import useFetch from '../../../../hooks/useFetch';
import { Footer_datagrid_template } from './data/footer_datagrid';
import { setGeneralValue, settask_footer_update } from '../../../../features/generalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ModalTemplate } from '../../../../templates/modal';
import axios from 'axios';
import { SignOff_toolbar } from '../../../accessories/custom-toolbars/signOff_toolbar';
import { RootState } from '../../../../app/store';
import { useAccountInfo } from '../../../../hooks/useAccountInfo';
import { SelectsTemplate } from '../../../../templates/select';
import Datagrid_template  from '../../../../templates/Datagrid';




interface props {
    state_change: boolean;
}

interface stateTypes {
    
    prdState?: {id:number, name:string};
    signOffSelectedRecord?: any;
    agree?: boolean;
    password?: string;
    dummypwd?: string;

}



export const SignOff_Assignments = ({ state_change }: props) => {
    const news_flash_height = useSelector((state: RootState) => state.general.newsflashHeight);
    // const width = window.innerWidth/2
    // const fixedWidth = 'calc(50% - 8px)';

    const dispatch = useDispatch()
    const [refreshstate, setRefreshstate] = useState<any>(false);


    const height = window.innerHeight - (navbar_height + news_flash_height + 0); //height without navbar
    const [formheight, set_form_height] = useState<number>(0);
    const [switch_change, setSwitch_change] = useState<boolean>(true)

    const formRef = useRef<any>(null);
    const [fullPage, setfullPage] = useState(window.innerHeight - (navbar_height + news_flash_height + 30));
    const pageHeight = window.innerHeight - (navbar_height + news_flash_height + formheight + 25);
    // const fullPage = window.innerHeight - (navbar_height + 45) ; //height without navbar

    // switch toggle
    const switch_toggle = () => {
        if (switch_change) {
            setSwitch_change(!switch_change);

        } else {
            setSwitch_change(!switch_change);

        }
    }

    // states initialization
    const [states, setStates] = useState<stateTypes>({
        prdState: {id:0, name:''},
        signOffSelectedRecord: [],
        agree: false,
        password: "",
        dummypwd: "aaaa12",
    })

    // state func
    const setState = (key: string, value: any) => {
        setStates((prev) => ({ ...prev, [key]: value }))
    }

    const [employeeId, userId, accountInfo] = useAccountInfo();
    const myId =7;
    const empId=3;
    //  Endpoints
    //const [DBdata, DBerror, DBloading] = useFetch(`api/PfmSignOffAssignments/GetAllPfmStaffInitiativesAssignment?prdIDx=${states.prdState}&siaEmpIDx=${1}`)
    const [period_data, period_error, period_loading] = useFetch('api/PfmSignOffAssignments/GetAllPfmPeriodFewCols', refreshstate)
    const [tableData, tableError, tableLoading] = useFetch(`api/PfmSignOffAssignment/GetAllPfmSignOffAssignments?periodIDpk=${states.prdState?.id}&siaEmpIDfk=${employeeId}`)
    // const [tableData, tableError, tableLoading] = useFetch(`api/PfmSignOffAssignment/GetAllPfmSignOffAssignments?periodIDpk=${states.prdState?.id}&siaEmpIDfk=${employeeId}`)
    const selects: selectsTypes[] = [
        { id: 1, label: 'Period', optionsData: period_data, idExpr: "prdIdpk", dataExpr: "prdName", stateName: "prdState", style: "", defaultValue: states.prdState?.name },
    ]

    // datagrid function 
    //----------------------------------------------------------------
    const onChangeRecord = (e: any) => {

            // setSelectedTask_update(item);
            if (setState !== undefined) {

                setState('signOffSelectedRecord', [e]);

                dispatch(setGeneralValue({ expr: "SignOff_formDisabled", value: true }));
            } else {
                console.log('Error Occured')
            }
        }

    // double click feature
    const allowUpdate = (e: any) => {
        if (setState !== undefined) {
            setState('signOffSelectedRecord', [e.data]);
            dispatch(setGeneralValue({ expr: "SignOff_formDisabled", value: false }));
            dispatch(settask_footer_update(true))
        }

    }

    console.log(states)
    const signOffModal = {
        message: "Are you sure you want to Sign-Off this performance contract ",
        title: "Sign-Off",
        okText: "Yes,",
        cancelText: "No,",
        iconSize: "large",
    }

    const [showModalDisableButton, setshowModalDisableButton] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [modalData, setModalData] = useState<any>(signOffModal);
    const [icon, setIcon] = useState<'warning' | 'success' | 'question'>();
    const [updatingstatus, setUpdatingstatus] = useState(false);
    const [showCancel, setshowCancel] = useState(false);


    const signOff = () => {

        try {
            if (states.prdState?.id === 0) {
                setIcon('warning')
                setshowModalDisableButton(true);
                setModalData({ message: "There are no records to Sign-Off. Please check and select the appropriate Period and try again!", title: "Invalid Action", okText: "Ok", iconSize: "large" });
                setOpenModal(true);
            } else {

                if (states.signOffSelectedRecord.length !== 0) {
                    if (states.signOffSelectedRecord[0].siaIDpk === 0) {
                        setIcon('warning')
                        setshowModalDisableButton(true);
                        setModalData({ message: "There is no assignment that is ready for Sign-Off yet. Please check your records and try again!", title: "Sign-Off Record Selection Error", okText: "Ok", iconSize: "large" });
                        setOpenModal(true);
                    } else {
                        if (states.signOffSelectedRecord[0].siaLocked === false) {
                            setIcon('warning')
                            setshowModalDisableButton(true);
                            setModalData({ message: "None of the records have been reviewd and locked by your supervisor yet. You can only Sign-Off locked records", title: "Sign-Off Record Selection Error", okText: "Ok", iconSize: "large" });
                            setOpenModal(true);
                        } else if (states.agree === false) {
                            setIcon('warning')
                            setshowModalDisableButton(true);
                            setModalData({ message: "You have not agreed to the terms and so cannot signOff, Check Agree", title: "Sign-Off Terms and Condition Error", okText: "Ok" });
                            setOpenModal(true);
                        } else if (states.password === "") {
                            setIcon('warning')
                            setshowModalDisableButton(true);
                            setModalData({ message: "Please Enter your password to signOff", title: "Enter Password", okText: "Ok" });
                            setOpenModal(true);
                        }

                        else if (states.password !== states.dummypwd) {
                            setIcon('warning')
                            setshowModalDisableButton(true);
                            setModalData({ message: "Your password is not correct", title: "Invalid Password", okText: "Ok" });
                            setOpenModal(true);
                        } else {
                            setshowModalDisableButton(false);
                            setIcon('question')
                            setModalData({
                                message: "Are you sure you want to Sign-Off this performance contract ",
                                title: "Sign-Off",
                                okText: "Yes",
                                cancelText: "No",

                            })
                            setOpenModal(true);

                            // try on put endpoint here 
                            try {
                                const res = axios.put(`${api_url}/api/PfmSignOffAssignments/UpdatePfmStaffInitiativesAssignmentSignOff?siaPeriodIDx=${states.prdState}&siaEmpIDx=${1}`)
                                setIcon('success')
                                setOpenModal(true);
                                setshowModalDisableButton(true);
                                setModalData({ message: "Data entry saved successfully!", title: "Data Saved!", okText: "Ok" });
                            } catch (err: any) {
                                setIcon('warning')
                                setOpenModal(true);
                                setshowModalDisableButton(true);
                                setModalData({ message: err.message, title: "Unexpected Error!", okText: "Ok" });

                            }

                            // End of using the put endpoint

                        }
                        // end of validation 



                    }
                    // End of if Record Id exist



                } else {
                    setIcon('warning')
                    setshowModalDisableButton(true);
                    setModalData({ message: "No record has been selected. Please select a records and try again!", title: "Sign-Off Record Selection Error", okText: "Ok", iconSize: "large" });
                    setOpenModal(true);
                }
                // End of if no record is selected



            }
            // End of if period is not selected

        } catch (err: any) {
            setIcon('warning')
            setOpenModal(true);
            setshowModalDisableButton(false);
            setModalData({ message: err.message, title: "Record Selection!", okText: "Ok" });
        }
    }
    useEffect(() => {

        set_form_height((formRef.current?.clientHeight) + 25)
        console.log(formRef)
    }, [])
    return (
        <div style={{ height: fullPage }} className='h-full dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base '>
            {/* toggles between the taskForm and the footer grid table vertically */}
            <ModalTemplate icon_width={modalData.iconSize === "large" ? 100 : 50} icon={icon} cancelText={modalData.cancelText}
                disableCancel={showModalDisableButton}
                cancelHandler={() => { setOpenModal(false) }} open={openModal} okHandler={() => { modalData === signOffModal ? signOff() : setOpenModal(false) }}
                message={modalData.message} okText={modalData.okText} title={modalData.title} />


            {switch_change && <div ref={formRef} style={{ height: '300px' }} className=' border-b-4 mb-1  '>
                <div className='px-8'>
                    <p>Note: </p>
                    <p>Please read the following instructions carefully and make sure you agree to all
                        that it says before you enter your password or PIN to accept the terms and conditions.
                    </p>

                    <div  >
                        <div className='flex justify-center  '>
                            <p>The PPE on the previous form has been issued to you in accordance with the Factories
                                ,Shops and Offices Act of 1970(Act 382), Labour Act 2003 (Act 651) and the Organization's
                                Personal Proitective Equipment (PPE) Policy at Work Regulations 2017
                            </p>
                        </div>
                        <div className=''>

                            <div className='flex justify-center'>

                                <ul className='list-inside list-disc'>
                                    <p className='p-4'>
                                        You have the responsibility to :
                                    </p>
                                    <li>Take reasonable care of the PPE provided;</li>
                                    <li>Use the PPE in accordance with the traning and instruction given;</li>
                                    <li>Keep the PPE clean and return it to its place of storage after use where applicable, and </li>
                                    <li>Report any loss or defect immediately to Supervisor or Area Safety Coordinator
                                        or Departmental Safety Representative.
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>

                <footer className='flex mt-4 py-5 items-center bg-yellow-200'>

                    <Form
                        className='w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 px-2 '
                        name="basic"
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 24 }}
                        initialValues={{ remember: true }}
                        autoComplete="off"
                        size='small'
                        layout='horizontal'
                    >
                        <div className=" w-[15%]">
                            {selects.map(({ id, label, optionsData, idExpr, dataExpr, defaultValue, stateName }) => {
                                const total_selects = selects?.length
                                return (
                                    <>
                                        <SelectsTemplate
                                            label={label}
                                            idexpr={idExpr}
                                            dataexp={dataExpr}
                                            useCallFunc={true}
                                            options={optionsData}
                                            placeHolder={defaultValue}
                                            handleRefresh={() => { setRefreshstate(!refreshstate) }}
                                            selectedValue={(e) => { console.log(e); setState(stateName!, {id: e.prdIdpk , name: e.prdName}) }}
                                            disabled={false}
                                        />
                                    </>
                                )
                            })}
                        </div>
                    </Form>

                </footer>
            </div>}


            {/* Footer gridtable  */}
            <div style={{ height: '' }} className='px-2 w-full h-full dark:bg-slate-900 dark:text-darkModeSkin-base'>
                {/* <Footer_datagrid_template
                    gridheight={switch_change ? pageHeight : fullPage}
                    columns={signOff_cols}
                    data={tableData}
                    onChangeRecord={onChangeRecord}
                    updateMode={allowUpdate}

                /> */}
                <Datagrid_template 
                    gridheight={switch_change ? pageHeight : fullPage} 
                    columns={signOff_cols} 
                    data={tableData}  
                    onRowClick={onChangeRecord} 
                    rowDoubleClicked={allowUpdate}             
                />
                <ul className='flex justify-between w-full bg-yellow-200  px-2 items-center  '>
                    <li className='flex w-1/2 justify-start'>
                        <Checkbox onChange={(e) => { setState('agree', e.target.checked) }} />
                        <p className='ml-5'>I agree to the terms and conditions</p>
                    </li>


                    <div className='flex justify-end items-center'>
                        <Form.Item className='w-full ' label={<p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">{'Password/PIN'}</p>}>
                            <div className='flex  items-center'>
                                <li className=''> <Input type='password' id="pwd" onChange={(e) => { setState('password', e.target.value) }} /> </li>
                                <li className='ml-3 '> <SignOff handleSignOff={signOff} /> </li>
                            </div>
                        </Form.Item>
                    </div>



                </ul>
            </div>

        </div>

    )
}





// const reload = ()=>{
//     return(
//         <img className='w-2/6' src={save} />
//     )
// }