/* eslint-disable react/jsx-pascal-case */
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";
import {
  setValue,
  tier1SaveOnclick,
  tier1UpdateOnclick,
} from "../../../../../../features/forms/tier1/createSlice";
import { setGeneralValue } from "../../../../../../features/generalSlice";
import { useAccountInfo } from "../../../../../../hooks/useAccountInfo";
import useFetch from "../../../../../../hooks/useFetch";
import { ModalTemplate } from "../../../../../../templates/modal";
import {
  validationsData,
  saveModalData,
  updateModalData,
  deleteModalData,
} from "../../../../../accessories/component_infos";
import Setups_Template from "../../../../../human-resource/setups/.setups_templates/Setups_Template";
import {
  exprsTypes,
  modalTypes,
  searchTextTypes,
} from "../../../../../human-resource/setups/data/types/selectsTypes";
import { assets_manufacturersColumns } from "./data/manufacturersData";
import { WarningAlert } from "../../../../../accessories/warningAlert";
import dayjs from "dayjs";
import { useBusyLoader } from "../../../../../../hooks/useBusyLoader";
import { UpdateDataFunc } from "../../../../../../functions/update";
import { PostDataFunc  } from "../../../../../../functions/post";
import { useCrudFunc } from "../../../../../../functions/crud";

export const Assets_setups_manufacturer = forwardRef(({ a }: any, ref) => {
  const postUrl: string = "TblAsmTmsManufacturers/create";
  const updateUrl: string = "TblAsmTmsManufacturers/update";

  const saveSuccessText = {
    message: "Manufacturers added Successfully",
    ok: "Ok",
    title: "Success",
  }; // successful save modal message
  const updateSuccessText = {
    message: "Manufacturers updated Successfully",
    ok: "Ok",
    title: "Success",
  }; // successful update modal message

  const [busyLoader, setBusyLoader] = useBusyLoader();
  const idExpr: string = "mftIdpk";
  const nameExpr: string = "mftName";
  const shortnameExpr: string = "mftShtName";
  const orderExpr: string = "mftOrder";
  const activeExpr: string = "mftActive";
  const remarksExpr: string = "mftRmks";
  const inputsExprsData: any = {
    idExpr: idExpr,
    nameExpr: nameExpr,
    shortnameExpr: shortnameExpr,
    orderExpr: orderExpr,
    activeExpr,
    remarksExpr: remarksExpr,
  };

  const storeExprs = () => {
    const exprs = [
      { expr: "existsUrl", value: `TblAsmTmsManufacturers` },
      { expr: "idExpr", value: idExpr },
      { expr: "nameExpr", value: nameExpr },
      { expr: "shortnameExpr", value: shortnameExpr },
    ];
    //to particular redux store based on form type
    for (let i = 0; i < exprs.length; i++) {
      dispatch(setValue({ expr: exprs[i].expr, value: exprs[i].value }));
    }

    // to general slice
    const generalExprs: exprsTypes[] = [
      { expr: "inputExprs", value: inputsExprsData },
    ];
    for (let i = 0; i < generalExprs.length; i++) {
      dispatch(
        setGeneralValue({
          expr: generalExprs[i].expr,
          value: generalExprs[i].value,
        })
      );
    }
  };

  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState<boolean>(false); //refresh data state for data creation or update

  const [employeeId, userId] = useAccountInfo();

  //modal states
  //--general--
  const triggerSuccessModal = useSelector(
    (state: RootState) => state.tier1Add.successModalState
  ); //trigger success modal
  const SuccessModalText = useSelector(
    (state: RootState) => state.tier1Add.successText
  ); //success modal text

  //--for backend errors--
  const [err, setErr] = useState<{
    state: boolean;
    title: string;
    message: string;
  }>({
    state: false,
    title: "",
    message: "",
  });

  //--warning modal--
  const triggerwarningModal = useSelector(
    (state: RootState) => state.tier1Add.warningState
  ); //trigger warning
  const warningInfos: any = validationsData; //warning type details
  const warningDataExpr = useSelector(
    (state: RootState) => state.tier1Add.warningDataExpr
  ); //warning type selector

  //--save data modal--
  const saveData = saveModalData; //save modal details[data]
  const triggersaveModal = useSelector(
    (state: RootState) => state.tier1Add.saveDataOnclick
  ); //trigger save modal

  //--Update data modal--
  const updateIndInfos = updateModalData; //update modal details[data]
  const triggerupdateModal: boolean = useSelector(
    (state: RootState) => state.tier1Add.updateDataOnclick
  ); //trigger update question

  // on page resize height responsiveness
  const [trigger, setTrigger] = useState<boolean>(false);
  useImperativeHandle(ref, () => ({
    test: () => {
      setTrigger(!trigger);
    },
  }));

  // get current values from  [slice dependent on form type]
  const name = useSelector((state: RootState) => state.tier1Add.name);
  const shortname = useSelector((state: RootState) => state.tier1Add.shortname);
  const order = useSelector((state: RootState) => state.tier1Add.order);
  const active = useSelector((state: RootState) => state.tier1Add.active);
  const remarks = useSelector((state: RootState) => state.tier1Add.remarks);
  const selectedID = useSelector(
    (state: RootState) => state.general.SelectedFieldID
  );

  //post form Data
  //validate data
  const validateDataSave = () => {
    dispatch(tier1SaveOnclick());
  };

  const [Posting, Updating, Patching] = useCrudFunc(); //crud functions
  // post
  const postData = async () => {
    dispatch(setValue({ expr: "saveDataOnclick", value: false })); //disable modal
    try {
      setBusyLoader("Saving record...");
      await Posting (`${postUrl}`, {
        mftName: name,
        mftShtName: shortname,
        mftOrder: order,
        mftActive: active,
        mftRmks: remarks,
        mftCreatedBy: `${userId}`,
      },`test`);

      setRefresh((current) => !current);
      setBusyLoader(""); //disable busy loader

      const exprs: exprsTypes[] = [
        { expr: "existsUrl", value: `TblAsmTmsManufacturers` },
        { expr: "saveDataOnclick", value: false },
        { expr: "successModalState", value: true },
        { expr: "successText", value: saveSuccessText },
      ];
      for (let i = 0; i < exprs.length; i++) {
        dispatch(setValue({ expr: exprs[i].expr, value: exprs[i].value }));
      }

      //disable form
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    } catch (error: any) {
      setBusyLoader("");

      //set states for db errors modal
      setErr({
        state: true,
        title: saveModalData.error.title,
        message: saveModalData.error.message,
      });
    }
  };

  //Update Form data
  //validate data
  const validateDataUpdate = () => {
    dispatch(tier1UpdateOnclick());
  };
  // update
  const UpdateData = async () => {
    dispatch(setValue({ expr: "updateDataOnclick", value: false })); //disable modal
    try {
      setBusyLoader("Updating record...");
      await Updating(`${updateUrl}/${selectedID}`, {
        mftIdpk: selectedID,
        mftName: name,
        mftShtName: shortname,
        mftOrder: order,
        mftActive: active,
        mftRmks: remarks,
        mftEditedBy: `${userId}`,
        mftEditedDate: dayjs().format(),
      },`test`);

      // refresh datagrid data
      setRefresh(!refresh);

      setBusyLoader(""); //disable busy loader

      //disable update mode
      dispatch(setGeneralValue({ expr: "updateMode", value: false })); // dispatch fetched data to reduxstore

      const exprs: exprsTypes[] = [
        { expr: "updateDataOnclick", value: false },
        { expr: "successModalState", value: true },
        { expr: "successText", value: updateSuccessText },
      ];
      for (let i = 0; i < exprs.length; i++) {
        dispatch(setValue({ expr: exprs[i].expr, value: exprs[i].value }));
      }
    } catch (error: any) {
      setBusyLoader(""); //disable busy loader

      //set states for db errors modal
      setErr({
        state: true,
        title: updateModalData.error.title,
        message: updateModalData.error.message,
      });
    }
  };

  //modals data
  const modals: modalTypes[] = [
    //warning
    {
      disableCancel: true,
      icon: "warning",
      open: triggerwarningModal,
      okHandler: () =>
        dispatch(setValue({ expr: "warningState", value: false })),
      cancelHandler: () =>
        dispatch(setValue({ expr: "warningState", value: false })),
      message: warningInfos[warningDataExpr!].message,
      cancelText: warningInfos[warningDataExpr!].cancelText,
      okText: warningInfos[warningDataExpr!].okText,
      title: warningInfos[warningDataExpr!].title,
    },
    //backend error
    {
      disableCancel: true,
      icon: "warning",
      open: err.state,
      okHandler: () => setErr((prev) => ({ ...prev, state: false })),
      cancelHandler: () => setErr((prev) => ({ ...prev, state: false })),
      message: err.message,
      cancelText: "",
      okText: "Ok",
      title: err.title,
    },
    //success
    {
      disableCancel: true,
      icon: "success",
      open: triggerSuccessModal,
      okHandler: () =>
        dispatch(setValue({ expr: "successModalState", value: false })),
      cancelHandler: () =>
        dispatch(setValue({ expr: "successModalState", value: false })),
      message: SuccessModalText.message,
      okText: SuccessModalText.okText,
      title: SuccessModalText.title,
    },
    //save
    {
      disableCancel: false,
      icon: "question",
      open: triggersaveModal,
      okHandler: () => postData(),
      cancelHandler: () =>
        dispatch(setValue({ expr: "saveDataOnclick", value: false })),
      message: saveData.message,
      cancelText: saveData.cancelText,
      okText: saveData.okText,
      title: saveData.title,
    },
    //Update
    {
      disableCancel: false,
      icon: "question",
      open: triggerupdateModal,
      okHandler: () => UpdateData(),
      cancelHandler: () =>
        dispatch(setValue({ expr: "updateDataOnclick", value: false })),
      message: updateIndInfos.message,
      cancelText: updateIndInfos.cancelText,
      okText: updateIndInfos.okText,
      title: updateIndInfos.title,
    },
  ];

  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });

  const activeStatus = useSelector(
    (state: RootState) => state.general.activeStatus
  );
  const [status, setStatus] = useState<boolean | string>("");
  // data for datagrid
  const [data, error, loading] = useFetch(
    `TblAsmTmsManufacturers/getAllManufacturers?searchText=${searchText.text}&isActive=${status}`,
    refresh,
    "mftIdpk"
  );
  useEffect(() => {
    if (data) {
      storeExprs(); // run func to store expressions on store
      dispatch(setGeneralValue({ expr: "idExpr", value: idExpr })); // dispatch fetched data to reduxstore
      dispatch(setGeneralValue({ expr: "formData", value: data })); // dispatch fetched data to reduxstore
    }

    loading ? setBusyLoader(".") : setBusyLoader("");
  }, [data, loading]);

  // form parameters
  const form_header = "Manufacturer Details";

  return (
    <>
      {error && <WarningAlert />}

      {modals.map(
        (
          {
            disableCancel,
            icon,
            okHandler,
            cancelHandler,
            open,
            cancelText,
            okText,
            message,
            title,
          }: modalTypes,
          index
        ) => {
          return (
            <div key={index}>
              <ModalTemplate
                icon={icon && icon}
                disableCancel={disableCancel}
                cancelText={cancelText && cancelText}
                open={open}
                okHandler={okHandler}
                cancelHandler={cancelHandler}
                message={message}
                okText={okText}
                title={title}
              />
            </div>
          );
        }
      )}

      <Setups_Template
        searchTextChange={(newText) => {
          setSearchText({ temp: newText, text: searchText.text });
        }}
        handleSearch={() => {
          setSearchText({ text: searchText.temp, temp: searchText.temp });
          setStatus(activeStatus);
          setRefresh((current) => !current);
        }}
        handleRefresh={() => {
          setSearchText({ text: "", temp: "" });
          setStatus("");
          setRefresh((current) => !current);
        }}
        datagridClicked={() => {}}
        createData={validateDataSave}
        updateData={validateDataUpdate}
        trigger={trigger}
        datagridColumns={assets_manufacturersColumns}
        datagridData={data}
        formHeader={form_header}
      />
    </>
  );
});
