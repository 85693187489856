/* eslint-disable react/jsx-pascal-case */
import { Form, Space } from "antd";
import { useCallback, useEffect, useState } from "react";
import { FaBinoculars } from "react-icons/fa";
import cancel from "../../../../../../../assets/close.png";
import save from "../../../../../../../assets/floppy-disk.png";
import refresh from "../../../../../../../assets/reset.png";
import useFetch from "../../../../../../../hooks/useFetch";
import Datagrid_template from "../../../../../../../templates/Datagrid";
import { DateTemplate } from "../../../../../../../templates/date";
import { InputsTemplate } from "../../../../../../../templates/input";
import { ModalTemplate } from "../../../../../../../templates/modal";
import {
  currentDate,
  dateFormat,
  postError
} from "../../../../../../accessories/component_infos";
import { modalTypes } from "../../../../../../human-resource/setups/data/types/selectsTypes";
import { CurrentMembers, staffList } from "../data/data";

import dayjs from "dayjs";
import { Tooltip } from "devextreme-react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import {
  setGeneralValue
} from "../../../../../../../features/generalSlice";
import { GetDataFunc } from "../../../../../../../functions/get";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { useBusyLoader } from "../../../../../../../hooks/useBusyLoader";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import { WarningAlert } from "../../../../../../accessories/warningAlert";
import { useCrudFunc } from "../../../../../../../functions/crud";

interface props {
  refreshGrid: () => void;
}
interface stateTypes {
  exams: { id: number | null; value: string; duration: number; exNoOfQuestions: number };
  staffListSearchText: string;
  currentMembersInitialLength: number;

  membersSearchText: string;
  membersSearchTextTemp: string;

  currentMembers: any[];
  selectedCurrentMembers: any[];
  refreshCurrentMembers: boolean;
  refreshExams: boolean;
  selectedStaff: any;
  startDate: string;
  endDate: string;

  passPercentage: number;
  exNoOfQuestions: number;
  duration: number;
  staffListSearchTemp: string;

  validationModal: { state: boolean; title?: string; message?: string };
  successModal: { state: boolean; title?: string; message?: string };
  confirmModal: {
    state: boolean;
    title?: string;
    okText?: string;
    message?: string;
    action?: () => void;
  };
}

export const DrivingTestsRegistrationForm = ({ refreshGrid }: props) => {
  // const genFormat = "YYYY-MM-DD";

  const [employeeId, userId] = useAccountInfo();
  const [loaderValue, setBusyLoader] = useBusyLoader();
  const dispatch = useDispatch();

  const [Posting, Updating] = useCrudFunc();

  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  const [privType, setPrivType] = useState<string>("add");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);

  const [states, setState] = useState<stateTypes>({
    staffListSearchText: "''",
    staffListSearchTemp: "",

    membersSearchText: "",
    selectedCurrentMembers: [],
    membersSearchTextTemp: "",
    currentMembers: [],
    currentMembersInitialLength: 0,
    selectedStaff: [],

    startDate: currentDate,
    endDate: currentDate,


    passPercentage: 0,
    exNoOfQuestions: 0,
    duration: 0,

    exams: { id: null, value: "", duration: 0, exNoOfQuestions: 0 },
    refreshCurrentMembers: false,
    refreshExams: false,

    validationModal: { state: false, title: "", message: "" },
    successModal: { state: false, title: "", message: "" },
    confirmModal: { state: false, title: "", message: "", okText: "" },
  });
  const {
    staffListSearchText,
    refreshCurrentMembers,
    passPercentage,
    exNoOfQuestions,
    duration,
    selectedCurrentMembers,
    refreshExams,
    membersSearchTextTemp,
    endDate,
    staffListSearchTemp,
    startDate,
    exams,
    membersSearchText,
    selectedStaff,
    validationModal,
    successModal,
    confirmModal,
  } = states;

  const updateState = (stateName: string, value: any) => {
    setState((currentState: any) => ({ ...currentState, [stateName]: value }));
  };


  const [examsData, examsError, examsLoading] = useFetch(
    "AsmTsmEntDrivingTestRegistration/GetEntAsmTsmExams",
    refreshExams
  );
  const [staffData, staffError, staffLoading] = useFetch(
    `AsmTsmEntDrivingTestRegistration/GetEntAsmTsmStaffList?search=${staffListSearchText}`
  );
  const [membersData, membersError, membersLoading] = useFetch(
    exams.id
      ? `AsmTsmEntDrivingTestRegistration/GetEntAsmTsmCurrentGroupMembers?search=${membersSearchText}&examId=${exams.id}`
      : "",
    refreshCurrentMembers,
    "ergIdpk"
  );
  useEffect(() => {
    membersLoading
      ? setBusyLoader(`Fetching ${exams?.value} exam members...`)
      : setBusyLoader("");
  }, [membersLoading]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        if (exams.id) {
          const response = await GetDataFunc(
            `AsmTsmEntDrivingTestRegistration/GetEntAsmTsmCurrentGroupMembers?search=${membersSearchText}&examId=${exams.id}`
          );
          updateState("currentMembers", response.data?.data);
          updateState(
            "currentMembersInitialLength",
            response.data?.data?.length
          );
        }
      } catch (error) {
        updateState("validationModal", {
          state: true,
          title: postError.title,
          message: postError.message,
        });
      }
    };
    exams.id && fetchData();
  }, [exams.id]);



  const addAccessPriv = () => {
    setPrivType("save");
    if (savePriv === true) {
      addSelectedStaff();
    } else {
      dispatch(setGeneralValue({ expr: "formDisabledPriv", value: true }));
      updateState("validationModal", {
        state: true,
        title: "Access Denied!",
        message: "No Access Privilege",
      });
    }
  };



  const removeAccessPriv = () => {
    setPrivType("save");

    if (savePriv === true) {
      removeMembers("validate");
    } else {
      dispatch(setGeneralValue({ expr: "formDisabledPriv", value: true }));
      updateState("validationModal", {
        state: true,
        title: "Access Denied!",
        message: "No Access Privilege",
      });
    }
  };


  const addSelectedStaff = () => {


    if (!exams.id) {
      updateState("validationModal", {
        state: true,
        title: "Select an exam",
        message: "Please select an exam",
      });
    } else if (selectedStaff.length === 0) {
      updateState("validationModal", {
        state: true,
        title: "Select record(s)",
        message: "Please Select record(s) to add",
      });
    } else if (!startDate) {
      updateState("validationModal", {
        state: true,
        title: "Select Start Date",
        message: "Please select start date",
      });
    } else if (!endDate) {
      updateState("validationModal", {
        state: true,
        title: "Select End Date",
        message: "Please select end date",
      });
    } else if (!passPercentage) {
      updateState("validationModal", {
        state: true,
        title: "Enter Pass Percentage",
        message: "Please enter pass percentage",
      });
    } else if (!exNoOfQuestions) {
      updateState("validationModal", {
        state: true,
        title: "Enter Exam Number of Question",
        message: "Please enter pass percentage",
      });
    } else if (!duration) {
      updateState("validationModal", {
        state: true,
        title: "Enter Duration",
        message: "Please enter duration",
      });
    } else {
      updateState("confirmModal", {
        state: true,
        title: "Save Record",
        message: `Are you sure you to register the selected staff${selectedStaff.length > 1 ? "s" : ""
          } for ${exams.value} exam?`,
        action: postData,
      });

    }
  };

  const memberAlreadyExists = (staffNo: number): boolean => {
    const match = membersData.find(
      (member: any) => member?.empStaffNo === staffNo
    );

    if (match) {
      return true;
    } else {
      return false;
    }
  };


  const removeMembers = async (action: "remove" | "validate") => {

    if (action === "validate") {
      if (membersData?.length < 1) {
        updateState("validationModal", {
          state: true,
          title: "No Member",
          message:
            "There is currently no registered member for the selected examination",
        });
        return;
      }
      if (selectedCurrentMembers.length < 1) {
        updateState("validationModal", {
          state: true,
          title: "Select Member",
          message: "Please select at least one member in the list to remove",
        });
        return;
      }

      updateState("confirmModal", {
        state: true,
        title: `Remove Selected Member${selectedCurrentMembers.length > 1 ? "s" : ""
          }?`,
        message: `Are you sure you want to remove the selected Member${selectedCurrentMembers.length > 1 ? "s" : ""
          }?`,
        action: () => removeMembers("remove"),
        okText: "Yes, Remove",
      });

      return;
    }

    try {
      updateState("confirmModal", { state: false, title: "", message: "" });

      setBusyLoader(
        `Removing Member${selectedCurrentMembers.length > 1 ? "s" : ""
        } from list...`
      );

      for (let currentMember of selectedCurrentMembers) {
        await Updating(
          `AsmTsmEntDrivingTestRegistration/DeleteGroupMember`,
          {
            ergIdpk: currentMember?.ergIdpk,
            ergEditedBy: userId,
          },
          `Updated the record with ID = ${currentMember?.ergIdpk} from the list`
        );
      }

      refreshGrid();
      updateState("refreshCurrentMembers", !states.refreshCurrentMembers);
      updateState("successModal", {
        state: true,
        title: "Success",
        message: `Record${selectedCurrentMembers.length > 1 ? "s" : ""
          } removed from list successfully`,
      });
    } catch (error) {

      updateState("validationModal", {
        state: true,
        title: "Removal Failed",
        message: `Failed to remove record${selectedCurrentMembers.length > 1 ? "s" : ""
          } from list. Please try again or contact your Systems Administrator`,
      });
    } finally {
      setBusyLoader("");
    }
  };


  const postData = async () => {
    updateState("confirmModal", { state: false, title: "", message: "" });

    setBusyLoader(
      `Saving Record${selectedCurrentMembers.length > 1 ? "s" : ""}...`
    );

    try {

      for (let index = 0; index < selectedStaff.length; index++) {
        if (!memberAlreadyExists(selectedStaff[index]?.empStaffNo)) {

          await Posting(
            `AsmTsmEntDrivingTestRegistration/CreateEntDrivingTestRegistration`,
            {
              ergExamNameIdfk: exams.id,
              ergEmpIdfk: selectedStaff[index]?.empIdpk,
              ergStartDate: startDate,
              ergEndDate: endDate,
              ergCreatedby: userId,
              ergPassMark: Number(passPercentage).toFixed(2),
              ergNoOfQuestions: Number(exNoOfQuestions),
              ergDuration: Number(duration).toFixed(2),
            },
            `Created the Exams Registration Log with Examination Name ID = ${exams.id}, Employee ID = ${selectedStaff[index]?.empIdpk}, Start Date = ${startDate}, End Date = ${endDate}, Pass Percentage = ${passPercentage}, Number of Questions = ${exNoOfQuestions}, Duration = ${duration}`
          );
        }
      }

      updateState("successModal", {
        state: true,
        title: "Success",
        message: `Record${selectedCurrentMembers.length > 1 ? "s" : ""
          } added successfully`,
      });
      updateState("refreshCurrentMembers", !states.refreshCurrentMembers);
      refreshGrid();
    } catch (error) {
      updateState("validationModal", {
        state: true,
        title: postError.title,
        message: postError.message,
      });
    } finally {
      setBusyLoader("");
    }
  };

  const implementRowColouring = useCallback((row: any) => {
    if (row.rowIndex > 0) {
      (row.rowElement.style.backgroundColor =
        "rgb(134 239 172 / var(--tw-bg-opacity))");
    }
  }, []);


  const modals: modalTypes[] = [

    {
      disableCancel: true,
      icon: "warning",
      open: validationModal.state,
      okHandler: () =>
        updateState("validationModal", { ...validationModal, state: false }),
      cancelHandler: () =>
        updateState("validationModal", { ...validationModal, state: false }),
      message: validationModal.message!,
      cancelText: "",
      okText: "Ok",
      title: validationModal.title!,
    },

    {
      disableCancel: true,
      icon: "success",
      open: successModal.state,
      okHandler: () => {
        updateState("successModal", { ...successModal, state: false });
      },
      cancelHandler: () =>
        updateState("successModal", { ...successModal, state: false }),
      message: successModal.message!,
      okText: "Ok",
      title: successModal.title!,
    },

    {
      disableCancel: false,
      icon: "question",
      open: confirmModal.state,
      okHandler: () => confirmModal.action!(),
      cancelHandler: () =>
        updateState("confirmModal", { ...confirmModal, state: false }),
      message: confirmModal.message!,
      cancelText: "No, Cancel",
      okText: confirmModal.okText!,
      title: confirmModal.title!,
    },
  ];
  return (
    <div className="px-2">
      {/* error alerts */}
      {(examsError || staffError || membersError) && <WarningAlert />}

      {/* modals */}
      {modals.map(
        (
          {
            disableCancel,
            icon,
            okHandler,
            cancelHandler,
            open,
            cancelText,
            okText,
            message,
            title,
          }: modalTypes,
          index
        ) => {
          return (
            <div key={index}>
              <ModalTemplate
                icon={icon && icon}
                disableCancel={disableCancel}
                cancelText={cancelText && cancelText}
                open={open}
                okHandler={okHandler}
                cancelHandler={cancelHandler}
                message={message}
                okText={okText}
                title={title}
              />
            </div>
          );
        }
      )}

      {/* FORM HEADER */}
      <p
        id={"form_header"}
        style={{ borderColor: borderTheme }}
        className="pt-1 border-[1px] px-2 w-full font-semibold bg-slate-100"
      >
        <span className="font-medium">
          {"Driving Test Registration Details"}
        </span>
      </p>
      <Form
        style={{ borderColor: borderTheme }}
        className="w-full border-[1px] border-b-[1px]  border-slate-100 rounded flex justify-between py-1 px-2 "
        name="basic"
        initialValues={{ remember: true }}
        autoComplete="off"
        size="small"
      >
        <section style={{ width: "250px" }} className="w-full  bg">
          {/* DATAGRID [safety groups] */}
          <Form.Item className="" name="Staff No" rules={[{ required: false }]}>
            <div className="flex flex-row justify-between -mt-[3px]">
              <span style={{ width: "89%" }} className="">
                <Datagrid_template
                  onRowClick={(selected) => {

                    updateState("exams", {
                      id: selected?.exnIdpk,
                      value: selected?.exnName,
                      duration: selected?.exnDuration,
                    });
                    updateState("duration", selected?.exnDuration ?? 0);
                  }}
                  selectionMode="single"
                  disableGroupPanel
                  disableSearch
                  disablePaging
                  data={examsData}
                  gridheight={390}
                  columns={[
                    {
                      id: 0,
                      caption: "EXAMS",
                      dataField: "exnName",
                      datatype: "string",
                      width: 245,
                      fixed: false,
                      visible: true,
                    },
                  ]}
                />
              </span>

              <span
                onClick={() => updateState("refreshExams", !refreshExams)}
                className=" flex flex-col mt-2 items-center"
                style={{ width: "10%", marginLeft: "1%" }}
              >
                <section className="w-11/12  ">
                  <div
                    className=" hover:cursor-pointer border-gray-300 rounded"
                    style={{
                      width: "100%",
                      borderWidth: 1,
                      height: 22.9,
                      borderColor: borderTheme,
                    }}
                  >
                    <img
                      className=" w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                      src={refresh}
                      alt=""
                    />
                  </div>
                </section>
              </span>
            </div>
          </Form.Item>
        </section>

        {/* middle section */}
        <section className="" style={{ width: "620px" }}>
          <Form
            style={{ borderColor: borderTheme }}
            className="w-full border-[1px] rounded-b pb-1  border-slate-100"
            name="basic"
          >
            <p
              id={"form_header2"}
              style={{ borderColor: borderTheme }}
              className="px-2 border-b-[1px]  w-full bg-slate-100"
            >
              <span className="font-medium">{"Staff List"}</span>
            </p>
            <div className=" px-1">
              <div className="flex flex-row-reverse justify-between w-full">
                <Space
                  wrap
                  className="flex h-full flex-row items-center mt-[80px] w-4/12"
                >
                  <div className="flex flex-row items-center">
                    <InputsTemplate
                      span
                      id=""
                      useCallbackFunc
                      orderOnchange={(value) => {
                        updateState("staffListSearchTemp", value);
                      }}
                      disabledStatus={false}
                      label="Find"
                    />

                    <span
                      style={{ borderColor: borderTheme }}
                      className=" h-full  flex items-center justify-center px-1 py-0.5 hover:cursor-pointer hover:bg-slate-50 ml-1 rounded border-2"
                      onClick={() => {
                        !staffListSearchTemp
                          ? updateState("staffListSearchText", "''")
                          : updateState(
                            "staffListSearchText",
                            staffListSearchTemp
                          );
                      }}
                    >
                      <FaBinoculars color="#007AFF" />
                    </span>
                  </div>
                </Space>

                <Form
                  labelCol={{ span: "2%" }}
                  className="flex items-center justify-between  pl-2  mt-0.5 w-7/12"
                >
                  <div className="">
                    <div className="pl-8">
                      <DateTemplate
                        disabled={false}
                        style={{ width: 252 }}
                        changeDate={(value: any) => {
                          updateState(
                            "startDate",
                            dayjs(value).format()
                          );
                        }}
                        showTime
                        format={dateFormat}
                        selectedDate={dayjs(startDate)}
                        label="Start Date"
                      />
                    </div>

                    <div className=" pl-8 ">
                      <DateTemplate
                        disabled={false}
                        style={{ marginLeft: 2, width: 250 }}
                        changeDate={(value: any) => {
                          updateState(
                            "endDate",
                            dayjs(value).format()
                          );
                        }}
                        showTime

                        selectedDate={dayjs(endDate)}
                        label="End Date"
                      />
                    </div>

                    <div className=" mb-0.5">
                      <InputsTemplate
                        defaultValue={Number(passPercentage).toFixed(2)}
                        useCallbackFunc
                        orderOnchange={(e) => {
                          updateState("passPercentage", e);
                        }}
                        id="passpercentage"
                        inputStyle={{ height: 24, width: 250, }}
                        numberOnly={true}
                        disabledStatus={false}
                        inputType="number"
                        span
                        label="Pass Percentage"
                      />
                    </div>

                    <div className="pl-1">
                      <InputsTemplate
                        defaultValue={Number(exNoOfQuestions)}
                        useCallbackFunc
                        orderOnchange={(e) => {
                          updateState("exNoOfQuestions", e);
                        }}
                        id="exNoOfQuestions"
                        inputStyle={{ height: 24, width: 200 }}
                        numberOnly={true}
                        disabledStatus={false}
                        inputType="number"
                        span
                        label="No of Question"
                      />
                    </div>

                    <div className="pl-1">
                      <InputsTemplate
                        defaultValue={Number(duration).toFixed(2)}
                        useCallbackFunc
                        orderOnchange={(e) => {
                          updateState("duration", e);
                        }}
                        id="duration"
                        inputStyle={{ height: 24, width: 200 }}
                        numberOnly={true}
                        span
                        disabledStatus={false}
                        inputType="number"
                        label="Duration (mins)"
                      />
                    </div>
                  </div>

                  <span className=" h-full flex justify-start ml-2 ">
                    <button
                      style={{ borderColor: borderTheme }}
                      className="flex flex-row items-center justify-center w-[80px] border-[1px] border-gray-300 h-full px-2 rounded py-3"
                      onClick={async (e) => {
                        e.preventDefault();
                        addAccessPriv();
                      }}
                    >
                      <img className="pr-0.99" src={save} alt="preview" />
                      Add
                    </button>
                  </span>
                </Form>
              </div>

              {/* DATAGRID  */}
              <Form.Item
                className=""
                name="Staff No"
                rules={[{ required: false }]}
              >
                <div className="">
                  <Datagrid_template
                    selectedItemsChange={(selectedItems) => {

                      updateState("selectedStaff", selectedItems);
                    }}
                    selectionMode="multiple"
                    disableSearch
                    disableGroupPanel
                    disablePaging
                    data={staffData}
                    gridheight={219}
                    columns={staffList}
                  />
                </div>
              </Form.Item>
            </div>
          </Form>
        </section>

        <section className=" " style={{ width: "calc(100% - 880px)" }}>
          <Form
            style={{ borderColor: borderTheme }}
            className="w-full border-[1px] rounded pb-1 border-slate-100"
            name="basic"
            wrapperCol={{ span: 25 }}
          >
            <p
              id={"form_header3"}
              style={{ borderColor: borderTheme }}
              className="px-2 w-full border-b-[1px] bg-slate-100"
            >
              <span className="font-medium">{"Current Group Members"}</span>
            </p>
            <div className=" px-1">
              {/* DATAGRID [] */}
              <Form.Item
                className=""
                name="Staff No"
                rules={[{ required: false }]}
              >
                <div className="flex flex-col justify-between">
                  <div className=" mt-0.5 w-full ">
                    <div className="flex flex-row justify-between items-center h-fit w-full">
                      <span
                        id="jbhdbvchfbvhfbvh"
                        onClick={() =>
                          removeAccessPriv()
                        }
                        style={{ borderRadius: "1px" }}
                        className="rounded border-2 w-5 p-0.5 flex items-center justify-center  hover:cursor-pointer  h-full"
                      >
                        <img
                          className=" w-11/12 h-11/12"
                          src={cancel}
                          alt="cancel"
                        />
                        <Tooltip
                          showEvent="dxhoverstart"
                          hideEvent="dxhoverend"
                          hideOnOutsideClick={true}
                          target={"#jbhdbvchfbvhfbvh"}
                        >
                          Remove selected record(s) from list
                        </Tooltip>
                      </span>

                      <div
                        style={{ width: "calc(100% - 16px)" }}
                        className="flex flex-row justify-end items-center"
                      >
                        <span className=" flex flex-row mr-6">
                          <p className=" text-gray-400 text-xs">
                            Total: {membersData?.length}
                          </p>
                        </span>

                        <div className=" w-[130px]">
                          <InputsTemplate
                            span
                            useCallbackFunc
                            orderOnchange={(value) => {
                              updateState("membersSearchTextTemp", value);
                            }}
                            disabledStatus={false}
                            label="Find"
                          />
                        </div>

                        <span
                          style={{ borderColor: borderTheme }}
                          className=" h-full  flex items-center justify-center px-1 py-0.5 hover:cursor-pointer hover:bg-slate-50 ml-1 rounded border-2"
                          onClick={() => {
                            updateState(
                              "membersSearchText",
                              membersSearchTextTemp
                            );
                          }}
                        >
                          <FaBinoculars color="#007AFF" />
                        </span>
                      </div>
                    </div>
                  </div>

                  <Datagrid_template
                    onRowPrepared={implementRowColouring}
                    selectionMode="multiple"
                    rowAlternationEnabled={false}
                    selectedItemsChange={(selectedList) =>
                      updateState("selectedCurrentMembers", selectedList)
                    }
                    disableGroupPanel
                    disablePaging
                    disableSearch
                    data={membersData}
                    gridheight={328}
                    columns={CurrentMembers}
                  />
                </div>
              </Form.Item>
            </div>
          </Form>
        </section>
      </Form>
    </div>
  );
};
