/* eslint-disable react/jsx-pascal-case */
import { useEffect, useRef, useState } from "react";
// import type { SelectProps } from 'antd';
import log from "../../../../../../assets/log.png";
import { Fuel_backlogtoolbar } from "../../../../../accessories/custom-toolbars/fuel_backlog";
import { Fuel_Transaction_Details_Form } from "./widgets/Transaction_details";
import { TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/accordion";

import {
  api_url,
  navbar_height,
} from "../../../../../accessories/component_infos";
import { Fuel_Management_Attachments } from "./widgets/Right pane widgets/attachments";
import { Fuel_Management_Comment } from "./widgets/Right pane widgets/comments";
import { Fuel_Management_Share } from "./widgets/Right pane widgets/share";
import { DatePicker, Select } from "antd";
import {
  footer_datagrid_SPTL_cols,
  footer_datagrid_SPTL_colsB,
} from "./data/datagrid_cols";
import { Footer_Management_Comment } from "./widgets/footerComment";
import { Footer_datagrid_template } from "./data/footer_datagrid";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";

interface props {
  state_change: boolean;
}

export const Fuel_Transactions = ({ state_change }: props) => {
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const heights_out = navbar_height + news_flash_height;
  const [formheight, set_form_height] = useState<number>(0);
  const formRef = useRef<any>(null);

  const grid_footer_div_height = window.innerHeight - (heights_out + 50);

  const dateFormat = "ddd, DD MMM YYYY HH:mm";
  const [DBdata, setDBdata] = useState<any>([]);
  const [refresh, setRefresh] = useState<boolean>(false);

  const DB_Fetch = async () => {
    const res = await axios.get(
      `${api_url}/api/AsmTmsEntFuelManagement/GetEntAsmTsmFuelManagemwntAll`,
      {
        headers: {
          "Content-Type": ["application/json", "text/plain", "charset= uft-8"],
        },
      }
    );

    setDBdata(res.data);
  };

  useEffect(() => {
    DB_Fetch();
    set_form_height(formRef.current?.clientHeight + 43);
  }, [refresh]);

  const gridHeight = window.innerHeight - (heights_out + formheight + 35);

  return (
    <div className="h-full dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base ">
      {/* toggles between the taskForm and the footer grid table vertically */}

      <div>
        <Fuel_backlogtoolbar />
      </div>

      <div className="flex h-full ">
        <div style={{ width: "calc(100% - 15%)" }}>
          <div ref={formRef} className="">
            <p
              id={"form_header"}
              className="pt-1 mx-2 pl-4 w-full bg-slate-100 rounded-tl"
            >
              Fuel Record Details
            </p>
            <div
              style={{ height: "345px" }}
              className="w-full px-2 flex justify-between gap-x-4 border-b-4 mb-1 pb-1"
            >
              {/* Internal toggler : expands the right taskUpdate horizontally */}

              <div style={{ width: "100%", height: "340px" }} className="mb-1">
                <Fuel_Transaction_Details_Form />
              </div>
            </div>
          </div>

          {/* Footer gridtable  */}
          <div
            style={{ height: "" }}
            className="px-2 w-full flex justify-between  dark:bg-slate-900 dark:text-darkModeSkin-base"
          >
            <div style={{ width: "49%" }} className="">
              {/* Header */}
              <p className="pt-1 mx-2 pl-4 w-full bg-slate-100 rounded-tl">
                Service Provider Transaction Logs
              </p>
              {/* Body */}
              <div className="flex justify-evenly mt-1 ">
                <Select style={{}} placeholder="" className="mr-1">
                  {[]?.map((dpt: any, index: number) => {
                    return (
                      <Select.Option key={index} value={dpt.dptIdpk}>
                        {dpt.dptShtName}
                      </Select.Option>
                    );
                  })}
                </Select>

                <Select style={{}} placeholder="" className="mr-1">
                  {[]?.map((sxn: any, index: number) => {
                    return (
                      <Select.Option key={index} value={sxn.sxnIdpk}>
                        {sxn.sxnShtName}
                      </Select.Option>
                    );
                  })}
                </Select>

                <DatePicker
                  disabled={false}
                  onChange={() => {}}
                  className=""
                  format={dateFormat}
                />

                <DatePicker
                  disabled={false}
                  onChange={() => {}}
                  className="ml-0.5"
                  format={dateFormat}
                />
              </div>
              <div className="flex justify-end">
                <DatePicker
                  disabled={false}
                  onChange={() => {}}
                  style={{ width: "29.5%" }}
                  className=""
                  format={dateFormat}
                />

                <DatePicker
                  disabled={false}
                  onChange={() => {}}
                  style={{ width: "29.5%" }}
                  className="ml-0.5 "
                  format={dateFormat}
                />
              </div>
              {/* Grids */}
              <div className="flex justify-between">
                <div style={{ width: "49%" }} className="">
                  <Footer_datagrid_template
                    gridheight={
                      window.innerHeight - (heights_out + formheight + 100)
                    }
                    columns={footer_datagrid_SPTL_cols}
                    data={DBdata.data}
                  />
                </div>

                <div style={{ width: "49%" }} className="">
                  <TabPanel
                    style={{
                      height:
                        window.innerHeight - (heights_out + formheight + 110),
                    }}
                    className="w-full "
                  >
                    <Item title={"Comments"}>
                      <Footer_Management_Comment />
                    </Item>

                    <Item title={"Attachments"}>
                      <Fuel_Management_Attachments />
                    </Item>
                  </TabPanel>
                </div>
              </div>
            </div>

            <div style={{ width: "49%" }} className="">
              {/* Header */}

              <div className="flex justify-between  ">
                <div style={{ width: "70%" }} className="">
                  <p className="pt-1 mx-2 pl-4 w-full bg-slate-100 rounded-tl">
                    Manual Fuel Purchase Logs
                  </p>
                </div>

                <div style={{ width: "28%" }} className=" flex ">
                  <button
                    style={{ height: "" }}
                    className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  w-full mb-0.5 ml-0.5   px-3 py-1 border-2 border-slate-200 rounded"
                  >
                    Show Chart
                  </button>

                  <button
                    style={{ height: "" }}
                    className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  w-full mb-0.5 ml-0.5   px-3 py-1 border-2 border-slate-200 rounded"
                  >
                    <img alt="log-icon" src={log} className="" />
                  </button>
                </div>
              </div>

              {/* Grids */}
              <div className="flex justify-between">
                <div style={{ width: "49%" }} className="">
                  <Footer_datagrid_template
                    gridheight={gridHeight}
                    columns={footer_datagrid_SPTL_colsB}
                    data={DBdata.data}
                  />
                </div>

                <div style={{ width: "49%" }} className="">
                  <TabPanel
                    style={{
                      height:
                        window.innerHeight - (heights_out + formheight + 45),
                    }}
                    className="w-full "
                  >
                    <Item title={"Comments"}>
                      <Footer_Management_Comment />
                    </Item>

                    <Item title={"Attachments"}>
                      <Fuel_Management_Attachments />
                    </Item>
                  </TabPanel>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ width: "calc(100% - 85%)" }} className="h-full">
          {/* Right side */}
          <div>
            <div style={{ height: grid_footer_div_height }} className="w-full">
              <TabPanel
                style={{ height: grid_footer_div_height }}
                className="w-full "
              >
                <Item title={"Share"}>
                  <Fuel_Management_Share />
                </Item>

                <Item title={"Comments"}>
                  <Fuel_Management_Comment />
                </Item>

                <Item title={"Attachments"}>
                  <Fuel_Management_Attachments />
                </Item>
              </TabPanel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// const reload = ()=>{
//     return(
//         <img className='w-2/6' src={save} />
//     )
// }
