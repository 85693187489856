import { Item } from "devextreme-react/accordion";
import { useEffect, useRef, useState } from 'react';
import { TaskDetails } from './widgets/taskDetails';
import { TaskUpdate } from './widgets/taskUpdate';
import { TaskOtherUpdate } from './widgets/taskOtherUpdate';
// import type { SelectProps } from 'antd';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { TabPanel } from 'devextreme-react';
import { navbar_height } from '../accessories/component_infos';
import CustomLoader from "../accessories/custom-loader";
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import statusLoader from '../../assets/statusloader.gif';
import { Right_Share } from './widgets/rightSide_pane/share';
import { Right_Comment } from './widgets/rightSide_pane/comments';
import { Right_Attachments } from './widgets/rightSide_pane/attachments';
import FilterTodos from "./FooterGrid/FilterTodos";
import dayjs from "dayjs";
import useFetch from "../../hooks/useFetch";

interface props {
    state_change: boolean;
    trigger : boolean; // trigger page height responsiveness
    // pageHeight: any; //page height
    // fullPage ?: any; // full page
    // formRef ?: any; //form reference
}



export const Tasks = ({state_change, trigger}:props)=>{

   
      //form height
    // const [form_height,set_form_height] = useState<number>(0);
    // const pass_form_height=(height:number)=>{
    //     set_form_height(height)
    // }

    const taskDetailsHeight:any = 309
    const news_flash_height = useSelector((state:RootState)=>state.general.newsflashHeight);

    const [tableHeight,settableHeight] = useState(window.innerHeight); //height without navbar and toolbar

     // All heights out
     const heights_out = navbar_height + news_flash_height ;

     
     const [switch_change, setSwitch_change] = useState<boolean>(true)
     
     
     // const fullPage = window.innerHeight - (navbar_height + 45) ; //height without navbar
   
   
     


    // const width = window.innerWidth/2
    // const fixedWidth = 'calc(50% - 8px)';
    // const datagridheight = "100%"

    const [tasks_update_state, setTask_update_state] = useState<Boolean>(true)
    const [tasks_update_col_width, setTask_update_col_width] = useState<number>(100)
    const [task_datagrid_width,set_task_datagrid_width] = useState(`calc(100% - ${'600px'})`);
    const [task_update_other_state,setTask_update_other_state] = useState<Boolean>(true);



    const navigate = useNavigate();
    const [searchParams,setSearchParams] = useSearchParams();

    const current_page_route = '/tasks-todos';
    const taskUpdateForms = 'tasks-forms'
    const taskUpdateTable = 'update-tableAlt';
    const taskUpdateFormExtended = 'tasks-todos-extended'
    const taskUpdateTableExtended = 'update-tableAlt-Extended';
    const view = searchParams.get('view');


// switch button toggler
    const switch_toggle = ()=>{
        if(tasks_update_state ){
            // extended display
            setTask_update_state(!tasks_update_state);
            setTask_update_col_width(200)
            set_task_datagrid_width('100%')
            
        }else {
            // mini dispay
            setTask_update_state(!tasks_update_state);
            setTask_update_col_width(100)

            
        }
        
    }


// Double tap /click gesture
    const doubleClick_toggle = ()=>{
        

        if(task_update_other_state == true ){
            // update forms view
            set_task_datagrid_width('calc(100% + 600px')
            setTask_update_other_state(!task_update_other_state)
            navigate({
                pathname: current_page_route,
                search: createSearchParams({
                    view : taskUpdateForms,
                }).toString(),
            })
        }else{
            // update table view
            set_task_datagrid_width('calc(100% + 600px')
            navigate({
                pathname: current_page_route,
                search: createSearchParams({
                    view : taskUpdateTable,
                }).toString(),
            })
            setTask_update_other_state(!task_update_other_state)
        }
        
        
    }
    
    
    const grid_footer_div_height = window.innerHeight - (heights_out + 85 )

    // Data from redux
    const form_disable = useSelector((state:RootState) => state.addTask.taskFormStatus)
    const update_state_change = useSelector((state:RootState)=>state.addTask.taskFooterUpdate)
     // Data from redux
  const customtoolbar_task_toggle_state = useSelector((state:RootState) => state.checkbox.task_toggle_state)
  const customtoolbar_active_status = useSelector((state:RootState)=> state.checkbox.active_status)
  const customtoolbarStartDate = useSelector((state:RootState)=> state.checkbox.startDate)
  const defaultToolbarStartDate = useSelector((state:RootState)=> state.checkbox.default_startDate)
  const defaultToolbarEndDate = useSelector((state:RootState)=> state.checkbox.default_endDate)
  const customtoolbarEndDate = useSelector((state:RootState)=> state.checkbox.endDate)
  
  
    const startDate_con = `${dayjs(defaultToolbarStartDate).format()}`
    const endDate_con = `${dayjs(defaultToolbarEndDate).format()}`
  
  
      const Personal_task = useFetch(`api/TaskDetail/GetPersonalTasks/${1}`)
      const All_tasks = useFetch(`api/TaskDetail/GetAllTasks?employeeId=${1}&startdate=${startDate_con}&enddate=${endDate_con}`);
      const deleted_task = useFetch("api/TaskDetail/GetStatus?isActive=false")
      // const searchByCriteria =useFetch(`/api/TaskDetail/GetGenTasksByCriteria?employeeId=${1}&criteriaIndex=0&startdate=1981-07-17T04:11:32.816Z&enddate=1981-07-17T04:11:32.816Z&active=true&completed=true&query=`)
     
      const [DBdata,error,loading] = (customtoolbar_task_toggle_state == "By me") ? Personal_task : (customtoolbar_task_toggle_state == "All tasks") ? All_tasks : (customtoolbar_active_status === false) ? deleted_task : deleted_task

      const [formheight, set_form_height] = useState<number>(0);
      const formRef = useRef<any>(null);
      const [fullPage, setfullPage] = useState(window.innerHeight - (navbar_height + news_flash_height + 150 ) );
      const pageHeight = window.innerHeight - (navbar_height + news_flash_height + formheight + 80 );

      useEffect(()=>{
        set_form_height((formRef.current?.clientHeight)+ 80)
        console.log(formRef)
        
      },[])

    return (
        <>
        {(!DBdata ) && 
            <div style={{height: window.innerHeight  }} className="w-full flex justify-center items-center"><CustomLoader /></div>
        }

        {(DBdata) &&(
            <div style={{height:''}}  className="flex w-full h-full ">
            <div  style={{width:'calc(100% - 15%)',}} className='h-full  dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base '>
            {/* toggles between the taskForm and the footer grid table vertically */}
            {state_change && (
                <div ref={formRef}  className="px-2 flex justify-between gap-x-4 border-b-4 mb-1 pb-1">
            
                {/* Internal toggler : expands the right taskUpdate horizontally */}
                {tasks_update_state && (
                    <div style={{height:'295px'}}  >
                        <TaskDetails />
                    </div>
                    )}
            
                {/* Double tap(click) feature to toggle to and fro of the right taskUpdate section */}
            <div onDoubleClick={doubleClick_toggle} style={{width: task_datagrid_width, minWidth:'600px',}}  className="h-full  rounded-md items-center dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base " >
                {task_update_other_state && view== null || view === taskUpdateTable ? 
                <TaskUpdate toggler={switch_toggle} datgridCol = {tasks_update_col_width}/>
                :
                <TaskOtherUpdate toggler={switch_toggle} backToggler = {doubleClick_toggle} />
                }
            </div>

                    
        </div>
            )}

            {/* Footer gridtable  */}
            <div style={{height: ''}}   className='  dark:bg-slate-900 dark:text-darkModeSkin-base '>
                
                  <div style={{height:''}} className=' '>
                    <FilterTodos
                        data={DBdata}
                        gridheight={state_change ? pageHeight : fullPage }
                     />
                  </div>

                 


                
                {/* Footer progress */}

            <div  className='flex w-full  justify-evenly '>

                <div>
                     {/* done state */}
                 {form_disable && <p className=" italic font-semibold text-gray-600">Done.</p>}

                {/* new record or editing state */}
                {!form_disable && <div className="gif  flex flex-row items-center">
                    <img style={{width:'70px', height:'50px'}} src= {statusLoader} alt="gif" />
                    {!update_state_change ?
                    <p className=" italic text-gray-600">New Record</p>
                    :
                    <p className=" italic text-gray-600">Editing</p>}
                </div>}
                </div>
                
                {/* First */}
                <div className='w-1/5 justify-center '>
                    <div className="flex flex-col justify-center"  id="avGAllTasks">
                        <div className="w-full h-5  bg-gray-300 rounded-lg ">
                            <div className="relative h-full flex  ">
                                <div className=" absolute h-full   bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-lg " style={{width: (value.toString() + '%')}} ></div>
                                <div className="relative text-xs text-white  w-full text-center">{value}%</div>

                            </div>
                            <p>Average percentage of all tasks (Assigned By and To me)</p>
                    
                        </div>
                    </div>
                </div>

                {/* second */}
                <div className='w-1/5'>
                    <div className="flex flex-col"  id="avGAllTasks">
                        <div className="w-full h-5  bg-gray-300 rounded-lg ">
                            <div className="relative h-full flex ">
                                <div className=" absolute h-full  bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-lg " style={{width: (value.toString() + '%')}} ></div>
                                <div className="relative text-xs text-white  w-full text-center">{value}%</div>

                            </div>
                            <p>Average percentage of all tasks Assigned By me</p>
                    
                        </div>
                    </div>
                </div>

                {/* third */}
                <div className='w-1/5'>
                    <div className="flex flex-col"  id="avGAllTasks">
                        <div className="w-full h-5  bg-gray-300 rounded-lg ">
                            <div className="relative h-full flex ">
                                <div className=" absolute h-full  bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-lg " style={{width: (value.toString() + '%')}} ></div>
                                <div className="relative text-xs text-white  w-full text-center">{value}%</div>

                            </div>

                            <p>Average percentage of all tasks Assigned To me</p>
                    
                        </div>
                    </div>
                </div>

                {/* fourth */}
                <div className='w-1/5'>
                    <div className="flex flex-col"  id="avGAllTasks">
                        <div className="w-full h-5  bg-gray-300 rounded-lg ">
                            <div className="relative h-full flex ">
                                <div className=" absolute h-full  bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-lg " style={{width: (value.toString() + '%')}} ></div>
                                <div className="relative text-xs text-white  w-full text-center jus">{value}%</div>

                            </div>

                            <p>Average Rating of tasks assigned to me </p>
                    
                        </div>
                    </div>
                </div>

             </div>

            </div>

            
        </div>

        <style
            dangerouslySetInnerHTML={{
                __html: `

                .dx-tab {
                    background-color: #e5dfdf52;
                    border-bottom: none;
                    border-left: 1px solid #ccc;
                    border-right: 1px solid #ccc;
                    width: 80
                }

                .dx-tabpanel > .dx-tabpanel-tabs .dx-tab {
                    width: 100px;
                    
                }

                
                `
            }}
  />

                    {/* Right side */}
        <div  style={{width:'calc(100% - 85%)',}} className="h-full">

           <div style={{height:grid_footer_div_height}} className="w-full">
            <TabPanel 
            style={{height:grid_footer_div_height}}
            className="w-full "

            >
            <Item   title={"Share"}>
                <Right_Share />
                
                
            </Item>

            

            <Item title={"Comments"}>
                <Right_Comment />
            </Item>

            <Item title={"Attachments"}>
            <Right_Attachments />
            </Item>

            
            </TabPanel>
        </div>
        </div>
        
       

    </div>
        )}
    </>
    )
}


const value = 50





 

// const reload = ()=>{
//     return(
//         <img className='w-2/6' src={save} />
//     )
// }