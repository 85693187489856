
import { forwardRef, useState } from 'react';
// import type { SelectProps } from 'antd';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CIA_toolbar } from '../../../accessories/custom-toolbars/cia_toolbar';
import { PPE_distribution_toolbar } from '../../../accessories/custom-toolbars/ppeDistributionToolbar';
import { MeetingScheduleData } from '../../../Safety/Entries/data/datagrid_rows_data';
// import { Meeting_Schedules_Details_TextArea } from '../../../Safety/Entries/Meetings/data/Meeting-schedules_inputs';
import { cooperate_initiatve_footer_datagrid_cols, footer_datagrid_cia_cols } from '../../data/datagrid_cols';
import { CIA_TextArea } from '../../data/Performance_inputs';
import { Footer_datagrid_template } from '../../data/template/footer_datagrid';
import { Theme_Details_Form } from './widgets/Strategic_theme_details';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';





interface props {
    state_change: boolean;
}



export const Theme = forwardRef(({ state_change }: props, ref: any)=>{
    // const width = window.innerWidth/2
    // const fixedWidth = 'calc(50% - 8px)';
    const pageHeight = window.innerHeight - 233;
    const borderTheme = useSelector((state:RootState)=>state.general.cssBorderClass)


    return (
        <div  className='w-full h-full dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base mt-1'>
            {/* toggles between the taskForm and the footer grid table vertically */}
                    
                
                
                <div   className='w-full'>
                    <p id={"form_header"} style={{borderColor:borderTheme}} className='pt-1 border-[1px] border-b-0 mx-2 pl-4 w-full bg-slate-100 rounded-tl'>Initiative Details</p>
                    <div   className="w-full px-2 flex justify-between gap-x-4 border-b-4 mb-1 pb-1">
                        
                        {/* Internal toggler : expands the right taskUpdate horizontally */}
                        
                            <div  className='w-full mb-1  ' >
                                <Theme_Details_Form ref={ref} toggleRefresh={()=>{}}  />
                            </div>
                            
                            
                        </div>
                </div>
               
        

            {/* Footer gridtable  */}
            {/* <div style={{height:''}} className='px-2  dark:bg-slate-900 dark:text-darkModeSkin-base'>
                <Footer_datagrid_template gridheight={705} columns={cooperate_initiatve_footer_datagrid_cols} data={''} />
            </div> */}

        </div>

    )
})





// const reload = ()=>{
//     return(
//         <img className='w-2/6' src={save} />
//     )
// }