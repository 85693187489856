/* eslint-disable react/jsx-pascal-case */
import { useEffect, useImperativeHandle, useState } from "react";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import useFetch from "../../../../../../hooks/useFetch";
import {
  navbar_height,
  toolbar_height,
} from "../../../../../accessories/component_infos";
import { HrSetupsCustomToolbar } from "../../../../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import { assets_productPriceColumns } from "./data/productPricesData";
import { ProductPrices_form } from "./widgets/productPricesForm";
import Datagrid_template from "../../../../../../templates/Datagrid";
import { useDispatch, useSelector } from "react-redux";
import { setGeneralValue } from "../../../../../../features/generalSlice";
import React from "react";
import { RootState } from "../../../../../../app/store";
import { useBusyLoader } from "../../../../../../hooks/useBusyLoader";
import { WarningAlert } from "../../../../../accessories/warningAlert";
import {
  searchTextTypes,
  statusTypes,
  searchCriteriaTypes,
} from "../../../../../human-resource/setups/data/types/selectsTypes";

export const Assets_Setups_ProductPrices = React.forwardRef(
  ({ a }: any, ref) => {
    const news_flash_height = useSelector(
      (state: RootState) => state.general.newsflashHeight
    );
    useEffect(() => {
      //disable Update Mode
      dispatch(setGeneralValue({ expr: "updateMode", value: false }));

      //disable forms
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    }, []);

    const [searchText, setSearchText] = useState<searchTextTypes>({
      temp: "",
      text: "",
    });
    const [activeStatus, setActiveStatus] = useState<statusTypes>({
      temp: "",
      status: "",
    });
    const [searchCriteria, setsearhCriteria] = useState<searchCriteriaTypes>({
      temp: 0,
      index: 0,
    });
    const [refreshToolbar, setrefreshToolbar] = useState<boolean>(false);

    const searchCriteriaData = [
      { id: 0, value: "Service Provider" },
      { id: 1, value: "Service Station" },
      { id: 2, value: "Product" },
    ];

    const [refresh, setrefresh] = useState<boolean>(false);

    const [data, error, loading] = useFetch(
      `AssetMgrProductPriceDetails/GetAllProductPriceDetails?searchText=${searchText.text}&active=${activeStatus.status}&searchCriteriaIndex=${searchCriteria.index}`,
      refresh,
      "pprIdpk"
    );
    useEffect(() => {
      loading ? setBusyLoader(".") : setBusyLoader("");
    }, [loading]);

    // on page resize height responsiveness
    const [trigger, setTrigger] = useState<boolean>(false);
    useImperativeHandle(ref, () => ({
      test: () => {
        setTrigger(!trigger);
      },
    }));

    //navigation
    const setupPageRoute = window.location.pathname; //get current route of page
    const datagridOnlySearchParam = "table-only"; //search param in datagrid mode
    const formModeSearchParam = "form-and-table"; //search param in form mode mode
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    //miscellaneous heights
    const miscHeight = 7;

    // All heights out
    const heights_out = navbar_height + news_flash_height + toolbar_height;

    //varying form height
    const [form_height, set_form_height] = useState<number>(245);

    // Page resize height responsiveness
    useEffect(() => {
      settableHeight(window.innerHeight - (heights_out + miscHeight - 4));
    }, []);

    // handle interface switch
    const toggle_staff_form = (): void => {
      const view = searchParams.get("view");

      view === null || view === formModeSearchParam
        ? navigate({
            pathname: setupPageRoute,
            search: createSearchParams({
              view: datagridOnlySearchParam,
            }).toString(),
          })
        : navigate({
            pathname: setupPageRoute,
            search: createSearchParams({
              view: formModeSearchParam,
            }).toString(),
          });
    };

    //Table Height
    const pageHeight = window.innerHeight - (navbar_height + miscHeight); //height without navbar
    const [tableHeight, settableHeight] = useState(window.innerHeight); //height without navbar and toolbar
    const [busyLoader, setBusyLoader] = useBusyLoader();
    const dispatch = useDispatch();

    const [selectedData, setSelectedData] = useState({});

    return (
      <div style={{ height: pageHeight }} className="w-full">
        <>
          {error && <WarningAlert />}

          <div className="px-2">
            <HrSetupsCustomToolbar
              searchCriteriaData={searchCriteriaData}
              searchTextOnchange={(searchValue) => {
                setSearchText({ temp: searchValue, text: searchText.text });
              }}
              checkOnChange={(check) => {
                setActiveStatus({ temp: check, status: activeStatus.status });
              }}
              handleRefresh={() => {
                setrefreshToolbar((current) => !current);
              }}
              handleFind={() => {
                setSearchText({ temp: searchText.temp, text: searchText.temp }); //search text
                setActiveStatus({
                  temp: activeStatus.temp,
                  status: activeStatus.temp,
                }); //active status
                setsearhCriteria({
                  temp: searchCriteria.temp,
                  index: searchCriteria.temp,
                });
              }}
              searchCriteriaOnchange={(newIndex, criteria) => {
                setsearhCriteria({
                  temp: newIndex,
                  index: searchCriteria.index,
                });
              }}
              searchCriteria={true}
              outerSelected={selectedData}
              withDates={false}
              toggler={toggle_staff_form}
            />
          </div>

          {/* form section */}
          {searchParams.get("view") !== datagridOnlySearchParam && (
            <section
              style={{ height: form_height }}
              className="form border-b-4"
            >
              <ProductPrices_form
                clearSelected={() => setSelectedData({})}
                selectedField={selectedData}
                refreshGrid={() => setrefresh((current) => !current)}
                busyloader={(text) => setBusyLoader(text)}
              />
            </section>
          )}

          {/* datagrid section */}
          <section className="mt-1 px-2">
            <Datagrid_template
              onRowClick={(selected) => {
                setSelectedData(selected);
                //disable Update Mode
                dispatch(setGeneralValue({ expr: "updateMode", value: false }));

                //disable forms
                dispatch(
                  setGeneralValue({ expr: "formDisabled", value: true })
                );
              }}
              rowDoubleClicked={() => {
                //disable Update Mode
                dispatch(setGeneralValue({ expr: "updateMode", value: true }));

                //disable forms
                dispatch(
                  setGeneralValue({ expr: "formDisabled", value: false })
                );
              }}
              gridheight={
                searchParams.get("view") === formModeSearchParam ||
                searchParams.get("view") === null
                  ? window.innerHeight -
                    (form_height + heights_out + miscHeight)
                  : tableHeight
              }
              columns={assets_productPriceColumns}
              data={data}
            />
          </section>
        </>
      </div>
    );
  }
);
