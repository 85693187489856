/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useEffect, useRef, useState } from "react";
import Datagrid_template from "../../../templates/Datagrid";
import { HrSetupsCustomToolbar } from "../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import useResizeMode from "../../../hooks/useResizeMode";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import {
  inputsTypes,
  searchCriteriaTypes,
  searchTextTypes,
  statusTypes,
} from "../../human-resource/setups/data/types/selectsTypes";
import { Form } from "antd";
import { TextareaTemplate } from "../../../templates/textarea";
import { ApplicationForm_details } from "./widgets/applicationForm_details";
import { appForms_cols } from "./data/datagridCols";
import { navbar_height } from "../../accessories/component_infos";
import useFetch from "../../../hooks/useFetch";
import { setGeneralValue } from "../../../features/generalSlice";
import { GetAllApplicationForm } from "../../../interfaces";

type state_types = {
    module: {id: number, name: string};
    formType: {id: number, name: string};
    formName: {id: number, name: string};
    shtName: {id: number, name: string};
    formCode: {id: number, name: string};
    slfServiceForm: boolean;
    description: string;
    remarks: string;
    visible: boolean;
    active: boolean;
}

interface stateTypes {
  criteria: searchCriteriaTypes;
  searchText: searchTextTypes;
  afmActive: statusTypes;
  selectedRecord: GetAllApplicationForm;
  description: string;
  remarks: string;
}

export const ApplicationForm = () => {
//   const formRef = useRef<any>(null);

  // Redux
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );

  const [switch_change, setSwitch_change] = useState<boolean>(true);
  // switch toggle
  const switch_toggle = () => {
    if (switch_change) {
      setSwitch_change(!switch_change);
    } else {
      setSwitch_change(!switch_change);
    }
  };

  const [refreshstate, setRefreshstate] = useState<boolean>(false);

  const [currentStates, setCurrentStates] = useState<state_types>({
    module: { id: 0, name: "" },
    formType: { id: 0, name: "" },
    formName: { id: 0, name: "" },
    shtName: { id: 0, name: "" },
    formCode: { id: 0, name: "" },
    slfServiceForm: false,
    description: "",
    remarks: "",
    visible: false,
    active: true,
  });

  const [searchStates, setSearchStates] = useState<stateTypes>({
    criteria: { temp: 0, index: 0 },
    searchText: { temp: "", text: "" },
    afmActive: { temp: "", status: "" },
    selectedRecord: {} as GetAllApplicationForm,
    description: "",
    remarks: "",
  });

  const [selectedRecord, setSelectedRecord] = useState<GetAllApplicationForm>();
  const dispatch = useDispatch();

  const { criteria, searchText, afmActive, remarks, description } = searchStates;
  const childRef = useRef<any>();

  const updateState = (key: string, value: any) => {
    setCurrentStates((prev) => ({ ...prev, [key]: value }));
  };

  const updateSearchState = (key: string, value: any) => {
    setSearchStates((prev) => ({ ...prev, [key]: value }));
  };

  const [appData]:[GetAllApplicationForm, any, boolean] = useFetch(
    `ApplicationForm/GetAllApplicationForm?afmActive=${afmActive.status}&searchText=${searchText.text}&criteria=${criteria.index}`
  );

  const [formheight, set_form_height] = useState<number>(0);

  const [initHeight, FullHeight] = useResizeMode(switch_change);

  var fullPage = FullHeight - (navbar_height + news_flash_height + 30);

  var pageHeight =
    initHeight - (navbar_height + news_flash_height + formheight + 25);

  const textAreas: inputsTypes[] = [
    {
      id: 0,
      label: "Description",
      stateName: "description",
      defaultValue: description,
      style: "",
    },
    {
      id: 1,
      label: "Remarks",
      stateName: "remarks",
      defaultValue: remarks,
      style: "",
    },
  ];

  const searchCriteria: { id: number; value: any }[] = [
    { id: 0, value: "Form" },
    { id: 1, value: "Module" },
    { id: 2, value: "Form Type" },
  ];

  useEffect(() => {
    set_form_height(childRef.current?.clientHeight + 25);
  }, [switch_change]);

  const populateFields = useCallback(()=>{
    const gridState = [
      {key:"description", value: selectedRecord?.afmDesc as any ?? ""},
      {key:"remarks", value: selectedRecord?.afmRmks ?? ""},
      
    ]

    for(let i of gridState) updateSearchState(i.key, i.value)

  },[selectedRecord])

  useEffect(()=>{
    populateFields()
  },[selectedRecord])

  return (
    <div className="h-full dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base">
      {/* toggles between the taskForm and the footer grid table vertically */}

      <div className="px-2">
        <HrSetupsCustomToolbar
         setCurrentStates={() => {
            setCurrentStates({
                module: { id: 0, name: "" },
                formType: { id: 0, name: "" },
                formName: { id: 0, name: "" },
                shtName: { id: 0, name: "" },
                formCode: { id: 0, name: "" },
                slfServiceForm: false,
                description: "",
                remarks: "",
                visible: false,
                active: true,
            });
          }}
          withDates={false}
          searchTextOnchange={(searchValue: any) => {
            updateSearchState("searchText", {
              ...searchStates.searchText,
              temp: searchValue,
            });
          }}
          checkOnChange={(check: any) => {
            updateSearchState("afmActive", { ...searchStates.afmActive, temp: check });
          }}
          handleFind={() => {
            updateSearchState("searchText", { ...searchText, text: searchText.temp });
            updateSearchState("criteria", { ...criteria, index: criteria.temp });
            updateSearchState("afmActive", { ...afmActive, status: afmActive.temp });
          }}
          toggler={switch_toggle}
          outerSelected={selectedRecord}
          useCallBackFunc
          searchCriteria
          searchCriteriaData={searchCriteria}
          searchCriteriaOnchange={(e) => {
            updateSearchState("criteria", { ...searchStates.criteria, temp: e });
          }}
          handleRefresh={() => {
            updateSearchState("searchText", { temp: "", text: "" });
            updateSearchState("criteria", { temp: 0, index: 0 });
            updateSearchState("afmActive", { temp: "", status: "" });
          }}
          handleNew={() => {
            if (childRef.current && childRef.current.singleClick) {
              childRef.current.singleClick({});
            }
            setSelectedRecord({} as GetAllApplicationForm);
            dispatch(setGeneralValue({ expr: "updateMode", value: false }));
            dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
          }}
        />
      </div>

      {switch_change && (
        <div ref={childRef} className="px-2">
          <p
            id={"form_header"}
            style={{ borderColor: borderTheme }}
            className="py-0.5 items-center border-[1px] border-b-0 pl-4 w-full bg-slate-100 rounded-t font-medium"
          >
            Form Details
          </p>
          <div
            style={{ height: "205px", borderColor: borderTheme }}
            className="w-full border-[1px]  px-2 flex justify-between gap-x-4 rounded-b mb-1 pb-1"
          >
            {/* Internal toggler : expands the right taskUpdate horizontally */}

            <div style={{ width: "420px", height: "200px" }} className="mb-1">
              <ApplicationForm_details
                ref={childRef}
                refreshState={() => setRefreshstate(!refreshstate)}
                currentStates={currentStates}
                setCurrentStates={setCurrentStates}
              />
            </div>

            <div
              style={{ width: "10%" }}
              className=" w-full  mt-0.5 rounded-md items-center dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base "
            >
              <Form>
                {textAreas.map(
                  ({ id, label, stateName, defaultValue, style }) => {
                    let textAreaValue = defaultValue as string | undefined;

                    return (
                      <React.Fragment key={id}>
                        <TextareaTemplate
                          labelCol={4}
                          useCallbackFunc
                          setCallbackValue={(value: any) => {
                            updateState(stateName!, value);
                          }}
                          height={98}
                          defaultValue={textAreaValue!}
                          label={label}
                        />
                      </React.Fragment>
                    );
                  }
                )}
              </Form>
            </div>
          </div>
        </div>
      )}

      {/* Footer gridtable  */}
      <div
        style={{ height: "" }}
        className="px-2  dark:bg-slate-900 dark:text-darkModeSkin-base"
      >
        <Datagrid_template
          gridheight={switch_change ? pageHeight : fullPage}
          columns={appForms_cols}
          data={appData}
          onRowClick={(e: any) => {
            console.log("Slected", e)
            if (childRef.current) {
              childRef.current.singleClick(e);
            }
            setSelectedRecord(e);
          }}
          rowDoubleClicked={(e) => {
            if (childRef.current) {
              childRef.current.doubleClicked(e);
            }
            setSelectedRecord(e);
            
          }}
        />
      </div>
    </div>
  );
};

export default ApplicationForm;
