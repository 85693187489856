import { Checkbox, DatePicker, Form, Input, message, Select } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import axios from "axios";
import { title } from "process";
import { async } from "q";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import { modalPropTypes, selectsTypes } from "../../../../../../human-resource/setups/data/types/selectsTypes";
import { ModalTemplate } from "../../../../../../../templates/modal";
import SetupsDatalistTemplat from "../../../../../../human-resource/setups/data/setups_datalist_template";
import { InputsTemplate } from "../../../../../../../templates/input";
import { CheckboxTemlate } from "../../../../../../../templates/checkbox";
import { TextareaTemplate } from "../../../../../../../templates/textarea";
import { BottomBtns } from "../../../../../../../templates/butttons";
import { saveModalData } from "../../../../../../accessories/component_infos";
import { useCrudFunc } from "../../../../../../../functions/crud";

interface props {
  selectedItemData: any;
  refreshGrid: () => void;
  resetData: () => void;
  setBusyLoader: (text: string) => void;
}
export const EventTypes_form = ({ selectedItemData, refreshGrid ,setBusyLoader,
  resetData}: props) => {
  const dispatch = useDispatch()


  const borderTheme = useSelector((state:RootState)=>state.general.cssBorderClass)


  //--validation modal --
  const [validationModalState, setvalidationModalState] = useState<any>({
    state: false,
    title: '',
    message: '',
  });
  const setValidationModal = (state: string, value: string | boolean) => {
    setvalidationModalState((warningState: any) => ({ ...warningState, [state]: value }));
  }

  //--save modal--
  const [saveModalState, setsaveModalState] = useState<any>({
    state: false,
    title: '',
    message: '',
    okText: ''
  });

  //--success modal--
  const [succesModalState, setsuccesModalState] = useState<modalPropTypes>({
    state: false,
    title: '',
    message: '',
    okText: ''
  });
  const setModal = (state: string, value: string | boolean) => {
    setsaveModalState((modalState: any) => ({ ...modalState, [state]: value }))
  }

  // retrieving input fields data
  //datalist user inputs
  let [datalistData, setDatalistData]: any = useState({
    datalist0: "",
    datalist1: "",
  });

  //checkbox user inputs
  const [checkboxData, setCheckboxData]: any = useState({
    checkbox0: false,
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
    checkbox4: false,
    checkbox5: false,
    checkbox6: false,
    checkbox7: false,
  });

  //Order user inputs
  const [orderData, setOrderData] = useState<string | number>(0);

  //textarea user inputs
  const [textareaValue, setTextareaValue] = useState<string>("");

  //set checkbox value
  const setCheckboxes = (value: boolean, index: number) => {
    //set checkboxes data
    setCheckboxData((prevState: any) => ({
      ...prevState,
      [`checkbox${index}`]: value, datalist1: value
    }));
  };

  const datalists: selectsTypes[] = [
    { id: 0, label: "Name", optionsData: [{}], defaultValue: datalistData.datalist0 },
    { id: 1, label: "Short Name", optionsData: [{}], defaultValue: datalistData.datalist1 },
  ];

  useEffect(() => {
    populateFromGrid(false);
    setTextareaValue(selectedItemData?.rmmRmks)
  }, [selectedItemData])

  const populateFromGrid = (clearFields: boolean) => {
    // populate datalists
    const datalistSelected = [
      { datalistIndex: 0, value: selectedItemData?.evtName ?? '' },
      { datalistIndex: 1, value: selectedItemData?.evtShtName ?? '' },
    ]
    for (let index = 0; index < datalistSelected.length; index++) {
      setDatalists(clearFields ? '' : datalistSelected[index].value, datalistSelected[index].datalistIndex)
    }

    //populate checkboxes
    const checkBoxListSelected = [
      { checkbox: 0, value: selectedItemData?.rmmActive ?? false },
      { checkbox: 1, value: selectedItemData?.rmmPickFrequencyFromModule ?? false },
      { checkbox: 2, value: selectedItemData?.rmmHelpDesk },
      { checkbox: 3, value: selectedItemData?.rmmSingleAssignmentPerPeriod ?? false },
      { checkbox: 4, value: selectedItemData?.rmmTaskManager ?? false },
      { checkbox: 5, value: selectedItemData?.rmmSafetyManagerMeetingAttendance ?? false },
      { checkbox: 6, value: selectedItemData?.rmmHrLeaveManagement ?? false },
      { checkbox: 7, value: selectedItemData?.rmmHospManagerMedicalExams ?? false },
    ]

    for (let index = 0; index < checkBoxListSelected.length; index++) {
      setCheckboxes(clearFields === true && index == 0 ? true : clearFields ? false : checkBoxListSelected[index].value, checkBoxListSelected[index].checkbox)
    }

    clearFields ? setOrderData(0) : setOrderData(selectedItemData?.rmmOrder ?? 0)
  }

  //set datalist value
  const setDatalists = (value: string | number, index: number) => {
    //set datalists value
    setDatalistData((prevState: any) => ({
      ...prevState,
      [`datalist${index}`]: value,
    }));

    index === 0 && setDatalistData((prevState: any) => ({
      ...prevState,
      [`datalist1`]: value,
    }));
  };

  const setOrder = (value: string | number) => {
    //set order value
    setOrderData(value);
  };

  const setTextarea = (value: string) => {
    //set order value
    setTextareaValue(value);
  };
  const [refresh, setRefresh] = useState<boolean>(false);

  //validate Data
  const ValidatePostData = (update) => {
    console.log(datalistData)
    if (datalistData["datalist0"] === '') {
      setvalidationModalState({
        state: true,
        title: 'Empty Field',
        message: 'Please enter field data'
      })
    }
    else if (datalistData["datalist0"]?.length < 2) {
      setvalidationModalState({
        state: true,
        title: 'Event Type Name can not be less than 2 characters.',
        message: 'Please enter field data'
      })
    }
    else if (datalistData["datalist1"] === '') {
      setvalidationModalState(
        {
          state: true,
          title: 'Short Name can not be Empty',
          message: 'Please enter field data'
        }
      )
    }
    else if (datalistData["datalist1"].length < 2) {
      setvalidationModalState({
        state: true,
        title: 'Event Type Short Name can not be less than 2 characters.',
        message: 'Please enter field data'
      })
    }
    // else if (checkboxData["checkbox0"] === false) {
    //   setvalidationModalState({
    //     state: true,
    //     title: 'Details will be invisible',
    //     message: 'Active checkbox Is not checked'
    //   })
    // }
    else {
      if(!update){
      setsaveModalState({
        state: true,
        title: 'Save Data?',
        message: 'Are you sure you want to save this record?',
        okText: 'Yes, Save'
      })
    }else{
      setsaveModalState({
        state: true,
        title: 'Update Data?',
        message: 'Are you sure you want to update this record?',
        okText: 'Yes, Update'
      })
    }
    }
  }

  const [posting , updating]=useCrudFunc("powerapp");

    //create
    const postData = async () => {
      //post request here
      setModal("state", false);
      // refreshGrid();
      // setBusyLoader(saveModalData.progress);
      try {
        await posting (`EventTypeContoller/Create`, {
          "evtName": datalistData["datalist0"],
          "evtShtName": datalistData["datalist1"],
          "evtMidnightCondition": checkboxData.checkbox1,
          "evtShiftChange": checkboxData.checkbox0,
          "evtShiftTakeOver": checkboxData.checkbox2,
          "evtShowInLogBook": checkboxData.checkbox3,
          "evtOrder": orderData,
          "evtActive": checkboxData.checkbox4,
          "evtRmks": textareaValue,
          "formCode": "string",
          "formAction": "string"
        },`Created Event Type`);
        refreshGrid();
        // setRefresh(!refresh);
        setsuccesModalState({
          state: true,
          message: "Record saved successfully",
          title: "Save Successful",
        });
        // setBusyLoader("");
        // populateFromGrid(false);
      } catch (error: any) {
        setvalidationModalState({
          state: true,
          title: saveModalData.title,
          message: saveModalData.error.message,
        });
      } finally {
        setBusyLoader("");
      }
    };

    const updateData = async () => {
        //post request here
        setModal("state", false);
        // refreshGrid();
        // setBusyLoader(saveModalData.progress);
        try {
          await updating (`EventTypeContoller/Update`, {
            "evtIDpk": selectedItemData.evtIDpk,
            "evtName": datalistData["datalist0"],
            "evtShtName": datalistData["datalist1"],
            "evtMidnightCondition": checkboxData.checkbox1,
            "evtShiftChange": checkboxData.checkbox0,
            "evtShiftTakeOver": checkboxData.checkbox2,
            "evtShowInLogBook": checkboxData.checkbox3,
            "evtOrder": orderData,
            "evtActive": checkboxData.checkbox4,
            "evtRmks": textareaValue,
          
          },`Updated Event Type`);
          refreshGrid();
          // setRefresh(!refresh);
          setsuccesModalState({
            state: true,
            message: "Record updated successfully",
            title: "Update Successful",
          });
          // setBusyLoader("");
          // populateFromGrid(false);
        } catch (error: any) {
          setvalidationModalState({
            state: true,
            title: saveModalData.title,
            message: saveModalData.error.message,
          });
        } finally {
          setBusyLoader("");
        }
    };
  return (
    <div className="px-2">  
    <ModalTemplate
        icon={"question"}
        disableCancel={false}
        cancelText={"Cancel"}
        open={saveModalState.state}
        okHandler={
          saveModalState.title === "Save Data?" ? postData : updateData
        }
        cancelHandler={() => setModal("state", false)}
        message={saveModalState.message}
        okText={saveModalState.okText}
        title={saveModalState.title}
      />

      {/* success modal */}
      <ModalTemplate
        icon={"success"}
        disableCancel={true}
        cancelText={"Cancel"}
        open={succesModalState.state}
        okHandler={() => {
          setsuccesModalState((prev: any) => ({ ...prev, state: false }));
        }}
        cancelHandler={() =>
          setsuccesModalState((prev: any) => ({ ...prev, state: false }))
        }
        message={succesModalState.message}
        okText={succesModalState.okText}
        title={succesModalState.title}
      />

      {/* validation */}
      <ModalTemplate
        disableCancel={true}
        open={validationModalState.state}
        okHandler={() => setValidationModal("state", false)}
        cancelHandler={() => setValidationModal("state", false)}
        message={validationModalState.message}
        okText={"Ok"}
        title={validationModalState.title}
      />  
      {/* FORM HEADER */}
      <p id={"form_header"} className="pt-1 px-2 font-semibold w-full bg-slate-100">{"Event Type Details"}</p>
      <Form
        className="w-full border-2 border-slate-100 rounded flex justify-between py-1 px-2 "
        name="basic"
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 19 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        size="small"
      >
        <section className="" style={{ width: "500px" }}>
          {/* datalists */}
          {datalists.map(
            ({ id, optionsData, label, defaultValue }, index: number) => {
              return (
                <div key={index} className=" mb-1">
                <SetupsDatalistTemplat
                  selectedData={defaultValue}
                  index={index}
                  datalistStyle={{ height: 24 }}
                  setCallbackValue={setDatalists}
                  useCallbackFunc
                  label={label}
                  options={optionsData}
                />
              </div>
              );
            }
          )}

          <div className=" mb-1">
            <InputsTemplate
              span
              inputType='number'
              inputStyle={{ height: 24 }}
              useCallbackFunc
              defaultValue={orderData}
              orderOnchange={(value) => { setOrder(value) }}
              label={"Order"}
            />
          </div>

          {/* checkboxes */}
          <div className=" mb-1">
            <CheckboxTemlate
              withBorders
              inlineDefaultValue={[checkboxData.checkbox0, checkboxData.checkbox1]}
              useCallbackFunc
              index={[0, 1]}
              setCheckboxOnchange={setCheckboxes}
              label={"Shift Change?"}
              label2={"Midnight Conditions?"}
            />
          </div>

          <div className=" mb-1">
            <CheckboxTemlate
              withBorders
              inlineDefaultValue={[checkboxData.checkbox2, checkboxData.checkbox3]}
              useCallbackFunc
              index={[2, 3]}
              setCheckboxOnchange={setCheckboxes}
              label={"Shift Change Takeover?"}
              label2={"Show in log book?"}
            />
          </div>

          {/* butons */}
         {/* checkbox and butons */}
         <div className=" ml-[-11px] mr-[-28px] mt-0.5">
            <BottomBtns
              span={false}
              addActiveBorders={true}
              label="Active?"
              // additionalBottomChckbox="External"
              onCancelClick={() => {}}
              saveHandler={() =>{
                ValidatePostData(false)
              }}
              updateHandler={() =>{
            ValidatePostData(true)
              }}
              useCallbackFunc={true}
            />
          </div>
        </section>

        {/* remarks */}
        <section className=" " style={{ width: "calc(100% - 500px)" }}>
          <TextareaTemplate
            defaultValue={textareaValue}
            useCallbackFunc
            setCallbackValue={setTextarea}
            height={175}
            MarginLeft={40}
            label={"Remarks"}
          />
        </section>
      </Form>
    </div>
  );
};

