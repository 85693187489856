export enum InputTypes {
    "Text",
    "Number",
    "object",
    "Date",
    "boolean"
  }
  
  interface FieldsType {
    message: string;
    title: string;
    fieldName?: string;
    type: InputTypes;
  }
  // Vehicle class -> vehClass vehicle make ->
  export const Fields: FieldsType[] = [
    {
      message: "Please Enter The Registration Number",
      title: "Registration Number",
      fieldName: "regNo",
      type: InputTypes.Text,
    },
  
    {
      message: "Please Enter The Chassis Number",
      title: "Chassis Number",
      fieldName: "chassisNo",
      type: InputTypes.Text,
    },
    {
      message: "Please Enter The Max Speedo ",
      title: "Max Speedo",
      fieldName: "maxSpeed",
      type: InputTypes.Number,
    },
    {
      message: "Please Select The Speed Unit",
      title: "Speed Unit",
      fieldName: "speedUnit",
      type: InputTypes.object,
    },
    {
      message: "Please Select The Vehicle class",
      title: "Vehicle class",
      fieldName: "vehClass",
      type: InputTypes.object,
    },
    {
      message: "Please Select The Vehicle Make",
      title: "Vehicle Make",
      fieldName: "vehMake",
      type: InputTypes.object,
    },
    {
      message: "Please Select The Vehicle Model",
      title: "Vehicle Model",
      fieldName: "vehModel",
      type: InputTypes.object,
    },
    {
      message: "Please Select The Manufacturer",
      title: "Manufacturer",
      fieldName: "manufacturer",
      type: InputTypes.object,
    },
    {
      message: "Please Select The Country",
      title: "Country",
      fieldName: "country",
      type: InputTypes.object,
    },
    {
      message: "Please Select The Supplier",
      title: "Supplier",
      fieldName: "supplier",
      type: InputTypes.object,
    },
    {
      message: "Please Select The Engine Type",
      title: "Engine Type",
      fieldName: "engineType",
      type: InputTypes.object,
    },
    {
      message: "Please Select The Fuel Type",
      title: "Fuel Type",
      fieldName: "fuelType",
      type: InputTypes.object,
    },
    {
      message: "Please Select The Engine Make",
      title: "Engine Make",
      fieldName: "engineMake",
      type: InputTypes.object,
    },
    {
      message: "Please Select The Power Train",
      title: "Power Train",
      fieldName: "powerTrain",
      type: InputTypes.object,
    },
    {
      message: "Please Select The Transmission Type",
      title: "Transmission Type",
      fieldName: "transType",
      type: InputTypes.object,
    },
    {
      message: "Please Enter The Cubic Capacity",
      title: "Cubic Capacity",
      fieldName: "cubicCapacity",
      type: InputTypes.Number,
    },
    {
      message: "Please Select The Colour",
      title: "Colour",
      fieldName: "colour",
      type: InputTypes.Text,
    },
    {
      message: "Please Select The Status",
      title: "Status",
      fieldName: "status",
      type: InputTypes.object,
    },
    {
      message: "Please Enter The Seat Capacity",
      title: "Seat Capacity",
      fieldName: "seatingCap",
      type: InputTypes.Number,
    },
    {
      message: "Please Enter The Length",
      title: "Length",
      fieldName: "len",
      type: InputTypes.Number,
    },
    {
      message: "Please Enter The Width",
      title: "Width",
      fieldName: "width",
      type: InputTypes.Number,
    },
    {
      message: "Please Enter The Height",
      title: "Height",
      fieldName: "height",
      type: InputTypes.Number,
    },
    {
      message: "Please Enter The Number Of Axles",
      title: "Number of Axles",
      fieldName: "noOfAxles",
      type: InputTypes.Number,
    },
    {
      message: "Please Enter The Number Of Wheels",
      title: "Number of wheel",
      fieldName: "noOfWheels",
      type: InputTypes.Number,
    },
    {
      message: "Please Enter The Engine Number",
      title: "Engine Number",
      fieldName: "engineNo",
      type: InputTypes.Text,
    },
    {
      message: "Please Enter The Number Of Cylinder",
      title: "Number of Cylinder",
      fieldName: "noCylinder",
      type: InputTypes.Number,
    },
    {
      message: "Please Enter The Number Of Horse Power",
      title: "Number of  Horse Power",
      fieldName: "horsePower",
      type: InputTypes.Text,
    },
    {
      message: "Please Enter The Tank Capacity",
      title: "Tank Capacity ",
      fieldName: "tankCap",
      type: InputTypes.Number,
    },
    {
      message: "Please Enter The Front Tyre",
      title: "Front Tyre",
      fieldName: "tyreSizeF",
      type: InputTypes.Text,
    },
    {
      message: "Please Enter The Middle Tyre",
      title: "Middle Tyre",
      fieldName: "tyreSizeM",
      type: InputTypes.Text,
    },
    {
      message: "Please Enter The Rear Tyre",
      title: "Rear Tyre",
      fieldName: "tyreSizeR",
      type: InputTypes.Text,
    },
    {
      message: "Please Enter The Net Weight",
      title: "Net Weight",
      fieldName: "netWeight",
      type: InputTypes.Number,
    },
    {
      message: "Please Enter The Gross Weight",
      title: "Gross Weight",
      fieldName: "grossWeight",
      type: InputTypes.Number,
    },
    {
      message: "Please Enter The Price",
      title: "Price",
      fieldName: "price",
      type: InputTypes.Number,
    },
    {
      message: "Please Enter The Currency",
      title: "Currency",
      fieldName: "currency",
      type: InputTypes.object,
    },
    {
      message: "Please Enter Other Expenses",
      title: "Other Expenses",
      fieldName: "other",
      type: InputTypes.Text,
    },
    {
      message: "Please Enter The Remarks",
      title: "Remarks",
      fieldName: "remarks",
      type: InputTypes.Text,
    },
    {
      message: "Please Select The Manufacturing Date",
      title: "Manu. Date",
      fieldName: "manuDate",
      type: InputTypes.Date,
    },
    {
      message: "Please Enter The Supply Date",
      title: "Supply Date",
      fieldName: "supplyDate",
      type: InputTypes.Date,
    },
    {
      message: "Please Enter The Registration Date",
      title: "Reg. Date",
      fieldName: "regDate",
      type: InputTypes.Date,
    },
    // {
    //   message: "",
    //   title: "",
    //   fieldName: "general",
    //   type: InputTypes.boolean,
    // },
    // {
    //   message: "",
    //   title: "",
    //   fieldName: "bus",
    //   type: InputTypes.boolean,
    // },
    // {
    //   message: "",
    //   title: "",
    //   fieldName: "specialised",
    //   type: InputTypes.boolean,
    // },
    // {
    //   message: "",
    //   title: "",
    //   fieldName: "motorBike",
    //   type: InputTypes.boolean,
    // },
    // {
    //   message: "",
    //   title: "",
    //   fieldName: "equipment",
    //   type: InputTypes.boolean,
    // },
    // {
    //   message: "",
    //   title: "",
    //   fieldName: "lightDuty",
    //   type: InputTypes.boolean,
    // },
    // {
    //   message: "",
    //   title: "",
    //   fieldName: "vehicle",
    //   type: InputTypes.boolean,
    // },
  ];
  
  
  