import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { modalTypes } from '../../components/human-resource/setups/data/types/selectsTypes'

interface ModalStoreState {
    // internal parameter
    setModal:modalTypes
  
    // methods
    warn:(title:string,message:string,width?:string|number)=> void
    success:(title:string,message:string,width?:string|number)=> void
    error:(title:string,message:string,width?:string|number)=> void
    confirm:(title:string,message:string,okHandler:()=> void,okText?:string,width?:string|number)=> void
    disableModal:()=>void
}

// initial modal values
const initialState:modalTypes={
    open:false,
    title:'',
    message:'',
    okHandler:()=>{
    },
    disableCancel:true,
    icon : 'warning',
    icon_width: undefined,
    width : undefined,
    okText : '',
    cancelText : 'No, Cancel',
    cancelHandler :()=>{
        useModalStore.getState().disableModal();
    }
}

const useModalStore = create<ModalStoreState>()(
  devtools(
    // persist(
      (set:any) => ({
        //---parameters---
        //modal props
        setModal:initialState,

        //---methods---
        //warning modal
        warn:(title:string,message:string,width?:string|number)=>{
            set({setModal:{
                ...useModalStore.getState().setModal,
                open:true,
                title:title,
                message:message,
                okHandler:()=>{
                    useModalStore.getState().disableModal();
                },
                disableCancel:true,
                icon : 'warning',
                width : width,
                okText : 'Ok',
            }})
        },

        //success modal
        success:(title:string,message:string,width?:string|number)=>{
            set({setModal:{
                ...useModalStore.getState().setModal,
                open:true,
                title:title,
                message:message,
                okHandler:()=>{
                    useModalStore.getState().disableModal();
                },
                disableCancel:true,
                icon : 'success',
                width : width,
                okText : 'Ok',
            }})
        },

        //error modal
        error:(title:string,message:string,width?:string|number)=>{
            set({setModal:{
                ...useModalStore.getState().setModal,
                open:true,
                title:title,
                message:message,
                okHandler:()=>{
                    useModalStore.getState().disableModal();
                },
                disableCancel:true,
                icon : 'warning',
                width : width,
                okText : 'Ok',
            }})
        },

        //confirmation modal
        confirm:(title:string,message:string,okHandler:()=> void,okText?:string,width?:string|number)=>{
            set({setModal:{
                ...useModalStore.getState().setModal,
                open:true,
                title:title,
                message:message,
                okHandler: okHandler,
                disableCancel:false,
                icon : 'question',
                width : width,
                okText : okText ?? ('Yes'),
            }})
        },

        //disable modal
        disableModal:()=>{
            set({setModal:initialState});
        }
      }),
      {
        name: 'modal-storage',
      }
    )
//   )
)

export default useModalStore;