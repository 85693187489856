export const hr_setups_region_columns = [
    {id : 0, caption : 'ID',dataField : 'rgnIdpk',datatype : 'number', width : 80, fixed : true, visible : false},
    {id : 1, caption : 'REGION', dataField : 'rgnName',datatype : 'string', width : 160, fixed : true , visible : true},
    {id : 2, caption : 'SHORT NAME', dataField : 'rgnShtName',datatype : 'string', width : 160, fixed : true , visible : true},
    {id : 3, caption : 'COUNTRY', dataField : 'ctyName',datatype : 'string', width : 160, fixed : true , visible : true},
    {id : 4, caption : 'REGION CODE', dataField : 'rgnCode',datatype : 'string', width : 160, fixed : true , visible : true},
    {id : 5, caption : 'CAPITAL', dataField : 'ctyCapital',datatype : 'string', width : 160, fixed : true , visible : true},
    {id : 6, caption : 'ORDER', dataField : 'rgnOrder',datatype : 'string', width : 130, fixed : true , visible : true},
    {id : 7, caption : 'ACTIVE', dataField : 'rgnActive',datatype : 'boolean', width : 90, fixed : true , visible : true},
    {id : 8, caption : 'REMARKS',dataField : 'rgnRemarks',datatype : 'string', width : 5000, fixed : false , visible : true}
]