export const inventory_checklist = [
  {
    id: 1,
    caption: "No",
    dataField: "mtiIDpk",
    dataType: "",
    width: 60,
    fixed: true,
    allowEditing: false,
  },

  {
    id: 2,
    caption: "Inventory Item",
    dataField: "mtiName",
    dataType: "datetime",
    width: 150,
    fixed: true,
    allowEditing: false,
  },
  {
    id: 3,
    caption: "Before",
    dataField: "pidBefore",
    dataType: "boolean",
    width: 80,
    fixed: true,
    allowEditing: true,
  },
  {
    id: 4,
    caption: "After",
    dataField: "pidAfter",
    dataType: "boolean",
    width: 80,
    fixed: true,
    allowEditing: true,
  },
  {
    id: 5,
    caption: "Remarks",
    dataField: "pidRmks",
    dataType: "string",
    width: 300,
    fixed: true,
    allowEditing: true,
  },
  {
    id: 6,
    caption: "Created By",
    dataField: "createdByEmpname",
    dataType: "string",
    width: 150,
    fixed: true,
    allowEditing: true,
  },
  {
    id: 7,
    caption: "Created On",
    dataField: "pidCreationDate",
    dataType: "datetime",
    width: 150,
    fixed: true,
    allowEditing: false,
  },

 
];

export const maintenance_checklist = [
  {
    id: 0,
    caption: "No",
    dataField: "mtiIDpk",
    dataType: "",
    width: 60,
    fixed: true,
  },

  {
    id: 1,
    caption: "Maintenance Checklist",
    dataField: "mtiName",
    dataType: "string",
    width: 150,
    fixed: true,
  },

  {
    id: 2,
    caption: "Faulty?",
    dataField: "padFaulty",
    dataType: "boolean",
    width: 60,
    fixed: true,
  },
  {
    id: 3,
    caption: "Replaced?",
    dataField: "padReplaced",
    dataType: "boolean",
    width: 60,
    fixed: true,
  },
  {
    id: 4,
    caption: "Remarks",
    dataField: "pidRmks",
    dataType: "string",
    width: 300,
    fixed: true,
    allowEditing: true,
  },
  {
    id: 5,
    caption: "Created By",
    dataField: "createdByEmpName",
    dataType: "string",
    width: 150,
    fixed: true,
  },
  {
    id: 6,
    caption: "Created On",
    dataField: "padCreationDate",
    dataType: "datetime",
    width: 150,
    fixed: true,
  },
];

export const progress_updatechecklist = [
  {
    id: 1,
    caption: "DATE",
    dataField: "pauDate",
    dataType: "datetime",
    width: 150,
    fixed: true,
  },

  {
    id: 2,
    caption: "TASK UPDATE",
    dataField: "pauActivityUpdate",
    dataType: "string",
    width: 150,
    fixed: true,
  },
  {
    id: 3,
    caption: "% COMPLETE",
    dataField: "pauPercentComplete",
    dataType: "",
    width: 150,
    fixed: true,
  },
  {
    id: 4,
    caption: "UPDATED BY",
    dataField: "updatedByEmpName",
    dataType: "string",
    width: 150,
    fixed: true,
  },
  {
    id: 5,
    caption: "STAFF No",
    dataField: "updatedByStaffNo",
    dataType: "string",
    width: 150,
    fixed: true,
  },
  {
    id: 6,
    caption: "JOB TITLE",
    dataField: "updatedByJbtShtName",
    dataType: "string",
    width: 150,
    fixed: true,
  },
  {
    id: 7,
    caption: "Created On",
    dataField: "pauCreationDate",
    dataType: "datetime",
    width: 150,
    fixed: true,
  },
];

export const pm_schedules_columns = [
  {
    id: 0,
    caption: "REG. No",
    dataField: "vdtRegNo",
    dataType: "string",
    fixed: true,
    width: 150,
  },
  {
    id: 1,
    caption: "PM DUE DATE",
    dataField: "vdtNextPmDate",
    fixed: true,
    dataType: "datatime",
    width: 150,
  },
  {
    id: 2,
    caption: "SCHED. DATE",
    dataField: "vdtScheduledPmDate",
    dataType: "datetime",
    fixed: false,
    width: 150,
  },
  {
    id: 3,
    caption: "SCHED. WKSHP",
    dataField: "pmWorkshopName",
    dataType: "datetime",
    fixed: false,
    width: 150,
  },
  {
    id: 4,
    caption: "VEH. CLASS ",
    dataField: "vhcName",
    dataType: "string",
    width: 150,
  },
  {
    id: 5,
    caption: "VEH. MAKE",
    dataField: "vmkName",
    dataType: "string",
    width: 150,
  },
  {
    id: 6,
    caption: "VEH. MODEL",
    dataField: "mdlName",
    dataType: "string",
    width: 150,
  },

  {
    id: 7,
    caption: "ACTIVE",
    dataField: "COMPLETION DATE",
    dataType: "string",
    width: 80,
  },
  {
    id: 8,
    caption: "REMARKS",
    dataField: "mrqRmks",
    dataType: "string",
    width: 4000,
  },
];
export const pm_veh_Ass_columns = [
  {
    id: 0,
    caption: "REG. No",
    dataField: "vdtRegNo",
    dataType: "string",
    fixed: true,
    width: 150,
  },
  {
    id: 1,
    caption: "PM DUE DATE",
    dataField: "vdtNextPmDate",
    fixed: true,
    dataType: "datatime",
    width: 150,
  },
  {
    id: 2,
    caption: "SCHED. DATE",
    dataField: "vdtScheduledPmDate",
    dataType: "datetime",
    fixed: false,
    width: 150,
  },
  {
    id: 3,
    caption: "SCHED. WKSHP",
    dataField: "pmWorkshopName",
    dataType: "datetime",
    fixed: false,
    width: 150,
  },
  {
    id: 4,
    caption: "DRIVER",
    dataField: "driverEmpName",
    dataType: "string",
    fixed: false,
    width: 150,
  },
  {
    id: 5,
    caption: "APPROV OFFICER",
    dataField: "ownerEmpName",
    dataType: "string",
    fixed: false,
    width: 150,
  },

  {
    id: 6,
    caption: "VEH. CLASS ",
    dataField: "vhcName",
    dataType: "string",
    width: 150,
  },
  {
    id: 7,
    caption: "VEH. MAKE",
    dataField: "vmkName",
    dataType: "string",
    width: 150,
  },
  {
    id: 8,
    caption: "VEH. MODEL",
    dataField: "mdlName",
    dataType: "string",
    width: 150,
  },

  {
    id: 9,
    caption: "ACTIVE",
    dataField: "COMPLETION DATE",
    dataType: "string",
    width: 80,
  },
  {
    id: 10,
    caption: "REMARKS",
    dataField: "mrqRmks",
    dataType: "string",
    width: 4000,
  },
];
export const pm_veh_details_columns = [
  {
    id: 0,
    caption: "REG. No",
    dataField: "vdtRegNo",
    dataType: "string",
    fixed: true,
    width: 150,
  },
  {
    id: 1,
    caption: "PM DUE DATE",
    dataField: "vdtNextPmDate",
    fixed: true,
    dataType: "datatime",
    width: 150,
  },
  {
    id: 2,
    caption: "SCHED. DATE",
    dataField: "vdtScheduledPmDate",
    dataType: "datetime",
    fixed: false,
    width: 150,
  },
  {
    id: 3,
    caption: "SCHED. WKSHP",
    dataField: "pmWorkshopName",
    dataType: "datetime",
    fixed: false,
    width: 150,
  },
  {
    id: 4,
    caption: "VEH. CLASS ",
    dataField: "vhcName",
    dataType: "string",
    width: 150,
  },
  {
    id: 5,
    caption: "VEH. MAKE",
    dataField: "vmkName",
    dataType: "string",
    width: 150,
  },
  {
    id: 6,
    caption: "VEH. MODEL",
    dataField: "mdlName",
    dataType: "string",
    width: 150,
  },

  {
    id: 7,
    caption: "ACTIVE",
    dataField: "COMPLETION DATE",
    dataType: "string",
    width: 80,
  },
  {
    id: 8,
    caption: "REMARKS",
    dataField: "mrqRmks",
    dataType: "string",
    width: 4000,
  },
];
