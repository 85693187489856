import { useEffect, useState } from "react";
import { Form, message, Modal } from "antd";
import { SelectsTemplate } from "../../../../templates/select";
import {
  ApproveButton,
  CustomizableButton,
  SaveButton,
} from "../../../accessories/buttons";
import { TextareaTemplate } from "../../../../templates/textarea";
import close from "../../../../assets/close.png";
import reset from "../../../../assets/reset.png";
import check from "../../../../assets/check-mark.png";
import { api_url, useModalData } from "../../../accessories/component_infos";
import { z, ZodError } from "zod";
import { ModalTemplate } from "../../../../templates/modal";
import { useCrudFunc } from "../../../../functions/crud";
import axios, { AxiosResponse } from "axios";
import { CheckboxTemlate } from "../../../../templates/checkbox";
import {
  useAxiosService,
  requestConfig,
} from "../../../../general/requestConfig";
import { userDataCrypt } from "../../../../functions/userDataEncrypt";

interface props {
  open: boolean;
  handleCancel: () => void;
}
type Station = {
  stnIDpk: number;
  stnName: string;
  stnShtName: string;
  stnCode: string;
  stnContact: string;
  stnTelNo: string;
  stnPlc: string;
};
type Employee = {
  empIDpk: number;
  empStaffNo: string;
  empName1: string;
  dptShtName: string;
  sxnShtName: string;
  jbtShtName: string;
  locShtName: string;
};

// const axiosInstance = axios.create({
//   baseURL: `${api_url}/`,
//   headers: {
//     "Content-Type": ["application/json", "text/plain", "charset=utf-8"],
//     "ngrok-skip-browser-warning": "true",
//     // Authorization: `Bearer ${parsedItem?.token}`,
//   },
// });
// type t_axiosConfig = {
//   method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
//   url: string;
//   data?: any;
//   params?: any;
// };
// const axioService = async (config: t_axiosConfig) => {
//   return await axiosInstance({
//     method: config.method,
//     url: config.url,
//     data: config.data,
//     params: config.params,
//   });
// };
// export const axiosGo = async (config: t_axiosConfig) => {
//   const response = await axioService(config);
//   return response?.data;
// };

const ErrorBoundary = (error: string[]) => {
  return (
    <ul className="flex flex-col space-y-2 p-2 rounded-lg list-outside list-disc">
      {error.map((err, index) => {
        return (
          <p
            key={index}
            className="text-red-500 px-3 py-1 rounded-lg bg-stone-50"
          >
            {err}
          </p>
        );
      })}
    </ul>
  );
};
const zodSchema = z.object({
  action: z
    .string({ required_error: "Action is empty but is required" })
    .min(1, { message: "Action is required" }),
  homeStation: z
    .string({ required_error: "Home Station is empty but is required" })
    .min(1, { message: "Home Station is required" }),
  initiatingOp: z
    .string({ required_error: "Initiating Operator is empty but is required" })
    .min(1, { message: "Initiating Operator is required" }),
  remoteOp: z
    .string({ required_error: "Remote operator is empty but is required" })
    .min(1, { message: "Remote Operator is empty but is required" }),
  summary: z.string().optional(),
});
const Remote_Shift_Change = ({ open, handleCancel }: props) => {
  const axiosInstance = useAxiosService();

  const [disabled, setDisabled] = useState(false);
  const [modalData, setModalData] = useModalData();
  const [Formvalues, setFormvalues] = useState<{
    action: { id: string; value: string } | undefined;
    homeStation: Station;
    initiatingOp: Employee;
    remoteOp: Employee;
    summary?: string;
  }>();
  const [asyncPost, asyncUpdate, asyncPatch] = useCrudFunc();
  const [HomeStation, setHomeStation] = useState<Station[] | undefined>();
  const [ReportingShift, setReportingShift] = useState<
    Employee[] | undefined
  >();
  const [InitiatingOperator, setInitiatingOperator] = useState<
    Employee[] | undefined
  >();
  const [Summary, setSummary] = useState<
    Array<{ rscRmks: string }> | undefined
  >();

  useEffect(() => {
    axiosInstance({
      method: "GET",
      url: "RemoteShiftChange/get_reporting_stations",
    })
      .then((res) => setHomeStation(res))
      .catch((err) => console.log(err));

    axiosInstance({
      method: "GET",
      url: "RemoteShiftChange/get_remote_operators",
      params: {
        action: Formvalues?.action?.id,
        stnIDpk: Formvalues?.homeStation?.stnIDpk,
      },
    })
      .then((res) => setReportingShift(res))
      .catch((err) => console.log(err));

    axiosInstance({
      method: "GET",
      url: "RemoteShiftChange/get_initiating_operators",
      params: {
        action: Formvalues?.action?.id,
        stnIDpk: Formvalues?.homeStation?.stnIDpk,
        remoteOperatorIDfk: Formvalues?.remoteOp?.empIDpk,
      },
    })
      .then((res) => setInitiatingOperator(res))
      .catch((err) => console.log(err));
    axiosInstance({
      method: "GET",
      url: "RemoteShiftChange/get_shift_change_summary",
      params: {
        initiatingOperatorID: Formvalues?.initiatingOp?.empIDpk ?? 995,
        remoteOperatorID: Formvalues?.remoteOp?.empIDpk ?? 977,
      },
    })
      .then((res) => setSummary(res))
      .catch((err) => console.log(err));
  }, [Formvalues]);
  const InitiateAsSave = async () => {
    let errors: string[];
    try {
      const values = {
        action: Formvalues?.action.id,
        homeStation: Formvalues?.homeStation?.stnName,
        initiatingOp: Formvalues?.initiatingOp?.empName1,
        remoteOp: Formvalues?.remoteOp?.empName1,
        summary: Formvalues?.summary,
      };
      const result = zodSchema.parse(values);
      if (!Object.values(result).some((val) => val === "")) {
        setModalData((current) => ({
          ...current,
          state: true,
          icon: "question",
          title: `Initiate?`,
          cancelText: "No Cancel",
          okText: "Ok",
          message: "Are you sure you want to initiate?",
          okHandler: async () => {
            try {
              const res = axiosInstance({
                method: "PUT",
                url: "RemoteShiftChange/initiate",
                data: result,
              });
              if (res) {
                message.success("Initiated successfully", 500);
                setModalData((current) => ({
                  ...current,
                  state: true,
                  icon: "success",
                  title: `Initiate Successfully?`,
                  cancelText: "No Cancel",
                  okText: "Ok",
                  okHandler: () => {
                    setModalData((current) => ({
                      ...current,
                      state: false,
                    }));
                  },
                  message: "Operation completed successfully",
                  disableCancel: true,
                  cancelHandler() {
                    setModalData((current) => ({
                      ...current,
                      state: false,
                    }));
                  },
                }));
              }
            } catch (err) {
              message.error("Error initiating");
              setModalData((current) => ({
                ...current,
                state: true,
                icon: "warning",
                title: `Operation failed`,
                cancelText: "No Cancel",
                okText: "Ok",
                message: "Operation failed, please try again",
                disableCancel: true,
                okHandler: () => {
                  setModalData((current) => ({
                    ...current,
                    state: false,
                  }));
                },
                cancelHandler() {
                  setModalData((current) => ({
                    ...current,
                    state: false,
                  }));
                },
              }));
            }
          },
          cancelHandler: () => {
            setModalData((current) => ({
              ...current,
              state: false,
            }));
          },
          disableCancel: false, // This should be part of the object
        }));
        return;
      }
    } catch (error: any) {
      if (error instanceof ZodError) {
        errors = error.errors.map((err) => {
          return err.message;
        });
        setModalData((current) => ({
          ...current,
          state: true,
          icon: "warning",
          title: `${errors.length} Error(s) Found during submission`,
          cancelText: "No Cancel",
          okText: "Ok",
          message: ErrorBoundary(errors),
          okHandler: () => {
            setModalData((current) => ({
              ...current,
              state: false,
            }));
          },
          disableCancel: true,
        }));
      }
    }
  };

  const refineData = (data: any): any[][] => {
    const refined = [[]];
    let count = 0;
    data.forEach((item: any, index: number) => {
      if (count === 4) {
        refined.push([]);
        count = 0;
      }
      refined[refined.length - 1].push(item);
      count++;
    });
    return refined;
  };

  const dataToRefine = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

  return (
    <>
      <ModalTemplate
        icon={modalData.icon}
        disableCancel={modalData.disableCancel}
        cancelText={modalData.cancelText}
        open={modalData.state}
        okHandler={modalData.okHandler}
        cancelHandler={modalData.cancelHandler}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
        width={`500px`}
      />
      <Modal
        maskClosable={false}
        width={790}
        footer=""
        centered
        style={{
          border: "1px solid rgb(59 130 246)",
          borderRadius: 9,
          paddingBottom: 0,
        }}
        title={
          <div
            style={{
              width: "100%",
              cursor: "move",
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
            onFocus={() => {}}
            onBlur={() => {}}
            // end
          >
            eLog Book - Remote Shift Change
          </div>
        }
        open={open}
        onOk={() => {}}
        transitionName=""
        onCancel={() => {
          handleCancel();
        }}
      >
        <>
          <main className="">
            {/* right form */}
            <Form
              labelCol={{ span: 7 }}
              wrapperCol={{ span: 19 }}
              className=" "
              size="small"
            >
              <div className=" flex justify-between">
                <div className=" mb-0.5 w-1/2 ">
                  {/* <div className=" w-1/2 ml-[54px] mb-0.5"> */}
                  <div className=" w-1/2 ml-[90px] mb-0.5">
                    <SelectsTemplate
                      useCusWidth
                      useCallFunc
                      span
                      cusWidth={50}
                      disabled={false}
                      idexpr="id"
                      // placeHolder={Formvalues.action?.value}
                      dataexp="value"
                      label="Action"
                      selectedValue={(e) => {
                        console.log(e);
                        setFormvalues((prev) => ({ ...prev, action: e }));
                      }}
                      options={[
                        { id: "accept", value: "Accept" },
                        { id: "request", value: "Request" },
                      ]}
                    />
                  </div>

                  <div className=" mb-0.5 ml-[38px] w-full">
                    <SelectsTemplate
                      useCusWidth
                      useCallFunc
                      cusWidth={50}
                      disabled={false}
                      label="Home Station"
                      idexpr="stnIDpk"
                      // placeHolder={Formvalues.action?.value}
                      dataexp="stnName"
                      selectedValue={(e) => {
                        console.log(e);
                        setFormvalues((prev) => ({ ...prev, homeStation: e }));
                      }}
                      options={HomeStation}
                    />
                  </div>
                  <div className="ml-[38px] w-full">
                    <SelectsTemplate
                      useCallFunc
                      label="Remote Operator"
                      disabled={false}
                      idexpr="empIDpk"
                      // placeHolder={Formvalues.action?.value}
                      dataexp="empName1"
                      selectedValue={(e) => {
                        console.log(e);
                        setFormvalues((prev) => ({ ...prev, remoteOp: e }));
                      }}
                      options={ReportingShift}
                    />
                  </div>
                  <div className=" mb-0.5 ml-[38px] w-full">
                    <SelectsTemplate
                      useCallFunc
                      label="Initiating Operator"
                      disabled={false}
                      idexpr="empIDpk"
                      // placeHolder={Formvalues.action?.value}
                      dataexp="empName1"
                      selectedValue={(e) => {
                        console.log(e);
                        setFormvalues((prev) => ({ ...prev, initiatingOp: e }));
                      }}
                      options={InitiatingOperator}
                    />
                  </div>
                </div>

                <div className=" mb-0.5 w-1/2 ml-4 flex justify-end items-end">
                  <span
                    onClick={() => {}}
                    style={{
                      height: 22.5,
                      marginBottom: 2,
                      borderWidth: 1,
                      width: 26,
                      marginLeft: 3,
                    }}
                    className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                  >
                    <img
                      className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                      src={reset}
                      alt="reset"
                    />
                  </span>
                </div>
              </div>

              <section className="flex mb-1">
                <p className="text-[12px] w-[24.5%]">
                  Incomming Shift Members:
                </p>
                <section
                  className={`flex h-auto w-full bg-muted overflow-x-auto p-1 border-[1px] rounded-md space-x-10 px-2`}
                >
                  {refineData(dataToRefine)?.map((items, index) => (
                    <section className="min-w-32 h-full space-y-1">
                      {items.map((_, index) => (
                        <section className="flex items-center space-x-2 w-full h-auto">
                          <CheckboxTemlate
                            withBorders
                            defaultValue={true}
                            customDisabled={false}
                          />
                          <span>{`Shift ${index + 1}`}</span>
                        </section>
                      ))}
                    </section>
                  ))}
                </section>
              </section>

              <div className="ml-[59px] ">
                <TextareaTemplate
                  labelCol={3}
                  disabled={false}
                  label="Summary"
                  height={200}
                  useCallbackFunc
                  defaultValue={Summary && Summary[0]?.rscRmks}
                  setCallbackValue={(e) => {
                    setFormvalues((prev) => ({ ...prev, summary: e }));
                  }}
                />
              </div>

              {/* <div className=" ml-[106.5px]"> */}
              <div className=" ml-[145px]">
                <div className=" mt-1 flex flex-row justify-between">
                  <div className=" w-1/2 flex h-fit">
                    <CustomizableButton
                      isTooltip
                      tooltipMsg={"Close"}
                      classname={"px-2 py-2"}
                      customizableBtnImage={close}
                    />
                  </div>

                  <div className=" w-1/2 flex justify-end mr-[-5px]">
                    {Formvalues?.action?.id === "accept" ? (
                      <ApproveButton
                        title="Accept"
                        disableButton={false}
                        useCallbackFunc
                        disabled_status={false}
                        handleSave={InitiateAsSave}
                      />
                    ) : (
                      <SaveButton
                        title="Initiate"
                        disableButton={false}
                        useCallbackFunc
                        disabled_status={false}
                        handleSave={InitiateAsSave}
                      />
                    )}
                  </div>
                </div>

                <hr className=" text-gray-200 my-2" />

                <div className="">
                  <p>
                    <span className=" text-blue-600 font-bold">*</span>
                  </p>
                </div>
              </div>
            </Form>
          </main>
        </>
      </Modal>
    </>
  );
};
export default Remote_Shift_Change;
