import { Input, Select } from "antd";
import { Column, DataGrid, Scrolling } from "devextreme-react/data-grid";
import { useState } from "react";
import {
  navbar_height,
  saveModalData,
  toolbar_height,
} from "../../../../../../accessories/component_infos";
import { SmsAlertsToolbar } from "./smsAlerts-toolbar";
import {
  vehicle_condition_TH_1,
  vehicle_condition_TH_2,
} from "../data/sms-alerts-data";
import { ModalTemplate } from "../../../../../../../templates/modal";
import useFetch from "../../../../../../../hooks/useFetch";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import useResizeMode from "../../../../../../../hooks/useResizeMode";

import Datagrid_template from "../../../../../../../templates/Datagrid";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import dayjs from "dayjs";
import { Cancel, SendEmailButton } from "../../../../../../accessories/buttons";
import { UpdateDataFunc } from "../../../../../../../functions/update";
import {
  searchCriteriaTypes,
  searchTextTypes,
  statusTypes,
} from "../../../../../../human-resource/setups/data/types/selectsTypes";
import { PostDataFunc } from "../../../../../../../functions/post";
import { useBusyLoader } from "../../../../../../../hooks/useBusyLoader";
import { useCrudFunc } from "../../../../../../../functions/crud";

const { Option } = Select;

interface props {
  toggled?: boolean;
  message?: string;
}
interface stateTypes {
  selected_scheduleSms: any;
  selected_historySms: any;
}

const SmsAlertsDetails = ({ toggled }: props) => {
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const heights_out = navbar_height + news_flash_height + toolbar_height;

  const [busyLoader, setBusyLoader] = useBusyLoader();

  //varying form height
  const [employeeId, userId, userInfo] = useAccountInfo();
  //Table Height
  var [tableHeight, settableHeight] = useState(
    window.innerHeight - 470 - heights_out
  );
  //height without navbar and toolbar
  const [initialHeight, fullheight] = useResizeMode(window.innerHeight);

  const [table_form_Height, settable_form_Height] = useState(
    window.innerHeight - heights_out
  ); //height without navbar and toolbar and form

  const [showTable, setShowTable] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth * 0.78);

  const [states, setStates] = useState<stateTypes>({
    selected_scheduleSms: [],
    selected_historySms: [],
  });
  console.log("states", states);

  //date
  const editedDate = dayjs().endOf("month").format("YYYY-MM-DD");
  const startingDate = dayjs()
    .subtract(5, "year")
    .startOf("year")
    .format("YYYY-MM-DD");

  // For the first toolbar
  const [startDate1, setStartDate1] = useState<{ temp: string; index: string }>(
    { temp: startingDate, index: startingDate }
  );
  const [endDate1, setEndDate1] = useState<{ temp: string; index: string }>({
    temp: editedDate,
    index: editedDate,
  });
  const [searchText, setSearchText] = useState<searchTextTypes>({
    text: "",
    temp: "",
  });
  const [searchCrit, setSearchCrit] = useState<searchCriteriaTypes>({
    temp: 0,
    index: 0,
  });
  const [status, setStatus] = useState<statusTypes>({ temp: "", status: "" });
  // For the second toolbar
  const [startDate2, setStartDate2] = useState<{ temp: string; index: string }>(
    { temp: startingDate, index: startingDate }
  );
  const [endDate2, setEndDate2] = useState<{ temp: string; index: string }>({
    temp: editedDate,
    index: editedDate,
  });
  const [searchText2, setSearchText2] = useState<searchTextTypes>({
    text: "",
    temp: "",
  });
  const [searchCrit2, setSearchCrit2] = useState<searchCriteriaTypes>({
    temp: 0,
    index: 0,
  });
  const [status2, setStatus2] = useState<statusTypes>({ temp: "", status: "" });

  console.log("toolbars", {
    startDate1,
    endDate1,
    searchText,
    searchCrit,
    status,
  });
  console.log("toolbars2", {
    startDate2,
    endDate2,
    searchText2,
    searchCrit2,
    status2,
  });

  const [scheduledSMSUrl, setScheduledSmsUrl] = useState(
    `MaintenanceScheduleSmsAlert/SearchMaintenanceSchedule?startDate=${startDate1.index}&enddate=${endDate1.index}&criteria=${searchCrit.index}&search=${searchText.text}&active=${status.status}`
  );

  const [selectedSms, setSelectedSms] = useState<any>({});
  const [proceedWithCancel, setProceedWithCancel] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [refresh2, setRefresh2] = useState(false);
  const [posting, updating] = useCrudFunc();

  const [scheduledSMS, scheduledSMSerror, scheduledSMS_loading] = useFetch(
    `MaintenanceScheduleSmsAlert/SearchMaintenanceSchedule?startDate=${startDate1.index}&enddate=${endDate1.index}&criteria=${searchCrit.index}&search=${searchText.text}&active=${status.status}`,
    refresh,
    "mtsIdpk"
  );
  const [historyUrl, setHistoryUrl] = useState(
    `MaintenanceScheduleSmsAlert/NewPopDgvAlertsHistory?criteria=${searchCrit2.index}&endDate=${endDate2.index}&startDate=${startDate2.index}&searchText=${searchText2.text}&active=${status2.status}`
  );

  const [smsHistory, smsHistoryerror, smsHistoryloading] = useFetch(
    `MaintenanceScheduleSmsAlert/NewPopDgvAlertsHistory?criteria=${searchCrit2.index}&endDate=${endDate2.index}&startDate=${startDate2.index}&searchText=${searchText2.text}&active=${status2.status}`,
    refresh2,
    "slgCreationDate"
  );
  console.log("selectedSms", selectedSms);
  // useEffect(() => {
  //    console.log(smsHistory)
  //  // fetchData();

  //  }, [smsHistory])
  if (scheduledSMS) {
    console.log("scheduledSMS", scheduledSMS);
  } else {
    console.log("scheduledSMSerror", scheduledSMSerror);
  }

  if (smsHistory) {
    console.log("smsHistorydata", smsHistory);
  } else {
    console.log("smserror", smsHistoryerror);
  }
  console.log("selectedScheduleSms", states.selected_scheduleSms);
  console.log("selectedHistorySms", states.selected_historySms);

  const [modalInfo, setModalInfo] = useState<any>({
    icon: "question",
    data: saveModalData,
    disableCancel: false,
    openModal: false,
  });

  function callFunction() {
    setProceedWithCancel(true);
    setModalInfo({
      icon: "question",
      data: {
        message: "Are you sure you want to proceed and cancel this schedule?",
        title: "Cancel Schedule",
        okText: "Cancel Schedule",
        cancelText: "No",
      },
      disableCancel: false,
      openModal: true,
    });
  }

  const sendSMS = {
    icon: "question",
    title: "Send Message?",
    message: "Are you sure you want send message to this Personnel?",
    okText: "Send",
    cancelText: "No",
  };
  // console.log("DEED",selectedSms?.mtsIdpk)
  //validation
  const validateCancel = () => {
    if (states.selected_scheduleSms.length === 0) {
      setModalInfo({
        icon: "warning",
        data: {
          message: "Please select a Record to cancel ",
          title: "Validation Error",
        },
        disableCancel: true,
        openModal: true,
      });
    } else {
      setModalInfo({
        icon: "question",
        data: {
          message:
            "This action will CANCEL the schedule and prevent personnel from receiving SMS Alerts.",
          title: "Cancel Schedule",
          okText: "Cancel Schedule",
          cancelText: "No",
        },
        disableCancel: false,
        openModal: true,
      });
    }
  };

  const [inputValue, setInputValue] = useState("");
  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };
  console.log("input", inputValue);
  const validate = () => {
    if (states.selected_scheduleSms.length === 0) {
      setModalInfo({
        icon: "warning",
        data: {
          message: "Please select a Record to send ",
          title: "No Record",
        },
        disableCancel: true,
        openModal: true,
      });
    } else {
      setModalInfo({
        icon: "question",
        data: sendSMS,
        disableCancel: false,
        openModal: true,
      });
    }
  };
  const cancelScheduleSMS = async () => {
    try {
      setBusyLoader("Cancelling Schedule....");
      await updating(
        `MaintenanceScheduleSmsAlert/AsmTmsMaintenanceSchedulesUpdateScheduleCancel?EditedBy=${userId}&Id=${
          states.selected_scheduleSms?.mtsIDpk ?? 0
        }`,
        `Cancelled Asset Mgr-Trans-Maintenance SMS Alert for vehicle with Reg No ${states.selected_scheduleSms?.vdtRegNo} for Apporving Officer ${states.selected_scheduleSms?.ownerEmpName} ${states.selected_scheduleSms?.ownerStaffNo}`
      );
      setModalInfo({
        icon: "success",
        data: {
          message: "Record cancelled",
          title: "Success",
          okText: "Ok",
          cancelText: "",
        },
        disableCancel: true,
        openModal: true,
      });
      setBusyLoader("");
    } catch (error: any) {
      console.log(error);
      setModalInfo({
        icon: "warning",
        data: {
          message:
            "Failed to Cancel Schedule!..Please contact your administrator",
          title: "Unexpected Error!",
          okText: "Ok",
          cancelText: "",
        },
        disableCancel: true,
        openModal: true,
      });
      setBusyLoader("");
    }
  };
  const checkConnection = () => {
    if (!navigator.onLine) {
      setModalInfo({
        icon: "warning",
        data: {
          message:
            "Your computer is not connected to the Internet. Please check your Internet connection and try again",
          title: "Connectivity Error!",
          okText: "Ok",
          cancelText: "",
        },
        disableCancel: true,
        openModal: true,
      });
    }
  };

  // const inputMessage={inputValue};

  let details = {
    driverEmpID: states.selected_scheduleSms?.driverEmpID,
    driverFname: states.selected_scheduleSms?.driverFname,
    ownerEmpID: states.selected_scheduleSms?.ownerEmpID,
    driverMobNox: states.selected_scheduleSms?.driverMobNox,
    vdtRegNo: states.selected_scheduleSms?.vdtRegNo,
    mtpName: states.selected_scheduleSms?.mtpName,
    mtsDate: states.selected_scheduleSms?.mtsMaintenanceDate,
    vehicleIDfk: states.selected_scheduleSms?.mtsVehicleDetailIDfk,
  };
  console.log("details", details);
  const message = `Hello ${
    details.driverFname
  }, please be informed that your vehicle with Reg. No ${
    details.vdtRegNo
  } is due for ${details.mtpName} on ${new Date(
    details.mtsDate
  ).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  })}.
   Thank you. Sent from Innox`;

  let mobNox = ""; // Declare the variable 'mobNox' outside of the if statement

  const sendScheduleSMS = async () => {
    try {
      checkConnection();

      // if (details.driverEmpID === details.ownerEmpID) {
      //   mobNox = details.driverMobNox.trim().replace("", "");
      // }

      // if (mobNox.trim.length >= 10) {
      //   mobNox = mobNox.substring(0, 10);
      // }
      setBusyLoader("Sending SMS....");
      await posting(
        `SMSAlert/SaveSMS`,
        {
          imsMessage: inputValue === "" ? message : inputValue,
          imsRecipientEmpIdfk:
            states.selected_scheduleSms?.driverEmpID ===
            states.selected_scheduleSms?.ownerEmpID
              ? states.selected_scheduleSms?.ownerEmpID
              : states.selected_scheduleSms?.driverEmpID &&
                states.selected_scheduleSms?.ownerEmpID,
          imsSender: userInfo.empName,
          imsRecordIDfk: details.vehicleIDfk,
          imsMessageType: "string",
          imsMessageClass: "string",
          imsEmpIDfk: details.driverEmpID,
          imsInitiatorEmpIDfk: userId,
          imsCreatedBy: userId,
        },
        `Sent Asset Mgr-Trans-Maintenance Alert to employee (Driver & Approv. Officer) with Emp ID ${states.selected_scheduleSms?.driverEmpID} and Staff No -${states.selected_scheduleSms?.driverStaffNo} successfully`
      );

      setRefresh2(!refresh2);
      setModalInfo({
        icon: "success",
        data: {
          message: "SMS Sent Successfully",
          title: "Success",
          okText: "Ok",
          cancelText: "",
        },
        disableCancel: true,
        openModal: true,
      });
      setBusyLoader("");
    } catch (error: any) {
      console.log(error);
      setModalInfo({
        icon: "warning",
        data: {
          message: "Failed to send Alert!..Please contact your administrator",
          title: "Unexpected Error!",
          okText: "Ok",
          cancelText: "",
        },
        disableCancel: true,
        openModal: true,
      });
      setBusyLoader("");
    }
  };

  //Access Priviledge

  const [privType, setPrivType] = useState<string>("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);
  //cancel button
  const cancelAccessPriv = () => {
    if (updatePriv === true) {
      validateCancel();
    } else {
      setModalInfo({
        icon: "warning",
        data: {
          message: "No Access Privilege ",
          title: "Access Denied!",
        },
        disableCancel: true,
        openModal: true,
      });
    }
  };

  // send Sms

  //send button
  const sendAccessPriv = () => {
    if (updatePriv === true) {
      validate();
    } else {
      setModalInfo({
        icon: "warning",
        data: {
          message: "No Access Privilege ",
          title: "Access Denied!",
        },
        disableCancel: true,
        openModal: true,
      });
    }
  };

  // alert(tableHeight)

  const searchCriteria: any = [
    { id: 0, value: "Registration No" },
    { id: 1, value: "Chassis No" },
    { id: 2, value: "Vehicle Type" },
    { id: 3, value: " Vehicle Make" },
    { id: 4, value: "Manufacturer" },
    { id: 5, value: "Supplier " },
    { id: 5, value: "Manufacturer date" },
  ];

  const smsAlert_tooltips_ids: any[] = [
    "find_smsAlert",
    "re_smsAlert",
    "pr_smsAlert",
    "h_smsAlert",
  ];
  const tooltips_history: any[] = [
    "find_smsHistory",
    "re_smsHistory",
    "pr_smsHistory",
    "h_smsHistory",
  ];

  return (
    <>
      <div className="w-full">
        <ModalTemplate
          icon={modalInfo.icon}
          disableCancel={modalInfo.disableCancel}
          cancelText={modalInfo.data.cancelText}
          open={modalInfo.openModal}
          okHandler={() => {
            modalInfo.title === sendSMS.title
              ? callFunction()
              : modalInfo.data.title === "Cancel Schedule"
              ? cancelScheduleSMS()
              : setModalInfo({ ...modalInfo, ["openModal"]: false });
            modalInfo.data.title === "Send Message?"
              ? sendScheduleSMS()
              : setModalInfo({ ...modalInfo, ["openModal"]: false });
          }}
          cancelHandler={() => {
            setModalInfo({ ...modalInfo, ["openModal"]: false });
          }}
          message={modalInfo.data.message}
          okText={modalInfo.data.okText}
          title={modalInfo.data.title}
        />

        {toggled && (
          <div
            style={{ borderColor: borderTheme }}
            className="w-full  border rounded-t-md rounded-b-xs"
          >
            <div
              id={"form_header"}
              style={{ borderColor: borderTheme }}
              className="text-xs bg-slate-100 border-[1px] border-b-[0px] border-l-0 border-r-0 border-t-0 px-1 round rounded-t-md py-1 "
            >
              Scheduled SMS Alerts
            </div>
            <div className="w-full">
              <div className="w-full pb-1">
                <div className="w-full flex text-xs">
                  <SmsAlertsToolbar
                    tooltip_ids={smsAlert_tooltips_ids}
                    id="smsAlerts"
                    startDateChanged={(date) => {
                      setStartDate1({
                        temp: dayjs(date).format("YYYY-MM-DD"),
                        index: startDate1.index,
                      });
                    }}
                    endDateChanged={(date) => {
                      setEndDate1({
                        temp: dayjs(date).format("YYYY-MM-DD"),
                        index: endDate1.index,
                      });
                    }}
                    checkOnChange={(check) =>
                      setStatus({ temp: check, status: status.status })
                    }
                    searchCriteria
                    searchCriteriaData={searchCriteria}
                    searchCriteriaOnchange={(newIndex, criteria) => {
                      setSearchCrit({
                        temp: newIndex,
                        index: searchCrit.index,
                      });
                    }}
                    searchTextOnchange={(text) =>
                      setSearchText({ temp: text, text: searchText.text })
                    }
                    setrefreshx={() => {
                      setRefresh(!refresh);
                    }}
                    startDate={startDate1.temp}
                    endDate={endDate1.temp}
                    handleFind={() => {
                      setStartDate1({
                        temp: startDate1.temp,
                        index: startDate1.temp,
                      });
                      setEndDate1({
                        temp: endDate1.temp,
                        index: endDate1.temp,
                      });
                      setSearchText({
                        temp: searchText.temp,
                        text: searchText.temp,
                      });
                      setStatus({ temp: status.temp, status: status.temp });
                    }}
                    sendSms={() => {
                      sendScheduleSMS();
                    }}
                  />
                </div>
                <div className={"w-full"}>
                  <Datagrid_template
                    gridheight={toggled === true ? tableHeight : 0}
                    columns={vehicle_condition_TH_1}
                    data={scheduledSMS}
                    onRowClick={(e) => {
                      setStates((prevState) => ({
                        ...prevState,
                        selected_scheduleSms: e,
                      }));
                    }}
                    disablePaging={true}
                  />
                </div>

                <div className=" mt-1 flex justify-end  px-1">
                  <div className="flex w-full">
                    <pre className="text-xs ml-20">{"Sample \nMessage:"}</pre>

                    <Input
                      value={inputValue}
                      onChange={handleInputChange}
                      style={{ width: "calc(100%)" }}
                      className=" mx-2"
                    />
                  </div>
                  <div className="flex justify-end w-[180px]">
                    <SendEmailButton
                      disableButton={false}
                      handleMail={() => {
                        sendAccessPriv();
                      }}
                    />
                    <Cancel
                      useCallbackFunc={true}
                      cancel_button_toggler={() => {
                        cancelAccessPriv();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div
          style={{ borderColor: borderTheme, width: "100%" }}
          className="w-full mt-1 border-[1px] rounded-t-md rounded-b-xs"
        >
          <div
            id="form_header2"
            style={{ borderColor: borderTheme }}
            className="text-xs bg-slate-100 border-l-0 border-r-0 border-t-0px-1 round border-l-0 border-r-0 border-b-0 rounded-t-md py-1 px-2"
          >
            SMS Alerts History
          </div>
          <div className="w-full ">
            <div className="w-full ">
              <div className="w-full flex text-xs">
                <SmsAlertsToolbar
                  tooltip_ids={tooltips_history}
                  id="smsHistory"
                  startDateChanged={(date) => {
                    setStartDate2({
                      temp: dayjs(date).format("YYYY-MM-DD"),
                      index: startDate2.index,
                    });
                  }}
                  endDateChanged={(date) => {
                    setEndDate2({
                      temp: dayjs(date).format("YYYY-MM-DD"),
                      index: endDate2.index,
                    });
                  }}
                  checkOnChange={(check) =>
                    setStatus2({ temp: check, status: status.status })
                  }
                  searchCriteriaData={searchCriteria}
                  searchCriteriaOnchange={(newIndex, criteria) => {
                    setSearchCrit2({
                      temp: newIndex,
                      index: searchCrit2.index,
                    });
                  }}
                  searchTextOnchange={(text) =>
                    setSearchText2({ temp: text, text: searchText.text })
                  }
                  setrefreshx={() => {
                    setRefresh2(!refresh2);
                  }}
                  startDate={startDate2.temp}
                  endDate={endDate2.temp}
                  hide={true}
                  handleFind={() => {
                    setStartDate2({
                      temp: startDate2.temp,
                      index: startDate2.temp,
                    });
                    setEndDate2({
                      temp: endDate2.temp,
                      index: endDate2.temp,
                    });
                    setSearchText2({
                      temp: searchText2.temp,
                      text: searchText2.temp,
                    });
                    setStatus2({ temp: status2.temp, status: status2.temp });
                  }}
                />
              </div>

              <Datagrid_template
                gridheight={toggled === true ? tableHeight : 0}
                data={smsHistory}
                columns={vehicle_condition_TH_2}
                onRowClick={(e) => {
                  setSelectedSms(e);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SmsAlertsDetails;
