import { useDispatch } from "react-redux";
import {
  recordExistsError,
  serverError,
} from "../components/accessories/component_infos";
import { validateRecordExists } from "../functions/validateRecordExists";
import { setGeneralValue } from "../features/generalSlice";

export const useValidateExist = () => {
  const dispatch = useDispatch();

  //validate
  const handleValidation = async (
    url: string,
    id?: number,
    idExpr?: string
  ) => {
    const checkResponse = await validateRecordExists(url);

    if (checkResponse.status === 200) {
      //record(s) found
      if (!id) {
        //exists and save mode
        dispatch(
          setGeneralValue({
            expr: "modalAlert",
            value: { state: true, ...recordExistsError },
          })
        );
        return false;
      }

      //update mode instance
      for (let record of checkResponse.data) {
        if (record[idExpr!] !== id) {
          //exists
          dispatch(
            setGeneralValue({
              expr: "modalAlert",
              value: { state: true, ...recordExistsError },
            })
          );
          return false;
        }
      }
      return true;
    } else if (checkResponse.status === 204) {
      //record not found
      //not exists
      return true;
    }

    // error
    dispatch(
      setGeneralValue({
        expr: "modalAlert",
        value: { state: true, ...serverError },
      })
    );
    return false;
  };

  return handleValidation;
};
