import { V_FieldTypes } from "../widgets/vehDetails_form";
import { Fields, InputTypes } from "./AllFieldOBj";

interface ModalDisplay {
  icon: "warning" | "question" | "success";
  title: string;
  message: string;
  okText: string;
  cancelText?: string;
  openState: boolean;
  disableCancel: boolean;
  okHandler: () => void;
  cancelHandler: () => void;
}

interface Props {
  method: "Save" | "Update";
  states: any;
  set_ModalData: (e: any) => void;
  modal_Data: ModalDisplay;
  SaveData?: any;
  UpdateData?: any;
}
const Array_Of_ByteField_objs: any[] = [
  {
    type: "Byte",
    fieldName: "seatingCap",
    title: "Seating capacity",
    message: "Value should not be more than 255",
  },
  {
    type: "Byte",
    fieldName: "noOfAxles",
    title: "No. Of Axles",
    message: "Value should not be more than 255",
  },
  {
    type: "Byte",
    fieldName: "noOfWheels",
    title: "No. Of Wheels",
    message: "Value should not be more than 255",
  },
  {
    type: "Byte",
    fieldName: "noCylinder",
    title: "No. Of Cylinder",
    message: "Value should not be more than 255",
  },
];

export const FieldValidation = ({
  method,
  states,
  set_ModalData,
  modal_Data,
  SaveData,
  UpdateData,
}: Props) => {
  let hasError = false;
  let Title = "";
  let Message = "";
  let Byte_Error = false;
  let DateError = false;

  for (let i = 0; i < Fields.length; i++) {
    let { type, fieldName, title, message } = Fields[i];
    let field = fieldName as keyof V_FieldTypes;
    let val = states[field!];
    const regex = /^\d+$/;

    if (type === InputTypes.Text) {
      if (val === "" || val === undefined) {
        Title = title;
        Message = message;
        hasError = true;
        break;
      }
    } else if (type === InputTypes.Number) {
      if (val === 0 || val === undefined || val === "NaN" || val === null || val < 0) {
        Title = title;
        Message = val < 0 ? `${title} shouldn't take negative number`: regex.test(val) === false ? `${title} must be a number` : message;
        hasError = true;
        break;
      }
    } else if (type === InputTypes.object) {
      if (
        val?.id === 0 ||
        val?.value === null ||
        val?.id === undefined ||
        val?.value === undefined ||
        val?.id === null || val?.value === ''
      ) {
        Title = title;
        Message = message;
        hasError = true;
        break;
      }
    } else if (type === InputTypes.Date) {
      if (val === "" || val === undefined) {
        Title = title;
        Message = message;
        hasError = true;
        break;
      } else if (
        states["manuDate"] === states["supplyDate"] ||
        states["manuDate"] === states["regDate"] ||
        states["supplyDate"] === states["regDate"]
      ) {
        Title = "Manufacture Date , Supply Date, Registration Date";
        Message = " Values of these Date fields can not be equal";
        hasError = true;
      } else if (
        states["manuDate"] >= states["supplyDate"] ||
        states["manuDate"] >= states["regDate"] ||
        states["supplyDate"] >= states["regDate"]
      ) {
        Title = "Manufacture Date , Supply Date, Registration Date";
        Message =
          "Manu.Date must not be greater or equal to supply.Date , Manu.Date must not be greater or equal to reg.Date and supply.Date must not be greater or equal to reg.Date";
        hasError = true;
      }
    } else if (
      states["general"] &&
      states["bus"] &&
      states["specialised"] &&
      states["motorBike"] &&
      states["equipment"] &&
      states["lightDuty"] &&
      states["heavyDuty"] &&
      states["vehicle"]
    ) {
      title = "Checkbox fields";
      message = "All the Check fields can not be true neither false";
      hasError = true;
    }
  }

  // general: false,
  //   bus: false,
  //   specialised: false,
  //   motorBike: false,
  //   equipment: false,
  //   lightDuty: false,
  //   heavyDuty: false,
  //   vehicle: false,

  // Byte_Input_validation;
  for (let o = 0; o < Array_Of_ByteField_objs.length; o++) {
    let { type, fieldName, title, message } = Array_Of_ByteField_objs[o];
    if (states[fieldName as keyof V_FieldTypes] > 255) {
      Title = title;
      Message = message;
      Byte_Error = true;
      break;
    }
  }

  if (hasError) {
    set_ModalData({
      ...modal_Data,
      ["title"]: Title,
      ["message"]: Message,
      ["openState"]: true,
    });
  } else if (Byte_Error) {
    set_ModalData({
      ...modal_Data,
      ["title"]: Title,
      ["message"]: Message,
      ["openState"]: true,
    });
  } else if (hasError == false && Byte_Error == false) {
    set_ModalData({
      icon: "question",
      title: `${method} Record ?`,
      message: `Are you sure you want to ${method === 'Save' ? 'save': 'update'} this record?`,
      okText: "Yes",
      cancelText: "No",
      openState: true,
      disableCancel: false,
      okHandler: () => {
        set_ModalData({ ...modal_Data, ["openState"]: false });
        method === "Save" ? SaveData() : UpdateData();
      },
      cancelHandler: () => {
        set_ModalData({ ...modal_Data, ["openState"]: false });
      },
    });
  }
};
