import React, { useCallback, useState } from "react";
import {
  DataGrid,
  Column,
  Scrolling,
  Selection,
  Editing,
  HeaderFilter,
} from "devextreme-react/data-grid";

interface props {
  // this means whether a prop is passed on the component or not the component called
  // anywhere should work (i.e. with the ?) but without the ? it takes it's strict rule
  // that without a prop there will be an error
  toggler?: () => void;
  customHeightHook?: any;
  columns?: any;
  gridheight?: number;
}

export const Mini_datagrid_template = ({ columns, gridheight }: props) => {
  const [selectedTask_update, setSelectedTask_update] = useState();

  const selectGrid_update: any = useCallback<any>((e: any) => {
    e.component.byKey(e.currentSelectedRowKeys[0]).done((item: any) => {
      setSelectedTask_update(item);
    });
  }, []);

  return (
    <div className="">
      <div
        style={{ height: "" }}
        className="flex justify-between w-full h-full bg-slate-50 dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base"
      ></div>

      {/* Datagrid table */}
      <div
        style={{ width: "100%" }}
        className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base"
      >
        <DataGrid
          allowColumnReordering={true}
          keyExpr={"ID"}
          height={gridheight}
          rowAlternationEnabled={true}
          showBorders={true}
          showRowLines={true}
          allowColumnResizing={true}
          onSelectionChanged={selectGrid_update}
          wordWrapEnabled={false}
          focusedRowEnabled={true}
        >
          {/* STAFF DETAILS COLUMNS */}
          {/* FIXED COLUMNS */}
          {columns.map((column: any) => {
            return (
              <Column
                key={column.id}
                dataField={column.dataField}
                caption={column.caption}
                dataType={column.dataType}
                width={column.width}
                alignment="left"
                fixed={column.fixed}
              />
            );
          })}

          <HeaderFilter visible={true} allowSearch={true} />
          <Editing mode={"row"} />
          <Scrolling
            columnRenderingMode="virtual"
            mode="virtual"
            showScrollbar={"always"}
          />
          <Selection mode="single" />
        </DataGrid>
      </div>
    </div>
  );
};
