import React, {
  useState,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import {
  Form,
  Input,
  Select,
  DatePicker,
  Col,
  InputNumber,
  Row,
  Slider,
  Checkbox,
} from "antd";
import { CheckBox } from "devextreme-react/check-box";
import reset from "../../../../../assets/refresh.png";
import setting from "../../../../../assets/setting.png";
import dayjs from "dayjs";
import {
  SaveButton,
  NewButton,
  UpdateButton,
  Cancel,
} from "../../../../accessories/buttons";
import {
  CIA_Details_Select,
  SIA_Details_Monitor_Select,
  SIA_Details_Select,
  SIA_Details_Target_Select,
  UIA_Details_Select,
} from "../../../data/Performance_selects";
import {
  inputsTypes,
  selectsTypes,
} from "../../../../human-resource/setups/data/types/selectsTypes";
import useFetch from "../../../../../hooks/useFetch";
import axios from "axios";
import {
  api_url,
  saveModalData,
  updateModalData,
} from "../../../../accessories/component_infos";
import { ModalTemplate } from "../../../../../templates/modal";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../../app/store";
import {
  task_form_disable,
  task_footer_update,
  task_footer_table_selected,
} from "../../../../../features/Task&Todo/Task/TaskDetails";
import {
  setGeneralValue,
  settask_form_disable,
  settask_footer_update,
} from "../../../../../features/generalSlice";
import { Settings } from "docx/build/file/settings";
import { FormModal } from "../../../../accessories/formModal";
import { PerformanceSetupsMonitoringModules } from "../../../../Performance/setups/monitoring-modules/monitoringModules";
import { Perf_setups_Target } from "../../../../Performance/setups/targets/targets";
import { Perf_setups_measures } from "../../../../Performance/setups/measures/measures";
import { PerformanceStaffInitiatives } from "../../../../Performance/setups/staff-initiatives/staffInitiatives";
import { PerformanceSetupsPeriods } from "../../../../Performance/setups/periods/periods";
import DropDownBox from "devextreme-react/drop-down-box";
import DataGrid, { Column } from "devextreme-react/data-grid";
import { periods_cols } from "../data/datagrid_cols";
import { SelectsTemplate } from "../../../../../templates/select";

interface props {
  onEditModeGrid?: () => void;
  onDisabledModeGrid?: () => void;
}

interface stateTypes {
  department?: { id: number; name: string };
  period: { name: string; id: number };
  assignmentType: { name: string; id: number };
  // department: { name: numstringber, id: nu,ber },
  section: { name: string; id: number };
  unit: { name: string; id: number };
  coopInitiative: { name: string; id: number };
  departmentInitiative: { name: string; id: number };
  sectionInitiative: { name: string; id: number };
  monitorResults: { name: string; id: number };
  unitInitiative: { name: string; id: number };
  staffInitiative: { name: string; id: number };
  strategicObjective: { name: string; id: number };
  measure: { name: string; id: number };
  target: { name: string; id: number };
  targetFreq?: string;
  selectedId?: string;
  formDisabled?: boolean;
  remarks?: string;
  siaState?: string;
  weight: string;
  applyTargetFreq?: boolean;
  targetPercent: any;
  compulsory?: boolean;
  allowManualUpdate: boolean;
  order: string;
  signedOff?: boolean;
  updateMode: boolean;
  state?: any;
  active?: any;
}

export const SIA_Details_Form = forwardRef(
  ({ onEditModeGrid, onDisabledModeGrid }: props, ref) => {
    const options = [];
    for (let i = 1; i < 36; i++) {
      options.push({
        label: i.toString(36) + i,
        value: i.toString(36) + i,
      });
    }

    const borderTheme = useSelector(
      (state: RootState) => state.general.cssBorderClass
    );
    const form_disable = useSelector(
      (state: RootState) => state.general.formDisabled
    );

    const [departmentData, departmentError, departmentLoading] = useFetch(
      "HrEmployee/FormDepartments"
    );
    const [sectionData, setSectionData] = useState([]);
    const [unitData, setUnitData] = useState([]);
    const [measureData, measureError, measureLoading] = useFetch(
      "PfmStaffInitiativesAssignments/GetLueMeasures"
    );
    const [resultsData, resultsError, resultsLoading] = useFetch(
      "PfmStaffInitiativesAssignments/GetLueResultModules"
    );
    const [periodsData, periodsError, periodsLoading] = useFetch(
      "PfmStaffInitiativesAssignments/GetAllPfmPeriodNames"
    );
    const [
      departmentInitiativeData,
      departmentInitiativeError,
      departmentInitiativeLoading,
    ] = useFetch("DeptInitiatives/GetAllDeptInitiatives");
    const [
      sectionInitiativeData,
      sectionInitiativeError,
      sectionInitiativeLoading,
    ] = useFetch(
      "PfmStaffInitiativesAssignments/GetAllPfmSectionInitiativeAssignmentNames"
    );
    const [coopInitiativeData, coopInitiativeError, coopInitiativeLoading] =
      useFetch(
        "PfmStaffInitiativesAssignments/GetAllPfmSectionInitiativeAssignmentNames"
      );
    const [
      strategicObjectiveData,
      strategicObjective,
      strategicObjectiveLoading,
    ] = useFetch("PfmObjectives/GetAllStrategicObjectives");
    const [unitOrStaffInitData, unitOrStaffInitError, cunitOrStaffInitLoading] =
      useFetch(
        "PfmStaffInitiativesAssignments/GetLueUnitInitiativeOrStaffInitiative?restrictForAdmin=false"
      );
    const [targetData, targetError, targetLoading] = useFetch(
      "PfmStaffInitiativesAssignments/GetLueTargets"
    );

    const [formData, setFormData] = useState<stateTypes>({
      selectedId: "",
      siaState: "",

      period: { name: "", id: 0 },
      assignmentType: { name: "", id: 0 },
      department: { name: "", id: 0 },
      section: { name: "", id: 0 },
      unit: { name: "", id: 0 },
      coopInitiative: { name: "", id: 0 },
      departmentInitiative: { name: "", id: 0 },
      sectionInitiative: { name: "", id: 0 },

      unitInitiative: { name: "", id: 0 },
      staffInitiative: { name: "", id: 0 },
      strategicObjective: { name: "", id: 0 },
      measure: { name: "", id: 0 },
      target: { name: "", id: 0 },
      targetFreq: "",
      applyTargetFreq: false,
      targetPercent: 0,
      monitorResults: { name: "", id: 0 },
      compulsory: false,
      weight: "",
      allowManualUpdate: false,
      order: "",
      active: false,
      signedOff: false,
      formDisabled: true,
      updateMode: false,
      remarks: "",
    });

    useImperativeHandle(ref, () => ({
      singleClick: (e: any) => {
        console.log(e);
        setFormData({
          ...formData,
          selectedId: e.fmgIDpk,
          state: e.siaState,
          period: { name: e.prdName, id: e.prdIDpk },
          department: { name: e.dptName, id: e.dptIDpk },
          section: { name: e.sxnName, id: e.sxnIDpk },
          coopInitiative: { name: e.cinName, id: e.cinIDpk },
          unit: { name: e.untName, id: e.untIDpk },
          unitInitiative: { name: e.uinName, id: e.uinIDpk },
          staffInitiative: { name: e.sinName, id: e.sinIDpk },
          measure: { name: e.msrName, id: e.msrIDpk },
          target: { name: e.tgtName, id: e.tgtIDpk },
          targetFreq: e.siaTargetFrequency,
          applyTargetFreq: e.siaApplyTargetFrequency,
          targetPercent: e.siaTargetPercentageToComplete,
          monitorResults: { name: e.rmmName, id: e.rmmIDpk },
          compulsory: e.siaCompulsory,
          weight: e.siaWeight,
          allowManualUpdate: e.siaAllowActualsManualUpdate,
          order: e.siaOrder,
          active: e.siaActive,
          updateMode: false,
          signedOff: e.siaSignedOff,
          formDisabled: true,
        });
      },
      setRemarks: (e: any) => {
        setFormData({
          ...formData,
          remarks: e,
        });
      },
      doubleClick: () => {
        setFormData({
          ...formData,
          updateMode: true,
          formDisabled: false,
        });
        //enable forms
        dispatch(task_form_disable(true));

        //enable Update Mode
        dispatch(task_footer_update(true));

        dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      },
    }));

    const fetchData = async (route: any, field: any) => {
      console.log(route);
      try {
        // get data
        const res = await axios.get(`${api_url}/${route}`, {
          headers: {
            "Content-Type": ["application/json", "text/plain", "charset=utf-8"],
          },
        });
        field == "dept"
          ? setSectionData(res.data.reverse().slice(0, 100))
          : setUnitData(res.data.reverse().slice(0, 100)); // set data
      } catch (error: any) {
        // set error
      } finally {
      }
    };

    const checkData = () => {
      // const inputArr = selects.concat(inputs_second, inputs_third);

      for (let index = 0; index < selects.length; index++) {
        if (selects[index]["dataExpr"]!.trim() === "") {
          setModalInfo({
            icon: "warning",
            data: {
              message: `Please select a valid ${selects[index][
                "label"
              ].toLocaleLowerCase()}`,
              title: `Invalid ${selects[index]["label"]}`,
              okText: "Ok",
              cancelText: "No, Cancel",
            },
            openModal: true,
            disableCancel: true,
          });
          return false;
        }
      }
      return true;
    };

    const validate = (actionType: any) => {
      if (!checkData()) {
      } else if (formData.target.name === "") {
        setModalInfo({
          icon: "warning",
          data: {
            message: "Please select a valid target",
            title: "Invalid Target",
            okText: "Ok",
            cancelText: "No, Cancel",
          },
          openModal: true,
          disableCancel: true,
        });
      } else if (formData.targetFreq === "") {
        setModalInfo({
          icon: "warning",
          data: {
            message: "Please enter target frequency",
            title: "Invalid Target Frequency",
            okText: "Ok",
            cancelText: "No, Cancel",
          },
          openModal: true,
          disableCancel: true,
        });
      } else if (formData.monitorResults.name === "") {
        setModalInfo({
          icon: "warning",
          data: {
            message: "Please select a valid monitor result",
            title: "Invalid Monitor Result",
            okText: "Ok",
            cancelText: "No, Cancel",
          },
          openModal: true,
          disableCancel: true,
        });
      } else if (formData.weight === "") {
        setModalInfo({
          icon: "warning",
          data: {
            message: "Please enter weight",
            title: "Invalid Weight",
            okText: "Ok",
            cancelText: "No, Cancel",
          },
          openModal: true,
          disableCancel: true,
        });
      } else if (formData.order === "") {
        setModalInfo({
          icon: "warning",
          data: {
            message: "Please enter order",
            title: "Invalid Order",
            okText: "Ok",
            cancelText: "No, Cancel",
          },
          openModal: true,
          disableCancel: true,
        });
      } else {
        if (actionType === "update") {
          setModalInfo({
            icon: "question",
            data: updateModalData,
            openModal: true,
            disableCancel: false,
          });
        } else if (actionType === "save") {
          setModalInfo({
            icon: "question",
            data: saveModalData,
            openModal: true,
            disableCancel: false,
          });
        }
      }
    };

    const selects: selectsTypes[] = [
      // { id: 0, label: 'Period', optionsData: periodsData, nameKey: 'prdName', idExpr: 'prdIdpk', dataExpr: formData.period.name, style:'89%' },
      {
        id: 0,
        stateName: "",
        label: "Assignment Type",
        defaultValue:formData.assignmentType.name,
        //  nameKey: 'name',
        idExpr: "id",
        dataExpr: "value",
        optionsData: assignment_data,
        style: "40%",
      },

      {
        id: 1,
        stateName: "",
        label: "Department",
        idExpr: "id",
        dataExpr: "name",
        optionsData: departmentData,
        defaultValue:formData.department.name,
        style: "89%",
      },
      {
        id: 2,
        stateName: "section",
        label: "Section",
        optionsData: sectionData,
        idExpr: "sxnIdpk",
        diffExprs: { valueKey: "HrEmployee/FormUnits", idKey: "section" },
        dataExpr: formData.section.name,
        defaultValue: formData.section.name,
        style: "89%",
      },
      {
        id: 3,
        stateName: "unit",
        label: "Unit",
        optionsData: unitData,
        nameKey: "untName",
        idExpr: "untIdpk",
        dataExpr: "untName",
        defaultValue: formData.unit.name,
        style: "89%",
      },

      {
        id: 5,
        stateName: "coopInitiative",
        label: "Cooperate Initiative",
        optionsData: coopInitiativeData,
        nameKey: "cinName",
        idExpr: "cinIdpk",
        dataExpr: "cinName",
        defaultValue: formData.coopInitiative.name,
        style: "89%",
      },
      {
        id: 6,
        stateName: "departmentInitiative",
        label: "Department Initiative",
        optionsData: departmentInitiativeData,
        nameKey: "dinName",
        idExpr: "dinIdppk ",
        dataExpr: "dinName",
        defaultValue: formData.departmentInitiative.name,
        style: "89%",
      },
      {
        id: 7,
        stateName: "sectionInitiative",
        label: "Section Initiative",
        optionsData: sectionInitiativeData,
        nameKey: "cinName",
        idExpr: "cinIdpk",
        dataExpr:"cinName",
        defaultValue: formData.sectionInitiative.name,
        style: "89%",
      },
      {
        id: 8,
        stateName: "unitInitiative",
        label: "Unit Initiative",
        optionsData: unitOrStaffInitData,
        nameKey: "name",
        idExpr: "id",
        dataExpr: "name",
        defaultValue: formData.unitInitiative.name,
        style: "89%",
      },
      {
        id: 9,
        stateName: "staffInitiative",
        label: "Staff Initiative",
        optionsData: unitOrStaffInitData,
        nameKey: "name",
        idExpr: "id",
        dataExpr: "name",
        defaultValue: formData.staffInitiative.name,
        style: "89%",
      },
      {
        id: 11,
        stateName: "strategicObjective",
        label: "Strategic Objective",
        optionsData: strategicObjectiveData,
        nameKey: "objName",
        idExpr: "objIDpk",
        dataExpr: "objName",
        defaultValue: formData.strategicObjective.name,
        style: "89%",
      },
      {
        id: 10,
        stateName: "measure",
        label: "Measure",
        optionsData: measureData,
        nameKey: "name",
        idExpr: "id",
        dataExpr: "name",
        defaultValue: formData.measure.name,
        style: "89%",
      },
      // {id: 6, label : 'Target', optionsData : [{id : 0, data : 'option'}]},
      // {id: 6, label : 'Unit Initiative', optionsData : [{id : 0, data : 'option'}]},
    ];

    const runGen = (index: number, data: any) => {
      console.log(index);
      index === 0
        ? setFormData({
            ...formData,
            period: { name: data.prdName ?? "", id: data.prdIdpk ?? "" },
          })
        : index === 0
        ? setFormData({
            ...formData,
            department: { name: data.name ?? "", id: data.id ?? "" },
          })
        : index === 1
        ? setFormData({
            ...formData,
            section: { name: data.sxnName ?? "", id: data.sxnIdpk ?? "" },
          })
        : index === 3
        ? setFormData({
            ...formData,
            coopInitiative: {
              name: data.cinName ?? "",
              id: data.cinIdpk ?? "",
            },
          })
        : index === 2
        ? setFormData({
            ...formData,
            unit: { name: data.name ?? "", id: data.id ?? "" },
          })
        : index === 5
        ? setFormData({
            ...formData,
            unitInitiative: { name: data.name ?? "", id: data.id ?? "" },
          })
        : index === 6
        ? setFormData({
            ...formData,
            staffInitiative: { name: data.name ?? "", id: data.id ?? "" },
          })
        : setFormData({
            ...formData,
            measure: { name: data.name ?? "", id: data.id ?? "" },
          });
    };

    const clearFields = () => {
      setFormData({
        ...formData,
        active: true,
        formDisabled: false,
        updateMode: false,
      });
    };
    const [modalInfo, setModalInfo] = useState<any>({
      icon: "question",
      data: saveModalData,
      disableCancel: false,
      openModal: false,
    });

    const save = async () => {};

    const update = async () => {
      try {
        const res = await axios.put(
          `${api_url}/PfmStaffInitiativesAssignments/UpdatePfmUnitInitiativeAssignment`,
          {
            siaIdpk: 1,
            siaPeriodIdfk: formData.period.id,
            siaEmpIdfk: 1,
            siaState: formData.state,
            siaSignedOff: formData.signedOff,
            siaEditedBy: 1,
            siaEditedDate: `${dayjs().format("YYYY-MM-DDTHH:mm:ss[Z]")}`,
          }
        );
        // refresh();
        setModalInfo({
          icon: "success",
          data: {
            message: "Record successfully added",
            title: "Success",
            okText: "Ok",
            cancelText: "No, Cancel",
          },
          disableCancel: true,
          openModal: true,
        });
      } catch (error: any) {
        console.log(error);
        setModalInfo({
          icon: "warning",
          data: {
            message: `${error.message}`,
            title: "Error",
            okText: "Ok",
            cancelText: "No, Cancel",
          },
          disableCancel: true,
          openModal: true,
        });
      }
    };

    const disablex = useSelector(
      (state: RootState) => state.addTask.taskFormStatus
    );
    const updateMode = useSelector(
      (state: RootState) => state.addTask.taskFooterUpdate
    );
    const dispatch = useDispatch();

    useEffect(() => {
      if (!disablex) {
        clearFields();

        dispatch(task_form_disable(false));
        dispatch(task_footer_table_selected({}));
        //  this destroys the update mode state
        dispatch(task_footer_update(false));
      } else {
        setFormData({
          ...formData,
          updateMode: false,
          formDisabled: true,
        });
        // setShowCancel(false); setDisableForms(true); setUpdating(false)
      }
      if (updateMode) {
        setFormData({
          ...formData,
          updateMode: true,
          formDisabled: false,
        });

        //     setShowCancel(true);
        //     setUpdating(true);
        //  setDisableForms(false);
      }
    }, [disablex, updateMode]);

    // settings form Modal
    const [settingModal, setSettingModal] = useState<string>("");
    const [opensettingModal, setOpenSettingModal] = useState<boolean>(false);

    const formModals = [
      {
        id: 0,
        label: "ResultMonitoring",
        module: <PerformanceSetupsMonitoringModules />,
      },
      { id: 1, label: "Targets", module: <Perf_setups_Target /> },
      { id: 2, label: "Measures", module: <Perf_setups_measures /> },
      {
        id: 3,
        label: "Staff Initiative",
        module: <PerformanceStaffInitiatives />,
      },
      { id: 4, label: "Periods", module: <PerformanceSetupsPeriods /> },
    ];

    const dropdownRef = useRef<any>(null);

    const close = () => {
      dropdownRef.current.instance.close();
    };

    const dataGridRender = (
      data: any,
      columnHeader: any,
      callBackFunction: any
    ) => {
      return (
        <DataGrid
          height={"100%"}
          width={"100%"}
          style={{ maxHeight: "195px" }}
          columnWidth={80}
          //  keyExpr = {'stnIdpk'}
          columnResizingMode={"widget"}
          //  focusedRowEnabled={true}
          showColumnLines={true}
          showRowLines={true}
          wordWrapEnabled={false}
          allowColumnResizing={true}
          dataSource={data}
          onRowClick={(e: any) => {
            callBackFunction(e.data);
            console.log(e.data);
            close();
          }}
          // columns={gridColumns}
          // hoverStateEnabled={true}
          // selectedRowKeys={this.state.gridBoxValue}
          // onSelectionChanged={this.dataGridOnSelectionChanged}
        >
          {columnHeader?.map((reqBy: any) => {
            return (
              <Column
                key={reqBy.id}
                dataType={reqBy.dataType}
                caption={reqBy.caption}
                dataField={reqBy.dataField}
                alignment="left"
                width={reqBy.width === null ? 45 : reqBy.width}
                fixed={reqBy.fixed}
              />
            );
          })}
        </DataGrid>
      );
    };

    return (
      <div
        style={{ width: "730px", minWidth: "730px", height: "100%" }}
        className="    dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base  "
      >
        {/* <h4 className=" w-full  mb-2 bg-slate-100  ">Task Details</h4> */}
        <div
          style={{ maxHeight: "500px", minHeight: "500px", height: "500px" }}
          className=" items-start justify-start "
        >
          <ModalTemplate
            disableCancel={modalInfo.disableCancel}
            icon={modalInfo.icon}
            title={modalInfo.data.title}
            okText={modalInfo.data.okText}
            message={modalInfo.data.message}
            cancelText={modalInfo.data.cancelText}
            open={modalInfo.openModal}
            okHandler={() => {
              modalInfo.data.title === "Update Record?"
                ? update()
                : modalInfo.data.title === "Save Record?"
                ? save()
                : setModalInfo({ ...modalInfo, openModal: false });
            }}
            cancelHandler={() => {
              setModalInfo({ ...modalInfo, openModal: false });
            }}
          />

          <FormModal
          onCancel={()=> setOpenSettingModal(!opensettingModal)}
            openState={opensettingModal}
            child={
              settingModal == formModals[0].label
                ? formModals[0].module
                : settingModal == formModals[1].label
                ? formModals[1].module
                : settingModal == formModals[2].label
                ? formModals[2].module
                : settingModal == formModals[3].label
                ? formModals[3].module
                : settingModal == formModals[4].label
                ? formModals[4].module
                : ""
            }
            title={""}
          />
          <Form
            className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 px-2 "
            name="basic"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            size="small"
            layout="horizontal"
          >
            <Form.Item
              className="mb-1"
              labelCol={{ span: 6 }}
              label={
                <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs mb-1 ">
                  {"Period"}
                </p>
              }
            >
              <div className="flex flex-row items-center dark:bg-slate-900 dark:text-darkModeSkin-base">
                <DropDownBox
                  hoverStateEnabled={true}
                  ref={dropdownRef}
                  className={"border-2 border-red-300"}
                  // value={this.state.gridBoxValue}
                  style={{
                    width: "89%",
                    height: "24px",
                    textAlign: "center",
                    backgroundColor: formData.formDisabled
                      ? "#e2e8f0"
                      : "transparent",
                    border: "1px solid #e5e7eb",
                  }}
                  valueExpr="ID"
                  deferRendering={false}
                  showClearButton={true}
                  openOnFieldClick={true}
                  value={formData.period.name}
                  placeholder={formData.period.name}
                  disabled={formData.formDisabled}
                  contentRender={() =>
                    dataGridRender(periodsData, periods_cols, (e: any) => {
                      console.log(periodsData);
                      // setreqByState([e])
                      // console.log([e])
                      setFormData({
                        ...formData,
                        period: { name: e.prdName, id: e.prdIdpk },
                      });
                      // updateState('reqBy', { id: e.id!, name: e.empName! })
                      // updateState('ack', { id: e.id!, name: e.empName! })
                      // updateState('aprvd', { id: e.id!, name: e.empName! })
                      // updateState('recom', { id: e.id!, name: e.empName! })
                    })
                  }
                />
                <span
                  onClick={() => {
                    setFormData({ ...formData, period: { name: "", id: 0 } });
                  }}
                  className="h-full w-4 ml-1 flex justify-evenly hover:cursor-pointer hover:bg-slate-50 rounded"
                  style={{
                    height: 22.5,
                    marginBottom: 2,
                    borderWidth: 1,
                    width: 24,
                    borderColor: borderTheme,
                  }}
                >
                  <img
                    className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                    src={reset}
                    alt="reset"
                  />
                  {/* <img className='' src= {''} alt="" /> */}
                </span>
              </div>
            </Form.Item>
            {/* <SIA_Details_Select /> */}

            {selects.map(
              (
                {
                  label,
                  optionsData,
                  nameKey,
                  dataExpr,
                  idExpr,
                  diffExprs,
                  style,
                  stateName,
                  defaultValue,
                }: selectsTypes,
                index
              ) => {
                return (
                  // <Form.Item
                  //     label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{label}</p>}
                  //     className={' mb-1'}
                  //     labelCol={{ span: 7 }}

                  // >
                  //     <div className="flex flex-row items-center">

                  //         <Select
                  //             // onChange = {(value:any)=>{dispatch(setciDetailsValue({expr:select.label, value:value}))}}
                  //             className='w-full mr-0'
                  //             placeholder=""
                  //             allowClear={false}
                  //             onSelect={(e) => {
                  //                 console.log(e);
                  //                 console.log(stateName)
                  //                 dispatch(setGeneralValue({ expr: 'siaDepartmentId', value: formData.department.id }));
                  //                 dispatch(setGeneralValue({ expr: 'siaSectionId', value: formData.section.id }));
                  //                 setFormData({ ...formData, [`${stateName}`]: { name: JSON.parse(e)[nameKey!], id: JSON.parse(e)[idExpr!] } })
                  //                 fetchData(`${diffExprs?.valueKey}/${JSON.parse(e)[idExpr!]}`, diffExprs?.idKey)
                  //             }}
                  //             value={dataExpr}
                  //             disabled={formData.formDisabled}
                  //             style={{ width: `${style}`, maxWidth: `${label == "Staff Initiative" && '89%'}` }}

                  //         >
                  //             {optionsData?.map((option: any, index: number) => {
                  //                 return (
                  //                     <option key={index} value={JSON.stringify(option)}>{option[nameKey!]}</option>
                  //                 )
                  //             })}
                  //         </Select>

                  //         <span onClick={() => { runGen(index, ''); }}
                  //             className='h-full w-4 ml-1 flex justify-evenly hover:cursor-pointer hover:bg-slate-50 rounded'
                  //             style={{ height: 22.5, marginBottom: 2, borderWidth: 1, width: 24, borderColor: borderTheme }}
                  //         >
                  //             <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src={reset} alt="reset" />
                  //             {/* <img className='' src= {''} alt="" /> */}
                  //         </span>

                  //         {(label == "Period" || label == "Staff Initiative" || label == "Measure") && (
                  //             <span onClick={() => { runGen(index, ''); label === "Measure" && setSettingModal(formModals[2].label); label === "Staff Initiative" && setSettingModal(formModals[3].label); label === "Period" && setSettingModal(formModals[4].label); setOpenSettingModal(!opensettingModal) }}
                  //                 className='h-full w-4 ml-1 flex justify-evenly hover:cursor-pointer hover:bg-slate-50 rounded'
                  //                 style={{ height: 22.5, marginBottom: 2, borderWidth: 1, width: 24, borderColor: borderTheme }}
                  //             >
                  //                 <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src={setting} alt="reset" />
                  //                 {/* <img className='' src= {''} alt="" /> */}
                  //             </span>
                  //         )}

                  //     </div>
                  // </Form.Item>

                  <>
                    <SelectsTemplate
                      key={nameKey}
                      label={label}
                      selectStyle={{ width: style }}
                      disabled={form_disable}
                      options={optionsData}
                      placeHolder={defaultValue}
                      index={idExpr}
                      dataexp={dataExpr}
                      useCallFunc
                      selectedValue={(e) => {
                        setFormData({
                          ...formData,
                          department: {
                            name: e.dataExpr,
                            id: e.idExpr,
                          },
                        });
                      }}
                    />
                  </>
                );
              }
            )}

            <Form.Item
              label={
                <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                  {"Target"}
                </p>
              }
              className={" mb-1"}
              labelCol={{ span: 6 }}
            >
              <div className="flex flex-row items-center justify-between mr-1">
                <div className="flex items-center">
                  <Select
                    // onChange = {(value:any)=>{dispatch(setciDetailsValue({expr:select.label, value:value}))}}
                    className="w-full"
                    placeholder=""
                    allowClear
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        target: {
                          name: JSON.parse(e).name,
                          id: JSON.parse(e).id,
                        },
                      });
                    }}
                    style={{ width: "240px" }}
                    value={formData.target.name}
                    disabled={formData.formDisabled}
                  >
                    {targetData?.map((option: any, index: number) => {
                      return (
                        <option key={index} value={JSON.stringify(option)}>
                          {option["name"]}
                        </option>
                      );
                    })}
                  </Select>

                  <span
                    onClick={() => {
                      setFormData({ ...formData, target: { name: "", id: 0 } });
                    }}
                    style={{
                      height: 20.5,
                      marginBottom: 2,
                      borderWidth: 1,
                      width: 20,
                      borderColor: borderTheme,
                    }}
                    className="h-full w-4 ml-1 flex justify-evenly hover:cursor-pointer hover:bg-slate-50 rounded "
                  >
                    <img
                      className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                      src={reset}
                      alt="reset"
                    />
                    {/* <img className='' src= {''} alt="" /> */}
                  </span>
                </div>
                <li className=" flex items-center flex-row space-x-[2px] ml-2.5 ">
                  {" "}
                  <p className="mr-2 text-xs">Targ. Freq :</p>
                  <Input
                    className="w-12 "
                    value={formData.targetFreq}
                    disabled={formData.formDisabled}
                    onChange={(e) => {
                      setFormData({ ...formData, targetFreq: e.target.value });
                    }}
                  />
                  <p className=" ">
                    <Checkbox
                      style={{ borderColor: borderTheme }}
                      className="border rounded px-1 ml-9"
                      disabled={formData.formDisabled}
                      checked={formData.applyTargetFreq}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          applyTargetFreq: e.target.checked,
                        });
                      }}
                    />
                  </p>
                  <span
                    onClick={() => {}}
                    className="  flex justify-center hover:cursor-pointer hover:bg-slate-50 rounded "
                    style={{
                      height: 22.5,
                      marginBottom: 2,
                      borderWidth: 1,
                      width: 26,
                      borderColor: borderTheme,
                    }}
                  >
                    <img
                      className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                      src={reset}
                      alt="reset"
                    />
                    {/* <img className='' src= {''} alt="" /> */}
                  </span>
                  <span
                    onClick={() => {
                      setSettingModal(formModals[1].label);
                      setOpenSettingModal(!opensettingModal);
                    }}
                    className=" h-full w-4  flex justify-evenly hover:cursor-pointer hover:bg-slate-50 rounded"
                    style={{
                      height: 22.5,
                      marginBottom: 2,
                      borderWidth: 1,
                      width: 24,
                      borderColor: borderTheme,
                    }}
                  >
                    <img
                      className="w-full hover:bg-blue-50 h-full flex  items-center justify-center"
                      src={setting}
                      alt="reset"
                    />
                    {/* <img className='' src= {''} alt="" /> */}
                  </span>
                </li>
              </div>
            </Form.Item>

            {/* <div style={{ width: 'calc(100% - 132px)', marginLeft: '77px'}} className=' flex  justify-between  mb-0.5 '>
                          <li style={{ width: '680px',  }} className=' flex items-start flex-row '><p style={{ width: '180px' }} className=' text-xs flex items-center flex-row '>Target % To Complete : </p><Slider className='w-full' min={0} max={100} onChange={(e) => {
                              setFormData({ ...formData, targetPercent: e })
                          }} value={formData.targetPercent} disabled={formData.formDisabled} />  </li>
                          <li style={{ width: '50px', }} className=' flex items-center flex-row justify-end '> <InputNumber disabled={formData.formDisabled} className='w-12 ml-2' min={0} max={100} value={formData.targetPercent}
                              onChange={(e) => {
  
                                  setFormData({ ...formData, targetPercent: e })
                                  console.log(e)
                              }}
                          /> </li>
  
  
                      </div> */}
            <Form.Item
              labelCol={{ span: 6 }}
              className=""
              label={
                <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                  {"Target % To Complete "}
                </p>
              }
            >
              <div className="w-[89%] flex dark:bg-slate-100 dark:text-darkModeSkin-base">
                <Slider
                  style={{ width: "100%" }}
                  className="mr-5"
                  min={0}
                  max={100}
                  onChange={(e) => {
                    setFormData({ ...formData, targetPercent: e });
                  }}
                  value={formData.targetPercent}
                />
                <InputNumber
                  className="h-6 w-[62px] flex items-center justify-center mt-0.5+"
                  size="small"
                  min={0}
                  max={100}
                  // style={{ margin: '0 16px' }}
                  value={formData.targetPercent}
                  onChange={(e) => {
                    setFormData({ ...formData, targetPercent: e });
                    console.log(e);
                  }}
                />
              </div>
            </Form.Item>
            <Form.Item
              key={""}
              label={
                <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                  {"Monitor Results From"}
                </p>
              }
              className={" mb-1"}
              labelCol={{ span: 6 }}
            >
              <div className="flex flex-row items-center">
                <Select
                  // onChange = {(value:any)=>{dispatch(setciDetailsValue({expr:select.label, value:value}))}}
                  className="w-full mr-0"
                  placeholder=""
                  allowClear
                  value={formData.monitorResults.name}
                  onChange={(e) => {
                    console.log(e);
                    setFormData({
                      ...formData,
                      monitorResults: {
                        name: JSON.parse(e).name,
                        id: JSON.parse(e).id,
                      },
                    });
                  }}
                  disabled={formData.formDisabled}
                  style={{ width: "89%" }}
                >
                  {resultsData?.map((option: any, index: number) => {
                    return (
                      <option key={index} value={JSON.stringify(option)}>
                        {option["name"]}
                      </option>
                    );
                  })}
                </Select>
                <span
                  onClick={() => {
                    setFormData({
                      ...formData,
                      monitorResults: { name: "", id: 0 },
                    });
                  }}
                  style={{
                    height: 22.5,
                    marginBottom: 2,
                    borderWidth: 1,
                    width: 24,
                    borderColor: borderTheme,
                  }}
                  className="h-full w-4 ml-1 flex justify-evenly hover:cursor-pointer hover:bg-slate-50 rounded"
                >
                  <img
                    className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                    src={reset}
                    alt="reset"
                  />
                  {/* <img className='' src= {''} alt="" /> */}
                </span>
                <span
                  onClick={() => {
                    setSettingModal(formModals[0].label);
                    setOpenSettingModal(!opensettingModal);
                  }}
                  className="h-full w-4 ml-1 flex justify-evenly hover:cursor-pointer hover:bg-slate-50 rounded"
                  style={{
                    height: 22.5,
                    marginBottom: 2,
                    borderWidth: 1,
                    width: 24,
                    borderColor: borderTheme,
                  }}
                >
                  <img
                    className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                    src={setting}
                    alt="reset"
                  />
                  {/* <img className='' src= {''} alt="" /> */}
                </span>
              </div>
            </Form.Item>

            <div
              style={{ width: "83.5%", marginLeft: "95px" }}
              className=" flex  justify-between mb-0.5 "
            >
              {/* <li className='flex justify-start '><Cancel/></li> */}
              <li className=" flex items-center flex-row">
                <p className="mr-2 text-xs">Compulsory? :</p>{" "}
                <Checkbox
                  style={{ borderColor: borderTheme }}
                  className="border rounded px-1"
                  disabled={formData.formDisabled}
                  checked={formData.compulsory}
                  onChange={(e) => {
                    setFormData({ ...formData, compulsory: e.target.checked });
                  }}
                />{" "}
              </li>
              <li className=" flex items-center flex-row pr-2">
                <p className="mr-2 text-xs">Weight :</p>{" "}
                <Input
                  disabled={formData.formDisabled}
                  className="w-16 "
                  value={formData.weight}
                  onChange={(e) => {
                    setFormData({ ...formData, weight: e.target.value });
                  }}
                />
                <span
                  onClick={() => {}}
                  className="h-full w-4 ml-1 flex justify-evenly hover:cursor-pointer hover:bg-slate-50 rounded"
                  style={{
                    height: 22.5,
                    marginBottom: 2,
                    borderWidth: 1,
                    width: 24,
                    borderColor: borderTheme,
                  }}
                >
                  <img
                    className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                    src={reset}
                    alt="reset"
                  />
                  {/* <img className='' src= {''} alt="" /> */}
                </span>
              </li>
            </div>

            <div
              style={{ width: "94.5%", marginLeft: "4px" }}
              className=" flex  justify-between mb-0.5"
            >
              {/* <li className='flex justify-start '><Cancel/></li> */}
              <li className=" flex items-center flex-row">
                <p className="mr-2 text-xs">Allow Actuals Manual Update? :</p>{" "}
                <Checkbox
                  style={{ borderColor: borderTheme }}
                  className="border rounded px-1"
                  disabled={formData.formDisabled}
                  checked={formData.allowManualUpdate}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      allowManualUpdate: e.target.checked,
                    });
                  }}
                />{" "}
              </li>
              <li className=" flex items-center flex-row mr-6">
                <p className="mr-2 text-xs">Order :</p>{" "}
                <Input
                  disabled={formData.formDisabled}
                  className="w-16 "
                  value={formData.order}
                  onChange={(e) => {
                    setFormData({ ...formData, order: e.target.value });
                  }}
                />{" "}
              </li>
            </div>

            <div
              style={{ width: "74%", marginLeft: "126px" }}
              className=" flex  justify-between "
            >
              {/* <li className='flex justify-start '><Cancel/></li> */}
              <li className=" flex items-center flex-row">
                <p className="mr-2 text-xs">Active? : </p>{" "}
                <Checkbox
                  style={{ borderColor: borderTheme }}
                  className="border rounded px-1"
                  disabled={formData.formDisabled}
                  checked={formData.active}
                  onChange={(e) => {
                    setFormData({ ...formData, active: e.target.checked });

                    dispatch(
                      setGeneralValue({
                        expr: "siaActive",
                        value: e.target.checked,
                      })
                    );
                  }}
                />
              </li>
              <ul className="flex justify-end  ">
                {formData.updateMode ? (
                  <UpdateButton
                    handleUpdate={() => {
                      validate("update");
                    }}
                    useCallbackFunc
                  />
                ) : (
                  <SaveButton
                    disableButton={formData.formDisabled}
                    useCallbackFunc
                    handleSave={() => {
                      validate("save");
                    }}
                  />
                )}
                {formData.formDisabled ? (
                  <NewButton
                    useCallbackFunc
                    new_button_toggler={() => {
                      clearFields();
                      onEditModeGrid!();
                    }}
                  />
                ) : (
                  <Cancel
                    useCallbackFunc
                    cancel_button_toggler={() => {
                      setFormData({
                        ...formData,
                        formDisabled: true,
                        updateMode: false,
                      });
                      dispatch(task_form_disable(true));
                      // dispatch(update_route_state(false))
                      dispatch(task_footer_update(false));
                      onDisabledModeGrid!();

                      // dispatch(settask_footer_update(false))
                      dispatch(
                        setGeneralValue({ expr: "updateMode", value: false })
                      );
                    }}
                  />
                )}
              </ul>
            </div>
          </Form>
        </div>
      </div>
    );
  }
);

const assignment_data = [
  { id: 0, value: "Staff" },
  { id: 1, value: "Corporate" },
  { id: 2, value: "Department" },
  { id: 3, value: "Section" },
  { id: 4, value: "Unit" },
];
