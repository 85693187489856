import { forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';
import { setGeneralValue } from '../../../../features/generalSlice';
import useFetch from '../../../../hooks/useFetch';
import  Datagrid_template from '../../../../templates/Datagrid';
import { navbar_height,  toolbar_height } from '../../../accessories/component_infos';
import CustomLoader from '../../../accessories/custom-loader';
import { HrSetupsCustomToolbar } from '../../../accessories/custom-toolbars/hrSetupsCustomtoolbar';
import { Hr_Setups_Datagrid_Template } from '../../../human-resource/setups/data/hr_setup_datagid_template';
import { PerformanceSetups_monitoringModules_columns } from './data/monitoringModulesData';
import { MonitoringModules_form } from './widgets/monitoringModulesForm';
import { searchTextTypes, statusTypes } from '../../../human-resource/setups/data/types/selectsTypes';
import { RootState } from '../../../../app/store';
import { WarningAlert } from '../../../accessories/warningAlert';
import { useBusyLoader } from '../../../../hooks/useBusyLoader';
import { current } from '@reduxjs/toolkit';



export const PerformanceSetupsMonitoringModules = forwardRef(({a}:any, ref)=>{
    const news_flash_height = useSelector((state:RootState)=>state.general.newsflashHeight);
    const dispatch = useDispatch();
    // on page resize height responsiveness
    const [trigger,setTrigger] = useState<boolean>(false);
    useImperativeHandle(ref, () => ({
        test: () => {
            setTrigger(!trigger);
        }
    }));

    
    const allowUpdate = ()=>{
        dispatch(setGeneralValue({ expr: "updateMode", value: true }));
        dispatch(setGeneralValue({ expr: "formDisabled", value: false}));
    }

    const[SelectedFielld,setSelectedFielld] = useState({});
    const[busyLoader, setBusyLoader] = useBusyLoader();

    //navigation
    const setupPageRoute = window.location.pathname; //get current route of page
    const datagridOnlySearchParam = 'table-only'  //search param in datagrid mode
    const formModeSearchParam = 'form-and-table' //search param in form mode mode
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    //miscellaneous heights
    const miscHeight = 7;
    
    // All heights out
    const heights_out = navbar_height + news_flash_height + toolbar_height;

    //varying form height
    const [form_height,set_form_height] = useState<number>(298);
    const pass_form_height=(height:number)=>{
        set_form_height(height)
    }

    // Page resize height responsiveness
    useEffect(()=>{
        settableHeight(window.innerHeight - (heights_out + miscHeight -4));
    },[])

    // handle interface switch
    const toggle_staff_form = ():void =>{
        const view = searchParams.get('view');

        (view === null || view === formModeSearchParam) ? 
        navigate({
            pathname : setupPageRoute,
            search : createSearchParams({
                view : datagridOnlySearchParam,
            }).toString(),
        })
        :
        navigate({
            pathname : setupPageRoute,
            search : createSearchParams({
                view : formModeSearchParam,
            }).toString(),
        });
    }
    const[refresh,setRefresh]=useState(false)

    //Table Height
    const pageHeight = window.innerHeight - (navbar_height + miscHeight) ; //height without navbar
    const [tableHeight,settableHeight] = useState(window.innerHeight); //height without navbar and toolbar


    const [searchText, setSearchText] = useState<searchTextTypes>({temp:'', text:''})
    const [status, setStatus] = useState<statusTypes>({temp:'', status:''})
    //fetch data
    const [data,error,loading] = useFetch(`ResultMonitoring/SearchResultMonitoringModule?resultMonitoringMod=${searchText.text}&activeState=${status.status}`,refresh,'rmmIdpk');
    useEffect(()=>{
        loading ? setBusyLoader('.') : setBusyLoader('');
    },[loading])

    const dataGridRowClick = (selectedFieldData:any)=>{
        setSelectedFielld(selectedFieldData);
        dispatch(setGeneralValue({expr:'selectedFieldData', value: selectedFieldData}))

        //disable forms
        dispatch(setGeneralValue({expr: 'formDisabled' ,value : true}));
        dispatch(setGeneralValue({expr: 'updateMode' ,value : false}));
    }

    return (
    <>
        {error &&(
            <WarningAlert/>
        )}

        <div style={{height : pageHeight}} className="w-full">           
            <div className="px-2">
                <HrSetupsCustomToolbar
                handleNew={()=> setSelectedFielld({})}
                outerSelected={SelectedFielld}
                searchTextOnchange={(newText)=>{
                    setSearchText({temp:newText, text:searchText.text})
                }}
                checkOnChange={(newStatus) =>{
                    setStatus({temp:newStatus, status:status.status})
                }}
                handleFind={() =>{
                    setSearchText({temp:searchText.temp, text:searchText.temp});
                    setStatus({temp:status.temp, status:status.temp})
                    setRefresh(current=> !current)
                }}
                withDates={false}
                toggler={toggle_staff_form}/>
            </div>
            
            {/* form section */}
            {(searchParams.get('view') !== datagridOnlySearchParam) &&
            <section style={{height : form_height}}  className="form border-b-4">
                <MonitoringModules_form 
                refreshGrid={()=> setRefresh(current => !current)}
                selectedItemData={SelectedFielld}/>
            </section>}

            {/* datagrid section */}
            <section className="mt-1 px-2">
                <Datagrid_template 
                    rowDoubleClicked={allowUpdate} 
                    onRowClick={dataGridRowClick}  
                    gridheight= {searchParams.get('view') === formModeSearchParam || searchParams.get('view') === (null) ?  window.innerHeight - (form_height + heights_out + miscHeight) : tableHeight} 
                    data = {data} 
                    columns = {PerformanceSetups_monitoringModules_columns}
                    disableSearch
                    />
            </section>
        </div>
    </> 
    );
})