/* eslint-disable jsx-a11y/alt-text */
import { Button, Divider, Popover, Tooltip } from "antd"
import designCol from '../../../../../../../../assets/designCol.png';
import dropdown from '../../../../../../../../assets/dropdown.png';
import linegraph from '../../../../../../../../assets/linegraph.png';
import piechart from '../../../../../../../../assets/piechart.png';
import barchart from '../../../../../../../../assets/barchart.png';
import areachart from '../../../../../../../../assets/areachart.png';
import otherchart from '../../../../../../../../assets/otherchart.png';
import colors from '../../../../../../../../assets/colors.png';
import steps from '../../../../../../../../assets/steps.png';
import stackline from '../../../../../../../../assets/stackline.png';
import line1 from '../../../../../../../../assets/line1.png';
import spline from '../../../../../../../../assets/spline.png';
import dline from '../../../../../../../../assets/3dline.png';
import dstepline from '../../../../../../../../assets/3dstepline.png';
import dstackline from '../../../../../../../../assets/3dstackedline.png';

interface props {
    toggler ?: ()=>void;
    updateStates ?: (key:string, value:any)=>void
}


export const DesignTab = ({toggler,updateStates}:props)=>{

const lineContent = [
    {id:0, label: 'Line', imgName : line1, columnImg : dropdown, target: '#Line' , type : 'line', message: <div><h4>Line</h4> <p>Displays series points in the same order as they appear in the collection</p></div>  },
    {id:1, label: 'StackedLine', imgName : stackline, columnImg : dropdown, target: '#StackedLine',type : 'stackedline', message: <div><h4>Stacked Line</h4> <p>Displays series points in the same order as they appear in the collection</p></div>   },
    {id:2, label: 'scatter', imgName : piechart, columnImg : dropdown,target: '#scatter', type : 'scatter', message: <div><h4>Scatter</h4> <p>Displays series points in the same order as they appear in the collection</p></div>   },
    {id:3, label: 'Spline', imgName : spline, columnImg : dropdown,target: '#Spline',type : 'spline', message: <div><h4>Spline</h4> <p>Displays series points in the same order as they appear in the collection</p></div>    },
    {id:4, label: 'Area', imgName : areachart, columnImg : dropdown,target: '#Area',type : 'area', message: <div><h4>Area</h4> <p>Displays series points in the same order as they appear in the collection</p></div>   },
    {id:5, label: 'StepLine', imgName : steps, columnImg : dropdown,target: '#StepLine',type : 'stepline',  message: <div><h4>Step Line</h4> <p>Displays series points in the same order as they appear in the collection</p></div>    },

]

const DlineContent = [
    {id:0, label: '3DLine', imgName : dline, columnImg : dropdown, target: '#3DLine' , type : 'line', message: <div><h4>Line</h4> <p>Displays series points in the same order as they appear in the collection</p></div>  },
    {id:1, label: '3DStackedLine', imgName : dstackline, columnImg : dropdown, target: '#3DStackedLine',type : 'stackedline', message: <div><h4>Stacked Line</h4> <p>Displays series points in the same order as they appear in the collection</p></div>   },
    {id:2, label: '3DSpline', imgName : spline, columnImg : dropdown,target: '#3DSpline',type : 'spline', message: <div><h4>Spline</h4> <p>Displays series points in the same order as they appear in the collection</p></div>    },
    {id:3, label: '3DStepLine', imgName : dstepline, columnImg : dropdown,target: '#3DStepLine',type : 'stepline',  message: <div><h4>Step Line</h4> <p>Displays series points in the same order as they appear in the collection</p></div>    },
]

const content = (
     <div className="flex flex-wrap w-56 h-56 justify-evenly">
       
       
                {lineContent.map((contents:any, index:number)=>{
                    return(
                        <div className="m-0" >
                            <Tooltip placement="rightBottom"   title={contents.message} >
                                <Button
                                    onClick={()=>{
                                        if(updateStates !== undefined){
                                            updateStates('chartType', contents.type)
                                        }
                                    }} 
                                 id={contents.label} style={{width:'70px', height: '40px'}} className="" size={'middle'}  >
                                    <img className="flex justify-center items-center h-full" src={contents.imgName} />

                                </Button>
                            </Tooltip>
                                    
                        </div>
                            )
                        })}
            <Divider orientation="left"> 3D-Line </Divider>
                {DlineContent.map((contents:any, index:number)=>{
                    return(
                        <div className="m-0" >
                            <Button style={{width:'70px', height: '40px'}} className="" size={'middle'}  >
                                <img className="flex  items-center h-full" src={contents.imgName} />

                            </Button>
                                    
                        </div>
                            )
                        })}
            
           

        
    </div>
  );

const BarContentx = [
    {id:0, label: 'Bar', imgName : barchart, columnImg : dropdown, target: '#Bar' , type : 'bar', message: <div><h4>Bar</h4> <p>Displays series points in the same order as they appear in the collection</p></div>  },
    {id:1, label: 'StackedBar', imgName : barchart, columnImg : dropdown, target: '#StackedBar',type : 'stackedbar', message: <div><h4>Stacked Bar</h4> <p>Displays series points in the same order as they appear in the collection</p></div>   },
    {id:2, label: 'Clustered Stacked Bar', imgName : barchart, columnImg : dropdown,target: '#clustered', type : 'fullstackedbar', message: <div><h4>Clustered 100% Stacked Bar</h4> <p>Displays series points in the same order as they appear in the collection</p></div>   },
    {id:3, label: 'Spline', imgName : barchart, columnImg : dropdown,target: '#Spline',type : 'spline', message: <div><h4>Spline</h4> <p>Displays series points in the same order as they appear in the collection</p></div>    },


]



const Barcontent = (
     <div className="flex flex-wrap w-40 h-36 justify-evenly">
       
       
                {BarContentx.map((contents:any, index:number)=>{
                    return(
                        <div className="m-0" >
                            <Tooltip placement="rightBottom"   title={contents.message} >
                                <Button
                                    onClick={()=>{
                                        if(updateStates !== undefined){
                                            updateStates('chartType', contents.type)
                                        }
                                    }} 
                                 id={contents.label} style={{width:'70px', height: '40px'}} className="" size={'middle'}  >
                                    <img className="flex justify-center items-center h-full" src={contents.imgName} />

                                </Button>
                            </Tooltip>
                                    
                        </div>
                            )
                        })}
            
            
           

        
    </div>
  );

const headers = [
    {id:0, label: 'Column', imgName : designCol, columnImg : dropdown, title : '2-D Column', popover : content    },
    {id:1, label: 'Line', imgName : linegraph, columnImg : dropdown, title : '2-D Line',popover : content    },
    {id:2, label: 'Pie', imgName : piechart, columnImg : dropdown, title : '2-D Pie', popover : content   },
    {id:3, label: 'Bar', imgName : barchart, columnImg : dropdown,  title : '2-D Bar' , popover : Barcontent  },
    {id:4, label: 'Area', imgName : areachart, columnImg : dropdown, title : '2-D Area',popover : content   },
    {id:5, label: 'Other Charts', imgName : otherchart, columnImg : dropdown, title : '2-D Column',   },
    
]

const right_headers = [
    {id:0, label: 'Palette', imgName : colors, columnImg : dropdown,   },
]

    return (
        <div className='h-full dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base '>

            <div style={{height:'100px'}} className="w-full h-full flex justify-between">
                <div style={{borderRightWidth: '1px',}}  className="w-2/5 mb-3  p-2 flex items-center justify-evenly ">
                    
                    {headers.map((headings:any, index:number)=>{
                        return(
                            <div >
                                <Popover  placement="bottom" style={{width:'100%', height: '100%', borderWidth:'1px solid black' }} className="" content={headings.popover} title={headings.title} trigger="click">
                                    <Button
                                    style={{width:'80px', height: '50px'}} className="" size={'middle'}  >
                                        <img className="flex justify-center items-center h-full" src={headings.imgName} />
                                        <p  className="flex  justify-center items-center py-0.5">{headings.label}</p>
                                        <span className="flex justify-center items-center"><img src={headings.columnImg} /></span>
                                        
                                    </Button>
                                </Popover>
                    </div>
                        )
                    })}
                    
                </div>

                <div className="w-3/5 mb-3 justify-start  p-2 flex items-center ">
                    <div >
                        {right_headers.map((headings:any, index:number)=>{
                                return(
                                    <div >
                                        <Popover style={{width:'100%', height: '100%', borderWidth:'1px solid black' }} className="" content={content} title={headings.title} trigger="click">
                                            <Button style={{width:'80px', height: '50px'}} className="" size={'middle'}  >
                                                <img className="flex justify-center items-center h-full" src={headings.imgName} />
                                                <p  className="flex  justify-center items-center py-0.5">{headings.label}</p>
                                                <span className="flex justify-center items-center"><img src={headings.columnImg} /></span>
                                                
                                            </Button>
                                        </Popover>
                            </div>
                                )
                            })}
                    </div>
                    <div style={{borderRightWidth: '1px',}}>

                    </div>

                </div>

            </div>

        </div>
    )
}