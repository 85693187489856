/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import { BottomBtns } from "../../../../templates/butttons";
import { CheckboxTemlate } from "../../../../templates/checkbox";
import { InputsTemplate } from "../../../../templates/input";
import { Form } from "antd";
import { selectsTypes } from "../../../human-resource/setups/data/types/selectsTypes";
import { SelectsTemplate } from "../../../../templates/select";
import InputSelect_template from "../../../../templates/InputSelect";
import useFetch from "../../../../hooks/useFetch";
import { useDispatch } from "react-redux";
import { setGeneralValue } from "../../../../features/generalSlice";

import {
  currentDate,
  saveModalData,
  updateModalData,
  useModalData,
} from "../../../accessories/component_infos";
import { ModalTemplate, ModalTemplate as SecondModalTemplate } from "../../../../templates/modal";
import { PopGdvApplicationModule } from "../../../../interfaces";
import { useCrudFunc } from "../../../../functions/crud";
import { useAccountInfo } from "../../../../hooks/useAccountInfo";
import dayjs from "dayjs";
// import { updateData } from "@syncfusion/ej2-react-grids";

interface props {
  selectedRecordx?: PopGdvApplicationModule;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>
  remarks: string;
}
interface stateTypes {
  project: { id: number; name: string };
  module: { id: number; name: string };
  shortName: { id: number; name: string };
  code: string;
  order: number;
  hmo: number;
  active: boolean;
  helpModule: boolean;
}

export const AapplicationMod_details = ({
  selectedRecordx,
  setRefresh,
  remarks,
}: props) => {

  const [modal, setModal] = useModalData();

  const dispatch = useDispatch();
  const [PostDataFunc, UpdateDataFunc] = useCrudFunc();
  const [userId] = useAccountInfo();

  const [states, setStates] = useState<stateTypes>({
    project: { id: 0, name: "" },
    module: { id: 0, name: "" },
    shortName: { id: 0, name: "" },
    code: "",
    order: 0,
    hmo: 0,
    active: false,
    helpModule: false,
  });

  const updateStates = (key: string, value: any) => {
    setStates((prev: any) => ({ ...prev, [key]: value }));
  };

  //States for modal
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();

  // API--------------------
  const [proData, proErr, proLaoding] = useFetch(
    `SwdApplicationModule/PopLueProject`
  );
  const [modData, modErr, modLaoding] = useFetch(
    `SwdApplicationModule/PopLueModuleName`
  );

  const populateFields = (clear?: boolean) => {
    const gridStates = [
      {
        key: "project",
        value: clear
          ? ""
          : {
            id: selectedRecordx?.prjIDpk ?? 0,
            name: selectedRecordx?.prjShtName,
          },
      },
      {
        key: "module",
        value: clear ? "" : { id: 1, name: selectedRecordx?.apmName },
      },
      {
        key: "shortName",
        value: clear ? "" : { id: 1, name: selectedRecordx?.apmShtName },
      },
      { key: "code", value: clear ? "" : selectedRecordx?.apmCode },
      { key: "order", value: clear ? "" : selectedRecordx?.apmOrder },
      { key: "hmo", value: clear ? "" : selectedRecordx?.apmHelpModuleOrder },
      { key: "active", value: clear ? false : selectedRecordx?.apmActive },
      {
        key: "helpModule",
        value: clear ? false : selectedRecordx?.apmIsHelpModule,
      },
    ];

    for (let i of gridStates) updateStates(i.key, i.value);
  };

  useEffect(() => {
    populateFields(false);
  }, [selectedRecordx]);

  const selects: selectsTypes[] = [
    {
      id: 0,
      label: "Project",
      idExpr: "prjIDpk",
      dataExpr: "prjName",
      optionsData: proData,
      stateName: "project",
      defaultValue: states.project.name,
    },
    {
      id: 1,
      label: "Module",
      idExpr: "apmIDpk",
      dataExpr: "apmName",
      optionsData: modData,
      stateName: "module",
      defaultValue: states.module.name,
    },
    {
      id: 2,
      label: "Short Name",
      idExpr: "apmIDpk",
      dataExpr: "apmShtName",
      optionsData: modData,
      stateName: "shortName",
      defaultValue: states.shortName.name,
    },
  ];

  // Validation
  const validation = async (method: "post" | "put") => {
    if (states.project.id === 0) {
      setModal({
        state: true,
        title: "Field Required!",
        message: "Please select project to continue",
        icon: "warning",
        okText: "OK",
        cancelText: "No",
        disableCancel: true,
        okHandler: () => {
            setModal(prev => ({
                ...prev,
                state: false,

            }))
        },
        cancelHandler: () => {
            setModal(prev => ({
                ...prev,
                state: false,

            }))
        }
    })
      return;
    } else if (states.module.id === 0) {
      setModal({
        state: true,
        title: "Field Required!",
        message: "Please select module to continue",
        icon: "warning",
        okText: "OK",
        cancelText: "No",
        disableCancel: true,
        okHandler: () => {
            setModal(prev => ({
                ...prev,
                state: false,

            }))
        },
        cancelHandler: () => {
            setModal(prev => ({
                ...prev,
                state: false,

            }))
        }
    })
      return;
    } else if (states.shortName.name === "") {
      setModal({
        state: true,
        title: "Field Required!",
        message: "Please select short name to continue",
        icon: "warning",
        okText: "OK",
        cancelText: "No",
        disableCancel: true,
        okHandler: () => {
            setModal(prev => ({
                ...prev,
                state: false,

            }))
        },
        cancelHandler: () => {
            setModal(prev => ({
                ...prev,
                state: false,

            }))
        }
    })
      return;
    } else if (states.code === "") {
      setModal({
        state: true,
        title: "Field Required!",
        message: "Please enter code to continue",
        icon: "warning",
        okText: "OK",
        cancelText: "No",
        disableCancel: true,
        okHandler: () => {
            setModal(prev => ({
                ...prev,
                state: false,

            }))
        },
        cancelHandler: () => {
            setModal(prev => ({
                ...prev,
                state: false,

            }))
        }
    })
      return;
    } else {
      if (method === "post") {
        await SaveApplicationModule();
      } else {
        await UpdateApplicationModule();
      }
    }
  };

  const SaveApplicationModule = async () => {
    setModal({
      state: true,
      title: "Save Application Module",
      message: "Are you sure you want to save?",
      icon: "question",
      okText: "Yes",
      cancelText: "No",
      disableCancel: false,
      okHandler: async () => {
        setModal(prev => ({
          ...prev,
          state: false,

        }))
        await PostData()
      },
      cancelHandler: () => {
        setModal(prev => ({
          ...prev,
          state: false,

        }))
      }
    })
  }

  const UpdateData = async () => {
    try {
      const res = await UpdateDataFunc(`SwdApplicationModule/UpdateApplicationModule`, {
        apmIdpk: selectedRecordx?.apmIDpk ?? 0,
        apmName: states.module.name,
        apmShtName: states.shortName.name,
        apmProjectIdfk: states.project.id,
        apmActive: states.active,
        apmCode: states.code,
        apmOrder: states.order,
        apmIsHelpModule: states.helpModule,
        apmHelpModuleOrder: states.hmo,
        apmRmks: remarks,
      },
      `Update the Application Modules with apmCode:${states.code}, apmName:${states.module.name}, apmShtName:${states.shortName.name} Date Edited:${currentDate}. Edited By:${userId}`
      
      );

      if (res.status) {
        setModal({
          state: true,
          title: "Update Record",
          message: "You have successfully updated the record",
          icon: "success",
          okText: "OK",
          cancelText: "No",
          disableCancel: true,
          okHandler: () => {
            setModal(prev => ({
              ...prev,
              state: false,

            }))
          },
          cancelHandler: () => {
            setModal(prev => ({
              ...prev,
              state: false,

            }))
          }
        })
        dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
        setRefresh((prev) => !prev);
      }

    } catch (err) {
      setModal({
        state: true,
        title: "Error",
        message: "Error updating record. Please retry or contact your Systems Administrator",
        icon: "warning",
        okText: "OK",
        cancelText: "No",
        disableCancel: true,
        okHandler: () => {
          setModal(prev => ({
            ...prev,
            state: false,

          }))
        },
        cancelHandler: () => {
          setModal(prev => ({
            ...prev,
            state: false,

          }))
        }
      })
    }
  };
  const UpdateApplicationModule = async () => {

    setModal({
      state: true,
      title: "Update application module",
      message: "Are you sure you want to update the selected application module?",
      icon: "question",
      okText: "Yes",
      cancelText: "No",
      disableCancel: false,
      okHandler: async () => {
        setModal(prev => ({
          ...prev,
          state: false,

        }))
        await UpdateData()
      },
      cancelHandler: () => {
        setModal(prev => ({
          ...prev,
          state: false,

        }))
      }
    })
  }


  const PostData = async () => {
    try {
      const res = await PostDataFunc(`SwdApplicationModule/InsertApplicationModule`, {
        apmName: states.module.name,
        apmShtName: states.shortName.name,
        apmCode: states.code,
        apmProjectIDfk: states.project.id,
        apmOrder: states.order,
        apmIsHelpModule: states.helpModule,
        apmHelpModuleOrder: states.hmo,
        apmActive: true,
        apmRmks: remarks,
      },
      `Created the Application Modules with apmCode:${states.code}, apmName:${states.module.name}, apmShtName:${states.shortName.name} Date Created:${currentDate}. created By:${userId}`
      );

      if (res.status) {

        setModal({
          state: true,
          title: "Save Record",
          message: "You have successfully saved the record",
          icon: "success",
          okText: "Yes",
          cancelText: "No",
          disableCancel: true,
          okHandler: async () => {
            setModal(prev => ({
              ...prev,
              state: false,

            }))
          },
          cancelHandler: () => {
            setModal(prev => ({
              ...prev,
              state: false,

            }))
          }
        })

        dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
        setRefresh((prev) => !prev);
      }


    } catch (err: any) {
      setModal({
        state: true,
        title: "Error",
        message: "Error saving record. Please retry or contact your Systems Administrator",
        icon: "warning",
        okText: "OK",
        cancelText: "No",
        disableCancel: true,
        okHandler: () => {
          setModal(prev => ({
            ...prev,
            state: false,

          }))
        },
        cancelHandler: () => {
          setModal(prev => ({
            ...prev,
            state: false,

          }))
        }
      })
    };




  }

  return (
    <div
      style={{ width: "450px", minWidth: "450px", height: "100%" }}
      className="    dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base  "
    >

      
    {/* <ModalTemplate
      icon={icon}
      cancelText={modalData.cancelText}
      disableCancel={icon === "warning" ? false : true}
      cancelHandler={() => {
        setOpenModal(false);
      }}
      open={openModal}
      okHandler={() => {
        modalData.title === saveModalData.title
          ? postData()
          : modalData.title === updateModalData.title
            ? updateData()
            : setOpenModal(false);
      }}
      message={modalData.message}
      okText={modalData.okText}
      title={modalData.title}
    /> */}

      <SecondModalTemplate
        width={400}
        icon={modal.icon}
        disableCancel={modal.disableCancel}
        cancelText={modal.cancelText}
        open={modal.state}
        okHandler={modal.okHandler}
        cancelHandler={modal.cancelHandler}
        message={modal.message}
        okText={modal.okText}
        title={modal.title}
      />

      <div
        style={{ maxHeight: "220px", minHeight: "220px", height: "220px" }}
        className=" items-start justify-start "
      >
        <Form
          className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 px-2 "
          name="basic"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 23 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          size="small"
          layout="horizontal"
          disabled={false}
        >
          {selects.map(
            ({
              id,
              label,
              idExpr,
              optionsData,
              dataExpr,
              stateName,
              defaultValue,
              style,
            }) => {
              return (
                <div key={id} className="">
                  {id !== 0 ? (
                    <InputSelect_template
                      placeHolder={defaultValue}
                      label={label}
                      dataexp={dataExpr}
                      idexpr={idExpr}
                      options={optionsData}
                      selectStyle={
                        label !== "Short Name"
                          ? { width: "82%" }
                          : { width: "82%" }
                      }
                      span={label === "Short Name" ? true : false}
                      useCallFunc
                      selectedValue={(value: any) => {
                        updateStates(stateName!, {
                          id: value[idExpr!],
                          name: value[dataExpr!],
                        });
                      }}
                    />
                  ) : (
                    <SelectsTemplate
                      placeHolder={defaultValue}
                      label={label}
                      dataexp={dataExpr}
                      idexpr={idExpr}
                      options={optionsData}
                      selectStyle={
                        label !== "Short Name"
                          ? { width: "82%" }
                          : { width: "100%" }
                      }
                      span={label === "Short Name" ? true : false}
                      useCallFunc
                      selectedValue={(value: any) => {
                        updateStates(stateName!, {
                          id: value[idExpr!],
                          name: value[dataExpr!],
                        });
                      }}
                    />
                  )}
                </div>
              );
            }
          )}

          <div className="w-full flex flex-col">
            <InputsTemplate
              label={"Code"}
              defaultValue={states.code}
              useCallbackFunc
              span
              orderOnchange={(e: any) => {
                updateStates("code", e);
              }}
              labelColPan={6}
            />

            <Form.Item
              label={<p className="text-xs">{"Order"}</p>}
              labelCol={{ span: 6 }}
            >
              <div className="w-full flex justify-between py-0.5">
                <InputsTemplate
                  useCallbackFunc
                  defaultValue={states.order}
                  numberOnly={true}
                  readOnly={false}
                  orderOnchange={(e: any) => {
                    updateStates("order", e);
                  }}
                />
                <InputsTemplate
                  label={"HMO"}
                  useCallbackFunc
                  defaultValue={states.hmo}
                  numberOnly={true}
                  readOnly={false}
                  orderOnchange={(e: any) => {
                    updateStates("hmo", e);
                  }}
                />
              </div>
            </Form.Item>

            <CheckboxTemlate
              withBorders
              label={"Active?"}
              label2={"Help Module?"}
              inlineRightLabelCol={19}
              inlineDefaultValue={[states.active, states.helpModule]}
              index={["isActive", "isHelpModule?"]}
              useCallbackFunc
              setCheckboxOnchange={(e, index) => {
                index === "isActive"
                  ? updateStates("active", e)
                  : updateStates("helpModule", e);
              }}
            />

            <div className="w-full flex mt-0.5 ">
              <BottomBtns
                style={{ marginRight: 30 }}
                useCallbackFunc
                span
                withoutCheckbox={true}
                onNewClick={() => {
                  dispatch(
                    setGeneralValue({ expr: "formDisabled", value: false })
                  );
                  populateFields(true);
                }}
                onCancelClick={() => {
                  dispatch(
                    setGeneralValue({ expr: "formDisabled", value: true })
                  );
                }}
                updateHandler={() => {
                  validation("put");
                }}
                saveHandler={() => {
                  validation("post");
                }}
              />
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};
