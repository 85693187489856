import { useEffect, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  navbar_height,
  recordExistsError,
  serverError,
  toolbar_height,
} from "../../../accessories/component_infos";
import { HrSetupsCustomToolbar } from "../../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import { Hr_Setups_Datagrid_Template } from "../data/hr_setup_datagid_template";
import {
  checkboxTypes,
  datagridTypes,
  inputsTypes,
  selectsTypes,
} from "../data/types/selectsTypes";
import reset from "../../../../assets/reset.png";
import { useDispatch, useSelector } from "react-redux";
import { setGeneralValue, setTier } from "../../../../features/generalSlice";
import { RootState } from "../../../../app/store";
import Hr_Setups_Form_Template from "../data/datalists/hr_setups_form_template";
import { create } from "domain";
import { validateRecordExists } from "../../../../functions/validateRecordExists";
import { WarningAlert } from "../../../accessories/warningAlert";
import {
  setValue,
  tier1SaveOnclick,
  tier1UpdateOnclick,
} from "../../../../features/forms/tier1/createSlice";
import { ModalTemplate } from "../../../../templates/modal";
import { useReduxValues } from "../../../../hooks/useReduxValues";
import {
  setTier2Value,
  tier2SaveOnclick,
  tier2UpdateOnclick,
} from "../../../../features/forms/tier2/tier2Slice";
import {
  setTier3Value,
  tier3SaveOnclick,
  tier3UpdateOnclick,
} from "../../../../features/forms/tier3/tier3Slice";
import {
  setTier4Value,
  tier4SaveOnclick,
  tier4UpdateOnclick,
} from "../../../../features/forms/tier4/tier4Slice";
import {
  setTier2CheckboxValue,
  tier2CheckboxSaveOnclick,
  tier2CheckboxUpdateOnclick,
} from "../../../../features/forms/tier2checkbox/tier2SliceCheckbox";
import { setElementStyles } from "../../../../functions/DOMElementStyles";
import { useFormPriviledge } from "../../../../hooks/useFormPriviledge";
import { useAccountInfo } from "../../../../hooks/useAccountInfo";
import useResizeMode from "../../../../hooks/useResizeMode";
import { useThemes } from "../../../../functions/themes";
interface props {
  // Page responsiveness and other functionalities
  trigger: boolean; // trigger page height responsiveness
  additionalHeights?: number;
  formTier?: "1" | "2" | "3" | "4" | "tier2checkbox";

  //form section props
  formHeader: string; //main form header
  optionalnamelabel?: string; //optional label for name eg.location
  optionalShortnamelabel?: string; //optional label for name eg.location
  selects?: selectsTypes[]; //optional selects if any
  inputs?: inputsTypes[]; //optional inputs if any
  selects_after_constants?: selectsTypes[]; //optional selects after constants if any
  additonalBottomCheckbox?: string; // double instead of single checkbox at bottom
  moreCheckboxes?: any; //optional flex or single checkboxes
  labelCol?: number; //where label span must be specified
  checkboxlist?: checkboxTypes[]; // block checkboxes
  disableName?: boolean; //remove name input
  disableShortName?: boolean; //remove short name input
  postRoute?: string; //endpoint for post
  inputsAfterConstants?: any;
  formWidth?: number;
  datalistsAfterConstants?: any;
  createData?: () => void; //
  updateData?: () => void; //handle update button click
  handleEdit?: () => void; //handle edit button click on toolbar
  handleFormNew?: () => void; //handle new button on form
  handleNew?: () => void; // handle new button click on toolbar
  deleteData?: () => void; //
  handleSearch?: () => void; //handle find click on toolbar
  handleRefresh?: () => void; //handle grid refresh
  searchTextChange?: (text: string) => void; //handle grid refresh
  //datagrid section props
  datagridColumns?: any; //compulsory datagrid columns
  datagridData?: any; //compulsory datagrid data
  multipleDatagrids?: datagridTypes[]; //more datagrids
  multipleDatagridsWidth?: number | string; //width when multiple datagrids are displayed
  extraWidgets?: any;
  datagridClicked?: (e: any) => void;
  searchCriteria?: any[];
  searchCriteriaChange?: (index: number, criteria: string) => void;
  checkOnChange?: (status: boolean) => void;
  isToolbar ?: boolean; //enable or disable toolbar
  selectedRecordX?: any
  gridheight_Form ?: number;
  gridheight_Full ?: number
}

const Setups_Template = ({
  optionalShortnamelabel,
  datagridClicked,
  createData,
  handleRefresh,
  handleSearch,
  datalistsAfterConstants,
  inputsAfterConstants,
  searchTextChange,
  updateData,
  handleEdit,
  handleNew,
  handleFormNew,
  deleteData,
  formTier = "1",
  formWidth,
  disableName,
  disableShortName,
  trigger,
  multipleDatagridsWidth = "20%",
  multipleDatagrids,
  additionalHeights = 0,
  checkboxlist,
  moreCheckboxes,
  labelCol,
  inputs,
  selects_after_constants,
  formHeader,
  optionalnamelabel,
  selects,
  additonalBottomCheckbox,
  datagridColumns,
  datagridData,
  postRoute,
  extraWidgets,
  searchCriteria,
  searchCriteriaChange,
  checkOnChange,
  isToolbar = true,
  selectedRecordX,
  gridheight_Form,
  gridheight_Full,
}: props) => {
  //navigation
  const setupPageRoute = window.location.pathname; //get current route of page
  const datagridOnlySearchParam = "table-only"; //search param in datagrid mode
  const formModeSearchParam = "form-and-table"; //search param in form mode mode
  const navigate = useNavigate();
  useThemes()
  const [searchParams] = useSearchParams();
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  const updateMode = useSelector(
    (state: RootState) => state.general.updateMode
  );

  const [resizeMode, initialResizeMode] = useResizeMode(
    searchParams.get("view") === formModeSearchParam ||
    searchParams.get("view") === null ||
    updateMode
  ); //miscellaneous heights
  var MultGrid_1 = resizeMode - (395);
  var Multigrid_2 = initialResizeMode - (224);

  var singleDataGrid_Full = resizeMode - (193);
  var singleDataGrid_Default = initialResizeMode - (formTier === "1" ? 350 :  formTier === "2" ? 380  : formTier === "tier2checkbox" ? 405 : gridheight_Form ?? 435);

  const miscHeight = 7 + additionalHeights;

  // All heights out
  const heights_out = navbar_height + news_flash_height + toolbar_height;

  //varying form height
  const [form_height, set_form_height] = useState<number>(0);
  const pass_form_height = (height: number) => {
    set_form_height(height);
  };

  const dispatch = useDispatch();

  const selected = useSelector(
    (state: RootState) => state.general.selectedFieldData
  );
  const selectedIDExpr = useSelector(
    (state: RootState) => state.general.idExpr
  );
  const [empID, userId, userInfo] = useAccountInfo();
  const [privType, setPrivType] = useState<string>("save");

  const [savePriv, readPriv , updatePriv] = useFormPriviledge(
    privType,
  );
  const [existsAlert, setExistsAlert] = useState({
    state: false,
    title: "",
    message: "",
  });

  // Page resize height responsiveness
  useEffect(() => {
    //dispatch form tier
    dispatch(setTier(formTier));

    // redux cleanups
    dispatch(setGeneralValue({ expr: "formDisabled", value: true })); //disable form
    dispatch(setGeneralValue({ expr: "updateMode", value: false })); //disable update mode
    dispatch(setGeneralValue({ expr: "selectedFieldData", value: [] })); //clear selected field data
    dispatch(setGeneralValue({ expr: "setupSearchText", value: "" })); //clear selected field data
    dispatch(setGeneralValue({ expr: "activeStatus", value: "" })); //clear selected field data

    dispatch(setValue({ expr: "checkExistsOnclick", value: false }));

    dispatch(setTier2CheckboxValue({ expr: "saveDataOnclick", value: false }));

    // dispatch(setGeneralValue({expr: 'selectedFieldData' ,value : })); //clear selected value
    settableHeight(window.innerHeight - (heights_out + miscHeight - 4));
  }, [trigger]);

  const postData = () => {
    setPrivType("save");
    if (savePriv === true) {
      createData && createData();
    } else {
      setExistsAlert({
        state: true,
        message: "No Access Privileges",
        title: "Access Denied!",
      });
    }
  };
  const updateAccessPriv = () => {
    setPrivType("update");
    if (updatePriv === true) {
      updateData && updateData();
    } else {
      setExistsAlert({
        state: true,
        message: "No Access Privileges",
        title: "Access Denied!",
      });
    }
  };

  const ondbAccessPriv = (e: any) => {
    if (updatePriv === true) {
      dispatch(setGeneralValue({ expr: "updateMode", value: true }));
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      console.log("ondbAccessPriv", e)
      setSelectdRecord(e)
      datagridClicked && datagridClicked(e);
    } else {
      dispatch(setGeneralValue({ expr: "updateMode", value: false }));
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      setExistsAlert({
        state: true,
        message: "No Access Privileges",
        title: "Access Denied!",
      });
    }
  };

  // handle interface switch
  const toggle_staff_form = (): void => {
    const view = searchParams.get("view");
    dispatch(setGeneralValue({ expr: "updateMode", value: false }));
    dispatch(setGeneralValue({ expr: "formDisabled", value: true }));

    view === null || view === formModeSearchParam
      ? navigate({
        pathname: setupPageRoute,
        search: createSearchParams({
          view: datagridOnlySearchParam,
        }).toString(),
      })
      : navigate({
        pathname: setupPageRoute,
        search: createSearchParams({
          view: formModeSearchParam,
        }).toString(),
      });
  };

  //Table Height
  const pageHeight = window.innerHeight - (navbar_height + miscHeight); //height without navbar
  const [tableHeight, settableHeight] = useState(window.innerHeight); //height without navbar and toolbar

  const [active, setActive] = useState<boolean | string>("");
  const [searchText, setSearchText] = useState<string>("");

  const saveObj = { expr: "saveDataOnclick", value: true };
  const save = () => {
    formTier === "1"
      ? dispatch(setValue(saveObj))
      : formTier === "2"
        ? dispatch(setTier2Value(saveObj))
        : formTier === "3"
          ? dispatch(setTier3Value(saveObj))
          : formTier === "4"
            ? dispatch(setTier4Value(saveObj))
            : dispatch(setTier2CheckboxValue(saveObj));
  };

  const updateObj = { expr: "updateDataOnclick", value: true };
  const update = () => {
    formTier === "1"
      ? dispatch(setValue(updateObj))
      : formTier === "2"
        ? dispatch(setTier2Value(updateObj))
        : formTier === "3"
          ? dispatch(setTier3Value(updateObj))
          : formTier === "4"
            ? dispatch(setTier4Value(updateObj))
            : dispatch(setTier2CheckboxValue(updateObj));
  };

  //record exists checks

  const formHeadersBG = useSelector(
    (state: RootState) => state.general.settingsColors?.fhbgc
  );

const [selectedRecord , setSelectdRecord] = useState({});

const{name,shortName,checkOnclick,checkTrigger,checkUrl}=useReduxValues(formTier)

  useEffect(()=>{
    const func=async()=>{
      if (checkOnclick) {
        const checkResponse = await validateRecordExists(`${checkUrl}/GetNameShortName?name=${name}&shortname=${shortName}`);

        if (checkResponse.status === 200) {
          if(!updateMode){
            //exists
            setExistsAlert({state:true,...recordExistsError})
            return;
          }

          //update mode instance
          // for(let record of checkResponse.data){
          //   if(record[selectedIDExpr] !== selected[selectedIDExpr]){ 
          //     setExistsAlert({state:true,...recordExistsError}) 
          //     return;
          //   }
          // }

          update();
          return;

        } 
        else if(checkResponse.status === 204) {
          //not exists
          updateMode ? update() : save()
          return;
        }

        setExistsAlert({state:true,...serverError})
      }
    }
    func();
  },[checkTrigger]) //checkTrigger

  return (
    <>
      <ModalTemplate
        title={existsAlert.title}
        message={existsAlert.message}
        open={existsAlert.state}
        disableCancel
        okHandler={() => setExistsAlert({ ...existsAlert, state: false })}
      />

      <div style={{ height: pageHeight }} className="w-full">
        {isToolbar && (
          <div className="px-2">
            <HrSetupsCustomToolbar
              setSearchParams={formModeSearchParam}
              setSearchParamspathname={setupPageRoute}
              searchCriteria={searchCriteria ? true : false}
              searchCriteriaData={searchCriteria && searchCriteria}
              searchCriteriaOnchange={searchCriteriaChange}
              handlePrint={() => {
                //do something
                console.log("Do something");
              }}
              handleRefresh={() => {
                handleRefresh && handleRefresh();
              }}
              powerTrainRefresh={()=>{
                handleRefresh && handleRefresh();
              }}
              checkOnChange={(value) => {
                setActive(value); //store locally
                checkOnChange && checkOnChange(value);
                dispatch(setGeneralValue({ expr: "activeStatus", value: value })); //push value to redux
              }}
              searchTextOnchange={(text) => {
                setSearchText(text); //store locally
                searchTextChange && searchTextChange(text); //to parent
                dispatch(
                  setGeneralValue({ expr: "setupSearchText", value: text })
                ); //push value to redux
              }}
              handleFind={() => {
                handleSearch && handleSearch();
              }}
              // outerSelected={selectedRecord}
              // setOuterSelected={setSelectdRecord}
              handleNew={handleNew}
              withDates={false}
              handleSave={createData}
              handleUpdate={handleEdit}
              handleDelete={deleteData}
              toggler={toggle_staff_form}
              // selected_Record={selectedRecord}
            />
          </div>
        )}

        {/* form section */}
        {(searchParams.get("view") !== datagridOnlySearchParam ||
          updateMode) && (
            <section style={{ height: form_height }} className="form border-b-4">
              <Hr_Setups_Form_Template
                optionalShortnamelabel={optionalShortnamelabel}
                datalistsAfterConstants={datalistsAfterConstants}
                inputsAfterConstants={inputsAfterConstants}
                updateData={updateAccessPriv}
                extraWidgets={extraWidgets}
                postData={postData}
                postRoute={postRoute}
                formTier={formTier}
                formWidth={formWidth}
                disableShortName={disableShortName}
                disableName={disableName}
                checkboxlist={checkboxlist}
                labelCol={labelCol}
                moreCheckboxes={moreCheckboxes}
                additionalBottomChckbox={additonalBottomCheckbox}
                selects_after_constants={selects_after_constants}
                inputs={inputs}
                optionalnamelabel={optionalnamelabel}
                selects={selects}
                form_header={formHeader}
                setheight={pass_form_height}
                handleNew={handleFormNew} 
                additionalCheckBoxVal = {[true]}
              />
            </section>
          )}

        {/* datagrid section */}
        {!multipleDatagrids ? (
          <section className="mt-1 px-2">
            <Hr_Setups_Datagrid_Template
              clicked={(e) => { setSelectdRecord(e) }}
              doubleClicked={(e) => {
                ondbAccessPriv(e);
              }}
              gridheight={
                searchParams.get("view") === formModeSearchParam ||
                  searchParams.get("view") === null
                  ? singleDataGrid_Default
                  : singleDataGrid_Full
              }
              dataGridData={datagridData}
              columns={datagridColumns}
            />
          </section>
        ) : (
          <section className="mt-1 px-2 flex flex-row justify-between">
            {multipleDatagrids.map(
              ({ id, header, columns, data, styleId, dblClick, singleClick, toggleRefresh, type }: datagridTypes) => {
                return (
                  <>
                    <div
                      key={id}
                      className="  "
                      style={{ width: multipleDatagridsWidth }}
                    >
                      <div
                        id={styleId}
                        className="flex flex-row items-center justify-between bg-slate-100 py-1 px-2 rounded-t"
                      >
                        <p className=" font-semibold">{header}</p>
                        <span onClick={() => toggleRefresh!(type)} className="h-full w-4 flex items-center justify-center cursor-pointer hover">
                          <img
                            // was modified
                            className="w w-11/12 h-11/12"
                            src={reset}
                            alt="reset"
                          />
                        </span>
                      </div>

                      <div className="border-2 px-1 pt-0.5 border-slate-100">
                        <Hr_Setups_Datagrid_Template
                          doubleClicked={(e) => {
                            dblClick && dblClick(e);
                          } }
                          // setSelectedRecord={(e)=>{setSelectdRecord(e)}}
                          searchWidth={150}
                          gridheight={
                            searchParams.get("view") === formModeSearchParam ||
                              searchParams.get("view") === null ||
                              updateMode
                              ? MultGrid_1
                              : Multigrid_2
                          }
                          dataGridData={data}
                          columns={columns}
                          clicked={(e : any) => {
                            singleClick && singleClick(e.data)
                            setSelectdRecord(e.data)
                          }}
                        />
                      </div>
                    </div>
                  </>
                );
              }
            )}
          </section>
        )}
      </div>
    </>
  );
};

export default Setups_Template;
