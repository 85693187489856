
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
// import type { SelectProps } from 'antd';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { RootState } from '../../../../app/store';
import useFetch from '../../../../hooks/useFetch';
import { navbar_height } from '../../../accessories/component_infos';
import { CIA_toolbar } from '../../../accessories/custom-toolbars/cia_toolbar';
import { Perspectives_toolbar } from '../../../accessories/custom-toolbars/perspectives_toolbar';

import { Perspective_mini_TextArea, Perspective_TextArea } from './data/Performance_inputs';
import { Footer_datagrid_template } from './data/template/footer_datagrid';
import { Perspective_Details_Form } from './widgets/Perspective_details_form';
import { inputsTypes, searchTextTypes, statusTypes } from '../../../human-resource/setups/data/types/selectsTypes';
import { TextareaTemplate } from '../../../../templates/textarea';
import { setperspectiveDetailsValue } from '../../../../features/forms/entries/safetyManager/ppeRequest';
import { useDispatch } from 'react-redux';
import { perspective_footer_datagrid_cols } from './data/datagrid_cols';
import Datagrid_template from '../../../../templates/Datagrid';
import { Form } from 'antd';
import { HrSetupsCustomToolbar } from '../../../accessories/custom-toolbars/hrSetupsCustomtoolbar';
import { setGeneralValue } from '../../../../features/generalSlice';





interface props {
    state_change: boolean;
}

interface stateTypes {
    selectedRecord?: any;
    question?: string;
    remarks?: string;
    searchText?: searchTextTypes;
    active?: statusTypes
}



export const Perspectives = ({ state_change }: props) => {
    const news_flash_height = useSelector((state: RootState) => state.general.newsflashHeight);
    const dispatch = useDispatch()
    // const width = window.innerWidth/2
    // const fixedWidth = 'calc(50% - 8px)';
    const height = window.innerHeight - (navbar_height + news_flash_height + 0); //height without navbar
    const [formheight, set_form_height] = useState<number>(0);
    const [switch_change, setSwitch_change] = useState<boolean>(true)

    const formRef = useRef<any>(null);
    const [fullPage, setfullPage] = useState(window.innerHeight - (navbar_height + news_flash_height + 30));
    const pageHeight = window.innerHeight - (navbar_height + news_flash_height + formheight + 20);
    // const fullPage = window.innerHeight - (navbar_height + 45) ; //height without navbar

    // switch toggle
    const switch_toggle = () => {
        if (switch_change) {
            setSwitch_change(!switch_change);

        } else {
            setSwitch_change(!switch_change);

        }
    }

    const form_disable = useSelector((state: RootState) => state.general.formDisabled)
    const update_state_change = useSelector((state: RootState) => state.general.updateMode)
    const borderTheme = useSelector((state:RootState)=>state.general.cssBorderClass)

    const [refresh, setRefresh] = useState<boolean>(false)
    const [states, setStates] = useState<stateTypes>({
        selectedRecord: {},
        question: "",
        remarks: "",
        searchText: {temp:'', text: ''},
        active:{status: '', temp: ''}
    })


   

    const [searchText,setSearchText]=useState<searchTextTypes>({temp:'',text:''});
    const [activeStatus,setActiveStatus]=useState<statusTypes>({temp:'',status:''});

    console.log("jgftfutfu",{
        searchText,
        activeStatus
    })

    const updateStates = (key: string, value: any) => {
        setStates((prev: any) => ({ ...prev, [key]: value }))
    }




    const resetTable = () => {
        if (refresh) {
            setRefresh(!refresh)
        } else {
            setRefresh(!refresh)
        }
    }

    const [perspective_data, perspective_error, perspective_loading] = useFetch(`PfmPerspective/GetAllPfmPerspective?status=${activeStatus.status}&searchText=${searchText.text}`, refresh)

  
    const[selectedData,setSelectedData]=useState({});

    if(perspective_data){
        console.log(perspective_data)
    }else{
        console.log(perspective_error)
    }
    

    // onChange={(e: any)=>{dispatch(setperspectiveDetailsValue({expr:input.label, value: e.target.value}))}}

    useEffect(() => {

        set_form_height((formRef.current?.clientHeight) + 20)
        console.log(formRef)
        if(form_disable == false && update_state_change == false){
            updateStates('remarks', "")
            updateStates('question', "")
        }

    }, [form_disable])

    return (
        <div className='h-full dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base '>
            {/* toggles between the taskForm and the footer grid table vertically */}

            <div>
                <HrSetupsCustomToolbar
                withDates={false}
                searchTextOnchange={(searchValue)=>{
                    setSearchText({temp:searchValue, text:searchText.text})
                }}
                checkOnChange={(check)=>{
                    setActiveStatus({temp:check,status:activeStatus.status })
                }}
            //    handleFind={()=>{
            //         updateStates('searchText',{text:searchText?.temp,temp:searchText?.temp})
            //         updateStates('active', {status: active?.temp, temp:active?.temp});
            //    }}
            handleFind={()=>{
                setSearchText({temp:searchText.temp,text:searchText.temp}) //search text
                setActiveStatus({temp:activeStatus.temp,status:activeStatus.temp}) //active status
           
            }}
                toggler={switch_toggle} 
                outerSelected={states.selectedRecord}
                handleNew={()=>{
                    setSelectedData({})
                    updateStates('selectedRecord', {})
                }}
                handleRefresh={()=>{setRefresh(!refresh)}}
            
            />
               
                
            </div>

            {switch_change && <div ref={formRef} className=''>
                <p id={"form_header"} style={{borderColor:borderTheme}} className='pt-1 border-[1px] border-b-0 pl-4 w-full bg-slate-100 rounded-tl'>Perspective Details</p>
                <div style={{ height: '180px', borderColor:borderTheme }} className="w-full border-[1px]  px-2 flex justify-between gap-x-4 border-b-4 mb-1 pb-1">

                    {/* Internal toggler : expands the right taskUpdate horizontally */}

                    <div style={{ width: '420px', height: '170px' }} className='mb-1' >
                        <Perspective_Details_Form
                            resetTable={resetTable}
                            statesx={states}
                            refreshx = {refresh}
                            setRefreshx = {setRefresh}
                        />
                    </div>


                    {/* Double tap(click) feature to toggle to and fro of the right taskUpdate section */}
                    <div style={{ width: '100%' }} className=" w-full  mt-0.5 rounded-md items-center dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base " >
                        {/* {inputs.map(({id,label, nameKey, stateName})=>{
                                return (
                                    <TextareaTemplate 
                                       
                                        label={label}
                                        useCallbackFunc
                                        defaultValue=''
                                        dataExpr={nameKey}
                                        readonly={false}
                                        setCallbackValue={(e:any)=>dispatch(setperspectiveDetailsValue({expr:label, value: e.target.value}))}
                                        height={55} />
                                )
                               })} */}

                       
                       <Form
                       
                       >
                       <TextareaTemplate
                            useCallbackFunc
                            setCallbackValue={(value) => {
                                updateStates('question', value);
                            }}
                            height={65}
                            defaultValue={states.question}
                            label={'Questions Answered'} />
                        {/* <Perspective_mini_TextArea /> */}
                       



                        <TextareaTemplate
                            useCallbackFunc
                            setCallbackValue={(value) => {
                                updateStates('remarks', value);
                            }}
                            height={95}
                            defaultValue={states.remarks}
                            label={'Remarks'}
                            MarginLeft={65}
                        />
                        {/* <Perspective_TextArea /> */}
                       </Form>
                    </div>


                </div>
            </div>}


            {/* Footer gridtable  */}
            <div style={{ height: '' }} className='px-2  dark:bg-slate-900 dark:text-darkModeSkin-base'>
                <Datagrid_template
                    gridheight={switch_change ? pageHeight : fullPage}
                    columns={perspective_footer_datagrid_cols}
                    data={perspective_data}
                    onRowClick={(e) => {
                        updateStates('selectedRecord', e)
                        updateStates('remarks', e.ptvRmks)
                        updateStates('question', e.ptvQuestionsAnswered)
                        dispatch(setGeneralValue({ expr: "formDisabled", value: true }))
                        dispatch(setGeneralValue({ expr: "updateMode", value: false }))
                    }}

                    rowDoubleClicked={(e) => {
                        updateStates('selectedRecord', e)
                        updateStates('remarks', e.ptvRmks)
                        updateStates('question', e.ptvQuestionsAnswered)
                        dispatch(setGeneralValue({ expr: "formDisabled", value: false }))
                        dispatch(setGeneralValue({ expr: "updateMode", value: true }))
                    }}
                />


            </div>

        </div>

    )
}





// const reload = ()=>{
//     return(
//         <img className='w-2/6' src={save} />
//     )
// }