import { Button, Form, Input, notification, NotificationArgsProps } from "antd";
import logo from "../../assets/InnorikLogo.jpg";
import check from "../../assets/check-mark.png";
import { useDispatch } from "react-redux";
import { setGeneralValue } from "../../features/generalSlice";
import { useEffect, useRef, useState } from "react";
import { useAccountInfo } from "../../hooks/useAccountInfo";
import { PostDataFunc, PostDataFuncNoAuth  } from "../../functions/post";
import { userDataCrypt } from "../../functions/userDataEncrypt";
import { initiateUserData } from "../../features/user";

type NotificationPlacement = NotificationArgsProps["placement"];

const LockScreen = () => {
  const dispatch = useDispatch();
  const password = useRef<string>("");
  const [statusMessage, setStatusMessage] = useState<any>({
    title: "",
    msg: "",
  });
  const [employeeID, userID, userInfo] = useAccountInfo();

  const [api, contextHolder] = notification.useNotification();

  const openNotification = (placement: NotificationPlacement) => {
    api.info({
      message: `${statusMessage?.title}`,
      description: `${statusMessage?.msg} \n`,
      placement,
    });
  };

  useEffect(() => {
    dispatch(setGeneralValue({ expr: "login", value: true }));
  }, []);

  const handleUnlock = async () => {
    try {
      const server = sessionStorage.getItem("serverType");
      const loginResponse = await PostDataFuncNoAuth(`Accounts/Login`, {
        username: userInfo.empStaffno,
        password: password.current,
        server: server.toLowerCase(),
      });
      //success
      if (loginResponse?.data) {
        sessionStorage.setItem(
          "client",
          userDataCrypt("encrypt", JSON.stringify(loginResponse?.data))!
        );
        sessionStorage.setItem("lockStatus", "false");
        setStatusMessage({
          msg: "Unlocked Successfully",
          title: "Screen Unlocked!",
        });
        dispatch(initiateUserData()); //initiate user data get from cookie
        dispatch(setGeneralValue({ expr: "loginMode", value: true })); //disable update
      }

      return;
    } catch (error: any) {
      //handle error

      setStatusMessage({
        msg: "Your password is invalid, Please try again or Contact your System Administrator for Assistance",
        title: "Invalid Credentials",
      });
      console.log(error);
    }
    
    openNotification("bottomRight");
  };

  return (
    <div className="login">
      {contextHolder}
      <section
        style={{ height: "calc(100vh - 210px)" }}
        className="controls w-full flex items-center justify-center "
      >
        <Form
          layout="vertical"
          className=" w-full"
          size="middle"
          style={{ maxWidth: 275 }}
        >
          <div className=" h-24 w-full flex items-center justify-center">
            <div className="img h-full w-24 bg-slate-100 rounded-full">
              <img
                className="flex justify-center items-center"
                src={logo}
                alt="logo here"
              />
            </div>
          </div>

          <Form.Item className=" mt-3">
            <Input
              type="password"
              style={{ borderRadius: "25px" }}
              className=" mt-3 mb-1"
              placeholder="Enter password"
              onChange={(e) => {
                password.current = e.target.value;
              }}
            />
          </Form.Item>

          <Form.Item className=" w-full ">
            <div className="flex flex-row items-center justify-end mt-1">
              <Button
                style={{ borderRadius: "25px" }}
                className="text-sm font-semibold flex flex-row items-center justify-center bg-inherit border-2 h-10"
                onClick={handleUnlock}
              >
                <img className=" mr-1" src={check} alt="" /> Login
              </Button>
            </div>
          </Form.Item>
        </Form>
      </section>
    </div>
  );
};

export default LockScreen;
