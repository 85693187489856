/* eslint-disable react/jsx-pascal-case */
import { forwardRef, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";
import {
  setTier2CheckboxValue,
  tier2CheckboxUpdateOnclick,
} from "../../../../../../features/forms/tier2checkbox/tier2SliceCheckbox";
import {
  setGeneralValue,
  setFormData,
} from "../../../../../../features/generalSlice";
import useFetch from "../../../../../../hooks/useFetch";
import { ModalTemplate } from "../../../../../../templates/modal";
import {
  validationsData,
  saveModalData,
  updateModalData,
} from "../../../../../accessories/component_infos";
import Setups_Template from "../../../../../human-resource/setups/.setups_templates/Setups_Template";
import {
  exprsTypes,
  modalTypes,
  searchCriteriaTypes,
  searchTextTypes,
  selectExprsTypes,
  selectsTypes,
} from "../../../../../human-resource/setups/data/types/selectsTypes";
import { assets_transunitsColumns } from "./data/transportUnitsData";
import { useAccountInfo } from "../../../../../../hooks/useAccountInfo";
import { PostDataFunc  } from "../../../../../../functions/post";
import { UpdateDataFunc } from "../../../../../../functions/update";
import { useCrudFunc } from "../../../../../../functions/crud";
import { useBusyLoader } from "../../../../../../hooks/useBusyLoader";

export const Assets_setups_transunits = forwardRef(({ a }: any, ref) => {

  const saveSuccessText = {
    message: "Transport Unit added Successfully",
    ok: "Ok",
    title: "Save Succesful",
  }; // successful save modal message
  const updateSuccessText = {
    message: "Transport Unit updated Successfully",
    ok: "Ok",
    title: "Update Successful",
  }; // successful update modal message
  const [employeeId, userId] = useAccountInfo();
  // store data expressions[unique]
  const idExpr: String = "truIdpk";
  const nameExpr: string = "truName";
  const shortnameExpr: string = "truShtName";
  const orderExpr: string = "truOrder";
  const activeExpr: string = "truActive";
  const remarksExpr: string = "truRmks";
  const inputsExprsData: any = {
    idExpr: idExpr,
    nameExpr: nameExpr,
    shortnameExpr: shortnameExpr,
    orderExpr: orderExpr,
    activeExpr,
    remarksExpr: remarksExpr,
  };
  const selectsExpr: selectExprsTypes[] = [
    { idExpr: "dptIdpk", nameExpr: "dptName", label: "Department" },
    { idExpr: "sxnIdpk", nameExpr: "sxnName", label: "Section" },
    { idExpr: "untIdpk", nameExpr: "untName", label: "Unit" },
    { idExpr: "areIdpk", nameExpr: "areName", label: "area" },
    { idExpr: "areIdpk", nameExpr: "areName", label: "area" },
  ];
  const checkboxesExpr: selectExprsTypes[] = [
    { idExpr: "etpMouse", nameExpr: "etpMouse" },
    { idExpr: "etpKeyboard", nameExpr: "etpKeyboard" },
    { idExpr: "etpMonitor", nameExpr: "etpMonitor" },
    { idExpr: "areIdpk", nameExpr: "areName" },
    { idExpr: "areIdpk", nameExpr: "areName" },
  ];
  const dispatch = useDispatch();
  const [posting , updating]=useCrudFunc();
  const [busyLoader,setBusyLoader]=useBusyLoader();
  const storeExprs = () => {
    const exprs: exprsTypes[] = [
      { expr: "existsUrl", value: `AssetMgrTmsTransportUnit` },
      { expr: "idExpr", value: idExpr },
      { expr: "nameExpr", value: nameExpr },
      { expr: "shortnameExpr", value: shortnameExpr },
      { expr: "selectExprs", value: selectsExpr[0] },
    ];
    for (let i = 0; i < exprs.length; i++) {
      dispatch(
        setTier2CheckboxValue({ expr: exprs[i].expr, value: exprs[i].value })
      );
    }

    // to general slice
    const generalExprs: exprsTypes[] = [
      { expr: "selectExprs", value: selectsExpr },
      { expr: "inputExprs", value: inputsExprsData },
      { expr: "checkboxExprs", value: checkboxesExpr },
    ];
    for (let i = 0; i < generalExprs.length; i++) {
      dispatch(
        setGeneralValue({
          expr: generalExprs[i].expr,
          value: generalExprs[i].value,
        })
      );
    }
  };

  const [refresh, setRefresh] = useState<boolean>(false); //refresh data state for data creation or update
  

  //modal states
  //--general--
  const triggerSuccessModal = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.successModalState
  ); //trigger success modal
  const SuccessModalText = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.successText
  ); //success modal text

  //--for backend errors--
  const [errState, setErrState] = useState<boolean>(false);
  const [errTitle, seterrTitle] = useState<string>("");
  const [errMessage, seterrMessage] = useState<string>("");

  //--warning modal--
  const triggerwarningModal = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.warningState
  ); //trigger warning
  const warningInfos: any = validationsData; //warning type details
  const warningDataExpr = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.warningDataExpr
  ); //warning type selector

  //--save and update modal state
  const [showModal, setshowModal] = useState<boolean>(false);

  //--save data modal-- //
  const saveData = saveModalData; //save modal details[data]

  //--Update data modal--
  const updateIndInfos = updateModalData; //update modal details[data]
  const triggerupdateModal: boolean = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.updateDataOnclick
  ); //trigger update question

  // on page resize height responsiveness
  const [trigger, setTrigger] = useState<boolean>(false);
  useImperativeHandle(ref, () => ({
    test: () => {
      setTrigger(!trigger);
    },
  }));

  // get current values from  [slice dependent on form type]

  const name = useSelector((state: RootState) => state.tier2Ckeckboxforms.name);
  const shortname = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.shortname
  );
  const order = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.order
  );
  const active = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.active
  );
  const remarks = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.remarks
  );
  const selectedID = useSelector(
    (state: RootState) => state.general?.SelectedFieldID
  );

  const department = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.select0
  );
  const departmentName = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.select0Text
  );
  const section = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.select1
  );
  const unit = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.select2
  );
  console.log('@#$ dptName',departmentName)

  //post form Data
  //validate data
  const validateDataSave = () => {
    if (department === "" || department === undefined) {
      setErrState(true);
      seterrTitle("Select Department");
      seterrMessage("Please select a department");

      return;
    } else if (section === "" || section === undefined) {
      setErrState(true);
      seterrTitle("Select Section");
      seterrMessage("Please select a section");

      return;
    } else if (unit === "" || unit === undefined) {
      setErrState(true);
      seterrTitle("Select Unit");
      seterrMessage("Please select a unit");

      return;
    } else if (name === "" || name === undefined) {
      setErrState(true);
      seterrTitle("Enter Transport Unit");
      seterrMessage("Please enter a transport unit");

      return;
    } else if (shortname === "" || shortname === undefined) {
      setErrState(true);
      seterrTitle("Enter Transport Unit");
      seterrMessage("Please enter a transport unit");

      return;
    } else {
      setshowModal(true);
    }
  };

  // post
  const postData = async () => {
    dispatch(setTier2CheckboxValue({ expr: "saveDataOnclick", value: false }));

    try {
      setBusyLoader("Saving Data....")
      const res = await posting (
        `AssetMgrTmsTransportUnit/CreateTransportUnit`,
        {
          truName: name,
          truShtName: shortname,
          truDeptIdfk: department,
          truSectionIdfk: section,
          truUnitIdfk: unit,
          truActive: active,
          truOrder: order,
          truRmks: remarks,
          truCreatedBy: employeeId,
        },`Created transport units ${name}`
      );
      setRefresh(!refresh);
      setshowModal(false);
      const exprs: exprsTypes[] = [
        { expr: "saveDataOnclick", value: false },
        { expr: "successModalState", value: true },
        { expr: "successText", value: saveSuccessText },
      ];
      for (let i = 0; i < exprs.length; i++) {
        dispatch(
          setTier2CheckboxValue({ expr: exprs[i].expr, value: exprs[i].value })
        );
      }
      setBusyLoader("")
    } catch (error: any) {
      //set states for db errors modal
      setErrState(true);
      seterrTitle(error.response.data.message);
      seterrMessage(error.response.data.errors[0]);
      setBusyLoader("")
    }
  };

  //Update Form data
  //validate data
  const validateDataUpdate = () => {
    dispatch(tier2CheckboxUpdateOnclick("transUnit"));
  };
  // update
  const UpdateData = async () => {
    dispatch(
      setTier2CheckboxValue({ expr: "updateDataOnclick", value: false })
    );

    try {
      setBusyLoader("Updating Data....")
      await updating(`AssetMgrTmsTransportUnit/UpdateTransportUnit/${selectedID}`, {
        truName: name,
        truShtName: shortname,
        truDeptIdfk: department,
        truSectionIdfk: section,
        truUnitIdfk: unit,
        truActive: active,
        truOrder: order,
        truRmks: remarks,
        truEditedBy: employeeId,
      }, `Updated transport unit to ${name}`);
      setRefresh(!refresh);
      setBusyLoader("")
    } catch (error: any) {
      //set states for db errors modal
      setErrState(true);
      seterrTitle(error.response.data.message);
      seterrMessage(error.response.data.errors[0]);
      setBusyLoader("")
    }

    const exprs: exprsTypes[] = [
      { expr: "updateDataOnclick", value: false },
      { expr: "successModalState", value: true },
      { expr: "successText", value: updateSuccessText },
    ];
    for (let i = 0; i < exprs.length; i++) {
      dispatch(
        setTier2CheckboxValue({ expr: exprs[i].expr, value: exprs[i].value })
      );
    }
  };

  //modals data
  const modals: modalTypes[] = [
    //warning
    {
      disableCancel: true,
      icon: "warning",
      open: triggerwarningModal,
      okHandler: () =>
        dispatch(setTier2CheckboxValue({ expr: "warningState", value: false })),
      cancelHandler: () =>
        dispatch(setTier2CheckboxValue({ expr: "warningState", value: false })),
      message: warningInfos[warningDataExpr!].message,
      cancelText: warningInfos[warningDataExpr!].cancelText,
      okText: warningInfos[warningDataExpr!].okText,
      title: warningInfos[warningDataExpr!].title,
    },
    //backend error
    {
      disableCancel: true,
      icon: "warning",
      open: errState,
      okHandler: () => setErrState(false),
      cancelHandler: () => setErrState(false),
      message: errMessage,
      cancelText: "",
      okText: "Ok",
      title: errTitle,
    },
    //success
    {
      disableCancel: true,
      icon: "success",
      open: triggerSuccessModal,
      okHandler: () =>
        dispatch(
          setTier2CheckboxValue({ expr: "successModalState", value: false })
        ),
      cancelHandler: () =>
        dispatch(
          setTier2CheckboxValue({ expr: "successModalState", value: false })
        ),
      message: SuccessModalText.message,
      okText: SuccessModalText.okText,
      title: SuccessModalText.title,
    },
    //save
    {
      disableCancel: false,
      icon: "question",
      open: showModal,
      okHandler: () => postData(),
      cancelHandler: () =>
        dispatch(
          setTier2CheckboxValue({ expr: "saveDataOnclick", value: false })
        ),
      message: saveData.message,
      cancelText: saveData.cancelText,
      okText: saveData.okText,
      title: saveData.title,
    },
    //Update
    {
      disableCancel: false,
      icon: "question",
      open: triggerupdateModal,
      okHandler: () => UpdateData(),
      cancelHandler: () =>
        dispatch(
          setTier2CheckboxValue({ expr: "updateDataOnclick", value: false })
        ),
      message: updateIndInfos.message,
      cancelText: updateIndInfos.cancelText,
      okText: updateIndInfos.okText,
      title: updateIndInfos.title,
    },
  ];
  const [searchCriteria, setsearhCriteria] = useState<searchCriteriaTypes>({
    temp: 0,
    index: 0,
  });
  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });
  const [status, setActive] = useState<{
    temp: boolean | string;
    active: boolean | string;
  }>({
    temp: "",
    active: "",
  });

  const searchCriteriaData = [
    { id: 0, value: "Transport Unit" },
    { id: 1, value: "Unit" },
    { id: 2, value: "Section" },
    { id: 3, value: "Department" },
  ];

  // const select0 = useSelector((state:RootState)=>state.tier3forms.select0Value);
  // data for datagrid
  const [data, dataError, dataLoading] = useFetch(
    `AssetMgrTmsTransportUnit/GetTransportUnitGdv?criteria=${searchCriteria.index}&dptActive=${status.active}&search=${searchText.text}`,
    refresh,
    "truIdpk"
  );
  if (data) {
    storeExprs(); // run func to store expressions on store
    dispatch(setGeneralValue({ expr: "idExpr", value: idExpr })); // dispatch fetched data to reduxstore
    dispatch(setFormData(data)); // dispatch fetched data to reduxstore
  }

  const [deptData, deptDataError, deptDataLoading] = useFetch(
    "AssetMgrTmsTransportUnit/GetDepartmentLkup",
    refresh
  );
  console.log('@#$ section', section)

  const [sectionData, sectionDataError, sectionDataLoading] = useFetch(
    `AssetMgrTmsTransportUnit/GetSectionLkup?DptName=${departmentName}`,
    refresh
  );
  // const [sectionData,sectionError,sectionLoading] = useFetch(`HrUnits/GetSections?dptID=${select0}`);
  // The commented code below was used for testing. It contains sample data.
  // const [unitData,unitDataError,unitDataLoading] = useFetch(`api/AssetMgrTmsTransportUnit/GetUnitLkup?dptName=North%20Grid%20Services&sxnName=Kumasi%20Area`,refresh);

  const [unitData, unitDataError, unitDataLoading] = useFetch(
    `AssetMgrTmsTransportUnit/GetUnitLkup`,
    refresh,
    "truIdpk"
  );

  // form parameters
  const form_header = "Transport Unit Details";
  const selects: selectsTypes[] = [
    {
      id: 0,
      label: "Department",
      optionsData: deptData,
      idExpr: "dptIdpk",
      nameKey: "dptName",
      dataExpr: "dptName",
      idKey: "dptIdpk",
    },
    {
      id: 1,
      label: "Section",
      optionsData: sectionData,
      idExpr: "sxnIdpk",
      nameKey: "sxnName",
      dataExpr: "sxnName",
      idKey: "sxnIdpk",
    },
    {
      id: 2,
      label: "Unit",
      optionsData: unitData,
      idExpr: "untIdpk",
      nameKey: "untName",
      dataExpr: "untName",
      idKey: "untIdpk",
    },
  ];
  const optionalnamelabel = "Transport Unit";
  return (
    <>
      {modals.map(
        (
          {
            disableCancel,
            icon,
            okHandler,
            cancelHandler,
            open,
            cancelText,
            okText,
            message,
            title,
          }: modalTypes,
          index
        ) => {
          return (
            <div key={index}>
              <ModalTemplate
                icon={icon && icon}
                disableCancel={disableCancel}
                cancelText={cancelText && cancelText}
                open={open}
                okHandler={okHandler}
                cancelHandler={cancelHandler}
                message={message}
                okText={okText}
                title={title}
              />
            </div>
          );
        }
      )}

      {dataError ? (
        // <CustomLoader />
        <></>
      ) : (
        <Setups_Template
          // search text not working because of gridData route, check it out
          searchCriteria={searchCriteriaData}
          searchCriteriaChange={(newIndex, criteria) => {
            setsearhCriteria({ temp: newIndex, index: searchCriteria.temp });
          }}
          checkOnChange={(value) => {
            setActive({ temp: value, active: status.active }); //store locally
            dispatch(setGeneralValue({ expr: "activeStatus", value: value })); //push value to redux
          }}
          searchTextChange={(newText) => {
            setSearchText({ temp: newText, text: searchText.text });
          }}
          handleSearch={() => {
            setRefresh((current) => !current);
            setSearchText({ text: searchText.temp, temp: searchText.temp });
            setActive({ temp: status.temp, active: status.temp });
            setsearhCriteria({
              temp: searchCriteria.temp,
              index: searchCriteria.temp,
            });
            }}

          handleRefresh={() => {
            setSearchText({ text: "", temp: "" });
            // setStatus(activeStatus);
            setRefresh((current) => !current);
          }}
          createData={validateDataSave}
          updateData={validateDataUpdate}
          formTier="tier2checkbox"
          datagridClicked={() => {}}
          labelCol={6}
          selects={selects}
          optionalnamelabel={optionalnamelabel}
          trigger={trigger}
          datagridColumns={assets_transunitsColumns}
          datagridData={data}
          formHeader={form_header}
        />
      )}
    </>
  );
});
