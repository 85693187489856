/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import Datagrid_template from "../../../../../../templates/Datagrid";
import { DateTemplate } from '../../../../../../templates/date';
import { SelectsTemplate } from '../../../../../../templates/select';
import { RefreshButton } from '../../_widget/utils';
import { SaveButton } from '../../../../../accessories/buttons';
import { stationColumns, stationEntries, stationEntriesColumns, stations } from './_data/stationentriescolumns';
import MiniToolbar from '../../_widget/minitollbar';
import { Switcher } from '../../_widget/switcher';
import { useGraphSwitcher } from '../../db/useSwitcher';
import { ReadingsChart } from '../../_widget/readingschart';

export default function BusVoltage() {
    const { status } = useGraphSwitcher();

    return (
        <section className="w-full h-full" >

            <Switcher alternate={''} >
                {/* top section */}
                <section className='flex space-x-10 w-full border-2 p-2'>
                    {/* left section */}
                    <aside className='w-[22%]'>
                        <aside className='flex items-center justify-between bg-neutral-50 py-1 rounded-sm px-2 border-[1px] border-neutral-400'>
                            <p className='text-sm font-medium '>STATION</p>
                            <RefreshButton />
                        </aside>
                        <aside>
                            <Datagrid_template
                                gridheight={170}
                                showColumnHeaders={false}
                                columns={[{
                                    id: 1,
                                    caption: "STATION",
                                    dataField: "station",
                                },]}
                                data={[{ id: 0, station: "Station", value: "Bus Station" }, { id: 1, station: "Vladivost", value: "Bus Station" }]}
                                disablePaging
                                disableSearch
                                disableGroupPanel
                            />
                        </aside>
                    </aside>
                    {/* right section */}
                    <aside className='w-[78%]'>
                        <section className='flex space-x-2 items-center ml-2'>
                            <DateTemplate disabled={false} label='Date' />
                            < SelectsTemplate label="Time Interval" extraWidget={false} selectStyle={{ height: "23px" }} disabled={false} options={["Hour"]} span={true} useCusWidth cusWidth={"90px"} disabledCustomWidth={true} />
                            <RefreshButton />
                            <SaveButton className={`flex h-6 items-center p-2 border-[1px] rounded dark:bg-slate-900 dark:text-darkModeSkin-base text-[12px]`} iconClassName='h-4 w-4 mr-1' disableButton={false} />
                        </section>
                        <section className='flex items-start'>
                            <p className='text-[12px] font-medium mr-2'>Entries:</p>
                            <Datagrid_template
                                gridheight={170}
                                showColumnHeaders={true}
                                columns={stationEntriesColumns}
                                data={stationEntries}
                                disablePaging
                                disableSearch
                                disableGroupPanel
                            />
                        </section>
                    </aside>
                </section>
                <div className='h-1 w-full bg-sky-200' />
            </Switcher>
            {/* botom section */}
            <section className='flex flex-col'>
                <MiniToolbar showEditIcon={false} />
                <section className='flex-grow '>
                    <section className='w-full h-full'>
                        {
                            status ? <ReadingsChart /> :
                                <Datagrid_template
                                    gridheight={415}
                                    showColumnHeaders={true}
                                    columns={stationColumns}
                                    data={stations}
                                    disableGroupPanel
                                />
                        }
                    </section>
                </section>
            </section>
        </section>
    );
}