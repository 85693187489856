import {
  Column,
  DataGrid,
  GroupPanel,
  Pager,
  Scrolling,
  SearchPanel,
} from "devextreme-react/data-grid";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import save from "../../../../../../../assets/floppy-disk.png";
import {
  navbar_height,
  toolbar_height,
} from "../../../../../../accessories/component_infos";
import {
  vehicle_request_priviledges_data,
  vehicle_request_priviledges_TH,
} from "../data/vehicle-request-priviledges-data";
import useResizeMode from "../../../../../../../hooks/useResizeMode";

interface props {
  toggled?: boolean;
}

const VehicleRequestPriviledgesDetails = ({ toggled }: props) => {
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const heights_out = navbar_height + news_flash_height + toolbar_height;

  const [resizeMode, initialResizeMode] = useResizeMode(!!toggled);

  var pageHeight = resizeMode - (navbar_height + 530);

  const [tableHeight] = useState(window.innerHeight - (heights_out + 70));

  var table_form_Height = initialResizeMode - (heights_out + 200);

  useEffect(() => { }, [toggled]);

  return (
    <div>
      <style
        dangerouslySetInnerHTML={{
          __html: `
           input[type="text"]:disabled {
            background: #fffbeb;
            height:22px
          }  

          
          
          .dx-datagrid-header-panel .dx-toolbar {
            margin-bottom: 1.5px;
        }

          .filterTodos .dx-datagrid-headers{
              margin-top: 10px;
          }

            `,
        }}
      />

      {toggled && (
        <div className="w-full">
          <div className="pt-0.3 flex">
            <DataGrid
              dataSource={vehicle_request_priviledges_data}
              showBorders={true}
              columnWidth={150}
              showRowLines={true}
              style={{ padding: "0.1%" }}
              showColumnLines={true}
              height={toggled === true ? 230 : tableHeight}
              width={"45%"}
            >
              <Pager visible={false} />

              {vehicle_request_priviledges_TH.map((data) => {
                return (
                  <Column
                    key={data.id}
                    caption={data.caption}
                    dataField={data.dataField}
                    alignment={data.alignment as any}
                    fixed={data.fixed}
                    width={data.width === null ? 150 : data.width}
                  />
                );
              })}

              <Scrolling columnRenderingMode="virtual" />
            </DataGrid>

            <DataGrid
              dataSource={vehicle_request_priviledges_data}
              showBorders={true}
              columnWidth={150}
              showRowLines={true}
              style={{ padding: "0.1%" }}
              showColumnLines={true}
              height={toggled === true ? 230 : tableHeight}
              width={"55%"}
            >
              <Pager visible={false} />

              {vehicle_request_priviledges_TH.map((data) => {
                return (
                  <Column
                    key={data.id}
                    caption={data.caption}
                    dataField={data.dataField}
                    alignment={data.alignment as any}
                    fixed={data.fixed}
                    width={data.width === null ? 150 : data.width}
                  />
                );
              })}

              <Scrolling columnRenderingMode="virtual" />
            </DataGrid>
          </div>
        </div>
      )}
      <div className="flex justify-end ">
        <button className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center my-1  px-2 py-0.5 border-2 border-slate-200 rounded ">
          <img alt="" className="pr-1" src={save} />
          Save
        </button>
      </div>

      <div className="pt-0.5">
        <DataGrid
          dataSource={vehicle_request_priviledges_data}
          showBorders={true}
          columnWidth={150}
          showRowLines={true}
          showColumnLines={true}
          height={toggled === true ? pageHeight : table_form_Height}
        >
          <SearchPanel visible={true} width={200} />
          <GroupPanel visible={true} />
          <Pager visible={false} />

          {vehicle_request_priviledges_TH.map((data) => {
            return (
              <Column
                key={data.id}
                caption={data.caption}
                dataField={data.dataField}
                alignment={data.alignment as any}
                fixed={data.fixed}
                width={data.width === null ? 150 : data.width}
              />
            );
          })}
          <Scrolling columnRenderingMode="virtual" />
        </DataGrid>
        <div className="text-xs text-indigo-500 p-1">Done</div>
      </div>
    </div>
  );
};

export default VehicleRequestPriviledgesDetails;
