import { useState } from "react"; 
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import useFetch from "../../../../../../../hooks/useFetch";
import { FormModal } from "../../../../../../accessories/formModal";
import VehicleLogBook from "../../Vehicle-Log-Book/widgets/vehicleLogBook";
import VehicleRequestsSecurityDetails from "./vehicle-requests-security-details";

import { VehicleRequestsSecurityToolbar } from "./vehicle-requests-security-toolbar";
import { WarningAlert } from "../../../../../../accessories/warningAlert";
import { searchTextTypes } from "../../../../../../human-resource/setups/data/types/selectsTypes";
import { currentDate, startingDate } from "../../../../../../accessories/component_infos";
import dayjs from "dayjs";

interface stateTypes {
  startDate: { temp: string; index: string };
  endDate: { temp: string; index: string };
  searchCriteria: { temp: number; index: number };
  searchText: { temp: string; index: string };
  activeStatus: { temp: string | boolean; status: string | boolean };
  dptID: number;
  refresh?: boolean;
}

const VehicleRequestsSecurity = () => {
  const [showTable, setShowTable] = useState(true);
  const [vehicleLogBookModal, setVehicleLogBookModal] =
    useState<boolean>(false);

  const switch_toggle = () => {
    setShowTable(!showTable);
  };

  const [employeeId, userId, userObject] = useAccountInfo();
  const [states, setStates] = useState<stateTypes>({
    startDate : {temp:startingDate, index: startingDate},
    endDate : {temp:currentDate, index: currentDate},
    searchCriteria: {temp: 0, index: 0},
    searchText: {temp:"", index:""},
    activeStatus: {temp:"", status:""},
    dptID: userObject.dptIDpk,
    refresh: false,

  })

  const [refreshstate, setRefreshstate] = useState<boolean>(false);
  const genFormat = "YYYY-MM-DD";
  const today = dayjs().endOf("month").format(genFormat);
  const initialStart = dayjs("2019-02-01").format(genFormat);

  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });
  const [active, setActive] = useState<{
    temp: string | boolean;
    status: string | boolean;
  }>({ temp: "", status: "" });
  const [dptID, setDptID] = useState<number>(userObject.dptIDpk);
  const [startDate, setStartDate] = useState<{ temp: string; index: string }>(
    {
      temp: dayjs(startingDate).format("YYYY-MM-DD"),
      index: dayjs(startingDate).format("YYYY-MM-DD"),
    }
  );
  const [endDate, setEndDate] = useState<{ temp: string; index: string }>({
    temp: dayjs(currentDate).format("YYYY-MM-DD"),
    index: dayjs(currentDate).format("YYYY-MM-DD"),
  });
  const [searchCriteria, setSearchCriteria] = useState<{
    temp: number;
    index: number;
  }>({ temp: 0, index: 0 });

  // End

  const searchCriteriaData = [
    { id: 0, value: "Reg No." },
    { id: 1, value: "Requested By" },
    { id: 2, value: "Requested Area" },
    { id: 3, value: "From" },
    { id: 4, value: "To" },
    { id: 5, value: "Departure Time" },
    { id: 6, value: "Return Time" },
  ];

  const [gridData, gridErr, gridLoading] = useFetch(
    // `AsmTmsVehicleRequestForSecurityGate/GetAllAsmTmsVehicleRequestForSecurityGate?searchText=${searchText.text}&startDate=${startDate.index}&endDate=${endDate.index}&dptID=&searchCriteria=${searchCriteria.index}&active=${active.status}`
    `AsmTmsVehicleRequestForSecurityGate/GetAllAsmTmsVehicleRequestForSecurityGate?Active=${active.status}&SearchText=${searchText.text}&LevelID=${dptID}&StartDate=${startDate.index}&EndDate=${endDate.index}&SearchCriteria=${searchCriteria.index}&PageSize=20&PageNumber=1`
  );

  return (
    <>
      {gridErr && <WarningAlert />}
      <div className="mx-2">
        <VehicleRequestsSecurityToolbar
          launchVehicleLogBookModal={() => {
            setVehicleLogBookModal(!vehicleLogBookModal);
          }}
          startDate={startDate.temp}
          endDate={endDate.temp}
          checkOnChange={(status) => {
            setActive({ temp: status, status: active.status });
          }}
          startDateChanged={(date) =>
            setStartDate({
              temp: dayjs(date).format(genFormat),
              index: startDate.index,
            })
          }
          endDateChanged={(date) =>
            setEndDate({
              temp: dayjs(date).format(genFormat),
              index: endDate.index,
            })
          }
          searchTextOnchange={(searchValue) => {
            setSearchText({ temp: searchValue, text: searchText.text });
          }}
          searchCriteriaOnchange={(val) => {
            setSearchCriteria({ temp: val, index: searchCriteria.index });
          }}
          searchCriteriaData={searchCriteriaData}
          handleFind={() => {
            setSearchCriteria({
              temp: searchCriteria.temp,
              index: searchCriteria.temp,
            });
            setSearchText({
              temp: searchText.temp,
              text: searchText.temp,
            });
            setActive({ temp: active.temp, status: active.temp });
            setStartDate({ temp: startDate.temp, index: startDate.temp });
            setEndDate({ temp: endDate.temp, index: endDate.temp });
            setRefreshstate(!refreshstate);
          }}
          toggler={switch_toggle}
          useCallBackFunc={true}
          handleRefresh={() => {
            setRefreshstate(!refreshstate);
            setSearchCriteria({
              temp: 0,
              index: 0,
            });
            setSearchText({
              temp: " ",
              text: " ",
            });
            setActive({ temp: "", status: "" });
            setStartDate({ index: initialStart, temp: initialStart });
            setEndDate({ index: today, temp: today });
          }}
        />
      </div>

      <div className="px-2 mb-0.5  ">
        <VehicleRequestsSecurityDetails toggled={showTable} data={gridData} />
      </div>
      <div className="">
        <FormModal
          title={"Transport - Vehicle Log Book"}
          child={<VehicleLogBook />}
          openState={vehicleLogBookModal}

          onCancel={() => setVehicleLogBookModal(false)}
        />
      </div>
    </>
  );
};

export default VehicleRequestsSecurity;
