import { Form } from "antd";
import SetupsDatalistTemplat from "../../../../../../human-resource/setups/data/setups_datalist_template";
import { modalPropTypes } from "../../../../../../human-resource/setups/data/types/selectsTypes";
import { InputsTemplate } from "../../../../../../../templates/input";
import { TextareaTemplate } from "../../../../../../../templates/textarea";
import { BottomBtns } from "../../../../../../../templates/butttons";
import { useEffect, useState } from "react";
import {
  saveModalData,
  updateModalData,
} from "../../../../../../accessories/component_infos";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import { useValidateExist } from "../../../../../../../hooks/useValidateExists";
import dayjs from "dayjs";
import { UpdateDataFunc } from "../../../../../../../functions/update";
import { PostDataFunc  } from "../../../../../../../functions/post";
import { useBusyLoader } from "../../../../../../../hooks/useBusyLoader";
import { setGeneralValue } from "../../../../../../../features/generalSlice";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { useCrudFunc } from "../../../../../../../functions/crud";
import InputSelect_template from "../../../../../../../templates/InputSelect";
import { name } from "mustache";

interface props {
  selectedItemData: any;
  refreshGrid: () => void;
  resetData: () => void;
  setBusyLoader: (text: string) => void;
}

export const Insurance_Companies_form = ({
  selectedItemData,
  refreshGrid,
  setBusyLoader,
  resetData,
}: props) => {


  const disabled: boolean = useSelector(
    (state: RootState) => state.general.formDisabled
  );

  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  const [refresh, setRefresh] = useState<boolean>(false);
  const [employeeId, userId,userInfo] = useAccountInfo();
  const dispatch = useDispatch();
  const [posting , updating]=useCrudFunc();
  const [input, setInputs] = useState<any>({
    input0: "",
    input1: "",
    input2: "",
    input3: "",
    input4: "",
    input5: 0,
    input6: "",
    input7: "",
    input8: "",
  });

  const [checkbox, setCheckBox] = useState<boolean>(true);

  let setInputValue = (inputKey: string, value: string | number) => {
    setInputs((prevState: any) => ({ ...prevState, [inputKey]: value }));
  };

  //remark
  const [textareaValue, setTextareaValue] = useState<string>("");
  const [order, setOrder] = useState<number | string>(0);

  const [saveModalState, setsaveModalState] = useState<any>({
    state: false,
    title: "",
    message: "",
    okText: "",
  });
  const setModal = (state: string, value: string | boolean) => {
    setsaveModalState((modalState: any) => ({ ...modalState, [state]: value }));
  };

  useEffect(() => {
    populateFromGrid(false);
  }, [selectedItemData]);

  const populateFromGrid = (clearFields?: boolean) => {
    // populate datalists
    const datalistSelected = [
      { datalistIndex: 0, value: selectedItemData?.icmName },
      { datalistIndex: 1, value: selectedItemData?.icmShtName },
    ];
    for (let index = 0; index < datalistSelected.length; index++) {
      setDatalists(
        clearFields ? "" : datalistSelected[index].value,
        datalistSelected[index].datalistIndex
      );
    }

    //populate checkboxes
    const inputListSelected = [
      { input: "input0", value: selectedItemData?.icmContacts },
      { input: "input1", value: selectedItemData?.icmMobNos },
      { input: "input2", value: selectedItemData?.icmExtensions },
      { input: "input3", value: selectedItemData?.icmTelNos },
      { input: "input4", value: selectedItemData?.icmEmails },
      {
        input: "input5",
        value: selectedItemData?.icmOrder,
      },
      { input: "input6", value: selectedItemData?.icmLongitude },
      { input: "input7", value: selectedItemData?.icmLatitude },
      { input: "input8", value: selectedItemData?.icmAddress },
    ];

    for (let index = 0; index < inputListSelected.length; index++) {
      setInputValue(
        inputListSelected[index].input,
        clearFields
          ? inputListSelected[index].input === "input5"
            ? 0
            : ""
          : inputListSelected[index].value
      );
    }

    setCheckBox(clearFields ? true : selectedItemData?.icmActive);
    setOrder(clearFields ? 0 : selectedItemData?.icmOrder);
    setTextareaValue(clearFields ? "" : selectedItemData?.icmRmks);
  };

  //field for entering data
  let [datalistData, setDatalistData]: any = useState({
    datalist0: {id: 0, name: ""},
    datalist1: {id: 0, name: ""},
  });

  //set datalist value
  const setDatalists = (value: string | number, index: number) => {
    //set datalists value
    setDatalistData((prevState: any) => ({
      ...prevState,
      [`datalist${index}`]: {id : value, name: value},
    }));
  };

  //--success modal--
  const [succesModalState, setsuccesModalState] = useState<modalPropTypes>({
    state: false,
    title: "",
    message: "",
    okText: "",
  });
  //--validation modal --
  const [validationModalState, setvalidationModalState] = useState<any>({
    state: false,
    title: "",
    message: "",
  });

  const setValidationModal = (state: string, value: string | boolean) => {
    setvalidationModalState((warningState: any) => ({
      ...warningState,
      [state]: value,
    }));
  };

  const validateFromBackend = useValidateExist();

  //validate Data
  const ValidatePostData = async () => {
    if (datalistData["datalist0"] === "" || !datalistData["datalist0"]) {
      setvalidationModalState({
        state: true,
        title: "Empty Field",
        message: "Please enter field data",
      });
    } else if (datalistData["datalist0"].length < 2) {
      setvalidationModalState({
        state: true,
        title: "Invalid Company name length",
        message: "Company Name can not be less than 2 characters.",
      });
    } else {
      (await validateFromBackend(
        `InsuranceCompany/GetNameShortName?name=${datalistData["datalist0"].name}&shortname=${datalistData["datalist1"].name}`
      )) &&
        setsaveModalState({
          state: true,
          title: "Save Data?",
          message: "Are you sure you want to save this record?",
          okText: "Yes, Save",
        });
    }
  };

  //create
  const postData = async () => {
    //post request here
    setModal("state", false);
    setBusyLoader(saveModalData.progress);
    try {
      await posting (`InsuranceCompany/CreateInsuranceCompany`, {
        icmName: datalistData["datalist0"].name,
        icmShtName: datalistData["datalist1"].name,
        icmMobNos: input["input1"],
        icmContacts: input["input0"],
        icmTelNos: input["input3"],
        icmExtensions: input["input2"],
        icmAddress: input["input8"],
        icmEmails: input["input4"],
        icmLatitude: input["input7"],
        icmLongitude: input["input6"],
        icmActive: checkbox,
        icmRmks: textareaValue,
        icmOrder: order,
        icmCreatedBy: userId,
      },`Created Insurance company name ${datalistData["datalist0"]}  and address: ${input['input8']}`);
      refreshGrid();
      resetData();
      setRefresh(!refresh);
      setsuccesModalState({
        state: true,
        message: "Record saved successfully",
        title: "Save Successful",
      });
      setBusyLoader("");
      populateFromGrid(false);
    } catch (error: any) {
      setvalidationModalState({
        state: true,
        title: saveModalData.title,
        message: saveModalData.error.message,
      });
    } finally {
      setBusyLoader("");
    }
  };

  const ValidatePutData = async () => {
    if (datalistData["datalist0"].name === "") {
      setvalidationModalState({
        state: true,
        title: "Empty Field",
        message: "Please enter field data",
      });
    } else if (datalistData["datalist0"].length < 2) {
      setvalidationModalState({
        state: true,
        title: "Module Detail Name can not be less than 2 characters.",
        message: "Please enter field data",
      });
    } else if (datalistData["datalist1"].name === "") {
      setvalidationModalState({
        state: true,
        title: "Short Name can not be Empty",
        message: "Please enter field data",
      });
    } else if (datalistData["datalist1"].length < 2) {
      setvalidationModalState({
        state: true,
        title: "Module Detail Short Name can not be less than 2 characters.",
        message: "Please enter field data",
      });
    } else {
      (await validateFromBackend(
        `InsuranceCompany/GetNameShortName?name=${datalistData["datalist0"].name}&shortname=${datalistData["datalist1"].name}`,
        selectedItemData.icmIDpk,
        "icmIDpk"
      )) &&
        setsaveModalState({
          state: true,
          title: "Update Data?",
          message: "Are you sure you want to update this record?",
          okText: "Yes, Update",
        });
    }
  };

  const updateData = async () => {
    //update request here
    //post request here
    setModal("state", false);
    setBusyLoader(updateModalData.progress);

    try {
      await updating(
        `InsuranceCompany/UpdateInsuranceCompany/${selectedItemData.icmIDpk}`,
        {
          icmIDpk: selectedItemData.icmIDpk,
          icmName: datalistData["datalist0"],
          icmShtName: datalistData["datalist1"].name,
          icmMobNos: input["input1"],
          icmContacts: input["input0"],
          icmTelNos: input["input3"],
          icmExtensions: input["input2"],
          icmAddress: input["input8"],
          icmEmails: input["input4"],
          icmLatitude: input["input7"],
          icmLongitude: input["input6"],
          icmActive: checkbox,
          icmRmks: textareaValue,
          icmOrder: order,
          icmEditedBy: userId,
          icmEditedDate: dayjs().format(),
        },`Updated insurance companies to ${datalistData["datalist0"]} and address: ${input['input8']}`
      );
      refreshGrid();
      setRefresh(!refresh);
      setsuccesModalState({
        state: true,
        message: "Record Updated successfully",
        title: "Updated Successful",
      });
      resetData();
      setBusyLoader("");
    } catch (error: any) {
      //set states for db errors modal
      setvalidationModalState({
        state: true,
        title: updateModalData.error.title,
        message: updateModalData.error.message,
      });
    } finally {
      setBusyLoader("");
    }
  };


  const onNewClick=()=>{
    populateFromGrid(true);
    dispatch(setGeneralValue({expr:"formDisabled", value: false}))
  }

  return (
    <div className="px-2">
      {/* save and update modal */}
      <ModalTemplate
        icon={"question"}
        disableCancel={false}
        cancelText={"Cancel"}
        open={saveModalState.state}
        okHandler={
          saveModalState.title === "Save Data?" ? postData : updateData
        }
        cancelHandler={() => setModal("state", false)}
        message={saveModalState.message}
        okText={saveModalState.okText}
        title={saveModalState.title}
      />

      {/* success modal */}
      <ModalTemplate
        icon={"success"}
        disableCancel={true}
        cancelText={"Cancel"}
        open={succesModalState.state}
        okHandler={() => {
          setsuccesModalState((prev: any) => ({ ...prev, state: false }));
        }}
        cancelHandler={() =>
          setsuccesModalState((prev: any) => ({ ...prev, state: false }))
        }
        message={succesModalState.message}
        okText={succesModalState.okText}
        title={succesModalState.title}
      />

      {/* validation */}
      <ModalTemplate
        disableCancel={true}
        open={validationModalState.state}
        okHandler={() => setValidationModal("state", false)}
        cancelHandler={() => setValidationModal("state", false)}
        message={validationModalState.message}
        okText={"Ok"}
        title={validationModalState.title}
      />

      {/* FORM HEADER */}
      <p
        id={"form_header"}
        style={{ borderColor: borderTheme }}
        className="pt-1 border-[1px] border-b-0 px-2 w-full font-semibold bg-slate-100"
      >
        {"Insurance Company Details"}
      </p>
      <Form
        style={{ borderColor: borderTheme }}
        className="w-full border-[1px] border-slate-100 rounded-b flex justify-between py-1 px-2 "
        name="basic"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 19 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        size="small"
      >
        <section className="" style={{ width: "600px" }}>
          {/* datalist */}
          <div className=" mb-1">
            <InputSelect_template
              label="Company Name"
              selectedValue={(e)=>{setDatalists(e, 0)}}
              placeHolder={datalistData.datalist0.name}
              options={[]}
              useCallFunc
              disabled={disabled} 
            />
            {/* <SetupsDatalistTemplat
              datalistStyle={{ height: 24 }}
              outerDisable={disabled}
              selectedData={datalistData.datalist0}
              useCallbackFunc
              index={0}
              setCallbackValue={(value, index) => setDatalists(value, index)}
              label="Company Name"
              options={[{}]}
            /> */}
          </div>

          <div className=" mb-1">
          <InputSelect_template
              label="Short Name"
              selectedValue={(e)=>{setDatalists(e, 1)}}
              placeHolder={datalistData.datalist0.name}
              options={[]}
              useCallFunc
              disabled={disabled} 
            />
            {/* <SetupsDatalistTemplat
              datalistStyle={{ height: 24 }}
              outerDisable={disabled}
              selectedData={datalistData.datalist0}
              useCallbackFunc
              index={1}
              setCallbackValue={(value, index) => setDatalists(value, index)}
              label="Short Name"
              options={[{}]}
            /> */}
          </div>

          {/* Contact Person Input */}
          <div className=" mb-1">
            <InputsTemplate
              inputStyle={{ height: 24 }}
              defaultValue={input.input0}
              useCallbackFunc
              orderOnchange={(value) => setInputValue("input0", value)}
              span={true}
              label="Contact Person"
            />
          </div>

          {/* Flex Inputs */}
          <div className=" mb-1">
            <InputsTemplate
              inputStyle={{ height: 24 }}
              useCallbackFunc
              inlinePlaceholder={[input.input1, input.input2]}
              inlineInputsOnchange={(key, value) => setInputValue(key, value)}
              keyValues={["input1", "input2"]}
              label="Mobile Nos"
              label2="Intercom"
              widths={{ form1: "52%", form2: "45.9%" }}
            />
          </div>

          {/*tel and mail inputs */}
          <div className=" mb-1">
            <InputsTemplate
              inputStyle={{ height: 24 }}
              defaultValue={input.input3}
              useCallbackFunc
              orderOnchange={(value) => setInputValue("input3", value)}
              span={true}
              label="Tel Nos."
            />
          </div>

          <div className=" mb-1">
            <InputsTemplate
              inputStyle={{ height: 24 }}
              defaultValue={input.input4}
              useCallbackFunc
              orderOnchange={(value) => setInputValue("input4", value)}
              span={true}
              label="Email"
            />
          </div>

          <div className=" mb-1">
            <InputsTemplate
              inputType="number"
              inputStyle={{ height: 24 }}
              defaultValue={input.input5}
              useCallbackFunc
              orderOnchange={(value) => setOrder(value)}
              span={true}
              label="Order"
            />
          </div>
          <div className=" mb-1">
            <InputsTemplate
              inputStyle={{ height: 24 }}
              useCallbackFunc
              inlinePlaceholder={[input.input6, input.input7]}
              inlineInputsOnchange={(key, value) => setInputValue(key, value)}
              keyValues={["input6", "input7"]}
              label="Longitude"
              label2="Latitude"
              widths={{ form1: "35%", form2: "62.9%" }}
            />
          </div>

          <div className=" mb-0.5">
            <TextareaTemplate
              label="Address"
              defaultValue={input.input8}
              height={70}
              labelCol={5}
              useCallbackFunc
              setCallbackValue={(value) => setInputValue("input8", value)}
            />
          </div>

          <div className="">
            <BottomBtns
              span
              addActiveBorders={true}
              saveHandler={ValidatePostData}
              updateHandler={ValidatePutData}
              useCallbackFunc
              defaultValue={checkbox}
              onNewClick={onNewClick}
              checkboxChange={(e) => setCheckBox(e)}
            />
          </div>
        </section>

        {/* remarks */}
        <section className=" " style={{ width: "calc(100% - 600px)" }}>
          <TextareaTemplate
            defaultValue={textareaValue}
            useCallbackFunc
            // handleRefresh={() => {
            //   setSearchText({ text: "", temp: "" });
            //   setStatus(activeStatus);
            //   setRefresh((current) => !current);
            // }}
            setCallbackValue={(value: string) => setTextareaValue(value)}
            MarginLeft={50}
            label={"Remarks"}
            height={327}
          />
        </section>
      </Form>
    </div>
  );
};
