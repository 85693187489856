import { createSlice, PayloadAction } from '@reduxjs/toolkit'



interface toolbarStateType {
   startDate : string;
   endDate : string;
    FindData : any
    active : boolean;
    staffStatus : boolean;
}


var initialState = {
    startDate: "2010-01-01T00:00",
    endDate: "2022-01-01T00:00", 
    findData: [],
    active : true,
    staffStatus : false,
}as toolbarStateType | any


const toolbarSlice = createSlice({
    name: 'toolbarDetails',
    initialState,
    reducers: {

     

      // transformer details
      settoolbarDetailsValue(state, action: PayloadAction<any>) { //set values of variables here
        // this automatically create a state with it's corresponding key value
        const expr:string = action.payload.expr;
        const value:any = action.payload.value;
  
        state[expr] = value;
        switch(expr) {
          case 'startDate':
            state.startDate = value;
          break;
          case 'endDate':
            state.endDate = value;
          break;

          default :
          state[expr] = value
        }
        

        //push selected item ID to state
        
      },

      toolbar_find_critera : (state, item) => {
        state.findData = (item.payload)
        // console.log(item.payload);
    },
    },
  })
  
  export const { settoolbarDetailsValue, toolbar_find_critera} = toolbarSlice.actions
  export default toolbarSlice.reducer