export const Remove_Duplicates_From_TerminationList = (Data: any) => {
  let remove_Dup: any = Data.filter((item: any, index: any, self: any) => {
    return (
      self.findIndex(
        (otherItem: any) => otherItem.vdtRegNo === item.vdtRegNo
      ) === index
    );
  });
  return remove_Dup;
};

export const add_Selected_Record_To_Terminate = (
  setTerminationList: any,
  recordsSelectedFromVehicleList: any
) => {
  let records_With_No_Duplicates = Remove_Duplicates_From_TerminationList(recordsSelectedFromVehicleList);
  setTerminationList(records_With_No_Duplicates);
};

// export const Remove_Item_onDoubleClick_In_TerminationList = (
//   DoubleClickedItem: any,
//   terminationList: any,
//   setTerminationList: any
// ) => {
//   if (DoubleClickedItem) {
//     let remainingItems = terminationList.map(
//       (item: any) => item !== DoubleClickedItem
//     );
//     setTerminationList(remainingItems);
//   }
// };
