import { Form } from 'antd'
import React from 'react'
import { formPropsConst } from '../../accessories/constants'
import { DateTemplate } from '../../../templates/date'
import { InputsTemplate } from '../../../templates/input'
import { TextareaTemplate } from '../../../templates/textarea'
import { NewButton, SaveButton } from '../../accessories/buttons'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { setGeneralValue } from '../../../features/generalSlice'
import { RootState } from '../../../app/store'

const SMSAlertInt = () => {
    const dispatch = useDispatch();
    const form_disable = useSelector((state: RootState) => state.general.formDisabled); //form disabled state on general redux slice


    return (
        <div className='w-full '>
            <Form
                labelCol={{ span: 3 }}
                // wrapperCol={{span: 10}}
                className='w-full py-4 px-2 '
                size='small'
                layout="horizontal"
            >
                {/* <DateTemplate
                    label={"Start Date"}
                    width={145}
                /> */}
                <DateTemplate
                    label={"Start Date"}
                    selectedDate={dayjs() ?? dayjs().format("YYYY-MM-DD ")}
                    datePlaceholder={dayjs("2024-04-11T22:36:17.930Z").format("YYYY-MM-DD ")}
                    disabled={form_disable}
                    width={242}
                    dbFormat={true}
                />

                {/* inputs */}

                <InputsTemplate
                    useCallbackFunc
                    disabledStatus={form_disable}
                    span
                    numberOnly
                    labelColPan={3}
                    label={"Interval (Days) - 1st"}
                    additionalWidget={<div className='flex space-x-1 ml-1'>
                        <InputsTemplate span label={"2nd"} inputStyle={{ width: 100 }} numberOnly />
                        <InputsTemplate span label={"3rd"} inputStyle={{ width: 100 }} numberOnly />
                    </div>}

                />
                <TextareaTemplate
                    useCallbackFunc
                    labelCol={3}
                    height={275}
                    label="Remarks"
                    disabled={form_disable}
                />

                <div className="w-full flex justify-end">
                    <SaveButton />
                    <NewButton
                        useCallbackFunc
                        
                        handleOnClick={() => {
                            dispatch(setGeneralValue({expr:"formDisabled", value:false}))
                        }}
                    />
                </div>


            </Form>

        </div>
    )
}

export default SMSAlertInt