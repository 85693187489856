/* eslint-disable react/jsx-pascal-case */
import { DatePicker, Form } from "antd";
import { setGeneralValue } from "../../../../../../../features/generalSlice";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import { Column, DataGrid, Pager } from "devextreme-react/data-grid";
import { useCallback, useEffect, useRef, useState } from "react";
import reset from "../../../../../../../assets/reset.png";
import {
  SaveButton,
  NewButton,
  UpdateButton,
  Cancel,
} from "../../../../../../accessories/buttons";
import {
  dbDateFormat,
  navbar_height,
  toolbar_height,
} from "../../../../../../accessories/component_infos";
import {
  vehicleSimCard_TH,
  vehicle_sim_reg_no,
} from "../data/vehicle-sim-card-data";
import useFetch from "../../../../../../../hooks/useFetch";
import { ModalTemplate } from "../../../../../../../templates/modal";
import Datagrid_template from "../../../../../../../templates/Datagrid";
import { CheckboxTemlate } from "../../../../../../../templates/checkbox";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import { DropDownBox } from "devextreme-react";
import SetupsDatalistTemplat from "../../../../../../human-resource/setups/data/setups_datalist_template";
import { WarningAlert } from "../../../../../../accessories/warningAlert";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import useResizeMode from "../../../../../../../hooks/useResizeMode";
import { PostDataFunc } from "../../../../../../../functions/post";
import { UpdateDataFunc } from "../../../../../../../functions/update";
import { GetDataFunc } from "../../../../../../../functions/get";
import {
  setDisable_form,
  setEdit_Cancel_Button,
  setNew_Cancel_Button,
  setSave_Cancel_Button,
} from "../../../../../../../features/VehicleRedux/TMS-Sicers/Vehicles/VehicleSimCardSlice";
import { SelectsTemplate } from "../../../../../../../templates/select";
import { AxiosResponse } from "axios";
import { useCrudFunc } from "../../../../../../../functions/crud";
import { selectsTypes } from "../../../../../../human-resource/setups/data/types/selectsTypes";
import { DropdownGrid_template } from "../../../../../../../templates/DropdownGrid";
import { DateTemplate } from "../../../../../../../templates/date";
import InputSelect_template from "../../../../../../../templates/InputSelect";
import InputSelect from "../../../../../../../templates/InputSelect";
import {
  fetchMobileNo,
  fetchSimVendor,
  fetchTrackerURL,
  fetchTrackerVendor,
} from "../../../../../../../features/apiCalls/vehSIMcardApi";
import { debounce } from "lodash";
import { RefreshButtonTemplate } from "../../../../../../../templates/RefreshButtonTemplate";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";

interface props {
  toggled?: boolean;
  handleUpdate?: (value: boolean) => void;
  handleNew?: (value: boolean) => void;
  handleRefresh?: () => void;
  toggleNew?: boolean;
  toggleUpdate?: boolean;
  mainDataGrid?: any;
  setShowTable?: (e: boolean) => void;
  setSelectRecodx?: (e: any) => void;
}

interface stateTypes {
  selectedItemId?: any;
  selectedAssignDate?: any;
  selectedEndDate?: any;
  selectedVehRegNo?: { id: number; name: string } | string | any;
  selectedSimVendor?: { id: number; name: string } | string | any;
  selectedMobNo?: { id: number; name: string } | string | any;
  selectedTrackerVendor?: { id: number; name: string } | string | any;
  selectedTrackerUrl?: { id: number; name: string } | string | any;
  remarks?: any;
  remarksKey?: any;
  activeState?: any;
  urls?: any;
  modalState?: any;
  confirmState?: any;
  refresh?: boolean;
  deactivateFields?: boolean;
  showUpdateButton?: boolean;
  form_disable: boolean;
  showCancelButton?: boolean;
  rowData?: any;
  setSelectRecodx?: any;
  isOpenDropdown?: boolean;
  isOpenDropdown1?: boolean;
  isOpenDropdown2?: boolean;
  isOpenDropdown3?: boolean;
  isOpenMobileNoDropdown?: boolean;
}

const VehicleSimCardDetails = ({
  toggled,
  handleUpdate,
  handleNew,
  toggleNew,
  mainDataGrid,
  handleRefresh,
  setShowTable,
  setSelectRecodx,
}: props) => {
  //redux
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const heights_out = navbar_height + news_flash_height + toolbar_height;
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const simVendorData = useSelector(
    (state: RootState) => state.vehSIM.simVendorData
  );
  const mobileNo = useSelector(
    (state: RootState) => state.vehSIM.mobileNo
  );
  const trackerVendorData = useSelector(
    (state: RootState) => state.vehSIM.trackerVendorData
  );
  const trackerURLdata = useSelector(
    (state: RootState) => state.vehSIM.trackerURLdata
  );
  const [states, setState] = useState<stateTypes>({
    activeState: true,
    remarks: "",
    remarksKey: "remark",
    selectedAssignDate: dayjs(),
    selectedEndDate: dayjs(),
    selectedVehRegNo: { id: 0, name: "" },
    selectedSimVendor: { id: 0, name: "" },
    selectedMobNo: { id: 0, name: "" },
    selectedTrackerVendor: { id: 0, name: "" },
    selectedTrackerUrl: { id: 0, name: "" },
    urls: [],
    modalState: {
      state: false,
      title: "",
      message: "",
    },
    confirmState: {
      state: false,
      title: "",
      message: "",
      event: () => { },
    },
    refresh: false,
    form_disable: true,
    deactivateFields: true,
    showUpdateButton: false,
    showCancelButton: false,
    rowData: {},
    selectedItemId: 0,
    isOpenDropdown: false,
    isOpenDropdown1: false,
    isOpenDropdown2: false,
    isOpenDropdown3: false,
    isOpenMobileNoDropdown: false,
  });
  //************************************************************************/

  const trackerVendor = useSelector((state: RootState) => state.general.trackerVendor);
  const [lookups, lookupsErr, lookupsLoading] = useFetch(
    `AsmTmsVehicleSimCardAssignment/GetVehicleSimCardEntryLookUps?tedIDpk=${states.selectedTrackerVendor.id}&pagenumber=1&pagesize=20`
  );
  console.log("lookups:", lookups);
  console.log("simVendorData:", simVendorData);
  console.log("trackerURLdata:", trackerURLdata);
  console.log("trackerVendor:", trackerVendor);
  const [empID, userId, userInfo] = useAccountInfo();



  const leftSide: selectsTypes[] = [
    {
      id: 0,
      label: " Assign Date",
      defaultValue: states.selectedAssignDate,
      stateName: "selectedAssignDate",
    },
    {
      id: 1,
      label: "End Date",
      defaultValue: states.selectedEndDate,
      stateName: "selectedEndDate",
    },
    {
      id: 2,
      label: "Vehicle Registration No",
      defaultValue: states.selectedVehRegNo.name,
      stateName: "selectedVehRegNo",
      optionsData: [],
      idExpr: "vdtIdpk",
      dataExpr: "vdtRegNo",
    },
    {
      id: 3,
      label: "SIM Vendor",
      defaultValue: states.selectedSimVendor.name,
      stateName: "selectedSimVendor",
      optionsData:
        states.selectedSimVendor?.length > 0
          ? simVendorData
          : lookups.getAllTelecomVendors,
      isOpenDropdown: states.isOpenDropdown1,
      isOpenDropdownKey: "isOpenDropdown1",
      idExpr: "tvdIdpk",
      dataExpr: "tvdName",
      singleEndpoint: fetchSimVendor,
      handleFocus: () => { },
    },
    {
      id: 4,
      label: "Mobile No",
      defaultValue: states.selectedMobNo.name,
      stateName: "selectedMobNo",
      optionsData:
        states.selectedMobNo?.length > 0
          ? mobileNo
          : lookups.getAllMobNos,
      isOpenDropdown: states.isOpenMobileNoDropdown,
      isOpenDropdownKey: "isOpenMobileNoDropdown",
      idExpr: "vsaIdpk",
      dataExpr: "vsaMobNo",
      singleEndpoint: fetchMobileNo,
      handleFocus: () => { },
    },
  ];
  const rightSide: selectsTypes[] = [
    {
      id: 1,
      label: "Tracker Vendor",
      defaultValue: states.selectedTrackerVendor.name,
      stateName: "selectedTrackerVendor",
      optionsData:
        states.selectedTrackerVendor?.length > 0
          ? trackerVendorData
          : lookups.getAllTrackerVendors,
      isOpenDropdown: states.isOpenDropdown2,
      isOpenDropdownKey: "isOpenDropdown2",
      singleEndpoint: fetchTrackerVendor,
      idExpr: "tedIdpk",
      dataExpr: "tedVendor",
    },
    {
      id: 2,
      label: "Tracker URL",
      defaultValue: states.selectedTrackerUrl.name,
      stateName: "selectedTrackerUrl",
      optionsData:
        states.selectedTrackerUrl?.length > 0
          ? trackerURLdata
          : lookups.getAllTrackerUrl,
      isOpenDropdown: states.isOpenDropdown3,
      isOpenDropdownKey: "isOpenDropdown3",
      singleEndpoint: fetchTrackerURL,
      idExpr: "tedIdpk",
      dataExpr: "tedTrackerUrl",
    },
  ];

  const updateState = (stateName: string, value: any) => {
    setState((currentState: any) => ({ ...currentState, [stateName]: value }));
  };

  const selectedRecord = useSelector(
    (state: RootState) => state.general.selectedRecord
  );

  const [gridData, gridErr, gridLoading] = useFetch(
    "AsmTmsVehicleSimCardAssignment/AsmTmsVehicleSimCardAssignmentGetAll",
    states.refresh
  );

  const [regNos, regNosErr, regNosLoading] = useFetch(
    "AsmTmsVehicleSimCardAssignment/AsmTmsVehicleSimCardAssignmentGetAllVehicleRegNos",
    states.refresh
  );

  const [mobNos, mobNosErr, mobNosLoading] = useFetch(
    "AsmTmsVehicleSimCardAssignment/AsmTmsVehicleSimCardAssignmentGetAllMobileNos"
  );

  const [simVendors, simVendorsErr, simVendorsLoading] = useFetch(
    "AsmTmsVehicleSimCardAssignment/AsmTmsVehicleSimCardAssignmentGetAllTelecomVendors"
  );

  const [trackers, trackersErr, trackersLoading] = useFetch(
    "AsmTmsVehicleSimCardAssignment/AsmTmsVehicleSimCardAssignmentGetAllTrackerVendors"
  );

  const [postFunc, updateFunc] = useCrudFunc();

  async function getTrackerUrls(vendorId: number) {
    const res = (await GetDataFunc(
      `AsmTmsVehicleSimCardAssignment/AsmTmsVehicleSimCardAssignmentGetAllTrackerUrl?tedIDpk=${vendorId}`
    )) as AxiosResponse;

    if (res.status === 200) {
      updateState("urls", res.data);
    }
  }

  const validate = (method: string) => {
    if (
      states.selectedVehRegNo?.id === 0 ||
      states.selectedVehRegNo?.id === undefined
    ) {
      updateState("modalState", {
        state: true,
        title: "Required field",
        message: "Please select vehicle registration number",
      });
      return;
    }

    if (
      states.selectedSimVendor?.id === 0 ||
      states.selectedSimVendor?.id === undefined
    ) {
      updateState("modalState", {
        state: true,
        title: "Required field",
        message: "Please select simcard vendor",
      });
      return;
    }

    if (
      states.selectedMobNo?.name === "" ||
      states.selectedMobNo?.name === undefined
    ) {
      updateState("modalState", {
        state: true,
        title: "Required field",
        message: "Please select mobile numbers",
      });
      return;
    }

    if (
      states.selectedMobNo?.name.length < 10 ||
      states.selectedMobNo?.name.length > 15
    ) {
      updateState("modalState", {
        state: true,
        title: "Required field",
        message: "Please mobile number isn't correct ",
      });
      return;
    }

    if (
      states.selectedTrackerVendor?.id === 0 ||
      states.selectedTrackerVendor?.id === undefined
    ) {
      updateState("modalState", {
        state: true,
        title: "Required field",
        message: "Please select tracker vendors",
      });
      return;
    }

    if (
      states.selectedTrackerUrl?.id === 0 ||
      states.selectedTrackerUrl?.id === undefined
    ) {
      updateState("modalState", {
        state: true,
        title: "Required field",
        message: "Please select tracker urls",
      });
      return;
    }

    if (method === "post") {
      updateState("confirmState", {
        state: true,
        title: "Save Record",
        message: "Are you sure you want to save this record?",
        event: () => {
          postData();
        },
      });
    } else {
      updateState("confirmState", {
        state: true,
        title: "Update Record",
        message: "Are you sure you want to update this record?",
        event: () => {
          updateData();
        },
      });
    }
  };

  const postData = async () => {
    updateState("confirmState", {
      state: false,
      title: "",
      message: "",
      event: () => { },
    });

    await postFunc(
      "AsmTmsVehicleSimCardAssignment/CreateAsmTmsVehicleSimCardAssignment",
      {
        vsaVendorIdfk: states.selectedSimVendor?.id,
        vsaVehicleDetailIdfk: states.selectedTrackerVendor?.id,
        vsaStartDate: states.selectedAssignDate,
        vsaEndDate: states.selectedEndDate,
        vsaMobNo: states.selectedMobNo?.name,
        vsaTrackerUrlIdfk: states.selectedTrackerUrl?.id,
        vsaActive: states.activeState,
        vsaRmks: states.remarks,
        vsaCreatedBy: `${empID}`,
      },
      `Created Vehicle SimCard for ${states.selectedVehRegNo?.name}`
    )
      .then((response) => {
        updateState("modalState", {
          icon: "success",
          state: true,
          title: "Success",
          message: "Vehicle SimCard successfully saved",
        });
        handleRefresh!();
        updateState("refresh", !states.refresh);
      })
      .catch((err) => {
        updateState("modalState", {
          icon: "warning",
          state: true,
          title: "Required field",
          message: "An unexpected error occur",
        });
      });
  };

  const updateData = async () => {
    updateState("confirmState", {
      state: false,
      title: "",
      message: "",
      event: () => { },
    });
    await updateFunc(
      `AsmTmsVehicleSimCardAssignment/UpdateAsmTmsVehicleSimCardAssignment/${states.selectedItemId}`,
      {
        vsaVendorIdfk: states.selectedSimVendor?.id,
        vsaVehicleDetailIdfk: states.selectedVehRegNo?.id,
        vsaStartDate: states.selectedAssignDate,
        vsaEndDate: states.selectedEndDate,
        vsaMobNo: states.selectedMobNo?.name,
        vsaTrackerUrlIdfk: states.selectedTrackerVendor?.id,
        vsaActive: states.activeState,
        vsaRmks: states.remarks,
        vsaCreatedBy: `${empID}`,
        vsaEditedDate: dayjs(Date.now()).format("YYYY-MM-DD"),
        formCode: "string",
        formAction: "string",
      }
    )
      .then((response) => {
        const success: boolean = response.data.success;
        const statusCode: number = response.data.statusCode;
        if (!success && statusCode === 400) {
          const errors = response.data.errors;
          updateState("modalState", {
            icon: "warning",
            state: true,
            title: "Required field",
            message: errors[0],
          });
        } else {
          updateState("modalState", {
            icon: "success",
            state: true,
            title: "Success",
            message: "Vehicle SimCard successfully updated",
          });
          handleRefresh!();
          updateState("refresh", !states.refresh);
        }
      })
      .catch((err) => {
        updateState("modalState", {
          state: true,
          title: "Required field",
          message: "An unexpected error occur",
        });
      });
  };

  const dispatch = useDispatch();

  //...............SCHRIFT.......................//
  let NewtoCancelButton = useSelector(
    (state: RootState) => state.TMSModule1.vehicleSimCard.New_Cancel_Button
  );

  let EdittoCancelButton = useSelector(
    (state: RootState) => state.TMSModule1.vehicleSimCard.Edit_Cancel_Button
  );

  let SaveToUpdateButton = useSelector(
    (state: RootState) => state.TMSModule1.vehicleSimCard.Save_Update_Button
  );

  let DisabledForm = useSelector(
    (state: RootState) => state.TMSModule1.vehicleSimCard.disable_Form
  );

  const fdispatch = useDispatch();

  //..........................................//

  const populateRow = (data: any) => {
    updateState("rowData", data);
    updateState("selectedAssignDate", dayjs(`${data?.vsaStartDate}`));
    updateState("selectedEndDate", dayjs(`${data?.vsaEndDate}`));
    updateState("selectedItemId", data?.vsaIdpk);
    updateState("selectedTrackerUrl", {
      id: data?.tedIdpk,
      name: data?.tedTrackerUrl,
    });
    updateState("selectedTrackerVendor", {
      id: data?.tedIdpk,
      name: data?.tedVendor,
    });
    updateState("selectedSimVendor", {
      id: data?.tvdIdpk,
      name: data?.tvdName,
    });
    updateState("selectedMobNo", { id: data?.vsaIdpk, name: data?.vsaMobNo });
    updateState("selectedVehRegNo", {
      id: data?.vdtIdpk,
      name: data?.vdtRegNo,
    });
    updateState("remarks", data?.vsaRmks);
    updateState("activeState", data?.vsaActive);
  };

  const resetFields = () => {
    updateState("activeState", true);
    updateState("selectedAssignDate", dayjs());
    updateState("selectedEndDate", dayjs());
    updateState("selectedVehRegNo", { id: 0, name: "" });
    updateState("selectedSimVendor", { id: 0, name: "" });
    updateState("remarks", "");
    updateState("selectedMobNo", { id: 0, name: "" });
    updateState("selectedTrackerVendor", { id: 0, name: "" });
    updateState("selectedTrackerUrl", { id: 0, name: "" });
  };
  const [initialH, fullH] = useResizeMode(!toggled);
  const form_height = 490;
  const update_state_change = useSelector(
    (state: RootState) => state.general.updateMode
  );

  var pageHeight = initialH - navbar_height; //height without navbar
  var fullheight = fullH - (heights_out + 20);
  const [privtype, setPrivType] = useState<string>("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privtype);
  const dateFormat = "ddd, DD MMM YYYY";

  useEffect(() => {
    if (toggleNew === true) {
      resetFields();
    }
  }, [toggleNew]);
  const vehicleRef = useRef<any>({});
  const closeGrid1 = () => {
    vehicleRef.current!.instance.close();
  };

  const saveAccessPriv = () => {
    setPrivType("save");
    if (savePriv === true) {
      validate("post");
    } else {
      updateState("modalState", {
        state: true,
        title: "Access Denied!",
        message: "No Access Privilege",
      });
    }
  };

  const editAccesPriv = (data: any) => {
    setPrivType("post");
    if (updatePriv === true) {
      handleUpdate!(false);
      handleUpdate && handleUpdate(true);
      handleNew!(false);
      fdispatch(setEdit_Cancel_Button(false));
      fdispatch(setSave_Cancel_Button(false));
      fdispatch(setDisable_form(false));
      setSelectRecodx && setSelectRecodx(data);
    } else {
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      updateState("modalState", {
        state: true,
        title: "Access Denied!",
        message: "No Access Privilege",
      });
    }
  };

  const newAccesPriv = () => {
    setPrivType("save");
    if (savePriv === true) {
      fdispatch(setNew_Cancel_Button(false));
      fdispatch(setEdit_Cancel_Button(true));
      fdispatch(setSave_Cancel_Button(true));
      fdispatch(setDisable_form(false));
      resetFields();
      updateState("rowData", {});
    } else {
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      handleNew!(false);
      updateState("modalState", {
        state: true,
        title: "Access Denied!",
        message: "No Access Privilege",
      });
    }
  };

  // const dataGridRender = (
  //   data: any,
  //   columnHeader: any,
  //   callBackFunction: any
  // ) => {
  //   return (
  //     <DataGrid
  //       height={"100%"}
  //       style={{ maxHeight: "200px", maxWidth: "1000px" }}
  //       width={"100%"}
  //       columnWidth={80}
  //       columnResizingMode={"widget"}
  //       showColumnLines={true}
  //       showRowLines={true}
  //       onRowClick={(e: any) => {
  //         callBackFunction(e.data);
  //       }}
  //       onRowDblClick={() => {}}
  //       wordWrapEnabled={false}
  //       allowColumnResizing={true}
  //       dataSource={data}
  //     >
  //       <Pager visible={false} />
  //       {columnHeader?.map((reqBy: any) => {
  //         return (
  //           <Column
  //             key={reqBy.id}
  //             dataType={reqBy.dataType}
  //             caption={reqBy.caption}
  //             dataField={reqBy.dataField}
  //             alignment="left"
  //             visible={reqBy.visible}
  //             width={reqBy.width === null ? 150 : reqBy.width}
  //             fixed={reqBy.fixed}
  //           />
  //         );
  //       })}
  //     </DataGrid>
  //   );
  // };

  return (
    <>
      {gridErr && <WarningAlert />}
      <ModalTemplate
        icon={states.modalState.icon ?? "warning"}
        disableCancel={true}
        title={states.modalState.title}
        okText={""}
        message={states.modalState.message}
        open={states.modalState.state}
        okHandler={() => {
          updateState("modalState", { state: false });
        }}
        cancelHandler={() => {
          updateState("modalState", { state: false });
        }}
      />

      <ModalTemplate
        icon="question"
        title={states.confirmState.title}
        okText={
          states.confirmState.message ===
            "Are you sure you want to update record?"
            ? "Yes, Update "
            : "Yes, Save"
        }
        message={states.confirmState.message}
        open={states.confirmState.state}
        okHandler={states.confirmState.event}
        cancelHandler={() => {
          updateState("confirmState", { state: false });
        }}
      />

      <div>
        <style
          dangerouslySetInnerHTML={{
            __html: `
           input[type="text"]:disabled {
            background: #fffbeb;
            height:22px
          }   
          .dx-datagrid-header-panel .dx-toolbar {
            margin-bottom: 1.5px;
        }

          .filterTodos .dx-datagrid-headers{
              margin-top: 10px;
          }

            `,
          }}
        />

        {toggled && (
          <div
            style={{ borderColor: borderTheme }}
            className="w-full  border-[1px] rounded-md"
          >
            <div
              id={"form_header"}
              style={{ borderColor: borderTheme }}
              className="  border-b-[1px] border-t-0 border-r-0 border-x-1  text-xs bg-slate-100 rounded-t-md py-1 px-2  font-medium"
            >
              Vehicle-SIM Card Assignment Details
            </div>
            <div className="pt-2 flex ">
              <Form
                name="complex-form"
                labelCol={{ span: 9 }}
                wrapperCol={{ span: 20 }}
                size={"small"}
                className="ml-2 "
                style={{ width: "450px" }}
              >
                {leftSide.map(
                  ({
                    id,
                    label,
                    defaultValue,
                    optionsData,
                    idExpr,
                    dataExpr,
                    stateName,
                    isOpenDropdown,
                    isOpenDropdownKey,
                    singleEndpoint,
                    handleFocus,
                  }) => (
                    <>
                      {id === 0 || id === 1 ? (
                        <DateTemplate
                          label={label}
                          selectedDate={dayjs(defaultValue)}
                          changeDate={(date: any) => {
                            updateState(
                              stateName!,
                              dayjs(date).format(dbDateFormat)
                            );
                          }}
                          disabled={DisabledForm}
                          width={248}
                        />
                      ) : id === 2 ? (
                        <div className=" mb-0.5  w-[28rem]">
                          <DropdownGrid_template
                            labelCol={9}
                            KeyExpr={idExpr}
                            disabled={DisabledForm}
                            columns={vehicle_sim_reg_no}
                            gridData={regNos}
                            defaultValue={defaultValue}
                            onRowClick={(e) => {
                              updateState(stateName!, {
                                id: e[idExpr!],
                                name: e[dataExpr!],
                              });
                            }}
                            label={label}
                            className="w-[500rem] "
                            isHide={true}
                          />
                        </div>
                      ) : (
                        <InputSelect_template
                          label={label}
                          selectedValue={(e) => {
                            updateState(stateName!, {
                              id: e[idExpr!],
                              name: e[dataExpr!],
                            });
                            updateState(isOpenDropdownKey, false);

                          }}
                          placeHolder={defaultValue}
                          disabled={DisabledForm}
                          options={optionsData}
                          isOpenDropdown={isOpenDropdown}
                          idexpr={idExpr}
                          selectStyle={{ width: 248 }}
                          useCallFunc={true}
                          dataexp={dataExpr}
                          handleSearch={
                            debounce((e) => {
                              updateState(stateName!, e);
                              dispatch(singleEndpoint(e));

                              updateState(isOpenDropdownKey, true);
                            }, 500) // Adjust the debounce delay as needed
                          }
                          handleFocus={(e: any) => {
                            updateState(isOpenDropdownKey, !isOpenDropdown);
                          }}
                          handleRefresh={() => {
                            updateState(stateName!, {
                              id: 0,
                              name: "",
                            });
                          }}
                        />
                      )}
                    </>
                  )
                )}
                {/* <Form.Item
                  label={<p className="text-xs">{"Assign Date"}</p>}
                  style={{ marginBottom: 0 }}
                >
                  <Form.Item
                    name="receivedBy"
                    style={{ display: "inline-block", width: "calc(100%)" }}
                  >
                    {" "}
                    <div className="flex flex-row items-center">
                      <DatePicker
                        key={
                          states.selectedAssignDate === undefined
                            ? ""
                            : states.selectedAssignDate
                        }
                        value={
                          states.selectedAssignDate === undefined
                            ? dayjs()
                            : dayjs(`${states.selectedAssignDate}`)
                        }
                        onChange={(e) => updateState("selectedAssignDate", e)}
                        disabled={DisabledForm}
                        size="small"
                        placeholder="Start Date"
                        style={{ width: "271px" }}
                        format={dateFormat}
                        clearIcon={<span style={{ display: "none" }} />}
                      />
                    </div>
                  </Form.Item>
                </Form.Item>

                <Form.Item
                  label={<p className="text-xs">{"End Date"}</p>}
                  style={{ marginBottom: 0 }}
                >
                  <Form.Item
                    name="receivedBy"
                    style={{ display: "inline-block", width: "calc(100%)" }}
                  >
                    {" "}
                    <div className="flex flex-row items-center">
                      <DatePicker
                        key={
                          states.selectedEndDate === undefined
                            ? ""
                            : states.selectedEndDate
                        }
                        value={
                          states.selectedEndDate === undefined
                            ? dayjs()
                            : dayjs(`${states.selectedEndDate}`)
                        }
                        onChange={(e) => updateState("selectedEndDate", e)}
                        disabled={DisabledForm}
                        size="small"
                        placeholder="Start Date"
                        style={{ width: "271px" }}
                        format={dateFormat}
                        clearIcon={<span style={{ display: "none" }} />}
                      />
                    </div>
                  </Form.Item>
                </Form.Item>
                <Form.Item
                    name="issuedBy"
                    style={{ display: "inline-block", width: "270px" }}
                  >
                <DropdownGrid_template
                  disabled={DisabledForm} 
                  className="w-full"
                  columns={undefined}
                   gridData={undefined} 
                   defaultValue={undefined}

                   label="eueueue"
                   />
                   </Form.Item> */}
                {/* // ref={vehicleRef} */}
                {/* // style={{ */}
                {/* //   borderColor: borderTheme,
                        //   width: "272px",
                        //   height: "23px",
                        //   textAlign: "center",
                        //   marginBottom: "3px",
                        // }}
                        // valueExpr="ID"
                        // deferRendering={false}
                        // showClearButton={true}
                        // openOnFieldClick={true}
                        // placeholder={states.selectedVehRegNo?.name}
                        // value={states.selectedVehRegNo?.name}
                        // contentRender={() =>
                        //   dataGridRender( */}
                {/* //     regNos,
                        //     vehicle_sim_reg_no,
                        //     (e: any) => { */}
                {/* //       updateState("selectedVehRegNo", { */}
                {/* //         name: e.vdtRegNo,
                        //         id: e.vdtIdpk,
                        //       });
                        //       closeGrid1();
                        //     }
                        //   )
                        // }
                      // /> */}

                {/* <Form.Item
                  label={
                    <p className="text-xs ">{"Vehicle Registration No"}</p>
                  }
                  style={{ marginBottom: 0 }}
                >
                  <Form.Item
                    name="issuedBy"
                    style={{ display: "inline-block", width: "270px" }}
                  >
                    {" "}
                    <div className="flex flex-row items-cente">
                      <DropDownBox
                        hoverStateEnabled={true}
                        disabled={DisabledForm}
                        ref={vehicleRef}
                        style={{
                          borderColor: borderTheme,
                          width: "272px",
                          height: "23px",
                          textAlign: "center",
                          marginBottom: "3px",
                        }}
                        valueExpr="ID"
                        deferRendering={false}
                        showClearButton={true}
                        openOnFieldClick={true}
                        placeholder={states.selectedVehRegNo?.name}
                        value={states.selectedVehRegNo?.name}
                        contentRender={() =>
                          dataGridRender(
                            regNos,
                            vehicle_sim_reg_no,
                            (e: any) => {
                              updateState("selectedVehRegNo", {
                                name: e.vdtRegNo,
                                id: e.vdtIdpk,
                              });
                              closeGrid1();
                            }
                          )
                        }
                      />

                      <span
                        onClick={() => {
                          setState({
                            ...states,
                            selectedVehRegNo: { name: "", id: 0 },
                          });
                          updateState("refresh", !states.refresh);
                        }}
                        style={{
                          borderColor: borderTheme,
                          height: 22.5,
                          marginBottom: 0.6,
                          borderWidth: 1,
                          width: 27,
                        }}
                        className=" ml-0.5 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                      >
                        <img
                          className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                          src={reset}
                          alt="reset"
                        />
                      </span>
                    </div>
                  </Form.Item>
                </Form.Item>

                <Form.Item
                  label={<p className="text-xs">{"SIM Vendor"}</p>}
                  style={{ marginBottom: 0 }}
                  className=" w-full block"
                >
                  <Form.Item
                    name="issuedBy"
                    style={{ display: "inline-block", width: "271px" }}
                  >
                    <SelectsTemplate
                      placeHolder={states.selectedSimVendor?.name}
                      handleRefresh={() => {
                        updateState("requestArea", "");
                      }}
                      options={simVendors}
                      disabled={DisabledForm}
                      useCallFunc={true}
                      idexpr={"tvdIdpk"}
                      dataexp={"tvdName"}
                      selectedValue={(e: any) => {
                        updateState("selectedSimVendor", {
                          name: e.tvdName,
                          id: e.tvdIdpk,
                        });
                      }}
                    />
                  </Form.Item>
                </Form.Item>
                <SetupsDatalistTemplat
                  style={{ marginBottom: "2px", marginLeft: "" }}
                  datalistStyle={{ width: "242px" }}
                  outerDisable={DisabledForm}
                  useCallbackFunc
                  selectedData={states.selectedMobNo?.name}
                  setCallbackValue={(value) => {
                    updateState("selectedMobNo", {
                      name: value,
                    });
                  }}
                  handleClear={() =>
                    setState({
                      ...states,
                      selectedMobNo: { name: "", id: 0 },
                    })
                  }
                  handleRefresh={() => updateState("refresh", !states.refresh)}
                  listid={"Mob. No"}
                  idExpr={"vsaIdpk"}
                  nameExpr={"vsaMobNo"}
                  refresh={true}
                  label={"Mobile No"}
                  options={mobNos}
                  span={true}
                /> */}
              </Form>

              <Form
                name="complex-form"
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 24 }}
                size={"small"}
                className="ml-2 flex flex-col justify-end "
                style={{ width: "450px", marginLeft: "20px" }}
              >
                {rightSide.map(
                  ({
                    id,
                    label,
                    stateName,
                    idExpr,
                    dataExpr,
                    defaultValue,
                    optionsData,
                    isOpenDropdown,
                    isOpenDropdownKey,
                    handleFocus,
                    singleEndpoint,
                  }) => (
                    <InputSelect
                      key={id}
                      label={label}
                      selectedValue={(e) => {
                        updateState(isOpenDropdownKey, false);
                        updateState(stateName!, {
                          id: e[idExpr!],
                          name: e[dataExpr!],
                        });
                        dispatch(setGeneralValue({
                          expr: "trackerVendor", value: { name: e.tedVendor!, id: e.tedIdpk!, },
                        })
                        );
                      }}
                      placeHolder={defaultValue}
                      disabled={DisabledForm}
                      options={optionsData}
                      idexpr={idExpr}
                      useCallFunc
                      isOpenDropdown={isOpenDropdown}
                      dataexp={dataExpr}
                      handleSearch={
                        debounce((e) => {
                          updateState(stateName!, e);
                          dispatch(singleEndpoint(e, states.selectedSimVendor.id));

                          updateState(isOpenDropdownKey, true);
                        }, 500) // Adjust the debounce delay as needed
                      }
                      handleFocus={(e: any) => {
                        updateState(isOpenDropdownKey, !isOpenDropdown);
                      }}
                      handleRefresh={() => {
                        updateState(stateName!, {
                          id: 0,
                          name: "",
                        });
                      }}
                    />
                  )
                )}
                {/* <Form.Item
                  label={<p className="text-xs">{"Tracker Vendor"}</p>}
                  style={{ marginBottom: 0 }}
                  className="w-full block"
                >
                  <Form.Item
                    name="issuedBy"
                    style={{ display: "inline-block", width: "300px" }}
                  >
                    <SelectsTemplate
                      placeHolder={states.selectedTrackerVendor?.name}
                      handleRefresh={() => {
                        updateState("refresh", !states.refresh);
                        setState({
                          ...states,
                          selectedTrackerVendor: { name: "", id: 0 },
                        });
                      }}
                      options={trackers}
                      disabled={DisabledForm}
                      useCallFunc={true}
                      idexpr={"tedIdpk"}
                      dataexp={"tedVendor"}
                      selectedValue={async (e: any) => {
                        updateState("selectedTrackerVendor", {
                          name: e.tedVendor,
                          id: e.tedIdpk,
                        });
                        updateState("selectedTrackerUrl", {
                          name: "",
                          id: 0,
                        });
                        await getTrackerUrls(e.tedIdpk);
                      }}
                    />
                  </Form.Item>
                </Form.Item>

                <Form.Item
                  label={<p className="text-xs">{"Tracker URL"}</p>}
                  style={{ marginBottom: 0 }}
                  className="w-full block"
                >
                  <Form.Item
                    name="issuedBy"
                    style={{ display: "inline-block", width: "300px" }}
                  >
                    <SelectsTemplate
                      placeHolder={states.selectedTrackerUrl?.name}
                      handleRefresh={() => {
                        updateState("refresh", !states.refresh);
                        setState({
                          ...states,
                          selectedTrackerUrl: { name: "", id: 0 },
                        });
                      }}
                      options={states.urls}
                      disabled={DisabledForm}
                      useCallFunc={true}
                      idexpr={"tedIdpk"}
                      dataexp={"tedTrackerUrl"}
                      selectedValue={(e: any) => {
                        updateState("selectedTrackerUrl", {
                          name: e.tedTrackerUrl,
                          id: e.tedIdpk,
                        });
                      }}
                    />
                  </Form.Item>
                </Form.Item> */}
              </Form>
            </div>
            <Form
              name="complex-form"
              size={"small"}
              className=""
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 18 }}
              style={{ width: "1400px" }}
            >
              <Form.Item
                label={<p className="text-xs">{"Remarks"}</p>}
                style={{
                  marginBottom: 0,
                  // width: "1400px",
                }}
              >
                <div>
                  <TextArea
                    value={states.remarks}
                    onChange={(v) => {
                      updateState("remarks", v.target.value);
                    }}
                    readOnly={DisabledForm}
                    style={{
                      resize: "none",
                      maxHeight: "80px",
                      minHeight: "80px",
                      height: "80px",
                      width: "100%",
                    }}
                    className="overflow-y-scroll resize-none "
                    rows={3}
                  />
                </div>
              </Form.Item>

              <Form.Item
                style={{
                  display: "inline-block",
                  marginBottom: "2px",
                  width: "1400px",
                }}
                label={<p className="text-xs ">{"Active"}</p>}
                className="w-full mb-1"
                labelCol={{ span: 3 }}
              >
                <div
                  className=" flex  flex-row justify-between"
                  style={{ marginBottom: "1px" }}
                >
                  <CheckboxTemlate
                    withBorders={true}
                    defaultValue={states.activeState}
                    setCheckboxOnchange={(val) => {
                      updateState("activeState", val);
                    }}
                    customDisabled={DisabledForm}
                    useCallbackFunc={true}
                    style={{ marginLeft: "" }}
                  />
                  <ul className="flex  ">
                    {SaveToUpdateButton === false ? (
                      <UpdateButton
                        useCallbackFunc
                        disableButton={false}
                        handleUpdate={() => {
                          validate("put");
                        }}
                      />
                    ) : (
                      <SaveButton
                        useCallbackFunc
                        disableButton={DisabledForm}
                        handleSave={() => {
                          saveAccessPriv();
                        }}
                      />
                    )}
                    {NewtoCancelButton ? (
                      <>
                        {EdittoCancelButton && (
                          <NewButton
                            useCallbackFunc={true}
                            new_button_toggler={newAccesPriv}
                          />
                        )}
                        {!EdittoCancelButton && (
                          <Cancel
                            useCallbackFunc={true}
                            cancel_button_toggler={() => {
                              fdispatch(setNew_Cancel_Button(true));
                              fdispatch(setEdit_Cancel_Button(true));
                              fdispatch(setSave_Cancel_Button(true));
                              fdispatch(setDisable_form(true));
                            }}
                          />
                        )}
                      </>
                    ) : (
                      <Cancel
                        useCallbackFunc={true}
                        cancel_button_toggler={() => {
                          handleNew!(false);
                          handleUpdate!(false);
                          fdispatch(setNew_Cancel_Button(true));
                          fdispatch(setEdit_Cancel_Button(true));
                          fdispatch(setSave_Cancel_Button(true));
                          fdispatch(setDisable_form(true));
                          resetFields();
                          updateState("rowData", {});
                        }}
                      />
                    )}
                  </ul>
                </div>
              </Form.Item>
            </Form>
          </div>
        )}

        <div className="pt-0.5">
          <Datagrid_template
            gridheight={toggled === true ? pageHeight - 385 : fullheight}
            columns={vehicleSimCard_TH}
            data={mainDataGrid}
            disableGroupPanel={false}
            disablePaging={false}
            onRowClick={(val) => {
              fdispatch(setNew_Cancel_Button(true));
              fdispatch(setEdit_Cancel_Button(true));
              fdispatch(setSave_Cancel_Button(true));
              fdispatch(setDisable_form(true));
              setSelectRecodx && setSelectRecodx(val);
              populateRow(val);
            }}
            rowDoubleClicked={(data) => {
              setShowTable && setShowTable(true);

              editAccesPriv(data);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default VehicleSimCardDetails;
