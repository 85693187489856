

export const staff_name_TH = [
 
  {
    id: 1,
    caption: "DATE",
    dataField: "enmDate",
    alignment: "left",
    fixed: true,
    width: null,
    dataType : "date", 
    format: "EEE, dd MMM yyyy"
  },
  {
    id: 2,
    caption: "TITLE",
    dataField: "titName",
    alignment: "left",
    fixed: true,
    width: null
  },
  {
    id: 3,
    caption: "LAST NAME",
    dataField: "enmLastName",
    alignment: "left",
    fixed: true,
    width: null
  },
  {
    id: 4,
    caption: "FIRST NAME",
    dataField: "enmFirstName",
    alignment: "left",
    fixed: false,
    width: null


  },
  {
    id: 5,
    caption: "OTHER NAMES",
    dataField: "enmOtherNames",
    alignment: "left",
    fixed: false,
    width: null

  },
  
  
  
  {
    id: 6,
    caption: "ACTIVE",
    dataField: "enmActive",
    alignment: "left",
    fixed: false,
    width: 60

  },
  {
    id: 7,
    caption: "INITIALS",
    dataField:"enmInitials",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 8,
    caption: "NICK NAME",
    dataField:"enmNickname",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 9,
    caption: "NAME 1",
    dataField:"enmEmpname1",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 10,
    caption: "NAME 2",
    dataField:"enmEmpname2",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 11,
    caption: "NAME 3",
    dataField:"enmEmpname3",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 12,
    caption: "NAME 4",
    dataField:"enmEmpname4",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 13,
    caption: "NAME 5",
    dataField:"enmEmpname5",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 14,
    caption: "NAME 6",
    dataField:"enmEmpname6",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 15,
    caption: "NAME 7",
    dataField:"enmEmpname7",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 16,
    caption: "NAME 8",
    dataField:"enmEmpname8",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 17,
    caption: "NAME 9",
    dataField:"enmEmpname9",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 18,
    caption: "NAME 10",
    dataField:"enmEmpname10",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 19,
    caption: "NAME 11",
    dataField:"enmEmpname11",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 20,
    caption: "NAME 12",
    dataField:"enmEmpname12",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 21,
    caption: "NAME 13",
    dataField:"enmEmpname13",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 22,
    caption: "NAME 14",
    dataField:"enmEmpname14",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 23,
    caption: "NAME 15",
    dataField:"enmEmpname15",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 24,
    caption: "NAME 16",
    dataField:"enmEmpname16",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    
    id: 25,
    caption: "REMARKS",
    dataField: "enmRmks",
    alignment: "left",
    fixed: false,
    width: 4000


  }


]


export const staff_name_data = [
  {
    "enmIDpk": 0,
    "titIDpk": 0,
    "enmDate": Date.parse("2023-01-13T10:03:21.288Z"),
    "enmStaffNo": "string",
    "enmLastName": 1,
    "enmFirstName": "string",
    "enmOthernames": "string",
    "enmEmpname": "string",
    "enmEmpname1": "string",
    "enmEmpname2": "string",
    "enmEmpname3": "string",
    "enmEmpname4": "string",
    "enmEmpname5": "string",
    "enmEmpname6": "string",
    "enmEmpname7": "string",
    "enmEmpname8": "string",
    "enmEmpname9": "string",
    "enmEmpname10": "string",
    "enmEmpname11": "string",
    "enmEmpname12": "string",
    "enmEmpname13": "string",
    "enmEmpname14": "string",
    "enmEmpname15": "string",
    "enmEmpname16": "string",
    "enmEmpname17": "string",
    "enmEmpname18": "string",
    "enmEmpname19": "string",
    "enmFLNamesNoSpace": "string",
    "enmLFNamesNoSpace": "string",
    "enmLFONamesNoSpace": "string",
    "enmLegacyName": "string",
    "enmInitials": "string",
    "enmNickname": "string",
    "enmTitleIDfk": 0,
    "enmOrgIDfk": 0,
    "enmActive": true,
    "enmRmks": "string",
    "enmCreationDate": "2023-01-13T10:03:21.288Z",
    "titName": "string",
    "titShtName": "string",
    "titActive": true
  }
]