// PPE

export const workshop_cols = [
  {
    id: 0,
    caption: "Workshop",
    dataField: "wsdName",
    dataType: "string",
    fixed: true,
    width: 60,
  },
  {
    id: 1,
    caption: "Town",
    dataField: "twnName",
    dataType: "string",
    fixed: true,
    width: 60,
  },
  {
    id: 2,
    caption: "Area",
    dataField: "areName",
    dataType: "string",
    fixed: true,
    width: 60,
  },
  {
    id: 3,
    caption: "Location",
    dataField: "locName",
    dataType: "string",
    fixed: true,
    width: 60,
  },
  {
    id: 4,
    caption: "Contacts",
    dataField: "wsdContact",
    dataType: "string",
    fixed: true,
    width: 60,
  },
  {
    id: 5,
    caption: "INTERCOM/Tel",
    dataField: "wsdTelNo",
    dataType: "string",
    fixed: true,
    width: 60,
  },
  {
    id: 6,
    caption: "Ext?",
    dataField: "wsdExternal",
    dataType: "boolean",
    fixed: true,
    width: 60,
  },
];

export const Batch_no_cols = [
  {
    id: 1,
    caption: "BatchNo",
    dataField: "gpsBatchNo",
    dataType: "string",
    fixed: true,
    width: 60,
  },
];

// FOR MAINTENANCE SCHEDULE MINI

export const mini_datagrid_cols = [
  {
    id: 0,
    caption: "REG. No",
    dataField: "vdtRegNo",
    dataType: "string",
    fixed: true,
    width: 60,
  },
  {
    id: 1,
    caption: "VEHICLE CLASS",
    dataField: "vhcName",
    dataType: "string",
    fixed: true,
    width: 60,
  },
  {
    id: 2,
    caption: "MAKE",
    dataField: "vmkName",
    dataType: "string",
    fixed: true,
    width: 60,
  },
  {
    id: 3,
    caption: "MODEL",
    dataField: "mdlName",
    dataType: "string",
    fixed: true,
    width: 60,
  },
  {
    id: 4,
    caption: "CHASSIS No",
    dataField: "vdtChassisNo",
    dataType: "string",
    fixed: true,
    width: 100,
  },
  {
    id: 5,
    caption: "MANUFACTURER",
    dataField: "mftName",
    dataType: "string",
    fixed: true,
    width: 100,
  },
  {
    id: 6,
    caption: "MANUF.DATE",
    dataField: "vdtManufactureDate",
    dataType: "date",
    format: "dd MM yyyy",
    width: 100,
  },
  {
    id: 7,
    caption: "SUPPLIER",
    dataField: "splName",
    dataType: "string",
    width: 100,
  },
  {
    id: 8,
    caption: "SUPPLY DATE",
    dataField: "vdtSupplyDate",
    dataType: "date",
    format: "dd MM yyyy",
    width: 100,
  },
  { id: 9, caption: "PRICE", dataField: "vdtPrice", dataType: "", width: 100 },
  {
    id: 10,
    caption: "STATUS",
    dataField: "vstName",
    dataType: "boolean",
    width: 100,
  },
  {
    id: 11,
    caption: "ENGINE TYPE",
    dataField: "egtName",
    dataType: "string",
    width: 100,
  },
  {
    id: 12,
    caption: "ASSIGNED?",
    dataField: "edtAssigned",
    dataType: "boolean",
    width: 60,
  },
  {
    id: 13,
    caption: "ACTIVE?",
    dataField: "edtActive",
    dataType: "string",
    width: 60,
  },
  {
    id: 14,
    caption: "REMARKS",
    dataField: "",
    dataType: "string",
    width: 4000,
  },
];
export const mini_datagrid_batch_cols = [
  {
    id: 0,
    caption: "Reg. No",
    dataField: "vdtRegNo",
    dataType: "string",
    fixed: true,
    width: 60,
  },
  {
    id: 1,
    caption: "Vehicle class",
    dataField: "vhcShtName",
    dataType: "string",
    fixed: true,
    width: 60,
  },
  {
    id: 2,
    caption: "Make",
    dataField: "vmkShtName",
    dataType: "string",
    fixed: true,
    width: 60,
  },
  {
    id: 3,
    caption: "Model",
    dataField: "mdlShtName",
    dataType: "string",
    fixed: true,
    width: 60,
  },
  {
    id: 4,
    caption: "Chassis No",
    dataField: "vdtChassisNo",
    dataType: "string",
    fixed: true,
    width: 100,
  },
  {
    id: 5,
    caption: "Assigned",
    dataField: "vdtAssigned",
    dataType: "boolean",
    fixed: true,
    width: 100,
  },
  {
    id: 6,
    caption: "Manufacturer",
    dataField: "mftShtName",
    dataType: "string",
    fixed: true,
  },
  {
    id: 7,
    caption: "Supplier",
    dataField: "splShtName",
    dataType: "string",
    width: 100,
  },
  {
    id: 8,
    caption: "Manuf. Date",
    dataField: "vdtManufactureDate",
    dataType: "datetime",
    format: "dd MM yyyy",
    width: 100,
  },

  {
    id: 9,
    caption: "Supply Date",
    dataField: "vdtSupplyDate",
    dataType: "date",
    format: "dd MM yyyy",
    width: 100,
  },
  {
    id: 10,
    caption: "Veh. status",
    dataField: "vstName",
    dataType: "boolean",
    width: 100,
  },
  {
    id: 11,
    caption: "Dept",
    dataField: "dptShtName",
    dataType: "string",
    width: 100,
  },
  {
    id: 12,
    caption: "Section",
    dataField: "sxnShtName",
    dataType: "boolean",
    width: 60,
  },
  {
    id: 13,
    caption: "Tank Cap.",
    dataField: "vdtTankCapacity",
    dataType: "",
    width: 60,
  },
  {
    id: 14,
    caption: "Engine Type",
    dataField: "egtShtName",
    dataType: "string",
    width: 60,
  },
  {
    id: 15,
    caption: "Fuel Type",
    dataField: "ftpShtName",
    dataType: "string",
    width: 60,
  },
  {
    id: 16,
    caption: "Power Train",
    dataField: "pwtName",
    dataType: "string",
    width: 60,
  },
  {
    id: 17,
    caption: "Remarks",
    dataField: "vdtRmks",
    dataType: "string",
    width: 4000,
  },
];

// FOR MAINTENANCE SCHEDULE

export const footer_datagrid_cols = [
  {
    id: 0,
    caption: "ID",
    dataField: "mtsIdpk",
    dataType: "",
    fixed: true,
    width: 35,
  },
  {
    id: 1,
    caption: "BATCH No",
    dataField: "mtsBatchNo",
    dataType: "",
    fixed: true,
  },
  {
    id: 2,
    caption: "REG. No",
    dataField: "vehDetailRegNo",
    dataType: "",
    fixed: true,
  },
  {
    id: 3,
    caption: "START DATE",
    dataField: "mtsStartDate",
    dataType: "datetime",
    fixed: true,
    width: null,
  },
  {
    id: 4,
    caption: "MAINT. DATE",
    dataField: "mtsMaintenanceDate",
    dataType: "datetime",
    fixed: true,
    width: null,
  },
  {
    id: 5,
    caption: "LAST ALERT DATE",
    dataField: "mtsLastAlertDate",
    dataType: "datetime",
    fixed: true,
    width: null,
  },
  {
    id: 6,
    caption: "NEXT ALERT DATE",
    dataField: "mtsNextAlertDate",
    dataType: "datetime",
    fixed: true,
    width: null,
  },
  {
    id: 7,
    caption: "MAINTENANCE TYPE",
    dataField: "mtpShtName",
    dataType: "string",
    width: null,
  },
  {
    id: 8,
    caption: "WORKSHOP",
    dataField: "wsdName",
    dataType: "string",
    width: null,
  },
  { id: 9, caption: "ACTIVE?", dataField: "mtsActive", dataType: "boolean" },
  {
    id: 10,
    caption: "RESCHEDULE PLAN",
    dataField: "mtsReschedulePlan",
    dataType: "string",
  },
  {
    id: 11,
    caption: "MODEL",
    dataField: "mdlName",
    dataType: "string",
    width: 100,
  },
  {
    id: 12,
    caption: "MAKE",
    dataField: "vmkName",
    dataType: "string",
    width: 100,
  },
  {
    id: 13,
    caption: "CLASS",
    dataField: "vhcName",
    dataType: "string",
    width: 100,
  },
  {
    id: 14,
    caption: "CHAISSIS No",
    dataField: "vdtChassisNo",
    dataType: "string",
    width: 100,
  },
  {
    id: 15,
    caption: "FREQ. (Days)",
    dataField: "mtsFrequencyDays",
    dataType: "",
  },
  { id: 16, caption: "DAYS DONE", dataField: " locShtName", dataType: "" },
  { id: 17, caption: "FREQ. (km)", dataField: "mtsFrequencyKm", dataType: "" },
  // {
  //   id: 18,
  //   caption: "LAST ALERT ON",
  //   dataField: "mtsLastAlertDate",
  //   dataType: "date",
  //   format: "ddd MMM yyyy",
  //   width: 100,
  // },
  {
    id: 19,
    caption: "NEXT ALERT ON",
    dataField: "mtsNextAlertDate",
    dataType: "date",
    format: "ddd MMM yyyy",
    width: 100,
  },
  {
    id: 20,
    caption: "CURRENT km",
    dataField: "",
    dataType: "string",
    width: 100,
  },
  { id: 21, caption: "NEXT km", dataField: "", dataType: "string", width: 100 },
  {
    id: 22,
    caption: "PM?",
    dataField: "mtpPreventiveMaintenance",
    dataType: "boolean",
  },
  { id: 23, caption: "RW?", dataField: "mtpRoadworthy", dataType: "boolean" },
  {
    id: 24,
    caption: "INS?",
    dataField: "mtpInsuranceRenewal",
    dataType: "boolean",
  },
  { id: 25, caption: "PD?", dataField: "mtsPromptDriver", dataType: "boolean" },
  { id: 26, caption: "PAO?", dataField: "mtsPromptOwner", dataType: "boolean" },
  { id: 27, caption: "PA?", dataField: "mtsPromptAdmin", dataType: "boolean" },
  {
    id: 28,
    caption: "PVC?",
    dataField: "mtsPromptVehicleController",
    dataType: "boolean",
  },
  {
    id: 29,
    caption: "PM?",
    dataField: "mtsPromptManager",
    dataType: "boolean",
  },
  {
    id: 30,
    caption: "P Dir?",
    dataField: "mtsPromptDirector",
    dataType: "boolean",
  },
  {
    id: 31,
    caption: "1st  SMS NOTIF.",
    dataField: "mtsFirstSmsPromptDays",
    dataType: "",
    width: 100,
  },
  {
    id: 32,
    caption: "2nd  SMS NOTIF.",
    dataField: "mtsSecondSmsPromptDays",
    dataType: "",
    width: 100,
  },
  {
    id: 33,
    caption: "3rd  SMS NOTIF.",
    dataField: "mtsThirdSmsPromptDays",
    dataType: "",
    width: 100,
  },
  {
    id: 34,
    caption: "4th  SMS NOTIF.",
    dataField: "mtsFourthSmsPromptDays",
    dataType: "",
    width: 100,
  },
  {
    id: 35,
    caption: "APPROV. OFFICER",
    dataField: "ownerEmpName15",
    dataType: "string",
    width: null,
  },
  {
    id: 36,
    caption: "DRIVER",
    dataField: "driverEmpName15",
    dataType: "string",
    width: null,
  },
  {
    id: 37,
    caption: "ADMINISTRATOR",
    dataField: "adminEmpName15",
    dataType: "string",
    width: null,
  },
  {
    id: 38,
    caption: "CONTROLLER",
    dataField: "controllerEmpName15",
    dataType: "string",
    width: null,
  },
  {
    id: 39,
    caption: "DEPT",
    dataField: "ownerDptShtName",
    dataType: "string",
    width: null,
  },
  {
    id: 40,
    caption: "SECTION",
    dataField: "ownerSxnShtName",
    dataType: "string",
    width: null,
  },
  { id: 41, caption: "LOC", dataField: "", dataType: "string", width: null },
  {
    id: 42,
    caption: "REMARKS",
    dataField: "mtsRmks",
    dataType: "string",
    width: 4000,
  },
];
