/* eslint-disable no-useless-computed-key */
import { Checkbox, DatePicker, Form, Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import dayjs from "dayjs";
import { TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/accordion";
import {
  Column,
  DataGrid,
  GroupPanel,
  Pager,
  Scrolling,
  SearchPanel,
} from "devextreme-react/data-grid";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import close from "../../../../../assets/close.png";
import correct from "../../../../../assets/correct.png";
import submit from "../../../../../assets/forward.png";
import newfile from "../../../../../assets/new.png";
import useFetch from "../../../../../hooks/useFetch";
import { ModalTemplate } from "../../../../../templates/modal";
import { SelectsTemplate } from "../../../../../templates/select";
import { SaveButton, UpdateButton } from "../../../../accessories/buttons";
import {
  api_url,
  navbar_height,
  saveModalData,
  toolbar_height,
  updateModalData,
} from "../../../../accessories/component_infos";
import { formPropsConst } from "../../../../accessories/constants";
import { fuel_dump_dips_cols } from "../../../../Assets-Manager/Transport/Entries/Fuel/Dump_Dips/data/datagrid_cols";


interface props {
  toggled?: boolean;
}

const StaffBeneficiaryDetails = ({ toggled }: props) => {
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const heights_out = navbar_height + news_flash_height + toolbar_height;

  //varying form height
  const form_height = 490;

  //Table Height
  const pageHeight = window.innerHeight - navbar_height; //height without navbar
  const [tableHeight, settableHeight] = useState(
    window.innerHeight - (heights_out + 70)
  ); //height without navbar and toolbar
  const [table_form_Height, settable_form_Height] = useState(
    window.innerHeight - heights_out
  ); //height without navbar and toolbar and form

  const [showTable, setShowTable] = useState(true);
  const dateFormat = " DD MMM YYYY HH: mm:ss";

  useEffect(() => {
    console.log(`inside ${toggled}`);
  }, [toggled]);

  //Refresh
  const [refresh, setRefresh] = useState(false);
  // data for datagrid
  const [data, error, loading] = useFetch(
    "api/HrEmployeeBeneficiaryDetails/GetStaffBeneficiariesAll",
    refresh
  );
  const [staffNameData, staffNameError, staffNameLoading] = useFetch(
    "api/HrEmployeeBeneficiaryDetails/GetPopCmbStaffName",
    refresh
  );
  const [relationShipData, relationShipError, relationShipLoading] = useFetch(
    "api/HrEmployeeBeneficiaryDetails/GetRelationship",
    refresh
  );

  const [IdTypeData, IdTypeError, IdTypeLoading] = useFetch(
    "api/HrEmployeeBeneficiaryDetails/GetHrIDTYpe",
    refresh
  );

  const [witnessData, witnessError, withnessLoading] = useFetch(
    "api/HrEmployeeBeneficiaryDetails/GetStaffBeneficaryWitness",
    refresh
  );

  if (data) {
    console.log(data);
  }

  // INit states
  const [disableForms, setDisableForms] = useState(true);
  const [updatingForms, setUpdatingForms] = useState(false);

  const [selectedData, setSelectedData] = useState<any>({
    parentId: "",
    staffName: { name: "", id: "" },
    beneficiaryName: "",
    relationShip: { name: "", id: "" },
    tier2: "",
    tier3: "",
    tier2EntryDate: "",
    beneficiary_DoB: "",
    tier3EntryDate: "",
    tier2ExitDate: "",
    tier3ExitDate: "",
    remarks: "",
    witnessedBy: { name: "", id: "" },
    approvedBy: "",
    idType: { name: "", id: "" },
    idNumber: "",
    beneficiaryAddress: "",
    staffAddress: "",
    active: false,
    sbfBeneficiaryDoB: "",
    sbfWitnessedByEmpIdfk: "",
    sbfRmks: "",
    sbfEditedBy: "",
    sbfEditedDate: "",
    sbfCreatedBy: "",
  });

  const [currentModalData, setCurrentModalData] = useState(saveModalData);
  const [modalIcon, setModalIcon] = useState<
    "warning" | "success" | "question"
  >("question");
  const [openModal, setOpenModal] = useState(false);

  const clearFields = () => {
    setSelectedData({
      ...selectedData,
      parentId: "",
      staffName: { name: "", id: "" },
      beneficiaryName: "",
      relationShip: { name: "", id: "" },
      tier2: "",
      tier3: "",
      tier2EntryDate: "",
      beneficiary_DoB: "",
      tier3EntryDate: "",
      tier2ExitDate: "",
      tier3ExitDate: "",
      remarks: "",
      witnessedBy: { name: "", id: "" },
      approvedBy: "",
      idType: { name: "", id: "" },
      idNumber: "",
      beneficiaryAddress: "",
      staffAddress: "",
      active: false,
      sbfBeneficiaryDoB: "",
      sbfWitnessedByEmpIdfk: "",
      sbfRmks: "",
      sbfEditedBy: "",
      sbfEditedDate: "",
      sbfCreatedBy: "",
    });
  };

  //message section
  const [saveModalState, setsaveModalState] = useState<any>({
    state: false,
    title: "",
    message: "",
    okText: "",
  });

  const [errMessage, seterrMessage] = useState<string>("");
  //modal state
  const setModal = (state: string, value: string | boolean) => {
    setsaveModalState((modalState: any) => ({ ...modalState, [state]: value }));
  };

  //--validation modal --
  const [validationModalState, setvalidationModalState] = useState<any>({
    state: false,
    title: "",
    message: "",
  });

  const setValidationModal = (state: string, value: string | boolean) => {
    setvalidationModalState((warningState: any) => ({
      ...warningState,
      [state]: value,
    }));
  };

  //validate Data
  const ValidatePostData = () => {
    // if (selectedData.beneficiaryName === "" || !selectedData.beneficiaryName) {
    //   setvalidationModalState({
    //     state: true,
    //     title: "Empty Field",
    //     message: "Please enter field data",
    //   });
    // } else if (selectedData.beneficiaryName ===  '') {
    //   setvalidationModalState({
    //     state: true,
    //     title: "Invalid beneficairy name length",
    //     message: "Beneificary Name can not be less than 2 characters.",
    //   });
    // } else {
    setModalIcon("question");
    setOpenModal(true);
    //   setsaveModalState({
    //     state: true,
    //     title: "Save Data?",
    //     message: "Are you sure you want to save this record?",
    //     okText: "Yes, Save",
    //   });
    // }
  };

  //API CALLS
  const updateData = async () => {
    //update request here
    //post request here
    try {
      //   alert(selectedItemData.icmIDpk);
      //   alert(`whole data: ${selectedItemData}`);
      const res = await axios.put(
        `${api_url}/api/HrEmployeeBeneficiaryDetails/UpdateHrStaffBeneficiary/${selectedData.parentId}`,
        {
          sbfIdpk: selectedData.parentId,
          sbfEmpIdfk: selectedData.staffName.id,
          sbfBeneficiaryName: selectedData.beneficiaryName,
          sbfRelationshipIdfk: selectedData.relationShip.id,
          sbfBeneficiaryDoB: selectedData.sbfBeneficiaryDoB,
          sbfTier2Percentage: selectedData.tier2,
          sbfTier3Percentage: selectedData.tier3,
          sbfBeneficiaryAddress: selectedData.beneficiaryAddress,
          sbfIdtypeIdfk: selectedData.idType.id,
          sbfIdnumber: selectedData.idType.idNumber,
          sbfWitnessedByEmpIdfk: selectedData.sbfWitnessedByEmpIdfk,
          sbfApprovedByEmpIdfk: selectedData.sbfApprovedByEmpIdfk,
          sbfActive: true,
          sbfRmks: selectedData.sbfRmks,
          sbfEditedBy: 1,
          sbfEditedDate: "2023-02-14T09:51:46.133Z",
        }
      );
      console.log(res);
      setOpenModal(false);
      setRefresh(!refresh);
    } catch (error: any) {
      // set states for db errors modal
      //  setErrState(true);
      //  seterrTitle(
      //    error.response ? error.response.data.message : "Server Error"
      //  );
      console.log(error);
      seterrMessage(
        error.response
          ? error.response.data.errors[0]
          : "Error connecting to server. Please contact system administrator"
      );
    }
  };

  const postData = async () => {
    //post request here
    // setModal("state", false);
    try {
      const res = await axios.post(
        `${api_url}/api/HrEmployeeBeneficiaryDetails/CreateHrBeneficiary`,
        {
          sbfEmpIDfk: selectedData.staffName.id,
          sbfBeneficiaryName: selectedData.beneficiaryName,
          sbfRelationshipIdfk: selectedData.relationShip.id,
          sbfBeneficiaryDoB: selectedData.sbfBeneficiaryDoB,
          sbfTier2Percentage: selectedData.tier2,
          sbfTier3Percentage: selectedData.tier3,
          sbfBeneficiaryAddress: selectedData.beneficiaryAddress,
          sbfIdtypeIdfk: selectedData.idType.id,
          sbfIdnumber: selectedData.idType.idNumber,
          sbfWitnessedByEmpIdfk: selectedData.sbfWitnessedByEmpIdfk,
          sbfActive: selectedData.active,
          sbfRmks: selectedData.sbfRmks,
          sbfCreatedBy: selectedData.sbfCreatedBy,
        }
      );
      console.log(res);

      setRefresh(!refresh);
    } catch (error: any) {
      //set states for db errors modal
      //   setErrState(true);
      //   seterrTitle(
      //     error.response ? error.response.data.message : "Server Error"
      //   );
      //   seterrMessage(
      //     error.response
      //       ? error.response.data.errors[0]
      //       : "Error connecting to server. Please contact system administrator"
      //   );
    }
  };

  return (
    <div>
      <style
        dangerouslySetInnerHTML={{
          __html: `
           input[type="text"]:disabled {
            background: #fffbeb;
            height:22px
          }  

          
          
          .dx-datagrid-header-panel .dx-toolbar {
            margin-bottom: 1.5px;
        }

          .filterTodos .dx-datagrid-headers{
              margin-top: 10px;
          }
            `,
        }}
      />
      <ModalTemplate
        icon={modalIcon}
        disableCancel={modalIcon === "question" ? false : true}
        cancelText={currentModalData.cancelText}
        open={openModal}
        okHandler={() => {
          currentModalData === saveModalData
            ? postData()
            : currentModalData === updateModalData
              ? updateData()
              : setOpenModal(false);
        }}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        message={currentModalData.message}
        okText={currentModalData.okText}
        title={currentModalData.title}
      />

      <div className="flex w-full">
        {" "}
        <div className="" style={{ width: "79.5%" }}>
          {" "}
          {toggled && (
            <div className="w-full   border rounded-md">
              <div className="text-xs bg-slate-100 rounded-t-md py-1 px-2">
                Beneficiaries Details
              </div>
              {/* The form */}
              <div className="py-2 flex ">
                <Form
                  {...formPropsConst}
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 20 }}
                  className="ml-2 "
                  style={{ width: "25%" }}
                >
                  {/* <Select
                            onChange = {()=>{}}
                           
                            className= {'mb-0'}
                            placeholder={useCallFunc ? placeHolder : data}
                            allowClear
                            disabled={useCallFunc ? disabled : formdisabled}
                            style={selectStyle}
                        >
                            {options ? options.map((option:any,index:number)=>{
                                return(
                                    <option  key={index} value ={option[idexpr!]}>{option[dataexp!]  && option[dataexp!]}</option>
                                )
                            }) : formTableData.map((option:any,index:number)=>{
                                return(
                                    <option  key={index} value ={option[idexpr!]}>{option[dataexp!]  && option[dataexp!]}</option>
                                )
                            })}
                        </Select> */}
                  <SelectsTemplate
                    disabledCustomWidth={true}
                    cusWidth="220px"
                    selectedValue={(e: any) => {
                      setSelectedData({
                        ...selectedData,
                        ["staffName"]: { name: "", id: e },
                      });
                    }}
                    placeHolder={selectedData.staffName.name}
                    label="Staff Name"
                    options={staffNameData}
                    disabled={disableForms}
                    useCallFunc={true}
                    dataexp="empName"
                    idexpr="empIdpk"
                  />
                  <Form.Item
                    label={<p className="text-xs">{"Beneficiary Name"}</p>}
                    style={{ marginBottom: 0, width: "calc(100%)" }}
                  >
                    <Form.Item
                      rules={[{ required: true }]}
                      style={{
                        display: "inline-block",
                        width: "calc(92%)",
                        marginBottom: "0.5%",
                      }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <Input
                          value={selectedData.beneficiaryName}
                          size="small"
                          allowClear
                          placeholder=""
                          disabled={disableForms}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>

                  <SelectsTemplate
                    disabledCustomWidth={true}
                    cusWidth="220px"
                    placeHolder={selectedData.relationShip.name}
                    label="Relationship"
                    useCallFunc
                    options={relationShipData}
                    selectedValue={(e: any) => {
                      setSelectedData({
                        ...selectedData,
                        ["relationShip"]: { name: "", id: e },
                      });
                      console.log(selectedData);
                    }}
                    disabled={disableForms}
                    dataexp="rlsName"
                    idexpr="rlsIdpk"
                  />

                  <Form.Item
                    label={<p className="text-xs">{"% (Tier 2 & 3)"}</p>}
                    style={{ marginBottom: 0 }}
                  >
                    <Form.Item
                      style={{ display: "inline-block", width: "calc(46% )" }}
                    >
                      <Input
                        disabled={disableForms}
                        value={selectedData.tier2}
                        onChange={(e) => {
                          setSelectedData({
                            ...selectedData,
                            ["tier2"]: e.target.value,
                          });
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      labelCol={{ span: 10 }}
                      style={{
                        display: "inline-block",
                        width: "calc(46% )",
                        marginLeft: "2.5%",
                      }}
                    >
                      <Input
                        disabled={disableForms}
                        value={selectedData.tier3}
                        onChange={(e) => {
                          setSelectedData({
                            ...selectedData,
                            ["tier3"]: e.target.value,
                          });
                        }}
                      />
                    </Form.Item>
                  </Form.Item>

                  <Form.Item
                    label={<p className="text-xs">{"Beneficiary DoB"}</p>}
                    style={{ marginBottom: 0 }}
                  >
                    <Form.Item
                      rules={[{ required: true }]}
                      style={{
                        display: "inline-block",
                        width: "calc(100%)",
                        marginTop: "",
                      }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <DatePicker
                          allowClear={false}
                          onChange={(e, dateTime) => {
                            setSelectedData({
                              ...selectedData,
                              ["beneficiary_DoB"]: dayjs(e),
                            });
                          }}
                          value={selectedData.beneficiary_DoB}
                          disabled={disableForms}
                          size="small"
                          placeholder="Beneficiary DoB"
                          style={{ width: "93.5%" }}
                          format={dateFormat}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>
                  <Form.Item
                    label={<p className="text-xs">{"Tier 2 Entry Date"}</p>}
                    style={{ marginBottom: 0 }}
                  >
                    <Form.Item
                      rules={[{ required: true }]}
                      style={{
                        display: "inline-block",
                        width: "calc(100%)",
                        marginTop: "",
                      }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <DatePicker
                          allowClear={false}
                          onChange={(e, dateTime) => {
                            setSelectedData({
                              ...selectedData,
                              ["tier2EntryDate"]: dayjs(e),
                            });
                          }}
                          value={selectedData.tier2EntryDate}
                          disabled={disableForms}
                          size="small"
                          placeholder="Start Date"
                          style={{ width: "93.5%" }}
                          format={dateFormat}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>
                  <Form.Item
                    label={<p className="text-xs">{"Tier 3 Entry Date"}</p>}
                    style={{ marginBottom: 0 }}
                  >
                    <Form.Item
                      rules={[{ required: true }]}
                      style={{
                        display: "inline-block",
                        width: "calc(100%)",
                        marginTop: "",
                      }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <DatePicker
                          allowClear={false}
                          onChange={(e, dateTime) => {
                            setSelectedData({
                              ...selectedData,
                              ["tier3EntryDate"]: dayjs(e),
                            });
                          }}
                          value={selectedData.tier3EntryDate}
                          disabled={disableForms}
                          size="small"
                          placeholder="Start Date"
                          style={{ width: "93.5%" }}
                          format={dateFormat}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>
                  <Form.Item
                    label={<p className="text-xs">{"Tier 2 Exit Date"}</p>}
                    style={{ marginBottom: 0 }}
                  >
                    <Form.Item
                      rules={[{ required: true }]}
                      style={{
                        display: "inline-block",
                        width: "calc(100%)",
                        marginTop: "",
                      }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <DatePicker
                          allowClear={false}
                          onChange={(e, dateTime) => {
                            setSelectedData({
                              ...selectedData,
                              ["tier2ExitDate"]: dayjs(e),
                            });
                          }}
                          value={selectedData.tier2ExitDate}
                          disabled={disableForms}
                          size="small"
                          placeholder="Exit Date"
                          style={{ width: "93.5%" }}
                          format={dateFormat}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>
                  <Form.Item
                    label={<p className="text-xs">{"Tier 3 Exit Date"}</p>}
                    style={{ marginBottom: 0 }}
                  >
                    <Form.Item
                      rules={[{ required: true }]}
                      style={{
                        display: "inline-block",
                        width: "calc(100%)",
                        marginTop: "",
                      }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <DatePicker
                          allowClear={false}
                          onChange={(e, dateTime) => {
                            setSelectedData({
                              ...selectedData,
                              ["tier3ExitDate"]: dayjs(e),
                            });
                          }}
                          value={selectedData.tier3ExitDate}
                          disabled={disableForms}
                          size="small"
                          placeholder="Exit Date"
                          style={{ width: "93.5%" }}
                          format={dateFormat}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>

                  <Form.Item
                    label={<p className="text-xs">{"Remarks"}</p>}
                    style={{ marginBottom: 0, marginTop: "1%" }}
                  >
                    <Form.Item
                      style={{
                        display: "inline-block",
                        marginBottom: "1px",
                        width: "calc(541.5%)",
                      }}
                      className=" mb-1 mr-2 "
                    >
                      <div className="flex">
                        <TextArea
                          readOnly={disableForms}
                          style={{
                            resize: "none",
                            maxHeight: "40px",
                            minHeight: "40px",
                            height: "40px",
                            width: "",
                          }}
                          className="o mr-2 overflow-y-scroll resize-none"
                          rows={3}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>
                </Form>

                {/* Second */}
                <Form
                  {...formPropsConst}
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 20 }}
                  className="ml-24 "
                  style={{ width: "25%" }}
                >
                  <SelectsTemplate
                    disabledCustomWidth={true}
                    cusWidth="220px"
                    placeHolder={selectedData.witnessedBy.name}
                    label="witnessed By"
                    useCallFunc
                    options={witnessData}
                    selectedValue={(e: any) => {
                      setSelectedData({
                        ...selectedData,
                        ["witnessedBy"]: { name: "", id: e },
                      });
                      console.log(selectedData);
                    }}
                    disabled={disableForms}
                    dataexp="empName"
                    idexpr="empIDpk"
                  />
                  <Form.Item
                    label={<p className="text-xs">{"Approved By"}</p>}
                    style={{ marginBottom: 0, width: "calc(100%)" }}
                  >
                    <Form.Item
                      name="issuedBy"
                      rules={[{ required: true }]}
                      style={{
                        display: "inline-block",
                        width: "calc(93.5%)",
                        marginBottom: "0.5%",
                      }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <Input
                          disabled={disableForms}
                          size="small"
                          allowClear
                          placeholder=""
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>

                  <SelectsTemplate
                    disabledCustomWidth={true}
                    cusWidth="220px"
                    placeHolder={selectedData.idType.name}
                    label="ID Type"
                    useCallFunc
                    options={IdTypeData}
                    selectedValue={(e: any) => {
                      setSelectedData({
                        ...selectedData,
                        ["idType"]: { name: "", id: e },
                      });
                      console.log(selectedData);
                    }}
                    disabled={disableForms}
                    dataexp="idtName"
                    idexpr="idtIdpk"
                  />
                  <Form.Item
                    label={<p className="text-xs">{"ID Number"}</p>}
                    style={{ marginBottom: 0, width: "calc(100%)" }}
                  >
                    <Form.Item
                      rules={[{ required: true }]}
                      style={{
                        display: "inline-block",
                        width: "calc(93.5%)",
                        marginBottom: "0.5%",
                      }}
                    >
                      {" "}
                      <div className="flex flex-row items-center">
                        <Input
                          disabled={disableForms}
                          size="small"
                          allowClear
                          placeholder=""
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>
                  <Form.Item
                    label={<p className="text-xs">{"Beneficiary Address"}</p>}
                    style={{ marginBottom: 0, marginTop: "" }}
                  >
                    <Form.Item
                      style={{
                        display: "inline-block",
                        width: "calc(355%)",
                        minWidth: "600px",
                      }}
                      className=" mb-1 mr-2 "
                    >
                      <div className="flex">
                        <TextArea
                          value={selectedData.beneficiaryAddress}
                          onChange={(e: any) => {
                            setSelectedData({
                              ...selectedData,
                              ["beneficiaryAddress"]: e,
                            });
                          }}
                          readOnly={disableForms}
                          style={{
                            resize: "none",
                            height: "50px",
                            minWidth: "600px",
                          }}
                          className="o mr-2 overflow-y-scroll resize-none"
                          rows={3}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>
                  <Form.Item
                    label={<p className="text-xs">{"Staff Address"}</p>}
                    style={{ marginBottom: 0, marginTop: "" }}
                  >
                    <Form.Item
                      style={{
                        display: "inline-block",
                        width: "calc(355%)",
                        minWidth: "580px",
                      }}
                      className="  mr-2 "
                    >
                      <div className="flex">
                        <TextArea
                          value={selectedData.staffAddress}
                          onChange={(e: any) => {
                            setSelectedData({
                              ...selectedData,
                              ["empAddress"]: e,
                            });
                          }}
                          readOnly={disableForms}
                          style={{
                            resize: "none",
                            height: "50px",
                            minWidth: "600px",
                          }}
                          className="o mr-2 overflow-y-scroll resize-none"
                          rows={3}
                        />
                      </div>
                    </Form.Item>
                  </Form.Item>
                  <Form.Item
                    style={{ display: "inline-block", marginBottom: "2px" }}
                    label={<p className="text-xs">{"Active"}</p>}
                    className="w-full mb-1"
                  >
                    <div className=" items-end">
                      <div
                        className=" flex pr-2  flex-row justify-between"
                        style={{ marginBottom: "1px" }}
                      >
                        <div>
                          <Checkbox
                            value={selectedData.active}
                            onChange={(e: any) => {
                              setSelectedData({
                                ...selectedData,
                                ["active"]: e,
                              });
                            }}
                            disabled={disableForms}
                          />
                        </div>
                      </div>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            </div>
          )}
          <div className=" mt-1 flex justify-between px-1">
            <div className="flex">
              <div className="text-xs ml-4 text-indigo-500"> Totals: </div>
              <div className="text-xs ml-4 text-indigo-500">Tier 2-0%</div>
              <div className="text-xs ml-4 text-indigo-500"> Tier 3-0%</div>

              <li
                id="newRecord"
                style={{ borderWidth: "1px" }}
                className="hover:cursor-pointer ml-40 mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
              >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                  {" "}
                  <img className="" src={correct} />{" "}
                </span>
              </li>
              <li
                id="newRecord"
                style={{ borderWidth: "1px" }}
                className="hover:cursor-pointer ml-2 mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
              >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                  {" "}
                  <img className="" src={close} />{" "}
                </span>
              </li>
            </div>
            <div className="flex">
              {updatingForms === true ? (
                <UpdateButton
                  handleUpdate={() => {
                    ValidatePostData();
                    setCurrentModalData(updateModalData);
                  }}
                />
              ) : (
                <SaveButton
                  useCallbackFunc={true}
                  disableButton={disableForms}
                  handleSave={() => {
                    console.log("er");
                    setCurrentModalData(saveModalData);
                    ValidatePostData();
                  }}
                />
              )}
              <li
                id="newRecord"
                style={{ borderWidth: "1px" }}
                className="hover:cursor-pointer  mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
              >
                <span
                  onClick={() => {
                    setDisableForms(false);
                    clearFields();
                  }}
                  className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"
                >
                  {" "}
                  <img className="pr-1 " src={newfile} />
                  New{" "}
                </span>
              </li>
              <li
                id="newRecord"
                style={{ borderWidth: "1px" }}
                className="hover:cursor-pointer   border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
              >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                  {" "}
                  <img className="pr-1 " src={submit} />
                  Submit{" "}
                </span>
              </li>
            </div>
          </div>
          <div className="pt-0.5">
            <DataGrid
              dataSource={data}
              showBorders={true}
              columnWidth={150}
              showRowLines={true}
              showColumnLines={true}
              //Remember to change
              height={toggled === true ? 510 : tableHeight}
              onRowClick={(e) => {
                console.log(e);
                setDisableForms(true);
                setSelectedData({
                  ...selectedData,
                  ["parentId"]: e.data.sbfIdpk,
                  ["staffName"]: { name: e.data.empName, id: e.data.empIdpk },
                  ["beneficiaryName"]: e.data.sbfBeneficiaryName,
                  ["relationShip"]: {
                    name: e.data.rlsName,
                    id: e.data.rlsIDpk,
                  },
                  ["tier2"]: e.data.sbfTier2Percentage,
                  ["tier3"]: e.data.sbfTier3Percentage,
                  ["tier2EntryDate"]: e.data.tier2EntryDate,
                  ["tier3EntryDate"]: e.data.tier3EntryDate,
                  ["tier2ExitDate"]: e.data.tier2ExitDate,
                  ["tier3ExitDate"]: e.data.tier3ExitDate,
                  ["remarks"]: e.data.sbfRmks,
                  ["witnessedBy"]: {
                    id: e.data.witnessedByEmpId,
                    name: e.data.witnessedByEmpName1,
                  },
                  ["approvedBy"]: e.data.approvedBy,
                  ["idType"]: { id: e.data.idtIdpk, name: e.data.idtName },
                  ["idNumber"]: e.data.sbfIdnumber,
                  ["beneficiaryAddress"]: e.data.sbfBeneficiaryAddress,
                  ["staffAddress"]: e.data.empAddress,
                  ["active"]: e.data.sbfActive,
                });
                setUpdatingForms(false);
              }}
              onRowDblClick={() => {
                setDisableForms(false);
                setUpdatingForms(true);
              }}
            >
              <SearchPanel visible={true} width={200} />
              <GroupPanel visible={true} />
              <Pager visible={false} />

              {/* STAFF DETAILS COLUMNS */}
              {/* FIXED COLUMNS */}

              {fuel_dump_dips_cols.map((data) => { //wrong col
                return (
                  <Column
                    key={data.id}
                    caption={data.caption}
                    dataField={data.dataField}
                    alignment={"left"}
                    fixed={data.fixed}
                    width={data.width === null ? 150 : data.width}
                  />
                );
              })} 

              {/* SCROLLBAR  */}

              <Scrolling columnRenderingMode="virtual" />
            </DataGrid>
            <div className="text-xs text-indigo-500 p-1">Done</div>
          </div>
        </div>
        <div style={{ width: "calc(.5%)", height: "100%" }}></div>
        <div style={{ width: "calc(20%)", height: "100%" }} className="">
          <div className="w-full h-full ">
            <TabPanel className="w-full h-full ">
              {/* <Item title={"Comments"}>
                <div style={{ height: pageHeight - 130 }}>
                  <Estate_Maintenance_Comment />
                </div>
              </Item>

              <Item title={"Audit Trail"}>
                <div style={{ height: pageHeight - 130 }}>
                  <Estate_Maintenance_Attachments />
                </div>
              </Item>

              <Item title={"Attachments"}>
                <div style={{ height: pageHeight - 130 }}></div>
              </Item> */}
            </TabPanel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffBeneficiaryDetails;
