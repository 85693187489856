import { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { LineDetailsToolbars } from "./line-details-toolbar";

import LineMainDetails from "./line-main-details";
import TransformerDetails from "./line-main-details";
import dayjs from "dayjs";
import {
  searchCriteriaTypes,
  searchTextTypes,
  statusTypes,
} from "../../../human-resource/setups/data/types/selectsTypes";

const LineDetails = () => {
  const taskDetailsHeight = "309px";

  const [tasks_update_state, setTask_update_state] = useState<boolean>(true);
  const [task_datagrid_height, set_task_datagrid_height] = useState(
    `calc(100% - ${taskDetailsHeight})`
  );
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const current_page_route = "/tasks-todos";
  const view = searchParams.get("view");
  const taskDataGrid = "task-data-grid-extended";
  const [showTable, setShowTable] = useState(true);

  // switch button toggler
  const switch_toggle = () => {
    setShowTable(!showTable);
  };

  const genFormat = "YYYY-MM-DD";
  const today = dayjs().format(genFormat);
  const initialStart = dayjs()
    .subtract(1, "month")
    .startOf("year")
    .format(genFormat);
  const [startDate, setStartDate] = useState<searchTextTypes>({
    text: initialStart,
    temp: initialStart,
  });
  const [endDate, setEndDate] = useState<searchTextTypes>({
    text: today,
    temp: today,
  });

  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });
  const [activeStatus, setActiveStatus] = useState<statusTypes>({
    temp: "",
    status: "",
  });
  const [searchCriteria, setsearhCriteria] = useState<searchCriteriaTypes>({
    temp: 0,
    index: 0,
  });

  const [states, setStates] = useState({
    selectedRecord : {}
  })

  const setState = (key: string, value: any) => {
    setStates((prev: any) => ({ ...prev, [key]: value }));
  };

  const searchCriteriaData = [
    { id: 0, value: "Nomenclature" },
    { id: 1, value: "Source Station" },
    { id: 2, value: "Destination Station" },
    { id: 3, value: "Length" },
    { id: 4, value: "Conductor Type" },
    { id: 5, value: "Conductor Code Name" },
    { id: 6, value: "Conductor Cross Sectional Area" },
    { id: 7, value: "Bundled?" },
    { id: 8, value: "Operating Voltage" },
    { id: 9, value: "Positive Sequence Resistance" },
    { id: 10, value: "Positive Sequence Reactance" },
    { id: 11, value: "Zero Sequence Resistance" },
    { id: 12, value: "Susceptance" },
    { id: 13, value: "Resistance on 100MVA Base" },
    { id: 14, value: "Reactance on 100MVA Base" },
    { id: 15, value: "Susceptance on 100MVA Base" },
    { id: 16, value: "Zero Sequence Resistance" },
    { id: 17, value: "Zero Sequence Reactance" },
    { id: 18, value: "Zero Sequence Susceptance" },
    { id: 19, value: "Zero Sequence Resistance on 100MVA Base" },
    { id: 20, value: "Zero Sequence Reactance on 100MVA Base" },
    { id: 21, value: "Zero Sequence Susceptance on 100MVA Base" },
    { id: 22, value: "Thermal Limit" },
    { id: 23, value: "Surge Impedance Loading" },
    { id: 24, value: "Construction Start Date" },
    { id: 25, value: "Commissioning Date" },
    { id: 26, value: "Decommissioning Date" }
  ];

  return (
    <>
      <div className="">
        <LineDetailsToolbars
          toggler={switch_toggle}
          searchTextOnchange={(searchValue) => {
            setSearchText({ temp: searchValue, text: searchText.text });
          }}
          handleFind={() => {
            setStartDate({ text: startDate.temp, temp: startDate.temp });
            setEndDate({ text: endDate.temp, temp: endDate.temp });
            setSearchText({ temp: searchText.temp, text: searchText.temp });
            setActiveStatus({
              temp: activeStatus.temp,
              status: activeStatus.temp,
            });
            setsearhCriteria({
              temp: searchCriteria.temp,
              index: searchCriteria.temp,
            });
          }}
          searchCriteriaOnchange={(newIndex, criteria) => {
            setsearhCriteria({ temp: newIndex, index: searchCriteria.index });
          }}
          handleRefresh={() => {
            setSearchText({ temp: "", text: "" });
            setsearhCriteria({ temp: 0, index: 0 });
            setActiveStatus({ temp: "", status: "" });
          }}
          startDate={startDate.temp}
          endDate={endDate.temp}
          startDateChanged={(date) =>
            setStartDate({
              temp: dayjs(date).format(genFormat),
              text: startDate.text,
            })
          }
          endDateChanged={(date) =>
            setEndDate({
              temp: dayjs(date).format(genFormat),
              text: endDate.text,
            })
          }
          searchCriteriaData={searchCriteriaData}
        />
        {/* <Staff_customtoolbar /> */}
      </div>
      <div className=" m-1  border rounded-md">
        <div className="text-xs bg-slate-100 rounded-t-md py-1 px-2">
          Line Details
        </div>
        <div className=" mb-0.5  ">
          <LineMainDetails
            toggled={showTable}
            activeStatus={activeStatus.status}
            searchCriteria={searchCriteria.index}
            searchText={searchText.text}
            selectedRecord={states.selectedRecord}
            setState={setState}
          />
        </div>
      </div>
    </>
  );
};

export default LineDetails;
