import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { selectExprsTypes } from '../../../components/human-resource/setups/data/types/selectsTypes'
import { regex } from '../../../components/accessories/component_infos'

interface tier4State {
  select0Value : number | null
  select1Value : number | null
  select2Value : number | null
  selectExprs : selectExprsTypes[]

  name : string | null
  shortname : string | null
  order : number
  remarks : string | null
  value: number | null
  active : boolean | null
  data : any
  idExpr : string | null
  nameExpr : string | null
  shortnameExpr : string | null
  
  saveDataOnclick : boolean,
  updateDataOnclick : boolean,
  warningState : boolean,
  warningDataExpr : any
  successModalState : boolean,
  successText : any

  existsUrl : string,
  checkExistsTrigger : boolean, 
  checkExistsOnclick : boolean,
}

var initialState = {
  select0Value : null,
  select1Value : null,
  select2Value : null,
  selectExprs : [{idExpr : 'idkey', nameExpr : 'datakey', label : 'label'},{idExpr : 'idkey2', nameExpr : 'datakey2', label : 'label2'},{idExpr : 'idkey3', nameExpr : 'datakey2', label : 'label3'},{idExpr : 'idkey2', nameExpr : 'datakey4', label : 'label4'}],

  name : null,
  shortname : null,
  order : 0,
  remarks : null,
  active : true,
  value: 0,
  data : [],
  idExpr : null,
  nameExpr : null,
  shortnameExpr : null,
  
  saveDataOnclick : false,
  updateDataOnclick : false,
  warningState : false,
  warningDataExpr : 'name',
  successModalState : false,
  successText : '',

  existsUrl : '',
  checkExistsTrigger : false,
  checkExistsOnclick : false,
}as tier4State | any

const tier4Slice = createSlice({
  name: 'tier4',
  initialState,
  reducers: {
    //set state reducers [name ,shortname ...]
    setTier4Value(state, action: PayloadAction<any>) {
      // set value of given expr
      state[action.payload.expr] = action.payload.value;
    },
    tier4SaveOnclick(state){ //handle form data save
      const select0Value = state.select0Value;
      const select1Value = state.select1Value;
      const select2Value = state.select1Value;
      const name = state.name;
      const shortname = state.shortname;

      if (!select0Value) {
          state.warningDataExpr = state.select0Value.label;
          state.warningState = true;
      }
      else if (!select1Value) {
          state.warningDataExpr = state.select1Value.label;
          state.warningState = true;
      }
      else if (!select2Value) {
          state.warningDataExpr = state.select2Value.label;
          state.warningState = true;
      }
     // name validation
     if (!name || name.replace(/\s/g,"") == "") {
      state.warningDataExpr = 'name';
      state.warningState = true;
      return;
    }
    if (regex.test(name[0])) {
      state.warningDataExpr = 'validName';
      state.warningState = true;
      return;
    }
    // short name validation
    else if (!shortname || shortname.replace(/\s/g,"") == "") {
      state.warningDataExpr = 'shortname';
      state.warningState = true;
      return;
    }
    else if (regex.test(shortname[0])) {
      state.warningDataExpr = 'validShortname';
      state.warningState = true;
      return;
    }
    //exists check
      else if (state.existsUrl){
        state.checkExistsOnclick = true;
        state.checkExistsTrigger = !state.checkExistsTrigger;
      }
      else {
          state.saveDataOnclick = true;
      }
    },
    tier4UpdateOnclick(state){ //handle form data save
      const select0Value = state.select0Value;
      const select1Value = state.select1Value;
      const select2Value = state.select1Value;

      const name = state.name;
      const shortname = state.shortname;

      if (!select0Value) {
        state.warningDataExpr = state.select0Value.label;
        state.warningState = true;
      }
      if (!select1Value) {
        state.warningDataExpr = state.select1Value.label;
        state.warningState = true;
      }
      if (!select2Value) {
        state.warningDataExpr = state.select2Value.label;
        state.warningState = true;
      }
     // name validation
     if (!name || name.replace(/\s/g,"") == "") {
      state.warningDataExpr = 'name';
      state.warningState = true;
      return;
    }
    if (regex.test(name[0])) {
      state.warningDataExpr = 'validName';
      state.warningState = true;
      return;
    }
    // short name validation
    else if (!shortname || shortname.replace(/\s/g,"") == "") {
      state.warningDataExpr = 'shortname';
      state.warningState = true;
      return;
    }
    else if (regex.test(shortname[0])) {
      state.warningDataExpr = 'validShortname';
      state.warningState = true;
      return;
    }
    //exists check
      else if (state.existsUrl){
        state.checkExistsOnclick = true;
        state.checkExistsTrigger = !state.checkExistsTrigger;
      }
      else {
        state.updateDataOnclick = true;
      }
    },
  },
})

export const { setTier4Value,tier4SaveOnclick,tier4UpdateOnclick} = tier4Slice.actions
export default tier4Slice.reducer

function async(arg0: (route: string) => void) {
  throw new Error('Function not implemented.')
}
