export const sia_updates_cols = [
    // {id: 1,caption : "ID", dataField : 'boolean', dataType : "boolean", fixed : true,width:60,visible: false},
    {id: 2,caption : "DATE", dataField : 'siuDate', dataType : "string", fixed : true,width:100},
    {id: 3,caption : "% COMPLETE", dataField : 'siuPercentComplete', dataType : "number", fixed : true,width:100},
    {id: 4,caption : "TASK UPDATE", dataField : 'siuUpdate', dataType : "string", fixed : true,width:100},
    {id: 5,caption : "DONE BY", dataField : 'siuDoneBy', dataType : "string", fixed : true,width:100},
    {id: 6,caption : "UPDATED BY", dataField : 'Submitted', dataType : "string", fixed : true, width:100},
    {id: 7,caption : "JOB TITLE", dataField : 'Unsubmitted', dataType : "string",width:100},
    {id: 8,caption : "UPDATER INTERCOM", dataField : 'Locked', dataType : "string",width:100},
    {id: 9,caption : "CREATED ON", dataField : 'Job Title', dataType : "string",width:100},
    {id: 10,caption : "COMPLETION DATE", dataField : 'Dept', dataType : "string",width:100},
    {id: 11,caption : "REMARKS", dataField : 'Remarks', dataType : "string",width:4000},
]
export const periods_cols = [
    {id: 0,caption : "ID", dataField : 'prdIdpk',dataType:"string", fixed : true,width:60,visible: false , },
    {id: 1,caption : "NAME", dataField : 'prdName', dataType : "string", fixed : true,width:100},
    {id: 1,caption : "START DATE", dataField : 'prdStartDate', dataType : "datetime", fixed : true,width:100},
    {id: 1,caption : "END DATE", dataField : 'prdEndDate', dataType : "datetime", fixed : true,width:100},
  
]