/* eslint-disable react/jsx-pascal-case */
import { Form, InputNumber } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setGeneralValue,
  settask_form_disable,
} from "../../../../../../../features/generalSlice";
import useFetch from "../../../../../../../hooks/useFetch";
import { BottomBtns } from "../../../../../../../templates/butttons";
import { DateTemplate } from "../../../../../../../templates/date";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { SelectsTemplate } from "../../../../../../../templates/select";
import { TextareaTemplate } from "../../../../../../../templates/textarea";
import SetupsDatalistTemplat from "../../../../../../human-resource/setups/data/setups_datalist_template";
import {
  datesTypes,
  modalPropTypes,
  modalTypes,
  selectObjectTypes,
  selectsTypes,
} from "../../../../../../human-resource/setups/data/types/selectsTypes";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import { task_form_disable } from "../../../../../../../features/Task&Todo/Task/TaskDetails";
import { useCrudFunc } from "../../../../../../../functions/crud";
import InputSelect_template from "../../../../../../../templates/InputSelect";
import { InputsTemplate } from "../../../../../../../templates/input";
import { debounce } from "lodash";
import {
  Fetch_CardNo,
  Fetch_CardPin,
  Fetch_RegionIssued,
  Fetch_ServiceProvider,
  Fetch_TownIssued,
} from "../../../../../../../features/apiCalls/CardDetails";
import React from "react";
import { useAxiosService } from "../../../../../../../general/requestConfig";

interface props {
  busyloader: (text?: string) => void;
  refreshGrid: () => void;
  clearSelected: () => void;
  selectedField: any;
  data: any;
  setSelectedField: (e: any) => void;
  disabled?: boolean | undefined;
  disabledCustomWidth?: boolean;
}
interface StateTypes {
  startdate: string;
  enddate: string;
  serviceProvider: selectObjectTypes | any;
  cardNumber: string;
  cardPin: string;
  regionIssued: selectObjectTypes | any;
  townIssued: selectObjectTypes | any;
  active: boolean;
  selectedRecordId: number;
  order: number;
  remarks: string;
  refresh: boolean;
  busyLoader: string;
  confirmModal: modalPropTypes;
  warningModal: modalPropTypes;
  successModal: modalPropTypes;
  serviceProviderRefresh: boolean;
  cardNumRefresh: boolean;
  cardPinRefresh: boolean;
  regionIssuedRefresh: boolean;
  townIssuedRefresh: boolean;

  isOpenDropdown?: boolean;
  isOpenDropdown1?: boolean;
  isOpenDropdown2?: boolean;
  isOpenDropdown3?: boolean;
  isOpenDropdown4?: boolean;
  isOpenDropdown5?: boolean;
  isLoading?: boolean;
}
export interface ResponseType_Lookup {
  serviceProviderResult: ServiceProviderResult[];
  cardNoResult: CardNoResult[];
  cardPinResult: CardPinResult[];
  regionIssuedResult: RegionIssuedResult[];
}

export interface CardNoResult {
  cdtIdpk: number;
  cdtCardNo: string;
}

export interface CardPinResult {
  cdtIdpk: number;
  cdtCardPin: string;
}

export interface RegionIssuedResult {
  rgnIdpk: number;
  rgnName: string;
}

export interface ServiceProviderResult {
  spdIdpk: number;
  spdName: string;
}
export interface TownIssued {
  twnIdpk: number;
  twnName: string;
}
const CardDtailsForm = ({
  busyloader,
  clearSelected,
  refreshGrid,
  data: formData,
  selectedField,
  setSelectedField,
  disabled = undefined,
}: props) => {
  const fetcher = useAxiosService("innox");
  const dispatch = useDispatch();
  const editedDate = dayjs().format();
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const form_access_disable = useSelector(
    (state: RootState) => state.general.formDisabled
  );

  const formdisabled = useSelector(
    (state: RootState) => state.general.formDisabled
  ); //form disabled mode from general redux slice

  const ServiceProvider_Data = useSelector(
    (state: RootState) => state.CardDetails.ServiceProviderData
  );
  const CardNo_Data = useSelector(
    (state: RootState) => state.CardDetails.CardNoData
  );
  const CardPin_Data = useSelector(
    (state: RootState) => state.CardDetails.CardPinData
  );
  const RegionIssued_Data = useSelector(
    (state: RootState) => state.CardDetails.RegionIssuedData
  );
  const TownIssued_Data = useSelector(
    (state: RootState) => state.CardDetails.TownIssuedData
  );

  const [PostDataFunc, UpdateDataFunc] = useCrudFunc();
  const [states, setState] = useState<StateTypes>({
    startdate: editedDate,
    enddate: editedDate,
    serviceProvider: { id: 0, value: "" },
    cardNumber: "",
    cardPin: "",
    regionIssued: { id: 0, value: "" },
    townIssued: { id: 0, value: "" },
    order: 0,
    active: true,
    remarks: "",
    selectedRecordId: 0,
    refresh: false,
    busyLoader: "",
    confirmModal: { state: false, title: "", message: "", action: "post" },
    warningModal: { state: false, title: "", message: "" },
    successModal: { state: false, title: "", message: "" },
    serviceProviderRefresh: false,
    cardNumRefresh: false,
    cardPinRefresh: false,
    regionIssuedRefresh: false,
    townIssuedRefresh: false,
    isOpenDropdown: false,
    isOpenDropdown1: false,
    isOpenDropdown2: false,
    isOpenDropdown3: false,
    isOpenDropdown4: false,
    isOpenDropdown5: false,
    isLoading: false,
  });

  //handle state update
  const updateState = (
    stateName: string,
    value: boolean | number | string | selectObjectTypes | modalPropTypes
  ) => {
    setState((prevState) => ({ ...prevState, [stateName]: value }));
  };

  const {
    confirmModal,
    successModal,
    warningModal,
    startdate,
    enddate,
    serviceProvider,
    cardNumber,
    cardPin,
    order,
    regionIssued,
    townIssued,
    selectedRecordId,
    active,
    remarks,
    serviceProviderRefresh,
    cardNumRefresh,
    cardPinRefresh,
    regionIssuedRefresh,
    townIssuedRefresh,
  } = states;

  const [townIssued_lookup, _w, __v]: [
    townIssued_lookup: TownIssued[],
    _: any,
    _: any
  ] = useFetch(
    `AssetMgrTmsCardDetail/GetCardDetailTownIssuedLookup?region=${"central"}&pagenumber=${1}&pagesize=${2}`
  );

  const [cardDetails_lookup, setLookup] = React.useState<ResponseType_Lookup>();
  const [loading, setLoading] = React.useState<boolean>();

  React.useEffect(() => {
    (() => {
      setLoading(true);
      fetcher({
        url: `AssetMgrTmsCardDetail/GetEntryLookup`,
        params: { pagenumber: 1, pagesize: 5 },
        method: "GET",
      }).then((res) => {
        setLookup(res);
      });
      setLoading(false);
    })();
  }, []);

  const datalists: selectsTypes[] = [
    {
      id: 6,
      label: "Service Provider",
      idExpr: "spdIdpk",
      dataExpr: "spdName",
      optionsData:
        states.serviceProvider?.length > 0
          ? ServiceProvider_Data
          : cardDetails_lookup?.serviceProviderResult,
      defaultValue: states.serviceProvider?.value ?? "",
      stateName: "serviceProvider",
      refreshStateName: "serviceProviderRefresh",
      refresh: serviceProviderRefresh,
      isOpenDropdown: states.isOpenDropdown5,
      isOpenDropdownKey: "isOpenDropdown5",
      singleEndpoint: Fetch_ServiceProvider,
    },
    {
      id: 2,
      label: "Card No",
      idExpr: "cdtIdpk",
      dataExpr: "cdtCardNo",
      // optionsData: cardnodata,
      optionsData:
        states.cardNumber?.length > 0
          ? CardNo_Data
          : cardDetails_lookup?.cardNoResult,
      defaultValue: states.cardNumber,
      stateName: "cardNumber",
      refreshStateName: "cardNumRefresh",
      refresh: cardNumRefresh,
      isOpenDropdown: states.isOpenDropdown1,
      isOpenDropdownKey: "isOpenDropdown1",
      singleEndpoint: Fetch_CardNo,
    },
    {
      id: 3,
      label: "Card PIN",
      idExpr: "cdtIdpk",
      dataExpr: "cdtCardPin",
      optionsData:
        states.cardPin?.length > 0
          ? CardPin_Data
          : cardDetails_lookup?.cardPinResult,
      defaultValue: states.cardPin,
      stateName: "cardPin",
      refreshStateName: "cardPinRefresh",
      refresh: cardPinRefresh,
      isOpenDropdown: states.isOpenDropdown2,
      isOpenDropdownKey: "isOpenDropdown2",
      singleEndpoint: Fetch_CardPin,
    },
  ];

  const [employeeId, userId] = useAccountInfo();
  const [privType, setPrivType] = useState("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);
  const saveAccessPriv = () => {
    setPrivType("save");
    if (savePriv === true) {
      dispatch(setGeneralValue({ expr: "formDisabledPriv", value: false }));
      postData("validate");
    } else {
      updateState("warningModal", {
        state: true,
        title: "Access Denied!",
        message: "No Access Privilege",
      });
      dispatch(setGeneralValue({ expr: "formDisabledPriv", value: true }));
      return;
    }
  };
  const newAccessPriv = () => {
    setPrivType("save");
    if (savePriv === true) {
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      populateStatesFromGrid(true);
    } else {
      updateState("warningModal", {
        state: true,
        title: "Access Denied!",
        message: "No Access Privilege",
      });
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      return;
    }
  };

  const updateAccessPriv = () => {
    setPrivType("update");
    if (updatePriv === true) {
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      update("validate");
    } else {
      updateState("warningModal", {
        state: true,
        title: "Access Denied!",
        message: "No Access Privilege",
      });
      return;
    }
  };

  //validation
  const validataFields = (): boolean => {
    console.log("All states: ", states);
    if (serviceProvider.id === 0 || !serviceProvider.id) {
      updateState("warningModal", {
        state: true,
        title: "Select Provider!",
        message: "Please select service provider.",
      });
      return false;
    } else if (!cardNumber || cardNumber.replace(/\s/g, "") === "") {
      updateState("warningModal", {
        state: true,
        title: "Select Card Number!",
        message: "Please select card number.",
      });
      return false;
    } else if (!cardPin || cardPin.replace(/\s/g, "") === "") {
      updateState("warningModal", {
        state: true,
        title: "Select Card Pin!",
        message: "Please select card pin.",
      });
      return false;
    } else if (regionIssued.id === 0 || !regionIssued.id) {
      updateState("warningModal", {
        state: true,
        title: "Select Region!",
        message: "Please select region issued.",
      });
      return false;
    } else if (townIssued.id === 0 || !townIssued.id) {
      updateState("warningModal", {
        state: true,
        title: "Select Provider!",
        message: "Please select town issued.",
      });
      return false;
    } else if (!order || order === 0 || isNaN(Number(order))) {
      updateState("warningModal", {
        state: true,
        title: "Incorrect Order!",
        message: "Please enter correct Order from 1.",
      });
      return false;
    }

    return true;
  };

  const disableFields = () => {
    //disable Update Mode
    dispatch(setGeneralValue({ expr: "updateMode", value: false }));

    //disable forms
    dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
  };

  const populateStatesFromGrid = (clearFieldsMain?: boolean) => {
    clearFieldsMain === true && setSelectedField && setSelectedField({});
    const run = (clearFields?: boolean) => {
      const populateInstances = [
        {
          state: "serviceProvider",
          value: clearFields
            ? { id: 0, value: "" }
            : { id: selectedField.spdIdpk, value: selectedField.spdName },
        },
        {
          state: "cardNumber",
          value: clearFields ? "" : selectedField.cdtCardNo,
        },
        {
          state: "cardPin",
          value: clearFields ? "" : selectedField.cdtCardPin,
        },
        {
          state: "regionIssued",
          value: clearFields
            ? { id: 0, value: "" }
            : { id: 1, value: selectedField.rgnName },
        },
        {
          state: "townIssued",
          value: clearFields
            ? { id: 0, value: "" }
            : { id: selectedField.twnIdpk, value: selectedField.twnName },
        },
        {
          state: "startdate",
          value: clearFields
            ? dayjs(new Date()).format()
            : selectedField.cdtIssueDate,
        },
        {
          state: "enddate",
          value: clearFields
            ? dayjs(new Date()).format()
            : selectedField.cdtExpiryDate,
        },
        { state: "order", value: clearFields ? 0 : selectedField?.cdtOrder },
        {
          state: "active",
          value: clearFields ? true : selectedField.cdtActive,
        },
        { state: "remarks", value: clearFields ? "" : selectedField.cdtRmks },
        {
          state: "selectedRecordId",
          value: clearFields ? 0 : selectedField.cdtIdpk,
        },
      ];

      for (let instance of populateInstances)
        updateState(instance.state, instance.value);
    };
    typeof selectedField !== "object" || Object.keys(selectedField).length === 0
      ? run(true)
      : run(clearFieldsMain);
  };

  const issuedFields: selectsTypes[] = [
    {
      id: 0,
      defaultValue: states.regionIssued.value,
      idExpr: "rgnIdpk",
      dataExpr: "rgnName",
      label: "Region Issued",
      optionsData:
        states.regionIssued?.length > 0
          ? RegionIssued_Data
          : cardDetails_lookup?.regionIssuedResult,
      stateName: "regionIssued",
      refreshStateName: "regionIssuedRefresh",
      isOpenDropdown: states.isOpenDropdown3,
      isOpenDropdownKey: "isOpenDropdown3",
      singleEndpoint: Fetch_RegionIssued,
    },
    {
      id: 1,
      defaultValue: states.townIssued.value,
      idExpr: "twnIdpk",
      dataExpr: "twnName",
      label: "Town Issued",
      // optionsData: towndata,
      optionsData:
        states.townIssued?.length > 0 ? TownIssued_Data : townIssued_lookup,
      stateName: "townIssued",
      refreshStateName: "townIssuedRefresh",
      isOpenDropdown: states.isOpenDropdown4,
      isOpenDropdownKey: "isOpenDropdown4",
      singleEndpoint: Fetch_TownIssued,
    },
  ];

  const dateFields: datesTypes[] = [
    {
      id: 0,
      label: "Issue Date",
      stateName: "startDate",
      defaultValue: states.startdate,
    },
    {
      id: 1,
      label: "Expiry Date",
      stateName: "enddate",
      defaultValue: states.enddate,
    },
  ];

  const postData = async (action: "validate" | "post") => {
    console.log("All states: ", states);
    if (action === "validate") {
      // validate fields
      validataFields() &&
        updateState("confirmModal", {
          state: true,
          title: "Save Record",
          message: "Are you sure you want to save this record?",
          action: "post",
        });
      return;
    }

    try {
      updateState("confirmModal", {
        state: false,
        title: "Save Record",
        message: "Are you sure you want to save this record?",
        action: "post",
      });

      const res = await PostDataFunc(
        `AssetMgrTmsCardDetail/CreateCardDetail`,
        {
          cdtCardNo: cardNumber,
          cdtCardPin: cardPin,
          cdtServiceProviderIdfk: serviceProvider.id,
          cdtIssueDate: startdate,
          cdtExpiryDate: enddate,
          cdtTownIdfk: townIssued.id,
          cdtActive: active,
          cdtRmks: remarks,
          cdtOrder: order,
          cdtCreatedBy: `${userId}`,
          formCode: "string",
          action: "string",
        },
        "Created the Asset Mgr-Trans-Card Detail - " + cardNumber
      );

      disableFields();
      refreshGrid();
      clearSelected();
      populateStatesFromGrid(false);
      busyloader("");

      if (res) {
        updateState("successModal", {
          state: true,
          title: "Record saved",
          message: "Record saved successfully",
        });
      }
    } catch (error: any) {
      //set states for db errors modal
      updateState("warningModal", {
        state: true,
        title: "Unexpected Error!",
        message: error["message"],
      });
    }
  };

  //update data
  const update = (action: "validate" | "update") => {
    const getData = async () => {
      //update
      disableConfirmModal();
      busyloader("Updating record");
      try {
        await UpdateDataFunc(
          `AssetMgrTmsCardDetail/UpdateCardDetail?Id=${selectedRecordId}`,
          {
            cdtCardNo: cardNumber,
            cdtCardPin: cardPin,
            cdtServiceProviderIdfk: serviceProvider.id,
            cdtIssueDate: dayjs(startdate).format(),
            cdtExpiryDate: dayjs(enddate).format(),
            cdtTownIdfk: townIssued.id,
            cdtActive: active,
            cdtRmks: remarks,
            cdtOrder: order,
            cdtEditedBy: `${userId}`,
            cdtEditedDate: editedDate,
            formCode: "string",
            action: "string",
          },
          `Edited the Asset Mgr-Trans-Card Detail from ${selectedField.cdtCardNo} to ${cardNumber}`
        );
        enableSuccessModal("Update", "updated");

        disableFields();
        refreshGrid();
        clearSelected();
        populateStatesFromGrid(false);
      } catch (error: any) {
        updateState("warningModal", {
          state: true,
          title: "Unexpected Error!",
          message: error["message"],
        });
      }
      busyloader("");
    };

    if (action === "validate") {
      // validate fieldds
      validataFields() &&
        updateState("confirmModal", {
          state: true,
          title: "Update Record",
          message: "Are you sure you want to update this record?",
          action: "update",
        });
      return;
    } else {
      getData();
    }
  };

  const enableSuccessModal = (
    titleActionText: string,
    messageActionText: string
  ) => {
    updateState("successModal", {
      state: true,
      title: `${titleActionText} Successful`,
      message: `Record ${messageActionText} successfully`,
    });
  };

  const disableConfirmModal = () => {
    updateState("confirmModal", {
      state: false,
      title: "",
      message: "",
      action: "post",
    });
  };
  // modal instances
  const modals: modalTypes[] = [
    //validation
    {
      disableCancel: true,
      icon: "warning",
      open: warningModal.state,
      okHandler: () =>
        updateState("warningModal", { state: false, title: "", message: "" }),
      cancelHandler: () =>
        updateState("warningModal", {
          state: false,
          title: "string",
          message: "string",
        }),
      message: warningModal.message,
      okText: "Ok",
      title: warningModal.title,
      cancelText: "No, cancel",
    },

    //confirmation
    {
      disableCancel: false,
      icon: "question",
      open: confirmModal.state,
      okHandler:
        confirmModal.action === "update"
          ? () => update("update")
          : () => postData("post"),
      cancelHandler: () =>
        updateState("confirmModal", {
          state: false,
          title: "string",
          message: "string",
        }),
      message: states.confirmModal.message,
      okText: confirmModal.action === "update" ? "Yes, Update" : "Yes, Save",
      title: states.confirmModal.title,
      cancelText: "No, cancel",
    },

    //success
    {
      disableCancel: true,
      icon: "success",
      open: successModal.state,
      okHandler: () =>
        updateState("successModal", { state: false, title: "", message: "" }),
      cancelHandler: () =>
        updateState("successModal", {
          state: false,
          title: "string",
          message: "string",
        }),
      message: successModal.message,
      okText: "Ok",
      title: successModal.title,
      cancelText: "No, cancel",
    },
  ];

  useEffect(() => {
    populateStatesFromGrid(false);
  }, [selectedField]);

  useEffect(() => {
    if (form_access_disable === true || form_access_disable === undefined) {
      dispatch(setGeneralValue({ expr: "TransformDisabled", value: true }));
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      dispatch(task_form_disable(true));
      dispatch(settask_form_disable(false));
    } else {
      dispatch(setGeneralValue({ expr: "TransformDisabled", value: false }));
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      dispatch(task_form_disable(false));
      dispatch(settask_form_disable(true));
    }
  }, [form_access_disable]);

  return (
    <div className="px-2">
      {modals.map(
        (
          {
            disableCancel,
            icon,
            okHandler,
            cancelHandler,
            open,
            cancelText,
            okText,
            message,
            title,
          }: modalTypes,
          index
        ) => {
          return (
            <div key={index}>
              <ModalTemplate
                icon={icon && icon}
                disableCancel={disableCancel}
                cancelText={cancelText && cancelText}
                open={open}
                okHandler={okHandler}
                cancelHandler={cancelHandler}
                message={message}
                okText={okText}
                title={title}
              />
            </div>
          );
        }
      )}
      {/* FORM HEADER */}

      <p
        id={"form_header"}
        style={{ borderColor: borderTheme }}
        className="pt-1 border-[1px] border-t-1 rounded-t-md px-2 w-full font-medium bg-slate-100"
      >
        {"Card Details"}
      </p>
      <Form
        style={{ borderColor: borderTheme }}
        className="w-full border border-slate-100 rounded-b-md border-t-0 flex justify-between py-1 px-2 "
        name="basic"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 19 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        size="small"
      >
        <section style={{ width: "500px" }} className="">
          {/* date and time */}
          <div className="">
            {dateFields.map(
              ({ defaultValue, label, stateName }: datesTypes) => {
                return (
                  <div className=" mb-0.5 ">
                    <DateTemplate
                      selectedDate={dayjs(defaultValue)}
                      width={361}
                      label={label}
                      span={false}
                      changeDate={(date: Dayjs) => {
                        updateState(stateName!, dayjs(date).format());
                      }}
                    />
                  </div>
                );
              }
            )}
          </div>

          {/* service providers */}
          {/* <div className=" mb-0.5">
            <InputSelect_template
              isOpenDropdown={states.isOpenDropdown}
              placeHolder={states.serviceProvider.value}
              useCallFunc
              idexpr="spdIdpk"
              dataexp="spdName"
              label="Service Provider"
              options={cardDetails_lookup.serviceProviderResult}
              // options={
              //   states.serviceProvider?.value
              //     ? ServiceProvider_Data
              //     : cardDetails_lookup.serviceProviderResult
              // }
              selectedValue={(e: any) => {
                console.log("selected", e);
                updateState("serviceProvider", {
                  id: e.spdIdpk,
                  value: e.spdName,
                });
              }}
              handleSearch={useCallback(
                debounce((e) => {
                  updateState(`serviceProvider`!, e);
                  // updateState("isLoading", true);
                  dispatch(Fetch_ServiceProvider(e) as unknown as any);

                  updateState(`isOpenDropdownKey`, !states.isOpenDropdown);
                }, 500), // Adjust the debounce delay as needed
                []
              )}
              handleRefresh={() => {
                updateState("serviceProvider", { id: 0, value: "" });
                updateState("serviceProviderRefresh", !serviceProviderRefresh);
              }}
            />
          </div> */}

          {/* selects [defined controls] */}
          {datalists.map(
            ({
              label,
              id,
              idExpr,
              dataExpr,
              optionsData,
              defaultValue,
              stateName,
              refresh,
              isOpenDropdown,
              refreshStateName,
              singleEndpoint,
              isOpenDropdownKey,
            }) => {
              return (
                <div key={id} className=" mb-1">
                  <InputSelect_template
                    useCallFunc
                    placeHolder={defaultValue}
                    isOpenDropdown={isOpenDropdown}
                    handleRefresh={() => {
                      updateState(stateName!, "");
                      updateState(refreshStateName!, !refreshStateName);
                    }}
                    handleFocus={(e: any) => {
                      updateState(isOpenDropdownKey, !isOpenDropdown);
                    }}
                    handleSearch={debounce((e) => {
                      updateState(stateName!, e);
                      updateState("isLoading", true);
                      dispatch(singleEndpoint(e));

                      updateState(isOpenDropdownKey, true);
                    })}
                    options={optionsData}
                    // options={[]}
                    selectStyle={{ height: 24 }}
                    idexpr={idExpr}
                    dataexp={dataExpr!}
                    key={label}
                    label={label}
                    span={false}
                    selectedValue={(e: any) => {
                      if (stateName === "serviceProvider") {
                        updateState(stateName!, {
                          id: e.spdIdpk,
                          value: e.spdName,
                        });
                      } else {
                        updateState(stateName!, e[dataExpr!]);
                      }
                      updateState(isOpenDropdownKey, false);
                    }}
                  />
                </div>
              );
            }
          )}

          {/* Region issued */}
          {issuedFields.map(
            (
              {
                defaultValue,
                label,
                idExpr,
                dataExpr,
                optionsData,
                stateName,
                refreshStateName,
                isOpenDropdown,
                isOpenDropdownKey,
                singleEndpoint,
              }: selectsTypes,
              _
            ) => {
              return (
                <div className=" mb-0.5" key={_}>
                  <InputSelect_template
                    useCallFunc
                    isOpenDropdown={isOpenDropdown}
                    isLoading={states.isLoading}
                    placeHolder={defaultValue}
                    idexpr={idExpr}
                    dataexp={dataExpr}
                    label={label}
                    options={optionsData}
                    handleSearch={debounce((e) => {
                      updateState(stateName!, e);
                      // UpdateState("isLoading", true);
                      dispatch(singleEndpoint(e));

                      updateState(isOpenDropdownKey, !isOpenDropdown);
                    })}
                    handleRefresh={() => {
                      updateState(stateName!, "");
                      updateState(refreshStateName!, !refreshStateName);
                    }}
                    handleFocus={(e: any) => {
                      updateState(isOpenDropdownKey, !isOpenDropdown);
                    }}
                    // selectedValue={(e: any) => {
                    //   UpdateState(stateName!, e?.vdtRegNo);
                    //   UpdateState(isOpenDropdownKey, !isOpenDropdown);
                    // }}
                    selectedValue={(e: any) => {
                      updateState(stateName!, {
                        id: stateName === "townIssued" ? e.twnIdpk : e.rgnIdpk,
                        value:
                          stateName === "townIssued" ? e.twnName : e.rgnName,
                      });
                      updateState(isOpenDropdownKey, false);
                    }}
                    // selectedValue={(e: any) => {
                    //   console.log("selected value: ", e);
                    //   updateState(stateName!, e[dataExpr!])
                    // }}
                  />
                  {/* <InputSelect_template
                    key={id}
                    placeHolder={defaultValue}
                    handleRefresh={() => { }}
                    label={label}
                    options={optionsData}
                    // disabled={form_disable}
                    // disabled={DisableForm}
                    useCallFunc
                    idexpr={idExpr}
                    dataexp={dataExpr}
                    isLoading={states.isLoading}
                    isOpenDropdown={isOpenDropdown}
                    handleSearch={useCallback(
                      debounce((e) => {
                        UpdateState(stateName!, e);
                        // UpdateState("isLoading", true);
                        dispatch(singleEndpoint(e));

                        UpdateState(isOpenDropdownKey, !isOpenDropdown);
                      }, 500), // Adjust the debounce delay as needed
                      []
                    )}
                    // handleSearch={useCallback((e: any) => {
                    //   // UpdateState(stateName!, e);

                    //   // dispatch(
                    //   //   setGeneralValue({ expr: "rxregNo", value:  getRegNo() })
                    //   // );
                    //  dispatch(fetchRegNo())
                    //   console.log("searching", e)

                    // })}
                    handleFocus={(e: any) => {
                      UpdateState(isOpenDropdownKey, !isOpenDropdown);
                    }}
                    selectedValue={(e: any) => {
                      UpdateState(stateName!, e?.vdtRegNo);
                      UpdateState(isOpenDropdownKey, !isOpenDropdown);
                    }}
                  field={field}
                  registerName={registerName}
                  errors={errors}
                  /> */}
                </div>
              );
            }
          )}

          {/* town issued*/}
          <div className="mb-1">
            <Form.Item
              label={
                <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                  {"Order"}
                </p>
              }
            >
              <div className="w-full flex dark:bg-slate-100 dark:text-darkModeSkin-base mb-1">
                {/* <InputNumber
                  className="h-6 w-[355px] flex"
                  size="small"
                  min={0}
                  max={100}
                  value={states.order}
                  disabled={disabled !== undefined ? disabled : formdisabled}
                  onChange={(value: any) => {
                    updateState("order", value);
                  }}
                /> */}
                <InputsTemplate
                  span
                  orderOnchange={(value: any) => {
                    updateState("order", value);
                  }}
                  numberOnly={false}
                  style={{ width: 352.5, height: 24 }}
                  inputStyle={{ height: 24 }}
                  defaultValue={states.order}
                  useCallbackFunc={true}
                  disabledStatus={
                    disabled !== undefined ? disabled : formdisabled
                  }
                  placeholder="Please enter order from 1"
                />
              </div>
            </Form.Item>
          </div>

          <div className="">
            <BottomBtns
              useCallbackFunc
              defaultValue={active}
              additionalCheckBoxValue={[active]}
              addActiveBorders={true}
              onNewClick={() => {
                newAccessPriv();
              }}
              checkboxChange={(value) => {
                updateState("active", value);
              }}
              saveHandler={() => saveAccessPriv()}
              updateHandler={() => {
                updateAccessPriv();
              }}
              span={false}
            />
          </div>
        </section>

        {/* remarks */}
        <section className=" " style={{ width: "calc(100% - 450px)" }}>
          <TextareaTemplate
            useCallbackFunc
            defaultValue={remarks}
            setCallbackValue={(value) => {
              updateState("remarks", value);
            }}
            MarginLeft={50}
            label="Remarks"
            height={260}
          />
        </section>
      </Form>
    </div>
  );
};

export default CardDtailsForm;
