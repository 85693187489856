import React, { useCallback, useState } from "react";
import {
  DataGrid,
  Column,
  Scrolling,
  Selection,
  Editing,
} from "devextreme-react/data-grid";

import { left_alert_drivers_cols } from "../data/datagrid_cols";

interface props {
  toggler?: () => void;
  datgridCol?: any;
  customHeightHook?: any;
}

export const Left_Grid_Form = ({ datgridCol }: props) => {
  const [selectedTask_update, setSelectedTask_update] = useState();

  const selectGrid_update: any = useCallback<any>((e: any) => {
    e.component.byKey(e.currentSelectedRowKeys[0]).done((item: any) => {
      setSelectedTask_update(item);
    });
  }, []);

  return (
    <div>
      <div
        style={{ width: "100%", marginBottom: "5px" }}
        className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base"
      >
        <DataGrid
          allowColumnReordering={true}
          height={330}
          columnWidth={datgridCol}
          rowAlternationEnabled={true}
          showBorders={true}
          showRowLines={true}
          allowColumnResizing={true}
          onSelectionChanged={selectGrid_update}
          wordWrapEnabled={false}
          focusedRowEnabled={true}
        >
          {left_alert_drivers_cols?.map((data_cols: any) => {
            return (
              <Column
                key={data_cols.id}
                dataType={data_cols.dataType}
                caption={data_cols.caption}
                dataField={data_cols.dataField}
                alignment={data_cols.alignment}
                fixed={data_cols.fixed}
                width={data_cols.width === null ? 30 : data_cols.width}
              />
            );
          })}

          <Editing mode={"row"} />
          <Scrolling
            columnRenderingMode="virtual"
            mode="virtual"
            showScrollbar={"always"}
          />
          <Selection mode="multiple" />
        </DataGrid>
      </div>
    </div>
  );
};
