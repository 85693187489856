export const hr_setups_country_columns = [
    {id : 0, caption : 'ID',dataField : 'ctyIdpk',datatype : 'number', width : 80, fixed : true, visible : false},
    {id : 1, caption : 'COUNTRY', dataField : 'ctyName',datatype : 'string', width : 160, fixed : true , visible : true},
    {id : 2, caption : 'SHORT NAME', dataField : 'ctyShtName',datatype : 'string', width : 160, fixed : true , visible : true},
    {id : 3, caption : 'NATIONALITY', dataField : 'ctyNationality',datatype : 'string', width : 160, fixed : true , visible : true},
    {id : 4, caption : 'SHOW IN TMS?', dataField : 'ctyShowInTransport',datatype : 'boolean', width : 130, fixed : true , visible : true},
    {id : 5, caption : 'DEFAULT?', dataField : 'ctyDefault',datatype : 'boolean', width : 130, fixed : true , visible : true},
    {id : 6, caption : 'ORDER', dataField : 'ctyOrder',datatype : 'number', width : 130, fixed : true , visible : true},
    {id : 7, caption : 'ACTIVE?', dataField : 'ctyActive',datatype : 'boolean', width : 90, fixed : true , visible : true},
    {id : 8, caption : 'REMARKS',dataField : 'ctyRmks',datatype : 'string', width : 5000, fixed : false , visible : true}
]
