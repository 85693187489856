export const Alerts_cols = [
  {
    id: 0,
    caption: "Alert",
    dataField: "altIdpk",
    dataType: "",
    width: "",
    visible: false,
  },
  {
    id: 1,
    caption: "",
    dataField: "algAlert",
    dataType: "",
    width: 555,
    visible: true,
    alignment: "left",
  },
];
