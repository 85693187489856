import { Select } from "antd";
import { useCallback, useEffect, useState } from "react";
import { Item } from "devextreme-react/accordion";
import { TabPanel } from "devextreme-react";
import BaseDetailsTab from "./baseDetails";
import OtherDetails from "./other-details";
import GPSDetailsTab from "./gps-details";
import OtherParameters from "./other-parameters";
import { transformers_TH } from "../../Transformers/data/transformers-data";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import {
  task_form_disable,
  task_footer_table_selected,
  task_footer_update,
} from "../../../../features/Task&Todo/Task/TaskDetails";
import {
  navbar_height,
  toolbar_height,
  api_url,
  api_base_url,
} from "../../../accessories/component_infos";
import { ImageUpload } from "./imageUpload";
import Datagrid_template from "../../../../templates/Datagrid";
import { GetDataFunc } from "../../../../functions/get";
import { string } from "zod";
import { update } from "lodash";

const { Option } = Select;

interface props {
  toggled?: boolean;
}
interface stateTypes {
  tappedWinding: string;
  tapChangeRange: string;
  tapChangeStep: string;
  deg: string;
  normalTap: string;
  ratedOutput: string;
  ratedVoltageI: string;
  ratedVoltageII: string;
  ratedVoltageIII: string;
  ratedPowerI: string;
  ratedPowerII: string;
  ratedPowerIII: string;
  zhBase: string;
  zxBase: string;
  zyBase: string;
  resistanceBase: string;
}

const TransformerDetails = ({ toggled }: props) => {

  const [states, setStates] = useState<stateTypes>({
    tappedWinding: "",
    tapChangeRange: "",
    tapChangeStep: "",
    deg: "",
    normalTap: "",
    ratedOutput: "",
    ratedVoltageI: "",
    ratedVoltageII: "",
    ratedVoltageIII: "",
    ratedPowerI: "",
    ratedPowerII: "",
    ratedPowerIII: "",
    zhBase: "",
    zxBase: "",
    zyBase: "",
    resistanceBase: "",
  });
  const updateState = (key: string, value: any) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };
  const {
    tappedWinding,
    tapChangeRange,
    tapChangeStep,
    deg,
    normalTap,
    ratedOutput,
    ratedVoltageI,
    ratedVoltageII,
    ratedVoltageIII,
    ratedPowerI,
    ratedPowerII,
    ratedPowerIII,
    zhBase,
    zxBase,
    zyBase,
    resistanceBase,
  } = states;
 
  const populateFields = (clearFields?: boolean) => {
    // console.log(selectedData);
  
    const states = [
      {
        key: "tappedWinding",
        value: clearFields ? "" : selectedRecord[0].tfdTappedWinding,
      },
      {
        key: "tapChangeRange",
        value: clearFields ? "" : selectedRecord[0].tfdTapChangerRange,
      },
      {
        key: "tapChangeStep",
        value: clearFields ? "" : selectedRecord[0].tfdTapChangerStep,
      },
      {
        key: "deg",
        value: clearFields ? "" : selectedRecord[0].tfdPercentImpedanceAt75Deg,
      },
      {
        key: "normalTap",
        value: clearFields ? "" : selectedRecord[0].tfdNominalTap,
      },
      {
        key: "ratedOutput",
        value: clearFields ? "" : selectedRecord[0].tfdRatedOutput,
      },
      {
        key: "ratedVoltageI",
        value: clearFields ? "" : selectedRecord[0].tfdRatedVoltageI,
      },
      {
        key: "ratedVoltageII",
        value: clearFields ? "" : selectedRecord[0].tfdRatedVoltageIi,
      },
      {
        key: "ratedVoltageIII",
        value: clearFields ? "" : selectedRecord[0].tfdRatedVoltageIii,
      },
      {
        key: "ratedPowerI",
        value: clearFields ? "" : selectedRecord[0].tfdRatedPowerI,
      },
      {
        key: "ratedPowerII",
        value: clearFields ? "" : selectedRecord[0].tfdRatedPowerIi,
      },
      {
        key: "ratedPowerIII",
        value: clearFields ? "" : selectedRecord[0].tfdRatedPowerIii,
      },
      {
        key: "zhBase",
        value: clearFields ? "" : selectedRecord[0].tfdZhAt100MvabaseAt75Deg,
      },
      {
        key: "zxBase",
        value: clearFields ? "" : selectedRecord[0].tfdZxAt100MvabaseAt75Deg,
      },
      {
        key: "zyBase",
        value: clearFields ? "" : selectedRecord[0].tfdZyAt100MvabaseAt75Deg,
      },
      {
        key: "resistanceBase",
        value: clearFields
          ? ""
          : selectedRecord[0].tfdResistanceAt100MvabaseAt75Deg,
      },
    ];
  
    //set states
    for (let instance of states) updateState(instance.key, instance.value);
  };
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const [tabs, setTabs] = useState<String[]>([
    "Base Details",
    "Other Details",
    "GPS Details",
    "Other Parameters",
  ]);

  const heights_out = navbar_height + news_flash_height + toolbar_height;
  const dispatch = useDispatch();

  //varying form height
  const form_height = 490;

  const refreshDB = useSelector((state: RootState) => state.general.refresh);

  //Table Height
  const pageHeight = window.innerHeight - navbar_height; //height without navbar
  const [tableHeight, settableHeight] = useState(
    window.innerHeight - (heights_out + 70)
  ); //height without navbar and toolbar
  const [table_form_Height, settable_form_Height] = useState(
    window.innerHeight - heights_out
  ); //height without navbar and toolbar and form
  const [currentIndex, setCurrentIndex] = useState(0);
  const [DBdata, setDBdata] = useState<any>([]);

  const [showTable, setShowTable] = useState(true);

  // const dataGridData = GetDataFunc(`${api_base_url}/TransformerDetails/GetAllTransformerDetails`)

  // onSelect

  const [selectedRecord, setSelectedRecord] = useState<any>([]);
  const selectRecord = useCallback((e: any) => {
    e.component.byKey(e.currentSelectedRowKeys[0]).done((e: any) => {
      setSelectedRecord(e);
      //disable forms
      dispatch(task_form_disable(true));
    });
  }, []);

  // post selected record to redux
  dispatch(task_footer_table_selected([selectedRecord]));

  const allowUpdate = (e: any) => {
    //enable forms
    dispatch(task_form_disable(false));

    //enable Update Mode
    dispatch(task_footer_update(true));
    console.log(e);
  };

  //   function onSelectionChanged(args:any) {
  //    if(args){
  //        console.log(args.value)
  //        setCurrentIndex(args.value)
  //    }
  //      }

  //   Endpoint Data

  const GetDBdata = async () => {
    const res = await axios.get(
      `${api_url}/api/AssetTamEntTransformerDetail/GetAssetTamLueTransformerDetails`,
      {
        headers: {
          "Content-Type": ["application/json", "text/plain", "charset=uft-8"],
        },
      }
    );
    console.log(res.data.data);
    setDBdata(res.data.data);
  };

  useEffect(() => {
    GetDBdata();
    // console.log(`inside ${toggled}`)
    console.log("hey terere ");
  }, [refreshDB]);

  return (
    <div>
      <style
        dangerouslySetInnerHTML={{
          __html: `
           input[type="text"]:disabled {
            background: #fffbeb;
          }  
          
          .dx-datagrid-header-panel .dx-toolbar {
            margin-bottom: 1.5px;
        }

          .filterTodos .dx-datagrid-headers{
              margin-top: 10px;
          }

            `,
        }}
      />

      {toggled && (
        <div className="border-b-4 pb-1 flex ">
          <div className="w-[60%]">
            <TabPanel className="w-full h-full flex">
              <Item title={"Base Details"}>
                <BaseDetailsTab otherDeatailsStates={states} />
              </Item>

              <Item title={"Other Details"}>
                <OtherDetails  states={states} updateState={updateState}/>
              </Item>

              <Item title={"GPS Details"}>
                <GPSDetailsTab />
              </Item>

              <Item title={"Other Parameters"}>
                <OtherParameters />
              </Item>
            </TabPanel>
          </div>
          <div className="w-[40%] ">
            <ImageUpload />
          </div>
        </div>
      )}

      <div className="pt-0.5">
        <Datagrid_template
          gridheight={400}
          showColumnHeaders={true}
          columns={transformers_TH}
          data={[]}
          disablePaging
          disableGroupPanel
        />
        <div className="text-xs text-indigo-500 p-1">Done</div>
      </div>
    </div>
  );
};

export default TransformerDetails;
