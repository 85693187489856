import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import  Datagrid_template from "../../../../../templates/Datagrid";
import { navbar_height,  toolbar_height } from "../../../../accessories/component_infos";
import { HrSetupsCustomToolbar } from "../../../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import { leaveRequestcolumns } from "./data/leaveRequestsColumns";
import { LeaveRequestsForm } from "./widgets/leaveRequestsForm";
import { RequestsToolbar } from "../../../../accessories/custom-toolbars/requestsToolbar";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
export const LeaveRequests = forwardRef(({a}:any, ref)=>{
    const news_flash_height = useSelector((state:RootState)=>state.general.newsflashHeight);
    //navigation
    const setupPageRoute = window.location.pathname; //get current route of page
    const datagridOnlySearchParam = 'table-only'  //search param in datagrid mode
    const formModeSearchParam = 'form-and-table' //search param in form mode mode
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    //miscellaneous heights
    const miscHeight = 7;
    
    // All heights out
    const heights_out = navbar_height + news_flash_height + toolbar_height;

    //varying form height
    const [form_height,set_form_height] = useState<number>(437);
    const pass_form_height=(height:number)=>{
        set_form_height(height)
    }

    // Page resize height responsiveness
    // on page resize height responsiveness
    const [trigger,setTrigger] = useState<boolean>(false);
    useImperativeHandle(ref, () => ({
        test: () => {
            settableHeight(window.innerHeight - (heights_out + miscHeight -4));
        }
    }));

    // handle interface switch
    const toggle_staff_form = ():void =>{
        const view = searchParams.get('view');

        (view === null || view === formModeSearchParam) ? 
        navigate({
            pathname : setupPageRoute,
            search : createSearchParams({
                view : datagridOnlySearchParam,
            }).toString(),
        })
        :
        navigate({
            pathname : setupPageRoute,
            search : createSearchParams({
                view : formModeSearchParam,
            }).toString()
        });
    }

    //Table Height
    const pageHeight = window.innerHeight - (navbar_height + miscHeight) ; //height without navbar
    const [tableHeight,settableHeight] = useState(window.innerHeight - heights_out-2.5 ); //height without navbar and toolbar

    const criteriaData = [
        {id:0, value:'Staff Name'},
        {id:0, value:'Reported By'},
        {id:0, value:'Absence Type'},
        {id:0, value:'Reason'},
    ]
    
    return(
        <div 
        className="h-full">
            <div 
            className="px-2">
                <RequestsToolbar 
                searchCriteria
                searchCriteriaData={criteriaData}
                toggler={toggle_staff_form}/>
            </div>

            {/* form section */}
            {(searchParams.get('view') !== datagridOnlySearchParam) &&
            <section 
            style={{height : form_height}}  
            className=" form border-b-4">
                <LeaveRequestsForm/>
            </section>}

            {/* datagrid section */}
            <section className=" px-2">
                <Datagrid_template 
                gridheight= {searchParams.get('view') === formModeSearchParam || searchParams.get('view') === (null) ?  window.innerHeight - (form_height + heights_out + miscHeight) : tableHeight} 
                data = {[]} 
                columns = {leaveRequestcolumns}/>
            </section>
        </div>
    )
})