

export const block_house_TH = [
    {
      id: 0,
      caption: "STAFF No",
      dataField: "empStaffNo",
      alignment: "left",
      fixed: true,
      width: null,
    },
    {
      id: 1,
      caption: "STAFF NAME",
      dataField: "",
      alignment: "left",
      fixed: true,
      width: null
    },
   
    {
      id: 2,
      caption: "DEPT",
      dataField: "dptName",
      alignment: "left",
      fixed: true,
      width: null
    },
    {
      id: 3,
      caption: "SECTION",
      dataField: "sxnName",
      alignment: "left",
      fixed: true,
      width: null
  
  
    },
    {
      id: 4,
      caption: "UNIT",
      dataField: "untName",
      alignment: "left",
      fixed: true,
      width: null
  
    },
    {
      id: 5,
      caption: "JOB TITLE",
      dataField: "jbtName",
      alignment: "left",
      fixed: true,
      width: null
  
    },
    
    {
      id: 6,
      caption: "STAFF?",
      dataField: "empIsStaff",
      alignment: "left",
      fixed: true,
      width: 60
  
    },
    
    
    {
      id: 7,
      caption: "ACTIVE?",
      dataField: "active",
      alignment: "left",
      fixed: true,
      width: 60
  
    },
    
    {
      id: 8,
      caption: "LOCATION",
      dataField: "locName",
      alignment: "left",
      fixed: false,
      width: null
  
    },
    {
      id: 9,
      caption: "SUPERVISOR",
      dataField: "empSupName",
      alignment: "left",
      fixed: false,
      width: null
  
    },
    {
      id: 10,
      caption: "MANAGER",
      dataField: "empMgrName",
      alignment: "left",
      fixed: false,
      width: null
  
    },
    {
      id: 11,
      caption: "DIRECTOR",
      dataField: "empDirName",
      alignment: "left",
      fixed: false,
      width: null
  
    },
    {
      id: 12,
      caption: "DoB",
      dataField: "empDoB",
      alignment: "left",
      fixed: false,
      width: null
  
    },
    {
      id: 13,
      caption: "AGE",
      dataField: "age",
      alignment: "left",
      fixed: false,
      width: null
  
    },
    {
      id: 14,
      caption: "HIRE DATE",
      dataField: "empHireDate",
      alignment: "left",
      fixed: false,
      width: null
  
    },
    {
      id: 15,
      caption: "RETIRE DATE",
      dataField: "",
      alignment: "left",
      fixed: false,
      width: null
  
    },
  
  {
    id: 16,
    caption: "TENURE (YRS)",
    dataField: "",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 17,
    caption: "TENURE LEFT",
    dataField: "tenureLeft",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 18,
    caption: "PROMO DATE",
    dataField: "empLastPromoDate",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 19,
    caption: "GRADE",
    dataField: "grdName",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 20,
    caption: "STEP",
    dataField: "stpName",
    alignment: "left",
    fixed: false,
    width: null

  },
 
  {
    id: 21,
    caption: "ROOM No",
    dataField: "romIdpk",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 22,
    caption: "COST CENTRE",
    dataField: "",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 23,
    caption: "SOC. SEC No",
    dataField: "empSocSecNo",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 24,
    caption: "NATIONAL ID No",
    dataField: "",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 25,
    caption: "ASSIGN. STATUS",
    dataField: "",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 26,
    caption: "APPRAIS. STATUS",
    dataField: "",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 27,
    caption: "APPRAIS. GRP",
    dataField: "",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 28,
    caption: "ASSIGN CAT",
    dataField: "",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 29,
    caption: "JOB",
    dataField: "jobName",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 30,
    caption: "MARITAL ST.",
    dataField: "",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 31,
    caption: "PERSON TYPE",
    dataField: "",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 32,
    caption: "EMP. GROUP",
    dataField: "",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 33,
    caption: "GENDER",
    dataField: "",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 34,
    caption: "PROMO DATE",
    dataField: "",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 35,
    caption: "ADDRESS",
    dataField: "",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 36,
    caption: "ADDRESS",
    dataField: "",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 37,
    caption: "INTERCOM",
    dataField: "empIntercom",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 38,
    caption: "MOB. No.",
    dataField: "empMobNo",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 39,
    caption: "DIRECT TEL No",
    dataField: "empTelNo",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 40,
    caption: "OFFICIAL EMAIL",
    dataField: "empOfficialEmail",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 40,
    caption: "PERSONAL EMAIL",
    dataField: "empPersonalEmail",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 41,
    caption: "NATIONALITY",
    dataField: "ctyNationality",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 42,
    caption: "HOME REGION",
    dataField: "",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 43,
    caption: "HOME DISTRICT",
    dataField: "",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 44,
    caption: "HOME TOWN",
    dataField: "",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 45,
    caption: "LINKEDIN",
    dataField: "",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 46,
    caption: "SKYPE",
    dataField: "",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 47,
    caption: "TWITTER",
    dataField: "",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 48,
    caption: "FACEBOOK",
    dataField: "",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 49,
    caption: "SUP?",
    dataField: "empIsSupervisor",
    alignment: "left",
    fixed: false,
    width: 60

  },
  {
    id: 50,
    caption: "MGR?",
    dataField: "empIsManager",
    alignment: "left",
    fixed: false,
    width: 60

  },
  {
    id: 51,
    caption: "DIR?",
    dataField: "empIsDirector",
    alignment: "left",
    fixed: false,
    width: 60

  },
  {
    id: 52,
    caption: "SNR?",
    dataField: "empIsSeniorStaff",
    alignment: "left",
    fixed: false,
    width: 60

  },
  {
    id: 53,
    caption: "JNR?",
    dataField: "empIsJuniorStaff",
    alignment: "left",
    fixed: false,
    width: 60

  },
  {
    id: 54,
    caption: "CEO?",
    dataField: "empIsCeo",
    alignment: "left",
    fixed: false,
    width: 60

  },
  {
    id: 55,
    caption: "MGMT?",
    dataField: "empIsManagementStaff",
    alignment: "left",
    fixed: false,
    width: 60

  },
  {
    id: 56,
    caption: "ORG ADMIN?",
    dataField: "",
    alignment: "left",
    fixed: false,
    width: 60

  },
  {
    id: 57,
    caption: "DEPT ADMIN?",
    dataField: "empIsDeptAdmin",
    alignment: "left",
    fixed: false,
    width: 60

  },
  {
    id: 58,
    caption: "DEPT SEC?",
    dataField: "empIsDeptSecretary",
    alignment: "left",
    fixed: false,
    width: 60

  },
  {
    id: 59,
    caption: "SEC'N ADMIN?",
    dataField: "empIsSectionAdmin",
    alignment: "left",
    fixed: false,
    width: 60

  },
  {
    id: 60,
    caption: "UNIT ADMIN?",
    dataField: "",
    alignment: "left",
    fixed: false,
    width: 60

  },
  {
    id: 61,
    caption: "SEC'N SEC",
    dataField: "empIsSectionSecretary",
    alignment: "left",
    fixed: false,
    width: 60

  },
  {
    id: 62,
    caption: "BOARD MEBM?",
    dataField: "empIsBoardMember",
    alignment: "left",
    fixed: false,
    width: 60

  },
  {
    id: 63,
    caption: "CORE STAFF?",
    dataField: "empIsCoreStaff",
    alignment: "left",
    fixed: false,
    width: 60

  },
  {
    id: 64,
    caption: "TECH. STAFF?",
    dataField: "empIsTechnicalStaff",
    alignment: "left",
    fixed: false,
    width: 60

  },
  {
    id: 65,
    caption: "CASUAL?",
    dataField: "empIsCasualStaff",
    alignment: "left",
    fixed: false,
    width: 60

  },
  {
    id: 66,
    caption: "NSP?",
    dataField: "empIsServicePerson",
    alignment: "left",
    fixed: false,
    width: 60

  },
  {
    id: 67,
    caption: "VOLUNTARY?",
    dataField: "empIsVoluntaryPerson",
    alignment: "left",
    fixed: false,
    width: 60

  },
  {
    id: 68,
    caption: "REMARKS",
    dataField: "",
    alignment: "left",
    fixed: false,
    width: 4000

  },
  
  ]
  
  
  
  export const block_house_data = [
    
  ]