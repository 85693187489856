import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import {selectObjectTypes } from '../../../../components/human-resource/setups/data/types/selectsTypes'
import dayjs from 'dayjs'

interface staffDetailsState {
  selectedBranchID : selectObjectTypes,
  selectedDepartmentID : selectObjectTypes,
  selectedSectionID : selectObjectTypes,

  selectedCountryID : selectObjectTypes,
  selectedRegionID : selectObjectTypes,
  selectedDistrictID : selectObjectTypes,
  
  staffNo : string,
  surname : string,
  firstname : string,
  otherNames : string,
  branch : selectObjectTypes,
  department : selectObjectTypes,
  section : selectObjectTypes,
  units : selectObjectTypes,
  supervisor : selectObjectTypes,
  director : selectObjectTypes,
  grade : selectObjectTypes,
  officeLocation : selectObjectTypes,
  assignmentStatus : selectObjectTypes,
  personType : selectObjectTypes,
  maritalStatus : selectObjectTypes,
  employeeType : selectObjectTypes,
  employeeGroup : selectObjectTypes,
  title : selectObjectTypes,
  nickname : string,
  initials : string,
  nationalID : string,
  socialSec : string,
  jobTitle : selectObjectTypes,
  manager : selectObjectTypes,
  location : selectObjectTypes,
  step : selectObjectTypes,
  roomNo : selectObjectTypes,
  appraisalGroup : selectObjectTypes,
  gender : selectObjectTypes,
  employeeCategories : selectObjectTypes,
  dob : string,
  hireDate : string,
  lastPromoDate : string,
  address : string,
  nationality : selectObjectTypes,
  region : selectObjectTypes,
  district : selectObjectTypes,
  homeTown : selectObjectTypes,
  costCenter : selectObjectTypes ,
  telNos : string,
  email : string,
  skype : string,
  twitter : string,
  remarks : string,
  intercom : string,
  mobNo : string,
  personalEmail : string,
  facebook : string,
  staff : boolean,
  active : boolean,
}

var initialState:staffDetailsState|any = {
  // for branch dropdown dependencies
  selectedBranchID : {id:0,value:''},
  selectedDepartmentID : {id:0,value:''},
  selectedSectionID : {id:0,value:''},

  // for country dropdown dependencies
  selectedCountryID : {id:0,value:''},
  selectedRegionID : {id:0,value:''},
  selectedDistrictID : {id:0,value:''},

  //form inputs states
  staffNo : '',
  surname : '',
  firstname : '',
  otherNames : '',
  branch : {id:0,value:''},
  department : {id:0,value:''},
  section : {id:0,value:''},
  units : {id:0,value:''},
  supervisor : {id:0,value:''},
  director : {id:0,value:''},
  grade : {id:0,value:''},
  officeLocation : {id:0,value:''},
  assignmentStatus : {id:0,value:''},
  personType : {id:0,value:''},
  maritalStatus : {id:0,value:''},
  employeeType : {id:0,value:''},
  employeeGroup : {id:0,value:''},
  title : {id:0,value:''},
  nickname : '',
  initials : '',
  nationalID : '',
  socialSec : '',
  jobTitle : {id:0,value:''},
  manager : {id:0,value:''},
  location : {id:0,value:''},
  step : {id:0,value:''},
  roomNo : {id:0,value:''},
  appraisalGroup : {id:0,value:''},
  gender : {id:0,value:''},
  employeeCategories : {id:0,value:''},
  dob : dayjs(new Date()).format(),
  hireDate : dayjs(new Date()).format(),
  lastPromoDate : dayjs(new Date()).format(),
  address : '',
  nationality : {id:0,value:''},
  region : {id:0,value:''},
  district : {id:0,value:''},
  homeTown : {id:0,value:''},
  costCenter : {id:0,value:''} ,
  telNos : '',
  email : '',
  skype : '',
  twitter : '',
  remarks : '',
  intercom : '',
  mobNo : '',
  personalEmail : '',
  facebook : '',
  staff : false,
  active : false,
}


const staffDetailsSlice = createSlice({
  name: 'staffDetails',
  initialState,
  reducers: {
    //set state reducers [name ,shortname ...]
    setstaffDetailsValue(state, action: PayloadAction<any>) { //set values of variables here
      const expr:string = action.payload.expr;
      const value:any = action.payload.value;

      state[expr] = value;    
    },
  },
})

export const { setstaffDetailsValue} = staffDetailsSlice.actions
export default staffDetailsSlice.reducer