import React, { useState } from "react";
import { checkboxTypes } from "../../human-resource/setups/data/types/selectsTypes";
import { CheckboxTemlate } from "../../../templates/checkbox";
import { Form } from "antd";
import { formPropsConst } from "../../accessories/constants";
import { SaveButton } from "../../accessories/buttons";

interface stateTypes {
  allowHRTraining: boolean;
  updateStaffOrgSections: boolean;
  updateStaffOrgUnits: boolean;
  updateSupervisorDetails: Boolean;
}

const CoreHR_Settings = () => {
  const [checkboxValues, setCheckboxValues] = useState({
    allowHRTraining: false,
    updateStaffOrgSections: false,
    updateStaffOrgUnits: false,
    updateSupervisorDetails: false,
  });

  const updateCheckboxValue = (key, value) => {
    setCheckboxValues((prevState) => ({ ...prevState, [key]: value }));
  };

  const checkboxes = [
    {
      id: 0,
      label: "Allow HR Training & Dev Self Service",
      stateName: "allowHRTraining",
    },
    {
      id: 1,
      label: "Update Staff Org. Sections from Oracle HRMS?",
      stateName: "updateStaffOrgSections",
    },
    {
      id: 2,
      label: "Update Staff Org. Units from Oracle HRMS?",
      stateName: "updateStaffOrgUnits",
    },
    {
      id: 3,
      label: "Update Supervisor Details from Oracle HRMS?",
      stateName: "updateSupervisorDetails",
    },
  ];

  return (
    <div className="w-full  ">
      <Form
        labelCol={{ span: 8 }}
        className="w-full-500 px-5 py-4 mt-7 items-center"
      >
        {checkboxes.map(({ id, label, stateName }) => {
          return (
            <div className="flex items-center space-x-1">
              <CheckboxTemlate
                span
                customDisabled={false}
                withBorders
                useCallbackFunc
                defaultValue={checkboxValues[stateName]}
                setCheckboxOnchange={(val) => {
                  updateCheckboxValue(stateName, val);
                }}
              />
              <p className="w-fulll flex items-center">{label}</p>
            </div>
          );
        })}

        <section className="w-full mt-7">
          <SaveButton disableButton={false} />
        </section>
      </Form>
    </div>
  );
};

export default CoreHR_Settings;
