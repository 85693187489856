import { Select, Input, Checkbox } from "antd"
import { DataGrid } from "devextreme-react"
import { Pager, Column, Scrolling } from "devextreme-react/data-grid"
import newIcon from '../../../../assets/new.png';
import refresh from '../../../../assets/refresh.png';
import { share_cols } from "../../data/datagrid_cols";


export const Right_Share = () => {
   return (
      <div className="w-full h-full ">
         <ul className="w-full flex justify-evenly items-center">
            <li className="justify-center items-center"><Checkbox /></li>
            <li className="justify-center items-center"><p>Find</p></li>
            <li className="justify-center items-center"><Select className="w-24 mt-0.5" size="small" /></li>
            {/* <li className="justify-center items-center"><Input className="w-24" size="small" /></li> */}
            <li className="justify-center items-center"><img src={newIcon} /></li>
            <li className="justify-center items-center"><img className="w-4" src={refresh} /></li>
         </ul>
         <div className="pt-0.5 h-1/4">
            <DataGrid
               dataSource={[]}
               showBorders={true}
               columnWidth={150}
               showRowLines={true}

               showColumnLines={true}
               //Remember to change
               className='h-full'






            >
               {/* <SearchPanel visible={true} width={200} />
                  <GroupPanel visible={true} /> */}
               <Pager visible={false} />

               {/* STAFF DETAILS COLUMNS */}
               {/* FIXED COLUMNS */}

               {
                  share_cols.map((data) => {
                     return (
                        <Column
                           key={''}
                           caption={''}
                           dataField={''}
                           alignment={'left'}
                           fixed={false}

                           width={150}
                        />
                     )
                  })
               }

               {/* SCROLLBAR  */}

               <Scrolling columnRenderingMode="virtual" />








            </DataGrid>

         </div>

         <div className="pt-0.5 h-4/6">
            <DataGrid
               dataSource={[]}
               showBorders={true}
               columnWidth={150}
               showRowLines={true}

               showColumnLines={true}
               //Remember to change
               // height={tableHeight - 310}
               // 
               className='h-full'





            >

               <Pager visible={false} />

               {/* STAFF DETAILS COLUMNS */}
               {/* FIXED COLUMNS */}

               {
                  share_cols.map((data) => {
                     return (
                        <Column
                           key={''}
                           caption={''}
                           dataField={''}
                           alignment={'left'}
                           fixed={false!}

                           width={150}
                        />
                     )
                  })
               }

               {/* SCROLLBAR  */}

               <Scrolling columnRenderingMode="virtual" />








            </DataGrid>
            <div className="text-xs text-indigo-500 p-1">
               Done
            </div>
         </div>
      </div>
   )
}