import { Form, Slider } from "antd";
import { useAccountInfo } from "../../../../hooks/useAccountInfo";
import { CheckboxTemlate } from "../../../../templates/checkbox";
import Datagrid_template from "../../../../templates/Datagrid";
import { SelectsTemplate } from "../../../../templates/select";
import { navbar_height, toolbar_height } from "../../../accessories/component_infos";
import { saa_cols, saa_emp_cols, saa_periods_cols } from "./data/saa_columns";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import DataGrid, { Column, Summary, TotalItem } from "devextreme-react/data-grid";
import { useRef, useState } from "react";
import { TextareaTemplate } from "../../../../templates/textarea";
import { UpdateButton } from "../../../accessories/buttons";
import { DropDownBox, Tooltip } from "devextreme-react";
import { InputsTemplate } from "../../../../templates/input";
import search from '../../../../assets/search.png';
import reset from '../../../../assets/reset.png';
import useFetch from "../../../../hooks/useFetch";
import { PatchDataFunc } from "../../../../functions/patch";
import dayjs from "dayjs";
import { useBusyLoader } from "../../../../hooks/useBusyLoader";
import { ModalTemplate } from "../../../../templates/modal";
const StaffAssignmentAppraisal = () => {

    //initializations
    const dropdownRef = useRef<any>(null);
    const borderTheme = useSelector((state:RootState)=>state.general.cssBorderClass)

    const close = () => {
        dropdownRef.current.instance.close();
    }
    const dataGridRender = (data: any, columnHeader: any, callBackFunction: any) => {
        return (
            <DataGrid
                height={'100%'}
                width={'100%'}
                style={{ maxHeight: '195px' }}
                columnWidth={80}
                //  keyExpr = {'stnIdpk'}
                columnResizingMode={'widget'}
                //  focusedRowEnabled={true}
                showColumnLines={true}

                showRowLines={true}
                wordWrapEnabled={false}
                allowColumnResizing={true}
                dataSource={data}
                onRowClick={(e: any) => {
                    callBackFunction(e.data)
                    console.log(e.data)
                    close();
                }}
            // columns={gridColumns}
            // hoverStateEnabled={true}
            // selectedRowKeys={this.state.gridBoxValue}
            // onSelectionChanged={this.dataGridOnSelectionChanged}
            >
                {
                    columnHeader?.map((reqBy: any) => {
                        return (
                            <Column
                                key={reqBy.id}
                                dataType={reqBy.dataType}
                                caption={reqBy.caption}
                                dataField={reqBy.dataField}
                                alignment="left"
                                width={reqBy.width === null ? 45 : reqBy.width}
                                fixed={reqBy.fixed}

                            />
                        )
                    })
                }

            </DataGrid>
        );
    }
    const news_flash_height = useSelector((state: RootState) => state.general.newsflashHeight);
    const heights_out = navbar_height + news_flash_height + toolbar_height;
    const [employeeId, userId, userAccount] = useAccountInfo();
    const [completionValue, setCompletionValue] = useState({ staffPercentageComplete: 0, supPercentageComplete: 0 });
    const [searchText, setSearchText] = useState('');
    const [period, setPeriod] = useState<any>({});
    const [section, setSection] = useState('');
    const [remarks, setRemarks] = useState({staff: '', supervisor: ''});
    const [checked, setChecked] = useState(false)
    const [currentData, setCurrentData] = useState<any>({});
    const [refresh, setRefresh] = useState(true);
    const [currentHAT, setCurrentHAT] = useState({ id: 0, name: 'Staff' });
    const [currentBranch, setCurrentBranch] = useState({ id: 0, name: '' });
    const [currentRate, setCurrentRate] = useState({ staffRatingRate: 0, supRatingRate: 0, staffRatingGrade: '', supRatingGrade: '' });
    const [currentDepartment, setCurrentDepartment] = useState({ id: 0, name: '' });
    const [currentSection, setCurrentSection] = useState<any>({ id: 0, name: '' });
    const [currentUnit, setCurrentUnit] = useState({ id: 0, name: '' });
    //    Working example
    // const [searchUrl, setSearchUrl] = useState(`api/StaffInitiativeAppraisal/SearchStaffInitiativeAppraisal?empID=1&prdID=1&sxnID=1&dptID=1&untID=1&supervisor=true&manager=false&director=false&performanceTeam=false&staff=false&hat0=false&search=a`)
    
    const [initialUrl, setInitialUrl] = useState(`api/StaffInitiativeAppraisal/SearchStaffInitiativeAppraisal?empID=${employeeId}&prdID=${period?.prdIDpk}&sxnID=${currentSection.id}&dptID=${currentDepartment.id}&untID=${currentUnit.id}&supervisor=${currentHAT.id===1}&manager=${currentHAT.id===2}&director=${currentHAT.id===3}&performanceTeam=${currentHAT.id === 4}&staff=${currentHAT.id === 0}&hat0=true&check=${checked}&search=${searchText}`)
    const [searchUrl, setSearchUrl] = useState(initialUrl)
    const [table_one_data, table_one_error, table_one_loading] = useFetch(searchUrl, refresh, 'siaIDpk');
    const [branches, branch_error, branch_loading] = useFetch('api/StaffInitiativeAppraisal/GetBranch', refresh, 'obrIdpk');
    const [periodsData, periodsData_error, periodsData_loading] = useFetch('api/StaffInitiativeAppraisal/GetPeriod', refresh, 'prdIdpk');
    const [departments, department_error, department_loading] = useFetch('api/StaffInitiativeAppraisal/GetDepartment', refresh, 'dptIdpk');
    //    const [sections, section_error, section_loading] = useFetch(`api/StaffInitiativeAppraisal/GetSection?sectionID=${userAccount.sxnIDpk}`, refresh,'sxnIdpk');
    const [sections, section_error, section_loading] = useFetch(`api/StaffInitiativeAppraisal/GetSection?sectionID=1`, refresh, 'sxnIdpk');
    //    const [units, unit_error, unit_loading] = useFetch(`api/StaffInitiativeAppraisal/GetUnit?unitID=${userAccount.untIDpk}`, refresh,'untIdpk');
    const [units, unit_error, unit_loading] = useFetch(`api/StaffInitiativeAppraisal/GetUnit?unitID=1`, refresh, 'untIdpk');
    const [ratingId, ratingId_error, ratingId_loading] = useFetch(`api/StaffInitiativeAppraisal/GetActualRatingID?rtnRate=${currentHAT?.id === 0? currentRate?.staffRatingRate : currentRate?.supRatingRate}`, refresh, 'rtnIdpk');
    const [rateData, rate_error, rate_loading] = useFetch(`api/StaffInitiativeAppraisal/GetActuals?empId=${employeeId}&period=${currentData?.prdIDpk}&sinID=${currentData?.sinIDpk}`, refresh, 'rtnRate');
    const [empData, emp_error, emp_loading] = useFetch(`api/StaffInitiativeAppraisal/GetEmps?empID=${employeeId}&prdID=${period?.prdIDpk}&untID=${currentUnit.id}&sxnID=${currentSection.id}&dptID=${currentDepartment.id}&supervisor=${currentHAT.id===1}&manager=${currentHAT.id===2}&director=${currentHAT.id===3}&performanceTeam=${currentHAT.id === 4}&performanceManager0=true&performanceManagerUnit=true&performanceManagerStaff=true&performanceManagerSection=true&performanceManagerDepartment=true`, refresh, 'rtnRate');
    const [busyLoader,setBusyLoader] = useBusyLoader();
    const [states, setState] = useState<any>({
      
        modalState: {
          state: false,
          title: "",
          message: "",
        },
        successModal: {
          state: false,
          title: "",
          message: "",
        },
        confirmState: {
          state: false,
          title: "",
          message: "",
          event: () => {},
        },
      });

       // update states
    const updateState = (stateName: string, value: any) => {
        setState((prevstate: any) => ({ ...prevstate, [stateName]: value }));
      };
  
    
    const updateRecord=async (type: any) =>{
        try {
      
        setBusyLoader('Updating Record')
        console.log(type)
        console.log({
            "siaStaffActualIDfk": currentData?.tgtIDpk,
            "siaStaffPercentageComplete": completionValue.staffPercentageComplete,
            "siaStaffRatingIDfk": ratingId[0]['rtnIdpk']!,
            "siaStaffAppraisalRmks": remarks,
            "siaEditedBy": userId,
            "siaEditedDate": dayjs(Date.now()).format('YYYY-MM-DD')
          })
      type === 'staff'? await PatchDataFunc(`api/StaffInitiativeAppraisal/UpdateStaff?Id=${currentData?.siaIDpk}`,{
            "siaStaffActualIDfk": currentData?.tgtIDpk,
            "siaStaffPercentageComplete": completionValue.staffPercentageComplete,
            "siaStaffRatingIDfk": ratingId[0]['rtnIdpk']!,
            "siaStaffAppraisalRmks": remarks.staff,
            "siaEditedBy": userId,
            "siaEditedDate": dayjs(Date.now()).format('YYYY-MM-DD')
          }) : 
         await PatchDataFunc(`api/StaffInitiativeAppraisal/UpdateSupervisor?Id=${currentData?.siaIDpk}`,{
            "siaSupActualIDfk": currentData?.tgtIDpk,
            "siaSupPercentageComplete": completionValue.supPercentageComplete,
            "siaSupRatingIDfk": ratingId[0]['rtnIdpk']!,
            "siaSupAppraisalRmks": remarks.supervisor,
            "siaEditedBy": userId,
            "siaEditedDate": dayjs(Date.now()).format('YYYY-MM-DD')
          })
          setBusyLoader('')
          updateState("successModal", {
            state: true,
            title: "Success",
            message: "Record updated successfully",
          });
          setRefresh(!refresh);
        } catch (error) {
            updateState("modalState", {
                state: true,
                title: "Required field",
                message: "An unexpected error occur",
              });
        }
    }

    

    

    return (<div className="flex">
          <ModalTemplate
          disableCancel={true}
          title={states.modalState.title}
          okText={""}
          message={states.modalState.message}
          open={states.modalState.state}
          okHandler={() => {
            updateState("modalState", { state: false });
          }}
          cancelHandler={() => {
            updateState("modalState", { state: false });
          }}
        />
        <ModalTemplate
          icon="success"
          disableCancel={true}
          title={states.successModal.title}
          okText={""}
          message={states.successModal.message}
          open={states.successModal.state}
          okHandler={() => {
            updateState("successModal", { state: false });
            // alert("we are in");
          }}
          cancelHandler={() => {
            updateState("successModal", { state: false });
          }}
        />

        <ModalTemplate
          icon="question"
          title={states.confirmState.title}
          okText={""}
          message={states.confirmState.message}
          open={states.confirmState.state}
          okHandler={states.confirmState.event}
          cancelHandler={() => {
            updateState("confirmState", { state: false });
          }}
        />
        {(currentHAT.id > 0) && <div className="w-[300px] mx-[1px]">
            <Datagrid_template 
            gridheight={window.innerHeight - (heights_out)} columns={saa_emp_cols} data={empData}
                disablePaging={true}

            />
        </div>}
        <Form
            className='  py-[2px] px-[2px] '
            style={{ width: currentHAT.id === 0 ? 'calc(100%)' : 'calc(100% - 302px)' }}
            name="basic"
            labelCol={{ span: 2 }}
            //   wrapperCol={{ span: 25 }}
            autoComplete="off"
            size='small'
        >
            {/* Deep Orange Section */}
            <section className=" bg-orange-100 py-[2px] px-2 w-full" >
                <Form.Item label="HAT" style={{ marginBottom: 0 }} >
                    <div className="flex">
                        <SelectsTemplate
                            useCallFunc
                            placeHolder={currentHAT?.name}
                            selectedValue={(val) => { setCurrentHAT(val) }}
                            disabled={false}
                            options={[
                                { id: 0, name: 'Staff' },
                                { id: 1, name: 'Supervisor' },
                                { id: 2, name: 'Manager' },
                                { id: 3, name: 'Director' },
                                { id: 4, name: 'Performance Team' }
                            ]}

                            idexpr="id"
                            dataexp="name"
                            useCusWidth
                            selectStyle={{ width: '200px' }}
                            islabelCol={true}
                        />
                        <div className="mx-[40px]"></div>
                        <SelectsTemplate
                            useCallFunc
                            placeHolder={currentBranch?.name}
                            selectedValue={(val) => { setCurrentBranch({ id: val['obrIdpk'], name: val['obrShtName'] }) }}
                            options={branches}
                            idexpr="obrIdpk"
                            dataexp="obrShtName"
                            disabled={currentHAT.id < 4}
                            useCusWidth
                            selectStyle={{ width: '200px' }}
                            span={true}
                            label={"Org Unit"}
                        />
                        <SelectsTemplate
                            useCallFunc
                            placeHolder={currentDepartment?.name}
                            selectedValue={(val) => { setCurrentDepartment({ id: val['dptIdpk'], name: val['dptShtName'] }) }}
                            options={departments}
                            idexpr="dptIdpk"
                            dataexp="dptShtName"
                            disabled={currentHAT.id < 3}
                            useCusWidth
                            selectStyle={{ width: '200px' }}
                            span={true}

                        />
                        <SelectsTemplate
                            useCallFunc
                            placeHolder={currentSection?.name}
                            selectedValue={(val) => { setCurrentSection({ id: val['sxnIdpk'], name: val['sxnShtName'] }) }}
                            options={sections}
                            idexpr="sxnIdpk"
                            dataexp="sxnShtName"
                            disabled={currentHAT.id < 2}
                            useCusWidth
                            selectStyle={{ width: '200px' }}
                            span={true}

                        />
                        <SelectsTemplate
                            useCallFunc
                            placeHolder={currentUnit?.name}
                            selectedValue={(val) => { setCurrentUnit({ id: val['untIdpk'], name: val['untShtName'] }) }}
                            options={units}
                            idexpr="untIdpk"
                            dataexp="untShtName"
                            disabled={currentHAT.id < 1}
                            useCusWidth
                            selectStyle={{ width: '200px' }}
                            span={false}

                        />
                    </div>
                </Form.Item>
            </section>

            {/* Light Orange Section */}
            <section className=" bg-orange-50 py-[2px] px-2 w-full" >
                <Form.Item label="Period" style={{ marginBottom: 0 }}>
                    <div className="flex ">

                        {/* <SelectsTemplate

                            disabled={false}
                            useCusWidth
                            selectStyle={{ width: '200px' }}
                            islabelCol={true}
                        /> */}

                        <Form.Item className='mb-0.5' >
                            <div className="flex flex-row items-center dark:bg-slate-900 dark:text-darkModeSkin-base">
                                <DropDownBox
                                    hoverStateEnabled={true}
                                    ref={dropdownRef}
                                    className={'border-2 border-red-300'}
                                    // value={this.state.gridBoxValue}
                                    style={{ width: 'calc(200px)', height: '23px', textAlign: 'center', backgroundColor: 'white', border: '1px solid #e5e7eb' }}
                                    valueExpr="ID"
                                    deferRendering={false}
                                    showClearButton={true}
                                    openOnFieldClick={true}
                                    value={period.prdName}
                                    placeholder={period.prdName}
                                    disabled={false}
                                    contentRender={() =>
                                        dataGridRender(
                                            periodsData,
                                            saa_periods_cols, (e: any) => {
                                                setPeriod(e)
                                            }
                                        )
                                    }
                                />
                                <span
                                    onClick={() => { setPeriod({}) }}
                                    className='h-full w-4 ml-1 flex justify-evenly hover:cursor-pointer hover:bg-slate-50 rounded'
                                    style={{ height: 22.5, marginBottom: 2, borderWidth: 1, width: 24 }}
                                >
                                    <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src={reset} alt="reset" />
                                    {/* <img className='' src= {''} alt="" /> */}
                                </span>

                            </div>
                        </Form.Item>
                        <div className="mx-[40px]"></div>
                        <div className="flex w-full justify-between">
                            <p className="text-blue-600">{userAccount.empName}</p>
                            <div style={{borderColor:borderTheme}} className="border rounded border-gray-300 px-[4px]">
                                <CheckboxTemlate
                                useCallbackFunc
                                defaultValue={checked}
                                setCheckboxOnchange={(val)=>{setChecked(val)
                                console.log(val)
                                }}
                                    customDisabled={false}
                                    label="Show all assignments?"
                                    withBorders={false}
                                // borders={true}
                                />
                            </div>
                        </div>

                    </div>
                </Form.Item>



            </section>
            <div className="flex flex-row justify-end items-center my-[4px] ">
                <InputsTemplate
                    defaultValue={searchText}
                    useCallbackFunc
                    orderOnchange={(value: any) => {
                        setSearchText(value)
                    }}
                    inputStyle={{ background: '#fffbeb', height: '24px' }}
                    disabledStatus={false}
                    span
                />
                <div className="w-[4px]">

                </div>


                <li
                    onClick={() => {
                        setSearchUrl(`api/StaffInitiativeAppraisal/SearchStaffInitiativeAppraisal?empID=${employeeId}&prdID=${period.prdIdpk}&supervisor=${currentHAT.id===1}&manager=${currentHAT.id===2}&director=${currentHAT.id===3}&performanceTeam=${currentHAT.id === 4}&staff=${currentHAT.id === 0}&check=${checked}&search=${searchText}`+ 
                        `${currentSection.id !== 0? `&sxnID=${currentSection.id}`: ``}` + `${currentDepartment.id !== 0? `&dptID=${currentDepartment.id}`: ``}` + `${currentUnit.id !== 0? `&untID=${currentUnit.id}`: ``}`
                        
                        )
                        console.log(`api/StaffInitiativeAppraisal/SearchStaffInitiativeAppraisal?empID=${employeeId}&prdID=${period.prdIdpk}&supervisor=${currentHAT.id===1}&manager=${currentHAT.id===2}&director=${currentHAT.id===3}&performanceTeam=${currentHAT.id === 4}&staff=${currentHAT.id === 0}&check=${checked}&search=${searchText}`+ 
                        `${currentSection.id !== 0? `&sxnID=${currentSection.id}`: ``}` + `${currentDepartment.id !== 0? `&dptID=${currentDepartment.id}`: ``}` + `${currentUnit.id !== 0? `&untID=${currentUnit.id}`: ``}`
                        )

                    }}
                    id="reset" style={{ borderWidth: '1px',borderColor:borderTheme }}
                    className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center">
                    <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 "> <img
                        style={{ height: '15px', width: '17px' }}
                        className="dark:bg-slate-900 dark:text-gray-100 duration-100" src={search} /></span>
                    <Tooltip
                        target="#reset"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        hideOnOutsideClick={true}
                    >
                        <p className="">Search
                        </p>
                    </Tooltip>
                </li>

            </div>
            {/* Datagrid */}

            <Datagrid_template gridheight={window.innerHeight - (heights_out + 245)} columns={saa_cols} data={table_one_data}
                disablePaging={true}
                onRowClick={(data) => {
                    setCurrentData(data)
                    setCurrentRate({ staffRatingRate: data?.staffRatingRate, supRatingRate: data?.supRatingRate, staffRatingGrade: data?.staffRatingGrade, supRatingGrade: data?.supRatingGrade })
                    setCompletionValue({ staffPercentageComplete: data?.siaStaffPercentageComplete, supPercentageComplete: data?.siaSupPercentageComplete })
                    setRemarks({staff:  data?.siaStaffAppraisalRmks, supervisor: data?.siaSupAppraisalRmks} )
                }}
                summary={<Summary >

                    <TotalItem
                        cssClass={"one"}
                        column="WEIGHT"
                        summaryType={`sum`}

                    />

                    <TotalItem
                        cssClass={"two"}
                        column="STAFF RATING"
                        summaryType={`sum`}
                        valueFormat="" />
                    <TotalItem
                        cssClass={"three"}
                        column="STAFF % COMPLETE"
                        summaryType={`avg`}
                        valueFormat="" />
                    <TotalItem
                        cssClass={"three"}
                        column="SUP RATING"
                        summaryType={`sum`}
                        valueFormat="" />
                    <TotalItem
                        cssClass={"three"}
                        column="SUP % COMPLETE"
                        summaryType={`avg`}
                        valueFormat="" />
                </Summary>



                }
            />
            {/* Bottom Section */}
            <section className=" bg-yellow-50 py-[2px] h-[30px] px-2 w-full" >




            </section>
            {/* Bottom Section */}
            <section className="  py-[2px] px-2 w-full" >
                <Form.Item label="Rate" style={{ marginBottom: 0 }}>
                    <div className="flex ">

                        <SelectsTemplate
                            useCallFunc
                            settingsIcon={true}
                            extraWidget={<div className="text-blue-500 ml-10">*</div>}
                            placeHolder={currentHAT.id === 0 ? currentRate?.staffRatingGrade : currentRate?.supRatingGrade}
                            selectedValue={(val) => {
                                setCurrentRate(currentHAT.id === 0 ? {
                                    ...currentRate,
                                    staffRatingRate: val['rtnRate'], staffRatingGrade: val['rtnGrade'],
                                } : {
                                    ...currentRate,
                                    supRatingRate: val['rtnRate'], supRatingGrade: val['rtnGrade']
                                }

                                )

                            }}
                            options={rateData}
                            idexpr="rtnRate"
                            dataexp="rtnGrade"
                            disabled={false}
                            useCusWidth
                            selectStyle={{ width: '200px' }}
                            islabelCol={true}
                        />

                    </div>
                </Form.Item>
                <Form.Item
                    className="mb-1"
                    label={
                        <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                            {"% Complete"}
                        </p>
                    }
                >
                    <div className="w-full flex dark:bg-slate-100 dark:text-darkModeSkin-base">
                        <Slider style={{ width: '100%' }} className="mr-5" min={0} max={100} onChange={(e) => {
                            currentHAT.id === 0 ?
                                setCompletionValue({ ...completionValue, staffPercentageComplete: e })
                                : setCompletionValue({ ...completionValue, supPercentageComplete: e })

                        }} value={
                            currentHAT.id === 0 ?
                                completionValue.staffPercentageComplete : completionValue.supPercentageComplete} />
                        <span className="text-blue-600">{`${currentHAT.id === 0 ?
                            completionValue.staffPercentageComplete : completionValue.supPercentageComplete}%`}</span>
                    </div>
                </Form.Item>

                <TextareaTemplate labelCol={2} height={50} label={'Remarks'} useCallbackFunc
                    defaultValue={ currentHAT.id === 0 ? remarks.staff : remarks.supervisor}
                    readonly={false}
                    setCallbackValue={(val) => {
                        setRemarks(currentHAT.id === 0 ? {...remarks,staff : val} : {...remarks, supervisor: val})
                    }}
                />
                <div className="flex justify-center">
                    <UpdateButton handleUpdate={()=>{
                        updateRecord(  currentHAT.id === 0? 'staff': 'supervisior')
                    }} />
                </div>



            </section>
        </Form>

    </div>);
}

export default StaffAssignmentAppraisal;