import { useEffect, useState } from "react";
import { useAccountInfo } from "./useAccountInfo";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";
import { useCrudFunc } from "../functions/crud";

type priveledgeStates = {
  savePrivilegeS: boolean;
  readPrivilegeS: boolean;
  updatePrivilegeS: boolean;
};

export const useFormPriviledge = (
  type: string
): [boolean, boolean, boolean] => {
  // Picks user Id from the userModel
  const [empId, userId, userObj] = useAccountInfo();
  const [__, _, PatchDataPrivFunc] = useCrudFunc();

  // Picks the form's code from the clicked form
  const formCode = useSelector((state: RootState) => state.user?.formCode);

  const [states, setStates] = useState<priveledgeStates>({
    savePrivilegeS: false,
    readPrivilegeS: false,
    updatePrivilegeS: false,
  });

  let test = true;
 

  const updateStates = (key: string, value: any) => {
    setStates((prev: any) => ({ ...prev, [key]: value }));
  };

  const fetchPriv = async () => {
    try {
      if (type === "save") {
        const savePrivilege = await PatchDataPrivFunc(
          `CheckUserPrivilege/GetSavePrivilege?UsaID=${userId}&formCode=${formCode}`,
          {}, 
        );

        updateStates("savePrivilegeS", savePrivilege.data);
      } else if (type === "read") {
        const readPrivilege = await PatchDataPrivFunc(
          `CheckUserPrivilege/GetReadPrivilege?UsaID=${userId}&formCode=${formCode}`,
          {}, 
        );

        updateStates("readPrivilegeS", readPrivilege.data);
      } else if (type === "update") {
        const updatePrivilege = await PatchDataPrivFunc(
          `CheckUserPrivilege/GetUpdatePrivilege?UsaID=${userId}&formCode=${formCode}`,
          {}, 
        );

        updateStates("updatePrivilegeS", updatePrivilege.data);
      }
    } catch (err: any) {
      //Not implemented
    }
  };

  useEffect(() => {
    fetchPriv();
  }, [userId, formCode]);

  return [test, test, test];
};
