import { useState, useRef, useEffect, useImperativeHandle, forwardRef } from 'react'
import { Checkbox, Form, Input, Select, theme } from 'antd';
import { CheckBox } from 'devextreme-react/check-box';
import { Cancel, NewButton, SaveButton, UpdateButton } from '../../../../accessories/buttons';
import { Theme_Inclusion_Exclusion_Date, Theme_Inclusion_Exclusion_Mini_TextArea, Theme_Inclusion_Exclusion_TextArea } from '../../../data/Performance_inputs';
import { Theme_Inclusion_Exclusion_Select, Theme_Inclusion_Exclusion_Single_Select } from '../../../data/Performance_selects';
import dayjs from 'dayjs';
import StategicTheme_Select_Template from '../data/templates/strategicTheme_selects_template';
import axios from 'axios';
import { api_url, saveModalData, updateModalData } from '../../../../accessories/component_infos';
import { StrategicThemeTextArea } from '../data/templates/strategicTheme_textArea';
import { ModalTemplate } from '../../../../../templates/modal';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../app/store';
import { task_footer_table_selected, task_footer_update, task_form_disable } from '../../../../../features/Task&Todo/Task/TaskDetails';
import { DateTemplate } from '../../../../../templates/date';
import { inputsTypes } from '../../../../human-resource/setups/data/types/selectsTypes';

interface props {
    toggleRefresh: any,
}

export const Theme_Inclusion_Exclusion_Details = forwardRef(({ toggleRefresh }: props, ref: any) => {

    const borderTheme = useSelector((state: RootState) => state.general.cssBorderClass)
    //state initializations
    const [incExcData, setIncExcData] = useState<any>({
        date: { startDate: '', endDate: '' },
        criteria: { name: '', id: '' },
        mission: { name: '', id: '' },
        theme: { name: '', id: '' },
        item: '',
        remarks: '',
        order: '',
        active: false,
        disabled: true,
        id: '',
    });
    const [mission, setMission] = useState([]);
    const [themeData, setThemesData] = useState([]);
    const [refreshValue, setRefreshValue] = useState(false);
    const [updatingStatus, setUpdatingStatus] = useState(false);
    const [showModalDisableButton, setShowModalDisableButton] = useState(false);
    const [disableForms, setDisableForms] = useState(true);
    const [showCancel, setShowCancel] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [modalData, setModalData] = useState<any>(saveModalData);
    const [icon, setIcon] = useState<'warning' | 'success' | 'question'>();

    //function calls
    async function fetchMissionVision() {
        try {

            // get data
            const res = await axios.get(`${api_url}/api/PfmMissionVision/GetAllPfmMissionVision`, {
                headers: {
                    'Content-Type': ['application/json', 'text/plain', 'charset=utf-8'],
                    // Accept : ['text/plain','application/json','charset=utf-8'],
                }
            })


            setMission(res.data.data.reverse()); // set data
        } catch (error: any) {
            console.log(error);

        } finally {

        }
    }

    async function fetchThemes() {
        try {

            // get data
            const res = await axios.get(`${api_url}/api/PfmObjectives/GetLuePfmThemes`, {
                headers: {
                    'Content-Type': ['application/json', 'text/plain', 'charset=utf-8'],
                    // Accept : ['text/plain','application/json','charset=utf-8'],
                }
            })

            setThemesData(res.data.reverse()); // set data
        } catch (error: any) {
            console.log(error);

        } finally {

        }
    }

    const clearFields = async () => {
        setIncExcData(
            {
                ...incExcData,
                date: { startDate: '', endDate: '' },
                criteria: { name: '', id: '' },
                mission: { name: '', id: '' },
                theme: { name: '', id: '' },
                item: '',
                remarks: '',
                order: '',
                active: false,
                id: '',
                ['disabled']: false
            }
        )
    }

    const validateForms = async () => {
        setIcon('warning');
        if (incExcData.date.startDate === '') {
            setShowModalDisableButton(true);
            setModalData({ message: 'Please select a start date', title: 'Select Start Date', okText: 'Ok' },)
            setOpenModal(true);
        } else if (incExcData.date.endDate === '') {
            setShowModalDisableButton(true);
            setModalData({ message: 'Please select an end date', title: 'Select End Date', okText: 'Ok' },)
            setOpenModal(true);
        }
        else if (incExcData.mission.id === '') {
            setShowModalDisableButton(true);
            setModalData({ message: 'Please select a mission', title: 'Select Mission', okText: 'Ok' },)
            setOpenModal(true);
        }
        else if (incExcData.theme.id === '') {
            setShowModalDisableButton(true);
            setModalData({ message: 'Please select a theme', title: 'Select Theme', okText: 'Ok' },)
            setOpenModal(true);
        }
        else if (incExcData.order === '') {
            setShowModalDisableButton(true);
            setModalData({ message: 'Please enter an order', title: 'Enter Order', okText: 'Ok' },)
            setOpenModal(true);
        }
        else {
            setIcon('question');
            setShowModalDisableButton(false);
            setOpenModal(true);
        }
    }

    const updateData = async () => {
        console.log('here');

        const del = await axios.put(`${api_url}/api/PfmThemeInclusionsExclusions/UpdatePfmThemeInclusionsExclusion`, {
            "tieIdpk": incExcData.id,
            "tieCriteria": incExcData.criteria.id,
            "tieThemeIdfk": incExcData.theme.id,
            "tieStartDate": incExcData.date.startDate,
            "tieEndDate": incExcData.date.endDate,
            "tieItem": incExcData.item,
            "tieOrder": incExcData.order,
            "tieActive": incExcData.active,
            "tieRmks": incExcData.remarks,
            "tieEditedBy": 1,
            "tieEditedDate": `${dayjs().format('YYYY-MM-DDTHH:mm:ss[Z]')}`

        }
        )
        //close modal
        setOpenModal(false);
        //Refresh table
        setShowModalDisableButton(true);
        setIcon('success');
        setModalData({ message: 'Record successfully updated', title: 'Record updated', okText: 'Ok' },)
        setOpenModal(true);
        toggleRefresh();


    }
    async function postData() {
        const del = await axios.post(`${api_url}/api/PfmThemeInclusionsExclusions/CreatePfmThemeInclusionsExclusion`, {
            "tieCriteria": incExcData.criteria.id,
            "tieThemeIdfk": incExcData.theme.id,
            "tieStartDate": incExcData.date.startDate,
            "tieEndDate": incExcData.date.endDate,
            "tieItem": incExcData.item,
            "tieOrder": incExcData.order,
            "tieActive": incExcData.active,
            "tieRmks": incExcData.remarks,
            "tieCreatedBy": 1,
        }
        )
    }

    useImperativeHandle(ref, () => ({
        doubleClick: () => {
            setIncExcData({ ...incExcData, ['disabled']: false })
            setUpdatingStatus(true);
            setShowCancel(true);
        },
        singleClick: (selectedRow: any) => {
            setIncExcData({
                ...incExcData,
                ['date']: { startDate: dayjs(selectedRow.tieStartDate), endDate: dayjs(selectedRow.tieEndDate) },
                ['criteria']: { name: selectedRow.tieCriteria, id: '' },
                ['disabled']: true,
                ['mission']: { name: selectedRow.mvsMission, id: selectedRow.mvsIdpk },
                ['theme']: { name: selectedRow.thmName, id: selectedRow.thmIdpk },
                ['item']: selectedRow.tieItem,
                ['remarks']: selectedRow.tieRmks,
                ['order']: selectedRow.tieOrder,
                ['active']: selectedRow.tieActive,
                ['id']: selectedRow.tieIdpk,
            })
        }
    }));


    useEffect(() => {
        fetchMissionVision();
        fetchThemes();
    }, [refreshValue]);

    const disablex = useSelector((state: RootState) => state.addTask.taskFormStatus);
    const updateMode = useSelector((state: RootState) => state.addTask.taskFooterUpdate);
    const dispatch = useDispatch();


    useEffect(() => {
        if (!disablex) {

            dispatch(task_footer_table_selected({}))

            //  this destroys the update mode state
            dispatch(task_footer_update(false));
            setShowCancel(true); setDisableForms(false);
            clearFields();


        } else {
            setShowCancel(false);
            setIncExcData({ ...incExcData, ['disabled']: true, })
            setUpdatingStatus(false)
            dispatch(task_form_disable(true));
            // dispatch(update_route_state(false))
            dispatch(task_footer_update(false));

        }
        if (updateMode) {
            setDisableForms(false);
            setUpdatingStatus(true);
            setShowCancel(true);
        }

    }, [disablex, updateMode])

    const dates: inputsTypes[] = [
        { id: 0, label: '', stateName: 'startDate', defaultValue: incExcData.date.startDate, style: '' },
        { id: 1, label: '', stateName: 'endDate', defaultValue: incExcData.date.endDate, style: '' },
    ]


    return (

        <div style={{ width: '900px', minWidth: '900px', height: '100%', borderColor: borderTheme }} className="  border-[1px]   dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base  ">
            <div style={{ maxHeight: '280px', minHeight: '280px', height: '280px' }} className=' items-start justify-start '   >
                {/* Modal */}
                <ModalTemplate icon={icon} cancelText={modalData.cancelText}
                    cancelHandler={() => { setOpenModal(false) }} disableCancel={showModalDisableButton} open={openModal}
                    okHandler={() => { showModalDisableButton === true ? setOpenModal(false) : updatingStatus === true ? updateData() : postData() }}
                    message={modalData.message} okText={modalData.okText} title={modalData.title} />
                <Form
                    className='w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 px-2 '
                    name="basic"
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    size='small'
                    layout='horizontal'
                >
                    <Form.Item
                        label={<p className='dark:bg-fill dark:text-darkModeSkin-base text-xs'>{'Date'}</p>}
                        labelCol={{ span: 2 }}
                    >
                        <div className="flex flex-row">
                            {dates.map(({ id, label, stateName, defaultValue, style }) => {
                                return (
                                    <>
                                        <DateTemplate
                                            label={label}
                                            style={{ marginLeft: id == 1 && 10 }}
                                            selectedDate={defaultValue}
                                            changeDate={(e: any) => {
                                                id == 0 && setIncExcData({ ...incExcData, ['date']: { startDate: dayjs(e) } })
                                                id == 1 && setIncExcData({ ...incExcData, ['date']: { endDate: dayjs(e) } })

                                            }}
                                            format={"DD MMM YYYY"}
                                        />
                                    </>
                                )
                            })}

                        </div>
                    </Form.Item>
                    {/* <Theme_Inclusion_Exclusion_Date
                        onChange={(e: any, dateString: any) => {
                            console.log(dateString);
                            setIncExcData({
                                ...incExcData, ['date']: {
                                    startDate: dayjs(dateString[0]),
                                    endDate: dayjs(dateString[1])
                                }
                            })
                        }}
                        disabled={incExcData.disabled}
                        value={[incExcData.date.startDate, incExcData.date.endDate]}
                    /> */}
                    <StategicTheme_Select_Template
                        disabled={incExcData.disabled}
                        label='Criteria'
                        labelCol={2}
                        wrapperCol={9}
                        width={'98%'}
                        onChange={(e: any) => { setIncExcData({ ...incExcData, ['criteria']: { name: JSON.parse(e)['crtName'], id: JSON.parse(e)['crtIdpk'] } }) }}
                        value={incExcData.criteria.name}
                        dataSet={[]}
                        displayKey="crtName"
                        refresh={() => { setIncExcData({ ...incExcData, ['criteria']: { name: '', id: '' } }) }}
                        span
                    />
                    <StategicTheme_Select_Template
                        borderTheme={borderTheme}
                        disabled={incExcData.disabled}
                        label='Mission'
                        labelCol={2}
                        wrapperCol={12}
                        onChange={(e: any) => { setIncExcData({ ...incExcData, ['mission']: { name: JSON.parse(e)['mvsName'], id: JSON.parse(e)['mvsIdpk'] } }) }}
                        value={incExcData.mission.name}
                        dataSet={mission}
                        displayKey="mvsName"
                        refresh={() => { setIncExcData({ ...incExcData, ['mission']: { name: '', id: '' } }) }}
                    />
                    <StategicTheme_Select_Template
                        borderTheme={borderTheme}
                        disabled={incExcData.disabled}
                        label='Theme'
                        labelCol={2}
                        wrapperCol={12}
                        onChange={(e: any) => { setIncExcData({ ...incExcData, ['theme']: { name: JSON.parse(e)['thmName'], id: JSON.parse(e)['thmIdpk'] } }) }}
                        value={incExcData.theme.name}
                        dataSet={themeData}
                        displayKey="name"
                        refresh={() => { setIncExcData({ ...incExcData, ['theme']: { name: '', id: '' } }) }}
                    />
                    <StrategicThemeTextArea
                        borderTheme={borderTheme}
                        label={'Item'}
                        readOnly={incExcData.disabled}
                        textheight={65}
                        labelCol={2}
                        onChange={(e: any) => { setIncExcData({ ...incExcData, ['item']: e.target.value }) }}
                        value={incExcData.item}
                    />
                    <StrategicThemeTextArea
                        borderTheme={borderTheme}
                        readOnly={incExcData.disabled}
                        label={'Remarks'}
                        textheight={100}
                        labelCol={2}
                        onChange={(e: any) => { setIncExcData({ ...incExcData, ['remarks']: e.target.value }) }}
                        value={incExcData.remarks}
                    />

                    <Form.Item labelCol={{ span: 2 }} label={<p className='dark:bg-fill dark:text-darkModeSkin-base text-xs'>{'Order'}</p>}>
                        <div className='w-full  flex flex-col  '>
                            <div style={{ width: 'calc(900px - 93px)', }} className=' flex  justify-between  '>
                                <ul className='flex justify-evenly'>
                                    <li> <Input value={incExcData.order}
                                        onChange={(e: any) => { setIncExcData({ ...incExcData, ['order']: e.target.value }) }} disabled={incExcData.disabled}
                                        className='w-24' /></li>
                                    <li className=' flex h-6 items-center flex-row ml-5'><p className='mr-2 text-xs'>Active? : </p>
                                        <Checkbox
                                            style={{ borderColor: borderTheme }}
                                            className='border-[1px] rounded px-1'
                                            checked={incExcData.active} disabled={incExcData.disabled}
                                            onChange={(e: any) => {
                                                setIncExcData({ ...incExcData, ['active']: e.target.checked })
                                            }} /></li>
                                </ul>
                                <ul className='flex justify-end '>
                                    {updatingStatus === true ? <UpdateButton handleUpdate={() => { setModalData(updateModalData); validateForms(); }} /> : <SaveButton handleSave={() => { setModalData(saveModalData); validateForms(); }} />}
                                    {showCancel === true ? <Cancel useCallbackFunc={true} cancel_button_toggler={() => {
                                        setShowCancel(false);
                                        setIncExcData({ ...incExcData, ['disabled']: true, })
                                        setUpdatingStatus(false)
                                        dispatch(task_form_disable(true));
                                        // dispatch(update_route_state(false))
                                        dispatch(task_footer_update(false));
                                    }} /> : <NewButton useCallbackFunc={true} new_button_toggler={() => {
                                        setShowCancel(true);
                                        clearFields();
                                        // setIncExcData({...incExcData, ['disabled']: false})

                                    }} />}
                                </ul>
                            </div>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
})