import { FormProps } from "antd";

export const formPropsConst = {
  className: "w-full rounded py-1",

  layout: "horizontal",
  autoComplete: "off",
  initialValues: {
    remember: true,
  },
  name: "basic",
  wrapperCol: {
    span: 24,
  },
  labelCol: {
    span: 6,
  },
  size: "small",
} satisfies FormProps<any>;


