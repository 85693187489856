/* eslint-disable no-lone-blocks */
import React, { useCallback, useRef, useState } from 'react'
import {
    DataGrid,
    Column,
    ColumnFixing,
    Scrolling,
    Selection,
    Editing
} from 'devextreme-react/data-grid';
import { TaskUpdateData } from "../data/taskUpdateData";
import { Form, Input, Select, DatePicker } from 'antd';
import { CheckBox } from 'devextreme-react/check-box';
import { VscNewFile } from 'react-icons/vsc';
import save from '../../assets/save2.png'
import add from '../../assets/add.png'
import locked from '../../../assets/locked.png'
import refresh from '../../../assets/refresh.png'
import { AiOutlineSwitcher } from 'react-icons/ai'
import { AppraiseButton, EditButton, NewButton, SaveButton } from '../../accessories/buttons';
import ReactDOM from "react-dom/client";
import { task_update_mini_cols } from '../data/datagrid_cols';
import { completed, inProgress, yetToStart } from '../../data';
import useFetch from '../../../hooks/useFetch';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import dayjs from 'dayjs';


interface props {
    // this means whether a prop is passed on the component or not the component called
    // anywhere should work (i.e. with the ?) but without the ? it takes it's strict rule
    // that without a prop there will be an error 
    toggler?: () => void
    datgridCol?: any
    customHeightHook?: any
}

export const TaskUpdate = ({ toggler, datgridCol, customHeightHook }: props) => {

    const [tasks_update_state, setTask_update_state] = useState<Boolean>(true)
    const [tasks_update_col_height, setTask_update_col_height] = useState<any>()
    const [isActive, setIsActive] = useState<boolean>(false)


    const grid_row_height = useRef(null)

    const toggle_grid_height = (val: boolean) => {
        console.log(val + " state")
        if (val == true) {

            // console.log(val + " is active")

            return (
                <style
                    dangerouslySetInnerHTML={{
                        __html: `
                    .dx-datagrid-rowsview .dx-row{
                        height : 50px;
                        
                    }
                    `
                    }}
                />

            )
        } else if (val == false) {

            return (
                <style
                    dangerouslySetInnerHTML={{
                        __html: `
                    .dx-datagrid-rowsview .dx-row{
                        height : 10px;
                        
                    }
                    `
                    }}
                />
            )
        }
    }
    const [selectedTask_update, setSelectedTask_update] = useState();

    const selectGrid_update: any = useCallback<any>((e: any) => {
        e.component.byKey(e.currentSelectedRowKeys[0]).done((item: any) => {
            setSelectedTask_update(item);
        });
    }, []);


    const renderGridCell = (value: number) => {
        return (<>
            <div className="flex flex-col" id="avGAllTasks">
                <div className="w-full h-4  bg-gray-300 rounded-sm ">
                    <div className="relative h-full flex ">
                        <div className=" absolute h-full  bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-sm " style={{ width: (value.toString() + '%') }} ></div>
                        <div className="relative text-xs text-white  w-full text-center">{value}%</div>

                    </div>

                </div>
            </div>
        </>);
    }

    //   const  statusButton=(value:string)=> {
    //     console.log( value)
    //     const style = value === 'Completed'? completed : value === 'In-Progress'? inProgress : yetToStart
    //     return (<>
    //    <div>
    //     <button className=" bg-green-100  px-2   h-full text-xs" style={style}>{value}</button>
    //    </div>
    //     </>);
    //   }

    //   DATA FROM REDUX
    const customtoolbar_task_toggle_state = useSelector((state: RootState) => state.checkbox.task_toggle_state)
    const customtoolbar_active_status = useSelector((state: RootState) => state.checkbox.active_status)
    const defaultToolbarStartDate = useSelector((state: RootState) => state.checkbox.default_startDate)
    const defaultToolbarEndDate = useSelector((state: RootState) => state.checkbox.default_endDate)

    const startDate_con = `${dayjs(defaultToolbarStartDate).format()}`
    const endDate_con = `${dayjs(defaultToolbarEndDate).format()}`

    const All_tasks = useFetch(`TaskDetail/GetAllTasks?employeeId=${1}&startdate=${startDate_con}&enddate=${endDate_con}`);

    const [DBdata, error, loading] = All_tasks








    return (

        <div>

            <div style={{ height: '' }} className='flex justify-between w-full h-full  dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base'>
                {/* <h4 className=" justify-start  w-32 ml-4 mb-2 bg-slate-200 ">Tasks Update</h4> */}
                <p id={"form_header2"} className='w-full py-1 px-2 bg-slate-100 dark:bg-darkModeSkin-div_headers_backgroundColor dark:text-darkModeSkin-headers'>Tasks Update</p>
                <ul className='flex items-center justify-end w-full bg-slate-100  self-end dark:bg-darkModeSkin-div_headers_backgroundColor dark:text-darkModeSkin-headers'>

                    <li className="px-4" >
                        <CheckBox
                            // hint="toggle Update rows auto height"
                            iconSize="16"
                            onValueChange={(val) => { { toggle_grid_height(val!) } }}

                        // onClick={toggle_grid_height(tasks_update_col_height)}

                        />
                    </li>
                    <li className="px-4">
                        <img className='w-4' src={locked} />
                    </li>
                    <li className="px-1">
                        <img className='w-4' src={refresh} />
                    </li>
                    <li onClick={toggler} className="hover:cursor-pointer border-2 border-slate-200 text-xs px-6 py-1 text-gray-600 rounded-lg flex items-center"><AiOutlineSwitcher size={16} />Switch</li>

                </ul>
            </div>

            {/* Datagrid table */}
            <div style={{ width: '100%', marginBottom: '5px' }} className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base">
                <DataGrid

                    allowColumnReordering={true}
                    dataSource={DBdata}

                    height={225}
                    columnWidth={datgridCol}
                    rowAlternationEnabled={true}
                    showBorders={true}
                    showRowLines={true}
                    allowColumnResizing={true}
                    columnResizingMode={'widget'}
                    onSelectionChanged={selectGrid_update}
                    wordWrapEnabled={false}



                >
                    {
                        task_update_mini_cols?.map((data_cols) => {

                            let dataType: "string" | "number" | "boolean" | "object" | "date" | "datetime" | undefined = data_cols.dataType as "string" | "number" | "boolean" | "object" | "date" | "datetime" | undefined;
                            return (
                                <Column

                                    key={data_cols.id}
                                    dataType={dataType}
                                    caption={data_cols.caption}
                                    dataField={data_cols.dataField}
                                    cellRender={(data: any) => {
                                        return data_cols.caption === "%COMPLETE" ? renderGridCell(data.value)

                                            : data.value
                                    }}
                                    // cellRender={(data:any)=>renderGridCell(data.value)}
                                    alignment={"left"}
                                    fixed={data_cols.fixed}
                                    // visible={data_cols.visible??true}
                                    width={data_cols.width === null ? 150 : data_cols.width}
                                />
                            )
                        })
                    }

                    <Editing mode={'row'} />
                    <Scrolling columnRenderingMode="virtual" mode='virtual' showScrollbar={'always'} />
                    <Selection mode="single" />
                </DataGrid>
            </div>

            <div className='w-full flex  flex-row items-start justify-between pb-1 mb-1 '>
                <li className=' flex justify-start items-center'>
                    <EditButton />
                </li>

                <ul className='flex items-center justify-start'>
                    <SaveButton />
                    <NewButton />
                </ul>



            </div>


        </div>
    )
}
