/* eslint-disable react/jsx-pascal-case */
import { DatePicker, Form } from "antd";

import {
  Cancel,
  NewButton,
  SaveButton,
  UpdateButton,
} from "../../../../accessories/buttons";
import { useEffect, useRef, useState } from "react";
import { HrInputRightSec1 } from "./inputs/HrRightInputSec1";
import { HrInputRightSecAddress } from "./inputs/HrEmpRightSecAddress";
import { HrInputRightSecFiles } from "./inputs/HrEmpRightFiles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import {
  inputsTypes,
  selectsTypes,
} from "../../../setups/data/types/selectsTypes";
import { SelectsTemplate } from "../../../../../templates/select";
import React from "react";
import InputSelect_template from "../../../../../templates/InputSelect";
import { InputsTemplate } from "../../../../../templates/input";
import { CheckboxTemlate } from "../../../../../templates/checkbox";
import { TextareaTemplate } from "../../../../../templates/textarea";
import useFetch from "../../../../../hooks/useFetch";
import { DropdownGrid_template } from "../../../../../templates/DropdownGrid";
import {
  cost_center_colums,
  form_supervisor_columns,
} from "../data/staff-details-data";
import {
  setGeneralValue,
  settask_footer_table_selected,
} from "../../../../../features/generalSlice";
import { DateTemplate } from "../../../../../templates/date";
import { populateFieldsX, validation } from "../data/validations/validations";
import { ModalTemplate } from "../../../../../templates/modal";
import {
  currentDate,
  saveModalData,
} from "../../../../accessories/component_infos";
import { useFormPriviledge } from "../../../../../hooks/useFormPriviledge";
import { PostDataFunc } from "../../../../../functions/post";
import dayjs, { Dayjs } from "dayjs";
import { UpdateDataFunc } from "../../../../../functions/update";
import { useCrudFunc } from "../../../../../functions/crud";
import { useAccountInfo } from "../../../../../hooks/useAccountInfo";
import { Width } from "devextreme-react/cjs/chart";
import { backgroundColor } from "@syncfusion/ej2-richtexteditor/src/rich-text-editor/models/items";

type DropDownType = {
  id: string;
  value: string;
};

type DropGridType = {
  id: string;
  value: string;
  shtIdFk: string;
};

interface stateTypes {
  empStaffNo: DropDownType;
  empBranchIdfk: DropDownType;
  empDeptIdfk: DropDownType;
  empUnitIdfk: DropDownType;
  empSupervisorEmpIdfk: DropGridType;
  empDirectorEmpIdfk: DropGridType;
  empGradeIdfk: DropDownType;
  empOfficeLocationIdfk: DropDownType;
  empAssignmentStatusIdfk: DropDownType;
  empPersonTypeIdfk: DropDownType;
  empMaritalStatusIdfk: DropDownType;
  employeeTypeX: DropDownType; //Not FOund in payload
  empEmployeeGroupIdfk: DropDownType;
  empTitleIdfk: DropDownType;
  empSectionIdfk: DropDownType;
  empJobTitleIdfk: DropDownType;
  empManagerEmpIdfk: DropGridType;
  empLocationIdfk: DropDownType;
  empStepIdfk: DropDownType;
  empRoomIdfk: DropDownType;
  empAssignmentCategoryIdfk: DropDownType;
  empAppraisalGroupIdfk: DropDownType;
  empGenderIdfk: DropDownType;
  employeeCategoryX: DropDownType; //Not found in the payload
  empNationalityIdfk: DropDownType;
  empReligionIdfk: DropDownType; //Same as Region
  empDistrictIdpk: DropDownType;
  empHomeTownIdfk: DropDownType;
  empCostCenterIdfk: DropGridType;

  //Input fields
  empLastName: string;
  empFirstName: string;
  empOtherNames: string;
  empInitials: string;
  empNickname: string;
  empNationalId: string;
  empSocSecNo: string;
  empDoB: string;
  empHireDate: string;
  empLastPromoDate: string;
  empOfficialEmail: string;
  empPersonalEmail: string;
  empAddress: string;
  empTelNo: string;
  empMobNo: string;
  empIntercom: string;
  empSkype: string;
  empTwitterHanlde: string;
  empFacebookAccount: string;
  empLinkedIn: string;
  empVisible: boolean;
  empActive: boolean;
  empRmks: string;

  //Files states
  passportPicture: string;
  signature: string;
  empIdpk: number;
}

interface Props {
  refreshGrid: () => void;
  selectedRecord?: any;
}

export const Staff_Form = ({ selectedRecord, refreshGrid }: Props) => {
  const init = {
    empStaffNo: { id: "", value: "" },
    empBranchIdfk: { id: "", value: "" },
    empDeptIdfk: { id: "-1", value: "" },
    empUnitIdfk: { id: "", value: "" },
    empSupervisorEmpIdfk: { id: "", value: "", shtIdFk: "" },
    empDirectorEmpIdfk: { id: "", value: "", shtIdFk: "" },
    empGradeIdfk: { id: "", value: "" },
    empOfficeLocationIdfk: { id: "", value: "" },
    empAssignmentStatusIdfk: { id: "", value: "" },
    empPersonTypeIdfk: { id: "", value: "" },
    empMaritalStatusIdfk: { id: "", value: "" },
    employeeTypeX: { id: "", value: "" },
    empEmployeeGroupIdfk: { id: "", value: "" },
    empTitleIdfk: { id: "", value: "" },
    empSectionIdfk: { id: "", value: "" },
    empJobTitleIdfk: { id: "", value: "" },
    empManagerEmpIdfk: { id: "", value: "", shtIdFk: "" },
    empLocationIdfk: { id: "", value: "" },
    empStepIdfk: { id: "", value: "" },
    empRoomIdfk: { id: "", value: "" },
    empAssignmentCategoryIdfk: { id: "", value: "" },
    empAppraisalGroupIdfk: { id: "", value: "" },
    empGenderIdfk: { id: "", value: "" },
    employeeCategoryX: { id: "", value: "" },
    empNationalityIdfk: { id: "", value: "" },
    empReligionIdfk: { id: "", value: "" },
    empDistrictIdpk: { id: "", value: "" }, //Not FOund in payload
    empHomeTownIdfk: { id: "", value: "" },
    empCostCenterIdfk: { id: "", value: "", shtIdFk: "" },

    //Input fields
    empLastName: "",
    empFirstName: "",
    empOtherNames: "",
    empNickname: "",
    empInitials: "",
    empNationalId: "",
    empSocSecNo: "",
    empDoB: "",
    empHireDate: "",
    empLastPromoDate: "",
    empOfficialEmail: "",
    empPersonalEmail: "",
    empAddress: "",
    empIntercom: "",
    empSkype: "",
    empTwitterHanlde: "",
    empFacebookAccount: "",
    empLinkedIn: "",
    empVisible: false,
    empActive: false,
    empRmks: "",
    empTelNo: "",
    empMobNo: "",

    //Files states
    passportPicture: "",
    signature: "",
    empIdpk: 0,
  };

  
  const borderTheme = useSelector( (state: RootState) => state.general.cssBorderClass);
  //component states
  const [states, setStates] = useState<stateTypes>(init);

  const [refreshEndpoint, setRefreshEndpoint] = useState<any>({
    //other states
    refreshregions: false,
    refreshdistricts: false,
    refreshhomeTowns: false,
    refreshdepartment: false,
    refreshsection: false,
  });

  const disabled = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const updateMode = useSelector(
    (state: RootState) => state.general.updateMode
  );
  const [refresh, setRefresh] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const [modalData, setModalData] = useState<any>(saveModalData);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [Icon, setIcon] = useState<"warning" | "success" | "question">(
    "warning"
  );
  const [privType, setPrivType] = useState<string>("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);
  const [Posting, Updating] = useCrudFunc();
  const [empId, userId] = useAccountInfo();

  const dispatch = useDispatch();
  //handle state update
  const updateState = (stateName: string, value: any) => {
    setStates((prev: any) => ({ ...prev, [stateName]: value }));
  };

  const updateEndpointState = (stateName: string, value: any) => {
    setRefreshEndpoint((prev: any) => ({ ...prev, [stateName]: value }));
  };

  // setting individual refresh state for some components

  //consuming api
  const [staffNos] = useFetch(`HrEmployee/FormStaffNumbers`, refresh);

  //branchsection
  const [branches] = useFetch(`HrEmployee/FormBranches`, refresh);
  const [departments] = useFetch(
    `HrEmployee/FormDepartments/${states.empBranchIdfk.id}`,
    refreshEndpoint.refreshdepartment
  );
  const [units] = useFetch(`HrEmployee/FormUnits`, refresh);
  const [supervisers] = useFetch(`HrEmployee/FormSupervisor`, refresh);
  const [directors] = useFetch(`HrEmployee/FormDirector`, refresh); //display for datagrid inside the select
  const [grades] = useFetch(`HrEmployee/FormGrades`, refresh);
  const [officeLocations] = useFetch(`HrEmployee/FormOfficeLocations`, refresh);
  const [assignmentStatus] = useFetch(
    `HrEmployee/FormAssignmentStatus`,
    refresh
  );
  const [personsTypes] = useFetch(`HrEmployee/FormPersonTypes`, refresh);
  const [maritalStatus] = useFetch(`HrEmployee/FormMaritalStatus`, refresh);
  const [employeeTypes] = useFetch(`HrEmployee/FormEmployeeType`, refresh);
  const [employeeGroups] = useFetch(`HrEmployee/FormEmployeeGroups`, refresh);
  const [titles] = useFetch(`HrEmployee/FormTitles`, refresh);

  //sectionSelects
  const [sections] = useFetch(
    `HrEmployee/FormSections/${states.empDeptIdfk.id}`,
    refreshEndpoint.refreshsection
  );
  const [jobTitles] = useFetch(`HrEmployee/FormJobTitles`, refresh);
  const [managers] = useFetch(`HrEmployee/FormManagers`, refresh);
  const [formLocations] = useFetch(`HrEmployee/FormLocations`, refresh);
  const [formSteps] = useFetch(`HrEmployee/FormSteps`, refresh);
  const [roomNumbers] = useFetch(`HrEmployee/FormRoomNumbers`, refresh);
  const [assignmnetCategories] = useFetch(
    `HrEmployee/FormAssignmentCategories`,
    refresh
  );
  const [appraisalGroups] = useFetch(`HrEmployee/FormAppraisalGroups`, refresh);
  const [genders] = useFetch(`HrEmployee/FormGender`, refresh);
  const [employeeCategories] = useFetch(
    `HrEmployee/FormEmployeeCategories`,
    refresh
  );

  //Nationality section
  const [Nationalities] = useFetch(`HrEmployee/FormCountries`, refresh);
  const [regions] = useFetch(
    `HrEmployee/FormRegions/${states.empNationalityIdfk.id}`,
    refreshEndpoint.refreshregions
  );
  const [districts] = useFetch(
    `HrEmployee/FormDistricts/${states.empReligionIdfk.id}`,
    refreshEndpoint.refreshdistricts
  );
  const [homeTowns] = useFetch(
    `HrEmployee/FormTowns/${states.empDistrictIdpk.id}`,
    refreshEndpoint.refreshhomeTowns
  );
  const [costCenters] = useFetch(`HrEmployee/FormCostCenters`, refresh);

  const branchSec: selectsTypes[] = [
    {
      id: 0,
      label: "Branch",
      idExpr: "obrIdpk",
      dataExpr: "obrName",
      optionsData: [...branches],
      stateName: "empBranchIdfk",
      defaultValue: states.empBranchIdfk.value,
      style: "",
    },
    {
      id: 1,
      label: "Department",
      idExpr: "dptIdpk",
      dataExpr: "dptName",
      optionsData: [...departments],
      stateName: "empDeptIdfk",
      defaultValue: states.empDeptIdfk.value,
      style: "",
    },
    {
      id: 2,
      label: "Units",
      idExpr: "untIDpk",
      dataExpr: "untName",
      optionsData: [...units],
      stateName: "empUnitIdfk",
      defaultValue: states.empUnitIdfk.value,
      style: "",
    },
    {
      id: 3,
      label: "Supervisor",
      idExpr: "empIdpk",
      dataExpr: "empName",
      optionsData: [...supervisers],
      stateName: "empSupervisorEmpIdfk",
      defaultValue: states.empSupervisorEmpIdfk.value,
      style: {backgroundColor: "red"},
      columns: [...form_supervisor_columns],
    },
    {
      id: 4,
      label: "Director",
      idExpr: "empIdpk",
      dataExpr: "empName",
      optionsData: [...directors],
      stateName: "empDirectorEmpIdfk",
      defaultValue: states.empDirectorEmpIdfk.value,
      style: "",
      columns: [...form_supervisor_columns],
    }, //ask for dropdown datagrid
    {
      id: 5,
      label: "Grade",
      idExpr: "grdIdpk",
      dataExpr: "grdName",
      optionsData: [...grades],
      stateName: "empGradeIdfk",
      defaultValue: states.empGradeIdfk.value,
      style: "",
    },
    {
      id: 6,
      label: "Office Location",
      idExpr: "locIDpk",
      dataExpr: "locName",
      optionsData: [...officeLocations],
      stateName: "empOfficeLocationIdfk",
      defaultValue: states.empOfficeLocationIdfk.value,
      style: "",
    },
    {
      id: 7,
      label: "Assign. Status",
      idExpr: "astIdpk",
      dataExpr: "astName",
      optionsData: [...assignmentStatus],
      stateName: "empAssignmentStatusIdfk",
      defaultValue: states.empAssignmentStatusIdfk.value,
      style: "",
    },
    {
      id: 8,
      label: "Person Type",
      idExpr: "pstIdpk",
      dataExpr: "pstName",
      optionsData: [...personsTypes],
      stateName: "empPersonTypeIdfk",
      defaultValue: states.empPersonTypeIdfk.value,
      style: "",
    },
    {
      id: 9,
      label: "Marital Status",
      idExpr: "mtsIdpk",
      dataExpr: "mtsName",
      optionsData: [...maritalStatus],
      stateName: "empMaritalStatusIdfk",
      defaultValue: states.empMaritalStatusIdfk.value,
      style: "",
    },
    {
      id: 10,
      label: "Employee Type",
      idExpr: "ettIdpk",
      dataExpr: "ettName",
      optionsData: [...employeeTypes],
      stateName: "employeeTypeX",
      defaultValue: states.employeeTypeX.value,
      style: "",
    },
    {
      id: 11,
      label: "Emp. Group",
      idExpr: "egpIdpk",
      dataExpr: "egpName",
      optionsData: [...employeeGroups],
      stateName: "empEmployeeGroupIdfk",
      defaultValue: states.empEmployeeGroupIdfk.value,
      style: "",
    },
  ];

  const SectionSec: selectsTypes[] = [
    {
      id: 0,
      label: "Section",
      idExpr: "sxnIdpk",
      dataExpr: "sxnName",
      optionsData: [...sections],
      stateName: "empSectionIdfk",
      defaultValue: states.empSectionIdfk.value,
      style: "",
    },
    {
      id: 1,
      label: "Job Title",
      idExpr: "jbtIdpk",
      dataExpr: "jbtName",
      optionsData: [...jobTitles],
      stateName: "empJobTitleIdfk",
      defaultValue: states.empJobTitleIdfk.value,
      style: "",
    },
    {
      id: 2,
      label: "Manager",
      idExpr: "empIdpk",
      dataExpr: "empName",
      optionsData: [...managers],
      stateName: "empManagerEmpIdfk",
      defaultValue: states.empManagerEmpIdfk.value,
      style: "",
      columns: [...form_supervisor_columns],
    }, //ask for dropdown datagrid
    {
      id: 3,
      label: "Location",
      idExpr: "locIdpk",
      dataExpr: "locName",
      optionsData: [...formLocations],
      stateName: "empLocationIdfk",
      defaultValue: states.empLocationIdfk.value,
      style: "",
    },
    {
      id: 4,
      label: "Step",
      idExpr: "stpIdpk",
      dataExpr: "stpName",
      optionsData: [...formSteps],
      stateName: "empStepIdfk",
      defaultValue: states.empStepIdfk.value,
      style: "",
    },
    {
      id: 5,
      label: "Room No",
      idExpr: "romIdpk",
      dataExpr: "romName",
      optionsData: [...roomNumbers],
      stateName: "empRoomIdfk",
      defaultValue: states.empRoomIdfk.value,
      style: "",
    },
    {
      id: 6,
      label: "Assign. Category",
      idExpr: "ascIdpk",
      dataExpr: "ascName",
      optionsData: [...assignmnetCategories],
      stateName: "empAssignmentCategoryIdfk",
      defaultValue: states.empAssignmentCategoryIdfk.value,
      style: "",
    },
    {
      id: 7,
      label: "Appraisal Group",
      idExpr: "apgIdpk",
      dataExpr: "apgName",
      optionsData: [...appraisalGroups],
      stateName: "empAppraisalGroupIdfk",
      defaultValue: states.empAppraisalGroupIdfk.value,
      style: "",
    },
    {
      id: 8,
      label: "Gender",
      idExpr: "gndIdpk",
      dataExpr: "gndName",
      optionsData: [...genders],
      stateName: "empGenderIdfk",
      defaultValue: states.empGenderIdfk.value,
      style: "",
    },
    {
      id: 9,
      label: "Emp. Categories",
      idExpr: "ectIdpk",
      dataExpr: "ectName",
      optionsData: [...employeeCategories],
      stateName: "employeeCategoryX",
      defaultValue: states.employeeCategoryX.value,
      style: "",
    },
  ];

  const staffNoSec: selectsTypes[] = [
    {
      id: 0,
      label: "Staff No",
      idExpr: "empIdpk",
      dataExpr: "empStaffNo",
      optionsData: [...staffNos],
      stateName: "empStaffNo",
      defaultValue: states.empStaffNo.value,
      style: "",
    },
    {
      id: 1,
      label: "Surname",
      idExpr: "",
      dataExpr: "",
      stateName: "empLastName",
      defaultValue: states.empLastName,
      style: "",
    },
    {
      id: 2,
      label: "FirstName",
      idExpr: "",
      dataExpr: "",
      stateName: "empFirstName",
      defaultValue: states.empFirstName,
      style: "",
    },
    {
      id: 3,
      label: "Other Name",
      idExpr: "",
      dataExpr: "",
      stateName: "empOtherNames",
      defaultValue: states.empOtherNames,
      style: "",
    },
  ];

  const NickNameSec: selectsTypes[] = [
    {
      id: 0,
      label: "Title",
      idExpr: "titIdpk",
      dataExpr: "titName",
      stateName: "empTitleIdfk",
      defaultValue: states.empTitleIdfk.value,
      style: "",
    },
    {
      id: 1,
      label: "Nick Name",
      idExpr: "",
      dataExpr: "",
      stateName: "empNickname",
      defaultValue: states.empNickname,
      style: "",
    },
    {
      id: 2,
      label: "Initials",
      idExpr: "",
      dataExpr: "",
      stateName: "empInitials",
      defaultValue: states.empInitials,
      style: "",
    },
    {
      id: 3,
      label: "National ID",
      idExpr: "",
      dataExpr: "",
      stateName: "empNationalId",
      defaultValue: states.empNationalId,
      style: "",
    },
    {
      id: 4,
      label: "Social.Sec.No",
      idExpr: "",
      dataExpr: "",
      stateName: "empSocSecNo",
      defaultValue: states.empSocSecNo,
      style: "",
    },
  ];

  const NationalitySec: selectsTypes[] = [
    {
      id: 0,
      label: "Nationality",
      idExpr: "ctyIdpk",
      dataExpr: "ctyNationality",
      optionsData: [...Nationalities],
      stateName: "empNationalityIdfk",
      defaultValue: states.empNationalityIdfk.value,
      style: "",
    }, //ask for dropdown datagrid
    {
      id: 1,
      label: "District",
      idExpr: "dstIdpk",
      dataExpr: "dstName",
      optionsData: [...districts],
      stateName: "empDistrictIdpk",
      defaultValue: states.empDistrictIdpk.value,
      style: "",
    },
    {
      id: 2,
      label: "Cost Center",
      idExpr: "cctIdpk",
      dataExpr: "cctName",
      optionsData: [...costCenters],
      stateName: "empCostCenterIdfk",
      defaultValue: states.empCostCenterIdfk.value,
      style: "",
      columns: [...cost_center_colums],
    },
  ];

  const TelephoneNosSec: inputsTypes[] = [
    {
      id: 0,
      label: "TelephoneNos",
      stateName: "empTelNo",
      defaultValue: states.empTelNo,
      style: "",
    },
    {
      id: 1,
      label: "Official Email",
      stateName: "empOfficialEmail",
      defaultValue: states.empOfficialEmail,
      style: "",
    },
    {
      id: 2,
      label: "Skype",
      stateName: "empSkype",
      defaultValue: states.empSkype,
      style: "",
    },
    {
      id: 3,
      label: "Twitter",
      stateName: "empTwitterHanlde",
      defaultValue: states.empTwitterHanlde,
      style: "",
    },
  ];
  const RegionSec: selectsTypes[] = [
    {
      id: 0,
      label: "Region",
      idExpr: "rgnIdpk",
      dataExpr: "rgnName",
      optionsData: [...regions],
      stateName: "empReligionIdfk",
      defaultValue: states.empReligionIdfk.value,
      style: "",
    },
    {
      id: 1,
      label: "HomeTown",
      idExpr: "twnIdpk",
      dataExpr: "twnName",
      optionsData: [...homeTowns],
      stateName: "empHomeTownIdfk",
      defaultValue: states.empHomeTownIdfk.value,
      style: "",
    },
  ];

  const IntercomSec: inputsTypes[] = [
    {
      id: 0,
      label: "Intercom/PLC",
      stateName: "empIntercom",
      defaultValue: states.empIntercom,
      style: "",
    },
    {
      id: 1,
      label: "MobileNo",
      stateName: "empMobNo",
      defaultValue: states.empMobNo,
      style: "",
    },
    {
      id: 2,
      label: "Personal Email",
      stateName: "empPersonalEmail",
      defaultValue: states.empPersonalEmail,
      style: "",
    },
    {
      id: 3,
      label: "Facebook",
      stateName: "empFacebookAccount",
      defaultValue: states.empFacebookAccount,
      style: "",
    },
    {
      id: 4,
      label: "LinkedIn",
      stateName: "empLinkedIn",
      defaultValue: states.empLinkedIn,
      style: "",
    },
  ];

  const convertYear = (y: string) => {
    let year = dayjs().diff(y === "" ? new Date() : y, "year");
    return year;
  };
  // right section 1[Date,...]
  const dateSections = [
    {
      id: 0,
      label: "Date of Birth",
      age: convertYear(states.empDoB),
      stateName: "empDoB",
      defaultValue: states?.empDoB,
    },
    {
      id: 1,
      label: "Hire Date",
      age: convertYear(states.empHireDate),
      stateName: "empHireDate",
      defaultValue: states.empHireDate,
    },
    {
      id: 2,
      label: "Last Promo Date",
      age: convertYear(states.empLastPromoDate),
      stateName: "empLastPromoDate",
      defaultValue: states.empLastPromoDate,
    },
  ];

  const other1 = [
    {
      id: 1,
      label: "Address",
      stateName: "empAddress",
      defaultValue: states.empAddress,
      style: "",
    },
  ];

  const other2 = [
    {
      id: 2,
      label: "Remarks",
      stateName: "empRmks",
      defaultValue: states.empRmks,
      style: "",
    },
  ];

  const handleValidation = (method: "save" | "update" = "save") => {
    validation.init = [
      ...staffNoSec,
      ...NickNameSec,
      ...branchSec,
      ...SectionSec,
      ...dateSections,
      ...other1,
      ...NationalitySec,
      ...TelephoneNosSec,
      ...RegionSec,
      ...IntercomSec,
      ...other2,
    ];
    const data: any = validation.input(states);

    const { title, message }: any = data?.required;

    if (title !== "" && message !== "") {
      setModalData({
        message: message,
        title: title,
        okText: "Ok",
      });
      setDisableButton(true);
      setOpenModal(true);
    } else {
      if (method === "save") {
        setIcon("question");
        setModalData({
          message: "Are you sure you want to save this record?",
          title: "Save Record ?",
          okText: "Yes",
        });
        setDisableButton(false);
        setOpenModal(true);
      } else {
        setIcon("question");
        setModalData({
          message: "Are you sure you want to update this record?",
          title: "Update Record ?",
          okText: "Yes",
        });
        setDisableButton(false);
        setOpenModal(true);
      }
    }
  };

  const AccessPriv_on_save_Button = () => {
    setPrivType("save");
    if (savePriv === true) {
      handleValidation();
    } else {
      setModalData({
        message: "No Access Priviledge",
        title: "Access Denied! ",
        okText: "Ok",
        cancelText: "",
      });
      setIcon("warning");
      setOpenModal(true);
      setDisableButton(true);
    }
  };

  const saveData = async () => {
    await Posting(
      `HrEmployee/CreateEmployee`,
      {
        empStaffNo: states.empStaffNo.value,
        empLastName: states.empLastName,
        empFirstName: states.empFirstName,
        empOtherNames: states.empOtherNames,
        empInitials: states.empInitials,
        empNickname: states.empNickname,
        empDoB: states.empDoB,
        empHireDate: states.empHireDate,
        empLastPromoDate: states.empLastPromoDate,
        empTelNo: states.empTelNo,
        empMobNo: states.empMobNo,
        empIntercom: states.empIntercom,
        empOfficialEmail: states.empOfficialEmail,
        empPersonalEmail: states.empPersonalEmail,
        empAddress: states.empAddress,
        empSocSecNo: states.empSocSecNo,
        empNationalId: states.empNationalId,
        empTitleIdfk: states.empTitleIdfk.id,
        empBranchIdfk: states.empBranchIdfk.id,
        empDeptIdfk: states.empDeptIdfk.id,
        empSectionIdfk: states.empSectionIdfk.id,
        empUnitIdfk: states.empUnitIdfk.id,
        empLocationIdfk: states.empLocationIdfk.id,
        empGradeIdfk: states.empGradeIdfk.id,
        empStepIdfk: states.empStepIdfk.id,
        empJobTitleIdfk: states.empJobTitleIdfk.id,
        empReligionIdfk: states.empReligionIdfk.id,
        empCostCenterIdfk: states.empCostCenterIdfk.id,
        empAppraisalGroupIdfk: states.empAppraisalGroupIdfk.id,
        empGenderIdfk: states.empGenderIdfk.id,
        empAssignmentStatusIdfk: states.empAssignmentStatusIdfk.id,
        empEmployeeGroupIdfk: states.empEmployeeGroupIdfk.id, //tell jessy to add this field to the api
        empOfficeLocationIdfk: states.empOfficeLocationIdfk.id,
        empRoomIdfk: states.empRoomIdfk.id,
        empSupervisorEmpIdfk: states.empSupervisorEmpIdfk.id,
        empSupervisorShtTitle: states.empSupervisorEmpIdfk.value,
        empManagerEmpIdfk: states.empManagerEmpIdfk.id,
        empManagerShtTitle: states.empManagerEmpIdfk.value,
        empDirectorEmpIdfk: states.empDirectorEmpIdfk.id,
        empDirectorShtTitle: states.empDirectorEmpIdfk.value,
        empMaritalStatusIdfk: states.empMaritalStatusIdfk.id,
        empNationalityIdfk: states.empNationalityIdfk.id,
        empHomeTownIdfk: states.empHomeTownIdfk.id,
        empAssignmentCategoryIdfk: states.empAssignmentCategoryIdfk.id,
        empPersonTypeIdfk: states.empPersonTypeIdfk.id,
        empFacebookAccount: states.empFacebookAccount,
        empLinkedIn: states.empLinkedIn,
        empSkype: states.empSkype,
        empTwitterHanlde: states.empTwitterHanlde,
        empVisible: states.empVisible,
        empActive: states.empActive,
        empRmks: states.empRmks,
      },
      `Created the Staff Form with empIdpk:${states.empIdpk}, empStaffNo:${states.empStaffNo.value}, empLastName:${states.empLastName}, empFirstName:${states.empFirstName} Date Created:${currentDate}. created By:${userId}`
    )
      .then((response) => {
        console.log(response);
        refreshGrid();
        setRefresh(!refresh);
        setModalData({
          message: "Record Save Successfully",
          title: "Saved ",
          okText: "Ok",
          cancelText: "",
        });
        setIcon("success");
        setOpenModal(true);
        setDisableButton(true);
      })
      .catch((err) => {
        setModalData({
          message: "Please contact the systems administrator",
          title: "Record not saved!",
          okText: "Ok",
          cancelText: "",
        });
        setIcon("warning");
        setOpenModal(true);
        setDisableButton(true);
      });
  };

  const AccessPriv_on_Update_Button = () => {
    setPrivType("update");
    if (savePriv === true) {
      handleValidation("update");
    } else {
      setModalData({
        message: "No Access Priviledge",
        title: "Access Denied! ",
        okText: "Ok",
        cancelText: "",
      });
      setIcon("warning");
      setOpenModal(true);
      setDisableButton(true);
    }
  };

  const updateData = async () => {
    await Updating(
      `HrEmployee/UpdateEmployee/${states.empIdpk}`,
      {
        empStaffNo: states.empStaffNo.value,
        empLastName: states.empLastName,
        empFirstName: states.empFirstName,
        empOtherNames: states.empOtherNames,
        empInitials: states.empInitials,
        empNickname: states.empNickname,
        empDoB: states.empDoB,
        empHireDate: states.empHireDate,
        empLastPromoDate: states.empLastPromoDate,
        empTelNo: states.empTelNo,
        empMobNo: states.empMobNo,
        empIntercom: states.empIntercom,
        empOfficialEmail: states.empOfficialEmail,
        empPersonalEmail: states.empPersonalEmail,
        empAddress: states.empAddress,
        empSocSecNo: states.empSocSecNo,
        empNationalId: states.empNationalId,
        empTitleIdfk: states.empTitleIdfk.id,
        empBranchIdfk: states.empBranchIdfk.id,
        empDeptIdfk: states.empDeptIdfk.id,
        empSectionIdfk: states.empSectionIdfk.id,
        empUnitIdfk: states.empUnitIdfk.id,
        empLocationIdfk: states.empLocationIdfk.id,
        empGradeIdfk: states.empGradeIdfk.id,
        empStepIdfk: states.empStepIdfk.id,
        empJobTitleIdfk: states.empJobTitleIdfk.id,
        empReligionIdfk: states.empReligionIdfk.id,
        empCostCenterIdfk: states.empCostCenterIdfk.id,
        empAppraisalGroupIdfk: states.empAppraisalGroupIdfk.id,
        empGenderIdfk: states.empGenderIdfk.id,
        empAssignmentStatusIdfk: states.empAssignmentStatusIdfk.id,
        empEmployeeGroupIdfk: states.empEmployeeGroupIdfk.id, //tell jessy to add this field to the api
        empOfficeLocationIdfk: states.empOfficeLocationIdfk.id,
        empRoomIdfk: states.empRoomIdfk.id,
        empSupervisorEmpIdfk: states.empSupervisorEmpIdfk.id,
        empSupervisorShtTitle: states.empSupervisorEmpIdfk.value,
        empManagerEmpIdfk: states.empManagerEmpIdfk.id,
        empManagerShtTitle: states.empManagerEmpIdfk.value,
        empDirectorEmpIdfk: states.empDirectorEmpIdfk.id,
        empDirectorShtTitle: states.empDirectorEmpIdfk.value,
        empMaritalStatusIdfk: states.empMaritalStatusIdfk.id,
        empNationalityIdfk: states.empNationalityIdfk.id,
        empHomeTownIdfk: states.empHomeTownIdfk.id,
        empAssignmentCategoryIdfk: states.empAssignmentCategoryIdfk.id,
        empPersonTypeIdfk: states.empPersonTypeIdfk.id,
        empFacebookAccount: states.empFacebookAccount,
        empLinkedIn: states.empLinkedIn,
        empSkype: states.empSkype,
        empTwitterHanlde: states.empTwitterHanlde,
        empVisible: states.empVisible,
        empActive: states.empActive,
        empRmks: states.empRmks,
      },
      `Updated the Staff Form with empIdpk:${states.empIdpk}, empStaffNo:${states.empStaffNo.value}, empLastName:${states.empLastName}, empFirstName:${states.empFirstName} Date Created:${currentDate}. created By:${userId}`
    )
      .then((response) => {
        console.log(response);
        refreshGrid();
        setRefresh(!refresh);
        setModalData({
          message: "Record Updated Successfully",
          title: "Updated ",
          okText: "Ok",
          cancelText: "",
        });
        setIcon("success");
        setOpenModal(true);
        setDisableButton(true);
      })
      .catch((err) => {
        setModalData({
          message: "Please contact the systems administrator",
          title: "Record not updated!",
          okText: "Ok",
          cancelText: "",
        });
        setIcon("warning");
        setOpenModal(true);
        setDisableButton(true);
      });
  };

  useEffect(() => {
    populateFieldsX(selectedRecord, updateState);
  }, [selectedRecord]);

  return (
    <div className="w-full dark:text-darkModeSkin-base">
      <ModalTemplate
        icon={Icon}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        disableCancel={disableButton}
        open={openModal}
        okHandler={async () => {
          modalData.title === "Save Record ?"
            ? await saveData()
            : modalData.title === "Update Record ?"
            ? await updateData() //UpdateData()
            : setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
      />
      <p className=" border-[1px] border-b-0 py-1 px-2 w-full bg-slate-100 font-semibold dark:bg-darkModeSkin-div_headers_backgroundColor dark:text-darkModeSkin-headers"
       style={{ borderColor: borderTheme}}>
        Staff Details
      </p>

      <Form
        className="w-full border-[1px] border-slate-100 flex justify-between py-1 px-2 dark:text-darkModeSkin-base"
        style={{ borderColor: borderTheme}}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        size="small"
      >
        {/* Left section */}
        <section className="w-1/2 border-r-2 border-slate-100 pr-3 flex justify-between dark:text-darkModeSkin-base">
          {/*SUB LEFT SECTION */}
          <div className="b w-1/2">
            {/* Staff No */}
            {/* <HrEmpStaffNo /> */}

            {/* Inputs [empLastName...] */}

            {staffNoSec.map(
              ({
                id,
                label,
                idExpr,
                dataExpr,
                defaultValue,
                stateName,
                style,
              }) => {
                return (
                  <React.Fragment>
                    {id === 0 ? (
                      <>
                        <InputSelect_template
                          useCallFunc
                          label={label}
                          idexpr={idExpr}
                          dataexp={dataExpr}
                          options={staffNos}
                          placeHolder={defaultValue}
                          selectedValue={(e) => {
                            updateState(stateName!, {
                              id: e[idExpr!],
                              value: e[dataExpr!],
                            });
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <InputsTemplate
                          useCallbackFunc
                          label={label}
                          span
                          defaultValue={defaultValue}
                          orderOnchange={(e) => updateState(stateName, e)}
                        />
                      </>
                    )}
                  </React.Fragment>
                );
              }
            )}

            <br />

            {branchSec.map(
              ({
                id,
                label,
                idExpr,
                dataExpr,
                optionsData,
                stateName,
                defaultValue,
                style,
                columns,
              }) => {
                return (
                  <div className="w-full">
                    {id === 3 || id === 4 ? (
                      <DropdownGrid_template
                        columns={columns}
                        onRowClick={(e) =>
                          updateState(stateName!, {
                            id: e[idExpr!],
                            value: e[dataExpr!],
                          })
                        }
                        className="mb-[3px] w-full bg-green-200"
                        gridData={optionsData}
                        defaultValue={defaultValue}
                        labelCol={8}
                        label={label}
                        disabled={disabled}
                        cusBtnStyle
                        dropdownBtnStyles={{
                          ...style,
                          minWidth: 212,
                          // maxWidth: 210,
                          overflow: "hidden",
                          marginBottom: 5,
                        }}
                      />
                    ) : (
                      <SelectsTemplate
                        label={label}
                        idexpr={idExpr}
                        dataexp={dataExpr}
                        options={optionsData}
                        placeHolder={defaultValue}
                        useCallFunc
                        selectedValue={(e) => {
                          console.log("selected: ", e);
                          updateState(stateName!, {
                            id: e[idExpr!],
                            value: e[dataExpr!],
                          });
                          id === 0 &&
                            updateEndpointState(
                              "refreshdepartment",
                              !refreshEndpoint.refreshdepartment
                            );
                          id === 1 &&
                            updateEndpointState(
                              "refreshsection",
                              !refreshEndpoint.refreshsection
                            );
                        }}
                      />
                    )}
                  </div>
                );
              }
            )}
          </div>

          {/* Title  */}
          <div className="w w-1/2">
            {NickNameSec.map(
              ({
                id,
                label,
                idExpr,
                dataExpr,
                stateName,
                defaultValue,
                style,
              }) => {
                return (
                  <div>
                    {id === 0 ? (
                      <>
                        <InputSelect_template
                          useCallFunc
                          label={label}
                          idexpr={idExpr}
                          dataexp={dataExpr}
                          options={titles}
                          placeHolder={defaultValue}
                          selectedValue={(e) => {
                            updateState(stateName!, {
                              id: e[idExpr!],
                              value: e[dataExpr!],
                            });
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <InputsTemplate
                          useCallbackFunc
                          label={label}
                          defaultValue={defaultValue}
                          span
                          orderOnchange={(e) => updateState(stateName!, e)}
                        />
                      </>
                    )}
                  </div>
                );
              }
            )}
            <br />
            {/*  */}
            {SectionSec.map(
              ({
                id,
                label,
                idExpr,
                dataExpr,
                optionsData,
                stateName,
                defaultValue,
                style,
                columns,
              }) => {
                return (
                  <React.Fragment key={id}>
                    {id === 2 ? (
                      <DropdownGrid_template
                        columns={columns}
                        onRowClick={(e) =>
                          updateState(stateName!, {
                            id: e[idExpr!],
                            value: e[dataExpr!],
                          })
                        }
                        className="mb-[3px]"
                        gridData={optionsData}
                        defaultValue={defaultValue}
                        labelCol={8}
                        label={label}
                        disabled={disabled}
                        cusBtnStyle
                        dropdownBtnStyles={{
                          minWidth: 210,
                          maxWidth: 210,
                          overflow: "hidden",
                        }}
                      />
                    ) : (
                      <SelectsTemplate
                        useCallFunc
                        label={label}
                        idexpr={idExpr}
                        dataexp={dataExpr}
                        options={optionsData}
                        placeHolder={defaultValue}
                        selectedValue={(e) => {
                          updateState(stateName, {
                            id: e[idExpr!],
                            value: e[dataExpr!],
                          });
                        }}
                      />
                    )}
                  </React.Fragment>
                );
              }
            )}

            {/* buttons */}
            <div className="  flex flex-row justify-end mt-2 pr-7 ">
              {!updateMode ? (
                <SaveButton handleSave={() => AccessPriv_on_save_Button()} />
              ) : (
                <UpdateButton
                  handleUpdate={() => {
                    AccessPriv_on_Update_Button();
                  }}
                />
              )}

              {disabled ? (
                <NewButton
                  new_button_toggler={() => {
                    dispatch(
                      setGeneralValue({ expr: "formDisabled", value: false })
                    );
                    setStates(init);
                  }}
                />
              ) : (
                <Cancel
                  useCallbackFunc
                  cancel_button_toggler={() => {
                    dispatch(
                      setGeneralValue({ expr: "formDisabled", value: true })
                    );
                    dispatch(
                      setGeneralValue({ expr: "updateMode", value: false })
                    );
                    setStates(init);
                  }}
                />
              )}
            </div>
          </div>
        </section>

        {/* ##### */}
        {/* right section */}
        <section className="w-1/2 border-l-2 dark:bg-fill dark:text-darkModeSkin-base">
          {/*SUB left SECTION */}
          <div className="b w-full flex justify-between dark:bg-fill dark:text-darkModeSkin-base">
            <div className="w-1/2">
              {/*RIGHT SECTION 1 */}
              {/* <HrInputRightSec1 /> */}
              {dateSections.map((item) => {
                return (
                  <Form.Item
                    className="mb-1"
                    label={
                      <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                        {item.label}
                      </p>
                    }
                    name={item.label}
                    rules={[{ required: false }]}
                  >
                    <span className="flex flex-row items-center">
                      <DateTemplate
                        changeDate={(d) => updateState(item.stateName, d)}
                        dbFormat={true}
                        selectedDate={dayjs(
                          item?.defaultValue === ""
                            ? new Date()
                            : item?.defaultValue
                        )}
                      />
                      <p className=" text-xs w-5/12 pl-1 text-blue-800">
                        {item.age.toString()} years
                      </p>
                    </span>
                  </Form.Item>
                );
              })}

              {/* <HrInputRightSecAddress /> */}

              <TextareaTemplate
                useCallbackFunc
                labelCol={8}
                label={"Address"}
                height={68.0}
                width={"23.9"}
                setCallbackValue={(value) => updateState("empAddress", value)}
                defaultValue={states.empAddress}
              />
            </div>

            {/* Image and signature */}
            <HrInputRightSecFiles />
          </div>

          <div className="flex justify-between mt-0.5">
            <div className="w-1/2">
              {/*right SECTION 2 */}
              {/* <HrEmpNationality setNationality={() => { }} />
                            <HrEmpDistrict setDistrict={() => { }} />
                            <HrEmpCostCenter setCostCenter={() => { }} /> */}
              {NationalitySec.map(
                ({
                  id,
                  label,
                  idExpr,
                  dataExpr,
                  optionsData,
                  stateName,
                  defaultValue,
                  style,
                  columns,
                }) => {
                  return (
                    <React.Fragment>
                      {id === 2 ? (
                        <DropdownGrid_template
                          columns={columns}
                          onRowClick={(e) =>
                            updateState(stateName!, {
                              id: e[idExpr!],
                              value: e[dataExpr!],
                            })
                          }
                          gridData={optionsData}
                          defaultValue={defaultValue}
                          labelCol={8}
                          label={label}
                          disabled={disabled}
                          cusBtnStyle
                          dropdownBtnStyles={{
                            minWidth: 216,
                            maxWidth: 205,
                            overflow: "hidden",
                          }}
                        />
                      ) : (
                        <SelectsTemplate
                          label={label}
                          useCallFunc
                          idexpr={idExpr}
                          dataexp={dataExpr}
                          options={optionsData}
                          placeHolder={defaultValue}
                          selectedValue={(e) => {
                            updateState(stateName!, {
                              id: e[idExpr!],
                              value: e[dataExpr!],
                            });
                            id === 1
                              ? updateEndpointState(
                                  "refreshhomeTowns",
                                  !refreshEndpoint.refreshhomeTowns
                                )
                              : updateEndpointState(
                                  "refreshregions",
                                  !refreshEndpoint.refreshregions
                                );
                          }}
                        />
                      )}
                    </React.Fragment>
                  );
                }
              )}
              {/* <HrInputRightSec2 /> */}

              {TelephoneNosSec.map(
                ({ id, label, stateName, defaultValue, style }) => {
                  return (
                    <React.Fragment>
                      <InputsTemplate
                        label={label}
                        defaultValue={defaultValue}
                        orderOnchange={(e) => updateState(stateName, e)}
                        span
                        useCallbackFunc
                      />
                    </React.Fragment>
                  );
                }
              )}
            </div>

            <div className="w-1/2 mt-0.5">
              {/*RIGHT SECTION 1 */}
              {/* <HrEmpRegion setRegion={() => { }} />
                            <HrEmpHomeTown setHomeTown={() => { }} /> */}

              {RegionSec.map(
                ({
                  id,
                  label,
                  idExpr,
                  dataExpr,
                  optionsData,
                  stateName,
                  defaultValue,
                  style,
                }) => {
                  return (
                    <React.Fragment>
                      <SelectsTemplate
                        label={label}
                        idexpr={idExpr}
                        useCallFunc
                        dataexp={dataExpr}
                        options={optionsData}
                        placeHolder={defaultValue}
                        selectedValue={(e) => {
                          updateState(stateName!, {
                            id: e[idExpr!],
                            value: e[dataExpr!],
                          });
                          if (id === 0)
                            updateEndpointState(
                              "refreshdistricts",
                              !refreshEndpoint.refreshdistricts
                            );
                        }}
                      />
                    </React.Fragment>
                  );
                }
              )}

              {IntercomSec.map(
                ({ id, label, stateName, defaultValue, style }) => {
                  return (
                    <React.Fragment key={id}>
                      <InputsTemplate
                        useCallbackFunc
                        label={label}
                        span
                        defaultValue={defaultValue}
                        additionalWidget={
                          (id === 0 || id === 1) && (
                            <>
                              <CheckboxTemlate
                                withBorders
                                style={{ marginLeft: 3 }}
                              />
                            </>
                          )
                        }
                        orderOnchange={(e) => updateState(stateName, e)}
                      />
                    </React.Fragment>
                  );
                }
              )}

              {/* <HrInputRightSec3 /> */}
            </div>
          </div>

          {/* remarks */}
          {/* <HrEmpRemarks /> */}
          <TextareaTemplate
            useCallbackFunc
            labelCol={4}
            label={"Remarks"}
            height={102.5}
            setCallbackValue={(value) => updateState("empRmks", value)}
            defaultValue={states.empRmks}
          />

          {/* <HrEmpRightCheckBoxes /> */}
          <div className="bg-red-500">
            <CheckboxTemlate
              useCallbackFunc
              labelCol={-4}
              withBorders
              // style={{ marginRight: 8}}
              label={"Staff?"}
              label2={"Active?"}
              inlineRightLabelCol={7}
              inlineDefaultValue={[states.empVisible, states.empActive]}
              index={["isStaff", "isActive?"]}
              setCheckboxOnchange={(e, index) => {
                index === "isStaff"
                  ? updateState("empVisible", e)
                  : updateState("empActive", e);
              }}
            />
          </div>
        </section>
      </Form>
    </div>
  );
};
