import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";

export const useReduxValues = (
  tier: "1" | "2" | "3" | "4" | "tier2checkbox"
) => {
  //states
  const [nameValue, setNameValue] = useState("");
  const [shortNameValue, setShortNameValue] = useState("");
  const [checkClickValue, setcheckClickValue] = useState("");
  const [checkTriggerValue, setcheckTriggerValue] = useState("");
  const [checkUrlValue, setcheckUrlValue] = useState("");

  //tier 1
  const name1 = useSelector((state: RootState) => state.tier1Add.name);
  const shortname1 = useSelector(
    (state: RootState) => state.tier1Add.shortname
  );
  const check = useSelector(
    (state: RootState) => state.tier1Add?.checkExistsOnclick
  );
  const checkTrigger = useSelector(
    (state: RootState) => state?.tier1Add.checkExistsTrigger
  );
  const checkurl = useSelector((state: RootState) => state?.tier1Add.existsUrl);

  //tier 2
  const name2 = useSelector((state: RootState) => state.tier2forms.name);
  const shortname2 = useSelector(
    (state: RootState) => state.tier2forms.shortname
  );
  const check2 = useSelector(
    (state: RootState) => state.tier2forms?.checkExistsOnclick
  );
  const checkTrigger2 = useSelector(
    (state: RootState) => state.tier2forms?.checkExistsTrigger
  );
  const checkurl2 = useSelector(
    (state: RootState) => state.tier2forms?.existsUrl
  );

  //tier 3
  const name3 = useSelector((state: RootState) => state.tier3forms.name);
  const shortname3 = useSelector(
    (state: RootState) => state.tier3forms.shortname
  );
  const check3 = useSelector(
    (state: RootState) => state.tier3forms?.checkExistsOnclick
  );
  const checkTrigger3 = useSelector(
    (state: RootState) => state.tier3forms?.checkExistsTrigger
  );
  const checkurl3 = useSelector(
    (state: RootState) => state.tier3forms?.existsUrl
  );

  //tier 4
  const name4 = useSelector((state: RootState) => state.tier4forms.name);
  const shortname4 = useSelector(
    (state: RootState) => state.tier4forms.shortname
  );
  const check4 = useSelector(
    (state: RootState) => state.tier4forms?.checkExistsOnclick
  );
  const checkTrigger4 = useSelector(
    (state: RootState) => state.tier4forms?.checkExistsTrigger
  );
  const checkurl4 = useSelector(
    (state: RootState) => state.tier4forms?.existsUrl
  );

  //tier 5
  const namegen = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.name
  );
  const shortnamegen = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms.shortname
  );
  const checkgen = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms?.checkExistsOnclick
  );
  const checkTriggergen = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms?.checkExistsTrigger
  );
  const checkurlgen = useSelector(
    (state: RootState) => state.tier2Ckeckboxforms?.existsUrl
  );

  useEffect(() => {
    //not well optimized for performance yet
    setNameValue(
      tier === "1"
        ? name1
        : tier === "2"
        ? name2
        : tier === "3"
        ? name3
        : tier === "4"
        ? name4
        : namegen
    );

    setShortNameValue(
      tier === "1"
        ? shortname1
        : tier === "2"
        ? shortname2
        : tier === "3"
        ? shortname3
        : tier === "4"
        ? shortname4
        : shortnamegen
    );

    setcheckClickValue(
      tier === "1"
        ? check
        : tier === "2"
        ? check2
        : tier === "3"
        ? check3
        : tier === "4"
        ? check4
        : checkgen
    );
    setcheckTriggerValue(
      tier === "1"
        ? checkTrigger
        : tier === "2"
        ? checkTrigger2
        : tier === "3"
        ? checkTrigger3
        : tier === "4"
        ? checkTrigger4
        : checkTriggergen
    );
    setcheckUrlValue(
      tier === "1"
        ? checkurl
        : tier === "2"
        ? checkurl2
        : tier === "3"
        ? checkurl3
        : tier === "4"
        ? checkurl4
        : checkurlgen
    );
  }, [
    name1,
    name2,
    name3,
    name4,
    namegen,
    shortname1,
    shortname2,
    shortname3,
    shortname4,
    shortnamegen,
    check,
    check2,
    check3,
    check4,
    checkgen,
    checkTrigger,
    checkTrigger2,
    checkTrigger3,
    checkTrigger4,
    checkTriggergen,
    checkurl,
    checkurl2,
    checkurl3,
    checkTrigger4,
    checkurlgen,
  ]);

  return {
    name: nameValue,
    shortName: shortNameValue,
    checkTrigger: checkTriggerValue,
    checkOnclick: checkClickValue,
    checkUrl: checkUrlValue,
  };
};
