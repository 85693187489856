/* eslint-disable react/jsx-pascal-case */
import React from "react";
import { TextareaTemplate } from "../../../../../../../templates/textarea";
import { IconsContainer } from "../../../../StationReadings/_widget/stationReadingsToolbar";
import savesmall from "../../../../../../../assets/save-small.png";
import New161 from "../../../../../../../assets/New161.png";
import Datagrid_template from "../../../../../../../templates/Datagrid";
import { HSeperator } from "../../../utils";
import { navbar_height, toolbar_height, tab_bar_height } from "../../../../../../accessories/component_infos";

const Attachments_columns = [
  { id: 1, caption: 'DATE', dataField: 'exnName', dataType: 'string', width: 100, fixed: false, visible: true },
  { id: 2, caption: 'NAME', dataField: 'exnName', dataType: 'string', width: 100, fixed: false, visible: true },
  { id: 3, caption: 'COMMENT', dataField: 'exnName', dataType: 'string', width: 100, fixed: false, visible: true },
];

export default function Comments() {
  const [showGrid, setShowGrid] = React.useState(true);
  const heights_out = navbar_height + toolbar_height + tab_bar_height + 140;
  const form_height = 178;

  return (
    <main>
      {
        showGrid && (
          <section className="w-full h-auto">
            <div className=" px-2">
              <TextareaTemplate disabled={false} height={100} />
            </div>
            <div className=" ">
              <aside className=" flex justify-between items-center">
                <div className="ml-2 ">
                  <p>*</p>
                  <p>*</p>
                </div>
                <IconsContainer url={savesmall} />
              </aside>
              <aside className=" flex justify-between items-center ">
                <div className="ml-2">
                  <p>*</p>
                  <p>*</p>
                </div>
                <aside>
                  <p>*</p>
                  <p>*</p>
                </aside>
                <IconsContainer url={New161} />
              </aside>
            </div>
          </section>
        )
      }
      <HSeperator onClick={() => setShowGrid(!showGrid)} status={showGrid} />
      <Datagrid_template
        columns={Attachments_columns}
        data={[]}
        disablePaging
        gridheight={showGrid ? window.innerHeight - heights_out - form_height : window.innerHeight - heights_out}
        // gridheight={showGrid ? heights_out : 448}
        disableSearch
      />
    </main>
  );
}
