

export const vehicle_condition_TH_1 = [
    {
      id: 0,
      caption: "APPROV. OFFICE",
      dataField: "ownerEmpName",
      alignment: "left",
      fixed: true,
      width: 130,
    },
    {
      id: 1,
      caption: "DRIVER",
      dataField: "driverName",
      alignment: "left",
      fixed: true,
      width: 130
    },
   
    {
      id: 2,
      caption: "MAINT. DATE",
      dataField: "mtsDate",
      alignment: "left",
      dataType : "date",
      fixed: true,
      width: 120,
      format: "EEE, dd MMM yyyy hh:mm:ss",
    },
    {
      id: 3,
      caption: "MAINT. TYPE",
      dataField: "mtpName",
      alignment: "left",
      fixed: true,
      width: 120
  
  
    },
    {
      id: 4,
      caption: "REG. No",
      dataField: "vdtRegNo",
      alignment: "left",
      fixed: true,
      width: 100
  
    },
    {
      id: 5,
      caption: "PD",
      dataField: "mtsPromptDriver",
      alignment: "left",
      fixed: true,
      width: 60
  
    },
    
    {
      id: 6,
      caption: "PAO",
      dataField: "mtsPromptOwner",
      alignment: "left",
      fixed: true,
      width: 60
  
    },
    
    
    {
      id: 7,
      caption: "ACTIVE",
      dataField: "mtsActive",
      alignment: "left",
      fixed: true
  
    },
    
    {
      id: 8,
      caption: "ASSIGNED",
      dataField: "vdtAssigned",
      alignment: "left",
      fixed: true,
      width: 60
  
    },
    {
      id: 9,
      caption: "COUNT",
      dataField: "mtsSmsCount",
      alignment: "left",
      fixed: true,
      width: 80
  
    },
    {
      id: 10,
      caption: "DRIVER STAFF No.",
      dataField: "driverStaffNo",
      alignment: "left",
      fixed: false,
      width: 130
  
    },
    {
      id: 11,
      caption: "DRIVER MOb No.",
      dataField: "driverMobNo",
      alignment: "left",
      fixed: false,
      width: 120
  
    },
    {
      id: 12,
      caption: "APPROVING OFFICER STAFF No",
      dataField: "ownerStaffNo",
      alignment: "left",
      fixed: false,
      width: 100
  
    },
    {
      id: 13,
      caption: "APRV. OFF..MOB.",
      dataField: "ownerMobNo",
      alignment: "left",
      fixed: false,
      width: 120
  
    },
    {
      id: 14,
      caption: "VEHICLE DETAILS...",
      dataField: "vmkName",
      alignment: "left",
      fixed: false,
      width: 120
  
    },
    {
      id: 15,
      caption: "TOWN",
      dataField: "twnName",
      alignment: "left",
      fixed: false,
      width: 100
  
    },
    {
      id: 16,
      caption: "WORKSHOP...",
      dataField: "wsdName",
      alignment: "left",
      fixed: false,
      width: 120
  
    },
    {
      id: 17,
      caption: "DEPT.",
      dataField: "driverDptName",
      alignment: "left",
      fixed: false,
      width: 120
  
    },
    {
      id: 18,
      caption: "SECTION..",
      dataField: "driverSxnName",
      alignment: "left",
      fixed: false,
      width: 120
  
    },
    {
      id: 19,
      caption: "STATUS",
      dataField: "",
      alignment: "left",
      fixed: false,
      width: 120
  
    },
    {
      id: 20,
      caption: "REMARKS",
      dataField: "mtsRmks",
      alignment: "left",
      fixed: false,
      width: 4000
  
    },
 
    
  
  
  ]
  
  
  
  export const vehicle_condition_TH_2 = [
    {
      id: 0,
      caption: "SCHED. DATE",
      dataField: "slgCreationDate",
      alignment: "left",
      fixed: true,
      width: 120,
      dataType : "date", 
      format: "EEE, dd MMM yyyy hh:mm:ss"
    },
    {
      id: 1,
      caption: "DATE SENT",
      dataField: "mtsDate",
      alignment: "left",
      fixed: true,
      width: 120,
      dataType : "date", 
      format: "EEE, dd MMM yyyy hh:mm:ss"
    },
   
    {
      id: 2,
      caption: "REG No.",
      dataField: "vdtRegNo",
      alignment: "left",
      fixed: true,
      width: 100
    },
    {
      id: 3,
      caption: "MAINT. TYPE",
      dataField: "mtpShtName",
      alignment: "left",
      fixed: true,
      width: 120
  
  
    },
    {
      id: 4,
      caption: "DRIVER",
      dataField: "driverName",
      alignment: "left",
      fixed: true,
      width: 120
  
    },
    {
      id: 5,
      caption: "COUNT",
      dataField: "mtsSmsCount",
      alignment: "left",
      fixed: false,
      width: 70
  
    },
    
    {
      id: 6,
      caption: "SMS",
      dataField: "slgMessage",
      alignment: "left",
      fixed: false,
      width: 400
  
    },
    
    
    {
      id: 7,
      caption: "APPROVING OFF..",
      dataField: "ownerEmpName",
      alignment: "left",
      fixed: false,
      width: 130,
  
    },
    
    {
      id: 8,
      caption: "VEHICLE CLASS",
      dataField: "vhcShtName",
      alignment: "left",
      fixed: false,
      width: 130
  
    },
    {
      id: 9,
      caption: "MAKE",
      dataField: "vmkShtName",
      alignment: "left",
      fixed: false,
      width: 120
  
    },
    {
      id: 10,
      caption: "MODEL",
      dataField: "mdlShtName",
      alignment: "left",
      fixed: false,
      width: 120
  
    },
    {
      id: 11,
      caption: "DEPT",
      dataField: "ownerDptShtName",
      alignment: "left",
      fixed: false,
      width: 120
  
    },
    {
      id: 12,
      caption: "SECTION",
      dataField: "ownerSxnShtName",
      alignment: "left",
      fixed: false,
      width: 120
  
    },
    
    {
      id: 13,
      caption: "mtsRmks",
      dataField: "remarks",
      alignment: "left",
      fixed: false,
      width: 4000,
      visible: false,
  
    },
 
    
  
  
  ]

  export const vehicle_condition_data = []