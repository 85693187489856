/* eslint-disable react/jsx-pascal-case */
import { useEffect, useImperativeHandle, useState} from 'react';
import Setups_Template from '../.setups_templates/Setups_Template';
import { checkboxTypes, exprsTypes, modalTypes, searchTextTypes, selectExprsTypes, selectsTypes } from '../data/types/selectsTypes';
import useFetch from '../../../../hooks/useFetch';
import { hr_setups_Town_columns } from './data/HrSetupsTownData';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../app/store';
import { setTier2CheckboxValue, tier2CheckboxSaveOnclick, tier2CheckboxUpdateOnclick } from '../../../../features/forms/tier2checkbox/tier2SliceCheckbox';
import { setGeneralValue } from '../../../../features/generalSlice';
import { ModalTemplate } from '../../../../templates/modal';
import { validationsData, saveModalData, updateModalData, deleteModalData, api_url, postError, putError } from '../../../accessories/component_infos';
import { WarningAlert } from '../../../accessories/warningAlert';
import { useBusyLoader } from '../../../../hooks/useBusyLoader';
import React from 'react';
import { useCrudFunc } from '../../../../functions/crud';
import { TownInterface } from '../../../../interfaces';
import dayjs from 'dayjs';
import { useAccountInfo } from '../../../../hooks/useAccountInfo';

export const Hr_Setups_Towns = React.forwardRef(({a}:any, ref)=>{
    const activeStatus = useSelector((state:RootState)=>state.general.activeStatus);
    const postUrl:string = 'Towns/CreateSftTown';
    const updateUrl:string = 'Towns/UpdateSftTown';

    const [posting, updating] = useCrudFunc();

    //get country data
    const saveSuccessText = {message : 'Town added Successfully',ok : 'Ok' , title : 'Success'} // successful save modal message
    const updateSuccessText = {message : 'Town updated Successfully',ok : 'Ok' , title : 'Success'} // successful update modal message

    const[busyLoader,setBusyLoader] = useBusyLoader();
    
    // store data expressions[unique] ...dependent on data coming in
    const idExpr:String = 'twnIDpk';
    const nameExpr:string = 'twnName'
    const shortnameExpr:string = 'twnShtName'
    const orderExpr: string = 'twnOrder';
    const activeExpr: string = 'twnActive';
    const remarksExpr: string = 'twnRmks';
    const inputsExprsData: any = {idExpr : idExpr,nameExpr : nameExpr,shortnameExpr : shortnameExpr,orderExpr : orderExpr,activeExpr,remarksExpr : remarksExpr }
    const selectsExpr:selectExprsTypes[] = [{idExpr : 'id', nameExpr : 'name',label :'country'},{idExpr : 'id', nameExpr : 'name',label :'region'},{idExpr : 'id', nameExpr : 'name',label :'District'},{idExpr : 'areIdpk', nameExpr : 'areName',label :'area'},{idExpr : 'areIdpk', nameExpr : 'areName',label :'area'}]
    const checkboxExprs: selectExprsTypes[] = [{idExpr : 'RgnCode', nameExpr : 'RgnCode'},{idExpr : 'ctyCapital', nameExpr : 'ctyCapital'},{idExpr : 'areIdpk', nameExpr : 'areName'},{idExpr : 'areIdpk', nameExpr : 'areName'},{idExpr : 'areIdpk', nameExpr : 'areName'}]

    const storeExprs = () =>{
        const exprs:exprsTypes[] = [{expr:'existsUrl',value:`Towns`},{expr: 'idExpr' ,value : idExpr},{expr: 'nameExpr' ,value : nameExpr},{expr: 'shortnameExpr' ,value : shortnameExpr},{expr: 'selectExprs' ,value : selectsExpr[0]}]
        for (let i = 0; i < exprs.length; i++) {            
            dispatch(setTier2CheckboxValue({expr: exprs[i].expr ,value : exprs[i].value}));
        }

        // to general slice
        const generalExprs:exprsTypes[] = [{expr: 'selectExprs' ,value : selectsExpr},{expr: 'inputExprs' ,value : inputsExprsData},{expr: 'checkboxExprs' ,value : checkboxExprs}]
        for (let i = 0; i < generalExprs.length; i++) {            
            dispatch(setGeneralValue({expr: generalExprs[i].expr ,value : generalExprs[i].value}));
        }
    }

    const dispatch = useDispatch();
    const [refresh, setRefresh] = useState<boolean>(false); //refresh data state for data creation or update

    //modal states
    //--general--
    const triggerSuccessModal = useSelector((state:RootState)=>state.tier2Ckeckboxforms.successModalState); //trigger success modal
    const SuccessModalText = useSelector((state:RootState)=>state.tier2Ckeckboxforms.successText); //success modal text

    //--for backend errors--
    const[err,setErr] = useState<{state:boolean,title:string,message:string}>({
        state:false,
        title:'',
        message:''
    });

    //--warning modal--
    const triggerwarningModal = useSelector((state:RootState)=>state.tier2Ckeckboxforms.warningState); //trigger warning
    const warningInfos:any = validationsData; //warning type details
    const warningDataExpr = useSelector((state:RootState)=>state.tier2Ckeckboxforms.warningDataExpr); //warning type selector

    //--save data modal--
    const saveData = saveModalData; //save modal details[data]
    const triggersaveModal = useSelector((state:RootState)=>state.tier2Ckeckboxforms.saveDataOnclick); //trigger save modal
   
    //--Update data modal--
    const updateIndInfos = updateModalData; //update modal details[data]
    const triggerupdateModal:boolean = useSelector((state:RootState)=>state.tier2Ckeckboxforms.updateDataOnclick); //trigger update question

    //--Update data modal--
    const deleteDataInfos = deleteModalData; //update modal details[data]
    const triggerdeleteModal:boolean = useSelector((state:RootState)=>state.tier2Ckeckboxforms.deleteDataOnclick); //trigger update question

    // on page resize height responsiveness
    const [trigger,setTrigger] = useState<boolean>(false);
    useImperativeHandle(ref, () => ({
        test: () => {
            setTrigger(!trigger);
        }
    }));

    // get current values from  [slice dependent on form type]
    const Country = useSelector((state:RootState)=>state.tier2Ckeckboxforms.select0);
    const CountryText = useSelector((state:RootState)=>state.tier2Ckeckboxforms.select0Text);

    const region = useSelector((state:RootState)=>state.tier2Ckeckboxforms.select1);
    const regionText = useSelector((state:RootState)=>state.tier2Ckeckboxforms.select1Text);

    const district = useSelector((state:RootState)=>state.tier2Ckeckboxforms.select2);

    const name = useSelector((state:RootState)=>state.tier2Ckeckboxforms.name);
    const shortname = useSelector((state:RootState)=>state.tier2Ckeckboxforms.shortname);
    const order = useSelector((state:RootState)=>state.tier2Ckeckboxforms.order);
    const active = useSelector((state:RootState)=>state.tier2Ckeckboxforms.active);
    const remarks = useSelector((state:RootState)=>state.tier2Ckeckboxforms.remarks);
    const selectedID = useSelector((state:RootState)=>state.general.SelectedFieldID);

    const dstCap = useSelector((state:RootState)=>state.tier2Ckeckboxforms.checkbox0);
    const rgnCap = useSelector((state:RootState)=>state.tier2Ckeckboxforms.checkbox1);
    const ntlCap = useSelector((state: RootState) => state.tier2Ckeckboxforms.checkbox2);

    
    const [Rowdata, setRowdata] = useState<TownInterface>();
    const [userId] = useAccountInfo();


    // selects validation
    const validateFields=()=>{
        if(!Country){
            setErr({
                state : true,
                title : 'Select Country',
                message: 'Please Select Country'
            })
            return false;
        }
        else if(!region){
            setErr({
                state : true,
                title : 'Select Region',
                message: 'Please Select Region'
            })
            return false;
        }
        else if(!district){
            setErr({
                state : true,
                title : 'Select District',
                message: 'Please Select District'
            })
            return false;
        }

        return true;
    }

    //post form Data
    //validate data
    const validateDataSave = () =>{
        if (validateFields()) {
            dispatch(tier2CheckboxSaveOnclick(''));
        }
    }
    // post
    const postData = async() =>{
        
        dispatch(setTier2CheckboxValue({expr: 'saveDataOnclick' ,value : false})) //disable modal
        try {
            setBusyLoader('Saving record...')
            const res = await posting(`${postUrl}`,{
                "twnName": name,
                "twnShtName": shortname,
                "twnCountryIdfk": Country,
                "twnRegionIdfk": region,
                "twnDistrictIdfk": district,
                "twnLocationIdfk": 1,
                "twnDistrictCapital": dstCap,
                "twnRegionalCapital": rgnCap,
                "twnNationalCapital": ntlCap,
                "twnActive": active,
                "twnRmks": remarks,
                "twnOrder": order
            },
                `
                Created the towns with  twnName:${name}, twnRegionalCapital:${rgnCap}, twnNationalCapital:${dstCap} Date Created:${dayjs().format()}. created By:${userId}
            `)
        
            setRefresh(!refresh);
            setBusyLoader('') //disable busy loader

            const exprs:exprsTypes[] = [{expr: 'saveDataOnclick' ,value : false},{expr: 'successModalState' ,value : true},{expr: 'successText' ,value : saveSuccessText}];
            for (let i = 0; i < exprs.length; i++) {            
                dispatch(setTier2CheckboxValue({expr: exprs[i].expr ,value : exprs[i].value}));
            }

            //disable form
            dispatch(setGeneralValue({expr: 'formDisabled' ,value : true}));

        } catch (error:any) {
            console.log(error);
            setBusyLoader('')

            //set states for db errors modal
            setErr({
                state : true,
                title :postError.title,
                message: postError.message
            })
        }
    }

    //Update Form data
    //validate data
    const validateDataUpdate = () =>{
        if (validateFields()) {
            dispatch(tier2CheckboxUpdateOnclick(''));
        }
    }
    // update
    const UpdateData = async()=>{
        dispatch(setTier2CheckboxValue({expr: 'updateDataOnclick' ,value : false})) //disable modal
        try {
            setBusyLoader('Updating record...')
            const res = await updating(`${updateUrl}`,{
                "twnIdpk": selectedID,
                "twnName": name,
                "twnShtName": shortname,
                "twnCountryIdfk": Rowdata?.rgnIDpk,
                "twnRegionIdfk": region,
                "twnDistrictIdfk": district,
                "twnLocationIdfk": Rowdata?.ctyIDpk,
                "twnDistrictCapital": dstCap,
                "twnRegionalCapital": rgnCap,
                "twnNationalCapital": ntlCap,
                "twnActive": active,
                "twnRmks": remarks,
                "twnEditedBy": userId,
                "twnOrder": order
            },
                `
                Updated the towns with  twnName:${name}, twnRegionalCapital:${rgnCap}, twnNationalCapital:${dstCap} Date Edited:${dayjs().format()}. Edited By:${userId}
                `
            )
            
            // refresh datagrid data
            setRefresh(!refresh);

            setBusyLoader('') //disable busy loader

            //disable update mode
            dispatch(setGeneralValue({expr : 'updateMode', value : false})); // dispatch fetched data to reduxstore
    
            const exprs:exprsTypes[] = [{expr: 'updateDataOnclick' ,value : false},{expr: 'successModalState' ,value : true},{expr: 'successText' ,value : updateSuccessText}];
            for (let i = 0; i < exprs.length; i++) {            
                dispatch(setTier2CheckboxValue({expr: exprs[i].expr ,value : exprs[i].value}));
            }
        } catch (error:any) {
            console.log(error);
            setBusyLoader('') //disable busy loader

            //set states for db errors modal
            //set states for db errors modal
            setErr({
                state : true,
                title : putError.title,
                message: putError.message
            })
        }
    }

    //modals data
    const modals:modalTypes[] = [
        //warning
        {disableCancel : true , icon : 'warning' ,open : triggerwarningModal, okHandler : ()=> dispatch(setTier2CheckboxValue({expr: 'warningState' ,value : false})), cancelHandler : ()=> dispatch(setTier2CheckboxValue({expr: 'warningState' ,value : false})), message : warningInfos[warningDataExpr!].message, cancelText : warningInfos[warningDataExpr!].cancelText, okText : warningInfos[warningDataExpr!].okText, title : warningInfos[warningDataExpr!].title},
        //backend error
        {disableCancel : true , icon : 'warning' ,open : err.state, okHandler : ()=> setErr((prev)=>({...prev,state:false})), cancelHandler : ()=> setErr((prev)=>({...prev,state:false})), message : err.message, cancelText : '', okText : 'Ok', title : err.title},
        //success
        {disableCancel : true, icon : 'success' ,open : triggerSuccessModal, okHandler : ()=> dispatch(setTier2CheckboxValue({expr: 'successModalState' ,value : false})), cancelHandler : ()=> dispatch(setTier2CheckboxValue({expr: 'successModalState' ,value : false})), message :SuccessModalText.message, okText : SuccessModalText.okText, title : SuccessModalText.title},
        //save
        {disableCancel : false , icon : 'question' ,open : triggersaveModal, okHandler : ()=> postData(), cancelHandler : ()=> dispatch(setTier2CheckboxValue({expr: 'saveDataOnclick' ,value : false})), message : saveData.message, cancelText : saveData.cancelText, okText : saveData.okText, title : saveData.title},
        //Update
        {disableCancel : false , icon : 'question' ,open : triggerupdateModal, okHandler : ()=> UpdateData(), cancelHandler : ()=> dispatch(setTier2CheckboxValue({expr: 'updateDataOnclick' ,value : false})), message : updateIndInfos.message, cancelText : updateIndInfos.cancelText, okText : updateIndInfos.okText, title : updateIndInfos.title},
    ]

    const [searchText, setSearchText] = useState<searchTextTypes>({temp:'', text: ''});
    const [status, setStatus] = useState<boolean|string>(''); 

    // data for datagrid
    const fetchUrl:string = `Towns/GetGenAllTowns?active=${status}&search=${searchText.text}`;
    const [data,error,loading] : [TownInterface[], any, boolean] = useFetch(fetchUrl,refresh,'twnIDpk');
    useEffect(()=>{
        if(data){
            storeExprs(); // run func to store expressions on store
            dispatch(setGeneralValue({expr : 'idExpr', value : idExpr})); // dispatch fetched data to reduxstore
        }
       
        loading ? setBusyLoader('.') : setBusyLoader('');
    },[loading,data])


    // form parameters
    const form_header = 'Town Details';

    //selects data
    const[countryData,countryError,countryLoading] = useFetch('Towns/GetCountries');
    const[regionData,regionError,regionLoading] = useFetch(`Towns/GetRegions?country=${CountryText}`);
    const[districtData,districtError,districtLoading] = useFetch(`Towns/GetDistricts?country=${CountryText}&region=${regionText}`);

    // towns data
    const[townsData,townsError,townsLoading] = useFetch(
        (region&&!district)?
        `Towns/GetTownsForRegion?regionId=${region}`
        :
        (region&&district)?
        `Towns/GetTownsForDistrict?districtId=${district}&regionId=${region}`
        :
        ''
    );
    useEffect(()=>{
        dispatch(setGeneralValue({expr: 'CustomDatalistExprs' ,value : {idExpr:'id',dataExpr:'name',sNameExpr:'shortName'}})); // dispatch fetched data to reduxstore

        dispatch(setGeneralValue({expr: 'formData' ,value : townsData})); // dispatch fetched data to reduxstore
    },[townsData])

    const selects:selectsTypes[] = [
        {id: 0, label : 'Country',idExpr:'ctyIDpk',dataExpr:'ctyName', optionsData : countryData,style:{marginBottom:1.25},diffExprs:{idKey:'ctyIDpk',valueKey:'ctyName'}},
        {id: 1, label : 'Region/State',idExpr:'rgnIDpk',dataExpr:'rgnName', optionsData : regionData,style:{marginBottom:1},diffExprs:{idKey:'rgnIDpk',valueKey:'rgnName'}},
        {id: 2, label : 'District/County',idExpr:'dstIDpk',dataExpr:'dstName', optionsData : districtData,style:{marginBottom:0.5},diffExprs:{idKey:'dstIDpk',valueKey:'dstName'}},
    ]

    const checkBoxes:checkboxTypes[] =[
        {id:0,label:'District Capital?'},
        {id:1,label:'Regional Capital?'},
        {id:2,label:'National Capital?'},
    ]

    const optionalnamelabel = 'Town'

    return (
        <>
        {error&&(
            <WarningAlert/>
        )}

        {modals.map(({disableCancel,icon,okHandler,cancelHandler,open,cancelText,okText,message,title}:modalTypes,index)=>{
            return(
                <div key = {index}>
                    <ModalTemplate  icon= {icon && icon} disableCancel = {disableCancel} cancelText = {cancelText && cancelText} open = {open} okHandler={okHandler} cancelHandler={cancelHandler} message= {message} okText= {okText} title={title}/>
                </div>
            )
        })}

        <Setups_Template
        searchTextChange={
            (newText)=> setSearchText({temp:newText, text:searchText.text})
        } 
        handleSearch={() => {
            setSearchText({text:searchText.temp,temp:searchText.temp})
            setStatus(activeStatus)
        }} 
        handleRefresh={()=>{
            setStatus('');
            setRefresh(current=> !current)
            setSearchText({text:'',temp:''})
        }}
        labelCol={6}
        checkboxlist={checkBoxes}
        formTier='tier2checkbox'
        optionalnamelabel={optionalnamelabel}
                datagridClicked={(e) => {
            setRowdata(e.data)
        }}
        createData={validateDataSave}
        updateData = {validateDataUpdate}
        trigger = {trigger}
                datagridColumns={hr_setups_Town_columns}
        
        datagridData = {data}
        selects={selects}
        formHeader={ form_header}/>
        </>
    );
})