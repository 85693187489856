/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/jsx-pascal-case */
import { DatePicker, Form, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useCallback, useEffect, useState } from "react";
import manArrow from "../../../../../../../assets/manArrow.png";
import reset from "../../../../../../../assets/reset.png";
import useFetch from "../../../../../../../hooks/useFetch";
import { SelectsTemplate } from "../../../../../../../templates/select";
import {
  SaveButton,
  NewButton,
  UpdateButton,
  Cancel,
} from "../../../../../../accessories/buttons";
import {
  api_url,
  navbar_height,
  saveModalData,
  toolbar_height,
  updateModalData,
} from "../../../../../../accessories/component_infos";
import {
  card_no_data,
  vehicle_card_TH_col,
  vehicle_Reg_No,
  vfca_cols,
} from "../data/vehicle-card-data";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { RootState } from "../../../../../../../app/store";
import { task_footer_update } from "../../../../../../../features/Task&Todo/Task/TaskDetails";
import { setGeneralValue } from "../../../../../../../features/generalSlice";
import { ModalTemplate } from "../../../../../../../templates/modal";
import axios from "axios";
import { Tooltip } from "devextreme-react";
import { EntryCustomToolbar } from "../../../../../../accessories/custom-toolbars/entryCustomtoolbar";
import Datagrid_template from "../../../../../../../templates/Datagrid";
import { CheckboxTemlate } from "../../../../../../../templates/checkbox";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { WarningAlert } from "../../../../../../accessories/warningAlert";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import useResizeMode from "../../../../../../../hooks/useResizeMode";
import {
  searchCriteriaTypes,
  searchTextTypes,
  statusTypes,
  dropdownGridTemplateTypes,
  datesTypes,
} from "../../../../../../human-resource/setups/data/types/selectsTypes";
import { DropdownGrid_template } from "../../../../../../../templates/DropdownGrid";
import { useCrudFunc } from "../../../../../../../functions/crud";
import InputSelect_template from "../../../../../../../templates/InputSelect";
import { debounce } from "lodash";
import { fetchServiceProviderType } from "../../../../../../../features/apiCalls/vehicleCardAssignApiSlice";

const VehicleCardDetails = () => {
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const heights_out = navbar_height + news_flash_height + toolbar_height;
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const form_height = 300;
  const [showTable, setShowTable] = useState(true);
  let [resizeMode, initialResizeMode] = useResizeMode(!!showTable);
  const pageHeight = resizeMode - (navbar_height + form_height); // form + datagerid

  const tableHeight = initialResizeMode - (heights_out + 10);
  const dateFormat = " DD MMM YYYY";
  const today = dayjs(new Date()).format(dateFormat);
  const initialDate = dayjs()
    .subtract(0, "year")
    .startOf("year")
    .format(dateFormat);
  const [startDate, setStartDate] = useState<searchTextTypes>({
    temp: initialDate,
    text: initialDate,
  });
  const [endDate, setEndDate] = useState<searchTextTypes>({
    temp: today,
    text: today,
  });
  const [searchCheck, setSearchCheck] = useState<statusTypes>({
    status: "",
    temp: "",
  });
  const [searchCriteria, setSearchCriteria] = useState<searchCriteriaTypes>({
    temp: 0,
    index: 0,
  });

  const dispatch = useDispatch();

  // Data from redux
  const form_disable = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const update_state_change = useSelector(
    (state: RootState) => state.addTask.taskFooterUpdate
  );
  const [employeeId, userId, userInfo] = useAccountInfo();
  const initialStates = {
    id: 0,
    sp: { id: 0, name: "" },
    cardNo: { id: 0, name: "" },
    VehNo: { id: 0, name: "" },
    AssignDate: today,
    endDate: today,
    UcardNo: { id: 0, name: "" },
    remarks: "",
    active: true,
    serviceProviderRefresh: false,
    cardNoRefresh: false,
    VehNoRefresh: false,
    isOpenDropdown: false,
    isLoading: false,
  };
  const [PostDataFunc, UpdateDataFunc] = useCrudFunc();
  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });
  const [states, setStates] = useState<any>(initialStates);
  const setState = (key: string, value: any) => {
    setStates((prev: any) => ({ ...prev, [key]: value }));
  };
  const [refreshstate, setRefreshstate] = useState(false);
  const [Uncard, setUncard] = useState<any>([]);

  const [lookups, lookupsErr, lookupsLoading] = useFetch(
    `AssetTamVehicleCardAssignment/GetVehicleFuelCardEntryLookUps?getCardNoIDpk=${states.cardNo.id}&pagenumber=1&pagesize=20&search=a`
  );

  console.log(lookups, "lookups");

  const serviceProvider_data = useSelector(
    (state: RootState) => state.vehicleCardAssign.serviceProviderData
  );

  const [serviceProvider, serviceProvider_error, serviceProvider_loading] =
    useFetch(
      `AssetTamVehicleCardAssignment/GetServiceProviderAssetTamVehicleCardAssignment?pagenumber=1&pagesize=20`,
      states.serviceProviderRefresh
    );
  console.log(serviceProvider, "serviceProvider");
  const [cardNoDB, Card_error, Card_loading] = useFetch(
    !states.sp.id
      ? ""
      : `AssetTamVehicleCardAssignment/GetCardNoAssetTamVehicleCardAssignment?Id=${states.sp.id}`,
    states.cardNoRefresh
  );
  const [vehicle_data, vehicle_error, vehicle_loading] = useFetch(
    `AssetTamVehicleCardAssignment/GetVehicleRegNoAssetTamVehicleCardAssignment`,
    states.VehNoRefresh
  );
  const [Unassigned_data, Unassigned_error, Unassigned_loading] = useFetch(
    `AssetTamVehicleCardAssignment/GetUnAssignedCardAssetTamVehicleCardAssignment`,
    refreshstate
  );
  const [All_data, All_error, All_loading] = useFetch(
    `AssetTamVehicleCardAssignment/SearchAssetTamVehicleCardAssignment?StartDate=${startDate.text}&EndDate=${endDate.text}&isActive=${searchCheck.status}&Search=${searchText.text}&CriteriaIndex=${searchCriteria.index}`,
    refreshstate,
    "vcaIdpk"
  );

  const [selectedRecord, setSelectedRecord] = useState<any>({});

  const Card_data = async () => {
    const res = await axios.get(
      `${api_url}/api/AssetTamVehicleCardAssignment/GetCardNoAssetTamVehicleCardAssignment/${states.sp}`,
      {
        headers: {
          "Content-Type": ["application/json", "text/plain", "charset=utf-8"],
        },
      }
    );
  };

  const clearFields = async () => {
    setSelectedRecord([]);
    setStates(initialStates);
  };

  const dateFields: datesTypes[] = [
    {
      id: 0,
      label: "Assign Date",
      defaultValue: states.AssignDate,
      stateName: "AssignDate",
    },
    {
      id: 0,
      label: "End Date",
      defaultValue: states.endDate,
      stateName: "endDate",
    },
  ];

  const dropDownGrids: dropdownGridTemplateTypes[] = [
    {
      id: 0,
      idExpr: "vdtIdpk",
      defaultValue: states.VehNo.name,
      label: "Vehicle Reg No.",
      columns: vehicle_Reg_No,
      rowClick: (e: any) => {
        setState("VehNo", {
          id: e.vdtIdpk,
          name: e.vdtRegNo,
        });
      },
      handleRefresh: () => {
        setState("VehNo", { id: 0, name: "" });

        setState("VehNoRefresh", !states.VehNoRefresh);
      },
      data: vehicle_data,
    },
    {
      id: 1,
      idExpr: "cdtIdpk",
      defaultValue: states.cardNo.name,
      label: "Card No",
      columns: card_no_data,
      rowClick: (e: any) => {
        setState("cardNo", {
          id: e.cdtIdpk,
          name: e.cdtCardNo,
        });
      },
      handleRefresh: () => {
        setState("cardNo", { id: 0, name: "" });

        setState("cardNoRefresh", !states.cardNoRefresh);
      },
      data: cardNoDB,
    },
  ];

  const validationForms = async () => {
    setIcon("warning");
    setshowModalDisableButton(false);
    if (states.sp.id === 0 || states.sp.id === null) {
      setModalData({
        message: "Please select data entry for Service Provider",
        title: " Service Provider ",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.VehNo.id === 0) {
      setModalData({
        message: "Please enter data for Vehicle Registration Number",
        title: "Vehicle Registration Number",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.AssignDate === null) {
      setModalData({
        message: "Please enter data for Assignment Date",
        title: "Assignment Date",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.endDate === null) {
      setModalData({
        message: "Please enter data for End Date",
        title: "End Date",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.cardNo.id === 0) {
      setModalData({
        message: "Please enter data for Card Number",
        title: "Card Number",
        okText: "Ok",
      });
      setOpenModal(true);
    } else {
      setIcon("question");
      setshowModalDisableButton(true);
      setOpenModal(true);
    }
  };

  const postData = async () => {
    try {
      await PostDataFunc(
        `AssetTamVehicleCardAssignment/CreateAssetVehicleAssignment`,
        {
          vcaVehicleDetailIdfk: states.VehNo.id,
          vcaCardDetailIdfk: states.cardNo.id,
          vcaServiceProviderIdfk: states.sp.id,
          vcaAssignDate: dayjs(states.AssignDate).format(),
          vcaEndDate: dayjs(states.endDate).format(),
          vcaActive: states.active,
          vcaRmks: states.remarks,
          vcaCreatedBy: userInfo.usaIDpk,
        },
        `Created the Asset Mgr-Trans-Vehicle-Card Assignment for Vehicle with Reg No- ${states.VehNo.name} for Card No ${states.cardNo.name} with ID`
      );

      setRefreshstate(!refreshstate);
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: "Record saved successfully!",
        title: "Record Saved!",
        okText: "Ok",
      });

    } catch (e: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: e.message,
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };

  const updateData = async () => {
    try {
      await UpdateDataFunc(
        `AssetTamVehicleCardAssignment/UpdateVehicleCardAssignment/${states.id}`,
        {
          vcaVehicleDetailIdfk: states.VehNo.id,
          vcaCardDetailIdfk: states.cardNo.id,
          vcaServiceProviderIdfk: states.sp.id,
          vcaAssignDate: dayjs(states.AssignDate).format(),
          vcaEndDate: dayjs(states.endDate).format(),
          vcaActive: states.active,
          vcaRmks: states.remarks,
          vcaEditedBy: userInfo.usaIDpk,
        },
        `Edited the Asset Mgr-Trans-Vehicle-Card Assignment for Vehicle with Reg No- ${states.VehNo.name} for Card No ${states.cardNo.name} with ID`
      );

      setRefreshstate(!refreshstate);
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: "Record updated successfully!",
        title: "Record Updated!",
        okText: "Ok",
      });

    } catch (e: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: e.message,
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };
  const disabled = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const updateMode = useSelector(
    (state: RootState) => state.general.updateMode
  );
  const populateForm = (e: any) => {
    setStates({
      id: e?.vcaIdpk,
      sp: { id: e?.spdIdpk, name: e?.spdName },
      cardNo: { id: e?.cdtIdpk, name: e?.cdtCardNo },
      VehNo: { id: e?.vdtIdpk, name: e?.vdtRegNo },
      AssignDate: dayjs(e?.vcaAssignDate),
      endDate: dayjs(e?.vcaEndDate),
      UcardNo: { id: 0, name: "" },
      remarks: e?.vcaRmks,
      active: e?.vcaActive,
    });
    dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
  };
  const allowUpdate = (e: any) => {
    dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
    dispatch(setGeneralValue({ expr: "updateMode", value: true }));
    dispatch(task_footer_update(true));
  };

  const reduxDispatchs = () => {
    dispatch(setGeneralValue({ expr: "formDisabled", value: false })); //disable form
    dispatch(setGeneralValue({ expr: "updateMode", value: false })); //disable update mode
    dispatch(setGeneralValue({ expr: "selectedFieldData", value: [] }));
  };
  useEffect(() => { }, [refreshstate]);

  const [privType, setPrivType] = useState<string>("save");

  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);

  const AccessPriv_on_NewButton = () => {
    setPrivType("save");
    if (savePriv === true) {
      reduxDispatchs();
      clearFields();
    } else {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: "No Access Privilege",
        title: "Access Denied!",
        okText: "Ok",
      });
    }
  };
  const AccessPriv_on_SaveButton = () => {
    if (savePriv === true) {
      setModalData({ ...saveModalData, message: "Are you sure you want to save this record?" });
      setOpenModal(true);
      validationForms();
    } else {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: "No Access Privilege",
        title: "Access Denied!",
        okText: "Ok",
      });
    }
  };
  const AccessPriv_on_UpdateButton = () => {
    if (updatePriv === true) {
      setModalData(updateModalData);
      setOpenModal(true);
      validationForms();
    } else {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: "No Access Privilege",
        title: "Access Denied!",
        okText: "Ok",
      });
    }
  };
  const AccessPriv_on_DatatgridDoubleClick = (e: any) => {
    if (updatePriv === true) {
      allowUpdate(e);
    } else {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: "No Access Privilege",
        title: "Access Denied!",
        okText: "Ok",
      });
    }
  };
  const singleEndPoint: any = fetchServiceProviderType;

  return (
    <>
      {All_error && <WarningAlert />}
      <div>
        <style
          dangerouslySetInnerHTML={{
            __html: `
           
          .dx-datagrid-header-panel .dx-toolbar {
            margin-bottom: 1.5px;
        }

          .filterTodos .dx-datagrid-headers{
              margin-top: 10px;
          }

            `,
          }}
        />
        <EntryCustomToolbar
          searchCriteria={true}
          searchCriteriaData={[
            { id: 0, value: "Reg No" },
            { id: 1, value: "Card Nos" },
            { id: 2, value: "Serv. Providers" },
          ]}
          searchTextOnchange={(val: any) => {
            setSearchText((prev) => ({ ...prev, temp: val }));
          }}
          handleFind={() => {
            setSearchText((prev) => ({ ...prev, text: prev.temp }));
            setSearchCheck((prev) => ({ ...prev, status: prev.temp }));
            setSearchCriteria((prev) => ({ ...prev, index: prev.temp }));
            setStartDate((prev) => ({ ...prev, text: prev.temp }));
            setEndDate((prev) => ({ ...prev, text: prev.temp }));
          }}
          outerSelected={selectedRecord}
          startDate={startDate.temp}
          endDate={endDate.temp}
          startDateChanged={(date) =>
            setStartDate((prev) => ({
              ...prev,
              temp: dayjs(date).format(dateFormat),
            }))
          }
          endDateChanged={(date) =>
            setEndDate((prev) => ({
              ...prev,
              temp: dayjs(date).format(dateFormat),
            }))
          }
          searchCriteriaOnchange={(val) => {
            setSearchCriteria((prev) => ({ ...prev, temp: val }));
          }}
          setIsActive={(checked) => {
            setSearchCheck((prev) => ({ ...prev, temp: checked }));
          }}
          checkOnChange={(val) => {
            setSearchCheck((prev) => ({ ...prev, temp: val }));
          }}
          toggler={() => {
            setShowTable(!showTable);
          }}
          setSwitchToggle={setShowTable}
          handleRefresh={() => {
            setStartDate({ temp: initialDate, text: initialDate });
            setEndDate({ temp: today, text: today });
            setSearchCheck({ temp: "", status: "" });
            setSearchCriteria({ temp: 0, index: 0 });
            setSearchText({ temp: "", text: "" });
            setRefreshstate(!refreshstate);
          }}
          handleNew={() => {
            clearFields();
          }}
        />
        {showTable && (
          <div
            style={{ borderColor: borderTheme }}
            className="w-full border rounded-md"
          >
            <ModalTemplate
              disableCancel={!showModalDisableButton}
              icon={icon}
              cancelText={modalData.cancelText}
              cancelHandler={() => {
                setOpenModal(false);
              }}
              open={openModal}
              okHandler={() => {
                if (modalData.title === "Save Record") postData();
                if (modalData.title === "Update Record") updateData();
                setOpenModal(false);
              }}
              message={modalData.message}
              okText={modalData.okText}
              title={modalData.title}
            />

            <div
              id={"form_header"}
              style={{ borderColor: borderTheme }}
              className="border-b-[1px] border-l-0 border-r-0 border-t-0 text-xs bg-slate-100  py-1 px-2 font-medium"
            >
              Vehicle-Card Assignment Details
            </div>
            <div className="pt-1 flex ">
              <Form
                name="complex-form"
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 20 }}
                size={"small"}
                className="ml-2 flex flex-col"
                style={{ width: "600px" }}
                disabled={form_disable}
              >
                <InputSelect_template
                  disabledCustomWidth={true}
                  cusWidth="307.5px"
                  span={false}
                  useCallFunc={true}
                  placeHolder={states.sp.name}
                  disabled={form_disable}
                  selectedValue={(value: any) => {
                    setState("isOpenDropdown", false);
                    setState("sp", { id: value.spdIdpk, name: value.spdName });
                  }}
                  label="Service Provider "
                  idexpr={"spdIdpk"}
                  dataexp={"spdName"}
                  options={
                    states.sp?.length > 0
                      ? serviceProvider_data
                      : lookups.getServiceProviders
                  }
                  handleRefresh={() => {
                    setState("sp", { id: 0, name: "" });
                    setState(
                      "serviceProviderRefresh",
                      !states.serviceProviderRefresh
                    );
                  }}
                  isLoading={states.isLoading}
                  isOpenDropdown={states.isOpenDropdown}
                  handleSearch={
                    debounce((e) => {
                      setState("sp", e);
                      setState("isLoading", true);
                      dispatch(singleEndPoint(e));

                      setState("isOpenDropdown", true);
                    }, 500) // Adjust the debounce delay as needed
                  }
                  handleFocus={(e: any) => {
                    setState("isOpenDropdown", !states.isOpenDropdown);
                  }}
                />

                {dropDownGrids.map(
                  ({
                    defaultValue,
                    columns,
                    data,
                    idExpr,
                    rowClick,
                    handleRefresh,
                    label,
                  }: dropdownGridTemplateTypes) => {
                    return (
                      <DropdownGrid_template
                        label={label}
                        labelCol={5}
                        KeyExpr={idExpr}
                        cusBtnStyle={true}
                        dropdownBtnStyles={{ width: 307.5 }}
                        defaultValue={defaultValue}
                        columns={columns}
                        gridData={data}
                        onRowClick={rowClick}
                        handleRefresh={handleRefresh}
                        disabled={form_disable}
                      />
                    );
                  }
                )}
                {dateFields.map(
                  ({ label, defaultValue, stateName }: datesTypes) => {
                    return (
                      <Form.Item
                        label={<p className="text-xs">{label}</p>}
                        style={{ marginBottom: 0 }}
                      >
                        <Form.Item
                          name="receivedBy"
                          rules={[{ required: false }]}
                          style={{
                            display: "inline-block",
                            width: "307.5px",
                            marginTop: "2px",
                          }}
                        >
                          <div className="flex flex-row items-center mb-0.5">
                            <DatePicker
                              onChange={(date: any, dateString: any) => {
                                setState(stateName!, dayjs(dateString));
                              }}
                              allowClear={false}
                              size="small"
                              value={dayjs(defaultValue)}
                              style={{ width: "55%" }}
                              format={"ddd, DD MMM YYYY"}
                            />
                          </div>
                        </Form.Item>
                      </Form.Item>
                    );
                  }
                )}
                <Form.Item
                  style={{ display: "inline-block", marginBottom: "2px" }}
                  label={<p className="text-xs">{"Active"}</p>}
                  className="w-full mb-1 "
                >
                  <div className="items-end">
                    <div
                      className="flex pr-2  justify-between"
                      style={{ marginBottom: "1px", width: "calc(72%)" }}
                    >
                      <div className="w-full">
                        <CheckboxTemlate
                          withBorders={true}
                          defaultValue={states.active}
                          useCallbackFunc={true}
                          setCheckboxOnchange={(e) => {
                            setState("active", e);
                          }}
                        />
                      </div>
                      <ul className="flex space-x-1 w-full justify-end">
                        {form_disable === false && updateMode === false ? (
                          <SaveButton
                            handleSave={() => {
                              AccessPriv_on_SaveButton();
                            }}
                          />
                        ) : updateMode === true ? (
                          <UpdateButton
                            handleUpdate={() => {
                              AccessPriv_on_UpdateButton();
                            }}
                          />
                        ) : (
                          <SaveButton
                            handleSave={() => {
                              AccessPriv_on_SaveButton();
                            }}
                          />
                        )}

                        {form_disable === true ? (
                          <NewButton
                            useCallbackFunc={false}
                            new_button_toggler={() => {
                              AccessPriv_on_NewButton();
                            }}
                          />
                        ) : update_state_change === true ? (
                          <Cancel />
                        ) : !(update_state_change && form_disable) ? (
                          <Cancel />
                        ) : (
                          <NewButton
                            useCallbackFunc={false}
                            new_button_toggler={() => {
                              AccessPriv_on_NewButton();
                            }}
                          />
                        )}
                      </ul>
                    </div>
                  </div>
                </Form.Item>
              </Form>

              <style
                dangerouslySetInnerHTML={{
                  __html: `
                    .dx-dropdowneditor-icon {
                        margin-top: 5px;
                    }
                    .dx-dropdowneditor-icon::before {
                        content: "\f001";
                        position: absolute;
                        display: block;
                        width: 18px;
                        top: 50%;
                        margin-top: -5px;
                        left: 50%;
                        margin-left: -9px;
                    }
                    .dx-popup-wrapper {
                        width: 400px;
                    } 
                    .dx-overlay-content.dx-popup-normal.dx-resizable.dx-popup-flex-height{
                        width: 500px;
                        min-width: 500px;
                    } 
                    `,
                }}
              />
              <Form
                name="complex-form"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 22 }}
                size={"small"}
                className=""
                style={{ width: "calc(100% - 510px)" }}
                disabled={form_disable}
              >
                <div className="flex justify-between mb-0.5">
                  <DropdownGrid_template
                    label={"Unassigned Cards"}
                    labelCol={8}
                    defaultValue={Uncard[0]?.cdtCardNo}
                    columns={vfca_cols}
                    gridData={Unassigned_data}
                    disabled={form_disable}
                    cusBtnStyle={true}
                    dropdownBtnStyles={{ width: 200 }}
                    isHide={true}
                    onRowClick={(e) => {
                      setUncard([e]);
                    }}
                  />

                  <span className="w-full flex">
                    <span
                      onClick={() => {
                        clearFields();
                        dispatch(
                          setGeneralValue({
                            expr: "formDisabled",
                            value: false,
                          })
                        ); //disable form

                        setStates({
                          id: 0,
                          sp: {
                            id: Uncard[0]?.spdIdpk,
                            name: Uncard[0]?.spdName,
                          },
                          cardNo: {
                            id: Uncard[0]?.cdtIdpk,
                            name: Uncard[0]?.cdtCardNo,
                          },
                          VehNo: { id: 0, name: "" },
                          AssignDate: null,
                          endDate: null,
                          UcardNo: { id: 0, name: "" },
                          remarks: "",
                          active: false,
                        });
                      }}
                      style={{ borderColor: borderTheme }}
                      className="border-[1px] h-[24px] w-[24px] p-0.5 rounded mr-1"
                    >
                      <img
                        className="w-4 h-full flex items-center justify-center "
                        id="manArrow"
                        src={manArrow}
                        alt="reset"
                      />
                      <Tooltip
                        target="#manArrow"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        hideOnOutsideClick={true}
                      >
                        <p className="h-2">
                          Click to initiatie assignment of card numbers
                        </p>
                      </Tooltip>
                    </span>

                    <span className="w-full ml-1 flex">
                      <span
                        onClick={() => {
                          setUncard([]);
                        }}
                        style={{
                          height: 22.5,
                          borderWidth: 1,
                          width: 24,
                          borderColor: borderTheme,
                        }}
                        className=" flex justify-center hover:cursor-pointer border-gray-300 rounded"
                      >
                        <img
                          className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                          src={reset}
                          alt="reset"
                        />
                      </span>
                      <span className="text-indigo-600 ml-3 flex flex-row ">
                        {`Unassigned Cards: ${Unassigned_data.length}`}
                      </span>
                    </span>
                  </span>
                </div>
                <Form.Item
                  label={<p className="text-xs">{"Remarks"}</p>}
                  style={{ marginBottom: 0, marginTop: "" }}
                >
                  <Form.Item
                    style={{
                      display: "inline-block",
                      marginBottom: "1px",
                      width: "calc(100%)",
                    }}
                    className=" mb-1 mr-2"
                  >
                    <div className="flex  mt-[2px]">
                      <TextArea
                        value={states.remarks}
                        onChange={(e: any) => {
                          setState("remarks", e.target.value);
                        }}
                        style={{
                          resize: "none",
                          maxHeight: "140px",
                          minHeight: "140px",
                          height: "140px",
                        }}
                        className="o mr-2 overflow-y-scroll resize-none"
                        rows={3}
                      />
                    </div>
                  </Form.Item>
                </Form.Item>
              </Form>
            </div>
          </div>
        )}

        <div className="pt-0.5">
          <Datagrid_template
            gridheight={showTable === true ? pageHeight : tableHeight}
            columns={vehicle_card_TH_col}
            data={All_data}
            rowDoubleClicked={AccessPriv_on_DatatgridDoubleClick}
            onRowClick={(e) => {
              dispatch(setGeneralValue({ expr: "updateMode", value: false }));
              setSelectedRecord(e);
              dispatch(task_footer_update(false));
              populateForm(e);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default VehicleCardDetails;
