export const appForms_cols = [
    {id: 0, caption:"ID", dataField:"afmIDpk", dataType:'', fixed:true, visible:true, width:120,},
    {id: 1, caption:"FORM NAME", dataField:"afmName", dataType:"string", fixed:true, visible:true, width:120,},
    {id: 2, caption:"SHORT NAME", dataField:"afmShtName", dataType:"string", fixed:true, visible:true, width:120,},
    {id: 3, caption:"FORM CODE", dataField:"afmCode", dataType:"string", fixed:true, visible:true, width:120,},
    {id: 4, caption:"FORM TYPE", dataField:"afmType", dataType:"string", fixed:true, visible:true, width:120,},
    {id: 5, caption:"MODULE", dataField:"apmName", dataType:"string", fixed:true, visible:true, width:120,},
    {id: 6, caption:"SSF", dataField:"afmSelfService", dataType:"boolean", fixed:false, visible:true, width:120,},
    {id: 7, caption:"ACTIVE?", dataField:"afmActive", dataType:"boolean", fixed:false, visible:true, width:120,},
    {id: 8, caption:"VISIBLE?", dataField:"afmVisible", dataType:"boolean", fixed:false, visible:true, width:120,},
    {id: 9, caption:"DESCRIPTION", dataField:"afmDesc", dataType:"string", fixed:false, visible:true, width:120,},
    {id: 10, caption:"REMARKS", dataField:"afmRmks", dataType:"string", fixed:false, visible:true, width:40000,},
]