/* eslint-disable react/jsx-pascal-case */
import { forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import Setups_Template from '../.setups_templates/Setups_Template';
import useFetch from '../../../../hooks/useFetch';
import { checkboxTypes, datalistTypes, exprsTypes, inputsTypes, modalTypes, searchTextTypes, selectExprsTypes } from '../data/types/selectsTypes';
import {hr_setups_country_columns } from './data/HrSetupsCountryData';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { setTier2CheckboxValue, tier2CheckboxSaveOnclick, tier2CheckboxUpdateOnclick } from '../../../../features/forms/tier2checkbox/tier2SliceCheckbox';
import { setGeneralValue, setFormData } from '../../../../features/generalSlice';
import { validationsData, saveModalData, updateModalData, api_url, putError } from '../../../accessories/component_infos';
import { ModalTemplate } from '../../../../templates/modal';
import { WarningAlert } from '../../../accessories/warningAlert';
import { useBusyLoader } from '../../../../hooks/useBusyLoader';
import { useAccountInfo } from '../../../../hooks/useAccountInfo';
import { useCrudFunc } from '../../../../functions/crud';

export const Hr_Setups_Countries = forwardRef(({ a }: any, ref) => {
  
  const [busyLoader,setBusyLoader]=useBusyLoader();
  const [employeeId, userId] = useAccountInfo();
  const [PostDataFunc, UpdateDataFunc] = useCrudFunc();
  
    const saveSuccessText = {
        message: "Country added Successfully",
        ok: "Ok",
        title: "Save Succesful",
      }; // successful save modal message
      const updateSuccessText = {
        message: "Country updated Successfully",
        ok: "Ok",
        title: "Update Successful",
      }; // successful update modal message
    
      // store data expressions[unique]
      const idExpr: String = "ctyIdpk";
      const nameExpr: string = "ctyName";
      const shortnameExpr: string = "ctyShtName";
      const orderExpr: string = "ctyOrder";
      const nationalityExpr: string = "ctyNationality";
      const activeExpr: string = "ctyActive";
      const remarksExpr: string = "ctyRmks";
      const inputsExprsData: any = {
        idExpr: idExpr,
        nameExpr: nameExpr,
        shortnameExpr: shortnameExpr,
        orderExpr: orderExpr,
        activeExpr,
        remarksExpr: remarksExpr,
      };
      const selectsExpr: selectExprsTypes[] = [
        {
          idExpr: "etpHardwareTypeIdfk", nameExpr: "etpHardwareTypeIdfk",label: "hardwareType",},
        
        { idExpr: "areIdpk", nameExpr: "areName", label: "area" },
        { idExpr: "areIdpk", nameExpr: "areName", label: "area" },
        { idExpr: "areIdpk", nameExpr: "areName", label: "area" },
        { idExpr: "areIdpk", nameExpr: "areName", label: "area" },
      ];
      const checkboxesExpr: selectExprsTypes[] = [
        { idExpr: "ctyDefault", nameExpr: "ctyDefault" },
        { idExpr: "ctyShowInTransport", nameExpr: "ctyShowInTransport"},
        { idExpr: "areIdpk", nameExpr: "areName" },
        { idExpr: "areIdpk", nameExpr: "areName" },
      ];
      const dispatch = useDispatch();
    
      const storeExprs = () => {
        const exprs: exprsTypes[] = [
          {expr:'existsUrl',value:`GenCountries`},
          { expr: "idExpr", value: idExpr },
          { expr: "nameExpr", value: nameExpr },
          { expr: "shortnameExpr", value: shortnameExpr },
          { expr: "selectExprs", value: selectsExpr[0] },
        ];
        for (let i = 0; i < exprs.length; i++) {
          dispatch(
            setTier2CheckboxValue({ expr: exprs[i].expr, value: exprs[i].value })
          );
        }
    
        // to general slice
        const generalExprs: exprsTypes[] = [
          { expr: "selectExprs", value: selectsExpr },
          { expr: "inputExprs", value: inputsExprsData },
          { expr: "checkboxExprs", value: checkboxesExpr },
        ];
        for (let i = 0; i < generalExprs.length; i++) {
          console.log(`${generalExprs[i].expr} ---- ${generalExprs[i].value}`);
          dispatch(
            setGeneralValue({
              expr: generalExprs[i].expr,
              value: generalExprs[i].value,
            })
          );
        }
      };
    
      const [refresh, setRefresh] = useState<boolean>(false); //refresh data state for data creation or update
      //modal states
      //--general--
      const triggerSuccessModal = useSelector(
        (state: RootState) => state.tier2Ckeckboxforms.successModalState
      ); //trigger success modal
      const SuccessModalText = useSelector(
        (state: RootState) => state.tier2Ckeckboxforms.successText
      ); //success modal text
    
      //--for backend errors--
      const [errState, setErrState] = useState<boolean>(false);
      const [errTitle, seterrTitle] = useState<string>("");
      const [errMessage, seterrMessage] = useState<string>("");
    
      //--warning modal--
      const triggerwarningModal = useSelector(
        (state: RootState) => state.tier2Ckeckboxforms.warningState
      ); //trigger warning
      const warningInfos: any = validationsData; //warning type details
      const warningDataExpr = useSelector(
        (state: RootState) => state.tier2Ckeckboxforms.warningDataExpr
      ); //warning type selector
    
      //--save data modal--
      const saveData = saveModalData; //save modal details[data]
      const triggersaveModal = useSelector(
        (state: RootState) => state.tier2Ckeckboxforms.saveDataOnclick
      ); //trigger save modal
    
      //--Update data modal--
      const updateIndInfos = updateModalData; //update modal details[data]
      const triggerupdateModal: boolean = useSelector(
        (state: RootState) => state.tier2Ckeckboxforms.updateDataOnclick
      ); //trigger update question
    
      // on page resize height responsiveness
      const [trigger, setTrigger] = useState<boolean>(false);
      useImperativeHandle(ref, () => ({
        test: () => {
          setTrigger(!trigger);
        },
      }));
    
      // get current values from  [slice dependent on form type]
      const hardwareType = useSelector(
        (state: RootState) => state.tier2Ckeckboxforms.selectValue
      );
      const name = useSelector((state: RootState) => state.tier2Ckeckboxforms.name);
      const shortname = useSelector((state: RootState) => state.tier2Ckeckboxforms.shortname);
      const Nationality = useSelector((state: RootState) => state.tier2Ckeckboxforms.Nationality);
      const order = useSelector((state: RootState) => state.tier2Ckeckboxforms.order);
      const active = useSelector((state: RootState) => state.tier2Ckeckboxforms.active);
      const remarks = useSelector((state: RootState) => state.tier2Ckeckboxforms.remarks);
      const checkbox0 = useSelector((state: RootState) => state.tier2Ckeckboxforms.checkbox0);
      const checkbox1 = useSelector((state: RootState) => state.tier2Ckeckboxforms.checkbox1);
      const checkbox2 = useSelector((state: RootState) => state.tier2Ckeckboxforms.checkbox2);
      const selectedID = useSelector((state: RootState) => state.general.SelectedFieldID);
    
      //post form Data
      //validate data
      const validateDataSave = () => {
        dispatch(tier2CheckboxSaveOnclick('type1'));
      };
    
      // post
      const postData = async () => {
        dispatch(setTier2CheckboxValue({ expr: "saveDataOnclick", value: false }));
    
        try {
          setBusyLoader('Saving record...')
          const res = await PostDataFunc(
            `GenCountries/CreateCountries`,
            {
              ctyName: name,
              ctyShtName: shortname,
              ctyNationality: Nationality,
              ctyDefault: checkbox0,
              ctyShowInTransport: checkbox1,
              ctyOrder:order,
              ctyActive: active,
              ctyRmks: remarks,
              ctyCreatedBy: `${userId}`,
            },`Created Country ${name} successfully`
          );
          setRefresh(!refresh);
          setBusyLoader('')

          const exprs: exprsTypes[] = [
            { expr: "saveDataOnclick", value: false },
            { expr: "successModalState", value: true },
            { expr: "successText", value: saveSuccessText },
          ];
          for (let i = 0; i < exprs.length; i++) {
            dispatch(
              setTier2CheckboxValue({ expr: exprs[i].expr, value: exprs[i].value })
            );
          }
        } catch (error: any) {
          setErrState(true);
          setBusyLoader('')
          if(error.response.status === 500){
          
      
            seterrTitle( 'Server Error');
            seterrMessage( 'Error connecting to server. Please contact system administrator');
        }else{
            //set states for db errors modal
            setErrState(true);
            seterrTitle(error.response.data.message);
            seterrMessage(error.response.data.errors[0]);
        }
          // console.log(error);
          // setBusyLoader('')

          // //set states for db errors modal
          // setErrState(true);
          // seterrTitle(error.response.data.message);
          // seterrMessage(error.response.data.errors[0]);
        }
      };
    
      //Update Form data
      //validate data
      const validateDataUpdate = () => {
        dispatch(tier2CheckboxUpdateOnclick('type1'));
      };
      // update
      const UpdateData = async () => {
        dispatch(
          setTier2CheckboxValue({ expr: "updateDataOnclick", value: false })
        );
    
        try {
          setBusyLoader('Updating record...')
          const res = await UpdateDataFunc(
            `GenCountries/UpdateCountries/${selectedID}`,
            {
              ctyName: name,
              ctyShtName: shortname,
              ctyNationality: Nationality,
              ctyDefault: checkbox0,
              ctyShowInTransport: checkbox1,
              ctyActive: active,
              ctyOrder:order,
              ctyRmks: remarks,
              ctyEditedBy: `${userId}`,
              ctyEditedDate: "2023-01-18T11:49:06.526Z",
            },`Updated Country to ${name} successfully`
          );
          setRefresh(!refresh);
          setBusyLoader('')

        } catch (error: any) {
          setBusyLoader('')
          console.log(error);
          
          //set states for db errors modal
          setErrState(true);
          seterrTitle(putError.title);
          seterrMessage(putError.message);
        }
    
        const exprs: exprsTypes[] = [
          { expr: "updateDataOnclick", value: false },
          { expr: "successModalState", value: true },
          { expr: "successText", value: updateSuccessText },
        ];
        for (let i = 0; i < exprs.length; i++) {
          dispatch(
            setTier2CheckboxValue({ expr: exprs[i].expr, value: exprs[i].value })
          );
        }
      };
    
      //modals data
      const modals: modalTypes[] = [
        //warning
        {
          disableCancel: true,
          icon: "warning",
          open: triggerwarningModal,
          okHandler: () =>
            dispatch(setTier2CheckboxValue({ expr: "warningState", value: false })),
          cancelHandler: () =>
            dispatch(setTier2CheckboxValue({ expr: "warningState", value: false })),
          message: warningInfos[warningDataExpr!].message,
          cancelText: warningInfos[warningDataExpr!].cancelText,
          okText: warningInfos[warningDataExpr!].okText,
          title: warningInfos[warningDataExpr!].title,
        },
        //backend error
        {
          disableCancel: true,
          icon: "warning",
          open: errState,
          okHandler: () => setErrState(false),
          cancelHandler: () => setErrState(false),
          message: errMessage,
          cancelText: "",
          okText: "Ok",
          title: errTitle,
        },
        //success
        {
          disableCancel: true,
          icon: "success",
          open: triggerSuccessModal,
          okHandler: () =>
            dispatch(setTier2CheckboxValue({ expr: "successModalState", value: false })),
          cancelHandler: () =>
            dispatch(setTier2CheckboxValue({ expr: "successModalState", value: false })),
          message: SuccessModalText.message,
          okText: SuccessModalText.okText,
          title: SuccessModalText.title,
        },
        //save
        {
          disableCancel: false,
          icon: "question",
          open: triggersaveModal,
          okHandler: () => postData(),
          cancelHandler: () =>
            dispatch(setTier2CheckboxValue({ expr: "saveDataOnclick", value: false })),
          message: saveData.message,
          cancelText: saveData.cancelText,
          okText: saveData.okText,
          title: saveData.title,
        },
        //Update
        {
          disableCancel: false,
          icon: "question",
          open: triggerupdateModal,
          okHandler: () => UpdateData(),
          cancelHandler: () =>
            dispatch(setTier2CheckboxValue({ expr: "updateDataOnclick", value: false })),
          message: updateIndInfos.message,
          cancelText: updateIndInfos.cancelText,
          okText: updateIndInfos.okText,
          title: updateIndInfos.title,
        },
      ];
    
      const[searchText,setSearchText]=useState<searchTextTypes>({temp:'',text:''});
      const activeStatus = useSelector((state:RootState)=>state.general.activeStatus);
      const[status,setStatus]=useState<boolean|string>('');
      const renderCount = useRef(0);
      // data for datagrid
      const [data, dataError, dataLoading] = useFetch(
        `GenCountries/SearchGenCountry?searchText=${searchText.text}&activeState=${status}`,
        refresh,'ctyIdpk'
      );

      useEffect(()=>{ 
        if (data) { 
          renderCount.current = 1;
          storeExprs(); // run func to store expressions on store
          dispatch(setGeneralValue({ expr: "idExpr", value: idExpr })); // dispatch fetched data to reduxstore
          dispatch(setFormData(data)); // dispatch fetched data to reduxstore      
        }  

        dataLoading ? setBusyLoader('.') : setBusyLoader('')
      },[data,dataLoading])
    
    // form parameters
    const datalists:datalistTypes[] = [
        {id: 0, label : 'Nationality',idExpr : 'ctyIdpk', nameExpr : 'ctyNationality', optionsData : data},
    ]
    const form_header = 'Country Details';
    // const optionalnamelabel = 'Nationality'

    const checboxlist: checkboxTypes[] = [
      { id: 0, label: "Default?" },
      { id: 1, label: "Show in TMS?" },
    ];

    return (
        <>
          {dataError&&(
            <WarningAlert/>
          )}

          {modals.map(({disableCancel,icon,okHandler,cancelHandler,open,cancelText,okText,message,title}:modalTypes,index)=>{
              return(
              <div key = {index}> 
                <ModalTemplate 
                icon= {icon && icon}
                 disableCancel = {disableCancel} 
                 cancelText = {cancelText && cancelText} 
                 open = {open} okHandler={okHandler} 
                 cancelHandler={cancelHandler} 
                 message= {message} 
                 okText= {okText} 
                 title={title}/>
              </div>
              )
          })}

          <Setups_Template
          formTier="tier2checkbox"
          checkboxlist={checboxlist}
          labelCol={5}
          searchTextChange={(txt)=>{setSearchText({temp:txt,text:searchText.text})}}
          handleSearch={()=>{setSearchText({temp:searchText.temp,text:searchText.temp});
            setStatus(activeStatus);
          }}
          trigger={trigger}
          datagridColumns={hr_setups_country_columns}
          datagridData={data}
          formHeader={form_header}
          updateData = {validateDataUpdate}
          datalistsAfterConstants = {datalists}
          createData = {validateDataSave}
          />
    </>
    );
})