
import { useEffect, useRef, useState } from 'react';
// import type { SelectProps } from 'antd';
import { CIA_toolbar } from '../../../accessories/custom-toolbars/cia_toolbar';
import { department_initiative_assignement_footer_datagrid_cols } from './data/datagrid_cols';
import { DIA_Details_Form } from './widget/DIA_details_form';
import { navbar_height } from '../../../accessories/component_infos';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { task_footer_table_selected, task_form_disable, task_footer_update } from '../../../../features/Task&Todo/Task/TaskDetails';
import { setGeneralValue } from '../../../../features/generalSlice';
import { useBusyLoader } from '../../../../hooks/useBusyLoader';
import useFetch from '../../../../hooks/useFetch';
import  Datagrid_template from '../../../../templates/Datagrid';
import { TextareaTemplate } from '../../../../templates/textarea';
import { searchTextTypes, statusTypes, searchCriteriaTypes } from '../../../human-resource/setups/data/types/selectsTypes';
import { Form } from 'antd';
import useResizeMode from '../../../../hooks/useResizeMode';





interface props {
    state_change: boolean;
}



export const DIA = ({ state_change }: props) => {
    const news_flash_height = useSelector((state: RootState) => state.general.newsflashHeight);
    // const width = window.innerWidth/2
    // const fixedWidth = 'calc(50% - 8px)';
    const [formheight, set_form_height] = useState<number>(0);
    const [switch_change, setSwitch_change] = useState<boolean>(true)

    const [initialH, finalH] = useResizeMode(switch_change);

    const dispatch = useDispatch()
    const form_disable = useSelector((state: RootState) => state.general.formDisabled)

    const formRef = useRef<any>(null);
    var fullPagefinalH = finalH - (navbar_height + news_flash_height + 30);
    var pageHeight = initialH - (navbar_height + news_flash_height + formheight + 20);
    // const fullPage = window.innerHeight - (navbar_height + 45) ; //height without navbar
    const borderTheme = useSelector((state:RootState)=>state.general.cssBorderClass)

    // switch toggle
    const switch_toggle = () => {
        if (switch_change) {
            setSwitch_change(!switch_change);

        } else {
            setSwitch_change(!switch_change);

        }
    }


    useEffect(() => {

        set_form_height((formRef.current?.clientHeight) + 20)
        console.log(formRef)
    }, [])

    const [searchText, setSearchText] = useState<searchTextTypes>({ temp: '', text: '' });
    const [activeStatus, setActiveStatus] = useState<statusTypes>({ temp: '', status: '' });
    const [searchCriteria, setsearhCriteria] = useState<searchCriteriaTypes>({ temp: 0, index: 0 });

    const [busyLoader, setBusyLoader] = useBusyLoader();
    const refresh = useSelector((state: RootState) => state.general.refresh)
    const [refreshToolbar, setrefreshToolbar] = useState<boolean>(false);

    // const [data, error, loading] = useFetch(`PfmDepartmentInitiatives/GetDeptInitiativePopGdv?criteria=${searchCriteria.index}&searchText=${searchText.text}&isActive=${activeStatus.status}`, refresh, 'dinIdpk')
    const [data, error, loading] = useFetch(`PfmDepartmentInitiatives/GetAll?search=${searchText.text}&active=${activeStatus.status}&criteria=${searchCriteria.index}`, refresh, 'dinIdpk')
    console.log(`data: ${data}`);
    useEffect(() => {
        loading ? setBusyLoader('.') : setBusyLoader('');
    }, [loading])

    const [selectedData, setSelectedData] = useState({});
    const [remarks, setRemarks] = useState<string>("")

    const searchCriteriaData = [
        { id: 0, value: 'Department' },
        { id: 1, value: 'Department Initiative' },
        { id: 2, value: 'Corporate Initiative' },
        { id: 3, value: 'Objective' },
        { id: 4, value: 'Theme' },
        { id: 5, value: 'Mission/Vision' },
    ]


    return (
        <div className='h-full dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base '>
            {/* toggles between the taskForm and the footer grid table vertically */}

            <div className='px-2'>
                <CIA_toolbar
                    toggler={switch_toggle}
                    searchTextOnchange={(searchValue) => {
                        setSearchText({ temp: searchValue, text: searchText.text })
                    }}
                    checkOnChange={(check) => {
                        setActiveStatus({ temp: check, status: activeStatus.status })
                    }}
                    handleRefresh={() => {
                        setrefreshToolbar(current => !current)
                    }}
                    handleFind={() => {
                        setSearchText({ temp: searchText.temp, text: searchText.temp }) //search text
                        setActiveStatus({ temp: activeStatus.temp, status: activeStatus.temp }) //active status
                        setsearhCriteria({ temp: searchCriteria.temp, index: searchCriteria.temp })
                    }}
                    searchCriteriaOnchange={(newIndex, criteria) => {
                        setsearhCriteria({ temp: newIndex, index: searchCriteria.index })
                    }}
                    withDates={false}
                    searchCriteria={true}
                    searchCriteriaData={searchCriteriaData}
                    outerSelected={selectedData}
                    setOuterSelected={setSelectedData}
                    handleNew={() => {
                        setSelectedData({})
                    }}
                />
            </div>

            {switch_change && <div ref={formRef} className='border-b-4 mb-2 pb-1 px-2'>
                <p  id={"form_header"} style={{borderColor:borderTheme}} className='pt-1 border-[1px] border-b-0  pl-4 w-full  rounded-tl'>Department Initiative Assignement Details</p>
                <div style={{ height: '205px',borderColor:borderTheme }} className="w-full flex justify-between gap-x-4 border-[1px] ">

                    {/* Internal toggler : expands the right taskUpdate horizontally */}

                    <div style={{ width: '700px', height: '200px' }} className='' >
                        <DIA_Details_Form
                            selectedRecordx={selectedData}
                            setSelectedRecordx={setSelectedData}
                            remarksx={remarks}
                            setRemarksx={setRemarks}
                        />
                    </div>


                    {/* Double tap(click) feature to toggle to and fro of the right taskUpdate section */}
                    <div style={{ width: '100%', height: '100%' }} className=" w-full mt-2  rounded-md items-center dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base " >
                        <Form>
                            <TextareaTemplate
                                height={190}
                                label={'Remarks'}
                                useCallbackFunc
                                defaultValue={remarks}
                                readonly={form_disable}
                                setCallbackValue={(e) => { setRemarks(e) }}
                            />
                        </Form>
                    </div>


                </div>
            </div>
            }


            {/* Footer gridtable  */}
            <div style={{ height: '' }} className='px-2  dark:bg-slate-900 dark:text-darkModeSkin-base'>
                <Datagrid_template
                    gridheight={switch_change ? pageHeight : fullPagefinalH}
                    columns={department_initiative_assignement_footer_datagrid_cols}
                    data={data}
                    rowDoubleClicked={(e) => {
                        dispatch(task_footer_table_selected(e))
                        dispatch(task_form_disable(false));
                        dispatch(task_footer_update(true))
                        dispatch(setGeneralValue({ expr: "formDisabled", value: false }))
                        dispatch(setGeneralValue({ expr: "updateMode", value: true }))

                        setSelectedData(e)
                    }}
                    onRowClick={(e) => {
                        dispatch(task_footer_table_selected(e))
                        dispatch(task_form_disable(true));
                        dispatch(task_footer_update(false))
                        dispatch(setGeneralValue({ expr: "formDisabled", value: true }))
                        dispatch(setGeneralValue({ expr: "updateMode", value: false }))
                        setSelectedData(e)
                    }}
                />

                {/* <Footer_datagrid_template 
                    gridheight={switch_change ? pageHeight : fullPage} 
                    columns={department_initiative_assignement_footer_datagrid_cols} 
                    data={data} 
                    callBack={(e:any)=>{setSelectedData(e)}}
                /> */}
            </div>

        </div>

    )
}





// const reload = ()=>{
//     return(
//         <img className='w-2/6' src={save} />
//     )
// }