/* eslint-disable react/jsx-pascal-case */
import { forwardRef, useState } from "react";
import { ElogBookMainFormV1, ElogBookMainFormV2 } from "./widgets/ElogBookMainForm";
import Datagrid_template from "../../../templates/Datagrid";
import { elogbook_columns } from "./data/EloggerDatagridData";
import {
  navbar_height,
  tab_bar_height,
  toolbar_height,
} from "../../accessories/component_infos";
import { EloggerCustomToolbar } from "../../accessories/custom-toolbars/elogger_toolbar";
import MidnightConditions from "./widgets/midnight_conditions";
import { Update_Shift_Members_Modal } from "./widgets/Update_Shift_Members";
import Remote_Shift_Change from "./widgets/Remote_Shift_Change";
import { Takeover_Shift_Members_Modal } from "./widgets/TakeOverShiftMembers";
import { ModalTemplate } from "../../../templates/modal";
import { StationDetails } from "./StationDetails/StationDetails";
import { SafetySetupEventTypes } from "../../Assets-Manager/Transport/setups/general/event-types/eventTypes";
import { Directories } from "./widgets/subsections/Directories/Directories";
import { Others } from "./widgets/subsections/Other/Others";
import { HSeperator, VSeperator } from "./widgets/utils";
import useFetch from "../../../hooks/useFetch";

export const Safety_Elogger = forwardRef(({ a }: any, ref) => {
  // All heights out
  const heights_out = navbar_height + toolbar_height + tab_bar_height + 50;

  //varying form height
  const form_height = 270;

  // Modal States
  const [showMidnightConditions, setShowMidnightConditions] = useState(false);
  const [showRemoteShift, setShowRemoteShift] = useState(false);
  const [showShiftTakeover, setShowShiftTakeover] = useState(false);
  const [showShiftMembers, setShowShiftMembers] = useState(false);
  const [showEventTypes, setShowEventTypes] = useState(false);
  const [showStationDetails, setShowStationDetails] = useState(false);

  const [showGrid, setShowGrid] = useState(true);
  const [showDirectories, setShowDirectories] = useState(false);
  const [showOthers, setShowOthers] = useState(false);
  const [showMiniForm, setshowMiniForm] = useState(false);

  const [ElogData] = useFetch(`ELogBook/getAllLogBook`, false);

  return (
    <>
      {/* Modals */}
      <MidnightConditions
        open={showMidnightConditions}
        handleCancel={() => {
          setShowMidnightConditions(false);
        }}
      />

      {/* <Update_Shift_Members_Modal
        open={showShiftMembers}
        handleCancel={() => {
          setShowShiftMembers(false);
        }}
      /> */}

      <Remote_Shift_Change
        open={showRemoteShift}
        handleCancel={() => {
          setShowRemoteShift(false);
        }}
      />
{/* 
      <Takeover_Shift_Members_Modal
        open={showShiftTakeover}
        handleCancel={() => {
          setShowShiftTakeover(false);
        }}
      /> */}

      <ModalTemplate
        disableCancel
        width={"90%"}
        customComponent={<SafetySetupEventTypes />}
        title={"eLog Book - Event Types"}
        message={""}
        cancelHandler={() => setShowEventTypes(false)}
        open={showEventTypes}
        okHandler={() => {}}
      />

      <ModalTemplate
        disableCancel
        width={"95%"}
        customComponent={<StationDetails />}
        title={"eLog Book - Station Details"}
        message={""}
        cancelHandler={() => setShowStationDetails(false)}
        open={showStationDetails}
        okHandler={() => {}}
      />

      {/* <ModalTemplate
        disableCancel
        width={"90%"}
        customComponent={<UpdateShiftMembersGrid />} 
        title={"eLog Book - Update Shift Members"} 
        message={""} 
        cancelHandler={()=> setShowShiftMembers(false)}
        open={showShiftMembers} okHandler={function (): void {
        throw new Error("Function not implemented.");
      } }/> */}

      {/* <StationDetails state_change={true}/> */}
      <main className="">
        {/* e Log book custom tab */}
        <section>
          <EloggerCustomToolbar
            updateShiftMembersClick={() => setShowShiftMembers(true)}
            midnightConditionsClick={() => setShowMidnightConditions(true)}
            updateTemplatesClick={() => setShowMidnightConditions(true)}
            remoteShiftChangeClick={() => setShowRemoteShift(true)}
            eventTypesClick={() => setShowEventTypes(true)}
            shiftTakeoverClick={() => setShowShiftTakeover(true)}
            stationDetailsClick={() => setShowStationDetails(true)}
          />
        </section>

        {/* form */}
        <section className="flex">
          <aside className="w-[80%] flex-1">
            {/* <aside className="w-[80%]"> */}
            <div className="w-full">
              <ElogBookMainFormV2 open={showGrid} showMiniForm={showMiniForm} />
            </div>
            <HSeperator
              onClick={() => setShowGrid(!showGrid)}
              status={!showGrid}
            />
            {/* datagrid */}
            {showGrid && (
              <section className="w-full h-auto flex">
                {showDirectories && (
                  <aside className="w-[40%]">
                    <Directories
                      height={
                        showGrid
                          ? showMiniForm
                            ? 210
                            : window.innerHeight -
                              heights_out -
                              (form_height + 60)
                          : window.innerHeight - heights_out
                      }
                    />
                  </aside>
                )}
                <VSeperator
                  height={100}
                  onClick={() => setShowDirectories(!showDirectories)}
                  status={!showDirectories}
                />
                <aside className="w-[59%] h-auto flex-grow">
                  <Datagrid_template
                    columns={elogbook_columns}
                    data={[]}
                    disablePaging
                    gridheight={
                      showGrid
                        ? showMiniForm
                          ? 270
                          : window.innerHeight - heights_out - form_height
                        : window.innerHeight - heights_out
                    }
                    // gridheight={showGrid ? window.innerHeight - heights_out - form_height : window.innerHeight - heights_out}
                    className={"w-full"}
                  />
                </aside>
              </section>
            )}
          </aside>
          <VSeperator
            height={100}
            status={showOthers}
            onClick={() => setShowOthers(!showOthers)}
          />
          {showOthers && (
            <aside className="w-[20%] h-auto">
              <Others />
            </aside>
          )}
        </section>
        <section className="flex justify-between items-center">
          <div className="w-full flex items-center py-1">
            <p>Colour Legend- </p>
            <p className="bg-yellow-200 py-0.5 px-2">Flagged</p>
          </div>
          <section className="w-80 py-2 font-medium flex justify-between">
            <p>10 mins</p>
            <p>2209138 mins</p>
            <p>34887 mins</p>
          </section>
        </section>
      </main>
    </>
  );
});
