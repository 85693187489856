import { Checkbox, DatePicker, Form, Input, message, Select } from "antd";
import reset from '../../../../../assets/reset.png';
import TextArea from "antd/es/input/TextArea";
import { BottomBtns } from "../../../../../templates/butttons";
import { InputsTemplate } from "../../../../../templates/input";
import { SelectsTemplate } from "../../../../../templates/select";
import { TextareaTemplate } from "../../../../../templates/textarea";
import { modalPropTypes, modalTypes, selectObjectTypes, selectsTypes, validateInstanceTypes } from "../../../../human-resource/setups/data/types/selectsTypes";
import useFetch from "../../../../../hooks/useFetch";
import { useEffect, useState } from "react";
import axios from "axios";
import { api_url, saveModalData, updateModalData } from "../../../../accessories/component_infos";
import { ModalTemplate } from "../../../../../templates/modal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import { setGeneralValue } from "../../../../../features/generalSlice";
import { UpdateDataFunc } from "../../../../../functions/update";
import { PostDataFunc  } from "../../../../../functions/post";
import { useAccountInfo } from "../../../../../hooks/useAccountInfo";
interface props{
    busyloader:(text?:string)=>void,
    refreshGrid:()=>void
    clearSelected:()=>void
    selectedField:any
}

interface stateTypes{
    period :selectObjectTypes;
    perspective:selectObjectTypes;
    measure:selectObjectTypes;
    target:selectObjectTypes;
    order:number,
    remarks:string;
    active:boolean;
    selectedRecordId : number;
    busyLoader : string
    confirmModal:modalPropTypes
    warningModal:modalPropTypes
    successModal:modalPropTypes
}


export const Corporate_measures_form = ({busyloader,refreshGrid,clearSelected,selectedField}:props) => {
    const dispatch = useDispatch();

    const borderTheme = useSelector((state:RootState)=>state.general.cssBorderClass)


    useEffect(()=>{        
        populateStatesFromGrid(false);
    },[selectedField])

    const[employeeId, userId] = useAccountInfo();

    const[refresh,setRefresh]=useState<boolean>(true)  
    
    const[periodsData,periodsError,periodsLoading] = useFetch('Periods/GetAllActivePeriods',refresh);
    const[perspectiveData,perspectiveData_Error,perspectiveData_Loading] = useFetch(`PfmPerspective/GetActivePfmPerspective?fewColn=true`,refresh);
    const[measureData,measure_Error,measure_loading]=useFetch(`StpMeasures/GetAllActiveMeasures`,refresh);
    const[targetData,target_Error,target_loading]=useFetch(`Targets/GetAllActiveTargets`,refresh);

    const [states, setStates]=useState<stateTypes>({
        period : {id:0,value:''},
        perspective : {id:0,value:''},
        measure : {id:0,value:''},
        target :{id:0,value:''},
        remarks : '',
        order:0,
        active : false,
        selectedRecordId : 0,
        busyLoader : '',
        confirmModal:{state:false,title:'',message:'',action:'post'},
        warningModal:{state:false,title:'',message:''},
        successModal:{state:false,title:'',message:''}
        
    })
    const{
        period,
        perspective,
        measure,
        target,
        order,
        remarks,
        active,
        successModal,
        warningModal,
        confirmModal}=states;

    const updateState=(stateName:string,value:string|modalPropTypes|boolean|number|selectObjectTypes):void=>{ //update single state
        setStates((prev)=>({...prev, [stateName]:value}))
    }
    
    const selects: selectsTypes[] = [
        {id: 0 , label : 'Period' ,optionsData : periodsData,idExpr : 'prdIdpk' , dataExpr : 'prdName',stateName:"period",defaultValue:period.value},
        {id: 1 , label : 'Perspective' ,optionsData : perspectiveData,idExpr : 'ptvIdpk' , dataExpr : 'ptvName',stateName:"perspective",defaultValue:perspective.value},
        {id: 2 , label : 'Measure' ,optionsData : measureData,idExpr : 'msrIdpk' , dataExpr : 'msrName',stateName:"measure",defaultValue:measure.value},
        {id: 3 , label : 'Target' ,optionsData : targetData,idExpr : 'tgtIdpk' , dataExpr : 'tgtName',stateName:"target",defaultValue:target.value},
        

    ]
    const disableFields=()=>{
        //disable Update Mode
        dispatch(setGeneralValue({expr: 'updateMode' ,value : false}));
    
        //disable forms
        dispatch(setGeneralValue({expr: 'formDisabled' ,value : true}));
    }

    const populateStatesFromGrid=(clearFields?:boolean)=>{
        const run=()=>{
            const populateInstances=[
                {state:'period',value:clearFields?{id:'',value:''}:{id:selectedField?.prdIDpk,value:selectedField?.prdName}},
                {state:'perspective',value:clearFields?{id:'',value:''}:{id:selectedField?.ptvIDpk,value:selectedField?.ptvName}},
                {state:'measure',value:clearFields?{id:'',value:''}:{id:selectedField?.msrIDpk,value:selectedField?.msrName}},
                {state:'target',value:clearFields?{id:'',value:''}:{id:selectedField?.tgtIDpk,value:selectedField?.tgtName}},
                {state:'order',value:clearFields?0:selectedField?.cmsOrder},
                {state:'active',value:clearFields?false:selectedField?.cmsActive},
                {state:'remarks',value:clearFields?'':selectedField?.cmsRmks},
                {state:'selectedRecordId',value:clearFields?0:selectedField?.cmsIDpk}
            ]
    
            for(let instance of populateInstances) updateState(instance.state,instance.value);
        }
        run();
        
    }


    console.log(states)
 //validate fields
 const validateFields=():boolean=>{
    const validationInstances:validateInstanceTypes[]=[
        {state:period?.value,baseValue:'',modalProps:{state:true,title:'Select period',message:'Please select period'}},
        {state:perspective.value,baseValue:'',modalProps:{state:true,title:'Select perspective',message:'Please select perspective'}},
        {state:measure.value,baseValue:'',modalProps:{state:true,title:'Select measure',message:'Please select measure'}},
        {state:target.value,baseValue:'',modalProps:{state:true,title:'Select perspective',message:'Please select target'}},
        // {state:weight,baseValue:0,modalProps:{state:true,title:'Enter % weight',message:'Please enter % weight'}},
    ]

    for(let instance of validationInstances) if (instance.state===instance.baseValue) {
        const{modalProps}=instance;
        updateState('warningModal',{
            state:modalProps.state,
            title:modalProps.title,
            message:modalProps.message
        })
            return false;
        }
        return true;
    }

        
        //post api
        const postData = async(action:'confirm'|'post')=>{
            console.log('post data called')
            if (action === 'confirm') {
                updateState('confirmModal',{
                    state:true,
                    title:'Save Record',
                    message:'Are you sure you want to save this record?',
                    func() {
                        console.log('post data event called')
                        postData('post')
                    },
                })
                return;
            }

            updateState('confirmModal',{
                state:false,
                title:'Save Record?',
                message:'Are you sure you want to save this record?',
            })

            try {
                busyloader(saveModalData.progress);

                const res = await PostDataFunc (`CorporateMeasures/CreateCorporateMeasures`,{
                    "cmsPeriodIdfk": period.id,
                    "cmsPerspectiveIdfk": perspective.id,
                    "cmsMeasureIdfk": measure.id,
                    "cmsTargetIdfk": target.id,
                    "cmsActive": active,
                    "cmsOrder":order,
                    "cmsRmks": remarks,
                    "cmsCreatedBy": userId.toString()
                })

                disableFields();
                refreshGrid();
                clearSelected();
                //Clear fields when done.
                populateStatesFromGrid(true);
                updateState('successModal',{
                    state: true,
                    title: "Record Saved",
                    message: "Corporate Measure added successuflly"
                })
            } catch (error) {
                console.log(error);
                
                updateState('warningModal',{
                    state: true,
                    title: saveModalData.error.title,
                    message:saveModalData.error.message
                })
            } finally{
                busyloader('');
            }
            return;
        }

    //update data
    const update=async(action:'validate'|'update')=>{
        const fetchData = async()=>{
            disableConfirmModal();
            busyloader(updateModalData.progress)

            try {
                const updateResponse = await UpdateDataFunc(`CorporateMeasures/UpdateCorporateMeasures/${states.selectedRecordId}`,{  
                    "cmsPeriodIdfk": period.id,
                    "cmsPerspectiveIdfk": perspective.id,
                    "cmsMeasureIdfk": measure.id,
                    "cmsTargetIdfk": target.id,
                    "cmsOrder":order,
                    "cmsActive": active,
                    "cmsRmks": remarks,
                    "cmsEditedBy": userId
                })
                enableSuccessModal('Update','updated')
                disableFields();
                refreshGrid();
                clearSelected();
                //Maintain updated fields
                populateStatesFromGrid(false);
            } catch (error) {
                console.log(error);

                updateState('warningModal',{
                    state: true,
                    title: updateModalData.error.title,
                    message: updateModalData.error.message
                })
            } finally{
                busyloader('');
            }
        }

        if (action==='validate') {
            // validate fieldds
            validateFields()&&
            updateState('confirmModal',{
                state:true,
                title:'Update Record',
                message:'Are you sure you want to update this record?',
                action:'update',
                func() {
                    update('update')
                },
            })
            return;
        }
        else{
            fetchData();
        }
        return;
    }

    
    const enableSuccessModal=(titleActionText:string,messageActionText:string)=>{
        updateState('successModal',{
            state:true,
            title:`${titleActionText} Successful`,
            message:`Record ${messageActionText} successfully`,
        })
    }

    const disableConfirmModal=()=>{
        updateState('confirmModal',{
            state:false,
            title:'',
            message:'',
            action:'post'
        })
    }
    // modal instances
    const modals:modalTypes[] = [
        //validation
        {disableCancel : true , icon : 'warning' ,open : warningModal.state, okHandler : ()=>updateState('warningModal',{state:false,title:'',message:''}), cancelHandler : ()=> updateState('warningModal',{state:false,title:'string',message:'string'}), message : warningModal.message, okText : 'Ok', title : warningModal.title,cancelText : 'No, cancel'},
        
        //confirmation
        {disableCancel : false , icon : 'question' ,open : confirmModal.state, okHandler : confirmModal.func!, cancelHandler : ()=> updateState('confirmModal',{state:false,title:'string',message:'string'}), message : states.confirmModal.message, okText : confirmModal.action==='update' ? 'Yes, Update':'Yes, Save', title : states.confirmModal.title,cancelText : 'No, cancel'},

        //success
        {disableCancel : true , icon : 'success' ,open : successModal.state, okHandler : ()=>updateState('successModal',{state:false,title:'',message:''}), cancelHandler : ()=> updateState('successModal',{state:false,title:'string',message:'string'}), message : successModal.message, okText : 'Ok', title : successModal.title,cancelText : 'No, cancel'},
    ]

                
    return (
        <>
        {modals.map(({disableCancel,icon,okHandler,cancelHandler,open,cancelText,okText,message,title}:modalTypes,index)=>{
            return(
                <div key = {index}>
                    <ModalTemplate  
                    icon= {icon && icon} 
                    disableCancel = {disableCancel} 
                    cancelText = {cancelText && cancelText}
                    open = {open} 
                    okHandler={okHandler} 
                    cancelHandler={cancelHandler}
                    message= {message}
                    okText= {okText} title={title}/>
                </div>
            )
        })}

        <div className="px-2">
            {/* FORM HEADER */}
            <p id={"form_header"} style={{borderColor:borderTheme}} className='pt-1 px-2 w-full border-[1px] border-b-0 bg-slate-100 font-semibold'>{'Corporate Measure Details'}</p>
            <Form
                style={{borderColor: borderTheme}}
                className='w-full border-[1px] border-slate-100 rounded-b flex justify-between py-1 px-2 '
                name="basic"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 19 }}
                initialValues={{ remember: true }}
                autoComplete="off"
                size='small'
            >
                <section  className="" style={{width: '500px'}} >
                    {/* selects  */}
                    {selects.map(({id,idExpr,label,dataExpr,optionsData,stateName,defaultValue}:selectsTypes)=>{
                        return(
                            <div className={`${id===selects.length-1?'mb-0.5':' mb-0.5'}`}>
                                <SelectsTemplate
                                useCallFunc={true}
                                placeHolder={defaultValue}
                                selectedValue={(value)=>{
                                    console.log(value[dataExpr!]);
                                    
                                    updateState(stateName!,{id:value[idExpr!],value:value[dataExpr!]})
                                }} 
                                label = {label} 
                                options = {optionsData} 
                                idexpr = {idExpr} 
                                dataexp = {dataExpr}/>
                            </div>
                        )
                    })}

                    <div className=" mb-1 mr-2">
                        <InputsTemplate
                        span
                        inputType='number'
                        defaultValue={order}
                        orderOnchange={(value)=>{updateState('order', value)}}
                        inputStyle={{height:24}}
                        useCallbackFunc={true}
                        label = {'Order'}/>
                    </div>

                    {/* checkbox and butons */}
                    <div className = 'mt-0.5 mr-[7px]'>
                        <BottomBtns 
                        span
                        addActiveBorders
                        checkboxChange={(check)=> 
                            updateState('active',check)}
                        useCallbackFunc
                        onNewClick = {()=>{
                            populateStatesFromGrid(true);
                        }}
                        defaultValue = {active}
                        updateHandler={()=>{validateFields() && update('validate')}}
                        saveHandler={()=>{validateFields() && postData('confirm')}}/>
                    </div>
                </section>

                {/* remarks */}
                <section className=" " style={{width : 'calc(100% - 550px)'}}>
                    <TextareaTemplate 
                    height = {168} 
                    label = "Remarks"
                    useCallbackFunc ={true} 
                    defaultValue= {remarks}
                    setCallbackValue= {(value)=>{
                        updateState('remarks', value)}} />
                </section>
            </Form>
        </div>
        </>
    );
}
 