export const assetsSetups_cubicCapability_columns = [
  {
    id: 0,
    caption: "ID",
    dataField: "cbcIdpk",
    dataType: "number",
    width: 160,
    fixed: true,
    visible: false,
  },
  {
    id: 1,
    caption: "CUBIC CAPACITY",
    dataField: "cbcName",
    dataType: "string",
    width: 200,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "SHORT NAME",
    dataField: "cbcShtName",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 3,
    caption: "ORDER",
    dataField: "cbcOrder",
    dataType: "int",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 9,
    caption: "ACTIVE?",
    dataField: "cbcActive",
    dataType: "boolean",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 10,
    caption: "REMARKS",
    dataField: "cbcRmks",
    dataType: "string",
    width: 5000,
    fixed: false,
    visible: true,
  },
];
