import { useCallback, useState } from 'react';
import DataGrid, { Column, Export,GroupPanel, HeaderFilter, Pager, Paging, Scrolling, SearchPanel, Selection } from 'devextreme-react/data-grid';
// import { staff_data, staff_datagrid_allcolumns } from '../data/staff-details-data';

interface props{
    gridheight : number
    columns: any
    data: any
    loading ?: boolean, 
    callBack ?: any,
    doubleClick ?: any
}

export const Footer_datagrid_template = ({gridheight,columns,data,loading, callBack, doubleClick}:props) =>{
    const [selectedEmployee, setSelectedEmployee] = useState();
    // const callBack = () => {

    // }
    const selectEmployee:any = useCallback<any>((e:any) => {
        console.log(e.data)
        // e.component.byKey(e.currentSelectedRowKeys[0]).done((employee:any) => {
        //     console.log(employee);
           
        // });

        callBack(e.data);
    }, []);

    const displayModes = [{ text: 'Display Mode \'full\'', value: 'full' }, { text: 'Display Mode \'compact\'', value: 'compact' }];
    const allowedPageSizes = ['all' ,5, 10, 15 ,];

    const renderTitleHeader = (data:any) => {
        return (<p>{data.column.caption}</p>);
    }
    return(
        <div className="w w-full mt-1">
            {/* custom style for search section */}
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                    .dx-datagrid-header-panel .dx-toolbar {
                        margin-bottom: 4px;
                    }`
                }}
            />
            <DataGrid
                dataSource= {data}
                showBorders={true}
                columnWidth={30}
                showRowLines = {true}
                showColumnLines = {true}
                height = {gridheight}
                allowColumnReordering={true}
                allowColumnResizing={true}
                rowAlternationEnabled={true}
                onRowClick={selectEmployee}
                onRowDblClick={doubleClick}
                >
                

                <Export enabled={false}/>
                <HeaderFilter visible={true} allowSearch={true}/>
                <SearchPanel width={180} visible={true}/>
                <GroupPanel visible={true} emptyPanelText={'Drag a column header to group here'} />
                <Selection mode="single" />

                {/* STAFF DETAILS COLUMNS */}
                {/* FIXED COLUMNS */}
                {columns.map((column:any)=>{
                    return(
                        <Column
                            
                            key={column.id}
                            dataField= {column.dataField}
                            caption = {column.caption}
                            dataType= {column.dataType}
                            width= {column.width}
                            alignment='left'
                            // alignment = {column.dataType === 'boolean' ? 'center' : 'left'}
                            fixed = {column.fixed}
                            // allowFiltering = {column.dataType === 'boolean'? false : true}
                            
                            
                        />
                    )
                })}

                <Scrolling rowRenderingMode={'virtual'} columnRenderingMode="virtual" />
                <Paging defaultPageSize={0} />
                {/*<Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    displayMode={displayModes}
                    showInfo={true}
                    showNavigationButtons={true}
                    showPageSizeSelector={true}
                />*/}
            </DataGrid>
        </div>
    )
}