export const hr_setups_maritalstatus_columns = [
    {id : 0, caption : 'ID',dataField : 'mtsIdpk',datatype : 'number', width : 80, fixed : true, visible : false},
    {id : 1, caption : 'MARITAL STATUS', dataField : 'mtsName',datatype : 'string', width : 160, fixed : true , visible : true},
    {id : 2, caption : 'SHORT NAME', dataField : 'mtsShtName',datatype : 'string', width : 160, fixed : true , visible : true},
    {id : 3, caption : 'ORDER', dataField : 'mtsOrder',datatype : 'string', width : 130, fixed : true , visible : true},
    {id : 4, caption : 'ACTIVE', dataField : 'mtsActive',datatype : 'boolean', width : 90, fixed : true , visible : true},
    {id : 5, caption : 'REMARKS',dataField : 'mtsRmks',datatype : 'string', width : 5000, fixed : false , visible : true}
]

export const maritalstatus_data = [
    {
        'ID' : 1,
        'MARITAL STATUS' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'ORDER' : '4',
        'ACTIVE' : true,
        'REMARKS' : 'SFF'
    },
    {
        'ID' : 2,
        'MARITAL STATUS' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'ORDER' : '4',
        'ACTIVE' : false,
        'REMARKS' : ''
    },
    {
        'ID' : 3,
        'MARITAL STATUS' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'ORDER' : '4',
        'ACTIVE' : true,
        'REMARKS' : ''
    },
    {
        'ID' : 4,
        'MARITAL STATUS' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'ORDER' : '4',
        'ACTIVE' : true,
        'REMARKS' : ''
    },
    {
        'ID' : 5,
        'MARITAL STATUS' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'ORDER' : '4',
        'ACTIVE' : true,
        'REMARKS' : ''
    },
    {
        'ID' : 6,
        'MARITAL STATUS' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'ORDER' : '4',
        'ACTIVE' : true,
        'REMARKS' : ''
    },
    {
        'ID' : 7,
        'MARITAL STATUS' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'ORDER' : '4',
        'ACTIVE' : false,
        'REMARKS' : ''
    },
    {
        'ID' : 8,
        'MARITAL STATUS' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'ORDER' : '4',
        'ACTIVE' : true,
        'REMARKS' : ''
    },
]