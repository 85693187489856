import { createSlice, PayloadAction } from '@reduxjs/toolkit'



interface Estate {
    al_reportDate : string;
    al_message : string;
    fac_asset : string,
    fac_serial : string,
    fac_manfDate : string,
    fac_purDate : string,
    fac_instDate : string,
    
}


var initialState = {
  al_reportDate: "",
  al_message : " ",
  // facility RelEs
  fac_asset : "",
  fac_serial : "",
  fac_manfDate : "",
  fac_purDate : "",
  fac_instDate : "",


}as Estate | any


const estateEntriesSlice = createSlice({
    name: 'RealEstateDetails',
    initialState,
    reducers: {
      // ALERT REQUESTER / SCHEDULE REQUEST
      
      setAlert_req(state, action: PayloadAction<any>) { //set values of variables here
        // this automatically create a state with it's corresponding key value
        const expr:string = action.payload.expr;
        const value:any = action.payload.value;
  
        state[expr] = value;
        //push selected item ID to state

        switch(expr) {
            case 'Report Date':
              state.al_reportDate = value;
            break;
  
            case 'Alert Message':
              state.al_remarks = value;
            break;
  
            default:
              state[expr] = value;
          }
        
      },

     
      //  Facility Details
      setfaciltyDetailValue(state, action:PayloadAction<any>){
        const expr:string = action.payload.expr;
        const value:any = action.payload.value;

        state[expr] = value

        switch(expr){
          case 'Asset Tag' :
            state.fac_asset = value
            break;
          
          case 'Serial No':
            state.fac_serial = value
            break;
          case 'Manuf. Date' :
            state.fac_manfDate = value
            break;
          case 'Purchase Date' :
            state.fac_purDate = value
            break;
          case 'First Inst. Date' :
            state.fac_instDate = value
            break;
          
          
        }

      },
      //  Maintenance Activity
      setmaintenanceDetailValue(state, action:PayloadAction<any>){
        const expr:string = action.payload.expr;
        const value:any = action.payload.value;

        state[expr] = value

        // switch(expr){
        //   case 'Asset Tag' :
        //     state.fac_asset = value
        //     break;
          
        //   case 'Serial No':
        //     state.fac_serial = value
        //     break;
        //   case 'Manuf. Date' :
        //     state.fac_manfDate = value
        //     break;
        //   case 'Purchase Date' :
        //     state.fac_purDate = value
        //     break;
        //   case 'First Inst. Date' :
        //     state.fac_instDate = value
        //     break;
          
          
        // }

      },
      
    },
  })
  
  export const { setmaintenanceDetailValue,setAlert_req,setfaciltyDetailValue} = estateEntriesSlice.actions
  export default estateEntriesSlice.reducer