/* eslint-disable react/jsx-pascal-case */
import { Tooltip } from "devextreme-react";
import "devextreme/dist/css/dx.light.css";
import close from "../../../assets/close.png";
import switchChart from "../../../assets/switch-chart.png";
import eLogBook from "../../../assets/eLogBook.png";
import help from "../../../assets/help.png";
import reset from "../../../assets/refresh-small.png";
import midnight from "../../../assets/night-mode.png";
import template from "../../../assets/open-folder.png";
import takeOver from "../../../assets/usernext.png";
import shiftChange from "../../../assets/refill.png";
import shiftMembers from "../../../assets/userCog.png";
import config from "../../../assets/configuration.png";
import file from "../../../assets/sync-folder.png";
import performance from "../../../assets/performance.small.png";
import redflag from "../../../assets/red-flag.png";
import docs from "../../../assets/google-docs.png";
import arrowUpDown from "../../../assets/mobile-data.png";
import print from "../../../assets/printer.png";
import loader from "../../../assets/statusloader.gif";

import { CheckboxTemlate } from "../../../templates/checkbox";
import { IconsContainer } from "../../Safety/elogger/StationReadings/_widget/stationReadingsToolbar";
import { InputsTemplate } from "../../../templates/input";
import InputSelect_template from "../../../templates/InputSelect";
import { DateTemplate } from "../../../templates/date";
import { FaBinoculars } from "react-icons/fa";
import { RefreshButton } from "../../Safety/elogger/StationReadings/_widget/utils";
interface props {
  midnightConditionsClick: () => void;
  updateShiftMembersClick: () => void;
  updateTemplatesClick: () => void;
  remoteShiftChangeClick: () => void;
  shiftTakeoverClick: () => void;
  eventTypesClick: () => void;
  stationDetailsClick: () => void;
}

export const EloggerCustomToolbar = ({
  midnightConditionsClick,
  updateShiftMembersClick,
  updateTemplatesClick,
  remoteShiftChangeClick,
  shiftTakeoverClick,
  eventTypesClick,
  stationDetailsClick,
}: props) => {
  const checkboxes = [
    {
      id: "spell-checker",
      name: "Spell Checker",
      description:
        "Tick to enable the spell checking feature for your event. You can turn it off by unchecking it.",
    },
    {
      id: "log-date",
      name: "Show log date",
      description:
        "Click to show or hide the date the event was saved to the database.",
    },
    {
      id: "multiline-rows",
      name: "Multiline Rows",
      description: "Click to expand or collapse multiple rows in the table.",
    },
    {
      id: "vertical-lines",
      name: "Show Vertical Lines",
      description: "Tick to show vertical lines in the table.",
    },
  ];

  const icons = [
    [
      {
        id: "midnight-conditions",
        name: "Midnight Conditions",
        tooltip:
          "Click to initiate the capturing of midnight conditions for the station",
        onClick: midnightConditionsClick,
        src: midnight,
      },
      {
        id: "update-templates",
        name: "Update Templates",
        tooltip: "Click to launch the templates update interface",
        onClick: updateTemplatesClick,
        src: template,
      },
      {
        id: "remote-shift",
        name: "Remote Shift Change",
        tooltip:
          "Click to launch the form to either instantiate or accept a remote shift change",
        onClick: remoteShiftChangeClick,
        src: shiftChange,
      },
      {
        id: "shift-takeover",
        name: "Shift Take Over",
        tooltip:
          "Click to launch the form to takeover the current shift. You should have shift takeover privilege before trying this",
        onClick: shiftTakeoverClick,
        src: takeOver,
      },
      {
        id: "shift-members",
        name: "Update Current Shift Members",
        tooltip:
          "Click to launch the form to use to update current shift members",
        onClick: updateShiftMembersClick,
        src: shiftMembers,
      },
      {
        id: "folder-sync",
        name: "Folder",
        tooltip:
          "Click to launch the form to use to update current shift members",
        onClick: () => {},
        src: file,
      },
      {
        id: "config",
        name: "Event Types",
        tooltip:
          "Click to launch the form to use to update current shift members",
        onClick: eventTypesClick,
        src: config,
      },
    ],
    [
      {
        id: "bookmark",
        name: "Bookmark Log",
        tooltip:
          "Click to bookmark the selected log. Bookmarked logs are visible to the individual who bookmarked it",
        onClick: () => {},
        src: docs,
      },
      {
        id: "bookmark",
        name: "Flag Log",
        tooltip:
          "Click to flag the selected log. Flagged logs are visible to all individuals who have the privilege to view the log. It is coloured yellow.",
        onClick: () => {},
        src: redflag,
      },
      {
        id: "station-details",
        name: "Station Details",
        tooltip:
          "Click to launch the form to use to capture a number of parameter readings recorded at the substation.",
        onClick: stationDetailsClick,
        src: performance,
      },
    ],
    [
      {
        id: "arrow-up-down",
        name: "Sync Logs",
        tooltip: "",
        onClick: () => {},
        src: arrowUpDown,
      },
      {
        id: "refresh",
        name: "Refresh",
        tooltip: "",
        onClick: () => {},
        src: reset,
      },
    ],
    [
      {
        id: "close",
        name: "Close",
        tooltip: "",
        onClick: () => {},
        src: close,
      },
      { id: "help", name: "Help", tooltip: "", onClick: () => {}, src: help },
    ],
  ];
  const inputTemps = [
    { id: 0, option: ["first"] },
    { id: 1, option: ["second"] },
    { id: 2, option: ["third"] },
    { id: 3, option: ["forth"] },
    { id: 4, option: ["fifth"] },
  ];

  return (
    <main className="mb-0.5">
      <section className="w-full ml-1 flex items-center space-x-2 border-[1px] px-1">
        <aside>
          <p className="truncate">{"Search-->"}</p>
        </aside>
        <aside className="w-[150px]">
          <InputsTemplate span={true} disabledStatus={false} inputStyle={{ height: "24px", marginBottom: 3 }} />
        </aside>

        {inputTemps.map((inputTemp, index) => (
          <aside className="w-[150px] bg-transparent" key={index}>
            {/* <InputSelect_template extraWidget={false} selectStyle={{ height: "25px" }} disabled={false} options={inputTemp.option} span={true} useCusWidth cusWidth={"90px"} disabledCustomWidth={true} /> */}
            <InputSelect_template
              selectStyle={{ height: "25px" }}
              disabled={false}
              options={inputTemp.option}
              span={true}
              settingsIcon={false}
              settingsIconBefore={false}
              disabledCustomWidth={true}
              useCallFunc
            />
          </aside>
        ))}
        <aside>
          <DateTemplate disabled={false} />
        </aside>
        <aside>
          <DateTemplate disabled={false} />
        </aside>
        <aside>
          <CheckboxTemlate
            withBorders
            defaultValue={false}
            customDisabled={false}
          />
        </aside>
        <aside>
          <CheckboxTemlate
            withBorders
            defaultValue={false}
            customDisabled={false}
            indeterminate
          />
        </aside>
        <div className="p-1 border-[1px] rounded-sm">
          <FaBinoculars size={15.5} color="#007AFF" />
        </div>
        <div className="">
          <RefreshButton />
        </div>
        {/* <IconsContainer
          url={print}
          name="Midnight Conditions"
          tooltip="Click to initiate the capturing of midnight conditions for the station"
        /> */}

        <img
          className=" hover:bg-blue-50  flex p-0.5 items-center justify-center border-[1px] rounded"
          src={print}
          alt="print"
        />
        <aside className=" w-[30px]">
          <img src={loader} alt="eLog Book" />
        </aside>
      </section>

      <section className="ml-1 flex items-center border-[1px] px-1 w-full h-full">
        <aside className="h-4 w-[100px] border-[1px] -mt-1 mx-1">
          <img src={eLogBook} alt="eLog Book" />
        </aside>
        <aside>
          <p className="truncate">{"Actions-->"}</p>
        </aside>

        <aside className="w-[45px]">
          <IconsContainer
            url={switchChart}
            name="Switch Chart"
            tooltip="Click to switch between the bar and line chart"
            onClick={() => {}}
          />
        </aside>
        <aside className=" flex">
          {checkboxes.map((checkbox, index) => (
            <li
              key={index}
              id={checkbox.id}
              style={{}}
              className="hover:cursor-pointer mr-1.5 text-xs text-gray-600 rounded flex items-center"
            >
              <CheckboxTemlate
                withBorders
                defaultValue={false}
                customDisabled={false}
              />

              <Tooltip
                target={`#${checkbox.id}`}
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className=" text-left font-semibold">{checkbox.name}</p>
                <p>{checkbox.description}</p>
              </Tooltip>
            </li>
          ))}
        </aside>

        <aside className="flex w-[300px]">
          {
            icons[0].map((icon, index) => (
              <IconsContainer url={icon.src} {...icon} />
            ))
          }
        </aside>

        <aside className="flex mx-5 w-[160px]">
          {
            icons[1].map((icon, index) => (
              <IconsContainer url={icon.src} {...icon} />
            ))
          }
        </aside>

        <aside
          style={{ width: "calc(100% - 500px)" }}
          className="flex justify-between  w-fit"
        >
          <div className="flex w-40">
            {
              icons[2].map((icon, index) => (
                <IconsContainer url={icon.src} {...icon} />
              ))
            }
          </div>

          <div className=" flex">
            {
              icons[3].map((icon, index) => (
                <IconsContainer url={icon.src} {...icon} />
              ))
            }
          </div>
        </aside>
      </section>
    </main>
  );
};
