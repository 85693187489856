

export const staff_dependant_TH = [
    {
      id: 0,
      caption: "STAFF No",
      dataField: "empStaffNo",
      alignment: "left",
      fixed: true,
      width: null,
    },
    {
      id: 1,
      caption: "STAFF NAME",
      dataField: "empName",
      alignment: "left",
      fixed: true,
      width: null
    },
   
    {
      id: 2,
      caption: "DEPEND. NO",
      dataField: "dpdDependantNumber",
      alignment: "left",
      fixed: true,
      width: null
    },
    {
      id: 3,
      caption: "DEPENDANT NAME",
      dataField: "dpdName",
      alignment: "left",
      fixed: true,
      width: null
  
  
    },
    {
      id: 4,
      caption: "RELATIONSHIP",
      dataField: "rlsName",
      alignment: "left",
      fixed: true,
      width: null
  
    },
    {
      id: 5,
      caption: "DEP. CLASS",
      dataField: "dclName",
      alignment: "left",
      fixed: true,
      width: null
  
    },
    
    {
      id: 6,
      caption: "DEPEND. DoB",
      dataField: "dpdDoB",
      alignment: "left",
      fixed: true,
      width: null
  
    },
    
    
    {
      id: 7,
      caption: "DEPEND. AGE",
      dataField: "dpdAge",
      alignment: "left",
      fixed: true,
      width: 60
  
    },
    
    {
      id: 8,
      caption: "SUBMI.?",
      dataField: "dpdSubmitted",
      alignment: "left",
      fixed: true,
      width: 60
  
    },
    
    {
      id: 9,
      caption: "WITNSD?",
      dataField: "dpdWitnessed",
      alignment: "left",
      fixed: true,
      width: 60
  
    },

    {
      id: 10,
      caption: "APRVD",
      dataField: "dpdApproved",
      alignment: "left",
      fixed: true,
      width: 60
  
    },

    {
      id: 11,
      caption: "ACTIVE",
      dataField: "dpdActive",
      alignment: "left",
      fixed: true,
      width: 60
  
    },

    {
      id: 12,
      caption: "STAFF DoB",
      dataField: "empDoB",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 13,
      caption: "RETIRE DATE",
      dataField: "retireDate",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 14,
      caption: "STAFF AGE",
      dataField: "age",
      alignment: "left",
      fixed: true,
      width: 60
  
    },

    {
      id: 15,
      caption: "JOB TITLE",
      dataField: "jbtShtName",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 16,
      caption: "DEPT",
      dataField: "dptShtName",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 17,
      caption: "SECTION",
      dataField: "sxnShtName",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 18,
      caption: "ID TYPE",
      dataField: "idtName",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 19,
      caption: "WITNESSED BY",
      dataField: "witnessedByEmpNameStaffNo",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 20,
      caption: "APPROVED BY",
      dataField: "approveddByEmpNameStaffNo",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 21,
      caption: "ID NUMBER",
      dataField: "sddIDNumber",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 22,
      caption: "SUBMITTED ON",
      dataField: "sddSubmittedOn",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 23,
      caption: "WITNESSED ON",
      dataField: "sddWitnessedOn",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 24,
      caption: "APPROVED ON",
      dataField: "sddApprovedOn",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 25,
      caption: "INTERCOM",
      dataField: "empIntercom",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 26,
      caption: "MOB No",
      dataField: "empMobNo",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 27,
      caption: "LOCATION",
      dataField: "locShtName",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 28,
      caption: "OFFICE LOC",
      dataField: "fofcShtName",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 29,
      caption: "ROOM No",
      dataField: "fromName",
      alignment: "left",
      fixed: true,
      width: 100
  
    },

    {
      id: 30,
      caption: "ASSIGNMENT STATUS",
      dataField: "astName",
      alignment: "left",
      fixed: true,
      width: 100
  
    },

    {
      id: 31,
      caption: "PERSON TYPE",
      dataField: "pstName",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 32,
      caption: " GENDER",
      dataField: "gndName",
      alignment: "left",
      fixed: true,
      width: 100
  
    },

    {
      id: 33,
      caption: " SUPERVISOR",
      dataField: "supName",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 34,
      caption: "MARITAL STATUS",
      dataField: "mtsName",
      alignment: "left",
      fixed: true,
      width: 100
  
    },

    {
      id: 35,
      caption: "GRADE STEP",
      dataField: "gradeStep",
      alignment: "left",
      fixed: true,
      width: 100
  
    },

    {
      id: 36,
      caption: "STAFF ADDRESS",
      dataField: "empAddress",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 37,
      caption: "BEN. ADDRESS",
      dataField: "sddAddress",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 38,
      caption: "COST CENTRE",
      dataField: "empCostCenter",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 39,
      caption: "APPRAISAL GROUP",
      dataField: "apgShtName",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 40,
      caption: "ASSIGNMENT CATEGORY",
      dataField: "ascShtName",
      alignment: "left",
      fixed: true,
      width: 200
  
    },

    {
      id: 41,
      caption: "PROMO DATE",
      dataField: "empPromoDate",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 42,
      caption: "DIR?",
      dataField: "empIsDirector",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 43,
      caption: "MGR?",
      dataField: "empIsManager",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 44,
      caption: "SUP?",
      dataField: "empIsSupervisor",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 45,
      caption: "SNR STAFF?",
      dataField: "empIsSeniorStaff",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 46,
      caption: "JNR STAFF?",
      dataField: "empIsJuniorStaff",
      alignment: "left",
      fixed: true,
      width: 160
  
    },
  
    {
      id: 47,
      caption: "MGMT STAFF?",
      dataField: "empIsManagementStaff",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 48,
      caption: "",
      dataField: "empIsJuniorStaff",
      alignment: "left",
      fixed: true,
      width: 160
  
    },
  ]
export const staff_dependant_TH_Without_No = [
  
    {
      id: 1,
      caption: "STAFF NAME",
      dataField: "empName",
      alignment: "left",
      fixed: true,
      width: null
    },
   
    {
      id: 2,
      caption: "DEPEND. NO",
      dataField: "dpdDependantNumber",
      alignment: "left",
      fixed: true,
      width: null
    },
    {
      id: 3,
      caption: "DEPENDANT NAME",
      dataField: "dpdName",
      alignment: "left",
      fixed: true,
      width: null
  
  
    },
    {
      id: 4,
      caption: "RELATIONSHIP",
      dataField: "rlsName",
      alignment: "left",
      fixed: true,
      width: null
  
    },
    {
      id: 5,
      caption: "DEP. CLASS",
      dataField: "dclName",
      alignment: "left",
      fixed: true,
      width: null
  
    },
    
    {
      id: 6,
      caption: "DEPEND. DoB",
      dataField: "dpdDoB",
      alignment: "left",
      fixed: true,
      width: null
  
    },
    
    
    {
      id: 7,
      caption: "DEPEND. AGE",
      dataField: "dpdAge",
      alignment: "left",
      fixed: true,
      width: 60
  
    },
    
    {
      id: 8,
      caption: "SUBMI.?",
      dataField: "dpdSubmitted",
      alignment: "left",
      fixed: true,
      width: 60
  
    },
    
    {
      id: 9,
      caption: "WITNSD?",
      dataField: "dpdWitnessed",
      alignment: "left",
      fixed: true,
      width: 60
  
    },

    {
      id: 10,
      caption: "APRVD",
      dataField: "dpdApproved",
      alignment: "left",
      fixed: true,
      width: 60
  
    },

    {
      id: 11,
      caption: "ACTIVE",
      dataField: "dpdActive",
      alignment: "left",
      fixed: true,
      width: 60
  
    },

    {
      id: 12,
      caption: "STAFF DoB",
      dataField: "empDoB",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 13,
      caption: "RETIRE DATE",
      dataField: "retireDate",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 14,
      caption: "STAFF AGE",
      dataField: "age",
      alignment: "left",
      fixed: true,
      width: 60
  
    },

    {
      id: 15,
      caption: "JOB TITLE",
      dataField: "jbtShtName",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 16,
      caption: "DEPT",
      dataField: "dptShtName",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 17,
      caption: "SECTION",
      dataField: "sxnShtName",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 18,
      caption: "ID TYPE",
      dataField: "idtName",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 19,
      caption: "WITNESSED BY",
      dataField: "witnessedByEmpNameStaffNo",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 20,
      caption: "APPROVED BY",
      dataField: "approveddByEmpNameStaffNo",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 21,
      caption: "ID NUMBER",
      dataField: "sddIDNumber",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 22,
      caption: "SUBMITTED ON",
      dataField: "sddSubmittedOn",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 23,
      caption: "WITNESSED ON",
      dataField: "sddWitnessedOn",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 24,
      caption: "APPROVED ON",
      dataField: "sddApprovedOn",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 25,
      caption: "INTERCOM",
      dataField: "empIntercom",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 26,
      caption: "MOB No",
      dataField: "empMobNo",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 27,
      caption: "LOCATION",
      dataField: "locShtName",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 28,
      caption: "OFFICE LOC",
      dataField: "fofcShtName",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 29,
      caption: "ROOM No",
      dataField: "fromName",
      alignment: "left",
      fixed: true,
      width: 100
  
    },

    {
      id: 30,
      caption: "ASSIGNMENT STATUS",
      dataField: "astName",
      alignment: "left",
      fixed: true,
      width: 100
  
    },

    {
      id: 31,
      caption: "PERSON TYPE",
      dataField: "pstName",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 32,
      caption: " GENDER",
      dataField: "gndName",
      alignment: "left",
      fixed: true,
      width: 100
  
    },

    {
      id: 33,
      caption: " SUPERVISOR",
      dataField: "supName",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 34,
      caption: "MARITAL STATUS",
      dataField: "mtsName",
      alignment: "left",
      fixed: true,
      width: 100
  
    },

    {
      id: 35,
      caption: "GRADE STEP",
      dataField: "gradeStep",
      alignment: "left",
      fixed: true,
      width: 100
  
    },

    {
      id: 36,
      caption: "STAFF ADDRESS",
      dataField: "empAddress",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 37,
      caption: "BEN. ADDRESS",
      dataField: "sddAddress",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 38,
      caption: "COST CENTRE",
      dataField: "empCostCenter",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 39,
      caption: "APPRAISAL GROUP",
      dataField: "apgShtName",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 40,
      caption: "ASSIGNMENT CATEGORY",
      dataField: "ascShtName",
      alignment: "left",
      fixed: true,
      width: 200
  
    },

    {
      id: 41,
      caption: "PROMO DATE",
      dataField: "empPromoDate",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 42,
      caption: "DIR?",
      dataField: "empIsDirector",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 43,
      caption: "MGR?",
      dataField: "empIsManager",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 44,
      caption: "SUP?",
      dataField: "empIsSupervisor",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 45,
      caption: "SNR STAFF?",
      dataField: "empIsSeniorStaff",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 46,
      caption: "JNR STAFF?",
      dataField: "empIsJuniorStaff",
      alignment: "left",
      fixed: true,
      width: 160
  
    },
  
    {
      id: 47,
      caption: "MGMT STAFF?",
      dataField: "empIsManagementStaff",
      alignment: "left",
      fixed: true,
      width: 160
  
    },

    {
      id: 48,
      caption: "",
      dataField: "empIsJuniorStaff",
      alignment: "left",
      fixed: true,
      width: 160
  
    },
  ]
  
  
  
  export const staff_bene_unsub_TH = [
    
      {
        id: 0,
        caption: "STAFF No",
        dataField: "",
        alignment: "left",
        fixed: true,
        width: 160
    
      
    },
    
    {
      id: 1,
      caption: "NAME",
      dataField: "",
      alignment: "left",
      fixed: true,
      width: 160
  
    
  },
 

  {
    id: 2,
    caption: "INTERCOM",
    dataField: "",
    alignment: "left",
    fixed: true,
    width: 160

},
{
  id: 3,
  caption: "JOB TITLE",
  dataField: "",
  alignment: "left",
  fixed: true,
  width: 160

},
{
  id: 4,
  caption: "MOB No",
  dataField: "",
  alignment: "left",
  fixed: true,
  width: 160

},
{
  id: 5,
  caption: "SECTION",
  dataField: "",
  alignment: "left",
  fixed: true,
  width: 160

},
{
  id: 6,
  caption: "OFFICE LOCATION",
  dataField: "",
  alignment: "left",
  fixed: true,
  width: 160

},
{
  id: 7,
  caption: "ROOM No",
  dataField: "",
  alignment: "left",
  fixed: true,
  width: 160

},
{
  id: 8,
  caption: "LOCATION",
  dataField: "",
  alignment: "left",
  fixed: true,
  width: 160

},
{
  id: 9,
  caption: "DEPT",
  dataField: "",
  alignment: "left",
  fixed: true,
  width: 160


},

  ]