import { Progress } from "antd"
import { useEffect, useState } from "react"

export const SplashScreen=()=>{
    const [counter, setCounter] = useState(0);
    useEffect(() => {
        if (counter<=100) {
            const timeout = setTimeout(() => {
                setCounter(counter + 40);
            }, 1000);
    
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [counter]);

    return(
        <main className=" flex flex-row justify-center h-screen items-end ">
            <div className=" w-64 mb-20">
                <Progress 
                percent={counter} 
                showInfo={false} 
                trailColor='rgb(191 219 254 / var(--tw-bg-opacity))'
                />
            </div>
        </main>
    )
}