/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import { RefreshButton } from "../../../../StationReadings/_widget/utils";
import Datagrid_template from "../../../../../../../templates/Datagrid";
import axios from "axios";
import { api_base_url } from "../../../../../../accessories/component_infos";
import useFetch from "../../../../../../../hooks/useFetch";
const OperatorsOnDuty_columns = [
  { id: 1, caption: 'STATION', dataField: 'stnName', dataType: 'string', width: 100, fixed: false, visible: true },
  { id: 2, caption: 'CODE', dataField: 'stnCode', dataType: 'string', width: 100, fixed: false, visible: true },
  { id: 3, caption: 'ACTIVE', dataField: 'exnName', dataType: 'string', width: 100, fixed: false, visible: true },
  { id: 4, caption: 'INTERCOM', dataField: 'stnPlc', dataType: 'string', width: 100, fixed: false, visible: true },
  { id: 5, caption: 'CONTACT(S)', dataField: 'stnContact', dataType: 'string', width: 1000, fixed: false, visible: true },
]

export default function OperatorsOnDuty({ height }: { height: number }) {
  
  const[refresh, setRefresh] = useState(false);
  const [data, error, loading] = useFetch(`ELogBook/PopDgvOperatorsOnDuty`, refresh, '', false, "powerapp");
  
  return (
    <main>
      <div className="w-full h-auto border-[1px] pr-1 flex justify-end">
        <RefreshButton onClick={()=>{
          setRefresh(current => !current)
        }}/>
      </div>

      {error && (
        <div className=" flex h-full justify-center items-center mt-10 mb-10">
          <p className=' font-bold text-red-800'>Error Fetching Data. Plase Refresh</p>
        </div>
      )}

      {loading && (
        <div className=" flex h-full justify-center items-center mt-10 mb-4">
          <p className=' font-bold text-gray-800'>Fetching Data...</p>
        </div>
      )}

      {(!loading && !error) && (
        <Datagrid_template
          columns={OperatorsOnDuty_columns} 
          data={data}
          disablePaging
          disableGroupPanel
          gridheight={height}
        />)}
    </main>
  );
}
