import { useEffect, useState } from 'react'
import {Checkbox, Form, Input, Select } from 'antd';
import { api_url, saveModalData, updateModalData } from '../../../../accessories/component_infos';
import { ModalTemplate } from '../../../../../templates/modal';
import { modalPropTypes, modalTypes, selectObjectTypes, selectsTypes, validateInstanceTypes } from '../../../../human-resource/setups/data/types/selectsTypes';
import { SelectsTemplate } from '../../../../../templates/select';
import useFetch from '../../../../../hooks/useFetch';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../app/store';
import dayjs from 'dayjs';
import { task_footer_table_selected, task_form_disable } from '../../../../../features/Task&Todo/Task/TaskDetails';
import { setGeneralValue, settask_footer_table_refresh } from '../../../../../features/generalSlice';
import { SaveButton, UpdateButton, NewButton, Cancel } from '../../../../accessories/buttons';
import { PostDataFunc } from '../../../../../functions/post';
import { UpdateDataFunc } from '../../../../../functions/update';
import { CI } from '../../CI/Cooperate_initiative';
import { Perf_setups_deptInitiatives } from '../../../../Performance/setups/dept-initiative-values/deptInitiativeValue';
import { FormModal } from '../../../../accessories/formModal';
import { Perf_setups_secInitiatives } from '../../../../Performance/setups/section-initiatives/sectionInitiatives';
import { Perf_setups_unitInitiatives } from '../../../../Performance/setups/unit-initiatives/unitInitiatives';

interface stateTypes{
    period: { id: number; name: string }
    department: { id: number; name: string }
    section: { id: number; name: string }
    unit: { id: number; name: string }
    mission: { id: number; name: string }
    objective: { id: number; name: string }
    initiative: { id: number; name: string }
    deptInitiative: { id: number; name: string }
    sectionInitiative: { id: number; name: string }
    unitInitiative: { id: number; name: string }
    active: boolean
    openFormModal : boolean
    setFormModal : any
    setModalTitle : string
}

interface props{
    selectedRecordx?: any;
    remarksx?: string
    setRemarksx?: (e: string) => void
    setSelectedRecordx?: (e: any) => void
}
export const UIA_Details_Form = ({ remarksx, setRemarksx, selectedRecordx, setSelectedRecordx }: props) =>{

    const { Option } = Select;
    const { TextArea } = Input;

    const dateFormat = " DD MMM YYYY HH: mm:ss";
    const dispatch = useDispatch()
    const borderTheme = useSelector((state:RootState)=>state.general.cssBorderClass)

    const [states, setStates] = useState<stateTypes>({
        period: { id: 0, name: '', },
        department: { id: 0, name: '' },
        section: { id: 0, name: '' },
        unit: { id: 0, name: ''},
        mission: { id: 0, name: '' },
        objective: { id: 0, name: '' },
        initiative: { id: 0, name: '' },
        deptInitiative: { id: 0, name: '' },
        sectionInitiative: { id: 0, name: '' },
        unitInitiative: { id: 0, name: '' },
        active: false,
        openFormModal : false,
        setFormModal : "",
        setModalTitle : "",
    })

    const updateState = (key: string, value: any) => {
        setStates((prev: any) => ({ ...prev, [key]: value }))
    }

    console.log(states)

    // useEffect(()=>{
    //     populateStatesFromGrid();
    // },[selectedField])

    // component states
    
    const form_disable = useSelector((state: RootState) => state.general.formDisabled)
    const form_disable2 = useSelector((state: RootState) => state.addTask.taskFormStatus)
    const update_state_change = useSelector((state: RootState) => state.addTask.taskFooterUpdate)

     // Modal dialog state
     const [refreshValue, setRefreshValue] = useState(false);
     const [showModalDisableButton, setshowModalDisableButton] = useState(false);
     const [openModal, setOpenModal] = useState(false);
     const [modalData, setModalData] = useState<any>(saveModalData);
     const [icon, setIcon] = useState<'warning' | 'success' | 'question'>();
     const [updatingstatus, setUpdatingstatus] = useState(false);
     const [showCancel, setshowCancel] = useState(false);
     const [refreshstate, setRefreshstate] = useState(false);
     const [isActive, setisActive] = useState(false);
     const [refresh, setRefresh] = useState(false);
 
     const editedDate = dayjs().format()

     // Taking data from redux
    const DepartmentID = useSelector((state: RootState) => state.safetyManagerDetails.DepartmentID)
    const MissionID = useSelector((state: RootState) => state.safetyManagerDetails.MissionID)

    const [periods_data, periods_error, periods_loading] = useFetch('PfmCorporateInnitiativeAssignment/GetPfmLueCorporatePeriods?isActive=true')

    const [department_data, department_error, department_loading] = useFetch('PfmDepartmentInitiatives/GetDeptInitiativePopLueDepts')

    const [section_data, section_error, section_loading] = useFetch('PfmSectionInitiativeAssignments/GetSectionInitiativeAssignmentsPopLueSections')
    const [unit_data, unit_error, unit_loading] = useFetch('PfmUnitInitiativeAssignments/UnitInitiativeAssignmentsPopLueUnits')
    const [mission_data, mission_error, mission_loading] = useFetch('PfmUnitInitiativeAssignments/GetUnitInitiativeAssignmentsPopLueMission')
    //const [Objective_data, objective_error,objective_loading] = useFetch(`PfmCorporateInnitiativeAssignment/GetPfmLueObjectivesWithMissionId?isActive=true&missionId=${MissionID}`)
    const [Objective_data, objective_error, objective_loading] = useFetch(`PfmUnitInitiativeAssignments/UnitInitiativeAssignmentsPopLueObjectives`)
    const [initiative_data, initiative_error, initiative_loading] = useFetch('PfmUnitInitiativeAssignments/UnitInitiativeAssignmentsPopLueCorporateInitiatives')

    const [deptInitiative_data, deptInitiative_error, deptInitiative_loading] = useFetch('PfmDepartmentInitiatives/GetDeptInitiativePopLueDeptInitiatives')

    const [sectionInitiative_data, sectionInitiative_error, sectionInitiative_loading] = useFetch('PfmSectionInitiativeAssignments/GetSectionInitiativeAssignmentsPopLueSectionInitiatives')
    const [unitInitiative_data, unitInitiative_error, unitInitiative_loading] = useFetch('PfmUnitInitiativeAssignments/UnitInitiativeAssignmentsPopLueUnitInitiative')

    console.log('pls', initiative_data)

    const selects: selectsTypes[] = [
        { id: 0, label: 'Period', optionsData: periods_data, dataExpr: "prdName", idExpr: "prdIdpk", stateName: 'period', defaultValue: states.period.name, style: '314px' },
        { id: 1, label: 'Department', optionsData: department_data, dataExpr: "dptName", idExpr: "dptIdpk", stateName: 'department', defaultValue: states.department.name, style: '380px' },
        { id: 2, label: 'Section', optionsData: section_data, dataExpr: "sxnName", idExpr: "sxnIdpk", stateName: 'section', defaultValue: states.section.name, style: '380px' },
        { id: 2, label: 'Unit', optionsData: unit_data, dataExpr: "name", idExpr: "untIDpk", stateName: 'unit', defaultValue: states.unit.name, style: '380px' },
        { id: 3, label: 'Corporate Mission/Vision', optionsData: mission_data, dataExpr: "mvsMission", idExpr: "mvsIdpk", stateName: 'mission', defaultValue: states.mission.name, style: '450px' },
        { id: 4, label: 'Strategic Objective', optionsData: Objective_data, dataExpr: "name", idExpr: "id", stateName: 'objective', defaultValue: states.objective.name, style: '450px' },
        { id: 5, label: 'Corporate Initiative', optionsData: initiative_data, dataExpr: "name", idExpr: "id", stateName: 'initiative', defaultValue: states.initiative.name, style: '450px' },
        { id: 6, label: 'Department Initiative', optionsData: deptInitiative_data, dataExpr: "dinName", idExpr: "dinIdpk", stateName: 'deptInitiative', defaultValue: states.deptInitiative.name, style: '450px' },
        { id: 7, label: 'Section Initiative', optionsData: sectionInitiative_data, dataExpr: "sxiName", idExpr: "sxiIdpk", stateName: 'sectionInitiative', defaultValue: states.sectionInitiative.name, style: '450px' },
        { id: 8, label: 'Unit Initiative', optionsData: unitInitiative_data, dataExpr: "name", idExpr: "id", stateName: 'unitInitiative', defaultValue: states.unitInitiative.name, style: '450px' },
    ]

    const populateFields = (clear?: boolean) => {
        if (clear === true) {
            dispatch(task_footer_table_selected([]))
            setSelectedRecordx!({})
        }

        const gridState = [
            { key: 'period', value: clear ? { id: 0, name: '' } : { id: selectedRecordx?.prdIdpk, name: selectedRecordx?.prdName } },
            { key: 'department', value: clear ? { id: 0, name: '' } : { id: selectedRecordx?.dptIdpk, name: selectedRecordx?.dptName } },
            { key: 'section', value: clear ? { id: 0, name: '' } : { id: selectedRecordx?.sxnIdpk, name: selectedRecordx?.sxnName } },
            { key: 'unit', value: clear ? { id: 0, name: '' } : { id: selectedRecordx?.untIDpk, name: selectedRecordx?.untName } },
            { key: 'mission', value: clear ? { id: 0, name: '' } : { id: selectedRecordx?.mvsIdpk, name: selectedRecordx?.mvsMission } },
            { key: 'objective', value: clear ? { id: 0, name: '' } : { id: selectedRecordx?.objIdpk, name: selectedRecordx?.thmName } },
            { key: 'initiative', value: clear ? { id: 0, name: '' } : { id: selectedRecordx?.cinIdpk, name: selectedRecordx?.cinName } },
            { key: 'deptInitiative', value: clear ? { id: 0, name: '' } : { id: selectedRecordx?.dinIdpk, name: selectedRecordx?.dinName } },
            { key: 'sectionInitiative', value: clear ? { id: 0, name: '' } : { id: selectedRecordx?.sxiIdpk, name: selectedRecordx?.sxiName } },
            { key: 'unitInitiative', value: clear ? { id: 0, name: '' } : { id: selectedRecordx?.uinIdpk, name: selectedRecordx?.uinName } },
            { key: 'active', value: clear ? true : selectedRecordx?.uiaActive },
        ]

        if (setRemarksx !== undefined) {
            setRemarksx(selectedRecordx?.sxaRmks);
        }

        for (let instance of gridState) updateState(instance.key, instance.value);
    }
    

    // Validation 
    const validationForms = async () => {
        setIcon('warning')
        if (states.period?.id === 0 || states.period?.id === undefined) {
            setshowModalDisableButton(true);
            setModalData({ message: "Please select data entry for Period", title: " Field Required! ", okText: "Ok" });
            setOpenModal(true);
        } else if (states.department?.id === 0 || states.department?.id === undefined) {
            setshowModalDisableButton(true);
            setModalData({ message: "Please select data entry for Department", title: " Field Required! ", okText: "Ok" });
            setOpenModal(true);
        } else if (states.section?.id === 0 || states.department?.id === undefined) {
            setshowModalDisableButton(true);
            setModalData({ message: "Please select data entry for Section", title: " Field Required! ", okText: "Ok" });
            setOpenModal(true);
        } else if (states.unit?.id === 0 || states.unit?.id === undefined) {
            setshowModalDisableButton(true);
            setModalData({ message: "Please select data entry for Unit", title: " Field Required! ", okText: "Ok" });
            setOpenModal(true);
        }else if (states.mission?.id === 0 || states.mission?.id === undefined) {
            setshowModalDisableButton(true);
            setModalData({ message: "Please select data entry for Mission / Vision", title: " Field Required!", okText: "Ok" });
            setOpenModal(true);
        } else if (states.objective?.id === 0 || states.objective?.id === undefined) {
            setshowModalDisableButton(true);
            setModalData({ message: "Please enter data for Strategic Objective", title: "Field Required!", okText: "Ok" });
            setOpenModal(true);
        } else if (states.initiative?.id === 0 || states.initiative?.id === undefined) {
            setshowModalDisableButton(true);
            setModalData({ message: "Please enter data for Corporate Initiative", title: "Field Required!", okText: "Ok" });
            setOpenModal(true);
        } else if (states.deptInitiative?.id === 0 || states.deptInitiative?.id === undefined) {
            setshowModalDisableButton(true);
            setModalData({ message: "Please enter data for Department Initiative", title: "Field Required!", okText: "Ok" });
            setOpenModal(true);
        } else if (states.sectionInitiative?.id === 0 || states.deptInitiative?.id === undefined) {
            setshowModalDisableButton(true);
            setModalData({ message: "Please enter data for Section Department Initiative", title: "Field Required!", okText: "Ok" });
            setOpenModal(true);
        }else if (states.unitInitiative?.id === 0 || states.unitInitiative?.id === undefined) {
            setshowModalDisableButton(true);
            setModalData({ message: "Please select data entry for Unit Initiative", title: " Field Required! ", okText: "Ok" });
            setOpenModal(true);
        }
        else {
            setshowModalDisableButton(false);
            setIcon('question')
            setOpenModal(true);
        }
    }


    //post data
    const postData = async () => {
        try {

            const res = await PostDataFunc(`PfmUnitInitiativeAssignments/CreatePfmUnitInitiativeAssignment`, {

                "uiaPeriodIdfk": states.period?.id,
                "uiaSectionIdfk": states.section?.id,
                "uiaUnitIdfk": states.unit?.id,
                //"uiaCorporateInitiativeIdfk": states.initiative?.id,
                "uiaUnitInitiativeIdfk": states.unitInitiative?.id,
                "uiaActive": states.active,
                "uiaRmks": remarksx != undefined ? remarksx : "",
                "uiaCreatedBy": 1
            })
            console.log(res)

            setIcon('success')
            setOpenModal(true);
            setRefreshValue(!refreshValue)
            dispatch(settask_footer_table_refresh(!refreshValue))
            setshowModalDisableButton(true);
            setModalData({ message: "Data entry saved successfully!", title: "Data Saved!", okText: "Ok" });

        } catch (e: any) {
            console.log(e);
            setIcon('warning')
            setOpenModal(true);
            setshowModalDisableButton(true);
            setModalData({ message: e.response ? e.response.data.message : 'Server Error', title: "Unexpected Error!", okText: "Ok" });
        }

    }

    //update data
    const updateData = async () => {
        //alert('Update data')
        try {

            const res = await UpdateDataFunc(`PfmUnitInitiativeAssignments/UpdatePfmUnitInitiativeAssignment`, {

                "uiaIdpk": selectedRecordx?.uiaIdpk,
                "uiaPeriodIdfk": states.period?.id,
                "uiaSectionIdfk": states.section?.id,
                "uiaUnitIdfk": states.unit?.id,
                "uiaCorporateInitiativeIdfk": states.initiative?.id,
                "uiaUnitInitiativeIdfk": states.unitInitiative?.id,
                "uiaActive": states.active,
                "uiaRmks": remarksx != undefined ? remarksx : "",
                "uiaEditedBy": 1,
                "uiaEditedDate": editedDate
            })

            //setOpenModal(false)
            setIcon('success')
            setOpenModal(true);
            setshowModalDisableButton(false);
            setModalData({ message: "Data entry updated successfully!", title: "Data Updated!", okText: "Ok" });
            console.log(res.data)
            setRefreshValue(!refreshValue)
            dispatch(settask_footer_table_refresh(!refreshValue))
        } catch (e: any) {
            console.log(e);
            setIcon('warning')
            setOpenModal(true);
            setshowModalDisableButton(true);
            setModalData({ message: e.response ? e.response.data.message : 'Server Error', title: "Unexpected Error!", okText: "Ok" });
        }
    }

    useEffect(() => {
        populateFields(false);
    }, [selectedRecordx])


     // settings form Modal
     const [settingModal, setSettingModal] = useState<string>("");
     const [opensettingModal, setOpenSettingModal] = useState<boolean>(false);
 
    const newAccessPriv = () => {
        // dispatch(task_form_disable(true)); 
        dispatch(setGeneralValue({ expr: "formDisabled", value: false }))
        populateFields(true);
    }

    const formModal = [
        {id:0, label: "Corporate Initiative", module: <CI state_change={false} />},
        {id:1, label: "Department Initiative", module: <Perf_setups_deptInitiatives />},
        {id:2, label: "Section Initiative", module: <Perf_setups_secInitiatives/>},
        {id:3, label: "Unit Initiative", module: <Perf_setups_unitInitiatives />}
    ]

    const handleSetting = (label:string) => {
        
        switch(label){
         case "Corporate Initiative":
             updateState("openFormModal", !states.openFormModal)
             updateState("setFormModal", formModal[0].module)
             updateState("setModalTitle", formModal[0].label)
 
             break;
         case "Department Initiative":
             updateState("openFormModal", !states.openFormModal)
             updateState("setFormModal", formModal[1].module)
             updateState("setModalTitle", formModal[1].label)
             break;
         case "Section Initiative":
                updateState("openFormModal", !states.openFormModal)
                updateState("setFormModal", formModal[2].module)
                updateState("setModalTitle", formModal[2].label)
                break;
         case "Unit Initiative":
                updateState("openFormModal", !states.openFormModal)
                updateState("setFormModal", formModal[3].module)
                updateState("setModalTitle", formModal[3].label)
                break;
         default:
             updateState("openFormModal", !states.openFormModal)
        }
     }


     return (
        <div style={{ width: '700px', minWidth: '700px', height: '100%' }} className="dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base  ">
            {/* <h4 className=" w-full  mb-2 bg-slate-100  ">Task Details</h4> */}
            <ModalTemplate icon={icon} cancelText={modalData.cancelText}
                cancelHandler={() => { setOpenModal(false) }} open={openModal} okHandler={() => { modalData === saveModalData ? postData() : modalData === updateModalData ? updateData() : setOpenModal(false) }}
                message={modalData.message} okText={modalData.okText} title={modalData.title} />

                <FormModal
                    openState = {states.openFormModal}

                    child={states.setModalTitle === formModal[0].label ? formModal[0].module : formModal[1].label ? formModal[1].module : formModal[2].label ? formModal[2].module : formModal[3].module} 
                    title={states.setModalTitle}
                />

            <div style={{ maxHeight: '260px', minHeight: '260px', height: '260px' }} className=' items-start justify-start '   >

                <Form
                    className='w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 px-2 '
                    name="basic"
                    labelCol={{ span: 6 }}
                    // wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    size='small'
                    layout='horizontal'
                    disabled={form_disable}

                >
                    <>
                        {selects.map(({ id, label, idExpr, dataExpr, optionsData, stateName, defaultValue, style }) => {
                            return (
                                <SelectsTemplate
                                    idexpr={idExpr}
                                    dataexp={dataExpr}
                                    label={label}
                                    options={optionsData}
                                    placeHolder={defaultValue}
                                    useCallFunc
                                    selectedValue={(e) => { updateState(stateName!, { id: e[idExpr!], name: e[dataExpr!] }) }}
                                    handleSettings={() => {handleSetting(label) }}
                                    disabled={form_disable}
                                    settingsIcon={label === "Corporate Initiative" || label === "Department Initiative" || label === "Section Initiative" || label === "Unit Initiative"}
                                    disabledCustomWidth={true}
                                    cusWidth={style}


                                />
                            )
                        })}
                    </>

                    <div className='w-full  flex flex-row    '>

                        <Form.Item
                            // labelCol={{span:20}}
                            label={<p className='dark:bg-slate-900 dark:text-darkModeSkin-base text-xs'>{"Active?"}</p>}
                            className='w-full'
                        >
                            <div className='w-full  flex  justify-between '>
                                <div>
                                    <Checkbox style={{borderColor:borderTheme}} disabled={form_disable} className='border-[1px] px-1 rounded' onChange={(e) => { updateState('active', e.target.checked) }} checked={states.active} />
                                </div>

                                <div className='mr-16'>
                                    <ul className='flex justify-end   '>
                                        {

                                            (form_disable === false && update_state_change === false) ?
                                                <SaveButton handleSave={() => { setModalData(saveModalData); validationForms(); setOpenModal(true); }} />
                                                :
                                                (update_state_change === true) ?
                                                    <UpdateButton handleUpdate={() => { setModalData(updateModalData); validationForms(); setOpenModal(true); }} />
                                                    :
                                                    <SaveButton />

                                            // useCallbackFunc new_button_toggler={()=>{setform_disable(!form_disable)}}
                                        }

                                        {(form_disable === true) ?
                                            <NewButton new_button_toggler={() => { newAccessPriv() }} />

                                            :
                                            (update_state_change === true) ?
                                                <Cancel cancel_button_toggler={() => { }} />
                                                :
                                                !(update_state_change && form_disable) ?
                                                    <Cancel cancel_button_toggler={() => { }} />

                                                    :
                                                    <NewButton new_button_toggler={() => { newAccessPriv() }} />
                                        }
                                    </ul>
                                </div>
                            </div>
                        </Form.Item>
                    </div>
                </Form>
            </div >
        </div >
    )
}