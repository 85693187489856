import { datagridColumnTypes } from "../../../../../../human-resource/setups/data/types/selectsTypes";

export const attendantsMainGridColumns: datagridColumnTypes[] = [
  {
    id: 0,
    caption: "ID",
    dataField: "faaIDpk",
    dataType: "number",
    width: 80,
    fixed: true,
    allowEditing: false,
    visible: false,
  },
  {
    id: 1,
    caption: "STAFF NO",
    dataField: "empStaffNo",
    dataType: "string",
    fixed: true,
    allowEditing: false,
    visible: true,
  },
  {
    id: 2,
    caption: "ATTENDANT",
    dataField: "empName",
    dataType: "string",
    width: 160,
    fixed: true,
    allowEditing: false,
    visible: true,
  },
  {
    id: 3,
    caption: "WORKSHOP",
    dataField: "wsdName",
    dataType: "string",
    width: 130,
    fixed: true,
    allowEditing: false,
    visible: true,
  },
  {
    id: 4,
    caption: "ATTENDANT LOC",
    dataField: "locShtName",
    dataType: "string",
    width: 130,
    fixed: false,
    allowEditing: false,
    visible: true,
  },
  {
    id: 5,
    caption: "CANCEL REQ?",
    dataField: "waaCancelRequests",
    dataType: "boolean",
    fixed: false,
    allowEditing: true,
    visible: true,
  },
  {
    id: 6,
    caption: "SCHED ALERTS?",
    dataField: "waaScheduleAlerts",
    dataType: "boolean",
    fixed: false,
    allowEditing: true,
    visible: true,
  },
  {
    id: 7,
    caption: "OPEN JOB CARDS?",
    dataField: "waaOpenJobCards",
    dataType: "boolean",
    fixed: false,
    allowEditing: true,
    visible: true,
  },
  {
    id: 8,
    caption: "MNG JOB DETAILS?",
    dataField: "waaManageJobDetails",
    dataType: "boolean",
    fixed: false,
    allowEditing: true,
    visible: true,
  },
  {
    id: 9,
    caption: "MNG MTRLS?",
    dataField: "waaManageMaterials",
    dataType: "boolean",
    fixed: false,
    allowEditing: true,
    visible: true,
  },
  {
    id: 10,
    caption: "MNG WORK ORDERS?",
    dataField: "waaManageWorkOrders",
    dataType: "boolean",
    fixed: false,
    allowEditing: true,
    visible: true,
  },
  {
    id: 11,
    caption: "MNG INVOICES",
    dataField: "waaManageInvoices",
    dataType: "boolean",
    fixed: false,
    allowEditing: true,
    visible: true,
  },
  {
    id: 12,
    caption: "CMASU",
    dataField: "waaCMASU",
    dataType: "boolean",
    fixed: false,
    allowEditing: true,
    visible: true,
  },
  {
    id: 13,
    caption: "MNG UPDATES",
    dataField: "waaManageUpdates",
    dataType: "boolean",
    fixed: false,
    allowEditing: true,
    visible: true,
  },
  {
    id: 14,
    caption: "APRV RQSTS?",
    dataField: "waaApproveRequests",
    dataType: "boolean",
    fixed: false,
    allowEditing: true,
    visible: true,
  },
  {
    id: 15,
    caption: "SEE STATS?",
    dataField: "waaSeeStatistics",
    dataType: "boolean",
    fixed: false,
    allowEditing: true,
    visible: true,
  },
  {
    id: 16,
    caption: "GET SMS?",
    dataField: "waaGetSms",
    dataType: "boolean",
    fixed: false,
    allowEditing: true,
    visible: true,
  },
  {
    id: 17,
    caption: "VIEW?",
    dataField: "waaView",
    dataType: "boolean",
    fixed: false,
    allowEditing: true,
    visible: true,
  },
  {
    id: 18,
    caption: "DATE ASSIGNED",
    dataField: "waaCreationDate",
    dataType: "string",
    width: null,
    fixed: false,
    allowEditing: false,
    visible: true,
  },
  {
    id: 19,
    caption: "DATE UNASSIGNED",
    dataField: "waaEditedDate",
    dataType: "string",
    width: null,
    fixed: false,
    allowEditing: false,
    visible: true,
  },
  {
    id: 20,
    caption: "ACTIVE",
    dataField: "waaActive",
    dataType: "boolean",
    width: 60,
    fixed: false,
    allowEditing: false,
    visible: true,
  },
  {
    id: 21,
    caption: "REMARKS",
    dataField: "waaRmmks",
    dataType: "string",
    width: 4000,
    fixed: false,
    allowEditing: false,
    visible: true,
  },
];

export const staffsColumns = [
  {
    id: 0,
    caption: "ID",
    dataField: "empIDpk",
    datatype: "number",
    width: 80,
    fixed: true,
    visible: false,
  },
  {
    id: 1,
    caption: "Staff No",
    dataField: "empStaffNo",
    datatype: "string",
    width: 90,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "Staff Name",
    dataField: "empName",
    datatype: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 3,
    caption: "Dept",
    dataField: "dptShtName",
    datatype: "string",
    width: 110,
    fixed: false,
    visible: true,
  },
  {
    id: 4,
    caption: "Section",
    dataField: "sxnShtName",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 5,
    caption: "Location",
    dataField: "locShtName",
    datatype: "string",
    width: 2000,
    fixed: false,
    visible: true,
  },
];

export const workshopsColumns = [
  {
    id: 0,
    caption: "No",
    dataField: "wsdIDpk",
    datatype: "number",
    width: 40,
    fixed: true,
    visible: true,
  },
  {
    id: 1,
    caption: "Workshop",
    dataField: "name",
    datatype: "string",
    width: 140,
    fixed: true,
    allowEditing: false,
    visible: true,
  },
  {
    id: 2,
    caption: "Cancel Rqst",
    dataField: "waaCancelRequests",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 3,
    caption: "Sched. Alerts",
    dataField: "waaScheduleAlerts",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 4,
    caption: "Open Job Cards",
    dataField: "waaOpenJobCards",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },

  {
    id: 5,
    caption: "Mng Job Details",
    dataField: "waaManageJobDetails",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 6,
    caption: "Mng Mtrls",
    dataField: "waaManageMaterials",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 7,
    caption: "Mng Wrk Ord",
    dataField: "waaManageWorkOrders",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 8,
    caption: "Mng Invoices",
    dataField: "waaManageInvoices",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 9,
    caption: "CMASU",
    dataField: "waaCMASU",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 10,
    caption: "Mng Updates",
    dataField: "waaManageUpdates",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },

  {
    id: 11,
    caption: "Aprv Rqsts?",
    dataField: "waaApproveRequests",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 12,
    caption: "See Stats",
    dataField: "waaSeeStatistics",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 13,
    caption: "Get SMS",
    dataField: "waaGetSms",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 14,
    caption: "View",
    dataField: "waaView",
    datatype: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 15,
    caption: "Remarks",
    dataField: "waaRmks",
    datatype: "string",
    width: 2000,
    fixed: false,
    visible: true,
  },
];
