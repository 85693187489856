import { Checkbox, DatePicker, Form, Input, Select } from "antd";
import reset from '../../../../../assets/reset.png';
import TextArea from "antd/es/input/TextArea";
import { BottomBtns } from "../../../../../templates/butttons";
import { CheckboxTemlate } from "../../../../../templates/checkbox";
import { DateTemplate } from "../../../../../templates/date";
import { InputsTemplate } from "../../../../../templates/input";
import { TextareaTemplate } from "../../../../../templates/textarea";
import SetupsDatalistTemplat from "../../../../human-resource/setups/data/setups_datalist_template";
import { modalPropTypes, modalTypes, selectsTypes, validateInstanceTypes } from "../../../../human-resource/setups/data/types/selectsTypes";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs, { Dayjs } from "dayjs";
import { setGeneralValue } from "../../../../../features/generalSlice";
import axios from "axios";
import { api_url, saveModalData, updateModalData } from "../../../../accessories/component_infos";
import { ModalTemplate } from "../../../../../templates/modal";
import { useValidateExist } from "../../../../../hooks/useValidateExists";
import { UpdateDataFunc } from "../../../../../functions/update";
import { PostDataFunc  } from "../../../../../functions/post";
import { useAccountInfo } from "../../../../../hooks/useAccountInfo";
import { RootState } from "../../../../../app/store";

interface props{
    busyloader:(text?:string)=>void,
    refreshGrid:()=>void
    clearSelected:()=>void
    selectedField:any
    data:any
}
interface stateTypes{
    name:string,
    shortName:string,
    startDate:string,
    endDate:string,
    order:number,
    opened:boolean,
    active:boolean,
    remarks:string

    selectedRecordId:number,
    confirmModal:modalPropTypes
    warningModal:modalPropTypes
    successModal:modalPropTypes
}

export const Periods_form = ({busyloader,clearSelected,refreshGrid,data,selectedField}:props) => {
    const validateExist = useValidateExist();
    const [employeeId, userId] = useAccountInfo();
    const borderTheme = useSelector((state:RootState)=>state.general.cssBorderClass);
    
    const postUrl:string = 'Periods/CreatePeriod';
    const updateUrl:string = 'Periods/UpdatePeriod';

    useEffect(()=>{
        populateStatesFromGrid(false);
    },[selectedField])

    const dispatch = useDispatch();

    const[states,setStates]=useState<stateTypes>({
        name:'',
        shortName:'',
        startDate:dayjs(new Date()).format(),
        endDate: dayjs(new Date()).format(),
        order:0,
        opened:false,
        active:false,
        remarks:'',

        selectedRecordId:0,

        confirmModal:{state:false,title:'',message:'',action:'post'},
        warningModal:{state:false,title:'',message:''},
        successModal:{state:false,title:'',message:''}
    })

    const{name,shortName,startDate,endDate,opened,selectedRecordId,order,active,remarks,confirmModal,warningModal,successModal}=states;

    const updateState=(stateName:string,value:string|modalPropTypes|boolean|number):void=>{ //update single state
        setStates((prev)=>({...prev,[stateName]:value}));
    }

    const disableConfirmModal=()=>{
        updateState('confirmModal',{
            state:false,
            title:'',
            message:'',
            action:'post'
        })
    }

    const enableSuccessModal=(titleActionText:string,messageActionText:string)=>{
        updateState('successModal',{
            state:true,
            title:`${titleActionText} Successful`,
            message:`Record ${messageActionText} successfully`,
        })
    }

    //validate fields
    const validateFields=():boolean=>{
        const validationInstances:validateInstanceTypes[]=[
            {state:name,baseValue:'',modalProps:{state:true,title:'Enter name',message:'Please enter a name'}},
            {state:shortName,baseValue:'',modalProps:{state:true,title:'Enter shortname',message:'Please enter a short name'}},
            // {state:weight,baseValue:0,modalProps:{state:true,title:'Enter % weight',message:'Please enter % weight'}},
        ]

        for(let instance of validationInstances) if (instance.state===instance.baseValue) {
            const{modalProps}=instance;
            updateState('warningModal',{
                state:modalProps.state,
                title:modalProps.title,
                message:modalProps.message
            })
            return false;
        }
        return true;
    }

    const disableFields=()=>{
        //disable Update Mode
        dispatch(setGeneralValue({expr: 'updateMode' ,value : false}));

        //disable forms
        dispatch(setGeneralValue({expr: 'formDisabled' ,value : true}));
    }

    const populateStatesFromGrid=(clearFields?:boolean)=>{
        const run=()=>{
            const populateInstances=[
                {state:'name',value:clearFields?'':selectedField?.prdName},
                {state:'shortName',value:clearFields?'':selectedField?.prdShtName},
                {state:'startDate',value:clearFields?dayjs(new Date()).format():selectedField?.prdStartDate},
                {state:'endDate',value:clearFields?dayjs(new Date()).format():selectedField?.prdEndDate},
                {state:'order',value:clearFields?0:selectedField?.prdOrder},
                {state:'opened',value:clearFields?false:selectedField?.prdOpened},
                {state:'active',value:clearFields?true:selectedField?.prdActive},
                {state:'remarks',value:clearFields?'':selectedField?.prdRmks},
                {state:'selectedRecordId',value:clearFields?0:selectedField?.prdIdpk},
            ]
    
            for(let instance of populateInstances) updateState(instance.state,instance.value);
        }
        Object.keys(selectedField).length !== 0 && run();
    }

    //post data
    const postData=async(action:'validate'|'post')=>{
        if (action==='validate') {
            // validate fieldds
            validateFields() && await validateExist(`Periods/GetNameShortName?name=${name}&shortname=${shortName}`) &&
            updateState('confirmModal',{
                state:true,
                title:'Save Record',
                message:'Are you sure you want to save this record?',
                action:'post'
            })
            return;
        }

        //post
        disableConfirmModal();
        busyloader(saveModalData.progress)
        try {
            const postResponse = await PostDataFunc (`${postUrl}`,{
                "prdName": name,
                "prdShtName": shortName,
                "prdStartDate": startDate,
                "prdEndDate": endDate,
                "prdOpened": opened,
                "prdOrder":order,
                "prdActive": active,
                "PrdRmks": remarks,
                "prdCreatedBy": userId.toString(),
            })
            postResponse.status===202 && enableSuccessModal('Save','saved')
            disableFields();
            refreshGrid();
            clearSelected()
            populateStatesFromGrid(false);
        } catch (error) {
            console.log(error);
            
            updateState('warningModal',{
                state: true,
                title: saveModalData.error.title,
                message: saveModalData.error.message
            })
        } finally{
            busyloader('');
        }
        return;
    }


    //update data
    const update=async(action:'validate'|'update')=>{
        console.log(states);
        
        if (action==='validate') {
            // validate fieldds
            validateFields() && 
            await validateExist(`Periods/GetNameShortName?name=${name}&shortname=${shortName}`,selectedRecordId,'prdIdpk') &&
            updateState('confirmModal',{
                state:true,
                title:'Update Record',
                message:'Are you sure you want to update this record?',
                action:'update'
            })
            return;
        }

        //update
        disableConfirmModal();
        busyloader(updateModalData.progress);

        try {
            const updateResponse = await UpdateDataFunc(`${updateUrl}/${selectedRecordId}`,{
                // "prdIdpk": selectedRecordId,
                "prdName": name,
                "prdShtName": shortName,
                "prdStartDate": startDate,
                "prdEndDate": endDate,
                "prdOpened": opened,
                "prdOrder":order,
                "prdActive": active,
                "prdRmks": remarks,
                "prdEditedBy": userId,
                "prdEditedDate": dayjs().format()         
            })
            enableSuccessModal('Update','updated')
            disableFields();
            refreshGrid();
            clearSelected();
            populateStatesFromGrid(false);

        } catch (error) {
            console.log(error);

            updateState('warningModal',{
                state: true,
                title: updateModalData.error.title,
                message: updateModalData.error.message
            })
        } finally{
            busyloader('');
        }
        return;
    }

    
    const datalists: selectsTypes[] = [
        {id: 0 , label : 'Name' ,optionsData : data, stateName: 'name', idExpr: 'prdIdpk', dataExpr:'prdName',defaultValue:name},
        {id: 1 , label : 'Short Name' ,optionsData : data, stateName: 'shortName', idExpr: 'prdIdpk', dataExpr:'prdShtName',defaultValue:shortName},
    ]


    // modal instances
    const modals:modalTypes[] = [
        //validation
        {disableCancel : true , icon : 'warning' ,open : warningModal.state, okHandler : ()=>updateState('warningModal',{state:false,title:'',message:''}), cancelHandler : ()=> updateState('warningModal',{state:false,title:'string',message:'string'}), message : warningModal.message, okText : 'Ok', title : warningModal.title,cancelText : 'No, cancel'},
        
        //confirmation
        {disableCancel : false , icon : 'question' ,open : confirmModal.state, okHandler : confirmModal.action==='update'?()=>update('update'):()=>postData('post'), cancelHandler : ()=> updateState('confirmModal',{state:false,title:'string',message:'string'}), message : states.confirmModal.message, okText : confirmModal.action==='update' ? 'Yes, Update':'Yes, Save', title : states.confirmModal.title,cancelText : 'No, cancel'},

        //success
        {disableCancel : true , icon : 'success' ,open : successModal.state, okHandler : ()=>updateState('successModal',{state:false,title:'',message:''}), cancelHandler : ()=> updateState('successModal',{state:false,title:'string',message:'string'}), message : successModal.message, okText : 'Ok', title : successModal.title,cancelText : 'No, cancel'},
    ]

    return (
        <>
        {modals.map(({disableCancel,icon,okHandler,cancelHandler,open,cancelText,okText,message,title}:modalTypes,index)=>{
            return(
                <div key = {index}>
                    <ModalTemplate  icon= {icon && icon} disableCancel = {disableCancel} cancelText = {cancelText && cancelText} open = {open} okHandler={okHandler} cancelHandler={cancelHandler} message= {message} okText= {okText} title={title}/>
                </div>
            )
        })}


        <div className="px-2">
            {/* FORM HEADER */}
            <p id={"form_header"} style={{borderColor: borderTheme}} className='pt-1 px-2 border-[1px] border-b-0 font-semibold w-full bg-slate-100'>{'Period Details'}</p>
            <Form
                style={{borderColor: borderTheme}}
                className={`w-full ${borderTheme ? 'border-[1px]' : 'border-2'} border-slate-100 rounded flex justify-between py-1 px-2 `}
                name="basic"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 19 }}
                initialValues={{ remember: true }}
                autoComplete="off"
                size='small'
            >
                <section  className="" style={{width: '500px'}} >
                    {/* datalists */}
                    {datalists.map((datalist, index)=>{
                        return(
                            <div key={index} className = {datalist.id === 1 ? 'mb-1' : ' mb-1'}>
                                <SetupsDatalistTemplat
                                    datalistStyle={{height:24}} 
                                    useCallbackFunc
                                    selectedData={datalist.defaultValue}
                                    setCallbackValue={(value)=>{
                                        updateState(datalist.stateName!,value)

                                        datalist.stateName === 'name' && updateState('shortName', value);
                                    }}
                                    listid={datalist.label}
                                    idExpr={datalist.idExpr}
                                    nameExpr={datalist.dataExpr}
                                    label = {datalist.label} 
                                    options = {datalist.optionsData}
                                />
                            </div>
                        )
                    })}

                    {/* dates */}
                    <Form.Item className="" label={<p className='text-xs'>{'Start & End Dates'}</p>} style={{ marginBottom: 0 }}>
                        <DateTemplate
                            selectedDate={dayjs(startDate)} showTime style= {{ display: 'inline-block', width: 'calc(50% - 2px)', paddingRight : '5px' }}
                            changeDate={(e:any)=>{updateState('startDate',dayjs(e).format())}}
                        />
                        <DateTemplate  
                            selectedDate={dayjs(endDate)} showTime style= {{ display: 'inline-block', width: 'calc(50% )',marginBottom:2 }}
                            changeDate={(e:any)=>{updateState('endDate',dayjs(e).format())}}
                        />
                    </Form.Item>

                    <div className=" mb-1">
                        <InputsTemplate 
                        useCallbackFunc
                        defaultValue={order}
                        orderOnchange={(e)=>{
                            updateState('order',e)
                        }}
                        inputStyle={{height:24}}
                        inputType='number'
                        span
                        label = {'Order'}/>
                    </div>

                    <div className=" mb-1">
                        <CheckboxTemlate 
                        label="opened?"
                        withBorders
                        defaultValue={opened}
                        useCallbackFunc
                        setCheckboxOnchange={(checkValue)=>{
                            updateState('opened',checkValue)
                        }}
                        />
                    </div>

                    {/* checkbox and butons */}
                    <div className = ''>
                        <BottomBtns
                        addActiveBorders
                        defaultValue={active}
                        onNewClick={()=>{
                            populateStatesFromGrid(true)
                        }}
                        saveHandler={()=>postData('validate')}
                        updateHandler={()=>update('validate')}
                        useCallbackFunc
                        checkboxChange={(value)=>updateState('active',value)}
                        span
                        />
                    </div>
                </section>

                {/* remarks */}
                <section className=" " style={{width : 'calc(100% - 550px)'}}>
                    <TextareaTemplate 
                        useCallbackFunc
                        setCallbackValue={(value)=>{
                            updateState('remarks',value);
                        }}
                        height = {170} 
                        defaultValue={remarks}
                        label = {'Remarks'}
                    />
                </section>
            </Form>
        </div>
        </>
    );  
}
 