import { datalistTypes } from "../../../../../human-resource/setups/data/types/selectsTypes";
import Datalist_select_template from "./template/Datalist_template";


// FOR MEASURE

export const Measure_Details_Select = () => {

    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';
    const datalist_selects:datalistTypes[] = [
        {id: 0, label : 'Name', optionsData : [{id : 0, data : 'option1'},{id : 0.1, data : 'option1_2'}]},
        {id: 1, label : 'Short Name', optionsData : [{id : 1, data : 'option2'}]},
        
    ]

    const labelCol = 7

    // const [data,error,loading] = useFetch(`api/HrEmp/FormAssignmentCategories`);
    // if (error) {
    //     console.log(error);
    // }
    return (
        <>
            <Datalist_select_template   datalist_selects={datalist_selects}   />
        </>
    );
}


// FOR TARGET

export const Target_Details_Select = () => {

    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';
    const datalist_selects:datalistTypes[] = [
        {id: 0, label : 'Name', optionsData : [{id : 0, data : 'option1'},{id : 0.1, data : 'option1_2'}]},
        {id: 1, label : 'Short Name', optionsData : [{id : 1, data : 'option2'}]},
        
    ]

    const labelCol = 7

    // const [data,error,loading] = useFetch(`api/HrEmp/FormAssignmentCategories`);
    // if (error) {
    //     console.log(error);
    // }
    return (
        <>
            <Datalist_select_template   datalist_selects={datalist_selects}   />
        </>
    );
}


// FOR PERSPECTIVE

export const Perspective_Details_Select = () => {

    // const label = 'Group';
    // const idExpr = 'ascIdpk';
    // const dataExpr = 'ascName';
    const datalist_selects:datalistTypes[] = [
        {id: 0, label : 'Name', optionsData : [{id : 0, data : 'option1'},{id : 0.1, data : 'option1_2'}]},
        {id: 1, label : 'Short Name', optionsData : [{id : 1, data : 'option2'}]},
        
    ]

    const labelCol = 7

    // const [data,error,loading] = useFetch(`api/HrEmp/FormAssignmentCategories`);
    // if (error) {
    //     console.log(error);
    // }
    return (
        <>
            <Datalist_select_template   datalist_selects={datalist_selects}   />
        </>
    );
}