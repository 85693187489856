export const outagecol = [
  {
    id: 1,
    caption: "NAME",
    dataField: "name",
    dataType: "string",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "SHORT NAME",
    dataField: "shortName",
    dataType: "string",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 3,
    caption: "ACTIVE?",
    dataField: "active",
    dataType: "boolean",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 4,
    caption: "AUTO?",
    dataField: "auto",
    dataType: "boolean",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 5,
    caption: "PLANNED",
    dataField: "planned",
    dataType: "boolean",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 6,
    caption: "REMARKS",
    dataField: "exnName",
    dataType: "string",
    width: 200,
    fixed: true,
    visible: true,
  },
 
];
