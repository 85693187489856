import React, {
    useState,
    useCallback,
    useRef,
    forwardRef,
    useEffect,
    useImperativeHandle,
  } from "react";
  import { Form, Input, Select, DatePicker, Checkbox } from "antd";
  import { CheckBox } from "devextreme-react/check-box";
  import refresh from "../../../../../assets/refresh.png";
  import dayjs from "dayjs";
  
  import {
    SaveButton,
    NewButton,
    UpdateButton,
    Cancel,
  } from "../../../../accessories/buttons";
  import {
    CIA_Details_Select,
    CI_Details_Select,
    Objective_Details_Select,
  } from "../../../data/Performance_selects";
  import {
    CI_TextArea,
    Objective_Input,
    Objective_mini_TextArea,
    Objective_TextArea,
  } from "../../../data/Performance_inputs";
  import axios from "axios";
  import {
    api_url,
    saveModalData,
    updateModalData,
  } from "../../../../accessories/component_infos";
  import TextArea from "antd/es/input/TextArea";
  import { ModalTemplate } from "../../../../../templates/modal";
  import { useDispatch, useSelector } from "react-redux";
  import { RootState } from "../../../../../app/store";
  import { setGeneralValue } from "../../../../../features/generalSlice";
  import {
    task_footer_update,
    task_form_disable,
  } from "../../../../../features/Task&Todo/Task/TaskDetails";
  import { TextareaTemplate } from "../../../../../templates/textarea";
  import { GetDataFunc } from "../../../../../functions/get";
  
  var utc = require("dayjs/plugin/utc");
  
  interface props {
    toggleRefresh: any;
  }
  
  export const Objective_Details_Form = forwardRef(
    ({ toggleRefresh }: props, ref: any) => {
      const options = [];
      for (let i = 1; i < 36; i++) {
        options.push({
          label: i.toString(36) + i,
          value: i.toString(36) + i,
        });
      }
  
      const borderTheme = useSelector(
        (state: RootState) => state.general.cssBorderClass
      );
  
      /**
       **************************************
       **************************************
       ***********Api Integrations***********
       **************************************
       **************************************
       */
  
      
  
      // init states
      //Hidden immutable IDS
      const [immutableIDS, setImmutableIDS] = useState<any>({});
      //Mutable states
      const [missionVisionData, setMissionVisionData] = useState([]);
      const [perspectiveData, setPerspectiveData] = useState([]);
      const [themesData, setThemesData] = useState([]);
      const [strategicObjectives, setStrategicObjectives] = useState("");
      const [shortName, setShortName] = useState("");
      const [description, setDescription] = useState("");
      const [outcome, setOutcome] = useState("");
      const [remarks, setRemarks] = useState("");
      const [order, setOrder] = useState("");
      const [active, setActive] = useState(false);
      const [disableForms, setDisableForms] = useState(true);
      const [missionVisionSelected, setMissionVisionSelected] = useState<any>({
        name: "",
        id: "",
      });
      const [perspectiveSelected, setPerspectiveSelected] = useState<any>({
        name: "",
        id: "",
      });
      const [themesSelected, setThemesSelected] = useState<any>({
        name: "",
        id: "",
      });
      const [refreshValue, setRefreshValue] = useState(false);
      const [showModalDisableButton, setShowModalDisableButton] = useState(false);
      const [openModal, setOpenModal] = useState(false);
      const [modalData, setModalData] = useState<any>(saveModalData);
      const [icon, setIcon] = useState<"warning" | "success" | "question">();
      const [updatingStatus, setUpdatingStatus] = useState(false);
      const [showCancel, setShowCancel] = useState(false);
      const [refreshState, setRefreshState] = useState(false);
      const [themeMembersData, setThemeMembersData] = useState<any>({
          theme: { name: "", id: 0 },
        });
  
      async function fetchMissionVision() {
          try {
            // get data
            const res = await GetDataFunc(
              `PfmMissionVision/GetAllPfmMissionVision?search=&active=`
            );
            setMissionVisionData(res.data.reverse()); // set data
          } catch (error: any) {
            console.log(error);
          } finally {
          }
        }
    
        async function fetchPerspective() {
          try {
            // get data
            const res = await GetDataFunc(`PfmObjectives/GetLuePfmPespectives`);
            setPerspectiveData(res.data.reverse()); // set data
          } catch (error: any) {
            console.log(error);
          }
        }
    
        async function fetchThemes(id: number) {
          try {
            // get data
            const res = await GetDataFunc(`PfmObjectives/GetLuePfmThemes?missionId=${id}`);
            setThemesData(res.data.reverse()); // set data
          } catch (error: any) {
            console.log(error);
          } finally {
          }
        }
  
      useEffect(() => {
        fetchMissionVision();
        fetchPerspective();
      }, [refreshValue]);
  
      //Run functions from table
      useImperativeHandle(ref, () => ({
        doubleClick: () => {
          setDisableForms(false);
          setUpdatingStatus(true);
          setShowCancel(true);
        },
        singleClick: (selectedRow: any) => {
          console.log("eee");
          console.log(selectedRow);
          // setMissionVisionData(selectedRow.mvsMission);
          // setPerspectiveData(selectedRow.mvsPtvName);
          // setThemesData(selectedRow.thmName)
          setImmutableIDS({
            objIDpk: selectedRow.objIDpk,
            objOrder: selectedRow.objOrder,
          });
          setStrategicObjectives(selectedRow.objName);
          setShortName(selectedRow.objShtName);
          setDescription(selectedRow.objDescription);
          setOutcome(selectedRow.objOutcome);
          setRemarks(selectedRow.objRmks);
          setActive(selectedRow.objActive);
          setOrder(selectedRow.objOrder);
          setMissionVisionSelected({
            name: selectedRow.mvsMission,
            id: selectedRow.mvsIDpk,
          });
          setPerspectiveSelected({
            name: selectedRow.ptvName,
            id: selectedRow.ptvIDpk,
          });
          setThemesSelected({
            name: selectedRow.thmName,
            id: selectedRow.thmIDpk,
          });
          setDisableForms(true);
          setUpdatingStatus(false);
          setShowCancel(false);
        },
      }));
  
      //Function Calls
      const updateData = async () => {
        console.log("here");
  
        const del = await axios.put(
          `${api_url}/api/PfmObjectives/UpdatePfmObjective`,
          {
            objIdpk: immutableIDS.objIDpk,
            objMissionIdfk: missionVisionSelected.id,
            objThemeIdfk: themesSelected.id,
            objPerspectiveIdfk: perspectiveSelected.id,
            objName: strategicObjectives,
            objShtName: shortName,
            objDescription: description,
            objOutcome: outcome,
            objOrder: order,
            objActive: active,
            objRmks: remarks,
            objEditedBy: 1,
            objEditedDate: `${dayjs().format("YYYY-MM-DDTHH:mm:ss[Z]")}`,
          }
        );
        //close modal
        setOpenModal(false);
        //Refresh table
        setShowModalDisableButton(true);
        setIcon("success");
        setModalData({
          message: "Record successfully updated",
          title: "Record updated",
          okText: "Ok",
        });
        setOpenModal(true);
        toggleRefresh();
      };
      const postData = async () => {
        // dispatch(setValue({expr: 'saveDataOnclick' ,value : false})) //disable modal
        try {
          console.log({
            objMissionIdfk: missionVisionSelected.id,
            objThemeIdfk: themesSelected.id,
            objPerspectiveIdfk: perspectiveSelected.id,
            objName: strategicObjectives,
            objShtName: shortName,
            objDescription: description,
            objOutcome: outcome,
            objOrder: order,
            objActive: active,
            objRmks: remarks,
            objCreatedBy: 0,
          });
  
          const res = await axios.post(
            `${api_url}/api/PfmObjectives/CreatePfmObjective`,
            {
              dto: {
                objMissionIdfk: missionVisionSelected.id,
                objThemeIdfk: themesSelected.id,
                objPerspectiveIdfk: perspectiveSelected.id,
                objName: strategicObjectives,
                objShtName: shortName,
                objDescription: description,
                objOutcome: outcome,
                objOrder: order,
                objActive: active,
                objRmks: remarks,
                objCreatedBy: 1,
              },
            }
          );
  
          setOpenModal(false);
          setShowModalDisableButton(true);
          setIcon("success");
          setModalData({
            message: "Record successfully saved",
            title: "Record saved",
            okText: "Ok",
          });
          setOpenModal(true);
          toggleRefresh();
  
          // const exprs:exprsTypes[] = [{expr: 'saveDataOnclick' ,value : false},{expr: 'successModalState' ,value : true},{expr: 'successText' ,value : saveSuccessText}];
          // for (let i = 0; i < exprs.length; i++) {
          //     dispatch(setValue({expr: exprs[i].expr ,value : exprs[i].value}));
          // }
        } catch (error: any) {
          console.log(error);
          //set states for db errors modal
          // setErrState(true);
          // seterrTitle(error.response ? error.response.data.message : 'Server Error');
          // seterrMessage(error.response ? error.response.data.errors[0] : 'Error connecting to server. Please contact system administrator');
        }
      };
      const clearFields = async () => {
        setMissionVisionSelected({ name: "", id: "" });
        setPerspectiveSelected({ name: "", id: "" });
        setThemesSelected({ name: "", id: "" });
        setStrategicObjectives("");
        setShortName("");
        setDescription("");
        setOutcome("");
        setOrder("");
        setShortName("");
  
        setRemarks("");
        setActive(true);
      };
  
      const validateForms = async () => {
        setIcon("warning");
        if (missionVisionSelected.id === "") {
          setShowModalDisableButton(true);
          setModalData({
            message: "Please select a Mission/Vision",
            title: "Select Mission/Vision",
            okText: "Ok",
          });
          setOpenModal(true);
        } else if (perspectiveSelected.id === "") {
          setShowModalDisableButton(true);
          setModalData({
            message: "Please select a Perspective",
            title: "Select Perspective",
            okText: "Ok",
          });
          setOpenModal(true);
        } else if (themesSelected.id === "") {
          setShowModalDisableButton(true);
          setModalData({
            message: "Please enter a Strategic Theme",
            title: "Enter Strategic Theme",
            okText: "Ok",
          });
          setOpenModal(true);
        } else if (strategicObjectives === "") {
          setShowModalDisableButton(true);
          setModalData({
            message: "Please enter a Strategic Objective",
            title: "Enter Strategic Objective",
            okText: "Ok",
          });
          setOpenModal(true);
        } else if (shortName === "") {
          setShowModalDisableButton(true);
          setModalData({
            message: "Please enter a Short Name",
            title: "Enter Short Name",
            okText: "Ok",
          });
          setOpenModal(true);
        } else if (order === "") {
          setShowModalDisableButton(true);
          setModalData({
            message: "Please enter a Order",
            title: "Enter Order",
            okText: "Ok",
          });
          setOpenModal(true);
        } else {
          setIcon("question");
          setShowModalDisableButton(false);
          setOpenModal(true);
        }
      };
  
      const disablex = useSelector(
        (state: RootState) => state.addTask.taskFormStatus
      );
      const updateMode = useSelector(
        (state: RootState) => state.addTask.taskFooterUpdate
      );
      const dispatch = useDispatch();
  
      useEffect(() => {
        if (!disablex) {
          setShowCancel(!disablex);
  
          setShowCancel(true);
          setDisableForms(false);
          clearFields();
  
          dispatch(task_form_disable(false));
  
          //  this destroys the update mode state
          dispatch(task_footer_update(false));
        } else {
          setShowCancel(false);
          setDisableForms(true);
          setUpdatingStatus(false);
        }
        if (updateMode) {
          setShowCancel(true);
          setUpdatingStatus(true);
          setDisableForms(false);
        }
      }, [disablex, updateMode]);
  
      return (
        <div
          style={{ width: "100%", height: "100%", borderColor: borderTheme }}
          className="  border-[1px]   dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base  "
        >
          <ModalTemplate
            icon={icon}
            cancelText={modalData.cancelText}
            cancelHandler={() => {
              setOpenModal(false);
            }}
            disableCancel={showModalDisableButton}
            open={openModal}
            okHandler={() => {
              showModalDisableButton === true
                ? setOpenModal(false)
                : updatingStatus === true
                ? updateData()
                : postData();
            }}
            message={modalData.message}
            okText={modalData.okText}
            title={modalData.title}
          />
          {/* <h4 className=" w-full  mb-2 bg-slate-100  ">Task Details</h4> */}
          <div
            style={{
              maxHeight: "350px",
              minHeight: "350px",
              height: "350px",
              width: "100%",
            }}
            className=" items-start justify-start "
          >
            <Form
              className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 px-2 "
              name="basic"
              // labelCol={{ span: 6}}
              // wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              autoComplete="off"
              size="small"
              layout="horizontal"
            >
              <Form.Item
                label={
                  <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                    {"Mission/Vision"}
                  </p>
                }
                className={" mb-1"}
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 6 }}
              >
                <div className="flex flex-row items-center">
                  <Select
                    onChange={(value: any) => {}}
                    className="w-full mr-0"
                    placeholder=""
                    allowClear={false}
                    value={missionVisionSelected.name}
                    onSelect={(e) => {
                      console.log(e);
                      const value = JSON.parse(e);
                      setMissionVisionSelected({
                        name: value["mvsName"],
                        id: value["mvsIdpk"],
                      });
                      // console.log(value[0]['mvsIdpk']);
                      fetchThemes(value["mvsIdpk"]);
                    }}
                    disabled={disableForms}
                  >
                    {missionVisionData!.map((option: any, index: number) => {
                      return (
                        <option key={index} value={JSON.stringify(option)}>
                          {option["mvsName"]}
                        </option>
                      );
                    })}
                  </Select>
  
                  <span
                    onClick={() => {
                      setMissionVisionSelected({ id: "", name: "" });
                    }}
                    style={{
                      height: 22.5,
                      marginBottom: 2,
                      borderWidth: 1,
                      width: 23.9,
                      borderColor: borderTheme,
                    }}
                    className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                  >
                    <img
                      className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                      src={refresh}
                      alt="reset"
                    />
                    {/* <img className='' src= {''} alt="" /> */}
                  </span>
                </div>
              </Form.Item>
              <Form.Item
                label={
                  <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                    {"Perspective"}
                  </p>
                }
                className={" mb-1"}
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 6 }}
              >
                <div className="flex flex-row items-center">
                  <Select
                    onChange={(value: any) => {}}
                    className="w-full mr-0"
                    placeholder=""
                    allowClear={false}
                    value={perspectiveSelected.name}
                    onSelect={(e) => {
                      const value = perspectiveData.filter(
                        (data: any) => data["name"] === e
                      );
                      setPerspectiveSelected({
                        name: value[0]["name"],
                        id: value[0]["id"],
                      });
                      console.log(value);
                    }}
                    disabled={disableForms}
                  >
                    {perspectiveData.map((option: any, index: number) => {
                      return (
                        <option key={index} value={option["name"]}>
                          {option["name"]}
                        </option>
                      );
                    })}
                  </Select>
  
                  <span
                    onClick={() => {
                      setPerspectiveSelected({ id: "", name: "" });
                    }}
                    style={{
                      height: 22.5,
                      marginBottom: 2,
                      borderWidth: 1,
                      width: 23.9,
                      borderColor: borderTheme,
                    }}
                    className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                  >
                    <img
                      className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                      src={refresh}
                      alt="reset"
                    />
                    {/* <img className='' src= {''} alt="" /> */}
                  </span>
                </div>
              </Form.Item>
              <Form.Item
                label={
                  <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                    {"Strategic Theme"}
                  </p>
                }
                className={" mb-1"}
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 6 }}
              >
                <div className="flex flex-row items-center">
                  <Select
                    onChange={(option: any) => {
                      const id = JSON.parse(option)["thmIdpk"];
                      const name = JSON.parse(option)["thmName"];
                      setThemeMembersData({
                        ...themeMembersData,
                        ["theme"]: {
                          id: id,
                          name: name,
                        },
                      });
                    }}
                    className="w-full mr-0"
                    placeholder=""
                    allowClear={false}
                    value={themeMembersData.theme.name}
                    disabled={disableForms}
                  >
                    {themesData.map((option: any, index: number) => {
                      return (
                        <option key={index} value={JSON.stringify(option)}>
                        {option["thmName"]}
                      </option>
                      );
                    })}
                  </Select>
  
                  <span
                    onClick={() => {
                      themesSelected({ id: "", name: "" });
                    }}
                    style={{
                      height: 22.5,
                      marginBottom: 2,
                      borderWidth: 1,
                      width: 23.9,
                      borderColor: borderTheme,
                    }}
                    className="ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                  >
                    <img
                      className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                      src={refresh}
                      alt="reset"
                    />
                    {/* <img className='' src= {''} alt="" /> */}
                  </span>
                </div>
              </Form.Item>
              <Form.Item
                className="mb-0"
                label={
                  <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                    {"Strategic Objectives"}
                  </p>
                }
                labelCol={{ span: 2 }}
              >
                <div className="flex flex-row justify-between">
                  <Input
                    disabled={disableForms}
                    value={strategicObjectives}
                    onChange={(e: any) => {
                      console.log(e.target.value);
                      setStrategicObjectives(e.target.value);
                    }}
                    className=" W-11/12"
                  />
                </div>
              </Form.Item>
              <Form.Item
                className="mb-0"
                label={
                  <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                    {"Short Name"}
                  </p>
                }
                labelCol={{ span: 2 }}
              >
                <div className="flex flex-row justify-between">
                  <Input
                    disabled={disableForms}
                    value={shortName}
                    onChange={(e: any) => {
                      console.log(e.target.value);
                      setShortName(e.target.value);
                    }}
                    className=" W-11/12"
                  />
                </div>
              </Form.Item>
  
              <TextareaTemplate
                height={40}
                label={"Description"}
                labelCol={2}
                useCallbackFunc
                defaultValue={description}
                readonly={disableForms}
                setCallbackValue={(e: any) => {
                  setDescription(e);
                }}
              />
  
              {/* <Form.Item
                          labelCol={{ span: 2 }}
  
                          label={<p className='dark:bg-fill dark:text-darkModeSkin-base text-xs'>{'Description'}</p>}
                          rules={[{ required: false, }]}
                      >
                          <div className="flex flex-row ">
                              <TextArea readOnly={disableForms} value={description} onChange={(e: any) => { setDescription(e.target.value) }} style={{ resize: 'none', height: '40px' }} className='w-full ' />
  
                          </div>
                      </Form.Item> */}
  
              <TextareaTemplate
                height={40}
                label={"Outcome"}
                labelCol={2}
                useCallbackFunc
                defaultValue={outcome}
                readonly={disableForms}
                setCallbackValue={(e: any) => {
                  setOutcome(e);
                }}
              />
  
              {/* <Form.Item
                          labelCol={{ span: 2 }}
  
                          label={<p className='dark:bg-fill dark:text-darkModeSkin-base text-xs'>{'Outcome'}</p>}
                          rules={[{ required: false, }]}
                      >
                          <div className="flex flex-row ">
                              <TextArea readOnly={disableForms} value={outcome} onChange={(e: any) => { setOutcome(e.target.value) }} style={{ resize: 'none', height: '40px' }} className='w-full ' />
  
                             
  
                          </div>
                      </Form.Item> */}
              <TextareaTemplate
                height={90}
                label={"Remarks"}
                labelCol={2}
                useCallbackFunc
                defaultValue={remarks}
                readonly={disableForms}
                setCallbackValue={(e: any) => {
                  setRemarks(e);
                }}
              />
              {/* <Form.Item
                          labelCol={{ span: 2 }}
  
                          label={<p className='dark:bg-fill dark:text-darkModeSkin-base text-xs'>{'Remarks'}</p>}
                          rules={[{ required: false, }]}
                      >
                          <div className="flex flex-row ">
                              <TextArea readOnly={disableForms} value={remarks} onChange={(e: any) => { setRemarks(e.target.value) }} style={{ resize: 'none', height: '75px' }} className='w-full ' />
  
                              
                          </div>
                      </Form.Item> */}
  
              {/* <div className='w-full  flex flex-col mt-0.5 '>
                                              <div style={{width:'calc(100% - 10%)',marginLeft:'8.8%'}} className=' flex  justify-between  '>
                                                   <ul className='flex justify-evenly'> */}
              <Form.Item
                labelCol={{ span: 2 }}
                label={
                  <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                    {"Order"}
                  </p>
                }
              >
                <div className="w-full flex justify-between ">
                  <div className=" flex justify-between">
                    <li className=" flex items-center flex-row pr-2">
                      {" "}
                      <Input
                        className="w-24"
                        disabled={disableForms}
                        value={order}
                        onChange={(e: any) => {
                          setOrder(e.target.value);
                        }}
                      />
                    </li>
                    <li className=" flex items-center flex-row ml-5">
                      <p className="mr-3">Activity? : </p>{" "}
                      <Checkbox
                        style={{ borderColor: borderTheme }}
                        disabled={disableForms}
                        className="border rounded px-1"
                        onChange={(e: any) => {
                          setActive(e.target.checked);
                        }}
                      />
                    </li>
                  </div>
                  <ul className="flex ">
                    {updatingStatus === true ? (
                      <UpdateButton
                        handleUpdate={() => {
                          setModalData(updateModalData);
                          validateForms();
                        }}
                      />
                    ) : (
                      <SaveButton
                        disableButton={!showCancel}
                        handleSave={() => {
                          setModalData(saveModalData);
                          validateForms();
                        }}
                      />
                    )}
                    {showCancel === true ? (
                      <Cancel
                        handleCallbackCancel={() => {
                          setShowCancel(false);
                          setDisableForms(true);
                          setUpdatingStatus(false);
                        }}
                      />
                    ) : (
                      <NewButton
                        useCallbackFunc={true}
                        new_button_toggler={() => {
                          setShowCancel(true);
                          setDisableForms(false);
                          clearFields();
  
                          dispatch(task_form_disable(false));
  
                          //  this destroys the update mode state
                          dispatch(task_footer_update(false));
                        }}
                      />
                    )}
                  </ul>
                </div>
              </Form.Item>
              {/* </ul>
                                                  
                                                  <ul className='flex justify-end '>
                                 {updatingStatus === true  ? <UpdateButton handleUpdate={()=>{setModalData(updateModalData); validateForms();}} />: <SaveButton handleSave={()=>{setModalData(saveModalData);validateForms();}} /> }
                                  {showCancel === true ? <Cancel  handleCallbackCancel={()=>{setShowCancel(false); setDisableForms(true); setUpdatingStatus(false)}} />:  <NewButton useCallbackFunc={true} new_button_toggler={()=>{setShowCancel(true); setDisableForms(false); clearFields(); }} />}
                              </ul>
                                              </div>
                                          </div> */}
            </Form>
          </div>
        </div>
      );
    }
  );