import React from 'react'

import { CheckboxTemlate } from '../../../../templates/checkbox'
import { checkboxTypes } from '../../../human-resource/setups/data/types/selectsTypes'
import { formPropsConst } from '../../../accessories/constants'
import { Form } from 'antd'

const Apa_r2_hr_sss = () => {

    const checkboxes1: checkboxTypes[] = [
        { id: 0, label: "Review HR Training", stateName: "", defaultValue: false, },
        { id: 1, label: "Approve HR Traning", stateName: "", defaultValue: false, },
        { id: 2, label: "Endorse HR Training", stateName: "", defaultValue: false, },
        { id: 3, label: "Sign HR Training", stateName: "", defaultValue: false, },
    ]
    
    return (
        <div className='w-full h-full'>
            <Form
            labelCol={{ span: 7}}
            // wrapperCol={{span: 10}}
            className='w-full py-2 px-2 '
            size='small'
            layout="horizontal"
            >
                {checkboxes1.map(({ id, label, stateName, defaultValue }) => {
                    return (
                        <React.Fragment key={id}>
                            <CheckboxTemlate
                                useCallbackFunc
                                span
                                customDisabled={false}
                                withBorders
                                label={label}
                                style={{ padding: "5px 0" }}
                            />

                        </React.Fragment>
                    )
                })}
            </Form>
        </div>
    )
}

export default Apa_r2_hr_sss