import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface mainState {
    task_toggle_state : string;
    active_status : boolean | any;
    complete_status : boolean | null ;
    default_startDate : string;
    default_endDate : string;
    startDate: string;
    endDate: string;
}
 
var initialState = {
    task_toggle_state : 'All tasks',
    active_status : true,
    complete_status : null,
    startDate: '',
    endDate: ''
} as mainState;


export const checkstateSlice = createSlice({
    name: 'check',
    initialState,

    reducers : {
        // this is an action function with a func name of clickedViewedItem
        customtoolbar_Active_Status : (state, item) => {
            // console.log(item.payload);
            if(item.payload === true){
                console.log('when true ' + item.payload)
                // will use variabel in later data fetch calls
               state.active_status = item.payload;
               
                
            }else if(item.payload === false){
                console.log("when false " + item.payload);
                state.active_status = item.payload
            }else {
                console.log("when null " + item.payload)
                state.active_status = item.payload
            }
            
        },
        customtoolbar_Complete_Status : (state, item) => {
            // console.log(item.payload);
            if(item.payload === true){
                
                state.active_status = item.payload;
            }else{
                // console.log('====================================');
                // console.log('Error with complete status');
                // console.log('====================================');
            }
            
        },

        customtoolbar_toggle_task : (state, item) => {
            switch(item.payload){
                case 'Personal':
                    state.task_toggle_state = item.payload
                    
                    break;
                case 'All tasks' :
                    state.task_toggle_state = item.payload
                    
                    break;
                case 'Others' : 
                    state.task_toggle_state = item.payload
                    
                    break;
                default :
                    state.task_toggle_state = 'Invalid task state toggle'
            }
        },

        customtoolbar_default_startDate : (state, item: PayloadAction<any>) => {
            state.default_startDate = item.payload
        },
        customtoolbar_default_endDate : (state, item: PayloadAction<any>) => {
            state.default_endDate = item.payload
        },

        customtoolbarStartDate : (state, item: PayloadAction<any>) => {
            state.startDate = item.payload
            console.log(item.payload);
        },
        customtoolbarEndDate : (state, item: PayloadAction<any>) => {
            state.endDate = item.payload
            console.log(item.payload);
        }
        
            
        
    }
})

export const {customtoolbar_toggle_task,customtoolbar_Active_Status,customtoolbar_Complete_Status,customtoolbar_default_startDate,customtoolbar_default_endDate, customtoolbarStartDate, customtoolbarEndDate} = checkstateSlice.actions;
export default checkstateSlice.reducer;
