import { AnyAction, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { selectExprsTypes } from "../components/human-resource/setups/data/types/selectsTypes";
import { Value } from "devextreme-react/range-selector";
import { MutableRefObject } from "react";

interface generalState {
  tier: string | null;
  formDisabled: boolean;
  updateMode: boolean;
  setupSearchText: string;

  detailsPreview: any;
  formData: any;
  idExpr: string;
  selectedFieldData: any;
  SelectedFieldID: number;

  inputsExpr: any;
  selectExprs: selectExprsTypes[];
  selectAfterExprs: selectExprsTypes[];
  checkboxExprs: selectExprsTypes[];
  inputAfterExprs: selectExprsTypes[];
  resized: boolean;
  loginMode: boolean;
  databaseError: boolean;
  activeStatus: boolean | null;
  CustomDatalistExprs: any;

  drawerYOffset: { top: number; left: number };
  printButtonOnclick: boolean;

  // Ahmed
  taskFooterUpdate: boolean;
  taskFormStatus: boolean;
  selectedRecord: any;
  ppe_request_update_route: boolean;
  refresh: boolean;
  drawerOpened: boolean;
  chart: boolean;

  //alerts and modals
  modalAlert: { state: boolean; title: string; message: string };
  busyLoader: string;
  gridRef: any | null;

  // Themes
  cssBorderClass: string;

  //Screen Lock
  lockStatus: boolean;
}

var initialState = {
  tier: null,
  setupSearchText: "",
  detailsPreview: "disabledMode",
  formData: [],
  idExpr: "",
  formDisabled: true,
  updateMode: false,
  selectedFieldData: {},
  SelectedFieldID: 0,
  resized: false,
  loginMode: false,
  databaseError: false,
  activeStatus: null,
  drawerYOffset: { top: -0, left: -0 },
  CustomDatalistExprs: null,
  drawerOpened: true,

  //Exprs data[for setups template forms]
  //must always be at least 4 objects
  inputExprs: {
    nameExpr: "nameKey",
    shortnameExpr: "shortnameKey",
    orderExpr: "orderKey",
    activeExpr: "activeKey",
    remarksExpr: "remarksKey",
  },
  //must always be at least 4 objects
  inputAfterExprs: {
    nameExpr: "nameKey",
    shortnameExpr: "shortnameKey",
    orderExpr: "orderKey",
    activeExpr: "activeKey",
    remarksExpr: "remarksKey",
  },
  //must always be at least 4 objects
  selectExprs: [
    { idExpr: "idkey", nameExpr: "datakey" },
    { idExpr: "idkey2", nameExpr: "datakey2", label: "label2" },
    { idExpr: "idkey3", nameExpr: "datakey2", label: "label3" },
    { idExpr: "idkey2", nameExpr: "datakey4", label: "label4" },
  ],
  //must always be at least 4 objects
  selectAfterExprs: [
    { idExpr: "idkey", nameExpr: "datakey" },
    { idExpr: "idkey2", nameExpr: "datakey2", label: "label2" },
    { idExpr: "idkey3", nameExpr: "datakey2", label: "label3" },
    { idExpr: "idkey2", nameExpr: "datakey4", label: "label4" },
  ],
  //must always be at least 5 objects
  checkboxExprs: [
    { idExpr: "idkey", nameExpr: "datakey" },
    { idExpr: "idkey2", nameExpr: "datakey2", label: "label2" },
    { idExpr: "idkey3", nameExpr: "datakey2" },
    { idExpr: "idkey2", nameExpr: "datakey4" },
    { idExpr: "idkey2", nameExpr: "datakey2", label: "label2" },
    { idExpr: "idkey3", nameExpr: "datakey2" },
    { idExpr: "idkey2", nameExpr: "datakey4" },
  ],

  // Ahmed
  taskFooterUpdate: false,
  taskFormStatus: true,
  gridRef: null,
  selectedRecord: [],
  chart: false,
  regNo : [],
  ppe_request_update_route: true,
  refresh: false,
  printButtonOnclick: false,

  //alerts and modals
  modalAlert: { state: false, title: "", message: "" },
  busyLoader: "",

  // Theme
  cssBorderClass: localStorage.getItem("theme"),

  //LockScreen Status
  lockStatus: false,
} as generalState | any;

const serializeRef = (ref: MutableRefObject<any>) => {
  // Extract the necessary data from the ref and serialize it
  const serializedData = {
    value: ref.current ? ref.current : null, // Serialize the current value of the ref
    // Add any other necessary data from the ref
  };

  return serializedData;
};

const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    //set state reducers [name ,shortname ...]
    setTier(state, action: PayloadAction<string>) {
      //tier specific reducer
      state.tier = action.payload;
    },
    setGeneralValue(state, action: PayloadAction<any>) {
      //set values of variables here
      const expr: string = action.payload.expr;
      const value: any = action.payload.value;

      state[expr] = value;

      //push selected item ID to state
      if (expr === "selectedFieldData") {
        console.log(value);

        // make sure there is a selected data
        if (value) {
          const selected = value;

          // set ID of selected Item
          state.SelectedFieldID = selected[state.idExpr];
        }
      }
    },

    setTheme(state, action) {
      state.AppTheme = action.payload;
    },

    setDetailsPreview(state, action: PayloadAction<any>) {
      state.detailsPreview = action.payload.value;
    },
    setFormData(state, action: PayloadAction<any>) {
      state.formData = action.payload;
    },

    // Ahmed
    settask_form_disable: (state, item) => {
      state.taskFormStatus = item.payload;
    },

    setRegNo: (state, item)=>{
      state.regNo = item.payload;
    },

    settask_footer_update: (state, item) => {
      state.taskFooterUpdate = item.payload;
    },

    setupdate_route_state: (state, item) => {
      state.ppe_request_update_route = item.payload;
    },
    settask_footer_table_selected: (state, item) => {
      state.selectedRecord = item.payload;
      // console.log(item.payload);
    },
    settask_gridRef: (state, action: PayloadAction<MutableRefObject<any>>) => {
      state.gridRef = action.payload.current;
    },

    settask_footer_table_refresh: (state, item) => {
      state.refresh = item.payload;
      // console.log(item.payload);
    },
    setShowChart: (state, item) => {
      state.chart = item.payload;
      // console.log(item.payload);
    },
  },
});

export const {
  setTier,
  setDetailsPreview,
  setFormData,
  setGeneralValue,
  settask_footer_update,
  setupdate_route_state,
  settask_footer_table_selected,
  settask_form_disable,
  settask_footer_table_refresh,
  settask_gridRef,
  setShowChart,
  setTheme,
  setRegNo,
} = generalSlice.actions;
export default generalSlice.reducer;
