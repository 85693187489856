export const hr_setups_Town_columns = [
    {id : 0, caption : 'ID',dataField : 'twnIDpk', width : 80, fixed : true, visible : false},
    {id : 1, caption : 'TOWN',dataField : 'twnName', width : 160, fixed : true , visible : true},
    {id : 2, caption : 'SHORT NAME',dataField : 'twnShtName', width : 160, fixed : true , visible : true},
    {id : 3, caption : 'COUNTRY',dataField : 'ctyName', width : 130, fixed : true , visible : true},
    {id : 4, caption : 'REGION',dataField : 'rgnName', width : 130, fixed : true , visible : true},
    {id : 5, caption : 'DISTRICT',dataField : 'dstName', width : 130, fixed : true , visible : true},
    {id : 6, caption : 'ORDER',dataField : 'twnOrder', width : 130, fixed : true , visible : true},
    {id : 7, caption : 'DST. CAP?',dataField : 'twnDistrictCapital', width : 90, fixed : true , visible : true},
    {id : 8, caption : 'RGN. CAP?',dataField : 'twnRegionalCapital', width : 90, fixed : true , visible : true},
    {id : 9, caption : 'NAT. CAP?',dataField : 'twnNationalCapital', width : 90, fixed : true , visible : true},
    {id : 10, caption : 'ACTIVE',dataField : 'twnActive', width : 90, fixed : true , visible : true},
    {id : 11, caption : 'REMARKS',dataField : 'twnRmks', width : 5000, fixed : false , visible : true}
]

export const Town_data = [
    {
        'ID' : 1,
        'REGION' : 'Accra Central',
        'COUNTRY' : 'Accra Central',
        'TOWN' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'TOWN HEAD' : 'Accra',
        'ORDER' : '4',
        'DISTRICT' : 'Accra Central',
        'ACTIVE' : true,
        'REMARKS' : 'SFF'
    },
    {
        'ID' : 2,
        'REGION' : 'Accra Central',
        'COUNTRY' : 'Accra Central',
        'TOWN' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'TOWN HEAD' : 'Accra',
        'ORDER' : '4',
        'DISTRICT' : 'Accra Central',
        'ACTIVE' : false,
        'REMARKS' : ''
    },
    {
        'ID' : 3,
        'REGION' : 'Accra Central',
        'COUNTRY' : 'Accra Central',
        'TOWN' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'TOWN HEAD' : 'Accra',
        'ORDER' : '4',
        'DISTRICT' : 'Accra Central',
        'ACTIVE' : true,
        'REMARKS' : ''
    },
    {
        'ID' : 4,
        'REGION' : 'Accra Central',
        'COUNTRY' : 'Accra Central',
        'TOWN' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'TOWN HEAD' : 'Accra',
        'ORDER' : '4',
        'DISTRICT' : 'Accra Central',
        'ACTIVE' : true,
        'REMARKS' : ''
    },
    {
        'ID' : 5,
        'REGION' : 'Accra Central',
        'COUNTRY' : 'Accra Central',
        'TOWN' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'TOWN HEAD' : 'Accra',
        'ORDER' : '4',
        'DISTRICT' : 'Accra Central',
        'ACTIVE' : true,
        'REMARKS' : ''
    },
    {
        'ID' : 6,
        'REGION' : 'Accra Central',
        'COUNTRY' : 'Accra Central',
        'TOWN' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'TOWN HEAD' : 'Accra',
        'ORDER' : '4',
        'DISTRICT' : 'Accra Central',
        'ACTIVE' : true,
        'REMARKS' : ''
    },
    {
        'ID' : 7,
        'REGION' : 'Accra Central',
        'COUNTRY' : 'Accra Central',
        'TOWN' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'TOWN HEAD' : 'Accra',
        'ORDER' : '4',
        'DISTRICT' : 'Accra Central',
        'ACTIVE' : false,
        'REMARKS' : ''
    },
    {
        'ID' : 8,
        'REGION' : 'Accra Central',
        'COUNTRY' : 'Accra Central',
        'TOWN' : 'Accra Central',
        'SHORT NAME' : 'Accra Central',
        'TOWN HEAD' : 'Accra',
        'ORDER' : '4',
        'DISTRICT' : 'Accra Central',
        'ACTIVE' : true,
        'REMARKS' : ''
    },
]