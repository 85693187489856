export const assetsSetups_maintenancetypes_columns = [
  {
    id: 0,
    caption: "ID",
    dataField: "mtpIdpk",
    dataType: "number",
    width: 200,
    fixed: true,
    visible: false,
  },
  {
    id: 1,
    caption: "NAME",
    dataField: "mtpName",
    dataType: "string",
    width: 200,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "SHORT NAME",
    dataField: "mtpShtName",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 3,
    caption: "ORDER",
    dataField: "mtpOrder",
    dataType: "decimal",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 4,
    caption: "ACTIVE?",
    dataField: "mtpActive",
    dataType: "boolean",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 5,
    caption: "PM?",
    dataField: "mtpPreventiveMaintenance",
    dataType: "boolean",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 6,
    caption: "RoadWorthy?",
    dataField: "mtpRoadworthy",
    dataType: "boolean",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 7,
    caption: "Ins.Renewal?",
    dataField: "mtpInsuranceRenewal",
    dataType: "boolean",
    width: 100,
    fixed: false,
    visible: true,
  },
  {
    id: 8,
    caption: "REMARKS",
    dataField: "mtpRmks",
    dataType: "string",
    width: 5000,
    fixed: false,
    visible: true,
  },
];
