import { Form } from "antd";
import React, { useEffect, useState } from "react";
import { DateTemplate } from "../../../templates/date";
import { SelectsTemplate } from "../../../templates/select";
import { InputsTemplate } from "../../../templates/input";
import { TextareaTemplate } from "../../../templates/textarea";
import Datagrid_template from "../../../templates/Datagrid";
import {
    Cancel,
    NewButton,
    SaveButton,
    UpdateButton,
} from "../../accessories/buttons";
import useResizeMode from "../../../hooks/useResizeMode";
import dayjs, { Dayjs } from "dayjs";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { useDispatch } from "react-redux";
import { setGeneralValue } from "../../../features/generalSlice";
import { useAccountInfo } from "../../../hooks/useAccountInfo";
import { useFormPriviledge } from "../../../hooks/useFormPriviledge";
import Modal from "antd/es/modal/Modal";
import { ModalTemplate } from "../../../templates/modal";
import {
    saveModalData,
    updateModalData,
} from "../../accessories/component_infos";
import useFetch from "../../../hooks/useFetch";
import { Maint_Sch_Freq_cols } from "./data/datagridCols";
import { select } from "@syncfusion/ej2-base";
import statusLoader from '../../../assets/statusloader.gif';
import { useCrudFunc } from "../../../functions/crud";

interface startTypres {
  selectedRecord: any;
  startData: any;
  maintType: { id: number; name: string };
  freqYear: number;
  setModes: number;
  vehicleClass: string;
  make: string;
  module: string;
  remarks: string;
}

const Maint_Sch_Freq = () => {
  const [initial, final] = useResizeMode(window.innerHeight);
  const gridheight = initial - 500;
  const form_disable = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const update_state_change = useSelector(
    (state: RootState) => state.general.updateMode
  );

    const format = "YYYY-MM-DD";
    const today = dayjs(new Date()).format(format);

  const [states, setStates] = useState<startTypres>({
    selectedRecord: {},
    startData: today,
    maintType: { id: 0, name: "" },
    freqYear: 0,
    setModes: 0,
    vehicleClass: "",
    make: "",
    module: "",
    remarks: "",
  });

  const setState = (key: string, value: any) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };

  console.table( states);

    const dispatch = useDispatch();
  const [PostDataFunc, UpdateDataFunc] = useCrudFunc();
  
    const [openModal, setOpenModal] = useState(false);
    const [modalData, setModalData] = useState<any>(saveModalData);
    const [icon, setIcon] = useState<"warning" | "success" | "question">();
    const [refresh, setRefresh] = useState<boolean>(false);

    const [userId, userInfo] = useAccountInfo();
    const [privType, setPrivType] = useState<string>("save");
    const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);

  const [data, dataErr] = useFetch(`GeneralSettings/PopGdcAsmTransMaintFrequencies`, refresh);
  const [maint_type] = useFetch(`GeneralSettings/PopulateMaintenaceTypes`);
  const formCode = useSelector((state: RootState) => state.general.formCode);


  const populateFields = (clear: boolean) => {
    // console.log("SELECTED RECORD", states.selectedRecord?.vmkName,states.selectedRecord.mdlName,states.selectedRecord.mtsFrequencyDays )
    const gridState = [
        { key: "startData", value: clear ? today : states.selectedRecord.mtsStartDate },
        {
          key: "maintType",
          value: clear ? { id: 0, name: "" } : { id: 0, name: states.selectedRecord?.mtpName },
        },
        { key: "freqYear", value: clear ? 0 : states.selectedRecord?.mtsFrequencyDays },
        { key: "setModes", value: clear ? 0 : 0 }, //not implemented yet
        { key: "vehicleClass", value: clear ? "" : states.selectedRecord?.vhcName },
        { key: "make", value: clear ? "" : states.selectedRecord?.vmkName},
        { key: "module", value: clear ? "" : states.selectedRecord?.mdlName },
        { key: "remarks", value: clear ? "" : "" }, //not implemented yet
      ];

      for (let instance of gridState) setState(instance.key, instance.value);
  };

  const validationForms = async () => {
    setIcon("warning");
    if (dayjs(states.startData).isAfter(dayjs())) {
      setModalData({
        title: "Validation Error!",
        message: "Start Date should not be greater than the current date",
      });
      setOpenModal(true);
    } else if (states.maintType.id === 0) {
      setModalData({
        title: "Validation Error!",
        message: "Maintenance Type is required",
      });
      setOpenModal(true);
    } else if (states.freqYear === 0) {
      setModalData({
        title: "Validation Error!",
        message: "Frequency per Year is required",
      });
      setOpenModal(true);
    } else if (states.setModes === 0) {
      setModalData({
        title: "Validation Error!",
        message: "Set for All Models is required",
      });
      setOpenModal(true);
    } else if (states.vehicleClass === "") {
      setModalData({
        title: "Validation Error!",
        message: "Vehicle Class is required",
      });
      setOpenModal(true);
    } else if (states.make === "") {
      setModalData({ title: "Validation Error!", message: "Make is required" });
      setOpenModal(true);
    } else if (states.module === "") {
      setModalData({
        title: "Validation Error!",
        message: "Module is required",
      });
      setOpenModal(true);
    } else {
      setIcon("question");
      setOpenModal(true);
    }
  };

    const newAccesspriv = () => {
        setPrivType("save");
        if (savePriv === true) {
            dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
            populateFields(true);
        } else {
            dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
            setIcon("warning");
            setOpenModal(true);
            setModalData({ title: "Access Denied!", message: "No Access Privilege" });
        }
    };

    const saveAccessPriv = () => {
        setPrivType("save");
        if (savePriv === true) {
            dispatch(setGeneralValue({ expr: "updateMode", value: false }));
            setIcon("question");
            setModalData(saveModalData);
            setOpenModal(true);
            validationForms();
        } else {
            setIcon("warning");
            setOpenModal(true);
            setModalData({ title: "Access Denied!", message: "No Access Privilege" });
        }
    };

    const updateAcccessPriv = () => {
        setPrivType("update");
        if (updatePriv === true) {
            dispatch(setGeneralValue({ expr: "updateMode", value: true }));
            setIcon("question");
            setModalData(updateModalData);
            setOpenModal(true);
            validationForms();
        } else {
            setIcon("warning");
            setOpenModal(true);
            setModalData({ title: "Access Denied!", message: "No Access Privilege" });
        }
    };

    const doubleClickAccessPriv = (e: any) => {
        if (updatePriv === true) {
            setState("selectedRecord", e);
            console.log("EEEEEE", e);
            dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
            dispatch(setGeneralValue({ expr: "updateMode", value: true }));
        } else {
            setIcon("warning");
            setOpenModal(true);
            setModalData({ title: "Access Denied!", message: "No Access Privilege" });
        }
    };

  const PostData = async () => {
    try {
      await PostDataFunc(`GeneralSettings/CreateMaintenaceFrequency?formCode=${formCode}&formAction=${""}`, {
        "mtsStartDate": states.startData,
        "mtsMaintenanceTypeIDfk": states.maintType.id,
        "mtsModelIDfk": 0,
        "mtsFrequencyDays": states.freqYear,
        "mtsRmks": states.remarks,
      },
        `Created the 'Form Name' with  mtsFrequencyDays:${states?.freqYear}, mtsStartDate:${states?.startData}, mtsMaintenanceTypeIDfk:${ states.maintType?.id} Date Created:${dayjs().format()}. created By:${userId}`
      );
      setRefresh(!refresh);
      setIcon("success");
      setOpenModal(true);
      setModalData({
        message: "Data saved successfully!",
        title: "Data Saved!",
        okText: "Ok",
      });
    } catch (e: any) {
      setIcon("warning");
      setOpenModal(true);
      setModalData({
        message: e.response ? e.response.data.message : "Server Error",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };

  // TODO: update fields are more than one from swagger, confirm
    const updateData = async () => {
        try {
            await UpdateDataFunc(`GeneralSettings/UpdateMaintenaceFrequency`, {
                startData: states.startData,
                maintType: states.maintType.id,
                freqYear: states.freqYear,
                setModes: states.setModes,
                vehicleClass: states.vehicleClass,
                make: states.make,
                module: states.module,
                remarks: states.remarks,
            },
        `Update the 'Form Name' with  mtsFrequencyDays:${states?.freqYear}, mtsStartDate:${states?.startData}, mtsMaintenanceTypeIDfk:${ states.maintType?.id} Date Edited:${dayjs().format()}. Edited By:${userId}`
        
          );
            setRefresh(!refresh);
            setIcon("success");
            setOpenModal(true);
            setModalData({
                message: "Data updated successfully!",
                title: "Data Updated!",
                okText: "Ok",
            });
        } catch (e: any) {
            setIcon("warning");
            setOpenModal(true);
            setModalData({
                message: e.response ? e.response.data.message : "Server Error",
                title: "Unexpected Error!",
                okText: "Ok",
            });
        }
    }

  useEffect(() => {
    populateFields(false);
  }, [ states.selectedRecord]);

  return (
    <>
      <div className="w-full px-2 py-1">
        <ModalTemplate
          icon={icon}
          cancelText={modalData.cancelText}
          okText={modalData.okText}
          title={modalData.title}
          message={modalData.message}
          disableCancel={
            icon === "warning" || icon === "success" ? true : false
          }
          cancelHandler={() => {
            setOpenModal(false);
          }}
          open={openModal}
          okHandler={() => {modalData === saveModalData ? PostData() : modalData === updateModalData ? updateData() : setOpenModal(false)}}
        />
        <Form
          labelCol={{ span: 3 }}
          // wrapperCol={{span: 10}}
          className="w-full py-4 px-2 border-[1px] rounded-sm "
          size="small"
          layout="horizontal"
        >
          <DateTemplate
            label={"Start Date"}
            width={145}
            selectedDate={dayjs(states.startData)}
            changeDate={(e: Dayjs) => {
              setState("startData", e.format());
            }}
            disabled={form_disable}
          />

                    {/* inputs */}

          <SelectsTemplate
            useCallFunc
            fullWidth
            span
            options={maint_type}
            idexpr={"mtpIDpk"}
            dataexp={"mtpName"}
            placeHolder={states.maintType.name}
            selectedValue={(e) => {
              setState("maintType", {
                id: e["mtpIDpk"],
                name: e["mtpName"],
              });
            }}
            labelCol={3}
            selectStyle={{ width: 155 }}
            label={"Maint. Type"}
            extraWidget={
              <div className="flex space-x-3 ml-4">
                <InputsTemplate
                  span
                  useCallbackFunc
                  label={"Freq./Year"}
                  inputStyle={{ width: 100 }}
                  numberOnly
                  disabledStatus={form_disable}
                  defaultValue={states.freqYear}
                  orderOnchange={(e) => {
                    setState("freqYear", e);
                  }}
                />
                <InputsTemplate
                  span
                  useCallbackFunc
                  label={"Set for All Models"}
                  inputStyle={{ width: 100 }}
                  numberOnly
                  disabledStatus={form_disable}
                  defaultValue={states.setModes}
                  orderOnchange={(e) => {
                    setState("setModes", e);
                  }}
                />
              </div>
            }
            disabled={form_disable}
          />

                    {/* inputs */}

          <InputsTemplate
            useCallbackFunc
            span
            labelColPan={3}
            inputStyle={{ height: 100, width: 200 }}
            label={"Vehicle Class, Make & Module"}
            defaultValue={states.vehicleClass}
            orderOnchange={(e) => {
              setState("vehicleClass", e);
            }}
            additionalWidget={
              <div className="flex space-x-1 ml-1">
                <InputsTemplate
                useCallbackFunc
                  span
                  inputStyle={{ height: 100 }}
                  disabledStatus={form_disable}
                  defaultValue={states.make}
                  orderOnchange={(e) => {
                    setState("make", e);
                  }}
                />
                <InputsTemplate
                useCallbackFunc
                  span
                  inputStyle={{ height: 100 }}
                  disabledStatus={form_disable}
                  defaultValue={states.module}
                  orderOnchange={(e) => {
                    setState("module", e);
                  }}
                />
              </div>
            }
          />

                    <TextareaTemplate
                        height={125}
                        useCallbackFunc
                        label={"Remarks"}
                        labelCol={3}
                        disabled={form_disable}
                        defaultValue={states.remarks}
                        setCallbackValue={(e) => {
                            setState("remarks", e);
                        }}
                    />

          <Form.Item
            colon={false}
            labelCol={{ span: 3 }}
            label={<p className="hidden">{"hidden"}</p>}
          >
            <section className="w-full flex justify-between">
              <aside className="w-1/2">
              {form_disable && <p className=" italic font-semibold text-gray-600">Done.</p>}

              {!form_disable && <div className="  flex flex-row items-center">
                  <img style={{ width: '31px', height: '31px' }} src={statusLoader} alt="gif" />
                  {!update_state_change ?
                      <p className=" italic text-gray-600 text-xs">New Record</p>
                      :
                      <p className=" italic text-gray-600 text-xs">Editing</p>}
              </div>}
              </aside>

                            <aside className="w-1/2 flex justify-end">
                                {form_disable === false && update_state_change === false ? (
                                    <SaveButton
                                        handleSave={() => {
                                            saveAccessPriv();
                                        }}
                                    />
                                ) : update_state_change === true ? (
                                    <UpdateButton
                                        handleUpdate={() => {
                                            updateAcccessPriv();
                                        }}
                                    />
                                ) : (
                                    <SaveButton
                                        handleSave={() => {
                                            saveAccessPriv();
                                        }}
                                    />
                                )}
                                {form_disable === true ? (
                                    <NewButton
                                        useCallbackFunc
                                        new_button_toggler={() => {
                                            newAccesspriv();
                                        }}
                                    />
                                ) : update_state_change === true ? (
                                    <Cancel cancel_button_toggler={() => { }} />
                                ) : !(update_state_change && form_disable) ? (
                                    <Cancel cancel_button_toggler={() => { }} />
                                ) : (
                                    <NewButton
                                        useCallbackFunc
                                        new_button_toggler={() => {
                                            newAccesspriv();
                                        }}
                                    />
                                )}
                            </aside>
                        </section>
                    </Form.Item>
                </Form>

        {/* Datagrid */}
        <section className="">
          <Datagrid_template
            gridheight={gridheight}
            columns={Maint_Sch_Freq_cols}
            data={data}
            onRowClick={(e) => {
              setState("selectedRecord", e);
              dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
              dispatch(setGeneralValue({ expr: "updateMode", value: false }));
            }}
            rowDoubleClicked={(e) => {
              doubleClickAccessPriv(e);
            }}
          />
        </section>
      </div>
    </>
  );
};

export default Maint_Sch_Freq;
