/* eslint-disable react/jsx-pascal-case */
import React, { useEffect } from "react";
import { lowerGrid, upperGrid } from "../data/datagrid_cols";
import { Checkbox } from "antd";
import Datagrid_template from "../../../../../../../templates/Datagrid";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import { setGeneralValue } from "../../../../../../../features/generalSlice";
import { useDispatch } from "react-redux";

interface props {
  upperGridData?: any;
  lowerGridData?: any;
  allowUpdate?: (e: any) => void;
  singleClick?: (e: any) => void;
  singleClick2?: (e: any) => void;
  checkBoxOnChange?: (e: any) => void;
  checkBoxOnChange2?: (e: any) => void;
  updateState?: (key: string, value: any) => void;
  dataGridUpdateFunc: (e: any) => void;
  showAllGrids: boolean;
  states?: any;
}

export const Right_grid = ({
  upperGridData,
  lowerGridData,
  allowUpdate,
  singleClick,
  checkBoxOnChange,
  checkBoxOnChange2,
  states,
  singleClick2,
  dataGridUpdateFunc,
  showAllGrids,
}: props) => {
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const dispatch = useDispatch();
  const fullHeight = 940;

  useEffect(() => {}, [lowerGridData]);

  return (
    <div className="w-full h-full">
      <div className=" w-full h-full">
        {showAllGrids && (
          <div>
            <div className="flex w-full  justify-between bg-slate-100 items-center ">
              <ul
                style={{ width: "38%" }}
                className=" mb-1 px-2 flex items-center justify-start    self-end dark:bg-darkModeSkin-div_headers_backgroundColor dark:text-darkModeSkin-headers}}"
              >
                <li className=" flex items-center flex-row justify-center">
                  <p className="mr-1 text-xs">Staff Details Criteria? : </p>{" "}
                  <Checkbox
                    style={{ borderColor: borderTheme }}
                    className="border-[1px] px-1 rounded"
                    indeterminate={states !== undefined && states.indeterminate}
                    onChange={checkBoxOnChange}
                    checked={states !== undefined && states.staffDC}
                  />
                </li>
              </ul>
              <ul
                style={{ width: "62%" }}
                className=" mb-1 flex items-center justify-end  self-end dark:bg-darkModeSkin-div_headers_backgroundColor dark:text-darkModeSkin-headers"
              >
                <li className="w-full flex items-center flex-row justify-center">
                  <p className="mr-1 text-xs">
                    Driver/Permit Holder Criteria? :{" "}
                  </p>{" "}
                  <Checkbox
                    style={{ borderColor: borderTheme }}
                    className="border-[1px] px-1 rounded"
                    indeterminate={
                      states !== undefined && states.indeterminate2
                    }
                    onChange={checkBoxOnChange2}
                    checked={states !== undefined && states.DPHC}
                  />
                </li>
              </ul>
            </div>
            {/* Upper Grid */}

            <div className="w-full  h-1/2 pb-1 max-w-none ">
              <Datagrid_template
                disablePaging
                gridheight={410}
                columns={upperGrid}
                data={upperGridData}
                onRowClick={singleClick2}
                handlePageNumber={(page) => {}}
                handlePageSize={(size) => {}}
              />
            </div>
          </div>
        )}
        {/* Lower Grid */}

        <div className="w-full h-1/2">
          <Datagrid_template
            gridheight={showAllGrids ? 495 : fullHeight}
            columns={lowerGrid}
            data={lowerGridData}
            onRowClick={(e: any) => {
              singleClick && singleClick(e);
              dispatch(
                setGeneralValue({ expr: "formDisabled", value: true })
              );
              dispatch(
                setGeneralValue({ expr: "updateMode", value: false })
              );
            }}
            rowDoubleClicked={(e: any) => {
              dataGridUpdateFunc(e);
              dispatch(
                setGeneralValue({ expr: "updateMode", value: true })
              );
              dispatch(
                setGeneralValue({ expr: "formDisabled", value: false })
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};
