import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { selectExprsTypes } from '../../../components/human-resource/setups/data/types/selectsTypes'
import { regex } from '../../../components/accessories/component_infos'

interface tier2State {
  selectValue : number | null
  selectExprs : selectExprsTypes[]

  name : string | null
  shortname : string | null
  order : number
  remarks : string | null
  value: number | null
  active : boolean | null
  data : any
  idExpr : string | null
  nameExpr : string | null
  shortnameExpr : string | null
  SelectedFieldData : any
  SelectedFieldID : number
  saveDataOnclick : boolean,
  updateDataOnclick : boolean,
  warningState : boolean,
  warningDataExpr : any
  successModalState : boolean,
  successText : any

  existsUrl : string,
  checkExistsTrigger : boolean, 
  checkExistsOnclick : boolean,
}

var initialState = {
  selectValue : null,
  selectExprs : [{idExpr : 'idkey', nameExpr : 'datakey', label : 'label'}],

  name : null,
  shortname : null,
  order : 0,
  remarks : null,
  active : true,
  value: 0,
  data : [],
  idExpr : null,
  nameExpr : null,
  shortnameExpr : null,
  selectedFieldData : [],
  SelectedFieldID : 0,
  saveDataOnclick : false,
  updateDataOnclick : false,
  warningState : false,
  warningDataExpr : 'name',
  successModalState : false,
  successText : '',

  existsUrl : '',
  checkExistsTrigger : false,
  checkExistsOnclick : false,
}as tier2State | any

const tier2Slice = createSlice({
  name: 'tier2',
  initialState,
  reducers: {
    //set state reducers [name ,shortname ...]
    setTier2Value(state, action: PayloadAction<any>) {
      // set value of given expr
      state[action.payload.expr] = action.payload.value;

      if (action.payload.expr === 'selectedFieldData') {
        const selected = action.payload.value;
        
        // set ID of selected Item
        state.SelectedFieldID = selected[state.idExpr];
      }
    },
    tier2SaveOnclick(state){ //handle form data save
      const selectValue = state.selectValue;
      const name = state.name;
      const shortname = state.shortname;

      // select
      if (!selectValue) {
        state.warningDataExpr = state.selectExprs.label;
        state.warningState = true;
      }
      // name validation
      if (!name || name.replace(/\s/g,"") == "") {
        state.warningDataExpr = 'name';
        state.warningState = true;
        return;
      }
      if (regex.test(name[0])) {
        state.warningDataExpr = 'validName';
        state.warningState = true;
        return;
      }
      // short name validation
      else if (!shortname || shortname.replace(/\s/g,"") == "") {
        state.warningDataExpr = 'shortname';
        state.warningState = true;
        return;
      }
      else if (regex.test(shortname[0])) {
        state.warningDataExpr = 'validShortname';
        state.warningState = true;
        return;
      }
      //exists check
      else if (state.existsUrl){
        state.checkExistsOnclick = true;
        state.checkExistsTrigger = !state.checkExistsTrigger;
      }
      else {
        state.saveDataOnclick = true;
      }
    },
    tier2UpdateOnclick(state){ //handle form data update
      const selectValue = state.selectValue;
      const name = state.name;
      const shortname = state.shortname;

      if (!selectValue) {
        state.warningDataExpr = state.selectExprs.label;
        state.warningState = true;
      }
      // name validation
      if (!name || name.replace(/\s/g,"") == "") {
        state.warningDataExpr = 'name';
        state.warningState = true;
        return;
      }
      if (regex.test(name[0])) {
        state.warningDataExpr = 'validName';
        state.warningState = true;
        return;
      }
      // short name validation
      else if (!shortname || shortname.replace(/\s/g,"") == "") {
        state.warningDataExpr = 'shortname';
        state.warningState = true;
        return;
      }
      else if (regex.test(shortname[0])) {
        state.warningDataExpr = 'validShortname';
        state.warningState = true;
        return;
      }
      //exists check
      else if (state.existsUrl){
        state.checkExistsOnclick = true;
        state.checkExistsTrigger = !state.checkExistsTrigger;
      }
      else {
        state.updateDataOnclick = true;
      }
    },
  },
})

export const { setTier2Value,tier2SaveOnclick,tier2UpdateOnclick} = tier2Slice.actions
export default tier2Slice.reducer
