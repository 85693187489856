export const assetsSetups_ISP_columns = [
  {
    id: 0,
    caption: "SERVICE PROVIDER",
    dataField: "spdName",
    datatype: "string",
    width: 180,
    fixed: true,
    visible: true,
  },
  {
    id: 1,
    caption: "SHORT NAME",
    dataField: "spdShtName",
    dataType: "string",
    width: 150,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "CONTACTS",
    dataField: "spdMainContact",
    dataType: "",
    width: 120,
    fixed: true,
    visible: true,
  },
  {
    id: 3,
    caption: "MOB NOs",
    dataField: "spdMobNos",
    dataType: "",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 4,
    caption: "TEL NOs",
    dataField: "spdTelNos",
    dataType: "",
    width: 100,
    fixed: true,
    visible: true,
  },
  {
    id: 5,
    caption: "EMAIL",
    dataField: "spdEmail",
    dataType: "string",
    width: 130,
    fixed: false,
    visible: true,
  },
  {
    id: 6,
    caption: "POSTAL ADDRESS",
    dataField: "spdPostalAddress",
    dataType: "string",
    width: 120,
    fixed: false,
    visible: true,
  },
  {
    id: 7,
    caption: "ORDER",
    dataField: "spdOrder",
    dataType: "",
    width: 90,
    fixed: false,
    visible: true,
  },
  {
    id: 8,
    caption: "FUEL DUMP?",
    dataField: "spdFuelDump",
    dataType: "boolean",
    width: 110,
    fixed: false,
    visible: true,
  },
  {
    id: 9,
    caption: "ACTIVE?",
    dataField: "spdActive",
    dataType: "boolean",
    width: 60,
    fixed: false,
    visible: true,
  },
  {
    id: 10,
    caption: "REMARKS",
    dataField: "spdRmks",
    dataType: "string",
    width: 4000,
    fixed: false,
    visible: true,
  },
];
