import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { regex } from "../../../components/accessories/component_infos";

interface addState {
  name: string | null;
  shortname: string | null;
  order: string | null;
  remarks: string | null;
  value: number | null;
  active: boolean;
  additionalCheckbox: boolean;
  checkExistsOnclick: boolean;
  data: any;
  idExpr: string | null;
  nameExpr: string | null;
  shortnameExpr: string | null;
  SelectedFieldData: any;
  updateDataOnclick: boolean;
  warningState: boolean;
  warningDataExpr: any;
  successModalState: boolean;
  successText: any;
  deleteDataOnclick: boolean;
  existsUrl: string;
  checkExistsTrigger: boolean;
}

var initialState = {
  name: null,
  shortname: null,
  order: 0,
  remarks: null,
  active: true,
  additionalCheckbox: false,
  value: 0,
  data: [],
  idExpr: null,
  nameExpr: null,
  shortnameExpr: null,
  saveDataOnclick: false,
  checkExistsOnclick: false,
  updateDataOnclick: false,
  warningState: false,
  warningDataExpr: "name",
  successModalState: false,
  successText: "",
  deleteDataOnclick: false,
  existsUrl: "",
  checkExistsTrigger: false,
} as addState | any;

const tier1addSlice = createSlice({
  name: "add",
  initialState,
  reducers: {
    //set state reducers [name ,shortname ...]
    setValue(state, action: PayloadAction<any>) {
      //set values of variables here
      const expr = action.payload.expr;
      const value = action.payload.value;

      state[expr] = value;
    },
    tier1SaveOnclick(state, action: { payload: boolean } = { payload: false }) {
      //handle form data save
      const name = state.name;
      const shortname = state.shortname;
      const order = state.order;
      const InputAfter0 = state.inputAfter0;
      const InputAfter1 = state.inputAfter1;

      console.log("order", Number(order));
      // name validation
      if (!name || name.replace(/\s/g, "") === "") {
        state.warningDataExpr = "name";
        state.warningState = true;
        return;
      }
      if (regex.test(name[0])) {
        state.warningDataExpr = "validName";
        state.warningState = true;
        return;
      }
      // short name validation
      else if (!shortname || shortname.replace(/\s/g, "") === "") {
        state.warningDataExpr = "shortname";
        state.warningState = true;
        return;
      } else if (regex.test(shortname[0])) {
        state.warningDataExpr = "validShortname";
        state.warningState = true;
        return;
      }
      if (order === undefined || order === "") {
        state.warningDataExpr = "order";
        state.warningState = true;
        return;
      } else if (isNaN(Number(order))) {
        state.warningDataExpr = "validOrder";
        state.warningState = true;
        return;
      }

      if (action.payload === true) {
        if (InputAfter0 === undefined || InputAfter0 === "") {
          state.warningDataExpr = "inputAfter0";
          state.warningState = true;
          return;
        } else if (isNaN(Number(InputAfter0))) {
          state.warningDataExpr = "validInputAfter0";
          state.warningState = true;
          return;
        }

        if (InputAfter1 === undefined || InputAfter1 === "") {
          state.warningDataExpr = "inputAfter1";
          state.warningState = true;
          return;
        } else if (isNaN(Number(InputAfter1))) {
          state.warningDataExpr = "validInputAfter1";
          state.warningState = true;
          return;
        } else if (state.existsUrl) {
          state.checkExistsOnclick = true;
          state.checkExistsTrigger = !state.checkExistsTrigger;
        } else {
          state.saveDataOnclick = true;
        }
      }
      //exists check
      else if (state.existsUrl) {
        state.checkExistsOnclick = true;
        state.checkExistsTrigger = !state.checkExistsTrigger;
      } else {
        state.saveDataOnclick = true;
      }
    },
    tier1UpdateOnclick(
      state,
      action: { payload: boolean } = { payload: false }
    ) {
      //handle form data save
      const name = state.name;
      const shortname = state.shortname;
      const order = state.order;
      const InputAfter0 = state.inputAfter0;
      const InputAfter1 = state.inputAfter1;

      // name validation
      if (!name || name.replace(/\s/g, "") === "") {
        state.warningDataExpr = "name";
        state.warningState = true;
        return;
      }
      if (regex.test(name[0])) {
        state.warningDataExpr = "validName";
        state.warningState = true;
        return;
      }
      // short name validation
      else if (!shortname || shortname.replace(/\s/g, "") === "") {
        state.warningDataExpr = "shortname";
        state.warningState = true;
        return;
      } else if (regex.test(shortname[0])) {
        state.warningDataExpr = "validShortname";
        state.warningState = true;
        return;
      }

      if (order === undefined || order === "") {
        state.warningDataExpr = "order";
        state.warningState = true;
        return;
      } else if (isNaN(Number(order))) {
        state.warningDataExpr = "validOrder";
        state.warningState = true;
        return;
      }

      if (action.payload === true) {
        if (InputAfter0 === undefined || InputAfter0 === "") {
          state.warningDataExpr = "inputAfter0";
          state.warningState = true;
          return;
        } else if (isNaN(Number(InputAfter0))) {
          state.warningDataExpr = "validInputAfter0";
          state.warningState = true;
          return;
        }

        if (InputAfter1 === undefined || InputAfter1 === "") {
          state.warningDataExpr = "inputAfter1";
          state.warningState = true;
          return;
        } else if (isNaN(Number(InputAfter1))) {
          state.warningDataExpr = "validInputAfter1";
          state.warningState = true;
          return;
        } else if (state.existsUrl) {
          state.checkExistsOnclick = true;
          state.checkExistsTrigger = !state.checkExistsTrigger;
        } else {
          state.saveDataOnclick = true;
        }
      }
      //exists check
      else if (state.existsUrl) {
        state.checkExistsOnclick = true;
        state.checkExistsTrigger = !state.checkExistsTrigger;
      } else {
        state.updateDataOnclick = true;
      }
    },
    tier1DeleteOnclick(state, value) {
      //handle form data save
      if (!value.payload) {
        state.warningDataExpr = "delete";
        state.warningState = true;
      } else {
        state.deleteDataOnclick = true;
      }
    },
    tier1clearInputs(state) {
      state.selectedFieldData = { "": "" };
    },
  },
});

export const {
  setValue,
  tier1SaveOnclick,
  tier1UpdateOnclick,
  tier1clearInputs,
  tier1DeleteOnclick,
} = tier1addSlice.actions;
export default tier1addSlice.reducer;
