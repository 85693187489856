export const vehicle_log_book_TH = [
  {
    id: 0,
    caption: "AUTH?",
    dataField: "vlbAuthorised",
    alignment: "left",
    fixed: true,
    
  },
  {
    id: 1,
    caption: "ACTIVE",
    dataField: "vlbActive",
    alignment: "left",
    fixed: true,
   
  },

  {
    id: 2,
    caption: "REG. No",
    dataField: "vdtRegNo",
    alignment: "left",
    fixed: true,
    
  },
  {
    id: 3,
    caption: "DEPART. TIME",
    dataField: "vlbTimeOut",
    dataType: "datetime",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 4,
    caption: "ARRIVAL. TIME",
    dataField: "vlbTimeIn",
    dataType: "datetime",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 5,
    caption: "LOGGED BY",
    dataField: "loggedByEmpName",
    alignment: "left",
    fixed: false,
    width: null,
  },

  {
    id: 6,
    caption: "AUTHORISED BY",
    dataField: "authorisedByEmpName",
    alignment: "left",
    fixed: false,
    width: null,
  },

  {
    id: 7,
    caption: "FROM-TOWN",
    dataField: "vlbFromTown",
    alignment: "left",
    fixed: false,
    width: null,
  },

  {
    id: 8,
    caption: "TO-TOWN",
    dataField: "vlbToTown",
    alignment: "left",
    fixed: false,
    width: null,
  },

  {
    id: 10,
    caption: "PURPOSE",
    dataField: "vlbDescription",
    alignment: "left",
    fixed: false,
    width: null,
  },

  {
    id: 11,
    caption: "ODOM. START",
    dataField: "vlbOdometerStart",
    alignment: "left",
    dataType: "",
    fixed: false,
    width: null,
  },

  {
    id: 12,
    caption: "ODOM. FINAL",
    dataField: "vlbOdometerEnd",
    alignment: "left",
    dataType: "",
    fixed: false,
    width: null,
  },

  {
    id: 13,
    caption: "DISTANCE",
    dataField: "distanceTravelled",
    alignment: "left",
    dataType: "",
    fixed: false,
    width: null,
  },

  {
    id: 14,
    caption: "FROM. REGION",
    dataField: "fromRegionName",
    alignment: "left",
    fixed: false,
    width: null,
  },

  {
    id: 15,
    caption: "TO. REGION",
    dataField: "toRegionName",
    alignment: "left",
    fixed: false,
    width: null,
  },

  {
    id: 16,
    caption: "REMARKS",
    dataField: "vlbRmks",
    alignment: "left",
    fixed: false,
    width: 200,
  },
];

export const vehicle_log_book_data = [];


export const vehicle_log_book_Reg_No = [
  {
    id: 0,
    caption: "Reg No",
    dataField: "vdtRegNo",
    alignment: "left",
    fixed: true,
    width: 120,

  },
  {
    id: 1,
    caption: "Class",
    dataField: "",
    alignment: "left",
    fixed: true,
    width: null

  },
  {
    id: 2,
    caption: "Make",
    dataField: "",
    alignment: "left",
    fixed: true,
    width: null

  },
  {
    id: 3,
    caption: "Manufacturer",
    dataField: "vmkShtName",
    alignment: "left",
    fixed: true,
    width: 120

  },
  
  {
    id: 4,
    caption: "Fuel Type",
    dataField: "ftpShtName",
    alignment: "left",
    fixed: true,
    width: 120

  },
]


export const vehicle_log_book_Authorized_By = [

  {
    id: 0,
    caption: "STAFF No",
    dataField: "empStaffNo",
    alignment: "left",
    fixed: false,
    width: 100


  },
  {
    id: 0,
    caption: "Name",
    dataField: "empName",
    alignment: "left",
    fixed: false,
    width: 160


  },
  
  {
    id: 6,
    caption: "DEPT",
    dataField: "dptShtName",
    alignment: "left",
    fixed: false,
    width: null

  },
  
  {
    id: 7,
    caption: "SECTION",
    dataField: "sxnShtName",
    alignment: "left",
    fixed: false,
    width: null

  },
  
  {
    id: 5,
    caption: "JOB TITLE",
    dataField: "jbtShtName",
    alignment: "left",
    fixed: false,
    width: null

  },
  {
    id: 8,
    caption: "LOCATION",
    dataField: "locShtName",
    alignment: "left",
    fixed: false,
    width: null

  },
  
 

]



export const equipment_assignment_TH_2 = [
  {
    id: 0,
    caption: "STAFF ...",
    dataField: "ownerStaffNo",
    alignment: "left",
    fixed: true,
    width: 80,
  },
  {
    id: 1,
    caption: "STAFF NAME",
    dataField: "ownerName",
    alignment: "left",
    fixed: true,
    width: 160,
  },
  {
    id: 2,
    caption: "SERIAL No",
    dataField: "edtSerialNo",
    alignment: "left",
    fixed: true,
    width: 130,
  },
  {
    id: 3,
    caption: "TAG.",
    dataField: "edtTag",
    alignment: "left",
    fixed: true,
    width: 130,
  },
  {
    id: 4,
    caption: "EQUIP. CATEGORY",
    dataField: "hwtName",
    alignment: "left",
    fixed: true,
    width: 130,
  },
  {
    id: 5,
    caption: "EQUIP. TYPE",
    dataField: "etpName",
    alignment: "left",
    fixed: true,
    width: 130,
  },
  {
    id: 6,
    caption: "MODEL",
    dataField: "emdName",
    alignment: "left",
    fixed: false,
    width: 130,
  },

  {
    id: 7,
    caption: "ACTIVE?",
    dataField: "easActive",
    alignment: "left",
    fixed: false,
    width: 60,
  },

  {
    id: 8,
    caption: "DEPARTMENT",
    dataField: "ownerDptShtName",
    alignment: "left",
    fixed: false,
    width: 130,
  },
  {
    id: 9,
    caption: "ASSIGNED?",
    dataField: "edtAssigned",
    alignment: "left",
    fixed: false,
    width: 60,
  },
  {
    id: 10,
    caption: "SECTION",
    dataField: "ownerSxnShtName",
    alignment: "left",
    fixed: false,
    width: 130,
  },

  {
    id: 11,
    caption: "STATUS",
    dataField: "stsName",
    alignment: "left",
    fixed: false,
    width: 130,
  },

  {
    id: 12,
    caption: "MANUFACTURER",
    dataField: "mftName",
    alignment: "left",
    fixed: false,
    width: 130,
  },
  {
    id: 13,
    caption: "MANUF. DATE",
    dataField: "edtManufactureDate",
    alignment: "left",
    fixed: false,
    width: 130,
  },
  {
    id: 14,
    caption: "SUPPLY DATE",
    dataField: "edtSupplyDate",
    alignment: "left",
    fixed: false,
    width: 130,
  },

  {
    id: 15,
    caption: "REMARKS",
    dataField: "easRmks",
    alignment: "left",
    fixed: false,
    width: 4000,
  },
];