import CryptoJs from 'crypto-js'
// will have to remove the secretkey later on
const secretKey = 'JGVFUHJ878F7R8FRFRFRFRFRFRFBCVDUVCF';
export const userDataCrypt = (action: 'encrypt' | 'decrypt', value: any): any => {  

    let NotFound = "formCode not found"

    try {
        // Checking if the value is undefined or null
        if (value === undefined || value === null){
            // for now do nothing
            value = NotFound
            return value
        }
        if (action === 'encrypt') {
            return CryptoJs.AES.encrypt(value ?? NotFound, secretKey).toString();
        }
        return CryptoJs.AES.decrypt(value ?? NotFound, secretKey).toString(CryptoJs.enc.Utf8);
    } catch (error) {
        console.error('Error decrypting data:', error);
        return null; // or throw an error, log a message, etc.
    }
}
