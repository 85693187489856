/* eslint-disable react/jsx-pascal-case */
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import { setGeneralValue } from "../../../../../../features/generalSlice";
import useFetch from "../../../../../../hooks/useFetch";
import Datagrid_template from "../../../../../../templates/Datagrid";
import {
  navbar_height,
  toolbar_height,
} from "../../../../../accessories/component_infos";
import { HrSetupsCustomToolbar } from "../../../../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
import { Assets_setups_insuranceCompaniesColumns } from "./data/insuranceCompaniesData";
import { Insurance_Companies_form } from "./widgets/insuranceCompaniesForm";
import { RootState } from "../../../../../../app/store";
import {
  searchTextTypes,
  statusTypes,
} from "../../../../../human-resource/setups/data/types/selectsTypes";
import { WarningAlert } from "../../../../../accessories/warningAlert";
import { useBusyLoader } from "../../../../../../hooks/useBusyLoader";

export const Assets_Setups_InsuranceCompanies = forwardRef(
  ({ a }: any, ref) => {
    const news_flash_height = useSelector(
      (state: RootState) => state.general.newsflashHeight
    );
    useEffect(() => {
      //disable Update Mode
      dispatch(setGeneralValue({ expr: "updateMode", value: false }));

      //disable forms
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    },[]);

    // on page resize height responsiveness
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState<boolean>(false);
    useImperativeHandle(ref, () => ({
      test: () => {
        setTrigger(!trigger);
      },
    }));

    const [selectedData, setSelectedData] = useState({});

    //for refreshing data
    const [refresh, setRefresh] = useState<boolean>(false);
    const [busyLoader, setBusyLoader] = useBusyLoader(); //handle busy loading

    //navigation
    const setupPageRoute = window.location.pathname; //get current route of page
    const datagridOnlySearchParam = "table-only"; //search param in datagrid mode
    const formModeSearchParam = "form-and-table"; //search param in form mode mode
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    //miscellaneous heights
    const miscHeight = 7;

    // All heights out
    const heights_out = navbar_height + news_flash_height + toolbar_height;

    //varying form height
    const [form_height, set_form_height] = useState<number>(373.5);

    // Page resize height responsiveness
    useEffect(() => {
      settableHeight(window.innerHeight - (heights_out + miscHeight - 4));
    }, []);

    // handle interface switch
    const toggle_staff_form = (): void => {
      const view = searchParams.get("view");

      view === null || view === formModeSearchParam
        ? navigate({
            pathname: setupPageRoute,
            search: createSearchParams({
              view: datagridOnlySearchParam,
            }).toString(),
          })
        : navigate({
            pathname: setupPageRoute,
            search: createSearchParams({
              view: formModeSearchParam,
            }).toString(),
          });
    };
    const dataGridRowClick = (selectedFieldData: any) => {
      //disable Update Mode
      dispatch(setGeneralValue({ expr: "updateMode", value: false }));

      //disable forms
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));

      setSelectedData(selectedFieldData);
    };

    const datagridRowDoubleClick = () => {
      dispatch(setGeneralValue({ expr: "updateMode", value: true })); //disable form
      dispatch(setGeneralValue({ expr: "formDisabled", value: false })); //disable form
    };

    const [searchText, setSearchText] = useState<searchTextTypes>({
      temp: "",
      text: "",
    });
    const [status, setstatus] = useState<statusTypes>({ temp: "", status: "" });
    // data for da  tagrid
    const [data, error, loading] = useFetch(
      `InsuranceCompany/GetAllInsuranceCompanies?search=${searchText.text}&status=${status.status}`,
      refresh,
      "icmIDpk"
    );
    useEffect(() => {
      loading ? setBusyLoader(".") : setBusyLoader("");
    }, [loading]);

    //Table Height
    const pageHeight = window.innerHeight - (navbar_height + miscHeight); //height without navbar
    const [tableHeight, settableHeight] = useState(window.innerHeight); //height without navbar and toolbar

    return (
      <>
        {error && <WarningAlert />}

        <div style={{ height: pageHeight }} className="w-full">
          <div className="px-2">
            <HrSetupsCustomToolbar
              searchTextOnchange={(newText) => {
                setSearchText({ temp: newText, text: searchText.text });
              }}
              handleFind={() => {
                setSelectedData({});
                setSearchText({ temp: searchText.temp, text: searchText.temp });
                setstatus({ temp: status.temp, status: status.temp });
                setRefresh((current) => !current);
              }}
              checkOnChange={(newStatus) => {
                setstatus({ temp: newStatus, status: status.status });
              }}
              withDates={false}
              toggler={toggle_staff_form}
            />
          </div>

          {/* form section */}
          {searchParams.get("view") !== datagridOnlySearchParam && (
            <section
              style={{ height: form_height }}
              className="form border-b-4"
            >
              <Insurance_Companies_form
                resetData={() => setSelectedData({})}
                setBusyLoader={(text) => setBusyLoader(text)}
                refreshGrid={() => setRefresh(!refresh)}
                selectedItemData={selectedData}
              />
            </section>
          )}

          {/* datagrid section */}
          <section className="mt-1 px-2">
            <Datagrid_template
              onRowClick={dataGridRowClick}
              gridheight={
                searchParams.get("view") === formModeSearchParam ||
                searchParams.get("view") === null
                  ? window.innerHeight -
                    (form_height + heights_out + miscHeight)
                  : tableHeight
              }
              rowDoubleClicked={datagridRowDoubleClick}
              data={data}
              columns={Assets_setups_insuranceCompaniesColumns}
            />
          </section>
        </div>
      </>
    );
  }
);
