import { TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/accordion";

import { useEffect, useState } from "react";
import MaintenanceRequests from "./widgets/maintenanceRequests";




interface props {
    selected: string;

}


const MaintenanceEntriesScreen = ({ selected }: props) => {


    const [tabs, setTabs] = useState<string[]>([]);
    const [currentIndex, setCurrentIndex] = useState(0);



    // switch button toggler


    useEffect(() => {
        if (tabs.indexOf(selected) > -1 === true) {
            setCurrentIndex(tabs.indexOf(selected))

        } else {

            setTabs([...tabs, selected]);
            setCurrentIndex(tabs.length);
        }
        // alert(selected);
    }, [window.location.pathname])
    return (
        <div className="w-full h-full flex justify-center items-center">
            <TabPanel selectedIndex={currentIndex} className="w-full h-full">

                {
                    tabs.map((tab) => {
                        switch (tab) {
                            case 'TMS-Maintenance Requests':
                                return (
                                    <Item title={tab}>
                                        <MaintenanceRequests />
                                    </Item>
                                )
                            default:
                                return (
                                    <Item title={tab}>

                                    </Item>
                                )
                        }
                    })

                }

            </TabPanel>
        </div>
    );
}

export default MaintenanceEntriesScreen;