export const PerformanceSetups_CorporateMeasures_columns = [
    {id : 0, caption : 'PERIOD',dataField : 'prdName',datatype : 'string', width : 60, fixed : true, visible : true},
    {id : 1, caption : 'PERSPECTIVE', dataField : 'ptvName',datatype : 'string', width : 130, fixed : true , visible : true},
    {id : 2, caption : 'MEASURE', dataField : 'msrName',datatype : 'string', width : 130, fixed : true , visible : true},
    {id : 3, caption : 'TARGET', dataField : 'tgtName',datatype : 'string', width : 130, fixed : true , visible : true},
    {id : 4, caption : 'ORDER', dataField : 'cmsOrder',datatype : 'string', fixed : false , visible : true,},
    {id : 5, caption : 'ACTIVE', dataField : 'cmsActive',datatype : 'boolean', fixed : false , visible : true},
    {id : 6, caption : 'REMARKS',dataField : 'cmsRmks',datatype : 'string', width : 4000, fixed : false , visible : true}
]

 