/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useState } from 'react'
import { HrSetupsCustomToolbar } from '../../accessories/custom-toolbars/hrSetupsCustomtoolbar'
import { TabPanel } from 'devextreme-react'
import { Item } from 'devextreme-react/tab-panel'
import { CustomizableButton } from '../../accessories/buttons'
import { Access_Privilees_Assignement_Form, GetAllUsersInterface } from './widgets/apa_form'
import { Apa_right1 } from './widgets/apa_right1'
import Apa_right2 from './widgets/apa_right2'
import noSafety from "../../../assets/noSafety.png"
import access_logout from "../../../assets/access_logout.png"
import { AxiosError } from 'axios'
import { UpdateDataFunc, UpdateDataFuncParams } from '../../../functions/update'
import { useModalData } from '../../accessories/component_infos'
import { ModalTemplate } from '../../../templates/modal'
import TabsPanel_template from '../../../templates/tabsPanel_template'
import { useCrudFunc } from '../../../functions/crud'

const Access_Privilees_Assignement = () => {

    const [Refresh, setRefresh] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<GetAllUsersInterface>(null);



    const tabs: { id: number, title: string, contents: any }[] = [
        { id: 0, title: "Form Access Privileges", contents: <Apa_right1 userID={selectedUser?.usaIDpk} /> },
        { id: 1, title: "System Privileges", contents: <Apa_right2 rowData={selectedUser} /> },
    ]
    const [modal, setModal] = useModalData();

    const awakeActivateModal_AccessPriv_POST = () => {

        switch (selectedUser?.usaActive) {
            case true:
                setModal({
                    state: true,
                    title: "Disable Acc?",
                    message: `The account for ${selectedUser?.empName} is Enabled. Are you sure you want to Disable the account`,
                    icon: "question",
                    okText: "Yes",
                    cancelText: "No",
                    disableCancel: false,
                    okHandler: async () => {
                        setModal(prev => ({
                            ...prev,
                            state: false,

                        }))
                        await triggerActivate_or_Disable(`SysAdminFormAccessPrivileges/ActivateUser`, 0)
                    },
                    cancelHandler: () => {
                        setModal(prev => ({
                            ...prev,
                            state: false,

                        }))
                    }
                })
                break;
            case false:
                setModal({
                    state: true,
                    title: "Enable Acc?",
                    message: `The account for ${selectedUser?.empName} is Disabled. Are you sure you want to Activate the account`,
                    icon: "question",
                    okText: "Yes",
                    cancelText: "No",
                    disableCancel: false,
                    okHandler: async () => {
                        setModal(prev => ({
                            ...prev,
                            state: false,

                        }))
                        await triggerActivate_or_Disable(`SysAdminFormAccessPrivileges/ActivateUser`, 1)
                    },
                    cancelHandler: () => {
                        setModal(prev => ({
                            ...prev,
                            state: false,

                        }))
                    }
                })
                break;
            default:
                break;
        }
    }


    const [Posting, Updating, Patching] = useCrudFunc()
    const triggerActivate_or_Disable = useCallback(async (url: string, activateOrDeActivate: 0 | 1) => {

        try {

            let res = await Updating(`${url}?UserID=${selectedUser?.usaIDpk}&isDisable=${activateOrDeActivate}`,{},``)

            if (res.status === 200) {

                setModal({
                    state: true,
                    title: activateOrDeActivate === 0 ? "Account disabled" : "Enabled",
                    message: `Successfully ${activateOrDeActivate === 0 ? "Disabled" : "Enabled"} the account for ${selectedUser?.empName}`,
                    icon: "success",
                    okText: "OK",
                    cancelText: "No",
                    disableCancel: true,
                    okHandler: async () => {
                        setModal(prev => ({
                            ...prev,
                            state: false,

                        }))
                    },
                    cancelHandler: () => {
                        setModal(prev => ({
                            ...prev,
                            state: false,

                        }))
                    }
                })

                setTimeout(() => setRefresh(prev => !prev), 4000)

            }

        } catch (e) {
            if (e instanceof AxiosError) {
                console.log("Axios Error:", e.message);
            }
        }

    }, [selectedUser?.usaIDpk])


    const awakeLogoutModal = () => {



        switch (selectedUser?.usaLoggedIn) {

            case true:
                setModal({
                    state: true,
                    title: "Log Out Active User",
                    message: `Are you sure you want to log Out the account for ${selectedUser.usaUsername} - "${selectedUser?.empName}"`,
                    icon: "question",
                    okText: "Yes",
                    cancelText: "No",
                    disableCancel: false,
                    okHandler: async () => {
                        setModal(prev => ({
                            ...prev,
                            state: false,

                        }))
                        await LogOutActiveUser()
                    },
                    cancelHandler: () => {
                        setModal(prev => ({
                            ...prev,
                            state: false,

                        }))
                    }
                })
                break;

            case false:
                setModal({
                    state: true,
                    title: "User logged Out?",
                    message: `The account for ${selectedUser?.empName} is already logged out.`,
                    icon: "success",
                    okText: "OK",
                    cancelText: "No",
                    disableCancel: true,
                    okHandler: async () => {
                        setModal(prev => ({
                            ...prev,
                            state: false,

                        }))
                    },
                    cancelHandler: () => {
                        setModal(prev => ({
                            ...prev,
                            state: false,

                        }))
                    }
                })
                break;
            default:
                break;

        }
    }

    const LogOutActiveUser = useCallback(async () => {

        try {

            let res = await Updating(`SysAdminFormAccessPrivileges/LogOutActiveUser`, {
                UserID: selectedUser?.usaIDpk
            },``)

            if (res.status === 200) {
                setModal({
                    state: true,
                    title: "Logged Out",
                    message: `Account disabled for ${selectedUser?.empName}`,
                    icon: "success",
                    okText: "OK",
                    cancelText: "No",
                    disableCancel: true,
                    okHandler: async () => {
                        setModal(prev => ({
                            ...prev,
                            state: false,

                        }))
                    },
                    cancelHandler: () => {
                        setModal(prev => ({
                            ...prev,
                            state: false,

                        }))
                    }
                })

                setTimeout(() => setRefresh(prev => !prev), 4000)


            }

        } catch (e) {
            if (e instanceof AxiosError) {
                console.log("Axios Error:", e.message);
            }
        }
    }, [selectedUser?.empName, selectedUser?.usaIDpk])

    return (
        <div className='w-full h-full px-2'>

            <ModalTemplate
                width={500}
                icon={modal.icon}
                disableCancel={modal.disableCancel}
                cancelText={modal.cancelText}
                open={modal.state}
                okHandler={modal.okHandler}
                cancelHandler={modal.cancelHandler}
                message={modal.message}
                okText={modal.okText}
                title={modal.title}
            />

            {/* toolbar */}
            <div className="w-full">
                <HrSetupsCustomToolbar
                    withDates={false}
                    searchCriteria={false}
                    checkBoxAfterHelp={false}
                    useCallBackFunc
                    isSwitch={false}
                    isSearch={false}
                    isAFRP={false}
                    extraWidgetsAfterSwitch={
                        <div className="flex pr-1">
                            <li className="" onClick={awakeActivateModal_AccessPriv_POST}><CustomizableButton handleOnClick={undefined} buttonID="lockedScenario" isTooltip tooltipMsg={"Lock all scenario"} classname={"p-1"} customizableBtnImage={noSafety} /></li>

                            <li className="pl-1"
                                onClick={awakeLogoutModal}
                            > <CustomizableButton buttonID="unlockedScenario" isTooltip tooltipMsg={"unLock all scenario"} classname={"p-1"} customizableBtnImage={access_logout} /></li>

                        </div>
                    }
                />
            </div>

            {/* Forms */}
            <section className="w-full h-full flex justify-between space-x-2">
                {/* leftForm */}
                <aside className='w-[45%]'>
                    <Access_Privilees_Assignement_Form
                        setSelectedUser={setSelectedUser}
                        refresh={Refresh}
                    />
                </aside>

                {/* rightForm */}
                <aside className="w-[55%]">
                    <TabsPanel_template tabs={tabs} />

                </aside>
            </section>

        </div>
    )
}

export default Access_Privilees_Assignement;