
// WILL HAVE TO FIND VALUES for act.freq and monitorig mdoule

export const signOff_cols = [
    {id: 1,caption : "ID", dataField : 'empID',  fixed : true,width:60, visible:false},
    {id: 2,caption : "SUBM?", dataField : 'siaSubmitted', fixed : true,width:100},
    {id: 3,caption : "LOCKED?", dataField : 'siaLocked', fixed : true,width:100},
    {id: 4,caption : "SIGNED-OFF", dataField : 'siaSignedOff', fixed : true,width:100},
    {id: 5,caption : "ACKN?", dataField : 'siaAcknowledged', fixed : true,width:100},
    {id: 6,caption : "STATE", dataField : 'siaState',  fixed : true, width:100},
    {id: 7,caption : "STAFF NAME", dataField : 'empStaffNo', width:100},
    {id: 8,caption : "PERIOD", dataField : 'prdName', width:100},
    {id: 9,caption : "ACTIVE? ", dataField : 'siaActive', width:100},
    {id: 10,caption : "DEPT", dataField : 'dptName', width:100},
    {id: 11,caption : "SECTION", dataField : 'sxnName', width:100},
    {id: 12,caption : "STAFF INITIATIVE", dataField : 'Location', width:100},
    {id: 13,caption : "TARGET", dataField : 'tgtName', width:100},
    {id: 14,caption : "TARG. FREQ", dataField : 'siaTargetFrequency', width:100},
    {id: 15,caption : "MEASURE", dataField : 'msrName', width:100},
    {id: 16,caption : "WEIGHT", dataField : 'siaWeight', width:100},
    {id: 17,caption : "STAFF ACTUAL", dataField : 'staffActualIDpk', width:100},
    {id: 18,caption : "ACT. FREQ", dataField : '', width:100},
    {id: 19,caption : "STAFF % COMPLETE", dataField : 'siaStaffPercentageComplete', width:100},
    {id: 20,caption : "STAFF RATING", dataField : 'staffRatingRate', width:100},
    {id: 21,caption : "STAFF RATE INTERPR.", dataField : 'staffRatingInterpretation', width:100},
    {id: 22,caption : "SUP ACTUAL", dataField : 'supActualIDpk', width:100},
    {id: 23,caption : "SUP % COMPLETE", dataField : 'siaSupPercentageComplete', width:100},
    {id: 24,caption : "SUP RATING", dataField : 'supRatingGrade', width:100},
    {id: 25,caption : "SUP RATE INTERPR.", dataField : 'supRatingInterpretation', width:100},
    {id: 26,caption : "ATF?", dataField : 'siaApplyTargetFrequency', width:100},
    {id: 27,caption : "AAMU?", dataField : 'siaAllowActualsManualUpdate', width:100},
    {id: 28,caption : "CMPLSRY?", dataField : 'siaCompulsory', width:100},
    {id: 29,caption : "ORDER", dataField : 'siaOrder', width:100},
    {id: 30,caption : "START DATE", dataField : 'prdStartDate', width:100},
    {id: 31,caption : "END DATE", dataField : 'prdEndDate', width:100},
    {id: 32,caption : "UNIT", dataField : 'untName', width:100},
    {id: 33,caption : "UNIT INITIATIVE", dataField : 'uinName', width:100},
    {id: 34,caption : "CORP. INITIATIVE", dataField : 'cinName', width:100},
    {id: 35,caption : "STAKEHOLDERS", dataField : 'siaStakeholders', width:100},
    {id: 36,caption : "MONITORING MODULE", dataField : '', width:100},
    {id: 37,caption : "SUP STAFF No", dataField : 'supStaffNo', width:100},
    {id: 38,caption : "SUPERVISOR", dataField : 'supEmpName', width:100},
    {id: 39,caption : "SUP. JOB TITLE", dataField : 'supJbtShtName', width:100},
    {id: 40,caption : "SUP DEPT", dataField : 'supDeptShtName', width:100},
    {id: 41,caption : "SUP SECTION", dataField : 'supSxnShtName', width:100},
    {id: 42,caption : "MGR STAFF No", dataField : 'mgrStaffNo', width:100},
    {id: 43,caption : "MANAGER", dataField : 'mgrEmpName', width:100},
    {id: 44,caption : "MGR JOB TITLE", dataField : 'mgrJbtShtName', width:100},
    {id: 45,caption : "MGR DEPT", dataField : 'mgrDeptShtName', width:100},
    {id: 46,caption : "MGR SECTION", dataField : 'mgrSxnShtName', width:100},
    {id: 47,caption : "DIR STAFF No", dataField : 'dirStaffNo', width:100},
    {id: 48,caption : "DIRECTOR", dataField : 'dirEmpName', width:100},
    {id: 49,caption : "DIR JOB TITLE", dataField : 'dirJbtShtName', width:100},
    {id: 50,caption : "DIR DEPT", dataField : 'dirDeptShtName', width:100},
    {id: 51,caption : "DIR SECTION", dataField : 'dirSxnShtName', width:100},
    {id: 52,caption : "REMARKS", dataField : 'siaRmks', width:4000},
]