export const footer_datagrid_cols = [
  { id: 0, caption: "REG No", dataField: "vdtRegNo", dataType: "", width: 100 },
  {
    id: 1,
    caption: "DATE IN",
    dataField: "pmaDateIn",
    dataType: "datetime",
    fixed: true,
    width: 100,
  },
  {
    id: 2,
    caption: "DATE OUT",
    dataField: "pmaDateOut",
    dataType: "datetime",
    fixed: true,
    width: 100,
  },
  {
    id: 3,
    caption: "MAINT. TYPE",
    dataField: "mtpName",
    dataType: "string",
    fixed: true,
    width: 100,
  },
  {
    id: 4,
    caption: "WORKSHOP",
    dataField: "wsdName",
    dataType: "",
    width: 100,
  },
  {
    id: 5,
    caption: "AVRG. %COMPLETE",
    dataField: "pmaPercentComplete",
    dataType: "",
    fixed: true,
    width: 100,
  },
  {
    id: 6,
    caption: "REPORTING DRIVER",
    dataField: "driverEmpName",
    dataType: "string",
    fixed: true,
    width: 100,
  },
 
  {
    id: 7,
    caption: "ODOM IN",
    dataField: "pmaOdometerIn",
    dataType: "",
    width: 100,
  },
  {
    id: 8,
    caption: "ODOM OUT",
    dataField: "pmaOdometerOut",
    dataType: "",
    width: 100,
  },
  {
    id: 9,
    caption: "DISTANCE",
    dataField: "distanceCovered",
    dataType: "",
    width: 100,
  },
  {
    id: 10,
    caption: "FUEL IN",
    dataField: "fuelLevelInName",
    dataType: "",
    width: 100,
  },
  {
    id: 11,
    caption: "FUEL OUT",
    dataField: "fuelLevelOutName",
    dataType: "",
    width: 100,
  },
  {
    id: 12,
    caption: "FUEL OUT",
    dataField: "fuelLevelOutName",
    dataType: "",
    width: 100,
  },
  {
    id: 13,
    caption: "ARRIVAL CONDITION",
    dataField: "pmaArrivalCondition",
    dataType: "",
    width: 100,
  },
  { id: 14, caption: "NOTES", dataField: "pmaNotes", dataType: "", width: 100 },
  {
    id: 15,
    caption: "VEH. CLASS",
    dataField: "vhcName",
    dataType: "",
    width: 100,
  },
  {
    id: 16,
    caption: "VEH. MAKE",
    dataField: "vmkName",
    dataType: "",
    width: 100,
  },
  {
    id: 17,
    caption: "VEH. MODEL",
    dataField: "mdlName",
    dataType: "",
    width: 100,
  },
  {
    id: 18,
    caption: "ACTIVE",
    dataField: "pmaActive",
    dataType: "",
    width: 100,
  },
  {
    id: 19,
    caption: "REMARKS",
    dataField: "pmaRmks",
    dataType: "string",
    width: 4000,
  },
];

// Right side pane window

export const attachement_cols = [];
export const comments_cols = [];
export const share_cols = [];
export const trails_cols = [];
export const images_cols = [];
export const worshop_col =[
  {
      id:0,
      caption:'Workshop',
      dataField:"wsdName",
      dataType:"string",
      width:200,
      fixed:false,
  },
  {
      id:1,
      caption:'Town',
      dataField:"locName",
      dataType:"string",
      width:200,
      fixed:false,
  },
  {
      id:2,
      caption:'Area',
      dataField:"mrqRmks",
      dataType:"string",
      width:200,
      fixed:false,
  },
  {
      id:3,
      caption:'Location',
      dataField:"locName",
      dataType:"string",
      width:200,
      fixed:false,
  },
  {
      id:4,
      caption:'Contacts ',
      dataField:"wsdContact",
      dataType:"string",
      width:200,
      fixed:false,
  },
  {
      id:5,
      caption:'INTERCO..',
      dataField:"",
      dataType:"string",
      width:200,
      fixed:false,
  },
  {
      id:6,
      caption:'Ext?',
      dataField:"",
      dataType:"string",
      width:200,
      fixed:false,
  },
]
export const reportingDriver_col =[
  {
      id:0,
      caption:'Staff no',
      dataField:"empStaffNo",
      dataType:"string",
      width:60,
      fixed:false,
  }
  ,
  {
      id:1,
      caption:'Name',
      dataField:"empNameStaffNo",
      dataType:"string",
      width:200,
      fixed:false,
  }
  ,
  {
      id:2,
      caption:'Dept',
      dataField:"dptShtName",
      dataType:"string",
      width:100,
      fixed:false,
  }
  ,
  {
      id:3,
      caption:'Section',
      dataField:"sxnShtName",
      dataType:"string",
      width:100,
      fixed:false,
  }
  ,
  {
      id:4,
      caption:'Job Title',
      dataField:"jbtShtName",
      dataType:"string",
      width:100,
      fixed:false,
  }
  ,
  {
      id:5,
      caption:'Location',
      dataField:"locShtName",
      dataType:"string",
      width:200,
      fixed:false,
  }
]