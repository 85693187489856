/* eslint-disable react/jsx-pascal-case */
import { Left_Grid_Form, } from "./widgets/leftGrid";
import { Right_Grid_Form } from "./widgets/rightGrid";

import { Footer_datagrid_template } from "./data/footer_datagrid";
import { Maintenance_activitiestoolbar } from "../../../../../accessories/custom-toolbars/maintenance_activitiestoolbar";
import { navbar_height } from "../../../../../accessories/component_infos";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";
import { footer_datagrid_cols } from "../../../../../Safety/Entries/PPE/Distribution/data/datagrid_cols";

interface props {
  state_change: boolean;
}

export const Fuel_Dump_Attendants = ({ state_change }: props) => {
  const news_flash_height = useSelector((state: RootState) => state.general.newsflashHeight);
  const heights_out = navbar_height + news_flash_height;
  const grid_footer_div_height = window.innerHeight - (heights_out + 85)

  const miscHeight = 43
  const pageHeight = window.innerHeight - (navbar_height + miscHeight);

  return (
    <div style={{ height: pageHeight }} className="h-full dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base mt-1 ">
      <div >
        <Maintenance_activitiestoolbar />
      </div>
      <div className="w-full  ">

        <div style={{ height: "350px" }} className=" w-full px-2 flex justify-between gap-x-2 border-b-4 mb-0.5 pb-0.5 ">
          {/* Internal toggler : expands the right taskUpdate horizontally */}

          <div className="w-1/2"  >
            <Left_Grid_Form />
          </div>

          {/* Double tap(click) feature to toggle to and fro of the right taskUpdate section */}
          <div
            className="h-full w-1/2  rounded-md items-center dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base "
          >
            <Right_Grid_Form />

          </div>
        </div>
      </div>

      {/* Footer gridtable  */}
      <div
        style={{ height: grid_footer_div_height }}
        className="px-2 dark:bg-slate-900 dark:text-darkModeSkin-base "
      >

        <Footer_datagrid_template
          gridheight={425}
          columns={footer_datagrid_cols}
          data={[]}
        />
      </div>
    </div>
  );
};
