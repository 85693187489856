export const assets_transunitsColumns = [
  {
    id: 0,
    caption: "TRANSPORT UNIT",
    dataField: "truName",
    dataType: "number",
    width: 200,
    fixed: true,
    visible: true,
  },
  {
    id: 1,
    caption: "SHORT NAME",
    dataField: "truShtName",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 2,
    caption: "DEPT",
    dataField: "dptName",
    dataType: "string",
    width: 160,
    fixed: true,
    visible: true,
  },
  {
    id: 3,
    caption: "SECTION",
    dataField: "sxnName",
    dataType: "string",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 4,
    caption: "UNIT",
    dataField: "untName",
    dataType: "string",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 5,
    caption: "ORDER",
    dataField: "truOrder",
    dataType: "",
    width: 130,
    fixed: true,
    visible: true,
  },
  {
    id: 6,
    caption: "ACTIVE",
    dataField: "truActive",
    dataType: "boolean",
    width: 90,
    fixed: true,
    visible: true,
  },
  {
    id: 7,
    caption: "REMARKS",
    dataField: "truRmks",
    dataType: "string",
    width: 5000,
    fixed: false,
    visible: true,
  },
];
