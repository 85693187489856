import { Tooltip } from "devextreme-react";
import { CheckBox } from "devextreme-react/check-box";
import "devextreme/dist/css/dx.light.css";
import { useEffect, useState } from "react";
import edit from "../../../../../../../assets/edit.png";
import newfile from "../../../../../../../assets/new.png";
import question from "../../../../../../../assets/question.png";
import alter from "../../../../../../../assets/alter.png";

import close from "../../../../../../../assets/close.png";
import printer from "../../../../../../../assets/printer.png";
import reset from "../../../../../../../assets/refresh-small.png";

import { Checkbox, DatePicker, Input, Select } from "antd";
import { FaBinoculars } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import {
  setGeneralValue,
  settask_footer_update,
  settask_form_disable,
} from "../../../../../../../features/generalSlice";
import {
  task_footer_update,
  task_form_disable,
} from "../../../../../../../features/Task&Todo/Task/TaskDetails";
import {
  AlertSMS,
  AppraiseSettings,
  DeleteTask,
  TaskSettings,
} from "../../../../../../accessories/custom-toolbars/widgets/customToolbarModals";
import { saveModalData } from "../../../../../../accessories/component_infos";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import dayjs from "dayjs";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
interface props {
  toggler?: () => void;
  handleDelete?: () => void; //delete handler
  handleNew?: (value: boolean) => void;
  handleSave?: () => void; //save handler
  handleUpdate?: () => void; //update handler
  searchTextOnchange?: (value: string) => void; //search text change
  startDateChanged?: (value: any) => void; //start date change
  endDateChanged?: (value: any) => void; //end date change
  checkOnChange?: (value: any) => void; //checkbox change
  handleFind?: () => void; //find handler
  handleRefresh?: () => void; //refresh main grid
  handlePrint?: () => void; // handle print functionality
  useCallBackFunc?: boolean;
  withDates?: boolean; //add dates to the toolbar?
  toggleNew?: boolean;
  toggleUpdate?: boolean;
  handleEdit?: (value: boolean) => void;
  setSwitchToggle?: (e: boolean) => void;
  startDate?: string;
  endDate?: string;
  outerSelected?: any;
  setOuterSelected?: (e: any) => void;
  handlewordWrap?: () => void;
}

export const MaintenanceRequestsToolbar = ({
  toggler,
  handleRefresh,
  handlePrint,
  handleFind,
  checkOnChange,
  startDateChanged,
  endDateChanged,
  setOuterSelected,
  searchTextOnchange,
  outerSelected,
  startDate,
  endDate,
  setSwitchToggle,
  handlewordWrap,
}: props) => {
  const { Option } = Select;

  const { Search } = Input;

  const dateFormat = " DD MMM YYYY";

  const [switchiconMsg, setSwitchiconMsg] = useState<string>("Personal");
  const [isActive, setIsActive] = useState<boolean>(false);
  const [SettingsisActive, SettingssetIsActive] = useState<boolean>(false);
  const [AlertSMSisActive, AlertSMSsetIsActive] = useState<boolean>(false);
  const [AppraiseisActive, AppraisesetIsActive] = useState<boolean>(false);
  const size: number = 16;
  const mainReqClear = useSelector(
    (state: RootState) => state.general.mainReqClear
  );

  const disablex = useSelector(
    (state: RootState) => state.addTask.taskFormStatus
  );
  // const disablex = useSelector((state: RootState) => state.general.M_Request);
  const updateMode = useSelector(
    (state: RootState) => state.addTask.taskFooterUpdate
  );
  const selectedRecord = useSelector(
    (state: RootState) => state.addTask.selectedRecord
  );
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const [refreshValue, setRefreshValue] = useState(false);
  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [updatingstatus, setUpdatingstatus] = useState(false);
  const [showCancel, setshowCancel] = useState(false);
  const [Active, setActive] = useState(false);
  const [refreshx, setRefreshx] = useState(false);

  const sideAlert = useSelector(
    (state: RootState) => state.general.sideAlertTest
  );

  const dispatch = useDispatch();
  // relative to the new button
  const handleNew = () => {
    // dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
    dispatch(setGeneralValue({ expr: "M_Request", value: false }));
    dispatch(setGeneralValue({ expr: "mainReqClear", value: true }));

    dispatch(task_form_disable(false));
    dispatch(settask_form_disable(false));

    //  this destroys the update mode state
    dispatch(task_footer_update(false));
    dispatch(settask_footer_update(false));

    //clear data to redux
    dispatch(setGeneralValue({ expr: "selectedFieldData", value: [] }));
    setSwitchToggle && setSwitchToggle(true);
  };

  const handleCancel = () => {
    // dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    dispatch(setGeneralValue({ expr: "M_Request", value: true }));
    // dispatch(setGeneralValue({ expr: "mainReqClear", value: false }));
    dispatch(task_form_disable(true));
    dispatch(settask_form_disable(true));
    // dispatch(update_route_state(false))
    dispatch(task_footer_update(false));
    dispatch(settask_footer_update(false));

    // dispatch(settask_footer_update(false))
    dispatch(setGeneralValue({ expr: "updateMode", value: false }));

    // NO ONE SHOULD DELETE THIS !!!!
    // handleCallbackCancel!();
  };
  const [searchParam, setSearchParam] = useState(0);
  // alert(selectedRecord.mrqIDpk)


  //handle edit
  // const handleUpdate = () => {
  //   if (mainReqClear === false) {
  //     if (selectedRecord===undefined) {
  //       setSwitchToggle && setSwitchToggle(true);
  //       dispatch(task_form_disable(true));
  //       setIcon("warning");
  //       setshowModalDisableButton(true);
  //       setshowCancel(false);
  //       setModalData({
  //         message: "No record selected",
  //         title: "Invalid record",
  //         okText: "Ok",
  //       });
  //       setOpenModal(true);
  //     } else {
  //       setSwitchToggle && setSwitchToggle(true);
  //       //enable forms
  //       dispatch(task_form_disable(false));

  //       //enable Update Mode
  //       dispatch(task_footer_update(true));
  //     }
  //   } else {
  //     setSwitchToggle && setSwitchToggle(true);
  //     dispatch(task_form_disable(true));
  //     setIcon("warning");
  //     setshowModalDisableButton(true);
  //     setshowCancel(false);
  //     setModalData({
  //       message: "No record selected",
  //       title: "Invalid record",
  //       okText: "Ok",
  //     });
  //     setOpenModal(true);
  //   }
  // };
  const handleUpdateAndValidate = () => {
    // First, check if there's a main request to clear
    if (mainReqClear === false) {
      // Check if a record is selected
      if (selectedRecord === undefined) {
        // No record selected scenario
        commonModalSetup("No record selected", "Invalid record", "Ok");
      } else {
        // Record is selected, proceed with validation
        if (selectedRecord.mrqRequestedByEmpIDfk !== employeeId) {
          commonModalSetup("You did not submit this request and so cannot edit it", "Access Denied!");
        } else if (selectedRecord.mrqAcknowledged === true) {
          commonModalSetup("This request has been acknowledged and so cannot be edited", "Access Denied!");
        } else if (selectedRecord.mtaOpened === true) {
          commonModalSetup("A Job Card has been opened for this request. It cannot be edited.", "Access Denied!");
        } else {
          // All validations passed, enable update mode
          setSwitchToggle && setSwitchToggle(true);
          dispatch(task_form_disable(false));
          dispatch(task_footer_update(true));
        }
      }
    } else {
      // Main request is to clear, similar to no record selected scenario
      commonModalSetup("No record selected", "Invalid record", "Ok");
    }
  };
  
  // Helper function to setup modal with common actions
  const commonModalSetup = (message, title, okText = "Ok") => {
    setSwitchToggle && setSwitchToggle(true);
    dispatch(task_form_disable(true));
    setIcon("warning");
    setshowModalDisableButton(true);
    setshowCancel(false);
    setModalData({ message, title, okText });
    setOpenModal(true);
  };
  // Three state checkboxes
  const [indeterminate, setIndeterminate] = useState<any>(true);
  const [checkedBox, setCheckedBox] = useState<any>(false);
  const [checkValue, setCheckValue] = useState<boolean | string>(""); //actual value to use
  const [searchTextValue, setSearchTextValue] = useState("");

  // const [criteriaDataLocal, setCriterialDataLocal] = useState({ id: searchCriteriaData![0]?.id ?? 0, value: searchCriteriaData![0]?.value ?? '' });

  useEffect(() => {
    dispatch(setGeneralValue({ expr: "activeStatus", value: checkValue }));
    checkOnChange && checkOnChange(checkValue);
  }, [checkValue]);

  const onChange = (value: boolean) => {
    if (indeterminate === true) {
      setCheckedBox(true);
      setIndeterminate(false);
      setCheckValue(true);
    } else if (!indeterminate && checkedBox) {
      setCheckedBox(false);
      setIndeterminate(false);
      setCheckValue(false);
    } else if (!indeterminate && !checkedBox) {
      setCheckedBox(false);
      setIndeterminate(true);
      setCheckValue("");
    }
  };


  const [employeeId, userId, userInfo] = useAccountInfo();
  //Access Priviledge

  const [privType, setPrivType] = useState<string>("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);
  //new button
  const newAccessPriv = () => {
    if (savePriv === true) {
      handleNew();
    } else {
      setIcon("warning");
      setshowModalDisableButton(true);
      setshowCancel(false);
      setModalData({ message: "No Access Privilege", title: "Access Denied!" });
      setOpenModal(true);
    }
  };

//validate edit
// const validateEdit = () => {
//   if(selectedRecord?.mrqRequestedByEmpIDfk !== employeeId){
//     setModalData({ message: "You did not submit this request and so cannot edit it", title: "Access Denied!" });
//     setOpenModal(true);
//     setIcon("warning");
//     setshowCancel(false);
//     dispatch(task_form_disable(true));
//     dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
// }else if(selectedRecord?.mrqAcknowledged===true){
//     setModalData({ message: "This request has been acknowledged and so cannot be edited", title: "Access Denied!" });
//     setOpenModal(true);
//     setIcon("warning");
//     setshowCancel(false);
//     dispatch(task_form_disable(true));
//     dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
// }else if(selectedRecord?.mtaOpened===true){
//   setModalData({ message: "A Job Card has been opened for this request.It cannot be edited.", title: "Access Denied!" });
//   setOpenModal(true); 
//   setIcon("warning"); 
//   setshowCancel(false);
//   dispatch(task_form_disable(true));
//   dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
// }else{
 
// }
// }

  //edit button
  const editAccessPriv = () => {
    if (updatePriv === true) {
      handleUpdateAndValidate()
      // validateEdit();
      // handleUpdate();
     
   
    } else {
      setIcon("warning");
      setshowModalDisableButton(true);
      setshowCancel(false);
      setModalData({ message: "No Access Privilege", title: "Access Denied!" });
      setOpenModal(true);
    }
  };
  //find bitton
  const findAccessPriv = () => {
    if (readPriv === true) {
      // alert("FIND")
      handleFind && handleFind();
    } else {
      setIcon("warning");
      setshowModalDisableButton(true);
      setshowCancel(false);
      setModalData({ message: "No Access Privilege", title: "Access Denied!" });
      setOpenModal(true);
    }
  };
  //print button
  const printAccessPriv = () => {
    if (readPriv === true) {
      handlePrint && handlePrint();
    } else {
      setIcon("warning");
      setshowModalDisableButton(true);
      setshowCancel(false);
      setModalData({ message: "No Access Privilege", title: "Access Denied!" });
      setOpenModal(true);
    }
  };
  return (
    <div
      style={{
        borderWidth: "1px",
        height: 32,
        borderColor: borderTheme,
        width: 4000,
      }}
      className="px-0.5 mt-0.5 rounded  w-full h-full flex  border-gray-200 mb-1"
    >
      <div className="">
        <ModalTemplate
          icon_width={modalData.iconSize === "large" ? 100 : 50}
          icon={icon}
          cancelText={modalData.cancelText}
          disableCancel={icon === "warning" ? true : false}
          cancelHandler={() => {
            setOpenModal(false);
          }}
          open={openModal}
          okHandler={() => {
            setOpenModal(false);
          }}
          message={modalData.message}
          okText={modalData.okText}
          title={modalData.title}
        />
        <ul className="flex justify-evenly w-full items-center h-full py-0.5">
          {disablex || updateMode ? (
            <li
              id="newRecord"
              onClick={() => newAccessPriv()}
              style={{
                borderWidth: "1px",
                borderColor: borderTheme,
                width: "62px",
              }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img className="pr-1 " src={newfile} />
                New{" "}
              </span>
              <Tooltip
                target="#newRecord"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">New Record</p>
              </Tooltip>
            </li>
          ) : (
            <li
              id="close-cancel"
              onClick={() => handleCancel()}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img className="pr-1 " src={close} />
                Cancel{" "}
              </span>
              <Tooltip
                target="#close-cancel"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Cancel</p>
              </Tooltip>
            </li>
          )}
          {!updateMode ? (
            <li
              id="editRecordst"
              onClick={() => {
                editAccessPriv();
              }}
              style={{
                borderWidth: "1px",
                borderColor: borderTheme,
                width: "58px",
              }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img
                  className="dark:bg-slate-900 pr-1 dark:text-gray-100 duration-100"
                  src={edit}
                />
                Edit{" "}
              </span>
              <Tooltip
                target="#editRecordst"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Edit Record</p>
              </Tooltip>
            </li>
          ) : (
            <li
              id="close-cancel"
              onClick={() => handleCancel()}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img className="pr-1 " src={close} />
                Cancel{" "}
              </span>
              <Tooltip
                target="#close-cancel"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Cancel</p>
              </Tooltip>
            </li>
          )}

          <li
            id="switch"
            onClick={toggler}
            style={{
              borderWidth: "1px",
              borderColor: borderTheme,
              width: "68px",
            }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
          >
            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
              {" "}
              <img
                className="dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"
                src={alter}
              />
              Switch{" "}
            </span>
            <Tooltip
              target="#switch"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="h-2">Switch interface / table view</p>
            </Tooltip>
          </li>
          <li
            id="check"
            onClick={() => {
              handlewordWrap && handlewordWrap();
            }}
            style={{
              borderWidth: "1px",
              paddingLeft: 3,
              paddingRight: 3,
              borderColor: borderTheme,
            }}
            className="  border-slate-200  hover:cursor-pointer mr-1  text-xs px-1 text-gray-600 rounded flex items-center h-[24px]"
          >
            <span className="flex">
              <Checkbox />{" "}
            </span>
            <Tooltip
              target="#check"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p>Click to show multiple or single row lines in the table</p>
            </Tooltip>
          </li>

          <li
            id="help"
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center"
          >
            <span className="flex">
              {" "}
              <img
                className="dark:bg-slate-900  dark:text-gray-100 duration-100"
                src={question}
              />{" "}
            </span>
            <Tooltip
              target="#help"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p>Help Center</p>
            </Tooltip>
          </li>

          <li className="px-1 text-xs">Search Criteria</li>
          <li className="items-right  ">
            <Select
              showSearch
              onChange={(object) => {
                setSearchParam(object);
              }}
              value={searchParam}
              size="small"
              allowClear={false}
              style={{ width: `${sideAlert === false ? 60 : 120}px` }}
              dropdownStyle={{ minWidth: "250px" }}
            >
              {[
                {
                  label: "Reg. No",
                },
                {
                  label: "Driver",
                },
                // {
                //   label: "Workshop",
                // },
                {
                  label: "Job No",
                },
                {
                  label: "Defect Report",
                },
                {
                  label: "Closing Remarks",
                },
              ]?.map((option: any, index) => {
                return (
                  <Select.Option value={index}>{option.label}</Select.Option>
                );
              })}
              {/* : formTableData?.map((option:any)=>{
                                return(
                                    <Select.Option  key={option[idexpr!]} value ={JSON.stringify(option)}>{option[dataexp!]}</Select.Option>
                                )
                            })} */}
            </Select>
          </li>
        </ul>
      </div>

      <div className="flex">
        <ul className="ml-4 flex items-center">
          <li className="px-1 flex items-center flex-row ml-1 ">
            <label style={{ width: "70px" }} className=" text-xs">
              Search Text
            </label>
            <Input
              onChange={(e: any) => {
                const searchText: string = e.target.value;
                searchTextOnchange && searchTextOnchange(searchText);
                setSearchTextValue(e.target.value);
              }}
              value={searchTextValue}
              name="search"
              id="search"
              size="small"
              allowClear={true}
              style={{ width: 130, height: "24px" }}
            />
          </li>
          <li className="px-0.5">
            <DatePicker
              value={dayjs(startDate) ?? dayjs()}
              clearIcon={<span style={{ display: "none" }}></span>}
              onChange={startDateChanged}
              size="small"
              placeholder="Start Date"
              style={{ width: 125 }}
              format={"DD MMM YYYY"}
            />
          </li>
          <li className="px-0.5">
            <DatePicker
              value={dayjs(endDate) ?? dayjs()}
              clearIcon={<span style={{ display: "none" }}></span>}
              onChange={endDateChanged}
              size="small"
              placeholder="End Date"
              style={{ width: 125 }}
              format={"DD MMM YYYY"}
            />
          </li>
          <li
            id="status"
            style={{
              borderWidth: "1px",
              paddingLeft: 3,
              paddingRight: 3,
              borderColor: borderTheme,
            }}
            className="  border-slate-200  hover:cursor-pointer mr-1  text-xs px-1 text-gray-600 rounded flex items-center h-[24px]"
          >
            <Checkbox
              checked={checkedBox}
              indeterminate={indeterminate}
              onChange={(checkValue: any) => {
                onChange(checkValue);
              }}
            />
            <Tooltip
              target="#status"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Show Status</p>
            </Tooltip>
          </li>
          <li
            onClick={() => {
              findAccessPriv();
            }}
            id="findst"
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
          >
            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
              <FaBinoculars size={15.5} color="#007AFF" />
            </span>
            <Tooltip
              target="#findst"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Find</p>
            </Tooltip>
          </li>

          {/* refresh */}
          <li
            onClick={() => {
              setSearchTextValue("");
              setCheckedBox(false);
              setIndeterminate(true);
              setCheckValue("");
              handleRefresh!();
            }}
            id="refreshst"
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
          >
            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
              {" "}
              <img
                className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                src={reset}
              />
            </span>
            <Tooltip
              target="#refreshst"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Refresh</p>
            </Tooltip>
          </li>

          <li
            onClick={() => printAccessPriv()}
            id="printst"
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
          >
            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
              {" "}
              <img
                className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                src={printer}
              />
            </span>
            <Tooltip
              target="#printst"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Print/Export</p>
            </Tooltip>
          </li>
        </ul>

        <DeleteTask isActive={isActive} setIsActive={setIsActive} />
        <TaskSettings
          SettingsisActive={SettingsisActive}
          SettingssetIsActive={SettingssetIsActive}
        />
        <AlertSMS
          AlertSMSisActive={AlertSMSisActive}
          AlertSMSsetIsActive={AlertSMSsetIsActive}
        />
        <AppraiseSettings
          AppraiseisActive={AppraiseisActive}
          AppraisesetIsActive={AppraisesetIsActive}
        />
      </div>
    </div>
  );
};

const data = [
  {
    label: "Name",
  },
  {
    label: "Staff Name",
  },
  {
    label: "Job Title",
  },
  {
    label: "Department",
  },
  {
    label: "Section",
  },
  {
    label: "Unit",
  },
  {
    label: "Location",
  },
];

// const data = [
//     { id: 1, text: "Task",  },
//     { id: 2, text: "Progress Activity",  },
//     { id: 3, text: "Task & Start Date",  },
//     { id: 4, text: "Task & Completion Date",  },
//     { id: 5, text: "Task & Due Date", },
//     { id: 6, text: "Progress Activity & Start Date", },
//     { id: 7, text: "Progress Activity & Completion Date",  },
//     { id: 8, text: "Progress Activity & Due Date", },
//     { id: 9, text: "Status", },
//     { id: 10, text: "Status & Start Date", },
//     { id: 11, text: "Status & Completion Date", },
//     { id: 12, text: "Status & Due Date", },
//     { id: 13, text: "Priority", },
//     { id: 14, text: "Priority & Start Date", },
//     { id: 15, text: "Priority & Completion Date", },
//     { id: 16, text: "Priority & Due Date", },
//     { id: 17, text: "Assigned By", },
//     { id: 18, text: "Assigned By & Start Date", },
//     { id: 19, text: "Assigned By & Completion Date", },
//     { id: 20, text: "Assigned By & Due Date", },
//     { id: 21, text: "Assigned To", },
//     { id: 22, text: "Assignred To & Start Date", },
//     { id: 23, text: "Assigned To & Completion Date", },
//     { id: 24, text: "Assigned To & Due Date", },
// ];
