

// FOR PPE REQUEST FOOTER

export const footer_datagrid_cols = [
    {id: 0,caption : "REQ.No", dataField : 'prqRequestNo', dataType : "", fixed : true,width:70},
    {id: 1,caption : "RECOM?", dataField : 'prqRecommended', dataType : "boolean", fixed : true,width:75},
    {id: 2,caption : "ACKNOW?", dataField : 'prqAcknowledged', dataType : "boolean", fixed : true,width:75},
    {id: 3,caption : "APRVD?", dataField : 'prqApproved', dataType : "boolean", fixed : true,width:75},
    {id: 4,caption : "ACTIVE?", dataField : 'prqActive', dataType : "boolean", fixed : true,width:75},
    {id: 5,caption : "DISTR?", dataField : 'prqDistributed', dataType : "boolean",fixed : true,width:75},
    {id: 6,caption : "REQUESTED BY", dataField : 'requesterEmpName5', dataType : "string",width:100},
    {id: 7,caption : "STF. No", dataField : 'requesterStaffNo', dataType : "",width:80},
    {id: 8,caption : "REQUESTED ON", dataField : 'prqRequestNo', dataType : "datetime",format:"dd MMM yyyy",width:150},
    {id: 9,caption : "REQUEST WINDOW", dataField : 'rqwName', dataType : "",width:100},
    {id: 10,caption : "PPE", dataField : 'ptpName', dataType : "",width:100},
    {id: 11,caption : "PPE SIZE", dataField : 'pszName', dataType : "",width:100},
    {id: 12,caption : "QTY. REQ.", dataField : 'prqQuantityRequested', dataType : "",width:85,cssClass:"qtyreq"},
    {id: 13,caption : "QTY. APRVD", dataField : 'prqQuantityApproved', dataType : "",width:85,cssClass:"qtyaprvd"},
    {id: 14,caption : "QTY. DISTR.", dataField : 'prqQuantityDistributed', dataType : "",width:85,cssClass:"qtydistr"},
    {id: 15,caption : "REQ. LOCATION", dataField : 'requesterLocName', dataType : "string",width:100},
    {id: 16,caption : "REQ. DEPT", dataField : 'requesterDeptName', dataType : "string",width:100},
    {id: 17,caption : "REQ. SECTION", dataField : 'requesterSxnName', dataType : "string",width:150},
    {id: 18,caption : "APPROVED ON", dataField : 'prqCreationDate', dataType : "datetime",width:100},
    {id: 19,caption : "CANCELLATION REQMARKS", dataField : 'prqCancellationRmks', dataType : "string",width:100},
    {id: 20,caption : "RECOMMENDED BY", dataField : 'requesterEmpID ', dataType : "string",width:100},
    {id: 21,caption : "ACKNOWLEDGED BY", dataField : 'requesterEmpID ', dataType : "string",width:100},
    {id: 22,caption : "APPROVED BY", dataField : 'requesterEmpID', dataType : "string",width:100},
    {id: 23,caption : "REMARK", dataField : 'prqRmks', dataType : "string",width:4000},
]

// PPE REQUESTS
// FOR REQUEST MINI DATAGRID
export const requisition_update_mini_datagrid = [
    {id: 0,caption : "PPE", dataField : 'ptpName', dataType : "string", fixed : true,width:100},
    {id: 1,caption : "Size", dataField : 'pszName', dataType : "string", fixed : true,width:80},
    {id: 2,caption : "Quantity", dataField : 'prqQuantityRequested', dataType : "number", fixed : true,width:100},
    {id: 3,caption : "Remarks", dataField : 'prqRmks', dataType : "string",cellEdit: true,width:"100%"},
    
]

// PPE / SIZE TABLE
export const ppeReqMini_cols = [
    {id: 0,caption : "PPE ", dataField : 'ptpName', dataType : "string", fixed : true,width:100},
    {id: 1,caption : "SIZE", dataField : 'pszName', dataType : "string", fixed : true,width:100},

]

export const requisition_update_edit_mini_datagrid = [
    {id: 0,caption : "ID", dataField : 'rtnIdpk', dataType : "string", fixed : true,width:80, visible: false},
    {id: 0,caption : "Rate", dataField : 'rtnRate', dataType : "string", fixed : true,width:80},
    {id: 1,caption : "Interpretation", dataField : 'rtnInterpretation', dataType : "string", fixed : true,width:80},
    {id: 1,caption : "Grade", dataField : 'rtnGrade', dataType : "string", fixed : false,width:80},
    {id: 1,caption : "Lower Value", dataField : 'rtnLowerValue', dataType : "string", fixed : false,width:80, visible: false},
    {id: 1,caption : "Order", dataField : 'rtnOrder', dataType : "string", fixed : false,width:80, visible: false},
    {id: 1,caption : "Remarks", dataField : 'rtnRmks', dataType : "string", fixed : false,width:80},
    
]

export const reqBy_cols = [
    {id: 0,caption : "Staff.No", dataField : 'empStaffNo', dataType : "number", fixed : true,width:40},
    {id: 1,caption : "Name", dataField : 'empName', dataType : "string", fixed : true,width:90},
    {id: 2,caption : "Dept", dataField : 'dptShtName', dataType : "string", fixed : true,width:40},
    {id: 3,caption : "Section", dataField : 'sxnShtName', dataType : "string", fixed : true,width:40},
    {id: 4,caption : "Job Title", dataField : 'jbtShtName', dataType : "string", fixed : true,width:60},
    {id: 5,caption : "Location", dataField : 'locShtName', dataType : "string", fixed : true,width:50},
]


export const ack_toolbar_cols = [
    {id: 0,caption : "Req.No", dataField : 'prqRequestNo', dataType : "", fixed : true,width:70},
    {id: 1,caption : "PPE", dataField : 'prqRecommended', dataType : "string", fixed : true,width:75},
    {id: 2,caption : "Size", dataField : 'prqAcknowledged', dataType : "", fixed : true,width:75},
    {id: 3,caption : "Date", dataField : 'prqApproved', dataType : "datetime",format:"dd MMM yyyy", fixed : true,width:95},
    {id: 4,caption : "Qty Req", dataField : 'prqActive', dataType : "", fixed : true,width:75},
    {id: 5,caption : "Qty Rec", dataField : 'prqDistributed', dataType : "",fixed : true,width:75},
    {id: 6,caption : "Qty Acknow", dataField : 'requesterEmpName5', dataType : "string",width:100},
    {id: 7,caption : "Qty Aprvd", dataField : 'requesterStaffNo', dataType : "",width:80},
    {id: 8,caption : "Rec?", dataField : 'prqRequestNo', dataType : "",width:150},
    {id: 9,caption : "Acknow?", dataField : 'rqwName', dataType : "",width:100},
    {id: 10,caption : "Approved?", dataField : 'ptpName', dataType : "",width:100},
    {id: 11,caption : "Remarks", dataField : 'pszName', dataType : "",width:4000},
]

export const app_toolbar_cols = [
    {id: 0,caption : "Req.No", dataField : 'prqRequestNo', dataType : "", fixed : true,width:70},
    {id: 1,caption : "PPE", dataField : 'prqRecommended', dataType : "boolean", fixed : true,width:75},
    {id: 2,caption : "Size", dataField : 'prqAcknowledged', dataType : "boolean", fixed : true,width:75},
    {id: 3,caption : "Date", dataField : 'prqApproved', dataType : "boolean", fixed : true,width:75},
    {id: 4,caption : "Qty Req", dataField : 'prqActive', dataType : "boolean", fixed : true,width:75},
    {id: 5,caption : "Qty Rec", dataField : 'prqDistributed', dataType : "boolean",fixed : true,width:75},
    {id: 6,caption : "Qty Acknow", dataField : 'requesterEmpName5', dataType : "string",width:100},
    {id: 7,caption : "Qty Aprvd", dataField : 'requesterStaffNo', dataType : "",width:80},
    {id: 8,caption : "Rec?", dataField : 'prqRequestNo', dataType : "datetime",format:"dd MMM yyyy",width:150},
    {id: 9,caption : "Acknow?", dataField : 'rqwName', dataType : "",width:100},
    {id: 10,caption : "Approved?", dataField : 'ptpName', dataType : "",width:100},
    {id: 11,caption : "Remarks", dataField : 'pszName', dataType : "",width:4000},
]

export const profileGrid_cols = [
    {id: 1,caption : "Safety Group", dataField : 'gtmName', dataType : "string", fixed : true,width:120, visible:true},
]


{/* <Column  dataField="LOCKED?"  fixed={true} width={60} cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataType='boolean'></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="DATE" fixed={true} dataType='date' width={100} format={"dd MMM yyyy"} ></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="%COMPLETE" fixed={true} cellRender={progressBar}></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="TASK UPDATE"  dataType='string' ></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="UPDATED BY" dataType='string' ></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="JOB TITLE" dataType='string'></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="UPDATER" dataType='string' width={100}></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="CREATED ON " dataType='string'></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="COMPLETION DATE" dataType='string'></Column>
        <Column cssClass={'dark:bg-slate-900 dark:text-darkModeSkin-base'} dataField="REMARKS" dataType='string' width={4000} ></Column> */}