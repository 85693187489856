export const leaveRequestcolumns = [
    {id : 0, caption : 'ID',datafield : 'dstIdpk',datatype : 'number', width : 80, fixed : true, visible : false},
    {id : 1, caption : 'SUBM?', datafield : 'dstActive',datatype : 'boolean', width : 75, fixed : true , visible : true},
    {id : 2, caption : 'APRVD?', datafield : 'dstActive',datatype : 'boolean', width : 75, fixed : true , visible : true},
    {id : 3, caption : 'AUTH?', datafield : 'dstActive',datatype : 'boolean', width : 75, fixed : true , visible : true},
    {id : 4, caption : 'ACTIVE?', datafield : 'dstActive',datatype : 'boolean', width : 90, fixed : false , visible : true},

    {id : 5, caption : 'STATUS', datafield : 'dstName',datatype : 'string', width : 160, fixed : false , visible : true},
    {id : 6, caption : 'STAFF NAME', datafield : 'dstShtName',datatype : 'string', width : 160, fixed : false , visible : true},
    {id : 7, caption : 'STAFF No', datafield : '',datatype : 'string', width : 100, fixed : false , visible : true},
    {id : 8, caption : 'START DATE', datafield : 'dstCapial',datatype : 'string', width : 160, fixed : false , visible : true},
    {id : 9, caption : 'END DATE', datafield : 'COUNTRY',datatype : 'string', width : 160, fixed : false , visible : true},
    {id : 10, caption : 'DUR(DAYS)', datafield : 'REGION',datatype : 'string', width : 80, fixed : false , visible : true},
    {id : 11, caption : 'LEAVE TYPE', datafield : 'dstOrder',datatype : 'string', width : 120, fixed : false , visible : true},
    {id : 12, caption : 'DEPT', datafield : 'dstActive',datatype : 'string', width : 120, fixed : false , visible : true},
    {id : 13, caption : 'SECTION',datafield : 'dstRemarks',datatype : 'string', width : 130, fixed : false , visible : true},
    {id : 14, caption : 'DEPT', datafield : 'dstActive',datatype : 'string', width : 130, fixed : false , visible : true},
    {id : 15, caption : 'REMARKS',datafield : 'dstRemarks',datatype : 'string', width : 5000, fixed : false , visible : true},
]
