import { Tooltip } from "devextreme-react";
import { CheckboxTemlate } from "../../../../../templates/checkbox";
import { DateTemplate } from "../../../../../templates/date";
import { InputsTemplate } from "../../../../../templates/input";
import { IconsContainer } from "./stationReadingsToolbar"; 
import close from '../../../../../assets/close.png';
import print from '../../../../../assets/printer.png';
import flip from '../../../../../assets/switch-chart.png';
import edit from '../../../../../assets/edit.png';
import chart from '../../../../../assets/bar-chart.png';
import newImg from '../../../../../assets/new.png';
import { FaBinoculars } from "react-icons/fa"; 
import { useGraphSwitcher, useSwitcher } from "../db/useSwitcher";
import { useDispatch } from "react-redux";
import { setGeneralValue } from "../../../../../features/generalSlice";
import { useState } from "react";
import dayjs, { Dayjs } from "dayjs";

type Props = {
    showEditIcon?: boolean;
    searchTextOnchange?: (value: string) => void;
    checkOnChange?: (value: any) => void;
    handleFind?: () => void;
    runNew?: () => void;
    runUpdate?: () => void;
    runCancel?: () => void;
    startDateChanged?: (value: any) => void; 
  endDateChanged?: (value: any) => void; 
  
}

export default function MiniToolbar({ showEditIcon = true, searchTextOnchange, checkOnChange, handleFind, startDateChanged, endDateChanged, runCancel, runNew, runUpdate }: Props) {
    const { setWitch } = useSwitcher();
    const { setSwitchGraph } = useGraphSwitcher();
    const dispatch = useDispatch();

    const [indeterminate, setIndeterminate] = useState<any>(true);
    const [checkedBox, setCheckedBox] = useState<any>(false);
    const [checkValue, setCheckValue] = useState<boolean | string>("");
    const [searchText, setSearchText] = useState("");

    const onChange = (value: boolean) => {
        if (indeterminate === true) {
          setCheckedBox(true);
          setIndeterminate(false);
          setCheckValue(true);
        } else if (!indeterminate && checkedBox) {
          setCheckedBox(false);
          setIndeterminate(false);
          setCheckValue(false);
        } else if (!indeterminate && !checkedBox) {
          setCheckedBox(false);
          setIndeterminate(true);
          setCheckValue("");
        }
      };

    return (
        <div className="border-2 border-muted w-full h-auto rounded-md">
            < section className="flex items-center w-full h-full space-x-1 px-1" >
                <aside className="flex space-x-0.5"> 
                <IconsContainer
                onClick={()=>{
                    dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
                    dispatch(setGeneralValue({ expr: "updateMode", value: false }));
                    runNew();
                }}
                url={newImg} name="Cancel" tooltip="Click to initiate the capturing of midnight conditions for the station" />
                    
                    {
                        showEditIcon &&
                        <IconsContainer
                        onClick={()=>{
                           runUpdate();
                        }}
                        url={edit} name="Midnight Conditions" tooltip="Click to initiate the capturing of midnight conditions for the station" />
                    }
                    <IconsContainer 
                     onClick={()=>{
                       runCancel();
                    }}
                    url={close} name="Cancel" tooltip="Click to initiate the capturing of midnight conditions for the station" />
                    <aside onClick={setWitch}>
                        <IconsContainer url={flip} name="Switch" tooltip="Click to initiate the capturing of midnight conditions for the station" />
                    </aside>
                    <aside onClick={setSwitchGraph}>
                        <IconsContainer url={chart} name="Chart" tooltip="Click to initiate the capturing of midnight conditions for the station" />
                    </aside>
                </aside>
                <aside className="flex flex-1 space-x-1 items-center">
                    < section className="flex-1" >
                        <InputsTemplate label="Search Text" inputStyle={{ height: "25px" }} span={true} disabledStatus={false}
                        orderOnchange={(e)=>{
                            searchTextOnchange(e.toString());
                        }}
                        />
                    </section >
                </aside>
                <aside className="flex space-x-1 items-center mr-2">
                    <DateTemplate disabled={false}
                   
                      changeDate={(date: Dayjs) =>
                        startDateChanged!(dayjs(date).format())
                      }
                     
                    />
                    <DateTemplate disabled={false} 
                     changeDate={(date: Dayjs) =>
                        endDateChanged!(dayjs(date).format())
                      }
                    />
                    <li
                        id="multiline-rows"
                        style={{
                        }}
                        className="hover:cursor-pointer mr-1.5 text-xs text-gray-600 rounded flex items-center "
                    >
                        <CheckboxTemlate
                        defaultValue={checkedBox}
                        indeterminate={indeterminate}
                            withBorders
                            customDisabled={false}
                           
                  useCallbackFunc
                 
                  setCheckboxOnchange={(e: boolean, index: any) => {
                    checkOnChange( e);
                    onChange(e);
                  }}
                            
                        />

                        <Tooltip
                            target="#multiline-rows"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p className=" text-left font-semibold">Multiline Rows</p>
                            <p>Click to expand or collapse multiple rows in the table.</p>
                        </Tooltip>
                    </li>
                    <div  onClick={()=>{
                            handleFind();
                        }}  className="p-1 border-[1px] rounded-sm ">
                        <FaBinoculars size={15.5} color="#007AFF" />
                    </div>
                    <IconsContainer
                        url={print}
                        name="Midnight Conditions"
                        tooltip="Click to initiate the capturing of midnight conditions for the station" />
                </aside>
            </section >
        </div >
    )
}