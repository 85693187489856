/* eslint-disable react/jsx-pascal-case */

import { CheckboxTemlate } from '../../../../../templates/checkbox';
import chart from '../../../../../assets/bar-chart.png';
import { InputsTemplate } from '../../../../../templates/input';
import { DateTemplate } from '../../../../../templates/date';
import { SelectsTemplate } from '../../../../../templates/select';
import { RefreshButton } from './utils';
import Datagrid_template from "../../../../../templates/Datagrid";
import { Chart } from 'devextreme-react';
import { useState } from 'react';

export const ReadingsChart = () => {

const [toggleFooter, setToggleFooter] = useState(true)
    return (
        <section className='w-full h-full flex space-x-3'>
            {/* left section */}
            <section className='h-full w-80 px-1'>
                <section className='flex space-x-2 justify-center'>
                    <aside >
                        <div className='flex items-center space-x-1' >
                            <DateTemplate showTime disabled={false} />
                            <CheckboxTemlate
                                withBorders
                                defaultValue={false}
                                customDisabled={false}
                            />
                        </div>
                        <div className='flex items-center space-x-1' >
                            <DateTemplate showTime disabled={false} />
                            <CheckboxTemlate
                                withBorders
                                defaultValue={toggleFooter}
                                customDisabled={false}
                                setCheckboxOnchange={(toggle) => {
                                    setToggleFooter(toggle);
                                  }}
                            />
                        </div>
                    </aside>
                    <aside className='my-0.5'>
                        {/* generate button */}
                        <section className='h-full border-[1px] content-center px-2 rounded-md cursor-pointer'>
                            <div className='flex justify-center'><img src={chart} alt='graph' /></div>
                            <p className='text-[12px]'>Generate</p>
                        </section>
                    </aside>
                </section>
                < SelectsTemplate extraWidget={false} idexpr={"id"} dataexp={"value"} selectStyle={{ height: "27px" }} disabled={false} options={selectOptions} span={true} useCusWidth cusWidth={"210px"} disabledCustomWidth={true} />
                <section className='flex justify-between items-center'>
                    <aside className='flex space-x-2 items-center border-[1px] rounded-md px-1 w-[210px]'>
                        <CheckboxTemlate
                            withBorders
                            defaultValue={false}
                            customDisabled={false}
                        />
                        <label className='text-[12px] text-neutral-500'>Plot Min & Max Constants</label>
                    </aside>
                    <aside className='flex items-center space-x-1'>
                        <RefreshButton />
                        <CheckboxTemlate
                            withBorders
                            defaultValue={false}
                            customDisabled={false}
                        />
                    </aside>
                </section>
                <section>
                    <Datagrid_template
                        gridheight={280}
                        showColumnHeaders={true}
                        columns={[{ id: 1, caption: "Station", dataField: "station", fixed: false, width: 100 }, { id: 2, caption: "Bus", dataField: "bus", fixed: false, width: 100 }, { id: 2, dataField: "active", fixed: false, dataType: "boolean", width: 100 }]}
                        data={[{ id: 0, station: "Station", bus: "Bus A", active: false }, { id: 1, station: "Vladivost", bus: "Bus B", active: false }]}
                        disablePaging
                        disableSearch
                        disableGroupPanel
                    />
                </section>
            </section>
            <section className='h-full w-full flex-1'>
                {
                    toggleFooter && (
                        <aside className="flex flex-1 space-x-1 items-center">
                    < section className="flex-1" >
                        <InputsTemplate label="Chart footer" inputStyle={{ height: "25px" }} span={true} disabledStatus={false} />
                    </section >
                </aside>)
                }

                {/* graph area */}
                <section className='w-full h-96 border-[1px] border-sm'>
                    <Chart id="chart" dataSource={[]} title="Chart" />
                </section>
            </section>
        </section>
    )
}

const selectOptions = [{ id: 0, value: "Spline" }, { id: 1, value: "Line" }, { id: 2, value: "Bar" }];