import { Tooltip } from "devextreme-react";
import { useState } from "react";
import { useSearchParams, useNavigate, createSearchParams } from "react-router-dom";
import { EntryCustomToolbar } from "../../../../accessories/custom-toolbars/entryCustomtoolbar";
import StaffDependantDetails from "./staffDependant-details";
import { StaffDependantToolbar } from "./staffDependant-toolbar";
import React from "react";





const StaffDependant = () => {

    const taskDetailsHeight = '309px'

    const [tasks_update_state, setTask_update_state] = useState<boolean>(true)
    const [task_datagrid_height, set_task_datagrid_height] = useState(`calc(100% - ${taskDetailsHeight})`);
    const [searchParams, setSearchParams] = useSearchParams()

    const navigate = useNavigate()

    const current_page_route = '/tasks-todos';
    const view = searchParams.get('view');
    const taskDataGrid = 'task-data-grid-extended';
    const [showTable, setShowTable] = useState(true)

    // switch button toggler



    return (
        <>
            <div className="mx-2">
                <StaffDependantToolbar />
            </div>



            <div className="px-2 mb-0.5  ">
                <StaffDependantDetails toggled={showTable} />

            </div>
        </>

    );
}

export default StaffDependant;